import * as Styled from './styled';

const Nav = ({ page, numResults, rows, q }: { page: number; numResults: number; rows: number; q: string }) => {
  const start = (page - 1) * rows + 1;
  return (
    <Styled.Nav>

      <div>
        {(page > 1) ? (
          <Styled.PrevPageLink to={`/search/${q}/${page - 1}`}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 166.52 331.63">
              <path d="M165.82 331.28.35 165.82 165.82.35" />
            </svg>
          </Styled.PrevPageLink>
        ) : <Styled.LinkPlaceholder />
        }
        <Styled.PageNum>Page {page}/{Math.ceil(numResults / rows)}</Styled.PageNum>
        {(numResults > start + rows - 1) ? (
          <Styled.NextPageLink to={`/search/${q}/${page + 1}`}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 166.52 331.63">
              <path d="m.35 331.28 165.47-165.46L.35.35" />
            </svg>
          </Styled.NextPageLink>
        ) : <Styled.LinkPlaceholder />}
      </div>
      <div>{start}-{Math.min(start + rows - 1, numResults)} of <strong>{numResults}</strong> matching area descriptions</div>
    </Styled.Nav>
  )
};

export default Nav;