export const colors = {
  black: '#000000',
  white: '#ffffff',
  grayLight: '#f0f0eb',
  grayMedium: '#c0c0c0',
  grayDark: '#656563',
  text: '#3e3e3d',
  textLight: '#6f6f6d',
  interactive: '#3366CC',
  interactiveLight: '#a3c1fd',
  interactiveDark: '#2952a3',
  yellow: '#FFFF8F',
}

export const fonts = {
  serif: "'Roboto Slab', sans-serif",
  sansSerif: "Helvetica, Arial, serif",
  // sansSerif: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif";
}

export const sizes = {
  tablet: 600,
  desktop: 1000,
}

export const devices = {
  tablet: `(min-width: ${sizes.tablet}px)`,
  desktop: `(min-width: ${sizes.desktop}px)`,
}

