import { useState } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import { useParams } from 'react-router-dom';
import { useDimensions } from '../../hooks';
import Transcription from './Transcription';
import PageNav from './PageNav';
import 'leaflet/dist/leaflet.css';
import * as Styled from './styled';


function PageViewer() {
  const { volume } = useParams();
  const page = useParams().page || "1";
  const page_num = parseInt(page as string);
  const { media } = useDimensions();
  const [isTranscript, setIsTranscript] = useState(true);

  // the pages are offset by 28--i.e page 1 is 29/{z}/{x}/{y}.png
  const url = `//s3.amazonaws.com/dsl-general/secession_convention/tiles/${volume}/${page_num}/{z}/{x}/{y}.png`;
  //const url = `${process.env.PUBLIC_URL}/static/tiles/${page_num + 28}/{z}/{x}/{y}.png`;
  return (
    <Styled.PageViewer>
      {(media !== "desktop") && (
        <div className="tabs">

          <div className="tab">
            <input type="radio" id="tab1" name="tab-group" checked={isTranscript} />
            <label htmlFor="tab1" onClick={() => { setIsTranscript(true) }}>Transcript</label>
          </div>
          <div className="tab">
            <input type="radio" id="tab2" name="tab-group" checked={!isTranscript} />
            <label htmlFor="tab2" onClick={() => { setIsTranscript(false) }}>Original Scan</label>
          </div>

          {/* 
          <button onClick={() => {setIsTranscript(true)}}>Transcript</button>
          <button onClick={() => {setIsTranscript(false)}}>Original Scan</button>
          */}
        </div>
      )}

      {(media === "desktop" || isTranscript) && (
        <Transcription />
      )}

      {(media === "desktop" || !isTranscript) && (
        <MapContainer
          center={[0, 0]}
          bounds={[[-84, -180], [84, 60]]} // these bounds are for just the trustee volume--something more robust will be needed for the reports and corresponding
          minZoom={1}
          id="page_image"
        >
          <TileLayer
            url={url}
            zIndex={1000}
            maxZoom={2}
            noWrap
            key={`page${page_num}`}
          />
        </MapContainer>
      )}

      <PageNav />



    </Styled.PageViewer>
  );
}

export default PageViewer;
