import styled from "styled-components";
import * as Constants from '../../constants';
import { hexToRgb } from "../../utilities";

export const PageViewer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 45px calc(100vh - (80px + 45px + 46px + 40px + 0.5rem)) 46px;
  grid-template-rows: 45px calc(100svh - (80px + 45px + 46px + 40px + 0.5rem)) 46px;

  header {
    position: relative;
    text-align: center;
    display: block;
    border-bottom: 0;
    background-color: ${Constants.colors.grayLight};
    z-index: 999999999;

    * {
      display: inline-block;
    }
  }

  .tabs {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100vw - 10px);
    min-width: 200px;
    margin: 0 auto;
    text-align: center;
    z-index: 999999999;

    .tab {
      flex-grow: 1;
      height: 100%;
    }
  }

  .transcription {
    font-size: 16px;
    z-index: 1000;
    overflow: hidden scroll;
    scroll-behavior: smooth;
    font-family: ${Constants.fonts.serif};
  }

  #page_image,
  #metadata {
    // min-height: 150vw;
    min-height: calc(100vh - 270px);
    width: 100%;
  }

  h2 {
    margin: 0;
    line-height: 45px;
    text-align: center;
    font-size: clamp(14px, 2.5vw, 24px);
  }

  #previous,
  #next {
    background-color: ${Constants.colors.interactive};
    color: ${Constants.colors.text};
    width: 60px;
    height: 60px;
    line-height: 60px;
    border-radius: 30px;
    font-size: 30px;
    text-align: center;
    text-decoration: none;
    font-weight: bold;
    border-color: ${Constants.colors.grayMedium};
    position: fixed;
    top: calc(50vh);
    z-index: 1001;
  }
  #previous {
    left: 40px;
  }
  #next {
    right: calc(34vw + 40px);
  }
  #previous:hover,
  #next:hover {
    background-color: ${Constants.colors.interactive};
  }
  #previous:hover a,
  #next:hover a {
    color: ${Constants.colors.interactive};
  }

  #page_image {
    background-color: black;
  }

  #metadataSection {
    position: absolute;
    top: 50%;
    transform: translate(0.5rem, -50%);

    svg {
      height: 1.25em;
      width: 1.25em;
      fill: ${Constants.colors.interactive};
      cursor: pointer;
    }
  }
  .synopsisDateLine {
    display: grid;
    grid-template-columns: min-content auto min-content;
    justify-content: space-between;

    div:first-child {
      grid-column: 2 / span 1;
    }
  }

  nav {
    height: 45px;
    font-size: 0.9em;
    text-align: center;
    line-height: 45px;
    // font-weight: 700;
    background-color: ${Constants.colors.grayLight};
    border-top: 1px solid ${Constants.colors.grayMedium};
    z-index: 999999999;

    a {
      color: ${Constants.colors.text};
      font-size: 1em;
      text-decoration: none;
      cursor: pointer;

      svg {
        height: 1em;
        width: 1em;
        transform: translateY(20%);

        path {
          fill: none;
          stroke: ${Constants.colors.interactive};
          stroke-width: 60;
          stroke-linejoin: miter;
        }

        &:hover,
        &:active {
          stroke: ${Constants.colors.interactiveLight};
        }
      }
    }
  }

  @media ${Constants.devices.tablet} {
    grid-template-columns: calc(100% - 100px) 100px;
    grid-template-rows: 50px 39px calc(100vh - (61px + 50px + 39px + 46px + 40px)) 46px;

    header {
      align-self: center;
      grid-column: 1/2;
      grid-row: 1/2;
      box-shadow: none;
    }

    .tabs {
      grid-column: 1/2;
      grid-row: 2/3;
      width: 100%;
    }

    .transcription {
      grid-row: 3/4;
      margin-top: 0;
      padding-bottom: 0;
    }

    nav {
      grid-column: 1/2;
      grid-row: 4/5;
      
      a svg {
        height: 1.2em;
        width: 1.2em;
        overflow: visible;
      }
    }

    #timeline {
      grid-column: 2/3;
      grid-row: 1/4;
      height: calc(100vh - 61px - 40px);
      margin-left: -300px;
      overflow-x: hidden;
      overflow-y: hidden;
      pointer-events: none;
      width: auto;
      z-index: 100;
    }

    #page_image,
    #metadata {
      min-height: auto;
      grid-column: 1/2;
      grid-row: 3/4;
    }
  }

  @media ${Constants.devices.desktop} {
    grid-template-columns: 50% calc(50% - 100px) 100px;
    grid-template-rows: 50px calc(100vh - (111px + 50px + 46px + 40px)) 46px;

    header {
      align-self: center;
      grid-column: 1/3;
      grid-row: 1/2;
    }

    .transcription {
      grid-column: 1/2;
      grid-row: 2/4;
      border-top: 1px solid ${Constants.colors.grayMedium};
    }

    nav {
      grid-column: 1/3;
      grid-row: 3/4;
    }

    #timeline {
      grid-column: 3/4;
      grid-row: 1/4;
    }

    #page_image,
    #metadata {
      grid-column: 2/3;
      grid-row: 2 / span 1;
    }
  }
`;


export const Metadata = styled.div`
  z-index: 4000;
  background-color: ${Constants.colors.grayDark};
  color: ${Constants.colors.grayLight};
  overflow-y: scroll;
  font-weight: 700;

  #data {
    display: grid;
    grid-template-columns: 150px auto;
    column-gap: 40px;
    row-gap: 20px;

    h3 {
      height: auto;
      margin: 0;
      text-align: right;
      font-weight: 300;
      font-size: 0.9em;
    }

    ul {
      padding: 0;
      margin: 0;
    }
  }

  a, .close span {
    padding: 0.5rem 1rem;
    color: ${Constants.colors.text};
    font-weight: normal;
    text-decoration: none;
    background-color: rgba(${hexToRgb(Constants.colors.white)}, 0.5);
    box-shadow: 0px 10px 10px -12px rgba(${hexToRgb(Constants.colors.black)}, 0.3);
  
    &:hover,
    &:active {
      color: ${Constants.colors.interactive};
      background-color: ${Constants.colors.white};
    }
  }

  .rawxml {
    margin-top: 30px;
    text-align: center;
  }

  .close {
    text-align: right;
    margin-top: 30px;

    span {
      margin-right: 10px;
    }
  }
`;


export const Timeline = styled.div`
  width: 400px;
  height: 100vh;
  overflow-y: hidden;
  overflow-x: hidden;
  z-index: 100;
  margin-left: -300px;
  pointer-events: none;

  line {
    stroke: ${Constants.colors.grayMedium};
    stroke-width: 1px;
  }
  
  text {
    fill: ${Constants.colors.text};
    font-size: 16px;
    pointer-events: none;
    font-weight: bold;

    &.text-halo {
      stroke: ${Constants.colors.grayLight};
      stroke-width: 2px;
    }
  }

  .meeting-label {

    text {
      visibility: hidden;
      font-size: 12px;
      font-weight: normal;

      &.selected {
        visibility: visible;
      }
    }
  }

  .meeting-circle {
    fill: ${Constants.colors.grayDark};
    fill-opacity: 0.5;
    stroke: ${Constants.colors.grayLight};
    stroke-width: 0;
    pointer-events: auto;

    &.selected {
      position: relative;
      fill: ${Constants.colors.interactive};
      fill-opacity: 1;
      
      // + .halo {
      //   fill-opacity: 0.5;
      // }
    }
    
    &:hover {
      fill: ${Constants.colors.interactive};
      
      &~text {
        visibility: visible;
      }
      + rect {
        position: relative;
        fill-opacity: 1;
        stroke: ${Constants.colors.grayMedium};
      }
    }
  }

  .text-background {
    fill: white;
    fill-opacity: 0;
    stroke-width: 0.5;
    pointer-events: auto;
    stroke: transparent;

    &.selected {
      position: relative;
      fill-opacity: 1;
      stroke: ${Constants.colors.grayMedium};
      
      // + .halo {
      //   fill-opacity: 0.5;
      // }
    }
  }

  .meeting-halo {
  
    &.selected {
      fill: ${Constants.colors.interactive};
      fill-opacity: 0.3;
    }
  }
`;