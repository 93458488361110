import { useEffect, useRef, useState } from "react";
import { useLocation, useParams, Link } from "react-router-dom";
// import "../../../styles/PageViewer/Transcription.scss";
import PageNumber from "./PageNumber";
import * as Styled from './styled';

const Transcription = () => {
  const page = useParams().page || "1";
  const { hash } = useLocation();
  const scrollToAnchor = hash ? hash.replace("#", "") : null;

  // pageNum is stored in state and updated when any necessary scrolling has finished
  const [pageNum, setPageNum] = useState(0);
  // this is set to true if the element if scrollIntoView is necessary in the effect below. It's passed to PageNumber components to prevent them from navigating to a new page until the scroll here is complete.
  const [isAutoScrolling, setIsAutoScrolling] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  //when the page number changes, scroll to the page on the transcription
  useEffect(() => {
    if (parseInt(page) !== pageNum && !scrollToAnchor) {
      const element = document.getElementById(`page-${page}`);
      const transcriptionContainer = ref.current;
      if (element && transcriptionContainer) {
        element.scrollIntoView();
        let scrollTimeout: any;
        setIsAutoScrolling(true);
        const onScroll = (e: any) => {
          clearTimeout(scrollTimeout);
          scrollTimeout = setTimeout(function () {
            setPageNum(parseInt(page));
            setIsAutoScrolling(false);
          }, 100);
        };
        transcriptionContainer.addEventListener("scroll", onScroll);
        return () => {
          transcriptionContainer.removeEventListener("scroll", onScroll);
        };
      }
    }
  }, [page, pageNum, scrollToAnchor]);

  // scroll to a meeting anchor
  useEffect(() => {
    if (scrollToAnchor) {
      const element = document.getElementById(scrollToAnchor);
      const transcriptionContainer = ref.current;
      if (element && transcriptionContainer) {
        element.scrollIntoView();
        let scrollTimeout: any;
        setIsAutoScrolling(true);
        const onScroll = (e: any) => {
          clearTimeout(scrollTimeout);
          scrollTimeout = setTimeout(function () {
            setPageNum(parseInt(page));
            setIsAutoScrolling(false);
          }, 100);
        };
        transcriptionContainer.addEventListener("scroll", onScroll);
        return () => {
          transcriptionContainer.removeEventListener("scroll", onScroll);
        };
      }
    }
  }, [page, pageNum, scrollToAnchor]);

  return (
    <Styled.Volume>
        <div className="tei_front" id="v1front">
          <div className="titlePage">
            <PageNumber num={1} />
            <div className="docTitle">
              <div className="titlePart">
                Proceedings<br />
                OF THE<br />
                Virginia State Convention<br />
                of 1861
              </div>
              <div className="titlePart">FEBRUARY 13 — MAY 1<br />In Four Volumes</div>
            </div>
            <div className="byline">GEORGE H. REESE<br />Editor</div>
            <div className="docImprint"><span className="date">1965</span><span className="publisher">VIRGINIA STATE LIBRARY</span><span className="pubPlace">RICHMOND, VIRGINIA</span>:</div>
          </div>
          <section className="front" id="vsc1965.v1.1">
            <h1>Published under auth …</h1>
            <PageNumber num={2} />
            <p>Published under authority of the Library Board of Virginia</p>
            <p>STERLING HUTCHESON, Chairman</p>
            <p>EDWIN COX</p>
            <p>E. ASHTON SALE</p>
            <p>VIRGINIUS DABNEY</p>
            <p>MRS. VIRGINIUS R. SHACKELFORD, JR.</p>
            <p>BOOKER T. BRADSHAW</p>
            <p>WOODROW W. WILKERSON</p>
            <p>And under the direction of</p>
            <p>RANDOLPH W. CHURCH, State Librarian</p>
            <p>GEORGE H. REESE, Head, Historical Publications Division</p>
            <p>Library of Congress Catalog Card No. A65-7459</p>
            <p>PRINTED IN U.S.A.</p>
            <div className="preface" id="vsc1965.v1.1.1">
              <PageNumber num={3} />
              <h2><span className="head">PREFACE</span></h2>
              <p>
                While the lengthy addresses and discussions which led up to the passage of the Ordinance of Secession by the Virginia Convention of 1861 have been in some measure available to historians from the original newspaper printings, there has been, to this date, no compilation of this important source material. The Documents submitted to the Convention were printed in a contemporaneous volume
                as also were the Journal of the Acts and Proceedings ... and the Ordinances Adopted.... With this present four-volume edition of the Proceedings ... the record of the Convention, in its first session, is now substantially available in easily readable form.
              </p>
              <p>The desirability of this publication was pointed out early in the years of the Civil War Centennial by a joint committee of the Virginia State Bar and the Virginia State Bar Association, but it was not until the General Assembly of 1964 that funds were made available to the Virginia State Library for this purpose, largely through the efforts of Eppa Hunton, IV.</p>
              <p>
                The task of assembling and reproducing this material has not been an easy one, and George H. Reese, Head of the State Library's Historical Publications Division, has brought to the undertaking a zealous and discerning effort which will be apparent even upon casual inspection. Rather than attempting a comprehensive subject index, which would have had many ramifications, the decision was
                made to prepare a synoptical guide to head each volume. Through this medium the progress of the Convention may be followed and the important addresses and motions may be determined. The interested reader is left to decide for himself how closely the speakers adhered to their stated subject.
              </p>
              <p>
                The 152 delegates who were called to convene in the Capitol on February 13, 1861, came from divergent backgrounds, but most were politically oriented. About one-fourth were from counties that later were to comprise West Virginia. Only about one-fifth of the whole appear to have taken prominent and active part in the day <PageNumber num={4} /> by day
                activities of the Convention. These, along with many others, had been or were to become members of the Virginia General Assembly. The balance was decidedly in favor of maturity. Among the most distinguished members were a former President, John Tyler; a former Governor, Henry A. Wise; two past cabinet members, A. H. H. Stuart and William Ballard Preston; and the Lieutenant-Governor,
                Robert L. Montague. Jubal A. Early was yet to achieve military prominence. There were several with past Congressional experience. Well-known Virginia surnames predominate in the proceedings and many of these may be recognized in the Virginia life of today.
              </p>
              <p>
                Unquestionably these volumes reflect the political tone and temper of the time in a manner that few other records do. Through the actions of the participants one may trace the decline of the moderate element, originally comprising about half of the delegates. Even so the vote by which the ordinance passed on April 17 was 88 to 55. Changes in votes, and absentee votes, raised the final
                count to 103 to 46. Thus Virginia, to all intents and purposes, left the Union, for the ratification by the people at their election of May 23 was hardly more than a formality.
              </p>
              <div className="signed">
                <span className="name">RANDOLPH W. CHURCH</span><br />
                State Librarian
              </div>
            </div>
            <div className="contents" id="vsc1965.v1.1.2">
              <PageNumber num={5} />
              <div className="table">
                <table>
                  <caption>
                    CONTENTS
                  </caption>
                  <tr>
                    <td>Introduction</td>
                    <td>vii</td>
                  </tr>
                  <tr>
                    <td>Synopsis</td>
                    <td>xvii</td>
                  </tr>
                  <tr>
                    <td>Proceedings</td>
                    <td>3</td>
                  </tr>
                  <tr>
                    <td>Notes</td>
                    <td>771</td>
                  </tr>
                  <tr>
                    <td>Appendixes</td>
                    <td>779</td>
                  </tr>
                </table>
              </div>
              <PageNumber num={6} />
            </div>
            <div className="introduction" id="vsc1965.v1.1.3">
              <PageNumber num={7} />
              <h2><span className="head">INTRODUCTION</span></h2>
              <p>This publication brings together for the first time reports of proceedings of the first session of the Virginia State Convention of 1861—the momentous session which brought about the withdrawal of Virginia from the Federal Union, and the adherence of Virginia to the Confederacy.</p>
              <p>
                The proceedings) published here are those of sittings of the Convention from the time it assembled on February 13 until its adjournment on May 1, as they were published in the Richmond Enquirer, official reporter of the Convention. The Convention sat again from June 12 through July 1, and from November 13 through December 6, in what the Convention Journal calls the Adjourned Session
                and the Second Adjourned Session. Proceedings of those sessions also were published in the Enquirer, but their bulk, and their relative unimportance, have made it advisable to exclude them from the present publication.
              </p>
              <p>
                This introduction aims only to give a summary account of the legislative and executive actions which brought the Convention of 1861 into being, to comment briefly on the arrangements for reporting its proceedings, and to explain the manner in which the reports of proceedings have been assembled and made ready for publication. The reader will find in Henry T. Shanks, The Secession
                Movement in Virginia, 1847-1861, a discussion of the circumstances which led to the calling of the Convention and a detailed account of the actions of the Convention in its session of February-May 1861.
              </p>
              <p>
                On November 15, 1860, Governor John Letcher of Virginia issued a proclamation calling the General Assembly into special session on January 7, 1861, in order that the representatives of the people of the Commonwealth might consider public affairs, particularly the recent Presidential election which had resulted in "the
                <span className="note" id="Note5"><span className="noteLabel">1</span>1. "Proceedings" seems the most fitting term for the record of all that took place at the Convention. The delegates, and the editors of the Enquirer, appear to have used the words "proceedings" and "debates" indiscriminately.</span><PageNumber num={8} /> appointment of electors, a majority of
                whom, are known to be favorable to the election of sectional candidates as President and Vice President of the United States, whose principles and views are believed by a large portion of the Southern States to be in direct hostility to their constitutional rights and interests."
              </p>
              <p>
                When the Assembly convened on January 7, Governor Letcher addressed a message to the Senate and the House of Delegates in which he remarked "The proposition for the call of a State convention, to determine the position which Virginia shall take, in view of passing events, appears to have been received with very general favor." The proposition did not find favor with the Governor, who
                went on to say
              </p>
              <p>
                I have my convictions upon this question, and I give expression to them, in declaring my opposition at this time, to the call of a State Convention. I see no necessity for it at this time, nor do I now see any good practical result that can be accomplished by it. I do not consider this a propitious time to moot the question, and I apprehend, from indications that have been exhibited,
                that serious difficulties and embarrassments will attend the movement. Subsequent events may show the necessity for it.
              </p>
              <p>
                Notwithstanding the Governor's opposition to a State convention, members of both houses of the General Assembly promptly went to work to have a convention called. On January 7, in the House of Delegates, Mr. Kemper proposed that a committee be appointed to draw up "a bill, providing for a convention of the people of Virginia" ; and on January 8, Mr. Dickinson made a similar proposal in
                the Senate. Mr. Kemper presented the House bill on January 9; the House passed the bill on January 12, with 141 ayes and no negative votes. On January 10, Mr. Dickinson presented the Senate bill, which was under consideration on January 12 when the House of Delegates passed its bill. Thereupon the Senate put aside its own bill and proceeded to consideration of the House bill. At the
                evening session of the Senate on January 12, Mr. Dickinson reported the House bill, with certain amendments, and the Senate approved the bill, as amended, by a vote of 45 ayes to 1 nay. The House of Delegates agreed to the amendments of the
              </p>
              <PageNumber num={9} />
              <p>Senate on January 14, and "An act to provide for electing members of a convention and to convene the same" became law.2</p>
              <p>
                The Act called for the election of 152 delegates to be held on February 4, 1861, and for a separate poll, at the same time, "to take the sense of the qualified voters as to whether any action of said convention dissolving our connection with the Federal Union, or changing the organic law of the state, shall be submitted to the people for ratification or rejection." 3 The Act further
                provided that the Convention should assemble on February 13 in the Capitol at Richmond, and meet in that place until otherwise provided for; upon passage of the Act, the Governor was to issue immediately a proclamation giving notice of the Act, of the date of the election, and of the date when the Convention was to assemble.
              </p>
              <p>
                The Governor's proclamation was issued on January 15.4 Elections were duly held, and the elected delegates5 assembled in the Capitol in Richmond at 12 o'clock, noon, on February 13. Since the General Assembly was still in session, the Convention moved to the Hall of the Mechanics' Institute for its meeting on February 14, but on April 8 it returned to the Capitol ( the General Assembly
                having adjourned) and met thereafter in the Hall of the House of Delegates.
              </p>
              <p>
                The Convention sat every week day, except Washington's Birthday, between its assembling on February 13 and the adjournment of its first session on May 1. The Convention met also on Sunday, April 21, at the call of Governor Letcher. On Tuesday, April 16, not long after it assembled for the day, the Convention went into secret session and continued to meet in secret session until
                adjourning on May 1; however, the injunction of secrecy was removed from the ceremony of receiving General Lee on April 23.
              </p>
              <p>
                Arrangements were made early in the session for the reporting and recording of the Convention's deliberations. The Convention resolved on February 15 that editors and reporters of newspapers published in Richmond should be admitted to seats in the Convention, and on the next day extended admission to editors and reporters of newspapers published anywhere in Virginia. Reporters
                <span className="note" id="Note6"><span className="noteLabel">2</span>2. See Appendix A for the text of the Act.</span><span className="note" id="Note7"><span className="noteLabel">3</span>3. The returns of votes on the referendum will be found in Appendix D.</span><span className="note" id="Note8"><span className="noteLabel">4</span>4. The proclamation is printed in Appendix B.</span
                ><span className="note" id="Note9"><span className="noteLabel">5</span>5. A list of the delegates elected is in Appendix C.</span><PageNumber num={10} /> from Richmond papers seem to have been in attendance from the start, for the Dispatch, the Enquirer, the Whig, and probably the Examiner, reported on every open meeting of the Convention.6
              </p>
              <p>
                The Enquirer, in its daily edition of February 20, took credit for being the only Richmond paper that had employed regular stenographers to report the proceedings of the Convention. "This," the editors explained, "we have done at a very great expense, and without any prospect of remuneration, but simply from a desire to protect the deliberations of so important a body from loss, and to
                preserve them for future history."
              </p>
              <p>
                On the same day, February 20, Mr. Fisher proposed that the Convention should enter into a contract with a Richmond paper the Enquirer—which had already employed two stenographers (the only paper which had employed stenographers) , and was already publishing the regular proceedings of the Convention, including the speeches. Mr. Fisher suggested that the duty of the editors should be
                "impartially to publish not only all the regular proceedings which are ordinarily placed upon the journal, but the speeches made by the members." The Convention agreed to Mr. Fisher's resolution, and authorized its President to contract with the proprietors of the Enquirer "for continuing the reporting and publishing the proceedings of this Convention."
              </p>
              <p>On February 21, the President informed the Convention that he had had an interview with the editors of the Enquirer, and had asked them "to submit a distinct proposition" on the subject of reporting the Convention debates; he had received that proposition, and he presented it to the Convention for such disposition as seemed best.</p>
              <p>
                By February 25 arrangements between the Convention and the Enquirer must have been concluded, for on that day the paper
                <span className="note" id="Note10"
                ><span className="noteLabel">6</span>6. Although the Enquirer was the only newspaper which published the full proceedings of the Convention, the Richmond Dispatch and Whig published abstracts of daily proceedings until the Convention went into secret session on April 16, and the Whig, in addition, published the text of speeches delivered by Mr. Summers on March 11, and by Mr. Bruce on
                  March 23 and March 25. Probably the Richmond Examiner also reported Convention proceedings from the beginning, as it certainly reported the proceedings of February 26-April 16 ; however, no complete file of the Examiner for February 1861 is known to have survived.</span
                ><PageNumber num={11} /> announced that it had entered into a contract "to publish full official reports of the proceedings of the State Convention." However, it was not till February 27 that the President informed the Convention that a contract had been closed with the proprietors of the Enquirer "upon the precise terms indicated in the proposition, which
                was approved by the Convention." Some provisions of the contract may be inferred from references to it in the Convention's Journal and Documents : the Enquirer was to receive $7.50 per column for reporting the debates and proceedings, and it was to provide twenty copies of its semi-weekly edition for each member of the Convention.
              </p>
              <p>The Enquirer's comment of February 20 suggests that it had employed two or more stenographers to cover the Convention, even before the paper became its official reporter, and the Convention Journal and Proceedings for April 16 suggest that several reporters for the Enquirer were still recording proceedings of the Convention when it went into secret session on that day.</p>
              <p>
                When the Convention went into secret session, all reporters except one were ordered to be excluded. The excepted reporter was Patrick Kean, "one of the staff of official reporters," who took an oath of secrecy, and swore that after each session he would deliver "all notes, reports and memoranda, of debates and proceedings." On May 1, the day of adjournment of its first session, the
                Convention resolved that its Secretary should "hand over to the reporter his short-hand notes of the debates in secret session, to enable him to proceed with the transcript thereof but the obligation of secrecy shall continue to rest upon the reporter until the Convention shall remove the same."
              </p>
              <p>
                Proceedings of the Convention in open session were printed in the semi-weekly Enquirer for February 15—May 21, 1861. The length of the proceedings, and above all the length of some speeches, often made it necessary to continue the proceedings of a convention day from one issue to another. Occasionally three or even four issues, not always consecutive ones, were required to complete the
                report of a single session. The proceedings of April 1, for example, were published in four installments : on April 4, April 27, May 3, and May 21.
              </p>
              <p>
                Another cause of delay in the printing of proceedings was indicated <PageNumber num={12} /> in the Enquirer comment of March 30 on errors in reporting. "The only manner in which the publication of such inaccuracies can be avoided, is by delegates revising for themselves the manuscript reports, for which we endeavor to offer every possible facility to
                delegates desirous to avail themselves of the opportunity." The hazards of allowing delegates to revise the reports of their speeches had already been demonstrated. On March 16, the Enquirer announced that reports of the speeches by Mr. Summers and ex-President Tyler on the Peace Conference propositions had been referred to them for revision, and would be published within the next few
                days—Mr. Tyler, in fact, had already returned his speech to the paper, but Mr. Summers had not yet returned his, and the paper wished to publish the two speeches in the same issue, "so both sides may be seen." On March 23, the Enquirer explained that Mr. Summers had kept his speech until March 19, so that it could not be published before March 25. Consequently, Mr. Tyler's speech was
                being kept back. Furthermore, the paper had "held back all speeches subsequently delivered until <span className="persName">Mr. S</span>'s was published, so that they might be published in the order in which they were delivered, and no injustice be done to any one."
              </p>
              <p>Proceedings of the Convention in secret session were printed in the semi-weekly Enquirer from October 15 through December 27. The Convention had removed the injunction of secrecy on its proceedings of late April by its resolution of June 12, but "the indisposition of the Reporter, and other causes" delayed publication until the fall, as the Enquirer explained on October 15.</p>
              <p>
                There seems to be no difference of any consequence between the versions of proceedings published in the daily and in the semiweekly edition of the Enquirer. The semi-weekly apparently used the type already set up for the daily, with whatever changes of column arrangement were required. Collation of the two versions would perhaps reveal some corrections of typographical errors, or small
                revisions desired by delegates. It is assumed that the semiweekly edition, because of its greater margin of time for corrections, published the most nearly definitive text.
              </p>
              <p>
                The text has been re-arranged so that the report of proceedings for each day is presented as a whole, under a uniform heading which gives the session day, the day of the week, and the day of the month. A speech or a part of a speech printed later than the <PageNumber num={13} /> rest of the day's proceedings has been restored to its proper place.
                Sometimes, however, the Enquirer deferred publication of a speech from its proper place in the proceedings, and finally never printed it at all. In these instances the editor has supplied, at the appropriate place in the notes, the abstract published in the Daily Dispatch.
              </p>
              <p>In some instances, a speech actually begun before a recess and continued after it, or delivered on more than one day, has been printed without a break, since there is no indication of the point or points at which the speech was interrupted.</p>
              <p>Editorial insertions indicating gaps or interruptions in the report of a day's proceedings have been deleted, whenever the missing material is supplied. Occasional repetitions of text from earlier proceedings, inserted for clarification, have been deleted.</p>
              <p>For practical reasons, the text of the proceedings was set up in type directly from photostats of the Enquirer. No editorial revisions could be made on the photostats without further defacement of a text already difficult to read.</p>
              <p>In order to keep down the delay and expense of "author's corrections," the editor has endeavored to make as few changes as possible in the text as it appeared in the printer's proofs.</p>
              <p>Obvious misspellings and typographical errors have been corrected without comment, and some obsolete spellings of important words have been changed to the modern form.</p>
              <p>
                Proper names have normally been made to conform with today's standard usage, but a few which differ from today's form in a minor particular, such as an apostrophe, have been left unchanged. The spelling of some proper names could not be established, for they could not be identified. Most of these, being simply rhetorical embellishments, were allowed to stand, without comment, as they
                were printed in the Enquirer. However, any unidentified name which seemed to have a real connection with the speaker's discourse is the subject of an explanatory note.
              </p>
              <p>The erratic and illogical punctuation of the Enquirer has, for the most part, been left unchanged. The dash after a colon and between sentences has been omitted, but other deletions, or insertions or alterations of punctuation, have been made but rarely, and only when needed to clarify the text.</p>
              <p>
                So far as possible, interpolations made by the reporter or editors of the Enquirer, or made by a speaker when quoting some one else, <PageNumber num={14} /> have been put between brackets, while parentheses have been used for other parenthetical matter. But since the Enquirer used brackets and parentheses indiscriminately, it seems impossible to
                distinguish some editorial interpolations from other parenthetical insertions. In case of doubt, brackets are used.
              </p>
              <p>In a few instances, the reporter's statement of action on a delegate's proposal has been printed as if part of the delegate's remarks; the present editor has inserted brackets to separate the reporter's remarks from what the delegate said.</p>
              <p>With the exceptions noted, the proceedings are presented exactly as they were published in the Enquirer. The few notes on the text which appeared in the Enquirer have been printed at the foot of the page, with an asterisk.</p>
              <p>
                Corrections or revisions, except those mentioned above, are put in notes at the end of each volume. Revisions made after publication of the proceedings, by the editors of the Enquirer, or by delegates in letters to the editor, are summarized in the notes, or quoted in full if they are long or complicated. Revisions made by the delegates on the Convention floor are indicated by
                references to the proceedings which include those revisions.
              </p>
              <p>Various records of the Virginia Convention of 1861 which were printed while the Convention was in session, or soon after it adjourned, may interest the reader.</p>
              <p>
                Except for the ordinance on contested elections, mentioned below, ordinances passed in the Convention's first session, with dates of passage, and notes of amendment, re-enactment, and repeal, are found in Ordinances Adopted by the Convention of Virginia, in Secret Session, in April and May 1861. In the proceedings, an ordinance as it was first proposed may be widely separated from
                subsequent amendments to it.
              </p>
              <p>
                The Documents of the Convention of 1861 include communications from persons or bodies outside the Convention, reports of Convention committees, and An Ordinance Touching Contested Elections, Passed by the Convention February 21, 1861. The contents of some Documents were published in the proceedings, but most of the Documents are papers which are only mentioned in the proceedings as
                ordered to be laid upon the table and printed.
              </p>
              <p>
                A journal of the first session of the Convention is included in the Journal of the Acts and Proceedings of a General Convention of the <PageNumber num={15} /> State of Virginia, Assembled at Richmond, on Wednesday, the Thirteenth Day of February, Eighteen Hundred and Sixty-One. This journal is supplemented by A Journalized Record of the Proceedings in
                Committee of the Whole upon Federal Relations (for March 15—April 13 ) , and by Portions of Journal of Secret Session of the Convention, Withheld from Publication at Its Session Ending May 1, 1861.
              </p>
              <p>These three journals may be fitted together to form an outline of the proceedings, and they give the serial number assigned to each report or other paper which was printed as a Document. The journals do not include the speeches which make up so large a part of the proceedings, and very often do not even mention that the speeches were delivered.</p>
              <p>
                The Virginia Historical Society, the Library of Congress, and the libraries of Cornell University, Duke University, the University of Texas and the University of Virginia have given their help to this edition of the Convention proceedings. In particular, the Alderman Library of the University of Virginia has performed the generous and indispensable service of providing the newspapers
                from which ( with trifling exceptions) this text of the Convention proceedings has been compiled.
              </p>
              <p>The editor owes a particular acknowledgement to his colleague, Dr. William H. Gaines, Jr., who has verified the re-arrangement of the text, read the whole of the proceedings in search of errors, and made many invaluable suggestions.</p>
              <div className="signed"><span className="name">GEORGE H. REESE</span></div>
              <PageNumber num={16} />
            </div>
            <div className="synopsis" id="vsc1965.v1.1.4">
              <PageNumber num={17} />
              <h2><span className="head">SYNOPSIS</span></h2>
              <h3>FIRST DAY</h3>
            <div className='synopsisDateLine'><div>Wednesday, February 13</div> <Link to="../27" relative="path">3</Link></div>
              <p>
                The Virginia State Convention assembles in the Hall of the House of Delegates, and elects Mr. Cox as temporary Chairman. Mr. Gordon, Clerk of the House of Delegates, is appointed temporary Secretary. The roll is called. Mr. Janney is elected President of the Convention, and makes a speech of acceptance. Mr. Eubank is elected Secretary. The Convention adopts for the time being the rules
                of the House of Delegates.
              </p>
              <h3>SECOND DAY</h3>
              <div className='synopsisDateLine'><div>Thursday, February 14</div> <Link to="../37" relative="path">13</Link></div>
              <p>The Convention meets in the Hall of the Mechanics' Institute. Mr. Thompson is elected Sergeant-at-arms. Mr. Linkous is elected First Doorkeeper. The Convention receives the credentials of the commissioners from Georgia, Mississippi and South Carolina, and decides that they shall be invited to address the Convention.</p>
              <h3>THIRD DAY</h3>
              <div className='synopsisDateLine'><div>Friday, February 15</div> <Link to="../45" relative="path">21</Link></div>
              <p>
                Mr. Leake is elected Second Doorkeeper. The Convention adopts the rules of the Virginia Convention of 1850, and calls for the appointment of a Committee on Elections and a Committee on Federal Relations. Mr. Elliott is elected Printer to the Convention. The Convention resolves to request a report from the Virginia commissioners at the Peace Conference. Mr. Wise denies accusations that
                he intended to seize Federal property in Virginia, to invade the District of Columbia, and to supplant Governor Letcher; Mr. Stuart disclaims any reference to Mr. Wise.
              </p>
              <h3>FOURTH DAY</h3>
              <div className='synopsisDateLine'><div>Saturday, February 16</div> <Link to="../61" relative="path">37</Link></div>
              <p>Mr. Stuart reverts to his dialogue with Mr. Wise. The President announces</p>
              <p>the members of the Committee on Federal Relations and the</p>
              <p>
                Committee on Elections. Mr. Marr and Mr. Morton offer resolutions <PageNumber num={18} /> against coercion of the seceded states. Mr. Morton speaks in support of his resolution, and Mr. MOORE speaks in opposition. Mr. Wise replies to Mr. MOORE and speaks at length. Messrs. Carlile, Leake, Richardson and Flournoy offer resolutions on coercion, and the
                relation of the states, particularly Virginia, to the Federal Union.
              </p>
              <h3>FIFTH DAY</h3>
              <div className='synopsisDateLine'><div>Monday, February 18</div> <Link to="../74" relative="path">50</Link></div>
              <p>Mr. Anderson, commissioner from Mississippi, addresses the Convention : he justifies the secession of his state, and invites Virginia to the leadership of a Southern Union. Mr. Benning, commissioner from Georgia, speaks in the same vein, with frequent reference to economic considerations.</p>
              <h3>SIXTH DAY</h3>
              <div className='synopsisDateLine'><div>Tuesday, February 19</div> <Link to="../100" relative="path">76</Link></div>
              <p>Mr. Preston, commissioner from South Carolina, addresses the Convention : he explains why his state exercised her right of secession, and urges the people of Virginia to join with South Carolina in protection of their common rights and interests. Mr. Hall offers resolutions asserting Virginia's right of secession, and proposing an ultimatum to the North.</p>
              <h3>SEVENTH DAY</h3>
              <div className='synopsisDateLine'><div>Wednesday, February 20</div> <Link to="../119" relative="path">95</Link></div>
              <p>
                Messrs. Brent, Turner, Morris, Tredway, Nelson, Holcombe, Goode and Fisher offer resolutions on settlement of sectional differences, coercion, movement of Federal forces, and the state of Virginia volunteers and militia. Mr. Branch offers resolutions calling for a meeting of commissioners from the slave-holding states still in the Federal Union; Mr. Chambliss and Mr. Branch comment.
                The Committee on Elections reports the vote on referring the Convention's action on secession, or on amending the Virginia Constitution, to the people. Mr. Bouldin offers a resolution against Federal attempts to reclaim forts and collect revenues in the seceded states.
              </p>
              <h3>EIGHTH DAY</h3>
              <div className='synopsisDateLine'><div>Thursday, February 21</div> <Link to="../146" relative="path">122</Link></div>
              <p>
                The Committee on Elections presents a list of elected delegates, and <PageNumber num={19} /> Mr. Haymond proposes an ordinance on contested elections. Mr. WOODS offers resolutions asserting Virginia's right of secession, and the independence of the seceded states. Messrs. Hall, Wise, Willey, and others discuss the loyalty of the northwestern section of
                Virginia.
              </p>
              <h3>NINTH DAY</h3>
              <div className='synopsisDateLine'><div>Saturday, February 23</div> <Link to="../166" relative="path">142</Link></div>
              <p>Mr. Hall and Mr. Clemens continue discussion of the loyalty of northwestern Virginia. Mr. Tredway explains his resolution on the reinforcement of Federal forts and arsenals in Virginia; Messrs. Barbour, Borst, Early, Carlile, and others comment. Mr. Fisher offers a resolution opposing a national convention.</p>
              <h3>TENTH DAY</h3>
              <div className='synopsisDateLine'><div>Monday, February 25</div> <Link to="../189" relative="path">165</Link></div>
              <p>Messrs. Haymond and Hall offer resolutions on amendment of the Constitution of Virginia. Mr. Chambliss presents the petition of Mr. Collier of Petersburg, calling for a conditional or temporary secession. Mr. MOORE offers various resolutions on the slavery question and speaks at length against alliance with the seceded states; Mr. Goode rises to protest Mr. MOORE's remarks.</p>
              <h3>ELEVENTH DAY</h3>
              <div className='synopsisDateLine'><div>Tuesday, February 26</div> <Link to="../217" relative="path">193</Link></div>
              <p>Mr. Goode continues his reply to Mr. MOORE, and declares that Virginia should at once take her place at the head of the Southern states. Mr. Goggin offers resolutions recommending co-operation with the border slave states, and explains his views at length.</p>
              <h3>TWELFTH DAY</h3>
              <div className='synopsisDateLine'><div>Wednesday, February 27</div> <Link to="../246" relative="path">222</Link></div>
              <p>
                Mr. Fisher suggests an appropriation for defense. Mr. Goggin concludes his remarks. Mr. Sheffey addresses the Convention; he urges a cautious approach to secession, but asserts Virginia's sovereign rights. The Governor communicates the Adjutant General's report on men and arms of the Virginia volunteer force. Mr. Wilson offers a resolution requesting the Auditor of Public Accounts to
                report on State bonds, and on the compensation of justices of the peace and Jurors.
              </p>
              <PageNumber num={20} />
              <h3>THIRTEENTH DAY</h3>
              <div className='synopsisDateLine'><div>Thursday, February 28</div> <Link to="../274" relative="path">250</Link></div>
              <p>
                Mr. Richardson's resolution calls for the Adjutant General to supply information on stores of arms in Virginia, and on the further recruitment of volunteer forces. Mr. Early describes Mr. Wilson's proposal on State bonds as a matter concerning the Assembly, rather than the Convention. Mr. Morton makes a speech in answer to those of Messrs. MOORE and Goggin, and in support of the
                secession of Virginia, and Virginia's joining a Southern Confederacy. Mr. Baylor denounces the Personal Liberty Bills of Northern states, but asserts his attachment to the Federal Union.
              </p>
              <h3>FOURTEENTH DAY</h3>
              <div className='synopsisDateLine'><div>Friday, March 1</div> <Link to="../299" relative="path">275</Link></div>
              <p>
                Mr. Garland proposes a referendum on whether Virginia should secede from the Federal Union and join the Southern Confederacy. Mr. Echols proposes that the U.S. Congress should recognize and treat with the Confederate States of America. Mr. Boisseau offers a resolution in support of the secession of Virginia. Mr. Dorman offers a resolution on dissolution of the Federal Union. Mr. Burley
                presents resolutions of citizens of Marshall County, in favor of the Union. Mr. Baylor resumes his remarks in defense of the Union. Mr. Turner asserts his willingness to advocate a Southern Union. There is discussion of how the commissioners at the Peace Conference shall report to the Convention.
              </p>
              <h3>FIFTEENTH DAY</h3>
              <div className='synopsisDateLine'><div>Saturday, March 2</div> <Link to="../341" relative="path">317</Link></div>
              <p>
                Mr. Turner proposes to adjust the tax on slaves. The Convention discusses the contract with the Enquirer for reporting. Mr. Haymond presents papers on a contested election in Lee County. Mr. Goode argues strongly that Virginia should secede and take her place with the Southern states. Mr. Fisher submits a resolution against Federal claims on revenues or military works in the
                Confederacy.
              </p>
              <h3>SIXTEENTH DAY</h3>
              <div className='synopsisDateLine'><div>Monday, March 4</div> <Link to="../364" relative="path">340</Link></div>
              <p>Mr. Brown proposes a censure of the United States Senators from</p>
              <p>
                Virginia with regard to the Peace Conference. Mr. Chambliss offers <PageNumber num={21} /> resolutions of the people of Botetourt County, advocating the secession of Virginia. Mr. Carlile contends that there has been no popular support for any ordinance of secession, and he commends the Peace Conference propositions. Mr. Mallory proposes a convention of
                the border states, to decide their allegiance. Mr. Willey delivers a long speech in favor of the Federal Union.
              </p>
              <h3>SEVENTEENTH DAY</h3>
              <div className='synopsisDateLine'><div>Tuesday, March 5</div> <Link to="../403" relative="path">379</Link></div>
              <p>
                Mr. Mallory specifies his opposition to a Central Confederacy; Mr. Cox joins him in a proposal for a convention of the border states. Mr. Goggin proposes resolutions asserting Virginia's opposition to Federal coercion, and requesting the co-operation of the border states in a Southern Confederacy. Mr. Harvie insists that Virginia must act independently. Mr. Leake suggests a new
                resolution, providing that Virginia is to secede and then summon a convention of the slave states. Mr. Harvie and Mr. Dorman carry on the discussion of Mr. Leake's resolution, and Mr. Branch protests the number and speed of resolutions.
              </p>
              <h3>EIGHTEENTH DAY</h3>
              <div className='synopsisDateLine'><div>Wednesday, March 6</div> <Link to="../432" relative="path">408</Link></div>
              <p>
                Mr. Osburn offers a resolution condemning secession, and urging patience and moderation. The Committee on Elections gives a further report on the vote for referring Convention action on secession, or on amending the Constitution of Virginia. The select committee reports on movements of Federal arms and men to forts in Virginia. Mr. Flournoy presses for assurance that Virginia will
                resist coercion of the Southern states. The President communicates the reports of the Peace Conference commissioners. Mr. Thornton denounces Lincoln's inaugural address. Mr. Early appeals for deliberation and moderation, but Mr. Goode calls for immediate action. Mr. Grant offers resolutions against coercion, and in favor of a convention of border states.
              </p>
              <h3>NINETEENTH DAY</h3>
              <div className='synopsisDateLine'><div>Thursday, March 7</div> <Link to="../464" relative="path">442</Link></div>
              <p>
                Mr. Brown proposes the appointment of a committee on finance, and refers to inequities in taxation. Mr. Nelson offers another resolution opposing Federal attempts to repossess property or collect revenues in the seceded states. A committee reports on publication of Convention <PageNumber num={22} /> proceedings. Mr. Carlile makes a very long speech
                against secession, and criticizes its advocates in the Convention and on the Enquirer. Mr. Cox replies, deploring the action of the seceded states, opposing coercion, and placing his hopes of peace on the border states.
              </p>
              <h3>TWENTIETH DAY</h3>
              <div className='synopsisDateLine'><div>Friday, March 8</div> <Link to="../510" relative="path">486</Link></div>
              <p>
                The Secretary reads letters between Messrs. Early and Goode. Mr. Wysor proposes an ordinance of secession. Mr. Campbell presents resolutions of citizens of Washington' County, in favor of immediate secession. Mr. Brent speaks at length on disunionist activity and slavery, urging Virginia to mediate between North and South. Mr. Ambler accuses the Convention of being dilatory, and
                reminds the delegates that eastern Virginia has sacrificed its interests to western Virginia.
              </p>
              <h3>TWENTY-FIRST DAY</h3>
              <div className='synopsisDateLine'><div>Saturday, March 9</div> <Link to="../547" relative="path">523</Link></div>
              <p>
                The Committee on Federal Relations submits a partial report. Mr. Marye presents resolutions of the citizens of Fredericksburg calling on Virginia to aid the seceded states, to work for a government of all the southern slaveholding states, and to secede, if she must, from the Union; he defines his own convictions. Mr. Wise presents a long statement of dissent from the report of the
                Committee on Federal Relations; Mr. Harvie voices agreement with Mr. Wise, and submits a minority report calling for an ordinance of secession. Mr. Barbour offers another minority report, proposing a conference with the Confederate government.
              </p>
              <h3>TWENTY-SECOND DAY</h3>
              <div className='synopsisDateLine'><div>Monday, March 11</div> <Link to="../569" relative="path">545</Link></div>
              <p>The Convention votes to thank Senator Crittenden for his attempts at conciliation. Mr. Summers begins a long speech in support of the Peace Conference. Mr. Baldwin and Mr. Wickham submit two more minority reports from the Committee on Federal Relations.</p>
              <h3>TWENTY-THIRD DAY</h3>
              <div className='synopsisDateLine'><div>Tuesday, March 12</div> <Link to="../609" relative="path">585</Link></div>
              <p>Mr. Goggin presents the proceedings of meetings of citizens of Bedford <PageNumber num={23} /> County, calling for secession. Mr. Sheffey presents proceedings of a meeting in Smyth County, also calling for secession. Mr. Tarr offers resolutions opposing the Southern position. Mr. Summers concludes his speech on the Peace Conference.</p>
              <h3>TWENTY-FOURTH DAY</h3>
              <div className='synopsisDateLine'><div>Wednesday, March 13</div> <Link to="../654" relative="path">630</Link></div>
              <p>Mr. WOODS presents resolutions of the citizens of Barbour County, approving states' rights and secession. Mr. Morris presents resolutions of citizens of Caroline. County, urging the Convention to action, and calling for secession. Mr. Tyler speaks at length on the Peace Conference, in reply to Mr. Summers.</p>
              <h3>TWENTY-FIFTH DAY</h3>
              <div className='synopsisDateLine'><div>Thursday, March 14</div> <Link to="../681" relative="path">655</Link></div>
              <p>
                Mr. Neblett presents resolutions of the people of Lunenburg County, in favor of immediate secession. Mr. Kent offers similar resolutions from the people of Wythe County. Mr. Tyler concludes his remarks on the Peace Conference. Mr. Conrad moves that the majority report of the Committee on Federal Relations be considered in Committee of the Whole. Mr. Wise then moves that the minority
                reports also be referred to the Committee. The Convention agrees. Mr. Richardson presses his request for a report by the Adjutant General on arms and manpower in Virginia.
              </p>
              <h3>TWENTY-SIXTH DAY</h3>
              <div className='synopsisDateLine'><div>Friday, March 15</div> <Link to="../714" relative="path">690</Link></div>
              <p>
                Mr. Fisher lays before the Convention the resolutions of citizens of Northampton County, recommending immediate secession. Mr. Richardson again moves his resolution on arms and manpower. Messrs. Goode, Wysor and Forbes submit resolutions from Mecklenburg, Pulaski and Buckingham counties, all in favor of secession. Mr. Conrad presents the majority report of the Committee on Federal
                Relations, and speaks till adjournment.
              </p>
              <h3>TWENTY-SEVENTH DAY</h3>
              <div className='synopsisDateLine'><div>Saturday, March 16</div> <Link to="../741" relative="path">717</Link></div>
              <p>Mr. Goggin proposes an ordinance to declare the secession of Virginia</p>
              <p>
                and recognition of the Confederate States. Mr. Holladay presents <PageNumber num={24} /> the petition of citizens of Norfolk County, begging the Convention to stand by the Union. Mr. Burley offers resolutions supporting the Unionist position. Mr. Randolph begins a long speech on the report of the Committee on Federal Relations, emphasizing the commercial
                advantages of secession. Mr. Willey proposes resolutions calling for an equitable system of taxation on slaves.
              </p>
            </div>
          </section>
        </div>
        <div className="tei_body" id="v1body">
          <section className="body" id="vsc1965.v1.2">
            <PageNumber num={25} />
            <header>
              <h1><span className="headingNumber">1. </span><span className="head">VOLUME ONE</span></h1>
            </header>
            <p>Proceedings: February 13-March 16</p>
            <PageNumber num={26} />
            <p>PROCEEDINGS</p>
            <div className="day" id="vsc1965.v1.2.1">
              <h2><span className="headingNumber">1.1. </span><span className="head">FIRST DAY</span></h2>
              <div className="dateline">Wednesday, <span className="date">February 13</span></div>
              <p>
                The Virginia State Convention met this day at 12 o'clock, in the Hall of the House of Delegates, in conformity with the requirements of the law authorizing the election of delegates to that body. The Hall was necessarily thronged, having to accommodate not only the members of the Convention, but the members of the General Assembly, who took positions in the lobby, and other available
                points within the Hall. The galleries were crowded, and, in fact, every point, in and out of the Hall, within hearing distance. One gallery was reserved exclusively for ladies, a large number of whom were present during the session of the Convention.
              </p>
              <p>In order to enable the members of that body to accommodate themselves to seats, in sufficient time to be prepared to proceed to business when the hour for assembling arrived, the House of Delegates adjourned at about twenty minutes before twelve.</p>
              <p>When that hour arrived,</p>
              <div className="speaker" id="sp0"><span className="persName">ROBT. E. SCOTT</span>, of Fauquier, called the Convention to order, and addressed the body as follows :</div>
              <p className="p-in-sp">
                Gentlemen of the Convention, at the request of sundry members, I rise for the purpose of calling this body to order; and with a view to its organization, it has been suggested that we appoint a temporary Chairman to preside over the deliberations of the body ; and, if it meets the approbation of the Convention, I propose for that office <span className="persName">Mr. JAMES H. COX</span>,
                of Chesterfield, and if no other name is put in nomination, I will take the liberty of submitting the name of that gentleman to the vote of the Convention.
              </p>
              <p>The question having been taken on the motion, it was unanimously agreed to.</p>
              <p>Messrs. <span className="persName">SUMMERS</span> and <span className="persName">PATRICK</span>, of Kanawha, were appointed a committee to inform Mr. Cox of his election to the temporary Chairmanship, and to conduct him to the Chair.</p>
              <PageNumber num={28} />
              <p>That duty having been performed, and Mr. Cox having taken the Chair, said :</p>
              <div className="speaker" id="sp1">[<span className="persName">Mr. COX</span>:]</div>
              <p className="p-in-sp">Gentlemen of the Convention, permit me to offer to you my profound acknowledgments for the distinguished honor which you have conferred upon me, by calling upon me to act—temporarily merely—as the President of this august body.</p>
              <p className="p-in-sp">
                Gentlemen, you hold in your hands the destinies of this great nation. Upon the wisdom of your deliberations, I verily believe, depends not only the honor and safety of this good old Commonwealth, but the prosperity of our Republican institutions. I need not remind you that "order is nature's first law." When I behold those who are before me, when I contemplate the magnitude of the
                occasion which assembled us, I know that order will reign supreme, and that during your deliberations the least important of all the duties which your presiding officer will have to perform will be that of keeping order.
              </p>
              <p className="p-in-sp">Permit me again, gentlemen, to return you my heartfelt thanks for the honor you have conferred upon me, and allow me to express a hope that your deliberations will terminate in honor to yourselves and this good old Commonwealth, and the conciliation of this great Republic.</p>
              <div className="speaker" id="sp2"><span className="persName">Mr. SCOTT</span>—</div>
              <p className="p-in-sp">In order to proceed further with the objects of the Convention, it is necessary to appoint a temporary Secretary, and for that office, I now present the name of Wm. F. Gordon, Jr., Clerk of the House of Delegates.</p>
              <p>The motion was agreed to unanimously, whereupon, a call of the roll was ordered, and the following members answered to their names :</p>
              <p>
                Accomac, Wm. H. B. Custis; Albemarle, James P. Holcombe : Alexandria, George W. Brent; Alleghany and Bath, Thos. Sitlington: Amelia and Nottoway, Lewis E. Harvie; Amherst, Samuel M. Garland : Appomattox, Lewis D. Isbell; Augusta, A. H. H. Stuart, John B. Baldwin, George Baylor; Barbour, Samuel WOODS; Bedford, Wm. L. Goggin; Berkeley, Allen C. Hammond ; Brooke, Campbell Tarr; Brunswick,
                James B. Mallory; Buckingham, Wm. W. Forbes; Cabell, Wm. McComas; Campbell, John M. Speed, Charles R. Slaughter; Caroline, Edmund T. Morris; Charlotte, Wood Bouldin; Chesterfield, Jas. H. Cox ; Clarke, Hugh M. Nelson ; Culpeper, James Barbour; Cumberland and Powhatan, Wm. C. Scott; Dinwiddie, James Boisseau; Doddridge and Tyler, C. J. Stuart; Elizabeth City, Warwick, York and
                Williamsburg, Chas. K. Mallory; Essex and King &amp; Queen, Richard H. Cox; Fairfax, Wm. H. Dulany; Fauquier, Robert E. Scott, John Q. Marr; Fluvanna, James M. Strange; Floyd, Harvey Deskins; Frederick, <PageNumber num={29} /> Robert Y. Conrad, James Marshall; Goochland, Walter D. Leake: Gilmer, Wirt and Calhoun, C. B. Conrad; Greene and Orange, Jeremiah
                Morton; Greenbrier, Samuel Price; Greenesville and Sussex, J. R. Chambliss; Halifax, Thomas S. Flournoy; Hampshire, Edward M. Armstrong, David Pugh; Hancock, George McC. Porter; Hanover, George W. Richardson; Hardy, Thomas Maslin; Harrison, John S. Carlile, Benjamin Wilson; Henrico, Williams C. Wickham ; Henry, Peyton Gravely; Highland, George W. Hull; Isle of Wight, Robert H.
                Whitfield; Jackson and Roane, Franklin P. Turner; Jefferson, Alfred M. Barbour, Logan Osburn; Kanawha, George W. Summers, Spicer Patrick; King George and Stafford, Edward Waller; King William, Fendall Gregory, Jr.; Lancaster and Northumberland, Addison Hall; Lee, John D. Sharp; Lee and Scott, Peter C. Johnston; Lewis, Caleb Boggess; Loudoun, John A. Carter; Louisa, Wm. M. Ambler;
                Lunenburg, W. J. Neblett; Madison, Angus R. Blakey; Marion, Alpheus F. Haymond, Ephraim B. Hall; Marshall, James Burley; Mason. James H. Couch; Matthews and Middlesex, Robert L. Montague : Mecklenburg, Thomas F. Goode; Mercer, Napoleon B. French: Monongalia, Waitman T. Willey, Marshall M. Dent; Monroe, Allen T. Caperton, John Echols; Montgomery, Wm. Ballard Preston; Morgan, Johnson
                Orrick ; Nansemond, Jno. R. Kilby; Nelson, Frederick M. Cabell; Norfolk County, Wm. White, J. G. Holladay; Northampton, Miers W. Fisher; Ohio, Sherrard Clemens, Chester D. Hubbard ; Page, Peter B. Borst; Patrick, Samuel G. Staples; Pendleton, Henry H. Masters; Pocahontas, Paul McNeil; Petersburg, Thomas Branch; Pittsylvania, Wm. T. Sutherlin, Wm. M. Tredway; Pleasants and Ritchie,
                Cyrus Hall; Preston, William G. Brown, James C. McGrew; Prince Edward, Jno. T. Thornton; Prince William, Eppa Hunton; Putnam, Jas. W. Hoge; Randolph and Tucker, John N. Hughes; Rappahannock, Horatio G. Moffett, Richmond city, William H. Macfarland, Marmaduke Johnson, George W. Randolph; Richmond county and Westmoreland, John Critcher; Rockbridge, Samuel McD. MOORE, James B. Dorman;
                Rockingham, Samuel A. Coffman, John F. Lewis; Scott, Colbert C. Fugate; Shenandoah, Samuel C. Williams, Raphael M. Conn; Smyth, James W. Sheffey; Southampton, John J. Kindred; Warren, Robert H. Turner; Wayne, Burwell Spurlock; Wetzel, L. S. Hall; Wood, John J. Jackson.<span className="note" id="Note11"
                ><span className="noteLabel">1</span>The list of members answering the roll call, as given in the Convention Journal, includes also Valentine W. Southall of Albemarle, John Janney of Loudoun, and George W. Berlin of Upshur. The Journal gives a supplementary list of 23 members who appeared and took their seats after the roll was called on the first day, and the belated appearance of a few
                  more delegates is recorded in the Journal for succeeding days.</span
                >
              </p>
              <div className="section" id="vsc1965.v1.2.1.1">
                <h3><span className="headingNumber">1.1.1. </span><span className="head">NOMINATIONS FOR PRESIDENT</span></h3>
                <p>The Chair informed the Convention that the next business in order was the election of a permanent President.</p>
                <PageNumber num={30} />
                <div className="speaker" id="sp3"><span className="persName">Mr. GEORGE W. SUMMERS</span> said:</div>
                <p className="p-in-sp">
                  Mr. Chairman, for the purpose of effecting the permanent organization of this body, in part at least, I rise for the purpose of making a nomination for the Presidency of the Convention. I submit the name of John Janney of the county of Loudoun. I am sure it will not be expected that I should enlarge at all upon the fitness of this nomination. The name which I present will be found
                  the synonym of purity of personal character, wisdom in council, and fidelity to the Commonwealth under all circumstances.
                </p>
                <p className="p-in-sp">I move sir, the election of John Janney of Loudoun. as President of this body.</p>
                <div className="speaker" id="sp4"><span className="persName">Mr. THOMAS S. FLOURNOY</span> said:</div>
                <p className="p-in-sp">
                  Mr. Chairman, I rise for the purpose of putting in nomination for the Presidency of this Convention VALENTINE W. SOUTHALL, of the county of Albemarle. <span className="persName">Mr. SOUTHALL</span> is well known throughout the Commonwealth of Virginia, and his experience as a presiding officer in deliberative bodies, renders him, in my judgment, peculiarly fit to be appointed to the
                  Presidency of this Convention. In presenting the name of <span className="persName">Mr. SOUTHALL</span>, I would not say a word in disparagement of the claims of the distinguished gentleman whose name has been presented by the gentleman from Kanawha,
                 [<span className="persName">Mr. SUMMERS</span>] . I concur fully in the short eulogy which he pronounced upon that gentleman. Without going further, I submit to this body the name of VALENTINE W. SOUTHALL, for the office of President of this Convention.</p>
                <p>The roll was then called and resulted as follows :</p>
                <p>
                  For JOHN JANNEY-Messrs. Custis, Brent, Sitlington, Stuart, Baldwin, Baylor, Pendleton, Hammond, Tarr, McComas, Cox, Nelson, Stuart, Dulany, Scott of Fauquier, Marshall, Price, Armstrong, Pugh, Porter, Maslin, Carlile, Wilson, Wickham, Hull, Whitfield, Barbour, Osburn, Summers, Patrick, Waller, Hall of Lancaster and Northumberland, Sharp, Boggess, Carter, Haymond, Hall of Marion,
                  Burley, Couch, French, Willey, Dent, Echols, Orrick, Kilby, White, Holladay, Clemens, Hubbard, Masters, McNeil, Tredway, Brown, McGrew, Hoge, Hughes, Moffett, Macfarland, Johnson of Richmond city, Critcher, MOORE, Dorman, Coffman, Lewis, Fugate, Burdett, Berlin, Spurlock, Hall of Wetzel, Jackson-70.
                </p>
                <p>
                  For VALENTINE W. SOUTHALL-Messrs. Holcomb, Harvie, Garland, Isbell, WOODS, Goggin, Mallory of Brunswick, Forbes, Speed, Slaughter, Morris, Bouldin, Barbour of Culpeper, Scott of Cumberland and Powhatan, Boisseau, Mallory of Elizabeth City, Warwick, York, and Williamsburg, Cox of Essex and King and Queen, Marr, Strange, Deskins, Conrad of Frederick, Leake, Conrad of Gilmer, Wirt and
                  Calhoun, Flournoy, Bruce, Richardson, Gravely, Turner, Gregory, Johnston of Lee and Scott, Ambler, Neblett, Blakey, Montague, Goode of Mecklenburg, Caperton, Preston, Cabell, Fisher, Borst, Staples, Branch, Sutherlin, Hall of Pleasants and Ritchie, Thornton, Hunton, Randolph, Williams, Conn, Sheffey, Kindred, Marye, Turner of Warren-54.
                </p>
                <PageNumber num={31} />
                <p><span className="persName">Mr. JANNEY</span> having a majority of the votes cast, was declared duly elected.</p>
                <p>The Chair appointed Messrs. <span className="persName">SUMMERS</span> and <span className="persName">FLOURNOY</span> as a Committee to inform <span className="persName">Mr. JANNEY</span> of his election and conduct him to the Chair.</p>
                <div className="speaker" id="sp5"><span className="persName">Mr. JANNEY</span> having taken the Chair, said :</div>
                <p className="p-in-sp">Gentlemen of the Convention, I did not realize the fact until within the last two hours that I might probably be called to this position, and I have, therefore, none of the products of my head to give you. But my heart is full, and from that pardon me for addressing you very briefly.</p>
                <p className="p-in-sp">I tender you my cordial and sincere thanks for the honor you have conferred upon me, and not only upon me, but upon the county which I in part represent here, in calling me to preside over the deliberations of this, by far the most important, Convention that has assembled in this Commonwealth since the year '76.</p>
                <p className="p-in-sp">
                  Gentlemen, I am without experience—I am without knowledge of parliamentary law, or the rules of order which govern bodies of this sort, and have, therefore, nothing in return for your kindness to promise you but fidelity and impartiality. Errors, I know, I must commit, but as they shall be errors of the head and not of the heart, in your kindness I shall find an excuse for them, and
                  in the wisdom of this body their prompt correction.
                </p>
                <p className="p-in-sp">
                  It is now, gentlemen, seventy-three years since a Convention of the people of this Commonwealth was assembled in this hall for the purpose of ratifying the Constitution of the United States, and one of the main objects of that instrument was to consolidate, not the Government, but the Union of these States. Causes which I do not mean to enumerate, causes which have passed and are
                  daily passing into a history, which will set its seal upon them all, have brought that Constitution and this Union into imminent peril, and Virginia has come to-day to the rescue of them; and well she might. It is what the whole country expected at her hands, because that Constitution and that Union are two of the many proud monuments of her own glory. Her honored son who lies at
                  Mount Vernon, the political Mecca of all future ages, was the President of the body that framed it; and another of her illustrious sons whose brow wore the crown with the civic wreath that will never fade, and who lies now in the county of Orange. was the chief architect of that instrument, and five of her own native sons have been called to the chief magistracy of the Government
                  which they aided in founding. It cannot be then, gentlemen, that a government thus founded, thus administered by our own sons, can now be <PageNumber num={32} /> permitted to fall without bringing either reproach upon the wisdom of its founders, or upon our own virtue, intelligence and patriotism.
                </p>
                <p className="p-in-sp">
                  Gentlemen, there is an old flag, the flag of the Union, which has been borne in triumph now for nearly a century through the battle and the breeze, and that flag now floats over this Capitol, with a star upon it representing this ancient Commonwealth of ours. God grant that it may remain there for ever, and I am sure that I but utter the sentiments of every man in this body, when I
                  utter from the bottom of my heart that prayer. But, gentlemen, it must remain there with its crowning lustre undimmed and untarnished.
                 [Applause.] 
                  We shall ask here for all the rights, and all the privileges, and full equality with the citizens of the Empire States of New York, Pennsylvania and Ohio; but in doing that we will scorn to demand one jot or tittle more than we are willing cheerfully to concede to the citizens of our little sister States, Delaware and Rhode Island. Rhode Island! Heaven bless her—a little State with a
                  heart big enough for a whole Continent ; and I pray God that the example which she set but the other day may be followed, and rapidly followed, by all her sisters of the North.
                </p>
                <p className="p-in-sp">
                  Gentlemen, the responsibility resting upon this body is an awful one. When I agreed to be a candidate for this Convention—when I said to my constituents, if it was their pleasure to give to me their unsolicited suffrages for a seat in this body, I would serve them—I said it with fear and trembling. The people have said they will revise our work ; but still, the consequences of our
                  action here, although subject to that revision, may be full of good or full of evil. We may, I trust—and I trust in God we shall—so conduct our measures here, and bring them to such a conclusion, as that some of our sister States of the South who, for what they believe to be just cause, have wandered a little from their orbits, may be brought back into the old constellation, to give
                  and receive light to and from their old sisters. I am not without hope that even old Massachusetts, when she comes to remember the past for she has a past as well as a present—when she comes to remember whose sword it was that was first torn from the scabbard upon her own soil, and never returned to the shield until her independence and liberty were secured, and remembers from whence
                  he came, will waken up to a sense of justice, and, following the example of her sister, Rhode Island, will expunge from her statute book that which her own wisest and best citizens say is a disgrace to it.
                </p>
                <p className="p-in-sp">
                  Gentlemen, this is no party Convention. It is our duty in considering the subjects that will come before us, to elevate ourselves into an atmosphere <PageNumber num={33} /> where party passion and party prejudice cannot live, and to conduct our proceedings in a manner, as I doubt not we shall, that will reflect credit upon all. I hope and trust that the
                  result of our labors may redound to the good of the State and of the Union.
                 [Applause.] <span className="persName">The PRESIDENT</span> stated that the next business in order, to be the election of a Secretary, whereupon—</p>
                <div className="speaker" id="sp6"><span className="persName">Mr. TREDWAY</span> said,</div>
                <p className="p-in-sp">
                  I rise to place in nomination for the office of Secretary, <span className="persName">Mr. S. D</span>. Whittle. I need not recommend to you or to those members of the Convention that occupied seats in the Convention of 1850 the name of <span className="persName">Mr. WHITTLE</span>. To them I know sir, I need not give any assurance of his fitness for this office, and his fidelity in the
                  discharge of the duties of that office. His intelligence, his promptness, his activity in the discharge of all the duties of that office will be attested I know, by all who have had an opportunity, as members of the Convention of 1850 have had of witnessing the manner in which he discharged all its duties, and his deportment, personally and officially towards the members of that
                  body. I will, therefore, go no further than to say that his experience as the Secretary of the last Convention eminently qualifies him for the discharge of the duties which may devolve upon him as the Secretary of this Convention, should it be the pleasure of this body to elect him.
                </p>
                <div className="speaker" id="sp7"><span className="persName">Mr. PATRICK</span>, of Kanawha, said :</div>
                <p className="p-in-sp">
                  I beg leave, sir, to present for the office of Secretary to this Convention, the name of GREEN PEYTON, of the county of Albemarle. I am assured, sir, by those who know him well, that he possesses the highest qualifications to discharge the duties that would devolve upon that official, and I therefore, without saying anything further, present his name to the Convention with great
                  confidence that if he shall be the choice of this body, he will efficiently perform the duties of the office.
                </p>
                <div className="speaker" id="sp8"><span className="persName">Mr. A. M. BARBOUR</span> said :</div>
                <p className="p-in-sp">
                  Mr. President, I beg leave to nominate for the office of Secretary to this Convention <span className="persName">Mr. SAMUEL T. WALKER</span>, of the county of Rockingham. That gentleman is known to all the public men of the State of Virginia, and certainly no eulogy can be needed for any man who is endorsed on this floor by the sturdy constituency of that great and noble county of
                  Rockingham. <span className="persName">Mr. WALKER</span> is a man of ability and experience. He comes from the Valley of Virginia, and I appeal to the magnanimity of this body to give us a man from the Valley as we have given a man from the East. I make no sectional distinction here, sir. I would cut this arm off, sir, before on the threshold of this Convention I would perpetrate one
                  act, or say one word that would bring discord or acrimony in such a Convention<PageNumber num={34} /> as this, because I hope that in its deliberations, first of all, we will look to the honor and integrity of the Commonwealth of Virginia. We sit here in a body of gentlemen chosen by the sovereign vote of the people to represent them, and the matters
                  which will probably come up for consideration will be of a character too important to admit of strife or division. I will not introduce sectional strife here, and did not intend to do so when I made reference to the locality whence the gentleman came whom I put in nomination. I will now conclude by presenting the name of S. T. WALKER for the office of Secretary of this Convention.
                </p>
                <p>
                  <span className="persName">Mr. JAMES BARBOUR</span> then nominated Zephaniah T. Turner of the county of Culpeper,<span className="note" id="Note12"><span className="noteLabel">2</span>The Journal gives Mr. Turner's county as Rappahannock.</span> with a short eulogy upon his fitness for the position.
                </p>
                <p><span className="persName">Mr. SOUTHALL</span> seconded the nomination of Mr. Green Peyton.</p>
                <p><span className="persName">Mr. McDOWELL MOORE</span> nominated <span className="persName">Mr. J. L</span>. Eubank of Richmond.</p>
                <p><span className="persName">Mr. GARLAND</span> seconded the nomination.</p>
                <p><span className="persName">Mr. R. E. SCOTT</span> seconded the nomination of Mr. Turner.</p>
                <p><span className="persName">Mr. WALTER D. LEAKE</span> nominated S. Bassett French of Chesterfield.</p>
                <p><span className="persName">Mr. MACFARLAND</span>, of Richmond. advocated the claims of Mr. Eubank.</p>
                <p><span className="persName">Mr. MONTAGUE</span> nominated John Bell Bigger, of Richmond.</p>
                <p>
                  <span className="persName">Mr. WICKHAM</span> nominated R. Lindsey<span className="note" id="Note13"><span className="noteLabel">3</span>The Journal gives the name as Lindsay.</span> Walker of New Kent.
                </p>
                <p><span className="persName">Mr. FORBES</span> advocated Mr. Eubank's claims.</p>
                <p><span className="persName">Mr. MOFFETT</span> supported the nomination of Mr. Turner.</p>
                <p>The Clerk then proceeded to call the roll, with the following result :</p>
                <p>Z. T. Turner 28, S. T. Walker 19, J. L. Eubank 16, Green Peyton 11, R. L. Walker 11, J. B. Bigger 9, S. B. French 8, S. D. Whittle 6. Mr. Whittle's name being lowest on the list was withdrawn. The second ballot was then proceeded with and resulted :</p>
                <p>Green Peyton 12, S. T. Walker 29, Z. T. Turner 34, J. L. Eubank 27, S. B. French 19, J. B. Bigger 6, R. L. Walker 12—no choice.</p>
                <p><span className="persName">Mr. CONRAD</span> moved, in order to save time, that the candidate having the highest vote in the next ballot be declared elected. Lost.</p>
                <p>The third ballot was then proceeded with, as follows, Bigger being dropped :</p>
                <p>Green Peyton 8, S. T. Walker 23, Z. T. Turner 35, J. L. Eubank 35, S. B. French 9, R. L. Walker 11—no choice.</p>
                <div className="speaker" id="sp9"><span className="persName">Mr. S. G. STAPLES</span>, of Patrick, said,</div>
                <p className="p-in-sp">in order to expedite the organization of the Convention, I move that all except the two highest candidates be dropped.</p>
                <p>The names of Messrs. French, Peyton and S. T. Walker having been drawn, <span className="persName">Mr. STAPLES</span> withdrew his motion.</p>
                <p>The fourth ballot was then proceeded with, and resulted as follows :</p>
                <PageNumber num={35} />
                <p>Z. T. Turner 51, J. L. Eubank 58, R. L. Walker 19.</p>
                <p>No choice—neither getting a majority of all the votes cast.</p>
                <p><span className="persName">Mr. WALKER</span> having been dropped, the contest was reduced to two—Messrs. <span className="persName">Z. T. TURNER</span> and <span className="persName">J. L. EUBANK</span>-and the roll being called, resulted as follows :</p>
                <p>
                  For EUBANK-Messrs. Southall, Holcombe, Sitlington, Harvie, Garland, Isbell, Stuart, Baldwin, Baylor, Goggin, Pendleton, Boyd, James B. Mallory, Forbes, Speed, Slaughter, Bouldin, W. C. Scott, Boisseau, Chas. K. Mallory, Cox, Gillespie, Strange, Early, Saunders, Deskins, Chapman, Leake, Park, Price, Chambliss, Flournoy, Armstrong, Pugh, Richardson, Maslin, Wickham, Hull, Osburn,
                  Addison Hall, Sharp, Peter C. Johnston, Burley, French, Caperton, Echols, Preston, Orrick, Kilby, Cabell, Clemens, Hubbard, Staples, Masters, McNeil, Branch, Sutherlin, Thornton, Wysor, Macfarland, Marmaduke Johnson, Randolph, Critcher, Tayloe, MOORE, Coffman, Lewis, Ashton, Fugate, Conn, Sheffey, Cecil, Berlin, Grant, Campbell, Jackson, Kent-77.
                </p>
                <p>
                  For TURNER-Messrs. Custis, Brent, WOODS, John Goode, Hammond, Tarr, McComas, James H. Cox, Nelson, James Barbour, Stuart, Dulany, Robert E. Scott, Marr, Morton, Bruce, Porter, Carlile, Wilson, Gravely, Whitfield, F. P. Turner, A. M. Barbour, Summers, Patrick, Waller, Gregory, Boggess, Janney, Carter, Blakey, Haymond, E. B. Hall, Couch, Montague, T. F. Goode, Willey, Dent, White,
                  Holladay, Fisher, Borst, Tredway, Cyrus Hall, Brown, McGrew, Hunton, Hoge, Hughes, Moffett, Dorman, Gray, Kindred, Marye, Burdett, R. H. Turner, Spurlock, L. S. Hall-59.
                </p>
                <p><span className="persName">Mr. EUBANK</span> having a majority, was declared duly elected.</p>
                <div className="speaker" id="sp10"><span className="persName">Mr. SCOTT</span>, of Fauquier, said,</div>
                <p className="p-in-sp">we are but temporary occupants of the seats in the Hall. It is understood that the Legislature have provided accommodations for the Convention at the Mechanics' Hall. Therefore, I move, that when this Convention adjourn it adjourn to meet at 12 o'clock tomorrow in the Mechanics' Hall. Agreed to.</p>
                <div className="speaker" id="sp11"><span className="persName">Mr. MORTON</span>, of Orange and Greene, said :</div>
                <p className="p-in-sp">
                  We all believe that the hairs of our heads are numbered. We believe that a sparrow cannot fall to the ground without a special Providence. We believe that God rules in the armies of Heaven as well as among the nations of earth ; I, therefore, think it eminently proper that we should follow in the footsteps of our ancestors; and, on an occasion like this, when we are deliberating not
                  only upon the happiness of our State but the peace of this country, that the proceedings of this Convention should be opened with prayer. I therefore submit the following resolution :
                </p>
                <p>Resolved, That the sittings of this Convention be daily opened with prayer, and that the President request the clergy of this city to officiate. Agreed to.</p>
                <div className="speaker" id="sp12"><span className="persName">Mr. CLEMENS</span></div>
                <p className="p-in-sp">-I move that the rules of the House of Delegates, so far as the same may be applicable, be adopted for the government of this Convention until otherwise ordered.</p>
                <PageNumber num={36} />
                <p>The motion was agreed to.</p>
                <p><span className="persName">Mr. PRICE</span>, of Greenbrier, offered the following resolution : Resolved, That no member shall be regarded as entitled to any seat which he is not immediately occupying.</p>
                <p><span className="persName">Mr. MONTAGUE</span> moved to lay the resolution on the table.</p>
                <p>The motion was agreed to, whereupon, on motion of <span className="persName">Mr. SCOTT</span>, of Fauquier, the Convention adjourned.</p>
              </div>
            </div>
            <div className="day" id="vsc1965.v1.2.2">
              <PageNumber num={37} />
              <h2><span className="headingNumber">1.2. </span><span className="head">SECOND DAY</span></h2>
              <div className="dateline">Thursday, <span className="date">February 14</span></div>
              <p>The Convention met today in the Hall of the Mechanics' Institute, at 12 o'clock.</p>
              <p>Long before the meeting of the Convention, all the available space allotted to spectators was filled.</p>
              <p>
                The President's chair is located on the side of the Hall, the members' seats being arranged in front in semi-circular order. The space for the Convention is enclosed with iron railings, the margins outside on either end of the Hall being assigned for spectators. The original platform at the inner end of the Hall, now on the right of the President is reserved for ladies, and the gallery
                on the left, or immediately above the entrance with the intervening space between the door way and iron railing, for gentlemen.
              </p>
              <p>The proceedings were opened with prayer by the Rev. <span className="persName">Mr. DUNCAN</span>, of the Methodist Church.</p>
              <p><span className="persName">Mr. MACFARLAND</span>, after a few remarks on the delay and embarrassment resulting from an election of officers by the Convention. as evidenced on yesterday, offered the following resolution :</p>
              <p>Resolved, That the President of the Convention be authorized to appoint the officers necessary to complete the organization of this body, to consist of a Sergeant-at-Arms, two door keepers and four pages.</p>
              <div className="speaker" id="sp13"><span className="persName">The PRESIDENT</span> said :</div>
              <p className="p-in-sp">Before the resolution is reported, the Chair will beg leave to make a few remarks.</p>
              <p className="p-in-sp">
                When I arrived in this city, I was informed that the universal practice of the Legislature had been for the body itself to appoint their officers. I consider myself, gentlemen, standing upon the same level with each one of you—that I should vote, as a member of the House, for these various officers, and in that connection I have committed myself to certain gentlemen in favor of these
                offices. If you refuse, gentlemen, to perform this duty, you will have to change the venue to get a fair trial.
               [Laughter.] I confess, although I might possibly do justice to these gentlemen, it would be, it seems to me, impossible for me to satisfy everybody that justice would be done. I beg to ask the gentleman to withdraw his resolution and relieve me from this embarrassment.</p>
              <div className="speaker" id="sp14"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">
                I concur entirely in the motive which prompted the gentleman to offer the resolution. I think it would be beneath the dignity and purpose which brought this Convention together to go on <PageNumber num={38} /> here wrangling over these offices. To remedy this, sir, I moved as an amendment to this resolution that a Committee of fifteen—five from the West,
                five from the Valley, and five from the tidewater region be appointed to whom the election of the remaining officers, to complete the organization, may be entrusted. The Committee to report to the President the result of their action.
              </p>
              <p><span className="persName">Mr. PATRICK</span>, of Kanawha, moved to lay the resolution and amendment on the table. Agreed to.</p>
              <div className="section" id="vsc1965.v1.2.2.1">
                <h3><span className="headingNumber">1.2.1. </span><span className="head">ELECTION OF SERGEANT-AT-ARMS</span></h3>
                <span className="note" id="Note14"><span className="noteLabel">1</span>This account of the nomination and election of the Sergeant-at-Arms differs from the Convention Journal in the spelling of some names, the identification of nominators and of nominees, and the tabulation of votes. The differences are thought to be too numerous and too unimportant for separate notes.</span>
                <div className="speaker" id="sp15"><span className="persName">Mr. NEBLETT</span>, of Lunenburg, said :</div>
                <p className="p-in-sp">
                  Mr. President : I beg leave to nominate for the office of Sergeant-at-Arms, Capt. Wm. C. Sneed, of the county of Lunenburg; and since it seems that the personal appearance of candidates for offices in the gift of this Convention is to be taken into consideration, I venture to say, that if this Convention will permit me to submit for inspection the gentleman whom I nominate, he will
                  be elected by acclamation. He is a very courteous and a very polite gentleman. He has been for nineteen or twenty years an officer of the militia, and for fifteen years of that time has acted as adjutant. I feel assured, if elected, he will give entire satisfaction.
                </p>
                <p><span className="persName">Mr. JOHNSTON</span>, of Scott county, nominated Charles B. Cosby, of Washington county.</p>
                <p>WAITMAN T. WILLEY nominated Josiah W. Rives, of the county of Barbour.</p>
                <p><span className="persName">Mr. WOODS</span> seconded the nomination of Mr. Rives.</p>
                <p><span className="persName">Mr. MORRIS</span> nominated Mr. Daniel Atwell, of the county of Caroline.</p>
                <p><span className="persName">Mr. DORMAN</span>, of Rockbridge, nominated <span className="persName">Mr. N. A. Thompson</span>, of Hanover.</p>
                <p><span className="persName">Mr. MACFARLAND</span> nominated Mr. John G. Moss of Richmond.</p>
                <p><span className="persName">Mr. COFFMAN</span> nominated Mr. Andrew J. Farrish of the county of Albemarle.</p>
                <p><span className="persName">Mr. HOLCOMBE</span> seconded the nomination of Mr. Farrish.</p>
                <p><span className="persName">Mr. GARLAND</span> nominated Col. John H. Fuqua of the county of Amherst.</p>
                <p><span className="persName">Mr. WICKHAM</span>, of Henrico, sustained the nomination of Mr. Thompson.</p>
                <p><span className="persName">Mr. RICHARDSON</span>, of Hanover, also sustained the same nomination.</p>
                <p><span className="persName">Mr. R. Y. CONRAD</span> nominated Mr. Thomas B. P. Ingram, formerly of Fauquier, but now of Richmond.</p>
                <PageNumber num={39} />
                <p><span className="persName">Mr. CAMPBELL</span>, of Washington, seconded the nomination of <span className="persName">Mr. C. B</span>. Cosby, of Washington county.</p>
                <p><span className="persName">Mr. BURLEY</span> sustained the nomination of Mr. Moss.</p>
                <p>Mr. Goode, of Mecklenburg, sustained the nomination of Capt. Sneed.</p>
                <p><span className="persName">Mr. BORST</span>, of Page county, nominated Mr. Charles M. Johnston, of the county of Fauquier.</p>
                <p>Mr. Robt. H. Vaughan, of Hampton, was also nominated.</p>
                <p><span className="persName">Mr. CHAMBLISS</span> moved to suspend the rules for the purpose of enabling him to offer a resolution referring the whole matter of the election of officers, to a committee of five.</p>
                <p><span className="persName">Mr. DORMAN</span> moved to lay the motion on the table. Agreed to.</p>
                <p><span className="persName">Mr. TIMOTHY RIVES</span>, of Prince George, nominated Mr. James B. Senner, of Spotsylvania.</p>
                <p><span className="persName">Mr. BRENT</span> seconded that nomination.</p>
                <p><span className="persName">Mr. BRANCH</span>, of Petersburg, also sustained the same nomination. Gen. EPPA HUNTON sustained the nomination of Mr. Johnston of Fauquier.</p>
                <p><span className="persName">Mr. MARYE</span>, of Spotsylvania, sustained the nomination of Mr. Senner.</p>
                <p>No more nominations being made, the clerk proceeded to call the roll on the election of Sergeant-at-Arms, with the following result :</p>
                <p>Wm. C. Sneed 9, Chas. B. Cosby 13, Josiah W. Rives 26, Robt. H. Vaughan 5, Daniel Atwell 2, N. A. Thompson 37, John G. Moss 5, A. J. Farrish 14, John H. Fuqua 4, Thos. B. P. Ingram 11, C. M. Johnston 3, J. B. Senner 7.</p>
                <p>Neither of the candidates having a majority of all the votes cast, there was no election.</p>
                <p><span className="persName">Mr. MARR</span> moved to suspend the rules for the purpose of offering a resolution, that the vote be taken between the three highest on the ballot just had, and that the same rule shall apply to the other offices to be filled.</p>
                <p><span className="persName">Mr. MONTAGUE</span> did not think the same rule should apply to the other elections. He favored the proposition so far as it related to the election of Sergeant-at-Arms, but would not sanction the application of the same rule to the election of the other officers of the Convention.</p>
                <p><span className="persName">Mr. SPEED</span> was in favor of referring the whole matter to a committee of five or fifteen as the Convention may determine.</p>
                <div className="speaker" id="sp16"><span className="persName">Mr. MONTAGUE</span>—</div>
                <p className="p-in-sp">My friend from Campbell [<span className="persName">Mr. SPEED</span>] , as it seems to me, is renewing the same proposition which I had the honor to submit this morning, in the shape of an amendment to a resolution<PageNumber num={40} /> offered by the gentleman from Richmond [<span className="persName">Mr. MACFARLAND</span>] 
                  . According to my recollection of the rules of the House of Delegates, which have been adopted for the government of this Convention, they prohibit the renewal on the same day of a proposition once voted upon and disposed of, unless by a reconsideration of the vote by which it was disposed of. I will make this point of order in reference to the proposition which the gentleman
                  intimates a disposition to bring forward.
                </p>
                <p><span className="persName">The PRESIDENT</span> believed it incompetent for the gentleman from Campbell [<span className="persName">Mr. SPEED</span>] to renew the proposition referred to.</p>
                <p>The question was then taken on the motion of <span className="persName">Mr. MARR</span>, that the vote be taken on the three highest on the list, and decided in the negative.</p>
                <p>The vote was then taken on a choice between Messrs. N. A. Thompson, Josiah W. Rives and Andrew Farrish, who were the three highest on the last ballot, with the following result :</p>
                <p>For THOMPSON-Messrs. John Janney [President] 
                  , Ambler, Armstrong, Aston, Baldwin, Alfred M. Barbour, James Barbour, Baylor, Blow, Jr., Bouldin, Boyd, Branch, Brent, Bruce, Campbell, Carter, Chambliss, Chapman, Clemens, Conrad, James H. Cox, Critcher, Custis, Dent, Dorman, Dulany, Early, Echols, Flournoy, Forbes, French, Fugate, Gillespie, Graham, Grant, Gray, Goggin, Cyrus Hall, Hammond, Hoge, Holladay, Hull, Jackson, Marmaduke
                  Johnson, Peter C. Johnston, Kilby, John J. Kindred, Macfarland, Charles K. Mallory, James B. Mallory, Marshall, Marr, Marye, Sr., Maslin, Masters, MOORE, Nelson, Osburn, Patrick, McC. Porter, Preston, Price, Pugh, Richardson, Rives, Saunders, Sr., Robert E. Scott, William C. Scott, Sharp, Southall, Staples, Alexander H. H. Stuart, Chapman J. Stuart, Sutherlin, Tarr, Tayloe, Thornton,
                  Tredway, Waller, White, Whitfield, Wickham, Wysor-84.
                </p>
                <p>For FARRIS H-Messrs. Ambler, Blakey, Boisseau, Borst, Cabell, Cecil, Coffman, Conn, Richard H. Cox, Deskins, Fisher, Garland, Gregory, Jr., Thomas F. Goode, Addison Hall, L. S. Hall, Holcombe, Hunton, Isbell, Kent, Leake, Moffett, Montague, Morris, Morton, Neblett, Parks, Randolph, Seawell, Sheffey, Slaughter, Speed, Strange, Robert H. Turner, Williams-37.</p>
                <p>For RIVES-Messrs. Berlin, Boggess, Brown, Burdett, Burley, Carlile, Couch, C. B. Conrad, Gravely, John Goode, Jr., Ephraim B. Hall, Haymond, Hubbard, Hughes, McComas, McGrew, McNeil, Orrick, Spurlock, Franklin P. Turner, Willey, Wilson, WOODS-22.</p>
                <p>Whole number of votes, 143 ; necessary to a choice, 72.</p>
                <p>Mr. Thompson having received a majority of all the votes cast, was declared duly elected.</p>
              </div>
              <div className="section" id="vsc1965.v1.2.2.2">
                <h3><span className="headingNumber">1.2.2. </span><span className="head">AMENDING THE RULES</span></h3>
                <p><span className="persName">Mr. NELSON</span> offered the following resolution, which was lost : Resolved, That a committee of members be appointed to consider <PageNumber num={41} /> and report what rules of proceeding of the House of Delegates are applicable as rules of this Convention, and what amendment thereof, if any, ought to be made.</p>
              </div>
              <div className="section" id="vsc1965.v1.2.2.3">
                <h3><span className="headingNumber">1.2.3. </span><span className="head">ELECTION OF FIRST DOORKEEPER</span></h3>
                <span className="note" id="Note15"><span className="noteLabel">2</span>This account of the nomination and election of the First Doorkeeper differs from the Convention Journal, notably in the tabulation of votes. The differ ences do not seem to call for separate notes.</span>
                <p><span className="persName">The PRESIDENT</span> announced the next business in order, to be the election of First Doorkeeper, whereupon—</p>
                <p><span className="persName">Mr. MALLORY</span> nominated <span className="persName">Mr. C. Drumright</span>, of the county of Mecklenburg.</p>
                <p><span className="persName">Mr. ADDISON HALL</span> nominated Mr. Richard Reins, of this city.</p>
                <p><span className="persName">Mr. N. B. FRENCH</span>, of Mercer, nominated Mr. Benjamin R. Linkous. of the county of Raleigh.</p>
                <p><span className="persName">Mr. CRITCHER</span> nominated Mr. James R. Fisher of Richmond.</p>
                <p><span className="persName">Mr. JOHNSON</span> of Richmond, nominated Mr. Samuel H. Jeter of this city.</p>
                <p><span className="persName">Mr. CARLILE</span> nominated Mr. Charles Lewis of the county of Harrison</p>
                <p><span className="persName">Mr. T. S. FLOURNOY</span> nominated Mr. Charles Kent of the county of Pittsylvania.</p>
                <p><span className="persName">Mr. TREDWAY</span> seconded that nomination.</p>
                <p><span className="persName">Mr. EARLY</span> seconded the nomination of Mr. Linkous.</p>
                <p>Mr. Jeremiah Morton nominated Mr. Wm. Routt of the county of Greene.</p>
                <p><span className="persName">Mr. PRICE</span> of Greenbrier advocated the nomination of Mr. Linkous.</p>
                <p><span className="persName">Mr. SCOTT</span> of Powhatan, nominated Mr. Jno. F. Simpson of the county of Powhatan.</p>
                <p>Mr. Cox of Chesterfield, seconded that nomination.</p>
                <p><span className="persName">Mr. WM. B. PRESTON</span> advocated the election of Mr. Linkous.</p>
                <p><span className="persName">Mr. BOULDIN</span> of Charlotte, nominated Mr. Wm. B. Wills.</p>
                <p><span className="persName">Mr. TIMOTHY RIVES</span> advocated the election of Mr. Jeter.</p>
                <p><span className="persName">Mr. GOODE</span>, of Mecklenburg, sustained the nomination of Mr. Drumright.</p>
                <p><span className="persName">Mr. WICKHAM</span> nominated <span className="persName">Mr. F. V</span>. Sutton, of Richmond.</p>
                <p>There being no other nominations made, the call of the vote was proceeded with, and resulted as follows :</p>
                <p>C. Drumright, 6; Richard Reins, 3; Benjamin R. Linkous, 45; Jas. R. Fisher, 1; Sam'l H. Jeter, 26; Charles Lewis, 29; Charles Kent, 5; Wm. Routt, ,6; John F. Simpson, 2; Wm. B. Wills, 4; F. V. Sutton, 8.</p>
                <p>Neither of the candidates having a majority of the whole number of votes cast, there was no election.</p>
                <p>On motion of <span className="persName">Mr. MORRIS</span> the rules were suspended to enable him <PageNumber num={42} /> to offer a resolution, that the Convention, in order to save time, proceed to take a vote upon the three highest on the Ballot just taken.</p>
                <p>The resolution was adopted, whereupon the clerk proceeded to call the roll upon a vote between Benjamin R. Linkous, Sam'l H. Jeter and Chas. Lewis, who were the three highest upon the last ballot.</p>
                <p>The following is the result :</p>
                <p>For JETER-Messrs. John Janney [President] 
                  , Ambler, Boisseau, Borst, Bouldin, Brent, Cabell, Chambliss, Chapman, Conn, James H. Cox, Richard H. Cox, Critcher, Dulany, Forbes, Gregory, John Goode, Thomas F. Goode, Addison Hall, Harvie, Holladay, Marmaduke Johnson, Kilby, Kindred, McNeil, Macfarland, Charles K. Mallory, J. B. Mallory, Marshall, Marr, Marye, Montague, Morris, Morton, Neblett, Nelson, Pendleton, Randolph,
                  Richardson, Rives, Robert E. Scott, Wm. C. Scott, Slaughter, Southall, Speed, Strange, Thornton, Tredway, Robt. H. Turner, F. P. Turner, Williams-51.
                </p>
                <p>
                  For LINKOUS-Messrs. Armstrong, Aston, Baldwin, A. M. Barbour, Berlin, Blakey, Blow, Boyd, Campbell, Caperton, Carter, Cecil, Coffman, Custis, Deskins, Dorman, Early, Echols, Fisher, Flournoy, French, Fugate, Garland, Gillespie, Graham, Grant, Gravely, Gray, Goggin, Hale, Hammond, Hoge, Hunton, Isbell, P. C. Johnston, Kent, Leake, Lewis, Maslin, Masters, MOORE, Parks, Patrick,
                  Preston, Price, Pugh, Saunders, Sharp, Sheffey, Spurlock, Staples, A. H. H. Stuart, Sutherlin, Tayloe, Waller, White, Wickham, Wysor-58.
                </p>
                <p>For LEWIS-Messrs. Boggess, Brown, Burdett, Burley, Carlile, Clemens, C. B. Conrad, Robert Y. Conrad, Couch, Dent, E. B. Hall, Haymond, Hubbard, Hughes, Hull, Jackson, McGrew, Moffett, Orrick, Osburn, Porter, C. J. Stuart, Tarr, Willey, Wilson, WOODS-26.</p>
                <p>Neither candidate having received a majority of the whole number of votes cast, there was no election.</p>
              </div>
              <div className="section" id="vsc1965.v1.2.2.4">
                <h3>
                  <span className="headingNumber">1.2.4. </span
                  ><span className="head"
                  >COMMISSIONERS FROM SOUTH CAROLINA, GEORGIA<br />
                    AND MISSISSIPPI</span
                  >
                </h3>
                <p>
                  Before further proceeding with the business of the Convention, the PRESIDENT desired an opportunity to present a communication from the Governor transmitting the credentials of the Hons. John S. Preston, Commissioner from the State of South Carolina, and Fulton Anderson, Commissioner from the State of Mississippi. He also submitted a communication addressed to himself, from the Hon.
                  Henry C. Benning, enclosing the credentials of that gentleman, as a Commissioner from the State of Georgia.
                </p>
                <p><span className="persName">Mr. WM. BALLARD PRESTON</span> offered the following resolution, which was adopted, with only one dissenting voice :</p>
                <p>
                  Resolved, That a committee of five be appointed by the President to wait upon the Hon. John S. Preston, commissioner from the State of South Carolina, the Hon. Henry C. Benning, commissioner from the State of Georgia, and the Hon. Fulton Anderson, commissioner from <PageNumber num={43} /> the State of Mississippi, and inform them that this Convention of
                  the people of the State of Virginia respectfully invite them to seats in this Hall, and will receive at such time and in such mode as they may severally prefer, any message they may have to deliver.
                </p>
                <p>The President appointed the following committee under this resolution : Messrs. Wm. B. Preston, Lewis E. Harvie, Wm. H. Macfarland, R. Y. Conrad and R. L. Montague.</p>
              </div>
              <div className="section" id="vsc1965.v1.2.2.5">
                <h3><span className="headingNumber">1.2.5. </span><span className="head">ELECTION OF FIRST DOORKEEPER AGAIN</span></h3>
                <p><span className="persName">Mr. JACKSON</span> moved to suspend the rules to enable some members to change their votes upon the election of Doorkeeper.</p>
                <p>
                  The motion was agreed to; whereupon the following gentlemen changed their votes in favor of Mr. Linkous : Messrs. <span className="persName">JACKSON</span>, <span className="persName">MOFFETT</span>, <span className="persName">BORST</span>, <span className="persName">C. J. STUART</span>, <span className="persName">HAYMOND</span>, <span className="persName">BURDETT</span> and
                  <span className="persName">BOGGESS</span>, This raised Mr. Linkous' vote to 67, which, being a majority of all the votes cast, secured his election.
                </p>
                <p>On motion of <span className="persName">Mr. MONTAGUE</span>, the communication from the Governor, with the accompanying documents, as also that of Mr. Benning to the President, were laid on the table and ordered to be printed.</p>
              </div>
              <div className="section" id="vsc1965.v1.2.2.6">
                <h3><span className="headingNumber">1.2.6. </span><span className="head">ELECTION OF SECOND DOORKEEPER</span></h3>
                <div className="speaker" id="sp17"><span className="persName">Mr. TREDWAY</span> said,</div>
                <p className="p-in-sp">inasmuch as we have a second doorkeeper to elect, and Mr. Jeter received a very large number of votes for the office of first doorkeeper, I would move, with the approbation of the Convention, and in order to save time, that Mr. Jeter be declared the choice of the Convention for the office of second doorkeeper.</p>
                <div className="speaker" id="sp18"><span className="persName">Mr. SPEED</span>—</div>
                <p className="p-in-sp">
                  I do not think this mode of proceeding is entirely just to others who desire to have their claims presented before this body for the office of second doorkeeper. There are others who ought to have as fair a chance as Mr. Jeter, and this they cannot have, if the proposition of the gentleman is carried. I, therefore, propose to nominate a young gentleman from the county of Goochland
                  for this office. This gentleman bears the name, and is, moreover, a relative of the worthy representative who is here from that county, and who from motives of delicacy is unwilling to present for this office the name of the gentleman with whose nomination I am entrusted. I present to this body the name of Mr. Wm. Josiah Leake of the county of Goochland.
                </p>
                <p>The President said that nominations were not in order, pending the motion of <span className="persName">Mr. TREDWAY</span>.</p>
                <p><span className="persName">Mr. TREDWAY</span> withdrew the motion.</p>
                <p>Mr. Cox nominated Mr. Wm. Welsh of Chesterfield.</p>
                <PageNumber num={44} />
                <p>It being too late in the day to go into the election of second doorkeeper, further nominations were suspended.</p>
              </div>
              <div className="section" id="vsc1965.v1.2.2.7">
                <h3><span className="headingNumber">1.2.7. </span><span className="head">APPOINTMENT OF PAGES</span></h3>
                <div className="speaker" id="sp19"><span className="persName">The PRESIDENT</span> said :</div>
                <p className="p-in-sp">I have about twenty-three applications for the office of page, and I am of the opinion that I have no authority to make appointment. I should be very glad if the Convention would dispose of this subject.</p>
                <p><span className="persName">Mr. HAYMOND</span> offered the following resolution, which was adopted :</p>
                <p>Resolved, That the President of this Convention be authorized to employ four pages, whose services are to be for the use of the members of the Convention.</p>
              </div>
              <div className="section" id="vsc1965.v1.2.2.8">
                <h3><span className="headingNumber">1.2.8. </span><span className="head">DELEGATES' SEATS</span></h3>
                <p><span className="persName">Mr. CAPERTON</span> offered the following resolution :</p>
                <p>Resolved, That the seats for delegates in this Hall shall be regarded as free among the members of the Convention, and not specially appropriated except whilst occupied.</p>
                <p>On motion of <span className="persName">Mr. STAPLES</span>, the resolution was laid on the table. On motion of <span className="persName">Mr. MORRIS</span>, the Convention then adjourned until 12 o'clock tomorrow.</p>
              </div>
            </div>
            <div className="day" id="vsc1965.v1.2.3">
              <PageNumber num={45} />
              <h2><span className="headingNumber">1.3. </span><span className="head">THIRD DAY</span></h2>
              <div className="dateline">Friday, <span className="date">February 15</span></div>
              <p>The Convention met at 12 o'clock.</p>
              <p>Prayer by the Rev. Mr. Duncan, of the M.E. Church.</p>
              <p><span className="persName">The PRESIDENT</span> announced the first business in order to be the election of Second Doorkeeper, whereupon,</p>
              <p><span className="persName">Mr. FORBES</span> nominated Col. J. J. Winn, of the county of Albemarle.</p>
              <p><span className="persName">Mr. McComas</span> nominated <span className="persName">Mr. H. S. Coleman</span>, of the county of Stafford.</p>
              <p><span className="persName">Mr. GREGORY</span> nominated Mr. Roscoe Burke, of the county of King William.</p>
              <p><span className="persName">Mr. TREDWAY</span> nominated <span className="persName">Mr. S. H. Jeter</span>, of Richmond.</p>
              <p><span className="persName">Mr. SOUTHALL</span> seconded the nomination of Mr. Winn.</p>
              <p><span className="persName">Mr. SPEED</span> reminded the Convention of his having on yesterday put in nomination for the office of Second Doorkeeper, Mr. Wm. Josiah Leake, of the county of Goochland.</p>
              <p>Mr. Cox, of Chesterfield, also reminded the Convention of his having on yesterday put in nomination, Mr. Wm. Welsh, of Chesterfield.</p>
              <p><span className="persName">Mr. BRANCH</span>, of Petersburg, seconded the nomination of Mr. Welsh.</p>
              <p><span className="persName">Mr. MONTAGUE</span> nominated Col. Joseph Tompkins, of the county of Chesterfield.</p>
              <p>There being no others in nomination, the Clerk proceeded to call the roll, with the following result :</p>
              <p>
                Col. J. J. Winn, 14; H. S. Coleman, 26; Roscoe Burke, 1; S. H. Jeter, 35 ; Wm. Josiah Leake, 34; Wm. Welsh, 26; Joseph Tompkins, 1.<span className="note" id="Note16"><span className="noteLabel">1</span>According to the Journal, Winn got 15 votes on this first ballot. The Journal gives the candidate's name as Welch.</span>
              </p>
              <p>Neither of the candidates having a majority of all the votes cast, there was no election.</p>
              <p><span className="persName">Mr. MORTON</span> moved to suspend the rules, for the purpose of enabling him to submit a proposition to strike off all the names except the three highest on the last ballot.</p>
              <p>The motion was agreed to, whereupon <span className="persName">Mr. MORTON</span> made the motion which he had just indicated.</p>
              <p>Mr. Cox of Chesterfield moved to amend that motion by substituting the four highest on the list for the three embraced in Mr. MORTON'S motion. This amendment, he said, was made necessary by the fact that two of the four highest had 26 each.</p>
              <p><span className="persName">Mr. MORTON</span> accepted the amendment, whereupon the question on the original motion was put, and decided in the affirmative.</p>
              <p>Messrs. Wm. Josiah Leake, S. H. Jeter, Wm. Welsh and H. S. Coleman, <PageNumber num={46} /> being the four highest in the last ballot, were the candidates chosen under the motion just adopted.</p>
              <p>The Clerk proceeded to call the roll upon the election of a choice between these gentlemen, with the following result :</p>
              <p>
                For LEAKE-Messrs. Ambler, Aston, Baldwin, Blakey, Borst, Bouldin, Boyd, Campbell, Cecil, Chapman, Clemens, Couch, Dulany, Echols, Fisher, Garland, Gillespie, Graham, Grant, Goggin, John Goode, Jr., Thomas F. Goode, Hale, Isbell, Jackson, Peter C. Johnston, Kent, Kindred, Leake, McGrew, Montague, Morris, Morton, Parks, Patrick, Price, Richardson, Sheffey, Sitlington, Slaughter,
                Southall, Speed, Strange, Robert H. Turner, Wickham, Wise-46.
              </p>
              <p>For JETER-Messrs. Janney [President], Boisseau, Bruce, Burdett, Cabell, Carlile, Carter, Chambliss, Conn, Robert Y. Conrad, Richard H. Cox, Flournoy, Gravely, Gregory, Jr., Addison Hall, Cyrus Hall, L. S. Hall, Harvie, Haymond, Holcombe, Marmaduke Johnson, Kilby, Macfarland, Charles K. Mallory, James B. Mallory, Marshall, Neblett, Nelson, Osburn, Randolph, Rives, Seawell, Chapman J. Stuart, Sutherlin, Tayloe, Thornton,
                Tredway, Franklin P. Turner, Williams, Wysor-40.
              </p>
              <p>For WELSH-Messrs. Alfred M. Barbour, Baylor, Blow, Jr., Branch, Brent, Brown, Burley, Caperton, Coffman, James K. Cox, Custis, Dent, Dorman, Early, Forbes, Ephraim B. Hall, Hammond, Hubbard, Lewis, MOORE, Pendleton, Porter, Preston, Pugh, Saunders, Sr., Robert E. Scott, Staples, Alexander H. H. Stuart, Tarr, White, Willey-31.</p>
              <p>For COLEMAN-Messrs. Armstrong, James Barbour, Berlin, Boggess, C. B. Conrad, Critcher, French, Fugate, Gray, Hoge, Hughes, Hull, Hunton, Lawson, McComas, McNeil, Marr, Marye, Sr., Maslin, Masters, Moffett, Orrick, Sharp, Spurlock, Waller, Wilson, WOODS-27.</p>
              <p>Neither of the candidates having a majority of all the votes cast, there was no election.</p>
              <div className="speaker" id="sp20"><span className="persName">Mr. HENRY A. WISE</span>—</div>
              <p className="p-in-sp">I rise, having just got here, to enquire whether this proceeding of the election of second doorkeeper, involving so much delay, is not "fiddling while Rome is burning?" [Loud applause.] 
                I will make a motion, and it is this : that the highest on the list be our second doorkeeper. I am satisfied to give any gentleman his choice of the three names—the highest, or the middle, or the lowest. My object is to expedite the business of the Convention, and to avoid the delay which this prolonged system of balloting involves. Does it require a suspension of the rules to make the
                motion which I have just indicated?
              </p>
              <div className="speaker" id="sp21"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">It does, sir.</p>
              <div className="speaker" id="sp22"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I move, then, to suspend the rules, for the purpose of making this motion.</p>
              <p>The motion was lost.</p>
              <div className="speaker" id="sp23"><span className="persName">Mr. C. B. CONRAD</span>—</div>
              <p className="p-in-sp">I move to suspend the rules, for the purpose of making a motion to adopt the plurality rule.</p>
              <PageNumber num={47} />
              <p><span className="persName">Mr. BRANCH</span> opposed the motion, and the question being taken upon it, was decided in the negative.</p>
              <p>The election of Doorkeeper was then proceeded with, and Mr. Coleman, who was lowest on the list, being dropped, the vote was taken between Messrs. Leake, Jeter and Welsh, with the following result : Leake, 79; Jeter, 42 ; Welsh, 24.</p>
              <p>Mr. Leake having a majority of all the votes cast, was declared duly elected.</p>
              <p><span className="persName">Mr. PRESTON</span>, from the Committee appointed on yesterday to wait upon the Commissioners from South Carolina, Georgia and Mississippi, submitted the following report :</p>
              <div className="floatingText">
                <div className="floatingText_body">
                  <p>The Committee appointed by the Convention to wait upon the Commissioners from the States of South Carolina, Georgia and Mississippi, to invite them to accept seats on the floor of this Convention, and inform them that the Convention would receive any message from them respectively at such time and in such form as they might choose, respectfully report :</p>
                  <p>
                    That the Committee waited upon the gentlemen named in the resolution, yesterday evening, and stated to them, as directed, the purport of their visit. The Commissioners expressed their grateful sense of the courtesy shown to them personally, and the honorable consideration of their mission, and accepted the tender of seats upon this floor, (which they now occupy) , and said that if
                    it should suit the convenience of the Convention, they desired to address it orally on Monday next. All of which they requested the Committee to communicate to the Convention.
                  </p>
                </div>
              </div>
              <div className="speaker" id="sp24"><span className="persName">Mr. PRESTON</span>—</div>
              <p className="p-in-sp">I move that this Convention appropriate the hour of 12 o'clock on Monday for the purpose of receiving these Commissioners.</p>
              <p>The motion was agreed to unanimously.</p>
              <p><span className="persName">Mr. TREDWAY</span> offered the following resolution, which was adopted :</p>
              <p>Resolved, That the editors of the newspapers published in the city of Richmond and reporters for such papers be admitted to seats in the Convention, under the direction of the President.</p>
              <p><span className="persName">Mr. HAYMOND</span> offered the following resolution :</p>
              <p>Resolved, That the President of the Convention appoint a committee of electors consisting of members, to whom shall be referred the returns of Delegates and all matters relating to contests for seats in the Convention.</p>
              <p>After some little debate on this resolution, it was adopted. On motion of <span className="persName">Mr. HAYMOND</span>, the blank was filled with 13.</p>
              <PageNumber num={48} />
              <div className="speaker" id="sp25"><span className="persName">Mr. NELSON</span>—</div>
              <p className="p-in-sp">I now move to take up a resolution which I offered yesterday, proposing to appoint a committee of seven to suggest what alterations should be made in the rules of the House of Delegates which we have adopted, in part, for our government. I think, sir, if the committee was appointed they could recommend such rules as would suit the purposes of this Convention.</p>
              <p>The motion was not agreed to.</p>
              <div className="speaker" id="sp26"><span className="persName">Mr. DORMAN</span>—</div>
              <p className="p-in-sp">I move to reconsider the vote by which the rules of the House of Delegates were adopted, to enable me to offer a resolution which I am sure will be acceptable to the Convention.</p>
              <p>The motion was agreed to, whereupon <span className="persName">Mr. DORMAN</span> offered the following :</p>
              <p>Resolved, That the rules of the Virginia Convention of 1850, be adopted for the government of this Convention, and that 200 copies of the same, be printed for the use of this body.</p>
              <p><span className="persName">Mr. DORMAN</span> explained that he had carefully examined the rules of the last Convention, and they seemed to him to embody all that was necessary to suit the present Convention.</p>
              <div className="speaker" id="sp27"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">I move that the resolution be so amended as to include the words "so far as applicable." It will be observed by reference to these rules, that they provide for Committees, which will not be needed by this Convention.</p>
              <p><span className="persName">Mr. DORMAN</span> accepted the amendment, and the resolution was then adopted as amended.</p>
              <p><span className="persName">Mr. SPEED</span> offered the following resolution :</p>
              <p>Resolved, That a committee of five be appointed, with instructions to ascertain if some arrangement can be made with the House of Delegates by which they may so adjust their sessions, as that their hall may be appropriated during a portion of each day, for the use of this Convention.</p>
              <div className="speaker" id="sp28"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">I propose to amend, by appointing a committee of three to provide for a suitable hall for the Convention.</p>
              <p>After some little debate, <span className="persName">Mr. WOODS</span> moved the indefinite postponement of the whole matter, which was agreed to.</p>
              <p><span className="persName">Mr. R. Y. CONRAD</span> offered the following resolution :</p>
              <p>
                Resolved, That a committee to consist of twenty-one members, selected from different sections of the State, to be termed the "Committee of Federal Relations," be appointed by the PRESIDENT; to said committee all resolutions on Federal relations shall be at once referred, without debate, and it shall be their duty from time to time, to report the same to the Convention for its action.
              </p>
              <PageNumber num={49} />
              <div className="speaker" id="sp29"><span className="persName">Mr. CONRAD</span>—</div>
              <p className="p-in-sp">This seems to me the best mode of proceeding that theConvention could adopt. Until some objection is made to the resolution, I am not disposed to trouble the Convention with any remarks on the subject.</p>
              <div className="speaker" id="sp30"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                At the first step, sir, I oppose this resolution. From long experience, and some sad experience, I know its import, and I know its power; the power of a standing committee that shall be monopolists of all determinate consideration, in every preliminary stage of deliberation, as the omnipotent arbiter ; the power—the absolute power—of a majority. I will not consent that every man in the
                Convention shall bind me to yield my powers of debate and objection at the first stage of any question that may come before this body. Appoint your twenty-one ; let a proposition come in from the majority side, or the minority side : give it, then, to the committee, and it is tantamount to a denial of debate. Wield your power, take your policy, determine upon your course, but at every
                inch of ground in this Convention, I shall demand the right, I shall demand the power, of objection—the right of giving the reasons for my objection. If I can kill a measure before it can come here with the sanction of a committee of 21, if I believe that it is wrong, and can convince men with me that it is wrong, I claim the privilege, the right, the justice, to be allowed an
                opportunity. But give it the prestige of that committee, when it comes up for debate. and the debate itself is conquered, is crushed.
              </p>
              <p className="p-in-sp">This is not the hour to submit to one inch of arbitrary power, [loud applause on the galleries and floor,] 
                to one jot or tittle of unjust restraint. I will not be compressed into such a Chinese shoe of deliberation as this; and in vindicating this poor privilege I don't know whether I stand in a minority here, or in a majority. I hope we came here to utter opinions and to yield opinions to reason, to debate and to deliberation. Above any moment in the world this is the time to hear each
                other and to hear each other in time ; and the business of all of us is to consider questions at their first blush, and decide upon them before they shall have come up clothed with the sanction of a committee. Tell me that the question of all questions should be received, that the question of all questions should be introduced and should be referred without question and without debate!
                I tell gentlemen that there are propositions that will come before this body that I will vote not to entertain and not to dignify with a reference.
              </p>
              <div className="speaker" id="sp31"><span className="persName">Mr. R. Y. CONRAD</span>—</div>
              <p className="p-in-sp">Will the gentleman allow me to correct a mistake into which he has fallen? That proposition does not require that resolutions offered should be referred without question in the Convention.<PageNumber num={50} /> It is merely proper that they should be referred without debate.</p>
              <div className="speaker" id="sp32"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Will the Clerk read the resolution?</p>
              <p>The resolution was read.</p>
              <div className="speaker" id="sp33"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">My friend will allow me to ask him what "at once without debate" means? [Laughter.] 
                Now, is there any definition, any lexicography on earth that will not sustain me in the objection I make to that resolution? Adopt this resolution, and what will then be the rule of the Convention? I may object, indeed, to any resolution which may be offered on Federal Affairs. But there I must stop. I am deprived even of the power of assigning a reason for such objection.
              </p>
              <p className="p-in-sp">
                I know the gentleman from Frederick well. I have known him long, and I know that there is not a man in this Commonwealth of more sage and astute reflection and deliberation than he is. He has won my respect from my boyhood, and I implore him not to cut me off from the privilege of reasoning with him, at every stage, upon the serious matters which we have met here to consider.
              </p>
              <div className="stage it">[Loud applause in the galleries, and clapping of hands, which was kept up for a considerable time.]</div>
              <div className="speaker" id="sp34"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">I merely wish to say, sir, that if this applause in the gallery is to be continued, I shall have to follow the illustrious example set me by one of the Senators from Virginia, in the United States Senate.</p>
              <div className="stage it">[Laughter.]</div>
              <div className="speaker" id="sp35"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">God save me from applause! Like the Greek philosophers, I do not know that I have not done something wrong when I am applauded. But I hope the gentleman [<span className="persName">Mr. CARLILE</span>] , will follow the example of the Senator from Virginia to whom he referred, and try, with him, to save his country.</p>
              <div className="stage it">[Loud laughter and shouts of applause in the galleries, which were repeated twice or three times.]</div>
              <div className="speaker" id="sp36"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">I now submit a motion to clear the galleries. It is impossible to conduct the proceedings of the Convention so long as such confusion is tolerated.</p>
              <div className="speaker" id="sp37"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I do not yield the floor.</p>
              <div className="speaker" id="sp38"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">This is a question of order, sir, and I claim that it is competent for me to make the motion which I have submitted.</p>
              <div className="speaker" id="sp39"><span className="persName">Mr. CLEMENS</span>—</div>
              <p className="p-in-sp">I move to except the ladies.</p>
              <div className="speaker" id="sp40"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">I had no reference to them.</p>
              <div className="speaker" id="sp41"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">I call for the ayes and noes upon the motion to clear the galleries.</p>
              <div className="speaker" id="sp42"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">
                I am satisfied here, sir, that the public who are now attending upon our deliberations is one that has a high sense of order, and will respect the privileges and the decorum of this body. I <PageNumber num={51} /> am sure they need but to be told once that all such indications of applause or of admiration offend against the just principles of order. We are
                here engaged in solemn deliberation, and it is to be hoped that the public that we admit with so much pleasure will show their appreciation of the privilege by restraining their emotions. In making these remarks, I hope that the gentleman who made the motion, will be satisfied with an admonition from the Chair, and I will answer for my fellow citizens, that under such an admonition
                order will reign.
              </p>
              <div className="speaker" id="sp43"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">I do not desire that this rule should be enforced, if we can have silence; I will now withdraw my motion, but shall renew it again if order is not preserved.</p>
              <div className="speaker" id="sp44"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                I believe, sir, that it has become the fashion in Washington, in the Senate of the United States ; it used not to be the case in old times, but you and I know that the old times are past; but new times must be better, sir. They don't suit me so well, and I would implore the people to stand quietly by, and to be rather solemn on the occasion, than to cheer in times like these.
              </p>
              <p className="p-in-sp">
                But to the question : Suppose you adopt the proposition now under consideration. Here comes another, that the Convention must, under certain contingencies, be willing to adopt, by a bare majority, at once, and to consider at once, and it would require a suspension of that rule to let it be acted upon by a majority. Let it be referred to a committee of 21, and then there may be a
                majority on that committee who would be in favor of delay—delay, which is death by doing nothing. Save me from that policy. Wait, wait for that committee of twenty-one; and 131 will have to wait upon this 21, and order, decorum, and this rule, if adopted, will keep the 131 waiting for the 21—waiting-- waiting. When ?—at what time? Waiting at a moment when a conflagration is burning the
                capitol, and the cry of the people is for water.
               [Applause.] 
                Waiting—waiting—on this 15th day of February, A.D. 1861, when, in fifteen or twenty days more, the 4th of March will be here—more to us than ever any Ides of March were to Rome. I shall commit myself to no such delay or waiting; to no such denial of objection; to no such denial of debate. I tell gentlemen now, fully, plainly, flatly, that whatever is to be done ought to be done
                quickly. I say to the man that tells me that he does not know what to do, and that he has not yet come to some conclusion as to what he will consent to, that that man's day for doing anything is doom's day. If he wants to preserve peace, so do I; if he wants to prevent war, he had better not delay ; if he wants to preserve the Union in the integrity of the Constitution of the United
                States, so do I. I hate the hate that hates the Union. I tell <PageNumber num={52} /> him that if he wants to serve this Union, and to restore it, he had better do it quickly, and the way to do it, rests now, now, now, upon the shoulders of Virginia. Let her take a stand on her ultimatum, that not one right of hers should be impaired; that every right of
                hers should be protected; that those who assail them, assail them at the peril of the sword, sink or swim, live or die.
               [Subdued applause.] Let them understand that, sir, and we will have peace and security. But delay, delay, delay for two days more, upon the consideration of a question like this about the election of doorkeepers—talk about rules—debate every little question, and then swallow up all the serious questions in the committee of twenty-one, and you will dig the grave of Virginia.</p>
              <p>
                <span className="persName">Mr. CLEMENS</span> made some remarks referring to the fact, that whatever action the Convention might take would have to undergo the revision of the people. He concluded by moving, as an amendment to the resolution of <span className="persName">Mr. CONRAD</span>, that the words "at once and without debate" be stricken out. He made a point of order that the resolution
                was not consistent with the rules adopted by the Convention.
              </p>
              <div className="speaker" id="sp45"><span className="persName">Mr. R. Y. CONRAD</span>—</div>
              <p className="p-in-sp">
                I respond to the point of order just made. I had understood that a few minutes ago the Convention reconsidered the vote by which they adopted the rules of the House of Delegates. There are now no rules in force. I shall address myself for a moment to that resolution. I am surprised, not only at the character of the objections which have been made to this resolution, but that any
                objection should have been made at all, although I don't mean to say that such objections, to some extent, are not well founded. The truth is, I did not anticipate them, because I had no such purpose in drafting that resolution as has been imagined. It is a mistake on the part of both gentlemen that it is intended to suppress debate. I suppose it would be competent for a gentleman,
                when the report of the committee came in, to debate it as long and as fully as he desired ; but I think that the delay which the gentleman from Princess Anne
               [<span className="persName">Mr. WISE</span>] 
                referred to would be best avoided by not having the debate until the committee reported. I am as much impressed with the momentous character of the crisis that is now upon the country, and that the action of Virginia should be as prompt as is consistent with due deliberation, as the gentleman from Princess Anne can be. It is with a view of avoiding debate that I proposed the
                resolution, and I am now disposed, in deference to the judgment of the gentleman from Princess Anne, to acquiesce in the amendment.
              </p>
              <div className="speaker" id="sp46"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                Will my friend allow me to make two modifications, which will, perhaps, answer the purpose? I admit the force of his remark,<PageNumber num={53} /> that debate upon the preliminary question would be the longest elongation of debate. That being so, I suggest that when a proposition is offered that objection may be made, and that debate upon the part of the
                objector, giving the reason for his objection, may be limited to some specific time. I would suggest another modification, which is, that this committee be not given its own time to report, but that this committee of twenty-one should, from day to day, be compelled to report its progress, and to assign its reason for delay succinctly by its chairman. It should be given a maximum time
                in which to report. Of course the Convention will have power to give it more time from day to day. I am anxious that some modification should be made which would put the spur to the committee.
              </p>
              <p>After a brief pause in the proceedings, the following resolution was agreed upon by <span className="persName">Mr. WISE</span> and <span className="persName">Mr. CONRAD</span>, and submitted by the latter in lieu of the one which he first offered :</p>
              <p>
                Resolved, That a committee to consist of twenty-one delegates, selected from the different sections of the State, to be termed the committee on Federal Relations, be appointed by the President. To said committee all resolutions touching Federal relations, which shall be offered in this Convention, shall be referred; and it shall be their duty, from day to day, to report to the
                Convention for its action.
              </p>
              <p>The resolution was adopted.</p>
              <p><span className="persName">Mr. GRAY</span> offered a resolution of thanks to <span className="persName">Mr. Wm. F. GORDON, JR.</span>, for the handsome manner in which he discharged the duty of temporary Secretary.</p>
              <p>The resolution was adopted.</p>
              <p><span className="persName">Mr. WILSON</span> offered the following resolution, which, being objected to by <span className="persName">Mr. CLEMENS</span>, was laid over, under the rules.</p>
              <p>Resolved, That the Virginia commissioners to the Peace Congress, now in session in the city of Washington, be respectfully requested to report to this body, at their earliest convenience, whether, in their opinion, any result acceptable to Virginia may be expected from the deliberations of that body.</p>
              <p><span className="persName">Mr. JOHNSON</span> nominated Mr. Wyatt M. Elliott for Printer to the Convention.</p>
              <p><span className="persName">Mr. DORMAN</span> seconded the nomination; and none other being nominated, Mr. Elliott was unanimously elected.</p>
              <p><span className="persName">Mr. MORRIS</span> offered a resolution proposing the appointment of a committee of five members to ascertain and report if the Hall occupied by the Convention could be so arranged as to accommodate the members in a better way.</p>
              <PageNumber num={54} />
              <p>Laid on the table, on motion of <span className="persName">Mr. C. B. CONRAD</span>.</p>
              <p><span className="persName">The PRESIDENT</span> submitted a communication from the President of the Young Men's Christian Association, inviting the members of the Convention to the free use of the library and reading-room of the Association.</p>
              <p><span className="persName">Mr. HAYMOND</span> offered a resolution fixing the printer's pay as the same now received by the printer to the Legislature.</p>
              <p><span className="persName">Mr. PATRICK</span>, of Kanawha, made a motion to adjourn, but withdrew it at the request of Gov. WISE.</p>
              <div className="speaker" id="sp47"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I have been waiting for the moment when a motion for adjournment should be made, so that I might introduce a matter which I now present, without interrupting the business of the Convention. I wish to delay the gentlemen of the Convention a moment in their seats, in order that I may ask from them a courtesy and a privilege.</p>
              <p className="p-in-sp">I arrived here last night, sir, about dark, and I have appeared in the Convention today, for the first time, and shall now use my representative capacity here to vindicate myself from accusations that have been bandied at this capital and at the capital of Washington against my name in connection with the present crisis.</p>
              <p className="p-in-sp">Before I allude to the topics which I now mean to touch, I inquire if one of the delegates from Staunton is here. Pardon me, sir, I ask is the Hon. A. H. H. Stuart in his seat.</p>
              <p><span className="persName">Mr. STUART</span> answered in the affirmative.</p>
              <div className="speaker" id="sp48"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                Mr. President, I am in feeble health, with my nervous system a great deal shattered from much breach of rest. I have been in retirement for the past two months, nursing the sick of my family, and have not touched a public concern within that time. I have not meddled even with the election which brought me here to a seat in this Convention. I have been at home sorrowing and suffering to
                hear from day to day, by the public prints, that my intended unlawful, revolutionary raid was made the pretext for assembling a standing army in Washington; that I was heading a revolutionary party to commit all sorts of outrages without the pale of law. I have been written to by friends from various parts of the North, to assure me that they could not convince persons there that I was
                not in disguise in the city of Washington, or that I was not lurking about the fastnesses still of Harper's Ferry. I could have told them, sir, that if I wanted to engage in any raid at any time, any week I could have sent 20,000 barrels of powder to South Carolina; and I want to tell them, if anything could have tempted me to do such an act, it would have been the slanders
                <PageNumber num={55} /> which have been perpetrated at Washington upon my reputation.
              </p>
              <p className="p-in-sp">Sir, last night when I arrived here this slander pursued me still, and this paper was put into my hand. It seems, sir, that a debate recently took place in the Senate of Virginia, founded upon a resolution offered by the gentleman from Staunton, [<span className="persName">Mr. STUART</span>] , which resolution I will ask the Clerk to read.</p>
              <p>The Clerk then read the preamble and resolution, as follows :</p>
              <p>
                Whereas, it has been extensively reported through the public prints in different parts of the Union, that certain rash and ill-advised citizens of Virginia meditate, if they have not already set on foot, a conspiracy to seize the fortifications and other property of the United States within the limits of Virginia, and to invade the District of Columbia on or before the 4th day of March
                next, and by violence and force of arms to take possession of the public buildings and other property of the United States within said District, for the purpose of obstructing the operations of the Federal Government; and, whereas, any such seizure or invasion would tend to destroy the peace of the country, and to produce tumult, anarchy and bloodshed, and would be in derogation of the
                dignity and sovereign power of Virginia, who alone has the power to decide, through her regularly constituted authorities, what may be the proper mode of obtaining redress for any injuries she may suffer; be it therefore resolved, by the General Assembly,
              </p>
              <p>1st. That any such lawless and unauthorized conduct on the part of any citizens of Virginia would merit and receive the unqualified disapprobation of the General Assembly and people of Virginia.</p>
              <p>2d. Resolved, That the Governor be and he is hereby requested, in case he should be satisfied that it is the purpose of any of the citizens of Virginia to engage in any such lawless enterprise, to employ all the power with which he is invested to prevent their being carried into effect.</p>
              <div className="speaker" id="sp49"><span className="persName">Mr. PATRICK</span>—</div>
              <p className="p-in-sp">If the gentleman will yield, I would suggest the propriety of postponing the consideration of the subject until tomorrow.</p>
              <div className="speaker" id="sp50"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I would be unwilling to occupy the time of the Convention tomorrow. I am, moreover, anxious to take the first opportunity afforded me for this purpose, and the gentleman from Staunton, I know, will say to the House that I have done him the justice of speaking openly in his presence, in order to give him an opportunity for fair explanation.</p>
              <p className="p-in-sp">The first inquiry that I want to make of the gentleman is, whether he in his own mind contemplated me as one of those "rash and ill-advised citizens" in Virginia to whom this resolution refers.</p>
              <div className="speaker" id="sp51"><span className="persName">Mr. STUART</span>—</div>
              <p className="p-in-sp">Mr. President, I have no hesitation in saying to the member from Princess Anne, [<span className="persName">Mr. WISE</span>] , that I never dreamed for a moment that he was in any form or shape connected with that matter, and in justice to myself, I will state the reason why I offered these resolutions.</p>
              <PageNumber num={56} />
              <p className="p-in-sp">
                It is a matter of notoriety, known I presume to almost every citizen in Virginia, that rumors were afloat "that certain rash and ill-advised citizens' among whom certainly, I never would class a gentleman who has filled the Executive Chair of the Commonwealth, did meditate a conspiracy to seize the fortifications and other property of the United States. Private letters which I received
                from abroad, have assured me that such reports were very injurious to the peace of the country and calculated to impair the prospects of a favorable adjustment of difficulties. A gentleman from Virginia, now a resident of Illinois, stated to me in a letter that the impression made upon the public mind of that State was, that a large body of men would accompany the President elect to
                the city of Washington—men of like passions and like excitability as those referred to in the resolution—and that, being confronted there, it might originate civil war. I thought it was my duty to offer the resolution with a view to obviate any act of this kind, and to satisfy persons abroad that there was no ground for such a rumor. I assure the gentleman that he in no wise entered
                into my mind in connection with that matter. I acquit him, sir, entirely, of any belief in my own heart that he, at any time, would engage in any such enterprise.
              </p>
              <div className="speaker" id="sp52"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                I am satisfied, sir. I thank the gentleman for the satisfactory answer which he has given me. At the same time, whilst the public prints did connect my name with this matter—they have had, certainly, reason to acquit me, on the evidence of my own declarations before the public. I would have preferred, and would not have winced so much under this resolution, connected, as it is, with
                other information that I have, if the resolution, instead of taking the fact as true and serious, had been a resolution of inquiry whether there was any foundation for the charge or not. They took that course at Washington, and a court of inquiry has been sitting there to ascertain whether the enemy had been sent to meet the Lieut. General. I wonder why they did not summon me as a
                witness? They found no witnesses and no evidence of any such fact. It would have been better if this resolution, instead of assuming that the fact existed, had assumed rather a doubt about it, and attempted an inquiry upon the subject; and had an inquiry been instituted, and I been called here, I think that my evidence would have been such as to make the Lieutenant General feel ashamed
                of his position; for J believe that I have complete and full evidence that he at least has made this assumption of a purpose on my part to organize a military force, the ground of his assembling a standing army at the capital. I fear, sir, that whilst the perpetration of that false imputation may be alleged against the authorities there, the public
                <PageNumber num={57} /> liberty may be concerned in the steps that must have been taken to ascertain whether I had been guilty of a raid or not, when I have reason to know and feel that my family letters have been peered into, whether in the Post Office or not, the deponent saith not. So much for that.
              </p>
              <p className="p-in-sp">Last night when I arrived here, sir, this paper was put into my hand an instance of another slander of which I was ignorant until I saw the debate in the Senate of Virginia, between the gentleman from Augusta, [<span className="persName">Mr. STUART</span>] , and the Senator from King William, [<span className="persName">Mr. B. B. DOUGLAS</span>] , when, for the first time, I was apprised of the rumor that it was alleged that there was a conspiracy, not only to prevent the inauguration of the President elect at Washington, but that there was a conspiracy to remove the incumbent Governor of Virginia and substitute in his place the ex-Governor of Virginia. [Laughter.] That was the first time that I ever heard of that rumor, so utterly unaware was I of the fact that my reputation was assailed in that respect. Senator DOUGLAS sent me this letter last night addressed to him from Staunton, signed by J. A. Harman and J. D. Imboden. It reads as follows :</p>
              <div className="floatingText">
                <div className="floatingText_body">
                  <p>STAUNTON, Feb. 8, 1861.</p>
                  <p>B. B. DOUGLAS, Esq.:</p>
                  <p>
                    My Dear Sir: I have just seen the question put by you to Mr. Stuart in the Senate last week, in reference to the deposing of Mr. Letcher and substitution of Mr. Wise. I suppose you got your information from me, and if so, I want to state the circumstances under which Mr. Stuart made the statement. Now, there is no intimacy between <span className="persName">Mr. S</span> and myself, and
                    could be no privacy. * * .* Mr. Stuart met me in the Rotunda at the Exchange. After a formal salutation he remarked to me that he was possessed of information that went to prove that one of the first acts of the Convention would be to remove Letcher and put in Wise, and that Wise had already been telegraphed to. I told him I did not believe it. Imboden came up just then and I told
                    him; he also said he did not believe it, and Mr. Stuart told him that he was in possession of information that he, Imboden, could not possibly get at, and it was certainly so. Imboden repeated that he did not believe one word of it. We separated, and I mentioned the conversation to a number of gentlemen, among the rest, yourself, and all agreed that they did not believe it. * * *
                  </p>
                  <p>Your friend, JOHN A. HARMAN.</p>
                  <p>
                    Capt. Harman has just shown me the foregoing letter, and the facts he states as occurring after I came up to where he and Mr. Stuart were talking, are exactly true. What passed before I joined them, I did not hear, except that Capt. Harman, when I joined them, repeated to me that <span className="persName">Mr. S</span>. had said that one object those had in view who were for calling
                    the Convention was, to "legislate" Letcher out of office and put Wise in and subvert the government. I immediately replied that I did not believe one word of it. Mr. Stuart replied, "I am in possession of information not accessible to you." I replied, "That may be; but I don't believe one word of it." At that point I was called away.
                  </p>
                  <div className="pagebreak" id="pb.1.58">[Page 34]</div>
                  <p>This occurred in the hall of the Exchange, where a crowd was present. I did not hesitate to repeat it, as I did not consider it "private or confidential." Signed, J. D. IMBODEN,</p>
                  <p>
                    HON. H. A. WISE, Present : The foregoing certificates addressed to me, are evidently not intended to be regarded as confidential. As their contents may be important to you, and as I deem you entitled to their possession, I feel not only authorized, but to some extent obliged to place them at your disposal, to be used publicly or privately, as you may deem necessary for your
                    personal vindication.
                  </p>
                  <p>Very truly and respectfully B. B. DOUGLAS.</p>
                </div>
              </div>
              <div className="speaker" id="sp53"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                Now, sir, I aver the fact as a gentleman before gentlemen, that there is not, so far as I am concerned, the least shadow of a foundation for any such communication. I cannot, of course, undertake to say that somebody did not undertake to telegraph to me. Whether he did or did not, I know no more than the child unborn. I know this, sir—that I live more than eight miles from the end of a
                telegraph line. I know that when any telegraph comes, addressed to me, it is obliged to be known to several witnesses; it is obliged to be sent to me by a messenger. I know that no messenger ever came to me with any such telegraph—with any such letter—with any such sign. I know that no such idea was ever intimated to me—never upon earth by anybody, in any form, until it came to me in
                this form. And I know, as every man does know who knows me, that had it come it would have met only with a smile of pity at the idea.
              </p>
              <p className="p-in-sp">Now, this, coming to me from a Senator of my State, alleging that another Senator has information which, it is alleged, he treats as reliable, implying at least that I was privy to a conspiracy to subvert the government, to remove the incumbent of the Governorship and take his seat, I am compelled here, in this presence, to ask the Senator from Staunton, [<span className="persName">Mr. STUART</span>] for the facts as he understands them; and if he has vouchers for these facts, what was his authority for stating them.</p>
              <div className="speaker" id="sp54"><span className="persName">Mr. STUART</span>—</div>
              <p className="p-in-sp">It is with great pleasure I mean to respond to the inquiry of the gentleman from Princess Anne [<span className="persName">Mr. WISE</span>] 
                . I have only to state, sir, that the information contained in that letter is an entire mistake. I never had any information upon the subject—never professed to have, to any mortal being. I mentioned the matter as one of the on dits of the day, in the rotunda of the Exchange Hotel. The gentlemen referred to are townsmen of mine—one a member of the bar; another a magistrate of the
                county of Augusta. They met me in the rotunda, and, I suppose, we were inquiring in the ordinary common-place <PageNumber num={59} /> form, what was the news. I gave this as one of the rumors; but, to the best of my recollection, did not connect the name of the gentleman from Princess Anne
               [<span className="persName">Mr. WISE</span>] with it. I might have mentioned his name, for I cannot recollect with accuracy. But one thing is very certain, that my colleague on my left, [<span className="persName">Mr. BALDWIN</span>] who heard me at the same time, and who occupies the near and dear relation of brother-in-law to me, and with whom, if I had any conversation, it would have been necessarily more unreserved, will say that I never connected the name of the gentleman from Princess Anne, [<span className="persName">Mr. WISE</span>] 
                with this rumor. I merely said the rumor was afloat, and, I presume, there are many members of the Convention who have heard it as one of the floating rumors of the day. I, sir, never professed to have any information upon this subject, and never referred to it in any other way than as a mere on dit. I heard it mentioned in a company of gentlemen, and in the presence of Governor
                Letcher himself. The Senator from King William,
               [<span className="persName">Mr. DOUGLAS</span>] referred to it in the Senate and undertook to catechise me upon the subject. Not recognizing the right of that gentleman to catechise me in that body upon a subject of private conversation, I demurred from this right, and was sustained in so doing, by the President of the Senate. I never entertained an idea that the gentleman was connected with any such movement.</p>
              <div className="speaker" id="sp55"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I had difficulty enough, God knows, in the campaign of 1855. My friend on my left, [<span className="persName">Mr. THOMAS STANHOPE FLOURNOY</span>, <span className="persName">Mr. WISE</span>'S competitor in 1855] , I did not meet in the campaign of '55 ; but I met him here, and am happy to shake him by the hand, and I hope he will stand side by side with me in maintaining the rights of this old Commonwealth. I had trouble enough to keep him out, not to undertake to drive Governor Letcher out. [Laughter.] It never came into my mind. I did not want to take my old shoes; I preferred the new ones. [Laughter.] It may be—God only knows—that I have missed my destiny. I do say that if it had so happened, in the course of Providence, that I had this term in the Governorship of Virginia, I should much prefer it to the last term. I had rather be the commander-inchief of your army, just now, than at the time of the John Brown raid. [Laughter and applause.] But, sir, I could not have been coaxed to consent to such an absurd idea as this conspiracy. My friend remarked that it did not enter into his head that I was a rash man, with hot blood. Let me say that I would not give a cent for any man that is not a man of hot blood. A cold-blooded man—</p>
              <div className="speaker" id="sp56"><span className="persName">Mr. STUART</span>—</div>
              <p className="p-in-sp">I merely excepted him from the class of men referred to in the resolution.</p>
              <PageNumber num={60} />
              <div className="speaker" id="sp57"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                I understand the gentleman. I accepted the remark, sir, in kindness and courtesy. But I say, at the same time, that I prefer a hot-blooded man—and I thank God that he has so constituted me that I am of hot blood, and I thank God that he so constituted me that I have, if not a rational talent, a natural instinct, that has taught me, in fifty-four years, that sometimes the aphorism of
                Andrew Jackson is a truth, that "by the eternal God, there is a policy in rashness too." When I do a rash thing—rash according to the opinions of every cold-blooded man, and prudent man, and conservative man, as he calls himself with a great deal of self-flattery—I beg him to know that I don't do it without a calculation; and the more suddenly I act, and the more rapid and rushing the
                rashness, the more calculation I have spent upon it. I never have attempted by a single floating thought to have calculated the one hundredth part of a second on such an idea as this. Whenever I am compelled to take a revolutionary stand—and God forbid that any patriot should be compelled to take such a stand—it shall be such a stand as my fathers taught me to take in the American
                revolution itself—it shall be open—it shall be above board—it shall defy the halter—it shall defy the sword and the bayonet.
               [Subdued applause.] I will do what I have promised to do—to call upon my sovereign State to cover me with her aegis, and when she fails me, and her shield is lowered in the dust, to be trampled upon by the foot of Black Republicanism, I will turn from it in weeping, but not in despair, and rush to some sister Commonwealth and beg her to come and help me to save my mother. [Applause.] And then, covered with the shield and the armor of State sovereignty, I will, with naked breast, meet the traitors at every point. [Applause.] I know my weakness, but I know my integrity in this matter. I will save as long as I can help to save, and then do my little all to fight.</p>
              <p>On motion of <span className="persName">Mr. MONTAGUE</span> the Convention then adjourned.</p>
            </div>
            <div className="day" id="vsc1965.v1.2.4">
              <PageNumber num={61} />
              <h2><span className="headingNumber">1.4. </span><span className="head">FOURTH DAY</span></h2>
              <div className="dateline">Saturday, <span className="date">February 16</span></div>
              <p>The Convention met at 12 o'clock.</p>
              <p>Prayer by the Rev. Mr. Duncan.</p>
              <div className="speaker" id="sp58"><span className="persName">Mr. STUART</span>—</div>
              <p className="p-in-sp">I ask the attention of the Convention for one moment to make a personal explanation.</p>
              <p className="p-in-sp">Yesterday, the gentleman from Princess Anne [<span className="persName">Mr. WISE</span>] read a letter to this Convention signed by two gentlemen of Augusta, and addressed to the Senator from King William [<span className="persName">Mr. B. B. DOUGLAS</span>] 
                charging that I had said that I possessed evidence of the fact of a certain conspiracy being on foot to remove Gov. Letcher from his position. I had not a correct conception of the import of that letter. Understanding that the statement was that I claimed to have information in reference to a supposed plot to subvert the government and at once substitute another gentleman for the
                present incumbent of the office of Governor, I utterly denied the fact. I have had an opportunity since the adjournment of the Convention of reading the letter, and can readily understand how gentlemen fell into the error. I find it stated there that the gentleman from Princess Anne
               [<span className="persName">Mr. WISE</span>] 
                had been telegraphed to, and in that connection these gentlemen expressed their disbelief of it. I stated that I had information upon that subject which satisfied me that it was true. I have no doubt, I used the language, but used it in reference to the telegram and not in reference to the plot said to be on foot. I had no disposition to impute to these gentlemen any purpose to pervert
                what I said on the occasion referred to, and in reading the letter I very readily conceived how they fell into the mistake of confounding my statement that I had information in reference to the telegraphic despatch with the idea that my information was in reference to the plot. I repeated that I had no information in reference to the supposed plot. All I had said was that I had
                information of a telegraphic despatch being sent to the gentleman from Princess Anne, which information did in no wise complicate that gentleman with any plot of the character referred to. The telegraph was prepared to be sent to him but not received by him. I will tell how I received the information.
              </p>
              <p className="p-in-sp">Sitting in my room, with <span className="persName">Mr. WICKHAM</span>, of Hanover, and <span className="persName">Mr. ARMSTRONG</span>, a gentleman came in, who stated that he had seen the telegraph and that several signatures were attached to it and others were to be added.</p>
              <PageNumber num={62} />
              <p className="p-in-sp">The telegraph read as follows: "Your friends think your services are needed here-come on immediately."</p>
              <p className="p-in-sp">The information which I stated to have had, referred to that dispatch and nothing else. My friend from Hanover [<span className="persName">Mr. WICKHAM</span>] is aware of the facts in reference to this dispatch, and his statement will be found to accord with what I say.</p>
              <p className="p-in-sp">
                <span className="persName">Mr. WICKHAM</span> made a statement in conformity with that submitted by <span className="persName">Mr. STUART</span>, stating, moreover, that from the intimate relations which they both held in the Senate, and their habit of exchanging views and communicating one to the other all that they knew of political matters, <span className="persName">Mr. STUART</span> would
                have mentioned this matter of the plot to him if he had the information regarding it, which the letter erroneously, no doubt, represents him as stating to have had.
              </p>
              <div className="section" id="vsc1965.v1.2.4.1">
                <h3><span className="headingNumber">1.4.1. </span><span className="head">COMMITTEE ON FEDERAL RELATIONS</span></h3>
                <p><span className="persName">The PRESIDENT</span> announced the following as the Committee on Federal Relations, ordered to be appointed on Friday:</p>
                <p>
                  R. Y. Conrad, of Frederick; Henry A. Wise, of Princess Anne; A. H. H. Stuart, of Augusta; Robert E. Scott, of Fauquier; William Ballard Preston, of Montgomery; Lewis E. Harvie, of Amelia; Sherrard Clemens, of Ohio; William H. Macfarland, of Richmond; William McComas, of Cabell; Robert L. Montague, of Matthews and Middlesex; Samuel Price, of Greenbrier; Valentine W. Southall, of
                  Albemarle; Waitman T. Willey, of Monongalia; James C. Bruce, of Halifax; W. W. Boyd, of Botetourt; James Barbour, of Culpeper; Samuel C. Williams, of Shenandoah; Timothy Rives, of Prince George and Surry; Samuel McDowelll MOORE, of Rockbridge; Geo. Blow, Jr., of Norfolk city; Peter C. Johnston, of Lee and Scott.' <span className="persName">Mr. STUART</span> asked to be excused from
                  service on the Committee, on the ground that being a member of the Senate of Virginia he would be compelled to neglect his duties in that body, if required to act upon the Committee. Under the presel!t arrangement, he is enabled to attend both the Senate and Convention, which he could not do if on the Committee just announced.
                </p>
                <p>The vote on excusing <span className="persName">Mr. STUART</span> was put and carried.</p>
                <p><span className="persName">Mr. CLEMENS</span> asked to be excused from service on the Committee on the ground of ill health. He asked for the privilege of resigning in favor of JOHN J. JACKSON, of Wood.</p>
                <p>The Convention excused <span className="persName">Mr. CLEMENS</span> and agreed to substitute <span className="persName">Mr. JACKSON</span>, of Wood, as he suggested.</p>
                <PageNumber num={63} />
                <p><span className="persName">The PRESIDENT</span> appointed <span className="persName">Mr. J. B. BALDWIN</span>, of Augusta, in place of <span className="persName">Mr. STUART</span>.</p>
              </div>
              <div className="section" id="vsc1965.v1.2.4.2">
                <h3><span className="headingNumber">1.4.2. </span></h3>
                <p>COMMITTEE ON ELECTIONS</p>
                <p>
                  <span className="persName">The PRESIDENT</span> next announced the following committee on Privileges and Elections, ordered to be appointed on Friday : Alpheus F. Haymond of Marion, Wm. L. Goggin of Bedford, Wm. C. Brown of Preston, J. R. Chambliss of Greensville and Sussex, Allen T. Caperton of Monroe, Wm. M. Ambler of Louisa, Algernon S. Gray of Rockingham, Eppa Hunton of Prince
                  William, John A. Campbell of Washington, Wm. M. Tredway of Pittsylvania, Addison Hall of Lancaster and Northumberland, James W. Sheffey of Smyth, and Dr. Patrick of Kanawha.
                </p>
                <p><span className="persName">The PRESIDENT</span> submitted the election returns on the "reference" question; which, on motion of <span className="persName">Mr. DORMAN</span>, were referred to the Committee of Privileges and Elections.</p>
              </div>
              <div className="section" id="vsc1965.v1.2.4.3">
                <h3><span className="headingNumber">1.4.3. </span><span className="head">EDITORS AND REPORTERS OF NEWSPAPERS</span></h3>
                <p><span className="persName">Mr. SUTHERLIN</span> offered the following resolution, which was adopted :</p>
                <p>Resolved, That the editors of newspapers published in this State, and reporters to the same, be admitted to seats in this Convention under the direction of the President.</p>
              </div>
              <div className="section" id="vsc1965.v1.2.4.4">
                <h3><span className="headingNumber">1.4.4. </span><span className="head">THANKS TO THE YOUNG MEN'S CHRISTIAN ASSOCIATION</span></h3>
                <p><span className="persName">Mr. TURNER</span> offered a resolution tendering the thanks of the Convention to the Young Men's Christian Association for their offer of the free use of their library and reading room to the Convention.</p>
              </div>
              <div className="section" id="vsc1965.v1.2.4.5">
                <h3><span className="headingNumber">1.4.5. </span><span className="head">PRESENTATION OF PORTRAITS</span></h3>
                <p><span className="persName">The PRESIDENT</span> submitted a communication from a Richmond lady accompanying a portrait of Monroe to be hung in the hall of the Convention, as also a communication from a Richmond gentleman, tendering a painting of Washington crossing the Delaware, for a like purpose.</p>
                <p>On motion of <span className="persName">Mr. MACFARLAND</span>, it was ordered that the thanks of the Convention be conveyed to the donors of these portraits by the clerk.</p>
              </div>
              <div className="section" id="vsc1965.v1.2.4.6">
                <h3><span className="headingNumber">1.4.6. </span><span className="head">ALLOTMENT OF SEATS FOR SPECTATORS</span></h3>
                <p>
                  <span className="persName">Mr. EARLY</span> said that on Monday next, when the Commissioners from South Carolina, Georgia and Mississippi would be received in <PageNumber num={64} /> the Hall, a great rush was to be expected. In order to obviate that result, and to give old men, who were unable to force their way in, a chance to be present, he would submit
                  the following resolution :
                </p>
                <p>
                  Resolved, That the sergeant-at-arms of this Convention do ascertain the number of persons that can be accommodated on the gallery and space set apart for gentlemen as spectators; and that the Secretary issue tickets of admission equally to the number that can be accommodated, and distribute them equally among the members of the Convention ; and that no person be admitted to a seat on
                  the gallery or space on Monday next, without a ticket of admission.
                </p>
                <div className="speaker" id="sp59"><span className="persName">Mr. MONTAGUE</span>—</div>
                <p className="p-in-sp">I do not like that resolution.</p>
                <div className="stage it">[Applause on the gallery.]</div>
                <div className="speaker" id="sp60"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">
                  Gentlemen : I avail myself of this, the first, and I trust, the last disorder of the day, to say, that I shall feel it to be duty whether a motion comes from the floor to that effect or not, to order the sergeant-at-arms to clear the galleries, if we have any more such scenes as we had on yesterday and this morning. I participate in the curiosity that is manifested to hear the
                  proceedings of this body. It is a laudable curiosity; but it is one that cannot be indulged at the expense of its order and dignity of its proceedings.
                </p>
                <div className="speaker" id="sp61"><span className="persName">Mr. MONTAGUE</span>—</div>
                <p className="p-in-sp">I am unwilling to vote for any resolution that proposes a system of favoritism. It is no part of the duty of members of this body to be suggesting who should be admitted to seats here. If the resolution is adopted, I will distribute tickets to no one. I am willing to give all an opportunity to come here and witness our proceedings.</p>
                <p><span className="persName">Mr. FISHER</span> moved to amend the resolution by ordering that the Convention shall meet on Monday in the African Church.</p>
                <p><span className="persName">Mr. TURNER</span> moved to lay the resolution and substitute on the table. Rejected—ayes 36, noes 51.</p>
                <p>The question was then taken on the amendment, and decided in the negative. The resolution was then adopted.</p>
                <p><span className="persName">Mr. MARR</span>, of Fauquier, offered the following resolution :</p>
                <p>
                  Resolved, That Virginia cherishes a devoted attachment to the Union of these States under the Constitution framed by the wise and patriotic men of the past—that she will use every honorable effort, and make any sacrifice consistent with her honor and interest, to restore and maintain it—but that it is proper to declare through the Convention now assembled, her opposition to the
                  coercion, under existing circumstances, of any slave State, and an unalterable determination not to submit to any Administration of the Government in which her <PageNumber num={65} /> rights are assailed or not fully protected, and that if the Union cannot be restored and preserved upon terms honorable to its component parts, it should be divided.
                </p>
                <p>The resolution stood referred, under the rule, to the committee on Federal relations.</p>
                <p><span className="persName">Mr. MORTON</span>, of Orange and Green, offered the following resolutions, which stood referred to the same committee under the same rule :</p>
                <p>1. Resolved, That the people of Virginia, in Convention assembled, do solemnly declare, that she will not submit to the coercion of the seceded States upon the pretext of the enforcement of the laws of the United States, or upon any pretext whatever.</p>
                <p>2. Resolved, That she solemnly protests against the use of the standing army and navy of the United States by the General Government, and the concentration of troops at the Federal Metropolis and at the forts, arsenals, navy yards, &amp;c., to coerce any State or States now in or out of the Union.</p>
                <p>3. Resolved, That this Commonwealth ardently desires to restore the Federal Union, and to preserve it upon terms of safety and honor to all its members; but if the efforts now being made prove unavailing, she will not hesitate to unite herself with her sister States of the South.</p>
                <div className="speaker" id="sp62"><span className="persName">Mr. MORTON </span>—</div>
                <p className="p-in-sp">As I understand, it will be no violation of the rules of the Convention for me to accompany the resolutions with a few remarks.</p>
                <p className="p-in-sp">
                  We have been now in session, I think, three days or four, and whilst the nation is upon the tip-toe waiting for something from this Convention, that body has been occupied so far in its organization merely. And when we behold the clouds that are accumulating so portentously over our horizon, it is proper that this Convention should, if possible, present some plans to restore harmony.
                </p>
                <p className="p-in-sp">
                  Mr. President, upon every one of these resolutions, I think, stands the safety, the peace and the honor, not only of this Commonwealth, but of the Union. Is there a man who has Virginia birth, who has Virginia feeling, who participates in the slightest degree in the spirit of '76, that can for one moment tolerate the idea of the coercion of any sovereign State in or out of this
                  Union? Has not the Virginia Legislature declared with unanimity that the coercion of a sister Southern State will be a cause of civil war, and be resisted with all the military power of the Commonwealth of Virginia? Will not that sentiment be responded to by the members of this Convention fresh from the people? Mr. President, I think I hazard nothing in saying that that is the
                  sentiment of Virginia, and without such a sentiment as that from <PageNumber num={66} /> Virginia, I should consider her as low and degraded and depressed as poor Ireland. If upon a sectional question now the South is to be crushed by the powers of the Federal Government, when the executive, the legislative and the judicial power of the Government will
                  be in the hands of a sectional hostile party, I ask you, if there is a Virginian or a Southern man that can raise himself to the dignity of a man, and say that he will submit to it? I ask if her condition is not as bad as that of Ireland? She has the privilege of being represented in the British House of Commons, but she is in a minority. The Queen belongs to England, and sits upon
                  the English throne; and although our people have a President who has no hereditary title to office, and merely elected for four years, he may be more tyrannical and oppressive than any crowned head in Europe. I take it, sir, that with regard to the first resolution there can be no objection in the hearts of Virginians.
                </p>
                <p className="p-in-sp">With respect to the second resolution, when we find that the President of the United States permits the Lieutenant General of the army of the United States to assemble in Washington the cohorts of the regular army, merely to keep a watch upon my distinguished friend on my right [Gov. WISE] , [laughter] 
                  and restless spirits in Virginia and Maryland, it is time that we should be aroused to a sense of the danger which menaces us. The alleged purpose for the assembling of the army at Washington is a mere pretext. This is not the cause. There was no testimony to sustain this pretext; there was no belief in the bosom of General Scott that there was the slightest danger of any raid upon
                  Washington. The object in bringing these troops to Washington was a different one from that which has been alleged. The object was to concentrate forces in Washington, and to arm our forts and arsenals, with a view to carry out the plan of a general armament such as General Scott indicated in October last, preparatory not only<span className="note" id="Note17"
                  ><span className="noteLabel">1</span>This first "not only" appears superfluous.</span
                  >
                  to the coercion of not only the seceding States, but any State which, feeling itself aggrieved, might attempt to resist oppression. But is it not a melancholy thing that in our day and our age, in the land of Washington, within sight of that which, I trust, will ever be an eternal monument to the memory of the great statesman and soldier, the greatest man that the world ever
                  produced—I say, is it not a melancholy thing that within view of the grave of the Father of his Country, the Commander-in-Chief of the army, should assemble a body of troops to intimidate the people of Virginia and Maryland? If that is not the meaning of the movement, it means nothing. So much for the second resolution.
                </p>
                <PageNumber num={67} />
                <p className="p-in-sp">
                  The third resolution, I trust, has a response in the heart of every member of this Convention, that it is the wish of the Commonwealth of Virginia, if possible, to preserve or to restore this Union. But how? With safety and with honor, not to Virginia alone, but with safety and with honor to every member of this Confederacy. That, I trust, is the wish of every Virginian—of every man
                  in the Union. But if all our efforts prove unavailing—if we fail to restore the Union, with safety and with honor to all of its members, then, we say, in distinct voices, which I trust will be unanimous, that we shall separate from our enemies and oppressors, and make common cause with our friends in the South.
                </p>
                <div className="speaker" id="sp63"><span className="persName">Mr. MOORE</span>, of Rockbridge, said :</div>
                <p className="p-in-sp">
                  It was not my purpose to have engaged in any debate at this early stage of the Convention ; but my friend has offered resolutions in which I do not acquiesce. I refer to one of them in particular. I do not want that it shall go before the committee without an expression of objection to it on my part. I am opposed to any resolution like this, which proposes to hand us over, bound hand
                  and foot, to the Cotton States, which have disregarded us in their proceedings. The last part of the last resolution is the part to which I except in particular. I do not choose to go over with those who did not choose to consult us before they took the step which they have taken. If I go out of the Union, I want to know who I go with, and the terms upon which we are to stand with
                  our new associates. As to what the Legislature has done and said, I will say that they did say what they had no right to say. We are a body superior to the Legislature. I would even prefer to suffer some before going where we may encounter worse.
                </p>
                <div className="speaker" id="sp64"><span className="persName">Mr. WISE</span> said,</div>
                <p className="p-in-sp">if he understood his friend on his left [Mr. MOORE] , he will not retire from the bondage in which he now is, for fear he may be put in bondage by somebody else. If he understood the gentleman, he said he would not go out though he was suffering wrong.</p>
                <div className="speaker" id="sp65"><span className="persName">Mr. MOORE</span>—</div>
                <p className="p-in-sp">I said I would not go out until I knew where I was to go.</p>
                <div className="speaker" id="sp66"><span className="persName">Mr. WISE</span>—</div>
                <p className="p-in-sp">He won't go until he knows where to go; he won't go until somebody comes and compresses him tighter than he is now compressed. [Laughter.] You [addressing himself to Mr. MOORE] 
                  , will wait and be content with the ills that you suffer until you see if you will have an easier master. Then we are elected as a body superior to the Legislature! God help such a character. This body has been deprived of all its pretended character of sovereignty. It has no determinate power. The Legislature had the honor of creating two
                  <PageNumber num={68} /> bodies—this Conventional body, without any power at all—a creature that was given no power to pro-create any result ; and then it created another body to revise and correct. This Convention was elected to do in his
                 [<span className="persName">Mr. W</span>.'s] opinion, nothing else on God Almighty's earth but to prepare the ground and cause for civil war, by failing to do anything—powerless to provide any remedy, until no remedy shall be left. The gentleman [Mr. MOORE] intimates that he has a master; he speaks very much like a master himself ; for he tells us that he shall have his own way. Shall! That is imperative. He [<span className="persName">Mr. W</span>.] 
                  would say so too; he would join him in that autocracy. He is one of the people, and so help his God he meant to have his own way first and last. It may lead him to a bad failure—it may lead him to a bitter end ; but those who cry peace, peace, when there is no peace, and who are taking these dilatory steps, these now miscalled conservative steps, to allow the real disunionists to
                  dissolve the Union, are the worst enemies to peace and Union. He would fight the real disunionists to the bitter end. And who are they? Are they those States that have been driven from the Union? No. South Carolina has been denounced by you all; you passed "force bills" over her to put her to the test of arms, a few years ago. Why don't you get Congress to pass force bills against
                  the Black Republican States? They are the Union breakers, and not those States that have been compelled to secede. Are you going to make fish of one and flesh of another? Are you going to make Massachusetts and Ohio—yes, Ohio, the dear little creature—flesh of your flesh and bone of your bone, unite with her population until she abolitionizes Virginia within her own limits? She has
                  done so already along the regions bordering upon her Territory.
                </p>
                <div className="speaker" id="sp67"><span className="persName">Mr. MOORE</span>—</div>
                <p className="p-in-sp">In what case?</p>
                <div className="speaker" id="sp68"><span className="persName">Mr. WISE</span></div>
                <p className="p-in-sp">
                  She has done it in the whole tier of counties along the Ohio river. You can no more own a slave there than on the other side of the Ohio. She maintains a terminus for an underground railroad within her territory, and she has brought you to test what have been the rights of Virginia at high water and low water mark. You talk about slavery in the territories; you talk about slavery in
                  the District of Columbia—when its tenure in Virginia is doubtful. Are we to be submissive to these wrongs? Why, he
                 [<span className="persName">Mr. W</span>.] 
                  would tell the gentleman that Virginia is brave enough and strong enough distinctly to say to all, that rather than be deprived of self-government—rather than be deprived of social safety—rather than be denounced until bitter hatred, sectional hatred has become almost personal hatred between the two sections—between the two people, North and South, if she
                  <PageNumber num={69} /> is compelled to go out of this Union and cannot find associates with whom she can unite on proper and just terms, she will stand erect, sovereign and independent by herself. If she can't find an associate that will treat her on better terms than she is now treated she will stand alone. Is it likely that we are to be enslaved and
                  oppressed by those who are homogeneous in interest with us—homogeneous in every proprietary interest and every social feeling? Which are likely to give us the best terms—those who are identified with us in every feeling, or those who have no common interest with us? There was no need to dwell upon that matter. The choice was too advantageous on one side to admit of a doubt as to the
                  course which any true son of. Virginia would take.
                </p>
                <p className="p-in-sp">He [<span className="persName">Mr. WISE</span>] saw it stated that Lincoln, in a speech of his recently, remarked that there was no harm done yet ; and another leader of the Black Republicans says, wait for one, two or three years, and then we shall be relieved of our apprehensions. We will certainly be relieved of our negro property. [Laughter.] He [<span className="persName">Mr. W</span>.] 
                  was for Union. We of the South have kept the faith of the covenant; you at the North have broken it. If the Union is to be dissolved who are responsible for it? Not the South, but those Black Republicans who disregard every constitutional obligation, and every constitutional right which the South holds dear. The gentleman cries peace, peace, peace, when we are about to put into the
                  hands of a hostile power all the means and munitions to make war—war, the most horrid—social, civil war, servile war. He
                 [<span className="persName">Mr. WISE</span>] 
                  would say to those who would commit him to a bait like that, that they may think they will get peace if they do not fight the Yankee invader, the Black Republican intruder. They shall not, without a fight, intercept me if I can meet him. We have to choose whether we will fight the common enemy of all, or whether we are to fight with each other. Now, my disposition is, not to fight
                  among ourselves. I implore Virginians not to wrangle among themselves. He
                 [<span className="persName">Mr. W</span>.] 
                  knew that this national raid is not only against the institution of slavery, but that it is an agrarian rush upon lands as well as negroes. When you have done nothing—when it is declared to you that you are to wait, wait until you are bound, hand and foot, you will have stampeded this State of half its slaves or nearly all; you will have driven away from their hearth-stones thousands
                  of the best hearts—hearts devoted to your altars and your homes, and then you will have cheap lands for the accommodation of a Northern population.
                </p>
                <p className="p-in-sp">
                  We are all, sir, in one category now. That is one thing that you <PageNumber num={70} /> cannot escape from. We are already on one side, in a category which is intolerable. The election is made. The Union has lasted from '89 down to this time; and are you in it now? The American Constitution, upon which that Union was founded, has been trampled upon, and
                  the question is put to you whether you will bow under this Northern master, who has violated the Constitution, or whether you will protect it? And how will the gentleman
                 [Mr. MOORE] act in this emergency? Will he tell me whether or not he has decided, before this time, where he will go? which side he will take, if separation must come? He [<span className="persName">Mr. W</span>.] would tell him that he has decided. He would tell the gentleman [Mr. MOORE] that he has no knowledge of his own what decision he has come to; but he inferred, from what he has already said, that he is immovably decided. He don't want one moment, in his [<span className="persName">Mr. W</span>'s.] opinion, to say which part he will take. The gentleman from Rockbridge [Mr. MOORE] will go North.</p>
                <div className="speaker" id="sp69"><span className="persName">Mr. MOORE</span>—</div>
                <p className="p-in-sp">You have no authority to say where I will go.</p>
                <p><span className="persName">Mr. WISE</span> remarked that he had already stated that he had no other knowledge on the subject except what he had from inference, and he had that as his own authority. He [<span className="persName">Mr. W</span>.] would say that it cannot be supposed that positive men, men of mind, have not taken their stand as to what course they will pursue in this crisis. He would suppose that the gentleman [Mr. MOORE] has decided, from motives that he did not want to impugn in the least, that his destiny was with the North. Let me tell the gentleman, that if he does march North and he [<span className="persName">Mr. W</span>.] South, he hoped they would not have to meet each other in serried ranks. If we are to separate, let us separate in peace. He would appeal to the gentleman to stand firm by his own section and aid him in driving out the Black Republican enemy who alone are responsible for the present state of things.</p>
                <div className="speaker" id="sp70"><span className="persName">Mr. MOORE</span>—</div>
                <p className="p-in-sp">I will.</p>
                <div className="speaker" id="sp71"><span className="persName">Mr. WISE</span>—</div>
                <p className="p-in-sp">
                  Will you agree to raise an army, then? Will you ask the General Government to withdraw the troops from Washington which have been assembled there to form the nucleus of an army of coercion? Will you assist me to drive out a parcel of Wide Awakes who may be stationed in Gosport navy yard to ransack the counties of Princess Anne and Norfolk, and rob her roosts for provisions?<span
                    className="note"
                    id="Note18"
                  ><span className="noteLabel">2</span>See the Proceedings of February 20, pages 119-121, for Mr. Wise's correction of this question.</span
                  >
                 [Laughter.] He [<span className="persName">Mr. W</span>.] would ask gentlemen who are eager for peace if the way to preserve the peace is to wait until after the 4th of March? He would ask them if they really desired to preserve the peace, to do what will preserve it. He [<span className="persName">Mr. W</span>.] was accused of engaging in raids already. They would have reason to charge him with <PageNumber num={71} /> worse if an attempt was made here to make him preserve the peace by submission to Black Republican domination.</p>
                <div className="stage it">[<span className="persName">Mr. WISE</span> addressed the Convention at considerable length, but we are necessarily obliged to give only a brief sketch of his remarks.]</div>
                <div className="speaker" id="sp72"><span className="persName">Mr. MOORE</span>—</div>
                <p className="p-in-sp">
                  My friend has told us about our Northern masters. I was not aware that the people of Virginia had any other master than the God of Heaven. The gentleman talks about going North or South. For one, I will say, that I don't mean to submit to the North, and I don't mean to submit to the South. I mean to maintain the rights of Virginia here and everywhere else. Sir, I disdain to submit to
                  the North. The gentleman asks how long we are to wait? I mean to wait until I know where we are to go, whether that time be the 4th of March or after.
                </p>
                <div className="speaker" id="sp73"><span className="persName">Mr. WISE</span>—</div>
                <p className="p-in-sp">When is that?</p>
                <div className="speaker" id="sp74"><span className="persName">Mr. MOORE</span>—</div>
                <p className="p-in-sp">
                  The gentleman will find out when I come to submit my proposition. I will show at the proper time that there is a conflict of interest between Virginia and the cotton States, more especially if their policy be to re-open the African slave trade, or make free ports and adopt the system of direct taxation, which would be absolutely ruinous to Virginia. I never will go with those States
                  if they have free ports and direct taxation.
                 [<span className="persName">Mr. M</span>. concluded with some remarks against coercion.] <span className="persName">Mr. CARLILE</span>, after a few brief remarks, offered the following resolution, which was referred to the committee on Federal Relations :</p>
                <p>
                  Resolved, That since the decision of the Supreme Court of the United States in the case of Chisholm vs. the State of Georgia, and the adoption of the 11th amendment to the Constitution, we are at a loss to understand how the impression, that the Federal Government, has the power to coerce,<span className="note" id="Note19"
                  ><span className="noteLabel">3</span>A correction (February 19) inserts "a State" after "coerce."</span
                  >
                  could have obtained credence.
                </p>
                <p><span className="persName">Mr. LEAKE</span>, of Goochland, offered the following resolution, which stood referred under the rule to the committee on Federal Relations :</p>
                <p>
                  Resolved, As the opinion of this Convention of the people of Virginia, that if the General Government at Washington, shall undertake forcibly to retake the forts within any of the States that have dissolved their connection with the Federal Union, Virginia will regard such acts as an invasion of the rights of sovereign States, and that if the Federal authorities undertake to collect
                  the revenue on foreign importations introduced or about to be introduced into any such States, Virginia will regard such acts as coercion ; and that her faith has been pledged, and is hereby again pledged, as far as it can be, to resist with all the means in her power all such acts of invasion and coercion.
                </p>
                <PageNumber num={72} />
                <p><span className="persName">Mr. RICHARDSON</span>, of Hanover, offered the following resolutions, which were referred to the Committee on Federal Relations :</p>
                <p>The people of the sovereign State of Virginia, in general Convention assembled, do declare and publish the following resolutions :</p>
                <ul>
                  <li className="item">1. That the compact by which the several sovereignties composing the United States of North America, were united in a Confederacy, has been repeatedly violated by individuals and States composing the Northern part of the same.</li>
                  <li className="item">2. That said compact having been thus repudiated by parties to the same, to the injury and oppression of other parties thereto, is not binding upon the latter, and exists not of right but by sufferance.</li>
                  <li className="item">3. That not only are the Sovereign States of the Union the rightful judges of the circumstances under which their honor and safety require their withdrawal therefrom, but those who have peaceably so withdrawn, were justified in so doing by invasion of their just rights.</li>
                  <li className="item">4. That we will resist the coercion of the States which have withdrawn, because there is no rightful power to use force against them, under present circumstances ; because their interest is common with ours, and because an impairment of their safety is dangerous to our own.</li>
                  <li className="item">
                    5. That in view of the grievances which the South has sustained at the hands of the North, and of the election of a chief magistrate avowedly hostile to the institutions of the former, it is the duty of the latter at once to concede such constitutional guarantees to the South as will prevent the recurrence of wrongs already inflicted on us, and secure our full and equal rights in
                    the Confederacy.
                  </li>
                  <li className="item">6. That the failure to provide against these wrongs and to secure these rights is evidence of either indifference or hostility towards us which are alike fatal to our peace and safety.</li>
                  <li className="item">7. That in view of these plain truths we demand that security for our rights and honor be accorded to us in the Confederacy as speedily-as the necessary constitutional proceedings can be carried out, and in default thereof we will dissolve our connection with those who first wantonly wrong us, and then obstinately persevere in the injury.</li>
                  <li className="item">8. That arguing from the persistent denial of our just demands and the danger of a conflict of arms, we decree that the State be forthwith put in such a condition of defence as will insure her safety, dignity and honor.</li>
                </ul>
                <p><span className="persName">Mr. JOHNSON</span>, of Richmond, offered the following resolution, which was adopted :</p>
                <PageNumber num={73} />
                <p>Resolved, That the President of the Convention be authorized to employ an assistant doorkeeper.</p>
                <p><span className="persName">Mr. FLOURNOY</span> offered the following resolutions, which were referred to the committee on Federal Relations :</p>
                <p>
                  Resolved, by this Convention, That whilst Virginia has a high appreciation of the blessings of the Union, and would do much and forbear much to perpetuate them, yet it feels itself bound to declare, that an identity of interest and of wrongs with the seceded States of the South would, in case of an attempted coercion by the Federal Government, demand and receive the interposition of
                  all her military strength in resisting such aggression.
                </p>
                <p>
                  Resolved, That Virginia hopes and believes that by prudent measures of conciliation on the part of the United States Government in its intercourse with the seceded States, and by a just appreciation of the magnitude of our present perils; that some measures of compromise between the North and the South may be adopted which will restore peace, friendship and union to every section of
                  our now distracted country.
                </p>
                <p><span className="persName">Mr. MORTON</span> offered the following resolution which was rejected :</p>
                <p>Resolved, That the sergeant-at-arms be directed to cushion the seats of members, and to provide each member with a desk or table with a lock and key.</p>
                <p><span className="persName">Mr. R. Y. CONRAD</span> informed the Committee on Federal Relations that a meeting would be held after the adjournment in the Secretary's office.</p>
                <p>On motion of <span className="persName">Mr. FORBES</span>, the Convention adjourned.</p>
              </div>
            </div>
            <div className="day" id="vsc1965.v1.2.5">
              <PageNumber num={74} />
              <h2><span className="headingNumber">1.5. </span><span className="head">FIFTH DAY</span></h2>
              <div className="dateline">Monday, <span className="date">February 18</span></div>
              <p>The Convention met at 12 o'clock.</p>
              <p>Prayer by the Rev. Mr. Read of the Presbyterian Church.</p>
              <p><span className="persName">Mr. R. Y. CONRAD</span> offered the following resolution, which was adopted :</p>
              <p>Resolved, That the Committee on Federal Relations be authorized to appoint a clerk for said committee.</p>
              <div className="section" id="vsc1965.v1.2.5.1">
                <h3><span className="headingNumber">1.5.1. </span><span className="head">NON-ATTENDANCE OF THE GOVERNOR</span></h3>
                <p><span className="persName">Mr. DORMAN</span> stated that the Governor was unavoidably absent from the Convention on account of illness. He deemed this explanation necessary in order to avoid remarks as to the cause of his absence.</p>
              </div>
              <div className="section" id="vsc1965.v1.2.5.2">
                <h3><span className="headingNumber">1.5.2. </span><span className="head">THE COMMISSIONER FROM MISSISSIPPI</span></h3>
                <p>The President stated that in pursuance of the resolution of Friday last, he begged leave to announce to the Convention, that the Hon. FULTON ANDERSON, the Commissioner from Mississippi, would now address them :</p>
                <p><span className="persName">Mr. ANDERSON</span> ascended the platform near the President's Chair, and addressed the Convention, as follows :</p>
                <div className="speaker" id="sp75">[<span className="persName">Mr. ANDERSON</span>:]</div>
                <p className="p-in-sp">
                  Gentlemen of the Convention : Honored by the Government of Mississippi with her commission to invite your co-operation in the measures she has been compelled to adopt for the vindication of her rights and her honor in the present perilous crisis of the country, I desire to express to you, in the name and behalf of her people, the sentiments of esteem and admiration which they in
                  common with the whole Southern people entertain for the character and fame of this ancient and renowned Commonwealth.
                </p>
                <p className="p-in-sp">
                  Born under the same confederated government with yourselves, and participating in the common inheritance of Constitutional liberty in the achievement of which your ancestors played so distinguished a part, we take as much of pride and pleasure as you, her native sons, in the great achievements and still greater sacrifices which you have made in the cause of the common government,
                  which has in the past united them to you; and nothing, which concerns your honor and dignity in the future can fail to enlist their deepest sympathies. In recurring to our past history, we recognize the State of Virginia as the leader in the first great struggle for independence; foremost not only in the <PageNumber num={75} /> vindication of her own
                  rights, but in the assertion and defence of the endangered liberties of her sister colonies; and by the eloquence of her orators and statesmen, as well as by the courage of her people arousing the whole American people in resistance to British aggression. And when the common cause had been crowned with victory under her great warrior-statesman, we recognize her also as the leader in
                  that great work by which the emancipated colonies were united under a written Constitution, which for the greater part of a century has been the source of unexampled progress in all that constitutes the greatness and the happiness of nations; nor do we forget that that progress has been due in a preeminent degree to the munificent generosity of Virginia, in donating as a free gift to
                  her country, that vast territory North-West of the Ohio river, which her arms alone had conquered, and which now constitutes the seat of empire, and, alas, too, the seat of that irresistible power, which now erects its haughty crest in defiance and hostility, and threatens the destruction of the honor and the prosperity of this great State.
                </p>
                <p className="p-in-sp">
                  I desire also to say to you gentlemen, that in being compelled to sever our connexion with the government which has hitherto united us, the hope which lies nearest to our hearts is that, at no distant day, we may be again joined with you in another Union, which shall spring into life under more favorable omens and with happier auspices than that which has passed away; and if, in the
                  uncertain future which lies before us, that hope shall be destined to disappointment, it will be the source of enduring sorrow and regret to us that we can no more hail the glorious soil of Virginia as a part of our common country, nor her brave and generous people as our fellow citizens.
                </p>
                <p className="p-in-sp">
                  Fully participating in these sentiments myself, it is with pride and pleasure that I accepted the commission of my State for the purpose I have indicated. Though, when I consider the gravity of the occasion, the high interests which are involved, and the enduring influence which your deliberations are to have upon the destinies of present and future generations, I confess my regret
                  that the cause on which I am come has not been entrusted to abler and worthier hands.
                </p>
                <p className="p-in-sp">
                  In setting forth to you, gentlemen, the action of my State and the causes which have induced it, I shall be compelled to speak in terms of condemnation of a large portion of what has hitherto been our common country; but, in doing so, I wish to be understood as excepting from whatever terms of censure I may employ, that large body of patriotic and conservative men of the Northern
                  section, who have, in all our struggles, manfully defended the constitutional rights of our <PageNumber num={76} /> section. From them, the people of my State, have no cause of complaint, and whatever the future may bring forth, we shall ever remember their efforts in behalf of the Constitution and Union, as we received them from their ancestors and ours
                  with admiration and gratitude. Our grievances are not from them, but from the dominant faction at the North, which has trampled them under foot and now strikes at us from the elevation it has obtained upon the prostrate bodies of our friends.
                </p>
                <p className="p-in-sp">I propose, gentlemen, in discharge of my mission to you, briefly to invite your attention to a review of the events which have transpired in Mississippi since the fatal day when that sectional Northern party triumphed over the Constitution and the Union at the recent election, and afterwards to the causes which have induced the action of my State.</p>
                <p className="p-in-sp">
                  On the 29th of November last, the Legislature of Mississippi, by an unanimous vote, called a Convention of her people, to take into consideration the existing relations between the Federal Government and herself, and to take such measures for the vindication of her sovereignty and the protection of her institutions as should appear to be demanded. At the same time, a preamble,
                  setting forth the grievances of the Southern people on the slavery question, and a resolution, declaring that the secession of each aggrieved State, was the proper remedy, was adopted by a vote almost amounting to unanimity. The last clause of the preamble and the resolution, are as follows :
                </p>
                <p className="p-in-sp">
                  "Whereas, they ( the people of the non-slaveholding States) have elected a majority of electors for President and Vice-President, on the ground that there exists an irreconcilable conflict between the two sections of the Confederacy, in reference to their respective systems of labor, and in pursuance of their hostility to us and our institutions, have thus declared to the civilized
                  world that the powers of the government are to be used for the dishonor and overthrow of the Southern section of this great Confederacy. Therefore, be it
                </p>
                <p className="p-in-sp">"Resolved by the Legislature of the State of Mississippi, That in the opinion of those who constitute said Legislature, the secession of each aggrieved State is the proper remedy for these injuries."</p>
                <p className="p-in-sp">
                  On the day fixed for the meeting of the Convention, that body convened in Jackson, and on the 9th January, 1861, proceeded to the adoption of an ordinance of secession from the Federal Union, by which the State of Mississippi withdrew from the Federal Government the powers theretofore confided to it, and assumed an independent position among the powers of the earth; determined
                  thenceforth to <PageNumber num={77} /> hold the people of the non-slaveholding section of the late confederacy as she holds the balance of mankind : enemies in war, and in peace friends. But at the same time, and by the same ordinance, it was provided "that the State of Mississippi hereby gives her consent to form a Federal Union with such of the States
                  as may have seceded, or may secede, from the Union of the United States of America, upon the basis of the present Constitution of the United States."
                </p>
                <p className="p-in-sp">This action of the Convention of Mississippi, gentlemen of the Convention, was the inevitable result of the position which she, with other slaveholding States, had already taken, in view of the anticipated result of the recent Presidential election, and must have been foreseen by every intelligent observer of the progress of events.</p>
                <p className="p-in-sp">As early as the 10th of February, 1860, her Legislature had, with the general approbation of her people, adopted the following resolution :</p>
                <p className="p-in-sp">
                  "Resolved, That the election of a President of the United States by the votes of one section of the Union only, on the ground that there exists an irrepressible conflict between the two sections in reference to their respective systems of labor and with an avowed purpose of hostility to the institution of slavery, as it prevails in the Southern States, and as recognized in the
                  compact of Union, would so threaten a destruction of the ends for which the Constitution was formed, as to justify the slaveholding States in taking council together for their separate protection and safety."
                </p>
                <p className="p-in-sp">
                  This was the ground taken, gentlemen, not only by Mississippi, but by other slaveholding States, in view of the then threatened purpose, of a party founded upon the idea of unrelenting and eternal hostility to the institution of slavery, to take possession of the power of the Government and use it to our destruction. It cannot, therefore, be pretended that the Northern people did not
                  have ample warning of the disastrous and fatal consequences that would follow the success of that party in the election, and impartial history will emblazon it to future generations, that it was their folly, their recklessness and their ambition, not ours, which shattered into pieces this great confederated Government, and destroyed this great temple of constitutional liberty which
                  their ancestors and ours erected, in the hope that their descendants might together worship beneath its roof as long as time should last.
                </p>
                <p className="p-in-sp">
                  But, in defiance of the warning thus given and of the evidences ao cumulated from a thousand other sources, that the Southern people would never submit to the degradation implied in the result of such <PageNumber num={78} /> an election, that sectional party, bounded by a geographical line which excluded it from the possibility of obtaining a single
                  electoral vote in the Southern States, avowing for its sentiment implacable hatred to us, and for its policy the destruction of our institutions, and appealing to Northern prejudice, Northern passion, Northern ambition and Northern hatred of us, for success, thus practically disfranchizing the whole body of the Southern people, proceeded to the nomination of a candidate for the
                  Presidency who, though not the most conspicuous personage in its ranks, was yet the truest representative of its destructive principles.
                </p>
                <p className="p-in-sp">The steps by which it proposed to effect its purposes, the ultimate extinction of slavery, and the degradation of the Southern people, are too familiar to require more than a passing allusion from me.</p>
                <p className="p-in-sp">
                  Under the false pretence of restoring the government to the original principles of its founders, but in defiance and contempt of those principles, it avowed its purpose to take possession of every department of power, executive, legislative and judicial, to employ them in hostility to our institutions. By a corrupt exercise of the power of appointment to office, they proposed to
                  pervert the judicial power from its true end and purpose, that of defending and preserving the Constitution, to be the willing instrument of its purposes of wrong and oppression. In the meantime it proposed to disregard the decisions of that august tribunal, and by the exertion of bare-faced power, to exclude slavery from the public Territory, the common property of all the States,
                  and to abolish the internal slave trade between the States acknowledging the legality of that institution.
                </p>
                <p className="p-in-sp">It proposed further to abolish slavery in the District of Columbia, and in all places within the Territory of the several States, subject under the Constitution to the jurisdiction of Congress, and to refuse hereafter under all circumstances, admission into the Union of any State with a Constitution recognizing the institution of slavery.</p>
                <p className="p-in-sp">
                  Having thus placed the institution of slavery, upon which rests not only the whole wealth of the Southern people, but their very social and political existence, under the condemnation of a government established for the common benefit, it proposed in the future, to encourage immigration into the public Territory, by giving the public land to immigrant settlers, so as, within a brief
                  time, to bring into the Union free States enough to enable it to abolish slavery within the States themselves.
                </p>
                <p className="p-in-sp">I have but stated generally the outline and the general programme of the party to which I allude without entering into particular details <PageNumber num={79} /> or endeavoring to specify the various forms of attack, which have been devised and suggested by the leaders of that party upon our institutions.</p>
                <p className="p-in-sp">That this general statement of its purposes is a truthful one, no intelligent observer of events will for a moment deny; but the general view and purpose of the party has been sufficiently developed by the President elect.</p>
                <p className="p-in-sp">
                  "It is my opinion," says Mr. Lincoln, "that the slavery agitation will not cease until a crisis shall have been reached and passed. A house divided against itself cannot stand. I believe this government cannot endure permanently, half slave and half free. I do not expect the house to fall, but I expect it to cease to be divided. It will become all one thing or all another. Either the
                  opponents of slavery will arrest its further spread and place it where the public mind shall rest in the belief that it is in the course of ultimate extinction, or its advocates will push it forward until it shall become alike lawful in all the States—old as well as new, North as well as South."
                </p>
                <p className="p-in-sp">
                  The party thus organized on the principle of hostility to our fundamental institutions, and upon the avowed policy of their destruction, with a candidate thus representing that principle and policy, has succeeded in the Presidential election, by obtaining a large majority of the votes of the people of the non-slaveholding States, and on the 4th of March next, would, unless prevented,
                  have taken possession of the power and patronage of our common government to wield them to our destruction. In contemptuous disregard of the principle on which that government was founded, and received our assent, to insure domestic tranquility, promote the general welfare, and, within the limit of its constitutional power, to exercise a fostering and paternal care over every
                  interest of every section, it was to become our foe and our oppressor, and never to pause in its career of hostility and oppression until our dearest rights, as well as our honor, were crushed beneath its iron heel.
                </p>
                <p className="p-in-sp">
                  We, the descendants of the leaders of that illustrious race of men who achieved our independence and established our institutions, were to become a degraded and a subject class, under that government which our fathers created to secure the equality of all the States—to bend our necks to the yoke which a false fanaticism had prepared for them, and to hold our rights and our property
                  at the sufferance of our foes, and to accept whatever they might choose to leave us as a free gift at the hands of an irresponsible power, and not as the measure of our constitutional rights.
                </p>
                <PageNumber num={80} />
                <p className="p-in-sp">
                  All this, gentlemen, we were expected to submit to, under the fond illusion that at some future day, when our enemies had us in their power, they would relent in their hostility; that fanaticism would pause in its career without having accomplished its purpose; that the spirit of oppression would be exorcised, and, in the hour of its triumph, would drop its weapons from its hands,
                  and cease to wound its victim. We were expected, in the language of your own inspired orator, to "indulge in the fond illusions of hope; to shut our eyes to the painful truth, and listen to the song of that syren until it transformed us into beasts."
                </p>
                <p className="p-in-sp">
                  But we in Mississippi, gentlemen, are no longer under that illusion. Hope has died in our hearts. It received its death-knell at the fatal ballot-box in November last, and the song of the syren no longer sounds in our ears. We have thought long and maturely upon this subject, and we have made up our minds as to the course we should adopt. We ask no compromise and we want none. We
                  know that we should not get it if we were base enough to desire it, and we have made the irrevocable resolve to take our interests into our own keeping.
                </p>
                <p className="p-in-sp">
                  I have already said that twelve months since the State of Mississippi, in company with other slaveholding States, had taken a position, in anticipation of the result of the recent Presidential election, from which they could not recede if they were base enough to desire it. I shall be pardoned by you, I trust, for adding that an event, of then recent occurrence, which deeply
                  concerned the honor and the dignity of Virginia, exercised a controlling influence in consolidating the Southern mind on this subject. When the exasperation was at the highest, which had been caused by the long and weary struggle which the Southern people had been compelled to make in defence of their institutions, the daring outrage on your soil, to which I allude, was perpetrated.
                </p>
                <p className="p-in-sp">
                  This State, relying on the faith of constitutional obligations and of those friendly relations which they were created to uphold and maintain, unconscious herself of any sentiment less noble than that of unwavering loyalty to her constitutional obligations, and, therefore, wholly unsuspicious of any treasonable design against her own peace and welfare, was, in a moment of fancied
                  repose, in a time of profound peace, was, to her own amazement and that of the whole Southern people, made the scene of a foray by a band of conspirators and traitors from the Northern States, whose purpose was to light up the fires of a servile insurrection, and to give your dwellings to the torch of the incendiary and your wives and children to the knives of assassins. The
                  disgraceful attempt, it is true, ended in ignominious <PageNumber num={81} /> failure. True that your slaves proved loyal, and by a prompt execution of your laws you vindicated your dignity and exacted from the wretched criminals the just forfeiture of their lives. But the event had, nevertheless, a terrible significance in the minds of Southern people.
                  It was justly considered as the necessary and logical result of the principles, boldly and recklessly avowed by the sectional party which was then grasping at the reins of government and which is now about to be inaugurated into power.
                </p>
                <p className="p-in-sp">
                  Let it not be supposed that I refer to this disgraceful event with a desire to stir up a spirit of hostility and revenge, or to re-awaken those sentiments of just indignation which the fact is SO, well calculated to excite. I refer to it as a necessary and legitimate result of the irrepressible conflict which has been proclaimed, of which the President elect gave a true exposition
                  when he said "There is a judgment and a conscience at the North against slavery, which must find an outlet either through the peaceful channel of the ballot box or in the multiplication of John Brown raids." I refer to it as a warning to the people of the Southern States, and to you the people of Virginia, of what they and you are to expect in the future when that party, whose
                  principles thus give encouragement, aid and comfort to felons and traitors, shall have firmly established its dominion over you.
                </p>
                <p className="p-in-sp">
                  These are some of the causes, gentlemen, which have at last convinced the people of Mississippi that the hour has arrived when if the South would maintain her honor, she must take her own destiny into her own hands; but let it not be supposed that they have not always felt a strong attachment to the union of the Constitution, provided that instrument could be administered in the
                  spirit in which it was created. That form of Government, on the contrary, is dear to their hearts and its necessity to them and their posterity has received the sanction of their judgments. Loving it not wisely, but too well, they have clung to it long after its obligations were abandoned by those who were the chief recipients of its benefits, under the fond illusion that a returning
                  sense of justice and a restoration of fraternal relations formerly existing, would secure to them their rights. They long and vainly hoped that the time would again return, when each and every section of the Confederacy would recognize the rights and interests of all, and that we might in harmony with each other have continued to rejoice over what had been achieved of glory and
                  prosperity in the past, and to look forward with united hope to the bright and glorious prospect which an observance of the principles of the Constitution promised in the future.
                </p>
                <PageNumber num={82} />
                <p className="p-in-sp">Alas, how has that hope been disappointed; how has that illusion been dispelled!</p>
                <p className="p-in-sp">
                  Could we think that the crisis which is now upon us was but a temporary ebullition of temper in one section of the country, which would in brief time subside, we might even yet believe that all was not lost, and that we might yet rest securely under the shadow of the Constitution. But the stern truth of history, if we accept its teachings, forbids us such reflections. It is not to be
                  denied that the sentiment of hatred to our institutions in the Northern section of the Confederacy is the slow and mature growth of many years of false teaching, and that as we receded further and further from the earlier and purer days of the Republic, and from the memory of associated toils and perils in a common cause which once united us, that sentiment of hatred has been fanned
                  from a small spark into a mighty conflagration, whose unextinguishable and devouring flames are reducing our empire into ashes.
                </p>
                <p className="p-in-sp">
                  Ere yet that generation which achieved our liberty had passed entirely from the scene of action, it manifested itself in the Missouri controversy. Then were heard the first sounds of that fatal strife which has raged, with occasional intermissions, down to this hour. And so ominous was it of future disaster, even in its origin, that it filled even the sedate soul of Mr. Jefferson
                  with alarm ; he did not hesitate to pronounce it, even then, as the death-knell of the Union, and in mournful and memorable words to congratulate himself that he should not survive to witness the calamities he predicted. Said he :
                </p>
                <p className="p-in-sp">
                  "This momentous question, like a fire-bell in the night, awakened and filled me with terror. I considered it at once as the knell of the Union. It is hushed, indeed, for the present, but that is only a reprieve, not a final sentence. A geographical line, coinciding with a marked principle, moral and political, once concurred in and held up to the passions of men, will never be
                  obliterated, and every new irritation will mark it deeper and deeper, until it will kindle such mutual and mortal hatred as to render separation preferable to eternal discord. I regret that I am now to die in the belief that the useless sacrifice of themselves by the generation of 1776, to acquire self-government and happiness for their country, is to be thrown away by the unwise and
                  unworthy passions of their sons, and that my only consolation is to be that I live not to weep over it."
                </p>
                <p className="p-in-sp">
                  But, so far were the Northern people from being warned by these sad prophetic words, that at each renewal of the struggle the sentiment of hostility has acquired additional strength and intensity. The <PageNumber num={83} /> passions enlisted in it have become more bitter, the disregard of constitutional obligations more marked, and the purpose to
                  destroy our institutions more fixed and definite.
                </p>
                <p className="p-in-sp">
                  An infidel fanaticism, crying out for a higher law than that of the Constitution and a holier Bible than that of the Christian, has been enlisted in the strife, and in every form in which the opinions of a people can be fixed and their sentiments perverted. In the schoolroom, the pulpit, on the rostrum, in the lecture-room and in the halls of legislation, hatred and contempt of us
                  and our institutions, and of the Constitution which protects them, have been inculcated upon the present generation of Northern people. Above all, they have been taught to believe that we are a race inferior to them in morality and civilization, and that they are engaged in a holy crusade for our benefit in seeking the destruction of that institution which they consider the chief
                  impediment to our advance, but which we, relying on sacred and profane history for our belief in its morality, believe lies at the very foundation of our social and political fabric and constitutes their surest support.
                </p>
                <p className="p-in-sp">This, gentlemen, is indeed an irrepressible conflict which we cannot shrink from if we would ; and though the President elect may congratulate himself that the crisis is at hand which he predicted, we, if we are true to ourselves, will make it fruitful of good by ending forever the fatal struggle and placing our institutions beyond the reach of further hostility.</p>
                <p className="p-in-sp">
                  I know not, gentlemen, what may be your views of the subject, nor what you purpose in this crisis ; but I have already told you what the people of Mississippi have resolved on, and to that determination, you may rely upon it, they will adhere through every extremity of prosperous or adverse fortune. They, like you, are the descendants of a revolutionary race, which for far less cause
                  raised the banner of resistance against a far mightier power, and never lowered it until that victory which the god of battles gives to brave men in a just cause, had crowned their efforts and established their independence ; and they have, like them, decided that the time has arrived to trust for the safety of their honor and rights only to their own strong arms and stout hearts,
                  rather than submit to placing those priceless blessings in the keeping of their inveterate foes.
                </p>
                <p className="p-in-sp">
                  I shall enter, gentlemen, into no discussion of the right of secession, whether it be peaceful and constitutional or violent and revolutionary. If declared that the question must, in the nature of things, be decided first by those who would force us back into a Union with them, <PageNumber num={84} /> which we have repudiated, and when they shall have
                  made up their minds on that subject, it will remain for us to join the issue and accept the consequences, be they peaceful or bloody. We shall do all in our power to avoid a hostile collision with those who were once our brothers, though now divided from us by an impassable gulph ; we wish them no harm, and could our prayers avail them, we would freely offer them, that in their
                  future destiny they may have that prosperity, liberty and peace which we intend to seek for ourselves under a new organization. All good men too will pray that that Providence which presides over the destinies of nations and shapes their ends, rough-hew them as they will, will so ordain that the friends of liberty throughout the world may not have cause to mourn over the folly and
                  madness and wickedness of an effort by arms on this continent, to subject a whole people, united in the vindication of their rights, and resolved to die in their defence.
                </p>
                <p className="p-in-sp">But if it must be so, and we are compelled to take up arms, we trust we shall know how to bear ourselves as freemen engaged in a struggle for their dearest rights. We have learned the lesson how to do so from the history of your own noble Commonwealth, and we shall attempt, at least, to profit by the glorious example.</p>
                <p className="p-in-sp">The conviction of the justice of their cause will be a tower of strength in the hour of battle, and inspire the hearts of the Southern people like the sounds of that divine music, which, in the words of Milton,</p>
                <p className="p-in-sp">"Cheered the hearts of heroes old, Arming to battle ; and instead of rage</p>
                <p className="p-in-sp">Deliberate valor breathed firm and unmoved By dread of death to flight or foul retreat."</p>
                <p className="p-in-sp">And when that hour comes, we know, too, where Virginia will stand. Her banner will float proudly "over the perilous edge of battle" wherever it rages, and the blood of her sons will enrich every field where Southern men strike for their rights and their honors.</p>
                <p className="p-in-sp">
                  Having thus stated the action of my State, and the causes which induced it, I should probably best consult the proprieties of the occasion, by adding nothing to what I have said. I trust, however, I shall be pardoned for offering one or two suggestions for your consideration. The fundamental idea which has influenced the action of the seceding States, is the demonstrated necessity
                  that the Southern people should take their interest and their honor into their own keeping, and thus rescue them from the power of an avowedly hostile government. It <PageNumber num={85} /> is not that they are opposed to an union of the Confederated States. Such a form of government is not only dear to their hearts, but its value and necessity to them,
                  and their posterity receives the recognition and approval of their judgments. It is no fault of theirs that the Union, as it recently existed, has ceased to be practicable or desirable. The Southern people may well recur with pride to the history of their connexion with that government. Well may they ask when have they, as States or individuals, proved faithless to the obligations it
                  imposed? In what point have they fallen short of the full measure of duty and comity to their sister States? What indulgence have they not showed to the insulting prejudices and unreasoning fanaticism of the other section? What sacrifices of blood and treasure have they not made in the common cause, and what efforts, to bring back the harmony ( halcyon days) which in the language of
                  one of her most eloquent sons, reigned in those days when Massachusetts summoned Washington to lead the armies of New England, and when Virginia and Carolina sent supplies of corn and rice to their famishing brethren in Boston?
                </p>
                <p className="p-in-sp">But such a form of government being demonstrated to be impracticable with the Northern people, all that is left us is the creation of a great and powerful Southern Union, composed of States inhabited by homogeneous populations, and having a common interest, common sympathies, common hopes, and a common destiny.</p>
                <p className="p-in-sp">
                  This is the inevitable destiny of the Southern people, and this destiny Virginia holds in her hands. By uniting herself to her sisters of the South who are already in the field, she will make that a peaceful revolution which may otherwise be violent and bloody. At the sound of her trumpet in the ranks of the Southern States, "grim-visaged war will smooth his wrinkled front," peace
                  and prosperity will again smile upon the country, and we shall hear no more threats of coercion against sovereign States asserting their independence. The Southern people, under your lead, will again be united, and liberty, prosperity and power, in happy union, will take up their abode in the great Southern Republic, to which we may safely entrust our destinies. These are the noble
                  gifts which Virginia can again confer on the country, by prompt and decided action at the present.
                </p>
                <p className="p-in-sp">
                  In conclusion, gentlemen, let me renew to you the invitation of my State and people, to unite and co-operate with your Southern sisters who are already in the field, in defence of their rights. We invite you to come out from the house of your enemies, and take a proud position in that of your friends and kindred. Come and be received as an elder brother whose counsels will guide our
                  action and whose leadership <PageNumber num={86} /> we will willingly follow. Come and give us the aid of your advice in counsel and your arm in battle, and be assured that when you do come, as we know you will do at no distant day, the signal of your move will send a thrill of joy vibrating through every Southern heart, from the Rio Grande to the
                  Atlantic, and a shout of joyous congratulation will go up which will shake the continent from its centre to its circumference.
                </p>
                <div className="speaker" id="sp76"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">In further execution of the order of the day, I have the honor of introducing to you the Hon. HENRY L. BENNING, of Georgia.</p>
                <p><span className="persName">Mr. BENNING</span> came forward and said :</p>
                <div className="speaker" id="sp77">[<span className="persName">Mr. BENNING</span>:]</div>
                <p className="p-in-sp">Mr. President and Members of the Convention:</p>
                <p className="p-in-sp">
                  I have been appointed by the Convention of the State of Georgia, to present to this Convention, the ordinance of secession of Georgia, and further, to invite Virginia, through this Convention, to join Georgia and the other seceded States in the formation of a Southern Confederacy. This, sir, is the whole extent of my mission. I have no power to make promises, none to receive
                  promises; no power to bind at all in any respect. But still, sir, it has seemed to me that a proper respect for this Convention requires that I should with some fulness and particularity, exhibit before the Convention the reasons which have induced Georgia to take that important step of secession, and then to lay before the Convention some facts and considerations in favor of the
                  acceptance of the invitation by Virginia. With your permission then, sir, I will pursue this course.
                </p>
                <p className="p-in-sp">
                  What was the reason that induced Georgia to take the step of secession? This reason may be summed up in one single proposition. It was a conviction, a deep conviction on the part of Georgia, that a separation from the North- was the only thing that could prevent the abolition of her slavery. This conviction, sir, was the main cause. It is true, sir, that the effect of this conviction
                  was strengthened by a further conviction that such a separation would be the best remedy for the fugitive slave evil, and also the best, if not the only remedy, for the territorial evil. But, doubtless, if it had not been for the first conviction this step would never have been taken. It therefore becomes important to inquire whether this conviction was well founded.
                </p>
                <p className="p-in-sp">Is it true, then, that unless there had been a separation from the North, slavery would be abolished in Georgia? I address myself to the proofs of that case.</p>
                <p className="p-in-sp">
                  In the first place, I say that the North hates slavery, and, in using that expression I speak wittingly. In saying that the Black Republican <PageNumber num={87} /> party of the North hates slavery, I speak intentionally. If there is a doubt upon that question in the mind of any one who listens to me, a few of the multitude of proofs which could fill
                  this room, would, I think, be sufficient to satisfy him. I beg to refer to a few of the proofs that are so abundant; and the first that I shall adduce consists in two extracts from a speech of Lincoln's, made in October, 1858. They are as follows : "I have always hated slavery as much as any abolitionist; I have always been an old line Whig; I have always hated it and I always
                  believed it in the course of ultimate extinction, and if I were in Congress and a vote should come up on the question, whether slavery should be excluded from the territory, in spite of the Dred Scott decision, I would vote that it should."
                </p>
                <p className="p-in-sp">
                  These are pregnant statements; they avow a sentiment, a political principle of action, a sentiment of hatred to slavery as extreme as hatred can exist. The political principle here avowed is, that his action against slavery is not to be restrained by the Constitution of the United States, as interpreted by the Supreme Court of the United States. I say, if you can find any degree of
                  hatred greater than that, I should like to see it. This is the sentiment of the chosen leader of the Black Republican party ; and can you doubt that it is not entertained by every solitary member of that same party? You cannot, I think. He is a representative man; his sentiments are the sentiments of his party; his principles of political action are the principles of political action
                  of his party. I say, then; it is true, at least, that the Republican party of the North hates slavery.
                </p>
                <p className="p-in-sp">
                  My next proposition is, that the Republican party of the North is in a permanent majority. It is true that in a government organized like the government of the Northern States, and like our own government, a majority, where it is permanent, is equivalent to the whole. The minority is powerless if the majority be permanent. Now, is this majority of the Republican party permanent? I
                  say it is. That party is so deeply seated at the North that you cannot overthrow it. It has the press, it has the pulpit, it has the school-house, it has the organizations—the Governors, Legislatures, the judiciary, county officers, magistrates, constables, mayors, in fact all official life. Now, it has the General Government in addition. It has that inexhaustible reserve to fall
                  back upon and to recruit from, the universal feeling at the North that slavery is a moral, social and political evil. With this to fall back upon, recruiting is easy. This is not all. The Republican party is now in league with the tariff, in league with internal improvements, in league with three Pacific Railroads. Sir, you cannot overthrow such a
                  <PageNumber num={88} /> party as that. As well might you attempt to lift a mountain out of its bed and throw it into the sea.
                </p>
                <p className="p-in-sp">
                  But, suppose, sir, that by the aid of Providence and the intensest human exertion, you were enabled to overthrow it, how long would your victory last? But a very short time. The same ascendancy which that party has gained now, would be gained again before long. If it has come to this vast majority in the course of twenty-five years, from nothing, how long would it take the fragments
                  of that party to get again into a majority? Sir, in two or three Presidential elections your labor would be worse than the labor of Sisyphus, and every time you rolled the rock up the hill it would roll back again growing larger and larger each time until at last it would roll back like an avalanche crushing you beneath it.
                </p>
                <p className="p-in-sp">
                  The Republican party is the permanent, dominant party at the North, and it is vain to think that you can put it down. It is true that the Republican party hates slavery, and that it is to be the permanent, dominant party at the North ; and the majority being equivalent to the whole, as I have already stated, we cannot doubt the result. What is the feeling of the rest of the Northern
                  people upon this subject? Can you trust them? They all say that slavery is a moral, social and political evil. Then the result of that feeling must be hatred to the institution; and if that is not entertained, it must be the consequence of something artificial or temporary—some interest, some thirst for office, or some confidence in immediate advancement. And we know that these
                  considerations cannot be depended upon, and we may expect that, ultimately, the whole North will pass from this inactive state of hatred into the active state which animates the Black Republican party.
                </p>
                <p className="p-in-sp">
                  Is it true that the North hates slavery? My next proposition is that in the past the North has invariably exerted against slavery, all the power which it had at the time. The question merely was what was the amount of power it had to exert against it. They abolished slavery in that magnificent empire which you presented to the North ; they abolished slavery in every Northern State,
                  one after another ; they abolished slavery in all the territory above the line of 36 30, which comprised about one million square miles. They have endeavored to put the Wilmot Proviso upon all the other territories of the Union, and they succeeded in putting it upon the territories of Oregon and Washington. They have taken from slavery all the conquests of the Mexican war, and
                  appropriated it all to anti-slavery purposes ; and if one of our fugitives escapes into the territories, they do all they can to make a free man of him; they maltreat his pursuers, and sometimes <PageNumber num={89} /> murder them. They make raids into your territory with a view to raise insurrection, with a view to destroy and murder indiscriminately
                  all classes, ages and sexes, and when the base perpetrators are caught and brought to punishment, condign punishment, half the north go into mourning. If some of the perpetrators escape, they are shielded by the authorities of these Northern States—not by an irresponsible mob, but by the regularly organized authorities of the States.
                </p>
                <p className="p-in-sp">My next proposition is, that we have a right to argue from the past to the future and to say, that if in the past the North has done this, in the future, if it shall acquire the power to abolish slavery, it will do it.</p>
                <p className="p-in-sp">
                  My next proposition is that the North is in the course of acquiring this power to abolish slavery. Is that true? I say, gentlemen, the North is acquiring that power by two processes, one of which is operating with great rapidity—that is by the admission of new States. The public territory is capable of forming from twenty to thirty States of larger size than the average of the States
                  now in the Union. The public territory is peculiarly Northern territory, and every State that comes into the Union will be a free State. We may rest assured, sir, that that is a fixed fact. The events in Kansas should satisfy every one of the truth of that. If causes now in operation are allowed to continue, the admission of new States will go on until a sufficient number shall have
                  been secured to give the necessary preponderance to change the Constitution. There is a process going on by which some of our own slave States are becoming free States already. It is true, that in some of the slave States the slave population is actually on the decrease, and, I believe it is true of all of them that it is relatively to the white population on the decrease. The census
                  shows that slaves are decreasing in Delaware and Maryland ; and it shows that in the other States in the same parallel, the relative state of the decrease and increase is against the slave population. It is not wonderful that this should be so. The anti-slavery feeling has got to be so great at the North that the owners of slave property in these States have a presentiment that it is
                  a doomed institution, and the instincts of self-interest impels them to get rid of that property which is doomed. The consequence is, that it will go down lower and lower, until it all gets to the Cotton States—until it gets to the bottom. There is the weight of a continent upon it forcing it down. Now, I say, sir, that under this weight it is bound to go down unto the Cotton States,
                  one of which I have the honor to represent here. When that time comes, sir, the free States in consequence of the manifest decrease, will urge the process with additional vigor, and I fear that the day is not distant when the Cotton States, as they are called, <PageNumber num={90} /> will be the only slave States. When that time comes, the time will have
                  arrived when the North will have the power to amend the Constitution, and say that slavery shall be abolished, and if the master refuses to yield to this policy, he shall doubtless be hung for his disobedience.
                </p>
                <p className="p-in-sp">
                  My proposition, then, I insist, is true, that the North is acquiring this power. That being so, the only question is will she exercise it? Of course she will, for her whole course shows that she will. If things are allowed to go on as they are, it is certain that slavery is to be abolished except in Georgia and the other cotton States, and I doubt, ultimately in these States also. By
                  the time the North shall have attained the power, the black race will be in a large majority, and then we will have black governors, black legislatures, black juries, black everything.
                 [Laughter.] 
                  The majority according to the Northern idea, which will then be the all-pervading, all powerful one, have the right to control. It will be in keeping particularly with the principles of the abolitionists that the majority, no matter of what, shall rule. Is it to be supposed that the white race will stand that? It is not a supposable case. Although not half so numerous, we may readily
                  assume that war will break out everywhere like hidden fire from the earth, and it is probable that the white race, being superior in every respect, may push the other back. They will then call upon the authorities at Washington, to aid them in putting down servile insurrection, and they will send a standing army down upon us, and the volunteers and Wide-Awakes will come in thousands,
                  and we will be overpowered and our men will be compelled to wander like vagabonds all over the earth; and as for our women, the horrors of their state we cannot contemplate in imagination. That is the fate which Abolition will bring upon the white race.
                </p>
                <p className="p-in-sp">
                  But that is not all of the Abolition war. We will be completely exterminated, and the land will be left in the possession of the blacks, and then it will go back into a wilderness and become another Africa or St. Domingo. The North will then say that the Lord made this earth for his Saints and not for Heathens, and we are his Saints, and the Yankees will come down and drive out the
                  negro.
                </p>
                <p className="p-in-sp">
                  Sir, this is Abolition to the cotton States. Would you blame us if we sought a remedy to avert that condition of things? What must be the requisites of any remedy that can do it? It must be one which will have one of two qualities. It must be something that will change the unanimity of the North on the slavery question, or something that shall take from them the power over the
                  subject. Any thing that does not contain one of these two requisites is not a remedy for the case; it does not come to the root of the disease.
                </p>
                <PageNumber num={91} />
                <p className="p-in-sp">
                  What remedy is it that contains these requisites? Is there any in the Union that does? Let us take the strongest that we have heard suggested, which is an amendment of the Constitution guaranteeing the power of self-preservation, of dividing the public territory at the line of 36 deg. 30 min., giving the South all below that line. I know that remedy has not been thought of as in any
                  degree practicable. But, let us look at it. Suppose they grant us the power of self-preservation—suppose they give to each Senator and member the veto power over any bill relating to slavery. That is putting it strong enough. Would that be sufficient now, to make it protective? I say it would not, and for two reasons. The first is that the North regards every such stipulation as void
                  under the higher law. The North entertains the opinion that slavery is a sin and a crime. I mean, when I say the North, the Republican party, and that is the North ; and they say that any stipulation in the Constitution or laws in favor of slavery, is an agreement with death and a covenant with hell ; and that it is absolutely a religious merit to violate it. They think it as much a
                  merit to violate a provision of that sort, as a mere stipulation in favor of murder or treason.
                </p>
                <p className="p-in-sp">
                  Well, sir, a people entertaining this opinion of a covenant of that sort, is beyond the pale of contract-making. You cannot make a contract with a people of that kind, because it is a bond not as they regard it, binding upon them. That being so, how will it be any protection to us, that our senators and representatives shall have the power of saying this bill shall not pass. Suppose
                  the bill to pass giving protection to slavery, they would say hereafter, we proclaimed from the mountain tops, from the hustings, from the forum, and wherever our voice could be heard, that we did not regard stipulations in matters relating to slavery as binding upon us. We recognize a higher law, and will not obey these stipulations—you might have so expected from our proclaimed
                  opinions beforehand.
                </p>
                <p className="p-in-sp">
                  The next reason is this, the North entertains upon the subject of the Constitution the idea that this a consolidated Government, that the people are one nation, not a Confederation of States, and that being a consolidated Government, the numerical majority is sovereign. The necessary result of that doctrine when pushed to its natural result is, that the Constitution of the United
                  States is, at any time, subject to amendment by a bare majority of the whole people; and that being so, it becomes no matter what protection the Constitution may contain, it would be changed by a majority of the people, because a stipulation in the Constitution can no more be binding upon those who may choose subsequently to alter it, than the act of a legislature upon a subsequent
                  <PageNumber num={92} /> legislature. Thus it is they will have the power to change the Constitution, alter it as you will. The President elect has proclaimed from the house tops in Indiana that a State is no more than a county. This is an abandonment in the concrete of the whole doctrine. How, then, can we accept any stipulation from a people holding the
                  opinions that they do upon the question of slavery, and the obligations of government. The proposition which I have already adduced for argument sake, is infinitely beyond anything that we have a hope of obtaining. Then I assume that if this be true, it must be true that you can get no remedy for this disease in the Cotton States of the Union.
                </p>
                <p className="p-in-sp">
                  The question then is, would a separation from the North be a remedy? I say it would be a complete remedy; a remedy that would reach the disease in all its parts. If we were separated from the North, the will of the North on the subject of slavery would be changed. Why is it now that the North hates slavery? For the reason that they are, to some extent, responsible for the institution
                  because of the Union, and for the reason that by hating slavery they get office. Let there be a separation, and this feeling will no longer exist, because slavery will no longer enter into the politics of the North. Does slavery in the South enter into the politics of England or France? Does slavery in Brazil or Cuba enter into the politics of the North? Not at all ; and if we were
                  separated, the subject of slavery would not enter into the politics of the North. I say, therefore, that this remedy would be sufficient for this disease in the worst aspect of it. Once out of the Union, we would be beyond the influence of the yeas and nays of the North. Get us out, and we are safe.
                </p>
                <p className="p-in-sp">
                  I think, then, that this conviction in the mind of Georgia—namely, that the only remedy for this evil is separation was well-founded. She also was convinced that separation would be the best, if not the only remedy for the fugitive slave evil and for the territorial evil. It may be asked, sir, if the personal liberty bills, if the election of Lincoln by a sectional majority, had
                  nothing to do with the action of Georgia? Sir, they had much to do with it. These were most important facts. They indicated a deliberate purpose on the part of the North, in every case in which there was a stipulation in favor of slavery, to obliterate it if it had the power to do so. They are valuable in another respect. These personal liberty bills were unconstitutional; they were
                  deliberate infractions of the Constitution of the United States; and being so, they give to us a right to say that we would no longer be bound by the Constitution of the United States, if we choose. The language of Webster, in his speech at Capon Springs, in your own State, was, that a <PageNumber num={93} /> bargain broken on one side, is broken on all
                  sides. And in this opinion many others have coincided. And these Northern States having broken the Constitutional compact gives us cause to violate it also if we choose to do it. The election of Lincoln in itself is not a violation of the letter of the Constitution, though it violates it in spirit. The Constitution was formed with a view to ensure domestic peace and to establish
                  justice among all, and this act of Lincoln's election by a sectional majority, was calculated to disregard all these obligations, and inasmuch as the act utterly ignores our rights in the government, and in fact disfranchises us, we had a full right to take the steps that we have taken.
                </p>
                <p className="p-in-sp">Now, I ask the question, Georgia feeling this conviction, what could she have done but to separate from this Union? Was she to stay and wait for Abolition? Sir, that was not to be expected of her? She did the only thing that could have been done to ensure her rights.</p>
                <p className="p-in-sp">The second branch of my case is to lay before the Convention some facts to influence them, if possible, to accept the invitation of Georgia to join her in the formation of a Southern Confederacy.</p>
                <p className="p-in-sp">
                  What ought to influence a nation to enter into a treaty with another nation? It ought not be, I am free to say, any higher consideration than interest—material, social, political, religious interests. I am free to say that unless it could be made to appear that it was to her interest, she ought not to enter into it. And it shall be my endeavor now to show that it will be to the
                  interest of Virginia materially, socially, politically and religiously, to accept the invitation of Georgia to join the Southern Confederacy—and, first, will it be to her material interests?
                </p>
                <p className="p-in-sp">
                  Georgia and the other cotton States produce four millions of bales of cotton, annually. Every one of these bales is worth $50. The whole crop, therefore, is worth $200,000,000. This crop goes on growing rapidly from year to year. The increase in the last decade was nearly 50 per cent. If the same increase should continue for the next decade we should have, in 1870, six million bales
                  ; in 1890,<span className="note" id="Note20"><span className="noteLabel">1</span>This is the year cited in the Enquirer, although the speaker must have meant to say 1880.</span> nine million of bales, and so on. And, supposing that this rate will not continue, yet we have a right to assume that the increase, in after years, will be very great, because consumption outruns production, and so
                  long as that is the case, production will try to overtake it.
                </p>
                <p className="p-in-sp">
                  You perceive, then, that out of one article we have two hundred millions of dollars. This is surplus, and a prospect of an indefinite increase in the future. Then, we have sugar worth from fifteen to twenty millions of dollars, increasing every year at a pretty rapid rate. Then, we have rice, and naval stores, and plank, and live oak and various
                  <PageNumber num={94} /> other articles which make a few more millions. You may set down that these States yield a surplus of $270,000,000 with a prospect of increase. These we turn into money and with that we buy manufactured goods. iron, cotton and woolen manufactures ready made and many other descriptions of goods necessary for consumption. Then we buy
                  flour, and wheat, and bacon, and pork, and we buy mules and negroes ; very little of this money is consumed at home ; we lay it out this way.
                </p>
                <p className="p-in-sp">
                  Now I say, why will not Virginia furnish us these goods? Why will not she take the place now held by New England and New York, and furnish to the South these goods? Bear in mind that the manufactures consumed by the South are manufactures of the United States. They have now got the whole market by virtue of the tariff which we have laid on foreign importation. Will not Virginia take
                  this place? I ask, is it not to the interest of Virginia and the border States to take this place? Most assuredly it is. Now I say it is at her own option whether she will take it or not. I dare say she can have the same sort of protection against the north that she has against Europe. That being so, and inasmuch as the same cause must produce the same effect, the same cause that
                  built up manufactures at the north, will operate similarly in Virginia.
                </p>
                <p className="p-in-sp">
                  Then the question is, will you have protection necessary to accomplish this result? I say I think you will. I do not come here, as I said at the outset, to make promises; but I will give my opinion, and that is that the South will support itself by duties on imports. It has certainly begun to do that. We have merely adopted the revenue system of the United States so far, and are now
                  collecting the revenue under an old law. Our Constitution has said that Congress should have the power to lay duties for revenue, to pay debts and to carry on the government, and therefore there is a limit to the extent that this protection can go, and within that the South can give protection that will be sufficient to enable you to compete with the North. We have got to have a
                  navy, and an army, and we have got to make up that army speedily. It must be a much larger army than we have been accustomed to have in the late Union—it must be large in proportion to the army that it will have to meet. These things will require a revenue of about 10 per cent, which will yield an aggregate of about $20,000,000, and with this per cent, it would be in the power of
                  Virginia to compete, in a short time, with all the nations of the earth in all the important branches of manufacture. Why? Because manufacturing has now been brought to such perfection by the invention of new machinery. The result will be the immigration of the best men of the <PageNumber num={95} /> North; skilled artizans and men of capital will come
                  here and establish works among you. You have the advantage of longer days and shorter winters, and of being nearer to the raw material of a very important article of manufacture. I have no idea that the duties will be as low as 10 per cent. My own opinion is that we shall have as high duty as is now charged by the General Government at Washington. If that matter is regarded as
                  important by this Convention, why the door is open for negotiation with us. We have but a provisional and temporary government so far. If it be found that Virginia requires more protection than this upon any particular article of manufacture let her come in the spirit of a sister, to our Congress and say, we want more protection upon this or that article, and she will, I have no
                  doubt, receive it. She will be met in the most fraternal and complying spirit.
                </p>
                <p className="p-in-sp">
                  What is the state of the cotton trade? The North by virtue of their manufactures buy our cotton. They then take our cotton to Europe; they buy for it European manufactures; they take these manufactures and carry them to Boston, New York or Philadelphia, whence they distribute to us and all over the continent. But this all depends on the fact that they have manufactures to buy the
                  cotton with. New York, Boston and Philadelphia, in fact, fatten upon the handling of cotton, and I ask why it is you do not avail yourselves of the advantages which these possess; why do you not take the place of New York, engage in manufactures, sell us your goods, take our cotton and send it to Europe for goods, and thus make this city the centre of the earth? I know that in the
                  outset foreign imports would come direct to our ports, because you have not the manufactured goods to buy the cotton with, and we would have to send the cotton direct to Europe. But after a while you would have a monopoly of our trade having all the facilities to build up a manufacturing business extensive enough for the requirements of the whole country.
                </p>
                <p className="p-in-sp">What would be the effect of this? Your villages would grow into towns, and your towns would grow into cities. Your mines would begin to be developed, and would throw their riches over the whole land ; and you would see those lands enhanced, which you have now to give away, almost, for nothing.</p>
                <p className="p-in-sp">
                  I say, then, it is in your power, by joining our Southern Confederacy, to become a great manufacturing empire. If you do not consider our organization as it is now made good enough, go down to Montgomery, and say, change this in such and such a form, and I venture to assert that they will meet you in the spirit in which you go. As things now stand, there is a great drain of wealth
                  from the South to <PageNumber num={96} /> the North. The operation of the tariff, which at present averages about 20 per cent, is to enhance the prices of foreign goods upon us to that extent; and not only foreign goods, but domestic goods, as they will always preserve a strict ratio with the price charged for foreign imports. The South is thus heavily
                  taxed. What the amount of tribute is which she pays to the North in this form, I have not accurately ascertained. It is difficult to find out how much tribute she pays in this form, but, from a rough estimate which I have made out myself, putting the amount of goods consumed by the South at $250,000,000 annually, though a Northern gentleman puts it at $300,000,000; but putting it at
                  $250,000,000, the tribute which the South pays to the North annually, according to the present tariff
                 [20 per cent] 
                  amounts to $50,000,000. Then there are the navigation laws which give the North a monopoly of the coasting trade. The consequence of this monopoly is that it raises freights, and to that extent enhances the price of goods upon us. There is the indirect carrying trade, in which they also have a monopoly. Instead of our goods coming to us direct, they now come by New York, Philadelphia
                  or Boston. Last year the amount of goods that came to the South by this indirect route was about $72,000,000 which were not carried at a less cost than $5,000,000, which, of course, had to be paid by us. In the matter of expenditures we have not more than one fifth allotted to us, whereas we ought to have one-third. In 1860 the expenditures were $80,000,000, and the proportion of
                  this which is lost to us by an unjust system of discrimination amounts to nearly $20,000,000. This is a perpetual drain upon us.
                </p>
                <p className="p-in-sp">
                  <span className="persName">Mr. BENNING</span> then referred to the drain in the matter of fugitive slaves, and proceeded to ask what would Virginia gain by joining the Southern Confederacy? What, said he, is the state of things now on the border? Is it such as to prevent the escape of slaves? It is not. There is a remedy for this. The state of things on the other side of the line should
                  be such that slaves would not be induced voluntarily to run off, and if they did, that they would again soon gladly return. If you were with us, it would become necessary, in order to collect our revenue, to station police officers all along the border, and have there bodies of troops. It could be easily made part of the duty of these officers to keep strict watch along there and
                  intercept every slave, and keep proper surveillance on all who may come within the line of particular localities. Is not that arrangement better than any fugitive slave law that you could get? Most assuredly it is. If we were separated from the North, the escape of a fugitive slave into their territory would be but the addition of one savage to the number they have already.
                </p>
                <div className="stage it">[Laughter.]</div>
                <PageNumber num={97} />
                <p className="p-in-sp">Separate us from the North, and the North will be no attraction to the black man—no attraction to the slaves. It is not from a love for the black man that they receive him now; but it is from a hatred to slavery, and from a hatred to the owners of slaves.</p>
                <p className="p-in-sp">Is not this a better remedy than anything that you can get out of Congress or in any form of legislation?</p>
                <p className="p-in-sp">
                  As regards the Territorial evil, I will show that the remedy for that too, is in separation. We want land, and have a right to it. How are we to get our share of it? Can we get it in the Union? Never. Put what you please in the Constitution, you never can get one foot of that land to which you have so just a claim. Why? Kansas tells the reason. The policy of the Black Republican
                  party is to have this land settled up by those who do not own slaves. Their policy is the Homestead bill. You can enjoy all these things if you join us; and not only that, but you can enjoy them in peace. Cotton is peace. It is an article of indispensable necessity to the nations of the world, and they cannot obtain it without peace. Whenever there is war they cannot have it, and
                  will therefore have peace. Join us, therefore, and you will have the advantage of enjoying all those,benefits in peace.
                </p>
                <p className="p-in-sp">
                  Suppose you join the North, what can they give you? Nothing. They will maintain, in the matter of manufactures, a competition that will destroy you. You cannot go into any market in the world and compete with them. They have the start of you, and you cannot catch up. How will it be with agricultural enterprise? Manufactures give the most active stimulant to agriculture, and when you
                  cannot build up manufactures, you must suffer in your agricultural pursuits. Then there is the social and religious aspect of the question. Go with us, and the irrepressible conflict is at an end. We are the same in our social and religious attributes. We have a common Bible; we kneel at the same altar, break bread together, and there can be no difficulty between us on this score.
                </p>
                <p className="p-in-sp">
                  Then there is the political question. Suppose you join us, and also the other border States, which they will, if you come in. We shall have a territory possessing an area of 850 or 900,000 square miles, with more advantages than any similar extent of territory on the face of the earth, lying as it is between the right parallels of latitude and longitude, having the right sort of
                  coast facilities, and abounding in every production that can form the basis of prosperity and power.
                </p>
                <p className="p-in-sp">
                  <span className="persName">Mr. BENNING</span> referred to the probability of the Pacific States forming a distinct Confederacy after a separation shall once occur, and then discanted briefly on the general corruption which seems to exist at the <PageNumber num={98} /> North, where men make politics a profession, requiring property to be taxed for their
                  support. He instanced the enormous burdens amounting to nearly $2,000,000 a year, to which the city of New York is subjected through the corrupting influences of politicians, and deduced from this state of things the decay and ultimate disintegration of the North after she shall have been cut off from the rest of the Union, and circumscribed with the narrow limits of her own
                  unproductive inhospitable area.
                </p>
                <p className="p-in-sp">If, said he, you join in the Southern Confederacy, you will become the leader of it as you are now. You will have the Presidency and Vice-Presidency and other advantages which it is unnecessary here to mention.</p>
                <p className="p-in-sp">
                  Join the North, and what will become of you? In that, I say, you will find yourself much lower than you stand now. No doubt the North will now make fine promises, but when you are once in, they will give you but little quarter. They will hate you and your institutions as much as they do now, and treat you accordingly. Suppose they elevated Sumner to the Presidency? Suppose they
                  elevated Fred. Douglas, your escaped slave, to the Presidency? And there are hundreds of thousands at the North who would do this for the purpose of humiliating and insulting the South. What would be your position in such an event? I say give me pestilence and famine sooner that that.
                </p>
                <p className="p-in-sp">
                  As regards the African slave trade, we have done what we could to expel the illusion which is said to deter some timid persons from uniting with us. Our State has given her voice against it, and so has Alabama, and finally the Convention at Montgomery has placed the ban upon it by a Constitutional provision. Suppose we re-open the African slave trade, what would be the result? Why,
                  we would be soon drowned in a black pool; we would be literally overwhelmed with a black population. If you open it, where are you going to stop? There is no barrier to it but that of interest, and that will never be a barrier until there will be more slaves than we want. But go down to Montgomery and we will stipulate with you, and satisfy you, I have no doubt, upon that, as upon
                  all other questions. What danger is there in your going with this Confederacy? You will have, with the other border States, a population of eight millions, while we will have only five. What danger is there then with such a preponderance in your favor?
                </p>
                <p className="p-in-sp">
                  I heard another objection urged to your joining us, and that is, that we held out a threat in the way of a provision in our Constitution that Congress shall have power to stop the inter-State slave trade. I do not hesitate to say to you, that in my opinion, if you do not join us but join <PageNumber num={99} /> the North, that provision would be put in
                  force. I think that these States would do all in their power to keep the border States slave States. It would be a mere instinct of self-preservation to do that, and I think that it would be done. But is this to be regarded as a threat held out to deter you from joining the North? You might as well say that a provision in respect to a tax is a threat against you.
                </p>
                <p>After meeting the objection urged against the seceding States for seceding without consultation with the border States, with the argument of necessity, he closed with an expression of thanks to the Convention, and submitted the Ordinance of Secession passed by Georgia, which was read by the Clerk.</p>
                <p>On motion of <span className="persName">Mr. WM. B. PRESTON</span> the ordinance was laid on the table and ordered to be printed.</p>
                <p><span className="persName">Mr. PRESTON</span> then moved the further execution of the special order be postponed till tomorrow, Tuesday, at 12 o'clock. The motion was agreed to.</p>
                <p><span className="persName">Mr. EARLY</span> offered the following resolution, which was adopted : Resolved, That the resolution adopted on Saturday, in regard to tickets of admission for this day be renewed for tomorrow.</p>
                <p>On motion of <span className="persName">Mr. STAPLES</span>, the Convention then adjourned.</p>
              </div>
            </div>
            <div className="day" id="vsc1965.v1.2.6">
              <PageNumber num={100} />
              <h2><span className="headingNumber">1.6. </span><span className="head">SIXTH DAY</span></h2>
              <div className="dateline">Tuesday, <span className="date">February 19</span></div>
              <p>The Convention met today at 12 o'clock, M.</p>
              <p>Prayer by the Rev. Mr. Peterkin.</p>
              <p><span className="persName">Mr. WM. BALLARD PRESTON</span> moved that the execution of the unfinished order of the day be proceeded with; whereupon,</p>
              <div className="speaker" id="sp78">The President [<span className="persName">Mr. JANNEY</span>] said :</div>
              <p className="p-in-sp">I have the honor to introduce to you the Hon. JOHN S. PRESTON, the Commissioner from South Carolina.</p>
              <p>
                <span className="persName">Mr. PRESTON</span> then came forward and said :<span className="note" id="Note21"
                ><span className="noteLabel">1</span>Mr. Preston's speech was subsequently printed by W. M. Elliott, Printer to the Convention. A few small omissions and typographical errors in the Enquirer report of Mr. Preston's speech have been corrected by Elliott's text, when clarification seemed called for.</span
                >
              </p>
              <div className="speaker" id="sp79">[<span className="persName">Mr. PRESTON</span>:]</div>
              <p className="p-in-sp">Mr. President and Gentlemen of Virginia :</p>
              <p className="p-in-sp">
                I have had the honor to present you, sir, and this Convention my credentials, as Commissioner from the Government of South Carolina, and, upon your reception of these credentials, I am instructed by my Government to lay before you the causes which induced the State of South Carolina to withdraw from the Union, and the people of South Carolina to resume the powers which they had
                delegated to the Government of the United States of America.
              </p>
              <p className="p-in-sp">
                In performing this duty, gentlemen, I am not instructed by my Government, nor is it my intention to endeavor to make before you an argument in proof of the right of secession. My State, in its sovereign capacity, assumed that right, and my ministry here is only to lay before you those reasons which she deemed sufficient to enforce upon her the necessity of exercising that right. The
                very learned and comprehensive argument of my countrymen from Georgia and Mississippi, confine me into a very narrow scope of statement. In laying these causes before you, it will be quite sufficient, as preliminary to this statement, that I should submit for your consideration a few historical facts bearing upon the relations of these States to each other, and to the late Federal
                Government of the United States.
              </p>
              <p className="p-in-sp">
                It will be recollected by all that the British Colonies of North America, save by contiguity of territory, held no nearer political Union with each other than they did with the Colonies under the same Government at remote parts of the empire. They had a common Union, and a common sovereignty in the Crown of Great Britain. But when that Union was dissolved, each colony was remitted to
                its own ministry as perfectly as if it was separated from the other by the oceans of the empire. But being of joint territory, and having common interests, and having identical grievances against the mother country, the people of <PageNumber num={101} /> these colonies met together, consulted with each other at various times for a long series of years, and
                in various forms, but, as you may remember, most generally, in the form of a Congress of independent powers. They began their conflict with the mother Government each for itself, and the battles of Lexington, of Bunker Hill, of Fort Moultrie in the colony of South Carolina, and the battle of the Great Bridge in the colony of Virginia—all occurred before their common Declaration of
                Independence on the 4th of July, '76. The Colonies, then in Congress, made a joint declaration of their freedom from Great Britain, of their independence and of their sovereignty.
              </p>
              <p className="p-in-sp">
                As you may remember, gentlemen—for I am now reciting what is present to your memory, with a view to bring it to your consideration, trusting as I may recite it you may discover, what has been certainly running through the minds of my people for years past—finding, that, individually, they could not carry on this contest for independence and sovereignty, they united in certain articles
                which are known as the Articles of Confederation. In these articles there is the reiteration of the original declaration of the sovereignty and independence of the parts of it. All rights, all powers, all jurisdiction therein delegated produce no limitation upon the ultimate and discretionary sovereignty of the parts of it.
              </p>
              <p className="p-in-sp">
                Under these considerations, and with these reservations, they prosecuted the war with the mother country successfully; and when that country recognized their success and their independence, she did so not by the positive recognition of the agency of this Confederation, but in absolute acknowledgment of the independence and the sovereignty of the parts of that agency.
              </p>
              <p className="p-in-sp">Four years later than this, it became apparent that their alliance was not sufficient for conducting the faculties of peace as it had been for conducting the faculties of war; and the remedies for this insufficiency resulted in that instrument known as the Constitution of the United States of America, and the amendments thereto proposed by the States individually.</p>
              <p className="p-in-sp">
                Now, gentlemen of Virginia, we all know that in this instrument there is no one clause, no one phrase, no one word which places the slightest limitation, or indicates the slightest transmission of the sovereignty of the parties to that compact. On the contrary, the whole spirit and genius of that instrument goes to recognize itself as a mere agency for the performance of delegated
                functions, and to recognize the parts of it as the original holders of the sovereignty. In proof of this, the States at various times, under various forms, with a treaty reservation, <PageNumber num={102} /> consented to this compact. Now since that period, of course there has been no legitimated change of the relations of the parts under this compact. On
                the contrary, all the contemporaneous and juxta-contemporaneous constructions of the instrument, especially that made by the voice of Virginia through Mr. Madison, whom your distinguished President,
               [<span className="persName">Mr. JANNEY</span>] , has characterized as the chief architect of the Constitution, declare the relations of the parts in these words :</p>
              <p className="p-in-sp">"The Constitution of the United States was formed by the sanctions of the States, given by each in its sovereign capacity."</p>
              <p className="p-in-sp">
                And again, "the States then being the parties to the constitutional compact, and in their sovereign capacity, it follows of necessity that there can be no tribunal above this with authority to decide in the last resort whether the compact made by them be violated, and consequently as parties to it, they must decide in the last resort, such questions as may be of sufficient magnitude to
                require their interposition."
              </p>
              <p className="p-in-sp">
                Then, are these questions of sufficient magnitude to require the interposition of sovereignty? Unquestionably, the interpreter means those questions which involve the prerogative of that sovereignty itself, and those were of sufficient magnitude to require its interposition, and such as are of themselves dangerous to the great purposes for which the Constitution was established ; and
                among these great purposes we know there are expressed those of justice, right, equality, general welfare and the blessings of liberty to us and our posterity.
              </p>
              <p className="p-in-sp">
                Gentlemen, in these relations of the States to each other and to the Federal Government, the people of South Carolina have assumed that their sovereignty has never been divided; that it has never been alienated ; that it is imprescriptibly in its entire ; that it has not been impaired by their voluntarily refraining from the exercise of certain functions of sovereignty which they had
                delegated to another power, and upon this assumption, they contend that in the exercise of this unrestricted sovereignty, and upon the great principle of the right of a sovereign people to govern themselves, even if it involves the destruction of the compact which by its vitiation has become in imminent peril, that they have a right to abrogate their consent to that compact.
              </p>
              <p className="p-in-sp">
                I have thus, gentlemen, as rapidly as possible, grouped before you a few facts and principles involved in the action of the State which I represent, and I shall now proceed, with as much rapidity as possible, to exhibit to you the reasons why, upon these facts and these principles, the people of South Carolina have deemed proper to abrogate their consent to the compact of the
                Constitution of the United States.
              </p>
              <p className="p-in-sp">
                As preliminary to this statement, I would say, that as early as the <PageNumber num={103} /> year 1820, the manifest tendency of the legislation of the general government was to restrict the territorial expansion of the slaveholding States. That is very evident in all the contests of that period ; and had they been successful to the extent that some hoped,
                even then, the line that cut off the purchase from France might have been projected eastward to the bottom of the Chesapeake and sent Virginia and half of Tennessee and all of Kentucky, Virginia proper, after she had given to non-slavery her northwestern empire, to the non-slavery section. That might be the line. The policy, however, has been pushed so far as to deprive this Southern
                section of that line of at least seven-tenths of the valuable acquisitions of the government. Besides this, I would state, as preliminary, that a large portion of the revenue of the government of the United States has always been drawn from duties on imports. Now, the products that have been necessary to purchase these imports, were at one time almost exclusively, and have always
                mainly been the result of slave labor, and therefore the burden of the revenue duties upon imports purchased by these exports must fall upon the producer who happens also to be the consumer of the imports.
              </p>
              <p className="p-in-sp">
                In addition to this, it may be stated, that at a very early period of the existence of this Government, the Northern people, from a variety of causes, entered upon the industries of manufacture and of commerce, but of agriculture scarcely to the extent of self support. This may have arisen from a variety of causes; among them, perhaps, an uncongenial climate, a barren soil, but an
                alluring sea coast adapted to commerce, besides an inherent tendency upon the part of the people of these latitudes to the arts of manucraft and traffic; and while, therefore, it was important that all the sources of the revenue should be kept up to meet the increasing expenses of the Government, it also manifestly became of great importance that these articles of manufacture in which
                they have been engaged should be subject to the purchase of their confederates. They, therefore, invented a system of duties partial and discriminating, by which the whole burden of the revenue from this extraordinary system fell upon those who produced the articles of exports which purchased the articles of imports, and which articles of import were consumed mainly, or to a great
                extent, by those who produced the exports.
              </p>
              <p className="p-in-sp">
                Now, the State of South Carolina being at the time one of the largest exporters and consumers of imports, was so oppressed by the operations of this system upon her, that she was driven to the necessity of interposing her sovereign reservation to arrest it, so far as she was concerned. This interposition, together with the rapid spread of the principle
                <PageNumber num={104} /> of free trade all over the world, did arrest the iniquity in the shape in which it was then presented. It could no longer be the avowed policy of the Government to tax one section for the purpose of building up another. But so successful had been the system; to such an extent had it already, in a few years, been pushed; so vast had
                been its accumulations of capital; so vastly had it been diffused throughout its ramifications as seemingly to inter-weave the very life of industry itself, in the two sections into each other in the form of mechanics, of manufactures, ships, merchants, and bankers. The people of the Northern States have so crawled and crept into every crevice of our industry which they could approach,
                and they have themselves so conformed to it, that we ourselves began to believe that they were absolutely necessary to its vitality; and they have so fed and fattened, and grown so great and large as they feed and fatten upon this sweating giant of the South, that with the insolence natural to sudden and bloated wealth and power, they begin to believe that the giant was created only as
                their tributary.
              </p>
              <p className="p-in-sp">Now, strange as it may seem, it is nevertheless true, that while they were thus building up their wealth and their power from these sources, step by step, we will see latterly that, with this aggregation of wealth was growing up a determined purpose to destroy the very sources from which it was drawn. I pretend not to explain this; I refer to it merely as history.</p>
              <p className="p-in-sp">
                This, gentlemen, brings me directly to the causes which I desire to lay before you. For fully thirty years or more, the people of the Northern States have assailed the institution of African slavery. They have assailed African slavery in every form in which, by our contiguity of territory and our political alliance with them, they have been permitted to approach it.
              </p>
              <p className="p-in-sp">
                During that period of thirty years, large masses of their people have associated themselves together for the purpose of abolishing the institution of African slavery, and means, the most fearful were suggested to the subject race—rising and murdering their masters being the charities of those means. In pursuance of this idea, their representatives in the federal government have
                endeavored by all the means that they could bring to bear, so to shape the legislation as almost to limit, to restrict, to restrain the slaveholding States from any political interest in the accretion of the government. So that as my distinguished colleague
               [Judge Benning] , stated to you on yesterday, the decree goes forth that there are to be no more slave States admitted into the Union.</p>
              <PageNumber num={105} />
              <p className="p-in-sp">
                Secondly, then, in pursuance of the same purpose that I have indicated, a large majority of the States of the Confederation have refused to carry out those provisions of the Constitution which are absolutely necessary to the existence of the slave States, and many of them have stringent laws to prevent the execution of those provisions ; and eight of these States have made it criminal,
                even in their citizens to execute these provisions of the Constitution of the United States, which, by the progress of the government, have become now necessary to the protection of an industry which furnishes to the commerce of the Republic $250,000,000 per annum, and on which the very existence of twelve millions of people depends. In not one of these seventeen States can a citizen
                of one of the fifteen States claim his main property, and in many of them the persons of the citizens of these States have been violated, and in numerous cases the violence has resulted in murder.
              </p>
              <p className="p-in-sp">
                Third. The citizens of not less than five of our confederates of the North have invaded the territory of their confederates of the slave-holding States, and proclaimed the intention of abolishing slavery by the annihilation of the slaveholders; and two of these States have refused to surrender the convicted felons to the demand of the invaded States; and one of these—one of the most
                influential—one, perhaps, recognized as the representative of what is called American sentiment and civilization, has, in its highest solemn form, approved of that invasion ; and numbers of people, scattered throughout the whole extent of these seventeen States, have made votive offerings to the memory of the invaders.
              </p>
              <p className="p-in-sp">
                Fourth. The most populous, and by far, the most potent of our late confederates, has for years proclaimed, through the federal legislature and by her own sovereign act, that the conflict between slavery and non-slavery is a conflict for life and death. Now, there is the calm, oft-reiterated decree of a State containing three millions of people, conducting four-fifths of the commerce of
                the Republic, with additional millions diffused through the whole of these 17 States. And many of these States themselves have decreed that the institution of slavery is an offence to God, and, therefore, they are bound by the most sacred attributes which belong to human nature, to exterminate it. They have declared, in their most solemn form, that the institution of slavery, as it
                exists in the States of their political confederates, is an offence to their social institutions, and, therefore, that it should be exterminated. Finally, acting upon the impulse of their duties of self-protection and self-preservation, majorities, large majorities throughout the whole of these 17 States have placed the executive power of the Federal Government
                <PageNumber num={106} /> in the hands of those who are bound by the most sacred obligations, by their obligations to God, by their obligations to the social institutions of man, by their obligations of self-protection and self-preservation, to place the system of slavery as it exists in the Southern States upon a course of certain and final extinction.
                Twenty millions of people, having in their hands one of the strongest Governments on earth, and impelled by a perfect recognition of the most powerful obligations which fall upon man, have declared that the vital interests of eight millions of people shall be exterminated. In other words, the decree, the result of this cumulation which I have endeavored to show you, was inaugurated on
                the 6th of November last, so far as the institution of slavery is concerned, in the confederates of the Northern non-slaveholding States. That decree is annihilation, and you can make nothing shorter of it.
              </p>
              <p className="p-in-sp">Now, gentlemen, the people of South Carolina, being a portion of these eight millions of people, have only to ask themselves, is existence worth the struggle? Their answer to this question, I have submitted to you in the form of their Ordinance of Secession.</p>
              <p className="p-in-sp">
                Gentlemen, I see before me men who have observed all the records of human life, and many, perhaps, who have been chief actors in many of its gravest scenes, and I ask such men if in all their lore of human society they can offer an example like this? South Carolina has 300,000 whites, and 400,000 slaves. These 300,000 whites depend for their whole system of civilization on these
                400,000 slaves. Twenty millions of people, with one of the strongest Governments on the face of the earth, decree the extermination of these 400,000 slaves, and then ask, is honor, is interest, is liberty, is right, is justice, is life, worth the struggle?
              </p>
              <p className="p-in-sp">
                Gentlemen, I have thus very rapidly endeavored to group before you the causes which have produced the action of the people of South Carolina. Had I endeavored to go into detail of it, it would occupy half the history of, this Republic. For half the period of its existence, from the days of the younger Adams to this hour, the untiring and unflinching purpose of the Northern
                representation in our federal legislation, and of the people of the Northern States, has been first to restrict and restrain the people of the Southern States within the limitations which they have prescribed, then to subsidise, then to destroy. They were baffled, they were checked in one course of this. But with that untiring energy of nature which belongs to them, they soon turned to
                another. Defeated in this, by the lingering and sturdy fragments of a dying patriotism among themselves, or the pointed resistance of their victims—they have still held on with the fierce grip of avarice, with the <PageNumber num={107} /> mad rage of fanaticism, until it has pleased God to curse them with a triumph which may plunge this continent into
                civil war, and destroy, perhaps forever, the fairest forms of liberty that ever human philosophy grafted upon the institutions of men.
              </p>
              <p className="p-in-sp">
                Now, gentlemen, for one moment look at the other side of the picture. For thirty years, by labor, by protest, by prayer, by warning, by every attribute, by every energy which she could bring to bear, my State has endeavored to avert this catastrophe. For this long series of years in the federal legislature what has been her course? What has been the labor which she has performed? What
                has been the purpose which she has avowed? Has she not given to this all her intelligence, all her patriotism, all her virtue—and that she had intelligence ; that she had patriotism; that she had virtue is in proof, because that marble sits in the hall where the sovereignty of Virginia is consulting upon the rights and honor of Virginia.
               [Applause.] All this she did in the Federal Government. Failing in this more than a year ago, seeing the storm impending, seeing the waves rising, she sends to this great, this strong, this wise, this illustrious Republic of Virginia, a grave commission, the purport of which, with your permission, gentlemen, I will venture to relate.</p>
              <p className="p-in-sp">"Whereas the State of South Carolina, by her ordinance of A. D. 1852, affirmed her right to secede from the Confederacy whenever the occasion should arise, justifying her, in her own judgment, in taking that step ; and in the resolution adopted by her Convention, declared that she forbore the immediate exercise of that right, from considerations of expediency only:</p>
              <p className="p-in-sp">And, whereas, more than seven years have elapsed since that Convention adjourned, and in the intervening time, the assaults upon the institution of slavery, and upon the rights and equality of the Southern States, have unceasingly continued with increasing violence, and in new and more alarming forms; be it therefore,</p>
              <p className="p-in-sp">1. Resolved unanimously, That the State of South Carolina, still deferring to her Southern sisters, nevertheless respectfully announces to them, that it is the deliberate judgment of this General Assembly that the slaveholding States should immediately meet together to concert measures for united action.</p>
              <p className="p-in-sp">2. Resolved unanimously, That the foregoing preamble and resolution be communicated by the Governor to all the slaveholding States, with the earnest request of this State that they will appoint deputies, and adopt such measures as in their judgment will promote the said meeting.</p>
              <p className="p-in-sp">
                3. Resolved unanimously, That a special commissioner be appointed by his Excellency the Governor to communicate the foregoing preamble and resolutions to the State of Virginia, and to express to the authorities of that State the cordial sympathy of the people of South Carolina with the people of Virginia, and their earnest desire to unite with them in measures of common defence."
              </p>
              <PageNumber num={108} />
              <p className="p-in-sp">
                This, gentlemen, was one year ago and no more. Failing in that effort, the people of South Carolina, for the first time in over 20 years, joined with the political organizations of the day, in the hopes-of deferring the catastrophe. Failing in effecting that through them, she then calmly, as the last hope, for she earnestly, honestly, and with the most anxious solicitude, desired the
                success of that party which she supposed would defer this catastrophe—cast her vote with that party in the Presidential contest; but failing in this, from the causes I have enumerated, and from the parties I have enumerated as arrayed against her—she then quietly and unostentatiously, without clamor, having asked her sister States, especially having asked Virginia, to unite with her in
                consultation, passes this act in these few simple words which I shall take the liberty of reading to the Convention :
              </p>
              <p className="p-in-sp">We, the People of the State of South Carolina, in Convention assembled, do declare and ordain, and it is hereby declared and ordained,</p>
              <p className="p-in-sp">
                That the Ordinance adopted by us in Convention, on the twenty-third day of May, in the year of our Lord one thousand seven hundred and eighty-eight, whereby the Constitution of the United States of America was ratified, and also, all Acts and parts of Acts of the General Assembly of this State, ratifying amendments of the said Constitution, are hereby repealed ; and that the union now
                subsisting between South Carolina and other States, under the name of "The United States of America," is hereby dissolved.
              </p>
              <p className="p-in-sp">
                After this simple act of excision still she has not been satisfied, and now still she is seeking aid, she is seeking counsel, she is seeking sympathy, and, therefore, gentlemen, I am before you here today. Now, gentlemen of Virginia, notwithstanding these facts which I have so feebly grouped before you, notwithstanding this patience which I have endeavored to show you she has
                practised, my State, throughout this whole land, throughout all Christendom, my State has been charged with rash precipitancy. Is it rash precipitancy to step out of the pathway when you hear the thunder crash of the falling clouds? Is it rash precipitancy to seek for shelter when you hear the gushing of the coming tempest, and see the storm cloud coming down upon you? Is it rash
                precipitancy to raise your hands to protect your head?
               [Loud applause.] 
                Gentlemen of Virginia, never since liberty came into the institutions of man have a people borne with more patience, or forborne with more fortitude than have the people of these Southern States in their relation with their confederates of the North. As long as it was a merely silly fanaticism or a prurient philanthropy that proposed our destruction, we scarcely complained. Even when a
                long series of unjust, partial and oppressive taxation was grinding us into the very dust of poverty, with one convulsive struggle we bore that happily. Even when a number <PageNumber num={109} /> of our confederate States refused to carry out the provisions of the Constitution, vital to our interests, by municipal and State regulations —even when hordes
                of their people, under the sanction of these regulations, robbed us of our property and murdered our citizens, and bands of wild fanatics, under the same sanction, invaded slave territory and proclaimed the destruction of slavery by the annihilation of the slaveholder—even when some of our confederates proclaimed in the federal legislature and by their sovereign decrees that we ought
                to be driven from the civilization in which we lived—even when one of our confederates sought to defame and vilify us before the civilized world, we bore all these accumulating calamities, and continued to give them our blood and our treasure to build up the grandeur and maintain the power of that Republic. And, in addition to this, when all that malignant fanaticism, that baffled
                avarice, that moral turpitude could invent to defame and vilify us, was proposed by the people and by the States of our confederates, still we gave them our blood and sweat and offered them our hands and called them brethren. I draw no fancy picture. I make no declamatory assertion here. There is not one man here who cannot cite twenty cases to fill every item of this category. But
                when at last this mad fanaticism, this eager haste for rapine, mingling their foul purposes, engendered this fell spirit which has seized the Constitution itself in its most sacred forms and distorted it into an instrument of our instant ruin; why, then, to hesitate one moment longer seems to us not only base cowardice, but absolute fatuity.
               [Applause.] 
                We felt, in the South, that if we submitted one hour to such a domination as that, we would have merited that destruction which we had earned by our folly and baseness. In South Carolina at least, we felt, if there was one son of a South Carolina sire who could give counsel to such submission as this, that there was not a hill side or a plain from Eutaw to the Cowpens, from which the
                spirit of his venerated sire would not have started forth to shame him from the land which discarded him.
               [Applause.] 
                I pray you, gentlemen of Virginia, to pardon me for referring with some particularity to the position of my State in connection with these matters, because she has been much spoken of and not much praised. I am here, gentlemen, as the Commissioner of these people, certainly not their eulogist. I am sent here as I thought mainly because among them I have always, with some pride,
                proclaimed that I sprang from this soil, and because they believe that I would tell an honest, earnest story of their wrongs and their trials; and if you will permit me, I will still farther allude to it. Never, gentlemen of Virginia, since liberty begun <PageNumber num={110} /> her struggles in the world, has a mightier drama been enacted on the trembling
                stage of man's affairs or been opened with a spectacle of purer moral sublimity, than that which has been manifested in this revolution in which we are now engaged. Scarcely had this decree of our subjection, to which I call your attention, been borne to our ears on the Northern breeze, than, as if from the very caverns of the earth, there rose up one voice, one voice only, from the
                people of South Carolina, who shouted back resistance to the death. Their Legislature then in session, caught that spirit, and with one voice, and one voice only, proclaimed resistance to the death. The people of the State again in their sovereign capacity, as you are, with one voice, and one voice only, ordained resistance to the death. And now, gentlemen, there is not, in the borders
                of that little State, one man from sixteen to sixty who can walk or stand, who is not armed, standing ready to resist to the death.
               [Applause.] We are very small—very weak—but if war, that fire-storm with which we are threatened, should fall upon us and consume us, hereafter the pilgrim of liberty, perhaps from this State, who may be searching beneath the ruins of Charleston, will find the skeleton of our sentinel standing at every sea gate. [Applause.] 
                Gentlemen of Virginia, do not believe—do not be persuaded that in taking the steps which we have, we have been forgetful of those things which are cherished among you. We have not been forgetful of the great and hallowed past, nor have we been reckless of our duties to our children. It is our memory of the great and hallowed past, of our sons, of our sacred obligations to our children
                which has nerved our arms to this act, and no sense of ourselves. For years and years, gentlemen, we have paused, we have hesitated, as I have shown you. We paused, and paused, as we raised the curtain with our own hands, and gazed back at that great and hallowed past, that fairest temple, as we all thought, in which liberty had ever found a shrine that which Washington, Jefferson,
                Adams, Franklin, Henry, Madison, the Lees, Masons, the Rutledges and the Pinckneys—that conclave of demi-gods
              </p>
              <p className="p-in-sp">
                had builded up as a tabernacle for us and our children to dwell in forever, and which they had consecrated by the blood of our own fathers—that which the Father of his Country deemed the palladium of human right—that precious muniment and refuge of human hope all over the world. We paused with reverence and pity as we gazed back on all these scenes; and yet, gentlemen, with these hands
                we have dragged them down to the earth—with these feet we have trampled them out of life—with this breath we have scattered them on the winds of Heaven : and yet we do not tremble. We are not appalled as we <PageNumber num={111} /> gaze on our hands, because those hands are unstained, pure, clear and unterrified as we raise them in confident appeal to the
                God of Justice and of Truth.
               [Applause.] Armed, then, in this panoply, with calm hearts, with unfaltering step we are ready to drop the curtain and move onward through the coming scenes of this solemn drama.</p>
              <p className="p-in-sp">
                Gentlemen of Virginia, I believe that these Southern States are no noisy faction clamoring for place and power. They are no hungry rabble ready to answer back in blood to every appeal to their brutal passions. We are no shouting mob ready to take for our government some glittering epigram or some fustian and infidel theory. We are no festering fanatics. With us liberty is not a painted
                strumpet, drazzling through the streets, nor does our truth need to build itself in pools of blood. We are a calm, grave, deliberate and religious people, the holders of the most majestic civilization and the inheritors, by right, of the fairest estate of liberty. Fighting for that liberty, fighting for our fathers' graves, standing athwart our hearth-stones and before our chamber
                doors, for days and weeks the people of our little State stood alone that little State around whose outermost border the guns fired at her capital might almost be heard; whose little scope of sky was so small that scarce one star had space to glitter in it. So small, so few, we begun this fight alone against millions; and had you piled millions upon millions, under God, in this fight
                we should have triumphed.
               [Applause.] 
                But that God, gentlemen, cares for his people cares for liberty, and right, and justice—and we are no longer alone. Very soon our own children from Florida and Alabama answer back to the maternal call, and our great sister, Georgia, marshaled forth her giant offspring; and from the grave of the gallant Quitman, on the banks of the Mississippi, there came forth his well-known clarion
                tones,
               [applause] ; and Louisiana proved her paternity in the appeal of liberty; and now young Texas has raised her giant form and marches to the right of this majestic column of confederated sovereignties. [Applause.] Ah, gentlemen of Virginia, wherever outside of the borders of Virginia, the voice of a son of Virginia has spoken in this fight, it too has been known, because he spoke in the ancient tongue of his mother. [Applause.] I, one of the humblest of her sons, told my countrymen, that before the spring grass grows long enough to weave one chaplet of victory, they will hear the sound as of the tramp of a mighty host of men, and they will see floating before that host the banner whose whole history is one blaze of glory, and not one blot of shame. [Applause.] Aye, they will hear coming up from that host one voice like their own, but it will be the resounding echo of that voice which has thundered <PageNumber num={112} /> into the hearts of your God-like sires—"give me liberty, or give me death!" [Applause.] And on that banner will be written the unsullied name of Virginia. [Applause.] Gentlemen of Virginia, have I promised too much for our mother? To suggest a doubt, would be more than blasphemy. I believe she will come. I believe she will take her place which she has held for one hundred years—the foremost of all the world in the ranks of liberty and of justice. [Applause.] The world knows her history, and knows no history above it in the niche of fame—and, knowing that history, none dare doubt where Virginia will be when her own offspring, and liberty and justice, call her to the fight. [Applause.] 
                Mr. President, I fear that I am deluded by the scene and place to go beyond my intention; but yet, with your permission, and that of the Convention, I -shall ask to steal a few more minutes of your time. I have endeavored thus far, although perhaps somewhat erratically, to confine myself specifically to the question in hand of my own mission. And in connection with that, gentlemen, if
                you will permit me, I will take a few moments of your time, and will assume your permission, while I endeavor to exhibit to the Convention some of the reasons which the people of my State deemed as fundamental causes why there can never again be a re-construction of the late Federal Union. The truth seems evident, gentlemen, to every mind which dares to speculate advisedly upon the
                principles of this revolution through which we are now passing, that these principles do involve irreconcilable differences between the systems on which slave and non-slaveholding communities may be calculated upon as enduring.
              </p>
              <p className="p-in-sp">
                Now, we believe that these diversities pertain to every attribute pervading the whole of these two systems, and we therefore believe that this revolution, with this separation, with this disintegration, is not a mere accident; that it is not the mere casual result of a temporary cause ; that it is not a mere evanescent bubble of popular error or irritation; that it is not a mere dream
                of philosophy, and that it is not the achievement of personal or individual ambition. We believe, gentlemen, that it has a far profounder cause than all this. We believe that it is not only a revolution of material necessity, but that it is a revolution resulting from the profoundest convictions, ideas, sentiments, and moral and intellectual necessities of earnest and intelligent men.
                We believe that it is not only the never-dying struggle between the freedom of labor and the despotism of power, but that it is that still sterner conflict which shivered Greece, and disintegrated the huge and solid armies of Rome; that conflict which gathers into its contending armies <PageNumber num={113} /> not only all the necessities, but all the
                customs, all the laws, all the intelligence, all the sentiments, all the passions, which constitute the civilization of man. We therefore believe, that although you may centralize a coercing power at the city of Washington, stronger than the Praetorian band when the eagles of Rome soared from Leuconia to the Caucasus, you can no more coalesce the people of Virginia and the people of
                Vermont, the people of the St. Lawrence and the people of the Gulf of Mexico, the people of the Rio Grande and the people of the Hudson, than could Rome make one coalition of the Gaul, the Briton and the Ionian. No community of laws, no community of language, of religion, can amalgamate, according to our faith, people whose severance is proclaimed by the most rigid requisitions of
                universal necessity. African slavery cannot exist at the North. The South cannot exist without African slavery.
               [Applause.] None but an equal race can labor at the North; none but a subject race will labor at the South.</p>
              <p className="p-in-sp">
                Now, for these reasons and for others, perhaps, we believe that the political socialisms of these two systems, of these two sections, have assumed shapes so diverse, that their continuity of action is an absolute impossibility. Only to cite one or two instances, gentlemen, for I am only making these suggestions for your consideration : At the North, in the free States, the pure, the
                simple, the isolated, the exclusive, the absolute principle upon which all political and social organism is constructed, is that of a pure Democracy, saving a scarcely reconcilable modification in the shape of a vague and indefinite system of representation. There can be no other principle introduced into the Northern socialisms, save this, in its utmost and extremest intensity. It is
                the vitalizing principle; it is the breath of life to the Northern institution. The almighty power of numbers, of simple physical numbers, from one to countless millions, is the sole basis of political and social agreement in the Northern States. Now, a fearful manifestation exists at this moment. Here is the Government of the United States, as we all know, established for the
                protection of all parties equally, and yet, by the accession of this number, that Government has limited a section of the parties territorially. That Government has unduly taxed a section of the parties. That Government has threatened with imminent peril, if not with instant destruction, parts and sections of the parties to it, and the people and the States North are at this moment
                consulting together whether, by the mere power of numbers, they shall not subjugate parties to this Government. Now, the modification of this principle at the South is so essential, that it cannot co-exist with the same principle in its unrestricted form as I have exhibited at the <PageNumber num={114} /> North. At the South it is modified, it is
                controlled, it is made absolutely subject. The recognition of a specific property is essential to the vitalization of the political organism. Now, this indicates that the instant you engraft one of these rules into the forms of the other section, that instant the section which you have invaded perishes. If you institute property as an element of Northern political or social organism,
                you destroy the whole system which exists at the North. If you exclude it from the South, you subvert the whole system on which the Southern civilization exists.
              </p>
              <p className="p-in-sp">
                Now, gentlemen, there is another which we regard as of more weight, more potential, by far, than either territorial or geographical segregation—that of political and social diversity; and that comes of the profound sentiment, the moral and religious sentiment, exhibiting itself in its religious form—that profound sentiment which pervades the people of both these sections, having
                arrayed itself on the sides of these sections. We know—we cannot conceal it from ourselves at this moment if we dare contemplate it that this cause of diversity is at this moment arraying itself in forms as bloody as it has ever exhibited since Christ came upon the earth, and its representative, the Church, has already, and is daily keeping her arm bare for this conflict. Already her
                drawn sword is flashing in the twilight of fanaticism, and the world knows that where that sword cuts asunder, no human surgery can re-unite the wound. But, gentlemen, we cannot conceal it from ourselves one moment longer—there is not a Christian man, a slaveholder, in this assembly who does not feel in his inmost heart as a Christian, that the point of this sword of the church is at
                this moment dripping with the last blood of sympathy-which bound him to his Christian brethren of the North. They set the lamb of God between our seed and their seed.
              </p>
              <p className="p-in-sp">
                Now, gentlemen, from this and other repellant diversities, we believe, and have so acted, that the political union is an unnatural and monstrous one, and, therefore, that its offspring must be abortive or fruitless, save of that brood of evils which always come from such unnatural unions. We believe, gentlemen, that it is a perfectly natural and reasonable deduction, that from these
                diversities there have arisen these differencies of construction of principles and parties of our political confederation; which has so early in the period of its existence, as we are in the habit of terming it, converted a government of popular consent into a government of force—which has driven from it seven sovereign States and five millions of people; which has enabled that
                government in the face of the people of Virginia to <PageNumber num={115} /> keep loaded bomb-shells for sixty days ready to be fired upon the women and children of Charleston, and which in less than sixty days will have the refuse of New York and Boston, re-enacting at Portsmouth and Hampton the scenes of 1813. No, gentlemen, where these conventional and
                natural diversities are, the conflict is for life, subjugation—and that conflict is upon you. Now, you are very strong, gentlemen of Virginia, and you own an empire. You are advanced in the arts of civilization; you are strong, powerful and skillful, and you may conjoin remote oceans. You may, by your force and art drag down your mountain tops, fill up your valleys; you may encircle
                the earth with your canals and your iron bands; you may pull down civil dynasties and religions, and re-build upon their ruins forms of liberty and faith; but I tell you, there is no human force, there is no essay of human art, there is no sanctity of human touch that can re-unite the people of the North and the people of the South.
               [Applause.] 
                No, gentlemen, never, never, until you unfix by your power, your art and your virtue the unchangeable economy of the Eternal God. When you have done that, you may make one people of the people of the North and the people of the South. A profound instinct of these diversities is at this moment enforcing upon the people of the Southern States the necessity of this recognition—and they
                are building up their institutions at this time. The same profound instinct is operating upon the people of the Northern States, and they are at this moment manifesting this by preparation with the aid of expiring, crumbling powers and the agents of anti-slavery, known as the army and navy of the United States. With these aids and weapons in their hands, they are recognizing the
                absolute existence of these diversities.
              </p>
              <p className="p-in-sp">
                I believe, gentlemen of Virginia, that the question which you now have to decide is whether you, the representatives of the sovereignty, the power, the glory, the hope of Virginia, will be content like a modern Egyptian to skulk for protection beneath the crumbling fragments of an ancient greatness, and under the scourge of a haughty but mean task-master, or whether you will step forth
                and hush this storm of war and keep the ancient glory of your name.
               [Applause.] 
                Gentlemen, the people and State of South Carolina, in the language of its various compacts with its confederates, have declared that they have never parted with their sovereignty or their independence ; that they had a right to exercise that sovereignty and according to their will; that they did, in connection with their confederates, establish an agency for the formation of a
                government; that that government has failed of its purpose, and, therefore, that they are justified in abolishing <PageNumber num={116} /> it, and establishing another. She has therefore, gentlemen, ordained, as I have read to you, and has maintained that ordinance for a reasonable time, by arms, that political connection with the Government of the United
                States is dissolved. The admitted rule on which she has made this declaration, I have copied, for the purpose of reading to you :
              </p>
              <p className="p-in-sp">"That a violation of a perfect right, either committed or committing, or with which a people is threatened in the future, justifies the undertaking of war—amicable means having been tried in vain. When it is evident that it would be useless to try such means, justice requires a resort to arms."</p>
              <p className="p-in-sp">
                Therefore, the people of South Carolina contend that justice has required of them a resort to arms, in the protection of a principle of right. She has maintained this position, as I have indicated to you, a reasonable time. She has maintained it against falsehood and prejudice; she has maintained it until six sovereign States have come to her aid, and have formed a Government which the
                six have announced to the world by the eminent and patriotic citizen who has been put in charge of the Executive department of that Government. She has maintained this position
               [the State of South Carolina] 
                , until this government has been formed, in which there can be no cause for doubt, that the courage and patriotism of the people of the confederate States will be found equal to any measures of defence which our honor and security may require. Farther obstacles may retard the progress of that government, but they cannot long prevent the progress of a movement sanctified by its justice
                and sustained by a virtuous people. Reverently let us invoke the God of our fathers to guide and protect us in our efforts to perpetuate the principles which, by his blessing, they were able to vindicate, establish and transmit to posterity; and with that continuance of his favor ever gratefully acknowledged, we will hopefully look forward to success, peace and prosperity.
              </p>
              <p className="p-in-sp">
                Now, gentlemen of Virginia, believing the interests involved and the rights violated to be identical with the interests and the rights of Virginia, and remembering their ancient motto and their common glory, the people of South Carolina have ordered me to ask and urge that the people of Virginia will unite with her and her confederates in the protection of those rights and those
                interests.
              </p>
              <p className="p-in-sp">
                I have now performed this, my mission, and have only, in the name of my government, to return to this Convention my earnest acknowledgement of the honorable courtesy with which it has accepted my <PageNumber num={117} /> mission. On my own behalf, gentlemen, accept my sincere and honest thanks, I should say my deprecating thanks, for the kindness, courtesy
                and patience with which you have listened to the delivery of this mission.
               [Long and continued applause.] The following motion was made by <span className="persName">Mr. GOODE</span>, and adopted by the Convention, at the close of the Hon. <span className="persName">Mr. PRESTON</span>'S speech :</p>
              <p>"Resolved, That the Hon. FULTON ANDERSON, of Mississippi, Hon. HENRY L. BENNING, of Georgia, and Hon. JOHN S. PRESTON, of South Carolina, be each respectfully requested to furnish a copy of their addresses to this Convention for publication."</p>
              <p><span className="persName">Mr. L. S. HALL</span>, of Wetzel county, offered the following resolutions, which were referred to the Committee on Federal Relations :</p>
              <ul>
                <li className="item">
                  1. Resolved, That in the opinion of this Convention, Virginia has a legal right at any time to resume to, herself the powers that she heretofore granted to the Federal Government, but she should never exercise that right where the interests of others are involved only for good cause, and that the mere election of Abraham Lincoln to the Presidency of the United States under the
                  provisions of the Constitution, affords no just grounds for Virginia to resume to herself the powers granted to the general government ; but in view of the fact that a portion of our sisters of the South composing this confederacy have seen proper to break up their connection with this government, and have formed a new confederacy, thereby leaving the State of Virginia, in connection
                  with the balance of the border slave States greatly in the minority in both houses of Congress; therefore, in the opinion of this Convention, Virginia should demand as a matter of self-protection, additional guarantees and proper amendments to the Federal Constitution, so as to preserve a just and proper equilibrium between the sections.
                </li>
                <li className="item">2. Resolved, That Virginia should unite in presenting an ultimatum to the Northern States, which ultimatum should be just and fair to all sections of our common country and give the States a fair opportunity to take action on the same.</li>
                <li className="item">
                  3. Resolved, That in the opinion of this Convention, Virginia should fix a time beyond which she will not wait, and if the Northern States fail to ratify the action of Virginia, or fail to meet her in a spirit of harmony and conciliation within the time that may be prescribed by this Convention, then Virginia should at once take such steps as will effectively secure her just rights
                  out of the Union with the Northern Confederacy.
                </li>
              </ul>
              <PageNumber num={118} />
              <p><span className="persName">Mr. R. Y. CONRAD</span> stated that he had been directed by the Committee on Federal Relations to request members of the Convention to offer, as soon as convenient, any resolutions touching the subject of inquiry before that Committee.</p>
              <p>Then, on motion of <span className="persName">Mr. CLEMENS</span>, the Convention adjourned.</p>
            </div>
            <div className="day" id="vsc1965.v1.2.7">
              <PageNumber num={119} />
              <h2><span className="headingNumber">1.7. </span><span className="head">SEVENTH DAY</span></h2>
              <div className="dateline">Wednesday, <span className="date">February 20</span></div>
              <p>The Convention met at 12 o'clock.</p>
              <p>Prayer by the Rev. Mr. Peterkin, of the Protestant Episcopal Church.</p>
              <div className="section" id="vsc1965.v1.2.7.1">
                <h3><span className="headingNumber">1.7.1. </span><span className="head">PUBLICATION OF THE DEBATES</span></h3>
                <div className="speaker" id="sp80"><span className="persName">Mr. CLEMENS</span> of Ohio—</div>
                <p className="p-in-sp">I rise to a privileged question.</p>
                <p className="p-in-sp">
                  Mr. President : I claim my privilege here as a gentleman, as a member of Congress from this State, as a member of this Convention to put the stamp of reprobation upon a most atrocious calumny which is finding its flagrant course through the papers of this State. And before doing so, I desire to propose a resolution for the adoption of this Convention as a test whether the discussions
                  of this body the phraseology adopted by the agents of the people assembled here in Mass Convention—shall be kept under the control of our employees, or whether it shall be diluted through the channels of private enterprise and private capital.
                </p>
                <p>The resolution was then read by the Clerk, as follows :</p>
                <p>"Resolved, That the President of this Convention have authority to employ, at a fixed rate of compensation, a sufficient number of Reporters to take down as they occur the Debates of this body with a view to the publication of the same hereafter in a portable form, if it shall be deemed by this body expedient or necessary."</p>
                <div className="speaker" id="sp81"><span className="persName">Mr. CLEMENS</span></div>
                <div className="stage it">[Resuming]</div>
                <p className="p-in-sp">Mr. President : I desire to say but a single word in the advocacy of that resolution, I shall then put it to a test vote of this body, before I enter upon the personal explanation which is due to myself.</p>
                <p className="p-in-sp">
                  So far, this body presents the singular anomaly of having its proceedings reported in this potential hour of the Republic—the most solemn, the most grave and the most momentous that has ever hung over the destinies of this great people—in the hands of private capital and private enterprise. And, however commendable it may be for those who are employed in the enlightenment of the
                  public mind to have control of the public press in this State, to gratify the natural and just curiosity of the people in regard to our deliberations here—I submit it to the candid consideration of every member of this body, if it is not an anomaly that should be corrected, that we, who are here placed in the very crisis of this nation's fate, standing in the jaws of life and death,
                  shall submit to have whatever is said here run through a private conduit or diluted through a private water cooler.
                </p>
                <PageNumber num={120} />
                <p className="p-in-sp">
                  This is the reason, and it is for no other reason, that I propose the resolution, and while I here do not hesitate to yield the proper meed of praise to those who so far have laid the proceedings and the debates of this body before the people of this State, I believe it is a reserved and sovereign power which we, as the agents of the people, should hold in our own hands—and
                  therefore, I have made a special provision that these debates shall be retained by this Convention, and if it is not deemed expedient to publish them in a portable form, that expense can be saved, and so far as I am concerned, at least as a member of this Convention, I intend to meet the grave responsibilities which devolve upon me with whatsoever of sturdy will is in me. I, at
                  least, shall not shrink from the proper expression of my sentiments when the time for the expression of those sentiments arrives, and I claim it as a matter of just right to myself individually and to every peer on this floor, that whatever sentiments or truths shall be uttered here, shall be placed in the face of this generation and in the face of coming posterity. I move the
                  adoption of the resolution which has been read by the Clerk.
                </p>
                <div className="speaker" id="sp82"><span className="persName">Mr. FISHER</span>—</div>
                <p className="p-in-sp">
                  I was about to offer a similar resolution, and I had it already drafted to offer, when the gentleman from Wheeling sent his up to the Clerk's desk, but I did not suppose that a different arrangement would be made from that already undertaken. I understand that the editors of one of the papers in this city have already employed two stenographers, and that they have commenced the
                  publication of the regular proceedings of this body, including the speeches. I propose to offer a resolution authorizing the Chair to enter into a contract with these gentlemen, they being the only persons who have employed stenographers, whose duty it shall be impartially to publish not only all the regular proceedings which are ordinarily placed upon the journal, but the speeches
                  made by the members in this body.
                </p>
                <p>The resolution was then read by the Clerk as follows :</p>
                <p>"Resolved, That the President of this Convention be authorized to contract with the proprietors of the Richmond "ENQUIRER" for continuing and publishing a report of the proceedings of this Convention."</p>
                <p><span className="persName">Mr. CLEMENS</span> accepted the resolution as a substitute for his own, and it was then agreed to by the Convention.</p>
              </div>
              <div className="section" id="vsc1965.v1.2.7.2">
                <h3><span className="headingNumber">1.7.2. </span><span className="head">PERSONAL EXPLANATION</span></h3>
                <div className="speaker" id="sp83"><span className="persName">Mr. CLEMENS</span>—</div>
                <p className="p-in-sp">
                  I now proceed, Mr. President, to the consideration of the matters to which I directly alluded in my opening remarks. I know, sir, that in times of political trial and political excitement, a <PageNumber num={121} /> public man is supposed to be, in this age, public property. And I have had a sufficient degree of experience in public life to know, also,
                  that a thin scurf skin is not suited to its hot and fierce encounters, and that those who are most accustomed to them will find that for a coat of arms a terrapin or an armadillo is the best insignia of at least their powers of endurance. I confess that in alluding to the matter to which I conceive it to be my duty to allude, I acknowledge self-abasement and humiliation. Before this
                  grave body, by all odds the gravest and the most gifted with which I have ever had the honor of being associated, I feel it to be my duty, due to the proprieties of the place and the occasion, to restrain the sentiments of just indignation which leap to my lips and crave for utterance.
                </p>
                <p className="p-in-sp">
                  We are told, sir, by high authority, that "he who conquereth his own spirit, is greater than he who taketh a city," and in times like these a man is not fitted to govern others who has not the capacity to govern himself. I had supposed it possible that among those who in other days were associated with me and shared my acquaintance in this magnanimous city, many of whose familiar
                  faces I now see around me, and who hung with brotherly love over a sick couch, and ministered to the necessities of a broken body; I had hoped that among those of God's fairer and better part of creation, many of whose fair faces now beam upon me, and whose soft and velvety hands ministered to the necessities of a sick chamber, I would find, when these accusations were travelling
                  their foul rounds in the public press, some sense of indignant remonstrance coming to my relief, against the charge that I, a member of Congress from Virginia, could deliberately, and in cold blood, send under my hand, franked documents to free negroes. Sir, if I had no sense of self-respect left, I had hoped that those who knew me, and who knew the honorable families in Eastern and
                  Western Virginia, in Georgia, in Alabama, in Louisiana, in Kentucky, in Missouri, in whose veins courses kindred blood, would suppose that however mean I might be, I would not double a rose leaf under their pillow, to give a moment's pain. It is one of the darkest portents of the times, that under these circumstances gentlemen—Virginia gentlemen—have affected to believe such a charge
                  against me. Sir, if there is any gentleman in this State who has hitherto shared my acquaintance, and who, knowing me in days past, has believed I could be guilty of so mean an act, let him never dare to raise his eyes or hand in kindness to me again.
                </p>
                <p className="p-in-sp">
                  But, sir, I do not choose to deal with this question in this indirect way. It happens that I have the fullest and the most complete proofs <PageNumber num={122} /> which will be submitted to the proper authorities at Washington. It happens that I left Washington city ten days before the assembling of this Convention, and was at home canvassing for a seat
                  in this honorable body, and during my absence, without consultation with me, without my knowledge, without my concurrence in any shape or form, a half sheet of the "National Intelligencer," containing the speech of the Hon. John S. Millson, of this State, a speech of the Hon. Mr. Nelson, of Tennessee, and my own speech delivered in Congress, was distributed broad-cas.t over Eastern
                  Virginia. I authorized no one to use my frank. I left no franks for anybody to use. I was not a party indirectly or directly in any shape or form to the sending the half-sheet of the "National Intelligencer" to any part of Virginia. I assert the fact to be, and no man can deny it, that there is not, within the limits of this broad land, a single gentleman who has received, under my
                  frank, a copy of that paper. It happens that I kept a list of all the documents I sent. I sent none but pamphlet copies. I commenced with Princess Anne, and sent one copy to that county, and I sent some copies to Essex to gentlemen I know there. I sent copies to Fredericksburg. I sent none to Orange, none to Albemarle, none to Fluvanna, and not a single copy to Middlesex.
                </p>
                <p className="p-in-sp">
                  The charge is made in the "Charlottesville Jeffersonian," and copied into the "Richmond Examiner," that I sent, under my frank, copies of public documents to free negroes in the county of Orange. The "Alexandria Sentinel" asserting the same fact, says that copies were received in Fluvanna; and a friend from the county of Essex has written to me that copies were received at Jamaica
                  Plains and at Urbanna, in Middlesex. It happens that the only person I do know is the gentleman from Middlesex who represents that county so honorably upon this floor. I did not send a copy to him. That disposes of the whole question. I have the franks and the envelopes, and any gentleman who is accustomed to trace similitude in hand-writing, can decide at once that the whole thing
                  is an ignoble and base forgery. Whether it was the result of ignorance or the result of malice, it is the same, and I put the heel of my indignation upon it ; and if I can trace it back to its author he will have to meet an accountability far greater that that of my own.
                </p>
                <div className="speaker" id="sp84"><span className="persName">Mr. MONTAGUE</span>—</div>
                <p className="p-in-sp">
                  Since my name has been referred to in this matter, it is proper that I should make a statement. Of course, I know nothing about this matter of franking. I am no member of Congress; I know nothing of the benefits or the abuses of the franking privilege. But I do know this : that there came to my post office at home—and it is <PageNumber num={123} /> now
                  in my room at the hotel—under the frank of Sherrard Clemens—whether genuine or not I do not know, for I am not acquainted with the gentleman's hand-writing—his speech, Mr. Millson's speech, Mr. Nelson's speech and an address of Mr. Stuart to his constituents. And there came to a post office in my county, which have been sent to me in Richmond, copies of documents under the
                  gentleman's frank—whether genuine or not I do not know—addressed to two men whom I know to be free negroes, one whom I know can read.
                </p>
                <div className="stage it">[Applause.]</div>
                <div className="speaker" id="sp85"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">Upon the first repetition of the disorder in the galleries, the Chair will order the Sergeant-at-Arms to clear them.</p>
                <div className="stage it">[Cries of good! good !]</div>
                <div className="speaker" id="sp86"><span className="persName">Mr. MONTAGUE</span>—</div>
                <p className="p-in-sp">
                  I know further, from consultation with a worthy and respectable friend, a delegate upon this floor, from the counties of King and Queen and Essex, that similar documents, under the same frank, have gone to free negroes in those two counties. Similar documents under the same frank have been sent to free negroes, as I understand it, in the county of Orange. Whether this be correct or
                  not, I do not know. I make no charge upon that ground. This is all I have to say in regard to the matter.
                </p>
                <div className="speaker" id="sp87"><span className="persName">Mr. CLEMENS</span>—</div>
                <div className="stage it">[The franks being shown to <span className="persName">Mr. C</span>.]</div>
                <p className="p-in-sp">I pronounce both of these signatures to be forgeries.</p>
                <div className="speaker" id="sp88"><span className="persName">Mr. MONTAGUE</span>—</div>
                <p className="p-in-sp">I am glad to hear it. If your Government at Washington, which some of us are disposed to worship, has become so corrupt, and rotten, that it has resorted to forgeries for the purpose of disseminating incendiary doctrines among the free negroes in Virginia, is it not time—</p>
                <div className="speaker" id="sp89"><span className="persName">A MEMBER</span>—</div>
                <p className="p-in-sp">I beg to inquire what the subject before the House is?</p>
                <div className="speaker" id="sp90"><span className="persName">Mr. MONTAGUE</span>—</div>
                <p className="p-in-sp">I have said all I desire to say.</p>
                <div className="speaker" id="sp91"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp"><span className="persName">Mr. MONTAGUE</span> having been appealed to by the gentleman from Ohio [<span className="persName">Mr. CLEMENS</span>] , the Chair considered his response in order. It was impossible to tell the extent to which he would go. The Chair is of the opinion that no farther debate is in order upon the subject.</p>
                <div className="speaker" id="sp92"><span className="persName">Mr. HALL</span>—</div>
                <p className="p-in-sp">I have been acquainted with <span className="persName">Mr. CLEMENS</span>' handwriting for at least 14 years, and I unhesitatingly think, from the best of my judgment, that these endorsements are forgeries.</p>
                <div className="speaker" id="sp93"><span className="persName">Mr. HOLCOMBE</span>—</div>
                <p className="p-in-sp">Reference has been made by the gentleman from Wheeling [<span className="persName">Mr. CLEMENS</span>] 
                  to a statement in the "Charlottesville Jeffersonian"—a paper published in my county by one of my constituents—that documents were sent to free negroes in that county purporting to be under the frank of <span className="persName">Mr. CLEMENS</span>. Now, sir, I heard <PageNumber num={124} /> a Post master in that county say, that such documents, under such
                  frank, had come to men in that county whom he knew to to be free negroes. That, I believe, was the entire statement made in the " Jeffersonian Republican." I do not remember the article, but I do not believe that there was any statement in it which necessarily implicated <span className="persName">Mr. CLEMENS</span> in a manner inconsistent with the explanation he has given to the
                  Convention. The statement of the paper that documents came to these persons, was true.
                </p>
                <div className="speaker" id="sp94"><span className="persName">Mr. MORTON</span>—</div>
                <p className="p-in-sp">
                  In connection with the explanation which the gentleman from Wheeling has made, as my constituents are interested in this matter, and as their representative, they placed in my hands the papers which have been submitted to the consideration of this House. I think it proper to state that I have a letter from five or six gentlemen, of high respectability, in that county
                 [Orange] , who mentioned the names of free negroes who have received incendiary documents under the frank of the gentleman from Ohio [<span className="persName">Mr. CLEMENS</span>] 
                  . I state further, that numerous documents of the same character had been sent to the county of Harrison, and such was the public indignation upon their reception that they were collected and publicly burned. I am gratified with the explanation made by the gentleman, and I hand over these papers to him with a great deal of pleasure, with the simple request that after he makes the
                  investigation, which he may deem it due to himself to make, he shall return them to me.
                </p>
                <p><span className="persName">Mr. BRENT</span> offered the following resolutions which were referred to the Committee on Federal Relations :</p>
                <p>
                  Resolved, by the people of Virginia, in Convention assembled, That we are warmly attached to the Union of these States. That we do not recognize in the diversity of our institutions any cause of conflict between the different States, but, on the contrary, we believe the great varieties of soil, of production and of interests in a Republic like this, grounds for a more lasting and
                  perfect Union.
                </p>
                <p>
                  Resolved, That in times like these it becomes the duty of every patriot, to rise above party or sectional considerations, to make an earnest effort to save the Union, in that spirit of generous compromise in which the Constitution was framed ; and therefore, in the spirit of compromise, we are willing to adopt the amendment to the Constitution proposed by Senator Crittenden, or any
                  other plan of conciliation equivalent thereto, which has been, or may be hereafter offered, by which harmony may be restored between the people of the different States, and the Union perpetuated.
                </p>
                <p>
                  Resolved, That in the opinion of this Convention, the employment <PageNumber num={125} /> of the army and navy of the United States to coerce submission of the seceding States will inevitably plunge the country into civil war, and entirely extinguish all hope of settlement of the issues now pending. We, therefore, earnestly invoke the Federal
                  Government, as well as the seceding States to withhold and stay the arm of military power, and in no pretext whatever to bring on the country the horrors of civil war, until the people themselves can take such action as our troubles demand.
                </p>
                <p><span className="persName">Mr. TURNER</span> offered the following resolutions, which were referred to the committee on Federal Relations :</p>
                <p>Resolved, by the people of Virginia in Convention assembled, That if all efforts to reconcile the unhappy differences existing between the two sections of the country shall prove to be abortive, then, in the opinion of this Convention, every consideration of honor and interest demands that Virginia shall unite her destiny with the slaveholding States of the South.</p>
                <p>Resolved, That when any one or more of the States has determined or shall hereafter determine, under existing circumstances, to withdraw from the Union, we are unalterably opposed to any attempt on the part of the Federal Government to coerce the same into reunion or submission, and that we will resist the same by all the means in our power.</p>
                <div className="speaker" id="sp95"><span className="persName">Mr. MONTAGUE</span>—</div>
                <p className="p-in-sp">
                  Mr. President, I rise to a personal explanation. It has been suggested to me by members of this Convention, that perhaps the last remarks I made might admit of an improper construction. I understand I stated that the government at Washington had resorted to forgery for the purpose of scattering incendiary documents. In making this remark I did not mean to be understood as stating
                  that the speeches of <span className="persName">Mr. CLEMENS</span>, <span className="persName">Mr. MILLSON</span> and <span className="persName">Mr. NELSON</span> were incendiary documents. I could not have intended to refer to them, inasmuch as I had never read them, and am therefore entirely ignorant of their character. What I meant to say was, that inasmuch as public documents were sent to
                  free negroes under the forged frank of a member of Congress, it was indicative of a state of things at Washington which was dangerous to our interests and safety.
                </p>
                <p><span className="persName">Mr. MORRIS</span> offered the following resolutions, which were referred to the Committee on Federal Relations :</p>
                <p>1. Resolved, That the union of the South is the safety of the South.</p>
                <p>2. Resolved, That in our opinion each of the remaining slaveholding States of this Union should speedily resume the powers delegated by them to the Federal Government, and cooperate afterwards.</p>
                <p><span className="persName">Mr. TREDWAY</span> offered the following resolution :</p>
                <PageNumber num={126} />
                <p>Resolved, That the Committee on Federal Relations be instructed to inquire into the expediency, as rapidly as practicable, whether any movement of arms or men have been made by the General Government to any Fort or arsenal, in or bordering upon Virginia, indicating a preparation for attack or coercion.</p>
                <div className="speaker" id="sp96"><span className="persName">Mr. EARLY</span>—</div>
                <p className="p-in-sp">I rise to a question of order. I suggest that that resolution does not go to the Committee on Federal Relations under the rules, but must be acted upon by the Convention.</p>
                <div className="speaker" id="sp97"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">The Chair is of opinion that the resolution is in order.</p>
                <p>A member having called for the reading of the resolution appointing the Committee on Federal Relations, it was read by the Clerk.</p>
                <div className="stage it">[The resolution was published in the proceedings of last week.]</div>
                <div className="speaker" id="sp98"><span className="persName">Mr. EARLY</span>—</div>
                <p className="p-in-sp">I think that the Chair has misapprehended my point of order, because I have not been able to make myself understood.</p>
                <p className="p-in-sp">
                  This is a resolution of instruction to the Committee on Federal Relations as to an inquiry to be made by them. Now, the resolutions that go to the Committee, are resolutions which they are to act and report upon to this Convention. But this is a resolution instructing them to make inquiry as to a matter of fact, and my point of order is not that the resolution is not in order, but
                  that it does not go to the Committee on Federal Relations under the rule.
                </p>
                <div className="speaker" id="sp99"><span className="persName">Mr. R. Y. CONRAD</span>—</div>
                <p className="p-in-sp">
                  I desire only to say, sir, that whilst the object of the gentleman who makes the point of order is one in which I entirely concur, it seems to me that he is in error in making the question of order at all. If, for instance, a resolution of this sort touching Federal Relations, is permitted without objection, to be referred, it is because it is the general sense of the Convention that
                  it should be referred; and I think that the proper course would be for the Convention to pass upon it before it is sent to the Committee. The resolution being one of instruction, should, in my opinion, be acted upon before the reference. This action the Convention ought to take upon an objection to the reference of the resolution being made.
                </p>
                <div className="speaker" id="sp100"><span className="persName">Mr. EARLY</span>—</div>
                <p className="p-in-sp">My object is to make that objection, but how can that be done if it is decided that the resolution goes to the Committee under the rule?</p>
                <div className="speaker" id="sp101"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">It is entirely competent for the gentleman to make an objection.</p>
                <div className="speaker" id="sp102"><span className="persName">Mr. EARLY</span>—</div>
                <p className="p-in-sp">I object then.</p>
                <div className="speaker" id="sp103"><span className="persName">Mr. R. E. SCOTT</span>—</div>
                <p className="p-in-sp">It seems to me, sir, that this resolution proposes <PageNumber num={127} /> to devolve a duty upon the Committee on Federal Relations which would be better discharged by a Select Committee.</p>
                <p className="p-in-sp">
                  Now, sir, the Committee on Federal Relations have just entered upon the important duties which have devolved upon them, and I apprehend that we shall need at every stage of our proceedings a full meeting of that Committee to enable us to discharge these duties within a reasonable time. Now, sir, in what manner or at what time the members of that committee are to make this
                  investigation I confess I do not well see, without trenching upon that important business, in respect to which I am sure this Convention feels solicitous. I would respectfully suggest to the mover of the resolution to modify it so as to propose this inquiry to be made by a select committee.
                </p>
                <div className="speaker" id="sp104"><span className="persName">Mr. TREDWAY</span>—</div>
                <p className="p-in-sp">I accept the suggestion of the gentleman from Fauquier [<span className="persName">Mr. SCOTT</span>] , and will alter my resolution in conformity therewith.</p>
                <p>The resolution was altered as suggested, with a proposition that the committee to which it was proposed to be referred should consist of five.</p>
                <div className="speaker" id="sp105"><span className="persName">Mr. EARLY</span>—</div>
                <p className="p-in-sp">I think I understand the purpose of my friend from Pittsylvania [<span className="persName">Mr. TREDWAY</span>] 
                  , in offering that resolution ; but I would ask him where is the necessity, where the propriety, of making this inquiry? What difference will it make with regard to our deliberations if the President of the United States, in the exercise of his undoubted authority, has thought proper to send guns to Fort Monroe or troops to Harper's Ferry to protect the public property there? In what
                  good can this inquiry result? Is it not giving too much importance to this subject? The only effect it can have is to add to the excitement already existing in the country.
                </p>
                <p className="p-in-sp">
                  Sir, I am not at all alarmed and I have no idea that my constituents nor the constituents of my friend from Pittsylvania feel any alarm at the idea that guns should be placed upon the parapets of Fort Monroe pointing towards Hampton. I do not think the people of Elizabeth City have any apprehension that the guns are given that direction for any aggressive purpose, or that they are
                  arranged in the manner reported, with any other view than that of defence. I am satisfied that in this movement there was no intention of intimidating the citizens of Virginia, and I may add that they are in no danger from these guns unless they may foolishly run their heads into the mouth of one of them.
                </p>
                <p className="p-in-sp">
                  I don't see, Mr. President, any good that can result from this inquiry, and believing that it can produce none, I shall vote against the resolution. It seems to me the gentleman is attaching too much importance <PageNumber num={128} /> to this matter by making it a subject of inquiry by this body. In 1832 the then President of the United States, while a
                  Convention was in session in South Carolina, or about that time, sent troops to the ports in Charleston harbor. A correspondence<span className="note" id="Note22"><span className="noteLabel">2</span>Mr. Early corrected the report of his reference to this correspondence in the Proceedings of February 21, page 129.</span> took place on the subject between the then Governor of the State and the
                  President in which the Governor was told that the President of the United States was Commander-in-chief of the Army and Navy of the country, and that he was not responsible to him for any disposition which he thought proper to make of them. We, in Virginia, did not rush then to oppose this movement, and if we make the inquiry now which the resolution proposes we may receive a similar
                  answer to that given to the Governor of South Carolina in 1832.
                </p>
                <div className="speaker" id="sp106"><span className="persName">Mr. TREDWAY</span>—</div>
                <p className="p-in-sp">I offer this resolution simply as a matter of enquiry, in order that some light might be obtained upon a subject which has caused so much anxiety, and led to so much comment. I know that the gentleman from Franklin, , [<span className="persName">Mr. EARLY</span>] 
                  , nor his constituents, have no fear from any such demonstrations as have been indicated; but let me say to the gentleman, that they have given rise to a great deal of interest in the public mind. My purpose is to ascertain and know whether at this time, when this Convention is in session, when it has gone forth to the world that a large majority of this body were sent to deliberate
                  with caution and forbearance—not with precipitate action—but to endeavor, by the most cautious and prudent counsels, if possible, to avert the calamities of a civil war, and procure an adjustment of the impending difficulties between the General Government and some States of this Union, I say I am anxious to ascertain whether, while occupying this position, the various rumors in the
                  public prints that the General Government was preparing to make war even upon Virginia, coming, as she is, in the capacity of peace-maker, are true or not. I want to know if it is true that, under these circumstances, the General Government is in fact preparing itself to make an attack upon Virginia, if she shall think proper, in a certain contingency, to take the course of other
                  States, which course I will not hesitate to take at the proper time—not, however, as long as I think it practicable to procure an adjustment of our difficulties. There are some gentlemen in this body who say that preparations are making throughout the land; and though my friend from Franklin
                 [<span className="persName">Mr. EARLY</span>] 
                  , may not be alarmed, I can say that many of my constituents are alarmed, and many in Virginia are alarmed. I want to know if the General Government is taking the course which the resolution has indicated, and which I understand to be a menace. And if the Government is taking such a course, and with a view to <PageNumber num={129} /> menace, I want that
                  we should, in our deliberations here, prepare to meet the worst. I want to know these facts, and if they shall prove to be true, I shall contribute all I have at command to meet the issue, when it shall be made.
                </p>
                <div className="speaker" id="sp107"><span className="persName">Mr. DORMAN</span>—</div>
                <p className="p-in-sp">
                  I have no particular desire to discuss this question myself. I simply rise for the purpose of saying that the gentleman from Jefferson, who is immediately interested in this discussion, is absent and will not be here until tomorrow. I would ask the Convention, therefore, as a matter of justice to him, to lay the resolution on the table until he shall be in his place. I will then move
                  myself to take it up. I make a motion to that effect.
                </p>
                <div className="speaker" id="sp108"><span className="persName">Mr. FISHER</span>—</div>
                <p className="p-in-sp">I call the yeas and nays on that motion.</p>
                <div className="speaker" id="sp109"><span className="persName">Mr. WISE</span>—</div>
                <p className="p-in-sp">
                  I rise to a point of order. You adopted a resolution which is the standing order of the House, providing that all resolutions touching Federal Relations shall be sent to the committee appointed to consider such subjects. Here is one touching federal relations, moving instructions to the committee. Now, as to the resolution itself—its subject matter is one thing and the instructions
                  are another. If the instructions relate to the action which shall be taken on the resolution, under the order of the Convention, the resolution itself without the instructions goes to the Committee on Federal Relations. The instructions are incidental questions which shall be decided by the Convention at once. Now, is it regular to move to lay the resolution on the table when the
                  standing order directs its reference at once, except so far as you have to decide upon the instructions?
                </p>
                <div className="speaker" id="sp110"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">The Chair thinks that when a resolution is offered on this subject, it is within the province of the Convention to say whether it shall go before the Committee or not.</p>
                <div className="speaker" id="sp111"><span className="persName">Mr. WISE</span>—</div>
                <p className="p-in-sp">I regret very much that the question comes up in this incidental form. I would prefer to have it made originally a question of debate, and not be made an indirect question as it is, because the vote on the instruction may tend to bring about a different result from that which would follow a vote upon the direct question.</p>
                <div className="speaker" id="sp112"><span className="persName">Mr. DORMAN</span>—</div>
                <p className="p-in-sp">I rise to a point of order. I maintain that debate is out of order, upon a motion to lay on the table.</p>
                <div className="speaker" id="sp113"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">The gentleman from Princess Anne rose to a point of order merely, and of course it was competent for him to state that point of order.</p>
                <p>The question on the motion to lay on the table was then taken by ayes and noes, as follows :</p>
                <PageNumber num={130} />
                <p>
                  AYES-Messrs. Aston, Baldwin, Baylor, Berlin, Blow, Boggess, Boyd, Branch, Brent, Brown, Burdett, Burley, Byrne, Campbell, Carlile, Carter, Clemens, Coffman, C. B. Conrad, Robert Y. Conrad, Couch, James H. Cox, Critcher, Custis, Deskins, Dorman, Dulany, Early, Echols, French, Fugate, Gillespie, Grant, Gravely, Gray, Addison Hall, E. B. Hall, Hammond, Haymond, Hoge, Holladay, Hubbard,
                  Hughes, Hull, Jackson, Peter C. Johnston, Lewis, McComas, McGrew, McNeil, Macfarland, Maslin, Masters, Moffett, MOORE, Nelson, Orrick, Osburn, Patrick, Pendleton, Porter, Pugh, Rives, Saunders, Sharp, Sitlington, Spurlock, Staples, Alexander H. H. Stuart, Chapman J. Stuart, Tayloe, Waller, White, Wickham, Willey, Wilson, WOODS-7 7.
                </p>
                <p>NAYS-Messrs. Janney [President] 
                  , Ambler, Armstrong, Blakey, Boisseau, Borst, Bouldin, Bruce, Cecil, Chambliss, Chapman, Conn, Richard H. Cox, Fisher, Flournoy, Forbes, Garland, Graham, Gregory, Goggin, John Goode, Jr., Thomas F. Goode, Hale, Cyrus Hall, L. S. Hall, Harvie, Holcombe, Hunton, Isbell, Marmaduke Johnson, Kent, Kilby, Kindred, Lawson, Leake, Charles K. Mallory, James B. Mallory, Marshall, Marr,
                  Montague, Morris, Morton, Neblett, Parks, Preston, Price, Randolph, Richardson, Robert E. Scott, Seawell, Sheffey, Southall, Speed, Strange, Thornton, Tredway, Robert H. Turner, Franklin P. Turner, Whitfield, Williams, Wise, Wysor-62.
                </p>
                <div className="speaker" id="sp114"><span className="persName">Mr. R. Y. CONRAD</span> arose when his name was called and said :</div>
                <p className="p-in-sp">I shall vote to lay on the table, merely from personal considerations towards the gentleman from Jefferson, who wishes to be heard on this subject, and not because I would otherwise vote so.</p>
                <p><span className="persName">Mr. NELSON</span> offered the following resolutions which stood referred under the rule to the Committee on Federal Relations :</p>
                <p>Resolved, That the differences between the slaveholding and the nonslaveholding States, can only be settled by the adoption of amendments to the Constitution, and that the interests of both sections of the country, imperiously demand that the slavery agitation should be removed now and forever from the halls of Congress.</p>
                <p>
                  2d. That whilst we hold that the Constitution of the United States makes all the States equal, and gives them all equal rights in the common territory, yet in view of the fact that ours is a Government founded upon compromise, we are willing to divide said territory by a line on the parallel of 36 deg., 30 min., and that we will insist that in all the territory South of said line of
                  latitude, slavery of the African race shall be recognized as property, during the continuance of the territorial government, by a constitutional provision irrepealable—except by the consent of all the slaveholding States.
                </p>
                <p>3d. That a like provision should be made to prohibit Congress from interfering with the subject of slavery in any of the States without the consent of every State, and also to make it the interest of the people of the States, to which fugitive slaves escape, to have them delivered to their owners.</p>
                <PageNumber num={131} />
                <p>4th. That if no adjustment of the differences between the States can be effected, it is the duty of the American people to provide the way for a dignified, peaceful, and fair separation, upon equitable terms and conditions, and for a proper division of the common property.</p>
                <div className="speaker" id="sp115"><span className="persName">Mr. HOLCOMBE</span>—</div>
                <p className="p-in-sp">In accordance with the suggestion of the Chairman of the Committee on Federal Relations, I submit the following resolution :</p>
                <p>
                  Resolved, That in the opinion of this Convention, no adjustment of the unhappy sectional controversy which now divides the country will give lasting peace or permanent security to the slaveholding States, unless it embraces an amendment of the Constitution which will place in their hands such measure of political power as will enable them to prevent any department of the Federal
                  Government from using its authority, in any form, to the prejudice of their institutions.
                </p>
                <div className="speaker" id="sp116"><span className="persName">Mr. TURNER</span>—</div>
                <p className="p-in-sp">I move to take up the resolution which the gentleman from Harrison [<span className="persName">Mr. WILSON</span>] offered on Friday last, for the action of the House. It is a resolution addressing certain inquiries to the Peace Commissioners now at Washington.</p>
                <p>The resolution reads as follows :</p>
                <p>Resolved, That the Virginia Commissioners now in session in the city of Washington, be respectfully requested to report to this body, at their earliest convenience, whether, in their opinion, any result acceptable to Virginia may be expected from the deliberations of that body.</p>
                <div className="speaker" id="sp117"><span className="persName">Mr. DORMAN</span>—</div>
                <p className="p-in-sp">I make a point of order, that under the rules this resolution cannot be taken up unless by a two-thirds vote.</p>
                <p><span className="persName">Mr. DORMAN</span> read the 8th rule, but it was found not to apply to this case.</p>
                <div className="speaker" id="sp118"><span className="persName">Mr. MONTAGUE</span>—</div>
                <p className="p-in-sp">If anything can be settled, there is one principle of parliamentary law that is settled, beyond all dispute, and that is, that whenever any proposition is laid upon the table, it is always in order to take it up.</p>
                <div className="speaker" id="sp119"><span className="persName">Mr. DORMAN</span>—</div>
                <p className="p-in-sp">I yield to the gentleman's experience and withdraw my point of order.</p>
                <p><span className="persName">Mr. TURNER</span> having, at the request of <span className="persName">Mr. JOHNSON</span>, of Richmond, withdrawn his motion for the present, <span className="persName">Mr. JOHNSON</span> offered the following resolution :</p>
                <p>Resolved, That a committee of five be appointed to enquire what compensation the officers of this Convention shall receive, and report the same to the Convention. Adopted.</p>
                <div className="speaker" id="sp120"><span className="persName">Mr. WILSON</span>—</div>
                <p className="p-in-sp">
                  My object in offering the resolution the other day <PageNumber num={132} /> was, if possible, to get some account from the Commissioners at Washington. The newspapers having since supplied information of the progress of the proceedings of the Commissioners, the necessity for this enquiry does not now exist. I therefore ask leave to be permitted to
                  withdraw the resolution.
                </p>
                <p><span className="persName">Mr. TURNER</span> objected.</p>
                <div className="speaker" id="sp121"><span className="persName">Mr. CARLILE</span>—</div>
                <p className="p-in-sp">
                  I trust, sir, that this Convention will not deny to my colleague a right which is conceded by every deliberative body to any of its members. If there be a gentleman upon the floor that desires such a resolution, it is a very easy matter for him to father it himself. The object which my colleague had in view has, as he has stated, been accomplished to a considerable extent, and he now
                  asks us, as a personal favor, that he will be allowed to withdraw his own resolution. This courtesy will not be denied to him, I am sure. I trust that the gentleman who made the objection will withdraw it; and if he desires that such a resolution shall be offered, he can offer it himself. I will say for my colleague, that this is his first experience in any deliberative body; and I
                  trust that this consideration will be sufficient to induce the gentleman who made the objection to withdraw it.
                </p>
                <div className="speaker" id="sp122"><span className="persName">Mr. TURNER</span>—</div>
                <p className="p-in-sp">If my friend from Harrison [<span className="persName">Mr. WILSON</span>] had put his wish to be permitted to withdraw his resolution on the ground that his colleague does, I would have readily consented to the request. I now withdraw my objection.</p>
                <div className="speaker" id="sp123"><span className="persName">Mr. GOODE</span>, of Mecklenburg—</div>
                <p className="p-in-sp">I move to suspend the rules for the purpose of offering the following resolution, with a view to its being acted upon at once :</p>
                <p>
                  Resolved, That in view of the probable immediate passage by Congress of a force bill, we feel called upon to declare, promptly and emphatically, that Virginia will regard an attempt to coerce any seceded State, under whatever pretext made, as in conflict with the fundamental principles of and destructive of our republican institutions, and that a due regard for her own rights and
                  those of our sister States ( for she still claims them as her sisters) would impel her to resist such attempt by all the means in her power.
                </p>
                <div className="speaker" id="sp124"><span className="persName">Mr. GOODE</span>—</div>
                <p className="p-in-sp">I suppose I have a right to state the reasons which induced me to make that motion.</p>
                <div className="speaker" id="sp125"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">Certainly sir.</p>
                <div className="speaker" id="sp126"><span className="persName">Mr. GOODE</span>—</div>
                <p className="p-in-sp">
                  Those who have observed the events of the past few months, know that coercion is to be the policy of the Administration to be inaugurated at Washington on the 4th day of March next. They know that that is to be the great political panacea for all the ills which <PageNumber num={133} /> now afflict the country. They know that the sword is to be sent upon
                  a mission of peace into the heart of the Southern country. Weeks ago, sir, a resolution giving a pledge to sustain the President in a resort to force, was adopted in the House of Representatives by a large majority. Clarke's resolution, offered as a substitute for the Crittenden proposition, in the Senate, received the vote of every Republican. Those resolutions declaring that no
                  additional guarantees or amendments to the Constitution were necessary—that the remedy for existing danger was the enforcement of existing laws—a force bill, upon a test vote, by a large majority has passed to a second reading in the House of Representatives. The President elect foreshadowing, at every step, the policy of coercion, is now on his triumphal march to the Federal
                  capital. That capital is now filled with Federal troops, to be marched down, perchance under the lead of a once honored son of Virginia, for the subjugation of the Southern States. Under these circumstances, sir, I for one believe that Virginia should not delay for one moment to declare the position she will take when the policy of coercion shall be inaugurated. I know, sir, that
                  propositions on this subject have gone to the Committee on Federal Relations, but I would not wait one moment for the report of that Committee. Here, sir, in the face of a Peace Conference inaugurated by the Union-loving people of Virginia, we have insult hurled in our face by the adoption of a force bill in the House of Representatives. Sir, I would have Virginia speak now, I would
                  have her to enter a solemn protest against the policy to be adopted by the incoming administration, and to declare unequivocally where she will stand when the contest comes. I trust, sir, that when it does come—that when Northern ruffians shall be marched down, perchance, under the lead of the Lieutenant General, to carry fire and sword to the now peaceful and happy homes of the
                  South, that the banner of this proud old Commonwealth will float in the van of a great Southern phalanx, and that all her sons will be around it, to strike side by side with those who strike for our household gods.
                </p>
                <p>A delegate remarked that debate was not in order.</p>
                <div className="speaker" id="sp127"><span className="persName">Mr. SCOTT</span>, of Fauquier—</div>
                <p className="p-in-sp">I don't see that the rule cuts off debate. On the contrary, I suppose that upon a question to suspend the rule considerable latitude of debate is allowable upon the merits of the proposition itself ; for a proposition might be of such a nature, when developed by proper discussion, as to furnish reasons why the rule should be suspended.</p>
                <p className="p-in-sp">
                  If, for instance, the motion which the gentleman has made to suspend the rules should happen to be rejected, it may admit of erroneous construction, <PageNumber num={134} /> and hence it is proper that an opportunity should be offered to give reasons why the suspension should be had. Already, very many resolutions touching this very question have been
                  referred to the Committee on Federal Relations, and I may be allowed to say that there is no disposition on the part of that committee, and, as far as I believe, on the part of any member who composes it, to delay one moment beyond what is necessary. In point of fact, sir, the subject matter of that resolution is now under consideration before the Committee, all other questions
                  arising before it having been postponed in order to give it precedence.
                </p>
                <p className="p-in-sp">I say this, sir, because I do not desire that any vote which I may give in opposition to the proposition of the gentleman should admit of the construction that by such vote my purpose or my desire was to postpone action upon this important question.</p>
                <div className="speaker" id="sp128"><span className="persName">Mr. HARVIE</span>—</div>
                <p className="p-in-sp">I would appeal to my friend from Mecklenburg [Mr. GOODE] to withdraw his resolution for the present. I would suggest that he withdraw his motion to suspend the rule, and allow the resolution to go to the committee.</p>
                <div className="speaker" id="sp129"><span className="persName">Mr. GOODE</span>—</div>
                <p className="p-in-sp">I accept the suggestion of the gentleman, and withdraw my motion to suspend the rule.</p>
                <p>The resolution was accordingly referred.</p>
                <div className="speaker" id="sp130"><span className="persName">Mr. FISHER </span>—</div>
                <p className="p-in-sp">There is a resolution of mine, which I sent up this morning, that has not yet been acted upon. I know this omission was the result of misapprehension, the idea being that the resolution which I offered as a substitute for that offered by the gentleman from Wheeling [<span className="persName">Mr. CLEMENS</span>] on the subject of reporting the proceedings of the Convention, was the only one which I had presented. The resolution not acted upon was the first I sent up.</p>
                <div className="speaker" id="sp131"><span className="persName">Mr. PRICE</span>, of Greenbrier—</div>
                <p className="p-in-sp">There appears to be some misunderstanding in relation to the employment of reporters. I understand that the resolution on this subject was never adopted.</p>
                <div className="speaker" id="sp132"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">The gentleman is in error. A substitute offered for the original resolution presented by the gentleman from Wheeling, [<span className="persName">Mr. CLEMENS</span>] , was accepted by that gentleman, and adopted by the Convention.</p>
                <div className="speaker" id="sp133"><span className="persName">Mr. PRICE</span>—</div>
                <p className="p-in-sp">If it is in order, I will move a reconsideration of the vote by which the resolution was adopted.</p>
                <div className="speaker" id="sp134"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">How did the gentleman vote?</p>
                <div className="speaker" id="sp135"><span className="persName">Mr. PRICE</span>—</div>
                <p className="p-in-sp">
                  I did not vote at all. I allowed the matter to pass sub silentio. I am satisfied that there is no sort of necessity for publishing our debates. I think that every member that served in the Convention <PageNumber num={135} /> of 1850, will bear me out in the assertion that the publication of the debates of that Convention became exceedingly irksome to
                  the members of the Convention and the public at large. I have, therefore, no desire to have a corps of reporters employed now to report our debates. I move a reconsideration of the vote by which the resolution was adopted.
                </p>
                <p>The reading of the resolution was called for.</p>
                <p>The resolution having been read,</p>
                <div className="speaker" id="sp136"><span className="persName">Mr. CLEMENS</span> said,</div>
                <p className="p-in-sp">I accepted the substitute offered by the gentleman from Northampton [<span className="persName">Mr. FISHER</span>] in lieu of the resolution which I had offered. That received the full sanction of this Convention, and I now call for the yeas and nays upon the motion to reconsider.</p>
                <div className="speaker" id="sp137"><span className="persName">Mr. WICKHAM</span>—</div>
                <p className="p-in-sp">
                  I was absent this morning when the resolution was offered, and therefore ignorant of its bearing. I saw in the "Enquirer" of this morning that they propose to select such portions of the debates of this Convention as they choose for publication. My object in rising is to know whether that is to be the arrangement under this resolution, or whether it is an arrangement by which the
                  whole proceedings of the Convention are to be published.
                </p>
                <div className="speaker" id="sp138"><span className="persName">Mr. FISHER</span>—</div>
                <p className="p-in-sp">I will inform the gentleman that a full report will be made of all that transpires in the Convention, and I think it is due to the Convention that a full report should be made.</p>
                <p>
                  The question on the motion to reconsider was then taken by yeas and nays, and resulted as follows :<span className="note" id="Note23"
                  ><span className="noteLabel">3</span>The Journal lists Mr. Parks among the 46 delegates casting an affirmative vote; addition of his name will make up the indicated total of ayes in these Proceedings. In the Proceedings of February 27, page 222, Mr. Ephraim B. Hall stated that a few days before, on a motion to reconsider the vote on a resolution by Mr. Clemens regarding the reporting
                    of debates, he had voted in the affirmative, and not in the negative. Presumably he referred to this vote.</span
                  >
                </p>
                <p>AYES-Messrs. John Janney [President] 
                  , Aston, Baldwin, Boyd, Brown, Bruce, Burdett, Burley, Byrne, Campbell, C. B. Conrad, Robert Y. Conrad, James H. Cox, Early, Fugate, Garland, Gillespie, Grant, Gravely, Hale, Holladay, Hull, Jackson, Kilby, Lewis, McGrew, Macfarland, Maslin, Masters, MOORE, Nelson, Patrick, Pendleton, Preston, Price, Saunders, Sr., Southall, Alexander H. H. Stuart, Chapman J. Stuart, Tayloe, Tredway,
                  White, Whitfield, Wickham, Wysor-46.
                </p>
                <p>
                  NOES-Messrs. Ambler, Armstrong, Baylor, Blakey, Blow, Jr., Boggess, Boisseau, Borst, Branch, Brent, Cecil, Chambliss, Chapman, Clemens, Coffman, Conn, Couch, Richard H. Cox, Critcher, Custis, Deskins, Dorman, Dulany, Echols, Fisher, Flournoy, Forbes, French, Graham, Gregory, Jr., John Goode, Jr., Thomas F. Goode, Addison Hall, Cyrus Hall, Ephraim B. Hall, L. S. Hall, Hammond, Harvie,
                  Haymond, Hoge, Holcombe, Hubbard, Hunton, Isbell, Marmaduke Johnson, Peter C. Johnston, Kent, Kindred, Leake, McComas, McNeil, Charles K. Mallory, James B. Mallory, Marshall, Marr, Marye, Sr., Moffett, Montague, Morris, Morton, Neblett, Orrick, Osburn, Porter, Pugh, Randolph, Richardson, Rives, Ro. E. Scott, William G. Scott, Seawell, Sheffey, Sitlington, Speed, Spurlock, Staples, R.
                  H. Turner, Franklin P. Turner, Waller, Willey, Williams, Wilson, Wise, WOODS-84.
                </p>
                <PageNumber num={136} />
                <p>The following resolution, referred to by <span className="persName">Mr. FISHER</span>, was taken up and adopted :</p>
                <p>Resolved, That the Governor of this Commonwealth be requested to inform this Convention of the number of enrolled militia and of the volunteers of the State, the number of companies that have been supplied with arms, their kind and description, as soon as he conveniently can.</p>
                <p><span className="persName">Mr. MONTAGUE</span> offered the following resolution :</p>
                <p>Resolved, That the Virginia commissioners to the Peace Congress, now in session in the city of Washington, be respectfully requested to report to this body, at their earliest convenience, whether, in their opinion, any result acceptable to Virginia may be expected from the deliberations of that body.</p>
                <div className="speaker" id="sp139"><span className="persName">Mr. PATRICK</span>—</div>
                <p className="p-in-sp">I move to lay the resolution on the table.</p>
                <div className="speaker" id="sp140"><span className="persName">Mr. MONTAGUE</span>—</div>
                <p className="p-in-sp">Upon that motion I call the yeas and nays.</p>
                <p>The yeas and nays were ordered.</p>
                <div className="speaker" id="sp141"><span className="persName">Mr. CLEMENS</span>—</div>
                <p className="p-in-sp">I beg leave to make a single suggestion to the gentleman from Middlesex [<span className="persName">Mr. MONTAGUE</span>] . He is perfectly well aware that the resolution appointing these Commissioners embodied a special provision that they should report at the very earliest period to the Legislature, if in session, or if they were not in session, to this Convention.</p>
                <p className="p-in-sp">Now, this is a limitation made by the very power which called them into existence. Where, then, is the necessity for this Convention doing over again what the Legislature has already done? The Commissioners are directed to report as early as possible, and no action of this Convention can accomplish more than was intended by the Legislature.</p>
                <div className="speaker" id="sp142"><span className="persName">Mr. ECHOLS</span>—</div>
                <p className="p-in-sp">I rise to a question of order. The motion before the Convention, is to lay the resolution on the table, and therefore not debatable.</p>
                <div className="speaker" id="sp143"><span className="persName">Mr. MONTAGUE</span>—</div>
                <p className="p-in-sp">I would be glad to make one remark. It is this that the same resolution of the Legislature which provided that these Commissioners should report their proceedings to that body also provides that they should be under the control of the Convention.</p>
                <div className="speaker" id="sp144"><span className="persName">Mr. CLEMENS</span>—</div>
                <p className="p-in-sp">I ask if there is not a special provision ordering them to report at the earliest possible period?</p>
                <p>The resolution was again repeated.</p>
                <div className="speaker" id="sp145"><span className="persName">Mr. BRENT</span>—</div>
                <p className="p-in-sp">
                  I understand that the Commissioners have been instructed to report to the Legislature at the earliest possible time. I would be glad to know how they are to report to the Convention again. The resolution, therefore, urges upon the Commissioners all the expedition which it is in their power to employ. I can, therefore, see no
                  <PageNumber num={137} /> necessity for renewing these instructions. I would ask to have the resolution read under which these Commissioners were appointed.
                </p>
                <div className="speaker" id="sp146"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">The Chair understands that the paper now called for is not in the possession of the Convention.</p>
                <div className="speaker" id="sp147"><span className="persName">Mr. SHEFFEY</span>—</div>
                <p className="p-in-sp">I understand that the Commissioners are required not only to report to the Legislature, but also to the Convention. I think it would be premature for us to call upon them for a report before they are ready to make it in regular form.</p>
                <p>The question on laying the resolution on the table was then taken by yeas and nays, with the following result :</p>
                <p>YEAS-Messrs. John Janney [President] 
                  , Armstrong, Aston, Baldwin, Baylor, Berlin, Blow, Boggess, Bouldin, Boyd, Branch, Brent, Brown, Bruce, Burdett, Burley, Byrne, Campbell, Carlile, Clemens, Coffman, C. B. Conrad, R. Y. Conrad, Couch, J. H. Cox, Critcher, Custis, Deskins, Dorman, Dulany, Early, Echols, Flournoy, Forbes, French, Fugate, Garland, Gillespie, Grant, Gravely, Gray, Goggin, Hale, Addison Hall, E. B. Hall,
                  Hammond, Haymond, Hoge, Holladay, Hubbard, Hughes, Hull, Jackson, Marmaduke Johnson, P. C. Johnston, Lawson, Lewis, McComas, McGrew, McNeil, Macfarland, C. K. Mallory, J. B. Mallory, Marshall, Marye, Maslin, Masters, Moffett, MOORE, Nelson, Orrick, Osburn, Patrick, Pendleton, Porter, Preston, Price, Pugh, Rives, Saunders, Robert E. Scott, Sharp, Sheffey, Sitlington, Slaughter,
                  Southall, Speed, Spurlock, Staples, A. H. H. Stuart, C. J. Stuart, Tayloe, Tredway, Waller, White, Wickham, Willey, WOODS-98.
                </p>
                <p>
                  NAYS-Messrs. Ambler, Blakey, Boisseau, Borst, Cecil, Chambliss, Chapman, Conn, R. H. Cox, Fisher, Graham, Gregory, John Goode, T. F. Goode, Cyrus Hall, L. S. Hall, Harvie, Holcombe, Hunton, Isbell, Kent, Kilby, Kindred, Leake, Montague, Morris, Morton, Neblett, Parks, Randolph, Richardson, Seawell, Strange, Thornton, Robert H. Turner, F. P. Turner, Whitfield, Williams, Wise,
                  Wysor-40.
                </p>
                <div className="speaker" id="sp148"><span className="persName">Mr. BRANCH</span>—</div>
                <p className="p-in-sp">
                  I have a budget of papers in my hand, sir, containing resolutions which I propose to refer to the committee on Federal Relations. I do not know that there is wisdom in much talking and much writing, but I mean to say that I have done the best I could in preparing my resolutions. I have seen so many different resolutions presented before this body—hardly any tending to any one
                  point—that I have determined to present mine. I take occasion to say that there seems to be a great variety of opinions here, all tending to save the honor of Virginia. I class mine among that number, and I trust that that is the opinion and object of every other member of this Convention. But, sir, as I mentioned at an early stage of this Convention that I was travelling in a slow
                  coach, I beg to say that although the clouds are very portentous at the North, yet, sir, I see no reason why I should hasten my gait. Rome is not on fire, as expressed by the honorable <PageNumber num={138} /> gentleman from Princess Anne. We are 151 Roman Senators, delegated to this Capitol this day, and we mean to stand here upon the watch tower, to
                  protect Rome. Virginia is the Rome of America. But, sir, to the point.
                 [Laughter.] 
                  I say that I have seen nothing yet to please me. I am not a politician, and as I have just entered into public life, as I said, I have for the present to cut my own road. I belong to no clique, no political class of politicians. I would save the Union ; I am a man who thinks for himself and judges according to the evidence presented before him. I confess I was somewhat surprised, on
                  reading the "Enquirer" this morning, to see that some great body of troops was coming down from Massachusetts to Richmond. Well, let them come; I am not afraid of them; I am too old to be frightened. I offer my resolutions because it is said that in a multitude of coundillors there is strength. I do not say that my resolutions are perfect, either in grammar or substance; but I
                  apprehend there may be a spark of life in them which the wisdom of twenty-one may take hold of and incorporate into the resolutions they may report. I shall give due consideration to the remarks of the great men in this Sanhedrin. I shall be a good listener; and I promise you, although I am a slow coach, yet if the exigency arises and events transpire at any time which require
                  action, I shall be one of the readiest voters. I am going to talk but little. I shall be a sound and true voter. I love my State, the Union, and the honor of my family.
                </p>
                <p className="p-in-sp">Gentlemen, while I do not look patriarchal, I have more children and grand children, I expect, than any man here. [Laughter.] I beg pardon for exciting your risible faculties. I am probably not as demure upon this occasion as I ought to be; but I cannot talk in any other way. I speak from the fullness of my heart—I mean right. But I believe I have said enough at this time, and I will now ask the Secretary to read my resolutions.</p>
                <p className="p-in-sp">The resolutions were then read by the Clerk as follows and referred to the Committee on Federal Relations :</p>
                <p className="p-in-sp">1. Resolved, That the people of Virginia, in Convention assembled, are firmly opposed to military coercion open or under disguise to the Confederated States of the Union by the Federal Government, and that they will not submit to it.</p>
                <p className="p-in-sp">
                  2. That the State of Virginia is willing to wait a reasonable time for the Northern States of the Confederacy to guarantee to Virginia and her sister Southern States, justice and equality in the Union, based on the principles of the Crittenden resolutions, and a protective power in <PageNumber num={139} /> the Constitution for the preservation intact of
                  their just and equal rights.
                </p>
                <p className="p-in-sp">
                  3. That in pursuance of this policy, and with the view of effecting a restoration of kindly feeling in all parts of the country, and of re-constructing the constitutional compact and Union upon an honorable, equal, and permanent basis, and the hope of securing the return of the Cotton States which have withdrawn from the United States, Virginia will not, for the present, exercise her
                  inherent right of declaring herself an independent sovereignty of America.
                </p>
                <p className="p-in-sp">
                  4. But that desiring and intending to act with the candor and independence becoming her position, and her relations towards her sister States, and the complication and magnitude of the interests, Virginia distinctly declares, that failing, if she should, in this her earnest effort to accomplish the objects above indicated, her natural ties, her clear rights and sacred honor alike
                  dictate a firm and prompt adhesion, on her part, to the common cause of the Southern States, and the blending of their interests and destiny with her own.
                </p>
                <p className="p-in-sp">5. That we invite and request the States of Arkansas, Missouri, Tennessee, Kentucky, Maryland and Delaware, and North Carolina, to appoint Commissioners to meet Commissioners appointed by this Convention, at Wytheville, in the county of Wythe, in this State, on the last Thursday in March next, to devise the best means of prombting our mutual interests.</p>
                <p className="p-in-sp">6. That we elect eleven Commissioners to carry out the provisions of the 5th resolution; that they report their determination to the Convention on the day of April.</p>
                <p className="p-in-sp">7. That a copy of these resolutions be forwarded to the Governors of all the States, to the President of the United States, and to the Governors of the Confederate States of North America, and to the President thereof.</p>
                <div className="speaker" id="sp149"><span className="persName">Mr. CHAMBLISS</span>—</div>
                <p className="p-in-sp">
                  It is in order, I believe, Mr. President, to make some remarks on these resolutions. It is extremely gratifying to me that a move has been made from an unexpected quarter in the right direction. Conservative as I was regarded when at home, I find that on coming here I am looked upon as a little ultra. I confess that when I heard the resolution of the gentleman from the Cockade city
                  and his avowal of a purpose to resist, to the last extremity, all military attempts at coercion, it gave me the greatest pleasure I have experienced for some time. When he says that the ultimate destiny of this good old Commonwealth is indissolubly bound to our Southern brethren, it fills me with still deeper sympathy and good feeling towards him. There
                  <PageNumber num={140} /> is one resolution, however, which my friend from the Cockade city has thought proper to offer that looks a little like waiting. How long will my friend feel disposed to wait? Rome he says is not burning. That is true. But the day that the bill now before Congress, passes, if Virginia is true to herself and her honor, true to her
                  ancient fame; if she will act with a boldness and courage that should become the descendants of so illustrious forefathers—she will find herself out of this Confederacy.
                 [Applause.] 
                  There is no conceivable circumstance, Mr. President, that will make me ever intimate that Virginia shall desert her Southern sisters. If we are to be dragged, as some gentlemen think, either to the North or the South, then in the name of our ancient fame, by whom would we prefer to be dragged? Would you be dragged by the Northern confederacy, your known haters or enemies—or would you
                  prefer to be dragged by your brethren of kindred ties and similar interests?
                </p>
                <p className="p-in-sp">
                  I hope and trust, Mr. President that the dark clouds which now hover over our political horizon, may be dispelled. I trust that some light will yet shine over this confederacy; that all our sisters may be brought into one common confederation again. But I fear that this hope is in vain. The time must come, the time will come, the time is at your door, the enemy is at the gates of the
                  capital. Virginia must present, with her sisters, an unbroken and undivided front. Virginia, if needs be, must become the living barrier behind which her Southern sisters may safely rally. It would be ruin to her fame to suppose for a moment that Virginia will ever be in alliance with the North on any terms that leave out the South, or the Cotton States as they call them. Call them
                  Cotton States, if you choose, by way of division—I had rather be under King Cotton than under King Abolition. I had rather be ruled by King Davis than by Autocrat Lincoln. I am glad that my friend is taking up what I have information is the swelling voice of the people. I tell you, Mr. President, that this Southern feeling is rising in its majesty in the country. I tell you that
                  there are thousands of men on the soil of Virginia today who are ripe for a severance from this Confederacy that were not ripe for it ten days ago. I received a letter this morning from Petersburg, in which this language was used—"You would be astonished to see the change of feeling, wonderful to imagine, that is now going on in the Cockade City." And we are told to wait. Already a
                  voice is coming here from the country to this Convention, saying that the people are arousing—that the people are already dissatisfied with the slow coach movement which we have been making.
                </p>
                <p className="p-in-sp">As I said in the commencement of my remarks, I feel extremely gratified at the resolutions coming from the quarter they do, and I <PageNumber num={141} /> trust they will receive the earnest, deliberate and immediate consideration of the committee to which they have been referred.</p>
                <div className="speaker" id="sp150"><span className="persName">Mr. BRANCH</span>—</div>
                <p className="p-in-sp">I rise to a question which I suppose is called a question of privilege. I am most happy that I gave my friend from the Greenesville district an opportunity of making so beautiful and patriotic a speech ; but I beg leave to say, that he is putting me in the fast coach rather too soon. [Laughter.] 
                  If gentlemen will notice the phraseology of the resolution, they will find that I draw a distinction between the Confederated States of North America and the Southern States of the United States. I do not concur with the gentleman, that the interests of this new confederacy and the interests of the Southern States in the Union are to be perfectly analogous. I cannot agree to that. I,
                  sir, am not willing to place the Southern States now in the confederacy in the same position that our Southern States of the new confederacy place us. It is foreign to my object to impugn the motives of the Southern Confederacy, but I beg leave to say, sir, that I have no sympathy with the action of the Southern Confederacy; for, through their action, I apprehend, Virginia this day
                  is placed in a more delicate relation to the West, the North and the South than was any State before we became a nation of thirteen. I mean to say, that it is our duty to look to the interests of the States now upon the border. Our interests are one and the same.
                </p>
                <p className="p-in-sp">
                  These are my views, and I only want my friend to understand another thing : that my resolution was drawn up this morning about three o'clock, before I knew he had got this late intelligence from my city. I do not feel very much complimented that the change of views of my constituents should be introduced through a foreign element. I should have felt complimented the more, if my
                  constituents had changed their views, that they should have made me their organ. I was elected as their organ, and I was elected on a moderate platform. I am now a moderate man, and whilst I go against coercion, whilst I never mean to give a child of mine to coerce the Southern Confederacy, still, I feel it to be the duty of Virginia to cling to the Union as long as she can, and that
                  she may maintain her prestige, not only throughout North America, but throughout the civilized world, in bringing back those who are now separated from us, and who were once our brethren.
                </p>
              </div>
              <div className="section" id="vsc1965.v1.2.7.3">
                <h3><span className="headingNumber">1.7.3. </span><span className="head">REPORT OF THE COMMITTEE ON ELECTIONS</span></h3>
                <div className="speaker" id="sp151"><span className="persName">Mr. HAYMOND</span>—</div>
                <p className="p-in-sp">
                  I desire to submit a report from the Committee on Elections. The returns from counties have not all come to hand. There are sixteen counties from which no returns have been received either by <PageNumber num={142} /> the Governor of the Commonwealth or the President of this Convention. The Convention has instructed the Committee on Elections to submit a
                  report at their earliest convenience. The Committee have employed all the means in their power to make a final report as early as possible, but on account of the absence of returns from these sixteen counties, they will not be able at this time to make a final and complete report. They are able to present, however, a report which approximates to what will be the final result. The
                  report is short, and I ask that it may be read.
                </p>
                <p>The report was then read by the Clerk as follows :</p>
                <div className="floatingText">
                  <div className="floatingText_body">
                    <p>
                      The Committee on Elections have examined the returns of the number of persons who have voted for and against referring the action of this Convention to the people, as provided by the act of the General Assembly, passed January 14th, 1861, entitled "an act to provide for electing members of a Convention, and to convene the same," and beg leave to report that in all except the
                      following counties to-wit : Barbour, Boone, Braxton, Buchanan, Cabell, Elizabeth City, Greene, Logan, McDowell, Prince William, Putnam, Upshur, Wayne, Wise, Wyoming and York, from which returns have not been received, the whole number of votes cast was 140,511, of which 96,684 were cast for referring to the people, and 43,827 against referring to the people, showing a majority as
                      far as heard from of 52,857 for referring to the people.
                    </p>
                    <p>The Committee will make a further and final report upon this subject on receiving the returns from the delinquent counties.</p>
                    <p>The foregoing result is derived from the returns received by the President of the Convention and the Governor of the Commonwealth.</p>
                    <p>A. F. HAYMOND,</p>
                    <p>Chairman of Committee on Elections</p>
                  </div>
                </div>
                <p>On motion of <span className="persName">Mr. PATRICK</span> the report was laid on the table and ordered to be printed.</p>
              </div>
              <div className="section" id="vsc1965.v1.2.7.4">
                <h3><span className="headingNumber">1.7.4. </span><span className="head">FEDERAL RELATIONS</span></h3>
                <p><span className="persName">Mr. BOULDIN</span> offered the following resolution which was referred to the Committee on Federal Relations :</p>
                <p>Resolved, by this Convention, That any attempt on the part of the Federal Government to reclaim, by military force, the forts and arsenals now held by the seceded States, or to collect, by force, federal revenue from those States, if not itself war, must inevitably lead to war; and in such war, Virginia, with all her military strength, will sustain the seceded States.</p>
              </div>
              <div className="section" id="vsc1965.v1.2.7.5">
                <PageNumber num={143} />
                <h3><span className="headingNumber">1.7.5. </span><span className="head">PERSONAL EXPLANATION</span></h3>
                <div className="speaker" id="sp152"><span className="persName">Mr. WISE</span>—</div>
                <p className="p-in-sp">I rise to a privileged question, and one which affects the debates of the Convention. It is to correct an error in the report. On Saturday last, as you very well remember, I made some remarks in reply to my friend on my left, from Rockbridge [Mr. MOORE] 
                  . In the course of those remarks I asked sundry questions—among others, this : whether in the event the Wide-Awakes should be sent to garrison Gosport Navy Yard, and should be quartered upon the surrounding country—my expression was—whether, in the event they should rob the hen-roosts of Princess Anne, he would help me to drive them out. The question, as reported in the "Enquirer,"
                  is—
                </p>
                <p className="p-in-sp">"Will you assist me to drive out a parcel of Wide-Awakes who may be stationed in Gosport Navy Yard to ransack the counties of Princess Anne and Norfolk, and rob their hen-roosts for provisions?"</p>
                <p className="p-in-sp">This is not a correct report, and I am informed by a friend, this morning, that some have inferred that perhaps my meaning was to cast an imputation upon the resident population of Portsmouth and Gosport. But my friend on the right, the representative from Portsmouth [<span className="persName">Mr. HOLLADAY</span>] 
                  , heard me distinctly then, as I hope he hears me now, and I appeal to him to say that no such imputation was cast upon that population or could be inferred from what I said. I will tell you why it was that, at the time, that matter of fact or of illustration came to my mind. I have within the past week received no less than three letters from friends in Boston, informing me—to use
                  their own language of mysterious movements in the militia of Massachusetts. I am told, sir, that they are not only enrolling, mustering, arming and training their militia, but a system of tyrannical espionage is going on by the power of the most odious instrument of human tyranny—that of the test oath—and what kind of test oath is it? To inquire of every man whether he has any
                  sympathy with Southern secession or Southern rights, and every man who dares to say that he will not fight against his own countrymen, is disarmed and put out of the ranks. That is my information. Directly upon the back of that, I have received information of a test vote in the House of Representatives of the Congress of the United States of 110 to 67, to bring the power of the
                  Executive, of the whole militia arm as well as the arm of the standing army, to suppress—mark the words, as telegraphed to me—to "suppress insurrection,"—not of slaves—it is an alliteration of the utmost force of contrast—but to "suppress insurrection" of States. This the electric wire brings with the lightning speed of the telegraph from the Capital of the Confederated States at
                  Washington to this <PageNumber num={144} /> Capital of States Rights, if it now be the Capital of States Rights that it once was—there are not many here older than the time when it was than the time when Virginia declared at this Capital that each State of this Union, for herself, had the right to judge not only of the infraction but the mode and
                  measure of redress. It was when men's minds were filled with these facts, this information, that I knew, or was informed that Fortress Monroe was not only garrisoned as in ordinary times, but that her big guns were now pointed land-wards. And I tell the gentleman from the Cockade city—no matter what cockade he wears—that there are now people, in the county of Elizabeth City, over
                  whose terra-firma those guns are pointed, who are now trembling, not at those guns, not for fear of those guns, but they are trembling for the sake of Rome, their Rome of Romes—the veritable Rome of a country. They are trembling, not with fear, but with indignation, when they reflect with logical precision that South Carolina has resisted, and if South Carolina and the other six
                  States that are now her confederates are to be coerced, that it will be their time when the time for coercion comes, to resist, if they mean to resist at all, the sending of those guns from Fortress Monroe to coerce South Carolina. With these thoughts in my mind and the additional thought that all the standing army will not be sufficient, nor twice or thrice that number, to coerce
                  Virginia—even with the odds of Fortress Monroe and all the magazines of Harper's Ferry, of Gosport Navy Yard, with all the appliances of coercion in the hands of the Government—if she resents the coercion of the Southern Confederacy, remembering that then there will be a necessity to call forth the militia; seeing that the power is put in the hands of the Executive to call forth an
                  unlimited number of the militia power of States that are 18,000,000 against 8,000,000; seeing, I say, that it would not be the standing army, men trained to war and its usages, but men called Wide Awakes, midnight soldiers, that have been trained by the torches of midnight in the doctrines of incendiarismseeing all this, it was that I asked the question—if these Wide Awakes, not our
                  own citizens, should be sent to garrison Gosport Navy Yard, to insult and rob us—the language was to strut over the county of Princess Anne and rob our hen-roosts whether we should drive them away? That is what I asked, casting no imputation upon our own people. That is all the explanation I have to make.
                </p>
                <p className="p-in-sp">
                  I have but a word more to add. It might be naturally and very reasonably inferred from my political as well as my personal affinity to the editors of the "Enquirer," that their reports of my remarks might generally be received as accurate. Undoubtedly, I presume, the editors <PageNumber num={145} /> of that paper take pains to make their reports
                  accurate. I know that the Reporter who took my remarks down—undoubtedly friendly to me—meant to make a correct report, but it is the fact that I never had the reports of my cursory remarks such as I am now making, correctly made, and I should not wish them published without my knowledge, without my revisal or correction. I have examined the report of the remarks in question, and I
                  find it to be a mere sketch, but as a sketch, it is very inaccurate throughout, both in expression and in the ideas which I intended to express ; but it is no wonder. There was but one Reporter, and it was impossible for him to be otherwise than inaccurate, laboring, as I am told he did, some ten or twelve hours upon the stretch, when it is well known that no Stenographer can stand
                  more than two hours at a time. For myself, unless I am to be reported fully in what I say, I would prefer in order to avoid the necessity of these explanations, that no report shall be made of my remarks at all, unless it be expressed that it does not purport to be an accurate and full report. I ask my friend from Portsmouth to state, according to his recollection, whether the report
                  of what I said was correct or not.
                </p>
                <div className="speaker" id="sp153"><span className="persName">Mr. HOLLADAY</span>—</div>
                <p className="p-in-sp">
                  I take great pleasure in saying that my recollection of what the gentleman did say corresponds precisely with what he has stated. My attention was called to the report of his remarks and I knew that they would be subject to criticism and misconstruction. I called the attention of the gentleman to it, well knowing from my own recollection of what he did say, that he was improperly
                  reported. I take great pleasure in saying that I concur fully with the gentleman's recollection as to what he did say upon the occasion.
                </p>
                <p>On motion of <span className="persName">Mr. PATRICK</span>, the Convention then adjourned.</p>
              </div>
            </div>
            <div className="day" id="vsc1965.v1.2.8">
              <PageNumber num={146} />
              <h2><span className="headingNumber">1.8. </span><span className="head">EIGHTH DAY</span></h2>
              <div className="dateline">Thursday, <span className="date">February 21</span></div>
              <p>The Convention met at 12 o'clock, M., and was called to order by the President.</p>
              <p>Prayer by Rev. <span className="persName">Mr. WM. B. NOLLY</span>, of the Methodist Church.</p>
              <div className="section" id="vsc1965.v1.2.8.1">
                <h3><span className="headingNumber">1.8.1. </span><span className="head">PUBLICATION OF DEBATES</span></h3>
                <div className="speaker" id="sp154"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">
                  In pursuance of the resolution adopted yesterday, upon the subject of reporting the debates of this body, I have had an interview with the editors of the "Richmond Enquirer," and requested them to submit a distinct proposition upon that subject. It has been placed in my hands, a nd the Chair feels itself wholly incompetent, from want of knowledge of business of this description, to
                  make this contract. I submit the communication now to the Convention for such order as it may feel disposed to make in the premises, either by reference to a committee of five or such other disposition as may seem to them best.
                </p>
                <div className="speaker" id="sp155"><span className="persName">Mr. MONTAGUE</span>—</div>
                <p className="p-in-sp">
                  I was about to remark that on yesterday this Convention, by a very decided vote, determined to have their proceedings published, and by that vote they indicated as their medium of publication, the Editors of the "Enquirer." I can very well appreciate the embarrassment of the Chair as not being familiar with such proceedings, and I therefore move that the Secretary of the
                  Commonwealth, a gentleman of large experience in these matters, be authorized by this Convention, to execute that contract with the Editors of the "Enquirer."
                </p>
                <div className="speaker" id="sp156"><span className="persName">Mr. STAPLES</span>—</div>
                <p className="p-in-sp">I move, by way of amendment to that motion, that the Chair appoint a committee of five to make a contract with the Editors of the "Enquirer" in pursuance of the resolution adopted on yesterday.</p>
                <div className="speaker" id="sp157"><span className="persName">Mr. WISE</span>—</div>
                <p className="p-in-sp">
                  I will ask what necessity there is for a change in the resolution at all. It has passed, and it is out of order, without a suspension of the rules, to have it amended. I would respectfully inquire of the Chair, if want of technical knowledge creates any difficulty, it would not be better for him that he should take counsel from gentlemen of some experience in these matters. I do know
                  the Chair is competent to the performance of this duty, and it is in his power to make inquiry, and obtain technical information from the experience of others. We are all often entrusted with business for which we are not educated or trained, or in which we are not experienced. I do <PageNumber num={147} /> hope that the Chair will keep this matter in
                  his superintendence, because it is one of those matters that require for faithful, prompt and efficient execution a unit power. Now, when these terms are submitted to the Chair, we simply ask him to refer to the Secretary of State, who for years has been compelled to make contracts with the printers of your public journals, your laws, your code, the journals of the House, and who
                  knows all the technicalities of the printer's art. I had the duty imposed upon me, as the Executive now has, when Governor of the Commonwealth, to overlook this matter of the public printing, and it was worse than Greek to me to understand all the technicalities relating to the subject, for it required a knowledge of what this was worth, what that was worth, a knowledge of the
                  various type used, the different modifications in price, and a knowledge of the various laws that regulate the subject. I could not determine, but by referring and turning over the whole account of public printing to the Secretary of State. I knew this much : that by referring it to him very much money was saved to the Commonwealth ; and the same thing can be done by yourself, Mr.
                  President, now. This would be better than any committee of five, unless there are a number of practical printers in this house, and of that I am not informed. In advising you how to guard the contract, I will pledge myself, without authority, however, but from my knowledge of him, that the Secretary of State will cheerfully give you his opinion; for I verily believe that he is one,
                  at all events, of the servants of the Commonwealth who likes to have burthens imposed upon him. Dexterous as he is and busy as he is in all the duties that devolve upon him about that capitol, he will leap, at your request, to any task of public duty, and perform it better than any man in this Convention, certainly better than any committee.
                </p>
                <div className="speaker" id="sp158"><span className="persName">Mr. CLEMENS</span>—</div>
                <p className="p-in-sp">I rise to a point of order and it is this, that the motion of the gentleman from Middlesex [<span className="persName">Mr. MONTAGUE</span>] 
                  , being inconsistent with the fixed purpose of this Convention manifested in the passage of the resolution upon which we voted yesterday, it is not competent for the Convention now to consider that resolution without a motion as a condition precedent to reconsider. I make that point of order, that neither the motion proposed by the gentleman on the left of the gentleman from
                  Middlesex, nor the motion of the gentleman from Middlesex is in order until that question is decided.
                </p>
                <div className="speaker" id="sp159"><span className="persName">Mr. WISE</span>—</div>
                <p className="p-in-sp">The motion cannot now be made to re-consider, because that motion was made on yesterday and decided in the negative.</p>
                <div className="speaker" id="sp160"><span className="persName">Mr. CLEMENS</span>—</div>
                <p className="p-in-sp">I had forgotten that circumstance.</p>
                <div className="speaker" id="sp161"><span className="persName">Mr. WISE</span>—</div>
                <p className="p-in-sp">To move a re-consideration, the way would be to suspend <PageNumber num={148} /> the rules, and that will require, I presume, two-thirds, and if we shall refuse to re-consider, after a suspension of the rules, the Chair will still be compelled to let the matter remain in his hands.</p>
                <div className="speaker" id="sp162"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">The Chair is of opinion that the motion of the gentleman from Middlesex [<span className="persName">Mr. MONTAGUE</span>] 
                  is in order, and that the amendment is not in order. The CHAIR understands that the resolution of the gentleman from Middlesex does not conflict at all with the resolution adopted yesterday in regard to the persons with whom the contract is to be made. The CHAIR desires simply to have the advice of the Convention through the report of a committee, as to the propriety of entering into
                  this contract, and when that comes the CHAIR will perform his duty in regard to this matter, and any other duty that he feels himself competent to perform.
                </p>
                <div className="speaker" id="sp163"><span className="persName">Mr. WISE</span>—</div>
                <p className="p-in-sp">I want to know what the motion of the gentleman from Middlesex is.</p>
                <div className="speaker" id="sp164"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">It is to refer the communication of the Editors of the "Enquirer" to a committee of five, to report upon.</p>
                <div className="speaker" id="sp165"><span className="persName">Mr. WISE</span>—</div>
                <p className="p-in-sp">I do not so understand it.</p>
                <div className="speaker" id="sp166"><span className="persName">Mr. MONTAGUE</span>—</div>
                <p className="p-in-sp">My proposition was, that the Secretary of the Commonwealth be instructed, instead of yourself, Mr. President, to execute this contract with the Editors of the "Enquirer."</p>
                <div className="speaker" id="sp167"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">The Chair is of the opinion that that motion is not in order.</p>
                <div className="speaker" id="sp168"><span className="persName">Mr. STAPLES</span>—</div>
                <p className="p-in-sp">I hope I may not be understood as reflecting upon the capacity of the President to make this contract ; I was under the impression that he had declined to make it, and it was, therefore, that I made the motion I did ; and I now withdraw it.</p>
                <div className="speaker" id="sp169"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">The Chair will not decline. The Chair wishes to be understood as never wishing to decline, and if it is the pleasure of the Convention, the Chair will proceed to the execution of the contract under the advice of the Secretary of the Commonwealth.</p>
                <div className="speaker" id="sp170"><span className="persName">Mr. JOHNSON</span>, of Richmond—</div>
                <p className="p-in-sp">I am utterly indifferent to whom the question shall be referred, except that I desire it to be referred to some proper person, and I know no gentleman more competent than the gentleman who has been alluded to by my friend from Middlesex [<span className="persName">Mr. MONTAGUE</span>] 
                  . I, with great pleasure, on yesterday, voted this contract to the editors of the "Enquirer," and voted against its reconsideration; but it seems to me, sir—and I rise for the purpose of obtaining information on the question—that there is some conflict between the communication from one of the editors of the Richmond "Enquirer," as I heard it read, and the resolution of this House
                  <PageNumber num={149} /> adopted several days before; and as I do not wish to be placed in an inconsistent position, my object is to inquire how that is in point of fact. I nominated several days ago, sir, Mr. Elliott, as public printer. I suppose it is his duty to print a journal of the proceedings and to do the public printing, which is prescribed by
                  the Convention. I see, accordingly, from the documents which are daily placed in our seats, that he has proceeded thus to execute the work.
                </p>
                <p className="p-in-sp">I understood on yesterday, sir, that the resolution then adopted was, that the editors of the "Enquirer" should publish the debates of the Convention.</p>
                <div className="speaker" id="sp171"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">The Chair understood that the resolution referred to the publishing of the debates, and nothing else.</p>
                <div className="speaker" id="sp172"><span className="persName">Mr. JOHNSON</span>—</div>
                <p className="p-in-sp">I understood the communication as read to read, "the proceedings of the Convention." I think the word "proceedings" is in the communication.</p>
                <div className="speaker" id="sp173"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">The Chair will state to the gentleman, that the Chair will make no contract that will conflict with any previous resolution of this body.</p>
                <div className="speaker" id="sp174"><span className="persName">Mr. JOHNSON</span>—</div>
                <p className="p-in-sp">I am sure of that, sir. My object in rising was to ascertain the true state of the facts, not wishing to stultify myself by taking an inconsistent position. With your explanation, sir, I shall consent with great pleasure to have the matter referred to the Secretary of the Commonwealth.</p>
                <p><span className="persName">The PRESIDENT</span> announced the following committee on the compensation of the officers of the Convention, ordered to be appointed yesterday :</p>
                <p>Messrs. <span className="persName">JOHNSON</span> of Richmond, <span className="persName">HUBBARD</span> of Ohio, <span className="persName">GREGORY</span> of King William, <span className="persName">COFFMAN</span> of Rockingham, and <span className="persName">SHEFFEY</span> of Smyth.</p>
                <div className="speaker" id="sp175"><span className="persName">Mr. HAYMOND</span>—</div>
                <p className="p-in-sp">
                  I am directed by the Committee on Privileges and Elections to present a list of the delegates who are elected to this Convention.<span className="note" id="Note24"><span className="noteLabel">1</span>See Doc. No. V, Report of the Committee of Elections Showing Who Are Elected Members of the Convention, Appendix C.</span>
                </p>
                <p className="p-in-sp">I am also instructed by the Committee on Elections to ask the Convention to adopt an ordinance for its government in cases of contested elections. I will have it read, sir, and I will then explain to the Convention the reasons why it is necessary to adopt it.</p>
                <p className="p-in-sp">The Secretary read the ordinance as follows :</p>
                <p className="p-in-sp">
                  Whereas, the General Assembly, on the 14th day of January, 1861, passed an act, entitled "an act for electing members of a Convention, and to convene the same ;" and whereas, by the eighth section of said act it is provided that in the case of a contested election, the same shall be governed in all respects by the existing laws in regard to contested
                  <PageNumber num={150} /> elections in the House of Delegates, unless otherwise ordered by the Convention. And whereas, it seems to the Convention, that the said existing laws in regard to contested elections in the House of Delegates are not suitable or proper for the government of the Convention, in cases of contested elections, the Convention deems it
                  necessary to prescribe proper rules for contested elections for seats therein.
                </p>
                <p className="p-in-sp">It is, therefore, ordained by the Convention, that the existing laws in regard to contested elections in the House of Delegates, shall not be applied to, or govern, in cases of contested elections for seats in the Convention.</p>
                <p className="p-in-sp">
                  It is further ordained by the Convention that any person contesting the election of another as a member of this Convention shall within a reasonable time after the day on which the election was held, give to the other notice thereof in writing and a list of the votes he disputes with his objections to each, and of the votes improperly rejected for which he will contend. If he objects
                  to the legality of the election or eligibility of the person elected, the notice shall set forth the objections, and the person whose election is contested shall within a reasonable time after receiving such notice deliver to his adversary a like list of the votes which he disputes, with his objections, if any he has, to the eligibility of the contesting party. Each party shall
                  append to the list of voters he intends to dispute or claim, an oath to the following effect : "I do swear that I have reason to believe the persons whose names are above mentioned are not legally qualified ( or are qualified as the case may be) 5 to vote in the county of or corporation or district of
                </p>
                <p className="p-in-sp">
                  The contesting party, and the person whose right is contested, shall respectively, begin to take depositions within a reasonable time after the delivery of the notice aforesaid, by the contesting party; and they shall finish taking the same in a reasonable time after delivery of such notice. The word reasonable, as used in this ordinance, shall be construed with reference to the
                  circumstances attending each case, the condition of the parties, and the fact that this ordinance has not been adopted until now, so as to prevent as far as practicable, surprise to the parties. This ordinance shall apply to contests now pending, as well as those which may hereafter be commenced.
                </p>
                <div className="speaker" id="sp176"><span className="persName">Mr. HAYMOND</span>—</div>
                <p className="p-in-sp">It is perhaps proper that I should explain to the Convention the ordinance which has been offered for its consideration by direction of the Committee on Elections.</p>
                <p className="p-in-sp">That ordinance was prepared by the direction of the Committee, and submitted to that Committee, and the Committee directed its Chairman to present it to this body for adoption. I will now state to the <PageNumber num={151} /> Convention the reasons why it has become necessary to adopt a regulation of that character.</p>
                <p className="p-in-sp">
                  It will be seen, by reference to the 8th section of the act calling this Convention, that it provides that this body shall be governed by the laws regulating contests for seats in the House of Delegates, unless otherwise ordered by this Convention. The Committee of election, after a contested election was referred to them for consideration, directed their attention to the law
                  regulating contested elections of the House of Delegates, and upon examining the 8th chapter of the code, page 95, it was found that the law, as there contained is wholly inapplicable and impracticable for the government of a contested election in a body of this character. It results from this fact. The first section of that chapter provides that the contestant "shall, within
                  twenty-five days after the day on which the election commenced, give to the other notice thereof in writing, and a list of the votes he will dispute, with his objections to each and all of the votes improperly rejected, for which he will contend," and also that the party whose right to a seat in the General Assembly is contested, "shall, within twenty days after receiving such
                  notice, deliver to his adversary a like list of the votes which he will dispute." Thus, sir, will twenty-five days be consumed if the parties are compelled to comply with the provisions of this first section.
                </p>
                <p className="p-in-sp">The second section of that chapter prescribes the parties shall commence taking their depositions within thirty days from the service of the notice by the contesting party, and that they shall finish taking those depositions within thirty days before the meeting of the General Assembly.</p>
                <p className="p-in-sp">This is the law and the whole law upon that subject, and you will at once see that they have no law in fact by which this Convention can be governed to decide a case of contested election.</p>
                <p className="p-in-sp">
                  The Ordinance that has been presented to the consideration of the Convention is the same as the law contained in the Code except that it prescribes no specific time within which any act shall be done. But it provides that the contestant shall serve notice upon the party who has the returns, in a reasonable time after the election ; and that the party whose seat is contested, shall
                  serve a like notice specifying, as this chapter does, upon the party contesting in a reasonable time after he is so served, and that the parties shall commence taking the depositions within a reasonable time after the service of the notice, and shall complete the taking of the same within a reasonable time after the service of the notice.
                </p>
                <PageNumber num={152} />
                <p className="p-in-sp">
                  But, sir, the ordinance does not stop there. It goes on to define, as nearly as it may be, what the word reasonable shall be considered to mean. It provides that it shall be considered with reference to the circumstances of each case, the conditions of the parties and the fact that this ordinance has not been adopted until now, so as to prevent, as far as practicable, surprise to the
                  parties.
                </p>
                <p className="p-in-sp">
                  This, sir, in my judgment and the judgment of the members of the Committee, is the best provision that could be adopted for the government of the case before the Committee, or any other case that may be submitted. The fact that the General Assembly has failed to prescribe any definite or practicable rule for the government of this Convention, in cases of contested elections, places
                  this Convention in a difficult position, and there is no alternative except to adopt some ordinance or regulation of the character of the one offered. The simple result of that ordinance is, to place all contestants in a position before this body in which they shall be guaranteed full justice, and to avoid delays to the utmost extent consistent with the avoidance of surprise, and the
                  allotment of a reasonable time to make proper preparations.
                </p>
                <p><span className="persName">Mr. MONTAGUE</span>, who was occupying the chair temporarily, said there were two questions from the Committee on Elections to be acted upon one the report on elected delegates and the other the ordinance to which the gentleman had just referred.</p>
                <p>The question being upon the report on the election of delegates, <span className="persName">Mr. HAYMOND</span> moved that it be laid upon the table and printed.</p>
                <p>The motion was agreed to.</p>
                <p>The next question was upon the ordinance, and the vote being taken on its adoption, it was decided in the affirmative.</p>
                <div className="speaker" id="sp177"><span className="persName">Mr. R. Y. CONRAD</span>—</div>
                <p className="p-in-sp">
                  The committee on Federal Relations have instructed me to ask leave of the Convention to sit during the sessions of the Convention. I would state, sir, as a reason for this request, and also to satisfy any impatience which may exist as to the action of the committee, that the committee has been in constant session every morning, up to the time of the session of the Convention, and yet
                  we make but little progress, owing to the inherent difficulties of the subject before us. At the request of the committee, therefore, I ask for the adoption of the following resolution :
                </p>
                <p>Resolved, That the Committee on Federal Relations have leave to sit during the session of this Convention, until further orders.</p>
                <p>The resolution was adopted.</p>
                <div className="speaker" id="sp178"><span className="persName">Mr. EARLY</span>—</div>
                <p className="p-in-sp">I rise, sir, to make a personal explanation. It is simply to correct a slight inaccuracy that has occurred in the report of the <PageNumber num={153} /> remarks which I made on yesterday, on the resolution of the gentleman from Pittsylvania [<span className="persName">Mr. TREDWAY</span>] . I do this, sir, with no idea that the reporter intended to do me any injustice. I am fully aware of the disadvantages under which I labor in making myself understood. I know, sir, that sometimes I do not, perhaps, articulate sufficiently distinct to make my remarks audible, and it is probably owing to this that the mistake occurred.</p>
                <p className="p-in-sp">
                  I am reported as alluding to a correspondence which is said to have taken place in 1832, between the President of the United States at that time and the then Governor of South Carolina. I am not aware of any correspondence that occurred between these functionaries. The correspondence to which I alluded was the correspondence that took place between Mr. Fillmore and the Governor of
                  South Carolina, during the years 1851 or 1852. I am not prepared to say at what particular time the correspondence was held. The Convention of South Carolina assembled in 1852, though the election was, I believe, held in 1850. It was to the correspondence which took place at that period that I referred, and not to any correspondence that may have taken place in 1832. I make this
                  explanation to put myself right, and not with a view to make any complaint of the reporters. I found that the substance of a few other remarks I made is correctly reported.
                </p>
                <div className="speaker" id="sp179"><span className="persName">Mr. WOODS</span>—</div>
                <p className="p-in-sp">I offer the following resolutions, and move their reference to the Committee on Federal Relations. After they shall have been reported, I desire to say a few words by way of explanation :</p>
                <ul>
                  <li className="item">1. Resolved, That the allegiance which the citizens of Virginia owe to the Federal Government of the United States of America is subordinate to that due, to Virginia, and may, therefore, be lawfully withdrawn by her whenever she may deem it her duty to do so.</li>
                  <li className="item">2. That in case the State of Virginia should exercise this authority, her citizens would be in duty bound to render allegiance and obedience to her alone.</li>
                  <li className="item">
                    3. That Virginia recognizes no authority in any government, State or Federal, to coerce her or any of her citizens, to render allegiance to the government of the United States, after she may, in the exercise of her sovereign power, have withdrawn from it; and that she will regard any attempt at coercion as equivalent to a declaration of war against her, to be resisted at "every
                    hazard and to the last extremity."
                  </li>
                  <li className="item">
                    4. That the States of South Carolina, Georgia, Florida, Alabama, Mississippi, Louisiana and Texas, having severally and formally withdrawn the allegiance of their respective people from the United States <PageNumber num={154} /> of America, a faithful, earnest desire to avert civil war, and the sound conservative sentiment of the country, alike
                    indicate to the Government of the United States the necessity and policy of acknowledging their independence.
                  </li>
                </ul>
                <div className="speaker" id="sp180"><span className="persName">Mr. WOODS </span></div>
                <p className="p-in-sp">
                  Representing, as I do, sir, the conservative sentiment of the county of Barbour, to the suffrages of whose voters I am indebted for the honor of a seat upon this floor, I have sought this opportunity of presenting to this Convention the views embodied in these resolutions, which have been fully canvassed, carefully considered, and which I believe to express the sentiments of my
                  people.
                </p>
                <p className="p-in-sp">
                  I have not deemed it necessary, in offering these resolutions, to accompany them with any declaration on the part of my people, of the devotion of the people of Virginia to the Union of the United States of America. I do not conceive that it is necessary that such a declaration should be made by me, in order that the real views of Virginia in regard to the Confederacy of the United
                  States should be understood. The whole history of Virginia, from her colonial condition to this day, has been a history of struggle, of sacrifice, first to secure the independence of the U. States, and since to establish and secure, upon a constitutional basis, the Union of the United States. She has given the most substantial evidence of the value that she placed upon the Union of
                  these States, in the sacrifices she made for it. First, in the struggle for independence; foremost in her readiness to make the sacrifice necessary to obtain it, first in the councils of the. nation in preparing its organic law, she holds today the position that she held then. When the first murmur of discontent came up from the small and weaker States, that Virginia, with her vast
                  extent of Territory, when filled with an active, enterprising and industrious population, might exert an undue influence in the councils of the common country, she, with a munificence such as was never manifested before, divested herself of an empire and to-day she realizes how "sharper than a serpent's tooth it is, to have a thankless child." But, not content with that; lest her
                  ample boundaries might still have been considered too broad, and her influence too powerful, she gave to the Union the great State of Kentucky; and, sprung from her bosom, that great State, the eldest daughter of the Union, sat down at the maternal board the equal of her mother.
                </p>
                <p className="p-in-sp">For these reasons, I do not deem it necessary to say anything to prove the fidelity of the people of Virginia to the Union of these States; but I have seen proper, Mr. President, to present in a specific form, in the resolutions which I have had the honor to present to this Convention,</p>
                <PageNumber num={155} />
                <p className="p-in-sp">
                  the views, such as they may be, that I entertain in relation to the allegiance of the citizens of Virginia to their State. I remember that there was a Virginia before the existence of a government of the United States. I remember that the citizens of Virginia owed allegiance to the State of Virginia before there was a government of the United States; and I remember that when the
                  State of Virginia acceded to the compact formed by the Constitution of the United States, that she conceded to me, and all her citizens, the privilege of yielding obedience and allegiance to the government of the United States, for the purpose indicated in the Constitution of the United States. Remembering these things, I feel that it becomes her to indicate her position clearly. If
                  a conflict of allegiance should arise, I believe the duty of every citizen of Virginia is just as clear as the light of day, to yield obedience to the State of Virginia and her only. As a consequence of this, my third resolution announces the doctrine that Virginia cannot, therefore recognise any authority in any Government, State or Federal, to coerce Virginia, or any citizen of
                  Virginia, to render allegiance and obedience to the Government of the United States, when she may, in the exercise of her sovereign capacity, have withdrawn that allegiance.
                </p>
                <p className="p-in-sp">
                  Without, at present, undertaking to discuss the propriety or impropriety of the act of the several States that have seceded from the Confederacy of the United States—for I desire, at the proper time, and under proper circumstances, to indicate my views fully and clearly upon this question—finding that these States have resorted to this extreme measure; finding that they are occupying
                  de facto, the position of sovereign and independent nations, and desiring, above all things, that the evils of a civil war may be averted ; and, believing that this is the desire, not only of this Convention, but of the sound, conservative sentiment of the whole country of the United States, I have no hesitancy in declaring it as my opinion that the Government of the United States
                  should, at the very earliest period consistent with the honor of the United States, acknowledge the independence of those States, and by one act remove every possible contingency upon which the conflict may unhappily result between the two sections of the country.
                </p>
                <p className="p-in-sp">It is for these reasons, Mr. President,' that I have deemed it proper, in my opinion, to submit the resolutions to the Convention.</p>
                <div className="speaker" id="sp181"><span className="persName">Mr. WISE</span>—</div>
                <p className="p-in-sp">
                  Ten years ago, I stood at this Capital, claiming for Western men the right of equal representation; and during an eighteen months siege for equal taxation, equal representation and the enlightment of the people, while that struggle was going on, I stood alone with four others from the East; was reproached and upbraided with <PageNumber num={156} /> the
                  idea that I was giving away the protection of slave property under a commission of power to the West. Permit me, sir, to say now, that that time has past, and I meet the West again in another Convention, and I cannot sit still and hear the response of the West at this day, without a gushing of the heart, which impels me to return thanks to the gentleman for the sentiments which he
                  has just uttered.
                </p>
                <div className="stage it">[Loud applause, and clapping of hands in the galleries.]</div>
                <div className="speaker" id="sp182"><span className="persName">Mr. MONTAGUE</span>, who was temporarily occupying the Chair, said :</div>
                <p className="p-in-sp">While I fully participate in the anxiety entertained by the public with reference to the important subject under discussion, I cannot, while acting as temporary Chairman of this body, permit such interruptions to its proceedings as these demonstrations on the gallery occasion. If they are repeated, I am determined to order the galleries to be cleared.</p>
                <div className="speaker" id="sp183"><span className="persName">Mr. WISE</span>—</div>
                <p className="p-in-sp">
                  I rise to say, that when I heard resolutions like these, and listened to the calm, deliberate, rational, grave, considerate manner with which my young friend, who is a stranger to me—he will not be in future—has addressed this Convention, I feel now that I did right to stand upon the right, and to leave my defence to the moral power of reason and of justice. I point out that
                  gentleman now, to those who reproached me, and say, that if he be a specimen of the children of equal rights whom I helped to create by the last Convention of Virginia, Virginia may well exclaim—Eastern as well as Western Virginia—all Virginia combined, united may proudly exclaim, ecce meos filios!
                </p>
                <div className="speaker" id="sp184"><span className="persName">Mr. NEBLETT</span> of Lunenburg—</div>
                <p className="p-in-sp">I will take this occasion to return to the gentleman [Mr. WOODS] 
                  my sincere thanks, and in the name of the constituency which I represent, for the eloquent and patrotic remarks which he has made, representing, as I do, a constituency intensely Southern in all their feelings, and in all their interests; who believe that the time has now come when it no longer comports with the dignity, nor the interests, nor the honor of Virginia to remain one day
                  longer a member of this Union.
                </p>
                <p className="p-in-sp">
                  We witnessed, yesterday, a most remarkable scene. If it be true that the army and the power of the Federal Government has been increased and strengthened in Virginia, why, let the fact be known. If its power has not been increased nor strengthened why not let that be known? I say, sir, emphatically, and with a desire and wish that it shall go to the country, for the purpose of
                  strengthening and intensifying the revolution which has grown up there within the last four years, that the guns at Fort Monroe have been turned inward ; that thirteen of them are now bearing upon the city of Hampton, and they can at <PageNumber num={157} /> any moment shell that town. I say, sir, emphatically that that fort is kept constantly filled
                  with water, and night after night does the sentinel give the signal.
                </p>
                <p className="p-in-sp">
                  These things, sir, are unnecessary; they are improper at a time of peace and quiet. But it is unnecessary for us to conceal the fact or to shut our ears to the inevitable conclusion that the Democracy of the North—and when. I say Democracy, I mean all the conservative element that is there—will be compelled to give way, and Virginia has nothing to do but forthwith to prepare herself
                  to meet the foe.
                </p>
                <p className="p-in-sp">Again, sir, I beg to return to the gentleman my thanks, and in the name of my constituents I thank him for the remarks that he has made. They will go forth to them, and they will receive them with great pleasure as the hope of a brighter day.</p>
                <div className="speaker" id="sp185"><span className="persName">Mr. CARLILE</span>—</div>
                <p className="p-in-sp">Is there anything before the Convention?</p>
                <div className="speaker" id="sp186"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">The resolutions of the gentleman from Barbour have not yet been referred to the Committee on Federal Relations.</p>
                <div className="speaker" id="sp187"><span className="persName">Mr. HALL</span>, of Wetzel—</div>
                <p className="p-in-sp">I beg leave to return to the gentleman from Princess Anne [<span className="persName">Mr. WISE</span>] , and the gentleman from Lunenburg [<span className="persName">Mr. NEBLETT</span>] , my thanks on the part of the North-west ; but the gentleman from Princess Anne [<span className="persName">Mr. WISE</span>] , will permit me to make a single remark in reference to the position of that section.</p>
                <p className="p-in-sp">
                  I am afraid that the North-West is not as sound as he thinks. I hail from the North-West, from the banks of the Ohio; my dwelling is within a few yards of the Ohio shore; my property is there; my interests are there; and let me inform the gentleman from Princess Anne that I am the only man in that region of country who was enabled to get a position in this body upon the Crittenden
                  proposition. Sir, I went alone before my people advocating this proposition as an amendment to the Federal Constitution as a necessary guarantee to the State of Virginia and her sister States of the South. They pronounced me a disunionist per se for disseminating this doctrine, and I am very much afraid, sir, that the gentleman from Princess Anne inadvertently and unwittingly,
                  perhaps, has contributed somewhat to that fatal state of affairs in that section of country by making use of the remark that he was going to fight in the Union. You cannot tell how that pleased the hearts of the people. Coming as it did from so distinguished a source, possessing the entire confidence of that people they took hold of it instantly, and they would now listen to no plan
                  or argument that would ultimately lead to a dissolution of the Union. I can, however, say this, that as to the county of Wetzel, there are no sounder State-rights people upon the face of the earth than are to be found there. We have but <PageNumber num={158} /> few slaves—we cannot keep them—the emissaries of the underground railroad are always upon the
                  alert, and the terminus of that road is at our very door. I heard a man say that he had been engineer of that road for twenty years, and that he thanked God that he had been the instrument of liberating many slaves.
                </p>
                <p className="p-in-sp">
                  Mr. President, we believe that a State has a right to secede. We believe, sir, that no State has ever yet surrendered her sovereignty. We believe in the county of Wetzel, that this was a confederacy of sovereignty, and that this Constitution was nothing more than the result of a treaty between the sovereignties—one undertaking to do a certain thing and an other to do certain things,
                  the obligations being mutual, and, I may say, conditional. We believe also that the whole commercial world is a confederation in one sense of the word, bound by treaties to the performance of certain duties to each other, reciprocal in their nature and character; that the whole commercial world has treaties and an organic law,<span className="note" id="Note25"
                  ><span className="noteLabel">2</span>The rest of this line, and the five lines following it, appear to be garbled.</span
                  >
                  and that this state of things, no man would have the presumption to pretend that because the United States of America or any other sovereignty, might enter into an agreement or treaty with other sovereignties, that then they are thereby giving up their sovereignty, and that they can never resume their delegated powers? Whether there is no stipulation of notice or not of a purpose to
                  resume these powers, I believe that it is a recognized rule of law that a party to a compact or treaty cannot at any time abrogate that treaty without giving due notice to the other parties of that purpose; I would be in favor of pursuing such a policy towards the North. We should give them notice, and say that the compact which we made with you has failed to accomplish what was
                  expressed upon its face as the object of forming it—to give peace, liberty and happiness—and we now give notice that we will wait a proper and sufficient time for you to come forward and say whether you will maintain the terms of the compact. Let me say this : that I am willing to co-operate with any gentleman on this floor as a North-western man, and to go before my people, whatever
                  may be the consequences, and unite in presenting an ultimatum that shall run to the first day of July ; and if Virginia has to go, let her go out and take the Constitution with her.
                </p>
                <div className="speaker" id="sp188"><span className="persName">Mr. WISE</span>—</div>
                <p className="p-in-sp">I wish, at this point of time, to say one word, and especially 'as my friend from Wetzel [<span className="persName">Mr. HALL</span>] 
                  has informed me of an opinion of his that gives me some pain—that by uttering the words that I intended to fight in the Union, I have caused some persons, perhaps, in the North-west to take the ground of submission to the <PageNumber num={159} /> wrongs which we are suffering. I stand where I have always stood. I mean to stand even in the last ditch for
                  the defense of the Union and the Constitution; and if it is ever dissolved, it shall be dissolved by war. (
                 [Applause.] 
                  And I want to say a word to the North-west, and to the South-west and the South-east, against this Federal doctrine, that it is treason to war in the Union against the usurpation, against the perversion of power in any form, whether by a State or by the federal government. I wish to show that this power to make defensive war in the Union is not an implied power—that it is not a
                  derived power—that it is not even one that proceeds merely from the nature of the government, but that it is an expressed power given to you in the Constitution of the United States.
                </p>
                <p className="p-in-sp">I am speaking in the language of the Constitution when I say that "no State shall, without the consent of Congress, lay any duty of tonnage, keep troops or ships of war in time of peace, enter into any agreement or compact with another State or with a foreign power, or engage in war unless actually invaded or in such imminent danger as will not admit of delay."</p>
                <p className="p-in-sp">
                  I declare, then, by the authority of the Constitution of the United States that whenever this sovereign State is ready for herself to declare that she is in such imminent danger as will not admit of delay, although she be not actually invaded by force of arms, if she be in such imminent danger as will not admit of delay, she has the express Constitutional power to engage in war
                  itself. She has the power to make a compact or agreement with any other State. She has the power to make a compact or agreement even with a foreign power. There is my authority
                 [referring to the Constitution] , and I ask gentlemen who are maintaining the Constitution to tell me how they will get over these express declarations. Yet I am constantly told by the knowing ones that they do not know what I mean by fighting in the Union; and this want of understanding is solely attributable to the fact that they will not even read the very Constitution of the country. [Laughter.] 
                  You have not, without the consent of Congress, the power to do this thing, unless you are in imminent danger. Are you in imminent danger now, or not? Upon warning to the world, that you feel the danger in such a manner as to compel you to go to war against oppression, having previously shown that your disposition, your purpose, was to defend the Union and the Constitution—tell me,
                  gentlemen, whether that course would tend to preserve peace, or to cause war? And tell me, farther, is it wiser to ask our people to give up the stakes in this Union, that, up to a short time past, has been blessed of God and man? Is it policy <PageNumber num={160} /> to wake up at six o'clock in the morning, and ask them to give up the stakes of
                  nationality, of treasury and of public lands, all at once, and to leave them to the enemy—who have proved themselves unworthy to hold this nationality and this treasury, this eminent domain? Is it policy to give up the name of Union, to give it to them, and to fix upon us who really mean to defend the Union, the cry of reproach of disunion. I tell you I mean to take the weather-gage
                  of them and if I must fight, I will have the stakes if I can, if I can win them by the battle. And if my friend had expressed himself thus, and had an understanding with his people that he would fight, in the Union, for the Constitution and the Union—for all the rights of Virginia under the Constitution and in the Union, with a firm determination to give up the Union only when, in
                  the Providence of God, it shall be inevitably dissolved by war—if my friend from Wetzel had taken this position, firmly and understandingly before his people, I apprehend that it would have strengthened, instead, as he supposes, of weakening his hands. I love peace, and I love Union. But rather than let this Union stagnate in the corruption of a revolution of decay until the very
                  green scum of stagnation shall offend the olfactories of every patriot, I would invoke the purification of fire and blood—those great purifiers of the Almighty Himself, from the redemption of the cross down to the countless instances of national crime expiated by the blood of an offending people. And if we must come to battle, and if there shall be found in North Western Virginia, or
                  any section of Western Virginia, unsound men, whose rights I have striven so hard to maintain, if they will not stand by my rights, and I must meet them in battle, I will bare to their spears the bosom which has defended them. I will fight on against all enemies, and especially, with all my might, against those who shall prove ingrates to a mother Commonwealth like this.
                </p>
                <div className="speaker" id="sp189"><span className="persName">Mr. WILLEY</span>—</div>
                <p className="p-in-sp">
                  I will avail myself, Mr. President, of the indulgence of the Convention, for a very few moments, while I attempt to disabuse the minds of many members, as I have been given to understand, of a very serious misapprehension of the public sentiment of the Northwestern section of this State. For some cause unknown to me, intimations and insinuations prejudicial to the character of that
                  section of country, for her loyalty to the institutions of Virginia, have been busily circulated among the members of the Convention. I was willing, for a while, to submit to a misapprehension which to some extent might be considered as natural, when it came alone from the Eastern borders of the State ; but when I hear a member of this Convention, upon this floor, giving out
                  intimations confirmatory of these suspicions, and <PageNumber num={161} /> going to credit the idea that there is want of loyalty in the Northwestern section of this State, to the institutions of Virginia, to all of our institutions, I cannot but violate the fixed resolution which I had formed in my mind when I came here, and ask the indulgence of this
                  Convention for a few moments, while I disabuse any mind which has been poisoned by any such insinuations.
                </p>
                <p className="p-in-sp">
                  Sir, there exists not within the broad limits of this great State any people more loyal to its interests than the people of the North-western part of the State—any people readier to defend her rights to the death. I speak especially for my own constituency, and I verily believe that I represent the universal sentiment of trans-Alleghany. But this seems to be an age of distrust and
                  suspicion. Guarantees are required on every hand, and it appears that Western gentlemen are asked for some guarantees for their fidelity to this glorious old Commonwealth. Why should these guarantees be asked? In what portion of our history can a single incident be pointed to that would subject us to the ban of your distrust? In what have we been derelict? In what have we been
                  faithless? When did we not come up to the full demands of justice to the East on all questions? Never; but, sir, we have a record upon this subject—a record written in blood. I stand here representing the sons of sires who fell in your defence in the war of 1812. The cry of your distress and for help had scarcely echoed back from our western mountains and died along the Eastern
                  shores of your coast, when the crack of the Western rifle was heard defending your firesides and your families defending that very property which you now make the object and subject of distrust of the Western heart. We have a glorious record. Your soil is consecrated with the memories of the loyalty of the West, because it contains the honored remains of some of her bravest and
                  noblest sons. Why, sir, your honor is her honor; your interest is her interest; your country is her country ; your faith shall be her faith; your destiny shall be her destiny.
                </p>
                <p className="p-in-sp">But, sir, it seems that we love the Union too well. That seems to be the measure of our offence. If it be treason to love the Union, we learned that treason from you, sir—we learned it from your great men</p>
                <p className="p-in-sp">
                  from your Jeffersons, from your Madisons, your Monroes, and from others of equally illustrious dead ; and we have learned it from the living, little less distinguished men who are recognized as leaders at the present day, and who need but the consecration of death to place their names on the same roll of immortality. Above all, we learned it from the Father of his Country, the
                  greatest of Virginia's sons—the greatest man that ever stood upon the tide of time. As I passed down by his <PageNumber num={162} /> monument the other day, and gazed upon it with the reverence with which every American heart must contemplate his memory, I could almost imagine that I heard falling from his sacred lips the admonitions he gave us in his
                  Farewell Address, bidding us beware of sectional dissensions, bidding us beware of geographical divisions, and instructing and conjuring us to regard the Union as the palladium of our liberties; to look with distrust upon any man that would teach us any-other doctrine. As I passed further down and gazed upon the beautiful statue of the immortal statesman of Kentucky, there came
                  rising up in my memory the recollection of his almost dying words, when he went home after the memorable contest of the Compromise of 1850. When at Frankfort, in the last speech he ever uttered, he said these words :
                </p>
                <p className="p-in-sp">"Mr. President, I may be asked, as I have often been asked, when I would consent to the dissolution of the Union?"—and I could almost imagine that I heard falling from his lips, "Never!" "Never!"</p>
                <p className="p-in-sp">
                  And, sir, there lingers in the Western heart, especially of the Democratic constituency which I have the honor to represent, that sentiment uttered or written by Mr. Calhoun, in 1832, to General Hamilton, when he said : "The institution of the Union was so wisely ordered for the redress of grievances and for the correction of all evils, that he who would seek a remedy for this
                  disease in dissolution would merit and receive the execration of this and all future generations." That sentiment lingers there yet. You will forgive us if we cannot forget these great lessons of these great men in a moment. But I tell the distinguished gentleman from Princess Anne,
                 [<span className="persName">Mr. WISE</span>] 
                  , that while I do not understand altogether what he meant by fighting in the Union, the West, who still remembers him with gratitude for his services in the Convention of 1851, will rally to his support, or to the support of any other man in any fair contest, for the redress of any just grievances. When the last resort must come, when the proper appeal to the law and to the
                  Constitution has failed to redress the grievances of the East, when her oppressions are intolerable, I tell you the Northwest will send you ten thousand men, with hearts as brave and arms as strong as ever bore the banner of freemen; and they will rally to her support, and seize by violence, if you see proper to call it so, or rescue by revolution, what we could not get by means of
                  law. We are with the gentleman from Princess Anne in that regard. We do not stand upon nice distinctions. We do not always understand what is meant by the right of secession—we do not understand what is meant by the right of <PageNumber num={163} /> revolution; but when the proper cause arises, there are men in Western Virginia who will stand by the
                  right to the last extremity.
                </p>
                <p className="p-in-sp">
                  I have been betrayed by the impulse of the moment to claim your attention much longer than I intended when I rose to address you; but, impelled by the kind indulgence of the Convention, I could not repress the desire to repel any insinuation against the loyalty of the citizens of Western Virginia, or to allow, on the other hand, any wrong impression to be made upon the minds of this
                  Convention, that we are going to yield up our glorious Union for naught.
                </p>
                <div className="speaker" id="sp190"><span className="persName">Mr. GOODE</span>, of Mecklenburg—</div>
                <p className="p-in-sp">
                  The gentleman from Monongalia has told us that they of the West had learned their loyalty and devotion to the Union from Jefferson, Madison, and other illustrious men of the East. I beg, sir, to remind the gentleman that the Union, formed in part by the wisdom of those men, and for the preservation of which they so earnestly labored, was a constitutional Union, designed to secure
                  equality and justice to every section of our great country. A Union which is of power to accomplish these ends, we of the East are now prepared to defend to the death. But, sir, we have seen the doctrine of the higher law triumph over the Constitution of the country; we have seen the most solemn constitutional guarantees, designed for our protection, trampled beneath the unhallowed
                  feet of the Republican party. And now, sir, without some speedy manifestation of a returning sense of justice and fraternal feeling on the part of our assailants, we are prepared to snap asunder the bonds that have made us one people.
                </p>
                <p>The resolutions were then referred to the committee on Federal Relations.</p>
              </div>
              <div className="section" id="vsc1965.v1.2.8.2">
                <h3><span className="headingNumber">1.8.2. </span><span className="head">ADJOURNMENT</span></h3>
                <div className="speaker" id="sp191"><span className="persName">Mr. CARLILE</span>—</div>
                <p className="p-in-sp">
                  It is understood, I believe, that the Convention granted to the Committee on Federal Relations the right to sit during its sessions. I presume it is not expected by any member of this body that we shall enter upon any elaborate discussion until after that Committee has reported to this body. I therefore move that when this Convention adjourn, it adjourn to meet on Monday next.
                </p>
                <p className="p-in-sp">I will add, that tomorrow is the 22nd of February, and there are some of us, perhaps, who would like to read the Farewell Address of the Father of his Country, and the next day being Saturday, I trust that the resolution will meet the approbation of the Convention.</p>
                <div className="speaker" id="sp192"><span className="persName">Mr. FISHER</span>—</div>
                <p className="p-in-sp">I move to amend the resolution of the gentleman, by naming Saturday instead of Monday.</p>
                <div className="speaker" id="sp193"><span className="persName">Mr. CARLILE</span>—</div>
                <p className="p-in-sp">
                  We heard a statement this morning, from the Committee <PageNumber num={164} /> on Federal relations, that must have satisfied every member of this body, that it will be utterly impossible for the committee to make any report on Saturday next. I presume that no action can be taken by this Convention to hasten the object of its assembling, until we
                  receive the report of the committee. I trust that the amendment will be voted down. There are some of us here who contracted very severe colds in coming here from our homes. I am, myself, suffering now from an attack of acute rheumatism, and it is with very great difficulty that I can get from my lodgings to this Hall. If allowed a day or two to take care of myself, I might find
                  myself relieved of my pain by Monday next.
                </p>
                <div className="speaker" id="sp194"><span className="persName">Mr. FISHER</span>—</div>
                <p className="p-in-sp">
                  I do not understand that the Chairman of the committee on Federal Relations has requested or desired that this body shall adjourn over until Monday. If the gentleman from Harrison is too unwell to attend on Saturday, I have no doubt that this body, however much it might regret his absence, would still be enabled to discharge the duties which may be devolved upon it, in consequence of
                  matters other than those which are to spring out of the action of the committee on Federal Relations. I think it suggests itself to every gentleman's mind, that there may be matters to be acted upon on Saturday, even if the Committee on Federal Relations shall not report before Monday. We know that there are other resolutions which have been adopted by the Convention, and which, as a
                  necessary consequence, call for its action. To protect the interests of our Commonwealth it is incumbent upon us to be in constant session now, for we know not what a day may bring forth. At all events we ought not to be running over the city of Richmond, when we ought to be engaged in the public business.
                </p>
                <div className="speaker" id="sp195"><span className="persName">Mr. HALL</span>, of Wetzel—</div>
                <p className="p-in-sp">
                  I am opposed to adjourning over at all. We have a Peace Conference sitting over at the Federal Capitol which is now deliberating upon the affairs of the country, and we have been delaying and waiting to hear from our Commissioners. I understand from madam Rumor, or from the best information we have, that they will be here tomorrow, and perhaps this afternoon. We cannot tell what
                  advices they will bring, and what action we may have to take. We may be compelled to act promptly and immediately, and if our Commissioners, among whom are two members of this body, Ex-President Tyler and Mr. Summers, should return here today or tomorrow, we ought to be in session to receive any communication they may make. Let us not go straggling about the streets of the city of
                  Richmond.
                </p>
                <div className="speaker" id="sp196"><span className="persName">Mr. DORMAN</span>—</div>
                <p className="p-in-sp">I will simply say that the resolution offered by the gentleman from Pittsylvania [<span className="persName">Mr. TREDWAY</span>] 
                  , yesterday, was, upon my <PageNumber num={165} /> motion, laid upon the table. That motion referred to the movements of arms, &amp;c., to the forts and arsenals of Virginia; and the resolution was laid upon the table, upon my motion, in consequence of the absence of one of the delegates from Jefferson. As that gentleman is not present today, upon
                  consultation with the gentleman from Pittsylvania, he has kindly consented to wait the presence of the delegate from Jefferson, before calling up his resolution. That gentleman will not be here in time for our action today, but he will be here in time for action on Saturday. I am entirely willing that the Convention shall adjourn over till Saturday in honor of the birthday of the
                  Father of his country. Farther than that, I have no desire for a postponement. I trust that my friend from Harrison
                 [<span className="persName">Mr. CARLILE</span>] , will see proper to withdraw his resolution.</p>
                <p>The question was then taken on <span className="persName">Mr. FISHER</span>'S amendment, and it was agreed to.</p>
                <p>The resolution as amended was then agreed to.</p>
                <p>The Convention then, on motion, adjourned to meet on Saturday next.</p>
              </div>
            </div>
            <div className="day" id="vsc1965.v1.2.9">
              <PageNumber num={166} />
              <h2><span className="headingNumber">1.9. </span><span className="head">NINTH DAY</span></h2>
              <div className="dateline">Saturday, <span className="date">February 23</span></div>
              <p>The Convention met at 12 o'clock, M., and was called to order by the President.</p>
              <p>Prayer by Rev. <span className="persName">Mr. WM. B. NOLLY</span>, of the Methodist Church.</p>
              <div className="section" id="vsc1965.v1.2.9.1">
                <h3><span className="headingNumber">1.9.1. </span><span className="head">PERSONAL EXPLANATIONS</span></h3>
                <div className="speaker" id="sp197"><span className="persName">Mr. HALL</span>, of Wetzel—</div>
                <p className="p-in-sp">
                  If it be in order, I rise this morning for the purpose of making a personal explanation, in consequence of the various newspaper comments and the criticism that has been indulged in on the part of some gentlemen in reference to my remarks day before yesterday, as to the true position of the Northwest in the important controversy now going on. I see from the newspaper accounts of the
                  matter, and I also understand from certain remarks, in which gentlemen from the Northwest have indulged in, that I have been represented as saying already, or as conveying the idea, that the whole Northwest was unsound—in other words, that they did not represent the sentiment of Virginia, and that they were not loyal to the Commonwealth of Virginia.
                </p>
                <p className="p-in-sp">
                  Now, Mr. President, in vindication of myself and also in vindication of the Northwest,<span className="note" id="Note26"><span className="noteLabel">1</span>Some such phrase as "I would explain" must have been omitted here, and the word "to" omitted after "remarks."</span> that I did not intend in my remarks include the whole Northwest, or, indeed, any considerable portion of the Northwest.
                  I intended to convey the idea that, unfortunately, there was a portion of the Northwest that was unsound, and although I did not express myself or direct my remarks as particularly as I ought to have done, I intended to refer to some two or three thousand Lincoln votes in the Northwest. I also intended to convey the idea that a portion of the delegation from that section was elected
                  upon the platform as laid down by the organ of the Republican party in the State of Virginia, to wit : Mr. Campbell, the Editor of the "Wheeling Intelligencer," who is now reporting upon this floor— a paper that was an out and out Lincoln paper, which advocated his election and denounced slavery as a sin.
                </p>
                <p className="p-in-sp">
                  I say, sir, that there are men here today standing upon the platform and resolutions endorsed by a meeting in the city of Wheeling, and advocated by Mr. Campbell of the Wheeling "Intelligencer," and I will name <span className="persName">Mr. CLEMENS</span> and <span className="persName">Mr. HUBBARD</span> of the Northwest.<span className="note" id="Note27"
                  ><span className="noteLabel">2</span>This assertion by Mr. Hall, and Mr. Clemens' reply, were corrected by Mr. Clemens in the Proceedings of February 25, pages 191-192.</span
                  >
                </p>
                <div className="speaker" id="sp198"><span className="persName">Mr. CLEMENS</span>—</div>
                <p className="p-in-sp">Will the gentleman allow me to ask him a question?</p>
                <div className="speaker" id="sp199"><span className="persName">Mr. HALL</span>—</div>
                <p className="p-in-sp">Certainly.</p>
                <PageNumber num={167} />
                <div className="speaker" id="sp200"><span className="persName">Mr. CLEMENS</span>—</div>
                <p className="p-in-sp">Do I understand him correctly as saying that I stood upon a platform which was advocated by the editor of the Wheeling "Intelligencer?"</p>
                <div className="speaker" id="sp201"><span className="persName">Mr. HALL</span>—</div>
                <p className="p-in-sp">You understand me to say this—</p>
                <div className="speaker" id="sp202"><span className="persName">Mr. CLEMENS</span>—</div>
                <p className="p-in-sp">I desire a categorical answer to this question. Did you say a moment since, that I, as a member of this Convention, stood upon a platform which was advocated by the editor of the Wheeling "Intelligencer"?</p>
                <div className="speaker" id="sp203"><span className="persName">Mr. HALL</span>—</div>
                <p className="p-in-sp">I say this, that the editor of the Wheeling "Intelligencer" laid down the programme, and that he advocated the platform upon which you run.</p>
                <div className="speaker" id="sp204"><span className="persName">Mr. CLEMENS</span>—</div>
                <p className="p-in-sp">I submit again the question to the gentleman from Wetzel, and I ask him for a categorical answer to the question whether he said a few moments since that I, a delegate from the county of Ohio, stood upon a platform which was advocated by the editor of the Wheeling "Intelligencer."</p>
                <div className="speaker" id="sp205"><span className="persName">Mr. HALL</span>—</div>
                <p className="p-in-sp">I will qualify it in this way, that you run upon a platform which was advocated and defended by the editor of the Wheeling "Intelligencer."</p>
                <div className="speaker" id="sp206"><span className="persName">Mr. CLEMENS</span>—</div>
                <p className="p-in-sp">That is not an answer to the question.</p>
                <div className="speaker" id="sp207"><span className="persName">Mr. HALL </span>—</div>
                <p className="p-in-sp">You can get no more from me.</p>
                <div className="speaker" id="sp208"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">The present occupant of the Chair was not in the Hall when the remarks of the gentleman from Wetzel [<span className="persName">Mr. HALL</span>] 
                  were made the other day. The Chair does understand, however, the rule to be that when a gentleman rises to a personal explanation of remarks, either made by him or imputed to him in the Convention, that he has to confine himself either to a denial or explanation of those remarks; but this is not the time or the occasion for entering into a controversy, as to the consistency of
                  gentlemen before they came here.
                </p>
                <div className="speaker" id="sp209"><span className="persName">Mr. HALL</span>—</div>
                <p className="p-in-sp">
                  I did not seek or desire to do so. I will state now, in vindication of myself and as explanatory of the views I intended to convey day before yesterday, that in the first place I have been denounced and held up to public indignation as not representing the wishes of my constituents and especially the North-west. I acknowledge that I told the gentleman who interrogated me upon this
                  matter that I was afraid that I did not represent the people of the North-west and that I was very much afraid that the gentleman himself did not represent a majority of his people. That is what I stated. I have no disposition to misrepresent the North-west. I have no disposition to quarrel with any gentleman upon this floor. I have no doubt that gentlemen' who represent the
                  North-west represent the sentiments of the <PageNumber num={168} /> majority of the people. That is the very thing of which I complain—not that they are doing anything wrong particularly. It is better that the truth should be known; it is better for this Convention to know the real state of affairs in reference to this matter. It is an important
                  question to this Convention, and it is also important that Virginia should be united. It is important that there should be but one sentiment in the State of Virginia. And let me tell you, Mr. President and gentlemen of this Convention, that there are dragon's teeth sowing in our section, and when Virginia shall take the decided position which she may be compelled to take, and when
                  civil war, neighborhood difficulties and social intercourse will have broken out in my section—
                </p>
                <div className="speaker" id="sp210"><span className="persName">Mr. CARLILE</span>—</div>
                <p className="p-in-sp">I rise to a question of order. I am not aware that there is anything before the Convention this morning.</p>
                <div className="speaker" id="sp211"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">The gentleman from Wetzel, [<span className="persName">Mr. HALL</span>] , rose to a personal explanation, but the Chair is decidedly of the opinion that the line of remark in which he is indulging is not now in order.</p>
                <div className="speaker" id="sp212"><span className="persName">Mr. HALL</span>—</div>
                <p className="p-in-sp">I will not, then, pursue that line of remark any farther, but ask the Clerk to read the following :</p>
                <p>The Clerk then read as follows :</p>
                <div className="stage it">[Special despatch to the Intelligencer]</div>
                <div className="floatingText">
                  <div className="floatingText_body">
                    <p>"RICHMOND, Feb. 20, 1861</p>
                    <p>"Great indignation prevails here among North-western members on account of the course pursued by Leonard S. Hall, delegate from Wetzel county. He openly denounced his colleague last night, in the parlor of the Spotswood House, as a submissionist, and read a letter from Mr. Charles W. Russell, of Wheeling, in support of his views.</p>
                    <p>
                      "The gallant old Gen. John Jackson, of Wood county, repudiated Hall and his letter before a large crowd, showing that Hall did not represent Wetzel county, much less the North-west—having been elected only by a meagre plurality, and that through a division of the Union men of his county. The letter of Mr. Russell to Hall is reprobated by Messrs. Willey, Clemens, Jackson, Burley,
                      and all others from the West whom I have heard speak of the affair. C."
                    </p>
                  </div>
                </div>
                <div className="speaker" id="sp213"><span className="persName">Mr. HALL </span>—</div>
                <p className="p-in-sp">
                  As to the facts stated in the despatch which has been read, the gentleman who sent it has labored under a great mistake. By permission of the Convention, I will read Mr. Charles W. Russell's letter to myself, or at least that portion of it which relates to the subject matter in controversy. The letter is dated at Wheeling, February 14th, 1861:
                  <PageNumber num={169} /> "Too many of our delegates, I fear, are prone to make fatal sacrifices for an object which they cannot attain. I cordially sympathize in that attachment for the Union established by our fathers, which no doubt pervades the Convention. I trust, however, that our representatives will not surrender the Constitutional safeguards
                  established by our fathers to protect the liberties of themselves and their posterity. This is my solicitude. The older I grow the more I cherish liberty as the chief of political blessings and the more jealous I become of all arbitrary power. I am not willing that the people of Virginia shall be subject to any power not rigidly curbed by a Constitution faithfully observed. I have
                  exerted myself for years to save the Union by maintaining equality and Constitutional right among the States. I would labor to restore it in the same way. But I have no sympathy with those who are content to leave Virginia under the domination of unfriendly States which have trampled upon the Constitution, without taking new and sufficient guaranties of her rights."
                </p>
                <p className="p-in-sp">That is the sum total of the letter referred to in that despatch. I wish to say but a word further in the way of explanation and in answer to the distinguished gentleman from Monongalia, [<span className="persName">Mr. WILLEY</span>] , day before yesterday. If I understood his position, and I think he represents, perhaps the views of a majority of the people of the county he represents, he certainly intended to convey the idea that he never, no never, under any circumstances, would sign an ordinance of secession, or something to that effect. He never would give up the Union.</p>
                <p className="p-in-sp">Now, sir, in further justification of my position, I will state this : that to give the North the Declaration of Independence with the explanation—</p>
                <div className="speaker" id="sp214"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">The Chair would suggest to the gentleman that this line of remark is, in the opinion of the Chair, not in order.</p>
                <div className="speaker" id="sp215"><span className="persName">Mr. HALL</span>—</div>
                <p className="p-in-sp">I was merely going to place myself correctly before the Convention and the people of my section in reference to what I conceive to be the true position of affairs.</p>
                <div className="speaker" id="sp216"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">When a gentleman proposes to read a paper, the Chair takes it for granted that it has some connection with the subject that is legitimately before the body. The Chair could not tell, until the letter of Mr. Russell was finished, what was contained in it, and the Chair is of the opinion that it has no connection with the explanation of the gentleman from Wetzel [<span className="persName">Mr. HALL</span>] .</p>
                <div className="speaker" id="sp217"><span className="persName">Mr. MONTAGUE</span>—</div>
                <p className="p-in-sp">I offer the following resolution. It is a resolution which merely calls for information, which, if obtained, may be useful <PageNumber num={170} /> during the progress of the Convention.</p>
                <p>The resolution was then read as follows :</p>
                <p>Resolved, That the several railroad companies in this State be requested to report to the Convention, as soon as practicable, the number of negroes carried over their roads en route for any Southern State within the years 1855 to 1861, inclusive.</p>
                <p>The resolution was agreed to.</p>
                <div className="speaker" id="sp218"><span className="persName">Mr. CLEMENS</span></div>
                <p className="p-in-sp">I desire to make an explanation. There is only one consideration which induces me to say a single word in reply to what has been said by the gentleman from Wetzel [<span className="persName">Mr. HALL</span>] 
                  . I am here representing a portion of this Commonwealth. Insinuations and imputations have been insidiously made, both privately and publicly against me, as a member of this Convention, the effect of which is apparent every moment, in the faces, the sentiments and the hearts of those who are the sovereigns in this section of the State. I should be wanting in respect to them, I should
                  be wanting in respect to myself, I should be wanting in respect to my people, if I should hesitate for one instant to put the heel of my irrepressible contempt upon every imputation, come from what source it may, made at what time it may, which impugns my loyalty to the interests of this great commonwealth. And I challenge here publicly, any man, I care not who he is, to take my
                  public record during six years of service in Congress, and point to a single vote or a single word ever uttered by me, in public or private, which will not accord even with the antagonistic hearts now beating around me. My speech, made in the face of the people, has been sent over this State broadcast; it is in the hands of the people. Let any gentleman who has denounced that speech,
                  as the gentleman from Middlesex
                 [<span className="persName">Mr. MONTAGUE</span>] did the other day, as incendiary matter, and who confessed afterwards that he had never read it, take that speech and point to one single passage which will not commend itself to the nicest extremist upon this floor.</p>
                <p className="p-in-sp">Sir, we live in times of public peril and public disaster. And, so far as I am concerned, as a citizen of this State, I desire to maintain, even against the contending sections of my own Commonwealth, that potential, power which *a, public man ought always to retain in his own hands. Now, sir, I do not desire to say anything unkind to the gentle man from Wetzel [<span className="persName">Mr. HALL</span>] 
                  . I can afford to be magnanimous to him. I desire simply to say, that when he declared upon this floor that I was elected upon a platform advocated by the editor of the Wheeling "Intelligencer," or any other man, he declared, ignorantly, I presume—maliciously, I will not presume—an opinion upon the matter <PageNumber num={171} /> upon which, at least,
                  he lacked information most miserably. I do not desire to say anything unkind in reply; I will not do it. I have here in my heart a consciousness of right. I am responsible to God ; I am responsible to my wife and to my children, who live here and who were born in the extreme South, and I would save them, if I could here by my action, as I have endeavored in Congress to save them from
                  the red and reeking hand, from the midnight torch, from the dagger of the assassin, and from ruined, ruined property. That is my position. I stood before my people upon no platform erected for me by others. I advocated no sentiment that did not spring spontaneously, honestly and conscientiously from my own bosom. I stood upon my own platform. I met in the face of the people the
                  fiercest persecution ever waged against mortal man, and I triumphed, triumphed gloriously. Do you tell me that, with my public record in Congress, that with my printed words upon the history of the country, and with these words, in the hands of nearly every citizen of Virginia, I could be capable of so far degrading myself as to give up one single sentiment of allegiance, one single
                  sentiment of loyalty that is due to this Commonwealth? If such be the case ; if any of this vast concourse, whose eyes now beam upon me, have been hitherto laboring under that conviction or that impression, I will leave my vindication to time—time the comforter, time the soother, time that brings back, in the end, to all those who can afford to be just while they can be generous, the
                  reward which springs from a consciousness of right, and which always carries a perennial blessing with it.
                </p>
                <div className="speaker" id="sp219"><span className="persName">Mr. MONTAGUE</span>—</div>
                <p className="p-in-sp">I rise to a personal explanation. It seems that it is the general sense of this Convention, that they came here to make personal explanations, and nothing else. The gentleman who has just taken his seat [<span className="persName">Mr. CLEMENS</span>] 
                  has put me before this Convention and this Commonwealth, in an improper position. He said that I designated, the other day, his speech in Congress an incendiary document, while at the same time I declared I never read it. You will remember, sir, and the Convention will remember, no doubt, very clearly, the circumstances under which I was led first to refer to this matter, and the
                  explanation which I then made and which I shall now repeat. It is this : that if the General Government had been guilty of a violation of the laws in sending documents of any sort to free negroes in this Commonwealth, as we are justified in inferring from the denial of the gentleman of any agency in the matter on his part; if they have done it for the purpose of stirring up strife in
                  this State, it is an additional reason why Virginia should withdraw from the Union. A gentleman <PageNumber num={172} /> came to me and said, that perhaps I did the gentleman, whose speeches I referred to, injustice, by characterizing them incendiary documents. I immediately explained that I did not mean to say that these documents were incendiary, for,
                  as I said, I never read them; but the point I wanted to make was, that if the Government was to engage in the work of disseminating documents to free negroes in the State of Virginia, for the purpose of inciting that population to strife and mischief, the sooner we separated from it the better. I presented that fact as an additional cause for our withdrawing from the Union. That was
                  what I said. I do not want that it shall go out to the country that I denounced the documents referred to as incendiary. I have never read them. If it satisfies the gentleman, I will state to him that I have never read his speech nor any speech he ever made.
                </p>
                <div className="speaker" id="sp220"><span className="persName">Mr. HUBBARD</span>—</div>
                <p className="p-in-sp">
                  Since my name has been mentioned by the gentleman from Wetzel, in connection with this matter, I deem it due to myself to deny that I was ever elected upon a platform erected for me by the editor of the Wheeling Intelligencer. I have been elected upon my own platform—a platform upon which I have stood all my life. I will now read from a card which I published previous to my election,
                  and which embodies the sentiments which I then held and now hold :
                </p>
                <p className="p-in-sp">
                  "Concerning the present political state of the country, all must admit there is great reason for anxiety, and much ground for difference of opinion, and each one may well ask, "who is sufficient for these things?" I realize that in the present condition of affairs much will depend on the course Virginia shall adopt. If she be found faithful—and who can doubt it?—to herself and the
                  Union, all may be saved. If she wavers and turns her back on the work of her own hands, all is lost, and the dial of human progress goes backward, and the hopes of humanity are blasted for untold ages.
                </p>
                <p className="p-in-sp">* * * * * * *</p>
                <p className="p-in-sp">
                  "Nor can I conceive that loyalty to the Union is want of fealty to Virginia. I should despise myself and count myself unworthy to be numbered among her-sons, which has ever been my pride and my boast, if I did not feel that every pulsation and instinct of my nature beat warmly and undividedly for the welfare of our good old Commonwealth; and while I would not relinquish a single
                  fraction of her rights, I unhesitatingly believe that every interest and every right can better be secured and maintained in the Union than out; and that disunion, so far from being a remedy for any evil, is the Pandora's box of all evils."
                </p>
                <p className="p-in-sp">
                  I take occasion further to say, sir, that I was elected to a seat here <PageNumber num={173} /> as a Virginian—as a Virginian ready to maintain her interests throughout all her parts, from the Chesapeake to the Ohio on both sides of James river and on both sides of the Shenandoah. I have been elected as one who has an inheritance in all Virginia, and
                  one who never intended to have any other inheritance.
                </p>
              </div>
              <div className="section" id="vsc1965.v1.2.9.2">
                <h3><span className="headingNumber">1.9.2. </span><span className="head">FORTS AND ARSENALS IN VIRGINIA</span></h3>
                <div className="speaker" id="sp221"><span className="persName">Mr. TREDWAY</span>—</div>
                <p className="p-in-sp">I hope we have got through with personal explanations. I fear that we shall become as famous for personal explanations as we have been for the election of officers. I move now, sir, to take up the resolution offered by me on Wednesday last, and laid on the table at that time.</p>
                <p>The resolution was taken up. It reads as follows :</p>
                <p>Resolved, That a select committee of five be appointed with instructions to enquire and report as speedily as practicable, whether any movement of arms or men has been made by the General Government to any fort or arsenal in or bordering upon Virginia, indicating a preparation for attack or coercion.</p>
                <div className="speaker" id="sp222"><span className="persName">Mr. A. M. BARBOUR</span>—</div>
                <p className="p-in-sp">I am sure, sir, that this body of Virginia gentlemen will recognize the delicacy of my position upon a question like this. But, sir, I want it known to all Virginia, that upon this floor, although occupying a Federal office, I represent the sovereign people of Virginia.</p>
                <p className="p-in-sp">I must return my thanks to this Convention for the kindness they did me in my absence, to lay that resolution upon the table, and shall also express to them my regret that my absence prevented the appointment of the Committee, which the resolution proposed.</p>
                <p className="p-in-sp">
                  That resolution meets with my cordial approbation. I want all these facts brought out. Let the truth come instead of flying rumors and telegraphs in sensation papers from sensation people. When the time comes—when that Committee reports here, I pledge myself before the whole people of Virginia, that every soldier and every piece of ammunition sent to the armory at Harper's Ferry sent
                  there under my suggestions, from considerations of the highest obligations of duty—duty to the State of Virginia and duty to the Federal Government, will be made known ; and, gentlemen, it will be to me, as a Virginian, a source of sincere consolation, if the sequel of that investigation does not become to the people of Virginia a subject of regret.
                </p>
                <p className="p-in-sp">I have nothing more to say, except to repeat my thanks to those gentlemen who had this resolution laid upon the table in order to give me an opportunity to be heard.</p>
                <PageNumber num={174} />
                <div className="speaker" id="sp223"><span className="persName">Mr. BORST</span>—</div>
                <p className="p-in-sp">Mr. President, I wish to say one or two words upon this subject.</p>
                <p className="p-in-sp">
                  I concur with the gentleman who has just taken his seat, that this Committee ought to be appointed for two reasons. One is that if these rumors in reference to the movements of arms and men, referred to in the resolution, be false, the good people of Virginia ought to be made acquainted with the fact. But if, perchance, it may be true, is it not equally important that this
                  Convention, that the people of this city, that the people of the Commonwealth should know the fact?
                </p>
                <p className="p-in-sp">
                  What is sought to be inquired into? Whether or not the Federal Government is reinforcing our forts and arsenals, and whether it is taking possession of our munitions of war which have been placed within the Commonwealth for the purpose, as we are led to believe, of State defence, to subjugate and overawe our people. I care not what the pretext of the General Government may have been
                  in placing these munitions within our territory—whether of preserving the peace in the neighborhood where these munitions have been deposited, or for any other purpose. I think it will be ascertained, upon investigation, that these warlike movements have been made for the purpose of overawing Virginia and intimidating her action. If not, perhaps, it will be found to have been done
                  for another purpose, and that is, if this Convention should determine during its deliberations here that it is right and proper to pass an Ordinance of Secession in order to secure the rights of Virginia and to maintain her interests, that then the Federal Government will be in a position to coerce her. Let me here say to this Convention, whose members are, no doubt, familiar with
                  the Trojan war, that the Old Dominion may find herself in the attitude in which ancient Troy was placed by the wooden horse of the Greeks. May not these fortifications prove to Virginia, by and by, what the wooden horse proved to Troy? It is well that our people should take warning by these suspicious movements of the Federal Government. I will say for my people that they are utterly
                  opposed to coercion; that they are opposed at this time to any reinforcement of the forts or arsenals by the Federal Government; and I believe it is the sentiment of Virginia, that if the Federal Government shall fill its wooden horses within her limits with arms and munitions of war for the ultimate destruction of her people, that the voice of this Convention should be heard issuing
                  orders to seize the wooden monster and hurl it from the State.
                </p>
                <p className="p-in-sp">
                  Let me here say that the constituency of the little county of Page are in favor of a Union of equality, a Union of peace, and a Union of strict <PageNumber num={175} /> justice to all sections. These were the principles which first formed the basis of Union. The voice of that people shall ever be heard in support of such a Union. They will always favor
                  such a Union as our fathers gave us, and will go as far as any other people to prop the pillars of that Union and key the arch. But let me say this, that they are opposed to a Union that will not give equality. They will never support a Union that will not furnish protection to all. They will never defend a Union that has no spirit, no vitality. When this Union lapses into a mere
                  carcass without a soul, they will unite only to execrate and condemn it.
                </p>
                <p className="p-in-sp">With these remarks I will express a hope that the Convention will adopt this resolution, that we may ascertain the true condition of the country. If we are to be menaced; if we are to be coerced; if the General Government contemplates a policy of subjugation, I hope and trust the fact will be made known.</p>
                <div className="speaker" id="sp224"><span className="persName">Mr. A. M. BARBOUR</span></div>
                <p className="p-in-sp">
                  I have no disposition to have myself paraded in the local papers in connection with this or any other matter. I do not intend to discuss the question of Union, but I mean to say, that if Virginia gentlemen, who are in favor of going out of the Union, are afraid of fifty blue-coated men, they had better stay in. We shall find that the result of this conflict will be, that the men who
                  go out last will be the first to fight for you. I desire to say, that I want Virginia to stay in the Union, if she can do so upon terms of honor; and if not, I want her to go out and appeal to the God of battles. I do say this, also, as a Virginian, that if you get me out, I will cut this head off before I come back. I am not in favor of dodging old Virginia out of the Union one day,
                  to get her in another. I want to stay in the Union upon terms that are satisfactory to all our people, and when we cannot stay in upon such terms, to go out; and if you go out, then there is no power on the face of this earth that can bring back the brave and noble people of the Commonwealth of Virginia.
                </p>
                <div className="speaker" id="sp225"><span className="persName">Mr. EARLY</span>—</div>
                <p className="p-in-sp">I am sorry to detain the Convention for one moment, but I feel bound to make a remark or two with regard to this resolution.</p>
                <p className="p-in-sp">I think we can all understand the motives that actuated the gentleman from Jefferson, [<span className="persName">Mr. A. M. BARBOUR</span>] 
                  in the call that he made. A Virginia gentleman and a Federal officer, in charge of an arsenal belonging to the United States, when lawless designs were initiated in some of the States, was put in a position that required for the protection of his honor that he should not be charged with colluding at any <PageNumber num={176} /> unlawful attempt upon the
                  arsenal in his charge. I think there can be no misapprehension as to the motives which actuated him.
                </p>
                <p className="p-in-sp">Sir, the gentleman from Lunenburg [<span className="persName">Mr. NEBLETT</span>] 
                  stated on Thursday last that a most remarkable spectacle was presented in this Commonwealth. He remarked that there were thirteen guns directed upon the town of Hampton, that water had been let into the moat that surrounds Fortress Monroe, that the sentinel made his nightly rounds and gave the signal at every hour, that these were portentous signs, not appropriate for times of peace,
                  and yet we find persons here who are not disposed to let these facts go before the country.
                </p>
                <p className="p-in-sp">As far back as 1837, the water was in the moat which surrounds Fortress Monroe, and I have fished in it. The water has been there for upwards of thirty years. If it is obstructed at any time it is because there is a large barrier to prevent the water in time of storms from being driven into the moat.</p>
                <p className="p-in-sp">
                  I have been at Fort Monroe on several occasions, when we had sentinels to parade their nightly rounds. We had them there principally to prevent some of the men who were a little inclined to spree, from getting out. If the sentinels were required to cry the hour of the night, it was because they might be inclined to sleep, and to prevent their being frozen in extremely cold weather.
                  It was also done to prevent them from leaving their posts. As to the thirteen guns, I do not know whether there are that number bearing upon Hampton or not; but I do know this, that never, when I had been there, was the armament of the Fort completed. It has been going on for years. A great number of guns have been in the casemates, and some en barbette pointing over the parapets,
                  but I do not think the armament has been completed to this day. You will find, by reference to the appropriation bills passed from time to time by Congress, that provisions have been made for the armament of Fort Monroe and other forts; and I understand that there has been a recent invention of iron carriages which have been substituted for the wooden ones, and that some changes are
                  going on in mounting the guns at Fortress Monroe on that account. I know that for years there has been a large establishment there in which munitions have been prepared, and in which gun-carriages have been manufactured. I know that it was, for a long time, in charge of a distinguished citizen of South Carolina, Colonel Huger, and afterwards under charge of a citizen of Virginia,
                  Captain Lee, now Colonel Lee. I understand it is now under charge of an old classmate and friend of mine, Captain Dyer of the Ordnance Department. I understand there are not more than one hundred and seventy-five guns mounted at that Fortress <PageNumber num={177} /> en barbette. One hundred and twenty-five of these bear upon the water. One half of that
                  Fort, if not more, faces inland. There have been frequent occasions, during the last eight or ten years, when we had reason to apprehend a foreign war, and if we waited until the war begun it would be too late to arm the forts; and those forts were intended to be fully armed and equipped. They ought to have been armed long ago. So far, Mr. President, from there being any increase of
                  troops, I understand, actually, that there has been a diminution.
                </p>
                <p className="p-in-sp">
                  Now, sir, if my friend's committee is to go to Fort Monroe; if he desires it to go there ; if he will so amend his resolution as to direct it to go there; if he is not yet satisfied; if he thinks it necessary to satisfy himself and his people by ocular demonstration, that this Fort is not mounted on wheels, I am willing that he shall go there. But, sir, I must confess I do not see
                  the propriety of this investigation.
                </p>
                <p className="p-in-sp">
                  Mr. President : With the indulgence of the Convention, while I am up, I will take this occasion, perhaps the most appropriate that I can find, to do justice to an old comrade—I mean Major Robt. Anderson, who now has command of Fort Sumter. I first met with him in the capacity of an instructor, and afterwards met with him in the swamps of Florida. For months we waded together through
                  them. We encamped within ten feet of each other, and we messed together. There were three of us—Major Anderson, who now commands Fort Sumter, Captain Dyer, who now has charge of the mounting of the guns at Fort Monroe, and myself. The first time I ever met with that stern and gallant soldier, General Taylor, was when he came to our camp upon the invitation of Major Anderson. The
                  Major having formerly served under him, he came out of compliment to him and dined with us. We had a table constructed on barrel staves that were stuck in the ground. Since that time, Mr. President, one has been President of the United States, another is in command of Fort Sumter, whose name now is as familiar to the ears of the people of this country as any other name in it ;
                  another now has charge of Fort Monroe, whose guns, it is said, are directed at the bosom of Virginia, his native State, and I stand here as the humblest of the four to defend those two soldiers against having any unlawful or improper designs against their country.
                </p>
                <p className="p-in-sp">I know Major Anderson, sir, well. In his veins runs true Virginia blood. On his maternal side runs the true blood of the Marshalls, and on his paternal side—</p>
                <div className="speaker" id="sp226"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">The Chair feels bound to call the gentleman to order, and to remind him that he is wandering altogether from the subject under consideration. I would state that his remarks have no bearing <PageNumber num={178} /> whatever upon the question, whether or not the resolution shall be adopted or rejected.</p>
                <div className="speaker" id="sp227"><span className="persName">Mr. EARLY</span>—</div>
                <p className="p-in-sp">We have had a great deal said about this question of increasing the armament in the forts of Virginia, much of which I think was as irrelevant to the matter under consideration as the remarks which I have submitted. But, sir, as it is not the pleasure of the Chair that I shall continue these remarks I will conclude.</p>
                <div className="speaker" id="sp228"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">The Chair feels bound to interrupt the gentleman and to remind him that he was not in order. The Chair will be happy to allow the gentleman to proceed, if such should be the pleasure of the Convention.</p>
                <div className="speaker" id="sp229"><span className="persName">Mr. MONTAGUE</span>—</div>
                <p className="p-in-sp">I move that the gentleman have leave to proceed. The motion was agreed to.</p>
                <div className="speaker" id="sp230"><span className="persName">Mr. EARLY</span>—</div>
                <p className="p-in-sp">I was proceeding to say, that on the paternal side there runs the same blood that runs in the veins of my friend from the county of Prince Edward, [<span className="persName">Mr. THORNTON</span>] .</p>
                <p className="p-in-sp">
                  We heard that for days the guns of Fort Sumter have been pointed at the bosoms of the women and children of Charleston. Let me tell this Convention that if the women and children of South Carolina have no other dangers to apprehend than those that result from the guns pointed at them by Major Anderson, they are far beyond the reach of harm. If it shall be his unpleasant duty, at any
                  time, to direct a gun against the bosom of any countryman, however misguided, every shot will wring his own heart. But he will do his duty; he will maintain his soldierly faith and honor to the last.
                </p>
                <div className="speaker" id="sp231"><span className="persName">Mr. JAMES BARBOUR</span>—</div>
                <p className="p-in-sp">I shall, in very few words, make a statement on a subject involving very great delicacy. But as a matter of justice to the Superintendent at Harper's Ferry, since this matter is going out to the public, I feel it to be my duty to make a statement of a fact.</p>
                <p className="p-in-sp">
                  I happened to be in the city of Washington at the time that the superintendent made the communication to the Executive, which resulted in this movement. It was made, sir, upon information communicated to me, with a desire to have that information communicated to him by a Virginia member of Congress, a personal friend of mine, and as warm and bitter a secessionist as is to be found
                  within the limits of the Commonwealth. After getting possession of this information, I consulted some gentlemen of clear heads and sound hearts, and told the Superintendent there that as a Virginian, in view of the facts that I communicated to him upon the high authority upon which I did communicate them, that it was his business to communicate them to the Executive, and to demand
                  aid, which he did, and I told him if censure <PageNumber num={179} /> came upon him that I would bear part of it. I say that the steps which he took were the steps which every Virginian, knowing the facts that he knew, would have taken.
                </p>
                <div className="speaker" id="sp232"><span className="persName">Mr. TREDWAY</span>—</div>
                <p className="p-in-sp">In offering this resolution, sir, I will state that I had entirely forgotten at the time that the gentleman from Jefferson [A. M. BARBOUR] 
                  was at all connected with the arsenal at Harper's Ferry. It was certainly not dictated by any want of confidence in his fidelity to the State of Virginia, although he occupied the position of an officer connected with that arsenal. I am satisfied, sir, that the effect of this resolution will be to place him, where I never doubted he would be placed, in the position of a true, loyal
                  Virginian.
                </p>
                <p className="p-in-sp">I shall not detain the Convention with any remarks in reply to my friend from Franklin, [<span className="persName">Mr. EARLY</span>] 
                  further than to say, that if this resolution shall not be adopted, it has had the effect, at any rate, of eliciting some information through that gentleman, which is comforting to me, and will doubtless be so to the people of Virginia. It is a source of consolation to me, as it must be to all who love the South, that, as the gentleman has assured us, the Fort at Old Point, as well as
                  that at Charleston, are under the command of gentlemen who have in their veins Virginia blood, and from whose character we have a right to infer that nothing will be done which is not true and honorable, at least, in respect to Virginia.
                </p>
                <div className="speaker" id="sp233"><span className="persName">Mr. JACKSON</span>—</div>
                <p className="p-in-sp">
                  I rise to say but a single word. Before I sit down I shall vote to lay this resolution upon the table. It strikes me that we are taking an improper view of this subject. We are proposing to raise a committee to go into the country and make an investigation to know whether or not Virginia has suffered in her honor or is likely to suffer in her relations, which she will sustain to the
                  country because of certain acts which are intimated in that resolution. From whom can this information be obtained? If any threat or improper conduct has been exhibited towards Virginia, it must have been done by the Executive of the United States. It strikes me that the proper channels, through which we should obtain information upon this subject, would be by a respectful
                  communication on the part of the Executive of this Commonwealth, directed to the Executive of the United States. Are you going to send your commission into the country and bring the public functionaries of the Federal Government before you? In what way is this information to be obtained? Through what channel will you seek it? I take it for granted that this dignified body will go to
                  the fountain-head; and I take it that you will approach that fountain-head in a manner becoming the dignity of this body, the dignity of this State, and <PageNumber num={180} /> the dignity of that relation which we sustain to the Confederacy. Let that communication be made through the proper channel, and in a respectful manner, and I doubt not a
                  satisfactory response will be obtained. There is another difficulty in the way, and that is the length of time that would elapse before this committee will be ready to report. I came here with the hope that I might soon re-cross the mountains and rejoin my family, but it really seems to me, in the manner we are proceeding, as if we might have an indefinite prolongation of the
                  session. When this committee would be able to report I know not. Through what channels we are to obtain information we are still in the dark. It may be possible we may-,have to adjourn before getting this important information. Under the circumstances of the case, inasmuch as the Executive, who is charged with the responsibility of looking after all the interests of this great
                  Commonwealth, has not thought proper to make any communication, I think it is, at least, an indirect imputation upon him, as the proper and responsible organ of the Commonwealth, to pass a resolution of this character. It is but justice and right to suppose that the Government of the United States have acted upon a proper sense of its responsibility to the country; and I doubt not if
                  a proper communication was made there, it would be satisfactory to us.
                </p>
                <div className="speaker" id="sp234"><span className="persName">Mr. A. M. BARBOUR</span>—</div>
                <p className="p-in-sp">Although the resolution of the gentleman fromPittsylvania [<span className="persName">Mr. TREDWAY</span>] 
                  in no way contemplated any action of mine, yet, because this debate has taken this range, I feel that it is due to this body, as Virginia gentlemen, due to myself and due to the gentleman who moved the inquiry, that the Convention should refuse to lay the resolution on the table. About the other forts in the Commonwealth, I know nothing and care nothing. The force sent to Harper's
                  Ferry was sent at my suggestion, and that takes from the Federal Execu tive the only poison here, that of intent. I have in no way deemed it due to myself even to consider that resolution in view of any vindication of myself, but because when these soldiers went there two or three weeks ago, there were misapprehensions aroused all over the State. I did not rise here to vindicate
                  myself, but to put the facts before the country, and make a statement which I believe to be due both to the government and to myself. It was with this view that I requested the Convention to refuse to lay the resolution on the table.
                </p>
                <div className="speaker" id="sp235"><span className="persName">Mr. CARLILE</span>—</div>
                <p className="p-in-sp">I shall vote, Mr. President, against the resolution in its present form. I desire to explain the reasons very briefly that have operated upon me to take that course. If I could reconcile it to my sense of duty and oblige my friend from Jefferson, [<span className="persName">Mr. BARBOUR</span>] 
                  who <PageNumber num={181} /> seems to desire the passage of the resolution, I would certainly vote for it; but Virginia, Mr. President, is still a part and parcel of the Federal Government, and that she has the power through her representatives in that government to call for such, information as is contemplated by that resolution, every member of this
                  body knows.
                </p>
                <p className="p-in-sp">
                  Now, sir, if my friend from Pittsylvania will amend his resolution, so as to request our representatives in Congress assembled, to introduce a resolution either in one or the other of the branches of that body, calling upon the Executive for this information, I will vote for it. But so long as we are a part and parcel of the Union ; so long as the Federal Government is our
                  government, I choose to observe the Constitutional limits of the legal modes for ascertaining and making any inquiry which we desire to make as to the action of that government.
                </p>
                <p className="p-in-sp">
                  Farther, we have the express declaration of the Chief Executive officer of that government, Virginia's officer, elevated to power by Virginia's vote, in his annual message submitted to Congress, denying the power to the Federal Government to coerce a State. If there has been anything emanating from that officer or from any department of that government, which goes to show that that
                  Executive officer who, by the Constitution, is Commander-in-Chief of your army and navy, has changed his views since the transmission of that message to Congress, I have not seen it. I cannot, therefore, by my vote place my own government in a position to have all its officers constantly engaged in answering inquiries, not alone from the constitutional departments of that government,
                  who are authorized to make and demand them, but inquiries that may be submitted to it for every act that, in the discharge of its duty it may see proper to perform, emanating from every assembly that may meet in any way and every State of this Union.
                </p>
                <p className="p-in-sp">For these reasons and others, with which I shall not now trouble the Convention, I shall vote against the resolution, and I now—in order that my vote may be recorded—ask that the yeas and nays may be awarded to me upon the motion for indefinite postponement which I now submit.</p>
                <div className="speaker" id="sp236"><span className="persName">Mr. HARVIE</span>—</div>
                <p className="p-in-sp">I shall, sir, vote for the resolution offered by the gentleman from Pittsylvania, [<span className="persName">Mr. TREDWAY</span>] with great pleasure—not that I have the slightest distrust in my friend from Jefferson county [<span className="persName">Mr. BARBOUR</span>] 
                  -not that I have ever dreamed that he has done anything improper or impolitic—but because I want to know whether this Federal Government is strengthening the fortifications in the State of Virginia; whether it is bringing forces to bear upon the State which may become necessary to be used in the contingency that Virginia may
                  <PageNumber num={182} /> adopt the position, which it is very possible, if it be not probable, she will be compelled to adopt. I want to know what the Federal Government is doing bearing upon the possible contingency that Virginia may go out of the Union. I want to save, if possible, the irritation that necessarily results from the apprehension or
                  belief that these forces are brought to bear upon Virginia, and that the General Government is strengthening fortifications in a time of profound peace, when there is no danger of foreign war. I want to save the irritation on the part of the people of Virginia which must inevitably result if we come to the conclusion that the Government is acting with the view to bear upon the State
                  of Virginia now or hereafter. I do not wish to consider the question that has brought us here, under the constraint that may be brought to bear upon this Convention by the exhibition of Federal forces in and around the State of Virginia and I now call upon the Convention here, I call upon one and all to give to the people of Virginia the opportunity of knowing whether we are to
                  consider the questions upon which we are to deliberate under constraint or not. I do not ask it of them, but as a good and loyal citizen of this Commonwealth I demand to know whether the common agent of the State of Virginia, and the other States, is concentrating forces here with a view of counteracting the action that this Convention may take. I want to know whether we are to
                  consider this question surrounded by armed men, with the clank of arms sounding in our ears, or whether we are to consider it quietly, peacefully and deliberately. I desire, therefore, that the resolution shall be adopted, in order that we may have a close, clear and thorough investigation of this subject, and a report upon it by the Committee.
                </p>
                <div className="speaker" id="sp237"><span className="persName">Mr. WICKHAM</span>—</div>
                <p className="p-in-sp">
                  I desire the passage of the resolution, and shall vote against its indefinite postponement. For the past two months the State of Virginia has been kept in a state of agitation and irritation by false rumors spread broadcast over the land. I believe that this is one of them. I have good reason to believe that there are, at this moment, fewer soldiers of the United States within the
                  limits of the State of Virginia, than there were upon the 6th of November last. I desire that fact to go officially before the people of the Commonwealth. I desire that the public mind should be quiet upon this subject. I desire all the information possible to go before this people that will quiet and allay the agitation which has been artificially produced in this Commonwealth.
                  Therefore, with that object, I shall vote against the indefinite postponement of the resolution, and in favor of its passage.
                </p>
                <div className="speaker" id="sp238"><span className="persName">Mr. TREDWAY</span>—</div>
                <p className="p-in-sp">
                  I did not intend to have said anything farther in <PageNumber num={183} /> respect to this resolution, but for the remarks which have been made by some gentlemen—the gentleman especially who made this motion to postpone indefinitely. Now, sir, after having heard the gentlemen, I cannot imagine what good reason can be assigned why this resolution should
                  be indefinitely postponed, or why it should not be adopted. Take all that has been said by every gentleman opposed to its adoption, and I ask if they have indicated that any harm can result from the appointment of this committee? If this committee, as one gentleman has intimated, can effect no good, still he has not intimated, nor have other gentlemen done so, that any injury would
                  result from its adoption. Other gentlemen besides myself have said that in their opinion it might result in good. It would at least settle the public mind, and if it turns out, as I am inclined to believe it will turn out, that much more has been said than the truth would warrant, in the public prints, in regard to the movements which have been made by the general government in
                  fortifying our forts, and preparing them for an attack upon the State of Virginia, it will allay the great excitement and irritation which exists in the public mind.
                </p>
                <p className="p-in-sp">I am sincerely desirous that that irritation and excitement shall be allayed, if possible. If, however, it turns out that these movements have been made, as it has been represented in the public journals of the day and by others—then, I submit that we ought to know it, because if the worst is to come, we should be prepared for it.</p>
                <p className="p-in-sp">But, sir, the gentleman from Harrison [<span className="persName">Mr. CaRLILE</span>] 
                  says that this resolution ought to be modified so as to instruct or request our Representatives in Congress to make a call upon the Executive for this information. I know that that is sometimes done, and is one mode of getting information, when Congress wants it. But I have never heard that any other power than that of Congress itself has the right to call upon the Executive for
                  information. I maintain, from the peculiar position that Virginia occupies as a Southern State, and from the fact that she has not seceded from this Confederacy, that it is eminently proper she should, through her Convention make these inquiries. Virginia, today, is a part of that Federal Government; she is now in the Union; she is one of the sovereign States who compose that Federal
                  Government. Surely, as one of the parties to that Government, interested in its action as a Federal Government, as well as interested in the condition of the State itself—it is eminently proper that the State of Virginia should appeal to its own Government to know what are its views in respect to any State, with respect to any action whatever.
                </p>
                <PageNumber num={184} />
                <p className="p-in-sp">I hope that this resolution will be adopted, because if it results in no good, it will produce no harm.</p>
                <div className="speaker" id="sp239"><span className="persName">Mr. CARLILE</span>—</div>
                <p className="p-in-sp">
                  I am satisfied that the information sought to be obtained by this resolution will do good. I think I am in the possession of facts sufficient to authorize me to say, if there have been any apprehensions on the part of any portion of the people of Virginia that anything tending to their injury was intended by the Federal Government in the action referred to, that those apprehensions
                  will be relieved by the information which will be derived from the passage of this resolution.
                </p>
                <p className="p-in-sp">
                  But, sir, I cannot consistently with my duty, as a citizen of the Federal Government, resort to extra-Constitutional modes to obtain that which I have the right to demand in a Constitutional way; and it is because of that reason that I shall vote against the resolution. Anxious to promote the object which the mover of the resolution had in view, I did suggest that our servants in the
                  Federal Government—our Representatives—our sentinels upon the watchtower, who had the right to call for the information, and whose call cannot be disregarded—should be requested, in accordance with the Constitutional mode of proceeding in such measures, to make the call.
                </p>
                <p className="p-in-sp">I have no desire to detain the Convention; and as the motion which I intimated I would submit, would possibly require two votes upon this resolution, I will withdraw it.</p>
                <p className="p-in-sp">The question was then taken on the adoption of the resolution, and it was decided in the affirmative—so the resolution was agreed to.</p>
              </div>
              <div className="section" id="vsc1965.v1.2.9.3">
                <h3><span className="headingNumber">1.9.3. </span><span className="head">CORRECTING THE JOURNAL</span></h3>
                <div className="speaker" id="sp240"><span className="persName">Mr. HAYMOND</span>—</div>
                <p className="p-in-sp">Mr. President, is it in order to correct the journal of the Convention?</p>
                <div className="speaker" id="sp241"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">Certainly, sir.</p>
                <div className="speaker" id="sp242"><span className="persName">Mr. HAYMOND</span>—</div>
                <p className="p-in-sp">
                  I find the list of delegates elected to this Convention, as published in the proceedings, is erroneous in one particular, and I desire to correct it. It is in regard to Benjamin W. Byrne, who represents upon this floor the counties of Braxton, Nicholas, Webster and Clay. In the journal it reads Braxton, Nicholas and Craig. I move to strike out the words "and Craig" and insert the
                  words "Webster and Clay."<span className="note" id="Note28"
                  ><span className="noteLabel">3</span>No list of delegates to the Convention has been found in the Proceedings. The Enquirer of February 15 published "an official list of the members of the Convention which assembled in the capitol on the 13th inst., at 12 o'clock M." but not as part of the reports of Proceedings; moreover, that list gives A. C. Kincaid as the delegate from Braxton,
                    Nicholas and Clay. The list of delegates printed in Doc. No. V, Report of the Committee of Elections Shewing Who Are Elected Members of the Convention, and in the Convention Journal for February 21, gives Benjamin W. Byrne as the delegate from Braxton, Nicholas, Clay and Webster. The Convention Journal for February 19 records that "<span className="persName">Mr. B. W</span>. Byrne, the
                    delegate from the counties of Braxton, Nicholas and Clay, appeared and took his seat."</span
                  >
                </p>
                <div className="speaker" id="sp243"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">The Chair will regard it as the sense of the Convention that this correction be made, no objection being indicated. The Secretary will make the correction.</p>
                <p><span className="persName">Mr. FISHER</span> offered the following resolution :</p>
                <PageNumber num={185} />
                <p>
                  Resolved, That this Convention does not wish to inaugurate a National Convention, and would not rely on such a body to afford redress for the grievances and wrongs of which the South complain, nor give such guarantees as would satisfy the people of this State that their honor and interests would obtain an effective protection from such a Convention; but, on the contrary, that the
                  people of the South might reasonably apprehend that such a body would re-organize the judiciary system of the United States, and make the Judges elective by the whole people of the Union, as Mr. Seward has declared his party would do as soon as they acquired the power; and that such a body would make other innovations upon the now recognized rights of the minority section.
                </p>
                <div className="speaker" id="sp244"><span className="persName">Mr. CLEMENS</span>—</div>
                <p className="p-in-sp">Is it in order to object to a resolution that it may lay over? I understand such to be the rule.</p>
                <div className="speaker" id="sp245"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">The Chair thinks that the resolution goes before the Committee on Federal Relations.</p>
                <div className="speaker" id="sp246"><span className="persName">Mr. CLEMENS </span>—</div>
                <p className="p-in-sp">Do not the rules adopted, which I understand to be those laid down in Jefferson's Manual, require that a resolution shall lay over when objected to?</p>
                <div className="speaker" id="sp247"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">The Chair is of opinion that, under the resolution adopted by the Convention, it is competent for the gentleman to explain his resolution, and then to have it referred to the Committee on Federal Relations.</p>
                <div className="speaker" id="sp248"><span className="persName">Mr. FISHER</span>—</div>
                <p className="p-in-sp">I desire to say that I offered that resolution with a view to disabuse, as I hope, the public expectations in the Federal metropolis. If I had any doubt as to the propriety of offering that resolution before I did offer it, it is dispelled by the objection made by the gentleman from Ohio [<span className="persName">Mr. CLEMENS</span>] 
                  . I desire to say that, in all human probability, Mr. Lincoln will, on Monday week next, propose to the United States of America the calling of a National Convention, upon condition that some one State of a now disrupted Union will demand it. I desire to know, by a vote of this body, whether Virginia is prepared to yield all her interests, to submit her honor to the action of any
                  National Convention that now can be convened. I can say for one, sir, that I would as soon have my right arm amputated as agree to such a proposition.
                </p>
                <p className="p-in-sp">
                  Why, sir, how would we stand in that National Convention, with nineteen States against us? Seward has, within the last eighteen months or two years, proclaimed that as soon as the Republican party got possession of the Federal Government, it would re-organize the Judiciary system, by making it elective by the people ; so that thereby they might
                  <PageNumber num={186} /> have, on the bench of the Supreme Court of the United States, Judges whose feelings and sentiments would be in harmony with those of the Republican party.
                </p>
                <p className="p-in-sp">
                  With these views I am opposed to a National Convention, because if all the slaveholding States should go into a Convention, they might expect what the New York Tribune, in its issue yesterday, declared, that the slave owners would be compelled to yield the representation which slaves now give to the Southern portion of this Confederacy in Congress. It also declared that if they
                  choose to recognize slaves as persons, that they would be prepared 'to meet us; but that they will not recognize them as persons and property—as persons for the purpose of increasing the representation of the South in the Congress of the United States, nor as property, because the Chicago platform has declared that this is the time when all idea of property in man shall become
                  extinguished.
                </p>
                <p className="p-in-sp">
                  I want, then, that those who are coming into power in Washington shall understand distinctly that Virginia will not yield to a National Convention, even the present rights which she has under the Federal Union. We know that if she was to go into a Convention, composed, as that Convention would be, that even these rights which she now claims would be no longer preserved. It is for
                  these reasons, sir, and with the hope that these were the unanimous opinions of this body, that I submitted that resolution. In this hope, however, I am evidently doomed to disappointment, judging by the manifestations made by the gentleman from Ohio
                 [<span className="persName">Mr. CLEMENS</span>] . I must still indulge the idea, however, that this Convention will, at least, be nearly, if not entirely unanimous in the adoption of this resolution whenever it comes to be acted upon. If, under the rule, it shall go to the Committee on Federal Relations, of course I cannot object.</p>
                <p><span className="persName">Mr. WHITFIELD</span> offered the following resolutions, which were referred to the Committee on Federal Relations :</p>
                <ul>
                  <li className="item">
                    1. Resolved, That the election of Abraham Lincoln to the Presidency of the United States, and the apprehensions entertained as to the policy of his Administration ; together with the withdrawal of the seceded States from the Union, having placed Virginia in great peril and embarrassment, the Convention should deliberate coolly and calmly upon the course she is to pursue for the
                    future.
                  </li>
                  <li className="item">
                    2. Resolved, That if the Peace Congress should fail in its mission ; or if the guarantees which may be adopted by it and assented to by this Convention, be not ratified within by the people of the North, Virginia, co-operating with the border slave States, will unite <PageNumber num={187} /> with the seceded States, provided the latter have not
                    incorporated in their Constitution, provisions prejudicial to her interests.
                  </li>
                  <li className="item">3. Resolved, That while we tender our warm sympathies to our Southern sisters, and feel embittered by the wrongs and injuries received from the North, this Convention should, nevertheless, look first to the interests of Virginia, and by conciliation and compromise pursue that course which will produce harmony and prevent discord among ourselves.</li>
                  <li className="item">4. Resolved, That in the judgment of this Convention, this Commonwealth should resist with all her means any attempt to coerce a seceded State.</li>
                  <li className="item">5. Resolved, That if the force of events shall present the alternative to Virginia, of uniting either with the North or the South, in the opinion of this Convention, her true position will be the latter.</li>
                </ul>
                <p><span className="persName">Mr. WILSON</span> offered the following resolutions, which were referred to the Committee on Federal Relations :</p>
                <p>Resolved, That we, the people of Virginia, in Convention assembled, do adhere with patriotic devotion to the Union of these States, and that we will do, as long as the same can be perpetuated consistently, with full security of all our constitutional rights, and the maintenance of the equality of all the States.</p>
                <p>Resolved, That it is inexpedient and improper for the General Government to increase its forces at the forts, arsenals and dock yards within the limits of Virginia, or to do any act looking to warlike preparations against this State.</p>
                <p><span className="persName">Mr. SHARP</span> offered the following resolutions, which were referred to the Committee on Federal Relations :</p>
                <ul>
                  <li className="item">
                    1. Be it resolved, as the sense of this body, that the Constitution of Government and the Union, founded and established by our forefathers, having been the silent, yet prolific source of prosperity, peace and happiness to all the people of the United States, and of prosperity, peace and happiness only, from its foundation to the present time, and will be for all time to come to
                    our posterity, if we are but true to the great trust reposed in us as freemen, should not be broken up and destroyed, except for grave and weighty causes of intolerable oppression and insufferable grievances ; and only then, after all peaceful and constitutional means of redress have been tried and have failed.
                  </li>
                  <li className="item">
                    2. That, without expressing an opinion as to whether or not a State has the Constitutional right to secede from the Union, yet this body deprecates its exercise on the part of any State, as an act of political <PageNumber num={188} /> suicide, that would aggravate, a hundred fold, every grievance complained of, rather than remedy a single one.
                  </li>
                  <li className="item">
                    3. That, although we believe that a Government without the Constitutional right and power to enforce all its laws, made in pursuance of the Constitution establishing it, and to hold all its property, everywhere, to the extent of its limits and jurisdiction, is worthless and equivalent to just no Government at all; yet we would deprecate an attempt on the part of the Federal
                    Government to execute its laws, in the seceding States, or to re-take, by force, the forts and other property of said Government, in the seceding States, that have been captured by said States from said Government, as an act of policy, bad, in its inevitable results—an act unalterably calculated to produce civil war and sectional strife—the direst calamity that could possibly
                    befall a people.
                  </li>
                  <li className="item">
                    4. Resolved, That whilst we would deprecate, on the part of the Federal Government, any act calculated or intended to coerce the seceding States, or any of them, and to produce a state of civil war and sectional strife; yet we would equally deprecate and condemn any attempt to take by force of arms from the Federal Government, by the seceding States, or any of them, any fort,
                    arsenal, navy yard, or other property owned by, and in the possession of said Federal Government, as an act of war on the part of said seceding State or States in which act said State or States would be the aggressor or aggressors, and that Virginia, under such circumstances, would be under no obligation to, and would not assist or support such State or States in a war produced by
                    such act.
                  </li>
                  <li className="item">
                    5. Resolved, That the unhappy differences that now distract and divide the country ought to be met in a spirit of fairness to all parties, and settled in the same spirit; and that, in the opinion of this body, the Crittenden or border States resolutions present a basis of settlement that would be fair to all parties, and satisfactory to Virginia, and to the border States of this
                    Confederacy.
                  </li>
                </ul>
                <p>On motion of <span className="persName">Mr. ADDISON HALL</span>, the Convention then adjourned until Monday at 12 o'clock.</p>
              </div>
            </div>
            <div className="day" id="vsc1965.v1.2.10">
              <PageNumber num={189} />
              <h2><span className="headingNumber">1.10. </span><span className="head">TENTH DAY</span></h2>
              <div className="dateline">Monday, <span className="date">February 25</span></div>
              <p>The Convention met at 12 o'clock M., and was called to order by the PRESIDENT.</p>
              <p>Prayer by the Rev. Dr. Minnigerode.</p>
              <p><span className="persName">The PRESIDENT</span> announced that he had appointed the following Committee upon the resolution offered by the gentleman from Pittsylvania [<span className="persName">Mr. TREDWAY</span>] , the other day, in relation to the increase of force in the forts and arsenals of Virginia : Messrs. Tredway, Pendleton, Bouldin, Wilson and Mallory.</p>
              <div className="section" id="vsc1965.v1.2.10.1">
                <h3><span className="headingNumber">1.10.1. </span><span className="head">AMENDMENT OF THE CONSTITUTION</span></h3>
                <div className="speaker" id="sp249"><span className="persName">Mr. HAYMOND</span>—</div>
                <p className="p-in-sp">I offer the following resolution :</p>
                <p className="p-in-sp">"Resolved, That the Constitution of this State should be amended and that this Convention will amend the Constitution wherein it is necessary and proper it should be amended, and will submit the same as amended to the voters of the State for their adoption or rejection."</p>
                <p className="p-in-sp">
                  I do not propose the resolution which has just been read to this Convention for the purpose of discussing the subject at this time. I do not propose to say anything upon this occasion to provoke discussion upon the question of amending our State Constitution. My object in offering the resolution at this time, is to bring the subject fully to the consideration of the members of this
                  body, in order that they may, after reflection and after consultation with each other, determine for themselves and their constituents, what action we propose to take upon the subject of our State Convention. I have felt it to be my duty to offer this resolution. I do it in obedience to the will of those who have delegated me to a seat upon this floor. I do it with a view to what I
                  conceive to be the best interests of the State of Virginia, and as a measure eminently proper to be adopted by the Convention.
                </p>
                <p className="p-in-sp">
                  I will farther remark, that in my humble judgment, the harmony and the best interests of the people of the Commonwealth of Virginia demand that this Convention should amend her organic law in many particulars. To accomplish my purpose, therefore, upon the present occasion, I move to lay the resolution on the table, and that it be printed, and I will here give notice to the members of
                  this body, that at a future period, when it is convenient, and when it will not disturb our deliberations upon the subject of our Federal relations, I will call it up for the consideration of the Convention.
                </p>
                <PageNumber num={190} />
                <p>The resolution was then laid on the table and ordered to be printed. <span className="persName">Mr. HaLL</span>, of Wetzel, offered the following resolution :</p>
                <p>Resolved, That a committee of twenty-one be appointed by the Chair, to be styled the Committee on the State Convention, and that they report to the Convention what amendments, if any, are necessary at present to be made to the present State Constitution.</p>
                <p><span className="persName">Mr. CHAMBLISS</span> moved to lay the resolution on the table, which motion was agreed to.</p>
              </div>
              <div className="section" id="vsc1965.v1.2.10.2">
                <h3><span className="headingNumber">1.10.2. </span><span className="head">PETITION OF MR. COLLIER</span></h3>
                <div className="speaker" id="sp250"><span className="persName">Mr. CHAMBLISS</span>—</div>
                <p className="p-in-sp">
                  I hold in my hands a petition addressed to this body by a most respectable citizen of the Commonwealth, not unknown to political fame. It is upon the vital subject of the present aspect of the troubled condition of our common country. His name is not signed to the petition. It is signed "A Citizen," and I endorse his respectability and his intelligence. If it be necessary, his name
                  can be affixed to the petition, and I have the authority to put it there. I ask that the petition may be read and referred to the Committee on Federal Relations. I am not to be understood, either as endorsing the action which it proposes, or as repudiating the views which it inculcates. It is my duty when a petition comes from a respectable source, that I should present it for the
                  consideration of the Convention. The Secretary may put the name of the petitioner, Robert R. Collier, of Petersburg. I ask that it be read, and referred to the Committee on Petitions.
                </p>
                <p>The petition was then read as follows :</p>
                <div className="floatingText">
                  <div className="floatingText_body">
                    <p>MR. COLLIER'S PETITION</p>
                    <p>To the Hon. the Delegates from the sovereign people of the State of Virginia to the Convention now holding its session to prepare measures to be ratified or rejected by the qualified voters :</p>
                    <p>
                      Your petitioner, who has bestowed much labor during five years in the examination of the subject under the consideration, and for the initiative disposal of your honorable body, would suggest and petition, in all deferential humility, that you will judge and decide it to be proper, with as little delay as may be consistent with deliberation, to adopt a decisive policy, and as
                      unqualifiedly as may comport with due respect for others who are interested, keeping two objects in view, to wit: the security of our rights, and a reconstruction of the federal Union. Your petitioner suggests that such policy should be to declare this state out of the federal Union, before the 4th day of March 1861, but only so out that we may be in again, by a self-repealing
                      ordinance, <PageNumber num={191} /> on the 19th day of October 1861, in case the non-slaveholding States shall intermediately have ratified the amended articles, to be thenceforth a part of the Constitution of the U. S., as amendments thereto, but which are only needed to make that instrument more explicit, to wit :
                    </p>
                    <p>
                      ART. 1. In all 'territory North of 36 deg., 30 min., slavery or involuntary servitude, except as now existing, or otherwise only as punishment for crime, shall not exist henceforth; and on the South of said degree of latitude, negro slavery is hereby recognized as property in persons, held to service, and shall not be prohibited by any law in the decision of any Court; and no
                      more territory shall be acquired to be formed in a State or States, unless such acquisition be with the previous consent of three-fourths of the States by their several Legislatures.
                    </p>
                    <p>
                      ART. 2. Congress shall not prohibit negro slavery anywhere it now exists, nor interfere with it in any way, otherwise than as Congress now has power to interfere with any kind of property; nor prohibit the slave trade between the citizens of the slaveholding States; nor ever re-open or legalize the African slave trade; but Congress may acquire territory in any country in which
                      the right in other respects can be obtained, for colonizing free persons of color born in the United States, and resident therein, or who may be hereafter set free by the voluntary act of their owners, to be removed thither.
                    </p>
                    <p>
                      ART 3. Every claimant of a fugitive person of color held to service in any State by the laws thereof, and escaped into and found in any non-slaveholding State, who shall produce before the Secretary of the Treasury of the United States, proof that such person is or has been harbored in such State, and that the recovery has been resisted there, and also proof of the value of and
                      title to such persons so owing service, shall be paid such value out of the Treasury of the United States ; and Congress is hereby required to enact a law suitably to recover from such State any sum or sums so paid.
                    </p>
                    <p>ART. 4. The owners or employers of persons of color held to service, shall have right of transit with such person or persons, and of temporary sojourn in any non-slaveholding State or Territory; and Congress is hereby required to enact a law suitably to secure and regulate this privilege.</p>
                    <p>ART. 5. No person who is in whole or in part of African race shall be eligible to any Federal or Territorial office, nor have right of suffrage in the election of any such officer.</p>
                    <p>
                      ART. 6. The election of citizens resident in the slaveholding or nonslaveholding section, is hereby restricted to two terms in succession, <PageNumber num={192} /> from either section. (Or thus in Art. 6: When the slaveholding or the non-slaveholding section of the States shall have furnished the President of the United States for two terms in
                      succession, the same section shall not furnish a candidate for whom the votes given in this section shall be counted in the next election ; so that the same section shall not aid in the election of citizens within its limits, for three terms in succession.)
                    </p>
                    <p>
                      ART. 7. The States or any of them that have seceded, shall be reinstated in the Union, as before they seceded, after they shall severally have ratified by three-fourths of all the States.<span className="note" id="Note29"><span className="noteLabel">1</span>In Article 7, "they" presumably refers to the proposed amendments, and "been" is omitted before "ratified."</span>
                    </p>
                    <p>Your petitioner would suggest that the form of the ordinance by which States remaining in the Union be invited to act on the said articles, be as follows, to wit :</p>
                    <p>
                      We, the people of Virginia, &amp;c., do ordain, &amp;c., that the States which may remain in the Federal Union, from which seven States have recently seceded, be and they are hereby invited to apply to their Congress to call a Convention for proposing certain amendments of their Constitution, which are hereby directed to be certified to the said Congress, and to the Governors of
                      the several States, to be referred to their respective Legislatures; and that the States that have seceded be, and they are hereby invited to ratify the said amendments by their several Legislatures, or by Conventions respectively, as those States may prefer; and that the effect of the affirmative action of three-fourths of the States remaining in the Union shall be, so far as
                      depends on Virginia, that so many of the States as have seceded, or may, shall be considered as re-instated in the old Union, as by Art. VII of said amendments is proposed.
                    </p>
                    <p>
                      Your petitioner, only in the hope that it might succeed, yet as a means of saving time and certainty of State action, would suggest that Congress first be applied to to propose, by two-thirds vote, the same amendments to be ratified by the Legislatures of three-fourths of the several States; and for this purpose your petitioner suggests the following form of an ordinance, to wit
                      :
                    </p>
                    <p>We, the people of Virginia, do ordain, &amp;c., that the Congress of the United States be and they are hereby invited to propose to the States composing their Union, the articles herewith certified, to become a part of the Constitution—said articles being the same which have been adopted by this Convention and referred to the people.</p>
                    <p>Your petitioner would further suggest that, having so put the ball in motion, to get the Constitution suitably, and, to Virginia, acceptably <PageNumber num={193} /> altered, the chief ordinance which he petitions this honorable body to adopt should be as follows :</p>
                    <p>
                      ART. I. We, the people of Virginia in Convention assembled, do ordain and declare, and it is hereby declared and ordained, that the powers delegated by the Constitution of the United States, which was ratified by Virginia, in Convention, on the 25th day of June, 1788, are hereby resumed, so that this State is, and henceforth shall be free to do all acts which independent States
                      may of right do; but in case the first five of the seven articles of amendments of the Constitution of the United States, which this Convention has invited Congress and the States to accept and accede to, shall, before the 19th day of October, 1861, be ratified as part of the said Constitution; or, in case the Union is re-formed, by the States that have seceded, otherwise or
                      sooner than this procedure contemplates, then this ordinance shall be deemed to be by itself revoked. Provided, however, that either in case the Constitution be not so amended, or a majority of the States seceded be not satisfied to restore the Union under the Constitution so amended, or the Union be not otherwise re-formed, then this ordinance shall be of full force as if it
                      were adopted and ratified without qualification.
                    </p>
                    <p>Let another article be added to that for referring the action of this Convention to the people, and directing that the vote shall be taken on the 1st and 2nd days of March, 1861, and that if this ordinance is ratified it shall be deemed to have taken effect at sunset of the last day of voting on it by the people.</p>
                    <p>And your petitioner prays that this his petition be referred by your honorable body to your Committee on Federal Relations, and that its suggestions be by them considered.</p>
                    <p>A CITIZEN</p>
                  </div>
                </div>
                <p><span className="persName">Mr. PATRICK</span> moved that it be referred to the Committee on Federal Relations.</p>
                <div className="speaker" id="sp251"><span className="persName">Mr. NELSON</span>—</div>
                <p className="p-in-sp">
                  I move to lay it on the table, because if all the petitions which may be sent in here upon this subject are to be referred to the Committee on Federal Relations, it will be evident to the Convention, that that committee will soon be overwhelmed. The people of the Commonwealth have sent 150 members here, and if we all present resolutions of this character, a great deal of time will be
                  consumed. I hope that this paper may be laid upon the table, and, with all deference to those who may send such petitions, I shall move to lay them upon the table without their being read, if some other member does not.
                </p>
                <PageNumber num={194} />
                <div className="speaker" id="sp252"><span className="persName">Mr. CHAMBLISS</span>—</div>
                <p className="p-in-sp">
                  I had thought if there was any right held dear to the American heart, it was the right of petition. I had thought that the humblest citizen of this broad land was deeply enough interested in the troubled condition of our country to allow his petition, at least, to be presented to this Convention. Why, sir, though the people have sent one hundred and fifty-two men here, does that
                  signify that that great power which is behind us is not to control our action? Are the people to be denied the right to petition this body, and to have their voice heard here? Then, sir, we had better sit here with closed doors. We had better sit here with doors shutting out from the public gaze all our proceedings until our conclusions are arrived at, and our conclusions alone given
                  to the country. I trust that the gentleman will not press his motion to lay this petition on the table. I undertake to say that the paper which he has presented has as much force and as much political sagacity in it as many of the resolutions which have been referred to the Committee on Federal Relations. If it be referred there, they will treat it with such consideration as it
                  deserves, and if they choose to heed its suggestions, "well and good," but if they do not, then it will be buried in that common grave which is the receptacle of so much that has been referred to that Committee.
                </p>
                <p className="p-in-sp">I hope that the gentleman will not press his motion to lay on the table, but that he will allow the petition of a citizen of Petersburg to go to the Committee on Federal Relations for their consideration.</p>
                <div className="speaker" id="sp253"><span className="persName">Mr. NELSON </span>—</div>
                <p className="p-in-sp">
                  If there were any right of the petitioner involved in any question presented before this body, I would be loath to oppose the reference asked for; but it must be evident to the Convention, as I stated before, if these petitions are to be presented in this manner, that it will consume a great deal of our time. I understand that the petition in this case is an anonymous one.
                </p>
                <div className="speaker" id="sp254"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">The signature has been appended to it.</p>
                <div className="speaker" id="sp255"><span className="persName">Mr. NELSON</span>—</div>
                <p className="p-in-sp">I do not think that it will be interfering with any man's rights, and I must insist, therefore, on my motion.</p>
                <div className="speaker" id="sp256"><span className="persName">Mr. CHAMBLISS</span>—</div>
                <p className="p-in-sp">Upon that motion I call for the yeas and nays.</p>
                <p>The yeas and nays were ordered.</p>
                <p>The question was then taken on Mr. Nelson's motion, with the following result—yeas 18, nays 98:</p>
                <p>AYES-Messrs. Robert Y. Conrad, Early, Holcombe, Holladay, Hughes, Lewis, McNeil, Macfarland, MOORE, Nelson, Pendleton, Randolph, Richardson, Sitlington, Southall, White, Wickham, WOODS-18.</p>
                <p>NAYS-Messrs. Janney [President] , Armstrong, Aston, Baldwin, Alfred Barbour, Baylor, Berlin, Blakey, Boisseau, Borst, Bouldin, Branch, Brent, Brown,</p>
                <PageNumber num={195} />
                <p>
                  Burdett, Burley, Byrne, Cabell, Campbell, Cecil, Chambliss, Chapman, Conn, C. B. Conrad, James H. Cox, Critcher, Custis, Deskins, Dulafiy, Echols, Fisher, Flournoy, Forbes, Fugate, Garland, Gillespie, Graham, Grant, Gravely, Gray, Gregory, Goggin, John Goode, Jr., Thomas F. Goode, Hale, Addison Hall, Cyrus Hall, L. S. Hall, Hammond, Harvie, Haymond, Hoge, Hubbard, Hull, Isbell,
                  Marmaduke Johnson, Kent, Kindred, Leake, Charles K. Mallory, James B. Mallory, Marshall, Marye, Maslin, Masters, Moffett, Montague, Morris, Morton, Neblett, Osburn, Parks, Porter, Pugh, Rives, Saunders, Wm. C. Scott, Seawell, Sharp, Sheffey, Slaughter, Spurlock, Staples, Alexander H. H. Stuart, Chapman J. Stuart, Strange, Sutherlin, Tarr, Tayloe, Tredway, Robert H. Turner, Franklin
                  P. Turner, Waller, Whitfield, Willey, Williams, Wilson, Wysor-98.
                </p>
              </div>
              <div className="section" id="vsc1965.v1.2.10.3">
                <h3><span className="headingNumber">1.10.3. </span><span className="head">EXPLANATION OF VOTES</span></h3>
                <div className="speaker" id="sp257"><span className="persName">Mr. EARLY</span> arose on his name being called and said—</div>
                <p className="p-in-sp">
                  I believe the rule adopted by this House permits a member when his name is called to state his reason for the vote he gives. After a petition has been presented, I hold that a vote in favor of laying it on the table or against its reference, does not deny the right of petition or interfere in any way with that right; and believing also, that if we refer all petitions that may be sent
                  to us by individuals to the Committee on Federal Relations, it would impose too onerous a duty on that Committee, I will vote aye.
                </p>
                <div className="speaker" id="sp258"><span className="persName">Mr. HOLCOMBE</span>—</div>
                <p className="p-in-sp">I deem it proper in giving my vote to accompany it with an explanation similar to that made by the gentleman from Franklin [<span className="persName">Mr. EARLY</span>] .</p>
                <p className="p-in-sp">In voting aye, I do so with no impression that I am at all denying the right of petition ; but merely expressing my opinion as to the proper destination of this petition, which is that it should not go to the Committee on Federal Relations, but be laid on the table.</p>
                <div className="speaker" id="sp259"><span className="persName">Mr. JACKSON</span>—</div>
                <p className="p-in-sp">Mr. President, I have just come in, and am not aware what is the character of the proposition before the House.</p>
                <div className="speaker" id="sp260"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">A petition has been presented by a gentleman from Petersburg, and the motion now, is to lay that petition upon the table, and upon that the yeas and nays were demanded.</p>
                <div className="speaker" id="sp261"><span className="persName">Mr. JACKSON</span>—</div>
                <p className="p-in-sp">Then, sir, I will ask to be excused from voting, for I do not know the purport of the petition, and I will not take up the time of the Convention by asking that it be read. I decline to vote.</p>
                <p>The question on excusing <span className="persName">Mr. JACKSON</span> from voting, was put, and decided in the affirmative.</p>
                <p><span className="persName">Mr. JOHNSON</span> being absent in the Committee room when the petition was read, and, therefore, ignorant of its character, also asked to be excused from voting.</p>
                <PageNumber num={196} />
                <p>The Convention agreed to excuse him.</p>
                <p>The question was then taken on ordering the petition to be referred to the Committee on Federal Relations, and decided in the affirmative.</p>
                <p>COMPENSATION OF OFFICERS</p>
                <div className="speaker" id="sp262"><span className="persName">Mr. JOHNSON</span>, of Richmond—</div>
                <p className="p-in-sp">Mr. President, I am prepared to make a report in behalf of the committee on the compensation of officers.</p>
                <p className="p-in-sp">
                  I will state to the Convention, that in making the allowances which have been made for the officers of this Convention, the committee have taken for their guide the compensations allowed to officers at the last Convention. I have copied the allowances from the journal of the last Convention, and the pay allowed to the officers of that body, is the same allowed to the officers of
                  this.
                </p>
                <p className="p-in-sp">I will now submit the report.</p>
                <div className="floatingText">
                  <div className="floatingText_body">
                    <p>The Committee on Compensation of Officers respectfully recommended to the Convention the adoption of the following resolution :</p>
                    <p>
                      Resolved, That the allowance to the officers of this Convention for their services during its sessions shall be—to the President, in addition to his mileage, as a member of the Convention, eight dollars per day ; to the Secretary, including the pay for his assistant, one hundred dollars per week ; to the Sergeant-at-Arms, thirty dollars per week ; to each Doorkeeper, twenty-eight
                      dollars per week; and to each of the pages. fourteen dollars per week; and that John D. Smith, for superintending and keeping in order the Mechanics' Institute during the session of the Convention therein, and for employment of servants to aid him in doing so, be allowed five dollars per day.
                    </p>
                    <p>MARMADUKE JOHNSON, Ch'm.</p>
                  </div>
                </div>
                <p>The report was adopted.</p>
                <div className="speaker" id="sp263"><span className="persName">Mr. MOORE</span> of Rockbridge—</div>
                <p className="p-in-sp">I desire to offer certain resolutions, and to follow them with some remarks if I can make myself heard. They read as follows :</p>
                <p>
                  Resolved, That the conduct of the so-called free States, in resisting the execution of the Fugitive Slave law, in refusing to give up criminals fleeing from justice; in seeking to deprive the Southern States of any portion of the common territory of the nation ; and of their citizens, in circulating incendiary pamphlets among us; in furnishing arms to bands of assassins to invade our
                  borders and murder our people, with other flagrant wrongs, is such as to require prompt reparation of the injuries inflicted, and justify Virginia in demanding, as she does demand, full and ample security, that those wrongs shall not be repeated.
                </p>
                <PageNumber num={197} />
                <p>2. Resolved, That Virginia can never consent to become a member of any confederacy, by the constitution of which, the re-opening of the African slave trade is not prohibited.</p>
                <p>3. Resolved, That Virginia will not become a member of any confederacy, the government of which, except under extraordinary circircumstances, is to be supported by direct taxation.</p>
                <p>
                  4. Resolved, That this Convention doth approve of the amendments of the Constitution of the United States, proposed by the Crittenden resolution; and declare its readiness to accept the same as a satisfactory adjustment of existing contingences<span className="note" id="Note30"><span className="noteLabel">2</span>The Convention Journal reads "controversies."</span> between the Northern and
                  Southern States.
                </p>
                <p>
                  5. Resolved, That in the event of the amendments referred to, or other equivalent amendments to the Constitution of the United States not being adopted, Virginia will be ready to enter into a compact with such States as will agree to adopt said amendments, by which the present government of the United States shall be declared to be dissolved, as to the States so agreeing, and that
                  they will thenceforth constitute a new confederacy under the constitution, so amended, from which all the States not so agreeing shall be excluded.
                </p>
                <div className="speaker" id="sp264"><span className="persName">Mr. MOORE</span> of Rockbridge—</div>
                <p className="p-in-sp">I propose to offer some remarks upon the resolutions which I have just submitted for the consideration of the Convention, in connection with the subjects referred to by the ambassadors sent here from the seceded States.</p>
                <p className="p-in-sp">
                  I trust, sir, that in the remarks which I shall submit, whilst I shall express my opinions fully and freely, I will give offence to no gentleman here or elsewhere. At the same time I do not expect that I shall entitle myself to any of the soft soder which has been administered of late. I desire that this Convention shall duly consider what shall be the destiny of Virginia in the
                  event of an unfortunate connection being formed between herself and the seceded States.
                </p>
                <p className="p-in-sp">The first resolution sets forth some of the prominent grievances which the people of Virginia and of the border States have a right to complain of in reference to the Northern States.</p>
                <p className="p-in-sp">I imagine it is unnecessary for me to discuss these things, because I presume there is perfect unanimity in this House as to the existence of the grievances complained of, and of the necessity of demanding an immediate assurance that they shall not be repeated.</p>
                <p className="p-in-sp">
                  I offer that resolution, sir, in order to draw a distinction between our grievances and the grievances of the seceded States. I say sir, that, although the grievances which we complain of are well founded as to us, they are matters of entire indifference to the seceded States, and <PageNumber num={198} /> only calculated to affect our interests. They
                  are merely assumed by the seceded States as levers to operate upon the border States, and induce them to form a connection with them. I think, sir, I am justified in that opinion by the declarations of the "Mercury," the leading organ of those States. I see it here stated, sir, in these words :
                </p>
                <p className="p-in-sp">"The Union will be restored with a few guarantees about negroes, such as the frontier States want, but which are of no consequence to the cotton States."</p>
                <p className="p-in-sp">
                  I say, sir, I believe that that is the true ground that they mean to take, and that they care nothing about the slaves which we have lost. They have lost no slaves ; there has been no inroad made upon them, for we alone have been the sufferers by any loss resulting from the carrying off of slaves. Their real ground of dissatisfaction lies in the operation of the tariff. They are for
                  free trade and direct taxation, and I maintain that our interests demand that we shall have a revenue to support any new government that may be formed, and that that revenue shall arise from duties on imports.
                </p>
                <p className="p-in-sp">
                  There is then a direct conflict at once between our interests and those of the seceded States. That the causes from which we suffer and which are alleged by them as provocations for the course they have pursued, were not the immediate causes which operated upon them, is publicly apparent from the declarations made in the South Carolina Convention. The then governor of South Carolina
                  has said that they have been ready for forty years to demand a division of the Union, and that they only wanted a pretext to make an effort to dissolve it. Then, sir, if they have been in favor of dissolution for the last forty years, they have not been influenced in the course they have pursued by the events which have happened within the last fifteen or twenty years. Our chief
                  losses from the abduction and escape of slaves have occurred within this period, and inasmuch as they have been seeking a pretext to go out of the Union for the last 40 years, these causes cannot have operated upon them at this time. The fugitive slave law and the personal liberty bills have been passed since 1850, and while the latter undoubtedly furnish just cause of complaint to
                  the citizens of the border States, they cannot be supposed to have led to the secession of the Cotton States, because these States formed that purpose forty years ago, and because, moreover, while they justly deprecated this policy on the part of the North, as a violation of a Constitutional right, they were not materially affected by that policy. The border States were the sufferers
                  by it, and they, if any, had cause to complain.
                </p>
                <PageNumber num={199} />
                <p className="p-in-sp">Now, sir, what is their real cause of complaint? They are but two, and those stick out so prominently that you cannot fail to see them.</p>
                <p className="p-in-sp">
                  They are opposed to the collection of any duties on goods brought into the country, and they want free trade and direct taxation. Sir, the election of Lincoln, of which they now complain, and which they present as the chief cause for their secession, is really not the true cause. And even, if it was, they alone are responsible for his election, for it was the natural result of the
                  course which they had pursued in the. Charleston Convention. As proof of the fact that the election of Lincoln was not the cause of this secession movement, I will quote from a pamphlet printed in Charleston, South Carolina :
                </p>
                <p className="p-in-sp">"It is a great mistake to assign the election of Lincoln as the cause for a disruption of the Federal Government. It is but the occasion. The cause existed, perhaps, at the foundation of the confederacy."</p>
                <p className="p-in-sp">
                  I apprehend it must be apparent to every man who is familiar with the history of this election, that they could not well complain of it, for, in my judgment, they contributed as much as any other people in the United States to the election of Lincoln. I call the attention of the Convention to facts as I believe them to exist, although I am not very familiar with the proceedings to
                  which I allude, and I imagine it is a fact that these men went to the Charleston Convention, believing that the Democratic party had lost the power to save the Union, for the purpose of breaking up the party with the ultimate purpose of destroying the Union. Now, there was but one man in the United States of the Democratic party who had the least chance of success. That man was
                  Stephen A. Douglas. What objection did they make to him? Why, that he was in favor of Squatter Sovereignty—Squatter Sovereignty, which they looked upon as one of the principles of the Democratic party, and which they had sustained in voting for the Kansas and Nebraska bill. Who did they nominate in opposition to him? John C. Breckinridge, who, himself, voted for the Kansas and
                  Nebraska bill containing this Squatter Sovereignty doctrine. I have never had the fortune to read but one speech made by Mr. Breckinridge, and that was reported in the Congressional "Globe," and in that speech he distinctly announced that Congress had no right to interfere for the protection of slavery in the Territories or for any purpose whatsoever.
                </p>
                <p className="p-in-sp">
                  Then, sir, they have indirectly operated in favor of the election of Lincoln, in another particular. It must have been known to them all, that the mischief arising from the existing tariff was such in Pennsylvania that she had suffered a loss in her iron interests, during the <PageNumber num={200} /> last few years, to the amount of one hundred millions
                  of dollars, and that it had been completely in the power of the British manufacturers to break down the manufactures of Pennsylvania, as they had broken down those of Western Virginia. It must have been known to them that Pennsylvania could not be expected to go and vote for the candidates of a party who would adhere to that party. It could not be expected that they would sacrifice
                  the interests of their State for the benefit of their party. They knew that the seceding States would consent to no demand for an increase of the tariff; and the members of the dominant party from the middle States, in order to preserve the ascendency of their party and that they might participate in the spoils, permitted the tariff to remain unchanged ; and the consequence was that
                  Pennsylvania was driven for protection to vote for a Black Republican candidate. But for that, if there had been a liberal policy pursued, Pennsylvania would have stood firm where she had been.
                </p>
                <p className="p-in-sp">
                  Mr. Buchanan, in his message of 1858, called the attention of Congress to the fact, that the revenue of the country was then falling short of paying its ordinary expenses, in a time of peace, to the amount of from 20 to 40 millions of dollars a year. He advised Congress that, owing to the operation of that tariff, it was in the power of any foreign manufacturer to cheat the
                  government to any extent ; and yet, with the knowledge of these things, they permitted it to remain as it was, and the consequence was that Pennsylvania and the other middle States voted for Lincoln.
                </p>
                <p className="p-in-sp">
                  I say, sir, that Lincoln owes his election in a great degree to the action, direct and indirect, of these seceding States. So far from our interests being identified with these seceding States, I say that there is a direct conflict between their interests and our own. Our interest is, that slaves should be sold as high as possible, because we have the commodity to supply. Their
                  interest is, that they shall be sold as low as possible, because they need the services of those slaves. Their policy. then, will be to re-open the African slave-trade, and I think I can foresee evidence that such is their purpose. I have extracts here from the Charleston Mercury, in which the declaration is made that they are determined to have it reopened, even if they have to
                  inaugurate a new revolution in order to effect their purpose. I have here a letter from a distinguished member of the Charleston Convention addressed to a member of the Congress at Montgomery, in which he declares, too, that they will inaugurate a new revolution, if that trade is not reopened. I take it, then, that that is a part of their policy.
                </p>
                <PageNumber num={201} />
                <p className="p-in-sp">
                  But it may be said that two or three of these States have declared against the re-opening of this trade, and that the Congress at Montgomery has so declared. If you will look, however, to the sentiments they have uttered in reference to that subject, you will find that they treat this declaration as a mere temporary arrangement, and that their determination is, that such a
                  declaration shall not be incorporated in the permanent Constitution of the Southern Confederacy. But suppose they do insert such a provision in their Constitution. What security have we that they will adhere to it? And when they hold to the doctrine of peaceable secession as belonging to every State, what confidence can we repose in anything they can put in their Constitution? Why,
                  sir, the State of Texas or the State of Mississippi, both known to defend the re-opening of the African slave trade, can secede at any moment, and then bring upon us all the mischief that would result from the re-opening of that trade.
                </p>
                <p className="p-in-sp">The third resolution declares—</p>
                <p className="p-in-sp">"That Virginia will not become a member of any Confederacy, the Government of which, except under extraordinary circumstances, is to be supported by direct taxation."</p>
                <p className="p-in-sp">
                  That the policy of these States is to subvert the Government by direct taxation, I have evidence in the declaration of Gov. Pickens, of South Carolina, in his inaugural address, in which he distinctly announced that as the policy of that State. At least I desire to know what are to be the consequences to Virginia, if that course of policy is adopted? What will be the amount of taxes
                  which Virginia will probably have to pay in this new Government? The existing Government of the United States has an expenditure of seventy or eighty millions of dollars per annum. I apprehend that the expenses of this Government of the Southern States can hardly fall short of that.
                </p>
                <p className="p-in-sp">It is true that it will extend over much less extent of country than the present government of the United States. But, sir, what is to be the additional expenditure on account of an increase of the standing army which must be maintained?</p>
                <p className="p-in-sp">It was admitted by the distinguished gentleman from Georgia [Mr. Benning] 
                  that it would be necessary for us to have a large increase of the standing army, if we should separate from the other States—and that is obviously true. Situated as this country has ever been, with its vast continent, with none but a few inconsiderable tribes of Indians to guard against, it was in our power to get along with some six or seven thousand troops in the standing army. Can
                  that be the case, <PageNumber num={202} /> when we shall have around our border free States, foreign to us, having interests coming often in conflict with our own, and who will be operated upon by no friendly feeling towards us?
                </p>
                <p className="p-in-sp">
                  Now, sir, we must have a standing army. The history of Europe shows that even the petty States there are compelled to keep up standing armies of from 20,000 to 30,000 men. France has her 500,000, Russia her 800,000, and even the petty States of Denmark and Germany have to keep up an army of from 20,000 to 30,000 men. Suppose, then, that the Southern Confederacy have to keep up an
                  army of twenty thousand men—what would be the cost? Judging from the cost of the six thousand men that the United States now has, which I believe is some nine or ten millions, we may form some estimate as to the cost of keeping up an army of twenty thousand men. I have not the means of making an exact calculation, but from an estimate furnished me by a military friend of mine, I
                  should say that the expense of supporting an army of twenty thousand men, deducting 25 per cent from the cost of the troops of the United States, would amount to twenty-four millions of dollars. Then, sir, a navy is to be built, and including the other expenses inseparable from maintaining an army and navy fully manned and equipped, it is safe to assume that we shall have an
                  expenditure not short of fifty millions a year. What portion of that will Virginia be compelled to pay? If all the fifteen Southern States unite in one confederacy, Virginia will constitute one-eighth of the population and wealth of such confederacy. But, sir, can you expect that all the fifteen States will unite? Delaware assuredly will not. Maryland is bankrupt the moment she
                  attempts it. She has built her railroad to the Ohio river—she is dependent upon the proceeds of that road to pay the interest of her debt. She derives from that road about six millions of dollars a year, from a trade which comes from the States Northwest of the Ohio; and I presume the moment that the connection will be dissolved with those States, that they will take care, as our
                  State does, to give to their own State the benefit of that trade. I say, then, that Maryland cannot expect to retain that trade, if she unites with us; and that, moreover, she will be bankrupt by doing so. We cannot count upon her then. Can you count upon Kentucky and Tennessee? The members of the Legislatures of those two States went to Cincinnati some time past on a visit, and they
                  there declared that they had no idea of seceding—that they would not permit the Union to be broken up. Tennessee has refused to call a Convention, Kentucky and Missouri do not propose to call a Convention; Arkansas has refused to do so. We will have then, but nine States to bear all those <PageNumber num={203} /> burthens. With the whole fifteen States
                  uniting in a Southern Confederacy—Virginia constituting one eighth of the entire territory—and what will be our share of the expense?
                </p>
                <p className="p-in-sp">
                  It will be between six and seven millions per annum. Where are we to get the money to pay this, in addition to the amount which we now pay to the support of our State Government? The sum required for the expense of the State Government is four millions of dollars. Where will you collect ten millions from the people of Virginia to support the two Governments? It cannot be done, sir;
                  and if it could, it probably should be collected in specie. Who is to collect your revenue? The sheriffs cannot collect it. No Government on earth would employ agents of another Government to collect their revenue and be responsible to them for it. No, sir; we should have war over our whole country, and the cost of collecting the revenue would thus be enhanced.
                </p>
                <p className="p-in-sp">The revenue of the Government heretofore has been collected by duties on imports mainly, and that revenue has had the effect of giving employment to our own laborers and mechanics in Virginia, and but for the protection which that revenue affords, they could not come in competition with British manufacturers, who labor there at from 10 to 15 cents a day.</p>
                <p className="p-in-sp">
                  The inevitable effect then of agreeing to this policy which the extreme Southern States will be certain to take, will be to drive all the laboring white population out of the State of Virginia. Mr. Spratt, in an able letter, advances that idea ; he says that they mean to get rid of the white mechanics and to employ negro mechanics in their stead, and when they have succeeded in that,
                  he says, there will be no Democracy, and no demagogues to appeal to then.
                </p>
                <p className="p-in-sp">
                  I want to know whether that is to be the policy of Virginia? I want to know whether one half of her white population is to be driven from her territory, which would be the result of adopting the policy of these States? And, sir, who pays the duties that are now derived from imports? They are levied almost invariably upon luxuries—luxuries that are consumed by the wealthy—they are
                  levied upon fine broad-cloth and silk, and valuable wines and brandies that are imported from abroad. Adopt the policy of these Southern States and the great mass of the people who now pay no portion of the taxes will be overwhelmed with taxation. I don't think the people of Virginia will stand this.
                </p>
                <p className="p-in-sp">
                  Mr. President, I have seen it stated some time since, in a newspaper, <PageNumber num={204} /> that Virginia was so situated with regard to these States, that they could drag us into a connection with them, against the will of the people. Is that so, sir? But, sir, not satisfied with that, they are holding the lash over us, to enforce obedience to their
                  wish.
                </p>
                <p className="p-in-sp">The distinguished commissioner from Georgia [Mr. Benning] 
                  , in his address the other day before this Convention, said very frankly, that in case Virginia and the other border States refused to join the Southern Confederacy, they would be compelled to prohibit the inter-State slave trade between seceded States and the border slave States. The Governor of Mississippi, in his message to the Legislature, suggested a similar proposition; and the
                  gentleman from Georgia
                 [Mr. Benning] , stated, in addition that a similar provision was embodied in the Constitution adopted at Montgomery. He remarked that if the connection was not formed between the border States and the States now seceded, that that provision would undoubtedly be enforced.</p>
                <p className="p-in-sp">
                  Now, I don't like to be taken in this way, under such threats. I have always understood that forced connections seldom effect any good. Forced matiimonial connections have rarely tended to promote the interests of those who are so united. I do not believe in forced connections. Forced connections between different States rarely result in benefit to the States that have been united
                  upon any such principle.
                </p>
                <p className="p-in-sp">
                  The gentleman from Georgia told us that they would be willing to give us a ten per cent tariff, and if we would go to Montgomery and consult with them, that they might go a little farther than that. What would a ten per cent tariff do? It would not pay one fourth of the expense of the Government ; and, sir, what certainty would we have that they would continue even that tariff. Every
                  argument which the gentleman introduced against the Northern States, had a direct bearing against the tariff, showing conclusively that that formed the great grievances of which they most complained, and the conclusion is that the seceded States mean to pursue an opposite policy. Suppose we sever our connection with the present Government and form one with them. They tell us that we
                  can manufacture here, and secure a monopoly of the Southern trade. How long would it be before that privilege would be denied us? How long would it be before we would become Yankees, when they would propose to repeal those provisions in regard to the slave trade? What would they say to us? They would tell us, why, that the Yankees only taxed us 20 per cent, and they would want to tax
                  us 200 per cent, or more. They would say, you want to make us pay you $1,800 apiece for your slaves, when we can get them <PageNumber num={205} /> in Africa for from $150 to $200, and it would not be five years until they would secede from us again.
                </p>
                <p className="p-in-sp">I cannot recollect the exact language of the Charleston Mercury-in reference to our sending a commissioner to that State, but it was something like this :</p>
                <p className="p-in-sp">"If Virginia should send a commissioner to South Carolina, it would be proper to feed him, stuff him with Champagne and send him home as soon as possible. After what has transpired no honorable State would be willing to receive a commissioner from the State of Virginia."</p>
                <p className="p-in-sp">
                  I do not know, sir, whether or not the people of Charleston fed the Commissioner from Virginia with champagne or not; but I do know that the balance of the proposition was carried out, for they stood upon their dignity. They told him that they were a free and independent sovereignty, and that they could not consult with us after the manner we proposed. And the sovereign people
                  afterwards sent their Commissioner here to invite us to go out with them, and he was pleased to say that we were skulking behind the Northern States. I repudiate this assertion. I would be unwilling to go into a connection with a people who cannot treat us with any more respect than they have done. For one, I would repudiate all connection with such a people. I would prefer a
                  connection with any other form of government that has some republicanism in it. I do not think that these Southern States have much republicanism in them, and, sir, the history of the world shows that democracy and monarchy are so combined as to produce an absolute despotism. This is the fact in regard to France now. What do they propose to do in the Southern States? To drive out all
                  the white mechanics and laborers; and they will then have a pure aristocracy or an oligarchy. They have universal suffrage, to be sure ; but universal suffrage may combine as well with an aristocracy as with a monarchy, and in either case it may establish a despotism. I prefer, then, not to go with them.
                </p>
                <p className="p-in-sp">
                  We have heard a great deal said about King Cotton. I have here a pamphlet, in which extracts are made from the speech of Gov. Hammond, in which he alleges that England and all the rest of the world would topple to their foundations in case the supply of cotton from the Southern States should fail them. This idea is absurd. Before the last war, the idea was prevalent in this country
                  that we could, by withdrawing the supplies of cotton from France and England, redress the grievances of which we complained, and we resorted to that plan. Did they redress our grievances? The real fact was, that it involved a loss upon our own citizens of some hundreds of millions of dollars.
                </p>
                <PageNumber num={206} />
                <p className="p-in-sp">As to King Cotton, let me say that it is just as necessary for us to sell as it is for them to buy.</p>
                <p className="p-in-sp">
                  Is it not as indispensable to them that they should have manufactures and something to eat, as it is to their people that they should sell their cotton? What are they going to do with their cotton -if they do not sell it to England and the Northern States? Will they eat it? They cannot manufacture it—depend upon it, that is an idle notion of theirs. There are other things as potent
                  as king Cotton. There are king spindle and king hog,
                 [laughter] who are just as potent as king cotton. They cannot do without manufacturing—and without something to eat.</p>
                <p className="p-in-sp">
                  As to the matter of secession, I do not propose to discuss that now. The respect I have for the opinions of gentlemen who entertain the idea that secession is a rightful remedy, and that every State has that right, prevents me from saying what I think of it. But for that respect, I should denounce it as the most absurd and ridiculous notion that was ever presented. They make
                  government nothing but a rope of sand, and the most solemn compact that men can enter into is to be set aside by one of the parties to it. Treaties between foreign powers can be enforced. There is redress which they can demand; but this thing of government is of so little consequence in the estimation of some, that it can be dissolved upon any slight pretext. But we have it, upon
                  very high authority, that this right of secession was reserved ; that, at the time of the Convention, a proposition was made to give the power to Congress to enforce obedience on the part of the State to the compact, but that it was subsequently withdrawn; that this proposition was in reference to the articles of confederation, and not in reference to the constitution, and that it
                  was laid aside. It was laid aside because they had determined to adopt a proposition which would operate upon the citizens of the United States. But we are told that Virginia reserved the right of secession. I deny the fact to be so. What Virginia said was that the people of the United States claimed the right. If they meant that each State had the right, they would have said that
                  the people of Virginia had that right but they said no such thing.
                </p>
                <p className="p-in-sp">I do not mean to discuss that point, because I go for the right of secession as a revolutionary right.</p>
                <p className="p-in-sp">The next resolution I offered was—</p>
                <p className="p-in-sp">"That this Convention doth approve of the amendments of the Constitution of the U. S. proposed by the Crittenden resolutions; and declare its readiness to accept the same, as a satisfactory adjustment <PageNumber num={207} /> of existing contingencies between the Northern and Southern States."</p>
                <p className="p-in-sp">
                  I do not mean to confine the view of the Convention to this resolution or any other equivalent to it. I think it is time that we should lay before the people some definite proposition of what we are going to do. I am not disposed to wait longer to see what the Peace Conference shall do. My next resolution was this—I fear that gentlemen will think there is secession in the 5th
                  resolution, but it is what I am willing to do.
                </p>
                <p className="p-in-sp">My next resolution reads thus :</p>
                <p className="p-in-sp">
                  "Resolved, That in the event of the amendments referred to, or other equivalent amendments to the Constitution of the United States, not being adopted, Virginia will be ready to enter into a compact with such States as will agree to adopt said amendments, by which the present Government of the United States shall be declared to be dissolved, as to the States so agreeing, and that
                  they will thenceforth constitute a new Confederacy under the Constitution, so amended, from which all the States not so agreeing shall be excluded."
                </p>
                <p className="p-in-sp">
                  Now, sir, if such a Confederacy as that shall be formed, I apprehend that the New England States will not agree to come in upon those terms. I do not desire to have them. And, sir, I believe if we exclude them, we will have no difficulty in getting those Southern States back, if their politicians see any chance open for retaining their high offices, the securing of which, I believe,
                  was at the bottom of their exertions to bring about the present state of things. I would be glad to receive back some of these States, at least. If Georgia and Alabama will agree to come back, I will kill the fatted calf and receive them with music and dancing; but I would let South Carolina be out a while and feed upon the husks until she learns to treat people with a little more
                  decency and respect than she has done.
                </p>
                <p className="p-in-sp">Mr. President, we have heard a great deal of talk about fighting in the Union. This is the doctrine avowed by my distinguished friend from Princess Anne [<span className="persName">Mr. WISE</span>] . I think I understand what it means. If any Northern people had interfered for the purpose of rescuing John Brown in that raid which he made upon Virginia, they would have found out what fighting in the Union meant, and they would have found it out to their cost, and in that view of the case I entirely concur with the distinguished gentleman from Princess Anne.</p>
                <p className="p-in-sp">
                  But it is said that we must prepare for war. Let us not be guilty of the ridiculous conduct of going into war until we are prepared for it. Let us not imitate the folly of our own Congress in 1812, who went to war with one of the most powerful nations of the earth, as John <PageNumber num={208} /> Randolph said, "with a fifteen shilling pamphlet against
                  a thousand ships of war." I say, then, let us prepare for war completely, and not be hurried into it in any way until we are fully ready. I apprehend that we may have to fight with somebody. Our young men are full of fight and they will never be satisfied until they get to fighting with somebody. They are a good deal like the Irishman who stepped into a crowd and said, "Gintlemen, is
                  there any one of ye's who wud do me the favor to tread upon the tail of me coat, for I feel very much like fighting this morning."
                 [Laughter.] 
                  We have had telegraphic despatches about what they are doing in Boston, and we have heard a great deal about the pointing of guns down at some of our forts at somebody's house or chicken coop. But this little sort of humbuggery I trust not will drive men into precipitate action, but I hope they will pass it all by as the idle wind. As to the attitude I take in reference to Virginia,
                  I say this, that I would take the part of the United States government against all the other governments of the earth ; and I would take the part of the people of Virginia against all other States. Go where she will, I will be with her, unless she disgraces herself by going where she ought not. I will fight for Virginia to the last hour, and although she goes against my consent, I
                  will still be bound to follow her fortunes. My idea of patriotism compels me to go for my country against all other countries; for my own family against all other families on the face of the earth.
                </p>
                <p className="p-in-sp">But I will not detain the Convention at any further length. I desire, in conclusion, to call the attention of the Convention to a fact which I think is an important one, that there is a foreign power at work trying to break up the Union.</p>
                <p className="p-in-sp">I have a pamphlet published in the city of Charleston, which develops this important and extraordinary fact. I find on the 19th page the following :</p>
                <p className="p-in-sp">"Policy of England; not to abolish slavery, but to separate the South from the North.</p>
                <p className="p-in-sp">"Be assured, then, fellow-citizens, Great Britain is too deeply interested in slave-grown productions to desire the abolition of slavery. She agitates the exciting subject, by her agents here and at Exeter Hall, not to abolish slavery, but to break up the Union."</p>
                <p className="p-in-sp">There is the secret partner in this movement, and that partner is no other than John Bull.</p>
                <div className="speaker" id="sp265"><span className="persName">Mr. GOODE</span>, of Bedford—</div>
                <p className="p-in-sp">I had no idea, Mr. President, of replying at length to the argument which has just been made by the gentleman from Rockbridge [Mr. MOORE] 
                  , and I rise merely to enter my protest <PageNumber num={209} /> against the views which he has submitted to this Convention. As a member of this body, I have been pained, inexpressibly pained, by the speech to which we have just listened. Sir, for what are we here? The sovereign people of the State of Virginia have been assembled in Convention to
                  determine what Virginia should do in this, the most trying hour of her history.
                </p>
                <p className="p-in-sp">The gentleman from Rockbridge [Mr. MOORE] 
                  seems to misapprehend the object of this Convention. He has levelled his big guns against our gallant sister States of the South. He has opened and poured forth the vials of his wrath upon our friends, and not one word of rebuke, not one word of reproach, has fallen from the gentleman's lips against those who have brought this sad and unhappy catastrophe upon the country. Without
                  undertaking to go into the full history of this unhappy controversy, I charge upon the people of the Northern States—and posterity will hold them justly accountable at the bar of history—the destruction of the noblest fabric of free government the world has ever known.
                </p>
                <p className="p-in-sp">
                  The gentleman has read to us extracts from the "Charleston Mercury," and he has read to us extracts from an Address published in the city of Charleston, which this Convention have doubtless seen. He has summoned up gorgons, hydras and chimeras dire, to frighten this Convention from their propriety—in the way of direct taxation and the re-opening of the African slave trade. He would
                  have us believe that he would flee, flee from the Southern Confederacy, as he would flee from the "pestilence that walketh in the darkness and the destruction that walketh at noon-day." I beg my friend to remember that the "Charleston Mercury" does not speak for the Southern Confederacy. While he has been reading the editorials in the "Charleston Mercury," the Southern Confederacy
                  has acted in their Congress at Montgomery, and have put the heel of their condemnation upon all these measures, which the gentleman tells us would frighten him from entering into any Southern Confederacy.
                </p>
                <p className="p-in-sp">Sir, they have made an express prohibition in the Constitution against the re-opening of the African slave trade. They have proceeded to levy duties for the support of their Southern Government; and now on account of these imaginary ills, these ills which have no foundation in fact, which exist only in the imagination of the gentleman from Rockbridge [<span className="persName">Mr. MOORE</span>] , and his coadjutors, are the people of Virginia to be frightened from their propriety, and is their natural praiseworthy and commendable sympathy for our gallant Southern brethren to be stifled and trampled in the dust?</p>
                <div className="stage it">[Applause.]</div>
                <PageNumber num={210} />
                <p className="p-in-sp">
                  I am unwilling that this view of the unhappy controversy should go to the country without entering my protest. If this were the time and the occasion, I could show that the majority of the people of the Northern States are responsible before high Heaven for the dire calamities which now threaten this once happy and prosperous country. Mr. President, why is it that such a dark and
                  threatening cloud this day overhangs the political horizon? Why is it that the business of the country is so paralysed, the energies of the country so completely prostrated, and men are everywhere asking with earnest and anxious enquiry, "Sirs, what must we do to be saved from all the untold horrors of civil war and fraternal strife?" Are our Southern brethren to be blamed? What have
                  they done? Have they trampled upon our rights—have they infringed upon our constitutional privileges? They have asked only to be let alone in the enjoyment of their natural rights and in the enjoyment of their domestic concerns. And when we of the South have begged of the people of the North—and when I say the people of the North, I mean the majority of the people of the North—for
                  peace almost upon bended knees, they have replied and replied persistently, that there can be no peace so long as we claim the right to hold property in slaves. There, sir, is the foundation of the whole difficulty. Nothwithstanding the right of property in slaves was expressly recognized by the first and second treaty of Independence which our fathers made with Great Britain;
                  notwithstanding the right of property in slaves was expressly recognized by the Constitution, which was the bond of Union and the only bond of Union between the States; notwithstanding all this, we have been told by the Northern press, and by the votes and speeches of Northern representatives, that we of the South shall have no peace upon this question so long as we claim this right.
                  Slavery is offensive to Northern sensibilities. Slavery is a sin which presses hard upon the Northern conscience. They are wiser and holier than Saint Paul, who could preach the word of eternal truth to ten thousand slaveholders, without one word of reproach. They are wiser and holier than an angel of Heaven, who could advise fugitive servants to return to their masters, and submit
                  to their legal authority; wiser and holier than the Saviour of mankind, who could commend the faith of a slaveholder by saying that he had not found such faith, "no, not in all Israel." And our Northern brethren tell us that there can be no peace so long as we claim the right to hold property in slaves ; and there again lies the foundation of this whole difficulty.
                </p>
                <p className="p-in-sp">
                  They have done more. They have denied an equal participation in <PageNumber num={211} /> the common territories—territories which have been purchased by the common blood and treasure—territories to acquire which the South has poured out the best blood of her children upon every battlefield in Mexico, from Vera Cruz to the Capital. Sir, the Constitution
                  of your country, which they have sworn to support, provides, that the fugitive slave shall be delivered upon the demand of his Southern master. They have deliberately trampled that provision under foot. The Constitution of your country, which every Northern Governor and every member of a Northern Legislature has sworn before high Heaven to support, provides, that a person charged
                  with treason, felony, or any other crime, who shall flee from one State and escape into another, shall be delivered up upon the demand of the State from which he fled. But, sir, they have come to invade the sanctity of the slaveholder's home and slaughter our unoffending children at midnight. They have refused to deliver up the fugitive slave and tell us that they think it is no
                  crime to steal a negro or invade the slaveholder's home. And, above all, Mr. President, they have, finally, seized the reins of the Government and propose on Monday next to inaugurate as your and my President, the man who declares that the negro is the equal of the white man that the master has no right to govern his slave by a set of rules, in the adoption of which, the slave has no
                  choice, and that there is an irrepressible conflict going on between the free States of the North, and the slave States of the South.
                </p>
                <p className="p-in-sp">
                  But, sir, we were told in advance of the election, and we have been told in every speech which the President elect has made upon his triumphal march to Washington, that the Government is to be administered upon the principles of the Chicago platform. Sir, we have loved the Constitution of our fathers. Are we to sit quietly down, and tamely submit to have the Chicago platform
                  substituted in its stead? I have loved this Union as our fathers formed it. My very heart-strings have been entwined around it. We have been bound to it by cords stronger than hooks of steel; but I have loved it in the past, because I have looked upon it as a Union of equality.
                </p>
                <p className="p-in-sp">
                  Now, sir, what are we to do? What are the people of Virginia to do? That is the question. We have not come here to indulge in tirades of abuse against our gallant Southern States, who have nobly and gallantly sprung into the breach, and comprehended the public danger. Sir, eighteen months ago, when old John Brown and his minions invaded your borders and slaughtered your unoffending
                  citizens at midnight, and caused every mother in the land to hug her infant closer to her breast, South Carolina and Mississippi sent to you their ambassadors, <PageNumber num={212} /> to tender their generous sympathy. They said the natural position of old Virginia was in the lead. They asked Virginia to take the lead, and say what should be done in
                  this hour of common peril and common danger. What did Virginia do? She spurned the offer of generous sympathy; she coolly turned away from her children, and said she had no advice to give ; and the Legislature of this State said. in their resolutions in response to those Commissioners, that it was best for each State to take its own separate State action. South Carolina and
                  Mississippi took her at her word. They have gone home, and taken their own State action ; they have commissioned their gallant sons to come up here and enumerate the causes and grievances which impelled them to take the course they did, and, as high-toned and honorable men, they have performed their duty in a way to which I presume the gentleman from Rockbridge could not except they
                  have done it with thoughts that breathe and words that burn. I believe that not even the gentleman from Rockbridge could have sat insensible under those words which I know brought tears to eyes unused to the melting mood. They have discharged the object of their mission. They have gone home, and, as the humblest member upon this floor, I protest against indulging in these tirades of
                  abuse against those States which they represented.
                </p>
                <p className="p-in-sp">But what are we to do?—that is the question; and it is a matter of life or death to us. Do we intend to submit to the administration of Abraham Lincoln upon the principles of the Chicago platform? The question is—</p>
                <div className="lg">
                  <div className="l">"Whether 'tis nobler in the mind, to suffer</div>
                  <div className="l">The slings and arrows of outrageous fortune</div>
                  <div className="l">Or to take arms against a sea of troubles,</div>
                  <div className="l">And by opposing end them?"</div>
                </div>
                <p className="p-in-sp">Sir, I know not what may be the views of this Convention, I may be in a minority here; but I believe I speak the voices of Virginia, when I say [great applause] .</p>
                <div className="speaker" id="sp266"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">The Sergeant-at-arms will clear the gallery.</p>
                <p>The Sergeant-at-arms was about to proceed to execute the order. when</p>
                <div className="speaker" id="sp267"><span className="persName">Mr. GOODE</span>, of Bedford—</div>
                <p className="p-in-sp">I beg the citizens in the gallery to abstain from any such demonstrations as those they just indulged in.</p>
                <div className="speaker" id="sp268"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">If the Chair is to be considered in any degree responsible for the order of this body, he will insist upon the galleries being cleared.</p>
                <PageNumber num={213} />
                <p>The order was accordingly enforced, and the Sergeant-at-arms was proceeding to clear the lobby, when a voice called out : Mr. President. I beg leave to say that this is not the gallery.</p>
                <p>The confusion at this stage was very great.</p>
                <div className="speaker" id="sp269"><span className="persName">Mr. SHEFFEY</span>—</div>
                <p className="p-in-sp">Mr. President, it is necessary for the preservation of the dignity and decorum of this body that scenes of this character should be redressed, and I therefore move that the Sergeant-at-arms do take the gentleman who made the disturbance, into custody, and bring him to the bar of this body.</p>
                <p>A DELEGATE, in a low voice, remarked that the individual had left the Hall.</p>
                <div className="speaker" id="sp270"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">The Chair has been informed that the person who caused the disturbance has left the Hall.</p>
                <div className="speaker" id="sp271"><span className="persName">Mr. SHEFFEY</span>—</div>
                <p className="p-in-sp">I withdraw my motion, then.</p>
                <div className="speaker" id="sp272"><span className="persName">THE PRESIDENT</span>—</div>
                <p className="p-in-sp">The Sergeant-at-arms will now clear the lobby.</p>
                <p>The Sergeant-at-arms obeyed the order promptly, and the lobby was cleared.</p>
                <div className="speaker" id="sp273"><span className="persName">Mr. GOODE</span>, of Mecklenburg—</div>
                <p className="p-in-sp">I move that the Convention adjourn.</p>
                <div className="speaker" id="sp274"><span className="persName">Mr. MONTAGUE</span>—</div>
                <p className="p-in-sp">There is a man in custody, and it is necessary that the Convention should make some disposition of his case.</p>
                <div className="speaker" id="sp275"><span className="persName">Mr. BRENT</span></div>
                <p className="p-in-sp">I understand <span className="persName">Mr. GOODE</span> is unwell, and unable to proceed with his speech. I move to lay the resolutions on the table until to-morrow morning.</p>
                <div className="speaker" id="sp276"><span className="persName">Mr. EARLY</span>—</div>
                <p className="p-in-sp">I rise to a point of order. Under the rule adopted by the Convention, the resolutions now under consideration must go to the committee on Federal Relations. I hold that there can be no motion to lay them on the table without a suspension of the rules.</p>
                <div className="speaker" id="sp277"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">The Chair begs leave to state that when the resolution was originally offered by the gentleman from Frederick [<span className="persName">Mr. R. Y. CONRAD</span>] , it contained an express provision that all resolutions touching Federal matters should be referred to the committee on Federal Relations, without debate. A proposition was made to amend that resolution by striking out the words "without debate," and after some discussion it was agreed to.</p>
                <p className="p-in-sp">The Chair believes the true meaning and spirit of the resolution to be, that when a gentleman offers a resolution intended to go to that committee, it is in order for him to state his reasons for offering it, and for other gentlemen to reply to him.</p>
                <p className="p-in-sp">The Chair believes the motion to lay on the table in order, because the subject is not disposed of.</p>
                <PageNumber num={214} />
                <p className="p-in-sp">The question on the motion to lay on the table, offered by <span className="persName">Mr. BRENT</span>, was then put, and decided in the affirmative.</p>
              </div>
              <div className="section" id="vsc1965.v1.2.10.4">
                <h3><span className="headingNumber">1.10.4. </span><span className="head">DISCHARGE OF THE PRISONER</span></h3>
                <div className="speaker" id="sp278"><span className="persName">Mr. MONTAGUE</span>—</div>
                <p className="p-in-sp">I understand from your Sergeant-at-Arms, Mr. President, that in pursuance of your order, he has arrested some gentleman for a breach of the privileges of this House.</p>
                <div className="speaker" id="sp279"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">The Chair gave no such order.</p>
                <p className="p-in-sp">A motion was made by some gentleman to arrest some individual in the gallery who was acting disorderly ; but it being ascertained that the offending party had retired, the motion was withdrawn.</p>
                <div className="speaker" id="sp280"><span className="persName">Mr. WYSOR</span>—</div>
                <p className="p-in-sp">I understand that a gentleman resisted the enforcement of the President's order to clear the lobby, and refused to leave when so directed by the Sergeant-at-Arms. He is now in the Hall in custody, and I think it is proper that his case should be disposed of.</p>
                <div className="speaker" id="sp281"><span className="persName">Mr. MONTAGUE</span>—</div>
                <p className="p-in-sp">I was so informed, and was about to read the rule upon that subject.</p>
                <p><span className="persName">Mr. MONTAGUE</span> here read the 14th rule, which provides that the cases of persons taken into custody for violation of the rules of order, shall be referred to a committee, who are to report the result of their inquiries to the Convention.</p>
                <div className="note" id="Note31">
                  <span className="noteLabel">3</span>A corrected version (February 28) gives this account of Mr. Montague's reading of the rule, and Mr. Wickham's comment:
                  <p><span className="persName">Mr. MONTAGUE</span> read the 14th rule, which provides that no person shall be taken into custody by the Sergeant-at-arms on any complaint of a breach of privilege, until the matter of complaint shall be examined by a committee. * * *</p>
                  <div className="speaker" id="sp282"><span className="persName">Mr. WICKHAM</span>—</div>
                  <p className="p-in-sp">
                    I wish to know how the Sergeant-at-arms is to perform his duty if he is not provided with adequate means to enforce the orders of the Convention. How can he remove any refractory individual without the power of arresting him, unless indeed the Convention furnishes him with sufficient force to eject him from the gallery? If the rule which the gentleman from Middlesex
                   [<span className="persName">Mr. MONTAGUE</span>] read be applicable to this case, then there is to be censure on the Sergeant-at-arms if he does not enforce order, and yet the rule deprives him of the power of doing so. No censure, direct or implied, ought to be cast upon the Sergeant-at-arms, for it seems to me that he did his duty promptly in accordance with the instructions of the President.</p>
                </div>
                <div className="speaker" id="sp283"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">The Chair has given no order for the arrest of any individual, and the subject is now with the Convention.</p>
                <div className="speaker" id="sp284"><span className="persName">Mr. R. Y. CONRAD</span>—</div>
                <p className="p-in-sp">I move that the gentleman under arrest be</p>
                <p>discharged.</p>
                <div className="speaker" id="sp285"><span className="persName">Mr. MONTAGUE</span>—</div>
                <p className="p-in-sp">The motion is not in order—you cannot dispose of the matter until it is referred to a committee.</p>
                <div className="speaker" id="sp286"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">The question is, is there any person legally under arrest at all?</p>
                <div className="speaker" id="sp287"><span className="persName">Mr. WICKHAM</span>—</div>
                <p className="p-in-sp">I wish to know how the Sergeant-at-arms is to perform his duty if he is not provided with adequate means to enforce the orders of the Convention. How can he remove any refractory individual without adequate means to do so, unless he hurls him by force from the gallery. If the rule which the gentleman from Middlesex [<span className="persName">Mr. MONTAGUE</span>] 
                  read be applicable, then there is a censure implied upon the Sergeant-at-Arms if it is not enforced, and any other disposition is made of the case under consideration than that indicated by the rule. No censure, direct or implied, ought to be cast upon the Sergeant-at-Arms, for it seems to me that he did his duty promptly in accordance with the instructions of the President.3
                </p>
                <p><span className="persName">Mr. MONTAGUE</span> again read the rule, when</p>
                <PageNumber num={215} />
                <div className="speaker" id="sp288"><span className="persName">Mr. R. Y. CONRAD</span> said :</div>
                <p className="p-in-sp">The object of the motion that I made was, to avoid any necessity for such a course as the rule prescribed, that being the first case of disorder that has occurred.</p>
                <p className="p-in-sp">I will, therefore, persist in the motion I have made, which is that the Sergeant-at-Arms be directed to discharge the person whom he has taken into custody.</p>
                <p>The motion was agreed to.</p>
              </div>
              <div className="section" id="vsc1965.v1.2.10.5">
                <h3><span className="headingNumber">1.10.5. </span><span className="head">CORRECTION OF DEBATES</span></h3>
                <div className="speaker" id="sp289"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">I have received a communication from the Hon. Sherrard Clemens, a member of this body, with a request that it be laid before you.</p>
                <p className="p-in-sp">The following is the communication :</p>
                <div className="floatingText">
                  <div className="floatingText_body">
                    <p>SPOTSWOOD HOUSE, Feb. 24, 1861 Hon. JNo. JANNEY, President of the Convention :</p>
                    <p>Sir : I regret to say, that I am under the prescriptions of my physician, and therefore, unable to resume my seat to-day.</p>
                    <p>I ask the privilege of correcting the debates on Saturday last, as reported in this morning's "Enquirer."</p>
                    <p>The assertion made by <span className="persName">Mr. HALL</span>, to which I replied, was this :</p>
                    <p>"I say, sir, that there are men here to-day standing upon the platform and resolutions DICTATED by Mr. Campbell of the Wheeling Intelligencer, and I will name <span className="persName">Mr. HUBBARD</span> and <span className="persName">Mr. CLEMENS</span> of the North-west."</p>
                    <p>I then put the question to <span className="persName">Mr. HALL</span>. "Do I understand you as saying that I stood upon a platform, which was DICTATED by the editor of the Wheeling Intelligencer? I ask for a categorical answer."</p>
                    <p>This was the question, a reply to which <span className="persName">Mr. HALL</span> evaded. In every instance in which I used the word DICTATED, the word advocated is substituted in the report of the "Enquirer." The elimination of the word I used, destroys the whole force of the point which I made.</p>
                    <p>In my closing remarks, the word dictated is again struck out, and the word advocated substituted.</p>
                    <p>What I did say, was this :</p>
                    <p>
                      "Now, sir, I do not desire to say anything unkind to the gentleman, the member from Wetzel on this floor. I can afford to be magnanimous to him. I desire simply to say, that when he declared that I was elected upon a platform DICTATED by the editor of the Wheeling Intelligencer, or any other man, he declared ignorantly, I presume—maliciously I will not presume—an opinion upon a
                      matter, upon which <PageNumber num={216} /> at least he lacked information most miserably. I was elected upon my own platform."
                    </p>
                    <p>I beg that you will do me the favor to have this explanation laid before the Convention, so that the same may be made part of its reported proceedings.</p>
                    <p>I have the honor to be,</p>
                    <p>Very respectfully, yours,</p>
                    <p>SHERRARD CLEMENS</p>
                  </div>
                </div>
                <div className="speaker" id="sp290"><span className="persName">Mr. HALL</span> of Wetzel—</div>
                <p className="p-in-sp">Mr. President : By way of explanation I will offer a remark or two :</p>
                <p className="p-in-sp"><span className="persName">Mr. CLEMENS</span>' recollection is not very distinct as to the manner in which this conversation occurred.</p>
                <p className="p-in-sp">I stated, sir, that this whole matter grew out of remarks made by the gentleman from Princess Anne [<span className="persName">Mr. WISE</span>] , the gentleman from Monongalia [<span className="persName">Mr. WILLEY</span>] , and the gentleman from Lunenburg [<span className="persName">Mr. NEBLETT</span>] 
                  , in reference to <span className="persName">Mr. WOODS</span>' resolutions. I stated that I did not think the North-West was as sound as those gentlemen thought it was ; and then, at the time referred to (Saturday) , I qualified my remarks so as to designate the delegates in North-Western Virginia to whom I referred in particular. I said that there were men here upon this floor, who were
                  elected upon a platform designated and laid down by Mr. Campbell, who is now acting as reporter for the Wheeling Intelligencer on this floor.
                </p>
                <p className="p-in-sp">
                  <span className="persName">Mr. BROWN</span> , one of the delegates from the county of Preston, who sits immediately behind me said, in a low voice, what do you mean? Do not include us all; designate whom you mean. <span className="persName">Mr. CLEMENS</span> being away yonder, could not have heard the remark made to me. I then said in answer to <span className="persName">Mr. BROWN</span> that I
                  would designate <span className="persName">Mr. HUBBARD</span> and <span className="persName">Mr. CLEMENS</span>; whereupon <span className="persName">Mr. BROWN</span> remarked, "we are out of it." <span className="persName">Mr. CLEMENS</span> did not hear the remark made by the gentleman from Preston
                 [<span className="persName">Mr. BROWN</span>] .</p>
                <p><span className="persName">Mr. BURDETT</span> offered the following resolution :</p>
                <p>Resolved, That in view of the scene of disturbance that has just occurred in our deliberations, a committee be appointed to take into consideration the expediency of adjourning from this place to Staunton, or some other place at which the sessions of this Convention can be held without being interrupted by outside pressure.</p>
                <div className="speaker" id="sp291"><span className="persName">Mr. WICKHAM</span>—</div>
                <p className="p-in-sp">I move to lay that resolution on the table.</p>
                <p>The motion was agreed to ; then,</p>
                <p>On motion of <span className="persName">Mr. SHEFFEY</span>, the Convention adjourned till to-morrow at 12 o'clock.</p>
              </div>
            </div>
            <div className="day" id="vsc1965.v1.2.11">
              <PageNumber num={217} />
              <h2><span className="headingNumber">1.11. </span><span className="head">ELEVENTH DAY</span></h2>
              <div className="dateline">Tuesday, <span className="date">February 26</span></div>
              <p>The Convention met at 12 o'clock and was called to order by the President.</p>
              <p>Prayer by Rev. Dr. MINNIGERODE.</p>
              <p><span className="persName">Mr. BROWN</span> offered the following resolution which was agreed to :</p>
              <p>"Resolved, That the Auditor of Public Accounts be requested to furnish to the Convention a statement showing the aggregate number of persons returned delinquent by the Sheriffs of the different counties of the Commonwealth for the non-payment of taxes for the year 1860: also, the aggregate amount of taxes with such delinquent list."</p>
              <div className="speaker" id="sp292"><span className="persName">Mr. GOGGIN</span>—</div>
              <p className="p-in-sp">I would enquire whether the resolutions offered by the gentleman from Rockbridge [Mr. MOORE] on yesterday, are the first business in order to-day.</p>
              <div className="speaker" id="sp293"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The resolutions were, by order of the Convention, laid on the table, and a motion to take them up would now be in order.</p>
              <p>The motion was agreed to.</p>
              <div className="speaker" id="sp294"><span className="persName">Mr. GOGGIN</span>—</div>
              <p className="p-in-sp">I would move then that they be now taken up.</p>
              <p>The resolutions having been read by the Secretary, the PRESIDENT announced that the gentleman from Bedford [Mr. GOODE] not having concluded his remarks yesterday, was entitled to the floor.</p>
              <div className="section" id="vsc1965.v1.2.11.1">
                <h3><span className="headingNumber">1.11.1. </span><span className="head">FEDERAL RELATIONS</span></h3>
                <div className="speaker" id="sp295"><span className="persName">Mr. GOODE</span> of Bedford—</div>
                <p className="p-in-sp">Mr. President : As the reading of the resolutions has been called for, I deem it proper to state that it was not my purpose, yesterday, to speak to the resolutions but in reply to the speech of the gentleman from Rockbridge [<span className="persName">Mr. MOORE</span>] .</p>
                <p className="p-in-sp">
                  I regret, Mr. President, that my physical condition, this morning, is such that I am wholly unable to do justice to the cause which I am endeavoring to represent upon this floor. I regret, also, that circumstances, beyond my control, prevented me, yesterday, from concluding the few remarks which I intended to submit in answer to the address of the gentleman from Rockbridge. I have no
                  allusion to make to the occurrences of yesterday, farther than to give expression to the hope that our fellow citizens who occupy the galleries, will, in future, abstain from such demonstrations of applause. Sirs, it is a solemn work in which we are now engaged. The action of this Convention may determine, and, doubtless, will determine, the destinies of Virginia in all time
                  <PageNumber num={218} /> to come. And while it is natural, that the eagle eye, of a people jealous of their rights, and trembling for their honor, should be riveted anxiously upon us, I beg them to remember, in the language of Mirabeau to the French Assembly, that "the silence of a people is a lesson to kings."
                </p>
                <p className="p-in-sp">
                  I was proceeding on yesterday, Mr. President, when interrupted, to inquire what Virginia ought to do in this the most trying and perilous hour of her history. That, sir, is the all absorbing question of the hour. It comes home to the minds and hearts of our people, and it must be answered by this Convention. We must answer it before God and our country. We must answer it plainly,
                  flatly, pointedly, and unequivocally; if we fail to answer it, we fail to meet the just expectations of our constituents.
                </p>
                <p className="p-in-sp">
                  Sir, gentlemen may sing paeans to the Union; they may discourse eloquently upon the hallowed glories, the historic memories of the Union; but the Union is already dissolved. The Union of 1789 which our fathers formed; which we have heretofore cherished with such fond devotion, and that which, in the language of the Father of his country, we have been accustomed in days that are gone,
                  to look upon as the palladium of our liberties, safety and prosperity—that Union is already numbered with the things that were.
                </p>
                <p className="p-in-sp">
                  "Why stand we here, then, all the day, idle?" Whither will we go? What will Virginia do? Will she be true to herself and her historical renown? Will she nobly vindicate her ancient fame? or is her proud blood altogether extinct, and are we prepared to lower her proud banner, and permit her to humble her pride and pass under the yoke of Northern abolition? Whither, then, will we go,
                  sir? Will we go north, or will we go south? Will we go to the house of our enemy, or will we go to the house of our friends? Will Virginia cast her lot with her southern sister States of the South? or will she still hope on, hope ever, hope against hope, trusting, in the meantime, to the tender mercies of Mr. Abraham Lincoln?
                </p>
                <p className="p-in-sp">
                  Sir, I know not what others may think ; I know not what others may do; but for myself, I feel a sense of the solemn responsibilities which now rest upon me, and appealing to the Searcher of hearts, as to the rectitude of my purpose, I have no hesitation in declaring, that in my humble opinion, Virginia ought now, promptly and without delay, to take her position at the head of the
                  Southern column.
                </p>
                <p className="p-in-sp">
                  Sir, I believe in my inmost soul that immediate separation from the Northern Confederacy is a peace measure. If we are to believe the organs of Black Republican sentiment in the North, the coercion of the <PageNumber num={219} /> seceded States is to be attempted by the Lincoln administration. The tone of the Northern press, the declarations of their
                  representative men, the votes and speeches of their Senators and Representatives in Congress, the action of their State Legislatures, the collection of the Federal troops at the Federal Metropolis, the repeated declarations of Mr. Abraham Lincoln himself, the organization and constant drilling of Northern Wide-Awakes, are sufficient in my judgment to convince the most skeptical upon
                  this subject. He may not attempt, sir, to march his Federal myrmidons into a seceded State, but he will attempt to collect the Federal revenue, and retake the captured forts. Will our Southern brethren submit to this? Submit! Why, sir, it is a slander upon their fair fame and good name to entertain the idea for one solitary moment. You may talk about your alien and sedition laws ;
                  your stamp acts ; your tax upon tea, and the most abominable tyranny that ever cursed any people upon earth ; but I maintain that the most abhorrent tyranny upon earth would be to attempt to wring tribute from the pockets of an unwilling people.
                </p>
                <p className="p-in-sp">
                  Sir, the union of these States can never be restored by the power of the sword. It has rested upon entirely different grounds in the past ; it has rested upon public opinion, and been preserved in the hearts and affections of the people. I say, then, that threats of coercion can have no fears for freemen. The blood of the martyrs, in every age, has been the seed of the Church ; and
                  if coercion is attempted, the blood of slaughtered patriots would be like the dragons' teeth sown upon the earth, from which would come forth heroes, full grown and armed, ready to spring into life and rush to battle.
                </p>
                <p className="p-in-sp">
                  Then, sir, they will attempt, doubtless, the coercion of the seceded States; but they will never attempt to coerce a united South. The strong, sometimes, are tempted to make war upon the weak; but the strong sit down and reflect long and well before they make war upon the strong. And, sir, when old Virginia places herself at the head of this Southern column, the other border States
                  will wheel quickly into line, and then, and not till then, in my humble judgment, will the offensive and insulting threats of Northern coercion be abandoned. Then, sir, and not until then, will "grim-visaged war smooth his wrinkled front" and all again be peace—then may the Union of our fathers be reconstructed upon fair, just and honorable principles, and our country move forward
                  once again upon a brilliant career of prosperity and glory.
                </p>
                <p className="p-in-sp">
                  Sir, what a noble chaplet would be hereafter entwined about the venerable brow of our blessed old mother, if, under the Providence of <PageNumber num={220} /> God, it should be her mission to restore peace to these dissevered, discordant, and almost belligerent States; if, in the Providence of God, it should be her destiny to appear at the mouth of the
                  sepulchre itself of this dead Union, and say : "Lazarus, come forth"—I say, it would be a noble chaplet. Not only the people of this now distracted land would with one acclaim bless her; the nations of the earth would cry all hail to her; and, what is more, we have the word of eternal truth itself, which cannot fail, that "blessed are the peace makers, for they shall be the elect of
                  Heaven." If there are among those who hear me today, who earnestly and ardently desire a re-construction of this Union upon terms of fair, just and honorable principles, I sympathize with them cordially. But I beg them to remember, that Virginia can never exert her just and proper influence with the now seceded States until she manifests her determination to cut loose from the
                  Northern aggressor and take sides with the oppressed—until she manifests her determination to share with her gallant Southern sisters the dangers and the perils and the trying ordeal through which they are now passing.
                </p>
                <p className="p-in-sp">
                  Why, sir, when they sent their Ambassadors here to defer to Virginia, to ask her to take the lead and say what was best to be done for the common good, and when old Virginia coolly spurned the offer of sympathy and turned away from her children, it was but natural that these Southern States should feel some temporary distrust towards the Old Mother. Was it not natural? They did feel
                  it. They felt it properly. But when Virginia shall move into line and proclaim to the world that, come weal or come woe, her destinies are indissolubly linked with her sister States of the South; when she shall take this position, and this unnatural strife shall be terminated hereafter; when Virginia invites these Southern sisters to come back into the government which she has helped
                  to reconstruct—they will come, sir, I hope, not as my friend from Rockbridge
                 [Mr. MOORE] 
                  supposed on yesterday; they will come not like the prodigal son of old, repenting, in sack cloth and ashes, for what they have done—but they will come in the proud consciousness of virtue and right; they will come back to the Old Mother, and in the beautiful and touching language of Ruth to Naomi : "Entreat me not to leave thee or to return from following after thee, for whither thou
                  goest I will go, and where thou lodgest I will lodge; thy people shall be my people, and thy God my God. Where thou diest will I die, and there will I be buried."
                </p>
                <p className="p-in-sp">
                  But if in this, sir, I shall be mistaken; if Virginia cannot reconstruct the Union, Virginia can secure a peaceable separation. Sir, if we cannot live together with our Northern Confederates, we have a <PageNumber num={221} /> right to be permitted to separate; we have a right surely to depart in peace. This would be a poor boon, and Virginia, by a
                  prompt and decided action, in my humble judgment, would either restore the Union, or secure a peaceable and final separation; she would have a right to say, sir, as was said by one of old, "let there be no contention I pray thee, between thou and me—thou wilt go to the right, and then I will go to the left."
                </p>
                <p className="p-in-sp">
                  But, sir, I say it is no longer a question of Union or disunion. The question is, will we go North, or will we go South? Every consideration of interest, of honor, of patriotism—every impulse of the heart prompts the Virginia people to cast their destiny with their sister States of the South. Sir, more than 300,000 of those Southern people have gone from Virginia. They claim Virginia
                  as their mother; they are our brethren, our kindred ; bone of our bone, and flesh of our flesh. Can Virginia desert them now? Can Virginia desert them in their hour of greatest need? As well, sir, might I ask if a mother could forget and desert her own offspring.
                </p>
                <p className="p-in-sp">But, Mr. President, I had intended to refer particularly to some of the points made by the gentleman from Rockbridge [<span className="persName">Mr. MOORE</span>] . I regret that he is not in his seat this morning.</p>
                <p className="p-in-sp">I will, with the permission of the Convention, notice some few of the objections urged by that gentleman against a connection of Virginia with the Southern Confederacy.</p>
                <p className="p-in-sp">
                  He seems still determined to believe, in spite of all the evidence which can be adduced, that it is the purpose of the Southern Confederacy to re-open the African slave trade. Why, sir, not only has every State Convention of the seceded States put the heel of condemnation upon it, but the Congress at Montgomery itself has embodied in its Constitution a provision prohibiting the
                  re-opening of the African slave trade.
                </p>
                <p className="p-in-sp">But what says the gentleman? He says he is not willing to go into a Southern Confederation—in fact, into any Confederacy whose Constitution does not prohibit the re-opening of the African slave trade; and yet he prefers to adhere to this Northern Confederacy in whose Constitution no such prohibition is to be found.</p>
                <p className="p-in-sp">
                  We are to be oppressed, he tells us, with direct taxation. Why, sir, in answer to that it is sufficient to say that the provisional government had already adopted the tariff of the old government, and that the revenue of the government is now collected by duties upon imports, and we have the assurance of their commissioners and of their leading men throughout the South that it is not
                  their purpose to resort to direct <PageNumber num={222} /> taxation, but to levy such duties as shall produce a sufficient revenue to carry on the government.
                </p>
                <p className="p-in-sp">But the Southern States have not assigned the true cause of their withdrawal from the Union. That is the great objection urged by the gentleman [Mr. MOORE] 
                  to these Southern States. He maintains that the cause which they have given is not the true one. Why, sir, some of them gave notice twelve months ago, that the election of a Black Republican to the Presidency, upon a Black Republican platform, would be with them a treaty breach, and they would no longer remain in the Northern Confederacy. It is upon this ground that they have taken
                  their stand, although for years they have submitted to acts of intolerable oppression in the way of high tariffs, in order to attest their devotion to the Union. But, now, when a President is elected by a party who denounce the Union of our fathers and the Constitution of the country as a league with death and a covenant with hell, those Southern people can submit no longer, but have
                  determined to cut the Gordian knot, and risk the issue with the god of battles.
                </p>
                <p className="p-in-sp">We were told by the gentleman [Mr. MOORE] 
                  that he is unwilling to form any connection with the seceded States, because there is a direct conflict of interest between Virginia and her sister States of the South. The gentleman did not proceed to point out that conflict. In what does it consist? Are we not a people of homogeneous interests? Are we not a people of like habits, of like institutions and like religion? Are we not
                  all deeply and vitally concerned in the preservation of the institution of African slavery? We are, sir, and the only Union which can yield any material benefit to us is a Union with our Southern Sister States.
                </p>
                <p className="p-in-sp">
                  But the burdens of taxation, under the new government, would be intolerable. Sir, it is humiliating to me, in times like these, to be compelled to meet the argument of taxation. Carried out to its legitimate extent, would it benefit us to submit to any and all aggression, and to purchase exemption from taxation at the price of honor itself? I maintain not, sir. But can the tax be so
                  enormous in the Southern Government? I have no doubt it would be the most economical and virtuous government ever administered upon this continent. I believe it would be an honest government, and that is to me a controlling reason for the preference that I give to it over our old Northern confederates.
                </p>
                <p className="p-in-sp">
                  But, sir, we may be taxed; and if so, are there not advantages to be reaped from it? We would derive some benefits from this taxation. As it is now, we are taxed for the benefit of the North; we are taxed and <PageNumber num={223} /> taxed, and they enjoy the benefits, because the money is distributed and expended in the Northern States. We furnish
                  two-thirds of the revenue and they get four-fifths of the expenditures; and if the South is to be taxed by any future government, we would have this assurance, that we would derive some benefit from that tax.
                </p>
                <p className="p-in-sp">But, sir, if the gentleman from Rockbridge [Mr. MOORE] 
                  will not go with the South, where will he go? Will he go with the North? Look upon that side of the picture. We have been unable heretofore to secure our rights, with seven seceded States to maintain us. What hope for the future can the gentleman indulge, with nineteen Northern States against seven? If we have been taxed for their benefit with these seven States united with us—if
                  with this advantage we were unable to uphold and maintain our equal rights and common property in the past; what, I ask, must be our fate, when left to the mercy of nineteen Black Republican non-slaveholding States in this controversy? Why, what have they already done? They have deliberately nullified and trampled under foot the Constitution of the country. They have continued to
                  agitate the subject of slavery in Congress and out of Congress, with a view of exciting servile insurrection and rebellion in our homes. They have elected sixty-eight of their chosen leaders to the Federal councils who have deliberately endorsed the infamous Helper book, which advised our slaves to rise at midnight and cut the throats of their masters, and murder our wives and
                  innocent little ones. They have deliberately placed under the ban four hundred millions of dollars worth of Southern property. They have said that there shall be no more slave States admitted into the Union, and that there shall be no peace so long as we claim the right of property in slaves.
                </p>
                <p className="p-in-sp">
                  Are we going to submit to this? Our fathers crowned themselves with immortal honor, not because they threw off the British yoke, but because they resisted the first attempt to put it on; and they took up arms for the sacred right of representation. But we will be only seven Slave States against nineteen Free States, and we will be, to all intents and purposes, disfranchised in the
                  Government which we claim to be the embodiment of freedom itself. Send your representatives to Congress and let them vote. Call the ayes and noes upon them. They may go through the forms peculiar to public assemblies, but our Northern masters will decide; and practically we will be without representation in the Federal councils so long as we shall stand slumbering between the two
                  sections.
                </p>
                <p className="p-in-sp">
                  But, sir, the question, I say, comes back at last—which Confederacy will we join? I for one indulge no such gloomy foreboding in regard <PageNumber num={224} /> to the future of Virginia in a Southern Confederacy as the gentleman from Rockbridge pictured for her. I believe that she can be the leading star in the Southern constellation; she would be the
                  largest slaveholding State in the Confederacy, with a salubrious climate, and soil of unrivalled productiveness; with the richest and most inexhaustible mines of coal, iron, copper and lead; with the noblest and most magnificent waterpower in the world; Virginia, I say, at the head of the Southern Confederacy, would carve out for her people a magnificent destiny in the future. That
                  Confederacy, sir, would have a territory twice as large as the continent of Europe; a population twice as large as that of the Colonies at the period of the revolution; it would have a soil and climate unsurpassed in the world; a grave, patriotic, public spirited, high-minded population; and, sir, it requires no prophet, or son of a prophet, to predict that Virginia would rise at
                  once to the front rank in the race of material greatness and power; capital and enterprise would spring up as if by magic, in our midst; our noble water-falls would whistle with machinery, and the spindles of the North would be transferred to the Potomac, the Rappahannock, and the James ; and Norfolk, with her magnificent harbor, would become the grandest commercial emporium of the
                  world.
                </p>
                <p className="p-in-sp">
                  Then, sir, let us not trust to the syren song of peace, peace, when there is no peace; let us not hug the delusive hope to our breasts that all may yet be well. Let us not trust to the tender mercies of Abraham Lincoln and his confederates, but let us come up to the dignity of the occasion and assert our own rights, sir, and maintain them in the strength of our own manhood and in the
                  fear of God.
                </p>
                <p className="p-in-sp">Sir, the gentleman from Rockbridge [Mr. MOORE] referred to the subject of party politics. I regret he has done so. In doing it, however, he took occasion to make a fling at the Breckinridge Democrats. He held them responsible for all the ills which now afflict the body politic. He stated that the distinguished gentleman from Kentucky [Mr. Breckinridge] 
                  who was a candidate for the Presidency in the last election, advocated Squatter Sovereignty when he ought to have known that he was nominated upon the idea and the sole idea, that he advocated protection for slave property in the Territories and went against the dogma of Squatter Sovereignty. But I will not go into the question of politics, I only regret that it was introduced here.
                  What have we to do with politics? Sir, we are Virginians all; no matter what may betide us in the future; no matter what may be before us in that undeveloped future, the same destiny awaits all the people of Virginia —whether they be Whigs, Democrats, Americans, Breckinridge men, <PageNumber num={225} /> Douglas men or Bell men—no matter what political
                  party they may belong to.
                </p>
                <p className="p-in-sp">Then, sir, let us not, while the house is on fire and we are zealously engaged in bringing water to extinguish the flames, quarrel on the way. Let us set aside the behests of party politics; let us endeavor to rise up to the grandeur and sublimity of this occasion and keep our garments all unstained by the miserable spots of party tactics.</p>
                <p className="p-in-sp">
                  I know not what others may do. But in this dread hour, in this hour of trial and of need to our common mother, I should feel myself unworthy of public confidence, if I could think for one solitary moment of past party conflicts. It is said, Mr. President, of the Duke of Wellington, that upon one occasion a war-worn hero who had served him during all his campaigns, came to see his old
                  commander. The Duke remarked to him that he bore about his person many signs of war and said to him : "Where did you fight, sir; and to what Regiment did you belong?" The old soldier replied that he had served him during all his Peninsula campaigns, that he had lost a leg upon the heights of Calatrava and an arm upon the plains of Waterloo. "My Regiment, sir, was the gallant 53rd."
                  "Did you, sir," said the Duke, "when in the fire of battle, think of your Regiment?" The eye of the old soldier flashed with excitement and indignation. "I loved my Regiment," he replied, "I gloried in her standard and her triumphs; I would give my remaining life for her honor. But on the battle-field I thought only of my king and my country."
                </p>
                <p className="p-in-sp">Sir, let that spirit animate us now, and in this dread hour which so deeply concerns us all, let us think only and for the rights and interest and honor of Virginia.</p>
                <p className="p-in-sp">Mr. President, for myself, though I know no evil more horrible than civil war, I am prepared to accept any alternative for Virginia, rather than submission, humiliation and disgrace ; and my prayer is that Heaven may protect and defend this noble old Commonwealth.</p>
                <div className="speaker" id="sp296"><span className="persName">Mr. GOGGIN</span>—</div>
                <p className="p-in-sp">
                  Mr. President, I should not ask the attention of this body today upon the great and important questions which now distract the country, were it not that I feel it to be my duty, to express the views which will govern and control my action here. I have come here as a member of this body to endeavor, in such a manner as may seem proper to myself, to discharge the high, responsible duty
                  which rests upon me, as one of those who have been clothed with the sovereignty and power of my native State. I have not heretofore, as you will bear me witness, occupied one moment's time and attention of this body, and I assure you, sir, that I now enter upon the performance of this <PageNumber num={226} /> duty, with many misgivings of my capacity to
                  say anything that can be calculated in any degree to reflect even the smallest light upon subjects so momentous as those which not only agitate our own State, but this Union, from one extremity to the other.
                </p>
                <p className="p-in-sp">
                  I have come to this Convention, sir, to act as I may think proper, without pledges and without a platform. That word, of so much party significance, as it is sometimes elsewhere, can have no significance here, for all those great questions to which some gentlemen seem to have thought it necessary to allude, have, in my judgment, as mere party measures, no connection with the
                  deliberations of this body. I came here, sir, as I said, without pledges. I came, however, with one pledge, and that was—if my past life was to be a guarantee of what I would do here, that I would endeavor to serve my country and my whole country. I need not say to you that I have ever professed an ardent attachment to the Union of these States. I have loved it because of the
                  benefits, as I conceived, which it conferred upon the greatest, freest, and mightiest people upon the earth. I have enjoyed its blessings, and it was my dearest wish that those blessings might be transmitted to my children and children's children. Yes, sir, that was the wish nearest my heart. I have lived for it; I have stood by it; I have defended its great interests, and I have
                  ever felt as if I was ready to die with it and perish under its ruins, if perish it must.
                </p>
                <p className="p-in-sp">
                  Why did the Union first attract my love and attachment? It was not because I had any wish to connect my name, humble as it is, with the interests and destiny of a people so great as ours, and a Union sc, glorious as this was. No, sir, I loved it in my youth, and that attachment sprung from the same source as does that which the young man feels for the object of his first and earliest
                  affections, when she is capable of inspiring that feeling, which I know you and I have felt, Mr. President, not because of any outward attractions so much as because of her purity, her worth and virtue. Such, sir, is the attachment which I have had for this Union.
                </p>
                <p className="p-in-sp">
                  But, sir, the Union is dissolved. Dissolved; not one, two or three, but some six or seven States have united themselves together to form another Union South, called the Union of the Confederated States of the South, while Virginia yet remains a member of the Union, as it is. Then, sir, the question arises here in this condition of things, what position is Virginia to assume? Is she
                  to remain in that Union which has been dismembered thatUnion which is lost, as I fear, and lost forever, or is she to unite herself and her destinies with the States of the South in that Union to which I have already referred—or is she to declare <PageNumber num={227} /> her own sovereignty and stand independent as one of the nations, not of the
                  Confederated States, but as an independent nation ; Virginia, alone and unconnected with any of the other States of this Union?
                </p>
                <p className="p-in-sp">
                  Mr. President, actuated by the love and attachment which I have had for the Union, when I saw the evidence before me as it appeared in the political horizon, that the danger which now impends over us was coming upon us, I looked around me and endeavored to find if there was any healing balm for those wounds that had been inflicted upon us, in that Constitution which you and I, Mr.
                  President, upon other occasions have sworn to support. I have looked to the instrument of the Union itself—the Constitution—and I find there a provision for the amendment of that Constitution to remedy any evils under which the country may labor. And, sir, I find that the attention of the people of the whole country has been called to it in the Farewell Address of Washington. I will
                  now call the attention of this body to that language and to the remedy to which I have referred.
                </p>
                <p className="p-in-sp">
                  "To the efficacy and permanency of your Union, a Government for the whole is indispensable. No alliance, however strict between the parts, can be an adequate substitute; they must inevitably experience the infractions and interruptions which all alliances, in all time, have experienced. Sensible of this momentous truth, you have improved upon your first essay, by the adoption of a
                  Constitution of Government better calculated than your former for an intimate Union, and for the efficacious management of your common concerns. This Government, the offspring of our own choice, uninfluenced and unawed, adopted upon full investigation and mature deliberation, completely free in its principles, in the distribution of its powers, uniting security with energy, and
                  containing within itself a provision for its own amendment, has a just claim to your confidence and your support. Respect for its authority, compliance with its laws, acquiescence in its measures, are duties enjoined by the fundamental maxims of true liberty. The basis of our political systems, is the right of the people to make and to alter their constitutions of Government : but
                  the Constitution which at any time exists, till changed by an explicit and authentic act of the whole people, is sacredly obligatory upon all. The very idea of the power, and the right of the people to establish Government, pre-supposes the duty of every individual to obey the established Government."
                </p>
                <p className="p-in-sp">
                  I will now quote from another distinguished statesman, scarcely less distinguished, indeed, than Washington himself—a statesman who was <PageNumber num={228} /> known as the father of the Constitution. I am not now going into a discussion of the right of secession on the part of any State of the Union. I do not consider that it is necessary to discuss
                  that question now, and I will defer any discussion upon that particular point until the question comes to be considered by the Convention directly. I shall now proceed to quote the opinion of Mr. Madison :
                </p>
                <p className="p-in-sp">"Should the provisions of the Constitution as here reviewed, be found not to secure the government and rights of the States, against usurpations and abuses on the part of the United States, the final resort within the purview of the Constitution, lies in an amendment of the Constitution, according to a process applicable by the States.</p>
                <p className="p-in-sp">
                  "And in the event of a failure of every constitutional resort, and an accumulation of usurpations and abuses, rendering passive obedience and non-resistance a greater evil than resistance and revolution, there can remain but one resort, the last of all; an appeal from the cancelled obligations of the constitutional compact to original rights and the law of self-preservation. This is
                  the ultima ratio under all governments, whether consolidated, confederated, or a compound of both; and it cannot be doubted that a single member of the Union, in the extremity supposed, but in that only, would have a right, as an extra and ultra-constitutional right, to make the appeal."
                </p>
                <p className="p-in-sp">
                  Now, sir, whose doctrine is that? Who is it that appeals to you in such earnest terms to remember that the Constitution provides for its own amendment? It is that distinguished man whom but to name is to call up a feeling of reverence in the heart of every man in this assembly. James Madison himself has held this language, and he has clothed it in characters almost of living light,
                  so that he who runs may read.
                </p>
                <p className="p-in-sp">
                  I believe, then, in the doctrine of self-preservation, in the right to defend my life, my liberty, my honor and my property—in the right to defend the life, liberty and property of my wife and children. Yes, sir; it is one of those rights that He who made and fashioned us as we are, has never taken from us; but it is an extra and ultra constitutional right which as a citizen remains
                  with you, because you have the power as one of his creatures to preserve that which He gave you. Then, sir, this is my doctrine. Still, I hold in the highest respect gentlemen around me who hold a different opinion, and I tell them sincerely and frankly as I feel that I believe the doctrine which I maintain leads to the same results and will accomplish the same purpose that they
                  desire. Will you tell me I hold this doctrine, and if I proceed to carry it out, that I will be hung as a rebel to the government? Will you tell me that I am liable to be tried for treason, because I entertain these opinions, which were <PageNumber num={229} /> the doctrines of Washington and Madison? If that makes me a rebel, Washington and that
                  illustrious man were rebels also. But let me ask gentlemen around me, who hold the opposite opinion, are you not liable to be arrested as traitors also, if you hold the doctrine of secession. I should like to know what better security these secessionists can have than the doctrine of the right of revolution. It was the very doctrine of the right of revolution that brought us through
                  that glorious struggle which gave us our independence, and which made us a great, mighty and a free people—that doctrine upon which I will stand side by side with you in vindication of the honor, the integrity, the rights and the privileges of this glorious old Commonwealth and of the South.
                </p>
                <p className="p-in-sp">
                  But, sir, I desire to see peace and harmony established in this Confederacy, or this Union as it was; because, as I have said, I loved the Union, I loved it as the proudest heritage that could be bequeathed by parents to their children; I loved it for the glories of its past history for that name made illustrious by those deeds which are emblazoned upon all that is connected with the
                  Revolutionary struggle—that of the Father of his country; I loved it, too, because its fair daughters, those of Massachusetts and those who lived upon the banks of the Connecticut, with their sisters of Virginia, as well as those all over this broad land have bedewed with their tears the shrine of the Father of his country at Mount Vernon, have knelt around that hallowed spot and
                  sent up their aspirations together to the Home of the Most High for the welfare and protection of this great people.
                </p>
                <p className="p-in-sp">
                  That was the doctrine which you and I, Mr. President, learned when we were young men. That was the doctrine which some of those around me learned, whose venerable appearance gives an imposing dignity to this assembly. I say, sir, that was the doctrine taught me by the language of the Father of his country. Pardon me, then, if I say I rely upon the teachings of Washington, rather than
                  adopt the teachings of those of the present day, who discard the sentiments that were uttered by the very men who built up this, the mightiest fabric of free government that ever existed upon the face of the earth.
                </p>
                <p className="p-in-sp">I will now submit, in connection with the remarks I intend to make, and for the consideration of this Convention, some resolutions which I have prepared as embodying my views :</p>
                <p className="p-in-sp">
                  Resolved, That in view of the provisions of the 5th article of the Constitution of the United States it was eminently wise and proper that the Southern States should, in concert, have proposed amendments to the same so as to have effectually secured a settlement of the present unhappy difficulties which disturb the peace of the country.
                  <PageNumber num={230} /> Resolved, That it is the duty of Virginia now to invite the co-operation of all the slave-holding States, upon the border, so as to provide measures for their concurrent action hereafter.
                </p>
                <p className="p-in-sp">Resolved, That Virginia is attached to the Union as it was, but that it does not protect her rights as it is—that it becomes her people in Convention assembled to look to every remedy for relief, and then to provide, also, in the event of a failure, for the future relations she is to occupy, having a due regard to her position as one of the States of the South.</p>
                <p className="p-in-sp">
                  I say, then, in connection with what I have here read, it was my ardent desire, when this controversy was commenced, that an appeal should have been made by the Legislatures of all the Southern States to the Northern States, in Congress assembled; and, sir, under the 5th Article of the Constitution which provides for its own amendment, such proceedings could have been had as would
                  have insured, perhaps, peace, harmony and quiet to our country. Now, sir, in connection with the resolutions which I have read as a part of my remarks, I respectfully ask the attention of the Convention to the article itself.
                </p>
                <p className="p-in-sp">
                  "The Congress, whenever two-thirds of both Houses shall deem it necessary, shall propose amendments to this Constitution, or, on the application of the Legislatures of two-thirds of the several States, shall call a Convention for proposing amendments, which, in either case, shall be valid to all intents and purposes, as part of this Constitution, when ratified by the Legislatures of
                  three-fourths of the several States, or by Conventions in three-fourths thereof, as the one or the other mode of ratification may be proposed by the Congress."
                </p>
                <p className="p-in-sp">
                  Here then are those printed directions to which the Father of his country, in his last moments, called the attention of the people, when he laid his hand upon them and gave them his parting blessing. Sir, I have loved this Union, and if I could make it as it was designed to be by those who instituted it, this day I would fling to the breeze the stars and stripes, and let them float
                  in triumph from the Aroostook to the Rio Grande, from the Atlantic to the Pacific.
                </p>
                <p className="p-in-sp">
                  But, sir, I said that the Union was dissolved. Dissolved how? Not by a trial of the remedies which the Father of his country prescribed, in the printed directions to which I have called the attention of the Convention. I say that there is no warrant in the constitution itself by reason of anything contained in it by which a State has the right to secede from this Union at all.
                </p>
                <p className="p-in-sp">
                  But, Mr. President, if I were to refer to the causes which have produced the present state of things, I should certainly depart from <PageNumber num={231} /> that line which I have thought proper to pursue in the views which I shall endeavor to present to this Convention. A reference to the history of the unfortunate difficulties in which we are now
                  involved might call up reminiscences in the minds of some of this body that might be unpleasant to remember. Let us then bury this question as to what causes have produced the present difficulties, and let us rather act the part of the wise men in the temple who took care of the jewels without stopping to inquire who had applied the torch that produced the conflagration. I say, sir,
                  that all such inquiries and all such allusions as these are irrelevant upon this occasion. Let us come up to the great work before us and endeavor so to discharge our duty here as that we may have the consciousness that we have done all that duty and love of our country required us to do.
                </p>
                <p className="p-in-sp">
                  In referring, as I shall not, to the causes which have produced this unhappy state of affairs, we cannot but be reminded today as we sit in this hall, that Abraham Lincoln is President of the United States, or will be in a few days—to fill the place which the father of his country once occupied. It becomes me here to say in connection with this painful subject that I have anticipated
                  better things of Mr. Lincoln than those foreshadowed in the speeches which he has made in his journey to Washington. It becomes me to say, that I knew him well some 12 years since. I am perhaps the only member in this hall, or the only person perhaps within the sound of my voice who does know him well. It was my privilege, as it was my duty to serve with him in Congress upon the same
                  committee for two years. Whatever may have been our associations in some respects, there is not a gentleman within the sound of my voice—a member of this or any other deliberative body, who does not know that from the kind of intercourse which exists between the members of a committee of any body, one is able to judge somewhat, not only of the strength of intellect, but of the
                  character and qualities of mind which those associated with him possess. Well, sir, I say—and I think it is due to him that I should say it—that I regarded Mr. Lincoln as industrious and sober—a man of fair talents, which I have no doubt he employed to the satisfaction and interests of his constituents; and I will say farther, that there was no man upon that Committee who worked with
                  more diligence and more faithfully discharged the duties devolved upon him; and he argued with great ability many of the complicated questions which arose for discussion among us. I have said this of him as I have said it upon other occasions; and however much I may have deprecated his election as one of the greatest evils which could befall the country, and however
                  <PageNumber num={232} /> much I strove to avert that evil, yet I have spoken of him and of my acquaintance with him, as I would of any other individual with whom my associations with him had made me acquainted. But, sir, I must say, if the accounts which I read of his speeches since he set out upon his journey from Springfield to Washington be correct,
                  that I was deceived in the character, the pretensions, the abilities and the strength of intellect of Abraham Lincoln. It is revolting to every sense of right, that a man, elevated to so distinguished a station as this, should have made the exhibition which he has of himself, at every station and at every point where he has been called upon to address his fellow-citizens. I had
                  supposed, from my estimate of his character, that he would at least have had that sort of knowledge of himself, and of those around him, as would have induced him to keep silent, but I must confess now, that I have not even the hope of his silence in the future, and I am almost ready to say, not even of his forbearance.
                </p>
                <p className="p-in-sp">
                  When we contemplate the fact as it is that Lincoln is President of the United States, elected as he is upon a sectional platform, with these questions distracting and disturbing the country as they do, the inquiry may very properly be made, where is Virginia to go? As I said a while since, I do not now propose to discuss the question of that peculiar doctrine entertained by some in
                  regard to the right of secession. The evil is near upon us, and the question presented to our minds now is what shall be the course of this old Commonwealth of ours? I ought to have said, in connection with the remarks I submitted in relation to Mr. Lincoln, that he is not the only man about the city of Washington, who, at this time, seems to have been possessed of a strange sort of
                  hallucination, that there was danger of force being used by Virginia and Maryland, to prevent the inauguration of Mr. Lincoln. I do not believe that there is within the broad limits of this Commonwealth, a man who is entitled to the claim of ordinary intelligence, who has entertained the idea of preventing the consummation of the inauguration of Lincoln by force. While Virginia then
                  has entertained no such idea, she can never fail to remember that the 4th of March is in the calendar; that it is a day which has called forth, unhappily for us, the exercise of that power, which belongs to the Government of calling to its aid and service her own war-worn veteran and soldier, Virginia's own son, whom the State of Virginia has deemed worthy of her thanks, and as
                  worthy to wear a sword—patriotic as I believe him to be, and as honest as his purposes were—it is unfortunate in the distracted state of affairs as they exist at this time, that General Scott was called to the city of Washington. I will pledge to Abraham Lincoln my <PageNumber num={233} /> right hand, that there is not a man within the limits of this
                  State who intends to prevent by force the consummation of his inauguration. Then, I say, it was unfortunate that this thing should have been done—to add excitement to that which already existed.
                </p>
                <p className="p-in-sp">
                  I only say, sir, in relation to the subject, as I am now speaking in reference to that particular point, that while no such thing is contemplated, and no such purpose is entertained, yet whenever the power of that army in Washington or elsewhere is brought to bear upon this Commonwealth under the guise of executing the laws by force in opposition to the will of this people, all the
                  sympathies of this Convention will be aroused for the honor of the State, and they will stand here united as one man. I say here, if any coercion were attempted to be used upon this subject, while this State was deliberating, if any force were to be applied so as to overawe our deliberations, either directly or indirectly, there is not a man, I believe, in this Convention, who would
                  not feel that his own rights were assailed. I do not care what you may say about the theory that the government must execute its laws. I say that when a great and free people, as we are, are deliberating upon a question of life or death, we do not intend to stand by and see their rights trampled upon under the pretext that the government has the power under the Constitution of the
                  United States.
                </p>
                <p className="p-in-sp">
                  But we are to be reconciled, as we are told by some gentlemen, and we are to go out of this Union upon the idea that we are to be taken care of in the Southern Confederacy. How is this thing to be accomplished? I now approach this subject in no spirit of captiousness—I make no allusion to those gentlemen, who but a few days since addressed this Convention in a manner that must have
                  been acceptable to every one who heard their arguments, however much any gentlemen here may have differed from them. They came here clothed with an authority which we were bound to respect, and for their gentlemanly deportment and bearing which they exhibited here and everywhere in their intercourse with Virginians, I will say, that whatever destiny awaits me, whether I am to be
                  separated from them or united with them, I shall ever entertain for them the highest respect.
                </p>
                <p className="p-in-sp">
                  I ask you whether the argument which has been addressed to you from other quarters is one which is to be adopted without grave and serious consideration. Go into a confederacy of the South! What is the argument which gentlemen bring to sustain it? They set out with the proposition, that a people, to be free and to maintain their rights and liberties in all time, must have the same
                  interests, and that there must be a sort of homogeneousness in the population. What is the <PageNumber num={234} /> argument in favor of this idea? They tell you that all the slave labor
                </p>
                <p className="p-in-sp">
                  rill be required to cultivate the cotton of the South, and when they nave taken the slave by the hand and carried him to a Southern market, with the other hand they would supply his place with the Yankee. That is the argument which has been employed by some of those who advocate a Southern Confederacy. Does not the argument destroy itself? They have not told us, in all the arguments
                  addressed to us, that the people whom they propose to bring here are not congenial, in their disposition and nature, to the people of the South. What, sir, reconcile Virginia to this process, which they propose to inaugurate, and by which all our slave labor is to be carried off! They tell us, in order to keep our slaves as our property, and prevent them from going over the border,
                  it will be necessary to establish a line of military posts all along the banks of the Ohio river. Is this the argument that is to be addressed to Virginians? Excuse me, Mr. President, and gentlemen of the Convention, if I cannot appreciate an argument of this sort.
                </p>
                <p className="p-in-sp">
                  Have we not been solemnly warned by some of our wisest statesmen to be jealous of these standing armies? We are told that this is to be the destiny of this great State; that in one alternative your property is to be protected by the confederated States establishing upon your borders a line of military posts, and lest that argument might not avail, they then tell you that in process
                  of time your whole State will be depleted of its slaves. Cotton they have told us is King; but when they have tauntingly proclaimed it—I make no reference to the Commissioners have they told us how long his Majesty will remain upon the throne? While they have proclaimed that cotton was King, they have altogether failed to tell us that King Cotton has a rival, and a mighty rival, too,
                  elsewhere. They have failed to tell us that the empire of Great Britain —a power to which, for purposes of conquest and subjugation, Rome, in the height of her glory is not to be compared—a power which has dotted the surface of the globe all over with her military possessions, and whose morning drum beat following the sun, and keeping time with the hours, circles the earth daily with
                  one continuous sound and strain with her martial airs; they have failed to tell us that this mighty power, which never makes a treaty without regard to her own interests, has now extended her acquisitions and made treaties with nations that can supply this article of cotton.
                </p>
                <p className="p-in-sp">
                  I want to call the attention of the Convention, for a moment, while upon this subject, to some statistics which I have in reference to it, and which have an important bearing upon the great interests of the State of Virginia, and more particularly the interests of the South, <PageNumber num={235} /> so far as the cotton question is concerned. These
                  statistics go to show that Great Britain produced of manufactured articles from this raw material, in the shape of cotton cloth, two thousand millions of yards. How much of this do you suppose comes to the United States? She sends here 177,000,000 of yards, while she sends to her East Indian colonies the enormous amount of 500,000,000, one quarter of the whole amount she makes. Now,
                  sir, is it to be supposed, when she has established her empire in India, when she has placed her foot upon the Celestial Empire, and where, by the force of her arms, she is about to make the people of the sun contribute to the support of her manufactures in Manchester—that she is a proper guardian for the interests of Southern men and Southern rights and Southern institutions? How,
                  sir, will the English Government extend her fostering care over the Southern Confederacy? A nation, permit me to say, with all the kindly feeling which I have towards it, which, as has been said by some writer upon the subject, will make good her record upon the abolition of slavery throughout the world. As to the opinion which she holds upon that subject, you need but refer to the
                  account as published in the papers of the treatment extended to Mr. Dallas, our Minister to England, some little time since, at a public entertainment, to whose kindness, courtesy, and integrity and great worth as a private citizen and as a public man, some gentlemen I see before me can bear witness from their intercourse with him. Even more remarkable than any man I see in this
                  assemblage, he was made to feel in the presence of representatives of the crowned heads of Europe, that he was the representative of a government where the institution of slavery existed, and which Wilberforce held up to the reprobation of the world. Will you talk to me, then, about the South looking to England for aid in her attempt to establish a Southern Confederacy?
                </p>
                <p className="p-in-sp">
                  I see before me a gentleman from the city of Petersburg, and I know he will excuse me for calling his attention for one moment to that grave interest with which he has some acquaintance. Let me ask this gentleman, and other gentlemen of the Convention, who are interested in the culture of the article of tobacco, whether they can expect any sympathy from England, in any aid she may
                  extend towards this article of commerce? Let me tell you that that mother country, England, whose protection is to be invoked for the South, levies the enormous amount of 800 per cent upon this article, before it goes into the mouth of the consumer.
                </p>
                <p className="p-in-sp">
                  I would speak here, sir, of another great interest of Virginia, which is represented by a friend who sits near me. But before I do so, permit <PageNumber num={236} /> me to say, that until within a few years past, but a few months, I should say, I had a very inadequate conception of the power, wealth and influence of this glorious old Commonwealth of
                  Virginia. Familiar as I was with her history, and with her growth and advance in all that makes a great State, I did not dream that Virginia had such resources as she has, and I feel prouder of her now than I ever did before—proud that I can call her mother ; and, sir, as was eloquently said by my colleague upon the other side, "her destiny shall be my destiny; where she goes, I will
                  go ; and if I perish, I will perish only upon her bosom, and be buried with her."
                </p>
                <p className="p-in-sp">
                  But, sir, I was about to refer to another interest—an interest peculiar to the county, which you represent, Mr. President, and to many other counties of the Commonwealth, I allude to the staple of wheat ; and then, too, there is an immense stock-grazing country in Virginia. Are these interests to be disregarded under the assumption that that Cotton is King and that every thing else
                  must bow with bended knee before it? I want tobacco, wheat and the interests of a grazing country to receive consideration in this grave question of what is to be the destiny of this people. Are you to be told that your wheat and tobacco, which you have taken from your farms, in whatever form it may be, cannot enter the ports of Boston, Philadelphia or New York, because we have made
                  them aliens and strangers to us? I ask you if Virginia would not place herself in a predicament she little dreams of at present, that would affect her most material interest, when she determines to go out of this Union. I want the Union as it was or as it was designed to be, that gives me the happiness and the privilege of going anywhere within its broad limits without a passport. I
                  want a Union that has sympathy with my rights and my interest. I want a Union that will protect my industry; I do not mean protect it by the imposition of duties, but which will allow that intercourse which should exist between every member of this confederacy, by which I can exchange without reference to duties my commodities for those which other people have to spare.
                </p>
                <p className="p-in-sp">
                  But, sir, I cannot dwell upon a subject of so much moment, because I shall consume more time than I had allotted to myself when I commenced my remarks. As I see some gentlemen before me who are capable of answering some of the questions I have put, let me ask them, if we are to go out of this Union without any regard to the North, and our affections are to be concentrated upon the
                  South—tell me, how is Virginia to get there? Some gentlemen tell us that they would not wait a moment—that Virginia must go now, now. How <PageNumber num={237} /> will she get to Montgomery, Alabama? Are you to negotiate for a transit route across North Carolina and Tennessee? Kentucky and Tennessee have not gone out, and I think that one of them has
                  determined that she will not go out. Suppose, Mr. President, when you go home you tell your constituents that we have made a Government by which Virginia is to become a part of the Confederated States of the South, and they ask you—"how, Mr. Janney, are you to get there?" Why, sir, you could not answer the question. You could not, as a statesman, go home and tell your people that you
                  had taken the chances of North Carolina following your lead. If we could negotiate and get through Tennessee and North Carolina, how are we to pass the frowning battlements of Fortress Monroe and the Rip-Raps? Suppose that you could pass them in safety—these forts that belong to the Government—are you to pursue a winding course along the coast of North Carolina, and to land at
                  Charleston? Why, sir, those pilots down about Elizabeth City would tell you that they could not take your vessel into the port of Charleston, until Charleston has her lights up. They will tell you that she has virtually blockaded her own ports against her own commerce, and the commerce of the world. Will your vessel land in Savannah? She might, but, sir, you would encounter these
                  fleets of the North. They would then be strangers to us, though they may have been our national allies in times past. Can we go through North Carolina and Tennessee to get to the Capital of the South? I want gentlemen to answer me this question. Sir, all these difficulties can be accommodated by the policy of a conference of the border slave States which my proposition suggests. That
                  is the way in which to treat our equals of the border States. If there is anything that could be calculated to throw obstacles in the way of an adjustment of the difficulties which would be likely to arise with North Carolina and Tennessee.<span className="note" id="Note32"
                  ><span className="noteLabel">1</span>This sentence is incomplete in the Enquirer. Perhaps the first sentence of the paragraph below should be joined with it.</span
                  >
                </p>
                <p className="p-in-sp">
                  It would seem to be the impression of some gentlemen here that, when Virginia will go, North Carolina and Tennessee will follow. I know, sir, the people of these States well, and I tell you that if there is anything above another that they dislike, it is the assumption of any superiority on the part of Virginia over them. I claim that they are our equals—I mean the States of North
                  Carolina and Tennessee. They are the equals of Virginia in this Union, her equals in the matter of State rights and State principles. I claim this for these States; I claim it, sir, for the smallest States; I claim it for the little States of Delaware and Maryland.
                </p>
                <p className="p-in-sp">And here, sir, I will observe that I saw the other day in a newspaper <PageNumber num={238} /> an article which brought to my mind some remarks which I heard repeated elsewhere. It relates to the elements which constitute a State :</p>
                <p className="p-in-sp">"WHAT CONSTITUTES A STATE?</p>
                <p className="p-in-sp">Not high raised battlements, labored mound, Thick wall or moated gate;</p>
                <p className="p-in-sp">Not cities, proud with spires and turrets crowned; Not bays and broad armed ports,</p>
                <p className="p-in-sp">Where, laughing at the storm, rich navies ride; Not starred and spangled courts,</p>
                <p className="p-in-sp">Where law and baseness waft perfume to pride; No, men—high minded men—</p>
                <p className="p-in-sp">With powers as far above dull brutes endued, In forest brake or den,</p>
                <p className="p-in-sp">As beasts excel cold rocks or brambles rude; Men, who their duties know,</p>
                <p className="p-in-sp">But know their rights, and knowing, dare maintain, Prevent the long aimed blow,</p>
                <p className="p-in-sp">And crush the tyrant while they rend the chain. These constitute a State</p>
                <p className="p-in-sp">And Sovereign law, that State's collected will, O'er thrones and globes elate,</p>
                <p className="p-in-sp">Sits Empress, crowning good, repressing ill."</p>
                <p className="p-in-sp">These, sir, are the elements of true greatness in States. Then, if you proceed upon the assumption of superiority for Virginia over any other State in this Union, by determining that you will go out without consulting them, you destroy the best opportunity that could be afforded for adjusting one of the most unfortunate difficulties that has ever existed.</p>
                <p className="p-in-sp">
                  Then, sir, I say that I am for a consultation with the Border States of the South. Then we can form our own Constitution and secure to ourselves our own sacred rights of persons and property. If six States of the South had a right to act, if they have a right to meet at Montgomery and adopt a Constitution which they say is alone to govern them—have we not a right, in like manner, to
                  consult together in the City of Lexington in relation to our rights? I have no idea myself, sir, of being compelled to go anywhere. I will only consent to go where my interest lies. If Mr. Lincoln should tell me that I would be compelled to yield unqualified obedience to him, I would point him to that proud flag which now floats over this house—a flag which has upon one side a
                  shield, and a virgin upon the other. The purity of the one, and the strength and durability of the other, will be ever sufficient to protect every citizen of Virginia, native or adopted, against any coercive <PageNumber num={239} /> policy that Abraham Lincoln may attempt. We, sir, should feel a perfect sense of security in our own strength, although we
                  should hear the notes of the muffled drum that marked the tread of those who came to call us to execution.
                </p>
                <p className="p-in-sp">
                  Notwithstanding this power and prestige which all will be ready to concede that Virginia possesses—notwithstanding her great resources —I ask you if she is to precipitate herself into a connection with her sister States of the South, upon the idea that they alone hold interests in common with us? Do you know that you would be welcomed? Do you know, although it is proposed that you
                  should unite with the seceded States, that you would be received with that kindness and cordiality which every Virginian would expect to meet when he went into the house of his friend? It has been the practice of gentlemen here, to read newspaper extracts to sustain their arguments. I trust that I may be permitted to do so, though I confess that, as a general thing, these extracts do
                  not convey any decided proof of the facts which they undertake to treat upon.
                </p>
                <p className="p-in-sp">I will, however, read for the information of this Convention, an article from the Charleston "Mercury," which is regarded as the organ of the Southern States, at least of the State of South Carolina.</p>
                <p className="p-in-sp">Speaking of Virginia, the "Mercury" says :</p>
                <p className="p-in-sp">
                  "She is completely demoralized in the estimation of the South; and no Southern State, intent on vindicating her rights and preserving her institutions, would go into a conference with her. She has placed the Union above the rights and institutions of the South, and will only seek a conference with the Southern States in order to bring them down to the level of her fatal Union policy.
                  Virginia and the other frontier States may as well at once understand their position with the cotton States. They are not expected to aid the cotton States in protecting themselves and redeeming their liberties. They will practically aid the Northern States in attempting to obtain in the South an acquiescence in the rule of Abolitionists at Washington. The Southern States, however,
                  will disregard their counsels. They want no conference but in the Convention which will assemble to frame the Constitution, and complete the organization of a Southern Confederacy. They intend to secede from the Union, and construct a Union among themselves."
                </p>
                <p className="p-in-sp">
                  This is the language of one of the leading journals of one of the leading States of the confederated Republic of the South. These were the sentiments then entertained by those who were a part and parcel of the State. I will not say that they were the sentiments of those gentlemen <PageNumber num={240} /> who appeared before this Convention, for the
                  frankness and courtesy which marked the demeanor of those gentlemen, and the character for sincerity to which they are entitled would repel any such idea.
                </p>
                <p className="p-in-sp">
                  But, sir, should we go into that Southern confederated nation, can anybody tell us how long we are to remain there? They tell you that the Government at Montgomery has adopted a temporary Constitution or form of Government. Has it attracted the attention of those around me that that very Constitution has laid the foundation for an interminable strife in relation to this very question
                  which is the subject matter of dispute here. The Constitution of these confederated States of the South, as I understand, provides that their Congress shall have the power to regulate the slave trade between the States, and the power is reserved by Congress to forbid it altogether. The very thing against which we have been contending, as one of the powers of this General Government,
                  of which we are members, is to be incorporated into the Constitution of this new Union of the South. I ask under this view of the case, if we are to rush out of this Union, in hot haste, without knowing where we are to go? I would hope not. I love the Union, not as it is, but as it was, and as it was designed to be, and I would try and have the abuses, of which we alt complain,
                  corrected. We must have the assurance of the North to that effect, and they must give us security for the future, if they cannot give us indemnity for the past. How, then, is such a result to be accomplished? Let the border States make a Constitution which shall protect their property in all time, and then invite all the States of this Union to come and unite with them—invite
                  all—stop not at the Hudson, as some gentlemen would do. I say I would invite all, but they must come in upon the terms which we prescribe.
                </p>
                <p className="p-in-sp">
                  But gentlemen talk about a diversity of interests as being irreconcilable to such an union. Why, sir, the philosophy of British statesmen has long since shown that a diversity of interests in a great republic or a great nation is what makes a people great and mighty. I do not mean a diversity in regard to a peculiar institution, such as slavery, but in reference to other interests
                  with which Virginia is connected as one of the members of this Union. There cannot be in all respects an identity of interests. There are diversities in families, in counties, in States and in nations. And let me ask, because of diversities in the social or political system would you, therefore, make this an argument against the system itself? Why, sir, carry out such an idea and you
                  would destroy every thing that you hold near and dear, and you would <PageNumber num={241} /> destroy many of the relations that you now hold most sacred. Such a feeling that a diversity of interests could not exist here when applied to our State, to the interests of the East and the West, would be calculated, above all things, to stir up strife, and
                  that kind of feeling which should not exist between citizens of the same State, having the same common destiny. I would be no more willing to part with Maryland. with Western Virginia, with Kentucky, with Tennessee or North Carolina, than I would be willing to part with any dear and honored friend who had accompanied me thus far along the journey of life, and to whom I had been
                  indebted for the many good courtesies which had strewn blessings in my path.
                </p>
                <p className="p-in-sp">
                  I was proceeding to say something of the provisional government of the South, and I would ask what assurance is there that such provisional government is to remain the government of the Confederated States of the South? Is it not a government that is only temporarily established? Have these gentlemen in the South, who complain of the North, and rightfully complain, too—a complaint
                  which I do not stand here to defend—ever asked themselves the question whether they have always been true to the interest of the South, true to that Constitution which these gentlemen tell us in this confederated republic of the South gives assurance to the people of Virginia, that her rights in all time are to be protected? Have they been under this Constitution? In defiance of the
                  allied navies of England and France and our own country, although we have a law here against the importation of Africans from abroad, we have found that they have been landed upon Southern soil, and we have found that this law, written upon the statute book, almost with letters of blood, and with the severest penalties attached to it, that could be inflicted, has been violated, and
                  that when juries have been found unwilling to convict, judges had it not in their power to punish.
                </p>
                <p className="p-in-sp">
                  Let me ask, is Virginia to be secured against such violations of law by the making of a new confederated Southern Republic, when she has not been able to protect her rights upon this great and vital question? We have already been informed that this was one of the great subjects for discussion—one of the great interests which was to be looked after in future—the re-opening of the
                  African slave trade. I ask gentlemen around me, when we get into the confederated Union of the South, and when Virginia, with the other border States, will be as five to nine, I ask if it be not in the power of that confederated Union of the South, to alter that Constitution, though she has told us by her temporary government that the slave trade is to be forever in
                  <PageNumber num={242} /> all time prohibited? Will not their interest lead them to go to the market where they can buy the cheapest and where they can sell the dearest? Have not these gentlemen always preached this doctrine and called our attention to it? Where, let me ask, is the market wherein you can buy the cheapest? It is in the valley of the Congo
                  ; and the market wherein you can sell the dearest, is in the valley of the Mississippi. With these competing forces by which we are surrounded, with a cordon of free States here upon one side, and a repelling force upon the other, Virginia will be placed in a position to perform the office of a baggage master upon a railway train, who has no interest in the general luggage, except to
                  perform the bidding of those who employ him. Virginia is to stand here, as we are told, and help the South to repel the forces of the North. She is to stand guard at the military posts on her borders, to watch the interests of the South. She is to perform this duty as an agent for the benefit of others, at the instance, as they tell us, of the confederated States of the South.
                </p>
                <p className="p-in-sp">Let me present the question in another point of view. After referring to the re-opening of the African slave trade, and the competition which must necessarily be induced by the reopening of that trade, let me ask, in all candor, how is the security of Virginia to be promoted?</p>
                <p className="p-in-sp">
                  I have already indicated to you what my course in the outset would have been—that I would have asked these people of the North to have met Virginia in consultation, as provided by the 5th Article of the Constitution. If they had not responded to the invitation, and the slave States had sent their Commissioners to this Convention and proposed amendments to the Constitution, how would
                  you stand? The invitation being extended to, and not responded to by the North, the whole South would to-day stand upon the vantage ground, and the very fact of the North having refused, would have rendered us in the South united, and therefore invincible. You would have presented here today the great, high and noble moral spectacle to the world, not of a divided people, but of a
                  people united everywhere, from the banks of the Ohio to the capes of Florida. If the demand which we would unitedly make, should be withheld from us, after we asked the North, in the manner and form pointed out by the Constitution, Virginia would not present those divisions in her councils which it is manifest now exist. Moreover, this unity of sentiment on her part would have
                  restored peace and confidence long since, instead of the general uncertainty which now prevails.
                </p>
                <p className="p-in-sp">
                  I believe in the co-operation of the border Slave States now, as I have believed from the beginning, and do now believe in the cooperation <PageNumber num={243} /> of all the States. But, as I said before, that co-operation must be accomplished upon a plan that is right and just to all the States. If the seceded States have co-operated among themselves,
                  I ask whether we should not assemble together and determine whether Virginia will go off alone, or whether we will take into our company the States of North Carolina and Tennessee and the other border Slave States? Tell me not that there is no time now to talk about this thing; that the time for action has arrived. The time for action? How? Why, sir, as I have endeavored to show, the
                  month of March is no more in the calendar, so far as the application of any force to our deliberations by the government is concerned, than the month of May last. The accession of Lincoln to power cannot and ought not precipitate our deliberations here. If it was necessary, we might deliberate here day after day until the 4th of March, 1862, without being affected by any apprehension
                  of force or disturbance through the agency of the Federal Government. I have no idea of being hurried under any influence of this sort. The fact that Mr. Lincoln is President of the United States, while it may enter our minds and form part of our deliberations, has nothing to do with precipitating our action. No, sir; and I will here observe, that a singular coincidence of opinion
                  exists between certain gentlemen who are in favor of precipitate action, and the great high priest of freesoilism, in reference to the probability of hostilities. I merely refer to this in order to disabuse the public mind of an idea that seems to have taken hold of it, that there is danger of an attempt to coerce the cotton States. These States being now united, we insist that they
                  cannot be forced into submission, and that an attempt to coerce them must not be made. Five millions of people, of whom nearly one third, if not more, are able and willing to shoulder their muskets, can never be subjugated while fighting round their hearth stones. If eight States, having five millions of people, choose to separate from us, they cannot be prevented or withheld from so
                  doing by coercion. Why, sir, those who had never seen the article to which I shall now call the attention of the Convention, would suppose that it emanated from one of the most decided secessionists within the sound of my voice. But it is the doctrine and declaration of Horace Greeley himself. It is the language which that philosopher and editor employs, and which he puts into his
                  journal, which is read by about 500,000 persons.
                </p>
                <p className="p-in-sp">He tells you that an attempt to coerce five millions of men is preposterous and absurd, and that it can never be done.</p>
                <p className="p-in-sp">
                  Horace Greeley says : "But if the Cotton States generally unite with <PageNumber num={244} /> her in seceding, we insist that they cannot be prevented, and that the attempt must not be made. Five millions of people, more than half of them of the dominant race, of whom at least half a million are able and willing to shoulder muskets, can never be subdued
                  while fighting around and over their own hearth stones."
                </p>
                <div className="speaker" id="sp297"><span className="persName">MR. MORTON</span>—</div>
                <p className="p-in-sp">What is the date of the paper in which that extract appeared?</p>
                <div className="speaker" id="sp298"><span className="persName">MR. GOGGIN</span>—</div>
                <p className="p-in-sp">I think the paper containing it was published in November last. I don't pretend to know, nor have I undertaken to ascertain what are Greeley's opinions now. Whatever they are, I am prepared to say to my friend from Orange [<span className="persName">Mr. MORTON</span>] 
                  that they are bad enough. I merely refer to his opinions as given in this extract to show what the sentiment was among the party, of which this paper is the organ, at the time it was written. It is, I feel assured, a correct opinion, however much its author may vary from it now, under the force of expediency or party obligations. I have very little faith in him, and very little faith
                  in the sincerity or consistency of any of those who minister round the altar of the political fanaticism that prevails at the North.
                </p>
                <p className="p-in-sp">
                  But, sir, apart from this authority, we have before us the fact that the administration that is just going out of power has distinctly stated that it had no power to coerce a State. I have seen it stated that Mr. Lincoln and Mr. Buchanan have had an interview recently in the city of Washington, but whether they entertain the same opinion on this question of coercion, I am unable to
                  say. That will doubtless, soon be ascertained. I am satisfied that the feeling which animates every member of this Convention, is that with the power which belongs to Georgia, and the other States associated with her in the new Confederacy, that neither Lincoln nor Mr. Buchanan, nor any other President, will attempt to coerce them into obedience. As was very well said by a friend of
                  mine the other day, upon this floor, whatever may be our opinions as to the true interest of Virginia in the question of secession, she should be prepared for any emergency. I would tell the Convention in any event to prepare for action, because whatever difference of opinion may exist, this consideration should be, as I have no doubt it is, paramount. Differences of opinion should
                  pass away in view of this necessity like chaff before the morning wind.
                </p>
                <div className="speaker" id="sp299"><span className="persName">Mr. DORMAN</span></div>
                <p className="p-in-sp">If it be agreeable to the gentleman, I will move an adjournment, that he may have an opportunity of resuming his speech to-morrow.</p>
                <div className="speaker" id="sp300"><span className="persName">Mr. GOGGIN</span>—</div>
                <p className="p-in-sp">I will cheerfully yield. I do not desire to impose upon <PageNumber num={245} /> the Convention, and if it is their pleasure to adjourn I will cheerfully give way.</p>
                <p>The motion to adjourn, offered by <span className="persName">Mr. DORMAN</span>, was then put and carried.</p>
              </div>
            </div>
            <div className="day" id="vsc1965.v1.2.12">
              <PageNumber num={246} />
              <h2><span className="headingNumber">1.12. </span><span className="head">TWELFTH DAY</span></h2>
              <div className="dateline">Wednesday, <span className="date">February 27</span></div>
              <p>The Convention met at 12 o'clock, M., and was called to order by the President.</p>
              <p>Prayer by the Rev. <span className="persName">Mr. MINNIGERODE</span> of the Episcopal Church.</p>
              <div className="speaker" id="sp301"><span className="persName">Mr. FISHER</span>—</div>
              <p className="p-in-sp">Mr. President, before the consideration of the order of the day is proceeded with, I will offer the following resolution which will lead to no discussion.</p>
              <p className="p-in-sp">
                "Resolved, That a Committee, consisting of thirteen members, be appointed by the President, whose duty it shall be to enquire into the expediency of passing an Ordinance making an appropriation for the defence of the Commonwealth. And, if in their opinion an appropriation should be made, that they report such ordinance and the amount which ought to be appropriated."
              </p>
              <div className="speaker" id="sp302"><span className="persName">Mr. FISHER</span>—</div>
              <p className="p-in-sp">I desire to say that I offered that resolution because of the fact, that a bill appropriating a million of dollars for the defence of the State, which has passed the House of Delegates, is now in the Senate, and for some reason or other is not likely to be acted upon for some time.</p>
              <div className="speaker" id="sp303"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">I move to lay that resolution on the table.</p>
              <div className="speaker" id="sp304"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">I understood the gentleman to say, when he offered his resolution, that it would give rise to no debate. This seems, however, not likely to be the case and the Chair will, therefore, have to decide that the unfinished business of yesterday is first in order.</p>
              <div className="speaker" id="sp305"><span className="persName">Mr. FISHER</span>—</div>
              <p className="p-in-sp">My impression was, when I offered the resolution, that it would lead to no debate. I will ask, if there was not a motion made yesterday to take up the unfinished business?</p>
              <div className="speaker" id="sp306"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">Such a motion may be made by any gentleman who chooses ; but in any event, the unfinished business should come up as first in order.</p>
              <div className="speaker" id="sp307"><span className="persName">Mr. EPHRAIM B. HALL</span>—</div>
              <p className="p-in-sp">
                Mr. President, I move to correct the Journal of a few days past. On a motion to reconsider the vote by which a resolution offered by <span className="persName">Mr. CLEMENS</span>, in reference to reporting the debates,<span className="note" id="Note33"><span className="noteLabel">1</span>Something like "was approved" has been omitted here.</span> I am put in the negative, when I voted in the
                affirmative.
              </p>
              <div className="section" id="vsc1965.v1.2.12.1">
                <h3><span className="headingNumber">1.12.1. </span><span className="head">FEDERAL RELATIONS</span></h3>
                <p><span className="persName">The PRESIDENT</span> having announced that the unfinished business was in order,</p>
                <div className="speaker" id="sp308"><span className="persName">Mr. GOGGIN</span> said :</div>
                <p className="p-in-sp">Mr. President, I feel very much indebted for the kindness and courtesy of the Convention, in having adjourned over as <PageNumber num={247} /> they did yesterday, and thus given me an opportunity of resuming the remarks which I had not concluded when the Convention adjourned.</p>
                <p className="p-in-sp">
                  Before I proceed, sir, to say what I design to say upon this occasion, I think it proper, sir, to express my thanks also to the Reporters of this body, who have in the main so very accurately reported the remarks which I had the honor to present to this Convention yesterday. The general accuracy of the report, I think, is unquestionable. From sheer inability on my part, however, to
                  make myself fully audible to the reporter, I find that there are some inaccuracies, unintentional, I have no doubt, and which could not have been avoided; but I do not propose now, however, to detain the Convention by any reference to them.
                </p>
                <p className="p-in-sp">
                  On yesterday, sir, I presented in such a manner as I was enabled to do, some views which to my mind were important in connection with the great subjects which now distract the country. It is proper to say, that, in presenting these views, I did so in no partisan sense or with any purpose on my part to present these views as embodying the sentiments upon which I may act when called
                  upon to do so, if a state of things different from those now existing, should arise in the country. An erroneous impression seems to have prevailed in the minds of many in this Convention, and out of it, that it is the duty of the members of this body, like advocates at the bar, to advocate some favorite public principle, or, like a candidate on the hustings, seeking for the
                  suffrages of the people, to come here and maintain some fixed principle of action without regard to events and circumstances, which are now of almost hourly occurrence. For my part, sir, I come here with no feelings in my heart other than those which look alone to the maintenance of the honor of Virginia, and the peace and prosperity of the country at large. As I said yesterday, I
                  came here not to advocate any particular line of policy, but to endeavor to do what, under the circumstances, I might deem right and proper for the safety, the security, the prosperity and the happiness of this old Commonwealth; and in looking to that, sir, I felt that it was my duty also to look abroad and contemplate in the efforts which I should make here, the happiness and
                  prosperity of the whole country.
                </p>
                <p className="p-in-sp">
                  I came here then, sir, to do what I presume every gentleman in the Convention has in view, to endeavor to force a conviction upon the minds of men, by presenting the particular views which may strike them as best calculated to accomplish what is for the interests of all ; <PageNumber num={248} /> and by a comparison of views to elicit the fullest
                  information on the great question which we are assembled here to discuss.
                </p>
                <p className="p-in-sp">That, sir, has been my purpose ; and I would have it remembered by this Convention, sir, and by the country, that we come not here for the sake or purpose of making a constitutional government, or to adopt measures that are to bind the people of the State of Virginia. We sit here, as I understand, as that committee of twenty-one [the Committee on Federal Relations] does in the other room of this building, to compare opinions, to advise and consult with each other, and then to present to the great council the result of our deliberations, that they may ratify or reject it, as they think proper.</p>
                <p className="p-in-sp">
                  That is what I understand to be my duty, and I don't intend to be driven from it by any impression that may have been made in the mind of any one—that I came here as the advocate of any particular line of policy or any particular school of politicians. I shall endeavor, sir, to do, as I have no doubt every member of this Convention will do—act strictly in accordance with
                  circumstances as they probably shall soon be developed to us.
                </p>
                <p className="p-in-sp">
                  I have said, sir, that I have been the friend of the Union, I have said that I stood among those who believed that the Union is the true palladium of our liberty and of our prosperity; but I have indicated also, sir, at least I so intended, that whatever may be my devotion, respect and admiration of this Union, whenever the time comes for Virginia no longer to be a part of that
                  Union—when, having calmly and deliberately considered these great questions which now distract the country, she shall determine that she can no longer be a part of that Union, I would not only go where she goes, but I would be one of those that would lead her to where I believe it would be her duty to go. That, sir, I mean to do ; and it is useless to tell me that it is expected that
                  I am to pursue this or that course. I shall pursue the course, sir, which my conscience tells me is the course of the patriot and not of the politician.
                </p>
                <p className="p-in-sp">I endeavored yesterday to present some views in connection with the subject of the relations which the State of Virginia holds towards the other States of this Union, and the relations in which she may stand to the seceded States in particular in a commercial aspect.</p>
                <p className="p-in-sp">
                  Before proceeding to offer a few additional remarks upon the same subject, I will state again that I came into this body with a desire for peace, with a desire for harmony; but I say to you now, and to this Convention, that when the remedies to which we shall resort, and which I think are yet within our control, shall have failed to give
                  <PageNumber num={249} /> relief, I shall stand by my friend whom I see before me from the county of Greenesville
                 [<span className="persName">Mr. CHAMBLISS</span>] , and who holds opinions in many respects, opposed to those which I entertain.</p>
                <p className="p-in-sp">
                  But while I say this, sir, I say it is not my purpose to sustain, before this body, any fixed or foregone conclusion upon the subject of secession or any other policy: I said, yesterday, that there were rea eons, that there were facts, that there were circumstances connected with those Confederated States of the South, in the new position which they occupy, that should cause us to
                  pause and consider well before we should cause Virginia to take her stand with those States.
                </p>
                <p className="p-in-sp">
                  Sir, this morning I was enabled to avail myself of some important information concerning the products of the State of Virginia, which may serve here as a source of some enlightenment in the consideration of the subject of Virginia's interests in her relations to the Southern or Cotton States. I shall not, in referring to this subject, dwell particularly upon the resources of the
                  tide-water country, or of the middle country, or of the western country. I shall content myself with some general views in relation to one of the leading products of Virginia. and the influences likely to be exercised upon it by the present and prospective changes in our political relations. I beg gentlemen to reflect upon the facts which I shall submit to them before they decide to
                  act. I beg gentlemen to examine the heavy stakes involved to the tide-water region of Virginia—to the ports of Richmond, Petersburg and Norfolk, and other points before taking any precipitate action. You will find that there is a great and powerful interest in Virginia that requires your consideration, and I will briefly bring your attention to it in the course of my remarks.
                </p>
                <p className="p-in-sp">
                  Having no familiarity with the subject of commerce, I will leave to others more competent than I am, the duty of dealing with it in fuller detail than it is my purpose to refer to it now. I said, yesterday, that I represented a peculiar interest, and that, in fact, the interests of the whole State were peculiar. Her interests are peculiarly diversified. She appeals to no king cotton
                  ; she appeals to no particular trade ; she appeals to no particular interest to decide her destiny, as do the other States of the South, the Cotton States in particular. She, like a good mother as she is, embraces all her children. She looks around her, to the East and West, and considers alike the interests and the welfare of all. As one of those representing peculiar interest in
                  Virginia, I will state that while I am more immediately concerned in the welfare of that interest, I shall not be governed by any such <PageNumber num={250} /> consideration in the course which I may pursue in this Convention. I will produce a few facts in connection with this interest.
                </p>
                <p className="p-in-sp">
                  I find, sir, upon examination of an article, for which I am indebted to one who occupies an official position in this body—one, sir, to whom the State of Virginia perhaps is more indebted than to any other man in the Commonwealth, for having, from time to time, made such an exhibit in his annual, semi-annual and quarterly reports of the resources of the trade of Virginia, as serves
                  to enlighten her people and inform them correctly of statistical facts connected with their material interests—I find, sir, upon examination of that article, the following fact. But first I will observe that the gentleman to whom I allude as the compiler of this important statistical information, is the gentleman who occupies a seat on your right
                 [Mr. John Graeme, Assistant Clerk] 
                  . The facts to which I refer show that the export of tobacco from the port of Richmond alone, to foreign ports, for the year 1860, were 18,798 hogsheads, of which Liverpool took 2,389 hhds., and London 2,461; while the total amount of Leaf and Stems to the Northern ports, to Baltimore, Philadelphia and New York alone, was 7,170 hhds. To this is to be added a still larger item of
                  275,275 boxes and packages of manufactured tobacco sent to these ports.
                </p>
                <p className="p-in-sp">Now, sir, tell me that this great interest is of no concern to the people of the State of Virginia, because Cotton has installed itself King in another portion of this Republic! I tell you, sir, that I cannot be driven from my propriety by the arguments of gentlemen, however ingeniously they may be applied. I say nothing of what may have gone from your port, sir [addressing himself to <span className="persName">Mr. BRANCH</span>, of Petersburg] 
                  , or what may have gone from other ports. The statistics which I present, however, will be sufficient to give some idea of the great stake which Virginia has in this particular interest. From this it will be seen that these ports of the North are large purchasers of one of our great staples, and that it is by no means the interest of Virginia to cut off these people from all
                  connection with us. If this alienation should occur, where is Virginia to find a market for this article? I ask my friend from the county of Orange
                 [<span className="persName">Mr. MORTON</span>] 
                  , who represents a tobacco interest with myself, whether he will readily yield to the cutting off of these ports or not? But, knowing him as I do, I know that, however he may act in this matter, he will do what he believes to be for the best interests of his people and of the whole State. Now, sir, in connection with this subject, I want this Convention, I want the State of Virginia,
                  to understand, that if she goes out of this Union, if she determines upon any policy, it should be such a policy as would <PageNumber num={251} /> forever settle these difficulties. I want the people of Virginia to know, when they leave this Union, that they are to contract an alliance which is to give them that security which they have failed to find
                  in the Union in which they were.
                </p>
                <p className="p-in-sp">
                  Gentlemen talk about extracts from newspapers as furnishing no reliable information. Why, sir, as I said yesterday, how are we to obtain information on any subject, unless through the medium of the press? We have heard it frequently designated another palladium of our liberty; and yet we have heard it said that an extract from a journal is worthless; that it is no authority as to
                  matters of fact. I think that newspaper authority is entitled to the consideration of this House, especially when it is found to be one of the leading papers of the country, and one which reflects the general sentiment of the State and section in which it is published. I refer to the Charleston Mercury, and that paper has, within the last few days, used the following language :
                </p>
                <p className="p-in-sp">
                  "We admit that the Government is but provisional and temporary, and that, therefore, the features objected to may not be carried into the Permanent Government, and probably were never intended to be carried there. They were doubtless intended to conciliate the Border Slave States; and induce them to an early union. But the concern we have expressed was not unwarrantable. It may be
                  questionable whether, should the Border Slave States be induced to union by such attractive features of the Constitution, it would be proper to propose a change. It is to be doubted whether, when they shall have entered, it will be possible for the Cotton States to make any changes to which they may object; and, as we would certainly lament the final adoption of the policy objected
                  to, as we would lament a constitutional recognition of a protective policy and a constitutional brand upon the institution of domestic slavery, we think it eminently important that those who deprecate those measures should make the efforts necessary to defeat them."
                </p>
                <p className="p-in-sp">
                  Here, sir, we have it directly from that journal, that those men at the South who entertain certain opinions on the question of Government, which they would have us adopt, merely because they have embodied in their provisional Constitution a clause prohibiting the African slave trade, defer to our prejudices and interests to the extent of adopting that clause with a view to expunge
                  it after they should have secured our co-operation in their new scheme of government. To be sure, this clause constitutes a very attractive element in the Constitution; but it is declared that this new Congress shall have <PageNumber num={252} /> power to regulate the whole subject of slavery ; and who doubts but that the prohibitory clause, in relation
                  to the African slave trade, which, as the Mercury states, was embodied in the Constitution merely to induce the Border States to join in the new Confederacy, would be stricken out when the interests of those States demanded that policy, and when it should have accomplished the purpose which first led to its enactment. I said, that while I was for the restoration of harmony and of
                  peace; yes, sir, if peace cannot be obtained upon honorable terms to the State; if her rights are not only fully recognized but established, I shall be willing, not only to go with Virginia, but to lead her to her duty. But before I will agree to do so, I will see that those measures which the Legislature inaugurated and adopted by a unanimous vote, have failed to meet the ends for
                  which they were designed. I allude to the resolutions inaugurating the "Peace Congress," now in session in the city of Washington. I am willing to await the report of that Conference, and I say that I am looking with anxious interest that something may be done to restore harmony; and I say now if that can be accomplished, I will hail it as a glorious event; I will rejoice at it, and
                  will give it my entire and willing sanction. But it must be no Cabinet affair; it must not, sir, to use the language employed on the other side of the House
                 [referring to <span className="persName">Mr. GOODE</span>, of Bedford] 
                  , it must not be peace, peace, when there is no peace. Whatever measure of adjustment may be determined upon, must be a measure in which the North shall not only say "we are ready to give you your guarantees, we are ready to give you a measure embodying a constitutional pledge that this subject of slavery shall not be agitated again in Congress ;" but they must speak through their
                  President. We want something more than a declaration of that sort. We want that man, who is to be installed in a few days President of the United States, to come up to his duty and to tell the country that, though he was elected upon the principles of the Chicago platform, yet, that these principles must be abandoned in his administration. If it is true, I admit, that it is difficult
                  for a man, under such circumstances as he finds himself placed in, to declare in advance what he intends to do. There is not a man among us who, if he were elected upon certain principles, would, before he was inaugurated, abandon those principles and say to the world that he was wrong in the avowal of such principles. But when Mr. Lincoln gets into the position to which a part of
                  the nation has called him—for an election, more sectional in its character, was never had in any State or country—if he had the magnanimity to declare, "I believe this Union cannot be preserved—I will surrender here now all <PageNumber num={253} /> those principles embodied in the Chicago platform, which look to interference with slavery in any way
                  whatever—I will proclaim now, as President of the United States, inaugurated as I am and having taken an oath to support the Constitution, that I will not only see that that Constitution is supported and maintained to the last, but I will here lay down the weapons of my rebellion against it"—I say if he had the magnanimity to make this proclamation, he would immortalize himself and
                  render himself the greatest benefactor of the age. But, sir, I cannot, I dare not expect such a concession as this. But, I say, sir, that there are men who have sacrificed themselves for the good of their country; there have been men in our own land and in other countries, who have sacrificed all that was dear to them for the sake of that country which gave them birth ; that country
                  which is the land that holds everything that is near and dear to us—that land the glories of which my name shall never be identified in dimming unless under the force of such events as I have already referred to. I might say, sir, with some degree of certainty that there are men now in this Union who, if elected upon such principles as those upon which Mr. Lincoln has ridden into
                  power, would be magnanimous and brave enough to make this sacrifice. I don't say that Lincoln will do it; I fear he will not; but, sir, whatever he may do, and however he may act, it is our duty here so to act as to avoid undue precipitancy, and not impair the force and great moral weight of our action when it is presented to the consideration of the people for ratification or
                  rejection.
                </p>
                <p className="p-in-sp">I say, then, sir, that I would wait for this "Peace Conference," so that we may act intelligibly and secure more unity than would now mark any final movement we may determine upon.</p>
                <p className="p-in-sp">
                  In the remarks which I submitted yesterday, and in the resolutions which I then took occasion to submit, I referred to the fact that there was another remedy which could be resorted to, if circumstances had not so changed as to make an amendment to the Constitution practicable. I don't see now, sir, that we can get any such measure ; I don't desire it. I do not say that we ought to
                  have any assemblage of a conference of the border States. I indicated it as my wish and desire, if we could have it without compromising the rights and interests of the State of Virginia. I do not wish this Convention to understand me as desiring, by the expression of any such wish or sentiment, delay on their part, in whatever action they contemplate taking. But if I was satisfied
                  that nothing would be introduced here that promised to establish peace and equality, and restore us the rights which our fathers gave us, I should have regarded it as a duty which we owed to ourselves <PageNumber num={254} /> and our constituents, to resort to the means which I indicated, namely : a Conference of the border slave States. And it would be
                  our duty to await the result before proceeding to take any precipitate action.
                </p>
                <p className="p-in-sp">
                  But, sir, in the meantime, if resistance should become necessary, if it so happened that the State of Virginia should be invaded; that her rights should be crippled in any way; that any attempt should be made to coerce or overawe her by marching Federal troops through the State—I say, sir, should these things be attempted, I will stand by you, sir, and stand by you to the last.
                </p>
                <p className="p-in-sp">
                  I shall never depart in the slightest degree from the exact line of that path of propriety and necessity which shall be presented to me. But I say, that before we move, we must be satisfied that the remedies which we have devised, or shall devise, for the evils which now afflict us, have been first exhausted. I must say to my people, when I return home, that there were no possible
                  means of effecting any thing within our reach to which we had not resorted ; that all the measures we had tried had proved unavailing; that we had endeavored by every means to accomplish what might result in the preservation of this Union ; but these having proved fruitless, we were compelled to take the ultimatum of dissolution, trusting by-and-by to the prospect of re-constructing
                  it. I would state to my people in this connection, that in resorting to dissolution, I would, in some degree, be governed by the prospect of being enabled to bring our influence to bear, through the medium of a Southern Confederacy, upon the States that have already seceded, in the hope of again bringing it back and forming an union upon its old basis, with additional guarantees, of
                  course. When it comes to the question of North or South alone ; when I am to choose my position and decide for Virginia, whether she will take her stand with the North or the South—I tell you that my sympathies will be with the South ; and that feeling which was born in me, and which I share in common with the people of Virginia, will place me under that flag which is now floating
                  over us to-day. If perish I must, I had rather die fighting gloriously under it, than to die ingloriously, to be wrapped and buried under the folds of one of even thirty-four stars upon it.
                </p>
                <p className="p-in-sp">
                  This, sir, is my position, and I intend to maintain it, come what may. I say most distinctly and unequivocally that, so far as I am concerned, I have little hope from Abraham Lincoln and his coadjutors. If I had faith that he would inaugurate anything like just principles, which would form a basis for the settlement of this question, I should be willing to forego any action until the
                  test was applied; but <PageNumber num={255} /> there is no hope of such a result. He told us the other day from that consecrated spot in Philadelphia—Independence Hall—that he was elected upon the principles of that statement in the Declaration of Independence which gives liberty, not alone to the people of this country, but to the people of the world
                  for all future time—those principles which give promise that in due time the weight would be lifted from the shoulders of all men. Do you tell me that I can go to Washington and feel that I can breathe the air which I once breathed in all its purity; that I can go there and take by the hand the man who says that these principles must be carried out throughout the land
                </p>
                <p className="p-in-sp">
                  the man who is to be installed into the highest office of the land upon principles that give liberty not only to the white race, but to the slaves? He tells me, like an advocate at the bar who receives a fee for his services, that these are principles at which nobody can take offence; and he tells you that he means what he says. In view of all this, while I might hope that he might
                  do otherwise, while I might wish he would do otherwise, I can have but little hope and no expectation that he will. Indeed, I almost entirely despair of his doing so; but there are instances in which men have sacrificed themselves in order to save their country. It is indeed a rare occurrence to find one turning his back upon the very platform of principles upon which he was elected
                  to power. When I find such a man, I shall indeed find a jewel. When I find Lincoln turning upon those who are polluted with the sentiments of that irrepressible conflict doctrine; when he can so far forget the behests of party as to shake off whatever partakes of the fanaticism which belongs to the Garrison, the Phillips, and the Greeley school, then, indeed, I will regard him as
                  having the good of his country at heart, and as exhibiting some disposition to reconcile the distracted elements of the country and bring peace to its people. I have no hope that he will do this, remembering, as I must, that he is obliged to carry out the behests of the party by whom he was elevated to power, rather than consult the interests of the country over which he is now
                  called to preside. Should he do anything to give peace to the country, he would, indeed, deserve the thanks of all men, and he might well feel, as might the country, that he was worthy to be one of the successors of Washington, Jefferson and the other distinguished men who have filled the Presidential Chair.
                </p>
                <p className="p-in-sp">
                  I have said what I intended to say, and I return you, Mr. President, and gentlemen of the Convention, my thanks for the patient manner in which you have heard the remarks which I have offered, and for the kind consideration which you manifested in adjourning over, last <PageNumber num={256} /> evening, to enable me to proceed with my remarks to-day. I
                  will conclude, now, with the expression of a hope that whatever we may do may redound to the glory of our own State and the benefit of the country at large.
                </p>
                <div className="speaker" id="sp309"><span className="persName">Mr. JAMES W. SHEFFEY</span> of Smyth then rose and spoke as follows :</div>
                <p className="p-in-sp">
                  Mr. President : The most experienced statesman may well be appalled by the fearful duties before us in this Convention. Appearing as I do, sir, for the first time in my life in a deliberative assembly, the thought that the fate of my country depends upon it, makes the blood rush back to my heart and fills me with a painful sense of my responsibility. The future of Virginia and of the
                  Union may, under God, depend upon the result of our deliberations. I am gratified, sir, I am profoundly gratified, that though there may be much diversity of sentiment amongst us, yet it is but seeming, in the reasoning only, which leads us to our conclusions, and that our reasoning and the events, as they will be developed, and developed in a very brief period of time, will bring us
                  all to the same conclusions at last. I have listened with profound pleasure to my friend from Bedford
                 [<span className="persName">Mr. GOGGIN</span>] for the last two days ; and while I was at a loss yesterday to know where my honorable friend would land at last, I welcomed him to the conclusion to which he has arrived—and to which extremity, if that faint hope that he has expressed in the conclusion, shall have died away at the inauguration of Mr. Lincoln—we must all come at last.</p>
                <p className="p-in-sp">
                  Mr. President : I come from my mountain home in the beautiful South-West, where flocks and herds are let into green pastures, and lie down by the still waters. There, cotton is not king. We have no king, sir; and there tobacco, the great interest at heart in the bosom of my friend, and of those whom he represents, has not yet made of her virgin soil and grass-clad hills, a barren
                  waste of pine forests and fields of broom sedge. There, sir, on the thousand hills to which he has so eloquently alluded, the flocks and herds form no small part of our staple. Those beautiful plains to the East, from the sea-board to the Blue mountains, may be laid waste, this proud capital and all the cities of the Eastern plain, may fall before the desolating tread of the
                  conqueror; but he will be a bold and a rash invader, who will dare to cross our mountain barriers and passes. Fortified by mountains, rising up on every side, with towers of strength, and fortresses of defence, we may, and we will, sir, maintain ourselves and our liberties when all are lost elsewhere.
                </p>
                <p className="p-in-sp">
                  But, Mr. President, there are no people in Virginia more true and loyal to the State; none who will stand by her with their lives, if need <PageNumber num={257} /> be; who will stand by her sister States of the South with their lives. if need be; who will stand by Virginia upon, her border and her seaboard, and by the States of the South, upon the
                  border of Virginia. to roll back the coming tide with more decision, with more firmness, with more resolution, and with a better and a truer heart, than the people of South-Western Virginia. We, sir, might maintain ourselves, if all the institutions of the State were swept away; if there were no Government, no finance, no banks, no railroads, no foreign commerce.
                </p>
                <p className="p-in-sp">But, sir, distrust us not. We will be true, as in the Revolution, and in the war of 1812. We, too, sir, have a record. It is written upon King's Mountain. It is written in bloody characters upon the sands of Norfolk.</p>
                <p className="p-in-sp">
                  Mr. President : We have been carried back in strains of thrilling eloquence to the great and glorious past. Virginia also has a past. She has a present and a future, too. The past of Virginia is written in history. Her history is marked throughout, from her first statesmen along this historic and romantic stream to the present hour, by prominent events, all evincing the spirit of a
                  bold and brave people, ready always to assert, and daring always to maintain her rights. But her future is unwritten. It may be written in blood. May it be written in enduring memorials of peace and prosperity!
                </p>
                <p className="p-in-sp">Her present, sir, is certainly the most eventful and most momentous period of her existence ; for upon her action now—now and here depends her future destiny. With her destiny is intimately—would I could say with you, sir [turning to <span className="persName">Mr. GOGGIN</span>] 
                  -inseparably connected the destinies of the other States of the Union. The members of this Convention are the arbiters of that destiny. This Convention is the embodiment of the supreme power of the State, invested with the highest power of sovereignty which a State can exert the power to determine the future of the State, and its relation not only to her sister States, but to the
                  world. Then let us, who are charged with these responsible duties, invested with this awful power, trusting in Him who presides over and guides the destinies of nations, emulate the spirit of our fathers, and seek to raise our beloved country from the pall and gloom which hangs over it, to a lofty and prosperous future.
                </p>
                <p className="p-in-sp">
                  Mr. President: Virginia has been censured, aye, even derided by some, for her delay in taking action whilst convulsion and revolution. North and South, have raged around her. For, I hold sir, that every act of every State of the North and of the people of the North—every violation of, and resistance to, the Constitution and laws of the Union, is as much revolution as the ordinances
                  of Secession of the seceded <PageNumber num={258} /> States. But 1, with the gentlemen who preceded me, would vindicate the honor, the courage and the patriotism of Virginia from such a rebuke. Slow, she may be, sir—slow to anger and to action; slow to move and to be moved; slow to lead and to follow the column of seceding States; slow to imperil the
                  form of government under which we so long lived and prospered; slow to abandon the hope of restoring and preserving the Union ; slow to encounter the hazards of revolution, re-construction and civil war; but, sir, let not her inaction be mistaken for apathy, nor her deliberation for submission. Virginia never has submitted, Virginia never will submit to outrage upon her rights;
                  Virginia never has submitted and Virginia never will submit to coercion against herself or her sister States at the South, in any form, come from what source it may.
                </p>
                <p className="p-in-sp">
                  But when Virginia acts, she will act from principle, not passion; from patriotism, not precipitancy ; from a just appreciation of her own sovereignty and the rights of her sister States, as well as of the inestimable value of the Union. Her very forbearance has directed and does this day direct all eyes to her as the great mediator and pacificator of this family strife. She would
                  have been untrue to herself and to the noble position assigned her by common and by general consent, as the mother of States and of statesmen, if she did not, like a true mother try to calm the passions and reconcile the differences of the sister States. The wisdom and justice of Solomon are renowned for his judgment in the memorable contest between the true and false mother of the
                  living child. "Bring me a sword, and I will divide it," said the king. "Spare, oh spare my child!" was the simple and touching appeal of her whose heart yearned with a mother's love. "Let it be neither mine nor thine," said the false one, "but let it be divided." Need I say, sir, that she who would save it from division, was the true and noble mother? Now the sword of coercion, if
                  not raised to-day, at all events, if the hope, which my friend so faintly indulged in at the last, shall have faded away in the next few days—the sword of coercion will be raised over our beloved Union, the darling of the bosom of Virginia, to divide it, and to divide it forever. As that noble mother of old would have interposed her own body between the uplifted sword and her darling
                  offspring, so will Virginia, when the sword of coercion is raised by the Federal arm to divide this Union, and to divide it forever, stand in the breach and interpose her own body, and the bodies of her noble sons, and sir, if need be, of her fair daughters, to save it from eternal destruction.
                </p>
                <p className="p-in-sp">
                  Mr. President: To Virginia has been properly committed the mission <PageNumber num={259} /> of peace, of reconciliation. The most aggrieved of all the States, her soil desecrated by the feet of the invader and the blood of her citizens, her property decoyed from her and denied to her, and her peace disturbed by an agitation scarcely less disastrous than
                  war, she has yet stood, amid the tumult and passion around her, elevated far above it; calm, dignified, serene and firm, a spectacle of moral sublimity worthy of her ancient renown. She stands to-day the observed of all observers, looked to as the pacificator and as the mediator in this, I had almost said fratricidal contest. With the sword in one Land, she stands pledged and ready
                  to defend her own rights and to stand by her sister States of the South in the maintenance of their just rights at all hazards and to the last extremity. But, with the olive branch in the other, nerved by a patriotism embracing every State in the Union, she extends—she has extended, and she yet extends—the symbol of peace and re-union to all that will meet her and commune with her
                  around a common altar.
                </p>
                <p className="p-in-sp">
                  Her Legislature, Mr. President, with a spirit of unanimity worthy of the State they represent, has declared by an almost unanimous vote against the policy and against the right of coercion, in the strongest terms; and with the same spirit of unanimity, has declared that if all these efforts to reconcile these differences, unhappy as they are, shall fail, then every consideration of
                  honor and of interest demands that Virginia shall unite her destinies, not with those who have driven her to this extremity, but with the sister States of the South. Her Legislaturc, in response to the call of the people, has directed the election of delegates to this Convention, who have met to consult and consider the state of the Union, and to determine the future course of
                  Virginia. And in the meantime, with a high-toned spirit of conciliation, rising above the passion of the hour, she has sent Commissioners to Washington as ambassadors of peace, profoundly convinced that unless these unhappy differences can be adjusted, the permanent dissolution of the Union is inevitable ; and earnestly desiring to avert so dire a calamity, she has sent her tried and
                  trusty men—men in whom she places her confidence and, to some extent, her destiny—TYLER, RIVES, SUMMERS, BROCKENBROUGH and SEDDON-to meet Commissioners from the other States at the National Capitol on the day of our election, to agree, if possible, upon terms of adjustment. In her noble work of peace and good-will she dispatched Ex-President TYLER to Washington and Judge ROBERTSON to
                  South Carolina, to stay, if possible, the arm of bloodshed, pending this negotiation.
                </p>
                <p className="p-in-sp">So far as I know, Mr. President, this mission has been thus far <PageNumber num={260} /> unsuccessful. No blood has yet been spilled. One drop of blood in the present excited and inflamed condition of the public mind, like a spark to a powder-magazine, would involve this glorious country of ours in a flame of universal war.</p>
                <p className="p-in-sp">The mission of Tyler and his associates was met by the President of the United States in a spirit—worthy, allow me to say, differing with him as I have differed with him in political considerations—worthy of the Chief Magistrate of a great nation.</p>
                <div className="speaker" id="sp310"><span className="persName">Mr. MORTON</span>—</div>
                <p className="p-in-sp">Mr. President, would it not be proper to have that nuisance stopped.</p>
                <div className="stage it">[Referring to the ringing of the bell outside.]</div>
                <div className="speaker" id="sp311"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">The chair understands that the bell is rung by the order of the House of Delegates.</p>
                <div className="speaker" id="sp312"><span className="persName">Mr. SHEFFEY</span> resumed.</div>
                <p className="p-in-sp">In a special message, sir, communicating the Virginia resolutions to Congress, and commending them to its favorable consideration, the President of the United States concludes with this patriotic sentiment and just tribute to Virginia :</p>
                <p className="p-in-sp">
                  "I am one of those who will never despair of the Republic. I yet cherish the belief that the American people will perpetuate the Union of the States on some terms just and honorable to all sections of the country. I trust that the mediation of Virginia may be the destined means, under Providence of accomplishing this inestimable benefit. Glorious as are the memories of her past
                  history, such an achievement, both in reference to her own fame and the welfare of the whole country, would surpass them all."
                </p>
                <p className="p-in-sp">
                  So say we all. 0, that the dove sent forth by him had come back with an olive leaf, to tell us that the spirit of fanaticism and of sectionalism had subsided, and that the ark of our Union and our Government had at last found an Ararat, far above the storm, to rest upon in permanent security! That mission was deemed a harbinger of hope. May the bow in the cloud that has so long
                  hovered over us, be a promise of speedy relief, and assure us that the sun of our hope has not yet set and that the storm cloud is passing away! May it be no delusive, evanescent hope! May it be rather a permanent and gladdening assurance of the re-union and renewal of the Government forever!
                </p>
                <p className="p-in-sp">
                  But, Mr. President, whether that mission succeeds or fails, Virginia has done her duty, her noble and her patriotic duty; and it is not derogatory to her honor, or her patriotism, or her courage, to say that she has cherished and that she does yet cherish in her heart of hearts an abiding attachment to the Union as it was, and as it was framed by the fathers and founders of the
                  Republic.
                </p>
                <PageNumber num={261} />
                <p className="p-in-sp">
                  But what country on earth, sir, can boast of such advancement in all the elements of material and national wealth as the United States of America? The progress of our improvements and settlements has been magical. From three millions of people in the times of the revolution we have grown, in three quarters of a century, to upwards of thirty millions. The tide of population has spread
                  from our settlements along your Eastern border, in North Carolina, Virginia and Massachusetts, and the other Colonies, across these States, across the Valley of the Mississippi—the present and the future seat of empire—to the plains, and across the plains, and over the Rocky Mountains to the Pacific.
                </p>
                <p className="p-in-sp">All this magnificent domain, from Canada to Mexico, from the Atlantic to the Pacific, a few weeks ago was ours—a domain in extent and in resources exceeding the continent of Europe. All that was ours—is ours, if by the intervention of Virginia the Union could have been or could be restored.</p>
                <p className="p-in-sp">
                  Reference has been made, sir, to England, whose reveille, following the sun, circles the earth daily. England may boast that her dominion reaches around the globe ; but we may claim, without boasting, that the sun never rose upon a domain, nor shone upon a Government more admirably adapted to the wants, and interests, and happiness of man, than ours; a domain embracing all that is
                  desirable of the North American Continent; a Government administered in its purity; a Government as it was founded by the fathers and founders of the Republic, the freest, the happiest and the best ever devised by the wisdom of man, for the happiness of man, upon this broad earth, in ancient or in modern times. No wonder, then, Mr. President, that a distinguished son of Virginia—no
                  wonder that Virginia herself—yet clings, though with weakened attachment, yet not without some fond memories, to her first love—to the Union as it was.
                </p>
                <p className="p-in-sp">
                  But Mr. President, let not her love of the Union, let not my love of the Union, let not the love of the Union of her gallant sons and her fair daughters everywhere, be mistaken or misunderstood. Let it not be misconstrued into an abandonment of her own State sovereignty and a surrender of her equally cherished attachment to the rights of the States. These, sir, she will never
                  surrender nor abandon till the last citadel of liberty in her mountain fastnesses shall have fallen, and every spot is memorable for heroic devotion and desperation as the pass of Thermopylae.
                </p>
                <p className="p-in-sp">
                  Mr. President, the Virginia statesman may no longer enquire, can this glorious Union, so hallowed in our memories, and which has sent <PageNumber num={262} /> such blessings to our great and beloved country, be preserved? Can there be a re-union? Will it be a union of the whole or of dismembered fragments; in the views of some, into two, and in the
                  views of others, into three or more distinct and separate confederacies? Can civil war be averted? Can we escape the fate of other Republics that have arisen, flourished and fallen? Other Republics have fallen to pieces by their own weight and their own prosperity. We are too blest, we are too happy through our own very prosperity—and, in that prosperity and happiness, have forgotten
                  the struggles which achieved them, and are unmindful of the blessings we are permitted to enjoy.
                </p>
                <p className="p-in-sp">
                  Sad coincidence in history will it be, that the same year which future historians will make famous for the union of the Italian States, after a lapse of so many centuries from the decline and fall of the Roman empire, shall be signalized by the disunion of the United States of America, and of the commencement of their decline and fall! 0, may Heaven avert so dire a calamity, not to
                  our country only, but to posterity and to the whole world!
                </p>
                <p className="p-in-sp">
                  Mr. President, the statesmen of Virginia assembled here must look at facts as they are, and not as patriotic hearts would have them to be. We cannot shut our eyes to the great fact, adverted to by my friend who has preceded me, that the Union is no more! The Union is dissolved! Seven States have seceded, and established a provisional Government. That fact practically dissolves the
                  Union. The silver cord is loosed, the golden bowl is broken. I shall not pause here, sir—I propose not now to discuss with my friend, the right of secession. Sir, I come with Mr. Buchanan to the same conclusion, to which I arrive at last, that whether the Union may exist or not, there is no right of coercion by the powers that be in the present Government. I shall pass by the
                  discussion of that question for the present.
                </p>
                <p className="p-in-sp">
                  But this great fact which we must look in the face and which we must consider in our deliberations and our determinations, whether secession is rightful or wrongful, whether a provisional Government South exists de jure or not; we all, if we believe facts, must come to the conclusion that it exists de facto. And that, sir, practically severs the chain of the Union. It is like, in
                  that regard, the chain which binds the universe of worlds together—
                </p>
                <p className="p-in-sp">"From nature's chain whatever link you strike, Ten or ten thousand breaks the chain alike ; The least, confusion, but in one, not all,</p>
                <p className="p-in-sp">That system only, but the whole must fall. Let earth unbalanced from her orbit fly,</p>
                <p className="p-in-sp">Planets and Suns run lawless through the sky." <PageNumber num={263} /> Such confusion is there now, sir, in the stars of this once harmonious system. The repulsion of fanaticism has driven the pleiades from their orbits, and no force but that of attraction can ever bring them back.</p>
                <p className="p-in-sp">
                  And now, Mr. President, I come to the discussion of a proposition passed sub silentio by my friend on the other side, though he came to the conclusions to which I proposed to carry you, and that is, that the Union cannot be preserved by force. Force cannot re-construct it. Force never can hold it together till the principles which underlie our government are extinguished, and till
                  liberty shall be taken from her by a military despotism.
                </p>
                <p className="p-in-sp">
                  The great principle of the Revolution was resistance, resistance to the death, of the assertion, the attempted enforcement, of the right to take from us our property by taxation against our own consent. The great principle, I say, sir, of the Declaration of Independence, the Magna Charta of our liberties, is, that "all rightful governments derive their just powers from the consent of
                  the governed." That Declaration proclaimed the thirteen colonies to be free, sovereign and independent States. The Constitution of the United States was framed by those free, sovereign and independent States, not merely, as some suppose, "to establish a more perfect Union," but for higher and greater objects—"to establish justice and ensure domestic tranquility ; to provide for the
                  common defence and general welfare, and to secure the blessings of liberty to us and our posterity." Then suppose that this Constitution fails in all these great and leading objects of its creation, where, then, is its vitality, and where its sacred obligations? Let it be, sir, that instead of establishing justice, it becomes an engine of injustice and oppression; that rights
                  guaranteed to us and intended to be protected by us are not only not secured and enforced, but are denied to us; that, instead of securing domestic tranquility it produces domestic discord, private insecurity and civil war ; that instead of providing for the common defence, the guns of the country—the guns, sir, bought by the common treasure, or won by the common blood—are turned
                  upon our own bosoms; let it be that instead of liberty, we are to have a body-guard for a Republican President; a standing army, a military despotism, to coerce us into submission. Can a standing army and a military despotism lead us into subjection to a Union or a Government against our own consent? Wherein, then, do we differ from the provinces of conquered Gaul held dciwn by Roman
                  legions? Wherein do we differ from Ireland, coerced by English bayonets, or from Hungary subjugated by Austrian despotism?
                </p>
                <p className="p-in-sp">
                  Mr. President, I too love this Union. I love it because it is a sacred <PageNumber num={264} /> heritage from our fathers. I love it with you, sir, because it is hallowed in the Farewell Address of the Father of this country, full of prophetic vision and warning, almost like inspiration. I love it, I revere it, I cherish it because it is hallowed by
                  JEFFERSON, the author of the Declaration; by MADISON, fitly called by you, sir, the architect of the Constitution, and by HAMILTON, JACKSON, WEBSTER, CLAY and a host of other national patriots from the Revolution down to the hopeless year of 1860.
                </p>
                <p className="p-in-sp">
                  But, sir, dear as is that love to the Union in my own bosom, and in the bosom of Virginia and of Virginia's sons, it cannot be maintained by force; it cannot be maintained at the expense of our interest, of our honor, of our liberty; it cannot be forced upon free States and a free people, unwillingly and against their consent. Such a Union would be the worst of tyrannies. A
                  government professing to be founded on the consent of the governed, based upon that principle ; a Union, a Government held by coercion and by force, against the consent of the States, and against the consent of the governed, would be a despotism that no free or brave people ever can or will submit to.
                </p>
                <p className="p-in-sp">
                  How hold, sir—how hold co-equal the States of the Union by force? The very fact of force would destroy all equality and all liberty. Force, if effectual, must reduce a State to the position of a conquered province, or what is worse—no, no; not what is worse, but what is better far, would lead to the extermination of it. If the General Government may by force conquer and hold one
                  State or seven States, why not, having raised an army for that purpose, conquer and hold successfully seventeen or all the States in a consolidated military despotism? What then, sir? What next? A standing army, against which we complained in the Revolution, harrassing our people out of their substance ; a military despotism to reduce us to a state of subjection and degradation far
                  worse than colonial bondage.
                </p>
                <p className="p-in-sp">
                  Mr. President, some other mode besides force must be resorted to, in order to preserve or restore this Union. The cause of the discontent and disruption must be removed. Public sentiment must be reformed and revolutionized at the North. The men of the North—the conservative men of the North, if there be such there—must rise up and, with their heel, crush out the spirit of fanaticism
                  and of abolitionism, their worst enemy. It has lost them millions; it has cost them millions; it has alienated friends and allies; it has literally destroyed this Union; it has subverted this Government, and it has brought us now to the very verge of an awful civil war. And for what, sir? Why, we never invade them; we never interfere with their rights of property or their domestic
                  <PageNumber num={265} /> institutions. Were we to deny to the proprietors, to the capitalists, to the property holders of the North the right to employ the labor peculiar to their section, the starving laborers thrown out of employment, would rise up and demand bread or blood; were we to appeal to their agrarians, whose name is legion, and say, "these
                  men have grown rich upon your labor—rise up, take from them their property and divide it, or extinguish it by the charge of some would-be Catilines and demons," some justification would exist for the warfare made upon us. We scorn such sentiments. Why then, sir, should we be pursued by an unrelenting war upon us and our institutions, peculiar to us, and which in no manner concern
                  them? Some have said that the cohesive spirit which binds them together is power, plunder, place. We shall see in a few days whether or no it be the fell spirit of fanaticism which never reasons, which never pauses, which never regards a caution or a consequence, which never tires, which never stops, which never dies, until it revels in excitement and blood.
                </p>
                <p className="p-in-sp">
                  Mr. President : It is said that the sentiment of the North is that the Union must and shall be preserved. Then they must not make it odious by making it a never ceasing annoyance. They must not make it odious and intolerable by making it oppressive and destructive by any measures of coercion. It was endeared to me—it was endeared to the country by the blessings which it was intended
                  to shower upon our great country. They must let it still be endeared to us by its blessings, and especially by the peace, security and tranquility which it was intended to afford.
                </p>
                <p className="p-in-sp">
                  Sir, it must be a union of fraternal attachment to be permanent. Its cement must be a community of interest, linked together by a common attachment. But, sir, I will hasten on. What would be the result of measures of coercion to keep this Union together? Whenever the central sun shall succeed in drawing back even an erratic star by force, consolidation will end its fatal career. The
                  tendency of force will be to a centralization of power, to a consolidation of the Government and to subjugation, if not annihilation of States and their liberties. I am, then from these considerations unalterably opposed to coercion. What is coercion, sir? I reserve the consideration of it, until we receive the report of our Committee. I can only state that, with the light now before
                  me, I would regard the collection of the revenues from the seceded States, without their consent, or an attempt to retake the forts within their limits and jurisdiction, as coercion, as much as an armed force to force them back into submission or subjection. Where then shall Virginia go? Upon this point I shall not long detain the attention
                  <PageNumber num={266} /> of this patient assembly. Where shall Virginia go? In answer to the great difficulties suggested by my friend upon the other side, I have to say that Virginia will go nowhere until she knows the terms upon which she is to go. She will not blindly rush into this Union or into that until in fair council she has considered,
                  consulted, deliberated and determined what will best protect her rights. I have only to say that I think she must soon come to a conclusion. She must stand either alone, neutral, go with the North or go with the South. She might stand in a neutral position as an armed neutral, if you please, between belligerent powers, bristling on all sides with bayonets ; saying to the North :
                  "Thus far shalt thou go and no farther, and here shall thy proud waves be stayed." Thus standing between her sister States and Northern aggressions, if events will permit her to occupy that position —I put this question, if coercion is attempted against the States of the South, thereby attacking directly the rights of the States, where then shall Virginia go? I understand that
                  Virginia will go—and my friend will go with her—out of the Union to take her equal place amongst the nations of the earth, and that then she will look around and by her statesmen determine whither she will go. She will hold her destiny in her own hands, and whatever that destiny may be, I am prepared to abide by it. Virginia, noble old mother of us all—all her sons in that dark hour
                  of peril and of trial will draw closer to thine altar, and swear fealty to thee alone; for, in the language of the noble resolution of the gentleman from Barbour
                 [Mr. GOODE] 
                  ,<span className="note" id="Note34"><span className="noteLabel">2</span>This should be Mr. WOODS.</span> whose acquaintance I have not had the pleasure of making, our first and highest allegiance is to Virginia—subordinate to that is the allegiance which we owe to the Government and the Constitution of the United States. All the theory of our Government forbids the exercise of force by the
                  general Government against the States. We recognize no high, divine power, no divine right in any power to govern us. The power of self-government is an inherent power in the people themselves. Our government is an agency—a representative government. Government can never rise above its creator. The government of the United States, complex as it is, is similar in its form and similar
                  in its operation. The States embody the sovereignty of the people primarily. The general government is the creature of the States; the general government is not a government of unlimited powers—it is a government of limited and delegated powers. But there is a principle introduced in that government by Virginia, to this effect : "All powers not expressly delegated, are reserved to
                  the States and to the people of the States respectively."
                </p>
                <p className="p-in-sp">
                  My friend who addressed you yesterday, said that there was no <PageNumber num={267} /> power in the constitution of the United States authorizing a State to secede. Granted. I will ask him this question : In a government of limited powers, where powers not delegated are not presumed to exist, but are reserved to the States, I ask him if he can find in
                  the Constitution of the United States any power expressly given or by implication given, to the general government to coerce a seceding State and punish it; to force it back or hold it by force, if, in the exercise of its right, it does go out? I find no such power. On the contrary, if the Convention will indulge me for one moment, they will allow me to refer, not only to the fact
                  that there is no such express power in the Constitution, but that it was negatived by the framers of the Constitution. When Mr. Randolph in his resolutions proposed to insert such a provision in the Constitution of the United States, to give to the general government the power to coerce a State, what then took place. You will find an account of what took place on that occasion in the
                  second volume of the Madison papers, page 761:
                </p>
                <p className="p-in-sp">"The last clause of the sixth resolution authorizing an exertion of the forces of the whole against a delinquent State, came next into consideration.</p>
                <p className="p-in-sp">
                  "Mr. Madison observed, that the more he reflected upon the use of force, the more he doubted the practicability, the justice and the efficiency of it when applied to people collectively and not individually. An union of the States containing such an ingredient, seemed to provide for its own destruction. The use of force against a State would look more like a declaration of war than
                  an infliction of punishment; and would probably be considered by the party attacked as a dissolution of all previous compacts by which it might be bound. He hoped that such a system would be framed as might render this resource unnecessary, and moved that the clause be postponed. This motion was agreed to nem con."
                </p>
                <p className="p-in-sp">Then, so far from the Constitution containing a provision authorizing the General Government to use force against a State, it is expressly negatived in the provisions of the Constitution.</p>
                <p className="p-in-sp">Indulge me, sir, for a single moment, while I look at the action of Virginia in reference to the terms upon which she ratified this Constitution of the United States. The extract which I will read is very brief :</p>
                <p className="p-in-sp">
                  "We, the delegates of the people of Virginia, duly elected in pursuance of a recommendation from the General Assembly, and now met in Convention, having fully and freely investigated and discussed the proceedings of the Federal Convention, and being prepared, as well as <PageNumber num={268} /> the most mature deliberation hath enabled us, to decide
                  them, do, in the name and in behalf of the people of Virginia, declare and make known that the powers granted under the Constitution, being derived from the people of the United States, may be resumed by them whensoever the same shall be perverted to their injury or oppression, and that every power not granted thereby remains with them, and at their will."
                </p>
                <p className="p-in-sp">Here, sir, is an express reservation, that whenever the powers of the Constitution are perverted, in the opinion of Virginia to her oppression, she shall have the right to resume her sovereign powers and to take her equal station amongst the nations of the earth.</p>
                <p className="p-in-sp">
                  I conclude by saying, that whenever coercion shall be attempted, it is the right, it is the duty of Virginia, in the exercise of her reserved right to resume her sovereign power and to take her station amongst the nations of the earth. I wait, and I wait with anxious interest, to know what is to be defined and what is to be considered coercion. I have expressed, in a crude manner, my
                  views upon that subject, and in conclusion, Mr. President, let me say—for time will not allow me to follow my friend in all the ramifications of the argument which he made—nor did I rise with that view, but simply with the view of presenting -before the Convention my views and opinions in reference to this great and interesting subject—that an out-going President disclaims the right
                  of coercion; and yet he and his government have maintained an attitude, at least, of menace and intimidation. The guns which were originally placed for the defence of South Carolina and Virginia have been turned upon the bosoms of those States. What the incoming Administration designs to do, I, with my friend, know not. Nor can I deduce from the picture of his ability in committee,
                  contrasted with his imbecility since his election, as evinced by his speeches upon his so called triumphal march into power and place, any idea as to what his future purposes may be. He holds his tongue. Silent as an Oriental despot and mysterious as the veiled prophet of Khorassan—we may not be able to look behind the veil and see the hideous visage nor the hidden purpose concealed
                  there. He is silent—and he takes credit to himself for his silence. He has seen State after State secede. He has seen the country over which he was elected to preside, march on with a steady step to the very verge of a disastrous civil war, when one word from him, in the spirit of a Virginian, might have poured oil upon the troubled waters and calmed the tumult and the storm. Yet he
                  spoke Dot that word. No voice from Springfield, no tones of consolation, no kind assurances came to us from his sealed <PageNumber num={269} /> lips and veiled face to tell us what his future purposes are. But on his way to Washington we hear very mysterious oracles, mystic murmurings and threatenings of coercion. How, we know not. Better, far, for his
                  own fame and for the prosperity of the country over which he has been elected to preside, had he determined that his elevation should not produce the downfall of the Union and of his country—that he would rather sacrifice himself upon the altar of his country, and offer up his resignation as a peace offering to the Union and the happiness and the prosperity of the country. Better to
                  have placed himself by the side of Washington, who, when solicited to remain in office, in a dignified, patriotic and sublime manner, announced his determination to retire to the peaceful occupations of a rural life, content with the honor of having been "first in war, first in peace, and first in the hearts of his countrymen." Better, far, for Mr. Lincoln to do that, than to adhere,
                  with a fanatical embrace, to his own delusive idea, and, Samson like, seize blindly upon the pillars of the temple of liberty and crush all in one common ruin. When he does so, let the voice of this Convention go forth to Virginia and the world, and proclaim to Virginia : "Stand from under and take your place as a free, sovereign and independent State," and w;th her gallant sons and
                  her fair daughters rallying around her, she may fearlessly resume that sovereignty, and she may fearlessly launch upon a new and untried experiment and destiny. What that destiny may be, the future only can disclose; and it would be unwise in us to determine what that destiny shall be. I have only to say that my predilections, my sympathies and my hopes are in a union of the South,
                  the whole South, for the sake of peace, for the sake of averting a disastrous civil war, and for the sake of preserving the hope of restoring our glorious Union.
                </p>
                <p className="p-in-sp">
                  Mr. President, I conclude by saying that a united South is to be the salvation of Virginia and the only hope of salvation for the Union as it was; but if not for that, for the Union as it should be. United, we triumphed in the battles of the revolution ; united we achieved our independence then—and, if we present a united front now, such a union would be formidable and terrible ; and
                  united in the holy cause in which we are engaged, it would secure us peace, prosperity, and liberty. To that end let us unite with one heart, with one mind, and with one purpose to wrest our bleeding country from its present distracted condition, and set us free.
                </p>
                <p>
                  <span className="persName">Mr. MORTON</span> obtained the floor and moved, as he was not prepared to address the Convention to-day, that they should now adjourn. <span className="persName">The PRESIDENT</span> laid before the Convention the following communication <PageNumber num={270} /> from the Governor, which was laid on the table and ordered to be printed
                  :
                </p>
                <div className="floatingText">
                  <div className="floatingText_body">
                    <p>EXECUTIVE DEPARTMENT,</p>
                    <p>February 27, 1861.</p>
                    <p>Gentlemen of the Convention:</p>
                    <p>In response to your resolution, adopted on the 20th inst., calling for information as to "the number of the enrolled militia, and of the volunteers of the State; the number of companies that have been supplied with arms; their kind and description," I communicate herewith a report from the Adjutant General.</p>
                    <p>Respectfully,</p>
                    <p>JOHN LETCHER</p>
                    <p>ADJUTANT GENERAL'S OFFICE,</p>
                    <p>February 27, 1861.</p>
                    <p>To His Excellency, John Letcher, Governor of Virginia :</p>
                    <p>
                      SIR: I have the honor to report the information called for by resolution of the Convention of 21st<span className="note" id="Note35"><span className="noteLabel">3</span>The date was the 20th.</span> inst :
                    </p>
                    <p>
                      The military force of the State consists of 5 divisions, 28 brigades, 5 regiments of cavalry, 5 of artillery, 3 regiments and 4 battalions of uniformed and armed volunteers, and 197 regiments of infantry of the line. The annual consolidated return up to 1st October, 1860, being made up from the latest brigade returns, gives an aggregate of only 143,255, officers, non-commissioned
                      officers and privates, although there are undoubtedly not less than 200,000 men in the State subject to militia duty. This is the result of negligence on the part of enrolling officers, and the failure of some regiments to make any returns at all.
                    </p>
                    <p>VOLUNTEER FORCE</p>
                    <p>There are now in commission 95 troops of cavalry, 26 companies of artillery, 112 companies of light infantry and 114 companies of riflemen.</p>
                    <p>Of Cavalry:</p>
                    <p>7 troops are armed with sabres and percussion cavalry pistols.</p>
                    <p>2 troops with sabres and cavalry musketoons.</p>
                    <p>32 " " " " revolvers.</p>
                    <p>21 " " only—and</p>
                    <p>33 are unarmed.</p>
                    <div className="pagebreak" id="pb.1.271">[Page 247]</div>
                    <p>Of the Artillery:</p>
                    <p>11 companies are armed with 6-pounder field guns, with carriages and implements complete, and artillery swords.</p>
                    <p>1 with 6-pounder field guns, swords and Sappers and Miners' musketoons.</p>
                    <p>1 with 6-pounder field guns, swords and artillery musketoons.</p>
                    <p>1 with six 12-pounder howitzers and light artillery swords—and</p>
                    <p>12 are unarmed.</p>
                    <p>Of the Light Infantry:</p>
                    <p>6 companies are armed with rifle muskets.</p>
                    <p>75 " " " " percussion "</p>
                    <p>26 " " " " flint lock "</p>
                    <p>4 " " without arms.</p>
                    <p>Of the Riflemen:</p>
                    <p>4 companies are armed with long range rifles, with sword attachment. 24 companies with percussioned rifles.</p>
                    <p>10 companies with flint lock rifles, and</p>
                    <p>76 companies are without arms.</p>
                    <p>All the armed companies are uniformed. The numerical strength of the armed force is:</p>
                    <p>Cavalry, with sabres and pistols, or sabres only, 2,547</p>
                    <p>Unarmed, about 1,650</p>
                    <p>4,197</p>
                    <p>Artillery—Armed companies, 820</p>
                    <p>Unarmed " 660</p>
                    <p>1,480</p>
                    <p>Light Infantry Companies with rifled muskets 400</p>
                    <p>Companies with percussion do. 3,830</p>
                    <p>Companies with flint lock do. 1,300</p>
                    <p>Companies unarmed 250</p>
                    <p>5,780</p>
                    <p>Riflemen—Companies with long range rifles 330</p>
                    <p>Companies with percussioned rifles 1,320</p>
                    <p>Companies unarmed 3,600</p>
                    <p>5,250</p>
                    <p>Making an aggregate of 16,707</p>
                    <div className="pagebreak" id="pb.1.272">[Page 248]</div>
                    <p>The military spirit which pervades the State, as evidenced by the correspondence of this office, would, I believe, in case of emergency, double the militia force, by men above 45, perfectly able and more than willing to bear arms if the State shall need their services.</p>
                    <p>Very respectfully,</p>
                    <p>Your obedient servant,</p>
                    <p>WM. H. RICHARDSON, A. G.</p>
                  </div>
                </div>
                <p>CLERK OF COMMITTEE ON FEDERAL RELATIONS</p>
                <div className="speaker" id="sp313"><span className="persName">Mr. R. Y. CONRAD</span>—</div>
                <p className="p-in-sp">
                  In the report of the committee for fixing the compensation of officers of this Convention, which was adopted the other day by the Convention, no provision was made for the compensation of the clerk of the Committee upon Federal Relations. This omission was made, as I understand, in order that the committee might itself fix that compensation. They have accordingly done so and directed
                  me to ask for the confirmation of their action from the Convention. I will add that the duties of our excellent clerk occupy more time than those in the cases of clerks of committees of the House of Delegates and the Committee on Federal Relations have fixed the compensation of their clerk at the same rate.
                </p>
                <p>The resolution was then read as follows :</p>
                <p>"Resolved, That the clerk of the Committee on Federal Relations be allowed as compensation for his services at the rate of $28 per week."</p>
                <p>The resolution was agreed to.</p>
                <p><span className="persName">Mr. WILSON</span> offered the following resolution :</p>
                <p>
                  Resolved, That the Auditor of Public Accounts be requested to report to this Convention whether any loss has been sustained to this State, from the manner in which the Commissioners of the Board of Public Works have disposed of the bonds of the State; if so, what the loss is, of what it consisted, and when it occurred. And that he be also requested to report to this Convention the
                  amount levied by each county of the State for the year 1860 for the compensation of Justices of the Peace. Also, the amount paid to jurors for services rendered in the county courts from the State and county treasuries during the same period.
                </p>
                <div className="speaker" id="sp314"><span className="persName">Mr. WILSON</span>—</div>
                <p className="p-in-sp">
                  I do not desire to retard action upon the subject of Federal Relations. I have been informed that the State has suffered largely from loss from the source I have indicated. I would like to have the Convention possessed of the information which it is necessary they <PageNumber num={273} /> should have upon this subject. I therefore ask that the Auditor
                  make a report upon this subject, which he can furnish with very little trouble.
                </p>
                <p>On motion of <span className="persName">Mr. BRANCH</span>, the resolution was laid on the table.</p>
              </div>
              <div className="section" id="vsc1965.v1.2.12.2">
                <h3><span className="headingNumber">1.12.2. </span><span className="head">REPORTING THE DEBATES</span></h3>
                <div className="speaker" id="sp315"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">The Chair will state to the Convention that in obedience to what is understood to be an order of the body, the contract with the proprietors of the "Richmond Enquirer" has been closed, upon the precise terms indicated in the proposition, which was approved by the Convention, and under the advice of the Secretary of the Commonwealth.</p>
                <div className="speaker" id="sp316"><span className="persName">Mr. MORRIS</span>—</div>
                <p className="p-in-sp">If it be in order, I would move to amend that contract in one particular, and I therefore offer the following resolution :</p>
                <p className="p-in-sp">"Resolved, That each member of the Convention be entitled to receive for distribution 100 copies of the daily proceedings of the body, instead of the number now provided for by the contract with the Reporters of the Convention."</p>
                <p className="p-in-sp">
                  I understand that by the contract, the members of this body are entitled to receive twenty copies of the proceedings of this body for distribution among their constituents. I am informed by the Reporters of the Convention, that the additional cost of furnishing eighty copies more would only be the cost of the paper upon which the proceedings are printed. I imagine it will be
                  acknowledged on all hands, that our constituents are anxious to be informed as to what we are doing here from time to time.
                </p>
                <p className="p-in-sp">
                  In such a crisis as this in our country's history—such as has never existed before—and on account of the deep interest which the people of the Commonwealth take in our action, the members of this Convention must desire, at least, to have as many as 100 copies each for circulation among their constituents, and especially, when it can be done with so little additional expense to the
                  Commonwealth.
                </p>
                <div className="speaker" id="sp317"><span className="persName">Mr. HAYMOND</span>—</div>
                <p className="p-in-sp">I am sorry to say that I can not support the resolution. It seems to me that the increased number asked for is too large, and that it will add very much to our expenses. I therefore move to lay the resolution on the table.</p>
                <p>The motion was agreed to and the resolution was laid on the table. The Convention then, on motion, adjourned.</p>
              </div>
            </div>
            <div className="day" id="vsc1965.v1.2.13">
              <PageNumber num={274} />
              <h2><span className="headingNumber">1.13. </span><span className="head">THIRTEENTH DAY</span></h2>
              <div className="dateline">Thursday, <span className="date">February 28</span></div>
              <p>The Convention met at 12 o'clock, M.</p>
              <p>Prayer by the Rev. Mr. Burrows.</p>
              <div className="speaker" id="sp318"><span className="persName">The PRESIDENT</span></div>
              <p className="p-in-sp">-The unfinished business of yesterday is now in order.</p>
              <p><span className="persName">Mr. MORTON</span> was entitled to the floor, but gave way to <span className="persName">Mr. RICHARDSON</span>, of Hanover, to enable him to offer the following resolution, which was adopted :</p>
              <p>
                Resolved, That in furtherance of the resolution adopted by this Convention on the 20th inst., seeking information of the Governor, regarding the militia, the Adjutant-General of the State be and he is hereby requested to communicate to this body as speedily as compatible, with a thorough report on the subject, how many and what kind of arms are in the possession of the State
                undistributed, and the number and kind of additional companies which can and will probably shortly be armed. Also, at what points in his judgement having due reference to the localities of the different companies and to economy in time and money, the whole volunteer force of the State can be best assembled in bodies sufficiently large to be instructed, in battalion evolutions, in the
                evolutions of the line, in siege, garrison and camp duties, and all the duties incident to the respective arms of the service, and any other information in his reach calculated to throw light on the means necessary to put the Commonwealth in a complete state of preparation against attack.
              </p>
              <div className="speaker" id="sp319"><span className="persName">Mr. EARLY</span></div>
              <p className="p-in-sp">-A resolution was offered yesterday by one of the gentlemen from the country of Harrison [<span className="persName">Mr. WILSON</span>] which was laid upon the table because it was not germain to any of the proceedings that have been had in this Convention, and because it comes under the Legislative department of this State. I think, sir, that a resolution of that sort has more connection with the action of the Legislature than of this body, and I therefore move to lay the resolution on the table.</p>
              <p>The motion was agreed to.</p>
              <p>The resolution referred to is as follows :</p>
              <p>
                "Resolved, That the Auditor of Public Accounts be requested to report to this Convention whether any loss has been sustained to this State, from the manner in which the Commissioners of the Board of Public Works have disposed of the bonds of the State; if so, what the loss is, of what it consisted, and when it occurred. And that he be also
                <PageNumber num={275} /> requested to report to this Convention the amount levied by each county of the State for the year 1860, for the compensation of justices of the peace. Also, the amount paid to jurors for services rendered in the county courts from the State and county treasuries during the same period."
              </p>
              <div className="speaker" id="sp320"><span className="persName">Mr. JEREMIAH MORTON</span>, of Orange and Greene, said :</div>
              <p className="p-in-sp">
                Mr. President : I feel deeply impressed by this august occasion, and by the vast importance of the deliberations of this assembly. But I could feel more satisfied on the present occasion if I knew that I was to have the aid of wisdom from on high; for if there ever was a time when men and nations should humble themselves before the Most High, it is this hour of our perplexity and
                danger. Never has there been convened an assembly, within the Union, upon the deliberations of which so much of the happiness of this people, and of mankind depend as upon the deliberations of this body. And, Mr. President, I trust that upon the present occasion, however unsuccessful I may be in presenting those views which impress me with that force and power which the present crisis
                demands; yet, I think I shall be successful at least in one thing—that there will be neither man nor woman here who will doubt the position which I occupy; and it will require not two days nor two speeches fully to define it. And I trust that my tongue may be palsied, if upon an occasion like this, it should be employed in the presentation of any views which I do honestly believe to be
                best calculated to promote the interests of the State and the country. I have listened, Mr. President, to the discussions of this body with pain and pleasure. I have heard the sentiments of my friend from Rockbridge
               [Mr. MOORE] whois not in his seat to-day, although I expected he would be present—with feelings of sincere regret, notwithstanding I was partly prepared to hear from him views very much of the character of those he has expressed.</p>
              <p className="p-in-sp">Thirty years ago the gentleman from Rockbridge [Mr. MOORE] 
                believed that slavery was a moral, religious and political evil. Does he believe it now? That was the belief of hundreds and thousands in the South and in Virginia, who, this day, stand up as firm advocates of the institution as a moral, social and religious institution; and I cannot help believing of my old and venerable friend from Rockbridge if it had not been for his peculiar
                position and surroundings; if he had been in the latitude in which I have been ; if he had looked to the lights which I had looked to, I believe, or rather hope, that we should be standing, to-day, upon the same platform.
              </p>
              <p className="p-in-sp">
                But, Mr. President, there is one comfort that I feel, that however <PageNumber num={276} /> much we may differ upon these questions, when Virginia takes her stand as she will, in defence of Southern rights and Southern honor, my friend will make as gallant a fight, under her honored banner, as the youngest man within the sound of my voice. I regret that
                my old and ancient friend, in my eye,
               [<span className="persName">Mr. GOGGIN</span>] who I know has, beating in his bosom as true and patriotic a heart as any man who listens to me—I regret that he found such difficulty in defining his position upon a question that I thought every man, at least East of the Blue Ridge, would feel but little difficulty in doing.</p>
              <p className="p-in-sp">
                But the gentleman has taken his position. It is when we come to coercion that he will stand under the flag of old Virginia, and rally to its support in the battle field. I know that his sympathies are with the South ; but there is one matter of regret that I have, and that is, that my friend, coming from the region he does, occupying a position so distinguished in that once
                distinguished and gallant party of other days, had not raised his voice in this hour of need and anxiety, and come up to the crisis with that boldness which should mark a member of that party in dealing with such a subject. I have no doubt it would have proven a source of joy to many of those who were associated with him in past party relations as it would to others occupying a party
                position antagonistic to his.
              </p>
              <p className="p-in-sp">But, Mr. President, there was a matter of profound regret, more in relation to the first in my eye, [Mr. MOORE] than to the last, and it was this, that whilst there was an acknowledgement of Southern wrongs, there was a captious disposition to find fault with our Southern and gallant friends. There was more of this spirit displayed in the speech of my friend on my left, [Mr. MOORE] than in the speech of my friend on the right, [<span className="persName">Mr. GOGGIN</span>] ; but there was an abundance of it in each. I know not, at least, I shall not undertake to define what was intended by this course of remark; but I am satisfied it could have no other effect than to generate in the hearts of Virginians antagonistic feelings towards South Carolina, Alabama and their associates in the new Confederacy.</p>
              <p className="p-in-sp">
                Mr. President, these gentlemen have not dwelt upon the wrongs that the South has suffered, and how long she has suffered. It would be almost insulting to an audience like this, that I should enter into any detail of the aggressions which have been committed upon the South by the people of the North. They are written upon the minds and in the hearts of every statesman. But inasmuch as
                these debates pass to the country, and knowing the influence of my friend from Bedford
               [<span className="persName">Mr. GOGGIN</span>] 
                I deem it my duty cursorily to present <PageNumber num={277} /> some views of the progress of these aggressions on the part of the North upon the South. And, Mr. President, I will venture to say that the history of the world has never presented a case in which a fanaticism striking into the vitals of a proud nation has progressed with the same rapidity as
                this abolition fanaticism has.
              </p>
              <p className="p-in-sp">
                Mr. President, my head is a little gray, and whilst I may be numbered now among the fathers of the land, I still feel that I am young enough to do some service. But as young as I am, it was my fortune five and thirty years ago, or about that time, to attend in the city of London a meeting of the antislavery society. Upon that occasion Mr. Wilberforce presided for the last time in
                public. The object of that meeting was to petition Parliament for the abolition of slavery in the West India islands. The distinguished men who addressed that meeting, were the Sir James McIntosh,<span className="note" id="Note36"><span className="noteLabel">1</span>The definite article is in the Enquirer. The reference is perhaps to Sir James Mackintosh.</span> Lord Denman, and Lord Brougham,
                and well I recollect the views presented by them. And permit me to say, Mr. President, at that day, I think, my views upon this subject were as extreme as my friend's from Rockbridge in 1832 or probably now; and if I ever envied the position of a man, I did that of Mr. Wilberforce, when by the eloquence of these statesmen and orators the tears were seen trickling down his cheeks as
                they delivered their powerful appeals upon the subject of African slavery. These gifted orators and distinguished statesmen presented their views emphatically to that anti-slavery society. "Gentlemen, petition to abolish slavery in the West India Islands, and if you succeed in that, slavery cannot stand in the Southern States of the United States. There will be a pressure upon it by
                the free States on the North and by the West India Islands on the South, which, with the moral power of the world, will soon crush it out."
              </p>
              <p className="p-in-sp">Little did I then think, having confidence in our Government, as the best that was ever given to man, that I should live to see the day when that dread struggle should be brought to my own home, and when I should be in council with statesmen of Virginia to devise means to drive out this fanaticism.</p>
              <p className="p-in-sp">
                Mr. President, this movement first began in England, and when they achieved their object they transplanted it to New England. It has been fostered by British gold and by British talent—it has been encouraged by American fanaticism and American gold. To go no farther back than the time that Mr. Van Buren was President of the United States we find that this movement was then inaugurated.
                I met him in the presence of my friend in my eye
               [<span className="persName">Mr. MACFARLAND</span>] 
                , in the mountains of Virginia, and he then declared to me that abolitionism <PageNumber num={278} /> was a speck so small and insignificant that we Southern men need not feel the least anxiety about it. It was only a hand-full; and yet ten years after, Mr. Van Buren himself became a candidate for the highest official office in the gift of the people of
                that very party.
              </p>
              <p className="p-in-sp">
                Mr. President, ten years ago, or eleven, I was honored with a seat in the House of Representatives. At that time there were, I think, but four free-soilers in the Senate of the United States; now, I believe, there are four and twenty. At that time, however, such had been the progress of this abolition sentiment at the North, that the Whig party North had almost been entirely absorbed
                by the free soil party; and I recollect that I was one of the few Whigs from the South, who with one from Florida, three from Georgia and one from Alabama, stood in the breach for three weeks and kept the House disorganized, because we required the Whig party, if we acted with them, to renounce the Wilmot Proviso. We told them that we would not co-operate with our enemies to crush our
                section ; that if we were to co-operate with them they must show that they are our friends.
              </p>
              <p className="p-in-sp">
                Mr. President, at the meeting of the present Congress, you recollect the struggle that there was for the speaker, because of the infamous Helper book—a book which recommended every crime that human intellect can point to, or the depravity of the human heart can conceive. That book recommended every crime for the suppression of slavery in the Southern States—arson, murder, burglary,
                poison in every shape; and, Mr. President, that book, atrocious as it was, received the endorsement of sixty-eight members of Congress, and the special endorsement of Wm. H. Seward, the expected Premier of Abraham Lincoln, and who is in the mouth of all hopeful patriots in the South as the man likely to lay the basis of some satisfactory adjustment of this question. Seward stated that
                he examined this book minutely, and he recommended it confidently as a proper book to be read. Now, sir, after that struggle for the Speakership, of some six weeks duration, they had to abandon the candidate they originally started, which, however, was not a renunciation of any principle they held, but a mere yielding to expediency. They adhered to the same principles which governed
                them in their original choice, for we find when the Presidential canvas came on, which unfortunately culminated in the election of Abraham Lincoln in November last, that five hundred thousand copies of the Helper book were distributed throughout the North as an electioneering document.
              </p>
              <p className="p-in-sp">
                Mr. President, our soil has been invaded ; our rights have been violated; principles hostile to our institutions have been inculcated in <PageNumber num={279} /> the Northern mind and ingrained in the Northern heart, so that you may make any compromise you please, and still, until you can unlearn and untea ch the people, we shall find no peace.
              </p>
              <p className="p-in-sp">Mr. President, suppose you were to take a boy of the size of that boy, [pointing to one of the pages] 
                and have him taught by a mother, and by a father, and by a preacher, and by every teacher, in the Catholic religion, in a Catholic country; do you not think it would be a miracle, if, at the age of twenty-one, he would not be a Catholic?—or a Protestant, if instructed in the doctrines of that church, by a similar process? When we find, therefore, that in the Northern States the youth
                are instructed in the nursery, in the schoolhouse, in the church, by the press, to regard slavery as a sin and a crime, and those who cherish it as a social institution, unworthy of recognition upon a footing of social equality with the people of the North, and fit objects of the scorn and contempt of the world—I ask you what is the hope, what is the chance of effecting a change?
              </p>
              <p className="p-in-sp">
                Mr. President, by the election of Mr. Lincoln, the popular sentiment of the North has placed in the Executive Chair, of this mighty nation a man who did not get an electoral vote South of Mason and Dixon's line, a man who was elected purely by a Northern fanatical sentiment hostile to the South. And, Mr. President, recollecting your antecedents, I am reminded of the high veneration in
                which you and myself in common with many within the sound of my voice, held Millard Fill-more; with what pleasures we once rallied to his standard. Will you forget his teachings? Will my friend from Bedford,
               [<span className="persName">Mr. GOGGIN</span>] 
                forget his teachings? When it was expected that Fremont would be elected to the Presidency in 1856, in addressing a Northern audience, he appealed to them to know whether, if the South was to elect a sectional President upon principles hostile to the North, getting not a single vote from the North, whether they ought to or would submit to it. What was the response? Never. Yes, he said
                never; and they never ought. And that very sentiment you and I, sir, and the gentleman from Bedford
               [<span className="persName">Mr. GOGGIN</span>] and the gentleman from Halifax, [<span className="persName">Mr. FLOURNOY</span>] fully endorsed at that day. I am for carrying it out now, and I trust the Convention will agree with me in that policy.</p>
              <p className="p-in-sp">
                Mr. President, we have suffered wrongs and grievances. The Government is no longer a Government of equal rights. Our enemies have now command of the Executive Department, they have command of both branches of Congress. How long will it be before they will have command of the Judiciary Department. You may judge of that from the fact that a recent nomination to the Senate of the United
                States of <PageNumber num={280} /> a national man has been postponed—rejected, I believe—in order that that patronage may fall into the hands of the incoming administration. Who will a Black Republican President and a Black Republican Senate confer that appointment upon? I am led to believe that that, like many others, will be carried over this
                Commonwealth and Kentucky and Tennessee, and the man of the most distinguished talents and the nearest approximation to the Black Republicans is the man upon whom that high station will be conferred.
              </p>
              <p className="p-in-sp">
                They will administer the Government for the strengthening of the party; they will make capital out of every appointment; and, Mr. President, with a Government, every Department of which shall be in the hands of the Black Republicans, administered upon the principles upon which William H. Seward and Abraham Lincoln will administer it, how long would our institutions be safe? What are
                the present expenditures of the Government? They have approximated to nearly $80,000,000 annually. What will be the expenditures of the incoming Administration, under the corrupting process of the Federal Government, I do not care in whose hands it may be, or what may be the political party in power I shall not undertake to say.
              </p>
              <p className="p-in-sp">
                Whenever it comes to the administration of the spoils with the view to the advancement of party—and that for many years has been the general type of all administrations—what are the number that will be purchased up by the patronage of the Government? I do not mean to say, Mr. President, corruptly. But, when there is a fat office which is tendered, and the aspirant for that office knows
                how important it may be that his opinions should be identical and should assimilate with the powers that be, how natural it is for a man under circumstances like these to satisfy himself that he once was a little wrong, and that the sober, second thought, is the best position. This is human nature. How often does it come to pass that a lawyer of distinguished talents and unquestioned
                integrity, receives a fee in a bad cause, and yet, in the hour of investigation, in endeavoring to induce the court and the jury to think that the wrong is the better side, he produces the very same effect upon his own mind?
              </p>
              <p className="p-in-sp">
                And I tell you Mr. President, that Abraham Lincoln will seek to hold a power over all the Southern States—and permit me to tell you that I will venture to say that if you stay back for the next twelve months there will be more beneficent showers of public patronage upon Virginia and Maryland and Tennessee—I think he would hardly go to North Carolina—but he will go to Kentucky and
                Missouri, sooner than to any other States. The Republicans will seek to commend their administration <PageNumber num={281} /> to the border States, to hold them where my friend from Rockbridge, I think, would have them to go.
              </p>
              <p className="p-in-sp">
                And, Mr. President, when a man gets a rich office, how many friends circle around him to congratulate him, the kindness to whom is sympathized in by them; and the donee of a fat office be it a Judgeship, be it a Collectorship, be it a Postmaste'r of this city—has much power, and each one will form a nucleus of sympathizing friends with the powers that be. And, Mr. President, will this
                principle be carried out? Let us acquiesce, and I tell you that in the next Presidential canvass if not in the next, in the second ; certainly in the third—you will find Black Republicans upon every stump, and organizing in every county; and that is the peace that we shall have from this 'glorious Union."
              </p>
              <p className="p-in-sp">Mr. President : I know that there are some who are strangers to me, who may think that I have never been a Union man, that I am rashly throwing away, as they conceive, the greatest boon that has ever been a gift to man. My friend, in my eye, from Halifax [<span className="persName">Mr. BRUCE</span>] 
                and myself, I think, have voted together in every Presidential election, as has my friend from Richmond. I know not of a single occasion in which we have not voted together. I voted at the last Presidential election for Bell and Everett. But whilst I gave that vote, I made the declaration before the Charleston Convention met, that if the Convention succeeded in nominating any man, I
                would go for that nomination; and I would have redeemed that pledge. And I believe that if they had concurred in a nomination, there never would have been a nomination at Baltimore. But when the Convention divided, and I was satisfied that two Democratic tickets run together, could not defeat the Republican ticket—because my object was to defeat the Republican ticket—then I concluded
                that the best chance was to rally with my old friends around the Bell and Everett standard, hoping that, North and South, there would be a conservative sentiment springing up that would have resulted in the defeat of the Republican candidate.
              </p>
              <p className="p-in-sp">
                In that hope, Mr. President, I was disappointed, although I confess that my hope was very faint. I have been and am a friend of this Union. I have everything to bind me to it. I have admonished the foes of the Union to desist from their assaults upon the South. I have implored them to do so, with all the power and force and earnestness of which I was capable, upon the floor of
                Congress, as I have also in private circles.
              </p>
              <p className="p-in-sp">And, Mr. President, in order to place myself right upon that subject, with the kind permission of this House, I will read an extract from <PageNumber num={282} /> a speech which I delivered in the memorable compromise Congress of 1849 and '50. The extract is as follows :</p>
              <p className="p-in-sp">
                "On the question of Constitutional justice, I demand with what propriety can it be asked by Northern men that the common property of the Union, paid for out of the common treasury and won by the common valor, should be monopolized by the North? That the South should have no part nor lot in the matter? That a slave State to be formed out of that territory should not be admitted into the
                Union? Are you treating us as equals? I ask, gentlemen, are you treating us as freemen—as the descendants of noble sires? Our fathers were the equals of your fathers; their sons are your equals. We claim no superiority, but, with the blessing of God, with strong hearts and firm nerves, let whatever will come, the South cannot and will not permit the brand of inferiority to be marked
                upon her forehead.
              </p>
              <p className="p-in-sp">
                "We ask, then, nothing but justice. Are you willing to award us justice? If you are, there will be peace, and Union, and prosperity, and happiness. But if this Government, which was intended as a shield—and a shield to the weak—should trample under foot the rights of the South, and the provisions of the Constitution; if the North appeal to the ballot-box, (for the ballot-box has become
                sectional on this question) , and ask us to submit, we will appeal to a violated charter ; we will stand on the very foundation on which our fathers stood; we will stand on the principles of '76, and the same spirit that animated them will animate their descendants. And now, gentlemen, say, will you render us justice? We ask but our rights. Think not you can press us to the wall, and
                meet no resistance. No! Greek will meet Greek. You are as brave as we are, but your arm will be unnerved in such an unholy crusade. We will battle upon our own soil, for our wives, our children, our hearthstones. A coward will be a hero.
              </p>
              <p className="p-in-sp">"He is trebly armed, whose cause is just."</p>
              <p className="p-in-sp">"Press that issue upon us, and we will appeal to the God of battles with more faith and stronger confidence than our fathers did, when they pledged 'life, fortune and sacred honor.'</p>
              <p className="p-in-sp">"Mr. Chairman, what were the great objects for which the Constitution was formed? The preamble declares : 'To form a more perfect Union, to establish justice, to ensure domestic tranquility, to provide for the common defence, to secure the blessings of liberty to ourselves and our posterity.'</p>
              <p className="p-in-sp">
                "Does the agitation of the slavery question establish justice? To this there is a negative response in the heart of every honest man. Does it <PageNumber num={283} /> ensure domestic tranquility? Let the embittered feelings, the widespread dissensions, the fearful apprehensions which now distract Congress, are convulsing the nation, and shaking our Union
                to its deepest foundations—let them answer. Does it tend to the common defence? In union there is strength—in discord, weakness. Does it secure the blessings of liberty to ourselves and our posterity? It is hazarding these blessings to every section of this Union. Speak, then, men of the North, the calming words, 'peace, be still!' "
              </p>
              <p className="p-in-sp">
                Mr. President, I have read that extract to show you that my heart has been in this Union and my brightest hopes connected with its welfare. I have but one child, and her destiny is linked with a Northern man. But he is upon Virginia soil, and like many of the Northern men who are with us for a time, has become true, and even truer than her own native sons to the rights of the South.
                But his kindred are in the North, and his sympathies with parents and relatives and friends give me an interest in his behalf ; so that I would do what I could to preserve the Union, if I could preserve it upon terms of honor and of safety.
              </p>
              <p className="p-in-sp">But, Mr. President, it is as apparent to my mind as the sun at mid-day, that unless we can have security—and I am not speaking of such securities as this miserable abortion which the Peace Conference presents—unless we can have securities of political power, I say that this Union ought to be, and I trust in God will be dissolved.</p>
              <p className="p-in-sp">Mr. President, why do I say this? I say it is the sentiment of my heart, that this question must be settled, and settled forever ; and that is the sentiment of the people in the country ; and I will venture to say, if I have the opportunity of reading the addresses of my friend from Rockingham [<span className="persName">Mr. GRAY</span>] 
                , and of knowing the will of his constituents, that you would find that the same sentiment would be impressed upon their minds—that we must have a settlement of this question, and a settlement forever. It has been an ulcer in our side for thirty years. It is sending its roots deeper and deeper into the system ; and unless we make an effectual use of the knife, and the caustic to burn
                out the root forever, it will result in our destruction.
              </p>
              <p className="p-in-sp">
                When I say that the tribulation which our people has experienced from the North to the South, from the Atlantic to the Pacific, upon this question; that men in every branch of the business of life do not know how to shape their contracts because of the agitation every four years of this never-dying question of African slavery—I say, I want to see this question put to rest, not where it
                will spring up to disturb my children and involve them in utter ruin twenty or thirty years <PageNumber num={284} /> hence ; but I want to put it where it never will disturb my descendants—for if there is to be bloodshed, and this question cannot otherwise be settled, I would rather give the blood that runs in my veins, to preserve that which is in the
                veins of my helpless offspring.
              </p>
              <p className="p-in-sp">I say, then, this question must be settled. You have said so, the country has said so. It is the peace of the world that we call for. How can the question be settled? My friend from Bedford [<span className="persName">Mr. GOGGIN</span>] 
                referred to a National Convention. If that National Convention had been held in time, we might, perhaps, have had some hope from such a source. Who is to blame that there was not such a National Convention? Can it be said that that much-abused little State, South Carolina, which this day I honor as much as I do the heroes of Thermopylae, is to blame? No, that gallant little State
                cannot be complained of, for when you were invaded here and your soil was bathed in Virginia blood, and you,
               [turning to Governor WISE] 
                like a true man, led the hosts of Virginia to repel the invader, however you may have received the censure of some, I do say every lady of Virginia paid you her meed of applause, and I tendered to you personally my thanks and my approbation. When Virginia was invaded and you were in the Gubernatorial chair, South Carolina sent her Commissioner here to sympathize with Virginia, to make
                the grievance a common cause, and to invite co-operation in devising measures for future security. Mississippi did the same. And how were they received and how were they treated? They were treated kindly, as gentlemen. You received them cordially as the Executive of the State. But the Legislature, after the most powerful and thrilling appeal, almost that I think I ever heard, the
                Legislature of Virginia determined that she could take care of herself, and that each sovereign State could take care of itself. And after South Carolina had thus been turned away from the door of Virginia, and the time had come when she
               [South Carolina] felt that her honor and her safety were involved—because she took the responsibility of an independent sovereign State, she is denounced upon this floor for rashness, for indiscretion, for a want of deference, that she did not invite Virginia to a further council.</p>
              <p className="p-in-sp">I say, sir, judging of nations by men, there is not a man who has brought forward this complaint—including my gallant friend from Rockbridge [Mr. MOORE] 
                if he was in his seat, but would have cut his right arm off, if, in a common danger, he had appealed to a common friend to unite against a common enemy, and he had told him coldly, "I will take care of myself—you take care of yourself." When the danger came he would recollect that, and he would have hazarded <PageNumber num={285} /> his life before he
                would have invited that friend to a mutual council for mutual protection again. So that while my friend from Bedford complains, let him complain of the right source. Do not complain of gallant South Carolina and Mississippi, but complain of Virginia. If there is blame to rest anywhere, then denounce Virginia—denounce your legislature. I am casting no censure upon those who took a
                different course. I told many of them at the time, "you are acting unwisely and you will bring about the very thing that you wish to avoid." They were very adverse to going into a Southern council. because if they went into a Southern council they were fearful that the action of South Carolina and of other States would result in a dissolution of the Union. I believed then and I believe
                now, that if Virginia had taken her stand in that Southern Council she would have the same influence then that she did have in the times of the Revolution. Had Virginia, however much they might have differed from her, looking to her ancient fame, looking to her great material interest, looking to her position, had Virginia taken the stand which patriotism required of her, South
                Carolina would have yielded, and other Southern States would have yielded to the counsels of Virginia. But Virginia rejected the offer and the crisis came.
              </p>
              <p className="p-in-sp">After the election of Mr. Lincoln it was believed by the Southern States that there would not be time to organize a Southern Conference to take definite action so as to meet the crisis by the fourth of March; and I appeal with candor to my friend from Bedford [MR. GOGGIN] 
                if, in the month of October or the month of September, there should have been an appeal from South Carolina to Virginia to go into a conference, whether she would not have received the same answer? No, sir; South Carolina has done her part to preserve the Union. And if Virginia had exerted herself as South Carolina has, and had been as sensitive to Southern rights and Southern wrongs
                as South Carolina has been, this Convention would not have been to-day in deliberation.
              </p>
              <p className="p-in-sp">
                But, Mr. President, whilst I am upon that subject, I will do further justice to South Carolina. I do not know whether I heard upon this floor that she acted cowardly in going out alone; but I have heard it often in the country, if not here. And I have often replied to an imputation of that kind in this way : "It would show a very gallant man who would go to South Carolina and proclaim
                that before South Carolinians." Whilst I have seen many of my fellow-citizens from all the States of the South and of the North, and whilst my position for several years past has enabled me to mingle freely with them, I can say that among all my warm-hearted friends from the South and from Virginia <PageNumber num={286} /> —for they all have a seat in my
                inmost heart of hearts—yet, I must assert that if there is one portion nearer the centre than another, it is gallant South Carolina. I have never known of more refinement and less assumption among any class of 'gentlemen than among the South Carolinians. I say that in vindication of South Carolina.
              </p>
              <p className="p-in-sp">And whilst I am upon that subject, I will vindicate her still further. My friend from Rockbridge [Mr. MOORE] 
                , spoke of the disrespect with which South Carolina treated the Virginia Commissioner. I saw that Commissioner yesterday, specially, with a view of enquiring what was the fact concerning that matter. He told me that he had made an official communication to this Convention, which was in print, and I presume every member of this body has received it. He said, that whilst his heart was
                warm by reason of the attention which was paid to him as the Representative of Virginia, if it was warmer towards any one spot more than another, that place was Charleston; that there could not have been more respect shown to the Representative of any State than was paid by South Carolina to the Representative of Virginia. And whatever may be the efforts of my friend from Bedford and
                my friend from Rockbridge to speak of a feeling and to generate a feeling between South Carolina and Virginia, because of what they will have the organ of South Carolina, the Charleston Mercury say, the Commissioner tells me, as the sentiment of every heart, that there is a warm sympathy with Virginia; and whilst they would rejoice in a Union with any of the border States, Virginia,
                the oldest, the most interested, and her fame more distinguished than any—she is the one they would prefer.
              </p>
              <p className="p-in-sp">
                I can but express my astonishment that in a body so grave as this, not an assemblage in which we are candidates for official position; but a body representing the aggregate sovereignty of Virginia in its greatest wisdom—that it should be gravely presented here that the Charleston "Mercury" is the organ of South Carolina, and that the expression of the Charleston organ is the expression
                of South Carolina.
              </p>
              <p className="p-in-sp">
                I presume by this time, that the Convention is satisfied that the proper course for Virginia is to secede forthwith. As we are in revolutionary times, I want my position to be distinctly defined, whether it be followed by the blessings or the curses of posterity, or whether I act wisely or unwisely. These are the resolutions which were offered by myself, and almost unanimously adopted,
                at a meeting of the citizens of Orange, held at Orange Court House, on Monday the 24th of December last :
              </p>
              <p className="p-in-sp">1. Resolved, In consideration of the perilous condition of the <PageNumber num={287} /> country, it is the opinion of this meeting that a State Convention be called at the earliest possible day.</p>
              <p className="p-in-sp">2. Resolved, From the proceedings in Congress, and other sources of information, we believe the policy of the Republican party will be the coercion of seceding States.</p>
              <p className="p-in-sp">3. Resolved, That the Union of the South is the safety of the South.</p>
              <p className="p-in-sp">4. Resolved, As the opinion of this meeting, the cotton States will secede, that their destiny is our destiny; and that Virginia should retire before the 4th of March next, and place herself at the head of the column.</p>
              <p className="p-in-sp">5. Resolved, That every slave State should secede before the 4th of March, and co-operate afterwards.</p>
              <p className="p-in-sp">6. Resolved, That with a united South, Abraham Lincoln will not make war upon fifteen States; but should he do so, may God defend the right.</p>
              <p className="p-in-sp">
                Mr. President, I think if resolutions like these had been acted upon and promptly carried out, we would not have heard at this time any question as to the position we should take in the present crisis of our national affairs, and we would not have been listening to that famous committee of twenty-one, deliberating as to what is or what is not coercion. You would have found Virginia
                wheeling into line, as I would have had her wheel, with the other Southern States by the first of February; and she would now have been in Montgomery with every slave State in consultation, not making a Provisional but a permanent Government.
              </p>
              <p className="p-in-sp">
                The Union is already dissolved. If it is to be re-constructed, how can it be re-constructed with most safety to Virginia and to the South? Is it by Virginia standing, as she has been with the North and the South, in doubt, as to what her position is, whilst wrongs and insults have been heaped upon her continually? The course I have indicated as being the proper one for Virginia to
                take, would have been a wise, conservative measure; it would have organized the whole South, and we would have had to-day, under the Constitution, all the guarantees we wanted. I would not have called a National Congress; I would have had the fifteen States gone into consultation and made<span className="note" id="Note37"
                ><span className="noteLabel">2</span>Probably the text should read "go into consultation and make."</span
                >
                a Constitution to suit themselves, and I would have opened the door of admission to such as would come in upon principles of safety to ourselves.
              </p>
              <p className="p-in-sp">
                Permit me to say, that I would not have been in favor of an exclusive slaveholding Confederacy. No! Whilst I would have required all the <PageNumber num={288} /> guarantees necessary for our protection, I would have had those border States associated with us, whose interests in trade would have led them to the South—I mean New Jersey, Pennsylvania, Ohio,
                Indiana and Illinois. We would always have had the preponderance, and we would not have let them in until they had purified themselves in some degree of Abolitionism, and I would have a provision to this effect: that, whenever they agitated this question of slavery again, and whenever they proclaimed that they were holier than ourselves and we were not worthy to sit at the communion
                table with them, we should say to them : "Leave us."
              </p>
              <p className="p-in-sp">
                Gentlemen have spoken here of the dangers and horrors of war, as though the South had every thing to lose and the North had nothing to lose. It seems that gentlemen are very apprehensive that the border States of the North would make war upon the border States of the South. But is it not as much their interest as it would be ours to avert any such result? It has been suggested that a
                standing army would be necessary for the protection of our negroes, and my friend from Rockbridge [Mr. MOORE] I think magnified the expenses of supporting that standing army to something like $24,000,000. My belief is, if it be necessary to keep troops upon the line at all, as it might be at some point where it was necessary to collect the revenue, it would not be a tithe of the
                portion now required of us for the expenses of the army of the general government.
              </p>
              <p className="p-in-sp">
                But our friend tells us, that if we establish a Southern Confederacy, and bring a Canada to our borders, we shall lose all our slaves. I have no such apprehension; and I believe that our slaves will be as secure then, if not more so, than they are now. And why do I believe it? I believe it for several reasons : one is, that slavery is considered a festering sore by the fanatics of the
                North. They believe that they are responsible before God and the world, for the sin of African slavery, and that although it is within our borders, they must use all the means in their power to destroy it in the States, and never permit another slave State to be admitted into this Union. That is the platform upon which Abraham Lincoln was elected, and that is the platform upon which
                Wm. H. Seward has been standing for years, until at last he has become Premier of the President—a "power behind the throne, greater than the throne itself." I should judge from the letters and speeches that Mr. Lincoln has issued to the world since he has been elected President, that he may be conscientious in the views he entertains; but I believe also, if there is a man within the
                broad limit of this Union who will deserve and receive the curses <PageNumber num={289} /> of an indignant posterity for the breaking up of this glorious Union, that man is Wm. H. Seward; who in all his public acts has been governed by a desire to make political capital and to secure his political advancement. It is shrewdly suspected—and I believe there
                is truth in the suspicion—that there is an organization progressing throughout this Union, that looks to the keeping of the border States within this Confederacy, and that the High Priest of that new party is to be Wm. H. Seward. I say, sir, for one, that I had rather perish, than say a kind word to that arch fiend who has destroyed his country. I have denounced him to his face ; I
                have told him of his intentions; I told him two years ago, that if this question were between him and myself, we would settle it in an hour, and settle it forever.
              </p>
              <p className="p-in-sp">
                But, I say, Mr. President, that Virginia is not the Virginia of 1776. If Virginia has lowered herself so far as to receive such a compromise as has been tendered to us by the Peace Conference, it still leaves the question open. As mean and despicable as it is, to be scorned and spit upon, as it will be by every Virginian; there is not a man who believes that it can be adopted, and get
                a constitutional majority to make it an amendment to the Constitution.
              </p>
              <p className="p-in-sp">Where, Mr. President, are we to go?—that is the question which my friend from Rockbridge [<span className="persName">Mr. MOORE</span>] would not answer distinctly. I believe there are some around me who doubt where Virginia is to go. My friend from Bedford [<span className="persName">Mr. GOGGIN</span>] 
                for one day left this Convention and myself in apprehension as to where he would go, and although upon the second day he did give us light enough to induce us to believe that his sympathies were with the South, and declared that if they attempted war upon the South he would wrap himself in the flag of old Virginia and die buried in its folds—at the same time, how many ungracious and
                how many unkind things did he say of our gallant friends of the South. I may be permitted to express myself with some feeling in speaking in behalf of those noble States, when I tell you that I have friends there in whose veins blood kindred to my own courses. I know them to be a noble and gallant people, and I know that they have been fighting a common battle with us, in defence of
                rights as sacred to them as to us.
              </p>
              <p className="p-in-sp">Where shall Virginia go? It has been foreshadowed in a document from a high official source, that we were to have a Middle Confederacy. But for the great respect and personal regard that I have for its author, and for the high official positions which he has held for many years, I should call the scheme a—but I forbear. My friend from Bedford [MR. GOGGIN] also speaks of a Middle Confederacy, and <PageNumber num={290} /> as an argument against the establishment of a Southern Confederacy, he tells you, as did the gentleman from Rockbridge [Mr. MOORE] that you would have a line of 1500 miles to be defended by a standing army; and yet, by the establishment of this Middle Confederacy, you would have two lines, one North and one South. So that the evil of a standing army, if an evil it would be, would in his Middle Confederacy be doubled.</p>
              <p className="p-in-sp">
                While upon that point, let me say a word here, in relation to runaway slaves : If we formed a Confederacy of the slave States, alone in which the border free States would not become partners, we could have ample protection with proper securities. And what would be that protection? That protection would be a discriminating duty of five per cent against them, until they would give us a
                treaty stipulation that our fugitive slaves should be surrendered. I believe that would be effectual, because, although our Northern brethren love humanity, they love money more. That we should have fewer runaways, I am perfectly satisfied, from this fact, that when you cleanse the Northern mind of the sin of slavery and divest them of the idea that they are responsible for it, they
                would feel themselves as free from the sin of slavery here as they are from the sin of slavery in Cuba, Brazil and the rest of the world. Would you crush and put down this agitation? You never can, never will put it down.
              </p>
              <p className="p-in-sp">
                This crusade against slavery has been carried on in defiance of the Bible. Since the discussion of this subject commenced, some of our most eminent clergymen and scholars have given to it all the powers of their minds, and they have demonstrated with a pencil of light that it is a Bible institution, recognized both in the Old and New Testament, and that it is a moral and social
                institution, which may be regarded as the very pillar of our hopes and prosperity—and yet ruthless hands seek to destroy it.
              </p>
              <p className="p-in-sp">My friend from Bedford [<span className="persName">Mr. GOGGIN</span>] 
                , in admonishing us against going with the South, and to be cautious that we did not place ourselves in a worse condition than we occupy at present, stated that it would be competent for the Confederated Congress to do, what the Congress at Washington cannot do, according to the decision of the Supreme Court of the United States—that is, prohibit the inter-State slave trade. My friend,
                I know, did not intentionally misrepresent this matter, but I am surprised that a statesman of his distinction, upon a question of such vital importance, should venture to make the statement he did before a body like this, unless he was better informed <PageNumber num={291} /> as to the facts. Let me read the clause relating to this subject from the
                Constitution of the new Confederacy :
              </p>
              <p className="p-in-sp">"The importation of African negroes from any foreign country other than the slaveholding States of the Confederated States, is hereby forbidden, and Congress is required to pass such laws as shall effectually prevent the same."</p>
              <p className="p-in-sp">
                The gentleman smiles at this, as though he was not mistaken. If he was not mistaken in the fact, he was most infelicitous in the communication of the idea, because when Virginia should become a rnember of the Southern Confederacy, then the Confederated Congress could not interdict the trade between Virginia and the other States of the South. I am surprised that my friend from
                Rockbridge
               [Mr. MOORE] , my friend from Bedford [<span className="persName">Mr. GOGGIN</span>] , and other gentlemen here, should endeavor to create an excitement against the Confederated States, because the Confederated States of the South have frankly declared that Congress shall have the power to interdict the introduction of slaves into the Confederated States, from any other States outside of the Confederacy.</p>
              <p className="p-in-sp">"Congress shall also have power to prohibit the introduction of slaves from any State not a member of this Confederacy."</p>
              <p className="p-in-sp">
                The gentleman is an advocate for the interdiction of the African Slave trade. He will not receive a single African into our limits ; but if Virginia and the border States don't unite their destiny with the destiny of the confederated States, they will be just as foreign to the new Confederacy as Africa is to us. But the gentleman complains of the exercise of this power by the
                confederated States as a threat. If I mistake not, there is now a statute in our own code interdicting the introduction of any slaves from any other State into Virginia. It may have been repealed; if it has not been, I have violated it, for I have occasionally brought a slave from the South to Virginia; and I have intended never to violate the laws of my State. I understand the law of
                Virginia to be, that you may bring slaves here for your own use, but you cannot bring them here for sale. I do not consider, however, that that is material. What I complain of is, that my friend from Bedford
               [<span className="persName">Mr. GOGGIN</span>] , as has also my friend from Rockbridge [Mr. Moor] , endeavored to engender a prejudice against South Carolina and her sister States, because they avowed themselves in favor of exercising the privilege, if we continued strangers to them, of protecting themselves.</p>
              <p className="p-in-sp">
                But my friends from Rockbridge and Bedford, seem to be apprehensive that Virginia would act unwisely in going into a Southern <PageNumber num={292} /> Confederacy, because they might pass a law re-opening the African slave trade. What did the eloquent Commissioners from the States of South Carolina, Georgia and Mississippi, say upon this subject? They
                told us, that they do not intend to pass any such law; that it was not the sentiment of the South, and the Provisional Government have already interdicted it in the clause I have read. But because there are a few individuals at the South who avow themselves in favor of reopening this trade, are we, therefore, to be alarmed, and refuse to mingle our counsels with theirs? I have an
                extensive acquaintance in all the South, and I have had an ample opportunity to test this matter, and I state from my experience and knowledge of this subject, that there are very few persons there who advocate the re-opening of the African slave trade. Gentlemen need have no apprehension upon this subject. If Virginia and the border States should go into council with her sister States
                of the South, they could exercise, and would have a controlling power in regulating and keeping down, and suppressing this trade. It is, therefore, a very powerful reason why Virginia should unite her destinies with the Southern Confederacy.
              </p>
              <p className="p-in-sp">
                These gentlemen tell us also that there is unfaithfulness on the part of the South to the Union—that the African slave trade has been carried on. It is true that the history of the Government presents three instances of a prosecution for the introduction of African slaves, in which there has not been a conviction in a single case. Whether this failure to convict arose from public
                sentiment, or whether it was owing to a defect in the testimony, I cannot say. But is it not wonderful that when slaves in the South are worth from $1,500 to $2,000, before the agitation of this question, and smugglers could introduce them for $300 to $400, that there should have been so few cases of the unlawful importation of negroes? Where can you find higher testimony of the
                morality, integrity and character of the people of the South than is here presented? As much as I have mingled in the South, I do not recollect of seeing a single African introduced unlawfully there, whilst they are smuggled into Cuba by hundreds and thousands. There is not one-fifth part of the smuggling of Africans into the South, as I believe there is of smuggling by the Yankees at
                the North. In point of integrity and honor, I will place the citizens of the South by the side of Virginia or any part of the world. I believe you will find as high a tone of character and morality at the South, as you will find in any other section of the world.
              </p>
              <p className="p-in-sp">Before I conclude, let me refer to one or two more objections to a Southern Confederacy which have been presented by my friend from <PageNumber num={293} /> Rockbridge [Mr. MOORE] and my friend from Bedford [<span className="persName">Mr. GOGGIN</span>] 
                . My friend from Bedford asked, if Virginia were to go out of the Union where would she go? If she is going to the South, how will she get there? How will she get to Charleston? How will she pass through North Carolina and Tennessee, both foreign States? How will she pass by the battlements of the Rip-Raps and Old Point Comfort? He presented a picture that, in days gone by, would have
                alarmed the children and women, but in this day of our revolution, I bless God that the hearts of both men and women are not as the hearts of children.
              </p>
              <p className="p-in-sp">But, Mr. President, I take it for granted that my friend would agree with me in this, that if Virginia goes out, the Rip Raps and Old Point Comfort will belong to her, and if the General Government refuses to make the surrender and shall send the Lieut. General to hold them, if there is no one of more military experience than my friend in my eye [<span className="persName">Mr. WISE</span>] we will rally under him, and we will pluck the plume from the cap of the Lieutenant General. [Laughter.] But, Mr. President, I will promise my friend one thing, that if Virginia goes out, and North Carolina does not follow in two months, I will give him my head in a charger. [Laughter.] Yes, I will do that. Mr. President, North Carolina will be out, I don't care what Virginia does; and if we cannot get an ordinance of secession, I ask my friend in my eye [<span className="persName">Mr. GOGGIN</span>, the reporter presumes] 
                , to grant us one ordinance. I will demand it to prevent an effusion of blood—to prevent civil war in our midst, for you will have it. I will demand that you will give us an ordinance for the election of another Convention; and if you do that, if you won't find political tombstones rising in many a quarter, on many a hill and in many a valley over our departed political friends, I
                mistake the public feeling very much.
              </p>
              <p className="p-in-sp">
                Mr. President, I am not speaking without authority. I tell you that I could read you letters that I got from my constituents—not from mad caps, but sober, deliberate, conscientious, religious men of the Baptist Church, and of the Methodist Church; men of the first standing and the first influence. They ask me : What are you doing; shall we be compelled to resort to revolution? That is
                the sentiment in my county, and that is the sentiment that I presented in these resolutions. If you will go out, as I trust in God you will, you will not be deliberating with my friend from Rockbridge
               [Mr. MOORE] or my friend from Bedford [<span className="persName">Mr. GOGGIN</span>] 
                in a Central Confederacy. If you go at all you will go with your Southern brethren. If they give us the post of danger, they will also give us the post of honor. They want our <PageNumber num={294} /> statesmen; they want our military; they want the material arm of Virginia to sustain ourselves and them in the great struggles. But I ask you if there is a
                Southern man whose heart beats—I won't say where a Southern man's heart would beat—I do not want to be unkind and uncharitable—but I ask my friends to say where is the man who, speaking of the great interests of Virginia in connection with the Northern Confederacy, can forget that for thirty years they have been warring upon the fifteen States of the South; that they have been sending
                their emissaries into our families and our dwellings; that they have been poisoning our citizens and staining our own soil with the blood of our own citizens, when our wives and children, if they did not have the spirit of the matrons of 76, would have been alarmed—and some are alarmed—I say, with all of these transgressions committed by the North, I do not know, gentlemen, how you
                would feel in associating yourselves with them—I do not mean to make any reflections upon you ; but I will speak the feelings of my own inmost heart, and say that I should feel that I was base enough to bend my knee to my oppressor, to take the yoke upon my neck, and to present my own hand for the shackles of slavery—were I to agree to associate myself with a people who have thus acted
                towards us. These are my feelings. I wish to God that every man felt as I do. If they felt as I feel, we would have peace, we would have security, we would have this Confederation as it was if we desired it; or if we did not desire it, we would have a Confederation of the South. And is there a man who is a descendant of the patriots of 76, whose heart is chilled with fear in this hour
                of danger? They tell us of their nineteen millions and of our twelve millions. We have twelve millions, but four millions of them are Africans—four millions of these are slaves. But I tell you, gentlemen, and you, Mr. President, if the tug of war ever comes, I would rather have the four millions of slaves and the eight millions of free men, than to have sixteen millions of free men and
                no slaves. That, probably, will seem to be strange doctrine to my friends. But it is, nevertheless, true. We would have with eight millions a larger army than the North, and the South could have the sinews of war to support them. Give us four millions of slaves under the management and discipline of Southern planters and Southern men, and they will give you more sinews of war, than ten
                millions of free men, agitated with the cares of families and the harassments of military duties. So that if there is fear you cannot alarm the women—if you can alarm the men. And upon this point permit me to say, that I would not charge my friend from Bedford
               [<span className="persName">Mr. GOGGIN</span>] nor my friend from Rockbridge <PageNumber num={295} /> [<span className="persName">Mr. MOORE</span>] 
                , as seeking to generate any hostility between free and slave labor. But upon one branch of the subject my friend adverted to the condition in which we should be placed by uniting with the South, namely, that we would have our negroes taken off to the South, and that the Yankees would flow in and fill up the vacuum, thus defeating the very object that we have in view—the preservation
                of our institutions.
              </p>
              <p className="p-in-sp">
                Mr. President, I will not trespass longer upon the patience of the Convention to finish the remarks I intended to make, but at some other time I will endeavor to answer the gentleman; and I think I will be able to prove most successfully, that it will be for the interest of Virginia—her material interests—to unite with the South and not with the North; that her agricultural, her
                manufacturing and her shipping interests will be promoted by it. I tell you if the folly of the North compels Virginia to take her stand with the South—and we shall be fifteen slave States—that the material prosperity of the city of Richmond in 10 years, will be beyond what it will be in 30 years in this Union. I tell you that in 50 years the city of Norfolk will be a larger city than
                the city of New York; so that if we were to look to the material interests of Virginia, they would be promoted by going with the South, and leaving the North by itself. But as surely as we pursue that course, the border States will come upon bended knees and ask permission for admission into the Southern Confederacy, if they can get in upon no other terms.
              </p>
              <p className="p-in-sp">Mr. President, I return you and the Convention my thanks for your kind attention, and I regret that I have not had something more interesting to present to you, and that my strength would not enable me to complete all I proposed to say.</p>
              <div className="speaker" id="sp321"><span className="persName">Mr. BAYLOR</span>, of Augusta</div>
              <p className="p-in-sp">
                I desire, Mr. President, to say a few words upon the question now before this body. I am not known as a public man. I am a very obscure individual, I admit, but for all that, I do not hail from an obscure place. I am from the town of Staunton, in the county of Augusta; and the county of Augusta is within an inch of the centre of the Commonwealth of Virginia. I am glad to see that
                almost every gentleman who has yet undertaken to speak upon the subject before us, has not displayed anything like party politics. All seem to unite in opinion as to the impropriety of allowing party politics to enter into considerations or relations of the questions which command the attention of this body.
              </p>
              <p className="p-in-sp">
                I say, Mr. President, that I am glad of that, because it is to the principle of excluding party politics from this question, that I owe my <PageNumber num={296} /> seat in this body. It has been my fortune—whether good or bad I will not say—to belong to a party in politics that was always in a minority in the county of Augusta, and but for the fact that
                my people rose above party in electing delegates here, your humble speaker never would have been honored with a seat in this body.
              </p>
              <p className="p-in-sp">One resolution offered by my friend from Rockbridge [Mr. MOORE] 
                the very first, I believe—sets forth some grievances which have been committed by the Northern people against the South. It has been said often, and doubtless will be said again, that there have not been complaints enough urged against the people of the North; that they were not abused sufficiently; that it is not enough that these resolutions should set forth our grievances, but that
                we ought to go further, and abuse them in our speeches, and even in the resolutions which may be presented here.
              </p>
              <p className="p-in-sp">
                These aggressions of the Northern people upon our rights are not the result of laws passed by the Congress of the United States. Congress passed a law called the Fugitive Slave law in pursuance of a plain provision of the Constitution of the United States. The effect of this law is to enable the Southern people to get back their property when it escapes from them and goes into a free
                State. Some of the Northern States, not all—I believe Illinois and Indiana, at least, are excepted have passed what are called Personal Liberty Bills. In other words, they have passed laws to obstruct the execution of the Fugitive Slave Law. These acts we denounce ; but it is our duty first to say to the people of the North that these Personal Liberty Bills are unjust to us and our
                rights, and they ought to be repealed. But it is the universal practice of almost all men who have undertaken to say anything against these Personal Liberty Bills, to denounce the whole people of the North. They make indiscriminate denunciations of the people of the States that have not passed these laws, as well as against the people of those States that have.
              </p>
              <p className="p-in-sp">
                I have just said that these Personal Liberty Bills which have been passed, passed by the Legislatures of certain Northern States, are against the rights and the interests of the people of the Southern States; that we ought to demand their repeal; and I believe, in justice to us, that they will repeal them. I said that we denounced these laws and their originators; but, sir, we ought to
                remember at the same time what these laws are. We ought to remember that they are null and void, and that, so far as they are recognized by the federal authorities, they are no laws at all.
              </p>
              <p className="p-in-sp">
                When gentlemen complain of not getting their rights in the South, <PageNumber num={297} /> why do they not show to us where they fail to get them? No one will rise in his seat in this Convention and say that the Congress of the United States has not done ample justice to the South and the rights of the South upon that question. I say that, whilst it is
                wrong to pass these laws—they are but State laws—they are, in the eye of the Constitution of the United States, no obstructions to the execution of the Fugitive Slave Law. I know the effect of them is injurious, and so far as the Constitution of the United States is concerned and the laws made in pursuance of them, they can avail nothing.
              </p>
              <p className="p-in-sp">Mr. President, I have heard it said out of this Hall, and I think it has been said upon this floor to-day by the gentleman from Orange [<span className="persName">Mr. MORTON</span>] that the State of Virginia ought not to hesitate for one moment to go out of the Union. I do not know that I understood him correctly. If I did not, I beg leave to be corrected.</p>
              <div className="speaker" id="sp322"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">You did, sir.</p>
              <div className="speaker" id="sp323"><span className="persName">Mr. BAYLOR</span> continued.</div>
              <p className="p-in-sp">
                And for what? Go out for what? I told my people, Mr. President, before I left home, and also whilst I canvassed my county, that I would promise them to be at my post, and I would not only want to see the first man who would offer a resolution to take the old State of Virginia out of the Union, but I would want to hear his reasons for it. Sir, it has been said here that that ought to be
                done, but nothing has yet been advanced to satisfy me that there is any good reason for it. Go out of the Union for what, Mr. President? Why, it is said that the Union is already dissolved. Admit that it is, in part. Six or seven States have gone out. Six or seven of the links of the great chain which has bound this great Union together, have gone. But, Mr. President, there are
                twenty-six yet remaining, I believe. The old Commonwealth of Virginia is not among the number that have gone out. No, sir ; the stars and stripes are yet unfurled over her; and I, for one, say that I intend to hold on to the stars and stripes until the very last hope has fled. Some gentlemen complain of others, because they are too long in coming to any point at which they may be
                understood, as indicating the place where they stand. I trust, sir, that before I get through with my remarks, there will be no complaint of that sort concerning me. I intend to show to you, Mr. President, and I intend to let this Convention know, precisely where I stand, before I resume my seat. But, Mr. President, according to the usual custom, I would much rather if it would be the
                convenience of this Convention, for it to adjourn for the present.
              </p>
              <div className="speaker" id="sp324"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">If it is the pleasure of the gentleman to suspend his remarks till to-morrow, I would move that the Convention adjourn.</p>
              <PageNumber num={298} />
              <div className="speaker" id="sp325"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">Before the Convention adjourns, I wish to announce that I have received a communication from the Dean of the faculty stating that he had been instructed by the Faculty of the Medical College of Virginia, to invite the members of the Convention to the annual commencement of the institution, at Metropolitan Hall on Friday evening, March 1st, at 8 o'clock.</p>
              <p>The invitation was read and accepted, and, on motion of <span className="persName">Mr. EARLY</span>, the Convention then adjourned.</p>
            </div>
            <div className="day" id="vsc1965.v1.2.14">
              <PageNumber num={299} />
              <h2><span className="headingNumber">1.14. </span><span className="head">FOURTEENTH DAY</span></h2>
              <div className="dateline">Friday, <span className="date">March 1</span></div>
              <p>The Convention met at 12 o'clock, M., and was called to order by the President.</p>
              <p>Prayer by the Rev. <span className="persName">Mr. BURROWS</span> of the Baptist Church.</p>
              <div className="speaker" id="sp326"><span className="persName">Mr. GARLAND</span>—</div>
              <p className="p-in-sp">Mr. President, before the order of the day is taken up, I beg leave, with the permission of the gentleman from Augusta [<span className="persName">Mr. BAYLOR</span>] , to offer the following resolution :</p>
              <p>"Resolved, That an ordinance should be passed by this Convention, submitting to the qualified voters of this State the question, whether Virginia shall remain attached to the Northern Confederacy or whether she will secede and unite her destiny with the States of the Southern Confederacy."</p>
              <p>Referred to the Committee on Federal Relations.</p>
              <div className="section" id="vsc1965.v1.2.14.1">
                <h3><span className="headingNumber">1.14.1. </span><span className="head">THE VIRGINIA COMMISSIONERS</span></h3>
                <div className="speaker" id="sp327"><span className="persName">Mr. FISHER</span>—</div>
                <p className="p-in-sp">I have obtained permission from the gentleman of Augusta [<span className="persName">Mr. BAYLOR</span>] who is entitled to the floor, to offer the following resolution :</p>
                <p>"Resolved that the Commissioners to the Peace Congress from this State, be requested to make their report to this Convention at their convenience."</p>
                <div className="speaker" id="sp328"><span className="persName">Mr. PRICE</span>—</div>
                <p className="p-in-sp">I suppose the design of that resolution is to have the Commissioners make an oral report.</p>
                <p><span className="persName">Mr. FISHER</span> nodded affirmatively.</p>
                <div className="speaker" id="sp329"><span className="persName">Mr. PRICE</span>—</div>
                <p className="p-in-sp">
                  I am opposed to any such form of reporting. I want a written report—I want these Commissioners to write down what they have done. If the object is to get them to come here merely to make speeches, we should invite them to do so; but so far as this report is concerned, I am unwilling that it shall be submitted in any other form than in writing, that it may be placed duly on record.
                </p>
                <p className="p-in-sp">I move to lay the resolution on the table.</p>
                <p>The motion was agreed to.</p>
              </div>
              <div className="section" id="vsc1965.v1.2.14.2">
                <h3><span className="headingNumber">1.14.2. </span><span className="head">FEDERAL RELATIONS</span></h3>
                <div className="speaker" id="sp330"><span className="persName">Mr. ECHOLS</span>, of Monroe—</div>
                <p className="p-in-sp">Mr. President, I beg leave to offer the following resolutions, which I ask may be referred to the Committee on Federal Relations :</p>
                <p>Resolved, That, in the present political complications of our country, <PageNumber num={300} /> it is the duty of the Congress of the United States to recognize the separate and independent nationality of the States that have united themselves under the name of the Confederate States of America.</p>
                <p>That, concurrently with such recognition, a treaty should be made between the two Governments, which treaty should, among other things, fix, 1st, the perpetual prohibition of the African slave trade, and 2d, the free navigation of the Mississippi river.</p>
                <p>These resolutions were referred to the Committee on Federal Relations.</p>
              </div>
              <div className="secion" id="vsc1965.v1.2.14.3">
                <h3><span className="headingNumber">1.14.3. </span><span className="head">PERSONAL EXPLANATION</span></h3>
                <div className="speaker" id="sp331"><span className="persName">Mr. GOGGIN</span>—</div>
                <p className="p-in-sp">Mr. President, I ask the indulgence of the Convention for one moment to correct a remark of my friend from Orange [<span className="persName">Mr. MORTON</span>] 
                  . He has represented me as being in favor of a Middle Confederacy, I have nowhere advocated such a measure; but my resolution proposed and my remarks were designed only to enforce the propriety of concert of action with the border slave States, and one of my resolutions offered for the consideration of the Convention, in connection with my remarks, was in these words :
                </p>
                <p className="p-in-sp">Resolved, That it is the duty of Virginia now to invite the cooperation of all the slaveholding States, upon the border, so as to provide measures for their concurrent action hereafter.</p>
                <p className="p-in-sp">
                  Now, the object I had in view, by a council of the border slave States, was that we might unite in the attainment of common objects, and together to propose our plan, as the other Southern States have made theirs; then to invite their co-operation with us—to invite all the States to accept it as a plan of adjustment, made agreeable to all, and forever putting to rest these
                  distracting topics. As for any plan of a Middle Confederacy, I frankly say I have not considered it at all. My friend will, I am sure, accept these remarks in reference to his speech yesterday, as given in the same spirit with his own courteous bearing toward me. I might reply to some inferences, also, which he has drawn from some of my positions, as inconsistent with my views as
                  presented by me, but I deem it unnecessary to detain the Convention.
                </p>
              </div>
              <div className="section" id="vsc1965.v1.2.14.4">
                <h3><span className="headingNumber">1.14.4. </span><span className="head">FEDERAL RELATIONS</span></h3>
                <p><span className="persName">Mr. BOISSEAU</span> offered the following resolutions, which were referred to the Committee on Federal Relations :</p>
                <p>
                  Resolved, That the Union was formed by the voluntary co-partner-ship of the States, the equal and sovereign parties to the compact of Union, known as the Constitution; that the Federal Government is the <PageNumber num={301} /> creature and common agent of the States, created by them for certain purposes specified in the Constitution, and having no
                  power, except those delegated ; that when the people of Virginia, in Convention assembled, ratified the Constitution of the United States, they declared that the powers which they thereby granted, might be resumed by them whensoever the same should be perverted to their injury or oppression; and the people of Virginia, and not the Federal Government, are the judges of the necessity
                  which may compel them to resume those powers.
                </p>
                <p>
                  Resolved, That the occasion has now arisen which justifies the people of Virginia in resuming the said powers, and when they resume the same, they will then, as a free and independent State, have full power to levy war, conclude peace, contract alliances, establish commerce, and to do all other acts and things which an independent State may of right do ; and no government or people
                  on earth will have any right to collect revenue in any form, or under any disguise or evasion on her soil, or to interfere in any way with her commerce; nor to hold any forts, magazines, arsenals or dockyards in her limits; but the State will account with the United States for their rights of property in the same, on just, fair and equitable terms.
                </p>
              </div>
              <div className="section" id="vsc1965.v1.2.14.5">
                <h3><span className="headingNumber">1.14.5. </span><span className="head">STATE AFFAIRS</span></h3>
                <div className="speaker" id="sp332"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">The Chair has received a communication from the Auditor of Public Accounts, in response to the resolution of the gentleman from Preston [<span className="persName">Mr. BROWN</span>] , which I beg leave to submit for the consideration of the Convention.</p>
                <div className="speaker" id="sp333"><span className="persName">Mr. BROWN</span>—</div>
                <p className="p-in-sp">I move that the communication be laid upon the table, and printed.</p>
                <p>The motion was agreed to. [The communication having been sent to the printer of the Convention, the reporters could not obtain a copy of it in time for publication to-day. It will be inserted in our next paper.] 
                  <span className="note" id="Note38"
                  ><span className="noteLabel">1</span>This communication from the Auditor of Public Accounts has not been found in the Enquirer. It was printed as Doc. No. VIII, Report of the Auditor of Public Accounts, Showing the Aggregate Number of Persons Returned Delinquent by the Sheriffs of the Commonwealth, for the Non-Payment of Taxes for 1860; also the Aggregate Amount of Such Taxes.</span
                  >
                </p>
              </div>
              <div className="section" id="vsc1965.v1.2.14.6">
                <h3><span className="headingNumber">1.14.6. </span><span className="head">PERSONAL EXPLANATION</span></h3>
                <div className="speaker" id="sp334"><span className="persName">Mr. MOORE</span>, of Rockbridge—</div>
                <p className="p-in-sp">With the permission of the gentleman from Augusta, I will ask the indulgence of the Convention while I make a personal explanation.</p>
                <p className="p-in-sp">I find reported in the "Enquirer" of this morning what purports to be a speech delivered by the gentleman from Orange [<span className="persName">Mr. MORTON</span>] on yesterday.</p>
                <p className="p-in-sp">In that speech, sir, he has taken the liberty of undertaking to communicate <PageNumber num={302} /> to the Convention, and to the world what were my opinions thirty years ago, and what they are now.</p>
                <p className="p-in-sp">I beg leave now to correct the misrepresentation of the gentleman—not intentional misrepresentations, I am sure—but, nevertheless a misrepresentation of my opinion.</p>
                <p className="p-in-sp">The gentleman sets out by saying that he expected I would be in my seat, &amp;c.</p>
                <p className="p-in-sp">
                  I am a little surprised that the gentleman did not know that I was serving on the Committee on Federal Relations, under the order of this Convention, and that it was my duty to be there. I believe that on the particular day on which he delivered his speech, I was but a short time on that committee, because the state of my health precluded me from being there. I was then surprised to
                  find that gentleman stating he expected to see me in my seat, when it was my duty to be on that committee.
                </p>
                <p className="p-in-sp">
                  The gentleman has undertaken to tell what was my opinion in regard to the subject of slavery thirty years ago, and what my opinions are now. I cannot undertake to recollect distinctly what were precisely my views thirty years ago upon that subject; but I will say that the gentleman had no right to assume that my opinions had undergone a change as he has done. I do not hold myself
                  responsible to this Convention or any body else for my opinions upon this or any other subject. Some of my friends, however, have expressed a wish to know my opinions on the subject of slavery, and I shall give them.
                </p>
                <p className="p-in-sp">
                  First. I do not regard slavery as an evil at all, so far as the slaves themselves are concerned. I have been satisfied upon reflection that a greater blessing was never conferred, by a kind Providence, upon any portion of the African slaves, than that establishing the institution of slavery as it exists in Virginia. I am convinced that there is no laboring class on the face of the
                  earth who enjoy more comfort, who are better fed and better clothed, than the slaves in the State of Virginia. And I think that their condition is preferable to that of the slaves of the free States, for slavery exists everywhere on the face of the earth, in some form or other. I say that those whose action is subject to the unrestricted control of others, whether as personal
                  property or in the light of absolute submission to those who hold the means of controlling their action, occupy alike in effect the position of slaves. And, sir, there are at this hour, more slaves in England and France, where they raise such a cry about slavery, than there are in the whole United States put together.
                </p>
                <p className="p-in-sp">
                  As to the white population of Virginia, I believe, that though some <PageNumber num={303} /> evils result from the existence of the institution of slavery, they are fully counterbalanced by benefits resulting from it. If the preservation of a Republican form of government is desirable, and I am old fashioned enough to think so, it is highly beneficial
                  to the white population of the State. My observation of the tendency of things in the free States has convinced me that they have very nearly approached that point, at which a Republican form of Government can no longer be maintained. The idea formerly prevailed extensively that the increase of population and wealth, was the only criterion by which the prosperity of a country could
                  be judged. That idea, I am satisfied, is entirely erroneous. I think that the population of a State as sparsely settled as Virginia, may enjoy as high a degree of happiness, as a more densely populated country.
                </p>
                <p className="p-in-sp">Now, sir, these are the views which I entertain upon that subject.</p>
                <p className="p-in-sp">
                  In another part of the gentleman's speech, he says that the gentleman from Rockbridge, that is myself, would not answer distinctly the question, where are we to go? He says that I refused to answer that question distinctly. I deny that I made any such refusal here or elsewhere. I have distinctly announced here and elsewhere that I did not intend to go anywhere ; that I meant to stand
                  here in Virginia and to maintain her rights. I said I did not mean to be forced to go to the South by gentlemen whose interests may induce them to go there.
                </p>
                <p className="p-in-sp">I see that one of the outside marshals, in this disunion conclave, has been pleased to allude to my connection with what is called the Ruffner pamphlet. I will say a few words on that subject.</p>
                <p className="p-in-sp">
                  There has been a great misconception on that matter. A few gentlemen, of whom I was one, called upon Dr. Ruffner to publish an address which he delivered in a debating society in the town of Lexington. There is a misconception as to the facts connected with that address. Dr. Ruffner suggested the idea of educating slaves and preparing them for emancipation, in Western Virginia, in
                  view of an ultimate division of the State. That was not my idea nor the idea of any gentleman who signed the invitation. My idea was to require that the owners should make sale of the slaves then in existence, and in case they refused to do so, that they should be seized by the sheriff and sold, and the money paid over to the owner.
                </p>
                <p className="p-in-sp">
                  It has been also stated that it was our purpose to divide the State of Virginia. There was such a purpose at that time. We know that other States have been divided. Immediately after the adoption of the Federal Constitution, the State of Vermont had been formed out of the <PageNumber num={304} /> territory claimed by Massachusetts; Maine had been formed
                  out of the territory of New Hampshire; Tennessee had been formed out of the territory of North Carolina; Alabama and Mississippi out of Georgia territory. Virginia ceded to the General Government a large extent of territory in the North-West, and the States of Ohio and Kentucky were formed out of that territory by the consent of all the States. We had an idea that a division would be
                  best, under the circumstances, in Virginia. We demanded an equal representation, and it had been denied to us. We were told in the Convention of 1829-'30, that they could not consent to give us the basis of representation that we desired, and to which we were entitled. They said they would prefer to divide the State rather than do so. Then, sir, we desired that this separation should
                  take place only, however, with the consent of all concerned.
                </p>
                <p className="p-in-sp">
                  One word as to the cause which led us to take the action we did. We all know that a proposition to construct a macadamized road from Buffalo, N. Y., to the city of New Orleans, through our country, had been rejected by a majority of one-fourth, through the instrumentality of gentlemen on this side of the mountains. When Baltimore asked the privilege to extend her road up the Valley
                  and down the Kanawha, it was denied her, upon the express ground that we would have the means of getting to market afforded us by the State of Virginia.
                </p>
                <p className="p-in-sp">
                  When the canal reached the town of Lynchburg, it was proposed to stop it there, and this we conceived an act of gross injustice and bad faith; and we urged its extension towards our region of country. In addition to that, we still insisted upon the right of fair representation, which we understood to be the purpose of the Eastern portion of the State to deny to us. I say now, without
                  a moment's hesitation, that if that injustice had been persisted in to this time, I would go at this hour for dividing the State. I would see it rent into fragments not larger than the smallest of the thousand islands on the St. Lawrence, before I would submit to have the State placed in a position of inequality in the Union, or the people of the State in a position of inequality in
                  the State.
                </p>
                <p className="p-in-sp">I have no desire now, Mr. President, to have this Commonwealth divided. I say to gentlemen, that we will not consent to divide it—we mean to hold it together, and not sell it to any party here or elsewhere.</p>
                <p className="p-in-sp">
                  I am sorry, sir, to see allusions made in this body to the positions of members here coming from certain portions of the State. It is intimated that they lack fidelity to her interests. I hope it is not so. If ever there was a time when Virginia required the united aid of her <PageNumber num={305} /> people, it is now. I should regret to see any
                  division among the people of Virginia, or any want of fidelity to her interests. I believe, when the occasion arises, that there will be found thousands of brave and true men in every section of the State, ready to defend her rights and her interests. Virginia has many noble sons, and I need not tell gentlemen here that she has also as many beautiful daughters as are to be found on
                  the face of the earth as you, gentlemen, may see, by casting your eyes around the Hall.
                 [Laughter.] <span className="persName">Mr. DORMAN</span> offered the following resolutions :</p>
                <p>Resolved, That the Federal Union was made by the people of the several States, and can rightfully be dissolved by the power alone that made it.</p>
                <p>Resolved, That Virginia should use her best efforts to procure a direct vote of the people of all the States, including those which have seceded from the Union upon the alternative proposition of Disunion or Adjustment on the basis of the Peace Conference proposition, and that she will thereafter co-operate with such States as concur with her in the result of their votes.</p>
                <p>Referred to the Committee on Federal Relations.</p>
                <p><span className="persName">Mr. HUGHES</span>, of Randolph and Tucker, offered the following resolution, which was adopted :</p>
                <p>Resolved, That a committee of three be appointed to confer with the editors of the "Richmond Enquirer," to ascertain whether the proceedings of this Convention could be published upon a separate sheet without any other matter.</p>
                <div className="speaker" id="sp335"><span className="persName">Mr. BURLEY</span>—</div>
                <p className="p-in-sp">I hold in my hand certain resolutions adopted by citizens of Marshall county, which I beg leave to offer. I ask that they be referred to the Committee on Federal Relations :</p>
                <div className="floatingText">
                  <div className="floatingText_body">
                    <p>FEBRUARY 4th, 1861</p>
                    <p>We, the undersigned, citizens of Marshall county, and State of Virginia, Parson's precinct, District No. 4—</p>
                    <p>
                      1. Resolved, That we are unalterably attached to the Constitution and union of these States and shall cling to it under all circumstances as the last hope of our country, and that the preservation of the Constitution and the execution of the laws according to the letter and spirit thereof, and the compact of the States is our only security for life, liberty and the pursuit of
                      happiness.
                    </p>
                    <p>2. Resolved, That all bills of rights made under and according to the Constitution shall remain inviolate.</p>
                    <p>3. Resolved, That our delegate be requested to lay the above resolutions <PageNumber num={306} /> before the Convention that is to meet at the city of Richmond on the 13th day of the present month.</p>
                    <p>4. Resolved, That the foregoing resolutions be read by the Clerk and referred to the Committee on Federal Relations.</p>
                  </div>
                </div>
                <p>The foregoing resolutions are signed by Hiram Hall and twenty-nine other citizens.</p>
                <p>The resolutions were referred to the Committee on Federal Relations.</p>
                <p><span className="persName">The PRESIDENT</span> stated the unfinished business to be the resolutions of the gentleman from Rockbridge [Mr. MOORE] .</p>
                <div className="speaker" id="sp336"><span className="persName">Mr. BAYLOR</span>, of Augusta, being entitled to the floor, he resumed his remarks as follows :</div>
                <p className="p-in-sp">Mr. President : Yesterday, before the adjournment, 1 was about to reply to what I had heard so frequently out of this hall and also in it, to wit : That the Commonwealth of Virginia ought to secede from the Union forthwith, or before the 4th of March. I desire now, sir, to say a few more words upon that question.</p>
                <p className="p-in-sp">
                  I have never been able to see any good reason why she should secede at all, much less why she should secede before the 4th of March. I know, Mr. President, it has been said that it won't do to give Mr. Lincoln the of the government at the first, because he would at once make war upon the seceded States, and attempt to coerce them, to force them back into the Union. And, Mr.
                  President, in the first place, I d6n't believe any such thing. I have no idea that the President elect will attempt any such thing. But if he had such an object in view, I ask you, sir, and I ask the gentlemen who have such fears, would the fact of the State of Virginia seceding before the 4th of March, prevent him from being inaugurated, or making such a war upon the seceded States?
                  You may take the old Commonwealth of Virginia out of the Union if you please, but that will not prevent Mr. Lincoln's inauguration as President of the United States.
                </p>
                <p className="p-in-sp">If I thought, Mr. President, that he had any such intention, why sir, that might afford one of the very best reasons why I would be unwilling to go out, because then he would have no excuse to go after me.</p>
                <p className="p-in-sp">
                  But, sir, what has occurred within the last two or three months? What has occurred, I ask you, sir, since the election of Abraham Lincoln to the Presidency of the United States? You know, sir, and I know—or at least I know if you don't— ( I don't know how it was in your county) but I know how it was in my county, and everywhere else that I was during the Presidential campaign. All
                  parties declared
                </p>
                <p className="p-in-sp">
                  the Breckinridge party, the Bell party, and the Douglas party—all <PageNumber num={307} /> declared that the election of Lincoln, in itself, was no cause for the dissolution of the Union. And, sir, if it was not regarded as the cause then, why is it regarded as the cause now? What have the Northern people done since that time against the rights of the
                  South? Sir, if your rights have become more insecure since that election than they were before, how has that fact been brought about? Not by anything that the people of the non-slaveholding States have done, but, I am sorry to say, on account of the acts of the representatives of the cotton States. Why, sir, it was admitted on all hands that, as Congress stood when Mr. Lincoln was
                  elected, he would have had no power; that—if he was the worst man in the world—he could have no power to touch a hair of our head. The power was against him—Congress was against him, the Supreme Court of the United States was against him and, I ask you, sir—placed as he was in that position if he would not have been more an object of pity than of dread?
                </p>
                <p className="p-in-sp">
                  But it is said that we are now in danger; that the Republican party has the ascendancy in Congress. I am sorry to say that that is true. But is that the fault of Virginia? Is Virginia to be blamed for that unhappy condition of affairs? And must she, therefore, go out of the Union? No sir! The fault is not with her. The fault is with the seceded States. When they had the power in
                  their own hands they surrendered it, and placed it in the hands of their enemies ; and now, sir, because they did wrong, they ask us to do wrong also.
                </p>
                <p className="p-in-sp">
                  Mr. President, I can see no reason, as I remarked before, why Virginia should be in a hurry to go out of the Union, if it is a constitutional measure. If it is a constitutional measure for any State to secede at pleasure, let the old Commonwealth delay a little at any rate, and when she is ready to go out, then she will have nothing to do but to just slide out quietly. But I am
                  opposed to her going out of the Union at all, Mr. President. I want Virginia to stand where she has always stood; I want her to stand where she has stood for more than three quarters of a century. I want her to contend for her rights in the Union, and not out of it. Did you ever know, Mr. President, did you ever hear of a man getting his rights by running away from them? I had
                  thought that the best way, when you sought to secure your rights, was to look your adversary in the face and tell him what you wanted to demand your rights and not run away from them.
                </p>
                <p className="p-in-sp">
                  Mr. President, I now want to say a few words on the question of secession. I do not believe, sir, in the doctrine of secession; I do not believe that it is a constitutional measure. I know, sir, that much has been said upon that subject, for and against. I know that the opinions <PageNumber num={308} /> of our great statesmen, who have gone before us,
                  have been quoted upon that subject, for and against; but I never have been able to satisfy myself that the doctrine of secession was a constitutional measure. I looked for it in vain in the Constitution of the United States itself. I think that the framers of that Constitution, if they had intended the doctrine to be there, would have put it in that instrument. I repeat, Mr.
                  President, that I do not believe in the doctrine of secession. Why, sir, it is said that this Government is a Government of consent ; that the States gave their consent to the federal compact, and that, therefore, they have a right to withdraw at their own will and pleasure. I think not, sir, I think the reason is the other way. I think if they had been forced into the federal
                  compact they might have some reason or some excuse for secession. They then might say : "We were forced into a compact to which we never consented, and therefore we ought to have the right to go out of it." But that a compact has been deliberately and understandingly entered into, is no ground that the aggrieved party should have a right to secede from it at their own will and
                  pleasure.
                </p>
                <p className="p-in-sp">
                  I have said, Mr. President, that I did not believe in the right of secession. But whilst I make that assertion, I also say that I am opposed to coercion on the part of the federal government with the view of bringing the seceded States back into the Union. I say, Mr. President, that I am opposed to it upon principle and upon policy. I am opposed to it, first because I can find no
                  authority in the Constitution of the United States, delegating that power to the federal government; and second, because, if the federal government had the power, it would be wrong to use it. This Government can never be kept together by force. Suppose, sir, it had the power. Suppose that South Carolina alone was out and the balance of the States had the power to coerce her back.
                  What good would it do? You may go to war with her, if you please; you may continue that war one, five, or ten years if you choose ; until both parties become tired—and what then, sir? You would not make her perform her duty as a member of the Federal Government. You could not make her send her Representatives to the Congress of the United States. Therefore I am opposed to coercion.
                </p>
                <p className="p-in-sp">
                  Then, sir, there arises the question whether there is not an inconsistency in that view of the case. I think not. I will say, Mr. President, that secession is nothing less than revolution. And when it arrives at revolution, the contending parties may go to war, or they can settle their difficulties without war—in a peaceful manner. I, sir, am in favor of settling them all without
                  war. That is the best way to do it; and while there is peace it is the best time to do it. Suppose the opposing <PageNumber num={309} /> parties went into a war with each other; after a strife of years would they be in any better condition to settle their difficulties at the end of the war, than they were when they begun? I think, Mr. President, they
                  would not be in a better condition, but would be in a far worse condition. At last they would be compelled to settle their difficulties by treaty or compromise. And after the war would be over, after having spent millions of treasure and lost the lives of hundreds of thousands of our best citizens, they would be in no better condition than they were before. Therefore, I am opposed to
                  war; I want the parties to settle all their difficulties without going to war at all.
                </p>
                <p className="p-in-sp">
                  Mr. President : I know very well that I and those who occupy the position that I do, have been called submissionists. Now, I have to say upon that point, that the very instinct of my nature revolts against submission to any thing that I know to be wrong. I say, that while I, and the people I represent, are opposed to war; yet, Mr. President, if to rally around the stars and stripes
                  of your country; if to contend for the honor of that flag for which Washington fought, and for which many of our soldiers gave up their lives in the revolutionary war, and again in the war of 1812—if that constitutes a Submissionist, I am one.
                </p>
                <p className="p-in-sp">
                  Yes, Mr. President, submission is resorted to because we don't want to fight, it is said. We don't want to fight, I admit, sir, unless it is necessary. We don't want to go to war for the fun of the thing. We don't want to fight for military distinction and military honors. God forbid that I, or any of the people I represent on this floor, should ever want to stain their hands in the
                  blood of a brother for military glory. But, sir, if ever the time comes—and I trust in God it never may come—but if the time ever comes, when the honor and the dignity and the interest of the Commonwealth of Virginia require it, I tell you, sir, that I and the people I represent here, will want to be counted in the ring. Yes, sir, we will want to be there. It is not the men, Mr.
                  President, who make the most noise about fighting, that are the first to go into it.
                </p>
                <p className="p-in-sp">
                  My position is simply this : I am in favor of exhausting all fair and honorable means first. Those are the sentiments of the people I represent here; that we ought first to use every possible means to settle all our difficulties; that we ought to resort to Constitutional measures, and not to inaugurate a revolution. I don't think we have resorted to all Constitutional measures yet. I
                  believe that all our difficulties can be settled. And why do I believe it? Why, sir, as I said yesterday, gentlemen are in the habit of denouncing the whole North, when they speak of the aggression of the Northern States. They have forgotten that in the last Presidential election—I speak from memory, for I have not <PageNumber num={310} /> the
                  statistics before me—but I believe there were not less than fifteen hundred thousand freemen in the non-slaveholding States who cast their votes against Abraham Lincoln. Fifteen hundred thousand! More than all the votes cast in all the Southern States put together. And yet, sir, those Northern men are all denounced broadcast, without any distinction. The very men who have always
                  stood up for the rights of the South, they are to go overboard with the rest, and no exception to be made. I say, sir, that is all wrong. I am convinced that all our difficulties will be settled if we can only get the questions in a proper shape, and get them before the people ; because there are, no doubt, hundreds of thousands who voted for Mr. Lincoln, whose views have become so
                  much modified since the last Presidential election, that if they had an opportunity to give another expression of their sentiments tomorrow, they would certainly go against him.
                </p>
                <p className="p-in-sp">
                  Mr. President, I may be wrong in the views which I entertain. I am liable to error, I know, like other men. But it may be thought by some, sir, that my sentiments—my conservative sentiments—are entirely too strong. Well, sir, if that be so, I cannot help it. When I look back to the early history of my country—and I must be permitted to say here that the first public meeting that I
                  ever attended was when I was a boy at school<span className="note" id="Note39"><span className="noteLabel">2</span>Mr. Baylor corrected this statement in the Proceedings of March 4, page 374.</span>—when I read the history of the country, and when I recall the toils and hardships that our Revolutionary ancestors had to encounter in order that they might work out for us liberty and peace;
                  when I reflect upon the men who took part in that memorable contest—that it was a Washington, a Morgan, a Williams, a Wayne and hosts of other great men who fought for the liberty of America—I say, sir, that if my sentiments are conservative, I cannot help it. My attachments to the Union have ever been strong. And when I come down to the period of the war of 1812, why, sir, I can say
                  that this day there are mouldering in the dust the bodies of some of my own blood relations, whose bones are now deposited beneath the surface of the soil in Louisiana, who fought under the command of the great and noble General Jackson, the hero of New Orleans, in order to perpetuate that glorious Union for which Washington fought in the days of the Revolution. So I say, Mr.
                  President, that the instincts of my nature are such that I cannot relieve myself of the attachments which, in the days of my youth, I formed for this Union.
                </p>
                <p className="p-in-sp">
                  We may talk, sir, about a Southern Confederacy or half a dozen Southern Confederacies; but I tell you that you will never have a better Government than the one which we have had—and which we yet have, I trust. I believe, sir, that the Constitution of the United States and the <PageNumber num={311} /> Government of the United States are the best
                  Constitution and Government which ever God permitted a free people on this earth to enjoy. I do not believe that any Confederacy that can be built up anywhere will ever approach to the Government of the United States, and you may build up as many as you please.
                </p>
                <p className="p-in-sp">
                  Mr. President : I have heard a great deal said about re-construction. I have heard it said repeatedly that the question is not now, "how can this Union be preserved?" because, they say, it is already dissolved ; but the question is, "how shall it be re-constructed?" And it is contended that Virginia must go out of the Union in order that she may help to re-construct it? It is
                  contended, sir, that Virginia must go out—and, I suppose, turn round and come back the other end foremost! Sir, I want Virginia to stand, as I said before, just where she has always stood. I want her to exert such an influence as she has exerted in the past. I want her to act as a mediator in the present strife ; and if she deserves to have any influence that middle ground is the
                  place for her to occupy in order that she may secure that influence. I am opposed to her going out of the Union; first, because she may never return, and next, because there is no necessity for her to go out.
                </p>
                <p className="p-in-sp">
                  Mr. President, it has been admitted, I believe, everywhere—except, perhaps, in some of the States that have already seceded—that it would be well to lay down a proposition as a basis of the settlement of our difficulties. At least, that has been the cry in my section of the country. But then, sir, the question arises, what is the best mode to be pursued? And that is the rock upon
                  which many have split. As to the time, I, for one, think that after we have laid down a proposition as a basis of settlement, we should then give the Northern people an opportunity —a fair, reasonable opportunity—to consider it, and to give them time to say whether they will accept our proposition or not. I ask you, Mr. President, why and wherefore make a proposition to any man, or
                  any set of men, to settle a difficulty, if, before you can get a reply, you place yourselves in such a position that you cannot acknowledge their acquiescence, if they should conclude to accept it?
                </p>
                <p className="p-in-sp">Mr. President : I do not say that secession is a remedy for any of the grievances against which we have complained. Why, it was admitted the other day by the honorable gentleman, the Commissioner from Georgia [Mr. Benning] 
                  , that if the State of Virginia should join the Southern Confederacy, it would be necessary to have a standing army along her border—a line of two thousand miles in extent, I believe. Now, sir, I have not the statistics with me—I have not had the time nor the inclination to procure them—but I will venture to say that a <PageNumber num={312} /> standing
                  army would cost more in five years than all the fugitives that have escaped from all the slave States. That gentleman admitted that it could hardly be expected that Virginia would go with the cotton States unless it could be made to appear that it was her interest to do so. Well, sir, I don't know how it is with other gentlemen who are members of this Convention, but I can speak for
                  myself, and I can say that I have listened in vain for reasons. I have never yet heard advanced a reason to satisfy me that it was the interest of Virginia to go out of the Union.
                </p>
                <p className="p-in-sp">
                  Mr. President : It is the custom of public speakers, everywhere in this land, whenever they can appropriate anything that has ever fallen from the lips of that great man, the Father of his country, to grasp it with eagerness, and present it in support of the views which they themselves entertained. I have heard his sentiments referred to in this hall, but I cannot see how gentlemen
                  can, with consistency, quote them, unless they intend to act according to the advice which he gave. I was pleased to hear my friend from Bedford
                 [<span className="persName">Mr. GOGGIN</span>] 
                  point out, in the Farewell Address of Washington, the mode laid down in the Constitution of the United States, by which grievances ought to be removed and alterations to the Constitution made. And now, sir, I beg leave to read another short extract from that memorable Farewell Address of Washington, and I want to see how it accords with the views of those who advocate secession. I am
                  willing to take that great man for my guide.
                </p>
                <p className="p-in-sp">In speaking of the Union, Washington uses the following language :</p>
                <p className="p-in-sp">
                  "The unity of government, which constitutes you one people, is also now dear to you. It is justly so; for it is the main pillar in the edifice of your real independence; the support of your tranquility at home, your peace abroad; of your safety ; of your prosperity; of that very liberty which you so highly prize. But as it is easy to foresee, that from different causes, and from
                  different quarters, much pains will be taken, many artifices employed, to weaken in your minds the conviction of this truth; as this is the point in your political fortress, against which the batteries of internal and external enemies will be most constantly and actively (though often covertly and insidiously) directed; it is of infinite moment, that you should properly estimate the
                  immense value of your national union, to your collective and individual happiness ; and that you should cherish a cordial, habitual, and immovable attachment to it; accustoming yourselves to think and speak of it as of the palladium of your political safety and prosperity; watching for its preservation with jealous anxiety discountenancing whatever may suggest even
                  <PageNumber num={313} /> a suspicion that it can in any event be abandoned; and indignantly frowning upon the first dawning of every attempt to alienate any portion of our country from the rest, or to enfeeble the sacred ties which now link together the various parts."
                </p>
                <p className="p-in-sp">That is the advice of that great man, and by that I am willing to stand.</p>
                <p className="p-in-sp">Mr. President, it is said in the Cotton States, and elsewhere, that Cotton is King. It may be there, sir; but we have more than one King in our own State. We have got at least half a dozen Kings here. We have got King Wheat, King Corn, King Potatoes [laughter] 
                  , King Tobacco, King Flax and King Hemp. King Oysters I know very little about. King Tobacco might be dispensed with, and so might King Cotton ; and in its place you might substitute flax or hemp. But when you sit down to your meals, you cannot substitute anything for King Wheat. We have more than one King, I say, then, in Virginia, and when you put all these Kings together, they far
                  over-ride King Cotton, with all the power that he may possess.
                </p>
                <p className="p-in-sp">
                  I do not intend, Mr. President, to take up your time, and the time of the Convention, much longer. I had no intention of making an elaborate argument. My only desire was to present some of my views upon the great question now before us, and I was exceedingly anxious to let this Convention know the views of those I represent in this humble body. By way of inflaming the passions of the
                  people of Virginia, you have been cited to the John Brown raid, and you hold the Black Republican party responsible for that. Now I think the best plan is to do justice all round to every body. Every man here, of course, and elsewhere, condemned that whole proceeding; and I say it is unjust to charge the Black Republican party as a party with countenancing the John Brown raid. If
                  gentlemen will read the Chicago platform, you will find in it that they distinctly denounced it. I want you to understand distinctly, that I do not sympathize with the Black Republican party; but I say justice ought to be done to all. When we meet in a body like this, for the purpose of discussing questions, and arriving at the truth, we ought to tell the whole truth, and nothing
                  else but the truth, if we know it. It is unjust, then I say, to condemn the Black Republican party, and hold them responsible for the lawless acts of John Brown and his followers.
                </p>
                <p className="p-in-sp">But, Mr. President, we have been asked over and over again, where will Virginia go? Will she go to the North or to the South? That question was answered this morning by my friend from Rockbridge [Mr. MOORE] , and it is hardly necessary that I should say more about it <PageNumber num={314} /> than to remark, that I heartily concur in his views. I say, she is to go nowhere.</p>
                <p className="p-in-sp">
                  Some members of this body have been appealing to others to know what should be done in case coercive measures were adopted by the General Government. I say that this Convention, if it is desired to bring about an amicable adjustment Of all our difficulties, ought to act wisely, deliberately, and judiciously. Set out your grievances and demand your rights. But if the object is to
                  frighten us, you will never accomplish any thing. I have no more idea that the people of the South can intimidate the people of the North, than that the people of the North can intimidate the people of the South. If I asked anything at your hands, Mr. President, I would have to come up respectfully and make my demand in a proper way. If I undertook to threaten you, I know that I
                  would never get what I wanted. States as well as individuals will not submit to threats. You cannot settle difficulties in that way.
                </p>
                <p className="p-in-sp">Here is another question upon which I desire to submit a few remarks. I mean the proposition presented by the Peace Conference at the city of Washington. Whatever may be the opinion of others, whatever may be the opinion of the gentleman who last spoke the gentleman from Orange [<span className="persName">Mr. MORTON</span>] 
                  I, for one, accept the proposition agreed upon by the Peace Conference ; and in this view, I desire to say to the Convention, my colleagues agree with me. I think that Virginia ought to accept it, and I think she will. If this matter were properly brought before the people of the State, and it were discussed from its centre to its circumference, I have no hesitation in saying, that
                  the people would rise in the majesty of their might and settle this question at once. They would be willing to accept the proposition presented by the Peace Conference at Washington.
                </p>
                <p className="p-in-sp">
                  I do not want, Mr. President, as I said yesterday, that there should be any doubt as to the position I occupy upon the subject now under discussion. If I am wrong, I am responsible to the people of my county, and to them alone, who knew my views before I came here. And right at this point, I desire to read a short extract from the 5th Article of the Constitution of the United States.
                  I know that the members of this House must be familiar with it, but I will read it for my own satisfaction, so that I may define my position more correctly, and so that gentlemen may know exactly the position I take :
                </p>
                <p className="p-in-sp">"The Congress, whenever two-thirds of both Houses shall deem it necessary, shall propose amendments to this Constitution."</p>
                <p className="p-in-sp">
                  It is expected that the plan now submitted by the Peace Congress <PageNumber num={315} /> will be adopted by Congress as an amendment to the Constitution. I do not know whether they will or not. The present Congress expires in a day or two; I do not know whether they will have the time to do so or not; but I must be permitted to say, if they do not
                  submit it in the way I have suggested, I shall not be very much surprised. I never had much confidence in the Black Republican members of Congress agreeing to any fair proposition.
                </p>
                <p className="p-in-sp">
                  But if they do not agree to this there is anothr remedy—and I go for that last resort. I am not willing that this Government of ours shall be broken up and cast to the four winds of Heaven, because some of the Black Republicans, who were elected years ago to office, are not willing to save it. If Congress fail to agree to the proposition now submitted by the Peace Conference, there
                  is another remedy, and it is this : "On the application of the Legislatures of two-thirds of the several States, Congress shall call a Convention for proposing amendments, which, in either case, shall be valid to all intents and purposes, as part of this Constitution, when ratified by the Legislatures of three-fourths of the several States, or by Conventions in three-fourths thereof,
                  as the one or the other mode of ratification may be proposed by the Congress."
                </p>
                <p className="p-in-sp">
                  Though Congress fail to recommend amendments to the Constitution according to the propositions laid down by the Peace Congress, I want this Convention to lay down the basis for a settlement of our difficulties in the way here suggested. I desire to have this question to go to the people of all the States, and I want them to elect members to their Legislatures, who will ask Congress
                  to call a constitutional Convention, and then, in that event, Congress would feel itself bound to call such Convention. I will not be satisfied about that.<span className="note" id="Note40"><span className="noteLabel">3</span>See note 2, above.</span> If the politicians fail to settle this matter, I want the bone and sinew of the country and the voters of the country to pass upon it. When
                  all is done that a just people can do, if the proposition I have indicated is fairly brought before the people of the non-slaveholding States as well as of the slaveholding States, and it be rejected—then I would say to them : "We cannot agree; let us part in peace." If they refuse to let us part in peace, then I would say, if you attempt to make war upon our rights or upon our
                  institutions, it is at your peril.
                </p>
                <p className="p-in-sp">
                  I want to try all these constitutional measures. I have faith that the present difficulties can be settled. It is necessary, Mr. President, to have faith. If we could believe that this Union might be saved and shape our conduct accordingly; if we do all in our power to avert the dangers that now threaten us, the happiest results would attend our efforts and peace and harmony would
                  once more be restored to a distracted land. <PageNumber num={316} /> I have said all I intended to say, and I thank the Convention for the courtesy and attention they have given me.
                </p>
                <div className="speaker" id="sp337"><span className="persName">Mr. MONTAGUE</span>—</div>
                <p className="p-in-sp">I did not desire, Mr. President, to participate in this very interesting discussion, but I merely rose for the purpose of putting a fact before the country, which it seems the gentlemen who have preceded me—my friend from Bedford [Mr. Goggin] and the gentleman from Augusta [Mr. Baylor] have studiously avoided presenting. I will not attempt any reply to the gentleman from Augusta [Mr. Baylor] . His argument against secession is original and unanswerable. He says that Virginia must not secede because if she were to go out, we could not get her back without bringing her in the other end foremost. [Laughter.] I do not see any way to answer this argument.</p>
                <p className="p-in-sp">I do not mean here, as one of the representatives of Virginia, humbly to apologise to the Black Republicans of the North, by assuring them here in my official capacity, that I do not believe they are responsible for the John Brown raid. If I remember aright, the great political leader of the gentleman from Augusta [<span className="persName">Mr. BAYLOR</span>] who has just taken his seat, declared in the Senate of the United States that Hale, Wilson, Sumner and others of that stamp, with their teachings were in his opinion the immediate cause of the John Brown raid.</p>
                <p className="p-in-sp">
                  But I did not rise to make a speech, but merely to call the attention of the Convention and the people of the country to the opinion of General Washington in his Farewell Address. His devotion to the Union and the sound advice he gave to the people of the country, when he advised them to adhere to the Union, are quoted for the purpose of teaching our people throughout the land a
                  servile submission to wrong. In the same address he declares that your attempt at self-government is an experiment, and, until experience shall have demonstrated its impracticability, he warns his countrymen against endeavoring to weaken its bands. But here is the language which the gentleman from Augusta failed to quote. Justice to the people, justice to his great reputation,
                  justice to the truth of history, require that I should now read that part of the Farewell Address to which neither the gentleman from Augusta
                 [<span className="persName">Mr. BAYLOR</span>] or the gentleman from Bedford [<span className="persName">Mr. GOGGIN</span>] alluded :</p>
                <p className="p-in-sp">
                  "These considerations speak a persuasive language to every reflecting and virtuous mind, and exhibit the continuance of the Union as a primary object of patriotic desire. Is there a doubt, whether a common Government can embrace so large a sphere? Let experience solve it. To listen to mere speculation in such a case were criminal. We are authorized to hope that a proper organization
                  of the whole, with the <PageNumber num={317} /> auxiliary agency of governments for the respective sub-divisions, will afford a happy issue to the experiment. It is well worth a fair and full experiment. With such powerful and obvious motives to Union, affecting all parts of our country, while experience shall not have demonstrated its impracticability,
                  there will always be reason to distrust the patriotism of those who in any quarter may endeavor to weaken its bands."
                </p>
                <p className="p-in-sp">
                  When the gentleman read extracts from this Address, for the purpose of controlling the public mind of Virginia, and for the purpose of controlling that mind by the great influence which the name of Washington will exert upon it, they should tell us, at the same time, that he declares that government was an experiment, and that you should not attempt to break it until experience
                  should have demonstrated its impracticability.
                </p>
                <div className="speaker" id="sp338"><span className="persName">Mr. TURNER</span>, of Jackson—</div>
                <p className="p-in-sp">
                  If I deviate now from the intention which has controlled me during the time I have been a member of this body, of keeping my seat, and patiently listening to any remarks which gentlemen might desire to make, and reserving to myself such as I intended to make until the report of the committee on Federal Relations had been submitted to this body, I do it because I desire to take this
                  occasion to present, very briefly, the position that I have hitherto occupied, and the motives that will influence and actuate my action in the course I shall pursue, during my connection with, and representation of the people that I have the honor to represent upon this floor. In order that I may be properly understood, and that the position I occupy may be properly understood, I
                  will first, with the permission of the Convention, call their attention for a moment, to the position which occupied before my constituents. And I trust, in the course I shall pursue here, that I shall be consistent to the declarations I made to my people, and consistent to the resolutions which I had the honor of submitting to this body. In my letter accepting the position of a
                  candidate for a seat in this body, I make use of the following language :
                </p>
                <p className="p-in-sp">
                  "In accepting the position you tender me, that of a candidate for the Convention, permit me to say, that, identified as I am, by position, by inclination and by interest with the citizens of this Legislative District, and of Western Virginia, I shall, if elected to that position, represent to the best of my ability the interest of my immediate constituency, so as best to conduce to
                  the general welfare of the entire State, and in all things that I may do as their representative, shall be governed by a firm determination to protect the honor, the integrity and the independence of our beloved Commonwealth, and in so doing, shall believe it to be <PageNumber num={318} /> my duty, as it will be my pleasure, to advocate the exhaustion
                  of all honorable means to preserve the Union of these States as heretofore, by such constitutional guarantees as will secure the rights of the citizens of the several States, and settle finally and forever the questions which are now agitating, distracting and dividing the country, demanding for the purposes of such an adjustment, only such things as of right belong to us, and
                  accepting nothing less. But if in the course of events it shall become necessary, to take action independent of the present Union, I shall advocate the Union of the Southern States, in order that we may preserve to ourselves and our posterity the rights guaranteed to us in the Constitution handed down to us by our fathers, and to prepare the way for the formation of another
                  confederacy, around which may rally every people desirous of the preservation and extension of civil liberty."
                </p>
                <p className="p-in-sp">I deem it unnecessary, even if my professional engagements did not interfere to prevent me, to repeat here the views to which I have given publicity elsewhere.</p>
                <p className="p-in-sp">I arose as much for the purpose of tendering to the gentleman from the Southwest—from the county of Smyth [<span className="persName">Mr. SHEFFEY</span>] 
                  , who addressed this Convention the day before yesterday—the cordial cooperation of a representative from the Northwest upon the Ohio border of this State, as for any other, and to say that I stand in this Convention as I stood before my people ; that I stand hand in hand, shoulder to shoulder with him. I believe, standing as we do, that we are as good Union men as any who have the
                  honor of a seat upon this floor. It is unnecessary for me to say that I am devoted to the Union of the States. Every pulse of my heart keeps time to the music of the Union. Every thought I entertain originates from an education which taught me to love and venerate it. Sir, I love the Union of these States, as it has heretofore existed. Of all things, I desire that our sister States
                  of the South should be restored to their former relations. I deprecate the necessity which may compel one single State to leave the bright galaxy of this constellation, but if that necessity shall and must arise, let us meet it like men—meet it boldly and squarely, and take that position which honor and interest, in my opinion, demand. I frankly confess that, for the Union of the
                  States as they now exist, I have little affection. I speak of those that remain in this Union, for we cannot avoid seeing the fact that the Union is now dissolved. That fact meets us as we step beyond our thresholds, stares us in the face as we sit down at our hearthstone; it accompanies us in our daily avocations.
                </p>
                <p className="p-in-sp">
                  Sir, the Union no longer exists, and the question for us to determine <PageNumber num={319} /> is what shah Virginia do in this hour of her need and peril? I do not now intend to discuss the right of secession. I do not deem it the appropriate occasion, and I shall content myself merely with saying that I beg to differ from the views expressed upon that
                  subject by the gentleman from Augusta
                 [<span className="persName">Mr. BAYLOR</span>] , who has just taken his seat. I believe in the right of a State to secede. But, as I said, I shall not undertake to discuss that question.</p>
                <p className="p-in-sp">
                  What shall Virginia do in the present crisis? I am not in favor of precipitate action. I do not think it is necessary for Virginia to jump into any collision before maturely considering her position. I am not one of those who would be deterred from pursuing, after the fourth of March, any course I intended to pursue before the 4th of March. I do not think it is necessary for our
                  safety or honor that Virginia shall jump out of the Union whatever is left of it—on any particular day.
                </p>
                <p className="p-in-sp">
                  I desire that we shall calmly, as men, engage in an important investigation, discuss all the questions which connect themselves with the subject before us. I desire, after that mature deliberation has been had, that the step which Virginia takes shall be a step which our whole people will approve, and a step which will carry with it the moral weight that any action she shall take
                  ought to carry. I think, in order to exert that influence, calmness and deliberation are necessary ; but if the alternative is alone presented with which Confederacy she shall connect herself, I, for one, discard, now and forever, any idea of a central Confederacy. I say, if the time should arrive and the question come up, as to which Confederacy she shall belong to, all my feelings,
                  all my interest, as well as the honor of my State, demand that my-preference should be for the Southern Confederacy. I believe that we ought to connect ourselves with the Southern States, but the reasons for this choice I am not prepared now to discuss in detail.
                </p>
                <p className="p-in-sp">
                  I will briefly say, in passing, that the proposition submitted by the Peace Conference, while it may meet the views of other gentlemen upon this floor, from the slight investigation I have given it, does not meet my approval. I prefer the Constitution of the United States now, to any such proposition as that submitted by the Peace Conference. I believe that the amendments in that
                  proposition, whilst I may be induced, by a more thorough investigation, to change my views, are merely designed for the prolongation of the time at which disruption might come, and that, in a more dangerous form than it would now come.
                </p>
                <p className="p-in-sp">
                  These are briefly my views, and I believe I am representing the views of the people who sent me here. I know that in the canvass I made before them I laid down my views flatly, so that there could be <PageNumber num={320} /> no misapprehension as to what they were. No man could have doubted what my views were. They are what I announced them to be, both
                  here and on the stump in my own county, during the canvass. I may be in a minority upon this floor and among Western members, but that has no influence upon me in the course I shall pursue. I shall do whatever I shall believe to be right, regardless of consequences, for I am merely an amateur politician, always having been in the ranks. I most cordially endorse the views of the
                  gentleman from Bedford so far as he desires to discard all party feelings and party prejudices in the discussion of the important questions which may arise here. I have no doubt that this is the feeling of every gentleman in this Convention. I have no doubt that the determination on the part of all is, for this short period in the history of Virginia, to lay upon the altar of our
                  State all feelings but one, and one alone—a firm determination to do what is right in maintaining the honor and interest of Virginia as we best understand them.
                </p>
                <p className="p-in-sp">
                  I say that I may be in a minority upon this floor; and, sir, the action of this Convention may sweep over me and others who entertain the same views with me; and we may no longer even be amateur politicians. But, sir, I shall continue to do battle in what I believe to be truth and justice and honor, knowing, sir, that the calm, sober, second thought will put all things right and
                  vindicate my course as the one best calculated to promote the interests of those who have sent me here. For, sir, it is said that,
                </p>
                <div className="lg">
                  <div className="l">"Truth crushed to earth will rise again,</div>
                  <div className="l">The eternal years of God are hers,</div>
                  <div className="l">But Error wounded writhes in pain,</div>
                  <div className="l">And dies amid her worshippers."</div>
                </div>
                <p className="p-in-sp">Believing, sir, in the efficacy of truth to vindicate itself and trusting to the future to make my course in this Convention approved and appreciated by the constituency that I have the honor to represent, I shall use my best efforts towards the success of the policy which I have marked out for myself.</p>
                <div className="speaker" id="sp339"><span className="persName">Mr. GOODE</span>, of Mecklenburg—</div>
                <p className="p-in-sp">
                  Mr. President, I have listened with much patience, whilst gentlemen, who take to themselves the parliamentary appellation of conservatives, have poured out the vials of their wrath upon all those who are not prepared to see the proud and noble Commonwealth placed as a mendicant at the footstool of the Black Republican power at the Federal Capitol, and upon all those who are not
                  prepared meekly to bow their heads to receive their rod from the hands of their oppressors. I shall, therefore, sir, offer no apologies for <PageNumber num={321} /> throwing myself upon the indulgence of this body while I reply to them, and I shall endeavor to give utterance to my own sentiments and to those of the people who sent me here. I shall,
                  however, following the precedent which has been set for several days, ask an adjournment of this body until to-morrow, when I expect to resume my remarks.
                </p>
                <div className="speaker" id="sp340"><span className="persName">Mr. WISE</span>—</div>
                <p className="p-in-sp">I hope the gentleman will withdraw his request for a moment, to enable me to make a motion.</p>
                <div className="speaker" id="sp341"><span className="persName">Mr. TURNER</span>—</div>
                <p className="p-in-sp">If the gentleman will permit I shall move to pass by the order of the day until to-morrow.</p>
                <div className="speaker" id="sp342"><span className="persName">Mr. GOODE</span>—</div>
                <p className="p-in-sp">Before yielding the floor, I desire to say a word in reply to the gentleman from Augusta [<span className="persName">Mr. BAYLOR</span>] 
                  who addressed us this morning. That gentleman has thought proper to tell us what, in his opinion, Virginia should do, and to tell us that Virginia should promptly acquiesce in the plan of adjustment adopted by the Peace Conference at Washington. He has undertaken to tell us what, in his opinion, the people should do. I desire to say to him, that in my opinion, Virginia should not
                  acquiesce in the plan of adjustment adopted by the Peace Conference at Washington, and she never will, while the hearts of her sons are alive to her best interests and to her own high honor. And for those whom I represent upon this floor, I am proud to say that they never—no, never—will submit to the plan of adjustment adopted by the Peace Conference at Washington. I now yield the
                  floor.
                </p>
                <div className="speaker" id="sp343"><span className="persName">Mr. TURNER</span>—</div>
                <p className="p-in-sp">I move that the further consideration of the above resolution be postponed until to-morrow.</p>
                <div className="speaker" id="sp344"><span className="persName">Mr. WISE</span>—</div>
                <p className="p-in-sp">There is no necessity for that.</p>
                <div className="speaker" id="sp345"><span className="persName">Mr. TURNER</span>—</div>
                <p className="p-in-sp">Then I withdraw my motion.</p>
                <div className="speaker" id="sp346"><span className="persName">Mr. WISE</span>—</div>
                <p className="p-in-sp">
                  On Monday last in my absence, some disorder occurred in the galleries of this House, and a highly respectable citizen of this city was arrested, and afterwards discharged upon a motion. That citizen very properly jealous of his reputation, asks as an act of justice to him, to be allowed to purge himself of all intention of disrespect towards this body, and to be afforded an
                  opportunity of putting this matter on record. He takes this course, under the idea that there was an entire mistake of the circumstances under which he was arrested. And honoring this body as he does, and not wishing to be understood as being guilty of any disrespect towards it, he placed in my hands this letter, which I now move shall be entered upon the record. I hope it may be
                  read, that the Convention may see the spirit of respect which he manifests towards this body.
                </p>
                <p>The letter was then read by the Clerk as follows :</p>
                <PageNumber num={322} />
                <div className="floatingText">
                  <div className="floatingText_body">
                    <p>RICHMOND, VA., Feb. 28, 1861.</p>
                    <p>
                      Dear Sir : Happening to be in the Hall of the Convention on Monday last, when the alleged disturbance took place in the gallery, and being complicated in the matter, I deem it proper that I should be put right. Permit me therefore to ask you to do me the favor to lay the facts before the Convention, so far as they relate to me. When the PRESIDENT gave the order to clear the
                      gallery I was attending quietly in the lower part of the Hall, assigned to visitors by the Convention, and remained there until the Sergeant at Arms had cleared the gallery, and the greater part of the apartment where I was standing; when he approached me, I motioned him to stand off, and remarked in a distinct, respectful tone of voice : "<span className="persName"
                      >Mr. PRESIDENT</span
                      >, this is not the gallery." <span className="persName">Mr. SHEFFEY</span> immediately submitted a motion that, "it is necessary for the preservation of the dignity and decorum of this body that scenes of this character should be redressed, and therefore moved that the Sergeant at Arms do take the gentleman who made the disturbance into custody, and bring him to the bar of this
                      body." Then, for the first time, the PRESIDENT gave the order to clear the lobby. I trust my views of dignity and decorum are not below the highest standard of the highest rank. But my views of truth and honor, and justice are deeper and stronger than any notion I may have about dignity. Being the "gentleman" referred to in the motion of <span className="persName">Mr. SHEFFEY</span>,
                      it makes me the promoter of the disturbance, as it stands recorded in Monday's proceedings, while, in fact, I had nothing to do with it. I did not participate in the applause, that caused the gallery to be cleared, in the slightest degree, though I heartily approved the sentiment which was applauded. The statement that appears in the proceedings that I left when the motion was
                      made, is a mistake. I would have died on the spot rather than leave before the matter was disposed of ; and when I was taken before the Convention by the Sergeant-at-Arms (whom I exempt from censure) I was discharged on the alleged ground that "it was the first case of disorder that had occurred," without the investigation which the rule required, and which I had a right to
                      demand, in view of the censure embodied in the resolution of Mr. SHEFFEY.
                    </p>
                    <p>Very truly yours,</p>
                    <p>FRANKLIN THOMAS.</p>
                    <p>HON. HENRY A. WISE.</p>
                  </div>
                </div>
                <div className="speaker" id="sp347"><span className="persName">Mr. WISE</span>—</div>
                <p className="p-in-sp">
                  I have only to say that I can endorse the gentleman's <PageNumber num={323} /> character for truth and integrity. And when he tells me that he was innocent of the charge of any intentional disorder or disrespect, I believe him. Knowing his respectability and truth, I believe it due to him to lay the letter before the Convention in order that it may go
                  upon the record.
                </p>
                <div className="speaker" id="sp348"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">The Chair regards this consent of the House as being given—no objection being made.</p>
                <div className="speaker" id="sp349"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">The Chair will take this occasion to announce the names of the committee on the resolution offered by the gentleman from Randolph and Tucker [<span className="persName">Mr. HUGHES</span>] relative to the contract with the proprietors of the Richmond Enquirer for reporting the debates, as follows—Messrs. <span className="persName">HUGHES</span>, <span className="persName">WICKHAM</span> and <span className="persName">RICHARDSON</span>.</p>
              </div>
              <div className="section" id="vsc1965.v1.2.14.7">
                <h3><span className="headingNumber">1.14.7. </span><span className="head">PEACE COMMISSIONERS</span></h3>
                <p><span className="persName">Mr. SHEFFEY</span> offered the following resolution :</p>
                <p>
                  "Resolved, That the Commissioners of Virginia to the late Peace Conference at Washington be requested, at as early a period as may be agreeable to them, after they shall have reported according to the provisions of the act of Assembly appointing them, to explain, severally before the Convention their views of the action of said Conference and of its result; and that Commissioners
                  Hon. Wm. C. Rives, Hon. John W. Brockenbrough and Hon. James A. Seddon be invited, by the President, to occupy seats in this Convention."
                </p>
                <div className="speaker" id="sp350"><span className="persName">Mr. PRICE</span>—</div>
                <p className="p-in-sp">
                  I doubt very much whether we need any oral speech from these gentlemen. Two of them are members of this Convention, and of course it will be competent for them to give such information on the subject of this Peace Conference as they may, from time to time, deem proper to furnish. We have had a great deal of speaking, and I don't know that we have been eminently enlightened in
                  consequence. Considering the fact that the two members of the Commission on this floor will have, by virtue of their connection with this body, a full opportunity to present their views upon the subject of their mission, I do not know that it is proper for us to invite the other three gentlemen to come here and deliver elaborate speeches before this assembly. I certainly would desire
                  that they should have the privilege of seats in this hall. I move that the subject be divided.
                </p>
                <div className="speaker" id="sp351"><span className="persName">Mr. MACFARLAND</span>—</div>
                <p className="p-in-sp">I confess that I do not see any sufficient reason for the division which is asked for of the resolution just offered. It must be remembered that we are to have a report from Commissioners, which I apprehend, will be as comprehensive and as detailed as may be called for, for any purpose of information. Beyond that, I apprehend</p>
                <PageNumber num={324} />
                <p className="p-in-sp">
                  that we are not interested in the views of the honorable gentlemen who constituted the commission. The report to be expected from them, will furnish detailed and valuable information. Beyond that, sir, the matter will be a mere subject of discussion; and, with the disposition to speak in this House, which seems to exist, I apprehend there is scarcely any opportunity to enable these
                  gentlemen to present their views if invited to do so.
                </p>
                <p className="p-in-sp">I trust, therefore, that the motion to divide the subject, will be withdrawn, and I may be permitted to move to lay the resolution upon the table. I make that motion.</p>
                <div className="speaker" id="sp352"><span className="persName">Mr. HARVIE</span>—</div>
                <p className="p-in-sp">I desire to know the object of the gentleman in making that motion. I regard it as a matter of great importance to this body that they should have the fullest information on the subject of this Peace Conference.</p>
                <div className="speaker" id="sp353"><span className="persName">Mr. DORMAN</span>—</div>
                <p className="p-in-sp">I rise to a point of order.</p>
                <div className="speaker" id="sp354"><span className="persName">Mr. HARVIE</span>—</div>
                <p className="p-in-sp">I call for the ayes and noes upon the motion to lay on the table. I desire to be heard upon this question.</p>
                <div className="speaker" id="sp355"><span className="persName">Mr. MaCFARLAND</span>—</div>
                <p className="p-in-sp">In order to afford the gentleman an opportunity of presenting his views in regard to this subject, I will withdraw my motion if the gentleman from Amelia [<span className="persName">Mr. HARVIE</span>] will renew it.</p>
                <div className="speaker" id="sp356"><span className="persName">Mr. HARVIE</span>—</div>
                <p className="p-in-sp">I will not.</p>
                <div className="speaker" id="sp357"><span className="persName">Mr. MACFARLAND</span>—</div>
                <p className="p-in-sp">I renew it then, sir.</p>
                <div className="speaker" id="sp358"><span className="persName">Mr. JOHNSON</span>, of Richmond—</div>
                <p className="p-in-sp">I rise for information in regard to the resolution.</p>
                <div className="speaker" id="sp359"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">The Secretary will read the resolution.</p>
                <p>It was accordingly read.</p>
                <div className="speaker" id="sp360"><span className="persName">Mr. JOHNSON</span>, of Richmond—</div>
                <p className="p-in-sp">
                  If I understand the resolution correctly, it is that the Commissioners who have lately returned from Washington should be invited on this floor to address this Convention orally. I think we have evidence enough already that we have material enough to occupy more time of this Convention than is consistent with the duties which we have assembled here to discharge. I desire to say
                  farther, that two of these Commissioners are members of this Convention, who, in that capacity, will be enabled to give us their experience, and no doubt, a history of the Congress at Washington. I, therefore, do not see—looking to the fact that it may be the object and desire of this Convention to hear from these gentlemen in such a form as to make their report entirely satisfactory
                  to the Convention—that it would be consistent with that wish that these gentlemen should occupy the position here of making oral reports as suggested by the resolution under discussion. I am, therefore, in favor of hearing from them by a <PageNumber num={325} /> written report, so that we may know what they did, and understand what they mean. It seems
                  to me it is more proper that we should content ourselves with a written report than that these gentlemen should be invited here to give their views in the form of a speech which the members cannot necessarily appreciate as fully as when committed in a permanent form to writing.
                </p>
                <div className="speaker" id="sp361"><span className="persName">Mr. PRICE</span>—</div>
                <p className="p-in-sp">Will the gentleman allow me to offer my substitute I have for the resolution, before he proceeds farther?</p>
                <div className="speaker" id="sp362"><span className="persName">Mr. JOHNSON</span>—</div>
                <p className="p-in-sp">I do not desire to occupy the floor longer, except to say that I am in favor of giving the privilege of a seat upon this floor to such of the members of the Commission as are not members of this body.</p>
                <div className="speaker" id="sp363"><span className="persName">Mr. MACFARLAND</span>—</div>
                <p className="p-in-sp">
                  I desire to be understood as making no objection to the proposition to give seats upon this floor to the Commissioners. My attention was not directed to that feature in the resolution until after the debate arose upon it, and I now take the first opportunity of stating that, so far from having any objection to extending the privilege of a seat upon this floor to those gentlemen, I
                  should most cheerfully have submitted such a proposition, if it had not been presented from another quarter.
                </p>
                <p><span className="persName">Mr. PRICE</span> then submitted his substitute for the resolution, which was read as follows :</p>
                <p>Resolved, That the President of this Convention be instructed to invite the Hon. Wm. C. Rives, the Hon. James A. Seddon, and the Hon. John Brockenbrough to occupy seats upon this floor.</p>
                <div className="speaker" id="sp364"><span className="persName">Ex-PRESIDENT TYLER</span>—</div>
                <p className="p-in-sp">
                  Mr. President, I beg leave to make a few remarks in explanation of why we, as Commissioners, have not made our report. It arises out of the very act authorizing our appointment. The Legislature, in appointing this Commission, charged us to present to the Congress of the United States, with the confirmation and recommendation of the other States represented, any conclusions to which
                  the Conference might come. They furthermore declared to us and made it our imperative duty, after the determination of Congress comes to be known, to communicate to the Governor by reporting in writing—which report is to be made by the Governor to the Convention now assembled—any matter that we have to make known to the State of Virginia. We, therefore, necessarily have to wait the
                  adjournment of Congress, which will take place in the course of the next three or four days, before we shall be able to ascertain whether those two honorable bodies will take any cognizance of the report of the proceedings submitted to them. Therefore, we cannot report at present. But, Mr. <PageNumber num={326} /> President, there is another impediment
                  standing in the way. The journal of the Peace Conference had not been completed when I left Washington. It would require two or three days to complete it, and the most satisfactory report which it would be in the power of the Commission to present to the Government of Virginia, will be that record of the proceedings of that body. With this explanation I will resume my seat.
                </p>
                <div className="speaker" id="sp365"><span className="persName">Ex-Governor WISE</span>—</div>
                <p className="p-in-sp">Mr. President, I understood the gentleman from Richmond [<span className="persName">Mr. JOHNSON</span>] to have two objections to the resolutions offered by the gentleman from Smyth [<span className="persName">Mr. SHEFFEY</span>] 
                  . He said first that the matter which is to be reported to us need not be debated here, because we have material enough already before us to cause, perhaps, too much talking. I would say in reply to that, sir, that the material which we have before us is all embraced in the result of this Peace Conference, and that the result of this Peace Conference is made with most members here, I
                  presume, the most material of all these materials. He says moreover as the second objection, that as the Commissioners sent from Virginia have to make a written report he does not desire a discussion of the subject by the Commissioners before us. Now, sir, I do not regard those gentlemen as mere Commissioners. In bringing them before us I shall not treat them as mere diplomats,
                  charged with the representation of our affairs at Washington, and to make simply a written report. I shall treat them as witnesses as well as Commissioners. I want from themselves, eye to eye, face to face, to catch the spirit of that Peace Conference. And you cannot catch the spirit from a report. But you may from a debate, you may from a discussion, eliminate many points that the
                  report could never be expected to embrace. I am informed, and I expect it is true, that our Commissioners differed in opinion ; that the five were divided, and of course they were divided upon the different views which they took, on the facts presented to them.
                </p>
                <p className="p-in-sp">
                  Well, the most important fact there was the spirit—of war or peace. One of the worst villains in all history that ever scourged the human race, was called the Prince of Peace; and I want to know, from a strict examination of this Conference, whether or not it is not misnamed, whether it is not a lucus a non lucendo, whether it has not been so divided that, instead of being a Peace
                  Conference, it has rather irritated the causes of civil war. I want to know the scenes behind the curtain of that Convention—what is out of the record, as well as what is in it ; and I want an opportunity of interrogation here, before the face of my constituents, when my peace, my property, my honor, my self respect, <PageNumber num={327} /> my self
                  government and my right at the forum of conscience, are all invaded. I will not trust to the spirit of a report when I can have the spirit of a cross-examination. Nobody knows better than my friend
                 [<span className="persName">Mr. JOHNSON</span>] 
                  , the difference between a deposition and the witness on the stand. No deposition is worth as much as a witness testifying, where you can see his eye, and where you can ask him a question concerning any defect which may appear in the testimony. I therefore wish to have the Commissioners, and to have a discussion, here. If they have disagreed, I want to know why. We hear some asking,
                  where we are going? I want to know which way our Commissioners were tending why they were divided, and what they can recommend, and what they would adjure us not to accept.
                </p>
                <div className="speaker" id="sp366"><span className="persName">Mr. JOHNSON</span>, of Richmond—</div>
                <p className="p-in-sp">I desire, Mr. President, to say a very few words in reply to my friend from the county of Princess Anne [Ex-Gov. WISE] . That gentleman stated that I gave two objections to the resolution offered by the gentleman from Smyth [<span className="persName">Mr. SHEFFEY</span>] 
                  . And so I did. But I did not give the objection which he understood me to have given. I understand my friend from Princess Anne to have said that I was opposed to the passage of that resolution because I said that our experience had shown that there were abundant materials for discussion here already. And so I did. But I did not say that the subject of that Commission should not be
                  discussed. I agree with my friend from Princess Anne that the subject of that commission is the most material of all the material; but I say, sir, that the proper persons to discuss that subject on this floor are the members of this Convention, and nobody else. Those gentlemen, Mr. President, have yet to make a report. I don't want any deposition. I don't want any examination or any
                  cross-examination, and if my voice can prevent it, there shall be no examination or cross-examination on this floor. It is neither statesmanlike nor dignified, in my opinion, for us to proceed in the investigation of an enquiry, when the Commissioners are in possession of all the facts which have distinguished their action in Washington, and when they can give us a lucid history of
                  the proceedings in Washington, so that we may read them, and in such a manner that he who runs may read.
                </p>
                <p className="p-in-sp">
                  Now, sir, is it not better to receive their report, than to bring those gentlemen here for the purpose of examination and cross-examination? To bring those gentlemen here, and when one of them rises to make a speech, the gentleman from Princess Anne gets up to ask a question, and then the gentleman from Richmond gets up to ask another question, and then the gentleman from Smyth gets
                  up to ask still another <PageNumber num={328} /> question—and all this to be incorporated in the proceedings of this Convention. Sir, as its humblest member, I protest against it as unauthorized and unnecessary.
                </p>
                <p className="p-in-sp">
                  Further than that, sir, can we give them the privilege of this floor? <span className="persName">Mr. SUMMERS</span> and the Hon. <span className="persName">Mr. TYLER</span>, members of the Commission and members of this Convention—and who, if the reports are correct, represent opposite sentiments upon this question—can give us the sentiments entertained by those Commissioners. Therefore,
                  while we have it from the most prominent authority that "Nero is fiddling while Rome is burning," I do not see that it is necessary to consume our time by this unnecessary speech-making.
                </p>
                <div className="speaker" id="sp367"><span className="persName">Mr. HARVIE</span> of Amelia—</div>
                <p className="p-in-sp">
                  Mr. President, we are in a minority on this floor. The majority can vote us down upon any proposition that is presented here, if they wish to do so. This is as little as we can take at their hands. But we have a right to expect at their hands that we should present in this Convention and to this Convention, and to the people of the State, in any mode that is legal and proper, any
                  views which will throw light upon this subject. Mr. President, there are to be two reports, as I understand, from these Commissioners. Now, when these two reports are presented here, I want to know how it is and why it is that those Commissioners differ. I want to know it not only from the reports themselves, and from those two Commissioners who are members of this Convention, but I
                  want to know from the other members of the Commission, how it is and why it is that they differ. And I want to know more than that. The majority of that Convention determined to withhold from the public what was going on in that conclave. But we want to know what took place in that conclave ; and I wish to select from among those five Commissioners the one from whom I would prefer to
                  get that information. I know some of those gentlemen well and respect them all. I know some of them better than others; and I may, perhaps, agree with them in my views better than with others. I want them to address the report, so far as they make it, particularly to those questions upon which I agree with them ; and I want them here, I want the people of the country, as well as the
                  members of this Convention, to understand what they have been about, and why they have come to these conclusions. Now, sir, is there any reason for withholding the light from the people of Virginia upon any question that has brought us together? We were sent here to consider these difficulties and then to endeavor to remove them, and it is necessary to receive all the information
                  that we can get, before we can remove them. And I imagine that before this Convention adjourns, it will be important that <PageNumber num={329} /> the people should know what we too will accomplish. Mr. President, this is no idle request. We don't ask that these Commissioners should be invited here merely to do them honor. We want them for great
                  purposes of State. We want them as guides to our conduct. We want them to develop what we have to consider. We want them here for the purpose of assisting us to arrive at a proper understanding of what we are about. I want to interrogate them, if there is anything concerning their mission that I don't understand, for we want all the light upon that subject that we can possibly
                  receive.
                </p>
                <div className="speaker" id="sp368"><span className="persName">Mr. MACFARLAND</span>—</div>
                <p className="p-in-sp">
                  I hope this question will be disposed of without any protracted debate. If the purpose be to treat the gentlemen in question as witnesses, I beg it may be remembered, that it is not necessary to bring them into this hall for the purpose of delivering discourses, in order to obtain the information we may desire. They are gentlemen, I am sure, who will consent to be interrogated by any
                  gentleman of this Convention, in the forth of a proper appeal, and who, in response, will give all the information in their power. I trust then, that no gentleman will vote for the resolution in question, under the apprehension that valuable information will be lost, for it is in the power of gentlemen to obtain the information they may desire, without inviting the gentlemen in
                  question upon the floor to perform the part of orators.
                </p>
                <p className="p-in-sp">
                  One word more, Mr. President; I understood my friend, who last addressed us, to refer to a division in this body between majorities and minorities. Nothing of that sort has yet been disclosed which would enable us to distinguish between majorities and minorities. It is difficult to tell precisely to what the gentleman refers. He surely does not belong to a minority in this body, if
                  reference is had to its speaking talent, for I think, in both the number of speakers and the brilliancy which has marked their speeches, the gentleman and his friends might claim that they were in the majority, and I beg to remind the gentleman that he disparages his own abilities and the abilities of his friends, when he suggests that it may be necessary to appeal to foreign aid for
                  the purpose of supporting propositions upon this floor. If we give our sanction to the precedent, I know not upon what ground any distinguished gentleman could be excluded, who it is suggested might furnish valuable aid, if he were invited to a seat upon the floor, for the purpose of addressing this Convention.
                </p>
                <p className="p-in-sp">
                  I hope, therefore, that it may be the pleasure of the Convention to notify the gentlemen in question that they are entitled to seats upon this floor, but that no sanction proceeding from this body will be <PageNumber num={330} /> given, which either suggests a necessity for their counsels, or that they should occupy upon this floor the position of
                  witnesses who are to submit to cross-examination.
                </p>
                <div className="speaker" id="sp369"><span className="persName">Mr. SHEFFEY</span>—</div>
                <p className="p-in-sp">
                  I did not anticipate a discussion upon this resolution, when I offered it a few days ago. This Convention extended an invitation to the Commissioners of a foreign State to address this body, and lay before them the reasons why South Carolina, Georgia and Mississippi had seceded from the Union. We have sent Commissioners to Washington, for the purpose of conferring with the
                  Commissioners of other States, upon the great questions which now agitate the country, and for the adjustment of which we have assembled here in this Hall. I cannot see why the Commissioners of a foreign State should be granted the courtesy of a hearing before this body, and our own Commissioners be excluded from that privilege, or the members of this body excluded from hearing them.
                </p>
                <p className="p-in-sp">
                  I have not determined to what party I shall ultimately belong in the adjustment of this great question. I can only say, that the impression has been made upon my mind, that the plan of adjustment which has been reported to us, not officially, by the Commissioners or the Governor, but by the newspapers, may be but a re-opening of the agitation and a re-opening of the scenes of Kansas.
                  If I am wrong in this impression, I desire to have my mind informed by the Commissioners who participated in the Peace Conference at Washington. I desire to hear from them personally what may have been the result of their deliberations, and to know from them the scenes behind the curtain. It is with this view that I desire to extend to these Commissioners the courtesy of granting
                  them a hearing upon this floor, and to obtain from them all the light which their oral explanations might afford to us. I desire it for my own information and to aid me in the conclusions to which I desire to come, as well as for the information of the members of this Convention. I offered it in no other view. I desire to hear the explanations not only of the gentlemen who are
                  members of this Convention, but the other gentlemen also who are not members of this body.
                </p>
                <div className="speaker" id="sp370"><span className="persName">Mr. BRANCH</span>—</div>
                <p className="p-in-sp">
                  I wish to bring to the notice of the Convention the resolutions which I offered the other day, and which, if I read aright the indications apparent in this body, are to form a basis for our action. I regard it so from the compliments I have received from fire-eaters and disunion men<span className="note" id="Note41"
                  ><span className="noteLabel">4</span>A corrected version (March 7) of Mr. Branch's remarks reads "Union men" instead of "disunion men."</span
                  >
                  throughout this city, who, if I were to name them, would be considered as worthy of your confidence.
                </p>
                <p className="p-in-sp">But to the subject. [Laughter.] My friend from the county of Amelia [<span className="persName">Mr. HARVIE</span>] 
                  whom I have known for a long time, says that <PageNumber num={331} /> he is in a minority. If he chooses to place himself in that category, let him know that I am pleased to stand here and defend his rights. I go with no majority to prostrate his rights. It is asked here to do, what? To bring gentlemen here to be catechised as witnesses. If I understand
                  the resolution, they wish to induce the Honorable Commissioners from Virginia to give ex-parte testimony. I am no lawyer, but in the Courts of Justice where I have been on juries, they have always had both sides of a question presented. I will never vote to place the Commissioners of Virginia in such a position as to be catechised here. I will never vote to place the honorable and
                  venerable man whom I have known from my childhood, in such a position. The etiquette which I learned in the beautiful chincapin hills of old Chesterfield
                 [laughter] 
                  will never allow me to place these honorable gentlemen in such a category.<span className="note" id="Note42"><span className="noteLabel">5</span>The corrected version reads "predicament" instead of "category."</span> We want their report in writing, and the substance of the action of the Peace Conference can be embodied in that form. I beg gentlemen, therefore, not to pass this resolution.
                  Every gentleman has his own views of the etiquette, propriety, and dignity which are due to these gentlemen and which should be accorded to them. They may differ from me in the position I have here taken, but I do think<span className="note" id="Note43"><span className="noteLabel">6</span>The corrected version reads "do not think."</span> that we should ask these gentlemen to address us
                  orally, but avow our desire to hear any written communication they may choose to make. Let us deliberate as men, as statesmen, as men who come here actuated only by a desire to save the country in this its hour of peril. Let us cast all prejudices aside and exhibit that spirit only which will best promote the interests we all have so near at heart.
                </p>
                <div className="speaker" id="sp371"><span className="persName">Mr. THORNTON</span>—</div>
                <p className="p-in-sp">
                  I do not desire to detain the Convention while I give a few of the reasons that shall influence my vote upon the resolution offered by the gentleman from Smyth. I have examined the propositions submitted by this Peace Conference at Washington. They do not appear at all plain or clear, and I am at a loss to understand what they mean. One who puts himself upon the Southern platform may
                  construe them in one way; but if he puts himself upon the Chicago platform and holds the idea that freedom is the normal condition of the territories of the United States, he may well construe them another way.
                </p>
                <p className="p-in-sp">Now, sir, distinguished Senators were sent to Washington. They have been deliberating there with citizens of over twenty other States of this Union, and they bring back to us these propositions embodied in what is called the Franklin Compromise ; and I agree with the gentleman from Princess Anne [<span className="persName">Mr. WISE</span>] 
                  in saying that they are misnamed when you call them Peace propositions. I do not desire, as the gentlemen from Richmond and Petersburg suppose for I do not understand that <PageNumber num={332} /> to be the meaning of the resolution—that we shall place these Commissioners at our bar to catechise and cross-examine them, but I desire to hear their views
                  upon those propositions that they will submit, and this is what the resolution of the gentleman from Smyth calls for. I want to know what was the construction placed upon those propositions by the gentlemen from the free States of this Union, who moved and supported them. What is the objection to hearing them?
                </p>
                <p className="p-in-sp">
                  My friend from Richmond says that we have already material enough for debate. If we were pressed for time, I might, as a matter of expediency, vote against this resolution ; but I do not think this body seems disposed to be precipitate in its action at all. We have had a discussion now extending over several days, when there is no proposition before the Convention, and when we cannot
                  have a vote. Now, I really think that we might as well be entertained by listening to the honorable gentlemen, Mr. Seddon and Hon. Judge Brockenbrough, as by protracting the present debate, that can lead to no determinate result. From these gentlemen we may derive information that will be of material aid as to the construction that should be given to these peace propositions. No
                  practical result can be effected from the debate now going on. Having plenty of time, why should we object to hearing these Commissioners. Do we not want all the light and all the information that we can possibly get? I will not presume that there is a member of this Convention who would at this time, take from himself or any other member, any right that he may have to speak upon the
                  propositions that will be before us for discussion. We have abundant time before us. These Commissioners have been chosen by the Legislature of Virginia, and sent to Washington because of their eminent talent. I desire to hear them, I desire to be enlightened upon these propositions. I desire to know the construction that Mr. Franklin, the mover of the proposition adopted by the
                  Peace Congress, and that other gentlemen have placed upon those identical propositions; and I want to hear this construction from the Commissioners of Virginia.
                </p>
                <div className="speaker" id="sp372"><span className="persName">Mr. DORMAN</span>—</div>
                <p className="p-in-sp">
                  I have simply to say, so far as I am concerned, that I am unalterably opposed to the resolution of the gentleman from Smyth in my present state of information. We are asked to pass a resolution inviting the Commissioners to seats upon the floor of this Convention, and to appear before us for the purpose of explaining to us what their action has been and what have been the views which
                  other gentlemen from other States of the Union have had upon the subjects before the Congress. I want to know whether gentlemen are authorized to speak on behalf of one or all of these Commissioners. I am unwilling <PageNumber num={333} /> for one belonging—I will not say to a party, but to a portion of this Commonwealth, who entertain decided opinions,
                  with regard to the objects of the Convention, and with regard to what should be our action—that we shall have Mr. Seddon, for example, speaking upon one side, and no gentleman speaking upon the other.
                </p>
                <p className="p-in-sp">
                  I beg to know from the gentlemen who make this proposition if they are authorized to say that all three of these gentlemen are willing to take that position before this Convention. If they are not, we who may be now upon this floor inclined to take one side are put in a position of listening to a lecture from those who are opposed to us, whilst we have no corresponding response upon
                  the part of those who may speak for us. These Commissioners are required to report to the Legislature or to this Convention their proceedings—to report the result of their action; and I wish to know whether the obligation of secrecy imposed upon the members of a body which has been in secret session in Washington for the last few weeks has been removed, so that these Commissioners
                  can honorably reply to questions upon cross-examination. I wish to know these things; but after all, when I do know all these things, I am free to say that I am still unalterably opposed to the course proposed to us. It is true, as the gentleman from Prince Edward has said, that we have been here for two weeks occupied with discussing propositions not of a very definite or precise
                  character; but I, for one, have not been dissatisfied with the position of things, waiting, as we have been, the action of the Commissioners at Washington. When I see something definite brought before me, when I know that we shall have in a day or two officially before us the report of the Commissioners for our own information and consideration, I will join with all who are ready, to
                  do all I can to promote the speedy action of this Convention, and to bring about some speedy result.
                </p>
                <p className="p-in-sp">
                  I want the propositions of our commissioners before us. I want to go into committee of the whole upon that report. I am willing to give my friend from Amelia a full and thorough investigation of those propositions, or of any other propositions before the body; but when that opportunity has been afforded I want that we should take some other action that will go before Virginia as
                  speedily as possible. I trust that those who have been sent here representing the views of the majority of the sentiments of Virginia, will concur with me in seeing the great necessity that we should now endeavor, by every proper means in our power and in accordance with parliamentary practice to bring to a speedy conclusion and some definite point our action. We will have a basis
                  for this in the report of these commissioners. For one, I will <PageNumber num={334} /> not consent to waste time in listening to arguments of counsel, if you choose to call them so, which will lead to no practical result, and which will only have the effect of delaying any practical result that may arise from the deliberations of this body. I agree
                  with my friend from Richmond, and my friend from Amelia, that it is an unnecessary disparagement of themselves and his friends and the cause they represent. when they undertake to call upon any Cassius to help them lest they sink. They have power, parliamentary eloquence, and logical force on their side, and skill in parliamentary practice, and, for one, I say that if their cause
                  fails it will not fail for the want of efficient advocacy. I trust, therefore, that the Convention will see the propriety of adopting the substitute of the gentleman from Greenbrier, and let us at once settle this matter.
                </p>
                <div className="speaker" id="sp373"><span className="persName">Mr. EARLY</span>—</div>
                <p className="p-in-sp">I rise to suggest to my friend from Greenbrier [<span className="persName">Mr. PRICE</span>] , the propriety of adding the name of Hon. John Robertson, who was Commissioner, on the part of this State, to the State of South Carolina.</p>
                <div className="speaker" id="sp374"><span className="persName">Mr. PRICE</span>—</div>
                <p className="p-in-sp">I accept the suggestion.</p>
                <div className="speaker" id="sp375"><span className="persName">Mr. EARLY</span>—</div>
                <p className="p-in-sp">While I am up, I shall offer an explanation of the vote which I shall give—an explanation which I deem necessary in consequence of the remarks made by my friend from Amelia [<span className="persName">Mr. HARVIE</span>] 
                  . These remarks, taken according to their direct import, must be meant to imply that a majority of this body are by some means or other determined to check a free expression of opinion by a minority. The course of proceedings so far, is far from favoring this conclusion ; for we find that five or six speeches have been delivered on their side to the one on the majority side.
                </p>
                <p className="p-in-sp">There is another fact in connection with this matter which I desire to notice. When a gentleman on the majority undertakes to express his views on this floor, he does it at a risk of incurring serious embarrassment and of subjecting himself to such disagreeable consequences as my friend from Rockbridge [Mr. MOORE] 
                  had to encounter for the frank avowal of his sentiments and of those of his constituents. I repudiate the idea that a majority of this Convention are going to take steps here to prevent any gentleman from being heard. We want here to maintain our rights and to express our sentiments at all hazards, and we shall cheerfully accord that privilege to those who differ from us. So far as I
                  am concerned, and I believe I may say the same of all here who agree with me in the policy which I advocate, every gentleman shall have a full and fair opportunity of expressing his views.
                </p>
                <p className="p-in-sp">The gentleman from Princess Anne [<span className="persName">Mr. WISE</span>] 
                  is of opinion that by <PageNumber num={335} /> putting these commissioners in the attitude of witnesses before this body, much important information could be obtained, under a due course of cross-examination. Now, sir, I am not as experienced in public affairs as that gentleman ; but I have had sufficient experience in the practice of my profession as a
                  lawyer, to satisfy me that in the case of an honest, intelligent witness, no cross-examination is necessary in order to elicit the truth, and the whole truth ; while in the case of a dishonest or ignorant witness, it may be necessary. The gentleman has, doubtless, in his experience, also discovered that cross-examination is sometimes resorted to for the purpose of suppressing rather
                  than eliminating the truth.
                </p>
                <p className="p-in-sp">I don't think it is necessary for the purpose of procuring full information on the subject of the Peace Congress at Washington, that these commissioners should be invited to address the Convention, and I shall therefore vote against the resolution and for the substitute.</p>
                <p className="p-in-sp">I call for the yeas and nays upon the proposition.</p>
                <p>The call was sustained.</p>
                <div className="speaker" id="sp376"><span className="persName">Ex-Gov. WISE</span>—</div>
                <p className="p-in-sp">I have received two lectures upon dignity and statesmanship;</p>
                <p className="p-in-sp">
                  one from the gentleman from Petersburg, the other from the gentleman from Richmond. The gentleman from Petersburg is an older and, I admit, a better soldier than I am ; and, perhaps, well entitled to read me a lecture on the proprieties of statesmanship and the decorum of dignity. But my friend from Richmond, although perhaps better qualified, is not so experienced in the duties of
                  statesmanship as I can claim to be. His experience is much shorter than mine. His has been confined to the Courts. Mine has principally been devoted to deliberative bodies. It may be, indeed, that the Judges have instructed him better in the requirements of dignity, than I have learned the lesson of decorum from the presiding officers of deliberative bodies. However that may be, sir,
                  I will not waste much time about the formalities and specialities of dignity when I am after truth. This crisis is too serious for us to be very particular about observing forms, when it is all-essential that we should be enlightened. I must have been unfortunate in the figure of speech which I used when I said I preferred to have a witness on the stand in order to understand him. I
                  prefer to have him on the stand, and to hear and see him, and have an opportunity of interrogating him, rather than merely to read his deposition. I think I may appeal to my brethren of the bar for a universal concurrence on that subject. I think I may say that my friend from Prince Edward
                 [<span className="persName">Mr. THORNTON</span>] 
                  -I am sure I may call him my friend—has perhaps stated my proposition better than I did myself. I only meant to say that I wished to observe and mark closely collisions of debate, to have <PageNumber num={336} /> an opportunity of asking questions of your Commissioners, as I would ask questions of my fellow-members, that, in one word, it would furnish
                  enlightenment, such as cannot be elicited from any written report.
                </p>
                <p className="p-in-sp">
                  I defy any man to read that report when it comes in, without raising a question upon construction; and then there may be such matter in the report as will necessitate further information outside of the report. What objection could be made to that? The gentleman from Rock-bridge says that he wants to know whether <span className="persName">Mr. RIVES</span> will accede to the request to
                  appear before us. I do not know whether he will or not. I know that Abe Lincoln told him the other day that he was not a very tall man in his person, but he was in intellect. I know that, if he does come here, he will do his duty with great ability; and the gentleman, I am sure, need not fear, if he is called, that his cause is likely to lose anything by his advocacy. I am very sure
                  <span className="persName">Mr. RIVES</span> is patriotic enough to come here and give all the information in his power. He may or he may not come. I do hope that he may come. That will be at his own option.
                </p>
                <p className="p-in-sp">
                  But the gentleman says that he wants light. He asks, whether the secrets of the closets of that Conference should be revealed whether the Commissioners have permission to reveal those secrets. Sir, have we come to the Star Chamber days? I would ask it with the voice of a trumpet, if I had the lungs. Is secrecy to be pleaded here? In the next breath the gentleman talks about the voice
                  of the people. Are you not here to enlighten the people and give the truth in full panoply
                </p>
                <p className="p-in-sp">to be our champion before the world?</p>
                <p className="p-in-sp">
                  It is important in the crisis to ask whether the Commissioners that were sent to obtain full information on all that is vital to our interests are to be permitted to disclose the facts and the truth. But the gentleman from Rockbridge tells us that we need not call in foreign aid. Are these Commissioners, I ask you, foreigners? Why, sir, they are your own Commissioners, commissioned
                  under your seal and the seal of your sovereignty. They are coming back to you, the representatives of the sovereignty of the State. Yet they are called foreign Commissioners. I claim them as mine; I claim them as belonging to me; and I, as a member of this Convention, claim the right to interrogate them. Not at their chamber—not to call there and then come here and detail what I
                  learned from them—for one gentleman might go and get the story in one way and I in another way, and perhaps it would be ipse dixit against ipse dixit. I want it in a formal way; I want it in a public way; I want it in the high way; I want it fully before the eyes of my constituents. Who are the men? I would put it to the sense of the
                  <PageNumber num={337} /> dignity of the gentlemen in this house, whether there is anything undignified in listening to the oral report of such men as the Hon. WM. C. RIVES, the Hon. JAMES A. SEDDON and the Hon. JOHN W. BROCKENBROUGH ? Who are they? Select men among the conscript fathers of your honored Commonwealth—selected by your Legislature to go to
                  ascertain the truth, the whole truth for us; whether we can renew the bonds of amity and repair the damage that is done, or whether we must prepare for the worst consequences of the wrongs to which we are subjected.
                </p>
                <p className="p-in-sp">
                  I am told, sir, that the Conference has removed this injunction of secrecy. I care not whether they have removed it or not. I would not permit my Commissioner, if I had authority over him, to keep a secret touching my public liberty. Now, sir, as to this question between the gentleman from Richmond and myself I would ask whether it would be any less dignified to hear the addresses of
                  these Commissioners than to listen to the debates between the gentleman from Richmond and the gentleman from Princess Anne. I can only say, sir, that I would far rather listen to them than to speak myself. My object, my purpose, is enlightenment, and I don't think that your dignity will be impaired by permitting these gentlemen to address us here.
                </p>
                <div className="speaker" id="sp377"><span className="persName">Mr. SCOTT</span>, of Powhatan—</div>
                <p className="p-in-sp">
                  If I believed that the main object of the gentlemen upon the other side who desire that these Commissioners should be heard here was to get from them the facts of the case and nothing but the facts, I would be willing to accede to their wishes. These Commissioners are required to make a written report to this body. If that report should not be full enough, if gentlemen desire those
                  Commissioners to give them information in their possession, which they have not communicated in their report, they can call upon the Commissioners and elicit such additional information as they may desire. Do they propose merely to bring them upon this floor and ask them questions upon cross-examination. No, sir. If these Commissioners are to appear here in this body, they appear not
                  in the character of witnesses, but in the character of advocates. If these Commissioners appear here, they will take part in this debate as advocates defending the position they have taken, as much as they will appear in the character of witnesses to give information to this Convention. The people of Virginia have sent us here to consult upon certain matters. We want facts and we
                  want nothing but facts, and the gentlemen upon this floor are sufficient to debate all these facts to the satisfaction of the people of Virginia.
                </p>
                <p className="p-in-sp">
                  But my friend said that this Franklin Compromise could not be understood <PageNumber num={338} /> by him in some particulars. I do not know to what he alludes. It is not obscure to me. If it be obscure to him I will tell him how he can get information upon it without introducing these Commissioners upon this floor to make speeches to go to the country.
                  We can get all the information we want from them without resorting to this course. If that be the case, I shall vote against allowing these Commissioners to appear here and make speeches. We can receive the communication of the facts they desire to lay before this body in the form of a written report.
                </p>
                <div className="speaker" id="sp378"><span className="persName">Ex-President TYLER</span>—</div>
                <p className="p-in-sp">I have but a word to say.</p>
                <p className="p-in-sp">
                  I have listened to this discussion with interest, and probably with some profit, and I rise now to protest against the conclusions at which some gentlemen have arrived. They raise an antagonism here without the possibility of consulting my friend Judge Summers nor myself about the matter which has led to it. I beg to be understood, sir, that I throw my gage to no man, and especially
                  to my worthy and distinguished friend who has been so frequently referred to. I am sure that he is too gallant a knight in debate and in the courtesy of parliamentary proceeding to throw down his gage to me. I don't know that we have very much to dispute or differ about. I am not prepared to say that I shall open my lips in debate on this subject during the session of the Convention,
                  unless prompted to it by considerations growing out of the peculiar relations which I hold to it. But I repeat that I am not to be placed in a situation which might be unpleasant to me, and I disclaim the imputation of throwing down any gage to any gentleman, and least of all, to one of my brother Commissioners, whose course in connection with the mission with which we have been
                  favored and honored, has been in great part, entirely correspondent with my own. In reference to the other Commissioners, I am sure I but express the sentiment which they would utter, if they were here and honored with a seat in this body, when I state that they too would protest against being placed in any such attitude as gentlemen seem disposed to place me in.
                </p>
                <p className="p-in-sp">
                  You want to be instructed upon certain matters; and these, I beg to say, the journal of the proceedings of the Conference will probably disclose in a manner sufficiently satisfactory. The injunction of secrecy referred to, is upon the speeches, and I am perfectly satisfied that there is no gentleman on this floor who would ask us to break the seal of confidence without the full
                  assent of the body from which we came. We are at liberty to draw our inferences from the opinions of those with whom we have acted. We can judge of the prospects of settlement <PageNumber num={339} /> and adjustment from our knowledge of the course of gentlemen in that body, and their ultimate vote. We may make references from other sources ; but of
                  that we can speak individually.
                </p>
                <p className="p-in-sp">But my principal object in rising was not only to protest against this matter of a single-handed combat between myself and the distinguished gentleman [<span className="persName">Mr. SUMMERS</span>] 
                  who was associated with me in this Commission, and for whom I entertain friendly feelings. I will not suffer myself to be committed to any such contest. I speak for myself. If you want my own individual feelings upon the subject of this resolution, of course there can be but one response upon my part, and that is, to admit my brother Commissioners here to make their developements as
                  broadly and fully as they may think proper. Why not? Because, as some gentlemen say, you have a great deal of speaking talent. This idea will never answer; you want a basis for that talent, and the information which these gentlemen would furnish, would probably supply the very best basis upon which that talent could be exercised.
                </p>
                <p className="p-in-sp">
                  I have heard in the course of this debate, something said here about party. I do not know who is to gain by it. Is it possible that I have got into a party Convention. Parties upon what? Upon what have you broken up into parties? Is it the great question of Virginia's honor? Is Virginia divided upon that? Is she divided upon the great question of her policy in the critical emergency
                  of your country's danger? Did you ever hear that your fathers were divided? Was there any hesitation on their part on the opening of the morning of the revolution? Some lingered behind, others went before, but all ultimately came into the ranks, and there they stood as one man. Should it not be so now? Should we speak of party, party, terrible party, when great issues are at stake
                  and your most cherished rights are in danger? No, no! I call upon you in this assembly of the representatives of the majesty of the people of Virginia, to bury party and trample it under foot as the vampire which, if you do not crush, will rise up and sting you to death.
                </p>
                <p className="p-in-sp">I did not rise to make a speech. I only meant to protest against the unhappy situation in which I am placed. I did not mean to go into the general subject, and I will now close.</p>
                <p>The question was then taken by yeas and nays, upon the substitute offered by <span className="persName">Mr. PRICE</span>, with the following result :</p>
                <p>YEAS-Messrs. John Janney [President] 
                  , Aston, Baldwin, Alfred M. Barbour, James Barbour, Baylor, Berlin, Boggess, Branch, Brown, Burdett, Burley, Byrne, Campbell, Caperton, Carter, Coffman, R. Y. Conrad, Couch, J. H. Cox, Critcher, Dent, Deskins, Dorman, Dulany, Early, French, Fugate, Gillespie, Grant, Gravely, Gray, Addison Hall, Ephraim B. Hall, Haymond, Hoge, Hubbard,
                  <PageNumber num={340} /> Hull, Jackson, Marmaduke Johnson, P. C. Johnston, Lewis, McComas, McGrew, McNeil, Macfarland, Maslin, Masters, Moffett, McD. MOORE, Nelson, Patrick, Porter, Preston, Price, Pugh, Saunders, Robert E. Scott, W. C. Scott, Sharp, Sitlington, Southall, Speed, Spurlock, A. H. H. Stuart, C. J. Stuart, Tarr, Tayloe, White, Wickham,
                  Willey-71.
                </p>
                <p>
                  NAYS-Messrs. Armstrong, Blow, Boisseau, Borst, Bouldin, Cabell, Cecil, Chambliss, Chapman, Conn, C. B. Conrad, R. H. Cox, Fisher, Garland, Graham, Gregory, Goggin, John Goode, Jr., T. F. Goode, Hale, Cyrus Hall, L. S. Hall, Harvie, Holcombe, Hunton, Isbell, Kent, Kilby, Kindred, Lawson, Leake, J. B. Mallory, Marr, Montague, Morris, Morton, Neblett, Randolph, Richardson, Sea-well,
                  Sheffey, Strange, Thornton, Robert H. Turner, F. P. Turner, Tyler, Williams, Wilson, Wise, WOODS-50.
                </p>
                <p>So the substitute was adopted. The resolution as amended was then agreed to.</p>
                <p>On motion of <span className="persName">Mr. EPHRAIM B. HALL</span>, the Convention adjourned until to-morrow at 12 o'clock.</p>
              </div>
            </div>
            <div className="day" id="vsc1965.v1.2.15">
              <PageNumber num={341} />
              <h2><span className="headingNumber">1.15. </span><span className="head">FIFTEENTH DAY</span></h2>
              <div className="dateline">Saturday, <span className="date">March 2</span></div>
              <p>The Convention met at 12 o'clock, M., and was called to order by the President.</p>
              <p>Prayer by the Rev. <span className="persName">Mr. BURROWS</span> of the Baptist Church.</p>
              <div className="section" id="vsc1965.v1.2.15.1">
                <h3><span className="headingNumber">1.15.1. </span><span className="head">THE TAXATION QUESTION</span></h3>
                <div className="speaker" id="sp379"><span className="persName">Mr. TURNER</span>—</div>
                <p className="p-in-sp">I desire to submit to the Convention the following resolutions, and ask that they may be reported and laid on the table. In justice to the gentleman from Mecklenburg [Mr. GOODE] I must say that he is not aware of the import of these resolutions.</p>
                <p>The resolutions were then read as follows :</p>
                <p>
                  Resolved, That it is expedient and proper that the 23d section of the 4th article of the Constitution of the State, shall be so modified, that slaves like other property shall be taxed without exemption, and according to value, and that no exemption of any property from taxation shall be had, without the vote of a majority of all the members elected to each House of the General
                  Assembly.
                </p>
                <p>Resolved, That a Committee to consist of thirteen members, to be selected from the different sections of the State, be appointed, who shall report to this Convention such amendments to the Constitution of the State as will effect the object indicated in the foregoing resolution.</p>
                <p>The resolutions were laid upon the table.</p>
              </div>
              <div className="section" id="vsc1965.v1.2.15.2">
                <h3><span className="headingNumber">1.15.2. </span><span className="head">THE REPORTING CONTRACT</span></h3>
                <div className="speaker" id="sp380"><span className="persName">Mr. WICKHAM</span>—</div>
                <p className="p-in-sp">I ask the gentleman from Mecklenburg [<span className="persName">Mr. GOODE</span>, who was entitled to the floor] , to allow me to offer a resolution in regard to the debates of this Convention, and to make a brief statement in connection with it. The following is the resolution :</p>
                <p>Resolved, That a Committee of five be appointed by the President of the Convention, to whom shall be referred the contract between the said President and the proprietors of the Richmond "Enquirer," in regard to the publication of the debates of the Convention—the said Committee shall have power to send for persons and papers, and shall make a report to this Convention.</p>
                <div className="speaker" id="sp381"><span className="persName">Mr. WICKHAM</span>—</div>
                <p className="p-in-sp">Mr. President, my object in offering that resolution is to bring to the notice of the Convention one of the clauses of the contract which has been entered into by the President in behalf <PageNumber num={342} /> of the Convention with the proprietors of the Richmond "Enquirer." That clause provides—</p>
                <p className="p-in-sp">"and the paper to be purchased by the said proprietors at the expense of the State charging ten per cent above cost for waste and trouble in procuring the same, this being the rate allowed by the Code of Virginia for procuring the paper used in the public printing."</p>
                <p className="p-in-sp">
                  There is a difference of opinion upon the effect of that clause. Gentlemen of legal ability with whom I have consulted are under the impression that it only refers to the extra copies proposed to be furnished to this Convention. Such, however, is not the interpretation placed upon it by the proprietors of the "Enquirer." On the contrary, they have interpreted it to refer to the paper
                  used for the daily and semi-weekly "Enquirer." This, I believe was not the intention of the President in making this contract, nor of the Convention in ordering it to be made. I propose to show to this body what the weekly expense of reporting the debates would be according to the terms of the contract as it is now interpreted by the proprietors of the Enquirer. Fixing the average
                  daily amount of matter reported at eight columns, the expenses per week would be—
                </p>
                <p className="p-in-sp">For composition $156.00</p>
                <p className="p-in-sp">For press-work 50.00</p>
                <p className="p-in-sp">For reporting 360.00</p>
                <p className="p-in-sp">For folding extras 121.60</p>
                <p className="p-in-sp">For paper, putting circulation at 20,000 per week 209.00</p>
                <p className="p-in-sp">Making a total per week of $896.60</p>
                <p className="p-in-sp">It is true, sir, that this contract has been made but it is equally true that there is a doubt in regard to the construction of that contract, and my object is to raise a committee that they may inquire fully into all matters connected with this contract and report the result to this Convention.</p>
                <p className="p-in-sp">
                  For myself, sir, had I been present when this question was first introduced I would have opposed it, and when I came here I voted in favor of a re-consideration of the vote by which the resolution, authorizing the contract to be made, was adopted. The effect of reporting the debates, I apprehend, will be to add indefinitely to these debates. I am satisfied that the newspapers of
                  Richmond possess enterprise enough to report what is worth reporting. What was not they would, of course, leave unreported.
                </p>
                <p className="p-in-sp">
                  I trust it will be the pleasure of the Convention to adopt that <PageNumber num={343} /> resolution; and I trust also, that when the President comes to appoint the committee, if he shall be authorized to do so, he will put upon it some gentlemen who may have a practical knowledge of the printing business, and I understand there are some in this body.
                </p>
                <div className="speaker" id="sp382"><span className="persName">Mr. FISHER</span>—</div>
                <p className="p-in-sp">
                  This seems to me to be rather a singular course for this Convention to pursue. As I understand it, a contract has been made between the President of this Convention and the proprietors of the "Richmond Enquirer," for reporting and publishing the debates of this body. Suppose it should turn out as gentlemen seem to think it may, that this contract is susceptible of two constructions.
                  Will it be in the power of this House to enforce its own construction? If this contract should be susceptible of two constructions, I take it for granted that some third party must be required to investigate and decide any differences of opinion that may arise between the parties to it. Suppose a committee is appointed to examine this contract, and they make a report to this body,
                  putting a different construction upon it from that which the proprietors of the "Enquirer" have put upon it—can you alter it? Sir, Virginia may be, in a few years, prepared to repudiate her public debt, but I take it for granted that this body is not going to set the example of repudiating its own contract. If it will, let it go to the Judiciary, if necessary, to place a proper and
                  true interpretation upon that contract.
                </p>
                <p className="p-in-sp">
                  Suppose this Committee should be composed of the most enlightened gentlemen who have seats upon this floor; suppose that the services of a practical printer should be invoked to enlighten this Committee ; are we to take the opinions of the most enlightened printers in the Commonwealth as to the true construction of that contract? Undoubtedly not. I think it would be infinitely better
                  that it should be referred to the ablest lawyers, of this body, if it be referred at all.
                </p>
                <p className="p-in-sp">But why refer it at all, when no issue has been made up, as I understand, between the Commonwealth and the proprietors of the "Enquirer?" When there shall be an issue made up it will be for this body to consider the question.</p>
                <p className="p-in-sp">
                  I hope that it will not be the pleasure of this Convention to adopt this resolution, because it can result in no good to us nor to any one else. Whenever the Auditor of Public Accounts shall be called upon to pay the proprietors of the Enquirer the amounts due to them, he will place his own construction upon the contract, and if he should be in doubt as to its terms, I take it for
                  granted he will take the opinion of the Attorney, and, if needs be, carry the matter to Judge Meredith's Court. This Convention, sir, will never act upon the principle that <PageNumber num={344} /> it is its right to construe a contract to which it is a party and that this construction shall be taken by the other party to it, who are as independent as
                  this body itself.
                </p>
                <p className="p-in-sp">I shall, therefore, in accordance with usages that seem to prevail here, move that the resolution be laid upon the table.</p>
                <div className="speaker" id="sp383"><span className="persName">Mr. WICKHAM</span>—</div>
                <p className="p-in-sp">
                  I desire to say, that as far as I am individually concerned, I concur with the editors of the "Enquirer" in their construction of this contract, so that I have no intention of asking this Convention to decide as to what is the meaning of the contract. I understand, as well as the gentleman, that the Judiciary is the proper source to which to refer for a decision upon the meaning of
                  contracts.
                </p>
                <div className="speaker" id="sp384"><span className="persName">Mr. GOGGIN</span> [who was temporarily occupying the Chair]—</div>
                <p className="p-in-sp">Not being perfectly familiar with parliamentary proceedings, I deem it my duty, as temporary Chairman of this body, to inquire if debate is in order upon a motion to lay on the table? My opinion is that it is not in order.</p>
                <div className="speaker" id="sp385"><span className="persName">Mr. BURDETT</span>—</div>
                <p className="p-in-sp">I call for the ayes and noes upon the motion to lay on the table.</p>
                <p>The call was sustained.</p>
                <div className="speaker" id="sp386"><span className="persName">Mr. FISHER</span>—</div>
                <p className="p-in-sp">Debate not being in order upon a motion to lay upon the table, I withdraw it for the present.</p>
                <p className="p-in-sp">
                  Mr. President, I was going on to say that it was unquestionably competent for this body, if by an oversight in entering into this contract, it subjected the Commonwealth to unjust expense, to make a proposition to the proprietors of the Enquirer which might satisfy them and induce them to relinquish their contract. The resolution is so worded as to give this committee, if it should
                  be raised, entire discretion in regard to what this report should be. I desire the Convention to have all the light upon the subject that it is proper they should have, to enable them to take such action as they may see fit. If the Iommittee shall report in a manner not acceptable to this House, the question will nevertheless be before them, and they can give it such consideration
                  and take such action in regard to it as they may think proper. It seems to me that any inquiry that might result in saving the public money would be desirable, inasmuch as the treasury is not in a very favorable condition. At all events, this committee, if it can do nothing more, may make some arrangement which may prove satisfactory to all, and lead to a better understanding as to
                  the nature of the contract.
                </p>
                <div className="speaker" id="sp387"><span className="persName">Mr. FISHER</span>—</div>
                <p className="p-in-sp">I have never seen the contract, and never heard that there was any conflict of views in regard to the construction of that <PageNumber num={345} /> contract until I came to the House and heard the remarks of the gentleman from Henrico [<span className="persName">Mr. WICKHAM</span>] . But whilst I would save every dollar I could to the Commonwealth, I: would consent to no repudiation of a contract. If any outside arrangement can be made, I have no objection to it. But I do not think that the House ought to originate the principle that because possessing the power of repealing its own contract it should do so.</p>
                <p className="p-in-sp">I renew the motion to lay the resolution on the table.</p>
                <p>The question was then taken on that motion by yeas and nays, with the following result—yeas 30, nays 62, as follows :</p>
                <p>
                  AYES-Messrs. Boisseau, Chambliss, Conn, Richard H. Cox, Fisher, Forbes. Graham, John Goode, Jr., Thomas F. Goode, Hale, L. S. Hall, Harvie, Hunton, Isbell, Kent, Kindred, Marr, Moffett, Montague, Morton, Neblett, Randolph, Richardson, Sheffey, Thornton, Tredway, Robert H. Turner, Franklin P. Turner, Wilson-30.<span className="note" id="Note44"
                  ><span className="noteLabel">1</span>The Journal includes Mr. Garland among the ayes.</span
                  >
                </p>
                <p>
                  NAYS-Messrs. Aston, Baylor, Berlin, Boggess, Bouldin, Branch, Burdett, Burley, Byrne, Campbell, Carter, Couch, James H. Cox, Custis, Deskins, Dorman, Early, Fugate, Gillespie, Grant, Gravely, Goggin, Addison Hall, Cyrus Hall, Ephraim B. Hall, Haymond, Hoge, Hubbard, Hull, Jackson, Marmaduke Johnson, Kilby, Lawson, McGrew, McNeil, James B. Mallory, Marshall, Marye, Sr., Maslin,
                  Masters, Nelson, Osburn, Patrick, Pendleton, Porter, Price, Rives, Saunders, Sr., Wm. C. Scott, Sharp, Sitlington, Speed, Spurlock, Alexander H. H. Stuart, Chapman J. Stuart, Summers, Tarr, Tayloe, White, Wickham, WOODS, Wysor-62 .
                </p>
              </div>
              <div className="section" id="vsc1965.v1.2.15.3">
                <h3><span className="headingNumber">1.15.3. </span><span className="head">EXPLANATIONS OF VOTES UPON THE ABOVE MOTION</span></h3>
                <div className="speaker" id="sp388"><span className="persName">Mr. CHAMBLISS</span>—</div>
                <p className="p-in-sp">When his name was called to vote upon the motion to lay the resolution on the table, said :</p>
                <p className="p-in-sp">
                  If this were an original proposition for inquiry about making the contract, I should certainly have voted differently from what I shall do now, but regarding this as a contract which has been completed by the competent authority, and not feeling that we are at liberty to change it, in any particular, and that some other tribunal must decide the matter, if a difference of opinion
                  exists, I shall vote to lay the resolution upon the table.
                </p>
                <div className="speaker" id="sp389"><span className="persName">Mr. GOODE</span>, of Mecklenburg—</div>
                <p className="p-in-sp">For the reason assigned by the gentleman from Greenesville [<span className="persName">Mr. CHAMBLISS</span>] I vote aye.</p>
                <div className="speaker" id="sp390"><span className="persName">Mr. HALL</span>, of Wetzel—</div>
                <p className="p-in-sp">
                  When his name was called, said : In explanation of my vote, I will make this remark, that I look upon this as an executed contract, not an executory contract ; and that whatever the committee appointed by this body or the body itself may do, can, with perfect impunity, be treated with contempt by the other parties to the
                  <PageNumber num={346} /> contract. I do not want to make such a fool of myself as to place myself in such a position. I vote aye.
                </p>
                <div className="speaker" id="sp391"><span className="persName">Mr. J. B. MALLORY</span>—</div>
                <p className="p-in-sp">When his name was called, said : I believe that the question relative to this contract ought to be attended to at the time it was proposed ; but remembering the celebrated supplement of 1856, I shall vote no.</p>
                <div className="speaker" id="sp392"><span className="persName">Mr. WILSON</span>, of Harrison—</div>
                <p className="p-in-sp">When his name was called, said : Inasmuch as there is a difference of opinion about the contract, and the Convention does not seem well informed with reference to it, no doubt because of its failure to bestow upon it the attention which it was proper it should, I shall vote aye.</p>
                <div className="speaker" id="sp393"><span className="persName">Mr. MONTAGUE</span>—</div>
                <p className="p-in-sp">I was not in the Hall until the gentleman from Henrico was about to conclude his remarks upon the resolution, and therefore I do not exactly know the object of the resolution. I desire to know if the object is to alter or rescind the contract which has been entered into by the Convention with the proprietors of the Enquirer.</p>
                <div className="speaker" id="sp394"><span className="persName">Mr. WICKHAM</span>—</div>
                <p className="p-in-sp">The object of the resolution is simply that a committee may inquire into all the facts connected with that contract, and to report to this Convention.</p>
                <div className="speaker" id="sp395"><span className="persName">Mr. MONTAGUE</span>—</div>
                <p className="p-in-sp">For what purpose?</p>
                <div className="speaker" id="sp396"><span className="persName">Mr. WICKHAM</span></div>
                <p className="p-in-sp">To ascertain whether it would not be competent for the Convention, in case of any oversight on their part in closing that contract, to propose a remedy, or whether it would be proper for them to recommend to the Auditor of Public Accounts to take legal opinion before making any payments.</p>
                <div className="speaker" id="sp397"><span className="persName">Mr. MONTAGUE</span>—</div>
                <p className="p-in-sp">I intended raising a question of order, but will not do it at present. I will effect my object in some way if I can get the attention of members for a moment.</p>
                <p className="p-in-sp">It is very clear, upon the gentleman's explanation of this resolution, that the object is to alter, rescind or in some way interfere with this contract.</p>
                <div className="speaker" id="sp398"><span className="persName">Mr. WICKHAM</span>—</div>
                <p className="p-in-sp">I don't see any right that the gentleman has to impute to me designs that I have not expressed. I have made known fully and entirely all the designs that I had in view in offering the resolution, and I do not see how I can be misunderstood.</p>
                <div className="speaker" id="sp399"><span className="persName">Mr. MONTAGUE</span>—</div>
                <p className="p-in-sp">
                  I assure the gentleman I mean no personal disrespect to him iri drawing my inferences of the motives which the resolution implies according to my idea. Surely the resolution means something or it means nothing. If it means any thing it is, that it proposes to interfere with this contract. I maintain that you have no <PageNumber num={347} /> power to
                  interfere with it all. The President has, by order of the Convention, made the contract, and it was subsequently ratified by this body. They cannot now take any action in the matter under the fifth rule which reads—
                </p>
                <p className="p-in-sp">"A question being once determined must stand as the judgment of the House, and cannot during the same session, be drawn again into debate."</p>
                <p className="p-in-sp">If that rule means anything, it obviously precludes this Convention from taking any action in reference to this contract, or interfering with it in any way.</p>
                <div className="speaker" id="sp400"><span className="persName">Mr. GOODE</span> of Mecklenburg—</div>
                <p className="p-in-sp">I claim the floor. I yielded it only to offer a resolution, but not for debate.</p>
                <div className="speaker" id="sp401"><span className="persName">The PRESIDENT</span> [<span className="persName">Mr. GOGGIN</span> acting temporarily]—</div>
                <p className="p-in-sp">The Chair is of opinion that the gentleman having yielded, and the question which was introduced through the courtesy extended by him being under consideration and not disposed of, as yet, he cannot claim the floor.</p>
                <div className="speaker" id="sp402"><span className="persName">Mr. BRANCH</span>—</div>
                <p className="p-in-sp">
                  It seems to me that there is some ambiguity in the contract, and that being so, I think it is entirely proper that there should be a committee to inquire into the matter. If there is an error. it ought to be corrected. It is due to both parties to the contract that it should be corrected, in order that when the editors of the Enquirer presented their accounts to the Auditor there
                  should be no difficulty about the payment. I believe the Committee should be raised by all means, and the whole subject referred to it.
                </p>
                <div className="speaker" id="sp403"><span className="persName">Mr. WICKHAM</span>—</div>
                <p className="p-in-sp">I desire to say a single word in response to the gentleman from Middlesex.</p>
                <div className="speaker" id="sp404"><span className="persName">Mr. GOODE</span>, of Mecklenburg—</div>
                <p className="p-in-sp">The gentleman has spoken twice upon this subject, and has, therefore, exhausted his privilege.</p>
                <div className="speaker" id="sp405"><span className="persName">Mr. WICKHAM</span>—</div>
                <p className="p-in-sp">I desire simply to make an explanation, how I came to bring this matter before the Convention.</p>
                <p className="p-in-sp">I was appointed a member of a committee raised yesterday to ascertain whether the debates could not be published in a different form from what they are now.</p>
                <div className="speaker" id="sp406"><span className="persName">Mr. EARLY</span>—</div>
                <p className="p-in-sp">I wish to make a remark or two.</p>
                <p className="p-in-sp">I know nothing about the terms of this contract; but the gentleman has suggested that it is an ambiguity. This Convention, I am sure, has no idea of repudiating any contract that has been made.</p>
                <p className="p-in-sp">But I will submit to the gentleman from Northampton [<span className="persName">Mr. FISHER</span>] 
                  if there is ambiguity in regard to it, whether justice and good faith do not require that we should render the matter clear so as to avoid any conflict or misunderstanding hereafter between the proprietors of the <PageNumber num={348} /> "Enquirer" and the Convention. If these gentlemen have one interpretation and we another and they are permitted to go
                  on executing the contract according to our<span className="note" id="Note45"><span className="noteLabel">2</span>This should probably be "their."</span> interpretation of it, would not the result be a deception on one side, or both sides, perhaps? I think that good faith on the part of these gentlemen require that we should come to some distinct understanding with regard to the terms of this
                  contract.
                </p>
                <p className="p-in-sp">The gentleman from Wetzel [<span className="persName">Mr. HALL</span>] 
                  has drawn a distinction between an executed contract and an executory contract. I think the state of this contract is in keeping with the distinction which the gentleman has made. He does not draw a distinction between a contract that has been made and one that has been executed. This contract has been entered into, but it is still an executory contract. The debates of this
                  Convention have not yet been reported and published, and until they have been, the contract will remain an executory contract.
                </p>
                <p className="p-in-sp">The question was then put on the adoption of the resolution, and decided in the affirmative.</p>
              </div>
              <div className="section" id="vsc1965.v1.2.15.4">
                <h3><span className="headingNumber">1.15.4. </span><span className="head">CONTESTED ELECTION</span></h3>
                <div className="speaker" id="sp407"><span className="persName">Mr. HAYMOND</span>—</div>
                <p className="p-in-sp">The President of the Convention has furnished me with papers containing depositions made in regard to the contested election from the county of Lee, which I beg leave to present.</p>
                <p className="p-in-sp">I suppose there ought to be a motion made that these papers be referred to the Committee on Elections. I make that motion.</p>
                <p>It was agreed to.</p>
                <div className="speaker" id="sp408"><span className="persName">Mr. HAYMOND</span>—</div>
                <p className="p-in-sp">I have another paper furnished to me by the gentleman from the county of Lee who is the sitting member, whose election from that county is contested. I also present that paper and ask that it be referred to the Committee on Elections.</p>
                <p>It was so ordered.</p>
              </div>
              <div className="section" id="vsc1965.v1.2.15.5">
                <h3><span className="headingNumber">1.15.5. </span><span className="head">FEDERAL RELATIONS</span></h3>
                <p><span className="persName">The PRESIDENT</span> announced the unfinished business, being the resolutions offered some days ago by <span className="persName">Mr. MOORE</span> of Rockbridge, to be next in order.</p>
                <div className="speaker" id="sp409"><span className="persName">Mr. GOODE</span> of Mecklenburg, being entitled to the floor, addressed the Convention :</div>
                <p className="p-in-sp">
                  Mr. President —I am aware that the debate which has progressed for several days, and upon which I am now about to enter, can result in no practical good, so far as the action of this body is concerned. While, however, resolutions and speeches are being sent out to the country calculated to excite a prejudice in the minds of our people
                  <PageNumber num={349} /> against those with whom, I trust, we are soon to be re-united—while a fierce arraignment is being made of the minority upon this floor—I am indisposed to remain silent. A distinguished gentleman from Richmond
                 [<span className="persName">Mr. MACFARLAND</span>] 
                  told us yesterday that he was not aware that we had a majority or a minority upon this floor. Sir, were I disposed, I might recount much, very much, to show that we have a majority here, and that not a very tolerant majority. But, sir, I pass from the subject. Sir, a little while since in the history of a nation, and the sun of Heaven shone upon no land so rich in all that pertains
                  to national greatness, prosperity and happiness as ours. Throughout a wide extended country, we were one people—one in sentiment, one in interest, one in the bonds of fraternal love. We knelt together around the same political altar, with every heart throbbing in unison and offering a prayer that our Government might endure forever. A future was spread out before us bright as fancy
                  ever sketched. But, alas! all is changed ; a dark cloud now hangs like a pall along our whole political horizon. We are in the midst of revolution—of a revolution that is sweeping before it the very foundation of our Governmental fabric. Property in the South to the value of millions of dollars, the lives of the Southern people, the sanctity of Southern homes and firesides are put in
                  issue. The gentleman from Rockbridge
                 [Mr. MOORE] 
                  charged before the Convention and the country that the responsibility for this dire calamity rested, in part at least, upon the Democratic party and the action of the cotton States. Sir, not for the vindication of those with whom I have been politically associated will I breathe one word of party upon this floor. If, in this great crisis of the country, if, at a time when we should
                  meet together here, as men who are to confront a common danger, the party strifes and animosities of the past are to be disentombed, I leave the noble work to others—I mean to have no part nor lot in it. I desire, sir, but to say for the Democratic party, that it went down before the same irresistible power that had already destroyed its great antagonistic party—the old Whig
                  party—before the same power before which had gone down the American party, reared for a time upon its ruins. And, sir, they all went down before a power that had severed those who had knelt together in Christian fellowship, and that power was the great antislavery power of the North. The gentleman from Rockbridge
                 [Mr. MOORE] 
                  , told us that the Cotton States were not the parties to the grievances of which the border States complained—that they had but availed themselves of our grievances as a pretext for carrying out a foregone determination. In this I may well leave the gentleman's <PageNumber num={350} /> resolution to answer the gentleman's speech; for that resolution, in
                  enumerating the wrongs which have been done us by the free States, charges that they have refused to surrender fugitive slaves; that they have refused to surrender criminals from justice; that they have sought to deprive the Southern States of any portion of the common territories; that they have circulated incendiary works among us; that they have furnished arms to bands of
                  assassins to invade our borders and murder our citizens. Sir, is it nothing to the Cotton States, that inflammatory and incendiary works are circulated among their slaves? I presume the gentleman himself will hardly contend that this evil is confined to the border States. Is it nothing, sir, to the Cotton States that they are denied the right of participation in the common
                  territories? Is it nothing to them that they see in the invasion of our soil a determination to assail by violence an institution which they have in common with us? But, sir, the gentleman's resolution may safely be left to answer the gentleman's speech. It was a question for some days, where the gentleman from Rockbridge would like to go. It has at last, I believe, been ascertained.
                  He would have Virginia stand alone, roll the storm as it may. One thing, at least, is certain, he would not put into a Southern port. Sir, I must be pardoned for saying that this idea that Virginia is to stand alone in this great crisis, has in it about the philosophy and foresight of him who would stand upon a sand bar in the midst of a mighty stream, while a swelling tide was
                  beating around him. The gentleman would frighten the people of Virginia from a union with the Southern States, with the hobgoblins of direct taxation, the re-opening of the African slave trade, and a standing army to protect our border. Sir, the provisional government adopted at Montgomery, has already put the African slave trade under the ban; it has already adopted a revenue policy
                  for the support of the Government; and, sir, for the protection of our border, as between us and the free States, as independent people, it will be much better protected by treaty stipulations than it is now. A distinguished gentleman from Bedford
                 [<span className="persName">Mr. GOGGIN</span>] 
                  attempted to show that the tobacco interest of Virginia would suffer greatly should the State go into a Southern Confederacy. He read from statistics to show that the exportation of tobacco from Richmond to the Northern ports, greatly exceeded that to all the European ports, and he desired to know if we were willing to be cut off from these ports. I take it, sir, that we can't be cut
                  off from these ports at all. We may and probably will have a duty to pay on entering those ports. But I beg to inform the gentleman that eight-tenths of the tobacco sent to the Northern <PageNumber num={351} /> ports, is sent for re-shipment, and of the manufactured tobacco, a very-large proportion is for re-shipment to the Southern markets. Now, sir,
                  let Virginia remain in the present Confederacy, and we will have a duty to pay on all tobacco going into the Southern ports, which will ultimately fall upon the tobacco-growers. Let us, sir, go into the Southern Confederacy, and we may avoid for the most part the duties which may be imposed by the Northern Confederacy, by shipping our tobacco, designed for the European and Southern
                  markets, by sending it direct to their respective ports. Sir, the question of responsibility, for the present condition of the country, has been raised upon this floor—I charge the whole responsibility upon a wanton, systematic and persistent warfare upon the part of the Northern States, upon the institutions of the South, and making this charge I make my appeal to the truth of
                  history.
                </p>
                <p className="p-in-sp">
                  The agitation of the slavery question commenced with the application of Missouri for admission as a State into the Union—unfortunately for us, the Southern patriots of that day thought that the demands of political ambition might be satisfied by concession. In a spirit of loyalty and devotion to the Union, to put to rest, as they hoped, for ever a question which endangered its
                  perpetuity, they proposed what they called a compromise, but which in point of fact was a complete surrender of the rights of the South. The North accepted it. That compromise excluded the South from all participation in, and dedicated forever to freedom one half of the territory acquired from France.
                </p>
                <p className="p-in-sp">
                  That settlement, indeed, lulled the angry passions of the moment and gave a brief repose to the country. But with what a prophetic spirit spoke the sage of Monticello, when he said it was "a reprieve only, not the final sentence." In a few years, the agitation of the slavery question was renewed with redoubled violence, and in a form much more dangerous and alarming—a wild fanaticism
                  entered the list with lust of political power—anti-slavery societies were organized throughout the Northern States; Northern men contributed freely their means for the publication and circulation throughout the South, of newspapers and pamphlets, of an inflammatory and incendiary character. They sent amongst us, under every garb that ingenuity could devise, hired emissaries to incite
                  our slaves to rebellion and violence. The legitimate and inevitable result of these things, was the formation of insurrectionary plots, which carried a feeling of apprehension to every fireside in the South. No man who can recollect anything of the occurrence, will ever forget the dismay and horror that seized upon our people as the tidings went out of the bloody tragedy enacted upon
                  <PageNumber num={352} /> the soil of Southampton in August, 1831. We would think that fanaticism itself, would have been appalled at the dread result of its own teachings—that its hand would have been stayed—that it would have made haste to retrace its steps. But not so; a few years later the Hydra of anti-slavery commenced a warfare upon Southern
                  society in yet a different form. The halls of the national Legislature were flooded with petitions for the abolition of slavery. The angry passions of men North and South were aroused. The two sections were brought into angry collision—the perpetuity of the Government put in issue —again peace was restored to the country by Southern concession by a virtual admission on the part of
                  Southern men, that Northern fanatics had a right to petition for the destruction of their property. The acquisition of the Mexican domain, the result of the Mexican war a few years later, was again the signal for the gathering of the Northern clans, to war upon the Constitutional rights of the South. That domain was acquired, in part at least, by the sacrifice of the lives of
                  Southern men and the expenditure of Southern treasure. Yet, while it was red with Southern blood, while the bones of Southern men bleached upon its bosom, Northern fanaticism and ambition demanded that it should be consecrated forever to freedom, and its doors forever closed against Southern men and their institutions. This demand gave rise to a sectional alienation and hate, which a
                  third time threatened the perpetuity of the Government. Again, as in times past, the rights of the South were freely offered upon the altar of the Union. The South accepted the compromise of 1850, by which California was admitted into the Union with a Constitution, without warrant in constitutional proceeding, which excluded her institutions forever from the whole of that vast and
                  wealthy country. Thus it will be seen, that whenever sectional strife has endangered the Government, it has been the rights of the South that have been offered up to maintain it. But, sir, there are yet other evidences of the fixed and determined hate of the Northern people to the South and her institutions. The Constitution of the United States provides, that persons held to service
                  or labor in one State, escaping into another, shall be delivered up on claim of the person to whom such service or labor may be due. They have, by the enactments of their State Legislatures, nullified that provision. Southern men seeking to enforce the fugitive slave law are liable to be prosecuted as felons, and to be subjected to fine and imprisonment. Their mobs have shed the
                  blood of the Southern master, with no other provocation than that of attempting peaceably to get possession of his fugitive slave, and no attempt has been made to <PageNumber num={353} /> punish those engaged in them. Under the decision of the highest tribunal in the government, Southern men have a right to go with their slaves into the common
                  Territories. Northern emigrant aid societies have flooded those Territories with mercenary bands, armed with Sharp's rifles and Bowie knives, for the accomplishment by violence, of that which they can not do by constitutional legislation. And now, sir, for the crowning act, and as an evidence of the inevitable and dread result of the aggressive spirit of the anti-slavery party, I
                  point you to the bloody tragedy enacted at Harper's Ferry on the night of the 16th of October, 1859; and for the evidence of that transaction, I point you to the majority report of the Joint Committee of the Legislature, for the consideration of that outrage.
                </p>
                <p className="p-in-sp">
                  By that report, we are informed, that on that night, "a band of armed conspirators from the Northern States, in pursuance of a design which had long been entertained, and deliberately matured, made an incursion into the State of Virginia, for the purpose of inciting slaves to insurrection, of putting arms into their hands, of aiding them in plundering their masters, murdering them
                  and their families, and overthrowing the government of the Commonwealth." By that report, we are further informed, that the evidence before it was sufficient to show the existence of a wide-spread conspiracy in many of the Northern States, not only against the peace and security of Virginia, but embracing all the Southern States. And, sir, I would ask, if this conspiracy does not now
                  exist? If I am told that it does not, I desire to know, when and by what means it was crushed out? And if it still exists, does it not in itself, furnish abundant cause for the dissolution of this government? And yet, sir, in the face of this report, made by a committee of which, if I am not mistaken, a distinguished gentleman from Augusta
                 [<span className="persName">Mr. STUART</span>] , now a member of this body was chairman, the gentleman from Rockbridge [Mr. MOORE] 
                  would have the people of Virginia believe that the cotton States have nothing to complain of. But, sir, the world will judge of the causes which have impelled the seceded States to dissolve their connexion with the present confederacy, and the people of Virginia will judge of the sentiments which prompted that gentleman to so fierce an arraignment of those States. Sir, we would
                  naturally have supposed that the invasion of a sovereign State and the murder of her peaceful citizens would have called forth from the Northern masses a universal cry of condemnation; that it would have aroused into action a conservative spirit of power to roll back the tide of fanaticism. But not so. By many at the North, the hero of Harper's Ferry was regarded as a martyr in a
                  holy cause. <PageNumber num={354} /> In honor of his achievement, a motion for an adjournment was made in the Legislature of the State of Massachusetts, which came within three votes of being carried.
                </p>
                <p className="p-in-sp">
                  The movers and participators in all these things are now leagued and banded together in a great political brotherhood, known as the Republican party. That party now holds the power in every department of government, save the supreme Court. A few more revolutions of the political wheel, and that, too, will pass under the yoke. With the view, then, sir, of ascertaining the extent of
                  the danger to which we are exposed by reason of the ascendancy of this party in the government I propose briefly to review and to trace to their legitimate results the declarations of principle which it has made, both through its organized bodies, and its representative men.
                </p>
                <p className="p-in-sp">
                  To begin with the platform which it adopted in 1856, and virtually endorsed by the Chicago Convention, we find that it holds the great doctrine of the declaration of American Independence, "that all men are endowed with the inalienable right to life, liberty and the pursuit of happiness," as applicable as well to the black as to the white man. In other words, that it holds the
                  doctrine of the equality of the races—a doctrine which, practically carried out, must not only give liberty to the slave, but place him on the same scale, socially and politically, with his former master. Again, that platform declares that Congress has sovereign power over the territories, and that in the exercise of that power that it is both the right and the imperative duty of
                  Congress to prohibit in the territories those two relics of barbarism—polygamy and slavery. Thus we have not only the solemn declarations of this party, that it regards slavery as a crime, but that it holds that Congress has the power and that it is its imperative duty to exercise that power for its extirpation as such, at least in the common territories.
                </p>
                <p className="p-in-sp">
                  Passing without comment the shameful disregard of constitutional compacts, the wanton outrage upon, the rights of the South, involved in this proposition, I come to enquire whether we have any assurance that the Republican party, when it shall have excluded slavery from the common territories—when it shall have consecrated them all entire to freedom—will it consider its duty done and
                  its mission ended? Sir, we have none ; on the contrary, there is but too much reason to apprehend that, emboldened by its already many triumphs over the rights of the South and the Constitution of the country, it will go on in its career of folly, of madness, and crime, until in the practical enforcement of the doctrine of the "higher law," it strikes a direct and mighty blow at the
                  institution of slavery within the States. A <PageNumber num={355} /> distinguished representative man of the party, the chosen premier of the incoming administration, has told us from his place in the Senate chamber, in substance, that it is an error to suppose that slavery had any constitutional guarantees which may not be released and ought not to be
                  relinquished. He has proclaimed that "the interests of the white race demand the ultimate emancipation of all men—that, whether that consummation shall be allowed to take effect, with needful and wise precautions against sudden change and disaster, or be hurried on by violence, is all that remains for you to decide." In the late Presidential canvass he exclaimed, "What a commentary
                  upon the history of man is the fact that eighteen years after the death of John Quincy Adams, the people have for their standard bearer Abraham Lincoln, confessing the obligations of the higher law, which the sage of Quincy proclaimed, and contending for weal or for woe, for life or death, in the irrepressible conflict between freedom and slavery. I desire only to say that we are in
                  the last stage of the conflict before the great triumphant inauguration of this policy into the Government of the United States."
                </p>
                <p className="p-in-sp">
                  The President elect has proclaimed that the great doctrine of the Declaration of Independence is without limitation—that it is applicable alike, to the black and to the white man. He, too, has proclaimed the doctrine of the "irrepressible conflict between freedom and slavery." He said, two years ago, that there would be no cessation of the agitation of the slavery question until the
                  North saw that a policy was inaugurated, which would place slavery "where the public mind will rest in the belief that it is in the course of ultimate extinction." He has said, that if in Congress, and a vote should come up on a question whether slavery should be prohibited in a new territory, he would vote that it should in spite of the Dred Scott decision. Thus, we have the
                  unmistakable evidence of the great expounder of the creed of the Republican Party—of the head and the chosen premier of the incoming Administration, that its great and ultimate purpose, is the extermination of slavery throughout the Southern States ; that in the accomplishment of this object it is prepared to tread beneath its unhallowed feet alike the compacts of the Constitution
                  and the decisions of the highest tribunal known to the country; and through its chosen champions it presents to us the alternative of humiliating and dastardly surrender of our rights, or the maintenance of them by the strong arm. Surely no son of the South but will say
                </p>
                <p className="p-in-sp">
                  "The last alternative befits me best." <PageNumber num={356} /> If other proof of the character and purpose of this party be wanting, it may be found in the endorsation by sixty-eight Republican members of Congress of the Helper pamphlet--a compendium of abolition teachings in their most offensive form a work in the highest degree incendiary and
                  dangerous, inculcating not only the abolition of slavery in the Southern States, but the confiscation of the property of the Southern master to compensate his slaves for past services. With the avowal of these atrocious and infamous doctrines, the Republican party have invoked the verdict of the people of the Northern States. That verdict has been solemnly rendered at the ballot box;
                  and by that verdict, that party, pledged to the accomplishment of a single object, and that object, the extermination of African slavery, has been elevated to place and power. It becomes then, sir, a great question for us of the South, what safe guards we can throw around us. What barriers we can interpose to the onward march of this aggressive and powerful party. Shall we hold up
                  before us the broad shield of the Constitution? Alas, sir, we are told that there is a law higher than the Constitution. Shall we look for protection to the halls of the National Legislature? We are in a fearful minority in both branches. Can we hold a Republican administration in check, by holding up to view the stern retribution of an outraged and indignant constituency? No—we of
                  the South are not regarded as practically a part of the constituent body. The Republican party was elevated to power by a sectional vote against the earnest remonstrance and the warning voice of the whole Southern people. The only responsibility it acknowledges, is to those whose hands have been long uplifted against the Constitution of the country, and the peace and safety of
                  Southern society to those who demand that it go forward until it shall have crushed out and extirpated the crime and the institution of slavery, everywhere throughout the land.
                </p>
                <p className="p-in-sp">
                  In view, sir, of these things, seeing that the safe-guards of the Constitution have, one by one, gone down before the onward march of the Republican cohorts, some of the Southern States, claiming to be the sole judges of the remedies for apprehended danger, have resumed the powers delegated by them to the General Government in its formation, and assumed independent attitudes before
                  the world. South Carolina, Georgia, Alabama, Florida and Mississippi are no longer members of the once glorious brotherhood of American States. Their lights have paled and gone down in the constellation of the three and thirty stars which, a little while since, proclaimed the union and the power of three and thirty States. It may be that they will move for a
                  <PageNumber num={357} /> time amid jarring elements, and it may be a long, a bloody track. But, sir, there is "a Promethean heat that shall their light relume." They are destined yet to give a brighter light in another sphere. I know, sir, that Northern Governors and Legislatures are pledging their power to aid a Republican Administration in the
                  subjugation of these States and all that may follow their example; to aid in coercing them into submission to a power that is to be wielded but for the destruction of Southern Society; a power that would emancipate the slave of the South even by the strong arm ; and, having done this, would compel the master to meet him as a social and political equal, or to become an exile forever
                  from the land of his birth and the heritage of his fathers; a power that, for the accomplishment of this object, would re-enact, if needs be, upon Southern soil, the bloody tragedy of St. Domingo. But, sir, I have a faith as fixed and abiding as that I repose in God, that a people battling for their rights of property, for life, for honor, for the sanctity of home and fire-side must
                  and will triumph. But be the result what it may, cost what it may, these gallant States will tread the path of honor. And now, sir, in this great extremity of the country, in this time of a common peril to the South, it is for the mighty Commonwealth, whose representatives we are, to determine what part she will act. Shall we, then, seek an ignoble and short-lived security beneath
                  the banner of the Republican party? Shall we look silently on, while a Republican Administration shall strike down, perchance, the power of State after State in the South until military power shall over-throw the great doctrine of State Rights and State Sovereignty, which we have ever been foremost to maintain and defend? or shall we take our place beside our Southern sisters, and if
                  need be, amid the embattled Southern host, where every Southern arm shall strike for all that is sacred to the great Southern heart?
                </p>
                <p className="p-in-sp">
                  Sir, there is for Virginia no other path of safety, and honor. The people of the South are "bone of our bone and flesh of our flesh." Thousands of them are the descendants of men who were reared upon Virginia soil. Thousands of them went themselves from amongst us. They have sat with us at the same social board, and knelt with us around the same family altars. They are bound to us by
                  a common sentiment, by common institutions, especially are we bound together in this hour of a common peril. Their destiny must and will ultimately be our destiny. In a union with them alone can we find security. Should we be seduced into any other alliance it will be unnatural, false and hollow, and of short duration. If fifteen slave States could not maintain the constitutional
                  rights before the aggressive spirit of <PageNumber num={358} /> the Republican party, what reason is there for hope that half or perhaps a third or fourth of the slave States can maintain them? But there are those who still think that a portion of the Southern States may obtain from the anti-slavery power concessions and guarantees that it denied to the
                  whole. Upon what foundation rests such expectation may be seen by reference to the action of the Republican members of Congress in both Houses. The Republicans on the committees refused to acknowledge the right of property in slaves.
                </p>
                <p className="p-in-sp">
                  In the House of Representatives a resolution, giving a pledge to sustain the President in a resort to force against the Southern States, was adopted by a large majority. In the Senate Clarke's resolutions, offered as a substitute for the Crittenden proposition, received the vote of every Republican—those resolutions declaring that no additional guarantees or amendments to the
                  Constitution were necessary, that the remedy for existing danger was the enforcement of the existing laws. A force bill has now passed to a second reading in the House of Representatives, upon a test vote—and if anything more were wanting to dispel the illusion, it may be found in the plan of adjustment proposed by the Peace Conference. That we may the better understand the effect of
                  this proposition upon the rights of the South, I propose briefly to refer to the state of things existing prior to the inauguration of this Conference. Under the Dred Scott decision, the slave went under the guardianship and protection of the Constitution of the United States into the common territories. The anti-slavery party repudiated that decision. In some of the Territories the
                  question was submitted to the arbitrament of Sharpe's rifles and bowie knives.
                </p>
                <p className="p-in-sp">
                  In the midst of a revolution, growing out of this and other agitating questions, a Peace Conference was convened at Washington, for the purpose of adopting a basis of adjustment that would restore peace to the country, now and forever; that would settle clearly, explicitly, fully, finally and beyond dispute, the vexed sectional questions which threatened to involve the country in a
                  common ruin. I come now, sir, to enquire whether the proposition of the Peace Conference, in any
                </p>
                <p className="p-in-sp">
                  sense, meets the just expectations of the South? The first section provides that "in all the territory north of the parallel of thirty-six degrees and thirty minutes of north latitude, involuntary servitude, except for crime, is prohibited." Here all is explicit and full. There is nothing left upon which to found a quibble. But when it comes to define the rights of the South, south
                  of that line, we find that all is vague and obscure. "In all the present territory south of that line, the status of persons held to service or labor, as it now exists, shall not be changed ; <PageNumber num={359} /> nor shall any law be passed by Congress or the Territorial Legislature, to hinder or prevent the taking of such persons from any of the
                  States of this Union to said Territory, nor to impair the rights arising from the said relation ; but the same shall be subject to political cognizance in the Federal Courts, according to the course of the common law." In the first place, then, what is the status of those "held to involuntary servitude?" The platform of the Republican party declares it to be that of freedom.
                </p>
                <p className="p-in-sp">
                  Here then is a question at once, for a Federal Government, composed of Republican Judges, appointed by a Republican President, to decide ; what the decision of that question by such a Court would be, we of the South well know. But, admitting that no question could arise with regard to the status of persons held to service South of the line, we are to look for the enforcement of all
                  our rights, as slave-holders, to the common law administered by the Federal Courts; and in answer to any appeal to such Courts for the enforcement of our rights, we would be told by Republican Judges that the common law did not recognize the right of property in man, and that as a consequence we had no right to be enforced. Thus, by the proposition of the Peace Conference, we are
                  excluded from all the territory North of thirty-six thirty, and South of that line our rights are to be at the mercy of the Federal Courts, whereas, prior to the Peace Conference we had a right, under the Dred Scott decision, to go with our slaves into the whole of the common territories. In addition to this, the acquisition of any territory South of the line is utterly excluded by
                  the provision of the 2d section. The 5th section provides that "it shall be the duty of Congress to pass laws to prohibit the importation of slaves into the United States, from places beyond the limits thereof." In the event, then, that Virginia remains a member of the present confederacy, and the recognition of the government of the seceded States,<span className="note" id="Note46"
                  ><span className="noteLabel">3</span>The text should perhaps read "In the event . . . of the recognition . . ."</span
                  >
                  that government will be to us a foreign government; and under this 5th section it will be the duty of Congress to prohibit the bringing of a slave from one of the States of that government into the State of Virginia. A citizen of Virginia may settle with his slaves in one of those States, become tired of the country, and desirous of returning; yet this poor boon will be denied to
                  him.
                </p>
                <p className="p-in-sp">
                  Under the 7th section, Congress is required to provide for the payment to the owner, by the United States government, of the value of his fugitive slave in any case where his recapture is prevented by violence or intimidation from mobs or riotous assemblages. Under this section, the Southern master is to be robbed of his slave by Northern
                  <PageNumber num={360} /> mobs, and his Southern brethren are to be taxed to pay for him. Surely this is a mode of emancipation that must commend itself to the Abolitionists and kidnappers along our borders. And this is the concession—this the olive branch tendered to a people smarting under wrong and injury. A just demand of the South in the settlement
                  of the agitating questions of the times was to have accorded to her citizens with their slaves, a right to pass through non-slaveholding territory. This right is expressly denied by the Franklin proposition. The feature of this proposition, boding the greatest mischief to the South, is the provision which requires that "Congress shall provide by law for securing to the citizens of
                  each State all the privileges and immunities of the several States." Under this provision, Congress is required to give to a free negro from any of the Northern States, in which he is recognized as a citizen, the rights and privileges, upon the soil of Virginia, to which a free white citizen of the State is entitled. Thus we have a door thrown open for the interference of the Federal
                  government in the affairs of the State government, and for the introduction, under the protection of the Federal government, of the free negroes of the North into the Southern States to incite our slaves to rebellion and insurrection.
                </p>
                <p className="p-in-sp">
                  In the name of the people who sent me here, I repudiate this plan of adjustment. I denounce it as an attempt to cheat, to swindle, and to defraud the South. With these evidences of the temper and disposition of our assailants, it is vain to hope for conciliation and adjustment. We must either submit to their unrighteous demands, or prepare to meet aggression by resistance. Sir, the
                  people, whom I have the honor to represent on this floor, have cherished a feeling of deep devotion to the Union. They have been among those who thought there must be madness in the brain, or treason in the heart of him who would rend its bonds asunder. They had fondly hoped, that giving peace, prosperity and happiness to the whole country, it would go down unimpaired to the "last
                  syllable of recorded time."
                </p>
                <p className="p-in-sp">
                  So long as the Constitution, the great heart and only bond of the Union, was of power to accomplish the object, for which the Union was formed, they were prepared to stand around it with those who stood fast in its defence, and, while they had an arm, to strike in its defence. But, sir, they have seen the vitality and power of that instrument crushed out; they have seen a party
                  elevated to power proclaiming that there is a law higher than the Constitution; they know that the power of the Government, perverted from its legitimate ends, is to be wielded for their destruction; and now, sir, they are <PageNumber num={361} /> animated but by one sentiment, and that is that there should be a separation of these States ; that this
                  Union should perish ; that unlike the fabled Phoenix, it rises from its ashes never more. They desire that Virginia should now resume the power delegated by her to the general government in its formation, and that, having done this, she shall in this great struggle take her place, come weal come woe, beside her sister States of the South. And the only boon they would ask for her in
                  severing her connection with a broken and dishonored confederacy, is that she may be allowed to depart in peace. But if not, if war must come, let it come, and may God defend the right! There are those who deny the right of a State to withdraw peaceably from the Union. We who hold the opposite doctrine maintain that this Government is a voluntary association of States; that the
                  States having, in the exercise of sovereign power, become parties to the compact, may, in the exercise of the same power, withdraw from it, whenever, they being the judge, believe that compact is broken. Virginia, at least, reserved to herself the right in delegating power to the general Government. But be this as it may—be secession a Constitutional right or not, we have the right,
                  which underlies the declaration of our independence. The right of a people, in the last resort, to resist oppression and wrong come whence they may.
                </p>
                <p className="p-in-sp">
                  In conclusion, sir, if this Government, the noblest fabric ever reared for the worship of human liberty, must go down in a fratricidal conflict, we of the South, appealing to history, may before the world, charge, without the fear of contradiction, that the responsibility rests upon our Northern brethren—that it is the result of a wanton repudiation by them, of the covenants of the
                  Constitution. And now, sir, come what may, may the God of battles be our shield and defence!
                </p>
                <p>
                  <span className="persName">Mr. WILLEY</span> then obtained the floor, but gave way to <span className="persName">Mr. TREDWAY</span> to make a motion to adjourn. <span className="persName">Mr. T</span>., however, withdrew his motion at the request of <span className="persName">Mr. GOODE</span> of Mecklenburg.<span className="note" id="Note47"
                  ><span className="noteLabel">4</span>A correction (March 5) notes that it was Mr. Goode of Bedford, and not Mr. Goode of Mecklenburg, who offered a resolution recommending the passage of an ordinance of secession.</span
                  >
                </p>
                <div className="speaker" id="sp410"><span className="persName">Mr. GOODE</span>—</div>
                <p className="p-in-sp">
                  I desire, before we adjourn, to offer a resolution which I hold in my hand, with a view of having it sent to the Committee on Federal Relations—not that I presume, sir, to enlighten by any suggestions of my own the worthy gentlemen who represent this Convention upon that Committee. But, Mr. President, I am to-day oppressed; my spirits are weighed down by a recollection of the fact,
                  that, before we assemble in this Hall again, a Black Republican will have been inaugurated as our President. Poor old Virginia, when the representatives of her sovereignty shall again assemble in Council, will have passed under the yoke of Northern abolition and bended her <PageNumber num={362} /> proud knee to the triumphal car of the Black
                  Republicans. In justice to myself and to the proud people whom I represent, I desire that, upon this very day, at this very stage of our proceedings, this resolution shall be spread upon the record :
                </p>
                <p className="p-in-sp">
                  Whereas, the people of Virginia, in Convention assembled, did declare and make known, when they assented to and ratified the Constitution of the U. States on the 25th day of June, 1788, that the powers granted under the said Constitution might be resumed, whenever the same should be perverted to their injury or oppression; and, whereas the said powers have been perverted to the
                  injury and oppression of the people of Virginia; and, whereas the very moderate and reasonable demands known as the Crittenden propositions with certain essential modifications, which were presented by the General Assembly of this Commonwealth as a final effort to restore the integrity of the Union have been deliberately rejected by our Northern confederates.
                </p>
                <p>Resolved, That every consideration of duty, interest, honor and patriotism requires that an ordinance should now be adopted by this Convention and submitted to the people for ratification by which Virginia shall resume all the powers delegated by her to the Federal Government and declare her connexion with that Government dissolved.</p>
                <p>They were referred to the Committee on Federal Relations.</p>
                <div className="speaker" id="sp411"><span className="persName">Mr. FISHER</span>—</div>
                <p className="p-in-sp">
                  I intended, sir, during the whole week to offer these resolutions, and to have accompanied them with some remarks. But as I see that it is impossible for me to do so, and being anxious that they should go to the Convention before the fourth of March, I shall content myself with simply offering them, and asking that they be referred to the Committee on Federal Relations.
                </p>
                <p>The resolutions were then read as follows :</p>
                <p>
                  "Resolved, That in the opinion of this Convention any attempt on the part of the Federal Government to collect revenue on goods in transit to any port or ports in any of the States which have withdrawn from the Confederacy of the United States of America, or any attempt to take the forts, arsenals, dockyards or munitions of war in possession of any of the said States that have
                  withdrawn from the Federal Union, would be the initiation of civil war; and that this Commonwealth will not be an indifferent spectator in such war, but will take part in the same to the fullest extent of her military ability in behalf of her Southern slaveholding sisters that have seceded from the Federal Union. <PageNumber num={363} /> "Resolved,
                  further, in the opinion of this Convention, that it is the duty of the Federal Government, at the earliest practicable moment, to enter into negotiations with the authorities of the Southern Confederacy for the transfer of Fort Sumter and Fort Pickens to said Confederacy, and for an equitable division of the public property and public burdens of the United States of America, at the
                  time of the withdrawal of the States of the said Southern Confederacy from the Union, between them."
                </p>
                <p>Then, on motion of <span className="persName">Mr. TREDWAY</span>, the Convention adjourned until 12 o'clock, Monday.</p>
              </div>
            </div>
            <div className="day" id="vsc1965.v1.2.16">
              <PageNumber num={364} />
              <h2><span className="headingNumber">1.16. </span><span className="head">SIXTEENTH DAY</span></h2>
              <div className="dateline">Monday, <span className="date">March 4</span></div>
              <p>The Convention met at 12 o'clock.</p>
              <p>Prayer by the Rev. <span className="persName">Mr. READ</span>, of the Presbyterian Church.</p>
              <div className="section" id="vsc1965.v1.2.16.1">
                <h3><span className="headingNumber">1.16.1. </span><span className="head">THE PEACE CONFERENCE AND THE VIRGINIA UNITED STATES SENATORS</span></h3>
                <div className="speaker" id="sp412"><span className="persName">Mr. BROWN</span>, of Preston—</div>
                <p className="p-in-sp">Mr. President, will it be in order before taking up the unfinished business to offer a resolution :</p>
                <div className="speaker" id="sp413"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">It can be done by leave of the gentleman from Monongalia [<span className="persName">Mr. WILLEY</span>] .</p>
                <div className="speaker" id="sp414"><span className="persName">Mr. WILLEY</span>—</div>
                <p className="p-in-sp">I understand it will take but a short time, and I give way with pleasure.</p>
                <div className="speaker" id="sp415"><span className="persName">Mr. BROWN</span>—</div>
                <p className="p-in-sp">I offer the following resolution :</p>
                <p>
                  Resolved, That the Hon. ROBERT M. T. HUNTER and the Hon. JAMES M. MASON, failed to reflect the opinions and wishes of the people of Virginia in their recent opposition in the Senate of the United States to a reference of the report of the Peace Conference to the consideration of the States of this Confederacy with a view of having the amendments proposed in the said report adopted as
                  part of the Constitution of the United States.
                </p>
                <div className="speaker" id="sp416"><span className="persName">Mr. BROWN</span>—</div>
                <p className="p-in-sp">
                  I intended, sir, to press the passage of that resolution. I desire, sir, that the country shall know that I, at least—and, I think, this Convention—differ with our Senators in the Congress of the United States; but I see so many empty seats here this morning, and principally seats occupied by gentlemen who may differ from me on this subject, and as I do not desire to take advantage
                  of an opponent, I shall move to lay the resolution on the table for the present, with a view to take it up at an early day.
                </p>
                <p>The motion was agreed to.</p>
              </div>
              <div className="section" id="vsc1965.v1.2.16.2">
                <h3><span className="headingNumber">1.16.2. </span><span className="head">FEDERAL RELATIONS</span></h3>
                <div className="speaker" id="sp417"><span className="persName">Mr. CHAMBLISS</span>—</div>
                <p className="p-in-sp">
                  Mr. President, I hold in my hand a preamble and resolutions, which I propose offering to the Convention. It is proper that I should say that the preamble is the production of that enlightened and venerable jurist, J. Allen, approved and adopted by the people of Botetourt, in one of their primary meetings. Predicated upon that, Mr. President, I have added three resolutions. I do not
                  propose to detain the Convention with having the preamble read, because I take it for granted that every gentleman on this floor has read that clear and condensed exposition of the part which Virginia <PageNumber num={365} /> took in the formation of the Federal compact, of the rights which she claims for herself, and the wrongs which she has suffered
                  at the hands of our Northern friends. I propose, Mr. President, that the Secretary shall read that portion of this resolution which is in manuscript ; and I desire, with the permission of my friend from Monongalia, to offer a few remarks.
                </p>
                <p className="p-in-sp">The following are the preamble and resolutions :</p>
                <p className="p-in-sp">
                  "The people of Botetourt county in general meeting assembled, believe it to be the duty of all the citizens of the Commonwealth, in the present alarming condition of our country, to give some expression of opinion upon the threatening aspect of public affairs. They deem it unnecessary and out of place to avow sentiments of loyalty to the Constitution and devotion to the Union of
                  these States. A brief reference to the part the State has acted in the past will furnish the best evidence of the feelings of her sons in regard to the union of the States and the Constitution, which is the sole bond which binds them together.
                </p>
                <p className="p-in-sp">"In the controversies with the mother country, growing out of the efforts of the latter, to tax the colonies without their consent, it was Virginia, who by the resolutions against the Stamp Act, gave the example of the first authoritative resistance by a legislative body to the British Government, and so imparted the first impulse to the Revolution.</p>
                <p className="p-in-sp">"Virginia declared her independence before any of the colonies. and gave the first written Constitution to mankind.</p>
                <p className="p-in-sp">"By her instructions her Representatives in the General Congress introduced a resolution to declare the colonies independent States. and the Declaration itself was written by one of her sons.</p>
                <p className="p-in-sp">"She furnished to the Confederate States the Father of his country, under whose guidance Independence was achieved, and the rights and liberties of each State, it was hoped, perpetually established.</p>
                <p className="p-in-sp">"She stood undismayed through the long night of the Revolution, breasting the storm of war, and pouring out the blood of her sons like water on almost every battle-field, from the ramparts of Quebec to the sands of Georgia.</p>
                <p className="p-in-sp">"By her own unaided efforts the North-western territory was conquered, whereby the Mississippi, instead of the Ohio river was recognized as the boundary of the United States by the treaty of peace.</p>
                <p className="p-in-sp">
                  "To secure harmony, and as an evidence of her estimate of the value of the Union of the States, she ceded to all for their common benefit this magnificent region—an empire in itself. <PageNumber num={366} /> "When the Articles of Confederation were shown to be inadequate to secure peace and tranquility at home, and respect abroad, Virginia first moved
                  to bring about a more perfect Union.
                </p>
                <p className="p-in-sp">"At her instance the first assemblage of Commissioners took place at Annapolis, which ultimately led to the meeting of the Convention which formed the present Constitution.</p>
                <p className="p-in-sp">"This instrument itself was in a great measure the production of one of her sons, who has justly been styled the father of the Constitution.</p>
                <p className="p-in-sp">"The Government created by it was put into operation with her Washington, the father of his country at its head—her Jefferson, the author of the Declaration of Independence, in his cabinet—her Madison, the great advocate of the Constitution in the Legislative Hall.</p>
                <p className="p-in-sp">"Under the lead of Virginia statesmen the Revolution of 1798 was brought about, Louisiana was acquired, and the second War of Independence was waged.</p>
                <p className="p-in-sp">"Throughout the whole progress of the Republic she has never infringed on the rights of any State, or asked, or received an exclusive benefit.</p>
                <p className="p-in-sp">"On the contrary, she has been the first to vindicate the equality of all the States, the smallest as well as the greatest.</p>
                <p className="p-in-sp">
                  "But claiming no exclusive benefit for her efforts and sacrifices in the common cause, she had a right to look for feelings of fraternity and kindness for her citizens from the citizens of other States, an equality of rights for her citizens with all others; that those for whom she had done so much, would abstain from actual aggressions upon her soil, or, if they could not be
                  prevented, would show themselves ready and prompt in punishing the aggressors ; that the common government to the promotion of which she contributed so largely for the purpose of 'establishing justice and ensuring domestic tranquility,' would not, whilst the forms of the Constitution were observed, be so perverted in spirit as to inflict wrong and injustice and produce universal
                  insecurity.
                </p>
                <p className="p-in-sp">"These reasonable expectations have been grievously disappointed.</p>
                <p className="p-in-sp">
                  "Owing to a spirit of pharisaical fanaticism prevailing in the North in reference to the institution of slavery, incited by foreign emissaries and fostered by corrupt political demagogues in search of power and place, a feeling has been aroused between the people of the two sections of what was once a common country which of itself would almost preclude the administration of a united
                  government in harmony.
                </p>
                <p className="p-in-sp">"For the kindly feelings of a kindred people we find substituted distrust, suspicion and mutual aversion. <PageNumber num={367} /> "For a common pride in the name of America, we find one section even in foreign lands pursuing the other with revilings and reproach.</p>
                <p className="p-in-sp">"For the Religion of a Divine Redeemer of all, we find a religion of hate against a part.</p>
                <p className="p-in-sp">"And in all the private relations of life instead of fraternal regard a 'consuming hate' which has but seldom characterized warring nations.</p>
                <p className="p-in-sp">"This feeling has prompted a hostile incursion upon our own soil, and an apotheosis of the murderers who were justly condemned and executed.</p>
                <p className="p-in-sp">"It has shown itself in the legislative halls by the passage of laws to obstruct a law of Congress passed in pursuance of a plain provision of the Constitution.</p>
                <p className="p-in-sp">"It has been manifested by the industrious circulation of incendiary publications, sanctioned by leading men, occupying the highest station in the gift of the people to produce discord and division in our midst. and incite to midnight murder and every imaginable atrocity against an unoffending community.</p>
                <p className="p-in-sp">"It has displayed itself in a persistent denial of the equal rights of the citizens of each State to settle with their property in the common Territory acquired by the blood and treasure of all.</p>
                <p className="p-in-sp">
                  "It is shown in their openly avowed determination to circumscribe the institution of slavery within the Territory of the States now recognizing it, the inevitable effect of which would be to fill the present slaveholding States with an ever increasing negro population resulting in the banishment of our own non-slaveholding population in the first instance, and the eventual surrender
                  of our country to a barbarous race, or what seems to be desired an amalgamation with the African.
                </p>
                <p className="p-in-sp">
                  "And it has at last culminated in the election, by a sectional majority in the free States alone, to the first office in the Republic, of the author of the sentiment that there is an 'irrepressible conflict' between free and slave labor, and that there must be universal freedom or universal slavery; a sentiment which inculcates, as a necessity of our situation, warfare between the
                  two sections of our country, without cessation or intermission, until the weaker is reduced to subjection.
                </p>
                <p className="p-in-sp">
                  "In view of this state of things, we are not inclined to rebuke or censure the people of any of our sister States in the South, suffering from injury, goaded by insults, and threatened with such outrages and wrongs, for their bold determination to relieve themselves from such injustice and oppression by resorting to their ultimate and sovereign
                  <PageNumber num={368} /> right to dissolve the compact which they had formed, and to provide new guards for their future security.
                </p>
                <p className="p-in-sp">"Nor have we any doubt of the right of any State, there being no common umpire between coequal sovereign States, to judge for itself, of its own responsibility, as to the mode and measure of redress.</p>
                <p className="p-in-sp">"The States, each for itself, exercised this sovereign power when they dissolved their connection with the British Empire.</p>
                <p className="p-in-sp">"They exercised the same power when nine of the States seceded from the Confederation, and adopted the present Constitution, though two States at first rejected it.</p>
                <p className="p-in-sp">"The Articles of Confederation stipulated that those articles should be inviolably observed by every State, and that the Union should be perpetuated and that no alteration should be made unless agreed to by Congress and confirmed by every State.</p>
                <p className="p-in-sp">"Notwithstanding this solemn compact a portion of the States did, without the consent of the others, form a new compact; and there is nothing to show, or by which it can be shown, that this right has been or can be diminished so long as the States continue sovereign.</p>
                <p className="p-in-sp">"The confederation was assented to by the Legislature for each State.</p>
                <p className="p-in-sp">"The Constitution by the people of each State for such State alone.</p>
                <p className="p-in-sp">"One is as binding as the other and no more so.</p>
                <p className="p-in-sp">
                  "The Constitution it is true established a government and it operates directly on the individual; the confederation was a league operating primarily on the States. But each was adopted by the State for itself ; in the one case by the Legislature acting for the State; in the other 'by the people not as individuals composing one nation, but as composing the distinct and independent
                  States to which they respectively belong.'
                </p>
                <p className="p-in-sp">"The foundation, therefore, on which it was established was federal, and the State in the exercise of the same sovereign authority by which she ratified for herself may for herself abrogate and annul.</p>
                <p className="p-in-sp">"The operation of its powers whilst the State remains in the Confederacy is national; and consequently a State remaining in the Confederacy and enjoying its benefits, cannot by any mode of procedure withdraw its citizens from the obligation to obey the Constitution, and the laws passed in pursuance thereof.</p>
                <p className="p-in-sp">
                  "But when a State does secede, the Constitution and laws of the United States cease to operate therein. No power is conferred on Congress to enforce them. Such authority was denied to the Congress in the convention which framed the Constitution, because it would be an act of war of nation against nation—not the exercise of the legitimate
                  <PageNumber num={369} /> power of a government to enforce its laws on those subject to its jurisdiction.
                </p>
                <p className="p-in-sp">"The assumption of such a power would be the assertion of a prerogative claimed by the British Government to legislate for the Colonies in all cases whatever; it would constitute of itself a dangerous attack on the rights of the States, and should be promptly repelled.</p>
                <p className="p-in-sp">"These principles, resulting from the nature of our system of confederate States cannot admit of question in Virginia.</p>
                <p className="p-in-sp">"Our people in convention, by their act of ratification, declared and made known that the powers granted under the Constitution being derived from the people of the United States, may be resumed by them whenever they shall be perverted to their injuiry and op- pression.</p>
                <p className="p-in-sp">
                  "From what people were these powers derived? Confessedly from the people of each State acting for themselves. By whom were they to be resumed or taken back? By the people of the State who were then granting them away. Who were to determine whether the powers granted had been perverted to their injury or oppression? Not the whole people of the United States, for there could be no
                  oppression of the whole with their own consent; and it could not have entered into the conception of the convention, that the power granted could not be resumed until the oppressor himself united in such resumption.
                </p>
                <p className="p-in-sp">"They asserted the right to resume in order to guard the people of Virginia, for whom alone the convention could act against the oppression of an irresponsible and sectional majority, the worst form of oppression with which an angry Providence has ever afflicted humanity.</p>
                <p className="p-in-sp">"Whilst, therefore, we regret that any State should, in a matter of common grievance, have determined to act for herself without consulting with her sister States equally aggrieved, we are nevertheless constrained to say that the occasion justifies and loudly calls for action of some kind.</p>
                <p className="p-in-sp">
                  "The election of a President by a sectional majority as the representative of the principles referred to, clothed with the patronage and power incident to the office, including the authority to appoint all the post-masters and other officers charged with the execution of the laws of the United States, is itself a standing menace to the South—a direct assault upon her institutions—an
                  incentive to robbery and insurrection, requiring from our own immediate local government in its sovereign character, prompt action to obtain additional guarantees for equality and security in the Union, or to take measures for protection and security without it. <PageNumber num={370} /> "In view, therefore, of the present condition of our country, and
                  the causes of it, we declare, almost in the words of our fathers, contained in an address of the freeholders of Botetourt in February, 1775, to the delegates from Virginia to the Continental Congress, 'That we desire no change in our government whilst left to the free enjoyment of our equal privileges secured by the Constitution. But, that, should a wicked and tyrannical sectional
                  majority, under the sanction of the forms of the Constitution, persist in acts of injustice and violence towards us, they only must be answerable for the consequences.' That liberty is so strongly impressed on our hearts, that we cannot think of parting with it but with our lives ; that our duty to God, our country, ourselves, and our posterity, forbids it. We stand, therefore,
                  prepared for every emergency.'
                </p>
                <p className="p-in-sp">
                  "Resolved, therefore, That in view of the facts set out in the foregoing preamble, it is the opinion of this meeting that a convention of the people should be called forthwith; that the State, in its sovereign character, should consult with the other Southern States, and agree upon such guarantees as in their opinion will secure their equality, tranquility and rights within the
                  Union. And in the event of a failure to obtain such guarantees, to adopt in concert with the other Southern States, or alone, such measures as may seem most expedient to protect the rights and ensure the safety of the people of Virginia.
                </p>
                <p className="p-in-sp">"And in the event of a change in our relations to the other States being rendered necessary, that the Convention so elected should recommend to the people for their adoption, such alterations in our State Constitution as may adapt it to the altered condition of the State and the country."</p>
                <p className="p-in-sp">And the conference, lately held in Washington City, having failed to accomplish, to the satisfaction of this Convention, the object of its mission—Therefore, this Convention declares :</p>
                <p className="p-in-sp">1st, That the compromise agreed upon by the majority of the Commission at Washington, fails to give assurance of that equitable, satisfactory, just and final settlement of the slavery controversy which the slaveholding States have the right to demand.</p>
                <p className="p-in-sp">2nd, That the dignity and honor of Virginia forbid, that she should offer any other propositions for adjusting the pending difficulties between the North and the South.</p>
                <p className="p-in-sp">3rd, That the time has come, when Virginia should resume her sovereignty, withdraw from the Federal compact, and adopt in concert with the other Southern slave States, or alone, such measures as may <PageNumber num={371} /> seem most expedient to protect the rights and insure the safety of her citizens.</p>
                <div className="speaker" id="sp418"><span className="persName">Mr. CHAMBLISS</span>—</div>
                <p className="p-in-sp">Mr President: One of these resolutions says that this Peace Conference, which held in its hand so high and holy a mission, has failed; yea, sir, utterly failed to arrive at such a conclusion as shall give peace and satisfaction to a troubled and distracted continent.</p>
                <p className="p-in-sp">I will only allude to one single item in that Peace Conference adjustment, because I will not consume the time of my friend from Monongalia [<span className="persName">Mr. WILLEY</span>] , because of my physical inability to do justice to it.</p>
                <p className="p-in-sp">
                  There is a principle that underlies one of the resolutions of that Peace Conference which forbids that Virginia should submit to it. It may seem small and insignificant in itself ; but it underlies the foundation upon which all our institutions rest. That Peace Conference declares that a slaveholder of the Southern States shall not have a right to travel over a non-slaveholding State
                  with his property, while a Yankee, from the Green Mountains of Vermont, may trot his Morgan through the State of Virginia, live here and claim the protection of the laws of the land for his property. Is that equality? Is that justice, that I should be denied the privilege of taking my property whithersoever I desire, while these Northern men shall take their property wherever they
                  choose? Sir, it is an inequality to which Virginia will not submit. But should Virginia offer any further propositions of compromise to these men who have been trampling, are now and will continue to trample upon our most sacred rights and most vital institutions; and should we, sir, on bended knees, beg our oppressors and those who have aggrieved us to do justice—or should we stand
                  upholding our own institutions and our own rights, and defy any further aggressions? We have done nothing that a good citizen would not do. We have not invaded the rights of the Northern States, neither their soil nor their institutions. They are the aggressors, and upon their shoulders should rest the responsibility of all that has occurred. And yet, some gentlemen, probably, will
                  be willing to offer to these men additional opportunities to grant us rights that are already granted to us.
                </p>
                <p className="p-in-sp">For one, sir, there is no power on this earth, there is no connection, there is no state of circumstances that will induce me to go into a Convention of any description with the people of the non-slaveholding States upon this question.</p>
                <p className="p-in-sp">
                  But, sir, the patriot's heart sinks within him when he reflects that this great temple of human liberty is crumbling in the dust. I had <PageNumber num={372} /> hoped, and you had hoped, sir, that the proud eagle, that emblem of our greatness and our liberty, with one foot upon Plymouth Rock and the other stretching to Vancouver's island, and then with
                  his beak lapping in the waters of Cape Sable, slaking his thirst for awhile in the waters of the Rio Grande, looking into the future of the South-West, would have been permitted to pursue his onward progress, until he had embraced within the sphere of his supervision the adjacent countries whose union with us would seem to be an inevitable decree.
                </p>
                <p className="p-in-sp">
                  But, sir, Abolition fanaticism has perverted this destiny, and that proud eagle is driven from his high and elevated position to seek some more congenial spot where he may hereafter repose in peace. And where is that, sir? It is in that noble temple that is now being built up in the Southern States. There, sir, he will find his future dwelling, and, as I say in that resolution,
                  Virginia should step forth to-day, and form one of the columns of that beautiful temple which is intended for the future dwelling of freemen's sons.
                </p>
                <p className="p-in-sp">
                  Now, Mr. President, where will Virginia go is a settled question. Her interests, her associations, her sympathies all say where, but when? that is the question. When will she go? If I had my way at this 4th day of March, 1861, at this very hour of 12 o'clock, while Abraham Lincoln is standing upon the Eastern portico of the Federal Capitol swearing to maintain and defend the already
                  violated Constitution of the United States—whilst he is taking the oath of office from the venerable Chief Justice of the United States—I would have you, sir, standing on the West portico of the Virginia Capitol, swearing by her ancient fame that his sceptre should never extend over the Old Dominion. I would, at this hour, if I had my way, have you there, sir, proclaiming that
                  Virginia is no longer a member of this Confederacy.
                </p>
                <p className="p-in-sp">Mr. President, I don't intend to make a speech—I did not rise to make one. I fear that I am already trespassing upon the indulgent courtesy of my friend from Monongalia [<span className="persName">Mr. WILLEY</span>] . I will say to him, in conclusion, that I hope he will be as sage in counsel as I know him to be able and eloquent in debate. I move to refer the preamble and resolutions to the Committee on Federal Relations.</p>
                <div className="speaker" id="sp419"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">It is so ordered.</p>
                <div className="speaker" id="sp420"><span className="persName">Mr. CARLILE</span>—</div>
                <p className="p-in-sp">I trust the gentleman will withdraw his motion for a moment to enable me to make a few remarks.</p>
                <div className="speaker" id="sp421"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">The subject is already referred under the rules, and debate is not now in order.</p>
                <div className="speaker" id="sp422"><span className="persName">Mr. CARLILE</span>—</div>
                <p className="p-in-sp">I supposed that the practice was, when a resolution <PageNumber num={373} /> intended for reference to the Committee on Federal Relations was offered, to discuss it.</p>
                <div className="speaker" id="sp423"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">Only before it is referred. These resolutions have been referred, and debate upon them is therefore out of order.</p>
                <div className="speaker" id="sp424"><span className="persName">Mr. CARLILE</span>—</div>
                <p className="p-in-sp">
                  I did not know that the resolutions were referred. If I mistake not, sir, debate has been permitted heretofore upon resolutions of this character. I desire to offer a few remarks in reply to the gentleman who has just taken his seat. If the resolutions were ordered to be referred, the announcement of the reference was not heard in this part of the hall. It seems to me that I had
                  risen to reply before any order of reference was made.
                </p>
                <div className="speaker" id="sp425"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">The order of reference was distinctly made before the gentleman arose. The chair decides that debate is not in order.</p>
                <div className="speaker" id="sp426"><span className="persName">Mr. CARLILE</span>—</div>
                <p className="p-in-sp">With the indulgence of the Convention, I will make one remark.</p>
                <p className="p-in-sp">It is perhaps exceedingly fortunate for the gentleman from Greenesville [<span className="persName">Mr. CHAMBLISS</span>] 
                  , for the people of the State, and for all posterity, that he has not his way to-day. This firing process has been going on now for months and months. It has failed to effect the purposes of those who, like my friend, would secede by the hour of twelve from this Union; and notwithstanding its failure in Virginia, notwithstanding the thunder tones of a majority of 60,000 of our
                  people, still this firing process is to be resorted to day after day in this Convention, to effect, if possible the purposes of gentlemen who seem to have a perfect contempt for the will of the people. Sir, this movement, from its inception to the present hour, had its origin, and has been carried on in contempt of the people. In what State, that has passed an ordinance seceding from
                  the Union, have the people spoken or been heard? Wherever the people have been allowed to speak upon the soil of our native Virginia—upon that of her daughter Kentucky —upon that of gallant Tennessee, they have spoken in tones not to be misunderstood of their indisposition to be dragged into this movement to destroy their own fair free Government. And the time has come, in my humble
                  opinion, Mr. President, when this firing process should be met and promptly met, and while I have a voice to raise, or an arm to lift, if no one else will meet it, humble as I am, I will attempt it.
                </p>
                <p className="p-in-sp">
                  Why are we here, Mr. President? Why this excitement? Why this paralysis resting upon all the industrial interests of our common country? What has occurred, what exists that has not existed for years, save and except alone the defeat of Breckinridge and the <PageNumber num={374} /> election of Lincoln? It may be that those alone who have been feeding
                  upon the green pastures and lying down by the still waters of official patronage ; that those who have been clothed in purple and fine linen, and have fared sumptuously every day, may shortly have to retire. It is, in my humble opinion, with all due deference to others, this retiring process that has caused so much of the undue excitement in this country. The great body of the
                  people, who have no other interest than that of perpetuating the liberties achieved for them by their fathers, have no interest in this official patronage ; but they have an interest in the perpetuity of the government, and the maintenance of their liberties, and they will preserve the one and maintain the other.
                </p>
                <p className="p-in-sp">
                  And here, sir, in hot haste before even Virginia's Commissioners to this Peace Conference have been enabled to report to this body, as is provided by the law organizing that conference passed by your own State Legislature, gentlemen get up and by resolution condemn the action of the Conference before it has been officially presented to the body. Sir, I think this hot haste is, to say
                  the least, not treating with the usual courtesy these distinguished gentlemen who represented you in this Peace Conference. It looks like endeavoring to prejudge their action and prejudice the public mind against it before it has been officially and properly laid before the people. I will take this occasion to say that the propositions, recommended by that Peace Conference, commend
                  themselves to me, and if I am not very much mistaken, they will commend themselves to the people of Virginia. I will say to the secessionists that when you called for a Convention in the hope of being out of the Union before the 4th of March, you got what you did not bargain for, and if you call another Convention you will get it worse still.
                </p>
                <div className="speaker" id="sp427"><span className="persName">Mr. LEAKE</span>—</div>
                <p className="p-in-sp">I rise to know what is the question before the House?</p>
                <div className="speaker" id="sp428"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">The gentleman is out of order.</p>
                <div className="speaker" id="sp429"><span className="persName">Mr. CARLILE</span>—</div>
                <p className="p-in-sp">I will take my seat; but I intend to refer to this matter again.</p>
                <div className="speaker" id="sp430"><span className="persName">Mr. LEAKE</span>—</div>
                <p className="p-in-sp">I object to this course of proceeding.</p>
                <div className="speaker" id="sp431"><span className="persName">Mr. CARLILE</span>—</div>
                <p className="p-in-sp">I do not intend to proceed now, but I will give more of that sort another time.</p>
                <div className="speaker" id="sp432"><span className="persName">Mr. LEAKE </span>—</div>
                <p className="p-in-sp">You shall be met, I assure you.</p>
                <div className="speaker" id="sp433"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">The gentleman [<span className="persName">Mr. CARLILE</span>] , cannot occupy the floor unless by general consent.</p>
                <div className="speaker" id="sp434"><span className="persName">Mr. CARLILE</span>—</div>
                <p className="p-in-sp">I am done, sir, for the present.</p>
                <PageNumber num={375} />
                <p>BORDER STATE CONVENTION</p>
                <p><span className="persName">Mr. J. B. MALLORY</span>, offered the following resolution which was referred to the Committee on Federal Relations :</p>
                <p>Resolved, That the States of Virginia, North Carolina, Tennessee, Kentucky, Missouri, Maryland and Delaware, ought to meet in Convention, with a view to concerted and united action, to determine where they will, go, whether with the North or the South, or whether they will establish a Central Confederacy.</p>
                <div className="speaker" id="sp435"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">In pursuance of a resolution offered some days ago, to solicit, for publication, copies of the addresses delivered before the Convention by the Commissioners from South Carolina, Georgia and Mississippi, I beg leave to submit these addresses for the action of the Convention.</p>
                <div className="speaker" id="sp436"><span className="persName">Mr. GOODE</span>, of Bedford—</div>
                <p className="p-in-sp">In connection with this subject, I desire to offer the following resolution :</p>
                <p>Resolved, That ten thousand copies of the addresses delivered before this body by the Hon. Fulton Anderson, Commissioner from Mississippi; the Hon. Henry L. Benning, Commissioner from Georgia; and the Hon. John S. Preston, Commissioner from South Carolina, be printed and distributed equally among the members of this Convention, for circulation among the people of this State.</p>
                <div className="speaker" id="sp437"><span className="persName">Mr. HUBBARD</span>—</div>
                <p className="p-in-sp">I move to lay that resolution on the table.</p>
                <div className="speaker" id="sp438"><span className="persName">Mr. GOODE</span>, of Bedford—</div>
                <p className="p-in-sp">Will the gentleman withdraw his motion for a moment?</p>
                <div className="speaker" id="sp439"><span className="persName">Mr. HUBBARD</span>—</div>
                <p className="p-in-sp">No, sir.</p>
                <div className="speaker" id="sp440"><span className="persName">Mr. GOODE</span>—</div>
                <p className="p-in-sp">Then I call for the yeas and nays upon that motion.</p>
                <p>The call was sustained.</p>
                <div className="speaker" id="sp441"><span className="persName">Mr. EARLY</span>—</div>
                <p className="p-in-sp">Will the gentleman from Bedford allow me to make an appeal to him to withdraw his resolution? The gentleman from Monongalia [<span className="persName">Mr. WILLEY</span>] has been prevented from proceeding with his remarks, and I would ask the gentleman to withdraw his resolution to enable him to proceed. It can be offered again.</p>
                <p>The question was then taken on the motion to lay on the table; and decided in the affirmative—yeas 65, nays 42, as follows :</p>
                <p>AYES-Messrs. John Janney [President] 
                  , Aston, Baldwin, Alfred M. Barbour, Baylor, Berlin, Boggess, Branch, Brown, Burdett, Burley, Caperton, Carlile, Carter, Coffman, C. B. Conrad, Couch, Custis, Dent, Deskins, Dorman, Early, Fugate, Gillespie, Gravely, Gray, Addison Hall, Ephraim B. Hall, Hoge, Hubbard, Hughes, Hull, Jackson, Kilby, Lewis, McComas, McGrew, James B. Mallory, Marshall, Maslin, Masters, Moffett, MOORE,
                  Nelson, Osburn, Patrick, Pendleton, Porter, Price, Pugh, Rives, Saunders, Sr., Sharp, Sitlington, Spurlock, Alexander <PageNumber num={376} /> H. H. Stuart, Chapman J. Stuart, Summers, Tarr, Tayloe, Tredway, White, Willey, Wilson, Wysor-65.
                </p>
                <p>
                  NOES-Messrs. Ambler, Armstrong, Boisseau, Borst, Bouldin, Campbell, Chambliss, Conn, Richard H. Cox, Echols, Forbes, French, Graham, Grant, Goggin, John Goode, Jr., Thomas F. Goode, Cyrus Hall, Harvie, Haymond, Hunton, Isbell, Marmaduke Johnson, Kent, Kindred, Lawson, Leake, McNeil, Marr, Marye, Sr., Morris, Morton, Neblett, Randolph, Richardson, Sheffey, Speed, Staples, Thornton,
                  Franklin P. Turner, Wickham, WOODS-42.
                </p>
                <p>So the motion to lay on the table was agreed to.</p>
              </div>
              <div className="section" id="vsc1965.v1.2.16.3">
                <h3><span className="headingNumber">1.16.3. </span><span className="head">EXPLANATION OF VOTES</span></h3>
                <div className="speaker" id="sp442"><span className="persName">Mr. BRANCH</span>, when his name was called upon the vote just taken, said—</div>
                <p className="p-in-sp">these speeches have been published in all the papers of the city, and every reading man in the Commonwealth has no doubt read them. We have no money to throw away and hence I vote aye.</p>
                <div className="speaker" id="sp443"><span className="persName">Mr. NELSON</span> when his name was called said—</div>
                <p className="p-in-sp">If I mistake not, copies of those speeches were requested for publication by this body. However, inasmuch as I consider the number proposed to be published too high, I will vote aye. I think a smaller number would do.</p>
                <div className="speaker" id="sp444"><span className="persName">Mr. TAYLOE</span>—</div>
                <p className="p-in-sp">For the reason assigned by the gentleman from Petersburg [<span className="persName">Mr. BRANCH</span>] , I vote yea.</p>
                <div className="speaker" id="sp445"><span className="persName">Mr. WICKHAM</span> stated when his name was called—</div>
                <p className="p-in-sp">
                  I desire to say that it is due as a matter of courtesy to those gentlemen who have been called upon to furnish their speeches for publication, that some number of copies should be published. I am opposed to the publication of so large a number as the resolution proposes, and if it were in order now, I would move to have the number diminished. That, however, not being in order, I will
                  vote no.
                </p>
              </div>
              <div className="section" id="vsc1965.v1.2.16.4">
                <h3><span className="headingNumber">1.16.4. </span><span className="head">THE UNFINISHED BUSINESS</span></h3>
                <div className="speaker" id="sp446"><span className="persName">Mr. DORMAN</span>—</div>
                <p className="p-in-sp">I move to take up the unfinished business.</p>
                <div className="speaker" id="sp447"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">It requires no motion; it comes up as a matter of course. The gentleman from Monongalia [<span className="persName">Mr. WILLEY</span>] is entitled to the floor.</p>
                <p><span className="persName">Mr. WILLEY</span>, of Monongalia, being entitled to the floor on the resolutions of the gentleman from Rockbridge [Mr. MOORE] , addressed the Convention as follows :</p>
                <div className="speaker" id="sp448">[<span className="persName">Mr. WILLEY</span>:]</div>
                <p className="p-in-sp">
                  Mr. President—I suppose the Convention has arrived at one conclusion which is very satisfactory, and that is, that I am not at all versed in the artifices of parliamentary tactics. I had no idea, sir, when yielding to gentlemen the courtesies of permitting them to offer resolutions, of having the time which I proposed to occupy
                  <PageNumber num={377} /> devoted to the interesting procedure of taking the ayes and noes. But, sir, it is quite likely that that process will be as interesting to this Convention as listening to any remarks which I shall be able to make. I had some misgivings, indeed, this morning, whether under the existing state of my health, and especially of my
                  voice, I should avail myself of the courtesy extended to me by the Convention on Saturday last. But, inasmuch as it was not then my purpose, and is not now my purpose, to enter into an argument upon the main points of the great issues before this Convention, I will ask your indulgence, sir, for a short time while I shall direct your attention, as best I may be able, to some
                  considerations upon the objections urged by gentlemen on this floor against the policy of Virginia, according to my apprehension of what is her best policy.
                </p>
                <p className="p-in-sp">
                  And here, sir, allow me to remark, that we have indeed fallen upon evil times. Allow me to say that I have looked with fearful apprehension, not merely upon the magnitude of the great questions which brought this Convention together, but I have looked with no less apprehension upon influences which have been brought to bear upon the deliberations of this Convention, as destructive of
                  the fundamental principles of a free people. And, sir, it may be in accordance with a spirit of pugnacity more than with any expectation that I shall be able to enlighten this body, that I choose, as a member representing free people, to rise here for the purpose of expressing freely my opinions upon the questions which have brought us together.
                </p>
                <p className="p-in-sp">
                  And in doing so, sir, while I shall expect to observe all the courtesies of gentlemanly debate, and should be very sorry to utter a word which would be out of order; yet I shall, nevertheless, claim the privilege of expressing my mind freely and fully, independent of any influences here or elsewhere. Sir, the right of free speech is a fundamental principle of republican liberty.
                  Whenever that is destroyed the people's liberties are destroyed. And it matters not, sir, whether that destruction be the result of an imperial edict, or whether it be the result of popular violence and intimidation ; in either case we are equally slaves. Is it not so sir? Does not history attest this great fact in all her pages? The scholar has read the demonstration of this truth
                  in the classic pages of the historians of republican Greece. The pages of Roman history are no less pregnant with memorable exemplifications of the same fundamental truth. Whilst the Forum was free, whilst the Senate was free, whilst there was freedom of speech, Rome was free; and when Cicero, the last great defender of the Roman Senate and freedom of speech, perished, and his head
                  was gibbeted on the Forum, where his <PageNumber num={378} /> eloquence had thundered against his country's oppressors, liberty fled from Rome, and the palsy of political dissolution settled forever upon that great empire.
                </p>
                <p className="p-in-sp">
                  Modern history has also furnished its example—an example abhorrent to every principle of humanity. Need I refer you to the reeking scaffold, smoking all day long with the best blood of France? Need I refer you to the deep dark dungeons of Paris, groaning with suffocating victims doomed to death, because of no other offence than having given utterance to their opinions? Need I point
                  you to her temples, descrated by the expulsion of the ministers of God, by the introduction of the foul orgies of a festering, intolerant infidelity ? These sir, were the pitiable, ultimate, results of an over awed and silenced Senate. The voice of liberty was drowned, and the oracles of God were silenced by the clamors of a revolutionary populace, and liberty herself, despairing of
                  relief, fled for refuge, and found it only in the arms of an absolute despotism. Sir, shall we have these scenes of Jacobin-violence renewed in our land? Shall we inaugurate a Reign of Terror here? No, sir! And when this great right has been assailed here, I for one, as one of the representatives of a free people, stand up to vindicate it; and though I may not utter words that will
                  be entitled to much weight in the consideration of the members of this Convention, I will nevertheless, utter them in all sincerity here.
                </p>
                <p className="p-in-sp">
                  Mr. President, as I have already said, it is not my intention to enter into any process of argumentation in reference to the main issues involved in this debate. I propose, sir, a cursory, running examination of some of the great evils brought to the notice of this Convention by the gentleman on the other side, demanding, as we are told here, the extreme remedy of breaking up this
                  Government.
                </p>
                <p className="p-in-sp">
                  Allow me, sir, to remark in the outset that I admit the evils in all the extent and magnitude in which they have been presented. I am not here, sir, to apologize for them. I am here to acknowledge, I am here to denounce, I am here to repudiate these errors ; but I am not here to consent to break up this Government. I am here for the purpose of endeavoring to correct those grievances,
                  and to vindicate the honor of Virginia, not by abandoning her position in this great Confederacy, but by bringing her oppressors to acknowledge those errors and to redress her grievances.
                </p>
                <p className="p-in-sp">
                  And allow me to enquire just here, Mr. President—granting that those evils exist, acknowledging them in all their extent and magnitude —what fault has there been on the part of the General Government of the United States? Why break up this Union? Will any gentleman <PageNumber num={379} /> be so kind as to particularize a single instance worthy of
                  debate, in which the Federal Government has been derelict in the discharge of its duty, or has failed to accomplish the purposes of its organization? If individual States of this Confederacy shall have been guilty of malfeasance, as I acknowledge they have been, what reason, logically considered, is there, in consequence of that fact, why we should break up the Federal Government,
                  which must be acknowledged by all, to have accomplished the design of those who constructed it?
                </p>
                <p className="p-in-sp">
                  Now, sir, I will make another remark in relation to these evils. The remedy proposed by gentlemen on the other side is secession. Now. sir, if it be true, as many able gentlemen on this floor think it to be true, as many of the ablest jurists of this land have decided it to be true, that there is no constitutional right of secession, that of itself ought, among a law-loving and law
                  abiding people like Virginians, to be an argument of no small consideration. I am not here, sir, to argue the question of the right of secession. I do not intend to weary the Convention by entering into a discussion of that great question. I shall not even pause, sir, to vindicate the founders of our Constitution from the imputation, which seems to me would certainly apply to them,
                  of a most gross self stultification in organizing a great government, in establishing a more perfect Union, by collecting together a heterogenous mass of political elements that might dissolve and fall asunder any day. I never shall be made to believe that Washington, and Madison, and Franklin, and the other great sages who constructed the Union in the first place, and organized our
                  Federal Government, brought their labors to no greater result than this ; that is to say, to bring the States of the Federal Union together, give them a simple introduction to each other, and place them side by side under the flag of the country, without any legal bond to bind the Union. Sir,
                </p>
                <p className="p-in-sp">
                  believe in no such voluntary association. In my opinion, if this be the correct construction of the Federal Constitution, we have never had a Union at all; these States have never been united, but they have been living together in a kind of unlawful co-habitation; and when the gentlemen are able to establish that principle, then I will be more willing to enable them to destroy that
                  illicit intercourse.
                </p>
                <p className="p-in-sp">
                  I said, Mr. President, that it was not my purpose to argue the question of constitutional law involved in this proposition, but I trust that it may not be considered improper to advert for a moment to the operation of that principle, if granted to be true. Many times the proper construction of a law may be best ascertained by following it out into its practical results. Well, sir, I
                  never could conceive that <PageNumber num={380} /> when the Federal Government was purchasing the territory of Louisiana, and the freedom of the mouth of the Mississippi, and of the Gulf, for the purpose of protecting and encouraging the great trade of the interior empire of States, which by the sagacity of our wise men was foreseen springing up in the
                  West—I say I could not conceive that this government would have made that purchase at the expense of a great deal of money, and of a great deal of trouble, upon the contingency that the little State of Louisiana might at her own pleasure foreclose these great advantages of commerce against all these interior States.
                </p>
                <p className="p-in-sp">
                  Again, sir, I cannot suppose that the purchase of Florida—another little State—which cost us, in the first place, I believe, some five millions, and a great many more millions in establishing forts and arsenals, and in driving the Indians out of her marshes, until the sum that that little State has cost us will amount perhaps to fifty millions—I say I never can suppose that our
                  government made that purchase, and expended all that money, under the contingency that this little State, after all these services had been rendered, after her territory had been made free, after the savages had been driven from her domain, might quietly walk out of the Union and leave us the bills to pay.
                </p>
                <p className="p-in-sp">
                  Again, sir, there is Texas, an empire in itself, which cost us some ten or fifteen millions to acquire, in the first place; which cost us, by the war brought upon this country, hundreds of millions. You, sir, remember, and I remember myself, that the very effort to acquire this great State had well nigh dissolved the Union, that it agitated it from centre to circumference—and we
                  encountered all that for what? Is it that now, when she has scarcely more than been admitted into the Union, when we have paid all this money on account of her, when scarcely the agitation occasioned by her acquisition has subsided, she may pass out of the Union, and leave us in this predicament? It seems to me that to admit such a solecism in the fundamental law of the Government
                  would be, I was going to say, ridiculous.
                </p>
                <p className="p-in-sp">
                  But, Mr. President, let us look at a few more consequences. If the doctrine of the right of a State to secede at her own good will and pleasure be true, then, sir, we may engage in a war, the enemy may be pressing us hard, and yet, in the very hour of our trial, in the very crisis of the country's extremity, a State may retire from the Union, and out of danger; and if she be indeed
                  independent and sovereign when she goes, may form a treaty of alliance with the enemies of the Government, and turn her guns against her former associates. Or she may wait until the war is concluded—a war in which the blood of her confederates may have been shed in defending her soil—and when the <PageNumber num={381} /> enemy is repelled, when the debt
                  incurred by the war is resting on the country, politely make her bow, retire from the Union, and leave the remaining States to pay the debt incurred in defending her soil, and in vindicating her honor? Can it be possible that Washington, and Madison, and Franklin, and the other sages of the Revolution, have organized a Government upon such an absurd basis as this?
                </p>
                <p className="p-in-sp">
                  But, sir, when did this doctrine first find a lodgement in the public mind? I think, sir, it is rather a modern invention. If I have read aright the debates in the Virginia Convention for the ratification of the Federal Constitution, the idea of the right of a State to secede was absolutely spurned from the councils of that great body. I know sir, where, it is said, we are to find
                  this. We are told
                </p>
                <p className="p-in-sp">
                  we may find it in the celebrated resolutions and proceedings of the Virginia and Kentucky Legislatures in 1798-'99. What, sir, is the correct interpretation of the doctrine contained in these resolutions and proceedings? To whom shall we apply for the correct exposition of it? We would go, sir, to the artificer for a correct explanation of his machine ; we would go to a law-giver for
                  a correct exposition of his law, and although, these resolutions have been quoted as authority, yet Mr. Madison, their author, frequently stated in his letters to Mr. Everett, Mr. Trist, Mr. Cabell and Mr. Stephenson, that no such construction can properly be placed upon these resolutions and proceedings. No, sir, it was a new idea. It was an idea repudiated by Mr. Madison, at the
                  very time of the adoption of our Federal Constitution. I will read a very short extract from a letter written by Mr. Madison to Alexander Hamilton, in 1788, while the question of ratifying the Federal Constitution was yet pending in the State of New York. Here, sir, is the language which he used in the letter to which I have referred, and which was written at that early day :
                </p>
                <p className="p-in-sp">
                  "The Constitution requires an adoption in toto and forever. It has been so adopted by the other States. An adoption for a limited time would be as defective as an adoption of some of the articles only. In short, any condition whatever must vitiate the ratification. What the new Congress, by virtue of the power to admit new States, may be able and disposed to do in such case, I do not
                  enquire, as I suppose that is not the material point at present. I have not a moment to add more than my fervent wishes for your success and happiness. The idea
                 [and these are the words to which I wish particularly to refer] 
                  of reserving the right to withdraw was started at Richmond, and considered as a conditional ratification, which was itself abandoned as worse than rejection." <PageNumber num={382} /> There, sir, is the opinion of Mr. Madison himself, the author, and, I might almost say, the finisher of that great instrument the Constitution of these United States.
                </p>
                <p className="p-in-sp">
                  It is true, sir, we had, a little sprinkling of secession in the early history of this Government, but not much. The first that we had appeared, I believe, in the Hartford Convention. It was there, I think, that the doctrine was first enunciated. Very little of it, however, appeared until afterwards, about the year 1830. It then made its reappearance, but the iron logic of General
                  Jackson, and the inexorable decree of the great Democratic party put their heel on it at that day, and it never recovered from the defeat it then received until very recently. General Jackson said :
                </p>
                <p className="p-in-sp">"To say that any State may at pleasure secede from the Union, is to say that the United States are not a nation; because it would be a solecism to contend that any part of a nation might dissolve its connexion with the other parts to their injury or ruin without committing any offence."</p>
                <p className="p-in-sp">
                  And, Mr. President, if you look a little into history—and I am almost tempted to do it—you will find that even in the States—our sister States that have seceded—this idea of secession is rather a modern idea. And, sir, it is always best, I believe, to speak to the record. We find, at least, as late as 1821, South Carolina herself held this proposition in resolutions declaring her
                  principles; that is to say, that she was opposed to "arraying, on questions of national policy, the States as distinct and independent sovereignties, in opposition to, or what is much the same thing, with a view to exercise control over the General Government," and felt it "their indispensable duty to protest against a measure of which they conceive the tendency to be so
                  mischievous."
                </p>
                <p className="p-in-sp">
                  And then, sir, in the State of Mississippi the doctrine is one of modern origin; for, during the excitement which grew out of the compromise measures of 1850, there was a Convention called in the State of Mississippi for the purpose of passing an ordinance of secession and dragging Mississippi out of the Union. But the people put their foot on that effort, as the popular majority put
                  its foot on the effort which was recently made to drag her out of the Union. I wish to read a resolution passed by the Convention in Mississippi at that time, called in pursuance of an act of the Legislature, to take into consideration the propriety of seceding from the Union. Here, sir, is the resolution :
                </p>
                <p className="p-in-sp">
                  "Resolved, further, That in the opinion of this Convention, the asserted right of secession from the Union on the part of a State is utterly unsanctioned by the Federal Constitution, which was framed to <PageNumber num={383} /> establish, and not to destroy, the Union of the States, and that no secession can in fact take place without a subversion of
                  the Union established, and which will not virtually amount in its effects and consequences to a civil revolution."
                </p>
                <p className="p-in-sp">
                  That was the deliberate expression of the popular voice of the people in Mississippi in Convention assembled, about the year 1851; and a like sentiment prevailed at that very time in Georgia; but it was put down by the able efforts and able arguments of Mr. Cobb, late Secretary of the Treasury. He was a candidate for Governor, and the issue in that State turned, as I understand it,
                  upon the right of a State to secede. He took ground against that right, and that I may not be placed in an attitude perhaps of asserting what I cannot maintain, I beg leave to read a few short extracts from his argument; one of the ablest arguments I ever read against the constitutional right of a State to secede from the Union. He said on that occasion :
                </p>
                <p className="p-in-sp">
                  "If it had been a recognized, undoubted principle that each State was bound to remain in the Union only so long as it suited its own convenience, no one can doubt that these States, instead of withholding their assent to the Constitution, after it had been adopted by the requisite number, would have come at once into the Union, with the intention of immediately withdrawing from it
                  upon the refusal of the other States to adopt such amendments as they desired; but, regarding the effect of their ratification of the Constitution in an entirely different light from the secessionists of the present day, they adopted quite a different policy. So far as we can gather light and information from the opinions and actions of the men who framed and adopted the
                  Constitution, it all goes to strengthen and confirm the conviction I have already expressed against the existence of any such right."
                </p>
                <p className="p-in-sp">
                  I will not detain the Convention by the tedious process of reading farther extracts from that very valuable argument. I have been betrayed, contrary to my expectations, far from the line of remark, I intended to make, and far from the notes that I had made to guide me in my remarks, when I rose to address you. I come, then, to notice in a cursory and brief manner the objections that
                  I have to the remedy proposed for the evils that now impend over us—that is, the secession of Virginia from the Union. I have made memoranda of those evils as announced by the gentleman from Orange, and by other gentlemen upon this floor, and I propose now hastily—and I am afraid it will be unsatisfactorily—to give a brief, running and cursory commentary on the views advanced by
                  those gentlemen.
                </p>
                <p className="p-in-sp">The first argument in favor of secession alleged, I believe, by the <PageNumber num={384} /> gentleman from Orange [<span className="persName">Mr. MORTON</span>] 
                  , were the bitterness and acrimony with which Southern institutions are constantly assailed by-the Northern press, Northern schools, and by the Northern pulpit. I would inquire of the gentleman from Orange and of this Convention, how do they expect that the mere act of secession will silence the Northern press, school-hoUse and pulpit? I want to know how the mere act of
                  secession—when we have thrown off all allegiance to the common Government, when we have left our brethren, if it may be proper to call them so, with a bitter hatred rankling in the bosom, to be still more intensified by a direct antagonism; when we have thrown off all the obligations of a common Government, common country—and a common nationality—will advance us one inch towards the
                  silence of the Northern press, pulpit or school-house? It will only aggravate the evil ; it will only add poison to the virulence and acrimony of which they now complain, and will give vigor and effect to the very objections which these gentlemen are urging as a cause for Virginia seceding from the Union.
                </p>
                <p className="p-in-sp">
                  Again—another argument in favor of secession, urged by these gentlemen, was to be found in the efforts made by the Northern Abolitionists to induce our slaves to abscond, in the operations of the Underground Railroad, in the Personal Liberty Bills and the inefficiency of the Fugitive Slave Law for the recovery of fugitives. Now, sir, secession is proposed as a remedy to cure these
                  evils. How will it accomplish this object? As I told you, I am not going into a legal argument here. From the fact that I am recognized as a kind of outside barbarian, and as living away up in Northern Virginia, amongst the Abolitionists there, of course, you cannot expect me to be very acute in logic; I come here as a plain man from the mountains, looking to common sense as my
                  guide. As I said, secession, for the evils of which we complain, is proposed as the remedy. How will it work, for instance, in reference to the recovery of our slaves? Let us look at this matter. You dissolve the Union. What then? The common national obligation is destroyed. Will not the negro find it out? The motives to flee across the line would be increased, because the negro
                  would know that whenever he crosses that line, he will be free. There will be no fugitive slave law for his recovery, and he will know it. There will be no posse comitatus to aid in the delivery of the slave to his master; there will be no federal arm to enforce the recovery of Anthony Burns at the point of the Northern bayonet. There will be none of the power of the law, the power
                  of the Constitution and the power of a common nationality to do this. How, then, will secession remedy the evils of which gentlemen <PageNumber num={385} /> complain? As I have shown you, it will only increase and aggravate them.
                </p>
                <p className="p-in-sp">
                  But then again, these gentlemen say : there are the Personal Liberty Bills of the North. In the first place I would ask, why should we condemn the General Government on this account? Why should we dissolve the Union because some of the States, members of this Confederacy, have seen proper to do wrong? We denounce those Bills and consider them as not only an insult to Virginia and the
                  whole South, but also to the people who ordained them. They are an insult to common sense. But what has been their operation? They have never been enforced, but have remained a blank upon the statute book. Northern courts have uniformly declared them to be unconstitutional and to be null and void ; and however offensive in character or spirit they may be, the truth is, that they have
                  never interposed one jot or tittle of hindrance in the way of the recovery of a single fugitive slave.
                </p>
                <p className="p-in-sp">
                  But I pass to a third argument which gentlemen have urged in favor of secession, and that is, that the Republican party threaten to exclude the South from the common territory and to destroy the equality of the States. I for one will never submit to such a law, and I want for myself and for Virginia an equal right in the territories, but I will not go off upon the subterfuge or
                  pretext of secession.
                 [A hiss.] 
                  I have heard hisses many a time. I believe it is a matter of history, that it was the hissing of a goose that saved Rome ; and if the hissing with which my sentiments have just been received will excite a proper indignation and examination of Virginia's rights all through the State and in the bosom of the Convention, it will save another Rome—it will save Virginia.
                </p>
                <div className="speaker" id="sp449"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">The Chair will remark to the gentleman, that he did not hear any manifestation of disapprobation. If he had, he would undoubtedly have ordered the gallery to be cleared.</p>
                <div className="speaker" id="sp450"><span className="persName">Mr. WILLEY</span>—</div>
                <p className="p-in-sp">Let it pass—we are even now; I reckon. [Laughter.] 
                  As to the inequality in the territories, I have said that I will never submit to it. But, then, what remedy is offered to me to redress that wrong. Let me, before I refer to that, however, ask what danger is there of our rights being invaded? Are we not a law-abiding people, and has not the Supreme Judicial tribunal of the land decided to guarantee, to the full extent, the right of
                  every slaveholder in the land to carry his property into all the territories of the United States? Such is the decision of the Supreme Court of the United States. But suppose there were no such decision, and we had to redress our rights in any other way. I ask gentlemen to point out to me how it is that <PageNumber num={386} /> secession will give us
                  the right to the territories that we demand? Let us look at this matter practically. Are we to acquire our equal rights in the territories of the United States, by seceding from the United States; by turning our backs upon those territories; by giving up all our right, claim and interest in those territories? Did ever the imagination of man conceive such an unsatisfactory way of
                  redressing so great a wrong?
                </p>
                <p className="p-in-sp">
                  Again: it is alleged that Black Republicanism has got possession of the Government, that the South is in a minority, and therefore that it will be at the will and pleasure of a Black Republican Government. I will read you a short extract from the opinion of Mr. Jefferson in his inaugural address of 1801, I believe it was. I commend it to the attention of my Democratic brethren; for,
                  representing, as I do, a Democratic constituency
                 [laughter] , I have the right to call them brethren.</p>
                <p className="p-in-sp">
                  He summed up in this address as among the essential principles of our free institutions "the preservation of the General Government in its whole constitutional vigor as the sheet-anchor of our peace at home and safety abroad; a jealous care of the right of election by the people—a mild and safe corrective of abuses which are lopped by the sword of revolution where peaceable remedies
                  are unprovided ; and absolute acquiescence in the decisions of the majority—the vital principle of Republics, from which there is no appeal but to force, the vital principle and immediate parent of despotism."
                </p>
                <p className="p-in-sp">
                  I am not here, Mr. President to defend the election of Abraham Lincoln. I believe that his election was virtually a fraud upon the people of the United States. I am utterly opposed to the manner in which he was nominated—nominated, as he was, by a sectional party, and upon a sectional platform, with no representation in the body which nominated him from the South; but he was
                  nominated and elected according to the forms of law. As to the errors and wrongs of his administration I would meet them as I would meet all other errors and wrongs, by the force of reason, until reason has no force, and then I would resort to the ultima ratio.
                </p>
                <p className="p-in-sp">
                  But, we are told that we are in a minority, and that the Black Republicans may swallow us up. Who placed us in the minority? In the first place it is a fact that Lincoln is elected by a meagre plurality of the votes of the people of the United States. But I would inquire who placed us in the minority? What would have been the complexion of the next Congress of the United States if
                  our sister States, as they are called, had hearkened to our voice and entreaty? We implored them to wait, to pause, to deliberate and co-operate with us and see if there <PageNumber num={387} /> were no constitutional means, no peaceful means of preserving this Government and redressing our rights? But they would not wait, and went off, contrary to our
                  wishes, contrary to our entreaties. Now, I inquire, in view of these indubitable facts, who placed us in the minority? Sir, it was these sisters of the seceding States. It is a fact that cannot be controverted, that the next House of Representatives of the United States, if these States had been true to themselves and true to Virginia would have contained a majority of not less than
                  thirty against Abraham Lincoln's policy. The Senate is so organized that if the Government had been preserved as it was on the 6th of November last, they would have had a majority in that body all through the Constitutional term of Mr. Lincoln as President. You see now who placed us in a minority, and yet the bug-bear of being in a minority is paraded before our eyes, connected, at
                  the same time, with most sympathetic demonstrations of affectionate regard for our Southern sisters.
                </p>
                <p className="p-in-sp">Then there was the Supreme Court as another protection for us; and with all these protections against the policy of the Northern Republican party, what danger had the South to apprehend—what practical danger, I ask? None at all.</p>
                <p className="p-in-sp">
                  Mr. Lincoln could not have appointed a member of his Cabinet without the consent of the South—he could not have appointed an officer in the whole Federal Government, whose salary amounted to a thousand dollars, without the consent and ratification of the South—and we never would have had a law objectionable to the South presented to him to be signed, and made final according to the
                  provisions of the Constitution. Let us then hear no more about a minority. Let us hear no more about the tender sympathies of our seceding sisters, who have left us in this minority ; who have left us to be trampled under foot; left us to the mercy of that party against whom our indignation is sought to be raised by eloquent appeals every day. I appeal to history and to the candid
                  knowledge of every member upon this floor, if these statements I have made are not borne out by the facts. They cannot be denied. We are in a minority, it is true, but our seceding sister States placed us in that minority.
                </p>
                <p className="p-in-sp">It was alleged, Mr. President, by the honorable gentleman from Orange [<span className="persName">Mr. MORTON</span>] 
                  , the other day, that the honor of Virginia required that we should secede. I beg to know how the honor of Virginia is to be vindicated by a secession from the Union? Have we been wronged? Have we been reviled? Have our rights been trampled upon in the Union? I admit that they have; but I claim that honor and chivalry demand that we should stand fast, give not an inch to our foe,
                  <PageNumber num={388} /> turn not our faces from him, but vindicate our rights to the letter and to the death, if need be, right where they have been assailed. That seems to me to be Virginia's honor and Virginia's consistency.
                </p>
                <p className="p-in-sp">
                  One gentleman here, in his eloquent speech the other day, referred to another argument—I must say that it was an extraordinary argument to be addressed to Virginians—and he illustrated it very forcibly and with a great deal of detail. He alleged in effect, that Lincoln would so employ the patronage of the Federal Government as to corrupt Virginia. It is an argument that I would not
                  dare to make to my constituents—that I would not like to make in any section of Virginia; and I will say, that if Virginia is of such easy virtue as to be corrupted from her integrity by a little paltry pap from the Federal Treasury, her honor is not worth preserving.
                </p>
                <p className="p-in-sp">
                  Gentlemen have also referred to the John Brown raid, which met with such condign punishment, and deservedly so. What did this raid demonstrate? It demonstrated the ample power of Virginia to vindicate her violated rights and her dishonored soil. She seized upon the traitors and the felons, and, without let or hindrance, hung every rascal of that marauding band, and she vindicated the
                  law to the full extent. Dissolve this Union—take away the common obligation of a common government, how then will the case stand? Instead of one John Brown raid in 75 years of our history, we will have John Brown raids every month, and, perhaps, every week, all along our borders. Instead of frightening the women and children and exciting the alarm of the people at Harper's Ferry and
                  vicinity, the peaceful valleys and blooming hills of 450 miles, close upon a hostile border in the section of the State where I live, will be subjected to these same John Brown raids; if, indeed, there is such hostility in the North as gentlemen assert there is. I believe there is not. I only mean to answer their argument.
                </p>
                <p className="p-in-sp">
                  But the argument that struck my mind with the greatest force was, that there is, in point of fact, an irrepressible conflict between the North and the South; that the social system, the system of civilization, of education and the interests of the people of the two sections are so diverse, that it will be impossible to construct a government that will harmonize them and enable them
                  to live together in peace.
                </p>
                <p className="p-in-sp">But, sir, is this argument sound? I think it will not bear the test of close examination. Against this mere speculative opinion, I oppose stubborn facts. Against this mere prediction, I present actual history. I appeal to the record of the past operation and effect of the Federal Union. It is no longer a problem to be solved.</p>
                <p className="p-in-sp">And just here, allow me to advert to the argument of the gentleman <PageNumber num={389} /> whom I saw in his seat a while since, but who, I believe, has now left the hall—the distinguished gentleman from the county of Middlesex [<span className="persName">Mr. MONTAGUE</span>] 
                  . He referred to a portion of the farewell address of the father of his country the other day. A most remarkable document to refer to for authority for secession. He referred to that portion of it, in which the father of his country, in the morning of our government, besought his countrymen to give it a fair trial, to make the experiment fully, and not abandon it until it has had a
                  fair trial. It has had a fair trial; it has been in existence seventy-five years. Look at the result of the experiment. I shall not attempt to describe it. Some traveller records that, in the great temple of St. Paul's there is a tablet upon which the name of Sir Christopher Wren, the great architect, is engraved. Beneath it is this inscription : "Do you seek his
                  monumentcircumspice—look around!" In reference to the great experiment of the Union, to which the gentleman from Middlesex
                 [<span className="persName">Mr. MONTAGUE</span>] , appealed, I can only say, with reverence, awe, and patriotic emotion : "Look around!"</p>
                <p className="p-in-sp">
                  Whose heart does not throb, as an American citizen, in view of this experiment? Look around you, from the Pacific to the Atlantic, from the Gulf to the Lakes, from Texas to Maine. Three-quarters of a century ago we were only four or five millions of people in number, and but a few scattered and impoverished States. Now we are thirty-four States—for I will not admit that our sisters
                  are finally gone—with cities rivaling in wealth, population, power and magnitude the oldest cities of the oldest Empire of the world; with a people unsurpassed for intelligence, for all the appliances and means of self subsistence, for happiness and prosperity, and the like of whom the sun of God has never before shone upon. It is such an experiment as this to which the gentleman
                  from Middlesex refers; and we are yet only upon the threshold of our glorious destiny, if we will be but faithful to our duties as true American citizens. What a scene opens before the imagination of the true American patriot, as his eye gazes down the vista of futurity? I like to look upon the flag of my country; I like to contemplate its starry folds, and when I think, that
                  originally there were but thirteen stars upon it, and that now there are thirty-four, I am reminded of an incident in the history of one of the infidels of France : Walking out one bright, unclouded night, he met with one of the Christian philosophers of that empire, who was on his way to his observatory, and engaging him in conversation, he propounded this question to him : "Where
                  is your God?" The Christian philosopher pointed him to the stars, sparkling all over the Heaven's blue arch; but <PageNumber num={390} /> still this exhibition affected him not. He then introduced him into his chamber, and turning his telescope heavenward, and bidding him look, as field after field of glorious starry worlds passed before his vision, the
                  infidel, awed and overcome by the sublime sight, could not but bow in meek reverence before the majesty of God and his works, and exclaim—"there is a God!"
                </p>
                <p className="p-in-sp">Allow me to say, Mr. President, that the starry field upon our country's flag shall still increase, until the telescope of the future shall reveal a galaxy of stars, representing States only bounded by the oceans which surround this hemisphere, and centuries hence the boldest political infidel, bowing before the majesty of the Union, shall acknowledge its power.</p>
                <p className="p-in-sp">Let us look, Mr. President, at the evils that must result from secession.</p>
                <p className="p-in-sp">
                  The first, in my opinion, would be that our country would not only be divided into a Northern Confederacy and into Southern Confederacy, but, soon or later it would be divided into sundry petty Confederacies. We would have a Central Confederacy, a Confederacy of the States of the Mississippi Valley, a Pacific Confederacy, a Western Confederacy, an Eastern Confederacy, a Northern and
                  a Southern Confederacy. And what the result of all this would be, I leave to the decision of history, recorded on many a mournful page of human story. It may be read in the desolation of Greece. It is chronicled in the disintegration and downfall of the Roman Empire. It is written in characters of blood, hardly yet dry, drawn from the veins of the compatriots of Garibaldi, struggling
                  to re-gain the blessings and power of the ancient Italian unity.
                </p>
                <p className="p-in-sp">
                  We would have between these several Confederacies a perpetual warfare, criminations and recriminations, inroads, strife and discord, until the energies and the wealth of this great people would be utterly destroyed and exhausted. We have now in the Central American States, and the Mexican States, a fit representation of what we would become under the condition of things which I have
                  depicted. And what is the condition of these States? They are sub-divided into so many Republics as that I venture to say there is not a member on the floor of this Convention who can tell their names. Such would be the ultimate result of the secession policy, which we are now called upon to adopt, and the evil that would result from this disunion. And I beg the attention of my
                  friend
                 [<span className="persName">Mr. MORTON</span>, the reporter presumes] 
                  —I hope I may be permitted to call him so—to what I am going to say upon another subject. I say, sir, that a dissolution of the Union will be the commencement of the abolition of slavery, first in Virginia, then in the <PageNumber num={391} /> border States, and ultimately throughout the Union. Will it not, sir, make a hostile border for Virginia, and
                  enable slaves to escape more rapidly because more securely? Will it not, virtually, bring Canada to our doors? The slave will soon be apprized of this. His motive to escape will be increased. He will know that when he reaches the line he will be safe; and escape he will. The owners of slaves, aware of this fact, will either themselves remove farther South, or they will sell their
                  slaves to be sent farther South. Thus the area of soil divested of slave labor along the border, would be constantly widening, and rapidly widening, until ere long it would begin to encroach upon the States immediately South of Virginia, and banish slavery still further from the border, until finally it would sweep it from the country. That is the idea of Charles Sumner, of Lloyd
                  Garrison, and of Wendell Phillips. That is what they say; they want to surround the slave States, in the language of Sumner, "with a belt of fire." How is that to be done? We maintain that we have a right to carry our slaves into the territories. Let the Union be dissolved, and let the South yield these territories, and what then? They would inevitably become free States. They would
                  be foreclosed against slavery, and the slave States would be literally hemmed in by a cordon of hostile elements, the influence of which they would try in vain to resist.
                </p>
                <p className="p-in-sp">
                  Again! Whether rightfully or wrongfully, it must be acknowledged that the moral sense of the world is against the institution of slavery. The Northern States would of course declaim against it; all Northern influence will be directed towards its overthrow, and the influence of the world in its commercial policy, in its treaty stipulations, in its literature, in its social influence,
                  and in all the power and patronage of its civilization, would be directly, or indirectly, modified and applied, so as to bear upon the institution of slavery. Especially would this be the case, when the South, severed from the Union, would become comparatively a weak and secondary power among the nations.
                </p>
                <p className="p-in-sp">
                  It has been the wise and uniform policy of this country to avoid entangling foreign alliances. We have wisely cherished what has been called the Monroe policy, and jealously inhibited the European powers from exercising dominion, or establishing their authority on this side of the Atlantic. But let disunion take place—let this great nation be severed into several petty republics, and
                  what will become of this policy of our fathers? What would become of the Monroe doctrine, which the government has so often pledged itself to maintain, should this policy of secession be carried out? Divide us into a number of petty confederacies, and what will become of that doctrine? England will seize <PageNumber num={392} /> on the Island of Cuba,
                  the great gate to the Gulf and the mouth of the Mississippi, and it will not be long before we find her setting up claims for debt against Mexico, and making that claim a pretext to descend upon Mexican territory and hold it as an indemnity. She will do with her as she has done everywhere else that an opportunity or pretext has been afforded her. She will take advantage of our
                  division and weakness, to extend her power on this continent, and we cannot resist. How will the South resist those encroachments? She has no navy, and no army, and if we reflect upon the proverbial greed for power and territorial extension, that has always characterized England, we behold dangers which might well admonish the South against a resort to any policy that involves
                  division and weakness.
                </p>
                <p className="p-in-sp">With such nations and such influences all around the Southern States—hemmed in by such adversaries without the power of expansion—how long can a Southern Confederacy endure? How long can slavery exist? And I put it to gentlemen to say whether these suggestions are not more than fancy.</p>
                <p className="p-in-sp">Then, sir, there is another thing we must look to. It is a very easy matter to pull down a house, but a difficult matter to build it up. It is easy perhaps to break down this Government ; but, sir, when we break it down it will not be so easy a matter to build it up.</p>
                <p className="p-in-sp">
                  Again, gentlemen ask us, will we join the North or will we join the South. I say neither one nor the other. We will stand upon the old Constitution. Let our destinies be placed upon that ; and not upon some miserable basis, which is liable to be changed to accommodate every fancied necessity. But, sir, there are other perils to which this new Government would be exposed, and I would
                  have gentlemen reflect upon that.
                </p>
                <p className="p-in-sp">
                  What sort of Government will we get? Look at what they are doing in Montgomery. What have they done? I had supposed, sir, that standing in the midst of a Convention like this, I would recognize the great principle that all power was derived from the people. I had supposed, sir, that I was in the midst of a Convention—since the expression of the late election, requiring us to send
                  back to them for ratification or rejection, whatever action we take here—that recognizes the fact that our people were determined not to submit to any decision which should not have their full and undoubted sanction. How does the matter stand in the seceded States? Alabama went out of the Union with a popular majority against her action. The same may be said of Mississippi ; and it
                  is recently ascertained that Louisiana did the same thing. <PageNumber num={393} /> It seems to me that this Southern Confederacy is disposed to repudiate the principle that the political power resides in the people. Will Virginia agree to that? What had the people to do with the choice of the Congress at Montgomery? Nothing. It is a kind of
                  self-constituted body. It was not chosen by the people. It was called without their sanction or authority. And yet it has ordained a government, and imposed it on the people without their sanction or authority. It has appointed a President and Vice President without any popular election or ratification. A totally new government is formed, without the popular sanction, which is
                  imposing taxes, raising armies, and levying war, all, without any direct authority or election of the people. Could absolute despotism do more? And yet it is to such a government as this that gentlemen wish to attach us. Gentlemen cry out against the tyranny of their own government, and yet denounce us because we hesitate to allow ourselves to be thrust into the embraces of such a
                  military despotism. Whither are we tending?
                </p>
                <p className="p-in-sp">
                  What may we expect, sir, in a Government that acts in this manner? What may we expect, sir, but a strong military government, very nearly approaching a military despotism. I refer to these things merely to suggest the idea of the system of Government which the policy of these States foreshadows. And these cotton States, thus premonishing us of future oligarchy, or constitutional
                  monarchy, or military despotism, must always have the balance of power in any Confederacy we shall form with them.
                </p>
                <p className="p-in-sp">I would ask the Convention to bear with me a little while longer. I regret that I feel myself unable to repay the courtesy which is manifested to me.</p>
                <p className="p-in-sp">
                  I say, sir, that secession necessarily implies the placing of this State upon a proper war footing. Sooner or later war is inevitable. If we enter into negotiations with the Federal Government, and separate peaceably, war may be avoided for a time, but we shall ultimately become engaged in war. Secession, therefore, implies a necessity of putting this State upon a war footing.
                </p>
                <p className="p-in-sp">
                  Now, gentlemen are acquainted with the geography of Eastern Virginia, and no doubt can well appreciate the difficulty of defending our long sea border. But what I wish more particularly to direct attention to is this : Look at our Western border; go down to the Kentucky line—to the lower end of Wayne county, and follow our border from Wayne, by Cabell, by Mason, by Jackson, by Wood,
                  by Pleasants, by Tyler, by Marshall, by Ohio, by Brooke, by Hancock nearly to Pittsburg—two hundred and fifty or perhaps three hundred miles of hostile <PageNumber num={394} /> border upon which lies one of the most powerful States of the Union—Ohio. And then we go back by Hancock, Brooke, Ohio, Marshall, Wetzel, Monongalia and Preston, which exhibit
                  one hundred and fifty or two hundred miles more of hostile border, upon which lies the most powerful State in the Union--the great State of Pennsylvania. Look how we are thus fixed—stuck in like a wedge between the enemy and the Red sea-450 miles of hostile borders surrounding us, liable to be swept from border to border, by the range of an Armstrong gun, for one hundred miles of our
                  territory.
                </p>
                <p className="p-in-sp">
                  Now, sir, look at our position. Between us and you there is the almost impassable barrier of the Alleghany mountains ; and you, gentlemen, of Eastern Virginia, have not seen proper to give us enough legislative aid to transpierce these mountains that we might have direct communication with you. We are cut off from the Eastern section of the State. How would we stand in a Southern
                  Confederacy? Why, sir, we would be swept by the enemy from the face of the earth before the news of an attack could reach our Eastern friends. Will you leave us in that condition? Will you drive us out of the State and leave us at the mercy of our enemies?
                </p>
                <p className="p-in-sp">
                  Will you place us in this extremity? Will you bring this desolation upon us? Will you expose our wives and children to the sword?—to the ravages of civil war?—to the perpetual scourge of the interminable raids which will inevitably follow upon a dissolution of the Union? You see that we shall be the weakest point of a Southern Confederacy, and, therefore, the point of attack. Will
                  you make North-Western Virginia the Flanders of America, and convert our smiling valleys into the slaughter pens of as brave and loyal a people as dwell in the "Old Dominion?" I hope not. I think there exists sufficient fidelity to us to justify me in saying, no, you never will. What, then, must you do—should you take the alternative of secession? The Legislature, I understand, are
                  about appropriating a million of dollars for the defence of the State. A million of dollars! Why, sir, I have consulted with a military gentleman on the subject of the probable cost of putting the State in a proper condition of defence, and after stating to him the extent of hostile border which we had to defend, and the difficulties of access, and other like considerations necessary
                  to be taken into the estimate, he said that it would require not less than one hundred millions of dollars to put the State of Virginia in a proper state of defence.
                </p>
                <p className="p-in-sp">
                  Now, sir, if secession implies the putting of the State of Virginia upon a war footing, then I appeal to this Convention to say whether the <PageNumber num={395} /> people are prepared to meet the burdens necessary to put her in that condition. What is our debt now? Forty millions of dollars or thereabouts. Will our people suffer any more taxation? Are
                  gentlemen ready here, if it can be avoided without dishonor, without degradation—are gentlemen willing to say, here I will stand, not to destroy, but to save Virginia and the Union? Are they willing to send back to the people an ordinance of secession which will imply that they shall be subjected to millions after millions for the purpose of placing the State on a war footing? The
                  people will never endure it if it should be done. They cannot endure it. They have not the ability to endure it.
                </p>
                <p className="p-in-sp">
                  I wish I had strength, Mr. President, to repay the kind attention of the Convention to my remarks. I will ask their further indulgence, while I present another favorite idea of mine to their consideration. I allude to the fact that in the Union Virginia is a central State; out of the Union, she would be a border State. Look at her position now. I have often looked at it, reflected
                  over it, and I confess that I felt a pride, and not a little wonder, as I contemplated her great advantages in point of resources, and all the facilities for commercial and manufacturing enterprise which she possessed. Look at her immense sea-board. Look at Norfolk, which possesses the most magnificent harbor on the face of the earth, with the mighty arm of the Chesapeake stretching
                  forth to grasp the trade of the North, and drawing it towards this great seaport. Look at the magnificent country, inviting the energies and wealth of the capitalist for the construction of railroads. Many of them are already built, and are in a fair way of commanding a monopoly of the Southern trade, and directing it to its great natural outlet at Norfolk. There are in my own
                  section of the state, North-western Virginia, mineral resources extensive enough to furnish the basis of an empire's greatness; and these, when developed, will necessarily add to the power and wealth of the whole State. In that section, I should not be surprised if, in twenty-five years, the white population will exceed the aggregate population of all Virginia besides. Look at our
                  geographical position. What relations do we sustain to the Pacific? If a great national line of railroad is built to the Pacific, and direct intercourse thus established between the Atlantic and the Pacific some Virginia city will be the terminus or depot; perhaps Richmond or Norfolk.
                </p>
                <p className="p-in-sp">Let Virginia secede and all these bright prospects are forever dashed to pieces.</p>
                <p className="p-in-sp">
                  I beg gentlemen to look at the geography of Virginia, and say if they would be willing to be dragged out of this Union to join some miserable Southern Confederacy. <PageNumber num={396} /> Mr. President, I will bring my remarks to a conclusion by mentioning another evil that would grow out of the secession of Virginia from the Union, and that is, that
                  it would destroy our nationality; it would destroy the prestige of the American name and of American citizenship.
                </p>
                <p className="p-in-sp">
                  How is it now, sir? Wherever our country's flag with its thirty-four stars, floats to the breeze, any Virginian may stand up and proudly point to that banner as a flag that represents his country and his country's greatness and power. Sir, it is a noble flag. It is a flag upon which victory has perched without interruption for seventy-five years—a flag which Perry carried in his hand
                  through the din and smoke of battle and placed it victoriously upon the enemy's vessel—an enemy who once held the empire of the sea—a flag which waved in triumph at the head of our army in its victorious march from Vera Cruz to the capital of Mexico, and at last floated over the Hall of the Montezumas—a flag which protects our commerce in every port and on every sea—a flag which, in
                  short, represents our national power, gives full protection to every American citizen, go where he will—whether among the savages, in the abodes of Kings or Emperors, or wherever else he may choose to wander.
                </p>
                <p className="p-in-sp">
                  But it is said our Union is already dissolved ; and there is no use in talking about these things. I think not, sir. The Union is not dissolved in the true sense of its construction. It still lives, and will live while Virginia stands firm. Let Old Virginia maintain her dignity; let her stand. fast where she ever stood, and this Union can never be legitimately dissolved. Some of the
                  States may secede, as they have done, but they will be like the sun shooting onwards and afar off, yet shedding its light and visible, though at a distance. It soon, however, returns to its original sphere, again to illumine and impart its genial warmth to the whole system. So with the seceded States ; they may pace onward for a while but they will soon return if Virginia holds on to
                  her position.
                </p>
                <p className="p-in-sp">
                  My voice is failing, sir, and I shall be compelled to come to a close. I wish to say, before I do so, that I will not discuss what I conceive to be the remedy for our ills, simply from the fact that I believe it would be improper for me to do so, being a member of the Committee on Federal Relations, having that matter in hand. Were I to do so, it may look like seeking to forestall
                  the judgment of this Convention, which I have no desire nor intention of doing. I shall, therefore, avoid any argument or expression which might have any such tendency.
                </p>
                <p className="p-in-sp">Allow me, Mr. President, again to tender to you and this Convention, my most cordial and hearty thanks for the attentive manner in which <PageNumber num={397} /> my remarks have been listened to; the attention and courtesy manifested were beyond their merits, far, very far indeed.</p>
                <div className="note" id="Note48">
                  <span className="noteLabel">1</span>A corrected version (March 9) of the last nine paragraphs of Mr. Willey's speech differs considerably from the version of those paragraphs published on March 7. The corrected version is as follows:
                  <p>
                    Now, sir, if secession implies the putting of the State of Virginia upon a war footing, then I appeal to this Convention to say whether the people are prepared to meet the burdens necessary to put her in that condition. What is our debt now? Forty millions of dollars or thereabouts. Will our people suffer any more taxation? Are gentlemen ready here, if it can be avoided without
                    dishonor, without degradation—to impose such crushing weight of taxation? Are they willing to send back to the people an ordinance of secession which will imply that they shall be subjected to millions after millions for the purpose of placing the State on a war footing? The people will never endure it if it should be done. They cannot endure it. They have not the ability to endure
                    it.
                  </p>
                  <p>
                    I wish I had strength, Mr. President, to re-pay the kind attention of the Convention to my remarks. I will only ask their further indulgence, while I present another idea to their consideration. I allude to the fact that in the Union Virginia is a central State: out of the Union, she would be a border State. Look at her position now. I have often looked at it, reflected over it,
                    and I confess that I felt a pride, and not a little wonder, as I contemplated her great advantages in point of resources, and all the facilities for commercial and manufacturing enterprise. Look at her immense sea-board. Look at Norfolk, which possesses the most magnificent harbor on the face of the earth, with the mighty arm of the Chesapeake stretching forth to grasp the trade of
                    the North, drawing it towards this great seaport. Look at the magnificent champaign country, inviting the energies and wealth of the capitalist for the construction of rail roads. Many of them are already partially built, and are in a fair way of commanding a monopoly of the Southern and South-Western trade, and directing it to its great natural outlet at Norfolk. There are in my
                    own section of the State, North-Western Virginia, mineral resources and recently discovered fountains of oil extensive enough to furnish the basis of an empire's greatness; and these, when developed, will necessarily add to the power and wealth of the whole State. In that section, I should not be surprised if, in twenty-five years, the white population there will exceed the
                    aggregate white population of all Virginia besides. Look at our geographical position. What relations do we sustain to the Pacific? If a great national line of rail road is built to the Pacific, and direct intercourse thus established between the Atlantic and the Pacific some Virginia city will be the terminus or depot; perhaps Richmond or Norfolk. Already the "iron-horse," the
                    great representative of American energy and enterprise, is pushing his way up the Pacific slope. Already he is snuffing the air from the summit of the Rocky Mountains, eager to rush down across the Valley of the Mississippi, laden with the "wealth of the Indies," the long sought desideratum of our statesmen, and deposit his rich freight along the shores of our Chesapeake.
                  </p>
                  <p>Let Virginia secede and all these bright prospects are forever dashed to pieces.</p>
                  <p>I beg gentlemen to look at the geography of Virginia, and say if they would be willing to be dragged out of this Union to join some miserable Southern Confederacy as a mere outside appendage.</p>
                  <p>Mr. President, I will bring my remarks to a conclusion by mentioning another evil that would grow out of the secession of Virginia from the Union, and that is, that it would destroy our nationality; it would destroy the prestige of the American name and of American citizenship.</p>
                  <p>
                    How is it now, sir? Wherever our country's flag with its thirty-four stars floats on the breeze, any Virginian may stand up and proudly point to that banner as a flag that represents his country and his country's greatness and power. Sir, it is a noble flag. It is a flag upon which victory has perched without interruption for seventy-five years—a flag which Perry carried in his
                    hand through the din and smoke of battle and placed it victoriously upon the enemy's vessel—an enemy who once held the empire of the sea—a flag which waved in triumph at the head of our army in its victorious march from Vera Cruz to the capital of Mexico, and at last floated over the palaces of the Montezumas—a flag which protects our commerce in every port and on every sea—a flag
                    which, in short, represents our national power, gives full protection to every American citizen, go where he will—whether among the savages, in the Steppes of Russia, or in the abodes of Kings or Emperors, or wherever else he may choose to wander. Secession will trail that glorious banner in the dust—destroy its prestige and its power—and leave the American citizen to wander
                    abroad, if he shall dare to go abroad, an object of contempt for chuckling tyrants to point the finger of scorn at, while they say: "Behold the last pitiable demonstration of the fallacy of the long cherished dogma of man's capacity for self-government."
                  </p>
                  <p>
                    But it is said our Union is already dissolved ; and there is no use in talking about these things. I think not, sir. The Union is not dissolved in the true sense of the word. It still lives, and will live while Virginia stands firm. Let Virginia maintain her position; let her stand fast where she ever stood, and this Union can never be permanently dissolved. Some of the States may
                    secede, as they have done, but they will be like asteroids flung off from the sun, hot, hissing through the trackless ether. But, sir, the sun still shines. The Union still remains while Virginia is steadfast. And I trust in God that such will be the centripetal influence of her moral power in the Union, as to bring back these wandering stars into their proper orbits in the great
                    system of American States.
                  </p>
                  <p>
                    My voice is failing, sir, and I shall be compelled to come to a close. I wish to say, before I do so, that I will not discuss what I conceive to be the remedy for our ills, simply from the fact that I believe it would be improper for me to do so, being a member of the Committee on Federal Relations, having that matter in hand. Were I to do so, it may look like seeking to forestall
                    my own freedom of decision and judgment, which I have no desire nor intention of doing. I shall, therefore, avoid any argument or expression which might have any such tendency.
                  </p>
                  <p>Allow me, Mr. President, again to tender, to you and this Convention, my most cordial and hearty thanks for the attentive manner in which my remarks have been listened to; the attention and courtesy manifested were beyond their merits, far, very far indeed.</p>
                </div>
              </div>
              <div className="section" id="vsc1965.v1.2.16.5">
                <h3><span className="headingNumber">1.16.5. </span><span className="head">PERSONAL EXPLANATION</span></h3>
                <div className="speaker" id="sp451"><span className="persName">Mr. BAYLOR</span>, of Augusta—</div>
                <p className="p-in-sp">Mr. President, I rise to make a personal explanation. I want to put myself right upon the record. I thought the other day that I made myself understood upon one thing when I attempted to make a speech here. But it seems that I failed to do so. I find in a newspaper of this morning a short sketch referring to those remarks which I shall read :</p>
                <p className="p-in-sp">
                  "In the debate of Friday last, <span className="persName">Mr. BAYLOR</span>, from Augusta, undertook astutely to defend the Black Republican party from the charge of responsibility for the JOHN BROWN RAID. <span className="persName">Mr. BAYLOR</span> appealed triumphantly to the Chicago platform for evidence that the Black Republican party not only gave no countenance to, but openly
                  repudiated the raid."
                </p>
                <p className="p-in-sp">
                  I said the other day that the Black Republican party, organized as a party under the Chicago platform, did not endorse the John Brown raid. I did not say that the Black Republicans were not responsible for the John Brown raid. I said that the Chicago platform, under which they had organized and upon which they placed Abraham Lincoln, did not endorse the John Brown raid, but on the
                  contrary that it distinctly denounced it. Now, sir, I want to read that clause of the Chicago platform and you will see that it will be found to sustain the remarks which I made, that the Black Republican party as a party, is not responsible for the John Brown raid. It reads as follows :
                </p>
                <p className="p-in-sp">
                  "Fourth, That the maintenance inviolate of the rights of the States, and especially the rights of each State, to order and control its own domestic institutions according to its own judgment, exclusively, is essential to the balance of power on which the perfection and the endurance of her political faith depends. And we denounce the lawless invasion by an armed force from any State
                  or territory, no matter under what pretext, as among the greatest of crimes."
                </p>
                <p className="p-in-sp">
                  There, sir, is what the Black Republicans stated as a party when they organized themselves as a party. I did not say Black Republican men were not to be held responsible for the John Brown raid. I say farther, if they had adopted such a resolution, that is a resolution giving countenance to the John Brown raid, and placed Mr. Lincoln, or anybody else, upon it, that a majority of the
                  Black Republicans never would have endorsed the John Brown raid and succeeded in electing their candidate, as they have done. <PageNumber num={398} /> It is said, and I have seen it in the "Enquirer," that no man will endorse the propositions of the Peace Conference, unless he be himself a Black Republican. I endorsed those propositions the other day,
                  and I want to tell that print that I am no more a Black Republican than the "Enquirer" itself. I am a slaveholder, I live in a slave-holding county, and we are not to be denounced as Black Republicans, because we intend to endorse the compromise measures proposed to us by the Peace Conference.
                </p>
                <p className="p-in-sp">There are other remarks which I desire to make, and which I shall take the liberty of making now in reference to a few unimportant errors which have been committed in the report of my speech. I do not complain of the Reporter, for I know it is a very hard matter to be understood in this hall. I shall detain the Convention but a moment.</p>
                <p className="p-in-sp">In the report I am made to say "when I look back to the early history of my country—and I must be permitted to say here that the first public meeting that I ever attended was when I was a boy at school."</p>
                <p className="p-in-sp">I said that the first reading I ever did was the early history of my country when I was a boy at school.</p>
                <p className="p-in-sp">There is another correction I desire to make. In the report the following language is used :</p>
                <p className="p-in-sp">"I desire to have this question to go to the people of all the States, and I want them to elect members to their Legislatures, who will ask Congress to call a constitutional Convention, and then, in that event, Congress would feel itself bound to call such Convention. I will not be satisfied about that."</p>
                <p className="p-in-sp">What I did state was that I will not be satisfied "short of that," instead of "about that."</p>
              </div>
              <div className="section" id="vsc1965.v1.2.16.6">
                <h3><span className="headingNumber">1.16.6. </span><span className="head">COMMITTEE ON REPORTING CONTRACT</span></h3>
                <div className="speaker" id="sp452"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">I will now announce the committee ordered yesterday, on the resolution offered by the gentleman from Henrico [<span className="persName">Mr. WICKHAM</span>] , relative to the reporting contract with the "Enquirer : "</p>
                <p>
                  Messrs. <span className="persName">WICKHAM</span>, <span className="persName">RICHARDSON</span>, <span className="persName">HUGHES</span>, <span className="persName">STAPLES</span> of Patrick, <span className="persName">DENT</span> and <span className="persName">MONTAGUE</span>.<span className="note" id="Note49"
                  ><span className="noteLabel">2</span>The Journal does not list Mr. Montague among members of this committee.</span
                  >
                </p>
              </div>
              <div className="section" id="vsc1965.v1.2.16.7">
                <h3><span className="headingNumber">1.16.7. </span><span className="head">RECORDING OF VOTE</span></h3>
                <div className="speaker" id="sp453"><span className="persName">Mr. MONTAGUE</span>—</div>
                <p className="p-in-sp">
                  I rise for the purpose of asking the Convention to permit me to record my name upon the record, in connection with the vote taken to-day upon the question of laying the resolution on the table, whether a certain number of copies of the addresses of the Commissioners from the Southern seceding States shall be printed. I am <PageNumber num={399} /> sure
                  that when I state the reason of my absence, the Convention will readily concede to me the privilege which I ask. I was absent to-day in the Senate of Virginia, with which, as you know, I am connected, upon a very peculiar occasion. Resolutions relating to the death of the Hon. George W. Hopkins, were introduced in that body, and I deemed it my duty to be present. With this brief
                  explanation of the cause of my absence, I will ask of this Convention the privilege of recording my name upon the motion to which I have just referred, and, with the permission of the Convention, I shall vote in the negative upon that proposition.
                </p>
                <div className="speaker" id="sp454"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">The Chair will regard it as the sense of the Convention that the gentleman shall have permission to record his vote, and he will accordingly instruct the Secretary to do so in the manner indicated by the gentleman himself.</p>
              </div>
              <div className="section" id="vsc1965.v1.2.16.8">
                <h3><span className="headingNumber">1.16.8. </span><span className="head">ADDRESSES OF COMMISSIONERS</span></h3>
                <div className="speaker" id="sp455"><span className="persName">Mr. BRANCH </span>—</div>
                <p className="p-in-sp">This morning I fell into an error, which I desire to correct. I voted with the majority on the resolution of the gentleman from Ohio [<span className="persName">Mr. HUBBARD</span>] , and I desire now to have it taken up. I was here last Tuesday week, and I heard the address delivered by the Hon. <span className="persName">Mr. PRESTON</span>, the Commissioner from South Carolina; and I heard also the addresses of the other Commissioners. They were all requested by a resolution of this body to give us their addresses for publication.</p>
                <p className="p-in-sp">I now move that the resolution, which was laid on the table, be taken up, in order to enable me to offer an amendment to it. I presume there will be no objection to the motion. It is a respect that I feel is due to these gentlemen and to this body; after asking them for their addresses, that they should be published.</p>
                <p>The motion was agreed to—whereupon, <span className="persName">Mr. BRANCH</span> moved that the blank be filled with the number "3040."</p>
                <div className="speaker" id="sp456"><span className="persName">Mr. BRANCH </span>—</div>
                <p className="p-in-sp">This number will afford each gentleman 20 copies for their constituents, such at least as desire to send them.</p>
                <div className="speaker" id="sp457"><span className="persName">Mr. MORTON</span>—</div>
                <p className="p-in-sp">I wish to inquire of the chair if an amendment proposing a larger number of copies be offered, whether the vote will not first be taken upon that?</p>
                <div className="speaker" id="sp458"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">Yes sir.</p>
                <div className="speaker" id="sp459"><span className="persName">Mr. MORTON</span>—</div>
                <p className="p-in-sp">
                  I move that the blank be filled with "9,000." I think it is a courtesy due to these gentlemen that we should publish their addresses. The Legislature of Virginia, before whom the Hon. Mr. Memminger, of South Carolina, delivered his address last winter, had <PageNumber num={400} /> ten thousand copies of that address printed; and it would be no more than
                  respectful to the Commissioners from South Carolina, Georgia and Mississippi that a like number, or at least a very few less, should be printed. It would not be treating them with courtesy, after having solicited them to furnish copies of their addresses, to refuse now to publish them. It would be, in fact, an insult to them not to publish them, after they have furnished copies to
                  this body in pursuance of this request. The expense of publishing an extra number of copies is comparatively trifling, inasmuch as the chief expense is in setting up the type. Any extra number, after the first few hundred, will involve but the additional expense of paper and presswork. I trust, therefore, that it will be the pleasure of the Convention to grant what is due to the body
                  as well as to the distinguished Commissioners who have addressed the Convention.
                </p>
                <div className="speaker" id="sp460"><span className="persName">Mr. DORMAN</span>—</div>
                <p className="p-in-sp">
                  I made the motion to lay the resolution upon the table, because I was not possessed of the information to enable me to vote with a proper understanding of the matter. I was willing to carry out the purpose of the Convention indicated by the request made of these Commissioners, and in order to carry out that purpose, I only desire to know whether or not copies of the speeches of these
                  Commissioners have been furnished by them for publication.<span className="note" id="Note50"
                  ><span className="noteLabel">3</span>In the Proceedings of March 4, page 351, Mr. Hubbard is reported to have moved to table the resolution in question; in the Journal, however, Mr. Hall of Marion is stated to have moved the tabling of the resolution. There seems to be no way of reconciling these contradictions.</span
                  >
                </p>
                <div className="speaker" id="sp461"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">They were communicated to the Convention this morning by the Chair.</p>
                <div className="speaker" id="sp462"><span className="persName">Mr. DORMAN</span>—</div>
                <p className="p-in-sp">That being the case, I think it but a matter of simple courtesy to these gentlemen that we should publish their addresses, and such a number as the Convention may think proper under the circumstances. For my own part I do not insist upon a very large number. In respect to the number, I am willing to adopt the suggestion of the gentleman from Petersburg [<span className="persName">Mr. BRANCH</span>] .</p>
                <div className="speaker" id="sp463"><span className="persName">Mr. EARLY</span>—</div>
                <p className="p-in-sp">I made an appeal when this resolution was offered, to my friend from Bedford [Mr. GOODE] to withdraw it, because I saw that it would lead to an interruption of the remarks of the gentleman from Monongalia [<span className="persName">Mr. WILLEY</span>] 
                  . In voting to lay the resolution on the table, I did not vote to reverse the judgment of the Convention, when the resolution was passed calling on these gentlemen for a copy of their addresses. When that resolution was offered and passed, I understood the purpose and intent of it to be to furnish, printed, the remarks and addresses of these gentlemen for the use of the Convention,
                  in order that we might have in a convenient form what they did say and not be obliged to rely upon memory for it. I did not understand it to be for the purpose of circulating these documents over the country in large <PageNumber num={401} /> numbers, to induce the people we represent to entertain different opinions from those we came here to represent.
                </p>
                <p className="p-in-sp">
                  These documents and speeches have already been published, and there are ample opportunities for all who desire to read these speeches. I think we are already paying enough for documents and speeches, made against the purposes that I am sure a majority of the Convention entertain; and I therefore move to substitute in the place of the number, already indicated by other gentlemen, the
                  usual number of documents printed for the use of the Convention.
                </p>
                <div className="speaker" id="sp464"><span className="persName">Mr. GOODE</span>, of Bedford—</div>
                <p className="p-in-sp">The reference made by the gentleman from Franklin [<span className="persName">Mr. EARLY</span>] 
                  to myself, makes it necessary that I should say a word. When he made an appeal to me this morning to withdraw the resolution I offered, I declined to accede to it, because I thought when the speeches were submitted by you, Mr. President, to this Convention, it would be the most suitable opportunity for obtaining the sense of the Convention as to the number of copies which they would
                  require. In addition, I was informed by a friend of the Public Printer, elected by this Convention to do its work, that he desired to know. as early as possible, what number of speeches would be ordered by this Convention. In this connection, I desire to return my thanks to my friend from the city of Petersburg
                 [<span className="persName">Mr. BRANCH</span>] , for his gallantry in submitting the motion to the Convention which he did. I am happy to believe, as a Virginian, that it was not the deliberate purpose of this Convention to treat these distinguished gentlemen with the discourtesy and disrespect, which would have been implied, if the motion to lay on the table had prevailed.</p>
                <p className="p-in-sp">
                  As to the number which the Convention should order, I will simply say that I have received letters from a large number of my constituents desiring copies of these speeches. I hear that these gentlemen had received similar letters. The people feel that they are in the midst of a mighty revolution—they desire light, all the light which they can get, and they desire to know from the
                  lips of these distinguished Commissioners, the accredited representatives of the seceding States, what causes have impelled them to a separation. They desire to ponder well the arguments which have been addressed to the reason and judgment of this Convention, the object of which is to show that our highest material interest, as well as every consideration of sympathy, induces us to
                  cast in our lot with the seceding States.
                </p>
                <p className="p-in-sp">
                  But I will not press this point. The Convention may order whatever number they may think proper. I would prefer a large number myself ; but I must confess that the only solicitude which I have felt upon the <PageNumber num={402} /> subject was—since these gentlemen had complied with the request made by this body to address them—that we should not treat
                  them with any seeming discourtesy.
                </p>
                <div className="speaker" id="sp465"><span className="persName">Mr. JOHNSON</span>, of Richmond—</div>
                <p className="p-in-sp">
                  I desire simply to state the reasons for the vote I shall give upon the question before the Convention. As I understand the fact to be, three Commissioners, one from Georgia, one from South Carolina, and one from Mississippi, were delegated by their respective States to attend this Convention. After they arrived here, by the unanimous vote of this Convention, they were requested to
                  address us whenever they thought proper. At a time, and upon an occasion agreeable to them, they did speak to us, and after they had spoken, a proposition was made, which was unanimously agreed to by the Convention, requesting copies of their several speeches. It does therefore seem to me eminently proper, and almost necessary to the preservation of our own consistency that we should
                  vote for a reasonable number of these speeches to be published. What that number should be, I do not pretend to say, but I do think it should be more than the usual number. I think there ought to be enough to give to the members of the Convention an opportunity of circulating among their constituents a certain number, and I presume that the number recommended by the gentleman from
                  Petersburg
                 [<span className="persName">Mr. BRANCH</span>] , would be sufficient for that purpose and to carry out the purpose we had in view in voting for the publication of these addresses.</p>
                <p className="p-in-sp">I trust, therefore, that it will be the pleasure of the Convention to sustain the [his] proposition.</p>
                <p><span className="persName">Mr. MORRIS</span> proposed to fill the blank with "5,000."</p>
                <div className="speaker" id="sp466"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">There are three propositions for filling the blank. The highest number named is "9,000," and the question will be taken upon that number first.</p>
                <p>The question was taken and it was not agreed to.</p>
                <p>The question then recurring upon filling the blank with "5,000," it was taken and decided in the negative.</p>
                <p>The question was then taken on <span className="persName">Mr. BRANCH</span>'S proposition to fill the blank with "3,040," and it was agreed to.</p>
                <p>The resolution as amended was agreed to.</p>
                <p>On motion of <span className="persName">Mr. ECHOLS</span>, the Convention then adjourned.</p>
              </div>
            </div>
            <div className="day" id="vsc1965.v1.2.17">
              <PageNumber num={403} />
              <h2><span className="headingNumber">1.17. </span><span className="head">SEVENTEENTH DAY</span></h2>
              <div className="dateline">Tuesday, <span className="date">March 5</span></div>
              <p>The Convention met at 12 o'clock, M.</p>
              <p>Prayer by Rev. <span className="persName">Mr. READ</span>, of the Presbyterian Church.</p>
              <div className="section" id="vsc1965.v1.2.17.1">
                <h3><span className="headingNumber">1.17.1. </span><span className="head">CONVENTION OF BORDER STATES</span></h3>
                <div className="speaker" id="sp467"><span className="persName">Mr. J. B. MALLORY</span>—</div>
                <p className="p-in-sp">
                  I beg the attention of the Convention to make an explanation, not a personal explanation as to anything I have said. On yesterday I offered a resolution, which seems to be misunderstood by a number of gentlemen here and elsewhere. It had reference to a Convention of the Border States, and placed Virginia in the attitude of deciding where she was to go. In offering that resolution, I
                  had no ambition to gratify, and I expect to win no laurels. It is due to my somewhat peculiar position in this Convention, and I think it is proper to make an explanation of my motives at the time I offered it yesterday, in order that the people of my county may know what I intended by it. I was elected and sent here a Union man. I am a Union man, and I wish to God that the Union
                  could be preserved for all time. But I was sent here, sir, somewhat upon probation. I was called a last-effort man, and I was in favor of exhausting all reasonable and honorable means to restore peace to our common country. I was in hopes that the Peace Conference would submit some proposition to the people which we ought to take. That Conference has failed. I was under the
                  impression, too, from what was said by members of this Convention, that there was no probability that this State would secede from the Union, and that Virginia ought to take some action. Hence it was that I submitted a resolution that the Border States should go into Convention with a view to concert and unity of action. I did not commit myself to a Central Confederacy.
                </p>
                <p className="p-in-sp">It has been said that I occupied the position of my friend from Bedford [<span className="persName">Mr. GOGGIN</span>] 
                  . I do not know exactly what his position is, but if his position be a Central Confederacy I am opposed to it. I am a Southern man, and if the question were presented to me to-day, where I would go North or South, my destiny is with the South. I love this Union, and I love the government under which we live, because I believe it to be the best government that was ever conceived by
                  the wit of man. We are the happiest people that ever lived, and I love this Union because it is a heritage bequeathed to us by the fathers of the Revolution. Nothing would please my heart so much as to see the broad <PageNumber num={404} /> banner of liberty and independence still spread out to the breeze—to see the stars and stripes still floating over
                  the same Union, under the same Constitution, and in the same glorious Union. But, sir, the time has come when something decisive must be done. This question must be settled, and settled forever. Virginia must take her stand, and my opinion is, that if Virginia should call a Convention of the Border States, the other border States would all go with her, in a solid phalanx.
                </p>
                <p className="p-in-sp">I did not intend to make a speech. Like my friend from Petersburg [<span className="persName">Mr. BRANCH</span>] 
                  , I intend to be a good listener. I am an old man, and have no aspirations in the world beyond the good opinion of my fellow men for good and upright deeds. I intend to be a listener, and not a speech-maker; and I would never have inflicted what I have said upon this Convention, if what I said yesterday had not been misunderstood. I have been advised, by some of my friends, to make
                  this explanation, and I thank the Convention for hearing me.
                </p>
                <div className="speaker" id="sp468"><span className="persName">Mr. COX</span>—</div>
                <p className="p-in-sp">I offer the following resolution for the consideration of the Convention :</p>
                <p className="p-in-sp">Resolved, That the Committee on Federal Relations be instructed to report without delay, a plan for a Convention of all the Border Slave States at the earliest practicable day; also, to report on the subject of coercion by the Federal Government of the seceded States.</p>
                <p className="p-in-sp">
                  I have only one word to say upon this subject. Whatever may be the state of feeling in other parts of the Commonwealth, I know not; but in the region of the country from which I come there is an excited, feverish state of the public mind. Since the rejection of these peace measures by the Congress of the United States, since we have read the Inaugural of Abraham Lincoln, no man can
                  censure our people for having a fevered and excited state of mind. With my friend from Brunswick
                 [<span className="persName">Mr. MALLORY</span>] 
                  , I say it is time for Virginia to take action. The action I propose in that resolution is to call at once a Convention of all the Border Slaves States, that we may consult together in regard to what is best to be done. It is due to our sister Border States that we should consult with them before we take any decided action. I want to consult with them, because I want the wisdom of
                  their counsel in our deliberations, and when action becomes necessary, I want their concert and co-operation in that action.
                </p>
                <p className="p-in-sp">
                  I will make only one more observation and that is in regard to the latter part of the resolution. My opinion has been that the first action taken by the Convention should have been action upon the subject of coercion. We should have let the powers that have departed from Washington know what our course would be upon that subject. It is
                  <PageNumber num={405} /> our duty at once to let this new administration know the position of Virginia upon this subject of coercion. They must know that the moment an effort is made to coerce the Southern States Virginia will back the Southern States. Like my friend from Brunswick
                 [<span className="persName">Mr. MALLORY</span>] , I love the Union, but as much as I love the Union and venerate the memories of the great men who gave us the Union, rather than live in this Union disgraced, rather than live upon terms of inequality I would tear the Union into fragments and trample it under foot.</p>
              </div>
              <div className="section" id="vsc1965.v1.2.17.2">
                <h3><span className="headingNumber">1.17.2. </span><span className="head">FEDERAL RELATIONS</span></h3>
                <div className="speaker" id="sp469"><span className="persName">Mr. WM. L. GOGGIN</span>, of Bedford—</div>
                <p className="p-in-sp">Mr. President, it is with no little emotion, sir, that I rise to address this Convention upon the subject of certain resolutions which I have prepared, and which I shall present to this body.</p>
                <div className="speaker" id="sp470"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">The question is now upon the adoption of the resolution offered by the gentleman from Chesterfield [Mr. Cox] .</p>
                <div className="speaker" id="sp471"><span className="persName">Mr. GOGGIN</span>—</div>
                <p className="p-in-sp">
                  I propose then, sir, to speak upon that question, and by way of modification, I shall propose the resolutions which I have prepared, as a substitute for the resolution presented by the gentleman from Chesterfield. I was about to say, sir, that I rose to address the Convention upon the subject, whether it be of the resolutions which I intend to present to this Convention, of the
                  resolution presented by the gentleman from Chesterfield
                 [Mr. Cox] , or the resolutions of my friend over the way, from the county of Brunswick [<span className="persName">Mr. MALLORY</span>] the subject is one of vast importance to us all.</p>
                <p className="p-in-sp">
                  We cannot to-day, sir, shut our eyes to the fact that those sources to which we looked for relief in this time of our trial and of our difficulties, have entirely failed to furnish those guarantees of security which we had a right to anticipate. Our hopes and expectations have been disappointed. Yesterday it was announced from the Capitol at Washington by him who was inaugurated as
                  President of these United States—and I desire to quote the language exactly as he gave it—that it is his "declared purpose" that "the Union will Constitutionally defend and maintain itself, and that the power confided to him will be used to hold, occupy and possess the property and places belonging to the Government, and to collect the duties on imports."
                </p>
                <p className="p-in-sp">
                  This, sir, was the enunciation made by Mr. Lincoln yesterday, on taking the oath to support the Constitution of the United States. I had hoped, sir, that better counsels would have prevailed, and that he would have pursued a different course to that which he has indicated <PageNumber num={406} /> by his inaugural address, if that address which we
                  received here last evening indicates the purposes and views of the present administration of the Government of the United States. I repeat, sir, that I had hoped that the indications would have been different from those which are now presented to us. But the fact is now plain to us all that Mr. Lincoln has shut his eyes to the condition of the country as it is. It seems to me that he
                  has altogether—or at least to some extent—failed to remember that there are several of the States of this Union as it was, which now no longer constitute a part of this American Union. He has failed to remember—at least in the light in which it is presented to my mind—that here are a people at the South, some seven or eight States, who are struggling for their independence and their
                  liberty, who, I trust, have the will and the power to maintain them, and who, I hope, will gallantly maintain them. I say, sir, he seems to have shut his eyes to the fact that this state of things exists; and yet, in some portions of that inaugural address which he has delivered to the public, he does appear to have some reference to the position occupied by those Southern States;
                  for in another part of his address he says that forbearance on his part to enforce the Constitution will be practiced only "where hostility to the United States in any interior locality shall be so great and so universal as to prevent competent resident citizens from holding the Federal offices."
                </p>
                <p className="p-in-sp">
                  Then, sir, it seems, by the expressions of Mr. Lincoln, that his purpose is not to carry out the operations of that Government of which he is the head, where there is such an organized power or party to resist its operations in the interior, as, by the force of public opinion, has rendered it improper for any resident citizen to take office under him; so as to execute the laws. Thus
                  we of the interior may be said to have a pledge given to us by him of some security ; but he has distinctly avowed in his address, that that protection is placed solely on the ground that the Government shall not have power to execute its laws.
                </p>
                <p className="p-in-sp">
                  Now, sir, as a citizen of Virginia, I look not only to the interests of particular localities in the State, but I think it is my duty, and the duty of all the members of this Convention, to look abroad into every part of her—to the exterior, as well as to the interior localities to which he has had reference. Upon that question—if the combined opposition of the people of the interior
                  is to defeat the operation of the law, the operation of the Constitution when brought to bear in a spirit, as we think, of injustice, of injury, of oppression, and of utter disregard of our rights—I want this Convention to declare that Virginia will extend her protecting care equally to all parts of the Commonwealth, <PageNumber num={407} /> as well as
                  to the interior; I want it to declare here to day that, in this perilous condition of the country, it is the duty of Virginia to stand united, as one man, to defend her interests and honor. You, gentlemen, on this floor, who hear me to-day, you who have known me all my life, and you, sir, who occupy the Chair, and have known me also, you all well know what my devotion to this Union
                  has been. I have declared what my devotion to this Union will be, if it is regarded in the light in which our fathers constructed it. I believe, then, with the gentleman from Chesterfield
                 [Mr. Cox] , and the gentleman from Brunswick [<span className="persName">Mr. MALLORY</span>] 
                  , if I understand the purport of his resolutions, in order that we may present an undivided front, and I think I might appeal to one who occupies a higher position perhaps than any other gentleman on this floor—we are not to endorse the propositions which have been made, but to consider them as having furnished to the country some indications that that might be the ultimate course to
                  which he would resort in a particular given state of affairs in the country. I allude, sir, to the venerable gentleman who is now before me
                 [Ex-President Tyler] 
                  whose days have been made useful to his country, who has just returned from a mission of peace to make a report, in connection with his colleagues, of the operations of that Peace Conference at Washington. It is not my purpose to speak of that question. But I do intend to say—and that is all that I did intend to say, after I have already stated my views before the Convention thatI am
                  with my friend from the county of Chesterfield and my friend from the county of Brunswick, in any attempt to inaugurate a platform upon which there should be, before Virginia takes her position anywhere outside of this Union, a cordial, sincere and earnest cooperation of the border slave States of this Union. That is the position, sir, on which I have planted myself, and from which I
                  do not mean to be driven, when I look to a re-construction of this Union. And I say that when I would I indicate—as I shall by the resolutions which I will presently offer—that it is the duty of Virginia in a confederacy to which the resolutions refer, to take her position outside of this Union, with the confederate States of the South; yet, sir, I mean that she should take her
                  position there with the hope—so far as I can speak her voice
                </p>
                <p className="p-in-sp">of being instrumental, as I trust she may be, in endeavoring to bring about a re-construction of the United States of America. This, sir, would be my course, and this is what I understand the gentleman from the county of Chesterfield [Mr. Cox] also designed to accomplish.</p>
                <p className="p-in-sp">
                  But, sir, while this is my position—while I ardently and most earnestly desire a re-construction of this Union—I do not intend to stand <PageNumber num={408} /> by it, whatever may have been my love and devotion to it as it was, and see given to the world a declaration which must place the new Administration at Washington at war with all the best
                  interests of the people of the South, with all the best interests of Virginia, with all the best interests of the people with whom she is identified, as being slaveholding States of this Union as it was. Tell me, sir, that to collect the revenue by force, under the pretext that the Government of the United States has the right to exercise such power, is not at war with the
                  Constitution; or, if not with the Constitution, at war with facts, when we look at the position now occupied by the people of the South as an independent Republic, and as having been the operation of the United States of America? And, sir, I say that when a President of the United States, who virtually abandons this position, as he does, by telling you that he will execute the law
                  only where he has the power to do it, I then say that it is an abandonment of the power, to all intents and purposes ; and if the want of men to execute the laws is the reason why these laws are not to be executed in the interior of the country, I want all my friends to stand with my friend there from the sea-board
                 [Ex-President TYLER] , and my friend from Princess Anne [Ex-Gov. WISE] , and with you, sir, in defending the interests of the sea-board, and in letting Mr. Lincoln and his Administration at Washington know that there are those, even in these exterior localities, who will not execute the behests of a party, merely for the perquisites held out by that party.</p>
                <p className="p-in-sp">
                  I say, then, I desire to have the opinion of all the border slave States. I desire that we should present an undivided front on this question. You may talk about the doctrine of secession. You may talk about it as being a constitutional right, as having been sanctioned by the Resolutions of 1798-'99, and by Madison's Report; but I tell you I don't choose to stand here and make any
                  such question. I put myself in the place of the fathers of the Revolution in their struggle for independence. I base it upon the right of resistance to oppression upon the last extremity.
                 [Applause.] 
                  And I say the result will be the same. I act under the right which I believe God has given me to defend the country; and I am called a revolutionist. But whether I stand on the doctrine of the right of secession, I care not. In either case, as I said last week before this Convention, the right and power of the people of Virginia will appeal to that flag which waves over it; and I
                  would appeal to the flag of Virginia and to the principles which that flag has always carried upon its folds, and I would say that I would stand there with the most perfect confidence that that flag would be able to <PageNumber num={409} /> protect me, and all those who would rally to the banner when it was unfurled to the breeze.
                </p>
                <p className="p-in-sp">
                  But, sir, I did not intend to detain this Convention. I merely meant to indicate the purposes or position that had been inaugurated by Mr. Lincoln, and to declare—conservative man as I am, Union man as I am, representing an interior county in this State as I do—that I was determined here to stand before these people as one of those who would look to the interests of all the people in
                  all parts of the State, and to ask men to bury party distinctions—to bury those differences of opinion which have divided us heretofore, and let us stand united—presenting a front to the world that shall be invincible whenever, in the Providence of God, we are called to the field to defend the rights and honor of this glorious Old Commonwealth. Now, sir, I beg to read the resolutions
                  which I submit for the consideration of this Convention.
                </p>
                <div className="speaker" id="sp472"><span className="persName">Mr. GOGGIN</span> then read the resolutions as follows :</div>
                <p className="p-in-sp">Resolved, That any attempt on the part of the General Government to use coercive measures for the collection of revenue from imports, at or near any of the ports within the States which have seceded, will be regarded by Virginia as furnishing just cause for apprehension of danger to the whole South, Virginia inclusive.</p>
                <p className="p-in-sp">Resolved, That in the event aforesaid, Virginia, however sincere may be her attachment to the Union as it was, will deem it her duty to aid in repelling any such attempt.</p>
                <p className="p-in-sp">
                  Resolved, That in the hope of restoring harmony to all parts of the Union, and before determining to secede from it, but especially in order to secure the co-operation of the border Slave States in any course she may pursue, the State of Virginia earnestly requests the speedy cooperation of the said States, in effecting with her a plan by which she, with all the said States, may
                  unite themselves with the Confederate States of the South.
                </p>
                <p className="p-in-sp">Resolved, That in the formation of such a Union, Virginia would have a due regard to all the States, and that in such Union she should still cherish the hope of reforming "the United States of America" upon the basis of the present Constitution, so modified as to protect the rights of persons, property and territory, in all time.</p>
                <p className="p-in-sp">
                  Resolved, That all fortified or other places in Virginia, now belonging to the General Government, in the event of the separation of said State from the Union, may and ought to be resumed by her as necessary for the defence of her citizens and their property in particular "localities," as well as for purposes of general defence.
                  <PageNumber num={410} /> Resolved, That while Virginia maintains this position, and while she yet remains a member of the Union, during her deliberations, she will assume no hostile attitude to the General Government, but will be prepared at all times to repel any assault which may be made upon her.
                </p>
                <div className="speaker" id="sp473"><span className="persName">Mr. GOGGIN</span>continued—</div>
                <p className="p-in-sp">
                  Thus, Mr. President, I have presented, in a very imperfect manner, these resolutions, hastily prepared this morning, without any concert with any human being on the face of this earth. I have offered them upon my own responsibility; and I take occasion to say, that not a single member of this Convention, except myself—none of my friends from Piedmont country, nor from Trans-Alleghany
                  country, nor from Tide-water country—are responsible for what is contained in these resolutions, or for the manner in which they are presented to this Convention, or for the fact that they were presented at all. I stand here alone responsible for whatever error there may be in presenting them, while I claim nothing of any merit, if there be any, which may result from it. I am aware
                  that they proceed from an humble source, but I thought it was due to that position which some gentlemen understood I occupied, and which I now occupy, that I should have occupied the attention of this Convention in presenting my views a few days since ; and I wish to say that I do not mean that anything which I have said now shall conflict with the position I there assume.
                </p>
                <p className="p-in-sp">
                  I still believe in the Union as the brightest heritage that was ever bequeathed to a free people; and I believe that that Union is destined, in its onward course, to a great career of glory under the Constitution, as it was intended by our fathers, and will yet make the proudest and happiest people on the face of the earth. That this may be so, sir, I shall earnestly invoke the
                  blessings and favor of Him who has made us all, and who directs the affairs of nations as He does the affairs of men. I stand here then, sir, not to vindicate myself because I occupy the position here which I did at home; I told my constituents that I had no platform upon which I would be willing to be placed; that my platform was the platform of my country, that it was the honor of
                  Virginia, and that there I would stand and there I would ever be found.
                </p>
                <div className="speaker" id="sp474"><span className="persName">Mr. DORMAN</span>—</div>
                <p className="p-in-sp">I would like to have the resolutions reported.</p>
                <div className="speaker" id="sp475"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">The Clerk will report the substitute.</p>
                <div className="speaker" id="sp476"><span className="persName">Mr. COX</span>—</div>
                <p className="p-in-sp">Those resolutions must be offered as a distinct proposition ; they cannot be offered as a substitute. My resolution goes, under the rule, to the Committee on Federal Relations.</p>
                <PageNumber num={411} />
                <div className="speaker" id="sp477"><span className="persName">Mr. GOGGIN</span>—</div>
                <p className="p-in-sp">I will merely remark that, at the proper time, I will offer those resolutions, so that they can be disposed of by the Convention.</p>
                <div className="speaker" id="sp478"><span className="persName">Mr. HARVIE</span>—</div>
                <p className="p-in-sp">Mr. President, I have but a few words to say, and I would not say them except that I am occupied elsewhere in the consideration of the very question now before the Convention. I heard the concluding remarks of the gentleman who has just taken his seat [<span className="persName">Mr. GOGGIN</span>] 
                  , and to those remarks I give my hearty concurrence, and I wish to tender to him my heartfelt thanks for having made them. He said that he would stand by Virginia; that the platform he occupied was to maintain the honor and interests of Virginia. I go with him, in that position, hand and heart, and, in the language of his resolutions, I will never consent to put the honor and
                  interests of Virginia in the custody of any other power than that of Virginia. I will go with him when Virginia is determined to maintain the position she means to occupy when she has taken her position upon this question, in the convulsions of this glorious empire. I will go with him to seek aid and comfort and help in other directions; but I insist upon it that Virginia shall
                  deliberate, that Virginia shall determine, without interference, without complication, what she will do in this particular. I will never call upon those border States to help define what Virginia shall do. When Virginia has decided what she shall do—and I trust in God that that decision will be to maintain the rights of the whole South—those in and those out, those upon the border
                  and those in the centre—then, sir, I will call upon the border States to stand by her. Never will I do it until then.
                </p>
                <p className="p-in-sp">
                  But, sir, that is not what we have to decide to-day; that is not what this Convention ought to decide to-day. When we in this Convention have been deliberating cautiously and calmly and coolly what ought to be done, we have just heard the trumpet sound of war thundering into our midst, by our servant not our master, at Washington. We have been told by this servant that he will use
                  the power that we have given him, to coerce into submission to the behests of the Federal Government the seceded States of the South ; and then I suppose comes our turn next. Undoubtedly a declaration of war. When the Federal Government has exceeded the limits of propriety and its limits of power, I am disposed to show that Federal Government that Virginia will clothe herself in the
                  panoply of her arms to maintain her rights against any unwarrantable power whether it comes from Washington or elsewhere.
                </p>
                <p className="p-in-sp">
                  That is what we ought to do to-day, sir. That is what I have come <PageNumber num={412} /> from that committee room to insist that we shall do; that we shall declare here and now that we do not, under any circumstances, mean to stand by and suffer our sister States to be coerced; that we do not mean that this Government shall do that after those States
                  are removed out of the jurisdiction of the Federal Government, which we would not submit to if we were similarly situated ; that we here proclaim, that the very moment the power is brought to bear against those States, Virginia will come forward and stand by them. And we ought not to delay that declaration; we ought to act at once. I call upon this Convention to act now. We have the
                  Legislature of the State convened at present; we are now ready for action; and we should call upon that Legislature to do what they ought to have done without the necessity of being called upon by us. They have delayed because they awaited our action. We ought to call upon them—here, now, to-day—to put this State in an attitude where she can repel invasion and maintain her rights,
                  whether they be assailed indirectly or directly by the Federal Government. We should pass resolutions to-day--and if some other gentleman does not offer a resolution I will offer it—instructing the Legislature of Virginia to pass the necessary bills, appropriating any amount of money that may be necessary to enforce her determination in this particular, and not only to appropriate
                  money, but to devise a plan to raise an army and to put this army in an attitude where it can be felt by our oppressors, whether they appear in the guise of friends or of enemies. These are my views; and I trust that this Convention will not commit itself to any half-way course, but that it will do what it can and ought to do now.
                </p>
                <div className="speaker" id="sp479"><span className="persName">Mr. WALTER D. LEAKE</span>, of Goochland—</div>
                <p className="p-in-sp">Do I understand that the resolutions offered by the gentleman from Bedford [<span className="persName">Mr. GOGGIN</span>] are intended as an amendment?</p>
                <div className="speaker" id="sp480"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">They were not. The question is upon the resolution offered by the gentleman from Chesterfield [<span className="persName">Mr. COX</span>] .</p>
                <div className="speaker" id="sp481"><span className="persName">Mr. LEAKE</span>—</div>
                <p className="p-in-sp">Then, sir, I beg leave to offer an amendment to the resolution offered by the gentleman from Chesterfield. I move to strike out all after the word "Resolved," and insert the following:</p>
                <p>Resolved, That the Committee on Federal Relations be instructed to bring in an ordinance setting forth the following facts and determinations of Virginia in connection with the present threatening aspect of public affairs :</p>
                <p>
                  That as Virginia was the foremost to make sacrifices for the Union under the Constitution, so, to preserve it, she has practiced the greatest self-denials; never seeking or receiving an exclusive benefit; she has never <PageNumber num={413} /> infringed the right of any State or section; jealous of the integrity of the Constitution and the equality of
                  the States, she has lived up to the obligations imposed upon her by the Federal compact. That, on the other hand, the Northern section has disregarded many of its obligations, and attempted to set aside some of the compromises made between the two great sections of the Confederacy, without which, no Union could ever have been formed. Hatred has been substituted for that fraternity
                  upon which these compromises rested for vitality; and power is claimed for a sectional majority, utterly at war with the spirit and letter of the compact, and subversive of our safety, our well-being and our rights. Equality of rights in the enjoyment of the common property is denied us, aggressions are made upon our soil, the powers of a common government are claimed as the lawful
                  means for our oppression, and the hedging in our rights. All this opposition to our civilization, all this hatred of our domestic institutions, and all this enmity to our peace, are banded together in the formation and upholding of a great sectional party that has elected a President upon the avowed principle of hostility to the institutions of the South, and upon the pledge to use
                  the power of the Government for their ultimate extinguishment; forgetful that the Union was formed for "establishing justice and ensuring domestic tranquility." These violations of the integrity of the compact have given rise to other great evils now impending over us, which menace the first principles, the very foundations of free institutions, and which threaten the overthrow of
                  the right of sovereign States. They have given rise to the claim of right upon the part of sovereign States in one section, to coerce sovereign States of another section into a Union to which they will not assent; and to the assertion of the doctrine that resistance to violations of the terms of our federal compact is treason to the claims of a sectional majority; and which have led
                  to the armed occupation of the seat of the common Government by an armed force, with friendly purposes towards the one section, with hostile feelings towards the other; and which too have led the authorities at Washington to make the fortresses of Virginia to frown upon her while she was showing a determination to exhaust all the resources of conciliation and compromise. These
                  outrages of a sectional majority have broken the Constitution, driven seven States out of the Union, dissolved the Union of our fathers, and are now substituting another Union in its place. Virginia is no party to any such new Union ; and she demands a re-construction, to secure her and the whole South from any future outrage. In this reconstruction, she ought to stand with the South
                  in the assertion of her rights, and she <PageNumber num={414} /> ought to occupy no position in connection with the North, in the state of things brought about by Northern aggressions, which would cripple her power for her own defence and prevent her from aiding in maintaining the rights and the equality of all the States. And that the said Committee
                  especially set forth the fact, that in consequence of the secession of the Southern States and the hopeless condition of New England fanaticism, the blind hate of Black Republicanism, and the coercive policy indicated by the President of a dismembered Union, there is no hope of an amendment of the Constitution that can be satisfactory to Virginia in the constitutional way, and that
                  the only mode, in the circumstances which now surround us, to secure any union in which the rights of Virginia would be safe and protected, is for Virginia to re-assume all the powers she delegated to the General Government, and to declare her independence ; and then to call into a Convention all the slaveholding States, to determine what shall be the new construction necessary for
                  their rights and protection in a Confederacy of slave States alone, or of the slave States and such free States as are willing to come into a Union under this new Constitution with the slave States.
                </p>
                <div className="speaker" id="sp482"><span className="persName">Mr. AMBLER</span>—</div>
                <p className="p-in-sp">I rise for information from the Chair.</p>
                <p className="p-in-sp">As I understand the original resolution it is a resolution of instruction. I do not understand the amendment offered by the gentleman from Goochland [<span className="persName">Mr. LEAKE</span>] , to be of a similar character.</p>
                <div className="speaker" id="sp483"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">The Chair understands both to be resolutions of instruction.</p>
                <div className="speaker" id="sp484"><span className="persName">Mr. LEAKE</span>—</div>
                <p className="p-in-sp">What do I understand the Chair to say?</p>
                <div className="speaker" id="sp485"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">The Chair has informed the gentleman from Louisa [<span className="persName">Mr. AMBLER</span>] , that the original and amendment were resolutions of instruction.</p>
                <div className="speaker" id="sp486"><span className="persName">Mr. LEAKE</span>—</div>
                <p className="p-in-sp">Is it not in order, sir?</p>
                <div className="speaker" id="sp487"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">The Chair decides that it is in order.</p>
                <div className="speaker" id="sp488"><span className="persName">Mr. LEAKE</span>—</div>
                <p className="p-in-sp">Then I propose to offer some remarks on the subject of these resolutions.</p>
                <p className="p-in-sp">
                  Mr. President, two sentiments have been in conflict in the bosoms of the people in reference to the condition of our national affairs. Sir, there is among them an inherent love of the Union—a Union formed by the blood of patriots and by the efforts of her best citizens; and there too, beyond that and before that, is the love of equality, is the love for all the rights of all the
                  States. And, sir, the facts which have been developed for the last few years have satisfied the South—have satisfied Virginia that to maintain a love of equality, to maintain the <PageNumber num={415} /> rights of all the States in this Union, would bring up this conflict to that sentiment, to that attachment which has been revered by all.
                </p>
                <p className="p-in-sp">
                  But, sir, the course of Northern fanaticism, the strides which a sectional party has made towards the usurpation of the power of the Federal Government, has brought about a state of things when the people of Virginia should no longer hesitate as to what part they shall take in the conflict that is now going on between the rights of the South and a sectional majority which is claiming
                  the power of the Federal Government for the purpose of advancing their selfish interests, and not for the purpose of saving the Constitution or advancing the rights and interests of all.
                </p>
                <p className="p-in-sp">
                  Mr. President, the devotion to the Union which exists among us, has made the people of Virginia anxious that all the means of conciliation and of compromise should be used for the purpose of preserving that Union, if it could be preserved consistently with our rights, consistently with our honor, consistently with our safety. And, sir, if the people of Virginia have been anxious that
                  our rights shall be so recognized by this Northern majority, that it would not become necessary for them to resort to any changes or measures to secure their rights and prevent them from being usurped by a Northern majority, have not passing events satisfied them that it is utterly impossible to secure our rights in a Constitutional way?
                </p>
                <p className="p-in-sp">The Constitution, it is true, as was stated by the gentleman from Bedford [<span className="persName">Mr. GOGGIN</span>] provides the means for its own amendment. But it is now utterly impossible that that Constitution can be amended in the way pointed out by any resolutions which have been offered.</p>
                <p className="p-in-sp">
                  Sir, this Northern sectional majority headed by Lincoln, Seward and Chase, are unwilling to acknowledge the right of secession. They plant themselves upon the platform that no State in any case whatsoever, has a right to secede from this Union ; and so far from acknowledging the right of secession, so far from acknowledging the propriety of the conduct of any of these Southern
                  States, the President of the United States has just proclaimed that he will exercise all the power vested in him by the laws of Congress in holding, in occupying, in possessing the public property within the seceded States, and in the collection of the revenue upon imports to the ports of these States.
                </p>
                <p className="p-in-sp">
                  Sir, to be allowed to collect the revenue in the seceded States, and to hold the forts in those States, is all that the Black Republicans ever will desire the President of the United States to demand. If the President of the United States shall do this ; if the seceded States will pay tribute to Black Republicanism; if the Southern States are so lost
                  <PageNumber num={416} /> to all the principles of true independence as to be unconscious of their just rights, and their equality as a nation, they have indeed played their part in this great political arena to little advantage. If they have not been in earnest in the assertion of their rights, then, sir, they will allow the President of the United
                  States to use the means under his control for the purpose of taking possession of the forts, and of collecting the revenue. But, sir, if they are true to themselves, if they are true to the principles which they have advocated, if they are true to their professions of loyalty to equal rights, they will resist, to the last extremity, any effort upon the part of the Federal authorities
                  to hold these forts, or to collect the revenue in their ports, either in the harbor or at sea.
                </p>
                <p className="p-in-sp">
                  Sir, there was a time in Virginia when this doctrine of secession was considered a doctrine to which Virginia clung. It seems now, sir, that the time has come when this doctrine is to be denounced—it is to be regarded as something worthless, or at least something that is calculated to bring ruin and disaster upon us. I shall not enter into a discussion of the right of secession. The
                  day for the discussion of that question has passed. Whether it be a constitutional or revolutionary right, six or seven States of the South have already exercised that right, and Virginia will exercise it if she thinks it necessary for her safety and for the protection and maintenance of her rights. She will exercise that right regardless of consequences—she will stand upon the
                  platform of inalienable right—to maintain her honor and drive back any invasion that may be made upon her.
                </p>
                <p className="p-in-sp">
                  Hence, sir, if the Northern States, who are in a large majority, will not acknowledge the right of secession, will not recognize the independence of those States that have withdrawn from the Union—how can you maintain the Constitution of the United States? If there was a decision upon that point if the Northern majority was to surrender to us these things to which we are entitled—for
                  we demand nothing to which we are not entitled—if they are unwilling to recognize the independence of these States, it is utterly impossible that a constitutional number can be obtained to ratify any amendment that may be proposed to the present Constitution. It is necessary that twenty-five States should vote for the amendment before it could become part of the Constitution. Does
                  anybody dream that any Southern State will vote for any amendment that will meet with the approval of this Northern majority? That, sir, is out of the question. I have no idea that any Southern State would record such a vote.
                </p>
                <p className="p-in-sp">
                  Sir, do not the occurrences which have taken place at Washington <PageNumber num={417} /> within the last two days satisfy us that this Northern majority is unwilling to surrender to the South its just demands; is unwilling to acknowledge any amendment to the Constitution that would satisfy even the most submissive in Virginia? Sir, so far as I can
                  learn anything of the sentiments of the people of this State, they are not willing to accept anything less than the Crittenden resolutions. And these have been spurned by the Black Republican majority in the Senate of the United States.
                </p>
                <p className="p-in-sp">
                  Sir, we are deceiving ourselves; we are deceiving the people of Virginia that are looking to us for prompt action, when we place ourselves upon the platform that the Constitution can be amended in a constitutional way. How is it to be done, sir? It is true, the Peace Congress, initiated by Virginia, after a long struggle, has adopted a proposition which has been offered to the
                  Congress of the United States and to the world; but the Congress of the United States has not deigned to treat even that with any kind of respect. Neither the President of the United States, the Senate, nor the House of Representatives have considered that the action of the Peace Conference was worthy of their respect or consideration. It seems to me, sir, that the proposition of the
                  Peace Congress, far from insuring the rights and interests of Virginia, rather involves a surrender of these rights which she now undoubtedly has, so that, so far as that Congress is concerned, it has ended in nothing.
                </p>
                <p className="p-in-sp">
                  What then will you have Virginia do? Will you have her running about these Northern States with this peace proposition in her hand, begging the North to allow her to remain with them in the Union upon the terms of this peace proposition? Would you have Virginia so far forget the high position which she occupies? Would you have Virginia so far forget the renown connected with her
                  name? Would you have Virginia so far forget the duties which she owes to her citizens? Would you have Virginia so far forget her constitutional rights, for which she has always contended, as to go to the North on bended knees and beg Seward and Chase to condescend so far as to recommend the people of the North to adopt this peace proposition? I do not believe that there is a man in
                  Virginia who would have the old mother to occupy a position of that sort. She has stood up nobly in behalf of peace, demanding nothing but equality, and yet, sir, her demands have been denied her; and every effort which she has made for conciliation and compromise; every effort which she has made to preserve the Union has, sir, been treated with contempt by that Northern majority
                  which controls the reins of this <PageNumber num={418} /> Government, that majority that is determined to use this Government for the advancement of their own interest and prosperity.
                </p>
                <p className="p-in-sp">
                  Sir, just after this effort thus made upon the part of Virginia to secure her rights; just after this effort upon the part of Virginia to secure the rights of her sister States of the South; just after this effort upon her part to secure the rights and equality of all the States; just as Virginia was making every effort in her power to compose the difficulties which surrounded us,
                  even before the members of the Peace Conference could fairly have returned home—notwithstanding that the Virginia Convention stood here delaying action in the hope that something might emanate from the North which would give promise of peace, and showing every sort of determination to demand nothing that was not right, to insist upon nothing that was wrong, the President of the
                  United States, in the inaugural address which he delivered on yesterday in Washington, utters sentiments which breathe war and revolution, and treats with contempt the declarations made by the Legislature of Virginia almost unanimously—that any attempt by the Federal Government to coerce the seceded States would be met with resistance. At the very time that Virginia is occupying this
                  position that was calculated to disarm her adversaries—a position that might cause her to be kindly and respectfully treated—this President of the United States pledges himself that the odious platform upon which he was nominated—the Chicago platform—should be his law.
                </p>
                <p className="p-in-sp">He uttered sentiments which are not only calculated to excite and arouse Virginia to resistance, but which will excite every lover of freedom to resistance everywhere.</p>
                <p className="p-in-sp">
                  Sir, in this emergency what is the duty of Virginia? Shall she be content with mere resolutions? Is that what is demanded of Virginia at this crisis? Why, sir, for the last twenty years she has been passing resolutions of resistance to the death, and, one by one, her resolutions of resistance have been disregarded ; one by one these resolutions have been treated with contempt by this
                  Northern majority; and Virginia has not yet placed herself in that attitude of resistance which her own resolves demanded that she should have assumed long ago. Why was this Convention called? What was it called for? Was it merely for the purpose of passing resolutions? Was it not because the sentiment was deep-seated in the bosoms of the people that they should take some action? And
                  do you now propose merely to pass resolutions that any effort at coercion will be met with resistance?
                </p>
                <p className="p-in-sp">
                  There is no time now to take that stand. Lincoln has proclaimed that he will coerce the seceded States. Sir, the question does not arise <PageNumber num={419} /> whether the collection of the revenue and the re-occupation of the forts is coercion or not. I believe the common sense of the people of Virginia understand that the collection of the revenue
                  by the Federal authorities from an unwilling people, and the occupation of the forts against the remonstrance of the people, is coercion. It is so understood by the people, and whether it is or not, it is an act they will all resist, you may call it by what name you please.
                </p>
                <p className="p-in-sp">
                  Then, sir, it is necessary that Virginia should place herself in a position to show that she means to carry out the resolves which she has heretofore made. The President of the United States says that he means to collect the revenue and occupy the forts. Are you going to wait until he does collect the revenue and occupy the forts before you carry your resolutions into practice? Sir,
                  is not his declaration enough? Is it to be supposed that Abraham Lincoln has used this language in his Inaugural merely for the purpose of deceiving the people of the country, and that he does not mean to carry out his threats of coercion? If such had not been his policy, he would have used language of conciliation; and the fact that the President of the United States has used
                  language of this character, in such a document as his Inaugural, is conclusive evidence of his intention to carry out the policy which it proclaims.
                </p>
                <p className="p-in-sp">I am satisfied, sir, that Virginia never will submit to such a policy. The Legislature has almost unanimously resolved that she shall not, and I ask if this Convention will not now maintain that position?</p>
                <p className="p-in-sp">I have heard it said, but not in this Convention—I have heard in the city, the sentiment advanced by some that the collection of the revenue and the occupation of the forts are not coercion. That Virginia will regard that as coercion, that this Convention will so regard it, I entertain the strongest belief.</p>
                <p className="p-in-sp">
                  Then what are we to do? We ought at once to take our position with our sister States of the South. We should at once call into consultation all the slave States, and not the border slave States alone, as gentlemen here would have us do. Let Virginia take this position, and rally not only the border slave States, but all the slave States ; and, sir, she will then establish some fixed
                  principle of action, which will give stability to trade, and afford a guarantee of peace and security against aggression. By this means, something will be done that will compose the troubles which now disturb us ; something will be done which will bring some of the conservative Northern States into the Confederacy, that are willing to unite with us in the hope—a hope
                  <PageNumber num={420} /> which I feel assured will be realized—of enjoying perfect security and happiness.
                </p>
                <p className="p-in-sp">
                  The question now presented to this portion of the Confederacy, is whether we will go to the North, or whether we will go to the South. Disguise it as we may, that is the only question that presents itself. Why, sir, would we decline to call the seceded States into a Convention with the border States, because these States seceded without consulting us? I will not undertake to discuss
                  this question, nor to refer to the causes which led these Southern States to secede. In my opinion their action was fully justified by circumstances, and the provocation was such as might well force such a resort. Why should we remain in the Union for the purpose of consulting with these border slave States? Why not take a position outside of the Union in which we can maintain the
                  doctrine that we have advanced, namely, that Virginia will resist coercion if attempted.
                </p>
                <p className="p-in-sp">
                  It is necessary that Virginia should take that position if she is determined to carry out her resolutions of resistance. If she does not mean to have any question raised as to the treason of her citizens; if she means to engage in hostilities with the General Government, and to put this question beyond cavil or doubt, she ought to be in a position in which she can command the aid of
                  her sons without subjecting them to the penalties attaching to treason, in case of arrest.
                </p>
                <p className="p-in-sp">
                  I understand that some gentlemen are objecting to our uniting with the cotton States in any effort of this sort, because they say in this there is no guarantee that the cotton States would be faithful to any obligation that they would enter into with us. Have the States that have gone out of this Union ever done any thing to violate the Constitution of the United States? Have they
                  ever violated any pledges which they have entered into with the Northern States? Have not the Southern States been faithful to their Constitutional obligations? Have they done anything to forfeit our respect? Have they done anything to forfeit any confidence we might repose in them, as to their fidelity to their obligations to us? I was astonished to hear that argument coming from
                  gentlemen who said nothing about the faithlessne5 s of the Northern portion of this Confederacy. Are we to remain in the Union with the North, who violated all their constitutional obligations? Who time and time again, trampled upon the plainest provisions of the Constitution, and who \disgraced the rights of the South? or should we unite with our Southern brethren for the purpose of
                  forming a Constitution, under which all can live in peace, security, and contentment? <PageNumber num={421} /> We are met with the argument that in as much as these States seceded from the Union, they would secede from us. Should we form a Union with them? Sir, they have only seceded from oppression, after the North had overthrown the Constitution which
                  was the only bond of Union ; they had seceded only when a hostile sectional majority had obtained possession of the Federal Government, for the purpose of aggrandizement and the ultimate destruction of their cherished institutions. Are we to be met with an argument of that sort, implying a want of confidence in the Southern States ; who never violated any contract, but who stood firm
                  to the obligations of the Government. Indeed, the whole history of that people show them to be people of the most exalted integrity of character, who have always sacredly observed every obligation, moral, social, and political.
                </p>
                <p className="p-in-sp">
                  Why should we remain longer in connection with a sectional majority, who have violated all the obligations that are due from them to us, and who propose to use all the power of the government for the purpose of coercing unwilling sovereign States back into the Union, which is hateful to them, and which they by their acts have dissolved? Although these States have seceded from the
                  Union, I deny that they have broken the Union. The Constitution was the only bond of the Union. That being broken on the part of the North, the Southern States had the right to resume their original powers as free and independent States.
                </p>
                <p className="p-in-sp">
                  The argument was used by a distinguished gentleman upon this floor against Virginia seceding at all events at this time—and it was generally asked, how is Virginia to get to the Southern Confederacy, with Tennessee and North Carolina between us? If my friend who made that argument had only gone back to the history of the formation of the present Constitution, he would have remembered
                  that Virginia and South Carolina were in the Union some considerable time before North Carolina came in. She had always been slow in her movements, and I suppose the idea never presented itself to the Virginia statesmen who adopted the Federal Constitution, or to the South Carolina statesmen who came in to assist in the election of Washington as the first President of the United
                  States, that they were offering any insult or indignity to North Carolina, because they did not wait before they adopted the constitution to see what North Carolina would do. North Carolina and Rhode Island did not come into this Union until after Washington had been elected and inaugurated as President. After that they ratified the constitution and became members of this
                  confederacy. North Carolina and Rhode <PageNumber num={422} /> Island, if they had remained together under the old articles of confederation, in order to have got together, would have had to cross the line of Virginia and several other States.
                </p>
                <p className="p-in-sp">
                  We propose to take the action I have suggested, because we believe it to be proper and right, and because we believe that the interest of the South demands it, and we believe that the same interest which actuates and governs our people can actuate and govern those who are similarly situated. At all events we ought to take care of our own people and our own interests, leaving it to
                  time and to the judgment and reason of our fellow-citizens of other States to take such courses as they should deem proper.
                </p>
                <p className="p-in-sp">
                  But gentlemen have alleged that we ought not to have any affiliation with the Southern Cotton States, because they have gone out of the Union without consulting us as to their policy. And yet it does seem to me, if it be right, proper and consistent with our interests to take this position, we ought to take it independently of the action of other States. I might say that the other
                  States had desired a consultation with us, that we had refused it, and that in consequence of our refusal they had taken separate State action. Is the argument which gentlemen have addressed to you upon this point one that should influence grave and deliberate men in their action? I suppose that the events and facts which daily present themselves, and which mould and shape our
                  action, are facts and circumstances over which we have no control. We have had no control in the formation of those facts, but because these facts rise up before us to coerce and influence our action, are we to say that we will not be influenced and governed by them, because we have not had control of the operations by which they have been formed? If it be the duty and interest, and
                  if it be consistent with the honor and rights of Virginia to take this position, she ought to take it regardless of what may be the position of the other States. But let Virginia take it, and the very same conditions of interest, the very same conditions of prudence, the very same facts that appeal to our judgment, will appeal to our sister States. I believe, if Virginia makes a very
                  prompt movement in this direction, you will have, in a very short time, a united South, and if we act promptly, you will prevent the inauguration of hostilities by the General Government. And that seems to me to be a reason which should peculiarly affect Virginia at this time. It is evident that the President of the United States, if he is true to the principles which he has
                  promulgated in his Inaugural Address, will, in a short time, intitiate hostilities in the Southern States. He will attempt to re-take Fort Moultrie and the <PageNumber num={423} /> other forts. If we sit idle here and pass resolutions saying that we will resist if the President of the United States initiates hostilities, without taking the position that
                  we mean to be true to that declaration, hostilities will be inaugurated, civil war will be brought on, and blood will be shed; and I believe if blood is shed, it will be in consequence of a failure upon the part of this Convention to act promptly in letting the Administration at Washington understand, that the sentiments and feelings of the South are utterly opposed to any coercive
                  measures on the part of the Federal Government. Is not that a consideration that should rouse us to action? If we can, by taking decided action now, prevent the United States Government from inaugurating hostilities, from commencing civil war, and from the shedding of the blood of any of the citizens of the Southern States, ought we not promptly to act, unless it be utterly
                  inconsistent with our respect for our own rights to take that position.
                </p>
                <p className="p-in-sp">
                  Are we to remain in the Union? Are we to remain in this Union and allow the power of Virginia to be used for the purpose of compelling the people of any other Southern State to come back into the Union? Is the power of Virginia— that power which she has delegated to the General Government—to be used by the powers at Washington for crippling and destroying any of her Southern sister
                  States? Are you willing to stand by and say that the aid which you lent to the General Government when you became one of the States of this Union shall be used for the purpose of coercion—for the purpose of bloodshed?
                </p>
                <p className="p-in-sp">I do not think it necessary to answer many of the arguments which have been urged by our friends upon the other side against separate State action and against secession. The state of affairs now does not allow us to stop for the purpose of considering the many objections which have been urged against this right of secession.</p>
                <p className="p-in-sp">The gentleman from Monongalia [<span className="persName">Mr. WILLEY</span>] 
                  , said yesterday that we ought to pause before we joined the Southern Confederacy, because it would require an enormous expenditure of money and an enormous outlay of capital on the part of the Southern States to defend the Northern border. And he told us that according to an estimate which had been furnished him by some military gentleman, it would take $100,000,000 to place the
                  Northern border in a state of defence, and to prevent Virginia from being encroached upon by these Northern States; and this declaration was made upon his part with the full knowledge that there is now in Pennsylvania and Ohio that state of enmity against us which would make necessary this large <PageNumber num={424} /> expenditure of money in order to
                  defend ourselves against hostile incursions upon our border. The argument is conclusive and of itself unanswerable, that there exists upon the part of these Northern people that enmity to our institutions which is utterly inconsistent with the idea of our remaining longer in the Union ; for that enmity and that feeling which now exists upon the part of the Northern people, will,
                  under the forms of the constitution, be used for the purpose of destroying our institutions, circumscribing our rights and bringing about that state of feeling which would inevitably end in our degradation.
                </p>
                <p className="p-in-sp">
                  Why, Mr. President, are we to remain any longer in our present doubtful position? The interests of Virginia and the interests of the South demand that we should act promptly one way or the other. If we are to go North, let us know it; if we are to go South, let us know it. It is this state of uncertainty that has produced all the mischief that now exists in our commercial affairs.
                </p>
                <p className="p-in-sp">
                  If we are to be kept in this state of uncertainty and doubt, if we are to remain here halting and hesitating as to the position which we are to assume—that hesitation and halting will work greater ruin to Virginia, and her material interests than any other course we can pursue. We should at once take a decided position. The condition of the country requires that we should do this.
                  The threatening aspect of public affairs demands it at our hands.
                </p>
                <p className="p-in-sp">
                  I do not desire to detain you any longer. The only thing I desire is that the Convention of Virginia will act at this particular time when our adversaries are using all the powers of a common Government to effect our ruin and degradation ; when they are promulgating sentiments directly in conflict with the sentiments of Virginia; and when they are proclaiming principles that are
                  utterly inconsistent with our peace. Now that principles have been proclaimed which must inevitably initiate civil war, it is time for Virginia, not only to declare what she will do but to take such steps as will show that she means to place herself in the front of resistance to all the encroachments which may be made upon us. Let Virginia do that. Let Virginia show that sort of
                  determination. Let there be no doubts as to the position which she intends to occupy. Let the world understand that she places herself upon the right. Let her announce her disposition to encroach upon nobody, and that she will never allow any encroachments to be made upon her or upon her sister States, and we may do something to drive back that tide of fanaticism which is now rolling
                  on towards the South, and which, if not resisted, will inevitably end in the overthrow, not only of our institutions, but in <PageNumber num={425} /> blasting the best hopes of humanity. It does seem strange to me that such sentiments as are promulgated by the President of the United States in the last 24 hours have not called into existence all the
                  resistance of our nature ; I believe that the people of Virginia and of all parties will not submit to such an encroachment upon our reserved rights as that which has been threatened by those now in authority at Washington. They will lend all their power for the purpose of driving back the invaders and maintaining the blessings which have been secured to us by the Constitution which
                  these very men have threatened to destroy, and which they are now trampling under foot by the course they are pursuing.
                </p>
                <div className="speaker" id="sp489"><span className="persName">Mr. HARVIE</span>—</div>
                <p className="p-in-sp">I submit the following as an amendment to the amendment of the gentleman from Goochland [<span className="persName">Mr. LEAKE</span>] :</p>
                <p>
                  Whereas, it is now plain that it is the purpose of the Chief Executive of the United States to plunge the country into civil war by using the power "to hold, occupy and possess the property and places belonging to the Government, and to collect the duties on imports," in all the States, as well those that have withdrawn from, as those that have remained in the Union; and whereas, the
                  State of Virginia will resist such exercise of power with all her means; therefore be it
                </p>
                <p>
                  Resolved, That the Legislature of the State be requested to make all needful appropriations of means and provide the necessary forces to resist and repel any attempt on the part of the Federal authorities to "hold, occupy and possess the property and places" of the United States in any of the States that have withdrawn or may withdraw from the Union, or to collect the duties on
                  imports in the same.
                </p>
                <div className="speaker" id="sp490"><span className="persName">Mr. DORMAN</span>—</div>
                <p className="p-in-sp">
                  I am unwilling to vote upon any propositions before the Convention until I have had an opportunity of knowing fully what they are and to what they tend. It is evident to the members of the Convention that propositions brought in here in this hurried and impromptu manner, cannot receive that proper consideration at our hands which will enable us to give upon any of them a well
                  considered vote.
                </p>
                <p className="p-in-sp">So far as the proposition of my friend from Chesterfield [Mr. Cox] 
                  is concerned—it being simple in its nature and concise in its terms—the inclination of my mind was to support it at once. But for one, I am unwilling to be flurried about any thing, and I desire to call upon the members of the Convention, who wish that any action we may take with regard to these important matters, shall have that potential weight and influence that it ought to have
                  before the people of Virginia, shall be calm, cool and deliberate. I shall not be moved by wind of doctrine <PageNumber num={426} /> from Washington or elsewhere. I shall not be shaken or driven into any position upon my part, as a member of this Convention, by the words of the man who now sits in the Chair at Washington or by the words of anybody else.
                  This Convention, it is true, is but a recommendatory body. It has been said by an eloquent gentleman in discussion upon this floor, that it was emasculated and deprived of all its power, either for good or evil. But I do not so regard it; I do not so regard the position which we occupy before the people of Virginia and the people of this Confederacy. On the contrary, I know and feel
                  that whilst the people have determined that they will hold in their hands the ultimate decision of the points presented to us by them, yet the recommendations of this Convention, composed as it is of the best talent of the State, will have a potential weight. It is therefore only the more necessary upon our part, before we put our foot down, that we should be sure we put it down in
                  the right place.
                </p>
                <p className="p-in-sp">
                  I shall not go into any general expression of my sentiments with regard to the position Virginia should occupy at the present time. I do not deem it necessary to re-echo the sentiments that I have heard upon this floor to-day and upon other occasions, as if it was something unheard of that a Virginian should declare and pledge himself to stand by Virginia to the last. I beg leave to
                  say for myself, representing as I do, a county which has a population of 12,000 whites and between 4 and 5,000 slaves—a county which has interests as closely connected with all the great and diversified interests of Virginia as any other county in the State—a type of the great Commonwealth itself, in its slaveholding, mining, manufacturing, and in all its staple
                  interests—representing such a county as this, I have ever taken the position before my people which I am ready to take here and elsewhere, that I owed my allegiance to this good old mother first, and that it should be given to her first, last, and all the time. Is it at all surprising to hear such a sentiment from a Virginian?
                </p>
                <p className="p-in-sp">
                  My colleague and myself, in the canvass in the county of Rock-bridge, declared before the people that the first intimations of coercion upon the part of the Black Republican power should be met by us at the frontier at the point of the bayonet. We are but as one man there, but we are men of the right stamp. We don't intend to do anything rashly, that shall bring upon this State the
                  horrors of civil war. I can but echo, with all my heart, the declaration of England's great Captain, who, after a long course of years spent in warfare in the Eastern hemisphere and upon the soil of his own continent of Europe, declared, in his place in the House of Peers, that to save his country <PageNumber num={427} /> from the horrors of one month
                  of civil war, he would cheerfully sacrifice his life. I assume for myself none of the virtue called Roman or Spartan, but I do claim that I, in common with the constituency that I represent upon this floor, have enough of the spirit of American patriotism to declare that we would save this State and this beloved country from the horrors which civil war would multiply upon them—we
                  would be willing to merit our doom by the cord or the fagot, if need be.
                </p>
                <p className="p-in-sp">
                  Are we to be hurried into the passage of an ordinance of secession here—to be sent back to the people for what purpose and to what effect? To be voted down. Do you think that the 60,000 majority represented upon this floor are to be turned away from the position of compromise, conciliation and settlement—that they are to be turned away from it by words coming from Republican
                  politicians or politicians of any sort? If you do, you make a most awful mistake, and I tell you who speak of Virginia and Virginia's honor as if Virginia's honor depended only upon the fighting point, that I regard Virginia's honor as much more indissolubly and intimately connected with another thing—and that is, the preservation intact of Virginia's faith. But we will not be put in
                  the position of breaking the faith which we have solemnly pledged in the formation of this Confederacy, except for causes, which, when they do compel us to act, will not allow us to take a step backwards.
                </p>
                <p className="p-in-sp">
                  Let us, then, instead of undertaking to take action upon so important matters in this hurried way, come up and see if we cannot stand together—if we cannot unite the people of this Commonwealth upon some common ground of action. I tell you that precipitate action of any kind will not do it. I tell you that action which ignores our natural allies upon the left, between whom we are
                  imbedded in this Confederacy, with whom we are indissolubly linked by geographical and social ties—that any action which anticipates that they will be morally coerced by the force of our example, will not receive the deliberate sanction of the people of Virginia.
                </p>
                <p className="p-in-sp">But, Mr. President, I find that I have been led into a longer course of remarks than I intended; but my object has been accomplished—simply that of calling upon the members of the Convention to act upon this matter coolly and deliberately. I am not willing to vote upon the proposition of my friend from Amelia [<span className="persName">Mr. HARVIE</span>] , because I am not now able exactly to understand its full scope and bearing. I would be, for the same reason, unwilling to vote upon the proposition of the gentleman from Goochland [<span className="persName">Mr. LEAKE</span>] 
                  . If any action is to be taken <PageNumber num={428} /> upon this matter of coercion, and any resolutions upon that subject are to be adopted by this body, common respect requires that we should call first upon the Committee on Federal Relations, which has been deliberating upon this very question, to make a report of their action. I am perfectly
                  satisfied that this unfortunate inaugural of Mr. Lincoln is calculated to stir up the feelings of a large portion of the people of this State and of the whole South. I regret as much as any man that Mr. Lincoln had not seen that one sentence of his own inaugural would apply as well to the question of coercion as it does to the question of appointing Marshals and Judges. He has told
                  us that he would not appoint Judges, Marshals and officers in any interior locality where the people are unanimous in their opposition to the Federal Government and where it is evident that resident citizens cannot be found to hold such offices. He has told us also in that connection that:
                </p>
                <p className="p-in-sp">"While the strict legal right may exist in the Government to enforce the exercise of these offices, the attempt to do so would be so irritating and so nearly impracticable withal, that I deem it better to forego, for the time, the uses of such offices."</p>
                <p className="p-in-sp">
                  Would to God that the spirit of wisdom had rested upon him, and that he might have known that the principle there laid down was as properly applicable to the question of the coercion of a State, as to the question of marshals and judges. However that may be, I am free to say—and I say it without any reservation—that when coercion is once attempted, whether direct or indirect, war is
                  at once certain and inevitable. I shall contend against it; I shall vote against it; I shall brace myself for the shock. That we stand now at a dangerous point in our history, I have recognized from the first hour I came here; and I call upon the friends of peace and conservatism not to allow themselves to be shaken from their equilibrium, not to be thrown from their self-poised
                  condition, but in the day of trial to stand firm and braced for the shock. We have entered upon a voyage perilous and full of dangers and difficulties. We have come to that point so much dreaded by the mariner in passing from one great ocean to another. We are off Cape Horn and we can expect only strong gales and rough seas, but let us yet trust that, with the help of Providence, we
                  may be enabled to pass from the stormy Atlantic to the smiling and placid waves of a Pacific ocean.
                </p>
                <div className="speaker" id="sp491"><span className="persName">Mr. THOMAS BRANCH</span>, of Petersburg—</div>
                <p className="p-in-sp">
                  Mr. President, all seem willing to contribute something to furthering the object which has called us here. But, sir, I really cannot see any necessity for such hot haste as is manifested to-day in the presentation of such resolutions as are <PageNumber num={429} /> offered here. If gentlemen will recur to the resolution which I offered fourteen days
                  ago, they will find that much of the subject matter of this discussion is before the Committee on Federal Relations now. Gentlemen who thus place themselves upon the side of the minority in this Convention, must have anticipated, a fortnight ago, everything which Mr. Lincoln has said in his inaugural address. Indeed that address is as moderate as I expected
                 [laughter] , and I apprehend that it is more moderate than they expected ; and whilst they anticipated something serious, nothing alarming has yet been done ; but they come here to-day in hot haste and tumble resolutions one over the other, making confusion worse confounded, so that we can do nothing practical or in order. The honorable gentleman from Amelia [<span className="persName">Mr. HARVIE</span>] 
                  , comes from the Committee room, where he does not see the Committee doing anything to suit him, and proposes to aid us here. I suppose it would be out of order for him to disclose what was going on there—but if I can place any confidence in unofficial information, I am told that the Committee have been talking about how they shall determine what coercion is. Well, I don't wonder
                  that the gentleman is tired of it, as I am of many things that have been said here. What shall I call them sir? I leave gentlemen and ladies to draw their own deductions.
                 [Laughter.] And I infer he looks on his amendment as something brilliant and new, and so before the sun shall sink below the western horizon, he would send the intelligence throughout the Union that Virginia has gone out of the Union. [Renewed laughter.] Or perhaps he wants to instruct the gentleman from Monongalia [<span className="persName">Mr. WILLEY</span>] 
                  , to revise the Constitution of Virginia. Don't be in such hot haste, my friend from Amelia. You have your whole life-time before you in which to attend to these matters. I have come here to assist this Convention in coming to some wise result—not to-day nor next week; but I say that before this Convention adjourns sine die we must do something that shall be a finality. So there is
                  no necessity to be in such a hurry about it—and if we cannot accomplish it, we can leave it to the generations that are to come after us.
                 [Laughter.] 
                  Gentlemen, this is no time for laughter. My heart has been saddened, and every patriotic heart should be saddened, and every Christian's voice be raised to Heaven in this time of our trial. After the reception of Mr. Lincoln's Inaugural, I saw some gentlemen rejoicing in the hotels. Rejoicing for what, sir? For plunging ourselves and our families, our wives and children, into civil
                  war? I pray that I may never rejoice at such a state of things. I pray that I may never have to march to battle to front my enemies. But I came here to defend <PageNumber num={430} /> the rights of Virginia, and I mean to do it at all hazards; and if we must go to meet our enemies, I wish to go with the same deliberation, with the same solemnity that I
                  would bend the knee in prayer before Almighty God. I shall go from a sense of duty. I wish to be led by God's providence and by the events transpiring daily, and to be satisfied that it is right before I go to war, and to give, as I would do, my life and my property to defend the honor of my State. They say fools wish otherwise. I would wish for logical power to give utterance to the
                  sentiments of my heart; but I will, however, strive to throw out some ideas in a crude state that gentlemen may amplify them if they please, and illuminate this audience and the whole State.
                 [Laughter.] 
                  I am not to be frightened by the call of the ayes and noes—the course sometimes resorted to. And if the gentleman from Goochland presses his amendment, I shall certainly vote no. I don't wish to vote on a resolution and argument as long as from here to my boarding house, in such haste, without understanding them; I once heard a man say he would vote for a thing because "he didn't
                  understand it;" but I am not willing to vote for any measure upon such hypothetical grounds as that. I would take pleasure in voting for the resolution offered by my friend from Chesterfield
                 [Mr. Cox] 
                  , because there is something tangible, there is some sound sense it.<span className="note" id="Note51"><span className="noteLabel">1</span>Probably "in" or "to" should follow "sense."</span>
                </p>
                <p className="p-in-sp">
                  Sir, the Committee on Federal Relations have been sitting for many days in that Committee room, and they have done nothing. Now it is time that that Committee should report. My constituents are becoming dissatisfied with the course this Convention is pursuing. Indeed, I expect our constituencies will soon call upon us, and demand that the time shall not be wasted in matters which
                  will lead to no practical result. Absolutely, sir, when I went home to Petersburg on Saturday, I could not tell my constituents what we were doing or going to do, and hence I left town.
                 [Laughter.] 
                  Now, as far as I am concerned, sir, I mean to have something to tell them when I go home next Saturday night, and if this Convention will sustain me, I mean to have a report from the very honorable Committee in the South wing of this house. They have been there long enough. The talk is that the distinguished Committee cannot decide what coercion is. I have had the temerity to decide
                  for myself what, in my opinion, coercion is, and I have told my friends how I mean to understand it when the subject is brought before this house for a vote.
                </p>
                <p className="p-in-sp">I would beg of my friend from Amelia [<span className="persName">Mr. HARVIE</span>] , to withdraw his clog to the amendment, and I would beg the honorable gentleman from Goochland [<span className="persName">Mr. LEAKE</span>] , who I have not the pleasure of being <PageNumber num={431} /> acquainted with, if he would be so good as to extend a civility and kindness to an old man who would tender his counsel to him, that he would withdraw his amendment; for I cannot be expected to vote upon a whole volume without a moment's warning. [Laughter.] Then to come to the resolution of the gentleman from Chesterfield, I will move that it be adopted, and that the Committee be instructed to report at 12 o'clock to-morrow.</p>
                <p className="p-in-sp">
                  To conclude, I am afraid that I shall come to be a sort of a conservator. I will tell you what I mean. I am for work; $4 a day don't pay me for sitting here; I have come here to do the work as far as I can and go home. I may ask that the time allowed to each member to speak shall be limited to one hour; for, if every one is permitted to speak two or three hours, and there being one
                  hundred and fifty-two members, it will be three months or more before we can come to a vote. So, without being invidious, I say it may be as well to abridge our remarks. I shall certainly make mine very short ; first, because I haven't prepared any speeches, and second, because I do not intend to attempt to do that which is not expected of me.
                </p>
                <div className="speaker" id="sp492"><span className="persName">Mr. EARLY</span>, of Franklin—</div>
                <p className="p-in-sp">
                  I think, Mr. President, we have had quite enough debate here based upon the inaugural address of the President of the United States. I beg to call the attention of the Convention to the fact that the report which we have had of it, so far, has come over the telegraphic wires, and I do not think that it would be exactly right for us to base any action upon it until we receive that
                  address in an authentic form.
                </p>
                <p className="p-in-sp">The hour has arrived at which we have been in the habit of adjourning. I do not make the motion which I am about to make for the purpose of claiming the floor to-morrow; but I think that, under the present circumstances, the best thing we can do is adjourn. I therefore move that the Convention do now adjourn.</p>
                <p>Accordingly, the Convention adjourned to Wednesday at 12 o'clock.</p>
              </div>
            </div>
            <div className="day" id="vsc1965.v1.2.18">
              <PageNumber num={432} />
              <h2><span className="headingNumber">1.18. </span><span className="head">EIGHTEENTH DAY</span></h2>
              <div className="dateline">Wednesday, <span className="date">March 6</span></div>
              <p>The Convention met at 12 o'clock.</p>
              <p>Prayer by the Rev. <span className="persName">Mr. HOGE</span> of the Presbyterian Church.</p>
              <div className="section" id="vsc1965.v1.2.18.1">
                <h3><span className="headingNumber">1.18.1. </span><span className="head">FEDERAL RELATIONS</span></h3>
                <p><span className="persName">Mr. LOGAN OSBURN</span> offered the following resolutions which were referred to the Committee on Federal Relations :</p>
                <p>Whereas the Government of the United States having been created and established for the purpose of forming a more perfect Union than existed under the articles of Confederation, and adopted by all of the original States, with ample provisions for amendments to the same, but without any for its disintegration—</p>
                <p>
                  Therefore, Resolved, That a resort to State secession, or a resumption of the original rights of the States, by an ordinance of secession, is not only unauthorized by the letter of the Constitution, but is contrary to, and subversive of the fundamental principles upon which it was formed; wholly at variance with the legitimate objects of its creation, and can only be justified as a
                  revolutionary means of obtaining redress, when every peaceable, honorable and constitutional expedient has been exhausted and failed.
                </p>
                <p>2d. Resolved, That an ordinance of secession cannot restore to the seceding State its original sovereignty until its secession has been assented to by a Convention of the remaining States, called for the purpose thereof.</p>
                <p>
                  3d. Resolved, That with a view of preserving peace, and to prevent the collision of arms and effusion of blood, it would be both politic and wise to waive the unquestionable right on the part of the General Government to collect the revenue and to protect the public property within the limits of the seceding State or States during the adjustment of pending difficulties, holding them
                  severally responsible for the safety of the same; and to either repeal or suspend the laws of the Federal Government therein, until their independence may be assented to, and their rights duly acknowledged.
                </p>
                <p>4th. Resolved, That the preservation of this Government cannot be maintained by force or coercion; that, therefore, this Convention earnestly recommend to both the Federal Government and the Government of the seceded States to carefully abstain from any aggressive measure or policy towards the other.</p>
                <PageNumber num={433} />
                <div className="speaker" id="sp493"><span className="persName">Mr. GOGGIN</span>—</div>
                <p className="p-in-sp">As the resolutions which I submitted yesterday were read twice in the hearing of the Convention, I simply ask the unanimous consent of the Convention to have them referred to the Committee on Federal Relations.</p>
                <div className="speaker" id="sp494"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">They will be so referred, no objection being made.</p>
              </div>
              <div className="section" id="vsc1965.v1.2.18.2">
                <h3><span className="headingNumber">1.18.2. </span><span className="head">REPORT OF COMMITTEE ON ELECTIONS</span></h3>
                <p><span className="persName">Mr. HAYMOND</span>, from the Committee on Elections, submitted the following report :</p>
                <div className="floatingText">
                  <div className="floatingText_body">
                    <p>
                      The Committee of Elections further report to the Convention that the vote in the various counties and election districts, from which election returns have been received, either by the President of the Convention or the Governor of the Commonwealth, for referring to the people the action of the Convention, "dissolving our connection with the Federal Union, or changing the organic
                      law of the State," and "against referring" is as follows :
                     [The returns come in here, but are too long for publication.] 
                      <span className="note" id="Note52"><span className="noteLabel">1</span>See Appendix D for the detailed returns.</span>
                    </p>
                    <p>From which [referring to the returns] it appears that the whole number of votes cast, were 145,697, of which 100,536 were cast for referring to the people, and 45,161 votes against referring to the people, showing a majority of 55,375 votes for referring to the people.</p>
                    <p>From the following named counties no official returns have been received, viz : Buchanan, Cabell, Elizabeth City, Greene, Logan, McDowell, Upshur, Wise, Wyoming and York. Should the returns from the delinquent counties be received, the result of the vote will be reported.</p>
                    <p>The committee are of the opinion that the returns from the delinquent counties will not materially change the above stated result. They may increase the majority for referring. All of which is respectfully submitted.</p>
                    <p>A. F. HAYMOND,</p>
                    <p>Chairman of Committee on Elections.</p>
                  </div>
                </div>
                <p>MARCH 6TH, 1861.</p>
                <p>The report was laid on the table and ordered to be printed.</p>
                <p>FORTS AND ARSENALS OF VIRGINIa</p>
                <p><span className="persName">Mr. TREDWAY</span>, from the Select Committee appointed by the Convention, to inquire in reference to movements of arms or men to the forts in Virginia, made the following report :</p>
                <div className="floatingText">
                  <div className="floatingText_body">
                    <p>
                      The Committee to whom was referred a resolution with instructions to inquire and report as speedily as practicable, "whether any movement <PageNumber num={434} /> of arms or men has been made by the General Government to any Fort or Arsenal in or bordering upon Virginia indicating a preparation for attack or coercion," beg leave to report that from
                      information derived from such sources as were accessible to them, they are of opinion, that there has been no movement of arms or men by the General Government, with any purpose of attack or coercion.
                    </p>
                    <p>
                      In regard to Fortress Monroe, there is no doubt, that since the 1st of January last, it has been put in a better condition for defence against attack from all quarters, than at any time before. Guns have been mounted upon the land side, pointing inland, a portion of the Fort which was before comparatively unprotected, and increased vigilance has been exercised in and around the
                      Fort. It was in evidence before your Committee, that the practicability of taking this post, in certain contingencies, had been discussed in the neighborhood, and they are of opinion, that nothing has been done by those in command of the Fort, which might not be regarded, by them, as proper measures of protection on their part.
                    </p>
                    <p>
                      Your Committee have been informed by the War Department at Washington, that within the period supposed to be alluded to, there has been no increase of the public arms in the Arsenal, in Virginia, nor any transfer of them from one point to another, in the State, except one hundred muskets, sent from Harper's Ferry to Fort Monroe, to supply a deficiency, and some weeks since a
                      company of troops was withdrawn from the latter place with a view of reinforcing a Southern fort.
                    </p>
                    <p>It also appears, from information satisfactory to your committee, that a company of recruits, consisting of 50 men, were removed, some months ago, from Carlisle Barracks to Harper's Ferry, to act as guard in the event of an attack, which the Superintendent has just grounds to apprehend, was contemplated by persons not residing in Virginia or sympathizing with the South.</p>
                    <p>It will be seen, from a letter herewith filed and addressed by the Secretary of War to your committee, that no other movement of troops has taken place in Virginia within the last twelve months, than those herein specified :</p>
                    <p>WAR DEPARTMENT, Feb. 27, 1861.</p>
                    <p>
                      Sir: In reply to your note of yesterday's date, communicating a resolution of the Convention of the State of Virginia, directing an enquiry, whether "any movement of arms or men has been made by the General Government to any fort or arsenal in or bordering upon Virginia, indicating <PageNumber num={435} /> a preparation for attack or coercion," I
                      have the honor to state that no such movement has taken place, nor has any such been contemplated. Within the period supposed to be referred to, there has been no increase of the public arms in the arsenal in Virginia, nor any transfer of them from one point to another in the State, except 100 muskets sent from Harper's Ferry to Fortress Monroe, to supply a deficiency. Some
                      months ago a company of recruits was ordered from Carlisle Barracks to Harper's Ferry, for the protection of the establishment against an apprehended attack from disorderly persons. This was done upon the request of Major Barbour, the Superintendent. Some weeks since, a company of troops was withdrawn from Fortress Monroe, with a view of reinforcing a Southern fort, but not for
                      any purpose of attack or coercion.
                    </p>
                    <p>These are the only movements of troops which have taken place in Virginia within the last twelve months.</p>
                    <p>Very respectfully,</p>
                    <p>Your obedient servant,</p>
                    <p>N. HOLT,</p>
                    <p>Secretary of War.</p>
                    <p>Hon. W. TREDWAY, Chairman of Committee, &amp;c.</p>
                  </div>
                </div>
                <p><span className="persName">Mr. TREDWAY</span> moved that it be laid on the table, and that the usual number be printed.</p>
                <div className="speaker" id="sp495"><span className="persName">Mr. EARLY</span>—</div>
                <p className="p-in-sp">I move to amend the resolution by requiring 10,000 copies of this report to be printed. The Convention will recollect that the object in raising this committee was, to inform us as to the true state of affairs.</p>
                <p>The amendment of <span className="persName">Mr. EARLY</span> was not agreed to, and the motion that the report be laid on the table, and that the usual number of copies be printed, was then agreed to.</p>
              </div>
              <div className="section" id="vsc1965.v1.2.18.3">
                <h3><span className="headingNumber">1.18.3. </span><span className="head">FEDERAL RELATIONS</span></h3>
                <p><span className="persName">The PRESIDENT</span> then stated the pending question to be upon the following amendment, offered by the gentleman from Amelia [<span className="persName">Mr. HARVIE</span>] , to the amendment offered by the gentleman from Goochland [<span className="persName">Mr. LEAKE</span>] :</p>
                <p>
                  "Whereas, it is now plain that it is the purpose of the Chief Executive of the United States to plunge the country into civil war, by using the power to 'hold, occupy and possess the property and places belonging to the Government, and to collect the duties on imports,' in all the States, as well those that have withdrawn from, as those that have remained
                  <PageNumber num={436} /> in the Union; and whereas, the State of Virginia will resist such exercise of power with all her means; therefore be it
                </p>
                <p>
                  "Resolved, That the Legislature of this State be requested to make all needful appropriations of means, and provide the necessary forces to resist and repel any attempt on the part of the Federal authorities to 'hold, occupy and possess the property and places' of the United States in any of the States that have withdrawn, or may withdraw from the Union, or to collect the duties on
                  imports in the same."
                </p>
                <div className="speaker" id="sp496"><span className="persName">Mr. FLOURNOY</span>, of Halifax—</div>
                <p className="p-in-sp">
                  It is with very great reluctance, Mr. President, that I trespass this morning upon the time and the attention of this Convention. I shall be as brief as I well can be, and shall address myself to one single question, to which I desire to call the attention of this Convention, and upon which I most earnestly desire to see immediate, firm and decisive action upon the part of this body.
                  I mean that question which I understand has occupied the time and the attention of our Committee upon Federal Relations for the last three weeks
                </p>
                <p className="p-in-sp">
                  the question of coercion. The situation of Virginia is a responsible and a painful one. But, I think, I know Virginia well enough, from her sea-board to the beautiful Ohio, to know that she is equal to any responsibility that circumstances may impose upon her. I regret, Mr. President, in the course of the running debate to which we have listened in this Convention to have heard at
                  any time the word party announced, or any allusion to the different portions of our State—East or West. Sir, I trust—and until some evidence conclusive to the contrary shall be shown by the action of gentlemen in the future, I shall believe that none but the true hearts of Virginians beat in the bosoms of our people upon every foot of our soil.
                </p>
                <p className="p-in-sp">
                  I say, Mr. President, that I desire to address myself to this single question of coercion, and I desire to see this Convention take a strong, decisive, firm, unequivocal and unmistakable position upon that subject. And in urging it, I urge it upon the consideration of this body as a measure of peace. I urge it, as, in my humble judgment, the only chance and hope that is left to
                  preserve this Government of ours, and restore it in all its parts and in all its perfection. I do not think, Mr. President, that in the course of my life I ever read with deeper interest or more painful emotion any paper that has ever come under my observation than the Inaugural Address of Abraham Lincoln, the President of the United States ; and the outrage of that document is
                  greater upon Virginia and upon us assembled here, who represent her sovereignty, than it is upon any portion of this vast Republic. The States of the South, seven of them, had seceded from this Union. They <PageNumber num={437} /> had placed themselves in hostility and in antagonism to the General Government. But was that the condition of Virginia? How
                  did Virginia stand when this Inaugural Address of Abraham Lincoln was sent out to the country with its menaces and its threats of coercion. When this Convention, which is now assembled, was called to meet together, what was the responsibility of the people of Virginia, even though a portion of our sister States of the South had already left the Union? She responded to the call for a
                  Convention, and she sent here upon this floor, to represent her and to protect, guard and preserve her honor and her interest, and to restore this Government to its unity, a very large majority of men, who were determined to exhaust every means to save the country. I was, and am still, among that number. I came here with an earnest desire—and the people who sent me here felt that
                  same earnest desire—that under the Providence of God, something might occur to drive away the storm that hung over our land and save us from its final destruction. We assembled. Our Legislature in the meantime, in the spirit of peace and with a desire of preserving our Government and of bringing back our sister States of the South, appointed distinguished citizens as Commissioners to
                  assemble in Washington, and Virginia invited all the States of the Confederacy to meet her there. A large number responded to the call made by Virginia to assemble there, and see if men fresh from the people, would not be able to settle and adjust these difficulties, upon a basis that would preserve our country and preserve the rights, honor, interest and liberty of the South. They
                  did assemble, sir. That Conference finally adopted measures, the Franklin propositions, not up to the standard of Virginia, not up to her desire.
                </p>
                <p className="p-in-sp">
                  But, sir, even the Franklin propositions, which were adopted by that Peace Conference, when submitted to the Congress of the United States, found not a response sufficient in the hearts of the Black Republicans in Congress. The Peace Conference has adjourned; Congress has adjourned, while we in Virginia, assembled in convention, have been waiting for some action upon the part of the
                  General Government that would drive away the clouds which now hang over us and darken our whole horizon. Upon the very adjournment of that Peace Conference and the adjournment of the Congress of the United States, the President, in his inaugural address, says to us this Union is not dissolved ; this Union cannot be dissolved ; that it is his duty, to execute all the laws in every
                  part of the land, and that he intends to hold, occupy and possess all the public property in the seceded States and collect the duties upon imports. <PageNumber num={438} /> I have heard it said by some here that Mr. Lincoln does not mean what he has said ; that his language is susceptible of different constructions, and that while, in reading his
                  inaugural, it may appear that he intends to exercise force to carry out his purpose, no coercion will be attempted and no force resorted to. Sir, no man with a just sense of responsibility, no man, who,<span className="note" id="Note53"><span className="noteLabel">2</span>The speaker apparently did not complete the relative clause.</span> when called to preside over thirty millions of
                  people, and upon whose conduct hangs the happiness of a nation, if not civilization and liberty throughout Christendom, ought to speak in doubtful language upon such a question; and I hope if his language is really susceptible of a different construction ; if he really does not intend coercion; if his object really is not to attempt to re-possess himself of the public property in the
                  seceded States by force; that a voice with unanimity, with clearness, with emphasis that does not admit of two constructions, will go up from this Convention saying to Mr. Lincoln, if coercion is attempted, that Virginia, with all her power and resources, will at once be in a position to maintain her honor and her interest, and protect her sisters of the South.
                </p>
                <p className="p-in-sp">
                  In reference to the proposition submitted on yesterday, I desire, when the Convention comes to take action upon it, that the question will be divided, so that we may pass that branch of the resolution requiring the Committee on Federal Relations to report, without delay, upon the subject of coercion. I hope that they will strip off and lay aside everything else connected with the
                  matters referred to them, and make a report to us at once upon this subject, so that the voice of Virginia may be heard, so that she may give quiet and assurance to our own people and say to this Government of the North, threatening the coercion of Southern States, that Virginia will never, never, never submit to see her Southern sisters coerced, until an arm more powerful than any
                  that she can raise shall crimson her soil with the blood of her children.
                </p>
                <p className="p-in-sp">
                  The time has passed, Mr. President, in my judgment, to speak in doubtful language any longer. I make this suggestion to this body in good faith, and as carrying out the views with which I was elected. I make it hoping that it will be the means of preserving harmony between the sections, and as the means of restoring the peace of the country by awarding to every portion of our land
                  protection for all the rights and interests to which they are entitled. I make it with no view of breaking up and destroying this Government, for I trust, that sustained by the righteous omnipotent hand of Him who presides over the destinies of men and nations, the peace of this land will be preserved, and its fraternity ultimately restored. The measure that I insist this
                  <PageNumber num={439} /> Convention shall act upon, is eminently a peace measure. It is the only way of preserving peace.
                </p>
                <p className="p-in-sp">
                  It is unnecessary for me to occupy the time of this Convention in telling you, that if the peace is broken, and war begun, the Union is at an end, and the possibility of its restoration over a free and excited people utterly impossible. I, therefore, say as a measure of peace, it addresses itself to this Convention as the only measure of peace left, and I hope that Virginia will
                  find, from one extremity to the other of this great old State, her sons coming up in one unanimous unbroken and undivided column, saying to the powers at Washington "stay your hand ; lay it not in wrath upon any Southern sister of ours."
                </p>
                <p className="p-in-sp">
                  When they shall do that, then, Mr. President, we can go and act upon the various questions before us. We can then go on and make an earnest and honest endeavor to settle and adjust the questions of difficulty that have divided and are still dividing and distracting our country. We can go on and adjust them upon a basis in which I trust that our sister States of the South and all of
                  us may come again together into the Union, and keep in that great road in which we have been led by Providence to greatness, power, and a position exalted above all the other nations of the earth. But refuse to take this position; say that Mr. Lincoln has spoken in a doubtful position; that we do not believe the man means what he has said ; remain here in quiet ; let the struggle
                  come; let blood be spilt; let our Southern sisters and this Government get into a bloody controversy; and, I tell you, no matter what may be our feelings, that when we get into war, God only knows how this country of ours will come out of that war. If you take a step now, in view of preserving the peace, Virginia will still occupy her commanding position, acting, as she does, for all
                  her border sisters, for a million and half of conservative men at the North, who are looking with interest to us to-day, and for her sisters of the South, who are looking to Virginia, in the hope that she will not allow them to be coerced. If we take the position that the Southern States shall not be coerced, war may come. If you do not take the position that the Southern States
                  shall not be coerced, war will come. Your choice, it appears to me, from the best view I can take of these questions, is between the possibility and certainty of war. If war should come, it will be attended with the loss of all that is dear to every section of this Confederacy; for I am not one of those who believe, when this Government is finally overthrown and a separation is
                  finally made between the North and the South, that in either section, for any great length of time, republican institutions will be maintained. <PageNumber num={440} /> I shall not go into a discussion of the question of slavery ; but I desire simply to announce this as my conviction : that this much derided and much assailed institution is the
                  conservative element in our Government, and that our liberty hangs upon its preservation. Sir, blot out to-morrow the four millions of Africans that occupy the position of laborers in the Southern States, and you put an extinguisher upon human liberty. Separate and divide us, throw us into a Northern and into a Southern Confederacy; let the conservatism and slaveholding
                  representation be taken out of the Congress of the United States, let the North be left alone to itself, and anarchy and confusion worse confounded, would, in a few years, reign throughout her borders; and the result would be that she could have no peace, no liberty but by the strong arm of power and by her standing armies to keep her disorderly population in order. And, on the other
                  hand, cut us loose from this Union of ours and make a Southern Confederacy of all these Southern States, and I will not draw you a picture of the troubles that may come upon us of the South. Let us then unite in an effort to restore this Government, to save this Union, and save it upon the terms of justice and equality to all the South. Let us bring our offending seceded sisters
                  back, and they will come when Virginia shall make an adjustment of the difficulties, and throw around them such guards as shall protect their honor and their rights. Let us once more get together; let all our land see the troubles that spring from an improper interference with this institution of ours in the South. Let them reflect, too, that this day England, is alarmed for fear
                  that the failure of one cotton crop may throw five millions of operatives out of employment. Restore the Government with these impressions upon the public mind, and one hundred years will pass away before the head of fanaticism will so erect itself in the North again as to disturb the peace and quiet of this land.
                </p>
                <p className="p-in-sp">
                  But in order to do this, the first step, the main step, is for this Convention now promptly and at once to take the position I have indicated in regard to the employment of coercion by the General Government—not in the spirit of menace, not in the way of threat, but with a cool, determined and deliberate purpose, expressed in such a form and in such a manner, as will show to the
                  world that Virginia has placed herself where she means to stand, for weal or woe. If the effect of our voice is to bring peace back to our land, it will fill every heart with rejoicing; if it bring war, she has no recreant son who will refuse to buckle on his armor and fight gloriously in her defence.
                </p>
                <PageNumber num={441} />
                <p>PEACE COMMISSIONERS</p>
                <div className="speaker" id="sp497"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">The Chair has received a communication from the Commissioners of the Peace Conference at Washington, which he begs leave to present for the consideration of the Convention.</p>
                <p>The communication was then read by the Secretary as follows :</p>
                <div className="floatingText">
                  <div className="floatingText_body">
                    <p>To his Excellency JOHN LETCHER, Governor of Virginia :</p>
                    <p>
                      The undersigned, Commissioners, in pursuance of the wishes of the General Assembly expressed in the resolutions of the 19th day of January last, repaired in due season to the city of Washington. They there found, on the 4th day of February, the day suggested in the overture of Virginia for a Conference with the other States, Commissioners to meet them from the following States,
                      viz : Rhode Island, New Jersey, Delaware, Maryland, New Hampshire, Vermont, Connecticut, Pennsylvania, North Carolina, Ohio, Indiana, Illinois and Kentucky. Subsequently, during the continuance of the Conference, at different periods, appeared likewise Commissioners from Tennessee, Massachusetts, Missouri, New York, Maine, Iowa and Kansas, so that before the close, 21 States were
                      represented by Commissioners, appointed either by the Legislatures or Governors of the respective States.
                    </p>
                    <p>The undersigned communicated the resolutions of the General Assembly to this Conference, and both before its Committee appointed to recommend a plan of adjustment, and the Conference itself urged the propositions known as the Crittenden Resolutions, with the modifications suggested by the General Assembly of Virginia as the basis of an acceptable adjustment.</p>
                    <p>
                      They were not adopted by the Conference, but in lieu thereof, after much discussion, and the consideration of many proposed amendments the article, with seven sections, extended as an amendment to the Constitution (a copy of which article is hereto adjoined) , was adopted by sections (not under the rules being voted on as a whole) , and by a vote of the Conference (not taken by
                      States) , was directed to be submitted to Congress, with the request that it should be recommended to the States for ratification, which was accordingly done by the President of the Conference.
                    </p>
                    <p>
                      The undersigned regret that the journal, showing the proceedings and votes in the Conference has not yet been published or furnished them, and that, consequently, they are not able to present it with this report. As soon as received, it will be communicated to your Excellency. <PageNumber num={442} /> In the absence of that record, it is deemed
                      appropriate to state, that, on the final adoption of the first section, two of the States, Indiana and Missouri, did not vote, and North Carolina was divided, and that the vote by States was—ayes 8, noes 8, Virginia, by a majority of her Commissioners, voting in the negative. The other sections were adopted by varying majorities (not precisely recollected) and on the 5th and 7th
                      sections, the vote of Virginia was in the negative.
                    </p>
                    <p>The plan, when submitted to Congress, failed to receive its recommendation, and, as that body having adjourned, can take no farther cognizance of it, the undersigned feel that the contingency has arrived on which they are required to report, as they herein do, the result of their action.</p>
                    <p>Respectfully,</p>
                    <p>JOHN TYLER,</p>
                    <p>G. W. SUMMERS, W. C. RIVES,</p>
                    <p>JAMES A. SEDDON</p>
                  </div>
                </div>
                <p>COMMUNICATION FROM JUDGE BROCKENBROUGH</p>
                <div className="speaker" id="sp498"><span className="persName">Mr. SUMMERS</span>—</div>
                <p className="p-in-sp">With the permission of the gentleman from Prince Edward [<span className="persName">Mr. THORNTON</span>] , who was entitled to the floor but gave way I will make a remark or two.</p>
                <p className="p-in-sp">
                  It will be perceived, Mr. President, that that report is signed by four of the Commissioners from Virginia, one of our Commissioners, Judge Brockenbrough of the county of Rockbridge, not being present in the city, his name is not signed to the report. I am in possession of a communication from that gentleman, giving his views somewhat at large upon the various sections of which that
                  document is composed, addressed to two of the members of this body. I think that justice to that gentleman, our absent colleague, as well as other points of view, make it proper that this communication shall be presented to this body.
                </p>
                <p className="p-in-sp">I rise for the purpose, therefore, of presenting this communication from Judge Brockenbrough, and to ask that it be printed in connection with the report—not as part of the report. The report is complete in itself and signed by the Commissioners, who were present.</p>
                <p className="p-in-sp">I move, sir, that the report be laid upon the table, and the usual number of copies printed. A gentleman beside me suggests that the report be referred to the Committee on Federal Relations, I have no objection to that disposition of it, if the Convention shall so decide.</p>
                <p className="p-in-sp">
                  I now submit the communication of Judge Brockenbrough, and move <PageNumber num={443} /> that it be laid upon the table and printed—agreed to. The following is the communication :<span className="note" id="Note54"
                  ><span className="noteLabel">3</span>The Enquirer made a number of errors in printing this letter; essential corrections have been supplied from the text printed as Document No. XII.</span
                  >
                </p>
                <div className="floatingText">
                  <div className="floatingText_body">
                    <p>WASHINGTON, March 2, 1861.</p>
                    <p>The Hon. JOHN TYLER, ex-President of the United States, and Hon. GEORGE W. SUMMERS :</p>
                    <p>Gentlemen : I beg leave to address you, as two of the Commissioners representing the State of Virginia in the late Peace Conference at Washington, and also as members of the State Convention, now sitting in Richmond, and to state, as briefly as I can, my views in reference to the results of that Conference.</p>
                    <p>
                      The act of the General Assembly of Virginia which originated the Conference, declares the patriotic purposes which impelled the Legislature in resorting to this extraordinary mode of adjusting the unhappy controversy which now divides the States of this Confederacy, and declares that unless it be satisfactorily adjusted, "a permanent dissolution of the Union is inevitable ; and
                      the General Assembly, representing the wishes of the people of this Commonwealth, is desirous of employing every reasonable means to avert so dire a calamity, and determined to make a final effort to restore the Union and the Constitution in the spirit in which they were established by the fathers of the Republic." The act further declared the opinion of the General Assembly to
                      be, that the resolutions submitted to the Senate of the United States by Mr. Crittenden, with several specified modifications, constitute the basis of such an adjustment of the controversy as would be accepted by the people of Virginia.
                    </p>
                    <p>
                      The plan of adjustment agreed upon by a majority of the Conference differs in many important particulars from the scheme of Mr. Crittenden. The main difficulty we had to contend with in the controversy, was in a satisfactory adjustment of the Territorial question. The Crittenden plan distinctly recognizes slavery of the African race South of 36 30 as existing, and precludes
                      Congress from interfering therewith : and declares that it shall be protected as property by all the departments of the territorial government during its continuance. The Conference plan contains no such recognition, eo nomine, but declares that "the status of persons held to involuntary service or labor; as it now exists, shall not be changed : nor shall any law be passed by
                      Congress or the Territorial Legislature to hinder or prevent the taking of such persons from any of the States to said territories, nor to impair the rights arising from said relation; but the same shall be subject to <PageNumber num={444} /> judicial cognizance in the federal courts, according to the course of the common law."
                    </p>
                    <p>
                      Whether this provision is substantially equivalent to the corresponding clause of the Crittenden plan is a question of interpretation. The language of the latter is clear and perspicuous; of the former, vague and ambiguous. Whatever the true construction of it be, it is a most weighty objection to it, that it admits of various interpretations. The rights arising from the relation
                      of master and slave are expressly recognized, and the Federal Courts are required to take cognizance of them; but neither the Executive or Legislative departments are, in terms, required to protect them. Its advocates in the Conference insisted that while the rights arising, from the relation are referred to the judicial determination of the Courts, the recognition of them in the
                      article, by a just implication, imports that it is the duty of the Legislature to afford them ample protection by positive enactment of laws necessary to accomplish the end. If this be the proper construction of the clauses, and in my opinion it is—the guarantee of protection of the rights of property in slaves in the territories, is equivalent to that contained in the Crittenden
                      scheme. But we have to resort to implication to deduce it. The terms employed, to secure protection of the rights growing out of the relation of master and slave, are negative only; they shall not be changed, or the introduction of slaves hindered, or the rights of the master impaired by legislation. No duty is prescribed to hedge them round with proper enactments. It is for this
                      reason that I made a most strenuous effort to amend the section by interpolating the words—"and it shall be the duty of the Territorial Government, in all its departments, to protect the rights arising from said relation." The effort was repeatedly and most earnestly made. It was ineffectual, but it is fair to say that the sense of the Conference was not tested on this particular
                      question—the consideration of the amendment being precluded by the ruling of the Chair, that the section was not then amendable.
                    </p>
                    <p>
                      The rights of the master are made subject to judicial cognizance according to the course of the common law! How far can the courts afford protection to the rights of the master to his slave, according to the course of the common law? Where legal rights exist, that most wise and flexible system of law known as the common law always supplies the appropriate remedies for their
                      enforcement. The invasion of a right is an injury, for the redress of which a suitable remedy was always afforded; for the common law knows no such anomaly as a wrong without a remedy. Any civil injury, therefore, to the rights growing out of the relation of master and slave would be redressed, according <PageNumber num={445} /> to the course of the
                      common law, by supplying the appropriate remedy of detinue, trover, case, &amp;c. It is said that at common law, slavery was not recognized—that at common law, man could not have property in man; and the celebrated Somerset case, and the late Anderson case are cited, in support of the proposition ; to which may be added the imposing authority of W. H. Seward himself. 2 Seward's
                      Works, vol. II, p. 453-4. But the common law is not referred to here to determine rights, but simply to furnish remedies for injuries to rights recognized by the section in explicit terms. The principle that rights always draw after them at common law the remedy for injuries to those rights, does not apply to public wrongs or crimes. These are not punishable until defined and
                      appropriate penalties are deduced either by the common law or by statute. But we cannot look to the common law as a source of criminal jurisdiction in the Federal Courts where those rights are made cognizable, for those tribunals have no such jurisdiction, and can only take cognizance of crimes specially created or defined by statute. Statutes prescribing police regulations are
                      indispensable in a slaveholding country; but the duty of enacting them is not enjoined by this section, unless it be implied from the recognition of the rights arising from the relation of master and slave. Is it a fair implication from the language of this section, that it is the duty of the Territorial Legislature of New Mexico to protect the rights of slave owners by all
                      proper enactments? In my judgment, it admits of no other fair or reasonable construction. There is much circumlocution to avoid the use of the terms "slaves" and "slavery" ; but the status of persons held to involuntary service or labor there, is that of slavery, and the persons so held are slaves; that status—that is, the state or condition of slavery—shall not be changed, nor
                      the importation or introduction of such persons from any of the States prevented, nor the rights arising from such relation impaired. They would be impaired without proper legislation for their protection, and the duty of such protection may therefore be inferred, since the failure to legislate may as effectually impair the rights recognized, as positive hostile legislation. But,
                      in point of fact, there is no necessity for such legislation in New Mexico. It exists already in very ample measure, as I learn from undoubted authority. The status of such persons, as it now exists, is recognized as a status of slavery, and of slavery only; the rights of the master are already fully protected by law, and to repeal those laws would be a clear violation of the
                      spirit and very words of this section, as it would certainly impair, most probably destroy, those rights. Upon every sound principle of interpretation, <PageNumber num={446} /> I think that the rights of the master to his slaves, as property, are protected by this section.
                    </p>
                    <p>
                      The second section introduces a new, and as I think, valuable principle. So far as the acquisition of future territory is concerned, it creates a dual Senate, by an equal partition of power between the two sections of the Senate. This is a practical guarantee of equal power to the weaker section, by which the South can exclude any future territory, if the conditions of an
                      admission are disadvantageous to her. But the Northern section may equally checkmate her, in her attempts to acquire future territory. True, but the only territory South of 36 deg. 30 min. which can be hereafter acquired, is Cuba and the Northern part of Mexico. Cuba is a slaveholding island already, and its great resources can only be developed by slave labor. The North is more
                      eager to possess it than the South. If any portion of Mexico is hereafter acquired, it will be on the principle of a fair and equitable partition of the territory between the sections. This section was approved by four of the Commissioners from Virginia.
                    </p>
                    <p>
                      The third section embodies, substantially, I think, the provisions of the second, third and fourth sections of the Crittenden plan. It prohibits Congress from interfering with slavery within any State or Territory; or in the District of Columbia, without the consent of Maryland and of the owners, or making the owners just compensation; or with representatives or others bringing
                      slaves for personal service with them and taking them away; or in places within the exclusive jurisdiction of the United States within those States and Territories where slavery exists; or the removal or transportation of slaves from one State or Territory to any other where slavery exists; or the right during transportation, by sea or river, of touching at ports, shores and
                      landings, and of landing in case of distress. The right of transit through States where slavery does not exist, is not confirmed. The provision that no higher rate of taxation shall be imposed on slaves than on land, is of some value to the South.
                    </p>
                    <p>
                      The action prohibiting the foreign slave trade by Constitutional amendment is objectionable, simply because it was wholly unnecessary. Even the confederated States of the South now constituting an independent Government of slave States, de facto, if not de jure, have prohibited this traffic. The advocates for the re-opening of it in any one of the border slave States are very
                      few, and there was no sort of necessity for interpolating this provision, which had no connection with the pending controversy, into this scheme of adjustment.
                    </p>
                    <p>
                      The seventh and last section of the Conference scheme is very much <PageNumber num={447} /> less satisfactory than the corresponding one in Mr. Crittenden's plan. Each provides for the payment of the full value of fugitive slaves by the United States in all cases where the officer, charged with the duty of making the arrest, is prevented from so
                      doing by violence or intimidation, or when after arrest, the slaves are rescued. But the former is defective in not providing for re-imbursement to the United States by clothing it with power to impose and collect a tax on the county or city where the outrage was committed, equal to the principal, interest and costs, as is provided in the Crittenden resolutions. There is a double
                      advantage in this feature. In the first place it is more just and equitable to cast the burthen on those who committed the wrong than upon the public treasury. The South is thus made, by its omission in the Conference scheme, to pay its full proportion of a charge resulting from a wrong committed against herself. But by far the most important consideration is, that such a
                      requirement would tend powerfully to restrain such wicked outrages by making it the interest of the offending locality to suppress all mobs and riotous assemblages, to rob and plunder the citizens of the South for no other cause than asserting an undoubted constitutional right.
                    </p>
                    <p>
                      I have thus endeavored to run a parallel between the two plans of adjustment. On the whole, it is clear that the Crittenden plan is far preferable to that of the late Peace Conference. It is necessary to say how earnestly, yet ineffectually, we struggled to come up to the very letter of our commission by obtaining an adjustment on the basis of the former plan, and in every
                      variety of form. We were uniformly voted down by that inexorable majority! At length, all rival schemes being rejected, the naked question came up of the adoption or rejection of the present Conference scheme. The vote was by sections, and Virginia voted against the most important sections, particularly the first and seventh of the series; and they were all adopted seriatim. It
                      was supposed that, as a matter of course, the vote would be taken upon the scheme as an entirety, and I then announced to one of you that after the most anxious deliberation, I had come to the conclusion that, distasteful as the scheme was to me, I felt it to be my duty to cast a representative vote, and sustain the measure as a whole. I was convinced that Western Virginia, which
                      I in part represented, would have so voted by an immense majority, if her voice could be heard within that hall, and, acting under that strong conviction, I would have done homage to that great principle of representative government which demands that the representative yield his individual sentiments, and give utterance to those of his constituents. But no vote was taken on the
                      plan, as a <PageNumber num={448} /> whole, the Chair having ruled that each section being successively adopted, the entire plan was adopted, and no farther vote was necessary or admissible, under the parliamentary rule.
                    </p>
                    <p>
                      The most solemnly momentous issue that ever agitated the councils of our dear old Commonwealth is now fairly made up and must soon find its solution in the deliberations of the Convention now assembled to give expression to her sovereign will. My earnest prayer is that true wisdom may conduct her safely and honorably out of this great crisis. Every loyal son of hers awaits the
                      issue with intense solicitude, and for myself I will say that my destiny is bound up indissolubly with hers!
                    </p>
                    <p>I am, gentlemen, most cordially,</p>
                    <p>Your friend,</p>
                    <p>JOHN W. BROCKENBROUGH.</p>
                  </div>
                </div>
                <div className="speaker" id="sp499"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">Does the gentleman move that the report be laid upon the table and printed.</p>
                <div className="speaker" id="sp500"><span className="persName">Mr. SUMMERS </span>—</div>
                <p className="p-in-sp">Perhaps that is the best disposition to make of it at present. After it is printed, it can, if deemed advisable, go before the Committee on Federal Relations, I therefore move to lay the report on the table and print it.</p>
                <p>The motion was agreed to.</p>
                <div className="speaker" id="sp501"><span className="persName">Mr. THORNTON</span>—</div>
                <p className="p-in-sp">
                  I wish, sir, to claim the attention of the Convention this morning for a few minutes, while I present to them some views that I entertain as to the policy which should be adopted by this body in reference to the question of coercion. The views, sir, that I desire to present have grown in my mind from a consideration of the inaugural address of the President of the United States; and
                  I wish to throw out some remarks on the seminal principles contained in that address, and to denounce them here upon this floor and before this country, as principles that are in violation of the rights of freemen all over the world.
                </p>
                <p className="p-in-sp">We were told, sir, by my friend from Halifax [<span className="persName">Mr. FLOURNOY</span>] 
                  , who just addressed you, that he has heard it said that Mr. Lincoln does not mean what is here written down. I see, sir, from the papers of this morning that Abraham Lincoln declared on yesterday, that he had "studied all the points" presented in this inaugural, "with great anxiety, and presented them with whatever of ability and sense of justice he could bring to bear."
                </p>
                <p className="p-in-sp">What sir are these principles, and in what position do they place Virginia? I say, Mr. President, that the seminal principles of this inaugural address are—first the Divine right of the majority to govern; <PageNumber num={449} /> and, 2nd. The duty of positive obedience and non-resistance upon the part of the minority.</p>
                <p className="p-in-sp">Such doctrines, sir, brought one English king to the block, and drove another from his throne. Such doctrines could not be enunciated at this day by any English statesman and he retain power twelve hours after their enunciation.</p>
                <p className="p-in-sp">
                  Look at it, sir. What does this President of ours say? The first proposition to which I desire to call the attention of the Convention, is this. It is plainly written down in this address, that "if by the mere force of numbers a majority should deprive a minority of any clearly written constitutional right, it might, in a moral point of view, justify revolution. Certainly it would,
                  if such right were a vital one." But, says Mr. Lincoln, it is impossible that any vital right thus clearly written down in the Constitution, can be denied to a minority; and he gives two reasons for it. The first is : that the human mind is so constituted that no party could reach to the audacity of doing such a thing.
                </p>
                <p className="p-in-sp">The second is, that "all the vital rights of minorities and of individuals are so plainly assured to them by affirmations and negations, guarantees and prohibitions, in the Constitution, that controversies never can arise concerning them."</p>
                <p className="p-in-sp">Therefore, in the further discussion of this question, we may dismiss what Mr. Lincoln calls these vital rights clearly written down in the Constitution.</p>
                <p className="p-in-sp">But he goes on to say, that all our constitutional controversies spring from questions arising out of the organic law, when it is not so framed as to have a special provision in reference to the identical question. Arid how is that to be decided?</p>
                <p className="p-in-sp">
                  Mr. President, in this country parties have been divided. One party has maintained, with Madison and Jefferson at its head, that when these rights were denied to minorities, the State should interpose for the purpose of protecting the minority in the exercise of that right. Another party, under the lead of Alexander Hamilton and the great constitutional lawyer of Massachusetts, has
                  maintained that when these rights were denied, the Constitution had erected the Supreme Court as the final arbiter between the majority and the minority.
                </p>
                <p className="p-in-sp">
                  These, sir, have been the opinions that have been held by these respective parties, and advocated by the ablest intellects of this country. Webster, in the Senate of the United States, pointed us to Marshall, and Jay, and Story, and to the authority of that court and to their tenure of office, as guarantees for the rights of the minority. He, sir, did not hesitate to bow his laureled
                  brow in humility before the decision <PageNumber num={450} /> of that great tribunal. But what says this pettifogger from Springfield? I deny the right of a State to interpose. I deny the right of the Supreme Court to decide that question. I say it is the majority alone who can decide it, and you of the minority must submit.
                </p>
                <p className="p-in-sp">
                  I ask you, Mr. President—I ask this Convention—if a paper containing a more odious doctrine than this, has ever been sent forth by any statesman of England since the Revolution of 1688; or was ever before presented to the consideration of the American people? Sir, I remember that one of Virginia's eminent sons—I refer to the late Judge Upshur—in addressing a number of young
                  Virginians some years ago, told them—"Young gentlemen, I fear that liberty is beginning to put off her modest and matron-like garb, and to walk forth with the impudent step and brazen front of the courtezan. Already has she entered upon her unholy revel, and ere long her reeling step will prostrate every cherished right in the dust."
                </p>
                <p className="p-in-sp">Sir, I ask you—I ask the members of this Convention—I ask Virginians everywhere—if the doctrine that was enunciated the day before yesterday from the Capitol at Washington, is to be held as the doctrine of the Republican party, what hope is there for liberty in the world?</p>
                <p className="p-in-sp">Mr. President, what does it mean? That numbers are to decide and that the minority is to submit. Is it not the doctrine of Rob Roy?</p>
                <p className="p-in-sp">"That he shall take who has the power, And he shall hold who can."</p>
                <p className="p-in-sp">I ask, is it possible that we shall sit here debating as to whether or not we shall pass a resolution opposing coercion? that we shall sit here, as was said by the gentleman from Petersburg [<span className="persName">Mr. BRaNCH</span>] 
                  , yesterday, debating to make a definition of coercion, when the President of the United States, in delivering his inaugural address, has told us that you can find no relief from the action in State sovereignty; you can find no relief in the decisions of a judicial tribunal. He says, I have the majority on my side, and I intend to hold, occupy and possess—I intend to collect tribute,
                  and, having a majority, your only duty, gentlemen of the South, is to submit. Why, sir, the President of the United States has left to the people of Virginia no alternative. He appeals to force himself, and we can only meet him by force. Mr. Lincoln, the year before last, I think it was, declared in one of his published speeches, that this land could not last permanently half slave
                  and half free. Sir, it cannot exist so, upon these doctrines thus declared and promulgated by him; and I see that men from an Eastern State had audience with President Lincoln, in the ante-chamber of the White House on yesterday, and <PageNumber num={451} /> pledged the power and military force of their State to carry out the doctrines of this
                  inaugural.
                </p>
                <p className="p-in-sp">
                  Sir, I accept the vaticination of President Lincoln. It is not the first time that the Almighty has placed the words of prophecy in the mouth of a wicked man. He has labored effectually, in the North, to prevent the Government from extending over the free labor and the slave labor States. So help me God, I will strike hands with gentlemen in the South, to make the residue of his
                  prophecy come true. I will labor with them, so that the land over which this man Lincoln sweeps the sceptre, shall embrace the free labor States of the North, while the land that is under the sway of that warrior statesman, Jefferson Davis, shall embrace the slave labor States of the South. He may take for his guide the light of the Northern bear, that sheds its disastrous gleam on
                  the wigwam at Chicago. I will see that the light that illumines my path, shall be the pure and steady ray that streams in lustrous beauty from the Southern cross as it breaks in gentle radiance over the Capitol at Montgomery.
                </p>
                <div className="stage it">[Applause.]</div>
                <div className="speaker" id="sp502"><span className="persName">Mr. EARLY</span>—</div>
                <p className="p-in-sp">Mr. President, I understand the state of the question to be this. A resolution was offered on yesterday by the gentleman from Chesterfield [<span className="persName">Mr. COX</span>] , to which the gentleman from Goochland [<span className="persName">Mr. LEAKE</span>] , offered an amendment, and to this amendment the gentleman from Amelia [<span className="persName">Mr. HARVIE</span>] , offered another amendment.</p>
                <p className="p-in-sp">I wish to enquire, sir, whether it is in order to offer a substitute for the whole.</p>
                <div className="speaker" id="sp503"><span className="persName">Mr. PRESIDENT</span>—</div>
                <p className="p-in-sp">No, sir, not at this time.</p>
                <div className="speaker" id="sp504"><span className="persName">Mr. EARLY</span>—</div>
                <p className="p-in-sp">I shall then vote against the amendment of the gentleman from Amelia [<span className="persName">Mr. HARVIE</span>] , and the amendment of the gentleman from Goochland [<span className="persName">Mr. LEAKE</span>] , with a view to offer a substitute, which I hold in my hand, for the original resolution offered by the gentleman from Chesterfield [<span className="persName">Mr. COX</span>] .</p>
                <p className="p-in-sp">
                  Mr. President, I think that there was never a body assembled on the face of the earth having before it so important a duty to perform as this Convention has, and upon the result of whose deliberations so much depends. Sir, consequences, the most momentous that ever depended upon the action of any assembly, ancient or modern, depend upon our action here. These consequences are no less
                  than the existence and the preservation of the fairest fabric of government that ever was erected. They involve the question whether thirty millions of people shall continue to enjoy peace and prosperity, or whether this Government shall be rent into fragments, and with it the last hope of freedom in the world shall expire. <PageNumber num={452} /> Sir,
                  we ought not to act in hot haste, but rather coolly deliberate in view of the grave consequences which our action involves. The gentleman from Halifax
                 [<span className="persName">Mr. FLOURNOY</span>] , and the gentleman from Prince Edward [<span className="persName">Mr. THORNTON</span>] 
                  , have just referred to the Inaugural Address of Mr. Lincoln, and among other parts of it to that portion in which he says he will execute the laws in all the States of this Union and hold, occupy and possess the public property of the United States. Now, sir, I do not approve of the inaugural of Mr. Lincoln, and I did not expect to be able to endorse his policy and I did not think
                  there was a member of this Convention who expected to endorse it ; but, sir, I ask the gentleman from Halifax
                 [<span className="persName">Mr. FLOURNOY</span>] , and the gentleman from Prince Edward [<span className="persName">Mr. THORNTON</span>] 
                  , if it were not for the fact that six or seven States of this Confederacy have seceded from this Union, if the declarations of President Lincoln that he would execute the laws in all the States would not have been hailed throughout the country as a guarantee that he would perform his duty, and that we should have peace and protection for our property, and that the fugitive slave law
                  would be faithfully executed? I ask why it is we are placed in this perilous condition? and if it is not solely from the action of these States that have seceded from the Union without having consulted our views.
                </p>
                <p className="p-in-sp">The gentleman from Prince Edward [<span className="persName">Mr. THORNTON</span>] , says that he has enunciated that the majority must rule and that the minority must submit.</p>
                <p className="p-in-sp">
                  Mr. President, I have been in a minority all my life. I have been standing up against currents that few men of my humble capacity could withstand, in defence of the rights of the minority. I have contended for the right to be heard, for the right to express my opinion under very disadvantageous circumstances, and I would be the last man to yield any right of the minority. But, sir,
                  can these rights be asserted, not by force or violence, but under the Constitution and according to the laws? I think they can, sir. The gentleman tells you that this doctrine of the divine right of the majority, and of the obligation of the minority to submit, cost one king his head and another his crown.<span className="note" id="Note55"
                  ><span className="noteLabel">4</span>In the Proceedings of March 7, page 446, Mr. Early gave another account of these remarks on the assertion of minority rights.</span
                  >
                  The gentleman has denounced the intimations in Mr. Lincoln's inaugural, that the political action of the Government is not to be regulated by the decisions of The Supreme Court in a particular case. I do not approve the intimation, but I believe the gentleman from Prince Edward
                 [<span className="persName">Mr. THORNTON</span>] 
                  , belongs to that school of politicians who take the resolutions of '98-'99, and the celebrated report thereon, as their guide ; and I believe that every secessionist in this Convention <PageNumber num={453} /> claims to derive the doctrine of secession from these resolutions and that report. Now, I do not profess to be intimately acquainted with the
                  history of events which led to the passage of these resolutions, but they resulted from opposition to the enforcement of the alien and sedition laws; and I believe it is an unquestionable fact that the alien and sedition laws were and had been declared Constitutional by the Courts at the time of the passage of these resolutions. Now, I submit it to the gentleman from Prince Edward,
                  if Mr. Lincoln might not fairly deduce from the resolutions of '98-'99, his doctrine that the political action of the Government is not to be controlled by the decisions of the Courts?
                </p>
                <p className="p-in-sp">
                  I do not wish to be understood as endorsing this inaugural, but I think, sir, as we have a Committee that has been earnestly engaged for some time in the consideration of these questions that are brought before us by the resolutions of the gentleman from Chesterfield, and the amendments thereto, that we ought not to forestall the action of that Committee, but that we ought to wait
                  quietly and calmly for its report.
                </p>
                <p className="p-in-sp">
                  I have heard a great deal about revolution going on in the country. We hear a great many reports from those who come to mix in this seething cauldron of confusion and disorder. They tell us that revolution is going on in the country. They know nothing about the masses of the people of this State. The real people—the bone and sinew of the State—the men upon whose exertions the
                  prosperity of the State depends, and upon the strength of whose arms its defence, in time of danger, will rest, are at home, attending to their occupations, providing the ways and means of support for themselves, their families and the country. They are waiting, calmly and quietly, the action of this Convention, upon which their whole hopes rest for the preservation of this Union.
                  They do not want precipitate or hasty action, and should such be had, they will apply the corrective having judiciously reserved to themselves the right of ratification or rejection, as to them may seem best.
                </p>
                <p className="p-in-sp">I think we can safely await the report of the Committee on Federal Relations, before taking any action ; and so believing, I shall vote against the resolutions of the gentleman from Goochland [<span className="persName">Mr. LEAKE</span>] , and those of the gentleman from Amelia [<span className="persName">Mr. HARVIE</span>] .</p>
                <div className="speaker" id="sp505"><span className="persName">Mr. GOODE</span>, of Bedford—</div>
                <p className="p-in-sp">Mr. President, I had no desire to address the Convention this morning, and particularly to follow the eloquent gentlemen who have already occupied the floor. But, sir, I desire to reply briefly to the gentleman from the county of Franklin [<span className="persName">Mr. EaRLY</span>] 
                  , who has just addressed this Convention. It will be remembered, sir, <PageNumber num={454} /> by yourself and by the Convention, that yesterday that gentleman informed us that the report of Mr. Lincoln's inaugural address was received by telegraph, and therefore he desired us to wait. The report is here to-day, sir, in the regular course of mail. The
                  gentleman still desires to wait. He is determined not to be precipitated into any hasty action.
                 [Laughter.] 
                  Sir, we were told by the gentleman, and those who co-operate with him, prior to the election of Abraham Lincoln, that we must wait for the "overt act." We were told by the gentleman, and those who co-operate with him in this Convention assembled, that we must wait and dance attendance upon the so-called Peace Conference at Washington. We were told by the gentleman when that failed,
                  that we must wait upon a Black Republican Congress. And now, sir, since they have deliberately rejected the solemn demands of the State of Virginia, which were put forth as the final effort to restore the integrity of the Union, the gentleman from Franklin comes again with the syren song "wait."
                </p>
                <p className="p-in-sp">
                  And, sir, yesterday we were told by him : "The lightning has perhaps made a blunder; let us wait, wait until we hear what the man really has to say, wait until it is written down in black and white." Well, sir, we waited. The gentleman moved an adjournment yesterday; we voted an adjournment; we have waited upon him; he has consulted his pillow, and he comes this morning to the
                  Convention. And now, sir, we have heard from Mr. Lincoln, and the gentleman from Franklin rises here, and while he repudiates the doctrines contained in the inaugural, while he does not wish to meet the crushing, overwhelming criticisms which have been made upon it, although we waited yesterday at his instance, and have heard what Mr. Lincoln had to say, the gentleman, contrary to
                  all expectations, asks us now to wait.
                </p>
                <div className="stage it">[Applause and laughter.]</div>
                <div className="speaker" id="sp506"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">It may save the Chair the actual performance of a very unpleasant duty, by announcing now his firm determination to clear the galleries if there is a repetition of such conduct. The scenes of the House of Representatives and of the Senate Chamber, shall not be repeated here in this Convention unless it takes from me the power delegated to me in the beginning.</p>
                <div className="speaker" id="sp507"><span className="persName">Mr. GOODE</span> resumed.</div>
                <p className="p-in-sp">
                  We have waited Mr. President; we have deliberated, but the gentleman from Franklin asks us to wait now, upon the committee of twenty-one. Wait upon the committee of twenty-one? Why, sir, we have waited; we have been here twenty days; that committee has been in solemn session upon this question of coercion ; a distinguished gentleman, who is a member of the committee, informed
                  <PageNumber num={455} /> the Convention ten days ago that they had this very question under consideration, and would be prepared to report in a very few days. Are we to wait any longer upon that committee? Is this Convention to be shorn of its strength and its power, and is the committee of twenty-one to be the "pent up Utica" which confines all our
                  powers? Is the committee of twenty-one to remain the slaughterhouse and the burial place of all the resolutions upon the question of coercion, which have been sent to them for their consideration?
                </p>
                <p className="p-in-sp">
                  Sir, the patience of the country is exhausted. The gentleman has referred to his own mountain home. I can tell him, for his consideration, that yesterday letters came to me from the mountains of Franklin, stating that the patience of that people, his own constituents, was well nigh exhausted. And, sir, I say I take up the words of the gallant and chivalrous gentleman from Prince
                  Edward
                 [<span className="persName">Mr. THORNTON</span>] , and I repeat, that the time has come for action, decisive, emphatic, earnest action. Now is the time to strike, or all may be lost.</p>
                <p className="p-in-sp">
                  Sir, the people of this Commmonwealth desire peace. They have desired peace. It is the daily prayer of this people to Almighty God that this hitherto favored country may be saved from all the untold horrors of civil war and fraternal strife. This peace-loving Commonwealth, with an unselfish and disinterested patriotism worthy of the pure and early days of the Republic, has thrown
                  herself into the breach, and with uplifted hands begged both sections to stay the hand that would explode the magazine and light up the fires of civil war.
                </p>
                <p className="p-in-sp">
                  How, sir, has she been met? The answer is contained in the inaugural address of Abraham Lincoln. He, the mere creature of an hour; he, the mere servant of the people; he, with all the solemn responsibilities of his official station, with his oath to support the Constitution just registered in Heaven, and still fresh upon his soul—he tells you that coercion is to be attempted; that
                  this Union is still unbroken, that he will not recognize acts of secession, that he will hold the forts and other public places within the seceded States, and that he will collect the duties upon imports. What does that mean, sir? If there is an apologist of Abraham Lincoln in this body—which I will not admit—but, if there is one here, I demand to know what does this mean? It means
                  war—war to the knife, and from the knife to the hilt; war, cruel, bitter, unrelenting war upon our friends, our kindred, and our brethren of the South. Will they submit? Submit! Why sir, it is a foul stain and a burning shame upon their fair fame and good name, to entertain that thought for a single moment. When it is attempted sir, when the programme laid down in that inaugural is
                  <PageNumber num={456} /> attempted—who believes that Jefferson Davis, the bright Saladin of the South, and the President of the Confederated States—who believes that he will hesitate one moment to march his proud legions upon the Capitol of the Black Republicans at Washington?
                </p>
                <p className="p-in-sp">
                  He will do it. He has said he would. He has said that if war must come, they should have war in earnest—and Jefferson Davis is an earnest man—that the war should be carried into Africa, that the battle should be fought upon Northern soil. And, sir, I for one stand here to-day, with all my heart, to bid him Godspeed. It would be my battle as well as his, it would be the battle of our
                  wives and children, it would be the battle for the very existence of the Southern people; and I hesitate not to declare—I don't know whether it will strike a responsive chord in the hearts of the members of this Assembly or not—but I hesitate not to declare that the Southern cause in such a struggle would be entitled to the sustaining prayers, the material aid, and the operative
                  sympathy of every man and woman in the land, upon the altar of whose heart the fires of liberty had not become entirely extinguished.
                </p>
                <p className="p-in-sp">
                  Mr. President, I do not intend to detain this Convention with a speech, for this is a time for action; but I want to say a few more words. I shake hands with the gentleman from Halifax that this is a peace measure. And why do I say it? Sir, he is an inattentive observer of the signs of the times, who does not see and know, that the people of Virginia are determined to resist coercion
                  to the death. And, sir, the first attempt that is made to carry out the doctrines of Abraham Lincoln, will light up this land with the flames of civil war, and raise a storm of fiery indignation throughout the length and breadth of this Commonwealth. Sir, the heavy tramp of her citizen soldiery would be heard from the sea-board to the mountains, the camp fires would blaze brightly
                  upon every hill-top, and the notes of martial music would be heard in every valley; her brave volunteers, her citizen-soldiery, the prop of the country and the flower of her youth, would come with arms in their hands, they would join the standard of JEFFERSON DAVIS, and press wherever they saw his white plume shining amid the ranks of war.
                </p>
                <p className="p-in-sp">
                  But I want to avoid it. How shall it be done? Why, sir, the civil power in this State could no more restrain this people than they could dam up the Nile with bulrushes. I want to preserve the peace ; and, in my opinion, there is no way in which it can be done but for Virginia to speak, and to speak to-day. We have tried a Peace Conference. Let us now try what virtue there is in a
                  firm, manly, and defiant <PageNumber num={457} /> attitude. And, sir, let the voice of Virginia go forth and peal into the ears of the President of the United States—a man who has but recently crept stealthily at night, under the garb of a foreigner, to place himself suddenly in a seat once occupied and honored by Washington, and Jefferson, and Madison,
                  and Monroe—a man stricken with the terrors of an evil conscience when about to disseminate those odious doctrines denounced by the gentleman who addressed this Convention a short time ago—a man who finds it necessary, in order to sustain those doctrines, to fill the streets of the National Capital with armed soldiers, and to put men, with rifles in their hands, upon the very house
                  tops in Washington. If we want to preserve the peace of the country and Commonwealth; if we want to preserve the lives of our wives and our little ones ; if we want to save this whole land from fire, and sword, and faggot, I earnestly appeal to this Convention to speak to-day, and to let the voice of Virginia go back to Washington upon the electric wire, that she will resist any and
                  all attempts at coercion at all hazards and to the last extremity.
                </p>
                <div className="speaker" id="sp508"><span className="persName">Mr. JOHNSON</span>, of Richmond—</div>
                <p className="p-in-sp">
                  Mr. President, it was entirely foreign from my purpose to rise on this floor this morning to address this Convention. And it may be now, sir, that I rise too much under the feelings which move me; for, after all the beautiful declamations and all the handsome elocution with which gentlemen have captivated this audience, I must acknowledge myself so obtuse in understanding as not to
                  precisely comprehend what question has been discussed. Sir, I for one must say that gentlemen seem to be in too much of a passion, and seem indisposed to do deliberately what cannot be well done unless it is done reflectively. I occupy a position of some embarrassment on this floor—a position somewhat of outside pressure ; and, sir, when my shoulders cease to be broad enough to bear
                  it, I shall surrender the post which I occupy.
                </p>
                <p className="p-in-sp">What, sir, is the subject now under consideration? As I understand it, there is a proposition of the gentleman from Chesterfield [Mr. Cox] , and a proposition of the gentleman from Goochland [<span className="persName">Mr. LEAKE</span>] , as an amendment to that, and a proposition of the gentleman from Amelia [<span className="persName">Mr. HARVIE</span>] 
                  , as a substitute for both. And, sir, what have we heard of either ?—what have we heard of either? I say, with a great deal of admiration for some who have spoken—and I will now draw no distinction between the gentlemen who have spoken, for the remark will apply to all--that there has been too much generality and too little particularity. Sir, where are we now? We are here as members
                  of this Convention, occupying, in my opinion, in the history of the <PageNumber num={458} /> world, the most important position that any other hundred and fifty-two individuals do occupy. This great trust is resting upon our shoulders by virtue of a responsibility placed upon us without our accord and by reasons beyond our control. It should induce
                  every member of this Convention to pause and to ponder, to feel and to think, and to pray—and for one, sir, I do confess, unworthy as I am, to approach the great tribunal of Omnipotent Providence, that I have invoked for myself, and if it were proper here, I would invoke for this Convention, that what in us is dark will be illumed, what is low, will be raised and supported, that we
                  may rise to the height of our great position and vindicate the great principles of Republican liberty.
                </p>
                <p className="p-in-sp">
                  Sir, consider, if you please, where we are. To dictate to the twenty-one members of this Convention, chosen by you, sir, after all the enquiry concerning their capacity and fitness for the position which has been assigned them, after having been in deliberation for three weeks, and with information before us that they are now about maturing and consummating those deliberations upon
                  the most momentous question that ever agitated the mind of mortal man with a full knowledge of those gentlemen, with a confidence in their patriotism, with a just comprehension of their fitness and qualification for the position which has been assigned them, knowing that they are doing what is best for the prosperity and honor of Virginia—to dictate to them the precise time when they
                  shall report to this Convention, I say, sir, is an insult which I will not reflect upon them. I am perfectly willing to vote for any resolution which informs them that it is the wish of this Convention that they should report at the earliest practical moment. But, knowing them as I do, knowing them as all the members of this Convention do, selected without distinction of party,
                  coming from all parts of Virginia, representing the best talent, and the best interests, and the best character of Virginia, I will not vote for any reflection upon their conduct, when I have reason to believe that each man in that committee is endeavoring to discharge his duty, to the very best of his ability.
                </p>
                <p className="p-in-sp">
                  Furthermore than that, sir : You have here vital resolutions, test resolutions, resolutions offered for the purpose of committing this Convention to a test vote, when there are twenty-one members absent in the discharge of a duty which this Convention has assigned to them; and I say, for one, that I would be unwilling to go into any test vote without having those members present.
                </p>
                <p className="p-in-sp">
                  And still further, sir : We have a report of the Peace Conference, in part, which report, upon its face, says that there are documents <PageNumber num={459} /> which have not yet been procured, because they could not be procured; and accompanying that report is a letter from the Hon. Judge Brockenbrough, which is ordered to be printed and published for
                  the enlightenment and information of this Convention. That Peace Conference, which was held at the instigation of Virginia; and gentlemen seem to me to have either the impetuosity or the temerity, whichever it may be called, to say that this Peace Conference is a failure, without having heard one word from it. Without having heard a word from that Peace Conference, gentlemen here,
                  say that the mission of our Commissioners is a failure. Well, sir, for reasons private and personal to myself, I have cause to believe that it is not a failure. I heard the distinguished gentleman from the county of Halifax
                 [<span className="persName">Mr. FLOURNOY</span>] 
                  , say that they hadn't come up to the mark. I will not say whether they have come up to the mark or not ; but I will say that I desire to hear from those gentlemen upon that question—and when I have heard from them, I wish to consider for myself how I can best represent the interests of my constituents. When we are to discuss that question, I think it altogether too inconsiderate to
                  take from the hands of the Committee the subject of coercion. For these reasons I shall vote against any resolution to take from their hands the deliberate consideration of that question. Yet I shall vote for a resolution instructing them to report as early as is practicable, but to report when they are ready, and not until then.
                </p>
                <p className="p-in-sp">
                  Now, sir, if gentlemen will tell me wherein Virginia will be worsted by this course, I will yield. My mind is open to conviction upon any question. But I ask how are we any better off to-day, than we will be to-morrow? Can any gentleman inform me? Will you be any weaker to-morrow than you are to-day? Have you any property to-day that you do not expect to have to-morrow? Are you not
                  the same sovereign State to-day that you will be a month hence? Have you not the same materials to aid you in sustaining your sovereignty? I say then, sir, upon this momentous question, in the consideration of which I deem to be involved, a very vital principle of Republican liberty itself, I, for one, am not in favor of this run-mad haste and this impetuous passion. Sir, I say that
                  for one, wherever my constituents wish me to go I will go; whatever they wish me to do I will do ; whatever Virginia does I will do ; and I wish that matter deliberately, calmly, soberly, reflected upon before any action is taken. And as we have the very best material that this Convention can afford, in the form of a Committee of twenty-one, I say let us await their report, and then
                  when we come to some determination, let us determine our course as Virginians, without regard <PageNumber num={460} /> to boundaries or sections; for the fate of Virginia shall be mine, and if I am asked where I will go, I will answer—I will go where Virginia goes, I will fight under no other banner than hers, I will march to the music of no other tune
                  than that which is inspired under the staff that lifts aloft her flag, and I wish to die under no nobler sentiment than the motto which is inscribed upon her banner—the brightest that ever adorned a nation's shield—"Virginia—Sic Semper Tyrannis."
                </p>
                <div className="speaker" id="sp509"><span className="persName">Mr. GRANT</span>—</div>
                <p className="p-in-sp">I do not rise to make a speech upon this occasion, but simply to declare to you and the members of this Convention, the position of the people whom I have the honor in part to represent in this Convention. I cannot understand why it is that the gentleman from Richmond [<span className="persName">Mr. JOHNSON</span>] 
                  , would delay the voice of this Convention in regard to the course Virginia should pursue, if coercion is attempted by Abraham Lincoln. I for one am willing to raise my voice against it, and in doing so, I but speak the united voice of the people of old Washington county, the first county named after the Father of his Country. They are, as one man, opposed to the coercive policy as
                  advocated in Abraham Lincoln's message. And why delay? I have sat here during this Convention, a silent and patient listener to the various members who have seen fit to express their opinions—and, sir, I confess that I have exercised the patience of old Job himself—until I can si t still no longer. I have thought that the action of Virginia had been proclaimed through the action of
                  our Legislature and by numbers of resolutions offered in this Convention sufficiently to declare to the world what the position of Virginia would be in regard to coercion. If it has not been sufficiently declared, why refuse to declare it to-day?
                </p>
                <p className="p-in-sp">
                  I am in favor of immediate action upon this point. Let Abraham Lincoln and his Black Republican cohorts know, that if any attempt be made to coerce any Southern sister State, they will have to march over the dead bodies of countless numbers of Virginia's true sons. I hope and trust that the blood which circulates within my veins, which I have derived from a noble ancestry, and who
                  have proved themselves such by their acts and deeds in maintaining the independence of their country, still lives and courses within this breast. For one, sir, I shall ever put my heel upon coercion in any shape or form.
                </p>
                <p className="p-in-sp">
                  I was sent here as a Union man. The representatives of Washington have been denounced as Submissionists by outsiders and the press. They are mistaken in the men, when they say that the representatives of old Washington are Submissionists. Never submit is my motto. I <PageNumber num={461} /> love my country. I have loved it long and dearly, and I had
                  hoped from the deliberations of the Peace Conference at Washington, which was inaugurated by the Legislature of Virginia, and from the deliberations of this body, that a brighter and happier day, ere this, would have dawned upon our now unhappy country.
                </p>
                <p className="p-in-sp">But, sir, I confess, in the gloom that still hovers around us, there is scarcely a ray of hope visible. I hope this Convention will, this moment, even in the absence of 21 members, make it the unanimous vote that Virginia shall resist coercion to the last extremity and at every hazard.</p>
                <p><span className="persName">Mr. EARLY</span> obtained the floor.</p>
                <div className="speaker" id="sp510"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">The gentleman from Franklin [<span className="persName">Mr. EARLY</span>] , is not entitled to speak again, if any other gentleman desires to speak upon the question.</p>
                <div className="speaker" id="sp511"><span className="persName">Mr. EARLY</span>—</div>
                <p className="p-in-sp">I merely rose to speak of a matter which is personal to myself.</p>
                <div className="speaker" id="sp512"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">The gentleman can proceed if no objection be made.</p>
                <div className="speaker" id="sp513"><span className="persName">Mr. EARLY</span>—</div>
                <p className="p-in-sp">The gentleman from Bedford [<span className="persName">Mr. GOODE</span>] 
                  , whose impatience has been so great and who has kept up the steam so high, not contented with the opportunities afforded to him for letting it off in this Convention, has on one or two occasions, let it off in the streets —undertakes to stand between me and my constituents, and he tells this Convention and the country that he has communications from my home, showing that the
                  patience of my constituents is exhausted. I take it for granted that the gentleman has informed the Convention of this fact, upon information which he is perfectly willing to disclose.
                </p>
                <div className="speaker" id="sp514"><span className="persName">Mr. GOODE</span>, of Bedford—</div>
                <p className="p-in-sp">Do you want me to answer?</p>
                <div className="speaker" id="sp515"><span className="persName">Mr. EARLY </span>—</div>
                <p className="p-in-sp">Yes, sir.</p>
                <div className="speaker" id="sp516"><span className="persName">Mr. GOODE</span>—</div>
                <p className="p-in-sp">
                  Since that gentleman has thought proper to arraign me in this manner before this Convention, I tell him, it has been a source of regret to me that somewhat of the spirit that animates other members of this body, has not been communicated to him. When he says that I have sought occasions here and elsewhere to harangue the people, I tell him that he makes the assertion without
                  foundation in fact; and I say that I have addressed no people in this city until they have sought me at my lodgings and called for me in a manner which I could not decline obeying.
                </p>
                <p className="p-in-sp">
                  I say again to the gentleman, that while I had no disposition to interfere between him and his constituents, I am endeavoring to perform my duty, and I hold myself responsible to him here or elsewhere. And, sir, as to his request that I should disclose the information which <PageNumber num={462} /> I have received, I state without hesitation that I have
                  received two letters, one from Col. Dillard, and the other from Mr. Garrett—two of the most respectable gentlemen in his county—stating that the secession feeling was clearly upon the increase there, and if the election was now to take place, in all probability there would be a different result.
                </p>
                <div className="speaker" id="sp517"><span className="persName">Mr. EARLY</span>—</div>
                <p className="p-in-sp">
                  The Convention will bear me witness that I did not say the gentleman sought to let the steam off in the street. I have seen it paraded in the paper that he had delivered one or two addresses. He tells me that he has received two letters—one from Col. Dillard and the other from Mr. Garrett. The latter gentleman I do not know at all, and he must have recently moved into the county.
                  Col. Dillard I do know, and I wish to say but one word in regard to him : He is a personal friend of mine, but he is the gentleman against whom I ran.
                 [Laughter.] In the canvass, his position was that I would be in too great a hurry to bring the deliberations of the Convention to a close, but that he, himself, was ready to devote one, two or three years to save the country.</p>
                <div className="speaker" id="sp518"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">The Chair suggests that the gentleman should confine his remarks to matters in the Convention.</p>
                <div className="speaker" id="sp519"><span className="persName">Mr. EARLY</span>—</div>
                <p className="p-in-sp">
                  In a Democratic county, giving 213 Breckinridge majority, I beat that gentleman 1060 votes. The other gentleman who has been referred to, I do not even know him by reputation, and I presume, therefore, that he has recently moved into the county. So much for the change that has taken place in my constituency. Let me tell the gentleman that he will have enough to do to settle his
                  account with his own constituents.
                </p>
                <div className="speaker" id="sp520"><span className="persName">Mr. GOODE</span>—</div>
                <p className="p-in-sp">
                  I would not have given this information to the gentleman, thus publicly, if I had an opportunity of seeing him elsewhere, but inasmuch as he thought proper to refer, in the terms that he did, to the views of his own constituents, I thought, as there was no telegraphic communication between this city and his constituents, that I had received information which it would be agreeable to
                  the gentleman to receive.
                </p>
                <div className="speaker" id="sp521"><span className="persName">Mr. HALL</span>, of Wetzel—</div>
                <p className="p-in-sp">I have not risen to make a speech, but to urge upon this body the necessity that they should do something. We have been here for 22 days. We have spent more than half a million of the people's money, and we have done positively nothing.</p>
                <div className="speaker" id="sp522"><span className="persName">Mr. BRANCH</span>—</div>
                <div className="stage it">[in his seat]</div>
                <p className="p-in-sp">.. Oh no, it cannot be so much!</p>
                <div className="speaker" id="sp523"><span className="persName">Mr. HALL</span>—</div>
                <p className="p-in-sp">These legislative bodies cost over $2,600 a day, and you take both Houses of the Assembly and this Convention and add their <PageNumber num={463} /> expenses together, and you will find out that the amount will come up to what I stated.</p>
                <p className="p-in-sp">
                  Here we are deliberating, talking, waiting and positively doing nothing. We have not made one single step of progress. I do ask the majority of this body to permit us, upon this occasion, to have the poor privilege of going under the yoke or objecting to it. We have now no choice but the sword or the yoke. If we are going under the yoke, let us enter our protest against it. In the
                  name of reason and justice we ask to enter our protest against it, and to tell this Yankee master of ours, that this yoke is galling and that we will resist it, if they attempt to force it upon us. I do hope that the Convention will pass a resolution now denouncing coercion and resisting it.
                </p>
                <p>Cries of question, question.</p>
                <div className="speaker" id="sp524"><span className="persName">Mr. CARLILE</span>—</div>
                <p className="p-in-sp">
                  Before the vote is taken upon the proposition which has been submitted, and which has been under consideration for the last few days, I desire, with the leave of the Convention, to submit some remarks, and I ask the privilege, not for myself individually, but that the people I have the honor to represent upon this floor, may be heard upon these all important questions.
                </p>
                <p className="p-in-sp">I am not physically in a condition, at this late hour of our sitting, to proceed with the remarks which I desire to submit. If it will be the pleasure of the Convention—I am sure it will be gratifying to the gentleman from Wetzel [<span className="persName">Mr. HALL</span>] , for perhaps we shall do something—I will submit a motion that we adjourn, and I will come here in the morning, prepared to condense the remarks that I design to submit to the Convention.</p>
                <p className="p-in-sp">
                  It is about the usual hour of adjournment. It is probably known to many members already, that I have been suffering severely ever since my arrival in the city, and if I had the authorities with me which I desire to consult, I don't know that I would have the physical ability to get through the remarks to-day which I desire to offer. I therefore move an adjournment.
                </p>
                <div className="speaker" id="sp525"><span className="persName">Mr. GRANT</span>—</div>
                <p className="p-in-sp">I ask the gentleman to withdraw that motion for a moment, to enable me to offer some resolutions.</p>
                <div className="speaker" id="sp526"><span className="persName">Mr. CARLILE</span>—</div>
                <p className="p-in-sp">Certainly, sir.</p>
                <p><span className="persName">Mr. GRANT</span> then offered the following resolutions, which were referred to the Committee on Federal Relations :</p>
                <p>
                  1. Resolved, That we, the people of Virginia, in Convention assembled, deeply regret the condition in which our country is placed, imperiling, as it does, the peace, prosperity and perpetuity of one of the noblest governments ever adopted by man. <PageNumber num={464} /> 2. Resolved, That Virginia has ever been inclined to a peaceful and amicable
                  adjustment of the difficulties that have so long menaced and threatened the destruction of our institutions, and has ever exerted her power and influence to that end. But, notwithstanding all her efforts, the future is still dark and portentous. Scarcely a ray of hope illumines it.
                </p>
                <p>3. Resolved, That from the tone and temper of the inaugural address of the recently inaugurated President, Abraham Lincoln, that he, in that address, declares his right to coerce the seceded States, and makes it a matter of policy, subject only to his discretion, whether he will attempt it or not.</p>
                <p>4. Resolved, That we, the people of Virginia, in Convention assembled, in the event that any attempt is made to coerce any of our sister States of the South, that Virginia will not submit to any attempt, but will make common cause with them, and resist coercion with all her power to the last extremity.</p>
                <p>
                  5. Resolved, That the honor, institutions and interests of Virginia are closely allied and identified with those of all the Southern States, but more directly at this time with the border slave States. And in view of effecting united action on the part of said States, and of ultimate union of the whole South, we the people of Virginia, through our representatives in Convention
                  assembled, doth earnestly request the States of Delaware, Maryland, North Carolina, Tennessee, Kentucky, Missouri and Arkansas, to meet the Commissioners, which shall
                </p>
                <p>be appointed by Virginia, in the city of Knoxville, by the day</p>
                <p>of , to advise and counsel what is best to be done under existing</p>
                <p>circumstances, the result of their deliberations to be referred for ratification either to Conventions of the several States or the Legislatures thereof, and that this Convention proceed to appoint Commissioners on the part of Virginia, and take the necessary steps to secure the co-operation of the aforesaid States in the proposed Conference.</p>
                <div className="speaker" id="sp527"><span className="persName">Mr. NEBLETT</span>—</div>
                <p className="p-in-sp">I am the last gentleman in this assembly who would show the least discourtesy towards any gentleman ; but I do hope that this Convention will not adjourn without taking a vote.</p>
                <div className="speaker" id="sp528"><span className="persName">Mr. CARLILE</span>—</div>
                <p className="p-in-sp">I renew my motion to adjourn.</p>
                <div className="speaker" id="sp529"><span className="persName">Mr. NEBLETT</span>—</div>
                <p className="p-in-sp">On that motion I call the yeas and nays.</p>
                <p>The call was sustained, and the vote being taken resulted yeas 73, nays 42, as follows :</p>
                <p>YEAS-Messrs. John Janney [President] 
                  , Aston, Alfred M. Barbour, Baylor, Berlin, Boggess, Bouldin, Branch, Brent, Brown, Burdett, Burley, Cabell, Campbell, Carlile, Carter, C. B. Conrad, Couch, Custis, Dent, Deskins, Dorman, Dulany, <PageNumber num={465} /> Early, Flournoy, Forbes, Fugate, Gillespie, Gravely, John Goode, Jr., Ephraim B. Hall, Hammond, Haymond, Holcombe, Hubbard, Hughes,
                  Hull, Marmaduke Johnson, Kent, Lewis, McGrew, McNeil, Marshall, Marr, Masters, Moffett, Nelson, Orrick, Parks, Patrick, Pendleton, Porter, Pugh, Randolph, Saunders, W. C. Scott, Sharp, Sitlington, Slaughter, Spurlock, Staples, A. H. H. Stuart, C. J. Stuart, Summers, Sutherlin, Tarr, Tayloe, Thornton, Tredway, Tyler, White, Wilson, WOODS-73.
                </p>
                <p>
                  NAYS-Messrs. Ambler, Armstrong, Boisseau, Borst, Caperton, Cecil, Chambliss, Chapman, Coffman, Conn, J. H. Cox, R. H. Cox, Critcher, Echols, French, Graham, Grant, Gregory, Jr., Goggin, T. F. Goode, Addison Hall, Cyrus Hall, L. S. Hall, Hunton, Kilby, Kindred, Lawson, Leake, C. K. Mallory, Marye, Sr., Morris, Morton, Neblett, Richardson, Sheffey, Speed, Strange, Robert H. Turner, F.
                  P. Turner, Whitfield, Williams-42.<span className="note" id="Note56"><span className="noteLabel">5</span>The Journal lists Maslin and Waller as voting no, but omits Marye's name from the negative votes.</span>
                </p>
              </div>
              <div className="section" id="vsc1965.v1.2.18.4">
                <h3><span className="headingNumber">1.18.4. </span><span className="head">EXPLANATION OF VOTES</span></h3>
                <div className="speaker" id="sp530"><span className="persName">Mr. TURNER</span>-When his name was called, said—</div>
                <p className="p-in-sp">I do not intend any discourtesy to the gentleman [<span className="persName">Mr. CARLILE</span>] -I would be delighted to hear from him, but feeling convinced that some action is necessary on the part of this Convention, I vote in the negative.</p>
                <div className="speaker" id="sp531"><span className="persName">Mr. WILSON </span>When his name was called, said—</div>
                <p className="p-in-sp">I am anxious some action should be taken, but inasmuch as my colleague desires to address the Convention—and as he complains of being unwell, I shall vote aye.</p>
                <p>The Convention then adjourned.</p>
              </div>
            </div>
            <div className="day" id="vsc1965.v1.2.19">
              <PageNumber num={466} />
              <h2><span className="headingNumber">1.19. </span><span className="head">NINETEENTH DAY</span></h2>
              <div className="dateline">Thursday, <span className="date">March 7</span></div>
              <p>The Convention met at 12 o'clock.</p>
              <p>Prayer by the Rev. T. V. MOORE, of the Presbyterian Church.</p>
              <div className="section" id="vsc1965.v1.2.19.1">
                <h3><span className="headingNumber">1.19.1. </span><span className="head">FINANCIAL AFFAIRS</span></h3>
                <p><span className="persName">Mr. BROWN</span>, of Preston—I believe, Mr. President, that it will be in order this morning, with the consent of the gentleman from Harrison [<span className="persName">Mr. CARLILE</span>, who was entitled to the floor] , to offer a resolution before the business of the day shall be taken up.</p>
                <div className="speaker" id="sp532"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">A resolution can be offered by consent of the gentleman from Harrison, if it lead to no debate.</p>
                <div className="speaker" id="sp533"><span className="persName">Mr. BROWN</span>—</div>
                <p className="p-in-sp">I cannot tell whether the resolution that I propose to offer will lead to debate or not. I certainly do not intend to debate it, sir. I desire, however, if my friend from Harrison [<span className="persName">Mr. CARLILE</span>] , will permit, to offer the resolution; and, in doing so, to state the reasons why I offer it.</p>
                <p>I desire it to be read, sir, and then I will simply state the reasons why I have offered it.</p>
                <p>The resolution reads as follows :</p>
                <p>"Resolved, That the President of the Convention appoint a Committee of members to be styled the 'Committee of Finance,' whose duty it shall be to take into consideration all subjects to it referred in relation to State and county taxation."</p>
                <div className="speaker" id="sp534"><span className="persName">Mr. BROWN</span>—</div>
                <p className="p-in-sp">We are now all aware that we are drifting rapidly, drifting to that point where men and money, the sinews of war, will be required.</p>
                <p className="p-in-sp">
                  With regard to men, we have them ; but, sir, with regard to money, we have it not. Sir, if you will just look, with a view to show our force of men, at the report of your Auditor, you will find that there are nearly 40,000 men in Virginia who cannot pay their taxes. In the city of Richmond, out of 32 or 33,000 persons there are 1,770 that cannot pay their taxes.<span
                    className="note"
                    id="Note57"
                  ><span className="noteLabel">1</span>Mr. Brown corrected this statement in the Proceedings of March 11, page 545.</span
                  >
                </p>
                <p className="p-in-sp">Now, sir, these persons will make noble soldiers. They are patriotic men, and good men—they are men whose patriotic emotions can be aroused by such appeals as are every day made here; and while I want the services of such men in the event of a war, I desire that they shall be well armed, well fed, and well clothed.</p>
                <p className="p-in-sp">Sir, you are aware that there is a large portion of the property of <PageNumber num={467} /> this State that is now exempt from taxation—that there is a Constitutional prohibition which prevents its being reached by the tax-laying power of the State.</p>
                <p className="p-in-sp">I desire, sir, to have this committee raised, and all resolutions looking to the subject of finance and taxation referred to it. My noble friend from Northampton [<span className="persName">Mr. FISHER</span>] 
                  , the other day, as you are aware, sir, offered a resolution looking to the appropriation of millions for the defence of the State. I know, sir, that he will come to the rescue and will aid me in initiating steps, and in initiating measures by which the funds can be raised. You know, sir, very well that in the present condition of State securities we cannot borrow a dollar. You are
                  aware that the other day at Washington, upon the report of the Peace Conference, the hopes of the nation were revived, and, sir, Virginia stocks went up in one day five per cent in the city of New York. Sir, the very next day or two—I believe the very next day—upon the action of the Senate in relation to that report, they went down again. Our action here, sir, since the adjournment
                  of Congress has further affected our State securities, so that our sources must be raised, not by borrowing money, but by taxation ; and I want the whole field opened, and all species of property subjected to the ad valorem system.
                </p>
                <p className="p-in-sp">
                  While I am opposed to all steps that will involve us in a war, yet I declare to you, sir, in the event that war must come, every dollar's worth I have shall be subject to taxation. Aye, sir, and the dollar itself shall be at the disposal of the State to arm and clothe the true and brave men that we may send to the field. I do not want to send them, sir, in-a condition in which they
                  would not do honor to the old Commonwealth. Sir, I desire action to be taken in reference to this subject, and that at the earliest period possible.
                </p>
                <p className="p-in-sp">
                  It was stated in the "Enquirer" of yesterday morning that there was a bargain—yes, sir, a bargain—made between the Western Anti-Secessionists and the Eastern Anti-Secessionists, by which Western men were to vote against this ad valorem system of taxation upon all the property of the East in consideration that these Eastern Anti-Secessionists were to keep the West in the State.
                </p>
                <p className="p-in-sp">
                  Now, sir, if there be any such Western men as these I want to know them. I want my constituents to know that I, at least, am not bargaining their rights away, and with that view, I want to have my vote recorded when the proper time arrives. Other Western men who may have a desire to be set right upon this subject, can pursue a like policy if they choose. I am satisfied, sir, that
                  Eastern citizens are prepared now, in that generous spirit which I hope animates them, to <PageNumber num={468} /> subject their property to equal taxation. I want those true and brave men of the East, who have delivered such patriotic speeches here, to come forward and help us now to raise the sinews of war. Sir, I do not want our army to be fed with
                  patriotic speeches and resolutions. I want them to have good coffee for breakfast; I want them to have good blankets to keep them warm ; I want them by all means to be well armed.
                </p>
                <p className="p-in-sp">
                  These are the considerations which induced me to offer that resolution, and I trust it will be the pleasure of the Convention to adopt it. I want the Eastern Secessionists, if they believe the Richmond "Enquirer," which is their organ, and I am satisfied they do believe it—I have been a reader of the "Enquirer" for nearly 40 years I doubt the propriety, sir, of indicating a time so
                  far back, and thus making myself appear so old—but, sir, I have been reading that paper since 1823, and have always had great faith in it, and I suppose it is now well informed upon this subject—I was going on to say, sir, that I hope the Eastern Secessionists, who believe in the doctrines of the "Enquirer," will join me in carrying out the object of that resolution. I know they are
                  really sincere in their secession sentiments, and I would therefore ask them to come up and assist me in procuring the sinews of war. Let my friend from Northampton
                 [<span className="persName">Mr. FISHER</span>] , ask that the resolution which he offered be referred to the committee proposed to be raised by the resolution which I offer. We all know that a war cannot be carried on without the necessary means to prosecute it—and it is therefore the duty of those who favor secession to come up and provide these means.</p>
                <div className="speaker" id="sp535"><span className="persName">Mr. ECHOLS</span>, of Monroe—</div>
                <p className="p-in-sp">At the risk of being considered a party to the bargain to which the gentleman refers, I move to lay the resolution on the table.</p>
                <div className="speaker" id="sp536"><span className="persName">Mr. BROWN </span>—</div>
                <p className="p-in-sp">Upon that motion I call the yeas and nays.</p>
                <p>The call was sustained.</p>
                <div className="speaker" id="sp537"><span className="persName">Mr. TURNER</span>—</div>
                <p className="p-in-sp">I desire to know whether an amendment or substitute for the resolution will be in order?</p>
                <div className="speaker" id="sp538"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">Not now, sir. The motion is to lay on the table.</p>
                <p>The yeas and nays were then called, and resulted—yeas 69, nays 41 as follows :</p>
                <p>AYES-Messrs. John Janney [President] 
                  , Ambler, Aston, Blakey, Boisseau, Borst, Bouldin, Branch, Brent, Cabell, Campbell, Cecil, Chambliss, Chapman, Conn, Couch, J. H. Cox, Richard H. Cox, Custis, Dulany, Early, Echols, Fisher, Flournoy, Forbes, Graham, Grant, Gray, Gregory, Goggin, Thomas F. Goode, Addison Hall, Hammond, Hoge, Holcombe, Holladay, Hunton, Marmaduke Johnson, Kent, Kilby, Kindred, Leake, C. K. Mallory,
                  Marshall, Marr, Marye, <PageNumber num={469} /> Sr., Moffett, Morris, Morton, Nelson, Parks, Pugh, Randolph, Richardson, Saunders, Sr., Seawell, Slaughter, Speed, Spurlock, Staples, Strange, Sutherlin, Tayloe, Thornton, R. H. Turner, Tyler, Waller, White, Whitfield-69.
                </p>
                <p>
                  NOES-Messrs. Armstrong, Alfred M. Barbour, Baylor, Berlin, Boggess, Brown, Burdett, Burley, Carlile, Coffman, C. B. Conrad, Dent, Deskins, Fugate, Gillespie, Gravely, Cyrus Hall, Ephraim B. Hall, L. S. Hall, Haymond, Hubbard, Hughes, Hull, Lawson, Lewis, McGrew, McNeil, Masters, Orrick, Osburn, Patrick, Pendleton, Porter, Sharp, Sitlington, C. J. Stuart, Summers, Tarr, Franklin P.
                  Turner, Wilson, WOODS-41.
                </p>
                <p>So the motion to lay on the table was agreed to.</p>
              </div>
              <div className="section" id="vsc1965.v1.2.19.2">
                <h3><span className="headingNumber">1.19.2. </span><span className="head">EXPLANATION OF VOTES</span></h3>
                <p>When the name of <span className="persName">Mr. GOODE</span>, of Bedford, was called,</p>
                <div className="speaker" id="sp539"><span className="persName">Mr. GOGGIN</span> said—</div>
                <p className="p-in-sp">Mr. President, I rise for the purpose of stating that my colleague, <span className="persName">Mr. GOODE</span>, is absent from the Convention on account of illness.</p>
                <div className="speaker" id="sp540"><span className="persName">Mr. SUTHERLIN</span>, when the name of Mr. TREDWAY was called, said—</div>
                <p className="p-in-sp">Mr. President, I desire to state that my colleague is absent from Richmond on account of illness in his family.</p>
                <div className="speaker" id="sp541"><span className="persName">Mr. EARLY</span>—</div>
                <p className="p-in-sp">With the consent of my friend from Harrison [<span className="persName">Mr. CARLILE</span>] , I desire to correct an error which appeared in the report of the remarks which I had the honor to submit on yesterday.</p>
                <p className="p-in-sp">It is exceedingly painful to me to have to intrude myself upon the Convention in this way. I know that my remarks are of little consequence, and am therefore unwilling to occupy the time of the Convention for any longer time than justice to myself and those whom I represent may require.</p>
                <p className="p-in-sp">
                  But as these debates are reported by authority of the Convention, and as my constituents are relying upon my colleague and myself to represent their wishes and feelings on this floor, and as I know they all read whatever I may have said, or may hereafter say, with some degree of interest, I feel it due to them and myself, to have what I do say, correctly reported.
                </p>
                <p className="p-in-sp">In referring to the remarks of the gentleman from Prince Edward [<span className="persName">Mr. THORNTON</span>] , with regard to the rights of minorities, yesterday, I am reported as having said this :</p>
                <p className="p-in-sp">"But, sir, can these rights be asserted, not by force or violence, but under the Constitution and according to the laws? I think they can sir."</p>
                <p className="p-in-sp">
                  There is no sense in this remark. What I said—what I intended, at least, to say was this : "But these rights are to be asserted, not by force or violence, but under the Constitution and according to the laws." <PageNumber num={470} /> I am also reported as having used the following language without any further explanation : "The gentleman tells you that
                  this doctrine of the divine right of the majority and of the obligation of the minority to submit, cost one king his head another his crown."
                </p>
                <p className="p-in-sp">
                  And there my remarks stop upon that subject, so that an improper inference might be drawn upon what I did say. The language which I used is pretty much this : "The gentleman from Prince Edward tells you that this claim of the divine right of the majority and the duty of passive obedience on the part of the minority, cost one king his head and another his throne." I have read history
                  differently. According to my reading of it, it was not the claim of the divine right of the majority and the duty of passive obedience on the part of the minority, that cost one king his head and another his throne; but it was the claim of the divine right of the king and the duty of passive obedience on the part of the majority—that is the people—which cost one king his head and
                  another his throne.
                </p>
              </div>
              <div className="section" id="vsc1965.v1.2.19.3">
                <h3><span className="headingNumber">1.19.3. </span><span className="head">THE POSSESSION OF THE FORTS, &amp;c.</span></h3>
                <div className="speaker" id="sp542"><span className="persName">Mr. NELSON</span>—</div>
                <p className="p-in-sp">With the permission of the gentleman from Harrison [<span className="persName">Mr. CARLILE</span>] , I beg leave to offer the following resolution—</p>
                <div className="speaker" id="sp543"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">Does the gentleman from Harrison yield the floor for that purpose?</p>
                <div className="speaker" id="sp544"><span className="persName">Mr. CARLILE</span>—</div>
                <p className="p-in-sp">Yes, sir.</p>
                <div className="speaker" id="sp545"><span className="persName">Mr. NELSON</span>—</div>
                <p className="p-in-sp">I offer the resolution then.</p>
                <p>The resolution was then read as follows :</p>
                <p>Resolved, by the Convention of Virginia, That Virginia entirely disapproves of, and earnestly protests against any attempt on the part of the Federal Government to re-possess itself of the property and places belonging to the Government, and to collect the duties or imports within the limits of the seceding States.</p>
                <div className="speaker" id="sp546"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">The pending question is upon the amendment offered by the gentleman from Amelia [<span className="persName">Mr. HARVIE</span>] , to the amendment offered by the gentleman from Goochland [<span className="persName">Mr. LEAKE</span>] , and upon that question, the gentleman from Harrison [<span className="persName">Mr. CARLILE</span>] , is entitled to the floor.</p>
              </div>
              <div className="section" id="vsc1965.v1.2.19.4">
                <h3><span className="headingNumber">1.19.4. </span><span className="head">THE REPORTING AND PUBLICATION OF DEBATES</span></h3>
                <div className="speaker" id="sp547"><span className="persName">Mr. HUGHES</span> of Randolph and Tucker—</div>
                <p className="p-in-sp">With the permission of the gentleman from Harrison [<span className="persName">Mr. CARLILE</span>] , I would ask to make a report from the committee appointed to contract with the Editors and <PageNumber num={471} /> proprietors of the Richmond "Enquirer" in relation to the publication of the debates in a separate sheet.</p>
                <div className="speaker" id="sp548"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">Does the gentleman from Harrison give way.</p>
                <div className="speaker" id="sp549"><span className="persName">Mr. CARLILE</span>—</div>
                <p className="p-in-sp">I shall leave the matter with the Convention.</p>
                <div className="speaker" id="sp550"><span className="persName">Mr. HUGHES</span>—</div>
                <p className="p-in-sp">I shall occupy no time; and it is important that the report should be submitted.</p>
                <div className="speaker" id="sp551"><span className="persName">The PRESIDENT</span></div>
                <p className="p-in-sp">I presume there is no objection to the gentleman's presenting it.</p>
                <div className="speaker" id="sp552"><span className="persName">Mr. HUGHES</span>—</div>
                <p className="p-in-sp">I shall now present the report, sir, and inasmuch as it is probable that the Convention would desire to see what the Committee has done, I will move to lay the report on the table, and that it be printed.</p>
                <p>The motion was agreed to.</p>
                <p>The following is the report :</p>
                <div className="floatingText">
                  <div className="floatingText_body">
                    <p>REPORT OF THE COMMITTEE</p>
                    <p>The undersigned Committee appointed to ascertain whether the debates of the Convention could be published on sheets containing no other matter, report that in discharge of their duty, they handed to one of the Proprietors of the "Enquirer," the following communication :</p>
                    <p>Resolved, That a Committee of three be appointed to confer with the Editors of the Richmond "Enquirer," to ascertain whether the proceedings of this Convention can be published upon a separate sheet without any other matter.</p>
                    <p>The Committee appointed under the foregoing resolution, deem it desirable that the debates of the Convention should be printed in a more convenient form for binding and reference, than now. They, therefore, in furtherance of the duty assigned them by the resolution aforesaid, submit the following inquiries to the Proprietors of the "Enquirer."</p>
                    <p>1st. On what terms would the Proprietors of the "Enquirer" substitute for their present contract for printing of the debates, an agreement to print them on sheets without any other matter in a form, more convenient for binding and reference.</p>
                    <p>
                      2d. On what terms would they substitute for the 20 copies of the Semi-Weekly "Enquirer" now furnished members of the Convention, the same number of copies of the debates of the Convention, made up from the columns of the "Enquirer," and printed upon the same paper, folded into octavo form, with three columns on each page, each copy to be stitched, and to contain no other matter
                      but the said debates? <PageNumber num={472} /> What would be the cost of copies above the number of 20 to each member?
                    </p>
                    <p>3d. On what terms would they substitute for the 20 copies of the "Enquirer" now furnished each member of the Convention-20 copies of an extra sheet of the "Enquirer" containing no other matter than the debates of the Convention, the said extra to be printed whenever there is matter enough to fill a sheet of the Enquirer paper?</p>
                    <p>What would be the cost per copy of a greater number than 20 to each member?</p>
                    <p>JNO. HUGHES,</p>
                    <p>W. C. WICKHAM, Committee.</p>
                    <p>GEO. RICHARDSON,</p>
                    <p>
                      That in response thereto they received a communication from the proprietors of the "Enquirer," declining to make any alteration whatever in their present contract, but proposing to "furnish in addition to the 20 semi-weeklies which the contract calls for, upon the terms of the present contract, without additional charge for composition and reporting, any number of copies on a
                      separate sheet, without any other matter, which the Convention may order."
                    </p>
                    <p>
                      Your Committee compute the cost of 20 copies to each member, if the publication is deferred until material enough is furnished to fill a sheet of the Enquirer paper; at about 100 dollars per week, allowing 64 columns per week as the average amount of matter, and excluding from calculation any allowance for folding and wrapping, which would increase the cost by the sum of 121
                      dollars and 60 cents per week.
                    </p>
                    <p>Inasmuch as many members of the Convention disapprove of the character of some of the "other matter" that under the existing arrangement accompanies the debates, the Committee recommend the adoption of the following resolution :</p>
                    <p>
                      Resolved, That the Committee appointed on Printing the Debates on separate sheets, be directed to endeavor to contract with the proprietors of the "Enquirer" to furnish each member of the Convention with twenty copies of the Debates from their commencement, to be printed in accordance with and on the terms indicated in their report, provided, that no contract shall be made for
                      folding and wrapping the same. And if they can obtain such contract it shall be submitted to the Convention for its action.
                    </p>
                    <p>JOHN HUGHES, Chairman</p>
                  </div>
                </div>
                <p><span className="persName">Mr. CARLILE</span> then took the floor and addressed the Convention at some length.</p>
                <PageNumber num={473} />
                <div className="speaker" id="sp553"><span className="persName">Mr. JOHN S. CARLILE</span>, of Harrison—</div>
                <p className="p-in-sp">
                  Mr. President, in this the hour of your country's greatest peril, when the strength of our system of government is being severely tested, I should be slow to believe that any but patriotic emotions could influence the members of this body. Candor and frankness, therefore, should characterize our discussions, and a love of country alone should influence our deliberations. In this
                  spirit I enter upon this discussion.
                </p>
                <p className="p-in-sp">
                  The resolutions before the Convention are designed, and if adopted will have the effect, to place Virginia in hostility to the Federal Government, which Federal Government is Virginia's government. In other words, to commit Virginia to a war against herself, and to connect her with the Cotton States, so as to share with them the disastrous consequences that may flow from the
                  rebellious attitude assumed for them and in their name, by the men who for the time have the control of their respective State Governments. Mark it well, Mr. President; note it, gentlemen of the Convention; look to it, ye people of Virginia—it is the purpose of those who are pressing with such eagerness and such earnestness upon this body these resolutions, if they can have them
                  adopted here, never, never to allow the people to pass upon them.
                </p>
                <p className="p-in-sp">
                  And, sir, it is not anything in the inaugural address of the President of the U. States that has induced the submission of these resolutions at this time. I grant, sir, that the delivery of that address and its appearance has been made the occasion, as the election of the man was made the occasion by the Cotton States to dissever their connection, so far as they can do it, from the
                  rest of the United States. I will here remark, Mr. President, that every movement that has been made in the State of Virginia, looking to secession, has been in exact conformity to the programme laid down by the Richmond "Enquirer." In October last, before the election, the editors of that paper advised the Cotton States immediately and separately to secede, and stated that they
                  would inevitably drag Virginia after them. This is the sentiment of gentlemen who profess an ardent love for a mother Commonwealth
                </p>
                <p className="p-in-sp">she who has been accustomed to give law to the States of this Confederacy; it is an exhibition of their fondness for her, to place her in a condition to be dragged at the heel of the Cotton States of this Confederacy.</p>
                <p className="p-in-sp">
                  I have said that the appearance of the Inaugural Address of the President has been merely seized upon as the occasion for the submission of these resolutions, and the eloquent declamation to which we have listened day after day, during the present week, has been but in compliance with the programme "to fire the Southern heart," to induce
                  <PageNumber num={474} /> members of this body, if possible, to forget that they had a constituency behind them to whom they were responsible—not responsible to those who get up meetings in the streets of this city, and call for reports from Peace Commissioners to be made to them. On the 19th day of February, more than two weeks before the Inaugural
                  Address appeared, the programme was laid down and published in the Richmond "Enquirer" as follows :
                </p>
                <p className="p-in-sp">"Aye, the Convention now assembled in this city, can, in one day, in one hour, take action which cannot fail to restore our Union, maintain our honor, and preserve an honorable peace in the Union.</p>
                <p className="p-in-sp">
                  "This can be done by a single ordinance—by an ordinance which will not require even reference to the people under the prescribed terms of the legislative act and the late popular vote—by an ordinance which will involve neither secession nor nullification; and comprehending only such action as a State may take in the Union, and in strict conformity with the letter itself of the
                  Constitution of the United States.
                </p>
                <p className="p-in-sp">"Let the Convention command the confidence of all the Southern States by declaring the fixed intention of Virginia to resist all attempts to coerce a Southern State; let it command the full sympathy of the Southern States by declaring that if separation shall prove final and irremediable, Virginia will cast her lot with that of her Southern Sisters."</p>
                <p className="p-in-sp">
                  This is the programme of the editors of this paper. Without having the pleasure of a personal acquaintance with them, I have the same respect, for any opinion they might give, that I would have for the opinions of any three respectable gentlemen, and no more ; so far as the cracking of their whip over my back is concerned, it will affect my action just as much as the cracking of any
                  other three gentlemen's whip over my back might effect it, and no more.
                </p>
                <p className="p-in-sp">
                  Let us look again, and see if we cannot take another peep into the programme. Mr. President, I have listened, in a body representing the sovereignty of my native Commonwealth, to appeals made to my fears, and through me to the fears of the people, to induce us to do that which gentlemen must suppose that if we were not influenced by fear, could not command the approbation of our
                  judgments. We are urged to adopt these resolutions "to save Virginia from civil war." Oh, but a tear will course down my cheek, when the fact is made patent to my mind that my mother Commonwealth is to be driven into a course of conduct which her judgment does not approve, by appeals to her fears!
                </p>
                <p className="p-in-sp">Sir, of course I know it is not intended that such will be the case ; <PageNumber num={475} /> but I am satisfied that gentlemen have not reflected wisely, before they submitted such arguments in behalf of their propositions.</p>
                <p className="p-in-sp">
                  But that those who are the authors of this plan, and in the carrying out of whose programme these resolutions have been offered, care very little about civil war, we will find in that paper of the 4th March, 1861, clothed in mourning. "EXPEL THE INVADER aT ONCE ;55 that is the heading of the editorial. I shall not detain the Convention by reading the whole of it; I will simply call
                  the attention of the Convention to the last sentence :
                </p>
                <p className="p-in-sp">"Let the Confederate States once appeal to arms for resistance to invasion, and the Submissionist programme loses its last prop on the border States."</p>
                <p className="p-in-sp">That is what those gentlemen say in that editorial. They understand their programme well. I have no doubt of it. But they will never be able to succeed in carrying it out and accomplishing their purpose.</p>
                <p className="p-in-sp">
                  Mr. President, how different is the "Enquirer" of 1860-61 from the "Enquirer" of 1858 ! Will any gentleman explain to me what has produced this change which 'has come over the spirit of its dreams? Everything in the Federal and State Governments is precisely where it was in 1858 with the solitary exception of Mr. Lincoln as President of the United States, and the going out of Mr.
                  Buchanan, who has held that position for the last four years. Every personal liberty bill that is or has been upon the statute book of any of the non-slaveholding<span className="note" id="Note58"><span className="noteLabel">2</span>The word "states" has probably been omitted here.</span> was there in 1858. The same anti-slavery sentiment, the same sentiment of hostility to the institution
                  of African slavery existed in '58 as much as it exists in '61. And yet that "Enquirer" speaks differently, and in a different tone.
                </p>
                <p className="p-in-sp">In its issue of July 23, 1858, it says :</p>
                <p className="p-in-sp">
                  "The shrill-tongued faction which has dinned in our ears so unmercifully with the cry of disunion, is composed of three distinct classes. Of these, the first is by far the most respectable—it consists of Simon Pure disunionists, who are laboring honestly and openly for a dissolution of the Union. The second is made up of men whose real object is disunion, but who cloak it under
                  flimsy pretences and disguises. The third set are no disunionists at all, but a mere band of malcontents, disappointed in their political aspirations, who require a thorough disorganization and re-organization of parties to offer opportunity for their own elevation to power, and find no scheme so available as that of exciting sectional and factionary differences among the members of
                  the only remaining National party."
                </p>
                <p className="p-in-sp">
                  Now, Mr. President, I shall not detain this Convention by attempting <PageNumber num={476} /> further proofs of the remarks I have made in relation to these resolutions. I think it will plainly appear that these resolutions were determined upon before the Inaugural address was delivered ; that the appearance of that Inaugural has only been seized upon
                  as the occasion, with the hope that in the excitement which gentlemen could get up in opposition to that inaugural, they might possibly succeed in passing through this body these resolutions, thus carrying out the programme by which the people who sent us here and who were induced to believe that all or any action of this body would first have to pass their supervision before it
                  would receive the authority of law, are to be deprived of the privilege of passing upon them, and a clash of arms is to be brought on by the Confederate States; and Virginia, having been induced to take this position, thus recommended to her by these gentlemen of the "Enquirer," she is to be committed without consulting her people, by the action of her advisatory representatives, for
                  you are nothing more, to all the horrors of civil war; not alone to share, as I said, with the Cotton States; but to stand here and receive it all for their benefit.
                </p>
                <p className="p-in-sp">
                  Mr. President, what are we called upon to do? Let us examine these resolutions? Let us see what gentlemen expect of this Convention? To make war upon the Constitution of our own country; to destroy our own Government, the work of our own revolutionary fathers; and, if I may be allowed to cite authority which I presume will be respected by this Convention, not alone their work. I now
                  read, sir, from an address delivered a little more than two years ago, by a distinguished gentleman, who, at that time, occupied the Chair of State in Virginia. It was delivered at a time when the remains of one of Virginia's distinguished sons PRESIDENT MONROE-had been brought to her own Capitol, by the Seventh Regiment of New York ; that New York which these gentlemen would have to
                  be a foreign government to Virginia. On that occasion the Governor of Virginia said :
                </p>
                <p className="p-in-sp">"Look to the formation of the Constitution after the articles of Federation had been signed. When your fathers attempted to form this Union, they did not calculate what sort of a Union it was to be. They agreed upon a Union for Union's sake, and, by all the gods, I too, go for the Union for the Union's sake! [Tremendous applause.] They went to work for the best Union they could make, and they did give us the best Union and the best Government the world ever saw. [Renewed applause.] But, Jefferson did not make it, nor Madison, or his co-laborers make it. GOD ALMIGHTY MADE IT. It was the work of inspiration. I believe that, as I believe in the Bible." <PageNumber num={477} /> That is the language of a patriot and a distinguished gentleman, but two years ago, when he was your Governor.</p>
                <p className="p-in-sp">I will invoke again the same distinguished authority, at a later period, in behalf of the Constitution and the Union of my country. Governor WISE delivered an address in this city, in May 1859, in which he said ;</p>
                <p className="p-in-sp">"And if any would array this country's parts against each other in sectional division and strife, let them have no inheritance in the whole—the grand, great whole; but let them selfishly have a single, small place for their safe keeping, a home made for treason, felony, or mania, a prison or a mad-house.</p>
                <p className="p-in-sp">
                  "They cannot destroy the Union without destroying States and homes, and they cannot destroy homes and States without destroying the Union. By strengthening each part we fortify the whole, and by fortifying the whole we protect each part. Each and all is ours; each and all belongs to all equally and alike ; in the part and in the whole all citizens are seized; all, North and South,
                  East and West, white and black, native and naturalized, bond and free, happy here as never men were happy elsewhere on earth, may say, for the whole Union of these States, as this toast says for the blessed mother of States :
                </p>
                <p className="p-in-sp">" 'Breathes there a man with soul so dead, Who never to himself hath said,</p>
                <p className="p-in-sp">This is my own, my native land ?'</p>
                <p className="p-in-sp">"I give you the Union and the Constitution of the United States, as they are—the country, the whole country 'my own, my native land,'</p>
                <p className="p-in-sp">as it is."</p>
                <p className="p-in-sp">Now, in less than two years we are called upon to turn our back upon this our native land, and to pledge ourselves either to States in actual rebellion against the Government, or to foreign powers—which ever view you choose to take; and I would respectfully commend to the gentlemen from Bedford [<span className="persName">Mr. GOGGIN</span>] and from Halifax [<span className="persName">Mr. FLOURNOY</span>] 
                  -that we follow their advice—either place ourselves in actual rebellion against our native land, or give aid to foreign Governments in a war against our own country; for, if the seceding States are out of the Union, they are a foreign and hostile power; if in the Union, they are in a state of rebellion. And Virginia is to do this to-day, what she was not required to do one week ago,
                  because of the appearance of Mr. Lincoln's inaugural address. Ah! Will Virginia do this thing? Why, sir, I suppose if these gentlemen expected to have been satisfied with Mr. Lincoln's inaugural address, they would not have opposed <PageNumber num={478} /> him so bitterly as they did. And here in the midst of the assembled Representatives of Virginia, I
                  declare, on my own responsibility as a man and a Virginian, that I am agreeably disappointed in the pacific tone that breathes through the whole of that inaugural address. Sir, it is fortunate for the people of Virginia that they will read that address for themselves, but I will call particular attention to what Mr. Lincoln says upon the subject which now concerns us all:
                </p>
                <p className="p-in-sp">
                  "The Chief Magistrate derives all his authority from the people, and they have conferred none upon him to fix terms for the separation of the States. The people themselves can do this also if they choose, but the Executive, as such, has nothing to do with it. His duty is to administer the present government as it came to his hands, and to transmit it unimpaired by him to his
                  successor.
                </p>
                <p className="p-in-sp">
                  "Why should there not be a patient confidence in the ultimate justice of the people? Is there any better or equal hope in the world? In our present difficulties is either party without faith of being in the right? If the Almighty Ruler of Nations, with his eternal truth and justice, be on your side of the North, or on yours of the South—that truth and that justice will surely
                  prevail, by the judgment of this great tribunal—the American people.
                </p>
                <p className="p-in-sp">
                  "By the frame of the Government under which we live, this same people have wisely given their public servants but little power to do mischief, and have, with equal wisdom, provided for the return of that little to their own hands at very short intervals. While the people retain their virtue and vigilance, no Administration, by any extreme of wickedness or folly, can very seriously
                  injure the Government in the short space of four years.
                </p>
                <p className="p-in-sp">
                  "My countrymen, one and all, think calmly and well upon this whole subject. Nothing valuable can be lost by taking time. If there be an object to hurry any of you in hot haste to a step which you would never take deliberately, that object will be frustrated by taking time, but no good object can be frustrated by it. Such of you as are now dissatisfied still have the old Constitution
                  unimpaired ; and, on the sensitive point, the laws of your own framing under it; while the new Administration will have no immediate power, if it would, to change either. If it were admitted that you who are dissatisfied held the right in the dispute, there still is no single good reason for precipitate action. Intelligence, patriotism, Christianity, and a firm reliance on Him who
                  has never yet forsaken this favored land, are still competent to adjust, in the best way, all our present difficulty.
                </p>
                <p className="p-in-sp">"In your hands, my dissatisfied fellow-countrymen, and not in <PageNumber num={479} /> mine, is the momentous issue of civil war. The Government will not assail you.</p>
                <p className="p-in-sp">
                  "You can have no conflict without being yourselves the aggressors. You have no oath registered in Heaven to destroy the Government, while I shall have the most solemn one to 'preserve, protect and defend' it. I am loath to close. We are not enemies, but friends. We must not be enemies. Though passion may have strained, it must not break our bonds of affection. The mystic chords of
                  memory, stretching from every battle-field and patriot grave to every living heart and hearth-stone all over this broad land, will yet swell the chorus of the Union, when again touched, as surely they will be, by the better angels of their nature."
                </p>
                <p className="p-in-sp">Now, sir, in order that the attention of the people, which may not have been specially directed to what Mr. Buchanan said in his last annual message upon the same subject, may be called to it now, I read what Mr. Buchanan said in his last message to Congress in December last :</p>
                <p className="p-in-sp">"The same insuperable obstacles do not lie in the way of executing the laws for the collection of the customs. The revenue still continues to be collected, as heretofore, at the Custom House in Charleston; and should the Collector unfortunately resign, a successor may be appointed to perform this duty.</p>
                <p className="p-in-sp">
                  "Then in regard to the property of the United States in South Carolina. This has been purchased for a fair equivalent, 'by the consent of the Legislature of the State,' for the erection of forts, magazines, arsenals,' &amp;c, and over these the authority 'to exercise exclusive legislation' has been expressly granted by the Constitution to Congress. It is not believed that any attempt
                  will be made to expel the United States from this property by force; but if in this I should prove to be mistaken, the officer in command of the forts has orders to act strictly on the defensive. In such a contingency, the responsibility for consequences would rightfully rest upon the heads of the assailants.
                </p>
                <p className="p-in-sp">
                  "Apart from the execution of the laws, so far as this may be practicable, the Executive has no authority to decide what shall be the relations between the Federal Government and South Carolina. He has been invested with no such discretion. He possesses no power to change the relations heretofore existing between them, much less to acknowledge the independence of that State. This
                  would be to invest a mere Executive officer with the power of recognizing the dissolution of the Confederacy among our thirty-three sovereign States. It bears no resemblance to the recognition of a foreign de facto Government, involving <PageNumber num={480} /> no such responsibility. Any attempt to do this would, on his part, be a naked act of
                  usurpation."
                </p>
                <p className="p-in-sp">
                  That is the language of President Buchanan, in December last, looking to this action on the part of South Carolina. And it is because Mr. Lincoln has not been guilty of this usurpation which would have been a mere nullity—of recognizing the independence of these States which are now in rebellion against our own Government, that he is to be denounced, and that we are with hot haste to
                  pledge ourselves to become a party to this effort at self-murder.
                </p>
                <p className="p-in-sp">
                  What less could Mr. Lincoln have said? I am not here as his defender or his apologist. God knows, if there is a man in the land who regrets his existence and the existence of his party more than I do, I know him not. But I am a Virginian, born and raised in the State, never having lived out of it, and not expecting to die out of it. I have too much Virginia blood in my veins to do
                  the slightest injustice to the meanest reptile that crawls. Mr. Lincoln dare not recognize these Ordinances of Secession, by which these States say they have severed the tie that bound them to the rest of the States of the Union. And I cannot for the life of me reconcile the opinions offered by the distinguished gentleman from Bedford
                 [<span className="persName">Mr. GoGoIN</span>] , denying the right of secession, but yet recognizing it as a duty on the part of Virginia, to give her aid, and to spill her blood, if necessary, and expend her money, and appropriate her men, in defence of those who have done that which, if they have not the right of secession, is evidently an illegal act.</p>
                <p className="p-in-sp">I had thought that the gentleman from Bedford [<span className="persName">Mr. GOGGIN</span>] was a member of the successful party in the State of Virginia at the late Presidential election. I thought he rang the Bell where Everett went. If I mistake not, he was on a certain committee who reported the platform upon which that party stood.</p>
                <div className="speaker" id="sp554"><span className="persName">Mr. GOGGIN</span>, of Bedford—</div>
                <p className="p-in-sp">I was a member of the Convention, not of the Committee.</p>
                <div className="speaker" id="sp555"><span className="persName">Mr. CARLILE</span>—</div>
                <p className="p-in-sp">Well, then, a member of this Convention, and of course he endorsed the platform of his party, which was "the Union, the Constitution, and the enforcement of the laws" against all resistance, either at home or abroad. Here is the resolution in their platform to which I refer :</p>
                <p className="p-in-sp">
                  "Resolved, That it is the part both of patriotism and duty to recognize no political principle other than the Constitution of the Country,' the Union of the States, and the Enforcement of the Laws, and that, as representatives of the Constitutional Union men of the country, in <PageNumber num={481} /> National Convention assembled, we hereby pledge
                  ourselves to maintain, protect and defend, separately and unitedly, these great principles of public liberty and national safety against all enemies, at home and abroad, believing that thereby peace may once more be restored to the country, the rights of the people and of the States re-established, and the Government again placed in that condition of justice, fraternity and equality
                  which, under the example and Constitution of our fathers, has solemnly bound every citizen of the United States to maintain a more perfect Union, establish justice, insure domestic tranquility, provide for the common defence, promote the general welfare, and secure the blessings of liberty to ourselves and our posterity."
                </p>
                <p className="p-in-sp">I would enquire if the gentleman from Halifax [<span className="persName">Mr. FLOURNOY</span>] , stood upon that platform? I merely enquire, sir, very respectfully, and the gentleman need not answer, unless it is agreeable to him to do so.</p>
                <div className="speaker" id="sp556"><span className="persName">Mr. FLOURNOY</span>—</div>
                <p className="p-in-sp">I nodded my head as an answer in the affirmative; I did not think it was necessary to rise and answer the gentleman in a more definite manner.</p>
                <div className="speaker" id="sp557"><span className="persName">Mr. CARLILE</span>—</div>
                <p className="p-in-sp">
                  Well, sir, what Union was it you had to preserve? What Constitution were you to protect and defend? And what laws were you to enforce? Did you not unite with me, and with the whole South, in doing homage to Mr. Fillmore? Did we not call him the Model President? And why did he deserve that name? Because, at the point of the bayonet, in the streets of Boston, with the army of the
                  country, he enforced the laws against those who were disposed to resist them. And now, when the laws are to be enforced on this side the line, Virginia is to pledge herself to resist their execution.
                </p>
                <p className="p-in-sp">
                  But not only by those who deny the right of secession, but by those who advocate the right of secession are we to be dragged into a committal of the people of Virginia, without their being consulted upon it, to a policy which unites our fortunes with those who contemn the laws of the country, and despise and set at nought its authority. The people I have the honor to represent upon
                  this floor are a brave, and a gallant, and a law-abiding people, and you may travel where you will—North, South, East, or West—and a more honorable, or a more intelligent people is not to be found on the face of God's green earth ; a more loyal people to the soil of their birth is nowhere to be found; a people devoted to the institution of slavery, not because of their pecuniary
                  interest in it, but because it is an institution of the State; and they have been educated to believe in the sentiment uttered by the gentleman from Halifax the other day, and which I cordially endorse, "that African <PageNumber num={482} /> slavery, as it exists in the Southern States, is essential to American Liberty."
                </p>
                <p className="p-in-sp">
                  The people that I have the honor in part to represent, have not been seized with this frenzied madness which has seized our friends in other parts of the Commonwealth, to induce them—brave and gallant though they may be—to adopt a cowardly—I use this language because I have no other, for I have never been inside a school house to learn since I was fourteen years of age—to adopt a
                  cowardly course, to run away and give up all their inheritance in this great country; because of our own divisions we allowed about one third of the voters of the Union, numbering a little more than one half of the votes in the non slave-holding States to succeed in elevating to the Presidency of the United States, one who is objectionable to us. Sir, we know we have the protection
                  of our Common Constitution ; we know that that flag is ours ; we know that the army is ours; we know that the navy is ours; we know that in any battle in defence of our rights, fifteen hundred thousand gallant voters in the non-slaveholding States will rush to our assistance, and under the stars and stripes will hurl from power any and all who dare to take advantage of the position
                  they have obtained to our injury or oppression. We cannot reconcile secession with our notions of Virginia's chivalry and Virginia's courage. But we know, Mr. President—and no man upon this floor has denied it—that this Government we are called upon to destroy has never brought us anything but good. No injury has it ever inflicted upon us. No act has ever been put upon the statute
                  book of our common country, interfering with the institution of slavery in any shape, manner or form, that was not put there by and with the consent of the slave-holding States of this Union. As I remarked upon a former occasion, in this Convention, when we did put an act there, when we drew the line of demarcation across the common territory that belonged to us, and claimed it as a
                  Southern triumph, we were saved from its injustice by the act of the Federal Government; and yet we are now called upon, in hot haste, to destroy the Government that shielded us from the injurious consequences of our own mistaken conduct. It did so by declaring that act of ours a nullity, and guaranteed to us the right to go to any and all the Territories of this Union with our slave
                  property, if we desired to do it. That is the Government which we are called upon to destroy—a Government which protects us even against our mistakes—a Government which has quadrupled the area of slave territory since it had an existence—a government in which we have to-day the right to make five more slave States, if we had either the whites or negroes to occupy
                  <PageNumber num={483} /> them; but we have neither—and it is because we have neither that we do not have to-day 19 slave States in the Union. We have had the right to occupy them ever since 1845; and yet we want expansion in northern latitudes, where all the legislation and stimulants on earth could not keep the negro for a week, even if we were to take
                  him there. This question of African slavery is regulated by climate, by soil, by products, and by interest.
                </p>
                <p className="p-in-sp">
                  But, Mr. President, we have heard a great deal here about equal rights—that's the expression, I believe. I never heard it specified what the rights were. We have heard a great deal about "rights," but very little about "duties." "Rights" are in every man's mouth—"duties" are never alluded to. "Rights" are to be enjoyed ; "duties" are to be performed. But it is not because of any
                  denial of right on the part of the Federal Government to allow us to carry our slaves into the Territories of this Union, that this Union is sought to be destroyed. S. Carolina scorns to place it upon any such ground. It is only used here and reference is made to Personal Liberty Bills here, not because of the injury inflicted by these bills, but it is because these gentlemen may
                  obtain the motive power which is necessary to enable them to accomplish their disunion ends. If it were resistance to the Fugitive Slave Laws; if it were the passage of the Personal Liberty Bills that they considered as just cause for the dissolution of this Union, would South Carolina have inaugurated the movement of secession? Is Virginia so dull, is she so stupid, is she so lost
                  to all her ancient fame, that she will consent to remain in the Union disgraced and dishonored, not knowing that she was so disgraced and dishonored until South Carolina advises her to that effect? Is that the position in which gentlemen would place us?
                </p>
                <p className="p-in-sp">
                  This movement originated in South Carolina, where they never lost a slave, precisely as most of these Personal Liberty Bills are found in the statute books of such of the New England and Western States as never saw a runaway slave. Now, sir, South Carolina tells you boldly and frankly, as Mr. Preston, her ambassador, told you in this Hall the other day, that it was not for that, but
                  because of the irrepressible conflict that exists between free and slave labor.
                </p>
                <p className="p-in-sp">Is it not strange, is it not remarkable that we get all our doctrines of secession, of irrepressible conflict from these Yankees, whom we love to abuse?</p>
                <p className="p-in-sp">
                  Where did this doctrine of the right of a State to secede originate? In the hot-bed of all the isms—Massachusetts. In 1807, because of the embargo, citizens of Massachusetts and other New England States <PageNumber num={484} /> resolved that they had the right to secede. Let us see how that doctrine was treated in Virginia. In 1808 the Presidential
                  Electors of Virginia met in this city and cast their votes for Mr. Madison as President, and as successor to Jefferson. A dinner was given to the Electors upon that occasion. Spencer Roane was President and Robert Taylor Vice President, P. N. Nicholas, Attorney General, Peyton Randolph, John Preston, Thomas Ritchie and many others of the most distinguished statesmen of Virginia, sat
                  down to that dinner.
                </p>
                <p className="p-in-sp">One of the regular toasts—the 14th I believe—was,</p>
                <p className="p-in-sp">"The Union of the States; the majority must govern; it is treason to secede."</p>
                <p className="p-in-sp">But, sir, that doctrine was still agitated to a later period in these New England States. The Richmond "Enquirer" of 1814 held the following language :</p>
                <p className="p-in-sp">
                  "No man, no association of men, no State, or set of States, has a right to withdraw itself from this Union of its own account. The same power which knit us together can unknit. The same formality which formed the links of the Union is necessary to dissolve it. The majority of States which formed the Union must consent to the withdrawal of any one branch of it. Until that consent has
                  been obtained any attempt to dissolve the Union or distract the efficiency of its constitutional law is treason—treason to all intents and purposes."
                </p>
                <p className="p-in-sp">
                  The authority of Mr. Madison has been invoked in favor of this right to secede. I will not detain the Convention now by reading the many letters which Mr. Madison wrote upon that subject. I will merely refer to them, taking care, however, that they shall accompany the publication of my remarks. First, in his letter to Mr. Trist ; 2d, in his letter to Mr. Cabell; 3d, in his letter to
                  Mr. Everett, and, again, in his letter to Mr. Webster, he put his heel upon this poisonous doctrine of secession.
                </p>
                <p className="p-in-sp">
                  I have been surprised—no, I will not say surprised—I have been struck with the adroitness on the part of the secessionists in this body in evading an express declaration that they believe in the right of secession. They will not stop to discuss the right of secession. It is one of the most adroit ways in which they could get around it; for if they were to stop and discuss the right
                  of a State to secede from this Union, and should fail to satisfy the people of Virginia that in the exercise of this power of withdrawal from the Union they were acting rightfully and legally, they would be very apt to pause long before they would exercise it; for the people of Virginia are not only a brave and gallant, but they are a moral people; and, sir, if they are not
                  <PageNumber num={485} /> satisfied of the morality of an act, they never, never will join you in its exercise. They are a law-abiding, a Constitution-loving people; and before you can get them to go with you for an ordinance of secession, or for resolutions pledging them to a course of policy which will bring about the same result that an ordinance of
                  secession will bring about, you must first convince them of the morality and legality of the act.
                </p>
                <p className="p-in-sp">
                  Now, sir, how will you attempt, at this day and at this hour, to maintain before the people of Virginia the rightfulness of secession? Astute, learned and great as you may be, you are not astute, learned and great enough for that. Its absurdity is too palpable ever to be maintained successfully before a Virginia people. Mr. Calhoun never contended for it. Mr. Calhoun never advocated
                  it in the discussion of what we call the Force Bill, in 1833 ; and when Mr. Rives, the then United States Senator from Virginia, intimated in his argument that Mr. Calhoun held to such a right, he interrupted him in the course of his argument, and expressly said that the exercise of such a right would be a breach of the compact and a violation of faith. And South Carolina herself,
                  through her highest judicial tribunal, the Court of Appeals, has expressly repudiated it in a case brought before it by mandamus, sued out at the instance of a gentleman by the name of McCready. This case occurred in 1834, after their ordinance had been adopted. <span className="persName">Mr. Mc</span>Cready refused to take the oath prescribed for the militia officers of that State by
                  the Convention that adopted the ordinance, and he applied to a Judge for a mandamus to compel the proper party to issue his commission, he having taken the original oath as prescribed by South Carolina prior to the adoption of the ordinance. The matter went up to the Court of Appeals, and that Court expressly denied the legality and constitutionality of the ordinance, and instructed
                  the proper party to issue his commission. I give the decision :
                </p>
                <p className="p-in-sp">
                  A Convention was called by South Carolina in November, 1832. In March, 1833, it passed an Ordinance to nullify the act of Congress called the Force Bill, one clause of which Ordinance read as follows : "We do ordain and declare that the allegiance of citizens of this State while they continue such is due to the said State, and that obedience only and not allegiance is due by them to
                  any other power and authority to whom the control over them has been delegated by the State." The Legislature followed up this Ordinance by the act of December, 1833, which enacted "that every officer of the Militia hereafter elected shall take the following oath : I, A. B., do solemnly swear that I will be faithful and true allegiance bear to the State of South Carolina." This oath
                  was tendered by Col. Hunt, of the Fourth Brigade, to Ed. McCready, <PageNumber num={486} /> a Lieutenant elect of the Washington Light Infantry. McCready declined it, went before a magistrate and took the oath prescribed by the fourth article of the Constitution of South Carolina to all persons chosen or appointed to any office, and applied to Mr.
                  Justice Bay for mandamus to direct Col. Hunt to issue his commission. The case was brought by appeal before the Court of Appeals of South Carolina in March, 1834, and argued by eight of the noblest counsel of the State. All the talent and influence of the party which formed five-sevenths of the State were brought to bear in favor of "the South Carolina doctrines," which stood or fell
                  with this case. But in vain. South Carolinian Judges, paid and appointed by the State, with all the warm State partialities which distinguish her sons, decided against the South Carolina doctrines, with their corollaries of nullification and secession.
                </p>
                <p className="p-in-sp">
                  In delivering the judgment of the Court in favor of the mandamus, Justice O'Neall said : "Treason is a violation of the tie of allegiance. What says the Constitution of the United States in relation to it? It is defined 'to consist in levying war against the United States or in adhering to their enemies, giving them aid and comfort.' If the Government of the United States (as we
                  familiarly call it, and I think it really is) , is no government, but is a mere agency, it is strange that treason can be committed against it. Who ever heard of treason being committed against the subordinate parts of a Government? It is one of the essential attributes of sovereignty to punish for treason." * * * * * "When the officer swears to preserve, protect and defend the
                  Constitution of this State and of the United States, is he not sworn to be faithful and true allegiance bear to the Government of the United States? It is impossible that it should have any other sense; for any act which was intended to be the overthrow of either Constitutional government would be the violation of the Constitutional oath. * * * * * * * The power of amendment of the
                  Constitution by three-fourths of the States has been by more than one great name in South Carolina held up as the ultimate sovereignty to which allegiance was due. I think there is no duty, no allegiance, to any such ultimate right. But it shows, however, that a government which can be amended against our will, and which will then operate directly upon us, is something more than an
                  agency; and that it has high sovereign powers to which obedience must be yielded. We have been told in the progress of this argument that the Government of the United States was a mere league between co-States : in other words, that the spirit of the old Confederation exists in the Federal Constitution, although the former has been superseded
                  <PageNumber num={487} /> and abolished by the latter. We must live in an age of political wonders and miracles, if not natural ones. I confess that I heard with astonishment the old Confederation lauded as the best Government in the world, when I had regarded it as settled and given up fifty years ago, as a matter of history, that it was an
                  impracticable Government.
                </p>
                <p className="p-in-sp">
                  "It seems to me perfectly clear that the Government created by the Federal Constitution is, strictly speaking, a government of the people. It is a Government : for within its prescribed Constitutional limits it acts upon the people, and enforces against them its laws through its own judiciary or that of each State. Within its own Constitutional limits it is absolute and supreme.
                </p>
                <p className="p-in-sp">
                  "By the second section of the sixth article of the Constitution of the United States it is declared that 'this Constitution and the laws of the United States, which shall be made in pursuance thereof, &amp;c., shall be the supreme law of the land; and the judges in every State shall be bound thereby, anything in the CONSTITUTION or laws of any State notwithstanding.' Does not this
                  supremacy of constitutional law necessarily make the Government of the United States as much the Government of the people of this State as her own immediate Government? It is too clear to admit of argument that it does. What is the necessary consequence? Is a Government possessing such great powers without any tie of obedience or allegiance between it and its citizens? Can it be that
                  in time of war a citizen soldier would be allowed to refuse to shoulder his musket and say I owe you no allegiance, I will wait until my own State has bade me fight? Could he say I will assist your enemies, and you dare not punish me for treason, because South Carolina has not defined it? These questions must have an affirmative answer, or we do owe allegiance to our Government, not
                  our agency, under the Federal Constitution."
                </p>
                <p className="p-in-sp">
                  The presiding Justice, the Hon. David Johnson, said : "The people have organized a Government, clothed with all the powers that are necessary to protect the citizen in the enjoyment of all his rights, privileges and immunities. It is that Government which does protect the citizen, and to that Government the allegiance of the citizen is due. If that had been a simple Government
                  intended for the State alone, and confided to the administration of agents appointed by the State, and responsible to the State alone, no proposition could be more certain than that the citizen would owe allegiance exclusively to that Government. But many of the powers of Government, and those of the greatest importance, have been confided by the people to the Government
                  <PageNumber num={488} /> of the United States, whose agents are not appointed by nor responsible to the State, except in common with the other States, and to that Government is confided the preservation of many of the dearest rights of the citizen, and amongst these may be mentioned the guaranty of the Constitution of the United States, which secures to
                  each State a Republican form of government. The Government of the United States has also the right to require of the citizen to contribute of his wealth to its support, and to serve in its armies. That Government is, to all intents and purposes, as much the Government of the people of South Carolina as the State Government. They have both received their sanction, and they have
                  consented to be bound by them, and if the conclusions of logic can be confided in, for the same reasons that they owe allegiance to the State Government, they owe it to the Government of the United States. Sophistry may confuse the subject, but this must be the conclusion whenever the unerring test of truth shall be applied."
                </p>
                <p className="p-in-sp">Furthermore, South Carolina, through her Legislature, expressly repudiated any such right. You will find it in a report, adopted by South Carolina in December, 1828, said to have been written by Mr. Calhoun himself :</p>
                <p className="p-in-sp">
                  "Our system, then, consists of two distinct and independent sovereignties. The General powers conferred on the General Government are subject to its sole and exclusive control, and the States cannot, without violating the Constitution, interpose their authority to check or in any manner counteract its movements, so long as they are confined to its proper sphere; so, also, the
                  peculiar and local powers reserved to the States are subject to their exclusive control ; nor can the General Government interfere with them, without, on its part, also violating the Constitution. In order to have a full and clear conception of our institution, it will be proper to remark that there is in our system a striking distinction between the Government and the sovereign
                  power. Whatever may be the true doctrine in regard to the sovereignty of the States individually, it is unquestionably clear that, while the Government of the Union is vested in its legislative, executive and judicial departments, the actual sovereign power resides in the several States, who created it, in their separate and distinct political character. But by an express provision
                  of the Constitution, it may be amended or changed by three-fourths of the States; and each State, by assenting to the Constitution with this provision, has surrendered its original rights as a sovereign, which made its individual consent necessary to any change in its political condition, and has placed this important <PageNumber num={489} /> power in
                  the hands of three-fourths of the States, in which the Sovereignty of the Union, under the Constitution, does now actually reside."
                </p>
                <p className="p-in-sp">
                  Sir, if that be true, if Mr. Calhoun be authority with these gentlemen, I ask you, how it is they can defend the right of a State to secede when, by virtue of his own doctrines, if three-fourths of the States of this Union against the unanimously expressed opinion of the other one-fourth should adopt a Constitutional provision, it is the duty of that one-fourth to abide by it and to
                  recognize it? The very provision for the amendment of the Constitution, provided in the instrument itself, at a glance shows the absurdity of the doctrine of secession.
                </p>
                <p className="p-in-sp">
                  But, we are told, although these States have no right to secede, although they do themselves that which is not lawful, constitutional or legal, yet Virginia, law-loving, law-abiding as she has been, is to pledge herself to aid these men who disregard the law, and who act in violation of law in making war upon Virginia's own Government, and Virginia must interpose her potent voice and
                  say to that government : "You must not enforce your laws in this or that State, but you must enforce them in the other States." In other words, New York must pay her revenues; collection of them must be enforced in New York and Norfolk, but they must not be enforced in the State of South Carolina, because she has done that which she has not the right to. That is the logical argument
                  of the gentlemen who deny the right of secession.
                </p>
                <p className="p-in-sp">Now, I commend to those who have spent a life-time in admiring the gallant statesman of Kentucky, now no more—to those whose lives have been expended in efforts to show their appreciation, not alone of the man, but of the sentiments of his life, the following:</p>
                <p className="p-in-sp">"ASHLAND, May 17th, 1851</p>
                <p className="p-in-sp">"My Dear Sir : I received your favor of the 15th. There is no significance whatever to the article which you refer to in the Reporter. It was put there without my authority or knowledge, and I regretted it, when I saw it.</p>
                <p className="p-in-sp">
                  "You ask what is to be done if South Carolina secedes? I answer unhesitatingly, that the Constitution and laws of the United States must continue to be enforced there, with all the power of the United States, if necessary. Secession is treason; and if it were not—if it were a legitimate and rightful exercise of power, it would be a virtual dissolution of the Union. For if one State
                  can secede, every State may secede, and how long in such a state of things could we be kept <PageNumber num={490} /> together? Suppose Kentucky were to secede. Could the rest of the Union tolerate a foreign power within their very bosom? There are those who think the Union must be preserved and kept together by an exclusive reliance upon love and
                  reason. This is not my opinion. I have some confidence in this instrumentality; but, depend upon it, that no human government can exist without the power of applying force, and the actual application of it in extreme cases. My belief is, that if it should be applied to South Carolina, in the event of her secession, she would be speedily reduced to obedience, and that the Union,
                  instead of being weakened, would acquire additional strength.
                </p>
                <p className="p-in-sp">"Writing, as you perceive, by an amanuensis, I must be brief, and conclude with assurances of my constant regard."</p>
                <p className="p-in-sp">
                  Upon this point, Mr. Clay was very explicit in his speech in the Senate, on the day after the defeat of the ominous<span className="note" id="Note59"><span className="noteLabel">3</span>The speaker perhaps referred to the Omnibus Compromise Bill.</span> compromise bill, July, 1850:
                </p>
                <p className="p-in-sp">
                  Mr. Clay said : "Now, Mr. President, I stand here in my place, meaning to be unawed by any threats, whether they come from individuals or from States. I should deplore, as much as any man, living or dead, that armies should be raised against the authority of the Union, either by individuals or States. But, after all that has occurred, if any one State or a portion of the people of
                  any State, choose to place themselves in military array against the government of the Union, I am for trying the strength of the government.
                 [Applause in the galleries.] 
                  I am for ascertaining whether we have a government or not, practical, efficient, capable of maintaining its authority and upholding the powers and interests which belong to the government. Nor, sir, am I to be allayed or dissuaded from any such course by intimations of the spilling of blood. If blood must be spilt, by whose fault will it be? Upon the supposition I maintain, it will
                  be the fault of those who raise the standard of Disunion, and endeavor to prostrate this government ; and, sir, when this is done, so long as it pleases God to give me a voice to express my sentiment, and an arm—weak and enfeebled as it may be by age—that voice and that arm will be on the side of my country, for the support of the general authority, and for the maintenance of the
                  powers of this Union."
                 [Applause in the galleries.] 
                  There is where Henry Clay stood. But, Mr. President, I have been driven from the point that I was about to make, into a notice of this Yankee notion of secession, and I now return to it. I gave you the true reason why South Carolina desired a separation from the Federal Government and the Federal Union. She tells you that she believes in this doctrine of the irrepressible conflict.
                  Now that Seward has <PageNumber num={491} /> abandoned it and the Black Republican party is afraid to maintain it, the South takes it and we heard the Commissioner from the State of South Carolina proclaim it here in our midst the other day.
                </p>
                <p className="p-in-sp">Mr. Spratt sent from South Carolina as Commissioner to the State Convention of Florida, while the question of secession was pending before that body, and again in a letter which he addressed to a delegate from Louisiana to the Montgomery Congress, uses the following language :</p>
                <p className="p-in-sp">
                  "The South is now in the formation of a Slave Republic. This, perhaps, is not admitted generally. There are many contented to believe that the South, as a geographical section, is in mere assertion of its independence ; that, it is instinct with no especial truth—pregnant of no distinct social nature; that for some unaccountable reason the two sections have become opposed to each
                  other; that for reasons equally insufficient, there is a disagreement between the peoples that direct them; and that from no overruling necessity, no impossibility of coexistence, but as mere matter of policy, it has been considered best for the South to strike out for herself and establish an independence of her own. This, I fear, is an inadequate conception of the controversy.
                </p>
                <p className="p-in-sp">
                  "The contest is not between the North and South as geographical sections, for between such sections merely there can be no contest; nor between the people of the North and the people of the South, for our relations have been pleasant; and on neutral grounds there is still nothing to estrange us. We eat together, trade together, and practice, yet, in intercourse, with great respect,
                  the courtesies of common life. But the real contest is between the two forms of society which have become established, the one at the North and the other at the South. Society is essentially different from Government—as different as is the nut from the bur, or the nervous body of the shell-fish from the bony structure which surrounds it; and within this Government two societies had
                  become developed as variant in structure and distinct in form as any two beings in animated nature. The one is a society composed of one race, the other of two races. The one is bound together but by the two great social relations of husband and wife, and parent and child; the other by the three relations of husband and wife, and parent and child, and master and slave. The one
                  embodies in its political structure the principle that equality is the right of man ; the other that it is the right of equals only. The one embodying the principle that equality is the right of man, expands upon the horizontal plane of pure Democracy; the other, embodying the principle that it is not the right of man, but of equals only, has taken to itself the rounded form of a
                  social <PageNumber num={492} /> aristocracy. In the one there is hireling labor, in the other slave labor; in the one, therefore, in theory at least, labor is voluntary; in the other involuntary; in the labor of the one there is the elective franchise, in the other there is not; and, as labor is always in excess of direction, in the one the power of
                  Government is only with the lower classes; in the other the upper. In the one, therefore, the reins of Government come from the heels, in the other from the head of the society; in the one it is guided by the worst, in the other by the best intelligence ; in the one it is from those who have the least, in the other from those who have the greatest stake in the continuance of existing
                  order. In the one the pauper laborer has the power to rise and appropriate by law the goods protected by the State—when pressure comes, as come it must, there will be the motive to exert it—and thus the ship of State turns bottom upwards. In the other there is no pauper labor with power of rising; the ship of State has the ballast of a disfranchised class; there is no possibility of
                  political upheaval, therefore, and it is reasonably certain that so steadied, it will sail erect and onward to an indefinitely distant period."
                </p>
                <p className="p-in-sp">Mr. Commissioner PRESTON, in his speech before this body, winds up a rhapsody of the same character, as follows : "None but a subject race will labor at the South."</p>
                <p className="p-in-sp">
                  There it is in a nutshell. That is it—that is the feast to which the people of Virginia are invited; that is the Government to be provided for the people I have the honor to represent here; for my children, for your children and the children of the people of this good old State. South Carolina initiated this movement; South Carolina will control this movement; South Carolina will
                  give direction to this new cotton Government, if ever a permanent one is formed, which, I trust in God, never will be, and humbly believe, never can be. But, if it ever should, it must of necessity—if these Commissioners from Georgia and South Carolina who addressed us, understood what they were talking to us about—partake strongly of a military character, and strongly of the
                  character of the present Government of South Carolina, where no man within her limits is eligible to a seat in the Lower House of her Legislature, unless he is the owner of ten negroes and 500 acres of land.
                </p>
                <p className="p-in-sp">
                  I have been a slaveholder from the time that I have been able to buy a slave. I have been a slaveholder, not by inheritance, but by purchase; and I believe that slavery is a social, political and religious blessing, and I so believed when, so far as I know, no other man south of Mason and Dixon's line believed, to which fact there is a living witness at this day. When a boy, but
                  seventeen years of age in the city of Philadelphia, <PageNumber num={493} /> I took the ground that slavery was right in itself. That day no man South took that ground in defence of the institution. The agitation of this question has, in the words of Mr. Hunter in the speech which he delivered before the Breckinridge Convention at Charlottesville last
                  fall, been productive of good. It has brought every man South of Mason and Dixon's line upon one common platform, and no man to-day denies the assertion I have made, that African slavery is right in itself. Believing that the institution of slavery is essential to the preservation of our liberties, I desire above all things to continue it.
                </p>
                <p className="p-in-sp">
                  How long, if you were to dissolve this Union—if you were to separate the slaveholding from the non-slaveholding States—would African slavery have a foothold in this portion of the land? I venture the assertion, that it would not exist in Virginia five years after the separation, and nowhere in the Southern States, twenty years after. How could it maintain itself, with the whole
                  civilized world, backed by what they call their international law, arrayed for its ultimate extinction?—with this North that is now bound to stand by us, and to protect slavery, opposed to us, and united with England, France and Spain, so to control the destiny of the slaveholding Republic as to work out the ultimate extinction of the institution? Think you that ever another square
                  mile of territory can be acquired by a purely slaveholding Republic? You would have not only the North to prevent you, but England, France and Spain. I have looked forward to the day when Cuba, that gem of the ocean, would fall into our lap. I have never advocated any harsh or violent measure to procure it, but if we remain together, that island is destined to be ours. The commercial
                  interests of the nonslaveholding States make them as anxious—more anxious—to procure it than we are; and nothing can prevent its addition to our Union but our own separation and dissolution.
                </p>
                <p className="p-in-sp">
                  Look at Virginia, to-day, standing in the centre of this Confederacy, by far the most powerful nation upon the globe, with the most prosperous and the most happy government on earth. A government that has gone on in a career of greatness, of glory, of power, and of prosperity in a manner that is almost too much for the human mind to realize. This government that has conferred upon us
                  blessings innumerable, and nothing but blessings, is to be destroyed, dissolved, not because of any act of its own, not that it is resisted ; not because of any intolerable oppression, for it has never oppressed us; but because a portion of its citizens, residing in a particular section of the Union, have so far forgotten their duty to their brethren of the same family,
                  <PageNumber num={494} /> as to entertain hostile opinions of an institution belonging to the other section.
                </p>
                <p className="p-in-sp">Mr. President, is there not reason why we should wait to see if that hostile sentiment has not already culminated, and is not to-day upon the wane?</p>
                <p className="p-in-sp">
                  Mr. Hunter, in the same speech to which I referred as delivered before the Breckinridge Convention at Charlottesville, stated that when he first entered Congress, which was in 1837, there was no statesman of any respectability that did not admit the power of Congress to enact the Wilmot Proviso—in other words, to exclude slavery from the Territories of the Union. He referred to that
                  as a significant fact in the history of the slavery agitation, to show the progress which this institution had made in public estimation. At this day, the power is denied by all the South and much of the North, and we find that in the midst of non-slaveholding communities men are found to get up and justify the institution of slavery, as by right an institution consistent with the
                  Providence of God. They would not have been allowed to have made a similar speech in the State of Virginia twenty-five years ago. Has not, then, the current of public opinion been running rapidly in our favor upon the subject of this institution? And, if we had maintained our plighted faith, made eight years ago, we would have had no disturbances, no agitation to this day upon the
                  subject.
                </p>
                <p className="p-in-sp">It is common, I know, for gentlemen who are giving aid and comfort to the promoters of Disunion to speak in rounded terms of the growing hostility to slavery, which is manifesting itself in the nonslaveholding portion of this Union. Gentlemen are mistaken when they say that it has steadily, without check, increased.</p>
                <p className="p-in-sp">
                  In 1848, Martin Van Buren, as the candidate of the Freesoil party, received 300,000 votes. In 1852, John P. Hale, the candidate of the same party, received but 147,000 votes, a falling off of more than one half. Now, what occurred between 1848 and 1852 to bring about such a result? What occasioned this change? It was that the compromise of 1850 had been adopted, and both the great
                  political parties of the country pledged themselves to regard these measures as a final settlement of this question of slavery, and an end of the agitation. Furthermore, they pledged themselves to resist all attempts at its renewal, whether in Congress or out of it.
                </p>
                <p className="p-in-sp">
                  But, we find in four years after, this vote of 147,000 was swelled to 1,300,000. Gentlemen are as familiar with the causes that produced that result as I am. There has not been, then, a steady increase of hostile sentiment to slavery at the North, but there was a decrease of it <PageNumber num={495} /> between the years 1848 and 1852, and the impetus
                  that was given to it after 1852, increased it to its present proportions.
                </p>
                <p className="p-in-sp">
                  I have sometimes thought, Mr. President, that He who rules and governs and punishes nations for their national sins, was now afflicting us for a violation of our plighted faith to the savage Indians solemnly made by treaty. I believe this trial, that we are now going through, is a punishment for that violation of our plighted faith—a violation of a solemn treaty made by a Christian
                  nation with a savage race. But, I believe, as firmly that we shall be carried through it safely.
                </p>
                <p className="p-in-sp">Mr. President, we have heard a great deal said about coercion, and the resolutions under consideration refer to that subject.</p>
                <p className="p-in-sp">
                  Will gentlemen define what kind of coercion it is they desire the people of Virginia to pledge themselves to resist? It is a most remarkable fact, that during the progress of this disunion movement, generalities and generalities alone are indulged in, accompanied with sensation telegrams. In the language of the lawyers, I call upon you to file your bill of particulars.
                </p>
                <p className="p-in-sp">I might detain you here for an hour, by reading telegraphic dispatches from this city to Tennessee, in order to induce the people of that State to call a Convention—"only twenty submissionists elected in Virginia"—"Virginia will be out before the 4th of March."</p>
                <p className="p-in-sp">
                  That is the character of these dispatches, which were intended to affect the election in Tennessee—as the addresses of our ten congressmen were intended to influence the election in this State. Two senators and eight representatives, circulated broad cast a few days before the election, an address, informing the people of what I believe every man, woman and child in the State knew
                  before, that they were utterly incompetent to effect any adjustment of the pending difficulties. And in like character we were told here yesterday that the Black Republicans in Congress had rejected the measure of adjustment recommended by the Peace Conference? Is that so, sir? I do not read the reports of the proceedings in Congress in that way, nor do I read the report of the vote
                  in Congress upon the Crittenden resolutions, which senators and representatives told us before the election were lost, because of Black Republican opposition. On the contrary I read that that gallant old statesman, John J. Crittenden, sent a dispatch to Raleigh, North Carolina—a nobler specimen of a man, and a purer patriot than John J. Crittenden, never trod God's free earth—saying
                  that in consequence of the failure of six Southern senators, who sat in their seats and did not vote—two of the six I have been informed were Messrs. Mason and Hunter—they were defeated. I have read that the Peace Conference <PageNumber num={496} /> propositions were not allowed to be put to the country, because of the fact that Mr. Hunter and Mr. Mason
                  would not favor them. They did not wait for the Hales, Sumners, and Wilsons to oppose them. The distinguished Senator from Kentucky presented them instead of his own, but Mr. Hunter rose from his seat and gave them their death blow. Is it the lead of these gentlemen that we are to follow in Virginia, if we desire to preserve the Union? They have a most singular way of preserving the
                  Union. Is not every step that has been taken in this disunion movement marked by a contempt, an utter contempt, on the part of the leaders, for the people of this country? Euripides informs us that Creon, King of Thebes, sent a herald to Athens, who inquired for the King of Athens. Theseus replied, "You seek him in vain; this is a free city, and the sovereign power is in all the
                  people."
                </p>
                <p className="p-in-sp">
                  And, sir, in this country the sovereign power is in the people. It has for the time been usurped, but just as sure as the sun shines in a clear and cloudless sky, that people will rebuke those who have endeavored to bring on this distracted condition of things, and to destroy the fairest Constitution and the freest Government ever erected by man, upon the footstool of God.
                </p>
                <p className="p-in-sp">
                  What say these gentlemen? This is an association of States, State sovereignties. I don't read history that way, and I commend to these gentlemen the perusal of the 39th number of "The Federalist," written by Mr. Madison, a very large portion of which I shall have appended to my remarks. Madison tells us that this is not a federal nor a national government. It is of a mixed
                  character—it partakes of the nature of both. That he is right in this<span className="note" id="Note60"><span className="noteLabel">4</span>It seems that some such words as "In order to see" have been omitted at the beginning of this sentence.</span> it is only necessary to refer to the action of the Convention that framed the Constitution of the United States, upon a proposition submitted
                  by Luther Martin, of Maryland; and I would invite the attention of the Convention to the extract I shall read from his letter to the Maryland Legislature :
                </p>
                <p className="p-in-sp">
                  "By the principles of the American revolution, Arbitrary power may, and ought to be resisted, even by arms, if necessary. The time may come when it shall be the duty of a State, in order to preserve itself from the oppression of the General Government, to have recourse to the sword, in which case, the proposed form of government declares that the State and every one of its citizens
                  who act under its authority, are guilty of a direct act of treason; reducing, by this provision, the different States to this alternative—that they must tamely and passively yield to despotism, or their citizens must oppose it at the hazard of the latter, if unsuccessful; and reducing the citizens of the State which shall <PageNumber num={497} /> take
                  arms, to a situation in which they must be exposed to punishment, let them act as they will—since, if they obey the authority of their State Government, they will be guilty of treason against the United States; if they join the General Government, they will be guilty of treason against their own State.
                </p>
                <p className="p-in-sp">
                  "To save the citizens of the respective States from this disagreeable dilemma, and to secure them from being punishable as traitors to the United States, when acting expressly in obedience to the authority of their own State, I wished to have obtained, as an amendment to the third section of this article, the following clause : Provided, that no acts or act done by one or more of the
                  States against the United States, under the authority of one or more of the said States, shall be deemed treason or punished as such; but in case of war being levied by one or more of the States against the United States, the conduct of each party towards the other, and their adherents respectively, shall be regulated by the laws of war and of nations. But this provision was not
                  adopted."
                </p>
                <p className="p-in-sp">
                  Sir, it is amazing that, with so many sources from which we can derive correct information as to the nature and character of our Federal Government and the relations that the States bear to it and to each other—I say it is amazing that, at this day, gentlemen will get up and contend that our General Government is a mere Confederation of States, in the face of the fact that Henry and
                  Mason, in the Convention of our State, opposed the ratification of the Federal Constitution, because it created a Government that rested not for its preservation upon State authority, but came, as did the State Government, from the people of each State, who delegated to it a portion of their sovereign power to be exercised in common with the other States for the mutual benefit and
                  common good of all.
                </p>
                <p className="p-in-sp">
                  I know that gentlemen, when they speak of coercion cannot mean that there is a power to coerce a sovereign State, as such. There is no such power. No man in the land contends for such a power; and if no one contends for it, why level your anathemas against it? Why build up cob-houses that you may have the pleasure of knocking them down? Coercing a State, if it means anything—means
                  making war upon it; war against a State affects the innocent as well as the guilty. This Government is created by the same power that created the State Government. It preserves itself by the same means that the State Governments preserve themselves—that is by punishing the guilty and protecting the innocent. Why are Governments necessary? If every body would act as Christians should
                  do, each rendering to the other what is his due, there would be no need for Government. The very <PageNumber num={498} /> fact that we have a Government and that it is necessary for the protection of society and individuals arises from the fact that all will not do right and that power must reside somewhere to punish the disobedient and enforce the
                  laws.
                </p>
                <p className="p-in-sp">The Government, therefore, acts upon individuals, punishes the guilty, protects the innocent; and without this power you can have no Government. And it must be sustained, too, in the exercise of that power, whenever it becomes necessary to be exercised, for the preservation and perpetuation of the Government.</p>
                <p className="p-in-sp">
                  But, sir, is there anything in this Inaugural Address to justify for a moment the assertions that have been made upon this floor, that it breathes a spirit of war. Read it again, gentlemen. More pacific, more peaceful language could not have been employed by Mr. Lincoln, unless he had been willing to stand up before that assembled multitude in Washington and proclaim to them that,
                  "although in a few moments I shall swear to discharge the duties of the office of President of the United States, and to preserve, protect and defend the Constitution of the United States, I don't mean to do it—I mean to perjure myself." Sir, unless he had done this, he could not have done less than he has done. He has told you, in effect, and told you in pleading, begging terms,
                  that no war will be made upon you, that no force will be used against you—none whatever. But you were dissatisfied, and he appeals to you and says : "Dissatisfied though you be, wait, wait and pursue the remedy pointed out under the Constitution, to provide for you every guarantee, every protection that you may desire ; I shall do nothing to injure ybu; it is made my duty to say, as
                  Mr. Buchanan, as General Jackson, and every President before me has said, and as every future President must say, that I will preserve my oath." But after that he tells you, that if States are so hostile to him that no one residing in them will accept the offices which are to be filled by the Federal Government, he will not attempt to fill them by persons from other States who may be
                  obnoxious to them.
                </p>
                <p className="p-in-sp">
                  But, these gentlemen say: "He says he intends to preserve and protect the forts and other public property of the United States." Well, sir, is he not right in doing so? Is it not his duty to do so? Would you have him to do less? Did you not sustain Mr. Buchanan in doing so to the extent that he did do so? Is it right that those gentlemen in Louisiana shall rob the mint of your money
                  and of my money? that they shall rob you of your arms and munitions of war, and of your forts and arsenals and dock yards? Is it your duty as good citizens to stand by and thus connive at this act of bad faith, and to speak well of it, and <PageNumber num={499} /> to give it aid and support, and to say to the Federal Government : "If you do not give up
                  these forts and arsenals and dock yards peaceably, willingly, why we will take them forcibly, we will make war upon you"? Sir, I, and the people I represent, do not read our duties in that way. Mr. Lincoln in his inaugural address virtually told you that he is not going to make any effort to retake forts which were taken before he came into power; it would be impolitic for him to do
                  so. On the contrary he negatives such an inference as much as he could do so, by saying that he will endeavor to preserve, sustain and hold the public property so that he may hand it over to his successor as it was handed to him by Mr. Buchanan ; and that is all he does say.
                </p>
                <p className="p-in-sp">
                  Now, sir, looking alone to my own ideas of what would be expedient in the present condition of the country, I would say, not only let them go with what they have taken, but let them have what is still left to take, if they desire it; for I am satisfied, as much as I can be of any fact, that has to occur in the future, that one year will not roll round until the people of each and all
                  of those States which have, in the estimation of some, withdrawn themselves from the Union, will rise in their majesty, assert their power, hurl those men from the places which they have obtained through their confidence, and raise again, high above the rattlesnake and the palmetto, the stars and stripes of our beloved land. Believing this, I would let them alone. I would let them,
                  to use the language of politicians, "stand out in the cold awhile," and, I warrant you, they will come shivering back, glad to get to a Union fire.
                </p>
                <p className="p-in-sp">
                  Mr. President, if I had strength, and if it would not be an abuse of the patience of this Convention, who so kindly indulged me yesterday with an opportunity to speak to-day, and to whom I am greatly indebted for the attention with which they have heard me, I would like to call their attention, and the attention of the country, to the condition in which we would be placed,
                  particularly here in Virginia, in the event of a dissolution of the Union.
                </p>
                <p className="p-in-sp">
                  Look at Virginia, her central position in the Confederacy, possessing within her broad limits the mineral wealth found anywhere and everywhere in the United States; the products of the Union are hers with the exception of sugar and rice; wielding a power and an influence in this Government by virtue of her very position, her central position, that she could never wield if the
                  Confederacy were dissolved. Look, when she recommended a Peace Conference; her recommendation is responded to by 21 States, as quick as the lightning can bear to them the resolutions requesting it. What other State could have accomplished so much <PageNumber num={500} /> in so short a time? Why is it that Virginia possesses the influence? Because of her
                  position; because of her sacrifices made for the Union; because of her well known devotion to the Union; because she was the principal architect in its construction; because she has ever been governed by the impulse of a patriotic heart ; because her material interests are such as make her interests equal between the sections.
                </p>
                <p className="p-in-sp">But dissolve the Union, and hitch her on to the tail of a Southern Confederacy, to stand guard and play patrol for King Cotton, and where would she be? What son of Virginia can contemplate this picture without horror?</p>
                <p className="p-in-sp">
                  "0, but," our friends say, "if you don't unite in a Cotton Government, they will not buy our negroes." I say they cannot get them anywhere else. I have no fear of their ever re-opening the African slave trade. No, sir, no slaveholding Republic will ever be permitted to do it. England will not allow it, France will not allow it, Spain will not allow it, nor would a Northern
                  Confederacy allow it. Even now, great and powerful as we are, with a large portion of our territory dedicated—as the Black Republicans call it—to freedom; even now, great as we are, dictating upon almost every other subject, our treaties with other nations upon our own terms, we are compelled to keep up a force, at an expenditure of millions of dollars, to prevent this African slave
                  trade. They are bound to buy our negroes. They could never coerce me into any act which my judgment disapproved of, by threatening that they would not buy my negroes.
                </p>
                <p className="p-in-sp">
                  Sir, is not language like this, employed by these secessionists with the design of influencing the minds of the people of Virginia, an insult to the honor, and the intelligence, and the patriotism of our people? "But, oh, our honor is at stake, our rights are denied," we are told by some. Pray, gentlemen, wherein has your honor suffered, or is likely to suffer? Tell me, if you
                  please, wherein any thing infringing upon Virginia's honor has been attempted, much less executed? What right has ever been denied? Haven't you equal rights in the Territories? Has not this very Government, that you are going to overthrow, declared that you have? Haven't you equal rights, as States, in the Federal Government? Has not the little State of Florida, with its forty-seven
                  thousand white inhabitants, and its twenty-three millions of property, an equal share in this Government with the great State of New York, with its three millions of white inhabitants, and its thousands of millions of property? Has not the State of South Carolina, with a white population not half as large as the single city of Philadelphia, an equal voice in the control of this
                  Government with the whole great <PageNumber num={501} /> State of Pennsylvania, with her two million five hundred thousand inhabitants? Then what has been denied you? Put your finger upon the right that has been taken away from you. What right has been denied in this Government? Wherein does this inequality consist? May it not be, gentlemen—and I ask it
                  with all kindness—may it not be that you have mistaken party platforms for the Constitution of the United States, and the action of individual parties for the action of the Federal Government?
                </p>
                <p className="p-in-sp">
                  Mr. President, with our extended frontier, with our defenceless sea coast, tell me the amount of money that would be required so to fortify the State, in case of a revolution, as to afford the slightest protection not only to our slave property, but against those John Brown forays upon a larger scale? And, by the way, let me here call your attention to a single fact, namely, that it
                  was fourteen of the marines of this very Federal Government, which you want to destroy, that took John Brown and his men out of the engine house. It was not all this army that you raised in Richmond and that we sent down from the border. It was fourteen marines belonging to the Federal Government which took that insurrectionary party out of the engine house, delivered them over to
                  your civil authorities, who justly tried and hung them; and it was the Governor of our sister State of Pennsylvania for, denounce me as submissionist if you please, apply whatever epithets you will, Pennsylvania is our sister State—and it was the Governor of Pennsylvania who delivered up to us Hazlett and Cook, and, in doing so, he behaved as the Chief Executive of a sister State
                  should behave.
                </p>
                <p className="p-in-sp">
                  Sir, can any man believe that in case of a dissolution of the Union, we would enjoy anything like the freedom and liberty and equality which we now enjoy under this General Government of ours? Could we maintain ourselves without a strong military force kept up at an enormous and exhausting expense? We are now, under the Union, and in the Union, the freest, the most independent, and
                  the happiest people on earth. Dissolve the Union, and a military despotism, the licentiousness of the camp and ragged poverty will be substituted in its place.
                </p>
                <p className="p-in-sp">
                  And now, Mr. President, in the name of our own illustrious dead, in the name of all the living, in the name of millions yet unborn, I protest against this wicked effort to destroy the fairest and the freest government on the earth. And I denounce all attempts to involve Virginia to commit her to self murder as an insult to all reasonable living humanity, and a crime against God. With
                  the dissolution of this Union, I hesitate not to say, the sun of our liberty will have set for ever. <PageNumber num={502} /> The following is the extract from the 39th number of the "Federalist," referred to in the course of the speech :
                </p>
                <p className="p-in-sp">APPENDIX</p>
                <p className="p-in-sp">From the XXXIX number of the "Federalist," written by James Madison.</p>
                <p className="p-in-sp">
                  But it was not sufficient, say the adversaries of the proposed Constitution, for the Convention to adhere to the Republican form. They ought, with equal care to have preserved the federal form, which regards the Union as a Confederacy of sovereign States; instead of which they have framed a national Government which regards the Union as a consolidation of the States. And it is asked,
                  by what authority this bold and radical innovation was undertaken? The handle which has been made of this objection, requires that it should be examined with some precision.
                </p>
                <p className="p-in-sp">
                  Without enquiring into the accuracy of the distinction on which the objection is founded, it will be necessary to a just estimate of its force, first, to ascertain the real character of the Government in question ; secondly, to enquire how far the Convention were authorized to propose such a Government; and thirdly, how far the duty they owed to their country could supply any defect
                  of regular authority.
                </p>
                <p className="p-in-sp">First. In order to ascertain the real character of the Government, it may be considered in relation to the foundation on which it is to be established; to the sources from which its ordinary powers are to be drawn; to the operation of those powers ; to the extent of them ; and to the authority by which future changes in the Government are to be introduced.</p>
                <p className="p-in-sp">
                  On examining the first relation, it appears, on one hand, that the Constitution is to be founded on the assent and ratification of the people of America, given by deputies elected for the special purpose; but on the other, that this assent and ratification is to be given by the people, not as individuals composing one entire nation, but as composing the distinct and independent
                  States to which they respectively belong. It is to be the assent and ratification of the several States, derived from the supreme authority in each State—the authority of the people themselves. The act therefore establishing the Constitution will not be a national, but a federal act.
                </p>
                <p className="p-in-sp">
                  That it will be a federal, and not a national act, as these terms are understood by the objectors, the act of the people, as forming so many independent States, not as forming one aggregate nation, is obvious from this single consideration, that it is to result neither from the decision of a majority of the people of the Union, nor that of a majority
                  <PageNumber num={503} /> of the States. It must result from the unanimous assent of the several States that are parties to it, differing no other wise from their ordinary assent than in its being expressed, not by the legislative authority, but by that of the people themselves. Were the people regarded in this transaction as forming one nation, the will
                  of the majority of the whole people of the United States would bind the minority; in the same manner as the majority in each State must bind the minority ; and the will of the majority must be determined either by a comparison of the individual votes, or by considering the will of the majority of the States, as evidence of the will of a majority of the people of the United States.
                  Neither of these rules has been adopted. Each State, in ratifying the Constitution, is considered as a sovereign body, independent of all others, and only to be bound by its own voluntary act. In this relation, then, the new Constitution will, if established, be a federal, and not a national Constitution.
                </p>
                <p className="p-in-sp">The next relation is, to the sources from which the ordinary powers of government are to be derived.</p>
                <p className="p-in-sp">
                  The House of Representatives will derive its powers from the people of America, and the people will be represented in the same proportion, and on the same principle, as they are in the Legislature of a particular State. So far, the government is national not federal. The Senate, on the other hand, will derive its powers from the States, as political and coequal societies ; and these
                  will be represented on the principle of equality in the Senate, as they now are in the existing Congress. So far the Government is federal not national. The executive power will be derived from a very compound source. The immediate election of the President, is to be made by the States in their political characters. The votes allotted to them are in compound ratio, which considers
                  them partly as distinct and coequal societies; partly as unequal members of the same society. The eventual election again, is to be made by that branch of the Legislature which consists of the national representatives; but in this particular act, they are to be thrown into the form of individual delegations, from so many distinct and co-equal bodies politic. From this aspect of the
                  government, it appears to be of a mixed character, presenting at least as many federal as national features.
                </p>
                <p className="p-in-sp">
                  The difference between a federal and national government, as it relates to the operation of the government, is, by the adversaries of the plan of the Convention, supposed to consist in this, that in the former, the powers operate on the political bodies composing the Confederacy, in their political capacities ; in the latter, on the individual citizens
                  <PageNumber num={504} /> composing the nation in their individual capacities. On trying the Constitution by this criterion, it falls under the national, not the federal character; though perhaps not so completely as has been understood. In several cases, and particularly in the trial of controversies to which States may be parties, they must be viewed
                  and proceeded against in their collective and political capacities only. But the operation of the government on the people in their individual capacities, in its ordinary and most essential proceedings, will, on the whole, in the sense of its opponents, designate it, in this relation, a national government.
                </p>
                <p className="p-in-sp">
                  But, if the government be national, with regard to the operation of its powers, it changes its aspect again, when we contemplate it in relation to the extent of its powers. The idea of a National Government involves in it, not only an authority over the individual citizens, but an indefinite supremacy over all persons and things, so far as they are objects of lawful government.
                </p>
                <p className="p-in-sp">
                  Among a people consolidated into one nation, this supremacy is completely vested in the national legislature. Among communities united for particular purposes, it is vested partly in the general and partly in the municipal legislatures. In the former case, all local authorities are subordinate to the supreme, and may be controlled, directed, or abolished by it at pleasure. In the
                  latter, the local or municipal authorities form distinct and independent portions of supremacy, no more subject within their respective spheres, to the general authority, than the general authority is subject to them within its own sphere. In this relation, then, the proposed government cannot be deemed a national one, since its jurisdiction extends to certain enumerated objects
                  only, and leaves to the several States a residuary and inviolable sovereignty over all other objects. It is true, that in controversies relating to the boundary between the two jurisdictions, the tribunal which is ultimately to decide is to be established under the General Government. But this does not change the principle of the case. The decision is to be impartially made,
                  according to the rules of the Constitution, and all the usual and most effectual precautions are taken to secure this impartiality. Some such tribunal is clearly essential to prevent an appeal to the sword and a dissolution of the compact, and that it ought to be established under the general rather than under the local governments; or, to speak more properly, that it could be more
                  safely established under the first alone, is a position not likely to be combatted.
                </p>
                <p className="p-in-sp">
                  If we try the Constitution by its last relation to the authority by which amendments are to be made, we find it neither wholly national, nor wholly federal. Were it wholly national, the supreme and ultimate <PageNumber num={505} /> authority would reside in the majority of the people of the Union; and this authority would be competent, at all times,
                  like that of a majority of every national society, to alter or abolish its established government. Were it wholly federal, on the other hand, the concurrence of each State in the Union would be essential to every alteration that would be binding on all. The mode provided by the plan of the Convention is not founded on either of these principles. In requiring more than a majority, and
                  particularly in computing the proportion by States, not by citizens, it departs from the national and advances towards the federal character. In rendering the concurrence of less than the whole number of States sufficient, it loses again the federal and partakes of the national character.
                </p>
                <p className="p-in-sp">
                  The proposed Constitution, therefore, even when tested by the rules laid down by its antagonists, is, in strictness, neither a national nor a federal Constitution, but a composition of both. In its foundation it is federal, not national; in the sources from which the ordinary powers of the Government are drawn, it is partly federal and partly national; in the operation of these
                  powers, it is national, not federal ; in the extent of them, again, it is federal, not national; and, finally, in the authoritative mode of introducing amendments, it is neither wholly federal nor wholly national.
                </p>
                <p className="p-in-sp">Mr. Madison, in his letter to Mr. Trist, in 1832, says :</p>
                <p className="p-in-sp">
                  "I partake of wonder that the man you name should view secession in the light mentioned. The essential difference between a free government and a government not free is that the former is founded on compact, the parties to which are mutually and equally bound by it. Neither of them, therefore, have a greater right to break off from the bargain than the other or others have to hold
                  them to it. And certainly there is nothing in the Virginia resolutions of 1798 adverse to this principle, which is that of common sense and common justice. The fallacy which draws a different conclusion lies in confounding a single party with the parties to a constitutional compact of the United States. The latter, having made the compact, may do what they will with it. The former,
                  as only one of the parties, owes fidelity to it till released by consent or absolved by an intolerable abuse of the power created. It is high time that the claim to secede at will should be put down by public opinion."
                </p>
                <p className="p-in-sp">In his letter to Mr. Cabell, in 1831, he said :</p>
                <p className="p-in-sp">"I know not whence the idea could proceed that I concurred in the doctrine that, although a State could not nullify a law of the Union, <PageNumber num={506} /> it has a right to secede from the Union. Both spring from the same poisonous root."</p>
                <p className="p-in-sp">In his letter to Mr. Everett, in 1830, Mr. Madison said :</p>
                <p className="p-in-sp">"It [the Constitution] 
                  was formed not by the Governments of the component States, as the Federal Government for which it was substituted was formed; nor was it formed by a majority of the people of the United States, as a single community, in the manner of a consolidated Government. It was formed by the States—that is, by the people in each of the States, acting in their highest sovereign capacity, and
                  formed consequently by the same authority which formed the State constitutions.
                </p>
                <p className="p-in-sp">
                  "Being thus derived from the same source as the constitutions of the States, it has within each State the same authority as the constitution of the State, and is as much a constitution, in the strict sense of the term, within its prescribed sphere, as the constitutions of the States are within their respective spheres, but with this obvious and essential difference, that being a
                  compact among States in their highest sovereign capacity, and constituting the people thereof one people for certain purposes, it cannot be altered or annulled at the will of the States individually, as the constitution of a State may be at its individual will."
                </p>
                <p className="p-in-sp">Mr. Madison, on the 15th day of March, 1833, wrote to Daniel Webster, upon the receipt of Mr. Webster's great speech, on the Carolina doctrine of nullification and secession, as follows :</p>
                <p className="p-in-sp">
                  "I return my thanks for the copy of your late very powerful speech in the Senate of the United States. It crushes 'nullification,' and must hasten the abandonment of 'secession.' But this dodges the blow of confounding the claim to secede at all, with the right to secede from intolerable oppression. The former answers itself, being a violation without cause of a faith solemnly
                  pledged. The latter is another name for revolution, about which there is no theoretic controversy," &amp;c.
                </p>
                <div className="speaker" id="sp558"><span className="persName">Mr. COX</span>—</div>
                <p className="p-in-sp">I want a vote upon this resolution this day, if it can be had. I should not now rise to say one word to the Convention at this late hour of the day, when I desire that the vote shall be taken, were it not for the extraordinary speech made by the gentleman [<span className="persName">Mr. CARLILE</span>] , who has just taken his seat.</p>
                <p className="p-in-sp">
                  He commenced his speech by saying that the inaugural was not the cause of the introduction of the resolution here, but was made the excuse for it. The original resolution was offered by myself, I intended to have offered that resolution many days ago, before I saw the inaugural. <PageNumber num={507} /> But I consulted some of the members of the
                  Committee of twenty-one, and I was informed—
                </p>
                <div className="speaker" id="sp559"><span className="persName">Mr. CARLILE</span>—</div>
                <p className="p-in-sp">Will the gentleman allow me to interrupt him right at this point. In the whole of my remarks I did not have in view your original resolution. My remarks were addressed principally to the amendment of the gentleman from Goochland [<span className="persName">Mr. LEAKE</span>] .</p>
                <p className="p-in-sp">Furthermore, I desire to say, if any remark that I have made is calculated to be construed by any gentleman into a personally offensive remark, I entirely disclaim any such intention whatever.</p>
                <div className="speaker" id="sp560"><span className="persName">Mr. COX</span> [resuming]—</div>
                <p className="p-in-sp">Mr. President, the gentleman from Harrison has expressed upon the subject of coercion, sentiments that I did not believe would fall from any member in this Convention.</p>
                <div className="stage it">[Applause.]</div>
                <div className="speaker" id="sp561"><span className="persName">The PRESIDENT</span></div>
                <p className="p-in-sp">The galleries will be cleared upon any renewal of such manifestations.</p>
                <div className="speaker" id="sp562"><span className="persName">Mr. COX</span>—</div>
                <p className="p-in-sp">If I understood the gentleman, he tells us that the coercion of a sovereign State is war. Sir, it is war; and the reason I want a report upon the subject of coercion, the reason I want a test vote, showing that Virginia is opposed to coercion, is that I want to prevent civil war.</p>
                <p className="p-in-sp">
                  Sir, that gentleman tells us that he is favor of this Union, and he sings paeans to the Union. I like him love the Union ; I want—though the hope is now faint to preserve or re-construct this Union and keep it entire in all its parts. But, sir, will abuse of South Carolina—as wrong as she may have been, and I condemn her for it—will abuse of her and her people—will abuse of the
                  Southern people who have seceded—will denunciations of them as rebels and traitors be calculated to bring them back to us? I condemn the rash course of South Carolina and the other States that have gone off with her. Seven sovereign, independent States have judged for themselves and have chosen to leave this Confederacy. I want, by our action here, if possible, to bring these States
                  back, and make this Union again entire.
                </p>
                <p className="p-in-sp">
                  But will coercion accomplish that end? Will abuse and denunciation of those States accomplish it? No sir! The very moment you attempt coercion you produce war, and war will end this Union now and forever. It breaks up all hope; faint as the hope is now—that faint hope is gone forever. I will not undertake to discuss this question of coercion, as it was so ably done by my friend from
                  Halifax
                 [<span className="persName">Mr. FLOURNOY</span>] , every word of whose speech I endorse most cordially; but I will not, at this late hour, undertake to detain the Convention by a discussion on that subject.</p>
                <p className="p-in-sp">I tell the gentleman who has just taken his seat, that if he wants <PageNumber num={508} /> to preserve this Union, he cannot preserve it by any acts of coercion or any attempt to collect unwilling tribute from these States that have thought proper to leave us.</p>
                <p className="p-in-sp">A word in regard to my resolution.</p>
                <p className="p-in-sp">One branch of it is, that Virginia shall express her opinion against coercion.</p>
                <p className="p-in-sp">The other branch of it is, that she shall invite a Convention of the border slave States—and by that I mean, every slave State which has not yet seceded—to meet us in a conference Convention and advise with us as to what is best to be done in the present distracted state of the country. If the gentleman from Harrison [<span className="persName">Mr. CARLILE</span>] 
                  , be a Union man, he ought to go with me, for if there is any thing upon earth to save the Union and re-construct it—such a course is the one to take. Virginia has done all she could so far, to adjust these difficulties. She has called a Peace Convention at Washington. That Peace Convention proved abortive. I now say that Virginia, in honor, cannot ask for another Peace Conference,
                  and if any propositions of compromise, any measures to reunite these States, and to preserve this Union entire be ever made again, they must be made by a Conference of the Border States together. This Union may be saved, and to effect this, we should confer with these Border States. Tennessee has determined that she will not call a Convention ; North Carolina has probably refused
                  also to call a Convention—the vote either way being so close that the result cannot be determined until the votes are officially counted ; Kentucky has not even called her Legislature together ; Maryland has taken no action. In Missouri we find that they have treated the Commissioners sent from Georgia to that State with indignity. In this state of feeling in the Border States, it is
                  due from Virginia to them that she shall ask them to meet her in Conference. I do not believe, if secession should be forced upon Virginia, that she should go by herself. South Carolina, unfortunately, adopted that policy. She went off alone, thinking that her precipitancy would drag the other States with her. I condemn the course she pursued and I would condemn the same course in
                  Virginia. No matter what may be the course Virginia may take, whether for war or peace, she ought to take it in concert with the action of the Border States. From the action of the Border States in declining to call Conventions, you would make them stultify themselves in attempting to drag them out with you, without first meeting them in convention and adopting some concert of
                  action. We want the benefit of their counsels and their deliberations for the purpose of preserving the Union; and if <PageNumber num={509} /> the Union cannot be preserved, and we have to leave it, then we want them to go along with us; we want their aid and co-operation.
                </p>
                <p className="p-in-sp">
                  I will not detain the Convention, at this late hour, with any extended remarks. I do express the hope that a vote will be taken now upon the propositions I introduced. When I introduced them, I hoped that a vote would be taken right away. There is a feverish and excited state of the public mind. They want to see this Convention do something, and that speedily. The first thing to be
                  done, I contend, is to act upon the subject of coercion, in order to prevent civil war and procure peace. When that is done, we can call a Conference of the Border States, and then we will have time to deliberate freely, fully and reflectingly upon this subject.
                </p>
                <p className="p-in-sp">These are the subjects that I think ought to be acted upon by us, and when that is done, we can act, as I have already said, with all the deliberation that the grave issues now pending before the country, demand. I am as much opposed to haste as my friend from Richmond [<span className="persName">Mr. JoH</span>NsoN] ; but I pray, gentlemen, let us act upon these two subjects promptly, because, although they are but preliminary steps, they may prevent civil war. Peace being secured, we can then stay here calmly, and deliberate upon other subjects that may come up for our action.</p>
                <div className="speaker" id="sp563"><span className="persName">Mr. BRENT</span>—</div>
                <p className="p-in-sp">As the hour for adjournment has nearly arrived, I move that the Convention do now adjourn.</p>
                <p>Mr. Cox called for the yeas and nays, but afterwards withdrew the call.</p>
                <p>The question was then taken on <span className="persName">Mr. BRENT</span>'S motion, and it was agreed to.</p>
                <p>The Convention accordingly adjourned till to-morrow at 12 o'clock, M.</p>
              </div>
            </div>
            <div className="day" id="vsc1965.v1.2.20">
              <PageNumber num={510} />
              <h2><span className="headingNumber">1.20. </span><span className="head">TWENTIETH DAY</span></h2>
              <div className="dateline">Friday, <span className="date">March 8</span></div>
              <p>The Convention met at 12 o'clock.</p>
              <p>Prayer by the Rev. <span className="persName">Mr. MOORE</span> of the Presbyterian Church.</p>
              <div className="section" id="vsc1965.v1.2.20.1">
                <h3><span className="headingNumber">1.20.1. </span><span className="head">PERSONAL EXPLANATION</span></h3>
                <div className="speaker" id="sp564"><span className="persName">Mr. EARLY</span>—</div>
                <p className="p-in-sp">With the permission of the gentleman from Alexandria [<span className="persName">Mr. BRENT</span>] , I rise to a personal explanation.</p>
                <p className="p-in-sp">I am sorry that the gentleman from Bedford [Mr. GOODE] 
                  , is detained from his seat by indisposition; but a misapprehension which seems to exist in certain quarters as to what occurred on the day before yesterday, the 6th inst., between that gentleman and myself on this floor, renders it necessary that a correspondence, which has taken place between him and myself, should be read here; and I take occasion to say that it is read with his
                  knowledge and assent.
                </p>
                <p>The following correspondence was then read by the Secretary :</p>
                <div className="floatingText">
                  <div className="floatingText_body">
                    <p>RICHMOND, March 6, 1861.</p>
                    <p>American Hotel, 46.</p>
                    <p>
                      SIR-After your remarks in the Convention to-day, in which you undertook to comment on what you supposed to be my position heretofore, and the position which I had taken in the remarks I had just submitted to the Convention, and after you had referred to some letters you had received from my county, indicating a change in the sentiments of my constituents, when I obtained the
                      floor, I made use of the following remarks which I repeat almost verbatim : "The gentleman from Bedford, whose impatience is so great, whose steam has been up so high that he has not been content with the opportunities afforded him on this floor to let it off, but has taken one or more occasions to let off some of it in the streets, has thought proper to step between my
                      constituents and myself, and informs this Convention and the country that he has received letters from my home, showing a great change in the sentiment of my constituents, and that they are very impatient at the delay which I have been favoring. I presume the gentleman has not undertaken to give this information to the Convention and the country, unless he is willing to give up
                      his authority, and I call on him for it."
                    </p>
                    <p>
                      You asked at this point if I wished an answer then, to which I replied, that I did. In a very excited manner you proceeded to comment on the remarks that I had made, and said as near as I can recollect : <PageNumber num={511} /> "When the gentleman from Franklin says that I have sought opportunities to let off steam in the streets, he makes a
                      statement which is utterly without foundation," or "which has no foundation, in fact." I do not recollect exactly the precise expression, but this is the substance. You coupled your remarks with the declaration that you held yourself responsible to me for what you had said, either on the floor or elsewhere.
                    </p>
                    <p>This renders it necessary for me to enquire of you whether, in what you did say, you meant to convey the idea that I had stated a falsehood, or been guilty of a misrepresentation.</p>
                    <p>I also enquire if you intended the expression that you held yourself responsible to me on the floor or elsewhere, in an offensive sense?</p>
                    <p>Your ob't serv't, J. A. EARLY.</p>
                    <p>This note will be handed to you by my friend, Thos. James Deane, Esq.</p>
                    <p>J. A. EARLY.</p>
                    <p>JOHN GOODE, Jr., Esq.</p>
                  </div>
                </div>
                <div className="floatingText">
                  <div className="floatingText_body">
                    <p>EXCHANGE HOTEL, 7th March 1861.</p>
                    <p>Sir: Your note of yesterday was handed to me at a late hour in the afternoon by your friend, Thomas James Deane, and I take the earliest opportunity allowed me to reply.</p>
                    <p>
                      In the remarks submitted by you to the Convention on yesterday, I understood you to say in substance that I had not been content with the opportunities afforded me on that floor to let off steam ( as you choose to characterise my speeches) , but that I had sought opportunities to let it off in the streets, &amp;c. So understanding you, I replied that such a statement was wholly
                      without foundation in fact—and that I had addressed no people in this city until they had sought me at my lodgings, and called for me in a manner which I could not decline obeying. From your remarks, as cited by you in your note, and the report of the same in the "Enquirer" of this morning, I find that I misapprehended your language, and that you did not say, as I then understood
                      you to say, that I had sought opportunities," &amp;c. I was then led to say, that the statement made by you (as I understood it) , was without foundation in fact. It is but just for me to add that I did not design to impute to you falsehood or willful misrepresentation, but simply to deny the truth of your statement as I understood it at the time. My remark "that I held myself
                      responsible for what I say here or elsewhere" was intended to declare my purpose to vindicate my personal and representative rights whenever, wherever, however and <PageNumber num={512} /> by whomsoever assailed, and was not intended to be offensive to yourself or to any other person.
                    </p>
                    <p>Your obedient servant,</p>
                    <p>JOHN GOODE.</p>
                    <p>This note will be handed you by my friend E. C. Burks, Esq.</p>
                    <p>JOHN GOODE.</p>
                    <p>Maj. J. A. EARLY.</p>
                  </div>
                </div>
                <div className="floatingText">
                  <div className="floatingText_body">
                    <p>RICHMOND, March 7th, 1861.</p>
                    <p>
                      Sir : Your note of this morning was handed to me by your friend <span className="persName">Mr. E. C. Burks</span>, immediately after the adjournment of the Convention, to-day; and in reply thereto, I will say, that understanding you to disclaim any intention on your part to impute to me falsehoods, or wilful misrepresentation in what you said in the Convention on yesterday, and also
                      to disclaim any purpose of being personally offensive to me in the expression you used, as specified in my note of yesterday—I have no hesitation in declaring that I did not intend to be personally offensive to you in the remarks I made, which led to your response my purpose being to indicate my sense of the precipitancy with which I thought you were disposed to act, in
                      contra-distinction to the disposition to delay, which you imputed to me.
                    </p>
                    <p>I think I may add, there was nothing in our previous intercourse to produce the impression that I had a different purpose, and I was pained to perceive the evident misapprehension under which you labored at the time.</p>
                    <p>I am sorry to hear of the indisposition under which you labor.</p>
                    <p>Very respectfully,</p>
                    <p>Your obedient servant,</p>
                    <p>J. A. EARLY.</p>
                    <p>JOHN GOODE, JR., ESQ.</p>
                  </div>
                </div>
                <div className="speaker" id="sp565"><span className="persName">Mr. EARLY</span>—</div>
                <p className="p-in-sp">
                  Mr. President and gentlemen of the Convention, I have only to add, that when my first note was written and sent, I had no reason to apprehend that the gentleman from Bedford was at all indisposed. Since my last note was delivered to him, I have visited him at his room, and I am happy to say that our former relations are perfectly restored, and I trust soon to see him in his seat in
                  renewed health.
                </p>
                <p>FEDERAL RELATIONS</p>
                <div className="speaker" id="sp566"><span className="persName">Mr. WYSOR</span>—</div>
                <p className="p-in-sp">With the permission of the gentleman from Alexandria [<span className="persName">Mr. BRENT</span>] , who is entitled to the floor, I desire to submit the following <PageNumber num={513} /> ordinance, which I propose to have read by the Clerk and then referred to the Committee on Federal Relations.</p>
                <p>The Ordinance was then read as follows, by the Clerk :</p>
              </div>
              <div className="section" id="vsc1965.v1.2.20.2">
                <h3><span className="headingNumber">1.20.2. </span><span className="head">AN ORDINANCE</span></h3>
                <p>Dissolving all political connection now existing between the State of Virginia and other States composing the American Union, and establishing the separate independence of the former.</p>
                <p>
                  We, the people of the State of Virginia in Convention assembled, having been called together to consider the present distracted and unhappy relations existing between the slaveholding and non-slaveholding members of the Federal Union, and to provide suitable and adequate remedies for the evils which flow from those relations, do most solemnly declare that whilst we have always kept
                  and performed all the covenants of the Constitution of the United States on our part truly, fully and in good faith, our non-slaveholding Confederates have, not only failed and refused to keep the same, and still continue so to refuse, but they have palpably violated them, both in their letter and spirit, in repeated instances, wherein their faithful performance was of the most
                  essential and vital consequence to our peace, our safety, and our happiness.
                </p>
                <p>In proof of this, we submit to the candid and impartial judgment of mankind the following plain recital of facts :</p>
                <p>The people of these States have, for the last 30 years or more, assailed negro slavery, an institution recognized by the Constitution of the United States, and of vital importance in the social and industrial system of this and the other Southern States, without scruple, as to the means or mode of attack, and without regard to the consequences to us.</p>
                <p>
                  They have, during this whole period, abusing the great rights of freedom, of speech and of the press, continued to agitate and discuss this social and domestic relation of ours, not theirs, on the hustings, in the pulpit, in the halls of legislation of individual States, and of Congress—through the political press, through books, pamphlets, and the drama, to our great annoyance and
                  injury, and against our repeated remonstrances, and our entreaties that these unfriendly acts should be forborne.
                </p>
                <p>
                  They have prostituted the mail service of the United States, designed to facilitate friendly, social and commercial correspondence, to the unholy purpose of distributing incendiary publications among our people, thereby disturbing our peace, rendering our slaves discontented <PageNumber num={514} /> and unhappy, and inciting them to insurrection and
                  rebellion against their masters.
                </p>
                <p>They have, for the same unholy purposes, sent emissaries and incendiaries among us.</p>
                <p>They have encouraged and promoted the invasion of our soil by lawless men, armed against our peace, our property and our lives, and have, by public ovations, converted into heroes and martyrs the murderers of our people.</p>
                <p>
                  A majority of these States have persistently refused to comply with the provisions of the Federal Constitution for the rendition of fugitives from labor, and have, by legislative enactments, impeded, hindered and prevented the execution of the fugitive slave law, made in pursuance of this provision; and several of said States have refused to surrender fugitives from justice in cases
                  where the crime imputed to the fugitive consisted in a violation of our rights with respect to slaves.
                </p>
                <p>These States have, with one voice, denied to this and the other slaveholding States an equal participation in the common territory of the United States, with the avowed object of confining slavery within its present limits, and of "placing it in the course of ultimate extinction."</p>
                <p>And, finally, they have set up over us an absolute despotism, consisting in the unchecked will of a mere sectional and numerical majority, which has seized upon the powers of the government and holds them for our present injury and ultimate destruction.</p>
                <p>By these means, the powers granted by the Constitution have been perverted to our injury and oppression, and a foreign rule has been established for the control of our interests, the subversion of our rights, and our complete subjection to absolute power.</p>
                <p>
                  And whereas, when the people of Virginia, in Convention assembled, assented to and ratified the Constitution of the United States, they did declare and make known "that the powers granted under the Constitution, being derived from the people of the United States, may be resumed by them whensoever the same shall be perverted to their injury or oppression, and that every power not
                  granted thereby remains with them, and at their will."
                </p>
                <p>
                  Now, therefore, we, the people of the State of Virginia, conscious of the right, and hoping, under God, to maintain it, do declare and ordain, and it is hereby declared and ordained, that the ordinance adopted by us in Convention, on the twenty-fifth day of June, in the year of our Lord one thousand seven hundred and eighty-eight, whereby the Constitution of the United States of
                  America was ratified, and <PageNumber num={515} /> also all acts and parts of acts of the General Assembly of this State, ratifying amendments of the said Constitution, are hereby repealed, rescinded and abrogated.
                </p>
                <p>We do further declare and ordain that the Union now subsisting between the State of Virginia and other States, under the name of the United States of America, is hereby dissolved; and that the State of Virginia is in the full possession and exercise of all those rights of sovereignty which belong and appertain to a free and independent State.</p>
                <p>We do further declare and ordain that the effect of the foregoing ordinance shall be to absolve each and every citizen of this Commonwealth, wherever residing, from all duty of obedience to the authority of the Federal Government, whether civil or military.</p>
                <p>
                  We do further declare and ordain that the Governor, by and with the advice and consent of the Lieutenant-Governor and Attorney General, shall have power, in addition to the purposes for which he may now embody the militia of the Commonwealth, to embody them to repel any and every attempt by force to intimidate or coerce the people of this State to submit, against their will, to the
                  authority of the Federal Government.
                </p>
                <p>Provided, That the foregoing ordinance shall not take effect or be of any force until the same shall have been submitted to and ratified by the votes of a majority of the people of this State, at a poll therein, to be taken on the day, , in the year 1861, in pursuance of a schedule hereafter to be enacted.</p>
                <p>Referred to the Committee on Federal Relations.</p>
                <div className="speaker" id="sp567"><span className="persName">Mr. CAMPBELL</span>—</div>
                <p className="p-in-sp">With the permission of the gentleman from Alexandria [<span className="persName">Mr. BRENT</span>] 
                  , I will, in compliance with the request of some of the citizens of Washington county, present to this Convention a series of resolutions, adopted on the 6th of February.<span className="note" id="Note61"><span className="noteLabel">1</span>The resolutions were adopted on March 6.</span> I would have simply asked for their reading by the Clerk without accompanying them with any remarks but
                  for the fact of the motley character of this meeting of citizens of Goodson, Washington county. Out of the four speakers who addressed the meeting only two of them are citizens of the State of Virginia; one of them is a Tennessean; another, if he hails from anywhere, comes from the city of New York. I shall always take pleasure in presenting to this Convention any communication
                  respectful in its terms which may be addressed to me with the request I shall so present it, when it comes from my constituents or from other portions of the State of Virginia, who may not from any cause be represented upon this floor. But, sir, when a yankee adventurer, a man who, to hear him talk, <PageNumber num={516} /> holds close communion with
                  Seward and all that class of persons, and who, I presume, if he were talking with a Black Republican, would impress you with the idea that he was the especial friend of Abraham Lincoln, takes part in a meeting—I do rise up and denounce the attempt of such a person as that to thrust his views upon the people of Virginia, at this time. Could insolence go further and effrontery assume a
                  more despicable character.
                </p>
                <p className="p-in-sp">
                  I do not want to dignify these resolutions with the notice I think they deserve, because, so far as any citizens of Washington county are concerned, they have a right to hold meetings, pass resolutions, and ask their representatives here to lay them before this body. In this precinct of Goodson, at a town meeting, on the 4th of February last, 45 citizens voted against me, and
                  recorded, at the polls, their opposition to Virginia remaining longer in the Union. But there happened to be 1550 men in Washington county who differed with them, and hence I happen to have a seat here. I send the resolutions to the Clerk, and ask that they may be read.
                </p>
                <p>The resolutions were then read, as follows :</p>
              </div>
              <div className="section" id="vsc1965.v1.2.20.3">
                <h3><span className="headingNumber">1.20.3. </span><span className="head">MEETING IN GOODSON</span></h3>
                <p>At a meeting of the citizens of Goodson, Washington County, Va., held on Wednesday, March 6, 1861, to have an expression of sentiment in regard to the views set forth in the inaugural address of Abraham Lincoln.</p>
                <p>Col. S. E. Goodson was called to the chair, and Wm. F. Moon, Esq., was requested to act as secretary.</p>
                <p>On motion, the chair appointed a committee of three, consisting of John S. Mosby, A. M. Appling and John N. Bosang, to draft resolutions expressive of the sense of this meeting.</p>
                <p>Brief addresses were made by Col. Goodson, A. H. Dorr, L. F. Johnson and J. S. Mosby.</p>
                <p>The committee reported the following resolutions, which were adopted without a dissenting voice:</p>
                <p>Whereas, Abraham Lincoln, in his inaugural address, has proclaimed his purpose of coercing into subjection the seceded States of the South, and repudiated the decisions of the Supreme Court, therefore be it</p>
                <p>Resolved, That we regard such a policy as a declaration of war against the seceded States, in resistance to which we pledge our lives, our fortunes and our sacred honor.</p>
                <p>Resolved, That bound as we are to our brethren of the South by <PageNumber num={517} /> every tie of interest and affection, it now becomes us to make common</p>
                <p>cause with them in a struggle for liberty, equality and independence.</p>
                <p>Resolved, That in view of the foregoing facts, Virginia should, by an immediate ordinance of secession sever the bonds that bind her to a prostituted Union, and form an alliance with her sister States of the South.</p>
                <p>Resolved, That a copy of these resolutions be forwarded to our representatives in the Convention now assembled in Richmond, with the request that they present them to that body.</p>
                <p>On motion the meeting adjourned.</p>
                <p>S. E. GOODSON, Chairman.</p>
                <p>WM. F. MOON, Secretary.</p>
              </div>
              <div className="section" id="vsc1965.v1.2.20.4">
                <h3><span className="headingNumber">1.20.4. </span><span className="head">FEDERAL RELATIONS</span></h3>
                <div className="speaker" id="sp568"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">The pending question is the motion of the gentleman from Amelia [<span className="persName">Mr. HARVIE</span>] , to amend the amendment offered by the gentleman from Goochland [<span className="persName">Mr. LEAKE</span>] , and upon this question the gentleman from Alexandria is entitled to the floor.</p>
                <div className="speaker" id="sp569"><span className="persName">Mr. GEORGE W. BRENT</span>, of Alexandria—</div>
                <p className="p-in-sp">
                  Mr. President : The events which have occurred during the past twelve months in the politics of Europe and of our own country, may well tend to awaken in the minds of thoughtful persons the reflection, whether permanent government on any fixed principle, can be long maintained without much difficulty. In Europe we behold political governments and institutions changing like the
                  shifting scenes of a theatre; we see society upheaved, dynasties changed, constitutions re-modeled, and States extinguished; and the public mind, aroused by the magnitude of these events, is preparing for the commencement of a new system for the redress of the grievances which have been endured, and for the attainment of some specific plan of improvement in their social and political
                  system. And, Mr. President, for the first time since the dismemberment of the great Roman fabric, the Italian heart and mind, cherishing the hope of a new and better civilization, is seen struggling for a constitutional union of the Italian States under one constitutional government.
                </p>
                <p className="p-in-sp">
                  In our own country we perceive similar phenomena reproduced, but under different and opposite circumstances. Here, sir, in the midst of a profound peace, when surrounded by all the elements of moral and material power and greatness, after a growth and progress unexampled in the history of nations, after exhibiting ourselves to the world as the great exemplar of Constitutional freedom
                  and ideas, we behold inaugurated <PageNumber num={518} /> a revolution which has dissolved the Union of the States, and torn asunder those who have been long bound together.
                </p>
                <p className="p-in-sp">
                  It is a painful reflection that in the throes of dissolution, in the midst of this era of national suicide, we read the story of our greatness, our numbers and our wealth, and are forced to contemplate the greatness and magnitude of that system which has been overthrown; for, sir, the era of national dismemberment is the era of the eighth census of the United States, and upon its
                  pages posterity will read the story of our greatness and our folly.
                </p>
                <p className="p-in-sp">
                  The consequences, Mr. President, which are involved in this wonderful event are not only of great importance to ourselves and to our posterity, but to the cause of human liberty and of all future civilization. Union or Disunion, peace, or civil war, are the issues involved. We should address ourselves, therefore, to their consideration, fully appreciating their importance and
                  magnitude and the responsibility which rests upon us, as the representatives of this great and ancient Commonwealth.
                </p>
                <p className="p-in-sp">
                  The secession of the cotton States from this Union, however sudden, has not come upon us by any sort of chance. It has been effected not so much by the unsoundness of the system which has been overthrown, as it has been by the conviction in the Southern mind that the true interests of the Southern States consist in independence, and this conviction has been fostered and strengthened,
                  and has grown in consequence of the mad agitation of the fanaticism in the Northern States; and hence we have that studied attempt on the part of Southern statesmen to transplant into the Southern mind the idea of an "irrepressible conflict" between the two sections of this Union.
                </p>
                <p className="p-in-sp">
                  Mr. President : Hostility to the Union of these States has existed for more than thirty years. And the institution of slavery has been seized upon at all times, by those who have been seeking the overthrow of this Union, as the occasion or pretext for their motives and aims. Abolitionism in the North, trained in the school of Garrison and Phillips, and affecting to regard the
                  Constitution as "a league with hell, and a covenant with death," has, with a steady and untiring hate, sought a disruption of this Union, as the best and surest means for the accomplishment of the abolition of slavery in the Southern States. And hence, sir, the song of triumph that went forth from the brazen throats of Garrison and Phillips, when South Carolina passed her ordinance
                  of secession which dissolved her connection with this Union; "all hail disunion! give her the forts and the arsenals and the sub-treasuries, <PageNumber num={519} /> lend her jewels of silver and gold, and Egypt will rejoice that she has departed."
                </p>
                <p className="p-in-sp">
                  Mr. President, South Carolina, and those leading statesmen of the South who have been educated in the philosophy of free trade, have likewise, with unwearied and constant assiduity pursued their schemes of disunion. Conscious of their inability to effect their schemes within the Union, they have sought a disruption of the States. Conscious of their impotency, after the successful
                  peaceful mediation made by Virginia in 1833, against their disunion tendencies, to effect their object upon any such political or economical policy, they have turned the Southern mind to considerations of a sectional character upon which they well knew the human mind was naturally sensitive and upon which there would be an identity of interest and of sentiment. Hence the cry which
                  went forth that the institution of slavery was endangered; the great excitement which was created in consequence of this cry, was unquestionably strengthened, and gained power in consequence of the lawless legislation upon the part of the North, by their attempt to arrest the operation of the Constitution of the United States by their Personal Liberty Bills, by their efforts to
                  impede the right to capture and bring back fugitive negroes, by the attempt upon their part—unjust, unnecessary and wicked—to exclude the South, with their property, from a common participation in the public domain; and the cry that went forth, that the equilibrium between the two sections had been destroyed, that the independence, the freedom and the institutions of the South were
                  in imminent peril, increased the excitement; and they were still further excited when they were told that the position of the South in this Government was not unlike that which is described by SPENSER in his Fairy Queen, of two brothers, one of whom saw the soil of his inheritance, daily wasting away and added to that of his rival, without the power of maintaining or restoring the
                  original equality.
                </p>
                <p className="p-in-sp">
                  In these two schools of political philosophy, Mr. President, I trace all the evils and disastrous troubles which now afflict and disturb our beloved and unhappy land. Like the hostile elements which the Hebrew mother carried within her womb, though they threatened her existence, yet she was unable to expel them from her loins. South Carolina has never concealed the cause of her
                  grievances, whether fancied or real. She has always boldly avowed it. Believing, on her part, that the system of protective tariffs had been adopted as the fixed and settled policy of this Government, she has regarded that system as the source of all her decadence in commerce and trade and has pertinaciously sought to secede from this Union, as her sole and only remedy. I do not
                  desire <PageNumber num={520} /> to do South Carolina any injustice. I admire all those noble qualities which belong to her character, and to the character of her sons ; and if South Carolina has committed errors, I would be indisposed, when I remember the respect and esteem which I bear to many within her limits; when I look at the patriotic impulses
                  and the spirit of liberty by which she has been heretofore animated, I would, in the language of Burke, "pardon much to a spirit of liberty."
                </p>
                <p className="p-in-sp">
                  Mr. President, I desire now to show that I have not done South Carolina injustice. I will now show that it has been more a desire to relieve herself of this system of protective tariffs than for protection of the institution of slavery, which has animated her in her political course. I have before me a volume of the Southern Review, published in the city of Charleston in the year
                  1851, and in an article which appeared after the settlement of the Compromise measures of 1850 we find the following :
                </p>
                <p className="p-in-sp">
                  "But the people of South Carolina have not yet entirely forgotten the angry feelings growing out of the war of the revolution. Well, then, let them read over the declaration of independence, and compare the wrongs recited there to those they now endure. What was the actual grievance then? What is it now? Then they 'augured mis-government at a distance.' Now the evil is upon them, and
                  tenfold greater evil than the most far-seeing politicians of that day anticipated from British tyranny. One, and but one of the luxuries of the rich was taxed, not more than five per cent. Now, every necessary of life, which she does not produce at home, is taxed, at an average rate of not less than thirty per cent. Then, Old England claimed the right to exact from her a portion of
                  the revenue necessary for the support of the British empire, while the amount expended for the benefit of South Carolina very far exceeded all that she was called on to contribute. Now, New England requires her, together with a few of her uncomplaining and acquiescent sisters, to furnish the whole revenue of the Union, no part of which comes back to them, except in the shape of
                  bribes to such as are willing to sell themselves into the service of their enemies, for Texas scrip and the emoluments of office."
                </p>
                <p className="p-in-sp">
                  Here we find the grievance and the wrong. And in 1861 we find the same policy inaugurated which was adopted in 1851. Those who do not concur and acquiesce in her views were denounced as submissionists; and those were charged then, as now, who did not concur with her in her sentiments of revolution and disunion, as being the recipients of "Texas scrip and the emoluments of office."
                  Here we have the wrongs and the grievances. Do we find in this article any prominent <PageNumber num={521} /> allusion to the rights of the South on the slavery issue? It is alone the question of taxation.
                </p>
                <p className="p-in-sp">A little further on, in the same volume, we find the remedy which South Carolina sought for those wrongs. It is found in the following words:</p>
                <p className="p-in-sp">
                  "What is there, at this day, antagonistic between the interests of Great Britain and those of South Carolina? Is not each the consumer of all the other's productions, reciprocally? Is not their relation, like that of the sexes, each necessary to the other? And shall South Carolina, like the Circassian slave, continue shut up in the harem of a brutal and sordid tyrant, when a generous
                  lover is waiting to make her his honored wife, and to establish her in wealth, and comfort, and freedom, and all the dignity of a Christian matron? How is this delusion kept up? It is from the same source whence all our other evils flow. With all her professions of friendship, Massachusetts hates England, with an inextinguishable, because interested, hatred. They are rivals in
                  commerce. They are rivals in manufactures. An especial object of rivalry is the commerce of the Southern States, and hence Massachusetts does all that she can to keep alive in the South their old animosities, and to prevent the growth of any sympathy between us and England. Hence she excites the impertinent clamors of English abolitionists. What for? Can they interfere with our
                  institutions? No! they can but make us angry."
                </p>
                <p className="p-in-sp">Thus South Carolina seeks her remedy in secession ; she seeks a remedy for her grievances in a free trade commercial alliance with England, and, although afraid of Massachusetts, she is not afraid of England.</p>
                <p className="p-in-sp">But let us see what are these disunion tendencies and proclivities. I will read from another portion, on page 299:</p>
                <p className="p-in-sp">
                  "We have no love for the Union; we have no fear of its dissolution. Welcome as summer shower to the sun-parched earth welcome as Heaven's free air to the heart-sick tenant of a dungeon—would come to us the voice of freedom, the word, the deed, which would tend to burst our bonds, and, in earnest faith, contribute to the disruption of this proud fabric (once beautiful, but now rotten
                  to the core) , which, under the name of Union, threatens to crush us beneath its unholy power."
                </p>
                <p className="p-in-sp">
                  Now, Mr. President, not only were these the sentiments enunciated in South Carolina in 1851, but they were among the moving causes of her action in 1860. In a proclamation, prepared by three of her distinguished men in her late Convention, signed by Messrs. Rhett and Adams, amongst the causes assigned for dissolution, is this old question
                  <PageNumber num={522} /> of taxation and free trade. To show still further, Mr. President, that I do not wish to do injustice to any one, that I do not wish to make statements here that are not sustained by the record, I will read the following extracts from speeches made in the recent South Carolina. Convention:
                </p>
                <p className="p-in-sp">Mr. Packer, speaking of secession, said : "It is no spasmodic effort that has come suddenly upon us, but it has been gradually culminating for a long series of years."</p>
                <p className="p-in-sp">Mr. Ingles said : "Most of us have had this subject under consideration for the last twenty years."</p>
                <p className="p-in-sp">Mr. Keitt said : "I have been engaged in this movement ever since I entered political life."</p>
                <p className="p-in-sp">Mr. Rhett said : "It is nothing produced by Mr. Lincoln's election, or the non-execution of the fugitive slave law. It is a matter which has been gathering head for thirty years." And he further said : "We are about to sunder our relations with that section [the North] , and I trust forever."</p>
                <p className="p-in-sp">On another occasion Mr. Rhett said : "The Federal laws laying taxes on the people of South Carolina have fallen this day; and, so far as we are concerned, fallen, I trust, forever."</p>
                <p className="p-in-sp">Mr. President, the argument which the able and distinguished gentlemen from South Carolina [<span className="persName">Mr. PRESTON</span>] 
                  , presented to us a short time since, was conclusive to show that the causes which have led to a disruption of this Union, originated in such diversities of soil, of climate, of domestic institutions, and modes of thought between the two sections, so perpetual and enduring in their character that they would present an insuperable barrier to the re-construction of the Union. I would
                  commend the argument of that able and distinguished Commissioner to those members of this Convention who desire, not the permanent disunion of these States, but who desire the immediate secession of Virginia with a view to the re-construction of this Union. That argument of the distinguished gentleman from South Carolina was sufficient to dispel the Syren song of re-construction
                  forever.
                </p>
                <p className="p-in-sp">The causes, Mr. President, which have been most generally assigned for the secession of Virginia, are, in my humble judgment, more specious and plausible, than sound or real. Three causes have been generally assigned for the secession of Virginia.</p>
                <p className="p-in-sp">
                  The first is the election of a Republican President of the United States. The second is the unjust and wicked attempt to exclude the Southern slaveholder from a participation of equal rights in the Territories of the United States. The third and last reason assigned is, that <PageNumber num={523} /> the North has refused to surrender up fugitive slaves
                  and has passed personal liberty bills, the object of which is to prevent re-capture and reclamation.
                </p>
                <p className="p-in-sp">
                  I think I have shown very clearly, from the argument presented by the Commissioner from South Carolina, and from the extracts which I have read from speeches made in the South Carolina Convention, that the election of Abraham Lincoln has had but little practical effect or influence in causing the secession of those Southern States which have dissevered their connection with the
                  Union.
                </p>
                <p className="p-in-sp">
                  But we are told that the South has been excluded from the Territories, that Southern planters have been prevented emigrating there with their property. I acknowledge that the South has equal rights in the Territories. I acknowledge the justice of this demand in all its length and breadth. It is a right which has been sanctioned by the highest judicial tribunal in the land. The
                  constitutional right is valuable and ought to be maintained, but I would ask how far is it available as a question of practical statesmanship? The absolute right is one thing, but the practicability of its exercise is another. In that view I take the ground that it has little real, practical value.
                </p>
                <p className="p-in-sp">
                  We have in the first place no territory belonging to the United States into which slave labor can profitably go. It will never go into any Territory where it is not profitable. This is the great economical law by which slave labor is governed, and this would present an insuperable barrier to its introduction into the Territories. The question is eminently a practical one. The
                  Northern States, at the time of the Revolution, were all slaveholding States. Slavery disappeared there, not in consequence of any hostility to the institution itself, or from motives of philanthrophy, but from motives of interest—for it ceased to exist because it ceased to be profitable. Northern slaveholders, under the guise of an enlightened philanthropy, under the mask of
                  prospective emancipation acts, sold all their negroes to the people of the South.
                </p>
                <p className="p-in-sp">
                  But it is not the nature of territorial soil, climate, productions and different modes of thought, that presents a barrier to the introduction of slavery into the Territories. The truth is, that the South has no slaves to go there. The South has yet vast and valuable cotton lands unoccupied, and the demand upon the slave labor of the South is so great that it is inadequate to meet
                  it. Cotton has become, as we are told here, the great necessity of the world, and wherever civilization has gone, cotton has followed in its track. The only limitation on the production of cotton is the want of labor, and hence every available hand that can be found, is turned upon the cotton fields, for the purpose <PageNumber num={524} /> of
                  increasing the production of that staple; yet the South is still unable to meet the demands which are made upon her. To use an Irish bull, the consumption of cotton is greater than its production ; and, in this condition of things, the result has been that the price of cotton, and land, and negroes, has risen. I have here, Mr. President, a statistical table, which has been prepared
                  by Mr. Kettell, one of the ablest statists in the country, in which he presents some curious facts in regard to the culture of cotton in this country :
                </p>
                <div className="table">
                  <table>
                    <tr>
                      <td></td>
                      <td>Crop Bales</td>
                      <td>No. Slaves</td>
                      <td>Slaves per bale</td>
                    </tr>
                    <tr>
                      <td>1800</td>
                      <td>35,000</td>
                      <td>857,095</td>
                      <td>24</td>
                    </tr>
                    <tr>
                      <td>1820</td>
                      <td>509,158</td>
                      <td>1,524,580</td>
                      <td>3</td>
                    </tr>
                    <tr>
                      <td>1830</td>
                      <td>870,415</td>
                      <td>2,005,471</td>
                      <td>2 1/4</td>
                    </tr>
                    <tr>
                      <td>1840</td>
                      <td>2,177,532</td>
                      <td>2,486,226</td>
                      <td>1 1/8</td>
                    </tr>
                    <tr>
                      <td>1850</td>
                      <td>2,796,796</td>
                      <td>3,204,051</td>
                      <td>1 1/6</td>
                    </tr>
                    <tr>
                      <td>1860</td>
                      <td>4,500,000</td>
                      <td>4,000,000</td>
                      <td>9/10</td>
                    </tr>
                  </table>
                </div>
                <p className="p-in-sp">
                  In 1800 we find that there was in this country for every bale of cotton produced 24 slaves, whilst, at the expiration of 1860, for every 26 bales of cotton produced in the United States there were but 24 slaves, averaging nine-tenths of a negro to a bale of cotton. Now, this table conclusively demonstrates another fact : that the transfer of slave labor from the cotton fields of the
                  South, where it is valuable, can never take place to the territories of the United States, where it is not valuable, where it would be worthless; nor is there any probability that territorial expansion for the blacks will be shortly needed. Not more than one-fourth of the cotton lands of the South are now in actual cultivation, and the number of slaves necessary to till those
                  uncultivated lands would require three times the present number, and it would require the lapse of 100 years before that number could be supplied by natural increase; and we are told, Mr. President, that in the meantime the demand for cotton will be greater than the increase of the negroes in the country. Col. Gouldin was, therefore, in the right when he declared, in the Charleston
                  Convention, that the South had no negroes to send into the territories of the United States. Mr. Yancey was also right at the Montgomery Convention, in Alabama, in May, 1858, when he demonstrated, in a clear and lucid argument, that the South had no slaves to send into these territories, and that prudence and necessity required the retention of slaves within the Southern States.
                </p>
                <p className="p-in-sp">
                  This argument, Mr. President, was presented by Mr. Yancey in that Convention, not upon the territorial question, because he informed the Convention that there was no territory to which he could send the negro; but it was presented by him upon the question of the repeal of <PageNumber num={525} /> the laws of Congress inhibiting the African slave trade.
                  At the Knoxville Convention, in Tennessee, in 1857, upon motion of Mr. Spratt, of South Carolina, a Committee was raised by that Convention to inquire into and report upon this subject. That report came in at Montgomery in 1858, and it was upon the report in favor of the abolition of the laws of Congress which prohibited the African slave trade, that this argument was presented by
                  Mr. Yancey. He denounced those laws as unconstitutional, unjust, discriminating, and odious to the rights and the institutions of the South. He declared that by the legislation of Congress, foreign emigration was encouraged, the effect of which was to increase the population of the North, thereby increasing its political power in the country, filling up its vacant and uncultured
                  lands, and rendering them more valuable, whilst the laws of Congress which prevented the African slave trade discriminated against the South, preventing the South from acquiring population from foreign sources, thereby causing a sparse population in the South, and as a consequence a diminution in the value of their lands and likewise diminishing the political power and weight of the
                  Southern States in the councils of the country. The whole scope and object of this argument was most manifest. It was to impress the Southern mind with the idea that their true destiny was in Southern independence.
                </p>
                <p className="p-in-sp">I have thus shown, I think, most satisfactorily that this territorial question has had but little practical weight or influence in precipitating the Cotton States into a revolution.</p>
                <p className="p-in-sp">
                  Another cause which gentlemen have assigned as a reason for secession is that our fugitive slaves have escaped to the North, and the Northern people, faithless to their constitutional obligations and duties, have not surrendered them but have prohibited their re-capture and reclamation by their personal liberty bills. Have South Carolina and the Cotton States suffered from any such
                  cause? The evils resulting from the escape of fugitive negroes are borne principally by the States along the border, by Virginia, Maryland, Kentucky and Missouri. The extreme Southern States, having two tiers of slaveholding States between them, have had little to complain of in regard to run-away negroes. And for these reasons, for these wrongs, for these grievances, not endured by
                  the Cotton States, but endured by the Border States, Virginia has been invited by these Cotton States into an immediate secession, and to link her destinies with the Cotton Confederacy. I will endeavor to show that Virginia is not invited to a banquet of peace, harmony, union, prosperity and power, but she is invited to a carnival
                  <PageNumber num={526} /> of death. We are invited into a Southern Confederacy. Secession and linking our destinies with the Southern Confederacy, we are told, will remedy all the wrongs and evils which we upon the border endure. I cannot perceive how secession can remedy any wrongs or grievances which the Border States now endure, and I believe that
                  secession will only aggravate and increase them.
                </p>
                <p className="p-in-sp">
                  We are told by the distinguished Commissioner from the State of Georgia, that if Virginia seceded from this Union, we would have the privilege of emigrating into the territories of the United States with our slave population. This argument assumes the fact that there will be a peaceable secession, and that there will be a peaceable partition of the public domain, and that the South
                  will get her just and proper share. How is it, if the South can obtain her portion of the public domain by means of a peaceable partition, that we have here been daily told since the commencement of the sessions of this Convention, that it is the policy of the dominant party to make war upon the South. How can we effect this peaceable partition when we are told that in his Inaugural,
                  the President of the United States threatens war, coercion and subjugation of the States which have seceded from this Union. Why, Mr. President, the argument is felo de se.
                </p>
                <p className="p-in-sp">
                  If there is a probability, or even a possibility, that there may be a peaceable separation of these States, if it shall turn out that the North and the South cannot agree upon amendments to the Constitution and guarantees which the South shall deem adequate for her own protection—would it not be much better for the border States to remain in this Union until that contingency shall
                  arise in order to effect this peaceable partition of the public domain? Will not the border States united in the Confederacy carry a more potential weight in effecting this peaceable settlement and averting the horrors of civil war by remaining than by instant secession, thereby increasing the irritation, bitterness and strife which already exist between the two sections of the
                  country? Is it not more reasonable to suppose that in an ordinary copartnership, a partner who has subjects of difficulty in dispute with his co-partner, can effect more promptly and more satisfactorily an adjustment of their difficulties whilst remaining in the co-partnership, than by defiantly withdrawing from it and abusing and outraging the feelings of the partner he has left
                  behind him?
                </p>
                <p className="p-in-sp">
                  But the argument is deprived of its force from other considerations. That portion of the territory which we would acquire by a peaceable partition is already slave territory. That portion of the territory which we would acquire would be New Mexico, and the condition of slavery <PageNumber num={527} /> is already recognized there by the law of that
                  territory. There exists a most stringent slave code in that Territory, and although we have the right and power to go there with our slaves, yet we cannot go for the reason I have already mentioned, that we have not the property to carry with us.
                </p>
                <p className="p-in-sp">But we are told that secession will remedy the escape of our slaves. In what manner? What is the remedy proposed? We have been told by the distinguished gentleman from Georgia [<span className="persName">Mr. BENNING</span>] , and I think his argument was adopted by my friend from Greene and Orange [<span className="persName">Mr. MORTON</span>] 
                  , that we could establish along the border, a cordon of military posts to intercept the fugitive. Mr. President, the remedy is worse than the disease. You would establish in the midst of the border States, within their very heart a standing army dangerous to the liberties and freedom of the people, and which would entail upon us for its support and maintenance a cost far greater than
                  the value of the slaves lost. Such a policy would invite the establishment of corresponding military posts along the line of the free border States, and strifes and collisions would inevitably ensue. And, finally, Mr. President, the remedy would be ineffectual, for experience has demonstrated that in all countries where no natural barriers intervene, large standing armies have proved
                  ineffectual to resist the escape of fugitives from justice, or the operations of the smuggler. But what would be the effect of secession upon the escape of fugitive slaves? Secession would relieve the Northern States from all constitutional obligations of duty to return our fugitive slaves. It would relieve the negro-stealer from all legal and constitutional restraints, and it would
                  give a secure and safe asylum upon our borders for the escape of the fugitive. As has been well said by some member upon this floor, it would bring Canada down to our very doors. It would be in vain to say that a refusal to render up fugitives would be a cause for war.
                </p>
                <p className="p-in-sp">
                  I am not aware of any principle in international law, or the comity that exists between nations, which would render it obligatory upon a nation to surrender a fugitive slave. Has it ever been demanded of England, within whose territorial limits thousands of our fugitive slaves are already settled? If any such demand has ever been made upon England, I am not aware of it. I am sure, if
                  it has ever been made, England has never complied with it. Year after year followed of long and protracted negotiations with England, in reference to compensation for slaves on board of our vessels, driven by stress of weather into British ports, and who were seized by British officials and violently set free. In the recent case of Anderson, who fled from Missouri into
                  <PageNumber num={528} /> Canada, charged with the crime of murder, a demand was made upon the British Government for him as a fugitive from justice, and the British Government refused to surrender him. Here was a case where British sympathy for the slave became paramount over their plighted faith and honor; and England, notwithstanding the stipulations
                  of the Ashburton treaty, under which she was bound to surrender fugitive criminals, had to yield to the supremacy of Exeter Hall, and refused the demand made upon her.
                </p>
                <p className="p-in-sp">Our distinguished Minister at the Court of England [<span className="persName">Mr. DALLAS</span>] 
                  , in a letter to the Secretary of State, upon this subject of the demand of Anderson, sets forth, clearly and distinctly, the tone and temper of English sentiment upon the subject, showing clearly that the sympathies of England were with the slave, and that notwithstanding her plighted faith and honor, she was unwilling to abide by those solemn stipulations which they have assumed,
                  because of their deference to sympathy with Abolition sentiment. I will read a short extract from this letter:
                </p>
                <p className="p-in-sp">
                  "It is scarcely necessary for me to remark, on the pungent and uncompromising hostility to social bondage which prevails throughout this country, that, as it has already led to giving by statute to the American slave who deserts his ship, a discriminating immunity over the freeman, so it cannot be expected to shrink from another manifestation in the interpretation of an international
                  convention for the mutual surrender of culprits. In truth, it may be said, generally, that in British opinion that status of slavery incapacitates the individual for contract or crime. You will notice that Lord Chief Justice COCKBURN, of the Queen's Bench, has, with 'surprising celerity,' allowed a writ of habeas corpus, addressed to the jailor in Canada, to be issued, and that
                  Anderson will thereupon be brought here, notwithstanding the very free and deliberate decision of the Colonial Court of the Queen's Bench ordering him to be delivered up. The learned Judge appears to have stated, among the reasons for his decision, that the case 'affects the construction of a treaty, which is a matter of imperial concern.' It may, perhaps, be thought expedient that
                  on the argument of the question the interpretation given by our Government should, in some form, be distinctly communicated."
                </p>
                <p className="p-in-sp">
                  It is scarcely necessary for me to remark upon it. I have read it to show the animus which operates upon the public mind of Great Britain in relation to the condition of slavery in this country. Can you suppose, when England refuses even under the solemn stipulations of her plighted faith to return, not a fugitive slave, but a criminal, that we
                  <PageNumber num={529} /> can hope to recover our fugitive slaves who may seek an asylum in the Northern States, after we have effected a separation from this confederacy? I think, then, that the evil in the matter of escaped fugitive negroes will be aggravated by secession rather than diminished.
                </p>
                <p className="p-in-sp">
                  But, apart from this, Mr. President, I regard secession as the doom of slavery within the border States. Secession, in the first place, will have the tendency to promote increased facilities for escape on the part of our negroes. The insecurity of the tenure by which your property will be held in the border States will render the emigration of slaves from these States more frequent
                  and numerous. The owner, conscious of the insecurity of his property, will be anxious to put it in a place of safety and security.
                </p>
                <p className="p-in-sp">
                  Apart from, this, Mr. President, the increased price of cotton and the negro will cause the removal of slaves from the border States, and the vacuum thus created will be supplied by persons from the North or from foreign emigration—thus introducing into the State persons who are hostile to the institution of slavery. All these causes, co-operating together, will, in a very short
                  period of time, cause an entire exodus of slaves from the border States of this Union. But who can tell whether disunion will come in peace or in the storm of war? I, for one, believe that a peaceful separation of these States cannot be effected. The interests are too great and too pervading to be snapped suddenly asunder without causing irritation, bitterness, strife and civil war.
                </p>
                <p className="p-in-sp">
                  What shall become of the public domain? What shall become of our army and navy? What shall be done in regard to the free navigation of the great rivers of the Ohio, Mississippi and the Missouri? Will the Northwestern States, whose territory is bounded by those great streams, in view of the greatness and magnitude of their commercial interests upon those rivers, tolerate or suffer,
                  for one moment, the mouth of the Mississippi to fall into the hands of a foreign republic or hostile State? Will the North-western and the Northern States, who are so deeply interested in the navigation of the Gulf of Mexico, permit the forts at Key West and Tortugas to pass into the hands of a foreign power, thereby making the Gulf a mare clausum. If this Union is to be involved in
                  war, the institution of slavery will vanish from our midst. The perpetuity of that institution depends upon peace and upon repose. Let civil war once sound its horrid tocsin in this land, and slavery is at once ended. In those countries where there have been alternate scenes of anarchy and military despotism, as for instance in Mexico, and the South American States, slavery has
                  disappeared from their midst—leaving the institution alone remaining in Brazil and Cuba, <PageNumber num={530} /> which have been free from civil war and intestine commotions.
                </p>
                <p className="p-in-sp">
                  What do we gain, Mr. President, by secession and a union of our destinies with the cotton States? What guarantee have we that the Southern Confederacy, which we are invited to join, will be maintained for any great length of time? Will not the causes which have operated in the present Union to disintegrate and overthrow one of the most beautiful fabrics that the ingenuity of man has
                  ever contrived, press with equal power, force, and effect in overthrowing the new Republic? Will they not have the same ever recurring question of taxation? Will not the same elements of antagonism exist and operate there? Will not the same question of political equilibrium between the different sections still operate in a Southern Confederacy? If the daring Herostratus of Revolution
                  has fired the present venerable pile of freedom, what security have we that his desolating torch will not be lighted, to consume the new temple which may be raised in its stead? If you expect to change the nature of man, if you expect to regenerate him, you may then destroy the causes which will effect the dissolution of a new Confederacy. Human nature never changes. "Arts fade,
                  States fall, but Nature never dies." The elements of destruction will operate in a Southern Confederacy with a more potential effect than in the present. In the new Confederacy the charm of the great federative principle, by which these States are bound together, will be dispelled. There will be other causes operating which will produce the same result.
                </p>
                <p className="p-in-sp">
                  It has been said, Mr. President, that free trade was not only one of the great elements which entered into the causes which have led to a dissolution and rupture of the present Confederacy, but that the question of re-opening the African slave trade was also a potential element in breaking up the present confederation. May not these same questions exist within the Southern
                  Confederacy? And say what you will, this question of re-opening the African slave trade will have a powerful effect in producing dissent and strife in their midst.
                </p>
                <p className="p-in-sp">
                  In the argument made by Mr. Yancey, at the Montgomery Convention, in Alabama, and to which I have already referred, that gentleman conclusively shows that the re-opening of the African slave trade is a necessity upon the part of the Southern people. As I have already shown to this Convention, he complained, that in the Southern States there was a scarcity of labor, and he denounced
                  that policy which compelled the Southern people to procure their negroes within the border States. He asked the question, why was it that the people of the South were forced to go to Virginia and purchase negroes for $1,500, when the same could be procured on the coasts of Congo and Guinea <PageNumber num={531} /> for $200? We know the fact that there
                  are many eminent, powerful and influential men at the South, who are combined together for the re-opening of the African slave trade. If it be the interest of the South to re-open the African slave trade, will they not repeal all those laws which inhibit it? It may be true, as was said by the gentleman from Harrison
                 [<span className="persName">Mr. CARLILE</span>] , that probably to no very great extent will the African slave trade ever be re-opened, for in the event of a dissolution of the Union, all the powers of the world would be combined more actively for its suppression ; therefore the importation of African slavery into the Southern States may meet with a check.</p>
                <p className="p-in-sp">But, Mr. President, you cannot stifle the promptings of interest. These questions will exist, will become elements of political consideration in the new Confederacy, and will operate with more power and potentiality in breaking up the Confederacy of the South than they have operated in destroying the present Confederacy.</p>
                <p className="p-in-sp">
                  But, Mr. President, it is said that the Southern States will not encourage the African slave trade, because it would be destructive of their interests. We are told that by an additional supply of African labor the value of slaves would be depreciated. What does the Southern planter care about a depreciation in the value of his negroes? He does not own the negro necessarily for the
                  purposes of sale, or as a vendable commodity. The chief value of the negro to him is for the production of cotton. Cheap negroes to him are desirable.
                </p>
                <p className="p-in-sp">Mr. Yancey, in the Montgomery Convention of 1858, sneeringly remarked, that a high price for negroes was a Virginia idea. The interest therefore of the planter was to produce as much cotton as possible, and his profits were the result more of the amount of cotton produced than the marketable price of the negro.</p>
                <p className="p-in-sp">But, Mr. President, we are told that if we do not enter into this Confederacy, if we do not link our destinies with the Gulf States, that the Gulf States will inhibit the States on the border from sending their slaves for sale into the cotton States.</p>
                <p className="p-in-sp">
                  Mr. President, can they do it? Dare they do it? Their own power, their own interest depends entirely upon the production of cotton. In order to compete successfully with the other cotton growing countries of the world, the price of cotton must be kept down to a certain minimum price. When it goes beyond that, the production of cotton is stimulated in the other cotton growing regions
                  of the world. They, therefore, cannot dispense with our labor—they are forced to have it. If they are cut off from the supply of labor from the border slave States, the production of cotton, which is the source of their prosperity <PageNumber num={532} /> and wealth, must necessarily decline, or else they come into competition with Algeria and other
                  cotton growing countries.
                </p>
                <p className="p-in-sp">
                  They boast, Mr. President, that Cotton is King, they tell us that his supremacy is so firmly established that it cannot be over borne : that his sway is so omnipotent that England, with all her antipathy to slavery, with all her instincts in behalf of freedom, must bow in submission before his power. How is this? Is this boast justified by the facts? Is it true that the cotton of the
                  Southern States is King? Are there any other countries in the world that can compete with the Southern States in the production of cotton? Why, sir, India produces already more cotton than the Southern States. The maximum product of the South in one year is four million five hundred thousand bales, and yet England consumes annually six millions of bales.<span className="note" id="Note62"
                  ><span className="noteLabel">2</span>See the Proceedings of March 9, page 528, for Mr. Brent's correction of this statement.</span
                  >
                  It is asked, why it is that England, with all her efforts, has not been enabled to build up rivals in India and elsewhere to the cotton production of the South? It results from this consideration alone, that whilst cotton can be produced in India -as it is in our own country, yet the cost of transportation from the cotton fields to the points whence it is to be carried to England, is
                  so great that it cannot be sent and sold to any advantage at the existing price.
                </p>
                <p className="p-in-sp">
                  But whenever cotton, grown in the Southern States, has risen to 12 or 12 IA cents a pound, you will find the growth of cotton in India will be extended and proper facilities for its transportation provided. In the year 1857, when cotton rose to 121/4 cents a pound, the looms of Great Britain manufactured fifty per cent of cotton not grown in the Gulf States of this country. You have
                  not only India product to compete with it, but it has been demonstrated that in Central America, in the Valley of the Magdalena, and in the Valley of the Amazon, there are rich cotton lands yet untouched ; and if the cotton Confederacy should interpose a law inhibiting the sale of negroes from the border States, the result would be that labor would be enhanced in value in the cotton
                  States, and cotton would necessarily cost more, and that India, Algeria, Central America and South America would come into competition with the cotton States in the production of cotton.
                </p>
                <p className="p-in-sp">
                  But, Mr. President, what are we to gain by immediate secession and by linking our destinies with the Southern States? We are told if we join the Cotton Confederacy we will become a great manufacturing interest within that Confederacy—that Virginia and the border States will occupy the same relations to the Southern States which now exist between the States of New England and the
                  States of the South. Why, Mr. President, how does this invitation to join the Southern Confederacy <PageNumber num={533} /> and these promises comport with the idea that France and England are ready to enter into treaties of alliance and commercial reciprocity with these Southern States? If they enter into these alliances with England, as has been
                  intimated by Southern papers, in the interests of the new Confederacy, what will become of the promised manufacturing industry and enterprise of Virginia and the other border States of which we hear so much? Has England ever been known to enter into an alliance with any nation of a commercial character, that she did not require an ample equivalent for the advantages which she may
                  bestow? If she purchases the cotton, will she not require the Southern States to buy in return its equivalent in manufactured goods? This has been the commercial system of England from the days of Cromwell down to the present time, and that system she has systematically pursued in all her commercial intercourse with other nations. It was the observance of this system, as was said the
                  other day by the gentleman from Bedford
                 [<span className="persName">Mr. GOGGIN</span>] 
                  , that prevented us, in our colonial condition, from making even a hob-nail. This would be very much the case with us in this new Confederacy. It is a remarkable fact, that where there is a large supply of the raw material, it is seldom turned into the manufactured state. How is it that in the Southern States the raw material has to be sent across the water, or to New England, to
                  convert it into fabrics? It is the manufacture of this material that gives to England the wealth which she possesses. This, sir, is a part and parcel of that commercial system upon which rests the greatness and the commercial glory and power of that country ; and when these gentlemen in the Southern States suppose that England will foster their interests, will form alliances and
                  connections with them, and that it is to be done at the expense of this policy, which she has always pursued, they are very much mistaken. For my part, I cannot see that there is any reason why Virginia should at once embark upon this unknown future of secession, and join in this Southern Confederacy.
                </p>
                <p className="p-in-sp">I desire to submit to the consideration of this Convention, a few observations in regard to the amendment under consideration, which was presented by the gentleman from Amelia [<span className="persName">Mr. HARVIE</span>] , as a substitute for the resolution offered by the gentleman from Goochland [<span className="persName">Mr. LEAKE</span>] . I desire to state the reason why I cannot vote for that amendment.</p>
                <p className="p-in-sp">
                  I consider that amendment, Mr. President, as an ordinance of secession in disguise. I consider it in effect, a declaration of war made by the Commonwealth of Virginia against the Federal Government. What is that amendment? It starts out with a "whereas," somewhat <PageNumber num={534} /> similar to the famous preamble with which the Congress of the
                  Unitea States declared the existence of war between this country and Mexico, "whereas war exists by the act of Mexico."
                </p>
                <p className="p-in-sp">In the substitute we have this language :</p>
                <p className="p-in-sp">
                  "Whereas, it is now plain that it is the purpose of the Chief Executive of the United States to plunge the country into civil war," &amp;c. It then goes on further, and assuming the fact of a declaration of hostilities on the part of the President against the South, asks from this Convention mandatory instructions to the Legislature to put this State in an attitude of defence, and to
                  employ the military power and arm of the State, not only to resist the holding, taking and possessing the places belonging to the government within the seceded States, but likewise to resist the employment of the power necessary to collect the revenue in these States.
                </p>
                <p className="p-in-sp">
                  I do not stand here, sir, to vindicate all the views which have been set forth in that Inaugural. I do not stand here in any sense his apologist. Educated, as I have ever been, in that school of political philosophy contained in the resolutions of '98-'99, and the report on their vindication by Mr. Madison, and regarding them as the true source of all sound political teaching, I
                  cannot for one moment sanction all the doctrines contained in the Inaugural of the President of the United States. I do not desire to defend any of those measures which he suggests; I do not desire to vindicate him or his policy; but I wish to examine the terms of that Inaugural so far as we may test the correctness of the declarations contained in the preamble and resolutions, that
                  he does intend to make war upon the States of the South.
                </p>
                <p className="p-in-sp">I do not belong to that class of individuals to whom the distinguished gentleman from Halifax [<span className="persName">Mr. FLOURNOY</span>] , referred the day before yesterday, who assumed the position that Lincoln did not mean what he said. On the contrary I wish to ascertain what he said and not what he wanted to say.</p>
                <p className="p-in-sp">What has he said? He has declared, as many of the Presidents before him—as I believe all have declared with the exception of Madison and Monroe—that a State had no right to secede from the Union. And arguing logically upon this assumption, he comes to the conclusion that no State, by her withdrawal from the Union, is legally and Constitutionally out of the Union.</p>
                <p className="p-in-sp">
                  Assuming, therefore, that a State is within the Union, he makes a declaration of a principle of a general character, the truth of which must necessarily be admitted by all, that it is his duty as President of the United States, to exercise the Constitutional powers entrusted to his <PageNumber num={535} /> keeping, and enforce the laws of the United
                  States enacted under the Constitution.
                </p>
                <p className="p-in-sp">But does he say that he means to enforce the law and exercise his Constitutional power in the States which have seceded? His declaration is, if I understand it correctly, that as far as practicable, he will enforce the laws within the States unless his masters, the sovereign people, shall otherwise direct.</p>
                <p className="p-in-sp">Now, what are the Constitutional powers of the President of the United States, and what are his duties under the law?</p>
                <p className="p-in-sp">
                  We, sir, who recognize the right of a State to secede, deny that he has any power within these States. We deny that he can, by virtue of any Federal authority, execute the laws of the United States within the seceded States, and therefore we advocate the propriety and the policy upon the part of the Administration to surrender to the seceded States the property within their limits,
                  and to abandon the idea of enforcing the revenue laws. But arguing from the positions assumed by Mr. Lincoln, that these States are still within the Union, and that he must execute the laws, how is he to execute them? Is he to execute them according to his own mere will and pleasure? or must he execute them in the mode and manner pointed out by the Constitution and the laws? He can
                  only do so according to law and the Constitution.
                </p>
                <p className="p-in-sp">
                  Now, can he execute these laws within a seceded State? These States, whether rightfully or wrongfully have seceded and have formed for themselves a Confederacy, which ought to be considered at the least, as a Government de facto. They have expelled from their limits every Federal officer. There is not a man, woman or child within their limits who recognizes allegiance to the
                  Government of the United States. They have established a Government with means and armies sufficient to maintain its independence not only against the Government of the United States, but against the world. How, then, can the laws of the United States be executed within the seceded States by the President?
                </p>
                <p className="p-in-sp">
                  The laws can only be executed by means of the civil authority. The power of the military can never be executed except as auxiliary to the civil power to which the Constitution makes it subordinate. Now all the powers of the President of the United States being exercised under the laws, I ask where is the authority according to the Constitution or any law of Congress, authorizing him
                  to execute the laws of the United States, within the limits of the Territories of the seceded States, in regard to the collection of the revenue? If Mr. Lincoln should assume the power, without authority of law, and in violation of the Constitution, <PageNumber num={536} /> and should attempt to collect tribute of the Southern States, would that be of
                  itself a sufficient ground to justify the State of Virginia to resort to immediate secession from this Union? I say not. If the President of the United States were to make an illegal, unauthorized and unconstitutional attempt to collect tribute in the seceded States, it would be promptly resisted and met by the Southern President, and by the confederated arms of the Southern States.
                  They have power sufficient to protect and defend themselves against any such attempt. But suppose that the Executive of the United States is sustained and supported by the Congress of the United States, or by the Northern people, in furnishing supplies, and aiding and co-operating with the President of the United States in any movement which he may set on foot for the enforcement of
                  what I regard as tribute upon the part of the Southern States? I would say, then, that every man within the limits of the slave States, whether they be the cotton or the border States, would be united in resisting such an unconstitutional and oppressive exercise of the power upon the part of the President of the United States.
                </p>
                <p className="p-in-sp">
                  As I have said already, the President has now no power to appoint any officer within the limits of the Southern Confederacy. The vacancies in the several offices in the seceded States having occurred during the session of Congress, no power is vested in the President of the United States under the Constitution, to fill any of them, so that there are no means now by which the
                  President can bring the army and navy into requisition to aid the civil officer to execute the laws in these States.
                </p>
                <p className="p-in-sp">
                  Let us test Lincoln by what he has said. He says he will only enforce the laws as far as practicable, unless his masters, the people, shall otherwise direct. What have the people, his masters, directed? Have they given him authority to enforce these laws within the limits of the seceded States? On the contrary, when the Congress of the United States was asked to confer this
                  extraordinary power upon the President of the United States, to call out the militia to aid in the execution of these laws, Congress, although the Black Republicans were then in a majority, gained by the withdrawal of the representatives of the six or seven seceded States, refused to confer upon the President any such authority.
                </p>
                <p className="p-in-sp">
                  The lower branch of Congress has gone still further. Although the Republicans were in a majority, they passed the resolutions which were reported by the Committee of Thirty-Three in regard to the Personal Liberty bills. These resolutions declared that these bills, <PageNumber num={537} /> passed by Northern State Legislatures, were, in their spirit and
                  tendency, unconstitutional, and requested other States to repeal them. Congress went even a step farther. We have been frequently told that slavery is in danger within the States. We find that Congress passed a resolution recommending an amendment to the Constitution of the United States, declaring it beyond the power of Congress to interfere with the institution of slavery within
                  any of the States of the Union, and then declaring that this amendment shall not be altered unless by unanimous consent of the slaveholding States. Notwithstanding that this was a Republican Congress, pledged to carry out all the principles of the Chicago platform, and having, as I said, a majority yet, did not attempt, in pursuance of their pledge, to repeal the laws in New Mexico,
                  establishing the status of slavery within its limits; nor did they, in the bill organizing the territories of Colorado, Nevada and Dacotah, incorporate the proviso inhibiting slavery north of 36 deg. 30 min., which was incorporated in other territorial bills, and which was so offensive to the South. Therefore, when you take this declaration of the President, in connection with' all
                  the surrounding circumstances, with the actual impossibility existing upon his part to make an attempt anywhere against the Southern States, you may well infer that the President did not mean that he intended to march an army into the Gulf States, or in any other way to coerce and subjugate them.
                </p>
                <p className="p-in-sp">
                  But, again, Mr. President, the latter part of this resolution pledges the State to resist any attempt to collect revenue by armed resistance, on the part of the Federal Government.<span className="note" id="Note63"
                  ><span className="noteLabel">3</span>The speaker must have meant to say "to resist, by armed resistance, any attempt on the part of the Federal Government to collect revenue."</span
                  >
                  And I say this is a declaration of war. It is an act of secession in disguise, because a collision between the State Government and the Federal Government would necessarily lead at once to a displacement of Virginia from her present position. I object to that part of it, for this reason : a resolution, under those circumstances, pledging the State of Virginia to resist the collection
                  of the revenue alone would put it in the power of Mr. Lincoln, by the exercise of a rash, arbitrary and unconstitutional power upon his part, to plunge the country into civil war. I am opposed to trusting such great power in the hands of any one man. If such acts of Mr. Lincoln shall be sustained by the Northern people, if they shall be sustained by the Congress of the United States,
                  the representatives of the people, then Virginia, with all the border States, will present one united and determined front against the exercise of any such power.
                </p>
                <p className="p-in-sp">
                  But, Mr. President, we are again requested to vote for the substitute to the resolution because the inaugural denies the binding validity of the judgments of the Supreme Court. I think this argument <PageNumber num={538} /> presented and urged in consequence of a misapprehension of Lincoln's inaugural address. It is charged that the President of the
                  United States in his inaugural address, has declared that the judgments of the Supreme Court of the United States have no binding validity upon their coordinate departments of government, or upon the people; and the idea seems to have gone abroad that the design of the President of the United States was to carry out the idea of Seward to re-construct the judiciary of the United
                  States, in order to place that tribunal in the power of Republicanism. If such had been the object of the President of the United States, would he not rather, on the contrary, have attempted to have imparted to the Supreme Court of the United States the sanctity of infallibility? If he designed to impose upon this country the principles of Republicanism, and has the power of placing
                  upon that bench men who represent his own opinions, would it not have been his policy to have upheld the doctrine, that the decisions of the Supreme Court were binding upon the country, and that that tribunal was the last and the final resort.
                </p>
                <p className="p-in-sp">
                  Mr. President, I will read a short extract from the Inaugural address of the President, and when you carefully examine its phraseology, you will find that the doctrine contained in the Inaugural, in that particular, is the same doctrine which has ever been maintained 1.3y the States Rights party of Virginia, from 1798 down to the present time; that it is not only sustained by the
                  Virginia resolutions of '98, the Kentucky resolutions, the report of Mr. Madison upon those resolutions, but is sustained by the opinion of those to whom the States Rights party has ever looked ; and is also sustained by the judgment of our own Court. The extract from the Inaugural, is as follows :
                </p>
                <p className="p-in-sp">
                  "I do not forget the position assumed by some, that constitutional questions are to be decided by the Supreme Court; nor do I deny that such decisions must be binding, in any case, upon the parties to a suit, as to the object of that suit, while they are also entitled to very high respect and consideration in all parallel cases by all other departments of the Government. And while it
                  is obviously possible that such decision may be erroneous in any given case, still the evil effect following it, being limited to that particular case, with the chance that it may be overruled, and never become a precedent for other cases, can better be borne than could the evils of a different practice. At the same time the candid citizen must confess, that if the policy of the
                  Government upon vital questions, affecting the whole people, is to be irrevocably fixed by decisions of the Supreme Court, the instant they are made in ordinary litigation between parties in personal actions, the people will <PageNumber num={539} /> have ceased to be their own rulers, having to that extent practically resigned their government into the
                  hands of that eminent tribunal."
                </p>
                <p className="p-in-sp">
                  Is not that the doctrine laid down in the resolutions of '98, which declared that each State has, for itself, the right to judge of the infraction of the constitutional compact, and the mode and measure of redress? When those resolutions were transmitted in 1798 to the Northern States for adoption, the response came back, that they recognized no such final interpretation by the
                  States, but that by the terms of the Constitution it was lodged in the Federal Judiciary; and the report of Mr. Madison maintained that if the Federal Judiciary was the final arbiter of the rights of the States, the Supreme Court of the United States would have the power, by its judgment, to bind the co-ordinate departments of the government and the people, and the ultimate effect of
                  which would be to concentrate in one department of the government, supreme and absolute power. When this subject came before the Supreme Court of the United States in a case from Rhode Island connected with Dorr's rebellion, it was asked, by one of the Judges, after referring to their unfitness to judge of political questions, what people, nurtured in the principles of Hampden and
                  Sydney, would ever consent to place their liberties in the hands of the Judiciary?
                </p>
                <p className="p-in-sp">
                  We were told the other day, that for his claim of absolute power, Charles I. was brought to the block. There is more significance in that allusion than at first appears. We find that that monarch was brought to the block in consequence of an effort, on his part, to impose doctrines on his people adverse to the liberty of the British subject which a venal judiciary, appointed by
                  himself, vindicated and upheld by their judgment. But we do not want to entrust to judicial power questions of such interest and magnitude.
                </p>
                <p className="p-in-sp">
                  I will refer you, Mr. President, to a short extract taken from a report made to the Senate of the United States by a distinguished Senator, a gentleman who once occupied the Gubernatorial Chair, and whose public and private life was adorned by every virtue. I refer to James Barbour, who, in his report on the case of Matthew Lyon, who, under the sedition law, was condemned to fine and
                  imprisonment, says :
                </p>
                <p className="p-in-sp">
                  "In times of violent party excitement, agitating a whole nation, to expect that judges will be entirely exempt from its influence, argues a profound ignorance of mankind. Although clothed with the ermine, they are still men, and carry into the judgment seat the passions and motives common to their kind. Their decisions, on party questions, reflect their individual opinions, which
                  frequently betray them unconsciously <PageNumber num={540} /> into error. To balance the judgment of a whole people, by that of two or three men, no matter what may be their official elevation, is to exalt the creature of the Constitution above its Creator, and to assail the foundation of our political fabric, which is, that the decision of the people
                  is infallible, from which there is no appeal, but to Heaven."
                </p>
                <p className="p-in-sp">The very same position was assumed by Mr. Jefferson in many of his letters—by President Jackson, in his message vetoing the Charter of the U. S. Bank, in which he denies that the Judiciary of the United States can bind the executive and legislative departments of the Government by their judgment.</p>
                <p className="p-in-sp">
                  Mr. President, what are the duty and the policy of Virginia under the existing circumstances? Virginia is warmly and ardently attached to the Union of these States. Not, however, sir, to a Union perverted and usurped, but to a Union framed in the spirit and object in which it was established and contemplated by our fathers. To such a Union, and to none other, will Virginia ever
                  cling, and none other will she ever cherish. The Union of these States was pre-eminently a Virginian conception. It was the conception of her wisdom and her patriotism. To the altar of Union, she brought, as a bride, her dowry, that munificent domain lying north-west of the Ohio river. Beneath the hallowed roof of Mount Vernon, was conceived the idea of a more perfect and a more
                  lasting Union of these States—when the Commissioners from Virginia and Maryland there assembled, for the purpose of entering into commercial stipulations in regard to the navigation of the Potomac and the Chesapeake, was conceived the idea of the Convention which met at Annapolis, and which finally led to the Convention of all the States which met in Philadelphia, by which the
                  Constitution and the Union were established. Virginia, for this Union, has sacrificed much, and she will sacrifice more, consistently with her honor. In these times of our national calamity, Virginia is called upon to rescue this Union from peril. It is her ancient privilege so to do. She rescued it when at its last gasp in the great political revolution of 1798; she saved it by her
                  peaceful mediation in 1833, and she rescued it in the compromise measures of 1850.
                </p>
                <p className="p-in-sp">
                  But Virginia has no sympathy with that doctrine, whether it comes from the North or from the South, that there exists an irrepressible conflict of opposing and enduring forces between the two sections, growing out of the diversities of soil, of climate, of productions, and of domestic institutions. On the contrary, she regards these diversities as links in the great chain of Union,
                  binding the States more closely <PageNumber num={541} /> together. But in order to form a perfect Union, each link must be preserved in all its original integrity. Under such circumstances, Virginia is called upon to discharge the duties of her ancient office. Her position is truly grand and sublime. The country looks on for some grand and magnanimous
                  experiment which will rescue it from strife and civil war. Let, then, Virginia, Mr. President, strive vigorously to remove all causes of discontent between the two sections of the country. Let her strive vigorously and earnestly to bring back the seceded States of this Union and re-construct it anew—to re-construct and bind together a Union in fact, in feeling and in interest. It
                  should be her duty to impress upon the people of the different States that noble lesson, that
                </p>
                <div className="lg">
                  <div className="l">No matter that at different shrines,</div>
                  <div className="l">They prayed unto one God;</div>
                  <div className="l">No matter that at different times</div>
                  <div className="l">Their fathers won the sod.</div>
                  <div className="l">In fortune and in fame they're bound</div>
                  <div className="l">In stronger links than steel,</div>
                  <div className="l">And neither can be safe or sound</div>
                  <div className="l">But in each other's weal.</div>
                </div>
                <p className="p-in-sp">
                  What then, Mr. President, should Virginia do? The duty of Virginia is to act in the present emergency with decision. Let her speak out. Let her demand a settlement of the issues pending between the two sections of the country, now and forever. The country demands that there shall be a final settlement of this question—a settlement at once, and, as I have said, forever. What
                  programme, then, or line of policy should Virginia pursue? Let her, as indicated in the resolutions of the gentleman from Chesterfield
                 [Mr. Cox] 
                  , call a conference of the border States. Let them determine upon such amendments to the Constitution as may be deemed necessary for the protection of the South. I care not whether they are the Crittenden amendments, or any other amendments equivalent to them. Let her propose them as the ultimatum upon which the settlement of the questions at issue between the two sections is to be
                  adjusted.
                </p>
                <p className="p-in-sp">It is due to the border States that we all should meet in convention. I would not rashly and precipitately rush out of this Union, or first undertake this settlement without the counsel of these border States. We have condemned the cotton States for their action. We should not imitate the same action on our part, but we should call them into council.</p>
                <p className="p-in-sp">
                  But, Mr. President, if all this shall fail, if the propositions thus <PageNumber num={542} /> tendered for a settlement of the pending issues, shall be rejected, what, then, will be the duty of this State? The duty of this State will be, to unite with the States of the South and such of the non-slaveholding States as shall agree with them. Not, however,
                  to withdraw from this Union, but with the intention of re-constructing it afresh; supersede the Government at Washington, take possession of the National Capitol, of the archives of Government, of the public domain, and of the ancient flag—the stars and stripes of the nation.
                </p>
                <p className="p-in-sp">
                  But, Mr. President, in the mean time Virginia should demand that the arm of Federal authority should be stayed, that no attempt should be made, on the part of the Federal Government, to coerce and subjugate the States that have seceded from the Union. Recognizing, as I have always done, the right of a State to secede, to judge of the violation of its rights, and to appeal to its own
                  mode for redress, I could not uphold the Federal Government in any attempt to coerce the seceded States to bring them back into the Union.
                </p>
                <p className="p-in-sp">
                  If then, Mr. President, these efforts on the part of Virginia for conciliation and harmony shall fail, if the Northern States shall reject the overtures of peace thus tendered, and shall attempt to inaugurate the policy indicated by the Chicago platform, if then, in the battle's wreck and midnight of storms which shall follow, this Union, the Rome of our hearts and affections, with
                  all the storied memories of its pictured past
                </p>
                <p className="p-in-sp">"Shall in Tiber melt,</p>
                <p className="p-in-sp">And the vast range of its wide Empire fall,"</p>
                <p className="p-in-sp">we shall be cheered by the consoling reflection, that we, at least, are innocent.</p>
                <p className="p-in-sp">Mr. President, one word more, in conclusion. My lot is cast with that of Virginia; come weal, come woe. Beneath her soil repose the remains of those who gave me existence, and of my children, and when my own journey of life shall have been run, my prayer will be that I too may rest in her bosom.</p>
                <div className="speaker" id="sp570"><span className="persName">Mr. AMBLER</span>, of Louisa—</div>
                <p className="p-in-sp">
                  I did hope that I would not feel it to be my duty to say one word during the deliberations of this Convention. I appear here as the representative of a people whose opinions have been expressed openly and without disguise, and I expected my only duty would have been to have recorded their wishes in the votes which I might be called upon to give. I knew that there would be assembled
                  around me those in whom Virginia trusted—those whose wisdom gave the people confidence in them—those whose experience would be a <PageNumber num={543} /> guarantee that the rights, honor and glory of Virginia would be protected at all times, and under all circumstances, I felt that here was a Convention of the Anglo-Saxon race—a race whose proud history
                  for 600 years has been but the reiteration of that noble stand taken on the plain of Runnymede, when it was announced that the rights of the people were invaded, and the answer came up with one universal shout, "these are our demands, here are our arms, choose you between acceding to the one, or taking the risk of the other."
                </p>
                <p className="p-in-sp">
                  I have heard repeated, time and time again, that our position here is an anomaly. Do those who say that, mean to tear from the pages of history your monuments of glory, and your title of deeds of fame? An anomaly, sir, that the Anglo-Saxon race meets to demand their rights, and say that they mean to maintain them! I have listened and listened—let me say it with all respect—with utter
                  surprise at the course which the gentleman has taken here. You have assembled here by the voice of the people, who are agonized because their rights are in danger. You are called together for the purpose of maintaining that right, which has been canvassed in the heart of every American, that a free people can only be governed by their consent; and when that great question is before
                  you, and when the long echoes that roll down for seventy odd years of your history tell you that that cry was uttered when the former foundations of your liberty were raised, we have been regaled, day after day, with talk about the protection of the negro. A stranger, who knew not the objects which you really had in view, and who only judged by the debates of this Convention, would
                  suppose that you were gathered together to protect negro property, and negro property alone. The great principle which lies at the very foundation of free Government, is what is really involved here. We have had essay after essay, quotation after quotation, extract after extract, read here by gentlemen—they will excuse me the expression—who have been mousing about old newspapers, to
                  prove what one gentleman has said at one time, and what another gentleman has said at another time ; what a newspaper editor may have said upon one occasion, and what he may have said upon another occasion and in the meanwhile the great question, which in all time heretofore, has aroused and fired the hearts of our race, and the maintenance of which has given them the proud position
                  they occupy, is completely ignored or forgotten.
                </p>
                <p className="p-in-sp">
                  Sir, such things do not happen from mere accident. I ask, in all sincerity, why this blinking of this question? Why attempt to deceive yourselves and those who trust in you, by discussing side issues, and by misleading the public mind. Can it be possible, sir, that we must write <PageNumber num={544} /> it down hereafter, that the instincts of our race
                  have been lost? that the proud memories of the past are to be forgotten, and that we, who ought to hold in our hands this single question and allow no cloud to be raised about it, but present it fairly and fully before our people, and give them our decision upon it fairly and squarely, will fail to meet the issue required at our hands? I put the question, sir, and I do it in sadness
                  and not in anger : what have we done? Business throughout the land is prostrate. Your people are in a feverish, unsettled and dissatisfied condition. They have looked for the trumpet tone which ought to have come from the Virginia Convention, assembled to deliberate upon the preservation of the liberties of Virginia. And what has been the policy that you have adopted? It is the
                  policy of an individual whose actions and sayings have been set forth so graphically by Mr. Dickens. I refer to Mr. Micawber. You have been asking us, time and time again, to "wait for something to turn up"
                 [laughter] 
                  and whenever you have asked us to wait for something to turn up, Barkis has always replied, "Barkis is willin'." But what has turned up? You have told my people that we must wait ; as an act of duty, as an act of right we must wait for your Peace Conference. Your Peace Conference has acted, and what did they do? Have you had from them that clear, open, manly and honest avowal of
                  Southern rights which you had a right to expect?
                </p>
                <p className="p-in-sp">
                  It comes upon my ear like a wail of anguish, when I hear an intimation, broadly given out here, that the men from the mountains did not have the community of feeling and community of interest within which<span className="note" id="Note64"><span className="noteLabel">4</span>The Enquirer has "within which."</span> I believe they possessed. Men of the West—if I am mistaken, if it be your hand
                  which is to drag down our destinies, and which is to extinguish the fires upon our altars—come, I beseech you—come, like your own mountain blasts when the storm King, in his wrath, sends his messengers forth to execute his will—come like your own mountain torrents, fierce, resistless and destructive—come, not in the guise of the Jesuit, to aid in destroying the last hope of those who
                  are gathered around the same family altar with yourselves. Remember, I beseech you, in the long list of sacrifices which Virginia has made uncomplainingly and unrepiningly, that the hands which offered these sacrifices, were the hands of your Eastern brethren ; that the hearts that prompted these sacrifices, beat in Eastern bosoms, and that the glory of this proud Commonwealth has
                  not, at least, been tarnished by Eastern hands. Go back to that period when Virginia with the view of making an offering to the insatiable demands of the North, and gathering the support of the North, for the purpose of opening that <PageNumber num={545} /> great artery of the West, the Mississippi, by interesting Northern men and Northern settlers in
                  that magnificent domain which she tendered to the Union—I mean the North-western Territory—and it was an Eastern head that conceived the propriety and duty of the sacrifice, and it was an Eastern hand that executed the deed. Remember, I beseech you, that when it was necessary to make another sacrifice, it was under the lead of the same men and dictated by the same noble spirit.
                </p>
                <p className="p-in-sp">
                  Remember again, when for the third time it was necessary to approach the altar with another sacrifice, in order to perpetuate the Union, and to maintain the brotherhood which should exist between the States, that the High Priest was an Eastern man. And for the fourth time we were called upon to make a sacrifice, when in 1850 you forced upon us a compromise which, at the time, we told
                  you was as hollow as it was deceitful—a compromise which we told you had in it the seeds of the present state of things—a compromise which I insist resulted in forcing upon you this great question which is now before you; but you would not heed us, and you forced us to acquiesce in that compromise. Then, throughout Virginia, particularly in Eastern Virginia, men occupying the humble
                  position that I do, were forced to consider this question in the privacy and quiet of their own homes.
                </p>
                <p className="p-in-sp">
                  We were obliged to consider the fearful question forced upon us. Then we did consider this question of secession. Then the ties which bound our hearts with as strong and enduring bands as yours could be bound to the Union, were torn one by one by your own hands, and we were forced calmly, deliberately, and conclusively, to the conclusion that the only remedy to which we could look
                  for a redress of our grievances, was secession, and secession alone.
                </p>
                <p className="p-in-sp">
                  We are met every day, and at all times, in every argument, with this appeal : that we ought not to be precipitate ; do not be in a hurry ; let us wait, with Micawber, until something turns up. After the Peace Conference, we did wait; and what was it that turned up then? Sir, from the midst of fire and bayonets, surrounded by a servile crew, waiting his behests, the man who embodies
                  the sentiments, and is to execute the will of the oppressor, declares that where tribute could be collected, there power could be used. And this, sir, in the teeth of a Virginia Convention! Did he stop there? Was not that sufficient? Was it necessary to add more to it? Aye, sir, it was, and he did not hesitate to do it. But why attempt such insult to this people, whom he threatens to
                  subjugate? He did not recommend it in so many words ; but he has told you in that inaugural, if that clause means anything, that you are to prepare yourselves for seeing whoever Massachusetts <PageNumber num={546} /> chooses to, clothed with citizenship. He means to use the same power which is to exact tribute from you, to force you to recognize as your
                  equal your own slave who may have reached Massachusetts and been clothed with her own meretricious garments of freedom.
                </p>
                <p className="p-in-sp">It was a useless insult, sir, but he felt, and he felt truly, that a race who could receive unmoved, a demand for tribute, would shirk under any insult that he chooses to bestow upon them.</p>
                <p className="p-in-sp">
                  Mr. President, let me apologise here, sir, and once for all, if the language that I use may seem harsh, for I tell you, sir, that in my inmost heart I feel most deeply upon this question, I assure you that alone, "out of the fullness of my heart, my mouth speaketh." But for my duty which I owe to the people that I represent, I would not have taken one moment of your time, and if I
                  could have induced any friend to have presented the position I occupy, I should have been silent. Believe me, sir, when I tell you, if I seem excited, that I am a calm deliberate man among my people.
                </p>
                <p><span className="persName">Mr. AMBLER</span> having yielded the floor for that purpose,</p>
                <p><span className="persName">Mr. HARVIE</span> moved an adjournment, which was agreed to.</p>
              </div>
            </div>
            <div className="day" id="vsc1965.v1.2.21">
              <PageNumber num={547} />
              <h2><span className="headingNumber">1.21. </span><span className="head">TWENTY-FIRST DAY</span></h2>
              <div className="dateline">Saturday, <span className="date">March 9</span></div>
              <div className="speaker" id="sp571"><span className="persName">Mr. GOGGIN</span>, when the hour of 12 o'clock arrived, took the Chair and said :</div>
              <p className="p-in-sp">Gentlemen of the Convention, at the request of the President I call the Convention to order.</p>
              <p>Prayer by the Rev. W. HOGE, of the Presbyterian church.</p>
              <div className="section" id="vsc1965.v1.2.21.1">
                <h3><span className="headingNumber">1.21.1. </span><span className="head">THE QUESTION BEFORE THE CONVENTION</span></h3>
                <div className="speaker" id="sp572"><span className="persName">Mr. GOGGIN</span>[who occupied the Chair]—</div>
                <p className="p-in-sp">Before the Chair recognizes any gentleman, he will state the question. The subject under consideration when the Convention adjourned on yesterday was, the amendment offered by the gentleman from Amelia [<span className="persName">Mr. HARVIE</span>] , to the amendment offered by the gentleman from Goochland [<span className="persName">Mr. LEAKE</span>] , to the original resolution submitted by the gentleman from Chesterfield [<span className="persName">Mr. COX</span>] .</p>
              </div>
              <div className="section" id="vsc1965.v1.2.21.2">
                <h3><span className="headingNumber">1.21.2. </span><span className="head">REPORT FROM THE COMMITTEE ON FEDERAL RELATIONS</span></h3>
                <div className="speaker" id="sp573"><span className="persName">Mr. R. Y. CONRAD</span>—</div>
                <p className="p-in-sp">The Committee on Federal Relations, to whom has been referred a large number of resolutions, have had the same under consideration and have directed me to make a partial report.</p>
                <p className="p-in-sp">
                  I will add, sir, that certain proposed amendments to the Constitution of the United States, referred to in this report, are now under consideration before the committee, and as soon as the same can be properly considered and decided upon, will be reported to the Convention. But the committee has deemed it proper to submit first this report, embodying various results, to which they
                  have come after mature consideration, and have instructed me to move that it be laid upon the table and printed.
                </p>
                <p>The motion was put and agreed to.</p>
              </div>
              <div className="section" id="vsc1965.v1.2.21.3">
                <h3><span className="headingNumber">1.21.3. </span><span className="head">MAJORITY REPORT OF THE COMMITTEE ON FEDERAL RELATIONS</span></h3>
                <p>The following is the report submitted by <span className="persName">Mr. CONRAD</span>:</p>
                <div className="floatingText">
                  <div className="floatingText_body">
                    <p>Partial Report from the Committee on Federal Relations, presented March 9, 1861.</p>
                    <p>The representatives of the people of Virginia in Convention assembled, are profoundly sensible of the difficulty, delicacy and importance of the duty which, in obedience to the popular will, they have assumed to perform.</p>
                    <p>They feel that the controversy which unfortunately distracts and <PageNumber num={548} /> divides our country has brought about a condition of public affairs for which history has no parallel and the experience of Governments no precedent.</p>
                    <p>They recognize the fact that the great questions which press for consideration are of entire novelty and of great intrinsic difficulty, and that their proper solution will require on the part of our Governments, State and Federal, and of our people the exercise of the utmost prudence, discretion, calmness and forbearance.</p>
                    <p>Above all other things at this time they esteem it of indispensable necessity to maintain the peace of the country, and to avoid everything calculated or tending to produce collision and bloodshed.</p>
                    <p>
                      The grievances for which several of the States have withdrawn from the Union and overthrown the Federal Government within their limits, are such as have affected the people of Virginia to a greater extent than any of the seceded States, and it is their determined purpose to require such guarantees for the protection of the rights of the people of the slaveholding States as in the
                      judgment of Virginia will be sufficient for the accomplishment of that object.
                    </p>
                    <p>Virginia having initiated measures to obtain such guarantees, a proper self-respect impels her to demand of all the parties that they shall refrain, during the pendency of her efforts for amicable adjustment, from all action tending to produce a collision of forces; therefore,</p>
                    <ul>
                      <li className="item">
                        1. Be it resolved and declared by the people of the State of Virginia in Convention assembled, That the States which composed the United States of America, when the Federal Constitution was formed, were independent sovereignties, and in adopting that instrument the people of each State agreed to associate with the people of the other States, upon a footing of exact equality. It
                        is the duty therefore, of the common Government to respect the rights of the States and the equality of the people thereof, and within the just limits of the Constitution, to protect with equal care, the great interests that spring from the institutions of each.
                      </li>
                      <li className="item">
                        2. African slavery is a vital part of the social system of the States wherein it exists, and as that form of servitude existed when the Union was formed, and the jurisdiction of the several States over it within their respective limits, was recognized by the Constitution, any interference to its prejudice by the federal authority, or by the authorities of the other States, or
                        by the people thereof, is in derogation from plain right, contrary to the Constitution, offensive and dangerous.
                      </li>
                      <li className="item">
                        3. The choice of functionaries of a common government established for the common good, for the reason that they entertain opinions and <PageNumber num={549} /> avow purposes hostile to the institutions of some of the States, necessarily excludes the people of one section from participation in the administration of the Government, subjects the
                        weaker to the domination of the stronger section, leads to abuse, and is incompatible with the safety of those whose interests are imperilled; the formation, therefore, of geographical or sectional parties in respect to federal politics is contrary to the principles on which our system rests, and tends to its overthrow.
                      </li>
                      <li className="item">
                        4. The Territories of the United States constitute a trust to be administered by the General Government, for the common benefit of the people of the United States, and any policy in respect to such Territories, calculated to confer greater benefits on the people of one part of the United States than on the people of another part, is contrary to equality, and prejudicial to the
                        rights of some for whose equal benefit the trust was created. If the equal admission of slave labor and free labor into any Territory excites unfriendly conflict between the systems, a fair partition of the Territories ought to be made between them, and each system ought to be protected within the limits assigned to it, by the laws necessary for its proper development.
                      </li>
                      <li className="item">
                        5. The sites of the federal forts, arsenals, &amp;c., within the limits of the States of this Union, were acquired by the Federal Government, and jurisdiction over them ceded by the States, as trusts, for the common purposes of the Union, during its continuance; and upon the separation of the States such jurisdiction reverts of right to the States, respectively, by which the
                        jurisdiction was ceded. Whilst a State remains in the Union, the legitimate use of such forts, &amp;c., is to protect the country against foreign force, and to aid in suppressing domestic insurrection. To use, or prepare them to be used to intimidate a State, or constrain its free action, is a perversion of the purposes for which they were obtained ; they were not intended to
                        be used against the States in whose limits they are found, in the event of civil war. In a time of profound peace with foreign nations, such as now exists, and when no symptoms of domestic insurrection appear—but whilst irritating questions, of the deepest importance, are pending between the States—to accumulate within the limits of a State interested in such questions, an
                        unusual amount of troops and munitions of war, not required for any legitimate purpose, is unwise, impolitic and offensive.
                      </li>
                      <li className="item">
                        6. Deeply deploring the present distracted condition of the country, and lamenting the wrongs that have impelled some of the States to cast off obedience to the Federal Government, but sensible of the blessings of Union, and impressed with its importance to the peace, prosperity <PageNumber num={550} /> and progress of the people, we indulge the
                        hope, that an adjustment may be reached by which the Union may be preserved in its integrity, and peace, prosperity and fraternal feelings be restored throughout the land.
                      </li>
                      <li className="item">
                        7. To remove the existing causes of complaint much may be accomplished by the Federal and State Governments; the laws for the rendition of fugitives from labor and of fugitives from justice may be made more effectual, the expenditures of the Government may be reduced within more moderate limits and the abuses that have entered into the administrative departments reformed. The
                        State authorities may repeal their unfriendly and unconstitutional legislation, and substitute in its stead such as becomes the comity and is due to the rights of the States of the same Union. But to restore the Union and preserve confidence, the Federal Constitution should be amended in those particulars wherein experience has exhibited defects and discovered approaches
                        dangerous to the institutions of some of the States.
                      </li>
                      <li className="item">
                        8. The people of Virginia recognize the American principle that government is founded in the consent of the governed, and they concede the right of the people of the several States of this Union, for just causes, to withdraw from their association under the Federal Government with the people of the other States, and to erect new governments for their better security, and they
                        will never consent that the federal power, which is in part their power, shall be exerted for the purpose of subjugating the people of such States to the federal authority.
                      </li>
                      <li className="item">
                        9. The exercise of this right by the States of South Carolina, Georgia, Florida, Mississippi, Alabama, Louisiana and Texas, without the assent of the other States, has given rise to new conditions, and presented questions touching those conditions intimately affecting the rights and safety of the other States. Among these are the free navigation of the Mississippi river, the
                        maintenance of the forts intended to protect the commerce of the Gulf of Mexico, and the power to restrain smuggling along the interior borders of the seceded States; but the federal authorities, under the Constitution as it is, disclaim power to recognize the withdrawal of any State from the Union, and consequently to deal with these questions, holding that it is reserved only
                        to the States as parties to the government compact to take lawful action touching them.
                      </li>
                      <li className="item">
                        10. Without expressing an opinion as to the question of power, but in deference to the opinion of the federal authorities, the people of Virginia hereby declare their desire to confer upon the Government of the United States, the powers necessary to enable its proper authorities <PageNumber num={551} /> to deal peaceably with these questions, and,
                        if it shall become necessary, to recognize the separate independence of the seceding States, and to make such treaties with them, and to pass such laws as the separation may make proper.
                      </li>
                      <li className="item">
                        11. This Convention, composed of delegates elected by the people in districts, for the purpose of considering the existing difficulties in our federal relations, represents the desire and earnest request of the people of Virginia, to meet as directly as possible the people of her sister States, and to them appeal for satisfactory adjustment. Virginia, therefore, requests the
                        people of the several States, either by popular vote, or in Conventions similar to her own, to respond, at their earliest convenience, to the positions assumed in the foregoing resolutions, and the proposed amendments to the Constitution of the United States hereunto appended. And in the event that this Commonwealth fails to obtain satisfactory responses to her requests, from
                        the non-slaveholding States, she will feel compelled to resume the powers granted by her under the Constitution of the United States, and to throw herself upon her reserved rights.
                      </li>
                      <li className="item">
                        12. The people of Virginia will await any reasonable time to obtain answers from the other States, to these propositions, aware of the embarrassments that may produce delay, but they will expect, as an indispensable condition, that a pacific policy shall be adopted towards the seceded States, and that no attempt be made to subject them to the federal authority, nor to reinforce
                        the forts now in possession of the military forces of the United States, or recapture the forts, arsenals or other property of the United States within their limits, nor to exact the payment of imposts upon their commerce ; nor any measure resorted to, justly calculated to provoke hostile collision.
                      </li>
                      <li className="item">
                        13. In the opinion of this Convention, the people of Virginia would regard any action of the Federal Government, tending to produce a collision of forces, pending negotiations for the adjustment of existing difficulties, as aggressive and injurious to the interests and offensive to the honor of this Commonwealth ; and they would regard any such action on the part of the seceded
                        or confederated States as hurtful and unfriendly, and as leaving them free to determine their future policy.
                      </li>
                      <li className="item">
                        14. The peculiar relations of the States of Delaware, Maryland, Virginia, North Carolina, Tennessee, Kentucky, Missouri and Arkansas to the other States, make it proper, in the judgment of this Convention, that the former States should consult together and concert such measures for their final action as the honor, the interests and the safety
                        <PageNumber num={552} /> of the people thereof may demand, and for that purpose the proper authorities of those States are requested to appoint commissioners to meet commissioners to be appointed by this Convention on behalf of the people of this State, at Frankfort, in the State of Kentucky, on the last Monday in May next.
                      </li>
                    </ul>
                  </div>
                </div>
                <p>PERSONAL EXPLANATION</p>
                <div className="speaker" id="sp574"><span className="persName">Mr. BRENT</span>—</div>
                <p className="p-in-sp">I desire to make a correction in the report of the remarks which I made here on yesterday.</p>
                <p className="p-in-sp">
                  I am represented in the report in the "Enquirer" this morning, as stating that England consumed six millions of bales of cotton annually, when my remark was that India produced six millions of bales annually. There are other inaccuracies which shall be corrected in the revised remarks which I shall publish.<span className="note" id="Note65"
                  ><span className="noteLabel">1</span>The "revised remarks" which Mr. Brent proposed to publish have not been found.</span
                  >
                </p>
              </div>
              <div className="section" id="vsc1965.v1.2.21.4">
                <h3><span className="headingNumber">1.21.4. </span><span className="head">THE FREDERICKSBURG RESOLUTIONS</span></h3>
                <div className="speaker" id="sp575"><span className="persName">Mr. MARYE</span>—</div>
                <p className="p-in-sp">Mr. President : With the permission of the gentleman from Louisa [<span className="persName">Mr. AMBLER</span>, who was entitled to the floor] 
                  , I will take occasion to refer to certain resolutions recently adopted at a meeting of a portion of my constituents, and which partake of the character of instructions. I shall bring these resolutions to the consideration of the Convention, that they may be made part of the proceedings of this body. I will read them myself, as I am familiar with the hand-writing.
                </p>
                <p>
                  At a full meeting of the citizens of Fredericksburg, held at the town-hall, called to consider the present aspect of public affairs, Dr. Wm. S. Scott was called to the chair, and James B. Sener appointed secretary. The following Committee were appointed to report resolutions to the meeting, viz : Dr. H. T. Minor, Elliott M. Braxton, Geo. H. C. Rowe, William S. Barton and John L.
                  Marye, Jr. Whereupon said Committee brought in the following preamble and resolutions :
                </p>
                <p>
                  "Whereas, the President of the United States has declared it to be the deliberate purpose of his administration to ignore the government established in the seven States which have withdrawn from the Federal Union, and has announced. his determination to enforce the authority and supremacy of the Federal Government over said States, by requiring them to pay tribute and to submit to
                  such other acts of coercion, as the resources put at his command may enable him to enforce. Therefore, resolved,
                </p>
                <p>"1st. That in the conflict which must ensue between the Federal Government and the said States of the South, upon the attempt made to enforce any such claim of jurisdiction, Virginia should rally all her <PageNumber num={553} /> resources to aid the seceded States in resisting the Federal Government and in maintaining their independence.</p>
                <p>
                  "2d. That in our opinion the Virginia Convention should no longer occupy its time, or spend its energies in making overtures to the Northern States to grant to Virginia Constitutional guarantees of her rights, but we deem it to be the obvious duty of that body in view of all the necessities and exigences which invest the condition of Virginia, to devote its efforts, without delay, to
                  the work of setting on foot arrangements adequate to the establishment of a new government, in which shall be embraced and united all the slaveholding States of the South.
                </p>
                <p>
                  "3d. That if the Convention shall deem it the most efficacious mode of securing the establishment of a Confederate Government of all the slaveholding States, to advise the immediate withdrawal of Virginia from the Federal Union, this community will cordially approve and sustain that action ; our desire being to sever, as soon as possible, our connection with the present Federal
                  Union, and to secure a Union of all the slaveholding States.
                </p>
                <p>"4th. That our delegate in said Convention, is requested to present to that body the proceedings of this meeting, it being in substance a petition to that body, and that he is desired to use his efforts to effectuate these views in said Convention."</p>
                <p>Whereupon, after addresses from Messrs. Wm. S. Gilman, Charles Herndon, J. Horace Lacy, Geo. H. C. Rowe, William S. Barton, Elliott M. Braxton and John L. Marye, Jr., the resolutions were adopted with but one dissenting voice in a meeting of three hundred and fifty citizens.</p>
                <p>WM. S. SCOTT, Chairman</p>
                <p>JAMES B. SENER, Secretary</p>
                <p>The following Committee were appointed by the chair to convey the within resolution to their delegate in Richmond, viz :</p>
                <p>Messrs. Seth B. French, E. M. Braxton, Jno. M. Wallace, E. A. Marye, Jas. B. Sener, Wm. S. Gilman, Robert S. Chew.</p>
                <div className="speaker" id="sp576"><span className="persName">Mr. MARYE</span>—</div>
                <p className="p-in-sp">
                  If the gentleman from Louisa will further indulge me, I will submit a few remarks in explanation of these resolutions. They emanate from that portion of my constituents who reside in the town of Fredericksburg. Those who adopted them, cannot be presumed to have viewed the grave matters therein referred to, in the precise aspect in which they present themselves to this body. We are
                  here, sir, acting our part, as best we may, in the shifting scenes of this disastrous drama. If I should take a determinate step in the direction which these resolutions seem to contemplate, would I, or not, sir, act with just the same <PageNumber num={554} /> lights before me, and under the identical circumstances which surrounded these constituents of
                  mine from whom the resolutions which I am adverting, proceeded? I am one of those, sir, who value, and have ever valued, as beyond the measure of any price, save that of honor, the perpetuity of the Union. I was elected to this assembly, having never been engaged in political life—elected, contrary to my personal preference in the premises, but because of the known and distinctly
                  enunciated fact that I did so value the Union, and could never consent to surrender the ties which bound this State to it, until all honorable efforts for the conservation of our rights within the Union should fail; it was, however, my opinion when elected, as it is my opinion now, that no adjustment of the pending difficulties should be accepted, which was not based upon such
                  permanent constitutional guaranties as would compose, for all time to come, that sectional agitation which has for years past disturbed the public mind and imperilled the public liberties. But those who sent me here agreed to commit to this Convention, with which I feel it to be an honor to be connected, an unrestricted discretion in respect to the method by which such an adjustment
                  might be consummated.
                </p>
                <p className="p-in-sp">
                  I am of a sanguine disposition, sir; very sanguine; but, if I have been too hopeful as respects the perpetuity of the Union, it is because it has always seemed to me that the value of the stake, which the Northern States held in the integrity of this confederacy was so great as to have induced them to yield those guaranties, without which the Union cannot continue.
                </p>
                <p className="p-in-sp">
                  Sir, according to my humble apprehension, much of the trouble in which we are now involved, may be referred to the violent and inflammatory resolutions—I do not call it legislation—heretofore enacted by the Legislatures of our own and other Southern States, in regard to this slavery controversy. Such treatment of the subject has misled the Northern people touching the temper of the
                  Southern mind upon this question, and has created the belief with them that our whole resistance to the wrongs we have endured would expend itself in high-sounding resolutions. But when troubles seemed to have culminated in the election of the present incumbent of the Executive Chair, and when this election furnished ground to believe that unless the Northern mind could, in some way,
                  be disabused of its prejudices upon this question, the constitutional rights of the South were endangered ; when, I say, sir, this took place, the Southern or Cotton States did not resort to mere resolves, but to action, as exhibited in the secession of six—now seven—of these States, from the Federal Union. The question <PageNumber num={555} /> is thus
                  presented in a form calculated to reach the Northern mind, if it can be reached at all; and unless that mind is under the dominion of prejudice and folly to an extent that I cannot comprehend, it did appear to me impossible that when one-half of the States from which the North mainly derived its resources, had taken this stand, and when the question was presented, whether the North
                  would guarantee to us protection to our property and our political rights, or would drive off the remaining moiety of Southern States by her persistence in this theory of an irrepressible conflict between the systems of labor prevailing in the respective sections—it did appear to me impossible, I repeat, that when the question was thus presented, the North would refuse to accord to
                  us the full measure of our just demands. Holding these views, sir, I came to this body for the declared purpose of inaugurating, as far as I could, the agency by which to bring about this adjustment of our difficulties within the Union. But in the event of a failure to procure such settlement, no alternative seemed to me, to be left but secession—not, sir, as a right under the
                  Constitution, but a right paramount to the Constitution—secession which presented itself not as a right of which we might avail ourselves, but as a duty to which we should resort in resistance of oppression. My purpose was, if this duty should be forced upon us, to advocate a union with our sister States who had, for like causes, sundered their connection with the Federal Government.
                </p>
                <p className="p-in-sp">
                  When the inaugural came, sir, I felt that the elements upon which my hope of an adjustment had reposed, were in a great degree diminished. But still, sir, I thought it might be that this Executive, who had been elected when a very different state of things prevailed, from those which now obtain, was lulled into the belief that there would be submission on the part of the remaining
                  slaveholding States. It struck me that the true policy for us to pursue was to put an end to this delusion in the most peremptory manner possible. I thought that a single day should not pass after the reception of this inaugural, without such action as is indicated in the resolutions which I shall presently read to the Convention. But not anticipating that the proceedings of this
                  body would take the direction which they have since assumed, I found myself voting to continue over the session to another day, because of actual inability on my part to apprehend the precise import of the question submitted to the Convention. I will therefore read, now, the resolutions which I prepared, as an expression of my views, and which I designed submitting to the Convention
                  the day after the reception of the inaugural, in the hope that prompt action thereon might serve <PageNumber num={556} /> to dispel the delusion under which the Executive labored.
                </p>
                <p className="p-in-sp">The first of these resolutions is as follows :</p>
                <p className="p-in-sp">"Whereas, Abraham Lincoln has announced in his inaugural address that he regards the Union as still unbroken, and that he feels constrained, by the obligations of his office, to assert the jurisdiction of the Federal Government within the seceded States.</p>
                <p className="p-in-sp">"1st. Be it resolved, by this Convention, That Virginia will resist any attempt by the Federal arm to coerce these States, and that she will regard the collection of revenue and the re-capture of forts, within the limits of such States, as coercive measures."</p>
                <p className="p-in-sp">But, sir, I did not mean that this declaration should be as the idle wind. I desired that it should be followed by such measures as would bring home the conviction to the Executive, that we were prepared as well to act as to talk. I therefore prepared this second resolution :</p>
                <p className="p-in-sp">"2nd. Resolved, That the Legislature of Virginia is hereby instructed to adopt such measures as will, in the most effectual manner, enable the State to repel all coercive movements of the Federal Government against the seceded States."</p>
                <p className="p-in-sp">These resolutions, I prepared, not for the purpose of having them referred to the Committee, to be considered in common with others, (because resolutions of a similar import had already been before them) ; but I wished these enacted at once as the voice of this Convention.</p>
                <p className="p-in-sp">Accordingly, I prepared this third resolution :</p>
                <p className="p-in-sp">"3. Resolved, That the foregoing resolutions be referred to the Committee on Federal Relations with instructions to report the same without delay to this body."</p>
                <p className="p-in-sp">
                  I wished this to be the answer of Virginia to the purpose I saw declared in that inaugural. When the gentleman—I do not know his locality, I forget at this moment the county from which he comes, and I may be guilty of a violation of the rules in alluding to a gentleman by name; I refer to <span className="persName">Mr. FLOURNOY</span>-made his speech; it was so much better than anything
                  I could have said that I declined submitting what I had proposed to say.
                </p>
                <p className="p-in-sp">
                  When I prepared these resolutions, I confess I was in impatient haste to submit them to the Convention, in the hope that members would be willing, without dissent, to adopt a declaration of resistance to coercion; for whilst I have been here knowing hardly a dozen individuals of those who are associated with me upon this floor, I have been pained to perceive symptoms of party feeling
                  here—allusions to localities—reference made to supposed conflict of sentiment and of interest between Eastern and Western Virginia. <PageNumber num={557} /> I know of no party here—no appellation save that of a Virginia patriot. All minor considerations must give way, and I have no doubt will be made subservient to the single one of the State's honor
                  and interest. I feel assured that all will unite, with one voice, in the adoption of such measures as will best promote the honor and interest of Virginia out of the Union, if it shall appear that these may not be maintained within it.
                </p>
                <p className="p-in-sp">
                  There was another matter which deterred me, to some extent, from introducing the last of the resolutions which I have just read. I heard from time to time that we were likely very soon to have the result of the deliberations of the Committee upon Federal Relations; a Committee selected from the best ability and experience of this body. I had this expectation, day after day increasing
                  in intensity as time went on, that we should have the result of their labors; and, sir, I have such confidence in the members who constitute the Committee, that, if left to myself, I should pause long before I could be brought to support any policy which would conflict with their advised action, knowing as I do that their report will present the results of their mature deliberations,
                  aided by the suggestions and advisement of the Convention, given to them in the shape of the numerous resolutions, which, under the rules, have been referred to that Committee. Their report, if what leaks out is to be relied on, cannot be delayed longer than to-day. It will probably be here within the day.
                </p>
                <div className="speaker" id="sp577"><span className="persName">A MEMBER</span>—</div>
                <p className="p-in-sp">It is already presented.</p>
                <div className="speaker" id="sp578"><span className="persName">Mr. MARYE</span>—</div>
                <p className="p-in-sp">
                  I was not aware of it. I am very much pleased to hear it. The main value of the resolutions I have read, which were in accordance with many that had gone to the Committee, was the concluding resolution by which we are to have the prompt action of this body upon the isolated question of arresting this doctrine of threatened violence, and I wished to record my vote upon that
                  resolution. I had hoped that when a vote came from this body upon that matter, it would be a unanimous vote. I had seen with pride that such had been the action taken by our Legislature, when this Convention was inaugurated.
                </p>
                <p className="p-in-sp">
                  I have deemed it necessary, therefore, to say through this channel, to my constituents—that portion of them who consider me rather as a slow coach, and who seek to stimulate my action by the resolutions which have been sent to the Chair—that I have not changed in any manner, from the ground upon which my commission was issued—and I shall not change, unless it is in obedience to the
                  behests of those who sent me. For although from what I have seen, through a tolerably long life, of the manner in which instructions are gotten up, if I should <PageNumber num={558} /> measure their claim to consideration from the benefits which have arisen from the exercise of this power on the part of the sovereigns, they would be utterly impotent to
                  affect my action; yet, sir, it can not be denied that the right to instruct rests upon the capacity of the people for self-government. I would not, because of its abuse in practice, abandon the theory itself ; and therefore I mean to announce here now, that I bow with implicit obedience to these instructions, because I have the most satisfactory assurances that they were properly
                  gotten up and truthfully represent the sentiments of my constituents.
                </p>
                <p className="p-in-sp">
                  Upon a hasty perusal of these instructions, I was inclined to condemn them in part, one of them particularly. But upon a second consideration, I think I may accord to them my most hearty assent. They do not, as I read them, take from me the power of co-operating with all who are laboring to secure a community of interest, action and destiny, between Virginia and the other
                  slaveholding States.
                </p>
                <p className="p-in-sp">
                  Sir, I believe that this is the only true policy for our Convention to pursue, and I shall take occasion at a future period, unless other duties prevent me, to address the Convention upon that subject. For the present, I shall content myself with submitting a declaration of purpose to abide by the instructions, and with a further declaration that I shall take occasion to make a
                  statement to my constitutents that they had better send no more instructions.
                </p>
              </div>
              <div className="section" id="vsc1965.v1.2.21.5">
                <h3><span className="headingNumber">1.21.5. </span><span className="head">REPORT BY GOV. WISE</span></h3>
                <div className="speaker" id="sp579"><span className="persName">Mr. WISE</span>—</div>
                <p className="p-in-sp">With the assent of the gentleman from Louisa [<span className="persName">Mr. AMBLER</span>] , I rise to a privileged question, that of making a report from the Committee on Federal Relations, or rather a minority report. I ask leave to present my dissenting report in the form of a minority report, and which I now beg leave to read.</p>
                <div className="floatingText">
                  <div className="floatingText_body">
                    <p>The undersigned begs leave to assign the reasons for his dissent from the Report of the Committee on Federal Relations, and presents the following as a substitute for the plan of measures recommended thereby to be adopted by the Convention :</p>
                    <p>Substitute offered by <span className="persName">Mr. WISE</span> to the Preamble and Resolutions of <span className="persName">Mr. SCOTT</span>, as amended, in the Committee on Federal Relations, Friday, March 8, 1861:</p>
                    <p>
                      This Convention, called by the people of the Commonwealth of Virginia, to deliberate upon the present exigencies of their federal relations, and upon the redress of their wrongs and grievances in the Confederacy of the United States, deems it necessary and proper, with a <PageNumber num={559} /> view to preserve peace, to defend the Federal
                      Constitution, and to restore and perpetuate the Federal Union of all the States, on a basis of just and equal rights, to declare :
                    </p>
                    <p>
                      That for a long series of years the property of the citizens of the slave-holding States, and particularly that of her own citizens, has been assailed and endangered; that the Constitution of the United States has been broken; that the rights and comities of States and their equality in the Union have been denied to the people of the slaveholding States; that the federal laws
                      have been nullified in respect to the protection of their property in slaves; that the separate and independent right of self-government by the border slaveholding States has been seriously impaired, and, in part, practically annulled ; that their domestic tranquility and social safety have been endangered and ruthlessly disturbed by actual invasion ; that associated and
                      systematic efforts have been constantly and persistently made to enforce upon their people rules of conscience and of morals by a power without their borders, to control the family governments of their homes and their relations as masters to their domestic slaves; that their character as a people has been maligned and misrepresented to the world, in order to bring an influence to
                      bear upon their rights and relations and their wills, rendering them odious and no less offensive and injurious to' their sense of self respect and to their interests than the force of arms; that the sanctity of the federal judiciary has been threatened and set at naught, in order to destroy the only peaceful guard and guarantee of their rights of property and federal equality ;
                      and that a sectional hate which engendered these evils is continually magnifying them by every form and effort of incendiarism, until they are no longer endurable; until the people actuated by it have obtained the reins of federal authority and control in all the departments of government ; and until several of the sovereignties, parties to the federal compact, have been
                      compelled to resume the powers granted by them under the independent confederacy, thereby dissolving the Union of the United States of America.
                    </p>
                    <p>These wrongs have been perpetrated in part by the Federal Government, either by acts of omission or commission, in part by the nonslaveholding States, and in part by their people, unrestrained by laws, such as confederates are bound to enact in respect to the rights and safety of each other.</p>
                    <p>
                      And the secession, consequent upon these wrongs, is now met by every indication of an intention and an attempt to coerce the submission of seceding States, by the authorities of the Federal Government, who are but the mere agents of the sovereign parties to the federal compact, <PageNumber num={560} /> without even an appeal to them for the sanction
                      of any ultimate resort to force.
                    </p>
                    <p>Thus, under the pretext of enforcing laws of the Federal Government, the jurisdiction of which is now denied and abjured by the seceding States, the nation is imminently threatened by an unnatural and unnecessary civil war; equally unnecessary, whether the Union is to be finally dissolved or restored.</p>
                    <p>
                      These indications are made but too plainly manifest by the failure of the Conference, inaugurated by the Legislature of this Commonwealth herself, to agree on any terms of adjustment; by the disclaimer of all power on the part of the federal authorities to negotiate for peace with the commissioners of the seceded States ; by the inaugural address of the incumbent President of the
                      United States, declaring the policy, powers and purposes of his administration of the Federal Government, and supposed to represent the sentiments of large majorities of the States constituting the major section of the United States; by his failure to suggest any mode, whilst disclaiming all powers of adjustment; by the failure of Congress to recognize the results of the Peace
                      Conference, or to recommend any other plan of peace ; and by the concentration of Federal troops at various points, and the reinforcing and holding of certain forts and arsenals, with the obvious intent and purpose of overcoming any resistance to the execution of federal laws by the seceded States, and to overawe the further secession and free action of the slave-holding States.
                    </p>
                    <p>Under these circumstances of peril to every thing precious to a State, this Commonwealth feels compelled to appeal to her confederates still remaining in the Union, and to ask for their determinate conclusions on the following points of difference and dissension, as to which she is bound to demand, and seeks to obtain satisfactory guarantees and assurances for the future :</p>
                    <p>1. As to a full recognition of the rights of property in African slaves.</p>
                    <p>2. As to slavery in the District of Columbia.</p>
                    <p>3. As to the powers of the Federal Government over African slavery, and the employment of slave labor in the forts, arsenals, dock yards, and all places ceded by the States for federal uses.</p>
                    <p>4. As to protection against the pretension to lay and collect excessive direct taxes on slaves.</p>
                    <p>5. As to the rendition of fugitive slaves.</p>
                    <p>
                      6. As to protection of the right and comity of transit with slaves through the limits of the States, by land or water; and of the right of transportation of slaves on the high seas. <PageNumber num={561} /> 7. The protection of the right of citizens of the United States, owning slaves to sojourn temporarily with their slaves in waiting, in the
                      limits of non-slaveholding States.
                    </p>
                    <p>8. The protection of equality of settlement by owners of slaves, with their slave property, in the common territories of the United States.</p>
                    <p>9. As to the rights of negroes or free persons of the African race to all the privileges and immunities of citizens of the several States.</p>
                    <p>10. As to the equality of the African race with the white race in the States where it may reside, and the protection of that equality by State laws, and by the laws of the United States.</p>
                    <p>11. As to the better security of the independence of the Judicial Department of the Government of the United States, by changing the mode of appointing the Federal Judges.</p>
                    <p>12. As to the protection of the slaveholding States against the abduction of their slaves, by repealing such State or Federal laws as may countenance the wrong, or by passing such laws by the States and by the Federal Government as may be necessary and proper to suppress it.</p>
                    <p>13. As to the protection of the domestic tranquility of the people of the United States, by suppressing the incendiary assemblages, associations and publications which have engendered the sectional wrongs and hatred which have rent the Union asunder and now threaten a civil war.</p>
                    <p>14. The protection of the public peace by suppressing societies and individual efforts for the collection of money and other means to invade the States or territories of the United States.</p>
                    <p>15. And by suppressing all organizations seeking and introducing foreign aid and influence, to incite domestic violence in any of the States or Territories of the United States.</p>
                    <p>
                      Upon these points, and any others which may arise requiring them, this Commonwealth needs and ought to demand additional assurances and guarantees to those now existing; and those assurances and guarantees can, on the main points of dissension and severance, only be made sure by obtaining, not merely Constitutional amendments, or the pledges of States by resolves or otherwise,
                      but by grants of power to check abuses or wrongs by a majority of the States.
                    </p>
                    <p>
                      And with a view of adjusting these points and obtaining these guarantees, guarded by the necessary and proper checks and balances of power, it is recommended that this Convention shall appeal to the States still remaining in the Union, to give, at as early a day as practicable, their answers to those demands of this Commonwealth, say within the period of the present year and by
                      the 1st day of October next, if possible. <PageNumber num={562} /> In the mean time it is recommended that every step be taken to preserve the peace of the country.
                    </p>
                    <p>
                      That to that end neither the Federal Government nor the seceded States shall commence hostilities; that the States now in the Union should confer with this State upon a mode of sanctioning the claim of the right of peaceable secession, and of determining all questions arising thereupon, such as the free navigation of the Mississippi river, the maintenance of forts and arsenals,
                      and the settlement of commercial and postal regulations, &amp;c., &amp;c. And the Federal authorities should avoid all acts whatever tending to cause or to irritate the causes of civil war, by abstaining from the execution of all laws which may require the force of arms against the seceded States; by withdrawing all occupation of their forts, arsenals, dock yards and other places
                      ceded; and by reducing the military forces at the forts, arsenals, magazines, dock yards, &amp;c., within the limits and around and about the borders of the slave-holding States still remaining in the Union, to mere garrisons, for the purpose of guarding and preserving the public property; pending the efforts of this Commonwealth to adjust pending issues, to obtain guarantees, to
                      preserve peace, and to restore the amity and Union, if possible, of all the States.
                    </p>
                    <p>
                      And it is further recommended to adopt an ordinance that this Commonwealth shall be immediately placed in a full and complete state of military organization for defence; and it shall be immediately submitted to the people to determine whether, if the just demands of this Commonwealth are not satisfactorily responded to, or are not responded to at all by her Confederates, or civil
                      war commencing on the part of the Federal Government, within the period named, pending her efforts of adjustment, they will or will not resume the powers granted by them under the Constitution of the United States, and that this Convention shall place itself immediately in communication with the border slave-holding States for conference and co-operation.
                    </p>
                    <p>To these ends, therefore, be it resolved—</p>
                    <p>1. That the foregoing points for adjustment or for declaration of purpose on the part of the States now in the Union, be immediately addressed to them.</p>
                    <p>2. That additional guarantees or assurances shall be demanded on the more important of these points, in the forms of checks and balances of power, to be defined by amendments to the Constitution of the United States.</p>
                    <p>3. That responses to these demands from the respective States shall <PageNumber num={563} /> be requested within a fixed and limited period, to wit : on or before the 1st day of October next, if possible.</p>
                    <p>
                      4. That, in the mean time, it be recommended to the people of this Commonwealth, in the event the federal authorities shall, under any pretext whatever, attempt to enforce their claim of jurisdiction over the people of the seceded States, as by collecting the duties for revenue or diverting the transit or entrance of commerce, or in any other mode, by force of arms, to resist
                      such exertion of force by all the means in their power.
                    </p>
                    <p>
                      5. That the federal authorities be requested to withdraw all occupation of the places ceded in the seceded States, and to reduce the forces at Fortress Monroe and Harper's Ferry, at Fort Washington and Fort McHenry, in Maryland, to garrisons on guard duty ; and that the guns which have been lately mounted landwards, be removed and all other preparations for war in the limits of
                      this Commonwealth, or on her border be at once stopped ; protesting that these forces and warlike preparations are irritating causes of civil war and dangerous to the people.
                    </p>
                    <p>6. That the Legislature of this Commonwealth be recommended and urged to make ample appropriations to place her people in a complete state of military defence.</p>
                    <p>7. That this Convention will place itself in immediate communication with the border and other slaveholding States still remaining in the Union, for conference and co-operation, whilst awaiting the responses of the other States to these requests and demands.</p>
                    <p>
                      8. That an ordinance be adopted at once, submitting to the people of this Commonwealth to determine whether, if their just demands are not satisfactorily responded to by the non-slaveholding States, or are not responded to at all by them, or if civil war shall commence on the part of the Federal authorities, within the period named pending the efforts of this Commonwealth for
                      adjustment, they will or will not resume the powers granted by them under the Constitution of the United States ; and will or will not unite their destiny with that of the seceded slave-holding States of the South, embracing in any new Union to be formed such non-slaveholding States as will manifest a disposition and determination to respect and maintain the equal rights of all
                      the States and their people.
                    </p>
                    <p>All which is respectfully submitted, with the request to be permitted to assign hereafter the objections to the report entertained by the minority of the Committee, several of whom are now absent.</p>
                    <p>HENRY A. WISE.</p>
                  </div>
                </div>
                <PageNumber num={564} />
                <p>On motion of <span className="persName">Mr. WISE</span>, the report was laid on the table and ordered to be printed.</p>
                <div className="speaker" id="sp580"><span className="persName">Mr. HARVIE</span>—</div>
                <p className="p-in-sp">With the consent of my friend from Louisa [<span className="persName">Mr. AMBLER</span>] , I will submit a minority report from the Committee on Federal Relations, and will ask that it be laid upon the table and printed.</p>
                <p>I concur with many portions of the report submitted by the gentleman who has just taken his seat [Gov. WISE] , and I concur with the request to be allowed, with him, hereafter, an opportunity of expressing the objections that I entertain to the majority report, differ from it, as I do, in many essential particulars.</p>
                <p>I now beg leave to submit the following report :</p>
                <div className="floatingText">
                  <div className="floatingText_body">
                    <p>The undersigned, a minority of the Committee on Federal Relations, report that, having had under consideration the resolution referred to the said Committee, and dissenting from the report of the majority, recommend to the Convention the adoption of the following resolution :</p>
                    <p>Resolved, That the Committee on Federal Relations be instructed to report an ordinance resuming the powers delegated by Virginia to the Federal Government, and to make provision for submitting the same to the qualified voters of the Commonwealth for their adoption or rejection.</p>
                    <p>Respectfully submitted,</p>
                    <p>LEWIS E. HARVIE, ROBT. L. MONTAGUE, SAM'L C. WILLIAMS.</p>
                  </div>
                </div>
                <p>PERSONAL EXPLANATION</p>
                <div className="speaker" id="sp581"><span className="persName">Mr. JOHNSON</span>, of Richmond—</div>
                <p className="p-in-sp">Mr. President, by leave of the gentleman from Louisa [<span className="persName">Mr. AMBLER</span>] , and I trust by the permission of this Convention, I desire to say a few words of a personal character.</p>
                <p className="p-in-sp">It is the first time that I have risen on this floor for any such purpose; and I trust, sir, it will be the last. I fear that the few remarks which I will make, will not be appreciated by the Convention, because it may be difficult for them to place themselves in, and to understand the position which I occupy.</p>
                <p className="p-in-sp">
                  I am the humblest of the representatives of the people of Richmond on this floor. Sir, you may not know, and perhaps do not know, and this Convention may not know, and perhaps the members do not know, that it has been industriously circulated, and I say, maliciously circulated, in the streets of the city of Richmond, that I, three days ago, made a Black Republican and a coercion
                  speech. I say that members may be surprised to hear this; but associating, as I do, with the people <PageNumber num={565} /> of Richmond, I have heard it frequently, not a little, either, to my mortification and to my indignation. The report, Mr. President, is circulated and predicated upon the fact that three days ago I made a speech in reply to the
                  gentleman from Halifax
                 [<span className="persName">Mr. FLOURNOY</span>] , the gentleman from Prince Edward [<span className="persName">Mr. THORNTON</span>] , and my friend from Bedford [<span className="persName">Mr. GOODE</span>] .</p>
                <p className="p-in-sp">
                  The circumstances in which that speech was made will be well remembered by the members of this Convention. It was when there was an effort made by gentlemen who did not agree with me in regard to the particular subject under consideration, to press a vote upon the Convention upon the question of coercion and upon other questions, when the Committee then had under consideration these
                  very identical subjects. Then, sir, I presented my views in opposition to any immediate action upon this question, and I have reason to believe that the Committee concurred with me in sentiment, inasmuch as up to this time no action had been taken by them.
                </p>
                <p className="p-in-sp">Now, sir, the remarks which I made then, were made in reply to the very beautiful arguments of the gentleman from Halifax [<span className="persName">Mr. FLOURNOY</span>] , the gentleman from Prince Edward [<span className="persName">Mr. THORNTON</span>] , and the gentleman from Bedford [Mr. GOODE] . I see but one of these gentlemen in his seat to-day, and I will appeal to him now, if I said one single word, if I uttered one single sentiment, that could intelligibly and truly be construed into an advocacy or toleration of Black Republicanism, or into any sort of toleration of the doctrine of coercion, and I appeal to my friend now to answer.</p>
                <div className="speaker" id="sp582"><span className="persName">Mr. THORNTON</span> [in his seat]—</div>
                <p className="p-in-sp">You did not.</p>
                <div className="speaker" id="sp583"><span className="persName">Mr. JOHNSON</span> [resuming]—</div>
                <p className="p-in-sp">I trust the gentlemen of the Convention will bear with me a moment longer. I tell them now that there is an effort here to put me down. Why, I do not know. What I have done, I do not know. What I have said, I do not know. In what I have offended, I have been unable to get mortal man to tell me.</p>
                <p className="p-in-sp">Now, sir, if I understand, if I appreciate the honor of this Convention, I will ascribe to no member of it, a sentiment which I am sure is foreign to all, and I will say that the lowest, meanest and most vulgar of all means to bring a man down is by wanton detraction and unmitigated calumny.</p>
                <p className="p-in-sp">
                  And these reports have been circulated in Richmond about me with as much audacity as they would have been with veracity, if I had been guilty of them. And I, therefore, think it right, sir, cherishing some regard for my own character and my own reputation, to say what I said last night, "that if any gentleman supposes I have been guilty of the
                  <PageNumber num={566} /> entertainment of any such sentiment, he is mistaken, and if any one asserts, as a fact, that I uttered any such sentiment, he is a mendacious liar."
                </p>
                <p className="p-in-sp">
                  Sir, I feel somewhat authorized to trespass a moment upon the patience of this Convention, because I have no interest at stake which the people of Richmond have not. My affections are here ; my home is here; my friends are here; my family is here. The little which I have is here, and I expect my last remains will be interred here—and, so help me God, I do not mean to be intimidated
                  by any idle threats, or be driven by the mendacious falsehoods of anybody. I had rather be a victim of this persecution; I had rather be torn and severed, joint by joint, and limb by limb, than to lay a destructive hand upon the altar of my country.
                </p>
                <div className="speaker" id="sp584"><span className="persName">The PRESIDENT</span>, pro tem—</div>
                <p className="p-in-sp">The question before the Convention is the amendment offered by the gentleman from Amelia [<span className="persName">Mr. HARVIE</span>] , to the amendment offered by the gentleman from Goochland [<span className="persName">Mr. LEAKE</span>] , to the proposition offered by the gentleman from Chesterfield [Mr. Cox] ; and upon this the gentleman from Louisa has the floor.</p>
                <div className="speaker" id="sp585"><span className="persName">Mr. WM. M. AMBLER</span>, of Louisa—</div>
                <p className="p-in-sp">
                  Mr. President, I regret that my physical condition will not enable me, at this time, to carry out the intention which I had of presenting my views before this Convention. The failure of my voice prevents me from doing more than to put myself right before the Convention, in explaining language which I used yesterday, and which I find may be capable of a construction very different—far
                  different, sir, from that which I intended. I find, sir, that the language which I addressed to my friends from the West may be construed into an implication upon the West. Sir, my object was rather to pay a compliment to the West—to let them know that I felt what was noble and true and high in their nature—that I, in the name of Eastern brethren, repelled the implication that there
                  was strife between us, and a difference of interest, and that they would act upon that strife, and upon that difference. I meant to acknowledge their manliness, and to appeal to their manliness to stand by us in our difficulties; to let them know that we relied upon them, that we had faith in them, and that we meant to acknowledge it.
                </p>
                <p className="p-in-sp">
                  I find, too, sir, that under the language I had used, it might be supposed that I meant to say that we were forced, by the action of the West, to acquiesce in the compromise of 1850. I do not believe it possible, sir, that any such construction could be given to the language I used. I meant to address what I said upon that subject to those gentlemen who occupied, in 1850, the precise
                  position which gentlemen who now, <PageNumber num={567} /> in 1861, are endeavoring to occupy, in forcing us to wait for such terms as our Northern masters propose to dictate to us. It was upon those gentlemen that I sought to throw the responsibility of forcing that compromise upon us, and of appealing to them to look to the fruit growing from the seed
                  that they had sown.
                </p>
                <p className="p-in-sp">
                  I hope, sir, that I have made myself understood in those two particulars, and that the misconstruction which might be put upon my language as used yesterday, will be removed from the minds of those gentlemen who have given it that construction. My voice has failed me utterly. I am not able to carry on what in the shape of argument I had proposed to submit to the Convention. The
                  condition of things is altered now. We have now a report from the Committee of twenty-one. Gentlemen may be more familiar with that report than I am, may know what is contained in it. It may be that some measure is contemplated by some gentleman in connection with that report; and in order not to detain the Convention I will yield the floor, with my thanks for the kind attention
                  which was paid me yesterday, and with the regret that I have not been able to do anything to show how much I felt the kindness which had been extended to me.
                </p>
              </div>
              <div className="section" id="vsc1965.v1.2.21.6">
                <h3><span className="headingNumber">1.21.6. </span><span className="head">REPORT FROM MR. JAMES BARBOUR</span></h3>
                <div className="speaker" id="sp586"><span className="persName">Mr. JAMES BARBOUR</span>, of Culpeper—</div>
                <p className="p-in-sp">
                  Mr. President, I was necessarily detained from the Committee on Federal Relations this morning, previous to the presentation of their report. Not being aware that they intended to report to-day, I was out of the room. I now desire, sir, to present, as the indication of my views upon the question, the following resolutions, which I wish to offer as my individual report :
                </p>
                <div className="floatingText">
                  <div className="floatingText_body">
                    <p>
                      Resolved, That this Convention has witnessed with deep concern the failure on the part of the authorities of the Federal Government, and a majority of the non-slaveholding State Governments, to co-operate efficiently with the authorities of this Commonwealth in an earnest effort to restore the Federal Union on terms consistent with the security of the people of the slave-holding
                      States.
                    </p>
                    <p>
                      Resolved, That the recommendations of this Commonwealth having been met by no sufficient response on the part of the authorities of the non-slaveholding States and the Federal Government, considerations of public duty induce us to refrain from further recommendations. If the authorities of the Federal Government and of the non-slaveholding State Governments desire and expect to
                      restore the Federal Union, they must, without further delay, adopt such measures as will afford to the people of the slaveholding States full constitutional assurance of their <PageNumber num={568} /> safety in continuing any further association with them under a common Government.
                    </p>
                    <p>Resolved, That this Convention will, on the day after the adoption of these resolutions, appoint three Commissioners, to proceed to Montgomery and confer with the authorities of the Confederated States, in reference to the present grave emergency in our public affairs.</p>
                  </div>
                </div>
                <div className="speaker" id="sp587"><span className="persName">Mr. BARBOUR</span>—</div>
                <p className="p-in-sp">I present those resolutions as a minority report.</p>
                <div className="speaker" id="sp588"><span className="persName">The PRESIDENT</span>, pro tem—</div>
                <p className="p-in-sp">What disposition does the gentleman wish to make of the resolutions?</p>
                <div className="speaker" id="sp589"><span className="persName">Mr. BARBOUR</span>—</div>
                <p className="p-in-sp">I desire them to take the same direction as the other reports.</p>
                <div className="speaker" id="sp590"><span className="persName">The PRESIDENT</span>, pro tem—</div>
                <p className="p-in-sp">The motion is that the minority report of the gentleman from Culpeper be laid on the table and ordered to be printed. If there is no objection it will be so referred.</p>
                <p>No objection having been offered, the paper took that course.</p>
                <div className="speaker" id="sp591"><span className="persName">Mr. COX</span>, of Chesterfield—</div>
                <p className="p-in-sp">
                  Mr. President, I have not seen the report of the Committee on Federal Relations that has been read here, and which has been ordered to be printed. But I understand that both propositions embraced in my resolution are already reported upon in that report, which we will have before us on Monday; and that being the case, I move to lay my resolution and the pending amendments to it, on
                  the table.
                </p>
                <p>The motion was carried; and, on motion of <span className="persName">Mr. PATRICK</span>, the Convention adjourned.</p>
              </div>
            </div>
            <div className="day" id="vsc1965.v1.2.22">
              <PageNumber num={569} />
              <h2><span className="headingNumber">1.22. </span><span className="head">TWENTY-SECOND DAY</span></h2>
              <div className="dateline">Monday, <span className="date">March 11</span></div>
              <p>The Convention met pursuant to adjournment.</p>
              <p>Prayer by the Rev. <span className="persName">Mr. PETERKIN</span>, of the Episcopal Church.</p>
              <div className="section" id="vsc1965.v1.2.22.1">
                <h3><span className="headingNumber">1.22.1. </span><span className="head">PERSONAL EXPLANATION</span></h3>
                <div className="speaker" id="sp592"><span className="persName">Mr. BROWN</span>—</div>
                <p className="p-in-sp">I desire to correct the report in relation to the few remarks which I made upon Thursday last. I am reported as saying that in the city of Richmond, out of 32 or 33,000 persons, there are 1,770 who cannot pay their taxes. I said that in the city of Richmond out of 3,595 white persons assessed with taxes, there are 1,770 returned delinquent.</p>
              </div>
              <div className="section" id="vsc1965.v1.2.22.2">
                <h3><span className="headingNumber">1.22.2. </span><span className="head">THANKS TO SENATOR CRITTENDEN</span></h3>
                <div className="speaker" id="sp593"><span className="persName">Mr. McGREW</span>—</div>
                <p className="p-in-sp">I propose for the consideration of this Convention a resolution which I ask may be reported by the Secretary, and adopted by this Convention, believing as I do, that it is eminently proper, and that it will be responded to by the people of Virginia, if not of the whole United States.</p>
                <p>The resolution was then read, as follows, by the Clerk :</p>
                <p>"Resolved, That the thanks of the people of Virginia be, and they are hereby most cordially tendered to the Hon. JOHN J. CRITTENDEN, for his recent able, zealous and patriotic efforts in the Senate of the United States to bring about a just and honorable adjustment of our national difficulties."</p>
                <div className="speaker" id="sp594"><span className="persName">Mr. WISE</span>—</div>
                <p className="p-in-sp">I move to lay the resolution on the table.</p>
                <div className="speaker" id="sp595"><span className="persName">Mr. BROWN</span>—</div>
                <p className="p-in-sp">Upon that motion I demand the yeas and nays. The yeas and nays were ordered.</p>
                <p>The question was then taken with the following result—yeas 23, nays 93 :</p>
                <p>YEAS---Messrs. Blakey, Boisseau, Borst, Bouldin, Conn, Fisher, Graham, Hun-ton, Isbell, Leake, Macfarland, Miller, Morton, Orrick, Randolph, Seawell, Slaughter, Speed, Strange, Thornton, Robert H. Turner, Wise, WOODS-23.</p>
                <p>NAYS-Messrs. John Janney [President] 
                  , Aston, Baldwin, A. M. Barbour, J. Barbour, Baylor, Berlin, Blow, Jr., Boggess, Branch, Brent, Brown, Bruce, Burdett, Burley, Caperton, Carter, Chapman, Clemens, Coffman, C. B. Conrad, R. Y. Conrad, Couch, Critcher, Custis, Dent, Deskins, Dulany, Early, Echols, Forbes, Fugate, Garland, Gillespie, Grant, Gravely, Gray, Goggin, Addison Hall, E. B. Hall, Hammond, Haymond, Hoge,
                  Holladay, Hughes, Hull, Jackson, <PageNumber num={570} /> M. Johnson, Peter C. Johnston, Lawson, Lewis, McComas, McGrew, McNeil, J. B. Mallory, Marshall, Marr, Marye, Sr., Maslin, Masters, Moffett, MOORE, Nelson, Osburn, Parks, Patrick, Pendleton, Porter, Preston, Price, Pugh, Rives, Saunders, Jr., R. E. Scott, Sharp, Sheffey, Sitlington, Southall,
                  Spurlock, Staples, A. H. H. Stuart, C. J. Stuart, Summers, Sutherlin, Tarr, Tayloe, Tyler, Waller, Whitfield, Wickham, Willey, Wilson, Wysor-93.
                </p>
                <p>So the Convention refused to lay the resolution on the table. The question then recurred on the adoption of the resolution.</p>
                <div className="speaker" id="sp596"><span className="persName">Mr. WISE</span>—</div>
                <p className="p-in-sp">I would inquire under what order that resolution was entertained. Did it come up as a matter of order itself?</p>
                <div className="speaker" id="sp597"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">There was nothing upon the table, and the table being entirely clear, the Chair considered it perfectly in order.</p>
                <div className="speaker" id="sp598"><span className="persName">Mr. WISE</span>—</div>
                <p className="p-in-sp">
                  I would merely remark that I object to entertaining that proposition, not thereby intending to cast any reflections upon the object of its gratitude, nor to withhold from <span className="persName">Mr. CRITTENDEN</span> any thing that is his due; but because in the first place I do not see any pre-eminence of patriotic exertions in his case above that of many other men who have been
                  exerting themselves in behalf of an adjustment. But that is a minor objection which I could have well deferred. My main objection is, that the resolution to give thanks to Mr. Crittenden, brings up by indirection before this body, what ought to be brought up directly-- the merits of his proposition of adjustment. I am opposed to forestalling other plans of adjustment which are in
                  competition with his.
                </p>
                <p className="p-in-sp">I do not mean to discuss the merits of his plan. I do not mean to draw forth either the merits of the man or of the measure. Certainly I do not intend its merits to disclose, nor would I now drag its faults from their dread abode ; but I do protest against this indirect form of deciding upon the merits of that plan without coming to any result.</p>
                <p className="p-in-sp">
                  This Convention has too much other important business to perform, to waste a moment of its time upon mere panegyric. You propose to thank Mr. Crittenden for his exertions. What exertions? The permanent and characteristic exertions of the man is his measure of compromise?<span className="note" id="Note66"
                  ><span className="noteLabel">1</span>This question, or sentence, sounds as if it had been garbled.</span
                  >
                  And when you thank him for his exertions; do you mean to adopt and approve that plan? Is that measure to be brought before this Convention in that form? Is it worthy of the deliberation of this Convention to present issues indirectly, which are to end in nothing?
                </p>
                <div className="speaker" id="sp599"><span className="persName">Mr. BROWN</span>—</div>
                <p className="p-in-sp">I would not have said a word but for the remarks of the gentleman from Princess Anne [<span className="persName">Mr. WISE</span>] 
                  . I do not understand that the resolution contemplates endorsing the Crittenden resolutions, but that it does intend to endorse the patriotic efforts made by that distinguished Senator in yielding his own proposition for a better and <PageNumber num={571} /> more acceptable one. It was his strong disposition to settle our national difficulties that I
                  desire we shall recognise by the passage of this resolution.
                </p>
                <div className="speaker" id="sp600"><span className="persName">Mr. LEAKE</span>—</div>
                <p className="p-in-sp">
                  As I understand the resolution, it would commit us to an approval of the course of Mr. Crittenden in sanctioning the results of the Peace Conference. While I am willing to do anything to approve of the patriotic spirit of this distinguished Senator in the Congress of the United States, I am not willing to give a vote by which it will appear that we approve the recommendation which he
                  made in the Senate, of the results of the Peace Conference.
                </p>
                <p className="p-in-sp">
                  I offer to amend the resolution, by adding this : "but this Convention does not mean hereby to sanction the measure proposed by the Peace Congress." It has been suggested to me and I do not want to get any test vote either, one way or the other at this stage of the proceedings—to change my amendment so as to say that we "neither approve nor disapprove the Crittenden amendment."
                </p>
                <div className="speaker" id="sp601"><span className="persName">Mr. JACKSON</span>—</div>
                <p className="p-in-sp">I rise for the purpose of moving the previous question. One object I have is to cut off a vote on the amendment, and to take a direct vote upon the proposition. Another is, that my friend from Kanawha [<span className="persName">Mr. SUMMERS</span>] wishes to occupy the floor this morning, and I want to give him an opportunity to do so.</p>
                <div className="speaker" id="sp602"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">The Chair is of the opinion that the previous question will apply to the amendment, and that a vote must be first had upon that.</p>
                <div className="speaker" id="sp603"><span className="persName">Mr. JACKSON</span>—</div>
                <p className="p-in-sp">Then I will withdraw my demand for it.</p>
                <div className="speaker" id="sp604"><span className="persName">Mr. WICKHAM</span>—</div>
                <p className="p-in-sp">
                  It seems to me that the amendment is entirely unnecessary, so far as I understand it. It is more than probable that the Crittenden proposition and the proposition of the Peace Conference will come up in competition before this body. By adopting the resolution approving the conduct of Mr. Crittenden, we are not necessarily asked by that to approve his proposition or any competing
                  proposition. The amendment is utterly unnecessary, and I trust it will be the pleasure of the Convention to vote down the amendment and adopt the original resolution.
                </p>
                <p>The question was then taken on the amendment, and it was rejected. The question then recurring on the adoption of the original resolution,</p>
                <p><span className="persName">Mr. WISE</span> demanded the yeas and nays, which were ordered.</p>
                <p>The question was then taken by the yeas and nays with the following result—yeas 107, nays 16: <PageNumber num={572} /> AYES-Messrs. Janney [President] 
                  , Aston, Baldwin, A. M. Barbour, James Barbour, Baylor, Berlin, Blow, Jr., Boggess, Bouldin, Branch, Brent, Brown, Bruce, Burdett, Burley, Cabell, Caperton, Carter, Chapman, Clemens, Coffman, C. B. Conrad, R. Y. Conrad, Couch, Critcher, Custis, Deskins, Dorman, Dulany, Early, Echols, Forbes, French, Fugate, Garland, Gillespie, Grant, Gravely, Gray, Goggin, Cyrus Hall, Ephraim B.
                  Hall, L. S. Hall, Haymond, Hoge, Holladay, Hughes, Hull, Jackson, Marmaduke Johnson, Peter ,C. Johnston, Kent, Kindred, Lawson, Lewis, McComas, McGrew, McNeil, Macfarland, James B. Mallory, Marshall, Marr, Marye, Sr., Maslin, Masters, Miller, Moffett, MOORE, Nelson, Orrick, Osburn, Parks, Patrick, Pendleton, Porter, Preston, Price, Pugh, Rives, Saunders, Sr., Robert E. Scott, Wm. C.
                  Scott, Sharp, Sheffey, Sitlington, Slaughter, Southall, Speed, Spurlock, Staples, Alexander H. H. Stuart, Chapman J. Stuart, Summers, Sutherlin, Tarr, Tayloe, Thornton, Franklin P. Turner, Tyler, Waller, Whitfield, Wickham, Willey, Wilson, WOODS, Wysor-107.
                </p>
                <p>NAYS-Messrs. Blakey, Borst, Cecil, Conn, Fisher, Graham, Hunton, Isbell, Leake, Morton, Randolph, Richardson, Seawell, Strange, Robert H. Turner, Wise-16.</p>
                <p>So the resolution was agreed to.</p>
              </div>
              <div className="section" id="vsc1965.v1.2.22.3">
                <h3><span className="headingNumber">1.22.3. </span><span className="head">EXPLANATIONS OF VOTES</span></h3>
                <div className="speaker" id="sp605"><span className="persName">Mr. BOISSEAU</span>-When his name was called said :</div>
                <p className="p-in-sp">As this resolution professes to express the sentiments of the people of Virginia and not the sentiments of this Convention—and as I do not know what the sentiments of the people of Virginia are upon this subject, I beg to be excused from voting.</p>
                <p>The question on excusing Mr. Boisseau was put, and decided in the affirmative.</p>
                <div className="speaker" id="sp606"><span className="persName">Mr. SHEFFEY</span>-When his name was called said :</div>
                <p className="p-in-sp">Without intending to commit myself to any plan or proposition of adjustment, but intending simply to accord to Mr. Crittenden the thanks of this Convention and of the people of Virginia, for patriotic motives and efforts, I vote aye.</p>
                <div className="speaker" id="sp607"><span className="persName">Mr. WILSON</span>—When his name was called said :</div>
                <p className="p-in-sp">In casting my vote in favor of this resolution, I do not wish to be understood as committing myself, by direction or indirection, to any plan or policy of adjustment, or as indicating what my course may be in the future. I vote aye.</p>
              </div>
              <div className="section" id="vsc1965.v1.2.22.4">
                <h3><span className="headingNumber">1.22.4. </span><span className="head">POPULATION OF VIRGINIA</span></h3>
                <div className="speaker" id="sp608"><span className="persName">Mr. CLEMENS</span>—</div>
                <p className="p-in-sp">
                  On the application of a number of members of the Convention, I requested the Superintendent of the census Bureau in Washington to make out a full and complete return of the population of the Commonwealth of Virginia, arranging the same by counties <PageNumber num={573} /> and giving the population by whites, free colored and slaves. I ask leave to
                  submit the paper to the Convention for the use of its members, and ask that it be laid upon the table and printed together with the letter subsequently received by the Superintendent, correcting an error in the county of Amherst. The paper gives the certified return of the aggregate population of the Commonwealth of Virginia since 1860.
                </p>
                <p>As appears from the tables sent by <span className="persName">Mr. CLEMENS</span> to the Clerk's desk, the following may be set down as the population of the State :</p>
                <p>Whites 1,067,613</p>
                <p>Free Colored 37,579</p>
                <p>Slaves 490,887</p>
                <p>Total 1,596,079</p>
              </div>
              <div className="section" id="vsc1965.v1.2.22.5">
                <h3><span className="headingNumber">1.22.5. </span><span className="head">FEDERAL RELATIONS</span></h3>
                <div className="speaker" id="sp609"><span className="persName">Mr. GEORGE W. SUMMERS</span>, of Kanawha, having obtained the floor, addressed the Convention as follows :</div>
                <p className="p-in-sp">
                  Mr. President, if there is nothing now before the Convention claiming precedence in the order of business, I will move that the Message of the Governor of the Commonwealth communicating the report of the Commissioners to the late Peace Conference at Washington, which was laid on the table a few days since be now taken up. If that motion shall be successful, I propose to follow it
                  with a motion to refer the message and report to the Committee on Federal Relations, and, with the consent of the Convention, to submit some remarks in support of that motion. I move in the first place, that the message and report be taken up.
                </p>
                <p>The motion was agreed to.</p>
                <div className="speaker" id="sp610"><span className="persName">Mr. SUMMERS</span> continued—</div>
                <p className="p-in-sp">Mr. President, it was my purpose some days since, to have submitted a motion for the reference of this report, but I have had no opportunity of doing so. I learned that I could not effect my purpose, unless by a previous motion to lay on the table the resolutions offered by the gentleman from Chesterfield [Mr. Cox] 
                  , and the pending amendments to his proposition, a course which I did not feel at liberty to adopt. I think it is due to the Convention and to the Committee, that the earliest possible opportunity for the consideration of this report, shall be had, and that the views of the Committee upon it shall be presented to the Convention. Having been one of the Commissioners sent by Virginia
                  to the Peace Conference, which seems to have become the name applied to the convocation of States lately held in Washington, and having had the misfortune to <PageNumber num={574} /> differ on some points with my most respected and esteemed colleagues, or a portion of them, I hope it will not be considered out of place if I avail myself of this occasion
                  to present, briefly as I may, the considerations and views which induced me to sustain the measure adopted by that Conference.
                </p>
                <p className="p-in-sp">
                  I remark, however, in the first place, that there were but very few votes taken upon any of the propositions offered in the Conference upon which there was any diversity of opinion entertained between the Commissioners from Virginia. According to my recollection the first section of the adjustment agreed on in regard to the Territorial question, presented the only occasion when there
                  was any dissenting opinion entered on the journal by a portion of the Commissioners from this State.
                </p>
                <div className="speaker" id="sp611"><span className="persName">Ex-President TYLER</span>—</div>
                <p className="p-in-sp">Except in regard to the second section, upon which I raised my own dissenting voice.</p>
                <div className="speaker" id="sp612"><span className="persName">Mr. SUMMERS</span> resumed :</div>
                <p className="p-in-sp">I beg pardon. I had forgotten that the distinguished gentleman from Charles City [Ex-President TYLER] my respected and venerable colleague in this commission, had indicated his dissent upon the second section, which second section, however, received the votes of the other four Commissioners from Virginia.</p>
                <p className="p-in-sp">
                  This adjustment, Mr. President, is the result of a procedure instituted by the General Assembly of this Commonwealth, desiring, with the most patriotic motives, to make an earnest effort for a settlement of the difficult and threatening questions now dividing the country. I do not propose to go into details in regard to the votings in the Conference. But I propose to address myself
                  for a short time to the adjustment itself, remarking, however, in advance, that we met in the Conference Commissioners from twenty-one States of this Union—seven of them slaveholding States—and fourteen non-slaveholding States—and that, while there was, to some extent, an exhibition of a spirit and purpose adverse to the views of the Southern States and the Commissioners from
                  Virginia; yet, as a general proposition, I was gratified to find marked indications of a spirit of fraternity—a general desire for harmony and tranquility, and an anxious desire for the restoration and perpetuity of our Union. This, especially, manifested itself, I will take occasion to say, on the part of the Commissioners representing the border non-slaveholding States. Going
                  beyond that line, I cannot permit myself to omit mentioning that the State of Rhode Island was found, throughout all the proceedings, in entire harmony with the wishes and purposes of those of the Southern States, and active in making effort for a settlement and adjustment <PageNumber num={575} /> of these questions; that the State of New Jersey, by her
                  entire delegation, was with us throughout upon every proposition ; that the Commonwealth of Pennsylvania, by a large majority of her delegation, and the State of Ohio, by a like majority of her Commissioners, exhibited the same purpose and spirit; and it was a matter of the deepest gratification and the most hopeful interest, that throughout those labors and to their close there was
                  manifested a growing spirit of brotherhood, a tightening of the bonds as it were, between the border States on each side of the line, including Illinois and Indiana. Indiana it is true did not vote upon the final adoption of the measures promulgated by the Conference. The Commissioners from Indiana were instructed by the resolutions of the Legislature of that State, to bestow no
                  final vote upon any proposition, without first reporting it to the Legislature then in session, for its concurrence. Following out the direction of her Legislature, in accordance with those restrictive resolutions, Indiana did not cast her vote upon the final action of the body. But Illinois did, and aided materially in their support, on the floor, in their advocacy.
                </p>
                <p className="p-in-sp">
                  Coming, then, Mr. President, to the result of these labors, I will first make a single remark as to a general objection to the whole, so far, at least, as the Territorial question is concerned ; an objection which I have heard repeated, and which was made in the Senate of the United States, and that is, that under the decision of the Supreme Court of the United States in the Dred
                  Scott case, the South is entitled to participation in all the Territories belonging to the people of the United States; that we have equal right from the South as from the North, to migrate to any of the Territories belonging to the United States, carrying with us any species of property known to the laws of the State from which it may be carried; and that this right being now
                  secured and recognized by the decision referred to, this adjustment is an abandonment, on the part of the South, of the right to that portion of the Territory on the North side of the line 36 deg. 30 min.
                </p>
                <p className="p-in-sp">
                  Mr. President, I have not been accustomed to regard the South as being entirely satisfied to stand upon the Dred Scott decision in this particular. Were that the fact, could we, in our own judgment, with safety now and hereafter stand upon the Dred Scott decision, awarding equal rights to the people of every portion of the Union in those Territories, there would be no occasion to
                  seek an adjustment at all, so far as the Territorial questions are concerned. We all know, however, that in certain quarters, that decision, beyond a given point, has not been regarded as of binding authority. We have been accustomed <PageNumber num={576} /> in the South to apprehend, that, by mutations in the composition of the Court, that opinion may
                  be changed ; and I think I cannot err in expressing it as the general conclusion of the Southern mind, that a fair and equitable partition of the Territories would be the most judicious mode of settling the question.
                </p>
                <p className="p-in-sp">
                  We went to Washington, Mr. President, under resolutions of the General Assembly of Virginia, which declared that the plan of adjustment proposed by the distinguished Senator from Kentucky, Mr. Crittenden, would be satisfactory to the people of this Commonwealth. I did not regard that declaration as intended to present the scheme of adjustment referred to in the light of an ultimatum.
                  I do not know that any of us regarded ourselves as restricted to the Crittenden propositions, but that these were indicated by the General Assembly as presenting one of the modes of adjustment which would be satisfactory to the people of Virginia, and that they were suggested as the basis of negotiation at Washington. The adjustment arrived at by the Peace Conference divides all the
                  Territory belonging to the United States by the line 36, 30 of North latitude. If it be an objection to this adjustment that it is a surrender of all upon the North side of that line it is equally an objection to the Crittenden proposition, which divides it by the same line. I observe that one of the distinguished Senators from Virginia in the Senate of the United States made this
                  objection : that it was a surrender of the rights of the South on the North side of the line of 36, 30; and yet that same distinguished Senator himself offered the first article of the Crittenden proposition as a substitute for the first article of the adjustment at Washington, and so far as the partition of the Territory by a fixed line is concerned both are precisely alike.
                </p>
                <p className="p-in-sp">Passing from that objection, and insisting that we might well have thought ourselves justified in approving a fair partition of the Territories by the plain directions of the resolutions of the General Assembly under which we were commissioned, I beg leave to submit some remarks upon the section itself.</p>
                <p className="p-in-sp">
                  I have before me the propositions submitted in the Senate of the United States by Mr. Crittenden, to whose patriotism we have just paid the tribute of our homage and respect,* as also the report of the adjustment made in the Peace Conference at Washington. I propose to call the attention of the Convention, with its permission, to the provisions contained in these two modes of
                  adjustment. I think, Mr.
                </p>
                <p className="p-in-sp">
                  *The Convention had just passed a resolution of thanks to <span className="persName">Mr. C</span>., for his patriotic efforts in the Senate. <PageNumber num={577} /> President, that it can be made quite manifest that while the adjustment made in the Peace Conference is as a whole the full equivalent of the Crittenden proposition, that it is in many
                  respects, in advance of it and of more real value. The first section of the report is in these words :
                </p>
                <p className="p-in-sp">
                  "In all the present Territory of the United States, north of the parallel of thirty-six degrees and thirty minutes of north latitude, involuntary servitude, except in punishment of crime, is prohibited. In all the present Territory south of that line, the status of persons held to involuntary service or labor, as it now exists, shall not be changed; nor shall any law be passed by
                  Congress or the Territorial Legislature to hinder or prevent the taking of such persons from any of the States of this Union to said Territory, nor to impair the rights arising from said relation; but the same shall be subject to judicial cognizance in the Federal Courts; according to the course of the common law. When any territory north or south of said line within such boundary as
                  Congress may prescribe, shall contain a population equal to that required for a member of Congress, it shall, if its form of government be republican, be admitted into the Union on an equal footing with the original States, with or without involuntary servitude, as the Constitution of such new State may provide."
                </p>
                <p className="p-in-sp">Mr. Crittenden's corresponding proposition is in these words :</p>
                <p className="p-in-sp">
                  "In all the territory of the United States now held or hereafter acquired, situated North of latitude thirty-six degrees and thirty minutes, slavery or involuntary servitude, except as a punishment for crime, is prohibited while such Territory shall remain under Territorial Government. In all the territory South of said line of latitude, slavery of the African race is hereby
                  recognized as existing; and shall not be interfered with by Congress; but shall be protected as property by all the departments of the Territorial Government during its continuance; and when any Territory, North or South of said line, within such boundaries as Congress shall prescribe, shall contain the population necessary for a member of Congress, according to the then Federal
                  representation of the people of the United States, it shall, if its form of Government be republican, be admitted into the Union on an equal footing with the original States, with or without slavery, as the Constitution of such new State may provide."
                </p>
                <p className="p-in-sp">The provisions are the same in both in reference to the Territorial division, and both provide that any new State shall be brought into the Union with or without slavery, "as the Constitution of such new State may provide."</p>
                <p className="p-in-sp">
                  Now, Mr. President, by the provision of the first article of the Peace <PageNumber num={578} /> Conference adjustment, the status of persons held to involuntary service or labor, as it now exists, can not be changed. "The status of persons held to involuntary service," is a state or condition of slavery. The persons so held in involuntary service are
                  themselves slaves. But this status of persons held to involuntary service is further indicated and fixed by the words "as it now exists." The announcement is that the condition of slavery "as it now exists" shall not be changed.
                </p>
                <p className="p-in-sp">
                  It is known to the members of this Convention that on the South side of this line of 36 deg. 30 min., slavery of the African race now exists ; and this adjustment by the Peace Conference, must be taken in connection with the fact that slavery has already been established on the South-side of this line, and has received already, prior to the labors of this Conference, the most full,
                  perfect and complete protection, at the hands of the Territorial government of New Mexico; and that the existence of this state of slavery under the law of New Mexico is the condition intended to be recognized by this article ; and this provision, if it shall find place in the Constitution of the United States, recognizes in effect this law of New Mexico as it stands on the statute
                  book of the territorial government. It concedes the condition of slavery, in the Territory, now to exist. It was specially established by the law of the Territorial Legislature. When the existence of the institution is itself admitted and recognized, the law of its creation and protection, meets an equal recognition. This condition of slavery "as it now exists" is not to be changed
                  during the territorial state of the country. How does it "now exist"? By territorial law. What does the declaration mean, that this condition "shall not be changed"? It means that the territorial law, establishing and protecting slavery, shall not be repealed during the continuance of the Territorial Government. Slavery exists in New Mexico, both de jure and de facto.
                </p>
                <p className="p-in-sp">
                  Permit me to call the attention of the Convention to this code, adopted by the New Mexico Territorial Legislature, on the 3rd day of February, 1859. The very title of the act is—"An act to provide for the protection of property in slaves in this Territory." This statute consists of thirty-one sections, and I will take it upon me to say that it is as full, complete and perfect a
                  codification of all acts necessary for the discipline, protection and safety of this population, as can be found in any one of the States of this Union. We have nothing, in the whole compass of our statutory enactments in Virginia that meets more thoroughly every case growing out of the presence, or relating to the value and use of slave property than this act supplies. The law
                  itself, passed by the Territorial Legislature of New Mexico, and which I now <PageNumber num={579} /> have before me, is too voluminous to read entire before the Convention. I will present a few sections, as specimens of the whole :
                </p>
                <p className="p-in-sp">SEC. 1. That every person who shall be convicted of the unlawful killing of a slave, or other offence upon the person of a slave within this territory, whether as principal or accessory, shall suffer the same pains and penalties as if the party upon whose person the offence was committed was a free white person.</p>
                <p className="p-in-sp">
                  SEC. 2. Every person who shall steal any slave, with the intent that the owner or any one having an interest in such slave, present or future, vested or contingent, legal or equitable, shall be deprived of the use or benefit of such slave, shall, upon conviction, suffer imprisonment for a term not more than ten nor less than four years, and be fined in a sum not more than ten
                  thousand nor less than five hundred dollars; and every person who shall by violence, seduction or other means, take and carry or entice away any slave with the like intent shall be deemed and held, for every purpose whatever, to have stolen such slave within the meaning of this act. And any person who knowing any slave to have been stolen as aforesaid, shall aid, assist or advise in
                  or about the carrying away of any such slave, shall suffer the like penalties as are above prescribed against the person stealing such slave as aforesaid.
                </p>
                <p className="p-in-sp">
                  SEC. 3. Every person who shall carry or convey, or wilfully assist in carrying or conveying any slave, the property of another, with the intent or for the purpose of aiding or enabling such slave to escape out of this territory, or within this territory and beyond the control or recovery of his owner or master, shall, upon conviction thereof, suffer the same penalties as are
                  prescribed in the foregoing section of this act. And in any indictment preferred against any person for the violation of any of the provisions of this act, the property in the slave shall be well laid, if charged to belong to any person having an interest in such slave, whether such interest be legal or equitable, present or future, joint or several, vested or contingent.
                </p>
                <p className="p-in-sp">
                  SEC. 4. Every person who shall forge or furnish to any negro, free or slave, any false or fabricated free papers, or false evidences in print or writing, of the freedom of such negro, shall, upon conviction, suffer imprisonment for a term not more than five years, nor less than six months, and be fined in a sum not more than one thousand, nor less than one hundred dollars.
                </p>
                <p className="p-in-sp">
                  SEC. 5. Any person who shall hire, entice, persuade, or in any manner induce any slave to absent himself from the service or custody of his owner or master, or who shall, upon any pretence, harbor or <PageNumber num={580} /> maintain any slave so absenting himself from such service or custody, shall, upon conviction thereof, suffer fine and imprisonment
                  as prescribed in section four of this act, and shall, besides, be liable to the owner or master in a civil suit for damages.
                </p>
                <p className="p-in-sp">
                  SEC. 6. Any person who shall endeavor to excite in any slave a spirit of insurrection, conspiracy or rebellion, or who shall advise, countenance, aid, or in any manner abet any slave in resistance against his owner or master, shall, upon conviction, suffer imprisonment not less than three months, nor more than three years, and be fined in a sum not less than twenty-five nor more than
                  one thousand dollars.
                </p>
                <p className="p-in-sp">
                  The 25th section of this act provides that "the emancipation of slaves within this territory is totally prohibited." The 30th section declares that the various provisions contained in the act shall not be construed to embrace the relation "between masters and contracted servants;" but that the word "slave" "shall only apply to the African race." There exists in New Mexico a system of
                  peonage, by which free persons of mixed races, Indian and Mexican, become servants for terms, upon contract between the parties. This relation is also regulated by territorial acts, one of which passed on the 26th day of January, 1859, I have before me. The act of February 3d, 1859, from which I have read, relates entirely to slaves of the "African race," whose status, or condition
                  of slavery is fully recognized and fixed by the law itself.
                </p>
                <p className="p-in-sp">We have African slavery then, fully established and in existence, South of the line of 36 deg. 30 min., by virtue of the act of the Territorial Legislature of New Mexico.</p>
                <p className="p-in-sp">African slavery also exists within the limits of the Cherokee treaty grant, the northern boundary of which is the 37th parallel of latitude, being one-half degree beyond 36 deg. 30 min.</p>
                <p className="p-in-sp">
                  Many of the chiefs are large owners of slave property, engaged in the culture of cotton; with regular code of their own, and with a system of government founded to a very great extent upon the same principles as ours, and formed somewhat in imitation of it; having a council of elders or chiefs, acting as a Senate, with a House of Representatives, which two bodies constitute the
                  legislative branch of their government.
                </p>
                <p className="p-in-sp">The treaty by which these lands were ceded to those Indians makes the territory granted their own property and domain, and whether included directly or indirectly in this adjustment would be wholly immaterial so far as their rights are concerned.</p>
                <p className="p-in-sp">
                  The Indians hold these lands by treaty stipulation, and by deeds of cession from the Government of the U. States. The Territory granted to them belongs to them, and not to us. They have their own government, <PageNumber num={581} /> laws and domestic institutions. No amendment of the Constitution would affect their rights or disturb their position,
                  being no parties to the procedure by which the amendment is to be made, and deriving title, with fixed boundaries and possession, from a grant antecedent to the amendment. The article under consideration purports to deal only with the Territories now belonging to the United States.
                </p>
                <p className="p-in-sp">
                  It must be conceded, I think, that this article of the adjustment, when read with the light of the act of the Territorial Legislature of New Mexico, recognizes the condition of slavery upon the South side of the line of 36 deg. 30 min., as a present existing fact. It declares that this "status of persons held to involuntary service," this condition of slavery, "as it now exists,
                  shall not be changed."
                </p>
                <p className="p-in-sp">
                  Having recognized the existence of slavery South of the line, it could not alter the result, to determine whether it came into existence lawfully or otherwise. Suppose it be said that this territory was acquired from Mexico—that previous to its purchase it was free territory—that its normal condition was that of freedom. And suppose it be urged, in addition, that the Legislature of
                  New Mexico had no power to enact the law referred to. Yet here is the constitution, the organic law, the law to which all other law must conform, declaring that slavery, "as it now exists," shall not be changed—whether it exists lawfully or unlawfully. Here the Constitution, as proposed, seizes it, as it is, recognizes it as a present existing condition, and that recognition is to be
                  embodied in the fundamental law. This condition is not only recognized, but is permanently fixed ; it is not to be changed.
                </p>
                <p className="p-in-sp">Having fully recognized slavery as now existing in the Territory, and having declared that that condition shall not be changed, the amendment next provides for taking slaves to the territory from any of the States.</p>
                <p className="p-in-sp">"Nor shall any law be passed by Congress or the Territorial Legislature, to hinder or prevent the taking of such persons from any of the States of this Union to said Territory," &amp;c.</p>
                <p className="p-in-sp">The right here secured, to take slaves to the Territory, is another proof that the Territory, while a Territory, is dedicated to slavery. The right of taking is worthless, if not to be held and enjoyed after arrival.</p>
                <p className="p-in-sp">
                  Here, then, is another recognition of the existence of slavery on the South of the line, coupled with the right to transfer slaves in any number, from the States where it exists, to such Territory. This right is not to be hindered or prevented, either by Congress or the Territorial Legislature. <PageNumber num={582} /> Here is a complete inhibition
                  against the passage of any law to prevent migration with slaves.
                </p>
                <p className="p-in-sp">
                  But Congress and the Territorial Legislature, are not only inhibited from passing any law "to hinder or prevent" the migration with slaves, but each government is, in like manner, forbidden to pass any law to "impair the rights arising from said relation"—the relation between master and slave; or to use constitutional terms, the relation between "persons held to service" and the
                  person to whom "such service is due." The relation of master and slave is here fully recognized. The rights arising from said relation are not to be "impaired." This forbids either Congress or the Territorial Legislature from repealing the act before referred to for the "protection of property in slaves" in New Mexico. Slavery now exists and is protected by that statute. The rights
                  arising from that relation, in New Mexico, spring from and are connected with it. The repeal of the statute would impair these rights
                </p>
                <p className="p-in-sp">the repeal, therefore, falls under the prohibition of the amendment.</p>
                <p className="p-in-sp">
                  The existence of slavery, as a fact, is admitted and recognized by the amendment—the announcement that this condition is not to be changed, excludes controversy as to whether it came there properly or not. How are the rights of the master to be protected? The very recognition of the relation of master and slave invests the master with all the rights of that relation—but he has an
                  ample code embodying protection to that relation and the rights springing from it—these rights cannot be impaired or abridged by withdrawing that protection.
                </p>
                <p className="p-in-sp">How are these rights to be awarded him when withheld, or vindicated when assailed? They are to be "subject to judicial cognizance in the federal courts according to the course of the common law."</p>
                <p className="p-in-sp">
                  Now, Mr. President, I beg leave to say that very many seem to mistake the import of this portion of the adjustment. The common law is not here referred to as conferring the right of the master to his slave, but is referred to solely as furnishing the remedies to enforce those rights. The right is given in the proposed amendment itself. Slavery, as a present existing condition, is
                  admitted and recognized—this condition is not to be changed—the right to carry slaves to the territory is not to be hindered or prevented—the rights arising from the relation of slavery are not to be impaired. Then comes the clause which places these rights under judicial cognizance, to be awarded, vindicated, executed and protected "according to the course of the common law." That
                  malleable, flexile and expansive system of jurisprudence, known as the "Common Law," is pointed to, not as the source of the rights of the master, but as the abounding field, furnishing all proper and <PageNumber num={583} /> needful remedies for the assertion of those rights. It is the proud and just boast of the Common Law, that it knows no right,
                  without an action, no wrong without a remedy.
                </p>
                <p className="p-in-sp">
                  The celebrated case decided by Lord Mansfield—I mean the Somerset case—in which it was said that slavery was unknown to the common law—has no relation, whatever, to the question under consideration, and would have no basis to stand on, in a case arising in our Territories under the proposed amendment. Neither that decision, nor any principle deducible from it, has any application to
                  this proposition as agreed upon by the Peace Conference; none whatever. That decision went upon the ground that no statute of England recognized slavery; that it was a condition unknown to the Parliamentary code, unknown to statutory or Constitutional provision or recognition, and that the common law, and the common law alone, was to be looked to, both for the right and the remedy. A
                  master from one of the Islands, seeking to recover his slave on the soil of England, was met by the objection that no statute declared his right to recover the slave, or recognised the relation of master and slave, and he was remitted and restricted to the common law for redress. Here the Constitution itself—for it is proposed to embody this proposition in the Constitution—here the
                  Constitution, the organic law, recognizes the right —declares that that right shall never be impaired; that no law shall be passed by Congress or the Territorial Legislature, to hinder or prevent the carrying of slaves from any of the States of this Union into the Territories, or to impair the rights arising from such relation. That relation is admitted and sanctioned in the most
                  solemn and most perfect form by the fundamental law itself, according to the proposition now under examination. After the right has been recognized and confirmed ; after the relation has been created and sanctioned, then the declaration is that the Courts shall take Judicial cognizance of that relation according to the rules, remedies and practice of the common law.
                </p>
                <p className="p-in-sp">
                  And, Mr. President, I need not ask you, versed as you are known to be, in jurisprudence; I need not ask the professional gentlemen of this body, what more ample and abundant remedies could be afforded than those which are to be drawn from the common law? The action of "detinue" where you desire the possession of a specific chattel, is furnished by the common law. The action of
                  "trover" if you waive the question of specific property, and are content with damages, is furnished by the common law. The action of "trespass ;" the action of "replevin," all actions that are needful, are furnished by this system. <PageNumber num={584} /> Old as it is, in our own Commonwealth, you will find no act in the statute law of Virginia, in so
                  many words, establishing slavery in this Commonwealth. Go back to the foundation of the Colony, and you will find no law establishing that relation or creating slavery. In 1620, I believe it was, some twenty African barbarians were landed at Jamestown from a Dutch ship, and sold as slaves. From that period slavery has existed in Virginia. That relation has been recognized and
                  protected by various laws, and by usage, for more than two hundred years in Virginia, and has become interwoven with our social and industrial system, as also with our legislative history.
                </p>
                <p className="p-in-sp">Now what are the remedies most familiar in Virginia, and which are constantly resorted to for the vindication of the rights of the master?</p>
                <p className="p-in-sp">
                  The common law of England belonged to us when a colony. It was afterwards adopted as part of our jurisprudence and is now incorporated, by statute, into our legal system except so far as it may be modified by legislative enactments. We are in the constant habit of resorting to common law remedies in Virginia to enforce and protect the rights arising out of the condition of slavery.
                  Yet the common law in Virginia, no more than the common law in England or in a territory, of itself, recognizes slavery, or confers the right of the master in his slave. That right in Virginia, is derived from usage and legislative recognition. It is recognized by the Constitution of the State and protected by law. Being so recognized and protected, the common law furnishes its
                  remedies to carry out and vindicate the rights which spring from the relation.
                </p>
                <p className="p-in-sp">The action of detinue, the action of trover, the action of trespass, are all common law remedies, applied, daily, to slave property, or to any other property. The right being conferred, the common law, with its plastic hand, moulds the remedy by which the right is to be asserted.</p>
                <p className="p-in-sp">
                  And so in a territory. When the condition of slavery shall have been acknowledged to exist, as it is acknowledged by the amendment under consideration; existing under full protection, by the law of New Mexico; and when it shall have been declared, as is proposed, that "the rights arising from said relations shall not be impaired," coupled with the provision that these rights are
                  subject to judicial cognizance, according to the course of the common law, can any legal mind doubt, that the common law would take hold of those rights and supply all needful remedies for their enforcement? Here the common law jurisdiction, is expressly conferred on the Federal courts, and these courts are directed to proceed under the remedies and practice of that system.
                  <PageNumber num={585} /> But it has been objected, to this section, that it does not, as Mr. Crittenden's proposition does, use the term "slave" and "slavery," and "African slavery;" that it does not, as the Crittenden proposition, require slaves to be protected as property by all the branches of the territorial government.
                </p>
                <p className="p-in-sp">
                  Well now, sir, is there, in reality, any difference between the rights of the master under the one form of language and the other? I can conceive of none. I am utterly incapable of conceiving how the rights of the master may be affected by the absence or presence of these terms of phraseology. I regard the terms in the one case, as fully and distinctly embodying a recognition of
                  slavery as in the other.
                </p>
                <p className="p-in-sp">
                  The introduction of new terms of description into any amendment of the Constitution, different from the description of the same thing as given in the Constitution itself, is, to say the least, of doubtful propriety and expediency. We do not find the words required to be inserted here used in any part of the Constitution, by the framers of that instrument. You may search it from the
                  beginning to the end, and you will not find the word "slave" or "slavery" in it. It has been said that it was a fundamental error that these terms were not incorporated in the Constitution. I will not go into a discussion of this question of the propriety of the phraseology used in that great instrument, but those who are familiar with Constitutional history, will remember that these
                  very terms "persons held to service or labor" were of Southern and not of Northern origin. These words were supplied from South Carolina, as will be found in the account preserved by Mr. Madison of the debates, discussions and amendments, proposed and adopted in the Convention of 1787, which framed the Constitution. And, why was it so? Was it not the appropriate sequence of the
                  language used in the 3d section of the 1st article of the Constitution?
                </p>
                <p className="p-in-sp">
                  I have not a copy of the Constitution with me, but a reference to it is sufficient. It is by that article that taxation and representation are disposed of. It will be remembered by all, that representatives and direct taxes are apportioned among the several States according to numbers, which are to be determined by adding to the whole number of free persons, including those bound to
                  service for a term of years, and excluding Indians not taxed, three-fifths of all other persons. This is the provision made in the very first article of the Constitution, and may be justly called the cornerstone of the whole fabric. Upon it, representation and taxation both repose. It declared in limine that representative power and taxation shall be according to an enumeration of
                  inhabitants ; shall rest upon numbers, which are to be determined <PageNumber num={586} /> by adding to the whole number of free persons, including those bound to service for a term of years, and excluding Indians not taxed, three-fifths of all other persons. We have three-fifths of our slaves counted for representation, under this clause, and by the
                  description of "other persons."
                </p>
                <p className="p-in-sp">
                  We find slaves everywhere in the Constitution treated as persons; they were necessarily treated as persons in that section, for the reason that representation was not to be given to any but persons. It was not based upon property. Representative power was to rest upon population. This was the theory of the government of the United States. It does not rest upon property, or upon
                  property and contribution combined. It was based upon the number of the inhabitants, and the great question upon that portion of the Constitution was, whether the whole number of slaves should be enumerated as persons, for the purpose of representation or taxation, and what lesser number should be adopted.
                </p>
                <p className="p-in-sp">
                  I will not go into the discussions which occurred upon that question at different periods, both in the formation of the articles of confederation, and this article of the Constitution of the United States. All will remember, however, that when it was a matter of interest to fix a mode for determining the ratio of contribution by the respective States, under the articles of
                  confederation, to the support of government, that the number of inhabitants was finally adopted as an exponent of the wealth of the State, and of its capacity to contribute. The question then presented itself, how far slaves should be counted as persons, in order to fix the amount to be paid by the States. Some of the Southern representatives held that they should not be counted for
                  that purpose at all. It was, however, determined that three-fifths should be included in the enumeration. The same numerical proportion, under the description of "three-fifths of all other persons," was afterwards adopted in the Constitution, as the measure both of representation and taxation. Having included slaves for representation and taxation, under the designation of "other
                  persons," it was appropriate to speak of them and describe them in other portions of the Constitution, by similar terms. Thus, in that section which provides for the rendition of fugitives, the same form of phraseology is adopted, with a qualification showing the status of the person. "Any person held to service or labor," escaping from one State into another, is to be surrendered to
                  "the person to whom such service or labor is due." The "person held to service or labor" is a slave—a slave to "the person to whom such service or labor is due." He does not fall either in the <PageNumber num={587} /> category of those to whom full representation is awarded, or that which is excluded. He is not to be enumerated in the class of "free
                  persons, including those bound to service for a term of years," nor excluded with "Indians not taxed ;" but he belongs to the class of "all other persons," of whom three fifths are to be numbered. He could not be called a "slave" in this clause of the Constitution, because the slave is property, and only persons are to be represented. If, in the other section referred to, they had
                  been called "fugitive slaves," departing from the terms used in the first article, it might have been doubted whether they belonged to the class described in that article as "other persons." They are therefore called "persons held to service or labor." They are both persons and property, and so the laws of all the slave-holding States treat them.
                </p>
                <p className="p-in-sp">
                  I am content with the language of the Constitution as our fathers made it. I am not ambitious of using new terms or introducing new descriptions. I think we shall act wisely by adhering to their well considered phraseology. The Constitution as it is has received construction, and is well understood in the particular matter under examination. "Persons held to service or labor" are
                  slaves, and recognized as such by all. I see no advantage in substituting the word "slave" for "persons held to service or labor." I do not think, therefore, that this report of the "Peace Conference is obnoxious to reproach in that point of view."
                </p>
                <p className="p-in-sp">Now, in point of protection, Mr. Crittenden's proposition provides that slavery shall be protected as property by all the Departments of the Territorial Government.</p>
                <p className="p-in-sp">
                  Mr. President, does that strike you as any more reliable to the slave-holder in New Mexico than the protection which this section affords? When Mr. Crittenden says that slavery shall be protected by all the Departments of the Territorial Government, what does it amount to, more than is provided here. The amendment proposed by the Peace Conference seizes slavery as it now exists in
                  New Mexico and fastens the machinery by which it exists and is protected. The condition is not to be changed.
                </p>
                <p className="p-in-sp">
                  The legislative branch of the Territorial Government has performed its duty. There is a slave code conferring rights and protecting rights already in full force and operation in New Mexico; and the amendment proposed, declares that these rights shall not be impaired. They would be impaired, perhaps destroyed, by the repeal of this code. The repeal then is prohibited, during the
                  continuance of the Territorial Government. <PageNumber num={588} /> Then, so far as the legislative branch of the Territorial government is concerned, it needs no constitutional mandate to protect slavery in New Mexico. It has already performed that duty and its work cannot be undone.
                </p>
                <p className="p-in-sp">Then the judicial department of the Territorial government, is required to take cognizance of the rights of slave owners, and to protect them by all remedies of the common law, and which is in addition to the rights and remedies given by the New Mexican statute, and which the Territorial courts are bound to execute, by virtue of the statute itself.</p>
                <p className="p-in-sp">
                  The legislative and judicial departments of the government, then, are bound to protect, as much as under Mr. Crittenden's proposition. Well, what power has the Governor of the Territory over the subject? He is to see the laws executed. This it is as much his duty to do, under the law of the Territory, and the proposed amendment as it would be under the amendment of Mr. Crittenden.
                </p>
                <p className="p-in-sp">
                  It may be remarked here, that while Congress and the Territorial Legislature are not to "impair the rights arising from this relation," there is no inhibition against the passage of laws for the protection of those rights, if any occasion should occur requiring it. On the contrary, I should hold that the very mandate of the Constitution—supposing this amendment to become a part of
                  the Constitution—the very prohibition against impairing the rights arising from this relation, would of itself be a source of power and of duty to protect and secure those rights by the passage of laws for that purpose. It might be, in any given case, that the very omission to pass a law for its protection, would itself be an impairing of the rights springing from the relation.
                </p>
                <p className="p-in-sp">
                  Taking it as it is now presented, I hold that this amendment provides ample protection of itself, and, therefore, I have no difficulty in bringing my mind to its acceptance. It has been my ardent desire, as it was the desire of all the Commissioners from Virginia, to obtain every guaranty which our own State, and which the States of the South are entitled to and ought to have. We
                  have differed on a few points, and only a few, as to the form of those guaranties. We have, however, had the same end and object in view, the equal rights of the States, and the security and protection of those rights. In my judgment, these rights, and this equality, so far as the territorial question is concerned, are fully attained by the first article of the amendments proposed by
                  the Conference.
                </p>
                <p className="p-in-sp">There is another feature in the Crittenden proposition which is not</p>
                <p className="p-in-sp">found in this adjustment, and to that I propose to address a few words.</p>
                <p className="p-in-sp">
                  It will be remembered that Mr. Crittenden's proposition, as originally <PageNumber num={589} /> offered by himself in the Senate, had no relation to territory hereafter to be acquired by the United States. His colleague, Mr. Powell, of Kentucky, offered an amendment applying the line 36 deg. 30. min. as a division line between the two systems of slave
                  and free labor, to future as well as to present territory. The amendment of the Constitution, as submitted by the Conference, does not extend to future territory, and in this it differs from the Crittenden propositions as modified by Mr. Powell's amendment.
                </p>
                <p className="p-in-sp">
                  Without going into particulars, as to what occurred in the Conference, it may be stated in general terms that objections were made to the feature embracing future territory, on the ground that it was inexpedient and impolitic to implant, in the Constitution itself, the seed of a policy, looking to future aggrandisement—a filibustering spirit as some termed it—that it would have the
                  appearance of a partition in advance of our neighbor's lands, and might unnecessarily subject our national character and faith to criticism and calumny.
                </p>
                <p className="p-in-sp">It was urged that the future, with its unknown eventualities, should be left to take care of itself. It was argued that if we could satisfactorily divide among ourselves what we now possess, that we might well leave to our children the power and duty of disposing of what they may hereafter add to the public domain.</p>
                <p className="p-in-sp">But, Mr. President, in my judgment, when the first and second sections of the Conference proposition are taken together, they constitute a better arrangement as to future Territories, than the plan of Mr. Crittenden.</p>
                <p className="p-in-sp">
                  Let it be borne in mind that the division of future Territory by the line of 36 deg. 30 min., is not the acquisition of future Territory. You may provide in the Constitution that all future Territory shall be divided by that line ; but such future Territory remains to be acquired; you may never get it. It is only a division in advance, provided you do acquire it :
                </p>
                <p className="p-in-sp">
                  Now, Mr. President, it will be readily seen that if you divide before you acquire, by the line of 36 deg. 30 min., and declare that involuntary servitude is not to exist North of the line, and that slavery shall exist South of it, that we enhance tenfold, if we do not render wholly impracticable, the acquisition of Territory South of the line. With slavery established before hand in
                  all Territory to be acquired South of the line, when we shall come to the question of acquisition, in any given case, under the present mode of acquiring territory, what prospect would exist of making the acquisition?
                </p>
                <p className="p-in-sp">
                  Let it be borne in mind that Mr. Crittenden's proposition contains <PageNumber num={590} /> no change whatever of the present plan of acquiring territory. It contains no provisions on that head. It leaves future territory to be acquired in the modes heretofore practiced, which have been by treaty and by joint resolution. These are the only two modes
                  heretofore resorted to for the acquisition of territory. Louisiana and Florida were acquired by treaty; so were California, New Mexico and Arizona. Texas was acquired and annexed by joint resolution of the two Houses of Congress. Taking either of these plans of acquisition, in connection with Mr. Crittenden's scheme, declaring future territory to be divided in advance by the line of
                  36:30; and what hope have you that any territory South of the line, could be brought in?
                </p>
                <p className="p-in-sp">
                  When it shall have been settled in the Constitution that Southern territory, when it comes, is to be slave territory, will it ever come at all? Of what value is the present partition of future territory if we are to have none? Suppose it be attempted by joint resolution, where is the majority? Why, with all the Southern States restored to us and in harmony again, the non-slaveholding
                  States, upon a joint resolution, are vastly in the majority, with that majority increasing continually. Suppose you try it by treaty; how are you to get the two-thirds majority required for the ratification of a treaty, unless you obtain the votes of a very large proportion of the Northern Senators? The division of the territory is fixed before it is acquired—on one side it is to be
                  free, on the other slaveholding. Power is in the hands of those opposed to slavery. They will use it to acquire on the North side and to reject acquisition on the South side. Leaving the mode of acquisition untouched, and dividing future territory, as Mr. Crittenden's proposition proposes to divide it, fixing slavery on one side and non-slavery on the other, in my judgment, renders
                  it next to impossible that we can ever acquire anything either by treaty or by joint resolution, South of the division line.
                </p>
                <p className="p-in-sp">
                  Again, sir—this state of things might arise under the Crittenden proposition. By that proposition you divide both present and future territory by the line of 36-30. You say slavery shall exist on one side, and that it shall be excluded from the other. The existence of slavery South of the line, is guaranteed only during its territorial condition. When the people of the Territories
                  come to form a State Constitution, they are to decide for themselves whether or not they shall have slavery, and are to be admitted as States with or without that system, as may be determined by their own Constitution. Now there are very valuable and desirable portions of Mexico and Central America, South of the line 36-30. These regions are without slavery. The institution is not
                  permitted by the law of the country. They cannot be acquired as territories, <PageNumber num={591} /> either under treaty or by joint resolution, for the reason, that being made a slave country in advance, the requisite majority could not be obtained.
                </p>
                <p className="p-in-sp">
                  But, suppose the North, with its power of numbers, with its preponderating population, and with greater aptitude of its people for emigration settles its colonies before acquisition, in some of the rich Mexican provinces, of which they may obtain control in the changing fortunes of that ill-governed country. Having obtained a number equal to the population required for a
                  representative in Congress, suppose them to frame a constitution for a State Government, Republican in form, and apply to the Northern majority for an introduction into this American family, either by joint resolution or by treaty. They might be instantly annexed and come with State institutions fully formed, thus escaping the restrictions or impress of a territorial condition
                  altogether. They would come with a constitution prohibiting slavery. In such a case, what benefit would we derive from a present division of future territories? We can only fix its condition during the time it remains a territory. It is not claimed by any one, that we can control the character of a State constitution.
                </p>
                <p className="p-in-sp">
                  The same difficulty might arise in another form. Sonora, Tamaulipas, or other Mexican States, might themselves apply to be annexed to the American Union, as States. In that event, the proposed division of future Territory, would not avail us. Even New Mexico and Arizona are only secured to slave labor during the continuance of a Territorial condition. Even as to them, the right to
                  decide for themselves, when forming State Constitutions, whether they will adopt or reject slavery. is acknowledged and reserved. It could not be otherwise. It is the uncontrollable right of a sovereign State, to construct and shape its own domestic institutions. In the case supposed, the Mexican State. annexed to, or received in the Union, if annexed or received at all, would come
                  with its own Constitution as a State, and with, or without slavery, as such Constitution might provide.
                </p>
                <p className="p-in-sp">
                  We are constrained, then, to look to some other source of security in the future extra-Territorial acquisitions of the nation, than the mere division of them, in advance by a given line. This security, I think, will be found in the provisions of the second section of the Conference adjustment, and which should be considered in connection with the objection to the first section, that
                  it omits to divide future acquisitions.
                </p>
                <p className="p-in-sp">The second section is in these words :</p>
                <p className="p-in-sp">
                  "No Territory shall be acquired by the United States, except by discovery, and for naval and commercial stations, depots and transit <PageNumber num={592} /> routes, without the concurrence of a majority of all the Senators from States which allow involuntary servitude, and a majority of all the Senators from States which prohibit that relation."
                </p>
                <p className="p-in-sp">Territory cannot come in under this clause of the second section by joint resolution, except by the vote of a majority of the Senators from the slave States, and the vote of a majority of the Senators from the non-slaveholding States.</p>
                <p className="p-in-sp">But, further : "nor shall territory be acquired by treaty, unless the votes of a majority of the Senators from each class of States herein-before mentioned, be cast as a part of the two-thirds majority, necessary to the ratification of such treaty."</p>
                <p className="p-in-sp">This section was offered and adopted as a substitute for the second section as proposed in the report of the Committee appointed by the Conference—a Committee consisting of one Commissioner from each State represented in the Conference, selected by their delegations. The corresponding section in the report, as it came from the Committee, is this.</p>
                <p className="p-in-sp">"Territory shall not be acquired by the United States unless by treaty, nor except for naval and commercial stations and depots, unless such treaty shall be ratified by four-fifths of the members of the Senate."</p>
                <p className="p-in-sp">
                  Now, by this provision, it will be perceived that the acquisition of territory is restricted to treaty alone, and acquisition by resolution is cut off. When by treaty, it requires the ratification should be, not by two-thirds of the Senators, as now provided in the Constitution, but by four-fifths. It struck some of us that this was an unnecessary and oppressive burthen upon the
                  treaty-making power—heavy, but not equal, or in the best manner protective. I proposed the 2nd section, as it now stands, as a substitute for the 2nd section of the report of the Committee. I did not think it desirable that, in every imaginable case, the acquisition of territory should be restricted to treaty. I thought that while islands, newly discovered in the ocean—an arctic or
                  antarctic continent—if any such awaits the hardihood and enterprise of our people, might possibly be brought in upon the right of discovery, and upon principles of international law. It was not a thing of impossibility, however, that a country might be so overrun in war as not to leave any competent authorities to treat with. In case of a war with Central America or Mexico, the
                  government might be so completely overthrown as to leave no government to deal with. It is not difficult to imagine a government so shattered, broken and crushed out, as to leave no sufficient authority to contract with. In such a contingency, what disposition <PageNumber num={593} /> could be made of the conquered country, under a Constitution which
                  restricts you absolutely to treaty? I do not think it desirable to annex foreign lands, by a joint resolution of Congress, as was done in the case of Texas. Yet, there might be, under peculiar circumstances, a necessity for such a power. It ought, however, to be carefully guarded. By the section as adopted, it will be necessary in a case of annexation by joint resolution, that a
                  majority of the Senators shall be had from each class of States, and, where the acquisition is made by treaty, that the votes of a majority of the Senators from each class of States shall form a component part of the two-thirds majority now necessary to the ratification. We leave the ratifying power in the hands of two-thirds of the Senators as it is now in the Constitution, provided
                  it be made up of majorities from both sections of the country.
                </p>
                <p className="p-in-sp">
                  You will observe that this will not apply to ordinary commercial treaties. It has no application to treaties of amity, peace and the like. It is only applicable to treaties of acquisition—acquisition of territory. In treaties of this latter class we have good reason and warrant for departure from the rules of ordinary treaties. Restricted as it is to the acquisition of territory in
                  these modes and not applicable to treaties of other character, it seems to me that it is the most perfect security that the South can have. The very first question that arises in the mind, both North and South, when the acquisition of foreign territory is spoken of, is what is to be the condition of that territory in regard to the institution of slavery, after it shall have been
                  brought in? We all know that.
                </p>
                <p className="p-in-sp">We could not carry the division of future territory, as Mr. Crittenden, with Mr. Powell's amendment, proposed.</p>
                <p className="p-in-sp">
                  We could not procure, in advance, equal partition of Territory hereafter to be brought in. We proposed, therefore, to substitute for equal partition of Territory where it could not be had, equality between sections in the right and power of acquisition. Mr. Crittenden's plan proposed a fair and equal partition of Territory hereafter to be acquired, making no change in the mode of
                  acquiring it. We all voted for the Crittenden proposition under the resolutions of the General Assembly. We could not obtain it. We then sought a substitute for this partition in advance—this equal partition of Territory hereafter to be brought in between the Northern and Southern States as an equivalent
                </p>
                <p className="p-in-sp">and that was best attained by equality of power in the acquisition.</p>
                <p className="p-in-sp">If gentlemen will permit their minds to have free action upon this subject, and if they will give it due consideration, I shall have little fear of any coming to the conclusion that this is an objectionable feature.</p>
                <p className="p-in-sp">
                  In the first place, it commends itself to us in this—that it establishes <PageNumber num={594} /> perfect equality between the sections upon this vital sectional question of slavery, where we need it most. It is not merely a concession of equality, but it is a concession to the weaker section, and places that weaker section upon an equal footing with
                  the stronger sections, so far as all future Territories are concerned, both in their procurement and in fixing their condition.
                </p>
                <p className="p-in-sp">
                  If we were all in union again, harmoniously, as one great political family—and no one mourns that it is not so more than I do, and no one is more hopeful than I am, that it will be so yet—there would be 15 slave States to 19 non-slaveholding States. The second section would, in that case, give to every Southern State one-fifteenth of power instead of one-thirty-fourth part of
                  power—the whole number of States being 34. In the present unhappy condition of things, with only 8 slave States to 19 non-slaveholding States, making 27 in all, it gives to every Southern State one-eighth of the power and influence instead of one-twenty-seventh.
                </p>
                <p className="p-in-sp">
                  It is said by some, that territory will never be acquired under this provision. How can slave territory ever be acquired under the report of the Committee, four-fifths of the Senate being necessary to a treaty, or by leaving it where it now is to a two-thirds vote, or under a joint resolution requiring a majority? The power in either case being in the hands of the Northern States.
                </p>
                <p className="p-in-sp">
                  Mr. President, much has been said, from Mr. Calhoun down to the present time, of the guarantees needed by the South touching this peculiar property. I believe there was a resolution of the General Assembly passed after the resolution appointing the Commissioners to Washington which affirmed the belief of that body, that security was only to be had by some self-protecting power of
                  sections. We have heard of dual executives and double legislative chambers. I present this section as the first example, from the foundation of the government to the present moment, as an amendment proposing this self-protecting power of sections. It is the entering wedge; it is the first and only instance in which such a power has ever been obtained or where there was the slightest
                  probability of its obtainment.
                </p>
                <p className="p-in-sp">
                  Now, the virtue of this arrangement will be this : Neither section can acquire territory, either for freedom ( as they call it) or for slavery, without the consent of a majority of the Senators from States of the opposite class. Very well. These Northern gentlemen are looking to acquisition as much as we are, and perhaps more. It was announced in the Conference, by one of the most
                  active and distinguished politicians from the North, that they had their eye upon Canada, and that it would <PageNumber num={595} /> come. They have their eye upon these Northern British possessions, Nova Scotia, New Brunswick, and Canada. They have a tooth for the Sandwich Islands, and a tooth for Southern settlements, if they can get down there in
                  numbers sufficient to enable them to come in hereafter with their own institutions. This section puts a lock upon every such enterprise, as they cannot proceed without the consent of a majority of the Senators from the Southern States. It enables you to do this. It enables you to bargain beforehand. As to Cuba, there is no difficulty in regard to the institution of slavery. The
                  Northern people, as a general proposition, are not unwilling that Cuba should be ours. They are more anxious about it than we are. The great commercial emporiums desire that the Queen of the Antilles shall become a part of our possessions, and to see the flag of the Union floating from the Morro Castle. When Cuba comes in, she will come in as a great slave State. She would only be
                  valuable as a slave State. She will come in by annexation, as a State with her present system of labor, without which her vast wealth and resources could not be developed.
                </p>
                <p className="p-in-sp">
                  But suppose these gentlemen desire a little Northern annexation somewhere, or some Southern acquisition, do you not perceive that it puts it in the power of the South at once to say, "not one foot, unless foot for foot." It enables us to bring in Southern territory and plant Southern institutions upon it as a tribute to the equal power with which this section arms us. It cuts off all
                  the difficulties as to what shall be the condition of a territory after territory comes in, and compels you to settle these in advance, and if not settled in advance, not to bring it in at all. And when any high political necessity shall require that these annexations shall be made, there will always be found the requisite majorities, both by treaty and resolution, to make them.
                </p>
                <p className="p-in-sp">I am free, then, to declare that in my deliberate judgment, these two sections, taken together, make a better disposition of the territorial question, than the Crittenden proposition does--equal in all the securities which it confers; equal in all the rights which it maintains, and better in regard to that which may lie before us in the future.</p>
                <p className="p-in-sp">
                  But I will not detain the Convention longer upon this point. The next or third section combines and embodies, substantially, the second, third and fourth sections of Mr. Crittenden's plan. It prohibits Congress from interfering with the institution of slavery within the States. It denies the power to interfere with slavery in the District of Columbia, except with the consent of
                  Maryland, and with the consent of the owners, or making compensation to such owners as do not consent. It contains, also, a denial of power on the part of Congress to interfere <PageNumber num={596} /> with slavery in places under the jurisdiction of the General Government, and within the limits of the slave States, embracing navy yards, dock yards,
                  forts, arsenals, &amp;c. It prohibits Congress from preventing representatives and others from bringing with them to the District of Columbia, or taking away with them, persons held to service.
                </p>
                <p className="p-in-sp">In an amendment to the Constitution nothing ought to be regarded as small or unimportant, for every amendment to that instrument should be treated with gravity, and considered as of consequence. Now in this last paragraph, there is an enlargement of the Crittenden amendment in this :</p>
                <p className="p-in-sp">"Nor shall Congress at any time prohibit officers of the Federal Government or members of Congress, whose duties require them to be in said District, from bringing with them their slaves and holding them, as such, during the time their duties may require them to remain there, and afterwards taking them from the District."</p>
                <p className="p-in-sp">
                  You perceive that is a limitation of privilege, to Federal officers and members of Congress, to be exercised only while they remain in Washington engaged in the performance of their respective duties. We struck off those restrictions and adopt the following terms instead : "Nor the power to interfere with or prohibit representatives and others from bringing with them to the District
                  of Columbia, retaining and taking away persons so held to labor or service."
                </p>
                <p className="p-in-sp">The privilege is not limited to the case of federal officers or representatives, or restricted to the period of the performance of their duties, but all persons have the right to take their slaves with them to the District of Columbia, and to remain as long as they desire.</p>
                <p className="p-in-sp">The third section, continuing its prohibitions upon the powers of Congress, declares :</p>
                <p className="p-in-sp">"Nor the power to prohibit the removal or transportation of persons held to labor or involuntary service in any State or Territory of the United States, to any other State or Territory."</p>
                <p className="p-in-sp">Here is another announcement of the same right contained in the first section; the right of transfer and migration from any State or territory where slavery is recognized, not only to any other State where it is recognized, but to any territory—a double cumulative recognition of the same right to take slaves to a territory where that condition is recognized :</p>
                <p className="p-in-sp">
                  "Nor the power to prohibit the removal or transportation of persons held to labor or involuntary service in any State or Territory of the United States to any other State or Territory thereof where it is established or recognized by law or usage; and the right during transportation, <PageNumber num={597} /> by sea or river, of touching at ports, shores
                  and landings, and of landing in case of distress."
                </p>
                <p className="p-in-sp">I will remark that here is a valuable addition to Mr. Crittenden's plan. Mr. Crittenden's proposition is this :</p>
                <p className="p-in-sp">"Congress shall have no power to prohibit or hinder the transportation of slaves from one State to another, or to a Territory in which slaves are by law permitted to be held, whether that transportation be by land, navigable rivers, or by the sea."</p>
                <p className="p-in-sp">It will be seen that this provision being the 4th article of Mr. Crittenden's pro jet, simply affirms the right of transportation with slaves from one State to another, or a Territory in which slaves are by law permitted to be held. The additional provision found in the Peace Conference adjustment, is in these words :</p>
                <p className="p-in-sp">"And the right during transportation by sea or river, of touching at ports, shores and landings, and of landing in case of distress."</p>
                <p className="p-in-sp">There is no provision in the Crittenden proposition for touching at landings, shores and ports, and no provision whatsoever as to landing cargo, in case of distress.</p>
                <p className="p-in-sp">We all believe it an important object to secure the recognition of these rights, and which are over and beyond what is contained in the pro jet, which the General Assembly said would be acceptable to the people of this Commonwealth.</p>
                <p className="p-in-sp">I will read the whole of the clause in the Peace Conference report upon this subject of transit :</p>
                <p className="p-in-sp">"And the right during transportation by sea or river, of touching at ports, shores and landings, and of landing in case of distress, shall exist, but not the right of transit in or through any State or Territory, or of sale and traffic against the laws thereof."</p>
                <p className="p-in-sp">This last is a modification of the transit privilege contained in Mr. Crittenden's plan.</p>
                <p className="p-in-sp">By the Peace Conference proposition you have the full privilege of transportation by sea and rivers, with the privilege of touching at landings, ports and shores, and of landing cargoes in distress; but you are not to travel by land with your slaves through States, or traffic in slaves in States, against the laws of said States.</p>
                <p className="p-in-sp">
                  Now, Mr. President, one great object we have all professed, and which I trust we all have at heart, is to make such a settlement of these slavery issues, which so much disturb the peace of the country, as shall remove and extinguish them forever. It ought to be done in such a mode as to leave as few irritating sources of difficulty open as practicable. Now, to pass through the
                  interior of non-slaveholding States <PageNumber num={598} /> by railway, turnpike and other avenues of travel and transportation, with slaves, either for sale or for personal use, subject to the prejudices of an anti-slavery population, might be regarded of doubtful expediency and propriety, if the right of interior transportation were fully secured.
                  The exercise of such right might serve to keep in existence, in greater or less degree, irritations which are hurtful to our interests, and which should be suppressed.
                </p>
                <p className="p-in-sp">But again, this provision only inhibits this right of transit through the States or Territories where it is prohibited by the law thereof. Those of us who have any claim to belong to the States Rights school—I have always, myself, claimed to be a '98 and '99 man [laughter] , and I have always received the doctrines of that day as interpreted by their author, Mr. Madison—might well hesitate to confer upon the General Government, by an amendment of the Constitution, a power to override the law of a State in regard to internal police regulations.</p>
                <p className="p-in-sp">
                  Suppose the right of transit through the State of Virginia attempted to be given to all manner of population from the North, including free negroes. Does any one doubt our power to arrest by statute, the passage as well as the settlement of such obnoxious population? Here, slaves are property, and as such are subjects of commerce and trade. But they are also persons, and are looked
                  to by our laws in their double character. This section gives the right of transit through States, unless the right be opposed by a law of the State. Without necessarily conceding the right of the State to interpose its negative, it withdraws from the conflict of jurisdiction, in the event it shall be presented.
                </p>
                <p className="p-in-sp">The next paragraph of this section which I am now considering was regarded as one of great value. It is not found in the Crittenden proposition. It might some day prove to be of infinite importance to the slaveholding States. It is this : "Nor shall Congress have power to authorize any higher rate of taxation on persons held to labor or service, than on land."</p>
                <p className="p-in-sp">
                  It will be remembered that the Constitution of the United States provides that Congress shall have power "to lay and collect taxes, duties, imposts and excises, to pay the debts and provide for the common defence," &amp;c. And while it provides that direct taxes are to be apportioned among the several States according to numbers, like representation, yet there is nothing in the
                  Constitution which forbids to Congress, in laying taxes, the power to select the subjects of taxation. We know that during the war of 1812, when impost duties were cut off and direct taxes became necessary, direct taxes were levied.
                </p>
                <p className="p-in-sp">
                  We are called upon, Mr. President, at this time to act not only for <PageNumber num={599} /> the present but for the future, to look round, to take the widest range of the horizon that our mental vision renders us capable of taking. Might there not come a time, when impost duties being cut off, either by war or by other causes, and a necessity having
                  arisen for levying taxes for the support of Government—that a hostile, unfriendly legislation might display itself, to the detriment if not the destruction of the rights of the Southern people in the form of unjust discriminating taxation on slave property?
                </p>
                <p className="p-in-sp">
                  Sir, you have a Republican Congress in power at present. Unfortunately, by the withdrawal of our impetuous sisters of the South—would to God they had remained with us, and then I believe we could have successfully resisted all wrong, and amicably settled all our difficulties—but by the withdrawal of our sister States of the South, the powers of the Government have been thrown into
                  the hands of a Republican majority. The Senate of the United States is in their power, and I believe also the House of Representatives. Suppose, then, Mr. President, in the first place, the necessity for direct taxation arises, and an inclination to make war upon the institution of slavery, should manifest itself in the form of excessive taxes upon slaves, might not the paragraph of
                  the third article now under examination, prove the shield and salvation of the slaveholding States?
                </p>
                <div className="stage it">[Ex. Gov. WISE here expressed the opinion that under the Constitution as it is, there could be no unjust discriminations against slave property in levying taxes—that taxes were required to be uniform. He referred to cases decided, in the Supreme Court, in which this question had been examined.]</div>
                <div className="speaker" id="sp613"><span className="persName">Mr. SUMMERS</span>—</div>
                <p className="p-in-sp">I am not prepared to speak of the cases referred to at this time, not having examined them. I think my friend from Princess Anne [ex-Gov. WISE] 
                  , will find himself mistaken in the application of the cases cited, to the point of security presented by the amendment. I will hear his views with great pleasure. The Constitution confers both the power to apportion direct taxes among the several States according to numbers, and to "levy and collect taxes." While it is expressly required that "duties, imposts and excises shall be
                  uniform throughout the United States," nothing is said of the uniformity of "taxes," though mentioned in another part of the same paragraph in connection with "duties, imposts and excises." I might concede that all property which is taxed at all, must be taxed at the same rate in one State with the same property in other States. But I know nothing either in the Constitution or in the
                  law which restricts Congress in the selection of the <PageNumber num={600} /> subjects of taxation, or which requires that taxes shall be according to value.
                </p>
                <p className="p-in-sp">I regard this as a most valuable provision. And, Mr. President, it is valuable not only for the security which it affords us against an unjust use of the taxing power of the General Government, but valuable in another aspect. We often hear that there should be some recognition of property in slaves, and of slaves as property.</p>
                <p className="p-in-sp">
                  I ask, gentlemen, whether they could have a fairer or more perfect recognition of property in slaves, or of slaves as property than this section itself affords? That Congress shall not "have power to authorize any higher rate of taxation on persons held to labor or service than on land." Subjects of taxation—"persons held to service." They are there treated as property. They are made
                  subject to valuation equally with good land; reduced to an ad valorem taxation, dollar for dollar with land. "On persons held to labor or service than on land ;" a direct and ample recognition of the "person held to service" as a thing of property, and as such a subject of taxation, and in the form of taxation, reduced to a valuation side by side with other property.
                </p>
                <p className="p-in-sp">
                  Another recognition of the same character will be found in the seventh section, where it is declared, that "Congress shall provide, by law, that the United States shall pay to the owner the full value of his fugitive from labor," &amp;c. Thus it is provided, that in case of the impossibility to return a fugitive from labor "the United States shall pay to the owner." That word "owner"
                  was never obtained before. "The person to whom such service is due," is the way in which the Constitution describes the master; but here it is provided that the "owner" shall receive the full value of his fugitive from the public Treasury. Here are two recognitions of property in one breath : First, the slave, as a fugitive, is the property of his master—the "owner" is to be paid for
                  "his fugitive;" and, second, the slave, as a subject of value, is admitted to be property—the "United States shall pay to the owner the full value of his fugitive." He is to be valued as property, and the value of this property paid to the master. Could there be a more complete admission of the rights of the master, and of his property in the slave?
                </p>
                <p className="p-in-sp">The last paragraph of the third section prohibits the bringing of slaves into the District of Columbia for sale, or for transportation thence as merchandise to other places. It merely carries out the law of 1850, now in force.</p>
                <p className="p-in-sp">The fourth section—a short one—is in these words :</p>
                <p className="p-in-sp">
                  "The third paragraph of the second section of the fourth article of the Constitution shall not be construed to prevent any of the States, by <PageNumber num={601} /> appropriate legislation, and through the action of their judicial and ministerial officers, from enforcing the delivery of fugitives from labor to the person to whom such service or labor
                  is due.
                </p>
                <p className="p-in-sp">
                  This section, Mr. President, is not found in Mr. Crittenden's propositions. It was introduced to meet the decision of the Supreme Court of the United States in the case of Edward Prigg vs. the Commonwealth of Pennsylvania—a case reported in the sixteenth volume of Peters' Reports, and familiar to the profession. In that case, Judge Story with a majority of the court, held that the
                  State law of Pennsylvania was unconstitutional. They held that the provision in the Constitution of the United States for the capture and rendition of fugitives from labor, was to be carried out and enforced, under the legislation of Congress, and not by State legislation.
                </p>
                <p className="p-in-sp">
                  The court held that the act of Congress, passed on the 12th day of February, 1793, relative to fugitives from labor, as well as fugitives from justice, was constitutional. The court was further of opinion, that the provisions of that act, covered both subjects, embraced by it, not that they exhausted the remedies which might be applied by Congress, to enforce the rights, if these
                  provisions should be found in practice inadequate, but because the act contained all the remedies which Congress, up to that time, had deemed expedient. They pronounced the act of the Legislature of Pennsylvania to be unconstitutional, and decided that it was not competent for a State to legislate on the subject. That the Federal Government must execute the law, without either the
                  aid or the obstruction of the State authorities.
                </p>
                <p className="p-in-sp">
                  On that occasion Chief Justice Taney, Mr. Justice Thompson and Mr. Justice Daniel, of our own State, delivered dissenting opinions, in which they held that the State Legislatures were not prohibited by anything in the Constitution from appropriate legislation in aid of the execution of that provision of the organic law, and that it might be their duty in many cases so to act. That
                  the right of Congress to legislate was not an exclusive right.
                </p>
                <p className="p-in-sp">
                  It has been announced, indeed, from very high authority in the South, that the act of 1850 for the reclamation and rendition of fugitive slaves, is itself, unconstitutional, and that the whole duty of providing for the reclamation and rendition of fugitive slaves belongs to the State Governments, and not to the General Government at all. Without expressing any opinion upon this point
                  it is certain that the entire Southern mind has concurred in the dissenting opinions of Chief Justice Taney and the judges who concurred with him. It has been said
                </p>
                <p className="p-in-sp">
                  and it is historically true—that it was the rendering of that decision <PageNumber num={602} /> of Justice Story and a majority of his colleagues, that gave the first impulse to the passage of laws unfriendly to the execution of the fugitive slave act. Soon after the decision of the Supreme Court was rendered, the Legislature of Pennsylvania enacted a
                  law by which the use of her jails was denied as a place for the confinement of fugitive slaves. We thought it was our duty to propose this section as harmonizing the duty of the State, and of the General Government, and opening a door for friendly co-operation.
                </p>
                <p>Without concluding his speech, <span className="persName">Mr. SUMMERS</span> gave way for a motion to adjourn.</p>
              </div>
              <div className="section" id="vsc1965.v1.2.22.6">
                <h3><span className="headingNumber">1.22.6. </span><span className="head">CLAIMS AGAINST THE CONVENTION</span></h3>
                <div className="speaker" id="sp614"><span className="persName">Mr. MACFARLAND</span>—</div>
                <p className="p-in-sp">I understand that there are some small claims against the Convention for services rendered before it was organized. I submit this resolution for the purpose of disposing of them :</p>
                <p>Resolved, That a Committee of Three be appointed to audit claims against the Convention for services before its organization, and to report thereon.</p>
                <p>The resolution was adopted.</p>
              </div>
              <div className="section" id="vsc1965.v1.2.22.7">
                <h3><span className="headingNumber">1.22.7. </span><span className="head">MINORITY REPORT FROM THE COMMITTEE ON FEDERAL RELATIONS</span></h3>
                <div className="speaker" id="sp615"><span className="persName">Mr. BALDWIN</span>, of Augusta—</div>
                <p className="p-in-sp">Mr. President : I desire to present a minority report from the Committee on Federal relations, expressing my views upon the subjects referred to that Committee.</p>
                <p className="p-in-sp">I ask that the report be laid upon the table and printed.</p>
                <p>The Convention so ordered.</p>
                <p>The following is the report :</p>
                <div className="floatingText">
                  <div className="floatingText_body">
                    <p>The representatives of the people of Virginia, in Convention assembled, are profoundly sensible of the difficulty, delicacy and importance of the duty which, in obedience to the popular will, they have assumed to perform.</p>
                    <p>They feel that the controversy which, unfortunately, distracts and divides our country, has brought about a condition of public affairs for which history has no parallel, and the experience of government no precedent.</p>
                    <p>They recognize the fact that the great questions which press for consideration are of entire novelty and of great intrinsic difficulty, and that their proper solution will require, on the part of our Government, State and Federal, and of our people, the exercise of the utmost prudence, discretion, calmness and forbearance.</p>
                    <p>
                      They concur, most earnestly, in the opinion declared by the General <PageNumber num={603} /> Assembly of Virginia, that a permanent dissolution of the Union is inevitable, unless our unhappy controversies can be adjusted in the spirit in which the Constitution was originally formed, and they feel that to perfect any such adjustment, it is of
                      indispensable necessity that, during its progress, the peace of the country shall be maintained, and that all parties shall, in good faith, avoid giving just occasion for irritation, collision and bloodshed.
                    </p>
                    <p>Therefore, be it resolved, by the people of Virginia, in Convention assembled :</p>
                    <p>
                      1. The conference of States, which, upon the invitation of Virginia, was recently held in the city of Washington, having recommended certain amendments to the Constitution of the United States, as in the opinion of the Conference, contain a fair and satisfactory adjustment of existing difficulties, so far as they can be reached by changes in the original law—this Convention is of
                      opinion that the said amendments, if concurred in by the States hereinafter invited to Conference, and engrafted upon the Constitution of the United States, will be satisfactory to the people of Virginia.
                    </p>
                    <p>
                      2. The peculiar relations of the States of Delaware, Maryland, Virginia, North Carolina, Tennessee, Kentucky, Missouri and Arkansas, to each other, and to the other States, make it proper, in the judgment of this Convention, that the former States should consult together for the maintenance of their rights in the Union, or, failing in that, to concert such measures for their
                      final action as the honor, the interests and the safety of their people may demand; and for that purpose, the proper authorities of those States are requested to appoint Commissioners, to meet Commissioners appointed by this Convention, on behalf of the people of this State, at Frankfort, in the State of Kentucky, on the last Monday in May next.
                    </p>
                    <p>
                      3. The people of Virginia will not anticipate any disposition, on the part of the General Government, to engage in the hopeless effort to subject the Government and people of seven States, against their will, to federal authority. Any such attempt would inevitably result in civil war—soon to become a sectional war against the institutions and people of fifteen States of this
                      Union. The people of Virginia cannot be inattentive or indifferent to any indications of such a policy, but they trust that the Government will take the wiser course, and will, in accordance with the spirit of our institutions, withdraw all irritating display of force, and seek to disarm freemen by removing the causes of their just complaints.
                    </p>
                    <p>
                      4. The grievances for which several of the States have undertaken <PageNumber num={604} /> to withdraw from the Union, are such as have affected Virginia to a greater extent than any of them. For these grievances she requires, and expects to obtain full redress, and she will be slow to believe that those States will find it consistent with their
                      inclinations or their interests to abandon permanently a Union in which they are offered terms of adjustment, which, in respect to common interest and common grievances, satisfy the judgment and the honor of Virginia. In any event, as Virginia is endeavoring to secure to them the opportunity to determine their destiny in peace, she can look with no favor or sympathy upon any
                      conduct on their part tending to precipitate upon her people the horrors of civil war.
                    </p>
                    <p>
                      5. It is the belief of this Convention that the sectional controversies which divide the people of the United States, if not originated, have been greatly aggravated by the management of politicians for the advancement of personal and party schemes. They feel confident that if the people, North and South, can come to understand each other, they will find means to compel a fair
                      and amicable settlement of all the matters in dispute. It is, therefore, recommended that the Conference at Frankfort shall take into consideration the propriety of a direct appeal to the people of the North in favor of justice, union and peace.
                    </p>
                    <p>
                      6. The federal authorities under the Constitution as it is, having disclaimed the power to recognise the withdrawal of any State from the Union, or to deal with the grave questions arising upon such withdrawal, the people of Virginia, without expressing any opinion upon the question of power, but in deference to the opinion of the federal authorities, hereby declare their
                      willingness to unite in conferring upon the Government of the United States the power, if it shall become necessary, to recognise the separate independence of the seceding States, to make such treaties with them, and to pass such laws as the separation may render proper.
                    </p>
                    <p>In order to await the action of the Frankfort Conference, this Convention will adjourn to meet again on the day of 1861.</p>
                  </div>
                </div>
                <p>PUBLICATION OF DEBATES</p>
                <div className="speaker" id="sp616"><span className="persName">Mr. WICKHAM</span>—</div>
                <p className="p-in-sp">Mr. President, I desire to present the report of the committee to whom was referred the contract for printing the debates. It is very short, and I will read it for the information of the Convention.</p>
                <p>The report was read and laid upon the table, on motion of Mr. WICKHAM.</p>
                <p>It reads as follows :</p>
                <PageNumber num={605} />
                <div className="floatingText">
                  <div className="floatingText_body">
                    <p>
                      The Committee to whom was referred the contract with the proprietors of the "Enquirer" for reporting and publishing the debates of the Convention, report that they have had the subjoined correspondence with the Auditor of Public Accounts from which it will be seen that, that officer, in auditing the accounts connected with this contract, allows the proprietors of the "Enquirer"
                      payment for "the proportion borne by the paper which contained the debates to the whole paper published," and not for the "whole paper," and that Mr. Tyler assents to this construction.
                    </p>
                    <p>Your Committee regard this as the point in the contract to which their attention was to be particularly directed. Approving the construction placed upon this branch of the contract by the Auditor, the Committee ask to be discharged from the further consideration of the subject. WMS. C. WICKHAM, Chen.</p>
                  </div>
                </div>
                <p>The following is the correspondence referred to in the report :</p>
                <div className="floatingText">
                  <div className="floatingText_body">
                    <p>RICHMOND, March 9, 1861.</p>
                    <p>
                      DEAR SIR—The Committee to whom the contract with the proprietors of the Enquirer, for reporting and publishing the debates of the Convention, was referred, desire me to enquire of you as to your construction of that part of the contract which relates to the payment for paper; and in what manner you make your calculations in auditing that portion of the accounts. You will oblige
                      the Committee by replying in detail, that we may lay the facts before the Convention.
                    </p>
                    <p>Your obedient servant</p>
                    <p>WMs. C. WICKHAM, Chairman.</p>
                    <p>J. M. BENNETT, ESQ.,</p>
                    <p>Auditor of Public Accounts.</p>
                    <p>AUDITOR'S OFFICE,</p>
                    <p>RICHMOND, Va., March 9, 1861.</p>
                    <p>Wms. C. WICKHAM, Esq., Chairman, &amp;c.,</p>
                    <p>
                      Dear Sir : Your communication of this date, making inquiry as to the construction which is given to the contract of the Convention with the proprietors of the Enquirer, for publishing the debates, in respect to the paper to be furnished, and asking for the manner of making my calculations therefor, is before me. I reply, I have only to say that my attention having been heretofore
                      called to the subject, and, having examined the contract with a view to its construction, I had no hesitation in giving my opinion that the proportion borne by the paper which contained the debates, to the whole paper published, was the <PageNumber num={606} /> paper to be paid for under the contract, and not the whole paper. My calculations have
                      been made upon this construction, to which Mr. Tyler assents.
                    </p>
                    <p>I am, very respectfully,</p>
                    <p>Your obedient servant,</p>
                    <p>J. M. BENNETT, Auditor.</p>
                  </div>
                </div>
                <p>ANOTHER MINORITY REPORT FROM THE COMMITTEE ON</p>
                <p>FEDERAL RELATIONS</p>
                <div className="speaker" id="sp617"><span className="persName">Mr. WICKHAM</span>—</div>
                <p className="p-in-sp">I will avail myself of this occasion [the presentation of the report on the publication of the debates] , to lay before the Convention, a substitute for the report of the Committee on Federal Relations.</p>
                <p>Substitute proposed by <span className="persName">Mr. WICKHAM</span>, for the report of the Committee on Federal Relations :</p>
                <div className="floatingText">
                  <div className="floatingText_body">
                    <p>"The representatives of the people of Virginia in Convention assembled, are profoundly sensible of the difficulty, delicacy and importance of the duty which, in obedience to the popular will they have assumed to perform;</p>
                    <p>"They feel that the controversy which unfortunately distracts and divides our country has brought about a condition of public affairs for which history has no parallel and the experience of Governments no precedent;</p>
                    <p>"They recognize the fact that the great questions which press for consideration are of entire novelty and of great intrinsic difficulty, and that their proper solution will require, on the part of our Governments, State and Federal, and of our people the exercise of the utmost prudence, discretion, calmness and forbearance.</p>
                    <p>"Above all other things at this time they esteem it of indispensable necessity to maintain the peace of the country, and to avoid everything calculated or tending to produce collision and bloodshed.</p>
                    <p>
                      "The grievances for which several of the States have withdrawn from the Union and overthrown the Federal Government within their limits, are such as have affected the people of Virginia to a greater extent than any of the seceded States, and it is their determined purpose to require such guarantees for the protection of the rights of the people of the slaveholding States as in
                      the judgment of Virginia will be sufficient for the accomplishment of that object.
                    </p>
                    <p>
                      "Deeply deploring the present distracted condition of the country, and lamenting the wrongs that have impelled some of the States to cast off obedience to the Federal Government, but sensible of the <PageNumber num={607} /> blessings of Union, and impressed with its importance to the peace, prosperity and progress of the people, we indulge the hope,
                      that an adjustment may be reached by which the Union may be preserved in its integrity, and peace, prosperity and fraternal feelings be restored throughout the land. Virginia having initiated measures to obtain such guarantees, a proper self-respect impels her to demand of all the parties that they shall refrain, during the pendency of her efforts for amicable adjustment, from
                      all action tending to produce a collision of forces therefore,
                    </p>
                    <p>
                      "Resolved, That the people of Virginia are under existing circumstances unalterably opposed to the exercise of any species of force on the part of the Federal Government towards the States that have withdrawn from the Union, and believing that any armed collision between the Federal authorities and those of the seceded States would render utterly futile the efforts in which
                      Virginia is engaged, to reconcile the differences now existing between the States, and would cause the irrevocable dissolution of the Union, they earnestly insist that the Federal Government shall adopt a pacific policy towards those States; shall make no attempt to subject them to Federal authority or re-inforce the forts now in possession of the military forces of the United
                      States, or to re-capture the forts, arsenals or other property of the United States within their limits, nor to resort to any measures calculated in the present excited state of feeling to provoke hostile collision; and on the other hand they invoke the seceded States to abstain from any act tending to produce such collision between them and the Federal Government.
                    </p>
                    <p>
                      "Resolved, That the peculiar relations of the States of Delaware, Maryland, Virginia, North Carolina, Tennessee, Kentucky, Missouri and Arkansas to the other States, make it proper, in the judgment of this Convention, that the former States should consult together and concert such measures for their final action as the honor, the interests and the safety of the people thereof may
                      demand, and for that purpose the proper authorities of those States are requested to appoint commissioners to meet commissioners to be appointed by this Convention on behalf of the people of this State, at Frankfort, in the State of Kentucky, and the last Monday in May next.
                    </p>
                    <p>
                      "Resolved, That whilst we desire to confer with the States mentioned in the preceding resolution, upon this, as upon all other matters connected with our national troubles; yet we deem it proper to declare that we regard the propositions agreed upon by the Convention recently in session in the city of Washington, known as the 'Peace Congress,' as affording, if adopted as
                      amendments to the Constitution, a fair, proper <PageNumber num={608} /> and honorable basis of adjustment of all our national difficulties.
                    </p>
                    <p>
                      "Resolved, That the people of Virginia, confiding in the justice of the people of her sister States, appeals to them for a satisfactory adjustment of the existing difficulties in our Federal Relations. Virginia, therefore, invites the people of the several States, either by popular vote or in Conventions, similar to her own, to respond, at their earliest convenience, to the
                      positions assumed in the foregoing resolutions. She cannot regard a failure to obtain such adjustment in any other light than as a final overthrow of the Union of these States."
                    </p>
                  </div>
                </div>
                <p>EARLY MEETING OF THE CONVENTION</p>
                <div className="speaker" id="sp618"><span className="persName">Mr. GRANT</span>—</div>
                <p className="p-in-sp">I beg leave to offer the following resolution :</p>
                <p>Resolved, That hereafter the Convention will meet at the hour of 11 o'clock instead of the usual hour.</p>
                <div className="speaker" id="sp619"><span className="persName">Mr. S. G. STAPLES</span>—</div>
                <p className="p-in-sp">I move to lay that resolution on the table.</p>
                <p>The motion was agreed to, and then, upon the motion of <span className="persName">Mr. STAPLES</span>, the Convention adjourned until 12 o'clock to-morrow.</p>
              </div>
            </div>
            <div className="day" id="vsc1965.v1.2.23">
              <PageNumber num={609} />
              <h2><span className="headingNumber">1.23. </span><span className="head">TWENTY-THIRD DAY</span></h2>
              <div className="dateline">Tuesday, <span className="date">March 12</span></div>
              <p>The Convention met at 12 o'clock. Prayer by the Rev. <span className="persName">Mr. PETERKIN</span>, of the Episcopal Church.</p>
              <div className="section" id="vsc1965.v1.2.23.1">
                <h3><span className="headingNumber">1.23.1. </span><span className="head">PUBLIC MEETING IN BEDFORD</span></h3>
                <div className="speaker" id="sp620"><span className="persName">Mr. GOGGIN</span>—</div>
                <p className="p-in-sp">I desire, with the consent of the gentleman from Kanawha [<span className="persName">Mr. SUMMERS</span>, who was entitled to the floor] , to present the proceedings of a meeting of a portion of the citizens of Bedford, held at the Court-house of the county, on the 6th instant, and, also, of a meeting of another portion of the citizens of the same county, holden at Emmaus, on the 8th instant, in reference to subjects now engaging the attention of this Convention :</p>
                <div className="floatingText">
                  <div className="floatingText_body">
                    <p>At a meeting of a portion of the citizens of Bedford county, held at the Court-house, on the 6th day of March, 1861.</p>
                    <p>On motion of Wm. V. Jordan, Esq., Major Cornelius Pate was called to the chair, and Jos. B. Layne, Esq., appointed secretary.</p>
                    <p>The object of the meeting having been explained by John R. Thurman, Esq., on motion, it was</p>
                    <p>Resolved, That a committee of seven be appointed to draft resolutions for the consideration of the meeting.</p>
                    <p>Whereupon the following gentlemen were appointed a committee; John R. Thurman, James M. Carter, Wilson Wheeler, Dr. Robert A. Clement, Dr. Thomas M. Page, Joseph B. Layne and Wm. V. Jordan, who, after a short retirement, reported the following preamble and resolutions, which were adopted :</p>
                    <p>Whereas, every effort to secure the just rights of the South has failed, and a Black Republican President has been inaugurated upon principles hostile to the equality of the States, who declares his purpose of coercing such States as refuse submission to his rule; therefore, be it</p>
                    <p>Resolved, That we will resist any and every attempt at coercion, and respectfully request our delegates in the Convention to use every means in their power to dissolve the connection of Virginia with the Federal Government.</p>
                    <p>On motion, it was</p>
                    <p>Resolved, That these proceedings be published in the county papers, and a copy thereof sent to our delegates in the State Convention.</p>
                    <p>On motion, the meeting adjourned.</p>
                    <p>CORNELIUS PATE, Chairman.</p>
                    <p>Jos. B. LAYNE, Secretary.</p>
                  </div>
                </div>
                <PageNumber num={610} />
                <div className="speaker" id="sp621"><span className="persName">Mr. GOGGIN</span>—</div>
                <p className="p-in-sp">
                  I will now submit the proceedings<span className="note" id="Note67"><span className="noteLabel">1</span>The Convention Journal gives the chairman's name as S.P.A. Moorman, and the name of the speaker in the second paragraph below as T. H. Nauer.</span> of the second meeting, to which I referred :
                </p>
                <div className="floatingText">
                  <div className="floatingText_body">
                    <p>At a meeting of a portion of the citizens of the county of Bedford, State of Virginia, held at Emmaus, in said county, on Friday, the 8th inst., Capt. S. P. R. Moorman was called to the Chair, and A. M. Whitten appointed Secretary.</p>
                    <p>The object of the meeting was explained by the Chair in a few appropriate and patriotic remarks, which were also sustained by Messrs. <span className="persName">T. H</span>. Naner, N. W. Robertson, A. M. Whitten, Wesley Peters and others.</p>
                    <p>The committee appointed to draft resolutions, reported the following, with the accompanying preamble, which were adopted without a dissenting voice :</p>
                    <p>Whereas, every effort to secure the just rights of the South has failed, and a Black Republican has been inaugurated upon principles hostile to the South, who declares his purpose of coercing such States as refuse submission to his rule, therefore, be it</p>
                    <p>Resolved, That we will promptly resist every, and by all of the means in our power, any attempt to coercion; that our rights as freemen and citizens of Virginia are now imperiled by delay; therefore, we earnestly request our delegates in the Convention to use every means in their power to dissolve the present odious connection of Virginia with the Federal Government.</p>
                    <p>Resolved, That the proceedings of this meeting be published in the county papers, and a copy thereof be sent to our delegates in the State Convention.</p>
                    <p>On motion, the meeting adjourned.</p>
                    <p>S. P. R. MOORMAN, Chairman.</p>
                    <p>A. M. WHITTEN, Secretary.</p>
                  </div>
                </div>
                <div className="speaker" id="sp622"><span className="persName">Mr. GOGGIN</span>—</div>
                <p className="p-in-sp">I merely desire to say, at this time, that the persons who seem to have been acting as the officers of both the meetings were gentlemen of the highest respectability; as were those also who seem to have participated in the deliberations of the meetings. I would ask that the proceedings be read, and that they be referred to the Committee on Federal Relations.</p>
                <p><span className="persName">Mr. GOGGIN</span> read the resolutions and proceedings of both meetings, and they were referred accordingly.</p>
              </div>
              <div className="section" id="vsc1965.v1.2.23.2">
                <h3><span className="headingNumber">1.23.2. </span><span className="head">PUBLIC MEETING IN SMYTH COUNTY</span></h3>
                <div className="speaker" id="sp623"><span className="persName">Mr. SHEFFEY</span>—</div>
                <p className="p-in-sp">
                  I will take occasion to present the proceedings<span className="note" id="Note68"
                  ><span className="noteLabel">2</span>The Convention Journal consistently gives the chairman's name as Adam Rosenbam, while in the Enquirer the name occurs once as Rosenbam and once as Rosenham. In the Convention Journal, the secretary's name is given as D. D. Hull, and the committee member is called James H. Gilmore.</span
                  >
                  of a meeting held in Smyth county, in conformity with a request contained in these proceedings. They are as follows:
                </p>
                <PageNumber num={611} />
                <div className="floatingText">
                  <div className="floatingText_body">
                    <p>At a large and respectable meeting of a portion of the citizens of Smyth county, held at Marion, pursuant to notice on Saturday, March 9th, 1861, on motion, Adam Rosenbam was called to the Chair, and George W. Henderlite and D. D. Hall appointed Secretaries.</p>
                    <p>On motion, the Chair appointed William Farmer, W. F. Hurst, W. D. Strother, E. L. Watson and E. A. Scott a Committee to draft resolutions expressive of the sense of this meeting.</p>
                    <p>On motion, James H. Gilman was added to the Committee.</p>
                    <p>On motion the meeting took a recess until 2 o'clock, P. M., to allow the said Committee time to report.</p>
                    <p>At 2 o'clock, P. M., the meeting was again called to order, when the Committee submitted the following preamble and resolutions:</p>
                    <p>
                      Whereas, Abraham Lincoln has clearly indicated in his inaugural address that he intends to make the attempt to coerce the Southern States that have seceded by holding, occupying and possessing the property and places claimed by the Government in these States, and whereas the citizens of Virginia stand ready, at all times, to resist such attempt at all hazards, and to cast in
                      their fortunes in such an event with their sister States of the South, and believing that the time has come when they must either take their stand with the States of the Northern portion of the Confederacy or with the Confederate States of the South, therefore it is
                    </p>
                    <p>Resolved, 1st. That the honor, the duty and the interest of Virginia imperatively demand that she should immediately resume all her rightful sovereignty and stand prepared for war.</p>
                    <p>
                      Resolved, 2d. That the time for adjustment in the Union has passed; that we regard it the imperative duty of the Virginia Convention now to pass an ordinance of secession without further consultation or cooperation with other States, and that a general Convention of the border slave States will be only productive of a delay that may be fatal to the best interests of our people.
                    </p>
                    <p>Resolved, 3d. That any guarantee which will fail to bring back the seceded States ought not to be accepted by Virginia.</p>
                    <p>Resolved, 4th. That our highest respect is due to South Carolina, and the rest of the seceded States. We honor them for their wisdom, their decision, boldness, and their humane forbearance.</p>
                    <p>Resolved, 5th. That we regard any attempt to interfere with the present system of taxation upon slave property at this crisis as ill-timed and ill-advised.</p>
                    <p>Resolved, 6th. That to Virginia, if she properly regards her honor and her plighted faith, we owe everlasting fealty, and to her and the rest of <PageNumber num={612} /> the gallant South, we pledge "our lives, our fortunes, and our sacred honor."</p>
                    <p>Resolved, 7th. That we do not regard these resolutions as necessarily instructions to our delegate in Convention, for we feel confident from his course so far, that his action will correspond thereto.</p>
                    <p>Resolved, 8th. That a copy of the foregoing preamble and resolutions be forwarded immediately to our delegate in Convention, with the request that he will lay them before that body.</p>
                    <p>After several spirited and patriotic addresses, the said preamble and resolutions were, on motion, unanimously adopted.</p>
                    <p>On motion, Resolved, that these proceedings be forwarded to the "Marion Visitor" and Richmond papers for publication.</p>
                    <p>On motion the meeting adjourned sine die.</p>
                    <p>ADAM ROSENHAM, Chairman.</p>
                    <p>G. W. HENDERLITE, D. D. HALL, Secretaries.</p>
                  </div>
                </div>
                <div className="speaker" id="sp624"><span className="persName">Mr. SHEFFEY</span>—</div>
                <p className="p-in-sp">I move that these proceedings be referred to the Committee on Federal Relations.</p>
                <p>The motion was agreed to.</p>
              </div>
              <div className="section" id="vsc1965.v1.2.23.3">
                <h3><span className="headingNumber">1.23.3. </span><span className="head">CLAIMS AGAINST THE CONVENTION</span></h3>
                <div className="speaker" id="sp625"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">I will now announce the Committee ordered to be appointed on yesterday, to examine claims for services rendered to the Convention before its organization:</p>
                <p className="p-in-sp">Messrs. <span className="persName">MACFARLAND</span>, <span className="persName">COX</span>, of Chesterfield, and <span className="persName">LEAKE</span>.</p>
                <div className="speaker" id="sp626"><span className="persName">Mr. TARR</span>—</div>
                <p className="p-in-sp">Mr. President, with the consent of the gentleman from Kanawha [<span className="persName">Mr. SUMMERS</span>] , I beg leave to offer the following resolution, which I will move to be referred to the Committee on Federal Relations :</p>
                <p>
                  Whereas, a number of Southern States of this Union having seceded therefrom, and an attempt to re-take the forts and other property of the United States, now in possession of said States, by the Federal Government in the present critical condition of the country, would, it is believed, seriously endanger the peaceful relations now existing between the remaining Southern States and
                  the General Government itself ; and will tend to, if it does not produce civil war; therefore,
                </p>
                <p>
                  Resolved, That this Convention, to avoid a conflict and restore harmony between the Federal Government and the seceding States, would earnestly recommend, both to the said Government and the said States, that the relations now subsisting between them should be maintained, with the hope that an amicable adjustment can be effected, and that
                  <PageNumber num={613} /> the Federal Government, with a view to the attainment of that object, although the right unquestionably exists, should not attempt forcibly to re-take the forts, arsenals, and other public property of the United States, now in possession of the said States, until an effort shall have been made for adjustment by the State of
                  Virginia in Convention assembled.
                </p>
                <p>
                  Resolved, That whilst we deprecate any action, on the part of the Federal Government, that may lead to a collision between itself and the seceding States, yet, the withdrawal of the said States from the Union, having been made in violation of the Constitution of the United States, and without the consent of Virginia, involving, as it does, the peace and security of the entire Union,
                  does not warrant, and ought not to receive the sanction of Virginia.
                </p>
              </div>
              <div className="section" id="vsc1965.v1.2.23.4">
                <h3><span className="headingNumber">1.23.4. </span><span className="head">THE PRIVILEGE OF VOTING</span></h3>
                <div className="speaker" id="sp627"><span className="persName">Mr. FLOURNOY</span>—</div>
                <p className="p-in-sp">
                  Mr. President, I ask the indulgence of the Convention but for a moment. I have been detained from my seat by severe indisposition for the last three or four days, and on noticing the proceedings of the Convention on yesterday, I observed that a resolution was introduced in reference to that distinguished patriot of Kentucky, John J. Crittenden, and if it be the sense of this
                  Convention, I would be much gratified to be allowed to have my vote recorded in favor of that resolution.
                </p>
                <p>The question was put on allowing <span className="persName">Mr. FLOURNOY</span> to record his vote in favor of the resolution of thanks to Mr. Crittenden, adopted on yesterday, and decided in the affirmative.</p>
                <div className="speaker" id="sp628"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">The Chair has received a communication from the Hon. John S. Carlile, on the same subject referred to by the gentleman from Halifax, which he begs leave to submit, to the consideration of the Convention as requested.</p>
                <p>The communication was read as follows :</p>
                <div className="floatingText">
                  <div className="floatingText_body">
                    <p>
                      SIR : I am anxious that the cause of my absence from the Convention on yesterday, when the resolution of thanks to Mr. Crittenden was voted upon, shall be made known to my constituents, who, with me, love to honor the man who has so signalized himself by a life devoted to the best interests of his whole country, as to have earned for himself the proud title of the "Patriot
                      Statesman." His efforts to place before the people the measures of adjustment recommended by the Peace Conference, have met the hearty approval of the great body of the people in my portion of the State, and the people of Harrison would expect my vote to be recorded in favor of the resolution referred to. It would <PageNumber num={614} /> have been
                      so recorded—for, from my heart, I thank him—had I not been prevented from attending the Convention by a return of inflammatory rheumatism, with which I was attacked shortly after my arrival in this city.
                    </p>
                    <p>Very respectfully,</p>
                    <p>JOHN S. CARLILE.</p>
                    <p>HON. JOHN JANNEY, Prest. Convention. March 12th. 1861.</p>
                  </div>
                </div>
                <p>The communication was laid upon the table.</p>
                <div className="speaker" id="sp629"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">The pending question is upon the reference of the report of the Commissioners from Virginia to the Peace Conference at Washington, to the Committee on Federal Relations; and, upon that question, the gentleman from Kanawha is entitled to the floor.</p>
                <div className="speaker" id="sp630"><span className="persName">Mr. SUMMERS</span>, of Kanawha—</div>
                <p className="p-in-sp">Mr. President, I feel that I owe an apology to the Convention for its indulgence and its attention. I certainly expected to have concluded the remarks, which I felt called upon to submit, during the sitting of yesterday; but wishing to comply with the pleasure of others, I yielded to a motion for adjournment.</p>
                <p className="p-in-sp">
                  In the review of the adjustment agreed upon by the Peace Conference at Washington, I had reached the fifth article in the series, designing, as I had done in regard to the other sections, to express my opinions on those which remain to be examined, and which were intended, together, to constitute, if adopted, the thirteenth amendment of the Constitution of the United States. I will
                  proceed to express my views in regard to the remaining parts.
                </p>
                <p className="p-in-sp">The fifth section, as prepared, is in these words :</p>
                <p className="p-in-sp">"The foreign slave trade is hereby forever prohibited; and it shall be the duty of Congress to pass laws to prevent the importation of slaves, coolies, or persons held to service or labor, into the United States and the Territories from places beyond the limits thereof."</p>
                <p className="p-in-sp">
                  I remark, Mr. President, in the first place, that the vote of the Commissioners from Virginia, was cast as a unit against this section. It is not my purpose, nor indeed am I prepared, if it were proper, to state the motives governing the several gentlemen composing the Commission from Virginia, in regard to that vote. It is sufficient that I say for myself, that I entirely concur in
                  the statement found in the explanatory letter of my late colleague, Judge BROCKENBROUGH. In speaking of this section, he says :
                </p>
                <p className="p-in-sp">"The section prohibiting the foreign slave trade, by constitutional amendment, is objectionable, simply because it was wholly unnecessary. Even the Confederated States of the South, now constituting an independent <PageNumber num={615} /> Government of slave States, de facto, if not de jure, have prohibited this traffic.</p>
                <p className="p-in-sp">"The advocates for re-opening it in any of the border slave States are very few, and there was no sort of necessity for interpolating this provision, which had no connection with the pending controversy, into this scheme of adjustment."</p>
                <p className="p-in-sp">We do not know the precise attitude of the government at Montgomery towards the African slave trade. The newspapers tell us of a veto by the President on some bill relating to that subject. I have not seen the grounds of the veto, nor do I know what was the exact character of the bill.</p>
                <p className="p-in-sp">
                  It will be remembered, Mr. President, that the Constitution of the United States itself, contains no inhibition of the foreign slave trade. The only provision relating to the subject is this : "The migration or importation of such persons as any of the States now existing shall think proper to admit, shall not be prohibited by the Congress prior to the year one thousand eight hundred
                  and eight." We all know that after the arrival of the period at which they might legitimately act, Congress did prohibit the slave trade, made it piracy to engage in it. and that it remains a prohibited traffic from that time to this. I was of opinion myself, that it was a matter to be left to Congressional regulation as heretofore left under the provisions of the Constitution.
                </p>
                <p className="p-in-sp">
                  I have, however, heard objections made to this section, which do not strike me as well founded. It seems to be supposed by some gentlemen, that in the event the seceded States still continue out of the Union, and their independence shall be recognized by the remaining Government, this provision might in some form, operate against the transfer of slave property from those seceded
                  States into the slave States still within the limits of the Union.
                </p>
                <p className="p-in-sp">
                  Mr. President, it must be quite obvious that in devising amendments to the Constitution, looking to the settlement of any of the questions of difficulty and controversy which now disturb us, the Conference was constrained to look to the Government as an unbroken one ; that it was compelled to propose amendments to the Constitution of the country as it now is, without change of
                  boundary; and that it would have been incompetent, as it would have been inexpedient and improper, to provide a proposed amendment to the Constitution of the United States, based upon the hypothesis that it was already, or was soon to be disintegrated. What I mean to say is, that in proposing amendments to the Constitution, as a matter of course, the Conference was bound to look to
                  the whole country as it is, and not as it possibly may be. <PageNumber num={616} /> But again, sir—my understanding of this section was at the time, and is now, that it is a prohibition directed against the foreign slave trade—the African slave trade—as a traffic not hereafter to be allowed. The provision is that "the foreign slave trade is hereby
                  forever prohibited; and it shall be the duty of Congress to pass laws to prevent the importation of slaves, coolies, or persons held to service or labor, into the United States and the territories from places beyond the limits thereof." Now, is it not the fair mode of construction, is it not the legitimate and only mode of construction, to take every part and portion of this
                  paragraph together and in pari materia? When it is made the duty of Congress to pass laws prohibiting the importation of slaves from beyond the limits of the United States, it is the importation from foreign countries which is denounced. It is directed against the African, the foreign slave trade. This unquestionably was the purpose in the minds of those who framed and supported the
                  amendment in the Conference.
                </p>
                <p className="p-in-sp">
                  Now, Mr. President, so far as the objection rests upon the idea, that in the event of the recognition of the independence of these Southern States which have seceded from the Union, that the limits and boundaries of the United States are then changed, and that this constitutional amendment, looking not to the hour of its enactment, or its ratification, but speaking at the moment of
                  its execution, might be construed to prevent the bringing of slaves from South Carolina, Georgia, Louisiana and other Southern States into Virginia and other slave States, as coming from beyond the limits of the United States, I have this remark to make : In the first place, the course of trade in slaves has been and always will be, not from the cotton and sugar States to the
                  agricultural slave States of the Union, but, e converso, from the border States to the cotton, the sugar, and the rice States. There never has been, there is no reason to suspect that there ever will be a system of slave trade by which we of the border slave States are to receive slaves from that region. We all very well know that the productions of slave labor in that region of the
                  country are greatly more profitable than with us, and that by consequence the price of negroes is always higher in the cotton and sugar States than it is in the border States.
                </p>
                <p className="p-in-sp">
                  But again, sir—the objection that Virginia owners working slaves on a cotton and sugar plantation in the more Southern States that have seceded, might perhaps not be permitted to bring them back to Virginia, I do not think a valid one. Take the case of a gentleman residing in Virginia and working a portion of his slaves on a cotton plantation in Mississippi, or on a sugar plantation
                  in Louisiana, and who might <PageNumber num={617} /> find it convenient to exchange the point of labor for these hands from time to time, rendering it necessary to pass back and forth with them from the State of his plantation to Virginia. In my opinion this section would not apply to any such case. I think in the first place that it applies alone to
                  the foreign slave trade, and in the second place, that the removal of hands by a Virginia owner under the circumstances supposed, would not be an "importation" of slaves into the United States in the sense of this section. By way of an illustration, suppose that a Virginia contractor upon some public work in Brazil, which is a slave-country—take the Dom Pedro Railroad now in course
                  of construction in the Empire of Brazil. Say that a Virginian takes with him fifty or a hundred of his slaves to work upon that railroad, and that he retains them there in his own service and upon that work one year, two years, or until the completion of the contract, whatever time it may be ; would the bringing back to Virginia of his own slaves, carried there and employed in his
                  own service, subject him to the penalties of the foreign slave trade? Would it be piracy on his part to bring them back ? Would it be an importation of slaves from beyond the limits of the United. States within the meaning of this amendment? It would only be bringing back his own property. It would in no sense be a "slave trade," or an "importation." It would be upon the same
                  principle that an American visitor in Europe, a foreign ambassador if you choose, might take his household servants with him if the laws of the country permitted it, and having retained them in his service the period of his absence, bring them back with him on his return. This would be neither the "foreign slave trade" or an "importation of slaves."
                </p>
                <p className="p-in-sp">Besides all this, if the seceded States shall remain out and become a foreign nation, all these questions of intercourse, with slave property, can be arranged by treaty stipulations.</p>
                <p className="p-in-sp">
                  But, Mr. President, let us see how this matter would work if we had attempted another form. Suppose this section had been so framed as to provide that "the importation of slaves, coolies or persons held to service or labor, into the United States and the Territories, from places beyond the present limits thereof," shall be prohibited. Suppose it had assumed that shape—"from beyond
                  the present limits thereof." In that case, in the event of the recognition of the independence of these Southern seceded States, they would no longer form part and parcel of the United States, but yet were within the limits of the United States at the time of the adoption of the amendment. They are within our "present limits." Suppose, Mr. President, that this Confederate Government,
                  thus recognized, and independent, should itself hereafter establish <PageNumber num={618} /> the African slave trade, might not slaves, imported from Africa into the Southern Confederacy, be exported thence into the border slave States, without violating the amendment thus framed? In such case, the slaves would not be imported into Virginia from beyond
                  the "present limits of the United States," but from points within those present limits. The importation would not, therefore, be prohibited. Thus, the people of the border slave States might be subjected, in invitum, against their will, to the African slave trade. While the provision, had it been thus framed, would have prohibited the slave States, which remained in the Union, and
                  under the Constitution, from importing slaves from Africa, it would not have prevented the importation of slaves by the Southern Confederacy, after its independency, and their re-exportation into the other States.
                </p>
                <p className="p-in-sp">
                  In any aspect of the question, it seems to me that this section, while it was not necessary—while the prohibition might have been well left to Congressional legislation—is not obnoxious to the objections which have been raised against it. There would seem to be, as Judge Brockenbrough intimates, a general concurrence of opinion adverse to the reopening of the African slave trade; and
                  such would seem to be the hereditary, traditional position of Virginia.
                </p>
                <div className="speaker" id="sp631"><span className="persName">Mr. MORTON</span>—</div>
                <p className="p-in-sp">
                  Will the gentleman permit me to ask him a question? I am perfectly satisfied that the gentleman is not inclined to wilfully do any injustice to the people of the South. But, if I understood him correctly, I think he expressed the opinion, that an act in favor of reopening the African slave trade had been vetoed by the President of the Confederated States, without stating the grounds
                  upon which that veto had been rendered. I merely wish to ask the gentleman if he has the slightest impression that that was a law interdicting the African slave trade, and that the veto was placed upon that ground at the time that the President signed it? If so, I have to inform him that the Constitution of the Confederated States expressly interdicts that trade; and if there was a
                  veto in relation to it, I presume it arose from the character of the penalties for its violation. Some in the South think that the crime of piracy is unconstitutional; and the Congress of the Confederated States may have passed a law changing the character of the crime, removing the penalty for piracy and substituting that for felony, and that the President at Montgomery may have
                  vetoed.
                </p>
                <div className="speaker" id="sp632"><span className="persName">Mr. SUMMERS</span>—</div>
                <p className="p-in-sp">
                  I certainly design no remark of mine that I have submitted, or that I shall submit, to cast the slightest imputation upon the motives or conduct of our sister States—none whatever. However much it may be a matter of regret that they have seceded from the <PageNumber num={619} /> Union, and the circumstances under which that secession occurred—the early
                  and the separate action taken by them—I have not a word of reproach for the distinguished men of those States, or the action of the States in the aggregate. I am sure my language has not warranted any inference of that kind.
                </p>
                <p className="p-in-sp">I was about to remark further, in reply to the gentleman from Orange [<span className="persName">Mr. MORTON</span>] 
                  , that the information which I have received in regard to the action of the Congress of the Confederate States, at Montgomery, is through the medium of the public press. I learned through that channel that an act in regard to the foreign slave trade has been vetoed by the President of the new Southern Republic. I understand further from the public press, that the effect of that
                  action would be to leave, for the present at least, the condition of that traffic as it is now regulated by act of Congress.
                </p>
                <p className="p-in-sp">I understood from the account of the transactions at Montgomery, that it was a bill to lessen the penalty imposed for a violation of the law carrying on that trade. Instead of leaving it as a felony, it was made to be a misdemeanor. I have not seen the veto message, and I know nothing of the grounds upon which the President predicates his veto.</p>
                <div className="speaker" id="sp633"><span className="persName">Mr. MORTON</span>—</div>
                <p className="p-in-sp">I hope the gentleman will understand that I did not mean to impute to him the slightest intention to misrepresent anything at all, but I thought that the fact might not have been cognizant to him that the African slave trade is as positively interdicted by the Constitution of the Southern Confederacy as it is by the article to which he is now referring.</p>
                <div className="speaker" id="sp634"><span className="persName">Mr. SUMMERS</span>—</div>
                <p className="p-in-sp">
                  Mr. President, when you are making a constitution for your own country, it will not do to predicate it upon what may or may not be the course of another government. The point of my remark, in this regard, was simply this : That had this provision prohibiting the importation of slaves from beyond the present limits of the United States been inserted, that, if in the future, the
                  African slave trade should be introduced by the Confederated States of the South, should be made lawful, that by such phraseology, the difficulty to which I have referred, might arise. It would, in that event, not be an importation from beyond the present limits of the United States, and although you might prohibit the direct African slave trade, we might be led into the traffic
                  through the medium of an independent government contiguous to us, and which was within our own limits at the time of adopting the provision.
                </p>
                <p className="p-in-sp">
                  I think, Mr. President, that there is really no reasonable objection <PageNumber num={620} /> to the provision as inserted here. If the South concurs in the expediency of the prohibition of the African slave trade, there is, at last, no insuperable objection to having it made a part of the Constitution any more than to having it in the statutes of
                  Congress. That such is the opinion of the people of Virginia, I have no reason to doubt.
                </p>
                <p className="p-in-sp">The next section is that in which certain sections of this adjustment and certain provisions of the Constitution of the United States are rendered irrepealable. It is as follows :</p>
                <p className="p-in-sp">"The first, third and fifth sections, together with this section of these amendments, and the third paragraph of the second section of the first article of the Constitution, and the third paragraph of the second section of the fourth article thereof, shall not be amended or abolished without the consent of all the States."</p>
                <p className="p-in-sp">
                  This renders irrevocable the provisions found in the first, third and fifth sections of these amendments, and further embraces the clause in our present Constitution which bases taxation and representation on three-fifths of the slave population, and also the provision for the rendition of fugitive slaves. By this amendment all these safe-guards are perpetuated, being irrepealable
                  except with the consent of every State in the Union.
                </p>
                <p className="p-in-sp">
                  Mr. President, among other causes of apprehension which have, at various periods, affected the public mind, not the least has been one growing out of intimations within the last year or two, from representative men of the Republican party in the North, that the time would come when slavery was to cease entirely upon this continent ; that, perhaps, the child was now in life who would
                  witness the entire extinction of this institution. It was natural to enquire, how is this end to be brought about? If it is to be done, by what agencies is it to be effected? They disclaim all intention to make assaults upon the rights of slave owners within the States; they disavow the use of all violence or force. What is it? Public opinion, before which this institution is to fall
                  down? Moral suasion? Their pulpit eloquence, if it could deserve that name? Their pulpit anathemas and denunciations rather I will say. Are these the moving power? Do these constitute the machinery by which this end is threatened to be brought upon us? Or, do these gentlemen look to the increase of free States to such an aggregate number as to enable them, at some future period, to
                  change the fundamental law of the land by the requisite majority pointed out by that instrument itself ? Is that the mode? These are the enquiries to which we have been drawn by declarations of purpose, such as those to which I have alluded. To remove this ground of apprehension, the <PageNumber num={621} /> section now before us is made to declare that
                  the section itself, with the other sections therein enumerated, including that by which slavery is not to be touched in the States, that in reference to slavery in the District of Columbia, the dock-yards and arsenals, the inter-State slave trade, the rendition of fugitive slaves, and the representation of three-fifths of the slave population, all these provisions and guarantees
                  referred to here, are made irrepealable, but upon the consent of every State of this Union. The Constitution can no longer be amended, changed or abrogated in any of these particulars, by a vote of three-fourths of the States, but can only be repealed or amended by the concurring assent of every State in the Union.
                </p>
                <p className="p-in-sp">And here, I might pause to enquire, whether that section and that alone is not worth all the effort which Virginia instituted, which ended in the convocation of the representatives of twenty-one of the States in the city of Washington, and which has placed here that section as assented to in that Conference?</p>
                <p className="p-in-sp">The 7th and last section of this pro jet is that which relates to fugitive slaves, and compensation for them :</p>
                <p className="p-in-sp">
                  "Congress shall provide by law that the United States shall pay to the owner the full value of his fugitive from labor, in all cases where the marshal or other officer whose duty it was to arrest such fugitive, was prevented from so doing by violence or intimidation from mobs or riotous assemblages, or when, after arrest, such fugitive was rescued by like violence and intimidation,
                  and the owner thereby deprived of the same; and Congress shall provide by law for securing to the citizens of each State the privileges and immunities of the several States."
                </p>
                <p className="p-in-sp">That addendum, of which I shall speak presently, formed no part of the original section as reported by the committee, but obtained its position here by motions in the Conference, of which I shall speak hereafter.</p>
                <p className="p-in-sp">
                  Now, Mr. President, in regard to this 7th section, so far as it provides compensation to the owner for fugitives from labor lost by violence or intimidation, there seems to be no objection. All agree that the owner should be paid. The principal objection which I hear made to this provision is, that it does not secure the right of reimbursement to the Government after payment of the
                  value of the slave, from the city or county where the violence or intimidation has occurred, and with remedy over against the tort feasor, or wrong doer.
                </p>
                <p className="p-in-sp">In the first place, I remark it is quite apparent that legislation to some extent will be required to carry out and execute this provision.</p>
                <p className="p-in-sp">
                  The legislation of Congress must provide some mode of ascertaining the value of the lost slave. There must be some judicial proceeding by <PageNumber num={622} /> which the value is to be ascertained. The evidence upon which the claim is to be allowed, and the manner of presenting such evidence, the tribunal which is to hear the evidence and determine
                  the claim, must be provided by law. Congress, when providing by legislation to carry out this amendment of the Constitution, can also provide, by the same act, the proper remedies against the city or county, or against the individual wrong doers, if it be deemed advisable to do so. Constitutional amendments do not go into detail. The details are left to legislation. It was so with
                  that provision of the Constitution by which persons held to service in one State fleeing into another are to be rendered up. Congress had to pass a law to carry out that organic provision. This is my first answer to the objection.
                </p>
                <p className="p-in-sp">
                  Another objection made to the proposition is, that it is payment out of the public treasury and that the owner of the slave is thus made in part to pay for his own property. If the owner of a lost slave is to be paid at all, it will be admitted, I presume, that he should be paid by the Government. Whether the Government is to be reimbursed or not, is another question. We would hardly
                  send the owner himself on the county or city where the obstruction has occurred, or against the perpetrators of the wrong.
                </p>
                <p className="p-in-sp">
                  The argument that gentlemen use, would apply to many cases where payment is made from the public treasury and to which, as citizens of the country, we contribute. By the law of Virginia, if my own slave is condemned for felony, to capital punishment and executed, I am paid the value of that slave. Being a citizen and tax-payer of the State, it may, in the same sense, be said that I
                  in part pay for my own slave. Every office-holder who derives compensation, may say that he in part pays his own salary.
                </p>
                <p className="p-in-sp">
                  But upon principle, is not the omission of this provision for reimbursement defensible? I will suggest a thought or two upon that point. The payment for the loss of a slave out of the public treasury of the United States, under this section, goes upon the hypothesis of a failure of the General Government to perform its duty, and execute the laws. It is only where the Marshal has
                  failed to reclaim the slave, that the Government is to pay. The whole duty of the Government to make the payment, is upon the ground of the failure of that Government to execute the fugitive slave law, and to restore me my lost slave. It is the duty of the Federal Government, and it is within the power of the General Government, to execute the laws in regard to fugitives from
                  service.
                </p>
                <p className="p-in-sp">
                  It is said that unless you have this recourse, that the people of any <PageNumber num={623} /> given community in the non-slaveholding States would feel less interest in the surrender of the fugitive and may be the more induced to aid in his escape. Do you not perceive that the argument would operate equally in another direction, if it be a sound one.
                  Here it is the duty of the General Government to execute this law and restore me my slave, by its public functionaries who are charged with the execution of the law. You, however, after making the General Government pay for that default of duty, give that government recourse over against counties, cities and individuals. Do you not perceive that the moment you give that recourse over
                  that you lessen the incentive and motive that should prompt the General Government and its functionaries to the faithful performance of duty? The Government knowing that the public is not to suffer—the marshal, deriving his official position from that Government and charged with the execution of the law, knowing that the public treasury will be repaid its outlay, that the loss will
                  be made good from the State, county or city where the obstruction has occurred, will lessen, to that extent, the stimulus to the performance of public duty by the General Government and by the agents and officials of that government.
                </p>
                <p className="p-in-sp">
                  But, again, I throw out this thought. As I remarked yesterday, we are not only endeavoring to obtain proper and sufficient guarantees for the protection of slave property; we not only desire to avail ourselves of the occasion to obtain a place in the Constitution itself for the security of our rights, but we desire that there shall be a termination, for all time to come, of agitation
                  upon this question of slavery between the Northern and Southern States.
                </p>
                <p className="p-in-sp">
                  Mr. President, after all, that is the great end at which we should aim. The consideration of this topic of dissension has found its way into the political arena; it has been foisted into the platforms of party, and it is the fruitful theme of party tactics every where. This fact, I say, after all is most objectionable and gives rise to a greater cause of apprehension than any other.
                  The desire of all should be then to exterminate as far as possible all grounds of irritation.
                </p>
                <p className="p-in-sp">
                  Now, Mr. President, apply that view to this provision. You will provide here that the General Government shall pay for the unreclaimed slave, and you give the Government then the power to sue or levy in some form upon States, counties or individuals, to reimburse itself. Take the case of a suit. Can the human mind conceive of a more certain and determinate mode of keeping up this
                  agitation in localities where we desire every thing shall be tranquil, and where it is our interest to suppress, rather than invite controversy and excitement? <PageNumber num={624} /> You sue in the city of Cincinnati for the loss of a slave from Kentucky, unreclaimed by the Marshal, because of alleged violence or intimidation. Every such suit will
                  present a direct issue to divide communities in opinion and action. Partizans will rally on either side, and thus keep alive the fires which would otherwise die out.
                </p>
                <p className="p-in-sp">
                  I put it to the calm judgment of the Convention to say, whether, in the case of a lost slave, lost by inability of the government, whose duty it is to protect me in the enjoyment of my property, it is not better to make the payment out of the treasury and there stop? The payment will be made because of the default of the government, and surely the government should not be reimbursed
                  from any quarter for its own default.
                </p>
                <p className="p-in-sp">
                  But, Mr. President, I may remark in this connection that it is a matter of doubtful expediency; this arming the General Government with power to levy upon States, counties, cities and boroughs to repay itself; and, as I remarked yesterday, you can proclaim no principle of Constitutional law but by making it general in its applicability. You can settle nothing by way of Constitutional
                  amendment as a matter of principle, which is not principle everywhere, North and South, in slave-holding and non-slaveholding States. I confess, for one, that I have some hesitation in arming the General Government with power to make good its own default of duty by levying upon States, cities and boroughs, to reimburse itself, or do the same thing by suits in its own Courts.
                </p>
                <p className="p-in-sp">Then, I say, that upon the whole, this section, thus far, is unobjectionable. It provides fully for the payment of the value of the lost slave, and that is the great thing at last.</p>
                <p className="p-in-sp">
                  Mr. President, what are the real practical evils under which our people suffer in these border States? How is it in Kentucky, Missouri, Western Virginia, surrounded by non-slaveholding communities, and liable at any moment to the loss of slave property? Here comes a provision which declares that if, under the present Constitution, aided by the law of 1850 and the law of 1793, you
                  shall be unable to have restoration of your slave, you shall be paid for him. Look for a moment to the stimulus which that provision gives to slave property in the border States. I speak for Western Virginia, coming as I do from that region; but when I speak for Western Virginia, I claim to be in every sense, a Virginian all over. Born and nurtured upon her soil, as my ancestors for
                  two hundred years before, I know nor cherish no sentiment adverse to her interests or of a divisional or sectional character. I look to her as a whole, glorious in all her proportions and in all the moral sublimity of her present position. <PageNumber num={625} /> Mr. President, do you not think it quite as important to protect slavery in Western
                  Virginia, as to protect it in New Mexico? We are upon the border surrounded by free States. If we cannot retain slave property securely and certainly, if we have by our position a diminished chance of its certain enjoyment and a greater exposure to its loss—if, when slaves escape, they cannot be reclaimed in any given case by reason of the public sentiment of the States around us, do
                  you not perceive when it is provided in that event that we shall be paid for our slave, that it removes to a very great extent the objections to an investment in that species of property and encourages the dissemination and use of slave property in that region of the State?
                </p>
                <p className="p-in-sp">
                  It has been understood that this provision is the very thing that the border States—Kentucky, Missouri, Maryland and Western Virginia—desire. We desire full and prompt execution of the fugitive slave law. If the fugitive be not surrendered up, then we desire to be paid for the loss of such slave by the government which fails to protect us. With such a provision you would encourage,
                  stimulate and protect the growth and dissemination of slave property and the slave interest of your own State.
                </p>
                <p className="p-in-sp">
                  Will you afford that encouragement as it is here afforded? Will you adopt the provision made for full compensation in the event contemplated, or will you reject it? Will you leave your own people unprotected and discouraged in the procurement and enjoyment of this property, upon the ground that there is no recourse over against the State, city and county where the violence has
                  occurred?
                </p>
                <div className="speaker" id="sp635"><span className="persName">Ex-President TYLER</span>—</div>
                <p className="p-in-sp">
                  Will the gentleman from Kanawha, allow me to interrupt him for a moment? According to my best recollection the vote of Virginia was given as a unit against that section. My honorable friend has vindicated the position all through. Will he be good enough to tell us what objection he had to its adoption. He will relieve me very much by exhibiting the other view of the picture. I have
                  been, and am now, in a very bad state of health, exceedingly infirm, but I propose to make some commentary upon this Peace negotiation. He will relieve me very much—if, in truth, I am not mistaken in supposing that that section was objected to by the unanimous vote of Virginia in the Convention—by reversing the picture which he has drawn and show the objections which he had to it at
                  the time.
                </p>
                <div className="speaker" id="sp636"><span className="persName">Mr. SUMMERS</span>—</div>
                <p className="p-in-sp">
                  My venerable and distinguished friend and late colleague, calls upon me to do a work which he will accomplish so much better himself that I would hardly feel justified in attempting the task which he has evidently reserved for his own hands. I have presented my views of this section, so far as it has been yet subjected to
                  <PageNumber num={626} /> examination, and will leave the gentleman to present the picture which it has impressed on his mind, in his own way and with his own more skillful pencil.
                </p>
                <p className="p-in-sp">
                  However, with the utmost respect and the kindest feelings for the gentleman—for he knows that I entertain none other towards him—I will remark that I have not yet finished this seventh section. There is a portion attached to this section of which I have not yet spoken, and about which I will speak presently. The Convention will bear me witness that I have only commented upon that
                  portion of the section which relates to payment for unreclaimed slaves. There is another portion which, at the beginning of my remarks on this section, I reserved for separate consideration. This has not been forgotten, nor intended to be forgotten.
                </p>
                <p className="p-in-sp">
                  Having, I believe, Mr. President, about finished all that I have to say upon so much of this section as provides for the payment of slaves, I proceed, in continuation of my purpose, to remark upon the residue of the section—the addendum. I trust that I have said enough, at least, to vindicate this seventh section from some of the objections which have been urged against it, so far as
                  it relates to the payment for slaves. In regard to the residue of this section contained in these words :
                </p>
                <p className="p-in-sp">"And Congress shall provide by law for securing to the citizens of each State the privileges and immunities of the several States."</p>
                <p className="p-in-sp">I am not sure whether there is a misprint in the copy before me. This certainly is not the exact language of the Constitution which I presume was intended to be followed. There is a provision in the Constitution in these words :</p>
                <p className="p-in-sp">"The citizens of each State shall be entitled to all privileges and immunities of citizens of the several States."</p>
                <p className="p-in-sp">This does not seem to have been followed literally. The addition reads "privileges and immunities of the several States." The Constitution is "privileges and immunities of citizens of the several States."</p>
                <p className="p-in-sp">
                  Now, Mr. President, my distinguished colleague and friend is right in saying that the commissioners from Virginia voted as a unit against the section after this addendum was made to it. We voted against the introduction of that addition, which was offered as an amendment, and the vote of the State was cast against the section itself, after the addition became incorporated with it.
                  This is the explanation of my vote on the 7th section.
                </p>
                <p className="p-in-sp">
                  I will not undertake any minute history of the affix or amendment. The first intimation of such a provision, so far as I recollect, came from a very well known member of the Pennsylvania delegation, acting in a <PageNumber num={627} /> minority of that delegation. When the 7th section was under consideration, providing compensation for unreclaimed
                  slaves, this gentleman suggested that, while we were proposing amendments to the Constitution to suit one great geographical division of the country, he thought it would be well to look to the other side too; and he suggested that, inasmuch as it occasionally happened that citizens of the Northern States, traveling through the Southern States, were treated by mobs with
                  violence—lynched as he called it—that it would be well to insert a like provision for compensation to such parties thus injured, out of the public treasury. That was the origin of this affix. I don't remember that he submitted himself any distinct proposition to that effect. I know that was the language he held ; it was the language of all those who proposed anything upon the
                  subject. That was the ground of this proposition as presented by those who supported it.
                </p>
                <p className="p-in-sp">The argument used in favor of its adoption, and the alleged necessity or propriety of such a provision as a matter of course, no Southern man admitted. We denied both its necessity and expediency, and resisted its adoption.</p>
                <p className="p-in-sp">The two lines which now stand as an addition to this section were offered as an addition, and received a sufficient vote to insert them. This addition was voted against generally, if not by all the Southern Commissioners in the Conference, and by the entire commission from Virginia.</p>
                <p className="p-in-sp">
                  I do not profess to be, myself, much of a politician. I never was, and never desire to be; but I think that the man must be blind who cannot see, in any public assembly of which he may be a member, even for a short time, something, at least, of the moving causes of things. I don't know that I am stating anything improper, to be commented on, coming from a member of that Conference,
                  when I say that this Republican party now dominant consists of two wings or divisions differing from each other very widely on very many points of opinion and of action. My own impression at the time was, and is now, that this provision was offered not with any expectation of Southern support; none of them expected it, but it was offered by the more concentrated portion of a party
                  existing in this country, to catch, if possible, the more conservative men of that party, especially in the Border free States, and with the purpose, if possible, of injuring them by getting them to vote against it. We all know enough of party machinery and party organization to know how these things are done.
                </p>
                <p className="p-in-sp">
                  These conservative gentlemen of the Republican party found themselves in this condition, either to vote against this which was set for <PageNumber num={628} /> them as a trap to endanger their position at home, or to take it and regard it as carrying out the declarations of the Constitution. It was adopted—it was adopted by a majority vote in that way.
                </p>
                <p className="p-in-sp">Now it has been said that the great objection to this addition is, that free negroes may be declared by any Northern State to be citizens, and that under and by virtue of this addition, Congress may by its legislation extend to them the "immunities and privileges of citizens of the several States."</p>
                <p className="p-in-sp">
                  I have told the Convention the grounds upon which this thing was proposed, namely, that of providing compensation for violence to persons from the North travelling through the South. Let that go for what it is worth. My design is now to meet the objection made to it upon the score that, by possibility at some time or other, free negroes may be declared to be citizens and entitled to
                  the privileges and immunities of citizens in the other States.
                </p>
                <p className="p-in-sp">Now, Mr. President, I desire to be perfectly understood at this point of my remarks. I have clearly and unequivocally stated that we all objected to the provision, as wholly unnecessary and perhaps mischievous</p>
                <p className="p-in-sp">as in all probability fruitful at least of cavil and objections. I am not maintaining—I am not defending it, but I have a word to say in regard to the objection that by possibility free negroes would be made to come within the purview of the proposition.</p>
                <p className="p-in-sp">
                  It will be remembered that in the Dred Scott case, so often referred to, this was the point upon which the case turned. Scott sued for freedom in the United States Court. Unless a citizen of the United States he had no right to sue in that Court. The jurisdiction of the Court to entertain his case was denied, on the ground that he was not a citizen. The Supreme Court of the United
                  States held in unqualified terms that free negroes were not citizens of the United States.
                </p>
                <p className="p-in-sp">
                  I need not remark, Mr. President, that the whole scheme and frame work of this Government of ours, is a Government for freemen, for white men—for the dominant race. I need not remark here that the Declaration of Independence, the Bill of Rights and the Constitution are declarative of the rights of the members of the body politic, and not of slaves or free negroes, or of the inferior
                  race held in subordination and subjection, whether free or bond, at the time of the Constitution, and ever since. I have no hesitation in saying, and the American mind must concur in the declaration, that when a citizen of the United States is spoken of, when the rights of citizens are spoken of, as in the Declaration of Independence, these are to be taken as announcing the rights
                  belonging to the members of the body politic. The Declaration of Independence, <PageNumber num={629} /> the Constitution and the Government which it created, were all the work of slaveholding statesmen. I have no hesitation in saying further, that when the time shall come that the Supreme Court of the United States shall declare that free negroes are
                  citizens, upon an equality with the white citizens of this country, the Government will not be worth preserving. It never has been so decided, and I take it that the innate sense of the American mind will never permit it to be so decided.
                </p>
                <p className="p-in-sp">
                  But apart from all this, my own individual preference is to exscind that amendment altogether. I do not believe that this proposition, put as an entirety, with that affix cut off, would be any less acceptable to the Northern mind. I do not believe it would. I believe that with that addition omitted, they would readily accept it. They do not desire it. It was adopted as a mere matter
                  of party maneuvering. The Constitution already provides that citizens of one State shall be entitled to the immunities and privileges of citizens of the several States. What more is wanted?
                </p>
                <p className="p-in-sp">
                  I will remark here, that I have not examined the report of the committee appointed by this Convention on Federal relations, and the minority reports, with any such degree of care as would enable me to decide what is the exact mode of procedure recommended for the adoption of this body. I do not intend to go into any examination of that report at this time. I perceive, however, that,
                  among other things, it is proposed that a conference of the slave States remaining in the Union shall be had at Frankfort, in the State of Kentucky—I believe, according to the report, on some day in May. If it is the purpose of this Convention to inaugurate such a conference (in which recommendation I cordially concur) , I see no objection or difficulty in submitting this "Peace
                  Conference" proposition to the consideration of the Frankfort Conference, if it shall be found acceptable to the Convention as a plan of settlement. I am very sure that this adjustment would prove entirely satisfactory to an immense majority of the people of Virginia. If we are satisfied with it as a settlement, let it be submitted to the Frankfort Conference as satisfactory to us.
                  It is eminently proper that it should be propounded for consideration there. In the Conference at Washington, this plan was accepted by four of the slave States represented : Tennessee, Kentucky, Maryland and Delaware. The vote of Virginia, as is now ascertained, would have been given for it as a whole, had it been practicable, under the parliamentary rule, to have taken a vote on it
                  as an entire proposition. Missouri did not vote. North Carolina was divided three to two, the majority being against it. <PageNumber num={630} /> Now, under these circumstances, let it be reconsidered in a Conference of slave States. Let us send it as it is, without additional sections or the introduction of new topics. Disturb it as little as possible.
                  It is a plan of infinite value. It has the value of having been adopted in a Conference of twenty-one States.
                </p>
                <p className="p-in-sp">
                  I do not object to the mere change of words not changing the sense, or to make the sense more explicit. I think it would be expedient to omit the addition to the last section. I do not believe it would be the less acceptable to the North, while it would be more acceptable to the South, freed of that paragraph. These alterations, with every other feature of the adjustment, would, of
                  course, be the subject of the free consultations at Frankfort. The scheme of guaranties which shall finally be proposed by that conference, I have no doubt will be such as all patriotic men, North and South, will concur in, and such as will find its way into the Constitution, in the form of amendments.
                </p>
                <p className="p-in-sp">
                  Now, sir, having, as I am very conscious, in an unsatisfactory manner, examined the various sections of which this report is composed, let me inquire what has been obtained? Take that report of your Commissioners as it is, and can it be denied that a very great advance has been made in securing the guarantees that were desired in regard to our rights under the Constitution? The
                  territorial question has been settled and adjusted by the first section. The territories are divided between North and South, and equality of right secured and perpetuated. I do not intend to add to the remarks submitted yesterday upon that section, further than to supply a mere omission which, at the time, did not occur to my mind, and it is this : to remind the Convention that the
                  territory of Arizona, by an act of Congress has been attached to and made part and parcel of the Territory of New Mexico. So that when we speak of New Mexico, or the New Mexican law—when we examine this first section, which declares and fixes the condition of persons held to service on the south side of the line of 36 30— we must remember, in that connection, that all the territory
                  that we now own south of that line, is comprehended and united under one territorial government, and embraced in the same code, so far as regards slavery.
                </p>
                <p className="p-in-sp">
                  I will not recapitulate the advantages possessed and the guaranties presented by this adjustment. They are present to the memory and comprehension of all. The denial of powers to the General Government, contained in the third section, are of the highest import and consequence to the States. The equality of power, in the acquisition of territory, the concession of perfect equality of
                  power to the unequal <PageNumber num={631} /> and weaker section, is, in my opinion, of itself worth infinitely more than all the effort which has been made, and should be secured by the most diligent endeavor, and with any reasonable delay in time which may be necessary to procure it.
                </p>
                <p className="p-in-sp">Then there are the provisions directed to the reclamation of fugitive slaves, and compensation for those unreclaimed; and, finally, the irrepealability of those guaranties, and of certain provisions in the Constitution of vital importance to the Southern States.</p>
                <p className="p-in-sp">
                  These measures, taken together, meet fully every cause of apprehension which has heretofore disturbed us, and close every avenue of trouble and annoyance, which can be reached by constitutional amendments. I do not hesitate to say that they are miles in advance of any line prescribed by any party in Virginia, six months ago. Sir, these amendments will remove every difficulty by which
                  we have been surrounded. They are guaranties upon which the country may repose, and with entire security.
                </p>
                <p className="p-in-sp">But it is said that this adjustment has proved abortive—that this cffort at settlement, initiated by Virginia, has proved a failure—because of the non-submission by Congress of those measures for adoption by the States.</p>
                <p className="p-in-sp">Mr. President, a word on that point. I am of opinion that the people of Virginia will not hold us justified in taking any action that is to determine her immediate position, by a failure on the part of the late Congress to propound these measure of the Peace Conference for the action of the several States.</p>
                <p className="p-in-sp">Sir, what are the circumstances attending those measures, and the failure of Congress to submit them?</p>
                <p className="p-in-sp">
                  The resolutions of the General Assembly of Virginia which looked to this proceeding, were passed on the 19th of Jan. last. Those resolutions required that the Commissioners appointed by herself, and those invited from the other States, should convene in the city of Washington on the 4th day of the following February. Between the 19th of January and the 4th of February, the
                  propositions of Virginia were not sufficiently thrown out, to be acted upon in time for all the States to meet us on the day appointed, the 4th of February. The period was short, and the notice was insufficient.
                </p>
                <p className="p-in-sp">
                  On the 4th of February, the day appointed, Commissioners from eleven States appeared, and the Conference was organized. In the course of some days thereafter, twenty-one States were found assembled —making a body of some one hundred and thirty members, by their representatives. <PageNumber num={632} /> Before this body, were propounded various important
                  and material amendments of your fundamental law, to be discussed, considered and decided, and then to be communicated to Congress with a request that they should be submitted to the States. After a labor of some three weeks, this adjustment which I have already reviewed, was agreed upon. Its adoption occurred on Wednesday the 27th of February, and I believe on the evening of the same
                  day it reached the halls of Congress. Now, Mr. President, let it be borne in mind that there were but three working days left of the congressional session—Thursday, Friday and Saturday. Congress was to adjourn on Monday the 4th day of March, when it expired. Remember, in connection with this, that not merely was there this absence of time, but on the other hand there was a fullness
                  of business, as there always is at the closing of Congress, rendering it almost impossible to take up any new propositions. I need not remind the members of this body how business in a deliberative assembly accumulates during the session and settles down in heavy mass at the close. Let it be borne in mind also, that this proposition, thus agreed upon at so late a period, came before
                  Congress not only at the close of the session when every member was more or less engaged with his own particular measures, devising them either for the benefit of the country, or his own personal benefit and popularity, and struggling to give them precedence; but let it be remembered further, that it came in at a time when one administration was about retiring from power and a new
                  administration, under most peculiar circumstances, was about going into power—that new administration, with a party discordant, to some extent, and having seminal causes of its ultimate dissolution already beginning to manifest themselves. Let me call to mind also, that these propositions were propounded to a Congress, not fresh from the people—elected two years ago and more—not
                  familiar with the public want and the popular sentiment as it now is, changed and modified as the public sentiment must be by the unprecedented and startling events which have occurred since their election. But notwithstanding all these circumstances so adverse to a fair consideration of the subject, a motion to take up the proposed amendments in the House of Representatives, which
                  could only be reached by a motion to suspend the rules requiring a vote of two-thirds, I say a motion to take up and consider received a large majority in its favor, and though not a two-thirds vote, sufficient to suspend the rule, indicated, under the circumstances, a willingness on the part of the House of Representatives to take up and consider the proposition.
                </p>
                <p className="p-in-sp">
                  I need not recur to the fate of this measure in the Senate, but I <PageNumber num={633} /> will say that it won't do to go home and tell the people of Virginia that these measures of the Peace Conference were not submitted because alone of opposition from a hostile party at the North. These measures received the opposition, and encountered the hostility
                  of Southern as well as of Northern Senators. I mean not the slightest disrespect to any gentleman bearing a representative character from this State, for whom I feel sentiments of personal kindness. I do not intend to designate gentlemen by party epithets, but I will say, without meaning offence to any, that these measures met their fate and received their quietus in the Senate under
                  the combined hostility and assault of gentlemen of extreme opinion from both sections of the country.
                </p>
                <p className="p-in-sp">
                  Now, sir, under these circumstances, is it to be supposed by any man in this assembly, that the people of Virginia are to be content with such an effort? Are the people of Virginia to be made to say, that all constitutional remedies and all honorable expedients have been resorted to and exhausted, and that there is no relief, that they will wait no more—that the day has come and the
                  die is cast? No, Mr. President, whatever may be the opinion of gentlemen here, believe me, sir, that the constituent body are not prepared to utter that sentiment and pronounce that judgment.
                </p>
                <p className="p-in-sp">
                  Sir, I undertake to say, that there never has been a period so propitious for a procurement of all the just rights of Virginia and of her sister Southern States as the present. I say that so far from being discouraged by anything that has occurred in the Peace Conference or out of it, I am prepared to declare as my own personal opinion, that never at any past period has there been so
                  favorable an opportunity and so inviting a field for effort to settle these questions, and with a prospect so encouraging of the best results, as the present period.
                </p>
                <p className="p-in-sp">
                  Sir, when, at any period, was the institution of slavery so fully and thoroughly understood as now? It has been examined in all its relations, social, moral and political. The political arguments, the economical views and the social and moral aspects of the institution have all been explored, and I undertake to say that the institution of African slavery has at this moment, North and
                  South, more enlightened support and a larger number of friends than at any past period. We have them in the North, and daily increasing in numerical strength. There has been a waking up in the Northern mind on this subject. They have not only looked into it as connected with the preservation of the Union, but as a thing in the abstract. And how many thousands of them now believe that
                  this institution is not only not sinful, but that it is expedient. They no longer look upon slavery in the South as adverse to their <PageNumber num={634} /> interests. In my own opinion, the two systems of free and slave labor not only are without any necessary conflict—are not only entirely free of all irrepressible antagonism—but they are mutually
                  necessary to each other, and reciprocally beneficial. I state, as a fact declared to myself personally, by members of the "Peace Conference" from extreme States of the North, and who were themselves found voting against these amendments, that if submitted to the people of their States, or if submitted to conventions of the people of these States, they would find acceptance by large
                  majorities.
                </p>
                <p className="p-in-sp">
                  But, Mr. President, I would advert in this connection to some facts tending still further to show that there is nothing to discourage us; that we have not exhausted the remedies and expedients which lie before us; but, on the contrary, that we are invited by recent events to further efforts; that instead of permitting ourselves to despair of an honorable settlement of all these
                  difficulties, there is really more ground for hope than at any period before. The state of the public mind of the North is more favorable to a harmonious settlement of sectional difficulties than heretofore. There have been manifestations of this more favorable proclivity, recently, which should not be overlooked.
                </p>
                <p className="p-in-sp">
                  You are aware, sir, that the Congress which has just adjourned, constituted as it was, submitted by the constitutional vote of two-thirds of each branch of that body, an amendment to the Constitution of the United States, which forbids any amendment hereafter, for the purpose of touching slavery in the States, unless the same be approved and ratified by every State in the Union.
                  There, sir, is an amendment to the Constitution closing up this source of apprehension, and making it impossible for Congress, or any less number of the States than the whole, slaveholding as well as non-slaveholding, to disturb this great interest of the South.
                </p>
                <p className="p-in-sp">
                  Our Constitution provides that when an amendment shall be proposed in that mode, that it shall be submitted to the Legislatures or to Conventions of the States, as Congress shall determine. I do not know whether the act of Congress, in this case, provides that the amendment referred to shall be submitted to Conventions, or to the Legislatures; but, if it is to be submitted to
                  Conventions, and this amendment shall find its way here, during the sitting of this body, is there any member who will not accept it and vote for it? All will be anxious to place in the Constitution a guaranty declarative of the inviolability of those rights in the Southern States.
                </p>
                <p className="p-in-sp">
                  Sir, I have before me a series of resolutions passed by Congress, which were furnished me by a member of Congress, with his own marginal <PageNumber num={635} /> notes upon them as they were passed. The adjournment of Congress is so recent that we have not yet been furnished with the proceedings of the last session, and therefore I am not able to speak
                  authoritatively from any examination of the resolutions myself as to their passage through both houses of Congress, but I conclude from the annotations that they have passed both branches of the General Government. The gentleman who sent me these resolutions, a member of Congress from our own State, has marked those which were "passed" and those which were "not voted on." I can say
                  with certainty that these resolutions passed the House of Representatives, which serves the only purpose I have in referring to them, that it is the present tendency of the public mind to remove all causes of irritation, and to observe constitutional duties and obligations and as furnishing encouragement to look forward to a fair and just settlement of all these perplexing questions.
                  I will read a portion of the resolutions which are marked "passed" :
                </p>
                <p className="p-in-sp">
                  "Resolved, By the Senate and House of Representatives of the United States of America in Congress assembled, That all attempts on the part of the Legislatures of any of the States to obstruct or hinder the recovery and surrender of fugitives from service or labor are in derogation of the Constitution of the United States, inconsistent with the comity and good neighborhood that should
                  prevail among the several States, and dangerous to the peace of the Union.
                </p>
                <p className="p-in-sp">
                  "Resolved, That the several States be respectfully requested to cause their statutes to be revised, with a view to ascertain if any of them are in conflict with or tend to embarrass or hinder the execution of the laws of the United States, made in pursuance of the second section of the fourth article of the Constitution of the United States, for the delivery up of persons held to
                  labor by the laws of any State and escaping therefrom ; and the Senate and House of Representatives earnestly request that all enactments having such tendency be forthwith repealed, as required by a just sense of constitutional obligations, and by a due regard for the peace of the Republic; and the President of the United States is requested to communicate these resolutions to the
                  several States, with a request that they will lay the same before the Legislatures thereof respectively.
                </p>
                <p className="p-in-sp">"Resolved, That we recognize slavery as now existing in fifteen of the United States by the usages and laws of those States; and we recognize no authority, legal or otherwise, outside of a State where it so exists, to interfere with slaves or slavery in such States, in disregard of the rights of their owners or the peace of society.</p>
                <p className="p-in-sp">
                  "Resolved, That we recognize the justice and propriety of a faithful <PageNumber num={636} /> execution of the Constitution, and laws made in pursuance thereof, on the subject of fugitive slaves, or fugitives from service or labor, and discountenance all mobs or hinderances to the execution of such laws, and that citizens of each State shall be entitled
                  to all the privileges and immunities of citizens in the several States.
                </p>
                <p className="p-in-sp">
                  "Resolved, That we recognize no such conflicting elements in its composition, or sufficient cause from any source, for a dissolution of this government; that we were not sent here to destroy, but to sustain and harmonize the institutions of the country, and to see that equal justice is done to all parts of the same; and finally to perpetuate its existence on terms of equality and
                  justice to all the States.
                </p>
                <p className="p-in-sp">"Resolved, That each State be also respectfully requested to enact such laws as will prevent and punish any attempt whatever, in such State, to organize or set on foot the lawless invasion of any other States or Territory."</p>
                <p className="p-in-sp">
                  You are aware, Mr. President, that we now have a constitutional provision that fugitives from justice shall be demanded by the Executive of the State from which the offender shall flee, and shall be rendered up, &amp;c. That constitutional provision, while it requires that the claim for surrender shall be made by the Executive of the State from which the party flees, does not provide
                  upon what State authority the demand shall be made. The custom and usage from the foundation of the Government, I believe, has been, in cases of that character, for the Executive of one State to make the demand upon the Executive of the other. I am aware that many instances have occurred in which difficulties have arisen upon demands of this sort being made—some from our own
                  State—where the Executive of the State upon whom the demand has been made, undertook to decide for himself whether the offence charged was an offence recognized by the law of the State in which the party had sought refuge. One of the men concerned in the Harper's Ferry insurrection was demanded, I believe, of the Governor of Ohio, and not surrendered. And there is the recent case in
                  which a demand was made by Kentucky upon the State of Ohio for a party charged with a criminal offence fleeing into that State ; and I believe I am very well warranted in saying that the act, to which I am about to refer, grew out of this very Ohio and Kentucky case. The Governor of Kentucky, perhaps by the direction of the Legislature, as to that I am not positive, appointed counsel
                  to ask of the Supreme Court of the United States at Washington, a mandamus on the Governor of Ohio for the surrender of this fugitive from justice. This case served to draw the public mind to the question, and to the want of a proper law on <PageNumber num={637} /> the subject. That case has not yet been disposed of by the Supreme Court. But the opinion
                  of the profession, and which, I have no doubt will be proved correct by the decision of the Court, is, that the Court has no power to proceed against the Executive of a State by way of mandamus. If the demand were made on the Judicial branch of the Government, then, for refusal to act, or acting erroneously, the higher Court could give relief.
                </p>
                <p className="p-in-sp">Well, to meet such cases, the act which I am about to read, was passed by the House of Representatives at its late session—I am not prepared to say certainly whether it passed the Senate.</p>
                <p className="p-in-sp">
                  "Be it enacted by the Senate and House of Representatives of the United States of America in Congress assembled. That every person charged, by indictment or other satisfactory evidence, in any State, with treason, felony, or other crime, committed within the jurisdiction of such State, who shall flee or shall have fled from justice and be found in another State, shall, on the demand
                  of the Executive authority of the State from which he fled upon the district judge of the United States of the district in which he may be found, be arrested and brought before such judge, who, on being satisfied that he is the person charged, and that he was within the jurisdiction of such State at the time such crime was committed, of which such charge shall be prima facie
                  evidence, shall deliver him up to be removed to the State having jurisdiction of the crime ; and if any question of law shall arise during such examination, it may be taken on exceptions by writ of error to the Circuit Court."
                </p>
                <p className="p-in-sp">Now, Mr. President, you see it is here provided that, when a person charged with treason, felony or other crime, shall escape from any State, that the demand shall be made by the Governor of the State upon the District Judge, instead of the Executive. If he fails to do his duty, the mandamus lies, as also the writ of error.</p>
                <p className="p-in-sp">
                  But again, the Judge is cut off from enquiring into the nature of the offence. The whole scope of his enquiry is, whether the party arrested and brought before him, is the person charged; and that he was within the jurisdiction of the State demanding him, at the time the offence was committed, and of these facts the charge itself is to be prima facie evidence, thus throwing the
                  burthen of disproving them on the offender.
                </p>
                <p className="p-in-sp">
                  This law would have relieved us of the difficulties that occurred in the Harper's Ferry case, the Kentucky case and a large class of cases of similar character. I adduce it as another practical proof, to show the tendency of the public mind. I mention it to show that there is nothing <PageNumber num={638} /> in the public sentiment to discourage,
                  nothing to make us halt in our exertions; but on the other hand that there is everything to animate and encourage us in the great work of repairing and perpetuating the Union and the Constitution.
                </p>
                <p className="p-in-sp">
                  Mr. President, it has been urged that neither the Peace Conference adjustment, the Crittenden project, or anything that we were likely to obtain in the way of guarantees, will induce our sister States of the South to return to the Union, and that Virginia ought to be content with nothing short of what will satisfy and restore them. Mr. President, we have duties to perform to, and for
                  ourselves. In the performance of those duties I am not unmindful of the desire of the country, of my own ardent personal desire for the restoration of those States to their former place in the Union. But, sir, I am here for one to declare my opinion that, whatever in the way of guaranties, whatever settlement of these vexed questions shall be found satisfactory to the slave States
                  still remaining in the Union ; whatever shall be found acceptable to them and sufficient to secure their rights, ought, a fortiori, to be acceptable to the seceded sister States of the South. Sir, we have infinitely more interest in all the perplexing questions which have arisen connected with slavery, than they have, or ever can possibly have. In regard to the fugitive slave law it
                  is certain we are much more deeply interested than they are—they lose exceedingly few slaves by escape, none in comparison with the number lost by the border States. They are hedged and surrounded by slave States, interposed between them and the free States, so that their slaves cannot escape, except in rare instances by vessels.
                </p>
                <p className="p-in-sp">
                  Again : What interest, compared with ours, have the Cotton States in the territorial question? Do they furnish any population for the settlement of the Territories? Is there a man from South Carolina, Georgia, Louisiana, Mississippi or Alabama, who would leave the fertile fields of the South, to migrate with his negroes into Arizona or New Mexico? What possible inducement can exist
                  for the removal of slave labor from the cotton and sugar plantations of the Southern States, where it is so profitable, to any of the Territories now owned by the United States? Practically, the border States have more interest in the territorial question than they can have. The slave migration to Territories, so far as there is any, will be from the border States, and not from the
                  cotton States.
                </p>
                <p className="p-in-sp">
                  In all these questions, we have the larger interest. Nor am I prepared to admit that in their settlement they have any right to claim a higher standard of honor, or nicer appreciation of right, than ourselves. I will <PageNumber num={639} /> not anticipate that in the event the border slave States shall obtain guarantees satisfactory to themselves, that
                  they will not prove equally satisfactory to the more Southern States. Should these States refuse to return upon a proper settlement of our difficulties, such a settlement as shall be satisfactory to Virginia, and the other slave States yet remaining in the Union, such refusal would furnish strong evidence that they left us, not on the ground of the slavery issues and difficulties,
                  but for other causes, and upon a foregone conclusion. I delight, as much as any one, to anticipate the hour when these States shall come back to. us. An apprehension was expressed from a certain quarter, in the discussions at Washington, that the seceded States might, while out of the Union, acquire by treaty, or otherwise, large portions of Mexico, and that there might be difficulty
                  in receiving them with their new accessions. I took occasion then to repel the ungenerous imputation, and expressed the opinion that let these States come back when they would, and whether they should return as the prodigal son came, bereft of all the fortune that he carried from his home, or come back laden with empires and kingdoms for their dowry, they would be received with open
                  arms and joyous hearts by the American people. And so they will be—and they will come. We belong to the same race. We cannot long be separated. There is at this moment a large amount of conservative sentiment in every one of these States, more or less repressed for the moment. There is a love of the old Union, lingering in many hearts. Eyes are yet turned with pride and admiration to
                  the stars and stripes—to the flag which has been borne so gallantly on many a field, and which has floated in triumph on lake and ocean. Settle and adjust these questions and relieve that pressure. You will then see, sir, the old sentiment of loyalty to the Union retoning itself. You will see that progress accelerated, and in time, we cannot say within what time ; time is the great
                  healer of wounds, whether mental, physical or political; time will give the people control of the politicians and they will return—we shall again be a united people.
                </p>
                <p className="p-in-sp">
                  Mr. President, in God's name what are we to gain by changing our position? I am here as one of this family council. I am here to speak my mind as a freeman and as a representative of free men ought to speak it, in kindness and brotherly love and at the same time with boldness and candor. We have been brought here for communion and for consultation. We are here deliberating upon the
                  question whether we shall pass an ordinance taking this old Commonwealth out of the Union or whether we shall make further efforts to restore and perpetuate this Union. <PageNumber num={640} /> What are we to gain by secession, what can we gain by separate action on the part of this Commonwealth? In regard to any one of the questions embraced in the
                  whole scope of our difficulties, what do we gain by such a step? What do you gain on the territorial question? The entire abandonment of all connection with and control over them. It is interesting to our people now only as a question of right—more so in that aspect than as a practical question of value ; but as a question of right I would settle it fairly, justly and forever. On
                  that question are you determined to lose everything?
                </p>
                <p className="p-in-sp">
                  On the question of our absconding slave population, what do you gain? You exchange the constitutional provision which requires the rendition of fugitives from service, the laws of Congress to enforce it, and the amendment now proposed for the payment in full of unreclaimed and lost slaves—you exchange all these provisions and guarantees securing you in your rights, for the mere
                  chance of treaty stipulations, after we shall have divided on the slave line, and after sovereign and independent nations shall have taken the place of confederated States. How are you to treat with them upon that question? Can you treat with England? It was tried in the Webster-Ashburton treaty. Have you ever been able to open to the English mind the conception of an arrangement for
                  the rendition of fugitives from Canada? Not at all. And across the Ohio river you would substitute for these confederated States, not Canada merely, but States once in communion with us thrown of and converted possibly into worse than a hostile Canada. That is what you would gain upon the fugitive slave law. And what do you gain in any particular as to this institution, in which we
                  are so much concerned—the institution of slavery, which is, and ought to be, a great and vital interest in this Commonwealth—an institution which, I am prepared to say, is founded not only in social and economical expediency, but is the best of all positions for the African himself—an institution morally, socially and politically right—what do you gain for that institution, I say, by
                  sundering this connection? Instead of a Commonwealth in the centre of a great confederacy, made up of slaveholding and non-slaveholding States, with constitutional and statutory provisions for your safety and security, you exchange that position for a border attitude along the line of States, no longer connected with you, not participating in that institution, but becoming possibly,
                  by your own act, more hostile to it than ever. You abandon all the guarantees which you now have under the Constitution, and all which are proposed to be added, for the uncertain chances of an arrangement between foreign governments. <PageNumber num={641} /> Mr. President, I remarked awhile ago that it was better to protect slavery in Western Virginia
                  than in New Mexico; better to legislate for the encouragement of that institution upon your own borders than elsewhere. If you do not afford such protection, what is the condition of Western Virginia in regard to the institution of slavery? Do you not perceive that the institution at once becomes worthless to us? In my region of the State, bordering upon the Ohio river, can you hold
                  slaves at all after separation? I come from a county which, by the census of 1850—the census of 1860 I have not yet seen—contained some 3,000 slaves. I come from a region of country constituting—permit me to say in passing—as lovely a portion of this green earth, as perhaps lies within the limits of this Union—I mean the fertile and beautiful valley watered by the Kanawha, abounding
                  in all the material resources of wealth, commerce and power. A country abounding in agricultural, mineral and commercial facilities and material. I come from the region of the great coal fields, the great salt deposits, and the oil wells and mills now furnishing light and lubrication for the whole land. Look at my neighbors of the upper Kanawha, 80 miles North of me—a valley filled
                  with enterprise, with capital and adventure, rivaling in activity of speculation and hope of fortune, the scenes of Pike's Peak a few years ago. Look, also, at the Wheeling region; its rich deposits of coal and its extensive manufactories. Look at the region represented in part by my friend from Monongalia
                 [Mr .WILLEY] 
                  , watered by streams running to Pittsburg—the head springs of the Ohio—his Pennsylvania neighbors within 12 miles of his own residence, in the charming village of Morgantown. What, I ask you, is to be the condition of this great portion of the State. Starting from the mouth of the Sandy River, which divides us from Kentucky, tracing a line by the Ohio to the upper end of the Pan
                  Handle, and then passing along the Pennsylvania boundary, you have about 400 miles of free State border.
                </p>
                <p className="p-in-sp">
                  Now sir, as one of the citizens of this community, as a property-holder in Western Virginia, I protest, for myself, and on behalf of my constituents in like condition, against changing its political and governmental relations. Give us peace ; give us guaranties such as are now offered and which we can get; invite and encourage us to our coal mines, salt wells and iron furnaces—to the
                  oil mills and oil springs—the work shops and manufactories—to the cultivation of our fertile fields, and the care of our lowing herds—that is what we desire. Dismember this republic, and where will you place us? You cut off a large mass of our labor, no longer to be retained among us, and subject every product of our industry, whether of the mine, the forest, the field or the
                  <PageNumber num={642} /> shop, to the burdens of protective and prohibitory tariff, in the Union which we shall have left. The free States contain some eighteen millions of inhabitants, and constitute the chief market for all our surplus; they can place no tariff on us under the Constitution ; but, as a separate government, would protect their own
                  industry and their own productions, to the injury and exclusion of ours. At the same time, by the tariff act of the Southern Confederacy, recently passed, all provisions for consumption are admitted, duty free; and, so far as the Southern market is concerned, the cattle, flour, wheat, corn, and other provisions supplied by Virginia, would be left to competition with the great
                  producing regions of the Western and the North-Western States. Are we to be placed in this predicament.
                </p>
                <p className="p-in-sp">
                  These remarks, both as to slave labor and our surplus productions, are applicable to the whole State in greater or less degree. Here is our northern border—the Potomac line—separated from the free States only by the narrow interposition of Maryland. Your whole sea-coast is all to be opened up, and we are to become, to use a homely phrase, the outside row in the corn field. We are to
                  protect slave property in States south of us, but to lose our own. So far from secession rendering the institution of slavery more secure in Virginia, it will be the potent cause of insecurity. Slave property cannot be retained in that position of affairs; it will diminish instead of increase. We shall find this diminution encroaching first upon the counties of the northwest, then on
                  the central west, compelling us to sell our slaves or permit them gratuitously to run away, disabling us from holding them and rendering them worthless ; that border widening and encroaching upon you in the centre, stretching to the East, until at last, in all human probability, by such separation, Virginia herself in the course of time, perhaps not far distant, will be placed in the
                  attitude, if not of a non-slaveholding State, at least slaveholding so partially as not to be regarded by her sister States of the South as any better than a Yankee appendage. I believe that the sundering and dismembering of the Union is the signal not only of vast injury to the slave institutions of the country, but possibly of its extinction in Virginia. God forbid that such
                  results should follow. I fear them—I point you to the natural effects of causes.
                </p>
                <p className="p-in-sp">But, Mr. President, not only are our most material interests to be thus affected by this remedy of secession, but in other aspects the consequences are not less worthy of consideration.</p>
                <p className="p-in-sp">
                  Europe is divided into numerous separate nationalities, bounding each other. Frowning fortresses, bastions, embrasures and all the preparations and muniments of war and standing armies constitute a <PageNumber num={643} /> necessity of the European system. Now, in the United States we have heretofore had no occasion for apprehension from internal
                  disasters and outbreaks. Our geographical position at the same time, has given safety from invasion from abroad. But, sir, when you shall divide this country by the slave line—a line of 2,000 miles between the slave and the free States—you at once perceive that you are immediately remitted to the European status. The same causes which bring about standing armies and preparations for
                  defence and war there will bring them about here. We have every reason to believe that in five years time after a permanent dismemberment of this Union there will be a standing army of at least 100,000 men on each side of the line. It will be the only mode of safety for either. It would only be the fear of each other that would keep the peace. It is only that which keeps the peace in
                  Europe now; it would be only that which would keep the peace here. As one section enlarged its army and navy, the other would find it necessary to make a corresponding increase—precisely as you find England and France watching each other, and increasing their military and naval forces pari passu.
                </p>
                <p className="p-in-sp">I will not go into an examination of the expenditures necessary to support and maintain the new ,government, with its army and navy. That these would be vastly augmented none can doubt. The increase of expense would be brought upon our people, with diminished means and capacity, on their part, to meet and discharge it.</p>
                <p className="p-in-sp">I might advert to our constant liability, after separation, to border raids and invasions ; the worst population on either side the line having it in their power to bring the two sections into strife at any time.</p>
                <p className="p-in-sp">I regard secession then, so far from being a remedy for the evils of which we complain, as an aggravation of them all, and as introducing and fastening upon us, new mischiefs, of the most injurious, if not fatal character.</p>
                <p className="p-in-sp">Mr. President, we talk about passing an Ordinance of Secession here, an ordinance for the immediate and separate secession of Virginia, in the condition of the public mind of the people of this State! Where would be the wisdom, the prudence of such a measure, if there was a majority in this Convention favorable to it?</p>
                <p className="p-in-sp">
                  With the known thought and sentiments of the people of Virginia, I cannot believe that this Convention would, if it could, pass an ordinance of secession, and send it out to be voted down by a larger majority than that which registered itself in favor of a reference of the action of this Convention to a vote of the people, for ratification or rejection. The people of Virginia do not
                  intend to accept and ratify <PageNumber num={644} /> an ordinance of secession until every effort has been exhausted to avoid it; until Virginia has performed her duty and her whole duty to herself and to the people of this country. If we pass this ordinance of secession before the consummation and exhaustion of all those efforts, and the performance of
                  this whole duty, we shall send it forth only to be overwhelmed by the waves of popular disapprobation and indignation ; to be buried so deep in the ocean that not even a bubble will arise to the surface to point the place where it went down.
                </p>
                <p className="p-in-sp">
                  Go on with your patriotic endeavors—make these efforts honestly, hopefully, fully, and to the last, and when all has been exhausted, and when the people of Virginia, as an enlightened and considerate people as they are, shall be satisfied in their heart of hearts, that there is no alternative, that we cannot and ought not to remain longer with the States to which we are now
                  connected, then, Mr. President, and then only, the Commonwealth as a Commonwealth ought to speak and act united from centre to circumference—united from the Roanoke to the Potomac, from the sea shore to the Ohio.
                </p>
                <p className="p-in-sp">
                  Sir, I warn you—I warn you as a Virginian—I warn you as a friend and brother, not to throw a fire-brand among the people of this Commonwealth. I speak freely; I speak as one of this family circle. I claim to have as large, as permanent, and as abiding a love and interest in this Commonwealth as any here. You shall not claim a higher niche for the glory and honor of Virginia than I
                  claim. I love her as a child ought to love a mother; I never entertained a sentiment at war with the loyalty of a Virginian, or adverse to her peace, her interests or her glory. If I know my own heart—man sometimes is mistaken in himself but if I know the sentiments of my own bosom, I would this moment, for the peace and honor, safety and glory of this old Commonwealth of ours, lay
                  down this poor and worthless life as a cheap sacrifice for such a good.
                </p>
                <p className="p-in-sp">But I am here to demand and protect the rights of my people. I came here to speak as a freeman, to deliberate as such; aye, and to deliberate and to speak boldly, uninfluenced by all and every extreme of pressure whether from within or without.</p>
                <p className="p-in-sp">
                  When gentlemen talk of passing an ordinance of secession, what do they mean? Have gentlemen averted to our position? Have gentlemen, I say, averted to our positions and our surroundings? You ask to take Virginia out of the Union by an ordinance of immediate and separate secession. I am here, sir, as one of her sons to protest against that course. Where is Virginia, and what are her
                  duties? Gentlemen of the Convention, bear with me for a moment while I refer to this subject. <PageNumber num={645} /> Where are you? Here lies Maryland in your lap—lying on your lap for safety and protection. Maryland, within whose limits is situated the capital of this nation—Maryland has not even called a convention or a legislature—which has yet
                  made no move towards a consideration of this question; and yet her fate, instantly and forever, is to be settled by the movements of Virginia. You cannot make a move in the way of secession in Virginia without precipitating Maryland. I know it; you cannot do it. Maryland, connected to you by every tie that can connect one State with another; by all our common rights and interest in
                  the Potomac and the Chesapeake—by her magnificent railroad, terminating at two points within your territory—Wheeling and Parkersburg—traversing the whole extent of your northern and northwestern borders—will you go out by separate State action without consulting Maryland, thereby precipitating her into the most painful and difficult position in which a State can be placed, putting
                  her into the hands of the Federal Government with all the power of that government upon her.
                </p>
                <p className="p-in-sp">But again, Sir, where is the Old North State, the land of Nathaniel Macon, typical as he was of North Carolina ; slow but wise, hard to come up to the mark it may be, but firm and unappeasable when she does come—where is North Carolina?</p>
                <p className="p-in-sp">
                  Why, Mr. President, if you pass an ordinance of secession, you cannot get to the Southern Confederacy unless North Carolina goes with you, for she lies between you and Georgia. There is evidently no disposition on her part to secede. A majority of her votes have been cast against a Convention. She refuses even to call a Convention to consider the question of secession, resting calmly
                  upon her dignity and upon her rights.
                </p>
                <p className="p-in-sp">
                  And then, what do you say of Tennessee—glorious Tennessee, daughter of North Carolina, which borders your territory and receives from you the waters of the Clinch and the Holston? You know the character and sentiments of her people, true, brave, and loyal; the bravest of the brave, the truest of the true. She has called no Convention, taken no measure or movement, laying quietly,
                  biding her time, ratifying by her vote in the Peace Conference the acceptability, the entire acceptability of that scheme and guaranty.
                </p>
                <p className="p-in-sp">
                  And then comes your noble and glorious daughter of Kentucky, the first-born of Virginia. I come from the Kentucky border country. Indissolubly connected as we are with our Kentucky friends on the other side of the Sandy River and with Western Virginia, holding the same rights and the same interests in the Ohio and the Mississippi.
                  <PageNumber num={646} /> Kentucky holds some six hundred miles of Ohio front, with the States of Ohio, Indiana and Illinois all lying across the stream, in full view of her. Do you mean to precipitate yourselves out of the Union without considering or enquiring what effect that movement is to produce upon Kentucky? Do you contemplate such a step without
                  conferring with your sisters of the border, who are equally interested with you in the result? Will you go out without consulting Maryland, North Carolina, Tennessee, Kentucky—all connected with us, bound up in our fate, affected necessarily by our act of secession? Do you mean to take a jump outside the magic circle which now encloses us, without taking counsel with those whose fate
                  is intimately connected with yours, and whose destiny for good or evil would probably be consummated by your act?
                </p>
                <p className="p-in-sp">
                  Why, sir, we hear the argument advanced continually, that whatever Virginia does, the other States will do. Mr. President, have we come to this? Are we to assume before the world, and put forth as our justification the idea that Virginia can properly go out of the Union in her present position, without arrangement or consultation with her surrounding sisters, upon the theory that so
                  commanding is her attitude and her influence, that these other States will be compelled to follow where she leads? This is not an argument upon which Virginia can stand. It is not worthy her ancient fame, or her yet unsullied justice and magnanimity.
                </p>
                <p className="p-in-sp">
                  Sir, we are constantly expressing ourselves and in strong terms against coercion. We are all against coercion. We are pledging ourselves against the policy of coercion. Yes, sir, and rightly; and yet, in the same breath, shall we ourselves coerce? You will not let President Lincoln or the Federal Government coerce any of the Southern States. You announce yourselves as utterly
                  determined to resist this policy, and yet you take a course towards your sister States, that have not offended you, who are relying upon you, who are hugged up, as it were, in your arms and sitting upon your lap, which will inevitably coerce them into a system which they would not willingly adopt. You are forcing them to change their position, and encounter all the perils of the
                  change, whether they will or no. By your own leap into the abyss, you are compelling those States to follow, with a boast before hand, that they are obliged to take the plunge if we do.
                </p>
                <p className="p-in-sp">
                  What is the difference between moral and physical coercion, in the result which is to be brought about? What is the difference between knocking a man in the river, by a blow, or compelling him, by causes which you can control and which he cannot escape, to jump in himself? <PageNumber num={647} /> Sir, the people of Virginia will not tolerate such a
                  policy towards the States I have referred to. Here is Missouri, more distant from us, now in Convention. Arkansas is now in Convention. Both these States will, in all probability, propose and recommend a border slave State conference.
                </p>
                <p className="p-in-sp">
                  There is another State which I will not forget, for I have had recent occasion to be renewedly impressed with admiration for that little State. I will not forget the gallant, noble little Delaware. With only some eighteen hundred slaves within her borders, she is true and faithful to the rights of the South—as true to the guarantees and obligations of the Constitution as her proud
                  soldiery were brave and intrepid in the days which tried men's souls, and when, by their gallantry, they obtained the sobriquet of the "Blue Hen's Chickens." Is Delaware to be consulted, or left to take care of herself ? Will Virginia, the elder sister, in a moment of madness, break away from the remaining members of the family without consulting their wishes, without taking leave,
                  or even telling them where she is going? No, sir; when the people of Virginia shall ultimately be brought to decide what shall be necessary for them, they will do so with a due regard to the interests of those States which are so intimately connected with her in interests and sympathy.
                </p>
                <p className="p-in-sp">
                  Sir, go home to your constituents, after having passed an ordinance of secession, and tell them that you have passed it because you tried to get guaranties and failed. They will ask you what trial and effort has been made? Why, we obtained in the "Peace Conference" guaranties on all the points of controversy, but Congress refused to submit them as amendments to the Constitution, to
                  be acted upon in the States and incorporated into that instrument, and the 4th of March is passed, and we thought we would not stay under Lincoln's administration.
                </p>
                <p className="p-in-sp">
                  Do you suppose the people would accept and approve your report and ratify that ordinance of secession? No, they would tell you that you had not acted upon the high and patriotic policy pursued by your fathers. Our fathers did not find that a great constitutional government could be built up in a day. Nor is a broken government to be repaired and reconstructed in a day. The fathers of
                  the republic depended on patient, persevering, honest, patriotic labor to the last, and with God's blessing upon these labors, success crowned their endeavors.
                </p>
                <p className="p-in-sp">
                  I tell you, that our people will require a like honest and faithful effort—persistent assiduous effort. Make up and present to the people the issue, whether the Union is to be dissolved, if the guaranties proposed <PageNumber num={648} /> can be obtained, and one mighty negative would be heard throughout the Commonwealth. These guaranties can and will
                  be obtained, and let the politicians say as they please of them, they are ample, and cover every point of controversy.
                </p>
                <p className="p-in-sp">But we are told that something must be done immediately—that we have a new administration—we don't know what mischief that administration may perpetrate, and that we must resume our original rights.</p>
                <p className="p-in-sp">Mr. President, I confess, for one, that, as a Virginian, I am not willing to see her driven and impelled into any course, which otherwise she ought not to take, by fear of any man or any set of men. She is not be dragooned, either by fear of the Federal Government, or affection for the Confederated States.</p>
                <p className="p-in-sp">
                  We are asked if we are willing to submit to Mr. Lincoln. We submit to the Constitution and the law, but not to tyranny and oppression—when these come we shall be ready for rebellion and revolution. But Mr. Lincoln is the submissionist, not us. He is at this moment submitting to the noble position of the border States. He is submitting to the exigencies of his own position.
                </p>
                <p className="p-in-sp">
                  I have nothing to say of the Inaugural Address, which has been so much commented on. My course cannot be governed by any expression of opinion, or any programme of measures, which must depend for their execution on the question of practicability, and must vary with the circumstances of the hour. I shall be but little moved by newspaper paragraphs or telegraphic despatches, those
                  swift-winged messengers of sensation and alarm. I think we should act cooly and calmly, and only upon full information of the facts, on which our action is to be predicated.
                </p>
                <p className="p-in-sp">We are to be alarmed by the declaration of the President that it is his duty to hold, possess and occupy the forts. That means war, it is said.</p>
                <p className="p-in-sp">
                  Why, Mr. President, I think that we ought to judge of every one by rule of common sense and by the rules of human experience, by the same modes of consideration that we judge ourselves. Why should we construe this into the announcement of a purpose to re-take forts which have been captured by the seceded States, or any of them, and are now in their possession? In the first place,
                  there is no cause for retaking them. They are not needed for purposes of national defence at all. Because they are in States that have seceded. There is, therefore, no necessity for retaking them. There is no motive to impel the administration to resort to force which will bring on a conflict between those seceded States and the General Government. Then, again, he
                  <PageNumber num={649} /> has no means of retaking them even if he desired. He can only retake them by a force which he has not the power to raise. He has only the little standing army belonging to the country, that is scattered over your entire frontier, West and Southwest, and utterly incapable of taking these fortified places or any of them out of the
                  hands of the seceded States.
                </p>
                <p className="p-in-sp">
                  Well, now as to the collection of the revenue. I am not aware of the existence of any law whatever that authorizes the collection of the revenue for the purposes of the Government anywhere, except at the custom-house, and in the ports of entry. We all know that in General Jackson's time it was found necessary to enact a law for the purpose of conferring this power. We know that that
                  law by its own terms expired in two years after the period of its enactment. We know that during the session of this last Congress there was a bill pending to confer that power in the present instance. We know it did not pass, and there was no progress made towards its enactment after the arrival of the new President in Washington. We know further, that Mr. Buchanan some time ago
                  nominated a gentleman from Pennsylvania to act as collector of customs at Charleston, and that that nomination was never acted upon by the Senate. I am then, Mr. President, warranted in saying that there is no legal instrumentality, at this time, which enables the government to collect revenue, except the law of Congress authorizing its collection at the custom houses in the ports of
                  entry.
                </p>
                <p className="p-in-sp">
                  The idea of collecting it outside the harbor and in an armed ship, even if that were an authorized lawful mode of collection, I am convinced would be—especially when connected with our warehousing system—utterly deficient and impracticable. You are aware, Mr. President, that under the present warehousing system, goods can remain three years without requiring the duty to be paid upon
                  them, unless they be withdrawn. Where is the power to blockade the port? Why, the power to blockade is the power to declare war, and the President is not vested with the power to declare war. Hence, I say, Mr. Lincoln has not the ability, if he had the inclination, to constrain the collection of revenue, without further legislation by Congress.
                </p>
                <p className="p-in-sp">
                  I do not propose to discuss the question of the right of secession, about which we all have opinions of our own. I will not go into that argument now ; it is not necessary, it is not opportune. I think that, in whatever aspect that right may be regarded, we are bound to accept secession as an existing fact. Seven States have declared themselves out of this Union—have formed a new
                  confederacy, and are now <PageNumber num={650} /> performing the functions of an independent government. Now, sir, I should say it is a narrow, unphilosophical statesmanship that would regard the movement of those Southern States, acting as organized commonwealths, and by conventional decrees and ordinances, in the light of partial insurrectionary
                  movements in opposition to State authority. Who would compare the secession of entire States by conventional authority, with such a movement as the Whiskey Insurrection in Pennsylvania, or Shays' Rebellion in Massachusetts?
                </p>
                <p className="p-in-sp">We are living in a new era. What has happened is entirely unprecedented. We have no example for it in the history of the world. It is a thing the founders of our government never anticipated. They made no provision for a dissolution or destruction of the government. It has come upon us and we must meet the event as best we may.</p>
                <p className="p-in-sp">
                  And what is that best? I say, Mr. President, just to let them alone. I would use no force. Force now is civil war, and with civil war, the bonds of our Union can never be renewed. I am happy to say that the news of this morning leaves no reasonable ground of doubt that a pacific policy has been wisely determined on at Washington, and that the troops in Fort Sumter are now or will
                  soon be withdrawn. These States must be left to time, to their experiment, to negotiation, to entreaty, to sisterly kindness. That is my mode of treatment. Under its operation all may be well. The old affection will return—the memories of the past will incline us to each other—our joint struggles and joint triumphs, the hopes which clustered about our great experiment of free
                  government and enlightened civilization, and which can not be abandoned, and yet, more than all, a sense of common interest and common safety, will bring us together again, and our Union be more strongly cemented than ever.
                </p>
                <p className="p-in-sp">Virginia is master of her own position and of her own movement. She need fear no force around or about her. She is capable of taking care of herself, and of taking care, by her voice and her declarations. of the interests of her sister States to a very large degree.</p>
                <p className="p-in-sp">
                  What then is her duty? Is she to plunge into an unknown future? Is she to rush into the arms of a Southern Confederacy, the structure of whose government she has not aided to erect, to take refuge in an edifice reared by other hands, and none of whose apartments may suit either her necessities or her convenience? Is she to hasten to a feast, where she may be, perchance, an unwelcome
                  guest ; a guest only and not an original member of the family? Is she to go without knowing the desire of any one of the States bordering upon us, or whether they will accompany us? Is she to go in contravention of the express will <PageNumber num={651} /> of the great body of her own people? Are we to do this on the ground that we are afraid to stay in
                  the Union longer? Are we to be whipped out of it by fear? Are we to become submissionists to the apprehensions; or to the ambition of politicians?
                </p>
                <p className="p-in-sp">
                  Mr. President, let us erect ourselves to a higher posture. It delights me to gaze upon the lineaments of this noble old Commonwealth, impressed upon her in her early youth time, and never hitherto effaced. Grand, noble, deliberate, just, calm, wise—all these attributes she spreads out before us—she is now asked to break away from this sisterhood of States; to abandon all hope of ever
                  re-constructing our once glorious Union, and to carry away with her the household gods of the nation—to depart with the remains of Washington in her soil, with Jefferson, and Henry, and Mason, and Madison, and that long line of illustrious sons who aided in laying the foundations of this Commonwealth, deep and abiding in the principles of law and order. She is asked to carry these
                  away and to make them to the larger portion of this nation, foreign relics, to be visited and gazed on, perhaps, in after years, only at the price of some new crusade, waged in vindication of the right to look upon the shrine, not of Him who shed His blood for the common salvation, but to visit the tomb of him who was the leader and conductor of his people in their great struggle
                  which led them to a joint inheritance of independence and freedom.
                </p>
                <p className="p-in-sp">
                  No, Mr. President. No! no! The duty of Virginia lies in another direction. It is hers to be faithful when all others shall prove faithless; it is her duty, when all others shall forget duty, to stand by it to the last, and only to give up its performance when that performance shall become impossible. It is hers, Mr. President, not to fly from, but to stand by these monuments of her
                  glory—the Constitution and the Union—constructed by herself more than by all others. If these monuments need repair, if they need retouching even, in their inscriptions, who has so much right as Virginia to lead the way in this remodeling and amendment? Let her call around her these sister States. I see that it is contemplated in the report of your committee to call a Conference at
                  Frankfort. Let Virginia call to this Conference all the slave States remaining in the Union. Let her there consult, devise, express her own opinion, consult the opinions and wishes of others—her daughters and her sisters—she will go there their equal, and no more—not to dictate, but to commune. She will have her full share of influence—she should ask no more. As the elder sister of
                  the Republic, with all her ancient glory, her honor and her services clustering around her, and which cannot be stripped from her, she invites her associates into council. <PageNumber num={652} /> Her past is safe; let not the error of the future dim the lustre of her former greatness. The brave and powerful man is always a generous man. The position of
                  Virginia is power—her voice when uttered, is decisive. She will not exert that power or speak the word to carry her out of the Union and involve the fate of her sisters. She will consult those sisters on every point of common interest, and on every scheme of common deliverance.
                </p>
                <p className="p-in-sp">Let her go there. Let her say that this adjustment is satisfactory to her. Let her present it as her contribution to that consultation, subject to approval in whole, or to modification.</p>
                <p className="p-in-sp">
                  The Frankfort Conference will, upon full consultation, agree upon the guaranties which will be satisfactory to the slave States now remaining in the Union, and will devise the plan of bringing them to the consideration of the other States. Let an appeal be made, not to the politicians, but to the people of the nation. What shall be agreed on there, will be accepted by the whole
                  nation.
                </p>
                <p className="p-in-sp">
                  And what further is the duty of this Convention? There is at this moment, Mr. President, a most oppressive, benumbing weight upon the hearts of the people of Virginia, your constituents and my constituents, and all the people of this Commonwealth. The great interests of this State are paralyzed. Capital seeks to hide itself from the passing storm. Labor goes unemployed. Property is
                  depreciated. Everything is at a stand-still. Why? Because of the uncertainty and dread of what is to come. This state of things ought not to continue. We ought to act, and act promptly, for the removal of the public anxiety. We ought, in the first place, to command peace on all sides. Then let us agree upon such plan of guarantees as will be satisfactory to Virginia. Call this border
                  slave State Conference at Frankfort, or elsewhere, to deliberate upon it, and ascertain whether it is satisfactory to them, and adjourn over for that consultation. What more can you do? What more ought you to do? I would have you do a little more than that. I would have you to say by your action that all is not lost. I would have you, by your action, remove this fear and trepidation
                  from the minds and hearts of our people. Say to them distinctly that the State is not to be precipitated ; that she is doing her duty under the Constitution which our fathers made; that she is in the Union which they constructed, and expects to remain there. I would have this Convention so act as to send the husbandman forth merrily to his fields again, and bid him look forward with
                  hope to a jocund harvest; renew the music of the hammer and the plane in your workshops, and start afresh the merchant and the man of commerce. I would unloose business and <PageNumber num={653} /> enterprise now locked up ; locked up because of some unknown but anticipated danger. Shall we continue this state of things? No, Mr. President! Remove it.
                  Say to the people in the city and in the country that Virginia knows her duty and will perform it; that she will perform it in her own time and in her own way. She is neither to be forced by outside or inside pressure to do wrong. If she is ever to leave this Union, she will leave it with no stain upon her justice, and with no remorse for duty unperformed. But she will not leave it.
                  Let that announcement go forth, and, my word for it, that, like the wand which struck the rock in the wilderness, the waters of joy will gush forth throughout this Commonwealth. Business will revive; agriculture, commerce and manufactures will revive. Make it known that you intend to keep the peace and restore the Union—and that you are taking your own time and your own mode to
                  accomplish it. Do it, Mr. President, and send a thrill of gladness throughout this State and over this broad land to animate every heart. Let it be known that however dark and lowering have been the clouds upon us, that they are breaking away, and that soon the bow of promise and peace will span the whole heavens again.
                </p>
                <p>On the motion of <span className="persName">ex-President Tyler</span>, the Convention then adjourned.</p>
              </div>
            </div>
            <div className="day" id="vsc1965.v1.2.24">
              <PageNumber num={654} />
              <h2><span className="headingNumber">1.24. </span><span className="head">TWENTY-FOURTH DAY</span></h2>
              <div className="dateline">Wednesday, <span className="date">March 13</span></div>
              <p>The Convention met pursuant to adjournment, and was called to order by the President.</p>
              <p>Prayer by the Rev. <span className="persName">Mr. BAKER</span>, of the Episcopal Church.</p>
              <div className="section" id="vsc1965.v1.2.24.1">
                <h3><span className="headingNumber">1.24.1. </span><span className="head">CHANGE OF HOUR OF MEETING</span></h3>
                <div className="speaker" id="sp637"><span className="persName">Mr. HALL</span>—</div>
                <p className="p-in-sp">I offer the following resolution :</p>
                <p>Resolved, That in future, until otherwise ordered, the Convention shall meet at 11 o'clock, A.M., instead of 12, M.</p>
                <div className="speaker" id="sp638"><span className="persName">Mr. EARLY</span>—</div>
                <p className="p-in-sp">I rise to a question of order. A resolution was offered on yesterday, or day before yesterday, embracing the same question, which was laid upon the table. I submit if it be in order to entertain this resolution, while the other was laid upon the table.</p>
                <div className="speaker" id="sp639"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">The Chair had forgotten the circumstance, and he presumes that the other resolution must be called up and acted upon.</p>
              </div>
              <div className="section" id="vsc1965.v1.2.24.2">
                <h3><span className="headingNumber">1.24.2. </span><span className="head">PROCEEDINGS OF A MEETING IN BARBOUR COUNTY</span></h3>
                <div className="speaker" id="sp640"><span className="persName">Mr. WOODS</span>, of Barbour—</div>
                <p className="p-in-sp">With the permission of the gentleman from Charles City county [ex-President TYLER] 
                  , I desire to occupy the attention of the Convention for a few moments for the purpose of presenting to the Convention the proceedings of a meeting of citizens of the county of Barbour, held at the Court house on the 7th of this month. I shall not trouble the Convention by reading all the proceedings of the meeting, but I shall take the liberty of reading the preamble and resolutions
                  adopted by that meeting, believing that in this way less time will be taken up. It is proper that I should say, in advance, that this meeting was held to express the sentiments of the persons engaged in regard to a series of resolutions offered by myself for the consideration of the Convention, and heretofore referred to the Committee on Federal Relations.
                </p>
                <div className="floatingText">
                  <div className="floatingText_body">
                    <p>At a large and respectable meeting of the citizens of Barbour county, irrespective of party, held at the Court House on the 7th day of March, 1861 (that being court-day) , John H. Woodford, Esq., was called to the Chair, and Col. Henry Sturm requested to act as Secretary.</p>
                    <p>Col. D. M. Auvil stated the object of the meeting to be to take into consideration the series of resolutions introduced by Samuel WOODS, Esq., in the Convention of Virginia, on the 21st of February, 1861, <PageNumber num={655} /> and to take such action in the premises as the meeting might deem proper.</p>
                    <p>On motion of Lair D. Morrall, Esq., a committee of five was appointed to draft resolutions expressive of the sense of the meeting. The motion being carried, the Chair appointed L. D. Morrall, Esq., Col. D. M. Auvil, John R. Williamson, Esq., Richard T. Talbott, Esq., and N. H. Taft, Esq.</p>
                    <p>The committee retired, and after a short absence returned, and through their Chairman, Col. Auvil, reported the following preamble and resolutions :</p>
                    <p>Whereas, Samuel WOODS, Esq., our representative in the Virginia Convention, on the 21st day of February, 1861, offered the following resolutions :</p>
                    <p>1. Resolved, That the allegiance which the citizens of Virginia owe to the Federal Government of the United States of America is subordinate to that due to Virginia, and may, therefore, be lawfully withdrawn by her whenever she may deem it her duty to do so.</p>
                    <p>2. That in case the State of Virginia should exercise this authority, her citizens would be in duty bound to render allegiance and obedience to her alone.</p>
                    <p>
                      3. That Virginia recognizes no authority in any Government, State or Federal, to coerce her or any of her citizens, to render allegiance to the Government of the United States, after she may, in the exercise of her sovereign power, have withdrawn from it; and that she will regard any attempt at coercion as equivalent to a declaration of war against her, to be resisted at "every
                      hazard and to the last extremity."
                    </p>
                    <p>
                      4. That the States of South Carolina, Georgia, Florida, Alabama, Mississippi, Louisiana and Texas, having severally and formally withdrawn the allegiance of their respective people from the United States of America, a faithful, earnest desire to avert civil war, and the sound conservative sentiment of the country, alike indicate to the Government of the United States the
                      necessity and policy of acknowledging their independence.
                    </p>
                    <p>1st. Resolved, That this meeting endorse and approve said resolutions, and that we clearly and distinctly announce and proclaim the doctrine of State rights and State sovereignty, and our unalterable opposition to the doctrine of Federal consolidation.</p>
                    <p>2d. Resolved, That we repudiate and denounce the doctrine of Federal coercion as a principle fatal and destructive to the doctrine of State sovereignty, and which, if attempted to be carried out in any <PageNumber num={656} /> form, will eventually lead to bloodshed, and in the end to an exterminating war of section against section.</p>
                    <p>3d. Resolved, That in the opinion of this meeting, it is the duty of the Federal Government to immediately acknowledge the independence of the seceded States.</p>
                    <p>4th. Resolved, That in the opinion of this meeting, it is the duty of the State of Virginia to immediately withdraw the allegiance of the citizens of this State from the Federal Government.</p>
                    <p>
                      Albert G. Reger,<span className="note" id="Note69"><span className="noteLabel">1</span>This is probably the A. G. Rogers and the Mr. Rogers mentioned below.</span> Esq., offered the following preamble and resolutions as an amendment which were accepted by the committee.
                    </p>
                    <p>
                      Whereas we, a portion of the people of Barbour county, believe that all honorable means on the part of Virginia to reconcile the differences existing between the North and the South have failed, and that the interest of Virginia is inseparably connected with that of the South, and that the time has come when Virginia should espouse the common cause of the slaveholding States,
                    </p>
                    <p>1st. Resolved, therefore, that the State of Virginia should lose no time in making common cause with her sister States of the South, who for years have borne so patiently the encroachment of Northern fanaticism.</p>
                    <p>2nd. Resolved, That any attempt on the part of the Federal Government to coerce the States which have seceded, will be regarded by us as a declaration of war upon the whole South.</p>
                    <p>Upon which reports the meeting was addressed by A. G. Rogers, Esq., T. A. Bradford, Esq., N. H. Taft, Esq., and Col. D. M. Auvil, and thereupon the preamble and resolutions of the Committee, together with Mr. Rogers' amendment, were unanimously passed by the meeting. After the adoption of the resolution aforesaid, it was further</p>
                    <p>Resolved, That the foregoing resolutions be forwarded to Samuel WOODS, Esq., at Richmond, with the request that he lay them before the Convention, and that the same be published in the Barbour Jefersonian, and that the Richmond Enquirer, Examiner and Whig be requested to copy.</p>
                    <p>J. H. WOODFORD, President</p>
                    <p>HENRY STURM, Secretary</p>
                  </div>
                </div>
                <div className="speaker" id="sp641">[<span className="persName">Mr. WOODS</span>:]</div>
                <p className="p-in-sp">
                  Mr. President, these resolutions do not purport to be instructions to me in regard to the course of duty I have to pursue in regard to these questions. They do, however, represent the sentiments of a very influential and respectable meeting of citizens of my county. Upon reference to those proceedings, you will observe the names of many
                  <PageNumber num={657} /> gentlemen of the highest respectability in my own section of the country, and not unknown to the State of Virginia in her politics and her legislation, who participated in this meeting, and by these resolutions express their sentiments in regard to these questions. It is not proper that I should trespass upon the attention of
                  this Convention and upon the kindness of my venerable friend
                 [Ex-President Tyler] 
                  who has given me the floor, farther than to say that my constituents and I with them, stand ready at every hazard to resist the coercion of the seceding States in any manner and for any cause and upon any pretence whatever, whether in the collection of the revenue, in the attempt to re-take the forts or in any other attempt which indicates a disposition and a determined purpose on
                  the part of the Federal Government to exercise jurisdiction and authority over any portion of the seceded States. And for this reason, Mr. President, that any attempt of that kind recognized by this Convention is a plain, direct and palpable abandonment of the doctrine of State sovereignty which for upwards of sixty years has been the distinguishing mark of Virginia politics. The
                  people that I represent and the people of this Commonwealth will understand that any attempt on the part of the Federal Government looking to coercion, will be an attempt to that extent to destroy the individual sovereignty of the State. They expect me and they expect this Convention, as the representatives of the wisdom of Virginia, not only to meet this question but to declare that
                  it shall and will be resisted.
                </p>
                <p className="p-in-sp">
                  They are not content that Virginia shall, again and again, with hat in hand, dance attendance at the gate of the Federal Capitol, petitioning for redress of grievances, acknowledging, at the same time, that the dominant party have a right to perpetuate those grievances. They expect to resist those wrongs. They will not be content with any action on the part of this Convention that
                  shall directly or indirectly dodge this great issue. They require it to be met manfully, and they expect this Convention, by a manly declaration, will vindicate those rights which are dear to them. I ask, therefore, in accordance with the usual practice in resolutions of this kind, that these resolutions be referred to the Committee on Federal Relations.
                </p>
                <p>The resolutions were referred to the Committee on Federal Relations.</p>
              </div>
              <div className="section" id="vsc1965.v1.2.24.3">
                <h3><span className="headingNumber">1.24.3. </span><span className="head">PROCEEDINGS OF A PUBLIC MEETING IN CAROLINE COUNTY</span></h3>
                <div className="speaker" id="sp642"><span className="persName">Mr. MORRIS</span>—</div>
                <p className="p-in-sp">With the leave of the honorable gentleman from Charles City county [ex-President TYLER] , I desire, Mr. President, to present resolutions of a somewhat similar character to those presented <PageNumber num={658} /> by the gentleman from Barbour [<span className="persName">Mr. WOODS</span>] , and I will ask leave of the Convention to read them.</p>
                <div className="floatingText">
                  <div className="floatingText_body">
                    <p>At a very large meeting of the citizens of the county of Caroline (irrespective of party) , held at the Bowling Green, on Monday, the 11th March 1861, on motion of F. W. Scott, Esq., Dr. John D. Butler was called to the Chair, and Col. H. B. White was requested to act as Secretary.</p>
                    <p>The object of the meeting having been explained by F. W. Scott, Esq., in a few pertinent remarks, the following preamble and resolutions were offered by Dr. Wm. Quisenberry and adopted without a dissenting voice.</p>
                    <p>
                      The people of Caroline, in public meeting assembled in December last, having unanimously resolved, that in order to maintain the Federal Union, it must be based upon the principles of exact equality and justice to each and every section of the Union, and of equal protection to the institutions and property of every section, and any Union based or maintained on other principles
                      was "void and of no binding effect and ought to be resisted;" and having also unanimously resolved, "that we deny to the Federal Government any right to coerce a State into this Union if she think proper to withdraw therefrom, and any attempt so to coerce a Southern State will make the cause of that State a common cause with us."
                    </p>
                    <p>And whereas, The President of the United States, in his recent inaugural address, has declared that he considers that "the Union is unbroken and that the power confided to me [him] will be used to hold, occupy and possess the property and places belonging to the Government and collect the duties on imports"—Be it, therefore,</p>
                    <p>1st. Resolved, That we utterly repudiate the recommendation of the Peace Conference, lately assembled at Washington, as unsatisfactory and unjust to the South, and entirely subversive of the principles heretofore declared by us, as the only principles on which the present Federal Government could, or should be maintained.</p>
                    <p>
                      2d. Resolved, That we maintain that any attempt on the part of the Federal Government at Washington to collect on land or water duties on imports, or otherwise to interfere with the commerce of the States which have seceded from the Union, or to "hold, occupy, or possess," or to re-take the property or places, belonging to the late Federal Government within the limits or
                      jurisdiction of such States constitutes coercion, and that any such attempt ought to be resisted with all the force of this Commonwealth.
                    </p>
                    <p>3d. Resolved, That we declare now, as we have heretofore declared, <PageNumber num={659} /> that we consider it to be the imperative duty of the Convention, now assembled in Richmond, without further delay to resume the powers delegated by Virginia to the Federal Union, and to take measures to connect her with her sister States of the South.</p>
                    <p>4th. Resolved, That we deprecate and deplore the delay heretofore practised by the said Convention, and as a portion of the sovereign people of the Commonwealth, call upon it solemnly and earnestly to act promptly in whatever it may do, so that the good people of the Commonwealth may know its determination.</p>
                    <p>5th. Resolved, That we earnestly invite the citizens of the other portions of the State to convene in public meetings, and give expression to their sentiments on the present state of the country, so that the voice of it may be heard.</p>
                    <p>6th. Resolved, That the course of our delegate in said Convention meets our entire approval.</p>
                    <p>7th. Resolved, That the unwavering devotion of our representative in Congress, the Hon. D. C. De Jarnette, to the interest and rights of the South, demands our unqualified approbation.</p>
                    <p>The following resolutions were offered by Eustace Moncure, Esq :</p>
                    <p>8th. Resolved, That we approve the course of our representatives in the State Legislature, Messrs. Wm. Quisenberry and George Tyler, and express entire confidence in their ability and integrity.</p>
                    <p>9th. Resolved, That a copy of these proceedings be forwarded to our delegate in the Convention, with a request that he present them to the Convention, and that copies be also furnished to the newspapers of Richmond and Fredericksburg, with a request that they will publish them.</p>
                    <p>On motion, the meeting then adjourned.</p>
                    <p>JOHN D. BUTLER, President</p>
                    <p>H. B. WHITE, Secretary</p>
                  </div>
                </div>
                <div className="speaker" id="sp643">[<span className="persName">Mr. MORRIS</span>:]</div>
                <p className="p-in-sp">Mr. President, I do not propose, at this time, to detain the Convention with any lengthy remarks. Not only the state of my health, but the courtesy which has been extended to me by the distinguished gentleman from Charles City [Ex-President TYLER] 
                  , would prevent me from so doing. I will merely state that the gentlemen who participated in this meeting comprise some of the most influential and leading men of my county. I will take the liberty, moreover, of saying, as I am justified in saying by one of the resolutions adopted at that meeting, that these resolutions were not adopted with any idea that I was at all wavering in my
                  views or sentiments, for I believe my constituents <PageNumber num={660} /> knew very well what my sentiments were when they sent me here. As I understand it, these resolutions were adopted with the view of giving an expression of opinion upon the developments made since the election of delegates to this Convention. These developments, in their opinion
                  and mine, seriously threaten the peace and endanger the liberties of this country. They believe that unless the federal powers are checked in the enunciations that are made in the inaugural address of the recent President of the United States, that the liberties of this people will be seriously endangered, if not entirely destroyed.
                </p>
                <p className="p-in-sp">
                  I will take the liberty of adding, if we are to give credit at all to the current rumors passing through the newspapers, that the developments about to take place threaten still more seriously to endanger the liberties of Virginia than any that have yet taken place. I see it stated in this morning's papers that the troops which are about to be withdrawn from the Southern country are
                  about to be concentrated within the State of Virginia and upon her borders. I suppose that we shall have shortly to consider the practical question whether our Southern sister States are to be coerced, and whether Virginia herself is to be coerced.
                </p>
                <p>The resolutions were then referred to the Committee on Federal Relations.</p>
                <p><span className="persName">The PRESIDENT</span> then stated the pending question to be on referring the report of the Commissioners of Virginia to the late Peace Conference to the Committee on Federal Relations, upon which the gentleman from Charles City county [Ex-President TYLER] was entitled to the floor.</p>
                <div className="speaker" id="sp644"><span className="persName">EX-PRESIDENT TYLER</span>—</div>
                <p className="p-in-sp">
                  I am about to make, Mr. President, a very bold and daring adventure. The condition of my health might very well justify me to this Convention in withholding from it any remarks upon the interesting subjects which were discussed yesterday. But, sir, I am acting under an impulse of duty—an impulse which I always obey, and which I shall attempt to carry out on the present occasion.
                </p>
                <p className="p-in-sp">
                  Mr. President, an aged man who had retired from the pursuits of busy life, surrounded by those comforts which should most properly surround one whose life had been spent in the public service with prattlers at his knees and a light illuminating his household, forever beaming around him—was startled from his quietude and repose, by a voice which came from the legislative halls of his
                  native State, admonishing him of danger to the country, and making a requisition for all of energy that still remained with him, either physically or mentally, in the effort to rescue that country from the imminent peril that threatened it. It was the voice of Virginia, appealing, sir, to a son, who, from the early morning of manhood, she had nurtured and petted,
                  <PageNumber num={661} /> even as a fond mother does her first born infant. At the age of twenty-one, having scarcely put on the toga virilis, he entered the public service of the State, cheered on his way by the approving smiles of those who had elected him a member of the Legislature ; and his presence there was greeted by his brother members with an
                  almost affectionate cordiality. The pathway of his life was lighted up by gracious smiles which he was continually receiving. Without anything of the spirit of boastfulness, which would ill become me, I might say that that aged man had sounded in the language of Cardinal Wolsey, "all the shoals and depths of honor." The highest public stations which the State of Virginia held in her
                  gift, she had conferred upon him. When I left the government sixteen years ago, sir, it had not entered into my contemplation that I should ever afterwards appear in a public assembly. I left that government prosperous and happy. The voice which startled me in my retirement, told me of feud, and discontent, and discord of a tearing in twain of that beautiful flag which had floated so
                  triumphantly over us in the days that had gone by, which I had never looked upon but my heart had throbbed with an emotion it is impossible for me to give utterance to. The Father of his Country had left behind an admonition to his children to avoid sectional feuds, but those feuds had arisen and had progressed until they had culminated into disunion. I had seen their beginning, sir,
                  thirty years before, when the dark cloud which now overspreads the hemisphere just rose above the horizon, no bigger than a man's hand. It was the cloud of Abolitionism. Washington, looking to the probable contingency that has now arisen, warned us against sectionalism and sectional parties. With the tongue and the pen of an inspired prophet, he foretold what has befallen us. From
                  the school-room where the youthful mind was impressed with doctrines in one section, inimical to those of another from the pulpit where traduction and abuse have been levelled at the very memories of the great dead who assisted to build up what was but yesterday a glorious government; desecrating the very altar itself and pronouncing against us anathema and violent vituperation,
                  bidding us "go forth from the communion table, you are miserable slaveholders and we cannot partake with you in the feast of peace and religion." Such the anathema. And when all is made ready—the masses excited and stirred up with an undefinable love of human liberty the politician, regardless of his country and intent only upon his own elevation, steps forth upon the stage to
                  control those masses and lead them to the disastrous point of sectionalism and separation.
                </p>
                <p className="p-in-sp">
                  Where is that Union now which we once so much loved? Where is <PageNumber num={662} /> its beautiful flag which waved over a land of wealth, of grandeur, and of beauty? Wrong, abuse, contumely, unconstitutional acts, looking to a higher law than the Constitution, thus setting men free from their obligations to society, have cut the ship of state loose
                  from her moorings, and here she is, drifting without helm or compass, amid rocks and whirlpools, her fragments floating in every direction—one part has gone South, while other parts moored for this moment, will probably at the next, break loose from their insecure anchorage. I grieve over this state of things, by day and by night. When I think of the manner in which all this has been
                  brought about by a race of hungry, artful Catilines, who have misled the Northern mind solely for their own aggrandizement, my blood becomes so heated in my veins as to scald and burn them in its rapid flow.
                </p>
                <p className="p-in-sp">
                  I was told, that in this hour of the country's danger, my services were needed ; and under the resolutions of the Legislature of Virginia, which I will very briefly advert to, as containing my letter of instructions, I resolved, at peril to myself and at every possible personal inconvenience, to venture upon the task which my native State had imposed upon me. I have not felt myself
                  at liberty to wander or depart from those instructions. One of them I will read :
                </p>
                <p className="p-in-sp">
                  "Whereas, it is the deliberate opinion of the General Assembly of Virginia, that unless the unhappy controversy which now divides the States of this Confederacy, shall be satisfactorily adjusted, a permanent dissolution of the Union is inevitable; and the General Assembly, representing the wishes of the people of the Commonwealth, is desirous of employing every reasonable means to
                  avert so dire a calamity, and determined to make a final effort to restore the Union and the Constitution, in the spirit in which they were established by the fathers of the Republic."
                </p>
                <p className="p-in-sp">
                  An effort was to be made to restore the Union ; not to enter into a sort of bargain, embracing only the border States; not merely to enter into a covenant with those who have brought about this state of things through misleading the public mind of the North ; nor yet to consult the interests of Virginia exclusively in any arrangement which might be made to restore the Constitution
                  and the Union of the States; but to bring back, if possible, the cotton States, thereby to restore the Union to what it was; to have the glorious old flag floating over one and all; to make the name of an American citizen, which had won respect in every part of the world, again a word of passport and of honor as it has been before.
                </p>
                <p className="p-in-sp">
                  What could have carried me to Washington, but the debt of gratitude <PageNumber num={663} /> which I felt I owed my State and my fellow-countrymen, and the deep solicitude which I experienced in this hour of the nation's peril? I confess to an additional motive of a personal character. If ever there lived a man ambitious of winning that true glory which
                  can alone arise from the fullfilment of the whole duty of a patriot—that man now addresses you. I aspired to the glory of aiding to settle this controversy. I had worn the honors of office through each grade to the highest. I had been surrounded by the echoes of applause in the course of my journey through life; but to encircle my brow, Mr. President, with the wreath to be won by the
                  restoration of this Union in all its plenitude, perfect as it was before the severance, would have been to me the proud crowning act of my life. That was the feeling that inspired my heart. You saw my address upon taking the chair of the Convention. Mr. President, I can speak of it without vanity and without impropriety. You all saw it. Did it please you? Was it of a character to
                  draw around Virginia the sympathies of her co-States? That was my sole object in uttering it.
                </p>
                <p className="p-in-sp">
                  I had hoped, in the manner of consultation, and from the spirit evinced at the opening of the Conference, that we were likely to accomplish the great object that Virginia had in view. Massachusetts came up, and her daughter, Maine, along with her. We had all New England, and all the border States, until we reached Michigan. A voice could not be heard on the Pacific coast; it was
                  uttered too late to reach California and Oregon in time—I wish, with all my heart, they had been there. New York soon joined us. But I found that many had come with no olive branch in their hands—nay, more—that with them the feeling of fraternity seemed to be gone. They had nothing to give—nothing to yield. The Constitution was enough for them. New York, with her potent voice, would
                  not yield one iota—not an "I" dotted nor a "T" crossed. "The Constitution must be maintained—we have nothing more to grant." Such was, in substance, her language.
                </p>
                <p className="p-in-sp">
                  Notwithstanding all these discouragements, we went to work ; and no man had more faithful colleagues than myself. We worked together, and we tried every possible expedient to overrule this state of things. It was soon perfectly obvious that without a close approach to unanimity on the part of the Convention, no measures originated by us would be of any avail. Here you have a measure
                  passed by a minority of that Convention—a measure which was defeated by a majority the night before, but which was afterwards passed by a minority, upon a reconsideration the next day, of nine to eight. The majority which passed it being a minority of the States represented in the Convention, of what value and consequence, then, is it? Why, sir, the gentlemen now in
                  <PageNumber num={664} /> power have not yet recognised the sovereignty of the Southern States, and probably do not intend to recognize it. I do not know, however, what they mean. If they could be lifted up to a lofty eminence of policy, I would say to them recognize it at once go into co-partnership not upon the restoration of the old Union, if that
                  cannot be and which may have become impossible—but by originating a commercial treaty and forming an offensive and defensive alliance, secure to yourselves all that is left of the old Confederacy—even its remains would be valuable. But there is no feeling of this kind exhibited.
                </p>
                <p className="p-in-sp">
                  But to pursue the course of remark from which I have been diverted. What are you to do with this Peace Conference proposition? What can you do with it? Do you propose, as this Convention has the right to do, to send it forth to your sister States for ratification? Why, sir, here is a majority of all the States that were in that Convention, already opposed to it. How are you to get it
                  ratified? How can you ever get it ratified? Have you made your calculations? Taking out seven cotton States, do you know that it would take every remaining State in the Union to ratify such a measure? Two-thirds must recommend and three-fourths are necessary to ratify it before you can incorporate it into the Constitution. The negative of one State defeats it. Of what possible use is
                  it then? or why expend our breath or time about it? Congress, to whom it has been submitted, has not approved it.
                </p>
                <p className="p-in-sp">My friend said the other day, in the speech to which I listened with so much interest, and in which he made so able an exposition of his side of the question, that Congress does not recommend it, because they are the old hacks of politics; they were elected before these things occurred. We had the young hacks of politics in our Convention ; [laughter] new men—some who had never appeared before in political bodies—and yet there they stand, as if under paralysis. I tell you nothing but the naked truth—we could not get them to move one inch.</p>
                <p className="p-in-sp">
                  Now, sir, how came you to get this Peace Conference proposition? I have already told you. It seems, however, that it was through my agency that the Virginia vote was lost for it. Even my friend, the editor of the "National Intelligencer," leaves an inference open, unintentionally on his part, I am sure, by which I am to be assailed for the casting of the Virginia vote. I know Col.
                  Seaton very well ; I have known him from the year 1816, when I entered the House of Representatives, and a more respectable gentleman there is not in the country. As I said, even he leaves the conclusion to be inferentially <PageNumber num={665} /> drawn, that I have been the instrument of preventing Virginia from voting for these propositions.
                </p>
                <p className="p-in-sp">
                  This pro jet of the Peace Conference is made up, as you have already seen, of seven sections. I happened to be the presiding officer of the body. The question came up in the first instance, after the amendment of all the sections, on the adoption of the entire proposition. Gentlemen called for a division of the question and demanded that the question should be taken by sections. The
                  subject of course was divided, and the vote taken by sections. Each separate section was voted upon, and, by varying majorities, each separate section was adopted. My friend, <span className="persName">Mr. SEDDON</span>, then moved that there should be a vote taken upon the entire proposition. Judge BROCKENBROUGH had voted against the first section, thus throwing the vote of Virginia
                  against it—and there we stood. We had voted against two of the other sections unanimously. A motion was made, as I have said, by <span className="persName">Mr. SEDDON</span>, that the vote should be taken afterwards upon it as an entirety. I expressed a doubtful opinion \upon the subject, and earnestly urged the Convention to take an appeal from my decision. I said it appeared to me that
                  the adoption of each separate section by the vote of the Convention amounted to the adoption of the entire pro jet. I asked an appeal. My friend, <span className="persName">Mr. SEDDON</span>, would not take it—I called upon the Convention to take an appeal. I reiterated my doubts about it. There rose up an almost, if not an unanimous assent to the decision of the Chair. I still further
                  reflected upon the question and again expressed my doubts to the Convention, and asked for its distinct decision upon the point. The Convention reiterated, by unanimous declaration of approval, coming up from every part and all over the chamber, its previous approval. That decision was given in the face of this fact, that Judge BROCKENBROUGH had just voted with
                  <span className="persName">Mr. SEDDON</span> and myself against that 1st section. I had not the slightest idea—and in this statement I am perfectly satisfied that I shall be endorsed by <span className="persName">Mr. SEDDON</span> himself that Judge BROCKENBROUGH looked to it as an entirety, or would have voted for it as an entirety. I certainly entertained no such opinion, and was only
                  informed of my presumed mistake upon hearing from him, through his letter to myself and Judge SUMMERS, which has been submitted to this Convention. And now, sir, after full reflection upon the question decided by me, I would submit that question to your own decision, Mr. President, or that of any other parliamentarian.
                </p>
                <p className="p-in-sp">I will, now, Mr. President, proceed to discuss, as briefly and as rapidly as I can, the article itself, and every section of the article which has been proposed to your consideration, and which was made the subject <PageNumber num={666} /> of the elaborate investigation of my friend from Kanawha [<span className="persName">Mr. SUMMERS</span>] , yesterday.</p>
                <p className="p-in-sp">Let us look at these sections. There can be no desire on the part of anybody to be deceived concerning them.</p>
                <p className="p-in-sp">
                  The views taken by my friend yesterday are, as I understand, concurred in by our distinguished colleague, <span className="persName">Mr. RIVES</span>, for whom I entertain sentiments of the highest respect, and with whom I have always been on familiar terms. Our acquaintance began, sir, at old William and Mary, and our friendship, I am sure, will only terminate with our lives. Both
                  <span className="persName">Mr. RIVES</span> and the gentleman from Kanawha, who addressed the Convention yesterday, took different views from myself as to the true character of these propositions. But my exalted respect for my two distinguished colleagues cannot lead me to doubt at all the simplicity, and at the same time the absolute irrefutability of the construction which I give to
                  the first section of the article. I have not been able to bring myself to the adoption of their conclusions, which reflection but serves to rivet me in the conviction that they have fallen, unintentionally I doubt not, into a great error. I enter, without further delay, on an analysis of the first section.
                </p>
                <p className="p-in-sp">It reads:</p>
                <p className="p-in-sp">"In all the present territory of the United States, North of the parallel of 36 deg. 30 min. of North latitude, involuntary servitude, except in punishment for crime, is prohibited."</p>
                <p className="p-in-sp">There is no misunderstanding that provision. North of this line the prohibition of slavery is plainly and emphatically expressed.</p>
                <p className="p-in-sp">Come to the South-side and let us see what this article means. I quote from this section again :</p>
                <p className="p-in-sp">"In all the present territory South of that line, the status of persons held in service or labor shall not be changed."</p>
                <p className="p-in-sp">
                  What does that mean? I recollect that when that provision was under examination before the Peace Conference a proposition was made to introduce the word legal before status. An eminent gentleman, whose name it is not necessary for me to mention, rose and said : "Why it is not necessary to introduce that word 'legal,' for when you talk of status you talk of legal status." And, it is
                  my duty to tell it, sir, he went on still further, and with a candor which did hiin honor, declared that the whole interpretation of the section was that it was the status which was fixed by the Mexican law of emancipation which had been proclaimed by the Mexican Government years before the acquisition of the territory by the United States ; and he maintained that the law of New
                  Mexico was the status of free soil. Why, sir, he ignored the idea that a <PageNumber num={667} /> territory could either prohibit or introduce slavery within its limits. I thanked him for his explanation afterwards. I went to him and said : "You have at all events established your character as an honest and frank man." I cannot refer more particularly
                  to him than to say that he is a prominent and leading man in his party.
                </p>
                <div className="speaker" id="sp645"><span className="persName">Mr. WISE</span>—</div>
                <p className="p-in-sp">Will the gentleman allow me to interrupt him? I want to know the name of the gentleman to whom he refers. Was it or was it not a member of the Cabinet from Ohio of the name of Chase?</p>
                <div className="speaker" id="sp646"><span className="persName">Mr. TYLER</span>—</div>
                <p className="p-in-sp">
                  Well, sir, that is what the lawyers call a question direct, and I have some doubt about my right to answer it, although I am perfectly satisfied that the gentleman himself would have no objection to my doing so. There was a question under consideration in the Conference as to disclosing what transpired there. How far the veil of secrecy was removed I really do not remember—the
                  journal has not yet reached me, if it ever does. I have but followed the example set me by my friend yesterday. I am only saying what was said, but have given no names. It is not necessary to mention the name. All that I have a right to say is, that he is a prominent and leading man. Well, sir, can you doubt the correctness of his interpretation? Remember, that shortly after the
                  Mexican revolution a general emancipation of slaves took place in Mexico. You acquired New Mexico by the treaty of Guadalupe Hidalgo, growing out of the Mexican war. You acquired it while still resting under Mexican emancipation and under the ordinance declaring that slavery shall not exist there, and that it was forever to be abolished. It is true that they retained a sort of
                  slavery. They retained among them what is called "peonage." It is a cruel bondage, a terrible bondage; a bondage of sinews and muscle which no one of our slaves has to endure. Well, sir, can you come to any other interpretation than that which the gentleman to whom I have referred has given? Why, sir, you had yesterday a statute which was passed by the Territorial Legislature of New
                  Mexico, read to you by <span className="persName">Mr. SUMMERS</span>. If that statute is the status of slavery over that territory, then all the subsequent part of this provision of this first section becomes null and void. It is of no consequence at all—it only tends to confusion. Mark how the section reads:
                </p>
                <p className="p-in-sp">"Nor shall any law be passed by Congress or the Territorial Legislature to hinder or prevent the taking of such persons from any of the States of this Union into said Territory, nor to impair the rights arising from said relation; but the same shall be subject to judicial cognizance in the Federal Courts according to the course of the common law."</p>
                <p className="p-in-sp">
                  What have you to do with the common law or its course? The statute <PageNumber num={668} /> of the Territorial Government is full and ample. It gave you a remedy and it gave you a right which are of themselves sufficient. The statute is full of remedies; it recognized your rights; and you want the common law—take care, sir—take care, Mr. President, of
                  the meshes which may encircle your feet. Mark you! You have given up the statutes of New Mexico—Congress can make no provision for the protection of slavery in the Territory, nor can the Territorial Legislature, but the Mexican law is there, and the moment you go there you are under the Mexican law of emancipation. The statute of New Mexico has become a nullity. The common law is to
                  abrogate the statute, which at its greatest stretch of interpretation can only cover the twenty-four slaves already there. What is the condition of the bondsman who accompanies you to that Territory? Congress is prohibited from preventing your journey to the Territory with your slaves, nor can the Territorial Legislature hinder you. But what becomes of him after he gets there? He
                  stands upon free soil—he is there surrounded with all the panoply of the Mexican code of emancipation. Does the common law aid you? Do its remedies offer you any security? At the most, you have a law suit upon your hands; and that is all you have got. When you come into court for security you will have applied to your case the same decision rendered by Lord Mansfield in the Somerset
                  case which was referred to by the gentleman from Kanawha
                 [<span className="persName">Mr. SUMMERS</span>] . This case grew out of the attachment of a slave on the soil of England, where, in the language of Curran, "the chains of the slave fall from around him and he stands redeemed, regenerated, and disenthralled by the genius of universal emancipation."</p>
                <p className="p-in-sp">
                  This language I have often repeated when a boy—I used to delight in its euphonious tones. It swelled like a mountain torrent upon the ear; it kindled the fancy into a perfect blaze and filled one with that delight which the eloquence of the Irishman in its brightest strain, can alone produce. So it is in New Mexico. "The altar and the god fall together in the dust," and you rely upon
                  the common law to restore them, and to invest your property with sanctity and protection. You appeal to Lord Mansfield's authority. He tells you the moment you touch the soil of New Mexico with your slave, his slavery is at an end, and he is a free man. It is true that at a later day Lord Stowell assumed a position antagonistic to Lord Mansfield on this subject. He decided in the
                  Gracie case, that Lord Mansfield was wrong. How then do you stand? You are asked to ignore the statute which recognizes your rights in the New Mexican territory and to appeal to the common law, where the decision will, in all probability, be governed by this <PageNumber num={669} /> decision of Lord Mansfield. Thus you have two of the most eminent
                  jurists of England differing upon your title to your bondsman. You come to Washington to seek a settled opinion upon the subject and you go to the great Senatus consultum. You listen to Mr. Benjamin, and he throws around you the panoply of the common law. He guarantees protection for your negro wherever he goes. Even he, however, staggers somewhat in doubt when he touches free soil,
                  as in Pennsylvania. He is met, however, by two distinguished gentlemen, Mr. Fessenden and Mr. Collamer—men of high eminence and talents, and as distinguished as Mr. Benjamin—with large and comprehensive views upon every other subject than this cramping subject of slavery; they are in conflict with Mr. Benjamin's views, having a majority of two to one in their favor among their
                  brother Senators. If, leaving Washington, you go to Chicago, you encounter a pronunciamento there, which ignores your whole claim to any refuge or protection either under the common or territorial law.
                </p>
                <p className="p-in-sp">Let us see what it is. Here is the Constitution of the "higher law" party; and this Constitution is proclaimed from the house tops; made the Shibboleth of political faith; engraved upon the standard of the free soil party of the North. And what does it say. I will read the eighth article of the Chicago platform :</p>
                <p className="p-in-sp">
                  "That the normal condition of all the territory of the United States, is that of freedom. That as our Republican Fathers, when they abolished slavery in all our national territory, ordained that no person should be deprived of life, liberty or property without the process of law, it becomes our duty, by legislation, whenever such legislation is necessary, to maintain this provision
                  of the Constitution against all attempts to violate it. And we deny the authority of Congress or a Territorial Legislature or of any individuals to give legal existence to slavery in any Territory of the United States."
                </p>
                <p className="p-in-sp">There it is, sir. There it is—the overruling fiat of the great dominant party of the United States ; and yet you rest in regard to this thing, upon the poor provision of this first section of the Peace Conference article.</p>
                <p className="p-in-sp">Sir, that is not all. Who is to decide this question of common law which arises in your territory? Where can you fly for refuge, for succor, or for security? You go to the Federal Judge. Whose Federal Judge, sir? Of our appointing? No, sir. No, this expounder of your rights is to be a Federal Judge appointed by Mr. Lincoln.</p>
                <p className="p-in-sp">
                  Nor is that all. Your whole granted territory, these pretended grants to you which like the apples of the Dead Sea melt away the very <PageNumber num={670} /> moment that you get them in your grasp, are to be employed and held not only under the decision of the Chicago platform and the just interpretation of the section which I read, but, when you come
                  to Congress, the great tribunal of the nation, and make your appeal there—the appeal of only seven—poor seven—what do your opponents count against you? Nineteen to seven! All the world to nothing. You are at their mercy, you have nothing to hope for.
                </p>
                <p className="p-in-sp">
                  But, sir, it was said the other day that you did not want any more territory. This was said by some gentleman in debate whose name I cannot now call to mind. You seven do not want any more territory—not a foot more! Make yourselves easy upon that subject; you have territory, but it is territory in the moon, which you cannot reach, even if it have a better foundation than Sancho Panza
                  had to his government. Our good friends of the North take care to head you off, sir; they give you no chance to get there. Look at the 3d section.
                </p>
                <p className="p-in-sp">That 3rd section denies you the right "of transit in or through any State or territory."</p>
                <p className="p-in-sp">
                  My friend announces himself a State rights man, and I should be very happy indeed to receive him as one of my own fraternity, as the advocate of the resolutions of '98-'99. He told us that this was but an attribute of State power, the non-permission on the part of these States to carry our property in transit through them; and then my honorable friend sent down a perfect avalanche of
                  persons upon us, and asked : Have we not a right to prevent that avalanche from passing through our territory? Sir, every day, every hour, every moment of every day, the mechanics of the North are passing on to the South and are taking their machinery with them of every character and description; but we poor seven, we miserable starvelings of the once great Confederacy, are to be
                  denied the poor privilege of passing with our property in transit through one of these States. Of what advantage, then, to us is the territory of Arizona and the beautiful Mesilla valley? How are we to get to them?
                </p>
                <p className="p-in-sp">
                  Suppose we start off upon our journey from Richmond. You are resolved to take up your residence in Arizona or in the Mesilla Valley. How are you to get there? If you go by sea, you have to double the stormy Cape before you reach the gulf of California. You will scarcely encounter a voyage of so much peril and danger. You prefer to go by some other route, the route by land. How are
                  you to accomplish it? By this 3rd section you cannot pass through the States and territories. The soil of Ohio you must not set foot upon. You may pass down the beautiful Ohio and go on dancing upon the waves of the great Father <PageNumber num={671} /> of waters—the Mississippi. But what will you do when you get to Louisiana which is now out of the
                  Union? Is she going to permit this avalanche upon her? Will she permit you to reach the Gulf of Mexico? This, of course, must depend entirely upon her discretion. And when you have passed into the Gulf of Mexico, how get across Texas? Attempt it in another direction. You are headed off by Kansas, the Indian Territory and Arkansas. And this is true, whether you begin your journey from
                  Richmond or St. Louis. These fair and beautiful valleys, for such they undoubtedly are, tempt you, but you cannot reach them, either by water or land, without permission from others. And there you are, with a capacious domain, with grants of land claimed in the sovereign capacity and attribute of the old Commonwealth of Virginia, and yet so fenced and hedged in by these provisions
                  that nothing of these possessions which you ought to enjoy, but which your masters tell you you shall not enjoy, is left to you in any practical sense.
                </p>
                <p className="p-in-sp">
                  But, sir, we were told that we wanted no more land ; that we have plenty of land to fill up for one hundred years to come. I did not expect to hear this upon this floor. I do not know the gentleman who uttered the remark. Want no more land! Content to remain but seven States in this vast collection of Northern States! Want no expansion! No more power than they will grant you! They
                  are nineteen now, while you are but seven; and let me tell you, sir, that before this administration goes out—or within two years afterwards, these nineteen States will have swollen up to twenty-seven. It will be twenty-seven to seven. Where is the glory of Virginia then? Want no more land! Your inhabitants, when they emigrate, are to be driven to the old settled States of the South,
                  there to be forced to give from thirty to one hundred dollars an acre for land. The Government lands, at one dollar and twenty-five cents an acre, are to be denied them. You may go into Texas, it is true, but you could not, probably, get land there for one dollar and twenty-five cents an acre.
                </p>
                <p className="p-in-sp">
                  I think it is a hard case to tell me that if I shall be obliged to emigrate with my large family in pursuit of a more extensive home, that I must give all the earnings of my life for the purchase of a few acres of land in South Carolina, or one of the neighboring cotton States. I cannot go into the Southern regions which can produce the cotton, the rice, the indigo, and am
                  practically excluded from all the benefits of our new grants through the operation of the several causes to which I have referred.
                </p>
                <p className="p-in-sp">
                  Well, sir, after what is here said of this state of things, it is not for me to complain of it. I never complain of any gentleman for his opinion. <PageNumber num={672} /> Upon that subject the gentleman has a right to his opinion, and I have a right to mine; we differ; that is all, and there the matter ends. Sir, my friend yesterday said something upon
                  the subject of future acquisition of Territory. I do not know how it can be that we should differ so widely upon that question. Now, when I look to our instructions from the General Assembly of Virginia, I find this language :
                </p>
                <p className="p-in-sp">
                  "Resolved, That in the opinion of the General Assembly of Virginia, the propositions embraced in the resolutions presented to the Senate of the United States, by the Hon. Jno. J. Crittenden, so modified as that the first article proposed as an amendment to the Constitution of the United States, shall apply to all the territory of the United States now held, or hereafter to be
                  acquired, south of latitude 36 deg. 30 min., and provide that slavery of the African race shall be effectually protected, if necessary, as property therein, during the continuance of the Territorial Government; and the fourth article shall secure to the owners of slaves the right of transit with their slaves between and through the non-slaveholding States and Territories, constitute
                  the basis of such an adjustment of the unhappy controversy which now divides the States of this Confederacy, as would be accepted by the people of this Commonwealth."
                </p>
                <p className="p-in-sp">I consider it in the light of a positive instruction, to stipulate for future acquired territory. And why not, sir? If the question is to be settled, why not settle it altogether? My honorable friend [<span className="persName">Mr. SUMMERS</span>] thinks it best to let the future take care of itself.</p>
                <div className="speaker" id="sp647"><span className="persName">Mr. SUMMERS</span>—</div>
                <p className="p-in-sp">Did I understand the gentleman from Charles City, that he intends to impute to me the sentiment that I am against the future acquisition of territory?</p>
                <div className="speaker" id="sp648"><span className="persName">EX-PRESIDENT TYLER</span>—</div>
                <p className="p-in-sp">No ; except that you abandon it in that projet.</p>
                <div className="speaker" id="sp649"><span className="persName">Mr. SUMMERS</span>—</div>
                <p className="p-in-sp">The gentleman himself will bear me witness that I, with himself and the other Commissioners—that we all sustained the Crittenden propositions. My argument yesterday was, that having lost the Crittenden proposition, I took that of the Peace Conference.</p>
                <div className="speaker" id="sp650"><span className="persName">EX-PRESIDENT TYLER</span>—</div>
                <p className="p-in-sp">I beg the gentleman to be perfectly assured that I did not mean to impute to him any sentiments which he did not entertain. I take his explanation. We all voted for the Crittenden propositions.</p>
                <p className="p-in-sp">
                  Now, Mr. President, let us see for what we all voted, and for what the majority of the Commissioners voted. I have already descanted upon the first section. You see that it is all wrapped up in ambiguity. It leads to doubt, at least as to its true import. Instead of healing up the <PageNumber num={673} /> discontents of the country it widens them. Now
                  take Mr. Crittenden's first section. Let us see how the fair and manly proposition of my honorable friend from Kentucky, aged now, a student at William and Mary College when I was there as a Grammar-boy, in approval of whose patriotism I voted with the greatest pleasure yesterday, and I will repeat that vote just as often as you ask me, for I know how indefatigable he has been in his
                  efforts to secure the adoption of measures which would settle the pending difficulties and restore peace to the country. My friend
                 [<span className="persName">Mr. SUMMERS</span>] apologized for Congress for not recommending the Peace Conference plan because there were only three days left to Congress of its session.</p>
                <p className="p-in-sp">
                  I wish him to remember that Mr. Crittenden had struggled and struggled, for months, by night and by day, to carry out this plan; that he was in all sorts of association, soliciting here, and urging there, taking into his counsel, as we were told, Mr. Seward, and aided by Mr. Douglas—taking everybody whom he could get into his counsel, and using all the means he could employ, likely
                  at all to accelerate and forward his purpose; and yet it could not be effected. No sir, his hopes led him on until all hope was wrecked. My honorable friend, <span className="persName">Mr. SUMMERS</span>, if I understood him, said this Peace Conference proposition was better than Mr. Crittenden's projet; that it was more full, more comprehensive, more complete; that you obtained more in
                  it than in Mr. Crittenden's—at all events, that it was quite equal to it. Did I understand you so, sir?
                </p>
                <div className="speaker" id="sp651"><span className="persName">Mr. SUMMERS</span>—</div>
                <p className="p-in-sp">My position was, that taken as a whole it was an equivalent to the Crittenden proposition, and in several particulars, in my judgment, in advance of it.</p>
                <div className="speaker" id="sp652"><span className="persName">EX-PRESIDENT TYLER</span>—</div>
                <p className="p-in-sp">
                  Then sir, you perceive that the argument which I advance is correct, that this projet was equivalent to Crittenden's; but there were only three days in which it could struggle before Congress. The Crittenden proposition was its prototype—this, the Jupiter out of whose head Minerva sprung, had long been under consideration, had been urged in all its forms, had been pressed in every
                  imaginable manner by my honorable friend from Kentucky
                 [Mr. Crittenden] 
                  , with whom I had the honor of serving in the Senate of the United States, and elsewhere, who was for a short time in my Cabinet, and whose loss I regretted upon his voluntary retirement—and there never has been any interruption of our friendship, at least, on my part, and will not be until death shall take away either him or me. Now, Mr. President, put this projet of Mr. Crittenden
                  beside that of the Peace Conference. Look at them placed side by side, and judge which is <PageNumber num={674} /> the more just, clear, comprehensive and patriotic of the two. Here are the first section of the Peace Conference proposition, and the first article of the Crittenden proposition :
                </p>
                <p className="p-in-sp">1st Sec. Peace Conference Proposition.</p>
                <p className="p-in-sp">
                  "SECTION 1. In all the present territory of the United States north of the parallel of thirty-six degrees and thirty minutes of north latitude, involuntary servitude, except in punishment of crime, is prohibited. In all the present territory south of that line, the status of persons held to service or labor, as it now exists, shall not be changed; nor shall any law be passed by
                  Congress or the Territorial Legislature to hinder or prevent the taking of such persons from any of the States of this Union to said Territory, nor to impair the rights arising from said relation; but the same shall be subject to judicial cognizance in the federal courts, according to the course of the common law. When any Territory north or south of said line, within such boundary
                  as Congress may prescribe, shall contain a population equal to that required for a member of Congress, it shall, if its form of government be republican, be admitted into the Union on an equal footing with the original States, with or without involuntary servitude, as such constitution of the State may provide."
                </p>
                <p className="p-in-sp">1st Article Crittenden Proposition.</p>
                <p className="p-in-sp">
                  "ARTICLE 1. In all the territory of the United States now held or hereafter to be acquired, situate north of latitude thirty-six degrees thirty minutes, slavery or involuntary servitude, except as a punishment for crime, is prohibited, while such territory shall remain under territorial government. In all the territory south of said line of latitude, now held or hereafter to be
                  acquired, slavery of the African race is hereby recognized as existing, and shall not be interfered with by Congress; but shall be protected as property by all the departments of the territorial government during its continuance; and when any Territory north or south of said line, within such boundaries as Congress may prescribe, shall contain the population requisite for a member of
                  Congress, according to the then federal ratio of representation of the people of the United States, it shall, if its form of government be republican, be admitted into the Union on an equal footing with the original States, with or without slavery, as the constitution of such new State may provide."
                </p>
                <p className="p-in-sp">
                  There you have the comparison, or more properly contrast, before you. In Mr. Crittenden's programme things are called by their right <PageNumber num={675} /> names. There is no wrapping up, no concealment of intention—all is fair and plain. It is so exceedingly simple and explicit that no child can misinterpret it. You may run and read as you run, and
                  yet it will still be plain and palpable. Like its author, sir, plain ; distinguished for his urbanity, with his heart in his hand, his tongue not given, as Talleyrand declared his to be, "to use words to conceal his ideas," but given to utter words of truth, of power and of force. This Peace Conference proposition palters with us in a double sense, "keeps the promise to the ear, but
                  breaks it to the hope."
                </p>
                <p className="p-in-sp">This [the Crittenden proposition] 
                  tells us of our rights. African slavery is ours. It is to be protected. The Legislatures of the Territories shall protect it, notwithstanding the Chicago platform. Why, sir, let him who can judge choose between that and this. "The counterfeit presentment of two" minds. Those who accept this Peace Conference proposition would find themselves in the dilemma in which Hamlet's mother was
                  when she cast aside her "precedent lord" and married with "the cut-purse of the Empire and the rule." No, sir, there can be no mistaking it. Now my friend
                 [<span className="persName">Mr. SUMMERS</span>] 
                  , thinks that upon the subject of the Territories, he has accomplished a great good by the second section. I do not by any means wish to deny, but that the principle upon which he acts ought to be consecrated. The principle is a lofty one. It is the principle of concurrent majorities. Sir, it is the very principle which will save us. The only principle that can restore this Union
                  which we have lost. If we can have a concurrent majority on all public questions, my deliberate opinion is that all will be safe; majorities to concur before either section can harass or disturb the other. In the crisis of legislation I do not care about dual Presidents, but concurrent majorities are essential. But what does this concurrent majority accomplish when applied solely to
                  territory? Why it gives to the seven States a great power, provided that there is anything for the power to act upon. But there lies the rub.
                </p>
                <p className="p-in-sp">
                  Now look at the North. What of territory remains for them? It has reached the Fraser River. It is in dispute about the island of San Juan, under a treaty with Great Britain. With its foot planted upon the estuary which divides Vancouver's Island from the main land, it has got to its ultima thule of territorial acquisition—nothing lies beyond but the British Fur company possessions,
                  or what may be called the Nova Zembla of the Russian Czar, lying in the lap of perpetual winter, wrapped up in furs against frost and ice. They will not go there I am sure.
                </p>
                <p className="p-in-sp">
                  Does it look to Canada? My friend told us there was a wire-worker <PageNumber num={676} /> in our Peace Conference, who talked to him about Canada, New Brunswick, and so on. A wire-worker! Well, sir, there were a good many wire-workers. But this fellow had his wire broken before he could spin it. I saw some notice of a movement of annexation on the part
                  of Canada to New England, a short time ago. The Canadians and the New Brunswickans said "No, we won't go a step in that direction; we want none of their isms; they are pervading the whole county with their isms; they have their isms of all sorts and descriptions that ever entered into the imagination of man; we prefer to chant our national anthem of 'God save the Queen ;' she affords
                  us protection, she nurtures us and she raises us, and if we are driven into a Confederacy, why we stretch from the extreme waters which wash our shores, to the extreme waters of the Pacific, we embrace all this hunting country." How can the North obtain it? How is this wire-worker to get it? Why, sir, the British colonies would not come if they could.
                </p>
                <p className="p-in-sp">
                  And who does not know all about the Anglo-saxon race? Did ever any Anglo-saxon man or woman part with one single foot of ground they had obtained, without full consideration? Why, the race is noted as a race of land-mongers. Of all nations in the world Great Britain is the last to surrender one single inch of territory belonging to her anywhere on the face of the earth. Queen
                  Victoria and her sceptre must have passed away, the lion rampant will be no longer the symbol of England, before she will surrender to the United States or to any other power those possessions on this continent.
                </p>
                <p className="p-in-sp">
                  But my friend supposed that our Northern friends would come down in hordes and take possession of Central America, unless we had this check upon them. Dear me! Sir, the law of climate as applicable to races, forbids this. A man of the Caucasian race pledged to go and work under a burning sun? Why, these dreamers of the North seemingly know nothing of the influence of climate. They
                  talk of your cotton fields, of your burning sun, it is true. They estimate well the wealth that comes up from these sources. They talk of it, and they talk of free labor. Now I beg to know, while the burning sun is upon the West-India Island, the lands, the richest in the world, and although a so called free soil (under emancipation acts) exists there, and although labor commands the
                  highest prices and a greater demand for it exists than anywhere else, why is it that the vast tide of emigrtion which rolls in from Europe makes no halt there, but passes on until it reaches a high Northern latitude, where the Caucasian is found enacting the part of herdsman or engaged in the cultivation of the cereals? Where he flourishes, the African perishes. Child of the sun, the
                  last luxuriates under tropical <PageNumber num={677} /> heat; creature of a Northern latitude, the Caucasian man perishes where the African flourishes; and so he of Africa's sultry land drags out a brief and wretched existence where the white man becomes vigorous and strong, and attains the full perfection of mental and physical developement. Such is
                  the law of climate, and that law negatives the supposition indulged in by the gentleman from Kanawha.
                </p>
                <p className="p-in-sp">
                  You rarely ever find the Caucasian day laborer in South America, and if an exception presents itself, he is a stranger in a strange land. And to guard against this imaginary acquisition of territory, on the part of the North, you require concurrent majorities which effectually precludes all hope of acquisition on your own part. You must have a majority of nineteen States now, soon to
                  be thirty-six; and a majority of your own Senators which you would readily obtain, and from those majorities make up the two-thirds before you can get Cuba, or the West India Islands, or any one foot of additional territory.
                </p>
                <p className="p-in-sp">
                  I wish to allude to one man, in this connection, who you knew, and who I knew well for the greater portion of my life. I looked upon him almost as the Atlas upon which not only his party, but, in some degree, his country rested. I knew him in the Senate of the United States, when my solitary vote was given against the Force bill, during General Jackson's time. He stepped forth, on
                  that occasion, like a patriot, to heal the discontents of his country, and to cicatrize its wounds. It was Henry Clay, sir. I take pleasure in pronouncing his name in this connection. There were afterwards political feuds between us, but it was his loss and not mine. He threw off the hand that would have supported him. He forfeited the assistance of a friend who admired and esteemed
                  him to the close of his life—one who has no hesitation in saying now, as I said in your presence
                 [addressing <span className="persName">Mr. MACFARLAND</span>] , upon a recent occasion, that he deserves a monument lofty as the mountains, enduring as the skies, for his great measure of pacification, his famous tariff compromise, which gave peace to the country, and bade the raging elements be still.</p>
                <p className="p-in-sp">
                  Well, sir, I am a disciple of his, to some extent, on the subject of the African race. He did not limit his view to the then confines of the United States. He looked from Maryland, Virginia and the other border States in the direction of the West India Islands, or Central America, with the idea that the slave population might ultimately find their outlet in that direction. And yet,
                  in my firm belief, you cannot acquire a foot of land under this provision of the Peace Conference, for it effectually closes the door to further expansion on the part of the South. The only way in which you have heretofore acquired territory has been <PageNumber num={678} /> by a great party in the free States coming to the aid of the South. You could
                  have acquired it in no other way. Louisiana and Florida were obtained in that way. New England opposed them, as she opposes now everything that pertains to the interests of the South, or tends to give it political strength. In like manner, she opposed the acquisition of Texas; and yet it was through the medium of a controlling majority, made up of Northern and Southern votes, that we
                  obtained the acquisition of that country.
                </p>
                <div className="speaker" id="sp653"><span className="persName">Mr. SHEFFEY</span>—</div>
                <p className="p-in-sp">I move, sir, that the Convention do now adjourn, and I hope it will be the pleasure of the Convention to extend the courtesy of the floor to-morrow to the distinguished gentleman who has addressed us.</p>
                <p>The Convention then adjourned.</p>
              </div>
            </div>
            <div className="day" id="vsc1965.v1.2.25">
              <PageNumber num={679} />
              <h2><span className="headingNumber">1.25. </span><span className="head">TWENTY-FIFTH DAY</span></h2>
              <div className="dateline">Thursday, <span className="date">March 14</span></div>
              <p>The Convention met pursuant to adjournment, at 12 o'clock, M., and was called to order by the President.</p>
              <p>Prayer by the Rev. Mr. Baker.</p>
              <div className="section" id="vsc1965.v1.2.25.1">
                <h3><span className="headingNumber">1.25.1. </span><span className="head">PUBLIC MEETING IN LUNENBURG</span></h3>
                <div className="speaker" id="sp654"><span className="persName">Mr. NEBLETT</span>—</div>
                <p className="p-in-sp">
                  I desire to present the following resolutions, which were adopted by my constituents at a meeting on the 11th inst. I will merely remark that these resolutions were adopted unanimously, and that the gentleman who presided at that meeting, has, until very recently, been a submissionist. These resolutions point in but one direction, for my constituents are aware of the fact that there
                  are between two and three hundred thousand of our kinfolk at the South, with whom they are anxious to unite their destinies without delay :
                </p>
                <div className="floatingText">
                  <div className="floatingText_body">
                    <p>On Monday, the 11th inst., the people of Lunenburg assembled at their Court House, it being court day, for the purpose of taking into consideration the great crisis under the galling pressure of which we are now suffering.</p>
                    <p>
                      On motion of R. H. Crawley, David R. Stokes was called to the Chair, and, on motion of John T. Merryman, C. Tacitus Allen<span className="note" id="Note70"><span className="noteLabel">1</span>The name is given as O. Tacitus in the secretary's signature below.</span> was appointed Secretary.
                    </p>
                    <p>Dr. Crawley briefly stated the object of the meeting.</p>
                    <p>Dr. Merryman moved that a committee of five be appointed to draw up and present to the meeting for adoption, resolutions expressive of the sense of the county.</p>
                    <p>Wm. C. Snead, Dr. E. T. Merryman, Col. R. H. Allen, J. R. Garland and Dr. Passmore constituted the committee, who reported the following preamble and resolutions :</p>
                    <p>
                      Whereas, we believe that our proud Commonwealth will be ruined unless the stern voices of her independent sovereigns interpose to arrest the dire calamity; and whereas a certain man called Abraham Lincoln, on the 4th of March, 1861, did disgrace the Presidential Chair of this country ; and, whereas, a certain method of adjustment of political troubles is before the sovereigns of
                      this country for adoption or rejection, which was elaborated and recommended by what was wrongfully called a Peace Congress, we the sovereigns of the county of Lunen-burg, do unanimously adopt the following resolutions, which embrace our well guarded opinions on the present crisis : <PageNumber num={680} /> 1st. Resolved, That secession, direct,
                      straight out, eternal, is the salvation of Virginia.
                    </p>
                    <p>2d. Resolved, That as far as we were concerned, Abraham Lincoln should never have waved his sceptre over the State that boasts of a Washington's grave.</p>
                    <p>3d. Resolved, That we are irreconcilably opposed to any border State Convention, and do hereby request our delegate in the State Convention now in session, to oppose every effort tending to that end, and to press with all his might and ability the immediate and everlasting separation of Virginia from all the non-slaveholding States, and to a union with the cotton States.</p>
                    <p>4th. Resolved, That we regard as coercion the retaking of forts, the collection of the revenue, or the assumption or even maintenance of any power in the seceded States by the Government at Washington, and will resist the same to the point of the bayonet.</p>
                    <p>5th. Resolved, That in the Peace Congress report, we recognize a willful and deliberate encroachment upon the rights of the South, consequently we repudiate it, we scorn and regard it as "the scum that rises when a nation boils."</p>
                    <p>6th. Resolved, That we have ever been and are now opposed to compromise of any character, with Lincoln's party or sympathizers.</p>
                    <p>7th. Resolved, That we are for the South, the whole South, and nothing but the South, so help us God.</p>
                    <p>
                      The preamble and resolutions were received by an intensely enthusiastic meeting, with such plaudits of approval as never before greeted the ears of mortal man, and adopted without a single dissenting voice. No speeches were made. All passed off in all the eloquence of silence, which indicated a determined spirit. The day for speeches has long since passed, and the time for action
                      is present. Upon the brow of every man was written, in unmistakable and indelible characters, my home is in the South, my grave shall be there too. If ever determination characterized the action of any people, it is stamped upon the actions of the people of Lunenburg. In days now gone forever, they cherished a devoted love for the American Union, and for that flag which has
                      floated in splendor over every sea, which has been hailed with rapture in every clime where civilization has made a foot-print. They have witnessed the downfall of the nation's citadel of honor, and now desire to leave its dishonored ruins to the care of those who wantonly undermined its once grand and lofty pillars. They are eager to detach the "old mother of States and
                      statesmen" from the accursed North, ere its fierce and desolating tide of furious fanaticism shall sweep her <PageNumber num={681} /> hallowed soil. Their ardent affections are closely entwined around the destiny of the Old Dominion. May oblivion never shroud her splendor is their only prayer. But if their mother State should ever conclude to cling
                      to the North, in all its hideousness and heinousness, we dare say every citizen of Lunenburg will leave her to the owls and bats of Abolition and seek some spot of earth where the sceptre of Lincoln can never desecrate their grave. DAVID R. STOKES, President.
                    </p>
                    <p>O. TACITUS ALLEN, Secretary.</p>
                  </div>
                </div>
                <p>PUBLIC MEETING IN WYTHE COUNTY</p>
                <div className="speaker" id="sp655"><span className="persName">Mr. KENT</span>—</div>
                <p className="p-in-sp">I hold in my hand the proceedings of a meeting held in the county of Wythe, which I ask to lay before the Convention :</p>
                <div className="floatingText">
                  <div className="floatingText_body">
                    <p>A large number of the people of Wythe county assembled in the Court House on Monday, the 11th day of March, 1861, that being Court day.</p>
                    <p>On motion, Judge Andrew S. Fulton was called to the Chair, and Lewis D. Hancock, Esq., was appointed Secretary.</p>
                    <p>
                      On taking the Chair, Judge Fulton addressed the meeting at length in explanation of the object of the assemblage. He discussed, at some length, the history of the formation of the Federal Constitution, demonstrating that it was a compact between independent and sovereign States, and not the establishment of a consolidated national government. He maintained the proposition that,
                      as each State entered into the Union by her own voluntary act, she could not be compelled to remain in the Union against her own will. He reviewed the history of the aggressions of the North, and analyzed the inaugural address of Mr. Lincoln. He contended that Virginia had done all that was incumbent upon her to maintain her place in the Union, and said that he could no longer
                      see any hope for Virginia except in withdrawing from the Union. He concluded by announcing himself to be compelled to advocate the secession of Virginia from the Union. This announcement was received with enthusiastic applause.
                    </p>
                    <p>R. G. Carrington, Esq., offered the following resolutions and moved their adoption :</p>
                    <p>Resolved, That in the opinion of the people of this county, there is no longer any hope that the safety, the rights, the interest and the honor of Virginia can be preserved in the Union as at present constituted and controlled ; and that her political connection with that Union ought to be severed at the earliest moment.</p>
                    <p>Resolved, That the Convention now in session, in Richmond, ought, <PageNumber num={682} /> at once, either to adopt such a course as will withdraw Virginia from</p>
                    <p>her present connection with the Federal Union or to adjourn sine die.</p>
                    <p>
                      Resolved, That the said Convention owes its existence only to the necessity for some action in reference to the troubles in which Virginia is involved with the Union and the non-slaveholding States, and not in reference to her internal affairs; that questions of taxation, representation, and other domestic matters, were not agitated nor considered in the election of the members
                      of the Convention; that it would be improper, unjust and unfair for the Convention to take any action in regard to these matters ; and we do protest against any action on the part of the Convention touching anything except the difficulties in the relations of Virginia towards the Federal Union and the non-slaveholding States.
                    </p>
                    <p>Wm. H. Cook, Esq., being called upon, addressed the meeting at some length in support of the resolutions. Dr. N. Crockett and Robert Crockett, Jr., addressed the meeting in opposition to the adoption of the resolutions, and Mr. Cook replied to their remarks. The discussion was an animated one and elicited much interest.</p>
                    <p>The vote was then taken on the first resolution, and the Chair announced that, by the sound, the resolution was adopted, but a division was demanded ; a count was had, when it was ascertained there were only 20 opposed to the resolution and the remainder in favor of its adoption, consisting of between 2 and 300 persons, so that the resolution was adopted.</p>
                    <p>The Chairman then asked leave to offer a substitute for the second resolution, which substitute is in these words :</p>
                    <p>
                      Resolved, That in the unanimous opinion of this meeting, Virginia will not submit to coercion on the part of the Federal Government of any seceding State, and we regard the collection of the revenue claimed to be due from the seceding States, by force, and the possession of the forts in said States, by force of arms, as coercion, and we will resist, to the last extremity, any
                      coercive policy to which the Federal administration may choose to resort in respect to any or all of these matters.
                    </p>
                    <p>This substitute was accepted and it was then adopted.</p>
                    <p>The third resolution was adopted without any opposition.</p>
                    <p>
                      On motion, it was ordered that a copy of the proceedings of this meeting be forwarded to <span className="persName">Mr. R. C</span>. Kent, our delegate to the Convention, to be laid before that body, and that they be published in the Wytheville papers, with the request that the papers published in <PageNumber num={683} /> North-Western Virginia, in
                      Lynchburg, and in Richmond, will copy the same; and then the meeting adjourned.
                    </p>
                    <p>AND. S. FULTON, Ch'n.</p>
                    <p>L. D. HANCOCK, Secretary</p>
                  </div>
                </div>
                <div className="speaker" id="sp656">[<span className="persName">Mr. KENT</span>:]</div>
                <p className="p-in-sp">
                  The gentlemen mentioned here as taking part in this meeting are gentlemen of the highest respectability. It was a meeting that was presided over by a gentleman well known, not only to many members upon this floor as occupying a high position in this State as the Judge of my Judicial Circuit, but as connected with various positions of a State and national character, which entitle him
                  to meet with a respectful consideration from this Convention. I do not regard these resolutions as containing any instructions to me, because the sentiments which I entertained and do still entertain were well known to the people who sent me here. There is, however, one of these resolutions which I feel warranted in making a comment upon, the second resolution, in relation to this
                  Convention taking into consideration any matters other than those which touch our federal relations and our connection with the non-slaveholding States. That resolution meets with my cordial approbation.
                </p>
                <p className="p-in-sp">
                  I have always understood that by the Convention of 1851, which changed the organic law of our State, and which inserted a clause in that Constitution prohibiting any change in the basis of representation until the year 1865, it was a tacit understanding between the various sections of our State that no change was to be made in relation to representation, or to taxation of a peculiar
                  species of property in our State, prior to a change in the representation of the various portions of the State. I would regard it, to say the least, as a breach of faith between the sections. It is exceedingly problematical, if there was any question of mere faith involved between the two sections, whether it would be of interest to the portion of the State from which I come, or
                  indeed, to that vast portion of Virginia which lies North and West of the Blue Ridge and the Alleghany Mountains. If things were to transpire, as it is anticipated they may transpire, so that there shall be an exodus of slavery from Eastern Virginia, or an insecurity in slave property in Eastern Virginia, and the burthen of taxation is compelled to fall upon other species of property
                  in the State, those who are here endeavoring to bring this question into this Convention may be caught in their own trap. I am not in favor of changing the organic law of the State, and this resolution, therefore, meets with my cordial approbation. <PageNumber num={684} /> The proceedings and resolutions were referred to the Committee on Federal
                  Relations.
                </p>
                <div className="speaker" id="sp657"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">The pending question is on the motion to refer to the Committee on Federal Relations, the report of the Virginia Commissioners at the late Peace Conference. On this question, the gentleman from Charles City [Ex-President TYLER] , is entitled to the floor.</p>
              </div>
              <div className="section" id="vsc1965.v1.2.25.2">
                <h3><span className="headingNumber">1.25.2. </span><span className="head">EX-PRESIDENT TYLER'S SPEECH</span></h3>
                <div className="speaker" id="sp658"><span className="persName">Ex President TYLER</span> resumed his remarks, and said :</div>
                <p className="p-in-sp">
                  Mr. President, I am deeply grateful, sir, to the members of the Convention for the kind indulgence which they granted me yesterday. I found much difficulty in causing the views which I addressed to the Convention yesterday, to be heard, because of a great deficiency in my voice. I feel similarly circumstanced this morning. A voice which has heretofore never failed, has become subdued
                  by severe and continued cold, until it has lost most of its distinctness and compass.
                </p>
                <p className="p-in-sp">But I make no apology for the circumstances under which I appear before you. I am in the discharge of a great public duty; and in the discharge of that duty I suffer no impediment arising from my own personal infirmities to stand in the way.</p>
                <p className="p-in-sp">On yesterday I dissected, as far as I could, the first section of the article proposed by the "Peace Conference." I find, however, that I omitted a fact which has much bearing upon the case.</p>
                <p className="p-in-sp">In the course of our proceedings at Washington, and looking to some safe guarantee of protection against the influence of propagandism through the officers of the Territory, I deemed it proper to offer the amendment which I hold in my hand. I will read it with the consent of the Convention.</p>
                <p className="p-in-sp">
                  "All appointments to office in the Territories lying North of the line 36 deg. 30 min., as well before as after the establishment of Territorial governments in and over the same, or any part thereof, shall be made upon the recommendation of a majority of the Senators representing at the time the non-slaveholding States. And in like manner, all appointments to office in the
                  territories which may lie South of said line of 36 deg. 30 min. shall be made upon the recommendation of a majority of the Senators representing at the time the slaveholding States. But nothing in this article shall be construed to restrain the President of the United States from removing, for actual incompetency or misdemeanor in office, any person thus appointed, and appointing a
                  temporary agent, to be continued in office until the majority of <PageNumber num={685} /> Senators as aforesaid may present a new recommendation, or from filling any vacancy which may occur during the recess of the Senate, such appointment to continue ad interim. And to insure, on the part of the Senators, the selection of the most trustworthy agents,
                  it is hereby directed that all the net proceeds arising from the sale of the public lands, shall be distributed annually among the several States, according to the combined ratio of representation and taxation; but the distribution aforesaid may be suspended by Congress in case of actual war with a foreign nation, or imminent peril thereof."
                </p>
                <p className="p-in-sp">
                  That proposition I submitted, and by the courtesy of the Convention and no body was ever more courteous than it was to me—the ten minutes' rule, which had been proclaimed as the limit of debate, was immediately suspended, it being understood that I intended to discuss the propositions, and my own time was allowed me, to take what range and latitude of debate I might deem necessary. I
                  accordingly entered into an explanation of the proposition in all its parts and bearings. I maintained that some security was necessary against the influence which the President, through his patronage, might exercise in the Territories. It was even without such intention natural that the President should bestow the emoluments of office on those who held opinions corresponding with
                  his own. The propagandism of anti-slavery sentiment would inevitably proceed from the Governor, Lieutenant Governor, and the long list of other officers to be appointed by Mr. Lincoln to these Territories. I sought to guard against this by the proposition to amend, so as to give substantially to the majority of Senators on each side of the line of 36 degrees and 30 minutes forever
                  the virtual appointment of officers in the territories lying respectively North and South of that line. I stated in that Assembly a fact which it is not out of place to repeat here, that sixteen years ago, when I left the government, we had reduced the public expenditures from thirty-two millions to twenty-four millions per annum. And yet we have in no degree impaired the efficiency
                  of the public service, but had added to our naval defence the Home Squadron; had enlarged the basis of our military operations ; had increased the strength, and the efficiency of the army—and manned our fortifications, and all at the low rate of expenditure mentioned. Within this short period of sixteen years, which has since elapsed, we find these expenditures swollen up, of 60, 70,
                  80,000,000—I know not what in fact. I cannot account for this vast expenditure. Nor does the evil stop with the expenditures from the Treasury. The public lands, that great source of public credit in times of embarrassment, are spoliated on with a merciless prodigality—land-grants, <PageNumber num={686} /> homestead bills and railroad donations, all
                  combined, seem to threaten an extinction of all public interest in the preservation of this great inheritance. It was time, I thought, to interpose with that great remedial measure of distributing the proceeds of the public lands equally among the States, according to the ratio of representation and taxation. But, furthermore, in the division of territory, there had been given to the
                  Northern States fully four-fifths of the territory of the United States. The remaining fifth being on our side of the line, I sought to equalize, to some extent, the division, by making each State a participant in the proceeds of the sales of the whole of the lands.
                </p>
                <p className="p-in-sp">
                  But above all, Mr. President, I desired to restrain that wild spirit of propagandism which I knew would inevitably influence those who would be appointed to offices in the territory under the new administration. I knew that the man who was in the Presidential office would appoint his own friends to office. It was natural that Mr. Lincoln should do so, and I took for granted that the
                  Chicago platform would be the Constitution of every man of them. I regarded the rejection of this proposition as a vital objection to that first section, and my friend near me
                 [<span className="persName">Mr. SUMMERS</span>] 
                  and the whole delegation from Virginia came up as one man and voted for it. They gave me their cordial support, and yet what vote do you suppose we obtained for it? Plain, rational, just as it seemed to be and really was—what sort of vote do you suppose we got for it? There were five States voting for it, and all the rest against it! It would not do to restrain Mr. Lincoln in the
                  giving of his patronage; they wanted to give him full sway. I was told by some, we will vote for your proposition in 1865, but we cannot vote for it now. Yes, sir, they would vote for it after the mischief will have been done —after the propagandists shall have accomplished their purposes. The Chicago platform will then have been carried into full effect. I repeat, that my colleagues
                  came up to the spirit of the proposition which I offered, and Virginia was a unit in favor of it; and I would now have it incorporated in any pro jet that may be offered to this Convention.
                </p>
                <p className="p-in-sp">Sir, I shall now endeavor to hurry on as fast as I can with my commentaries upon the remaining sections.</p>
                <p className="p-in-sp">
                  The third section, if you examine it, will be found to be deprived of its principal value by the adoption by two-thirds of both houses of Congress of the Corwin amendment. Why that amendment has not been submitted to us, for our consideration, I do not know. The section secures us against the abolition of slavery in the District of Columbia, and in places under the exclusive
                  jurisdiction of Congress, within the States and Territories, where slavery exists. My friend thinks that we <PageNumber num={687} /> gain much by the succeeding clause of the section which gives us the right to transport our slaves by sea or river, to touch at ports, shores or landings, and of landing in case of distress. I cannot place the same high
                  value upon it that he does. This right stands so under the international law—that law which is co-extensive in its operation with the civilized world—no country, however powerful, can violate it without incurring universal odium and denunciation. How was it in the case of the Creole, which we settled under the Ashburton treaty? You recollect that the Creole sailed from this port with
                  a cargo of slaves for a Southern State. Her crew rose up against her officers, took possession of the ship and carried her into Nassau, New Providence, where the slaves were taken from the ship by the populace and all set free. We made a demand of Great Britain, charged that she had violated the rights of hospitality, and the comity that exists between nations. The amende honorable
                  was made by Lord Ashburton in his negotiations with us at Washington. The Executive would have accepted no result which had not embraced an honorable adjustment of the principle involved in the case of the Creole.
                </p>
                <p className="p-in-sp">
                  So that you perceive that this is not a new thing. It is no more than the confirmation of a principle in the international code by a Constitutional enactment. Unhappy state of things, truly, when a principle of comity and good feeling, observed by all nations in their intercourse with each other is, in order to be recognized as obligatory by confederates, to be incorporated as a
                  guarantee in the fundamental law. How lamentably deplorable is that state of things which renders it necessary.
                </p>
                <p className="p-in-sp">In regard to the last clause of this section, which my friend [<span className="persName">Mr. SUMMERS</span>] 
                  , laid so much stress upon. We have simply caught a Tartar. I do not know that it would not be fortunate if the whole measure was rejected in order to get rid of this. I think no objection was made to it, and being urged by one of our own members, we all voted for it. I allude to the final clause of the section, which declares that Congress shall not authorize any higher rate of
                  taxation on persons held to labor or service than on land.
                </p>
                <p className="p-in-sp">
                  I remember to have heard an anecdote which is somewhat illustrative of the great sagacity and aptitude for business of our Northern associates. It occurred upon the meeting of the first Congress. Patrick Henry and Richard Henry Lee opened the session in terms of impressive eloquence. Samuel Adams, that illustrious man who is recognized as one of the most pure, noble and patriotic
                  statesmen of the glorious age in which he lived, is reported to have said to one of <PageNumber num={688} /> his colleagues; "we have no business here, let us go home; these people will settle this thing without us." But, the Congress went shortly afterwards to work, and something else than eloquence was required. Astute and quick perception were
                  necessary to carry out the political problems of the day—and practical results were only to be attained by that practical good sense, the nurseling of experience and observation
                </p>
                <p className="p-in-sp">and Adams very soon approached his colleague with the remark, "we had as well stay where we are." They understood this thing very well. Sagacious, keen, intellectual, the Northern men are capable of doing their work in the best way and after the best manner, and I fear they have, by silent acquiescence, done so in this instance to our disadvantage.</p>
                <p className="p-in-sp">I confess to the fact that I did consider the provision a valuable one when I voted for it. But, sir, subsequent reflection and consideration has greatly unsettled my opinion concerning it, and I find, upon examination, the whole case was decided as long ago as 1793, by the Supreme Court.</p>
                <p className="p-in-sp">In the case of Hylton, plaintiff in error, vs. the United States, a decision was rendered by the Supreme Court of the United States, from which I extract the following language :</p>
                <p className="p-in-sp">
                  "The great object of the Constitution was, to give to Congress a power to lay taxes adequate to the exigencies of government; but they were to observe two rules in imposing them, namely, the rule of uniformity, when they laid duties, imposts or excises; and the rule of apportionment according to the Census, when they laid a direct tax. A general power is given to Congress to lay and
                  collect taxes of every kind or nature without any restraint, except only on exports; but two rules are prescribed for their government, namely, uniformity, and apportionment; three kinds of taxes, to wit : duties, imposts and excises by the first rule, and capitation or other direct taxes, by the second rule.
                </p>
                <p className="p-in-sp">
                  "The Constitution evidently contemplates no taxes as direct taxes, but only such as Congress could lay in proportion to the Census. The rule of apportionment is only to be adopted in such cases where it can reasonably apply; and the subject taxed, must ever determine the application of the rule." Paterson, justice, said : "I never entertained a doubt that the principal, I will not
                  say the only object that the framers of the Constitution contemplated as falling within the rule of apportionment, were a capitation tax and a tax on land. Local considerations, and particular circumstances and relative situations of the States, naturally lead to this view of the subject. The provision was <PageNumber num={689} /> made in favor of the
                  Southern States. They possessed a large number of slaves; they had extensive tracts of territory, thinly settled, and not very productive. A majority of the States had but few slaves, and several of them a limited territory, well settled and in a high state of cultivation. The Southern States, if no provision had been introduced in the Constitution would have been wholly at the mercy
                  of the other States. Congress, in such case, might tax slaves, at discretion, or arbitrarily, and land in every part of the Union after the same rate or measure, so much a head in the first instance, and so much an acre in the second. To guard them against imposition in these particulars, was the reason of introducing the clause in the Constitution, which directs that representatives
                  and direct taxes shall be apportioned among the States, according to their respective numbers." He argued, further, that the rule of apportionment was not to be preferred to the rule of uniformity; but the former was to be applied only where the Constitution imperatively requires it to be applied, to wit, to "capitation or other direct taxes," and where it would reasonably apply. He
                  considered it radically a wrong rule, and asks, "why should slaves, who are a species of property, be represented more than any other property"?
                </p>
                <p className="p-in-sp">In that opinion all the judges concurred, save one, who gave no opinion.</p>
                <p className="p-in-sp">Well, it has occurred to me that, inasmuch as the apportionment is to be made according to taxation and representation combined, we have by that clause subjected ourselves to a capitation tax upon the head of every slave, when it should only apply to every three out of five. I think I am sustained in that position by this decision.</p>
                <p className="p-in-sp">Again, sir, we find in the case of Loughborough vs. Blake, 5 Wheaton, 317, the following language by Judge Marshall:</p>
                <p className="p-in-sp">It was under these clauses, to reconcile and expound them, that the decisions of the supreme court referred to, were made. Federal taxation, it is shown, consists of two classes of taxes :</p>
                <p className="p-in-sp">1st. Direct; supposed to be capitation and land tax alone. And,</p>
                <p className="p-in-sp">2d. Indirect taxes, embracing duties, imposts and excises. And,</p>
                <p className="p-in-sp">1st. The first are always to be apportioned with representatives among the several States, according to their respective numbers of described persons; and among these are three-fifths of the slaves; and no direct tax can ever be laid, unless in proportion to this census thus described to be enumerated; and,</p>
                <p className="p-in-sp">2d. The second, consisting of duties, imposts and excises, are always to be "uniform throughout the United States;" and,</p>
                <p className="p-in-sp">
                  3d. Congress may or may not tax the territories, as included under <PageNumber num={690} /> the first clause of section 8th, in the United States, but not necessarily included among the "several States," in the apportionment required by the 3d clause of section 2d; but if Congress, or power derived from Congress, does tax the territories as well as
                  States, it must be done by one of the two rules above, of apportionment or uniformity. And,
                </p>
                <p className="p-in-sp">4th. If slaves, or "other persons," in States or territories, are taxed by Congress or its authority, it must be done under the clauses apportioning representatives and capitation or other direct taxes, taxes upon persons being capitation and direct taxes throughout the United States.</p>
                <div className="speaker" id="sp659"><span className="persName">Mr. SUMMERS</span>—</div>
                <p className="p-in-sp">I don't desire at all to interrupt the argument at this point, or to occupy the floor further than to call attention to the fact, which my distinguished friend from Charles City [<span className="persName">Mr. TYLER</span>] 
                  , will readily call to mind, that the minority report submitted by <span className="persName">Mr. SEDDON</span>, from the Committee of States, which prepared business for the Convention and of which Committee our excellent friend <span className="persName">Mr. SEDDON</span> was the Virginia member, proposed the Crittenden propositions with certain emendations and additions, and among other
                  modifications of the Crittenden proposition, this same feature is contained as proposed in the minority report of our colleague. It is annexed to the 4th article of the Crittenden proposition :
                </p>
                <p>"And in imposing direct taxes pursuant to the Constitution, Congress shall have no power to impose on slaves a higher rate of tax than on land, according to their just value."</p>
                <div className="speaker" id="sp660"><span className="persName">Mr. SUMMERS</span>—</div>
                <p className="p-in-sp">When that was submitted by our colleague as a substitute for the report of the Committee, the Virginia Commission voted for it as a unit.</p>
                <p className="p-in-sp">I merely call the attention of my distinguished friend to the fact, that it was considered not only by us, but by all the Southern men, as a most vital and important amendment to the Crittenden projet.</p>
                <div className="speaker" id="sp661"><span className="persName">Mr. TYLER</span>—</div>
                <p className="p-in-sp">I am perfectly aware of the facts stated by my honorable friend. They are all correct. We concurred in the adoption of that section with that addendum. I frankly confess the fact before the Convention, and here I express my regret at our having fallen into the error.</p>
                <p className="p-in-sp">I used, moreover, the words that we had "caught a Tartar" by the adoption of this clause which was recommended to us by the circumstances adverted to by my honorable friend—but I renounced my own error the moment I became convinced of it.</p>
                <p className="p-in-sp">I am hurrying rapidly through my task of dissecting the various sections of this article; and now I come to the 4th section. If gentlemen <PageNumber num={691} /> will look at it, they will find probably no objection to it, because it merely re-affirms what exists now in the Constitution.</p>
                <p className="p-in-sp">The 5th section we voted against, unanimously. That, as you may remember, declares that,</p>
                <p className="p-in-sp">"The foreign slave trade is hereby prohibited; and it shall be the duty of Congress to pass laws to prevent the importation of slaves, coolies, or persons held to service or labor, into the United States and the Territories, from places beyond the limits thereof."</p>
                <p className="p-in-sp">My friend [<span className="persName">Mr. SUMMERS</span>] , has made the best apology for that section that could be made, in the address that he delivered. But against it, Virginia stood as a unit. And why?</p>
                <p className="p-in-sp">We look to the probable arrival of a period when the independence of the Southern Confederacy will be recognized by the whole country. Whether we might have a separate Confederacy along side of us, made up of other States, or whether we would belong to the Confederacy whose seat of Government is at Montgomery or not, was not the question.</p>
                <p className="p-in-sp">
                  We contended and voted against the proposition on the grounds that a prohibition being made by the government to which we now belong, in regard to the inter State slave trade from that quarter, would have the effect of producing a similar interdict on the part of that government at a future day, and in that way a demand upon us for supplies of labor would altogether cease. I remember
                  the time that Virginia prevented the introduction of slaves within her borders by statute from other States, and I also remember that similar prohibitions soon afterwards followed from most if not all of the Southern States, as retaliatory on us. Such a policy would be again resorted to by this Southern confederacy, when it shall come to be recognized, in case this provision was
                  enforced; and the result would be to put a stop to the profitable drain of this species of property. It was a desire to avert this result, and not the remotest wish to re-open the African slave trade, that induced us to vote against this section. So far as the re-opening of the African slave trade is concerned, I protest against it. I believe there is no man now in Virginia who is in
                  favor of the restoration of that trade. I protest against it with all my heart, with all my strength. I would not suffer it to be re-opened by any State, either in this Union, or of the American hemisphere, if it depended upon my voice to prevent it. I should deny my own paternity if I failed to do so. My venerated father made the continuance of that trade to 1808 one of his leading
                  objections to the adoption of the present Constitution of the United States in the Convention which gave to that instrument <PageNumber num={692} /> the ratification of Virginia. I adopt his views and shall endorse them so long as life shall last.
                </p>
                <p className="p-in-sp">The 6th section is merely a re-confirmation, if I may be allowed such an expression, of certain sections of the Constitution as it is.</p>
                <p className="p-in-sp">And now I come to the last or 7th section, which I shall dispose of in very few words :</p>
                <p className="p-in-sp">
                  "Congress shall provide by law that the United States shall pay to the owner the full value of his fugitive from labor, in all cases where the marshal or other officer, whose duty it was to arrest such fugitive, was prevented from so doing by violence or intimidation from mobs or riotous assemblages, or when, after arrest, such fugitive was rescued by like violence or intimidation,
                  and the owner thereby deprived of the same; and Congress shall provide by law for securing to the citizens of each State the privileges and immunities of the several States."
                </p>
                <p className="p-in-sp">
                  It might be sufficient to say that we were united against that provision. I had hoped that it would require no explanation at my hands. It received all explanation at the hands of my honorable colleague, in which I could not concur. I felt that that explanation was rather in contravention of the views I entertained, and therefore I begged him to urge the objections which he had had
                  to the section when we voted unitedly against it. It was considered by some of us at least that that section was, in effect, the initiation of a course of emancipation throughout the extensive borders of the slave States; that there would be every inducement on the part of the slave to cross the border, and every inducement for mobs to assemble and prevent the arrest of the fugitive,
                  or to secure his escape. The master himself would be less in earnest to recover possession of the fugitive, as he had to be paid for him by the Government. Nay, the marshal himself—I should have but little confidence in marshals of Mr. Lincoln's appointment— might wink at the whole proceeding, and thus a great Pandora's box would be opened all along the border. Instead of such a
                  provision as this quieting agitation it would re-open it. But, strong as those objections occurred to me to be, our chief position, as I understood it, was leveled at the last clause. Under the Constitution, when a citizen travels abroad from his own State, he should enjoy the rights, privileges and immunities which are enjoyed by the citizen of the State to which he goes. That
                  provision is broad enough, capacious enough and covers the whole ground. It wants no legislation to enforce it. Then it is plain as words can make it. But Congress is to be invested with full discretionary power to legislate upon this subject. In Massachusetts, if I am not deceived, and in some other States, they proclaim <PageNumber num={693} /> that a
                  portion of their so called citizens, are of the African race. They are admitted to an equality with the whites at the polls. Being professedly citizens of that State why sir, the toils might be thrown over us, without our being aware of it, through an act of Congress. Congress might legislate them even into seats in that body, and you might have Fred. Douglass occupying a seat there.
                  He is one of their leading men, one of their principal orators upon all the disturbing questions connected with the slave interest of the country. He is ever thrusting himself into everything. The last time I heard of him he was at Faneuil Hall, attempting to address some assemblage held there. Faneuil Hall, that glorious hall, consecrated to freedom, and around whose altars their
                  fathers and our fathers have so often knelt together—to be desecrated by the ravings of a runaway slave! I could not tolerate for a moment the idea of putting my honorable friend
                 [<span className="persName">Mr. SUMMERS</span>] —and I trust that some day he will be clothed with a higher representative power, either at Washington or Montgomery—down by the side of Fred. Douglass, in the Senate of the United States, cheek by jowl, and in fellowship with him as his fellow citizen. "Give me some civet, good apothecary, to sweeten my imagination."</p>
                <p className="p-in-sp">Now, Mr. President, what do you make of this thing? Let us see what is left of it. I have demonstrated, that so far as grants of the public land are concerned, it is a mere myth—a gossamer of the air—something that fades before you as a will-o'-the-wisp; that vanishes into thin air, so that you cannot lay your hand upon it.</p>
                <p className="p-in-sp">
                  As to the first section, therefore, it is of no consequence at all. That is my deliberate judgment. Your committee of twenty-one will have it under their consideration. The first section, then, is worthless. The second I demonstrated to be injurious, as preventing the acquisition of any new territory. The third is superseded in its material part by Mr. Corwin's amendment, which has
                  passed the two Houses of Congress; and whatever remains of it is, in my deliberate judgment, not compensated for by the negation of the right of transit through the States. The fourth section is already provided for in the Constitution of the United States. The fifth and seventh were voted against unanimously by the Virginia Commissioners. What remains of the article then? Why, it is
                  all gone, dissipated, according to the interpretation I put upon it. It was, in truth, but a phantom from the first.
                </p>
                <p className="p-in-sp">Since it introduces nothing which is tangible and of value, that should have been provided for, what is omitted by it? I will recapitulate, very briefly, what it has omitted :</p>
                <p className="p-in-sp">First. Lands all gone and none to be acquired. <PageNumber num={694} /> Second. No right of transit.</p>
                <p className="p-in-sp">Third. No rendition of fugitives from justice.</p>
                <p className="p-in-sp">
                  A negro kills his master in Virginia, and flees into Ohio; Governor Dennison turns over his volumes, and he cannot find, with the aid of all his spectacles, that in the State of Ohio, a slave killing his master can be punished as for a crime. One of John Brown's raid flees to Iowa. A requisition is made upon the Governor for the man who has violated your own homestead, and who has
                  dared to tread with impious footsteps upon Virginia Soil. You make a demand upon the Governor for him; but the authority cannot be found in the bond which authorizes his restoration. Like the Jew Shylock, he cannot find it stipulated in the bond, and the murderer and the incendiary goes free.
                </p>
                <p className="p-in-sp">Fourth. No repeal of personal liberty bills demanded.</p>
                <p className="p-in-sp">
                  Let me here do justice to the gallant little State of Rhode Island; prompt in the execution of her duty, the very instant there was a requisition of the kind made upon her by the public voice, she repealed her personal liberty bill. Has she been followed by Massachusetts? Will the exultant party in New Hampshire that is now lifting more loftily its banner of victory than ever before,
                  be likely to follow the example of Rhode Island? Will they make any concession? especially when they have again triumphed, and by a larger majority than heretofore?
                </p>
                <p className="p-in-sp">What else?</p>
                <p className="p-in-sp">Fifth. No security against under-ground railroads.</p>
                <p className="p-in-sp">
                  Mr. President, have you not been struck with the perfect silence which has prevailed on the part of these under-ground railroads of late? We have not heard of a single passenger over that route since the present agitation commenced, and since South Carolina first entered upon her course of separation. It seems to be abandoned. What inference do you draw from that circumstance? That
                  the whole matter is under the management and control of the leaders—under their dictation and constant control. If they put it in motion, it is in motion. If they bid it stand still, it stands still. You have heard nothing of it now for months, but you may expect developements from it bye and bye.
                </p>
                <p className="p-in-sp">Sixth. No restraint upon the circulation of Helper's book or pamphlets through the Post Office.</p>
                <p className="p-in-sp">Can you restrain their circulation, in the absence of any provision, through the Post Office? New men are in power. Their friends and allies are clothed with the investiture of office. But I will not stop to discuss that matter further.</p>
                <p className="p-in-sp">
                  The whole of this pro jet is the work of a minority. Congress repudiates <PageNumber num={695} /> it through non-action. It was presented to that body for ratification or rather for recommendation to the States. The Premier looked at it closely. The Premier of this Administration, which has been installed in office by blast of trumpet and roll of drum—
                  this Administration that has come stealthily in by night into the Presidential chair which was filled by a Washington, and which, under some vague pretence of hidden assassins lurking in ambush, surrounds itself with a guard of armed men—the Premier of this Administration, I say, glanced at it, and what does he propose? After midnight conferences with our friends, pretendedly
                  inclining to compromise and settle it, why he proposes a National Convention.
                </p>
                <p className="p-in-sp">"Will you walk into my parlor, said the spider to the fly; It is the prettiest little parlor that ever you did spy." [Laughter.] That is exactly the parlor in which you are invited to walk. Go into it and the fate of the poor fly is yours.</p>
                <p className="p-in-sp">
                  Now, Mr. President, I want seriously to know of this Convention, what are you going to do? You cannot stand still. That is not possible. Events are moving too rapidly around you. The times are pregnant with signs. Even if you were disposed to take a little more slumber, you cannot fold your arms and sleep soundly. You must do something. And what is that something? On one day there
                  comes forth a smile from the White House; but, lo! the next day it is chased away with a frown. On one day we hear that Fort Sumter is to be abandoned—the next that the Star of the West with supplies is ready to sail from New York for some point on the Southern coast. I would fain hope that the news is authentic that Fort Sumter is to be evacuated and that that gallant man, Major
                  Anderson, is to be relieved from a condition which becomes more desperate every day. A fine gallant boy, the son of an esteemed and respected friend of mine, whom I have known for many years, and whose father was familiar with my father—our present Minister to Brazil—is now shut up in that fort. I know that he is prepared to die in the discharge of his duty. He would not be a
                  Virginian if he were not. But his father is 2,000 miles away, representing his Government in a foreign Court. I thought I would wait upon. President Buchanan, and try to interest him in behalf of Major Anderson and those under his command. I urged the impossibility of sending relief—I saw no chance for a successful defence. I represented to him, in the mildest terms I could, the
                  condition of that noble boy. I adverted to the state of the garrison; that there were only seventy-odd <PageNumber num={696} /> people to man the guns; that after all this vaunting about the strength of the fort, there were no more than enough soldiers to man six guns, and the ability would fail even to man them, after a few days of conflict. Why not,
                  then, relieve and discharge the garrison? They are ready to perish in defence of their duty—why let them perish? Why shall your robes be stained with the blood of your officers, when you have but a few days more to remain in office. The reply in substance was "duty." I could not feel it—I could not comprehend it—but there it was. I was rejoiced when I understood that the present
                  occupant of the Presidential Chair had decided to abandon the fort. He can do nothing else. He is in a strait-jacket; he cannot throw in supplies, neither can he retain possession of the fort much longer. But I do not altogether like the appearance of the news of this morning—yesterday the dove was let loose from its cage in evidence that bitter waters were subsiding—to-day the hawk
                  is unhooded and flies on rapid wing to overtake the dove. I trust yet that there will be good sense enough on the part of the Administration to induce an evacuation of that fortress as well as others, without a conflict of arms.
                </p>
                <p className="p-in-sp">
                  There are many things that look alarming. The Star of the West and the Daniel Webster are at New York with supplies, ready to sail for the Southern coast. And a newspaper paragraph announces that General Scott has been studying out a plan by which Fort Sumter can be reinforced. This paragraph I do not altogether credit, because I do not believe that the old war-worn chieftain,
                  although he may have lost some of the chivalric spirit which formerly illustrated his character and his fame, has yet gone to that desperate extremity of trying by shift and cunning to accomplish what noble-hearted men will ever try to accomplish only by direct action. Mr. President, I wish not only that Fort Sumter and Fort Pickens might be abandoned, but that the President would
                  lift himself up to a higher and loftier pinnacle of statesmanship and at once yield to the propriety of a recognition of the Southern Confederacy. A commercial treaty and a treaty of alliance offensive and defensive with them, would save much of the Union under which we have all lived so long and happily. If all cannot be saved, save as much as can be saved, even of the fragments;
                  for every fragment will be a gem glorious and priceless. But I fear that this policy is not to be their policy. Italy can rise up from the thraldom of centuries and win the bright coronet of free government. The iron crown of Austria may be removed from the brow of its wearer to do honor to Hungary. But Mr. Lincoln recognizes no such principle as lying at the base of American
                  institutions, as the right of the people <PageNumber num={697} /> of any of these States to seek their happiness under any other government than that inaugurated by himself, of a sectional majority. Hence the Pacific and Mediterranean fleets are, it is said, ordered home, to cluster about our coast! Hence, the whole border, stretching off to California,
                  is to be left exposed to the attacks of the savages, by withdrawing from it the two thousand five hundred regular troops. Rumor speaks of a portion of these troops being stationed at Washington as a strategic point. If it be accurate, I shall regard it as "bearding the lion in his den, the Douglas in his hall." It looks like a strategic operation to coerce Virginia, and keep you
                  under subjection and control. Virginia is the bright star that now fixes the attention of the country. Every eye is turned to her. I fear that the game is to hold Virginia in thraldom, if possible. If it can be done by the practice of chicanery and smiles, she will be kept in her present attitude of inaction. Depend upon it, all means will be resorted to to accomplish this object.
                  Troops may be concentrated in considerable numbers at Fort Washington and Fortress Monroe. In that event what will be the condition of Alexandria and Richmond—Fort Washington upon the Potomac, garrisoned and not a barrel of flour, not a hogshead of tobacco, no article of commerce, can float by without being under the range of its guns. If Fortress Monroe is to be garrisoned by 5,000
                  men, as speculation has sometimes intimated, the trade of Richmond—that trade which floats down the James, the York, the Rappahannock, and the other rivers of the Commonwealth, is essentially blocked out from the ocean by a ship of war stationed in the Bay, to co-operate with the Fort. Look at it, I pray you, and let your action here anticipate, and as far as it can avail, guard
                  against the state of things which may soon exist.
                </p>
                <p className="p-in-sp">
                  What else presents itself to you? There is a hint given you from a quarter which speaks almost in tones of thunder. What means the resignation of those, as it were cabinet officers of the army? These aids and chief officers, including the adjutant-general of the army? Why do they resign the fat emoluments of their offices? Why are they seeking employment elsewhere? I put it to your
                  own sagacity to say, for there must be something of distrust and a want of confidence, when these things happen. I repeat the question, what will you do? What is Virginia to do in this state of things? This I know she cannot do in safety : She cannot rest upon any inefficient measure. Mark you, how things have gone. I trust I shall be pardoned by the Convention for the feeble and
                  tedious manner in which I am exhibiting my views upon this great question. I feel that I am surrounded by a band of brothers. We are to speak our sentiments freely to each other. In that <PageNumber num={698} /> spirit I ask you, what sort of guarantees will Virginia require? Mere reiterations of constitutional enactments which already exist? No sir,
                  that will be of but small avail. For 50 years in the history of the government, you had a positive guarantee of powers—you could not be injured—no sectional party could disturb you, because you had an equal balance of power in the Senate. There you had a check. For a long course of time, when a slave State was introduced, a free State was also introduced, and that state of things
                  continued until recently. You exhausted your land. You had no longer any slave States to bring in ; and now you are out numbered. The whole power of the government is against you ; and you have no check. You must, therefore, have guarantees of power. You effect but little by mere declarations, whether in the form of resolutions or amendments. Against such a power as that which has
                  been growing up for 30 years, and swelling in magnitude from almost nothing, until it has grown up into a sectional magnitude to control the destinies of the country—how can you restore your safety? To guard against sectional majorities, are you going to trust to a piece of parchment? In the language of the great orator, whose portrait adorns the hall of this Convention
                 [John Randolph] 
                  , you had as well pierce the parchment with bayonets, and burn it, as to trust your rights to a merely declaratory provision. Majorities are despotic. I had rather be governed by King One than by King Numbers, with sectional conflicts and antagonism between us. King One will look to my interest, along with the interests of all his subjects; but King Majority, when sectional, looks to
                  its own interests, and nothing else. Why, sir, your parchment would not be worth any thing for positive protection. Rise up, then, in the dignity of your manhood. If you will delay still—if you are desirous to try still farther experiments—go forth with a strong hand, I pray you, such as Virginia ought to exhibit. Why, sir, my friend
                 [<span className="persName">Mr. SUMMERS</span>] , gave us a glowing picture of the exposure along the line of our borders to inroads for the abduction of the slaves. Such inroads cannot, in any state of the future, exceed what has occurred in the past. I have a friend upon this floor, a member of this Convention [Gen. JACKSON, from Wood] 
                  , who, years ago, informed me of the sweeping loss which, along with others, he had sustained in this particular. More vigilance will be exerted in this behalf than in a time of profound tranquility, and by that alone property will be rendered more secure. If driven to secession, Virginia should introduce a rigid passport system to be executed by a vigilant police, and in that,
                  security will be found.
                </p>
                <p className="p-in-sp">
                  I have heard others express fears of inroads, and border forays. Let me tell all such, that the Alleghany Eagle soars with as strong a wing <PageNumber num={699} /> and gazes with as unwinking an eye upon the sun as any similar bird from any other eyrie. If you fear those across the border, they also fear you. Yield not one inch in taking your position,
                  whatever it may be. That is the way to maintain your rights. Sir, the slave States have, it is true, two thousand miles of border, but so have the free States. Their cities and farms are quite as much exposed as are ours. The large cities are chiefly on their side. If Wheeling is in danger, so also is Pittsburg. Cincinnati is opposite to Newport. Do you think that those Ohio people
                  are going to endanger Cincinnati, by making an inroad upon Newport? What are either side to gain by inroads? No, sir, it is to the interests of the borderers to keep the peace, and men are apt to pursue their interests. I have but little fear from that quarter. Mr. President, my policy stretches still farther than to the slave States. I want the government of the whole Union, sir;
                  and you can acquire it if you pursue a wise and determined course of policy. New Jersey will not stay an appendage to a Northern Confederacy. You cannot fasten her to the North, and what is there to induce Pennsylvania to remain? I have great hope of change in the politics of Pennsylvania. Sir, I heard a voice from New York last night. It was mellifluous, powerful. truthful. I know
                  not whether the gentleman who uttered those sentiments be present in my hearing, the Hon. John Cochrane, of New York Nes, said some one, he is present, if he is, let me tell him that his own great imperial city of New York cannot stay where she is. The South is her natural ally, and she must come with us.
                </p>
                <p className="p-in-sp">
                  And now what result may be anticipated? I say to you here, play your part properly; open your eyes and take a full expansive survey of all the circumstances that surround you. What will you have done if you get three or four of the free States and all the stave States to go with you? What becomes of Mr. Lincoln's General Government? Why you constitute a majority of the whole number
                  of States; and if a majority power means anything, it means that you are entitled to the sceptre and the crown. The Government becomes yours most decidedly. And I don't despair of this result. All I say is, that if my friends desire time, in the name of peace let them take time; let them take what time they please, so that it be reasonable time. My constituents I know are in motion.
                  They are composed of the old Whig party five to one. They sent me here almost without consulting me. They have chosen to confer upon me the mantle of their representative in this Convention, and voted five to one against a reference to them of what we should do here. I feel it to have been a great honor. They are in motion, sir. You cannot keep the Virginia spirit down. And you
                  cannot preserve any nomenclature <PageNumber num={700} /> of party in these times. He who attempts to establish a party for mere party victory will find himself in a very miserable minority.
                </p>
                <p className="p-in-sp">Well sir, if you cannot act with those whose sentiments you consider extreme, do as my friend from Kanawha [<span className="persName">Mr. SUMMERS</span>] 
                  , told you. Let Virginia act for herself. Let your wiseheads lay down your ultimatum. Let that ultimatum consist of ample and full protection. Then send it to your Border slave States; and send it to all the free States, saying, "Here is our ultimatum ; if you do not take that, we go out, we cannot longer continue with you." It is not possible for us to remain at rest; we must do
                  something.
                </p>
                <p className="p-in-sp">
                  Mr. President, that is the view I entertain upon this subject. Sir, about the Western part of Virginia I have not the slightest apprehension. There is a glory in the West that cannot fade away. It was there, at the dawn of the Revolution, that the first great victory was obtained, in advance of the fight at Bunker Hill. My friend alluded in glowing terms to the West. There it was
                  that your men of former times battled for the rights of your eminent domain. Point Pleasant is a marked place in history. A little higher up you vanquished the French and Indian legions, and drove them from Pittsburg, which afterwards Pennsylvania got from you under mistake in the survey. That is the whole secret of it. According to right, if that line had been run properly you would
                  have got Pittsburg. What are the difficulties now in comparison with what they were then? Then there was a wilderness to settle; there were no habitations to be seen; it was a country uninhabited except by the roving savage. Cornstalk, the Indian chief, ruled the land, and was master of that region. It became necessary to extend your sway and your dominion; and your gallant Lewis and
                  others of the West, transmontane and cis-montane united, and you accomplished victory and glory in face of difficulties of immense magnitude.
                </p>
                <div className="speaker" id="sp662"><span className="persName">Ex Governor WISE</span> [interrupting]—</div>
                <p className="p-in-sp">And the battles of the North West.</p>
                <div className="speaker" id="sp663"><span className="persName">Mr. TYLER </span>—</div>
                <p className="p-in-sp">
                  Yes, sir, under the gallant Clark, and at an after day by the brave Kentuckians. But I am speaking exclusively of Virginia. Now, gentlemen, here you stand in the midst of these evils. I fear that a dangerous and insidious policy is about to surround us. The patronage of the Administration begins to be distributed. Who is to be sent as representative to Spain? A man who is everywhere
                  reproaching us, Cassius M. Clay. He is to go to Spain. Clay to acquire Cuba? Don't dream about it. Sir, you will never get Cuba under this Administration. Cassius M. Clay, a perfect fanatic on the subject of slave property, is sent to Spain. Well, a better appointment is made in the <PageNumber num={701} /> representative of the Government to Mexico,
                  Mr. Thomas Corwin. I have a very high respect for that gentleman. I think he is a more liberal man than the other in his views, and he is a man of high talent. Who is to go to England, who to France, and who to the other Courts of Europe, we know not yet. These will doubtless be of those who will be welcomed in Exeter Hall, and delight to put forth long tirades against us. These are
                  the sort of men likely to be appointed to represent the United States in foreign courts, whose office and pleasure it will be to defame and abuse us upon the theatre of Europe. 0, Sir, great events depend upon every moment of our lives. "There was a Brutus once that would have braved the very devil to keep his state in Rome, as proudly as a king." And so it was once with old
                  Virginia. Is she going to sneak about in order to obtain possible terms by which she is to keep in with the North? Or does she mean to stand upon the great platform of her rights? Does she mean to claim in her own behalf the application of her great magna charta to hold sacred her property under all circumstances and at all hazards? That is the Virginia that I have been born in and
                  that I have been living in. I have traveled over her lofty Western mountains. I have wandered over those hills where slumber the remains of Morgan, that gallant leader of the Rifles in many a hard fought field; and among those hills you will find the graves of the gallant and the brave. My home is in sight of Jamestown. There our ancestors four years in advance of the British
                  Parliament adopted the great petition of right which lies at the foundation of public liberty. The non-observance of the principles of that petition of right cost the head of one sovereign and the expulsion of another. I wander amid their graves and ponder beside their tombs. I find myself in Williamsburg, and the memories of the past cluster all around me. The voice of Henry is
                  heard from amid the ruins of the old capitol, and the dying echoes of the last cannon reverberate from the plains of Yorktown. In triumphant array our victorious hosts pass before me, and my eyes become riveted upon them and the tattered banner which those of Virginia proudly bear. It is the prototype of that which floats over this deliberative hall.
                </p>
                <p className="p-in-sp">
                  There they are, all of them. By the example of these illustrious men, I stand up for our rights as the only way to vindicate them. Watchman, what time of the night? The hour has almost struck. Put down your ultimatum, and don't stop there. Go a little further. You have already reported an anti-coercion bill. Let it be strong; let there be no sort of reserve upon its face. Let it say
                  to these gentlemen in Washington as King Canute said to the waters of the great deep, "thus far, and no <PageNumber num={702} /> farther." Arrest their warlike movements, if possible. Go a step further. Insist upon the observance of the status quo precisely as it is. Not an additional man to garrison Fortress Monroe; not another to Harper's Ferry; not
                  another to Fort Washington; not another at the city of Washington. Do that, and you will do right. Then you can give time, reasonable time, for action on your ultimatum. Revolutions never go backward. Ponder on this, and be ready.
                </p>
                <p className="p-in-sp">
                  I have heard much abuse heaped on South Carolina. Now, preliminary to any remarks which I shall make upon that subject, I want merely to say that I am not in correspondence with any politicians upon the face of the globe. A newspaper, the New York "Times," in a scurrilous article calling me traitor because I would not vote for this Peace Congress proposition, accused me of being the
                  mere attorney of South Carolina and the Government at Montgomery. Sir, I only mean to say that I never received a communication from either place, except by telegraph in reply to one dispatched from almost under the eye of the then President of the United States, with a view to preserve peace between the contestants. I wrote no letter at all. My whole correspondence, limited as it
                  is, is in another direction, and very much is confined to the North. I delight to correspond with the men of high talents, and the noble men of Massachusetts—for there are noble men there. There is no man in the Union that I respect more highly than Edward Everett. I correspond with him occasionally—not on political subjects, but in the ordinary course of familiar correspondence.
                  There is another whom I would like very much to make my correspondent, and that is Robert Winthrop of Massachusetts. There is but one more man in the wide world outside of my own family, that I have as a correspondent, and that is Dr. Sprague, of Albany, the venerable Presbyterian divine, the most distinguished man of his order in America. The New York "Times" could hardly, with all
                  its knowledge of alchemy, extract treason out of this.
                </p>
                <p className="p-in-sp">
                  Now, sir, much has been said against South Carolina. Well, look upon the other hand, if you please. That she might have been more patient and waited for the co-operation of her sister States, may be admitted. I have thought that the cotton States had better have retained their Senators and Representatives in their seats at Washington. Sir, they had in their hands a great power—the
                  power of control over the public expenditures. They were in a majority in both Houses in Congress, and they were destined to hold that majority for two years if they chose. All of us, who are familiar with history, know that the great instrument for saving public liberty against the licentiousness of <PageNumber num={703} /> the monarch in England, has
                  been the control over the appropriations, the right to withhold appropriations. Sir, according to my conception, although I may be mistaken, they had this control in their hands and they threw it away. If I had been one of that majority, and my voice might have been heard, I would have begged and supplicated the cotton States to have held on to their position; and I would not have
                  voted one dollar to Mr. Lincoln's administration for the army and navy until he had come up to the high duties of statesmanship, and given such guarantees to the South as were necessary for their protection. I would have done as the Republican party did in the case of Kansas. But they threw away the game. So, at least I thought. But it was for them to decide; and I no longer
                  complain.
                </p>
                <p className="p-in-sp">
                  Now, I hold to the opinion that this consultation of the border slave States will amount to nothing. But put forth your ultimatum, sir; let it be, as I have said, strong and decisive, full of guarantees of security, and I don't know what may be the effect of it if you can get it adopted. I am not prepared to say that it will not restore peace to this glorious Union. I am not prepared
                  to say that with these guarantees of protection, the seceded States of the South will not come back.
                </p>
                <p className="p-in-sp">Well, Mr. President, what labor, what expenditure of means ought to be made to place in your power so glorious and so magnificent a result, as the restoration of the Union?</p>
                <p className="p-in-sp">
                  Sir, you cannot do without the cotton States. It is idle to talk of it. You must have the cotton States, if they can on proper terms be brought back. If those States to-morrow were put up at market overt, and you invited to the place of bidding; the nations of the earth, Russia, wrapped up in her furs, would be there ; England with argosies freighted with treasure would be there;
                  France would come with her imperial crown—and what price would be bid for them, it would puzzle arithmetic to determine. What would be the price to be paid down for them? Would you count it by millions? Or would you go up to billions and trillions? Why, look at it. The foundation of all the exchanges of the world, the clothing of the world, the commerce of the world proceeds chiefly
                  from them.
                </p>
                <p className="p-in-sp">
                  Go to the Exchanges of the world and you see that they are all regulated by cotton. Go to the North—why the whole North is covered over with glittering gems through the cotton trade. And yet it is to be thrown away because of your conception that South Carolina has acted badly. The North will not give guarantees which cost nothing, to reclaim so great a treasure. Why cannot the free
                  States, if they really design you no harm, give the necessary guarantees to secure you? I <PageNumber num={704} /> fear that they desire disunion. Disunion is to them the high road to office; and I fear that many of their politicians would rather "rule in hell than serve in Heaven." South Carolina was a glorious star in the firmament, and I want her to
                  shine there again in all her brightness and glory. Who has forgotten her Marion, her Pickens, and her Sumter? Who has forgotten—even the boys at their school have learned it—who has forgotten King's Mountain and its glory? Sir, I remember an incident connected with King's Mountain. When traveling in the railroad cars, I fell in with Mr. Bancroft, the historian. "I am just from King's
                  Mountain," he said, "they have had a great celebration there, and I have been delighted beyond measure. I went over with William C. Preston, I accompanied him in his carriage upon that occasion. Stricken down as he was in the flower of his life, there was enough of intellect still to coruscate into jewels everything around him, magnificent in its splendor, brilliant in everything
                  that related to him. There he was. They called upon him for a speech; and even there, amidst that decrepitude, broken down as he was by paralysis, a stranger at his home by the severance of his domestic ties, which he lamented and mourned over 'like a stricken deer,' he found his way to the heart of every human being who heard him." That was what Mr. Bancroft said.
                </p>
                <p className="p-in-sp">
                  Well, sir, you are going to throw up this. Gems so bright as your cotton interest you are going to discard. Whither are you going? You have to choose your association. Will you find it among the icebergs of the North or the cotton fields of the South? What will you gain by going North? Will you jeopardise for an association with the North your great interest arising under your
                  domestic institutions? That interest is worth $300,000,000. Decide upon association with the North, and you reduce it two-thirds in value. Nor is that all—a still more evil day will befall you. Brennus may not be yet in the capitol, but he will soon be there, and the sword will be thrown into the scale to weigh against our liberties, and there will be no Camillus to expel him.
                </p>
                <p className="p-in-sp">
                  Sir, I am done. I know that I have presented my views to you most feebly. I have presented them, however, with all the frankness with which one Virginian should talk to another upon this great occasion. You have much more wisdom than I possess. I look with fear and trembling, to some extent, at the condition of my country. But I do want to see Virginia united—I wish to see her
                  carrying her head as she carried it in former times. The time was, when she did not fear. I have entire confidence that her proud crest will yet be seen waving in that <PageNumber num={705} /> great procession of States that go up to the temple to make their vows to maintain their liberties, "peaceably if they can, forcibly if they must." Sir, I am
                  done.
                </p>
              </div>
              <div className="section" id="vsc1965.v1.2.25.3">
                <h3><span className="headingNumber">1.25.3. </span><span className="head">THE PEACE CONFERENCE PROPOSITION</span></h3>
                <div className="speaker" id="sp664"><span className="persName">Mr. R. Y. CONRAD</span>—</div>
                <p className="p-in-sp">I rise to express a hope that the pending question of reference may be taken without further debate, in order that I may offer a resolution which will conduct this debate to, perhaps, a more practical aim.</p>
                <p className="p-in-sp">
                  We have listened, sir, for three days past, with much delight, and I trust, profit, to the able discussion upon this question, by the distinguished gentlemen who have addressed the Convention. I mean to express certainly no regret at the occupation of the time consumed in that discussion; but it is time, in my apprehension, that we should be addressing this debate to something
                  practical, and I desire to offer a resolution, which has for its object, to take up the report of the Committee on Federal Relations, and discuss it with a view to action upon it by this Convention.
                </p>
                <p>The question on referring the report of the Commissioners from Virginia to the "Peace Conference," was then put, and decided in the affirmative.</p>
                <div className="speaker" id="sp665"><span className="persName">Mr. CONRAD</span>—</div>
                <p className="p-in-sp">I now offer the following resolution :</p>
                <p>Resolved, That the report of the Committee on Federal Relations be made the order of the day in Committee of the Whole, to-morrow at half-past twelve o'clock, and continue so from day to day until otherwise ordered.</p>
                <div className="speaker" id="sp666"><span className="persName">Mr. WISE</span>—</div>
                <p className="p-in-sp">I hope the Chairman of the Committee will include the minority reports as well as the majority report in his resolution. I know that in a parliamentary sense, there is but one report, and that is the report of the majority; and that the minority reports are mere amendments or substitutes. I will ask the gentleman to incorporate them in his resolution.</p>
                <div className="speaker" id="sp667"><span className="persName">Mr. R. Y. CONRAD</span>—</div>
                <p className="p-in-sp">In my ignorance of parliamentary law, I had supposed that in a Committee of the Whole, the whole subject would come up for debate. I cheerfully acquiesce in the suggestion, if it be deemed necessary to include all.</p>
                <div className="speaker" id="sp668"><span className="persName">Mr. WISE</span>—</div>
                <p className="p-in-sp">It is not absolutely necessary, but it is proper to treat the minority reports with that much respect. I have no idea that the gentleman designed by this omission to withhold the expression of any respect for ,these reports.</p>
                <div className="speaker" id="sp669"><span className="persName">Mr. CONRAD</span>—</div>
                <p className="p-in-sp">I had no such intention, I assure the gentleman.</p>
                <PageNumber num={706} />
                <div className="speaker" id="sp670"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">The chair will state what he supposes would be the state of the question if that resolution were adopted in the form in which it now is.</p>
                <p className="p-in-sp">It would be competent for any gentleman, when the majority report is under consideration, to rise and offer any of the minority reports as a substitute.</p>
                <div className="speaker" id="sp671"><span className="persName">Mr. WISE</span>—</div>
                <p className="p-in-sp">
                  The minority reports were laid upon the table and ordered to be printed by this body. They were made thereby a part of its whole material for consideration and deliberation. The majority and minority reports came into this house through and from the Committee. They are both official—both came from the Committee. One is technically the report of the Committee, because adopted by the
                  majority. The minority reports are the competing views of other members of the Committee, which this Convention has ordered to be printed. They have been laid upon the table, and ought to be taken up with the report of the Committee, and referred with it to the Committee of the whole. If they are taken up and referred to the Committee of the whole, they are then before that Committee
                  without a motion. If they are not taken from the table and not referred to the Committee of the whole, they will require individual motions, and would only have individual sanction for being presented to the Committee. It is for this reason that I express a hope that the chairman will respect the minority reports so far as to incorporate them in his motion. I would prefer, sir, the
                  motion should come from him.
                </p>
                <div className="speaker" id="sp672"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">Does the Chair understand the gentleman from Frederick [<span className="persName">Mr. CONRAD</span>] , as agreeing to the emendation?</p>
                <div className="speaker" id="sp673"><span className="persName">Mr. CONRAD</span>—</div>
                <p className="p-in-sp">Yes, sir.</p>
                <div className="speaker" id="sp674"><span className="persName">Mr. DORMAN</span>—</div>
                <p className="p-in-sp">
                  Before this action is determined upon I would like to be informed as to the manner in which these minority reports are to be considered. I regard these reports in no other light than as amendments to the majority report, and I hold that they can only be considered as such. I am anxious to know if there is any rule by which they can go before the Committee. They cannot, in my opinion,
                  go before the Committee with the majority report as a matter of course. If they go before them at all, it must be in the form of amendments. When we come to vote upon the question we will be in a state of confusion, unless this matter is understood. When amendments are offered to a resolution, the vote is taken on the amendments first, and it is well that the Convention should
                  reflect upon this fact in considering the question before them. I think the proper course for the Convention would be to refer the report of the majority to the Committee <PageNumber num={707} /> of the whole, and to let these minority reports come up as amendments.
                </p>
                <p><span className="persName">Mr. CONRAD</span> amended his resolution in conformity with the suggestion of Gov. WISE, and it was then read as follows :</p>
                <p>Resolved, That the report of the Committee on Federal Relations with the minority reports from said Committee be made the order of the day in Committee of the whole, to-morrow, at half past 12 o'clock, and continue so from day to day until otherwise ordered.</p>
                <div className="speaker" id="sp675"><span className="persName">Mr. SHEFFEY</span>—</div>
                <p className="p-in-sp">
                  I desire merely to enquire, Mr. President—for I do not profess to be at all familiar with parliamentary rules—whether it is proper that we should resolve ourselves into a committee of the whole for the purpose of considering the majority report of the Committee which, on its face, is incomplete. I understand that it is said in the report that they will agree upon some other form of
                  just demand which is not before us.
                </p>
                <p className="p-in-sp">I desire to enquire of the Chairman of the Committee on Federal relations whether there is a probability of the Committee agreeing upon that ultimatum, and within what time they are likely to agree upon it?</p>
                <div className="speaker" id="sp676"><span className="persName">Mr. CONRAD</span>—</div>
                <p className="p-in-sp">
                  It would be impossible for me to answer the interrogatory of the gentleman, at what time the committee will be likely to agree upon constitutional amendments which are now under their consideration. I would suppose, however, that in the course of this week at farthest the Committee will be able to act finally upon that subject. I will state that there are various questions arising
                  upon the partial report of the Committee so far made, which might be profitably discussed even before that addendum should come in, and that no time would be lost by taking up the report to-morrow. The amendments would come in probably by Monday next.
                </p>
                <div className="speaker" id="sp677"><span className="persName">Mr. SHEFFEY</span>—</div>
                <p className="p-in-sp">I would then move an amendment to the resolutions offered, fixing Monday for the purpose contemplated in that resolution.</p>
                <div className="speaker" id="sp678"><span className="persName">Mr. CONRAD</span>—</div>
                <p className="p-in-sp">I trust the Convention will not sustain the motion which has been made to postpone.</p>
                <p className="p-in-sp">
                  We have two days left of this week, and these two days would be virtually lost if employed in a discussion upon no practical question. Now, we have a practical question in this report, so far as it goes—a question, too, that may be as well debated in the present condition of the report, as it would be if the amendments now under consideration before the Committee had been added.
                </p>
                <p className="p-in-sp">
                  The subject, sir, is a very wide and a very diversified one, and the two remaining days of this week, in my judgment, might be usefully employed in discussing these vital and momentous questions. I hope, <PageNumber num={708} /> therefore, either that my friend will withdraw his motion to postpone, or, if not, that it will be voted down.
                </p>
                <div className="speaker" id="sp679"><span className="persName">Mr. SHEFFEY</span>—</div>
                <p className="p-in-sp">
                  I cannot withdraw the proposition, because it does seem to me that when we resolve ourselves into a committee of the whole for the purpose of discussing the merits of the report of the majority committee, we ought to have that report as a finality. We ought to have that report complete. We cannot take a comprehensive view of the entire subject without we have that report before us. I
                  beg it to be understood by this Convention, that I do not propose any delay by fixing upon Monday; for I desire action, and not delay; but when this Convention acts, it seems to me that we ought to have the whole field before it, and when it resolves itself into committee of the whole for the purpose of considering and discussing the majority report in connection with the minority
                  reports, we ought to know what are the amendments that are to be proposed by the majority of the committee. That report is without those amendments, and is imperfect in the very vital element of the report, and it seems to me that we would be adopting that report in the absence of that which is its very gist and vitality. I insist, therefore, upon the amendment which I propose, and I
                  hope it will be the pleasure of the Convention to sustain it.
                </p>
                <p>The question was taken, and the amendment was rejected.</p>
                <p>The question then recurring on the adoption of the original resolution, it was taken, and the resolution was agreed to.</p>
              </div>
              <div className="section" id="vsc1965.v1.2.25.4">
                <h3><span className="headingNumber">1.25.4. </span><span className="head">DEFENCE OF THE STATE</span></h3>
                <div className="speaker" id="sp680"><span className="persName">Mr. RICHARDSON</span>—</div>
                <p className="p-in-sp">I desire to call up a resolution which I offered to the Convention on the 28th of last month. It is one which, in my judgment, vitally affects the interests of the State, and should receive the immediate consideration of this body.</p>
                <p>The resolution was then read by the Clerk, as follows :</p>
                <p>
                  Resolved, That in furtherance of the resolution adopted by this Convention on the 20th inst., seeking information of the Governor regarding the militia, the Adjutant General of the State be, and he is hereby requested to communicate to this body, as speedily as is compatible with a thorough report on this subject, how many and what kind of arms are in the possession of the State,
                  undistributed, and the number and kind of additional companies which can and will, probably, shortly be armed. Also, at what points, in his judgment, having due reference to the localities of the different companies, and to economy in time and money, the whole volunteer force of the State can be assembled in bodies sufficiently large to be instructed in battalion
                  <PageNumber num={709} /> evolutions, in the evolutions of the line, in siege, garrison and camp duties, incident to the respective arms of the service, and any other information in his reach calculated to throw light on the means necessary to put the Commonwealth in a complete state of preparation against attack.
                </p>
                <div className="speaker" id="sp681"><span className="persName">Mr. RICHARDSON</span>—</div>
                <p className="p-in-sp">Is it in order to make remarks upon the resolution before the Convention agrees to take it from the table?</p>
                <div className="speaker" id="sp682"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">The Chair considers the motion to be a debatable one.</p>
                <div className="speaker" id="sp683"><span className="persName">Mr. RICHARDSON</span> [who was imperfectly heard at the Reporter's desk], was understood to say :</div>
                <p className="p-in-sp">
                  I do not propose at this time to give the thousand and one reasons why this State should be placed in a complete attitude of defence; this will be more proper when there shall be a report in accordance with the object of the resolution, but simply to assign a few reasons why the object of this resolution should be carried out by this Convention; in other words, why this State should
                  be placed in a complete panoply of defence. In the first place, this resolution does not bring up that great bug-bear, taxes, which seems to be so terrible now-a-days. It is a mere resolution of inquiry, preliminary in its scope and object ; it does not propose the appropriation of one dollar, and it simply asks for information regarding the forces of Virginia as they now exist. I
                  presume there is no man in this Convention, no matter what may be his sentiments, who is not thoroughly and strongly convinced that the attitude of Virginia in the present crisis is one of great peril. Whatever may be her destiny, whether she is to act with the North or the South, with a Middle Confederacy or alone, I for one, wish her to be placed in such attitude that when she
                  speaks her voice will not be regarded as the idle gabble of an old woman who is utterly unable to make good what she thinks proper to demand. The crisis is upon us, and very soon we may have to decide what is to be the destiny of the State. If the State remains with the North, I presume we are to be involved in a war with the South. If she goes with the South, then we may be involved
                  in a war with the General Government. If she goes with the Middle Confederacy, we stand a chance of being involved in a war with both sections; and if she remains alone, with the North, South, East and West. I know it is the general sentiment, that whatever is done in the way of putting the Commonwealth in an attitude of defence, so far as the details go, must be done by the
                  Legislature. But I see no objection to the Convention instructing the Legislature to place the Commonwealth in an attitude of defence and carrying out this instruction by <PageNumber num={710} /> framing- a bill setting out accurately and in detail exactly how the programme of safety is to be carried out. More than a year ago the Legislature passed a
                  bill appropriating $500,000 to put the State in a condition of defence; but what has been the result? At this very moment there are only 10,547 armed troops in this State. Of this number there are 1,300 cavalry armed with sabres and with not a pistol among them. Not an inconsiderable number of the infantry are armed with flint lock muskets.
                </p>
                <p className="p-in-sp">
                  We have heard various reports from Washington as to the number of troops concentrated there and likely to be concentrated there. There was one report, whether it was well authenticated or not I am unable to state, that there are already 6,000 troops concentrated there. If the report is not true, it is likely to be verified before long—six thousand troops regularly armed, equipped and
                  drilled—while in the old Commonwealth of Virginia, notwithstanding the magnificent vaunting in which some gentlemen have indulged in regard to our position—from the sea-shore to the mountains, and from the mountains to the Ohio, there are only about four thousand more troops than this number, and they are half armed. I am no military man, never having had a military education; but
                  there is one thing I do know, and which every one ought to know : let Virginia be involved in war; let her sacred soil be desecrated by the footsteps of the invaders; the invaaers will not come in squads and companies; they will come in large bodies, in brigades and divisions. With four or five hundred miles of frontier upon the free States; with Fortress Monroe, which is said to be
                  the Gibraltar of this country, commanding us upon the sea-board—I ask you, what will the ten thousand undisciplined volunteers of this State be able to do against the regular forces of the Federal Government? I repeat, you will not be invaded by squads, but by brigades and divisions. Although our volunteers now may be disciplined in company movements, yet they have no information at
                  all as to movements in large bodies, by brigades or divisions.
                </p>
                <p className="p-in-sp">
                  The object of this resolution is to inquire where they can be concentrated in order that they may be instructed in movements of large bodies of troops, so that if the invader comes, they may be able to beat him back. Stand, Mr. President, in front of a regiment or battalion of volunteers now organized in Virginia, and you will see that it is composed of the very elite of the
                  education, talent and courage of the State—of young men who, by high qualities of virtue, honor and chivalry are not only invaluable to their country, but the pride, the glory, the idols of the houses to which they belong. Sir, I scout and <PageNumber num={711} /> scorn the idea that the blood of these men, or of any other men is too good to be shed for
                  Virginia. But, in the name of humanity, of justice, of even sordid economy, if it is to be shed, let it not be in vain; let it be shed when it will insure the safety and honor of the State, and not be thrown away. These considerations, sir, demand that the volunteers should be instructed in bodies as large as those which will be sent against them. I am in favor of appointing a
                  committee of this body to mature a bill setting forth fully and accurately a plan to instruct the troops and insure the safety of the State. When a bill is matured, this body, which represents the sovereignty of the State, can instruct the Legislature to carry it out. I am opposed to leaving the subject to the Legislature, because they have had the matter under consideration for more
                  than a year, and have done nothing but make an appropriation, which has been attended with the result I have already mentioned. Even if a committee of the Legislature were at work upon the subject (and none is that I know of) , I see no reason why one of this body may not also be at work at the same time—"in a multitude of counsellors there is wisdom." If you ask members of the
                  Legislature why such a bill is not matured, they tell you they are waiting for the action of the Convention; and if you press on members of the Convention the necessity of this provision, they tell you that it is the peculiar and appropriate business of the Legislature. I fear that between the two the Commonwealth will go undefended. I presume that no member of this Convention is
                  willing to leave this State defenceless, in the present complications of public affairs; but if there is one who is willing so to leave her, I hope he will not oppose this mere resolution of enquiry, but will postpone his opposition until a bill is reported in accordance with the spirit of the resolution, if it should be the pleasure of the Convention to appoint a committee upon the
                  subject. Sir, the idle gasconade of would-be brave men and wise men is all that is now being done to defend the State. This mere boasting is not only disgusting, but dispiriting, and, to some extent, humiliating. It is the bravado of men who, in times of public peril, never were, and never will be, found among the defenders of their country. Above this cackle of geese—this barking of
                  feists and growling of cursis heard a voice which I, for one, always reverence: it is the majestic voice of the true and sovereign people, which rises above the din like the mighty but musical roar of a noble ocean; and that voice says in tones of thunder, trifle not with the safety of the State. With that deep earnestness and feeling which is the evidence of sincerity, the people
                  implore you to save their property, their wives and children from the knife of the assassin; their cities from being laid in ashes; <PageNumber num={712} /> their beautiful and blooming forest homes from the sacrilegious hands of the heartless invader. Whether the State of Virginia is to fulfill the magnificent destiny which would be hers at the head of
                  the glorious Southern column, or is to march as mere file closer in rear of a Northern Confederacy, it is equally incumbent upon her true and loyal sons, to clothe her with such material power that she shall be able to ensure safety and to command respect, and to keep proudly floating over her that radiant standard, on which is emblazoned arms and a motto dear to freemen, formidable
                  to tyrants, and so blended with all the glories of the State.
                </p>
                <div className="speaker" id="sp684"><span className="persName">Mr. EARLY</span>—</div>
                <p className="p-in-sp">
                  I hope the Convention will not take up this resolution. The last branch of it will require a military reconnoissance of the State and it will require the employment of competent military topographical engineers to ascertain at what point bodies of troops could be concentrated for the purpose of defence of the State. I take it, that if we are to have a war, we are not to disclose our
                  system of defence and attack beforehand. These matters ought to be left to the officers upon whom the duty of defending the State will be devolved.
                </p>
                <div className="speaker" id="sp685"><span className="persName">Mr. RICHARDSON</span>—</div>
                <p className="p-in-sp">
                  The gentleman has thoroughly and entirely misapprehended the object of the resolution. There is not one syllable in the resolution about the points where these bodies of troops should be concentrated for the defence of the State. The resolution simply provides that the Adjutant General shall indicate the points where these companies can be assembled, not to defend the State, but to
                  be instructed. To illustrate : The returns in the Adjutant General's office, and they alone, show the location of the different companies of volunteers. Now, suppose those returns show that there are twenty companies in ten miles of. Staunton. These twenty companies will form two regiments, or a brigade; then Staunton is the proper place to concentrate these two regiments for brigade
                  drill.
                </p>
                <p className="p-in-sp">Again : suppose these returns show that there are forty companies near Richmond. These forty companies make a division; then Richmond is the proper place to embody them for instruction in division evolutions. In other words, the resolution simply calls for information.</p>
                <p className="p-in-sp">
                  Again, the gentleman is mistaken as to another fact. He says it will require the employment of an engineer to select these points, thereby holding out the idea that it will cost the State something. There is nothing in this objection. The resolution seeks of the Adjutant General information as to the points where the volunteers can be concentrated to be instructed in brigades and
                  divisions. If that officer has the information <PageNumber num={713} /> he will report it, if not, he will so state, and there will be an end of it; no reference is made to an engineer.
                </p>
                <div className="speaker" id="sp686"><span className="persName">Mr. BROWN</span>—</div>
                <p className="p-in-sp">
                  If I am correctly informed, the Legislature has had under consideration a bill for arming the State and raising the means necessary for that purpose. They have appropriated, as I understand, a million of dollars for that purpose. They have other bills in progress, and as they are taking action upon this subject, I think that we had better let them alone. The Legislature is the proper
                  and legitimate body to prepare the State for war, both in regard to money and men.
                </p>
                <div className="speaker" id="sp687"><span className="persName">Mr. RICHARDSON</span>—</div>
                <p className="p-in-sp">
                  I will merely say to the gentleman who has just taken his seat, that he is mistaken in regard to the action of the Legislature. I stated, a while since, that the Legislature had had this subject under consideration for more than a year and they have done nothing except to make an appropriation. They have matured no plan. If you go to the Legislature and ask them why they are not
                  doing anything, they tell you that they are waiting for the Convention, and when you get up here and ask the same question, you are told that they are waiting for the Legislature. So between the Legislature and the Convention, the State remains undefended.
                </p>
                <p className="p-in-sp">As I said before, it is a mere resolution of inquiry and will do no harm to anybody.</p>
                <p>The question was then taken on taking up the resolution from the table; and a division being called for resulted, ayes 44, noes 28.</p>
                <div className="speaker" id="sp688"><span className="persName">Mr. DORMAN</span>—</div>
                <p className="p-in-sp">I would suggest, as there is not a quorum present, that the resolution could not receive a favorable consideration at this late hour of the day.</p>
                <p>On motion of <span className="persName">Mr. BURDETT</span>, the Convention adjourned.</p>
              </div>
            </div>
            <div className="day" id="vsc1965.v1.2.26">
              <PageNumber num={714} />
              <h2><span className="headingNumber">1.26. </span><span className="head">TWENTY-SIXTH DAY</span></h2>
              <div className="dateline">Friday, <span className="date">March 15</span></div>
              <p>The Convention met at 12 o'clock, M.</p>
              <p>Prayer by the Rev. <span className="persName">Mr. NOLLEY</span>, of the Methodist Church.</p>
              <div className="section" id="vsc1965.v1.2.26.1">
                <h3><span className="headingNumber">1.26.1. </span><span className="head">PUBLIC MEETING IN NORTHAMPTON</span></h3>
                <div className="speaker" id="sp689"><span className="persName">Mr. FISHER</span>—</div>
                <p className="p-in-sp">I desire, Mr. President, to lay before this body, as I am requested by my constituents to do, certain preamble and resolutions thereto annexed, adopted at a meeting of the citizens of my county on Monday last.</p>
                <div className="floatingText">
                  <div className="floatingText_body">
                    <p>Preamble and Resolutions, adopted unanimously by a meeting of the citizens of Northampton County, March 11, 1861.</p>
                    <p>Whereas, the Convention representing the sovereignty of the State of Virginia, has now been in session in the city of Richmond about four weeks, without any apparent approximation to the purposes for which it was convened;</p>
                    <p>And whereas, the expense and uncertainty growing out of such inaction, is greatly injurious to the business interests of the people of this county, as well as to those of all other parts of the State;</p>
                    <p>And whereas, the Peace Conference, for whose action it was first declared it waited, has adjourned; and Congress, for whose action the majority of the Convention next demanded delay, has also adjourned; and as the inaugural address of Mr. Lincoln has now been before the country for a week;</p>
                    <p>And whereas, neither the action of the Peace Conference, nor that of Congress, nor the inaugural address, is of such a character as to justify longer delay;</p>
                    <p>Therefore, Resolved, by the people of Northampton in public meeting assembled,</p>
                    <p>1st. That the Convention of Virginia should at once proceed to take official and decisive steps to preserve the honor, the rights, and the interests of the State.</p>
                    <p>2nd. Resolved, That every passing day renders it more certain that immediate secession and union with our sister States of the South present the only means by which our independence, equality, and safety can be maintained.</p>
                    <p>
                      3rd. Resolved, as the deliberate opinion of this meeting, That the meaning of the term "coercion" is as well settled to all plain and honest minds, as any other word in our language, and that, when applied to <PageNumber num={715} /> the collection of customs, or the execution of any other law within the limits of the seceding States, and against
                      their consent, by the Black Republican Government at Washington, it means war, and should at once be met and repelled as such, by every slaveholding State.
                    </p>
                    <p>
                      4th. Resolved, That the Inaugural Address of Abraham Lincoln is a declaration of war, not only against our sister States of the South, but against the sovereignty of the States everywhere, and our Convention should so treat it, by placing the State in such a posture of military preparation as will enable her to guard her honor and safety, and to maintain the vital principle of
                      States Rights now sought to be destroyed by the centralized despotism at Washington.
                    </p>
                    <p>5th. Resolved, That we heartily endorse the bold and manly course pursed by MIERS W. FISHER, our delegate in the Convention, and accord to him the well earned praise of "well done, good and faithful servant."</p>
                    <p>
                      6th. Resolved, That we accord the meed of our approbation and admiration to our Senators R. M. T. Hunter and James M. Mason and to M. R. H. Garnett, our Representative in the lower House of Congress, for their prompt, bold and able support of the vital doctrines of State rights, and the rights of the South, in the trying circumstances in which they have been placed ; and the
                      attempt to censure our Senators by the Convention in Richmond, because they are true to the honor, the rights, and the interests of their State, should be denounced by every man who is not a Black Republican at heart.
                    </p>
                    <p>7th. Resolved, That we hereby appeal to the other counties of the State to express their opinions upon the vital questions now engaging the public mind, with as little delay as possible.</p>
                    <p>8th. Resolved, That the Secretary of this meeting is hereby directed to send a copy of these resolutions to M. W. Fisher, our delegate to the Convention with a request that they be laid before that body.</p>
                    <p>9th. Resolved, That these proceedings be published in the Richmond and Norfolk papers.</p>
                    <p>FREDERICK J. GOFFIGAN, Chairman. THOMAS A. DOWNS, Secretary.</p>
                  </div>
                </div>
                <div className="speaker" id="sp690">[<span className="persName">Mr. FISHER</span>:]</div>
                <p className="p-in-sp">I desire, Mr. President, in presenting these resolutions to this body, as I am requested to do, to submit a few remarks before asking that they may be referred to the Committee on Federal Relations.</p>
                <p className="p-in-sp">
                  But a few weeks since, sir, there was a small division in my county on the subjects which we are here considering. A few gentlemen of the first respectability and standing, with whom I have been associated <PageNumber num={716} /> from early life, but who could not yield to the position which I assumed in the canvass, on Monday last, as I understand
                  from a gentleman now on this floor, gave in their adhesion to that body who gave me the commission to represent them in this hall.
                </p>
                <p className="p-in-sp">
                  It is, sir, one of the gratifying circumstances of my life, that on the occasion when I was before the people asking for their suffrages, I was not opposed by men simply for opposition sake, but because they were not ready to yield to the position which I took in advance of them. And, now, sir, with a magnanimity, which does honor to these gentlemen, they have abandoned all
                  opposition, and are now sustaining me by their voice in public meeting assembled. And, sir, it is a beautiful commentary as to what should be the course of an individual seeking for the suffrages of the people. He ought, in my estimation, and I have ever entertained that opinion—express his own sentiments boldly and fearlessly, and ask others to come up and yield their support to him
                  in his efforts to save the country. Such has been my course of policy, and I am proud to be enabled to say, that my appeal for support in aid of what I regard as the true measures of safety for Virginia, has been met by a noble and generous response. I took the bold ground that the best measure of safety was the withdrawal of Virginia from this destructive Union, and now my people,
                  with one voice, as you see, have taken the same position. I, took, it, sir, before the people, although I was told that it was inexpedient, and that I would not probably be sustained. I, however, had thought otherwise, and I was not deceived in that idea. The people have nobly come up. They have shown themselves to be patriots by changing when they found they were in error. I hope,
                  sir, that the time will come, and that rapidly, when the Convention now assembled here, will come up to the position assumed by my constituents, and reflect their wishes, which, I believe, in my soul, will redound to the honor, the interests and the safety of our Commonwealth. It is wholly impossible that in the present distracted and unsettled condition of all the great interests of
                  the State agricultural, manufacturing and commercial, that the people of this Commonwealth will submit to be kept in the uncertain condition in which they are now placed. The feverish state of excitement of the public mind demands action, which will result in the circulation of the money now held up under the apprehensions growing out of the existing state of things—imparting energy
                  and vitality to trade, and opening of its cannels for renewed and healthy action.
                </p>
                <p className="p-in-sp">With these remarks I desire that the resolutions shall be referred to the Committee on Federal Relations.</p>
              </div>
              <div className="section" id="vsc1965.v1.2.26.2">
                <PageNumber num={717} />
                <h3><span className="headingNumber">1.26.2. </span><span className="head">DEFENCE OF THE STATE</span></h3>
                <div className="speaker" id="sp691"><span className="persName">Mr. RICHARDSON</span>, of Hanover—</div>
                <p className="p-in-sp">On yesterday, sir, the Convention decided to take up a resolution which I had offered on the 28th of last month, and which has been laid upon the table.</p>
                <p className="p-in-sp">I now, sir, move the adoption of that resolution. Having explained its object on yesterday, I will not trouble the Convention with any further remarks upon it.</p>
                <div className="speaker" id="sp692"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">There was no quorum voting on yesterday upon the motion to take up.</p>
                <div className="speaker" id="sp693"><span className="persName">Mr. RICHARDSON</span>—</div>
                <p className="p-in-sp">Well, sir, I now move to take up the resolution.</p>
                <div className="speaker" id="sp694"><span className="persName">Mr. WYSOR</span>—</div>
                <p className="p-in-sp">I would ask the gentleman to postpone his motion for a few minutes to enable me to present the proceedings of a meeting of my constituents which I have been endeavoring for the last three days to submit.</p>
                <div className="speaker" id="sp695"><span className="persName">Mr. RICHARDSON</span>—</div>
                <p className="p-in-sp">I would yield to the gentleman with great pleasure were it not that we are now within the ten minutes of the time fixed for the Convention to go into a committee of the whole. Moreover, I have just refused to give way to the gentleman from Mecklenburg [Mr. GOODE] , and I could not with propriety yield to the gentleman who has just made the request. If I yield at all, other gentlemen may request a like indulgence, and the time left for considering my resolution admits of no delay.</p>
                <p className="p-in-sp">I shall, therefore, insist on my motion to take up the resolution, and upon that motion I ask the ayes and noes.</p>
                <div className="speaker" id="sp696"><span className="persName">Mr. WYSOR</span>—</div>
                <p className="p-in-sp">Under these circumstances I shall be compelled to vote against that motion.</p>
                <div className="speaker" id="sp697"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">The Secretary will read the resolution.</p>
                <p>It was then read as follows:</p>
                <p>
                  "Resolved, That in furtherance of the resolution adopted by this Convention on the 20th inst., seeking information of the Governor regarding the militia, the Adjutant General of the State be, and he is hereby requested to communicate to this body, as speedily as is compatible with a thorough report on this subject, how many and what kind of arms are in the possession of the State,
                  undistributed, and the number and kind of additional companies which can and will, probably, shortly be armed. Also, at what points, in his judgment, having due reference to the localities of the different companies, and to economy in time and money, the whole volunteer force of the State can be assembled in bodies sufficiently large to be instructed in battalion
                  <PageNumber num={718} /> evolutions, in the evolutions of the line, in siege, garrison and camp duties, incident to the respective arms of the service, and any other information in his reach calculated to throw light on the means necessary to put the Commonwealth in a complete state of preparation against attack."
                </p>
                <div className="speaker" id="sp698"><span className="persName">Mr. BROWN</span>, of Preston—</div>
                <p className="p-in-sp">
                  Upon last evening, sir, I expressed in few words, an unwillingness to take that resolution from the table. I desire this morning more fully to assign the reasons why I think the Convention ought not to take up or act upon that resolution. At first view, it may seem that there should be no objection to its consideration ; but when you reflect upon the condition of the mercantile
                  interests of Virginia, that every agitation in this body prejudices them in the exchanges between this and the North, you will better appreciate the disadvantage to which this course of policy leads. We are now under contributions to Northern citizens through the deranged condition of the exchanges, and if you take up this resolution, you will add to the agitation and cause a further
                  diminution in the value of our State stocks, and keep up the inequality of the exchanges.
                </p>
                <p className="p-in-sp">
                  Sir, I referred last evening to the action of the Legislature upon this subject. That body on yesterday, looking, no doubt, to the contingency of Virginia's needing sinews of war, passed a bill appropriating one million of dollars, and providing that that should be raised through the sale of treasury notes—treasury notes, sir, of the denomination of ten dollars, which, if they were
                  the notes of individuals, would have to be collected by a Constable's receipt. What effect will that have upon exchanges and the price of Virginia securities in the Northern States, where our State bonds are carried for the purpose of going into market?
                </p>
                <p className="p-in-sp">Sir, the very passage of that bill will show its effect before to-morrow morning upon the price of Virginia securities in the market.</p>
                <p className="p-in-sp">
                  Now, this whole subject is one that properly belongs to the Legislature. It is one that I am willing to leave with them, while I think that they are not very wisely managing it. I am opposed to increasing the present agitation by making a demonstration in the Convention such as would be involved in taking action upon this resolution. If war comes, let it come. When we see that war is
                  inevitable let us move, and not talk all the time. An English statesman, in three words, will indicate the policy of Great Britain. Napoleon, in three lines in a newspaper, will indicate the policy of that mighty empire. And here we are talking about war and bloodshed while we have determined on nothing.
                </p>
                <p className="p-in-sp">
                  I think, sir, we should make no demonstrations but what we intend <PageNumber num={719} /> to carry out; and, sir, whenever Virginia moves—whenever the emergency arises which will render it necessary for her to take her stand—I will not vote to take up and act upon a resolution of this character, but vote willingly to provide the sinews of war.
                </p>
                <p className="p-in-sp">I am opposed to the motion to take up this resolution now.</p>
                <p>The question was then taken, and decided in the negative.</p>
                <div className="speaker" id="sp699"><span className="persName">Mr. RICHARDSON</span>—</div>
                <p className="p-in-sp">I ask for a division. I asked previously for the ayes and noes.</p>
                <div className="speaker" id="sp700"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">The Chair certainly did not hear the gentleman call for the ayes and noes. If he had, he would assuredly have acted upon it.</p>
                <p>The question on the motion to take up was then taken, and a division being had, there were—ayes 35, noes 46. So the motion to take up was lost.</p>
              </div>
              <div className="section" id="vsc1965.v1.2.26.3">
                <h3><span className="headingNumber">1.26.3. </span><span className="head">MEETING IN MECKLENBURG COUNTY</span></h3>
                <div className="speaker" id="sp701"><span className="persName">Mr. GOODE</span>, of Mecklenburg—</div>
                <p className="p-in-sp">I am requested, sir, to lay before this body the proceedings of a public meeting recently held in the town of Clarksville, in the county of Mecklenburg.</p>
                <p className="p-in-sp">
                  In presenting them, I desire but to say that the resolves are those of gallant men, who, in common with thousands of Virginia's sons, have entered upon a high and holy struggle, for the emancipation of Virginia from the thraldom of a hostile and despotic power; men who are determined, if it be within human power, to sever the cords that bind her to those who are warring upon her
                  peace and happiness, and to link her destiny forever with those to whom she is bound by every tie of interest and of feeling. And, sir, in this struggle their arms will never be unnerved. They will never turn back until satisfied that the Virginia heart is cold and dead to Virginia interest and Virginia honor; and then, sir, in humiliation and sorrow they will turn from their native
                  land, to seek a clime where a freeman may tread with his brow erect, and his step more firm.
                </p>
                <p className="p-in-sp">Sir, the eyes and the hearts of the people of Virginia are now turned to the banner which proudly and defiantly floats from the Capitol of Montgomery. I for one, sir, trust in God, that the day is not far distant when, as that banner floats to the breeze, it will envelope this mother of States and statesmen within its ample and protecting folds.</p>
                <p className="p-in-sp">I ask that the resolutions be referred to the Committee on Federal Relations.</p>
                <p>They were accordingly referred. They read as follows :</p>
                <PageNumber num={720} />
                <div className="floatingText">
                  <div className="floatingText_body">
                    <p>MECKLENBURG RESOLUTIONS PUBLIC MEETING IN CLARKSVILLE</p>
                    <p>
                      At a public meeting, held in Clarksville, on Friday, the 8th inst., on motion of Dr. S. H. Harris, Robt. Y. Overby was called to the Chair, and John G. Boyd<span className="note" id="Note71"><span className="noteLabel">1</span>The name is given as Thomas in the secretary's signature below.</span> appointed Secretary.
                    </p>
                    <p>After a brief explanation of the object of the meeting, on motion of E. K. Harris, a committee of five was appointed to prepare suitable resolutions for the adoption of the meeting.</p>
                    <p>The committee appointed by the Chair retired, and, after a brief absence, reported the following preamble and resolutions, which were unanimously adopted :</p>
                    <p>
                      Whereas, the results of the efforts to allay the agitation of the country, made by Virginia in inaugurating the "Peace Congress," and in sending commissioners to President Buchanan and to the seceded States, establish incontrovertibly that the sectional dominant party does not intend to give the just rights which were demanded, and on the other hand, show conclusively that the
                      seceded States have no idea of coming back into what was once the United States of America.
                    </p>
                    <p>Whereas the Franklin compromise not only fails utterly to give that equality and security which we demand and will have, but also leaves the questions at issue unsettled and in a worse condition for the South than before; and</p>
                    <p>Whereas the recent inaugural of President Lincoln is in fact a declaration of war against the South, therefore</p>
                    <p>Resolved, 1st, That the honor, dignity and best interests of Virginia require her immediate resumption of the powers delegated to the General Government.</p>
                    <p>Resolved, 2d, That Virginia should at once take her position by the side of her sisters of the South—"bone of her bone and flesh of her flesh"—and that our delegate in the State Convention be requested to urge upon that body the necessity of prompt action.</p>
                    <p>Resolved, 3d, That we utterly repudiate the Franklin Compromise, and fully endorse the position taken by our able and gallant delegate in the speech lately pronounced by him in the Virginia Convention.</p>
                    <p>Resolved, 4th, That a copy of these resolutions be forwarded to Col. Thomas F. Goode, with the request that they be laid before the Convention.</p>
                    <p>COMMITTEE-E. K. Harris, G. W. Finley, E. A. Williams, T. E. Thackston, B. D. Morton.</p>
                    <p>On motion, it was then <PageNumber num={721} /> Resolved, That the proceedings of this meeting be published in the "Tobacco Plant," whereupon the meeting adjourned.</p>
                    <p>R. Y. OVERBY, Chairman.</p>
                    <p>Trios. G. BOYD, Secretary.</p>
                  </div>
                </div>
              </div>
              <div className="section" id="vsc1965.v1.2.26.4">
                <h3><span className="headingNumber">1.26.4. </span><span className="head">COMMITTEE OF THE WHOLE</span></h3>
                <div className="speaker" id="sp702"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">On yesterday, the Convention adopted a resolution, that this day, at half-past 12 o'clock, it would go into a Committee of the Whole, to take into consideration the report of the Committee on Federal Relations : That hour has now arrived. Will <span className="persName">Mr. SOUTHALL</span>, of Albemarle, be pleased to take the Chair?</p>
                <div className="speaker" id="sp703"><span className="persName">Mr. WYSOR</span>—</div>
                <p className="p-in-sp">It lacks a few minutes of half-past 12 o'clock by my watch.</p>
                <div className="speaker" id="sp704"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">The time has arrived according to my standard.</p>
                <div className="speaker" id="sp705"><span className="persName">Mr. WYSOR</span>—</div>
                <p className="p-in-sp">I would ask the indulgence of the Convention for a moment, to enable me to present the proceedings of a meeting of a portion of my constituents.</p>
                <div className="speaker" id="sp706"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">It can only be done by general consent.</p>
                <p>There being no objection,</p>
                <div className="speaker" id="sp707"><span className="persName">Mr. WYSOR</span> said :</div>
                <p className="p-in-sp">I hold in my hand a copy of the proceedings of a meeting of a portion of my constituents, to which I desire to call the attention of the Convention.</p>
                <p className="p-in-sp">I do not propose to take up the time of the Convention by having them read. All I want the Convention to do is to consider them as indicative of the temper and spirit of the people whom I represent, upon the questions which engage our attention here.</p>
                <p className="p-in-sp">I will simply recite the propositions which are contained in the resolutions.</p>
                <div className="floatingText">
                  <div className="floatingText_body">
                    <p>PUBLIC MEETING IN PULASKI</p>
                    <p>The resolutions read as follows :</p>
                    <p>At a meeting held in Newbern, Pulaski county, on the 7th inst., the following proceedings were had :</p>
                    <p>On motion of Thomas Poage the following resolutions were offered and their adoption as a whole moved by Mr. Draper :</p>
                    <p>
                      Whereas, the result of the recent overtures made by Virginia for a settlement of the existing difficulties in our federal relations, has demonstrated that no arrangement, which can be effected with our Northern neighbors, will secure the guaranteed and constitutional rights of <PageNumber num={722} /> Virginia; and whereas the inaugural address of
                      the President Abraham Lincoln speaks of the determination of the Federal Government to coerce the Southern States, therefore resolved—
                    </p>
                    <p>1st. That Virginia has done everything consistent with her honor and safety to preserve the union of the States; and all further overtures must come from the North.</p>
                    <p>
                      2nd. Resolved, That any attempt on the part of the General Government to use coercive measures for the collection of revenue from imports, at or near any of the ports within the States which have seceded, will be regarded by Virginia as furnishing just cause of apprehension of danger to the whole South, Virginia inclusive. That in the event aforesaid, Virginia, however sincere
                      may be her attachment to the Union as it was, will deem it her duty to aid in repelling any such attempt.
                    </p>
                    <p>3rd. Resolved, Upon the first attempt of the Federal Government to carry out the coercive policy announced in Lincoln's inaugural address, then it is the duty of our State Convention, now assembled in the city of Richmond, to pass an ordinance absolving Virginia from all connection with the Federal Government, and make common cause with the South against Northern aggression.</p>
                    <p>
                      4. Resolved, Unless the present administration expressly and totally renounces and abandons the policy of coercion and of holding, possessing and occupying the Federal property within the limits of the seceded States prior to the adjournment of our State Convention, then it shall be the duty of said Convention to pass an ordinance of secession or separation before its
                      adjournment.
                    </p>
                    <p>5. Resolved, That a copy of these resolutions be forwarded to our delegate in the Convention and published in the Richmond Whig and Enquirer.</p>
                    <p>On motion of Dr. R. M. Craig the following resolution was offered as a substitute for the 3d resolution offered by Mr. Poage :</p>
                    <p>
                      "Resolved, That we believe Virginia ought to resume her delegated powers and dissolve her connection with the Federal Government, without delay or hesitation." Which was opposed by Thomas Poage, Esq., and advocated by James A. Walker; and the vote being put by the President, the substitute was adopted by a large majority. Mr. Poage's remaining resolutions were then adopted nem.
                      con.
                    </p>
                    <p>Thereupon, amidst great excitement and enthusiasm, the meeting adjourned.</p>
                    <p>J. T. WITHERS, President.</p>
                    <p>JNO. S. DRAPER, Secretary.</p>
                  </div>
                </div>
                <PageNumber num={723} />
                <div className="speaker" id="sp708"><span className="persName">Mr. WYSOR</span>—</div>
                <p className="p-in-sp">These resolutions were adopted by a vote of 146 to 15 in the negative. I submit these proceedings to the Convention as showing what the sentiments of my people are upon the subjects which we are now considering. I shall not ask that they be referred to any committee or printed. I have discharged my duty in presenting them.</p>
              </div>
              <div className="section" id="vsc1965.v1.2.26.5">
                <h3><span className="headingNumber">1.26.5. </span><span className="head">MEETING IN BUCKINGHAM COUNTY</span></h3>
                <div className="speaker" id="sp709"><span className="persName">Mr. FORBES</span>—</div>
                <p className="p-in-sp">I trust the Convention will indulge me for one moment. Circumstances under which I have no control, will require me to leave the city to-morrow morning, and I would therefore ask to be indulged while I submit to the Convention the proceedings of a meeting held in Buckingham county, which have just been placed in my hands.</p>
                <p className="p-in-sp">At a large and probably the largest meeting ever held in my county, which assembled on Monday last, the following resolutions were adopted :</p>
                <div className="floatingText">
                  <div className="floatingText_body">
                    <p>At an unusually large, enthusiastic meeting of the citizens of Buckingham, without respect to party, held at the court house, on the 2d Monday in March, Col. T. M. Bondurant was called to the chair, and R. S. Ellis, Jr., appointed secretary.</p>
                    <p>
                      Col. Bondurant explained the object of the meeting to be to take action in reference to the dark aspects of political affairs, and to express the views of the people here as to the policy of Virginia in this crisis—and said for himself, satisfied that she had made all the efforts she should to obtain proper protection for her rights in the Union, and in vain, he was willing to
                      see her at once secede. Col. Fuqua addressed the meeting, and was willing to aid in any thing that tended Southward, where he expected to go with many of Virginia's sons, if she did not secede. A motion for a committee to bring in suitable resolutions was made, but Col. Fuqua thought the temper of the meeting demanded something prompter—and, therefore, at the suggestion of Col.
                      Bondurant, the following resolution was adopted by an unanimous shout :
                    </p>
                    <p>Resolved, unanimously, That the Peace Congress instituted at the suggestion of Virginia, having failed to accomplish anything satisfactory, the State Convention now in session should immediately pass an ordinance of secession.</p>
                    <p>
                      After the applause with which the unanimous passage of this resolution was received, had subsided, Col. E. W. Hubard and Mr. Philip W. McKinney, the delegate of the county in the Legislature, were severally <PageNumber num={724} /> called on, and delivered speeches approbatory of the resolutions, and congratulatory of the stand the people had taken.
                    </p>
                    <p>A resolution was then adopted that a copy of the foregoing resolution be sent to our delegate to the Convention, Col. W. W. Forbes, and one to the papers for publication; and, on <span className="persName">Mr. R. K</span>. Irving's motion, the meeting adjourned. THOS. M. BONDURANT, Chm'n.</p>
                    <p>R. S. ELLIS, JR., Sec'y.</p>
                  </div>
                </div>
                <div className="speaker" id="sp710"><span className="persName">Mr. FORBES</span>—</div>
                <p className="p-in-sp">
                  I need not refer to the character of this meeting further than to say that it was presided over by a gentleman not only known to this Convention, but to the State at large, as one of the most conservative men in the State—I mean Col. Thomas M. Bondurant, of the county of Buckingham, who is known to be connected with one of the most conservative papers in the city of Richmond. These
                  resolutions were adopted, at his instance, at a large meeting, on Monday last, with a request that I would lay them before the Convention and have them referred to the Committee on Federal Relations, with a hope that they would propose some plan that would meet the approbation of the people of that county.
                </p>
                <p className="p-in-sp">I ask that they be referred to that Committee, and at the proper time, when the subject comes up, I shall ask the indulgence of the Convention to offer some remarks. Meanwhile I will state, that I fully concur in the resolutions, and will do all in my power to carry out the objects which they contemplate.</p>
                <div className="speaker" id="sp711"><span className="persName">The CHAIRMAN</span>—</div>
                <p className="p-in-sp">
                  The Committee of the Whole have had referred to it the report of Majority Committee on Federal Relations, together with various minority reports from the members of that Committee. In order to facilitate—and possibly to avoid any perplexities—the consideration of subjects submitted for the consideration of this Committee, the Chair would beg leave, in advance—especially for the
                  purpose of securing to each and every member of this Committee, as well as to the friends of the minority reports as others, a full opportunity of presenting their propositions as competing propositions with the report of the Committee—to indicate the course that the Chair is inclined to pursue, if acquiesced in by the Committee.<span className="note" id="Note72"
                  ><span className="noteLabel">2</span>In the Proceedings of March 21, pages 150-152, the Chairman made a fuller statement of the procedure he proposed to follow during consideration of the report.</span
                  >
                </p>
                <p className="p-in-sp">
                  The report of the Majority Committee announced, as no doubt the members have seen, sundry, distinct, independent propositions; and perhaps according to strict technical parliamentary rule, the proper course would be to take up the majority report and act upon its distinct, independent resolutions seriatim, amending, rejecting, or adopting them. Thus adopted, one or more of them, they
                  then become the <PageNumber num={725} /> judgment of this Committee, and cannot afterwards, at the will of this Committee, be reversed, altered or amended. It is manifest, therefore, if the Chair or the Committee were to adopt that course, that gentlemen advocating minority reports and desiring to offer substitutes for the entire Committee's report,
                  would be precluded from a fair consideration of their propositions. In order to afford them that facility, and that they may have the privilege of obtaining the sentiment of the Committee on any competing propositions submitted by any one as against the Committee's report, the Chair, as at present advised, will pursue that course; and he will now receive as an amendment to the
                  Committee's various propositions, propositions to substitute some other competing propositions for the whole. These propositions would be amendable by offering a second substitute as a substitute for the amendment. Beyond that we could not go ; and if, after these various propositions shall have been disposed of, if the Committee's report sustains itself and shall overcome all the
                  opposition, then the Chair would take up the Committee's report, and proceed to consider, seriatim, the various resolutions for the final action of the Convention. The Chair has deemed it essential to make this statement as a fair indication of his own views upon the present occasion, so that every member, as all of us are not acquainted, perhaps, with parliamentary proceedings, may
                  have a fair opportunity of coming into competition with the majority report. The Clerk will now proceed to read the majority report.
                </p>
                <div className="speaker" id="sp712"><span className="persName">Mr. FORBES</span>—</div>
                <p className="p-in-sp">I move to dispense with the reading of the report, as it is in the hands of every member of the Convention, and I see no necessity in occupying time by reading it.</p>
                <div className="speaker" id="sp713"><span className="persName">The CHAIRMAN</span>—</div>
                <p className="p-in-sp">By general consent, the reading of the report will be dispensed with.</p>
                <div className="speaker" id="sp714"><span className="persName">Mr. ROBERT Y. CONRAD</span>—</div>
                <p className="p-in-sp">
                  Mr. Chairman : The report of the majority, which I have the honor now to bring to the attention of this body, is the result of a very thorough examination and discussion in the Committee on Federal Relations for the space of some two or three weeks past, and of all the various subjects referred to it by the resolution in this body. The Committee, after such careful, deliberate
                  examination, voted upon the various propositions in this report and then upon the report of the Committee as a whole; and although it might appear from the journals of the Committee that diversity of opinion prevailed in that Convention to a considerable extent; yet, it will be found, upon examination, that that diversity exists much more in appearance than in reality. I may be
                  allowed to state that, on the final vote upon that report, the Committee being then comparatively thin, the vote was <PageNumber num={726} /> twelve in the affirmative, and two only in the negative, and if the Committee had all been present, I believe that not more than four or five, at the most, dissenting voices would have been disclosed in the
                  Committee.
                </p>
                <p className="p-in-sp">
                  I mention these facts, Mr. Chairman, in regard to the examination which has been undergone in order to lay the basis of an earnest request that I now make to the members of this Convention, that they give to the subject a similar careful, deliberative and anxious examination. I do it because of the novelty, importance and difficulty of these questions, and because of the momentous
                  issues which may result from the determination of this Convention as to its final action upon the subject. I would mention that the course which is prescribed by yourself, Mr. Chairman, for the consideration of this subject, as I understand it, is one which will conform to what I deem necessary for the proper consideration of this report.
                </p>
                <p className="p-in-sp">
                  It will be found that the propositions in this report are necessarily and inseparably connected; that while, for the sake of convenience, they have been distributed into sections, yet no one is independent of the rest, and if all taken at all, the report must be taken as a whole. They are a logically connected series of propositions, intended to lay a rational basis for the demands
                  to be made on the part of the people of Virginia, both upon our sister States of the North and upon the Federal Authorities at Washington.
                </p>
                <p className="p-in-sp">
                  I will proceed as briefly as I can, without attempting to go out for one moment from the plain and direct path of my explanation, to call the attention of the Convention more particularly to the various propositions in this report. I may, however, mention now that there are two minority reports, or three rather, which I may have occasion to advert to in the course of the examination,
                  which I may make, of the report of the majority committee.
                </p>
                <p className="p-in-sp">
                  It will be found, Mr. Chairman, that the first, second and third sections of this report contain merely an assertion of the equality of the people of the States in the original formation of this Confederacy, of the equal rights which resulted from the Federal Constitution and especially of the recognition of the right to full protection and equality of the peculiar institution of the
                  Southern States of this Union. With regard to the propositions embraced in these three first sections. I apprehend there will be no controversy. I therefore merely allude in passing to the subject of these three sections. The fourth section is of the same character. It asserts a necessarily resulting right of equality both of the slave and the non-slaveholding States in the
                  Territories <PageNumber num={727} /> of the United States, by affirming the fact, that they constitute a trust to be administered by the General Government for the common benefit of the people of the United States, and then protesting against any policy which would confer greater benefit at the expense of any other State or States upon one particular
                  State or class of States. That I suppose will not be a matter of controversy.
                </p>
                <p className="p-in-sp">
                  The fifth section affirms that the sites of the federal forts, arsenals, &amp;c., within the limits of the States of this Union, were acquired by the Federal Government, and jurisdiction over them ceded by the States, as trusts, for the common purposes of the Union, during its continuance; and upon the separation of the States, such jurisdiction reverts of right to the States,
                  respectively, by which the jurisdiction was ceded. Whilst a State remains in the Union, the legitimate use of such forts, &amp;c., is to protect the country against foreign force, and to aid in suppressing domestic insurrection. To use, or prepare them to be used to intimidate a State, or constrain its free action, is a perversion of the purposes for which they were obtained; they
                  were not intended to be used against the States, in whose limits they are found, in the event of civil war.
                </p>
                <p className="p-in-sp">It then proceeds to protest against any unusual amount of munitions of war being collected in those places during the present troubles, unless there should rise some foreign war or something which might properly be termed domestic insurrection.</p>
                <p className="p-in-sp">
                  The sixth section I pass over as one not controverted. The seventh section refers to existing causes of complaint to be removed by the Federal and State Government, referring especially to those which the State Government necessarily must remedy, and also referring to proposed amendments to the Constitution of the United States, in order to remedy effectually these grievances.
                </p>
                <p className="p-in-sp">The eighth section I will read in full, because I desire to call the particular attention of the Committee to it.</p>
                <p className="p-in-sp">
                  "The people of Virginia recognize the American principle that government is founded in the consent of the governed and they concede the right of the people of the several States of this Union, for just causes, to withdraw from their association under this Federal Government with the people of the other States, and to erect new Governments for their better security, and they will
                  never consent that the Federal power, which is in part their power, shall be exerted for the purpose of subjugating the people of such States to the Federal authority."
                </p>
                <p className="p-in-sp">
                  The ninth section refers to the condition at this time, of the State of South Carolina, and the other States of the Southern Confederacy, <PageNumber num={728} /> and to a question interesting to the remaining States of the Union, which has arisen from the fact of the withdrawal of these States from the Federal Government. It then states the fact that
                  the Federal authorities, under the Constitution as it is, disclaim power to recognize the withdrawal of any State from the Union and consequently to deal with these questions, holding that it is reserved only to the States as parties to the government compact to take lawful action touching them.
                </p>
                <p className="p-in-sp">
                  The tenth section expresses on the part of the Convention their desire to confer upon the Government of the United States, the powers necessary to enable its proper authorities to deal peaceably with these questions, and, if it shall become necessary, to recognize the separate independence of the seceding States, and to make such treaties with them, and to pass such laws as the
                  separation may make proper.
                </p>
                <p className="p-in-sp">
                  The eleventh section expresses the desire of the people of Virginia to meet as directly as possible the people of her sister States in order to present immediately to them her complaints and her demands of redress, and it concludes with the statement, "and in the event that this Commonwealth fails to obtain satisfactory responses to her requests, from the non-slaveholding States, she
                  will feel compelled to resume the powers granted by her under the Constitution of the United States, and to throw herself upon her reserved rights."
                </p>
                <p className="p-in-sp">
                  The twelfth section states, that the people of Virginia will await any reasonable time to obtain answers from the other States, to these propositions, aware of the embarrassments that may produce delay, but they will expect, as an indispensable condition, that a pacific policy shall be adopted towards the seceded States; and that no attempt be made to subject them to the Federal
                  authority; nor to reinforce the forts now in possession of the military forces of the United States; nor recapture the forts, arsenals or other property of the United States within their limits; nor to exact the payment of imposts upon their commerce; nor any measure resorted to, justly calculated to provoke hostile collision.
                </p>
                <p className="p-in-sp">
                  The thirteenth section is to the same effect, that is, a protest against any attempt at coercion on the part of the Federal authorities, based, I will remark, Mr. Chairman, not upon any favor, not upon any ground of mere expediency, but upon the ground of right; and, it is also a remonstrance of protest, on the part of this Commonwealth against any action calculated to produce such a
                  collision of force by our former sister States of the Southern Confederacy.
                </p>
                <p className="p-in-sp">
                  The fourteenth section referring to the border slaveholding States of this Union and their peculiar interests as more assimilating to those of <PageNumber num={729} /> this Commonwealth, provides for a conference between them and the submission to them of the propositions which may be made on the part of Virginia, both to the Northern States and the
                  Federal authorities.
                </p>
                <p className="p-in-sp">
                  These, Mr. Chairman, are all the sections embraced in the partial report of the Committee which has been made. I beg leave to repeat and I submit it to the intelligence of gentlemen who will examine these propositions for themselves—that there is not one of the propositions laid down here which is not indispensably necessary, as a basis for some one or more of the demands which we
                  have made or shall make. There is nothing unnecessary, and it will be found that nothing has been omitted in other words, that this is a declaration of principles as a basis for the demands properly and necessarily for the safety of the country, to be made by Virginia upon her sister States and upon the authorities of the Federal Government.
                </p>
                <p className="p-in-sp">
                  I do not regret, Mr. Chairman, that the Committee have as yet been unable to complete their report by furnishing to the Convention the proposed amendments to the Federal Constitution, because it will better enable the members of the Convention, when those proposed amendments shall be laid before them, to see, after some discussion and examination of these propositions with those
                  amendments, whether they come up fully to the declarations of rights, made in the report, so far as it is now completed.
                </p>
                <p className="p-in-sp">
                  To amplify this matter a little more, I ask but one single concession in advance. In the condition in which this Convention finds itself, it is proper that some efforts should be made on the part of the immediate representatives of Virginia here to settle and compose the existing difficulties of the country. With those gentlemen who will entertain no other than the proposition of an
                  instantaneous withdrawal from this Union, and the instant disrupture of this great nation without further effort and further consideration, it is utterly impossible, Mr. Chairman, that I can agree. I can only address myself to the proper effort and proper. proceeding which should be adopted by the people of Virginia, in order, if possible, to settle and compose the strifes which now
                  agitate the country, and therefore, I am utterly unable even to approach those who will not entertain the possibility of an adjustment of these difficulties.
                </p>
                <p className="p-in-sp">
                  I need not say, sir, that for those gentlemen and for their opinions I entertain entire respect. I mean not to cast the slightest censure or reproach upon them. I simply wish to remark that for my part—and I can say it for the Committee on Federal Relations, or at least all except one of those who signed one of the minority reports—that they
                  <PageNumber num={730} /> duly entered upon this task, because they considered that the people of Virginia, their constituents, had sent them here for that purpose, as was plainly indicated by the vote cast by the people at the polls upon the election of delegates to this Convention ; that no action of this Convention tending to sever the connection of
                  Virginia from the Federal Government should bind Virginia unless they should give their subsequent approval of and assent thereto. I repeat, then, sir, that we entered upon that examination only on that alternative : that it was the business, the imperative duty of this Convention to try to the utmost to devise means to restore this Union as it originally existed. And if these
                  efforts should unhappily prove unavailing then the duty of this Convention will be simply to advise the people of this Commonwealth of that unfortunate result, and to advise them as to the only course that remains to be pursued.
                </p>
                <p className="p-in-sp">
                  With this concession, then sir, I will proceed to consider generally, in the first instaiice, some of the objections which I have heard made to this report; and first to one made by gentlemen whose opinions I have felt bound to respect, from my knowledge of their intelligence and character. The first objection, covering the whole subject, is : that it is unnecessary, that it is
                  inexpedient, that any declaration of rights and principles such as the committee have deemed it necessary to report to this Convention, should be made or adopted by the Convention. I have heard that objection urged in various quarters. I confess I have been surprised, and, with all respect, I must say that it proceeds from a want of due reflection upon the exigencies of the case.
                  Why, sir, does any one doubt that it was proper and expedient, when our people cast off the yoke of the King of Great Britain, to make any declaration of rights or of principles? Have we not now the ablest state papers that exist in history, enunciating rights and principles carefully prepared at that period of our history? At subsequent periods it has been deemed necessary and
                  proper for Virginia, in solemn form, to make declarations of this character. In '98 and '99, resolutions which have formed a text-book of the political party which has always prevailed in the Commonwealth of Virginia, were enunciated. And, sir, whenever a great crisis in our affairs arises, it is peculiarly proper that these examinations, these enunciations of rights and of
                  principles, should be made in conformity with the advice which was solemnly given us by our fathers : that, in order to preserve our institutions in their purity, it is necessary frequently to recur to fundamental principles.
                </p>
                <p className="p-in-sp">
                  Now, sir, I submit to this Convention, if a greater occasion, a more momentous crisis, in the affairs and interests of the American people, <PageNumber num={731} /> has ever arisen, than that which has called this Convention into this hall? And, sir, it will be found that it not merely involves rights and constructions which have been practically
                  brought to the attention of the American people before, but new difficulties, questions which never have been practically submitted to the minds of our statesmen, and which probably never have been discussed even theoretically. If I am able to proceed with the exposition, as I purpose to do, the Convention will bear me out in that assertion : that new questions, never before
                  agitated, have sprung out of the present difficulties, which it is indispensable, if there is to be any amicable adjustment of them, should be settled now.
                </p>
                <p className="p-in-sp">
                  It is necessary further, Mr. Chairman, that we should declare these principles; because we are to make demands, we are to make extraordinary demands, upon the authorities of the Federal Government; we are to demand of our sister States that they should consent to amendments to the fundamental Constitution of the Federal Government; and in order to furnish a rational basis for these
                  demands, it is necessary that we should lay down the rights and principles upon which we predicate those demands.
                </p>
                <p className="p-in-sp">
                  It is necessary still again, sir, in order that the demands which may be made on the part of Virginia shall not be inferentially asked as matter of favor, or advised merely as expedient, but that they should be presented in the far stronger, in the imperative form of a right, and especially as it is proposed that these demands should be made the ultimatum of Virginia, upon the
                  refusal of which she is to become an acting party in the disruption of this nation.
                </p>
                <p className="p-in-sp">I may assume then, I think, Mr. Chairman, that this objection to the report of the Committee, namely, that it is unnecessary to make any such enunciation of principles or rights, should not be sustained by this Convention, is unfounded, and that even those who have entertained the objection, will, upon reflection, be disposed to abandon it.</p>
                <p className="p-in-sp">
                  It remains, then, Mr. Chairman, only to consider, first, whether these propositions are necessary, logically necessary, in order to furnish the basis for the demands which we make, some of which are to be found in the resolutions themselves; secondly, having ascertained that they are necessary, that no surplus, no unnecessary statements or declarations are contained herein ; and then
                  to ascertain whether they are sound and true in themselves.
                </p>
                <p className="p-in-sp">
                  I shall not, Mr. Chairman, trouble the Convention with any detailed enquiry as to whether these are necessary and proper, provided they are sound. I leave it to the members of the Convention, upon examination, <PageNumber num={732} /> to say whether any portion of these resolutions can be stricken out without destroying the symmetry of the whole. I take
                  the position that you cannot, consistently with the idea that you are to base your demands upon declared rights and principles, strike out any portion which is to be found in the report of the majority of the Committee. I will proceed to enquire very briefly whether these propositions are in themselves just and sound.
                </p>
                <p className="p-in-sp">
                  I pass at once, Mr. Chairman, to the eighth resolution. That resolution, sir, is one which, doubtless, at first view, will meet, I might almost say, with the unanimous disapprobation of the Northern mind. It is utterly opposed to their opinions of principle and of right, as far as we have been enabled to gather from recent declarations, and it finds, even among us, many opponents
                  when first presented. Sir, the very objection which is made to this resolution presents the strongest confirmation of the wisdom of that advice to which I referred a little while ago, as to the necessity of a frequent recurrence to fundamental principles. The Northern people, for the seventy years during which our nation has existed, have probably attended scarcely in the slightest
                  degree to this question of constitutional law and right. In Virginia, we have been much more in the habit of discussing and examining these questions. We, probably, should find in the North an almost unanimous denial of this proposition ; and, as I have said, we find many who deny it here. And yet, Mr. Chairman, I undertake to say that it is the very corner stone of our liberty and
                  of our institutions, for all those fundamental principles are to be found in every State paper cotemporaneous with the American revolution. It is affirmed almost in the same words in the Bill of Rights of Virginia. It is affirmed in the declaration of American independence almost in the same terms. It was a proposition maintained by Gen. Washington at the head of the American army at
                  Boston, in correspondence with Gage. It is this principle, that any people have a right to alter, reform, or abolish their Government, made only for their benefit by mere agencies, excluding all the European ideas of Divine right of legitimacy of Government, or any inherent power in Governmental agency to rule people without their consent. Let me advert to the eighth section again.
                  It is as follows :
                </p>
                <p className="p-in-sp">
                  "The people of Virginia recognize the American principle that Government is founded in the consent of the governed, and they concede the right of the people of the several States of this Union, for just causes, to withdraw from their association under the Federal Government with the people of the other States, and to erect new Governments
                  <PageNumber num={733} /> for their better security, and they will never consent that the Federal power, which is in part their power, shall be exerted for the purpose of subjugating the people of such States to the Federal authority."
                </p>
                <p className="p-in-sp">
                  Now sir, the affirmation here is simply that the people of the several States of this Union, for just causes, may withdraw from their association under the Federal Government. That is, may abolish, within the limits of their State, the federal authority, for just causes. Sir, permit me to say, if any gentleman upon this floor is disposed to deny that as a proposition of American
                  constitutional law, we have, in my estimation, sadly degenerated since the days of '76. At that time, sir, it was a principle universally entertained in the American mind. Unless it can be shown that the people of any one of the original States of this Union are not now a people, a community, then they have the right which is announced in our Bill of Rights, and in the Declaration of
                  Independence, to abolish their forms of government at their own pleasure. Unless, I repeat, it can be shown that the people of a State of this Union no longer remain a people at all—unless their existence as a community, as a people, has been utterly obliterated, destroyed, merged in some other community, then they still retain this right.
                </p>
                <p className="p-in-sp">
                  Sir, we have, in the State of Virginia, at several different periods of our history, without question, almost totally changed our organic law. We have made greater changes within the period of my life-time and recollection, in the statutes of the State of Virginia, than have been made in England in five hundred years. No one questions our right upon that point. We, the people of
                  Virginia, have a Federal Government. We have the same right, for just causes, to change that Federal Government as the people of Virginia have to change theirs. If we have a right to change our State Government against the authorities constituted to carry on that Government, we have the same right to change our Federal Government. I care not by what name you call it. I should not
                  even call it a revolution; because a revolution implies, at least in ordinary acceptation, a revolt. I hold that the people of a State, acting as a State, acting unanimously, therefore, so far as the rest of the world is concerned, are neither in a condition necessarily of revolution, nor are they rebels, traitors or insurgents, when they undertake, for just causes, to withdraw from
                  this Federal authority. And yet, sir, I freely concede that the people of the other States who are also interested in this common government have a right to take their redress from the people of Virginia for any injury which may be done them by the act of Virginia in disrupting this common Government. <PageNumber num={734} /> I say, however, as
                  Washington said at Boston, that, as a separate people, under an organized Government, they were neither insurgents nor rebels, but had a right to be treated as equals, provided the other parties chose to make war upon them for this separation or withdrawal.
                </p>
                <p className="p-in-sp">
                  Now, sir, I submit that this proposition is sound, is impregnable upon these American principles, established at the period of the American revolution, and then entertained, without dissent, by all citizens of America. It was, sir, an American principle. It overthrew all the ideas of Government which had been previously entertained in Europe—all ideas of divine right of legitimacy,
                  of inherent power in governmental authority; and it put the whole subject of Government upon the simple ground of a convenient agency of the people to carry on their affairs, leaving them free at any time, when necessary, to alter or abolish that form of Government.
                </p>
                <p className="p-in-sp">
                  And, Mr. Chairman, allow me to say in passing, that although this was originally an American principle, strictly American, it has in recent times ceased to be so, for you find this very principle recognised now both by England and France, in reference to the late disturbances upon the European continent. It has been affirmed almost in identical terms by the people of France, that a
                  people have a right to change their form of government, even where connected in subordination with other governments. If they can succeed as against that government in effecting such change, they have a perfect right to do it; it is not against morals, against any code of nations, against any other power, for people to exercise such right.
                </p>
                <p className="p-in-sp">
                  Perhaps, sir, it may not be amiss, as I have stated, that both in the Declaration of Independence, and in the Bill of Rights of Virginia, this principle is affirmed, to call the attention of the Convention to the language in which it is affirmed. Here is the Declaration of Independence, familiar to us all, but which it is never amiss, sir, to read over and over again :
                </p>
                <p className="p-in-sp">
                  "We hold these truths to be self-evident : that all men are created equal : that they are endowed by their Creator with certain inalienable rights; that among these are life, liberty and the pursuit of happiness; that, to secure these rights, governments are instituted among men, deriving their just powers from the consent of the governed; that, whenever any form of government
                  becomes destructive of these ends, it is the right of the people to alter or to abolish it, and to institute a new government, laying its foundation on such principles and organizing its powers in such form as to them shall seem most likely to effect their safety and happiness." <PageNumber num={735} /> This affirms as to a people then in subordination,
                  actual subordination, to the King of Great Britain. It is not the people of Great Britain, or the conjoint people of Great Britain and the American Colonies, that have a right to reform and abolish governments, but the people of the American Colonies; because they were a people—a distinctly organized people; and it is declared that they, as a people, had a right to abolish the
                  government of the Crown of Great Britain, so far as it referred to them, and to erect new governments to secure their own happiness.
                </p>
                <p className="p-in-sp">And so it is with any State of this Union. It has a right, upon this principle, to alter or abolish; and, sir, it is all important that we in Virginia should not forget that we have this right in contingencies that may possibly occur.</p>
                <p className="p-in-sp">Again, sir, in the Bill of Rights of Virginia, we find the same principle affirmed :</p>
                <p className="p-in-sp">"And that when any government shall be found inadequate or contrary to these purposes, a majority of the community hath an indubitable, unalienable and indefeasible right to reform, alter or abolish it, in such manner as shall be judged most conducive to the public weal."</p>
                <p className="p-in-sp">Here, then, is a distinct declaration of the same principle, that a majority of any community, in respect to any governm- ent that they may have erected—I say, as much in respect to any confederated government in which other States may be associated with them, as in respect to their own separate government—has an inalienable right to alter, reform or abolish.</p>
                <p className="p-in-sp">
                  Mr. Chairman, I suppose it is unnecessary to disclaim, on my part, any assent to what has been usually termed the right of peaceable secession, or the right of constitutional secession. I have always, since I have first heard of rights of that sort contended for, been utterly unable even to comprehend the meaning of the idea that the Constitution of a Government provided for a
                  constitutional mode of breaking up finally the government, or that an association of States would allow the right to one State, at whatsoever risks to the interests of others, by its own voluntary act, to disrupt the government; and to do that without restoring to the other parties to the compact, the right to take their redress for injury done them, is a principle inconceivable to
                  my mind. Yet I will undertake to say, that it is only by confounding the great principle affirmed in this book, with their ideas of constitutional or peaceable secession, that any objection by any gentleman can be entertained to the proposition as there stated. It is, as I have said before, <PageNumber num={736} /> but a simple enunciation of the right
                  declared in the Declaration of Independence, and the Bill of Rights of Virginia—of a people to reform or alter as a people their form of government without being rebels, traitors, or insurrectionists in that act.
                </p>
                <p className="p-in-sp">Well now, sir, as to the necessity, the propriety of making this declaration; that necessity will be seen from the latter part of the very clause to which I have been referring.</p>
                <p className="p-in-sp">
                  The people of Virginia recognize the American principle that government is founded in the consent of the governed, and they concede the right of the people of the several States of this Union, for just causes, to withdraw from their association under the Federal Government with the people of the other States, and to erect new Governments for their better security, and they will never
                  consent that the Federal power, which is in part their power, shall be exerted for the purpose of subjugating the people of such States to the Federal authority.
                </p>
                <p className="p-in-sp">
                  The necessary corollary from this proposition, if maintained, as to the right of the people of a State to withdraw themselves from the Federal authority is, that these Federal authorities have no right, legally or constitutionally, to deal with the question; and it is intended—and not only in this, but in various resolutions in this report, the position is assumed—it is intended to
                  deny, utterly and absolutely, to the constituted authorities of the Federal Government at Washington, the right in any way, peaceably or forcibly, to adjust the question of the withdrawal of any State or States from the Union. It underlies this whole protest proposed to be made by Virginia against any attempt at subjugation or coercion. It makes this affirmation upon the basis, not
                  of fear, not of expediency, but of absolute denial of the right of these Government authorities to attempt in any way to decide in regard to the secession or withdrawal of these States. It regards such an attempt as a usurpation of authority not granted them by the Constitution. We concede, sir, any one would concede, I apprehend, that the other parties to this compact, which is
                  broken by the withdrawal of some States, have a perfect right to judge for themselves as to the propriety and justice of the cause which has induced those States to withdraw, and to the extent that they are injured, to take redress from the former parties in the Federal Government. But we deny totally to the authorities of the Federal Government, as a mere agency, with limited
                  powers, looking only to the conduct of the Government, in its fullness and entirety, in a state of peace, the power in any way to deal with this question. <PageNumber num={737} /> Sir, it is a remarkable fact, that the present Federal Executive, Mr. Lincoln, while he denies the power to deal with this subject peaceably-- while he denies that he has any
                  right to recognize the secession or withdrawal of these States—holds that he has the right to deal with them forcibly. I suppose it could be scarcely denied, if every other State in this Union except the Southern Confederated States was perfectly willing to consent to their separation, that it would be competent to do so, and that no war, no collision would necessarily result in this
                  state of things. I presume if one State of this Union, at any period of her history, had proposed to the other States of the Union that it might peaceably withdraw from the Federal Union, and the other States had unanimously assented to that proposition, that that withdrawal might be made peaceably, without any danger, without any possibility of collision. And yet, according to the
                  position of Mr. Lincoln, he has no right to consent to the withdrawal of these States, but he is forced to the only other alternative : that of their subjugation. He admits that he has no power in the one aspect—that of assent to peaceable separation—to deal at all with the question; while he undertakes, from a sense of duty, as he says, to decide it in the other aspect, and say that
                  he is bound by his oath to execute the laws of the United States over the seceded States. Why, sir, this alone would present the question in a most extraordinary point of view. The federal authority might thus make war, but without the right to make peace.
                </p>
                <p className="p-in-sp">
                  But, sir, when you come to look into the Constitution of the United States it is most obvious it never was in the contemplation of the framers of that instrument that under existing circumstances any law of the United States, of any kind or description, could be executed within the limits of the State where the civil authorities had been overthrown. Is there any right on the part of
                  the federal executive to use military force except upon the call of the civil authorities—the local civil authorities—and that force to be used in subordination to the civil authorities? The Constitution itself provides that the authorities of the State, its Legislature, if in session, or the Governor, if the Legislature is not in session, alone shall call upon the Federal Government
                  even for the purpose of putting down domestic insurrection. The President of the United States has no original authority in any way to use any military force within the limits of a State under any circumstance; but it has been supposed by him that whilst he could not execute this authority directly, or would not, as he said, use it immediately in the interior of a State; yet in the
                  collection of the revenue along the coast, he might go on and execute these laws—and you find a bill before Congress, <PageNumber num={738} /> one of thdse bills called force bills, designed ingeniously in order to bring to bear upon the people of the seceded States the Federal authority directly, under the pretence of collecting the revenue. I allude
                  to the bill called the Cochrane bill. The substance of it was a provision that in any part of those States where there was no collector, the President should make proclamation of that fact, and vessels from abroad laden with dutiable goods going into that port, should be diverted from their course by revenue cutters of the United States and compelled to make their entries in other
                  ports outside of those States. It will be found, sir, that even in regard to that, that that bill could not be put into operation without a direct and palpable violation of one of the distinct provisions of the Constitution of the United States. I refer to the 6th clause of section 9:
                </p>
                <p className="p-in-sp">"No preference shall be given by any regulation of commerce or revenue to the ports of one State over those of another ; nor shall vessels bound to, or from, one State, be obliged to enter, clear, or pay duties in another."</p>
                <p className="p-in-sp">
                  This is a direct contradiction of the whole scheme of that Cochrane bill and the only mode which by any ingenuity could be devised by which this effort to collect the revenue in any one of the seceding States by the Federal authorities could be carried out. I repeat then, sir, that to deny—and the report of the Committee intends to deny—to the Federal authorities at Washington any
                  and all power to deal in any respect with the citizens of the seceded States, whilst it does not deny to the people of the Confederated States remaining in the Union the power to deal with it as they may deem wise and expedient, when they have the opportunity to consider it.<span className="note" id="Note73"
                  ><span className="noteLabel">3</span>There seems to be an omission at the end of this sentence.</span
                  >
                </p>
                <p className="p-in-sp">
                  This resolution, then, is intended to lay a rational basis of right for a demand to be made by the State of Virginia, upon the Federal authorities—that no demand should be made in any form or manner, to subjugate or extend their power and authority over the people of these Confederated States. By placing it upon this basis, we at once settle the whole question of coercion—one,
                  .perhaps, which threatens more than any other to prostrate all the efforts that may be made on the part of Virginia to compose the present difficulties of the country.
                </p>
                <p className="p-in-sp">
                  There is another class of demands—for I need not refer particularly to the other propositions which grow necessarily out of this question, and which I have considered, in regard to the forts and arsenals. The proposition in respect to them is, that they were ceded by the States in which they are situated for certain purposes; that they are merely a trust in the hands of the General
                  Government; that they can only <PageNumber num={739} /> be legitimately used against foreign enemies, or to suppress domestic insurrection; that they cannot be used to subjugate a State, and cannot be used in the event of a civil war, because the authorities of the Federal Government have no right to engage in any such war.
                </p>
                <p className="p-in-sp">Another class of propositions—which I will only trouble the Convention now by alluding to, are those which make demands upon the people of the Northern States. In respect to those, the Convention will find when they come in, that there will be no ground for debate.</p>
                <p className="p-in-sp">
                  I can undertake to say now, when the report of the committee comes in embracing the demands to be made and the amendments to be offered to the Federal Constitution, that there shall be no grounds of objection, at least, of the character, which we have heard made here upon this floor, in the last three or four days; that whatever we do demand will be plainly, clearly and unequivocally
                  expressed, and that we will make demands upon all points which it is deemed essential to the interests of Virginia shall be made. With respect to the method there has been some contrariety of opinion. The principal object of the report of the committee is to pass by the politicians, who stand in front of the people of our Northern States, who have the possession now, not only of the
                  Federal authority at Washington, but most of the State organizations, and make a quasi, extra-constitutional appeal directly to the people of our sister States, calling upon them to respond to the claim of the people of Virginia, that all these matters shall be now, forever and amicably adjusted.
                </p>
                <p className="p-in-sp">
                  There have been some propositions made for a General Convention. In the opinion of the Committee, I am authorised to say, that preference was given to this direct appeal to the people, either by a popular vote upon the propositions themselves, or by a Committee formed similarly to our own elected in small districts, and directly and purely reflecting the will of the people of their
                  several States. If we can get a response of this character, allow me to say, I have the utmost confidence and hope, almost by one universal acclaim, the people of these Northern States will be prepared to say, that they will give to Virginia whatsoever Virginia demands of right. I have no fear upon the subject.
                </p>
                <p className="p-in-sp">Mr. Chairman, I am fully sensible that I have wearied the members of the Convention in this exposition which I have made, unconnectedly and without any attempt to go one step outside of the path of a plain examination of the principles of this report.</p>
                <p className="p-in-sp">
                  I thank the Convention for the kind attention with which they have listened to me. I shall not, at this time, further trespass upon their patience. I shall have occasion, however, in the progress of the debate <PageNumber num={740} /> upon this subject, again to trouble them upon some of these points. I therefore leave untouched some that I had intended
                  to consider.
                </p>
                <div className="speaker" id="sp715"><span className="persName">Mr. RANDOLPH</span> obtained the floor and said :</div>
                <p className="p-in-sp">It is now ten minutes past two o'clock, and unless it should be the pleasure of the Convention that I should go on, I should greatly prefer an adjournment. I am at this time somewhat troubled with hoarseness, which may be removed by morning.</p>
                <div className="speaker" id="sp716"><span className="persName">Mr. BALDWIN</span>—</div>
                <p className="p-in-sp">I move that the committee rise, report progress, and ask leave to sit again.</p>
                <p>The motion was agreed to, and the committee rose and reported progress.</p>
                <p><span className="persName">The PRESIDENT</span> having resumed the Chair,</p>
                <p>On motion of <span className="persName">Mr. HALL</span> of Lancaster, the Convention adjourned.</p>
              </div>
            </div>
            <div className="day" id="vsc1965.v1.2.27">
              <PageNumber num={741} />
              <h2><span className="headingNumber">1.27. </span><span className="head">TWENTY-SEVENTH DAY</span></h2>
              <div className="dateline">Saturday, <span className="date">March 16</span></div>
              <p>The Convention met pursuant to adjournment, and was called to order by the President.</p>
              <p>Prayer by the Rev. <span className="persName">Mr. MITCHELL</span>, of the Presbyterian Church.</p>
              <div className="section" id="vsc1965.v1.2.27.1">
                <h3><span className="headingNumber">1.27.1. </span><span className="head">FEDERAL RELATIONS</span></h3>
                <div className="speaker" id="sp717"><span className="persName">Mr. GOGGIN</span>—</div>
                <p className="p-in-sp">
                  I ask the indulgence of the Convention this morning to enable me to present to the Convention, that it may be referred to the Committee of the Whole, a proposition by way of amendment to the report which has been made by the Committee of Twenty-one, with a view to bring about some tangible and practical points to be reached in this important subject which has employed our
                  deliberations for such a length of time. I shall not this morning attempt any discussion of the proposition which I propose to submit, but my object simply is, to enable me to place it before the Convention in such a form that it may be presented, when printed, to the Committee of the Whole, where I will endeavor to have it placed by way of amendment to the propositions which are now
                  under consideration.
                </p>
                <p className="p-in-sp">I will read, for the information of the Convention, what I propose :</p>
                <p className="p-in-sp">AN ORDINANCE OF THE STATE OF VIRGINIA</p>
                <p className="p-in-sp">
                  Whereas, the State of Virginia has made every honorable effort to restore the friendly relations which should exist between the General Government and the several States of the Union—upon terms perfectly just to all, but deeming it unnecessary to refer to the causes of complaint which have existed for a series of years, still more aggravated as those causes now are by the declared
                  purposes of a mere sectional majority—and as all the efforts so made have proved unavailing—without reciting the differences of opinion which exist in regard to the powers of the State Government, or those of the Government of the United States, as derived from the reserved rights of the one, the constitutional authority of the other, or the inherent rights of the people constituting
                  a Government which seeks to protect the persons and property of those who compose and who have ordained and established it, against the abuses of such Government itself, or which arise from its connexion with the Government of other States, or that of an association of States—the people of Virginia, in Convention assembled, deem it proper now to declare that the time has arrived when
                  it becomes them to assume, <PageNumber num={742} /> as they do, their position as the people of a sovereign independent State.
                </p>
                <p className="p-in-sp">1. Be it, therefore, ordained by the people of Virginia, and they do hereby declare, That the said State is no longer one of the Union of States known as the United States of America, and that the people of the said State owe no allegiance or duty to any Government whatsoever.</p>
                <p className="p-in-sp">
                  2. Be it further ordained, and it is hereby declared, That the people of the said State do assume all rights of property, or the use thereof, which have been granted by the said State to the Government of the United States, or which have in anywise accrued to the same by reason of the connection of the said State with the said Government, by assent of the said State, given with a
                  view to the protection of her own people, and the people of all the other States, composing a Union formed under an agreement that it should establish justice—insure domestic tranquility—provide for the common defence—promote the general welfare, and secure the blessings of liberty to themselves and their posterity—that agreement having been violated, and the objects of the Union
                  perverted so as to defeat the purposes of justice—to destroy the very foundations of domestic tranquility—to lessen the means of common defence—so as to disregard the objects of the general welfare of one entire section of the Union, and thereby to entail, in that section, injury and oppression upon the people thereof, and upon their posterity forever.
                </p>
                <p className="p-in-sp">
                  3. And without determining, at this time, whether the State of Virginia will unite herself with any other State or association of States, in any common government, this Convention doth respectfully and earnestly request that the States of North Carolina, Tennessee, Kentucky, Maryland, Missouri, Arkansas and Delaware will, as soon as possible, appoint commissioners to meet
                  commissioners to be appointed by this Convention, in the city of Lexington, in the State of Kentucky, on the last Wednesday in may next, to confer together, and to propose a plan of constructing a Government to be formed by the said States, Virginia inclusive, and the Confederate States of America. Such plan of Government, however, to have no binding authority till the same shall be
                  adopted and ratified by this Convention. And for the better accomplishment of the objects intended, the said Confederate States of America are also respectfully requested to send three commissioners to the Conference herein proposed, who shall be invited, at such time as may be agreeable to them, to address the same. That each of the States herein before named, shall be entitled to
                  as many votes as it had <PageNumber num={743} /> Representatives and Senators in the last Congress, in the Conference herein proposed. That the Commissioners to be appointed by this Convention shall make report to the Governor of this Commonwealth, as speedily as possible, of the result of their deliberations—whereupon he shall make known the same by
                  proclamation. That on the 15th day after the date of such proclamation (unless the same be Sunday, then on the next day) , this Convention shall re-assemble in the city of Richmond, at such place as the Governor shall designate in said proclamation, and shall then and there consider the report of the said commissioners, and all other matters which at this time are, or may then be,
                  proper subjects for deliberation, touching the future relations of the State of Virginia to any other Government or State.
                </p>
                <p className="p-in-sp">
                  4. And it is hereby ordained and declared by the people of Virginia, That they do recognize and acknowledge the, independence and nationality of the said Confederate States of America; and that they will extend to the said States any aid which they, the said people of Virginia can command, or which may be necessary to enable the said States to maintain their independence, or against
                  any coercive measures which may be adopted by the authorities of the United States.
                </p>
                <p className="p-in-sp">
                  5. Be it further declared, That the people of Virginia have ever cherished an ardent attachment for the Union and the Constitution of the United States while it was the bond of peace and fraternity; and that it can now only be restored upon the original basis by an amendment of the Constitution through the primary agency of the non-slaveholding States themselves proposing suitable
                  and sure guarantees, and by a full and unconditional, plain and positive recognition of the rights of property in slaves, as held under the laws of any of the States; so as also to obtain satisfactory assurances and guarantees, for the future, as to slavery in the District of Columbia; as to the powers of the Federal Government over African slavery, and the employment of slave labor
                  in the forts, arsenals, dock yards, and all places ceded by the States for Federal uses; as to protection against excessive direct taxes on slaves; as to the rendition of fugitive slaves; as to the transit with slaves through any of the States by land or water, and of the right of transportation on the high seas of slaves from one State to another State or Territory; as to the
                  protection of slave property in the common territories of the United States; as to the better security of the independence of the judiciary, and for protection against unjust taxation in the form of excessive imposts laid upon foreign importations.
                </p>
                <p className="p-in-sp">
                  6. Be it further declared, That the people of Virginia, though they have taken their position, have an anxious desire to preserve peace, <PageNumber num={744} /> and would, therefore, regard any action of the Federal Government tending to produce a collision, pending negotiations for the adjustment of existing difficulties, as aggressive and injurious
                  to the interests and offensive to the honor of this State; and they would regard any action on the part of the Confederate States of America, tending to produce a like collision, as hurtful and unfriendly; and as leaving the people of Virginia free to determine their future policy.
                </p>
                <p className="p-in-sp">
                  7. Be it further declared, That the President of this Convention shall immediately cause copies of this ordinance to be forwarded to the Governors of each of the United States, as the same existed on the first day of December 1860, to the President of the Confederate States of America, and to the President of the United States. And it is further ordained, that this Convention will
                  proceed at once to the appointment, viva voce, of fifteen commissioners to attend on behalf of this State, the Conference herein proposed to be holden; and it is provided, also, that if commissioners shall fail to attend the said Conference, from the other States named in the third clause of this ordinance, so that the said Conference shall not be holden, then the said commissioners
                  from this State shall, in like manner, report the fact to the Governor, who shall make proclamation thereof, when also this Convention shall re-assemble at the time herein provided for.
                </p>
                <p className="p-in-sp">
                  8. But this Convention, anxious as it is to take no step to disturb existing relations, only so far as is necessary, but seeking to avoid any collision, doth suspend the operation of the second clause of this ordinance till such time as it may deem proper to enforce the same, and with a view to an adjustment of the pending difficulties, through the agency of the Conference herein
                  provided for, and by a returning sense of justice among the people of all sections.
                </p>
                <p className="p-in-sp">
                  I do not propose to occupy the time of the Convention, as I said before, with any extended remarks which it might seem proper to make upon the proposition which I propose by way of amendment to the report of the committee. I only wish to say that the position which I have here indicated as the one I deem it proper for the State of Virginia to assume, is no new one; and therefore, in
                  order that I may disabuse the minds of those persons upon whom an erroneous impression may have been made, I beg leave for one moment to refer to the position which I assumed when I was a candidate before the people of my county for a seat in this Convention :
                </p>
                <p className="p-in-sp">
                  "He believed that Virginia should now take her position—she must take it as a sovereign, independent State—she should at once, however, and without delay, set about the reconstruction of some national government <PageNumber num={745} /> in which she can maintain her position of perfect equality with her sister States. He was by no means prepared to go
                  the length of some others, and to declare that Virginia, under all circumstances, should unalterably attach herself to a Union of slaveholding States alone, but when she has become a party to a proposed Constitution as fully sufficient to protect her rights in all time, the result of earnest efforts to harmonize with other States in the formation of such a Constitution, then he would
                  say to New Jersey, Pennsylvania, Ohio, Indiana, Illinois and Iowa, come and be with us and of us, by endorsing our bond of Union, and giving earnest that you intend in all time to abide by it. He would even go farther : he would exact nothing for the South but what was strictly proper; he would then say to all the States, North and South, if you will again, with us, unfurl the flag
                  with the stars and stripes, and agree never to war upon us so long as there is a piece of the bunting left, according to the bond as we have made it, with the Constitution of the present Union as the basis, then we bid you welcome to the protection which it will once more secure to the happy people of the United States of America."
                </p>
                <p className="p-in-sp">
                  I believe that the proposition which I have here submitted will tend to bring about a re-construction of this Union. I do not desire to see this Union forever destroyed. I do not agree with gentlemen around me, who believe that Virginia should in all time connect herself with an exclusive slaveholding confederacy. I have lived long enough, Mr. President, to appreciate the blessings
                  of this Union and to know something of the history of those common struggles and honors which have made our history so glorious and which have made our country so much respected and revered abroad. Sir, I can well appreciate the patriotism and the sacrifices which have so adorned the history of the past, although I have never been one of those the incidents of whose lives have formed
                  any portion of that history. I desire to see, sir, this Union, if possible, re-constructed, as it was designed to be by our fathers; but I believe it is the duty of Virginia now to take her position outside of this Union, in order that she may maintain and preserve the peace and the unity of this great Republic of ours. I shall hail it as the happiest day of my life, if I can once
                  more see this glorious event consummated, and I stand here to take, as I have taken, the very position which I intended I would take at home.
                </p>
                <p className="p-in-sp">
                  I stand here, sir, as a conservative, Union-loving man ; but conservative and Union-loving as I am, I believe the time has now arrived, when Virginia should take her position, when she should indicate what action she should take: when she should go outside of this <PageNumber num={746} /> Union, and there assert those rights which she has been unable to
                  obtain, while she has been a member of it.
                </p>
                <p className="p-in-sp">I therefore ask, Mr. President, that this position which I submit by way of amendment may be laid upon the table, printed, and referred to the Committee of the Whole.</p>
                <p><span className="persName">Mr. GOGGIN</span>'S proposition was laid on the table, ordered to be printed, and referred to the Committee of the Whole.</p>
                <div className="speaker" id="sp718"><span className="persName">Mr. HOLLADAY</span>—</div>
                <p className="p-in-sp">I hold in my hands a petition, signed by several hundred of my constituents of Norfolk, enclosed in the flag of the United States of America. I am instructed, sir, to present the petition in the enclosure to this Convention.</p>
                <p className="p-in-sp">The language of the instruction is in these words :</p>
                <p className="p-in-sp">"We, the undersigned citizens of the county of Norfolk, do hereby affirm our devotion to the Constitution and Union of the States, and request our delegates to the Convention to stand by the same under the existing circumstances."</p>
                <p className="p-in-sp">This memorial is numerously signed, bearing, I think, upwards of 800 names, and, if needful, the number of signers might have been more than doubled. My constituents have deemed it necessary to forward this memorandum to their delegates in view of the action of the constituencies of other gentlemen who occupy seats upon this floor.</p>
                <p className="p-in-sp">
                  Now, sir, I desire to say one single word in reference to the language there used. They express their devotion to the Constitution and the Union of these States, and instruct their delegates to stand by the same under existing circumstances. One word of explanation in reference to the term "existing circumstances." I wish that gentlemen should understand this, that these questions
                  were too fully and elaborately discussed before my constituents, for me to misunderstand what is liable perhaps to misconstruction by others.
                </p>
                <p className="p-in-sp">
                  The phrase "under existing circumstances" implies that they are not hopeless of an adjustment of the difficulties upon honorable terms. Sir, my people have been taught to regard the Constitution of the United States as much a part of their Constitution as they regard the Constitution of Virginia as their own Constitution. They do not regard the two as inimical, the one to the other.
                  They hold that there is no antagonism between them; that the one is as much their Government and a part of their Government, as the other. They hold, sir, that the Constitution of the United States is the great charter of the rights and sovereignty of the States, and as it is the charter of personal rights and personal freedom, they are desirous of preserving that Constitution
                  intact, of preserving it in the spirit in which our fathers framed it. <PageNumber num={747} /> They are desirous, therefore, of securing such guarantees as shall forever dispose of those agitating questions which disturb the public mind of the country. I wish it to be understood that they are not, in the common and acrimonious language of the present
                  day, submissionists. They are prepared to exhaust and are determined to exhaust every means in their power to obtain those guarantees which they require, and if defeated in obtaining those remedies, they are prepared to make common cause, and go as far as any, in the assertion of the rights of Virginia, under the Constitution as we understand it.
                </p>
                <p className="p-in-sp">With these remarks, I beg leave to refer the petition to the Convention, and I move that it be laid upon the table.</p>
                <p>The petition was laid on the table.</p>
              </div>
              <div className="section" id="vsc1965.v1.2.27.2">
                <h3><span className="headingNumber">1.27.2. </span><span className="head">UNION RESOLUTIONS</span></h3>
                <div className="speaker" id="sp719"><span className="persName">Mr. BURLEY</span>—</div>
                <p className="p-in-sp">I hold in my hands some resolutions, which I wish to offer to the Convention. I ask that they may be laid upon the table and printed, with the view of calling them up at some future day, for the consideration of this body.</p>
                <p>The resolutions were then read by the Clerk, as follows :</p>
                <p>
                  Resolved, That this Convention can see no reason for departing from the faith of our fathers and from the principles on which the Government of the United States was founded, and therefore we declare, in the name of our constituents the people of Virginia, that the Constitution of the United States was, in the language of Mr. Madison, adopted by the people of the several States, who
                  were parties to the compact in their highest sovereign capacity "in toto and forever."
                </p>
                <p>
                  2. Resolved, As the fixed and deliberate opinion of this Convention that nullification and secession are fallacies and heresies, and in the language of Mr. Madison, "both spring from the same previous root,"<span className="note" id="Note74"><span className="noteLabel">1</span>A correction (March 21) of Mr. Burley's resolutions reads "poisonous" instead of "previous."</span> that they had no
                  place in the minds of the framers of the Constitution, and are political anomalies in government which the sound practical sense of the people will never adopt or submit to, and which, if once recognized, will utterly and entirely overthrow all possibility of establishing a fixed and permanent government on this continent.
                </p>
                <p>
                  3. Resolved, In the language of the illustrious statesman above referred to, whom the people of Virginia have been taught to venerate and revere as the wisest, safest and truest expounder of the Constitution which he so largely contributed to construct, that that instrument "makes the government to operate directly on the people, places at its command the needful physical means of
                  executing its powers, and finally proclaims its supremacy and that of the laws made <PageNumber num={748} /> in pursuance of it, over the Constitution and laws of the States, subject to the revolutionary rights of the people, in extreme cases ; that a political system that does not provide for a peaceable and authoritative termination of existing
                  controversies would not be more than the shadow of a government, the object and end of a real government being the substitution of law and order for uncertainty, confusion and violence. That in the event of a failure of every Constitutional resort, and an accumulation of usurpations and abuses, rendering passive obedience and non-resistance a greater evil than resistance and
                  revolution, there can remain but one resort, the last of all, an appeal from the conceded<span className="note" id="Note75"><span className="noteLabel">2</span>The corrected version reads "canceled" instead of "conceded."</span> obligations of the Constitutional compact to the original rights and the law of self-preservation. This is the ultima ratio of all governments, whether consolidated,
                  confederated, or a compound of both. It cannot be doubted that a single member of the Union, in the extremity supposed, BUT IN THAT ONLY, would have a right as an extra and ultra Constitutional right to make the appeal."
                </p>
                <p>
                  4. Resolved, That the forts, fortifications, armies, arsenals, arms, ammunition, ships of war, custom houses, mints, post-offices and other property of the United States, can, by the Constitution, be disposed of only by Congress, and that no portion of the people have any interest in or any claim to any part thereof after they cease to be citizens of the United States, and when they
                  no longer participate in the payment of its debts or in the defence of the Constitution of the country.
                </p>
                <p>
                  5. Resolved, That, while no doubt can exist on the minds of this body of the right and the obligation of the Government to execute all its laws fairly, impartially and promptly upon all its citizens without distinction or discrimination, yet, under the extraordinary condition of things as they now exist, we earnestly and anxiously urge upon the Executive Department of the Government
                  the policy of abstaining from the exercise of such power at any point where such attempt would be likely to occasion collision, so long as there are efforts to be made by the other States or hopes to be indulged of a final and peaceful settlement of the difficulties with which the country is embarrassed.
                </p>
                <p>
                  6. Resolved, That the right of revolution above recognized can be exercised as well by a portion of the citizens of a State against their State Government, as it can be exercised by the whole people of a State against their Federal Government, and when the powers of a State Government are used for purposes of unjust discriminations against a portion of the citizens, or a particular
                  section of the State, in imposing upon one portion or section an undue proportion of the burdens of the State Government, and in exempting from taxation a <PageNumber num={749} /> peculiar species of property belonging to a great extent to another portion of the citizens, and located mostly in another section of the State, thus increasing taxation upon
                  all other interests in order to favor a "peculiar interest," the people thus oppressed, after having exhausted all constitutional efforts to obtain redress, would be justified in resisting the collection of all revenue from them until the injustice aforesaid was removed. And that any change of the relation Virginia now sustains to the Federal Government, against the wishes of even a
                  respectable minority of her people, would be such an act of injustice perpetrated upon the rights of that minority, as to justify them in changing their relation to the State Government, by separating themselves from that section of the State that had thus wantonly disregarded their interests and defied their will, particularly when the cause assigned for the change of Virginia's
                  relation to the said Federal Government, is the alleged insecurity, in the said last-mentioned Government, of the peculiar species of property thus protected by the organic law of the State from contributing its due share to the support of the said State Government, by prohibiting the taxing of a large portion of said property, and limiting the portion subject to taxation to a
                  specified tax far less than that imposed upon every other species of property.
                </p>
                <p>The resolutions were laid on the table and ordered to be printed.</p>
              </div>
              <div className="section" id="vsc1965.v1.2.27.3">
                <h3><span className="headingNumber">1.27.3. </span><span className="head">CHANGE OF HOUR OF MEETING</span></h3>
                <div className="speaker" id="sp720"><span className="persName">Mr. SUTHERLIN</span>—</div>
                <p className="p-in-sp">
                  I am aware of the fact that a resolution for meeting at an early hour has been voted upon twice before, and I hope, in view of the condition of our country, in view of the necessity that the people, not only our constituents, but the people of the entire land, should be made acquainted with what is to be the final action of this Convention, they will adopt the policy of spending more
                  time in their deliberations, and thereby come to an earlier conclusion of their labors. I offer, then, the following resolution :
                </p>
                <p>Resolved, That hereafter this Convention will commence its early session at 11 o'clock, A.M.</p>
                <div className="speaker" id="sp721"><span className="persName">Mr. ARMSTRONG</span>—</div>
                <p className="p-in-sp">I move to amend the resolution by making it 10 o'clock.</p>
                <div className="speaker" id="sp722"><span className="persName">Mr. PATRICK</span>—</div>
                <p className="p-in-sp">There is a resolution of the same kind now on the table, and the gentleman, to accomplish his object, must move to take that resolution from the table, instead of offering a new one.</p>
                <div className="speaker" id="sp723"><span className="persName">Mr. SUTHERLIN</span>—</div>
                <p className="p-in-sp">I move, then, to take the resolution from the table.</p>
                <PageNumber num={750} />
                <div className="speaker" id="sp724"><span className="persName">Mr. EARLY </span>—</div>
                <p className="p-in-sp">This matter can be disposed of after the Committee of the Whole shall rise. I object to the question now.</p>
                <div className="speaker" id="sp725"><span className="persName">Mr. SCOTT</span>—</div>
                <p className="p-in-sp">I understand that the resolution which was adopted by the Committee the other day required that we resolve ourselves into a Committee of the Whole at half-past twelve o'clock. That hour is passed.</p>
                <div className="speaker" id="sp726"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">The Chair understood that by general consent, the gentleman from Pittsylvania [<span className="persName">Mr. SUTHERLIN</span>] was allowed to proceed. The resolution will be read by the Clerk, as follows :</p>
                <p>Resolved, That hereafter this Convention will meet at the hour of eleven o'clock, instead of the usual hour.</p>
                <div className="speaker" id="sp727"><span className="persName">Mr. ARMSTRONG</span>—</div>
                <p className="p-in-sp">I move to amend the resolution by striking out "eleven" and inserting "ten."</p>
                <p>The question was then taken, and the amendment was rejected.</p>
                <p><span className="persName">Mr. HUBBARD</span> moved to amend by making the time "half past ten o'clock."</p>
                <p><span className="persName">Mr. ARMSTRONG</span> demanded the yeas and nays, which were ordered. The question was then taken and resulted as follows :</p>
                <p>
                  YEAS--Messrs. Armstrong, Aston, Baldwin, A. M. Barbour, Baylor, Berlin, Boggess, Borst, Brent, Bruce, Byrne, Cecil, C. B. Conrad, Couch, J. H. Cox, Custis, Dorman, Early, Flournoy, Fugate, Gillespie, Grant, Gravely, Addison Hall, Cyrus Hall, Ephraim B. Hall, Hammond, Haymond, Holladay, Hubbard, Hull, Hunton, Jackson, Marmaduke Johnson, Peter C. Johnston, Kilby, Lawson, McComas,
                  McGrew, McNeil, Macfarland, J. B. Mallory, Marshall, Marr, Maslin, Masters, Moffett, MOORE, Osburn, Parks, Pendleton, Porter, Price, Pugh, Randolph, Rives, Saunders, Sr., Sharp, Sheffey, Sitlington, Slaughter, Spurlock, Chapman J. Stuart, Strange, Sutherlin, Tarr, Tayloe, R. H. Turner, Whitfield, Wilson-70.
                </p>
                <p>NAYS-Messrs. Janney [President] 
                  , Ambler, J. Barbour, Blow, Jr., Bouldin, Boyd, Brown, Burdett, Burley, Caperton, Chambliss, Conn, R. Y. Conrad, Deskins, Dulany, Fisher, French, Garland, Graham, Gray, Gregory, Jr., Goggin, T. F. Goode, Hale, Hughes, Isbell, Kindred, Leake, Marye, Sr., Miller, Morris, Morton, Neblett, Orrick, Patrick, Preston, Richardson, Robt. E. Scott, William C. Scott, Seawell, Southall, Speed,
                  Staples, Franklin P. Turner, WOODS, Wysor-46.
                </p>
                <p>So the amendment was agreed to.</p>
                <div className="speaker" id="sp728">Pending the call, <span className="persName">Mr. WILLEY</span> said—</div>
                <p className="p-in-sp">At the instance of my friend from Amelia [<span className="persName">Mr. HARVIE</span>] , I have paired off with him. If it were not so, I would have voted aye.</p>
                <p>The resolution as amended was then agreed to.</p>
                <div className="speaker" id="sp729"><span className="persName">Mr. CONRAD</span>—</div>
                <p className="p-in-sp">
                  There is no vote as to changing the hour of going into Committee of the Whole, I believe. I should have no objection to meet at an earlier hour, but I should object to any change of the hour for <PageNumber num={751} /> going into the Committee of the Whole, because it would prevent the Committee on Federal Relations from attending the debate upon their
                  own report.
                </p>
                <div className="speaker" id="sp730"><span className="persName">Mr. ARMSTRONG</span>—</div>
                <p className="p-in-sp">I now make the motion that we go into the Committee of the Whole at 11 o'clock.</p>
                <div className="speaker" id="sp731"><span className="persName">Mr. WOODS</span>—</div>
                <p className="p-in-sp">
                  I trust that it will not be the pleasure of the Convention to change the hour at which we shall resolve into Committee of the Whole. There are a good many other matters of general interest and of pressing importance which must come before this Convention before it adjourns, and it would be just as well to devote the additional hour and a half which we have obtained by the resolution
                  which has been adopted to the consideration of these questions. There are several resolutions now lying upon the table which look to the appointment of Committees upon various subjects in regard to such changes as may be deemed necessary in the organic law of the State. While I am as anxious as any gentleman in this body that this Convention shall give to the reports of the Committee
                  upon Federal Relations such consideration as they may deserve, and to take such ultimate action therein as will secure the rights and the interests of Virginia, as a member of the Federal Union, I trust it will be the pleasure of this Convention also to pay a little attention to those other matters which very materially interest her own citizens.
                </p>
                <p className="p-in-sp">
                  It is not proper that I should attempt to discuss any of these questions now. I shall not do so, but I trust that it will be the pleasure of the Convention to refuse to change the hour now adopted, for resolving itself for going into Committee of the Whole, so that whatever additional time we may possess may be, from time to time, devoted to the consideration of these questions; and
                  that such preparatory steps may be taken, and such preliminary action had as may enable us to obtain beneficial results in that direction hereafter.
                </p>
                <p className="p-in-sp">For these reasons and many others that might be deduced, I trust it will be the pleasure of the Convention to vote down the motion now pending.</p>
                <div className="speaker" id="sp732"><span className="persName">Mr. FISHER</span>—</div>
                <p className="p-in-sp">I make a point of order that the motion just submitted is not in order.</p>
                <p className="p-in-sp">The Convention has adopted a resolution requiring the Convention to go into a committee of the whole at half past 12 o'clock, and we cannot get rid of that order except by a motion to reconsider.</p>
                <div className="speaker" id="sp733"><span className="persName">THE PRESIDENT</span>—</div>
                <p className="p-in-sp">The resolution is in these words : "That the Convention will resolve itself into a committee of the whole at half-past 12 o'clock, unless otherwise ordered," and the Chair thinks it is now <PageNumber num={752} /> competent for this Convention to make an order otherwise if it chooses to do so.</p>
                <div className="speaker" id="sp734"><span className="persName">Mr. DORMAN</span>—</div>
                <p className="p-in-sp">
                  I should be very glad if this matter could be arranged so as to suit the Committee on Federal Relations. Unless the Chairman of the Committee can give us some assurance that the change of hour to half-past 10 o'clock will meet the wishes of that Committee, I cannot vote in favor of this change. I would wish very much that that Committee could arrange its business so that we could
                  enter upon the business of the Convention at an earlier hour. It is the usual practice in parliamentary bodies to have a morning hour for the purpose indicated by the gentleman from Barbour
                 [Mr. WOODS] , and I would like to know whether the time fixed by the motion just submitted would suit the convenience of the Committee on Federal Relations.</p>
                <p className="p-in-sp">I would, therefore, ask for information from the Chairman of the Committee, whether the hour of half-past 11 to go into a committee of the whole would suit that Committee.</p>
                <div className="speaker" id="sp735"><span className="persName">Mr. R. Y. CONRAD</span>—</div>
                <p className="p-in-sp">
                  At the present time I should be compelled to object to any change in the standing order, although I trust within one or two days the objections will be removed. I should be very unwilling for my own part—and I have no doubt, other members of the Convention would be also unwilling to be absent from the Hall during the discussions upon their own report, and I fear that absence will be
                  rendered necessary, if any change is made in the hour for going into Committee of the whole.
                </p>
                <div className="speaker" id="sp736"><span className="persName">Mr. DORMAN</span>—</div>
                <p className="p-in-sp">Under these circumstances, sir, I move to lay this resolution on the table.</p>
                <div className="speaker" id="sp737"><span className="persName">Mr. ARMSTRONG</span>—</div>
                <p className="p-in-sp">I merely rise, Mr. President, to say that, in view of the explanation of the Chairman of the Committee on Federal Relations, I will withdraw my motion.</p>
                <div className="speaker" id="sp738"><span className="persName">Mr. FLOURNOY</span>, of Halifax—</div>
                <p className="p-in-sp">
                  If the hour at which this Convention is to resolve itself into a Committee of the Whole is to remain half-past 12 o'clock, it really seems to me that too much time between the hour which has been fixed for the meeting of the Convention, and the hour for going into Committee of the Whole, is set apart for other business. I do not imagine that this Convention will find that it will
                  have sufficient business to occupy fully the two hours which this resolution sets apart; and the Committee has indicated, by its Chairman, that they will be unable to attend the sittings of the Committee of the Whole if the proposed change is made. It really seems hardly desirable that we should meet at so early an hour as half-past 10 o'clock.
                </p>
                <PageNumber num={753} />
                <p className="p-in-sp">Is it in order to make a motion to reconsider the vote by which this hour was fixed?</p>
                <div className="speaker" id="sp739"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">The Chair cannot, under the rule, entertain that motion unless by general consent.</p>
                <p>There being no objection, <span className="persName">Mr. FLOURNOY</span> submitted his motion to reconsider.</p>
                <div className="speaker" id="sp740"><span className="persName">Mr. SCOTT</span>, of Fauquier—</div>
                <p className="p-in-sp">I think that probably the difficulty will be removed in two or three days. The committee will probably report on Monday.</p>
                <div className="speaker" id="sp741"><span className="persName">Mr. FLOURNOY</span>—</div>
                <p className="p-in-sp">In view of the statement made by the gentleman from Fauquier [<span className="persName">Mr. SCOTT</span>] , I will withdraw my motion.</p>
              </div>
              <div className="section" id="vsc1965.v1.2.27.4">
                <h3><span className="headingNumber">1.27.4. </span><span className="head">REPORTS OF THE COMMITTEE ON FEDERAL RELATIONS</span></h3>
                <p>The Convention then resolved itself into a committee of the whole, <span className="persName">Mr. SOUTHALL</span> of Albemarle in the Chair.</p>
                <div className="speaker" id="sp742">
                  <span className="persName">Mr. RANDOLPH</span>, of Richmond city, obtained the floor, and said :<span className="note" id="Note76"
                  ><span className="noteLabel">3</span>The delivery of Mr. Randolph's speech extended over three days: March 16, 18, and 19. However, the speech was printed with no indication of where the breaks occurred in its delivery. The text of the whole speech has been incorporated in the Proceedings of March 16. The figures in the tables have been verified arithmetically. The statistics quoted in
                    Mr. Randolph's speech have been corrected (when possible) to agree with the tables; but some unexplained discrepancies between these quoted figures and the tables have been allowed to stand. No effort has been made to compare the tables with the sources from which they were drawn.</span
                  >
                </div>
                <p className="p-in-sp">Mr. Chairman, it is generally known that I obtained the floor yesterday with the design of asking the permission of the Convention to transfer it to my friend from Albemarle [<span className="persName">Mr. HOLCOMBE</span>] 
                  , should the impaired condition of my voice hinder me from proceeding with my remarks. Unfortunately for the Convention, and for the audience assembled to hear that eloquent gentleman, the indisposition which has detained him from his seat for several days, still continues, and, somewhat unexpectedly, and in a condition unfit to do my subject justice, I am compelled to offer myself
                  as a most unworthy substitute for my eloquent friend. Thus situated, I shall proceed with the discussion, and should I prove physically unable to go on, I shall ask the permission of the Convention to continue my remarks on some other occasion, or to print what I may leave unsaid.
                </p>
                <p className="p-in-sp">
                  The questions arising from the various reports of the Committee on Federal Relations, present themselves in two aspects—one political, the other commercial; one having reference to proposed changes of our organic law, the other looking to the industrial interests of the Commonwealth. Representing, in part, the chief manufacturing and commercial city of the State, I shall confine
                  myself, in the main, to the commercial questions, which, possessing great interest for my constituents, have not yet received much consideration in the debates of this body. I do not feel at liberty, however, altogether to decline the discussion of the political questions, and I shall, therefore proceed to consider such of them as more immediately press upon our notice.
                </p>
                <p className="p-in-sp">The most prominent plan of Constitutional amendment, is that <PageNumber num={754} /> emanating from the Peace Conference, endorsed by their recommendation, and expounded most ably and elaborately by the distinguished gentleman from Kanawha [<span className="persName">Mr. SUMMERS</span>] . I heard him with great attention, without bias and with that pleasure which I always feel in witnessing exhibitions of high intellectual power; but after giving the subject a careful consideration, I find myself entirely unable to concur in his conclusions.</p>
                <p className="p-in-sp">Before examining this plan in detail, let us consider for a moment what should be the indispensable requisites of a satisfactory adjustment ; to do which we must first enquire what the dangers are against which it is intended to protect us.</p>
                <p className="p-in-sp">
                  On the 6th of November last the Government passed, in strict accordance with Constitutional forms, into the hands of a sectional party, organized upon principles hostile to our institutions, social, political and industrial. In the opinions of some, the observance of Constitutional forms in that election robs it of all wrong. In my judgment, it is that very observance of form which
                  renders the election so dangerous to our liberties. Had revolution been attempted, we should have known how to deal with it. We should have opposed with the strong arm of force the seizure of the government. But the fact that our enemies found it unnecessary to violate the forms of the Constitution proves the insufficiency of that instrument for our protection. This is acknowledged
                  on all hands. No one who has risen in debate on this floor denies the necessity of amending the Constitution.
                </p>
                <p className="p-in-sp">
                  The Government, then, if I may so express it, is constitutionally revolutionized, and requires a counter revolution to restore it. We know that while the South and its friends were in possession of the Executive, the Judiciary and one branch of the Legislature, we were assailed in the territories, we were robbed of our slaves, our soil was invaded, our citizens murdered, our right of
                  transit with slaves from one Southern State to another was obstructed, our right of property in slaves was denied, our slaves themselves were incited to rebellion, we were threatened with Abolition in all places of exclusive Federal Jurisdiction, and our character was systematically traduced through all Christendom. These things, and many more, were effected by our enemies out of
                  power, and while fifteen slave States were in the Union. Now that our adversaries possess the Government in all its branches—for the Judiciary will soon be theirs—and that our number is reduced to eight, what may we not expect? It is easy to imagine points of assault, yet unattempted. Our commerce may be left unprotected ; may even be actively warred upon through the treaty making
                  power and the <PageNumber num={755} /> diplomatic establishment; we may be excluded from all share in the administration of the government as we have been in the choice of persons to conduct it ; we may be reduced from the position of equals to one of inferiority; from sovereignty to virtual vassalage, and yet the forms of the Constitution may continue
                  to be observed.
                </p>
                <p className="p-in-sp">
                  But we should be most unwise to suppose that we can foresee all dangers, and protect all possible points of assault. The ingenuity of our enemy is infinite, and we know not when, where, or from what quarter, we may be next assailed. We have made immense sacrifices to procure a recognition of our rights, and those sacrifices must not be thrown away. In the struggle, industry has been
                  paralyzed in all its branches, the commercial community has well nigh experienced the agonies of dissolution, millions have been lost already, and more yet will be sunk. We must end the contest now, and forever. We must have not only immunity from attack, but rest from the ever wearing agitation of the slavery question. We must rest secure against hostile legislation, hostile
                  diplomacy, the power of corruption, the power of agitation, and from unfriendly power in every shape and form. And to do this we must have power and not promises, an efficient share in the Government and not mere paper guarantees.
                </p>
                <p className="p-in-sp">
                  Tried by this test, the Peace Conference plan is an utter failure. It does not profess to give us power, save in one particular, and there the grant is insufficient and delusive. It does profess to give us guarantees, but its professions here, as I shall attempt to show, are not fulfilled. Let us examine the sections of the plan successively, avoiding, as far as may be, what has been
                  already said about them by the venerable gentleman from Charles City
                 [<span className="persName">Mr. TYLER</span>] . Unless I am much mistaken we shall find that under pretence of protecting slavery and quieting the anti-slavery agitation, it incorporates into the Constitution the dogmas of the Republican party, and stores up material for everlasting controversy.</p>
                <p className="p-in-sp">
                  The first section undertakes to settle the law of the Territories. We are told that this is unimportant, inasmuch as the Territories are of small value. It is not the value of the Territories which gives magnitude to the territorial question, but the fact that it involves an issue as to the character of the institution of slavery. If there were but one square mile of territory, the
                  question would be one of the first magnitude. Our right to carry slaves into the Territories is resisted, on the ground that slavery does not involve a right of property in slaves, but that it is a mere personal relation, dependant for its existence on municipal jurisprudence, local in extent, not recognized by the Constitution
                  <PageNumber num={756} /> of the United States, and ceasing the moment the slave is carried from the State in which the relation was created. We, on the other hand, found our rights in the territories on the right of property in slaves; we maintained that like other rights of property it is not created, but protected by municipal jurisprudence; that,
                  consequently, it is not local, but general, and that it is especially recognized and protected by the Constitution of the United States. This, then, is the issue involved in the territorial question; it concerns not merely the law of the Territories, but the status of four millions of slaves in the Southern States.
                </p>
                <p className="p-in-sp">The first section, therefore, of the Peace Conference plan is one of vast importance. Let us see what it does for the protection of our rights.</p>
                <p className="p-in-sp">
                  You will have remarked, no doubt, Mr. Chairman, the change of phraseology in passing from the North side to the South side of the line which is to divide freedom and slavery in the Territories. North of the line "involuntary servitude" is prohibited save in punishment of crime. No one doubts or denies that this phrase applies to slaves, and that it prohibits slavery whether slavery
                  be property in slaves or a mere relation. While South of the line "the status of persons held to service or labor as it now exists shall not be changed." What is meant by "persons held to service or labor?" The gentleman from Kanawha
                 [<span className="persName">Mr. SUMMERS</span>] , says that it is the language of the Constitution, and that it recognizes property in slaves. The Republican leaders on the other hand rely on this very phrase to prove that the Constitution does not recognize property in slaves, and that slavery is a mere personal relation.</p>
                <p className="p-in-sp">Senator Fessenden in a speech delivered in the United States Senate on the 8th of February, 1858, in commenting on the Dred Scott case, said :</p>
                <p className="p-in-sp">
                  "The Court say that slaves are recognized as property by the provision that 'persons held to service' escaping from one State to another shall be delivered up. Are they not spoken of as 'persons?' Are they spoken of as property? Is there anything about their being property? Does not that provision of the Constitution apply just as well to white apprentices, held under the laws of the
                  different States for a term of years, as it does to slaves? Will you pretend that by the Constitution of the United States white persons held as apprentices for a term of years are property? Certainly no such position can exist?"
                </p>
                <p className="p-in-sp">
                  Senator Collamer, in a speech made in the Senate on the 2d of March, 1858, in criticising the same decision, said : <PageNumber num={757} /> "The Supreme Court rests the claim to consider slaves as property upon this clause of the Constitution. 'No person held to service or labor in one State under the laws thereof, escaping to another State shall, in
                  consequence of any law or regulation therein be discharged from such service or labor.' Now, the question is, does that guarantee it? Does that make it the same as other property? The very fact that this same clause makes provision on the subject of persons bound to service shows that the framers of the Constitution did not regard it as other property. It recognizes slavery, if
                  property at all, to be property local within the State whose laws make it such, not as beyond it."
                </p>
                <p className="p-in-sp">Who will be the expounders of this phrase? Not the gentleman from Kanawha [<span className="persName">Mr. SUMMERS</span>] 
                  , nor any other Southern man; but Republican judges, commissioned because they hold the tenets of their party. This status then, which is not to be changed, is not a right of property, and the provision pretending to recognize slavery, incorporates in the settlement of the question, the Republican argument disproving the existence of property in slaves. How does it help the matter to
                  inform us that the phrase employed is the language of the Constitution? It is the misconstruction of the Constitution of which we complain, and the design of this settlement was to remove the possibility of misconstruction. It does so by employing the very language which has given rise to the controversy and has afforded the Republicans their favorite argument against slavery. The
                  gentleman from Kanawha
                 [<span className="persName">Mr. SUMMERS</span>] , in urging this provision upon our acceptance, merely repeated the Southern argument and omitted that of the Republicans, although the latter is to be the rule of construction.</p>
                <p className="p-in-sp">
                  Let us examine this phrase in another aspect. "The status as it now exists shall not be changed." By what standard is that status to be tried. Undoubtedly, we in the South, maintain that the Constitution carries slavery into the Territories, and that it overrode the Mexican law in New Mexico. The Territorial legislation, therefore, recognizing slavery in that Territory, relied on by
                  the gentleman from Kanawha
                 [<span className="persName">Mr. SUMMERS</span>] , does in a Southern point of view, fix the status of slavery upon the slaves now there.</p>
                <p className="p-in-sp">But the question material for us to consider, is, whether the Republican judges will recognize that status, to be one of slavery? Unquestionably not, if they hold the dogmas of their party, as announced in the speeches of their leaders, and formally incorporated in their platforms adopted at Philadelphia and Chicago.</p>
                <p className="p-in-sp">
                  By the second resolution of their platform adopted in Philadelphia on the 18th of June, 1856, it is declared, "That we deny the authority <PageNumber num={758} /> of Congress, of a territorial legislature, of any individual, or association of individuals, to give legal existence to slavery in any territory of the United States, while the present
                  Constitution shall be maintained."
                </p>
                <p className="p-in-sp">
                  In the 8th resolution of the platform adopted by the Republicans at Chicago, on the 16th of May, 1860, they declare : "That the normal condition of all the territory of the United States is that of freedom : That as our Republican fathers, when they had abolished slavery in all our national territory, ordained that no person should be deprived of life, liberty, or property, without
                  due process of law, it becomes our duty, by legislation, whenever such legislation is necessary, to maintain this provision of the Constitution against all attempts to violate it ; and we deny the authority of Congress, of a territorial legislature, or of any individuals, to give legal existence to slavery in any territory of the United States."
                </p>
                <p className="p-in-sp">
                  To understand the full significance of the Chicago resolution it must be borne in mind that it was adopted six days after the Republicans had by a majority of seven in the House of Representatives abrogated the slave code of New Mexico. The measure failed in the Senate because there were yet Southern Senators enough to defeat it, but the Republicans immediately took it up and made
                  such legislation, "the duty" of their party.
                </p>
                <p className="p-in-sp">We might suppose that the gentleman from Kanawha [<span className="persName">Mr. SUMMERS</span>] 
                  , was not aware of this Republican dogma, concerning the law of the Territories, but the venerable gentleman from Charles City, informs us that an eminent Republican leader avowed it on the floor of the Peace Congress. It is understood that this candid adversary is the present Secretary of the Treasury. We are compelled, therefore, to conclude that the gentleman from Kanawha,
                 [<span className="persName">Mr. SUMMERS</span>] , urged this provision upon our acceptance, with the full knowledge that in the opinion of Republican leaders it prohibited slavery in the Territories.</p>
                <p className="p-in-sp">Be this as it may, however, it can hardly be doubted that the next Congress will abrogate the slave code of New Mexico, and should the Peace Conference plan be adopted, speaking as it does in the present tense, it will find freedom as the status of New Mexico, and fix that status irrevocably.</p>
                <p className="p-in-sp">The next provision intended for our benefit is, that "no law shall be passed by Congress or the Territorial Legislature, to hinder or prevent the taking of such persons [persons held to service or labor] 
                  from any of the States of this Union to the said Territory, nor to impair the rights arising from the said relation." This sounds very fairly, but, unfortunately for us, the Republican doctrine is, that "the rights <PageNumber num={759} /> arising from the said relation" are not rights of property, but are a mere local relation, which cease when the
                  persons who are the subjects of the relation are carried from the States recognizing it.
                </p>
                <p className="p-in-sp">
                  Recurring again to Senators Collamer and Fessenden, we find the former declaring most explicitly that "slavery cannot extend one inch beyond its own territorial limits." And the latter asserting that the Constitution "neither recognizes slavery as property, nor does it protect slavery as property." We are not forced, however, to rely for an exposition of Republican doctrines on
                  expressions used in debate. We have, on this point, the grave utterances of learned judges on the bench.
                </p>
                <p className="p-in-sp">
                  In the Supreme Court, Justices McLean and Curtis, in dissenting from the opinion of the Court in the Dred Scott case, maintain that slavery is local. Justice McLean says, quoting the case of Prigg vs. the State of Pennsylvania : "In the language of the Court, it is a mere municipal regulation, founded upon and limited to the range of the territorial laws." Justice Curtis says :
                  "Slavery being contrary to natural right, is created only by municipal law. This is not only plain in itself and agreed by all writers on the subject, but is inferrible from the Constitution, and has been explicitly declared by this Court. The Constitution refers to slaves 'as persons held to service in one State under the laws thereof.' Nothing can more clearly describe a status
                  created by municipal law."
                </p>
                <p className="p-in-sp">
                  The former Judge, in another portion of his opinion asks : "By virtue of what law is it, that a master may take his slave into a free territory and exact from him the duties of a slave? The law of the territory does not sanction it. No authority can be claimed under the Constitution of the United States, or any law of Congress." "Does the master carry with him the laws of the State
                  from which he removes into the territory?"
                </p>
                <p className="p-in-sp">Thus it appears that protection is extended to us with one hand and withdrawn with the other. We are assured that no law shall be passed impairing our rights, and we are informed that we have no rights to be impaired.</p>
                <p className="p-in-sp">
                  If this provision be further examined it will be found to contain still another Republican argument against slavery. It declares that the rights arising from the relation "shall be subject to judicial cognizance in the Federal Courts, according to the course of the Common Law." The Republicans maintain that the Common Law recognizes no right in slaves and affords no remedy in
                  controversies concerning freedom. <PageNumber num={760} /> Their highest judicial authority, Justices McLean and Curtis, held this doctrine in the Dred Scott case.
                </p>
                <p className="p-in-sp">
                  Justice McLean says, "Lord Mansfield held that a slave brought into England was free. Lord Stowell agreed with Lord Mansfield in this respect, and that the slave could not be coerced in England; but on her return to Antigua, the place of her slave domicil, her former status attached. For nearly a century the Courts of England have declared that the slave there is free from the
                  coercion of the master. Lords Mansfield and Stowell agree upon this point, and there is no dissenting authority."
                </p>
                <p className="p-in-sp">
                  Justice Curtis says, "The will of States and Nations by whose municipal law slavery is not recognized, has been manifested in three ways. One is, absolutely to dissolve 'the relation,' the second is to refuse the master all aid to exercise any control over his slave ; and if the attempt to do so in a manner justifiable only by that relation, to prevent the exercise of that control.
                  But no law exists designed to operate directly on the relation of master and slave and put an end to that relation. This is said by Lord Stowell, in the case above mentioned, to be the law of England."
                </p>
                <p className="p-in-sp">Our rights, therefore, in slaves carried to the Territories are to be taken cognizance of according to the course of the Common Law, which, in the opinion of the Republican portion of our Judiciary, "will prevent the exercise of any control by the master over his slave."</p>
                <p className="p-in-sp">
                  The gentleman from Kanawha thinks differently, maintains that the course of the Common Law does afford a remedy, and cites in support of his position our practice in Virginia of bringing the Common Law actions of detinue and trover for slaves. Unfortunately for us his opinion will not be the rule of interpretation, and if it were, would require other reasoning to support it. The
                  application by Virginia of Common Law forms of action to controversies between third persons about slaves, could hardly be regarded as proof that the Common Law of England furnishes remedies for the master against his slave. What we need is a remedy for the master against the slave asserting his right to freedom, and it is poor consolation to be told that the Common Law affords a
                  remedy where the question of freedom does not arise.
                </p>
                <p className="p-in-sp">
                  This very distinction is taken by Lord Mansfield, in the famous Somerset case. He says, "contracts for the sale of a slave are good here; the sale is a matter to which the law properly and readily attaches, and will maintain the price according to the agreement, but here the person of the slave himself is immediately the object of enquiry which makes a very material difference."
                  (Howell's State Trials, vol. 20, p. 79.) <PageNumber num={761} /> In the celebrated argument of Hargrave in the same case, he denies the right of the master to coerce his slave expressly on the ground that the Common Law affords him no remedy. The Common Law remedies, he says, in questions of freedom, were only applicable to cases of villenage, admitted
                  of defences incompatible with African slavery, and had no application to that system of bondage. The villain was set free, unless it could be proved that his ancestors had been slaves from time immemorial, but we in this country hold our slaves by purchase and not by prescription. The controversy between Lord and villain, moreover, was carried on by means of certain ancient writs
                  long obsolete in England and never in force in this country.
                </p>
                <p className="p-in-sp">
                  This pretended recognition of our rights and remedies, therefore, is in fact an incorporation of Lord Mansfield's decision in our Constitution. A decision under which, says Judge McLean, "for near a century, the Courts of England have declared that the slave there is free from the coercion of his master." A decision under which, in the opinion of Judge Curtis, a master will "be
                  prevented from exercising any control over his slave."
                </p>
                <p className="p-in-sp">There is a bitter mockery about this pretended settlement of the Territorial question. We ask for bread, and they give us a- stone. There is the Sardonicus risus on the features of what I trust is now a corpse.</p>
                <p className="p-in-sp">The second section of the Peace Conference plan is much eulogized by the gentleman from Kanawha [<span className="persName">Mr. SUMMERS</span>] , because it confers upon the South equality of power in the acquisition of territory, instead of equality of partition in the territory when acquired, as provided in the Crittenden plan. In my judgment, Mr. Chairman, the proposed equality of power will be a fertile source of agitation, intrigue, bargain and corruption, and a most unreliable safeguard.</p>
                <p className="p-in-sp">
                  By the equal partition of territory, we should have gained at least one thing. We should have withdrawn the question from Congress, and escaped all further agitation on the subject. But this proposition, which requires the majorities of the Senators of each section to concur in the acquisition of territory, exposes us again to all the annoyances of controversy; and, much worse than
                  that, to the dangers of intrigue and infidelity. We have now but seven slave States, for Arkansas is trembling on the verge of secession and cannot be relied on. By this proposed partition of power, it will only be necessary to gain eight Southern Senators to admit all the Canadas; while we shall have to win twenty Northern Senators to acquire slave territory. All the Northern States
                  are equally interested against us, while four of the Southern States, sufficient to control us, have less than a third of the slave population, <PageNumber num={762} /> and are but little interested in our behalf. Of the fifteen hundred and fifty thousand slaves in the seven slave States, the States of Delaware, Maryland, Kentucky and Missouri have only
                  four hundred and twenty-eight thousand—less than twenty-eight per cent of the whole. The North will wield all the patronage of the government, and we shall be powerless. Have we not just cause to fear that as the white population of the four States before mentioned gains rapidly on the blacks, and their interest in the institution of slavery sinks almost to zero, they may yield to
                  temptation, and suffer the acquisition of free territory, without requiring an equal addition of slave territory. The odds against us in the struggle will be tremendous; it will be all the world to nothing. To such a partition of power as this plan proposes, I much prefer the partition of territory.
                </p>
                <p className="p-in-sp">In the third section again recurs that pernicious phrase "persons held to labor," which to me is an insurmountable objection wherever I find it, when I remember the past uses that have been made of it. Nor do I see particular merit in the provision so much approved of by the gentleman from Kanawha [<span className="persName">Mr. SUMMERS</span>] 
                  , restricting Congress from imposing a higher rate of taxation on "persons held to labor" than on land. We have thought heretofore that the tax on slaves being a capitation tax, could not be higher than that on white tithables, and it seems quite as well if not better to couple slaves with white polls than with land; for while it gives us as good a guarantee against unequal taxation,
                  it does not lay us open to the objection that if slaves are taxed as property, and direct taxation and representation go together they should not be represented as persons. I do not say that the argument is sound, but experience teaches us that our adversaries are satisfied with the specious and do not always need the solid.
                </p>
                <p className="p-in-sp">
                  The fifth section prohibits the "foreign slave trade," and thus interdicts exportation to foreign States as well as importation from them. When the Confederate States of the South are recognized as an independent power, as no doubt they soon will be, we shall be cut off by this provision from exporting our slaves, and we shall see them accumulate while our laboring whites will be
                  enticed from us by Homestead bills. For it must be borne in mind that this Homestead policy is a cardinal tenet of the Republican party, and so soon as a bill donating one hundred and sixty acres of the public land to each head of a family had been defeated in Congress by an almost unanimous Southern vote, the policy was incorporated in the Chicago platform. If we are prohibited from
                  sending our slaves South, and the Homestead policy prevail, we may see Virginia Africanized. The men of <PageNumber num={763} /> Anglo-Saxon and of Norman blood may be lured away, and the African admitted to full citizenship, may become eligible to the Presidential Chair itself. Shall we sanction this policy? Shall we permit the North under pretence of
                  cutting off the African slave trade, to say that the negro shall stay here, and the white man be induced to go hence?
                </p>
                <p className="p-in-sp">
                  In reference to the seventh section, Mr. Chairman, I desire to be informed why it is that the language of the Crittenden proposition, providing compensation for the master where the fugitive slave is rescued by force, has been changed? In the Peace Conference plan, such compensation is to be made only where the fugitive is rescued by "mobs or riotous assemblages." Now, when we
                  remember that these words have a technical meaning; that a mob certainly implies many persons, and that in some of the States it requires not less than twelve persons to constitute a "riotous assemblage," we must see that cases of forcible rescue, or intimidation, may occur without the agency of either "mob" or "riotous assemblages."
                </p>
                <p className="p-in-sp">If we are to pay one half the damages, we surely should be compensated in all cases of forcible rescue.</p>
                <p className="p-in-sp">
                  In this same section is found that addendum so abhorrent to our feelings, that even the gentleman from Kanawha gives it up and is willing to excise it. I mean the provision making it imperative on Congress "to provide by law for securing to the citizens of each State the privileges and immunities of the citizens of the several States." By virtue of this provision, our fugitive slaves
                  purchased under the same section by the General Government, made citizens in some Northern State, might return and stand as equals by the side of their masters. The gentleman from Kanawha has rather incautiously admitted that it was proposed in the Peace Conference by the radicals of the North, and submitted to by the moderate men, for fear of their constituents. His proposition,
                  therefore, to excise it, if carried out, may lead to the rejection of the whole plan by the Northern people, for this seems to be one of its popular features. We have still better evidence of its popularity at the North, in the fact that it was the only part of the Peace Congress plan noticed and recommended by President Lincoln in his Inaugural.
                </p>
                <p className="p-in-sp">I have endeavored, Mr. Chairman, in the discussion of this plan, to avoid the statement of objections already made, and, as the venerable gentleman from Charles City [<span className="persName">Mr. TYLER</span>] has examined it elaborately, I shall not detain the Committee with its further consideration.</p>
                <p className="p-in-sp">I shall now proceed to consider the subject in its other and more <PageNumber num={764} /> interesting aspect—as a commercial question. And I shall take leave to state the question in the following form : Will the material interests of Virginia be promoted by adhering to the North or by joining the Southern Confederacy?</p>
                <p className="p-in-sp">In view of the great agency exercised upon the production of a country by its mode of raising revenue, the first enquiry will naturally be concerning the revenue systems of the two Confederacies.</p>
                <p className="p-in-sp">
                  In the North the system is, and always will be, highly protective. In proof of which, observe the haste they made to adopt a tariff higher than any before known, so soon as they were relieved from the presence of some fourteen Southern Senators and thirty-odd Southern Representatives. In the South, as we see from their provisional Constitution, their provisional tariff and the
                  debates of their Congress, a tariff for revenue will be adopted, and not free trade as some fear, or affect to fear. It would be strange indeed for people who had never seen the face of a Federal tax-gatherer, suddenly to depart from the usages of a life-time and to resort to direct taxation ; but we shall have a better guarantee than that against free trade. The representative
                  population of the Border slave States is now 6,225,272, while that of the cotton States is only 4,511,197. That of Virginia, North Carolina, Tennessee and Kentucky is now 4,373,499, and soon will exceed that of all the cotton States. If Virginia goes to the Southern Confederacy I presume that the other border States will go also, not merely from the force of her example, but because
                  they are operated on by the same causes which impel her. I think, therefore, that free trade is a mere bug-bear which may be dismissed from all further consideration here, as it has been at the South, and that we may regard a revenue tariff, averaging not less than 15 or 20 per cent as the settled policy of the Southern Confederacy.
                </p>
                <p className="p-in-sp">Such being the case I shall now proceed to consider the staples of our agricultural and mining industry and to enquire in reference to each, where lies the market? where the competition? and how the revenue system affects the price?</p>
                <p className="p-in-sp">
                  Our principal agricultural productions are wheat, tobacco and live stock; our chief mining products are salt, iron and coal. In reference to these productions I have prepared a series of tables, embracing all the reliable information within my reach. They are taken mainly from the finance reports of the Secretary of the Treasury. The estimate of the seed and exportation of wheat are
                  from a number of DeBow's Review, published while <span className="persName">Mr. De</span>Bow was connected with the Census Bureau. Some few facts are from the Patent Office reports, and the statistics of <PageNumber num={765} /> the Richmond trade were obtained from Mr. Graeme, of the Richmond. "Whig"—a gentleman to whom the State is indebted for much
                  statistical information concerning her trade, and who derives it from the official records of the internal improvement companies, having their chief offices in this city. I think, therefore, that the tables which I now submit to the Convention are altogether reliable and are entitled to consideration.
                </p>
                <p className="p-in-sp">For the sake of clearness, I shall, at the risk of fatiguing the Convention, examine successively the staples of our industry, and consider in reference to each, the questions of the market, the competition and the effect of the revenue system upon prices.</p>
                <p className="p-in-sp">
                  WHEAT—The latest reliable statistics in reference to the production of wheat, are those of the census of 1850, since which time we have had nothing but estimates. From the table now submitted to the Convention, it appears that the wheat crop of 1849 was 100,485,944 bushels, and that, after deducting the seed and the foreign export, there remained for the home market 76,488,851
                  bushels. The census gives us the production of each State, and consequently, after deducting the seed and foreign export, the amount contributed by each State to supply the home market may be estimated. The consumption of each State is estimated to be in proportion to her white population. We are thus enabled to determine where the market lies and where the competition is to be
                  encountered. Virginia had a surplus, after deducting seed, foreign export and her own consumption, of 5,036,595 bushels. The North had a surplus, after similar deductions, of 3,049,141 bushels. The border slave States, exclusive of Virginia, were deficient 3,130,159 bushels, and the cotton States were deficient 4,955,577 bushels. It thus appears that the competition was at the North
                  and the market at the South. Let us now enquire what comparative effects may be expected from the high tariff of the North and the low tariff of the South upon the prices of wheat. And, in making this enquiry, I beg leave to assure the Convention that I shall not engage in the controversy between Free Trade and Protection, but rest my argument upon grounds conceded by the friends of
                  both systems.
                </p>
                <p className="p-in-sp">
                  The revenue system of the United States has been changed six times since the war of 1812. The tariff of 1816 was a continuation of war duties. That of 1824 was the first tariff professedly and effectually protective, and in which the principle of minimum valuations was introduced. That of 1828 was still more protective. That of 1832 abolished minimums and specific duties, imposed ad
                  valorem duties exclusively, reduced the rate of duty, and returned to the revenue <PageNumber num={766} /> standard. That of 1842 restored minimums and specific duties, raised the rates of duty and was highly protective. That of 1846 abolished minimums and specific duties, restored ad valorem duties, reduced the rate of duty and again approached the
                  revenue standard, and that of 1857 still further reduced the tariff of 1846. Thus the tariffs of 1828 and 1842, embracing a period of nine years, constituted a protective era; those of 1832 and 1846, embracing a period of nineteen years, gave us an era of comparative free trade. The table of prices in the American Almanac, extending back to 1821, gives us the prices during eight
                  years of comparatively moderate protection under the tariffs of 1816 and 1824, and under the tariff of 1857 the prices during three years of near approach to a revenue tariff. The result is, that during the period of moderate protection, the average price of superfine flour in New York was $6.191/4 per bbl., during the period of excessive protection it was $5.887/8 per bbl.; during
                  the nineteen years of comparative free trade it was $6.933A per bbl., and during three years under the tariff of 1857 it was $5.08 per bbl. The period of the last tariff seems to constitute an exception to the general law established by results under the other tariffs; but it must be remembered that throughout the entire period of its operation we have had severe commercial distress,
                  and therefore the exception is apparent only and not real.
                </p>
                <p className="p-in-sp">
                  In view of these facts it is astonishing that gentlemen on this floor, in maintaining that our material interests were connected with the North and had no connection with the South, have selected wheat as illustrating and proving their proposition. If we went further into this matter we should find that a very considerable portion of our crop is exported in the shape of flour to
                  foreign ports directly; that most of the remaining export goes through New York either abroad or to the Southern States; that, a very small portion of it is consumed at the North, and that even such portion, by releasing an equal amount of Northern flour for exportation does, in effect, find its ultimate consumption abroad or at the South. For, Mr. Chairman, no one will dispute the
                  proposition, that should New York be unable to export her own crop she would not take any portion of ours, and therefore it must be admitted that if she, with the proceeds of what she exports, purchases a part of our crop, our real customers are abroad and not in New York.
                </p>
                <p className="p-in-sp">I shall now pass to our next agricultural staple, requesting the Convention to bear in mind what has been said about the changes of our revenue system.</p>
                <p className="p-in-sp">
                  TOBACCO-The table shows that about seven tenths of the entire <PageNumber num={767} /> crop of 1849 was exported and that the prices of leaf tobacco have responded so accurately to the changes of the tariff, as to render it possible to compare the prices under each tariff. The striking facts appear, that under the tariff of 1816 leaf tobacco averaged
                  $71.27 per hogshead; under the higher tariff of 1824 it fell 18 cents per hogshead ; and averaged $71.09 ; under the still higher tariff of 1828 it fell $8.52, and averaged only $62.57; under the lower tariff of 1832 it rose $19.46, and averaged $82.03 ; under the higher tariff of 1842 it fell $30.09, and averaged $51.94; and under the lower tariff of 1846 it rose $17.46 per
                  hogshead, and averaged $69.40. If these changes of tariff and price stand not to each other in the relation of cause and effect, they present an accidental coincidence perfectly marvellous.
                </p>
                <p className="p-in-sp">
                  If asked where the competition was I should say that it was at the North, and that the home market was at the South. In 1849 the North produced in round numbers about seven millions of pounds, and the Cotton States about half million of pounds of tobacco. At present, as I am informed by gentlemen in the tobacco trade, and it has probably always been so, the manufactured tobacco
                  consumed at the North grows in the valley of the Connecticut, in the southern portions of the Northwest, and in Missouri. That consumed at the South is manufactured in the border slave States. The qualities of the two articles are entirely different, each being adapted to the taste of its own section, and finding little or no demand in the other section. The fine tobacco manufactured
                  south of James River, I am told, is exclusively consumed at the South, being merely shipped to New York, if shipped at all, as a point of distribution. The foreign demand for manufactured tobacco is by no means inconsiderable, and the best qualities of it sell for a guinea a pound in London, for smoking tobacco. It is estimated by gentlemen conversant with the trade, that Virginia
                  exports nine millions of dollars worth of manufactured tobacco per annum, of which the Southern States consume about six millions, and the remainder is exported to Europe. Richmond, alone, exported in 1858, manufactured tobacco worth $6,228,496. It is the leading export of the State, and any injury to this branch of our trade would be felt by the entire Commonwealth.
                </p>
                <p className="p-in-sp">
                  I fear much that a separation from the South and union with the North, will put the axe to the roots of this great interest, not only by bringing us under a revenue system which will cut us off from our customers, but by building up destructive competition in a quarter where we little expect it. The proposed tariff of the Confederate States admits leaf tobacco free, and imposes a
                  duty of ten per cent on the <PageNumber num={768} /> manufactured article. Already agents from those States have been purchasing leaf tobacco in this city and sending it South to be manufactured, in order to escape the duty. If this state of things continue it will transfer to the South the manufacture of all the tobacco consumed in the Southern market,
                  and strip us of our leading industrial interest.
                </p>
                <p className="p-in-sp">
                  LIVE STOCK-The third and last agricultural staple is live stock, the product chiefly of Western Virginia. Unfortunately I have been unable to procure statistics of this branch of industry. But I believe that the competition is at the North, and the market at the South in this, as in all the other productions of the State. I find from the last finance report of the Secretary of the
                  Treasury, that there was an export of live stock from the lake ports during the past years and comparatively none from the South. Thus, out of 95,509 hogs exported, 94,434 were sent from Detroit alone, and out of 32,513 horned cattle exported, 29,495 were shipped from the same port. As I have never heard that hogs or horned cattle were driven from the South to the North in any
                  considerable number, certainly not in numbers sufficient to constitute a considerable portion of Northern consumption, I conclude that the North, not only sup--)lies its own market, but has a surplus for exportation.
                </p>
                <p className="p-in-sp">I understand that the principal market of North-western Virginia is Baltimore. If so, we are secure of it under all circumstances, for the distinguished gentleman from Kanawha assures us that Maryland lies in our lap, and cannot separate from us, nor do I believe that she would do so if she could.</p>
                <p className="p-in-sp">
                  I have another reason for supposing that the market for live stock must be in the South. From the want of grass, they find it cheaper to buy the matured animal than to raise it; and thus the fact disclosed by the census, that there is a greater proportion of live stock at the South than at the North, only proves the magnitude of the purchases made by the cotton States from the border
                  slave States. The cotton States, moreover, are not only unable to raise live stock to advantage, but are incapacitated, by their climate, from curing meat, while, from the character of that climate, they are compelled to use it very extensively. Notwithstanding the fact, therefore, that the great packing points are at the North, the greater comparative consumption must be at the
                  South, and if Virginia live stock find their way to Cincinnati to be packed, they probably go thence, in the shape of salt meat, to a Southern market.
                </p>
                <p className="p-in-sp">
                  I come, now, to our mineral productions—salt, iron and coal. I shall subject them in part to the enquiry already pursued with reference to <PageNumber num={769} /> the products of agriculture. I am unable to say how the prices of these articles have been affected by our revenue system, but I think that the effect of the system upon our production of
                  iron and coal will be apparent.
                </p>
                <p className="p-in-sp">
                  SALT-I find, from the census of 1850, that the domestic production of salt for the preceding year was $2,222,745, of which amount $1,452,554 was produced at the North, $700,466 in Virginia, and $69,725 at the South. This clearly shows where the domestic competition lies. From the larger consumption of salt meat, and the. greater proportionate number of live stock at the South than at
                  the North, and from the further fact that a portion of our salt region is in Southwestern Virginia, in juxtaposition with the South and separated from the North, and that the remaining portion of the salt region is in the valley of the Kanawha, which possesses water communication with the South and Southwest, in the cheap form of untaxed navigable rivers, I infer that our market also
                  lies at the South. Nor would it at all affect this conclusion to show, if such be the fact, that most of the Kanawha salt goes to Cincinnati, unless it be also shown that Cincinnati is not a point for the distribution of salt or salt meat to the South. From the fact that Cincinnati is a packing point, and is contiguous to the South and Southwest, I should suppose that much of the
                  Kanawha salt consumed in the latter region would reach it through Cincinnati. If, therefore, this course of trade were broken up by a dissolution of the Union, the effect would only be to transfer the distributing point to Covington, or some other place South of the Ohio river.
                </p>
                <p className="p-in-sp">I may be told, however, that foreign salt competes with our own product to a greater extent than Northern salt, and that, under the revenue system of the South, foreign competition would be more destructive to our interests than the competition of the North.</p>
                <p className="p-in-sp">
                  The foreign salt consumed in the country is less than a third of the whole consumption, and is relatively diminishing. The table submitted to the Convention shows that in 1850 the salt consumed in the country was in value $3,450,263, of which amount $1,227,518 was imported. The same rate of consumption per head of the population, would give the consumption of 1860 at $4,379,086, of
                  which amount $1,431,140 was imported, leaving $2,947,946 as the domestic production. Our domestic product, therefore, increased $725,201 in ten years, while the importation increased in the same time only $203,622, thus showing a gain of $521,579 on the part of our own salt makers over their foreign competitors. And this gain, it must be borne in mind, is in the face of a reduction
                  of the duty on salt in 1857 from 20 to 15 per cent <PageNumber num={770} /> ad valorem. If this 15 per cent duty, or even a less rate of duty, were imposed on Northern as well as foreign salt, the result would be greater protection to our salt interest than it now enjoys. If, on the other hand, that interest be separated by a tariff, from the South, and
                  exposed to Northern competition without protection, it will inevitably occupy a worse condition than it now does. I am not able to say whether the very considerable development of domestic production has been participated in by Virginia, if not, it will be another illustration of what abundantly appears from the iron and coal trade. In this trade the benefits of the protective system
                  have fallen exclusively to the North, and have not been at all shared by Virginia, as I shall now proceed to show.
                </p>
                <p className="p-in-sp">
                  IRON-The table shows that under the high protection afforded by the tariff of 1842, amounting to 48.93 per cent, per ton, if reduced to an ad valorem duty, the average annual production of American pig iron was $12,094,151, and the average annual increase of production was $3,089,106, while the average annual importation was $5,603,796 and the average annual decrease of importation
                  was $154,886. The average price during this period was $28.19 per ton in New York.
                </p>
                <p className="p-in-sp">
                  From 1846 to 1857, under a duty of 30 per cent, the average annual production of American pig iron was $24,045,933, and the annual average increase was $1,839,606. The annual average importation was $18,376,163 and the annual average increase was $1,252,137. The average price in New York was $28.93 per ton. Thus, notwithstanding the revival of the foreign trade, consequent upon a
                  reduction of duty, the domestic production gained upon it during the ten years at an annual average rate of more than $600,000. Until the year 1853 the foreign trade gained upon the domestic production, but since that time the gain has been heavily the other way. And it must be also remarked that in the face of this immense development of production the prices were not only
                  maintained, but advanced. The Secretary's tables do not come lower than 1857.
                </p>
                <p className="p-in-sp">
                  Let us now enquire what share Virginia had in this prosperous condition of the iron interest, and what benefit protection conferred upon her while it was increasing Northern production at the rate of ten per cent per annum. Our only means of answering this question is by reference to the trade of Richmond, which may fairly be considered as representing that of the State.
                </p>
                <p className="p-in-sp">
                  The table shows an annual average decrease of 379 tons in the Virginia iron brought to Richmond since the 1st of July, 1857, and an annual average increase of 3,867 tons in the Northern iron brought <PageNumber num={771} /> to that city during the same period. Thus it appears that while the Northern iron masters maintained their supremacy over the
                  foreign importer and gained on him steadily, without, however, diminishing his trade, they were absolutely exterminating the production of iron in Virginia. And yet we have heard on this floor lamentations from gentlemen favoring union with the North, over the supposed losses of the Pennsylvania iron masters, although that union requires us to purchase its supposed advantages by
                  surrendering our miners and manufacturers to the overwhelming competition of their Northern adversaries.
                </p>
                <p className="p-in-sp">
                  COAL-The table shows the same state of things with regard to the coal trade. The gain of Northern production over foreign importation, and the gradual extinction of our own production. The anthracite coal sent eastward from Pennsylvania in 1846 was worth $13,337,314, while the foreign importation of both bituminous and anthracite coal in the whole country during that year was worth
                  only $336,691. In 1856 the Pennsylvania coal had trebled in value, having increased to $39,834,098, and increasing annually at an average rate of $2,649,738, while the importation of foreign coal was only $597,094, showing an annual average increase of but $26,040. While this enormous increase of Northern production was progressing, the coal trade of Virginia was steadily declining.
                  From the 1st of July, 1856, to the end of 1859 the amount of coal brought to Richmond from the interior diminished at an average annual rate of 2,307 tons, while the importation of Northern coal increased annually on an average 4,033 tons. The foreigner seems to be merely retarded by our protective system, while we of the South are undergoing gradual extinction. It would appear,
                  therefore, that we might well exchange this system for one of moderate protection against the North, even if the protection against foreign countries were diminished.
                </p>
                <p className="p-in-sp">
                  MANUFACTURES-The table of the productions of manufactures and the mechanic arts, shows that in 1850 there was a surplus at the North of $264,637,000, and a deficiency in the cotton growing States of $139,401,000. If we wish to become a manufacturing State, it will be an extraordinary policy for us to unite ourselves with a people who produce vastly more than they consume, and to
                  decline a union with States who will take everything we produce.
                </p>
                <p className="p-in-sp">
                  But even this statement of the case does not present the objections to such a policy in their full force. We have now large manufactories of iron which have their entire market at the South, through agencies established in New Orleans, and which do not sell one pound of their <PageNumber num={772} /> wares north of Virginia. If we be separated from the
                  Southern Confederacy, and the duty be imposed on manufactured iron, proposed in their provisional tariff, it will destroy our iron manufacturers. For while their Northern adversaries have an extensive home market to fall back upon, our manufacturers are dependent entirely on the Southern market. Their only course left will be to emigrate or to break up their establishments and turn
                  their capital to some other branch of industry. The magnitude of this interest may be conceived when I inform the Convention that the production of one establishment alone had risen to $100,000 per month before the present troubles began. Its owners fear that they may be compelled to follow their customers in the South, should this State adhere to the North.
                </p>
                <p className="p-in-sp">
                  I have thus reviewed, most tediously I fear, the course of trade in the principal articles of our industrial products, and, as I humbly conceive, have proved that the market for them is at the South or abroad; that the competition is at the North, that prices are better under the Southern than under the Northern revenue system ; and that what we require is moderate protection against
                  the North, and not excessive protection against foreign and none against Northern competition.
                </p>
                <p className="p-in-sp">I stated, however, that I should not enter into the controversy between free-trade and protection, and I shall now proceed to make good my word, by considering some objections which may possibly be urged by the friends of both systems.</p>
                <p className="p-in-sp">I shall probably be told by the free-trader that Virginia is an importer of manufactured goods, and, therefore, requires free-trade with the section which can best supply her deficiency, and that to separate her from the North will render this supply precarious and costly.</p>
                <p className="p-in-sp">
                  This is true, but it is only one half the truth. In order that we may import, we must export, and it is no advantage to have cheap goods offered to us unless we have the means to pay for them. While, therefore, by separation from the North, we impair our import trade; by separating from the South we impair our export trade, and as we are forced to sacrifice one or the other, it is
                  surely better to retain that which fills our pockets and protects our manufactures, than that which impoverishes us and depresses our industry. If we sell our exports well, and find obstacles to importation, we shall be in the best possible condition to develop our manufactures, to furnish a home market, and ultimately to give us the advantages of free trade, by placing the
                  agriculturalist, the miner and the manufacturer beside each other, with no hostile tariff to interrupt their exchanges. I must also remind the free trader, that while our importations from the North may be impaired, <PageNumber num={773} /> those from abroad will be facilitated. And, as the foreigner consumes our exports, he will be enabled to pay
                  better prices for them than he has done under the protective system heretofore in force against him.
                </p>
                <p className="p-in-sp">This consideration, however, brings me nearer to the other horn of the dilemma upon which the protectionist expects me to be transfixed.</p>
                <p className="p-in-sp">
                  He will say that, by reducing the duties on foreign imports, we shall expose our manufacturers to the destructive competition of foreign capital and pauper labor, and thereby prostrate them in their infancy. He forgets that, while we lower the duties on foreign imports, we impose duties on Northern production, and gain, in this way, vastly more protection than we lose by the
                  reduction of the duty on foreign manufactures. Protect us from the North and we have nothing to fear from abroad. Foreign competition may be hurtful to the North, where they have been stimulated by their unwise protective policy to undertake the manufacture of costly fabrics for which they are not prepared. But. to us, who wish only to make articles of prime necessity, generally
                  cheap and bulky, and who should not attempt to force our manufactures forward faster than they are ready to go, foreign competition has few terrors.
                </p>
                <p className="p-in-sp">
                  The freight, insurance and exchange upon cheap and bulky fabrics imported from abroad are in themselves no inconsiderable protection; a protection of not less than 15 per cent, while against the importation of such articles from the North, we find but little 'protection in those charges. By means of the telegraph and steamboat, orders are executed at the North as the demands of the
                  market require, and no accumulation of stock is necessary. While, in the foreign trade, from the slow communication between us and the importer, the market must be anticipated and large stocks of goods collected in bonded warehouses to meet the demands of wholesale dealers. This involves, not only a loss of interest, but necessarily confines the larger portion of the foreign trade to
                  such goods as will bear accumulation.
                </p>
                <p className="p-in-sp">Many of our articles of prime necessity, the very goods that we can and should manufacture ourselves, cannot be accumulated in bonded warehouse's. For instance, if a large stock of ready-made clothing were collected and held for future demand, it might become worthless from a change of fashion.</p>
                <p className="p-in-sp">In the manufacture of these things the North competes successfully with us, while the foreigner never can command our market.</p>
                <p className="p-in-sp">
                  An intelligent friend, who has had much experience in framing the revenue system of this city, informs me that, having occasion to <PageNumber num={774} /> estimate the amount of manufactured goods sold in Richmond, he found it to be in the aggregate of about twenty-five millions of dollars; that one-half of these goods were imported from the North, and
                  that the imports were chiefly such things as in his youth had been the product of household and neighborhood manufacture in Virginia. Every member on this floor recollects this state of things, when the spinning wheel and the loom were in every house, and the estimate was that a sheep would clothe each member of the family.
                </p>
                <p className="p-in-sp">This extensive branch of industry has been transferred to the North, where accumulated capital, machinery and skill enable them to make and bring into our market most of the articles formerly made at home. The extent of the transfer of our domestic manufactures may be estimated without difficulty.</p>
                <p className="p-in-sp">
                  In 1858 the ready-made clothing sold in Richmond amounted to $1,221,000; of which $762,000 was imported from the North—more than two-thirds of the whole amount. The amount of shoes sold was $1,151,350; of which $898,350—nearly four-fifths—was imported from the North. The sales of hats amounted to $315,000; of which $250,000 —about four-fifths—was imported from the North.
                </p>
                <p className="p-in-sp">Thus in these necessary manufactures, formerly made at home, and which, with a very slight protection, could be again made at home, we find Northern competition nearly exterminating our domestic production, while from the foreigner we should have nothing to fear.</p>
                <p className="p-in-sp">
                  It was estimated by a Convention held in Boston in 1824, that seventy-five per cent of the entire manufacturing product of the country was domestic. At the present time domestic manufactures at the South have almost ceased, and are replaced by Northern goods. We may thus form some idea of the weight of Northern competition, and the immense transfer of industrial pursuit from South to
                  North which it has effected in the last thirty or forty years. We can have nothing like this to fear from abroad, for these domestic products will not bear long transportation or large accumulation of stocks, and we are well prepared to manufacture them ourselves.
                </p>
                <p className="p-in-sp">
                  Mr. Chairman, it is in vain that we struggle to make ourselves a manufacturing State, while we have adversaries so powerful, so sagacious, and with such easy access to our markets, as the manufacturers of the Northern States. They fully appreciate the value of the Southern market, they know us better than we know ourselves, they have sounded all the depths and shoals of Southern
                  trade and they never will permit manufacturing industry to raise its head at the South so long as they have the power to suppress it. <PageNumber num={775} /> Their mode of operation is familiar enough to us in Richmond. So soon as a branch of manufactures is attempted here, it immediately draws upon itself the most powerful and concentrated competition
                  from the North. A few years ago a glass factory went into operation in this city, and began to sell profitably wares equally good and quite as cheap as those imported from the North. Immediately the market was flooded with Northern glass ware at reduced prices. The Virginia manufacturer not having the capital to endure long continued competition succumbed, and in the course of time
                  glass resumed its former price.
                </p>
                <p className="p-in-sp">
                  By means of their vast accumulations of capital, Northern manufactures can bear heavy losses, expecting in the end to regain them with interest. And, sir, they are sagacious enough and bold enough not to fear losses when contending for such a prize as the Southern market. But we are weak as yet, and require success at the start, in order to accumulate the capital necessary for
                  successful competition.
                </p>
                <p className="p-in-sp">
                  I am asked to explain how it is that our protective system has dwarfed Southern enterprise while it has developed so enormously the manufactures of the North. If the fact be so, and I think that it is established beyond controversy by the tables I have submitted to the Convention, it matters but little whether I can explain it successfully or not. I am willing, however, to attempt
                  the explanation, admonishing the Convention that should it prove unsatisfactory the force of the fact itself is in no wise impaired.
                </p>
                <p className="p-in-sp">
                  The protective system found the two sections very differently prepared to reap its benefits. The North had already started in a career of manufacturing and commercial industry and were impelled onwards by protection with an accelerated velocity, while the South was agricultural, and only manufactured by hand and in a simple way some of the products of agriculture. She, therefore, was
                  speedily overwhelmed by the capital, machinery and skill of her neighbor. If you ask how the North obtained the start, the answer would probably differ as it might be given North or South of Mason and Dixon's line.
                </p>
                <p className="p-in-sp">North of that line you would be informed that it was owing to the superior intelligence and energy of the North, and to her exemption from the blight of slavery. As this answer may not be altogether satisfactory to Virginians, I shall attempt another.</p>
                <p className="p-in-sp">
                  The settlers of the Northern States found a rigorous climate and a poor soil, which repelled agricultural pursuit, while off their coast were fisheries, making the sea infinitely more profitable than the land. They were thus driven to the ocean, and, of necessity, became commercial. <PageNumber num={776} /> The configuration of their country was such
                  that large cities necessarily grew up. From the Chesapeake Bay to Maine, there were but three navigable rivers of any magnitude, the Susquehanna, the Delaware and the Hudson. Trade therefore was forced to converge on three points, and Baltimore, Philadelphia and New York came into existence. There was another position, with an excellent harbor, far to the eastward, on the highway to
                  Europe, convenient to the fisheries, and removed from the competition of the other cities. Here Boston sprang up.
                </p>
                <p className="p-in-sp">
                  With commerce came accumulations of capital, and cities perfected the mechanic arts, thus furnishing the necessary conditions for the development of manufactures, and that branch of industry followed as a matter of course. Another fact should be mentioned, which in the opinion of high maritime authority exercised no inconsiderable influence upon Northern commerce. Just before the
                  revolution Dr. Franklin ascertained the existence of the Gulf stream, but concealed it for political reasons until the war was over. It became generally known about the time that the present Government went into operation. and the result was that the passage to the Northern ports from Europe was shortened from eight to four weeks, while to the Southern ports it remained as before.
                  About this time the commerce of Charleston, the Emporium of the South, began to decline and that of the Northern ports to increase rapidly. This caused the receipts and disbursements of the Federal Revenue to be principally at the North and still farther stimulated Northern industry.
                </p>
                <p className="p-in-sp">
                  The early settlers of Virginia, on the other hand, found a rich soil capable of producing valuable staples, a mild climate, and a country so cut up with navigable rivers that it was impossible to converge trade on any single point. Ships went to each plantation to receive its crop, and, although the House of Burgesses made many towns on paper, none of them existed in fact. Thus the
                  State was necessarily agricultural, and without cities. Capital, if it accumulated at all, was invested in agricultural pursuits, and the mechanic arts did not flourish. While we were tilling the soil, the North was growing great in commerce and manufactures. Thus they got the start of us, and it must be confessed that they have made good use of it.
                </p>
                <p className="p-in-sp">It would be an imperfect view, however, to confine ourselves to a consideration of the mere staples of our industry, without considering the relative effects of a union with the North or a junction with the South upon our system of labor, the basis of our industrial fabric.</p>
                <p className="p-in-sp">
                  That system is mixed, partly slave and partly free, and is admirably <PageNumber num={777} /> adapted to our soil, our climate and our physical geography in general. We have pontine districts highly productive, but unhealthy for the white man, where the negro is impervious to the local diseases and cultivates the soil with impunity. We have the
                  manipulations of agriculture, and some of the coarser processes of mining, where the small intelligence and power of endurance of the negro, come into play, but where the white man would be misplaced, and would be withdrawn from occupations more suited to his superior intellect. We have the higher processes of mining, manufactures, navigation and the mechanic arts, for our white
                  laborers, in which they may do themselves and their race justice, and quicken their intellect, while they develop the wealth and power of the State. This mixed system of labor, if left to the law of supply and demand, works not only without conflict, but in harmonious conjunction. The slave produces food for the white laborer and raw material for him to manufacture and transport,
                  while the white laborer furnishes a market for slave products and imparts to them additional value.
                </p>
                <p className="p-in-sp">It is not true that there is an irrepressible conflict between white and slave labor. That notion is the coinage of men who deserve to be degraded from the rank of statesmen for their sacrifice of truth to the base purposes of sectional strife. The real conflict is between the white laborer of the North and the white laborer of the South.</p>
                <p className="p-in-sp">
                  It is not the negro laboring at his humble vocation in the cotton and tobacco fields of the South, or deep under ground in the coal shaft, or in menial employments. who makes himself felt by the white man as his competitor. The true competitors of our laboring whites are the gigantic manufacturing corporations of the North which flood our market with everything that white labor can
                  produce. When our manufacturers are discharging their operatives, or working on half time, it is not because we have negroes making wheat or tobacco, mining coal, and occasionally cobbling a shoe or doing a rough job of work as carpenters or blacksmiths; but because the Northern manufacturer, by his capital, machinery, and skill, is enabled to capture our market.
                </p>
                <p className="p-in-sp">
                  Where, Mr. Chairman, will our system of labor best preserve its equilibrium, and be allowed to develope itself under the law of supply and demand? Will it be in the South, where each kind of labor is not only left free from legislative interference, but receives equal protection; or at the North which systematically wars upon slave labor and entices free labor to emigrate by offers
                  of free farms in the West?
                </p>
                <p className="p-in-sp">
                  Where shall we meet the most competition for our white labor, North or South? In the Eastern part of the State where slave labor <PageNumber num={778} /> preponderates, we do not feel Northern competition to such an extent as in Western Virginia. In that region, possessing as it does, great capacities for mining and manufacturing, where the climate is
                  healthy, the people energetic and intelligent, the earth filled with minerals and the country abounding with water power, we find the population restricted in the main to agriculture, and to an agriculture not very productive.
                </p>
                <p className="p-in-sp">
                  Why is this? Why are the unrivalled advantages of this fine region unavailing, the industry of this most intelligent and active people dwarfed and undeveloped? It is because their labor is exposed to the overwhelming competition of the North; because their iron and coal are driven out of their own market by the iron and coal of the North ; and because they cannot accumulate the
                  capital necessary to wage this most unequal war.
                </p>
                <p className="p-in-sp">
                  Let them go with us into a Southern Confederacy, and receive protection from Northern industry, and they will be what they ought to be—the manufacturers and miners of a great nation. Their country will resound with the hum of industry, and their mountain streams, instead of purling idly over their stony beds, will make their glens musical with the clatter of machinery.
                </p>
                <p className="p-in-sp">
                  Let not Western Virginia suppose that she has no interest in the slavery question, because she owns but few slaves. She has a vast interest in maintaining the equilibrium of our system of labor, the overturn of which will bring woes unnumbered upon herself. It is not merely the loss of taxable property that she would feel, but a far greater evil in the entire subversion of our
                  industrial pursuits, the withdrawal of labor not in competition with her own, and the introduction in its place of swarms of hungry and desperate competitors. What she needs is capital, not competition, and if the East flourishes, its capital will seek investments in the West.
                </p>
                <p className="p-in-sp">
                  We are now crushed by Northern competition, cut off by high tariff from Europe, and the Southern States our only customers, and with manufactures springing into existence in the Southern Confederacy under the protection of their revenue tariff. We are between the upper and the nether mill stone, and I verily believe we shall be ground to powder. We must bear in mind that we are not
                  assembled to consider whether we will remain as we were, but whether we shall rest in the new and perilous position in which we now find ourselves, menaced by political overthrow and commercial ruin.
                </p>
                <p className="p-in-sp">
                  Three courses are proposed to us by the Committee on Federal Relations. First, that we shall demand guarantees (most insufficient in <PageNumber num={779} /> my opinion) , and give the North their own time to answer. Second, that we shall demand much more satisfactory guarantees, and fix a time for the response; and, third, that we shall make no further
                  demands, but shall dissolve our connection with the North.
                </p>
                <p className="p-in-sp">My objections to the first and second plans proposed are very great, to the first they are insurmountable.</p>
                <p className="p-in-sp">
                  I believe that no application for adequate guarantees to the Northern people will be successful. It is an appeal to the constituent body from the public functionaries who have denied such guarantees. It is, in effect, to stigmatize the leading public men of the North, to charge them with misrepresenting their people, and to challenge them to a trial of the charge before that people
                  as judges. We, with no means of access to the Northern people, with all their prejudices, sectional, social and political against us, expect to prevail over their tried and trusted leaders, connected with them by ties of blood, of party, of social intercourse, sharing their sympathies and prejudices, wielding the power of State and Federal Government, and forced by our charges into a
                  position where victory or political death are the only alternatives. To expect success in such a contest, seems to me chimerical in the last degree.
                </p>
                <p className="p-in-sp">
                  But, this plan not only proposes to take us again, cap in hand, through the weary round of supplication, but fixes no limit to this disastrous if not degrading experiment. How long shall it last? Is it to be one, two, or three years, as Mr. Seward suggests? Are we to stand for an indefinite period, with our industry paralyzed, our people feverish and impatient, our manufacturers
                  ready to emigrate in search of their customers? Such delay will be ruinous, it will bankrupt our mercantile community. Already they have endured agonies and have shown extraordinary powers of endurance. But these powers ought not to be taxed beyond all reason.
                </p>
                <p className="p-in-sp">
                  And while we are thus dallying by the wayside the imperial throne of Southern commerce, now vacant for our occupancy, will be seized by some more enterprising rival, and, when at the end of our unwise delay, we find ourselves compelled to quit the North, and turn with a people exhausted and impoverished by the dreadful process to which they have been subjected, to grasp the
                  advantages of the Southern trade, we shall find it occupied in all its branches and be forced to contend with rivals where we could have entered as friends. But suppose we obtain a favorable answer from the North, and the South, in the meantime having consolidated its Confederacy and resting in quiet from the assaults of abolition, decline to live again under Northern
                  <PageNumber num={780} /> rule—what then will be our situation—separated from our friends, connected with our enemies, cut off from our customers, delivered over to our competitors and forced to compensate ourselves for this vast injury with proper guarantees of our rights to be interpreted by those from whom they have been extorted?
                </p>
                <p className="p-in-sp">Mr. Chairman, far better will it be for us to risk a rupture with the North than separation from the South. One will not injure us permanently—the other will undo us quite.</p>
                <p className="p-in-sp">
                  If, however, we are to make another application to the North, let us at least limit the time for the response. Let our people see the end of this state of suspense. Let the enterprising company which has just inaugurated at a heavy expense, direct trade between this port and Liverpool, know whether their ships shall load their return cargoes, under a prohibitory or under a revenue
                  tariff ; whether the return trade is to be annihilated or fostered. Give our people reason to expect a speedy end to this middle state between the North and South, and they can shape their business accordingly; keep them in suspense and it will ere long shape itself.
                </p>
                <p className="p-in-sp">In my judgment, the safest, the best and the most honorable course, is to abrogate the existing Union, so far as we are concerned, and to unite ourselves with the Confederate States of the South.</p>
                <p className="p-in-sp">Our right to do so need not be proved after the lucid and statesmanlike exposition of our Federal system, by the chairman of the Committee on Federal Relations. Nor do I wish to detain the Convention by an enumeration of the just grounds for separation from the North; that has been done so frequently as to require no repetition.</p>
                <p className="p-in-sp">
                  One grievance, however, has not yet received the attention its importance demands. The greatest of all wrongs, one which in my judgment would require separation from the North if they had never otherwise injured us, is the translation of anti-slaveryism to power, the change from passive sentiment to energetic action. While the anti-slavery sentiment was merely speculative we had no
                  right to complain; but now that it has become an efficient agent in the government, it is no longer safe for a slave State to remain under that government.
                </p>
                <p className="p-in-sp">
                  Take the history of abolitionized Governments and it is a history of abolitionized people. Look at England, France, Denmark, and at their magnificent Colonies; the pearls of the Antilles, sacrificed without remorse. Look at Russia. Abolition mounts the throne and serfdom disappears. What right have we to expect better things from our Government? Will the Constitution restrain it.
                  Abolition will soon have the power to make that what it pleases, and as the gentleman from <PageNumber num={781} /> Augusta
                 [<span className="persName">Mr. STUART</span>] 
                  , well said, in his excellent report on the Harper's Ferry raid, "The whole argument against the extension of slavery is soon by a very slight deflection, made to bear against the existence of slavery, and thus the anti-extension idea is merged in that of abolition. Accordingly we find, notwithstanding the denial by the Republican party of any purpose to interfere with slavery where
                  it exists, that the tendency of its policy is to its extermination every where."
                </p>
                <p className="p-in-sp">
                  This disposes most satisfactorily of the denial of our Chief Magistrate in his inaugural (pleasantly characterised by Senator Wilson, of Massachusetts, as "a mild and genial instrument") 5 of any intention to interfere with slavery in the States. This distinguished chief, the head of the army and navy, whose rapid and decisive advance upon Washington frustrated the numerous bands of
                  conspirators along his route, put to shame the fumbling of the Lieutenant General in the cellars of the Capitol, in search of gunpowder plots, and gave us one of the most remarkable military evolutions of our day. This modern Mahomet, who, like his prototype of old, commenced his reign with a flight, differing only from the Hegira of the Prophet in the fact that it was to and not
                  from his future Capital, and that while the Arabian led his followers to battle with his green turban displayed as a banner, the American marshals his adherents under a Scotch cap. This occupant of Washington's seat has informed us that, in his opinion deliberately expressed, written out and published as a campaign document, "he who would be no slave must consent to have no slave.
                  Those who deny freedom to others, deserve it not for themselves; and, under a just God, cannot long retain it."
                </p>
                <p className="p-in-sp">
                  Here, then, is the policy of our government. Here the sentiment of a President whose friends recommended him to the electors of the Northern States by publishing and circulating that sentiment. We know what to expect : and I ask, whether this be a fit government for a slave State? With such views held by the chief Executive, the dispenser of honors and fortune, must we wait for an
                  overt act—must we stand until the bayonet is at our throats?
                </p>
                <p className="p-in-sp">
                  But we are told that we should give the Northern people time to change—that they will come right after a while. Sir, they are much more likely to make us wrong than we are to bring them right. Their anti-slavery sentiment is old as slavery itself—it is backed by Christendom—it is taught in their schools, their pulpits, their press, and pervades their literature—it is crowned with the
                  laurels of repeated triumphs—it has all the signs of a great mental movement. The opposite sentiment with us is recent—it is comparatively a thing of yesterday—it has <PageNumber num={782} /> not been inculcated in early life—it is not backed by sympathy abroad
                </p>
                <p className="p-in-sp">
                  it has hardly yet had time to be understood and appreciated by our own people. To dash it now against the iron-bound fanaticism of the North, would be the height of folly; at a future day it may be otherwise. The most friendly portion of the Northern people look on slavery as an unmitigated evil, and would reject any defence of it as an assault upon religion and morality. They are
                  willing to tolerate it if kept out of sight. They will not seek it; but toleration of what they consider a nuisance, is all we can expect from them. There are no signs of permanent re-action at the North—we must not take every refluent wave for a change in the direction of the current. In the language of the Tribune, it is rather "the crouch before the spring."
                </p>
                <p className="p-in-sp">
                  Abolitionism has been sown on a most fertile soil. The North and the South were originally different and have diverged from each other farther still. When these States were settled the mother country was rent with political, religious, and to some extent, social strife. Royalist struggled with Republican, Episcopalian with Puritan, Town with Country, the military spirit of the middle
                  ages with the newly-born spirit of commerce. The Puritan migrated to Massachusetts and the Cavalier to Virginia, and having a boundless continent to expand in, they developed their difference without restraint. One became Commercial, the other Agricultural, one held slaves the other comparatively none ; their climates differed, their pursuits differed and they themselves became
                  unlike in every thing except their common tongue.
                </p>
                <p className="p-in-sp">I speak not of these differences as matters of reproach, but simply as historical facts. In my opinion the Puritan was, in many things a better man than the Cavalier. Be that as it may he differed from him in sentiments, manners, and pursuits.</p>
                <p className="p-in-sp">
                  Twenty years before the Revolution, Dr. Franklin pronounced it impossible to unite the Colonies. Common danger forced them together, and common weakness for a time made the Union practicable. But that period passed away, the necessity for union, growing out of the exigencies of the times, has ceased. Abolition came and widened the differences until the Common Government has become a
                  mere anarchy, a nuisance, a reproach to civilized man. The Congress of 1859 and 1860 saw its members more than once arrayed against each other for personal conflict.
                </p>
                <p className="p-in-sp">
                  This state of things is produced by the vain effort to bind two nations together who struggle and chafe against their bonds. They are as different as Frenchmen from Englishmen; their social life is different; nothing could be more unlike the home of a Southern Planter, the <PageNumber num={783} /> manners, sentiments and habits of thought of his family,
                  than those of his social peer at the North.
                </p>
                <p className="p-in-sp">The Union has answered its purpose—it protected us when weak. Now, that we need its protection no longer, and that our differences have rendered us unfit companions, it is forcing nature longer to retain it.</p>
                <p className="p-in-sp">
                  Let us go, but to no Border State Confederacy. That is a commercial absurdity, and politically objectionable. The border slave States have the same products, are competitors, present no market for each other's productions, and would have no inter-State trade, no union except a political one. A majority of those States have a small and a decreasing interest in the institution of
                  slavery, and are not such protectors as we should select for that institution, in preference to other States having a greater stake in it.
                </p>
                <p className="p-in-sp">
                  Let us go to the Southern Confederacy—with the border States, if they will; if not, without them. If we encounter the evils of disunion, let us reap its advantages. We are told that North Carolina will intervene and cut us off. If she be a lion in our path, what are we in hers? If we cannot get South, neither can she get North, and our powers of endurance are as great as hers. But
                  she will go. The same causes impelling us operate as strongly upon her. If we go first, our example will influence her; if she go first, her example will unquestionably influence us. We shall soon come together, if we momentarily separate, and find ourselves in a Union where we may rest from the discord so destructive of our peace and prosperity in the existing Confederacy.
                </p>
                <p className="p-in-sp">We are told that it will bring war. On the contrary, it will tend to avert war. Virginia, united with the Southern Confederacy, will present small inducement for war upon that Confederacy. If we stay where we are, and war ensues, we must either fight our friends or fight our own Government. Neutrality is impossible and would be dishonorable.</p>
                <p className="p-in-sp">View the questions before us as you will, and a withdrawal from the present Union seems to be the safest and best course for us to pursue. Let us not hesitate to take it and to submit our action to the people for their approbation.</p>
                <p className="p-in-sp">I thank the Convention for the patient attention with which they have done me the honor to listen to my remarks, and only regret that they are not better rewarded for their trouble.</p>
                <PageNumber num={784} />
                <div className="stage it">[No. 1.]</div>
                <p>A Table showing that the home market for Wheat is in the South.</p>
                <p>Wheat crop of 1850 100,485,944 bushels.</p>
                <p>Seed 10,048,594</p>
                <p>90,437,350</p>
                <p>Exported 13,948,499</p>
                <p>Consumed in United States 76,488,851</p>
                <p>Free States consumed 52,618,278</p>
                <p>Free States produced 55,667,419</p>
                <p>Surplus 3,049,141</p>
                <p>Virginia consumed 3,495,540</p>
                <p>Virginia produced 8,532,155</p>
                <p>Surplus 5,036,595</p>
                <p>Grain-growing slave States, except Virginia, consumed</p>
                <p>12,008,749</p>
                <p>Produced 8,878,590</p>
                <p>Deficiency 3,130,159</p>
                <p>Cotton States consumed 8,336,284</p>
                <p>Produced 3,380,707</p>
                <p>Deficiency 4,955,577</p>
                <p>The consumption is estimated to be in proportion to the white population of each section, and the production is the crop of the several sections, less the seed and the quantity exported. [No. 2.] A Table showing the prices of Superfine Flour in New York, under the comparatively high tariffs of 1828 and 1842, and under the comparatively low tariffs of 1833 and 1846.</p>
                <p>Average price of superfine flour in New York in the years 1829,</p>
                <p>'30, '31, '32, '33, and 1843, '44, '45, '46, under high tariff $5.88 .79 <PageNumber num={785} /> Average price of superfine flour in New York in the years 1834, '35, '36, '37, '38, '39, '40, '41, '42, and in 1847, '48, '49, '50, '51, '52, '53, '54, '55, '56, under low tariff $6.93 3/4</p>
                <p>
                  For eight years prior to the tariff of 1828, and under the low tariffs of 1816 and 1824, the average price was $6.191/4. For three years, under the low tariff of 1857, it was $5.08, but it must be remembered that there were two revulsions of trade during this period. The year in which each tariff act was passed is included in the period of the preceding tariff, thus allowing time for
                  the effect of each act to be felt. The prices were taken from the American Almanac of 1861, which does not give them prior to 1821.
                 [No. 3.] A table showing the Market for Tobacco to be chiefly abroad and that the price to some extent is dependant on the Revenue system:</p>
                <p>Tobacco crop of 1850 199,732,655 lbs.</p>
                <p>cc Exported 139,806,858 "</p>
                <p>Consumed in the U. S. 59,945,767</p>
                <p>Prices of Tobacco per hogshead under successive Tariff Acts :</p>
                <p>Average price from 1821 to 1824 inclusive. Duties from 71/2 to</p>
                <p>30 per cent. ad valorem $71.27</p>
                <p>Average price from 1824 to 1828 inclusive. Duties from 12 to 50 per cent. ad valorem and minimums. Tobacco fell 18 cents per hogshead 71.09</p>
                <p>Average price from 1828 to 1833 inclusive. Duties 20 to 50 per cent. ad valorem, minimums greatly extended. Tobacco fell $8.52 per hogshead 62.57</p>
                <p>Average price from 1833 to 1842 inclusive. Duties falling every two years, under compromise act and minimums abolished. Tobacco rose $19.46 per hogshead 82.03</p>
                <p>Average price from 1842 to 1846 inclusive. Duties from 1 to 50 per cent. ad valorem, minimums restored. Tobacco fell $30.09 per hogshead 51.94</p>
                <p>Average price from 1846 to 1854 inclusive. Duties 5 to 100 per cent. ad valorem, minimums abolished. Tobacco rose $17.46</p>
                <p>per hogshead $69.40</p>
                <p>The table ends with 1854, because the Report of the Secretary of the</p>
                <p>Treasure does not furnish the means of ascertaining the price per</p>
                <p>hogshead after that year. <PageNumber num={786} /> [No. 4.] A table showing the relative value of the markets afforded by the Northern States, and by the Cotton States for the productions of Manufactures, Mining and the Mechanic Arts:</p>
                <p>Whole product in 1850 $1,013,000,000</p>
                <p>Exported about 13,000,000</p>
                <p>Consumed in the U. S. $1,000,000,000</p>
                <p>The Northern States produced $ 844,037,000</p>
                <p>consumed 579,400,000</p>
                <p>Surplus $ 264,637,000</p>
                <p>The Cotton States produced $ 31,099,000</p>
                <p>consumed 170,500,000</p>
                <p>Deficiency $ 139,401,000</p>
                <p>The consumption is estimated to be in proportion to the population of the two sections. The production is taken from the compendium of the census of 1850. [No. 5.] A table showing the market for the Salt of the State of Virginia to be in the South, and the competition to be chiefly in the North:</p>
                <p>Salt produced in the Northern States in 1850 $1,452,554</p>
                <p>In Virginia 700,466</p>
                <p>In the Southern States 69,725</p>
                <p>Domestic production $2,222,745</p>
                <p>Foreign salt consumed 1,227,518</p>
                <p>Total consumption $3,450,263</p>
                <p>Salt produced in the United States in 1860 (estimated) $2,947,946</p>
                <p>Foreign salt consumed 1,431,140</p>
                <p>Total consumption $4,379,086</p>
                <p>Increase of Domestic production $ 725,201</p>
                <p>Foreign importation 203,622</p>
                <p>
                  Gain of Domestic production $ 521,579 <PageNumber num={787} /> The consumption of salt having been ascertained to be 14 cents per head of the population of 1850, the consumption of 1860 is estimated to be at the same rate, and the import being deducted, the home production is thus ascertained. This production has gained on the Foreign importation
                  although the duty on salt was reduced, in 1857, from 20 to 15 per cent. ad valorem. The production of Virginia in 1860 is not known.
                 [No. 6.] A table showing the gain in the home market of American pig iron over imported pig iron:</p>
                <p>From 1842 to 1846, the duty being 48.93 per cent. ad valorem.</p>
                <p>Annual average value of production of American iron $12,094,151 00</p>
                <p>Annual average increase of production 3,089,106 00</p>
                <p>Annual average importation of Foreign iron 5,603,796 00</p>
                <p>Annual average decrease of Foreign iron 154,886 00</p>
                <p>Average price of pig iron in New York 28 19</p>
                <p>From 1846 to 1857, the duty being 30 per cent. ad valorem. Annual average value of production of American pig</p>
                <p>iron $24,045,933 00</p>
                <p>Annual increase 1,839,606 00</p>
                <p>Annual importation of Foreign iron 18,376,163 00</p>
                <p>Annual increase 1,252,137 00</p>
                <p>Average price of pig iron per ton in New York 28 93</p>
                <p>From 1846 to 1853 the foreign importation gained on the domestic production ; after that time the gain was reversed. And it will be observed that the increase was not only in quantity but also in price. The Secretary of the Treasury's report extends only to 1857. [No. 7.] A table showing the gain in the home market of American coal over Foreign coal, and the preponderance of the former:</p>
                <p>Anthracite coal sent Eastward in Pennsylvania in 1846 13,337,314 00</p>
                <p>Anthracite coal sent Eastward in Pennsylvania in 1856 39,834,098 00</p>
                <p>Increase in ten years 26,497,384 00</p>
                <p>Average annual increase 2,649,738 00 <PageNumber num={788} /> Anthracite and Bituminous coal imported in 1846 336,691 00</p>
                <p>Anthracite and Bituminous coal imported in 1856 597,094 00</p>
                <p>Increase in ten years 260,403 00</p>
                <p>Average annual increase 26,040 00</p>
                <p>Bituminous coal produced in the United States in 1856:</p>
                <p>In the Northern States 3,912,000 tons.</p>
                <p>Southern States, except Virginia 1,060,000 tons.</p>
                <p>Virginia 175,000 tons.</p>
                <p>Pennsylvania produced in the same year Anthracite coal</p>
                <p>amounting to 7,082,000 tons. [No. 8] A table showing that Virginia has not shared in the increased production of iron and coal manifested by the foregoing tables:</p>
                <p>Pig Iron brought to Richmond from the Interior.</p>
                <p>During the last six months of 1856 3,200 tons</p>
                <p>the year 1857 5,855 "</p>
                <p>cc</p>
                <p>1858 6,009 "</p>
                <p>cc</p>
                <p>cc</p>
                <p>1859 4,242 "</p>
                <p>cc</p>
                <p>cc</p>
                <p>1860 4,575 "</p>
                <p>cc</p>
                <p>Pig and scrap Iron imported from the Northern States coastwise into</p>
                <p>Richmond.</p>
                <p>In the year 1857 2,732 tons</p>
                <p>In the year 1858 4,372 tons</p>
                <p>In the year 1859 6,051 tons</p>
                <p>In the year 1860 13,333 tons</p>
                <p>Coal brought to Richmond from the Interior.</p>
                <p>During the last six months of 1856 36,696 tons</p>
                <p>During the year 1857 82,471 tons</p>
                <p>During the year 1858 58,927 tons</p>
                <p>During the year 1859 63,571 tons</p>
                <p>Coal imported from the Northern States coastwise into Virginia.</p>
                <p>During the year 1856 13,764 tons</p>
                <p>During the year 1857 18,887 tons</p>
                <p>During the year 1858 20,588 tons</p>
                <p>During the year 1859 23,049 tons</p>
                <p>During the year 1860 29,898 tons</p>
                <p>CC <PageNumber num={789} /> Average annual decrease in the production of Virginia iron 379 tons Average annual increase in the importation of Northern iron 3,867 tons Annual average decrease in the production of Virginia coal 2,907 tons Annual average increase in the importation of Northern coal 4,033 tons</p>
                <div className="speaker" id="sp743"><span className="persName">Mr. MORTON</span>, [Mr. RANDOLPH going away]—</div>
                <p className="p-in-sp">I move, Mr. President, that the Committee now rise. The gentleman is in feeble health, and he is doing so much justice to the subject that I take it for granted it will be the pleasure of the Committee to rise.</p>
                <p>The motion was agreed to.</p>
                <p>The Committee rose and reported progress.</p>
              </div>
              <div className="section" id="vsc1965.v1.2.27.5">
                <h3><span className="headingNumber">1.27.5. </span><span className="head">THE QUESTION OF TAXATION</span></h3>
                <div className="speaker" id="sp744"><span className="persName">Mr. WILLEY</span>, of Monongalia—</div>
                <p className="p-in-sp">I hold in my hand some resolutions which I propose to offer for the consideration of the Convention. Before doing so, I would ask the indulgence of the Convention for a few moments, while I make some explanation in regard to them.</p>
                <p className="p-in-sp">
                  We have now been earnestly engaged for more than a month in the great work of inaugurating a national conciliation. We have been, sir, earnestly engaged in inaugurating an effort to obtain for Virginia a recognition of her proper equality as one of the States of this Confederacy, and of procuring for her the guarantees necessary for her protection as one of the members of this
                  Confederacy; and I rejoice, while I myself feel, that the time is not distant when this consummation so devoutly to be wished for, will be obtained. I think that nothing is wanting in order to accomplish this great object, but a little time, a little firmness, a little forbearance, and a wise co-operation with our sister slaveholding States not yet out of the Union.
                </p>
                <p className="p-in-sp">
                  But, sir, while we are thus engaged in settling our national difficulties; while we are thus ardently engaged in efforts at national pacification, there is also a duty which Virginia owes to her own citizens. Whilst we are engaged in efforts to repel assaults from abroad, would it not be wise on our part to remedy the causes of difficulty and strife at home? Whilst we are engaged in
                  an effort to roll off the burthens of oppression from outside of Virginia, would it not be well to remedy the odious distinction or unequal burthens which are resting upon our own citizens; and whilst we are endeavoring to throw oil upon the troubled national waters, would it not be well, in the mean time, to make the necessary efforts to compose domestic strife, to produce
                  conciliation and harmony throughout the length and breadth of our own State. <PageNumber num={790} /> One of these odious and unequal burthens, Mr. President, is that anomaly in our organic law, whereby a large portion of the property of this Commonwealth is wholly exempt from taxation.
                </p>
                <p className="p-in-sp">Now, sir, allow me in this connection to make a statement which I have drawn from the report of the public Auditor of last year. According to the report of the Auditor, the whole number of slaves in Virginia in 1859, was 511,154 of which 272,073 were over 12 years of age, and 239,081 under 12 years of age.</p>
                <p className="p-in-sp">
                  Now, Mr. President, each slave over 12 years of age can, under our organic law, be taxed only to the amount imposed upon $300 worth of land. Slaves under 12 years of age, as I have said, are wholly exempt from taxation. The assessed value of negroes in Georgia, according to the same Auditor's report is $612 63 each. Putting the average value of negroes in Virginia at the moderate
                  estimate of $500 each, then it follows that in point of fact, two-fifths of all slaves over and above 12 years are likewise and virtually exempted from taxation. The value of this exemption of slaves over 12 years of age at the rate of $500 each, would amount to the sum of $54,514,000, which, taxed at the same rate with other personal property, would bring to the revenues of the
                  State annually the sum of $208,056.
                </p>
                <p className="p-in-sp">And then there are 239,081 slaves under 12 years of age, wholly exempt from taxation. Their value at the same average of $500 each, would amount to $119,540,500. If taxed at the same rate with other personal property, it would realize to the State the sum of $478,162.</p>
                <p className="p-in-sp">The value of this kind of property exempt from taxation is $174,054,500, which, taxed at the rate of other personal property, would realize to the Commonwealth of Virginia revenue amounting to the handsome sum of $686,218.</p>
                <p className="p-in-sp">
                  Now, Mr. President, allow me to remark, in this connection, that I am a slaveholder myself; and I ask upon what principle of public policy—upon what principle of public justice—upon what principle of right and propriety, is it that this large amount of the most valuable personal property in the Commonwealth of Virginia is wholly exempt from taxation? I confess I, myself, feel a sense
                  of mortification when I remember that the property of my non-slaveholding neighbor is subject, every cent of it, to rigid tribute, whilst my property, equally valuable, is exempt from taxation. Why is it that because I am a slaveholder I shall be exempt from the burdens of the Commonwealth, and my neighbor, equally worthy with me, though not a slaveholder, is to bear the burdens
                  which I ought to bear, and which by virtue of my possession of my property I am able to bear? I ask again, upon what principle <PageNumber num={791} /> of public policy should such an anomaly and inconsistency in our organic law be tolerated? Sir, according to the report of the proceedings of our Legislature, they have just passed a law appropriating a
                  million of dollars, which is to be added to our burdens; and the public debt upon our Commonwealth now I believe amounts to forty millions. The tendencies of the times, and the necessity to protect the State against encroachments growing out of the possession of this property have demanded this increase of the debt of Virginia, and I recur again to the question which I wish to be
                  sent home to the consideration of every member of this Convention, upon what principle is this exemption justified, whether of public policy or of personal right? Therefore, sir, I have prepared resolutions, which I shall send to be reported presently, by which I ask for the appointment of a committee to take into consideration so much of our Constitution as is embraced in the 22d
                  and 23d sections of the fourth article, with a view to uniform taxation throughout the Commonwealth. And, sir, I have heard it stated that there is great complaint throughout the Commonwealth in regard to our present judiciary organization. Especially, sir, is there great complaint in the section of the State from which I come—the county of Monongalia—in reference to the county court
                  system. It has been found to be inefficient; it has been found to be cumbersome; it has been found to be a caricature upon the administration of justice; it has been found to be extremely expensive; and a general public sentiment in that section of the State demands that this evil shall be removed. I have also prepared a resolution asking for the appointment of a Committee to take
                  the organization of our judiciary system into consideration, and report upon it accordingly. Then, sir, there remains a question concerning the basis of representation in the two houses of the General Assembly. The time has arrived I think, sir, when both houses of the General Assembly should have representation apportioned upon the same basis, and I have prepared resolutions upon
                  that subject. Without further explanation, sir, I send the whole of them to the Clerk, that they may be reported, and I ask for their adoption.
                </p>
                <p>The resolutions were reported as follows :</p>
                <p>Resolved, That taxation should be equal and uniform throughout the Commonwealth, and that all property should be taxed in proportion to its value.</p>
                <p>Resolved, That a committee of thirteen members be appointed to prepare and report to the Convention such alterations of section 22 and 23 of article IV, of the Constitution of the Commonwealth as shall <PageNumber num={792} /> conform said sections to the principle of taxation enunciated in the foregoing resolution.</p>
                <div className="speaker" id="sp745"><span className="persName">Mr. EARLY</span>—</div>
                <p className="p-in-sp">I think if I remember right, that the gentleman from Marion [<span className="persName">Mr. RAYMOND</span>] , offered a resolution for the appointment of a committee to take into consideration amendments or alterations to the Constitution of the State. Am I correct sir?</p>
                <div className="speaker" id="sp746"><span className="persName">The PRESIDENT</span>—</div>
                <p className="p-in-sp">The Chair regards the first resolution as specific in its object, and not embraced necessarily by the other.</p>
                <div className="speaker" id="sp747"><span className="persName">Mr. HAYMOND</span>—</div>
                <p className="p-in-sp">
                  I will state for the information of the gentleman, that the resolution offered by myself does not contemplate the appointment of any committee. It is only a resolution which announces, in its terms, that the Constitution of Virginia should be amended, and that this Convention will amend it where it is necessary and proper, and shall submit such amendments for the approbation or
                  rejection of the people, at the polls.
                </p>
                <div className="speaker" id="sp748"><span className="persName">Mr. EARLY</span>—</div>
                <p className="p-in-sp">I recollect it was the gentleman from Wetzel [<span className="persName">Mr. HALL</span>] , who offered the resolution for the appointment of a committee.</p>
                <div className="speaker" id="sp749"><span className="persName">Mr. HAYMOND</span>—</div>
                <p className="p-in-sp">The resolution offered by the gentleman from Wetzel is a resolution referring the whole Constitution to one Committee, and not in character with this resolution.</p>
                <div className="speaker" id="sp750"><span className="persName">Mr. TURNER</span>—</div>
                <p className="p-in-sp">
                  I did not catch exactly the terms of the resolution offered by the gentleman from Monongalia, but I will state that by reference to page 74 of the journal, it will be found that on the second day of March I offered a resolution which I think embraces specifically the same purpose as the first resolution offered by the gentleman from Monongalia. The resolution which I offered is as
                  follows :
                </p>
                <p className="p-in-sp">
                  Resolved, That it is expedient and proper that the 23d section of the 4th article of the Constitution of the State, shall be so modified, that slaves, like other property, shall be taxed without exemption, and according to value, and that no exemption of any property from taxation shall be had, without the vote of a majority of all the members elected to each House of the General
                  Assembly.
                </p>
                <p className="p-in-sp">I suggest, sir, that this resolution, which has been laid on the table, and which I have been desiring to get up for some time, covers the subject, and that the first resolution, offered by the gentleman from Monongalia, is not in order.</p>
                <p>
                  The Clerk was directed to report the resolutions.<span className="note" id="Note77"><span className="noteLabel">4</span>According to the Journal, Mr. Slaughter moved to lay Mr. Willey's "resolutions upon the table, pending which, on motion of Mr. Early, the Convention adjourned."</span> When he had read the second resolution, on motion of <span className="persName">Mr. EARLY</span>, the
                  Convention adjourned.
                </p>
              </div>
            </div>
          </section>
        </div>

        <div className="tei_back" id="v1back">
          <section className="back" id="vsc1965.v1.3">
            <PageNumber num={793} />
            <header>
              <h1><span className="headingNumber">Appendix A </span><span className="head">NOTES</span></h1>
            </header>
            <PageNumber num={794} />
            <div className="notes" id="vsc1965.v1.3.1">
              <PageNumber num={795} />
              <h2><span className="headingNumber">Appendix A.1 </span><span className="head">NOTES</span></h2>
              <p>The notes below are numbered 1 and upwards for each day of Convention Proceedings.</p>
              <p>Dates in parentheses are dates of issues of the Enquirer which contain editorial corrections for its reports of Proceedings.</p>
              <p>Page numbers in citations of Proceedings are numbers of pages in this edition.</p>
              <p>References to Documents, and to Journal or Convention Journal, are references to the printed official Documents of the Convention, and to the printed Journal of the Acts and Proceedings of a General Convention of the State of Virginia ... (Richmond : Wyatt M. Elliott, Printer, 1861) .</p>
              <p>FIRST DAY</p>
              <p>Wednesday, February 13</p>
              <p>
                1. The list of members answering the roll call, as given in the Convention Journal, includes also Valentine W. Southall of Albemarle, John Janney of Loudoun, and George W. Berlin of Upshur. The Journal gives a supplementary list of 23 members who appeared and took their seats after the roll was called on the first day, and the belated appearance of a few more delegates is recorded in
                the Journal for succeeding days.
              </p>
              <p>2. The Journal gives Mr. Turner's county as Rappahannock.</p>
              <p>3. The Journal gives the name as Lindsay.</p>
              <p>SECOND DAY</p>
              <p>Thursday, February 14</p>
              <p>1. This account of the nomination and election of the Sergeant-at-Arms differs from the Convention Journal in the spelling of some names, the identification of nominators and of nominees, and the tabulation of votes. The differences are thought to be too numerous and too unimportant for separate notes.</p>
              <p>2. This account of the nomination and election of the First Doorkeeper differs from the Convention Journal, notably in the tabulation of votes. The differ ences do not seem to call for separate notes.</p>
              <p>THIRD DAY</p>
              <p>Friday, February 15</p>
              <p>1. According to the Journal, Winn got 15 votes on this first ballot. The Journal gives the candidate's name as Welch. <PageNumber num={796} /> FOURTH DAY</p>
              <p>Saturday, February 16</p>
              <p>1. This first "not only" appears superfluous.</p>
              <p>2. See the Proceedings of February 20, pages 119-121, for Mr. Wise's correction of this question.</p>
              <p>3. A correction (February 19) inserts "a State" after "coerce."</p>
              <p>FIFTH DAY</p>
              <p>Monday, February 18</p>
              <p>1. This is the year cited in the Enquirer, although the speaker must have meant to say 1880.</p>
              <p>SIXTH DAY</p>
              <p>Tuesday, February 19</p>
              <p>1. Mr. Preston's speech was subsequently printed by W. M. Elliott, Printer to the Convention. A few small omissions and typographical errors in the Enquirer report of Mr. Preston's speech have been corrected by Elliott's text, when clarification seemed called for.</p>
              <p>SEVENTH DAY</p>
              <p>Wednesday, February 20</p>
              <p>1. The words "into the expediency" make no sense here, and it appears that the text of the resolution is garbled.</p>
              <p>2. Mr. Early corrected the report of his reference to this correspondence in the Proceedings of February 21, page 129.</p>
              <p>
                3. The Journal lists Mr. Parks among the 46 delegates casting an affirmative vote; addition of his name will make up the indicated total of ayes in these Proceedings. In the Proceedings of February 27, page 222, Mr. Ephraim B. Hall stated that a few days before, on a motion to reconsider the vote on a resolution by Mr. Clemens regarding the reporting of debates, he had voted in the
                affirmative, and not in the negative. Presumably he referred to this vote.
              </p>
              <p>EIGHTH DAY</p>
              <p>Thursday, February 21</p>
              <p>1. See Doc. No. V, Report of the Committee of Elections Shewing Who Are Elected Members of the Convention, Appendix C.</p>
              <p>2. The rest of this line, and the five lines following it, appear to be garbled.</p>
              <p>NINTH DAY</p>
              <p>Saturday, February 23</p>
              <p>1. Some such phrase as "I would explain" must have been omitted here, and the word "to" omitted after "remarks." <PageNumber num={797} /> 2. This assertion by Mr. Hall, and Mr. Clemens' reply, were corrected by Mr. Clemens in the Proceedings of February 25, pages 191-192.</p>
              <p>
                3. No list of delegates to the Convention has been found in the Proceedings. The Enquirer of February 15 published "an official list of the members of the Convention which assembled in the capitol on the 13th inst., at 12 o'clock M." but not as part of the reports of Proceedings; moreover, that list gives A. C. Kincaid as the delegate from Braxton, Nicholas and Clay. The list of
                delegates printed in Doc. No. V, Report of the Committee of Elections Shewing Who Are Elected Members of the Convention, and in the Convention Journal for February 21, gives Benjamin W. Byrne as the delegate from Braxton, Nicholas, Clay and Webster. The Convention Journal for February 19 records that "<span className="persName">Mr. B. W</span>. Byrne, the delegate from the counties of
                Braxton, Nicholas and Clay, appeared and took his seat."
              </p>
              <p>TENTH DAY</p>
              <p>Monday, February 25</p>
              <p>1. In Article 7, "they" presumably refers to the proposed amendments, and "been" is omitted before "ratified."</p>
              <p>2. The Convention Journal reads "controversies "</p>
              <p>3. A corrected version (February 28) gives this account of Mr. Montague's reading of the rule, and Mr. Wickham's comment:</p>
              <p><span className="persName">Mr. MONTAGUE</span> read the 14th rule, which provides that no person shall be taken into custody by the Sergeant-at-arms on any complaint of a breach of privilege, until the matter of complaint shall be examined by a committee. * * *</p>
              <div className="speaker" id="sp751"><span className="persName">Mr. WICKHAM</span>—</div>
              <p className="p-in-sp">
                I wish to know how the Sergeant-at-arms is to perform his duty if he is not provided with adequate means to enforce the orders of the Convention. How can he remove any refractory individual without the power of arresting him, unless indeed the Convention furnishes him with sufficient force to eject him from the gallery? If the rule which the gentleman from Middlesex
               [<span className="persName">Mr. MONTAGUE</span>] read be applicable to this case, then there is to be censure on the Sergeant-at-arms if he does not enforce order, and yet the rule deprives him of the power of doing so. No censure, direct or implied, ought to be cast upon the Sergeant-at-arms, for it seems to me that he did his duty promptly in accordance with the instructions of the President.</p>
              <p>ELEVENTH DAY</p>
              <p>Tuesday, February 26</p>
              <p>1. This sentence is incomplete in the Enquirer. Perhaps the first sentence of the paragraph below should be joined with it.</p>
              <p>TWELFTH DAY</p>
              <p>Wednesday, February 27</p>
              <p>1. Something like "was approved" has been omitted here.</p>
              <p>2. This should be Mr. WOODS.</p>
              <p>3. The date was the 20th. <PageNumber num={798} /> THIRTEENTH DAY</p>
              <p>Thursday, February 28</p>
              <p>1. The definite article is in the Enquirer. The reference is perhaps to Sir James Mackintosh.</p>
              <p>2. Probably the text should read "go into consultation and make."</p>
              <p>FOURTEENTH DAY</p>
              <p>Friday, March 1</p>
              <p>1. This communication from the Auditor of Public Accounts has not been found in the Enquirer. It was printed as Doc. No. VIII, Report of the Auditor of Public Accounts, Showing the Aggregate Number of Persons Returned Delinquent by the Sheriffs of the Commonwealth, for the Non-Payment of Taxes for 1860; also the Aggregate Amount of Such Taxes.</p>
              <p>2. Mr. Baylor corrected this statement in the Proceedings of March 4, page 374.</p>
              <p>3. See note 2, above.</p>
              <p>4. A corrected version (March 7) of Mr. Branch's remarks reads "Union men" instead of "disunion men."</p>
              <p>5. The corrected version reads "predicament" instead of "category."</p>
              <p>6. The corrected version reads "do not think."</p>
              <p>FIFTEENTH DAY</p>
              <p>Saturday, March 2</p>
              <p>1. The Journal includes Mr. Garland among the ayes.</p>
              <p>2. This should probably be "their."</p>
              <p>3. The text should perhaps read "In the event . . . of the recognition . . ."</p>
              <p>4. A correction (March 5) notes that it was Mr. Goode of Bedford, and not Mr. Goode of Mecklenburg, who offered a resolution recommending the passage of an ordinance of secession.</p>
              <p>SIXTEENTH DAY</p>
              <p>Monday, March 4</p>
              <p>1. A corrected version (March 9) of the last nine paragraphs of Mr. Willey's speech differs considerably from the version of those paragraphs published on March 7. The corrected version is as follows:</p>
              <p>
                Now, sir, if secession implies the putting of the State of Virginia upon a war footing, then I appeal to this Convention to say whether the people are prepared to meet the burdens necessary to put her in that condition. What is our debt now? Forty millions of dollars or thereabouts. Will our people suffer any more taxation? Are gentlemen ready here, if it can be avoided without
                dishonor, without degradation—to impose such crushing weight of taxation? Are they willing to send back to the people an ordinance of secession which will imply that they shall be subjected to millions after millions for the purpose of placing the State on a war <PageNumber num={799} /> footing? The people will never endure it if it should be done. They
                cannot endure it. They have not the ability to endure it.
              </p>
              <p>
                I wish I had strength, Mr. President, to re-pay the kind attention of the Convention to my remarks. I will only ask their further indulgence, while I present another idea to their consideration. I allude to the fact that in the Union Virginia is a central State: out of the Union, she would be a border State. Look at her position now. I have often looked at it, reflected over it, and I
                confess that I felt a pride, and not a little wonder, as I contemplated her great advantages in point of resources, and all the facilities for commercial and manufacturing enterprise. Look at her immense sea-board. Look at Norfolk, which possesses the most magnificent harbor on the face of the earth, with the mighty arm of the Chesapeake stretching forth to grasp the trade of the
                North, drawing it towards this great seaport. Look at the magnificent champaign country, inviting the energies and wealth of the capitalist for the construction of rail roads. Many of them are already partially built, and are in a fair way of commanding a monopoly of the Southern and South-Western trade, and directing it to its great natural outlet at Norfolk. There are in my own
                section of the State, North-Western Virginia, mineral resources and recently discovered fountains of oil extensive enough to furnish the basis of an empire's greatness; and these, when developed, will necessarily add to the power and wealth of the whole State. In that section, I should not be surprised if, in twenty-five years, the white population there will exceed the aggregate white
                population of all Virginia besides. Look at our geographical position. What relations do we sustain to the Pacific? If a great national line of rail road is built to the Pacific, and direct intercourse thus established between the Atlantic and the Pacific some Virginia city will be the terminus or depot; perhaps Richmond or Norfolk. Already the "iron-horse," the great representative of
                American energy and enterprise, is pushing his way up the Pacific slope. Already he is snuffing the air from the summit of the Rocky Mountains, eager to rush down across the Valley of the Mississippi, laden with the "wealth of the Indies," the long sought desideratum of our statesmen, and deposit his rich freight along the shores of our Chesapeake.
              </p>
              <p>Let Virginia secede and all these bright prospects are forever dashed to pieces.</p>
              <p>I beg gentlemen to look at the geography of Virginia, and say if they would be willing to be dragged out of this Union to join some miserable Southern Confederacy as a mere outside appendage.</p>
              <p>Mr. President, I will bring my remarks to a conclusion by mentioning another evil that would grow out of the secession of Virginia from the Union, and that is, that it would destroy our nationality; it would destroy the prestige of the American name and of American citizenship.</p>
              <p>
                How is it now, sir? Wherever our country's flag with its thirty-four stars floats on the breeze, any Virginian may stand up and proudly point to that banner as a flag that represents his country and his country's greatness and power. Sir, it is a noble flag. It is a flag upon which victory has perched without interruption for seventy-five years—a flag which Perry carried in his hand
                through the din and smoke of battle and placed it <PageNumber num={800} /> victoriously upon the enemy's vessel—an enemy who once held the empire of the sea—a flag which waved in triumph at the head of our army in its victorious march from Vera Cruz to the capital of Mexico, and at last floated over the palaces of the Montezumas—a flag which protects our
                commerce in every port and on every sea—a flag which, in short, represents our national power, gives full protection to every American citizen, go where he will—whether among the savages, in the Steppes of Russia, or in the abodes of Kings or Emperors, or wherever else he may choose to wander. Secession will trail that glorious banner in the dust—destroy its prestige and its power—and
                leave the American citizen to wander abroad, if he shall dare to go abroad, an object of contempt for chuckling tyrants to point the finger of scorn at, while they say: "Behold the last pitiable demonstration of the fallacy of the long cherished dogma of man's capacity for self-government."
              </p>
              <p>
                But it is said our Union is already dissolved ; and there is no use in talking about these things. I think not, sir. The Union is not dissolved in the true sense of the word. It still lives, and will live while Virginia stands firm. Let Virginia maintain her position; let her stand fast where she ever stood, and this Union can never be permanently dissolved. Some of the States may
                secede, as they have done, but they will be like asteroids flung off from the sun, hot, hissing through the trackless ether. But, sir, the sun still shines. The Union still remains while Virginia is steadfast. And I trust in God that such will be the centripetal influence of her moral power in the Union, as to bring back these wandering stars into their proper orbits in the great
                system of American States.
              </p>
              <p>
                My voice is failing, sir, and I shall be compelled to come to a close. I wish to say, before I do so, that I will not discuss what I conceive to be the remedy for our ills, simply from the fact that I believe it would be improper for me to do so, being a member of the Committee on Federal Relations, having that matter in hand. Were I to do so, it may look like seeking to forestall my
                own freedom of decision and judgment, which I have no desire nor intention of doing. I shall, therefore, avoid any argument or expression which might have any such tendency.
              </p>
              <p>Allow me, Mr. President, again to tender, to you and this Convention, my most cordial and hearty thanks for the attentive manner in which my remarks have been listened to; the attention and courtesy manifested were beyond their merits, far, very far indeed.</p>
              <p>2. The Journal does not list Mr. Montague among members of this committee.</p>
              <p>3. In the Proceedings of March 4, page 351, Mr. Hubbard is reported to have moved to table the resolution in question; in the Journal, however, Mr. Hall of Marion is stated to have moved the tabling of the resolution. There seems to be no way of reconciling these contradictions.</p>
              <p>SEVENTEENTH DAY</p>
              <p>Tuesday, March 5</p>
              <p>1. Probably "in" or "to" should follow "sense." <PageNumber num={801} /> EIGHTEENTH DAY</p>
              <p>Wednesday, March 6</p>
              <p>1. See Appendix D for the detailed returns.</p>
              <p>2. The speaker apparently did not complete the relative clause.</p>
              <p>3. The Enquirer made a number of errors in printing this letter; essential corrections have been supplied from the text printed as Document No. XII.</p>
              <p>4. In the Proceedings of March 7, page 446, Mr. Early gave another account of these remarks on the assertion of minority rights.</p>
              <p>5. The Journal lists Maslin and Waller as voting no, but omits Marye's name from the negative votes.</p>
              <p>NINETEENTH DAY</p>
              <p>Thursday, March 7</p>
              <p>1. Mr. Brown corrected this statement in the Proceedings of March 11, page 545.</p>
              <p>2. The word "states" has probably been omitted here.</p>
              <p>3. The speaker perhaps referred to the Omnibus Compromise Bill.</p>
              <p>4. It seems that some such words as "In order to see" have been omitted at the beginning of this sentence.</p>
              <p>TWENTIETH DAY</p>
              <p>Friday, March 8</p>
              <p>1. The resolutions were adopted on March 6.</p>
              <p>2. See the Proceedings of March 9, page 528, for Mr. Brent's correction of this statement.</p>
              <p>3. The speaker must have meant to say "to resist, by armed resistance, any attempt on the part of the Federal Government to collect revenue."</p>
              <p>4. The Enquirer has "within which."</p>
              <p>TWENTY-FIRST DAY</p>
              <p>Saturday, March 9</p>
              <p>1. The "revised remarks" which Mr. Brent proposed to publish have not been found.</p>
              <p>TWENTY-SECOND DAY</p>
              <p>Monday, March 11</p>
              <p>1. This question, or sentence, sounds as if it had been garbled.</p>
              <p>TWENTY-THIRD DAY</p>
              <p>Tuesday, March 12</p>
              <p>1. The Convention Journal gives the chairman's name as S.P.A. Moorman, and</p>
              <p>
                the name of the speaker in the second paragraph below as T. H. Nauer. <PageNumber num={802} /> 2. The Convention Journal consistently gives the chairman's name as Adam Rosenbam, while in the Enquirer the name occurs once as Rosenbam and once as Rosenham. In the Convention Journal, the secretary's name is given as D. D. Hull, and the committee member is
                called James H. Gilmore.
              </p>
              <p>TWENTY-FOURTH DAY</p>
              <p>Wednesday, March 13</p>
              <p>1. This is probably the A. G. Rogers and the Mr. Rogers mentioned below.</p>
              <p>TWENTY-FIFTH DAY</p>
              <p>Thursday, March 14</p>
              <p>1. The name is given as 0. Tacitus in the secretary's signature below.</p>
              <p>TWENTY-SIXTH DAY</p>
              <p>Friday, March 15</p>
              <p>1. The name is given as Thomas in the secretary's signature below.</p>
              <p>2. In the Proceedings of March 21, pages 150-152, the Chairman made a fuller statement of the procedure he proposed to follow during consideration of the report.</p>
              <p>3. There seems to be an omission at the end of this sentence.</p>
              <p>TWENTY-SEVENTH DAY</p>
              <p>Saturday, March 16</p>
              <p>1. A correction (March 21) of Mr. Burley's resolutions reads "poisonous" instead of "previous."</p>
              <p>2. The corrected version reads "canceled" instead of "conceded."</p>
              <p>
                3. The delivery of Mr. Randolph's speech extended over three days: March 16, 18, and 19. However, the speech was printed with no indication of where the breaks occurred in its delivery. The text of the whole speech has been incorporated in the Proceedings of March 16. The figures in the tables have been verified arithmetically. The statistics quoted in Mr. Randolph's speech have been
                corrected (when possible) to agree with the tables; but some unexplained discrepancies between these quoted figures and the tables have been allowed to stand. No effort has been made to compare the tables with the sources from which they were drawn.
              </p>
              <p>4. According to the Journal, Mr. Slaughter moved to lay Mr. Willey's "resolutions upon the table, pending which, on motion of Mr. Early, the Convention adjourned."</p>
            </div>
            <div className="appendix" id="vsc1965.v1.3.2">
              <PageNumber num={803} />
              <h2><span className="headingNumber">Appendix A.2 </span><span className="head">APPENDIX A</span></h2>
              <p>The Virginia State Convention of 1861 was authorized by this Act of Assembly, published in Acts of the General Assembly of the State of Virginia, Passed in 1861, in the Eighty-Fifth Year of the Commonwealth (Richmond : William F. Ritchie, Public Printer, 1861. Pages 24-27) :</p>
              <p>An ACT to provide for electing Members of a Convention, and to convene the same.</p>
              <p>Passed January 14, 1861.</p>
              <p>
                1. Be it enacted by the general assembly, that it shall be the duty of the commissioners and officers who were appointed to superintend and conduct elections for county and corporation officers in May last, at the places established for holding elections for members of the general assembly, to open polls for electing delegates to a convention. The said election shall be held on the
                fourth day of February in the year of our Lord eighteen hundred and sixty-one. At the same time the said commissioners and officers shall open a separate poll to take the sense of the qualified voters as to whether any action of said convention dissolving our connection with the federal Union, or changing the organic law of the state, shall be submitted to the people for ratification
                or rejection; and in order to ascertain the sense of the voters upon the question aforesaid, the said officers shall cause to be kept a poll, to be headed "Upon the question of referring such action to the people for their decision;" which said poll book shall have two columns the one headed "For referring to the people," and the other, "Against referring to the people;" and the names
                of those who vote for the former shall be written under the former heading, and those who vote for the latter, under the latter heading. When the said officers meet as herein provided, they shall ascertain and make return of the number of persons voting for each proposition. They shall forthwith send to the clerk of their respective counties or corporations a copy thereof, whose duty
                it shall be to transmit immediately a copy thereof to the president of the convention at Richmond, and also to the governor of the commonwealth. In case any officer appointed as aforesaid should fail to act, his or their place shall be supplied in the mode prescribed by law for general elections. <PageNumber num={804} /> 2. The convention shall consist of
                one hundred and fifty-two members, to be chosen for and by the several counties and cities of the commonwealth, as prescribed by the second section of the fourth article of the constitution of this state, for the election of members of the house of delegates. The county or counties which alternately vote for delegates to the general assembly under the said article of the said
                constitution, and which at the next election for delegates would be entitled to elect a delegate or delegates, shall elect the same number of members of the convention, and in the same manner that they would be entitled to if the election were for members of the next session of the general assembly.
              </p>
              <p>3. Any person may be elected a member of the convention, who at the time of election has attained the age of twenty-five years, and is a citizen of this commonwealth.</p>
              <p>4. All persons shall be qualified to vote on the question and in the election aforesaid, who are entitled to vote for delegates to the general assembly under the constitution of this commonwealth.</p>
              <p>5. The said election shall in all respects be conducted in the mode prescribed, and the officers conducting the same shall be vested with the powers, perform the duties, receive the same compensation, and be liable to the penalties prescribed by law for general elections, except as herein provided.</p>
              <p>
                6. The polls shall remain open for one day only; and the commissioners superintending the said election at the courthouses, shall meet in their respective counties and corporations on the second day after the election day; shall then compare the polls for their respective counties and corporations which elect a delegate or delegates, and ascertain and certify the votes of the counties
                and corporations, or parts thereof comprising election districts, and deliver a certified statement thereof to the officers conducting the election at the courthouses. And to compare the returns from the respective counties and parts of counties forming election districts for members of the general assembly, the officers conducting the election at the courthouses of the respective
                counties and parts of counties of such election districts, shall meet and compare the returns at the places now required by law for such comparison, on the fourth day after the election, and make returns of the election; one of which they shall forthwith transmit by mail to the governor : another with the poll books, shall be delivered to the clerk of the county or corporation court,
                to be filed in his office; and another to the member or members elected to the said convention. <PageNumber num={805} /> 7. The members so chosen shall meet on Wednesday the thirteenth of February next, at the capitol in the city of Richmond, and proceed to adopt such measures as they may deem expedient for the welfare of the commonwealth. The sessions of
                the convention shall be held in the capitol until otherwise provided for.
              </p>
              <p>8. In the case of a contested election, the same shall be governed in all respects by the existing laws in regard to contested elections in the house of delegates, unless otherwise ordered by the convention.</p>
              <p>9. In case of vacancies occurring previous to the meeting of the convention, the governor shall issue writs to supply the same ; and after the said meeting, the writs shall be issued by order of the convention, and the elections under such writs shall be conducted in all respects as the elections herein before provided for.</p>
              <p>
                10. The said convention shall be the judge of its own privileges and elections, and the members thereof shall have, possess and enjoy all the privileges which members elected to and attending on the general assembly are entitled to; and moreover shall be allowed the same pay for traveling to, attending on and returning from the said convention, as is now allowed to members of the
                general assembly for like services.
              </p>
              <p>11. The said convention is hereby empowered to appoint such officers, and to make them such reasonable allowances for their services as it shall deem proper ; which several allowances shall be audited by the auditor of public accounts, and paid by the treasurer of the commonwealth, out of any money in the treasury not otherwise appropriated.</p>
              <p>12. The expenses incurred in providing poll books and in procuring writers to keep the same, shall be defrayed as heretofore in the elections of members of the general assembly.</p>
              <p>13. Immediately upon the passage of this act, the governor shall issue a proclamation giving notice thereof, of the time of holding the election, and of the meeting of the convention herein provided for.</p>
              <p>
                14. The secretary of the commonwealth shall cause to be sent to the clerks of each county and corporation, as many copies of this act as there are precincts therein, using for that purpose special messengers, when necessary in his judgment. It shall be the duty of the clerks to deliver the same to the sheriff for distribution, who shall forthwith cause a copy to be posted at the door
                of his courthouse, and at some public place in each election district.
              </p>
              <p>15. This act shall be in force from its passage.</p>
            </div>
            <div className="appendix" id="vsc1965.v1.3.3">
              <PageNumber num={806} />
              <h2><span className="headingNumber">Appendix A.3 </span><span className="head">APPENDIX B</span></h2>
              <p>The Governor's proclamation of the Virginia State Convention was published in this form in the Daily Richmond Enquirer of January 16, 1861:</p>
              <p>BY THE GOVERNOR OF VIRGINIA</p>
              <p>A PROCLAMATION</p>
              <p>
                The General Assembly of this Commonwealth having passed "an act to provide for electing members of a Convention, and to convene the same," and having provided that Proclamation thereof shall be made by the Governor; therefore, I, John Letcher, Governor, by virtue of the authority aforesaid, do hereby announce that the election for members to the said Convention is required to be held
                at the places for holding elections for members of the General Assembly, on the fourth day of February next : That the Commissioners superintending the elections for separate counties or corporations, are required to meet at the Court Houses of their respective counties and corporations, and make returns of the election on the second day after the election day, and that those
                superintending- the elections for counties and corporations comprising election districts, are to meet at the places required by law, and make their returns on the fourth day after the said election: And that the members of the Convention who may be elected, are required to meet on Wednesday the 13th day of the same month, at the Capitol in the City of Richmond.
              </p>
              <p>Given under my hand as Governor, and under the seal of the Commonwealth, on the 15th day of January, 1861, and in the eighty-fifth year of the Commonwealth.</p>
              <p>JOHN LETCHER</p>
              <p>By the Governor :</p>
              <p>GEORGE W. MUNFORD,</p>
              <p>Secretary of the Commonwealth.</p>
            </div>
            <div className="appendix" id="vsc1965.v1.3.4">
              <PageNumber num={807} />
              <h2><span className="headingNumber">Appendix A.4 </span><span className="head">APPENDIX C</span></h2>
              <p>The Report below is the Report of the Committee of Elections Shewing Who Are Elected Members of the Convention, which was printed as No. V of the Documents of the Convention of 1861. Following the Report is an alphabetical list of the delegates, with corrections of some names misspelled in the Report.</p>
              <p>REPORT</p>
              <p>The Committee of Elections have had under consideration the evidence of election of members to the Convention, and report that the following persons appear to have been duly elected thereto, to wit : Accomac William H. B. Custis.</p>
              <p>Valentine W. Southall.</p>
              <p>Albemarle</p>
              <p>James P. Holcombe.</p>
              <p>Alexandria George W. Brent.</p>
              <p>Alleghany and Bath Thomas Sitlington.</p>
              <p>Amelia and Nottoway Lewis E. Harvie.</p>
              <p>Amherst Samuel M. Garland.</p>
              <p>Appomattox Lewis D. Isbell.</p>
              <p>A. H. H. Stuart,</p>
              <p>Augusta John B. Baldwin,</p>
              <p>George Baylor.</p>
              <p>Barbour Samuel WOODS.</p>
              <p>William L. Goggin,</p>
              <p>Bedford</p>
              <p>John Goode, Jr.</p>
              <p>Berkeley Edmund Pendleton,</p>
              <p>Allen C. Hammond.</p>
              <p>Fleming B. Miller,</p>
              <p>Botetourt and Craig</p>
              <p>William W. Boyd.</p>
              <p>Braxton, Nicholas, Clay and Webster Benjamin W. Byrne.</p>
              <p>Brooke Campbell Tarr.</p>
              <p>Brunswick James B. Mallory.</p>
              <p>Buckingham William W. Forbes.</p>
              <p>Cabell William McComas.</p>
              <p>Campbell John M. Speed,</p>
              <p>Charles R. Slaughter.</p>
              <p>Caroline Edmund T. Morris.</p>
              <p>Carroll F. L. Hale.</p>
              <PageNumber num={808} />
              <p>Charles City, James City and New</p>
              <p>John Tyler.</p>
              <p>Kent</p>
              <p>Charlotte Wood Bouldin.</p>
              <p>Chesterfield James H. Cox.</p>
              <p>Clarke Hugh M. Nelson.</p>
              <p>Cu/peper James Barbour.</p>
              <p>Cumberland and Powhatan William C. Scott.</p>
              <p>Dinwiddie James Boisseau.</p>
              <p>Doddridge and Tyler C. J. Stuart.</p>
              <p>Elizabeth City, Warwick, York and</p>
              <p>Charles K. Mallory.</p>
              <p>Williamsburg</p>
              <p>Essex and King Ce Queen Richard H. Cox.</p>
              <p>Fairfax William H. Dulany.</p>
              <p>Fauquier Robert E. Scott,</p>
              <p>John Q. Marr.</p>
              <p>Fayette and Raleigh Henry L. Gillespie.</p>
              <p>Fluvanna James M. Strange.</p>
              <p>Franklin Jubal A. Early,</p>
              <p>Peter Saunders, Sr.</p>
              <p>Floyd Harvey Deskins.</p>
              <p>Robert Y. Conrad,</p>
              <p>Frederick</p>
              <p>James Marshall.</p>
              <p>Giles Manitius Chapman.</p>
              <p>Gloucester John T. Seawell.</p>
              <p>Gilmer, Wirt and Calhoun C. B. Conrad.</p>
              <p>Goochland Walter D. Leake.</p>
              <p>Grayson Wm. C. Parks.</p>
              <p>Greene and Orange Jeremiah Morton.</p>
              <p>Greenbrier Samuel Price.</p>
              <p>Greenesville and Sussex J. R. Chambliss.</p>
              <p>Halifax Thomas S. Flournoy,</p>
              <p>James C. Bruce.</p>
              <p>Edward M. Armstrong,</p>
              <p>Hampshire</p>
              <p>David Pugh.</p>
              <p>Hancock George McC. Porter.</p>
              <p>Hanover George W. Richardson.</p>
              <p>Hardy Thomas Maslin.</p>
              <p>John S. Carlile,</p>
              <p>Harrison</p>
              <p>Benjamin Wilson.</p>
              <p>Henrico Williams C. Wickham.</p>
              <p>Henry Peyton Gravely</p>
              <PageNumber num={809} />
              <p>Highland George W. Hull.</p>
              <p>Isle of Wight Robert H. Whitfield.</p>
              <p>Jackson and Roane Franklin P. Turner.</p>
              <p>Alfred M. Barbour,</p>
              <p>Jefferson</p>
              <p>Logan Osburn.</p>
              <p>George W. Summers,</p>
              <p>Kanawha</p>
              <p>Spicer Patrick.</p>
              <p>King George and Stafford Edward Waller.</p>
              <p>King William Fendall Gregory, Jr.</p>
              <p>Lancaster and Northumberland Addison Hall.</p>
              <p>Lee John D. Sharp.</p>
              <p>Lee and Scott Peter C. Johnston.</p>
              <p>Lewis Caleb Boggess.</p>
              <p>Logan, Boone and Wyoming James Lawson.</p>
              <p>John Janney,</p>
              <p>Loudoun</p>
              <p>John A. Carter.</p>
              <p>Louisa William M. Ambler.</p>
              <p>Lunenburg W. J. Neblett.</p>
              <p>Madison Angus R. Blakey.</p>
              <p>Marion Alpheus F. Haymond,</p>
              <p>Ephraim B. Hall.</p>
              <p>Marshall James Burley.</p>
              <p>Mason James H. Couch.</p>
              <p>Matthews and Middlesex Robert L. Montague.</p>
              <p>Mecklenburg Thomas F. Goode.</p>
              <p>Mercer Napoleon B. French.</p>
              <p>Waitman T. Willey,</p>
              <p>Monongalia</p>
              <p>Marshall M. Dent.</p>
              <p>Monroe Allen T. Caperton,</p>
              <p>John Echols.</p>
              <p>Montgomery Wm. Ballard Preston.</p>
              <p>Morgan Johnson Orrick.</p>
              <p>Nansemond John R. Kilby.</p>
              <p>Nelson Frederick M. Cabell.</p>
              <p>Norfolk City George Blow, Jr.</p>
              <p>William White,</p>
              <p>Norfolk County</p>
              <p>J. G. Holladay.</p>
              <p>Northampton Miers W. Fisher.</p>
              <p>Ohio Sherrard Clemens,</p>
              <p>Chester D. Hubbard.</p>
              <p>Page Peter B. Borst. <PageNumber num={810} /> Patrick Samuel G. Staples.</p>
              <p>Pendleton Henry H. Masters.</p>
              <p>Pocahontas Paul McNeil.</p>
              <p>Petersburg. Thomas Branch.</p>
              <p>William T. Sutherlin,</p>
              <p>Pittsylvania</p>
              <p>William M. Tredway.</p>
              <p>Pleasants and Ritchie Cyrus Hall.</p>
              <p>Preston William G. Brown,</p>
              <p>James C. McGrew.</p>
              <p>Prince Edward John T. Thornton.</p>
              <p>Prince William. Eppa Hunton.</p>
              <p>Princess Anne Henry A. Wise.</p>
              <p>Prince George and Surry Timothy Rives.</p>
              <p>Pulaski Benjamin F. Wysor.</p>
              <p>Putnam James W. Hoge.</p>
              <p>Randolph and Tucker John N. Hughes.</p>
              <p>Rappahannock Horatio G. Moffett.</p>
              <p>William H. Macfarland,</p>
              <p>Richmond City Marmaduke Johnson,</p>
              <p>George W. Randolph.</p>
              <p>Richmond County &amp; Westmoreland John Critcher.</p>
              <p>Roanoke George P. Tayloe.</p>
              <p>Samuel McD. MOORE,</p>
              <p>Rockbridge</p>
              <p>James B. Dorman.</p>
              <p>Samuel A. Coffman,</p>
              <p>Rockingham John F. Lewis,</p>
              <p>Algernon S. Gray.</p>
              <p>Russell and Wise William B. Aston.</p>
              <p>Scott Colbert C. Fugate.</p>
              <p>Shenandoah Samuel C. Williams,</p>
              <p>Raphael M. Conn. Smyth James W. Sheffey. Southampton John J. Kindred. Spotsylvania John L. Marye, Sr. Taylor John S. Burdett.</p>
              <p>Tazewell, McDowell and Buchanan William P. Cecil,</p>
              <p>Samuel L. Graham.</p>
              <p>Upshur George W. Berlin.</p>
              <p>Warren Robert H. Turner.</p>
              <p>Washington Robert E. Grant,</p>
              <p>John A. Campbell. <PageNumber num={811} /> Wayne Burwell Spurlock.</p>
              <p>Wetzel Leonard S. Hall.</p>
              <p>Wood John J. Jackson.</p>
              <p>Wythe Robert C. Kent.</p>
              <p>The certificates of election produced are herewith filed, except those filed in the office of the Secretary of the Commonwealth.</p>
              <p>JOHN D. SHARP is returned elected from the county of Lee, but his seat in the Convention is contested by M. B. D. LaNE, of said county of Lee, and his petition and notice of contest have been referred to the Committee for examination and decision, which contest has not yet been finally acted upon by the Committee.</p>
              <p>The Committee, therefore, report that the said JOHN D. SHARP, having the official return of election for said county of Lee, is prima f acie entitled to occupy a seat in the Convention, until otherwise ordered by the Convention, on the final decision of said contest.</p>
              <p>The Committee will report upon said contest as soon as circumstances will allow.</p>
              <p>All of which is respectfully submitted.</p>
              <p>A. F. HAYMOND,</p>
              <p>Chairman of the Committee of Elections. <PageNumber num={812} /> ALPHABETICAL LIST OF CONVENTION DELEGATES</p>
              <p>Ambler, William M. Louisa</p>
              <p>Armstrong, Edward M Hampshire</p>
              <p>Aston, William B. Russell and Wise</p>
              <p>Baldwin, John B. Augusta</p>
              <p>Barbour, Alfred M. Jefferson</p>
              <p>Barbour, James Culpeper</p>
              <p>Baylor, George Augusta</p>
              <p>Berlin, George W. Upshur</p>
              <p>Blakey, Angus R. Madison</p>
              <p>Blow, Geo,rge, Jr. Norfolk City</p>
              <p>Boggess, Caleb Lewis</p>
              <p>Boisseau, James Dinwiddie</p>
              <p>Borst, Peter B. Page</p>
              <p>Bouldin, Wood Charlotte</p>
              <p>Boyd, William W. Botetourt and Craig</p>
              <p>Branch, Thomas Petersburg</p>
              <p>Brent, George W. Alexandria</p>
              <p>Brown, William G. Preston</p>
              <p>Bruce, James C. Halifax</p>
              <p>Burdett, John S. Taylor</p>
              <p>Burley, James Marshall</p>
              <p>Byrne, Benjamin W Braxton, Nicholas, Clay and Webster</p>
              <p>Cabell, Frederick M. Nelson</p>
              <p>Campbell, John A. Washington</p>
              <p>Caperton, Allen T. Monroe</p>
              <p>Carlile, John S. Harrison</p>
              <p>Carter, John A. Loudoun</p>
              <p>Cecil, William P. Tazewell, McDowell and Buchanan</p>
              <p>Chambliss, J. R. Greensville and Sussex</p>
              <p>Chapman, Manilius Giles</p>
              <p>Clemens, Sherrard Ohio</p>
              <p>Coffman, Samuel A. Rockingham</p>
              <p>Conn, Raphael M. Shenandoah</p>
              <p>Conrad, C. B. Gilmer, Wirt and Calhoun</p>
              <p>Conrad, Robert Y. Frederick</p>
              <p>Couch, James H. Mason</p>
              <p>Cox, James H. Chesterfield</p>
              <p>Cox, Richard H. Essex and King &amp; Queen</p>
              <p>Critcher, John Richmond and Westmoreland <PageNumber num={813} /> Custis, William H. B. Accomack</p>
              <p>Dent, Marshall M. Monongalia</p>
              <p>Deskins, Harvey Floyd</p>
              <p>Dorman, James B. Rockbridge</p>
              <p>Dulany, William H Fairfax</p>
              <p>Early, Jubal A. Franklin</p>
              <p>Echols, John Monroe</p>
              <p>Fisher, Miers W Northampton</p>
              <p>Flournoy, Thomas S. Halifax</p>
              <p>Forbes, William W. Buckingham</p>
              <p>French, Napoleon B. Mercer</p>
              <p>Fugate, Colbert C. Scott</p>
              <p>Garland, Samuel M Amherst</p>
              <p>Gillespie, Henry L. Fayette and Raleigh</p>
              <p>Goggin, William L. Bedford</p>
              <p>Goode, John, Jr. Bedford</p>
              <p>Goode, Thomas F. Mecklenburg</p>
              <p>Graham, Samuel L. Talewell, McDowell and Buchanan</p>
              <p>Grant, Robert E Washington</p>
              <p>Gravely, Peyton Henry</p>
              <p>Gray, Algernon S. Rockingham</p>
              <p>Gregory, Fendall, Jr King William</p>
              <p>Hale, F. L. Carroll</p>
              <p>Hall, Addison Lancaster and Northumberland</p>
              <p>Hall, Cyrus Pleasants and Ritchie</p>
              <p>Hall, Ephraim B. Marion</p>
              <p>Hall, Leonard S. Wetzel</p>
              <p>Hammond, Allen C. Berkeley</p>
              <p>Harvie, Lewis E. Amelia and Nottoway</p>
              <p>Haymond, Alpheus F. Marion</p>
              <p>Hoge, James W. Putnam</p>
              <p>Holcombe, James P. Albemarle</p>
              <p>Holladay, J. G. Norfolk County</p>
              <p>Hubbard, Chester D. Ohio</p>
              <p>Hughes, John N. Randolph and Tucker</p>
              <p>Hull, George W. Highland</p>
              <p>Hunton, Eppa Prince William</p>
              <p>Isbell, Lewis D. Appomattox</p>
              <p>Jackson, John J. Wood</p>
              <p>Janney, John Loudoun</p>
              <p>Johnson, Marmaduke Richmond City <PageNumber num={814} /> Johnston, Peter C. Lee and Scott</p>
              <p>Kent, Robert C. Wythe</p>
              <p>Kilby, John R. Nansemond</p>
              <p>Kindred, John J. Southampton</p>
              <p>Lawson, James Logan, Boone and Wyoming</p>
              <p>Leake, Walter D Goochland</p>
              <p>Lewis, John F. Rockingham</p>
              <p>McComas, William Cabell</p>
              <p>Macfarland, William H. Richmond City</p>
              <p>McGrew, James C Preston</p>
              <p>McNeil, Paul Pocahontas</p>
              <p>Mallory, Charles K. Elizabeth City, Warwick, York and Williamsburg</p>
              <p>Mallory, James B. Brunswick</p>
              <p>Marr, John Q Fauquier</p>
              <p>Marshall, James Frederick</p>
              <p>Marye, John L., Sr Spotsylvania</p>
              <p>Maslin, Thomas Hardy</p>
              <p>Masters, Henry H. Pendleton</p>
              <p>Miller, Fleming B. Botetourt and Craig</p>
              <p>Moffett, Horatio G Rappahannock</p>
              <p>Montague, Robert L. Mathews and Middlesex</p>
              <p>MOORE, Samuel McD. Rockbridge</p>
              <p>Morris, Edmund T. Caroline</p>
              <p>Morton, Jeremiah Greene and Orange</p>
              <p>Neblett, W. J. Lunenburg</p>
              <p>Nelson, Hugh M. Clarke</p>
              <p>Orrick, Johnson Morgan</p>
              <p>Osburn, Logan Jefferson</p>
              <p>Parks, William C. Grayson</p>
              <p>Patrick, Spicer Kanawha</p>
              <p>Pendleton, Edmund Berkeley</p>
              <p>Porter, George McC Hancock</p>
              <p>Preston, William Ballard Montgomery</p>
              <p>Price, Samuel Greenbrier</p>
              <p>Pugh, David Hampshire</p>
              <p>Randolph, George W Richmond City</p>
              <p>Richardson, George W. Hanover</p>
              <p>Rives, Timothy Prince George and Surry</p>
              <p>Saunders, Peter, Sr. Franklin</p>
              <p>Scott, Robert E. Fauquier</p>
              <p>Scott, William C. Cumberland and Powhatan <PageNumber num={815} /> Seawell, John T. Gloucester</p>
              <p>Sharp, John D. Lee</p>
              <p>Sheffey, James W . Smyth</p>
              <p>Sitlington, Thomas Alleghany and Bath</p>
              <p>Slaughter, Charles R. Campbell</p>
              <p>Southall, Valentine W. Albemarle</p>
              <p>Speed, John M. Campbell</p>
              <p>Spurlock, Burwell Wayne</p>
              <p>Staples, Samuel G. Patrick</p>
              <p>Strange, James M. Fluvanna</p>
              <p>Stuart, A. H. H Augusta</p>
              <p>Stuart, C. J. Doddridge and Tyler</p>
              <p>Summers, George W. Kanawha</p>
              <p>Sutherlin, William T. Pittsylvania</p>
              <p>Tarr, Campbell Brooke</p>
              <p>Tayloe, George P. Roanoke</p>
              <p>Thornton, John T. Prince Edward</p>
              <p>Tredway, William M. Pittsylvania</p>
              <p>Turner, Franklin P. Jackson and Roane</p>
              <p>Turner, Robert H. Warren</p>
              <p>Tyler, John Charles City, James dity and New Kent</p>
              <p>Waller, Edward King George and Stafford</p>
              <p>White, William Norfolk County</p>
              <p>Whitfield, Robert H. Isle of Wight</p>
              <p>Wickham, Williams C. Henrico</p>
              <p>Willey, Waitman T. Monongalia</p>
              <p>Williams, Samuel C. Shenandoah</p>
              <p>Wilson, Benjamin Harrison</p>
              <p>Wise, Henry A. Princess Anne</p>
              <p>WOODS, Samuel Barbour</p>
              <p>Wysor, Benjamin F Pulaski</p>
            </div>
            <div className="appendix" id="vsc1965.v1.3.5">
              <PageNumber num={816} />
              <h2><span className="headingNumber">Appendix A.5 </span><span className="head">APPENDIX D</span></h2>
              <p>The Report below is the Supplementary Report from the Committee of Elections Concerning the Vote on the Question of Referring' the Action of the Convention, Etc. to the People. It was printed as No. IX of the Documents of the Convention of 1861.</p>
              <p>REPORT</p>
              <p>
                The Committee of Elections further report to the Convention, that the vote in the various counties, cities and election districts, from which official returns have been received, either by the President of the Convention or the Governor of the Commonwealth, for referring to the people the action of the Convention, dissolving our connection with the Federal Union, or changing the
                organic law of the State, and "against referring" is as follows :
              </p>
              <p>For Against</p>
              <p>Reference Reference</p>
              <p>Accomac 845 599</p>
              <p>Albemarle 802 1,099</p>
              <p>Alexandria 1,221 200</p>
              <p>Alleghany 452 57</p>
              <p>Amelia and Nottoway 139 716</p>
              <p>Amherst 115 654</p>
              <p>Appomattox 33 346</p>
              <p>Augusta 3,394 263</p>
              <p>Barbour 1,291 148</p>
              <p>Bath 437 33</p>
              <p>Bedford 964 1,072</p>
              <p>Berkeley 1,533 206</p>
              <p>Boone 379 87</p>
              <p>Botetourt and Craig 866 823</p>
              <p>Braxton 517 74</p>
              <p>Brooke 785 64</p>
              <p>Brunswick 263 379</p>
              <p>Buchanan</p>
              <p>Buckingham 336 639</p>
              <p>Cabell</p>
              <p>Calhoun 313 35</p>
              <p>Campbell 1,418 1,078 <PageNumber num={817} /> For Against</p>
              <p>Reference Reference</p>
              <p>Caroline</p>
              <p>Carroll</p>
              <p>Charles City, New Kent and James City</p>
              <p>Charlotte</p>
              <p>Chesterfield</p>
              <p>Clarke</p>
              <p>Clay</p>
              <p>Craig, (see Botetourt)</p>
              <p>Culpeper</p>
              <p>Cumberland and Powhatan</p>
              <p>Dinwiddie</p>
              <p>Doddridge and Tyler</p>
              <p>Elizabeth City</p>
              <p>Essex</p>
              <p>Fairfax</p>
              <p>Fauquier</p>
              <p>Fayette</p>
              <p>Floyd</p>
              <p>Fluvanna</p>
              <p>Franklin</p>
              <p>Frederick</p>
              <p>Giles</p>
              <p>Gilmer</p>
              <p>Gloucester</p>
              <p>Goochland</p>
              <p>Grayson</p>
              <p>Greenbrier</p>
              <p>Greene</p>
              <p>Greenesville</p>
              <p>Halifax</p>
              <p>Hampshire</p>
              <p>Hancock</p>
              <p>Hanover</p>
              <p>Hardy</p>
              <p>Harrison</p>
              <p>Henrico</p>
              <p>Henry</p>
              <p>Highland</p>
              <p>214</p>
              <p>328</p>
              <p>133</p>
              <p>113</p>
              <p>504</p>
              <p>389</p>
              <p>197</p>
              <p>532</p>
              <p>356</p>
              <p>183</p>
              <p>1,504</p>
              <p>202</p>
              <p>856</p>
              <p>954</p>
              <p>661</p>
              <p>700</p>
              <p>320</p>
              <p>1,517</p>
              <p>1,909</p>
              <p>446</p>
              <p>535</p>
              <p>46</p>
              <p>79</p>
              <p>353</p>
              <p>1,322</p>
              <p>63</p>
              <p>722</p>
              <p>1,637</p>
              <p>657</p>
              <p>287</p>
              <p>972</p>
              <p>2,033</p>
              <p>1,135</p>
              <p>762</p>
              <p>677</p>
              <p>885 613 303 408 639 240 46</p>
              <p>365</p>
              <p>518</p>
              <p>400</p>
              <p>28</p>
              <p>262 524 921 41 38</p>
              <p>401 286 377 221 17 397 477 528 127</p>
              <p>166 779 255 41 762 67 183 709 216 66</p>
              <PageNumber num={818} />
              <p>For Against</p>
              <p>Reference Reference</p>
              <p>Isle of Wight 125 418</p>
              <p>Jackson, and part of Roane 1,186 310</p>
              <p>James City 15 53</p>
              <p>Jefferson 1,420 391</p>
              <p>Kanawha 2,272 186</p>
              <p>King George and Stafford 835 326</p>
              <p>King &amp; Queen 44 449</p>
              <p>King William 18 , 313</p>
              <p>Lancaster 93 204</p>
              <p>Lee 714 312</p>
              <p>Lewis 1,212 20</p>
              <p>Logan</p>
              <p>Loudoun 2,180 472</p>
              <p>Louisa 153 707</p>
              <p>Lunenburg 24 360</p>
              <p>Madison 59 695</p>
              <p>Marion 2,049 110</p>
              <p>Marshall 1,900 86</p>
              <p>Mason 1,654 49</p>
              <p>Matthews 2 224</p>
              <p>McDowell</p>
              <p>Mecklenburg 151 824</p>
              <p>Mercer 840 189</p>
              <p>Middlesex 19 213</p>
              <p>Monongalia 2,015 13</p>
              <p>Monroe 903 142</p>
              <p>Montgomery 622 181</p>
              <p>Morgan 612 38</p>
              <p>Nansemond 476 254</p>
              <p>Nelson 679 267</p>
              <p>New Kent (see Charles City)</p>
              <p>Nicholas 544 45</p>
              <p>Norfolk City 875 466</p>
              <p>Norfolk County 1,669 589</p>
              <p>Northampton 45 296</p>
              <p>Northumberland 220 329</p>
              <p>Nottoway, (see Amelia)</p>
              <p>Ohio 3,618 62</p>
              <PageNumber num={819} />
              <p>For Against</p>
              <p>Reference Reference</p>
              <p>Orange</p>
              <p>Page</p>
              <p>Patrick</p>
              <p>Pendleton</p>
              <p>Petersburg</p>
              <p>Pittsylvania</p>
              <p>Pleasants</p>
              <p>Pocahontas</p>
              <p>Powhatan, (see Cumberland)</p>
              <p>Preston</p>
              <p>Princess Anne</p>
              <p>Prince Edward</p>
              <p>Prince George and Surry</p>
              <p>Prince William</p>
              <p>Pulaski</p>
              <p>Putnam</p>
              <p>Raleigh</p>
              <p>Randolph, Tucker, etc.</p>
              <p>Rappahannock</p>
              <p>Richmond City</p>
              <p>Richmond County</p>
              <p>Ritchie</p>
              <p>Roane, (see Jackson)</p>
              <p>Roanoke</p>
              <p>Rockbridge</p>
              <p>Rockingham</p>
              <p>Russell</p>
              <p>Scott</p>
              <p>Shenandoah</p>
              <p>Smyth</p>
              <p>Southampton</p>
              <p>Spotsylvania</p>
              <p>Stafford, (see King George)</p>
              <p>Surry, (see Prince George)</p>
              <p>Sussex</p>
              <p>Taylor</p>
              <p>Tazewell</p>
              <p>Tucker, (see Randolph)</p>
              <p>296 471</p>
              <p>520 430</p>
              <p>699 161</p>
              <p>757 72</p>
              <p>1,134 317</p>
              <p>1,561 1,023</p>
              <p>449 18</p>
              <p>530 81</p>
              <p>1,923 11</p>
              <p>116 424</p>
              <p>183 472</p>
              <p>535 247</p>
              <p>270 550</p>
              <p>206 180</p>
              <p>679 127</p>
              <p>420 29</p>
              <p>832 103</p>
              <p>601 180</p>
              <p>2,024 1,679</p>
              <p>244 258</p>
              <p>904 102</p>
              <p>441 338</p>
              <p>1,901 205</p>
              <p>2,489 589</p>
              <p>910 49</p>
              <p>1,250 24</p>
              <p>797 1,266</p>
              <p>152 769</p>
              <p>469 457</p>
              <p>653 605</p>
              <p>55 341</p>
              <p>1,132 107</p>
              <p>236 915</p>
              <PageNumber num={820} />
              <p>For Against</p>
              <p>Reference Reference</p>
              <p>Tyler, (see Doddridge)</p>
              <p>Upshur</p>
              <p>Warren 349 299</p>
              <p>Warwick 21 70</p>
              <p>Washington 1,551 476</p>
              <p>Wayne 803 82</p>
              <p>Webster 106 45</p>
              <p>Westmoreland 264 342</p>
              <p>Wetzel 937 7</p>
              <p>Williamsburg 33 68</p>
              <p>Wirt 457 22</p>
              <p>Wise</p>
              <p>Wood 1,309 26</p>
              <p>Wyoming</p>
              <p>Wythe 694 653</p>
              <p>York</p>
              <p>100,536 45,161</p>
              <p>45,161</p>
              <p>Majority for referring 55,375</p>
              <p>From which it appears that the whole number of votes cast were 145,697, of which 100,536 were cast for referring to the people; and 45,161 votes against referring to the people, showing a majority of 55,375 votes for referring to the people.</p>
              <p>From the following named counties, no official returns have been received, viz : Buchanan, Cabell, Elizabeth City, Greene, Logan, McDowell, Upshur, Wise, Wyoming and York. Should the returns from the delinquent counties be received, the result of the vote will be reported.</p>
              <p>The Committee are of opinion that the returns from the delinquent counties will not materially change the above stated result. They may increase the majority for referring.</p>
              <p>All of which is respectfully submitted.</p>
              <p>A. F. HAYMOND,</p>
              <p>Chairman of the Committee of Elections.</p>
              <p>March 6, 1861.</p>
            </div>
            <span className="pagebreak" id="index.xml-pb-w2846204aab1b2ab8">[Page]</span>
          </section>
        </div>
        <div className="stdfooter autogenerated">
          <div className="footer"><a className="plain" href="/">Home</a> </div>
          <address>Virginia. Constitutional Convention.. Date: 2010<br /></address>
        </div>
        </Styled.Volume>
  );
};

export default Transcription;
