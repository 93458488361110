import React from "react";
import Map from './Map';
import ThreeStars from "../Icons/ThreeStars";
import * as Styled from './styled';
import * as StyledRoot from '../styled';


const Maps = () => {


  return (
    <main>
      <StyledRoot.ThreeStars>
        <ThreeStars />
      </StyledRoot.ThreeStars>

        <h1>Votes for Secession By County</h1>
      <p>Secessionists from the Tidewater and Piedmont tried three times to pass an ordinance of secession. Unionists delayed the first, on March 8, on procedural grounds.</p>
     
      <p>On April 4, as Unionist delegate John Baldwin met with Abraham Lincoln in Washington to discuss how war might be averted, the convention voted a second ordinance of secession down by a two-thirds majority. </p>
      
      <Styled.MapContainer>
        <Map date='404' />
        <figcaption>April 4 vote — Motion for secession defeated 45 to 90</figcaption>
      </Styled.MapContainer>

      <p>The vote for followed striking geographic patterns.  Most delegates west of the Blue Ridge mountains voted to stay in the Union.  Of the western delegates who voted to secede, most represented one of a number of contiguous counties west of the New River.  Many more secessionist delegates called the Piedmont and Tidewater home, though delegates from counties closest to Virginia's northern border tended to vote against an ordinance that, by then, was certain to hasten civil war.</p>
      
      <Styled.MapContainer>
        <Map date='417' />
        <figcaption>April 17 vote — Ordinance of Secession passed 88 to 55</figcaption>
      </Styled.MapContainer>

      <p>On April 17, after troops in South Carolina fired on Ft. Sumter and Lincoln called for troops to suppress the rebellion, delegates from Virginia voted again, this time for secession by a vote of 88 to 55. Again the pattern was geographical. Very few delegates east of the Blue Ridge mountains voted against secession. Most delegates whose counties bordered Washington, DC and Maryland continued to vote against secession, as did the staunchest unionists from the Valley and northwestern Virginia. The map of counties voting against secession evidences the Unionist sentiment in the region that would become West Virginia twenty-six months later.</p>
      
      <Styled.MapContainer>
        <Map date='417' emphasizeChanged />
        <figcaption>Counties whose delegates changed votes on April 17</figcaption>
      </Styled.MapContainer>

      <p>Most of the delegates changed their votes and joined the secession movement represented Piedmont counties with large enslaved population along the North Carolina border and tobacco-producing Tidewater counties.</p>


    </main>

  );
};

export default Maps;