import { useEffect, useRef, useState } from "react";
import { useLocation, useParams, Link } from "react-router-dom";
// import "../../../styles/PageViewer/Transcription.scss";
import PageNumber from "./PageNumber";
import * as Styled from './styled';

const Transcription = () => {
  const page = useParams().page || "1";
  const { hash } = useLocation();
  const scrollToAnchor = hash ? hash.replace("#", "") : null;

  // pageNum is stored in state and updated when any necessary scrolling has finished
  const [pageNum, setPageNum] = useState(0);
  // this is set to true if the element if scrollIntoView is necessary in the effect below. It's passed to PageNumber components to prevent them from navigating to a new page until the scroll here is complete.
  const [isAutoScrolling, setIsAutoScrolling] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  //when the page number changes, scroll to the page on the transcription
  useEffect(() => {
    if (parseInt(page) !== pageNum && !scrollToAnchor) {
      const element = document.getElementById(`page-${page}`);
      const transcriptionContainer = ref.current;
      if (element && transcriptionContainer) {
        element.scrollIntoView();
        let scrollTimeout: any;
        setIsAutoScrolling(true);
        const onScroll = (e: any) => {
          clearTimeout(scrollTimeout);
          scrollTimeout = setTimeout(function () {
            setPageNum(parseInt(page));
            setIsAutoScrolling(false);
          }, 100);
        };
        transcriptionContainer.addEventListener("scroll", onScroll);
        return () => {
          transcriptionContainer.removeEventListener("scroll", onScroll);
        };
      }
    }
  }, [page, pageNum, scrollToAnchor]);

  // scroll to a meeting anchor
  useEffect(() => {
    if (scrollToAnchor) {
      const element = document.getElementById(scrollToAnchor);
      const transcriptionContainer = ref.current;
      if (element && transcriptionContainer) {
        element.scrollIntoView();
        let scrollTimeout: any;
        setIsAutoScrolling(true);
        const onScroll = (e: any) => {
          clearTimeout(scrollTimeout);
          scrollTimeout = setTimeout(function () {
            setPageNum(parseInt(page));
            setIsAutoScrolling(false);
          }, 100);
        };
        transcriptionContainer.addEventListener("scroll", onScroll);
        return () => {
          transcriptionContainer.removeEventListener("scroll", onScroll);
        };
      }
    }
  }, [page, pageNum, scrollToAnchor]);

  return (
    <Styled.Volume>
      <div className="tei_front" id="v3front">
        <PageNumber num={1} />
        <div className="titlePage">
          <div className="docTitle">
            <div className="titlePart">
              Proceedings<br />
              OF THE<br />
              Virginia State Convention<br />
              of 1861
            </div>
            <div className="titlePart">FEBRUARY 13 — MAY 1<br />In Four Volumes</div>
          </div>
          <div className="byline">GEORGE H. REESE<br />Editor</div>
          <div className="docImprint"><span className="date">1965</span><span className="publisher">VIRGINIA STATE LIBRARY</span><span className="pubPlace">RICHMOND, VIRGINIA</span>:</div>
        </div>
        <section className="front" id="vsc1965.v3.1">
          <h1>Library of Congress …</h1>
          <PageNumber num={2} />
          <p>Library of Congress Catalog Card No. A65-7459</p>
          <p>PRINTED IN U.S.A.</p>
          <div className="contents" id="vsc1965.v3.1.1">
            <PageNumber num={3} />
            <h2><span className="head">CONTENTS</span></h2>
            <p>Synopsis</p>
            <p>Proceedings 3</p>
            <p>Notes 771</p>
            <PageNumber num={4} />
          </div>
          <div className="synopsis" id="vsc1965.v3.1.2">
            <PageNumber num={5} />
            <h2><span className="head">SYNOPSIS</span></h2>
            <h3>FORTY-FIRST DAY</h3>
            <div className="synopsisDateLine"><div>Tuesday, April 2</div><Link to={`../${3 + 10}`} relative="path">3</Link></div>
            <p>
              Mr. Willey resumes his remarks on taxation, and once more asks for a change in the taxes on slaves. Mr. Montague concludes his speech and asserts the constitutional right of secession ; he declares it is Virginia's moral duty to separate from people who strive to destroy God's institution of African slavery. Mr. Macfarland delivers a long speech in which he expresses the hope that
              North and South may be reconciled, perhaps by amendments to the Constitution. He feels that friendship with the North is possible if slavery is excluded from legislation and agitation, and he favors one more effort to rebuild the Union. Mr. Wise defends his record as a friend of the West and recalls his efforts on behalf of equal taxation.
            </p>
            <h3>FORTY-SECOND DAY</h3>
            <div className="synopsisDateLine"><div>Wednesday, April 3</div><Link to={`../${50 + 10}`} relative="path">50</Link></div>
            <p>
              Mr. Stuart speaks on taxation. He states that Virginia is on the verge of a civil war, and that her people must be united. He adds that the people of western Virginia must demand equality of taxation. Mr. Scott appeals for an adjustment of differences with the North, and for concerted action with the border states. Mr. Richardson rises to discuss the historical, moral, economic, legal
              and political aspects of slavery, and to criticize in detail the report of the Committee on Federal Relations. He recommends secession and the Southern Confederacy; he regrets the rupture of the Union, but affirms that the seceded South can rejoin it only as an equal.
            </p>
            <h3>FORTY-THIRD DAY</h3>
            <div className="synopsisDateLine"><div>Thursday, April 4</div><Link to={`../${112 + 10}`} relative="path">112</Link></div>
            <p>Mr. Stuart concludes his talk on taxation, with a demand that slaves be taxed in the same way as other property. Mr. Richardson concludes his speech. Mr. Morton states that the Committee on Federal Relations has compromised Southern rights, and argues that the South must have the political power to protect herself. The third resolution</p>
            <PageNumber num={6} />
            <p>
              of the Committee on Federal Relations is adopted. The fourth resolution, on slavery in the territories, and the fifth resolution, on Federal forts, are adopted. The sixth resolution, recommending efforts at reconciliation, is introduced, and Mr. Harvie proposes an ordinance of secession as a substitute. After spirited debate, the substitute is defeated. The Convention resolves to meet
              in the Hall of the House of Delegates on and after Monday, April 8. Mr. Turner offers resolutions providing that slaves shall be taxed without exemption.
            </p>
            <h3>FORTY-FOURTH DAY</h3>
            <div className="synopsisDateLine"><div>Friday, April 5</div><Link to={`../${168 + 10}`} relative="path">168</Link></div>
            <p>
              Mr. Stuart concludes his remarks, predicting that secession will bring about the end of slavery. There is further discussion on the sixth resolution in the report of the Committee on Federal Relations, which is amended and adopted. Mr. Wise and others discuss the seventh resolution, concerning fugitive slaves. The seventh resolution is amended and approved. The eighth resolution is
              passed over, and discussion turns to the ninth resolution, concerning relations between the seceded states and those still in the Union. Mr. Macfarland, Mr. Blow, and Mr. Sheffey offer amendments.
            </p>
            <h3>FORTY-FIFTH DAY</h3>
            <div className="synopsisDateLine"><div>Saturday, April 6</div><Link to={`../${222 + 10}`} relative="path">222</Link></div>
            <p>
              Mr. Bouldin, Mr. Chambliss, and Mr. Johnson present resolutions of the citizens of Charlotte and Norfolk counties, and the cities of Portsmouth and Richmond, asking for immediate secession. The delegates adopt the ninth resolution of the Committee on Federal Relations. They take up the eighth resolution, which asserts the right of secession, and adopt it. Mr. Preston submits
              resolutions which call for a committee of three to ascertain the intentions of the Federal government towards the Confederacy; Mr. Baldwin suggests a preamble, as an amendment to Mr. Preston's resolutions. Mr. Jackson, Mr. Early, and Mr. Willey object, and hard words pass between East and West.
            </p>
            <h3>FORTY-SIXTH DAY</h3>
            <div className="synopsisDateLine"><div>Monday, April 8</div><Link to={`../${292 + 10}`} relative="path">292</Link></div>
            <p>The Convention meets in the Hall of the House of Delegates. The</p>
            <p>Governor transmits communications from the Arkansas. State Con-</p>
            <PageNumber num={7} />
            <p>vention. Mr. Stuart, Mr. Tyler, and other delegates debate Mr. Preston's resolutions on ascertaining Federal intentions, and the resolutions, with Mr. Baldwin's preamble, are finally passed. Messrs. Preston, Stuart, and Randolph are chosen as the commissioners to Washington.</p>
            <h3>FORTY-SEVENTH DAY</h3>
            <div className="synopsisDateLine"><div>Tuesday, April 9</div><Link to={`../${370 + 10}`} relative="path">370</Link></div>
            <p>
              Mr. Hunton, Mr. Scott and Mr. Goggin present resolutions of meetings in Prince William, Cumberland and Bedford counties, in favor of secession and the Confederacy. Discussion of the report of the Committee on Federal Relations is resumed. Mr. Conrad moves a change in the tenth resolution, empowering the Federal government to recognize the Confederacy. Mr. Bouldin objects, and Mr. Wise
              proposes a substitute, which is adopted. The eleventh resolution, favoring an appeal to Virginia's sister states, is read and adopted. The twelfth resolution, opposing Federal coercion of the seceded states, is taken up ; several amendments are proposed.
            </p>
            <h3>FORTY-EIGHTH DAY</h3>
            <div className="synopsisDateLine"><div>Wednesday, April 10</div><Link to={`../${444 + 10}`} relative="path">444</Link></div>
            <p>
              Mr. Cox presents resolutions of a meeting in King and Queen County, urging secession, and deploring the dilatory course of the Convention. Mr. Willey's resolutions on taxation, and various amendments to them, are discussed. The twelfth resolution of the Committee on Federal Relations is taken up once more, discussed at length, amended, and finally adopted. The thirteenth resolution,
              calling on the Federal and Confederate governments to keep the peace, is read, amended, and adopted. The fourteenth resolution, concerning a conference of the border states at Frankfort, is discussed, and amendments are suggested. The Convention resumes consideration of Mr. Willey's resolutions, but adjourns without final action on them.
            </p>
            <h3>FORTY-NINTH DAY</h3>
            <div className="synopsisDateLine"><div>Thursday, April 11</div><Link to={`../${523 + 10}`} relative="path">523</Link></div>
            <p>Mr. Harvie presents resolutions of a meeting in Nottoway County,</p>
            <p>desiring immediate secession. Mr. Willey's resolutions on taxation</p>
            <p>are taken up once more, and Mr. Hall comments. The fourteenth</p>
            <PageNumber num={8} />
            <p>1</p>
            <p>
              resolution of the Committee on Federal Relations comes up again; various delegates speak on the resolution and several proposed amendments. Discussion returns to Mr. Willey's resolutions on taxation. Mr. Wickham addresses the Convention on taxation of slaves, and declares the prevailing system is not unjust to the West. It is resolved to refer Mr. Willey's taxation proposals to a
              committee.
            </p>
            <p>FIFTIETH DAY</p>
            <p>Friday, April 12 603</p>
            <p>
              Mr. Blow reads a petition from the City of Norfolk, recommending secession, and a letter from a meeting of Union men in Norfolk. The Convention discusses procedural points, then resumes consideration of the fourteenth resolution of the Committee on Federal Relations, with Mr. Wise, Mr. Bouldin, Mr. Baldwin and others engaging in the debate. The resolution is adopted, as amended. The
              first part of the report of the Committee on Federal Relations having now been dealt with, the second part, proposing amendment of the Federal Constitution, comes under consideration. The first section, concerning slavery in the territories, is reported ; Mr. Wise proposes radical changes, and Mr. Boyd offers a substitute. Mr. Forbes presents resolutions of citizens of Buckingham
              County, urging secession, and opposing a convention of border states.
            </p>
            <p>FIFTY-FIRST DAY</p>
            <p>Saturday, April 13 676</p>
            <p>
              Mr. Parks and Mr. Campbell present petitions from Grayson and Washington counties, three in favor of secession, and one against it. The Convention receives official news of the bombardment of Fort Sumter, and resumes consideration of the proposed amendments to the Federal Constitution; the first section is amended and adopted. The second section, regarding future acquisition of
              territory, is reported; several amendments are offered, and the section is adopted as amended. The third and fourth sections, concerning Federal power over slavery and the recovery of fugitive slaves, are reported and agreed to. The fifth section, concerning importation of slaves, is taken up and discussed. The Convention receives further news of Fort Sumter. Mr. Speed lays before the
              Convention the resolutions of a meeting in Campbell County, in favor of secession. Mr. Wysor offers a resolution calling Virginia to the aid of the Confederate states.
            </p>
            <PageNumber num={9} />
            <p>FIFTY-SECOND DAY</p>
            <p>Monday, April 15 733</p>
            <p>The Convention receives the report of the commission appointed to ascertain the intentions of the Federal government towards the Confederacy. Mr. Holcombe proposes that the Convention go at once into secret session. Mr. Scott and others comment on this proposal, and various members allude to President Lincoln's proclamation calling for troops from the states still in the Union.</p>
            <p>FIFTY-THIRD DAY</p>
            <p>Tuesday, April 16 765</p>
            <p>The President announces the members of the committee to consider Mr. Willey's resolutions on taxation of slaves. The Convention resolves to go immediately into secret session, the galleries and lobbies are cleared, and the Hall is cleared of all reporters except one.</p>
            <PageNumber num={10} />
          </div>
        </section>
      </div>

      <div className="tei_body" id="v3body">
        <section className="body" id="vsc1965.v3.2">
          <PageNumber num={11} />
          <header>
            <h1><span className="headingNumber">1. </span><span className="head">VOLUME THREE</span></h1>
          </header>
          <p>Proceedings : April 2 - April 16</p>
          <PageNumber num={12} />
          <div className="day" id="vsc1965.v3.2.1">
            <PageNumber num={13} />
            <h2><span className="headingNumber">1.1. </span><span className="head">FORTY-FIRST DAY</span></h2>
            <div className="dateline">Tuesday, <span className="date">April 2</span></div>
            <p>The Convention met at 10 A. M., pursuant to adjournment. The President in the Chair. Prayer by the Rev. Mr. Pettigrew of the Disciples Church.</p>
            <p><span className="persName">The PRESIDENT</span> stated the question to be upon the resolutions offered by the gentleman from Monongalia [<span className="persName">Mr. WILLEY</span>] , who was entitled to the floor.</p>
            <div className="speaker" id="sp1873"><span className="persName">Mr. WILLEY</span>, in Convention, resumed as follows:</div>
            <p className="p-in-sp">
              I regret, Mr. President, that we have to press within the short limits of time allowed us under the rules of the House, the discussion of a subject so important as the subject of taxation. The time will not admit of an argument; it will hardly allow a statement of the case to be made. I know, sir, that we are occupied with another subject of transcendent importance. We are seeking to
              preserve the integrity of the Union. But, sir, let me assure you that, in my humble estimation, the principle involved in the discussion of the resolutions now before the Convention involves the integrity of the State. When I was arrested in my remarks the other day, if I remember aright, I was endeavoring to show the unequal operation of the principle of taxation as now recognized and
              provided in the organic law. I was attempting to illustrate it by reference to individuals. I was protesting against the allegation urged by the opponents of this measure that it was simply a sectional question, that it was essentially a Western question as contra-distinguished from the whole State.
            </p>
            <p className="p-in-sp">
              I have, since that time, Mr. President, been looking a little into facts and figures; and propose now, with your leave, to advert briefly to a few facts which will settle the question in this respect, in my estimation. I protest again against the argument of gentlemen that this is either a sectional question, or that it is confined in its scope to the north-western part of the State,
              or of any other particular part of the State. I aver that it is a matter in which all the citizens of the State are interested. How is it? I was making some enquiries of the Auditor of Public Accounts, the other day, and I ascertained from him two facts. I ascertained from him that the whole number of taxpayers in the Commonwealth of Virginia at this time, was, in round numbers,
              207,000. I also ascertained from him that the number of slaveholders in Virginia, in round numbers, was 54,000. Thus making 153,000 non- <PageNumber num={14} /> slaveholding tax-payers, and 54,000 slaveholding tax payers in the whole State.
            </p>
            <p className="p-in-sp">
              Well, sir, in looking into the able and interesting report of the Auditor for 1859, I find that the number of slaves West of the Blue Ridge is about 66,000, or about one-eighth of the whole number of slaves in the Commonwealth. It is fair to suppose, therefore, that one-eighth of the slave owners live West of the Blue Ridge. Sir, it is hardly fair to suppose so, because the number of
              slaveholders in proportion to the number of slaves owned is much greater West than it is East of the Blue Ridge. Well, sir, deducting one-eighth from the 54,000, you leave 47,000 slave owners East of the Blue Ridge.
            </p>
            <p className="p-in-sp">
              Now, sir, I have looked into the Auditor's report to ascertain another fact, and I am sorry that there are no data for the years 1859 or 1860, from which we could infer the number of slave-owners ( I mean the number of taxpayers) East of the Blue Ridge ; but I did find that the number of tax-payers, according to the Auditor's report, in 1857 living East of the Blue Ridge was in round
              numbers 90,000. Deduct from this the 47,000 owners of slaves, and you have 43,000 remaining, as the number of non-slave-owning tax-payers East of the Blue Ridge. So that you have in Eastern Virginia, within four thousand of as many non-slaveholding tax-payers as there are slave holders. And yet, Mr. President, it is said that this is a sectional question; it is said that this is a
              question brought here by Western men for the purpose of sowing dissensions and strife in the deliberations of this body. I demand to know whether the 43,000 non-slaveholding tax-payers in Eastern Virginia have not a right to be heard upon this floor as much as the non-slaveholding tax-payers West of the Blue Ridge? Sir, it is not a sectional question. Gentlemen cannot make it a
              sectional question. It is a question in which the people of every section of this Commonwealth are interested, and I think the period is not far distant when their voice will be heard from every part of the Commonwealth unless their grievances are redressed.
            </p>
            <p className="p-in-sp">
              Now, sir, what are the facts developed in the discussion of this subject? They are that there is from $150,000,000 to $200,000,000 of the most valuable property in Virginia wholly exempt from taxation. I have only time to refer to the circumstances under which this fact is allowed to exist. And what are they? Let it be remembered, sir, by this Convention and let it be remembered by the
              people of Virginia, that from $150,000,000 to $200,000,000 of property in the Commonwealth of Virginia are wholly exempt from taxation, while we have resting upon us the crushing burden of $40,000,000 of public <PageNumber num={15} /> debt. Let it be remembered, sir, that this debt is annually increasing. Let it be remembered that our Legislature now in
              session, and the last Legislature, have appropriated—the former $500,000, and the present Legislature $1,000,000 for the purpose of putting this State in a condition of defence and to redress grievances upon this very property, so exempt from taxation—in short, to defend the institution of slavery.
            </p>
            <p className="p-in-sp">
              I admit, sir, that the North-Western section of Virginia is more interested in this question than any other quarter of the State ; but I wish this fact to be pondered upon; that this vast amount of property is allowed to remain exempt from taxation not only when our public debt is increasing—not only when we find it necessary to increase it to defend the honor and the property of
              Virginia—but this fact is allowed to exist when the State credit is deteriorating, while our bonds are depreciating in the market, while they are at a discount of 25 to 30 per cent, while they are scoffed at in the Exchanges abroad, and have little credit at home.
            </p>
            <p className="p-in-sp">
              Why, sir, we of the West willingly pay our quota of the taxes; but if I don't misread the signs of the times, there will be another kind of demand made upon us ; and I take pride in saying that whether you grant us this right or not, this new demand will be met to the fullest extent. If these warlike appropriations mean anything—if we are to be involved in war, as I fear we shall
              be—where are you to find the men to fight your battles? Where will you get the strong arms to defend your slaves? From our glorious mountains of the West. There, where the white man lives with true and loyal heart, you will find men now, as aforetime, to defend your institutions and your firesides. I wish to know whether Eastern gentlemen require us to submit all of our property to
              taxation, and fight the battles of the country too, while your property, which we are defending, is to be exempt from taxation?
            </p>
            <p className="p-in-sp">
              How is it again, sir? You are contending here for the right to carry your slaves into distant territories two thousand miles away—territories so inhospitable that the very rain and dews refuse to fall upon them. They would be practically as barren of any benefits to the slaveholder as the soil is of vegetation. We are appropriating millions upon millions for the vindication of this
              naked right, while we have, without any figure of speech, within our own Commonwealth, what may be properly termed terra incognita, rich in all the elementary resources of national wealth and prosperity—abounding in rivers and water-falls and "wild cascades with echo undefined," with power sufficient to <PageNumber num={16} /> drive all the spindles and
              machinery of a thousand Lowells—abounding in forests equal in value and beauty to those which crowned the summits, adorned the sides of Lebanon—abounding in minerals, in illimitable fields of coal, capable of employing all the slave labor in Virginia for centuries, without exhaustion. All these elements of wealth, all these elements of State power and prosperity, are within 200 and 250
              miles of the capital of your State. And yet you exempt your slave property from taxation, and thus suffer this great source of internal wealth to lie undeveloped and unavailing, because we have not the means to complete our works of internal improvement now partially accomplished, leading thitherward, which would make available all these sources of State wealth and State power.
            </p>
            <p className="p-in-sp">
              By our policy we are not only grinding the people down with the burdens of heavy taxation, in carrying on, in a laggard way, our State improvements; we are not only doing that, sir, but we are allowing these improvements to be stopped short of completion, in consequence of the want of the means to complete them. The ever-increasing tide of Western wealth and travel and traffic is
              coming down against our Western mountains like the waves of the sea against the shore, seeking the shortest way to the ocean and to Europe. Sir, this tide of travel, this tide of traffic and wealth, from the rich valleys and prairies and rivers of the West, thus bearing down against our Western mountain barriers, for the want of the means of direct outlet to its natural places of
              deposit at Richmond, Alexandria, Norfolk, and on the banks of the Chesapeake, is diverted from its natural channel and is turned away through the Northern ice and snows to New York, or down through the swamps and miasma of the South; thus securing Black Republican power by the diversion of capital from our State, concentrating it in the North, increasing Northern population and power,
              and increasing the ratio of representation against us, decade after decade, while we stand here with this immense amount of property untaxed, which if taxed, would be sufficient to obviate all these evils. The roar of this great tide of Western wealth, rolling down against our Western mountains, is falling upon our ears daily and yearly, demanding a passage to our Eastern shore cities
              and seaports, while we stand here folding our arms and saying, "let no profane commissioner of the revenue lay his impious hands upon our slaves. They shall not be taxed." I submit, therefore, Mr. President, whether under these circumstances, it is not the true policy of our Eastern friends to come at once to the rescue of their own interest.
            </p>
            <PageNumber num={17} />
            <p className="p-in-sp">
              I did not intend, Mr. President, to discuss this subject. I have now said more than I intended to say. I wish, in conclusion, simply to add, why delay this measure of redress? Why delay the removal of this obnoxious oppression? Upon what principle of political expediency, or personal right, or public law, or public justice, can this discrimination be made in favor of the large
              slaveholders against the many nonslaveholders in the Commonwealth? I hope the question will be taken, and I give way for the purpose of allowing it to be done.
            </p>
            <div className="speaker" id="sp1874"><span className="persName">Mr. STUART</span>, of Doddridge—</div>
            <p className="p-in-sp">I desire very much to submit a few remarks upon this question, but as I believe the hour for going into Committee, has nearly arrived I prefer not to commence them until to-morrow morning.</p>
            <div className="speaker" id="sp1875"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">But three minutes of the morning hour remain.</p>
            <div className="speaker" id="sp1876"><span className="persName">Mr. C. J. STUART</span>—</div>
            <p className="p-in-sp">
              Well, sir, in that three minutes, I desire to say to the gentlemen from Eastern Virginia, upon this floor, that I do not think they have been talked to on this floor quite as plainly as they ought to, on this question, and not quite as plainly as I will talk to them. I think it is my duty to speak to them plainly. I know exactly the feelings of Western Virginia upon this subject, and
              if gentlemen here will listen to me for a few minutes to-morrow morning, in the remarks that I shall make in all kindness to every one, they will understand precisely the position occupied by us in the West.
            </p>
            <p className="p-in-sp">
              A crisis has arisen in which it may be necessary that the people of the State should be united, and should act harmoniously. We ought to be united. We ought to be harmonious. We ought to be one in interest and one in determination. I hope gentlemen from the East will listen to what we have to say and I am sure they will concede to us our rights. I will now yield the floor with the
              purpose of resuming it in the morning.
            </p>
            <div className="section" id="vsc1965.v3.2.1.1">
              <h3><span className="headingNumber">1.1.1. </span><span className="head">FEDERAL RELATIONS</span></h3>
              <p>The time of half past 10 having arrived, the Convention, in pursuance of its standing order, resolved itself into Committee of the Whole [<span className="persName">Mr. SOUTHALL</span> in the Chair] , and resumed the consideration of the report of the Committee on Federal Relations, the question being on the third resolution, as follows :</p>
              <p>
                "3d. The choice of functionaries of a common Government established for the common good, for the reason that they entertain opinions and avow purposes hostile to the institutions of some of the States, necessarily excludes the people of one section from participation in the administration of Government, subjects the weaker to the
                <PageNumber num={18} /> domination of the stronger section, leads to abuse, and is incompatible with the safety of those whose interests are imperilled; the formation, therefore, of geographical or sectional parties in respect to Federal politics is contrary to the principles on which our system rests, and tends to its overthrow."
              </p>
            </div>
            <div className="section" id="vsc1965.v3.2.1.2">
              <h3><span className="headingNumber">1.1.2. </span><span className="head">PERSONAL EXPLANATION</span></h3>
              <div className="speaker" id="sp1877"><span className="persName">Mr. FISHER</span>—</div>
              <p className="p-in-sp">With the permission of the gentleman from Middlesex [<span className="persName">Mr. MONTAGUE</span>] , who is entitled to the floor, I desire to correct, in some particulars, the report of some remarks which I submitted on Thursday last. I would not complain of this report, if, in certain respects, it made me speak respectable nonsense, but inasmuch as I am made to speak not even that, I cannot allow it to go farther to the world as emanating from me.</p>
              <p className="p-in-sp">In the afternoon session on Thursday last, I offered an amendment to the report of the Committee of Twenty-one. As it is reported it reads thus :</p>
              <p className="p-in-sp">
                "I propose to amend it by striking out after the word 'instrument' the words: 'the people of each State agreed to associate with the people of the other States, upon a footing of exact equality;' and inserting, in lieu thereof, the following: 'each State acceded as a State and as an integral party with its co-States, forming as to itself the other party ;' so that it will read:
              </p>
              <p className="p-in-sp">"That the States which composed the United States of America, when the Federal Constitution was formed, were independent sovereignties, and in adopting that instrument, each State acceded," &amp;c.</p>
              <p className="p-in-sp">
                Now, sir, I made a quotation in the amendment which I offered, from the resolution which was drafted by Mr. Jefferson and presented by Mr. Breckinridge to the Kentucky Legislature for adoption, in 1798, and if I could have been guilty of writing such nonsense, Mr. Jefferson never was. The amendment I offered was, to insert after the words "this instrument," the following words :
                "Each State acceded as a State, and is an integral party, its co-States forming as to itself, the other party."
              </p>
              <p className="p-in-sp">If this amendment of mine had appeared upon the journal, I would not ask that the report of it and of my remarks in reference to it, which appear in the "Enquirer," should be corrected ; but it will no where appear except in the columns of that paper.</p>
              <p className="p-in-sp">
                I stated, sir, in my remarks, that the right of secession was not derived from the Constitution, and could not be termed strictly a legal <PageNumber num={19} /> right, but that it was one over and above the Constitution—a right that the States had never parted with, and that it still resided with them. I urged that it was one of the same class of rights
                which are exercised by each and every one of the sovereign powers of the earth, in forming treaties which they might abrogate; and that, although they might be held responsible to the contracting parties for a refusal to respect or a neglect to obey the injunctions of the treaty, yet, that the power, the sovereign power to abrogate, resided in each of the parties, and could not be
                controlled by the other, although it might involve the party refusing to obey the treaty in war with its co-contracting party.
              </p>
              <p className="p-in-sp">
                My idea upon this point is presented in this report altogether different from the manner in which I endeavored to present it to the committee. I stated that this amendment which I then offered would not impair the beauty and the symmetry of the affirmations contained in the first section of the report of the committee of twenty-one. Instead of that I am reported as saying: "This
                amendment will add to the beauty, the symmetry, and the proportion of the first section," &amp;c.
              </p>
              <p className="p-in-sp">Then, again, in the few remarks, I submitted in reply to the gentleman from Fauquier [<span className="persName">Mr. SCOTT</span>]
                , I first remarked that I desired to read an extract from a letter from Mr. Madison to Mr. Everett in 1830. And I supplied the reporter with the letter itself. Yet no information is given from whence that extract is derived, and it might be inferred by the public that I had spoken of some private letter, as the gentleman from Fauquier did, and which I stated I had heard of but had
                never seen. The quotation from Mr. Madison is correct as it appears, but it does not appear as coming from a letter of Mr. Madison to Mr. Everett.
              </p>
              <p className="p-in-sp">But, sir, here is what is not respectable nonsense :</p>
              <p className="p-in-sp">"In all the twenty-eight pages of the revised Code, what does my friend find? that the Federal Government, acting for and on behalf of their constituents, who are the people, ratified the Constitution of the United States, and declared that it shall be binding upon the people of the State."</p>
              <p className="p-in-sp">
                Sir, I not only never uttered any such a confused idea upon a subject of which I profess to have some comprehension, but I never entertained such an opinion as in this declaration I am reported to have made. I asked the gentleman from Fauquier if on page 28 (not in all the pages, for I have some knowledge of that Code) , he could find that the Virginia Convention had undertaken to
                declare that <PageNumber num={20} /> they, on behalf of themselves and the people of the other States of the Union, thereby ratified and confirmed the Constitution as presented by the Federal Convention of 1787. I argued that the Virginia Convention undertook to ratify that Constitution in the name and on behalf of the people of Virginia, but did not
                undertake to declare that in that ratification, in any sense, they were associating themselves with all the other States composing the United States.
              </p>
              <p className="p-in-sp">
                Now, sir, there are other mistakes in this report, as there are in previous reports of remarks I have submitted, but they are of a character that most readers would correct for themselves. I am very sure that the reporter did not design to misrepresent me, but either my manner or my delivery was so unfortunate as not to enable him even to catch the idea that I was endeavoring to
                advance before the Committee; and, as suggested by my friend here, there was a great deal of confusion at the time, and that it was not unnatural that the reporter should misunderstand the idea I was advancing, as well as the language I was using.
              </p>
              <div className="speaker" id="sp1878"><span className="persName">Mr. MONTAGUE</span> resumed as follows :</div>
              <p className="p-in-sp">
                Mr. Chairman when I yielded the floor yesterday I was about to remark, that the people of Virginia had caused this Convention to assemble for the purpose of taking into consideration the great fact, whether Virginia should dissolve her connection with the Federal Government or not. As I am one of the representatives upon this floor, as well as one of the people of Virginia who
                believe that she or any other State in this Union, for cause to be judged of by themselves, have the right to dissolve their connection with the Federal Government, a decent regard for public opinion, and a proper appreciation of my own self respect, after what has occurred here renders it, in my judgment, imperative that something should be said in favor of this great right of
                secession. Only one gentleman, so far as I remember, had attempted during the progress of our deliberations to make an argument against this right. I allude to the gentleman from Harrison
                [<span className="persName">Mr. CARLILE</span>] . Other gentlemen have sneered at it. The venerable gentleman from Rockbridge [Mr. MooRE] , denounced it as ridiculous and absurd; my friend from Bedford [<span className="persName">Mr. GOGGIN</span>] , sneered at it; the gentleman from Monongalia [<span className="persName">Mr. WILLEY</span>] , referred to it as ridiculous; the gentleman from Prince George, who addressed you on Friday last [<span className="persName">Mr. RIVES</span>] , denounced in open and bold terms all of us who entertained that opinion, and who were willing to act upon it as traitors, and inquired if there was not such a thing as hemp for us.</p>
              <PageNumber num={21} />
              <p className="p-in-sp">
                He went further, and imputed unworthy motives to us. He said that our desire to exercise this right was based on motives of self-promotion in the Southern Confederacy. Thus, sir, while we have been denounced as traitors, while our doctrine has been denounced as ridiculous, while the doctrine of the fathers of the Republic is held up to the scorn of the world, I trust I shall be
                excused if I attempt to put this great doctrine on a foundation from which I think it cannot be overthrown.
              </p>
              <p className="p-in-sp">
                I trust, Mr. Chairman, that if, in the ardor of my temperament. I shall seem to manifest something like harshness and unkindness, gentlemen who differ with me will excuse me, for I assure them that I desire to be perfectly courteous and respectful to all. I have not an unkind feeling against a member of this Convention, and why should I? We are all Virginians. We are all seeking to
                attain the same great object. We have all been reared under the same great principles of constitutional liberty.
              </p>
              <p className="p-in-sp">
                Although we may differ here for a time, I have the consolation to believe that the day is not far distant when all of us, the whole people of Virginia, will rally together as a band of brothers in defence of the time-honored rights of Virginia, and when Virginia does that the world will see that she is still the friend of liberty, and the uncompromising foe of tyranny and oppression.
              </p>
              <p className="p-in-sp">
                Mr. President, one of the wisest men that Virginia or this country ever produced—I mean the venerable Mason, the author of your Bill of Rights—declared that "the perpetuity of our institutions depended on a firm adherence to justice, moderation, temperance, frugality, and by a frequent recurrence to fundamental principles. I propose to recur briefly to some fundamental principles of
                our government, and see whether, by a proper application of those principles, to the crisis in which we stand, there is not a course of safety and of escape from the dangers which threaten to overwhelm us.
              </p>
              <p className="p-in-sp">
                I hope the Committee will bear with me if I be somewhat tedious. What sort of Government is this we have? Is it a Consolidated Government? Is it a Central Empire, governed and controlled in all its departments by a mere majority of the whole people of the country? Or on the other hand, is it a compact between Sovereign States—a government of defined and limited powers, fixed and
                prescribed for certain well defined purposes? On the answer to this question depends, in my judgment, the proper solution of the great question of the doctrine of the right of secession.
              </p>
              <PageNumber num={22} />
              <p className="p-in-sp">The gentleman from Harrison [<span className="persName">Mr. CARLILE</span>]
                , said that this doctrine of secession was a doctrine of modern origin—that it emanated from New England. I think I understood him to say that it emanated from the Hartford Convention, and was endorsed by that Convention. While I do not endorse the doctrine put forth in the Hartford Convention, yet I have read their resolutions with care, and I find no such doctrine laid down there.
                I take issue with the gentleman. I say that so far as this Commonwealth is concerned, and so far as these United States are concerned it had its origin in Virginia. Virginia was the first State on this Continent that ever exercised the great doctrine of secession. When did Virginia do that? On the 15th of May, 1776—prior to your Declaration of Independence. She did not go about to
                consult border States, to see what they would do. In the plenitude of her sovereignty, and in the depth of her patriotism, she severed her connection with the British Government, thereby exercising the great right of secession. As was eloquently said by my departed and venerated preceptor, the late Judge Beverley Tucker, "prior to your Declaration of Independence, her voice of
                defiance was always ringing in the tyrant's ears; hers was the cry that summoned him to the strife : hers was the shout that invited his vengeance."
              </p>
              <p className="p-in-sp">
                It is a Virginian doctrine. I shall show, before I get through, that one of the greatest men of the country, differing with him as we in Virginia have always done on many great cardinal principles—a man whose ability the world has acknowledged and paid homage to John Quincy Adams—referred to that act of separation from England as secession, and quoted it as a precedent in favor of
                States resorting to the same means of getting rid of the Federal Government.
              </p>
              <p className="p-in-sp">
                That, sir, was the first act of secession. What was the next? I hope the Committee will bear with me when I show that old Virginia, who adopted the first act of secession on this continent, was the first to take the move to bring about another great measure of secession. In January 1786, Virginia, by her Legislature proposed a Convention of Delegates from the States for the purpose
                of augmenting the powers of Congress in regard to the regulation of commerce. It was held in Annapolis, only five States being represented ; and that Convention recommended that all the States should appoint commissioners to meet at Philadelphia, on the second Monday of May, 1787, to take into consideration the situation of the country, &amp;c. And on the 21st of Febr., 1787,
                Congress passed a resolution calling a Convention—for what purpose? For the sole and express purpose of revising the articles of Confederation and reporting to Congress and the several <PageNumber num={23} /> Legislatures such alterations and provisions therein as should be agreed to.
              </p>
              <p className="p-in-sp">
                That was the beginning of your present Confederation. That Convention met and formed our Constitution. That Constitution was referred to the people of the States for ratification or rejection by Conventions of the people. I have heard a good deal said against the people of the South, that they are usurping powers, that they are ratifying a Federal Constitution through their State
                Convention, without referring back the question to the people. Sir, they are following our example. They are following the example of the men of 1787 and 1788. In no single instance was the Federal Constitution ever voted upon by the people after it was ratified by the Convention of the States.
              </p>
              <div className="speaker" id="sp1879"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">Do I understand my friend from Middlesex to say that the Constitution of the Confederate States, adopted by the Montgomery Congress, has been referred to Conventions of the people of the several States, for ratification or rejection, as was the Constitution of the United States?</p>
              <div className="speaker" id="sp1880"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">Substantially the same thing, sir.</p>
              <div className="speaker" id="sp1881"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">I understand that that Convention expressly refused to refer to the people of the several States the Constitution which they have adopted.</p>
              <div className="speaker" id="sp1882"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">Yes, because they had Conventions in session at the same time, and it was idle, and absurd, and nonsensical, to be calling other Conventions, to do what the Conventions already in session had complete and adequate powers to do. It was substantially the same thing as our forefathers did.</p>
              <p className="p-in-sp">
                Now, what sort of government is this? Is it a compact between sovereign parties? I will not stop to argue that question. Just take up the celebrated report of Mr. Madison, which has been the political law of Virginia for sixty years, and which is quoted as authority here by all sides, and you will see that it is declared to be a compact between sovereign States. If it is a compact,
                who is to judge of its infraction? We all know what Virginia has said on that subject. She has declared, and we have all acquiesced in it, that being a compact between sovereign States, the States who are parties to it can alone judge of its infraction, and of the mode and manner of redress. That is the Virginia doctrine. That was the doctrine of the whole country at the time the
                Constitution was pending before' the States for ratifica tion. I read from the "Federalist," page 144, an article written, not by <PageNumber num={24} /> Mr. Madison, not by any Virginian, but by one of the great intellects of the North, Mr. Hamilton :
              </p>
              <p className="p-in-sp">
                "But I may again be asked who is to judge of the necessity and propriety of the laws to be passed for executing the powers of the Union? I answer first that this question arises as well and as fully upon the simple grant of those powers as upon the declaratory clause ; and I answer, in the second place, that the National Government, like every other, must judge in the first instance
                of the proper exercise of its powers, and its constituents in the last."
              </p>
              <p className="p-in-sp">
                "Its constituents in the last." Who are the constituents of the Federal Government? Why, the sovereign States. Here, then, I have the political law of Virginia, agreeing with the law, as laid down by the great political philosopher of that day at the North, that this is a compact between sovereign States, and that the General Government can judge of the extent of its powers, and in
                the last resort, the constituents—the States themselves—are to judge of it.
              </p>
              <p className="p-in-sp">
                I might here rest my argument. If you admit that this is a compact between sovereign parties ( States) , and that these parties shall judge whether the compact has been faithfully and honestly fulfilled, I might rest, because on that doctrine any State can judge for itself ; it can select any mode of redress that it may think proper. But, says my old and gallant friend—I beg his
                pardon, my young friend—from Powhatan
                [<span className="persName">Mr. SCOTT</span>] , this doctrine cannot be so—that this is a government of the people of the United States; following up the idea put forth by Mr. Lincoln, and taken up by my friend from Augusta [<span className="persName">Mr. BALDWIN</span>]
                , that this is a government of a majority, and not of minority. He says that we are a government of the people of the whole United States, and he quotes the preamble to the Constitution for the purpose of showing that by the adoption of the Constitution, the States had merged their individuality and sovereignty in the naticnal government at Washington. I wish, just here, to put the
                committee in possession of a fact on this subject, the presentation of which scatters to the four winds of Heaven all ideas about this being a government of one people, because the preamble declares, "we, the people of the United States." Suppose that preamble had declared, as that of the South does, that "we, the people of the separate States," would that not have preserved the
                individuality and sovereignty of each State? Now, let us see what the preamble did say. The history of the preamble is this : On the 6th of August, 1787, a committee, appointed for the purpose, reported the first draft of the Constitution. The preamble was in these words : "We, the people of the States of New Hampshire,
                <PageNumber num={25} /> Massachusetts, Rhode Island and Providence Plantation, Connecticut, New York, New Jersey, Pennsylvania, Delaware, Maryland, Virginia, North Carolina, South Carolina and Georgia do ordain and establish," &amp;c. (1 Ell. Debates, 255.)
              </p>
              <p className="p-in-sp">On the next day this preamble was unanimously adopted, and you will see it distinctly preserves the sovereignty of the States by naming each.</p>
              <p className="p-in-sp">
                Then, sir, and after they had adopted the preamble, to wit : on the 8th of September, 1787, they appointed a committee of five simply "to revise the style of and arrange the articles agreed to by the House." (Id. 324.) They gave to that Committee no power to alter or change the meaning, but simply "to revise the style and arrange the articles ;" that Committee made the report; they
                altered the preamble to what it now is—"we, the people," leaving out the States, and for these two reasons—first that the Constitution itself provided upon its face that after it was adopted and ratified by nine States, the Government should go into operation, the other four might never adopt it, and if the style had not been corrected there would have been four States named in the
                preamble not members of the confederation. This would have been absurd. Second, the Constitution provided that the Congress of the United States might admit new States, and if the preamble had not been altered you would afterwards have had fourteen or fifteen States in the confederation that were not named in the preamble. This is the history of the preamble.
              </p>
              <p className="p-in-sp">Here then this fact shows that the very Convention that framed the Constitution recognised and acknowledged, in the history of the preamble the separate and sovereign existence of each State.</p>
              <p className="p-in-sp">
                But, sir, let us look a little further into this matter. Mr. Webster, in his celebrated debate with Calhoun in 1832, as well as Judge Story in his commentaries upon the Constitution, as also Judge Kent, have all admitted that the old articles of confederation were but a compact of government between the States. Mr. Webster admitted that, if the scheme presented by Mr. Paterson had
                been adopted, it would have been merely a governmental compact. They all admit that the old articles of confederation were a mere compact between States. Mr. Webster said, in the debate with Mr. Calhoun in 1833, that if our government was "a compact or league, States may secede." He said "the system was not a compact between States in their sovereign capacity, but a government
                proper." And is it a compact between sovereign States? The Constitution, in the 7th article, declares "the ratification of the Conventions of nine States shall be sufficient for the <PageNumber num={26} /> establishment of this Constitution between the States so ratifying the same." What is this but a compact, a bargain between the States? The plan of
                the Constitution submitted by Mr. Paterson, Mr. Webster said would have been a compact, had it been adopted. Now, if gentlemen will examine that plan, they will see that it is very similar to our Constitution.
              </p>
              <p className="p-in-sp">But, as before said, all admit that the old articles of confederation were a compact between sovereign States. Now, sir, our present Constitution is but an amplification and enlargement of the old articles of confederation. No new principles are introduced. It is only an expansion of the old.</p>
              <p className="p-in-sp">Let us see what Mr. Madison said. In the Federalist No. 40, pages 181-2, in examining the question whether the Convention were authorized to frame and propose the Constitution, he said :</p>
              <p className="p-in-sp">
                "No stress, it is presumed, will, in this case, be laid on the title: a change of that could never be deemed an exercise of ungranted power. Alterations in the body of the instrument are expressly authorized : new provisions therein are also expressly authorized. Here then is a power to change the title; to insert new articles; to alter old ones. * * * Will it be said, that the
                fundamental principles of the Confederation were not within the purview of the Convention, and ought not to have been varied? I ask, what are these principles? Do they require, that in the establishment of the Constitution, the States should be regarded as distinct and independent sovereigns? They are so regarded by the Constitution proposed. Do they require that the members of the
                Government should derive their appointment from the Legislatures—not from the people of the States? One branch of the new Government is to be appointed by these Legislatures; and under the Confederation, the delegates to Congress may all be appointed immediately by the people; and in two States are actually so appointed. Do they require that the powers of the Government should act on
                the States, and not immediately on individuals? In some instances, as has been shown, the powers of the new Government will act on the States in their collective characters. In some instances, also, those of the existing Government act immediately on individuals. In cases of capture, of piracy, of the post office, of coins, weights and measures, of trade with the Indians, of claims
                under grants of land by different States; and, above all, in the case of trials by court-martial in the army or navy, by which death may be inflicted without the intervention of a jury, or even of a civil magistrate.
              </p>
              <p className="p-in-sp">
                "In all cases the power of the confederation operates immediately <PageNumber num={27} /> on the persons and interests of individual citizens. Do these fundamental principles require, particularly, that no tax should be levied, without the intermediate agency of the States? The confederation itself, authorizes a direct tax, to a certain extent, on the
                post-office. The power of coinage has been so construed by Congress, as to levy a tribute immediately for that source also. But pretermitting these instances, was it not an acknowledged object of the Convention, and the universal expectation of the people, that the regulation of trade should be submitted to the General Government, in such a form as would render it an immediate source
                of general revenue? Has not Congress repeatedly recommended this measure, as not inconsistent with the fundamental principles of the confederation? Had not every State, but one; had not New York herself, so far complied with the plan of Congress, as to recognize the principle of the innovation? Do these principles, in fine, require that the powers of the General Government should be
                limited, and that, beyond this limit, the States should be left in possession of their sovereignty and independence? We have seen, that in the new government, as in the old. the general powers are limited; and that the States, in all unenumerated cases, are left in the enjoyment of their sovereign and independent jurisdiction.
              </p>
              <p className="p-in-sp">
                "Truth is, that the great principles of the Constitution proposed by the Convention, may be considered less, as absolutely new, than as the expansion of principles which are found in the Articles of Confederation." And on page 216, he says : "If the new Constitution be examined with accuracy and candor, it will be found that the change which it proposes, consists much less in the
                addition of new powers to the Union, than in the invigoration of its original powers."
              </p>
              <p className="p-in-sp">That is what Mr. Madison said. He shows—if he is to be relied upon, and I know of no higher authority—that your present Constitution is but an expansion of the principles of the articles of confederation; and in the same book shows how the articles of confederation could be broken up. Let us hear him on that point :</p>
              <p className="p-in-sp">
                "On what principle," he asks, "the confederation, which stands in the solemn form of a compact among the States, can be superseded without the unanimous consent of the parties to it? This question is answered at once by recurring to the absolute necessity of the case ; to the great principle of self-preservation; to the transcendent law of nature and of nature's God, which declares
                that the safety and happiness of society are the objects at which all political institutions aim, and to which all such institutions must be sacrificed. Perhaps, also, <PageNumber num={28} /> an answer may be found without searching beyond the principles of the compact itself. It has been heretofore noted among the defects of the confederation, that in
                many of the States, it had received no higher sanction than a mere legislative ratification. The principle of reciprocality seems to require, that its obligation on the other States should be reduced to the same standard. A compact between independent sovereigns, founded on acts of legislative authority, can pretend to no higher validity than a league or treaty between the parties.
                It is an established doctrine on the subject of treaties, that all the articles are mutually conditions of each other; that a breach of any one article is a breach of the whole treaty; and that a breach committed by either of the parties, absolves the others, and authorizes them, if they please, to pronounce the compact violated and void." (Federalist, page 205, No. 43.)
              </p>
              <p className="p-in-sp">
                From these extracts it will be seen that Mr. Madison states, that the States are sovereign and independent powers, and that in all unenumerated cases the States are left in the enjoyment of their sovereign independence; and after throwing the similarity in principle between the old articles of Confederation and our present Constitution, he shows how the old articles of Confederation
                could be superseded and broken up. Why the old articles of Confederation declared that the Union which they formed should be perpetual, and that it could not be altered or abolished without the consent of all the States. Yet the fact is, that nine States, upon the principles laid down by Mr. Madison above, did secede from that Union and form another--Virginia advised and started it,
                and this is her second act of secession.
              </p>
              <p className="p-in-sp">
                Now, sir, won't that reasoning apply here? If the existing Government is based upon the same principles as the old articles of Confederation, and if the States did break up these articles, does it not follow that the same right exists now, to break up the present Confederation? But notwithstanding all this, we are now told here, by able and distinguished men, that because we
                voluntarily assented to that Government, our hands are bound to it with hooks of steel, for ever; and if we resist, we are to be regarded by all the world as rebels and traitors.
              </p>
              <p className="p-in-sp">
                Now, Mr. Chairman, with these general views on this subject, I come down to the action of the Virginia Convention that ratified your Constitution; and I say, if any gentleman will take up these debates and read them carefully, he will see that it was a conceded fact, running through the whole proceedings of secession or withdrawal,
                <PageNumber num={29} /> that this great right belonged to the States, and that they could exercise it whenever they thought proper.
              </p>
              <p className="p-in-sp">Sir, you know, and this Convention knows, that Patrick Henry, George Mason, and Wm. Grayson, and other able and distinguished men—your venerated father, sir, among the number [referring to Ex-President TYLER]
                warred against the ratification of this Constitution to the bitter end—warred against it upon the ground that it would concede a fundamental principle that would over ride State sovereignty and State rights, and consolidate everything into one Government, to be regulated and controlled by a numerical majority. How did the great Edmund Pendleton meet that? How did Edmund Randolph meet
                it? How did Col. Nicholas meet it? If gentlemen will give me their attention, I will show, from the debates of this Convention, how these gentlemen met that objection. I will now read from Elliott's debates, vol. 3, page 37.
              </p>
              <p className="p-in-sp">Edmund Pendleton, the great lawyer and patriot, in reply to Patrick Henry said :</p>
              <p className="p-in-sp">
                "Where is the cause of alarm? We, the people, possessing all power, form a Government, such as we think will secure happiness; and suppose in adopting this plan, we should be mistaken in the end; where is the cause of alarm on that quarter? In the same plan we point out an easy and quiet method of reforming what may be found amiss. No, but, say gentlemen, we have put the introduction
                of that method in the hands of our servants, who will interrupt it from motives of self-interest. What then? We will resist, did my friend say? conveying an idea of force. Who shall dare to resist the people? No, we will assemble in Convention; wholly recall our delegated powers, or reform them so as to prevent such abuse, and punish those servants who have perverted powers, designed
                for our happiness, to their own emolument."
              </p>
              <p className="p-in-sp">"Wholly recall our delegated powers." What is this but secession? And was it not held up by Pendleton as a remedy to the States, when the general powers had been perverted to their injury?</p>
              <p className="p-in-sp">
                That is what our fathers understood. Sir, it was the great remedy of resistance which they laid down when they formed the Federal Government : and yet you now hear gentlemen denying the sovereignty of the States; and you have now disseminated the new doctrine that it is treason in me or in any body to preach the doctrine of our venerated ancestors. That is what Judge Pendleton said
                in that debate. Now, sir, let us hear what Mr. Madison said. I do not care what you may quote about Mr. Madison's writings <PageNumber num={30} /> thereafter. You may quote what he said in his letters about revolutions. He meant revolution between Sovereign States, and not revolution between the State and the General Government. I will read what he said
                upon this subject, when he was trying by his reason and argument, logic and information to persuade the people of Virginia to ratify the Federal Constitution. In making his speech in answer to Patrick Henry who had made a violent onslaught upon that clause of the Constitution which gives to the President and Congress of the United States the power over the militia of the States, Mr.
                Madison said :
              </p>
              <p className="p-in-sp">"An observation fell from a gentleman on the same side with myself [alluding to judge Pendleton's words, before quoted] which deserves to be attended to. If we be dissatisfied with the national government, if we should choose to renounce it, this is an additional safeguard to our defence." (3 Ell. Debates, 414-15.)</p>
              <p className="p-in-sp">
                That is what he said. Here I show you that Mr. Madison, the great Ajax who carried the Constitution through the Virginia Convention, and pledged his honor and his character to the people of Virginia, if all other efforts should fail to get redress of our grievances, that we could at last renounce the Federal Government, which right he declared was "an additional safeguard to our
                defence." Well, sir, Virginia reserved the right, whenever those powers which she had granted were perverted to her injury and oppression, to resume them. Now, let us see what the articles of ratification declare :
              </p>
              <p className="p-in-sp">
                "We, the delegates of the people of Virginia, &amp;c., do, in the name and in behalf of the people of Virginia, declare and make known, that the powers granted under the Constitution, being derived from the people of the United States, may be resumed by them whensoever the same shall be perverted to their injury or oppression, and that every power not granted thereby remains with
                them, and at their will."
              </p>
              <p className="p-in-sp">But, say gentlemen, that is the people of the United States, who must resume. I will let the President of our Court of Appeals, Judge Allen, answer this :</p>
              <p className="p-in-sp">"Our people in Convention, by their act of ratification, declared and made known that the powers granted under the Constitution, being derived from the people of the United States, may be resumed by them whenever they shall be perverted to their injury and oppression.</p>
              <p className="p-in-sp">
                "From what people were these powers derived? Confessedly, from the people of each State, acting for themselves. By whom were they to' be resumed or taken back? By the people of the State who were <PageNumber num={31} /> then granting them away. Who were to determine whether the powers granted had been perverted to their injury or oppression? Not the
                whole people of the United States, for there could be no oppression of the whole with their own consent ; and it could not have entered into the conception of the Convention that the powers granted could not be resumed until the oppressor himself united in such resumption."
              </p>
              <p className="p-in-sp">Gov. Randolph, who wrote the article of ratification, said before it was proposed: "If it be not considered too early, as ratification has not yet been spoken of, I beg to speak of it. If I did believe, with the honorable gentleman, that all power not expressly retained was given up by the people, I would detest this government.</p>
              <p className="p-in-sp">
                "But I never thought so; nor do I now. If, in the ratification, we put words to this purpose, 'And that all authority not given is retained by the people, and may be resumed when perverted to their oppression; and that no right can be cancelled, abridged, or restrained, by the Congress, or any officer of the United States'—I say if we do this, I conceive that, as this style of
                ratification would manifest the principles on which Virginia adopted it, we should be at liberty to consider as a violation of the Constitution, every exercise of a power not expressly delegated therein. I see no objection to this." (3 Ell. Debates, 576.)
              </p>
              <p className="p-in-sp">Col. Nicholas, after the articles of ratification had been proposed, said, as follows :</p>
              <p className="p-in-sp">
                "Mr. Nicholas contended that the language of the proposed ratification would secure everything which gentlemen desired, as it declared that all powers vested in the Constitution were derived from the people, and might be resumed by them whensoever they should be perverted to their injury and oppression; and that every power not granted thereby remained at their will. No danger
                whatever could arise; for (says he) these expressions will become a part of the contract. The Constitution cannot be binding on Virginia but with these conditions. If thirteen individuals are about to make a contract, and one agrees to it, but, at the same time declares that he understands its meaning, signification, and intent to be (what the words of the contract plainly and
                obviously denote) that it is not to be construed so as to impose any supplementary condition on him, and that he is to be exonerated from it whensoever any such imposition shall be attempted, I ask whether, in this case, these conditions on which he has assented to it would not be binding on the other twelve? In like manner these conditions will be binding on Congress. They can
                exercise no power that is not expressly granted them." (3 Ell. Debates, 625-626.)
              </p>
              <p className="p-in-sp">
                Both Gov. Randolph and Col. Nicholas were strong advocates of the <PageNumber num={32} /> ratification of the Constitution, and you have here their opinion as to the meaning of Virginia's articles of ratification. They say it would be the condition upon which Virginia would enter the confederation, and would become "a part of the contract." Can anything
                be plainer? I have now shown what Madison, Pendleton, Randolph and Nicholas said in the Virginia Convention on this subject, and I hope I shall hear no more about treason and sedition—a modern, new fangled idea from New England. Consult the history of your country, and see what the authors of liberty in old Virginia said upon this subject before you talk to me about these new-fangled
                ideas of New England. The right to secede is no New England doctrine. It is Virginia's own doctrine, and by it I hope all true sons of Virginia will stand. It is one of the blessed doctrines of our glorious old mother, Virginia; it is one of the doctrines which had its origin in that high spirit of liberty which developed itself in Williamsburg in 1776, and which finally settled down
                in triumph and glory amid the applause and acclaims of the whole world, around the sacred monuments now mouldering upon the banks of the York. Here is where this doctrine came from; and, sir, if I am to be hung, if I am to be denounced as a traitor, why, in God's name, let it be for standing up and maintaining, in all their vigor and purity, the doctrines of the fathers of Virginia's
                immortal principles of civil liberty.
              </p>
              <div className="stage it">[Applause.]</div>
              <div className="speaker" id="sp1883"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Chair must order the galleries to be cleared. The gentleman will suspend his remarks until the gallery is cleared.</p>
              <div className="speaker" id="sp1884"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I will inquire of the Chair the effect of clearing the gallery—how long does the order last?</p>
              <div className="speaker" id="sp1885"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">There is no limit to it.</p>
              <div className="speaker" id="sp1886"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Will it remain cleared all the time.</p>
              <div className="speaker" id="sp1887"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Chair cannot answer how long the order will last. The object is to preserve the peace and decorum of the body. To clear the galleries and allow them to return immediately would be interrupting greatly the progress of our business.</p>
              <div className="speaker" id="sp1888"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I wish merely to remark, that unquestionably I saw some citizens there whom I can vouch for as not being guilty of disorder.</p>
              <div className="speaker" id="sp1889"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Chair has no doubt of that. That is the great difficulty and makes the Chair regret that he has to confound the innocent with the guilty.</p>
              <div className="speaker" id="sp1890"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I believe there were gentlemen upon the floor who were guilty of the disorder as well.</p>
              <PageNumber num={33} />
              <div className="speaker" id="sp1891"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Chair did not hear any on the floor, nor did he believe that there was any in the lobby.</p>
              <div className="speaker" id="sp1892"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">It was all around. I think it very hard that the innocent should suffer.</p>
              <div className="speaker" id="sp1893"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">Certainly.</p>
              <div className="speaker" id="sp1894"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">May they return immediately?</p>
              <div className="stage it">[Laughter.]</div>
              <div className="speaker" id="sp1895"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">After a while.</p>
              <div className="speaker" id="sp1896"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Return after a while. How long will that be?</p>
              <div className="stage it">[Laughter.]</div>
              <div className="speaker" id="sp1897"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The gentleman from Middlesex [<span className="persName">Mr. MONTAGUE</span>] , will please proceed with his remarks.</p>
              <div className="speaker" id="sp1898"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">I believe these little ebullitions which have occured must occur in a time like this.</p>
              <div className="speaker" id="sp1899"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Chair deemed it his imperative duty to enforce the order to clear the galleries.</p>
              <div className="speaker" id="sp1900"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">I do not complain of the Chair. It is hard for men to restrain their feelings in a time like this. I assure gentlemen and the Chair that I have no desire to excite applause here. I did not come here with any such purpose. I came here to present my views to the heads of the people, and not to their feet.</p>
              <p className="p-in-sp">
                I have shown that Virginia when she joined the Federal Constitution, reserved the power to withdraw whenever she might think proper. Having shown that that was the understanding of the men who ratified the Constitution in Virginia, let us see what they did in New York. In the ratification of the Constitution by New York, yol will find this : "That the powers of government may be
                re-assumed by the people whensoever it shall become necessary to their happiness ; that every power, jurisdiction, and right, which is not by the said Constitution clearly delegated to the Congress of the United States, or the departments of the government thereof remains to the people of the several States, or to their respective State governments, to whom they may have granted the
                same." (1 Ell. Debates, 327.)
              </p>
              <p className="p-in-sp">Here, then, the Committee will see that New York reserved the right to secede. Little Rhode Island, about whom we hear so much here, declared in the third section of her articles of ratification, "That the powers of government may be re-assumed by the people whensoever it shall become necessary to their happiness." (1 Ell. Debates, 334.)</p>
              <p className="p-in-sp">
                Here, then, you have three States going into the confederation upon a certain condition? What condition? Why, that they reserved the right to withdraw whenever in their judgment their happiness and safety require it. And just here I lay down a great principle, not only <PageNumber num={34} /> of municipal, but of political law,—a principle that even as
                humble and poor a lawyer as I am, I am not afraid to assert even in the presence of the great lawyers here. What is the principle?
              </p>
              <p className="p-in-sp">
                It is this : "That, in case of a contract between several partners, if the entrance of one on condition be admitted into the partnership, the condition enures to the benefit of all the partners." I am not afraid to assert that as a principle of municipal and political law of general and universal application. If, then, you admit the authority of this plain principle of law—and it
                cannot be denied—then having accepted these States into the political partnership, with the condition annexed that they had a right to withdraw—does it not thereby follow that the condition enures to the benefit of all the States, and that all the States, or any one State, "must have the right of withdrawing"?
              </p>
              <p className="p-in-sp">I will proceed, as best I can, to present another view of this subject. I throw out no challenge to anybody here. I did not come here with that purpose; but if any gentleman here shall do me the honor to notice my remarks, I should be glad to have him confute successfully, if he can.</p>
              <p className="p-in-sp">
                I say that the 10th amendment of the Federal Constitution expressly makes it a great constitutional right. Sir, Virginia was the author, in part, of the 10th amendment. Virginia had just seceded from two Governments. She had just ratified another Government, with the express understanding that she reserved to herself, as I have shown you, the right to secede, if necessity should make
                it proper for her to do so. She was the author of it. What is the great principle of construction that the 10th amendment lays down?
              </p>
              <p className="p-in-sp">The 9th amendment of the Constitution declares that "the enumeration in the Constitution of certain rights, shall not be construed to deny or disparage others retained by the people."</p>
              <p className="p-in-sp">
                The 10th amendment declares that "the powers not delegated to the United States by the Constitution, nor prohibited by it to the States, are reserved to the States respectively, or to the people." Now, sir, we have seen that this right of secession was, at the time Virginia proposed this 10th amendment, an acknowledged and recognized right in this country. It was declared to be a
                right in the Virginia Convention. There is not one jot or tittle in your Constitution which declares that the power is conferred upon Congress to prevent a State from withdrawing. All powers not prohibited to the States are reserved to the States respectively. This was a power that had been exercised, and unless you can show that it is prohibited by the Constitution to the States, it
                can still be exercised. It was one of the rights reserved to the States, and your 10th amendment to the Constitution <PageNumber num={35} /> declares that if one of the reserved rights, it rests and remains with the people of the States, thereby making it a remedy within the power and pale of the Constitution itself. It is, therefore, a right under the
                Constitution.
              </p>
              <p className="p-in-sp">
                But, sir, let us go a little further; and I beg the attention of the Committee to the point I am now about to make. Your Committee declare that the States, when they formed the Federal Constitution, were sovereigns. Then, if they were independent sovereigns when they formed the confederation, they went into the Union voluntarily. Nobody will pretend that any force was resorted to by
                any power, of carrying the States into the Union.
              </p>
              <p className="p-in-sp">
                Now, sir, I ask, as it is admitted they went into the Union voluntarily, if there is anything in the Constitution which gives Congress or any department of the Federal Government the power to use force to keep them in? If there is, point it out. If any gentleman here shows me one word in the Constitution which gives Congress, or any department of the Federal Government, the power to
                keep a State in the Federal Union by force, I will yield the point. No, sir, it is not in the Constitution. You cannot find it there—it is not there. On the contrary, sir, in the Convention which framed the Constitution, they expressly refused to give Congress any such power over the States. What does the record say? Gov, Randolph's proposition proposed to grant to the General
                Government the power "to negative all laws contrary, in the opinion of the national Legislature, to the articles of the Union, or any treaty subsisting under the authority of the Union ; and to call forth the force of the Union against any member of the Union, failing to fulfill his duty under the articles thereof." This power was refused. Then came Mr. Charles Pinckney's plan, which
                proposed "that the Legislature of the United States should have the power to revise the laws of the several States that may be supposed to infringe upon the powers exclusively delegated by this Constitution to Congress, and to negative and annul such as do." This was refused.
              </p>
              <p className="p-in-sp">The next plan was submitted by Mr. Paterson, which provided, "if any State, or body of men in any State, shall oppose or prevent the carrying into execution such acts or treaties [of the Union]
                the federal executive shall be authorized to call forth the powers of the confederated States, or so much thereof as shall be necessary to enforce, or compel the obedience to such acts, or observance of such treaties." This, too, was refused. Then came Gen. Hamilton's plan, which declared, "all laws of the particular States, Contrary to the Constitution or laws of the United States,
                to be utterly void ; and, the <PageNumber num={36} /> better to prevent such laws being passed, the Governor or President of each State shall be appointed by the General Government, and shall have a negative on the laws about to be passed in the State of which he is Governor or President." This, too, was refused. Later, it was moved to grant power "to
                negative all laws passed by the several States, interfering, in the opinion of the Legislature, with the general harmony and interest of the Union, provided that two-thirds of the members of each house assent to the same." This also failed. See the different plans referred to in 1st Vol. of Ell. Debates.
              </p>
              <p className="p-in-sp">
                Thus you see from the journal of the Convention, that so far from being able to find any such thing in the Constitution of the United States, of the formation of that Constitution, itself shows that the Convention which framed it refused, in every instance, to grant any power to Congress to use force or coercion against a State in any form. Then, sir, you see that they went in
                voluntarily. Can the Federal Government at Washington resort to force to keep them in? Have they any authority to do that, sir? This Convention, in which we are now assembled, has the power to carry Virginia out, and to submit that question to the people to decide for themselves. Can any department of the Federal Government send any force here, under the sanction of the Constitution,
                to disperse this Convention, because it may do this. Show me where there is such power given in the Constitution and I will give up the whole question. It is not there. I think then I have conclusively shown you that if they went in voluntarily, as we all know they did, that after they are in, you cannot resort to force to keep them in; and your Committee of Twenty-one, whom you have
                endorsed by the resolution the other day declaring that each member should only have ten minutes to debate the amendments presented to their report, that committee which, by that vote, was pronounced to embody all the wisdom of this house, has declared in their report that the Federal Government has no power to subjugate these States, if they go out. When all this is admitted, is it
                not an argument which no man can meet that they must have the power to go out if they think proper. Let us see. The States went in the Union voluntarily. When in, you cannot resort to force to keep them in, and if they go out you cannot use force to bring them back—then does it not logically and irresistibly follow that they must have the power to go out if they see fit to exercise
                such power. The argument is a perfect syllogism. I ask gentlemen to meet it.
              </p>
              <p className="p-in-sp">Mr. Chairman, thus much upon that subject. Now let us hear what some of the greatest lights, intellectually and morally, that this <PageNumber num={37} /> or any other country has ever produced, have said upon this subject. I have nearly concluded my argument upon this branch of the subject, but since the gentleman from Harrison, [<span className="persName">Mr. CARLILE</span>]
                , in his argument rested his whole position upon the opinions of great men —Henry Clay, General Jackson and others—I will take the liberty of referring to what some of our great men have said. Let us hear what John Quincy Adams said. You, gentlemen—and I mean no disrespect to you, I have not an unkind feeling towards a member of this Convention but you Blue Light Federalists here (
                and some of you are as blue as indigo) , my friend from Kanawha
                [<span className="persName">Mr. SUMMERS</span>] , who spoke so eloquently the other day about the light of his section, did not exactly show it, but I could see his light was tinged with blue—you gentlemen, who belong to the Adams' school of politicians, hear what that great man, Mr. Adams, said upon this question, and then, if you think proper, denounce the doctrine as "absurd and ridiculous."</p>
              <p className="p-in-sp">Will my friend read the extract for me? It is from an address delivered before the New York Historical Society in 1839, at the jubilee of the Constitution.</p>
              <div className="speaker" id="sp1901"><span className="persName">Mr. HOLCOMBE</span>, of Albemarle, read the extract as follows :</div>
              <p className="p-in-sp">
                "Nations acknowledge no judge between them upon earth. And their Governments, from necessity, must, in their intercourse with each other, decide when the failure of one party to a contract to perform its obligations absolves the other from the reciprocal fulfillment of his own. But this last of earthly powers is not necessary to the freedom or independence of States connected
                together by the immediate action of the people, of whom they consist. To the people alone is there reserved, as well the dissolving as the constituent power, and that power can be exercised by them only under the tie of conscience, binding them to the retributive justice of Heaven.
              </p>
              <p className="p-in-sp">
                "With these qualifications, we may admit the same right as vested in the people of every State in the Union, with reference to the General Government, which was exercised by the people of the United Colonies with reference to the supreme head of the British Empire, of which they formed a part; and, under these limitations, have the people of each State in the Union a right to secede
                from the Confederated Union itself.
              </p>
              <p className="p-in-sp">
                "Thus stands the right. But the indissoluble link of union between the people of the several States of this confederated nation is, after all, not in the right, but in the heart. If the day should ever come (may Heaven avert it!) when the affections of the people of these States shall be alienated from each other; when the fraternal spirit
                <PageNumber num={38} /> shall give way to cold indifference, or collisions of interest shall fester into hatred, the bands of political association will not long hold together parties no longer attracted by the magnetism of conciliated interests and kindly sympathies; and far better will it be for the people of the disunited States to part in friendship
                from each other, than to be held together by constraint. Then will be the time for reverting to the precedent, which occurred at the formation and adoption of the Constitution, to form again a more perfect Union, by dissolving that which could no longer bind, and to leave the separated parts to be re-united by the law of political gravitation, to the centre."
              </p>
              <div className="speaker" id="sp1902"><span className="persName">[Mr. MONTAGUE: ]</span></div>
              <p className="p-in-sp">
                That is what Mr. Adams said about it. He says we have had two precedents—one when we seceded from England, and the other when we seceded from the articles of Confederation; and when the day comes that the hearts of these people are divided, when the Government no longer rests upon kindly sympathies and affections, then it is time for them, as Abraham and Lot did, to separate in
                peace. Adams men present, listen to the voice of your father. Come up now; you cannot shut your eyes to the fact that the period has arrived when kindly sympathies no longer exist, and instead of keeping Virginia in a free-soil Confederacy, and keeping her down as a mere political dependency upon that central empire at Washington, use your efforts to bring about a peaceable
                separation of people that can no longer live together in peace and in harmony.
              </p>
              <p className="p-in-sp">Sir, what did a distinguished gentleman of Virginia, whose portrait hangs yonder, and whose ashes repose, as I have been informed, near the residence of a distinguished gentleman now upon this floor [<span className="persName">Mr. BOULDIN</span>, of Charlotte] —I allude to John Randolph. What did John Randolph say in his celebrated resolutions at Charlotte Court House in 1833, upon that subject. Here it is, in the fourth resolution :</p>
              <p className="p-in-sp">"That Virginia has never parted with the right to recall the authority so delegated, for good and sufficient cause, nor the right to judge of the sufficiency of such cause, and to secede from the Confederacy whensoever she shall find the benefits of the Union exceeded by its evils."</p>
              <p className="p-in-sp">That is what John Randolph said in 1833, and he understood well the nature of this Government.</p>
              <p className="p-in-sp">But I have a letter here to which I wish to call the attention of the distinguished gentleman from Kanawha [<span className="persName">Mr. SUMMERS</span>] . He [<span className="persName">Mr. SUMMERS</span>]
                , spoke in warm and eloquent language of Macon, of North Carolina, as one who, the gentleman to whom I refer declared, had <PageNumber num={39} /> never taken a position until he had surveyed the whole field, and whenever he took a position he was right, and when he was right the whole world could not turn him from his position. Let us hear what he said
                upon that subject. I happen to have a letter here from Mr. Macon dated the 9th of February, 1833, and addressed to the Hon. S. P. Carson, in which he says :
              </p>
              <p className="p-in-sp">
                "I have received your letter of the 24th ultimo. There can be no doubt that the United States are in a deplorable situation, and that the publication of the opinion you desire would be useless. It has never been a secret, and always stated to those who wanted to know it. In the year 1824 the Constitution was buried in the Senate, the Senators who were then present will, it is
                believed, recollect the fact, and never afterwards quoted by me while I continued in the Senate. The opinions of General Washington, Mr. Jefferson and Governor Clinton are known, but not respected. I have never believed that a State could nullify and remain in the Union, but have always believed that a State might secede when she pleased, provided that she would pay her proportion of
                the public debt ; and this right I have considered the best guard to public liberty, and to public justice that could be desired; and it ought to have prevented—what is now felt in the South—oppression."
              </p>
              <p className="p-in-sp">
                There is what a wise man—a man who, according to the distinguished gentleman from Kanawha, never was mistaken; who never took a position until he had surveyed the whole field, and whose grasp of wisdom was so great that he foresaw all the difficulties before him—there is what that man said upon the subject. He said that the greatest safety for public liberty and public justice, was
                the right of a State to secede from the Union, and the only condition attached was, that she should pay her part of the public debt; and we all will agree to do that. I say, sir, that if, when you exercise that right, you are to be denounced as being guilty of treason and revolution, that yon Government falls into a military despotism, and liberty will be buried, and the battles of
                the Revolution will have been fought in vain. That will be the consequence if this doctrine is to prevail; and I fear that this Convention is determined that it shall.
              </p>
              <p className="p-in-sp">Mr. Jefferson, in his letter to Mr. Giles, dated the 26th of December, 1825, distinctly admitted the right of secession, when he declared that we ought to separate from our confederates, "when the sole alternatives left, are the dissolution of our union with them, or submission to a Government without limitation of powers."</p>
              <p className="p-in-sp">The gentleman from Harrison [<span className="persName">Mr. CARLILE</span>]
                , invokes the great <PageNumber num={40} /> name and fame of Mr. Calhoun against the right of secession. All I have to say on that point is, if any gentleman will refer to his elaborate letter written to Gen. Hamilton, on the 28th day of August, 1832, he will see that while Mr. Calhoun draws a distinction between nullification and secession, yet he
                distinctly admitted the right of secession, and ably contended for both.
              </p>
              <p className="p-in-sp">
                Thus, Mr. Chairman, I have passed thus far in the discussion of this subject. And here I want to make an enquiry of the distinguished Chairman of that distinguished Committee of Twenty-one. They say in their report that a State has a right to withdraw from the Union. but he, the Chairman, in his argument here the other day in which he denied the sovereignty of a State—and if a State
                is not sovereign, I don't see how it could withdraw—he said it was a revolutionary right. I want to know what he means by a revolutionary right—I ask for information?
              </p>
              <div className="speaker" id="sp1903"><span className="persName">Mr. CONRAD</span>, of Frederick—</div>
              <p className="p-in-sp">
                Mr. Chairman, I can only answer that enquiry of the gentleman by saying that he misconceives, at least, my definition of the resolution to which he refers. I stated that I was unwilling to use the word revolutionary, so far as that word implied anything like revolt or force; that I conceded fully the right which the gentleman has so ably and clearly maintained in the argument—the
                right of a State, as a State, to withdraw from the Federal authority; and I expressly denied, always have denied, that under such circumstances, the citizens of a State can be regarded as traitors, or insurgents; and yet, sir, in one sense I did say that the right to withdraw may be termed a revolutionary right, that it is an extra constitutional right. I cannot conceive of any right
                in the Constitution to break up that Constitution; yet in consequence of the separate organization of the State, which I regard as a most important check and balance in our Constitution, that a State has a right by such action, for just cause, to re-form or abolish its Federal Government, precisely as it has a right to abolish its State Government—
              </p>
              <div className="speaker" id="sp1904"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">And would not be guilty of treason in the attempt?</p>
              <div className="speaker" id="sp1905"><span className="persName">Mr. CONRAD</span>—</div>
              <p className="p-in-sp">
                And would not be guilty of anything like treason. I say, sir, it is not a constitutional right. I desire to be understood ; and the able argument which the gentleman has just closed shows that it is not a constitutional right. It was a right which originated upon this continent at the very time of the commencement of the American Revolution. It was a right exercised by the colonies
                of America in severing their connection with the Crown of Great <PageNumber num={41} /> Britain. Therefore, it cannot be a right which springs, in any mode from the Federal Constitution, adopted ten or fifteen years afterwards. I hope, sir, the gentleman is answered.
              </p>
              <div className="speaker" id="sp1906"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">I understand you perfectly, and I am exceedingly rejoiced that I do understand you. You have got to take but one step more, and I will take your hand and clasp it as another volunteer to the Virginia States Rights doctrine. I cannot expect you to come over all at once.</p>
              <p className="p-in-sp">
                I had intended, Mr. Chairman, to have presented an argument upon another branch of the subject upon this right of revolution. I could show from the law of nations, the international law, that these States, being sovereign, have the right to withdraw from the Confederacy, and that if any other Government in the world made war upon them it would have to do it according to the
                established usages of war, and if their people in the conflict were overcome, they would have to be treated as prisoners of war, and not as traitors. But the explanation of the gentleman from Frederick, I am very happy to say relieves me from the necessity of elaborating that argument. I will not, therefore, follow it further, although I am prepared to do it, and to establish the
                view I take by the most approved authorities upon international law. Here, Mr. Chairman, I rest the argument, and I want gentlemen who shall attempt to reply, as I have said before, to meet it in the same spirit in which I have attempted to deliver it; by calm, fair, deliberate reason and argument, not by sneers and epithets.
              </p>
              <p className="p-in-sp">
                The gentleman from Kanawha, in his eloquent speech, did not come out openly and deny this right. He suggested, however, one difficulty to its exercise, which I will notice here. Another gentleman, the gentleman from Monongalia, and others who have spoken here, have thrown out the same objection : that these Southern States, Virginia included, ought not to secede, because of the
                difficulty which will spring up from the navigation of the Mississippi river. With great respect to that gentleman, and with entire deference and respect to other gentlemen here, I say, that that is no longer an open question. It is settled by well established principles of international law that these Southern States cannot, under any circumstances, close the navigation of the
                Mississippi. But the inhabitants of the States living above them upon its navigable waters may pass up and down without interruption. Here is my authority. I have not time to read it, but here it is. It is a principle for which your government has contended from its foundation—and it is now an established principle of the world. They would not have the right, even if they had the
                hardihood; <PageNumber num={42} /> and if they were foolish enough to attempt to exercise it, it would bring upon them the condemnation of the whole world. I will read one single authority upon the subject : "The right of navigating, for commercial purposes, a river which flows through the Territories of different States, is common to all the nations
                inhabiting the different parts of its banks; but this right of innocent passage being what the text-writers call an imperfect right, its exercise is necessarily modified by the safety and convenience of the State affected by it, and can only be effectually secured by mutual convention regulating the mode of its exercise." (Wheaton's International Law, page 253.) Thus it is a matter
                which under this rule must be regulated by treaty, which is done all over the world—and which no nation would be justified in setting at naught.
              </p>
              <p className="p-in-sp">That is a great and well settled principle.</p>
              <p className="p-in-sp">
                Well, sir, what other argument? The gentleman from Monongalia brought forward here one argument against the exercise of this right which I was extremely surprised to hear. I allude to it with the kindest feelings and the most perfect respect. I assure that gentleman that I mean nothing unkind or discourteous when I call that argument against the right of secession to which I shall
                refer as the picayune argument, as the argument of dollars and cents. The gentleman says that Florida cost millions of dollars, that Texas cost millions of dollars, that Louisiana cost millions of dollars, and asks whether we shall now permit them to secede after having paid this large amount of money for them ; thus measuring this great principle of American liberty, to which my
                friend has adverted, and making it subservient to the miserable, picayune argument of dollars and cents. Why, sir, if you intend to test that argument by dollars and cents, go to the Federal Government at Washington, and make your officers there give you the amount of what you have received from the public land which you acquired under these treaties, and you will find that you have
                been paid an hundred fold the price you gave. That is the way to meet that argument. By the treaties under which these territories came in, your whole country guaranteed to them the same sovereignty, the same equality, and put them, in every respect, upon precisely the same footing as the other States. And yet they are to be kept in because of the love of the almighty dollar, which
                is crushing and ruining our whole country. And the same argument, I suppose, will justify the North for keeping in Virginia and the other States. I scout this argument. With perfect respect, I say to the gentleman that it is unworthy the consideration of any Virginian, to <PageNumber num={43} /> measure sovereignty, to measure the great question of
                self-government, and the great eternal principles of civil liberty, by dollars and cents. I repeat, sir, it is such an argument as should not be used upon this floor.
              </p>
              <p className="p-in-sp">
                Now, Mr. Chairman, I have finished what I proposed to say upon that branch of the subject. I could elaborate it further, but I will let it stand as I have made it. And I will now, if I can hold out, give one or two reasons why, in my judgment, Virginia ought at this time, ought to-day, to resort to the exercise of this right. I dissent from the gentleman from Augusta in his
                declaration, which he made in his elaborate speech, that slavery is stronger to-day than it had ever been. It is stronger with its friends—it is stronger in the States where it exists; but everywhere else it has infinitely more enemies to-day than it ever had. The whole world is against you; the whole civilized world, including three-fourths of your own country—including every power
                of Europe, except Spain, and every power upon this continent, except Brazil—is against you. And with these facts staring the gentleman in the face, he gets up and proclaims that slavery is stronger to-day than ever. If that were so, how can he reconcile it with the history of our own government? That government is seventy-five years old. In its beginning, slavery existed in nearly
                every State ; and if slavery is stronger to-day than ever before, how happens it that your government is for the first time in the hands of the enemies of slavery? How did it get there? Now, sir, the war upon slavery was begun in 1790. It has been waged against us for seventy or seventy-five years; and ultimately it will triumph against us, unless we resist. This, sir, is a
                momentous, pregnant fact. It admonishes us in tones that cannot be mistaken, that unless we are true to ourselves, true to our little ones, true to our firesides, true to our blessed old mother Commonwealth, we will be crushed out and crushed forever. Sir, so help me God, I will die on her soil before I will give way. This war was inaugurated, I believe, some time in the year 1789.
                In 1790 petitions were sent from Philadelphia and New York to Congress asking them to abolish slavery. My friend from Bedford
                [<span className="persName">Mr. GOGGIN</span>]
                , read a debate which occurred upon the presentation of that petition signed among others, by Dr. Franklin, a man who had helped to make the Constitution. In 1792 petitions came again. In 1797 they came. In 1805 they came. In 1817 they came again. In 1819. So we had the war upon the Missouri question. In 1827 petitions came again. In 1831 they came again. In 1835 the war regularly
                begun upon the right of petition, and it was waged there until it was shifted from that <PageNumber num={44} /> to a war upon the territories.
              </p>
              <p className="p-in-sp">
                Now, Mr. Chairman, I call the attention of the Committee to some other facts; and when I have presented the facts I will ask your attention to the deductions from them. In 1840 for the first time this anti-slavery element entered into the Presidential election. Twenty years ago Birney, as their candidate, received 7,000 votes. In 1844, he again received, as the candidate of the
                anti-slavery party, 62,140 votes. In 1848 Van Buren and Gerrit Smith received 296,233 votes. In 1852 Hale received 157,296 votes. In 1856, sixteen years from the commencement of the efforts of these anti-slavery men, in connection with the Presidential election, John C. Fremont received 1,324,812 votes, showing an increase in sixteen years of over 1,300,000 votes. In 1860 Abraham
                Lincoln got votes enough to elect him President.
              </p>
              <p className="p-in-sp">
                These are the facts. Now for the deductions. The conclusion which I draw is this : These facts show that there has been a persistent war from the very first session of the First Congress down to the present moment, made upon you and upon your interests. Now, is there any principle of human reason, is there any principle of logic, any principle of common sense, by which any sensible
                man can arrive at the conclusion that these people, who have been contending now for nearly seventy-five years for the accomplishment of one object, will relinquish it, give it up, just when successful, and go back to where they started? Answer that point, gentlemen. Can it be answered upon any principle of human reason, upon any principle that governs human nature? If it cannot,
                these facts show you that your existence as a free people, that your existence as a slaveholding people depends upon your separation from this Government, where, if you remain, you will be crushed out ; you will become degraded outcasts among the nations of the earth.
              </p>
              <p className="p-in-sp">
                That is enough, sir. If there was no other point, nothing else in the history of the slavery question but these facts, they would be enough to show that we cannot stop these aggressions, and that eternal separation is our only remedy. But, sir, let me look a little farther. I think, these facts show that these people cannot now relinquish that for which they have been contending for
                75 years. Now, sir, I have no desire to say anything disrespectful about these Northern people. I have an ineffable contempt for their leaders, for such men as Seward, Sumner and Wilson. I believe they are hypocrites. I have no unkind feeling, however, for the great masses of their people. But the generation that now governs the North have grown up in the last 30 years, and every man
                of them, except Charles O'Conor, of <PageNumber num={45} /> New York, that I have ever heard of believes in their hearts, honestly before God, that slavery is a sin. Judge Story, Chancellor Kent, and Daniel Webster, in his great argument before the Supreme Court of the United States have taught them the fallacious doctrine that this is a Government of
                one people, and being a Government of one people, it necessarily follows that one section must be in part responsible for the sins of the other section. They honestly believe it, and I can respect an honest man in that honest belief. But I contemn, I despise canting, hypocritical demagogues, such as those leaders who strive to rise to place and power upon these honest but fallacious
                and delusive convictions of the people of the North. Judge Story's book is a text-book in all their Colleges. Dr. Wayland, a great theologian, who has written a book upon Moral Philosophy, is an authority there, and his book is a text-book in all their Colleges ; and in that book there is a whole chapter put forth to show that slavery is a sin. Then, sir, there is Peter Parley,
                useful and instructive as his books are—yet, begin at the first, as I have done, and read them all through, and you will find pervading the whole works an anti-slavery feeling. This feeling lies at the bottom of the whole, and the design is to create the impression that slavery is a sin and a wrong. Here, then, you have the masses indoctrinated with Parley's doctrine, and the
                educated young man indoctrinated with Webster, Kent, Story and Wayland's doctrine. Then you have—and I defy any gentleman here to deny it—a union of the masses, the honest masses, to strike down an institution which they believe is sinful—a union, I say, of the masses with the educated men and political leaders ; and I again defy any gentleman here to point me to an instance in the
                history of the world, when the masses have united with the leaders for a given purpose, that they ever ceased from their efforts until that purpose had been accomplished, or they themselves have been destroyed in their efforts to accomplish it.
              </p>
              <p className="p-in-sp">
                Then, sir, I say this is a reason why we should separate. Look again at the way in which these Northern people have been educated. They have had anti-slavery catechisms, anti-slavery priests, anti-slavery lecturers; they have anti-slavery everything. Their whole system is pervaded with anti-slavery feeling and anti-slavery sentiment. And yet Virginia statesmen get up here and talk
                about the overwhelming tide that is to destroy the anti-slavery feeling of the North, by paper guarantees attached to the Constitution. You may just as well attempt to dam up the tumbling waters of Niagara, with your little finger, as to attempt any such thing. Take this illustration: <PageNumber num={46} /> You are a religious man. You go to church, to
                the Episcopal Church perhaps. Now take your little boy, seven years old, let his mother night and morning teach him to kneel and say his prayers, teach him the great truths of religion, and continue her instructions until he becomes a grown-up man, let him be taught all the beautiful formula of the Prayer Book, and when that man goes into the world, can you turn him away from the
                doctrines his mother has inculcated? Yes, sir, you may one or two, here and there, but the proposition is a general one, that he will adhere steadfastly to the end. Well, sir, these people of the North, in their honest zeal, in their sincere desire to eradicate an institution which they, in their heart and in their conscience, believe to be sinful, like Hamilcar, when he took his son
                Hannibal, nine years old, and made him swear upon the altar eternal hostility to Rome, they make them swear eternal hostility to your institution of slavery. And do you suppose you can change the sentiment of such a people by paper guarantees? Sir, I desire not to say anything unkind to any gentleman here, nothing that shall in its remotest tendency interfere with the feelings of any
                gentleman here, but I must state to you, Mr. Chairman, that I was startled, astounded to hear gentlemen stand up and declare such opinions; to hear them, in times like these, crying, "wait, wait," "delay, delay," when every day, every hour, brings the event these Northern people desire to accomplish nearer to your door.
              </p>
              <p className="p-in-sp">
                Well, sir, our idea is, that Government is simply made to protect persons and property. That is my opinion. I believe that when you have a Constitution and laws sufficiently strong to protect persons and property, the less legislation you have the better. Their idea is just the reverse. Their idea is that Government is a great machine to dispense benefits and bounties to individuals;
                their idea is that the majority must govern without regard to the rights of minorities. Can we live with people like these? Look at your Government now at Washington. Is there so corrupt a Government upon God's earth as your present Government at Washington? There it stands, your citadel of liberty itself, a place where the most corrupt scoundrels upon earth gather together for the
                purpose of peculation and fraud upon the Government, upon the honest masses of the people of the country. Look at your members of Congress, expelled for receiving bribes. Every department of your Government has become corrupt as the Augean stables themselves. Do you tell me that you can save this by paper guaranties in the shape of amendments to the Constitution? Mr. Chairman, in my
                humble opinion, there is no other cure but to wipe <PageNumber num={47} /> it out and begin afresh. It is a failure. It has lived seventy-five years, and now you have got to wipe it out and begin afresh or it will end in despotism; and those men who will not vilely and slavishly submit to that despotism will be slaughtered upon some battle field fighting
                in defence of the rights and principles that have been handed down to us from our ancestors.
              </p>
              <p className="p-in-sp">
                Mr. Chairman, I find it is impossible for me to say all I intended to say. I cannot hold out. But, sir, there is one point I want to present before I take my seat. It is in reference to the moral question. I do not know what is the religious feeling of this assembly, but I am not ashamed here or elsewhere to proclaim the honest belief of my heart. I believe in God, I believe in His
                Providence, I believe in His direction, and I believe, as a moral, Christian man, it is my duty to do what I can to separate my State from these people at the North, who are striving day and night to destroy one of God's institutions the institution of African slavery. Let us look at it. How did it get here? We are told that about 1620 there was a storm at sea, and that somewhere off
                the Capes of Virginia—while the winds howled and the billows rolled, when spar after spar had gone down, when sail after sail had been reefed, when top-sail and stay-sail had been tattered and torn away, God, in His providence and mercy, to save a ship from being engulfed in the ocean, led her into a safe haven at Hampton Roads. Driven by the winds and waves into that haven, she put
                out twenty odd savage Africans. Our people assembled. They were amazed. They saw there a simple-hearted, docile people that might be elevated to a high standard of Christian civilization. They saw that Ethiopia had stretched out her hands, and was uttering the Macedonian cry, "Come over and help us," "Come over and help us." Virginia responded to that cry, and in consequence of her
                response these twenty odd savage Africans have grown to be four millions of civilized men now upon your continent.
              </p>
              <p className="p-in-sp">
                As Virginia was thus the great pioneer of the institution and its great author, would it not be the rankest cowardice, the most disgraceful scene ever acted on the theatre of the world, for her, in this hour of distress and extremity of that institution which she has imposed on the land, to turn her back upon it and to permit Northern fanatics to exterminate it from the face of the
                earth? I do not believe that God will permit that to be done. You may talk about National Conventions, and Border Conferences, and you may send your committees to Washington every other day to confer with Mr. Lincoln. and to know when he is going to hit us ; you may read letters from <PageNumber num={48} /> Secretary Cameron, and Colonel Somebody, and
                Major Somebody else, each contradicting the other, to show that they are not going to remove guns from this city, but all will have no effect. God means, through the instrumentality of African slavery, to accomplish His great purpose of Christianizing and civilizing that race. You may throw impediments in the way, and thereby bring down punishment on your own heads, but you cannot
                stop it. I met, recently, a very able book, written by a distinguished Presbyterian divine, the Rev. Mr. Stiles, of Georgia, from which I have made an extract which I submit to the consideration of the Christian members of this Convention and of the people of the State. Here it is :
              </p>
              <p className="p-in-sp">"In 1855, heathen church membership is set down at 180,000. The present [1855] , estimate of colored Church members in the Methodist Church South, alone [which includes slaveholding States only, and does not include Maryland and a part of Virginia]
                , is 175,000. Eight or ten years ago the Baptist colored membership at the South was recorded as only 4,000 less than the Methodist. When to these numbers you add all the colored members of other unincluded organizations of Methodist and Baptist, also Episcopalians, Lutherans and Presbyterians, Old School, New School and Cumberland, you readily reach an aggregate of colored Church
                membership near thrice as large as the strictly heathen orthodox Church membership of the world."
              </p>
              <p className="p-in-sp">
                Here then the Almighty power, through us as its instrument, has Christianized more than double as many of those savage Africans as have been Christianized in all the rest of the world. Why, then, should we stand here idle, and fold our arms, and permit the run-mad people of the North to come down and exterminate this institution? Sir, you will be held to an accountability if you do
                not stand forth as moral Christian men, and do your duty in this great crisis in favor of protecting this institution which any man can see is destined to Christianize and civilize a great part of the heathens of the world.
              </p>
              <p className="p-in-sp">
                Mr. Chairman I intended to have said more, but I forbear. I have spoken longer than I intended and have become exhausted. In conclusion I have to say, that differing as I do with my friends here, my earnest, honest, convictions—and I appeal to my God for the sincerity of the declaration—are that every consideration of duty, of interest, of high Christian moral obligation, conspires
                to make us cause Virginia to secede at once, settle our difficulties peaceably afterwards, if we can and if we cannot, forcibly.
              </p>
              <p className="p-in-sp">
                If I find that you will not go with me, if you are determined to wait, wait, wait, and see when you will be hit, if my plan fails, I will <PageNumber num={49} /> set up no factious opposition, but will go with you for the next plan which I think will best promote the great object I have in view. Mr. Chairman, I will not utter the common place sentiment
                of returning my thanks to the Convention. I had a right to speak, and they had a right to listen—both parties have exercised that right. I will conclude my remarks with this earnest, honest, declaration, that if I am in error it is an honest error of my heart. I believe I am right ; and I mean to stand by my principles and doctrines to the bitter end. If that day shall come—which God
                in his mercy avert—when you and I will have to be exiled or yield to this horde of Northern Vandals, I re-echo, sir
                [alluding to <span className="persName">Mr. PRESTON</span>]
                , your eloquent sentiment. I will not be exiled. I will not be a second Marius to go off amid the ruins of some Carthage, and there to weep away existence in bitter agony over the memory of lost and past glories, but, like you, sir, will stand at my post and fall as a true man—and when you shall sleep in your own green meadow, beneath your own native mountain, I will stand on the
                shores of my own native Rappahannock, and there will I fall ; and the ripple of its waves shall be the requiem that will soothe my repose till the morning of the resurrection. Virginia I will never forsake. I will die in Virginia, and trust to God and to posterity to vindicate what is just and what is right.
                [Applause on the floor and gallery.] <span className="persName">Mr. MACFARLAND</span> then obtained the floor, and addressed the Committee until two o'clock, when the Convention took a recess.</p>
            </div>
            <div className="section" id="vsc1965.v3.2.1.3">
              <h3><span className="headingNumber">1.1.3. </span><span className="head">EVENING SESSION</span></h3>
              <p><span className="persName">Mr. MACFARLAND</span> resumed his remarks and spoke for two hours, when he concluded. His speech will appear hereafter.</p>
              <div className="note" id="Note5">
                <span className="noteLabel">1</span>This speech by Mr. Macfarland has not been found in the Enquirer. On April 3 the Daily Dispatch published this abstract of the proceedings including the speech :
                <p><span className="persName">Mr. MACFARLAND</span>, of Richmond, took the floor, but the Chairman requested him to suspend his remarks for a moment, while the Sergeantat-arms again cleared the gallery and the lobby.</p>
                <p><span className="persName">Mr. MACFARLAND</span> and <span className="persName">Mr. WISE</span> appealed to the Chair to withdraw the order, which the Chair consented to do, alluding to the pain which it gave him to enforce such an order, and admonishing the spectators that if the thing were again repeated, the order would be peremptorily executed.</p>
                <p>
                  <span className="persName">Mr. MACFARLAND</span> then commenced his speech with the remark that he entered upon the discussion with a painful sense of the responsibility which the position implied. When the secession movement began, the country was in the plenitude of its strength ; yet the evils resulted from causes which had long been operating in the public mind. No Union could long
                  exist, where the people on one side constantly exercised a spirit of hostility against the people of the other side, and to this constant agitation he attributed the present evils overshadowing the country. He proceeded to consider the question whether the sentiment of hostility at the North might be overcome and supplanted by a sentiment of kindness and friendliness to the South.
                  It was, he conceived, the part of wise statesmanship not to dismiss and abandon institutions like ours, without at least making one intrepid effort to retain them. There was neither courage nor intrepidity in despair. The peace of the world was once placed in jeopardy far more imminent, under the inflaming influence of a religious controversy, than it was now under the agitation of
                  the African slavery question; and yet it was found that by toleration the Protestant and the Roman Catholic might dwell together in peace. He apprehended that in years hence it would be found that the people of this country might dwell together as peacefully as the people of any one county in either section. No suggestion of the futility of making an effort to this end should
                  discourage us from making it. If success should fortunately crown the effort, there was not an individual here engaged in the work of endeavoring to restore the Union, but would establish a claim upon posterity for reverence and gratitude. If it were possible, by amendments to the Constitution, to close the door of agitation so that no human power could open it, was there not at
                  least a prospect of annihilating the evils which have so wrought upon the feeling of the South? He would not say that the report of the committee was sufficient, but he took the ground that amendments might be introduced which would satisfy, not only Virginia, but the whole South. The question is not whether Virginia shall go with either the Northern or the
                </p>
                <p>Southern Confederacy, but whether she shall continue in the Union ti</p>
                <p>restored and reconstructed as a whole. He could not consent to abandon</p>
                <p>
                  the Southern States, nor the Border States ; but believed that the whole Union should be preserved, and through judicious efforts the North might be brought to proper concessions, such as would satisfy the extreme South. Pennsylvania, according to reliable information, would consent to such a scheme, and with Pennsylvania would come the whole Northwest. With Pennsylvania and the
                  Northwest united, it would be impossible to keep New England out of the Union.
                </p>
                <p>On this point <span className="persName">Mr. MACFARLAND</span> argued until half-past 1 o'clock, when he yielded the floor at the request of <span className="persName">Mr. PRESTON</span>, who moved that the Committee rise.</p>
                <p>The Chair could not entertain the motion, since the Committee had agreed to sit until 2 o'clock.</p>
                <p><span className="persName">Mr. SPEED</span> said that the object could be attained by the general consent of the Committee. Mr. Macfarland was laboring under physical disability, and under such circumstances he supposed no objection would be made.</p>
                <p>The Committee then took a recess until 4 o'clock P. M.</p>
                <p>The Committee re-assembled at 4 o'clock, and <span className="persName">Mr. MACFARLAND</span> resumed his remarks.</p>
                <p>
                  He said he had, in the morning, invited the attention of the Committee to a practical view of the method by which the great question now agitating the country might be definitely settled. His object was to show that if the subject of slavery was to be taken out of the halls of legislation, and withdrawn from the fields of agitation, we might yet live on terms of friendship and
                  respect with the North. Unless this could be done, he would not consent to remain in a Union with the people of that section, for it would be by no means desirable. He took the position that the Northern people were as much interested in perpetuating the institution of slavery as we were, and if the question of its extinction were presented to them, it would be decided in the
                  negative. They were dependent upon it for subsistence, and you might as well expect that the collieries of England would be abandoned, as that the Yankees would consent to their own impoverishment by the abolition of slavery at the South. There was, he assumed, a. change going on in the sentiment of the world on the subject, as shown by the employment of coolies by France and
                  England. We had a right to insist that the North should let us alone, and he believed that when the naked issue was presented, the North would recede from its aggressive position rather than encounter the evils which must result from a continued agitation of the slavery question. Alluding to the grandeur of the Union in the eyes of the world, he thought it would be time enough to
                  pass sentence upon it after our best efforts for its preservation shall have failed. He looked upon it as altogether improbable, that war could be avoided in the event of separation ; and one single war between the North and the South would entail upon each section an amount of suffering and waste which years of industry and successful commerce would in vain endeavor to repair.
                  These considerations, he conceived, would have their due weight upon the Northern mind. Bring them to the question, slavery or war, and no sensible man would decide in favor of the latter. He argued that the proper way to remedy existing evils was to close the door forever against agitation. If we could bring the North to agree that there should be no introduction of the slavery
                  question in the halls of Congress, no effort to impair the relation between master and slave, the public mind would become quieted, and peace would return to the country. But suppose we were mistaken in this? Would anything be lost by making the experiment ? Is it not as easy to separate twenty years hence as now "? The relative positions of the two sections would not be changed.
                  Everything encouraged us to make the effort. He conceived there would be no dishonor in saying to the North we must have these guarantees, or disunion is the inevitable result. It may be said that this has already been done ; but he apprehended it had not been done in the form and with the authority here proposed.
                </p>
                <p>
                  He argued that the Federal Government ought, and might now with propriety, acknowledge the independence of the Confederated States, and that it was an indispensable step towards a settlement of the difficulty. He agreed with the gentleman from Middlesex that the condition of the Confederated States was not to be disturbed by force. He held that the Committee ought to agree upon
                  something that would speedily settle the pending difficulty, taking care to demand enough to meet the exigencies of the case. He spoke highly of the resources of the Southern Confederacy, and the gallantry of her people, but must be permitted to say that he thought she acted with rather too much haste in going off without consulting with her Southern sisters. He was not, however,
                  disposed to quarrel with her on that account. He apprehended that, as strong as the Southern Confederacy was, she would not object to an addition to her strength, if it could be done with perfect security and without sacrifice of principle.
                </p>
                <p>
                  <span className="persName">Mr. MACFARLAND</span> went on with an elaborate argument on the question of the Tariff to show that the Southern Confederacy was not entitled to any particular favor from Virginia upon the ground of her avowed policy of free trade; yet he was so firmly attached by every consideration to the Gulf States, that he would be unwilling, under any fair
                  circumstances, to turn his back upon them. He was in favor of a further and final effort to reconstruct the Union; and whoever should have the good fortune to so shape the course of events as to bring about a peaceful solution of the great questions at issue, would be entitled to the lasting gratitude of posterity.
                </p>
              </div>
              <div className="speaker" id="sp1907"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">I understood the gentleman from the city of Richmond [<span className="persName">Mr. MACFARLAND</span>] , to say that a minority of the Committee took the majority by surprise in approving the position of the majority.</p>
              <div className="speaker" id="sp1908"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">
                I say that the complaints against the Committee's reports proceeded mainly from members of that Committee. I remember perfectly the course of the gentleman from Amelia in relation to the report. I do not mean to convey the idea that the Committee was surprised by the position of any of the minority. I was about to state here what were the facts in this matter, when interrupted by the
                Chair. If it is in order, I will state now what I meant to say when I referred to this matter in my address.
              </p>
              <div className="speaker" id="sp1909"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">It is not in order to state what transpired in Committee.</p>
              <PageNumber num={50} />
              <div className="speaker" id="sp1910"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">I understood the gentleman as having no reference to my action in what he said.</p>
              <div className="speaker" id="sp1911"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">None whatever.</p>
              <div className="speaker" id="sp1912"><span className="persName">Mr. SCOTT</span>, of Fauquier—</div>
              <p className="p-in-sp">I understand the usual time for adjournment is near at hand. I desire to give to the Committee some of the views that I entertain respecting the questions that have been so long in debate, but feeling reluctant to trespass at this time upon the exhausted patience of the Committee, I will ask that the Committee rise.</p>
              <p>The question was put and carried, and the Committee rose and reported progress.</p>
              <div className="speaker" id="sp1913"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">I have, sir, a substitute which I have prepared for the report of the Committee on Federal Relations, that I desire now to submit in order that it may be printed, with a declaration that at the proper time, when it is in order, I shall offer it, in order that I may have an opportunity of recording my vote.</p>
              <p className="p-in-sp">I will take this occasion to say, that the gentleman from Orange [<span className="persName">Mr. MORTON</span>] on yesterday, did me the honor—the high honor—to say that I was the strongest Union man in this Convention, not excepting the gentleman from Kanawha [<span className="persName">Mr. SUMMERS</span>] . I trust, Mr. President, that there are a number of members of this Convention who are as strong Union men as I am. I would to God that I had the power and the eloquence of the gentleman from Kanawha [<span className="persName">Mr. SUMMERS</span>]
                to enable me to place before this Convention and the country my views upon the great questions now agitating the country. My constituents heard my views, the views which I now entertain, and upon a full, fair and candid expression of them, they elected me to this Convention. I thank God that I have not allowed any persons to remain in doubt about my position, and I have not been here
                for six weeks to have it now debated as to what position I occupy. My constituents know, and the members of this Convention, with whom I have been brought in contact, know what is my position. I desire to place upon the record of this Convention my vote, in order that there shall be no possibility of mistake as to the position I do occupy, and it is for this reason that I shall offer
                the substitute which I have sent to the Clerk and which I ask may be printed.
              </p>
              <p>The question was taken on printing the substitute offered by Mr. Early and it was agreed to.</p>
            </div>
            <div className="section" id="vsc1965.v3.2.1.4">
              <h3><span className="headingNumber">1.1.4. </span><span className="head">TAXATION QUESTION</span></h3>
              <div className="speaker" id="sp1914"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">There is one question that was brought, some time ago before this body, in the Convention, not in the Committee of the <PageNumber num={51} /> Whole, which I wish to inquire about and to know what has become of it—I mean the question of taxation.</p>
              <div className="speaker" id="sp1915"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The question of taxation is the subject now properly in order at this moment.</p>
              <div className="speaker" id="sp1916"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">In what form is it pending before this body?</p>
              <div className="speaker" id="sp1917"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">It has been the question in order in the morning, until the debate was arrested by the Convention going into Committee of the Whole ; and consequently it is in order as soon as the Committee rises.</p>
              <div className="speaker" id="sp1918"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I mean to ask what the particular question is?</p>
              <div className="speaker" id="sp1919"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">Upon the adoption of the resolution offered by the gentleman from Monongalia [<span className="persName">Mr. WILLEY</span>] .</p>
              <div className="speaker" id="sp1920"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">There is no proposition.</p>
              <div className="speaker" id="sp1921"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">Yes, sir, there is a distinct proposition of principle in the first resolution.</p>
              <div className="speaker" id="sp1922"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                I mean no distinct proposition of constitutional amendment.<span className="note" id="Note6"><span className="noteLabel">2</span>A correction (April 6) gives this sentence as "I mean no proposed amendment."</span>
              </p>
              <div className="speaker" id="sp1923"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">None.</p>
              <div className="speaker" id="sp1924"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                I merely rise to say that I desire at some time or other, before the Convention shall dispose of this matter, if I am able to do so, to say briefly what I know upon that subject, as occurring ten years ago in the Convention that formed the present State Constitution. I claim upon that part of the new Constitution to have been a part, if not magna pars. That clause in the new
                Constitution I drew with my own forefinger and thumb as a guarantee for the East against unequal taxation, when I had waged a siege of eighteen months to destroy that odious thing which was called a mixed basis. It was that mixed basis, more than anything else, which made the institution of slavery odious. It was that which made property stand up against numbers of voters, and made
                numbers of voters curse the property which subordinated to itself the majority of the white population of the State. With all my might, with all my zeal, with all my head, and with all my heart, I tried to break down unequal representation, and with equal zeal I tried to secure equal taxation. I understnd that I have been upbraided in the West, and am now cited and quoted in the West
                as being the author of the exemption from taxation, of negroes under twelve years of age. I here avail myself of the opportunity in this place, before the whole State, to deny the charge. There were three points that I made with my constituents, throwing my bosom bare upon the spears of the popular prejudice on that question in the East; there were three cardinal points that I made
                with <PageNumber num={52} /> my constituents at that day : free equal representation; free equal, universal, uniform, ad valorem taxation; and free universal education. I compromised with my constituents upon every other subject, and I appeal to them, who know the fact, at home, to say whether this was not my position. And now I appeal to the members who
                represented the West in that Convention, and who are members of this Convention, to bear me witness, if in the long and tedious effort that was made to obtain equal representation for the West, if the siege was not carried on at my instance. And to prove this, I need but refer you—for he was one of the divided West that stood by me throughout—to
                <span className="persName">Mr. CAPERTON</span>, of Monroe. WM. SMITH, of Greenbrier, now of the Senate of Virginia, was another. When even the gentleman from Kanawha
                [<span className="persName">Mr. SUMMERS</span>]
                when<span className="persName">Mr. SAMUELS</span>, of Shenandoah, despaired almost of obtaining what they came to obtain, equality of representation ; and when the question was started in a caucus of the West, whether it was worth while for them to remain here, it was at my instance that they were kept here to obtain their rights—and I was backed in the appeal to remain by my friend from
                Monroe
                [<span className="persName">Mr. CAPERTON</span>] . He remembers it well; my friend from Kanawha [<span className="persName">Mr. SUMMERS</span>]
                remembers it well. It was I that urged upon the West to stand firm, to continue the siege, to lay siege to the majority, until argument and reason should prevail, as they did ultimately prevail. When I had finished the work with the West and for the West, I retired from that caucus, and then went over to aid the East in obtaining the guaranties of equal taxation which I had promised
                to my constituents. I appeal to all who recollect the fact, to bear me witness—I am sure, Mr. President, that your colleague, my friend from Loudoun
                [<span className="persName">Mr. CARTER</span>]
                , remembers it—when I turned to the East that I said to the West : "I asked if you are earnest ;<span className="note" id="Note7"><span className="noteLabel">3</span>The correction (April 6) gives this clause as "I asked of you no earnest."</span> I made no bargain with you; I demanded no hostage of you; I did what I did for equal representation, because it was right, and not only because it
                was right, but because it was politic. I have freely done justice to you; I ask only that you shall do justice to me. I have pledged my constituents at home, that if the mixed basis is abolished, they shall have a better guarantee."
              </p>
              <p className="p-in-sp">
                Sir, it so happened that in the Convention of 1829-30 there were two men—Abel P. Upshur, of illustrious memory, and Thomas R. Joynes, of the county of Accomack—the latter not quite so well known to the nation, but equally strong in all mental powers. These were the two men from the Eastern shore, of whom Mr. Randolph said, that one was the figure of rhetoric, and the other was the
                figure of arithmetic <PageNumber num={53} /> of that Convention; and I believe no compliment was ever more just or more true. Both of these illustrious men had, only ten years before me, maintained the mixed basis—and in their faces, and in the face of their arguments, in the face of their example, challenging Mr. Joynes to meet me there before the
                people, I did say, what I now repeat, that I did find a better guarantee in equal taxation than ever the mixed basis afforded. What was that?
              </p>
              <p className="p-in-sp">
                Now I come to what I want to say, that my only guarantee was, so far as I was concerned, the guarantee of making the taxation of slaves quadrate with the taxation of land. I wanted no more; I wanted no exemption; I asked for no exemption; but I was forced to take the exemption. I mean no reproach to anybody. A friend whom I loved, a friend from the South-side, a friend who is now no
                more, a friend who has not left behind him one who laments that he is no more, more than I do—a man of distinguished worth—an excellent man, William L. Goode, of Mecklenburg, led off thirteen Eastern men, including himself, from that proposition, and after one whole day's hard fighting in that Convention, I was defeated upon the guarantee which I offered. It so happens that that very
                man who was called the figure of rhetoric was then in this city, waiting here, and watching the result of that question. Thirteen men, as you well remember, Mr. President, Mr. Goode at their head, had filed out of the Eastern line and to defeat the guarantee which I proposed. Here let me say, sir, that upon the question of representation five men,<span className="note" id="Note8"
                ><span className="noteLabel">4</span>According to the Enquirer text, Mr. Wise named only four men, including himself.</span
                >
                Stuart, of Patrick—I lament that he is no more—Carter, of Loudoun, White of Loudoun, and myself, were the five men, only, who came from the East, and who did strongly and manfully do justice to the West on the question of representation, against all the odds of the prejudices of our own constituents, and the views of our colleagues from the East; and, at last, when the East defeated
                her own guarantees under the lead of Mr. Goode, for one whole day I made an appeal to the West, to say whether I should be left defenceless and without my guarantee to carry home to my constituents, which I had promised them; and, to their honor be it said, precisely the same number from the West ( the number of five) , that had gone from the East to do justice to the West on the
                question of representation—that number no more, no less—came forward from the West, like men, to do justice to the East on the question of taxation. Martin, of Marshall—now a Marshal of the United States—rose from his seat, came down to me and gave me his hand, saying, "if no other man stands by you, I will."
              </p>
              <p className="p-in-sp">Another was the member from Preston [<span className="persName">Mr. BROWN</span>]
                . He was one <PageNumber num={54} /> of the five that came from the West, who voted for that exemption. I am sorry to hear that he has been hard pressed by that vote since, and that he has been compelled to retire from a canvass in consequence of it. Never mind it; it ought not to have hurt you; it ought not to hurt you now. You had gained the victory of
                equal representation, and you had approximated nearly to equalization of taxation, and you gave to the East a guarantee of the quadration of taxation with land.
              </p>
              <p className="p-in-sp">Now, sir, I have got no bargain to offer to any body. I have got no consideration to exchange with anybody on this question. All I have to say is that, if negroes under 12 years were exempted, it is not because I was elected to exempt them, but because that exemption was forced upon me.</p>
              <p className="p-in-sp">
                My doctrine of taxation ten years ago in that Convention the doctrine which I have entertained all my life since I could think upon the subject of taxation—the doctrine which, if you wake me up in the morning at six o'clock, I will tell you is my faith and one in which I am unchangeable—is that nothing in a free Republic ought to be exempt except your wearing apparel, food for the
                poor and the relics of the dead. Let all come in ; I am willing. I do not speak for my constituents. I was not sent here to represent them upon this question. So far as any prejudice in the West has been created upon this subject, I have here to affirm, and I support the affirmation by the journal, that without the five votes from the West that guarantee could not have been passed
                and I say now, that never, never in one session of a reform body, was there greater progress made, more triumphant progress made, from bad to better, than was made on taxation and representation. However defective the new Constitution may have been in other respects, no greater progress was ever made in any reform body, than was made by the firmness, eloquence and arguments of
                Western members in that struggle for equal representation and equal taxation. The triumph was complete, save and except the tax upon little negroes. And now if that question is to divide the State, I will clear my skirts of that, by saying that I did not prefer it then, and would advise my constituents to relinquish it now. My own personal tax—according to the valuation of $300,
                which was a fair valuation then—my own personal tax upon twelve small negroes under twelve years of age, would make a difference of only about three dollars and sixty cents. I am willing to pay it, if that is all you have to complain of against the East. And, when that last act of justice shall be done, when equality of rights shall be secured, when equality of
                <PageNumber num={55} /> taxation shall be fully secured, all I have to say to you is and you know
              </p>
              <p className="p-in-sp">it, no men ought to know it better, so far as I have a right to speak—that I have done justice. In 1855, my friend from Kanawha [<span className="persName">Mr. SUMMERS</span>]
                , when I was in his town, and abused then in Western papers for being the author of this exemption, when I called upon him at his home to testify, as to the matter at issue, returned me then and there his thanks for the part that I had borne in this matter. It has been acknowledged in various ways that satisfy me that I stand fair at least, in the hearts and in the judgment of my
                fellow-citizens of the West. I reproach them with no ingratitude to me. I have asked them for no pay or no reward that they have not given me. They have stood by me in the day of trial, and I have no complaint to make of them; but I have this to say: if the exemption from taxation of negroes under twelve years of age is all that is to divide us, that shall not divide us, so far as I
                am concerned. I have only one word more to say: that, if after doing you full justice, you deny me justice the justice of protection, the justice of standing by my rights, the justice of being as true to the East as you are to the West if you deny me that justice, I can tell you that then you will have stung me and then I shall feel that your sting is the sting which gives me the
                intensest pain; all the sting of all Yankeedom would not compare with it, for this would be the sting of ingratitude. When I am stung, if ever, by the West, this will be the only response in my bosom. I do not believe, I will not believe, that Western Virginia ever will be, ever can be, induced thus to sting their brethren of the East. Never, never; for when they do it there will be
                a war between brethren, the fiercest war of a hell upon earth. None could be worse, none bitterer, for that war is always worse which occurs between brethren. I cannot believe it either, come when it may, how it may, in a crisis like this, that a proposition, such as that of the gentleman from Monongalia, that this exemption shall be abolished intends, I cannot believe that it is his
                motive to drive us to a bargain or a bribe in order that justice may be done. Now, sir, I have made these remarks with but one object, and that is to say, that whoever else is the author of this exemption of negroes, under twelve years of age, from taxation, I am not. It was forced upon me, as it was forced upon the West. We could have passed nothing without it. And hereafter and
                forever, I appeal to Western men, of all parties and from all sections, whenever they hear my authority quoted, whenever they hear me blamed for that exemption, I call upon them to do me the justice to repel it. It is unfounded and untrue. Western men and myself did pass it because <PageNumber num={56} /> we were forced to do that or do without any
                guarantee at all.
              </p>
              <div className="speaker" id="sp1925"><span className="persName">Mr. SUMMERS</span>, of Kanawha—</div>
              <p className="p-in-sp">
                I am sure the distinguished gentleman from Princess Anne will not need my testimony in regard to the patriotic and gallant services he rendered, not to Western Virginia alone, but, in my opinion, to the whole of this Commonwealth, during the Convention of 1850 and 1851. I never see him without having my memory carried back to the labors of those days and those nights. I have never
                omitted any proper occasion to avow my sense of the valuable services rendered by that gentleman in regard to all that pertains to the subject of representation in the General Assembly of the State. I take pleasure in saying that that gentleman, according to my recollection—I have not the journal before me, or any of the transactions; I speak from memory; I speak, however, from a
                recollection impressed upon me at the time by many protracted conversations upon that subject, and upon all other topics connected with that question that he was in favor of the ad valorem system of taxation, without exemption of any species of property from the taxing power. In regard to the provision by which negroes over twelve years of age were not to be taxed at a higher figure
                than $300, it was a measure coming from other quarters, with which I was less familiar. In all matters touching representation, the gentleman from Princess Anne and myself stood side by side. I know, in regard to the ad valorem principle, where he stood, and that he was opposed to the exemption of any property. The sum of $300 was fixed on at the time as the assumed average value of
                negroes over twelve years of age. I remember that a gentleman from this city, Mr. Robert G. Scott, was somewhat active in that branch of the subject. I remember he had some statistics, which he laid before us, in regard to the average value of slaves, and it was finally fixed at the sum of $300. The recollection of the gentleman from Princess Anne and myself agree throughout in the
                statement which he has made, with a single exception, in which he has, unintentionally, I know, done perhaps a slight injustice to myself. It is not a matter of any consequence to anybody except myself. The gentleman mentioned that even I had, at one time, somewhat despaired.
              </p>
              <div className="speaker" id="sp1926"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I spoke doubtfully of that, sir, and I meant to speak more doubtfully, for I was not certain. I know that there were some in the caucus to whom I referred who had despaired and talked about adjourning and going home, but I am not certain who they were.</p>
              <div className="speaker" id="sp1927"><span className="persName">Mr. SUMMERS</span>—</div>
              <p className="p-in-sp">I remember very well the caucus to which the gentleman from Princess Anne refers. I know the troubles which then surrounded us. I recollect much that was said upon the occasion.</p>
              <PageNumber num={57} />
              <p className="p-in-sp">
                But I say to you all, Mr. President—and you yourself acted a prominent part in the Convention of 1850-'51—that during all those labors I never did despair. I was often discouraged, but never was brought to despair. I have always believed in the maxim that a citizen should never despair of the Republic. Upon that occasion, Mr. President, I did not permit the feeling of despair to
                pervade my bosom, and I do not permit it to influence me now, when still greater troubles are upon us. I never despaired of the Republic. I did not despair of it then; I do not despair of it now. That is the only correction I desire to make in the statement presented by the gentleman from Princess Anne. In all the matters of fact alluded to by him, his recollection and mine coincide.
              </p>
              <div className="speaker" id="sp1928"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I accept that correction with great pleasure. I was not certain that I was correct at the time I made the allusion. My friends from Loudoun [<span className="persName">Mr. CARTER</span>] , and from Monroe [<span className="persName">Mr. CAPERTON</span>]
                , remember the facts, and know the correctness of my statement, and when it was at last remembered that the siege should be carried on, I was called upon, as my friend from Loudoun knows, to state what I considered the programme of the campaign, I did not rest my feet until I had detailed the programme of proceeding, which I believe they followed out ad libitum. I believe I am
                correct in that statement. The programme did succeed with a minority against a majority. And I wish I was as hopeful here now, sir, that the minority would succeed.
              </p>
              <div className="speaker" id="sp1929"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">I trust the Convention will pardon me for a moment.</p>
              <div className="speaker" id="sp1930"><span className="persName">Mr. C. J. STUART</span>, of Doddridge—</div>
              <p className="p-in-sp">I claim the floor upon the subject, before the Convention, but I am willing that the question should be put now, if the house is ready for it. If there is to be any further discussion, I claim the right to be heard.</p>
              <div className="speaker" id="sp1931"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">I will only detain you a few moments.</p>
              <div className="speaker" id="sp1932"><span className="persName">Mr. STUART</span>—</div>
              <p className="p-in-sp">I desire to know whether you wish to discuss the question.</p>
              <div className="speaker" id="sp1933"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">I simply wish to make one or two remarks, not to speak at any length.</p>
              <div className="speaker" id="sp1934"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">Does the gentleman from Doddridge yield the floor?</p>
              <div className="speaker" id="sp1935"><span className="persName">Mr. STUART</span>—</div>
              <p className="p-in-sp">Not at present, sir. I want to ascertain whether the gentleman from Franklin desires to discuss this question, and also whether there are other members who desire to discuss it, for, if so, I will retain the floor.</p>
              <div className="speaker" id="sp1936"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">I only want to make one remark.</p>
              <PageNumber num={58} />
              <p><span className="persName">Mr. STUART</span> yielded the floor.</p>
              <div className="speaker" id="sp1937"><span className="persName">Mr. EARLY</span>, of Franklin—</div>
              <p className="p-in-sp">
                I trust the Convention will indulge me a moment while I make a remark or two in regard to the question now pending before it. I think the few remarks I shall offer will not be inappropriate. One of the gentlemen alluded to by the gentleman from Princess Anne was a delegate representing the district which I represent in this Convention. The three delegates voted for the present basis
                of representation. One, my honored friend <span className="persName">Mr. STUART</span>, was elected as a white-basis man ; the other gentlemen were elected as mixed-basis men. It so happened that I was a candidate on the conservative ticket, and was beaten by an overwhelming majority. I received only 112 votes in the county that sends me here with 1100 majority; I was a mixed-basis man.
                Two delegates changed their votes here, and when they went back there was a great clamor against them. I believe, though I had been one of the strongest mixed basis men in the District, that I was almost the only one who did not join in the clamor against those gentlemen. In a very short time we became satisfied that they had acted wisely, in agreeing to base representation upon
                white population. I am now fully convinced of the error of the opinion which I formerly entertained with regard to the proper basis of representation. In all the history of the legislation of the country from that time to this, no more conclusive and triumphant vindication of that policy is to be found than in the history and the organization of this Convention. I am fully convinced,
                sir, that my former views were erroneous, that the principle adopted by the Convention of 1850 and '51 was correct.
              </p>
              <p className="p-in-sp">With regard to the other question of taxation I am fully and completely satisfied that the principle contended for by the gentleman from Monongalia [<span className="persName">Mr. WILLEY</span>] , and the gentleman from Marion [<span className="persName">Mr. HALL</span>]
                , and others on this floor is just and right. But, sir, as I said to the Convention on another occasion, this question was not mooted before my constituents in the canvass; the question of union or disunion overrode all other questions. Therefore I don't feel authorized at this time to interfere in any way with the Constitution of the State, though as I said before, I opposed it from
                the beginning to the end, casting my vote against it at the polls ; and there are many of its provisions I would be glad to see changed.
              </p>
              <p className="p-in-sp">
                But, sir, I will readily vote for a committee to take this subject into consideration. As I said before, I presume it is well ascertained that we are to have an adjourned session. In the mean time, I can go home and consult my constituents—we all can go home and consult our <PageNumber num={59} /> constituents, and the committee can report to the
                adjourned session of this Convention. I acknowledge the justice of the principle contended for by the gentleman from Monongalia
                [<span className="persName">Mr. WILLEY</span>] , and I shall advise my constituents to accede to the justice and propriety of that proposition.</p>
              <div className="speaker" id="sp1938"><span className="persName">Mr. C. J. STUART</span>—</div>
              <p className="p-in-sp">Is this a question simply for the purpose of raising a committee.</p>
              <div className="speaker" id="sp1939"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">No, sir, that is not the only question. The Secretary, perhaps, had better read the first resolution that the House may understand it.</p>
              <p><span className="persName">Mr. ECHOLS</span> moved that the Convention adjourn. A division was called for, and by a vote of 52 to 28 the Convention then adjourned to Wednesday morning at 10 o'clock.</p>
            </div>
          </div>
          <div className="day" id="vsc1965.v3.2.2">
            <PageNumber num={60} />
            <h2><span className="headingNumber">1.2. </span><span className="head">FORTY-SECOND DAY</span></h2>
            <div className="dateline">Wednesday, <span className="date">April 3</span></div>
            <p>The Convention met at 10 o'clock, A. M.</p>
            <p>Prayer by the Rev. Mr. Willis, of the Baptist Church.</p>
            <div className="section" id="vsc1965.v3.2.2.1">
              <h3><span className="headingNumber">1.2.1. </span><span className="head">THE TAXATION QUESTION</span></h3>
              <p><span className="persName">Mr. STUART</span>, of Doddridge, addressed the Convention on the resolutions submitted by <span className="persName">Mr. WILLEY</span>, as follows :</p>
              <div className="speaker" id="sp1940">[<span className="persName">Mr. STUART</span>:]</div>
              <p className="p-in-sp">
                Mr. President, I propose this morning to submit a few remarks upon the resolution of the gentleman from Monongalia, before the Convention goes into Committee of the Whole. I am sorry that more of my Eastern friends are not present, because it is especially to them that I desire to address my observations. It will be recollected that when the Committee rose last evening, I was
                entitled to the floor, but for the purpose of progressing as speedily as possible with the business of this Convention, I was desirous that the question should then be put upon the resolutions of the gentleman from Monongalia
                [<span className="persName">Mr. WILLEY</span>] , but I yielded very willingly, to the gentleman from Princess Anne [<span className="persName">Mr. WISE</span>]
                , to give a history of his connexion with the subject of taxation and representation in the Convention of 1850-51, as well as in reference to his sentiments entertained at this time. I desire to say that we of the West have felt profoundly grateful to him for his past advocacy of our rights and our interests, and we now entertain a like sentiment, and are disposed to return to him
                our most profound thanks for the disposition he now manifests to accord us our rights. Mr. President, it is with diffidence that I attempt to say anything on the present occasion. I arrogate nothing to myself, but, representing a constituency, as I do, deeply interested in this question, I feel it to be my duty to raise my voice in behalf of their rights, feeble as it may be.
              </p>
              <p className="p-in-sp">
                I am not one of those who are disposed to fold their arms and say that all is peace and quiet ; that the interest and honor of Virginia are in no danger. No, sir, I look upon the present aspect of affairs as threatening and perilous. If the ship of State can be steered clear of the breakers that surround us in the impending storm that is hanging over us, in dark and portending
                clouds, I for one shall rejoice. I cannot fail to see that there are many dangers and breakers ahead, and it is to avert these things that we are now convened in this Convention. I say that I am not one of those who are in favor of standing still and saying all is well—all is peace. I believe that we are <PageNumber num={61} /> on the verge of a civil
                war that will be forced upon the people of Virginia, unless by wise and prudent counsels it may be averted. Entertaining these views and sentiments, I hold that it is our first duty to make all right within our own borders; seek to heal all and every dissension among our own people, bind ourselves together as a band of brothers, and come up to the work as a united people with one
                object and purpose. We can read from very good authority that a house divided against itself cannot stand. Although we may differ as to the mode and manner of redressing our national difficulties, we still concur in the fact that the institutions and rights of Virginia have been invaded by Northern free States, and that it is our duty and our right to ask at their hands a remedy
                against these evils complained of, and we of the North-west are willing to unite with our Eastern brethren in asking and demanding relief against these wrongs. Mr. President, permit me to say to my Northwestern friends who have argued this question before the Convention, that it seems to me they have fallen into an error in charging upon our friends of the East that they have said
                that we were unsound upon the slavery question. It is but justice to my Eastern friends to say that in all their remarks in this Convention which I have had the pleasure to listen to, and I believe that I have been in constant attendance, I have not heard one sentiment uttered by them indicating that we of the West were unsound upon the institutions of Virginia. It has been left for
                a Western member to bring this charge against his brethren of the West—the only one, so far as my observation has extended, who has charged the West with being unsound on the slavery question. To that member belongs the honor of bringing that charge against us. I most cheerfully acquit my Eastern brethren of having made the charge in this body. Unsound, indeed, upon the slavery
                question? Why, Mr. President, can the history of the world show a people who have been more loyal and true to the institutions of their State than the people of Western Virginia have been, and especially to the institution of slavery. They have for many years stood up for the institution and rights of slavery, when that very institution has been made to bear most oppressively upon
                the interest and rights of the Western portion of the State. Still you have always found them at their post. And, now, when this institution is not only made the means by which we are ranged, but when it is conceded that it is the direct cause of all present national difficulties, and when it is remembered that we have no direct interest in it, and yet, under all these circumstances,
                you find us at our posts ready to do battle for it, and to risk even the destruction of our <PageNumber num={62} /> National Government in demanding its protection for you. Can it be possible that any man can be so lost to a sense of justice as to again bring that charge against us? Let me say to you, Mr. President, that the people of Western Virginia
                are as disinterested, brave and true hearted a people as ever stood upon this green earth. I may assert it, that the people of Western Virginia are true to the institution of slavery from principle, whilst I might bring the charge against you of the East that your loyalty to it is prompted upon interested motives. Yes, gentlemen, of the East, it is interest that now makes you ready
                to rise in rebellion against your Government, the interest you have in the protection of slavery, while we of the West are advancing solely upon principle, and the wish to secure to you your rights. Standing forth in this attitude, will you not mete out to us even-handed justice? or will you pursue a course that must alienate us from you? How do you reconcile to yourselves the fact
                that you are calling upon us to come to your rescue; even to help you to destroy our Government; a government that is endeared to us by many sacred ties, and under which we have enjoyed rights and privileges which no other people ever enjoyed ; a Government, too, under which we have prospered as no other people ever have? Still to secure your rights, and to prevent injuries which you
                say may ensue, you ask us to cut loose from the General Government, and run the risk of a war and conflict with our sister States, not only our sister States, but truly in the limits of which our fathers, brothers and sisters are. All this for fear a wrong may be done you. And yet you are not willing to redress positive and grievous wrongs to your brethren in the same State, and
                those wrongs inflicted by your own hands. You appear ready to sacrifice your all in struggling for your rights against your alleged injuries, and not willing to come forward and redress our injuries, which are much more apparent and flagrant than your own. We do not ask you to fight for our complaints. It needs no armament or State equipment to secure justice and equality to your
                western brethren. All that it requires at your hands, is "to do unto others as you would have them do unto you."
              </p>
              <p className="p-in-sp">The gentleman from Richmond city [<span className="persName">Mr. RANDOLPH</span>]
                , in arguing another question—the question of protection said that we of the West were poor, not in an offensive sense but argued that we were made so from the operations of the Federal Government; that if it had not been for the operations of the General Government in giving large protection to the New England manufactories, thereby building up a strong power with which Virginia
                could not compete, that we might have been a large manufacturing people, and, as a consequence, <PageNumber num={63} /> would now be enjoying opulence and wealth. True, we are poor; but made so, in my opinion, more from the operations of the Government of Virginia and its unfriendly bearing to us, than by the Federal Government. But still, Mr. President,
                I might be permitted to inquire of that gentleman and of this Convention, where can you find a people who have advanced more in prosperity, population and in material wealth than the people of Western Virginia? It surely cannot be found in any other portion of Virginia. It will be recollected that trans-Alleghany, fifty years ago, was an almost unbroken wilderness. The emigration to
                that portion of Virginia commenced scarcely half a century ago, and yet, sir, those hardy pioneers have felled the sturdy oak, and in that short period of time, unaided, and as I think, oppressed by their Eastern brethren, have emerged from the wilderness and present a prosperous and happy people, counting their hundreds of thousands, and still advancing with an increase heretofore
                unknown to Virginia. But, Mr. President, while alluding to the remarks of the gentleman from Richmond, permit me to say that I love the institutions of Virginia. I love the Virginia gentlemen, their broad fields and large domain. It is here that the love of freedom is inspired and liberty can be proclaimed. I do not want to see our Virginia habits pass away. I do not want to see
                Virginia sundered from the manufacturing States, and protection between them and us, for the purpose of building up corporations and moneyed aristocracies. In that way, Mr. President, you would invite Yankee emigration to Virginia, and in less than 20 years, Virginia would be completely abolitionized. Then, sir, all the pride and glory of Virginia will be swallowed up, and our
                boasted superiority will have fled and it will be Virginia in name, and not in spirit.
              </p>
              <p className="p-in-sp">
                I desire now to bring myself down to the question under discussion. One reason I wish to bring to the notice of this Convention, why our organic law should now be changed, and one, which, in any aspect of this case, will have force and reason with it. If you are not willing to give us equal taxation and representation, because you say you entered into a contract to wait until 1865,
                of which I will hereafter speak, we have a right, under any circumstances, to demand of you the representation we are entitled to under the bases you forced upon us. You will discover that in 1850 the white population of Virginia was 895,000; at the present time, as shown by the census report, the white population of Virginia is 1,047,000, showing an increase of 152,000 white
                inhabitants. Of this increase, 109,000 are in Western Virginia. Taking the present population of the State and the basis <PageNumber num={64} /> of representation established by you in 1850-51, and it will be found that we in the West will now be entitled to 23 representatives over the Eastern portion of the State, instead of the 13 at present given to
                us. We are entitled to ten more representatives in the Lower House, and are now entitled to them on this floor, and they almost exclusively to Trans-Alleghany. And have we not, I appeal to you, a right at least to ask at your hands this small boon that is due us according to your own showing?
              </p>
              <p className="p-in-sp">Then let me call the attention of the Convention to another fact, that must strike every member with its force and justice. I stand here this day, representing a large constituency upon the floor of this Convention, more than double the number that many do on this floor, and even three times the number of some.</p>
              <p className="p-in-sp">
                Let me give you one illustration—take a small group of counties around my county. There is my friend from Wood, my friend from Marshall, and myself, representing a constituency of 35,383, while the six members here on this floor from another group in the Valley, do not represent that number, to wit : The members representing the counties of Shenandoah, Rockingham, Pendleton, and
                Highland. Why is it that the gentleman from Marshall represents a greater number of constituents than the two gentlemen from Shenandoah? Does not this great inequality demand an adjustment? Are you not willing to grant unto us our plain and undeniable rights, stated upon your own basis that you forced upon us? But the gentleman from Franklin
                [<span className="persName">Mr. EARLY</span>] , says that these questions were all settled in 1851 by a compromise entered into by the two sections of the State, and by that compromise it was to rest as then adjusted until 1865. According to my reading of the Constitution of 1851, I can see nothing in it that can fix any such a construction, except alone upon the tax question.</p>
              <p className="p-in-sp">
                Let us look into this compromise even upon the tax question. That compromise was forced upon us by our Eastern friends. They could just as easily have said that the Constitution upon that question should not be interfered with until 1900 as to say what they did ; and will any man contend that a Constitution can be fastened on any people, and that it is irrepealable by the same power
                that made it? What would become of your American principle that you talk so much about "that Government is founded in the consent of the governed," if such a Constitution could be fastened on our people? But, sir, it cannot be fastened on us as a moral obligation, let alone a legal obligation. The people of the North-West protested against such an agreement, and when the Constitution
                of 1851 was offered to them for ratification they <PageNumber num={65} /> voted against it, although, in their opinion, it was quite an improvement upon the old Constitution. They voted against it because they did not think that it gave to them that protecion they had a right to demand, and they were not willing that by their endorsement of it, that it
                should be construed into an acknowledgment of compromise. You had the power to force it upon us. We entered our protest against it, and yet you insist that it was a solemn agreement on our part. The facts do not bear you out in the assertion.
              </p>
              <p className="p-in-sp">
                We are now assembled in Convention of the State of Virginia for the purpose of promoting the interest of Virginia, and if we can convince you that it is to the interest of the State that the organic law should be changed, are you going to stand out against it and say that you are not going to do what is right because you forced upon us a compromise, as you term it, against our
                consent and most solemn protest? Let me say to my Eastern friends that now is the time to come forward, and offer to the West her rights. Advance with the olive branch in your hands and harmonize the people of the State. Unite the people of the East and West as one band of brothers ready on all occasions to fight to the death for each other's interests. You who want us to pass an
                ordinance of secession, ask us to go home to our people and convince them that it is their interest to leave the Union, and cut asunder from the general government. Our people will be very apt to take a common sense view of this subject. They will ask us for what reason are they called upon to do this thing? We will have to answer, that it is because the institution of slavery is
                endangered by the encroachments of our sister free States in this Confederacy; but their reply would be that they have no interest in slavery, and that they should not be called upon for that reason to launch forth in an untried experiment. We would then have to reply, that it is true, they had no direct interest in the question of slavery, but that our State and our Eastern brethren
                have a large and vital interest in it, and that it was to our interest to protect the interest of slavery in the State. We would then have to cast about us for arguments and reasons for this hypothesis. I suppose that the only reason that could be offered would be the one pressed upon the consideration of this Convention by the argument of the gentleman from Culpeper
                [Mr BARBOUR]
                , the other day, and that was, that it was the interest and policy of the Western portion of this . State to make slavery valuable for in that way it would pay more into the public treasury in the shape of taxes and thereby help to meet our large State debt. This argument however, will not hold good, and will not bear an investigation by our Western people. Under
                <PageNumber num={66} /> our present organic law, which you refused to change, your slave cannot be taxed higher than you tax $300 dollars worth of our land, and if we help you to dissolve our connection with the Union, and thereby make your slave, which is worth now $1,000, worth then $2,000, still it will not add one cent to the taxes, or help us to pay
                our large and increasing public debt. We are willing to stand forth in vindication of your rights, as we have always done heretofore, but we will with the same spirit insist and demand our rights at your hands. Your property must be taxed in proportion as our cows and horses are taxed. Our people know how to demand their rights as well as to protect yours. We are no Submissionists—
              </p>
              <div className="speaker" id="sp1941"><span className="persName">The PRESIDENT</span> [interrupting]—</div>
              <p className="p-in-sp">The hour of going into Committee of the Whole has arrived, and <span className="persName">Mr. SOUTHALL</span> will please resume the Chair.</p>
              <p>The Convention resolved itself into Committee of the Whole [<span className="persName">Mr. SOUTHALL</span> in the chair] , and resumed the consideration of the report of the Committee on Federal Relations, the question being on the third resolution, as follows :</p>
              <p>
                "The choice of functionaries of a common government established for the common good, for the reason that they entertain opinions and avow purposes hostile to the institutions of some of the States, necessarily excludes the people of one section from participation in the administration of the government, subjects the weaker to the domination of the stronger section, leads to abuse,
                and is incompatible with the safety of those whose interests are imperilled; the formation, therefore, of geographical or sectional parties in respect to Federal politics is contrary to the principles on which our system rests, and tends to its overthrow."
              </p>
              <p>On which <span className="persName">Mr. SCOTT</span>, of Fauquier, was entitled to the floor.</p>
            </div>
            <div className="section" id="vsc1965.v3.2.2.2">
              <h3><span className="headingNumber">1.2.2. </span><span className="head">CORRECTION</span></h3>
              <div className="speaker" id="sp1942"><span className="persName">Mr. NELSON</span>—</div>
              <p className="p-in-sp">
                With the permission of the gentleman from Fauquier, I desire to occupy a few moments in the correction of a report of my speech, for I am determined to have it right. The result of my effort to correct it the other day, has been to make the matter worse than it was before, and out of regard for the reputation of the University, I want to have it right. I quoted from Virgil the
                well-known phrase, "Tantcene irce animis ccelestibus?" For animis they have printed animus, and they have left the word "Yellowlegs" instead of "Yellowley."
              </p>
              <p className="p-in-sp">In my written speech I had made use of a Greek quotation, being <PageNumber num={67} /> the famous advice of Hippolochus to his son Glaucus, but, as there was no Greek type in the printing office, I omitted that flourish, hoping to have my Latin at least right, and I am determined that I shall have it right.</p>
              <div className="speaker" id="sp1943"><span className="persName">Mr. SCOTT</span>, of Fauquier, then addressed the Committee as follows :</div>
              <p className="p-in-sp">
                Mr. Chairman : It is not without reluctance that I am about to trespass to-day on what I feel must be the exhausted patience of the Committee. The report under consideration, has already been the subject of discussion for several weeks, and we have reached only the third resolution. To-morrow the general debate is to cease, and yet the most important propositions remain to be
                propounded ; they are contained in the minority reports, and can only be properly considered when reached in their order; but it is principally to these propositions that I desire to address my remarks. If the discussion had been confined to the questions arising upon each resolution, as it came up in order, we would have made better progress towards the conclusion of our labors, and
                experienced less unwillingness on the part of this body to accord the time for necessary debate.
              </p>
              <p className="p-in-sp">
                Mr. Chairman—We stand in the presence of grave events. A Union which, but a short time ago, we thought perpetual, has been broken, and a government which we deemed the strongest in the world is threatened with overthrow. At a moment of profound peace, when friendly relations prevailed with all the nations of the earth, when every industrial pursuit was returning its richest rewards;
                when through a thousand channels prosperity was shedding her choicest blessings throughout the land, upon the recurrence of a quadrennial election of Chief Magistrates for the country, seven States, dissatisfied with the result of that election, cast off the Federal authority, and are proceeding to erect a new government for their separate existence. Of the thirty-three stars that
                composed our constellation, seven have shot suddenly from their orbits, and in their eccentric courses now threaten the safety of the entire group. It is with the conditions brought about by these events we are commissioned to deal, being especially charged to provide for the safety of those by whom we have been summoned to act.
              </p>
              <p className="p-in-sp">Chosen by counties, and elected by different constituencies, of necessity we must differ as to the measures most proper to be pursued ; but if we cannot agree upon measures the best, in the opinion of all, may we not yet, under the influence of a common object, unite in such as are reasonably calculated to defend the honor and advance the interest of the State.</p>
              <PageNumber num={68} />
              <p className="p-in-sp">
                A high trust is committed to our hands; the peace of the country, the integrity of the Union, the safety of the Government, the happiness and prosperity of the people, may be affected by our action; therefore, with a full sense of the responsibility, with a just appreciation of the difficulties, with a firm reliance on a beneficent providence and a patriotic desire to discharge our
                duty, let us address ourselves to the task before us. Let us deliberate calmly, act cautiously and move prudently; when a nation's safety and a people's welfare hang on the issue. Let us avoid precipitation and unseemly haste.
              </p>
              <p className="p-in-sp">
                If diversities of views and divided counsels exist among us, we must be tolerant of the differences; they spring, in every case, from honest motive and patriotic desire. The State embraces a wide area, strongly marked by geographical divisions, touching in the east the waters of the Atlantic ocean, and in the west forming part of the great valley watered by the Mississippi and its
                tributaries. Diversities of soil and climate lead to diversities of pursuit, and the unequal distribution of that peculiar property, out of which the troubles that now press us have chiefly sprung. Its physical geography separates and diversifies the social and commercial relations of our people. Between the eastern and western divisions, lofty mountains interpose impassable
                barriers, and compel the products of each to seek different markets. Where they are accessible to the Ohio river, western products find purchasers in the cities and towns that stand upon its banks, and where our territory approaches the Potomac river, its products seek the markets of Pennsylvania and Maryland. With the Southern States, the people of the western division have but
                little intercourse, and it necessarily follows, that a separation of the States by which Virginia, is to be cut off from association with her western neighbors, and to become a border State, must affect unequally the people of the two sections. In such an event it is obvious that the western division would suffer most; and if gentlemen who represent the interests of that division are
                found to entertain stronger union sentiments than some who represent dissimilar interests, it cannot be alleged as any disparagement of their patriotism. The western is as much Virginia as the eastern part, and western interests appeal to us here as strongly for protection.
              </p>
              <p className="p-in-sp">
                It is our duty to consult the interest of the entire State, and to determine finally on that policy and adopt those measures that will best promote the general welfare. We will take but an imperfect view of our duty, it seems to me, if we regard only the localities from which we come and address ourselves to such measures as will best
                <PageNumber num={69} /> promote their peculiar interests. In the pursuit of such objects we can never agree. Where so many and so diversified interests press for consideration, there must be compromise and concession. In no other way can the affairs of State be safely conducted. I come from a part of the State as deeply interested in these questions as
                any other part within its broad limits. Our relations are most intimate with the people of Maryland. Baltimore and Washington are our important markets ; much of what we produce we sell in those cities, and much of what we buy we obtain from them. The prosperity of our county, its industry, its thrift, the welfare of our people are connected with those cities, and if you cut us off
                from them or destroy their prosperity, you strike a blow that must prostrate forever our safety and progress.
              </p>
              <div className="speaker" id="sp1944"><span className="persName">Mr. HALL</span>, of Wetzel—</div>
              <p className="p-in-sp">
                I will state, for the benefit of the gentleman from Fauquier, that he is mistaken as to the important points of trade with the West. Our most important market is New Orleans, in the Confederated States, and whether we start from the North-West with a boat load of coal or of plows, wagons or any thing else necessary for the use and consumption of the Confederated States, or whether we
                start with a boat load of grain, apples or potatoes, every mile we go, every sail we make, we have to meet with the competition of the Northern States in that whole route, and also meet it at the termination of the route. The States to which he refers, Ohio, Louisiana,<span className="note" id="Note9"
                ><span className="noteLabel">1</span>The speaker must have said, or meant to say, Indiana.</span
                >
                &amp;c., produce an immense surplus which we have to meet in competition in the Southern market.
              </p>
              <div className="speaker" id="sp1945"><span className="persName">Mr. SCOTT</span>—</div>
              <p className="p-in-sp">
                I had supposed that the salt of Kanawha found its market in the Western States, beyond our limits; that its coal and its oil found their market there. I know that the cattle of the West, the great product of that section, find their market in the States of Pennsylvania and Maryland ; and I know that there is no trade in cattle which takes its direction to the South. Philadelphia,
                Baltimore, Washington, even New York, are the marts in which that great staple of Virginia finds purchasers. I know nothing about the oats and potatoes to which the gentleman refers.
              </p>
              <p className="p-in-sp">
                When we contemplate the geographical position of the State, its broad extent, the diversity of its soil and climate, the variety of its products, and the social and commercial relations of our people, we must be forced to the conclusion that the best interests of all will be most strongly promoted by a reconstruction of the Union and the preservation of those connexions which a
                common government provides.
              </p>
              <p className="p-in-sp">Virginia is herself a type of the Union, and she furnishes a striking <PageNumber num={70} /> illustration of the practicability of harmonizing and adjusting great and varied interests of a widely dispersed people under the rule of a common government.</p>
              <p className="p-in-sp">
                The States of this Confederacy are bound together by ties too strong to admit of a permanent separation. Social diversities, religious influences, party antagonisms, and fanatical prejudices may, for a time, produce a severance, but they will again become united. The necessity of Union to our national importance, the memories of the past and the hopes of the future, a common
                ancestry, a common interest; and the brotherhood of a century, will work their result. The heavenly bodies that compose our system, are not more perfectly adjusted to the great purposes of the Creator, than the States of this Union seem adjusted to the fulfillment of all the conditions of a great national power. Their position on this continent, their varied climates, their
                productions, the diversified pursuits of their people, establish them as dependencies upon each other, and define them, unmistakably, as separate parts of one great whole. I repel the idea of an "irrepressible conflict." What matters it that nineteen of the States forbid slavery of the African race, while fifteen recognize it? Neither class is responsible for the institutions of the
                other, nor has power over the relations that spring from them, and if in the conflict of parties antagonisms have arisen from this source, injurious to the harmonious working of the Government, we have only to overhaul the Constitution, repair its defects and adjust the balance between the sections to regain all that has been lost, and provide against the recurrence of the evils that
                have produced these estrangements.
              </p>
              <p className="p-in-sp">
                But the Union is broken, and this Convention is assembled to deal with the events that have sprung from its rupture. The withdrawing States have associated under the name of the Confederated States of America, and are now exercising all the powers of an independent nation. Can they be restored to the Union? The adoption by them of the Federal Constitution, with but few alterations,
                as their form of government, and the nature of the alterations to which they confined their action, lead me to conclude that the people of those States will not be averse to the restoration of their former connections, provided such guarantees can be obtained as will protect them in the future against the evils to which the past has exposed them. If I am right in this conclusion, I
                can see no impediment in the way of a re-union which temperate counsels, just forbearance, and the softening influences of time may not remove.
              </p>
              <p className="p-in-sp">
                But for the present, the separation of the Confederated States has <PageNumber num={71} /> given to the non-slaveholding States such a preponderance in the Federal Government over the remaining slaveholding States as to make it incompatible with the safety of the latter to remain permanently associated with them under the present constitution. I will not
                detain the Committee by a statement of the reasons on which this conclusion rests; it is unnecessary, because all concur in it. Every member of this body unites in the demand of constitutional amendments; the demand is a concession of the necessity of amendment ; and the concession of this necessity involves the concession that without amendment it will be unsafe for us to remain
                subject to the present Constitution. There is yet another concession involved in the category, which is, if the demand for constitutional amendments be rejected by the non-slaveholding States, this State will, and the other border slave States ought, to withdraw from the Union; otherwise we should be but acting an idle farce, making demands, and at the same time proclaiming our
                readiness to submit to their haughty refusal.
              </p>
              <p className="p-in-sp">
                But what is to be done? In this grave emergency we are to consider the measures necessary for the public safety. Various propositions are before us; we have the report of the Committee on Federal Relations, four minority-reports from the same Committee, and sundry schemes emanating from individual members of this body. I propose to consider these various propositions, and to inquire
                which of them is best calculated to secure the desired end. This diversity of opinion points to opposite inclinations. There is a Northern and a Southern Confederacy, and we have been told that we have reached a point at which we are compelled to choose between the two—that we must make up our minds to adhere to the Northern or unite with the Southern.
              </p>
              <p className="p-in-sp">
                If it be true that we have reached this point, if our choice be limited to this alternative, for one, I am free to declare that my choice is already made. My interests, my feelings, my sympathies and my instincts attach me to our Southern brothers, and from the first moment that I considered the question I never hesitated in the choice. I care not what guarantees may be given for the
                protection of our slaves; we may write the covenants and they may execute the deed. But on what terms of equality can eight slaveholding States confederate with nineteen non-slaveholding States? The diversity of institutions necessarily gives rise to diversities of interest, and these interests are of a character to be affected injuriously or beneficially by the policy to which the
                government may be committed. But what security can guarantees ha the Constitution afford—what security can covenants and agreements give against a hostile policy? What security <PageNumber num={72} /> can they furnish that the Government will not shape its policy to serve the interests of the dominant majority? In my opinion there would be no security.
                The interests of the free States would control the Government, and those of the slave States be reduced to the condition of humble subordination. Therefore, I repeat, that whenever I am satisfied that events have progressed to the point at which I am to choose between a Northern and a Southern Confederacy, my choice is made.
              </p>
              <p className="p-in-sp">
                But, happily, Mr. Chairman, our choice does not lie between such alternatives; it is rather between different modes of reconstructing the Union. We may reconstruct upon the Federal Constitution with amendments, or upon the Constitution of the Confederated States; or we may reconstruct upon a Constitution to be proposed by the border States. The report of the Committee embraces the
                first plan; declaratory resolutions and propositions of constitutional amendment are to be submitted to the other States for adoption, which are thought to provide for all the exigences of the slavery question, and it is expected that the Confederated States will be willing to unite on them. But the Secessionists dissent from this plan, and propose on their part an ordinance of
                Secession. They desire to sever at once our connection with the Federal Government, and resume for the State her original independent position; here, however, divergent policies separate their councils. One class of them would sue at once for admission into the Southern Confederacy, while the other would demand a consultation of all the States for the purpose of a common adjustment.
              </p>
              <p className="p-in-sp">
                The plan of the Committee assumes a disposition on the part of the people of the free States to adjust our differences on the basis of these amendments, and the willingness of the other States to accede to the same terms. I am myself not very hopeful of these results, but others, more sanguine, think they can be obtained and I am willing to defer to their better opinions. I do so the
                more readily because I am assured that, if these demands be rejected, our councils will be united, and the divisions that now embarrass our action will disappear in a universal resolution to sever a connexion no longer compatible with our safety or honor.
              </p>
              <p className="p-in-sp">
                But there are other reasons why the effort should be made. We are under obligations to our co-States, and we shall thereby discharge the duties which those obligations impose. The conservative masses of the free States will feel its effects; their dispositions will be made more friendly; their energies will be invigorated. The border slave States will be brought, too, into harmonious
                action; experiencing <PageNumber num={73} /> with us the same divisions, their embarrassments will vanish when ours disappear. These States stand together in a common interest—their institutions are the same—and anything that affects the safety of one equally affects the safety of all. The social and commercial ties that unite their people make it
                indispensable that they should act in concert, and this the plan of the committee proposes.
              </p>
              <p className="p-in-sp">
                I entertain the opinion—and I know the same opinion is entertained by others—that if all of the border slave States sever their connections with the present government, and unite themselves with the Confederated States, the States of New Jersey, Pennsylvania, Ohio, Indiana and Illinois will not long remain separated from them. Everything that touches their material interests inclines
                in that direction.
              </p>
              <p className="p-in-sp">To dissipate our divisions, therefore, and unite the people in entire harmony, we have only to proceed according to the plan of the committee; for when the amendments are rejected—and gentlemen on the other side tell us they are sure to be rejected—the border States will withdraw together, and the work of re-construction can instantly begin.</p>
              <p className="p-in-sp">
                But it is objected that this process is too dilatory, and immediate secession is demanded. I desire to know in what condition an ordinance of secession would place us; and when I appeal for information to the advocates of the measure I find them divided in counsel and unsettled in purpose. Some contemplate an instant union with the Confederated States, while others insist that any
                movement in that direction must be delayed until the co-operation of the States of North Carolina and Tennessee can be obtained. They do not, however, explain how this co-operation is to be brought about, what time will be required to effect it, or what the condition of the State will be while the experiment is being made. They contemplate only the final result, and disregard
                entirely all intervening difficulties. To this class of secessionists I beg leave to say—since they find impediments in the way which can only be removed by the concerted action of other States, and the success of their scheme depends at last upon the concurrence of others—will it not be as well to remove those impediments and secure that concurrence in advance, and so avoid the
                hazard to which premature action might possibly expose us? I understand the gentleman from Princess Anne
                [<span className="persName">Mr. WISE</span>] , and the gentleman from Bedford [<span className="persName">Mr. GOGGIN</span>] , to be of this class of secessionists.</p>
              <div className="speaker" id="sp1946"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I would rather that the gentleman should not undertake to state my position now, because I am very sure that he has not my position in his mind correctly or fully. I will place myself in the <PageNumber num={74} /> attitude where I wish to stand when I can get the floor with lungs to occupy it.</p>
              <p className="p-in-sp">
                While I am up, permit me to interrupt the gentleman further. I did not interrupt him at the time because I did not wish to cut the thread of his very strong, well linked presentation of views. He has twice asserted, and his name is authority for the assertion, both in point of moral and intellectual weight, that there is no one member of this body, who, in debate here, in debate in
                the Committee of 21, or elsewhere, within his knowledge, has avowed or declared the doctrine of submission. Am I right in that?
              </p>
              <p><span className="persName">Mr. SCOTT</span> bowed assent.</p>
              <div className="speaker" id="sp1947"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                Then the gentleman has heard no such declaration. Let me inform him that he would be in a bad predicament if he should happen to go home, and they who were arraigning his cause here should show him that on the 16th day of March—this, I believe, is the 3d day of April—on the 16th day of March, 15 and 3, or 18 days ago, a member of this body—I mean the Hon. gentleman who represents the
                county of Marshall
                [<span className="persName">Mr. BURLEY</span>]
                has not only laid upon your table a printed document, which I hold in my hand, avowing submission, but avowing the right of coercion to submission and not only that, but he has avowed in print here, as his doctrine, the right of the minority of this State, when she, in the exercise of sovereign and independent power, shall place herself in the attitude of secession, or shall resume
                the powers granted by her under the Constitution—he claims even the right of the minority of the people of this State to rise in revolution and resist their own State against the exercise of the right of secession. I put the document into the gentleman's hands, the resolutions submitted by <span className="persName">Mr. BURLEY</span>, on the 16th of March, 1861, and when the gentleman
                reads it he will understand that there is at least one person on this floor who avows submission, and who avows more than submission—the right of revolution against the State, against secession. And I say here authoritatively, that there are others here who, in my opinion, are as bad, and what is worse, who plant themselves upon the fact that they claim their constituency are the
                same way. I do not believe it as to their constituents, but I do believe it as to more than one member of this body.
              </p>
              <div className="speaker" id="sp1948"><span className="persName">Mr. SCOTT</span>, of Fauquier—</div>
              <p className="p-in-sp">
                I have not had my attention drawn to the document alluded to by the gentleman from Princess Anne, and therefore will not undertake to criticise or characterize it. That gentleman is upon the floor, and at the proper time can speak for himself. <PageNumber num={75} /> But if he be an exception to the rule, so far as I am informed, he constitutes a
                solitary exception.
              </p>
              <p className="p-in-sp">
                Now, sir, in reference to the position of the gentleman from Princess Anne, I certainly will leave it to himself to define it. I understood that gentleman, like the gentleman from Bedford, was an immediate secessionist, but that he did not contemplate a junction with the Southern Confederacy immediately upon the ordinance of secession; that he was one of that class of secessionists
                who propose, after the ordinance has been adopted, to wait for co-operation. I repeat to the gentlemen constituting that class, that if co-operation is necessary to the consummation of their purpose, if notwithstanding secession, they cannot move without the concurrence of other States, is it not better, is the policy not safer and wiser to seek co-operation first, and to secede
                after it shall be obtained?
              </p>
              <p className="p-in-sp">
                But the other class have no difficulty. They are for no delay. They want instant secession, and when secession is obtained they are for an instant junction with the Southern Confederacy. They care nothing for obstacles that are in the way. What do they care for North Carolina? What do they care for Maryland? What do they care for Tennessee? With a bound they clear all these barriers.
                But did it ever occur to these gentlemen to inquire whether the offering they propose to make to the Southern Confederacy, will prove an acceptable offering? Did it ever occur to them to think whether the Confederate States, separate from Virginia by intervening States, would deem it wise and consistent with their own safety, to involve themselves by such a connection? Did it ever
                occur to them that reciprocal obligations would arise from the connection which neither party would be in a condition to discharge? Virginia would owe obedience to the laws of the Confederate States if she should join them. They will owe her protection. In case of collision with the Federal authorities, or in case of hostility with her neighbors, in what manner would the Confederate
                States give her protection? The territory of North Carolina and Tennessee interpose between them, and the communication by water would be possessed by the enemy. Without the right to approach her by land, without the power to approach her by water, in what way, I ask, would that obligation of protection be complied with? It would seem to me, Mr. Chairman, that the union would be one
                of doubtful propriety; one calculated to impair the power of the Southern Confederacy, rather than to increase it; one calculated to increase the risk of hostile collision—one that, indeed, as I will presently show, would render collision almost a necessity. Do gentlemen <PageNumber num={76} /> mean to rely upon the secession of Maryland, the secession
                of North Carolina, and the secession of Tennessee, as removing these obstacles out of the way? Are they, too, co-operationists? Are they like the gentleman from Bedford, and others that belong to his class, at last dependent for the success of their measure upon the co-operation of these other States? I think I have heard some of the gentlemen say—if none others, at least the
                gentleman from Orange
                [<span className="persName">Mr. MORTON</span>]
                -that if Virginia would secede, these other States were certain to go. If they are certain to go provided Virginia goes, will they not agree with Virginia beforehand to go? And if their co-operation is necessary, if their concert of action is essential to the feasibility and security of the scheme, why not take the precaution of consulation beforehand? Why not extend to them that
                courtesy, that respect, that common courtesy and common respect, that the relation of the States and their dependency upon each other would seem to demand? Do gentlemen fear discordant counsels upon the consultation? Do they fear that these States, advised with in advance, will refuse to secede? Is that their apprehension? That would be inconsistent, Mr. Chairman, with the
                declaration that if Virginia goes out, they will go also. I think, if they will follow the action of Virginia, if they are so connected with Virginia, if they will be so influenced by her movement as to require them to follow the direction in which she leads, this influence will control their counsel, and lead them to agree to her proposition in advance.
              </p>
              <p className="p-in-sp">
                But it is possible at last that they may refuse to secede. Then, if a course of action is adopted by this State which may offend their dignity, which may justly wound their pride, that course of action may engender a spirit of antagonism, a spirit of hostility towards our people, that would be most likely to defeat the very end and purpose which gentlemen desire to accomplish. Is it
                not better, then, to confer beforehand? Is it not better to take counsel in advance, before a measure so important is adopted? If concert of action, if harmony of purpose, if Union is desirable, will not these be best obtained by a respectful courtesy?
              </p>
              <p className="p-in-sp">
                Since then, at last, all agree that to make a union of Virginia with the Confederate States practicable, we must have concert of action with our neighboring States, why may we not all agree in that line of policy which leads to friendly consultation? Is anything to be lost by it? I apprehend not. Is there not something to be gained by it? It seems to me there is. In what condition
                would the instant and separate secession of Virginia place her? We may adopt an ordinance <PageNumber num={77} /> of secession, and put the State out of the Union. We, then, would tear her from her present associations. Maryland would be in the Union, North Carolina, Kentucky, Tennessee, Missouri would all be in the Union; Virginia would be out. And what
                would be her relations to the General Government? That Government could not forsake Maryland. It could not forsake North Carolina, Tennessee, Kentucky, or Missouri. So long as they remain parties to the contract, protection is due to them. The Federal Government, while remaining at Washington, would, of necessity, preserve its armed position, because the secession of Virginia would
                imperil its safety to a greater extent. The divided state of opinion in Maryland would be at once a source of danger, of imminent danger. Stimulated by the secession of Virginia, the people of Maryland would be driven to action. Discordant counsels would prevail there. Division now marks the sentiment of their people, leaving it doubtful on which side the majority remains; but the
                stimulant given by the secession of Virginia, would quickly excite to action, and precipitate a conflict that would end speedily in a hostile collision with the Federal power.
              </p>
              <p className="p-in-sp">
                Separate secession on the part of Virginia has been commended as a measure of peace. I look upon it, Mr. Chairman, as a measure of war. I do not believe that the people of this country would remain at peace for sixty days. I believe it would bring on collision with the Federal forces. The point of collision would be the seat of the Federal Government. That Government could not remain
                in Washington with safety under the condition of things that the separate secession of Virginia would bring about. Instead, therefore, of its commending itself to me as a measure of peace, I reject it as a measure of war—of unnecessary war, because if we take counsel with the other States on the border, and concert action with them, peace will be preserved. We should reach a
                peaceable solution of the difficulties which now surround us and press on our attention. With the withdrawal of Maryland, Virginia, North Carolina, Tennessee, Kentucky and Missouri, the Federal authorities would evacuate the city of Washington. They would not remain there if they could; they could not, if they would. An absolute necessity for them to remove elsewhere would arise, and
                they would obey the law of that necessity.
              </p>
              <p className="p-in-sp">
                The District of Columbia would be retroceded to Maryland, reincorporated with her own territory, and restored to its original jurisdiction. The government would be removed to another State, and that point of collision would be avoided. Therefore, with a concert of action between the border States there will be peace; with the separate
                <PageNumber num={78} /> secession of Virginia, there will be war—and the most horrible war, civil war.
              </p>
              <p className="p-in-sp">
                If, then the one policy looks to the preservation of peace, and the other to the inauguration of war, which ought to be pursued? Do gentlemen desire war, or do they prefer peace? On that determination ought to depend—it seems to me—the selection of the one or the other line of policy. Gentlemen all agree that these border States stand on the same footing, and are surrounded by
                circumstances which affect all alike. They agree that if one go, all must go ; none can remain. They agree that their interests are the same, their destiny the same.
              </p>
              <p className="p-in-sp">
                If, therefore, a prudent disposition is to prevail, we will adopt measures for consultation. I scarcely know how to argue the proposition. Its statement is its argument. Why then does this diversity of views prevail? Why is it that the one side insists so pertinaciously on instant secession, and the other on the policy of previous consultation? There must be some motive that has not
                been disclosed. There must be some hidden reason yet undeveloped. If concert of action is necessary, that is best attained by previous consultation. Why then this diversity of sentiment? There must be a reason for it, and yet I have heard no reason assigned. What has been given to us as reasons, are too inconsequential to account for the diversity. Therefore, I conclude, there is
                some motive which yet lies hid. There is some reason which yet remains to be explained. What is it? I do not know that I shall be successful in discovering it, but I shall make the attempt. Gentlemen on the one side tell us that the propositions for Constitutional amendments are idle forms and ceremonies; that the people of the North will never assent to them; and yet I think they
                are actuated by fear, least perchance by this negotiation, some adjustment might be made which would be satisfactory to the border States.
              </p>
              <div className="speaker" id="sp1949"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I wish to understand whether the gentleman in his imputation of the motives of those who want to go out of the Union, means to apply it to me?</p>
              <div className="speaker" id="sp1950"><span className="persName">Mr. SCOTT</span>, of Fauquier—</div>
              <p className="p-in-sp">I had not the gentleman in my mind at all.</p>
              <div className="speaker" id="sp1951"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">It is strange the gentleman had not me in his mind when he classed me with the gentleman from Bedford [<span className="persName">Mr. GOGGIN</span>] , as one who wanted to go out of the Union immediately.</p>
              <div className="speaker" id="sp1952"><span className="persName">Mr. SCOTT</span>, of Fauquier—</div>
              <p className="p-in-sp">I was speaking of another class.</p>
              <div className="speaker" id="sp1953"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I have only to say, for myself, that I have no fear about an adjustment being made with the North, for my desire is to form <PageNumber num={79} /> a new and a better Union of the North and South; but I do fear the adjustment which some gentlemen are ready to make with the North. That is what I fear.</p>
              <div className="speaker" id="sp1954"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Chair did not understand the gentleman from Fauquier as impugning the motives of any gentleman on the floor. It is certainly not in order to do so.</p>
              <div className="speaker" id="sp1955"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">His inquiry is as to the fact of a motive on the part of those who wish to move first and to operate afterwards; and he asserts, as a fact, that one motive is the fear that an adjustment may be made. That is all legitimate.</p>
              <div className="speaker" id="sp1956"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">Certainly. So the Chair understands.</p>
              <div className="speaker" id="sp1957"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I only want to know whether that imputation is applied to me, so that if I have an opportunity to reply I can answer.</p>
              <div className="speaker" id="sp1958"><span className="persName">Mr. SCOTT</span>, of Fauquier—</div>
              <p className="p-in-sp">No gentleman, I am sure, can have so misconceived me as to suppose that I was indulging in any suggestion of an improper motive.</p>
              <div className="speaker" id="sp1959"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">So the Chair understood.</p>
              <div className="speaker" id="sp1960"><span className="persName">Mr. SCOTT</span>, of Fauquier—</div>
              <p className="p-in-sp">
                I was attributing a motive to those among us, who are the most ardent friends of the Southern movement, arising out of an apprehension from which I am not free myself. I think that many of them are, perhaps, actuated in their hostility to this course of proceeding by reason of an apprehension that an adjustment may be made which will fix the destinies of the border States with the
                Northern Confederacy. Gentlemen on the other side are actuated, I think, by a similar apprehension, proceeding from the opposite direction.
              </p>
              <p className="p-in-sp">It is feared that if an ordinance of secession be adopted, the State will be committed to the Southern Confederacy upon a permanent separation from the other States. One fears a Northern, the other a Southern connexion, and hence the diversity.</p>
              <p className="p-in-sp">
                But, Mr. Chairman, both sides are for re-construction. One proposes to re-construct the Union upon amendments to the Federal Constitution, to be sought for through the action of the non-slaveholding States ; the other proposes to re-construct upon the Constitution of the Confederate States. There are no disunionists among us. I feel at liberty to assert that there is no member of
                this body who would not, if he could, restore the Union to its former condition, if, by the restoration, that feeling could be restored between the people of the two sections which existed when the Union was formed. Every member who has spoken, has avowed sentiments friendly to the Union. Every one has declared a desire to see the Union re-constructed. We are all Unionists. We are
                all for re-construction; for mending the broken parts; for <PageNumber num={80} /> re-uniting its shattered fragments, and the same sentiment prevails among the people. When expressions hostile to the Union gain utterance, it is because re-construction is despaired of. The diversity is not between those who are for the Union and those who are against it,
                but between those who are hopeful of re-construction, and those who are hopeless of it. It could not be otherwise. If we consider what the Union has accomplished in the past, and what it is capable of accomplishing in the future, every heart must leap with glad emotions at the prospect of its restoration. From thirteen subject Colonies lying along the Atlantic border, we have
                increased to thirty-four sovereign States, stretching broadly to the Pacific Ocean. From three millions of people, we have increased to more than thirty millions. We have subdued a wilderness and peopled it ; impenetrable forests have given place to a breathing, living population, and silent prairies have become vocal with human voices; cities, rivaling the proportion and
                magnificence of those of ancient days, every where dot the surface of our broad land, as if called suddenly into existence by some great magic power. In agriculture, manufactures and commerce, in the arts and sciences, in all that constitutes a nation's wealth, and a nation's greatness, in the short space of less than eighty years, we have reached an eminence that places us in the
                foremost rank of the nations of the earth. And for our own peculiar institutions, what has been effected? From five States that recognized slavery, we have increased to fifteen. From 600,000 slaves, worth one or two hundred dollars each, we have increased to 4,000,000, worth from five hundred to a thousand dollars each; and, upon the products of their labor, the world depends for its
                prosperity and progress. It cannot, therefore, be a matter of surprise that, in a Convention, composed of those who represent all the varied interests of this great Commonwealth, that a Union sentiment should pervade the entire body. Yes, sir, we are all for union, and differ only as to the proper mode of proceeding. Some would re-construct upon amendments to the present
                constitution; while others would attempt the same thing under the constitution of the Confederate States. But they cannot agree; a cause of division between them exists, which, I fear, will, to the end of our deliberations, keep them apart. Is there no common ground on which they may meet? Is there no way to avoid the Scylla, on the one hand, and Charybdis on the other? Is there no
                safe middle passage?
              </p>
              <p className="p-in-sp">
                Permit me to call the attention of the committee on the amendment which I propose to offer to the 14th resolution, when that shall be reached. That amendment provides that, in the event that our propositions <PageNumber num={81} /> of adjustment shall be rejected by the non-slaveholding States, the border slaveholding States shall each assemble in
                Convention, and appoint delegates to a Congress of those States, to be charged with the duty of proposing an amended Constitution, upon which the border slaveholding States, the Confederate States, and such of the non-slaveholding States as may concur in the same, may safely unite; at which Congress, the Confederate States, and the concurring non-slaveholding States are to be invited
                to send Commissioners. The border States will be in Convention, ready to act finally; a constitution will be agreed upon, satisfactory to the Confederate States, which can be submitted at once to the Conventions in session, when, by the same act, a separation from the present Union and a re-union with the Confederate States can be effected. Thus will all unnecessary delay be avoided,
                and the hazard and uncertainty arising from separate action. Thus, too, will a peaceful solution of the question be obtained; because, when the border States move off together, the dissolution of the present Union will be complete.
              </p>
              <p className="p-in-sp">
                But if separate secession be de'termined upon, in what attitude will the State be placed? She will be sent to knock at the door of the Southern Confederacy an humble petitioner for admittance. We are told that friendly hands will be held out to greet her; but if she goes alone she will take the position which the Confederate States may choose to give her; she can make no terms. Does
                the Constitution of those States suit her? Can any advocate of this policy answer the question? Are her interests identical, in all respects, with the interests of the Cotton States? On the contrary, are there not diversities between them? She has had no voice in the construction of the Government, and can have no influence in the direction of its policy. I have said that if the
                Southern States remain permanently separated from us, that, in my opinion, the border slave States could not safely confederate with the non-slaveholding States, because the policy of the Government would be moulded with exclusive reference to the interests of the dominant majority, and I apply the same argument to the Southern Confederacy. Can those who would precipitate us into the
                Southern Union, give any assurance that our peculiar interests will not be subordinated to the interests of the dominant majority of our new associates?
              </p>
              <p className="p-in-sp">
                Is there any motive for precipitate action? It was said that the fourth of March ought not to find us in the present Union, but that day has passed, and whatever disadvantage was anticipated from the inauguration of the new administration has now been encountered, <PageNumber num={82} /> and that argument fails. May we not, therefore, proceed with
                deliberation, and place ourselves in a position to demand fair terms? If the border States act together they will assume the position to which their strength and importance justly entitle them. The Confederate States are not more necessary to them than they are necessary to those States; in fact, the Union will be of mutual advantage. The one will derive no less benefit from the
                union than the other, and by acting together the border States can command honorable and just terms of confederation. Why should any one entrusted with the great interests of the State desire to deprive her of this advantage? Let us then unite upon this proposition. Why should we differ about it? It is objected that in the progress of the scheme recommended by the Committee an
                adjustment may be reached by which the border States may be permanently separated from the Confederate States, but this objection comes from those who have heretofore insisted that the propositions to the Northern States are an idle and useless ceremony. If this be so; if the ceremony is indeed merely formal, why may they not defer so much to the opinions of others as to go through
                these forms and ceremonies, when the result must be to unite us all in a common policy and bring about eventually the very measure upon which their own party is so intent? If the Northern States reject our proposed amendments, no alternative will be left us but secession, and the other border States will stand in the same necessity. There can be no apprehension of any middle
                confederacy; no one proposes that form of adjustment. If, therefore, we separate from the Northern States, we must join the Southern Confederacy, we will only have to adjust the terms of Union.
              </p>
              <p className="p-in-sp">
                But does any one fear an adjustment with the Northern States upon the plan of the Committee? I know there are some who yet remain hopeful of a settlement in that way, but I am not one of them. I once thought such a settlement practicable. I thought the people of the non-slaveholding States would consent to proper amendments of the Constitution; that an intelligent perception of the
                blessings of Union and feelings of common brotherhood would overcome the prejudices that stood in the way. I thought the same influence prevailed with the people of the Southern States; that underlying the excitement by which they had been precipitated into revolution there was evidence of a strong feeling for the Union.
              </p>
              <p className="p-in-sp">Under these impressions, I left my home hopeful of a settlement, but I regret to say this hope has vanished.</p>
              <p className="p-in-sp">
                The adjustment of which I am speaking is to be sought by amendments <PageNumber num={83} /> to the present Constitution, and there are but two modes of amending that instrument. One is by propositions of amendment emanating from Congress, the other by the instrumentality of a National Convention. Is either mode practicable? The first has been already
                tried and failed. The recommendations of the Peace Congress were rejected; the Congress refused to submit them to the States. A National Convention can be called only upon the demand of two-thirds of the States, and any amendment recommended by it requires the concurrence of three-fourths of the States to make it part of the Constitution. Can two-thirds of the States be got to call a
                Convention, and can three-fourths of them be got to concur in satisfactory amendments? According to the Northern idea, the Union is yet composed of thirty-four States. They reject the fact of secession, and still claim the seven Confederated States as rightly subject to the Government. Upon this hypothesis twenty-three States will be necessary to call a Convention, and twenty-six
                will be required to ratify amendments. If the Confederate States be left out of the enumeration, eighteen States will be necessary to call a Convention, and twenty-one to ratify amendments. Can the concurrence of the requisite number of States be obtained? In any computation it will be conceded that the seven Confederated States must be left out; they have formed a separate
                government, and repudiate the Federal authority. Upon the one hypothesis, therefore, the non-concurrence of two of the States, and upon the other, that of six, will defeat a settlement.
              </p>
              <p className="p-in-sp">
                I say, then, Mr. Chairman, that I am not of the number who look hopefully to this form of adjustment. I am willing, however, to try it. I am willing to defer so much to the wishes of others, especially as I am assured that when it fails, the divisions and diversities that now mark this body will disappear. To dissipate our divisions and obtain united action, may we not make this
                small sacrifice of individual opinion?
              </p>
              <p className="p-in-sp">
                At one time I thought the experiment of the Southern Confederacy of doubtful issue. Time has removed that doubt. We have seen those States advance firmly and dispassionately in their work of revolution ; we have seen them adopt a government suited to their exigencies, on which their people repose in confidence. We see the opposition at one time manifested against it, daily growing
                less, and the Government itself becoming daily more firmly established. I have, then, on both sides, facts upon which to rest my opinion. In the North I see less and less disposition to re-establish the Union, and in the South less and less necessity for it. We met here under circumstances of great <PageNumber num={84} /> excitement and pervading
                interest. For two months we have been debating questions that concern the peace of the country, the integrity of the Union and the safety of the Government; and we have held this debate in the very presence of the high functionaries to whom the powers of government have been recently confided. But not a word has been spoken to relieve our anxiety, not a measure projected or promised,
                having reference to the preservation of the peace or the restoration of harmony; nothing has been done to relieve the nation from the apprehensions that oppress it.
              </p>
              <p className="p-in-sp">
                We hear of intentions to reinforce Southern forts, to supply Southern garrisons; the City of Washington is still in the keeping of the military, and the President and his Cabinet move by day and repose by night, under the protection of the Federal soldiery. Obdurate silence pervades all departments; no word of explanation, no kindly counsel, no expression of hope comes to dissipate
                the gloom.
              </p>
              <p className="p-in-sp">
                If from the functionaries at Washington, we turn to the people by whom they were elevated to their places, we discover the same stolid indifference. I say then, however buoyant was my expectation, how- ever strong my hope of a satisfactory adjustment through the free States, that expectation is gone, that hope has fled. I do not believe it practicable, under existing circumstances,
                to reconstruct the Union by either mode of amendment provided by the Constitution. I do not believe it practicable under the policy of the immediate secessionists. If Virginia attaches herself now to the Southern Confederacy, and the other border States establish themselves at once at Montgomery, they will place an impossible gulph between them and their Northern associates. While,
                therefore, I have little hope of re-construction by the one scheme, I have as little by the other.
              </p>
              <p className="p-in-sp">The border States must hold firmly to the position they occupy. Central as to the other States, their interests are divided, flowing partly into a Southern and partly into a Northern channel. They occupy a position in which they will suffer greatly by a separation from either.</p>
              <p className="p-in-sp">
                They are the fortress of the Union, and Virginia is its citadel ; let us never surrender it. If we go to Montgomery, that is too far South for our Northern brethren to follow after us. It will be an act of estrangement, separating us forever; it will be a conversion into enemies of those who are friends; it will be the erection of a barrier in the way of re-construction, impassable
                forever. Let us, then, hold firmly to our position. If the border States move in concert, and withdraw together from the Union, the present Government will abandon the capital; its speedy removal will be an absolute necessity. With the Federal <PageNumber num={85} /> Capital in our possession, half the work of reconstruction would be accomplished—half
                did I say, Mr. Chairman? I had almost said the work itself would be already accomplished. In the possession of the Federal Capital, with alterations and amendments to the Montgomery Constitution satisfactory to the border States and the Confederate States, a government would be established having all the prestige of the one it would supplant. It would be where the public mind has
                been habituated to behold the Government ; it would be where the Northern man could come without prejudice, where, in my judgment, at no distant day, New Jersey would come, Pennsylvania would come, where Ohio, Indiana and Illinois would speedily come, when the accession of the remaining States would be but a question of time.
              </p>
              <p className="p-in-sp">Not believing in the practicability of re-constructing the Union upon amendments to the Constitution under a Republican dynasty, and having no hope that direction if we precipitately establish ourselves at Montgomery, I would adopt the middle plan.</p>
              <p className="p-in-sp">
                With the border States assembled in a Congress, with their separate Conventions in session, with all the machinery in readiness for final action, with the strong inducements to a union with the Southern States, can any one doubt that terms of association satisfactory to both sides will readily be agreed upon? Can any one doubt that the accession of the eight border States, together
                will be far more serviceable and acceptable to the Southern States than the separate accession of the State of Virginia? Going alone she would carry no strength to her new allies, but add to their embarrassments; going together with her seven neighbors, she would carry the strength of all, giving solidity to the compact and the security which solid structure imparts.
              </p>
              <p className="p-in-sp">
                But it is objected that the scheme of the border State Congress surrenders the ultimatum of the 1 1 th resolution and subjects Virginia to the determination of the other States. There is no foundation for this objection; for nothing in the proposed amendment impairs the force of that resolution. She may carry its determination into effect prior to entering into the Congress, or she
                may forbear its execution until a future time. I propose to leave the choice on that point perfectly free. She may abide by the recommendations of the Congress, or she may reject them. I propose to bind her in no way, but to leave her free, with all the lights then before her, to pursue that policy which enlightened statesmanship may dictate and the welfare of her people demand.
              </p>
              <p className="p-in-sp">
                Can any one doubt that by the time the proposed Congress is to assemble, public opinion in our own State, and all the other border <PageNumber num={86} /> States, will have ripened upon these questions, so as to lead necessarily to their easy solution? Can any one doubt that by that time the minds of all of us will be united in a common policy? Events
                are on the wing, and each successive flight contributes its influence towards instructing our judgments and dispelling the darkness that obscures our way.
              </p>
              <p className="p-in-sp">
                At this time, our councils are divided; those of the other border States are in like manner divided; our people are divided; opposing sections of the State are arrayed against each other; and to set before them now an ordinance of Secession, taking Virginia alone out of the Union, will be to aggravate these divisions and antagonize the sections, until final agreement shall be made
                impossible. With Maryland, North Carolina, and Tennessee remaining in the Union, and acknowledging the authority of the Federal Government, in what condition would an ordinance of secession place Virginia? The industry of our people is now depressed; would such an act remove the depression? Their commerce is embarrassed; would separate secession dispel the embarrassment? Would it not
                rather deepen the depression and increase the embarrassments? Nay, would it not strike a fatal paralysis into every fibre of the entire system?
              </p>
              <p className="p-in-sp">
                How long would she remain in her isolated condition? When would the interposing States follow her example? In the mean time, what would become of Harper's Ferry, of Fortress Monroe, of Fortress Calhoun, of her foreign commerce? How would it be possible to avoid collision and bloodshed? Secession now, under surrounding circumstances, will necessitate war. To ordain secession is to
                declare war, and to involve our people, without preparation, in all its horrors. If I represented the Southern States, and was seeking to influence the action of Virginia, with a view to their interests, I might advocate that plan, because thereby the attention of the enemy would be attracted to another quarter ; but as I represent Virginia, and am seeking her safety, I would avoid
                it; necessity may compel to it, choice never.
              </p>
              <p className="p-in-sp">Concert among the border States will avoid war, or if that extremity at last be forced upon us, we shall have the strength to meet it that union gives.</p>
              <p className="p-in-sp">
                But, Mr. Chairman, if we turn our thoughts from these painful contemplations, and lift them to the cheerful hopes of friendly adjustment and re-construction, there is much in the propositions of my amendment commending it to approval; some of its advantages have already been pointed out, but there is another to which I will advert. If the adjustment is reached upon the plan of the
                Committee's report, <PageNumber num={87} /> or upon the basis of the Montgomery constitution, something would still be wanting necessary for the harmonious working of the renovated system. Neither would relieve us from the danger of a recurrence in the future of the very evils we now suffer. The effects of either, doubtless, would be palliative at first,
                but the root would remain, and instead of eradicating the plant, we would be only removing a part of the rank soil that nourishes its growth.
              </p>
              <p className="p-in-sp">
                The slaveholding States and the non-slaveholding States have diversities of interests—the differences of soil, climate, labor and productions, necessarily give rise to them. When associated under a common government, these diversities must work their result. Unless evenly balanced, they compel to conflicts for governmental supremacy. The divisions will become sectional, and the
                conflicts will rage until victory brings estrangement and dissolution as its necessary attendants. The Constitution of the United States has this defect. Notwithstanding its departmental divisions, and separation of powers, and notwithstanding its various modes of official selection, the entire framework of the Government rests, at last, upon the power of the majority. Popular
                majorities elect the President, who appoints the Judges; they elect the Legislatures that appoint the Senators, and mediately or immediately give form and direction to all governmental action. If we re-construct upon the plan of my amendment, we can remedy this defect, and by engrafting upon our amended Constitution the principle of sectional equality in the Senate, forever extirpate
                the noxious plant from whose wild growth our misfortunes spring.
              </p>
              <p className="p-in-sp">
                The resolutions reported by the Committee on Federal Relations, command my approval. The discussion of them will be more appropriate when they come up separately for adoption; but I will take this occasion to say that they proceed upon the true theory of constitutional construction. They recognize the States as the true parties to the government compact ; I mean the States in the
                sense of the people of the States; they recognize the original sovereignty and independence of the States, and deduce from these premises the right of the States, for just cause, to sever their connections with each other; they recognize the government as an agency of the parties, and the functionaries who administer it, as their trustees and agents, and thence deduce a total want of
                authority on the part of the government, or any of its functionaries, to hold a State, or the people thereof. to accountability for the exercise of that right. When a State withdraws and breaks the compact, the offence, if any, is committed against the other States, and they only have authority to deal with it. The <PageNumber num={88} /> government can
                deal with those acts only that constitute, properly, offences against the government—such as sedition, insurrection, and revolt, in some phases.
              </p>
              <p className="p-in-sp">
                The conclusion seems to follow, that, when a State withdraws, and severs its connection with the other States, it is the duty of the government to remain quiescent, and to do no act to forestall the proper judgment of the parties, but to leave them free to determine in their own time, according to their own pleasure, the manner in which the case shall be dealt with. The parties
                injured may adjudge the cause sufficient, or they may acquiesce in the act of the withdrawing State and discharge it from responsibility. For the agent to interfere, would be to usurp the authority of the principals, and to exercise a power in derogation from their rights. This is according to the American system, which derives the power of government from the consent of the
                governed. The European system is different, and we have only to contrast the two systems to be satisfied as to the conclusions stated. Anciently, by divine right, now, by the doctrine of legitimacy, monarchs claim an inheritance in the powers of government descendible according to certain ascertained and established laws. They represent in their persons the sovereignty of the nation,
                and the subjects owe to them allegiance and obedience. If the subject disobey, the monarch may enforce his obedience, and the power extends to any number, to the majority or to the whole. If the power be adequate the nation may be controlled.
              </p>
              <p className="p-in-sp">
                But where States are parties to a government compact, and for their mutual convenience create an agency, endowing its functionaries with limited powers, it would seem absurd to suppose that the agent could rise superior to the principals, and according to its pleasure control their will. If the government can coerce the obedience of one State, it may coerce half of the States, or a
                majority of them, or, if it possesses the power it may, by the same reasoning, coerce the entire number. So that if the President should chance to have a full treasury and a numerous army, although all of the States might determine to withdraw from the Union, his power might coerce their will.
              </p>
              <p className="p-in-sp">I will not protract the discussion further, or trespass any longer upon the indulgence of the Committee.</p>
            </div>
            <div className="section" id="vsc1965.v3.2.2.3">
              <h3><span className="headingNumber">1.2.3. </span><span className="head">AFTERNOON SESSION</span></h3>
              <p>The Convention re-assembled at 4 o'clock, P. M., and resumed, in Committee of the Whole, the consideration of the report of the Committee <PageNumber num={89} />, of Fauquier, had the floor.</p>
              <div className="speaker" id="sp1961"><span className="persName">Mr. SCOTT</span>, of Fauquier—</div>
              <p className="p-in-sp">Mr. Chairman, I have become so hoarse since the recess, that I am obliged to decline any further discussion. I thank the Committee for its attention to what I have said.</p>
              <p><span className="persName">Mr. RICHARDSON</span> obtained the floor.</p>
              <div className="speaker" id="sp1962"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">With the consent of the gentleman from Hanover I desire to ask my friend from Fauquier a question.</p>
              <p><span className="persName">Mr. SCOTT</span>, of Fauquier, made a remark.</p>
              <div className="speaker" id="sp1963"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">The gentleman says he is so hoarse that he cannot answer. He does seem distressed and, of course, I will not vex him in his fatigue.</p>
              <div className="speaker" id="sp1964">
                <span className="persName">Mr. RICHARDSON</span> addressed the Committee up to the hour of adjournment : <span className="note" id="Note10"><span className="noteLabel">2</span>Mr. Richardson's speech was delivered on April 3 and 4, but the point of interruption is not indicated. All of the speech has been printed as part of the Proceedings of April 3.</span>
              </div>
              <p className="p-in-sp">Mr. Chairman : I am not without hope that some member of the Convention will express or thank in my behalf the kindness uttered by the worthy gentleman from Petersburg [<span className="persName">Mr. BRANCH</span>] , of my distinguished friend from Albemarle, who sits on my right [<span className="persName">Mr. HOLCOMBE</span>] , for whose accommodation the former gentleman [<span className="persName">Mr. BRANCH</span>] , announced his willingness to adjourn, so that the latter gentleman [<span className="persName">Mr. HOLCOMBE</span>]
                , might be heard when the Convention was not fatigued, on the ground that he had troubled this body but little with speeches. I have presumed to speak here but twice—once less than ten minutes, advocating the election of a friend to an office to which he was preferred by the Convention; and once again less than fifteen minutes in explanation of a resolution looking to the military
                defence of the State, which I had the honor to propose. Though I have been firm in my sentiments on the momentous matters to be considered by Virginia in this the highest exercise of her sovereignty, I confess to some vacillation as to whether I should speak here or not. For, notwithstanding the interminable clamors and fault-findings of the respective outside partizans of the
                majority and minority, with regard to those who differ from them, I see around me gentlemen who, from character, talents and position, are the most distinguished men of the State, and my embarrassment is increased by the fact that I am, for the first time, a representative of the people in a body like this ; so that it is with awe that I attempt to address them. Nor should I make the
                effort at all had I not reason to believe that my constituents desire their voice, however humble when spoken by me, to be heard in this hall. Certain mental peculiarities of mine, sir, lead me, in considering a subject, to look to its historical surroundings. I, therefore, <PageNumber num={90} /> propose first to take a glance at the origin of the
                Federal Union—of the many ties which should have united forever in fraternity and affection the North and the South—to show while the former section and a foreign power are, for the most part, responsible for slavery here, they have sought, with unhallowed hands, to destroy both the institution and those on whom they forced it; that the people of the North have rudely and cruelly
                crushed the mutual love which bound us together, and have cherished and do cherish towards us such feelings as render a longer union with them almost hopeless. Proceeding a step farther in the argument, and considering our responsibilities in connection with a once glorious but now disrupted nationality, I shall hold the position not only that our destiny is with the Southern
                Confederacy, but that the best mode of re-constructing the Union is promptly to identify ourselves with the seceded States. Maintaining this ground, I shall be drawn into a reply to arguments advanced by gentlemen on this floor, who take a different view of the subject, and having carried out this line of remark, I shall have completed the programme formed in my mind, and will
                cheerfully yield the floor to gentlemen who will doubtless be able to speak with more ability and eloquence than I can command.
              </p>
              <p className="p-in-sp">
                Eighty-four years ago, Mr. Chairman, heralded by the clash of arms, and amid the shock of battle, a giant, so to speak, started into life that giant was the youthful, but already magnificent country which reached from the ice-bound hills of New Hampshire to the warm and illuminated fields of Georgia, the renowned and patriotic thirteen sovereignties which had been the brightest gem
                in the coronet of power and dominion of that green isle of the ocean, in the storied inscriptions of whose Westminster, we read with reverential awe, the merited eulogies of those heroes, sages and philanthropists, who illustrated the land where once our fathers dwelt. Long years of suffering and of sorrow succeeded the nation's advent, but there came at last that independence, which
                in the end rewards the devotion of the noble, the chivalrous and the free—and when the smoke of the conflict had cleared away, when the clouds of war rolled back, and left spanning the horizon the rainbow of peace, that young land displayed a moral and physical grandeur to which the world showed no parallel. Every thing seemed to demand of her sons for their country and their whole
                country, the tribute of their devotion and their love—a people on whose every lineament were stamped the moral and mental attributes of the great race from which they sprang. A federative system of separate State sovereignties of kindred institutions and kindred freedom, <PageNumber num={91} /> which promised, in the matchless beauty of its construction
                and operation, to be typical of the order and regularity of the planetary system itself. Varieties of soil, climate and productions which seemed to have been ordained by a kind Providence, that this bright temple of freedom might be decked with every luxurious elegance of soul, of sense, or of intellect—rivers which rolled their brilliant tides through State after State, as if to
                mingle in great arteries the gushing life blood of those States—majestic ranges of mountains stretching forth their giant arms and uniting in fraternal embrace whole Commonwealths—washed by an ocean whose shores were indented with countless harbors, in which might safely ride the navies of the world—over whose waves were wafted the teeming products of a virgin soil to every
                clime—whence were brought in return over the same billows the commercial spoils of distant lands, the Confederate States of the Revolution had, besides these physical bonds of Union, the sacred recollections of a struggle in which they stood side by side and shoulder to shoulder. The blood and the bones of noble warriors from North, South, East and West, were mingled at Long Island
                and Camden, Brandywine and Savannah, Reading and Guilford, where lingered the melancholy memories of defeat, and at Lexington and Eutaw, Bunker's Hill and Cowpens, Monmouth and King's Mountain, Trenton and Hanging Rock, Princeton and Yorktown, above which hovered the glories of victory. To the progress of a portion of this beautiful country there was an obstacle which at first seemed
                insurmountable. Settled by Caucasians, by Saxons, and chivalrous Normans, whose genius and courage blazoned on their shields the quarterings of glory and renown won in every cabinet and on every field in Europe, and which fitted them for similar honors here, they found themselves lords of a country whose resources were to be developed in a climate, and under a sun beneath which their
                energies, mental and physical, drooped and withered. But the power which had guided them over the trackless ocean did not desert them here. Far away over the blue waves of the sea, and beneath the burning sun of Africa, there dwelt in the abasement of barbarism, heathenism and cannibalism, a race on whom, for the crime of their progenitor, had come down through the shadows of ages
                the curse of the God "whose nod can hush the thunders and serene the skies," whose throne is placed amid the music of circling orbs, and glows and glitters in the light of suns and worlds—the solemn and awful anathema, "cursed be Canaan, a servant of servants shall he be unto his brethren." The dread Omnipotent who makes "the wrath of man to praise Him," and restrains "the remainder
                of wrath," to <PageNumber num={92} /> lift that curse, that the degraded African might have his share in the atonement—as a part of the great scheme, which gives the Redeemer the heathen for his inheritance; in view of the slow progress of light through that benighted land, over which wave after wave of moral darkness rolled, brings him in contact with
                civilization here. But, that fair and glorious freedom might have no stain on her escutcheon, makes others than her sons the responsible agents of the evil, from which was to flow in full and gushing streams blessings and benefits. Yes, to the thousand sins for which despotism has to answer at the bar of history and of God, is to be added the expatriation of the heathen African for
                gold, with no view to humanity or benevolence, while the free men of our Southern States, guiltless in the means, but partakers of the merit of the end, have, by the dispensation of God, been made the honored instruments of good to a fallen race in the very act of developing the resources of their own beautiful and blooming land. They have made him a happy part of a patriarchal
                institution, infused into his savage and gloomy mind the kindlier sympathies and feelings leading to contentment and happiness, and unsealing the word of God, pointed him to the great sacrifice, which, when he has faithfully performed his duties here, offers him an inheritance in common with his kind in a world of eternal happiness. Passing from theory to history
              </p>
              <p className="p-in-sp">
                from assertion to the logic of facts—inexorable truth will demonstrate that to Great Britain, the country which now so bitterly denounces us for our domestic institutions, must be charged the existence of slavery amongst us. She established it here by positive enactment of law, spreading on her statute book that it was not only profitable, but right. Citizens highest in rank,
                individuals and corporations, princes of the blood and even sovereigns participated in the traffic. Ten Judges of her highest Courts, including Holt and Pollexfen, declared negroes to be merchandise, and she introduced and continued the trade amongst us against our earnest protest. The North, lynx-eyed as to everything which advances her interest, took up and carried on the trade
                with an avidity which showed her constitutional thirst for and keenness in the pursuit of gain. Immaculate Massachusetts, as early as 1641, declared the lawfulness of African slavery and of the slave trade. In the other Northern States, slavery was established, but the immutable laws of soil and climate, which, if let alone, will carry into every country the labor, whether slave or
                free, best adapted to it, rendering the institution unprofitable, these enterprising people took their part in the trade more in selling slaves to the South than in introducing them into their own country. Hence they rapidly disappeared <PageNumber num={93} /> from their territory into the Southern States, so as to make emancipation in the former no
                stretch of philanthropy. By the census of 1790, the number of slaves in the older and more flourishing colonies were only 40,370, while in the Southern and then more feeble colonies, there were, even exclusive of those in Virginia, 567,527; Vermont had only 17. Massachusetts, though slavery never was abolished there by statute, none. New Hampshire, whose statutes were equally silent
                on the subject, 158; Connecticut 2,759; Pennsylvania 3,737; New York 21,524; New Jersey 11,423, while Virginia alone had 293,427. These figures prove that in the Northern States the institution was not worth preserving, but the last four having the largest numbers, in order to avoid all loss, adopted a plan of prospective emancipation, before the arrival of the period for which there
                was a wonderful and nearly complete stampede of slaves to Southern States for Southern gold.
              </p>
              <p className="p-in-sp">
                To the influx of Africans, through the slave trade laws of Great Britain, the history of the Southern Colonies presented an unbroken series of earnest protests. Noble, glorious old Virginia, who stands in reference to the slave trade like the Chevalier Bayard, "above fear and above reproach," passed no less than twenty-three acts to suppress it; the other Southern States also
                endeavored to put an end to it, but veto after veto of royal Governors trampled upon the ardent wishes of the people. Not until the revolution had given freedom to these States were they enabled to end the traffic, and then, notwithstanding the magnificent vaunting of England of her love of freedom and hatred of slavery, they suppressed it twenty years in advance of her. I have
                glanced at these historical facts to show the injustice, the iniquity, the cruelty of the attacks on the South and her institutions, and I ask if the world can furnish a parallel to an outrage so atrocious. Not only are we assailed by that country which forced the institution upon us, and which still retains bitter recollections of our forfeited allegiance and their defeat by our
                gallant ancestors, but the honest, benevolent and immaculate saints of the perjured North, who sold us slaves for gold, proclaim that a sin in which they were particeps criminis, is unpardonable, and seek to destroy our institutions and cut our throats, because we will not surrender these slaves, which have become necessary to our civilization, and to liberate whom would be our moral
                and physical death. No philanthropy commends itself to these wicked meddlers, save that which is a deadly injury to their countrymen. In vast regions of the earth millions wear the chains of a servitude to which our slavery is freedom; but the lamb-like hearts <PageNumber num={94} /> of our pseudo brothers are cold—their sympathies dead as to them. To
                give their philanthropy spice and relish, it must have the seasoning of perjury—the stimulus of violated compacts, and comity with brethren, the fiendish thirst for the blood of noble men, and the dishonoring of lovely women. Mr. Chairman, there seems to be a disposition on the part of both real and imaginary wrong doers, to make others responsible for, and the scape-goats for their
                acts. Many a miserable old man who has had, during a long life, hundreds of slaves and reared a family to whom these slaves have become a necessity, when time has shattered his health, and weakened his nerves, is seized with a spurious remorse, and in an evil hour wills the liberation of his slaves, and the immolation of his children's birth-right. This folly and wrong finds many a
                parallel in the history of nations. France introduced slavery into her Colonies—and then, by abolishing it, gave them to fire and sword. So did Britain. And now this same Britain, having forced the institution on us, seeks, hand in hand with the unnatural North to enact the same tragedy in the South. Sir, the immaculate God could, in the sublime benevolence and boundless love, which
                are the attributes of divinity, offer up a sinless mortal life as an atonement for a lost world, but I utterly deny the right of any human power to make a vicarious atonement, and I demand that the North shall bear the guilt of their own sins. I referred in the beginning to the great physical links and the hallowed memories which should have bound together the North and the South in
                bonds of fraternity as firm as the granite of the everlasting hills. But for "bread" the North has given us the "stones" of immovable and incessant oppression. For "fish," the serpents of ceaseless agitation and attack. They, not we, have forgotten that the bones of our ancestors lie side by side with the bones of their ancestors at Princeton and Yorktown. They, not we, have
                forgotten that we are brethren, and that when once the hand "is stained with brother's blood," nothing less "than the waters of the sweet Heavens can wash it white as snow." Sir, to almost every conceivable insult and injury have we been subjected. I will not weary the Convention by reading the innumerable assaults upon us by the North. I could read page after page to show that our
                oft repeated complaints are true. That from the press, the pulpit, the school-house, conventions, legislative bodies, the masses, whether in cities or rural districts, from the leader of Senates to the lowest cross-road politician, who like a former Indiana Senator, plays steamboat for the amusement of rowdies and scoundrels at
                <PageNumber num={95} /> there
                is one wicked, common, deadly and deep-seated hatred to the South and her institutions. As early as 1790 the war upon us began in the shape of petitions to Congress to interfere with our domestic institutions. These have been in every form—to abolish slavery in the District of Columbia—in the forts, dock-yards and other places, under the jurisdiction of the Federal Government—to
                exclude it from the Territories, the common property of both sections—to prohibit the slave trade between the States—to abolish it in the States—and to dissolve the Union because of slavery—many of these petitions being couched in terms of the grossest insult to the South and her institutions. The platform on which the abolitionists went into the presidential contest in 1840,
                demanded the abolition of slavery in the District of Columbia—in the Territories—of the slave trade between the States, and announced an opposition to slavery every where, "to the full extent of constitutional power." In 1848, that portion of the party which did not support the Buffalo nominees, went for abolishing slavery in the States by the General Government. The Buffalo and
                Utica platforms themselves ( claiming to be Republican, not abolition ) took the strongest ground for what they call "freedom," in opposition to slavery. For the "relief" ( as they called it) of the Federal Government from all responsibility for slavery wherever that government had jurisdiction. For the absolute prohibition by act of Congress of slavery in the Territories, and of
                more slave States; and they flaunted in our faces the inscriptions on their banner of "free soil, free speech, free labor and free men," declaring slavery to be "a great moral, social and political evil," and a relic of barbarism. In 1852 the independent Democrats (as they called themselves) , who supported John P. Hale for the presidency, declared that their organization was a Union
                "of freedom against slavery" "slave Territory" "slave States"—and any legislation for the reclamation of fugitive slaves; "that slavery was a sin against God," "a crime against man," and that "no human power could make it right." In 1856, the Republican platform denied the right of Congress—of Territorial Legislatures—of any individual, or association of individuals, to give legal
                existence to slavery in any territory of the United States, and claimed that it was the duty of Congress to prohibit it. Added to all this, we have not the mere billingsgate of the Five Points, and the fish market eloquence with which <PageNumber num={96} /> we have been bespattered by strong-minded women and obscure male traitors, but countless
                declarations of leading men, politicians and divines, that republicanism is not only the ally, "but the progeny of abolitionism." That they are for the logic of Sharp's rifles to oppose slavery—"higher law judges, an anti-slavery Constitution, an antislavery Bible, an anti-slavery God." "That the time shall come when the sun shall not rise on a master or set on a slave"— "slavery
                shall go out in fire and blood," and in its extinguishment the streets of our cities "shall run blood to the horses' bridles"—shall be abolished at the price "of the Constitution, the Union, and the country" —because of its existence the Union is "a sham, a lie, and imposture" —a "covenant with death, and an agreement with hell"—"insurrections are justifiable"—"a fugitive slave is
                right to kill his master if he attempts to reclaim him"—"servile war or anything is better than the extension of slavery to the territories"—"and that jurors are justified in violating their oaths in acquitting persons who effect the escape of fugitive slaves," as are States in violating the law for their rendition. Their repeated legislative resolutions have declared against any
                more slave States. Chase proclaims that the clause in the Constitution for the rendition of fugitive slaves is null and void, and forms no part of an oath when it is taken to support that instrument. Seward, besides his higher law and irrepressible conflict doctrines, proclaims "that slavery has no constitutional guarantee which may not be released"—"its ultimate extinction is
                certain." Sumner, besides holding similar doctrines, declares the fugitive slave law should not be executed. Webb is for preventing the extension of slavery, sword in hand. Wade is not only in favor of abolishing it in the District of Columbia, and excluding it from the territories, and everywhere, where the General Government has jurisdiction, but declares that agitation shall
                continue so long as the foot of a slave presses American soil. To the same purport are the declarations of Wilson. And the great splitter, who now occupies the Presidential chair, who seems as good at splitting the Union as he formerly was at splitting rails, who is as much out of place in his present position as a child or a monkey passing with a lighted match in the midst of open
                kegs of gun-powder, also proclaims the "irrepressible conflict ;" that the Federal Government has the power, and should exercise it, of prohibiting slavery in all the territories; is not even certain that the abolition of the slave trade between the States is unconstitutional, and declares that men who own slaves do not deserve to be free. Moreover, as far back as 1858, there were
                twenty abolition United States Senators, and 100 abolition <PageNumber num={97} /> representatives, since that time, sixty-eight members of Congress endorsed the work of Helper, an infamous incendiary publication, recommending fire and sword for the South, and the alarming spread of whose vile doctrines is shown by the boasting flaunting on its title
                page of the sale of one hundred thousand copies. The statutes of no less than fifteen States nullify the fugitive slave law. Myriads in the North claim the crown of martyrdom for the thieves, bandits and assassins who, under the lead of John Brown, invaded our soil and murdered our citizens. Last, though not least, a sectional party has "seized the government," with the avowed
                purpose of warring with its thousand weapons of patronage and power on our institutions. Thus, through every channel of communication, speaks the stolid, relentless, moveless North. Thus are heard the denunciations and threatenings, not of a foreign power without weight or influence in our government, between which and its hoped for victim rolls the billows of an ocean barrier, but
                of a vast and powerful column and cordon of free States of eighteen millions of inhabitants, whose suffragans have an immense majority in the election of our governmental officers—whose territory is in immediate proximity to our own, spanning and outflanking our whole Northern, North-eastern and North-western frontier, which, before the secession of the Southern Confederacy, were to
                the slave States in number as 19 to 15 ; in white population as eighteen millions to eight millions; in representation as 188 to 114; which to the slave States which now remain in the Northern Union, are in number as 19 to 8; in white population as eighteen millions to five millions, in representation as 188 to 53; which immense preponderance of Commonwealths and population is to be
                steadily increased by the addition of new States, which is in possession of the power and the patronage of the government, with the ability and the will forever to exclude from it Southern men, and the unlimited power of taxing and trampling upon the hopeless minority.
              </p>
              <p className="p-in-sp">
                And in this gathering of huge clouds, black as night—this rolling of the distant thunder, ominous of the approach of the storm—these shocks of the earthquake, heralding an explosion which may shake to atoms the pillars of our social edifice—this roaring and surging of the mountain waves of the great deep, by the breaking up of whose fountains we may at any moment be overwhelmed, my
                distinguished friend from Augusta
                [<span className="persName">Mr. BALDWIN</span>]
                , who I was happy to see receive from fair hands the orator's wreath, not because I agree with him in sentiment, but because I am always pleased to witness rewards to intellectual merit; sees little more than the raving of madmen, joined <PageNumber num={98} /> to the votive offerings of gallant persons to the toothless old maids of Yankeedom—these poor
                old creatures who stalk about with eyes dim as lamps living on a short allowance of oil—their cheeks blazing with the permanent blush of the brush—their heads decorated with a profane and alarming eruption of exotic curls, and who make the welkin ring in his own facetious, but expressive language, with the singing of "psalms and hymns." Sir, I hope my friend is what I say with sorrow
                and humilation I am not, a member in good standing and fellowship of some orthodox Christian Church; for I fear that if he is not in now, the scepticism which sees no danger to the South from Northern aggression, will keep him out forever. Knowing the solemn responsibilities of our position here, I feel rather rebuked at venturing upon an anecdote, especially a trite one ; but the
                gentleman really seems as hard of belief as the man who having been refused a place in the Ark, though the waters of the flood were rising in every direction around him, contemptuously told that distinguished old navigator Noah, to get along with his old boat, for he did not believe "there would be much of a shower after all."
              </p>
              <p className="p-in-sp">
                Mr. Chairman, a fearful crisis is upon us—our agricultural, mercantile and commercial interests are prostrate—our finances are deranged—our once glorious Union is disrupted—seven stars have left our federative constellation, and others threaten to shoot from their spheres. Waiting in vain for sympathetic responses from the "blue mountains and monumental sea shore of Virginia," the
                Confederate Commonwealths have evinced the prudence of the traveller who stands on his guard as he hears by the wayside "the warning rattle of the serpent"—of the Western hunter, who raises his death dealing rifle when there is a "rush in the jungle," and he beholds the large green eyes of the spotted tiger glaring upon him. The flower decked fields of Louisiana ; the beautiful
                savannahs of Georgia and fair Carolina, "from plain to mountain cave," resound with the din of preparation, and echo the stern notes of defiance. The Gulf States bristle with bayonets—column after column of the Huguenot and Cavalier chivalry deploy into line, and the brilliant casque and more brilliant eye, the sable plume, the "dancing crest" of many a noble leader, gleam along the
                ranks where flash the banners of a glittering army.
              </p>
              <p className="p-in-sp">
                Amid these raging elements, what should be the course of the border States and of Virginia? In my humble judgment they should at once resume the powers formerly delegated by them to the General Government, and in solid phalanx assert their independence. The time when <PageNumber num={99} /> they could be accused of imprudence for such a course has passed
                ; necessity, not rashness, is now the word. Attempt after attempt at compromise has resulted in failure, until longer asking looks like supplication. Statesman after statesman in the Senate has endeavored in vain to calm the storm. Proposition after proposition for peace, which, though patriotically made, was regarded by the Southern men as in part a surrender of their rights, has
                been persistently voted down by the North. The voice of Virginia for peace, so potential, so successful heretofore with the nation, has been scorned; and having in vain attempted to secure the rights of the South by her separate action, when she again speaks, it should be from the head of the Southern column. Without the Confederate States she would be as helpless as a child. What,
                if she remained with the Northern, or Northern and middle Confederacy, would become of her cherished doctrine of State-rights, the liturgy of her political prayer book? How would she resist unequal taxation—the plunder of the public treasury, insults and aggression, and the steady encroachments of a relentless sectional majority upon her institutions? Every reading man knows the wild
                theories of the North and North-West on the subject of government. Scornful derision is their only reply to the cherished States Rights doctrines, to the perfection of which the great men of Virginia have devoted their lives. Trampling this hallowed creed in the dust, they favor the wildest and most partial schemes of internal improvement by the General Government. Rejecting both, an
                equal distribution of the public lands among the States, and the trust by which they are held, for the common benefit of all, they allow them to be seized by the States where they are situated ; they hold that no system of taxation, by duties on imports, however excessive, is unequal or unconstitutional, and advocate dangerous agrarian schemes in the shape of homestead bills and
                other obnoxious measures for robbing the treasury and ruining the country. If, when the South was a unit, we were unable to stem the tide of injustice and oppression, how hopeless will our condition be now, with this resistless majority of Northern and North-western States against us, and when seven of our sovereign allies have left the Confederacy? With these great political facts
                staring me in the face, I am forced to say that guarantees, which, for the sake of the peace, I would, at one time, have accepted, I would now consider myself unjust to my constituents to touch. Every moment's delay on the part of Virginia has confirmed the wavering and strengthened the anti-Southern rights party, in the border slave States, while the gulf States have become more and
                more exasperated <PageNumber num={100} /> against the North; and the hope of re-construction grows fainter and fainter. As the breach between the Gulf States and Northern States widens, and the former become more determined to act with perfect independence, and thus diminishes our chance of having by our side our natural allies, so increase the perils of
                Virginia and the necessity for greater demands and guarantees, and for the assertion of her absolute equality in the Confederacy. How are these guarantees to be obtained? By the fiat of Virginia acting singly? The futility of such a hope has already been proved by the failure of the Peace Conference inaugurated by herself. If acting alone, she is too weak to command respect for her
                demands, she must seek more strength. Where is that to be obtained? The answer is plain : with the Southern Confederacy —though there may be matters in which our interests may clash with those cf that Confederacy (which I do not admit) , yet it is impossible that that clash can be so fatal as that between the slaveholding interests of the South and the anti-slavery aggressions of the
                North. To the Southern Confederacy alone can we look for a community of interest for strength, and for real sympathy in the maintenance of that most sensitive and vital of all our domestic institutions, slavery.
              </p>
              <p className="p-in-sp">
                We cannot be sure of the effective co-operation of the border States —Delaware, Maryland, Kentucky, Tennessee and North Carolina, notwithstanding the nation is in the throes of revolution, have refused to call conventions. How far North Carolina, Tennessee and Kentucky have been influenced by the delay of Virginia in taking her position with the Confederate States, or what other
                causes have weighed with them, I have no means of knowing. But I fear there is too much reason to deplore the free soil proclivities of Delaware and Maryland; and Missouri on the 20th of last month, by a vote of 69 to 23, actually refused to declare her willingness to unite with the South, even though the Northern States refuse to agree on a just settlement of the slavery question,
                and the Union is dissolved in consequence thereof. Then we should not await the action of the border States. Delay and uncertainty are ruinous to our interests. The great objection to border State conferences, and all other measures for delay, is that business men are kept in a state of harassing and ruinous uncertainty, as to where the State is to go, and the country is to go—under
                what revenue system we are to live—as to what amount of protection, stability and safety is to be given to that domestic institution of ours, which is the foundation on which is reared the whole edifice of State prosperity and civilization. Not only do commercial and manufacturing men stand aghast at the doubts and difficulties of the State's future,
                <PageNumber num={101} /> but slaveholders prepare to weaken our strength by the withdrawal not only of their energy, virtue and ability, but of millions of property from our soil—the motives which impel the latter to leave the State are two fold ; first by our unsettled policy they fear the reduction of the value of their slaves, and secondly they know
                that of all institutions on earth slavery is the most sensitive, and the most dangerous to be meddled with by any but its friends and owners. They well know that the history of every outside interference with the institution is written in blood hence they desire to place themselves and the institution in the keeping of its friends. Even though the secession of Virginia should in
                consequence of her delay fail to give her the proud leadership, which all were once willing to accord her, it would at least put her in a position where the slave interest would be free from attack by legislation—in an alliance with Commonwealths by whom she will not be trampled upon, and will be freed from insult, excessive taxation and ceaseless agitation.
              </p>
              <p className="p-in-sp">
                We might have supposed our enemies to have prudence if not principle—shrewdness, if not wisdom and honor; and that this miserable government at Washington, when straining every nerve to keep the border States in the Northern Confederacy, when Virginia was staying the tide which threatened to sweep them from place and power, would have had the tact, not to say the common decency to
                stay the hand of oppression and plunder. So far from it, at that very time they seek to prostrate Southern prosperity. For, as the distinguished gentleman from Halifax
                [<span className="persName">Mr. BRUCE</span>]
                , who first addressed us has shown, at the very moment this Convention was discussing the modes of restoring union and fraternity, a Black Republican Congress passed, and a Black Republican President signed the odious Morrill Tariff, more unequal and oppressive to the South than that bill of abominations of 1832, which came near disrupting the government and destroying the
                confederacy. Go with the Southern Confederacy, sir, and no such oppression awaits us. We will then rest under the wise and statesman-like Constitution of the Confederate States, formed by Southern slaveholders for the benefit of Southern slaveholders. This is the conclusive answer to gentlemen who ask what we are to get by going to the Southern Confederacy, and express doubts as to
                the security of our rights and interests in it. Is there a gentleman on this floor who is not perfectly satisfied with that Constitution, who sees any thing in it incompatible with the interests of Virginia, or which does not better secure them than the Constitution under which we live. Moreover, the construction of an instrument is secondary in importance
                <PageNumber num={102} /> only to the terms of the instrument itself. The Constitution of the Confederate States will be administered by a Southern Congress, a Southern President, with a Southern Cabinet, and construed by Southern Judges, all looking to the interest of Southern slaveholders; and not by a Northern free soil executive and abolition
                Congress, and a Supreme Court to be abolitionized as rapidly as death shall remove the remainder of the venerable States rights Judges who now grace its bench.
              </p>
              <p className="p-in-sp">The distinguished gentleman from Fauquier [<span className="persName">Mr. SCOTT</span>]
                , argued that in ranging herself with the Southern Confederacy, Virginia would go as a mendicant, ignorant of the manner in which she was to be received. As a mendicant, sir? Why, have not the States which led the secession movement, sent commissioners to Virginia, both before and since their withdrawal, not only inviting but imploring her to join the Southern Confederacy?
              </p>
              <p className="p-in-sp">
                Again, sir, he has argued that the Government would not brook our withdrawal and it would be equivalent to war, and that the act would forever alienate the Northern States from us, so that there could be no re-construction of the Union. I do not see that the withdrawal of Virginia from the Confederacy, without aggression upon the Federal Government, will produce war any more than the
                mere withdrawal of the Confederate States has produced war. I apprehend that if war springs up between the rival Confederacies, it will not be in consequence of the isolated act of secession, but in consequence of some controversy with regard to the forts, and the collection of the revenue. And if it be said that the Government has up to this time stayed its hostile hand to keep
                Virginia with the Northern Confederacy I answer that if she secedes, the same policy will probably be pursued to keep in that Confederacy the remaining border slave States. And, with regard to the alienation of the Northern States from us by our alliance with the Southern Confederacy, and the consequent lessening of the chances of reconstruction, all I have to say is, that if, after
                all the injuries, insults and indignities heaped upon us by the Northern people, they are so sensitive, so squeamish as to make out union with the Gulf States a mortal offence, all hope of reconstruction is gone forever. The distinguished gentleman
                [<span className="persName">Mr. SCOTT</span>] , touched upon a subject which has already been alluded to more than once on this floor. It was started by the distinguished senior delegate from Bedford [<span className="persName">Mr. GOGGIN</span>]
                , and refers to the difficulty, in case of the secession of Virginia, and her alliance with the Southern Confederacy, which the State and the Confederacy would have in discharging their reciprocal <PageNumber num={103} /> obligations. I understood the argument to refer in the beginning to the difficulty of the transit of our Congressional representatives
                to Montgomery, and I thought it a strange position, when only a few days before Commissioners from the seceded States of Georgia, South Carolina and Mississippi had passed, without molestation and with welcome through unseceded States lying between us and the Southern Confederacy. In the discharge of these reciprocal duties there would be little difficulty in time of peace; and if it
                be said that in time of war it would be so great as to make secession for that reason unwise, I submit with great deference that the argument proves too much—for it would show that we could not safely secede the State, even if every effort of compromise with the North and every proposition proposed in this body—border State propositions and all—shall fail. In other words that we are
                helpless in the grasp of abolitionism. It is a difficulty then which, as we may have to meet it, we should look in the face and be prepared for it. I hope that it will be found more imaginary than real. If Virginia should join the Southern Confederacy, I should hope there would be no difficulty, in case of danger, in marching an army from one to the other through the Southern and
                friendly States still in the old Union, and that while those States would resist the march of a Federal army, the spirit of their people, of their institutions, the feeling of Southern fraternity, the principle of honor would prevent their opposing the march of Southern forces from Virginia to the Confederate States, or from the latter to the former. Besides, it is to be hoped that a
                Southern navy will start like magic into life, by which her troops can be transported where they may be needed. Then let Virginia go with her Southern sisters, and in concert with them demand her rights of the North. We have already sufficient evidence of the inability of Virginia, acting singly, to obtain proper guarantees of the North and its abolition government, and of the
                indisposition of the North to accede to the demands of a disunited South in the failure of the Peace Conference inaugurated by her, of the Crittenden propositions, and of the various other propositions of peace offered by Southern men, all of which were voted down and branded with scorn.
              </p>
              <p className="p-in-sp">Though we contend that the Peace Conference propositions were a virtual surrender of Southern rights, and point to their failure in Congress to show that the North is not willing even to give us a part of our rights, the distinguished gentleman from Augusta [<span className="persName">Mr. BALDWIN</span>]
                , says we are estopped in any reference to the subject because the Virginia Senators, Hunter and Mason, voted along with Seward against the propositions, but the answer is plain. Seward voted against <PageNumber num={104} /> the report, because it gave the South more than he was willing it should have. Hunter and Mason voted against it because it gave
                the South less than its due. Does any human being suppose that Seward voted against it because it gave the South too little, or that the Virginia Senators opposed it because gave the South too much? The bare statement of the proposition is its own best refutation, and there is an end of it.
              </p>
              <p className="p-in-sp">
                I confess, Mr. Chairman that my hopes of a reconstruction of the old Union are slight indeed. I fear that a separation of the States is eternal, but I am convinced that if there is any hope of reconstruction, it must be from the prompt secession of Virginia, and her union with the Southern Confederacy. Of one thing I am certain, after the terrible ordeal through which the country has
                been carried and is now passing, the Gulf States will never return without constitutional amendments, securing them thorough and absolute equality with the North. It is vain for any man to tell me the Union can be reconstructed without an utter abandonment of those principles founded in inequality, injustice and wrong, but which are the very existence of the free soil party. That
                there will be such an abandonment, is almost hoping against hope; and almost equally futile is the hope that the gulf States will return on any terms. But, if miraculously, almost, the free soil party, from a returning sense of justice, should be willing to accord us this equality, nothing but a union of Virginia with the States of the Southern Confederacy will bring them back. My
                distinguished friend from Halifax, who last addressed us
                [<span className="persName">Mr. FLOURNOY</span>]
                , referred to the proud position of Virginia. He told us in eloquent terms that at her command the government had stayed its hostile hand against the South. My heart responded to every sentence of his beautiful eulogy on the old Commonwealth, for I am one of those who so reverence and love the Old Dominion, that even when dissenting from her line of policy, my tongue will utter
                nothing in regard to her, unless couched in the most humble and respectful terms, such as a dutiful son might employ towards an aged, venerated, adored mother. If, in the sentiment of my friend, her position was proud, noble, glorious, when acting singly, what would be her attitude when, assuming her ancient and natural leadership which she has been implored to take, she marches at
                the head of the glorious Southern column? She would then be entitled to address the seceded States, and remonstrate with them in the spirit of an elder sister—she could say to them, sisters I have left the house of your enemies and am with you in the house of my friends—I ask you not to abandon a single <PageNumber num={105} /> right or a solitary
                principle of honor—I will resist the invasion of either, with you, to the death. I am prepared with you to lay down a platform, an ultimatum of amendments to the Constitution, giving you perfect equality with the North and securing in all respects your full rights—if these are denied, with you I ignore the old Confederacy forever—but if they are accorded to us, will you not go back
                with me, reconstruct a once glorious Union, and, forgetting the bitter recollections of the past, rest again with me under the shadow of that flag, which has made our name so respectable, glorious and renowned among the nations of the earth? For my own part, much as I have loved the old Union and would love it now, freed from oppression and wrong, I think we have no safety in it
                unless these seceded States return. Having ranged ourselves with them we would go back when they return; and if that is never to be, then we should remain under the wise Constitution of the Confederate States, with powerful allies, and not be reduced to the weakness, the impotence of being alone. If all the slave States will not join the Southern Confederacy, I would, at least, make
                it as strong as we can by the addition of Virginia. The Northern States will then see the result, if they cannot comprehend the guilt of their acts, that the subjugation of the South is hopeless ; and ( harshly as it may grate on their haughty and guilty pride) that unless the war on our institutions is ended, and our equality admitted every where, and every where around our property
                as around theirs is thrown the protecting aegis of the law, the Union is gone forever. The eternal principles enunciated in three short words may re-construct the Union; they are truth, justice, equality—none other ever will. Then, sir, I favor the prompt secession of Virginia. This brings me to an examination of the report of the Committee on Federal Relations, which I shall make
                with great respect and regard for the gentleman who compose the Committee. Looking, as it does, to other modes of redress, than the prompt resumption by Virginia of her delegated powers, I cannot give it my support—nor could I approve it, even if I was willing to live in the Northern Confederacy without the Confederate States of the South. There are objections to the second
                resolution of the first partial report of the Committee, but I pass them by to the 4th resolution, the latter part of which says :
              </p>
              <p className="p-in-sp">
                "If the equal admission of slave labor and free labor into any Territory, excites unfriendly conflict between the systems, a fair partition of the Territories ought to be made between them, and each <PageNumber num={106} /> system ought to be protected within the limits assigned to it, by the laws necessary for its proper developement."
              </p>
              <p className="p-in-sp">
                Sir, that resolution defers to what, in my opinion, is an unjustifiable, illegal and abominable prejudice of the North against the institutions of the South. It holds out the idea that there is a moral degradation attached to those institutions, and that Northern men are contaminated in being brought in contact with them, and therefore in the very teeth of the decision of the Supreme
                Court, Southern men shall have no protection for their property in one-half of the Territory, while the property of Northern men is protected in all. I never will, willingly, give my vote for any resolution which sanctions such a principle.
              </p>
              <p className="p-in-sp">
                The time has come when, if we are to live with these Northern people, they must give up the idea that we are a degraded class. They must admit that this busy-body intermeddling of theirs, in which they undertake to brand us and our ancestors with being an inferior race, in a moral point of view, shall cease. They must cease to insult us by telling us that our property in slaves is a
                claim so infamous, so polluting, as to be put under the ban of Northern morality and excluded from its constitutional right to protection in the Territories, or there never can be peace between us, and the sooner we separate the better.
              </p>
              <p className="p-in-sp">The distinguished gentleman from Augusta [<span className="persName">Mr. BALDWIN</span>] , illustrated his views in favor of a division of Territory, and the exclusion of slavery from one-half, by this example : Suppose, said he, a Northern man moved into a Territory belonging to the United States, he would have what he [<span className="persName">Mr. BALDWIN</span>]
                , termed to be a very natural prejudice, but what I conceive to be a most outrageous one, against the institutions of slavery; he might consider that there was something degrading in slavery, and therefore be unwilling that his family should live in a community where such an institution was tolerated. Well, sir, what is the meaning of that? The plain English of it is, you gentlemen
                of Virginia, South Carolina, Georgia, Florida, Louisiana and Texas, and the other Southern States, hold an institution which is enervating and degrading, contrary to the spirit of the age, to freedom, chivalry. honor, justice and right.
              </p>
              <p className="p-in-sp">Now, sir, is not that an impertinent and most unjustifiable stigma upon our institutions, our social system. "Look upon this picture and then upon that."</p>
              <p className="p-in-sp">
                Suppose a Southern man chooses to go there, and to say, "I don't like this system by which boys are kept all day long learning tricks of Yankeedom, in making wooden nutmegs and swindling clocks, which are 'no go,' without any opportunity of cultivation or refinement. I <PageNumber num={107} /> do not intend that this Territory, to which I have brought my
                children, shall be without that peculiar institution to which the South owes so much of her civilization—virtue, refinement and chivalry, I demand that the Northern men be excluded and that the Southern institutions be planted here."
              </p>
              <p className="p-in-sp">
                Now, sir, would it not be just as fair and right for him to exclude the Yankee, as for the Yankee to exclude him? Both being citizens of a free country, I say, in the language of the Supreme Court, "that the territory is acquired for their equal and common benefit, and, if open to any, it must be open to all upon equal and the same terms," and, that the Constitution, recognizing
                slaves as property, and pledging the Federal Government to protect it, is bound to redeem that pledge as to the Southern man's slaves, as well as to the Northern man's clocks or nutmegs. If Southern men go into the Territory with their slaves in sufficient numbers to make it a slave State, then it ought to be a slave State. If Northern men have the ability to exclude the system, not
                by Emigrant Aid Societies and Sharp's rifles, but by superior industry in emigration, and consequently by superior numbers, let them exclude it, and let the matter be determined when a State Constitution is formed by a Convention of the people of the territory according to the true States rights doctrine. So much as to that resolution, sir.
              </p>
              <p className="p-in-sp">
                Again, sir, there is the 8th resolution, which acknowledges the right of States to withdraw from the Federal Government, and is so far right, and which says that the people of Virginia "will never consent that the Federal power, which is in part their power, shall be exercised for the purpose of subjugating the people of such States to the Federal authority." The objection to that
                resolution is that it is not strong enough. It does not recognize the fact that Virginia not only ought not to consent that the Federal power shall be exerted for the purpose of subjugating the people of the seceded States, but that she ought to resist the subjugation of those States with all the material power at her command, for the simple reason that if the seceded States are
                subjugated, we will fall an easy prey to the common enemy, the North.
              </p>
              <p className="p-in-sp">
                But, sir, the most extraordinary resolution of all is the eleventh, which, after some preliminary sentences, goes on to say—"Virginia, therefore, requests the people of the several States either by the popular vote, or in Conventions similar to her own, to respond, at their earliest convenience, to the positions assumed in the foregoing resolutions, and the proposed amendments to the
                Constitution of the United States hereunto appended."
              </p>
              <PageNumber num={108} />
              <p className="p-in-sp">
                Well, now sir, that gives the Northern people of this Confederacy a carte blanche to delay this matter if they please to all eternity. There is no compulsion upon them even to call Conventions. They have under this resolution an absolute unlimited right to respond to the action of Virginia by their popular vote. Now, sir, let us see how that will work. I take it every body knows that
                the mere popular vote amounts to nothing—that all the people can do by this popular vote is to influence those who, as their representatives, are bound to carry it out as an indication of the will of the constituent body.
              </p>
              <p className="p-in-sp">
                Now, mark you, the redress sought for the South is to be effected by amendments to the Constitution. Well, how is the Constitution to be amended? The only two modes by which it can be done are set forth in its 5th article. One is that Congress by a two-thirds vote shall propose amendments, which, when ratified by three-fourths of the States, by their Legislatures, or Conventions,
                shall become parts of the Constitution. The other is, by two-thirds of the States, by their Legislatures, uniting in the call to Congress for a National Convention ; and the action of that Convention is also to be ratified by three-fourths of the States, by their Legislatures, or in Conventions, before it becomes valid. Well, sir, under this resolution the people are to respond. The
                popular will is to be brought to bear on Congress, so that by a two-thirds vote of both houses it will propose the desired amendments to the Constitution, or upon the Legislatures of two-thirds of the several States, inducing them to apply to Congress to call a National Convention for proposing amendments, which in either case shall become parts of the Constitution, when ratified by
                three-fourths of the several States. The popular voice then to be of any avail must be brought to bear upon Congress or the Legislatures—then again upon the Legislatures or Conventions, as the case may be.
              </p>
              <p className="p-in-sp">
                Do not gentlemen see that this is a roundabout process that will be interminable? "By the popular vote"—when? where? How expressed? how arrived at? By whom demanded? Popular vote in the election of local officers, members of the Legislatures, Judges, Congressmen, or President and Vice President? At which of these elections is this popular vote to be expressed? Under whose direction?
                Whose fiat is to be had in reference to the constitutional amendments proposed by this Convention? Sir, it is made the business of nobody, and they may put off this response to all eternity; for, mark you, they have the alternative of ignoring everything else except a direct popular vote. Sir, we hear of some very remarkable names among these long, lank, lantern-jawed Puritans of the
                North, which, whether they have any real existence <PageNumber num={109} /> or not, are made sufficiently classic by the genius of Irving. If I recollect aright, he speaks in that delightful embodiment of wit and humor, Knickerbocker, of certain selectmen of Yankeedom, rejoicing in the musical titles of "Preserved Fish, Habakkuk Nutter,
                ReturnStrong-and-Determined Cock." Very similar to the names of these same Puritans in Cromwell's army and Parliament, such as "Praise-God Barebones," "General Cry-aloud-and-spare-not," "Colonel Fightthe-good-fight," and "Captain Smite-them-hip-and-thigh." Well, sir, the actual working of this proposition would be to request the people, to request Congress, to request the
                Legislature, to request Conventions, to respond to the action of Virginia. Very much like requesting Preserved Fish, to request Habakkuk Nutter, to request Return Strong, to request Determined Cock, to request General Cry-aloud-and-spare-not, to request Colonel Fight-the-good-fight, to request Captain Smite-themhip-and-thigh, to request Praise-God Barebones, to do the South justice.
                And if they do not do it some time between now and the day of judgment, we will play the very old Harry with them.
                [Laughter.] It seems to me that the South will get what she wants under this process when the archangel Gabriel blows his trump and frightens this crop-eared abolition crew into honest dealing with us, and not before. As well might Prince Rupert and the Royal Charles have asked their rights of the Barebones Parliament.</p>
              <p className="p-in-sp">The twelfth resolution says that the people of Virginia will wait any reasonable time.</p>
              <p className="p-in-sp">Now, sir, I am utterly opposed to any such indefinite language as that and, giving these men, who have always trifled with our rights, the liberty to wait as long as they please. Let us inform them what we mean by a reasonable time—that if they do not accede to our requests by a certain period, we will dissolve our connexion with them.</p>
              <p className="p-in-sp">The 14th resolution proposes a Border State Conference, and is objectionable because of the delay which it involves.</p>
              <p className="p-in-sp">Then comes the 2d report of the Committee.</p>
              <p className="p-in-sp">
                The first section says, that involuntary servitude is prohibited North of 36 degrees 30 minutes, and that South of 36 degrees 30 minutes it is not prohibited. Well now, Mr. Chairman, that is securing the Northern share to the labor of the North, and failing to secure the Southern share to the labor of the South. The Supreme Court has decided in the Dred Scott case that slavery is
                entitled to protection everywhere in the territories. Now, if we are to surrender a part of our rights under that decision, and to give the North one-half of the territories, when the law adjudges us equal rights in all, and North <PageNumber num={110} /> of 36 degrees 30 minutes slavery is to be prohibited, in God's name, South of that line let it be
                PROTECTED. Let us not be satisfied with a mere declaration that it is not to be prohibited.
              </p>
              <p className="p-in-sp">Then there is the fifth section, which my friend from Richmond, who first addressed us [<span className="persName">Mr. RANDOLPH</span>] , proved conclusively, looks strongly in its terms (whatever may be its intention) to a prohibition of the introduction of slaves to this State from the Southern Confederacy.</p>
              <p className="p-in-sp">Then comes the sixth section, in which it is said that if the reclamation of a fugitive slave is prevented, by intimidation of, or violence against a Marshal, he shall be paid for out of the Treasury of the United States; thus saddling the South with one-half the burthen of paying for her stolen property. The distinguished gentleman from Prince George [<span className="persName">Mr. RIvES</span>]
                , who addressed the Convention the other evening, made an argument upon the subject which seems to have brought down the House, but which struck me as a most remarkable one. He reduced the subject to mills in his calculation, and, with great respect, I thought the argument as small as the illustration. He went into a very interesting recital of a Southern man going North and bringing
                back, not his fugitive slave, but $1,000 from the public treasury, in his place, and then being laughed at by his wife, when, on counting the cost, it was found to be only two mills. In the first place, it seems to me a very extraordinary thing, when a great principle is at stake, to measure it by the mere matter of dollars and cents. When our ancestors resisted the stamp act, was
                there any calculation as to the number of cents each man would have to pay? No, sir. It was the principle—the violation of their rights—that they resisted, without stopping to inquire whether it would cost them a copper or not. Thus it should always be with nations in establishing the institutions under which they are to live. But, Mr. Chairman, the argument is a mistaken one as a
                matter of fact. If one or two slaves only are lost, then the cost will be small. But, suppose, under this process by which the United States undertakes to pay for all the stampeded slaves, thousands of them are lost in a year, the cost would be a more serious matter than the gentleman supposes. Added to the outrage and wrong—in the violation of your rights, of comity and fraternity—a
                premium would be offered to these Abolitionists to run off our slaves to be paid for out of the Treasury of the United States.
              </p>
              <p className="p-in-sp">
                Great complaints have been made of the so-called rashness of these Cotton States, and of their failure to act in concert with the other slave States. Mr. Chairman, statesmen have to deal with questions practically. There is no propriety in standing upon stilts. In matters <PageNumber num={111} /> of individual dealing, when A and B meet each other upon
                the field of honor, there may be as much display of chivalry as suits the parties —either may even go as far as a distinguished gentleman once did when he pointed out the white vest of his adversary, below a black coat, as presenting too fair a mark in the conflict; but, in a representative capacity, we have no right to be governed by such nice points of etiquette. Sir, we have the
                interests of our constituents and of our country in our keeping, and the question is not whether the Southern States have acted with perfect prudence and courtesy, but whether, under the facts as they exist, the interest of the State is with the Northern or with the Southern Confederacy. And, it seems to me, that the imputation of rashness or want of courtesy at all, to the Southern
                Confederacy, is rather illiberal, in view of their invitation to us, both before and since their secession, to co-operate with them.
              </p>
              <p className="p-in-sp">Equally illiberal seems to me to be the complaint, that they have endeavored to coerce and drag us out of the Union. We must admit their sovereignty, or abandon our own, and sovereignty carries with it the right to secede.</p>
              <p className="p-in-sp">
                Now, does a State, when she thinks her safety and honor require her to withdraw from the Confederacy, coerce States that do not withdraw? She uses no force upon them. She merely exercises her own Constitutional rights—her own attribute of sovereignty. If you and I, sir, were settled on the Western frontier, in a perilous district, surrounded by savages or robbers, and if you, after
                having remonstrated with me time and again about the danger of remaining, and notified me that if I did not leave the place with you, you would quit it by yourself, went away, would I be justified in saying that you insulted and coerced me? And yet I might as well say that, as that the seceding States have coerced and insulted us, or disparaged us in any way, by withdrawing from the
                Confederacy, after having warned us they would do so, and having invited us to go along with them. The coercion which a man exercises on another by the assertion of his unquestioned rights, is a very different sort of coercion from that of wrongful force; if not, every industrious man may be said, by force of example, wrongfully to coerce every lazy man in his neighborhood to be
                energetic; and, by a like argumentative fallacy, the charge of coercion may be made by every bad man against every good man, by every slow man against every prompt man, who, by example, keeps up so high a standard of action and morality in his community as to brand a stigma on a less elevated standard. One cannot be rightfully
                <PageNumber num={112} /> complained of for exercising his unalienable rights, merely because thereby another is left in an unsafe position.
              </p>
              <p className="p-in-sp">I listened, sir, with great pleasure to your [<span className="persName">Mr. MONTAGUE</span>'S]
                argument on the right of secession. I do not think it necessary for me to argue that subject—first, because you have discussed it ably ; and, secondly, because I do not consider it a practical question. This matter of secession lies in a nutshell, and can be disposed of, practically, in a few sentences just as well as in a whole volume. I have not heard any gentleman upon this floor
                deny the right of revolution. Every one who has spoken here admits that if the oppressions and wrongs of a people are intolerable, they have that right. The practical question, then, is, do our wrongs justify resistance? if not, we have not the mora4,right of secession, though the technical right were "nominated in the bond," written in the Constitution. The aim of my argument has
                been to show that our oppressions do give us the right of resistance—in other words, of revolution. That we have not exercised it, is because we choose not to do so? We have suffered, not consented to our wrongs. What, then, is the right of revolution? It is the right of an oppressed people, in unorganized bodies, to resist the Government. If unorganized bodies, in the existing state
                of things, have the right of resistance, a fortiori, and, as a conclusion of both logic and common sense, organized and sovereign Commonwealths, free from anarchy, and with settled institutions, have the same right.
              </p>
              <p className="p-in-sp">It has been said, sir, that the South has submitted to, and sanctioned all the aggressions of the North. I think that argument has been well nigh annihilated, first by my distinguished friend from Charles City [<span className="persName">Mr. TYLER</span>] , and next by the gentleman who now presides over this Committee [<span className="persName">Mr. MONTAGUE</span>] . But even suppose it were so, does anybody deny that these aggressions have been grievous wrongs? If they have been submitted to for the sake of peace, does that prove that they ought to be endured for ever? So far from being an argument to be used against us, it is one of the strongest to justify our present resistance.</p>
              <p className="p-in-sp">The eloquent gentleman from Kanawha [<span className="persName">Mr. SUMMERS</span>] , told us that he did not despair of the Republic, and argued to show that we would get our rights from the North; that all the phases of the argument in favor of slavery, including the Biblical argument, and its effects—moral, social, political and civilizing—upon the country, were better understood at the North than ever before.</p>
              <p className="p-in-sp">And the gentleman from Augusta [<span className="persName">Mr. BALDWIN</span>]
                , told us that there <PageNumber num={113} /> were at this time more pro-slavery men in the North than there were ten years ago in the whole world. Now, sir, the answer to all this is, that it is idle to talk of the great number of pro-slavery men there, when they are out-voted in every election, and when, in their halls of legislation, they are
                defeated in every measure looking to Southern rights, and utterly powerless to stay the Vandal tide of sectionalism. While the distinguished gentleman from Kanawha is arguing to show the advancing enlightenment of the Northern mind on the subject of our domestic institution, the logic of facts and figures, as State after State increases her abolition majority, is utterly demolishing
                his beautiful theory. What use do the Northern people make of their increased knowledge? Are they any better for understanding the arguments by which the able and eloquent statesmen and divines of all sections have demonstrated that slavery is right, morally, socially, and politically? that in their assaults on the institution they are invading our rights and trampling the
                Constitution in the dust? No, sir; their aggressions advance with .their increase of light, in a sort of geometrical progression, until they threaten to overwhelm the South and destroy her institutions. This is the way in which they respond to the argument. In 1840, the Abolitionists cast 7,000 votes. By 1844, they showed that they had received great light upon the subject and were
                disposed to do perfect justice to the South, by increasing the vote of 7,000 up to 62,140. yi 1848, they show that they are still further enlightened, still 'more benevolent, by increasing the last vote to 100,000. In 1852, they eixhibit a still farther perception of our rights by polling 157,196 votes; and in 1856, by polling 341,812 votes; in 1860, by an overwhelming number of
                votes, they possess themselves of the Government and bring all its patronage and power to bear against our institutions.
              </p>
              <p className="p-in-sp">
                But it has been repeatedly argued here, that for all these things, the Federal Government is not responsible; that it has made no aggression on the South; has taken no part in the passage of personal liberty bills, and has reclaimed some fugitive slaves. Sir, I think it has been shown you, clearly and conclusively, that the Federal Government has trespassed upon the rights of the
                South by legislation. Thousands upon thousands of fugitive slaves have never been reclaimed. And, further, the argument resolves itself into this: The Federal Government has or has not power to secure the recapture of fugitive slaves. If it has the power, it has been faithless, because, in countless instances, it has not exercised it; if it has not the power, it is impotent, and
                powerless to protect us; in either case the result is the <PageNumber num={114} /> same, and the Government, in default of a change for the better, is a nuisance, and ought to be abated.
              </p>
              <p className="p-in-sp">
                Sir, who can count the number of slaves which have not been reclaimed? Look at the report of the Committee appointed by the General Assembly of Virginia, to investigate the Harper's Ferry invasion. It shows the escape of numerous fugitive slaves, and, in some cases, the murder of their masters endeavoring to reclaim them. The reclamation of the fugitive Anthony Burns, under one of
                the late administrations, required the entire military organization of a State, with a large force of the Federal troops; and though the slave was worth only some $700 or $800, he cost the Treasury of the United States upwards of $100,000. Do you tell me then, that this is a Government of which the South has no right to complain, and which secures protection to the South? Sir, if
                this is protection, God save us from injustice and wrong!
              </p>
              <p className="p-in-sp">I have been struck, sir, with the many attempts and ingenious arguments which have been made upon this floor to apologize for the North ; to underrate the enormity of Northern aggressions upon us; to prove that those Northern people are—what they are not—just and true to their obligations to the South. The gentleman from Prince George [<span className="persName">Mr. RIVES</span>]
                , read you the amounts of subscriptions in Northern cities to the towns of Norfolk and Portsmouth during the prevalence of yellow fever a few years ago. Well, sir, what does that show? Does it show anything but what everybody knows and what we all are willing to acknowledge, that there are some noble spirits and generous people in the North? Sir, when the British Government was
                pushing our ancestors to the wall, depriving them of their rights and liberties, and driving them to revolution, how many generous spirits, private individuals and public orators, were there in the mother country who protested against the whole system of oppression? But, though grateful for this sympathy, our noble forefathers considered it no reason for submitting to oppression and
                wrong.
              </p>
              <p className="p-in-sp">
                Again, sir, it is argued that the claim of the South to equality of rights in the Territories is a mere abstraction, that slavery cannot and will not go into them. If this be so, it is a wanton exercise of power on the part of this Northern majority to exclude the Southern people from an equal right to carry their property into the Territories. But the truth is it is not an
                abstraction. No wonder that slavery does not go into the Territories when the moment it enters it is met there by Emigrant Aid Societies and Sharp's rifles? Though Southern men <PageNumber num={115} /> could get the most fertile lands at the lowest price, they dare not carry slaves into the Territory where it is so insecure.
              </p>
              <p className="p-in-sp">The eloquent gentleman from Kanawha [<span className="persName">Mr. SUMMERS</span>]
                , asked us if we were willing to make the Western section of the State the outside row of the Southern Confederacy—if we were willing to bring the Canada line down to the Border? Well, sir, I think the Canada line has been brought down practically to the border years ago. I scout the idea that ( with a few exceptions) the gallant men of the West are not as true to the interests of
                Virginia as the men of any other section of the State. I cannot believe that Virginians are wanting in fidelity to the State. I have a great respect for a Virginian wherever I meet him. I am always inclined "to give him my hand and call him brother." And I have the most earnest desire to afford to those gallant gentlemen of Western Virginia what I consider they need—protection for
                their property, their homes, and their families. Make them the "outside row"? Why, sir, I ask if they are not already the "outside row." Have they any protection there for their property? What prevents their slaves from being stampeded under the operation of the underground railroad? What has become of their slaves? I have taken the trouble to look a little into this matter, and a
                most remarkable state of facts presents itself to me upon this examination. I find, sir, that the border counties, with the number of slaves in each one, are as follows :
              </p>
              <div className="table">
                <table>
                  <tr>
                    <td>Monongalia</td>
                    <td>101</td>
                  </tr>
                  <tr>
                    <td>Wetzel</td>
                    <td>10</td>
                  </tr>
                  <tr>
                    <td>Marshall</td>
                    <td>29</td>
                  </tr>
                  <tr>
                    <td>Ohio</td>
                    <td>100</td>
                  </tr>
                  <tr>
                    <td>Brooke</td>
                    <td>18</td>
                  </tr>
                  <tr>
                    <td>Hancock</td>
                    <td>2</td>
                  </tr>
                  <tr>
                    <td>Tyler</td>
                    <td>18</td>
                  </tr>
                  <tr>
                    <td>Pleasants</td>
                    <td>15</td>
                  </tr>
                  <tr>
                    <td>Wood</td>
                    <td>176</td>
                  </tr>
                  <tr>
                    <td>Jackson</td>
                    <td>55</td>
                  </tr>
                  <tr>
                    <td>Mason</td>
                    <td>386</td>
                  </tr>
                  <tr>
                    <td>Cabell</td>
                    <td>305</td>
                  </tr>
                  <tr>
                    <td>Wayne</td>
                    <td>143</td>
                  </tr>
                </table>
              </div>
              <p className="p-in-sp">Now, compare these figures with those representing the slaves of other counties, with those showing the number in Hanover, where there are 10,000, or in Halifax, where there are 14,000.</p>
              <PageNumber num={116} />
              <p className="p-in-sp">
                Perhaps those gentlemen can inform me what has become of their slaves. I ask whether the abolitionists have not carried them away? Pressing thus upon the border and driving slavery from those counties, what, I ask, is to prevent them from pressing on still further and pushing it from the counties which come next in order, and so rolling on the wave of sectionalism till they sweep
                slavery away through Virginia, through the Confederate States, and into the far South?
              </p>
              <p className="p-in-sp">Now, sir, I want to stop this fearful wave—to roll it back from our Western brethren, their homes and families. To effect this, let the Southern States in solid column leave the Northern Confederacy, and establish on our free State frontier a line of military posts which will prevent further encroachments by the abolitionists.</p>
              <p className="p-in-sp">
                Sir, if the slaveholding States of this country were ocean-bound, there would be no danger from encroachments on the part of the Abolitionists. But they are not ocean-bound. We have a Northern frontier, bounded by the free States—by Pennsylvania and Ohio—and as well might you attempt to keep back the waves of the ocean, after you have destroyed the shores of that ocean, as to attempt
                to keep back the aggressions of this freesoil party of the North, unless you interpose some such barrier as I have mentioned, to stop them.
              </p>
              <p className="p-in-sp">It is said that if we separate from the North, we will have to keep an immense standing army, to the ruin of the country. The gentleman from Halifax [<span className="persName">Mr. BRUCE</span>] , and my friend from Albemarle [<span className="persName">Mr. HOLCOMBE</span>] , have shown you that there are no fears of that. War is contrary to the spirit of the age. The people of the North are people of commerce and of peace. They will not support a large standing army, and we will not be compelled to raise a single man more than they raise.</p>
              <p className="p-in-sp">
                But, sir, this idea that the maintenance of an army of 15,000 or 20,000 men is going to ruin our hoped for Southern Confederacy, is a most preposterous one. How is it possible that it can be correct? Ruin such a country, sir, by the appropriation of a few millions of dollars for its defence? Why, do not some of the most prosperous and flourishing countries in the world maintain
                immense standing armies of hundreds of thousands of men; and could not our giant Southern country maintain twenty or thirty thousand? Why, sir, when our cities were villages, our farms forests, when our large and beautiful stores were small shops, the want of a proper military force enabled Tarleton and Arnold, in the half of a short summer, to destroy twelve millions of property in
                Virginia; but that did not ruin her; though young and feeble and bleeding, she struggled and overcame it. When, then, the <PageNumber num={117} /> great Southern Confederacy is in successful operation; when the commercial navies of the world ride in her harbors ; when, relieved from Northern taxation, she has grown to be independent and prosperous—she
                will no more feel the burthen of maintaining an army of fifteen or twenty, or thirty thousand men, than would a giant feel the stroke of a pigmy's arm. Then fort after fort would spring up along our free State frontier, and be manned by this army; thus would we protect our brethren, their homes and property. The federal army is now engaged in defending our extreme Western
                settlements, stretching from the Pacific Ocean and the Gulf of Mexico South, to the British possessions North. In case of separation our federal army would not have to defend the immense territory North of the line of 36 deg. 30 m. where you have Mormons and savages; our half would be South of that line. The Indian troubles South of that line will probably be settled in a short time.
                Indeed, I think there is little difficulty experienced with the Indians anywhere South of 36 deg. 30 min., except in Texas. Troubles there will soon be ended. So that the whole strength of our force could be concentrated in military posts on our free State frontier, and thus raise a sort of Chinese wall, and stay, if anything will, this tide of Northern aggression.
              </p>
              <p className="p-in-sp">
                Mr. Chairman, I have endeavored briefly, by historical facts, to show the aggressions of those Northern people upon the rights and interests of the people of the South; that they were ever advancing, never receding; that our only safety was with the Southern Confederacy. I have shown directly where and when assaults have been made upon us by States and individuals as well as by the
                General Government.
              </p>
              <p className="p-in-sp">
                Sir, is there nothing else that should stir the blood of a free and gallant people, except interference with their rights? Is slander nothing? Is insult after insult nothing? Is defamation in foreign lands of the honor of our State and section nothing? They have libelled our great names, abused our fathers and our families, and desecrated the very graves of our ancestors by claiming
                that slaveholders are unworthy of association with them.
              </p>
              <p className="p-in-sp">
                Sir, neither individuals or nations can submit, habitually, to insult and indignity, without degradation so fearful, that they become ultimately as base as the denunciations of the oppressors proclaim them. The spiritual dilapidation goes on ; it may be slowly, but surely, and soon or late moral ruin is the result. On the other hand, men cannot be made slavish or mean, who strongly
                will to be free and noble. Before they can be trampled on, their "spirits high" must be bowed to the dust—then may it truly be said of them :
              </p>
              <PageNumber num={118} />
              <div className="lg">
                <div className="l">"Enough—no foreign foe could quell</div>
                <div className="l">Thy soul, till from itself it fell.</div>
                <div className="l">Yes—self-abasement paved the way</div>
                <div className="l">To villain bonds and despot sway."</div>
              </div>
              <p className="p-in-sp">Sir, I for one, will never consent to live or hold intercourse with men who claim that I am socially their inferior. The insolent pretension of those who—whether nations or individuals—have invariably the least right to make it ; the bloated arrogance and impudence which prefers such a claim, will be met by true men with the scorn and defiance of border chivalry.</p>
              <div className="lg">
                <div className="l">"I tell thee thou'rt defied !</div>
                <div className="l">And if thou said'st I am not peer</div>
                <div className="l">To any lord in Scotland here—</div>
                <div className="l">Lowland or Highland, far or near,</div>
                <div className="l">Lord Angus—thou hast lied !"</div>
              </div>
              <p className="p-in-sp">
                The fanatical, meddlesome, overbearing disposition of the Puritans —their arrogant, conceited and wicked determination to ignore the moral fact that individuals as well as nations have quite enough to do to keep evil from their own characters without interfering with others; their ultraism and persistent claim to regulate and control the social rights and customs of other people, has
                deluged more than one country in blood. These detestable qualities altogether outweigh everything that is good in them, and render them the greatest curse that can be inflicted on a country. It would have been better for humanity had their Plymouth rock been riven by an earthquake and their Mayflower sunk into the bottom of the ocean. In England they persecuted the families, razed
                the houses and cut down the ancestral oaks of the cavaliers. So bitter—so arrogant—so conceited and tenacious of their peculiar views—so intolerant were they, that they became separated from the great body of constitutional reformers, and the re-actionary spirit of a gallant people hurled them from power like Satan from Paradise, and drove them beyond the ocean. Whether on American
                soil they are to relearn in bitterness and blood this historical lesson; whether after having desolated with fire and sword the homes of the South, they are again, by the outraged spirit of conservatism, to be voted a nuisance, and pushed over or into the sea, I am not prophet enough to tell. But this I do know, that "joined to their idols," and failing to profit by the evil example
                of their ancestors, they are again stirring up strife on another continent—that with no earthly right to interfere with them in any way, they have chosen to make themselves the bitter enemies of the peace, happiness and safety of the <PageNumber num={119} /> gentlemen of the South; that while gentlemen hate strife and are constitutionally quiet, social
                and peaceable, yet, combining science with courage, when driven to resistance they pursue their enemies with the terrible energy of furies—the resistless sweep of avenging angels—and that while these our hereditary foes were driven from Britain with execrations and curses, the descendants of the bleeding Cavaliers sit again in peace and happiness in their once desolate but now
                beautiful homes, or under the shade of their renovated groves.
              </p>
              <p className="p-in-sp">
                Mr. Chairman, no man has looked with more reverence on the flag, or regarded with more idolatrous fondness the Union of these States than I. I have remembered how that flag floated in triumph in conflicts when the only aspirations of the heroes and patriots who poured out their blood beneath its folds were for the honor and glory of their country; and I have remembered how the
                majestic Union of our fathers carried into every clime, not the trembling terror inspired by the Roman name, when its eagles overshadowed the world, but the awe, the respect, the love which the great heart of the whole race accords to freedom, to virtue and to justice; and I have said, in my "heart of hearts," should the South, to preserve its own existence, have to strike down that
                flag, her every true son would exclaim, in mournful resolution, with the great bard of Avon : "This sorrow's heavenly; it strikes where it doth love." It has been present to my mind that this great country was formed by the compact of independent sovereignties, not with the right, first, of libel, and then of destruction by one part of the institutions of the other, but with an
                endorsement of the institutions of the whole country, and an engagement of honor, and of law, in the very act of Union, to defend these institutions to the death. Sir, I am forced to remember how these solemn covenants have been fraudulently and foully repudiated by the North. How that Pharisaical land of every ism which degrades man to a level with brutes, Infidelity, Spiritualism,
                Mormonism, Free Soil—which means to rob a partner of all the partnership effects—Free Speech, which, with them, is the right of rank and reeking blackguardism, and atrocious slander on the South; Free Love, which means to roll back the tide of refinement and virtue, for the saturnalia, the hell of vileness and iniquity; has, in the blended spirit of avarice and fanaticism, not only
                assailed our fair fame abroad, but our very household gods at home. They, our pseudo brothers, have sought to direct upon us, in foreign lands, "the slow, moving finger of scorn." Great names adorn our Southern annals—Washington and Jefferson, Madison and Monroe, Carroll and Rutledge, and a host of others. Splendid mausoleums mark the resting
                <PageNumber num={120} /> places of these great men, and they have still nobler monuments in the hearts of their countrymen. But the sacrilegious effort is made to blacken their sacred fame because they were slaveholders. Added to this, it is sought to outrage the reputation and profane the sanctity of the social circle. Our very ancestors are to be
                libeled because they were slaveholders. Sir, facts, stored in the memory of every gentleman in this hall, give the lie to these atrocious slanders.
              </p>
              <p className="p-in-sp">
                Who is there, reared in Virginia, whose memory, in reverting to the days of his childhood, does not give him a thousand pleasant memories of the patriarchal institution of the State—of the good will between the servants and the families of their masters—of the many errands of kindness on which he has been sent to old or sick domestics of the household, and of the scenes in which
                these domestics would bless with their latest breath the benevolence of their owners? Raise the curtain of the past, and, forgetting the stern and wasting duties of manhood, he is carried back to the time when he was a happy member of a happy family circle. Before him, in memory, rises the aged and honored head and patriarch of the house, whose feeble steps, whose personal goodness,
                whose position of common ancestor, commanded for him the pious love and care of his descendants. He recalls the manly form of one whose kind words linger forever in his memory—who bore to him the sacred name of father. A soft yet bright eye sheds upon him its mild and beautiful light in pity and in love as his youthful mother watches him with tenderest devotion. As childhood fades in
                the past and boyhood puts on its free and joyous attributes, that mother's love still encircles him like the atmosphere of a holier and better clime. Her tears fall upon his head like the dews of Heaven. Her prayers ascend to God in fond and fervent aspirations for blessings upon him even beyond the lot of man ; and in after years amid the stirring scenes of life, the memory of that
                mother's counsel and that mother's love will better defend him from dishonor than a thousand bristling bayonets. Time rolls on, and these sacred forms depart, and he exclaims in bitterness of heart, all, all gone to return no more forever. They have sought the sanctuary of the dead, where repose other honored ones in whose veins once coursed the same life blood. Green grows the long
                grass and sweetly bloom the flowers planted on their graves by the hand of affection, and in the star-light and sunlight glimmers in pallid lustre the monumental marble, with the simple inscriptions of departed worth. With throbbing brow the descendant of that house says these were mine, and though dead their pure virtuous memories live. But Abolitionism, that fiendish libeller,
                <PageNumber num={121} /> says cursed be they for they were slaveholders. Sir, let us say to these oppressors of the South, compromises with you are as nought. "Thus far shalt thou go and no farther." We demand stern, full and exact justice; cease your assaults on our institutions; stop your agitation and give us peace, that peace to which your honor
                bound you in the compact of Union; bow to the decision of the Supreme Court; sweep from the statutes of your States every enactment warring on our property; cease your attacks on the laws which have established slavery in places under the jurisdiction of the Federal Government; confess that we came into the Union on terms of perfect equality with you, and that wherever, in the common
                territories, our flag floats our property has the same right to protection that yours has; regard us, and let your legislation regard us as equals, not tributaries, and the seceded South may return. The Union may again stretch its grand proportions from Maine to California, from the Atlantic to the Pacific. Deny us these, our rights, and our separation from you is eternal.
              </p>
              <p>Before concluding, the Committee rose and the Convention adjourned.</p>
            </div>
          </div>
          <div className="day" id="vsc1965.v3.2.3">
            <PageNumber num={122} />
            <h2><span className="headingNumber">1.3. </span><span className="head">FORTY-THIRD DAY</span></h2>
            <div className="dateline">Thursday, <span className="date">April 4</span></div>
            <p>The Convention met, pursuant to adjournment, at 10 o'clock, A. M. Prayer by Rev. J. B. JETER, D. D. of the Baptist Church.</p>
            <div className="section" id="vsc1965.v3.2.3.1">
              <h3><span className="headingNumber">1.3.1. </span><span className="head">BASIS OF TAXATION</span></h3>
              <p><span className="persName">The PRESIDENT</span> stated that the business before the Convention was the resolution offered by <span className="persName">Mr. WILLEY</span>, on which <span className="persName">Mr. CHAPMAN J. STUART</span> had the floor.</p>
              <div className="speaker" id="sp1965"><span className="persName">Mr. CHAPMAN J. STUART</span> resumed as follows :</div>
              <p className="p-in-sp">Mr. President, when the Convention went into Committee of the Whole, yesterday morning, I was discussing the question raised before this House by the resolution of the gentleman from Monongalia [<span className="persName">Mr. WILLEY</span>]
                . It is utterly impossible for any man to present his views in an intelligible form in this fragmentary way. I desire very much to facilitate the business of this Convention, and I am prepared at any moment to yield the floor to take a vote upon this question, if it should be the pleasure of the Convention to do so. I am prepared to vote on this and on all other pending questions
                before this body. I was saying yesterday when interrupted by the Convention going into Committee of the Whole, that my people were not submissionists. My District is on the extreme Western border of the State, washed by the beautiful Ohio river; we stand there on the Eastern shore of our beautiful river, looking across into our mighty and powerful neighbor State, saying to her and
                all others whom it may concern, that we and our friends in Virginia together with all others, have our guaranteed constitutional rights, and for the vindication of these rights we are willing and will make any sacrifice. Never for one moment would we entertain the idea that we would prove recreant to the interest of our Eastern brethren. But, Mr. President, we can right about face,
                and with the same determined purpose demand our rights under our State Government. We are not submissionists in either sense, nor do we ever intend to be. I stand here, at this moment, demanding, in the name of my constituents, their rights at the hands of this Convention, and I do not want to be offensive when I say, we intend to have them. We are compelled, in arguing this
                question, to anticipate your objections. You do not appear to be disposed to meet us in arguing the subject. Do you contend that we have nothing to complain of ? I would not be afraid to go before any jury of men in the civilized world, with the <PageNumber num={123} /> evidence I could bring to bear against you, and obtain a verdict from their hands,
                that we have many wrongs which we can trace back to you. Sir, I would not be afraid to take a jury from the vicinage. Yes, willing to take twelve secession gentlemen from Eastern Virginia, and have them called up and sworn the truth to find, and from them wrest a verdict that we are being most ungenerously treated. And now for specifications. Let it be remembered that our State is
                vastly in debt, to the amount of 40 million dollars. That our people are borne down with grievous and burthensome taxation, which, when collected, is barely sufficient to pay the interest on our State debt. Then consider the large additional expense now accruing to our State, and the large appropriation just made a few days ago, and then enquire for what is all this for? The answer
                will have to be that it is for the protection of slave property. The Legislature of last year appropriated five hundred thousand dollars, and this present session one million of dollars to arm and equip the State, to defend the rights and interests of the slaveholder ; the extra session of the State Legislature convened last January, induced by the dangers to the institution of
                slavery; this Convention convened from the same causes; which make in all at least $2,000,000 additional debt, incurred in the last sixteen months, to protect your peculiar property; and yet you are not willing to let that property be taxed the same as our land, cattle and horses which give you no trouble and incur no expense to you. The taxes received into the treasury, collected
                from slave property the last year, I believe, were $327,000, while within the last sixteen months the cost that property has been to the State amounts to $2,000,000. This two million has to be borrowed by the State, and it is a low estimate to say that it will at least cost the State ten per cent to obtain that money. The interest on the $2.000,000 would be $200,000, leaving to the
                State one hundred and twenty-seven thousand dollars only to pay into the treasury after paying the interest on the debt which has been made within the short time named for the exclusive benefit of the slave property. At that rate it would take some twelve or fifteen years for the present slave tax to pay the debt made for its benefit within so short a period of time. By an
                examination into the revenue of our State and the appropriations made to the different localities of the State, it will be found that the Northwest will pay into the State treasury in five years every dollar that we have received from the treasury; and, after having done this, we are still to be oppressed and burthened with taxation to pay debts that have been contracted to build up
                improvements in your part of the State <PageNumber num={124} /> and to protect your negroes, and still you are not willing that the slaveholder shall bear his equal part of the burthens. This is the kind of property, according to the argument of the gentleman from Culpeper, that we should be willing to sunder our connections with the General Government
                for, in order to make that property more valuable, that it might pay a large revenue into our State treasury—a property which, under our present Constitution, is a tax and burthen upon it. We have always stood up to our obligations, and will soon, as before stated, have liquidated every demand that the treasury has against us, while the amount of taxes received from the slaveholder
                will scarcely have paid the interest on the debt contracted for his benefit.
              </p>
              <div className="speaker" id="sp1966"><span className="persName">Mr. FISHER</span> [in his seat]—</div>
              <p className="p-in-sp">There never has been a dollar appropriated to my county since the formation of the Government.</p>
              <div className="speaker" id="sp1967"><span className="persName">Mr. STUART</span>—</div>
              <p className="p-in-sp">
                I do not refer to individual counties, but to the principal divisions of the State. The gentleman's county may be an exception ; I know nothing about that matter. I say that there has been millions upon millions of dollars expended in the State, for improvements in which the Eastern portion of the State has been mostly interested, and now that our State is about to be bankrupted by
                your extravagant appropriations, for the purpose of building up your improvements, I maintain that it is our right to demand that you shall, at least, permit your property to be taxed as ours is taxed, to help to maintain the credit of our State. Is it not apparent to the most casual observer that the State of Virginia, unless something is done, must eventually repudiate her debt? I
                should have remarked when speaking about the extra session of the Legislature being called together for the purpose of looking after your slave interest, they undertook to relieve your contractors on the Covington and Ohio Railroad, and on account of the great depreciation in the market value of your State bonds, with which the contractors had to be paid, the Legislature found it
                necessary, to sustain the contractors and carry on the work, to increase their contract price ten per cent. This is ominous legislation when we are in debt 40 millions of dollars, still increasing the price of labor to be paid for ten per cent. to enable the contractors to peddle our State bonds around at 40 or 50 per cent. discount. A fine field for Yankee speculation, if they were
                not afraid that we would never pay them.
              </p>
              <p className="p-in-sp">
                And may I be permitted to ask of you, gentlemen, who are for secession, what is to become of this great State improvement if your policy is to be carried out? I have always labored under the impression <PageNumber num={125} /> that the millions of dollars that have been appropriated to this improvement, looked to its ultimate completion, and through it
                open up a great trade with the Western States—the very States that you want to cut loose from, and build up a protective system that will prohibit the trade you expected to support the road and build up your cities.
              </p>
              <p className="p-in-sp">
                But more particularly to the question : it seems to me that you are willing to legislate in any way, so it does not tax your little negroes. And as I before remarked, we have to anticipate your objections. I suppose that your apology is that they are unprofitable until they arrive at that age. Let us compare notes and see how this affects the Western poor man, who pushes out into the
                wilderness and buys himself 100 acres of land for $100, takes his deed for the land, and you charge up the tax against him. When in its native state it is of no value towards supporting his little family, but absolutely requires years of toil and labor to bring it into value; and the very moment he erects a little house upon it to shelter him and his from the storm, you put the
                increased valuation on it and make him pay the additional tax before he has been enabled to realize one dollar, but which requires many years of most unremitting industry to subdue and bring into a state of cultivation. Many a noble and brave heart has sunk under the effort. But you were always ready to tax the very sweat of his face in his effort to bring into value your wild lands,
                while your little negroes were running around with a convertible value all the time, and growing in value most rapidly. By what mode of reasoning can it be justified, that the man of wealth who can afford to own slaves, and one of them under the age of twelve years and exempt from taxation, but still worth to the master more than the entire estate of his poor neighbor, yet that poor
                neighbor's cow may be exposed to sale by the sheriff to pay the tax that has been levied on his little all for the purpose of defraying the expenses of the Government in defending the property of the rich man against the abolitionists of the North? It is indefensible, and such an open violation and invasion upon the rights of freemen as should not be tolerated one day longer than an
                opportunity offers itself that it may be corrected. That opportunity is afforded you now to do, and give unto us of the West our rights as attaches to all freemen. We are willing to demand and aid in securing to you your rights from your sister States. We stand as a barrier between you and your advancing enemies. Should a John Brown invade the territory of the little counties of
                Doddridge and Tyler, with five hundred followers, with a view of making war upon your institutions, much less a dozen with a few negroes, the crack of our <PageNumber num={126} /> Western rifles would be heard, and, I predict, that not one would be left to be guarded and hung by due course of law.
              </p>
              <p className="p-in-sp">
                The resolution of the gentleman from Monongalia contemplates the raising of certain Committees to prepare and report certain amendments to our Constitution. All the amendment we want in the Constitution, as far as it relates to the tax question, is to strike out of section 22 article IV, which reads, "Taxation shall be equal and uniform throughout the Commonwealth, and all property
                other than slaves shall be taxed in proportion to its value, which shall be ascertained in such manner as may be proscribed by law," the words "other than slaves." Then strike out the entire 23d section, which relates to slaves under 12 years of age, and the valuation of other slaves, and we should then have nothing of which we would have a right to complain so far a s that subject
                was concerned.
              </p>
              <p className="p-in-sp">Let me say to you in all candor and sincerity, until this thing is done by our Eastern friends, you might as well undertake to remove the Alleghany mountains from their base, as to undertake to induce the people of the Northwest, for present causes, to secede from the Union.</p>
              <p className="p-in-sp">
                Then, sir, the basis question, which you say is to rest until 1865. we are not so strenuous about. But are you afraid to trust us with our rights! Would you not have every security when the Constitution would say that property should be taxed according to its value? Have we of the West not always stood as the guardians of the treasury? Have we not always opposed those large
                appropriations which are now bringing ruin and bankruptcy upon our State? Trust us with the power, and if we do not show another state of affairs, I will be willing to forfeit my right of citizenship.
              </p>
              <p>The hour for going into Committee of the Whole having arrived. any further remarks were suspended.</p>
            </div>
            <div className="section" id="vsc1965.v3.2.3.2">
              <h3><span className="headingNumber">1.3.2. </span><span className="head">FEDERAL RELATIONS</span></h3>
              <p>The hour of half past ten having arrived, the Convention resolved itself into Committee of the Whole [<span className="persName">Mr. SOUTHALL</span> in the Chair] , and resumed the consideration of the report of the Committee on Federal Relations, the question being on the 4th resolution, as follows :</p>
              <p>
                "The Territories of the United States constitute a trust to be administered by the General Government, for the common benefit of the people of the United States, and any policy in respect to such Territories calculated to confer greater benefits on the people of one part of the United States, than on the people of another part, is contrary to equality and prejudicial to the rights of
                some for whose equal benefit <PageNumber num={127} /> the trust was created. If the equal admission of slave labor and free labor into any Territory, excites unfriendly conflict between the systems, a fair partition of the Territories ought to be made between them, and each system ought to be protected within the limits assigned to it, by the laws
                necessary for its proper development."
              </p>
              <p>
                <span className="persName">Mr. RICHARDSON</span> resumed and concluded his remarks.<span className="note" id="Note12"><span className="noteLabel">1</span>Mr. Richardson's entire speech is given in the Proceedings of April 3. See note 2 on those Proceedings.</span>
              </p>
              <div className="speaker" id="sp1968"><span className="persName">Mr. MORTON </span>—</div>
              <p className="p-in-sp">It had been my wish to address the committee on topics discussed in the general range of debate ; but in the very limited time that will elapse before the hour arrives when this debate is to close</p>
              <p className="p-in-sp">
                not more, I believe, than 30 minutes I shall confine my remarks to a very few points, and I desire the attention of the majority, as well as of the minority; for, permit me to say, that while the minority have generally been in their seats, extending a close attention to gentlemen of the majority who have addressed them, yet when a minority man has occupied the floor, I have found
                the seats on the other side of the House very generally vacant.
              </p>
              <p className="p-in-sp">
                When I presented myself to my fellow citizens as a candidate for a seat in this Convention, or rather when I accepted an almost unanimous invitation of the people of the county of Greene to become a candidate for a seat in this Convention, to that invitation I made a response, from which I will read a couple of lines. I said "the South should be satisfied with nothing short of equal
                rights, and the political power to protect them." This equality was the great principle which I announced to my constituents, and it is a principle which has resounded from every section of this Commonwealth, and every quarter of this hall, that we are entitled to equal rights. But when we come to carry the principle into practice, the very men who proclaim it, in the next breath
                tell you they are willing that there should be a division of the common territory of the United States, by the line of 36 degrees 30 minutes, above which slavery shall be excluded, and south of which slavery may be permitted, and that, we are told, is equality. Sir, I say-that is not the equality of the present Constitution; it is not the equality declared by the Supreme Court of the
                United States. That tribunal has declared that all the territory belonging to the common government, shall be occupied by the citizens of the common government, with the right of protection for all their property. That I deem to be equality. Sir, is it to be said that it is a degradation to the Northern man to go South because my son or my neighbor may have a plantation of slaves by
                his side? Is the Northern man a better or a purer man than the slaveholder from the Southern States? I think not. It is no more a degradation for him to settle by a planter, a man his equal <PageNumber num={128} /> and generally his superior, than it is for the Southern man to go North of that line, and settle by the Northern man. I say then, Mr.
                Chairman, while this principle of equality is proclaimed in our Constitution, as construed by the Supreme Court of the United States, that the Northern man and the Southern man should go to all the territories together upon terms of perfect equality, the majority of the Committee of Twenty-one have agreed to a compromise which is a sacrifice of Southern rights if not of Southern
                honor.
              </p>
              <p className="p-in-sp">So much, Mr. Chairman, for that branch of the subject. For one I say that if we come together again we must come by a re-construction, and I am never for re-constructing this government unless it is done upon the foundation stone of equality and unless that equality is carried to the very cap of the edifice.</p>
              <p className="p-in-sp">Mr. Chairman, the next great principle that I enunciated to my constituents was that the South should have the political power to protect herself. The South is in a minority. We have seen what is the efficacy of paper guarantees. The distinguished gentleman from Fauquier [<span className="persName">Mr. SCOTT</span>]
                who has been the Ajax of the Union party upon this floor, yesterday made the manly declaration, the manly concession, that the eight slave States could not remain in a Union with the Northern States even if the free States permitted Virginia and the other border States to write a Constitution with all the guarantees that we might consider necessary for our protection. You cannot be
                safe, write the Constitution as you will; cross every "t" and dot every "i," because of the hostility existing between the nineteen free States and the slave States—because, I suppose, of that irrepressible conflict between free institutions and slave institutions. With that concession, coming from any quarter, but especially so distinguished a quarter as it emanated from yesterday,
                I ask if there should not be political power to protect us. I regretted very much that the gentleman's indisposition deprived us of hearing his argument upon the very point to which I am now directing the attention of the Committee, for, if he thinks, as I think, I know it would come with more power and influence from that gentleman than it will from me. But, sir, I say that we ought
                to have political power to protect ourselves; and at this point I am solicitous of having the ear of my distinguished friend from Bedford
                [<span className="persName">Mr. GOGGIN</span>] , for I am desirous of having his co-operation. I desire also the attention of the gentleman from Kanawha [<span className="persName">Mr. SUMMERS</span>] . Can we have these guarantees? If I can make it palpable to my distinguished friend from Montgomery [<span className="persName">Mr. PRESTON</span>]
                , from the very principles which he laid down I think <PageNumber num={129} /> he will concede it. Now, sir, these guarantees have been shadowed forth by the Committee of 21 in part, when they propose that no laws shall be passed in respect to the acquisition of territory without a majority vote of each section. I will illustrate the point by the action
                of clerical and lay deputies in Episcopal conventions. Gentlemen who have paid any attention to our ecclesiastical policy know that there have been dissensions and jealousies existing between the clergy and laity. We have a house of bishops. Then we have a lower house, composed of clergy and laity. Whenever any question arises in the lower House, as to whether the clergy are jealous
                of the laity, or the laity jealous of the clergy, they call for a vote by orders, and it must pass by a majority of the clergy and a majority of the laity. That is the principle which I ask now to have applied to both Houses of Congress on any or on all questions on which either a Northern or a Southern member shall call for a vote by sections. Let nothing become a law that has not a
                majority of votes from the free States, and a majority of votes from the slave States.
              </p>
              <p className="p-in-sp">Then, Mr. Chairman, as respects the Judiciary! Let there be no confirmation of the appointment of a judge, whose tenure is for life, or during good behavior, except by a majority of Senators from the South, and a majority from the North. In that way, you will have national men filling the judicial bench, and not Black Republicans, as the present prospect is.</p>
              <p className="p-in-sp">
                So much for Congress, and so much for the Judiciary. Then, as to the Executive! That can be effected by requiring any man who is elected president, to receive a majority of the electoral votes from the North and a majority from the South. The effect of that amendment would be that you would never see an Abe Lincoln dishonoring the presidential mansion. You would never see a worse man
                than him, Wm. H. Seward, presiding in the presidential mansion. You would find moderate men from either North or South. You would find such a man as Dickinson in the North or one of his type in the South. You would not find an extreme man in either section.
              </p>
              <p className="p-in-sp">
                If we have these political guarantees, if the North be willing to concede to us guarantees of political power, then I think, we might safely see re-construction under the expectation and hope that the high destinies of the Union will be worked out with all of the distinction which the fathers of the Republic anticipated when they established our Government. You will then have
                security to a minority, and the majority cannot trample down and oppress the minority.
              </p>
              <PageNumber num={130} />
              <p className="p-in-sp">
                In throwing out these suggestions, I do not say that I have done more than shadow forth the general ideas. Whether or not these particular guarantees of power in one branch of Congress would suffice, whether they could be dispensed with in the Executive Department or in the Judiciary Department, is a matter which is for the Convention to decide. My own belief is, that if we
                acknowledge the principle in theory, we ought to apply that principle to every department of the Government.
              </p>
              <p className="p-in-sp">Mr. Chairman, I will not, in the few moments that I have left, expend more time on that branch of the subject. The distinguished gentleman from Fauquier [<span className="persName">Mr. SCOTT</span>]
                made the confession that Virginia and the border Slave States could not find security with the North even though she were to give us a carte blanche to write such a Constitution as we choose. He confessed also that when he came from home he had a confident hope that this Union could be reconstructed, and reconstructed with safety and with honor to all parties, but that that confident
                hope and expectation had "vanished into thin air." And he made another confession which I should be glad to hear echoed by my distinguished friend from Kanawha
                [<span className="persName">Mr. SUMMERS</span>] and by all the trans-Alleghany range and the seaboard of Virginia—that if this Union cannot be reconstructed the destiny of Virginia is with the South. I believe that that sentiment will have, and has, a response in the bosoin of an overwhelming majority in this Convention. With these confessions, the point of difference to which I wish to call the gentleman's [<span className="persName">Mr. ROBERT E</span>. ScOTT'S]
                attention and the attention of this Committee is as to the policy of Virginia, whether she is to seek cooperation with the border States in the Union or out of the Union? The gentleman seemed to argue, with a confident and triumphal manner, that if we are to have consultation with the border States, that consultation had better be before than after the withdrawal of Virginia. I think
                that my friend from Culpeper
                [<span className="persName">Mr. BARBOUR</span>]
                , demonstrated, with as much mathematical precision as a moral question is susceptible of, that the true policy of Virginia is to act for herself and not to go into conference with the border States. If we are to have a border conference, this Convention is to nominate the delegates to that border Conference. You may judge from what has been the action of this body and of its
                President—all honorable men, all seeking to act for the best—what would be the stripe and type of the delegation from Virginia. My opinion is, that it would not represent the popular sentiment of Virginia—and there is the beginning point. Where will you find Maryland? Maryland has a Black <PageNumber num={131} /> Republican Governor, or one who is
                cousin-german to a Black Republican, and he would depute submissionists from Maryland. What would you expect from Delaware—a State which has not as many slaves as there are in the city of Richmond—not one third as many as there are in my county? What would you expect from Kentucky? If there is a vote, of which I shall for ever be proud, it was that by which I refused a vote of thanks
                to John L. Crittenden. As much as I venerated and honored him in time past, I saw enough of him in Washington to satisfy me that he would rather give up the institution of slavery than give up the Union. You find him to-day proclaiming in Kentucky that the interest of Kentucky is in union with the North. Then what can you expect from Missouri and Tennessee, sir? If you were to go
                into council with those States you will find that they would bring you down to the lowest demand of the lowest submissionist in this Convention. I am against it. I want Virginia to act, and to act for herself. I would be willing to rest this question, if it were a new question, on the argument with the gentleman from Fauquier
                [<span className="persName">Mr. SCOTT</span>] ; or, if I had not successfully met it myself, I say his argument was answered before it was delivered, by my friend from Culpeper [<span className="persName">Mr. BARBOUR</span>] .</p>
              <p className="p-in-sp">
                But, Mr. Chairman, we have something more than theory, and something better than theory. The very policy which the gentleman from Fauquier is urging upon this Convention is that which caused the struggle in the Confederate States before there was separate State action. I was there when this question agitated those States. In the struggle in Alabama, in Louisiana, and in Georgia you
                did not find a man who said that the State should submit to the rule of the Black Republicans—not one that did not say that Southern rights had been invaded, and that they must be vindicated. But the co-operationists there, as the co-operationists here insisted that no State ought to act until five of the Southern States would agree to act together, and go out together. That was the
                struggle. The secessionists insisted on going at once, and co-operating afterwards.
              </p>
              <p className="p-in-sp">
                Well, what has been the result, the signal result of that experiment? No man could pay it a higher compliment than my distinguished friend from Fauquier did yesterday, when he said, that though the Confederate Government has had but a short existence, it is conducted by wise men, by statesmen. It is a stable Government, and commands to-day more of the respect and confidence of the
                capitalists of the world than the miserable, crumbling, corrupt Government under which Virginia is yet seeking to find protection and shelter.
              </p>
              <PageNumber num={132} />
              <p className="p-in-sp">
                There is experience against theory. There you find secession first, and co-operation afterwards. Who doubts, who is bold enough to doubt, that if Virginia retires and unites her destiny with the South, the border States will not follow? I have not seen an intelligent man from any one of those States who expresses any doubt of it. We will solve the difficulty by our own action. If we
                retire now, we will have, by another year, a republic of fifteen slave States.
              </p>
              <p className="p-in-sp">
                At this point, Mr. Chairman, permit me to say that we should form our own Constitution with none but slave States, and admit such of the border States as may affiliate with us in sentiment, as will purge themselves of abolitionism, as will acknowledge the right of a Southern man to go into those States with his servant in the same manner as Northern men are permitted to go into the
                Southern States with his white servant.
              </p>
              <p className="p-in-sp">
                I say, until they come to this point, I will not go into union with them. Permit me to say another thing, sir, that, never in this new Confederacy, would I ask or permit the introduction of any free State unless they would not only acknowledge me their equal politically but socially. A friend told me, that last year, speaking of this same question, a man visited the State of
                Minnesota with a black servant to wait upon a dying wife; and he petitioned the Legislature to permit the servant to remain in the State until the fall to nurse that dying wife, and that pure, immaculate body considered that their soil and their social institutions would be contaminated, and they unanimously rejected the application.
              </p>
              <p className="p-in-sp">
                Now, what do you want? Do you not humble yourselves by seeking an association with these men ? I humbly submit that you do. While I admire much that fell from my young friend who has just taken his seat, I have no idea of a necessity for 120,000 men to guard our border. I do not believe it will require more soldiers to guard that than our Canadian border. It is more to their interest
                to preserve the peace than it is to our interest. Now, sir, I will say, in the few moments left to me—are we men, or are we children? Can we not look to facts as facts exist? Where is the man that has any hope, or ought to have any rational hope, of a re-construction of this Union? Your "Peace Conference" resolutions were rejected, I may say, in Washington, because if the New York
                delegation had voted, they would not have passed them. It would have been nine to nine—this proposition, which the House of Representatives trampled under foot—this proposition which was scorned by the Senate, with Wm. H. Seward at its head ; and when it is brought to this Union-loving body, it is consigned to the silent <PageNumber num={133} /> grave,
                with my friend from Franklin
                [<span className="persName">Mr. EARLY</span>] , leading a few sad mourners. And even as mean as it was; as despised as it was; it was too good for the South.</p>
              <p className="p-in-sp">They tell you of the re-action in public sentiment; and this, whilst Abraham Lincoln has formed his Cabinet with five abolitionists from the free States and two renegades from the South.</p>
              <p>At this stage, the hour of 12 arrived, when, under a resolution adopted a few days ago, debate, in the Committee of the Whole, should cease.</p>
              <div className="speaker" id="sp1969"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I have received a piece of information, this morning, which I was ignorant of the other day. I find that the resolution adopted by the Convention on the motion of the Chairman of the Committee of Twenty-one, instead of allowing ten minutes to each member who has objections to any proposed amendment, allows ten minutes to but one member. Am I right in that, sir?</p>
              <div className="speaker" id="sp1970"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Chair thinks so.</p>
              <div className="speaker" id="sp1971"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">The case is this : If "A" gets up and urges an objection that is not sound, "B," who might have an objection that is sound, is not allowed to make it.</p>
              <div className="speaker" id="sp1972"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">If "A" makes a proposition "B" can object to it and speak ten minutes in support of his objection.</p>
              <div className="speaker" id="sp1973"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">That is not the point I am after. I, for example, make a proposition, to which my friend, the Chairman of the Committee of Twenty-one [<span className="persName">Mr. R. Y. CONRAD</span>]
                , is able to make an objection, perhaps, that is sound and that would hold good with the body. But some body else, not as well informed as my friend, might get the floor, and he is then entitled to make the objection. Even though the objection be not a sound one, he speaks his ten minutes; but my friend, the Chairman of the Committee, would be cut off. Is that the interpretation?
              </p>
              <div className="speaker" id="sp1974"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">There are two resolutions. The first is that the Committee shall at once proceed to vote upon the propositions before it, giving five minutes to the member offering any amendment, and the same time to any member opposing it for explanation.</p>
              <div className="speaker" id="sp1975"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">That is not the resolution to which I refer. I ask the Clerk to read another resolution which was adopted.</p>
              <div className="speaker" id="sp1976"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The resolution adopted at the same time?</p>
              <div className="speaker" id="sp1977"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I don't know, sir—they got in there some way or other and I want to see whether I am in a vice or not.</p>
              <p>The Clerk read the second resolution as follows :</p>
              <p>"That hereafter no member in the Committee of the Whole shall be allowed to speak more than once upon the same proposition."</p>
              <PageNumber num={134} />
              <div className="speaker" id="sp1978"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">There is still another resolution or the Clerk informed me wrong this morning.</p>
              <p className="p-in-sp">Here is the resolution to which I desire to call attention :</p>
              <p className="p-in-sp">
                "That on Thursday, the 4th day of April next, at 12 o'clock noon, all debate in the Committee of the Whole upon the reports from the Committee on Federal Relations, shall terminate, and the committee shall at once proceed to vote upon the propositions before it, giving ten minutes to the member offering any amendment, and the same time to one member opposing it, for explanation, and
                ten minutes for replication."
              </p>
              <p className="p-in-sp">Am I to understand that as giving ten minutes to each member, or to only one member of the Convention? Is he that happens to get the floor the only one who is to be allowed to make any objection?</p>
              <div className="speaker" id="sp1979"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">That seems to be the letter of the resolution, and its probable spirit.</p>
              <div className="speaker" id="sp1980"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                It is very easy, if that be the interpretation of the resolution, for a member making a proposition to get some friend to make a groundless objection in order to favor it. Two members may conspire together and kill off all objections, under this interpretation. I want this exclusion, not only of debate, but of objection, to be understood by the people of the State.
              </p>
              <div className="speaker" id="sp1981"><span className="persName">Mr. R. Y. CONRAD</span>—</div>
              <p className="p-in-sp">
                I hope I may be allowed to say one word upon this question, as I offered the resolution. This resolution, sir, was adopted by a vote of the friends of the report of the Committee. The gentleman from Princess Anne objected to the limitation of ten minutes, or five minutes, perhaps, which was then made, and, as I understood him, made an appeal to the Convention, and to myself, that if
                the time was increased to fifteen minutes for the party proposing amendments to the report, he would deem it sufficient.
              </p>
              <div className="speaker" id="sp1982"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">No, sir.</p>
              <div className="speaker" id="sp1983"><span className="persName">Mr. CONRAD</span>—</div>
              <p className="p-in-sp">
                I so understood him, and yielding to his appeal, the resolution was altered, by general consent, so as to give to the member proposing to amend the report of the Committee, instead of five or ten minutes, actually twenty minutes, with the additional advantage of dividing that time into two ten minutes—ten minutes to sustain the proposition, and ten minutes for replication.
              </p>
              <div className="speaker" id="sp1984"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                I want to correct the gentleman. I claimed in point of time what was sufficient, in order to state either the proposition or the objection to the proposition. I said that in many instances fifteen minutes, and three times fifteen, would not be sufficient. It was in that connection that I spoke of fifteen minutes ; but I affirm to the
                <PageNumber num={135} /> gentleman, that at the time I was making the objection, I thought that the word "one" was the word "each" in that resolution. I never looked at it until the clerk showed it to me this morning. It was not until this morning that I became aware that but one member was allowed to make an objection. I was under the impression that
                ten minutes was given to each of the members who might choose to object to a proposition.
              </p>
              <div className="speaker" id="sp1985"><span className="persName">Mr. CONRAD</span>—</div>
              <p className="p-in-sp">I will state, sir, that the understanding of the gentleman from Princess Anne [<span className="persName">Mr. WISE</span>]
                , is not at all material to the point to which I desire to call the attention of the Committee. As I stated, sir, the friends of this report were the ones who established this rule by their vote, and yet we find now that the gentleman from Princess Anne rises with a view of opening this debate, and making it an interminable debate, by permitting every member of the Convention to
                consume ten minutes, and he is doing that on the ground that he is asserting the rights of the friends of the report. It is fair to presume that all propositions for amendment are to come from the gentleman's side, and all explanations would be confined to the friends of the report.
              </p>
              <div className="speaker" id="sp1986"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Will the gentleman allow me to interrupt him?</p>
              <div className="speaker" id="sp1987"><span className="persName">Mr. CONRAD</span>—</div>
              <p className="p-in-sp">
                I will conclude immediately. I am explaining my views. The gentleman says, that when a proposition of amendment is made, an objection may be made to that amendment, and that this objection may not be a sound or good one, whilst some other member may have a better objection ; and he argues that this is an advantage to the friends of the report, because the best objections may not be
                presented to the Convention.
              </p>
              <div className="speaker" id="sp1988"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Will the gentleman permit me for a moment?</p>
              <div className="speaker" id="sp1989"><span className="persName">Mr. CONRAD</span>—</div>
              <p className="p-in-sp">
                I will conclude in a minute or two. I was about to say, that I trust the gentleman will allow the friends of this report to maintain their own rights. They have voluntarily, with a view of bringing this Convention to a close, abandoned this right of debate, even upon objections to amendments ; and I understand they are willing to retain the position that they have voluntarily
                assumed. I will agree not to open my mouth again in this Convention, if gentlemen will allow us to go on and finish our business.
              </p>
              <div className="speaker" id="sp1990"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                The gentleman has not stated my position, and let me say that he does not seem to appreciate his own position. By what authority is it that the gentleman claims that all who adopted this resolution are the friends of this report? When he undertakes to say before a vote is taken upon the report, who are its friends or its foes,
                <PageNumber num={136} /> does he intimate to us that we are to be forced into submission by another body—a body that is king, it may be; but that is not king over me—a caucus that is held outside of this body? He may understand who are the friends of this report, but I don't understand who are its friends or who are its opponents until a vote is taken
                here.
              </p>
              <p className="p-in-sp">
                There is a constituted body to ascertain who are the friends and who are the opponents of this report. But, granting that before a vote is taken, he does know to a certainty, aye, sir, to a caucus certainty, who are friends of the report, and grant it that the friends of the report have a right to take care of themselves, the question is, have I a right to take care of the minority,
                also, and to maintain for them the right of objection? I am not undertaking to take care of the friends of the report, for I know that they will take care, not of themselves alone, but I am afraid that they will take upon themselves the care of others to their ruin, and the ruin of me and mine. I know, sir; that they will take care of their own propositions and their own report,
                whether they take care of themselves and of me or not; but, I would ask, are they taking care, by a course like this, of the interest of the Commonwealth, when they propose here the tyrannical, exclusive vice rule that but one man shall be allowed to make an objection to any proposed amendment to this report, or any item or particle of this report? And, sir, it is a most odious act
                of tyranny—it not only assails the freedom of speech and the right of debate, but it assails the right of opinion and the right of stating an objection.
              </p>
              <div className="speaker" id="sp1991"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">It is out of order to reflect upon the action of the Convention.</p>
              <div className="speaker" id="sp1992"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I will do it holding by the horns of the altar.</p>
              <div className="speaker" id="sp1993"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The gentleman is out of order.</p>
              <div className="speaker" id="sp1994"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Whenever it becomes out of order to exclaim against tyranny in any form in a body like this, your liberties are gone, Sir, and you are slaves already. Is that out of order? If it is, make the most of it.</p>
              <div className="speaker" id="sp1995"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">
                The Chair has indulged the debate for the last ten or fifteen minutes, which, in his opinion, was irregular. The orders of the House must be obeyed, as the Chair understands them. The order which the House made, that debate upon the report of the Committee of Twenty-one should cease at the hour of 12 o'clock, must be enforced; as regards the character of that order, as regards its
                interpretation, if there be a difference in the Committee as to its interpretation, whether in its letter or spirit, whether it be tyrannical, oppressive or otherwise, this Committee cannot change it, cannot alter <PageNumber num={137} /> it, and the only mode to obtain relief, for the gentleman from Princess Anne, or any other member, is to endeavor to
                get the rule changed, when we go back into the Convention. The Chair trusts, therefore, now, that all debate upon the question will cease, and that the Committee will proceed to vote. The question will be upon the third resolution of the Committee.
              </p>
              <p>The third resolution was then reported as follows :</p>
              <p>
                "The choice of functionaries of a common Government established for the common good, for the reason that they entertain opinions and avow purposes hostile to the institutions of some of the States, necessarily excludes the people of one section from participation in the administration of the Government, subjects the weaker to the domination of the stronger section, leads to abuse,
                and is incompatible with the safety of those whose interests are imperilled; the formation, therefore, of geographical or sectional parties in respect to Federal policies, is contrary to the principles on which our system rests, and tends to its overthrow."
              </p>
              <div className="speaker" id="sp1996"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Before the vote is taken upon that proposition, I move that the Committee rise, so that we may go into the Convention with a view of re-considering this resolution restricting debate.</p>
              <div className="speaker" id="sp1997"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The motion would not be now in order, until after two o'clock, the time fixed for the recess.</p>
              <div className="speaker" id="sp1998"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Suppose the house were on fire?</p>
              <div className="speaker" id="sp1999"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">We would protect ourselves the best way we could.</p>
              <p>The question was then taken on the third resolution, and it was agreed to.</p>
              <p>The fourth resolution was then reported as follows:</p>
              <p>
                "The Territories of the United States constitute a trust to be administered by the General Government, for the common benefit of the people of the United States, and any policy in respect to such Territories calculated to confer greater benefits on the people of one part of the United States, than on the people of another part, is contrary to equality and prejudicial to the rights of
                some for whose equal benefit the trust was created. If the equal admission of slave labor and free labor into any Territory, excites unfriendly conflict between the systems, a fair partition of the Territories ought to be made between them, and each system ought to be protected within the limits assigned to it, by the laws necessary for its proper development."
              </p>
              <div className="speaker" id="sp2000"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I move again that the Committee rise. Do I understand the Chair as making the same decision?</p>
              <PageNumber num={138} />
              <div className="speaker" id="sp2001"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Chair considers that the motion cannot be entertained at this time.</p>
              <div className="speaker" id="sp2002"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I appeal from the decision of the Chair.</p>
              <div className="speaker" id="sp2003"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">On Saturday, the 23d of March, the Convention adopted the following resolution :</p>
              <p className="p-in-sp">
                "Resolved, That on and after Monday next, and until further ordered, this Convention shall be called to order at 10 o'clock, A. M., at half past 10 shall resolve itself into Committee of the Whole, upon the reports from the Committee on Federal Relations; at 2 o'clock said Committee of the Whole shall take a recess until 4 o'clock, when it shall resume its session."
              </p>
              <p className="p-in-sp">
                The gentleman from Princess Anne has moved that the Committee now rise. The Chair, upon a due interpretation of this resolution, and in obedience to the order of the House, overrules the motion of the gentleman from Princess Anne as out of order. From the opinion of the Chair the gentleman from Princess Anne has taken an appeal, and the question now is, shall the decision of the
                Chair stand as the judgment of the House?
              </p>
              <div className="speaker" id="sp2004"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                Mr. Chairman, the Convention passed that resolution to be the law of the Committee until the further order of the Convention. My motion now is, that the committee rise, in order that it may go back to the Convention to obtain further orders upon this construction; and I submit it to the common sense of this body, whether the motion that I have made to go back into the Convention and
                take order upon the question is inconsistent with the order itself ? It does seem to me so plain that I know not how to argue it. It seems that, according to the construction of the Chair, from the hour we go into the committee of the whole, under all circumstances, until the Convention, of its own motion, shall order otherwise, we are obliged to sit here, debate, vote, or do
                nothing, until two o'clock comes. Now, I take it, the common sense and practical view of the matter is this—the same body of men constituting the Convention which constitutes the committee—that whenever the committee find that the rule is not practical, is not just, is not equal to the members of the body, that they can rise, and so report to the Convention, and the Convention can so
                amend it as to make it just and equal, fair and practical. I do insist that it is impossible for the members of this Convention to represent their constituents, if, out of 152 men, but one man can be allowed to state objections to propositions; that it is worse than the suppression of freedom of speech. You are now on the 4th section of the first report, which contains 14 sections;
                you have then a second <PageNumber num={139} /> report which contains eight sections, thus leaving eighteen sections yet to be acted upon ; and in the very frontispiece of the report, before you begin to see the middle of it, let alone the end of it, you have adopted a rule, which does not allow objections, however reasonable, to be made to any
                proposition of these twenty-two sections, embracing many pages of an octavo pamphlet ; you allow no objections to be made, however sound, after objection, however unsound, may have been made by one man. I submit it, in the face of their constituents, to this Assembly, whether any body of men can deliberate in justice to themselves, or, what is of much more importance, in justice to
                their constituents, under such a rule as this. Seeing that it is so outrageous in its application, unjust to us, unjust to our constituents, I ask this Committee to report it to the House, in order that the House may take better orders upon the subject.
              </p>
              <div className="speaker" id="sp2005"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">
                I rise to a question of order. I submit, whether it be consistent with the rules which the Convention have adopted for the government of the Committee, that a question of order can be raised in Committee, decided by the Chair, an appeal taken from the decision of the Chair and that appeal discussed without any limitation whatever as to debate, when the Convention has expressly
                instructed the Committee, that it shall only tolerate debate for ten minutes on the proposition upon which the Committee is required to vote.
              </p>
              <div className="speaker" id="sp2006"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Chair overrules the question of order which the gentleman from Harrison [<span className="persName">Mr. CARLILE</span>] , makes. This is a privileged question. All decisions of the Chair are privileged questions, from which appeals lie, and on all such questions there is no limit to debate.</p>
              <div className="speaker" id="sp2007"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">The result then is, that upon questions raised in the manner in which this has been, unlimited debate is to arise, and the order of the Convention is never to be observed.</p>
              <div className="speaker" id="sp2008"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">There is no limit to debate on an appeal taken from the decision of the Chair. The limitation of debate to ten minutes, is confined to the report of the Committee of twenty-one.</p>
              <div className="speaker" id="sp2009"><span className="persName">Mr. SPEED</span>—</div>
              <p className="p-in-sp">
                I do trust it will be the pleasure of the Committee to sustain the appeal that has been taken from the decision of the Chair. I suppose everybody understands that these rules are prescribed for the purpose of preserving order, and contributing to the dispatch of business intelligibly. The chief object I had in view in seeking to obtain the floor, even before the appeal was taken, was
                to state what seemed to me to be proper that this body should know, or at least some of them. My apprehension was, that many members voted for this resolution, not giving it the construction that the Chair gave to it.
              </p>
              <PageNumber num={140} />
              <p className="p-in-sp">
                It will be remembered by this Convention, that when this motion was made before, I opposed it for several reasons, which I stated very briefly, and I was cut off, under the rule confining the debate to ten minutes, from assigning other reasons. It did not enter my mind that this resolution was susceptible of the interpretation which has been given to it. I confess now that I have
                read and examined it critically as you have done, Mr. Chairman ; that I concur in the correctness of the interpretation literally; and if I had understood it, then as I do now, I would have commented in stronger terms upon the cruelty and injustice of such a rule than I did upon that occasion. Look at this matter for a moment. Here we are considering amendments to the Constitution of
                the United States, to be made acceptable to the people of Virginia, and to the people of the other States of this Union. Those gentlemen who have considered this matter for six weeks and have had their opinions matured in regard to what those amendments should be, are prepared to vote without discussion at all. I rise to suggest a modification of some one of these amendments. In my
                feebleness I waste my time in a preface, or in an idle effort to show that the proposition or amendment I submit ought to be made. By my side sits my colleague, abler than I am, able to show to this body that this amendment ought to prevail, but for want of the explanation which he or any other member is able to give, showing the propriety of the amendment in question, the amendment
                fails, and thus, that which we all ought to desire to accomplish, to wit, the perfection of these resolutions to the highest degree, we are prevented from accomplishing by the operation of the rule which was prescribed for the despatch of our business intelligibly. Now, I submit that this appeal ought to prevail; if there were no other reason, because the Convention, I presume, voted
                for the resolution in question without giving it that consideration which it required, without giving it that interpretation which is now given to it—an interpretation, the injustice of which is so manifest that it seems to me scarcely a quorum or a majority of a quorum of this body could be found to vote for such a resolution, when the operation of it is to be such as I have shown
                you. As soon as we get into Convention I propose to offer the following resolution :
              </p>
              <p className="p-in-sp">"Resolved, That the first resolution adopted on the 30th of March, in regard to the regulation of debate in Committee of the Whole be so modified as to permit any member advocating any amendment the space of ten minutes."</p>
              <p className="p-in-sp">
                This is not done for the protection of the majority, who may have made this report, and who may be prepared in ten minutes, to <PageNumber num={141} /> advocate the propriety of their action, but it is done for the benefit of those who may have some fault to find with it, and who may have friends who can furnish better reasons for it, than they can
                themselves. I ask that, in some mode or other, we may get out of the dilemma in which we have been placed.
              </p>
              <div className="speaker" id="sp2010"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">
                I dislike very much, Mr. Chairman, to dissent from you upon this proposition. I do it honestly and sincerely. I know you well enough to know that you can appreciate the position of a gentleman when he dissents from a presiding officer. I understand you to decide the broad proposition, because the Convention has adopted a resolution declaring that they shall go into Committee of the
                Whole at a certain hour, then take a recess at another hour, to meet again at another hour, that for no cause can this Committee entertain a motion to rise until the hour of two arrives. Let us see how this is. As the gentleman from Princess Anne well stated, suppose the house were on fire, could not the Chair entertain a proposition that the Committee rise?
              </p>
              <div className="speaker" id="sp2011"><span className="persName">Mr. PRICE</span>, of Greenbrier—</div>
              <p className="p-in-sp">Will my friend allow me to ask him a question?</p>
              <div className="speaker" id="sp2012"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">Certainly sir, with pleasure.</p>
              <div className="speaker" id="sp2013"><span className="persName">Mr. PRICE</span>—</div>
              <p className="p-in-sp">Would the gentleman from Middlesex wait to submit a motion of that kind if the house was on fire?</p>
              <div className="speaker" id="sp2014"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">
                Yes, sir. Perhaps the gentleman from Middlesex would not be so much frightened as other gentlemen might be; and he would submit a motion so that the Committee might come back, according to the well-established rules of Parliamentary regulation. But, sir, let me put this illustration. Say you go into Committee of the whole at 12 o'clock, and in ten minutes after, a member of this body
                should fall dead upon this floor, do you say you could entertain no motion for the Committee to rise? Do you say that we should be compelled to sit here over the dead body of one of our members for nearly two hours? It strikes me as preposterous.
              </p>
              <p className="p-in-sp">But take another case. Suppose you complete the consideration of the business before the Committee at one o'clock, will you stay here, like a parcel of children after they have finished their game of marbles, waiting till dinner is ready, until 2 o'clock, before you rise? Sir, such a thing was never heard of.</p>
              <p className="p-in-sp">But, Mr. Chairman, this Committee has already decided that very identical question. The distinguished gentleman from the county of Augusta [<span className="persName">Mr. BALDWIN</span>]
                , wants to commit Virginia to certain principles and certain doctrines because, he says, such has been her <PageNumber num={142} /> action that she is estopped from raising objection. I do not think it is statesmanlike to put in a plea of estoppel, but it is in accordance with Parliamentary law, for your whole code of Parliamentary law is but a
                compilation of precedents. When the gentleman from Richmond
                [<span className="persName">Mr. MACFARLAND</span>] , was addressing the Committee, the other day, and indicated his desire that the Committee should rise before the usual hour, because he was exhausted, a motion was made that the Committee rise and it did rise before the hour of two. The motion was entertained then.</p>
              <div className="speaker" id="sp2015"><span className="persName">Mr. PRESTON</span>, of Montgomery—</div>
              <p className="p-in-sp">I made that motion, sir, and the Committee refused to entertain it, but by unanimous consent it was agreed to.</p>
              <div className="speaker" id="sp2016"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">
                Precisely so, sir. The Chairman refused to entertain the motion, but by unanimous consent the Committee rose before the appointed hour; and, if it was done by unanimous consent then, why can't it be done by unanimous consent now? Sir, I understand this whole question. I trust I will be the last member in this body, or elsewhere, to raise anything like a factious objection even to
                what I conceive to be a tyrannical movement; but I told you the other night, when you adopted this resolution, that if gentlemen thought proper to avail themselves of privileges guaranteed to them by the principles of parliamentary law, the resolution would protract your debate and protract your sessions, instead of shortening them. And now you see that I was right, don't you? Yes,
                sir, I am right. Now, you have got up an appeal from the decision of the Chair, and if gentlemen think proper they can debate this question for a month and you cannot stop them. Now, I do submit to this Convention, that the decision of the Chair is incorrect, that it ought to be overruled, and that this resolution ought to be reconsidered. Why, Mr. Chairman, what is the effect of
                this resolution before the country? It is arrogating to that Committee of Twenty-one, and I would speak of that committee, sir—
              </p>
              <div className="speaker" id="sp2017"><span className="persName">Mr. STUART</span>, of Doddridge—</div>
              <p className="p-in-sp">I rise to a question of order.</p>
              <div className="speaker" id="sp2018"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">About what?</p>
              <div className="speaker" id="sp2019"><span className="persName">Mr. STUART</span>—</div>
              <p className="p-in-sp">In arguing this question, the gentleman must confine himself to the point in issue, and that is, whether the decision of the Chair is correct or not.</p>
              <div className="speaker" id="sp2020"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">Well, sir, in doing that, I have a right to give my reasons, and I may go somewhat into the merits of the resolution upon which the point of order is raised. I will not transcend any rule of order.</p>
              <PageNumber num={143} />
              <p className="p-in-sp">
                I was about to remark, Mr. Chairman, that I was a member of that Committee, and I entertain for every member of it the most profound respect. That resolution, sir, arrogates to the Committee of 21 the whole wisdom of this Convention. Yes, sir, you see that you do so by adopting a resolution which will prevent you from altering or improving their report in the slightest particular.
                Sir, if it is the desire of this body to put the destiny of Virginia and of the whole country in the hands of this committee of twenty-one, let us adjourn, constitute them a Committee of Safety, and let them decide what they please. That is the position of the whole question, sir. I told you the other evening, sir, that the members of this Convention were here as the representatives
                of the people of Virginia, and that such a course of action as this resolution proposed, would not be calculated to produce the feeling of harmony which was so much desired at this time in all parts of the State, but would increase the already too much distracted condition of the Commonwealth, and that such a resolution should not be adopted. As I said before, it will protract the
                session of this Convention, and you cannot prevent it from doing so.
              </p>
              <div className="speaker" id="sp2021"><span className="persName">Mr. PRICE</span>, of Greenbrier—</div>
              <p className="p-in-sp">
                The question now before the Committee is : "Has the Chairman of the Committee of the Whole correctly interpreted the order?" and is not as to whether the order has been correctly made or not. If the Chairman of the Committee has correctly interpreted the order, ought he not to be sustained, whatever might be the order? Whatever may be the order? What is that order? The order is that
                we shall go into Committee of the Whole at half past ten o'clock, remain in Committee of the Whole till two, and meet again at four. That is the order of the Convention, and under that order this Committee is acting. Now, if we rise before two are we obeying the order of the House? I think not. And if we are not obeying the order of the House, then the Chairman of the Committee has
                given the correct interpretation of the order. Now, sir, we have had arguments adduced and questions propounded here upon this matter. Suppose, it was asked, that a member should fall dead upon the floor, or suppose that some accident should occur to disperse the Committee without any motion, what is to become of the Committee of the Whole? Why, sir, I apprehend that the Chairman as
                has been the case in the British Parliament—would resume the Chair without any motion. If a fire should occur, who would wait here until all the forms of Parliamentary law were gone through? Why, sir, the Chairman would vacate the chair without any motion and we would all scamper off the best way we could. Those occasions are extraordinary and there
                <PageNumber num={144} /> certainly is no law laid down for them in the rules for the government of the general proceedings of any deliberative body. And, whilst I have the floor, I desire to reply to my friend from Campbell
                [<span className="persName">Mr. SPEED</span>] , because he and I do not understand the order alike. Suppose he should offer an amendment to the report, and he would have ten minutes to speak upon that amendment; then the Chairman of the Committee, or any other member would have ten minutes to reply to him, then his colleague would have ten minutes in replication? That is not as I understand it.</p>
              <div className="speaker" id="sp2022"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Read the resolution.</p>
              <div className="speaker" id="sp2023"><span className="persName">Mr. PRICE</span>—</div>
              <p className="p-in-sp">If that is the construction that gentlemen put upon it, I think I have not understood the order aright. As I understand it, either the gentleman himself, who offers an amendment, or his friend, would have a right to occupy ten minutes in replication. Is not that the interpretation which you gave the order, sir?</p>
              <div className="speaker" id="sp2024"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The gentleman propounding the amendment has the right of replication for ten minutes.</p>
              <div className="speaker" id="sp2025"><span className="persName">Mr. PRICE</span>—</div>
              <p className="p-in-sp">Then it is confined to the gentleman himself who proposes an amendment; and he would have the privilege of occupying twenty minutes altogether, while the friend of the report as it is would have only ten minutes.</p>
              <div className="speaker" id="sp2026"><span className="persName">Mr. SPEED</span> of Campbell—</div>
              <p className="p-in-sp">
                My argument is based upon the idea that I would be so restricted in time that I could not finish the reasons that occurred to my mind for presenting the amendment; and, further, that although I might not be restricted, I might not be so well qualified to present arguments in favor of the amendment as another member, and yet I would consume the time while he would have none.
              </p>
              <div className="speaker" id="sp2027"><span className="persName">Mr. PRICE</span>—</div>
              <p className="p-in-sp">
                I know my friend would not be so unfortunate as not to make himself understood in ten minutes ; but if such should be the case, I apprehend it would be better for his colleague to propound the amendment. He has the right to open and to conclude, and if he were confused at the outset, I hope he would be very clear at the conclusion, so that he would be distinctly understood. I think
                it is quite material to know what the sense of this committee is. I ask that the ayes and noes shall be called, and let us see whether the decision of the Chair ought to be sustained.
              </p>
              <div className="speaker" id="sp2028"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Will my friend from Greenbrier let me give him another illustration? Just now he made a blunder, an egregious one, in which the Chair corrected him, but under that rule he would not be allowed to correct himself.</p>
              <div className="speaker" id="sp2029"><span className="persName">Mr. PRICE</span>—</div>
              <p className="p-in-sp">I am aware, sir; it was a very immaterial blunder.</p>
              <PageNumber num={145} />
              <div className="speaker" id="sp2030"><span className="persName">Mr. AMBLER</span>, of Louisa—</div>
              <p className="p-in-sp">
                I differ from the Chair on this question. I would ask the attention of the Chair to the rules of the House. In the rules which you have adopted here, there is an express direction to the Chair to be governed by Jefferson's Manual. If the Chair will look at that Manual, it will find in the rules laid down to govern the proceedings of the Committee of the Whole, that this principle is
                clearly involved in the instances there cited : that when, for sufficient reason, by accident, or even by design—as in the case of a difficulty arising in the Committee of the Whole—when the Speaker took the Chair, and, by the act of taking the Chair, he, himself, on his own motion, dissolved the Committee of the Whole and the House was instantly in session. Now, sir, can it be that
                the Chair is so to construe the rule here as that the wish of the body of the Committee is to be ignored, their intention crushed out by the decision of the Chair, when the case is before you in that Manual by which you are directed to be governed, where, by the motion of the President himself, the Committee of the Whole was dissolved and the House resumed its session? Do you mean,
                sir, as you must, if your decision is sustained, that the body of the Committee cannot do what the body of the Convention itself can do? Suppose that this Committee should find itself without a quorum, has the Committee no power to send after members to bring a quorum here, if a question is pending before it? You cannot have a vote upon the question unless you have a quorum; and, if
                the decision of the Chair stands, you must wait here until 2 o'clock arrives before you can exercise this power. Is not that construction an equal absurdity? I might go on and illustrate the principle in fifty different ways. It is an absurdity which we would be forced into if you allow the decision of the Chair to stand. The Committee itself is the judge of when, under what
                circumstances, and how, it ought to report its action to the House.
              </p>
              <p className="p-in-sp">
                It is suggested to me that the case which I have just put has actually occurred here, where there was no quorum, and the practice then adopted was to go back into Convention, and correct the evil, whatever it was—for I do not remember the case myself. But, sir, if this Committee sustain the decision of the Chair, we place ourselves in a position where it must necessarily follow—as I
                think can be demonstrated by the manual, which is the authority which should govern the Chairman of the Committee here—from which nothing but a total disregard of the rights of the minority, can by any possibility extricate you.
              </p>
              <div className="speaker" id="sp2031"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">
                Gentlemen, it is proper that, after listening with much patience to the various arguments urged against the decision which has just been given touching the question before you, the views <PageNumber num={146} /> of the Chair should be heard, and at the same time the Chair assures the Committee that if, in their opinion, the Chair is incorrect in the
                decision which it has just given—of the correctness of which the Chair has no doubt—it is the duty of the Committee, irrespective of the feelings of the Chair, to overrule its decision and sustain the appeal. It has been stated by the gentleman from Princess Anne
                [<span className="persName">Mr. WISE</span>]
                the resolution provides that this Convention shall be called to order at 10 o'clock, A.M., at half past 10 shall resolve itself into Committee of the Whole upon the reports of the Committee upon Federal Relations, at 2 o'clock shall take a recess until 4, when it shall resume its session and sit during its pleasure, and that that order shall so remain the law of this Committee until
                further ordered by the house. The gentleman is perfectly correct. Until the house alters this law which it has prescribed for the government of this Committee, it is binding and obligatory upon it ; and, in the opinion of the Chair, the Committee have no right to reverse the order of the Convention, and that order remains during the pleasure of the house, and until set aside by the
                house. It can only be in the House when it is in Convention assembled, that a motion can be made to rescind, modify, or entirely repeal an order which it has made. It has been further suggested by the worthy gentleman from Middlesex
                [<span className="persName">Mr. MONTAGUE</span>]
                that the decision of the Chair is not correct, simply upon the ground that the business of the Committee may be concluded before the hour of two o'clock on any day. I would say, that when the business of the Committee is completed, of course there is an end of it, and the Committee must rise. It resolves itself back into Convention and reports to the House that its duties have been
                entirely discharged, its business completed, and of course it is dissolved, and does not meet again. It has been intimated by the worthy gentleman from Louisa
                [<span className="persName">Mr. AMBLER</span>] that the Manual lays down the rule. Whatever rule the Manual lays down, the Convention has made a rule for its own government independent of the Manual.</p>
              <p className="p-in-sp">The Convention has made this rule and any provision of the manual to the contrary has been set aside by the order of the Convention.</p>
              <div className="speaker" id="sp2032"><span className="persName">A MEMBER</span>—</div>
              <p className="p-in-sp">Suppose we were without a quorum?</p>
              <div className="speaker" id="sp2033"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">There would then be no Committee in session. It cannot be in session if there is no quorum. The moment, therefore, that fact is ascertained, the Committee must necessarily rise, go back to the House, and inform the House of that fact. But if there be a full Committee they must go on, as directed, to discharge their duty.</p>
              <p className="p-in-sp">
                In regard to any sudden excitement, such as an alarm of fire, or from any other cause, which we cannot anticipate, it is not necessary for the <PageNumber num={147} /> Chair now to give an opinion. If the house were in flames, the Chair supposes that members would look to their own personal safety, and that it would not be necessary for any exact
                parliamentary form to be observed. Indeed, if the danger was imminent, the Chair will not say that he should remain long enough to receive a motion that the Committee rise.
                [Laughter.] The question now is upon the appeal from the decision of the Chair, on which the yeas and nays have been ordered. Those who are in favor of sustaining the decision of the Chair will say "aye," those opposed, "no."</p>
              <p>The Secretary commenced to call the roll, but was interrupted by the Chairman, who stated that he believed the question was not understood. Those who voted for sustaining the appeal should vote in the affirmative.</p>
              <div className="speaker" id="sp2034"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">The question is, shall the decision of the Chair stand as the judgment of the Committee. Those who are for sustaining the decision of the Chair therefore vote in the affirmative.</p>
              <div className="speaker" id="sp2035"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Chair intended so to state it.</p>
              <div className="speaker" id="sp2036"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Well, now, sir, I wish to know how to vote. The Chairman stated it one way at the first, another way the second time, and the third time as at first. Now, am I to vote aye or no by statement number one, number two, or number three?</p>
              <div className="stage it">[Laughter.]</div>
              <div className="speaker" id="sp2037"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">
                The Chair will inform the gentleman that he may dismiss number one, number two and number three, and that he will now state the question, he hopes, so that every gentleman will understand it. An appeal has been taken from the decision of the Chair. The question to be decided is, shall the decision of the Chair stand as the judgment of the Committee. Those who are of that opinion will
                vote "aye," those who are opposed will vote "no."
              </p>
              <p>The question was taken, and it was decided in the affirmative—ayes 85, nays 43—as follows :</p>
              <p>AYES-Messrs. Janney [President]
                , Aston, A. M. Barbour, Baylor, Berlin, Blow, Jr., Boggess, Bouldin, Boyd, Branch, Brent, Brown, Burdett, Byrne, Campbell, Caperton, Carlile, Carter, Chapman, C. B. Conrad, R. Y. Conrad, Couch, J. H. Cox, Critcher, Custis, Dent, Deskins, Dorman, Early, Echols, Flournoy, French, Fugate, Garland, Gillespie, Gravely, Goggin, A. Hall, E. B. Hall, Hammond, Haymond, Hoge, Hubbard, Jackson,
                Marmaduke Johnson, Peter C. Johnston, Kilby, Lewis, McComas, McGrew, McNeil, Macfarland, Charles K. Mallory, James B. Mallory, Marshall, Marr, Masters, Moffett, Moore, Nelson, Osburn, Parks, Patrick, Pendleton, Porter, Preston, Price, Pugh, Robt. E. Scott, Sharp, Sheffey, Sitlington, Slaughter, Spurlock, Staples, A. H. H. Stuart, C. J. Stuart, Summers, Sutherlin, Tarr, Tayloe,
                Waller, Whitfield, Wickham, Willey-85.
              </p>
              <PageNumber num={148} />
              <p>
                NOES-Messrs. Ambler, James Barbour, Blakey, Boisseau, Borst, Bruce, Cecil, Coffman, Conn, Richard H. Cox, Fisher, Graham, John Goode, Jr., Cyrus Hall, L. S. Hall, Harvie, Holcombe, Hughes, Hunton, Isbell, Kent, Kindred, Lawson, Leake, Marye, Sr., Montague, Morris, Morton, Neblett, Orrick, Randolph, Richardson, Seawell, Speed, Strange, Tredway, R. H. Turner, Franklin P. Turner, Tyler,
                Wilson, Wise, Woods, Wysor-43.
              </p>
              <p>So the decision of the Chair was sustained as the judgment of the Committee.</p>
              <p>Before the vote was announced, <span className="persName">Mr. CHAMBLISS</span> stated that he had paired off with <span className="persName">Mr. HOLLADAY</span>.</p>
              <p>
                <span className="persName">Mr. HALL</span> stated that he had paired with <span className="persName">Mr. FORBES</span>, otherwise he should vote in the affirmative.<span className="note" id="Note13"
                ><span className="noteLabel">2</span>In the Proceedings of April 6, page 226, Mr. Hull noted that it was he, and not Mr. Hall, who had paired off with Colonel Forbes. It is not clear whether Mr. Hull referred to only one of the two votes in which Mr. Hall was reported to have paired with Colonel Forbes, or to both of them.</span
                >
              </p>
              <p>A member stated that <span className="persName">Mr. BURLEY</span> was absent on account of indisposition.</p>
              <p>The vote was then announced as above recorded.</p>
              <p>The Chairman then stated the question to be upon the adoption of the fourth resolution, which was read as follows :</p>
              <p>
                "The Territories of the United States constitute a trust to be administered by the General Government, for the common benefit of the people of the United States, and any policy in respect to such Territories calculated to confer greater benefits on the people of one part of the United States, than on the people of another part, is contrary to equality and prejudicial to the rights of
                some for whose equal benefit the trust was created. If the equal admission of slave labor and free labor into any Territory excites unfriendly conflict between the systems, a fair partition of the Territories ought to be made between them, and each system ought to be protected within the limits assigned to it, by the laws necessary for its proper development."
              </p>
              <div className="speaker" id="sp2038"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                I think the words "United States," were inserted by mistake, in the clause which says, "the Territories of the United States are for the common benefit of the people of the United States." "For the benefit of the people of the several States," I think was what the Committee intended to say. I may be mistaken about that, but I prefer the expression "several States," and I move
                therefore, to amend, by striking out the words "United States" in the third line of the fourth section, and inserting "several States."
              </p>
              <p className="p-in-sp">Now, sir, I suppose under your rule, I am entitled to ten minutes. I do not know who holds the watch.</p>
              <div className="speaker" id="sp2039"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Chair holds the watch.</p>
              <div className="speaker" id="sp2040"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                If anything can express the idea of consolidation in its most direct form, the expression "for the common benefit of the people <PageNumber num={149} /> of the United States," expresses it. You make the benefit common to the aggregate consolidated mass by that expression. I submit to you that the correct idea is, that the benefits spoken of are benefits
                which are common to the several States. The word "several" expresses their separation into individual sovereignties. "United States" are words which aggregate them in a national sense, and the word "common" prefixed to the word "benefit," when united to the one expression or the other, gives distinct force to the one idea or the other. Thus the "benefit" spoken of by that resolution
                is "common" to a consolidated government and people; according to the amendment which I now propose, it is "common" to the several confederates. Here, again, creeps out, or rather crops out, as we speak in geology, as almost everywhere throughout this report, the idea of consolidation, and it not only crops out, but the whole boulder of the idea is above ground. If for "the common
                benefit of the people of the United States," means anything it means, the consolidated benefit of the consolidated people of the States.
              </p>
              <p className="p-in-sp">
                I only wish, in this State-rights State, in this mother of State rights, in a Convention of her people, that we shall preserve, at all events, all the guards against the direct assertion of any doctrines or principles opposed to her ancient faith. It may be that she has now, other opinions, that other faith has superseded her ancient faith. If that be so, I wish it known to the
                people and I wish it distinctly expressed. I shall fight that idea of consolidation, whether it presents itself in the full boulder, or whether it crops out just into sight anywhere in this report; and I offer this amendment, not for the purpose of seeming hypercritical, but because the phrase used is one which expresses the idea of consolidation.
              </p>
              <div className="speaker" id="sp2041"><span className="persName">Mr. CONRAD</span>, of Frederick—</div>
              <p className="p-in-sp">
                I cannot acquiesce in the propriety of the proposed amendment. The language which is used in this section is precisely the language of the Constitution of the United States. Nor can I see any liability to this objection of consolidation which the gentleman has raised. It states that the "Territories of the United States constitute a trust to be administered by the General Government,
                for the common benefit of the people of the United States." Now, sir, I would ask, with what propriety can it be alleged that these territories are held for the benefit of the people of the several States? Is there any idea of severalty connected with the holding of these territories; and has not the State of Virginia refused—upon the very ground that the Federal Government has no
                right to divide and pay in severalty the proceeds arising from the sales of its public lands—to receive her <PageNumber num={150} /> quota of these proceeds, alleging that they were for the common benefit of the whole people of the United States?
              </p>
              <p className="p-in-sp">
                Now, sir, as to the idea that this language imports consolidation. If it does, the Constitution of the United States establishes consolidation ; but our construction has always been that the people of the United States are only the people of the States united, still preserving the character of a community to the people of each State, and not importing, in any way, that the people of
                all the States were consolidated into one people. Is there any such conclusion to be drawn from this language? It is that they are "for the common benefit of the people of the United States," and I repeat that the idea of a several ownership by the several States in their political capacity was certainly never intended to be applied to the common territories of the United States.
                They were ceded for the benefit of all, to remain in common, not to be divided, according to the doctrine of Virginia, even by the power of the Federal Government, the trustee.
              </p>
              <div className="speaker" id="sp2042"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">How many minutes did I use the first time?</p>
              <div className="speaker" id="sp2043"><span className="persName">A MEMBER</span>—</div>
              <p className="p-in-sp">Only five.</p>
              <div className="speaker" id="sp2044"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Then am I to have the other five minutes added now to my ten?</p>
              <div className="speaker" id="sp2045"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The gentleman will only be entitled to ten minutes in reply.</p>
              <div className="speaker" id="sp2046"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Then, sir, you do not reward economy in time. [Laughter.]
                And, sir, I not only gain nothing in economy, but here I stand the exclusive monopolist, quoad hoc, of the floor. Now who objects to this proposition? Not one of you gentlemen, my peers, have the right to open your lips in reply to me or in reply to the gentleman from Frederick. He has had the floor, I have got it in reply, and sit still all of you and listen. I am, sir, as
                sufficient to reply as the report is infallible, and I must not be interrupted by any one. Oh! oh! the travesty of the performance of our duty upon a great question like this, the great important subject of sovereignty and consolidation, of separate identity or individual independence, and you are to give 10 minutes for its consideration at this crisis. Well, sir, I do not want two
                minutes, let alone 10, in reply to my friend from Frederick. But, mark you, he has got to sit still and take it. He cannot make any rejoinder. In a County Court he would have the right—here silence must be the argument.
              </p>
              <p className="p-in-sp">
                The gentleman says, is there any idea of severalty in the States in respect to the territories? If there is not in relation to eminent domain, is there in relation to anything else? But suppose it to be true, that <PageNumber num={151} /> there is no idea of severalty as to what is common—cannot a thing be common to several? Is the gentleman ansivered?
                He cannot answer me. I ask him a question; but you, Mr. Chairman, say no. You forbid him, you command him, not to answer.
                [Laughter.]
                I ask him, as a lawyer—whether a county court or a circuit court lawyer—cannot a thing be common to several? The idea of severalty unquestionably attaches to, and it is the only idea I have to deal with. You assert here that lands are not common to the several States, and that their benefits are not common to the several States, but they are common to the consolidated people of the
                United States—that they are common not only because they are held to be regulated by, or to belong to, the people of the whole nation, but that they are common because they belong to the United States as a nation, and not to the several States as confederates and several in their confederation—several, even as they are united--united because they are several; but several, though
                united, and not consolidated, though united. They are partners. Now take the instance of partners in a mercantile concern. We have a stock of goods on hand ; the goods are owned by the common partnership; yet, are not the partners several? So, in our Confederacy, the benefits, such as the public lands, for example, are common to the several confederates, and common to them as
                several. But the Committee asserts that they are common to the people of the United States, in a consolidated mass. That is what I deny.
              </p>
              <p className="p-in-sp">
                Now, sir, take the grant of Virginia. My friend reminds me of it. Virginia ceded a larger territory than was ceded to the United States by any other State. When Virginia made her grant, when all the States made their grant to the General Government, of land, how did they grant their land? Take that as an instance of common benefit. They granted it for the benefit of the several
                States, and yet the gentleman says there is no idea of severalty attached to the common benefits of the Union, no idea of severalty attached to the lands of the Union. These grants, in their express terms, were for the common benefit of the several States. Well, sir, I only want to say, that these benefits are common to us, that they are common benefits to the several States and not
                to the people in a consolidated relation, as is claimed by the faith of my friend from Frederick. He believes in consolidation ; I do not. I believe in the several, though united sovereignty and independence of the confederation of the States. There is the difference between us. He has no idea of severalty. I have no tolerance for the idea of consolidation, and I will not entertain
                it.
              </p>
              <p><span className="persName">Mr. SEAWELL</span> demanded the yeas and nays.</p>
              <PageNumber num={152} />
              <p>The yeas and nays were ordered.</p>
              <p>The question was taken, and it was decided in the affirmative—yeas 68, nays 66, as follows :</p>
              <p>
                YEAS-Messrs. Ambler, J. Barbour, Blakey, Blow, Boisseau, Borst, Bouldin, Boyd, Bruce, Caperton, Cecil, Chapman, Coffman, Conn, C. B. Conrad, R. H. Cox, Deskins, Dorman, Echols, Fisher, Flournoy, Garland, Gillespie, Graham, J. Goode, Hale, C. Hall, L. S. Hall, Hammond, Harvie, Holcombe, Hunton, Isbell, Kent, Kilby, Kindred, Lawson, Leake, Macfarland, C. K. Mallory, Marr, Marye,
                Montague, Morris, Morton, Neblett, Orrick, Parks, Preston, Randolph, Richardson, Seawell, Sheffey, Slaughter, Southall, Speed, Strange, Sutherlin, Thornton, Tredway, R. H. Turner, F. P. Turner, Tyler, Whitfield, Wilson, Wise, Woods, Wysor-68.
              </p>
              <p>NAYS-Messrs. Janney [President]
                , Aston, A. M. Barbour, Baylor, Berlin, Boggess, Branch, Brent, Brown, Burdett, Byrne, Campbell, Carlile, Carter, R. Y. Conrad, Couch, J. H. Cox, Critcher, Custis, Dent, Early, French, Fugate, Gravely, Gray, Goggin, A. Hall, E. B. Hall, Haymond, Hoge, Hubbard, Hughes, Jackson, M. Johnson, P. C. Johnston, Lewis, McComas, McGrew, McNeil, J. B. Mallory, Marshall, Masters, Moffett,
                Moore, Nelson, Osburn, Patrick, Pendleton, Porter, Price, Pugh, R. E. Scott, W. C. Scott, Sharp, Sitlington, Spurlock, Staples, A. H. H. Stuart, C. J. Stuart, Summers, Tarr, Tayloe, Waller, White, Wickham, Willey-66.
              </p>
              <p>So the amendment was agreed to.</p>
              <p>
                During the call of the roll <span className="persName">Mr. HALL</span> announced that he had paired off with <span className="persName">Mr. FORBES</span>.<span className="note" id="Note14"><span className="noteLabel">3</span>See note 2, above.</span>
              </p>
              <div className="speaker" id="sp2047"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Well I am glad that States Rights are triumphant by two. Now to make the remainder of the section conform, I move to amend in the fifth line which reads "calculated to confer greater benefits on the people of one part of the United States than on the people of another part," by striking out "one part of the United" and inserting "some of the."</p>
              <div className="speaker" id="sp2048"><span className="persName">Mr. FLOURNOY</span>—</div>
              <p className="p-in-sp">As I understand the amendment of the gentleman from Princess Anne, the insertion of the expression "some of the" States would make it liable, under a strict construction, to mean that it might not be conferred on one. I would suggest to him the use of the words "one or more."</p>
              <div className="speaker" id="sp2049"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Very well. I will move to insert the words "one or more."</p>
              <p>The amendment was agreed to.</p>
              <div className="speaker" id="sp2050"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I move now to amend, in the 6th line, by striking out "another part" and inserting the words "other States." The clause as amended would then read : "calculated to confer greater benefits on the people of one or more of the States than on the people of other States," &amp;c.</p>
              <p>The amendment was agreed to.</p>
              <PageNumber num={153} />
              <div className="speaker" id="sp2051"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">I move to amend the fourth section, by striking out all after the word "created," in the eighth line, as follows :</p>
              <p className="p-in-sp">"If the equal admission of slave labor and free labor into any Territory, excites unfriendly conflict between the systems, a fair partition of the Territories ought to be made between them, and each system ought to be protected within the limits assigned to it, by the laws necessary for its proper development."</p>
              <p className="p-in-sp">
                Mr. Chairman, according to my opinion, and the opinion of a majority of the gentlemen who have addressed the Convention, this clause, which I move to strike out, does not give to Virginia and the slave States that equality which all wish to see carried out. If the Northern man can come, with all his property, into the territory South of 36 deg. 30 min. ; and if the Southern man
                cannot do the same in the territory North of it, that is not equality. It is not in accordance with the present Constitution of the United States, as construed by the Supreme Court of the United States, in the Dred Scott decision. For one, I am free to say, that so far as my voice and my influence shall be potential, there never shall be a re-construction, unless I can stand the peer
                of the Northern man on any portion of our soil. I am not willing to be interdicted from taking any portion of my property into territory North of 36 deg. 30 min., if the Northern man can bring his into territory South of that line. If the North have such holy horror of Southern men and Southern institutions, let there be a division of the territory, and let it be declared that North
                of 36-30 the Southern man shall not have any participation whatever, and that South of it a man from the North shall not tread the soil with any portion of his property. In that there would be equality. But in the spirit of compromise you have given up that provision of the constitution which the Federal court has decided gives the Southern man his rights.
              </p>
              <p className="p-in-sp">
                You give up that equality, and consent to give the North a monopoly of the territory North of 36-30, and you give it at the same time equal privileges in the territory South of that line. That is not equality. That is not justice. If slavery cannot be permitted to stand where the constitution has placed it, where the Bible has placed it, and where reason has placed it, then there is
                that incongruity between the two sections which prevents them associating together any longer.
              </p>
              <p><span className="persName">Mr. WISE</span> called for the yeas and nays on <span className="persName">Mr. MORTON</span>'S amendment.</p>
              <p>The ayes and nays were ordered.</p>
              <div className="speaker" id="sp2052"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">
                I want to give the reason why I shall vote for the amendment. The resolution recognizes the doctrine of drawing a line <PageNumber num={154} /> of partition between free labor and slave labor. If it be wrong to carry slavery North of the line it must be wrong to carry it South of the line, and I do not mean to vote here or elsewhere to localize or
                sectionalize slavery. Hence, I shall vote for the amendment.
              </p>
              <p>The question was taken, and it was decided in the negative yeas 38, nays 91, as follows :</p>
              <p>YEAS-Messrs. Ambler, James Barbour, Blakey, Boisseau, Borst, Carlile, Cecil, Chapman, Coffman, Conn, J. H. Cox, R. H. Cox, Fisher, Garland, Graham, John Goode, Hale, Cyrus Hall, L. S. Hall, Harvie, Holcombe, Hunton, Isbell, Kent, Kindred, Leake, Montague, Morris, Morton, Neblett, Randolph, Richardson, Seawell, Strange, Thornton, R. H. Turner, Wise, Woods-38.</p>
              <p>NAYS-Messrs. John Janney [President]
                , Aston, A. M. Barbour, Baylor, Berlin, Blow, Boggess, Bouldin, Boyd, Branch, Brent, Brown, Bruce, Burdett, Byrne, Campbell, Caperton, Carter, C. B. Conrad, R. Y. Conrad, Couch, Critcher, Custis, Dent, Deskins, Dorman, Early, Echols, Flournoy, French, Fugate, Gillespie, Gravely, Goggin, Addison Hall, E. B. Hall, Hammond, Haymond, Hoge, Hubbard, Hughes, Marmaduke Johnson, Peter C.
                Johnston, Kilby, Lewis, McComas, McGrew, McNeil, Macfarland, C. K. Mallory, J. B. Mallory, Marshall, Marr, Marye, Masters, Moffett, Moore, Nelson, Orrick, Osburn, Parks, Patrick, Pendleton, Porter, Preston, Price, Pugh, R. E. Scott, Wm. C. Scott, Sharp, Sheffey, Sitlington, Slaughter, Southall, Speed, Spurlock, Staples, A. H. H. Stuart, C. J. Stuart, Summers, Sutherlin, Tarr, Tayloe,
                Tredway, F. P. Turner, Tyler, Waller, Whitfield, Wickham, Willey, Wilson-91.
              </p>
              <p>So the amendment was rejected.</p>
              <p>The Committee then, at 2 o'clock, took a recess till 4 P.M.</p>
            </div>
            <div className="section" id="vsc1965.v3.2.3.3">
              <h3><span className="headingNumber">1.3.3. </span><span className="head">AFTERNOON SESSION</span></h3>
              <p>The Committee of the Whole resumed its session at 4 o'clock, P.M., the question being on the fourth resolution of the Committee on Federal Relations, as amended.</p>
              <p>The question was taken and the resolution, as amended, was adopted. The fifth resolution of the Committee was then read as follows :</p>
              <p>
                "5. The sites of the federal forts, arsenals, &amp;c., within the limits of the States of this Union, were acquired by the Federal Government, and jurisdiction over them ceded by the States, as trusts, for the common purposes of the Union during its continuance; and upon the separation of the States, such jurisdiction reverts of right to the States, respectively, by which the
                jurisdiction was ceded. Whilst a State remains in the Union, the legitimate use of such forts, &amp;c., is to protect the country against foreign force, and to aid in suppressing domestic insurrection. To use, or prepare them to be used to intimidate a State, or constrain its free action, is a perversion of the purposes for which they were obtained ; they were not intended to be used
                against the <PageNumber num={155} /> States, in whose limits they are found, in the event of civil war. In a time of profound peace with foreign nations, and when no symptoms of domestic insurrection appear but whilst irritating questions of the deepest importance are pending between the States to accumulate within the limits of a State, interested in
                such questions, an unusual amount of troops and munitions of war, not required for any legitimate purpose, is unwise, impolitic and offensive."
              </p>
              <div className="speaker" id="sp2053"><span className="persName">Mr. FLOURNOY</span>—</div>
              <p className="p-in-sp">
                I move to amend the resolution by striking out the words : "And to aid in suppressing domestic insurrection." In looking over the Act of Assembly by which the territory on which Fort Monroe and Old Point Comfort are located,<span className="note" id="Note15"><span className="noteLabel">4</span>Some words, perhaps "was ceded," are omitted here.</span> I find that Virginia, with her usual
                caution, and in the protection of her rights, gave this property to the Federal Government for no such purpose as to suppress insurrection. I will read the Act of Assembly:
              </p>
              <p className="p-in-sp">"Whereas, It is shown the present General Assembly, that the Government of the United States is solicitous that certain lands, at Old Point Comfort, and at the Shoal, called the Rip Raps, should be, with the right of property, and entire jurisdiction thereon, vested in the United States, for the purpose of fortifications, and other objects of national defence."</p>
              <p className="p-in-sp">The first section goes on to direct the Government to make the necessary conveyances. The second section is as follows :</p>
              <p className="p-in-sp">"And be it further enacted, That should the United States at any time abandon the said lands, or appropriate them to any other purposes than those indicated in the preamble of this act, then, and in that case, the same shall revert to, and revest in the Commonwealth."</p>
              <p className="p-in-sp">
                This refers to the preamble which I have read, and which says that the land is given solely for purposes of fortification and other objects of national defence. It may seem a little captious to move to strike out these words, but I really think it important to do so, especially when we look at the adversary with which we are dealing. I understand that the present head of the
                Government does not recognize the sovereignty of a State. If his public speeches, from Springfield to the seat of government, are to be relied upon as expressive of his opinions, he looks upon a State as he does upon a mere county.
              </p>
              <p className="p-in-sp">
                If we should, in the progress of events that are rapidly developing themselves, find it necessary that Virginia should take a position of resistance to the General Government, we might be looked upon as insurgents, as insurrectionists; and it would be a most mortifying position to put ourselves in, that the very government which was treating us as insurgents and insurrectionists
                should quote our own <PageNumber num={156} /> language to prove that the use of these forts was designed to suppress domestic insurrection, while the act which grants the land on which these forts are located, declares that the cession was made to the Government for no other purpose than for defence against foreign invasion. It is for this reason, Mr.
                Chairman, that I move to strike out these words, leaving the section to read "that the legitimate use of such forts &amp;c., is to protect the country against foreign force."
              </p>
              <div className="speaker" id="sp2054"><span className="persName">Mr. R. Y. CONRAD</span>—</div>
              <p className="p-in-sp">I would be glad to agree with the gentleman in his proposed amendment, if it was even a matter of indifference; but it is important, in my opinion, that these words should be retained. I would say, in the first place, that this resolution contains our own language, and that it is a matter of little moment to us how it may be construed by the Federal Government.</p>
              <p className="p-in-sp">
                It will be conceded that it is part of the duty of the Federal Government, under the Constitution, to interfere in case of domestic insurrection; and it is the right of the State authorities to call upon the Federal Government so to interfere. I think it is important not to discharge the Federal Government from this obligation. I will remind the gentleman of a case that occurred in
                an adjoining county to my own—the case of Harper's Ferry. Was it not right and proper—nay, the duty of the Federal Government, upon the demand of the civil authorities of Virginia, to interfere then to put down domestic insurrection?
              </p>
              <p className="p-in-sp">The apprehension of the gentleman from Halifax [<span className="persName">Mr. FLOURNOY</span>]
                , seems to be, sir, that by some construction or other that action of the State of Virginia, through its constituted authorities, may be construed to be domestic insurrection. That conclusion is excluded by the terms of this resolution. If the gentleman will look a little further into this resolution he will find that in the sixteenth line there is an affirmation to the contrary—"and
                when no symptoms of domestic insurrection appear." That domestic insurrection, sir, as I am led to think, means, and can only mean, an opposition to State authority; and, according to the Constitution of the United States, no interposition of Federal authority can take place except upon the demand of the State authority, so that it is impossible, in any way that this can be construed
                or understood, to import any right to interfere in case of State action, with the concurrence of the State authority.
              </p>
              <p className="p-in-sp">
                Now, sir, I submit to the gentleman over the way, whether striking these words out, would not simply have the effect, so far as we can discharge, of discharging the Federal Government from this Constitutional obligation, upon our demand, to interfere in case of <PageNumber num={157} /> domestic insurrection, and not only must it apply in the case of
                Harper's Ferry, but it must even apply to the very points instanced by the gentleman himself, Fort Monroe. Domestic insurrection might threaten the peace of Virginia there, and it may be necessary that the Virginia authorities should call upon the Federal authorities, to interpose their military arm there, in order to suppress insurrection against the authorities of the State of
                Virginia. I think, then, that the apprehension of my friend is unfounded ; that there is no possibility that a case can arise in this connection, which would be misapprehended to our injury; but a case might easily arise when it would be important and right for us to make a demand upon the Federal authorities for their interposition.
              </p>
              <p className="p-in-sp">I trust that the amendment will not be adopted.</p>
              <div className="speaker" id="sp2055"><span className="persName">Mr. FLOURNOY</span>—</div>
              <p className="p-in-sp">I have submitted this amendment in no captious spirit and for no purpose to occupy, unnecessarily, the time of the Committee; and if I shall offer any other amendment, I shall do so in no other spirit than to place these resolutions in such a shape as I think they ought to be put in order to be adopted.</p>
              <p className="p-in-sp">
                I listened to the remarks of my friend with much attention, but I cannot admit the force of his argument in reply to the objections which I made to that portion of the resolution which I propose to strike out. A provision is made under the Constitution by which insurrection is to be suppressed, and it is to be done by application to the General Government for the use of the military
                power of the country and of the militia to suppress it; and, if this clause is stricken out, it will not impair, in the least degree, the right to make application to the General Government, in case of necessity, for the use of the military power of the Government to aid us in the suppression of insurrection.
              </p>
              <p className="p-in-sp">
                But this declares, not that it is the duty of the General Government to aid a State to suppress insurrection when that State shall call upon it for aid, but declares that the forts were established for that purpose ; when the act of Virginia, granting the territory upon which the forts are located declares, in plain terms, that it was granted solely for purposes of national defence.
                Mark the distinction between the purpose for which the forts were created and the duty of the Federal Government to suppress insurrection, when the power of the State is insufficient to suppress that insurrection. If it becomes necessary to call upon the General Government for aid to suppress insurrection, does it impair the duty of the General Government to respond to the call of
                Virginia? It does not in the least degree, in my judgment, <PageNumber num={158} /> lessen the obligation of the General Government to render the aid necessary to suppress any insurrection which may arise, and which the State feels unable to suppress. Why, sir, the militia of the country, the military power of the country are left as subject to the
                control and direction of the General Government, as perfectly, for all the purposes of aiding the State, as they can possibly be under the Constitution, after this clause is stricken out; for this has no reference to the military power of the Government as a military power; it has no reference to the duty of the Government in regard to the militia; but alone to the forts that are
                established, and to the purpose of their establishment in the States in which they exist. How can we ascertain better the purposes for which these forts are established, than by looking at the act of the State which ceded the territory, and which declares the purposes for which they were established? And when you look at that, you will find it declares, that it was ceded solely for
                purposes of national defence, and when diverted or applied to any other purpose, that the territory retrocedes to the State; so that the very moment, under a strict construction of the language of the act granting this territory, at the very moment that these forts are used for any other purpose than national defence, they belong again to the State of Virginia, and are forfeited by
                the General Government.
              </p>
              <p className="p-in-sp">Sir, a copy of the "Force Bill," is just handed to me, in which I find this language :</p>
              <p className="p-in-sp">
                "Be it enacted by the Senate and House of Representatives of the United States of America in Congress assembled, That the provisions of an act approved the 28th day of February, in the year one thousand seven hundred and ninety-five, entitled 'an act to provide for calling forth the militia to execute the laws of the Union, suppress insurrection, and repel invasions, and to repeal
                the act now in force for these purposes ;' and of the act approved the third day of March in the year one thousand eight hundred and seven, entitled 'an act authorising the employment of the land and naval forces of the United States in cases of insurrection,' are hereby extended to the case of insurrections against the authority of the United States."
              </p>
              <p className="p-in-sp">Now, sir, suppose that we resist—suppose Virginia makes resistance</p>
              <p className="p-in-sp">
                for I do not understand that the Federal Government concedes the right of a State to secede or resume her delegated powers—suppose Virginia shall make resistance, is she not then, in the estimation of this Government, an insurgent against the authority of the United States? And the very forts established here by her authority, solely for the purpose of national defence, become the
                common arm of power of the <PageNumber num={159} /> General Government, to suppress insurrection against that Govern ment which may be committed by the State of Virginia.
              </p>
              <p className="p-in-sp">
                But if I am not right in that, it is humiliating to Virginia—mortify ing to her pride; it is lowering her proud crest for her to assert that she ceded to the General Government, and procured the establishment of forts within her borders to protect her against a servile insurrection that may occur within her borders. I regard the amendment as an important one. I will not further
                occupy the time of the Committee in discussing it, but will leave it with the Committee to act upon it as in their judgment they shall deem best.
              </p>
              <p>The question being on the amendment offered by <span className="persName">Mr. FLOURNOY</span>,</p>
              <p><span className="persName">Mr. GOODE</span>, of Bedford called for the yeas and nays upon its adoption.</p>
              <p>
                The call was sustained, and the question being put resulted, yeas 68, nays 63, as follows :<span className="note" id="Note16"><span className="noteLabel">5</span>The Journal refers to and lists only 61 negative votes.</span>
              </p>
              <p>
                YEAS--Messrs. Ambler, Aston, James Barbour, Blakey, Boisseau, Borst, Bouldin, Boyd, .Branch, Bruce, Cecil, Chapman, Coffman, Conn, R. H. Cox, Echols, Fisher, Flournoy, French, Fugate, Garland, Gillespie, Graham, Gravely, John Goode, Hale, Addison Hall, Cyrus Hall, L. S. Hall, Harvie, Holcombe, Hunton, Isbell, Peter C. Johnston, Kent, Kilby, Kindred, Lawson, Leake, J. B. Mallory,
                Marr, Marye, Montague, Morris, Morton, Moore, Neblett, Parks, Randolph, Richardson, Seawell, Sheffey, Slaughter, Southall, Speed, Strange, Sutherlin, Tredway, R. H. Turner, F. P. Turner, Tyler, Waller, Whitfield, Williams, Wilson, Wise, Woods, Wysor-68.
              </p>
              <p>NAYS-Messrs. John Janney [President]
                , Armstrong, A. M. Barbour, Baylor, Boggess, Brent, Brown, Burdett, Burley, Byrne, Campbell, Caperton, Carlile, Carter, C. B. Conrad, Robert Y. Conrad, Couch, Critcher, Custis, Deskins, Dorman, Early, Gray, Goggin, E. B. Hall, Hammond, Haymond, Hoge, Hubbard, Hughes, Jackson, Marmaduke Johnson, Lewis, McComas, McGrew, McNeil, Macfarland, Marshall, Masters, Moffett, Nelson, Orrick,
                Osburn, Patrick, Pendleton, Porter, Preston, Price, Pugh, R. E. Scott, Sharp, Sitlington, Spur-lock, Staples, A. H. H. Stuart, C. J. Stuart, Summers, Tarr, Tayloe, Wickham, Willey-63.
              </p>
              <p>So the amendment was agreed to.</p>
              <div className="speaker" id="sp2056"><span className="persName">Mr. FLOURNOY</span>—</div>
              <p className="p-in-sp">The amendment just adopted makes it necessary to strike out also the 16th line which reads :</p>
              <p className="p-in-sp">"And when no symptoms of domestic insurrection appear."</p>
              <div className="speaker" id="sp2057"><span className="persName">Mr. BOISSEAU</span>—</div>
              <p className="p-in-sp">I would suggest to the gentleman that it will read better by striking out all after the word "and," and to substitute the word "and" for the word "but."</p>
              <p className="p-in-sp">To render these amendments intelligible, we annex the section :</p>
              <p className="p-in-sp">
                "The sites of the federal forts, arsenals, &amp;c., within the limits of the States of this Union, were acquired by the Federal Government, and <PageNumber num={160} /> jurisdiction over them ceded by the States, as trusts, for the common purposes of the Union, during its continuance; and upon the separation of the States, such jurisdiction reverts of
                right to the States, respectively, by which the jurisdiction was ceded. Whilst a State remains in the Union, the legitimate use of such forts, &amp;c., is to protect the country against foreign force, and to aid in suppressing domestic insurrection. To use, or prepare them to be used to intimidate a State, or constrain its free action, is a perversion of the purposes for which they
                were obtained; they were not intended to be used against the States, in whose limits they are found, in the event of civil war. In a time of profound peace with foreign nations—and when no symptoms of domestic insurrection appear—but whilst irritating questions of the deepest importance are pending between the States—to accumulate within the limits of a State, interested in such
                questions, an unusual amount of troops and munitions of war, not required for any legitimate purpose, is unwise, impolitic and offensive."
              </p>
              <div className="speaker" id="sp2058"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I hope the gentleman from Halifax [<span className="persName">Mr. FLOURNOY</span>] will withdraw that amendment. I think his last motion is not consistent with his first. I hope he will withdraw it.</p>
              <div className="speaker" id="sp2059"><span className="persName">Mr. FLOURNOY</span>—</div>
              <p className="p-in-sp">It occurs to me that I am right; but, as there is a different opinion about it; if it affects the force of the amendment, I will not insist upon it. I withdraw it, sir.</p>
              <div className="speaker" id="sp2060"><span className="persName">Mr. SPEED</span>—</div>
              <p className="p-in-sp">I propose to amend this section in the 20th line by striking out the words "unwise, impolitic and," and adding to the section after the closing word "offensive" these words, "and ought not to be tolerated by a free people."</p>
              <p className="p-in-sp">
                It seems to me when the Committee read the 8th section, which, if adopted, would pledge the people of this Commonwealth to resist every attempt on the part of the General Government to exert its power for the purpose of subjugating such States as may choose to exercise its sovereignty by seceding from the Union, I say, sir, it seems to me, in view of the language of that section,
                that these words, in the 5th section, are not so expressive as they ought to be. We should, in my opinion, substitute for them a declaration, that if such an attempt is made that it ought to be resisted by a free people.
              </p>
              <p className="p-in-sp">I presume the point of the amendment is fully understood and I do not, therefore, deem it necessary to elaborate it.</p>
              <p><span className="persName">Mr. SPEED</span>, after some conversation, modified his amendment as follows : to strike out the words, "is unwise, impolitic and offensive," and to insert the words, "is offensive and ought not to be tolerated by a free people."</p>
              <PageNumber num={161} />
              <div className="speaker" id="sp2061"><span className="persName">Mr. DORMAN</span>—</div>
              <p className="p-in-sp">Have I a right to call for a division of the question?</p>
              <div className="speaker" id="sp2062"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The question is perfectly divisible.</p>
              <div className="speaker" id="sp2063"><span className="persName">Mr. DORMAN</span>—</div>
              <p className="p-in-sp">Then I call for a division of the question.</p>
              <div className="speaker" id="sp2064"><span className="persName">Mr. SPEED</span>—</div>
              <p className="p-in-sp">My object is to save time, and if it will accommodate the gentleman over the way, as it will equally answer all the ends I have in view, with the permission of the Committee, I will modify my amendment by proposing to add, at the end of the section, the following words : "And ought not to be tolerated by a free people."</p>
              <p><span className="persName">Mr. MONTAGUE</span> demanded the yeas and nays, which were ordered.</p>
              <p>
                The question was then taken and it was decided in the negative—yeas 58, nays 80, as follows :<span className="note" id="Note17"><span className="noteLabel">6</span>The Journal lists 53 affirmative votes, which is the actual count of affirmative votes in the Proceedings. The Journal has Carter among the 80 negative votes it lists.</span>
              </p>
              <p>
                AYES-Messrs. Ambler, Barbour, Blakey, Boissseau, Borst, Bouldin, Branch, Cecil, Chapman, Coffman, Conn, Cox, Critcher, Echols, Fisher, Graham, J. Goode, Hale, Addison Hall, Cyrus Hall, L. S. Hall, Harvie, Holcombe, Hunton, Isbell, Johnson, Kent, Kindred, Lawson, Leake, Macfarland, Mallory, Marr, Marye, Sr., Montague, Morris, Morton, Neblett, Randolph, Richardson, Wm. C. Scott,
                Seawell, Sheffey, Speed, Strange, Robert H. Turner, Franklin P. Turner, Tyler, Williams, Wilson, Wise, Woods, Wysor-58.
              </p>
              <p>NOES-Messrs. Janney [President]
                , Armstrong, Aston, A. M. Barbour, Baylor, Berlin, Blow, Boggess, Boyd, Brent, Brown, Bruce, Burdett, Burley, Byrne, Campbell, Caperton, Carlile, C. B. Conrad, R. Y. Conrad, Couch, Custis, Dent, Deskins, Dorman, Early, Flournoy, French, Fugate, Garland, Gillespie, Gravely, Gray, Goggin, Ephraim B. Hall, Hammond, Haymond, Hoge, Hubbard, Hughes, Jackson, P. C. Johnston, Kilby, Lewis,
                McComas, McGrew, Marshall, Masters, Moffett, Moore, Nelson, Orrick, Osburn, Parks, Patrick, Pendleton, Porter, Preston, Price, Pugh, R. E. Scott, Sharp, Sitlington, Slaughter, Southall, Spur-lock, Staples, A. H. H. Stuart, C. J. Stuart, Summers, Sutherlin, Tarr, Tayloe, Tredway, Waller, White, Whitfield, Wickham, Willey-80.
              </p>
              <div className="speaker" id="sp2065"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The question now recurs on the resolution as amended.</p>
              <div className="speaker" id="sp2066"><span className="persName">Mr. BROWN</span>—</div>
              <p className="p-in-sp">I desire to offer the following amendment, and I wish to call the attention of the Chairman of the Committee to the amendment that I propose. It is a matter of taste as much as anything else.</p>
              <p className="p-in-sp">The resolution now reads :</p>
              <p className="p-in-sp">"The sites of the Federal forts, arsenals, &amp;c. within the limits of the States of this Union, were acquired by the Federal Government and jurisdiction over them ceded by the States, as trusts, for the common purposes of the Union during its continuance; and upon the separation of the States."</p>
              <p className="p-in-sp">I propose to strike out "during its continuance and upon the separa tion of the States," and to insert in lieu thereof the following:</p>
              <p className="p-in-sp">"And upon the Federal Government ceasing to execute the trust." So that the resolution as I propose to amend it would read,</p>
              <PageNumber num={162} />
              <p className="p-in-sp">
                "The sites of the Federal forts, arsenals, &amp;c. within the limits of the States of this Union, were acquired by the Federal Government, and jurisdiction over them ceded by the States as trusts for the common purposes of the Union, and upon the Federal Government ceasing to execute the trust, such jurisdiction reverts of right to the States respectively, by which the jurisdiction
                was ceded."
              </p>
              <p className="p-in-sp">The section speaks of a trust being created and I conceive, whenever the General Government ceases to execute the trust, from any cause. that it reverts as a matter of right to the States.</p>
              <div className="speaker" id="sp2067"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I would inquire of the mover of this amendment whether he stated that he offered it as a matter of taste.</p>
              <div className="speaker" id="sp2068"><span className="persName">Mr. BROWN</span>—</div>
              <p className="p-in-sp">
                I called the attention of the Chairman to it and I stated that it might probably to some extent be a matter of taste. The General Government may cease to execute the trust for more causes than are assigned in the original resolution. The amendment I offer covers the whole ground, and provides that whenever the Federal Government from any cause ceases to execute the trust, either by
                being interfered with, or by the separation of States or from any other cause, their jurisdiction reverts as a matter of right to the States.
              </p>
              <div className="speaker" id="sp2069"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                I oppose that amendment with all my might. It has something more than mere taste in it; there is something more than the gentleman named in it. As the report stands, I agree with the proposition of the committee, which commends itself to me upon the ground that it does acknowledge the act of separation of the States, and does admit that, under such separation, the forts do revert to
                the States by virtue of their own act of separation. The gentleman's proposition is meant to deny that, and it smacks to me of war. It is. in fact, an avowal of a proposition that the Federal Government has the right, after separation, to insist upon the occupation of the forts.
              </p>
              <div className="speaker" id="sp2070"><span className="persName">Mr. BROWN</span>—</div>
              <p className="p-in-sp">
                I had no idea of getting into a war. We have been hearing of war and bloodshed ever since we assembled here. I trust we will cease hearing of these bloody conflicts. I simply designed, by my amendment, neither to affirm nor deny the right of secession, or the right of jurisdiction reverting upon the secession of a State; but I intended simply to declare, that whenever the Federal
                Government, from any cause, ceased to perform its part and execute the trust confided to it, the State ought of right to assume jurisdiction over the subject.
              </p>
              <p>The question was taken on <span className="persName">Mr. BROWN</span>'S amendment, and it was rejected.</p>
              <PageNumber num={163} />
              <div className="speaker" id="sp2071"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">Do I understand from the Chair that it would be in order to move to strike out the whole section?</p>
              <div className="speaker" id="sp2072"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">It would be in order.</p>
              <div className="speaker" id="sp2073"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">I propose to strike out the whole section, and I would like to have my name recorded in favor of striking it out, and therefore I will ask for the yeas and nays.</p>
              <p>The yeas and nays were ordered.</p>
              <p>
                The question was then taken and the Committee refused to strike out the section—yeas 14, nays 115 as follows : <span className="note" id="Note18"><span className="noteLabel">7</span>The Journal lists 114 negative votes, adding the names of Custis and Fugate to those given in the Proceedings.</span>
              </p>
              <p>YEAS-Messrs. Brown, Burdett, Burley, Carlile, Dent, Early, Hubbard, Hughes, Jackson, Lewis, McGrew, Patrick, Porter, Tarr-14.</p>
              <p>NAYS-Messrs. Janney [President]
                , Ambler, Armstrong, Aston, A. M. Barbour, J. Barbour, Baylor, Blakey, Blow, Jr., Boggess, Boisseau, Borst, Bouldin, Boyd, Branch, Brent, Bruce, Byrne, Campbell, Caperton, Cecil, Chapman, Coffman, Conn, C. B. Conrad, R. Y. Conrad, Couch, R. H. Cox, Critcher, Deskins, Dorman, Echols, Fisher, Flournoy, French, Garland, Gillespie, Graham, Gravely, Gray, Goggin, J. Goode, Jr., Hale, A.
                Hall, C. Hall, L. S. Hall, Hammond, Harvie, Haymond, Hoge, Holcombe, Hull, Hunton, Isbell, M. Johnson, P. C. Johnston, Kent, Kilby, Kindred, Lawson, Leake, McComas, McNeil, Macfarland, J. B. Mallory, Marshall, Marr, Marye, Sr., Masters, Moffett, Montague, Morris, Morton, Moore, Neblett, Nelson, Orrick, Osburn, Parks, Preston, Price, Pugh, Randolph, Richardson, R. E. Scott, W. C.
                Scott, Seawell, Sheffey, Sitlington, Slaughter, Southall, Speed, Spurlock, Staples, A. H. H. Stuart, C. J. Stuart, Summers, Sutherlin, Tayloe, Tredway, R. H. Turner, F. P. Turner, Tyler, Waller, Whitfield, Wickham, Willey, Williams, Wilson, Wise, Woods, Wysor-1 1 5.
              </p>
              <div className="speaker" id="sp2074"><span className="persName">Mr. WILSON</span>—</div>
              <p className="p-in-sp">I now move that the Committee rise. It is very desirable that we should take a vote in the Convention.</p>
              <p>The question was taken and the motion was not agreed to. The 6th section was then reported as follows :</p>
              <p>
                "Deeply deploring the present distracted condition of the country, and lamenting the wrongs that have impelled some of the States to cast off obedience to the Federal Government, but sensible of the blessings of the Union, and impressed with its importance to the peace, prosperity and progress of the people, we indulge the hope, that an adjustment may be reached by which the Union
                may be preserved in its integrity, and peace, prosperity and fraternal feelings be restored throughout the land."
              </p>
              <div className="speaker" id="sp2075"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">I understand that it is in order to move a substitute for that whole section. As I am thoroughly opposed to every enunciation in it, except the one contained in the first line, I propose to offer the following substitute for the whole of it :</p>
              <p className="p-in-sp">
                "Resolved, That an Ordinance of Secession, resuming the powers <PageNumber num={164} /> delegated by Virginia to the Federal Government, and providing for submitting the same to the qualified voters of the Commonwealth, for their adoption or rejection at the polls in the spring election in May next, should be adopted by this Convention."
              </p>
              <p className="p-in-sp">Mr. Chairman, there is but one provision in this sixth resolution that I approve. I do deeply deplore the present distracted condition of the country, and after saying that much, I utterly dissent from every solitary provision in this section. The section goes on to say :</p>
              <p className="p-in-sp">"Deeply deploring the present distracted condition of the country, and lamenting the wrongs that have impelled some of the States to cast off obedience to the Federal Government."</p>
              <p className="p-in-sp">
                Now, Mr. Chairman, I utterly dissent from the opinion expressed there, and the declaration made by this Committee, that the States are bound to obey the Federal Government. The Federal Government, in my opinion, is bound to obey the States. The States are parties to the compact that made the Federal Government their agent, and the States have the right to control the Federal
                Government. I utterly dissent from the doctrine, that under any circumstances, at any time, the Federal Government had the right to enforce obedience upon the States that created it. There is the great mistake, in my opinion, that this Committee of twenty-one have made. I am one of those who believe that the States have the right, in their judgment, if they choose, to put an end to
                this government. I do believe that this government is now ended; that it is not a government to which seven States owe obedience now, and that they did not owe obedience to it before they withdrew from it.
              </p>
              <p className="p-in-sp">So much for that point. I have only ten minutes, a very limited time, in which to explain an amendment, much less to argue the great question of secession.</p>
              <p className="p-in-sp">
                Another thing I wish to say here. I utterly dissent from the position which the Committee take here, that we are sensible of the blessings of this Union, for, if they were blessings which I was sensible I had derived from this Union, I would not now occupy the position that I do; nor would those States which have withdrawn from this Union, if they also were sensible of the blessings
                of the Union. It is because this Union does not extend blessings to those States, that they have withdrawn from the Union, and it is because I do not think that the Union extends blessings to the State of Virginia, that I am for following their example. Nor do I believe that the committee of twenty-one is right when they say, "but sensible of the blessings of the Union, and impressed
                with its importance to the peace, prosperity and progress <PageNumber num={165} /> of the people," &amp;c. I believe the case is exactly the reverse. I do believe that the prosperity and progress of the people have been curtailed by the operations of this Government, and I do not believe, if we remain in this Government, that the peace of the country
                would be promoted by it. I think that the only way to preserve the peace of the country is for Virginia to get out of the Union.
              </p>
              <p className="p-in-sp">There is another position in this resolution that I dissent from just as strongly. I indulge no "hope that an adjustment may be reached by which the Union may be preserved in its integrity." I don't believe that the Southern States will ever come back into this Union under any proposition which you can get the North to adopt. I do not think they ought.</p>
              <p className="p-in-sp">Mr. Chairman, I have taken this, the first opportunity I could get to offer these propositions of mine, because I expected they would be voted down when the vote should take place. Is my time up, sir?</p>
              <div className="speaker" id="sp2076"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">Yes, sir.</p>
              <div className="speaker" id="sp2077"><span className="persName">Mr. GOGGIN</span>, of Bedford—</div>
              <p className="p-in-sp">Mr. Chairman, I propose to amend that amendment, if it is in order, by striking out all after the word "Resolved," and inserting the following—</p>
              <div className="speaker" id="sp2078"><span className="persName">Mr. SHEFFEY</span> of Smyth—</div>
              <p className="p-in-sp">Mr. Chairman, this being an important matter in the deliberations of this body, and the point of all our labors, I move that the Committee do now rise.</p>
              <p>The motion was put and the Committee refused to rise.</p>
              <p><span className="persName">Mr. GOGGIN</span>'S amendment was then read, as follows :</p>
            </div>
            <div className="section" id="vsc1965.v3.2.3.4">
              <h3><span className="headingNumber">1.3.4. </span><span className="head">AN ORDINANCE OF THE STATE OF VIRGINIA</span></h3>
              <p>
                "Whereas, the State of Virginia has made every honorable effort to restore the friendly relations which should exist between the General Government and the several States of the Union upon terms perfectly just to all, but deeming it unnecessary to refer to the causes of complaint which have existed for a series of years, still more aggravated as those causes now are by the declared
                purposes of a mere sectional majority—and as all the efforts so made have proved unavailing without reciting the differences of opinion which exist in regard to the powers of the State Government, or those of the Government of the United States, as derived from the reserved rights of the one, the constitutional authority of the other, or the inherent rights of the people,
                constituting a Government which seeks to protect the persons and property of those who compose and who have ordained and established it, against the abuses of such Government itself, or which arise from its connection with the Government of other States, or that <PageNumber num={166} /> of an association of States, the people of Virginia, in Convention
                assembled, deem it proper now to declare that the time has arrived when it becomes them to assume, as they do, their position as the people of a sovereign independent State.
              </p>
              <p>1. Be it, therefore, ordained by the people of Virginia, and they do hereby declare, That the said State is no longer one of the Union of States known as the United States of America, and that the people of the said State owe no allegiance or duty to any Government what- soever.</p>
              <p>
                2. Be it further ordained, and it is hereby declared, That the people of the said State do resume all the rights of property, or the use thereof, which have been granted by the said State to the Government of the United States, or which have in anywise accrued to the same by reason of the connection of the said State with the said Government, by the assent of the said State, given
                with a view to the protection of her own people, and the people of all the other States, composing a Union formed under an agreement that it should establish justice—insure domestic tranquility—provide for the common defence—promote the general welfare, and secure the blessings of liberty to themselves and their posterity—that agreement having been violated, and the objects of the
                Union perverted so as to defeat the purposes of justice—to destroy the very foundations of domestic tranquility—to lessen the means of common defence—so as to disregard the objects of the general welfare of one entire section of the Union, and thereby to entail, in that section, injury and oppression upon the people thereof, and for their posterity forever.
              </p>
              <p>
                3. And without determining, at this time, whether the State of Virginia will unite herself with any other State or association of States, in any common government, this Convention doth respectfully and earnestly request that the States of North Carolina, Tennessee, Kentucky, Maryland, Missouri, Arkansas and Delaware will, as soon as possible, appoint commissioners to meet
                commissioners to be appointed by the people of this State, in the city of Lexington, in the State of Kentucky, on the last Wednesday in May next, to confer together, and to propose a plan of constructing a Government to be formed by the said States, Virginia inclusive, and the Confederate States of America. Such plan of Government, however, to have no binding authority till the same
                shall be adopted and ratified by this Convention and by the people. And for the better accomplishment of the objects intended, the said Confederate States of America are also respectfully requested to send three commissioners to the Conference <PageNumber num={167} /> herein proposed, who shall be invited, at such time as may be agreeable to them, to
                address the same. That each of the States herein-before named, shall be entitled to as many votes as it had Representatives and Senators in the last Congress, in the Conference herein proposed. That the Commissioners to be appointed by the people of this State shall make report to the Governor of this Commonwealth, as speedily as possible, of the result of their
                deliberations—whereupon he shall make known the same by proclamation. That on the 15th day after the date of such proclamation (unless the same be Sunday, then on the next day) , this Convention shall re-assemble in the city of Richmond, at such place as the Governor shall designate in said proclamation, and shall then and there consider the report of the said commissioners, and all
                other matters which at this time are, or may then be, proper subjects for deliberation, touching the future relations of the State of Virginia to any other Government or State, and for the submission of the action of the Convention to the people.
              </p>
              <p>
                4. And it is hereby ordained and declared by the people of Virginia, That they do recognize and acknowledge the independence and nationality of the said Confederate States of America; and that they will extend to the said States any aid which they, the said people of Virginia can command, or which may be necessary to enable the said States to maintain their independence, or against
                any coercive measures which may be adopted by the authorities of the. United States.
              </p>
              <p>
                5. Be it further declared, That the people of Virginia have ever cherished an ardent attachment for the Union and the Constitution of the United States while it was the bond of peace and fraternity; and that it can now only be restored upon the original basis by an amendment of the Constitution through the primary agency of the nonslaveholding States themselves proposing suitable and
                sure guarantees, and by a full and unconditional, plain and positive recognition of the rights of property in slaves, as held under the laws of any of the States; so as also to obtain satisfactory assurances and guarantees, for the future, as to slavery in the District of Columbia; as to the powers of the Federal Government over African slavery, and the employment of slave labor in
                the forts, arsenals, dock yards, and all places ceded by the States for Federal uses; as to protection against excessive direct taxes on slaves; as to the rendition of fugitive slaves; as to the transit with slaves through any of the States by land or water, and of the right of transportation on the high seas of slaves from one State to another State or Territory; as to the
                protection of slave property in the <PageNumber num={168} /> common territories of the United States; as to the better security of the independence of the judiciary, and for protection against unjust taxation in the form of excessive imposts laid upon foreign importations.
              </p>
              <p>
                6. Be it further declared, That the people of Virginia, though they have taken their position, have an anxious desire to preserve peace, and would, therefore, regard any action of the Federal Government tending to produce a collision, pending negotiations for the adjustment of existing difficulties, as aggressive and injurious to the interests and offensive to the honor of this State
                ; and they would regard any action on the part of the Confederate States of America, tending to produce a like collision, as hurtful and unfriendly; and as leaving the people of Virginia free to determine their future policy.
              </p>
              <p>
                7. Be it further declared, That the President of this Convention shall immediately cause copies of this ordinance to be forwarded to the Governors of each of the United States, as the same existed on the first day of December, 1860, to the President of the Confederate States of America, and to the President of the United States. And it is further ordained, that this Convention will
                proceed at once to provide at once for the election, by the people of this State, of fifteen commissioners to attend on behalf of this State, the Conference herein proposed to be holden ; and it is provided, also, that if commissioners shall fail to attend the said Conference, from the other States named in the third clause of this ordinance, so that the said Conference shall not be
                holden ; then the said commissioners from this State shall, in like manner, report the fact to the Governor, who shall make proclamation thereof, when also this Convention shall re-assemble at the time herein provided for.
              </p>
              <p>
                8. But this Convention, anxious as it is to take no step to disturb existing relations, only so far as is necessary, but seeking to avoid any collision, doth suspend the operation of the second clause of this ordinance till such time as it may deem proper to enforce the same, and with a view to an adjustment of the pending difficulties, through the agency of the Conference herein
                provided for, and by a returning sense of justice among the people of all sections. And it is provided and hereby declared, that the people of North Carolina and Tennessee shall have the right of transit through the State of Virginia, to and from the city of Washington, till such time as final action may be had by the State of Virginia upon the report of her commissioners under the
                third clause of this ordinance.
              </p>
              <PageNumber num={169} />
              <div className="speaker" id="sp2079"><span className="persName">Mr. HARVIE</span>, of Amelia—</div>
              <p className="p-in-sp">I desire to know whether it is in order to move a substitute to a substitute?</p>
              <div className="speaker" id="sp2080"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">Certainly, sir, it is perfectly in order. This is a substitute for one section of the entire report.</p>
              <div className="speaker" id="sp2081"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Mr. Chairman, we cannot hear one word, there is such confusion in the hall. I have tried to hear the question but have failed. I have not heard the proposition of the gentleman from Amelia [<span className="persName">Mr. HARVIE</span>] . I don't know in what light this is offered—whether it is a substitute for the whole report or only for the amendment. We have such great disorder in this part of the hall that I cannot hear.</p>
              <div className="speaker" id="sp2082"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Chair has almost exhausted itself in endeavoring to preserve the order of the Committee.</p>
              <div className="speaker" id="sp2083"><span className="persName">Mr. GOGGIN</span>—</div>
              <p className="p-in-sp">Does the Chair decide that my amendment is in order?</p>
              <div className="speaker" id="sp2084"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">Yes, sir, it is in order.</p>
              <div className="speaker" id="sp2085"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">How is it in order? I have made that enquiry before.</p>
              <div className="speaker" id="sp2086"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The sixth section of the report of the Committee of twenty-one has been reported to this Committee of the Whole; the gentleman from Amelia [<span className="persName">Mr. HARVIE</span>] offers an amendment, and the gentleman from Bedford [<span className="persName">Mr. GOGGIN</span>] offers a substitute for the gentleman's amendment.</p>
              <div className="speaker" id="sp2087"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">It is offered, then, as a substitute for the gentleman's amendment?</p>
              <div className="speaker" id="sp2088"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">Yes, sir.</p>
              <div className="speaker" id="sp2089"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">And if the gentleman's amendment is rejected, then the question will be upon the substitute as competing with the sixth section of the report of the Committee?</p>
              <div className="speaker" id="sp2090"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">Precisely, sir.</p>
              <div className="speaker" id="sp2091"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                Now, sir, I hope the gentleman from Bedford will withdraw his substitute. It is in the form of an ordinance to amend one of the resolutions of a series of resolutions that are proposed as resolutions, some of which are adopted as resolutions. When you come to the ordinance in the second report of the Committee, I presume that this amendment will be in order. There is much in the
                proposition I would like to sustain, and yet as a whole, as it is proposed and as it stands, I cannot vote for it. It is not germane to the subject matter.
              </p>
              <div className="speaker" id="sp2092"><span className="persName">Mr. GOGGIN</span>—</div>
              <p className="p-in-sp">I would ask the Chair to have the amendment offered by the gentleman from Amelia reported.</p>
              <p>The Clerk again reported <span className="persName">Mr. HARVIE</span>'S amendment.</p>
              <div className="speaker" id="sp2093"><span className="persName">Mr. GOGGIN</span>—</div>
              <p className="p-in-sp">
                Not having heard, and not understanding the purport of the resolution offered by the gentleman from Amelia, or the effect <PageNumber num={170} /> it would have, or object sought to be attained by it, I offered the amendment which I have offered. But, now, understanding more fully what it is, I withdraw my amendment to that amendment, with the distinct
                understanding, however, that I do so with the purpose of still introducing it at another time.
              </p>
              <div className="speaker" id="sp2094"><span className="persName">Mr. MOORE</span>, of Rockbridge—</div>
              <p className="p-in-sp">I object to the gentleman's withdrawing it; I want to vote it down.</p>
              <div className="speaker" id="sp2095"><span className="persName">Mr. AMBLER</span>, of Louisa—</div>
              <p className="p-in-sp">Can the question of order be now raised? It is in reference to the reception of the substitute offered by the gentleman from Bedford. As I understand it, unless the Chair has decided that the substitute offered by the gentleman from Bedford is in order, the objection cannot be made.</p>
              <div className="speaker" id="sp2096"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Chair decides that it is in order.</p>
              <div className="speaker" id="sp2097"><span className="persName">Mr. AMBLER</span>—</div>
              <p className="p-in-sp">I was in hopes the Chair would not decide it in order, for good reasons which I think the Chair will see at once. I think the proposition is entirely out of order.</p>
              <div className="speaker" id="sp2098"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I move that the gentleman from Bedford have leave to withdraw his proposition.</p>
              <div className="speaker" id="sp2099"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">That is the question which the Chair was about to put.</p>
              <p>The question was then put, and <span className="persName">Mr. GOGGIN</span> was permitted to withdraw his amendment for the present.</p>
              <div className="speaker" id="sp2100"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The question recurs on the amendment offered by the gentleman from Amelia [<span className="persName">Mr. HARVIE</span>] , to the 6th resolution.</p>
              <div className="speaker" id="sp2101"><span className="persName">Mr. TURNER</span>, of Jackson—</div>
              <p className="p-in-sp">As it is about the usual hour for the Committee to rise, I move that the Committee rise.</p>
              <p>The motion was put, and the Committee refused to rise.</p>
              <div className="speaker" id="sp2102"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I hope the resolution will be reported now.</p>
              <div className="speaker" id="sp2103"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Chair will state the question as soon as the Committee shall come to order.</p>
              <p>The Clerk will again proceed to report the sixth resolution, if desired by any member ; if not desired, the Clerk will proceed to report the proposed substitute.</p>
              <p>The Clerk then read again <span className="persName">Mr. HARVIE</span>'S amendment.</p>
              <div className="speaker" id="sp2104"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The question will now be upon striking out and inserting.</p>
              <div className="speaker" id="sp2105"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Is it the committee of twenty-one that is to bring in an ordinance?</p>
              <div className="speaker" id="sp2106"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">It will be reported again, and the gentleman can put his own construction upon it.</p>
              <p>The Clerk then read again the proposed amendment offered by <PageNumber num={171} />, of Amelia, to the sixth section of the report of the Committee on Federal Relations, as follows :</p>
              <p>"Resolved, That an ordinance resuming the powers delegated by Virginia to the Federal Government, and provision for submitting the same to the qualified voters of the Commonwealth for adoption or rejection at the polls in the Spring elections in May next, should be adopted by the Convention."</p>
              <div className="speaker" id="sp2107"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                Mr. Chairman, as I have proclaimed again and again, I proclaim now, that I am opposed to the immediate secession of this State for secession's sake. But, sir, I believe that there is one form of secession which may be proposed, that is the only thing upon earth, according the best of my judgment, which can accomplish the end which I aim at above any end in the world, of restoring the
                unity, the peace, the brotherhood and harmony of this great nation, of bringing back to Union the States remaining in the Northern and the States that have seceded in the Southern Confederacy. With the view of bringing in such an ordinance, and with this understanding of the vote, regarding it as a substitute looking to a better ordinance which I believe can be prepared, and which,
                at the proper time, I will submit to this body for what it is worth; in order to afford an opportunity of proposing a plan of re-union, or new union, or restoration of the Union, I shall submit that this Commonwealth shall take an independent stand, and make a proposition that shall bring back the old Union, or a better Union of all our sister States. Above all things on earth I
                desire to see this Commonwealth placed in that high, independent position that shall not save the old Union, not restore the old Union—for you all admit that it is to be amended, but that will enable her to tender to all an amended Union that shall be acceptable to all. When the Committee of the whole shall resolve itself again into Convention, I propose to offer such a plan as a
                substitute for the report of this Committee; and, with a view of reaching that end, not to be understood at all as voting for secession per se, I shall vote for this as a substitute, in order to open the door of opportunity to present the other ordinance.
              </p>
              <div className="speaker" id="sp2108"><span className="persName">Mr. SPEED</span>, of Campbell—</div>
              <p className="p-in-sp">I shall address myself to—</p>
              <div className="speaker" id="sp2109"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The gentleman from Campbell is not entitled to the floor.</p>
              <div className="speaker" id="sp2110"><span className="persName">Mr. SPEED</span>—</div>
              <p className="p-in-sp">I had supposed, sir, that under the rule of the House I was entitled to as much time as the gentleman from Princess Anne, if not twice as much.</p>
              <div className="speaker" id="sp2111"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">Under the order of the Convention, when the <PageNumber num={172} /> amendment was offered by the gentleman from Amelia [<span className="persName">Mr. HARVIE</span>] , he had ten minutes to state his reasons for offering it, the gentleman from Princess Anne [<span className="persName">Mr. WISE</span>] , had ten minutes to reply, and the gentleman from Amelia has ten minutes in replication.</p>
              <div className="speaker" id="sp2112"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">I will give the gentleman from Campbell my time, if the House will allow me.</p>
              <p>Cries of "go on, go on."</p>
              <div className="speaker" id="sp2113"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The gentleman cannot go on unless it is the pleasure of the Committee that he shall do so.</p>
              <div className="speaker" id="sp2114"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Nobody objects, sir.</p>
              <div className="speaker" id="sp2115"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">If there is no objection, of course the gentleman can proceed.</p>
              <div className="speaker" id="sp2116"><span className="persName">Mr. CARTER</span>, of Loudoun—</div>
              <p className="p-in-sp">I beg leave to object, as the gentleman is proceeding contrary to the rules of the House.</p>
              <div className="speaker" id="sp2117"><span className="persName">Mr. SPEED</span>—</div>
              <p className="p-in-sp">
                I desire to say, sir, that I am among the last, perhaps the very last member of this body who would ask any courtesy to be extended to me as a matter of favor; but I claim for myself the same right that is accorded to every other member on the floor; and I don't understand by what rule of courtesy or propriety, or by what rule regulated by any other influence the gentleman from
                Princess Anne or the gentleman from any other county is entitled to privileges that are not granted to me, supposing him to be the highest and myself to be the humblest member of this body.
              </p>
              <div className="speaker" id="sp2118"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Chair assures the gentleman from Campbell that so far as the Chair is concerned, it recognizes perfect equality among every member of the House.</p>
              <div className="speaker" id="sp2119"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">I believe I am entitled to the floor, am I not, sir?</p>
              <div className="speaker" id="sp2120"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">Yes, sir.</p>
              <div className="speaker" id="sp2121"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">I have a right I suppose to yield to another gentleman, if I choose, for explanation?</p>
              <div className="speaker" id="sp2122"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">Will the gentleman from Amelia allow the clerk to report the order of the Convention?</p>
              <div className="speaker" id="sp2123"><span className="persName">Mr. HULL</span>, of Highland—</div>
              <p className="p-in-sp">It is impossible to hear with any satisfaction what is transpiring in this Committee, and with a view of offering a resolution in Convention which will obviate the difficulty hereafter, I move that the Committee, do now rise.</p>
              <p>The motion was put and the Committee refused to rise.</p>
              <div className="speaker" id="sp2124"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Clerk will proceed to report the order of the Convention.</p>
              <p>The Clerk read the order as follows :</p>
              <p>1. Resolved and ordered, That on Thursday, the 4th day of April</p>
              <PageNumber num={173} />
              <p>next, at 12 o'clock, noon, all debate in the Committee of the Whole upon the reports from the Committee on Federal Relations shall terminate, and the Committee shall at once proceed to vote upon the propositions before it, giving ten minutes to the member offering any amendment, and the same time to one member opposing it, for explanation, and ten minutes for replication.</p>
              <p>2. That hereafter no member in the Committee of the Whole shall be allowed to speak more than once on the same proposition.</p>
              <div className="speaker" id="sp2125"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The question will be upon striking out the 6th resolution of the Committee and inserting the amendment of the gentleman from Amelia, and upon this question the ayes and noes are called.</p>
              <p>
                The ayes and noes were then called upon the question to strike out and insert, which the Convention refused to do by a vote of 45 to 88, as follows :<span className="note" id="Note19"
                ><span className="noteLabel">8</span>In the Proceedings of April 9, page 370, Mr. Mallory noted that he had voted in the affirmative on Mr. Harvie's substitute to the 6th resolution of the Committee of Twenty-One. The Journal lists J. B. Mallory, W. C. Scott, R. H. Turner, F. P. Turner and Tyler among the affirmative votes, giving a total of 45. In the negative votes, the Journal omits
                  J. B. Mallory, but includes Coffman, Gray, Marshall and Marr, making a total of 90.</span
                >
              </p>
              <p>YEAS—Messrs. Ambler, Barbour, Blakey, Boisseau, Borst, Bouldin, Branch, Cecil, Chapman, Conn, R. H. Cox, Fisher, Garland, Graham, Goggin, J. Goode, Hale, L. S. Hall, Harvie, Holcombe, Hunton, Isbell, Kent, Kindred, Lawson, Leake, Montague, Morris, Morton, Neblett, Randolph, Richardson, Seawell, Sheffey, Speed, Strange, Williams, Wise, Woods, Wysor-45.</p>
              <p>NAYS-Messrs. Janney [President]
                , Armstrong, Aston, A. M. Barbour, Baylor, Berlin, Blow, Boggess, Boyd, Brent, Brown, Bruce, Burdett, Burley, Byrne, Campbell, Caperton, Carlile, Carter, C. B. Conrad, R. Y. Conrad, Couch, Critcher, Custis, Dent, Deskins, Dorman, Early, Echols, Flournoy, French, Fugate, Gillespie, Gravely, Addison Hall, Cyrus Hall, Ephraim B. Hall, Hammond, Haymond, Hoge, Hubbard, Hughes, Jackson,
                Marmaduke Johnson, Peter C. Johnston, Kilby, Lewis, McComas, McGrew, McNeil, Macfarland, J. B. Mallory, Marye, Sr., Maslin, Masters, Moffett, Moore, Nelson, Orrick, Osburn, Parks, Patrick, Pendleton, Porter, Preston, Price, Pugh, Rives, R. E. Scott, Sharp, Sitlington, Slaughter, Southall, Spurlock, Staples, A. H. H. Stuart, C. J. Stuart, Summers, Sutherlin, Tarr, Tayloe, Tredway,
                Waller, Whitfield, Wickham, Willey, Wilson-88.
              </p>
            </div>
            <div className="section" id="vsc1965.v3.2.3.5">
              <h3><span className="headingNumber">1.3.5. </span><span className="head">EXPLANATION OF VOTES</span></h3>
              <div className="speaker" id="sp2126"><span className="persName">Mr. CHAMBLISS</span>, of Greenesville—</div>
              <p className="p-in-sp">I have paired off with <span className="persName">Mr. HOLLADAY</span>, from Norfolk county; if I could vote I should vote aye.</p>
              <div className="speaker" id="sp2127"><span className="persName">Mr. BRENT</span>, of Alexandria—</div>
              <p className="p-in-sp">I would state that <span className="persName">Mr. GOODE</span>, of Mecklenburg, and <span className="persName">Mr. DULANY</span>, of Fairfax, are unavoidably absent. I feel warranted in saying that <span className="persName">Mr. GOODE</span> would vote no, and <span className="persName">Mr. DULANY</span>, no.</p>
              <div className="note" id="Note20">
                <span className="noteLabel">9</span>A correction (April 16) of Mr. Brent's remark is as follows:
                <div className="speaker"><span className="persName">Mr. BRENT</span>—</div>
                <p className="p-in-sp">The official report in the "Enquirer" represents me as saying, whilst the motion of the gentleman from Amelia [<span className="persName">Mr. HARVIE</span>] was pending to strike out the sixth section of the report of the Committee of Twenty-One, and to insert the substitute offered by him, that the gentleman from Mecklenburg [<span className="persName">Mr. GOODE</span>] and the gentleman from Fairfax [<span className="persName">Mr. DULANY</span>] had paired off, and that I felt warranted in saying that both gentlemen would have voted no. I stated on that occasion that the gentleman from Mecklenburg had he been present would have voted aye, and the gentleman from Fairfax, no.</p>
                <p>Although the form of the correction looks like that of the Proceedings, the correction follows the close of the report of Proceedings of April 9, and has no apparent relation to it.</p>
              </div>
              <div className="speaker" id="sp2128"><span className="persName">Mr. CRITCHER</span>, of Richmond County—</div>
              <p className="p-in-sp">I have been requested by <span className="persName">Mr. MALLORY</span>, of Elizabeth City, to state that he has paired off with <PageNumber num={174} />, of Norfolk county, but if he were present, he would vote aye.</p>
              <div className="speaker" id="sp2129"><span className="persName">Mr. J. A. CAMPBELL</span>—</div>
              <p className="p-in-sp">I would state that <span className="persName">Mr. GRANT</span>, of Washington county has been called home by sickness in his family, and has not yet returned. If he were here I would feel warranted in saying that he would vote no.</p>
              <div className="speaker" id="sp2130"><span className="persName">Mr. THORNTON</span>, of Prince Edward—</div>
              <p className="p-in-sp">At the request of the member from Chesterfield [<span className="persName">Mr. COX</span>] , I have paired off with him; I regret that the arrangement was entered into. If I could vote I should vote aye.</p>
              <div className="speaker" id="sp2131"><span className="persName">Mr. TURNER</span>, of Jackson—</div>
              <p className="p-in-sp">For the reasons assigned by the gentleman from Princess Anne [<span className="persName">Mr. WISE</span>] , in his remarks, I shall vote aye.</p>
              <div className="speaker" id="sp2132"><span className="persName">Mr. SEAWELL</span>, of Gloucester—</div>
              <p className="p-in-sp">I wish to state that <span className="persName">Mr. GREGORY</span>, of King William, is absent from the city. If he were present, I believe he would vote aye.</p>
              <div className="speaker" id="sp2133"><span className="persName">Mr. EARLY</span>, of Franklin—</div>
              <p className="p-in-sp">My colleague, <span className="persName">Mr. SAUNDERS</span>, is prevented from being here on account of serious illness. If he were present he would vote No.</p>
              <div className="speaker" id="sp2134"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The question recurred upon the adoption of the 6th resolution.</p>
              <div className="speaker" id="sp2135"><span className="persName">Mr. CRITCHER</span>—</div>
              <p className="p-in-sp">
                I propose to amend this 6th resolution, by striking out the words "to cast off obedience to the Federal Government," and inserting in their place, "dissolve their connection with the Federal Government." The section now declares that we deplore the wrongs "that have impelled some of the States to cast off obedience to the Federal Government." I do not propose to recognize obedience
                to the Federal Government, and therefore, I prefer the words "dissolve their connection."
              </p>
              <div className="speaker" id="sp2136"><span className="persName">Mr. PATRICK</span>—</div>
              <p className="p-in-sp">I wish to submit to the Chair whether the refusal to insert the substitute which has been offered for the whole section does not, in fact, adopt the section so that it can now only be amended by addition.</p>
              <div className="speaker" id="sp2137"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">A motion to strike out and insert, if carried in the negative, does not necessarily adopt the first proposition. Another motion to strike out the whole resolution and insert a substitute for it would still be in order, but the Chairman does not think that a motion to amend the original section, in part, would be in order.</p>
              <p>A MEMBER moved that the Committee rise.</p>
              <p>The motion was agreed to.</p>
              <p>The Committee accordingly rose, and the Chairman [<span className="persName">Mr. SOUTHALL</span>] , reported progress.</p>
            </div>
            <div className="section" id="vsc1965.v3.2.3.6">
              <PageNumber num={175} />
              <h3><span className="headingNumber">1.3.6. </span><span className="head">CHANGE OF PLACE OF MEETING</span></h3>
              <div className="speaker" id="sp2138"><span className="persName">Mr. HULL</span>—</div>
              <p className="p-in-sp">I understand that we are now paying $125 a week for the use of this hall, $25 extra if the gas is lighted even for a few minutes, and for the additional reason that we are laboring under great difficulty here from the confusion that necessarily prevails in so large a Hall I submit the following resolutions :</p>
              <p className="p-in-sp">Resolved, That in the event of the adjournment of the legislature to-day this Convention will meet on to-morrow and thereafter in the Hall of the House of Delegates.</p>
              <p className="p-in-sp">Resolved, That the services of the Assistant Doorkeepers be hereafter dispensed with.</p>
              <p className="p-in-sp">I presume the services of the Assistant Doorkeepers will not be necessary in that Hall.</p>
              <div className="speaker" id="sp2139"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">
                I would suggest to the gentleman offering that resolution, the propriety of so altering it, that it shall take effect from Monday next. Our contract for this Hall is by the week, so that the two remaining days of this week will make no difference in the expense. I would suggest the change for another reason. I was at the Capitol this evening and gave directions to the employees in
                attendance, to have the Hall put in perfect order. I was informed that it is in so bad a condition that it would take at least two days to put it in order; I, therefore, hope there will be no provision for our going there before Monday.
              </p>
              <div className="speaker" id="sp2140"><span className="persName">Mr. HULL</span>—</div>
              <p className="p-in-sp">With that statement, understanding that no expense could be saved by going to-morrow, I will so modify the resolution as to make it take effect on Monday.</p>
              <div className="speaker" id="sp2141"><span className="persName">Mr. WICKHAM</span>—</div>
              <p className="p-in-sp">
                I trust the gentleman from Highland will strike out the additional resolution, which dispenses with the services of the Assistant Doorkeepers, or any of the officers of the Convention. We can very well determine, when we get into the Capitol, whether their services are needed, and, if not, we can then dispense with such as are not needed. I trust the gentleman will withdraw that
                resolution.
              </p>
              <div className="speaker" id="sp2142"><span className="persName">Mr. HALL</span>—</div>
              <p className="p-in-sp">I am willing to withdraw the latter resolution for the present, though I give notice that I shall renew it again; for I understand that we can dispense very well with all of them.</p>
              <div className="speaker" id="sp2143"><span className="persName">Mr. PRICE</span>—</div>
              <p className="p-in-sp">
                I would suggest the further provision, that when the Convention adjourns on Saturday, it adjourn to meet in the hall of the House of Delegates. And I would suggest, further, that as his resolution still reads, it makes our adjournment to the Capitol dependent upon the adjournment of the Legislature, I suppose if the resolution is not to take effect until Monday, that is not
                necessary.
              </p>
              <PageNumber num={176} />
              <div className="speaker" id="sp2144"><span className="persName">Mr. HULL</span>—</div>
              <p className="p-in-sp">When the resolution was offered, that provision was put in, to meet a contingency that might possibly arise, if we changed our place of meeting to-morrow. I suppose the condition is not necessary if the resolution does not take effect until Monday, and I will, therefore, omit it and add the other provision, suggested by the gentleman from Greenbrier.</p>
              <p>The resolution, as modified, was then read as follows :</p>
              <p>Resolved, That this Convention will meet on Monday next, and thereafter, in the Hall of the House of Delegates; and that when the Convention adjourn on Saturday next, it adjourn to meet in said Hall.</p>
              <div className="speaker" id="sp2145"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I desire to know whether it is certain that the Legislature will adjourn before Monday next?</p>
              <div className="speaker" id="sp2146"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">
                I think I can answer that question. There is very great doubt whether a quorum of the Senate is remaining in the city at this time. The Senate suspended their business this morning until half-past seven this evening for the purpose of giving the committee on enrolled bills an opportunity of finishing up their business. The Legislature will certainly adjourn to-day.
              </p>
              <p>The resolution, as modified, was adopted.</p>
            </div>
            <div className="section" id="vsc1965.v3.2.3.7">
              <h3><span className="headingNumber">1.3.7. </span><span className="head">TAXATION QUESTION</span></h3>
              <div className="speaker" id="sp2147"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">I believe the first business in order is the resolution submitted by the gentleman from Monongalia [<span className="persName">Mr. WILLEY</span>] . I understand the gentleman who holds the floor upon that resolution is willing to yield it for the purpose of having a vote taken upon it to-night.</p>
              <div className="speaker" id="sp2148"><span className="persName">Mr. SCOTT</span>, of Powhatan—</div>
              <p className="p-in-sp">I am willing to yield the floor for that purpose.</p>
              <div className="speaker" id="sp2149"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">For the purpose, then, of having the vote taken to-night, I move the previous question.</p>
              <div className="speaker" id="sp2150"><span className="persName">Mr. FISHER</span>—</div>
              <p className="p-in-sp">I hope that motion will be withdrawn.</p>
              <div className="speaker" id="sp2151"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">Another gentleman who sits near me, and who is deeply interested in this question, desires also that I shall withdraw the demand, and I will therefore withdraw it.</p>
              <div className="speaker" id="sp2152"><span className="persName">Mr. TURNER</span>, of Jackson—</div>
              <p className="p-in-sp">Some time since I raised a point of order upon the reception of this resolution, which I believe the Chair has never decided. If he decides the point not to be well taken, I then desire to offer a substitute.</p>
              <div className="speaker" id="sp2153"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair remembers that his attention was called to it, and that he intimated at the time that the resolutions of the gentleman from Monongalia were in order, because they covered <PageNumber num={177} /> ground not occupied by those of the gentleman from Jackson, which now lie on the table. That is the decision of the Chair.</p>
              <div className="speaker" id="sp2154"><span className="persName">Mr. TURNER</span>, of Jackson—</div>
              <p className="p-in-sp">
                I desire then, to offer a substitute for the resolutions of the gentleman from Monongalia. I do it for the purpose of bringing before the Convention this question of taxation, simply desiring, as I do, that it shall be treated alone, without any connexion with any other question whatever. I move to strike out the resolutions now pending and to substitute in lieu thereof, as follows :
              </p>
              <p className="p-in-sp">
                "1. Resolved, That it is expedient and proper that the 23d section of the 4th article of the Constitution of this State shall be so modified, that slaves, like other property, shall be taxed without exemption, and according to value, and that no exemption of any property from taxation shall be had without the vote of a majority of all the members elected to each House of the General
                Assembly.
              </p>
              <p className="p-in-sp">"2. Resolved, That a Committee, to consist of thirteen members, to be selected from the different sections of the State, be appointed, who shall report to this Convention such amendments to the Constitution of the State, as will effect the object indicated in the foregoing resolution."</p>
              <p className="p-in-sp">I desire that these resolutions may be adopted, for the further reason that they indicate the manner in which the Committee is to be selected. I demand the yeas and nays upon the adoption of the substitute.</p>
              <div className="speaker" id="sp2155"><span className="persName">Mr. SEAWELL</span>—</div>
              <p className="p-in-sp">Is it in order to offer an amendment to the amendment?</p>
              <div className="speaker" id="sp2156"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">It is.</p>
              <div className="speaker" id="sp2157"><span className="persName">Mr. SEAWELL</span>—</div>
              <p className="p-in-sp">I move, then, to strike out the resolutions of the gentleman from Jackson, and to insert as follows:</p>
              <p>"That a Committee of 13 be appointed to inquire into, and report to this Convention whether any, and if any what alteration of sections 22 and 23 of Article 4 of the Constitution of the Commonwealth are proper to be made at this time."</p>
              <div className="speaker" id="sp2158"><span className="persName">Mr. PATRICK</span>—</div>
              <p className="p-in-sp">Would a motion be in order to lay the amendment, and the amendment to the amendment on the table?</p>
              <div className="speaker" id="sp2159"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">It would carry the original resolutions with it.</p>
              <div className="speaker" id="sp2160"><span className="persName">Mr. PATRICK</span>—</div>
              <p className="p-in-sp">I do not desire to do that.</p>
              <div className="speaker" id="sp2161"><span className="persName">Mr. TREDWAY</span>—</div>
              <p className="p-in-sp">I move that the Convention adjourn.</p>
              <p>The motion was agreed to, and the Convention thereupon adjourned, until to-morrow, at 10 o'clock, A. M.</p>
            </div>
          </div>
          <div className="day" id="vsc1965.v3.2.4">
            <PageNumber num={178} />
            <h2><span className="headingNumber">1.4. </span><span className="head">FORTY-FOURTH DAY</span></h2>
            <div className="dateline">Friday, <span className="date">April 5</span></div>
            <p>The Convention met, pursuant to adjournment, at 10 o'clock A. M. Prayer by the Rev. Mr. Walker, of the Baptist Church.</p>
            <div className="section" id="vsc1965.v3.2.4.1">
              <h3><span className="headingNumber">1.4.1. </span><span className="head">BASIS OF TAXATION</span></h3>
              <div className="speaker" id="sp2162"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">I beg the indulgence of the Convention while I make an explanation which is due to my friend from Doddridge [<span className="persName">Mr. STUART</span>]
                , as well as to myself. Yesterday the hour for going into committee arrived while the gentleman from Doddridge was in the midst of his speech on the question of taxation. Yesterday evening, understanding from some friends of the resolution before the Convention that it was desirable a vote should be had at once, I submitted a few remarks, thereby cutting off, unintentionally, my
                friend from Dodd-ridge from his right to the floor. I trust that it will be the pleasure of the Convention to allow him, when the subject is again under consideration, to resume the floor and conclude his remarks, that he may not be prejudiced by an unintentional act.
              </p>
              <p><span className="persName">The PRESIDENT</span> stated the question to be the substitute offered by the gentleman from Gloucester [<span className="persName">Mr. SEAWELL</span>] to the substitute offered by the gentleman from Jackson [<span className="persName">Mr. TURNER</span>] to the resolution offered by the gentleman from Monongalia [<span className="persName">Mr. WILLEY</span>] .</p>
              <div className="speaker" id="sp2163">
                <span className="persName">Mr. STUART</span>, of Tyler and Doddridge, resumed as follows :
                <div className="note" id="Note21">
                  <span className="noteLabel">1</span>These concluding remarks by Mr. Stuart were printed on April 6, divorced from the rest of the Proceedings of April 5. They were printed again on May 17 along with Mr. Stuart's remarks of April 3 and 4 on the same topic. The version used here in the Proceedings of April 5 is the one printed on May 17. The version of April 6 does not have the short
                  second paragraph of the later version, and what corresponds to the third and sixth paragraphs of the later version was printed thus on April 6:
                  <p>
                    It will be recollected, Mr. President, that I said yesterday that my constituents were ready and willing to stand up in defence of the rights and institutions of the people of the Eastern portion of this State. In saying so I did not wish to be misunderstood as meaning that the people of the West are willing to take the course pressed by those whom we call the ultra men of the
                    Eastern portion of the State; but simply that the people of the West are sound on this question of slavery and are willing, at all hazards, to stand up for the rights and interests of the people of the Eastern portion of this State.
                  </p>
                  <p>
                    I desire to be understood that we are not in favor of the doctrine of the Constitutional right of secession. We believe in the right of revolution. We believe that when our Constitution has been perverted to our injury and oppression, we have the right to throw off the shackles, and appeal to our natural rights. But revolution pre-supposes, in my opinion, a remedy; and, if the
                    fact of dissevering ourselves from the Federal Government would be a remedy for the evils of which we complain, then we would be in favor of it. But I cannot see, for my life, how, under present circumstances, secession or revolution would be a remedy for the evils complained of. Will it relieve us from any of our grievances? If we had to complain of a perversion of the
                    Constitution by the Federal Government, then revolution, if successful, would be a complete remedy; but if the complaint is in regard to the action of separate States, then secession from the General Government would be no remedy. If it were even successful, we would still find ourselves in identically the same position in which we now are. The institutions and laws of those
                    States, derogatory of the rights of the South, would still remain on their statute books.
                  </p>
                  <p>* * *</p>
                  <p>
                    They appeal to us to come to their rescue and help them to save the Union. They have fought for us in days gone by, and now they appeal to our brotherly feeling to come to their aid and to save the Union. If we pursue the course indicated here by the secessionist party, we will cut ourselves loose from our friends in the free States, and array them against us. The whole civilized
                    world will then be arrayed, by our action, against the institution of slavery. If we say to those in the North, who have heretofore stood up for our rights, "We will have nothing more to do with you," can you expect or hope to retain the good will and kind feeling of that people ? No sir. By pursuing this course, you will, in my humble opinion, unite the people of the North in
                    one solid mass against our institutions. Then we will have the whole world arrayed against us. Can we expect our friends in the North to stand by us, after we have destroyed the Government and brought ruin upon them? It is hopeless to expect so. Then I hold that secession or ,revolution is no remedy for the evils complained of, but is an aggravation of them, and will, if
                    persisted in, lead to the extermination of slavery.
                  </p>
                  <p>I find, Mr. President, that I have but four minutes left before the Convention goes into Committee of the Whole, and, as I could not discuss any other proposition within that time, I will now yield the floor.</p>
                </div>
              </div>
              <p className="p-in-sp">
                Mr. President, I feel that I should offer an apology to the Convention for detaining them on this question, but it will be recollected that I only had some fifteen minutes each morning before the hour of going into Committee of the Whole. Being anxious to proceed, without delay, to the business of the Convention, I was willing, at any moment, and am now willing to have the question
                submitted to a vote. I attempted, last evening, to get the floor to conclude my remarks, but was prevented by my friend from Harrison
                [<span className="persName">Mr. CARLILE</span>] .</p>
              <p className="p-in-sp">I desire now to set myself right in some things in which I may be misunderstood.</p>
              <p className="p-in-sp">
                It will be recollected, Mr. President, that I said yesterday that my constituents were ready and willing to stand up in defence of the rights and institutions of the people of the Eastern portion of the State. In saying so, I did not wish to be understood as meaning that the people of the West were willing to take the course pressed by those whom we call the ultra men of the Eastern
                portion of the <PageNumber num={179} /> State, but that the people of the West are sound on the question of slavery, and are willing, at all hazards, to demand and have secured to you your rights ; but I do not wish to be understood as being in favor of secession, or the constitutional right of secession. We believe in the right of revolution. We
                believe that when the Constitution has been perverted to our injury and oppression, we have the right to throw off the shackles and appeal to our natural rights. But revolution presupposes, in my opinion, a remedy, and if the fact of dissevering our connection from the Federal Government would be a remedy for the evils of which we complain, then we would be in favor of it. But I
                cannot see for my life how, under present circumstances, secession or revolution would be a remedy for the evils of which you complain. Will it relieve us from any of them? If we had to complain of the perversion of the Constitution by the Federal Government, to our injury and oppression, then revolution, if successful, would be a complete remedy. But if the complaint is in regard to
                the action of separate States, then secession from the General Government would be no remedy. If it were even successful, we would still find ourselves in identically the same position in which we now are. The institutions and laws of those States derogatory of the rights of the South, would still remain on their statute books.
              </p>
              <p className="p-in-sp">
                The people of my district are in favor of contending for their rights in the Union. In that we go with our friend from Princess Anne. We all stand up as one solid mass of people to contend and fight for our rights in the Union. We believe that secession, instead of being a remedy for the evils complained of, would be an aggravation of those evils. The great question that has given
                rise to complaint is the slavery question. And I have told you that although we have no direct interest in that question, we were loyal and true to the interests of the people of the eastern portion of the State.
              </p>
              <p className="p-in-sp">
                We take the position that slavery is right, legally, morally, and in every sense of the word. But the Convention will recollect that the sentiment of the whole civilized world, at this day, is arrayed against the institution of slavery, and it is nothing but the prestige and power of the General Government now that guarantees to the slaveholder his right. We find a large portion of
                the people of the Northern States standing up also to vindicate and support our rights. I believe that at this day there is a majority of the people of the free States in favor of the right of the Southern portion of the Confederacy to the institution of slavery.
              </p>
              <p className="p-in-sp">
                They appeal to us to come to the rescue and help them to save the <PageNumber num={180} /> Union. They have fought for our rights in days gone by, and now they appeal to us as friends, for whose rights they have ever stood firm and true, to come to their aid and help them to save the Union. If we pursue the course indicated here by the secession party,
                we will cut ourselves loose from our friends in the free States, and array them against us and our institutions. If we say to those in the North, who have heretofore stood up for our rights, "We will have nothing more to do with you," can you expect or hope to retain the good will and kind feeling of that people? No, sir. By pursuing this course, you will, in my humble opinion, drive
                from us our heretofore best and truest friends, and unite them in one solid mass against our institutions. Then we will have the whole world arrayed in sentiment against our institutions, with a power right on our borders three times our strength, made our enemies by our own acts. Can we expect our friends in the North to stand by us after we have destroyed our common Government and
                brought ruin upon them? It is hopeless to expect so. Then I hold that secession or revolution is no remedy for the evils complained of, but will tend as an aggravation of them, and will, if persisted in, lead to the extermination of slavery. I find, Mr. President, that I have no time left to discuss any other proposition before the Convention goes into Committee of the Whole, and, as
                I do not wish to pursue this course any further, I now yield the floor.
              </p>
              <div className="speaker" id="sp2164"><span className="persName">Mr. SLAUGHTER</span>—</div>
              <p className="p-in-sp">For the reasons indicated by me some time ago, I now move to lay the whole subject on the table for the present. I think its discussion premature and disastrous in the extreme.</p>
              <div className="speaker" id="sp2165"><span className="persName">Mr. WILLEY</span>—</div>
              <p className="p-in-sp">Regarding this as a test question, I call for the yeas and nays.</p>
              <div className="stage it">[The yeas and nays were ordered.]</div>
              <div className="speaker" id="sp2166"><span className="persName">Mr. STAPLES</span>—</div>
              <p className="p-in-sp">I ask the gentleman to withdraw the motion to lay on the table, that I may submit a few remarks.</p>
              <div className="speaker" id="sp2167"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">No debate is in order.</p>
              <p>
                The question was taken and it was decided in the negative—yeas, 55, nays 73, as follows:<span className="note" id="Note22"><span className="noteLabel">2</span>The Journal refers to 54 affirmative votes, and lists the affirmative votes given here.</span>
              </p>
              <p>
                YEAS-Messrs. Ambler, Blakey, Blow, Jr., Boisseau, Borst, Bouldin, Branch, Brent, Bruce, Carter, Chambliss, Conn, Fisher, Flournoy, Garland, Gravely, J. Goode, A. Hall, Hammond, Harvie, Holcombe, Hunton, Isbell, Kent, Kilby, Leake, C. K. Mallory, J. B. Mallory, Marshall, Marr, Marye, Moffett, Montague, Morris, Neblett, Nelson, Randolph, R. E. Scott, Wm. C. Scott, Seawell, Slaughter,
                Southall, Speed, Strange, Sutherlin, Thornton, Tredway, R. H. Turner, Tyler, Waller, White, Whitfield, Wickham, Williams-55.
              </p>
              <p>NAYS-Messrs. John Janney [President]
                , Armstrong, Aston, A. M. Barbour, Baylor, Berlin, Boggess, Boyd, Brown, Burdett, Burley, Byrne, Campbell, Carlile, Cecil, Chapman, Clemens, Coffman, C. B. Conrad, R. Y. Conrad, Couch, Custis, <PageNumber num={181} /> Dent, Deskins, Early, Echols, French, Fugate, Gillespie, Graham, Gray, Goggin, Hale, Cyrus Hall, E. B. Hall, Haymond, Hoge, Hubbard,
                Hughes, Hull, Jackson, Peter C. Johnston, Lawson, Lewis, McComas, McGrew, McNeil, Masters, Morton, Moore, Orrick, Osburn, Parks, Patrick, Porter, Preston, Price, Pugh, Sharp, Sheffey, Sitlington, Spurlock, Staples, A. H. H. Stuart, Chapman J. Stuart, Summers, Tarr, Tayloe, F. P. Turner, Willey, Wilson, Woods, Wysor73.
              </p>
              <p>So the Convention refused to lay the whole subject on the table. The Convention then went in Committee of the Whole, <span className="persName">Mr. SOUTHALL</span>, of Albemarle, in the Chair.</p>
              <div className="speaker" id="sp2168"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Chair would avail itself of the present occasion to say that in the somewhat excited proceedings of yesterday evening, not altogether free from confusion, the Chair entertained a motion which, as soon as it was made, it was satisfied, it ought not to have done. It was during the pendency of the proposition of the gentleman from Amelia [<span className="persName">Mr. HARVIE</span>] , to strike out the 6th resolution and insert his proposition as a substitute for the whole.</p>
              <p className="p-in-sp">The Chair entertained as an amendment to that proposition, the motion of the gentleman from Bedford [<span className="persName">Mr. GOGGIN</span>] to strike out the substitute proposed by the gentleman from Amelia [<span className="persName">Mr. HARVIE</span>] and to insert his proposition in lieu thereof. The Chair is of opinion that he should not have entertained the motion of the gentleman from Bedford [<span className="persName">Mr. GOGGIN</span>] and was about to correct the error, when the gentleman withdrew his proposition ; so that no inconvenience resulted from the motion.</p>
              <p className="p-in-sp">
                The Chair will now state, for the information of those interested in its opinions and decisions, that when an original proposition is pending, a motion is made by way of amendment to strike out the original proposition, and insert, say proposition "A," it is not in order to move to strike out "A" and insert proposition "B"—the friend of B, should intimate its character and object,
                and the friends of B would unite in voting down the amendment A, with a view of substituting B for the original proposition.
              </p>
              <div className="speaker" id="sp2169"><span className="persName">Mr. CRITCHER</span>—</div>
              <p className="p-in-sp">If it will be now in order, I will propose an amendment to the 6th resolution. I move to strike out the words "to cast off obedience to the Federal Government," in the third line, and insert in lieu thereof "to dissolve their connection with the Federal Government."</p>
              <div className="stage it">
                <p>[The 6th resolution in the report proposed to be amended reads as follows :</p>
                <p>
                  "Deeply deploring the present distracted condition of the country, and lamenting the wrongs that have impelled some of the States to <PageNumber num={182} /> cast off obedience to the Federal Government, but sensible of the blessings of the Union, and impressed with its importance to the peace, prosperity and progress of the people, we indulge the
                  hope that an adjustment may be reached by which the Union may be preserved in its integrity, and peace, prosperity and fraternal feelings be restored throughout the land."]
                </p>
              </div>
              <div className="speaker" id="sp2170"><span className="persName">Mr. WYSOR</span>—</div>
              <p className="p-in-sp">I move to amend the amendment of the gentleman by striking out the entire section.</p>
              <div className="speaker" id="sp2171"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">It is not in order to move to strike out the whole section. The friends of the section have a right to perfect it.</p>
              <div className="speaker" id="sp2172"><span className="persName">Mr. CONRAD</span>, of Frederick—</div>
              <p className="p-in-sp">When this amendment was proposed by the gentleman from Westmoreland [<span className="persName">Mr. CRITCHER</span>] , yesterday, it seemed to me to be a decided improvement both of the phraseology of the section and of the idea expressed. I merely rise to say that I shall cheerfully vote for the amendment which is proposed.</p>
              <p>The amendment was agreed to.</p>
              <div className="speaker" id="sp2173"><span className="persName">Mr. MOORE</span>, of Rockbridge—</div>
              <p className="p-in-sp">I move to amend the 6th resolution by striking out the word "wrongs" in the second line, and substitute the word "causes."</p>
              <div className="speaker" id="sp2174"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I inquire of the mover of this, whether by the amendment he means to assert that there were no wrongs which compelled or impelled the withdrawal of some of the States.</p>
              <div className="speaker" id="sp2175"><span className="persName">Mr. MOORE</span>—</div>
              <p className="p-in-sp">I will answer the gentleman without hesitation.</p>
              <p className="p-in-sp">I believe that there have been great wrongs inflicted upon Virginia and the other border States, but I don't believe that the seceding States have withdrawn for any such causes. I believe it is more a calculation of interest with them—that they believe they can profit by a disruption—and the wrongs set forth are mere pretexts intended to operate upon us.</p>
              <div className="speaker" id="sp2176"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">I call for the yeas and nays upon that motion.</p>
              <p>The call was sustained, and the yeas and nays being called, resulted —yeas 26, nays 107—as follows :</p>
              <p>AYES-Messrs. Berlin, Boggess, Brown, Burdett, Burley, Carlile, Clemens, C. B. Conrad, Dent, Early, Hubbard, Hughes, Jackson, Peter C. Johnston, Lewis, McGrew, Masters, Moore, Patrick, Porter, Wm. C. Scott, Sharp, Tarr, White, Willey, Wilson-26.</p>
              <p>NOES-Messrs. Janney [President]
                , Ambler, Armstrong, Aston, A. M. Barbour, James Barbour, Baylor, Blakey, Blow, Jr., Boisseau, Borst, Bouldin, Boyd, Branch, Brent, Bruce, Byrne, Campbell, Carter, Chambliss, Chapman, Coffman, Conn, R. Y. Conrad, Couch, R. H. Cox, Critcher, Custis, Deskins, Echols, Fisher, Flournoy, French, Fugate, Garland, Gillespie, Graham, Gravely, Gray, Goggin, John Goode, Jr., Hale, A. Hall, E.
                B. Hall, L. S. Hall, Hammond, <PageNumber num={183} /> Harvie, Haymond, Hoge, Holcombe, Hull, Hunton, Isbell, Marmaduke Johnson, Kent, Kilby, Lawson, Leake, McComas, McNeil, Macfarland, Charles K. Mallory, James B. Mallory, Marshall, Marr, Marye, Sr., Moffett, Montague, Morris, Morton, Neblett, Nelson, Orrick, Osburn, Parks, Pendleton, Preston, Price,
                Pugh, Randolph, R. E. Scott, Seawell, Sheffey, Sitlington, Slaughter, Southall, Speed, Spurlock, Staples, A. H. H. Stuart, C. J. Stuart, Strange, Summers, Sutherlin, Tayloe, Thornton, Tredway, R. H. Turner, Franklin P. Turner, Tyler, Waller, Whitfield, Wickham, Williams, Wise, Woods, Wysor-107.
              </p>
              <div className="speaker" id="sp2177"><span className="persName">Mr. SUTHERLIN</span>, of Pittsylvania—</div>
              <p className="p-in-sp">I desire to amend this 6th resolution by inserting in the sixth line, after the word "hope," the words, "provided the authorities at Washington shall maintain peaceful relations with the seceded States."</p>
              <p className="p-in-sp">I desire to make but a few remarks in explanation of this amendment.</p>
              <p className="p-in-sp">
                I cannot express any hope for any adjustment that will restore peace and unite this country again, unless the authorities at Washington shall maintain peaceful relations with the seceded States. I cannot express that hope, because I am ready myself, at any time when they shall undertake, under any pretext whatever, "to enforce the laws or reinforce the forts," or commit any other act
                within the seceded States of a coercive character—I say, in any of these events, I am ready to vote to dissolve our connection with this government; and not only that, but to pledge myself to resist that authority in the execution, as they may consider it, of the laws in the seceded States, which I consider nothing more or less than war.
              </p>
              <p className="p-in-sp">
                It is due to myself, it is due to the constituency which I have the honor in part to represent, that I should make this statement; for the position which I have assumed is the same that I occupied when a candidate before them. I now say, that unless this or a similar amendment is adopted, I shall be under the necessity of voting against the whole resolution, because I have no hope of
                the reconstruction of the Government as it was, except on peaceful relations. I have no idea of recording my vote expressing any hope to preserve it upon any other terms. It is, therefore, sir, that I offer the amendment.
              </p>
              <div className="speaker" id="sp2178"><span className="persName">Mr. SPEED</span>—</div>
              <p className="p-in-sp">Will it be in order to move to add to the amendment proposed by the gentleman?</p>
              <div className="speaker" id="sp2179"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Chair sees at once, that if the order of the House is to be strictly and technically construed, that a most serious inconvenience may result in the progress of the amendments to this report:</p>
              <p className="p-in-sp">"All debate in Committee of the Whole shall cease at 12 o'clock on Thursday, and the Committee shall at once proceed to vote upon the <PageNumber num={184} /> propositions before it, giving ten minutes to the member offering any amendment and the same time to one member opposing it, for explanation, and ten minutes for replication."</p>
              <p className="p-in-sp">
                It seems, therefore, if the Chair is to look to the phraseology of this order of the Committee and to consider it according to its language, that no amendment to the amendment would be sustainable or allowed. The Chair did hope, after what passed yesterday, that members dissatisfied with this rule, this morning in the Convention would have had it changed and modified.
              </p>
              <div className="speaker" id="sp2180"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I was dissatisfied with the construction put upon that rule yesterday, but I was not here this morning, being necessarily absent. I understand the Chair now to say that that rule, which concerns the limitation of time to be consumed in debate and refers to persons debating any question, precludes an amendment that would otherwise be in order.</p>
              <div className="speaker" id="sp2181"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Chair feels the difficulty, as he has just said, in deciding that question that the rule seems to be confined entirely to an amendment, opposition to it, and then a replication.</p>
              <div className="speaker" id="sp2182"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">It confines and designates the persons who are to debate the amendment, but it does not exclude any amendment that would otherwise be in order.</p>
              <div className="speaker" id="sp2183"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Chair would be very happy if it could be brought to that interpretation of the order. The Chair will make its decision, and it will be in order for any gentleman who differs from the Chair to take an appeal.</p>
              <div className="speaker" id="sp2184"><span className="persName">Mr. R. Y. CONRAD</span>—</div>
              <p className="p-in-sp">Will the Chair permit me to say one word upon this question of order, which has been raised by the Chair.</p>
              <div className="speaker" id="sp2185"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">It is out of order, but the Chair certainly will indulge the gentleman.</p>
              <div className="speaker" id="sp2186"><span className="persName">Mr. R. Y. CONRAD</span>—</div>
              <p className="p-in-sp">
                I feel myself responsible for that order, and I beg leave to say that, little versed as I am in parliamentary law, I cannot think that the construction which the Chair seems disposed to put upon that order is the correct one. I submit that an amendment to an amendment is not the less an amendment. Now, the rule reads that when any amendment is offered, ten minutes shall be allowed to
                the member proposing the amendment to discuss it, ten minutes for a reply, and ten minutes for a replication. Why should not that rule apply as well to an amendment to an amendment, if such motion be in order, as to the original amendment? It must apply, unless the decision of the Chair involves this proposition, that an
                <PageNumber num={185} /> amendment to an amendment is not an amendment at all. I ask the Chair, without deciding the question—I do not understand that the Chair has decided it positively—to re-consider his opinion, that I may not be driven to the necessity of appeal.
              </p>
              <div className="speaker" id="sp2187"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">
                The Chair will decide that it will receive the amendment, because the author of the resolution gives it a different interpretation, and the Chair is satisfied that its interpretation would work more inconvenience and perhaps injustice, and, therefore, the Chair will decide to receive the amendment, and the Chair hopes that any member who is dissatisfied with the decision of the
                Chair, will take an appeal, so that the sense of the Committee can be ascertained.
              </p>
              <div className="speaker" id="sp2188"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I want simply to inquire what the decision of the Chair is—whether it conforms to the views of the gentleman from Frederick [<span className="persName">Mr. CONRAD</span>] ?</p>
              <div className="speaker" id="sp2189"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">Distinctly.</p>
              <div className="speaker" id="sp2190"><span className="persName">Mr. CLEMENS</span>-s</div>
              <p className="p-in-sp">I desire to know, under the construction which the gentleman from Frederick has given to this resolution, whether the limit as to the time is to be applied to the amendment, to the amendment, as if no amendment were offered at all.</p>
              <div className="speaker" id="sp2191"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">It applies to any amendment.</p>
              <div className="speaker" id="sp2192"><span className="persName">Mr. CLEMENS</span>—</div>
              <p className="p-in-sp">I desire to say, that under the strict phraseology of that rule, the Chair, in my opinion, was right.</p>
              <div className="speaker" id="sp2193"><span className="persName">Mr. SPEED</span>—</div>
              <p className="p-in-sp">I propose to add to the sixth resolution of the report of the Committee, the following words :</p>
              <p className="p-in-sp">"And not seek to accumulate, within the limits of any Southern State, an amount of troops or munitions of war not required for the purpose of external defence."</p>
              <div className="speaker" id="sp2194"><span className="persName">Mr. DORMAN</span>—</div>
              <p className="p-in-sp">I beg to call the attention of the gentleman from Pittsylvania [<span className="persName">Mr. SUTHERLIN</span>] , and the gentleman from Lynchburg [<span className="persName">Mr. SPEED</span>] , both, to the 5th and 12th resolutions. I shall be compelled to vote against both of the amendments offered by these gentlemen, because the amendment of the gentleman from Pittsylvania [<span className="persName">Mr. SUTHERLIN</span>] is provided for expressly and fully in the 12th resolution, which declares "they will expect, as an indispensable condition, that a pacific policy shall be adopted towards the seceded States." That, of course, necessarily implies the idea, unless that pacific policy be adopted, that we cannot hope for the continuance of peace and progress.</p>
              <p className="p-in-sp">As regards the last amendment proposed by the gentleman from Lynchburg [<span className="persName">Mr. SPEED</span>] it seems to me to be but a repetition of the amendment offered by him to the 5th resolution, when he moved <PageNumber num={186} /> to add after the words "unwise, impolitic and offensive," the words "not to be tolerated by a free people."</p>
              <p className="p-in-sp">I understand that question to be decided substantially already by this Committee.</p>
              <div className="speaker" id="sp2195"><span className="persName">Mr. SPEED</span>—</div>
              <p className="p-in-sp">
                I do not understand that the decision made yesterday was necessarily an exclusion of the amendment that I propose now. The 5th resolution declares that "An unusual amount of troops and munitions of war, not required for any legitimate purpose is unwise. impolitic and offensive." Why unwise, impolitic and offensive? Surely for some reasons, and the reasons will occur to members
                without stopping to suggest them. It does seem to me it would be idle to suppose, taking as an example the other States, that we could have a peaceable adjustment of the difficulties now pending between this State and the other States of the Union, not seceded, if there were at Old Point Comfort, for example, an accumulation of troops and munitions of war, which this State understood
                was carried there for purposes connected with the administration of the Federal Government in its external affairs, but for the purpose,<span className="note" id="Note23"><span className="noteLabel">3</span>There seems to be some omission in this sentence, as the speaker must have meant to say something like "but which, on the contrary, were carried there for the purpose."</span> and there
                could be no other purpose, of intimidating and preventing Virginia from following the policy that she might be disposed to adopt. I do believe it is indispensable as a condition to a settlement of this question, not only that there shall be no attempt to coerce the seceded States, but. further, that there shall be no attempt by actual intimidation, or by any measure which might be
                regarded as such, to operate upon the State of Virginia or any other State. Those who concur with me in opinion, ought to express that opinion by voting for the amendment.
              </p>
              <div className="speaker" id="sp2196"><span className="persName">Mr. SUTHERLIN</span>—</div>
              <p className="p-in-sp">I wish to add a word in reply to the gentleman from Rockbridge [<span className="persName">Mr. DORMAN</span>]
                . He says that the amendment I offer is provided for in the 12th resolution. The 12th resolution is not yet adopted. Even if that resolution had been adopted, it only asserts a principle, and the amendment I offer asserts another principle. It says, unless this relation is maintained, that we cannot express the hope of the re-construction of this government and the preservation of
                peace ; that we cannot even express the hope. I certainly cannot, and therefore, it is, that I insist upon the amendment. I saw in the papers this morning, that in all probability there will be an attempt made to reinforce the forts at the South; that in all probability the vote given here against immediate secession will stimulate that effort to re-inforce those Southern forts; and
                I, for one, who voted here yesterday against immediate secession, have no idea that it shall go forth to the world that I am in favor of maintaining this government by force. I shall <PageNumber num={187} /> express that opinion here. I wish it to go forth as my opinion. I have no doubt that it is the opinion of a large majority of this Committee, that
                they cannot and will not express any hope here of a re-construction and preservation of this government, unless it can be done peaceably.
              </p>
              <p><span className="persName">Mr. SPEED</span>, demanded the yeas and nays, which were ordered.</p>
              <p>The question was then taken on <span className="persName">Mr. SPEED</span>'S amendment, and it was rejected; yeas 61, nays 75, as follows :</p>
              <p>
                YEAS-Messrs. Ambler, J. Barbour, Blakey, Boisseau, Borst, Bouldin, Cecil, Chambliss, Chapman, Coffman, Conn, Richard H. Cox, Echols, Fisher, Garland, Graham, Goggin, J. Goode, Jr., Hale, C. Hall, L. S. Hall, Hammond, Harvie, Holcombe, Hunton, Isbell, Kent, Kindred, Lawson, Leake, McNeil, C. K. Mallory, J. B. Mallory, Marr, Marye, Montague, Morris, Morton, Neblett, Orrick, Parks,
                Randolph, Richardson, W. C. Scott, Seawell, Sheffey, Slaughter, Southall, Speed, Spurlock, Strange, Sutherlin, Thornton, Tredway, R. H. Turner, Franklin P. Turner, Tyler, Williams, Wise, Woods, Wysor-61.
              </p>
              <p>NAYS-Messrs. Janney [President]
                , Armstrong, Aston, Alfred M. Barbour, Baylor, Berlin, G. Blow, Boggess, Boyd, Branch, Brent, Brown, Bruce, Burdett, Burley, Byrne, Campbell, Carlile, Carter, Clemens, C. B. Conrad, Robert Y. Conrad, Couch, J. Critcher, Custis, Dent, Deskins, Dorman, Early, Flournoy, French, Fugate, Gillespie, Gravely, Gray, A. Hall, E. B. Hall, Haymond, Hoge, Hubbard, Hughes, Jackson, Marmaduke
                Johnson, P. C. Johnston, Kilby, Lewis, McComas, McGrew, Macfarland, Marshall, Masters, Moffett, Moore, Nelson, Osburn, Patrick, Pendleton, Porter, Preston, Price, Pugh, R. E. Scott, Sharp, Sitlington, Staples, A. H. H. Stuart, C. J. Stuart, Summers, Tarr, Tayloe, Waller, White, Wickham, Willey, Wilson-75.
              </p>
              <div className="speaker" id="sp2197"><span className="persName">Mr. SCOTT</span>, of Fauquier—</div>
              <p className="p-in-sp">The Committee have voted down the amendment of the gentleman from Campbell [<span className="persName">Mr. SPEED</span>] , and I hope that they will now vote down the amendment of the gentleman from Pittsylvania [<span className="persName">Mr. SUTHERLIN</span>]
                . I think it must be obvious to every one who will take the trouble to read these resolutions in connection, that the purpose of both amendments is fully answered by the resolutions as they stand. I am sure that there is not a member of this body who does not concur entirely in the propositions announced by both the amendment, and the amendment to the amendment. The effect of
                proposing these amendments is, to some extent, to place the members of this body in a false position making them seem to vote against propositions in which all concur.
              </p>
              <p className="p-in-sp">Now, sir, you have only to look to the resolutions, which precede these, to find an earnest protest against the accumulation of an unusual number of troops and of an unusual amount of munitions of war. The purpose intended to be subserved by the amendment proposed by the gentleman from Campbell [<span className="persName">Mr. SPEED</span>] is already answered <PageNumber num={188} /> by the fifth resolution. Then, sir, you have only to read the concluding paragraph of the sixth section to find that the purpose of the amendment of the gentleman from Pittsylvania [<span className="persName">Mr. SUTHERLIN</span>] , is answered, because there you will find that we indulge the hope that an adjustment may be reached, by which the Union may be preserved in its integrity, and peace.</p>
              <p className="p-in-sp">
                Further than that, if you will turn to the 12th resolution of the series, it is emphatically declared that we regard it as an indispensable condition that a pacific policy shall be adopted towards the seceded States, "and that no attempt be made to subject them to the Federal authority, nor to reinforce the forts now in possession of the military forces of the United States, nor
                recapture the forts, arsenals, or other property of the United States within their limits."
              </p>
              <p className="p-in-sp">I can conceive that a purpose designed to be answered by both the amendment and the amendment to the amendment can be more strongly obtained than by the resolutions as they already stand.</p>
              <p>The question was then taken on the amendment of <span className="persName">Mr. SUTHERLIN</span>, and it was rejected.</p>
              <div className="speaker" id="sp2198"><span className="persName">Mr. JOHNSTON</span>, of Lee and Scott—</div>
              <p className="p-in-sp">
                I propose to amend this resolution in the fourth line, by striking out "the" before "Union." In the place where it is, it makes the Committee, in this resolution, utter a sentiment directly at war with almost the whole tenor of their report. On referring to subsequent resolutions in that report you will see that provisions are made for a state of things directly at war with the idea
                that we are convinced that the present Union—"the Union"—is a blessing to us. The use of such an expression here would be uttering an absurdity, would be giving utterance to an idea directly at variance with the ideas expressed everywhere else in the report. Moreover, sir, let me remark that my recollection is—and I have no doubt I will be sustained in that recollection by the other
                members of the Committee—that, as the resolution was prepared and intended to be reported by them, as it was actually shaped by the Committee, the word "the" was stricken out. It did not appear in the resolution as adopted by the Committee on Federal Relations. By what mistake it has come to be introduced here in the report, I do not know. It is sufficient, however, for the present,
                to see that it is here, and I move that it be stricken out.
              </p>
              <div className="speaker" id="sp2199"><span className="persName">Mr. CONRAD</span>, of Frederick—</div>
              <p className="p-in-sp">I hope it will be stricken out by general consent. It is there simply by mistake of the Clerk.</p>
              <div className="speaker" id="sp2200"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">Unless the Chair hears some objection, the article "the" will be stricken out before the word "Union," in the fourth line <PageNumber num={189} /> of the report, so as to cause it to read "blessings of Union." The Chair hears no objection.</p>
              <p>So the word "the" was stricken out, by general consent.</p>
              <div className="speaker" id="sp2201"><span className="persName">Mr. CARLILE</span>, of Harrison—</div>
              <p className="p-in-sp">
                Mr. Chairman, I move to amend this sixth section, by striking out, in the second and third lines, the words, "and lamenting the wrongs that have impelled some of the States to dissolve their connection with," and inserting, "caused by some of the States having cast off obedience to." The section now reads : "Deeply deploring the present distracted condition of the country, and
                lamenting the wrongs that have impelled some of the States to dissolve their connection with the Federal Government," &amp;c. If the amendment which I propose should prevail, the section will then read : "Deeply deploring the present distracted condition of the country, caused by some of the States having cast off obedience to the Federal Government." I do not concur with the
                Committee in the opinion that there have been any such wrongs inflicted by the Federal Government upon those States as justified them in casting off their obedience to it.
              </p>
              <p><span className="persName">The CHAIRMAN</span> stated the motion.</p>
              <div className="speaker" id="sp2202"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">Mr. Chairman, if this amendment be adopted by the Committee, it will be a distinct reversal of the proposition of the gentleman from Rockbridge [Mr. MOORE] , and of the gentleman from Westmoreland [<span className="persName">Mr. CRITCHER</span>] . I hope the Committee will not stultify itself by adopting the amendment.</p>
              <p>The Committee then refused to adopt the amendment offered by <span className="persName">Mr. CARLILE</span>.</p>
              <div className="speaker" id="sp2203"><span className="persName">Mr. BOULDIN</span>, of Charlotte—</div>
              <p className="p-in-sp">
                In the sixth line of the same section I move to amend by striking out the words "indulge the hope" and inserting in lieu thereof the words "earnestly desire," so as to make the section read, "but sensible of the blessings of Union and impressed with its importance to the peace, prosperity and progress of the people, we earnestly desire that an adjustment," &amp;c. My reasons for
                offering this amendment are that there are many of us from whom hope has gone, and yet who earnestly desire a restoration of our ancient Union in its integrity, with guarantees just, honorable and safe to all. Therefore, I desire to place it in such a condition that those concurring with me in the opinion that hope is gone, may still be able to vote, expressing a desire that that
                state of things may be restored.
              </p>
              <p>The question was stated by the Chairman.</p>
              <div className="speaker" id="sp2204"><span className="persName">Mr. SCOTT</span>, of Fauquier—</div>
              <p className="p-in-sp">Mr. Chairman, I am sorry, sir, to find that my friend from Charlotte [<span className="persName">Mr. BOULDIN</span>]
                , is in such a hopeless condition. I trust, however, that it is only for a season that hope has bid <PageNumber num={190} /> this country farewell. I trust that she will again re-visit our land. I am not quite as hopeless as the gentleman from Charlotte, and I am free to say that notwithstanding the gloom that now overhangs us, I have an abiding,
                confident hope that it is within the purpose of a benign Providence to unite the people of this land once more in the bonds of brotherhood. Whether effected, Mr. Chairman, by amendments to the present Constitution, or by amendments to the Montgomery Constitution, or by amendments to be proposed by these Border slave States, I repeat, that I not only have the hope, but I feel a
                confidence, that in one or the other of these modes, or in some other mode, the States that composed the United States of America, will again be united in strong bonds of fellowship. In respect to the amendment of the gentleman from Charlotte, I can perceive no objection to it whatever. The resolution now expresses the indulgence of a hope. The gentleman from Charlotte and some
                others, as he says, cannot, if this language is preserved, conscientiously vote for the resolution. It will not impair its force, it will not impair its strength, it will not render the resolution less expressive to substitute, "earnestly desire;" and sir, if it be the pleasure of the Committee to adopt the amendment of the gentleman from Charlotte, as I trust it will, I shall desire
                that the vote upon this resolution shall be taken by the ayes and noes, that our constituents may know, and that the entire country may know, that notwithstanding all the excitement and all these various causes of heart burning, there yet abides in the bosom of this Convention, there yet abides in the bosom of the people of the State of Virginia, an earnest desire for the restoration
                of our ancient harmony.
              </p>
              <div className="speaker" id="sp2205"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The question will be upon the amendment.</p>
              <div className="speaker" id="sp2206"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I understand the gentleman from Fauquier to require the ayes and noes.</p>
              <div className="speaker" id="sp2207"><span className="persName">Mr. SCOTT</span>, of Fauquier—</div>
              <p className="p-in-sp">No, sir, not on the amendment; I said on the passage of the resolution.</p>
              <p><span className="persName">Mr. BROWN</span>, of Preston, called for the ayes and noes on the amendment.</p>
              <div className="speaker" id="sp2208"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The ayes and noes are now ordered.</p>
              <p>The ayes and noes were then called, and the amendment was adopted by a vote of 131 to 5, as follows :</p>
              <p>YEAS-Messrs. Janney [President]
                , Armstrong, Aston, A. M. Barbour, Jas. Barbour, Baylor, Blakey, Blow, Boisseau, Borst, Bouldin, Boyd, Branch, Brent, Brown, Burdett, Burley, Byrne, Campbell, Caperton, Carlile, Carter, Cecil, Chambliss, Chapman, Clemens, Coffman, Conn, C. B. Conrad, R. Y. Conrad, <PageNumber num={191} /> Couch, R. H. Cox, Critcher, Custis, Dent, Deskins, Dorman, Early,
                Echols, Fisher, Flournoy, French, Fugate, Garland, Gillespie, Graham, Gravely, Goggin, John Goode, Hale, Addison Hall, Cyrus Hall, Ephraim B. Hall, L. S. Hall, Hammond, Haymond, Hoge, Holcombe, Hubbard, Hughes, Hunton, Isbell, Jackson, Marmaduke Johnson, P. C. Johnston, Kent, Kilby, Kindred, Lawson, Leake, Lewis, McComas, McGrew, McNeil, Macfarland, Chas. K. Mallory, Jas. B. Mallory,
                Marshall, Marr, Marye, Sr., Maslin, Masters, Moffett, Montague, Morris, Morton, Moore, Nelson, Orrick, Osburn, Parks, Patrick, Pendleton, Porter, Preston, Price, Pugh, Richardson, R. E. Scott, W. C. Scott, Seawell, Sharp, Sheffey, Sitlington, Slaughter, Southall, Speed, Spurlock, Staples, A. H. H. Stuart, C. J. Stuart, Strange, Summers, Sutherlin, Tarr, Tayloe, Thornton, Tredway, R.
                H. Turner, F. P. Turner, Tyler, Waller, White, Whitfield, Wickham, Willey, Williams, Wilson, Wise, Woods, Wysor-131.
              </p>
              <p>NOES-Messrs. Ambler, Berlin, Boggess, Harvie, Neblett-5.</p>
              <div className="speaker" id="sp2209"><span className="persName">Mr. SPEED</span>—</div>
              <p className="p-in-sp">
                I move to further amend the sixth resolution in the seventh and eighth lines by striking out the words "preserved in" and inserting the words "restored to." That part of the resolution will then read, "earnestly desiring that an adjustment may be reached by which the Union may be restored to its integrity, and peace, prosperity and fraternal feelings be restored throughout the land."
              </p>
              <div className="speaker" id="sp2210"><span className="persName">Mr. GOODE</span>, of Bedford—</div>
              <p className="p-in-sp">
                I think, if my friend will allow me a moment, he will accept a slight amendment which I wish to propose. In order to avoid the recurrence of the word "restored," I propose to substitute the word "re-established," where the word "preserved" now occurs. So that the section will then read : "Earnestly desiring that an adjustment may be reached by which the Union may be re-established in
                its integrity, and peace, prosperity and fraternal feelings be restored throughout the land."
              </p>
              <div className="speaker" id="sp2211"><span className="persName">Mr. SCOTT</span>, of Fauquier—</div>
              <p className="p-in-sp">I merely wish to say that I am exceedingly anxious to make this resolution conform to the wishes of the gentleman, and I trust it will be the pleasure of the Committee to indulge the gentleman from Bedford in this amendment, which does not detract from the resolution in the slightest degree.</p>
              <div className="speaker" id="sp2212"><span className="persName">Mr. WOODS</span>, of Barbour—</div>
              <p className="p-in-sp">I ask for the ayes and noes upon that question.</p>
              <p>Cries of "No," "no."</p>
              <p>The call for the ayes and noes was not sustained.</p>
              <p>The amendment of the gentleman from Bedford [<span className="persName">Mr. GOODE</span>] , was accepted by the gentleman from Campbell [<span className="persName">Mr. SPEED</span>] .</p>
              <p>The question was taken upon <span className="persName">Mr. SPEED</span>'S amendment, which was then adopted by the Committee.</p>
              <div className="speaker" id="sp2213"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The question now is upon the sixth resolution as amended.</p>
              <PageNumber num={192} />
              <div className="speaker" id="sp2214"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                I have so much amendment to offer to the verbiage of this resolution, that, with a view to save time, I shall move to strike out the whole of it, in order to reach all the changes of language and terms that I propose, without changing, except in one respect, the sense of it. I shall propose to strike out the word "deploring," and insert the words "affected by" in the first line. I
                did not come here to deplore. I propose to strike out the word, "lamenting," in the second line, and to insert the word "resisting" the wrongs, &amp;c.
              </p>
              <p className="p-in-sp">After the word "Union" I propose to add the words "in its integrity." The latter part of the resolution I propose to change, not substantially, but altering the language, and then to add, "And Virginia should not offer or adopt any terms of adjustment which ought not to be acceptable to the Seceded States and restore them to the Union."</p>
              <p className="p-in-sp">So that the resolution, if amended as I propose by the adoption of my substitute, would read :</p>
              <p className="p-in-sp">
                "Deeply affected by the present distracted condition of the country, and resisting the wrongs that have impelled some of the States to dissolve their connection with the Federal Government, but sensible of the blessings of the Union in its integrity, and impressed with the importance to the peace and prosperity of the people of preserving its faith, we indulge the earnest desire that
                an adjustment may be reached by which peace, prosperity and fraternal feelings throughout the land may be restored ; but Virginia should not offer or adopt any terms of adjustment which ought not to be acceptable to the Seceded States and restore them to the Union."
              </p>
              <div className="speaker" id="sp2215"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">I ask for a division of the question.</p>
              <div className="speaker" id="sp2216"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">If we are to have a division of the question, I do not propose to say any thing until the vote shall have been taken on striking out. If that motion shall be adopted, I then propose to offer some remarks upon the matter which I propose to insert.</p>
              <div className="speaker" id="sp2217"><span className="persName">Mr. PRICE </span>—</div>
              <p className="p-in-sp">Suppose the motion to strike out is rejected will that have the effect of adopting the section.</p>
              <div className="speaker" id="sp2218"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">It will, and no further amendment will be in order except by way of addition.</p>
              <div className="speaker" id="sp2219"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Then I will say what I have to say upon the motion as it now stands. I wish to give notice that if this motion shall prevail, I will then move an amendment such as I have in my hand to the tenth resolution.</p>
              <p className="p-in-sp">
                I do object to placing ourselves in the attitude of standing aloof, as it were in this controversy between the States, and simply deploring and lamenting our condition. We have got something more to do, <PageNumber num={193} /> as Virginians, than to deplore and to grieve. These wrongs which have impelled other States to secede from the Union, are your
                wrongs more your wrongs than those of any body else. You stand upon the frontier; you are in the front rank; in the vanguard; you are in the immediate presence of the enemy; in contact with their alignment; and what an attitude is it, when you come to the vital conflict; when the issue is the life or death of your institutions; of your social state; of your political compacts; of
                your republican existence what an attitude is it for you to stand in, your friends, behind you suffering less, further off from the enemy, less injured by the enemy than yourselves, cut loose from the Union—grant that they have been precipitate, that they have taken up your quarrels and theirs combined, the only complaint is that they have taken up the gauntlet rather too early for
                you—what an attitude, I say, is it that you should be standing here, shedding tears, declaring merely jeremiads of grief as if you were a mere spectator, standing aside, looking on in a contest in which you are not the greater and more responsible participant! Sir, you are obliged to say that you are affected by the present distracted condition of the country—affected more than to
                the extent and in the emotion of deeply deploring; you are deeply affected in interest, and you are deeply affected in other emotions; in more powerful emotions than grief.
              </p>
              <p className="p-in-sp">You ought to be affected even to indignation. You ought to be affected even to anger. But here we stand, wiping our eyes and weeping, while our friends South of us are moving into action with hearts too steeled, with countenances too stern to be weeping, puling and wiping their eyes at a moment when we are over the very crater of a revolution.</p>
              <p className="p-in-sp">
                Are you going to stand in that attitude?—the very women who are fit to be the mothers of Virginia's sons, will scorn you. Take, then, the attitude of manhood, not of old women. Make the declaration, firmly and manfully, that you are affected in all your emotions, in all your feelings, in all your passions, in all your thoughts, in all your interests deeply affected by the distracted
                state of the country; that you are not affected only in the single emotion of grief. Do not stop here saying over and over and over that you are wretched, urging again and again your lamentation over your woes; but say to your enemies, in the strain in which revolutionists ought to speak in which our fathers have set us an example of speaking—that you mean to resist—that you mean to
                resent these wrongs. They are yours; they are yours more than any body's else, because you have a larger interest in it than any <PageNumber num={194} /> body else. You are more in the immediate presence of the enemy. I say to you that you ought to enter the lists like men, and meet the enemy at the half way mark. I will never stand when I see the enemy
                approaching outside my lines. By the God of Battles, when he threatens invasion, I will leap the half way mark and carry the war into the enemy's country. That is your true position; and when you take it, the enemy will either meet you and bring the issue to a conflict and the conflict to a final termination ; or, if you are armed, as God Almighty has armed us in this cause, with
                justice, the enemy will retire. No puling, no weeping. It is not only maudlin and mawkish to use this language, but it is to me positively offensive, it is nauseating. Then, as to the last. How much time have I, Mr. Chairman?
              </p>
              <div className="speaker" id="sp2220"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">About one minute.</p>
              <div className="speaker" id="sp2221"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I could not say what I would say upon this latter point in one day, let alone one minute. I will give you that minute.</p>
              <div className="speaker" id="sp2222"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">Thank you, sir. The question is on the amendment of the gentleman.</p>
              <div className="speaker" id="sp2223"><span className="persName">Mr. CONRAD</span>, of Frederick—</div>
              <p className="p-in-sp">
                I did hope, Mr. Chairman, when under the rule of the Convention we had come to the point of voting upon these resolutions, that we had done with the King Cambyses views. Now, sir, I have no intention of replying to the eloquent remarks we have heard from the gentleman from Princess Anne, but I do trust that the gentlemen of the Committee will look for themselves at this resolution
                upon which they are now called to vote. If they agree with me that they do deplore the present distracted condition of the country; that they prefer peace to war, which I am sorry to find the gentleman does not, they will vote down the substitute he has offered, which does not accord with the general tone and current of the report of the Committee, and leave to be adopted this
                resolution, in every sentiment of which, I must say, I most heartily concur. I see no necessity for alteration. I confess I have not looked very particularly into the amendments proposed by the gentleman to see how far they would carry us, but I gathered enough as he read his substitute, to see that they do, to a considerable extent, change the tone and character of the resolution.
                Certainly, from the argument which the gentleman offered in behalf of his substitute, I could not really gather what his purpose was, but I am satisfied with the resolution as it stands, and I trust that the members of this Committee have so far examined it as to be satisfied with it, and that they will vote down the substitute of the gentleman from Princess Anne.
              </p>
              <div className="speaker" id="sp2224"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                I believe I have ten minutes, and, Mr. Chairman, I will <PageNumber num={195} /> take them myself. Why what has the gentleman urged? No reason, no argument. He has confessed that he did not understand the substitute at all. No, sir; but in his own King Cambyses vein he tells us—and he really ought to put both arms akimbo—that he thinks, really that the
                resolution ought to be adopted and that the substitute, which he does not understand, ought not to be. Is there no other argument than that? Yes, sir; one is intimated. He thinks that I have changed the tone of this resolution. That is precisely what I meant to change. To change an old woman into an armed man; a pretty material transformation. But, sir, my friend mistakes my tone and
                temper, when he supposes I am aiming at war. I am preparing only to keep the peace. That man of peace, imitating the Prince of Peace, George Washington, who nearer than any uninspired man on earth, imitated his Master in this respect, told us to be prepared for war. And you have need of it. Sir, a despatch this moment received, states that all correspondence with Washington has been
                cut off at Charleston; that supplies are forbidden to Fort Sumter, but that an attack will not be made until orders are received from Montgomery, unless an effort shall be made to reinforce.
              </p>
              <p className="p-in-sp">
                Are we in this danger? Is the spark so near the magazine? And are we to stand here weeping and puling like women and infants, or are we to stand more erect than ever? Which position will best keep the peace? Which will best cause the Government at Washington to withdraw its forces in time? A tone of Virginia which shall speak authoritatively, at the same time that it speaks
                pacifically; or, an attitude of Virginia which shall look on "lamenting" at these immediate dangers, which are more than distressing or distracting—which is the destructive policy? Which best will keep the peace—the namby-pambyism of deploring and lamenting, or the assertion that this is our quarrel, and that we must take it up if hostilities shall be commenced? Which will best keep
                the peace—the gentleman's submissiveness and tears, or a position firm and moderate? There is certainly nothing intemperate in saying that we are "deeply affected," instead of "deploring;" and that we mean to "resist" these wrongs, instead of simply lamenting them. There is nothing intemperate in that. There is no voice of Sempronius there. Not at all.
              </p>
              <p className="p-in-sp">But it is the latter clause of the amendment which affects my friend, and for which he cannot vote. He and I differ there toto ccelo.</p>
              <p className="p-in-sp">
                He will stay in a Northern Confederacy without the South, and I tell him I will not. It may suit his views of policy and his sympathies to stay with the North when divided from the South, and to become a disunionist in that form; for it seems that one kind of disunionism is to <PageNumber num={196} /> be sustained by gentlemen themselves, while another
                is not to be allowed. Division of the old Union, we remaining in the South, is disunion ; but division of the old Union, we remaining in the North, is not disunion. Now I cannot see the difference between the two species of disunionism.
              </p>
              <p className="p-in-sp">
                Gentlemen who mean to slough off the Southern States, who mean to soothe us and pacify us in a mediatorial, or deploring, or lamenting position, rather than in the position of declaring the cause which really is our cause, to be our cause, and taking it up as more our cause than that of the seceding States, prefer, if the Union is to remain divided, to belong to the Northern portion.
                We desire to belong, where we belong naturally, physically, morally, socially, and politically South of Mason and Dixon's line, possessed of 500,000 slaves, pro-slavery, co-sufferers of wrong, and pro-South. That is our position; and there would be no great objection perhaps, on the part of my friend
                [<span className="persName">Mr. ROB</span>'T Y. CONRAD]
                , were it not that in changing the verbiage, I alter the tone and temper of his resolution, and append the addendum that we will take no terms that will not or ought not to be acceptable to our friends—to those who have shown themselves so strongly our friends as even to outleap us in our own cause, to pass us so far that we do even reprehend them for what is fashionably called their
                "precipitation."
              </p>
              <div className="speaker" id="sp2225"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The gentleman's ten minutes is out.</p>
              <div className="speaker" id="sp2226"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Well, I am about "out," too.</p>
              <div className="speaker" id="sp2227"><span className="persName">Mr. BROWN</span>—</div>
              <p className="p-in-sp">I call for a division of the question, and for the yeas and nays on the first branch of it.</p>
              <p>
                The yeas and nays were ordered on striking out the 6th resolution. The question was taken and it was decided in the negative—yeas 57, nays 84, as follows :<span className="note" id="Note24"
                ><span className="noteLabel">4</span>The Journal lists 57 affirmative votes, among them L. S. Hall and Lewis E. Harvie ; the Enquirer seems to have telescoped these two names into "L. S. Harvie." The Journal lists 83 negative votes.</span
                >
              </p>
              <p>
                YEAS-Messrs. Ambler, James Barbour, Blakey, Boisseau, Borst, Bouldin, Branch, Bruce, Carlile, Cecil, Chambliss, Chapman, Coffman, Conn, Richard H. Cox, Fisher, Flournoy, Garland, Graham, Goggin, John Goode, Jr., Hale, Cyrus Hall, L. S. Harvie, Holcombe, Hunton, Isbell, Kent, Kindred, Leake, Charles K. Mallory, James B. Mallory, Marr, Marye, Montague, Morris, Morton, Neblett,
                Randolph, Richardson, Seawell, Sheffey, Slaughter, Speed, Strange, Sutherlin, Thornton, Tredway, Robert H. Turner, Franklin P. Turner, Tyler, Williams, Wilson, Wise, Woods, Wysor-57.
              </p>
              <p>NAYS-Messrs. Janney [President]
                , Armstrong, Aston, Alfred M. Barbour, Baylor, Berlin, Blow, Boggess, Boyd, Brent, Brown, Burdett, Burley, Byrne, Campbell, Caperton, Carter, Clemens, C. B. Conrad, Robert Y. Conrad, Couch, Critcher, Custis, Dent, Deskins, Dorman, Early, Echols, French, Fugate, Gillespie, Gravely, Gray, Addison Hall, Ephraim B. Hall, Hammond, Haymond, Hoge, Holladay, Hubbard, Hughes, Jackson,
                Marmaduke Johnson, P. C. Johnston, <PageNumber num={197} /> Kilby, Lawson, Lewis, McComas, McGrew, McNeil, Macfarland, Marshall, Maslin, Masters, Moffett, Moore, Nelson, Orrick, Osburn, Parks, Patrick, Pendleton, Porter, Preston, Price, Pugh, Robert E. Scott, Wm. C. Scott, Sharp, Sitlington, Southall, Spurlock, Staples, A. H. H. Stuart, Chapman J.
                Stuart, Summers, Tarr, Tayloe, Waller, White, Whitfield, Wickham, Willey-83.
              </p>
              <p>So the Convention refused to strike out the sixth resolution.</p>
              <div className="speaker" id="sp2228"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Is it in order now to move an addition to that resolution?</p>
              <div className="speaker" id="sp2229"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">If it does not affect the substance of the resolution it will be in order.</p>
              <div className="speaker" id="sp2230"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I would want to make no addition at all, if it did not affect it and add another idea to it.</p>
              <div className="speaker" id="sp2231"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">In the opinion of the Chair it is not in order.</p>
              <div className="speaker" id="sp2232"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I do not want to change the matter which has been adopted. I do not want to take anything from the substance of it, but I want to put on additional matter.</p>
              <div className="speaker" id="sp2233"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Chair has just expressed the idea that it is in order to add to here the resolutions as adopted, provided the addition proposed does not affect the substance of the resolution.</p>
              <div className="speaker" id="sp2234"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I propose to add this amendment :</p>
              <p className="p-in-sp">"But Virginia should not offer or adopt any terms of adjustment that ought not to be acceptable to the seceded States and restore them to the Union."</p>
              <div className="speaker" id="sp2235"><span className="persName">Mr. ROBERT Y. CONRAD</span>—</div>
              <p className="p-in-sp">I submit that the amendment is not in order under the ruling of the Chair. I understand that the vote on the motion to strike out has adopted the 6th resolution.</p>
              <div className="speaker" id="sp2236"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Chair has an impression that the Convention has adopted some rule in regard to the subject which changes the manual.</p>
              <div className="speaker" id="sp2237"><span className="persName">Mr. ROBERT Y. CONRAD</span>—</div>
              <p className="p-in-sp">
                I desire to submit another objection to the amendment, that the proposition has, in fact, just been voted upon by the Committee. It constituted a part of the substitute offered by the gentleman from Princess Anne, and the motion was to strike out in order to insert the substitute. Is it competent now to take a portion of that substitute and offer it as an addition to the resolution?
              </p>
              <div className="speaker" id="sp2238"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">On that point the gentleman is unquestionably inexperienced in parliamentary law. He confesses to his inexperience, and when he makes such a proposition as this, it must be looked over. The rule is this. Here is the Parliamentary law adopted by this body :</p>
              <p className="p-in-sp">
                "Any member may call for a division of the question, and it shall thereupon be divided, if it comprehends propositions in substance so distinct, that one being taken away, a substantive proposition shall remain <PageNumber num={198} /> for the decision of the House; but, a motion to strike out being lost, shall not preclude a motion to strike out and
                insert."
              </p>
              <div className="speaker" id="sp2239"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">That is one of the rules of the Convention. The Chair was acting, a little while back, on the manual, but as the Convention has changed the manual in that respect, of course the law of this Committee will be changed in conformity.</p>
              <div className="speaker" id="sp2240"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Permit me to say, too, that this is a different proposition, although it is in totidem verbis, the same one. Why? Because it is now disconnected from the other proposition. Non constat that gentlemen would not vote for it, standing alone, although they voted against it when connected with other matter.</p>
              <p className="p-in-sp">Some of my friends appear to have been frightened by what I said in regard to leaping over the half way mark, and therefore did not vote for my amendment. I will say now, that I will only go to the halfway mark, and shall not go across the line.</p>
              <p><span className="persName">Mr. BOISSEAU</span>, called for the yeas and nays on Mr. Wise's amendment.</p>
              <p>The yeas and nays were ordered.</p>
              <p>The question was taken and it was decided in the negative—yeas 64, nays 74, as follows :</p>
              <p>
                YEAS-Messrs. Ambler, James Barbour, Blakey, Boisseau, Borst, Bouldin, Branch, Bruce, Cecil, Chambliss, Chapman, Coffman, Conn, R. H. Cox, Critcher, Echols, Fisher, Flournoy, French, Garland, Graham, Goggin, John Goode, Hale, Addison Hall, Cyrus Hall, L. S. Hall, Holcombe, Hunton, Isbell, Kent, Kindred, Lawson, Leake, McNeil, Charles K. Mallory, James B. Mallory, Marr, Marye,
                Montague, Morris, Morton, Neblett, Orrick, Parks, Randolph, Richardson, Wm. C. Scott, Seawell, Sheffey, Slaughter, Speed, Strange, Sutherlin, Thornton, Tredway, R. H. Turner, F. P. Turner, Tyler, Williams, Wilson, Wise, Woods, Wysor-64.
              </p>
              <p>NAYS-Messrs. Janney [President]
                , Armstrong, Aston, Alfred M. Barbour, Baylor, Berlin, Blow, Boggess, Boyd, Brent, Brown, Burdett, Burley, Byrne, Campbell, Caperton, Carlile, Carter, Clemens, C. B. Conrad, Robert Y. Conrad, Couch, Custis, Dent, Deskins, Dorman, Early, Fugate, Gillespie, Gravely, Gray, Ephraim B. Hall, Hammond, Haymond, Hoge, Holladay, Hubbard, Hughes, Jackson, Marmaduke Johnson, P. C. Johnston,
                Kilby, Lewis, McComas, McGrew, Macfarland, Marshall, Maslin, Masters, Moffett, Moore, Nelson, Osburn, Patrick, Porter, Preston, Price, Pugh, R. E. Scott, Sharp, Sitlington, Southall, Spurlock, Staples, A. H. H. Stuart, C. J. Stuart, Summers, Tarr, Tayloe, Waller, White, Whitfield, Wickham, Willey-74.
              </p>
              <p>So the amendment was rejected.</p>
              <p>The question then recurred upon the adoption of the 6th resolution, as amended.</p>
              <PageNumber num={199} />
              <div className="speaker" id="sp2241"><span className="persName">Mr. WICKHAM</span>—</div>
              <p className="p-in-sp">I call for the yeas and nays upon the adoption of that resolution.</p>
              <p>The call was sustained, and the roll being called resulted, yeas 103, nays 31, as follows :</p>
              <p>YEAS-Messrs. Janney [President]
                , Armstrong, A. M. Barbour, Baylor, Berlin, Blow, Boggess, Bouldin, Boyd, Branch, Brent, Brown, Burdett, Burley, Byrne, Campbell, Caperton, Carter, Clemens, Coffman, C. B. Conrad, R. Y. Conrad, Couch, Critcher, Custis, Dent, Deskins, Early, Echols, Flournoy, French, Fugate, Garland, Gillespie, Gravely, Gray, Goggin, Addison Hall, Cyrus Hall, Ephraim B. Hall, Hammond, Haymond, Hoge,
                Holladay, Hubbard, Hughes, Jackson, Marmaduke Johnson, Peter C. Johnston, Kilby, Lewis, McComas, McGrew, Macfarland, Charles K. Mallory, James B. Mallory, Marshall, Marr, Marye, Maslin, Masters, Moffett, Moore, Nelson, Orrick, Osburn, Parks, Patrick, Pendleton, Porter, Preston, Price, Pugh, Randolph, Richardson, Robert E. Scott, Wm. C. Scott, Sharp, Sheffey, Sitlington, Slaughter,
                Southall, Speed, Spurlock, Staples, A. H. H. Stuart, C. J. Stuart, Summers, Sutherlin, Tarr, Tayloe, Thornton, Tredway, R. H. Turner, F. P. Turner, Tyler, Waller, White, Whitfield, Wickham, Willey, Wilson, Woods-103.
              </p>
              <p>NAYS-Messrs. Ambler, James Barbour, Blakey, Boisseau, Borst, Bruce, Carlile, Cecil, Chambliss, Chapman, Conn, Richard H. Cox, Fisher, Graham, John Goode, Hale, Holcombe, Hunton, Isbell, Kent, Kindred, Lawson, Leake, Montague, Morris, Morton, Neblett, Seawell, Strange, Williams, Wise-31.</p>
              <p>So the 6th resolution was adopted. The 7th resolution in the report was then taken up, for consideration. It reads as follows :</p>
              <p>
                7. To remove the existing causes of complaint much may be accomplished by the Federal and State Governments; the laws for the rendition of fugitives from labor and of fugitives from justice may be made more effectual, the expenditures of the Government may be reduced within more moderate limits and the abuses that have entered into the Administrative departments reformed. The State
                authorities may repeal their unfriendly and unconstitutional legislation, and substitute in its stead such as becomes the comity and is due to the rights of the States of the same Union. But to restore the Union and preserve confidence, the Federal Constitution should be amended in those particulars wherein experience has exhibited defects and discovered approaches dangerous to the
                institutions of some of the States.
              </p>
              <div className="speaker" id="sp2242"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I have another King Cambyses amendment to offer to this.</p>
              <p className="p-in-sp">The 7th resolution reads :</p>
              <p className="p-in-sp">"7. To remove the existing causes of complaint much may be accomplished by the Federal and State Governments."</p>
              <p className="p-in-sp">And then the resolution goes on to state what may be done :</p>
              <PageNumber num={200} />
              <p className="p-in-sp">"The laws for the rendition of fugitives from labor, and of fugitives from justice, may be made more effectual."</p>
              <p className="p-in-sp">What next?</p>
              <p className="p-in-sp">"The expenditures of the Government may be reduced within more moderate limits and the abuses that have entered into the Administrative departments reformed."</p>
              <p className="p-in-sp">What next?</p>
              <p className="p-in-sp">
                "The State authorities may repeal their unfriendly and unconstitutional legislation, and substitute in its stead such as becomes the comity and is due to the rights of the States of the same Union. But to restore the Union and preserve confidence, the Federal Constitution should be amended in those particulars wherein experience has exhibited defects and discovered approaches
                dangerous to the institutions of some of the States."
              </p>
              <p className="p-in-sp">Now, I propose to amend this by striking out all after the word "Governments," in the second line, to the word "Union," in the 10th line inclusive, so that the section will read :</p>
              <p className="p-in-sp">"7. To remove the existing causes of complaint much may be accomplished by the Federal and State Governments. But to restore the Union and preserve confidence, the Federal Constitution should be amended in those particulars wherein experience has exhibited defects and discovered approaches dangerous to the institutions of some of the States."</p>
              <div className="speaker" id="sp2243"><span className="persName">Mr. JOHNSON</span>, of Richmond—</div>
              <p className="p-in-sp">I propose to offer an amendment to the amendment of the gentleman from Princess Anne.</p>
              <div className="speaker" id="sp2244"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">When I am done, the gentleman will have an opportunity of offering his amendment.</p>
              <p className="p-in-sp">
                Mr. Chairman, the effect of the amendment proposed, will be simply to state the general proposition which I presume is sufficient, that much may be done by the Federal and State Governments; but not content with stating the general proposition that much may be done to remove existing causes of complaints, by Federal and State governments, this resolution goes on to state, and means
                and may be understood to mean to state the material things that may be done. Sir, if that is the express or implied understanding of this resolution, I say that it has not begun to state what may be done even independent of amendments of the Constitution. I know that the first part of the resolution undertakes to state merely what may be done by the administrative department. In that
                point of view even, it is a pitiful statement, a meagre statement of the existing causes of complaint. And it <PageNumber num={201} /> is but a meagre statement of the means of removing the existing causes of complaint.
              </p>
              <p className="p-in-sp">What is the first?</p>
              <p className="p-in-sp">Why the first complaint is only that about the glorious privilege of catching runaway slaves—one of the least, one of the last, one of the lowest of the rights that I claim upon this floor, or claim as a citizen of Virginia, or claim as a slaveholder, and that, sir, is stated only in the permissive sense.</p>
              <p className="p-in-sp">
                What is next? I am sorry to say that in the next there is a seeming of petty party spirit, at a time when party spirit ought to be silenced and rebuked, even as Satan was rebuked to silence by the Saviour on the Mount. Every man ought to say to it : "Get thee behind me, Satan." Why turn from the great critical questions of the hour—why turn from the great, radical, fundamental,
                constitutional differences that exist between us—why depart from the great social questions that divide us, and go into a question passe more than forty years ago yes, forty-six years old? The question was started by the Democratic party against the Adams administration of 1824. Did our constituents expect that when we came to settle the differences between the North and the South,
                that we were to leave these great fundamental grounds—these great chronic and constitutional diseases—and treat the mere party questions of economy and reform? Is it meant as an imputation upon the last administration? God knows that I am not here to defend it. You have weightier matters to attend to—you have bigger questions than this to settle—you have more important work to do
                than the settling of the accounts of the Land office, the Treasurer's office, the Collector's office, the Postoffice, or the Pension office. Before you go into mere administrative measures, you had better settle the matter of life and death. Before you go into the matter of accounts with mere ministerial and executive officers, you had better settle whether you are to have a
                government with officers, high or low.
              </p>
              <p className="p-in-sp">Then the third clause reads, "The state authorities may repeal their unfriendly and unconstitutional legislation."</p>
              <p className="p-in-sp">
                "The state authorities may repeal"—not in the imperative sense shall, not in the sense of duty and obligation ought, but in the milder sense, the third degree still, in the permissive and elective sense, the States that have nullified the fugitive slave act, "may repeal their unfriendly and unconstitutional legislation." Sir, when South Carolina in 1832-'33 nullified, was that the
                language you used towards her? No. You not only were imperious towards her, but you were belligerent towards her. You flew to your halls of legislation and under the shield <PageNumber num={202} /> of the popular Caesar of the day, with the Senate at his heels, you passed a force bill against her nullification; and now for nullification of the fugitive
                slave bill at the North, instead of declaring against their unconstitutional legislation in language imperative or authoritative, and instead of claiming obligations of duty from them, you want simply to say, softly, you may repeal your unconstitutional acts.
              </p>
              <p className="p-in-sp">
                Sir, their unconstitutionality and their unfriendliness toward us may soon come up before the Supreme Court of the United States. Another judge is dead; another vacancy is in the Supreme Court bench. This morning the papers announce the death of John McLean of Ohio. Let the great majority that elected Lincoln, put upon his tomb that thus far John McLean was the only man in our
                history that has put Abolitionism and Freesoilism into the decisions of a judge upon the Supreme bench. I have no doubt that the majority of the North who are to fill these two places at a moment when death has stricken down the battlements that defend you, and the parapets of the Supreme Court—
              </p>
              <p>Here the hammer fell.</p>
              <div className="speaker" id="sp2245"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Right exactly in the middle of a sentence. [Laughter.] Hurrah for the ten minutes rule.</p>
              <div className="speaker" id="sp2246"><span className="persName">Mr. WILSON</span>—</div>
              <p className="p-in-sp">I propose to amend the amendment by striking out the word "may" in the 4th line, and inserting the word "shall."</p>
              <div className="speaker" id="sp2247"><span className="persName">Mr. ROBERT E. SCOTT</span>—</div>
              <p className="p-in-sp">
                I beg leave to say to the gentleman from Princess Anne, that I am sure it was no part of the intention of any member of the Committee from which this report has emanated to incorporate in it one sentence, one word or syllable having the slightest reference to former party divisions. I confess that I am exceedingly at a loss, when I read this resolution, to perceive anything that can
                be tortured into such a construction as he has given it. We have heard complaints from gentlemen of all parties against the extravagance of the Federal Government. It is one of the chief causes of complaint alleged by Southern politicians, in justification of their secession movement. They complain of exorbitant tariffs and the rate of duties made necessary by extravagant
                expenditures; and it is of the unequal operation of high tariffs upon the Northern and the Southern sections of the country that the Southern people complain of. If you will read the debates in their recent Convention, you will find that they complain, not only of the extravagant expenditures of the Government, but of the unfaithful manner in which that government has been
                administered, not by this or the other party, but by all parties, who in modern times have exercised power.
              </p>
              <PageNumber num={203} />
              <p className="p-in-sp">The gentleman complained of the use of the word "may"—that it is "permissive." I would remind the gentleman from Princess Anne [<span className="persName">Mr. WISE</span>]
                , that this word "may" has many significations, and that its signification in any particular case must be determined by the context in which it is used. Does he not know that in the same connections it has an imperative sense, and means "shall?" Does he not know that it may have the signification of "can," "should" or "shall?" And can any one read this resolution without coming to
                the conclusion that the sense in which it is used here is the imperative sense? Suppose you strike out the word "may" and insert "must"—would it change the sense of the resolution? Suppose you strike it out and insert "should"—would it change the sense? In the connection that the word is here used, I submit that it has the sense of "shall."
              </p>
              <p className="p-in-sp">
                The resolution glances at certain causes of complaint, which it is supposed are within the jurisdiction of the Federal and State Government, which causes of complaint must be removed, in order to restore harmony. Does it not follow, therefore, that the true meaning of the resolution is that the action of these Governments must be such as to remove these causes, and I submit that
                though other words may be supplied, no other words will express the meaning, which is desired to be conveyed, more strikingly than the words objected to. Whether the Committee strike out the words in the manner proposed by the gentleman from Princess Anne, or retain them, I do not conceive to be a matter of any great importance. It seemed to me that these were prominent causes of
                complaint—the inefficiency of the Fugitive Slave Law, the want of that comity which States associated together in the same Union ought to extend to each other, and an extravagant Administration, and they have been pointed out by persons of all parties in the Southern Confederacy as prominent causes of complaint.
              </p>
              <div className="speaker" id="sp2248"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                I beg my friend from Fauquier to remember that I did not impugn the motives of any one. I merely complained that one of the three modes which he points out for removing causes of existing complaint, might reasonably be regarded as introducing party contest. But, sir, as the gentleman has alluded to it, permit me to say, if there is anything in man that is as blind as an adder and
                that has an adder's sting in it, it is party. Unconsciously to itself, it will manifest itself in action in moments like these. Sir, I cannot but see and feel that it exists here, that its influence is all around me, and somewhat in me. Does the gentleman suppose, if the Southern States had been of different politics from what they are or have been, that there would have been a
                marked general distinction between us in the questions <PageNumber num={204} /> that have arisen in relation to their secession? Do we not see the question of the tariff springing up on every side? Have we not seen internal improvements springing up like dragon's teeth in our path?
              </p>
              <p className="p-in-sp">
                Have we not seen gentlemen on this floor consume hours in the mere party discussion of administrative questions? Sir, at the very foundation of the Government there was a party, that pure, plain Republican party, that told you for fifty or sixty years—that has been telling you all the time—that if you allowed the Federalists to set up towers like those which characterized their
                advent, you would share the fate of the builders of Babel tower. Has not the prediction been sadly fulfilled? Grasping at empire—to make plain, simple republicanism become "monarch of all it surveys"—whatever may be the motive, the question still remains, who is accountable for the shabby splendor of that overgrown government which now requires large expenditures, and resorts to high
                tariffs in order to have protection? Is it an offence, on the part of the South, that they have been democratically opposed to high tariffs, democratically opposed to spanning continents with iron railways from ocean to ocean, democratically opposed to large expenditures, and all the paraphernalia of "splendid misery" and "shabby splendor," to use the language of Mr. Randolph, of a
                consolidated central despotism? Why stick it in here? I do not allude to this matter for party effect. We stand here, Whig and Democratic, joining hands on other questions than those of mere party—joining hands on other questions than those of mere administrative import. How is it that a State that has kept a standing army to defend State rights, from 1799 and 1800 up to this moment,
                is now questioning whether she will stand on her arms or not? Sir, to the other gentlemen opposed to me in politics, I wish to be distinctly understood that I am repelling party issues. I want to drive them out of this report. I honor the Whig who is a professed State rights man, who comes to me and proves himself to be as good a State rights man as I am. By his fruit I will judge
                him, and by my fruit he will judge me. These are the times to try the temper of the steel that is in men. Democrats who have been preaching State rights all their life time, are now brought to the test of manly patriotism. And some of the Whigs that have been denounced as Federalists heretofore, prove themselves to be true State rights men—as true as I can claim to be. On the other
                hand, Democrats on this floor have been led captive to the precepts of Federalism. Thus we stand divided—not on questions of party, but of fundamental principles. But in my conscience I believe, if the South was not a Democratic South, if the South was not an anti-tariff
              </p>
              <PageNumber num={205} />
              <p className="p-in-sp">
                South, if the South was not for a plain, economical, administrative Government, the South would not encounter much of the opposition now manifested on this floor. Some of us would be better content to go over to a Southern Confederacy rather than to remain in a Northern Confederacy. Those motives do not govern me, and certainly I am not imputing those motives to my friend from
                Fauquier
                [<span className="persName">Mr. SCOTT</span>]
                . I have no right to impute them to him, and I wish it to be distinctly understood that all I ask him is, if he does undertake to enumerate the means to remove the causes of the existing complaints, that he will avoid even the appearance of partisan appeals, and that he will enumerate all the means. He says these are the more prominent means. He says these are the more prominent
                means to remove the causes of complaint. I deny it. They are among the means. Sir, I cannot go on to show what are the means.
              </p>
              <div className="speaker" id="sp2249"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The gentleman's time has expired. Gentlemen, it is proposed to amend by substituting the word "shall," in the fourth line, for the word "may."</p>
              <p>The question was then put, and the motion was lost.</p>
              <div className="speaker" id="sp2250"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I ask for the ayes and noes on my amendment.</p>
              <div className="speaker" id="sp2251"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The question will be upon striking out, and upon that the ayes and noes are ordered.</p>
              <p>
                The vote was then taken, and the motion to strike out was carried by a vote of 68 to 66, as follows :<span className="note" id="Note25"><span className="noteLabel">5</span>The Journal lists 67 negative votes, adding Haymond and Summers to those given in the Proceedings.</span>
              </p>
              <p>
                YEAS-Messrs. Ambler, Armstrong, Blakey, Blow, Boisseau, Borst, Bouldin, Boyd, Branch, Brent, Bruce, Cecil, Chambliss, Chapman, Coffman, Conn, R. H. Cox, Echols, Fisher, Flournoy, Garland, Graham, Gravely, Goggin, Jno. Goode, Hale, Cyrus Hall, L. S. Hall, Harvie, Holcombe, Hunton, Isbell, Kent, Kilby, Kindred, Lawson, Leake, McNeil, Macfarland, C. K. Mallory, J. B. Mallory, Marr,
                Marye, Montague, Morris, Morton, Neblett, Orrick, Parks, Randolph, Richardson, Sheffey, Slaughter, Southall, Speed, Strange, Tayloe, Thornton, R. H. Turner, F. P. Turner, Tyler, Waller, Whitfield, Williams, Wilson, Wise, Woods, Wysor-68.
              </p>
              <p>NAYS-Messrs. Janney [President]
                , Aston, A. M. Barbour, Baylor, Berlin, Boggess, Brown, Burdett, Burley, Byrne, Campbell, Caperton, Carlile, Carter, Clemens, C. B. Conrad, R. Y. Conrad, Couch, Critcher, Custis, Dent, Deskins, Dorman, Early, French, Fugate, Gillespie, Addison Hall, Ephraim B. Hall, Hammond, Hoge, Holladay, Hubbard, Hughes, Jackson, Marmaduke Johnson, P. C. Johnston, McComas, McGrew, Marshall,
                Maslin, Masters, Moffett, Moore, Nelson, Osburn, Patrick, Pendleton, Porter, Preston, Price, Pugh, R. E. Scott, Wm. C. Scott, Sharp, Sitlington, Spurlock, Staples, A. H. H. Stuart, C. J. Stuart, Sutherlin, Tarr, White, Wickham, Willey-66.
              </p>
              <p><span className="persName">Mr. CHAMBLISS</span>, when <span className="persName">Mr. SEAWELL</span>'S name was called, said : I am requested, by <span className="persName">Mr. SEAWELL</span>, to state, that he was compelled to leave <PageNumber num={206} /> the hall on account of indisposition.</p>
              <div className="speaker" id="sp2252"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The question now occurs on adopting the resolution as amended.</p>
              <div className="speaker" id="sp2253"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">
                I move to amend the resolution by striking out the word "restore," in the tenth line and substituting the word "preserve." I take it for granted, sir, if the Union is broken up, confidence does not exist. I intend to follow this motion with another motion to strike out the word "preserve" in the next line, so as to make it read, "to preserve the Union and restore confidence."
              </p>
              <div className="speaker" id="sp2254"><span className="persName">Mr. BOISSEAU</span>, of Dinwiddie—</div>
              <p className="p-in-sp">
                I beg leave to say that if that amendment is adopted it will be directly in the teeth of the amendment adopted awhile ago to the sixth section. The word "preserved" in the seventh line of the Sixth section was stricken out, and the word "re-established" substituted. The Committee refused to entertain the idea that the Union could be preserved. It might be re-established ; and now the
                gentleman proposes to restore the word that has already been stricken out in this application.
              </p>
              <div className="speaker" id="sp2255"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">
                I did not vote for the amendment in the sixth section; but it is not inconsistent to adopt the amendment I propose to the seventh section. As it reads in the sixth section it is to "re-establish the Union in its integrity." Now, sir, the Union may exist and yet not exist in its integrity, and I think the terms are not in conflict with the words I propose to substitute. I don't think
                the Union is at an end, and therefore I shall prefer that word and shall vote for it.
              </p>
              <p>The question was then put, and the Committee refused to adopt <span className="persName">Mr. EARLY</span>'S amendment.</p>
              <div className="speaker" id="sp2256"><span className="persName">Mr. LEAKE</span>, of Goochland—</div>
              <p className="p-in-sp">I move, sir, to amend that seventh resolution by an addition at the end. The latter part of the resolution, as it stands, reads :</p>
              <p className="p-in-sp">
                "But to restore the Union and preserve confidence, the Federal Constitution should be amended in those particulars wherein experience has exhibited defects and discovered approaches dangerous to the institutions of some of the States." I propose to add : "And the people of the non-slaveholding States must abstain from all interference with the institution of slavery as it exists in
                any of the States or in any of the common territory; and there must be an entire abandonment of all the political organizations based upon the principle of hostility to Southern institutions."
              </p>
              <div className="speaker" id="sp2257"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The question will be upon the amendment.</p>
              <div className="speaker" id="sp2258"><span className="persName">Mr. LEAKE</span>—</div>
              <p className="p-in-sp">This section, sir, undertakes to say that the Union may be preserved and confidence restored by the amendment of the <PageNumber num={207} /> Constitution, when it is well known that the present distracted condition of the country has been brought about by the election of the Chief Executive of the United States.</p>
              <p>The hour of 2 o'clock having arrived, the remarks of the gentleman from Goochland were interrupted, and the Committee took a recess till 4 o'clock, P. M.</p>
            </div>
            <div className="section" id="vsc1965.v3.2.4.2">
              <h3><span className="headingNumber">1.4.2. </span><span className="head">EVENING SESSION</span></h3>
              <p>The Committee re-assembled at 4 o'clock.</p>
              <p><span className="persName">The CHAIRMAN</span> stated the pending question to be upon the amendment offered by <span className="persName">Mr. LEAKE</span> as an addition to the 7th section, as follows ;</p>
              <p>"The people of the non-slaveholding States must abstain from all interference with the institution of slavery as it exists in any of the States or in any of the common territory; and there must be an entire abandonment of all political organizations based upon the principles of hostility to Southern institutions."</p>
              <div className="speaker" id="sp2259"><span className="persName">Mr. LEAKE</span>—</div>
              <p className="p-in-sp">
                I apprehend, Mr. Chairman, that the business of this Convention is to discover what is the disorder of the times, and to apply the proper corrective. I think it is admitted—not on all hands, for there are some here who deny it—that there is just cause of complaint of interference upon the part of the North with the institutions of the South—that there is a settled hostility on the
                part of a large majority of the Northern people to the institution of slavery, which is the cause of the present distracted state of the country. Now this section of the report of the committee says it is to be restored by amendments to the Constitution. Sir, when we look at the amendments which are proposed in another part of this report we fail to find any such amendment proposed
                as to at all reach the heart of the disease. In the amendments proposed by the distinguished gentleman from Princess Anne
                [<span className="persName">Mr. WISE</span>]
                , to the Constitution of the United States, if they should be adopted, there is to a considerable extent a remedy for the evils which cause the present disturbance of the public mind. Those amendments, in my judgment, if adopted, will be calculated to restore harmony and peace to the country. But, sir, the disease which has called together this Convention, which has given that
                disturbance to the harmony and peace of the country, strikes its roots deeper than any constitutional provision. It is not a mere functionary derangement; it is chronic, it is deep-seated ; it is necessary for the Northern people to correct their sentiments upon the subject of slavery, it is necessary that they should abstain from intermeddling with the
                <PageNumber num={208} /> institution before any harmony or quiet can be restored. Sir, the State Government and Federal Government may do whatever belongs to them to restore peace and harmony, but it will be utterly impossible to accomplish, by any action of the State Government, or by any action of the Federal Government, a restoration of that harmony
                which ought to exist in a brotherhood of States, while sentiments are entertained, cultivated, encouraged and disseminated which are at war with the rights and institutions of one section of the country. Sir, no amendment has been propounded by any gentleman, which would reach that disturbance which occurred at the commencement of the first session of the last Congress of the United
                States, which was brought about by the effort of a part of the people of the representatives of the Northern States, to place in the Chair of the presiding officer of the House of Representatives a man who had endorsed that infamous book—the Helper Book—which had been put in circulation throughout the country for the purpose of stirring up the blood of the Northern people, and of the
                non-slaveholding portion of the Southern people, against the institution of slavery. No amendment of the Constitution of the United States can possibly reach a case of that kind. And again—no amendment to the Constitution of the United States can reach a case such as has but too recently transpired that of the election of a President of the United States, upon a question of
                sectionality and of sectional opposition to the institution of slavery. Is there any amendment of the Constitution of the United States which can prevent the President of the United States from sending to all the foreign governments, as ministers, men who are directly opposed to the rights and the institutions of the South, and who, at those foreign governments will use all their
                power for the purpose of slandering and injuring the institutions of the Southern people of the country in their estimation.
              </p>
              <p className="p-in-sp">
                Sir, what amendment to the Constitution is it that can possibly reach a case of this sort? And yet, gentlemen when they are proposing a basis by which these difficulties are to be settled between these different sections of the country, are failing to look to the very root of the disease, are failing to warn the North that it is these sentiments and feelings which have been
                engendered in that section of the country which have produced the disturbance that now exists ; and unless we do say to the North that they must abandon all their organizations which seek the destruction of our rights and our institutions, it is utterly impossible for us to look for the restoration of harmony and quiet.
              </p>
              <p className="p-in-sp">
                I admit that much may be accomplished by the adoption of such <PageNumber num={209} /> amendments as will secure to us all our rights—as will secure to us equal rights in the territories—as will secure the recognition of the institution of slavery everywhere. But, sir, still the Northern portion of the Confederacy are not disposed to recognize those
                rights. They will be governed by the "higher law" rather than the Constitution, and so long as they recognize the superior obligations of that "higher law," no amendments to the Constitution will restore harmony and peace to the country.
              </p>
              <div className="speaker" id="sp2260"><span className="persName">Mr. JOHNSON</span> of Richmond—</div>
              <p className="p-in-sp">
                I cannot, Mr. Chairman, give my assent to the amendment of my friend from Goochland, for it seems to me the gentleman himself contemplates the execution of a higher law. It seems to me that laying down the proposition that Virginia shall here declare that the people of a State or the people of a Territory, shall abstain from all interference with the question of slavery, no matter
                where it exists, the gentleman, in expressing these views, declares that no constitutional guarantees will suffice to satisfy him as a member of this Union; that he is for going beyond the reach of constitutional amendments, and of taking the law into his own hands, thereby adopting precisely what he complains of on the part of the people of the North—a higher law.
              </p>
              <p className="p-in-sp">
                Now, sir, I appeal to this Convention, how can we adopt such an amendment as that? How can we declare that the people of the States and Territories shall abstain from interference with slavery, no matter where it exists? How many people? The people of a county? The people of a district? The people of Kansas? The people of Washington, or of any other Territory? It seems to me that it
                resolves itself into a great higher law principle, that we will be content with nothing, unless we arrogate to ourselves the power of commanding every man in the United States. It is not for me to arrogate such a power. My most ambitious hopes will be most abundantly gratified, if I can obtain from the people of the United States—from a majority of the Northern people—their consent
                to such constitutional amendments as will give repose to this too much disturbed and distracted country. But, sir, it does seem to me that my friend from the county of Gooch-land is one of those of whom, I fear, there are a number here—and I say it with the kindest regard and the highest respect—who desire to throw difficulties and obstacles in the way of an adjustment of these
                questions, rather than to contribute their influence to advance the possibility of a satisfactory settlement. You cannot command, you cannot dictate to the people of the States or of the Territories. All that you can do—and if you accomplish that, you will have ac-
              </p>
              <PageNumber num={210} />
              <p className="p-in-sp">
                complished much more than any other deliberative body ever did do—is by your influence, now and here, to induce a sufficient majority of the people of all the States, to enter into guarantees such as shall put at rest those questions which have so long disturbed the peace and the happiness of the country. I trust, therefore, Mr. Chairman, it will not be the pleasure of this
                Convention, to say for the people of Virginia, that we announce to the people of all the States and Territories, that no matter where slavery exists, no matter under what circumstances, they shall not interfere with it. But if they do, then what? What is the remedy which my friend proposes? None at all. What does he propose to do if they do interfere? Nothing. How then will you be
                better off? If the gentleman had embodied in his amendment any remedy by which he could have brought them into terms of accommodation, I could have appreciated the force of his proposition. If the gentleman had accompanied his amendment with anything that could give quiet and repose to these questions, I would have gone with him, heart and hand. But he says the people shall not do
                it; none of the people shall do it. In other words, he proposes to take away the liberty of individual action and freedom of speech as to the people of the United States, notwithstanding the fact that a majority of the people of each State may, by sound, solid, governmental, constitutional amendment, have repudiated any interference with slavery where it exists. I say, it seems to me
                that we have not that right, and I trust it will not be the pleasure of the Committee to adopt the amendment of the gentleman from the county of Goochland.
              </p>
              <div className="speaker" id="sp2261"><span className="persName">Mr. LEAKE</span>—</div>
              <p className="p-in-sp">
                I feel confident that the gentleman from the city of Richmond has not comprehended the amendment which I have proposed, or he would not have urged the objections which he has urged to it. I am satisfied he has not paid full attention to the clause to which I propose my amendment, or he would have seen that the objections he urges to my amendment apply with equal force to that portion
                of the report to which I have proposed an amendment.
              </p>
              <p className="p-in-sp">
                Now, sir, his first objection is, that I propose to require that the people of the States and Territories, shall not interfere with the subject of slavery no matter how much it may be necessary and expedient that they should do so. Sir, this is not the amendment that I propose. My amendment is, that the people of the non-slaveholding States shall not interfere in any way with slavery
                in the other States, or in the common Territory of the United States. And am I to understand the argument of the gentleman from Richmond
                [<span className="persName">Mr. JOHNSON</span>]
                , as going to the length of contending that the people of the non-slaveholding <PageNumber num={211} /> States, must have reserved to themselves the privilege of interfering with the institution of slavery where it exists in the other States or in the common Territory where it exists? If not, there is no force in the objection which the gentleman urges
                against my amendment, for it is simply that the people of the non-slaveholding States shall not interfere with slavery. Is not that a sound position? Yet the gentleman from the City of Richmond
                [<span className="persName">Mr. JOHNSON</span>]
                , has argued against that position. He is for preserving for them, at some time or other, under some circumstances or other, that right to interfere. I trust that it is the sense of this body at no time, under no circumstances, no matter what may turn up, shall they be permitted to interfere with an institution which is not under their control, and which is beyond their jurisdiction.
                And I understand that it is the duty of this Convention to assert, on behalf of the people of Virginia, that the people of the non-slaveholding States shall, under no circumstances whatever, interfere with the institution of slavery, either in any State or in any Territory. Yet this is the opinion which my friend from Richmond has controverted. Sir, is it not a sound position? Yet he
                says that if we declare that they must not interfere, it is arrogating something to ourselves; it is appealing to a higher law.
              </p>
              <p className="p-in-sp">
                Mr. Chairman, my amendment only asserts what is necessary to be obtained in order to restore harmony. If the Committee adopt my amendment, they will, in effect, say that it is utterly impossible that harmony should be restored between the States unless the people of the Northern States abstain from interference with slavery. Is there any higher law in that? If that is higher law so
                is the Constitution of the United States, for there is nothing in it in conflict with the Constitution. It is that higher law which says that my individual rights and individual property shall be protected. And does the gentleman from the city of Richmond say that the Northern people shall have the right, under some sort of higher law, to interfere with my rights and my property?
              </p>
              <div className="speaker" id="sp2262"><span className="persName">Mr. JOHNSON</span> of Richmond—</div>
              <p className="p-in-sp">I wish simply to say that I do not occupy any such position. I cannot interrupt the gentleman to explain.</p>
              <div className="speaker" id="sp2263"><span className="persName">Mr. LEAKE</span>—</div>
              <p className="p-in-sp">
                Then, sir, the argument of the gentleman cannot hold water. I am satisfied that he has not looked at this amendment. Is there a gentleman in this House who is not for saying to the Northern people that they shall not interfere with slavery either in the States or territories? Yet the gentleman says that is asserting for ourselves a higher law which we complain of in others. Why, has
                not this report just before said "to remove the existing causes of complaint <PageNumber num={212} /> much may be accomplished by the Federal and State Governments," and the report in the first part of it says, that State Governments ought to remove the causes of complaint and members of this Committee have argued to show that the word "may" meant
                "must." In the report which my friend himself supports, is there nothing, which he voted for, declaring that the Northern States must remove these causes of complaint? Yet now, when it is desired further to add that, in order to restore harmony, in order that that good feeling which has heretofore existed among the States shall be restored, in order that we may live together in peace
                and quiet, and that these existing disturbances may be removed, there must be, hereafter no party organizations based upon the principle of hostility to our institutions. Because we say that, in a report which is merely laying down general principles, making merely general declarations of what are our rights, and what ought to be done under the circumstances that surround us, is the
                objection to be raised that we are asserting more than we are entitled to assert? I cannot see that a declaration of this sort can be offensive to any body upon the face of the earth, except the President of the United States and those that surround him. We are here making the declaration that the President of the United States now occupies a position to which he has been elevated by
                placing his pretensions to popular support upon hostility to the institutions of the South. And that state of feeling which has led to his election, has existed long at the North, and has been increasing from day to day until it has finally triumphed in placing him in power. And do you not find that he is using the powers with which he is invested in accordance with the principles
                upon which he was elected? And has the Virginia Convention no right to assert the mere declaration that those principles cannot be carried out and peace and harmony be restored?
              </p>
              <p className="p-in-sp">
                But my friend does not like this declaration, because he thinks I am not as much wedded to the Government of Abraham Lincoln as he may be. Well, sir, I confess that I have no very great attachment for an administration that has been elected upon the avowed principle that my State is not equal with the Northern States of this Union. I confess that I am not prepared to pay homage to
                any such power as that. I do confess, that unless the principles upon which this administration have been inaugurated and upon which it is conducted are to be overthrown, I want to seek connection with somebody else. Yes, sir, if there be an objection to this amendment, that it is proposed by one who is willing to seek communication with those alone
                <PageNumber num={213} /> where his rights, as an equal, shall be acknowledged, then it is a good objection. But I trust this Committee is not going to decide upon the merits of this amendment upon any such grounds. You are making declaration to the North—
              </p>
              <p>Here the hammer fell.</p>
              <p><span className="persName">Mr. LEAKE</span> called for the yeas and nays upon the adoption of his amendment.</p>
              <p>The yeas and nays were ordered.</p>
              <p>
                The question was taken and decided in the negative ; yeas 40, nays 93, as follows:<span className="note" id="Note26"><span className="noteLabel">6</span>The Journal lists 93 negative votes, adding Maslin and Wilson to those in the Proceedings.</span>
              </p>
              <p>
                YEAS--Messrs. Ambler, James Barbour, Blakey, Boisseau, Borst, Bouldin, Chambliss, Chapman, Conn, Fisher, Graham, Goggin, John Goode, Jr., L. S. Hall, Harvie, Holcombe, Hunton, Isbell, Kent, Kindred, Leake, J. B. Mallory, Montague, Morris, Morton, Neblett, Randolph, Richardson, Wm. C. Scott, Seawell, Speed, Strange, Thornton, R. H. Turner, F. P. Turner, Tyler, Williams, Wise, Woods,
                Wysor-40.
              </p>
              <p>NAYS-Messrs. John Janney [President]
                , Armstrong, Aston, Baldwin, A. M. Barbour, Baylor, Berlin, Blow, Boggess, Boyd, Branch, Brent, Brown, Bruce, Burdett, Burley, Byrne, Campbell, Caperton, Carlile, Carter, C. B. Conrad, Robert Y. Conrad, Couch, Critcher, Custis, Dent, Deskins, Dorman, Early, Echols, French, Flournoy, Fugate, Garland, Gillespie, Gravely, Gray, A. Hall, Cyrus Hall, E. B. Hall, Hammond, Haymond, Hoge,
                Holladay, Hubbard, Jackson, Marmaduke Johnson, Peter C. Johnston, Kilby, Lewis, McComas, McGrew, McNeil, Macfarland, Mallory, Marshall, Marr, Marye, Masters, Moffett, Moore, Nelson, Orrick, Osburn, Parks, Patrick, Pendleton, Porter, Preston, Price, Pugh, R. E. Scott, Sharp, Sheffey, Sitlington, Slaughter, Southall, Spur-lock, Staples, A. H. H. Stuart, C. J. Stuart, Summers,
                Sutherlin, Tarr, Tayloe, Waller, White, Whitfield, Wickham, Willey-93.
              </p>
              <p>So the amendment was disagreed to.</p>
              <p>The 7th section, as amended, was then agreed to.</p>
              <p>The 8th section was read, as follows :</p>
              <p>
                "8. The people of Virginia recognize the American principle that government is founded in the consent of the governed, and they concede the right of the people of the several States of this Union, for just causes, to withdraw from their association under the Federal Government with the people of the other States, and to erect new governments for their better security, and they will
                never consent that the Federal power, which is in part their power, shall be exerted for the purpose of subjugating the people of such States to the Federal authority."
              </p>
              <div className="speaker" id="sp2264"><span className="persName">Mr. PRICE</span>—</div>
              <p className="p-in-sp">I suppose it is competent to pass over the 8th section, and recur to it again subsequently, for the purpose of considering the 9th.</p>
              <PageNumber num={214} />
              <div className="speaker" id="sp2265"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">It may be done by general consent.</p>
              <div className="speaker" id="sp2266"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">I object.</p>
              <div className="speaker" id="sp2267"><span className="persName">Mr. PRICE</span>—</div>
              <p className="p-in-sp">I suppose it is competent to do it by motion.</p>
              <div className="speaker" id="sp2268"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">It is.</p>
              <div className="speaker" id="sp2269"><span className="persName">Mr. PRICE</span>—</div>
              <p className="p-in-sp">
                I move then that the 8th resolution for the present be passed over. I know that some gentlemen desire to amend the 8th, and that others desire to amend the 9th. Now with a view of obviating the objection to the 8th, I desire a modification to the 9th, and for the purpose of ascertaining whether that can be so modified as to make the 8th acceptable, I move to pass over the 8th.
              </p>
              <div className="speaker" id="sp2270"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I object.</p>
              <div className="speaker" id="sp2271"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Chair supposes a majority would have the right to pass by any resolution, with a view to going back and acting upon it subsequently.</p>
              <div className="speaker" id="sp2272"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">Did not the Chair decide under the resolution of the Convention, that we would have to go on with this report seriatim, that no proposition could be taken up out of its order?</p>
              <div className="speaker" id="sp2273"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Chair does not recollect any such decision. The Chair supposes that the Committee may, in their discretion pass by any resolution.</p>
              <div className="speaker" id="sp2274"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">Then you could pass over the whole report, and go to something else.</p>
              <div className="speaker" id="sp2275"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp"><span className="date"></span>It is for the majority to say whether they will pass by this eighth resolution.</p>
              <div className="speaker" id="sp2276"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">I call for the reading of the resolution we adopted in Convention.</p>
              <p>The Secretary read as follows :</p>
              <p>Resolved, That on and after Monday next, until further ordered, this Convention shall be called to order at ten o'clock, A. M. At half past ten, it shall resolve itself into a Committee of the Whole upon the report of the Committee on Federal Relations; and, at two o'clock, said Committee shall take a recess until four o'clock, when it shall resume its session.</p>
              <div className="speaker" id="sp2277"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Chair supposes, perhaps, that is not the resolution to which the gentleman refers.</p>
              <div className="speaker" id="sp2278"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">
                Yes, sir, that is the resolution to which I refer, and the Chairman stated that this Committee had no right to adopt any rule; that its rules of proceeding had been prescribed by the Convention, in consequence of which, even a motion to rise could not be entertained before 2 o'clock. You now propose that the Committee shall pass by one resolution and go to another. If you can pass by
                one <PageNumber num={215} /> can you not pass by the whole and take some other subject besides the report of the Committee on Federal Relations? I, therefore, contend that we have no right to pass by the resolution.
              </p>
              <div className="speaker" id="sp2279"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">Does the gentleman appeal from the decision of the Chair?</p>
              <div className="speaker" id="sp2280"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">No, sir.</p>
              <div className="speaker" id="sp2281"><span className="persName">Mr. DORMAN</span>—</div>
              <p className="p-in-sp">
                I think it important that we should understand correctly the operation of the order adopted by the House. It is perfectly clear from the authorities in parliamentary law, and from the practice in the Convention of 1850, that according to ordinary rules, the Committee has a perfect right to pass by any resolution at any time, and unless that rule is changed by the specific order of
                the Convention it must stand as the order of proceeding.
              </p>
              <div className="speaker" id="sp2282"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Chair deems this discussion entirely out of order, unless there be an appeal taken from the decision of the Chair. The question being on <span className="persName">Mr. PRICE</span>'S motion, to pass by the eighth resolution.</p>
              <p><span className="persName">Mr. WISE</span> called for the question.</p>
              <p>The question was ordered.</p>
              <p>The question was taken and it was decided in the affirmative—Yeas 68, Nays 67, as follows:</p>
              <p>AYES-Messrs. Janney [President]
                , Armstrong, Aston, Baldwin, Baylor, Berlin, Blow, Boggess, Boyd, Branch, Brown, Burdett, Burley, Byrne, Campbell, Caperton, Carter, C. B. Conrad, R. Y. Conrad, Couch, Critcher, Dent, Deskins, Early, French, Fugate, Gillespie, Gravely, Gray, Addison Hall, Ephraim B. Hall, Hammond, Haymond, Hoge, Holladay, Hubbard, Jackson, P. C. Johnston, Lewis, McComas, McGrew, McNeil, Marshall,
                Maslin, Masters, Moore, Osburn, Patrick, Pendleton, Porter, Preston, Price, Pugh, R. E. Scott, Wm. C. Scott, Sharp, Sitlington, Spurlock, Staples, A. H. H. Stuart, C. J. Stuart, Summers, Tarr, Tayloe, Waller, White, Wickham, Willey-68.
              </p>
              <p>
                NOES-Messrs. Ambler, J. Barbour, Blakey, Boisseau, Borst, Bouldin, Brent, Bruce, Carlile, Chambliss, Chapman, Coffman, Conn, Custis, Dorman, Echols, Fisher, Flournoy, Garland, Graham, Goggin, J. Goode, Cyrus Hall, L. S. Hall, Holcombe, Hunton, Isbell, M. Johnson, Kent, Kilby, Kindred, Lawson, Leake, Macfarland, C. K. Mallory, J. B. Mallory, Marr, Marye, Miller, Moffett, Montague,
                Morris, Morton, Neblett, Nelson, Orrick, Parks, Randolph, Richardson, Seawell, Sheffey, Slaughter, Southall, Speed, Strange, Sutherlin, Thornton, Tredway, Robert H. Turner, Franklin P. Turner, Tyler, Whitfield, Williams, Wilson, Wise, Woods, Wysor-67.
              </p>
              <p>So the Committee agreed to pass by the eighth resolution.</p>
              <p>The ninth resolution then came up for consideration, and was read as follows :</p>
              <p>
                "The exercise of this right by the States of South Carolina, Georgia, <PageNumber num={216} /> Florida, Mississippi, Alabama, Louisiana and Texas, without the assent of the other States, has given rise to new conditions, and presented questions touching those conditions, intimately affecting the rights and safety of the other States. Among these are the
                free navigation of the Mississippi river, the maintenance of the forts intended to protect the commerce of the Gulf of Mexico, and the power to restrain smuggling along the interior borders of the seceded States; but the Federal authorities, under the Constitution as it is, disclaim power to recognize the withdrawal of any State from the Union and consequently to deal with these
                questions, holding that it is reserved only to the States as parties to the government to take lawful action touching them."
              </p>
              <div className="speaker" id="sp2283"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">The first line in the ninth resolution reads, "The exercise of this right." What right is there referred to? Something in the eighth resolution that has been passed by. Are we in nubibus, sir? I ask if this ninth resolution attaches itself to anything else but that which we have passed by?</p>
              <div className="speaker" id="sp2284"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">It is not for the Chair to decide.</p>
              <div className="speaker" id="sp2285"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                I call the attention of the Chair to the fact that, having passed by the eighth resolution, we cannot take up the ninth, because the ninth is a part of the eighth. We must pass that by, too, and take up something independent of both eighth and ninth. The one reads with the other. The old doctors did, I believe, divide the psalms and the Bible generally into verses, and yet did not
                make them independent. Following that example, the Committee has divided the eighth resolution in two, although the eighth and ninth read as to one. Now, that we have passed the eighth, however, it does not follow that we go to the ninth. We have to skip over the ninth, too.
              </p>
              <div className="speaker" id="sp2286"><span className="persName">Mr. PRICE</span>—</div>
              <p className="p-in-sp">If the gentleman from Princess Anne, will permit an amendment to be offered, he will see that it strikes the part of the ninth resolution to which he refers.</p>
              <div className="speaker" id="sp2287"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">Does the gentleman from Princess Anne yield the floor?</p>
              <div className="speaker" id="sp2288"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">No, sir. I make the point that no amendment can be offered to the ninth resolution, because, in fact and in consecutive grammatical construction, it is a part of the eighth, which has been passed by. You may number this the ninth if you please, but still it is part of the eighth and so connected with it that they cannot be severed.</p>
              <div className="speaker" id="sp2289"><span className="persName">Mr. ROBERT Y. CONRAD</span>—</div>
              <p className="p-in-sp">I can probably remove the difficulty of the gentleman from Princess Anne, by moving an amendment to this ninth section.</p>
              <PageNumber num={217} />
              <div className="speaker" id="sp2290"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">That is exactly what I am objecting to.</p>
              <div className="speaker" id="sp2291"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The gentleman has a right to do that, if the gentleman from Princess Anne, will yield the floor.</p>
              <div className="speaker" id="sp2292"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I object to any amendment of the ninth section, because it reads as a part of the eighth.</p>
              <div className="speaker" id="sp2293"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Chair will entertain an amendment. It is for the Committee to dispose of it.</p>
              <div className="speaker" id="sp2294"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Then I understand that the Chair overrules my objection.</p>
              <div className="speaker" id="sp2295"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Chair decides that the ninth resolution is now open to amendment.</p>
              <div className="speaker" id="sp2296"><span className="persName">Mr. R. Y. CONRAD</span>—</div>
              <p className="p-in-sp">I move to amend the ninth resolution by striking out the words "exercise of this right" and inserting in lieu thereof "withdrawal from the Federal Government."</p>
              <div className="speaker" id="sp2297"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                I ask whether the object of this amendment is not simply to remove the objection of proceeding with the ninth resolution because it is the continuous matter of the eighth resolution, and whether it is not moved expressly to avoid that objection? If so, I humbly submit that the amendment is not in order. It is ex post facto. You have agreed to pass by the matter of the eighth
                resolution, and if the matter of the eighth continues with the ninth, then logically and by every fair construction, having passed by the eighth the ninth is also passed by. It is in pari materia with the eighth. In fact it is the same matter and was intended to be the same.
              </p>
              <div className="speaker" id="sp2298"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">Does the Chair understand that the gentleman from Princess Anne is objecting to the amendment as out of order.</p>
              <div className="speaker" id="sp2299"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Yes.</p>
              <div className="speaker" id="sp2300"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">And raise, on that, a point of order?</p>
              <div className="speaker" id="sp2301"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Certainly.</p>
              <div className="speaker" id="sp2302"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">Will the gentleman be kind enough to reduce his point of order to writing, as the Chair does not exactly understand it.</p>
              <div className="speaker" id="sp2303"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I have completed my primer of points. They are as follows :</p>
              <p className="p-in-sp">
                "The Committee of the Whole, by vote, decided to pass by the 8th resolution of the pending report, without deciding to what subsequent resolution of the report it would pass to the consideration of; the next resolution—the 9th—commences in these words: 'The exercise of this right'—referring to a right declared in the 8th resolution, which was passed by. To obviate this, the gentleman
                from Frederick moved to amend the 9th by striking out the words, 'the exercise of this right,' relating to the 8th resolution, and to insert words making the 9th <PageNumber num={218} /> independent of the 8th resolution. The point submitted is, that this resolution proposes to amend the matter of the 8th, which has been passed by."
              </p>
              <div className="speaker" id="sp2304"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Chair overrules the point of order.</p>
              <div className="speaker" id="sp2305"><span className="persName">Mr. R. Y. CONRAD</span>—</div>
              <p className="p-in-sp">I desire to state here—as that point of order may appear in some way upon the journal—that the facts stated there are certainly not conceded on my part. It was not without assigning any reason that the motion was made by the gentleman from Greenbrier [<span className="persName">Mr. PRICE</span>] , to pass by the 8th resolution, but it was expressly with a view to move an amendment to the 9th resolution, and this very amendment which I do move.</p>
              <div className="speaker" id="sp2306"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I certainly am correct that the motion was not to pass by the 8th to the 9th, or to any other resolution. I don't care about the statement ore tenus on the floor; I make my point of order by the journal.</p>
              <div className="speaker" id="sp2307"><span className="persName">Mr. CONRAD</span>—</div>
              <p className="p-in-sp">I understood, Mr. Chairman, the gentleman from Greenbrier [<span className="persName">Mr. PRICE</span>]
                , who can speak for himself, to state that some proposition should be made to amend the 9th resolution, and it was supposed that the necessity for any amendment to the 8th resolution might be obviated by passing on to the 9th and amending that, and, therefore, the reason for his motion, as I understood, was to reach the 9th, in order to amend it and reflex back upon the 8th.
              </p>
              <div className="speaker" id="sp2308"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Will the Clerk read the motion of the gentleman from Greenbrier as it was entered on the journal. That is the test.</p>
              <div className="speaker" id="sp2309"><span className="persName">The SECRETARY</span>—</div>
              <p className="p-in-sp">The motion reads, "I move that the 8th resolution be passed by for the present, with a view of considering the 9th resolution."</p>
              <div className="speaker" id="sp2310"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">It was not so made.</p>
              <div className="speaker" id="sp2311"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Chair certainly understood the motion to be made as the journal has it.</p>
              <div className="speaker" id="sp2312"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I certainly did not understand the motion to be made in that form. Did the Secretary make the entry himself?</p>
              <div className="speaker" id="sp2313"><span className="persName">The SECRETARY</span>—</div>
              <p className="p-in-sp">The entry was made by the assistant Secretary at the time the motion was made.</p>
              <div className="speaker" id="sp2314"><span className="persName">Mr. CONRAD</span>—</div>
              <p className="p-in-sp">The motion may require, perhaps, a word of explanation from me.</p>
              <p className="p-in-sp">
                The 8th resolution, sir, asserts the right of the people of the several states of this Union, for just cause to withdraw from their association under the Federal Government. The 9th resolution immediately following commences : "The exercise of this right by the State of South Carolina," &amp;c., and taking the two together, it was supposed
                <PageNumber num={219} /> by some that the right asserted of withdrawing was that peaceable or constitutional right of secession which it was understood, these Southern States have asserted in their act of withdrawal; and in order to remove that difficulty in the minds of some members in the Convention, it is proposed not to connect the two resolutions
                at all, but to strike out these words—"The exercise of this right," and leave the fact of withdrawal stated without determining upon what particular right this act of these seceding States was done.
              </p>
              <div className="speaker" id="sp2315"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I ask for the yeas and nays.</p>
              <p>The call for the yeas and nays was sustained.</p>
              <div className="speaker" id="sp2316"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">The motion is made upon the ground, as I understood the gentleman from Frederick [<span className="persName">Mr. R. Y. CONRAD</span>] , distinctly to announce, that although the ninth resolution admits "the exercise of this right by the States of South Carolina," &amp;c., as expressed now, he means to put it in words that will neither avow nor disavow the right. He refuses to assert the right. Then, the purpose is to ignore the right of the States to withdraw.</p>
              <p>The roll was then called upon the proposition of the gentleman from Frederick [<span className="persName">Mr. CONRAD</span>]
                , and resulted—yeas 76, nays 58, as follows : <span className="note" id="Note27"><span className="noteLabel">7</span>The correct number of affirmative votes is probably 77, as it is in the Journal.</span>
              </p>
              <p>YEAS-Messrs. Janney [President]
                , Armstrong, Aston, Baldwin, A. M. Barbour, Baylor, Berlin, Blow, Boggess, Boyd, Branch, Brown, Burdett, Burley, Byrne, Campbell, Caperton, Carlile, Carter, C. B. Conrad, R. Y. Conrad, Couch, Critcher, Custis, Dent, Deskins, Dorman, Early, French, Gillespie, Gray, Addison Hall, Ephraim B. Hall, Hammond, Haymond, Hoge, Holladay, Hubbard, Jackson, Marmaduke Johnson, Peter C. Johnston,
                Kilby, Lewis, McComas, McGrew, McNeil, Macfarland, Marshall, Maslin, Masters, Moffett, Moore, Nelson, Osburn, Patrick, Pendleton, Porter, Preston, Price, Pugh, R. E. Scott, Sharp, Sitlington, Southall, Spurlock, Staples, A. H. H. Stuart, C. J. Stuart, Summers, Tarr, Tayloe, Waller, White, Whitfield, Wickham, Willey, Wilson-76.
              </p>
              <p>
                NAYS-Messrs. Ambler, J. Barbour, Blakey, Boisseau, Borst, Bouldin, Brent, Bruce, Chambliss, Chapman, Coffman, Conn, Echols, Fisher, Flournoy, Garland, Graham, Gravely, Goggin, J. Goode, Hale, Cyrus Hall, L. S. Hall, Harvie, Holcombe, Hunton, Isbell, Kent, Kindred, Lawson, Leake, C. K. Mallory, J. B. Mallory, Marr, Marye, Sr., Miller, Montague, Morris, Morton, Neblett, Orrick, Parks,
                Randolph, Richardson, Seawell, Sheffey, Speed, Strange, Sutherlin, Thornton, Tredway, R. H. Turner, Franklin P. Turner, Tyler, Williams, Wise, Woods, Wysor-58.
              </p>
              <div className="speaker" id="sp2317"><span className="persName">Mr. PRICE</span>—</div>
              <p className="p-in-sp">Is it necessary to make a motion now to recur to the eighth resolution?</p>
              <div className="speaker" id="sp2318"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">No, sir.</p>
              <div className="speaker" id="sp2319"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Is not the ninth resolution perfected now, sir?</p>
              <PageNumber num={220} />
              <div className="speaker" id="sp2320"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Chair cannot say, sir. The question now is on agreeing to the resolution as amended.</p>
              <div className="speaker" id="sp2321"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">
                I propose to offer an essential amendment to the 9th resolution. I propose to strike out from the word "States" in the 6th line to the close of the resolution, and to substitute what I shall presently read. It will be perceived by the Committee that the resolution asserts that the withdrawal of the States of the Southern Confederation has given rise to many questions. The resolution
                then proceeds to refer to those questions without giving a full enumeration, as is obvious upon inspection. Then the resolution proceeds to assert that the Federal authorities disclaim the power to recognize the withdrawal of any State from the Union, and the power consequently to deal with the questions growing out of this withdrawal.
              </p>
              <p className="p-in-sp">Now, for one, sir, I am not prepared to affirm that the Federal Government does disclaim a power, the disclaimer of which is the foundation of the allegation found in the resolution. I cannot undertake to say that the Government feels itself incompetent to deal with the question which has occurred.</p>
              <div className="speaker" id="sp2322"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">Will the gentleman allow the Chair to understand the amendment.</p>
              <div className="speaker" id="sp2323"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">I have just stated, sir, that I propose to strike out all after the word "States," in the 9th resolution.</p>
              <div className="speaker" id="sp2324"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">Does the gentleman propose to insert anything as a substitute?</p>
              <div className="speaker" id="sp2325"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">Yes, sir, but I prefer, before submitting what I propose to substitute, to make a few preliminary remarks.</p>
              <div className="speaker" id="sp2326"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">It is not in order for the gentleman to proceed with any remarks before he submits his proposition.</p>
              <div className="speaker" id="sp2327"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">I propose to insert in lieu of what I have just proposed to strike out, the following: "to the adjustment whereof, and to the recognition of the independence of the Confederate States of the South, or their restoration to their former relations as negotiation may eventuate, the Federal Government is hereby called upon to address itself."</p>
              <div className="speaker" id="sp2328"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Secretary will now read the portion of the resolution proposed to be stricken out.</p>
              <p>The secretary read as follows :</p>
              <p>
                "Among these are the free navigation of the Mississippi River, the maintenance of the forts intended to protect the commerce of the Gulf of Mexico, and the power to restrain smuggling along the interior borders of the seceded States; but the Federal authorities <PageNumber num={221} /> under the Constitution as it is, disclaim power to recognize the
                withdrawal of any State from the Union and consequently to deal with these questions, holding that it is reserved only to the States as parties to the Government compact to take lawful action touching them."
              </p>
              <div className="speaker" id="sp2329"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">
                I was proceeding to call the attention of the Committee, Mr. Chairman, to the consideration that the enumeration of the questions growing out of the secession of the Southern States was by no means full and complete, and the enumeration is singularly unhappy in omitting the most important of those reasons. Then I adverted to the consideration that the resolution affirmed the
                disclaimer of powers by the Federal Government of which I am not aware there is any satisfactory evidence. I, therefore, think that there is a manifest propriety in dropping so much of the resolution.
              </p>
              <p className="p-in-sp">
                Then, Mr. Chairman, to explain what I propose to substitute. It will be remarked that it calls upon the Federal Government to exert itself in restoring the retired States to their former position, if negotiation can effect it; at all events, to treat and negotiate with these States. Now, I apprehend that whilst there may be differences of opinion in respect to an immediate
                recognition of the independence of the Southern States, there can hardly be any diversity of opinion in respect to the propriety and expediency of so far recognizing their position as to enter into conference and negotiation with them. My own conviction is, that the Federal Government committed a great mistake in not receiving commissioners from the South, when they first appeared in
                Washington. Manifestly, there is an impediment to any understanding, so long as the Federal Government refuses to acknowledge, or even to recognize their actual condition.
              </p>
              <p className="p-in-sp">
                Sir, the federal government has acted upon the principle that it cannot know what is known by all the world besides, viz that the seceded States are in a condition of de facto independence. If the federal government and these States are to be brought together, it is indispensable that there should be communication between them, necessary that negotiations be begun and that they
                should be conducted upon the basis of reciprocal respect. It is within the historical recollection of us all that in the early stages of our Revolutionary war, the thing which of all others most offended the national pride of the country was the refusal of the British authorities to acknowledge the title of Washington. It put an end to attempts at negotiation.
              </p>
              <p className="p-in-sp">
                Now, sir, the amendment proposed simply enunciates a proposition, the propriety of which, I trust, will address itself to the good judgment of the committee. It asserts that it is expedient that the Federal <PageNumber num={222} /> Government enter into negotiations with the Southern States hold communication with them for the purpose of ascertaining
                whether their restoration to their ancient position, or their actual independence, shall ensue.
              </p>
              <p className="p-in-sp">I beg further to say, if it be the pleasure of the Committee to adopt the amendment, that I propose to follow it up with a proposition to drop the whole of the tenth resolution, as the proposition will dispose of both.</p>
              <div className="speaker" id="sp2330"><span className="persName">Mr. R. Y. CONRAD</span>—</div>
              <p className="p-in-sp">
                The proposition which is made by the gentleman from Richmond, is to strike out what I regard as the most important feature of this portion of the Committee's report. The gentleman states that he is not aware that the Federal authorities have disclaimed the power to recognize the withdrawal of any State from the Union, and yet, in the same breath, almost, he complains that the Federal
                authorities do positively refuse to recognize the fact, upon the ground that they have no authority to recognize the facts. I certainly so understood the gentleman ; but whether he so stated it or not, we know that the present Federal authority does disclaim the right or power to recognize the withdrawal of any State from the Union, and the late Executive of the United States, Mr.
                Buchanan, made the distinct disclaimer. I hold it of importance, not merely to avail ourselves of this disclaimer by the Executive of the United States, but to maintain the truth of the position which they have assumed, viz—that the federal authority has no right to deal with this fact of withdrawal of one or more States from the federal authorities, because, whilst they do disclaim
                the right to recognize the fact of withdrawal, the right to negotiate with these withdrawing States, they assert the power to deal with the subject in the other and most dangerous aspect, that is, the power to deal with them forcibly, in order to prevent a withdrawal of any State from the Union, disclaiming, at the same time, the power to negotiate and make peace with the seceding
                States. And in order to form a rational basis for the protest of Virginia, the Committee have reported these resolutions, for which the gentleman from Richmond
                [<span className="persName">Mr. MACFARLAND</span>] , now proposes a substitute, pledging the power of this State to interpose in case of any attempt to subjugate or coerce these seceded States.</p>
              <div className="speaker" id="sp2331"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">If the Committee will indulge me I will reply to the objections which have been made by the gentleman from Frederick [<span className="persName">Mr. CONRAD</span>]
                . I apprehend, Mr. Chairman, and I desire to call the attention of the Committee distinctly to the statement, that all that can be accurately or truly predicated of what has transpired in <PageNumber num={223} /> Washington between the Government and the States of the Southern Confederacy is, that the Government has so far simply declined accrediting
                the Commissioners from the South. That we all know, but whether it proceeded from an indisposition of the Government to exercise its power, or upon whatsoever ground it may have proceeded, there certainly is not such evidence as to satisfy our official judgment, and enable us to declare.
              </p>
              <p className="p-in-sp">
                Sir, I do not agree with the gentleman, that the power to treat with these States with a view to the acknowledgment of their independence was distinctly disclaimed or renounced in the message of President Buchanan. And yet I apprehend, sir, that if there had been such a disclaimer, it could hardly be regarded as indicating the fixed, unchangeable policy of the government. But that
                administration has passed away, and I should like to know if there be any thing in the nature of an explicit declaration on the part of the present administration to satisfy gentlemen that it has disclaimed the power to treat with the Southern States?
              </p>
              <p className="p-in-sp">
                And then I take issue with the gentleman distinctly upon the proposition that the Federal Government have not the power to treat with these States. For myself, I believe that the Government has now the power, without further legislation, certainly with it, to provide for the public peace by consenting to pacific measures with the Southern States. Does any one doubt that? Can it be
                for a moment supposed that the administration is not at liberty to make terms with the Southern States, by which the peace, for a time, at least, will be preserved? Can there be a doubt upon that subject? It will not be said that the Government is under a necessity to proceed to hostilities with these Southern States. The Government, then, may forbear from hostilities. It may forbear
                under the formalities of a distinct and solemn avowal. Is it conceivable that a great State policy may be instituted without authority in the Government to pledge itself to adhere to it, and if hostilities may be avoided for a time they may not be for any time?
              </p>
              <p className="p-in-sp">If hostilities may be suspended for six months, they may be for six years, and this power of forbearance, followed to its logical consequences, establishes the power of making a permanent peace, and, by consequence, of entering into treaties.</p>
              <p className="p-in-sp">
                I should be glad of an opportunity of going more fully into considerations connected with this question. I beg gentlemen to observe that the proposition contemplates the effectual security of the peace of the country by the removal of the exciting, inflammatory material <PageNumber num={224} /> contained in the refusal of the Government to acknowledge
                the representatives of the South. It is recommended by that consideration, and by the further, that if it be within the range of peaceable, persuasive, conciliatory means to bring back our brethren to their ancient position, it may be thus accomplished; so that, Mr. Chairman, whether we would preserve the peace of the country, or whether we desire the restoration of the States, the
                resolution in question provides for the object. I have again to express the hope that the proposition will meet with the approval of the Committee.
              </p>
              <p><span className="persName">Mr. DORMAN</span> demanded a division of the question and called for the yeas and nays on the motion to strike out; which were ordered.</p>
              <p>
                The question was then taken on striking out, and it was decided in the negative—yeas 58, nays 71, as follows :<span className="note" id="Note28"><span className="noteLabel">8</span>The Journal includes Kilby in the affirmative votes, and Janney and McComas in the negative votes, in addition to those listed here.</span>
              </p>
              <p>
                YEAS-Messrs. Ambler, Blakey, Boisseau, Borst, Bouldin, Boyd, Branch, Bruce, Chambliss, Chapman, Coffman, Conn, Dorman, Echols, Fisher, Flournoy, Garland, Graham, John Goode, Jr., Hale, Cyrus Hall, L. S. Hall, Harvie, Holcombe, Hunton, Isbell, Kent, Kindred, Lawson, Leake, Macfarland, Chas. K. Mallory, James B. Mallory, Marr, Marye, Sr., Miller, Montague, Morris, Morton, Neblett,
                Randolph, Richardson, Seawell, Sheffey, Slaughter, Speed, Strange, Sutherlin, Thornton, Tredway, Robt. H. Turner, Franklin P. Turner, Tyler, Williams, Wilson, Wise, Woods-58.
              </p>
              <p>
                NAYS-Messrs. Armstrong, Aston, Baldwin, Alfred M. Barbour, Baylor, Berlin, Boggess, Brent, Brown, Burdett, Campbell, Carlile, Carter, C. B. Conrad, Robert Y. Conrad, Couch, Critcher, Custis, Deskins, Early, French, Fugate, Gillespie, Gravely, Gray, Goggin, Addison Hall, Ephraim B. Hall, Hammond, Haymond, Hoge, Holladay, Hubbard, Jackson, Marmaduke Johnson, Peter C. Johnston, Lewis,
                McGrew, McNeil, Marshall, Maslin, Masters, Moffett, McD. Moore, Nelson, Orrick, Osburn, Parks, Patrick, Pendleton, Porter, Preston, Price, Pugh, Robert E. Scott, Wm. C. Scott, Sharp, Sitlington, Spurlock, Staples, A. H. H. Stuart, Chapman J. Stuart, Summers, Tarr, Tayloe, Waller, White, Wickham, Willey-71.
              </p>
              <div className="speaker" id="sp2332"><span className="persName">Mr. BLOW</span>, of Norfolk City—</div>
              <p className="p-in-sp">
                I have an amendment which I propose to offer to the 9th resolution. I would state, Mr. Chairman, that this amendment contemplates that it shall be followed up by a motion to strike out the whole of the 10th resolution, inasmuch as that 10th resolution is but the sequence of the doctrines set forth in the 9th resolution. The amendment I propose is, to strike out all after the word
                "States" in the 10th line, and insert the following: "With a view to a peaceable adjustment of these and kindred questions, the people of Virginia hereby express their earnest desire that the Federal authorities, if so empowered, shall recognize the independence of the Confederate States of the South, and make such treaties with them, and pass such laws as separation (if unavoidable)
                shall render proper and expedient."
              </p>
              <PageNumber num={225} />
              <p className="p-in-sp">
                It is stated, Mr. Chairman, in the 9th resolution that "the Federal authorities, under the Constitution as it is, disclaim power to recognize the withdrawal of any State from the Union, and consequently to deal with these questions," and for that reason the 10th resolution contains the express declaration that "the people of Virginia hereby declare their desire to confer upon the
                Government of the United States the powers necessary to enable its proper authorities to deal peaceably with these questions." As I understand it, sir, the assertion in the 10th resolution is, that without the conference of that power upon the General Government, they are not enabled to deal peaceably with these important questions; and for that reason it is I am disposed, so far as
                the action of Virginia is concerned, that her express will shall be made and set forth, in language that cannot be misunderstood; that it is her desire, that if the power be possessed, that power shall be exercised, for the purpose of securing the peace, by enabling the General Government to recognize the independence of the Confederate States of the South, and to make such treaties
                and such laws as may be necessary for the purpose of securing this great end. I do not believe, sir, that it is consistent with the dignity of the State of Virginia that any doctrine which she is disposed to set forth shall be based upon deference to the opinions of the authorities of the Federal Government. I believe that her doctrines should emanate from herself and depend upon the
                judgment of Virginia alone; and I am not willing, so far as I am individually concerned, that the enunciation of her doctrine shall be based on such a risk as that alluded to in this resolution.
              </p>
              <p className="p-in-sp">
                In the second place how is this power to be conferred upon the General Government? What is the machinery that is to be used so as to enable the General Government to deal peaceably with this important question? What does it mean, sir? Does it contemplate a National Convention? If not, in what way is this power to be conferred? I see no necessity that we should involve ourselves in
                difficulties of that kind when it is the desire as well as the judgment of this Convention to express in language which cannot be misunderstood, that if the power be possessed by the General Government, it is the wish of Virginia that that power shall be exercised for the purpose of recognizing the independence of the Confederate States, and for the purpose of making such treaties
                and such laws as may be necessary and proper under the circumstances.
              </p>
              <div className="speaker" id="sp2333"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Mr. Chairman, I really approve of the motives of my friend from Norfolk City [<span className="persName">Mr. BL</span>Ow]
                , but I cannot concur in the <PageNumber num={226} /> action, or rather, in his proposition of action. Sir, I wish I had the power to address myself to the hearts and heads of this Committee, in calling their attention to the possible effect of these two resolutions—the 9th and the 10th. You are men of peace. You are patriots. We are all trying with
                heart and head to unite in preserving and protecting our people from civil war. I hope that the being is not upon this floor that would, by direction or indirection, if it can be avoided, cause the worse calamity that can come upon our land. What are you doing by adopting these two resolutions? I address myself earnestly to you as brethren, when I ask, can you by implication
                entertain the remotest idea that there is wanting in our present government, the most essential power of government, that which ministers most to the happiness of mankind? Are you about to vote here to day—without designing it, for I am sure you do not design it—that the Government of the United States, at Washington, has not within its grasp all the power of peace, the power of
                preventing civil war.
              </p>
              <p className="p-in-sp">
                Sir, in this ninth resolution, you have no resolution at all. What is a resolution? It is that which proposes a purpose, a plan, a measure of action or motive of action. Is there any purpose avowed in this ninth resolution? Is there any plan avowed in that ninth resolution? No, sir, none whatever. It contains a mere assertion—a declaration of fact—unconnected with any action, or
                motive of action. You state there that the Government of the United States disclaims power to recognize the withdrawal of any State from the Union. Do you assent to that? Do you admit that proposition? You seem to do so, by implication, in the two resolutions. If you state the fact that the disclaimer is made, and then you prepare to give them the power—it would imply that they did
                not have it already.
              </p>
              <p className="p-in-sp">Sir, the gentleman from Richmond [<span className="persName">Mr. MACFARLAND</span>]
                , well remarked, have they not the power of forbearance? I thank him for that word. If they have no other power, they have the power of abstaining from the exercise of power. What is the doctrine avowed in these resolutions? The odious, detestable doctrine that came from Mr. Buchanan's message. You have no power to declare war upon the States, he says, by Congress; Granted. What
                then? You are bound to execute the law. That, at all hazards—at even the hazard of national disintegration—at the hazard of all hope of its ever being re-united—through blood, through fire, through human misery—the law must be executed. Is that so? Are we bound to wait until a National Convention can give the power of peace? Are we about to
                <PageNumber num={227} /> vote here, by indirection, or implication even, that at all hazards—at all peril at all risk, the laws must and shall be executed?
              </p>
              <p className="p-in-sp">
                Then another question arises—a fearful question to me—one that deeply agitates my bosom—and as brethren I appeal to you, that I may clear myself of it. Is it the purpose of any of you—for it seems to me there is some lurking purpose in these two resolutions—is it the lurking purpose of any of you to slough off the Southern States and to let them go in peace? Can any Union man consent
                to do that? If he can, let him never after this call himself a Union man. You consent to part with all the rights of the navigation of the Mississippi river—you consent to part with seven States of the Union—you consent to part with all the Gulf coast—you consent to part with all the South-east Atlantic coast—you consent to break the golden chain that once bound us together? If so
                you are worse than murderers. You do not stone Stephen, but stand by and consent to the death. I am making no factious opposition to these resolutions; and when my friend from Norfolk tells me that he wants language plain as light, let me tell him that he is mistaken if he supposes his own is plain. He puts an hypothesis. That hypothesis is "if." "If" they have the power, he says.
                And he, too, thus raises an implication that they have not the power of peace.
              </p>
              <p className="p-in-sp">
                Have they not the power of peace? 0, Heaven defend us! Who who so weak, who so humble, as not to have the power of peace, the power of forbearance? Sir, if the gentleman will withdraw that proposition, I would move, if I had the opportunity, to strike out this which professes to be a resolution, but which is not, because it has not a single idea of a resolution in it; it merely makes
                a statement of facts, has no purpose, proposes no plan, no action ; it simply states facts, which may be dubious, which may be misapplied, which are somewhat doubtful, about which, at all events, there is some controversy. I would move to strike out the portion which admits that "the Federal authorities, under the Constitution as it is, disclaim power to recognize the withdrawal of
                any State from the Union, and consequently to deal with these questions, holding that it is reserved only to the States, as parties to the government compact, to take lawful action touching them." Here it takes two resolutions to do what one would do as well as two ; with only one, you see what your meaning is at once, without touching the free navigation of the Mississippi river—
              </p>
              <div className="speaker" id="sp2334"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">Your time is up, sir.</p>
              <div className="speaker" id="sp2335"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I could not be allowed to get to the Chesapeake Bay, <PageNumber num={228} /> where I wanted to show another mare clausum, where a pretext for coercion can be raised just as plausibly as at the mouth of the Mississippi.</p>
              <div className="stage it">[Laughter.]</div>
              <div className="speaker" id="sp2336"><span className="persName">Mr. SHEFFEY</span>, of Smyth—</div>
              <p className="p-in-sp">I move to amend the amendment of the gentleman from Norfolk City [<span className="persName">Mr. BL</span>Ow]
                , by striking out after the words "Federal authorities," "if so empowered." The separation of the seceded States is a fact which we cannot ignore. It is a fact which we know to exist as a fact ; and the question for the consideration of the Government at Washington, is—and it is a question which is now agitating the public mind and troubling the public heart—whether that Government
                shall deal with this subject peaceably or forcibly. As I understand it, sir, the Government at Washington disclaims the power to deal with this subject so vital to the peace, so vital to the interest, so vital to the very existence of our country, peaceably. It has not asserted directly, the right to deal with it forcibly, but it has, in effect asserted that right; for,
                notwithstanding the fact to which have I alluded, the President, in his Inaugural, has declared that the union of these States is unbroken, that he will execute the laws in all the States, that he will use the power confided to him, to hold, possess and occupy the forts, and to collect the revenue within the seceded States. That, sir, is the assumption of the power to deal with this
                subject forcibly.
              </p>
              <p className="p-in-sp">
                Now I maintain, that a government which possesses the power to deal forcibly with a subject, has also the power to deal peaceably with it. The resolution has a proper object, the resolution has a noble object; the resolution has for its object, the dealing with the seceded States as a government, de facto; it has for its object, not the conferring upon the General Government the
                power which it does not possess, but it has for its object to call upon the General Government to treat this subject peaceably, by negotiation, by treaty, and not by the sword. And the object of my amendment to that amendment, is to remove from it any doubt as to the existence of the authority on the part of the General Government to treat, to negotiate, with the seceded States, for
                the recognition of their independence.
              </p>
              <p className="p-in-sp">
                Sir, I say that the first step for the Government of the United States to pursue in order to preserve the hope, if hope may be preserved, of the reconstruction of this Union, is first to recognize their independence. Go there with the sword, and you make a gulf between them and the other States as wide as eternity. Treat with them, recognize their independence, and you do not
                alienate them forever, but you leave at least a hope for them to return if we remain <PageNumber num={229} /> banded together. I propose, therefore, for the purpose of removing all questions in our minds, that Virginia shall speak as to the authority of the Federal Government to preserve peace. That Virginia statesmen shall speak in advance, that they
                shall place it beyond any doubt or equivocation, that this Government has the power of peace, and that it should assert the power of treating peaceably with the Southern Confederacy. I move, therefore, this amendment, for the purpose of calling on the General Government to negotiate with, to recognize the independence of the seceded States, and thereby secure the peace of the
                country, and a hope of the restoration of the Union.
              </p>
              <div className="speaker" id="sp2337"><span className="persName">Mr. SCOTT</span>, of Fauquier—</div>
              <p className="p-in-sp">The purpose of these resolutions is a peaceful purpose. I am sure the gentleman from Princess Anne [<span className="persName">Mr. WISE</span>]
                , will acquit all his associates upon the Committee of Federal Relations of any other purpose. Unquestionably, Mr. Chairman, we all recognize the fact of withdrawal by the Southern States. And we all know that this withdrawal has given rise to novel and difficult questions. The amendment now proposed to be made to the amendment of the gentleman from Norfolk, is intended to obtain
                from this body a declaration that the Federal Government, under the power with which it is now clothed, has the right to recognize this withdrawal, and to deal with the questions which have arisen from it. This body may have its opinion. It is entitled to have its opinion. It may be its opinion that the government possesses this power. Of what avail will it be that we shall
                communicate this opinion to the authorities at Washington? We may send up a rescript of Constitutional construction; of what avail will that rescript be? These authorities tell us that according to their construction of the Constitution no such power is imparted to the Government. We must say that they came to the conclusion honestly; we must conclude that this is their deliberate,
                honest opinion. How are these difficult, novel, and various questions to meet their solution?
              </p>
              <p className="p-in-sp">
                According to my understanding, there are certain offences with which government may deal; offences committed against the government, such, for instance, as sedition, insurrection, revolt, in some of its phases. If the case be one of sedition or insurrection, being an offence against the government authority, the government may deal with it. I have come to the conclusion, Mr.
                Chairman, that the withdrawal of a State by virtue of its sovereign power, from association with the other States, does not come within the category of offences against government; and is not, therefore, one of those offences with which a government can deal. I take it, sir, that the withdrawal of a State from a <PageNumber num={230} /> government
                compact, if an offence at all, is an offence against the other parties to the compact, and it is, therefore, an offence with which the other parties can deal. Then, sir, when we find the government authorities disclaiming the right to recognize the withdrawal of a State, what remains? How are you to prevent a hostile collision? It seems to me there is but one way to do it, and that
                is by an appeal to the parties to the compact.
              </p>
              <div className="speaker" id="sp2338"><span className="persName">Mr. FLOURNOY</span>—</div>
              <p className="p-in-sp">Will the gentleman allow me to ask a single question?</p>
              <div className="speaker" id="sp2339"><span className="persName">Mr. SCOTT</span>, of Fauquier—</div>
              <p className="p-in-sp">I cannot, if it is to come out of my time.</p>
              <div className="speaker" id="sp2340"><span className="persName">A MEMBER</span>—</div>
              <p className="p-in-sp">We will extend your time.</p>
              <div className="speaker" id="sp2341"><span className="persName">Mr. FLOURNOY</span>—</div>
              <p className="p-in-sp">I merely want to ask the gentleman a single question. Admitting the position of the gentleman, so long as these parties to the compact communicate with each other through their common agent, the General Government, by what process will the other parties deal with the seceded States, except through that common agent?</p>
              <div className="speaker" id="sp2342"><span className="persName">Mr. SCOTT</span>, of Fauquier—</div>
              <p className="p-in-sp">
                That is the very question I was proceeding to argue. I said that there was but one mode, and there is no other possible mode that I can see, by which a peaceable solution of these questions can be reached, than by action upon the part of those who are interested in the question, and who have the power to deal with it. The government remains the agent of the other party. The
                withdrawing States have cast off the agency, and the government continues alone the agency of the remaining States. If it has not the power, then, to deal with these questions, those States, whose agent it is, may communicate new powers to it.
              </p>
              <div className="speaker" id="sp2343"><span className="persName">Mr. WISE</span> [in his seat]—</div>
              <p className="p-in-sp">By a National Convention.</p>
              <div className="speaker" id="sp2344"><span className="persName">Mr. SCOTT</span>, of Fauquier—</div>
              <p className="p-in-sp">
                The gentleman says, by a National Convention. That is one mode, but it is not the only mode by which the Constitution may be amended and these powers communicated to the government. The States may demand through their representatives in Congress an amendment of the Constitution by other means. We do not propose to indicate the mode or prescribe the means, but we propose upon the part
                of Virginia—a State which yet remains associated in this Union—a State in respect to which as one of the parties this act of withdrawal may not be regarded as a wrong or an offence—we propose, upon the part of Virginia, to say that we recognize the justice of the causes which impelled to the withdrawal ; we acquit our sister States of all offence, and we appeal to the other States
                remaining with us in the Union to take the measures necessary to solve these questions amicably; to communicate to their agent new powers, if new <PageNumber num={231} /> powers be necessary, to relieve it from the embarrassment under which it proclaims itself to labor, and if necessary to recognize the independence of these seceded States.
              </p>
              <p className="p-in-sp">The gentleman from Princess Anne asks if we can part company with the seceded States. Sir, there is nothing in the resolution proposing such a step. The recommendation of the acknowledgment of their independence is, if it shall become necessary—</p>
              <p>Here the hammer fell.</p>
              <div className="speaker" id="sp2345"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">We have now been in session nearly seven hours. I do not know how it is with other gentlemen, but I am tired, and I move that the Committee rise.</p>
              <p>The motion was agreed to.</p>
              <p>The Committee accordingly rose, and the President having resumed the Chair, the Chairman [<span className="persName">Mr. PRICE</span>]
                , reported progress.<span className="note" id="Note29"><span className="noteLabel">9</span>According to the Journal, it was Mr. Southall who reported the progress of the Committee. The Proceedings of April 5, page 171, note that Mr. Southall took the chair in Committee of the Whole.</span>
              </p>
              <p>And then, on motion of <span className="persName">Mr. STAPLES</span>, at 10 minutes before 7 o'clock, the Convention adjourned.</p>
            </div>
          </div>
          <div className="day" id="vsc1965.v3.2.5">
            <PageNumber num={232} />
            <h2><span className="headingNumber">1.5. </span><span className="head">FORTY-FIFTH DAY</span></h2>
            <div className="dateline">Saturday, <span className="date">April 6</span></div>
            <p>The Convention met, pursuant to adjournment, at 10 o'clock, A. M. Prayer by Rev. Mr. Walker, of the Baptist church.</p>
            <div className="section" id="vsc1965.v3.2.5.1">
              <h3><span className="headingNumber">1.5.1. </span><span className="head">PROCEEDINGS OF CHARLOTTE MEETING</span></h3>
              <div className="speaker" id="sp2346"><span className="persName">Mr. BOULDIN</span>—</div>
              <p className="p-in-sp">
                Mr. President, I desire, with the leave of the Convention, to present this morning, the proceedings of a portion of my constituents, and after having submitted a few remarks,<span className="note" id="Note30"><span className="noteLabel">1</span>In the Proceedings of April 9, page 381, Mr. Bouldin made some corrections of the second and fourth paragraphs of his remarks.</span> I will ask the
                Convention to make such order as is usual in such cases. I will read such portion of the proceedings as bear upon the question before the Convention. They are as follows :
              </p>
              <div className="floatingText">
                <div className="floatingText_body">
                  <p>At a meeting of the citizens of Charlotte county, held at the Court-House on Monday the 1st day of April, 1861, for the purpose of promoting the cause of immediate secession. A. A. Lyle was called to the Chair, and H. A. Carrington appointed Secretary.</p>
                  <p>Mr. Isaac R. Watkins then offered and sustained, in a short but pertinent speech, the following preamble and resolutions :</p>
                  <p>
                    Whereas, the people of Charlotte, in general meeting assembled, on their court-day in January last, determined by their unanimous resolves that they would submit no longer to the present Federal Government without additional guarantees of safety, a complete redress of the grievances then set forth by them, and a speedy and final settlement of all distracting sectional questions
                    between North and South, such as would be satisfactory to the Southern States; and that in the event of a failure to obtain such settlement and guarantees, they would welcome a final separation from the Northern States; and, whereas, every effort at an honorable adjustment has utterly failed, be it therefore,
                  </p>
                  <p>Resolved, by this meeting, 1st. That the present Convention ought, at once, to pass an ordinance withdrawing Virginia from the present Union; and that any further delay will be dangerous, alike to the peace of the country, to our property, to our liberties, and to the future welfare and prosperity of Virginia.</p>
                  <p>Resolved, 2d. That the foregoing preamble and resolution be published in the Richmond papers; and that a copy of the same be forwarded to our delegate in the Convention, with a request to present them to that body.</p>
                  <PageNumber num={233} />
                  <p>Mr. John R. Cardwell offered, and earnestly advocated the subjoined substitute :</p>
                  <p>
                    Whereas, a Convention of the sovereign people of Virginia, composed of men distinguished for their ability, integrity, patriotism and statesmanship, is now in session in the city of Richmond, for the purpose of determining what course of action Virginia shall pursue in the present distracted and deplorable condition of the country; and, whereas, the county of Charlotte is
                    represented in that Convention by a gentleman who was chosen by the almost unanimous voice of her people, as the one best qualified, of all her citizens, for that important and responsible position, and in whose judgment, firmness and fidelity, we have the most unlimited confidence; therefore, be it
                  </p>
                  <p>Resolved, That we, as true and loyal Virginians, perceiving no imperative necessity for any immediate or precipitate action, deem it our duty to await and abide by the decision of said Convention; provided the same shall be ratified by a majority of the people of the Commonwealth.</p>
                  <p>
                    Resolved, further, That, as Virginia has voluntarily assumed the office of mediator, in the present unfortunate sectional controversy, which has already produced a temporary, if not a permanent dissolution of the Union, she can not, consistently with her honor, secede from that position until she shall have first exhausted every means for a fair and honorable adjustment of
                    pending difficulties; and that it is her true policy, as well as her manifest duty, before taking any final action, to give formal notice to her sister border States, of the line of policy which she may deem it her duty to pursue, and respectfully to invite their co-operation.
                  </p>
                  <p>Mr. Cardwell advocated his substitute with decided ability, and spoke in opposition to the policy of immediate secession, upon the ground that it would inevitably result in a division of the State.</p>
                  <p>The vote was first taken on the substitute, which was rejected by an overwhelming majority. The preamble and resolutions of Mr. Watkins were then adopted by acclamation.</p>
                  <p>The hearty response given in favor of these resolutions, proves, incontestably, that nine-tenths of the people of Charlotte are for immediate secession.</p>
                  <p>In response to repeated calls, Mr. William E. Green delivered an able and unanswerable secession speech, on the conclusion of which, the meeting adjourned.</p>
                  <p>A. A. LYLE, Ch'mn.</p>
                  <p>H. A. CARRINGTON, Sec'y.</p>
                </div>
              </div>
              <PageNumber num={234} />
              <div className="speaker" id="sp2347">[<span className="persName">Mr. BOULDIN</span>:]</div>
              <p className="p-in-sp">
                I ask that the usual course be taken with the resolutions. Allow me to say, in presenting them, that they come from the people of a county as largely interested in the peculiar institution which is the cause of all our present trouble, as any county in the Commonwealth save one or two. For material prosperity, for moral, mental, and social elevation, they will compare favorably with
                the people of any portion of this Commonwealth. They have ever shown themselves, under all circumstances, jealous of their rights, restless under injustice, and prompt to resist oppression.
              </p>
              <p className="p-in-sp">
                Indeed, sir, from their surroundings it could not be well otherwise. The very soil on which they tread would rise up in condemnation against them if there could be found a man among them who could, for a moment, encourage the craven spirit of willing submission to oppression and wrong. Their soil may be called classic ground it is the shrine of the mighty dead. It holds the
                consecrated dust of the inspired prophet of liberty
                [Patrick Henry]
                , the patriot orator of the revolution. There are yet one or more persons living among them who have heard his clarion tones, and they and their children, and children's children will cherish his teachings and cling with undying devotion to his lessons of constitutional liberty. It holds, also, the honored remains of another faithful and esteemed son of Virginia—John Randolph, of
                Roanoke—one whose image is here now to cheer and encourage, and whose features I might almost fancy I can see to gaze on these sons of Virginia with intense solicitude, as they have gathered here to protect the interest and honor of the State which he served so long and loved so well. His life, sir, was one long-continued battle for the rights of the States and the sovereignty of
                Virginia. His last battle was fought for the same glorious cause. As has been eloquently alluded to by my friend from Halifax
                [<span className="persName">Mr. FLOURNOY</span>]
                , he had been absent in the service of his country in distant lands. He returned with a frame worn by disease, but with the old fire still burning brightly in his breast. It was at a moment when the stalwart arm which he had contributed to clothe with power was raised in its majesty to strike down the rights of that State to whose interests he had been so long and so faithfully
                bound. He did not hesitate, though the spark of life was just then expiring, to put on the old familiar harness, and right clearly in that last battle did the old war cry ring out once more. The lance which in his boyhood had been directed against the helmet of Patrick Henry, was boldly aimed against the towering crest of Andrew Jackson. It was at the close of such a life that he
                illustrated the maxim, coronat finis opus.
              </p>
              <PageNumber num={235} />
              <p className="p-in-sp">
                Mr. President, the people of Charlotte stood by him through all his trials, and in all his battles in defence of State sovereignty. Their vote had been almost unanimous in carrying Andrew Jackson into power, and with the same unanimity they stood by Mr. Randolph in resisting that spirit of encroachment on the rights of a State, which was set forth in the proclamation of Andrew
                Jackson.
              </p>
              <p className="p-in-sp">
                With such memories, and such teachings, the people of Charlotte could not well do otherwise than they have done in these resolutions; the spirit of resistance to oppression, is a part of their very nature. I approve of these resolutions. I am authorized to say, from recent association with that people, and from information which I have received since the meeting, that these
                resolutions were not intended to trammel or to instruct me. It would not have been kind in my constituents to do so, for when they called on me, without solicitation on my part, to come out from the private life to which I had hitherto been devoted, I told them that the crisis was such, and the character of the questions to be presented was such, that they must entrust to me a large
                discretion, that I should have the untrammelled right to deal with those mighty questions according to the dictates of my judgment, on general principles made known to them, and by the lights which should surround me when I should be required to act. I reserved that right, and they conceded it to me.
              </p>
              <p className="p-in-sp">
                I desire now to say to the members of this Convention and to my constituents, that while I acknowledge the right of instruction, and while I would never thwart the wishes of my constituents, I mean to stand here the peer of honorable gentlemen around me, fully at liberty to bring my mind, such as it may be, to bear on the questions to be decided, to compare opinions, to reconcile and
                compromise difficulties, and to strike out, for the honor of this good old Commonwealth, that course which I think will promote it. Should my constituents be dissatisfied with this position, I have only to say, that I will, at any moment, allow them to send in my place some other person to represent them.
              </p>
              <p className="p-in-sp">
                I have made these remarks, Mr. President, because the fact of sending these papers might, perhaps, be regarded as instructions to me. There have been, and still are, difficulties between us, and I will state them frankly. I am satisfied that more than nine-tenths of my constituents now desire the immediate secession of Virginia from the Union, and I have cast a vote here in deference
                to that wish. They did not instruct me to do so. They expressly avoided doing so, because they knew that I thought, situated as Virginia is with the difficulties <PageNumber num={236} /> existing among us, that another course would be wiser and better, even for the friends of immediate secession, and they trusted me to act as I might think proper. Under
                the generous latitude thus allowed me I did give a vote which I knew would gratify my constituents.
              </p>
              <p className="p-in-sp">
                I have never had any difficulty as to this right of secession. I told my people, when I was a candidate, that I believed that Virginia, in becoming a member of the Federal Union, delegated certain powers to a common agent to be exercised for her benefit, but parted with no portion of her sovereignty ; and that she has the right to secede at will, whenever, in her opinion—and to use
                the language of the people of Charlotte in 1833—the benefits of the Union shall be exceeded by its evils. And, sir, I said that whenever Virginia thought proper to take that stand, there was no such thing as traitor in Virginia unless it be he who, when Virginia calls, shall, like a dastard son, hesitate to enroll under her banner.
              </p>
              <p>The proceedings were referred to the Committee on Federal Relations.</p>
            </div>
            <div className="section" id="vsc1965.v3.2.5.2">
              <h3><span className="headingNumber">1.5.2. </span><span className="head">CORRECTION</span></h3>
              <div className="speaker" id="sp2348"><span className="persName">Mr. HULL</span>—</div>
              <p className="p-in-sp">
                Mr. President, in the proceedings of Thursday I stated that I had paired off with Col. FORBES. It is reported in the "Enquirer" this morning, that <span className="persName">Mr. HALL</span> had paired with Col. FORBES. I presume it is a typographical mistake, owing to the substitution of one letter for another. The omission of a letter may generally be important, but it is very important
                in spelling my name, whether you spell it with an "a," "e," or a "u." I desire the mistake to be corrected.
              </p>
            </div>
            <div className="section" id="vsc1965.v3.2.5.3">
              <h3><span className="headingNumber">1.5.3. </span><span className="head">PETITIONS FROM NORFOLK COUNTY</span></h3>
              <div className="speaker" id="sp2349"><span className="persName">Mr. CHAMBLISS</span>—</div>
              <p className="p-in-sp">
                Mr. President, I hold in my hand two petitions, one from the people of Norfolk county and one from the city of Portsmouth. They have been sent to me, enclosed in the envelopes which you see, with the request that I should present them to this Convention. The memorials are couched in respectful language, and are decided in opinions, which opinions are in unison with my own. I,
                therefore, feel bound, notwithstanding these people are represented by two very respected friends of mine, to present those memorials, and ask that they be referred to the Committee on Federal Relations.
              </p>
              <p>The Portsmouth petition was read as follows :</p>
              <div className="floatingText">
                <div className="floatingText_body">
                  <p>TO THE SOVEREIGN CONVENTION OF VIRGINIA</p>
                  <p>Whereas, we have seen it announced in the public papers that J. G. Holladay, Esq., one of the delegates from the county of Norfolk,</p>
                  <PageNumber num={237} />
                  <p>
                    including the city of Portsmouth, has presented to your humble body a petition wrapped in the flag of the once United States, purporting to come from the people of Norfolk county; and whereas we, as a portion of said county as at present represented, are unwilling that it shall be presumed that said petition represents our sentiments, believing that Virginia has already made, in
                    vain, every effort consistent with her dignity and self-respect, to obtain from her Northern Confederates just, plain and sufficient guarantees of her equal rights, and that neither her interests nor honor can be maintained in the present Union; therefore we, citizens of Portsmouth, do respectfully petition your honorable body to proceed at once, to pass an ordinance of
                    secession, providing for Virginia to resume the powers delegated by her to the Federal Government, and to submit the same as soon thereafter as may be to the qualified voters of the State for their approval.
                  </p>
                  <p>And your petitioners will ever pray, &amp;c.</p>
                </div>
              </div>
              <p>The memorials were referred to the Committee on Federal Relations.</p>
              <div className="speaker" id="sp2350"><span className="persName">Mr. CHAMBLISS</span></div>
              <p className="p-in-sp">I would remark, sir, that the other memorial is in almost identical language, and therefore I do not see the necessity of reading it. I would remark that the Norfolk memorial is signed by 526; the other from Portsmouth by 358.</p>
              <p>Referred to the Committee on Federal Relations.</p>
              <div className="speaker" id="sp2351"><span className="persName">Mr. JOHNSON</span>, of Richmond—</div>
              <p className="p-in-sp">I desire, with the indulgence of the Convention, to offer a memorial, which, if I am afforded an opportunity, I shall accompany with a few remarks.</p>
              <div className="speaker" id="sp2352"><span className="persName">Mr. HOLLADAY</span>—</div>
              <p className="p-in-sp">Will the gentleman permit a single remark on the subject of the memorial just presented?</p>
              <div className="speaker" id="sp2353"><span className="persName">Mr. JOHNSON</span>—</div>
              <p className="p-in-sp">Certainly.</p>
              <div className="speaker" id="sp2354"><span className="persName">Mr. HOLLADAY</span>—</div>
              <p className="p-in-sp">
                My friend, <span className="persName">Mr. CHAMBLISS</span>, from the county of Greensville, has just presented the memorials of the citizens of the county of Norfolk and of the county of Portsmouth, which have been forwarded to him for presentation. I don't believe that any disrespect was intended to their representative ; and I have no objection to the course pursued by the gentleman
                from Greensville
                [<span className="persName">Mr. CHAMBLISS</span>]
                , I would say that it was pursued after consultation with my colleague and myself. I am satisfied, sir, that it is not intended by this action to reflect upon my colleague, because I recognize among the petitioners a number of very warm personal friends of mine. But they have selected a gentleman who reflects the sentiments of the memorial better than my colleague or myself ; and I
                would simply say, in addition, that the memorial is of a small minority of the voters of my county, gotten up very laboriously, and fails to embody the sentiments of a majority of <PageNumber num={238} /> my constituents. I say here, sir, that from private accounts and from my personal observation on my return a short time since, the voters of that
                county stand now where they stood in the election of delegates to this Convention. A large and overwhelming majority stand now as they stood then, still maintaining the rights of the Union and the Constitution, with a desire to secure for them all proper guarantees.
              </p>
              <div className="speaker" id="sp2355"><span className="persName">Mr. JOHNSON</span>, of Richmond—</div>
              <p className="p-in-sp">Mr. Chairman, the question to which I desire to call the attention of the Convention, is one somewhat personal to myself ; and as I will be very brief, I hope it will be the pleasure of the Convention to let me get through with the little statement that I propose to make.</p>
              <p className="p-in-sp">There was handed to me yesterday by a highly respectable citizen of the city of Richmond, a memorial addressed to this Convention, which I hold in my hand. The address is but in a few words, and can be more briefly read than explained :</p>
              <p className="p-in-sp">"To the Convention of the State of Virginia:</p>
              <p className="p-in-sp">
                "The undersigned, voters of the city of Richmond, in view of the policy indicated by the inaugural of Abraham Lincoln ; the complete failure of the Peace Congress, and the rejection of Crittenden's Compromise, now feel that Virginia should at once sever her connection with the Federal Union. We respectfully petition your honorable body to adopt at once an ordinance of secession."
              </p>
              <p className="p-in-sp">
                This memorial, sir, is signed by 1,530 subscribers. I don't mean to say 1,530 voters, for I am not aware of that fact. I desire, sir, inasmuch as I know from my own personal association with the people of Richmond, that it was the purpose of some of the subscribers to this memorial that it should operate somewhat in the shape of an instruction to me and to one of my colleagues
                [<span className="persName">Mr. MACFARLAND</span>] , for I take it that my other colleague [<span className="persName">Mr. RANDOLPH</span>] , requires no instructions upon the question—I say, I desire in view of this fact to make a brief statement.</p>
              <p className="p-in-sp">This memorial came subscribed by very many of the most sterling and substantial people of Richmond—many of them my personal friends, from whom, however, I widely differ in regard to the subject of the day.</p>
              <p className="p-in-sp">
                I wish to state, sir, that this paper has been being gotten up ever since about the time that this Convention met; that by the most earnest industry and active efforts it has been circulated, I believe, at least in every reputable locality of the city for the purpose of adding to the number of the names; and sir, I opened it yesterday,
                <PageNumber num={239} /> and will inform the Convention that there are pasted together fifty-three different pieces of paper. I mention this for the purpose of showing the number of agents who so industriously applied themselves to the procurement of these signatures.
              </p>
              <p className="p-in-sp">
                I state further, that upon inspection—which the Convention, if they have the curiosity to find out, will perceive—that very many of the names are in the same handwriting; and I will state, further, that there are at least 4,500 voters in the city of Richmond ; that in the presidential election there were polled between 4,300 and 4,400 voters, making it safe for me to assume that
                there are 4,500 qualified voters in the city; so that there were obtained by these agents, thus actively engaged, about one third of the number of the voters of the city of Richmond.
              </p>
              <p className="p-in-sp">
                I am not prepared to say, for I don't feel authorized to say, that there are some of the subscribers to this memorial who are not legally qualified voters, and that there are some who do not live in the city of Richmond, and some that are not 21 years of age. Under these circumstances I propose, that this memorial be referred to the Committee on Federal Relations.
              </p>
              <p className="p-in-sp">I will state, in conclusion, that the gentlemen who were most active in getting it up, are gentlemen of the highest respectability. I trust the memorial will receive the consideration of the Committee.</p>
              <div className="speaker" id="sp2356"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">If the Committee will indulge me for a moment, I will present a paper which is connected with the paper just presented by my colleague.</p>
              <p className="p-in-sp">I will say that the paper which I now propose to read is the result of the deliberations of a public meeting in this city, held a few days since.</p>
              <p className="p-in-sp">The following resolution was adopted at the meeting held by the conservative Union men of Richmond, last night. The number present could not have been less than 2,000, many estimated the number as high as 2500.</p>
              <p className="p-in-sp">"Resolved, As the sense of this meeting, that when Messrs. Johnson and Macfarland were elected by the conservative men of Richmond, they were voted for as conservative men. We, therefore, request them not to heed any paper purporting to be instructions, signed by those who voted against them.</p>
              <p className="p-in-sp">"March 30th, 1861. J. H. GILMER."</p>
              <p className="p-in-sp">I ask, Mr. President, that this paper may receive the destination given to that presented by my colleague.</p>
              <PageNumber num={240} />
              <p>It was ordered to be referred to the Committee on Federal Relations.</p>
            </div>
            <div className="section" id="vsc1965.v3.2.5.4">
              <h3><span className="headingNumber">1.5.4. </span><span className="head">COMMITTEE OF THE WHOLE</span></h3>
              <p>The Convention then resolved itself into Committee of the Whole, <span className="persName">Mr. SOUTHALL</span> of Albemarle in the Chair.</p>
              <div className="speaker" id="sp2357"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">When the Committee rose last evening, it had under consideration the ninth resolution of the report of the Committee of Twenty-one. A motion was made by the gentleman from Norfolk [<span className="persName">Mr. BLOW</span>]
                , to strike out all that part of the resolution commencing at the tenth line, after the word "States," which will be again reported if desired, and to insert in lieu thereof the following : "With a view to the peaceable adjustment of this and kindred questions, the people of Virginia hereby express their earnest desire that the Federal authorities, if so empowered, shall recognize
                the independence of the Confederate States of the South, and make such treaties with them and pass such laws as separation, if inevitable, shall render proper and expedient."
              </p>
              <p className="p-in-sp">It is proposed by the gentleman from Smyth [<span className="persName">Mr. SHEFFEY</span>] , to amend the amendment by striking therefrom the words, "if so empowered."</p>
              <p className="p-in-sp">Should the amendment prevail, that part of the amendment will then read, "the people of Virginia hereby express their earnest desire that the Federal authorities shall recognize the independence," &amp;c.</p>
              <p className="p-in-sp">The question will be upon the amendment to the amendment, and upon that question the gentleman from Smyth is entitled to the floor.</p>
              <div className="stage it">
                <p>[We give here the 9th resolution, to which these amendments have been offered.</p>
                <p>
                  "The exercise of this right by the States of South Carolina, Georgia, Florida, Mississippi, Alabama, Louisiana and Texas, without the assent of the other States, has given rise to new conditions, and presented questions touching those conditions, intimately affecting the rights and safety of the other States. Among these are the free navigation of the Mississippi river, the
                  maintenance of the forts intended to protect the commerce of the Gulf of Mexico, and the power to restrain smuggling along the interior borders of the seceded States; but the Federal authorities under the Constitution as it is, disclaim power to recognize the withdrawal of any State from the Union and consequently to deal with these questions, holding that it is reserved
                  <PageNumber num={241} /> only to the States as parties to the Government compact to take lawful action touching them."]
                </p>
              </div>
              <div className="speaker" id="sp2358"><span className="persName">Mr. SHEFFEY</span>—</div>
              <p className="p-in-sp">
                The policy of Virginia has been to keep the peace, and to preserve the hope of restoring the Union by preventing collision and bloodshed. We differ widely as to the mode of accomplishing so beneficent an object. I have thought that since the failure of the "Peace Conference," prompt and decided action by Virginia, was the course of wisdom, prudence and safety. I believe, now, sir,
                that a bold and decisive position by this Convention would have united the South, and that a united South would have assured peace. With an united South there would have been no thought of war on the part of the authorities at Washington, or on the part of the States of the North. What effect the course which is pursued—the course of delay and indecision of "a wise and masterly
                inactivity," must have upon the great issue of peace, or war, the future alone can disclose. I trust, sir, that that course may redound to the great interests of peace, and our skirts may be clear of fraternal blood. Seven States have seceded; they have established a Government, and the question now is, how these States are to be treated. Shall they be reduced by the sword, or shall
                they be treated with and recognized as independent sovereignties—as an independent nationality? They passed through a bloodless revolution, if you please—I maintain that they have passed through it in the exercise of a right—of a peaceful right. How, then, are they to be treated by the Government at Washington? Are they to be reduced by the sword, or are they to be recognized as an
                independent nationality? One course is war, the other peace. Coercion is war, negotiation is peace. We are called upon to express to the authorities at Washington, the voice of Virginia, by the mouth of this Convention. Shall that voice be for negotiation, or shall it be for coercion? I am one of those who desire to send forth a voice to the world and to the authorities at
                Washington, for peace, for negotiation, for settlement upon a fair and equitable basis, and not for war, collision and bloodshed.
              </p>
              <p className="p-in-sp">Mr. Chairman, this report of the Committee has conceded the right of these States to withdraw for just cause. It has declared that the people of Virginia will never consent that the Federal power shall be used to subjugate these States to Federal authority, and it has deplored the "wrongs" which have impelled them to a separation.</p>
              <p className="p-in-sp">
                Now, sir, the wrongs which we have endured, I think all ought to admit are just causes for separation. Then, sir, the separation has been rightful and has been in the exercise of a conceded right, and those <PageNumber num={242} /> States are now—whatever may have been their position under the Union until it was dissolved—sovereign, and as to this
                Government, foreign, and may be treated with as this Government may treat with any foreign government. What is the power of the Government at Washington? Is it alone to make war upon these States, and to reduce them to a state of subjugation, or is it to recognise their independence and make a negotiation and treaty upon a fair basis of adjustment? Suppose, sir, they inaugurate a
                war. What, then, is to be the condition of affairs? The result will be, according to the position of the distinguished gentleman from Fauquier, that that war may proceed, as a war of extermination, till the States convene and authorise the Federal Government to make peace. Then, you must make war in order to make peace, and you cannot have peace until the States convene and empower
                that Government to make such peace.
              </p>
              <p className="p-in-sp">
                Now, sir, this is not a question of peace or war at all. There has been no war; there is no necessity for a treaty of peace. It is a question whether our National Government may recognize the independence of sovereign foreign States as to us. The Government of England and the Government of the United States have always recognized a Government de facto as an independent Government,
                and treated with it as such; and shall our Government be held to have the power to recognize a de facto foreign Government, and not have the power to recognize the independence of sovereign States which, in the exercise of a rightful power and authority, have withdrawn from this Union? And shall it be, sir, that blood shall flow and our soil dyed with the blood of our citizens before
                there be any measure for the restoration of harmony to the country? I maintain that this Government has the authority to recognize the independence of the seceded States, and that it is their duty to recognize them as the only measure to prevent collision and bloodshed, and as the only means of preserving the hope of peace and restoring the Union. Why, sir, coercion now would be a
                war of the North against the South. The Northern hordes, like the Goths and Vandals, are to be gathered, marshaled and marched upon our own sunny South. They come, if they come at all, to make us slaves. They come to transpose the relations of Southern society, and to make freemen of slaves and slaves of freemen, and yet we sit here, debate and deliberate whether we shall remain
                allied with those who would reduce us to a state of subjection and keep us estranged from our natural allies and friends of the South. I am one of those who believe that it is the right and duty of the Government at Washington, to recognize the independence of these seceded States <PageNumber num={243} /> and treat with them for a peaceable solution of
                this difficulty; to treat with them—if we cannot live together in peace—for terms of separation; that we should not place ourselves in the predicament of waiting until the States convene and empower the General Government to recognize their independence, while, in the meantime streams of blood may be made to flow all over the land.
              </p>
              <div className="speaker" id="sp2359"><span className="persName">Mr. BLOW</span>—</div>
              <p className="p-in-sp">I regret, Mr. Chairman, with the views I entertain upon this subject, that I do not feel at liberty to accept the amendment which has been offered by the gentleman from Smyth [<span className="persName">Mr. SHEFFEY</span>]
                , or to agree with him in the propriety of that amendment. The object of the amendment I have offered, is to avoid some of the difficulties and objections which may be urged upon the 9th and 10th sections of the report, and which will commend itself, in my estimation, upon a proper examination, to the sense of this Committee. The Committee, in the 9th resolution, assert that the
                withdrawal from the General Government of the State of South Carolina, and other States, has given rise to new conditions, and presented questions touching those conditions intimately affecting the rights and safety of the other States. Some questions are referred to us, springing from this new relation which has been produced by the withdrawal of these States. The resolution then
                goes on to state :
              </p>
              <p className="p-in-sp">"But the Federal authorities, under the Constitution as it is, disclaim power to recognize the withdrawal of any State from the Union, and consequently to deal with these questions, holding that it is reserved only to the States, as parties to the Government compact, to take lawful action touching them."</p>
              <p className="p-in-sp">
                Then the 10th resolution goes on to express that Virginia, in deference to this opinion on the part of the Federal authorities, desires to confer upon the General Government the powers necessary to enable the proper authorities to deal. peaceably with these questions. I desire to call the attention of the Committee to the peculiar phraseology of this portion of the resolution :
              </p>
              <p className="p-in-sp">"The people of Virginia hereby declare their desire to confer upon the Government of the United States the powers necessary to enable its proper authorities to deal peaceably with these questions."</p>
              <p className="p-in-sp">
                Now, sir, I ask if a fair interpretation of that language does not convey the idea, on the part of this Committee, that unless these new powers are conferred upon the General Government, they cannot deal peaceably with these questions. I know, Mr. Chairman, that a different construction is placed upon this language by other gentlemen; but I hold, that so important a question as this,
                which may be regarded <PageNumber num={244} /> as the hinge of the whole controversy—a question, upon the decision of which, depends the issue of war or peace—an issue which may be precipitated upon us at any moment—a question upon which this Committee has already expressed its opinions in the most unmistakable terms, ought not to be left in doubt.
              </p>
              <p className="p-in-sp">It is, therefore, for the purpose of avoiding an inference deducible from the language used in the tenth resolution, viz : that the people of Virginia believe that peace cannot be preserved except by conferring extraordinary powers upon the Federal Government—that I have offered this amendment.</p>
              <p className="p-in-sp">
                I know, Mr. Chairman, that there is a question of difficulty which has arisen in consequence of the withdrawal of these States from the Federal Government. I know that there are two classes of opinions in the country upon this subject. I know that one class entertain the opinion that the Federal Government, under existing circumstances, can deal peaceably with these questions—can
                make a treaty—can recognize the independence of the Confederated States of the South; whilst another class of gentlemen, whose authority is entitled to equal consideration, hold the opinion that the power does not exist, and that specific powers should be conferred upon the Federal Government, for the purpose of enabling them to accomplish these desirable results.
              </p>
              <p className="p-in-sp">Now, sir, in the accomplishment of the object I have in view, I am not disposed to raise that question of power. The amendment proposed by the member from Smyth [<span className="persName">Mr. SHEFFEY</span>]
                , will raise that question. I am disposed to leave it open, to let gentlemen think as they please upon that particular point. I do not regard it as essential to carry out the object I had in view, and I, therefore, am opposed to any change of the language of the amendment. Its object is to secure a peaceable adjustment of this and kindred questions. "The people of Virginia hereby
                express their earnest desire that the Federal authorities if so empowered shall recognize the independence of the Confederate States of the South, and make such treaties," &amp;c.
              </p>
              <p className="p-in-sp">
                Here is the question of policy presented, and it deals with this question as a matter of fact. Gentlemen may entertain just such opinions as they please with reference to the question of power, but I want the distinct expression of an earnest desire on the part of the people of Virginia for a peaceable settlement of the questions referred to in this resolution. Why should not this be
                done? Has not this committee, in this very report, laid down the doctrine in unmistakable terms, that this question should be peaceably dealt with? Have we not announced in one or two places, and expressed the positive opinion, <PageNumber num={245} /> that coercion must result in a termination of our relations with the Federal Government?
              </p>
              <p className="p-in-sp">
                Is there any doubt upon that subject in the mind of any man who will carefully read the report which is now the subject Of consideration? None, sir. Then, is there any difference of opinion upon the question whether the Confederated States are to be regarded as a Government de facto? I have listened to debates from all sides of this House; I have heard the opinions of gentlemen
                expressed upon this subject, and I have yet to hear the first member of the Committee who has expressed a contrary opinion. Then, sir, if they have established for themselves a Government de facto, is it improper for us to express the desire ( if the Federal authorities have the power) that they shall carry out the whole spirit and object of this report; that this Committee shall
                express its earnest desire, representing, as it does, the popular voice of Virginia, that the independence of this Government, de facto, shall be recognized, and that such treaties be made, and such laws be passed, as, under the circumstances, and with a view to the preservation of peace, may be proper and expedient?
              </p>
              <p>The yeas and nays were demanded and ordered.</p>
              <p>
                The question was then taken and the amendment was rejected—yeas 64, nays 72 as follows : <span className="note" id="Note31"><span className="noteLabel">2</span>The Journal includes Boggess among the 72 negative votes it lists.</span>
              </p>
              <p>
                YEAS-Messrs. Ambler, James Barbour, Blakey, Boisseau, Borst, Bouldin, Branch, Cecil, Chambliss, Chapman, Coffman, Conn, Richard H. Cox, Echols, Fisher, Flournoy, Garland, Graham, Gregory, Jr., Goggin, John Goode, Jr., Hale, Cyrus Hall, L. S. Hall, Hammond, Harvie, Hoge, Holcombe, Hunton, Isbell, Kent, Kilby, Kindred, Lawson, Leake, Macfarland, Charles K. Mallory, James B. Mallory,
                Marr, Marye, Sr., Miller, Moffett, Montague, Morris, Morton, Neblett, Parks, Randolph, Richardson, William C. Scott, Seawell, Sheffey, Speed, Staples, Strange, Sutherlin, Thornton, Tredway, Robert H. Turner, Tyler, Williams, Wilson, Wise, Wysor-64.
              </p>
              <p>NAYS-Messrs. Janney [President]
                , Armstrong, Aston, Baldwin, Alfred M. Barbour, Baylor, Berlin, Blow, Jr., Brent, Brown, Burdett, Burley, Byrne, Campbell, Caperton, Carlile, Carter, C. B. Conrad, R. Y. Conrad, Couch, Critcher, Custis, Dent, Deskins, Early, French, Fugate, Gillespie, Gravely, Addison Hall, Ephraim B. Hall, Haymond, Holladay, Hubbard, Hughes, Jackson, Marmaduke Johnson, Peter C. Johnston, Lewis,
                McComas, McGrew, McNeil, Marshall, Maslin, Masters, Moffett, Moore, Nelson, Osburn, Patrick, Pendleton, Porter, Preston, Price, Pugh, Rives, Robert E. Scott, Sharp, Sitlington, Slaughter, Southall, Spurlock, A. H. H. Stuart, C. J. Stuart, Summers, Tarr, Tayloe, Waller, White, Wickham, Willey-72.
              </p>
              <p>After the call of the roll had been completed <span className="persName">Mr. GRAY</span> said that he had paired off with <span className="persName">Mr. TURNER</span>, of Jackson.</p>
              <PageNumber num={246} />
              <p><span className="persName">Mr. HULL</span> said that he had paired off with <span className="persName">Mr. FORBES</span> on all questions upon which he stood opposed to <span className="persName">Mr. HARVIE</span>.</p>
              <div className="speaker" id="sp2360"><span className="persName">Mr. BOULDIN</span>—</div>
              <p className="p-in-sp">I would like to offer a substitute for the whole of the 9th resolution, if it is in order to do so ; and I offer it with a view of afterwards striking out the 10th resolution, so as to make the substitute I now offer, a substitute for the 9th and 10th resolutions.</p>
              <p><span className="persName">Mr. BOULDIN</span>'S substitute was read, as follows :</p>
              <p>
                "The States of South Carolina, Georgia, Florida, Mississippi, Alabama, Louisiana, and Texas, having withdrawn from their association with the other States of the Union under the Federal Government and united in a separate Confederacy, the State of Virginia is of opinion and hereby declares, that the separate independence of the seceded States ought to be acknowledged without further
                delay, and that such treaties should be made with them, and such laws passed as the separation may make proper."
              </p>
              <div className="speaker" id="sp2361"><span className="persName">Mr. BOULDIN</span>—</div>
              <p className="p-in-sp">
                Mr. Chairman, I desire to submit to the Convention a few remarks in explanation of that substitute. The question which has just been discussed as to the power of the Federal Government directly to acknowledge the separate independence of the seceded States is avoided, as will be seen, by the resolution. This substitute affirms distinctly and unequivocally the opinion of Virginia,
                that the separate independence of those seceded States ought at once to be acknowledged, that proper treaties ought at once to be made with those States, and all laws necessary and proper for the condition of things growing out of the separation, ought at once to be passed. The substitute is commended to my mind from the fact that we may avoid what seems to me to be a dangerous
                concession in the original proposition. The original proposition concedes the fact that the Federal Government has no power to deal with the question, and the inevitable effect of such concession is that it is left to the remaining States themselves in their sovereign capacity. I confess, Mr. Chairman, that when this idea was at first presented, I thought it a concession to State
                rights. I was disposed to receive it with favor. I thought it was a concession of a principle which I had always recognized, and which had guided me through life. But I am satisfied, upon further examination, that it is dangerous to the State rights policy.
              </p>
              <p className="p-in-sp">Sir, I desire to call the attention of the Committee to the position in which we would be placed by admitting the position assumed by the gentleman from Fauquier [<span className="persName">Mr. SCOTT</span>]
                , when he argued this question yesterday. He said the States must confer that power. They are now <PageNumber num={247} /> acting under the Federal constitution through which they can confer it in a particular mode, and in that mode alone; and that is by and through that Government by amendments to the constitution, and the mode in which that
                constitution is to be, and can only be amended as prescribed therein.
              </p>
              <p className="p-in-sp">
                Now, sir, let us see what will be the position of the seceded States in order to get their separate independence recognized and acknowledged, and their rights accorded to them, if Virginia, North Carolina and Arkansas shall follow the lead of the seven States now confederated in a separate Republic and out of this Union. There will then be ten States out of the Union. The idea is
                that until recognized by the Federal Government they still remain integral portions of the old Union, and it requires three-fourths of all the States in the Union under the Constitution to make an amendment to that instrument. There will then be but twenty-four States left, and three-fourths of all the States will require twenty-six, so that the twenty-four, until the end of time,
                never can confer the power, in the mode in which my distinguished friend from Fauquier.
                [<span className="persName">Mr. SCOTT</span>]
                , has told us is the only mode by which this power can be conferred, and that is by delegating the power through their common agent—the Federal Government. I take the view that the States which have gone had a right to secede, that they are outside the Union, and are foreign powers, and that the constitutiOn confers upon the States that still remain and which claim this Union as
                their Union, and this Government as their Government, the power, through that Government, to treat with those States that have seceded, now foreign States, as foreign States.
              </p>
              <p className="p-in-sp">
                Mr. Chairman, there is another objection that I have to the admission in the resolution. It is admitted here that the present authorities of the Federal Government disclaim the power to deal with this subject. This resolution was probably prepared before Mr. Lincoln went into office. I have seen no indication -of a. disclaimer of power to deal, either peaceably or forcibly, with this
                question by the present authorities of the Federal Government, and it is with them and with them alone that we have to deal. We hear of wars and rumors of wars daily. But I know not that the present Federal Government has disclaimed any such power. I rather think they have avoided such disclaimer. They are made to say, I apprehend, that which is inconsistent with the present position
                of affairs. This brief explanation is all I desire to make.
              </p>
              <p className="p-in-sp">It is suggested by one of my friends that I should repeat, that my <PageNumber num={248} /> proposition expressly avoids either affirming or disaffirming, but is so shaped that the voice of Virginia shall be heard in reference to the matter, and every gentleman can vote for it.</p>
              <div className="speaker" id="sp2362"><span className="persName">Mr. CONRAD</span>, of Frederick—</div>
              <p className="p-in-sp">Mr. Chairman, the object of each of the amendments offered to this section, including the one now offered by the gentleman from Charlotte [<span className="persName">Mr. BOULDIN</span>]
                , is certainly, as has been said, one and the same. It is to preserve the peace of the country as between the Federal Government and these Confederated States. The objection which has been made to the section as it stands in the report of the Committee is, that it affirms by implication a want of power on the part of the Federal authorities to deal with this subject; and the
                amendment proposed, as the gentleman states, expressly avoids any affirmation upon the subject. I admit, sir, that the report of the Committee is equivocal upon this question; but when we come to the tenth resolution, I shall propose to amend it so as to make the declaration that the Federal authorities have no power, in any aspect, to deal with this question.
              </p>
              <p className="p-in-sp">
                Now, sir, the only enquiry before the Committee is, which is the best mode to attain the common object—either to avoid any declaration in regard to the power of the Federal authorities concerning that question, or, if we can properly make an affirmation, to deny to the Federal authorities all power to deal with the subject in any respect. Because, sir, I hold it to be clear that
                gentlemen who concede the power to deal with these seceded States by making treaties with them, and to acknowledge that they have withdrawn from the Federal authority, must be prepared also to admit that the Federal authority has a correlative right to deal with this subject in the other alternative ; that is, that they have the right of coercion, the right of war.
              </p>
              <p className="p-in-sp">
                Well, sir, is it better to ask of this Federal authority as a matter of favor, as a mere matter of forbearance, that it will not exercise this power of coercion, but will adopt the alternative of a peaceful adjustment of the difficulty with these States, or is it better to deny, if we have a right to deny—as I hold we have—to this government the power over the subject? I do not think
                the difficulty mentioned by the gentleman from Charlotte
                [<span className="persName">Mr. BOULDIN</span>]
                , does arise here—that it would require three-fourths of all the original States of the Union to amend the Constitution so as to confer the power. In the mean time, no injury could be done. The Federal Government is paralyzed. It can preserve the status quo. It can use no power of aggression upon the seceded States without, as we say, a palpable usurpation of power not granted in the
                Constitution.
              </p>
              <PageNumber num={249} />
              <p className="p-in-sp">
                And, sir, I confess, that I have been utterly at a loss to discover in the Federal Constitution, in its objects, in its terms, any grant of power, either to recognize the disruption of the Government, of which it is the common agent, or to force back any one of the States of this Union after such State has overthrown the Federal authority within its limits. I hold that when the
                States have overthrown the Federal authority, they are out of the authority of the Federal Government. The remaining States still have a common agent, for, although they cannot dissolve the Union as to themselves, they can dissolve the Union as to the others; and this government was never created for the purpose of dealing with the extraordinary position of things by which we are
                surrounded. It is a Government of limited powers. Has my time expired yet, sir?
              </p>
              <div className="speaker" id="sp2363"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">No, sir, you have several minutes yet.</p>
              <div className="speaker" id="sp2364"><span className="persName">Mr. CONRAD</span>—</div>
              <p className="p-in-sp">
                Then I shall press this subject a little further, because I really consider it all-important. It is a novel, and I admit, a difficult question. It is one that has never occurred to my mind before. There has never arrived an occasion to consider this question before; and therefore it is that such diversity of opinion exists among the members of this body upon the subject. But I put it
                to any gentleman to examine the Federal Constitution—especially to those gentlemen who have been in the habit of construing that instrument strictly, who have called themselves States-rights men—I put it to them to examine the Federal Constitution, to grant the concession only, that the Government is a Government of limited powers, that it can exercise no powers not expressly
                delegated in the grant, or an incidental power necessary to carry out some express power; and then tell me where is the power granted to acknowledge the disruption of the very constituents which have erected it into a common Governmental agent?
              </p>
              <p className="p-in-sp">
                Sir, it is not in the treaty-making power, for that gives the Government authority to make treaties with foreign nations. It is not in the war power, for that gives authority to make war upon foreign nations. Here is a State, one of the integral constituents of this very Government, which overthrows the authority of the Government which its agency had assisted in creating for the
                common benefit of all, in peaceful and harmonious operation. Was this contingency foreseen at the formation of the Government and provided for in the Federal Constitution? Is there a word in that Constitution pointing to such a condition of things as now surround us? I apprehend not, sir. If there is, I have been unable to discover it. I deny—as gentlemen of the State rights school
                always have denied—that this Government <PageNumber num={250} /> has any inherent governmental power. I deny that, according to our ideas of Government, it is anything more than a mere agency for the benefit of the people who constitute it.
              </p>
              <p className="p-in-sp">
                I deny that it is even an entity in any such sense as Governments are regarded according to the old European idea. It is not even an entity; it is a mere agency. Agency for what? Can an agency deal with a subject not contemplated at all, and not provided for, in the instrument conferring powers upon the agency? And especially when it goes to break up the constituency which appointed
                the agency?
              </p>
              <p>Here the hammer fell.</p>
              <div className="speaker" id="sp2365"><span className="persName">Mr. BOULDIN</span>—</div>
              <p className="p-in-sp">
                With all respect to my able friend from Frederick, I submit that the argument he himself has submitted is sufficient to show the propriety of the position I have assumed. Sir, the question we have to meet in this proposition is whether the Confederated States, the present condition of which we are now treating in this resolution, are now in the Union or out of the Union. The
                distinguished gentleman from Frederick, in the course of his remarks has, in my humble judgment, made a concession which is fatal to the residue of his argument. It is this : He was compelled to concede, in order to meet the force of the argument I presented to the Committee when I was up before, that the seven seceded States are not now integral parts of the Union. Then what are
                they? They cannot be in the Union and out of the Union; they cannot be States in the Confederacy and foreign States at the same time ; they must, of necessity, be either foreign States or States in the Union, there can be no intermediate position, there can be no transition state. He has conceded that they are foreign States, in order to meet the argument—for he could meet in no
                other way. Then, sir, I beg to know, in the first place, if they are foreign States, whence he can derive the argument that there is not a plain grant in the Constitution, both in its letter and its spirit, to treat with foreign States? He asked me to point out any clause in the Constitution conferring upon the General Government the power to treat with the Confederate States. I
                point him to that clause in the Constitution which confers upon the government, as a common agent, the power of treating with and of entering into alliances with foreign governments.
              </p>
              <p className="p-in-sp">
                What is the condition of these States after their secession? Are they a government or not? Are we a government or not? Does Virginia claim that the Federal Government is her agent for the purpose of exercising this power of sovereignty which she exercises in treating with foreign powers? If the Federal Government is her agent for that
                <PageNumber num={251} /> purpose, has that Government powers to treat with this new Government which has just now sprung into existence? If not, has the Government the power to recognize any new Government which has sprung into existence since the formation of the Constitution? If the gentleman would argue that the Federal Government cannot recognize
                the Government of the Confederate States—admitting that they constitute a Government—because they are a Government; the same argument would require that the Government of the United States should recognize no Government that was not in existence at the time of the formation of the Constitution of the United States. To such an extremity does the argument of the gentleman drive him. If
                the Federal Government has properly recognized any Government which has sprung into existence since its agency was conferred upon it in the formation of the Constitution, then it may properly recognize the Government of the Confederate States.
              </p>
              <p className="p-in-sp">Sir, permit me to say, further, that I claim this power as beneficial in its effect, and as, in fact, an indispensable necessity for the preservation of peace between the States; and in this connexion I shall repeat, in a measure, the argument I have already presented. The gentleman from Fauquier [<span className="persName">Mr. SCOTT</span>] , differs widely from the gentleman from Frederick [<span className="persName">Mr. CONRAD</span>]
                , as to the practicability of securing any satisfactory amendments to the Constitution; for, in the able and, to my mind, perfectly conclusive argument of the gentleman from Fauquier, the other day, when he was in a more hopeless condition of mind than he seemed to be yesterday, he demonstrated the utter futility of any attempt to amend the Constitution, because the seceded States,
                upon the theory of the Government at Washington, must count as a part of the Federal Union, and yet the Constitution says, that for the adoption of any amendment to the Constitution, there must be a concurrence of three-fourths of all the States. I put, then, the argument of the distinguished gentleman from Fauquier before the Committee, against the argument of my able friend from
                Frederick; for he has answered the argument of the gentleman from Frederick more perfectly than anything I could utter.
              </p>
              <p className="p-in-sp">
                I repeat, Mr. Chairman, that upon the first reading of this section of the report of the Committee, I was pleased with it; I saw no objection to it, until I came to trace its consequences, and especially after the concession of the gentleman from Fauquier, that in failing to recognize the independence of the seceded States until the votes of three fourths of the seceded States shall
                have been obtained; and the fact, that if in the meantime three States more shall have seceded, the <PageNumber num={252} /> door to such amendment of the Constitution will be forever closed, unless you admit that the union of the balance of the States is dissolved into its original elements, and as such may meet to form a new Government. But, sir,
                there is another objection. I do not want to affirm any want of power which implies the necessity of my appealing to that inexorable Black Republican majority of the Northern States to meet in Convention and give me help. I do not wish to concede any want of power to act, until I can obtain from the Northern States the concession of my right to act. I do not want to place the rights
                and interests of my constituents at the mercy of a Black Republican majority of the Northern States. With these remarks, sir, I submit the amendment.
              </p>
              <p><span className="persName">Mr. FISHER</span> called for the yeas and nays upon the adoption of the amendment.</p>
              <p>The yeas and nays were ordered.</p>
              <p>
                The question was taken and it was decided in the negative—yeas 68, nays 71, as follows : <span className="note" id="Note32"><span className="noteLabel">3</span>The Journal lists the same 69 affirmative votes given in the Proceedings. In the Journal, the name of Jackson makes up the 71 negative votes.</span>
              </p>
              <p>
                YEAS-Messrs. Ambler, James Barbour, Blakey, Blow, Jr., Boisseau, Borst, Bouldin, Branch, Bruce, Cecil, Chambliss, Chapman, Coffman, Conn, J. H. Cox, R. H. Cox, Echols, Fisher, Flournoy, Garland, Graham, Gregory, Jr., Goggin, J. Goode, Jr., Hale, A. Hall, C. Hall, L. S. Hall, Harvie, Holcombe, Hunton, Isbell, Kent, Kilby, Kindred, Lawson, Leake, Macfarland, C. K. Mallory, J. B.
                Mallory, Marr, Marye, Sr., Miller, Montague, Morris, Morton, Neblett, Parks, Randolph, Richardson, W. C. Scott, Seawell, Sheffey, Slaughter, Southall, Speed, Staples, Strange, Sutherlin, Thornton, Tredway, R. H. Turner, F. P. Turner, Tyler, Waller, Williams, Wilson, Wise, Wysor-68.
              </p>
              <p>NAYS-Messrs. Janney [President]
                , Armstrong, Aston, Baldwin, A. M. Barbour, Baylor, Berlin, Boggess, Boyd, Brent, Brown, Burdett, Burley, Byrne, Campbell, Caperton, Carlile, Carter, C. B. Conrad, R. Y. Conrad, Couch, Critcher, Custis, Dent, Deskins, Early, French, Fugate, Gillespie, Gravely, Gray, E. B. Hall, Hammond, Haymond, Hoge, Holladay, Hubbard, Hughes, M. Johnson, P. C. Johnston, Lewis, McComas, McGrew,
                McNeil, Marshall, Maslin, Masters, Moffett, Moore, Nelson, Osburn, Patrick, Pendleton, Porter, Preston, Price, Pugh, Rives, R. E. Scott, Sharp, Sitlington, Spurlock, A. H. H. Stuart, C. J. Stuart, Summers, Tarr, Tayloe, White, Wickham, Willey-71.
              </p>
              <p>So the amendment was disagreed to.</p>
              <p>During the call of the roll <span className="persName">Mr. WOODS</span> stated that he had paired off with <span className="persName">Mr. ORRICK</span>.</p>
              <p>The ninth resolution of the Committee of Twenty-One being under consideration in the Committee of the Whole—</p>
              <div className="speaker" id="sp2366"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I move to amend the ninth section in the 11th line, by striking out from the word "is," as follows :</p>
              <p className="p-in-sp">"Disclaim power to recognize the withdrawal of any State from the <PageNumber num={253} /> Union, and consequently to deal with these questions, holding that it is reserved only to the States as parties to the compact to take lawful action touching them."</p>
              <p className="p-in-sp">And by inserting as follows :</p>
              <p className="p-in-sp">"Are bound to forbear the exercise of powers touching these questions until the States, as parties to the Federal compact, take action thereon."</p>
              <p className="p-in-sp">So that the clause will read :</p>
              <p className="p-in-sp">"But the Federal authorities, under the Constitution as it is, are bound to forbear," &amp;c.</p>
              <p className="p-in-sp">
                Mr. Chairman, as to these two resolutions, or rather as to the preamble contained in the 9th and 10th resolutions, I have to say that they were very ingeniously drawn to cover up the "cat in the meal tub" of a National Convention. The gentleman from Fauquier yesterday pretty distinctly disclosed that that proposition was calculated upon as the effect of the resolution—without
                claiming it—on the ground that the Federal authorities had disclaimed the power of peace with the Confederate States. He avows a desire to confer powers of peace, and to do that, he admits that he has to resort to a National Convention, or the other mode of amending the Constitution of the United States. My friend from Charlotte
                [<span className="persName">Mr. BOULDIN</span>]
                , has relieved me from any necessity for an answer upon that subject, but I rise to call the attention of my friend from Frederick, the Chairman of the Committee of twenty-one, to the assertion that he made to-day, that the Federal Government has power to treat only with foreign powers. I deny the proposition. In the Constitution of the United States you have four provisions touching
                treaties. In the first place it is provided that "no State shall enter into any treaty of alliance," &amp;c. Of course it is understood that that clause refers to a State in the Union.
              </p>
              <p className="p-in-sp">Again—clause three, of section ten, of article one :</p>
              <p className="p-in-sp">"No State shall, without the consent of Congress, lay any duty of tonnage, keep troops or ships of war in time of peace, enter into any agreement or compact with another State or with a foreign power, or engage in war unless actually invaded, or in such imminent danger as will not admit of delay."</p>
              <p className="p-in-sp">Now, is there not the power of treaty by implication with these States? Can they not make a compact or agreement with the other States? But if this is considered as straining a point, I come now to the main power itself of treaty-making conferred upon the Executive department in connection with the Senate by the Constitution of the United States :</p>
              <PageNumber num={254} />
              <p className="p-in-sp">"The President shall have power by and with the consent of the Senate to make treaties."</p>
              <p className="p-in-sp">
                Treaties with whom? It does not say with foreign powers, as the gentleman from Frederick interpolated into the phrase of the Constitution. Sir, you have made treaty after treaty, compact after compact, with new States as they have been admitted into the Union. You made a compact with Virginia when she ceded Kentucky, a treaty that is immutable between the United States and the State
                of Virginia. You made a compact with North Carolina when she ceded Tennessee, an immutable treaty. You made a compact with Georgia when she ceded Mississippi to you. You made a compact with Virginia again even before the Constitution was formed and confirmed it afterwards, in respect to the North-Western territory. When you admit any State into the Union you make treaties with that
                State in respect to the Indian tribes, in respect to the right of taxing the public lands : you even deprive the State by treaty of its power of eminent domain over the public lands within its limits. You have gone further than that. You have taken first a portion of the States of Virginia and Maryland, constituting the District of Columbia, and the Federal Government has exercised
                the power of ceding back a portion of that District. Just the other day, the Federal Government ceded to you its control over the Bellona Arsenal. But all this is nothing, compared with the treaty-making power you exercised by legislation with Texas. What did you do in the case of Texas? In the first place, you dispensed with the treaty-making power contemplated by the Constitution
                itself. You actually put the treaty-making power aside, and adopted legislation. Against the recommendation of my friend, then President of the United States
                [<span className="persName">Mr. TYLER</span>]
                , you admitted Texas into the Union, and made her a component part of the Union by legislation, as before you had made Louisiana a component part of the Union by treaty. And what more have you done? After Texas came into the Union, you made another treaty with her; a treaty in the form of a tender by legislation, that, for a cession of 44,000 square miles of her territory, you should
                pay to Texas $10,000,000.
              </p>
              <p className="p-in-sp">
                But in the face of all this history, in face of all the treaties, compacts and agreements, immutable and unchangeable, made under the powers conferred in these four clauses of the Constitution, you now pretend to give countenance, by this resolution, to the doctrine, that after a State has gone out of the Union, you cannot agree to allow her to remain in peace, but that you are to
                leave the peace of this country at the discretion, the sole, arbitrary discretion of the powers that be, <PageNumber num={255} /> with such dispositions and inclinations as possess them—you are to leave that peace at their mercy until you can call a National Convention, or until you can get three-fourths of the States to consent to an amendment of the
                Constitution, for the conservation of peace. Where is the guaranty of peace, in the meantime, while you are waiting for the consent of three fourths of the States, if there be three-fourths remaining to consent—as was very well said by the gentleman from Charlotte? How are you to preserve the peace while you are waiting, waiting, waiting for a National Convention?
              </p>
              <p className="p-in-sp">
                Sir, the gentleman from Fauquier has shadowed forth throughout, the idea of a National Convention, of delay, of procrastination, of time, as an element to compose the difficulties that surround us. There are two evil tendencies attaching to this policy. In the first place, its tendency is to keep us in a Northern Confederacy, or to force us eventually to a violent rupture with the
                Northern Confederacy. It either forces us by delay, by giving the serpent time to coil around us, until we share the fate of another Laocoon, or until we are exposed to the horrors of a civil war. Sir, I want the question now acted upon. A question comes before us this very day, showing the necessity of immediate action—the question of the Tortugas.
              </p>
              <p>Here the hammer fell.</p>
              <div className="speaker" id="sp2367"><span className="persName">Mr. SCOTT</span>, of Fauquier—</div>
              <p className="p-in-sp">My friend from Accomac—</p>
              <div className="speaker" id="sp2368"><span className="persName">Mr. WISE</span> [in his seat]—</div>
              <p className="p-in-sp">Princess Anne. I am royal.</p>
              <div className="stage it">[Laughter.]</div>
              <div className="speaker" id="sp2369"><span className="persName">Mr. SCOTT</span>—</div>
              <p className="p-in-sp">My friend from Princess Anne, has a wonderful faculty for discovering "cats in meal tubs." [Laughter.]
                As the gentleman from Princess Anne has moved an amendment—but in the course of a ten minutes speech, has said not one word in its advocacy—concurring, as I do, in what I understand to be the principle of the amendment, I beg leave to supply, in some degree, his deficiency. Now, sir, I have not time within the limits of ten minutes, to respond to that portion of his remarks addressed
                more particularly to myself. I desire to occupy my time in considering the proposition of his amendment.
              </p>
              <p className="p-in-sp">When a State withdraws from this Union, I understand that it breaks a Government compact. I desire to know from the gentleman from Charlotte [<span className="persName">Mr. BOULDIN</span>]
                -I need not put the question to the gentleman from Princess Anne—whether, in such case, the withdrawing State incurs any liability growing out of that act; and if a liability is incurred, I desire further to know, to whom does it accrue? Does it accrue to the Government from which she withdraws, or does it accrue to the other parties to the government compact? In fact, sir, when a
                bargain is broken even between independent sovereignties, some liability <PageNumber num={256} /> attaches growing out of the broken bargain. I desire to know in what quarter the liability is found? If it is not to the government, must it not necessarily be to the other parties to the compact? And if it be only to the parties to the compact, does it not
                follow necessarily that those parties only can deal with the question? I understand the amendment of the gentleman from Princess Anne to concede that the questions growing out of State secession are to be dealt with by the other States as parties to the government compact, and that his amendment announces the proposition that the government, as an agent, must forbear to deal with the
                questions growing out of it until the States who are the other parties to the compact take action. That, sir, is the principle of the resolutions reported by the committee. That is the very doctrine intended to be announced by the 9th and 10th resolutions.
              </p>
              <div className="speaker" id="sp2370"><span className="persName">Mr. WISE</span> [in his seat]—</div>
              <p className="p-in-sp">They do not touch it—either of them.</p>
              <div className="speaker" id="sp2371"><span className="persName">Mr. SCOTT</span>—</div>
              <p className="p-in-sp">
                Now, sir, entertaining exactly the opinion which I understand the gentleman from Princess Anne to entertain, that it is reserved for the other States, as parties to this bargain or compact, to take action upon this question, I would ask him in what manner he proposes this action to be taken? A National Convention he seems to have a mortal horror of ; yet, he says, these other States
                are to take action.
              </p>
              <div className="speaker" id="sp2372"><span className="persName">Mr. WISE</span> [in his seat]—</div>
              <p className="p-in-sp">No, I do not.</p>
              <div className="speaker" id="sp2373"><span className="persName">Mr. SCOTT</span>—</div>
              <p className="p-in-sp">I understand the amendment of the gentleman to say that the government shall forbear until the other parties take action concerning it. The amendment, therefore, contemplates action upon the part of the other States. And what I desire to know is in what manner the gentleman expects that action to be taken?</p>
              <p className="p-in-sp">They may take it through a general convention, or they may take it in another way, as may seem most proper and desirable. But I say to the gentleman from Charlotte, that I think he altogether misunderstood the argument of the gentleman from Frederick [<span className="persName">Mr. CONRAD</span>]
                , when he supposed that from anything that fell from him he had the right to conclude that these withdrawing States were still to be regarded as members of the Confederacy. I understand all of us concede that these States have withdrawn and are without the obligations of the Government compact; and it remains for the other States to take such action touching their withdrawal as to
                them seems proper. And we mean to deny the power upon the part of the Federal Government, to deal with these questions at all. Now, how the States are to take action is a question for them alone to determine. What action <PageNumber num={257} /> they are to take is a question for them to decide, and I cannot see the point of difference between the
                amendment proposed by the gentleman from Princess Anne, and the proposition of the Committee in their report—I mean any substantial difference in principle. The purpose of the amendment, if I comprehend it, seems to be the same as the purpose of the resolution, that is to announce in respect to these new and novel questions, that the government shall not take action, but that all
                action remains to be taken by the other parties to the compact; and that if they desire the government to act as their agent, they must communicate new power.
              </p>
              <p>Here the hammer fell.</p>
              <div className="speaker" id="sp2374"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                The gentleman says I have a wonderful faculty for finding out "cats in the meal." Let me say that if I have such a faculty, it is because he has a very remarkable one of covering up so many cats in the meal. I never saw a gentleman so thoroughly secretive in his propensities as I find the gentleman to be. I have watched him constantly. I have to watch him constantly, and he has
                trained me to a pretty good understanding of his purposes.
              </p>
              <p className="p-in-sp">
                Now, sir, the gentleman says that the report itself, in the 9th and 10th sections asserts the same principle as the amendment. It does no such thing. What do the Committee on Federal Relations assert? That the Federal Government is to forbear? This Committee asserts no such thing anywhere. The language is that "the Federal authorities, under the Constitution as it is, disclaim the
                power to recognize the withdrawal of any State," &amp;c. The report says no such thing as that the Federal authorities are to forbear, touching these questions; but they merely avow the fact that the Federal authorities disclaim the power to recognize the withdrawal of a State. I do not concur with the Federal authorities, and I want that we shall impose a most authoritative demand
                upon them until the States shall act. And there, the gentleman takes a turn upon me that is not a fair one. He says that my amendment is intended to make the States act. I do not care whether they act or not. My resolution proposes no such thing as that they shall or shall not act. I say they may or may not act. All I want to say here is that until they do act—or whether they act or
                not—the Federal Government shall be instructed to forbear to exercise the powers of executing the laws within the jurisdiction of the seceded States until the other parties to the Federal Compact shall act. So that there is no difficulty there. The resolutions do not declare that the States shall act, but to satisfy the gentleman on that point if this
                <PageNumber num={258} /> amendment shall be adopted, when we come to the 10th section I shall propose an amendment to show how they should act.
              </p>
              <p className="p-in-sp">I shall then propose that the people of Virginia "hereby declare their consent, for themselves, to the recognition of the separate independence of the seceded States—that they shall be treated with as independent powers, and that the proper laws shall be passed to give effect to such recognition."</p>
              <p className="p-in-sp">
                Well, sir, suppose the other States do not act—suppose that they are a long time in acting—still, I wish this Convention, speaking for the sovereign people of Virginia, to say that we require that the Federal authorities shall not merely disclaim the power to treat with those States as independent powers, but shall forbear to attempt to execute laws over them which they disclaim. The
                resolution reported by this Committee leaves them free to execute the laws against the seceded States, and thereby to bring upon us civil war.
              </p>
              <p className="p-in-sp">
                Now, I contended, in my first ten minutes, that the Federal Government has power, already, to make compacts with them, to make a treaty with them, to recognize them—by the convertible forms by which we admitted Louisiana. I deny the doctrine that they have not the power to keep the peace. They have the power to treat with them. The President of the United States might have submitted
                an agreement to the Senate, and, if confirmed by the Senate, it would have been, by the fourth provision of the Constitution in regard to treaties, the supreme law of the land. I believe that to disclaim the power to treat, and yet to leave with them the power of executing the laws in the meantime, until the power to treat shall be conferred, is to expose the very heart of the nation
                to civil war. The argument of the gentleman from Charlotte
                [<span className="persName">Mr. BOULDIN</span>] , has not been answered yet.</p>
              <p className="p-in-sp">But conceal it as the gentleman [<span className="persName">Mr. R. Y. CONRAD</span>] may, move directly or indirectly as he may, his aim is but too apparent. It is for delay, and for all the machinery that will obstruct a speedy termination of this question. I tell him now, for my people, that his course is making more precipitationists than any other one cause.</p>
              <p className="p-in-sp">
                This delay, this suspense, this prostration of business, this state of dubiety, this distraction, is kindling fire upon the fever which will soon burst out into the most terrible consequences, unless we come to some more immediate and more decisive action. The time is past to soothe and coax, and honey-fugle this people into submission. The time is too dangerous for delay, which will
                allow the boa-constrictor to enfold us to our death. You ought now to take your independent stand and speak to the present Government; you ought now to have a commissioner <PageNumber num={259} /> in Washington to demand, in the presence of the President of the United States, whether his purpose is peace or war.
              </p>
              <p className="p-in-sp">We ought to be informed at this moment what is the meaning of these armaments that have gone South. We ought to be informed what is the point which they intend to make as to Tortugas and Fort Pickens. We ought to tell them distinctly that they must forbear while we are making efforts, long or short, to keep the peace and defend our rights.</p>
              <p className="p-in-sp">The gentleman from Fauquier [<span className="persName">Mr. ROBERT. E. SCOTT</span>]
                , takes the ground incorrectly, that there is any concurrence or semblance of concurrence between the proposition which I now make, and those two resolutions of the Committee of Twenty-one. Theirs leave the Federal Government the power to execute the law. They have not restrained that power to bring upon us civil war at any moment. They leave us to all the delay and all the machinery
                of a national Convention, or an amendment of the Constitution to attain peace. My proposition says to the powers at Washington : "You must forbear to execute the laws over this people until the parties to the Federal compact—when, it matters not; soon or late, it matters not—can make an arrangement. Forbear to bring States and States into conflict, power and power into conflict,
                Confederacy into conflict with Confederacy."
              </p>
              <p className="p-in-sp">At the request of some friends, I propose to change my amendment by substituting for the word "until the States parties to the Federal compact shall act," the words "until pending difficulties are adjusted."</p>
              <p>There being no objection the amendment was so modified.</p>
              <div className="speaker" id="sp2375"><span className="persName">Mr. WM. C. SCOTT</span>—</div>
              <p className="p-in-sp">I propose to amend the amendment by striking out the words "are bound to" and inserting in lieu thereof the word "should," so that it shall read : "should forbear the exercise of powers," &amp;c.</p>
              <div className="speaker" id="sp2376"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I care nothing about the words. To me they are equivalent. I accept the proposed amendment if the Committee have no objection.</p>
              <p>There being no objection the amendment was so modified.</p>
              <p>The question was taken on <span className="persName">Mr. WISE</span>'S amendment, and, on division, there were, ayes 49, noes 76.</p>
              <p>So the amendment was rejected.</p>
              <p>The question recurred on the 9th resolution as amended.</p>
              <div className="speaker" id="sp2377"><span className="persName">Mr. GARLAND</span>—</div>
              <p className="p-in-sp">
                If I understand that resolution aright there is a clause in it which I would like to have stricken out. I move to strike out, in the third line, the words "without the assent of the other <PageNumber num={260} /> States." I will state, in a few words, the point of my objection. That clause does not declare directly that the assent of the States in the
                Union shall be given to the withdrawal of the seceding States; but it might be inferred, from implication, from the words which I propose to strike out, that such assent was necessary. That idea would be in conflict with my opinions and, perhaps, with the opinions of others. If it does not convey that idea, then it seems to me the words are unnecessary and mere surplusage, and that
                they may be stricken out without in the least impairing the harmony and compactness of the other part of the section. This is my view of it, and therefore I submit the amendment.
              </p>
              <p><span className="persName">Mr. WISE</span> called for the yeas and nays on the amendment. The yeas and nays were ordered.</p>
              <p>
                The question was taken and it was decided in the negative—yeas 54, nays 80 ; as follows :<span className="note" id="Note33"><span className="noteLabel">4</span>The Journal includes Marye, but not Williams in the affirmative votes; in the Journal, McComas and Preston are among the negative votes, but Marye is not.</span>
              </p>
              <p>
                YEAS-Messrs. Ambler, J. Barbour, Blakey, Boisseau, Borst, Bouldin, Branch, Bruce, Cecil, Chambliss, Chapman, Coffman, Conn, R. H. Cox, Echols, Fisher, Flournoy, Garland, Graham, Gregory, J. Goode, Hale, C. Hall, L. S. Hall, Hammond, Harvie, Holcombe, Hunton, Isbell, Kent, Kindred, Lawson, Leake, C. K. Mallory, J. B. Mallory, Miller, Montague, Morris, Morton, Neblett, Randolph,
                Richardson, W. C. Scott, Seawell, Sheffey, Speed, Strange, Sutherlin, Thornton, R. H. Turner, Williams, Wise, Woods, Wysor-54.
              </p>
              <p>NAYS-Messrs. Janney [President]
                , Armstrong, Aston, Baldwin, Baylor, Berlin, Blow, Boggess, Boyd, Brent, Brown, Burdett, Burley, Byrne, Campbell, Caperton, Carlile, Carter, C. B. Conrad, R. Y. Conrad, Couch, Critcher, Custis, Dent, Deskins, Early, French, Fugate, Gillespie, Gravely, Goggin, A. Hall, E. B. Hall, Haymond, Hoge, Holladay, Hubbard, Hughes, Jackson, M. Johnson, P. C. Johnston, Kilby, Lewis, McGrew,
                McNeil, Macfarland, Marshall, Marr, Marye, Maslin, Masters, Moffett, Moore, Nelson, Osburn, Parks, Patrick, Pendleton, Porter, Price, Pugh, Rives, R. E. Scott, Sharp, Sitlington, Slaughter, Southall, Spurlock, Staples, A. H. H. Stuart, C. J. Stuart, Summers, Tarr, Tayloe, Waller, White, Wickham, Willey, Wilson-80.
              </p>
              <p>So the amendment was rejected.</p>
              <div className="speaker" id="sp2378"><span className="persName">Mr. FISHER</span>—</div>
              <p className="p-in-sp">I desire to offer an amendment to the resolution, one which I think ought to be made. In the 10th line these words occur : "but the Federal authorities, under the Constitution as it is, disclaim power to recognize," &amp;c.</p>
              <p className="p-in-sp">I move to insert after the word "authorities" in the 10th line, the following: "as they were constituted when this section was drafted."</p>
              <p className="p-in-sp">
                I offer this, sir, because we all affirm, if that resolution shall be adopted, a fact that no man in this Convention knows to be true, and shall place ourselves in the position of a witness swearing to what he <PageNumber num={261} /> does not know to be true or false. We do not know how the Federal authorities, as at present constituted, stand upon
                this question ; we do not know what opinions they entertain upon it. It is true that Mr. Buchanan disclaimed that power, but I do not think that Mr. Lincoln disclaimed it. If we adopt that resolution to-day, we affirm that to be true which no gentleman on this floor knows to be true.
              </p>
              <div className="speaker" id="sp2379"><span className="persName">Mr. CONRAD</span>, of Frederick—</div>
              <p className="p-in-sp">
                Unless I am grossly deceived, the President of the United States both the present and the last President—have distinctly uttered the disclaimer that is mentioned here, that "they disclaim power to recognize the withdrawal of any State from the Union." Mr. Buchanan unquestionably did disclaim that power, and that disclaimer, as I understand, has never been withdrawn by any subsequent
                Federal Executive. That fact would be sufficient to maintain the affirmant here ; but, in addition to that, the present President, in his inaugural, if I am not mistaken, also disclaimed the power to recognize the withdrawal of any State from the Union; asserted his right and duty to execute the Federal laws within the seceded States, and has continued, so far as we know, up to this
                moment, to assert that authority and duty.
              </p>
              <p className="p-in-sp">Now, sir, if that does not involve a disclaimer of power to recognize the withdrawal of the States, I know not what would amount to a disclaimer of that power.</p>
              <div className="speaker" id="sp2380"><span className="persName">A DELEGATE</span>, from his seat—</div>
              <p className="p-in-sp">The latter part of the resolution says it has no power to deal with these questions.</p>
              <div className="speaker" id="sp2381"><span className="persName">Mr. CONRAD</span>—</div>
              <p className="p-in-sp">
                That is the mere inference of the Committee, "and consequently to deal with these questions." That is mere inference, as I have said. Because it has no power to recognize the withdrawal we say it has no power of coercion; and the whole object of this resolution is to protest against the exercise of any coercive power by the Federal authorities against these States, recognizing,
                however, in full force, the responsibility of these seceding States to their co-States in the Union.
              </p>
              <p className="p-in-sp">
                I have never held—although gentlemen seem to have so conceived the admission that these States or any States of this Union have a right to withdraw from the obligations of the government compact into which they have entered. They have a right to withdraw from the Federal authority, but their responsibility, for such withdrawal, to the extent of any injury which may have been done to
                the co-States or the other parties to the compact, still remains—remains to be asserted by the parties to the compact, and not by the Federal agent, who is not authorised to assert such rights.
              </p>
              <PageNumber num={262} />
              <div className="speaker" id="sp2382"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">Will it be in order to make an observation or two?</p>
              <div className="speaker" id="sp2383"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">No, sir.</p>
              <div className="speaker" id="sp2384"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">I was about to ask the Chairman of the Committee of Twenty-one a question. I will do so if it be in order.</p>
              <div className="speaker" id="sp2385"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Chair would think not, because the Chairman of the Committee would probably seek to answer the question, and that would lead to debate.</p>
              <div className="speaker" id="sp2386"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">Is the question divisible?</p>
              <div className="speaker" id="sp2387"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">No, sir, the question is not divisible.</p>
              <p>The question was then put upon the amendment offered by <span className="persName">Mr. FISHER</span>, and decided in the negative.</p>
              <p>The question then recurred upon agreeing to the resolution as amended.</p>
              <div className="speaker" id="sp2388"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I call for the yeas and nays upon that resolution.</p>
              <p>The call was sustained, and the question having been taken, resulted—yeas 92, nays 37—as follows :</p>
              <p>YEAS-Messrs. Janney [President]
                , Armstrong, Aston, Baldwin, A. M. Barbour, Baylor, Berlin, Blow, Boyd, Brent, Brown, Burdett, Burley, Byrne, Campbell, Caperton, Carter, Chapman, Coffman, C. B. Conrad, R. Y. Conrad, Couch, Critcher, Custis, Dent, Deskins, Early, Echols, Flournoy, French, Fugate, Garland, Gillespie, Gravely, Goggin, Hale, A. Hall, E. B. Hall, L. S. Hall, Hammond, Haymond, Hoge, Holladay, Hubbard,
                Hughes, Jackson, M. Johnson, P. C. Johnston, Kilby, Lewis, McComas, McGrew, McNeil, C. K. Mallory, J. B. Mallory, Marshall, Marr, Marye, Maslin, Masters, Moffett, Moore, Nelson, Orrick, Osburn, Parks, Patrick, Pendleton, Porter, Preston, Price, Pugh, Rives, R. E. Scott, Sharp, Sheffey, Sitlington, Slaughter, Southall, Spurlock, A. H. H. Stuart, C. J. Stuart, Strange, Summers,
                Sutherlin, Tarr, Tayloe, Waller, White, Wickham, Willey, Wilson-92.
              </p>
              <p>NAYS-Messrs. Ambler, Blakey, Boisseau, Borst, Bouldin, Branch, Cecil, Chambliss, Conn, R. H. Cox, Fisher, Graham, Gregory, J. Goode, Harvie, Holcombe, Hunton, Isbell, Kent, Leake, Macfarland, Miller, Montague, Morris, Morton, Randolph, Richardson, W. C. Scott, Seawell, Speed, Thornton, R. H. Turner, F. P. Turner, Tyler, Wise, Woods, Wysor-37.</p>
              <p>So the resolution was adopted.</p>
              <p><span className="persName">Mr. WILSON</span> stated, when <span className="persName">Mr. JAMES BARBOUR</span>'S name was called, that that gentleman paired off with the gentleman from Lewis county [<span className="persName">Mr. BoGGES S</span>] , and that both were absent.</p>
              <div className="speaker" id="sp2389"><span className="persName">Mr. PRESTON</span>, of Montgomery—</div>
              <p className="p-in-sp">I move that we now recur to the consideration of the 8th resolution.</p>
              <p>The motion was agreed to, and the 8th resolution was then taken up and read, as follows :</p>
              <PageNumber num={263} />
              <p>
                "The people of Virginia recognize the American principle that government is founded in the consent of the governed, and they concede the right of the people of the several States of this Union, for just causes, to withdraw from their association under the Federal Government with the people of the other States, and to erect new governments for their better security, and they will
                never consent that the Federal power, which is in part their power, shall be exerted for the purpose of subjugating the people of such States to the Federal authority."
              </p>
              <div className="speaker" id="sp2390"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">I move to amend this resolution by striking out all after the word "and," in the third line, and inserting the following :</p>
              <p className="p-in-sp">
                "In the language of the illustrious statesman, James Madison, whom the people of Virginia have been taught to venerate and revere as the wisest, safest and truest expounder of the Constitution which he so largely contributed to construct; that that instrument makes the Government to operate directly on the people, places at its command the needful physical means of executing its
                powers, and finally proclaims its supremacy, and that of the laws made in pursuance of it over the Constitution and laws of the States, subject to the revolutionary rights of the people in extreme cases; that a political system that does not provide for a peaceable and authoritative termination of existing controversies would not be more than the shadow of a Government ; the object
                and end of a real Government being the substitution of law and order for uncertainty, confusion and violence; that in the event of a failure of every constitutional resort, and an accumulation of usurpations and abuses rendering passive obedience and non-resistance a greater evil than resistance and revolution, there can remain but one resort, the last of all—an appeal from the
                cancelled obligations of the Constitutional Compact to the original rights and the law of self-preservation. This is the ultima ratio of all governments, whether consolidated, confederated, or a compound of both. It cannot be doubted that a single member of the Union, in the extremity supposed—BUT IN THAT ONLY-would have a right, as an extra and ultra constitutional right, to make
                the appeal."
              </p>
              <p className="p-in-sp">This resolution, sir, is not my own. It is the language of Mr. Madison, and will be recollected by the Committee as one of the series proposed a week or two ago by the delegate from Marshall [<span className="persName">Mr. BURLEY</span>] .</p>
              <p className="p-in-sp">
                The eighth resolution, which is now before the Committee, asserts, as the opinion of the Committee, that the States of this Union have a right to secede from it, and not concurring in or being willing to <PageNumber num={264} /> admit by any act of mine here such a political heresy, I prefer, sir, to avail myself of the labors of the gentleman from
                Marshall, who had looked up, in the writings of Mr. Madison, the correct doctrine, in my opinion, upon, this question. And it will be for this Committee to say whether they or Mr. Madison, who has been styled the father of the Constitution, best understand it.
              </p>
              <p className="p-in-sp">I will now read the resolution as it would read should the amendment be adopted. [<span className="persName">Mr. CARLILE</span> here read the resolution indicated.]
                The original resolution, in my opinion, lays down nothing but abstractions and political heresies, one of which is the assumption of this constitutional peaceable remedy of secession. It is idle to presume that James Madison, and those who were associated with him in forming the government, expressly provided in the Constitution for its dissolution, and that they spent years of
                thought and years of toil in building up a government which was to be dissolved the next morning at the pleasure of a single member of that Government.
              </p>
              <p className="p-in-sp">
                We have been enlightened, I at least have been, upon this new idea for the first time by this 8th section of the report. These gentlemen present this new sort of governmental idea that a sovereign State has the right to withdraw from the government and that the government has no right to question this act of the sovereign State, inasmuch as they say it is an offence committed, not
                against the government, but against co-States. Now, I confess that I have not comprehension enough to understand this idea. It is a novel one to me at least, for I have heard it for the first time upon this floor. I believe that the government has the right of self-preservation. I believe if a State withdraws from this government that it must be prepared by the right arm of
                revolution to maintain its separate existence. I believe, when this government becomes oppressive to the people of any of the States composing it, that they should resort to that revolutionary right which our fathers resorted to—that they will do, as they did not take shelter from their acts under the cover of a right that cannot exist; but place the reasons and the acts before the
                civilized world, and appeal to the God of battles, to sustain them.
              </p>
              <div className="speaker" id="sp2391"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                As one of the Committee of twenty-one, I feel myself bound to defend the Committee at all events upon this section, because I have had an humble part in forming it. Now, I undertake to say, if any proposition was ever made to any body divided in sentiment, that ought to be received on all sides in a spirit of fairness, without compromitting principle upon either side, this
                proposition is one.
              </p>
              <PageNumber num={265} />
              <p className="p-in-sp">
                It affirms nothing about the revolutionary or the sovereign right. You and I concur in avoiding that rock, the Scylla on the one hand and the Charybdis on the other. It is the fairest proposition that could have been presented to the minds of our people. I care not whether my neighbor acts upon the revolutionary right, or I act upon the States right I upon the sovereign right or he
                upon the individual right—if he and I march unitedly, shoulder to shoulder, to resist aggression.
              </p>
              <p className="p-in-sp">
                I rose more particularly to say, that now it is demonstrated openly, by the bold and candid member who has just addressed us, that we have the same dissension tearing the womb of this Convention that is now tearing the womb of the Cabinet at Washington. The image of Sin, the Mother of Death, sits at Hell's gate with her offspring, barking, eternally barking at every thing that is
                good upon earth ; when disturbed running back to the mother womb, and even there still barking; and now in that Capitol, in the womb of that Cabinet is the very conflict going on, the barking, which we have just heard in this body—the power of war claimed against the power of peace, the power claimed of war and war only to execute the laws through blood, flame and devastation—and
                that put upon the ground of self-preservation.
              </p>
              <p className="p-in-sp">
                Let me tell the gentleman, that if he relies upon that for self-preservation, he will be deceived. The moment that this government resorts to coercion to demonstrate that that Government has power, has existence and because it has it, it will wield it—whenever this government adopts that principle, it rushes upon its certain and fearful fate of destruction. Let him establish that
                doctrine, that there is no power of peace—let him establish the doctrine, whether under the supposition of revolutionary or of sovereign right, of constitutional or extra-constitutional right, that there is no peaceable mode of composing strife, that there is no power of agreement by which these States can part, like Abraham and Lot, in peace, if they cannot live together without
                dissension—let that be established; let it be said that the laws at all hazard shall be executed or, if the admission be made, that they must not be executed for the sake of peace, that then the government becomes defunct; let that be the received faith and practice of the hour, and I tell gentlemen, that however hesitating the people of Virginia and the border States may be about
                secession now, their decision will be made upon the instant whenever that decision is proclaimed.
              </p>
              <p className="p-in-sp">
                I am told that the great arch-enemy of the South—he who has <PageNumber num={266} /> invented and proclaimed the "irrepressible conflict" is now for peace. Seward is the leader of peace and is called now the "conservative" of the Cabinet. I dread his peace, I confess, worse than Greeley's war. But I am told that the Seward policy is in the descending
                node and that the Greeley and Chase policy is now dominant, and that is the reason that these forces are sent from New York to the South. Let it be understood now that the gentleman who offers this amendment takes the side of Greeley and Chase against Seward on this question. This is the conflict, the real conflict that at this moment is sending forth arms. The resolution which the
                gentleman proposes to amend I shall vote for intact as it is. I would not amend a word of it, lest any other part of it should be disturbed, and if that is not adopted here, if the competing proposition is adopted, you will then have sown dragon's teeth from which will spring forth armed men.
              </p>
              <div className="speaker" id="sp2392"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">I confess my surprise at the remarks of the gentleman from Princess Anne [<span className="persName">Mr. WISE</span>] . I believe I may call him my friend.</p>
              <div className="speaker" id="sp2393"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Yes, sir.</p>
              <div className="speaker" id="sp2394"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">In opposing the amendment which I have offered, the gentleman attempts to identify that amendment with myself, and by so doing, he identifies its author, Mr. Madison, with myself.</p>
              <div className="speaker" id="sp2395"><span className="persName">Mr. WISE</span> [in his seat]—</div>
              <p className="p-in-sp">Oh no; I have heard the Devil quote Scripture before.</p>
              <div className="stage it">[Laughter.]</div>
              <div className="speaker" id="sp2396"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">
                If, in giving my opinion, in the few remarks which I submitted to the Committee, that this Government, under which we have lived for near three quarters of a century, is, in fact, a Government, and, as such, of necessity, had the right to preserve and maintain its own existence—if the expression of an opinion, like that, will justify the remarks and attribute to me the disposition
                that was attributed by my friend from Princess Anne—then I stand corrected. Sir, if I said anything expressive of a wish that force should be used or coercion resorted to, I am unconscious of it, and I think the report, of the few remarks that I made, will bear me out, that I did not so treat the subject. On the contrary, I have, on a former occasion in this Convention, expressed it
                as my sincere wish that the difficulties now surrounding us shall be peaceably settled, and I believe they can and will be, if the efforts of the gentleman from Princess Anne and the Confederated States will but allow a settlement in a peaceable and amicable mode. I can see from no information laid before the public, any disposition on the part of the Administration at Washington to
                use force. I confess I am not as familiar with the differences in the Black Republican Cabinet, at Washington, as the gentleman from <PageNumber num={267} /> Princess Anne seems to be—that there is any disposition on the part of this Federal Government of ours to use force against any member of that Government, has not as yet been made known to the
                public. But, sir, when I am called upon, as a representative of a constituency, here to commit myself to what I have believed to be a political heresy, I certainly will endeavor, at least, to assert that which I believe to be true. I would willingly, if it could be done, vote against the whole of the first part of this report. The adoption of all these resolutions can lead to no
                practical good. They can only commit gentlemen to the assertion of principles about which we differ. That there are those here who believe in the constitutional right of a State to secede, there can be no doubt; that there are those who deny it, there can be as little doubt; and when we speak of a right acknowledged by the founders of the Constitution that exists outside of all
                constitutions, the right of revolution, we all understand what that means and what we are called upon to do. If the people of Virginia have suffered intolerable oppression from this Federal Government, which some are so desirous of overthrowing, they have the nerve to declare they will do the deed that they believe they are justifiable in doing, and that they will hazard the result
                that must follow in the event they fail in their efforts.
              </p>
              <p><span className="persName">Mr. EARLY</span> called for a division of the question.</p>
              <p>The yeas and nays were demanded and ordered.</p>
              <p>The question was then taken, and the motion to strike out was not agreed to—yeas 23, nays 114—as follows :</p>
              <p>YEAS-Messrs. Baldwin, Baylor, Berlin, Boggess, Burdett, Burley, Campbell, Carlile, Carter, Early, Ephraim B. Hall, Hubbard, Hughes, Jackson, Lewis, McGrew, Masters, Moore, Porter, Wm. C. Scott, Sharp, A. H. H. Stuart, Tarr-23.</p>
              <p>NAYS-Messrs. Janney [President]
                , Ambler, Armstrong, Aston, A. M. Barbour, James Barbour, Blakey, Blow, Boisseau, Borst, Bouldin, Boyd, Brent, Brown, Bruce, Byrne, Caperton, Cecil, Chambliss, Chapman, Coffman, Conn, C. B. Conrad, R. Y. Conrad, Couch, R. H. Cox, Critcher, Custis, Deskins, Dorman, Echols, Fisher, Flournoy, French, 'Fugate, Garland, Gillespie, Graham, Gravely, Gray, Gregory, Goggin, John Goode, Jr.,
                Hale, Addison Hall, Cyrus Hall, L. S. Hall, Hammond, Harvie, Haymond, Hoge, Holcombe, Holladay, Hull, Hunton, Isbell, Marmaduke Johnson, P. C. Johnston, Kent, Kilby, Kindred, Lawson, Leake, McComas, McNeil, Macfarland, C. K. Mallory, J. B. Mallory, Marshall, Marr, Marye, Maslin, Miller, Moffett, Montague, Morris, Morton, Neblett, Nelson, Orrick, Osburn, Parks, Patrick, Pendleton,
                Preston, Price, Pugh, Randolph, Richardson, R. E. Scott, Seawell, Sheffey, Sitlington, Slaughter, Southall, Speed, Spurlock, Staples, C. J. Stuart, Strange, Summers, Sutherlin, Tayloe, Thornton, R. H. Turner, F. P. Turner, Tyler, Waller, Wickham, Willey, Wilson, Wise, Woods, Wysor-114.
              </p>
              <div className="speaker" id="sp2397"><span className="persName">Mr. BAYLOR</span>—</div>
              <p className="p-in-sp">I propose to insert in the 8th resolution after the word <PageNumber num={268} /> "principle" the words "as established in the American Revolution," so that it would read :</p>
              <p className="p-in-sp">
                "The people of Virginia recognize the American principle as established in the American Revolution, that government is founded in the consent of the governed, and they concede the right of the people of the several States of this Union, for just causes, to withdraw from their association under the Federal Government with the people of the other States, and to erect new governments
                for their better security, and they will never consent that the Federal power, which is in part their power, shall be exerted for the purpose of subjugating the people of such States to the Federal authority."
              </p>
              <p className="p-in-sp">
                I propose to enter into no argument at all upon this amendment. I simply desire to say that it is offered in no captious spirit by me. I expressed my opinion in this Hall long before this report was brought in by the Committee of Twenty-one. I cannot vote for the resolution as it now stands. As I said before, I do not intend to go into an argument of the question, because I know very
                well that there is nothing I can say, after so much has been said upon both sides, that will change the vote of a single member in this body.
              </p>
              <div className="speaker" id="sp2398"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                Is this a proposition, Mr. Chairman, to restore a controverted point? If the principle is good in itself, it is just as good without saying how it was established, or when it was established. We are not voting for the mode in which the principle was established; we are voting for the principle itself. It certainly is something remarkable for a gentleman to tell us that he will not
                vote for a principle without telling us how the principle was established. Does it matter how the principle was established? If you state how the principle was established, that does not alter, does not change the principle itself. The real meaning of it is that the gentleman wants to use the word "revolution," and to raise the very point which the Committee of Twenty one avoided as
                a point of controversy—of ceaseless controversy. Some may say it is revolutionary; others may say it is sovereignty; let us agree to disagree upon those points, and come to the principle; and without giving it its name, let us take it for itself.
              </p>
              <div className="speaker" id="sp2399"><span className="persName">Mr. BAYLOR</span>—</div>
              <p className="p-in-sp">
                I endeavored to make myself understood when I explained my proposition. I never have believed that any State has a right peaceably to secede from the General Government, of its own will and pleasure. I always thought that after we entered into the Federal compact, we had a General Government, but if it be true that any State has a right to secede of its own will and pleasure, at any
                time, without entering into a compact to that effect with the <PageNumber num={269} /> Federal power, then we have no Government at all. When the several States of the Union ratified the Constitution of the United States, I do not believe it was the intention that they should have the right to secede the next day, if they chose, from the Federal
                Government; and I propose to insert those words, in order that we may draw a distinction between a revolutionary right and a right of peaceful secession. We all believe in the revolutionary right, and my object is simply to draw that distinction.
              </p>
              <div className="speaker" id="sp2400"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The question will be upon the amendment.</p>
              <div className="speaker" id="sp2401"><span className="persName">Mr. BROWN</span>, of Preston—</div>
              <p className="p-in-sp">I call for the ayes and noes.</p>
              <div className="speaker" id="sp2402"><span className="persName">Mr. BALDWIN</span>, of Augusta—</div>
              <p className="p-in-sp">I wish to ask a question of the gentleman from Princess Anne. It was stated by the gentleman from Princess Anne, I believe, that it was the purpose of the Committee of 21 not to assert either a revolutionary right or a Constitutional right, but to leave that question open.</p>
              <div className="speaker" id="sp2403"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">This discussion is not in order.</p>
              <div className="speaker" id="sp2404"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I would like, since the question was allowed to be asked, that I should be allowed to answer it.</p>
              <div className="speaker" id="sp2405"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">I suppose that since the question has been asked the Committee will bear with the answer, though it is not strictly in order.</p>
              <div className="speaker" id="sp2406"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                I thought I was distinct twice in stating what I understood to be the intention of that Committee. It was to avoid the Scylla and Charybdis, not between Constitutional right and revolutionary right, but sovereign right and revolutionary right. They did not intend to raise that issue, by the report; but without characterising the right, its source or its application, they merely meant
                to assert the principle of the right to be governed only by consent, either on the part of men or on the part of States.
              </p>
              <p>
                The vote was then taken on the amendment offered by <span className="persName">Mr. BAYLOR</span>, and the question was decided in the negative—ayes 29, noes 101, as follows :
                <span className="note" id="Note34"><span className="noteLabel">5</span>The Journal lists 106 negative votes, adding Woods to those listed in the Proceedings. In the Journal, Willey's name is only among the affirmative votes, and presumably it should be deleted from negative votes in the Proceedings.</span>
              </p>
              <p>YEAS-Messrs. Baldwin, Baylor, Berlin, Brown, Burdett, Burley, Campbell, Carlile, Carter, Couch, Dent, Early, Ephraim B. Hall, Hubbard, Hughes, Jackson, Lewis, McGrew, Masters, Moore, Osburn, Patrick, Porter, Sharp, A. H. H. Stuart, C. J. Stuart, Summers, Tarr, Willey-29.</p>
              <p>NOES-Messrs. John Janney [President]
                , Ambler, Armstrong, Aston, Alfred M. Barbour, James Barbour, Blakey, Blow, Jr., Boggess, Boisseau, Borst, Bouldin, Boyd, Branch, Brent, Byrne, Caperton, Cecil, Chambliss, Chapman, Coffman, Conn, C. B. Conrad, R. Y. Conrad, Richard H. Cox, Critcher, Custis, Deskins, Dorman, Echols, Fisher, Flournoy, French, Fugate, Garland, Gillespie, Graham, Gravely, Gray, Gregory, Jr., Goggin, John
                Goode, Jr., Hale, Addison <PageNumber num={270} /> Hall, Cyrus Hall, L. S. Hall, Hammond, Harvie, Haymond, Hoge, Holcombe, Holladay, Hunton, Isbell, Marmaduke Johnson, Peter C. Johnston, Kent, Kilby, Kindred, Lawson, Leake, McComas, McNeil, Macfarland, Chas. K. Mallory, Jas. B. Mallory, Marshall, Marr, Marye, Sr., Maslin, Miller, Moffett, Montague,
                Morris, Morton, Neblett, Nelson, Orrick, Parks, Pendleton, Preston, Price, Pugh, Randolph, Richardson, Robert E. Scott, Seawell, Sheffey, Sitlington, Slaughter, Speed, Spurlock, Staples, Strange, Sutherlin, Tayloe, Thornton, Robert H. Turner, Franklin P. Turner, Tyler, Waller, Wickham, Willey, Wilson, Wise, Wysor-105.
              </p>
              <div className="speaker" id="sp2407"><span className="persName">Mr. EARLY</span>, of Franklin—</div>
              <p className="p-in-sp">I move to amend the 8th resolution by striking out all after the word "the" before the words "several States" in the third line, and inserting in lieu thereof the following : "United States to resume the powers granted under the Federal Constitution whensoever the same shall be perverted to their injury or oppression." So that the section, as amended, will read :</p>
              <p className="p-in-sp">"8. The people of Virginia recognize the American principle that Government is founded in the consent of the governed, and they concede the right of the people of the United States, to resume the powers granted under the Federal Constitution whensoever the same shall be perverted to their injury or oppression."</p>
              <p className="p-in-sp">That portion of the resolution which was stricken out being :</p>
              <p className="p-in-sp">
                "Several States of this Union, for just causes, to withdraw from their association under the Federal Government with the people of the other States, and to erect new Governments for their better security, and they will never consent that the Federal power, which is in part their power, shall be exerted for the purpose of subjugating the people of such States to the Federal
                authority."
              </p>
              <div className="speaker" id="sp2408">
                <span className="persName">Mr. EARLY</span>
                <div className="note" id="Note35">
                  <span className="noteLabel">6</span>A correction (April 11) gives this somewhat different version of Mr. Early's comments on his amendment:
                  <div className="speaker"><span className="persName">Mr. EARLY</span> said—</div>
                  <p className="p-in-sp">
                    The amendment which I have proposed is in the language of the ordinance or form of ratification adopted by the Virginia Convention which ratified the Constitution of the United States. That ordinance or form of ratification was prepared by a Committee of which Governor Edmund Randolph was Chairman and Wilson Cary Nicholas, James Madison, John Marshall and Francis Corbin were
                    members, and is in the following words:
                  </p>
                  <p className="p-in-sp">* * *</p>
                  <p className="p-in-sp">
                    Now, sir, I shall not go into the general argument upon the right of secession—that was fully exhausted thirty years ago—but there is an argument made in Virginia, based upon the phraseology of the form of ratification adopted by her Convention, which is the most embarrassing one the opponents of the right of secession have to encounter. On day before yesterday, the word
                    "several" was inserted, by a vote of this body, in the place of the word "united," in the third line of the fourth resolution; upon the express idea that the words, "the people of the United States," were words of consolidation, and sustained the doctrine that the Federal Government is a consolidated government. Now, sir, the language of the form of ratification adopted by the
                    Virginia Convention is the language of her wisest and best statesman, and if the gentlemen of the States' rights school of politics, on this floor, think that this language contains the doctrine of the right of secession, and are willing to risk that doctrine upon that language, I call upon them to vote for my amendment; but if they do not think that it contains that doctrine,
                    but contains the doctrine of consolidation, then I want them to cast their votes against the amendment, and forever put an end to the argument in favor of the right of secession, based upon the form of ratification adopted by the Virginia Convention.
                  </p>
                </div>
                —
              </div>
              <p className="p-in-sp">
                The amendment which I have proposed is in the language of the form of ratification adopted by the Virginia Convention which ratified the Constitution of the United States, that was prepared by a committee of which Governor Randolph was chairman, and of which such men as Jefferson, Madison and Williamson were members. This is the language of the ordinance of ratification :
              </p>
              <p className="p-in-sp">
                "We, the delegates of the people of Virginia, duly elected in pursuance of a recommendation from the General Assembly, and now met in Convention, having fully and freely investigated and discussed the proceedings of the Federal Convention, and being prepared, as well as the most mature deliberation hath enabled us, to decide thereon, do, in the name and in behalf of the people of
                Virginia, declare and make known, that the powers granted under the Constitution, being derived from the people of the United States, may be resumed by them <PageNumber num={271} /> whensoever the same shall be perverted to their injury or oppression," &amp;c
              </p>
              <p className="p-in-sp">
                Now, sir, I shall not say anything in regard to the argument upon the subject of the right of secession. That, sir, was fully exhausted thirty years ago, and the most impressive argument I have to present in return, is that based upon the form of ratification adopted by the Virginia Convention. On day before yesterday, in the third line of the fourth resolution, the word "several"
                was inserted, by a vote of this body, in place of the word "united," upon the express idea that the words "the people of the several States," were words of consolidation, and that they established the doctrine that this was a consolidated government. Now, sir, here is a similar declaration of the Convention of Virginia, in a form which was prepared by the wisest and best statesmen;
                and if gentlemen of the State Rights school of politics are willing to risk the doctrine of secession upon this form of ratification, I think I have the right to ask them to come up for their own doctrine, for in their opinion these are words of consolidation. I call for the ayes and noes upon the question.
              </p>
              <div className="speaker" id="sp2409"><span className="persName">Mr. JOHNSTON</span>, of Lee and Scott—</div>
              <p className="p-in-sp">I desire to know, sir, whether the motion is to strike out all of the eighth section from the words "several States," down to the end, or whether any part of the remainder is to be preserved?</p>
              <div className="speaker" id="sp2410"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The motion is to strike out the whole, from the words "several States."</p>
              <div className="speaker" id="sp2411"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">I ask for a division of the question.</p>
              <div className="speaker" id="sp2412"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">In reply to the gentleman from Lee and Scott [<span className="persName">Mr. JOHNSTON</span>] , I would say that it was my purpose to strike out the whole. To put it in proper form would require an entire change of phraseology, and the same doctrine is contained in two or three other resolutions of the majority report of the Committee on Federal Relations.</p>
              <div className="speaker" id="sp2413"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">I ask for a division of the question.</p>
              <div className="speaker" id="sp2414"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The call has already been made by the gentleman from Middlesex, and the question will first be put upon striking out.</p>
              <div className="speaker" id="sp2415"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">The House expressly refuses to strike out.</p>
              <div className="speaker" id="sp2416"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">There is a rule of this House which provides that although a motion to strike out and insert is overruled, it does not overrule a motion to strike out something else.</p>
              <div className="speaker" id="sp2417"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">I submit that it is not competent to have a division of the question.</p>
              <div className="speaker" id="sp2418"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">That point of order is overruled.</p>
              <PageNumber num={272} />
              <p>The question was then put, and the Committee refused to strike out —ayes 35, noes 98, as follows :</p>
              <p>YEAS-Messrs. Aston, Baldwin, Baylor, Berlin, Boggess, Brown, Burdett, Burley, Byrne, Campbell, Carlile, Carter, Couch, Custis, Dent, Early, E. B. Hall, Hubbard, Hughes, Jackson, Lewis, McGrew, Masters, Moore, Osburn, Patrick, Pendleton, Porter, Wm. C. Scott, Sharp, A. H. H. Stuart, C. J. Stuart, Summers, Tarr, Willey-35.</p>
              <p>NAYS-Messrs. Janney [President]
                , Ambler, Armstrong, Alfred M. Barbour, Blakey, Blow, Boisseau, Borst, Bouldin, Boyd, Branch, Brent, Bruce, Cecil, Chambliss, Coffman, Conn, C. B. Conrad, R. Y. Conrad, R. H. Cox, Critcher, Deskins, Dorman, Echols, Fisher, Flournoy, French, Fugate, Garland, Gillespie, Graham, Gravely, Gray, Gregory, Goggin, J. Goode, Jr., Hale, A. Hall, C. Hall, L. S. Hall, Hammond, Harvie, Haymond,
                Hoge, Holcombe, Holladay, Hunton, Isbell, M. Johnson, P. C. Johnston, Kent, Kilby, Kindred, Lawson. Leake, McComas, McNeil, Macfarland, C. K. Mallory, Marshall, Marr, Marye, Maslin, Miller, Moffett, Montague, Morris, Morton, Neblett, Nelson, Orrick, Parks, Preston, Price, Pugh, Randolph, Richardson, R. E. Scott, Seawell, Sheffey, Sitlington, Slaughter, Speed, Spurlock, Staples,
                Strange, Sutherlin, Tayloe, Thornton, R. H. Turner, F. P. Turner, Tyler, Waller, Wickham, Wilson, Wise, Woods, Wysor-98.
              </p>
              <p>The hour of two o'clock having arrived, the Committee took a recess until 4 o'clock, P. M.</p>
            </div>
            <div className="section" id="vsc1965.v3.2.5.5">
              <h3><span className="headingNumber">1.5.5. </span><span className="head">EVENING SESSION</span></h3>
              <p>The Committee assembled at 4 o'clock.</p>
              <p><span className="persName">The CHAIRMAN</span> stated the question to be upon the adoption of the 8th resolution of the report of the Committee on Federal Relations.</p>
              <div className="speaker" id="sp2419"><span className="persName">Mr. JACKSON</span>—</div>
              <p className="p-in-sp">
                As that is a very important resolution, and one exceedingly distasteful to me, I hope we shall have a fuller House than I now see present. As I perceive I am in a very small minority in this Committee, I should like to have as much company as possible. I propose to amend the resolution in the third line after the word "the" by inserting the word "revolutionary" so that the clause
                would read,
              </p>
              <p className="p-in-sp">"They concede the revolutionary right of the people of the several States of this Union, for just cause to withdraw," &amp;c.</p>
              <div className="speaker" id="sp2420"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">Has not that proposition been already substantially voted on in this Convention? The revolutionary character of the right was involved in the amendment of the gentleman from Harrison [<span className="persName">Mr. CARLILE</span>] . The amendment of the gentleman from Augusta [<span className="persName">Mr. BAYLOR</span>] , too involved the same idea. I submit, for that reason, that the amendment of the gentleman from Wood is not in order.</p>
              <div className="speaker" id="sp2421"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">It will be for the Committee to say whether they <PageNumber num={273} /> will adopt the amendment or not. The Chair decides that it is in order.</p>
              <div className="speaker" id="sp2422"><span className="persName">Mr. JACKSON</span>—</div>
              <p className="p-in-sp">
                I do not desire to detain the Committee. I belong to the silent voting portion of this body; and it would have been much more agreeable to me if some other gentleman had proposed the amendment, and I could have simply given my vote upon it. But, sir, I cannot vote for this resolution as it now stands—not even with the amendment I have proposed, if it should be adopted. I should be
                compelled to offer other amendments. I offer this for the purpose of redeeming the resolution from the objection which many gentlemen believe it to have, in respect to the character of the right which is asserted. I propose to make it so clear that there shall be no doubt about it.
              </p>
              <p className="p-in-sp">
                It is due, however, to candor to say that I have not the slightest hope of my amendment being adopted—none whatever—and I shall not, therefore, undertake to debate it as I might under other circumstances. I simply propose to make my own record in reference to it. I shall not even call the yeas and nays upon it; for I know that to spend any time in reference to it would be a work of
                supererogation. I content myself with offering the proposition and asking for a vote upon it.
              </p>
              <div className="speaker" id="sp2423"><span className="persName">Mr. SHEFFEY</span>—</div>
              <p className="p-in-sp">I call for the yeas and nays upon the adoption of the amendment.</p>
              <p>The yeas and nays were not ordered. The amendment was disagreed to—ayes 29, noes 58.</p>
              <p>The question then recurred upon the adoption of the resolution.</p>
              <div className="speaker" id="sp2424"><span className="persName">Mr. CAMPBELL</span>—</div>
              <p className="p-in-sp">I have an amendment to propose to that resolution. I propose to strike out all after the word "they," in the third line, down to the word "and," in the seventh line, as follows :</p>
              <p className="p-in-sp">"Concede the right of the people of the several States of this Union, for just causes, to withdraw from their association under the Federal Government with the people of the other States, and to erect new governments for their better security."</p>
              <p className="p-in-sp">And to insert this in the place of it :</p>
              <p className="p-in-sp">"Assert that the people of the several States, whensoever the powers granted by them under the Federal Constitution shall be perverted to their injury and oppression, have the extra and ultra constitutional right to resume the same, and that every power not granted by them remains with them and at their will."</p>
              <p className="p-in-sp">So that the clause will read : "The people of Virginia recognize the American principle, that government is founded in the consent of the governed, and they assert that the people of the several States," &amp;c.</p>
              <p className="p-in-sp">
                Mr. Chairman, there have been four amendments proposed to this <PageNumber num={274} /> resolution, two of which have been propositions to strike out and insert. Upon these amendments a division of the question was called, and I voted, in each case, to strike out, not because I was in favor of inserting the propositions presented, but because I am
                opposed to the resolution as it now stands.
              </p>
              <p className="p-in-sp">
                Now, sir, I propose to strike out what I have indicated, and to insert what I have presented, upon this ground. I agree that government is founded on the consent of the governed. I agree that this is not a consolidated government, but, on the contrary, that it is a government founded by the people of the several States, acting in their individual sovereign capacity, and that they are
                the parties to the compact; that they being the parties to the compact they alone can judge of the grievances which may be sustained by them under that compact; that in the exercise of that right, if they determine that the government has been perverted to their injury and oppression, they have the right to amend, alter or abolish it and that they are responsible to their co-States
                for any injury which they inflict upon those States by such amendment, alteration or abolishment. What I mean to say is this : that while Louisiana had the right, if in her opinion, the government had perverted its powers for her injury and oppression, to go out of the Union, and while the Federal Government has no right to collect duties upon her imports, yet she has no right to
                control the mouth of the Mississippi river; and the fact that she has so gone out gives her no right of such control ; and if by her going out she has inflicted injury upon the States bordering upon that river she is responsible to them for that injury.
              </p>
              <p className="p-in-sp">
                Now, sir, this resolution is obviously uncertain. It has been avowed upon this floor that it is intentionally uncertain ; that it was purposely so frmed, that this word "right" might be made to accord to the peculiar views of the individual who uses it, whether a sovereign right, a constitutional right, or a revolutionary right. I prefer that it shall be construed by us. I believe it
                to be an extra-constitutional right; and when this Convention is declaring a great principle, I want the declaration made in such terms that he who reads it may understand it. I am, therefore, opposed to the resolution as it stands, and it is for the purpose of rendering it more certain in its construction that I have proposed this amendment.
              </p>
              <p><span className="persName">Mr. CHAMBLISS</span> called for the yeas and nays upon the adoption of the amendment.</p>
              <p>The yeas and nays were not ordered.</p>
              <p>The amendment was disagreed to.</p>
              <PageNumber num={275} />
              <div className="speaker" id="sp2425"><span className="persName">Mr. SUMMERS</span>—</div>
              <p className="p-in-sp">I propose to add nothing to this section, but I do propose what would render it more appropriate in language and more acceptable to some. I propose to strike out in the 3rd line, the words "they concede."</p>
              <p className="p-in-sp">
                One word only as to the object of the amendment. It will be perceived by the gentlemen of the Committee, that if these two words be stricken out, it will leave us the word "recognize" as the governing word of the sentence. Now this affirmation of right must apply to the rights of our own people, who are making this concession, as well as to the rights of the people of the several
                States. It now reads, "the people of Virginia recognize the American principle that government is founded in the consent of the governed, and they concede the right of the people," &amp;c. Now we are employing a term here which, it strikes me, is not appropriate ; when we say we "concede" the right of the people of the Several States, we are then speaking of our own rights as well as
                the rights of the people of the several States. Now I think it is sufficient to say that we "recognize the American principle that government is founded in the consent of the governed, and the right of the people of the several States of this Union for just causes to withdraw," &amp;c. That includes the people of Virginia as well as the people of the other States. We are not called
                upon to concede anything to the people of those States, and I see no reason why this word "concede" should not be omitted, leaving the word "recognize" to cover the right of the people of all the States. The use of one verb is sufficient, and I think it is proper that we should strike out these two words. The whole sentence is left perfectly grammatical, and I think the language is
                more appropriate. We desire here to recognize the right of all the people of all the States under certain circumstances to throw off the government. I do not know how the word "concede" is employed there. The word "concession" and the word "concede" imply some superiority in the party exercising or employing the term. It is necessarily improper, it seems to me, and I hope it will be
                omitted.
              </p>
              <div className="speaker" id="sp2426"><span className="persName">Mr. CONRAD</span>, of Frederick—</div>
              <p className="p-in-sp">I merely desire to say that I consider the amendment proposed a very decided improvement to the section ; I shall vote for it very cheerfully, and, as far as I am concerned, I hope it will be adopted.</p>
              <div className="speaker" id="sp2427"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I rise, sir, to offer a suggestion. Now, as to this word "concede," it may imply that I am a grantor of a right, as I understood him [the gentleman from Kanawha]
                to indicate, and in that sense it would be too strong a term, but in the sense in which it is <PageNumber num={276} /> obviously used in this connexion, as a qualification of the phrase, it is merely admitting the right, merely an acknowledgment; acceding to a confession of the right. That is the only meaning it has there, and in that sense, it is not
                too strong. But, if proper to be stricken out, because implying more than it ought to import, then the question arises whether the word "recognize" is sufficient to imply enough. What is to recognize? It is merely a term that takes cognizance of an act. Does it necessarily imply the acknowledgment of a right?
              </p>
              <p className="p-in-sp">
                I know that in our vernacular, we sometimes mean, by the word "recognize," to imply that we acknowledge the thing, but literally it means no such thing. It is a mere vernacular implication, and not an implication derived from the root of the word. The word "recognize" means only to know again. You may recognize a fact without acknowledging it at all. You may recognize a right because
                it is set up, and you have known it to be set up, but that does not express necessarily that you acknowledge the right. I recognize, for example, the doctrine of consolidation as a doctrine which is held by very high and respectable authorities; but I do not acknowledge it. I more than recognize the doctrine of State sovereignty. I not only recognize it, but I acknowledge it, and
                concede it, and we act on it.
              </p>
              <p className="p-in-sp">
                While, then, we are getting clear of one word which might, perhaps, imply, in some sense, rather too strong a meaning, we ought to be cautious, it seems to me, not to fall back on the other governing word "recognize" which might be short of what is meant. I submit to my friend from Kanawha, that if for the word "concede" we substitute the word "acknowledge" that would meet every
                difficulty. But if he means merely to recognize the right, without acknowledging it, I cannot go along with him. I wish to go with him, because I think his criticism on the word "concede" is right.
              </p>
              <div className="speaker" id="sp2428"><span className="persName">Mr. SUMMERS</span>—</div>
              <p className="p-in-sp">
                It occurred to me, Mr. Chairman, that it would not be necessary to insert any word in place of the words proposed to be struck out. The gentleman from Princess Anne appears to think that the word "recognize" is not quite strong enough and he suggests the insertion of the word "acknowledge" instead of the word "concede." It is clear to me, however, that when we say "we recognize the
                American principle," &amp;c., and the right of secession, that is equivalent to the word "acknowledge." Do you mean simply to use the word "recognize" as you would use it to a friend from whom you had been long parted—that you recognize his features on meeting him again? In its application here you mean to say that you acknowledge the American system. What do we mean when we say we
                recognize <PageNumber num={277} /> the independence of Greece, of the South American Republics, of Sardinia? We mean that we acknowledge their independence. It is equivalent to the use of the word "acknowledge" and would be so understood by all here. I think we need not employ any stronger or different word in either sentence. I regard it as tautology;
                and in adopting amendments to the Constitution we should do so in as few words as possible. I think there can be no objection to the striking out of these words ; it will make the sentence more neat and grammatical and would be better in every way.
              </p>
              <p>The question was taken on Mr. Summers' amendment and it was agreed to.</p>
              <div className="speaker" id="sp2429"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">I move to insert in the blank just created, the words "they acknowledge," and on that I call for the yeas and nays.</p>
              <div className="speaker" id="sp2430"><span className="persName">Mr. SUMMERS</span>—</div>
              <p className="p-in-sp">I would remind the gentleman from Middlesex and the Committee, that the insertion of the words proposed would be obnoxious to the same objection that I made to the other words. As the sentence now stands, we recognize the rights of all the States, including this State.</p>
              <div className="speaker" id="sp2431"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">
                That is exactly the object which I have in view. I know enough about men and things to know that that gentleman and his friends, if this resolution is to stand as you have fixed it by this vote, will quote this as a decision of this Convention that Virginia did not concede the great right of State secession. And it is to test the sense of the Convention on this question that I move
                to insert the words "they acknowledge." I do it, not with the view of deceiving anybody, but of seeing, whether this Convention are, by their votes to-day, ready to stultify themselves by taking back everything they have said.
              </p>
              <div className="speaker" id="sp2432"><span className="persName">Mr. HOLCOMBE</span>—</div>
              <p className="p-in-sp">I prefer the word "recognize" to the word "acknowledge," and shall vote against the amendment, but I do not concede at all that I am yielding or surrendering the right of secession.</p>
              <p>The yeas and nays were ordered.</p>
              <p>The question was taken and it was decided in the negative—yeas 28, nays 90—as follows :</p>
              <p>AYES-Messrs. Ambler, Alfred M. Barbour, James Barbour, Blakey, Blow, Jr., Cabell, Cecil, Chambliss, Coffman, Conn, Fisher, Hale, Hunton, Isbell, Kent, Kindred, Leake, Moffett, Montague, Morris, Richardson, Seawell, Sheffey, Speed, Robt. H. Turner, Wise, Woods, Wysor-28.</p>
              <p>NOES-Messrs. John Janney [President]
                , Armstrong, Aston, Baldwin, Baylor, Berlin, Boggess, Bouldin, Boyd, Brent, Brown, Bruce, Burdett, Burley, Byrne, Campbell, Carlile, Carter, Chapman, C. B. Conrad, Robert Y. Conrad, Couch, Custis, Dent, Deskins, Dorman, Early, Echols, Flournoy, Fugate, Garland, Gillespie, <PageNumber num={278} /> Gravely, Goggin, John Goode, Jr., Addison Hall, Ephraim
                B. Hall, L. S. Hall, Hammond, Harvie, Hoge, Holcombe, Holladay, Hubbard, Hughes, Jackson, Marmaduke Johnson, Peter C. Johnston, Kilby, Lewis, McComas, McGrew, McNeil, Macfarland, Charles K. Mallory, James B. Mallory, Marshall, Marr, Marye, Sr., Maslin, Masters, Mills, Morton, McD. Moore, Neblett, Osburn, Parks, Patrick, Porter, Preston, Price, Pugh, Randolph, Robert E. Scott, Wm. C.
                Scott, Sharp, Slaughter, Spurlock, A. H. H. Stuart, Chapman J. Stuart, Strange, Summers, Sutherlin, Tarr, Tayloe, Waller, White, Wickham, Willey, Wilson-90.
              </p>
              <p>So the amendment was rejected.</p>
              <p>During the roll call</p>
              <p><span className="persName">Mr. CRITCHER</span> stated that Mr. Richard H. Cox having been called home by indisposition in his family, had requested him to pair off with him.</p>
              <p><span className="persName">Mr. SITLINGTON</span> stated that he had paired off with <span className="persName">Mr. BRANCH</span>. <span className="persName">Mr. STAPLES</span> stated that he had paired off with <span className="persName">Mr. BOISSEAU</span>.</p>
              <p><span className="persName">Mr. FRANKLIN P. TURNER</span> stated that he had paired off with Mr. GRAY, otherwise he should have voted "aye."</p>
              <p>The result was announced as above recorded.</p>
              <div className="speaker" id="sp2433"><span className="persName">Mr. JOHN GOODE, Jr.</span>—</div>
              <p className="p-in-sp">
                In order to meet the criticism of my friend from Kanawha on the words "concede" and "acknowledge," I propose, in the blank left by striking out the word "concede," to insert the word "assert." The gentleman argued that it was inappropriate to employ the word "concede," as to the rights of our own people. I admit the force of the argument, and therefore propose the word "assert."
                There certainly can be no objection to that. I think that in times like these it is highly appropriate that this Convention should assert this right on the part of the people of Virginia. It is the prevailing idea in the Northern mind, that the people of Virginia have no such right as we do assert here. As I understand that these resolutions are to be sent to the Northern people, to
                be responded to by them, it is certainly proper and appropriate that they should understand this assertion of rights which I believe to be the ground-work of our federative system.
              </p>
              <div className="speaker" id="sp2434"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">
                I do not understand on what principle it is that the gentleman gives such a preference to the second right mentioned in the resolution, over the first one. I understand that exception is taken to the term "recognize" as not being strong enough to apply to the right of secession; and it is proposed to use the word "assert" in place of the words stricken out. Now, I should like to
                understand on what principle it is, if the word "recognize" is strong enough for this great American principle, that it is not strong enough for any principle whatever?
              </p>
              <PageNumber num={279} />
              <p className="p-in-sp">
                If, however, the gentleman objects that the term "recognize" is not strong enough for the second principle I suggest whether it is not better to go back and substitute the word "assert" for the word "recognize" in the first line. Thus both rights would be placed on the same level as regards the strength of the asseveration. I cannot see any ground for the preference. The term
                "assert" means, under some circumstances, to "exercise" a right—not merely to declare it, but to exercise it, and, therefore, the use of that word would lead to some ambiguity.
              </p>
              <p className="p-in-sp">
                Now it seems to me that there is no necessity whatever for inserting anything in place of the words struck out. In regard to the 8th resolution, I desire to say that I understood this morning from the gentleman from Princess Anne, that it was the object and purpose of the Committee of Twenty-one in drafting that resolution to evade the controverted question in regard to the right of
                secession, and merely to assert that right, leaving it to every one to characterize the right as he holds it.
              </p>
              <p className="p-in-sp">I should like to know from the gentleman from Princess Anne [<span className="persName">Mr. WISE</span>]
                , if that is his recollection. It is mine, and if I am sustained in that by his recollection, I do not see that there is any necessity for protracting this sort of controversy. If it is the understanding of this Committee, that this 8th resolution is not intended to touch the question of the right of secession or revolution, but simply to assert the right, for just cause, to throw
                off that Government—I say if that was his interpretation as to the character of the right, it seems to me that we can all go for it.
              </p>
              <div className="speaker" id="sp2435"><span className="persName">Mr. GOODE</span>, of Bedford—</div>
              <p className="p-in-sp">I will make one or two remarks in reply to the gentleman.</p>
              <p className="p-in-sp">
                The gentleman desires to know why I give a preference to the word "assert" in the third line over the word "recognize" in the first line, and he seemed to think that the word "assert" might be as well employed in the first as in the third line. If he looks more particularly, he will see that the word "recognize" in the first line is qualified by the word "founded" in the second line,
                and that therefore his criticism does not apply.
              </p>
              <p className="p-in-sp">Again—he speaks of the admission made by the gentleman from Princess Anne [<span className="persName">Mr. WISE</span>]
                , that it was not contemplated by the Committee that the great doctrine of secession was to be settled by this Convention. I don't know what was contemplated by the Committee, but I desire this Committee to bear in mind that they are enunciating principles which are to be submitted to the non-slaveholding States as <PageNumber num={280} /> a complete
                enunciation of principles on the part of the people of Virginia ; and it is in that connection that I wish to assert, what I understand to be denied by the people of the Northern States, and even by the President of the United States, in his inaugural address—that the people have a right to withdraw from the Federal Government for any cause; and it is this right that I wish this
                Convention to assert—a right which is essential to our existence as a free people.
              </p>
              <div className="speaker" id="sp2436"><span className="persName">Mr. TARR</span>—</div>
              <p className="p-in-sp">I desire to offer an amendment to this resolution.</p>
              <p className="p-in-sp">I move to strike out all after the word "governed," in the second line, and insert in lieu thereof the following :</p>
              <p className="p-in-sp">"Denying the constitutional right of the several States to secede from the Union, yet they may, in the exercise of their revolutionary right, disrupt the ties that bind them to the Federal Government, and form such associations with other States as may best promote their security and welfare."</p>
              <p>The question was taken on this amendment, and decided in the negative.</p>
              <p>The question then recurred upon the adoption of the resolution, and the question being put, was decided in the affirmative.</p>
              <div className="speaker" id="sp2437"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">It is now 5 o'clock, and I am informed that unless this body leave this hall, so as to enable the furniture to be removed before bed time to-night, the State will have to pay another week's rent. I am aware, moreover, that the gentleman from Montgomery [<span className="persName">Mr. PRESTON</span>] has a resolution to offer in committee of the whole which I know is important and will require some consideration.</p>
              <div className="speaker" id="sp2438"><span className="persName">Mr. JACKSON</span>—</div>
              <p className="p-in-sp">
                If that motion is insisted upon I will call for the yeas and nays. I wish to state that this body costs the State of Virginia about $1,000 a day, and if we lose one week's rent of this hall it is but $150—not equal to one hour's labor, and we can probably accomplish the work of two or three hours' labor to-night, and thus save two or three hundred dollars. I will state, moreover,
                that the proposition which is prepared by the gentleman from Montgomery
                [<span className="persName">Mr. PRESTON</span>] can be offered as well in committee as in convention.</p>
              <div className="speaker" id="sp2439"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">If there is any objection to the motion I will withdraw it.</p>
              <p>Cries of "No, no, no."</p>
              <div className="speaker" id="sp2440"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">I will not withdraw it, then.</p>
              <div className="speaker" id="sp2441"><span className="persName">Mr. JACKSON</span>—</div>
              <p className="p-in-sp">Then I ask for the yeas and nays.</p>
              <p>Cries of "withdraw it," "withdraw it."</p>
              <div className="speaker" id="sp2442"><span className="persName">Mr. JACKSON</span>—</div>
              <p className="p-in-sp">I withdraw the call, sir. I understand it will be voted down if I do not.</p>
              <PageNumber num={281} />
              <p>The motion that the Committee rise was then put, and agreed to ; whereupon, the Committee rose and reported progress.</p>
              <div className="speaker" id="sp2443"><span className="persName">Mr. PRESTON</span>—</div>
              <p className="p-in-sp">I beg leave to offer the following resolutions, which I ask the Clerk to read.</p>
              <p>The Clerk read the resolutions, as follows :</p>
              <p>
                1. Resolved, That in the opinion of this Convention, the Federal Government of this Union is one of limited and expressly granted powers; that the Constitution confers no power upon its constituted authorities to subjugate a State or execute the Federal laws within the limits of a State which has withdrawn from the Government, expelled the civil authorities of the same, and is in the
                exercise of an independent sovereignty.
              </p>
              <p>2. That, as one of the parties to the compact of government, this Commonwealth has the right to protest against any such exercise of coercive policy on the part of the Federal authorities, and they will never consent that the Federal power, which is, in part, their power, shall be exercised for the purpose of subjugating the people of such States to the Federal authority.</p>
              <p>3. That a committee of three delegates be appointed by this Convention to wait upon the President of the United States, present to him these resolutions and respectfully ask of him to communicate to this Convention the policy which the authorities of the Federal Government intended to pursue in regard to the Confederate States.</p>
              <div className="speaker" id="sp2444"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">I rise to a question of order, and it is this : that under the rule adopted when the Committee of Twenty-one on Federal Relations was appointed, all resolutions relating to Federal subjects were to be referred to that committee; and it is not competent for this Convention to pass upon these resolutions, until they pass through that Committee.</p>
              <div className="speaker" id="sp2445"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">Will the Secretary report the resolution under which the Committee of Twenty-one was appointed?</p>
              <div className="stage it">[The resolution was reported. It expressly directed the reference of all resolutions of a Federal character to the Committee of Twenty-one.]</div>
              <div className="speaker" id="sp2446"><span className="persName">Mr. PRESTON</span>—</div>
              <p className="p-in-sp">I believe the rule has been to permit the gentleman who offered a resolution to make a few remarks.</p>
              <div className="speaker" id="sp2447"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair is of opinion that the resolutions will have to go to the Committee of Twenty-one; but the gentleman is at liberty to make some remarks upon them.</p>
              <div className="speaker" id="sp2448"><span className="persName">Mr. FLOURNOY</span>—</div>
              <p className="p-in-sp">With the consent of my friend from Montgomery <PageNumber num={282} /> [<span className="persName">Mr. PRESTON</span>] , in order that these resolutions may be considered now, I move a suspension of the rule.</p>
              <div className="speaker" id="sp2449"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">I ask if it does not require a two-thirds vote to suspend the rule.</p>
              <div className="speaker" id="sp2450"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">I am not aware of any such rule. We are not governed here by the rules of the House of Delegates or of Congress either.</p>
              <div className="speaker" id="sp2451"><span className="persName">Mr. FLOURNOY</span>—</div>
              <p className="p-in-sp">These resolutions are very important, and upon the motion to suspend the rule, which I have just made, I call the yeas and nays.</p>
              <div className="speaker" id="sp2452"><span className="persName">SEVERAL MEMBERS</span>—</div>
              <p className="p-in-sp">There is no occasion for that call.</p>
              <div className="speaker" id="sp2453"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">I desire to submit a question of order.</p>
              <p className="p-in-sp">When the Convention was last in session, it had under consideration, and what I will characterize, as the unfinished business, the resolutions submitted by the gentleman from Monongalia [<span className="persName">Mr. WILLEY</span>] , with amendments submitted by other members. The point of order I wish to make is this, that until some disposition has been made of the resolutions of the gentleman from Monongalia, with the amendments thereto, it is not in order to proceed to any other business.</p>
              <div className="speaker" id="sp2454"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The gentleman from Middlesex [<span className="persName">Mr. MONTAGUE</span>] , moved that the Committee should rise, and indicated the purpose for which he made the motion. A vote was taken upon that motion and the Committee did rise. The gentleman from Montgomery [<span className="persName">Mr. PRESTON</span>] , then rose, without objection on the part of any member, and presented his resolutions. The Chair took it for granted that it was done by general consent.</p>
              <div className="speaker" id="sp2455"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">Mr. President.</p>
              <div className="speaker" id="sp2456"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The question of order is decided so far. Does the gentleman rise to another point of order?</p>
              <div className="speaker" id="sp2457"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">I am requested to take an appeal from the decision of the Chair, which I do not wish to do myself.</p>
              <div className="speaker" id="sp2458"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">I think the House has pretty clearly indicated its purpose to take up and consider this subject. It has been done almost by universal consent, and it is now too late to make any objections.</p>
              <div className="speaker" id="sp2459"><span className="persName">Mr. PRESTON</span>—</div>
              <p className="p-in-sp">Mr. President, I have but a remark or two to make. I have thought proper upon consultation with a few of my friends to offer the resolutions which I have just submitted to the Convention. They embrace propositions of great magnitude, and I regard them as questions upon which we ought now to act, and act decisively.</p>
              <p className="p-in-sp">
                The first resolution, Mr. President, affirms a proposition which I believe is conceded by a very large majority of this Convention. It is <PageNumber num={283} /> that there is no power on the part of the Federal Government to coerce one of these seceding States. The second proposition is that the people of Virginia will never consent that the Federal
                power, which is in part our power, shall be exerted for the purpose of subjugating the people of seceded States to the Federal authority. In that proposition there is a very general and hearty concurrence on the part of most gentlemen here. The third resolution proposes that this Convention shall elect a committee to proceed to Washington, for the purpose of presenting these
                principles, avowed by this Convention, to the President of the United States, and to respectfully ask from him an answer thereto, with the hope of breaking a silence that has been so long protracted, so profound, and so mysterious as to create the greatest solicitude and alarm throughout the country. None of us know what the condition of things will be to-morrow. I very well see the
                difficulties that may exist in the recognition of the Commissioners from the Confederate States, presenting themselves as they do as ministers or as the representatives of Foreign Governments. Our Committee will present themselves in no such relation to the President—they will present themselves to him as their President, in the exercise of their trust, in the enjoyment of their
                power, in the administration of their government and their laws, for the purpose of knowing how that agent is executing that trust—how he intends to execute that trust. We desire an opportunity to express to him our entire protest against the exercise of that trust in a certain manner. I am not alarmed, Mr. President, by the newspaper accounts or by the telegraphic dispatches. I have
                not regulated my conduct by such sources of information. We have waited here for more than a month, with an anxious desire, on the part of us all, to preserve the peace, and on the part of many, a very ardent wish that there should come from the President of the United States such a clear and distinct enunciation of the manner in which he intended to exercise his power and to
                discharge the trust that has been reposed in him by others, that the country at large might understand and know it, clearly and distinctly. This long silence is operating most injuriously upon every interest in the land. I should feel that I had failed to discharge my duty, if I were to return to my constituents and say to them, after all the sources of information that have
                surrounded us, that I could not tell what the President of the United States and his Cabinet intend to do on the momentous questions of war and of peace. I trust that he may speak the words of peace; but from the long silence the President has kept, and from various circumstances connected with the Government, there are serious
                <PageNumber num={284} /> grounds of apprehension that a contrary course may be resolved on. I do not affirm that he intends war—I hope and trust he does not—but a suspicion of that kind exists in the minds of many; and that very apprehension is the point of difficulty under which I labor.
              </p>
              <p className="p-in-sp">
                Will such an enquiry as these resolutions propose be regarded as an intrusion upon him? I have studiously and particularly guarded the resolution and couched it in the respectful language that is due to the President of the United States. The language has been selected by me with great care, that it may be language proper to address to the President of the United States. The request
                is, that he shall communicate to this Convention—a Convention of one of the sovereign States of this Union—the course of policy which he intends to pursue with regard to the great and momentous question now agitating the country. We desire peace, but there is no sense of security and peace in the country under this uncertainty and great delay, and under the machinations, may I say,
                that surround the President at Washington. The silence is more alarming, more ominous of the future than any words, unless they are words of express, implacable and immediate war.
              </p>
              <p className="p-in-sp">Now, sir, another remark. Will the President feel that this is an intrusion upon him? He cannot. It is intended in no such sense, with no such purpose. Is it an improper thing for a sovereign State to call upon the government of which it is a constituent part, to know what its purposes are?</p>
              <p className="p-in-sp">
                This is an inquiry addressed to our agent, to our Government, to know whether, as the agent and head thereof, he intends to make war upon sovereign States ; war that must involve me and my people, my friends, in its consequences and results. I consider this silence most remarkable and most ominous; and we want to break it, if possible. Gentlemen may say that he will not answer. Very
                well. We cannot compel him to answer; but we will place him in this position that he must make some declaration of his intentions. I believe the fairest measures that can be resorted to on this subject, are those which are most manly; and can he or will he say that he will not answer? Is it an intrusion to ask him what his policy is to be? Has it com, to this, that the Government of
                the United States will feel its dignity wounded, or its rights infringed upon, because a sovereign State in Convention asks him to speak the word which they desire—the word of peace, of forbearance? Suppose he does not answer. Silence at such a time as this would be regarded as very significant, and it is in <PageNumber num={285} /> consequence of that
                silence that I desire the appointment of a Committee to go to Washington.
              </p>
              <p className="p-in-sp">
                It is said that he will not answer. Then you see the President of the United States in a position in which he will not answer his principal—will not answer those whose trust he administers—will not answer, so that our families, our friends, our vocations, our business, can go on in the steady and assured form in which alone any government ought to be administered. Sir, a greater
                curse could not come upon a country than the curse of perpetual apprehension of war. I had rather realise it to-morrow—and so would every man—than to have the strong, perpetual, and permit me to say Mr. President, just and reasonable apprehension resulting from this long silence. What superinduces it? I shall not go into any general debate upon this question. What can superinduce it?
                Is it because he wants to take us by surprise, if he does intend war? I impute no such motive. The greater, however, is the necessity for the disclosure. Does he intend to avoid war—not to do an act tending to result in war? Why, then, is he silent? Is it for the mere purpose of adjusting mere party questions, enabling gentlemen to make an easy transit from the extreme of error they
                have labored under to truth and justice? The more he is stimulated, hastened, by our just demands to declare his policy on this subject, required of him in the resolutions, the better and easier it will be for him. I see a powerful necessity pressing upon him to break this portentous silence.
              </p>
              <p className="p-in-sp">
                We heard, in the Inaugural Address, that he was not going to do certain things (which this Committee of Twenty-one, in this connection, have since told him are very dangerous measures to be ventured upon) , because his masters, the American people, had not clothed him with the means necessary to carry them into effect. Are we to wait during the whole summer, until his purse is
                replenished, before we are to know what is to be his policy. But we hear now that the President has recently negotiated a large loan of eight millions; and if that is the state of his finances, and his American masters have furnished him with the sinews of war, we must expect something decisive in a short time. I ask if that is not a conclusive reason why we ought to act on these
                resolutions without delay?
              </p>
              <p className="p-in-sp">Again, let us look at this matter in another aspect. If the resolutions are calculated to produce mischief—if they did not tend to preserve peace and secure tranquility—I could not have been induced to offer and advocate them. But, I ask, will they not tend to peace?</p>
              <PageNumber num={286} />
              <p className="p-in-sp">Do they not ask for a fair and manly understanding with regard to the principles upon which our own government is to be conducted?</p>
              <p className="p-in-sp">
                There may be some apprehensions that the President will not speak. Gentlemen to whom I disclosed my purpose, said to me, that he might delay or protract his answer. Very well. The longer he protracts his answer, the more ominous and dangerous the silence becomes, the greater the necessity that we who are sent here to shield and protect the Commonwealth by our vigilance, and by our
                conservative opinions and action, should be at our posts. I think this Convention could not do a better thing than to stand to their posts and watch vigilantly and patiently the progress of affairs, especially, when fleets and armies are concentrated by the General Government for objects and purposes we know not of.
              </p>
              <p className="p-in-sp">
                I hope it will be the pleasure of the Convention to elect, from their own body, three gentlemen who shall go to Washington, present themselves to the President, and respectfully ask of him that he shall answer on all the subjects referred to. I sincerely hope and trust his answer will give to the country repose; that it will insure tranquility and peace, and, at least, that we may
                know what we are going tot do and what we ought to do on the great subjects that are now engrossing all our thoughts and evoking every patriotic impulse of our nature.
              </p>
              <div className="speaker" id="sp2460"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">
                I rise to a question of order. The Committee have made a report embracing this very question of coercion. That report, embracing these resolutions, was referred to the committee of the whole, and the question of order I make is, that it is not competent for the Convention now to entertain this question without discharging the committee of the Whole from the farther consideration of
                the subject.
              </p>
              <div className="speaker" id="sp2461"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The object of the report of the Committee of Twenty-one, as the Chair understands it, is to agree to the amendments to the constitution of the United States, which are to be submitted in a constitutional way to the people of the other States for their ratification or rejection. The Chair understands the object of the gentleman from Montgomery [<span className="persName">Mr. PRESTON</span>] , to be to express the sense of this Convention upon certain topics and to communicate them directly to the President of the United States.</p>
              <p className="p-in-sp">The Chair is, therefore, of the opinion that the point of order is not well taken, and he overrules it.</p>
              <div className="speaker" id="sp2462"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">
                Whither are we drifting? Why are we here, and what is the legitimate business in which this Convention should employ itself? The law providing for this Convention contemplated, I think, two things, that it should consider whether it would advise <PageNumber num={287} /> the people of Virginia to change their existing relations with the Federal
                Government, and whether it would advise any change in the organic law of our own State? Were we sent here to inquire of the Federal Government the reasons for either its silence or the utterances of its determination?
              </p>
              <p className="p-in-sp">I understood the distinguished gentleman from Montgomery [<span className="persName">Mr. PRESTON</span>]
                , to say that he does not rely upon any thing that appears in the newspapers, in the telegraphic despatches or any thing that is before the public; but he predicates the support of his resolutions upon the ground that the continuance of peace on the part of the Federal Government, for more than a month since the present administration came into power, is evidence of war.
              </p>
              <p className="p-in-sp">Now, sir, if we are to judge what is to be the policy of the Administration in the future, by what has been its policy in the past, there cannot be, and the gentleman must be mistaken when he supposes that there is, any apprehension in the minds of any individual in the Commonwealth of Virginia, that this Administration means war.</p>
              <p className="p-in-sp">
                There is another reason why I do not see that the gentleman himself can entertain such an apprehension. He has been at one period of his life a Cabinet officer, I believe. He must, therefore, know that the power to declare war is not vested in the President of the United States. War can only be declared on behalf of this Government, by the Congress of the United States. The Executive
                Department has no power to make war; and, therefore, I think that there is no ground for apprehension that the President of the United States will do that which he has not the power to do, and which, were he to attempt, would render him liable to impeachment. It would be an unconstitutional act on his part, one that he has not the power to perform.
              </p>
              <p className="p-in-sp">
                Furthermore, sir, as the representatives of the people of Virginia, who are a part and parcel of this Government, how can we be apprehensive that its power is to be used and wielded for the purpose of making war upon them? Or is it a legitimate business, in the minds of gentlemen of this Convention, that we are to watch here as a guard for the Confederate States? Were we sent here
                for any such purpose? Were we not rather sent here to determine what is the interest of the State of Virginia, and what duty requires to be done by her in this crisis? We were told, not more than three or four days ago, that Virginia should unite her fortunes with the Confederate States of the South, because this Federal Government of ours was unable to obtain the loan of a dollar;
                that it was bankrupt, was the language used by the gentleman from Culpeper
                [<span className="persName">Mr. BARBOUR</span>]
                , but <PageNumber num={288} /> two or three days ago. And now, sir, it would seem that we are to be frightened out of our propriety by the fact that this bankrupt Government has succeeded in obtaining a loan of eight millions of dollars, which the Confederate States could not succeed in obtaining, but which the gentleman from Culpeper informed us they
                were about to procure. I cannot for the life of me reconcile these conflicting statements made by gentlemen who seem to be driving to the same end, for the accomplishment of the same purpose—to involve this State in greater difficulties than she has yet been involved in, to bring still greater peril than that which has already been brought upon the country by the acts of those who
                tell you that they have done so because they believe this Government should never have been formed, and not because of any oppression inflicted upon them by the Federal Government.
              </p>
              <p className="p-in-sp">But there is another objection to the resolution offered by the gentleman from Montgomery [<span className="persName">Mr. PRESTON</span>]
                . The co-ordinate department of the Government, whose duty and right it is to enquire of the Executive Department as to kindred subjects brought before this Convention by the resolutions of the gentleman from Montgomery, for a disclosure of its policy, never makes an enquiry without saying "if it be compatible with the public interests." Now, sir, we have no Constitutional authority
                to enquire as to the purpose of the Administration at Washington, to exercise a power which the co-ordinate department of the Government does not itself exercise when it performs a constitutional and a legitimate duty; and I would say that the President might well treat your Commissioners—or whatever title with which you would clothe the gentlemen whom you would send in accordance
                with the resolution—by saying "It is not my duty to answer your inquiry."
              </p>
              <p className="p-in-sp">
                And he would be right in so saying, because if we have a right to make an enquiry of him, each and every one of those States which, we are told, are threatening war against those Southern States, and which have volunteered to give men and money to the Administration for the purpose of bringing on a war and aiding him in this coercive policy which gentlemen tell us he intends to
                pursue, might make similar inquiries, might ask him why he did not inaugurate a war, why he did not enforce the laws, why he had allowed these States to go on as they have done, and to continue their present attitude, the attitude of hostility, the attitude of a foreign Power to the Federal Government. Why, sir, if such would the case, the Cabinet, and all the employees of the
                Administration would be engaged, not in the legitimate duties <PageNumber num={289} /> pertaining to the simple administration of the Government, but in answering enquiries made by the various States of this Union, following the precedent which the gentleman from Montgomery would have the Virginia Convention set. I understood the gentleman from
                Middlesex
                [<span className="persName">Mr. MONTAGUE</span>] , to move that the Committee rise, because it would save the State a week's rent, and if it did not then rise there would not be time to move the furniture. I think, sir, we might as well adjourn; and I move that the Convention do now adjourn.</p>
              <div className="speaker" id="sp2463"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">Is that motion in order.</p>
              <p><span className="persName">The PRESIDENT</span> decided that it was.</p>
              <div className="speaker" id="sp2464"><span className="persName">Mr. HULL</span>, of Highland—</div>
              <p className="p-in-sp">I hope that motion will not prevail. The gentleman having charge, tells me that he will take care of the furniture, take up the carpet and store it away for $25. You can have the use of the Hall till 12 o'clock to-night.</p>
              <p>The motion to adjourn was put and lost.</p>
              <div className="speaker" id="sp2465"><span className="persName">Mr. BALDWIN</span>, of Augusta—</div>
              <p className="p-in-sp">
                It seems to me Mr. President, that this proposition is one which, if properly directed, has merit in it; and that it will not do for us on the one hand to hurry it through without fair consultation, or on the other hand, to reject it, without giving it the consideration that its importance demands. It cannot be denied that the condition of uncertainty into which the public mind is
                left as to the policy of the administration at Washington, in regard to the great questions of peace or war, is doing serious damage to the commercial and industrial interests of the country. It is presenting a serious obstacle to anything like the calm deliberation which is essential to the proper adjustment of the pending difficulties, and is tending constantly towards
                exasperation, violence and bloodshed. I think we will all agree upon that. But I think there will be a little difference of opinion in regard to this proposition : that the President of the United States would do an immense public service if he would at once determine upon a pacific policy and announce that policy to the public, so as to give to the public mind a perfect assurance as
                to the future upon this subject—I have no doubt, sir, that if this Convention communicates with the President of the United States upon this subject, under proper circumstances, that much good can be effected in bringing about such a desirable disclosure of the mind of the administration; and I think that it is important for us to address ourselves to the manner in which that
                communication ought to be made. I have not the least objection to the appointment of a committee. It seems to me that a proper committee, such as would be appointed by this Convention, would exert a favorable influence, not only upon the <PageNumber num={290} /> disclosure of the policy of the administration, but upon the adoption of the policy by the
                administration.
              </p>
              <p className="p-in-sp">
                But, sir, it seems to me, with all deference to the gentleman who has moved these propositions, that he is in danger of destroying, to some extent, the influence, the just weight of the movement, by accompanying it by propositions of political law, as to which much diversity of opinion may be found among the members of this Convention. It seems to me the less we indulge in our
                communications with the administration at Washington, in abstract propositions of constitutional law, the less we engage in discussion about political matters, the more apt we are to come to a favorable result from this mission. I fear there is too great a tendency in this Convention to run into discussion of abstract politics. I hope we will confine that agency to such declarations
                as we make peculiarly as a Virginia Convention addressed to the other States of the Confederacy, and when we come to a practical matter, addressing ourselves to the administration at Washington, that we will avoid the mooting of any question about which there can be difference of opinion.
              </p>
              <p className="p-in-sp">I do not see the necessity of the first and second resolutions offered by the gentleman from the county of Montgomery [<span className="persName">Mr. PRESTON</span>]
                . It seems to me that a single resolution appointing a committee, and defining the duties of the committee in regard to the obtaining of the information, and in regard to impressing a particular line of policy on the Administration, will answer every purpose. Having heard of this plan, and having seen something of the resolutions, as the gentleman did me the honor to show them to me
                on the way to the Convention, anticipating that I should have another opportunity of conferring with him, I was engaged in the preparation of something in the way of a substitute, not to be offered here, but to be submitted to him in conference for his consideration, and I have gotten to the extent of preparing a short preamble intended to be followed by his third resolution
                substantially, and had proceeded no further. I will read what I did prepare, as an indication of my views as to the proper declaration to be made in approaching the President of the United States.
              </p>
              <p><span className="persName">Mr. BALDWIN</span> read the preamble he had prepared, as follows :</p>
              <p>
                "Whereas, In the opinion of this Convention the uncertainty which prevails in the public mind as to the policy which the General Government intends to pursue toward the seceded States is extremely injurious to the industrial and commercial interests of the country, tends to keep up an excitement which is unfavorable to the adjustment of
                <PageNumber num={291} /> the pending difficulties, and threatens a disturbance of the public peace ; therefore,
              </p>
              <p>"Resolved, That a committee be appointed," &amp;c.</p>
              <div className="speaker" id="sp2466"><span className="persName">Mr. PRESTON</span>, of Montgomery—</div>
              <p className="p-in-sp">
                The reason why I offered that resolution was because it is a copy of the precise phrase of the 8th resolution in the report, that has met so hearty an approval in this House all day long; that the people of Virginia will never consent that the Federal power, which is in part their power, shall be used for the purpose of coercion. I made that as the ground upon which the committee
                might present themselves to the President and say: "This is the temper of Virginia and in that temper we come in peace to ask you to pronounce and declare what your policy is." Anything on that subject, sir, I am willing to accept, that will meet the approbation of the house. The inquiry
              </p>
              <div className="lg">
                <div className="l">—"is the thing</div>
                <div className="l">Wherein to catch the conscience of the king."</div>
              </div>
              <div className="speaker" id="sp2467"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">
                I do not desire, sir, to impress upon this mission to Washington any peculiar opinions of mine. I have not the least desire to infuse into the preamble or the resolutions any of my opinions upon abstract policy—not the least. I do not think I am asking anything unreasonable of gentlemen who may differ with me in regard to questions of abstract politics, when I urge upon them not to
                press their peculiar views into these resolutions. It seems to me that there is no sacrifice of principle anywhere in withholding the expression of these opinions upon controverted points. If this mission is to be beneficial, if it is to have weight in determining the policy of the Administration, or if it is to bring from the Administration a respectful recognition of the objects
                that we have in view, that object, sir, will be very much promoted, as we can all see, by unanimity on the part of this body. I understand the gentleman from Montgomery to accept the preamble which I offered as a preamble to the third resolution, and to withdraw his first and second resolutions. I have no objection whatever to his third resolution. I was about to suggest whether it
                would not be well—I have not formed an opinion myself upon the subject—but I suggest whether it would not be well, in addition to enquiring into the policy of the Administration, for the Committee to express their hope that that policy would be pacific. I simply throw out the suggestion, for consideration, without having formed any opinion of my own upon it.
              </p>
              <p>The Clerk read the preamble and resolution as amended.</p>
              <PageNumber num={292} />
              <div className="speaker" id="sp2468"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">I move to amend the preamble by inserting after the words "Seceded States" the words, "and as to the policy the seceded States intend to pursue towards the General Government," so as to make it read :</p>
              <p className="p-in-sp">
                "Whereas, in the opinion of this Convention the insecurity which prevails in the public mind as to the policy which the General Government intends to pursue towards the seceded States, and as to the policy the seceded States intend to pursue towards the General Government, is extremely injurious to the industrial and commercial interests of the country, tends to keep up an excitement
                which is unfavorable to the adjustment of the pending difficulties and threatens the disturbance of the public peace, therefore."
              </p>
              <div className="speaker" id="sp2469"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">I understood the gentleman from Halifax to move to suspend the rule by which this resolution should go to the Committee on Federal Relations. I think that vote has never been taken.</p>
              <div className="speaker" id="sp2470"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair understood the objection to considering the resolution now as waived.</p>
              <div className="speaker" id="sp2471"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">On the contrary I made the point of order which the Chair decided. The gentleman moved to suspend the rule and I inquired of the Chair if it did not require two-thirds to suspend the rule.</p>
              <div className="speaker" id="sp2472"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair certainly understood the objection to be waived and he thinks it is now too late to renew it.</p>
              <p>The amendment was disagreed to.</p>
              <div className="speaker" id="sp2473"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">I now move to amend the resolution by adding, "and that a like Committee be appointed to wait upon the seceded States and report to this Convention what policy they intend to pursue towards the General Government."</p>
              <div className="speaker" id="sp2474"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I was about to suggest playfully to my friend, that it was likely he would be appointed upon that Committee to go South, and if he is, it will be the last of him.</p>
              <div className="stage it">[Laughter.]</div>
              <div className="speaker" id="sp2475"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">I suppose I may be indulged by way of reply, to the gentleman from Princess Anne, that if this Convention will adopt the amendment, I will most cheerfully go South, if it is the pleasure of the body to send me upon that Committee, and I have no fear of not returning. [Laughter.] The amendment was disagreed to.</p>
              <p>The question was then put, and the Chair decided the preamble and resolution adopted.</p>
              <div className="speaker" id="sp2476"><span className="persName">Mr. JACKSON</span>—</div>
              <p className="p-in-sp">
                I understand that this resolution has been passed. I did not understand that the proposition was propounded to the Convention any more than I understand the sense of the Committee when <PageNumber num={293} /> they rose, as was generally understood, for the purpose of an immediate adjournment. I rise now to move a reconsideration of the vote by which
                the resolution was adopted.
              </p>
              <div className="speaker" id="sp2477"><span className="persName">A MEMBER</span>—</div>
              <p className="p-in-sp">How did you vote?</p>
              <div className="speaker" id="sp2478"><span className="persName">Mr. JACKSON</span>—</div>
              <p className="p-in-sp">I did not vote at all, because I did not understand the question as put to the Convention, but I submit to the Chair that I am in order, because any gentleman not dissenting is understood to be in the affirmative.</p>
              <div className="speaker" id="sp2479"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair understands the rule to be, that the motion must be made by a member actually voting with the majority.</p>
              <div className="speaker" id="sp2480"><span className="persName">Mr. JACKSON</span>—</div>
              <p className="p-in-sp">Well, sir, every member around me supposed that you were propounding the proposition of the gentleman from Harrison [<span className="persName">Mr. CARLILE</span>] .</p>
              <div className="speaker" id="sp2481"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair propounded distinctly the proposition made by the gentleman from Harrison, first to the preamble, then his amendment to the resolution, then upon the adoption of the preamble and resolution.</p>
              <div className="speaker" id="sp2482"><span className="persName">Mr. SCOTT</span>, of Fauquier—</div>
              <p className="p-in-sp">I suppose, if there was, to any general extent, a misunderstanding on the part of the Convention, the Chair would have no difficulty in again taking the vote.</p>
              <div className="speaker" id="sp2483"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">It may be done by general consent.</p>
              <div className="speaker" id="sp2484"><span className="persName">Mr. JACKSON</span>—</div>
              <p className="p-in-sp">I rose several times to gain the attention of the Chair, but I am exceedingly unfortunate in that respect. I mean no reflection upon the Chair, but I certainly did endeavor to obtain the floor before any vote was taken upon this resolution.</p>
              <div className="speaker" id="sp2485"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair will again put the question to the Convention.</p>
              <div className="speaker" id="sp2486"><span className="persName">Mr. JACKSON</span>—</div>
              <p className="p-in-sp">
                I do not participate in the merriment which seems to pervade the hall upon the present occasion. In my humble judgment, it is the most solemn moment that has occurred since we have met here. I utterly dissent from this resolution, the adoption of which seems to be a foregone conclusion. The resolution proposes to do that which you have no constitutional right to do.
              </p>
              <div className="speaker" id="sp2487"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I rise to a question of order. I do not understand that the courtesy extended to the gentleman to have the vote taken over goes to the extent of permitting him to enter into a discussion of the resolution.</p>
              <div className="speaker" id="sp2488"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair regards it as if no vote at all had been taken.</p>
              <div className="speaker" id="sp2489"><span className="persName">Mr. JACKSON</span>—</div>
              <p className="p-in-sp">
                The Constitution of the United States, as has been very justly remarked by the gentleman from Harrison, has properly <PageNumber num={294} /> provided a certain mode by which information is to be obtained from the Executive Department, and as I understand it, being a bit of a lawyer, that excludes all other ways expressio unius est exclusio alterius. I
                say the Constitution provides certain means by which you may get certain information; and it provides that even then it is to be given only, if in the judgment of the President, it is compatible with the public interest. That is the proper mode of addressing the Executive.
              </p>
              <p className="p-in-sp">Sir, if you pass this resolution, and send these Commissioners to Washington, will they be received with more consideration than was the distinguished gentleman, I believe not now in his seat [<span className="persName">Mr. TYLER</span>]
                who was sent by Joint Resolution of the General Assembly of Virginia, to Mr. Buchanan? What was the answer he received? If I recollect correctly, he reported to the Governor that Mr. Buchanan received him very politely, treated him very much like a gentleman, but told him substantially, that it was none of his business. Certainly he reported that the interview was wholly
                unsatisfactory. And now, sir, I put it to you, when you send this committee to the city of Washington, what answer can you reasonably expect? The preamble of your resolution is in fact an annunciation to the President that the policy he has seen fit to pursue has been such as to bring upon the country the calamities which are now pervading it.
              </p>
              <div className="speaker" id="sp2490"><span className="persName">A MEMBER</span>—</div>
              <p className="p-in-sp">And it is true, too.</p>
              <div className="speaker" id="sp2491"><span className="persName">Mr. JACKSON</span>—</div>
              <p className="p-in-sp">
                It may be true, but it is not very polite to tell the President that it is true; and my word for it, if this committee goes there with such a preamble as that, he will regard it as a reflection upon him. If he is a sensible man, he cannot regard it otherwise. If it be true that his policy has caused the difficulties that pervade the country, this preamble is as much as to say that he
                can change the policy under the circumstances that surround him. Sir, if his policy has produced that effect, and he can change it, it would be a monstrous reflection upon him. He is a miscreant if he would not relieve the country, if it were in his power to do it. Does any gentleman here believe that the President of the United States is a Nero—that he is fiddling while Rome is
                burning? Does any gentleman believe that if the President of the United States could relieve this great country from the throes and convulsions that now agitate it, he would hesitate a moment to do it? Sir, he would be worse than a miscreant if he had it in his power and did not do it. Why, then, does he not do it? Simply because the condition of public affairs is such that he has
                not the power. If he has information which would quiet the country, he owes <PageNumber num={295} /> it to the whole country, as well as to us, that it should be imparted. But, sir, in view of all the responsibilities resting upon him and of the circumstances with which he is surrounded, I have no doubt he is pursuing that policy which, in his judgment
                and in the judgment of his Cabinet, is calculated to promote the public good. Your resolution and your preamble are a reflection upon him. They are an announcement that he is dark—I will not say a sort of midnight lantern, but that he is dark, that he is mysterious and that he ought to be more communicative to us and to the country. Sir, why should he withhold information unless the
                public good requires that it should be withheld? What other answer could he give to your committee? I venture to say that when your committee have called upon him he will return them no other answer than that. He could not make any other answer without stultifying himself. He could make no other answer without rendering himself infamous in the public mind.
              </p>
              <p className="p-in-sp">
                But, sir, that is not my chief objection to the resolution. I ask gentlemen to bear in mind—I ask Western gentlemen to bear it in mind, that when your committee come back, as I think they will do—if it were not a vulgar expression, I would say with a flea in their ear—when they come back with an answer that is wholly unsatisfactory, and then comes up this Ordinance of Secession, we
                shall be expected to rush off like a flock of sheep, right into the embrace of the Southern Confederacy, where sir, so help me God, I do not mean to go under any circumstances. '
                [Laughter.] I want my decision to be understood, though I stand here solitary and alone. Sir, I only claim to speak for myself, and I think, for my constituents, when I declare that you cannot under any circumstances make us go to South Carolina.</p>
              <div className="speaker" id="sp2492"><span className="persName">A MEMBER</span>—</div>
              <p className="p-in-sp">Suppose Virginia goes, what then?</p>
              <div className="speaker" id="sp2493"><span className="persName">Mr. JACKSON</span>—</div>
              <p className="p-in-sp">
                If Virginia desires to form this middle confederacy contemplated by the propositions now under consideration, we will stand with her; but when you ask us to break up our markets, impoverish our people, destroy our domestic interest, to drench our homes in blood, to exile from our altars, and stultify ourselves in the sight of the whole world by admitting that which we know is not
                true, that there is any sufficient cause to break up this Union, I tell you we will not do it. We do not mean to do it. We are ready to stand by you in securing all the guarantees that are necessary for your protection, in view of the altered circumstances of the country, but when you ask us to go farther—to disrupt all our social and political relations—to subject our homes and all
                that we possess to the horrors of <PageNumber num={296} /> border and internecine strife, then we tell you that this thing called secession has more stings in it than one. We will tell you that we will throw ourselves on our own rights and stand by them. We will stand by you as long as you will permit us to do so, but when we can no longer do so, then
                we will take care of ourselves.
              </p>
              <p className="p-in-sp">
                And now I tell you, Western gentlemen, that when this Committee comes back with their unsatisfactory answer, the agitation by which the country is now convulsed, and in which so many gentlemen here delight, who are rolling it as a sweet morsel under their tongues, will be intensified, and you will be expected to go over to the secessionists, horse, foot and dragoon.
              </p>
              <p className="p-in-sp">
                They will struggle to launch this State upon the sea of the Southern movement, and then you may bid adieu to the star spangled banner and all the untold blessings which we have enjoyed under it for the last seventy years. I ask gentlemen to bear my prediction in mind. I know that my words are not to have any weight here. I know that the passage of this resolution is a foregone
                conclusion. But I am ready to throw myself in the breach even if I have no one else to stand by me but my friend from Harrison t
                [<span className="persName">Mr. CARLILE</span>]
                . If other champions had been ready to take up this glove I would have remained as I have hitherto remained—retired, silent, and content with casting my vote. But when there was nobody else to speak I had to raise my voice. I had to show my little farthing light; though it was ever so small and ever so little heeded by those near me, and warn them of the consequences of their hasty
                and ill-advised action. Sir, I have done.
              </p>
              <div className="speaker" id="sp2494"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">
                Mr. President, I have heard the declaration of the venerable gentleman who has just taken his seat, with a vast amount of surprise. I confess, that while I had some dim expectation that such was the design of that gentleman, I did not think that that design would be publicly avowed on the floor of the Convention. What is his declaration here? And he tells you he speaks for the
                North-west.
              </p>
              <div className="speaker" id="sp2495"><span className="persName">Mr. JACKSON</span>—</div>
              <p className="p-in-sp">No, sir, I did not say that. I said that I spoke for myself, and that I believed I represented my constituents in so speaking.</p>
              <div className="speaker" id="sp2496"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">
                The gentleman speaks for himself and his constituents, and in the same breath he appeals to his Western brethren on this floor to observe what will be the result of the movement now attempted to be put on foot. The plain English of it is that while these gentlemen stand here and vote with us, their votes counting equally with our votes—while they declare that justice and equity
                require that our slave property shall be taxed ad valorem with all other <PageNumber num={297} /> property, yet in the same breath they tell you that if a majority of the people of Virginia decide to carry Virginia into the Southern Confederacy, they are determined to divide the State, and join the free soil Confederacy.
              </p>
              <div className="speaker" id="sp2497"><span className="persName">A MEMBER</span>—</div>
              <p className="p-in-sp">Himself, not they.</p>
              <div className="speaker" id="sp2498"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">Well, do other Western gentlemen disclaim it?</p>
              <div className="speaker" id="sp2499"><span className="persName">Mr. JACKSON</span>—</div>
              <p className="p-in-sp">The gentleman misapprehended me. I said nothing of the kind.</p>
              <div className="speaker" id="sp2500"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">What did you say?</p>
              <div className="speaker" id="sp2501"><span className="persName">Mr. JACKSON</span>—</div>
              <p className="p-in-sp">I said this—that we would stand by you in securing you the Constitutional guarantees necessary for the protection of your rights; that if you were content with that we would stand by you, but that if you intended to hitch us on to South Carolina we would not go with you.</p>
              <div className="speaker" id="sp2502"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">Yes ; and you and not we are to be the judges of the efficiency of those guarantees.</p>
              <div className="speaker" id="sp2503"><span className="persName">Mr. JACKSON</span>—</div>
              <p className="p-in-sp">This Convention was to be the judge of that.</p>
              <div className="speaker" id="sp2504"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">
                You would stand by us and give us such guarantees as you might judge proper, if we would stay with you in a middle Confederacy or remain in the Northern free-soil Confederacy; but if we would not do either, then you would divide the State. That is the plain English of the matter. Now as you have appealed to the West, I appeal to the East, to note carefully and mark well what has
                occurred here this evening.
              </p>
              <p><span className="persName">Mr. MARR</span> called for the previous question.</p>
              <p>Several members requested <span className="persName">Mr. MARR</span> to withdraw the call.</p>
              <div className="speaker" id="sp2505"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">Does the gentleman withdraw the call for the previous question?</p>
              <div className="speaker" id="sp2506"><span className="persName">Mr. DORMAN</span>—</div>
              <p className="p-in-sp">I object to its withdrawal.</p>
              <p>The previous question was seconded, and the President stated that the ten minutes debate was now in order.</p>
              <div className="speaker" id="sp2507">
                <span className="persName">Mr. MARR</span><span className="note" id="Note36"><span className="noteLabel">7</span>Mr. Marr noted in the Proceedings of April 10, p. 506, that this should be "Mr. Early," and not "Mr. Marr." The Enquirer (April 11) made the same correction.</span>—
              </div>
              <p className="p-in-sp">
                I have made no professions on this floor of my devotion to the State of Virginia. My little experience in life has shown me that expressions of patriotism are very little to be relied upon. I trust in God, that when Virginia's hour of trial does come, I will have the nerve and the ability to do my duty. My fortunes are with her, and shall ever be with her, under all circumstances,
                through weal or woe. I shall neither desert her in her hour of prosperity, her hour of adversity, her hour of glory, or her hour of shame. She is my mother, and I will stand by her under all circumstances. That I hope to prove, Mr. President, not by my declarations, but by my acts. What do we <PageNumber num={298} /> propose to do? To call upon the
                President of the United States, with a declaration in our mouths that his course has been so uncertain that it has precipitated the country into all this calamity, and that we want him now to tell us what is to be his course of policy. Why, sir, if the President has a particle of pride, if he has a particle of manhood in him, will he not say, as Mr. Fillmore said to the Governor of
                South Carolina, in 1852, "I am not responsible to you, or to the Convention of Virginia, I am responsible only to the nation"? What, then, will be the effect of this call? It will be to precipitate on the State of Virginia that condition of things which we have been seeking to avoid.
              </p>
              <p className="p-in-sp">When the motion that the Committee rise, was made by the gentleman from Middlesex, the avowed reason was, that it would cost the State $150 extra if we should sit here too late to allow the furniture to be removed. It is true he mentioned the fact that the gentleman from Montgomery [<span className="persName">Mr. PRESTON</span>]
                , was going to offer a resolution. But, who of us voted to rise on the latter ground? Here is a resolution of which I had no anticipation. It is sprung upon us, and we are called upon now, on this Sabbath eve, to give a vote which may precipitate us into revolution before another Sabbath eve rolls around. I submit to this Convention, whether it is not due to the great interest
                involved, due to Virginia, due to her dignity, that we should, at least, take one night to reflect on this question? If the question is to be put at this time, I will be compelled to vote against the resolution.
              </p>
              <div className="speaker" id="sp2508"><span className="persName">Mr. WILLEY</span>—</div>
              <p className="p-in-sp">
                Mr. President, we are not to be controlled in our action here by threats or promises thrown out to the West, on this or any other question. We stand perfectly free and perfectly independent, and so we will stand so long as the eternal mountains raise their heads. We will stand by the honor and interests of Virginia and maintain her right and integrity, at this time, at any time, and
                under all circumstances. But we do not wish to be precipitated into a measure which, in my humble opinion, demands some reflection. I am very much mistaken, if this is not a measure fraught with the deepest and highest importance.
              </p>
              <p className="p-in-sp">
                I am very much mistaken about this measure if it will not precipitate us into revolution before fifteen days. In that view I rise to submit to the Convention whether the occasion is not one of such emergency, that we ought not to be compelled to record our votes upon it to-night; and whether we ought not to be permitted to reflect over it during the Sabbath day, and to come here on
                Monday morning with our minds maturely made up on the question. Why this hot haste?
              </p>
              <PageNumber num={299} />
              <p className="p-in-sp">Why this endeavor to put the resolution through without deliberation and discussion? I beg gentlemen to pause and reflect.</p>
              <p className="p-in-sp">
                I beg them to consider the consequences which may ensue from hasty action. I am rather favorably impressed with the propriety of the resolution as now modified. My judgment inclines me to support it. But at the same time I desire to say that I have some hesitation. some doubt, which is of a painful character. Suppose our committee, when appointed, should meet with a rebuff—suppose
                that it should come back with intelligence not favorable to us—are we in a condition to meet such a contingency? Is Virginia in a condition to meet such a contingency? Suppose the report should be adverse to our hopes and wishes, would it not precipitate that crisis on us at a time when we may be unprepared to meet it? Let us pause and reflect.
              </p>
              <p className="p-in-sp">As to the insinuation of the gentleman from Middlesex [<span className="persName">Mr. MONTAGUE</span>]
                , in regard to the want of fidelity in the Western character, I hurl it back, as I have hurled back such insinuations from the beginning. I say here, that if the worst comes to the worst, it will be again as it has been heretofore : Western men will have to fight your battles. It is on our own mountain men that you must rely at last for the vindication of Virginia's rights,
                Virginia's honor, and Virginia's integrity. No impeachment of the kind will affect us, more than to cause us pain.
              </p>
              <p className="p-in-sp">
                Again I ask, therefore, why should not this Convention be allowed to reflect on this matter for a day and night? Dissatisfaction may be caused by precipitation. I ask whether it may not be worth the consideration of Eastern gentlemen to pause and reflect before, by any action of theirs, they alienate their best friends—the friends of whom they may soon stand in the greatest need?
              </p>
              <div className="speaker" id="sp2509"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">
                I stated awhile ago that I was inclined to the opinion that this matter, properly adopted, would be a beneficial movement, but that to give it proper efficiency it ought to be the unanimous or nearly the unanimous, action of this Convention. For one, I cannot consent that a move of this sort shall be taken by pressing gentlemen to a vote without giving them a fair opportunity for
                consideration. When I see gentlemen coming forward with an earnest sincerity which commands my confidence, and ask time, expressing their inclination to vote for the measure, but at the same time declaring that they have doubts, which they desire an opportunity to solve, I cannot consent to press them forward to a vote on a measure of this importance. Particularly would I regard it
                as extremely disastrous to press this House to a vote on this question in its <PageNumber num={300} /> present temper and feeling. I regretted exceedingly to hear the sort of discussion indulged in in regard to the sections of the State. I come from the very centre of the State, and have no sympathy whatever with extreme feelings in any part of it. I
                have no sympathy with the declarations of a determination to divide the State, coming from the extreme West ; nor have I any more sympathy with declarations of civil war and resistance to the Government, coming from Eastern men, if their views do not prevail. We have had enough of this on both sides. It does not become gentlemen on either side to be throwing hard words at one another
                by such declarations.
              </p>
              <p className="p-in-sp">
                We have had, from the members of the West—of the extreme West a firm declaration of a purpose to resist the will of Virginia ; aye, sir, and we have had from members of the extreme East, a declaration of a purpose to resist the declared will of Virginia. Coming from the great central heart of the State, I represent a people who have no sympathies with any of these extreme views on
                either side.
              </p>
              <div className="speaker" id="sp2510"><span className="persName">Mr. WILLEY</span>—</div>
              <p className="p-in-sp">-Has the gentleman any reference to me in these remarks?</p>
              <div className="speaker" id="sp2511"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">Not at all, sir. I understood the gentleman to disclaim these ultra views.</p>
              <div className="speaker" id="sp2512"><span className="persName">Mr. JACKSON</span>—</div>
              <p className="p-in-sp">I make a similar inquiry.</p>
              <div className="speaker" id="sp2513"><span className="persName">Mr. BALDWIN</span></div>
              <p className="p-in-sp">
                I certainly referred to some of the views of the gentleman, uttered, as I hope, under the influence of the excitement of debate, rather than that of a permanent and abiding conviction. I hope that expressions of the same character, uttered by gentlemen from Eastern Virginia, do not prevail, but that they are mere ebullitions caused by temporary excitement. I do not think we are in a
                condition now to act with the discretion and calmness which becomes a grave and important subject of this character, and I trust that it will be the pleasure of the Convention not to press these resolutions now, but to pass them by until we can act upon them with more coolness and deliberation than we can now exercise in their consideration.
              </p>
              <div className="speaker" id="sp2514"><span className="persName">Mr. DORMAN</span>—</div>
              <p className="p-in-sp">I propose the withdrawal of the call for the previous question, because I desire to see this debate stopped at the earliest moment.</p>
              <p className="p-in-sp">I beg leave to say, without referring to one side or the other, that it is the most unfortunate debate that has occurred within this Hall since the Convention met, and I want to see it stopped as soon as possible. I have been in favor of the resolutions offered by the gentleman from Montgomery [<span className="persName">Mr. PRESTON</span>] , and I was prepared to vote for them <PageNumber num={301} /> without delay, and without question; and I was still more ready to adopt them in the form as amended by the gentleman from Augusta [<span className="persName">Mr. BALDWIN</span>]
                , but I recognize the force of the reasons offered by-that gentleman, and in order that this matter shall be properly considered, and to avoid crimination and recrimination, on the part of Western and Eastern Virginia, I will make a motion to adjourn. I hope the Convention will take until Monday morning to consider this question, and avoid now a discussion which, if persisted in,
                will do us a great deal of harm.
              </p>
              <p className="p-in-sp">I move that this Convention now adjourn.</p>
              <div className="speaker" id="sp2515"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">That motion is not in order.</p>
              <div className="speaker" id="sp2516"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair is of opinion that a motion to adjourn is in order before the Convention has directed by vote that the main question shall be put.</p>
              <div className="speaker" id="sp2517"><span className="persName">Mr. BARBOUR</span>, of Culpeper—</div>
              <p className="p-in-sp">I would inquire whether the main question has not, by a vote of this body, been ordered?</p>
              <div className="speaker" id="sp2518"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The call for the previous question was sustained, but the main question was not ordered.</p>
              <p>The question was then put on the motion to adjourn, and was decided in the affirmative.</p>
              <p>The Convention then adjourned, at half-past seven o'clock, P. M., to meet in the Hall of the House of Delegates, on Monday.</p>
            </div>
          </div>
          <div className="day" id="vsc1965.v3.2.6">
            <PageNumber num={302} />
            <h2><span className="headingNumber">1.6. </span><span className="head">FORTY-SIXTH DAY</span></h2>
            <div className="dateline">Monday, <span className="date">April 8</span></div>
            <p>The Convention met at 10 o'clock in the Hall of the House of Delegates.</p>
            <div className="section" id="vsc1965.v3.2.6.1">
              <h3><span className="headingNumber">1.6.1. </span><span className="head">COMMUNICATION FROM THE GOVERNOR</span></h3>
              <div className="speaker" id="sp2519"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">I have received a communication from the Governor of this Commonwealth which I beg leave to lay before the Convention.</p>
              <p>The communication was read as follows :</p>
              <div className="floatingText">
                <div className="floatingText_body">
                  <p>EXECUTIVE DEPARTMENT,</p>
                  <p>April 8, 1861.</p>
                  <p>Gentlemen of the Convention:</p>
                  <p>
                    On Saturday evening last, I received two communications from Honorable David Walker, President of the State Convention of Arkansas, enclosing an ordinance and resolutions adopted by the Convention, on the 20th day of March, 1861. On an examination of the resolutions it will be seen that they were intended to be laid before the General Assembly, but as that body has adjourned, and
                    as the matters to which they relate are under consideration in your honorable body, I have deemed it my duty to transmit them for you, for such disposition as you may think proper to make of them.
                  </p>
                  <p>Respectfully,</p>
                  <p>JOHN LETCHER.</p>
                  <p>LITTLE ROCK, March 29, 1861.</p>
                  <p>To His Excellency JOHN LETCHER,</p>
                  <p>Governor of the State of Virginia:</p>
                  <p>
                    Sir—By the fifth of the series of resolutions which I have the honor herewith to transmit to you, and which were adopted by the State Convention of Arkansas, on the 20th day of March, 1861, I have respectfully to request that your Excellency will inform me, as soon as convenient and practicable, what action your State has taken or proposes to take in reference to the proposition
                    to hold a Border Slave State Convention, at Frankfort, Kentucky, or elsewhere, during the coming spring or summer.
                  </p>
                  <p>This information is sought with a view to guide the action of the Commissioners or Delegates elected by the Convention of the State of Arkansas to said Convention or Conference of the Border Slave <PageNumber num={303} /> States, should one be held.</p>
                  <p>I trust your Excellency will furnish the desired information at your earliest convenience.</p>
                  <p>My post office address is Fayetteville, Washington County, Arkansas.</p>
                  <p>Very respectfully,</p>
                  <p>Your obedient servant,</p>
                  <p>DAVID WALKER.</p>
                  <p>Pres't of the State Convention of Arkansas. By C. E. BOUDINOT, Secretary.</p>
                  <p>LITTLE ROCK, ARKANSAS,</p>
                  <p>March 28, 1861.</p>
                  <p>His Excellency, JOHN LETCHER,</p>
                  <p>Governor of the State of Virginia:</p>
                  <p>Sir—In accordance with the second resolution of the series herewith enclosed, I have the honor to present to you the action of the Convention of the people of the State of Arkansas, on the subject therein treated of, and respectfully request that you will place it before your Legislature, at the earliest convenient moment.</p>
                  <p>I would be pleased to hear from your Excellency, touching the political affairs of the country, and of the proposed remedies, for existing evils.</p>
                  <p>My post office address is, Fayetteville, Washington county, Arkansas.</p>
                  <p>Very respectfully,</p>
                  <p>Your obedient servant,</p>
                  <p>By E. C. BOUDINOT, Secretary. DAVID WALKER, President.</p>
                </div>
              </div>
              <div className="stage it">[The resolutions referred to will be published so soon as we can obtain a copy of the same from the Convention printer.]</div>
              <p>
                <span className="note" id="Note37"
                ><span className="noteLabel">1</span>The Arkansas resolutions and ordinance transmitted by Governor Letcher were printed on April 13 in the Enquirer. The Convention Journal for April 8 notes that the communications and accompanying documents "were laid upon the table, and ordered to be printed." The three letters printed in the Proceedings of April 8, and the resolutions and ordinance,
                  were printed as Doc. No. XVI, Communication from the Executive of the Commonwealth Transmitting Communications from the President of the State Convention of Arkansas. It appears that the resolutions and ordinance were not included in the Proceedings.</span
                >
              </p>
              <p>While the communications were being read,</p>
              <div className="speaker" id="sp2520"><span className="persName">Mr. STAPLES</span> said—</div>
              <p className="p-in-sp">Unless some gentleman specially desires the communication to be read, I move that the reading of it be dispensed with, and that it be laid upon the table and printed, so as to give members an opportunity of reading it at their leisure.</p>
              <div className="speaker" id="sp2521"><span className="persName">Mr. JACKSON</span>—</div>
              <p className="p-in-sp">I call for the reading of the communication.</p>
              <p>As the SECRETARY proceeded further with the reading</p>
              <p><span className="persName">Mr. WISE</span> moved that the reading be dispensed with.</p>
              <div className="speaker" id="sp2522"><span className="persName">Mr. JACKSON</span>—</div>
              <p className="p-in-sp">I think that this communication is as instructing as the Constitution of the Confederate States which was read the other day. I desire to have it read.</p>
              <PageNumber num={304} />
              <p>The Secretary was proceeding again with the reading, when—</p>
              <div className="speaker" id="sp2523"><span className="persName">Mr. JACKSON</span> said :</div>
              <p className="p-in-sp">For reasons that did not exist before, but which now exist, I move that the further reading of the communication be dispensed with, and that it be laid upon the table and printed.</p>
              <p>The motion was agreed to.</p>
              <div className="speaker" id="sp2524"><span className="persName">Mr. SPEED</span>—</div>
              <p className="p-in-sp">I offer the following resolution :</p>
              <p>Resolved, That the order requiring the Convention to resolve itself into Committee of the Whole at half past ten o'clock, be suspended for this day, to complete the unfinished business under consideration when the Convention adjourned on Saturday last.</p>
              <div className="speaker" id="sp2525"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">If the resolution is objected to, can it be received?</p>
              <div className="speaker" id="sp2526"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair is not aware of any rule that prevents the reception of that resolution.</p>
              <div className="speaker" id="sp2527"><span className="persName">Mr. SPEED</span>—</div>
              <p className="p-in-sp">My reason for offering the resolution is, that if we are to adopt the resolution of the gentleman from Montgomery [<span className="persName">Mr. PRESTON</span>] , it is important that it should be adopted at once, so that the information which is sought under it may be given to the Convention at as early a day as possible.</p>
              <div className="speaker" id="sp2528"><span className="persName">Mr. JACKSON</span>—</div>
              <p className="p-in-sp">I desire to be informed as to the order of business. After this resolution is disposed of, will not the business then in order be the customary morning business—the resolutions of the gentleman from Monongalia [<span className="persName">Mr. WILLEY</span>] ?</p>
              <div className="speaker" id="sp2529"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">No, sir. The business in order will be the resolutions offered by the gentleman from Montgomery [<span className="persName">Mr. PRESTON</span>] .</p>
              <div className="speaker" id="sp2530"><span className="persName">Mr. JACKSON</span>—</div>
              <p className="p-in-sp">I defer to the decision of the President, whilst I must confess to some doubts as to its entire correctness.</p>
              <p className="p-in-sp">
                I think, sir, it is inexpedient to depart from the important business that brought us here to take up a resolution that is, to say the least of it, of very doubtful character. I think, without meaning to reflect upon the gentleman who offered the resolutions—that he should know that the great business of this Convention is to proceed regularly and orderly to the accomplishment of the
                work in which we have been so long engaged, and which it is our duty to dispose of at once. I will say, sir, without meaning any disrespect to the mover of these resolutions, that they are a sort of side bar—a bomb thrown into this Convention, which is calculated in my opinion to create distraction and confusion in this body; and I therefore move to lay the resolution of the
                gentleman from Campbell
                [<span className="persName">Mr. SPEED</span>] , on the table, and upon that motion I call the yeas and nays.</p>
              <div className="speaker" id="sp2531"><span className="persName">Mr. STAPLES</span>—</div>
              <p className="p-in-sp">Is it in order to call the previous question upon this motion?</p>
              <PageNumber num={305} />
              <div className="speaker" id="sp2532"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The motion is to lay on the table, and is not debatable. The gentleman's object being to stop debate, the motion made will accomplish that object.</p>
              <div className="speaker" id="sp2533"><span className="persName">Mr. SCOTT</span>, of Powhatan—</div>
              <p className="p-in-sp">I rise to a question of order.</p>
              <p className="p-in-sp">On Saturday evening, if I understood rightly, the question before the Convention was whether the main question should be put, on which ten minutes speeches were allowed. If that is the question before the House, is it in order to introduce a separate resolution from that which is under consideration?</p>
              <div className="speaker" id="sp2534"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">No objection being made to the resolution when it was offered, the Chair thinks it is in order for the Convention now to consider it.</p>
              <div className="speaker" id="sp2535"><span className="persName">Mr. WILSON</span>—</div>
              <p className="p-in-sp">I will ask my friend from the county of Wood [<span className="persName">Mr. JACKSON</span>] , to withdraw his motion for a moment, to enable me to offer an amendment.</p>
              <div className="speaker" id="sp2536"><span className="persName">Mr. JACKSON</span>—</div>
              <p className="p-in-sp">I cannot consent to withdraw my motion. I prefer to look straight ahead.</p>
              <p>
                The roll was then called, and resulted, yeas 64, nays 64, as follows :<span className="note" id="Note38"><span className="noteLabel">2</span>The Journal adds the name of Brent to the affirmative votes listed here. The Journal is presumably correct in listing P. C. Johnston in the affirmative votes, rather than M. Johnson, since the latter was paired with Mr. Thornton.</span>
              </p>
              <p>YEAS-Messrs. Janney [President]
                , Armstrong, Aston, Baldwin, A. M. Barbour, Baylor, Berlin, Blow, Boggess, Brown, Burdett, Burley, Byrne, Campbell, Carlile, Carter, Clemens, C. B. Conrad, R. Y. Conrad, ()ouch, Critcher, Custis, Dent, Dorman, Dulany, Early, French, Fugate, Gillespie, Gravely, Gray, Goggin, Addison Hall, E. B. Hall; Haymond, Hoge, Hubbard, Hughes, Jackson, M. Johnson, McComas, McGrew, Macfarland,
                Marshall, Masters, Moffett, Moore, Osburn, Patrick, Porter, Price, Pugh, Rives, Wm. C. Scott, Sharp, Spurlock, A. H. H. Stuart, C. J. Stuart, Summers, Tarr, White, Wickham, Willey-64.
              </p>
              <p>
                NAYS-Messrs. Ambler, James Barbour, Boisseau, Borst, Bouldin, Bruce, Cabell, Caperton, Cecil, Chambliss, Chapman, Coffman, Conn, R. H. Cox, Deskins, Echols, Fisher, Flournoy, Garland, Graham, Gregory, John Goode, Jr., Hale, Cyrus Hall, L. S. Hall, Hammond, Holcombe, Holladay, Hunton, Isbell, Kent, Kilby, Kindred, Lawson, Leake, Charles K. Mallory, James B. Mallory, Marr, Miller,
                Montague, Morris, Morton, Neblett, Orrick, Parks, Preston, Randolph, Richardson, R. E. Scott, Seawell, Sheffey, Slaughter, Southall, Speed, Staples, Strange, Sutherlin, Tayloe, Tredway, Tyler, Waller, Wilson, Wise, Wysor-64.
              </p>
              <p>So the motion was lost.</p>
              <p><span className="persName">Mr. AMBLER</span>, when <span className="persName">Mr. HARVIE</span>'S name was called, stated that <span className="persName">Mr. HARVIE</span> had paired off with the delegate from Rockingham [<span className="persName">Mr. COFFMAN</span>] .</p>
              <p><span className="persName">Mr. NELSON</span> paired off with <span className="persName">Mr. WOODS</span>, of Jackson.</p>
              <p><span className="persName">Mr. THORNTON</span> paired off with <span className="persName">Mr. JOHNSON</span>, of Richmond. <span className="persName">Mr. WHITEFIELD</span> was absent from indisposition.</p>
              <PageNumber num={306} />
              <p><span className="persName">Mr. BRANCH</span> paired off with <span className="persName">Mr. SITLINGTON</span>. He would otherwise have voted no.</p>
              <p><span className="persName">Mr. WYSOR</span> stated that <span className="persName">Mr. BLAKEY</span> paired off with another gentleman.</p>
              <p>The question then recurred upon the adoption of the resolution offered by <span className="persName">Mr. SPEED</span>.</p>
              <div className="speaker" id="sp2537"><span className="persName">Mr. JACKSON</span>—</div>
              <p className="p-in-sp">The hour has arrived for the Convention to go into Committee of the Whole.</p>
              <div className="speaker" id="sp2538"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">I move to suspend the rule requiring the Convention to go into Committee of the Whole.</p>
              <div className="speaker" id="sp2539"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair will take the question upon the resolution, but it cannot entertain debate upon it.</p>
              <div className="speaker" id="sp2540"><span className="persName">Mr. JACKSON</span>—</div>
              <p className="p-in-sp">I call for the yeas and nays upon the resolution.</p>
              <p>
                The call was sustained, and the question having been taken resulted —yeas 67; nays 64, as follows :<span className="note" id="Note39"><span className="noteLabel">3</span>The Journal adds Baldwin to the negative votes listed here.</span>
              </p>
              <p>
                YEAS-Messrs. Ambler, James Barbour, Blakey, Boisseau, Borst, Bouldin, Branch, Bruce, Cabell, Caperton, Cecil, Chambliss, Chapman, Coffman, Conn, R. H. Cox, Deskins, Dorman, Echols, Fisher, Flournoy, Garland, Graham, Gregory, Goggin, Jno. Goode, Hale, Cyrus Hall, L. S. Hall, Hammond, Holcombe, Hunton, Isbell, Kent, Kindred, Lawson, Leake, C. K. Mallory, J. B. Mallory, Marr, Miller,
                Montague, Morris, Morton, Neblett, Orrick, Parks, Preston, Randolph, Richardson, R. E. Scott, Seawell, Sheffey, Sitlington, Slaughter, Southall, Speed, Staples, Strange, Sutherlin, Tayloe, Tredway, Tyler, Waller, Wilson, Wise, Wysor-67.
              </p>
              <p>NAYS-Messrs. Janney [President]
                , Armstrong, Aston, A. M. Barbour, Baylor, Berlin, Blow, Boggess, Brent, Brown, Burdett, Burley, Byrne, Campbell, Carlile, Carter, Clemens, C. B. Conrad, R. Y. Conrad, Couch, Critcher, Custis, Dent, Dulany, Early, French, Fugate, Gillespie, Gravely, Gray, Addison Hall, Ephraim B. Hall, Haymond, Hoge, Holladay, Hubbard, Hughes, Jackson, P. C. Johnston, McComas, McGrew, Macfarland,
                Marshall, Masters, Moffett, Moore, Osburn, Patrick, Pendleton, Porter, Price, Pugh, Rives, Wm. C. Scott, Sharp, Spurlock, A. H. H. Stuart, C. J. Stuart, Summers, Tarr, White, Wickham, Willey-64.
              </p>
              <p>So the resolution was adopted.</p>
              <div className="speaker" id="sp2541"><span className="persName">Mr. GOGGIN</span>—</div>
              <p className="p-in-sp">When his name was called, said he was willing his friends should have an opportunity of disposing of the resolution now [that of <span className="persName">Mr. PRESTON</span>] offered on Saturday last; he would, therefore, though opposed to the adoption of that resolution, vote in the affirmative on the resolution of the gentleman from Campbell [<span className="persName">Mr. SPEED</span>] , now before the Convention.</p>
              <div className="speaker" id="sp2542"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I rise to a privileged question. In behalf of the people at the door, I offer, for the adoption of the Convention, a resolution, that the door keepers be permitted to pass into the Hall such a number <PageNumber num={307} /> of orderly persons as can conveniently fill the space which we have at both ends of the Hall.</p>
              <p>The resolution was then read as follows :</p>
              <p>"Resolved, That the Door-keepers be permitted to pass into the Hall such number of orderly persons as to fill without excessively crowding the lobby; and when the lobby is filled to pass in no more persons."</p>
              <p>The resolution was then adopted—ayes 59, noes 44.</p>
              <div className="speaker" id="sp2543"><span className="persName">Mr. HULL</span>—</div>
              <p className="p-in-sp">
                Before proceeding to take up the business first in order, I ask the Convention to consider a resolution which I have prepared, and which will not occupy five minutes. It is, that the Superintendent of the Public Buildings shall take charge of the furniture which was left at the Mechanics' Institute. In all possibility the furniture will be greatly damaged unless some such action as I
                propose, is taken.
              </p>
              <div className="speaker" id="sp2544"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">Instructions were given by the Chair to the Sergeant-at-arms to attend particularly to that duty, as it was a duty that belonged to him, and he promised to attend to it.</p>
              <div className="speaker" id="sp2545"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">I understand from the Sergeant-at-arms that he has performed that duty.</p>
              <div className="speaker" id="sp2546"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The pending question is—shall the main question now be put upon the resolution offered by the gentleman from Montgomery [<span className="persName">Mr. PRESTON</span>] , as amended.</p>
              <div className="speaker" id="sp2547"><span className="persName">Mr. STUART</span>, of Augusta—</div>
              <p className="p-in-sp">
                I desire to express the hope that it will not be the pleasure of the Convention to order the previous question, and, as a reason for it, I wish to say, if the previous question should not be ordered, I will present an amendment. While I appreciate the patriotic spirit of the gentleman who moved the resolution, and cordially concur with him in the object which he has in view, yet, in
                my judgment, in the form in which this resolution has been presented, it is calculated to do harm, rather than good. It proceeds upon the idea of the right of this Convention to make a call upon the Executive of the United States for a disclosure of his policy. I think that there is no such right upon the part of this Convention to make such a call. It is true that the government of
                the United States is our government—that it is in part our government. It is the government not only of Virginia, however, but of twenty-six other States, excluding those States which have already seceded. All have equal rights in this government, and have an equal right to make a demand upon the Executive of the United States for a disclosure of his policy. It strikes me that it
                will be establishing a dangerous precedent to affirm that any one State has a right, either through its Convention or through <PageNumber num={308} /> its Legislature, to make a call upon the Executive of the United States for a disclosure of his policy in reference to any particular question which may be pending. I think I may safely appeal to the
                veteran gentleman
                [ex-President TYLER]
                , who sits before me, and who has filled the high position of the Chief Executive officer of the United States—to bear me witness that, at the time negotiations in regard to the admission of Texas were pending, if any one of the Northern States had undertaken to call upon him to make known to them and to the public, what his policy was to be upon that subject, he would have promptly
                declined to furnish such information.
              </p>
              <p className="p-in-sp">
                Now, sir, if it be right of Virginia to make a demand upon this occasion, you establish a precedent for all future cases, and you prevent the United States Executive from fulfilling its appropriate functions by withdrawing the veil of secrecy at times when it is essential to the success of any negotiations which may be pending. I think it is peculiarly important, in view of the
                relation which this Convention bears to the present Executive, that we should be careful not to place the great Commonwealth of Virginia in a false position. The Executive, sir, is not the President of our choice, and hence we ought to approach him with all the forms which are proper under such circumstances. We ought to observe all that etiquette which is appropriate to govern the
                relations between two governments. I am anxious to obtain the information, and will cheerfully vote for a resolution which will accomplish all the purposes which the gentleman from Montgomery
                [<span className="persName">Mr. PRESTON</span>] , has in view, but I am not willing to vote for the resolution in the form which he presented it; and, by way of argument against the call for the previous question, I beg leave to submit to the Convention an amendment which I propose to offer in the event of a refusal to order the previous question :</p>
              <p className="p-in-sp">
                "Whereas, The apprehension which now exists in the public mind, that the Executive of the United States may be impelled to pursue a coercive policy toward the seven States which have adopted ordinances of secession, has tended to create an unhappy excitement among the people, and to affect, injuriously, the commercial and industrial interests of the whole country; and whereas, in the
                judgment of this Convention, such a policy would be, in a high degree, unwise and inexpedient. Be it therefore
              </p>
              <p className="p-in-sp">
                "Resolved, That the President of this Convention be requested to make known to the President of the United States, in such form as he may deem most advisable, the firm conviction of this body, that a pacific policy towards the seceded States is demanded by every consideration <PageNumber num={309} /> of public interest; and to express to the President
                of the United States the earnest desire of this Convention, that he will seize an early opportunity to assure the public of his determination to adopt such a policy."
              </p>
              <p className="p-in-sp">If the Convention refuse to sustain the call for the previous question, I will submit that proposition, which I have no doubt will be productive of all the results contemplated by the gentleman from Montgomery , [<span className="persName">Mr. PRESTON</span>] .</p>
              <div className="speaker" id="sp2548"><span className="persName">Ex-President TYLER</span>—</div>
              <p className="p-in-sp">In answer to the request made upon me by the honorable gentleman from Augusta [<span className="persName">Mr. STUART</span>]
                , I have only to say, that in negotiations pending between the United States and a foreign Government, it is undeniably true that a President of the United States may have very strong reasons for not communicating to Congress the actual state of such negotiations. To do so might even lead to a disastrous termination of the negotiation itself. During my own time I remember many
                instances in which I felt constrained, pending a negotiation, to withhold information called for by Congress. Such calls are always couched in respectful language, and whether proceeding from the Senate, or House of Representatives, are always qualified by the declaration "if not incompatible with the public interests," or words of equivalent import. Such calls were more than once
                made, by one or other of the Houses, during the negotiation of the Ashburton Treaty.
              </p>
              <p className="p-in-sp">
                In regard to the question which is immediately before us, there may be a different view taken. That we have the right to make a request of the President to be informed of his intended course of policy, I do not entertain the slighest doubt. Virginia is one of his constituents and you are emphatically her representatives. Whether Virginia voted for him or not—it is a matter of no
                consequence—none in the world. She is as much one of his constituents as if she had voted for him. He is the President of the United States—the representative of each individual State and of all the States. The States are parties to the social compact of the United States.
              </p>
              <p className="p-in-sp">
                They have originated the government, have ratified the Constitution, and through their electoral colleges the President is elected. The Government of Virginia has the people of Virginia for its constituent body, while the Government of the United States has its constituent body composed of States. Thus it often happens that the President is elected by a large electoral vote, while a
                decided majority vote of the people has been polled against him. Such has been especially the case with Mr. Lincoln. No member of this body would <PageNumber num={310} /> pretend to deny to any one of his constituents the right to make an application to him for information, and no member here would resist the application thus made, unless, indeed, there
                was a question of State policy so important as to require the Convention to meet in secret session for deliberation. The same rule applies to a State, as a constituent of the Federal Executive.
              </p>
              <p className="p-in-sp">
                Now, sir, I am not going into the question whether the President will answer your inquiries or not. He will, undoubtedly, if you approach him respectfully, respond to you respectfully. If, for instance, he has reasons for not disclosing the whole line of his policy, he will tell you that he is constrained in that behalf by considerations of public good, and, therefore, he will only
                make you such revelations as he thinks himself justified in making in view of what the public interests, in his opinion, may require. To that extent Mr. Lincoln will go. That you have the right to make the application of him there can exist no doubt. That he ought to answer, is my deliberate opinion ; whether he will nor not, is a question for him to determine. In the present
                emergency of the country, he ought to answer. He ought to answer plainly and emphatically as to his course of policy touching the Confederate States—the forts and arsenals. Every thing depends upon it—the peace of the country—its commercial, manufacturing and agricultural interests. Whether we are to have peace or war ought to be made known by every consideration of private and
                public interest—the whole country is interested in that answer. It must be a strong matter of State policy which shall close his lips and result in a refusal to answer you fully, broadly and satisfactorily upon the whole subject.
              </p>
              <p className="p-in-sp">In regard to the question put by the honorable gentleman from Augusta [<span className="persName">Mr. STUART</span>]
                I repeat with very great pleasure and promptitude, that in negotiations with foreign powers, the President is always shielded against an application in relation to them, by the acknowledged propriety of his retaining to himself so much of the secret of his negotiation as will enable him to conduct that negotiation to a successful issue. In this matter, however, I think that the
                application to Mr. Lincoln to make public his opinions and his course of policy are much more pressing and more important than upon any ordinary occasion.
              </p>
              <p className="p-in-sp">
                Sir, pardon me for one more remark. If it be the design of Mr. Lincoln to reclaim these cotton States by force, there is the absence of a great step in his career. It should be preceded by a proclamation. To strike down a people without giving them notice that you were obliged by the estimate placed on your duties to level at their hearts the dagger or the sword, would be in the
                greatest degree an act of <PageNumber num={311} /> impolicy, inhumanity, and cruelty. Sir, when the whiskey insurrection broke out in Pennsylvania, what was the course of that God-like man whose memory we all so much idolize—what, I ask, was the course of Gen. Washington? When that insurrection, not of a State, but an insurrection on the part of the
                whiskey boys as they were called, occurred, what was the course of policy pursued? Gen. Washington issued a proclamation forewarning them of the consequences of their conduct. He demanded that they should retire from their purpose—that they should abandon their insurrectionary move. Do we not all know the effect of that proclamation? It is true that he had to muster troops, but
                before those troops had reached Pittsburg resistance to the laws had been abandoned.
              </p>
              <p className="p-in-sp">
                Coming down to a still later period, let me advert to the action of the Executive, in connection with the nullification of South Carolina. Why, Gen. Jackson never thought for a moment of inaugurating an era of blood, before he had issued his celebrated proclamation; he proclaimed his policy to the States and the people, according to his interpretation of the Constitution and the
                Executive duty. Why shall not Mr. Lincoln do this? Why go about sneakingly, as represented by the newspapers? Why dodge in this corner, and hide himself in that? Is the President of the United States, with all the power of the government in his hands, according to his interpretation of his rights and powers, driven to such an alternative, that he is obliged to shut himself up in his
                closet and not let the people know what is his purpose in regard to them—one day to feed them with smiles, and the next to threaten them with the sword? I wish this state of things could be ended. I wish with all my heart and soul, that he would come out openly and boldly, and let the country know what he means to do.
              </p>
              <p className="p-in-sp">
                Sir, the declaration of letter writers copied into the "Enquirer" of this morning is that the greater portion of these forces, now collected at New York, are designed for another region—to guard us against the inroads of the Mexicans and of the Indians upon our Western border. Can it be false? Can a man at the head of the Government tolerate, for one moment, the idea of deceiving the
                people of the United States by false rumors? Why not speak out openly? If you mean to take the forts, say so. If you do not mean to take the forts, say so. Let us be at peace. Let us understand our relative positions to each other. I think that is his plain and obvious course. But I am far from undertaking to dictate any course of policy to the President of the United States. Whether
                his course be right, or whether it be <PageNumber num={312} /> wrong, it will, when developed by words or acts, be our duty to decide.
              </p>
              <div className="speaker" id="sp2549"><span className="persName">Mr. CONRAD</span>, of Frederick—</div>
              <p className="p-in-sp">I intend to vote for the resolution offered by the gentleman from Montgomery [<span className="persName">Mr. PRESTON</span>]
                , in some form, because I expect good results to come from the movement. From all that has occurred since this Convention assembled, and from a very careful and anxious examination of all the facts which have appeared before the public, I do not believe that any coercive policy, any policy which would be deprecated by this Convention, is intended to be pursued on the part of the
                Federal Executive. Such, sir, has been my hopeful conclusion from all the facts. And I should expect that in response to the request of this Convention, we should have, will have, from the President of the United States a satisfactory and soothing answer. Therefore it is, sir, that I heartily concur in the movement which has been instituted by my friend from Montgomery. But for the
                very reason that I do expect great good to result from this movement, in calming the agitation of the public mind, in removing that which, as the gentleman has well said, is worse than almost any condition of certainty—an anxiety of suspense and imaginary apprehension—I am the more anxious, that when we approach the Federal Executive, we shall do it in some form where there will be
                less likely to arise any obstruction of mere etiquette. Reflecting, sir, upon this subject, and especially the idea thrown out by the gentleman who has just addressed the Chair—that, preceding any attempt at coercion, the President of the United States would be bound by every consideration, to give fair public proclamation of such purpose—for these considerations, sir, I am, as I
                said, the more anxious that we shall approach him in such form as will secure a direct answer. I have my doubts whether it is proper to address him in the form of interrogatory. I am utterly ignorant upon these subjects, and I would avoid that, as some suggestion has been made that that would be an improper mode of approach. And I propose, in order to attain the main object, to offer
                another resolution, if the Convention will refuse to direct the previous question to be put. I concurred entirely with the substitute offered by the gentleman from Augusta
                [<span className="persName">Mr. STUART</span>]
                , except in one single feature. I do not think the subject can be satisfactorily managed by a correspondence between the President of this body and the President of the United States. I think it would be most respectful, and most probably effectual, to adopt the original form proposed by the gentleman from Montgomery, and send a Committee of this body to ascertain the fact which is
                so important to be ascertained.
              </p>
              <PageNumber num={313} />
              <p className="p-in-sp">I will read, by way of argument, the resolution which I will present, if an opportunity is given to do so :</p>
              <p className="p-in-sp">
                "Resolved, That a Committee of three members be appointed by the President of this Convention to proceed at once to the city of Washington, wait upon the President of the United States and respectfully communicate to him the anxious desire of this Convention that a pacific policy will be pursued toward the seceded States, and that such a policy will be announced by him as soon as may
                be expedient, in order to quiet the public mind."
              </p>
              <div className="speaker" id="sp2550"><span className="persName">Mr. SCOTT</span>, of Fauquier—</div>
              <p className="p-in-sp">
                Mr. President, I think, sir, that in the consideration of the question involving the interests which the question now before this body does involve, we should not be embarrassed in the interchange of our views by the demand for the previous question. I think, sir, the time of this body could not be more profitably employed than in a free interchange of views, on the part of the
                members of this Convention on this interesting question. I take occasion to say that I was among those with whom the gentleman from Montgomery consulted previous to the introduction of his resolution, and that I heartily concurred in the object which that gentleman had in view. I trust that it will be the pleasure of this Convention to address the President in some proper form for
                the purpose of acquiring the information which this resolution proposes. I cannot conceive that the President of the United States will hesitate, if approached in respectful terms, to respond freely and frankly to the enquiries. But, sir, whatever may be the disposition of that high functionary, I am among those who desire to obtain information upon this question with a view to the
                government of my own action. We have adopted a resolution, Mr. President, declaring in emphatic terms that the State of Virginia will never consent to be made a party to an unnatural war upon her sister Southern States. We adopted that resolution, I believe, with some degree of unanimity. Now, sir, I trust that that resolution was not intended to be mere words without any specific
                meaning. I trust that something more was intended by it; that it was intended to declare emphatically the unalterable determination of this body, so far as its powers extend, that under no circumstances will an aggressive policy, on the part of the Federal Government, against these Confederated States, be tolerated by Virginia. Sir, I desire the information, as I have said, with a
                view to govern my own action as a member of this body, and I am free to say, that the moment when it is disclosed to me that the Federal Government meditates aggressive war upon these Confederate <PageNumber num={314} /> States, for one I am ready to vote upon the instant for an ordinance of secession.
              </p>
              <div className="stage it">[Applause.]</div>
              <div className="speaker" id="sp2551"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The lobbies will be instantly cleared if such disorder is repeated.</p>
              <div className="speaker" id="sp2552"><span className="persName">Mr. STAPLES</span>—</div>
              <p className="p-in-sp">The disorder, sir, I think, was on the floor of the House.</p>
              <div className="speaker" id="sp2553"><span className="persName">Mr. SCOTT</span>, of Fauquier, resumed—</div>
              <p className="p-in-sp">I beg leave, Mr. President, to say that I concur entirely with the opinion of the gentleman from Frederick [<span className="persName">Mr. CONRAD</span>]
                . I beg leave to say that I concur in the opinion that the Federal authorities do not meditate aggressive war. I have seen no indication given yet, to the public, sufficient to induce such a belief. On the contrary, as I understand the indications, as I interpret them, I have come to the conclusion that the policy of the Administration is to be a peace policy; and I shall be slow to
                change that opinion ; I shall change it upon nothing short of that direct and positive evidence which will exclude the possibility of a doubt. It has been suggested that the President will consider the resolution of the gentleman from Montgomery as offensive. I can not perceive, sir, how such an interpretation can be put upon the preamble and resolution. I see nothing in them but
                respectful language. I would not address the President, or any other functionary, except in the language of respect. But, sir, so far from entertaining the objection which other gentlemen have thrown out against the matter of this preamble and resolution, I myself would be willing to go further and employ even stronger terms. I would be content, Mr. President, to employ terms
                expressive of our settled determination to the President, and to let him know, in no spirit of threat or of menace, but calmly and firmly, that upon the policy of the Administration in respect to these Confederate States depends the action of this Convention.
              </p>
              <div className="speaker" id="sp2554"><span className="persName">Mr. RANDOLPH</span>, of Richmond—</div>
              <p className="p-in-sp">Mr. President, there is one view of the resolution offered by the gentleman from Montgomery [<span className="persName">Mr. PRESTON</span>]
                , which I desire to present to the Convention, and which in my judgment renders it indispensable not only to the slave States, but to the honor of this Commonwealth that this inquiry should be propounded. We all know, Mr. President, that there is a large military expedition fitting out in the Northern ports. We have adopted a resolution saying that the Federal power, being in part
                the power of this Commonwealth, should not be employed to subjugate the Confederate States. Now, sir, have we not a right to know if that military expedition, raised in part by our resources, manned in part by our citizens, is to be employed against the Spaniards or against the Southern <PageNumber num={315} /> States? Will it be disrespectful, will it
                be impolitic, for this Convention, respectfully to ask the President of the United States whether he has ordered Virginia to engage in military operations against the Confederate States? We know, sir, without being officially informed, that there are many Virginians in the military posts, now employed in and about the Confederate States. We know that some of the best officers we have
                are from this State. We know that the engineer officer in Fort Sumter is a Virginian. We know that the first Lieutenant of the first ship that was sent with troops to the South—the gentleman whose duty it would have been to bring that ship into action
              </p>
              <p className="p-in-sp">
                is a Virginian, a native of this city. We know that many of those officers are opposed to the service, and that many are refrained from tendering their resignation, because many have families depending upon their pay for their support. Now, is it not right that we should know whether our sons are to engage in this strife, if strife there be; whether Virginia treasure is to contribute
                to the raising of an army to subjugate those States; and whether Virginia is to contribute her blood, on the other side of that bloody battle?
              </p>
              <p className="p-in-sp">
                Can the President of the United States object to telling us that? Is there any analogy between that question and the question of an inquiry into the foreign policy of the Government, and does the President recognize his relations to the Confederated States as foreign relations? Are you not to know whether the South is to be subjected or conciliated—whether we are to fight on one side
                or the other in this quarrel? Are we to be led blindfold into a war, the end of which no man can foresee or predict? Are we not to be permitted to inquire of one, our Chief Magistrate, whether he intends to use our blood and treasure in the subjugation of those States, by war, or whether he means to treat peacefully with them?
              </p>
              <p className="p-in-sp">Why, Mr. President, do gentlemen recollect that there are numerous officers now employed in your army and Navy who are natives of this State? My venerable friend from Charles City [<span className="persName">Mr. TYLER</span>]
                will remember an incident which occurred some few years ago which illustrates the extent to which Virginians are engaged in the military service of the United States. Some two or three years ago, being accidentally at Old Point Comfort, and being about to receive the honors due to him as an ex-President of the United States, he did me the honor to invite me to go with him to the
                fortress. We went together to the fortress which was at that time garrisoned, if I mistake not, by a company of the 3d Regiment of Artillery commanded by an officer from New England, by the name of Gardiner. After the usual <PageNumber num={316} /> ceremonies of the occasion were over, we were invited to the house of the commanding officer, and the
                conversation turning upon the service of that regiment in Mexico, he mentioned an occurrence which he said ought to be known by Virginians, inasmuch as it was highly honorable to the State. He informed us that being in command of six companies of that regiment, and having been called on to detail from his command twenty-four men to perform a part of the forlorn hope in storming the
                Castle of Chapultepec, he informed his men of the dangerous nature of the service to be performed, that in all probability some of them would never return, and then called for volunteers, who promptly presented themselves. He afterwards took occasion to ascertain something in reference to the history of the men who had volunteered, and he ascertained, that of those twenty-four men
                from a regiment recruited from every portion of the United States, and commanded by Northern officers, eighteen were native born Virginians. I take pleasure in announcing the fact, that it may go upon the record and stand as an honorable circumstance in the history of the State.
              </p>
              <p className="p-in-sp">
                Now, sir, we know that there are numerous native born Virginians in all branches of the military and naval service of the United States. It is a service which the people of this State have all favored and liked. We know that there are men—Virginians--connected with the expedition which is being fitted out in the port of New York, who have no mcans of disconnecting themselves from
                that expedition. Sir, it is not kind to them; it is not kind to their State to require them to go upon such an expedition, if it is to be directed against the Government upon our Southern border. Let us go, then, with all respect, for I should be the last man to treat the incumbent of the Presidential Chair, no matter who he may be, with the slightest disrespect; let us go, then,
                with all respect, and I confess I do not understand how a resolution could be framed more respectful in terms than the one before us, and ask this President of ours to state plainly whether he is going to use force against the Confederate States, or whether he is going to treat with them; and let us tell him, in respectful terms, but plainly, that if it is his determination to use
                force, if it is his determination to inaugurate war, the sovereign Convention of Virginia declare to him, that in such a war the State of Virginia will take no part—at least upon the part of the United States—and ask him to relieve from service all the officers of the army and the navy who are natives of Virginia, and who have received orders to march against the Confederated States.
                I trust the members of this Convention will <PageNumber num={317} /> bear in mind that they have received communication after communication, application after application, to take action in reference to this matter. I myself have received communications from officers connected with that branch of the service to which I once belonged, announcing boldly
                that they will take no part in any war that may be inaugurated against the South; that they do not know what to do. If they remain in the service they may at any time be ordered to march against the South, while, if they resign, they may be beggared. I say, then, let this Committee not only request the President to inform us what is his intention towards the Southern Confederacy, but
                let them further request while, negotiations are pending, that all officers of the army and navy who are citizens of Virginia shall be relieved from any service in the region of the Confederated States. That is what I desire; and I desire, further, that in order to present the inquiry in a form more imposing and in such a manner as shall entitle it to yet more of consideration, that
                it shall be presented by a Committee of this body in person, and not by the mere transmission of a communication in writing. It is time that all uncertainty should cease, that this Commonwealth should know what the policy of this government is to be in regard to the new nation upon our Southern border.
              </p>
              <div className="speaker" id="sp2555"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                I did not want to, say anything about this question. I was disposed, from various considerations, to leave this question in the hands of its friends or of its opponents. But the debate has taken a turn which I think brings the subject calmly and considerately before us; and in that view of calm consideration, I wish to say a few words as to the character of the inquiries that should
                be addressed to the President of the United States. You are in a state—as was well remarked by the gentleman from Frederick
                [<span className="persName">Mr. CONRAD</span>] , and by the gentleman from Fauquier [<span className="persName">Mr. SCOTT</span>]
                ofanxious dubiety. I am written to from every section and quarter, where I have acquaintances and friends, and by many strangers, to know what is to be the result, what we are to expect. Is the danger on the right or the left? or, what danger is it that is to be expected, which seems to threaten us on every side? I can tell nobody; and I presume there is not a representative in this
                body who is not in the same condition with myself. He knows not what reply to make. We see that the sword of war is hanging over us by a thread, and although we know that it is impending over us, we know not whether the decree of fate is that it shall fall or not. It is not information sought in respect to a foreign war; it is in respect to the imminency of a civil war, that our
                people are kept in this painful suspense. Are we to be kept in this state of <PageNumber num={318} /> suspense by public servants who hold the questions of peace and war in their hands? Unquestionably not. Sir, besides the personal interests that are involved; besides the lives; besides the blood; besides the misery of horrid civil war, there are
                important local questions, and national questions—local and national to us as well as to the seceded States—that point to this view and this policy; let alone the important personal interests that are involved, and which have been alluded to by my friend from Richmond
                [<span className="persName">Mr. RANDOLPH</span>] .</p>
              <p className="p-in-sp">Now, sir, as to the ground which these questions cover. Every sentiment uttered by the gentleman from Fauquier [<span className="persName">Mr. SCOTT</span>]
                I concur in. I concur in the opinion that we ought not to tolerate an aggressive or a coercive policy; but the question arises, what is aggressive? What will be a coercive policy? The President may 17 asked by you or by a Committee. "What is your intention? Do you intend coercion or aggression?" He would answer "no" to that general question, and yet not answer truly and fairly all
                the time. Why? How? I will give you an illustration, one of apprehension in my own mind. I believe the fort that is within the limits of South Carolina, Fort Sumter, is about to be evacuated. Why? Because it is a fort that may be called intramural, within the walls of the State. But I should like to ask the President of the United States, whether the same policy will be pursued in
                respect to the Tortugas, in respect to the Barrancas, and in respect to Fort Pickens. Is there not clearly defined, a disposition to pursue a different line of policy between the forts, in reference to which questions about mare clausum may be raised in connection with the forts, guarding the entrance of the Gulf and the mouth of the Mississippi as compared to the intra mural
                condition of Fort Sumter. While he will evacuate Fort Sumter, for example, he will still occupy Tortugas, Fort Barrancas and Fort Pickens. But, if the President answers the question at all, he will probably say—"I will give up one, because it is a local fortress; but I shall occupy the others because they are co-national fortifications." What does he mean by co-national
                fortifications? One that guards the navigation of the Gulf Of Mexico, one that guards the free navigation of the Mississippi river. Then comes up the question, is not Tortugas within the limits of Florida, which has seceded as much as Sumter is within the limits of South Carolina? It undoubtedly is. Suppose he replies—"we regard this, sir, as outside the shores of Florida, one that
                is not within the limits of the marine league." All the questions that may be raised then about mare clausum—all the questions that may arise about the marine league—of islands as contra-distinguished from points within <PageNumber num={319} /> the main territory of a State—all these questions may arise, and these questions may bring on a conflict just
                as well as the occupation of Fort Sumter. Yet he may say to you—"While I would regard the occupation of Fort Sumter as aggressive and coercive, I would not regard the occupation of Tortugas and Barrancas as aggressive." Why not? "Because, even if I considered the States connected with the Southern Confederacy as permanently separated from the Government of the United States, we shall
                claim an interest in Tortugas, although we shall not claim an interest in Sumter." May not that involve war? Although his intentions may not be coercive, though he may deny the occupation of Tortugas to be aggressive or coercive, yet, granting the principle that he has the right to occupy Pickens and Tortugas upon the ground that the Gulf is a great mare clausum, upon the ground that
                it is co-national, still, in what attitude does it put Virginia to that very question? You stand with a fort in the very same relation, in that point of view, as Tortugas stands. When you have decided that his policy is aggressive ; when you have decided that it is coercive in retaining these forts, if you ever do so decide; if the time shall ever come—and it will come—when you, too,
                must depart from the Union—the same question will then arise in respect to Fortress Monroe. Why? Because the Susquehannah river rises at the lip of the lakes within the State of New York, flowing through the States of Pennsylvania and Maryland, and emptying into the ocean between Cape Charles and Cape Henry. It would also establish the Chesapeake Bay also, as a mare clausum. You have
                seen me deeply interested, living as I do within the sound of guns of that fort, on learning the fact that those guns had been turned landward. And, sir, I repeat, that the issue here involved, whether the Administration at Washington shall regard as aggressive or not the occupation of Pickens and Tortugas as well as Sumter, is one in which we are directly involved, and one much more
                important than the lives of our officers—involving the mare clausum of the Chesapeake Bay and the question of the co-national right to the mouth of the Susquehannah, of New York, Pennsylvania and Maryland, as well as Virginia, the very question that may bring us into conflict with the National Government, as it is now likely to bring Florida and Alabama into conflict with the Federal
                Government, in respect to the occupation of Tortugas and Fort Pickens.
              </p>
              <p><span className="persName">The PRESIDENT</span> announced the gentleman's time had expired.</p>
              <div className="speaker" id="sp2556"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I hope I shall be allowed to utter a single sentence in addition. [Cries of "leave."]
                I only desire to say that I am for no <PageNumber num={320} /> resolution that will not go further than my friend from Frederick proposes. The gentleman's resolution, as I understand it—only expresses the wish, that the policy of the President shall be peaceful. I desire more. I desire that we should insist upon such information as will enable us to
                judge as well as the President himself, as to the tendency as well as the intent of his policy.
              </p>
              <div className="speaker" id="sp2557"><span className="persName">Mr. RIVES</span>—</div>
              <p className="p-in-sp">Mr. President, I rise to say that I shall vote against any proposition having for its object the sending of a committee to Washington, to make any inquiry from the President of the United States. I shall especially vote against the proposition of my friend from Montgomery [<span className="persName">Mr. PRESTON</span>]
                . That proposition looks to sending a committee to the President, to ask him what line of policy he is going to pursue in regard to the seceded States. If I know anything of the history of the seceded States, they are now represented at Washington by Commissioners. When has Virginia been put in possession of facts showing that those Commissioners have failed to do their duty?
              </p>
              <p className="p-in-sp">
                Members of this Convention avowed, before Lincoln was elected, what he would do. They announced, after his election, what he would do. They predicted, before his inauguration, what he would do. After his inaugural address, they declared what he would do. And now, in self-stultification, they come up here and say they do not know what he is going to do, and want to send somebody to
                ask him.
                [Laughter.] I ask those gentlemen, and I ask the press of Virginia, whether they have not made these declarations?</p>
              <p className="p-in-sp">
                Oh, yes, before the 4th of March you tried to fire the Southern heart, by predicting what Mr. Lincoln would do. And now, that he has taken an oath to perform the duties of President of the United States, and sent forth his inaugural, leaving you to read it and decide upon it for yourselves, you are not satisfied. To get up a new issue by which to fire the Southern heart, a Committee
                is to be sent to him to ask him what line of policy he is going to pursue in regard to the seceded States. And you base your action on what? On the ground that suspense injures the commercial and other interest of the country. The seceded States have produced the state of things complained of, and, instead of demanding of them what they mean, we are to go to the President of the
                United States, and ask him what he means to do. Yes, sir, after telling the people of Virginia what Mr. Lincoln meant to do, you now confess yourselves to be false prophets. Not a public meeting has been held at a Court House or cross-road in this State, at which the people were not told, that when the fourth of March came, their interests would be ruined.
              </p>
              <PageNumber num={321} />
              <p className="p-in-sp">
                But I know what the game here is, and I will tell it right out. There was a vote taken here on the 4th of April. That vote decided an important question, and now it is necessary to raise a new issue. That vote decided that Virginia would not commit the suicidal act of secession. That was the time when the harpoon went into the vital spot of the whale, and it is not remarkable that
                the whale should dive down, and that blood should spout, and that the rolling and dashing waters should now break over him; but I tell you if we do our duty it will be but a little while before his white belly appears on the surface.
                [Laughter.]
                With the attitude of these seceded States before us, with the fact staring us in the face, that the military forts in New Mexico have been seized by the Secessionists, we propose to ask the President what he is going to do. Suppose it shall turn out that the Governor of New Mexico is acting in concert with the seceded States, will New Mexico, too, under this new fangled idea of
                secession secede? If so I reckon they may "get their hemp."
              </p>
              <p className="p-in-sp">
                Look at the condition of affairs in the country; while officers of the General Government are throwing up their commissions, while territorial forts are being delivered up to secessionists, we are going to ask the President what he proposes to do. Has he not a right to shape his own course of policy and make it conform to the circumstances which may present themselves? Suppose the
                seceded States—which are claimed to be independent States—should raise an army and navy and come up here to take fortress Monroe—must he still remain inactive, and let them do it? They have their Commissioners abroad; and if they should make an agreement with France, England or any other power, to unite with them in subjugating the remaining portions of the State, is the President
                still to remain inactive? Is he not bound to pursue that policy which his sense of duty to the country dictates? Shall I go home and tell my constituents that womanish fears had struck me I do not say that they have struck any here—and that I had got so much frightened that I voted to send off two or three gentlemen, as fast as they could speed, to ask Mr. Lincoln what he was going
                to do?
              </p>
              <p className="p-in-sp">
                Sir, my constituents are not so easily alarmed. I do not represent people of that sort. Mr. Lincoln knows the policy of this Convention. It has been openly avowed. There are not ten men here who have not declared their opinion and their determination not to sanction any forcible measures, whether in the collection of the revenue or in the recapture of the forts. The President has,
                perhaps, framed his line of <PageNumber num={322} /> policy on this very action of the Convention. He pursues what is called the inactive, silent kind of policy. Some time ago he was represented as a roaring lion, seeking whom he might devour; but now he has become silent and inactive—these same men wait to send some persons to rouse him up.
              </p>
              <div className="stage it">[Laughter.]</div>
              <div className="speaker" id="sp2558"><span className="persName">Mr. TREDWAY</span>—</div>
              <p className="p-in-sp">
                I do not occupy the position of those gentlemen referred to by the gentleman who has just taken his seat. I am not among those who predicted that after the 4th of March, Lincoln would make war on the seceded States. I am not among those who were in favor of precipitating the action of Virginia, because I believed there was nothing so very alarming immediately in the election of the
                present President of the United States. But I am among those who came here with some apprehension. I could not shut my eyes to the fact that, shortly after Mr. Lincoln's coming into possession of the powers of the government, there might result some forcible action which might bring on civil war. We have been here deliberating for well nigh two months. We have come here, some
                entertaining one opinion in regard to the course which Mr. Lincoln may pursue, and others entertaining a different opinion. We have been all watching the course of events and endeavoring, from the developments that are being made from time to time, to adapt our action to the exigencies that arise. Now at this time we hear, as we have heard before, of preparations for war. The
                information that comes on the telegraph daily is such as to satisfy the public mind of one thing—that there are unusual movements of men and munitions of war; that the Executive is certainly taking some action now which authorizes us to believe that he entertains a purpose either of coercion or defence.
              </p>
              <p className="p-in-sp">We do not stand, in regard to the present Executive, in the same relation as do the Commissioners from the Confederate States. The gentleman who last addressed you [<span className="persName">Mr. RIVES</span>]
                , spoke of those Commissioners being there, and being ineffectually endeavoring to get such information, and said that it would, therefore, be useless for Virginia to send commissioners, inasmuch as the President would refuse to answer them. But I beg leave to submit that our positions are very different. In regard to the Confederate States, there is a grave question involved. The
                President says, that if he confers with those Commissioners at all, he will be thereby acknowledging that they represent an independent nation. It is on a question of constitutional or legal propriety that he refuses to meet those Commissioners who represent States now in opposition to the present Government. When Virginia sends commissioners she will occupy a different attitude. It
                was well <PageNumber num={323} /> remarked by the gentleman from Charles City
                [<span className="persName">Mr. TYLER</span>] , "we are one of the constituents of the Executive. We make no offensive demand of him. We simply ask him, in respectful terms, to indicate to us, who are a part of this Government, and have to bear its burdens and take its responsibilities, what is to be his policy." As was remarked by the gentleman from Augusta [<span className="persName">Mr. STUART</span>]
                , "we do not, in making this request, assert any right which is to be a precedent hereafter." It is only the right of petition. It is only the right of asking information as to a matter in which we are deeply interested, information which is material to our deliberations. The people are clamoring and complaining that the body has not before this, come to some result. For one, I am
                unwilling to adjourn this body leaving the State of Virginia not in a condition to meet the emergencies that may arise, so long as the Executive of the United States keep himself buttoned up and does not indicate his policy. I can imagine no good reason why he should not tell us. If he breaks the suspense which is now hanging like a dark cloud over the nation, and tells us that he
                means to coerce, that his policy will be to maintain the power of the General Government in those forts, we will then know what action to take. All doubt and hesitation will be removed from my mind, and I will be then for taking our position with the Confederate States and going out of the Union. I have been hesitating, and have been kept back because I hoped that the policy of the
                Executive of the United States would be peaceful. If I could be assured of that I should not then feel myself justified in taking the position which I have just indicated. I ask those gentlemen who oppose the appointment of the proposed committee, to indicate to us and to the country what great objection there can be to the course indicated? We tell them that it is, in our opinion,
                important that we should have this information, on which we may base our action. They say that the President will probably not answer the committee. Suppose he does not, still, if he gives a good reason for his silence, we must judge of his response as given.
              </p>
              <p className="p-in-sp">
                We may not take his response with any factious or carping spirit, but as men who are to act on their responsibility. I reiterate the inquiry, what harm can result from this application? What good reason can you give for not making that simple attempt, respectfully, to obtain from the highest source known to us important information on which we may act? I ask, whether this body is to
                adjourn, in the present state of suspense and doubt, with the indications before us <PageNumber num={324} /> of movements of men and arms? We cannot do so until we have more certain information.
              </p>
              <div className="speaker" id="sp2559"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">I certainly did not intend to take any part in this debate, and should not have said a word upon this subject but for the remarks that fell from the gentleman from Prince George [<span className="persName">Mr. RIvES</span>]
                . It is a matter of perfect indifference to me whether this Committee is sent to Washington or not. I care very little whether the information looked for is transmitted to us or not. I have no manner of doubt that the resolutions to which the gentleman referred will have to be offered in this Convention, if not in a short time, they will have to be submitted ultimately in spite of
                anything the Convention can do. I merely rise now, sir, for the purpose of disabusing the mind of this Convention that I, or those who act with me, have anything to do with these resolutions. My friend who offered the resolutions on Saturday, did me the honor to inform me that he had resolutions of this character to offer, but not feeling any interest in them, I did not remain in the
                Convention to vote upon them; I have no interest in the movement. When the Convention voted down the resolutions which I submitted the other day, I felt relieved from any responsibility that might attach to me for any results that may take place from the action of this Convention. I concluded to throw upon this Convention all the responsibility for its future action, and not to
                interfere except so far as I shall vote for any proposition that may recommend itself to my judgment in preference to others which are now pending. I speak with reference to the propositions of the Committee on Federal Relations. I shall vote for this proposition because it seems to gentlemen that a development of the purposes and the policy of the Administration may lead, by
                possibility, to action upon the part of this Convention. It is that consideration, and that alone, which will permit me to give my support to the proposition submitted by the gentleman from Montgomery
                [<span className="persName">Mr. PRESTON</span>] . But, sir, if this question assumes a different aspect—if, instead of Virginia going out, she should, through her commissioners, assume a crouching, humiliating, imploring attitude, with a view to ascertain the policy of the Administration, I shall undoubtedly vote against the resolution.</p>
              <p className="p-in-sp">I should be glad if my friend from Montgomery [<span className="persName">Mr. PRESTON</span>]
                , would come up with a proposition directing that Virginia should be represented at Washington as, in my judgment, her interest and her honor require that she should be represented. I shall gladly vote for such a proposition, but I am not willing, at any time, to vote for any action here which would afford to the administration a mere pretext
                <PageNumber num={325} /> for evasion, and enable the President, by equivocal diplomatic terms, to cover up his purposes. I shall vote for no resolution that proposes that letters shall be sent to the President inquiring what his future policy shall be. I want, if any action is taken on this subject, that proper representatives shall be chosen, who can
                report to us what occurred when they waited on the President of these United States, to which, in my opinion, we now unfortunately belong. I will do nothing which my judgment does not lead me to believe, will not lead to a severance of the connection which now exists between Virginia and the Northern States of this Union.
              </p>
              <p className="p-in-sp">I am willing to vote for the proposition of my friend on my left [<span className="persName">Mr. GOGGIN</span>]
                , which proposes to take independent action; and that is the farthest that I mean to go in that direction. I am willing, if Virginia severs her connection with this former Confederacy—for I will not admit that it is a Confederacy now—that she shall take an independent act and shape her own course. I don't desire that she shall proclaim, before leaving this Confederacy, that she is
                going—as I know she is—with the South. I am perfectly certain that her interests, her honor, and her inclinations will drive her to the South. I would be perfectly willing that she should pass an ordinance of secession disconnected from her future policy of union with the Southern States, or any other States; for I am satisfied, that her interests and sympathies will inevitably lead
                her in that direction.
              </p>
              <div className="speaker" id="sp2560"><span className="persName">Mr. HOLLADAY</span>—</div>
              <p className="p-in-sp">When these resolutions were offered on Saturday evening, whilst I had some doubts as to the propriety of the matter, I was strongly inclined to vote for them, and should have done so, had a vote been taken on that occasion.</p>
              <p className="p-in-sp">
                The information sought to be elicited is, in my judgment, eminently proper. We all must concede, sir, that the public mind requires to be enlightened upon the subjects involved in the resolutions referred to. I have no doubt, sir, that the information sought to be elicited, if asked for in a proper manner, would be furnished, and that the result would be to quiet the apprehensions
                which may now exist upon the minds of a portion of the people of the Commonwealth. I express this belief, sir, because when I couple the pacific course pursued by the Administration with the inaugural address of the President, I feel justified in saying sir, that the policy of the Administration is a peaceable policy. And whilst I have no apprehension that the information sought
                would elicit anything to the contrary, I was anxious that the information might be obtained in order that it may produce a beneficial effect upon the public mind.
              </p>
              <PageNumber num={326} />
              <p className="p-in-sp">
                I thought on Saturday, that the resolutions contemplated merely to elicit from the President of the United States information as to the policy he meant to pursue towards the seceded States; but, sir, I frankly confess, that the course of remarks indulged in here to-day on the part of the few who have addressed this Convention have created doubts in my own mind as to the policy of
                this matter at all, and I am strongly disposed to vote against the resolutions which are now before the Convention.
              </p>
              <p className="p-in-sp">
                Sir, I had supposed, if I shall be permitted to reiterate, that the object was respectfully to draw from the President an expression of the policy designed to be pursued by him. I had no idea that gentlemen would construe these resolutions to authorize an inquisitorial course on the part of the Commissioners, who may be selected to go to Washington under these resolutions. But, sir,
                I cannot fail to perceive that there is a construction existing in the minds of some gentlemen who have addressed the Convention, which, in my judgment, will render the appointment of Commissioners, under these resolutions, exceedingly inexpedient, and exceedingly dangerous. It has been intimated here that we are not only to address an inquiry to the President as to what course he
                means to pursue, but that we are to follow up the inquiry—an answer to which is to pacify the public mind or determine our action in this body—by detailed inquiries as to what the President intends to do in reference to that fort or this fort, this thing or that thing. Now, the gentleman from Amelia
                [<span className="persName">Mr. HARVIE</span>]
                , has given a reason here why he wants Commissioners appointed, which disposes me to go diametrically opposite to the course which he desires. He is not willing that a correspondence should be opened by this Convention through its President, with the Administration at Washington. He is not willing to receive the declaration of the President of the United States through that channel;
                but he desires the information to be received, and the manner in which it may be communicated directly from the report of the commissioners who are to be selected to wait upon the President. I say that there lies a cat in the meal tub here, as has been remarked by a gentleman on the floor of the Convention a few days ago. We propose to send these commissioners to the city of
                Washington. In what manner are they to deport themselves when they get there? What inquiries are they to address to the President? The action of the Convention may be perfectly proper and respectful, but I am not authorized to say that every gentleman who may be selected will have the disposition and the desire to conduct this matter in such a way as to draw forth the information
                that would <PageNumber num={327} /> be calculated to pacify the public mind. I say, sir, I have been disposed to favor these resolutions; but I must confess that, whilst I am solicitous that the information should be obtained, I am entirely indisposed to pursue the course to obtain the information that we desire, which the gentleman from Amelia
                [<span className="persName">Mr. HARVIE</span>] proposes.</p>
              <p className="p-in-sp">The proposition of the gentleman from Augusta [<span className="persName">Mr. STUART</span>]
                , met<span className="note" id="Note40"><span className="noteLabel">4</span>The Enquirer text, presumably garbled, says "ray met."</span> me this morning for the first time; and I am free to say that that proposition commends itself to my best judgment as avoiding all the difficulties which may present themselves in the mode in which the information may be sought by personal interview
                between members of this Convention and the President. I say, therefore, if my mind comes to the conclusion from the debate here, that that is the proper course to pursue, I shall decline to vote for this proposition and shall vote, if an opportunity is afforded me, for the proposition which the gentleman from Augusta
                [<span className="persName">Mr. STUART</span>] brought to the attention of the Convention.</p>
              <p className="p-in-sp">
                Now, sir, we desire to ascertain from the President of the United States what is to be his policy in reference to the seceded States. If I understand the resolution correctly, that is the object it has in view. Are you not to address the President upon the subject? Will not the resolution speak for itself? If the President of the United States in response to this resolution shall
                communicate the desired information I ask will not the object be accomplished which the members of this Convention profess they desire to have accomplished? Can there be any objection to it? It is the information we desire, and I humbly submit here, that an objection as to the mode of appointing this commission is a matter foreign entirely to the object. I desire that this
                information shall be acquired in such a way as not to complicate it with the difficulties that environ it. I am not disposed to put the cause of the constitution and the Union and the preservation and construction of this Union into the power and keeping of any man or of any three men. Sir, let this Convention speak directly to the President of the United States through the President
                of this Convention. Let the response be a response to the resolution of the Convention, and I trust that no man who is in favor of the preservation of this Union will trust any man or any set of men, with the mode and manner of conducting this thing which may disappoint the hopes and expectations of those who may vote for it. I say, therefore, that I shall unhesitatingly vote against
                the proposition, when it shall come up.
              </p>
              <div className="speaker" id="sp2561"><span className="persName">Mr. FLOURNOY</span>—</div>
              <p className="p-in-sp">1 am really very much surprised at the opposition <PageNumber num={328} /> which the resolution of my friend from Montgomery [<span className="persName">Mr. PRESTON</span>]
                , has received upon this floor. It seems to me that a little reflection on the part of the members of this body, would satisfy them of the absolute propriety of some communication upon the part of this Convention to the President of the United States, in regard to the policy which he intends to pursue towards the seceded States. The vote of this Convention, taken I believe on
                Thursday last, by which the proposition for extreme action on the part of Virginia, was voted down by a majority of more than two to one has indicated to the Administration and the country, in unmistakable terms, that the object of Virginia is the peaceable adjustment of all these difficulties which now distract our country, and the restoration of our entire country to its former
                position. Sir, I imagine that every gentleman who voted on Thursday last, in this body, against the propriety of passing an ordinance of secession, voted his sentiments, and that in recording his vote he desired to declare, before his country and his constituents, that the time had not come when Virginia must sever her connection with the General Government, but that earnest, fair
                and manly efforts are yet to be made by us, as a body representing the State of Virginia to procure from that General Government such recognition of our equality and rights, as well as those of all the States of this Confederacy, as would have the effect to heal all our distractions and divisions, and restore the Union again to its former position. Then why, I ask, when Virginia has
                declared her policy by an overwhelming vote, is the proposition, on our part to ask the Administration to declare its policy in regard to these seceded States, to be regarded as dangerous, or matter of offence to that Administration? Sir, I will read the resolutions of my friend from Montgomery
                [<span className="persName">Mr. PRESTON</span>] . <span className="persName">Mr. FLOURNOY</span>, after reading the resolutions, resumed as follows : If language could be more careful and respectful than that in which the preamble and resolution are couched, I am unable to perceive it.</p>
              <p className="p-in-sp">
                Sir, there is not a word in that preamble and resolution that the strictest caviller for propriety could regard as offensive to the President of the United States, when these resolutions shall be presented to him by a committee of this body. We go to him, too, after having carefully indicated, in an unmistakable manner, the policy of Virginia in reference both to the seceded States
                and the Federal Government, with the recorded vote upon our journals, expressing, by a declared majority of two to one, the desire of Virginia that this Union shall be maintained ; that the government shall be re-united upon terms of <PageNumber num={329} /> equality and that Virginia does not wish to sever her connection with it. When my friend from
                Montgomery
                [<span className="persName">Mr. PRESTON</span>] , on Saturday evening, offered these resolutions, my distinguished friend from Augusta [<span className="persName">Mr. BALDWIN</span>]
                , had prepared a preamble which I thought would be more acceptable to the Convention, and less objectionable than the resolution of my friend from Montgomery; and, when he indicated it, my friend from Montgomery, without hesitation upon his part, accepted the preamble as a substitute for two of his resolutions, and connected it with the last resolution appointing commissioners. It
                was done in a spirit of conciliation, in a spirit of fairness, and with a purpose to give unanimity, as far as practicable, to the action of this body in approaching the President of the United States. On Saturday evening it met with scarcely any objection. How has the change come over this body since Saturday evening? Gentlemen have discovered in this—as it has been frequently
                discovered before in the action of this body—a cat in the meal tub.
              </p>
              <p className="p-in-sp">
                Let me say, for one, that if this resolution is introduced here for the purpose of bringing about a state of things that will drive Virginia into extreme action, I have no part in any such purpose. Sir, the day may come when we may have to stand in this Convention and vote for Virginia taking her extreme position. I shall regret that day when it does come, and I hope, if it ever
                comes, I shall have the manliness and firmness to stand up, with my life in my hand, if it may be, and record my vote, or do any other act to place Virginia wherever her honor shall require her to go. But I am sure my friend, the mover of these resolutions, and the friends who were consulted, had no such purpose of seeking an opportunity to place her in that extreme position. Our
                purpose, however different, was to get from this Administration a declaration of its policy, that would enable us, when we adjourned, after having adopted such propositions, and proposed such amendments to the Constitution as ought to be sent to the North for their adoption, to go home to our people, and assure our disquieted, distracted and distressed constituencies, that while the
                North was acting upon these propositions, we have the assurance that the peace of the country is not to be disturbed. I do hope that it will be the pleasure of this Convention to pass the preamble and resolutions as they are, and to select from their body three discreet and prudent gentlemen to go to the city of Washington and present these resolutions, make the requisite inquiries,
                and bring back the report to us; and I trust in God, as I would deplore the shedding of blood, as I would deplore the destruction of my country, that a change may come <PageNumber num={330} /> over the spirit of the dream of the man at the head of the government, if he has war in his purposes, and that he may say to us in honest truth that it is his
                purpose to deal with this whole question peaceably, and then we will be left to deal with it wisely, and bring about such results as may be best adapted to the interests of our country, our whole country; but whatever may be his purposes—whether peace or war—it is our right to know it. If peace, I shall rejoice to know it, if war, we must prepare to meet it.
              </p>
              <div className="speaker" id="sp2562"><span className="persName">Mr. BALDWIN</span>, of Augusta—</div>
              <p className="p-in-sp">From the remarks submitted by the gentleman from Halifax [<span className="persName">Mr. FLOURNOY</span>] the inference might be drawn that the acceptance, on Saturday evening last, by the gentleman from Montgomery [<span className="persName">Mr. PRESTON</span>] of the preamble which I suggested as a substitute for his first resolution, was upon some understanding or agreement.</p>
              <div className="speaker" id="sp2563"><span className="persName">Mr. FLOURNOY</span>—</div>
              <p className="p-in-sp">Oh, no. If any such inference could be drawn, it was not in my mind, because there was no understanding or agreement on the subject.</p>
              <div className="speaker" id="sp2564"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">
                I merely wish to disabuse the minds of the Convention of any idea that I intended, by moving the preamble as a substitute for the first two resolutions, to commit myself absolutely to the plan as thus amended, and I think I can remind the members of this body that in the remarks which I submitted I stated that I had the impression—I could not say it was a distinct opinion—that the
                third resolution ought to be modified in certain particulars which I indicated; and the gentleman from Halifax will recollect that pending the discussion afterwards, at my instance, he called upon the gentleman from Montgomery
                [<span className="persName">Mr. PRESTON</span>] to make a suggestion enlarging the scope of the resolution.</p>
              <div className="speaker" id="sp2565"><span className="persName">Mr. FLOURNOY</span>—</div>
              <p className="p-in-sp">Yes, sir.</p>
              <div className="speaker" id="sp2566"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">
                So, sir, I do not regard myself as committed to the plan by having offered the amendment and by that amendment being accepted. I stated that I believed the mission to Washington at present, if properly conducted, would be of value. I think so yet, and if we can have the proper men to go to Washington with the right feeling, I have no question that a mission of the sort would be of
                great advantage.
              </p>
              <p className="p-in-sp">I was very much struck with the remarks made by the gentleman from Norfolk County [<span className="persName">Mr. HOLLADAY</span>]
                in regard to the latitude which seems to be indulged in in anticipation by gentlemen upon this floor. Is it proposed that we shall send a Committee to the City of Washington to cross-examine the President of the United States, and press <PageNumber num={331} /> upon him an inquiry into minute particulars, after he shall have indicated in such terms as
                he deems proper his general policy? Is it proposed that he shall be put upon the witness stand and subjected to a minute examination, and that the result of this sort of interview is to be brought back here and referred to this body, according to the impression which it may make upon each one of the envoys engaged in it. If that is the idea, I desire utterly, utterly to protest
                against any such mission. I concur in the sending of a mission, the main object of which shall be to represent to the President of the United States the desire of this body for a pacific policy, and the opinion of this body that a declaration of such a policy, at an early day, will conduce to the public good. If it is in the mind of any member here, that upon such a mission he would
                be entitled to go further than that, it seems to me that every mind here ought to be disabused of any such impression.
              </p>
              <p className="p-in-sp">
                In regard to the form of this resolution, it does not strike me as being at all wanting in respect to the President of the United States; but, sir, the direct enquiry, asking the President of the United States to report to this Convention, seems to me, to go beyond any constitutional right that we have in addressing the President of the United States. As has been stated by the
                distinguished gentleman from Charles City
                [Ex-President TYLER]
                , if the Senate of the United States, or both Houses of Congress, representing the entire Government, the people and the States, were to call upon the President for information in regard to any line of policy as to which secrecy might be desirable, they would adopt towards him the courteous language, "if it consists with the public interests to make the disclosure." Is there any
                reason that we should address to him a direct inquiry and depart from what is universally regarded as the respectful language of inquiry to be addressed to the President of the United States in regard to Executive business? If there is no reason—none occurs to me and none has been suggested to me as yet—for leaving out what seems to me recognized as the universal formula in all such
                cases; if we are to confine ourselves to the simple inquiry—then I would suggest that we ought to adopt the form universally recognized under such circumstances. Why? To avoid the idea that some persons have intimated here—an idea that I think the gentleman who moved the resolutions intimated the other day, that if the President declines to give the information sought, we are to
                treat his refusal as an act of hostility? Now, I am not willing to make any forgone conclusion of that sort. I desire, if we go beyond our plain, constitutional right, as clearly defined, to ask questions in <PageNumber num={332} /> regard to matters about which we earnestly crave information, that we shall not determine in advance to fall out with the
                President and attribute other than proper reasons of State for his declining.
              </p>
              <p className="p-in-sp">I prefer, sir, the resolution suggested by the gentleman from Frederick [<span className="persName">Mr. CONRAD</span>]
                . It is the form of resolution that I would prefer; and if I could see that resolution adopted, and proper men selected to represent this Convention upon such a mission, I should expect much good to result from it. But unless something of that sort is done, unless some definite assurance could be given, both in regard to the frame of the enquiry, and in regard to the dispositions and
                opinions—I don't mean general opinions but opinions on the policy to be pursued on this mission—of the gentlemen who would go upon that mission were distinctly understood by me, and understood to confine them to this respectful enquiry, and to avoid a cross-examination of the President of the United States, I should be compelled to vote against the whole proposition.
              </p>
              <div className="speaker" id="sp2567"><span className="persName">Mr. WM. C. SCOTT</span>—</div>
              <p className="p-in-sp">
                I think this mission, sir, one of doubtful propriety. If the vote had been taken on Saturday evening, I should unquestionably have given my vote for it with my whole heart; but upon further examination and information, I think it is doubtful whether this resolution should pass or not. Sir, what are the circumstances connected with the condition of the country at this time? We are
                informed by the latest telegraphic despatches from Washington, that the whole policy of the Administration is in profound mystery; that neither the President, nor any member of his Cabinet, has indicated to any individual what that policy is to be.
              </p>
              <p className="p-in-sp">
                We know, sir, that military expeditions have been undertaken; that vessels have been sent off with troops; and we know that secrecy is the very soul of all military expeditions. What are we to expect if we send this committee to Washington? Do we not know that the policy of the Administration will not be communicated to them? Ought Virginia then to be put in the humiliating attitude
                of asking from the Executive of the United States that which we have every reason to believe will be refused? And, sir, if refused, and the information comes back to this Convention that it is refused, what course will gentlemen then take in regard to that refusal? But, sir, notwithstanding all these objections, I shall vote for the resolution. I shall vote for it, Mr. President,
                because I wish the committee to go to Washington and inform the President what, in their opinion, the opinion of the majority of this Convention is. I wish the committee to let the President know that the coercive policy will not be submitted to by the people of <PageNumber num={333} /> Virginia. I wish this committee to let him know that he must not
                misunderstand the temper of this Convention; that while we are conservative, while we are disposed to do everything in the world to reconstruct this Union, if his purpose is to subjugate the people of the South, the honor and the interest of Virginia require that we shall take sides with them and endeavor to prevent that coercion. These being my sentiments, I hope the resolution will
                be adopted, and that these gentlemen will be selected by this Convention to go to Washington and inform the Executive of the United States what is the tone and temper of this Convention.
              </p>
              <div className="speaker" id="sp2568"><span className="persName">Mr. CARLILE</span>, of Harrison—</div>
              <p className="p-in-sp">Mr. President, if there had been any indications from the friends—</p>
              <div className="speaker" id="sp2569"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair is inclined to the opinion, but wishes to be corrected if he is in error, that the gentleman from Harrison has spoken ten minutes upon the previous question.</p>
              <div className="speaker" id="sp2570"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">I did speak, sir, but I spoke before the previous question was called, as the President will remember.</p>
              <div className="speaker" id="sp2571"><span className="persName">Mr. PRESIDENT</span>—</div>
              <p className="p-in-sp">I did not recollect; I merely wished to be informed.</p>
              <div className="speaker" id="sp2572"><span className="persName">Mr. CARLILE</span>, of Harrison—</div>
              <p className="p-in-sp">
                I was about to remark, Mr. President, that if there had been any indications from the friends of this resolution that they would yield to the request not to press the previous question, I should not have troubled the Convention with any remarks, and should not have consumed the ten minutes to which I am entitled under the rules of this body. Upon the first introduction of the
                resolutions on Saturday evening, without previously having the slightest idea that any thing of the kind was contemplated by any member of this Convention, I felt satisfied that it would be improper to adopt them, and I gave expression to that opinion then. Subsequent reflection has but confirmed me in that opinion ; and if I had a doubt that doubt would have been removed by the
                remarks of the very distinguished gentleman from Charles City
                [<span className="persName">Mr. TYLER</span>]
                , when he told us this morning that it would be cruel and inhuman, on the part of the President, to adopt a coercive or warlike policy without first declaring to the nation his determination thus to adopt that policy. Now, sir, however much we may dislike the man who now occupies the Presidential chair, I for one, am unwilling to declare, by any act of mine, that I believe him to be
                that monster which the declarations, coming from such a respectable source, would necessarily make him, if he did contemplate anything like war or coercion. And I think that the very statement to which I have referred as having been made by one who has himself occupied the position of President of the <PageNumber num={334} /> United States
                [<span className="persName">Mr. TYLER</span>]
                , now occupied by Mr. Lincoln, is the very best argument why there cannot be, upon the mind of any gentleman, the slightest apprehension that anything like a warlike or coercive policy towards the seceded States is contemplated by the President of the United States. But, sir, we may see—at least, I think I can see—strong reasons why those who desire a maintenance of the Union, and
                those who believe that no Union exists, but desire its reconstruction, and yet demand of the Executive a public avowal of his policy at this time, should believe that his policy is pacific ; it has been pacific, and no man can doubt that it will continue so to be, until he, by public proclamation, declares his determination to reverse that policy; no man who is not willing to
                attribute to him, in the language of the gentleman from Charles City, cruelty and inhumanity, can for a moment doubt. And that it is the true policy, no man who has reflected upon the matter can question. Let him, as has been heretofore the course, give no farther disclosures of his policy than have appeared already through the sources of public information, and allow those in power
                in the Confederate States, to continue their exhausting and harassing measures of preparations for war, both by getting men and money, and you will see that in a month or two more, the people in those States will take the reins of Government out of the hands of politicians, and the patriotic sentiment in the hearts of those people, will bring back those States to their ancient
                allegiance, and then they will be found once more in the family of States. Such a policy, in my opinion, is the true policy to be pursued.
              </p>
              <p className="p-in-sp">
                But this preamble, as well as the resolution, seems to point to the fact that the policy which the Administration is now pursuing, and has heretofore pursued, is paralyzing the industrial and commercial interests of the country, and, by virtue of apprehension in the public mind as to the action of the Federal Government, these industrial and commercial interests are prejudiced. Sir,
                is there a man who doubts for a moment that all that has been done to the prejudice of the industrial and commercial interests of the country, has been the necessary, direct and legitimate result of the action of the seceded States, and of the action of those other States which are like ourselves, South of Mason &amp; Dixon's line, yet remaining in the Union, and who indicate a
                disposition to resist the Federal authorities in the event the seceded States should provoke hostilities by attempting to take possession of the forts necessary to protect our commerce?
              </p>
              <p className="p-in-sp">
                We were just about recovering, so far as our State credit is concerned, from the effects produced by the act of the Legislature providing <PageNumber num={335} /> for the issue of one million of $10 treasury notes. The action of this body on the 4th of April, declaring by a vote of two to one that Virginia was not prepared to resort to the adoption of
                an ordinance of secession, was about to restore confidence and our bonds to-day, but for the offering of these resolutions, would have been in the market at a much better rate than they now command. I find in the leading disunion and sensation journal in this country, the New York "Herald," this very proposed action reported as having been adopted the day previous, twenty-four hours
                before the resolutions of the gentleman from Montgomery were submitted. I begin strongly to suspect that it was necessary to keep up the disunion feeling on the part of the editors of that paper, who have done more to destroy the government than the editors of any other press in the land, that this false statement was published as another means to fire the Southern heart, to alarm
                the patriots of the country and to remove the healthful impression that was about being made by the action of this body in refusing to adopt the secession resolution submitted by my friend from Amelia. I find in the New York "Herald" of the 6th inst. the following :
              </p>
              <p className="p-in-sp">"IMPENDING REVOLUTION IN VIRGINIA</p>
              <p className="p-in-sp">"WASHINGTON, April 5, 1861</p>
              <p className="p-in-sp">"A special meeting of the Cabinet was called to-night at half-past ten to give a hearing to a select committee from the Virginia State Convention. * * * * * * *"</p>
              <p className="p-in-sp">This dispatch was received from Washington on the night of the 5th instant, and it was not until the night of the 6th instant, the resolution for the appointment of a select committee to go to Washington was introduced into this body.</p>
              <p className="p-in-sp">The previous question should be voted down, in order to allow the amendment indicated by the gentleman from Augusta [<span className="persName">Mr. STUART</span>] . If we desire the information which it is the object of the resolution to obtain, it should be in the language of the President of whom we make the enquiry; and it would be better to adopt the amendment which the gentleman from Augusta [<span className="persName">Mr. STUART</span>]
                , has indicated his purpose to move in the event that the previous question shall not be sustained. Let the President of this body address a letter to the President of the United States, and let him answer in writing. But you propose to appoint a committee of three gentlemen, taken from this body, they to enter into conversation with the President, then to detail
                <PageNumber num={336} /> to us and give us in their own language their impression of what that conversation was.
              </p>
              <p className="p-in-sp">
                Sir, feeling, as I do, an ardent desire to preserve forever the institutions under which we have lived thus far, loving my whole country, every part of it, Louisiana and Texas as much as any other part, save and except the mother of us all, I desire that no action shall be taken which will not promote the great end and object we have in view—the preservation of the Union and the
                restoration of the harmony of the country. Therefore, sir, if I were upon that committee ( of course I shall not be) , I would receive the declarations made to me by Mr. Lincoln under very different circumstances from those in which they would be received by my friend before me
                [<span className="persName">Mr. HARVIE</span>] , who tells you frankly, he does not desire perpetuity of the Union or its reconstruction.</p>
              <div className="speaker" id="sp2573"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">The gentleman did not read the whole of the dispatch to which he alluded in the Herald.</p>
              <div className="speaker" id="sp2574"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">I read that portion of it which we all know to be untrue. I have not time, in the few minutes allowed me, to read more, or I would do so.</p>
              <div className="speaker" id="sp2575"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">Well, I wish to say that I would like to show that the remainder of the dispatch is also untrue.</p>
              <div className="speaker" id="sp2576"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">I wish to occupy the whole of my ten minutes; if I had longer time I would give way.</p>
              <div className="speaker" id="sp2577"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The gentleman from Harrison has already occupied his ten minutes.</p>
              <div className="speaker" id="sp2578"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">I hope I will be allowed one moment to finish what I was about to say.</p>
              <div className="speaker" id="sp2579"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">If there is no objection the gentleman can proceed.</p>
              <div className="speaker" id="sp2580"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">
                In illustration of the point to which I was directing the attention of the Convention, I desire to call the attention of the members of this body for one moment to the remarks which were made upon this subject by the gentleman from Princess Anne. He told you that the retention of the Tortugas and those other forts which are necessary to protect the commerce of Virginia, and of the
                whole country, would, in his view, be aggressive and coercive. Sir, so long as I remain a citizen of the United States, I shall never consent to the abandonment of any fortifications which are necessary to protect the commerce of the people of Virginia and the commerce of the Union in which we remain.
              </p>
              <div className="speaker" id="sp2581"><span className="persName">Mr. MACFARLAND</span>, of Richmond—</div>
              <p className="p-in-sp">
                I should not have ventured, Mr. President, to express opinions upon the question now before us, but <PageNumber num={337} /> for what was said by the gentleman who has just taken his seat. That gentleman ventured the opinion that the deranged condition of the trade, and business of the country, is the result of the secession movement of the South,
                wholly unaffected by the course or policy of the Administration. There is no opinion more mistaken or erroneous.
              </p>
              <p className="p-in-sp">
                It is true the disruption did operate adversely upon business and pursuits, reduced prices, suspended confidence, and led to great sacrifices; yet, had the country been assured of a peaceful policy on the part of the Government, trade and industry, at this early day, would have, in the main, recovered their activity. It is characteristic of our people, and denotes their energy and
                resources, that be the depression of an unexpected disaster what it may, it requires but a short period for them to regain their confidence and repair the waste. I hazard nothing in saying, that, by this time, if the policy of the Administration had been stable and avowed, and the fear of commotion and war been dismissed, confidence would have taken the place of uneasiness and
                distrust, and industry and enterprise been in the enjoyment of their rewards. It is important, therefore, with reference to its commercial effects, that the course of the Administration should be declared.
              </p>
              <p className="p-in-sp">
                But, I fear, an importance is attached to this resolution to which it it not entitled. Assuming that the administration entertains purposes of hostility against the seceded States, they cannot be long hid or concealed. Preparations for war do not admit of being covered up or disguised; nor would there seem to be any adequate or just motive for concealment, if the President meditated
                war. He may be, and probably is, without any definite plan, only waiting upon events and expecting relief from some fortunate conjuncture. With an humble estimate of his statesmanship, and that of his Cabinet, I might suggest it as probable that he has not any settled views in respect to the course he will pursue. He may contemplate devolving the decision upon Congress, at a called,
                or at the regular session. For myself I do not expect any specific or valuable disclosure from the proposed mission to Washington, yet I am so sensible, from my connection with the general business of this city, of the importance of disabusing the public mind of the apprehension of war, that I am in favor of any fair means of gaining information from the Executive. I desire,
                therefore, that the resolution may be adopted, for which there is good reason in the time we have been engaged about it. If the mission accomplish nothing, it cannot eventuate in evil.
              </p>
              <p className="p-in-sp">
                The intimation that the committee may make an unfair report is <PageNumber num={338} /> both unjust and chimerical. Should the President abuse the grave occasion by indulging in evasive and oracular responses, the discredit will be all his own, whilst our position will be unchanged. The judgment of this body is far too enlightened and well balanced to
                be betrayed or disturbed by reports from Washington.
              </p>
              <p className="p-in-sp">
                The proposed application to the President is proper and becoming, and is authorized by the highest sanctions. In England, it is a familiar resort, when the business or commerce of the kingdom is threatened by acts of State, for merchants and others to appoint committees to wait upon the ministry, to remonstrate against the proposed measure. And, to their credit, be it said, the
                ministry habitually receive such delegations with courtesy, and respond to them with frankness. In whatever other particulars the President may be indifferent or unconcerned about the public uneasiness and anxiety, it would, if I may so say, betray an ignorance and obtuseness, unexampled and shameless, to refuse to a committee from this body a respectful and candid audience.
              </p>
              <div className="speaker" id="sp2582"><span className="persName">Mr. SHEFFEY</span>—</div>
              <p className="p-in-sp">I think, Mr. President, that the question which we are now debating is one of no ordinary interest, one which demands a calm and dispassionate consideration. The spirit of our institutions invests the functionaries of Government with no such sanctity as renders them unapproachable either to the people or to the States.</p>
              <p className="p-in-sp">
                We are assembled here, sir, representing the people of Virginia. We have shadowed forth in the action of this Convention, a policy looking to peace, to reconstruction and to the restoration of the whole Union. Yet, pending that policy, pending that course, we have borne to us, by every mail, communications, to which we cannot close our eyes, showing that there is a warlike policy
                prevailing in the General Government. Now, sir, this policy concerns us. It matters not whether that war be against a foreign foe or against members of our own community, we have a right to know—being a part of that great community over which Mr. Lincoln presides as President—what these preparations for war mean? If it is a foreign war, we are involved in it, for we still remain a
                part and parcel of this Union. Our institutions are affected by it, our treasure is to be expended in it, our blood is to be shed in it, our commerce and our institutions are to be deeply affected by it. We look to the constitution to see what power there is to involve the country in war, and we ascertain that that power exists in Congress alone. But, sir, we have heard of no
                declaration of war against anybody, and yet we see warlike preparations and warlike movements all over the country. What does all this mean? Have not <PageNumber num={339} /> we, sitting here and deliberating as we are about the course Virginia is to pursue, the right to know from the President of the United States whether his policy is peace or war?
                Mr. President, there has been an ominous, and, to my mind, awful silence in the North and in the functionaries of the Government at Washington. We have been in session now for two months, and yet no voice from the North, no commissioners from the North or from any portion of it have come to tell us that the pacific policy foreshadowed by the action of this Convention meets or has
                awakened any action, any sympathy or any responsive chord in the Northern mind or heart. On the contrary, wrapt in silence, in rigid, frigid dignity, she stands with an eye steadily fixed upon the devoted South, immovable, and in unbroken silence. And what has been the course of the President and his Cabinet? We have heard from the Halls of the Senate a debate between Mr. Douglas and
                those who are the organs of the President; and when Mr. Douglas interprets the inaugural address of the President to be pacific, as shadowing forth a pacific policy and not as breathing a war spirit, we hear no response to that interpretation. But when he brings his interpretation home to the organs of the Administration and asks them to declare whether that interpretation is
                correct, whether his policy is peace or war, they not only refuse to answer, they not only maintain a perfect silence upon that point, but they hurl vehement and vindictive denunciation at Mr. Douglas for daring to say that the policy of the President was not warlike but pacific. And, sir, we have indicated by our solemn declaration that we, the people of Virginia, represented in
                this Convention, the arbiters of her destinies, linked and interlinked with the destinies of the remaining States of this Union, have spoken to the country, have spoken to the Government at Washington, our fixed determination that no coercive measures, that no measures of subjugation of these seceded States shall be adopted by our consent. Yet we hear that expeditions are being
                fitted out, that men are being armed, that light batteries are being placed on board vessels, and are being sent off. Is it against a foreign foe? Why should the people of Virginia be refused that information? Why should they be refused the information as to whether they are to be involved in a foreign war? Is it against our own people? Have not we, the people of Virginia, the right
                to know that such is the purpose of our own Government at Washington.
              </p>
              <p className="p-in-sp">
                Sir, we are deeply, vitally interested in a plain and unequivocal response to the inquiry proposed by the distinguished gentleman from Montgomery. We have the right to know whether, in violation of the <PageNumber num={340} /> express will and wishes of the people of Virginia, through their Convention, and in contempt of the pacific course of policy of
                this Commonwealth, we are, under these circumstances, and at such a time, to be committed to a war of coercion against the seceded States in any form.
              </p>
              <p className="p-in-sp">
                We have been told—and that is the only thing that has broken the seal of silence on the part of the Executive of the United States—that the laws shall be executed in all the States. We have been told that the forts will be held, will be occupied, will be possessed; that is, re-captured and re-occupied. We have been told that the revenues will be collected. Now, sir, we all hold here
                that the re-capture and reoccupation of the forts in the seceded States, and the collection of the revenues, would be coercion. Now, sir, if these troops, if these war-like operations, secretly and silently carried on, are intended to subjugate these seceded States, either by a re-capture and re-possession of the forts, or the collection of the revenue, we have the right to know it.
                Then, sir, let us present, respectfully, through the Commissioners appointed by us, a manly, bold and unequivocal demand to know, "Sir, what is your policy? What mean these warlike preparations? Are we to be involved in a war against our consent? And especially, are we to be involved in a civil or fratricidal war—a war against our own institutions—a war that will end, God only knows
                where or when?"
              </p>
              <p><span className="persName">The PRESIDENT</span> here informed the speaker that his time had expired.</p>
              <div className="speaker" id="sp2583"><span className="persName">Mr. SPEED</span>—</div>
              <p className="p-in-sp">
                In my humble judgment, this motion of the gentleman from Montgomery ought to prevail. I regard it as eminently wise and conservative, and because it is so, in my opinion, I approve of it. It has been my fortune, or, as some may suppose, my misfortune, to become disconnected, by the vote I gave on the third<span className="note" id="Note41"
                ><span className="noteLabel">5</span>The vote occurred on April 4.</span
                >
                of this month for the submission of an ordinance of secession to the people of Virginia, from those with whom I have been heretofore politically associated. Whether that vote was wise or unwise, I shall not now stop to inquire. I think if I had the opportunity, I should be at least enabled to show to those more immediately interested in my action here, that I acted with the best
                lights before me, and with an anxious desire to do my duty to those who have entrusted me, in part, with their interests. I claim, Mr. President, to be conservative in my opinions, and anxious, always, to support every measure conservative in its tendency and peaceful in its influence. I trust no gentleman on this floor will suppose that I and those who are associated with me,
                advocate this measure with a view of accomplishing, indirectly, what we cannot <PageNumber num={341} /> accomplish directly. To the extent that they impute such motives to me, certainly I beg them to believe they do me great injustice.
              </p>
              <p className="p-in-sp">
                Look, Mr. President, at the condition of things. I, in part, represent an agricultural, manufacturing and commercial constituency, and they have a deep and abiding interest in the question of peace or war, and they desire to prepare themselves for any fate that awaits them. Look at the means which, with anxious concern, we are endeavoring to arrive at the truth in regard to this
                momentous question. Men of wisdom and experience say that all the lights and indications point to the fact that we are to have war, and that at an early day. Gentlemen upon the other side—I will not call them "submissionists," because it is far from me to impute to them any such purpose as the use of that term would impute. It would be disrespectful; it would savor of discourtesy;
                and I assure gentlemen that there is no feeling in my bosom of the slightest disrespect to them. These gentlemen, on the other hand, I say, seem to entertain no fear in regard to the action of the authorities at Washington. They have assigned no reason which has reached my ear, why they entertain this opinion, other than the fact that up to this time no warlike step has been taken. I
                beg to remind them that steps have been taken which some have interpreted as distinctly warlike. Others have not given them that interpretation. Steps have certainly been taken of an equivocal character. Steps have been taken which, in the estimation of many whose opinions are entitled to weight, can mean nothing except to set on foot a hostile expedition against the seceded States.
                If there be such a movement, ought we not to know it? If these steps are not designed for such a purpose, ought we not to know the fact? Have not the reasons set forth by the distinguished gentleman from Richmond city
                [<span className="persName">Mr. MACFARLAND</span>] , affecting the commercial interests of this city and this Commonwealth, been sufficient to show that in regard to this matter we ought to be informed, and that it is our solemn duty to be correctly informed?</p>
              <p className="p-in-sp">But it is suggested, that perhaps we have no authority to make this application at Washington. The gentleman from Augusta [<span className="persName">Mr. BALDWIN</span>]
                , inquires if you are to send delegates there to cross-examine the President of the United States. Sir, I presume, when this body sends delegates on such a mission, gentlemen of intelligence will be appointed, who will understand the proprieties of their position and of the occasion. If for no other consideration, respect for the office of the President of the United States would
                dictate to them that they should propound no other than proper and respectful questions.
              </p>
              <PageNumber num={342} />
              <p className="p-in-sp">
                If in reply to such questions the President does not give answers that are satisfactory, because not understood, is it the expectation of any gentleman here, that those to whom we have assigned the duty of making these enquiries of the President, will desist from their enquiry, simply because the answers given are not clear and satisfactory in the first instance? If the President of
                the United States, in regard to matters of such transcendent importance, will not condescend to give satisfactory answers—if he will place himself in the category in which a cross-examination would be necessary, and, therefore, proper, I hope it will be addressed to him. To suppose that he would give evasive answers is to show, in another form, the necessity of a cross-examination,
                which is to extract from him that which we desire to know. I take it, however, that proper answers would be given to proper questions propounded by the Committee, and that if they failed to secure such answers in the first instance, in consequence of the questions not being such as to elicit them, they would in the second instance so frame their interrogatories as to draw forth
                proper responses.
              </p>
              <p className="p-in-sp">It is suggested by a friend near me, that the resolutions, do not contemplate that any questions shall be propounded to the President.</p>
              <p className="p-in-sp">Well, sir, I suppose it would be useless to communicate with the President, unless the communication was in such form as to elicit answers on the points suggested, and on which we desire information, and that form I would respectfully submit would, in effect, amount to an interrogatory.</p>
              <p className="p-in-sp">
                Mr. President, at the session of the Legislature before the last, the sum of $500,000 was appropriated—and for what? To put the State in an attitude of defence. $1,000,000 more were appropriated at this last session for the same purpose—and not only for the purpose of general defence, but for the purpose of preparing for any sudden emergency that may arise. Against whom are we to
                defend ourselves? Against the very government we have aided in inaugurating and sustaining? And, now, the question arises are we to have immediate use for the arms and munitions bought and to be bought by this immense outlay of treasure? Are we to have peace or war? When we propose to address the President of the United States a respectful communication for the purpose of eliciting
                that information, gentlemen upon the other side resist the proposition—some for one reason, some for another
              </p>
              <p className="p-in-sp">and others, permit me to say, it seems to me, for no reason at all, that has been assigned, satisfactory to the Convention.</p>
              <p className="p-in-sp">
                I trust that no gentleman will be induced to vote against this resolution, <PageNumber num={343} /> on the ground that anything unfair is designed; on the ground that it is the intention of those who want to precipitate Virginia into a condition that is to accomplish the purposes of those who desire secession; for I believe that no such purpose is
                entertained.
                [Here the speaker's ten minutes expired.] <span className="persName">Mr. PRESTON</span> obtained the floor.</p>
              <div className="speaker" id="sp2584"><span className="persName">Mr. AMBLER</span>—</div>
              <p className="p-in-sp">Will the gentleman allow me to make two or three remarks, as I suppose I shall have no other opportunity?</p>
              <div className="speaker" id="sp2585"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The gentleman will have an opportunity to speak if he desires.</p>
              <div className="speaker" id="sp2586"><span className="persName">Mr. AMBLER</span>—</div>
              <p className="p-in-sp">I was afraid I should not be able to obtain the floor. I will not, however, take up the time of the gentleman from Montgomery.</p>
              <div className="speaker" id="sp2587"><span className="persName">Mr. PRESTON</span>—</div>
              <p className="p-in-sp">
                The relation in which I stand to this question is the only apology that I shall make for again intruding myself upon this Convention. I shall occupy but very few moments; and my desire will be to bring this question back from the discursive debate which has attended it to the real question, and the real objects I propose to effect by this resolution. Sir, there has been much said in
                regard to the purpose that may lie hidden under this movement of precipitating revolution, of bringing up again the ordinance of secession, and by other gentlemen in reference to certain coincidences in time. Before I proceed, I wish to ask the gentleman from Harrison to give me his ear and to respond distinctly to the question I shall propound. The gentleman alluded to a coincidence
                in time between a certain dispatch which he read from the New York Herald, and my action on Saturday. I ask him whether he meant by pointing to that concurrence of time, to insinuate directly or indirectly that those who prepared the resolution had any connexion with that dispatch?
              </p>
              <div className="speaker" id="sp2588"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">
                If the gentleman from Montgomery had heard the connection in which I read from the "Herald," he would not have taken up the impression which now seems to be upon his mind. It will be remembered that I was alluding to the "Herald" as a great disunion sensation organ, and I was referring to the fact that if there had been nothing in conflict with the vote of this Convention on the 4th
                of April, in my opinion confidence would have been, by this time, in the way of being restored, and our State credit would have occupied a much higher position than it now occupies. I read from that paper, not at all to impute—for I trust I never will be so far forgetful of what is due to myself and others as to impugn the motives of any gentleman upon this floor or elsewhere—not for
                the purpose, I say, <PageNumber num={344} /> of imputing any improper motives, in any connection, between this resolution or the action of the gentleman from Montgomery, and those who wrote that dispatch, but for the purpose of showing that such action, together with such paragraphs as that I read from the "Herald," and still others equally untrue, were
                calculated to get up again the feverish excitement which has prevailed in the country for the past few months. Immediately following the paragraph which I read were other paragraphs, equally untrue, as there are in other sensation disunion papers, gotten up, I presume, for the same purpose.
              </p>
              <div className="speaker" id="sp2589"><span className="persName">Mr. PRESTON</span>—</div>
              <p className="p-in-sp">
                The point and the only point to which I wished to call the gentleman's attention was this. I have not seen or read the paragraph to which the gentleman refers, the whole of which I hear he did not read, and which would have placed a different aspect on what he did read. About that, however, I care nothing. The gentleman dated the paragraph which he read on the 4th, and then said this
                resolution was offered upon the 6th, connecting me and this movement in that coincidence of time, so as to leave upon my mind the inference that the two had some connexion.
              </p>
              <div className="speaker" id="sp2590"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">
                Will the gentleman pardon me for one moment? The reason, as appeared from my statement at the time, why I did not read the whole paragraph was that I regarded the remainder as equally untrue, and I only read from the paper at all for the purpose of showing that many of the articles in these disunion papers had not the semblance of truth about them, as it was in my power in this
                instance, to show, from the fact that the paragraph appeared twenty-four hours before the gentleman from Montgomery had submitted his resolution to the Convention. I presume that all these statements are equally unfounded. This dispatch actually made the Convention wait upon the President, and a Cabinet meeting be convened at 10 o'clock at night for considering the matter before any
                resolution on the subject had even been introduced into the body. I gave it as an instance of the efforts being made on the part of disunionists to keep up the excitement in the country.
              </p>
              <div className="speaker" id="sp2591"><span className="persName">Mr. PRESTON</span>—</div>
              <p className="p-in-sp">And therefore, as I understand, the gentleman disclaims any intention of imputing any connexion whatever between myself and those acting with me, and the paragraph which he read.</p>
              <div className="speaker" id="sp2592"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">Certainly I do.</p>
              <div className="speaker" id="sp2593"><span className="persName">Mr. PRESTON</span>—</div>
              <p className="p-in-sp">
                Having passed this point most satisfactorily and agreeably, I beg to recall the Convention, if I can for a moment, to the true questions presented or intended to be presented by those who consulted together, and, to a certain extent, whose organ I was in <PageNumber num={345} /> presenting this resolution. Sir, upon the vote which occurred on the 4th
                day of April, in this body, I felt that a new condition of things had arisen in this Hall, that there were new relations and new obligations imposed upon me and upon all who acted with me. The contest had gone on for months in this Commonwealth as to what was the remedy under the circumstances for the difficulties that surrounded us. One portion believed it to be secession. Another
                portion, among whom I have been and am and will be while there is a just hope of procuring our rights and maintaining our institutions—maintained that the remedy was under and within the Federal Constitution and Government as it is. On the fourth day of April we vanquished our adversary, but thereby imposing upon ourselves the entire obligation, the high duty of providing safety and
                protection for the Commonwealth of Virginia by such other remedies as we might desire. It is a high, a responsible trust. It is a trust that I think God has devolved upon us, the trust of preserving our rights within this Union against the judgment of those who believe our only security is in separation. It is the judgment of this Convention, solemnly proclaimed in Committee of the
                Whole, that our rights shall be preserved in the Union, and all the obligation, all the responsibility, all the difficulty, all the danger, all the accidents that may occur, all the disasters that may befall the Commonwealth are upon us who have remained instead of departing. I assume it. So far as I am concerned, I shall meet it. No circumstances but those of ignominious cowardice
                could make me shrink from it.
              </p>
              <p className="p-in-sp">
                What, then, is our duty? We have told the people of the State that they were safe within the Union. We have told them, moreover, that we have a committee engaged in considering propositions on which we hoped to secure our rights, which propositions were to be submitted to, and, as we hoped, adopted by the North. Under this state of things, I felt that there was a sense of great
                security resulting from the silence, the protracted and pertinacious and unaccountable silence of the President of the United States. I thought he had it in his power, and hoped he would use it, to speak a word of peace, and to tranquilize these troubled waters. Look at the aspect in which we stand. The language of my resolution is admitted on all hands to be courteous, and
                respectful, and deferential, as all language ought to be. Not because, perchance, it may be due to the estimate which gentlemen have of the President himself, but because the only language which I could permit myself to use is the language of courtesy, the language of courage, and the language of decision—I want no equivoque, I want
                <PageNumber num={346} /> no evasion, I want no suppression—I want nothing but language of courtesy; and no language is so courteous as the language of frankness and courage. What now is our position? We have told our people that the President was safe to be trusted. We ask him now to confirm that confidence, and to afford tranquility and repose to the
                country; and gentlemen rise with vehemence, with temper, with feelings which did not exist in my own bosom then and do not now, and declare that this is an attempt to carry us out of the Union. Look, at what a work of supererogation and folly we are engaged in if gentlemen suppose that the President's answer is not to be propitious! We inspired the hope in the bosom of the
                constituents of two-thirds of this body that the North is ready and willing and anxious to shake hands with us, to accept those propositions, and to restore harmony. And when we come to test it, the great impersonation of the party, the great representative of the North, is silent, and you will not permit us to break that silence lest his answer might be adverse and the Union should
                be dissolved. We are going with demands of justice, with propositions which we know, confront and oppose some of the strongest prejudices of the North and their most compact party organization, and while we have the hope and expectation that these propositions will be acceded to gentlemen here refuse to permit their President to speak the words "peace, be still."
              </p>
              <p className="p-in-sp">I say, therefore, to the gentleman from Wood [<span className="persName">Mr. JACKSON</span>] , to the gentleman from Harrison [<span className="persName">Mr. CARLILE</span>] , and to my friend from Franklin [<span className="persName">Mr. EARLY</span>]
                , that when they take the ground that the President will not promptly, willingly, cheerfully avail himself of this opportunity to initiate and lead on those great measures of adjustment which we propose to carry out during the summer, then the game is hopeless and the work is one of supererogation. If we cannot talk, we cannot bargain. If we cannot address the President of the party,
                we certainly cannot address the subalterns of the party. If we cannot hold treaty with the general in his tent, we cannot subsidize the privates in their ranks. The declaration, therefore, that Lincoln ought not to be questioned, is a declaration which can rest only on the conviction of an unfriendly answer, and if that unfriendly answer is in his bosom, this re-construction is in
                its grave.
              </p>
              <p className="p-in-sp">
                I come, sir, with altogether different feelings. I believe the President will answer. I believe he will be glad to answer. I believe he will find, in this demand upon him, an opportunity of saying to the violent partisans who are now holding him up to their party issues, that the exigency is so great that he must speak or dissolve this nation. In the
                <PageNumber num={347} /> necessity which constrains him, he will find a justification in their bosoms for doing this work of justice and reconciliation. This is the sentiment in which I offered the resolution.
                [Here the ten minutes expired, but, by common consent, <span className="persName">Mr. PRESTON</span> was permitted to proceed.] I cannot consent to the amendment suggested by the gentleman from Augusta [<span className="persName">Mr. STUART</span>]
                , because it does not reflect the temper of this Convention or of the Commonwealth. My resolutions as they were originally prepared, declared, in the very terms of a resolution which the Committee had adopted not an hour before, that Virginia would never consent that the Federal power, which is, in part, her power, shall be exercised for the purpose of subjugating the seceded States.
                My resolution puts itself, not on the courtesy or discretion of the members of the Convention, but would send the message to the President in the language of Virginia, courteous, firm, bold, wise, inviting in respectful terms his answer. I thought, after the consultation which I had with deliberate and wise friends, that here was a common ground on which all of us could stand. I
                thought there was no member of this Convention who did not feel the obligation and duty of calling on the President for information. I supposed that when the call came from our side, on which the duty more properly devolved, our side would consent to adopt it, and I know that the other side could not refuse to do so. That is "the cat in the meal tub."
              </p>
              <p className="p-in-sp">
                I say here, with confidence and assurance, that it is the deliberate judgment of nine-tenths of the Commonwealth of Virginia that this silence is ominous, portentous, dangerous, that it ought to be broken, that it must be broken, and that, if the President continues to administer the Government without declaring his policy, it shall be broken. The gentleman from Augusta
                [<span className="persName">Mr. STUART</span>]
                , said this morning that the Executive was not commissioned by us, and that therefore he was to be treated deferentially. Non constat. The reverse precisely. He is not in our confidence; he is adverse to our opinions; he is dangerous to our institutions. We are bearing with him and forbearing, because we love the Government more than we hate him. That is the sum and substance of it.
              </p>
              <p className="p-in-sp">
                Now, I ask, deliberately, what on earth can induce the President not to answer? Who are we, the representatives of this Commonwealth of Virginia? Occupying a position the very front and eye and view of this administration, we stand, as in truth we are, the sentinels on the outer wall. We see an adverse government moving and approaching and congregating forces. I am an humble sentinel
                from <PageNumber num={348} /> Montgomery. I hail the movement. I say to the man : "Hail, stand fast, who are you—friend or foe?" I ask him to give me the countersign. Let him say "peace," and I will pass him into the encampment. Let him say "war," and we then know what is to be done. Will he not give the countersign to me, an humble and earnest sentinel
                on the watch tower? What would induce him to refuse it? Nothing but a covert movement, or a mere party combination, which a President of the United States is not justified in resorting to under any circumstances.
              </p>
              <p className="p-in-sp">A word more, Mr. President, and I have done. There is one difference between my proposition and that of the gentleman from Frederick [<span className="persName">Mr. CONRAD</span>] , or that of the gentleman from Augusta [<span className="persName">Mr. STUART</span>] . They propose that the Committee shall be appointed by the President. I propose that they shall be elected by the Convention. I proposed that because it is a common ground on which we ought all to stand, and we ought all to participate in the choice of the Committee. The gentleman from Norfolk county [<span className="persName">Mr. HOLLADAY</span>] , is not willing to trust to three men appointed by the President. He thinks that a dangerous mode of appointment, and wants the appointment to be made in a different way.</p>
              <div className="speaker" id="sp2594"><span className="persName">Mr. HOLLADAY</span>—</div>
              <p className="p-in-sp">I hope the gentleman will not regard my remark as a reflection on himself, for, I say it with perfect candor and frankness, if this resolution should pass, I would regard the appointment of the gentleman himself as absolutely essential and there is no gentleman of this body for whom I would vote more cheerfully.</p>
              <div className="speaker" id="sp2595"><span className="persName">Mr. PRESTON</span>—</div>
              <p className="p-in-sp">
                I see that the gentleman, instead of looking at the principle, is looking at the man. I am sorry for that. God forbid that I should participate in that feeling. <span className="persName">Mr. STUART</span>'S amendment proposes that the President shall communicate in any manner he may think proper—either by letter or Committee—with the Executive. My friend from Frederick
                [<span className="persName">Mr. CONRAD</span>]
                , proposes that the President shall appoint the Committee. Sir, you know, and it is unnecessary for me to say, that when I proposed the election of the Commissioners, it was through no discourtesy to you. You know, too, that it was in forgetfulness of self, for if I had offered the resolution in the usual form, parliamentary courtesy would have made me one of the members. I did not
                choose to put myself in any such position. I put myself on the broad, catholic ground, that all Virginia should require from the President, whom she was unwilling to trust, such assurances as would enable us to calm apprehensions, and as would command our concurrence and support.
              </p>
              <PageNumber num={349} />
              <p className="p-in-sp">
                I think this will do good. I believe that Mr. Lincoln will respond to the inquiry. If we go home without doing this, there will be a condemnation of our course. Sitting here with all the opportunities of commanding an answer, we will be condemned for not making inquiry on a point on which every mind in Virginia is enquiring, about which every tongue is speaking, and which every purse
                is feeling. If we cannot trust him to answer, how can we trust him to govern? If he is ignominious enough to avoid an answer, how is he noble enough to keep the bonds of the Union strong?
              </p>
              <p className="p-in-sp">
                This is a great crisis. We want the moral courage to meet it : we have the physical courage. We want that calm serenity which draws inspiration from our own bosoms. By a prompt and manly action we would dispense a beneficial influence on all around us. Let us then stand up fully and fairly. I pray to God that the members of this Convention may be wise, strenuous, firm, of
                unsubmitting souls, "to check this torrent, of a downward haste, to slavery borne, and bid us rise again in all our native strength of freedom bold." Let us come in this spirit to the question.
              </p>
              <div className="speaker" id="sp2596"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I want to give notice, that if the main question be not ordered, I shall offer an amendment to the preamble and resolution. The preamble as suggested by the gentleman from Augusta [<span className="persName">Mr. BALDWIN</span>] , on Saturday, and accepted by the gentleman from Montgomery [<span className="persName">Mr. PRESTON</span>] , recites :</p>
              <p className="p-in-sp">
                "Whereas, in the opinion of this Convention, the uncertainty which prevails in the public mind as to the policy which the General Government intends to pursue towards the seceded States, is extremely injurious to the industrial and commercial interests of the country, tends to keep up an excitement which is unfavorable to the adjustment of pending difficulties, and threatens a
                disturbance of the public peace," &amp;c.
              </p>
              <p className="p-in-sp">
                I shall move, if the main question is not ordered, to strike out in the second line the words "Federal Government"<span className="note" id="Note42"><span className="noteLabel">6</span>Mr. Wise must have meant to say "General Government."</span> and insert the words "Federal Executive." The Federal Executive cannot, I presume, be called upon to answer as to the policy of the General
                Government. The General Government consists of three departments, and we are only calling upon one, and according to the terms of this preamble, he would have to speak for Congress and the judiciary. Our intention is to call upon the Executive, and the Executive alone, for information as to its policy, that being the department which is sworn to execute the laws.
              </p>
              <p className="p-in-sp">
                Then, again, the resolution of the gentleman from Montgomery <PageNumber num={350} /> reads : "Resolved, 3. That a committee of three delegates be appointed by this Convention to wait upon the President of the United States, present to him these resolutions and respectfully ask of him to communicate to this Convention the policy"—embracing the general
                terms—"which the authorities of the Federal Government"—again-- "intend to pursue in regard to the Confederate States."
              </p>
              <p className="p-in-sp">
                Now, I shall propose to strike out the words "authorities of the Federal Government intend," and insert the words "Federal Executive intends," so that it will read, "That a committee of three delegates be appointed by this Convention to wait upon the President of the United States, present to him these resolutions, and respectfully ask of him to communicate to this Convention the
                policy which the Federal Executive intends to pursue in regard to the Confederate States."
              </p>
              <div className="speaker" id="sp2597"><span className="persName">Mr. PRESTON</span>—</div>
              <p className="p-in-sp">I accept the amendments.</p>
              <div className="speaker" id="sp2598"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Then, I want, in order to remove certain objections to the resolution which exists in my mind, to add, "and particularly in respect to the forcible occupation of the various forts within their limits, by the troops of the United States," so that the resolution will read—</p>
              <p className="p-in-sp">
                "Resolved, That a committee of three delegates be appointed by this Convention to wait upon the President of the United States, present to him these resolutions, and respectfully ask of him to communicate to this Convention the policy which the Federal Executive intends to pursue in regard to the Confederate States, and particularly in respect to the forcible occupation of the
                various forts within their limits by the troops of the United States."
              </p>
              <p className="p-in-sp">And in explanation of this, I ask leave of the House, sir, through you, to say a word.</p>
              <p>There being no objection,</p>
              <div className="speaker" id="sp2599"><span className="persName">Mr. WISE</span> said :</div>
              <p className="p-in-sp">Mr. President, the gentleman from Augusta [<span className="persName">Mr. STUART</span>]
                , remarked that he will not consent to go into a cross-examination of the President as to the policy which he means to pursue in regard to the seceded States. I concur with him in the extent of his objections, but do not concur with him, however, in the application of his objections to any remarks I have heard on this floor to-day. It cannot be inferred, from what I said, at all
                events, that I propose to interrogate the President of the United States so as to involve us in any confusion of questions and answers. But I do wish to interrogate him as to one point. I do wish your resolution to indicate one point. What is that, sir? He is now occupying the forts within the jurisdiction of the seceded States. He holds now Fort Sumter; he holds the
                <PageNumber num={351} /> Tortugas; he holds Fort Pickens; he holds the Barrancas. Sir, I repeat it, whether he has troops there or not, he holds to the idea that he has a right to put troops there. I infer from his continuing to hold these forts, that he intends to persist in holding them. Well, now, sir, I don't go into the question at all of the right
                to hold them or the pretext to hold them, I only look at one fact which inevitably follows, and it is this, that if he persists in holding these forts, after these States have seceded—after they have resumed the powers granted by them under the Constitution of the United States—he holds them at the peril of war; he holds them at the risk, Mr. President, of resistance by the seceded
                States—he holds them, in my mind, under a momentary apprehension that South Carolina, that Alabama, that the seven States combined, with their confederated power, will attempt to expel the forces of the United States. If so, what does it matter to you and me, sir, whether he makes the attack ; whether he commits the aggression; whether he brings on a collision; whether he is the one
                to initiate the war. Does it make any difference with you or me, sir, or to the people of Virginia, whether he commences the war or whether the Confederate States make the attack? In either event, they can say to him, "You have commenced the war—you first committed hostilities by holding these forts, within my sovereign jurisdiction." He will say, "No; I hold them under a general
                power from you; you have not been released from your obligations to the Federal authority; I have not admitted the right of a State to secede; I am compelled under my oath to execute the laws of the United States, and if you attempt to expel the forces of these United States, you commence the aggression —I am defending only the power which I am sworn to protect and exercise." Sir, is
                not that war? You may say that he is right; you may say that this is not aggression. He will say so; but I say it is war or it is nothing; and let me say, sir, that there are members on this floor who hold that this is not aggression; that that is not coercion. One gentleman has avowed it already openly upon this floor. There are other gentlemen who hold the same opinions, among whom
                is the chairman of the Committee of twenty one. He holds to the doctrine that the Federal Government have a right to hold the Tortugas. Am I right in that or not?
              </p>
              <div className="speaker" id="sp2600"><span className="persName">Mr. CONRAD</span>, of Frederick—</div>
              <p className="p-in-sp">I am not aware that I had occasion to state anything about my opinion upon the subject in the Convention. I don't recollect that I have.</p>
              <div className="speaker" id="sp2601"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                Have I made a mistake then? I can only say that I went to the gentleman and asked the question in order to understand <PageNumber num={352} /> the effect of the resolution. I did not ask it in confidence, nor did I receive his answer in confidence. But he expressed the opinion, as I understood, when asked the question, that holding these forts was not
                aggression or coercion.
              </p>
              <div className="speaker" id="sp2602"><span className="persName">Mr. CONRAD</span>—</div>
              <p className="p-in-sp">I took it for granted the gentleman referred to something that transpired on the floor of the Convention.</p>
              <div className="speaker" id="sp2603"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">No.</p>
              <div className="speaker" id="sp2604"><span className="persName">Mr. CONRAD</span>—</div>
              <p className="p-in-sp">I am not here to answer any inquiry in relation to matters which may have occurred out of Convention, nor do I desire to consume the time of this body by explaining in Convention what I may have said out of it.</p>
              <div className="speaker" id="sp2605"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I hope that gentlemen will declare their opinion here that they will come out and say what they think is coercion, what they think is aggression, and what not. I hope that we will not act in the dark. You ask the President of the United States, what is your policy peace or war? "Oh, peace, sir peace;" he will say to the gentleman from Montgomery [<span className="persName">Mr. PRESTON</span>]
                , when he goes to Washington. Yet enquire more specifically, and he will avow "I have a right to occupy the Tortugas; I have a right to occupy Fort Pickens; if any body attacks me, I am bound by my oath to defend these Forts; I cannot recognize the act of the seceded States." He has told you officially "if they attack me, they are rebels, and I shall defend myself." He doesn't call
                that war he calls it defence. Some gentlemen here maintain that that is defence —I call it aggression —I call it coercion —I call it war; and, therefore, I want to ask him a specific question : "Do you mean to hold those forts in the seceded States?" and I would say "if you do, we know then what you mean, for we have reason to know that the seceded States mean to attack you in the
                forts." And when gentlemen say that they do not intend to cross-examine the President, I hope they will unite with me in the converse sentiment that they do not mean either to whitewash him! that they do not mean that we shall delude our people with the idea of peace when war is imminent.
              </p>
              <div className="speaker" id="sp2606"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">I understood the gentleman to be engaged in making an explanation by the courtesy of the Convention. If this system of attack and defence is to go on, I desire that all shall be in the ring.</p>
              <div className="speaker" id="sp2607"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">So do I. I should like to have a full ring and give all a chance. If the gentleman objects to the courtesy, I will withdraw.</p>
              <div className="speaker" id="sp2608"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">No, sir, I only desire if this system of attack is to be pursued that the courtesy of reply will be given. I do not object to the gentleman's receiving this courtesy if the same courtesy is extended to others.</p>
              <PageNumber num={353} />
              <div className="speaker" id="sp2609"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair desires to enforce the rules which the Convention has given to him for his government.</p>
              <div className="speaker" id="sp2610"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I will relieve the Chair.</p>
              <div className="speaker" id="sp2611"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">I hope the gentleman will permit the Chair to finish his remarks. The Convention by universal consent agreed that the gentleman should go on and make his explanation. All power upon the subject was therefore taken out of the hands of the Chair, and the gentleman is at liberty to go on.</p>
              <div className="speaker" id="sp2612"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I will take the gentleman from Augusta [<span className="persName">Mr. BALDWIN</span>] , at his word, and wait to clear the deck of the previous question and then go into the ring with him.</p>
              <div className="speaker" id="sp2613"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">I will ask the permission of the Convention to read an extract from the New York Herald, which the gentleman from Harrison [<span className="persName">Mr. CARLILE</span>] , omitted to read a while ago, when he quoted from that paper.</p>
              <div className="speaker" id="sp2614"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The gentleman can do so only by general consent.</p>
              <div className="speaker" id="sp2615"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">I object. If it is a personal matter to the gentleman I will withdraw my objection.</p>
              <div className="speaker" id="sp2616"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">I only ask a courtesy which has been extended to every other gentleman on this floor.</p>
              <div className="speaker" id="sp2617"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">I desire that the gentleman shall have leave to proceed; but I shall ask that the same courtesy be extended to me.</p>
              <p>Cries of leave, leave.</p>
              <div className="speaker" id="sp2618"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">The gentleman from Harrison read this extract to the Convention—</p>
              <div className="speaker" id="sp2619"><span className="persName">Mr. HALL</span>, of Marion—</div>
              <p className="p-in-sp">I object, sir, to the gentleman's proceeding. Courtesy will eat this body up, and destroy the country.</p>
              <div className="speaker" id="sp2620"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The gentleman was proceeding with his remarks when the objection was made, and he is, therefore, entitled to the floor.</p>
              <div className="speaker" id="sp2621"><span className="persName">Mr. CRITCHER</span>—</div>
              <p className="p-in-sp">I renew my motion to adjourn.</p>
              <div className="speaker" id="sp2622"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">You have no right to make a motion while I have the floor.</p>
              <div className="speaker" id="sp2623"><span className="persName">Mr. HALL</span>, of Marion—</div>
              <p className="p-in-sp">I rise to a question of order. I was protesting against the gentleman's proceeding, and there could not, therefore, be general consent to his proceeding with his remarks. I make that point of order.</p>
              <div className="speaker" id="sp2624"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair stated that the gentleman from Amelia [<span className="persName">Mr. HARVIE</span>] , could not proceed except by general consent. I heard no objection at the time. He took the floor, commenced his remarks, and the Chair decides that it is too late to object.</p>
              <PageNumber num={354} />
              <div className="speaker" id="sp2625"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">The gentleman from Harrison [<span className="persName">Mr. CARLILE</span>]
                took occasion to make a fling at that portion of this Convention to which I am attached, by quoting some remarks from the New York "Herald." He read from a communication in the "Herald," dated at Washington, such portion as was necessary to suit his purpose, while he withheld the balance, though requested by me to read on. Now, I want to call the attention of this house to the
                language of this communication. I want, sir, to call the witness back to court, and to present all that the witness adduced by what the gentleman has to say. I want to ascertain who it is that is engaged in these sensation movements to which the gentleman from Harrison referred. I want to ascertain who it is that is in correspondence with the head of the Black Republican
                party—whether they are persons connected with the party to which I belong or persons identified with the principles and policy of the gentleman from Harrison. Here is what is said :
              </p>
              <p className="p-in-sp">"A special meeting of the Cabinet was called to-night at half past ten, to give a hearing to a select committee from the Virginia State Convention."</p>
              <p className="p-in-sp">That far the gentleman read, and I could not make him read any farther.</p>
              <p className="p-in-sp">Now, sir, I read on—</p>
              <p className="p-in-sp">
                "The Committee arrived here to-night, and it is believed that they not only laid before the Cabinet the exact state of affairs in Virginia, where there exists a strong revolutionary feeling, but they bring a letter from Letcher, expressing a hope that the administration will take some prompt action to stay the tide of revolution and restore fraternal feeling in all the States."
              </p>
              <p className="p-in-sp">
                Now, who were that Committee? Here it is, sir. There are more engaged in the efforts to produce these difficulties, than that secession organ, the New York "Herald," as the gentleman from Harrison is pleased to designate it. Here is a statement simultaneously made upon the subject by the Philadelphia "Inquirer," a paper not published in New York but Philadelphia. There is evidently a
                mistake in saying that a Committee of delegates of this Convention were in Washington; but let us see who they were. Here they are—
              </p>
              <p className="p-in-sp">"Senator Chandler and Colonel Segar, members of the Virginia Convention, with John Minor Botts, are here from Richmond, and are having an interview with Mr. Lincoln, urging peace and deprecating activity in the War Department."</p>
              <div className="speaker" id="sp2626"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">-I would ask the gentleman from what paper he reads that.</p>
              <PageNumber num={355} />
              <div className="speaker" id="sp2627"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">The Philadelphia "Inquirer." These are facts. So don't call down public indignation upon me and mine, because you and yours are stated by the New York "Herald" to be implicated in what you want to implicate me in.</p>
              <p className="p-in-sp">While I am up, I wish to state that I have got a sensation dispatch which I received from Washington, since I have been upon this floor, which reads as follows :</p>
              <p className="p-in-sp">"All Southern men and nearly everybody else in Washington consider war certain and imminent. The only question is where the blow is to fall."</p>
              <div className="speaker" id="sp2628"><span className="persName">A MEMBER</span>—</div>
              <p className="p-in-sp">What is the date?</p>
              <div className="speaker" id="sp2629"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">It is dated about an hour ago.</p>
              <div className="speaker" id="sp2630"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">Mr. President, I shall not permit myself to exhibit any excitement upon this occasion. The Convention will bear with me witness that, at the time the gentleman from Amelia [<span className="persName">Mr. HARVIE</span>]
                , requested me to read the whole paragraph from which I had read, I remarked to him I had not the time to say what I desired within the ten minutes allowed me under the rules of this body. The gentleman has thought proper to connect with what I read from the New York Herald, a scrap which was in his possession and which I had never seen or heard of until he read it. I take occasion
                to say that I had never seen the article from the Philadelphia "Inquirer," mentioning the names of the three individuals to whom the gentleman from Amelia referred; and I did not know the article was in existence until he read it. Therefore, I did not omit to read that which was in my possession. I read, as the Convention will remember, so much of the paragraph in the Herald, as the
                Convention knew to be false. I did not know whether the remainder of the paragraph speaking of the action of Governor Letcher, was true or false; nor did I suppose any member of the body knew it to be false or not. I believed it to be equally false with that which I had read. I read the paragraph in question for the purpose of showing that it was the business of the press engaged in
                promoting the disruption of this Government by false statements, to keep the public mind in constant alarm; and for the purpose, also, of impressing, if I could, upon the friends of this resolution, that it was the policy which those whom I presume differed from them, had been engaged in pursuing. I think, therefore, that any attempt to convey the idea that I was suppressing, for any
                purpose whatever, any portion of that paragraph, cannot be believed by any member of this body. It was for the double purpose, as I have before stated, of showing the falsehood resorted to, and of showing the <PageNumber num={356} /> friends of this resolution, who, to judge from what they have hitherto said, have given evidence of their desire to
                preserve the Union, what course they were expected to take, if they followed the policy which had been pursued by the editor of this paper and those who were associated with him in that particular policy.
              </p>
              <div className="speaker" id="sp2631"><span className="persName">Mr. BAYLOR</span>—</div>
              <p className="p-in-sp">
                Every member of this body feels himself somewhat responsible for the vote which he will in a few moments be called upon to give. At any rate whether gentlemen do or not, I for one must answer for myself, and I say that I do feel myself accountable to my constituents for the vote I shall have to give, and for that reason I want to state the reasons which will govern me in giving my
                vote. If I know my own heart, I want to do what is right, I want to do what is best for the common welfare of the Commonwealth of Virginia and the whole country, and I have not the shadow of a doubt that the mover of these resolutions is just as honest as I am in his opinions, or as any other man can be.
              </p>
              <p className="p-in-sp">
                Mr. President, if we send this commission to Washington to wait upon the President of the United States, and ascertain from him what may be his future policy, and if it should turn out to be a peaceable one, it will be a matter of sincere rejoicing with us all. I do not know, but I may be a little too fast in saying all of us, because there are some gentlemen here who do not want to
                preserve the country, and who are in favor of breaking up the government. A favorable reply from the President, however, would be acceptable to a large majority of the Convention, I have no doubt.
              </p>
              <div className="speaker" id="sp2632"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">I rise to a point of order here. I beg the gentleman from Augusta [<span className="persName">Mr. BAYLOR</span>]
                , to believe me, when I say I have not singled him out, because he happens to be the gentleman upon whom the point of order is made. We have been discussing this question now, for five and a half hours, and, in my opinion, the whole discussion has been out of order. It is now time to stop it. Your rule says that on the call for the previous question, each member may speak for ten
                minutes, that is, he may give reasons why the call should not be sustained; but yet, he is not allowed to go into the general merits of the whole question. The object of the previous question is to stop debate upon the general merits of a question, and to get to a vote. We have now sat here and permitted that rule to be violated for five and a half hours. The whole subject has been
                thoroughly discussed, and now I raise the point of order, and ask the Chair to decide it at once, so as to put a stop to this thing.
              </p>
              <div className="speaker" id="sp2633"><span className="persName">Mr. BAYLOR</span>—</div>
              <p className="p-in-sp">It would be very unfair towards me.</p>
              <PageNumber num={357} />
              <div className="speaker" id="sp2634"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair was perfectly aware of the existence of the rule stated by the gentleman from Middlesex [<span className="persName">Mr. MONTAGUE</span>] , but the Parliamentary law expressly states that while it is not proper to go into a full discussion of the merits of the original question, yet it may be argued to a certain extent for the purpose of showing why or why not the main question should be ordered and all debate upon it closed.</p>
              <div className="speaker" id="sp2635"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">I agree to that, but he may not argue the whole question.</p>
              <div className="speaker" id="sp2636"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">
                Then comes the application of the rule. Almost every gentleman who has addressed the Chair has spoken to the point, whether or not the main question should be ordered, but then gentlemen have gone also into the general merits of the original question, so that the Chair has found it impossible to discriminate so far as to call any gentleman to order who has addressed the Convention.
                The Chair, therefore, thinks that it cannot now arrest the debate, and the gentleman from Augusta will be allowed to proceed.
              </p>
              <div className="speaker" id="sp2637"><span className="persName">Mr. BAYLOR</span> [resuming]—</div>
              <p className="p-in-sp">
                I trust I shall say nothing which I have not a perfect right to say upon this floor, and I remark, in advance, if I should happen to say any thing which seems to be out of order that it is not my intention to do so. I was saying, when interrupted, that if the answer from the President should be a favorable one, it would be glorious news for the majority of this Convention. If there
                were any reasonable, well-founded hope that such would be the result, I would vote for this resolution with all my heart. But, Mr. President, suppose it should turn out otherwise. Suppose, for some cause unknown to any of us, for some good cause, the President of the United States would not give a favorable response and the Committee should return here with the news of such
                unfavorable response —then, sir, I ask you what would be the course of this body? I have not heard any one undertake to say what would be the course of this body under such circumstances. I now put it to gentlemen to say, if that should be the case, would they not feel themselves committed to take the course that they could not otherwise take? Would they not feel themselves committed
                to take a course which might lead us to adopt what might be called war measures—a course to which I think the people of the Commonwealth of Virginia would not want to be committed at this stage of our proceedings.
              </p>
              <p className="p-in-sp">
                Now, my position is this: If I was certain I could do good by voting for this mission, I would be willing to do it; but I want to be certain. Whilst I may not be certain that I can do good by such a vote, I <PageNumber num={358} /> want, at least, to be certain that I would do no harm; and unless I can have some reasonable certainty of that, I would
                rather not act at all. We have heard gentlemen here repeatedly say, during the course of our discussions, that wherever the Commonwealth of Virginia was to go, there they would go, and that with her destiny their own destiny was linked. I want to go with the Commonwealth too, but I want to keep her right. If she goes wrong, I should have to go with her in sorrow; but if she goes
                right, I can go with her with joy and pleasure. I say, sir, for these reasons, because I can see no reasonable and fair hope that good may be done and because I am not satisfied that harm would not result from it, I shall feel constrained to vote against sending this mission to Washington.
              </p>
              <div className="speaker" id="sp2638"><span className="persName">Mr. HALL</span>, of Wetzel—</div>
              <p className="p-in-sp">
                I shall occupy but a moment's time. Permit me to say, Mr. President, so far as the previous question is concerned, so far as the question in the resolution is involved, I care but very little about it, one way or the other. I do not care whether the gentlemen who may constitute this committee ever speak or have any communication with Abe Lincoln or not. But I can never yield the
                principle that a sovereign State has the right to demand of the President, to demand of her servant, what that servant is going to do. I would not take back my vote with the immortal forty-five for the world. It is a fact the Convention had better realize, that the flood-tide which is now rising and increasing every hour and moment with increased volume and momentum, will sweep all
                the riff-raff and stuff down into the vortex of oblivion, never to rise again. Appreciating the motives of the distinguished gentleman from Montgomery
                [<span className="persName">Mr. PRESTON</span>] , I will be compelled to sustain him in the resolution he has offered; but being one of the immortal forty-five who want to go to Dixie's land, I say the responsibility is with the majority, whether the result that will follow the action now contemplated be for weal or woe.</p>
              <div className="speaker" id="sp2639"><span className="persName">Mr. CARTER</span>—</div>
              <p className="p-in-sp">I rise merely to state that inasmuch as a correspondence between the Governor and the Executive of the United States has been alluded to this morning, I am authorized to say that no such correspondence has taken place. I move, sir, that the Convention do now adjourn.</p>
              <p>Cries of "no, no!"</p>
              <div className="speaker" id="sp2640"><span className="persName">A MEMBER</span>—</div>
              <p className="p-in-sp">Suppose we adjourn now, do we adjourn till tomorrow?</p>
              <div className="speaker" id="sp2641"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">Yes, sir.</p>
              <div className="speaker" id="sp2642"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">Will my friend withdraw the motion for a moment in order to enable me to have the journal corrected?</p>
              <PageNumber num={359} />
              <div className="speaker" id="sp2643"><span className="persName">Mr. CARTER</span>—</div>
              <p className="p-in-sp">You can do it to-morrow morning.</p>
              <div className="speaker" id="sp2644"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">I will renew the motion to adjourn. It will not take a minute to make the correction.</p>
              <p><span className="persName">Mr. CARTER</span> yielded.</p>
              <div className="speaker" id="sp2645"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">Mr. President, the resolutions which I submitted on Saturday are improperly described in the journal. I beg leave to ask that the error shall be corrected.</p>
              <div className="speaker" id="sp2646"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">It shall be done, sir.</p>
              <div className="speaker" id="sp2647"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">I now renew the motion to adjourn.</p>
              <p>A division was taken on the question, and by a vote of 42 in the affirmative, and 56 in the negative, the Convention refused to adjourn.</p>
              <div className="speaker" id="sp2648"><span className="persName">Mr. MORTON</span>, of Orange—</div>
              <p className="p-in-sp">I move that this Convention take a recess till 5 o'clock, or to half past four, if that hour would be preferred.</p>
              <div className="speaker" id="sp2649"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">I rise to a question of order. By the 33d rule I understand that no motion can be entertained by the Chair, pending a call for the previous question, except a motion to adjourn.</p>
              <div className="speaker" id="sp2650"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The previous question has not been ordered by the Convention. This debate of ten minutes each, which is allowed upon the question, may keep us here till Saturday night. Of course the Convention must have the power to take a recess or to adjourn.</p>
              <div className="speaker" id="sp2651"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">I now move that the Convention take a recess till 5 o'clock.</p>
              <p>A division was called for, and by a vote of 47 to 40 the Convention, at 3 o'clock, took a recess until 5 o'clock, P. M.</p>
            </div>
            <div className="section" id="vsc1965.v3.2.6.2">
              <h3><span className="headingNumber">1.6.2. </span><span className="head">AFTERNOON SESSION</span></h3>
              <p>The Convention re-assembled at 5 o'clock, the President in the Chair.</p>
              <div className="speaker" id="sp2652"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The pending question is, shall the main question be now put on the resolutions offered by the gentleman from Mont gomery [<span className="persName">Mr. PRESTON</span>] ?</p>
              <p><span className="persName">Mr. BURDETT</span> called for the ayes and noes.</p>
              <p>The ayes and noes were then called on the motion to demand the previous question. The motion was carried in the affirmative, ayes 59, noes 51, as follows :</p>
              <p>
                YEAS-Messrs. Ambler, James Barbour, Blakey, Bouldin, Branch, Cabell, Caperton, Cecil, Chambliss, Chapman, Coffman, Conn, Cox, Deskins, Dorman, Echols, Fisher, Flournoy, Garland, Graham, J. Goode, Jr., Hale, Addison Hall, C. Hall, Hammond, Holcombe, Hunton, Isbell, Kent, Kindred, Lawson, Leake, C. K. Mallory, J. B. Mallory, Marr, Miller, Montague, Morris, Morton, Neblett, Orrick,
                Parks, Preston, Randolph, Richardson, Rives, Robt. E. Scott, <PageNumber num={360} /> Seawell, Sheffey, Slaughter, Speed, Staples, Strange, Sutherlin, Tayloe, Tredway, Waller, Wilson, Wysor-59.
              </p>
              <p>NAYS-Messrs. Janney [President]
                , Armstrong, Aston, Baldwin, A. M. Barbour, Baylor, Boggess, Brent, Burdett, Burley, Campbell, Carlile, Carter, R. Y. Conrad, Couch, Custis, Dent, French, Fugate, Gillespie, Gray, Goggin, Ephraim B. Hall, Haymond, Hoge, Holladay, Hubbard, Hughes, Jackson, P. C. Johnston, Lewis, McGrew, Marshall, Masters, Moffett, Moore, Osburn, Patrick, Pendleton, Porter, Price, Pugh, Wm. C. Scott,
                Sharp, Sitlington, A. H. H. Stuart, Summers, White, Wickham, Willey, Wise-51.
              </p>
            </div>
            <div className="section" id="vsc1965.v3.2.6.3">
              <h3><span className="headingNumber">1.6.3. </span><span className="head">EXPLANATIONS OF VOTES</span></h3>
              <div className="speaker" id="sp2653"><span className="persName">Mr. BROWN</span>, of Preston—</div>
              <p className="p-in-sp">Mr. President, I paired of with <span className="persName">Mr. SOUTHALL</span>, who is unwell. Otherwise I would vote no.</p>
              <div className="speaker" id="sp2654"><span className="persName">Mr. WHITE</span>, of Norfolk—</div>
              <p className="p-in-sp">I am requested, sir, by <span className="persName">Mr. KILBY</span>, of Nansemond, to state that he is indisposed to-day.</p>
              <div className="speaker" id="sp2655"><span className="persName">Mr. HULL</span>, of Highland—</div>
              <p className="p-in-sp">I am requested by <span className="persName">Mr. MCNEIL</span>, of Pocahontas, to state that he is indisposed.</p>
              <p><span className="persName">Mr. WISE</span>, of Princess Anne, announced the indisposition of <span className="persName">Mr. TYLER</span>, of Charles City.</p>
              <div className="speaker" id="sp2656"><span className="persName">Mr. NELSON</span>, of Clarke—</div>
              <p className="p-in-sp">I have paired off with <span className="persName">Mr. WOODS</span>, of Barbour, upon the subject of Federal Relations, and I suppose this comes within my contract. Therefore I cannot vote.</p>
              <div className="speaker" id="sp2657">After the vote had been announced, <span className="persName">the PRESIDENT</span> said :</div>
              <p className="p-in-sp">It is decided that the previous question shall now be put.</p>
              <div className="speaker" id="sp2658"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">I call for the ayes and noes upon the main question. Before the question is taken, I ask that the resolution may be reported.</p>
              <p>The Clerk reported the preamble and resolution.</p>
              <p>
                The main question was then put, and the preamble and resolution were carried in the affirmative, ayes 63, noes 57, as follows :<span className="note" id="Note43"><span className="noteLabel">7</span>The Journal lists 63 affirmative votes, omitting Borst from the list as given in the Proceedings.</span>
              </p>
              <p>
                YEAS-Messrs. Ambler, James Barbour, Blakey, Borst, Bouldin, Branch, Cabell, Caperton, Cecil, Chambliss, Chapman, Coffman, Conn, Ro. Y. Conrad, R. H. Cox, Critcher, Deskins, Echols, Fisher, Flournoy, Garland, Gillespie, Graham, Gregory, John Goode, Jr., Hale, Addison Hall, Cyrus Hall, Hammond, Harvie, Holcombe, Hunton, Isbell, Kent, Kindred, Lawson, Leake, C. K. Mallory, J. B.
                Mallory, Marr, Miller, Montague, Morris, Morton, Neblett, Orrick, Parks, Preston, Randolph, Richardson, Ro. E. Scott, Wm. C. Scott, Seawell, Sheffey, Slaughter, Speed, Staples, Strange, Sutherlin, Tayloe, Tredway, Waller, Wilson, Wysor-63.
              </p>
              <p>NAYS-Messrs. Janney [President]
                , Armstrong, Aston, Baldwin, A. M. Barbour, Baylor, Berlin, Blow, Boggess, Brent, Burdett, Burley, Campbell, Carlile, Carter, Clemens, C. B. Conrad, Couch, Custis, Dent, Dorman, Early, French, Fugate, Gray, Goggin, E. B. Hall, Haymond, Hoge, Holladay, Hubbard, Hughes, Jackson, P. C. Johnston, Lewis, McGrew, Marshall, Masters, Moffett, Moore,
                <PageNumber num={361} /> Osburn, Patrick, Pendleton, Porter, Price, Pugh, Rives, Sharp, Sitlington, A. H. H. Stuart, C. J. Stuart, Summers, Tarr, White, Wickham, Willey, Wise-57.
              </p>
            </div>
            <div className="section" id="vsc1965.v3.2.6.4">
              <h3><span className="headingNumber">1.6.4. </span><span className="head">EXPLANATION OF VOTES</span></h3>
              <div className="speaker" id="sp2659"><span className="persName">Mr. BROWN</span>, of Preston—</div>
              <p className="p-in-sp">I wish to say that I have paired off with <span className="persName">Mr. SOUTHALL</span>, of Albemarle, who is not very well to-day. Otherwise I would vote no.</p>
              <div className="speaker" id="sp2660"><span className="persName">Mr. WHITE</span>, of Norfolk—</div>
              <p className="p-in-sp">I am requested by <span className="persName">Mr. WHITFIELD</span>, of Isle of Wight, to say that he is sick. If he was here he would vote no.</p>
            </div>
            <div className="section" id="vsc1965.v3.2.6.5">
              <h3><span className="headingNumber">1.6.5. </span><span className="head">APPOINTMENT OF COMMISSIONERS</span></h3>
              <p>After the announcement of the vote,</p>
              <div className="speaker" id="sp2661"><span className="persName">The PRESIDENT</span> said :</div>
              <p className="p-in-sp">The resolution which has just been adopted, provides that the Convention shall appoint three Commissioners to go to Washington, and that business is now in order.</p>
              <div className="speaker" id="sp2662"><span className="persName">Mr. SCOTT</span>, of Fauquier—</div>
              <p className="p-in-sp">I suppose there are various modes of making the appointment. If candidates are to be put in nomination, I beg leave to nominate the gentleman from Montgomery, who presented the resolutions.</p>
              <div className="speaker" id="sp2663"><span className="persName">A MEMBER</span>—</div>
              <p className="p-in-sp">Name the three commissioners at once.</p>
              <div className="speaker" id="sp2664"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">Under the rule there can be but one vacancy filled at a time. Gentlemen, there is placed in nomination for the office of commissioner, under this resolution, WM. BALLARD PRESTON, of Montgomery. All who are in favor of his appointment will say aye.</p>
              <p>A unanimous response in the affirmative confirmed his appointment.</p>
              <div className="speaker" id="sp2665"><span className="persName">Mr. SUMMERS</span>—</div>
              <p className="p-in-sp">
                Mr. President, I rise, sir, for the purpose of making another nomination. As the gentleman from Montgomery, who has just been appointed as a commissioner, has himself filled a place in the Cabinet, under a former Administration, I rise to propose the name of another gentleman who held a like situation. I move to appoint ALEXANDER H. H. STUART, of the county of Augusta. Then you will
                have two gentlemen who have heretofore held Cabinet appointments.
              </p>
              <div className="speaker" id="sp2666"><span className="persName">Mr. STAPLES</span>—</div>
              <p className="p-in-sp">I nominate R. L. MONTAGUE, of the counties of Matthews and Middlesex.</p>
              <div className="speaker" id="sp2667"><span className="persName">Mr. SPEED</span>—</div>
              <p className="p-in-sp">I rise, sir, to make a nomination that I am sure I need not say one word in approval of. The gentleman is well known to every member of this body and throughout the United States. I nominate R. E. SCOTT, of the county of Fauquier.</p>
              <div className="speaker" id="sp2668"><span className="persName">Mr. SCOTT</span>, of Fauquier—</div>
              <p className="p-in-sp">I beg leave to say that circumstances will make it impossible for me to leave the city, and, therefore, I cannot serve on the Commission.</p>
              <PageNumber num={362} />
              <div className="speaker" id="sp2669"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">
                It is very evident, sir, from the proceedings of this body thus far, that there are three very distinctly marked parties here. One, the extreme secession party, to which I belong; the second, the middle party, to which my friend from Montgomery, who has just received an appointment as one of these Commissioners belongs; and the third is a party a little lower down.
              </p>
              <div className="speaker" id="sp2670"><span className="persName">A MEMBER</span>—</div>
              <p className="p-in-sp">A little higher up.</p>
              <div className="stage it">[Laughter.]</div>
              <div className="speaker" id="sp2671"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">
                No, sir, a little lower down. I think in the selection of these Commissioners there ought to be one Commissioner taken from each of these parties. I am unwilling to represent the party to which I belong; and, therefore, I respectfully decline the nomination. I hope another gentleman, who has more experience than I have, will be selected. I have another reason for declining the
                appointment. I would be perfectly willing to discharge any duty that this Convention, or any other proper authority, should see fit to impose upon me, consistent with what was just and right and honorable; but I will not consent that this Convention shall send me; and unless they bind me, and send me by force, they cannot get me to go to Washington, to ask favors of Mr. Lincoln,
                either in an official or in any other capacity.
              </p>
              <div className="speaker" id="sp2672"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">I rise for the purpose of calling the attention of the committee to the constitution of this body, which has been very plainly indicated by the gentleman from Middlesex [<span className="persName">Mr. MONTAGUE</span>]
                . We have what are called secessionists; we have the middle men; and we have the extreme Union men; and I think that in the delegation to Washington, it is eminently proper that every branch of this Convention should be represented. By unanimous consent, the mover of this resolution has been nominated and appointed. He is a representative of the middle party. There has been a
                nomination of a gentleman belonging to the extreme Union party—a party which, if I were as courteous as gentlemen are to the secession party, which they denominate as "precipitationists," I should call the "submission party." I will not call them, however, by that name. I call them the "extreme Union party." The gentleman from Augusta belongs to that wing of the Union party. I have a
                choice, however, from that wing of the party; and I now place in nomination, in opposition to the gentleman from Augusta, one who is as extreme as he is, but for whom I will more readily vote. I nominate the gentleman from Rockbridge-<span className="persName">SAMUEL MCDOWELL MOORE</span>.
              </p>
              <div className="speaker" id="sp2673"><span className="persName">Mr. MOORE</span>, of Rockbridge—</div>
              <p className="p-in-sp">
                I trust the House will concur in the nomination of my friend, <span className="persName">Mr. STUART</span>, of Augusta. I have no desire <PageNumber num={363} /> to go to Washington upon this commission. I do not know how the thing is to be conducted there. If I am to take notes of all the conversation that takes place in my presence or anything of that
                sort, I cannot do it. But upon personal grounds, I prefer the nomination of my friend from Augusta
                [<span className="persName">Mr. STUART</span>] .</p>
              <div className="speaker" id="sp2674"><span className="persName">Mr. SCOTT</span>, of Fauquier—</div>
              <p className="p-in-sp">
                I beg leave to say that there must be a misapprehension about the terms of the resolution which has been adopted. Gentlemen seem to think that we are raising a committee to be sent to Washington for the purpose of catechising the President. I have no thought of such a proceeding myself. I take it for granted that gentlemen labor under this misapprehension more from what has been said
                upon the floor during the discussion than from what appears in the resolution itself. As I understand it, the duty of the committee will be to proceed to Washington and respectfully present the resolution, and so far as their commission from this body is concerned, they will have but to present that resolution and bring back the reply of the President, if he sends one. As to their
                being charged with the duty of catechising the President, of taking notes of what he says, and of reporting them to this body, I should be the last man in this House to give my consent to anything like it.
              </p>
              <p className="p-in-sp">While I am up, I take occasion to say, that I cordially concur in the nomination made by the gentleman from Kanawha, and trust it will be the pleasure of the House to concur in the selection of the gentleman from Augusta.</p>
              <div className="speaker" id="sp2675"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                I am not willing that this resolution should go out with the interpretation against its express terms, which has been put upon it by the gentleman from Fauquier. I have voted against it. I would have inserted in it more direct and specific enquiries to be addressed to the President. Yet, the resolution has not only directed the Commissioners to go to the President of the United
                States, and present to him this preamble and resolution, but also to ask him respectfully to communicate to this Convention in regard to the policy of this Administration towards the seceded States. Thus, the resolution not only directs enquiries, but those enquiries must cover all the grounds of his policy, in all respects, towards the seceded States. According to the terms and
                letter of the resolution, the committee are required to ascertain the policy of the Administration as to the free navigation of the Mississippi ; as to the free navigation of the Gulf of Mexico, and as to the dozen other matters, embracing the entire policy of the Executive towards the seceded States.
              </p>
              <PageNumber num={364} />
              <p className="p-in-sp">
                Now, sir, as to this idea of catechising the Executive or of cross-questioning him—gentlemen here may use terms that will cast opprobrium upon what, fairly considered, may be a very proper proceeding. It is for us to say, whether in breaking the matter to the Executive, the Committee are to ask for the precise definite information that is to be useful to us or not. If you mean to ask
                for information, to define the information you want in your resolution, is what I think you ought to have done. But you have not done it. You have left it as vague as the whole horizon of enquiry. You start at this centre, the capital of Virginia, and send this Committee to diverge upon the whole world of Executive policy in respect to the Confederated States. And they are not only
                to get at that information by going in person and presenting this resolution, but they are to go in person and ask the President to communicate his policy. They are not to report "private conversation," but to ask the President to report, officially to this Convention. The conversation which your Committee will hold with the President, will not be "private." It will be official; and
                can be reported to us without indelicacy or indecorum. Sir, I voted against the resolution, and have been sitting here watching to see the difficulty that comes out of the very letter of it. Now appoint your committee, and then you have got to decide other questions. You have got to decide the question, who is to ask the President for the information. Is it to be by a majority of
                two? Is the committee, as a committee, to do it, or is any one of the committee to ask the question? I tell gentlemen that I once officiated in a matter of this sort, and there is no indelicacy at all in going to the President officially, not for the purpose of private conversation, but to announce to him that you have come in an official character. You ask him a question, and you
                may repeat his answer to him. I once served exactly on such a committee. I would not serve on this. I have had to do that very thing with a President of the United States. I was assigned the duty of taking down, for a whole delegation, his language; of taking them aside to report it to them; of having it approved by them, in order that it might be recorded, and taken back to the
                President himself, to be confirmed by him. And I tell gentlemen again, that there is no difficulty nor indelicacy about the matter at all. One of the most valued papers that I own now, upon earth, is an entire report of a communication with the lamented President Harrison, in a matter of very grave importance; no less a matter than the policy of his administration in respect to the
                formation of his Cabinet. And there was no difficulty about conducting the mission, none whatever. I have <PageNumber num={365} /> now in my possession, in my own archives at home, and could produce it this authoritative paper, which I value as much as any I possess. You are to go there and announce yourself as a committee; you present as your
                credentials this preamble and resolutions; you show them as authority for asking questions, and you do ask them. Sir, if I were to go there, I would be sure to ask him, "Do you consider that you are bound, under oath, to hold on to these forts? Is it your intention, your policy to hold on to these forts at the risk of collision with the States claming jurisdiction over them?" If he
                answers me, "Yes, sir," I should report his answer to you. If he answered me "no," I should report that answer to you. But, sir, I should not deem myself as having fulfilled my duty under this resolution if I should go there and ask, "What is your policy?" or simply hand him this resolution, asking him to indicate his policy. Suppose he were to say that his policy was peace, and
                nothing but peace, that he was merely executing his duty, that he did not intend to attack anybody, that he only intended to protect and defend the property of the United States? Why, sir, his language, brought back to you, would be delusive. If he intends to hold on to these forts, if he intends to risk civil war, by an attempt to retain or re-capture the forts, that is not a peace
                policy. I was asking, this morning, when I was interrupted, whether he would attempt to hold a fort, or drive a State out of a fort within its own jurisdiction, the occupation of which that State would control? Sir, it would be war, and it would be a war in which this State, more than any other, is directly interested. It would be a war that you and I could not control. I say his
                answer in such a case would be delusive to your constituents and mine. I will not consent to vote for any resolution here that may be answered delusively. He may, when gentlemen ask a question, beg to be excused from answering that question, on the ground that it is incompatible with the public interest. As long as you ask him for his entire policy, for the whole field of policy of
                the Executive in respect to these States, he may make that the ground work, and very properly of returning you no answer at all. He may say that it would require an answer longer than a message. The Committee are simply directed to ask him to indicate his policy. To whom? To the Convention. How? Why through your Committee of course. Do you expect him, Mr. President, to send you a
                message? The message of the President of the United States is directed to the Congress of the United States of America. He is required under oath to give them information when called for. How will the message to this Convention be addressed? What an <PageNumber num={366} /> extraordinary thing it would be for him to answer your resolution by sending a
                message to <span className="persName">JOHN JANNEY</span>, Esq., President of the Convention of the people of the State of Virginia. Then, sir, he has to answer to the members of the Committee; he has to answer questions which they ask and which you direct to be asked and desire to be answered. Yet, the gentleman from Fauquier
                [<span className="persName">Mr. SCOTT</span>] , rises and tells us, after voting for this resolution, that if there had been any such idea in it he would not have voted for it at all. I did not vote for it.</p>
              <div className="speaker" id="sp2676"><span className="persName">Mr. CONRAD</span>, of Frederick—</div>
              <p className="p-in-sp">
                Before I cast my vote for the gentleman who has been nominated, the gentleman from Augusta, which I shall do with a great deal of pleasure, in consequence of something which has been said upon this floor, I deem it proper in fairness to state, that I cannot assent to the propriety or necessity of constituting this committee upon the idea of the representation of parties in this
                Convention. I wish, in order that my vote at least may not be misunderstood, to say now, that I can vote for no member of this committee who I am well assured desires ardently to preserve the Union and the constitution of the United States. I hope, therefore, it will be distinctly understood, in order to relieve myself from any vote I may subsequently be expected to give, that while
                I shall vote for the gentleman from Augusta it will not be upon the ground that he is the representative of any particular party, nor shall I vote for any of the three members of that committee upon any such ground.
              </p>
              <div className="speaker" id="sp2677"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">
                I wish to ask the gentleman one question. I desire to know whether he means to exclude from representation upon this committee, or from any other representation in this body, gentlemen whom he calls "precipitationists," or secessionists? I want to know whether we are to be excluded in representing the State of Virginia, in any and every capacity, by the dominant party in this House?
                Now, sir, I voted for the gentleman from Montgomery
                [<span className="persName">Mr. PRESTON</span>] , as a member of this committee. I intended to vote, and shall vote, notwithstanding what the gentleman has said, for the gentleman from Augusta [<span className="persName">Mr. STUART</span>]
                . Then I wish to see how many of the dominant party in this House will follow the example of the Chairman of the principal committee of this body, in excluding and cutting off from the service of the State of Virginia, gentlemen who acknowledge as much fealty to that State, gentlemen who are as true to its interests as he or any other member of the Union party in this House or in
                this world.
              </p>
              <p className="p-in-sp">
                I take it that those who go there will endeavor to represent the interests of Virginia, and to ascertain what the President of the United <PageNumber num={367} /> States is going to do affecting the interests of Virginia. I want to know from the gentleman from Frederick, whether, if Virginia outlives this Confederacy—and if she does not, she will have
                but a very short time, in my opinion—he would still exclude those men who differ with him as to the time when Virginia should go out of the Union?
              </p>
              <div className="speaker" id="sp2678"><span className="persName">Mr. BRANCH</span>—</div>
              <p className="p-in-sp">
                I rise to a question of order. We are here to elect three delegates to wait on Mr. Lincoln; and yet gentlemen do not speak to the qualifications or disqualifications of nominees, but are taking a wide latitude of argument. We have passed the resolution, and I presume that the gentlemen nominated are qualified to carry out its meaning. If they want any counsel, I will give them the
                best advice I can. I am opposed to this irregular way of doing business out of order, and therefore I call the gentlemen to order.
              </p>
              <div className="speaker" id="sp2679"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">
                The occupant of the Chair, while he is in the Chair, knows nothing about parties. Out of it he has his own opinions and, at the proper time, will express them. But gentlemen on the floor avow that there are parties here, and the Chair understands them to be now discussing the question whether it be right and proper that the various sentiments entertained in this body shall be
                represented in this Committee. The Chair, therefore, thinks that to that extent the debate is in order.
              </p>
              <div className="speaker" id="sp2680"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">Mr. President, I shall sustain with the greatest pleasure the nomination of the gentleman from Augusta [<span className="persName">Mr. STUART</span>]
                . I think that gentlemen forget the dignity due to themselves as representatives of the sovereignty of Virginia, when they undertake to characterize the parties here, assuming a high position to themselves and assigning a low one to others. When gentlemen present themselves on this elevated position I am inclined to say, "how we apples swim!" Sir, does all honor, all chivalry,
                consist in resisting the government instituted by our forefathers? Does all heroism consist in rebellion against the constituted authorities of the land?
              </p>
              <p className="p-in-sp">
                There are some of us here who have had no voice in the government of this country for years, and who have nothing to expect, either in a party or a personal point of view ; and yet we have to encounter odium because we desire to sustain the Constitution and the Union formed by our fathers. Gentlemen think we should imitate one who was lately a distinguished General in the Federal
                Army, and be ready to yield at the first command. They do not seem to appreciate the high position which the gallant commander of Fort Sumter occupies—a man who will, for all time to come, stand out as a beacon light, for fidelity to his trust and devotion to his country.
              </p>
              <PageNumber num={368} />
              <p className="p-in-sp">
                There was a President of the United States with whom, though young at the time, I never did agree—I allude to Andrew Jackson. He issued a farewell address to his countrymen; warned his countrymen of the danger of slavery agitation, and pointed out to them the remedies which they have in the Union and under the Constitution. He advised them to appeal to the people, and said that when
                all remedies had failed, it became no less the duty of the public authorities than of the people, to yield a patriotic submission.
              </p>
              <div className="speaker" id="sp2681"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair thinks the gentleman is taking rather too much latitude just now.</p>
              <div className="speaker" id="sp2682"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">Gentlemen spoke about submissionists.</p>
              <div className="speaker" id="sp2683"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The gentleman from Orange [<span className="persName">Mr. MORTON</span>] , was understood to disclaim, in express terms, the use of the term "submissionist" in any offensive sense.</p>
              <div className="speaker" id="sp2684"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">
                I remark that the party to which I feel proud to belong, was repeatedly pointed to as precipitationists, and I suggested whether or not, if I applied the term "Submissionist" to the extreme party on the other side, I would be manifesting less courtesy than they manifest to us. But I said at the same time that I would not apply the term to them, but would call them extreme Union men.
                But when the gentleman manifests so much excitement on this question, when he reflects on a distinguished general of the federal army, who, in discharge of what he considered his duty to his country, resigned his position—
              </p>
              <div className="speaker" id="sp2685"><span className="persName">The PRESIDENT</span> [interrupting]—</div>
              <p className="p-in-sp">The Chair thinks the gentleman from Orange is indulging in too much latitude.</p>
              <div className="speaker" id="sp2686"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">
                I trust, Mr. President, that I give to any gentlemen on this floor, who differ from me, credit for honesty, integrity and patriotism. I trust I will never forget what is due from me as a gentleman, and as a representative of the people, to my co-delegates in this Convention. I can understand and appreciate the patriotic motives that may influence gentlemen who disagree with me. It so
                happens that never, since I was able to cast a vote, has the Federal Government been a government of my choice. It is true that two Presidents for whom I voted were elected. One of them, however, lived but a few months after his inauguration, and the administration of the other was against my political views. But I have always yielded what General Jackson calls "patriotic submission"
                to the authorities of the country, and have always been able to appreciate the patriotism and sincerity of gentlemen who disagreed with me in opinion.
              </p>
              <p className="p-in-sp">
                I think gentlemen forget what is due to themselves, and from them <PageNumber num={369} /> to other members, when they undertake to characterize the motives and feelings of their colleagues. I feel that I would be unworthy of the trust which my constituents, who are opposed to me in sentiment, have placed in me, if I were not able to go forward in
                discharge of my duty without regard to any of those insinuations cast upon those with whom I have been acting and expect to act. I should feel that I was a submissionist in the very worst sense of the term, if I could submit to be bullied out of my sentiments by any kind of denunciation here or elsewhere.
              </p>
              <div className="speaker" id="sp2687"><span className="persName">Mr. JOHN GOODE, JR.</span>—</div>
              <p className="p-in-sp">
                Mr. President, I regret to see so much excitement prevailing here in regard to the selection of Commissioners to wait on the President of the United States. I do not participate in that excitement, and see no reason for it. I rise, however, in the discharge of a duty to make a nomination. I do not know whether the gentleman whom I desire to put in nomination will accept the position,
                if tendered to him; but I think it important to the interest of the State and of the country that he should be placed on this commission. He is well known to the Convention and the country, possesses the confidence of the entire country, and has, in my judgment, all the qualifications necessary to enable him to discharge the important duty with satisfaction, with honor, and with
                benefit to our beloved Commonwealth. I refer to the distinguished member from Princess Anne. We have heard here of parties. I know no party myself in making these selections. The gentleman I have nominated is sui generis, and if he be placed on this Committee he will represent the whole State without regard to party. I do not place him in competition with the name of any other
                gentleman, and I do hope it will be the pleasure of the Convention to command his services on this important occasion.
              </p>
              <p><span className="persName">Mr. MORTON</span> obtained the floor, but yielded to—</p>
              <div className="speaker" id="sp2688"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                There is but one condition, Mr. President, on which you could get me to go to Washington. That is, that you should send me there alone, and let me adopt my own course. Do that, and I think I could end the controversy in a very short time. I would treat the President with the utmost kindness, and I have no doubt that he and I would have one of the most perfectly confidential cheek by
                jowl talks.
                [Laughter.] We would meet in all courtesy, and part in peace. But I would understand him perfectly before I left him, or else he would have a great deal of trouble to avoid being understood, and he would understand me distinctly.</p>
              <p className="p-in-sp">
                Personally, I pity the man. No man could be in a more pitiable <PageNumber num={370} /> condition than an ignoramus and coarse creature as he is, elevated by a surprise, to one of the most responsible positions on the face of the earth, with the destinies of the great country in his hands. I have no doubt that he is unapprized of the sentiments of the
                hearts of this people, a portion of the people he governs. I would try, on my part, to make him understand them. I would not come back with any word, or shadow of word or thought, that could deceive any body, especially when common mercy requires that there should be no veil over the face of the prophet at this hour.
              </p>
              <p className="p-in-sp">Sir, no veiled prophet of Khorassan, no Mokanna, was ever more dangerous to a people than the veiled President who now sits in the Executive Chair of this republic.</p>
              <p className="p-in-sp">
                I cannot consent to go there under a resolution which I voted against. I cannot consent to go there, not knowing how far I would be controlled by two out of the three members of the Committee. I will not consent to go there for the very reason mentioned by my friend who nominated me, that perhaps I am sui generis. I could not content myself, however much I loved the Union, to come
                back here, knowing that the President intended to hold those forts—knowing that he thought it was right and peaceful to do so, and say to you that his words were peace, while I knew they were not peace, but war. I could not be instrumental, even in the third degree, in practising a deception on a people who ought to be waked up, if ever a people ought to be, by God's own thunder, if
                nothing else will rouse them from their slumber. They are slumbering over a volcano. They are pursuing their peaceful avocation while the match is almost touching the magazine of explosion. I cannot, and shall not, admit that he means peace, so long as he holds those forts. Gentlemen here think he has a right to hold them. I do not so think.
              </p>
              <p className="p-in-sp">Policy as well as peace forbids him to hold them. I am well assured, that if he is asked the general question, under this resolution, he will tell you his dispositions are pacific. I am well assured, that if he does tell you that, it will be pointed to in this Convention and out of this Convention, to lull our people into false security.</p>
              <p className="p-in-sp">Sir, I was surprised by the language of the gentleman from Frederick [<span className="persName">Mr. CONRAD</span>] . I do not want to be understood as urging the least objection to the appointment of the gentleman from Augusta [<span className="persName">Mr. STUART</span>]
                , on this Committee. I am advocating no one's election, and opposing no one's election; but I do oppose this sentiment which has been uttered by the gentleman from Frederick. While saying that he would not be guided by party, he tells us frankly and expressly that he <PageNumber num={371} /> would put no man on the Committee, except from his own party
                alone. He does not regard parties, it seems, but he regards one party alone; and that is the party which will, at all hazards, to the utmost extremity, maintain a Union which does not exist any longer, even in name.
              </p>
              <p className="p-in-sp">
                Yesterday we were the United States of America, to-day we are the dis-united States of America; and there is no disputing the fact—disunited in the issue of war and peace, disunited now in commerce, disunited socially before we were divided politically. I can't agree to pursue a plan that is to keep up appearances, when the solemn reality is upon us, that the forts in the seceded
                States are still held by United States forces, and that re-inforcements are sent on to strengthen them. And gentlemen here say that it is proper, that it is right to hold the forts; that it is just to hold them, that it is politic to reinforce and supply them with men, and arms and munitions of war. And gentlemen hold this as the doctrine of peace, because it is the doctrine of
                defence, as they say—and but the exercise of right and the execution of law. That is the doctrine here as well as at Washington; and it is not called war; but it is called defence. I wish it distinctly understood, sir; I wish to impress it on the minds of the people, as well as upon this body, that we are here deliberately called upon to delude ourselves with a mere distinction of
                words, without looking to the substance to ascertain whether that substance is peace or war. The gentleman from Frederick
                [<span className="persName">Mr. CONRAD</span>]
                , may explain it—and I will say here, that I regret having alluded, as I did to-day, to what I understood to be his opinion. I must apologise for doing so. I alluded to his opinion, only with a view to give an illustration, simply by way of argument, not to expose any opinion, whatever, of his, which he desired to retain as private. I beg his pardon, if in the least I have
                transgressed. In alluding to his opinions, I meant nothing in the world but to illustrate the arguments which I was presenting. But I put the illustration to you, sir : If I knew it was your opinion that these forts should be held, and that duty required of you to hold them—that your disposition was not to aggress upon any body in holding them, but that your purpose was to defend
                yourself in case of attack—I ask you the question, sir, is your policy peace, or is your policy war? You might truthfully, you might conscientiously answer, "my policy is pacific ; my policy is peace; my purpose is not war; I want to preserve the peace." And it might be accepted as a truthful, a satisfactory, a consolatory reply. And yet, if it were so; if you intended to hold those
                forts at issue with those States; to hold those forts against their claim <PageNumber num={372} /> of jurisdiction; to hold them against their claim of sovereignty and independence too, and the States intended to take them; if, in the face of that, you were to proclaim that your policy was peace, knowing that you would be attacked, knowing that the
                attempt would be made to capture, knowing that that would bring on war—I ask you, sir, whether the answer would not be delusive?
              </p>
              <p className="p-in-sp">
                That is the position that I wanted to impress upon your minds—upon the minds of my constituents, and upon the minds of gentlemen here. I wanted to ask gentlemen who entertain these opinions with Abraham Lincoln, whether that is their meaning by this resolution. If it be, under no circumstances would I go there—not even to save the Union. I could not practice such a delusion upon our
                people.
              </p>
              <p className="p-in-sp">
                Sir, it would be the most certain and fearful mode of bringing on war. Suppose you go there and put these various questions to him, and he answers that his policy is peace, and by the time the report of that reply is heard at that table; you receive intelligence of the booming of cannon for the attack and defence of these forts. These very ships that have just sailed from New York
                have gone, it is said, to Forts Sumter, Pickens and the Tortugas, with supplies and munitions of war, and they proceed, knowing that they will be attacked if they attempt to reinforce or land supplies; and yet it is said that Lincoln's policy is peace. Would it not bring disrepute upon any committee that would bring back such a report—that, forsooth, Lincoln's policy is peace, while
                he continued to hold the forts and reinforce them, knowing that he would thus provoke attack? Would it not in fact bring disrepute upon the President to maintain that his policy was peace, while he pursued such a course as this? And what would be the defence of the President in such a case? His defence would be, that he did not mean war; that he did not mean anything else than peace;
                he only meant to execute his duty; to obey his oath, and he would also say that he was defending himself. I care not whether he regards the was as offensive or defensive, the question is, is he bringing on war?
              </p>
              <div className="speaker" id="sp2689"><span className="persName">Mr. WHITE</span>—</div>
              <p className="p-in-sp">I rise to a question of order.</p>
              <div className="speaker" id="sp2690"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I will save the necessity of making any point of order on me, for I am done for the present.</p>
              <div className="speaker" id="sp2691"><span className="persName">Mr. NEBLETT</span>—</div>
              <p className="p-in-sp">Mr. President.</p>
              <div className="speaker" id="sp2692"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">I believe I am entitled to the floor, I merely yielded it to the gentleman from Princess Anne, for an explanation.</p>
              <div className="speaker" id="sp2693"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair did not understand for what purpose the gentleman yielded the floor.</p>
              <PageNumber num={373} />
              <div className="speaker" id="sp2694"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">I yielded it merely to enable the gentleman to make an explanation.</p>
              <div className="speaker" id="sp2695"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">Then the gentleman should have called the gentleman from Princess Anne to order, and resumed the floor.</p>
              <p className="p-in-sp">The gentleman from Lunenburg is entitled to the floor.</p>
              <div className="speaker" id="sp2696"><span className="persName">Mr. NEBLETT</span>—</div>
              <p className="p-in-sp">I hope, Mr. President, we will proceed with the nominations. I believe I am regarded as pretty extreme upon the subject of Southern Rights, but I shall vote for the gentleman from Augusta [Mr. STUART] , with a great deal of pleasure, and I hope that it will be the pleasure of my friends in all parts of the House to vote for him. After we have acted upon that nomination, I trust that the House will, with equal unanimity, fill the other vacancy with a gentleman whom I shall put in nomination.</p>
              <div className="speaker" id="sp2697"><span className="persName">Mr. MORTON</span>, of Orange—</div>
              <p className="p-in-sp">I nominated the gentleman from Rockbridge [<span className="persName">Mr. MOORE</span>] , in opposition to the gentleman from Augusta—both entertaining the same phase of opinion. From the indications that I see around me, and also from the fact that my friend to my right , [<span className="persName">Mr. GOODE</span>, of Bedford] , has nominated the distinguished gentleman from Princess Anne, I shall withdraw the name of the gentleman from Rockbridge.</p>
              <div className="speaker" id="sp2698"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">My name is withdrawn too.</p>
              <div className="speaker" id="sp2699"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">
                And while the gentleman from Princess Anne, repudiates the idea of his being nominated, and also lays down conditions of acceptance, I take it for granted that that gentleman has too much patriotism not to fill any position in which this Convention may consider he will be serviceable to the State, and it may be to the Union. For one, sir, I had rather send that gentleman alone than
                any half a score of the members of this Convention. I say this without intending any disrespect to any member. Whilst I think he knows what courtesy is, I am perfectly satisfied that upon his return, there would be no doubt upon the mind of any member of this Convention or any citizen of the Commonwealth of Virginia, as to the policy of the Executive of the United States. As the
                gentleman has justly remarked, under the terms of this resolution, there may be a response from the President that his policy is pacific, while he means to hold to the forts in the seceded States and reinforce them. I very much fear that the report may partake of this vague and general character, leaving us in a state of painful doubt and uncertainty.
              </p>
              <p className="p-in-sp">
                We do not want any such report as that. We want to know distinctly whether the policy of this administration is peace or war. My belief is, that it is a war policy—I am confident it is a war policy. You <PageNumber num={374} /> will find that every Black Republican newspaper in the city of New York—the "Tribune," "Post," the "Times"—and all the Black
                Republican papers throughout the Northwest are crying out for war. We find also that armaments and troops are to be transported to the South, and that leads me to the belief that the object and intention of the administration is war.
              </p>
              <div className="speaker" id="sp2700"><span className="persName">Mr. WHITE</span>—</div>
              <p className="p-in-sp">I rise to a point of order; and it is this, that the remarks of the gentleman do not pertain to the question before the House.</p>
              <div className="speaker" id="sp2701"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair understands that the gentleman from Princess Anne has been nominated, and that nomination not being withdrawn, the gentleman from Orange is therefore in order.</p>
              <div className="speaker" id="sp2702"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">Thank you, sir—I am in order, and I shall be very glad if gentlemen will keep themselves in order. [Laughter.]
                The question is as to the nomination of suitable Commissioners, and in construing the question who are suitable, it is perfectly legitimate for us to consider the subjects which are to be dealt with. I want to have a delegation sent that will not be put off with any general answer. I wish a delegation sent that will propound respectful, yet probing questions to the Executive; and if
                he answers equivocally, I would take the interpretation of the gentleman from Fauquier
                [<span className="persName">Mr. SCOTT</span>] , this morning, that we should construe an equivocal answer as a war policy; and I am satisfied, if we had the gentleman from Princess Anne [<span className="persName">Mr. WISE</span>] , upon that committee, whilst he would observe all courtesy that is proper, and would have a fixed determination to obtain definite answers which will satisfy it that the policy of the administration will be either peace or war. I shall, therefore, second the nomination of the gentleman from Princess Anne.</p>
              <div className="speaker" id="sp2703"><span className="persName">Mr. R. Y. CONRAD</span>—</div>
              <p className="p-in-sp">At a risk of wearying further this Convention, and averse as I am at all times to occupying its time, I still deem it necessary to say a word in answer to my friend from Amelia [Mr. HARVIE] .</p>
              <p className="p-in-sp">I endeavored to get the floor as soon as the gentleman took his seat, but have not been able to succeed until now.</p>
              <p className="p-in-sp">When the gentleman from Augusta was put in nomination, I think the gentleman from Orange [<span className="persName">Mr. MORTON</span>]
                , rose, and, approving the nomination, or, perhaps, making some other nomination, he proceeded to indicate his suspicion that this committee would be selected upon representative grounds from different parties in this Convention. As I intended to vote for the nomination that had been made, I deemed it due to candor and propriety that my vote should not be misunderstood.
                <PageNumber num={375} /> To correct the impression of the gentleman from Orange ,
                [<span className="persName">Mr. MORTON</span>]
                , so far as I was concerned, I rose, sir, to speak as an individual, as an humble individual member of this Convention. I appealed to no party and denounced no party. I said not one word except to remark that I did not regard the Constitution of this Commonwealth as having anything to do with the representation of parties on this floor, and that I did not mean to vote upon any such
                ground.
              </p>
              <p className="p-in-sp">Now, sir, I repeat again, that I will vote for no member myself for this committee whom I do not believe desires a satisfactory, favorable answer to the inquiries which this committee is sent to Washington to make.</p>
              <div className="speaker" id="sp2704"><span className="persName">A DELEGATE</span>—</div>
              <p className="p-in-sp">What do you understand by a satisfactory answer?</p>
              <div className="speaker" id="sp2705"><span className="persName">Mr. CONRAD</span>—</div>
              <p className="p-in-sp">
                I do not choose to be interrogated. I have borne with patience and forbearance all these vituperations, all these epithets which have been applied to me, in connection with others, in reference to the opinions which different members of this Convention may entertain upon this floor. I desire to treat every member here with all respect; and, sir, I shall continue to disregard utterly
                all denunciations of my opinions, or of the opinions of those with whom I may stand upon this floor.
              </p>
              <p className="p-in-sp">I merely wish to set my friend from Amelia right in regard to my position. My object in rising was to prevent any misunderstanding, that when I cast my vote for the gentleman from Augusta [<span className="persName">Mr. STUART</span>]
                , I was bound to vote thereafter for the nominee of some other supposed party upon this floor. In voting, I shall vote for each member of this Committee as, in my judgment, will best conduce to the object of constituting such Committee; that is, of quieting the public mind, by getting what would certainly have that effect—a satisfactory and soothing answer from the Federal Executive.
              </p>
              <div className="speaker" id="sp2706"><span className="persName">Mr. HOLCOMBE</span>—</div>
              <p className="p-in-sp">I desire to say but one word. I very much prefer the mode of communication with the President suggested by my friend from Princess Anne [<span className="persName">Mr. WISE</span>] . But as the Convention would hardly accede to that suggestion, I am compelled to make the best of what I can get. I do not concur with another friend of mine, the gentleman from Middlesex , [<span className="persName">Mr. MONTAGUE</span>]
                , that a position in this Commission is one which, upon principle, a gentleman holding views common to us both ought to be unwilling to accept. I feel assured that the purpose for which this resolution was passed, was a patriotic one. I am not one of those who anticipate great results either one way or the other, from this mission. I believe, however, that it ought to be fairly
                tried. I rise now, simply to say that I shall vote with great pleasure for the <PageNumber num={376} /> gentleman from Augusta, whose name has been put in nomination. After the Convention has elected two Commissioners, I give notice that I shall put in nomination my friend from Richmond
                [<span className="persName">Mr. GEORGE W. RANDOLPH</span>] , and I am sure that in his discretion, and in his courtesy, the Convention will have all possible confidence. I shall put his name in nomination because I understood the gentleman from Princess Anne [<span className="persName">Mr. WISE</span>] , as expressing a determination not to accept the position. I do not now put <span className="persName">Mr. RANDOLPH</span>'S name before the Committee, but simply give notice that I will do so.</p>
              <div className="speaker" id="sp2707"><span className="persName">Mr. GOODE</span>, of Bedford—</div>
              <p className="p-in-sp">I desire to withdraw the name of the gentleman from Princess Anne [<span className="persName">Mr. WISE</span>] , for the present.</p>
              <div className="speaker" id="sp2708"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The question now is upon the nomination of <span className="persName">A. H. H. STUART</span>, of Augusta.</p>
              <p>The question was then put, and the nomination was agreed to by an unanimous vote.</p>
              <div className="speaker" id="sp2709"><span className="persName">Mr. HOLCOMBE</span>—</div>
              <p className="p-in-sp">I now put in nomination the name of <span className="persName">GEORGE W. RANDOLPH</span>, of this city.</p>
              <div className="speaker" id="sp2710"><span className="persName">Mr. FLOURNOY</span>—</div>
              <p className="p-in-sp">
                I take great pleasure in seconding the nomination of <span className="persName">Mr. GEORGE W. RANDOLPH</span>. I need not say a word by way of recommending <span className="persName">Mr. RANDOLPH</span> to the favorable consideration of this Convention. He was well known to some of you before the meeting of this Convention. He is now well known to every member of this body; and I trust it
                will be the pleasure of this Convention to adopt the suggestion made by the gentleman from Albemarle
                [<span className="persName">Mr. HOLCOMBE</span>] , and place him upon this commission with the same unanimity with which they have agreed to the other gentlemen who have been appointed.</p>
              <div className="speaker" id="sp2711"><span className="persName">Mr. CONRAD</span>—</div>
              <p className="p-in-sp">I beg leave to nominate <span className="persName">GEO. W. BRENT</span>, of Alexandria.</p>
              <div className="speaker" id="sp2712"><span className="persName">Mr. BRENT</span>—</div>
              <p className="p-in-sp">I do not desire to be one of these Commissioners, and I would therefore ask the gentleman from Frederick to withdraw my name.</p>
              <div className="speaker" id="sp2713"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">Is the name withdrawn?</p>
              <div className="speaker" id="sp2714"><span className="persName">Mr. CONRAD</span>—</div>
              <p className="p-in-sp">No, sir.</p>
              <div className="speaker" id="sp2715"><span className="persName">Mr. STAPLES</span>—</div>
              <p className="p-in-sp">I would suggest the name of the gentleman from Middlesex [<span className="persName">Mr. MONTAGUE</span>] .</p>
              <div className="speaker" id="sp2716"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">
                I will not go. I just wish to call the attention of Convention to one single fact. If you will look at the yeas and nays, you will see that the majority who adopted the resolution appointing this commission, belong to that party which has been called the secession party here. Now, I ask this Convention, if there is an instance in the history of parliamentary legislation, where
                members representing <PageNumber num={377} /> that portion of the votes which constitute the majority, that have passed a resolution providing for a committee, have been excluded from appointments on such committee. Such a practice is unheard of, and it is unjust and improper. The minority here upon every principle of justice, reason and parliamentary
                law, ought to have a representative upon that committee.
              </p>
              <div className="speaker" id="sp2717"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair can only take the question upon one nomination.</p>
              <div className="speaker" id="sp2718"><span className="persName">Mr. PRICE</span>—</div>
              <p className="p-in-sp">There seems to be some little uncertainty as to whether the nomination of the gentleman from Alexandria [<span className="persName">Mr. BRENT</span>] , is before the Convention. I rise to request the gentleman over the way [<span className="persName">Mr. CONRAD</span>] , to say if he desires that his name shall be used, notwithstanding his objection.</p>
              <div className="speaker" id="sp2719"><span className="persName">Mr. BRENT</span>—</div>
              <p className="p-in-sp">I desire my friend from Frederick [<span className="persName">Mr. CONRAD</span>] , as a personal favor to me, to withdraw my name.</p>
              <div className="speaker" id="sp2720"><span className="persName">Mr. CONRAD</span>—</div>
              <p className="p-in-sp">I withdraw the name, then, of <span className="persName">Mr. BRENT</span>.</p>
              <p><span className="persName">Mr. STUART</span>, of Doddridge, put in nomination the name of <span className="persName">Mr. MARSHALL</span>, but at <span className="persName">Mr. MARSHALL</span>'S request, withdrew it.</p>
              <p><span className="persName">Mr. STUART</span> inquired if <span className="persName">Mr. BRENT</span>'S name was withdrawn. <span className="persName">The PRESIDENT</span> said it was.</p>
              <div className="speaker" id="sp2721"><span className="persName">Mr. STUART</span>—</div>
              <p className="p-in-sp">I renew it again.</p>
              <div className="speaker" id="sp2722"><span className="persName">Mr. MOORE</span>—</div>
              <p className="p-in-sp">I put in nomination the name of <span className="persName">GEO. W. SUMMERS</span>, and I want to vote for him or for some other man whom I know to be a strong Union man.</p>
              <div className="speaker" id="sp2723"><span className="persName">Mr. SUMMERS</span>—</div>
              <p className="p-in-sp">I hope my friend from Rockbridge [<span className="persName">Mr. MOORE</span>] , as a personal favor to myself, will withdraw my name without hesitation.</p>
              <div className="speaker" id="sp2724"><span className="persName">Mr. MOORE</span>—</div>
              <p className="p-in-sp">At the gentleman's request, I will withdraw his name.</p>
              <div className="speaker" id="sp2725"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I hope the gentleman's name will not be withdrawn. He did so well at the Peace Conference at Washington.</p>
              <div className="stage it">[Laughter.]</div>
              <div className="speaker" id="sp2726"><span className="persName">Mr. SUMMERS</span>—</div>
              <p className="p-in-sp">I did much better than the gentleman from Princess Anne would have done.</p>
              <div className="stage it">[Laughter.]</div>
              <div className="speaker" id="sp2727"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Yes ; for I would have brought back nothing—rather than what you brought.</p>
              <div className="speaker" id="sp2728"><span className="persName">Mr. CRITCHER</span>—</div>
              <p className="p-in-sp">I would put in nomination GEORGE BLOW.</p>
              <div className="speaker" id="sp2729"><span className="persName">Mr. BLOW</span>—</div>
              <p className="p-in-sp">
                I must ask my friend, as a personal favor to myself, to withdraw my name. I happen to be one of those who voted in opposition to these resolutions. There is great force in the views which some gentlemen have taken upon this floor, that the various sections of opinion in this Convention should be represented in that commission. I do not see—if I may be pardoned for making the
                remark—why the great interests of the Union party, and the great interests of <PageNumber num={378} /> this State, cannot be as well represented and as well maintained by the gentleman of distinction, good temper, large experience and patriotism, whose name now stands as a candidate. I trust that it may be the sense of this Convention to confer upon him
                this distinguished position with the same unanimity that has been conferred upon other gentlemen. I repeat that I trust my friend as a personal favor will withdraw my name.
              </p>
              <p><span className="persName">Mr. CRITCHER</span> withdrew the name of <span className="persName">Mr. BLOW</span>.</p>
              <div className="speaker" id="sp2730"><span className="persName">Mr. CARTER</span>—</div>
              <p className="p-in-sp">
                I am one of those who voted against this mission to Washington, but I think there is force in the remarks that have been made here, that as far as practicable the different shades of opinion that exist in this Hall should be represented upon that mission. I think the propriety of that must strike us all in a greater or less degree. and having elected two gentlemen who are said to
                represent two shades of opinion, I do hope that it will be the pleasure of the Convention to elect with the same unanimity, the gentleman from Richmond City, who has been nominated. It will give me great pleasure to vote for the gentleman, and I hope his name will be carried with the same unanimity as the names of the other gentlemen were.
              </p>
              <p>Cries of question, question.</p>
              <div className="speaker" id="sp2731"><span className="persName">Mr. STUART</span>—</div>
              <p className="p-in-sp">At the solicitation of <span className="persName">Mr. BRENT</span>, I will withdraw his name, and put in nomination, that of <span className="persName">Mr. PRICE</span>.</p>
              <div className="speaker" id="sp2732"><span className="persName">Mr. PRICE</span>—</div>
              <p className="p-in-sp">I would be very reluctant to accept the nomination, and I must indeed positively decline it. I don't suppose I would be elected, because I think it is the sense of the Convention now to elect <span className="persName">Mr. RANDOLPH</span>, and I hope my friend from Doddridge will be good enough to withdraw my name.</p>
              <div className="speaker" id="sp2733"><span className="persName">Mr. STUART</span>, of Doddridge—</div>
              <p className="p-in-sp">I will withdraw the name of <span className="persName">Mr. PRICE</span> then, and put in nomination that of <span className="persName">Mr. TREDWAY</span>.</p>
              <p><span className="persName">Mr. TREDWAY</span> declined being a candidate.</p>
              <div className="speaker" id="sp2734"><span className="persName">Mr. STUART</span>, of Doddridge—</div>
              <p className="p-in-sp">I put in nomination the name of <span className="persName">Mr. R. Y. CONRAD</span>.</p>
              <div className="speaker" id="sp2735"><span className="persName">Mr. R. Y. CONRAD</span>—</div>
              <p className="p-in-sp">I am very anxious as he is, for some name upon which to rest my vote, and I cannot vote for myself.</p>
              <div className="speaker" id="sp2736"><span className="persName">Mr. STUART</span>, of Doddridge—</div>
              <p className="p-in-sp">I cannot withdraw your name.</p>
              <div className="speaker" id="sp2737"><span className="persName">Mr. R. Y. CONRAD</span>—</div>
              <p className="p-in-sp">
                I hope the gentleman will withdraw it for another reason. I have duties to perform here in connection with the report which is under consideration, and it is impossible, for that reason, that I should leave here, even if I should happen to be appointed. I would suggest to the gentleman that it is useless to make farther opposition; and I beg leave to add that no gentleman in this
                <PageNumber num={379} /> Convention could be more acceptable to me personally than the one who has been nominated to fill the vacant place in this committee, and whilst I desire certainly to vote upon principle for some other man, yet, so far as I am concerned I will withdraw any opposition to the nomination of
                <span className="persName">Mr. RANDOLPH</span>, and I hope the gentleman from Doddridge will withdraw my name.
              </p>
              <div className="speaker" id="sp2738"><span className="persName">Mr. STUART</span>, of Doddridge—</div>
              <p className="p-in-sp">I am afraid I will have to withdraw the name of my friend of Frederick [<span className="persName">Mr. CONRAD</span>] , and I will put in nomination the name of <span className="persName">Mr. BRANCH</span>.</p>
              <div className="stage it">[Laughter.]</div>
              <div className="speaker" id="sp2739"><span className="persName">Mr. BRANCH</span>—</div>
              <p className="p-in-sp">The gentlemen is mistaken in the man. He must mean <span className="persName">Mr. BARBOUR</span>, of Culpeper. [Laughter.] I most respectfully decline the nomination tendered me.</p>
              <div className="speaker" id="sp2740"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">Is the name of <span className="persName">Mr. BRANCH</span> withdrawn?</p>
              <div className="speaker" id="sp2741"><span className="persName">Mr. STUART</span>, of Doddridge—</div>
              <p className="p-in-sp">It is, and I would put in nomination <span className="persName">Mr. MOORE</span>, of Rockbridge.</p>
              <div className="stage it">[Laughter.]</div>
              <div className="speaker" id="sp2742"><span className="persName">Mr. HUGHES</span>, of Randolph and Tucker—</div>
              <p className="p-in-sp">I desire to nominate <span className="persName">Mr. STUART</span>, of Doddridge. [Laughter.] Calls of "Question! question!"</p>
              <div className="speaker" id="sp2743"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">Is the name of <span className="persName">Mr. BRANCH</span> withdrawn?</p>
              <div className="speaker" id="sp2744"><span className="persName">Mr. BRANCH</span>-</div>
              <p className="p-in-sp">Yes, sir.</p>
              <div className="speaker" id="sp2745"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">Then there is no name before the Convention but that of <span className="persName">Mr. RANDOLPH</span>.</p>
              <div className="speaker" id="sp2746"><span className="persName">Mr. HUGHES</span>—</div>
              <p className="p-in-sp">I made the nomination of <span className="persName">Mr. STUART</span>, of Doddridge.</p>
              <div className="speaker" id="sp2747"><span className="persName">Mr. STUART</span>—</div>
              <p className="p-in-sp">I must decline to serve on that Commission.</p>
              <div className="speaker" id="sp2748"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Is the Committee alive now, sir, or is it killed?</p>
              <div className="speaker" id="sp2749"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Committee is two-thirds alive, and I hope will soon be entirely alive.</p>
              <div className="speaker" id="sp2750"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">If this proceeding goes on you will kill it before you create it.</p>
              <div className="speaker" id="sp2751"><span className="persName">Mr. HUGHES</span>—</div>
              <p className="p-in-sp">I withdraw the nomination of <span className="persName">Mr. STUART</span>, of Doddridge.</p>
              <p>The question was then taken on the nomination of GEORGE W. RANDOLPH, of Richmond city, and he was unanimously chosen as the third Commissioner.</p>
              <p>On motion of <span className="persName">Mr. PATRICK</span>, of Kanawha, the Convention then, at 7 o'clock, adjourned till Tuesday morning at 10 o'clock.</p>
            </div>
          </div>
          <div className="day" id="vsc1965.v3.2.7">
            <PageNumber num={380} />
            <h2><span className="headingNumber">1.7. </span><span className="head">FORTY-SEVENTH DAY</span></h2>
            <div className="dateline">Tuesday, <span className="date">April 9</span></div>
            <p>The Convention assembled at 10 o'clock, A. M., the President in the Chair.</p>
            <div className="section" id="vsc1965.v3.2.7.1">
              <h3><span className="headingNumber">1.7.1. </span><span className="head">CORRECTION</span></h3>
              <div className="speaker" id="sp2752"><span className="persName">Mr. JAMES B. MALLORY</span>, of Brunswick—</div>
              <p className="p-in-sp">I wish to correct an error in the report of the vote on the proposition of the gentleman from Amelia [<span className="persName">Mr. HARVIE</span>]
                . On Thursday last when the sixth resolution of the Committee of Twenty-One was under consideration, <span className="persName">Mr. HARVIE</span> offered a substitute, looking to secession, and on that question, I voted in the affirmative, but I find I am reported in the "Enquirer" of yesterday, as voting in the negative. I have referred to the Richmond "Whig" of Friday, and find that I
                am correctly reported in that. I hope the reporters will notice this explanation in order that I may stand correctly before my constituents.
              </p>
            </div>
            <div className="section" id="vsc1965.v3.2.7.2">
              <h3><span className="headingNumber">1.7.2. </span><span className="head">PUBLIC MEETING IN PRINCE WILLIAM COUNTY</span></h3>
              <div className="speaker" id="sp2753"><span className="persName">Mr. EPPA HUNTON</span>, of Prince William—</div>
              <p className="p-in-sp">
                I desire to call attention to the proceedings of a meeting held in the County of Prince William on the 1st of April. That constituency, sir, like myself, for a long time have thought that the only solution of our present difficulties was to be found in immediate secession, and had they not entertained those sentiments they never would have been represented on this floor by me. Being
                able to represent their sentiments in strict accordance with my own views, I feel justly proud of the noble and generous constituency which I have the honor to represent, and I take great pleasure in laying before this Convention their sentiments upon the subject now agitating the country.
              </p>
              <p>The proceedings of the meeting were then read as follows :</p>
              <div className="floatingText">
                <div className="floatingText_body">
                  <p>At a Southern Rights meeting of the people of the county of Prince William, held at the Court House on the 1st day of April, 1861, on motion, Capt. William W. Thornton was called to the Chair, and A. Nicol, Esq., appointed Secretary.</p>
                  <p>The object of the meeting was explained by B. E. Harrison, Esq., in a short and neat address, on whose motion it was—</p>
                  <p>Resolved, That the several election precincts in the county, be requested to appoint ten delegates each, to meet in county Convention at the Court House, on the first Monday in May next, to nominate <PageNumber num={381} /> a candidate to represent the county in the next Legislature of Virginia.</p>
                  <p>The meeting was then forcibly and ably addressed by General Hunton, delegate to the State Convention, and by Judge C. E. Sinclair, of Memphis, Tennessee, and was followed by Col. Basil Brawner, upon whose motion the following preamble and resolutions were unanimously adopted :</p>
                  <p>Whereas, All honorable means for the preservation of the Union, consistent with the honor and equality of all the States, have been exhausted without avail; Therefore-</p>
                  <p>1. Resolved, That we, the people of Prince William county, in public meeting assembled, are in favor of the State of Virginia seceding from the Union as soon as possible.</p>
                  <p>And, Whereas, Seven States have already withdrawn from the Union, and organized themselves into a separate and distinct Confederacy, whose interests and social institutions are identical with our own, and whose Constitution we approve of-</p>
                  <p>2. Resolved, That in our opinion the welfare and prosperity of Virginia requires her to become a member of the Southern Confederacy at the earliest practical moment.</p>
                  <p>3. Resolved, That if the Convention now assembled shall pass an ordinance of secession, and thereby increased taxation becomes necessary, we are willing that all property shall be taxed ad valorem.</p>
                  <p>4. Resolved, That we cordially approve of the position taken by Gen. Hunton, our representative in the State Convention, and tender him our thanks for the faithful manner in which he has represented us ; and that a copy of the foregoing resolutions be forwarded to him with the request that he lay the same before the Convention.</p>
                  <p>The third resolution was debated by Judge S. Lynn and John T. Williams, Esq.</p>
                  <p>On motion of Mr. Fitzhugh,</p>
                  <p>Resolved, That the Alexandria and Richmond papers be requested to publish the proceedings of this meeting.</p>
                  <p>On motion, the meeting then adjourned.</p>
                  <p>WM. W. THORNTON, Chairman</p>
                  <p>A. NICOL, Secretary</p>
                </div>
              </div>
              <p>The proceedings took the usual course of being referred to the Committee on Federal Relations.</p>
              <div className="speaker" id="sp2754"><span className="persName">Mr. SCOTT</span>, of Powhatan—</div>
              <p className="p-in-sp">I beg leave to lay before the Convention the proceedings of a meeting recently held in the county of Cumberland, <PageNumber num={382} /> which I wish to accompany with a few remarks after they are read.</p>
              <div className="speaker" id="sp2755"><span className="persName">Mr. NELSON</span>, of Clarke—</div>
              <p className="p-in-sp">Mr. President, I rise to a point of order of this Convention which requires its proceedings to be opened by prayer. I hope the President don't think we are past praying for.</p>
              <div className="speaker" id="sp2756"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The President has no right to form an opinion upon that subject. But he would state that there was no clergyman in attendance either yesterday morning or this morning. Two were invited but neither attended.</p>
              <div className="speaker" id="sp2757"><span className="persName">Mr. MONTAGUE</span>, of Middlesex—</div>
              <p className="p-in-sp">Mr. President, can I make a suggestion to you on that subject?</p>
              <div className="speaker" id="sp2758"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">Certainly, sir.</p>
              <div className="speaker" id="sp2759"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">The course pursued by the House of Delegates and the Senate in reference to the subject alluded to by the gentleman from Clarke [<span className="persName">Mr. NELSON</span>] andthe plan has worked very well indeed—is this : For the Clerk, on Saturday, to address a note to one minister and request him to make arrangements so that he could officiate during the week at the opening of the proceedings ; and the next week to address a similar note to a minister of another denomination. That plan has worked very well.</p>
              <div className="speaker" id="sp2760"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">That is in substance, sir, what the Clerk of this Convention has done; and yesterday he especially invited two ministers, fearing that the one might not be able to attend. But neither came.</p>
            </div>
            <div className="section" id="vsc1965.v3.2.7.3">
              <h3><span className="headingNumber">1.7.3. </span><span className="head">PROCEEDINGS OF A MEETING IN CUMBERLAND COUNTY</span></h3>
              <p>The proceedings of the public meeting, presented by <span className="persName">Mr. SCOTT</span> of Cumberland, were then read as follows :</p>
              <div className="floatingText">
                <div className="floatingText_body">
                  <p>Pursuant to a call made through the Richmond papers, for a meeting of the citizens of Cumberland County, to take into consideration the present alarming condition of the country, a very large number of the voters of the county assembled at the Court House on the 25th of March, it being Court day.</p>
                  <p>John W. Wilson, Esq., was called to preside over the meeting, and Wm. A. Perkins, chosen Secretary. Mr. George H. Matthews, Sr., in a short and appropriate address, urged the policy of the immediate secession of Virginia. Mr. Wm. A. Perkins offered the following preamble and resolutions for the consideration of the meeting:</p>
                  <p>
                    Whereas, In the recent election of delegates to the Convention now being held in the city of Richmond, a majority of the voters of this county (fondly hoping that the Union might still be preserved or reconstructed) cast their votes for our present worthy representative, <PageNumber num={383} /> Gen. Wm. C. Scott, upon the ground that he was the
                    friend and advocate of proper and honorable efforts to bring about an amicable adjustment of existing difficulties between the different sections of the country, and to secure from the North such guarantees as would justify Virginia in remaining a member of the present Union; but, that, when it should be ascertained that those overtures would not be met in a spirit of
                    conciliation and of justice by the North, he would then favor the resumption, by this State, of the powers ceded to the General Government in the present Constitution. Whilst we design casting no censure upon our delegate, Gen. Scott, for his past course, but on the contrary avow our entire confidence in his capacity and fidelity; yet we deem it proper that he should be advised
                    of the state of public opinion in this portion of his district. Recent events have convinced us, that Virginia cannot honorably, and, therefore, should not make any further appeals, or any longer occupy the position of supplication to a dominant and fanatical sectional party, which, regardless of constitutional obligations, has met all our advances, either with indifference,
                    absolute refusal, or fraudulent and deceptive compromises. Therefore, interest and honor alike demand that we should sever our connection with the present Union, and take the proper steps to unite ourselves with the Southern Confederacy.
                  </p>
                  <p>
                    At the time of the election we cherished a hope that the Peace Conference, then about to assemble in Washington would be able to adopt some action which would give security to the rights of the different portions of the Union. We also hoped that Mr. Lincoln, in his Inaugural, would enunciate some sentiment, and proclaim some line of policy, on which to base an expectation that
                    peace and justice might prevail, and the Union be preserved.
                  </p>
                  <p>
                    The Peace Conference adjourned after adopting what is known as the Franklin Compromise. This compromise we regard as delusive and fraudulent, utterly failing to secure to the South the rights to which it is plainly entitled under the constitution, and as holding out no inducements which can bring back into the Union any one of the seceded States. We, therefore, protest against
                    its acceptance by the State of Virginia. Closely following the action of the Peace Conference, came Mr. Lincoln's inaugural, in which, forgetting the claims of patriotism, and showing himself incapable of appreciating the dignity and responsibility of his office, he maintains his position as the tool of fanaticism, gives utterance to sentiments and indicates a policy to which no
                    Southern State can honorably submit. Since his inauguration he has, in the selection of his Cabinet, and also by his <PageNumber num={384} /> other appointments of officers, both foreign and domestic, given unmistakable evidence that the slaveholding States have nothing but injustice and hostility to expect from his administration.
                  </p>
                  <p>
                    We are pleased to hear that Fort Sumter is to be evacuated without bloodshed. But should this be true, and should even it be followed by the evacuation of Fort Pickens, we shall regard neither as any voluntary concession to the just demands of the South, or as evincing any change in the coercive policy of the inaugural, but as an unwilling submission to a stern necessity, for
                    which there is no alternative.
                  </p>
                  <p>
                    Without any further enumeration of the reasons which have forced this conviction upon us, we now assert that we are firmly convinced that Virginia has petitioned for redress of grievances, submitted to insult and injury, and threatened long enough ; and that the time has now arrived for her to assert her independence, and demand those rights which have been so invariably and
                    constantly denied to her petitions, her remonstrances and her threats; therefore,
                  </p>
                  <p>Resolved, 1. That our delegate to the Convention is hereby instructed that it is the will of his constituents in this county that he should advocate such measures as will lead to the immediate secession of Virginia.</p>
                  <p>
                    Resolved, 2. That whilst we do not approve of the proposed Convention of the border slave States, because of the delay consequent thereon, and because we deem it useless and degrading to Virginia to continue proposing ultimatum after ultimatum, when the least of her demands has been refused, yet if a majority of our Convention should insist on the said Convention, it is then our
                    wish that the delegates of Virginia should be sent with instructions not to lay down an ultimatum, but to take steps to secure concerted secession on the part of said border States.
                  </p>
                  <p>
                    Resolved, 3. That the Convention should immediately adopt a resolution declaring in plain and unequivocal language, that should the Federal Government undertake to coerce any Southern State, either on the plea of occupying, holding and possessing the forts, and other public property, or collecting the revenue, or any other plea whatsoever, this State will meet such effort with an
                    armed resistance to the utmost extent of her power.
                  </p>
                  <p>Resolved, 4. That the Convention, before adjourning, should make provision for the calling out of the militia, on the happening of the above contingency.</p>
                  <p>Resolved, 5. That the reported intention of the Federal Government to throw additional troops into Fortress Monroe, can only be regarded <PageNumber num={385} /> as threatening and insulting to Virginia, and should be met with stern resistance.</p>
                  <p>Mr. Francis D. Irving moved, and warmly advocated the striking out of the second resolution in the above series, and inserting in its stead the following:</p>
                  <p>Resolved, 2. That before passing an ordinance of secession, proper steps should be taken by the Convention of Virginia for a conference at an early day with our sister slave States still in the Union, and particularly with the States of Maryland, Kentucky, Tennessee, and North Carolina, lying on our border, in order to insure united action.</p>
                  <p>Our Delegate, General Scott, being called on, addressed the meeting, opposing the policy of immediate secession, and advocating the Border State Convention. Mr. Charles Irving, was then called upon, and delivered an able and eloquent address in favor of immediate secession.</p>
                  <p>
                    The vote being now taken on Mr. Francis Irving's amendment, it was rejected by a very large majority. The meeting then adopted the preamble and resolutions almost unanimously, the negative vote being so small that no count was called for. Captain Henry R. Johnson being loudly called for made a few eloquent and stirring remarks, denouncing the Franklin Compromise, opposing the
                    Border State Convention and urging immediate secession.
                  </p>
                  <p>On motion, it was Resolved, That a copy of these proceedings be sent to the Richmond papers for publication, also a copy to be sent to our delegate, with a request that he present the same to the Convention.</p>
                  <p>The meeting then adjourned.</p>
                  <p>JOHN W. WILSON, Chairman</p>
                  <p>WM. A. PERKINS, Secretary</p>
                </div>
              </div>
              <div className="speaker" id="sp2761"><span className="persName">Mr. SCOTT</span>, of Powhatan—</div>
              <p className="p-in-sp">
                Mr. President, I wish to make a few remarks, in order to explain my position in reference to instructions through meetings of my constituents. In the canvass for the election of delegates to this Convention, I took the ground that Virginia could not permanently remain in the Union as it stands, after the withdrawal of those Southern States which now constitute the Confederate States
                of the South; but I announced distinctly that I was in favor of an amicable settlement of all the difficulties between the States of the South and the States of the North. In regard to the mode and the terms of adjustment, I left myself perfectly free to judge according to the best suggestions of my own mind. In the canvass I declared that I would make no pledges, and that I did not
                wish to be elected <PageNumber num={386} /> in such manner that I would be trammelled in any way. I told my constituents, that if I should be sent to this Convention, I would go entirely untrammelled. In this connection I declared, in a public speech in Cumberland, that I did not desire to be elected, unless I could go into the Convention
              </p>
              <p className="p-in-sp">"Free as the mountain antelope, Free as the wild bird on the wing."</p>
              <p className="p-in-sp">Under these circumstances I was elected, and was opposed to the immediate secession of Virginia, and am still opposed to it under the existing state of things.</p>
              <p className="p-in-sp">
                The meeting whose proceedings have just been read, was called in the county of Cumberland, to be held on the 25th of March, for the purpose of giving me instructions, and I was present at that meeting. A gentleman—not the one who prepared the resolutions which have been read here this morning—another gentleman, Mr. Matthews I mean, presented a series of resolutions and made a speech,
                declaring that I ought not be allowed to address the meeting. Afterwards, however, a gentleman moved that I should be requested to state the condition of affairs in this Convention, and that motion prevailed. Then, I did not address the meeting upon the general questions at large, but merely made a statement of the course which this Convention had pursued, and how its members were
                divided. I made but few remarks in reference to these matters.
              </p>
              <p className="p-in-sp">
                After that, sir, another gentleman, not a citizen of Cumberland, was called on, and made a speech, after which a vote was taken, and it appears that a majority of the meeting was in favor of giving me instructions. How many voted, and how they voted, I do not know. How many of those who voted for giving me instructions had voted for me, I do not know. There were only three or four,
                to my knowledge, who voted for me at the election.
              </p>
              <p className="p-in-sp">
                On the following Monday, a meeting, for the same purpose, was held in the county of Powhatan—my own county. I attended that meeting, and felt quite at home there. A division was taken upon the question of instructing me. One gentleman counted 125 for, and 77 against instructions; another gentleman counted 119 for, and 85 against instructions. Then I requested all those who were in
                favor of instructions, and who had voted for me as a member of the Convention, to step out, in order that I might see how many there were. Three came forward, and one of those three told me, in the evening, that he took back his sanction to instructions, stating that he would rather depend <PageNumber num={387} /> upon my judgment than upon his own. I
                understand that there were more of those who did not vote for me, who were opposed to giving me instructions, present. That being the case, I did not consider myself instructed. And I now wish this Convention, and the people of the county, to know, that I do not consider myself instructed by the action of those two meetings. The persons who composed them did not represent one-half of
                the voters of the two counties; and very few of those who voted to instruct me at those meetings, voted for me at the election.
              </p>
              <p className="p-in-sp">
                But, sir, notwithstanding all that, I believe the majority of my constituents are in favor of secession. I have come to that belief, sir, not from the fact that those meetings were held, but from private information that I have received from my friends. There is one precinct in the county of Cumberland, the precinct of Raines' Tavern, in which I obtained an almost unanimous vote, and
                in the neighborhood of which I understand now that nearly all the people who voted are in favor of secession. Believing, therefore, that a majority of my constituents are in favor of secession, as long as I am of that belief I shall vote in that way upon every test question. Upon all other subjects I shall vote as my judgment shall dictate.
              </p>
              <p className="p-in-sp">
                I undertake to say, Mr. President, that there is not a more intelligent constituency within the limits of Virginia, than that contained within the counties which I have the honor to represent. And, I undertake to say again, sir, that there is no constituency more deeply interested in the difficulties which now endanger the country; for in them are more than two slaves to one white,
                in those two counties.
              </p>
              <p>The proceedings were referred to the Committee on Federal Relations.</p>
            </div>
            <div className="section" id="vsc1965.v3.2.7.4">
              <h3><span className="headingNumber">1.7.4. </span><span className="head">PUBLIC MEETING IN BEDFORD COUNTY</span></h3>
              <div className="speaker" id="sp2762"><span className="persName">Mr. GOGGIN</span>, of Bedford—</div>
              <p className="p-in-sp">
                I do not intend to detain the Convention with any remarks. I merely wish to present the proceedings of a meeting. I do not intend even to ask that they shall be read. They are the proceedings of a large and respectable meeting of citizens of Bedford, held on Saturday, the 6th of April, to express the sentiments of the people in that portion of the county in which the meeting was held
                in relation to the present attitude of public affairs and in relation to what the duty of Virginia is in reference to those affairs. After reciting a long catalogue of abuses, in the first resolution, and referring in the others to the industrial interests of the country, which have been made to suffer by the present condition of our national
                <PageNumber num={388} /> affairs, and the great inequality which the South experiences by our present condition, and particularly the State of Virginia, and that in the language of the third resolution, Mr. Lincoln denies that the Union is dissolved, yet it is just as apparent to their vision as the Blue Mountains near which those people reside that it
                is dissolved; and the fourth resolution declares that it is the duty of the State of Virginia to unite her destiny with the Confederate States of the South, and that a further adherence to the Federal Government, under existing circumstances, is unwise and improper. I merely ask that the proceedings shall be laid upon the table, and take the usual course by which they will become a
                part of the proceedings of the Convention and be printed with them.
              </p>
              <div className="floatingText">
                <div className="floatingText_body">
                  <p>At a large and respectable meeting of the citizens of Bedford, held at Charlemont on Saturday, April 6th, to express their sentiments of the duty of Virginia in the present crisis. On motion, Jas. M. Rucker was called to the Chair, and Dr. A. J. Almond and Robt. W. Freeman were appointed Secretaries.</p>
                  <p>The Chairman having explained the object of the meeting, on motion, a committee consisting of John Davis, Jr., John D. Burks, Benj. N. Hobson, T. H. Turpin, Thos. M. Falls, Alex. S. Thompson and S. J. Howard, was appointed to prepare a preamble and resolutions for the consideration of the meeting. They reported the following:</p>
                  <p>
                    Whereas, the government of the United States of America was founded on a Constitution intended to give the same protection and benefits to the Southern as well as Northern States of the Union, and to make them equal in all respects; and whereas, the North has always claimed, and as we verily believed, received her full share of such benefits, but not content with her rights, has,
                    as is clearly shown by an impartial history of the country, for the long period of forty years, warred directly and indirectly upon the property of the Southern people. She has through her politicians, her school rooms, and last and worse than all, through her churches and pulpits, preached that pure religion demanded the abolition of slavery and the equality of the white and
                    black races; and whereas the adoption of these abominable doctrines by the Northern people has excited a deep feeling of hatred towards us, which has resulted in a systematic and organized plan for the robbery of the Southern people of valuable property, which has resulted in the murder of Southern citizens in Northern States, while engaged in the just, clear and legal rights of
                    securing their own property, which has resulted in an invasion of the soil of Virginia <PageNumber num={389} /> by an armed band to murder our people and to seduce our slaves into a servile insurrection against their own masters ; and, whereas, against every entreaty and remonstrance, the Northern people have continued their aggressions until these
                    evils have finally culminated in the election of Abraham Lincoln to the Presidency on an issue of direct hostility to the South, and with no other claim or merit than that hostility; therefore, we a portion of the people of Bedford, in general meeting assembled, express our sentiments in the following resolutions :
                  </p>
                  <p>
                    1st. Resolved, That the long catalogue of wrongs and grievances inflicted on the Southern people by their Northern brethren, and the continuance of the same, though often attempted to be remedied by the greatest statesmen of the country, does demonstrate as clearly as Holy Writ, that there is a deep, a great, an irreconcilable difference between the two sections, and that all
                    paper compromises have heretofore failed to produce permanent harmony and peace, and would continue to prove impotent hereafter if made.
                  </p>
                  <p>
                    2nd. Resolved, That in view of the above enumerated facts, not only the honor and glory of Virginia, but the material interests, the industrial pursuits of every citizen, her very life blood, demand that she should seek a government where she would be recognized as an equal, and not continue a member of this, which is now illustrated by Abraham Lincoln, his appointees, Seward,
                    Chase, Burlingame &amp; Co., as its exponents, who are the embodiment of Black Republicanism, selected for office, not because of fame and worth as statesmen, but because of their notoriety as political malefactors and criminals against the Southern people.
                  </p>
                  <p>
                    3rd Resolved, That while Abraham Lincoln denies that this Union is dissolved, the fact that it is dissolved, is as apparent to our moral and mental knowledge, as that our own mountains are visible to physical view; and we recognize the further fact, the fixed fact, the irrevocable fact, that the seceded States have formed a confederacy sovereign and independent of this
                    Government, and of all other Governments, and will be speedily recognized as such by the powers of Europe.
                  </p>
                  <p>
                    4th. Resolved, That we regard the position of the Confederated States of America as presenting the most sublime and exalted spectacle in the history of the world; that they acted wisely in withdrawing from a Government which has become thoroughly sectional against their and our interests—sectional against the solemn advice of the father of his country, the immortal
                    Washington—which has degenerated <PageNumber num={390} /> into Agrarianism, Black Republicanism and Abolitionism, and that we behold in the statesman, patriot and soldier, Jefferson Davis, a man eminently worthy of the Presidency of such a republic and while we, as a people, by the forms of government, are now aliens and strangers to them, yet, by a
                    common interest, by a similarity of education and sentiment, and, more than all, by the sacred ties of kindred and blood, they are our brethren and countrymen.
                  </p>
                  <p>
                    5th. Resolved, That we regard the longer adherence of Virginia to this Black Republican Government not only unwise, but unnatural and monstrous; that it legalizes the General Government to require us to make war upon our kindred, in interest and affection; that it is utterly abhorrent to every sentiment of honor and nature; that our delegates in the State Convention, Messrs.
                    Goggin and Goode, have not only represented the wishes of this meeting, but, as we believe, of an overwhelming majority of the people of the county, by voting for an ordinance for the immediate secession of Virginia from the Union; and that we trust Virginia may soon be where nature and nature's God intends she should be—in the Southern Confederacy.
                  </p>
                  <p>An able letter was then read from Wm. M. Burwell, Esq., who was unable to be present. Charles A. Bower, Esq., then addressed the meeting in an able, eloquent, and warm Southern speech, after which the preamble and resolutions were adopted with but one dissenting voice.</p>
                  <p>The thanks of the meeting were unanimously tendered to Mr. Bower for his address.</p>
                  <p>On motion, the proceedings of the meeting were ordered to be published in the Lynchburg and Bedford papers, and a copy sent to Messrs. Goggin and Goode, our delegates in the Convention, with a request that they offer the same to that body, and that the letter of Mr. Burwell be also published.</p>
                  <p>On motion, the meeting then adjourned.</p>
                  <p>JAS. M. RUCKER, Chairman</p>
                  <p>A. J. ALMOND, ROBERT W. FREEMAN,</p>
                  <p>Secretaries</p>
                </div>
              </div>
              <p>The proceedings were ordered to be printed and referred to the Committee on Federal Relations.</p>
            </div>
            <div className="section" id="vsc1965.v3.2.7.5">
              <h3><span className="headingNumber">1.7.5. </span><span className="head">THE TAXATION QUESTION</span></h3>
              <div className="speaker" id="sp2763"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The next business in order is the resolutions offered by the gentleman from Monongalia [<span className="persName">Mr. WILLEY</span>] . But there are only <PageNumber num={391} /> two minutes before the hour for the Convention to resolve itself into Committee of the Whole.</p>
              <div className="speaker" id="sp2764"><span className="persName">Mr. WILLEY</span>—</div>
              <p className="p-in-sp">I desire to give notice that I cannot consent to give way for the convenience of gentlemen any longer. I have been giving way for about a month past. Now I give notice that to-morrow I shall ask that a determination shall be arrived at in some form or other.</p>
              <p><span className="persName">Mr. HULL</span>, of Highland, offered the following resolution :</p>
              <p>Resolved, That a committee of three be appointed to take into consideration the propriety of reducing the number of employees of this Convention, and make such reduction, if in their opinion, it can be done without detriment to the public good.</p>
              <p>On motion of <span className="persName">Mr. BURDETT</span> the resolution was laid on the table.</p>
            </div>
            <div className="section" id="vsc1965.v3.2.7.6">
              <h3><span className="headingNumber">1.7.6. </span><span className="head">CORRECTION</span></h3>
              <div className="speaker" id="sp2765"><span className="persName">Mr. BOULDIN</span>, of Charlotte—</div>
              <p className="p-in-sp">Mr. President, I ask to correct the report of my remarks in Convention on Saturday morning last, on presenting the resolutions of the people of Charlotte. In alluding to Mr. Randolph's last struggle for State sovereignty I am made to say, "as has been eloquently alluded to by my friend from Halifax [<span className="persName">Mr. FLOURNOY</span>] , he had been absent, &amp;c."</p>
              <p className="p-in-sp">I said : "His last battle was fought in the same glorious cause. It was during that memorable struggle for State sovereignty in 1833, which has been so eloquently alluded to by my friend from Halifax [<span className="persName">Mr. BRUCE</span>.] " I then went on to say that Mr. Randolph had been absent in the service of his country, &amp;c.</p>
              <p className="p-in-sp">Farther on, in alluding to his last effort in Charlotte, I said : " 'Right clearly in that last battle,' as has been well said, 'did the old war cry ring out once more,' " &amp;c., &amp;c. In appropriating the language of another I always prefer to make the acknowledgment.</p>
              <p className="p-in-sp">I am made to say, in relation to the Charlotte resolutions, "I approve these resolutions." I said I approve the spirit of these resolutions, but differed with my constituents as to time, &amp;c.</p>
              <p className="p-in-sp">There are other slight inaccuracies which it is unnecessary to notice.</p>
            </div>
            <div className="section" id="vsc1965.v3.2.7.7">
              <h3><span className="headingNumber">1.7.7. </span><span className="head">FEDERAL RELATIONS</span></h3>
              <p>The hour of half past ten having arrived, the Convention, in pursuance of its standing order, resolved itself into a Committee of the whole, and resumed the consideration of the report of the Committee on Federal Relations, the 10th section being under consideration, as follows :</p>
              <p>
                "10. Without expressing an opinion as to the question of power, <PageNumber num={392} /> but in deference to the opinion of the Federal authorities, the people of Virginia hereby declare their desire to confer upon the Government of the United States the powers necessary to enable its proper authorities to deal peaceably with these questions, and if it
                shall become necessary, to recognize the separate independence of the seceding States, and to make such treaties with them, and to pass such laws as the separation may make proper."
              </p>
            </div>
            <div className="section" id="vsc1965.v3.2.7.8">
              <h3><span className="headingNumber">1.7.8. </span><span className="head">PRIVILEGE OF MEMBERS</span></h3>
              <div className="speaker" id="sp2766"><span className="persName">Mr. E. B. HALL</span>, of Marion—</div>
              <p className="p-in-sp">When the Convention went into Committee, I was about to call the attention of the President to a matter which is perhaps within the province of the Committee to consider.</p>
              <p className="p-in-sp">
                On yesterday the Convention passed a resolution admitting persons, not members of this body, into the lobbies. It is my fortune to occupy a seat here just in the rear. I do not know how it may have been with others, but in my own case I found the passage leading to the lobby perfectly blocked up, so that there was no free ingress or egress by that passage. The consequence was that I
                was run over by every man who had occasion to pass out or into the lobby; insomuch that I was compelled to vacate my seat and remain standing. What I desire to ask is whether, under the resolution passed yesterday in the Convention, the passage leading to the lobby may be blocked by strangers? If it may, I shall, on the first occasion which presents itself, move to rescind that
                resolution.
              </p>
              <div className="speaker" id="sp2767"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">
                It is certainly within the province of the Chair, as it is within the province of the Committee to preserve order and to secure to members their personal rights. But it is the duty of the Sergeant-at-Arms, without the order of the Convention or of the Chair, to see that the comfort of members is not intruded upon by persons not members of the Convention. The Sergeant-at-Arms will see
                that that duty is executed now, and that in future there shall be no cause of complaint in this respect.
              </p>
              <div className="speaker" id="sp2768"><span className="persName">Mr. BURDETT</span>—</div>
              <p className="p-in-sp">I desire to say in confirmation of the remark made by the gentleman from Marion [<span className="persName">Mr. HALL</span>]
                , that I experienced yesterday all the inconvenience of which he speaks. It was impossible to write without subjecting every thing I wrote to the inspection of persons standing behind me. If these passages are to continue full and overflowing with human beings, I think the resolution had better be rescinded, and if it be in order I will move that the resolution be rescinded.
              </p>
              <PageNumber num={393} />
              <div className="speaker" id="sp2769"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">It is not in order in Committee to move to rescind what has been adopted in Convention.</p>
              <div className="speaker" id="sp2770"><span className="persName">Mr. BURDETT</span>—</div>
              <p className="p-in-sp">Well, sir, it is very easy for gentlemen occupying seats inside to submit resolutions, but it is a serious matter for those of us who occupy seats here in the rear.</p>
              <div className="speaker" id="sp2771"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">It is the duty of the Sergeant-at-arms to see that the passages leading to the lobby are kept clear and to see that members of the Convention are not interfered with.</p>
              <div className="speaker" id="sp2772"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I offered that resolution and it is specifically confined to orderly persons and permits them only to occupy the lobby. These passages are not the lobby. The lobby is merely the open space at the end of the passages, and it is of course the duty of the officers of the Convention to keep the passages clear.</p>
              <div className="speaker" id="sp2773"><span className="persName">Mr. JACKSON</span>—</div>
              <p className="p-in-sp">While the Committee is upon this subject I desire to call attention to another fact. I desire to say that my friend from Fauquier [<span className="persName">Mr. MARR</span>] , myself and several others, were yesterday spit upon by persons in the gallery. [Laughter.] It is certainly disagreeable to be spit upon from that or any other quarter.</p>
              <div className="speaker" id="sp2774"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Chair will suggest that it would be for the Convention to take action in reference to such a matter and not for the Committee.</p>
            </div>
            <div className="section" id="vsc1965.v3.2.7.9">
              <h3><span className="headingNumber">1.7.9. </span><span className="head">FEDERAL RELATIONS AGAIN</span></h3>
              <p>The Committee then resumed the consideration of the report of the Committee on Federal Relations.</p>
              <div className="speaker" id="sp2775"><span className="persName">Mr. CONRAD</span>, of Frederick—</div>
              <p className="p-in-sp">
                I move to strike out of the 1st and 2nd lines of the 10th section, the following words; "Without expressing an opinion as to the question of power, but in deference to," and to insert in lieu thereof, the following words : "concurring with," so that the clause will read : "concurring with, the opinion of the Federal authorities, the people of Virginia hereby declare," &amp;c.
              </p>
              <p className="p-in-sp">
                Mr. Chairman, I am aware that the amendment I have proposed will raise a question which may meet with some opposition in the Convention. Indeed, some diversity of opinion has already been expressed upon this question. It is intended to affirm, substantially, that in the present condition of things, the Federal authorities have no right to deal in any respect, with this question of
                secession or with the questions growing out of the secession of some of the States of the Union. I think the amendment is required in order to make the report consistent with itself. It is also required, I think, in consistency with the action taken by the Convention yesterday, in sending the <PageNumber num={394} /> commission which they did to
                Washington. I think it is all important, if it can be done in the judgment of this Convention, that the position should be assumed that the Federal authorities are in truth paralyzed by the occurrence of this state of things, not contemplated at all in the adoption of the Constitution of the United States. I think it is all important, in order to justify our demands upon the Federal
                Government, that we should found them upon this position of constitutional right.
              </p>
              <p className="p-in-sp">
                My own view, I may remark, without undertaking to argue it, is simply this : That the Constitution was framed simply and only for the normal condition of the Confederacy; framed for its peaceful operation with all the States in the Union, and that there is no authority expressed in the Constitution which would justify the Federal authorities in dealing in any way with the abnormal
                conditions of a disruption of the Confederacy.
              </p>
              <p className="p-in-sp">
                I am well aware that this state of things, if it be conceded, will give rise to questions extremely difficult of solution, some of which were alluded to yesterday, by the gentleman from Princess Anne. I am well aware that it is almost impossible to draw clearly the line of demarcation in regard to this very question of coercion, which this position would mark out for the Federal
                authorities to follow. There are difficulties in regard to these forts. My own opinion is, that in general the Federal Executive is bound to preserve—and I think the gentleman from Princess Anne indicated the same opinion yesterday—is bound to preserve the status quo; that he is not permitted to act upon the subject in any way which either expressly or by implication would assume to
                decide. He has not the right to re-take the Forts, which, by the act of secession, or after the act of secession, have come within the possession of the seceded States. In my opinion, he has not the right even to maintain or hold any fort, which is within—as the gentleman termed it—the walls or boundaries of a State which has withdrawn from the Union, and that was erected for the
                purpose of local defence only, or a fort, the possession of which would be likely to intimidate, or could be used rather for the purpose of intimidation, which would be likely to provoke war with that State. Yet, at the same time, I am by no means prepared to say, that under this obligation to preserve, as far as he can, until the matter can be remitted to the proper authorities—the
                other States of the Union—to settle and adjust it, the status quo, that the President would have the right to give up the forts, which are national in their character, although nominally within the limits of seceded States. I would take the fort, <PageNumber num={395} /> for instance, to which the gentleman from Princess Anne yesterday referred, upon
                the Florida Keys. That fort, as I understand, is remote from any settlement in Florida. It can by no possibility be used for the purpose of intimidating or embarrassing the people of Florida. It is nominally within the limits and jurisdiction of that State, yet it was erected in the place where it stands for nothing else than to protect the general commerce of the Gulf of Mexico. It
                has cost the United States an immense sum of money. It was erected at the cost of the National Treasury for purposes of National defence. The State of Florida can have no use for it, and I am not prepared to say that it would be the duty or the right of the President of the United States at this time, to surrender that fort to the authorities of Florida, or that it would be properly
                termed coercion to fortify or reinforce, or maintain that fortification for national purposes.
              </p>
              <p className="p-in-sp">
                The same may also be true in reference to the fort which commands the mouth of the Mississippi river. But, sir, I merely allude to these instances for the purpose of stating that these are difficulties necessarily growing out of this position, and that it is, therefore, the duty of the Federal Authorities to practice forbearance, to desist from all hostilities, because of the
                intrinsic difficulty there is of drawing this line of demarkation as to the duty of the Executive in regard to this matter. All I would ask would be a clear indication, upon the part of the Executive, that so far as possible it would aim to preserve the status quo until the States which, in my opinion, alone have the right of determining this question, should have the opportunity of
                doing so, that he would do nothing which would be likely to lead to a collision of the forces of the Federal Authorities and of the Authorities of the Seceded States. And that is the general indication of the report of the Committee.
              </p>
              <p className="p-in-sp">
                We have requested this Southern Confederacy also to forbear from any act likely to give rise to hostile collision. We have requested both parties to forbear until the people of Virginia have exhausted every effort which they propose to make to reconcile these differences, or, if irreconcilable, to settle them peaceably. I, therefore, think that this amendment ought to be adopted,
                and, I trust, that the Convention will concur in that opinion. I think there can be no difference of opinion if we place it upon this clear, strong, foundation of constitutional right in our demand upon the Federal Executive not to attempt any force. If we can do that it must be better than to place it upon the ground of mere request, asking him only as a matter of favor instead of
                demanding as a matter of right.
              </p>
              <PageNumber num={396} />
              <div className="speaker" id="sp2776"><span className="persName">Mr. BOULDIN</span>—</div>
              <p className="p-in-sp">
                I trust that the committee will pause before it affirms that this high attribute of sovereignty—the capacity to make a treaty with the Confederate States exists, at this time, no where in this country. I trust the committee will pause before it follows the able Chairman of the Committee on Federal Relations in taking a position which is to denude the Federal Government, denude the
                State of Virginia, denude every State now remaining in the old Union of the United States of the power to make any treaty whatever with the seceded States. The position assumed is that, in the present abnormal state of things, as it is called, there is no power in the Federal Government to deal in any manner with these questions with the seceded States.
              </p>
              <p className="p-in-sp">
                It is not denied, by the able Chairman of the Committee on Federal Relations, that we are still bound by the Constitution of the United States, and by each and every provision thereof. That instrument denies to Virginia the power to treat with any State whatever, either to form alliances, make treaties, enter into confederations, or make any other arrangement whatever of that
                character; and the gentleman says that the Federal Government has not that power. He wishes the people of Virginia, now in the crisis of our fate, when action, and immediate action, becomes every day more necessary, solemnly to declare that there is no human power that can treat with the Confederate States. They are called upon to say that this high attribute of sovereignty, this
                power to make a treaty, is a myth; that it is, as the law writers say, in abeyance; that it is nowhere; that it is not held by the States, because by the Constitution it is denied to them; and that it is not held by the Federal Government, because they say, that in this abnormal state of things it is not there. It is a myth—an airy nothing—wholly impotent for good. War may be upon
                us, the enemy may be thundering at the gates of the capital, a moment may bring on a state of things which would urgently require prompt and immediate action, which would make a treaty indispensable ; but yet the gentleman tells us that there is no human power that can make that treaty. And why does he say so? Because, he says, the present state of things was not contemplated by the
                framers of the Constitution.
              </p>
              <p className="p-in-sp">
                He does not deny that the power is plainly given. The grant is general, unlimited. The President, by and with the consent of the Senate, has the power to make treaties, &amp;c. The power thus conferred is general and unlimited; and I say that both the spirit and letter of that grant of power means this, if it means anything, that the
                <PageNumber num={397} /> President shall make all treaties which the exigencies of the government may at the time require. When you look to the fact that the power to make these treaties is denied to the States, you are bound to give a fair construction to the power given to the President and Senate, and say that it means what I have just said it ought
                to mean—the power to make such treaties as the exigencies of the government may require.
              </p>
              <p className="p-in-sp">
                But I go farther. I take issue with the gentleman, and say that the Constitution itself, when it was framed, not only looked to this state of things, but could not have been formed without looking to it. Why do I say so? When the Constitution was formed, we were then bound in a bond of perpetual union, called the Old Articles of Confederation. The present Constitution provides that
                when nine States should ratify it, it should be the complete government of the nine States so ratifying it, and they should be the United States. Here, then, the attention of the States was called directly, in the very forming of the instrument itself, to a condition both of secession and of excision—of the secession of the nine States which should ratify, and the excision of the
                four which might fail to do so. Did they contemplate that the nine States which should accept the Constitution should have no power whatever to deal with the four that might not accept? They were then bound in a union of thirteen States; and the Constitution provided that when nine should accept the new Constitution, it should be the Constitution of the nine accepting it. Is it to be
                supposed that in thus framing the Constitution they did not look to the possibility, aye to the probability, of some of the States being out of the Union from the fact of their not accepting the Constitution, and of the necessity of making treaties with them?
              </p>
              <p className="p-in-sp">
                The fact was, that for some year or more, one or two States did not come into the Union. Was there no power to make a treaty with those that were out? The power to make treaties was given to the President by and with the consent of the Senate, and that power was denied to the States. I ask, is it reasonable to suppose that the States did not contemplate that a state of things might
                arise which the very instrument itself provided for, and that there would or might be a necessity of making treaties with the States cut off?
              </p>
              <p className="p-in-sp">
                But I come down to another fact in history. Years later we added to the Federal Union the foreign State of Texas. We added it by joint resolution and negotiation. Prior to its admission, we had, of course, the power to treat with Texas, and we did treat with her. We agreed to pay her debts, and by joint resolution and negotiation she
                <PageNumber num={398} /> was brought into the Union. The States that made that arrangement with Texas, through their authorities at Washington, had power to make a treaty, and did in effect treat with her before she came into the Union. Suppose that Texas had been the only State to secede. I desire to know whether she could thus deprive the other States
                of the power of treating with her? Suppose there had been difficulties in construing and carrying into effect the terms of the agreement under which she became a member of the Union, and she had seceded alone and made war on the United States to compel a compliance with the contract of annexation. Is it possible that there would have been no longer a power to treat with her? That the
                mere fact that she had been for a short time a member of the Union, is of itself sufficient to deprive the Union, after her secession, of a power it clearly had before? Could she thus deprive them of this essential attribute of sovereignty? Are we prepared to admit that this is a reasonable doctrine? Now, I do not deny the position assumed by the able Chairman of the Committee on
                Federal Relations, and by the distinguished gentleman from Fauquier
                [<span className="persName">Mr. SCOTT</span>]
                , who argued this question in the Convention a day or two ago, that the offence of separating from the Union—if offence it be at all—is an offence by the seceding States against the other States. But how are the States which remain and which hold the Federal Government as their Government to deal with this offence? They have constituted a Government to deal with it. By and through
                the Federal Government, their agent, they do deal with it, and they can deal with it no other way. The language of the Constitution is sufficiently broad to cover the ground. The Federal Government has, within the letter and the spirit of the Constitution, the power to treat with the seceded States, and absolute necessity requires at this time the exercise of that power. The simple
                question, I repeat, is, whether now, in this, the very crisis of our fate, when at any moment war may be in this land, from one end of it to the other, Virginia in Convention, shall solemnly say that neither the States nor Federal Government have the power in any form to deal with this question.
              </p>
              <p>Here the Chairman's hammer fell.</p>
              <div className="speaker" id="sp2777"><span className="persName">Mr. R. Y. CONRAD</span>—</div>
              <p className="p-in-sp">
                Of course it is impossible, Mr. Chairman, to answer, within the space of ten minutes the acute and able argument delivered just now, and which is an addendum to an argument delivered the other day by my friend from Charlotte. I will, however, endeavor briefly to answer his points. But, first, I am requested by the gentleman from Princess Anne to state that he does not concur in the
                <PageNumber num={399} /> proposition which I make, although my inference was that the amendment which he offered the other day looked that way.
              </p>
              <p className="p-in-sp">Now, sir, I would first call the attention of the Convention to the fact that the rights of peace and war, in the Federal authorities, must be corelative.</p>
              <p className="p-in-sp">If, under the treaty making power, as alleged, the President of the United States has a right to make a treaty with the seceded States, acknowledging their independence, he must have the corelative right of making war. Therefore, the necessity of asserting this proposition of constitutional law.</p>
              <p className="p-in-sp">The gentleman asks whether it is possible that the Federal Government does not possess the sovereign power of making treaties? Unquestionably it does, but under express grant. But I hold that all these powers—war power, treaty power, commercial power—looked only to the foreign relations of the government.</p>
              <p className="p-in-sp">
                This is an answer to the argument of the gentleman from Charlotte, in regard to the provision that nine of the States under the old Confederation might set the new government in motion; and he asks emphatically, could they not treat with the States refusing to come in? Unquestionably they could, because the States connected under the old Confederation were not States within one
                government. It never has been pretended that the old Confederacy was a Constitutional Government. It was merely a league between the States; and, therefore, notwithstanding the Confederacy which was formed for the purpose of the Revolutionary war, the States remained foreign to each other. The nine States, then, would have had the same right to deal with the States which refused to
                come under the Federal Constitution, as they had to deal with foreign nations. But the question is materially altered when the States have come in. They are then connected under one and the same government, and the question is, does the government agent possess the power to deal with the question of a disruption of that government? I say the Constitution contains not one word looking
                to that object.
              </p>
              <p className="p-in-sp">
                The gentleman has adduced as another reason, the admission of Texas into the Union; but does not my friend from Charlotte know that there is in the Constitution an express power to admit States into the Union, without any power to allow States, by treaty, or in any other mode, to sever themselves from the Union, and that is a strong proof of my position that it was a casus omissus?
                That it never entered into the contemplation of the framers of the government to provide for the case of departure of any State from the Union, is proved by the <PageNumber num={400} /> fact, that while they provided expressly for the admission of new States—which new States need not be formed out of any previous territory of the United States—no
                provision, whatever, is made for the departure of any State out of the Union.
              </p>
              <p className="p-in-sp">
                Now, the only serious difficulty urged in a practical point of view, to this position, was urged the other day by the gentleman from Princess Anne, which I had no opportunity of answering. He pressed with great force, and, as I apprehend, with considerable effect, the difficulty that might arise in conferring this power. He urged that it squinted at the necessity of a National
                Convention to amend the Constitution of the United States. Now, in my apprehension, no such necessity exists. The terms of our resolution are amply sufficient, in my view, to confer this very power so far as the State of Virginia is concerned. I believe it may be conferred by joint resolution of the Legislatures of the different States. Nay, more ; I believe it can be conferred by
                simple acquiescence. Where is the necessity for any amendment of the Constitution in order to confer a power on the Federal authority to deal with this subject? It is not a permanent power. There is no necessity to incorporate it in the Constitution of the United States, because it is to act only on this present emergency. It is analogous to the case of the purchase of Louisiana.
                There was some talk of amending the Constitution at that time, but it was considered that the mere tacit acquiescence of the people of the U. States in that purchase had confirmed its legality. And so it ought to be here. The expression of opinion by the constitutional authorities of a State would, so far as that State is concerned, confer on the Federal authority the power to
                act—with a limit that it should be a power to act peaceably, not forcibly. We take the position that now the Federal authority has no right to deal with this question in either aspect—either of war or of peace. We propose, so far as Virginia is concerned, by a resolution of this Convention, to confer on the Federal authority the power to deal peaceably with it, and to recognize the
                independence of the seceded States. We can then go to the Federal authorities and say, "You have no authority or right to deal otherwise with this subject. You have a power under the Constitution to make war upon and use coercion towards these seceded States. If you do so, you put yourselves in the attitude of mere usurpers of power." We will then have the right, without violating a
                single constitutional obligation of Virginia, without the slightest breach of our plighted faith, to make war upon the Federal Executive, because it would not be acting under its constitutional authority, but on a gross usurpation of power.
              </p>
              <PageNumber num={401} />
              <div className="speaker" id="sp2778"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The question will be upon the amendment offered by the gentleman from Frederick [<span className="persName">Mr. CONRAD</span>] .</p>
              <div className="speaker" id="sp2779"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I ask that the latter clause of the 9th resolution shall be read, so that it may be seen what opinion it is that we are concurring in.</p>
              <div className="speaker" id="sp2780"><span className="persName">Mr. CONRAD</span>, of Frederick—</div>
              <p className="p-in-sp">
                I will ask the indulgence of the Committee. I see the point of the proposition of the gentleman from Princess Anne, and I find that the amendment I proposed does not express my full idea. I will ask the indulgence of the Committee to detain it one single moment, in order that I may change the form of the amendment, not the substance. My object is merely to carry out the idea
                suggested by the gentleman from Princess Anne.
              </p>
              <div className="speaker" id="sp2781"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I suppose if this is a new proposition, it will be open to debate.</p>
              <div className="speaker" id="sp2782"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Chair thinks so.</p>
              <div className="speaker" id="sp2783"><span className="persName">Mr. MOORE</span>—</div>
              <p className="p-in-sp">I object to the amendment, for I see it is going to lead to debate.</p>
              <div className="speaker" id="sp2784"><span className="persName">Mr. CONRAD</span>—</div>
              <p className="p-in-sp">I hope the Committee will permit the amendment to be made. I hope the gentleman from Princess Anne [<span className="persName">Mr. WISE</span>] , will make no objection.</p>
              <div className="speaker" id="sp2785"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I shall make no objection. I think, however, the proposition is open to debate.</p>
              <div className="speaker" id="sp2786"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">Will the gentleman indicate the change which he proposes to make in order that the Chair may see whether any change is proposed in the substance of the proposition before the Committee.</p>
              <div className="speaker" id="sp2787"><span className="persName">Mr. CONRAD</span>—</div>
              <p className="p-in-sp">I propose to strike out the first part of the 10th resolution to the word "authorities" in the 3d line, and to insert in lieu thereof the words "believing that the Federal authorities have no power to deal with this subject in any way."</p>
              <div className="speaker" id="sp2788"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">That is a new proposition.</p>
              <div className="speaker" id="sp2789"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">Entirely, sir, and the Chair must take the question on the pending amendment, unless leave be given to the gentleman from Frederick, to withdraw it. The CHAIR will now read the latter clause of the 9th resolution, the reading of which was called for by the gentleman from Princess Anne a few minutes ago.</p>
              <p className="p-in-sp">"But the Federal authorities, under the Constitution as it is, disclaim power to recognize the withdrawal of any State from the Union, and consequently to deal with these questions, holding that it is reserved only to the States as parties to the Government compact to take lawful action touching them."</p>
              <PageNumber num={402} />
              <p>The question was then taken on the amendment offered by <span className="persName">Mr. CONRAD</span> and decided in the negative.</p>
              <div className="speaker" id="sp2790"><span className="persName">Mr. CONRAD</span>—</div>
              <p className="p-in-sp">I now submit the amendment to which I referred a few minutes ago, which is, to strike out the first part of the 10th resolution, down to the word "authorities," which reads, "Without expressing an opinion as to the question of power, but in deference to the opinion of the Federal authorities," &amp;c., and to insert the following:</p>
              <p className="p-in-sp">"Believing that the Federal authorities have no power to deal with this subject in any way," &amp;c.</p>
              <div className="speaker" id="sp2791"><span className="persName">Mr. BALDWIN</span>, of Augusta—</div>
              <p className="p-in-sp">I hope it will not be the pleasure of the Committee to make the amendment which has been suggested.</p>
              <p className="p-in-sp">
                The object of these 9th and 10th sections, as I understand, is simply to state the opinion of the Committee, that the Federal Government has constitutional difficulties in regard to the solution of the question which are presented by the secession of the Confederate States, and to express their willingness, if such be the fact, to remove all difficulties out of the way as far as the
                consent of Virginia can do so. I understand the opinion of the Committee, as gathered from their report, to be this : that they are not prepared to determine whether the General Government has now constitutional power to recognize the independence of the seceded States. That is a question of extreme novelty, difficulty and delicacy. We wish to steer clear of committing ourselves in
                any way upon that question. But as a doubt has been expressed, on the score of constitutional power, we desire, if any difficulty of that sort is in the way, that it shall be removed out of the way. That I understand to be the report of the committee, and I think it is the proper ground. I suppose there is no member of this committee who would hesitate to declare, that if the
                Government has power to part peaceably with these Confederate States, that that power ought to be exercised, in preference to any attempt whatever to seek collision with them, or enforce authority over them. I suppose there is no difficulty upon that subject—that all power which the Government possesses, ought to be exercised in favor of a peaceable solution of our difficulties. So
                far, there is no difference among us; and the report even goes farther than that; it proposes to confer upon the Government this power, if it does not now possess it. That I understand to be the report of the committee.
              </p>
              <p className="p-in-sp">
                Now, the proposition to amend here, is a proposition to declare, as the opinion of this Convention, that the Government of the United States has no authority to deal with the subject at all. I am not <PageNumber num={403} /> willing to declare that opinion, because, if the Government of the United States can exercise no discretion upon this subject, we
                are rushed at once, as it seems to me, directly into the jaws of civil war; and I am not willing to recognize the obligation of the Government to take any such step. I do not wish this Convention to express any opinion which will furnish the occasion to anybody, hereafter, to quote us as authority for the necessity for war. I don't want to express any opinion which might be perverted
                by any one, hereafter, to the injury of the cause that I have so much at heart—the cause of peace; and I think it was an exercise of wise discretion and prudence on the part of the committee, to abstain altogether from the expression of any opinion upon these questions. Let us confine our duty to the mere declaration that, if the Government has the power, we want it, in God's name,
                to exercise all it has in favor of peace. If it has not the power, we are ready to give to them all the power that is necessary to maintain the peace. That I understand to be your construction of the committee's report, and I hope it will be the pleasure of the Committee to adopt the report, and declare this view of it.
              </p>
              <div className="speaker" id="sp2792"><span className="persName">Mr. CONRAD</span>, of Frederick—</div>
              <p className="p-in-sp">
                I don't purpose to trouble the Convention again, sir, with a repetition of the views which I endeavored briefly to state before; and this is no time to go into an extended argument on this question. It is true, and I should perhaps have so stated when I was up before, that this proposition is made by me, not as representing the opinions of the Committee, but upon my own individual
                responsibility as a member of the Convention; nor do I understand that there is anything in the report of the Committee adverse at all to the view which I present. I am still more at a loss to imagine how, by any possibility this position, taken by the Convention, would lead directly or indirectly to civil war. My object concurs fully with that, doubtless, of every member of the
                Convention, to preserve peace if possible; and my opinion is, that the very best mode for preserving peace is the assumption of this position by this Convention. We deny to the President of the United States any power to deal with this question at all. What is the consequence? That his arm is paralyzed; all he can do is to preserve the question in the condition in which it stands,
                until those who have a right to deal with it have settled it. That is to be the result. He has no right to make war upon these seceded States; he has no right to assume any attitude of hostility towards them, because he has no right to make peace; he can neither acknowledge nor deny their independence. But it remains for the co-States of the Union—those with whom the
                <PageNumber num={404} /> seceded States entered into the compact—to determine what course it becomes them to take in consequence of the action of the seceded States. And I hold that these seceded States are fully responsible to them. They may confer upon this Federal Government, which is their agent, power to deal with this subject—they may confer the
                power to deal with it in either aspect—either peaceably or forcibly; and we propose here to assert that it shall be dealt with peaceably and that the independence of these States shall be acknowledged. I suppose, sir, that we have no right to make a demand upon the Federal authority unless we can establish it upon the basis of right; upon the mere ground of forbearance (especially as
                we are disposed to view the disposition of the present Federal Executive) , it may prove insufficient, and would, at last, be a mere favor granted to us, and not because the Federal Government is bound to take the course which we indicate and approve.
              </p>
              <p className="p-in-sp">I prefer, and I trust this Convention will prefer, to put it upon the ground of a denial to the Federal authorities of power to deal with the subject at all.</p>
              <div className="speaker" id="sp2793"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I call for the yeas and nays upon the amendment.</p>
              <p>The call was sustained, and the vote being taken resulted—yeas 12, nays 114, as follows :</p>
              <p>YEAS-Messrs. Armstrong, Brown, Carter, R. Y. Conrad, Early, Gillespie, Marshall, Osburn, Pugh, Sharp, C. J. Stuart, White-12.</p>
              <p>NAYS-Messrs. Janney [President]
                , Ambler, Aston, Baldwin, A. M. Barbour, James Barbour, Baylor, Berlin, Blakey, Blow, Boggess, Boisseau, Borst, Bouldin, Branch, Brent, Bruce, Burdett, Byrne, Cabell, Campbell, Caperton, Cecil, Chambliss, Chapman, Coffman, Conn, C. B. Conrad, Couch, R. H. Cox, Critcher, Custis, Dent, Deskins, Dorman, Dulany, Echols, Flournoy, French, Fugate, Garland, Graham, Gravely, Gray, Gregory,
                Goggin, Jno. Goode, T. F. Goode, Hale, Addison Hall, Cyrus Hall, Ephraim B. Hall, L. S. Hall, Hammond, Harvie, Haymond, Hoge, Holcombe, Hubbard, Hughes, Hull, Hunton, Marmaduke Johnson, P. C. Johnston, Kent, Lawson, Leake, Lewis, McComas, Macfarland, C. K. Mallory, J. B. Mallory, Marr, Marye, Masters, Moffett, Montague, Morris, Morton, Moore, Neblett, Nelson, Orrick, Parks, Patrick,
                Pendleton, Porter, Price, Richardson, Rives, R. E. Scott, Wm. C. Scott, Seawell, Sheffey, Sitlington, Slaughter, Southall, Speed, Spurlock, Staples, Strange, Summers, Sutherlin, Tarr, Tayloe, Thornton, Tredway, Tyler, Waller, Wickham, Willey, Williams, Wilson, Wise-114.
              </p>
              <p>So the amendment was not agreed to.</p>
              <p><span className="persName">Mr. MILLER</span> paired off with <span className="persName">Mr. CLEMENS</span>.</p>
              <p><span className="persName">Mr. R. H. COX</span> stated when <span className="persName">Mr. ISBELL</span>'S name was called, that that gentleman was absent from illness.</p>
              <p><span className="persName">Mr. WYSOR</span>, when his name was called, said that he had paired <PageNumber num={405} /> off with the gentleman from Marshall [<span className="persName">Mr. BURLEY</span>] , who was detained in his room on account of sickness and the inclemency of the weather, otherwise he would have voted in the negative.</p>
              <p><span className="persName">Mr. JOHNSON</span>, of Richmond, stated, when the name of Mr. Cox, of Chesterfield, was called, that that gentleman was absent on account of illness.</p>
              <div className="speaker" id="sp2794"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I move to strike out the 10th section and insert in lieu thereof the following:</p>
              <p className="p-in-sp">"The people of Virginia hereby declare their consent to the recognition of the separate independence of the seceded States; that they shall be treated with as independent powers and that the proper laws shall be passed to effectuate their separation."</p>
              <p className="p-in-sp">I had not the opportunity of getting the floor upon the amendment offered by the gentleman from Frederick [<span className="persName">Mr. CONRAD</span>]
                . Now, that the whole subject is open to us, I do not pretend to say that this body is to exercise the power of recognizing the separate independence of these States. All I propose is to express our consent to the recognition of their independence, and that is the true position in which I think that a late confederate of the seceded States should stand—to say to the agent, and to the
                principal parties, that as far as we are concerned, we consent to the separation. Now, what does the resolution of the Committee propose?
              </p>
              <p className="p-in-sp">
                They say, "without expressing an opinion as to the question of power"—and very prudently they have carried out that intention. But do they express no opinion? They do express an opinion, indeed, "without expressing an opinion as to the question of power," but in deference to the opinion of the Federal authorities, the people of Virginia hereby declare their desire to confer upon the
                Government of the United States, which, still in deference to the opinion of the Federal authority, is the opinion on our part that there is a necessity to confer the power, and that believing the necessity of the power to be conferred, we admit, out of deference to the opinion of the Federal authorities, that there is no such power already existing—for if there is such power already
                existing, there is no necessity for the declaration that the power ought to be conferred. This resolution goes on further to say:
              </p>
              <p className="p-in-sp">"The powers necessary to enable its proper authorities to deal peaceably with these questions, and if it shall become necessary, to recognize the separate independence of the seceding States, and to make such treaties with them, and to pass such laws as the separation may make proper."</p>
              <PageNumber num={406} />
              <p className="p-in-sp">
                What would it take, under this resolution, to confer the power? You have to confer the power by an amendment of the Constitution. How would matters stand, admitting that the power does not already exist? You would have to amend the Constitution by calling a National Convention, and the real effect of this resolution is, to make the necessity for a National Convention.
              </p>
              <p className="p-in-sp">Now, what does my friend from Frederick [<span className="persName">Mr. CONRAD</span>]
                , mean by the status quo. He says that under power of holding forts in statu quo, they may hold on to those that they have in possession at the time of the withdrawal. I presume he will agree, if the principle applies to the forts, that it applies to every other subject matter. Am I right in that, that the status quo, if it is to be preserved in one respect, must be preserved in all?
                Now let me call the attention of my friend from Frederick
                [<span className="persName">Mr. CONRAD</span>]
                , to what would be the result of this status quo at the time of the withdrawal? Was it a fact that these States were States of the Union? According to the Constitution of the United States, "no State shall enter into any alliance, treaty or confederation." If the status quo is to be preserved, does not the gentleman perceive that the Federal Government will be bound to array itself
                against the Confederated Southern States, against their making any alliance with foreign powers, against their emitting bills of credit, against their making anything but gold and silver a tender in payment of duties, against their power to pass any bill of attainder or ex post facto laws, or laws impairing the obligation of contracts, or against their granting any title of nobility?
                Does he not see further, that no State, without the consent of Congress, can lay any impost or duties upon imports or exports? Would not the status quo compel us to war upon that power of levying duties, as well as the power to occupy the forts?
              </p>
              <p className="p-in-sp">
                Again, sir, no State, without the consent of Congress, can lay any duty of tonnage, or keep troops. Would not the status quo operate to disband the troops that they have raised? Would not the status quo forbid them to keep ships of war in time of peace? Would not the status quo forbid them to enter into any agreement or compact with another State? Does he not see that this doctrine
                of status quo, by the very terms of the Constitution of the United States, would necessarily bring on a conflict between the Federal Government, that is sworn to execute these laws, and the States that have withdrawn? Sir, the doctrine of status quo would nullify the mission of peace upon which our messengers are now departing from this capital. Suppose when they get to Washington,
                and they approach the President, upon this <PageNumber num={407} /> subject, he shall tell them : "Why, gentlemen, as I have not the power to recognize the withdrawal of these States, I am left necessarily to execute the powers of this constitution; I am to see that no treaty of alliance or confederation is entered into by these States as well as to
                hold the forts belonging to the United States; I am compelled to see that they emit no bills of credit, that they raise no money by any such means to resist the Federal Government." Does not the gentleman see to what the doctrine of status quo would lead him? How can he distinguish and say that the status quo shall be preserved in relation to the forts, but in relation to nothing
                else?
              </p>
              <p className="p-in-sp">Mr. Chairman, let me call your attention and the attention of the gentleman from Frederick [<span className="persName">Mr. CONRAD</span>]
                , to the broad distinction that the constitution of the United States, even in respect to States remaining in the Union without a withdrawal, without a separation, takes between treaty and alliance on the one part and a compact and agreement on the other. Under no circumstances, unequivocally, unconditionally is a State remaining in this Union forbidden to enter into any treaty,
                alliance or confederation. Is that the fact in relation to compact or agreement? No, sir! The 3d section of the constitution says
              </p>
              <p className="p-in-sp">"No State shall, without the consent of Congress, lay any duty on tonnage, keep troops or ships of war in time of peace, enter into any agreement or compact with another State or with a foreign power, or engage in war, unless actually invaded, or in such imminent danger as will not admit of delay."</p>
              <p className="p-in-sp">
                Then, if these States deemed themselves in such imminent danger as would not admit of delay, they had a right, expressly, and without the consent of Congress, by the Constitution of the United States, to make a compact or agreement, though they have not the right, if they are still States of the Union, to make alliance, confederation or treaty proper; for compact and agreement are
                both distinguishable from treaty, alliance or confederation by the political law, and expressly by the Constitution of the United States. If they hold that these States are still in the Union, there is a power, in the midst of imminent danger, to make a compact or agreement with them. If they can make that compact or agreement with another State or with a foreign power, or actually
                engage in war—in such a condition will gentlemen tell me that they cannot make a compact or agreement with their own Government, simply to forbear hostilities until all the Government authorities can be called upon to act, and all the parties to the compact can be called upon to consent?
              </p>
              <PageNumber num={408} />
              <p className="p-in-sp">
                Sir, the proposition is too plain to admit of controversy with any gentleman who will compare these two or three sections. Our forefathers never committed such an error as to omit this power of compact in the midst of dissension and imminent danger, arising from dissension among the parties to the compact. They never were guilty of such gross, flagrant omission, of not allowing, in
                some form, that greatest of all powers on earth, the power of peace.
              </p>
              <div className="speaker" id="sp2795"><span className="persName">Mr. R. Y. CONRAD</span>—</div>
              <p className="p-in-sp">
                The gentleman from Princess Anne greatly misapprehends the position which I take. The status of which I spoke was the status of the federal authorities, not of the seceding States. And the term is not applicable, as I conceive, at all to questions of right or power, but simply to questions of condition or possession. The term is only used in reference to the actual position of
                parties at a particular moment. Now I say the status of the federal authorities for themselves, because they have no right to deal with these seceding States, and the gentleman answers that if the status is to be preserved, they are to exercise all the powers conferred upon them by the constitution. The term is not applicable to any question of power or right as it is originally
                used.
              </p>
              <p className="p-in-sp">
                But I am somewhat surprised that the gentleman should have misunderstood my position, but I do not mean to trouble the Convention again by adverting to this question at all. The Committee have decided upon the amendment I proposed and I bow with cheerful submission to that decision. I have only to say in reference to the amendment of the gentleman from Princess Anne, I shall vote for
                it, as at present advised. I think, as the Committee have refused to give to the section the character which I have endeavored to give it by the amendment I propose, the amendment proposed by the gentleman from Princess Anne may well be substituted for it.
              </p>
              <div className="speaker" id="sp2796"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">My friend does not alter the case at all. I know that his own mind, astute and subtle as it is, is capable of perceiving, upon a moment's reflection, that he has not altered his proposition at all. What does he say? He does not speak of the status of a State, but he speaks as to the status of the federal authority. Are they not convertible terms?</p>
              <div className="speaker" id="sp2797"><span className="persName">Mr. CONRAD</span>—</div>
              <p className="p-in-sp">I say that the Federal Government has nothing to do with the status of the seceding States.</p>
              <div className="speaker" id="sp2798"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                The Federal authorities—I take him as he last states his proposition—are not to regard their powers, but their condition and possession. Now, at the time of the withdrawal of these States, did <PageNumber num={409} /> they not possess the power to prohibit the State from forming a treaty of alliance or confederation as well as possessing forts?
              </p>
              <div className="speaker" id="sp2799"><span className="persName">Mr. CONRAD</span>—</div>
              <p className="p-in-sp">Not after the secession.</p>
              <div className="speaker" id="sp2800"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                Why not after the secession? If a State has power, by withdrawal, to absolve herself from this obligation not to make a treaty—grant that and you grant all she wants—to hold forts. How can the gentleman escape that logic? She has got the right to make a treaty, to make alliance, even to grant titles of nobility, to emit bills of credit and do anything else that is forbidden by the
                Constitution to be done, and yet has not the right to take possession of her own forts. Will that hold water in the mind of any man that compares one idea with another? My friend, I know is not uncandid, and if he perceived this, he would acknowledge it; but it shows me that he is somewhat blindly wedded to the conclusion that the Federal Government has a right to hold on to these
                forts. And he asserts this as his opinion. I say to him, that if the Federal Government assumes the power, exercises and enforces the power to hold on to these forts, that it is just as dangerous to the peace of his people and of my people as if they assailed the Confederation formed by these seven States; to form which requires a power, requires a right to be acknowledged, requires
                the exercise of a sovereignty, which is all that is required to take forts themselves from the Federal Government.
              </p>
              <p className="p-in-sp">
                Sir, let me say to the gentleman, that the Fort on the Tortugas Isles is within the limits of Florida. Let me say to him that Fort Pickens is within the limits of Florida. The State of Florida, like the State of Virginia, holds all the islands within the marine league on her coast. To hold the fort upon the Tortugas is just as much an invasion of her sovereignty as an aggressive war;
                if she be sovereign enough after a withdrawal from the Confederacy, to emit bills of credit, to make a treaty or alliance, she is sovereign enough to hold the forts within her limits; and the attempt to take these forts, or to continue the occupation of them will threaten war, and produce a war, as fearfully, as certainly, as for the General Government at once to attack the Southern
                Confederacy itself ; for to hold the forts, is, in fact, to deny their right of confederation. You deny all their powers by this 10th section. The denial of one upon the ground of status quo, is a denial of all ; and the denial of any power that is essential to maintain the condition of those Southern States, with regard to the condition and power, and position of the Federal
                Government, is war, inevitable war. This Administration is now holding on to these forts. The gentleman from Harrison
                [<span className="persName">Mr. CARLILE</span>]
                , said yesterday, that he knew <PageNumber num={410} /> of nothing that the Federal Government had done that indicated a purpose of war. I say, sir, that the holding of these forts—whether peace or war be their purpose—the holding on to these forts will inevitably bring on war, and they know it. Yet, knowing it, they are sending re-inforcements of men
                and supplies of provisions and strengthening and fortifying the forts with all the munitions and implements and muniments of war. The war steamer is on her path on the deep. Within ten days of their sailing from New York, when the attempt shall have been made to reinforce these forts, you may hear the echo of the booming of the cannon which proclaims that war, a flagrant war has
                begun. Yet, in the face of these facts, whilst war is on its march to the site of war, we find started this doctrine of status in quo that raises every dire issue that can grow out of the question of peace or war. I ask this committee, I implore them, not to commit themselves upon this tenth resolution. It is unwise to pass these propositions to wait until a National Convention, or
                until Congress shall confer the power to amend the Federal Constitution ; for, whilst in the midst of waiting we may hear the clash of the arms of war.
              </p>
              <p className="p-in-sp">
                Sir, I have been accused of being belligerent. I say, as I said to a friend who asked me privately on the floor yesterday my opinion—a gentleman for whose own opinion I have great respect—when he asked me to answer him in a moment, "were you President what would you do in respect to these forts?" I said to him, without hesitation, "It would depend upon my construction of my powers.
                With my opinions, I should forbear. With the opinions of gentlemen who believe that the Federal Government is bound to hold on to those forts, with the opinions of those gentlemen, who believe that the Federal Executive is sworn to execute the laws, and is bound to enforce them at all hazards, I suppose I should be bound to execute them." He replied to me, "and I concur in the
                sentiment." "Sir," he said, "my opinions would require me to evacuate those forts at once, as a matter of peace, as a matter of policy, as a matter of patriotism, for the purpose of restoring the peace and harmony of the Union." I could not but assent to the wisdom as well as the pacification of the maxim. They ought to be evacuated ; and we ought to consent to the recognition of the
                independence of those Southern States.
              </p>
              <div className="speaker" id="sp2801"><span className="persName">Mr. SCOTT</span> of Powhatan—</div>
              <p className="p-in-sp">I ask for a division of the question, for I want to say a few words upon the question of striking out.</p>
              <div className="speaker" id="sp2802"><span className="persName">Mr. CARTER</span>—</div>
              <p className="p-in-sp">I wish to hear the substitute reported.</p>
              <p>The Clerk reported the substitute.</p>
              <PageNumber num={411} />
              <p>The question was then taken on the motion to strike out, which was carried in the affirmative, a call for a division of the House having been made by <span className="persName">Mr. SHEFFEY</span> and the count given up.</p>
              <div className="speaker" id="sp2803"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The question recurs upon amending the tenth resolution, by inserting the proposition of the gentleman from Princess Anne [<span className="persName">Mr. WISE</span>] .</p>
              <div className="speaker" id="sp2804"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">I call for the ayes and noes.</p>
              <p>The call was sustained.</p>
              <div className="speaker" id="sp2805"><span className="persName">Mr. SCOTT</span>, of Powhatan—</div>
              <p className="p-in-sp">I move, as an amendment to the amendment, that the word "consent" be stricken out, and the word "desire" be inserted in lieu thereof.</p>
              <p><span className="persName">The CHAIRMAN</span> stated the question.</p>
              <div className="speaker" id="sp2806"><span className="persName">Mr. SCOTT</span>, of Powhatan—</div>
              <p className="p-in-sp">
                My mind never was clearer, Mr. Chairman, upon any proposition, than that it is in the power of the Federal Government to acknowledge the independence of the seceded States. I admit that this is a government of limited powers; that the Federal Government possesses no powers that have not been conferred upon it by the Constitution. But the power to make treaties has been conferred upon
                it. It has been said by some that this treaty-making power was only intended to apply to treaties with foreign nations. The words "foreign nations" do not appear in the Constitution in connection with the treaty-making power. But even if they did, that would make no difference. Who would be the judge, in respect to the question who the foreign nations were? Would not the Federal
                Government have the authority to judge? Would it not be the judge of the question who were foreign nations, and who were not foreign nations? Have not the proper authorities of the Federal Government the power to acknowledge the independence of revolted States, or of revolted people of other countries? Did not the proper authorities of the Federal Government acknowledge the
                independence of several of the South American States? Did they not acknowledge the independence of Greece? Were they not the first to acknowledge the independence of Texas? And if the Federal Government does possess the power to acknowledge the independence of one people who have revolted, why have they not the same power to acknowledge the independence of three millions of
                people—put upon them what terms you please—call it a revolution or not? They have the power to recognize Greece, to recognize Texas, to recognize the people of the Southern Republics, and why have they not the power to recognize the independence of the seceded States?
              </p>
              <p className="p-in-sp">
                Sir, those seceded States are either sovereign States, or they are <PageNumber num={412} /> not sovereign States. If they are sovereign States, who have withdrawn from this Union, they are foreign nations now, because they have withdrawn; and the Federal Government can make a treaty with them upon the ground of their being foreign nations. But if they
                are not sovereign States, if this Government is partly consolidated and partly Federal, I consider that they have been in a state of revolution.
              </p>
              <p className="p-in-sp">
                Suppose, sir, that this were a consolidated Government, to all intents and purposes. I say, when three millions of people have revolted and established a government of their own, and are in a condition to maintain their independence by force of arms, that there is power in the Federal Government to acknowledge their independence. If it did not possess that power what would be the
                consequence? Suppose fifteen States of this Union were to withdraw, and to rise up in opposition to the authorities of the Federal Government, and suppose that they should overcome the powers of the Federal Government, can it be said that the Federal Government would have no power to make any treaty with them? Sir, it is a principle of the law of nations, that every government has
                the power to treat with any portion of its people who are in a state of revolt ; and unless you can acknowledge the independence of these people, it seems to me that there is power on the part of the Federal Government to execute the laws of the United States within the limits of the seceded States upon the people within them. The President of the United States has, in my opinion,
                the power to execute the laws of the United States within the limits of any State that is not independent; and if these people have not acquired their independence, are not strong enough to maintain their independence, the President of the United States has the Constitutional right to execute the laws of the United States upon them. But, sir, believing as I do, that when three
                millions of the people of this country have revolted, if you choose, and are in a state of revolution, if you choose, have formed a government and are able to maintain their independence, I do hold that there is power in the Federal Government, that there is power in the President and Senate, to make a treaty with them, and that that treaty ought to be made. Mr. President, I will now
                withdraw my amendment.
              </p>
              <div className="speaker" id="sp2807"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The question recurs upon the amendment proposed by the gentleman from Princess Anne, and upon this question the ayes and nays are called.</p>
              <p>The roll was then called and the question, upon inserting the amendment, was decided in the affirmative—ayes 106, noes 20, as follows :</p>
              <PageNumber num={413} />
              <p>
                YEAS-Messrs. Ambler, Armstrong, Aston, Baldwin, A. M. Barbour, James Barbour, Baylor, Berlin, Blakey, Blow, Jr., Boisseau, Borst, Bouldin, Brent, Bruce, Byrne, Cabell, Campbell, Caperton, Cecil, Chambliss, Chapman, Coffman, Conn, C. B. Conrad, R. Y. Conrad, Couch, Richard H. Cox, Critcher, Custis, Dent, Deskins, Dorman, Dulany, Echols, Flournoy, French, Fugate, Garland, Gillespie,
                Graham, Gravely, Gray, Goggin, John Goode, Jr., T. F. Goode, Hale, A. Hall, C. Hall, L. S. Hall, Hammond, Harvie, Haymond, Hoge, Holcombe, Hubbard, Hull, Hunton, M. Johnson, P. C. Johnston, Kent, Lawson, Leake, McComas, Macfarland, C. K. Mallory, J. B. Mallory, Marshall, Marr, Marye, Sr., Masters, Moffett, Montague, Morris, Morton, Neblett, Nelson, Orrick, Osburn, Parks, Patrick,
                Pendleton, Price, Pugh, Richardson, R. E. Scott, W. C. Scott, Seawell, Sheffey, Sitlington, Southall, Speed, Spurlock, Staples, C. J. Stuart, Strange, Sutherlin, Tayloe, Thornton, Tredway, Tyler, Waller, Wickham, Williams, Wilson, Wise-106.
              </p>
              <p>NAYS-Messrs. John Janney [President] , Boggess, Brown, Burdett, Carlile, Carter, Early, E. B. Hall, Hughes, Jackson, Lewis, McGrew, Moore, Porter, Rives, Sharp, Summers, Tarr, White, Willey-20.</p>
              <div className="speaker" id="sp2808"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Clerk will proceed to report the 11th resolution.</p>
              <p>The CLERK then read the 11th resolution, as follows :</p>
              <p>
                "11. This Convention, composed of delegates elected by the people in districts, for the purpose of considering the existing difficulties in our Federal relations, represents the desire and earnest request of the people of Virginia, to meet as directly as possible the people of her sister States, and to them appeal for satisfactory adjustment. Virginia, therefore, requests the people
                of the several States, either by popular vote, or in Convention similar to her own, to respond, at their earliest convenience, to the positions assumed in the foregoing resolutions, and the proposed amendments to the Constitution of the United States hereunto appended. And in the event that this Commonwealth fails to obtain satisfactory responses to her requests, from the
                non-slaveholding States, she will feel compelled to resume the powers granted by her under the Constitution of the United States, and to throw herself upon her reserved rights."
              </p>
              <div className="speaker" id="sp2809"><span className="persName">Mr. WICKHAM</span>, of Henrico—</div>
              <p className="p-in-sp">I propose to amend the 11th resolution by striking out all after the word "relations" in the third line, and inserting the following :</p>
              <p className="p-in-sp">
                "Declares that the people of Virginia, confiding in the justice of the people of the other States, appeal to them for a satisfactory adjustment of those difficulties by the adoption of the amendments to the Constitution of the United States, hereto appended; they, therefore, invite the people of the several States, either by popular vote, or in State Conventions similar to their own,
                to respond, at their earliest <PageNumber num={414} /> convenience, to the propositions in regard to those amendments. And they declare that a refusal on the part of the non-slaveholding States to accede to such amendments should and would result in the total and final disruption of the Union."
              </p>
              <p className="p-in-sp">
                Mr. Chairman, I have two objects in offering this amendment. The first is to avoid that portion of the present resolution which provides that the positions assumed in the foregoing resolutions shall be responded to by the Northern States. My opinion is, that as we ourselves differ materially upon some of the points embraced in those resolutions, it would be unwise in us to demand
                that the Northern people should respond affirmatively to all of them, for I suppose that they must respond affirmatively to respond satisfactorily. I do not think it wise to require them to respond affirmatively to all the abstract questions laid down in this report. I, therefore, have sought in the amendment I have offered to meet that difficulty, by providing that the Northern
                people shall respond only to the articles of amendment to the Constitution which the people of Virginia consider as affording a sufficient adjustment of these difficulties.
              </p>
              <p className="p-in-sp">
                The other point I desire to gain is that we should make our application in such a form as to leave it to be endorsed by the action of the other slaveholding States now remaining within the limits of the present Union, before making it decisive as regards Virginia. I for one am unwilling to take any action in regard to this matter, without having a free and full consultation with the
                Border slave States of this Union. I think we ought to be unwilling to take action, such as will deeply affect their interests, without at least going through the form of consulting them in the matter. I am satisfied that the position assumed by Virginia in this Convention, will be cheerfully and cordially ratified by the other slaveholding States remaining in the Union, and I
                believe that if these slaveholding States, including ourselves, go before the country united upon these propositions, there is no question whatever, that a favorable response to the application will be received from the Northern States of the Union. Does not every gentleman see that we stand a far better chance in presenting these propositions to the people of the North, backed by
                the voice of all the other border slave States, than by standing alone upon our own propositions? I think every gentleman must be satisfied that it would be a far better course for us to pursue than to act upon our own individual responsibility. Already, intimations are being thrown out that Virginia is assuming a position of arrogance in demanding as an ultimatum of the North, that
                her requests shall be acceded to without <PageNumber num={415} /> consultation with any of the other States concerned in the response of the North, equally with ourselves.
              </p>
              <p className="p-in-sp">
                Mr. Chairman, I have presented these two points in the amendment I have offered and I trust it will be the pleasure of this body to concur with me in the opinion that it is far wiser thus to consult with these border States upon these questions before taking our final action, than to declare the position to be finally assumed by us without consultation with the other border slave
                States.
              </p>
              <div className="speaker" id="sp2810"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                It is obviously the purpose of this amendment to exclude the latter clause of the 11th section. It does it in express terms, and I presume of course the intention is to strike out that clause which provides that "in the event that this Commonwealth fails to obtain satisfactory responses to her requests from the non-slaveholding States, she will feel compelled to resume the powers
                granted by her under the constitution of the United States, and to throw herself upon her reserved rights."
              </p>
              <p className="p-in-sp">
                Now, sir, the language used in that latter clause, is the language expressly used in the ratification of the constitution of the United States, by this State. Not that we shall arrogantly undertake to say, what the gentleman proposes to us to say, that in the event that a "definite" response is not returned to us upon these propositions of adjustment there shall be a "total" and
                "final" disruption of the Union. If the gentleman would avoid arrogance in a proposition to this Committee, I ask him what could be more arrogant than his assuming to speak for all the United States by the words "final" and "total." He would not undertake, he says, to say as an original proposition, that in case our requests are not acceded to, Virginia shall resume her granted
                powers, that she will, in that contingency, "throw herself upon her reserved rights ;" but he asks us to say, more decidedly, that in case she does not receive a "definite" answer to her requests, there must be and there "ought to be"—ought to be is the language—a "final" and "total" disruption of the Union. I can only say that I prefer the language of the ratification of the
                Constitution of the United States, to this language. I can only say that I regard the language of the resolution, as it stands, as less arrogant, as more modest in undertaking to speak for ourselves alone, than for everybody else, by saying that the disruption shall be "total" and "final."
              </p>
              <p className="p-in-sp">
                Now, sir, I would ask the gentleman what he means by "definite," response? It will be very hard to define the word "definite," in that connexion. Is the purpose here to propose that the answer <PageNumber num={416} /> shall be so known, so distinctly known, so definitely known, that there can be no mistake about it; that it must be so definitely in the
                negative as to compel us to disrupt, totally and finally, the whole Union? Does he mean by that expression, "definite" response, a mental reservation, that if they should not say exactly "no," but only something sort o' so and sort o' not so, and a little more sort o' so than sort o' not so, that then he will have another opportunity for another trial, thus putting off any definite
                action for an indefinite time? Is that the use he would make of the word "definite" ?
              </p>
              <p className="p-in-sp">
                Again, sir, why does he make the response to be definite, when he leaves the time indefinite? What time does he provide in which this response is to be made? Why, at the convenience of those States. Suppose they find it convenient in one, two or three years, the time proposed by Seward, or in five or ten years. Again, I make this point not only as to time, but the gentleman says, "if
                they should neglect or fail to answer." Neglect for how long? Fail for how long? The gentleman is very particular about the response being "definite." Would it not be well at the same time to be particular also as to the period of response, or in which they may neglect or fail to respond?
              </p>
              <p className="p-in-sp">Again, sir, if the gentleman wants to avoid secession or the acknowledgement that at some time or another we shall come to the point that we shall be compelled in necessity and in honor to secede, I submit to the gentleman that his proposition would grant secession, even before this 11 th resolution, as it stands, would.</p>
              <p><span className="persName">The CHAIRMAN</span> announced that the speaker's time had expired.</p>
              <div className="speaker" id="sp2811"><span className="persName">Mr. WICKHAM</span>—</div>
              <p className="p-in-sp">
                In response to the gentleman from Princess Anne, I will say, that he has totally mistaken the point of my declaration and of my amendment, if he supposes there is more arrogance in the declaration I propose in my amendment than there is in the declaration that Virginia will resume her sovereignty and reserved rights. I merely express the opinion that if these amendments to the
                Constitution are not acceded to the result would be, and should be, a total disruption of the Union. I stand here, sir, maintaining the position, as I did before my constituency in the canvass, and as I shall do till the end of time, that unless these difficulties which now exist are satisfactorily adjusted by amendments to the Constitution, I for one, will go for breaking up this
                government, not by secession but by revolution; taking all the responsibility which shall attach to my position in that regard. Therefore I wish here to express the opinion which I have always expressed, that it is necessary that <PageNumber num={417} /> we should have these amendments to the constitution to preserve this Union, and that it is my belief
                it will be broken, and entirely broken, unless we obtain these amendments. So far as the expression of such an opinion is arrogance, there is arrogance in this amendment I propose, and not unless the expression of such an opinion is arrogance.
              </p>
              <p className="p-in-sp">
                In regard to the use of the word "definite," I mean, by the use of that word, to indicate unmistakably that I, for one, must be clearly satisfied that it is the intention of the Northern States persistently to refuse this adjustment before I give my consent to break up this Union. I do not desire to obtain the opinion of the politicians of the North. I am satisfied that if we can
                reach the minds of the people they will eject their politicians and will give us these amendments and this adjustment to which we are entitled.
              </p>
              <div className="speaker" id="sp2812"><span className="persName">Mr. WISE</span> [in his seat]—</div>
              <p className="p-in-sp">How will you do it?</p>
              <div className="speaker" id="sp2813"><span className="persName">Mr. WICKHAM</span>—</div>
              <p className="p-in-sp">
                I propose to do it by the popular vote, or by conventions similar to our own. I presume the people of the Northern States, if they do not intend to grant this adjustment, will say that they do not, in their response to us by the popular vote, or by conventions similar to this. If the people cannot defeat the politicians, then the Union will have to go ; but I believe if this question
                is ever brought fairly before them, they will defeat the politicians.
              </p>
              <p className="p-in-sp">
                In regard to the time which they shall have for their response, it will be for this Convention and the people of Virginia to decide. The very next of the series of resolutions now under consideration, provides that there shall be a reasonable time. I take it for granted that that resolution will be adopted in connexion with this, if the Committee should accept my amendment; and I
                therefore reply to the gentleman from Princess Anne, that I propose to wait a reasonable time. I am not prepared to specify the precise time which will be required. I think there is a difference of opinion among those who act with the gentleman himself, as to the time we should wait. I think they are not prepared to fix upon the time he has mentioned in his proposition, and if they
                themselves cannot agree as to the time required for the settlement of these matters, it is not for them to complain that we have not fixed an exact period.
              </p>
              <p className="p-in-sp">
                As I said before, my chief object in offering this amendment is, that we should not make an affirmatory answer to all these abstract propositions by the Northern people, the condition on which we propose to separate from them, and, also, that before we fix any ultimatum, we should have a consultation and some concert of action with our sister slave States upon the border. That, sir,
                is a plan <PageNumber num={418} /> which has commended itself favorably to my humble judgment, and I trust it is a plan that shall commend itself favorably to the judgment of this Convention, for I am satisfied that under it we may have some hope of the preservation and restoration of this Union.
              </p>
              <div className="speaker" id="sp2814"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">Will it be in order to amend the amendment of the gentleman from Henrico, if it shall be adopted?</p>
              <div className="speaker" id="sp2815"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">It would not then be in order.</p>
              <div className="speaker" id="sp2816"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">I propose, then, at this time to amend the gentleman's amendment by striking out the words "popular vote," and inserting "their proper authorities."</p>
              <p className="p-in-sp">I understand the amendment of the gentleman provides that the people of the Northern States shall respond to Virginia either by popular vote or by Conventions constituted as ours is. I propose to strike out "popular vote" and to require that they shall respond by "their proper authorities" or by Conventions similar to this.</p>
              <p className="p-in-sp">
                I understand that if it is desirable to get responses from the free States to the application of Virginia, these responses should come in an authoritative form, and they can only come in that form when they come through the proper State authorities or through sovereign Conventions. A mere response of the people in a popular mass meeting, or for them to go to the polls without the
                authority of law, would not be worth anything more than the paper on which it is written. It would be nothing more than the re-enactment of the Dorr scenes of Rhode Island, and I trust that this Convention will not put Virginia in such a position.
              </p>
              <div className="speaker" id="sp2817"><span className="persName">Mr. CONRAD</span>, of Frederick—</div>
              <p className="p-in-sp">
                I think the gentleman from Middlesex, mistakes the object of that phrase which is in the original as well as in the amendment. The object, in which I supposed we all agreed, was to have a response from the people as contra-distinguished from the politicians. And the proposition there is that they shall respond either by popular vote or by Conventions, similar to our own. Now, sir,
                that does not import, as the gentleman seems to suppose, that this popular vote is not to come through the regular constituted authorities of the State.
              </p>
              <div className="speaker" id="sp2818"><span className="persName">MR. MONTAGUE</span>—</div>
              <p className="p-in-sp">Then why not put it in the resolution?</p>
              <div className="speaker" id="sp2819"><span className="persName">Mr. CONRAD</span>—</div>
              <p className="p-in-sp">
                I have no objection, if the gentleman will change his amendment, leaving in the words "by popular vote," and adding "through their regular authorities," if he considers such a provision necessary. It certainly was not in the contemplation of any one that the popular vote here referred to should be the vote of mere mass meetings of assemblages voluntarily formed in those States, but
                that <PageNumber num={419} /> their constituted authorities should recognize it as the popular vote, as the vote of the people, and that it should be sent to us through them. We believed that while the Legislatures of the Northern States might be disposed to give a negative response to our propositions—nearly all of them being in the hands of this
                Republican party—they might still be disposed to refer the matter to the people in their own States. It is the people we want to reach, and not the politicians. The gentleman's amendment would authorize a negative response to be given by the constituted authorities of the Northern people directly. It, in fact, invites such a response, for it places the alternative before them, to
                answer either by their regular authorities or by Convention, striking out the popular vote, which is the main feature of the proposition. I trust, that the amendment will not be adopted, as it would defeat the main object of the section.
              </p>
              <div className="speaker" id="sp2820"><span className="persName">MR. MONTAGUE</span>—</div>
              <p className="p-in-sp">
                I desire to say just a word or two in reply to the remarks of the gentleman from Frederick. As I before remarked, if we get any response from these Northern States, it ought to be such a response as will be satisfactory. How can that response be satisfactory unless it comes from the legally constituted authorities of the land? Is it worth anything unless it comes in the form of
                amendments to the Constitution? Is it worth more than the paper upon which it is written unless it comes in that form? And can it come in that form unless the popular vote be expressed in accordance with the forms of the Constitution? The Constitution prescribes its own forms of amendment. I will not stop to specify what they are, you all know what are the only modes by which the
                Constitution can be amended. Then, if this amendment means anything it ought to mean that which gives a satisfactory response; and how can any response be satisfactory, unless it comes in such a shape as to give binding effect, by the popular vote, upon the States in which that vote occurred. Otherwise, as I remarked before, the effect of your popular vote will be simply to repeat
                the Dorr scenes of Rhode Island, which occurred several years ago. I think the amendment which I have proposed ought to be adopted, and I hope it will be the pleasure of the Committee to accept it.
              </p>
              <p>The amendment to the amendment was disagreed to.</p>
              <div className="speaker" id="sp2821"><span className="persName">MR. GOODE</span>, of Bedford—</div>
              <p className="p-in-sp">
                I propose to amend the amendment of the gentleman from Henrico, by adding to it these words, "and in the event that this Commonwealth fails to obtain affirmative responses to her requests from the non-slaveholding States, she will feel compelled to resume the powers granted by her under the Constitution of the United States, and throw herself upon her reserved rights."
              </p>
              <PageNumber num={420} />
              <p className="p-in-sp">
                Mr. Chairman, I will only say a word or two in support of this amendment. It will be observed that I have taken a clause from the section itself, changing, simply, the word "satisfactory" to "affirmative." As I understand it, there is a very marked distinction between these two words in this connection. An affirmative response upon the part of the nonslaveholding States to the
                amendments proposed by this Convention to be adopted as part of the Constitution, would necessarily imply the acceptance of these amendments, but a satisfactory response would not necessarily imply their acceptance on the part of the non-slaveholding States. Now, sir, suppose that some of the non-slaveholding States should respond, and others should refuse to respond, would that be
                satisfactory to gentlemen? Suppose that the non-slaveholding States adopt some of the amendments proposed, and reject others, would that be satisfactory? Suppose the non-slaveholding States, a majority, or all of them, if you please, should respond to the amendments proposed by this Convention, that "they seem to be fair and just, but without giving a definite response at this time
                we propose to consider them through the medium of a National Convention ;" would that be satisfactory to this Convention? I understand, sir, that it is proposed here to submit an ultimatum to the non-slaveholding States. We have had a great deal of discussion in this body about an ultimatum. If that is the purpose of the body, I wish to place it beyond doubt or misconstruction for
                the future. I wish an ultimatum that is an ultimatum in truth and in fact. I wish it understood by the non slaveholding States and by the country, that these amendments of ours are indeed the ultimatum of the State of Virginia.
              </p>
              <p className="p-in-sp">
                Mr. Chairman, according to my apprehension the amendment, as offered, will not present such an ultimatum. And in order to test the sense of the Convention, as to whether we are to have such an ultimatum, I have proposed this amendment. I may say, that if anything upon earth has been fixed and agreed upon by the whole people of Virginia, it is that this Convention must either take
                some definite or decisive action now, or adopt some line of policy which must lead to a definite and decided action in the future; and I ask gentlemen if they intend to propose an ultimatum to the Northern States to say so in clear, decided, unequivocal terms so there can be no mistake in the future. Why, sir, suppose we adjourn over, as is contemplated, to await the responses to our
                propositions, and suppose when we reassemble one response shall be given by the people of one State, and another by another; will gentlemen, when we come back in the fall, ask that we shall still wait? Sir, as this Convention has decided that the <PageNumber num={421} /> time has not yet come for a separation, I ask them to indicate the point, the
                alternative which in their judgment will lead to a separation. And if we are to have an ultimatum which is to be understood by the State of Virginia and by the country, as such, I hope it will be so framed that there will be no doubt or misconstruction in the future.
              </p>
              <div className="speaker" id="sp2822"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">
                I cannot undertake to speak for the people of Virginia, but I can speak for myself and for the people I have the honor to represent upon this floor; and I say that they did not contemplate that this Convention should lay down any ultimatum. Sir, I am opposed to all ultimata, and I shall so vote. I shall not here, by my vote, countenance any proposition having for its purpose
                secession or disunion. I would vote for the amendment of the gentleman from Henrico
                [<span className="persName">Mr. WICKHAM</span>]
                , provided he would agree to strike from it the latter clause, which is the ultimatum of his amendment. But, sir, I cannot better express the reasons which influence me and the constituents I represent, to oppose any ultimatum on the part of this Convention, than by reading an extract from the last great speech made by Henry Clay in the Senate of the United States, upon the
                compromise measures of 1850, and made, sir, at the cost of the remaining days of his life. On that occasion he said :
              </p>
              <p className="p-in-sp">
                "Such is the Union, and such are its glorious fruits. We are told now, and it is rung throughout this entire country, that the Union is threatened with subversion and destruction. Well, the first question which naturally arises is, supposing the Union to be dissolved—having all the causes of grievances which are complained of—how far will a dissolution furnish a remedy for these
                grievances? If the Union is to be dissolved for any existing causes, it will be dissolved because slavery is interdicted or not allowed to be introduced into the ceded Territories ; because slavery is threatened to be abolished in the District of Columbia, and because fugitive slaves are not returned, as in my opinion they ought to be, restored to their master. These, I believe, will
                be the causes, if there be any causes, which can lead to the direful event to which I have referred.
              </p>
              <p className="p-in-sp">
                "Well, now, let us suppose that the Union has been dissolved. What remedy does it furnish for the grievances complained of in its united condition? Will you be able to push slavery into the ceded Territories? How are you to do it, supposing the North—all the States north of the Potomac, and which are opposed to it—in possession of the navy and army of the United States? Can you
                expect, if there is a dissolution of the Union, that you can carry slavery into California and New Mexico? You cannot dream of such a purpose. If it were <PageNumber num={422} /> abolished in the District of Columbia, and the Union was dissolved, would the dissolution of the Union restore slavery in the District of Columbia? Are you safer in the
                recovery of your fugitive slaves in a state of dissolution or of severance of the Union, than you are in the Union itself? Why, what is the state of the fact in the Union? You lose some slaves. You recover some others. Let me advert to a fact which I ought to have introduced before, because it is highly creditable to the courts and juries of the Free States. In every case, so far as
                my information extends, where an appeal has been made to the courts of justice for the recovery of fugitives, or for the recovery of penalties inflicted upon persons who have assisted in decoying slaves from their masters, and aiding them in escaping from their masters—as far as I am informed, the courts have asserted the rights of the owner, and the juries have promptly returned
                adequate verdicts in favor of the owner. Well, this is some remedy. What would you have if the Union was dissevered? Why, sir, then the several parts would be independent of each other—foreign countries! Slaves taken from the one into the other would be there like slaves now escaping from the United States into Canada. There would be no right of extradition—no right to demand your
                slaves—no right to appeal to the courts of justice to demand your slaves which escape, or the penalties for decoying them. Where one slave escapes now by running away from his owner, hundreds and thousands would escape if the Union were severed in parts —I care not where or how you run the line, if independent sovereignties were established.
              </p>
              <p className="p-in-sp">"Well, finally, will you, in a state of dissolution of the Union, be safer with your slaves within the bosom of the States than you are now? Mr. President, that they will escape much more frequently from the border States no one will doubt.</p>
              <p className="p-in-sp">
                "But, I must take occasion to say that, in my opinion, there is no right on the part of one or more of the States to secede from the Union. War and the dissolution of the Union are identical and inseparable. There can be no dissolution of the Union except by consent or by war. No one can expect, in the existing state of things, that that consent would be given, and war is the only
                alternative by which a dissolution could be accomplished. And, Mr. President, if consent were given—if possibly we were to separate by mutual agreement and by a given line, in less than sixty days after such an agreement had been executed, war would break out between the free and slave-holding portions of the Union—between the two independent portions into which it would be erected
                in virtue of the act of separation.
              </p>
              <PageNumber num={423} />
              <p className="p-in-sp">
                Yes, sir, sixty days—in less than sixty days, I believe, our slaves from Kentucky would be fleeing over in numbers to the other side of the river, would be pursued by their owners, and the excitable and ardent spirits who would engage in the pursuit would be restrained by no sense of the rights which appertain to the independence of the other side of the river, supposing it, then, to
                be a line of separation. They would pursue their slaves ; they would be repelled, and war would break out. In less than sixty days war would be blazing forth in every part of the now happy and peaceable land.
              </p>
              <p className="p-in-sp">
                "But how are you going to separate them? In my humble opinion, Mr. President, we should begin at least with three Confederacies—the Confederacy of the North, the Confederacy of the Atlantic Southern States (the slaveholding States) , and the Confederacy of the Valley of the Mississippi. My life upon it, sir, that vast population that has already concentrated, and will concentrate
                upon the head-waters and tributaries of the Mississippi, will never consent that the mouth of that river shall be held subject to the power of any foreign State whatever. Such I believe would be the consequences of a dissolution of the Union. But other Confederacies would spring up, from time to time, as dissatisfaction and discontent were disseminated over the country. There would
                be the Confederacy of the Lakes, perhaps the Confederacy of New England, and of the Middle States.
              </p>
              <p className="p-in-sp">"But, sir, the veil which covers these sad and disastrous events that lie beyond a possible rupture of this Union, is too thick to be penetrated or lifted by any mortal eye or hand.</p>
              <p className="p-in-sp">
                "Mr. President, I am directly opposed to any purpose of secession or separation. I am for staying within the Union, and defying any portion of this Union to expel or drive me out of the Union. I am for staying within the Union, and fighting for my rights—if necessary, with the sword—within the bounds under the safeguard of the Union. I am for vindicating these rights, but not by
                being driven out of the Union rashly and unceremoniously by any portion of this Confederacy. Here I am within it, and here I mean to stand and die; as far as my individual purposes or wishes can go—within it to protect myself, and to defy all the power upon earth to expel me or drive me from the situation in which I am placed. Will there not be more safety in fighting within the
                Union than without it?
              </p>
              <p className="p-in-sp">
                "Suppose your rights to be violated; suppose wrongs to be done you, aggressions to be perpetrated upon you—cannot you better fight and vindicate them, if you have occasion to resort to that last necessity of the sword within the Union, and with the sympathies of a large portion <PageNumber num={424} /> of the population of the Union of these States
                differently constituted from you, than you can fight and vindicate your rights, expelled from the Union, and driven from it without ceremony and without authority?
              </p>
              <p className="p-in-sp">
                "I said that I thought that there was no right on the part of one or more of the States to secede from this Union. I think that the Constitution of the thirteen States was made, not merely for the generation which then existed, but for posterity, undefined, unlimited, permanent and perpetual—for their posterity, and for every subsequent State which might come into the Union, binding
                themselves by that indissoluble bond. It is to remain for that posterity now and forever. Like another of the great relations of private life, it was a marriage that no human authority can dissolve or divorce the parties from; and, if I may be allowed to refer to this same example in private life, let us say what man and wife say to each other: 'We have mutual fault; nothing in the
                form of human beings can be perfect; let us then be kind to each other, forbearing, conceding; let us live in happiness and peace.'
              </p>
              <p className="p-in-sp">"Mr. President, I have said what I solemnly believe—that the dissolution of the Union and war are identical and inseparable; that they are convertible terms.</p>
              <p className="p-in-sp">
                "Such a war too as that would be following the dissolution of the Union! Sir, we may search the pages of history, and none so furious, so bloody, so implacable, so exterminating, from the wars of Greece down, including those of the Commonwealth of England and the revolution of France, none, none of them raged with such violence, or was ever conducted with such bloodshed and
                enormities as will that war which shall follow that disastrous event—if that event ever happens—of dissolution.
              </p>
              <p className="p-in-sp">
                "And what would be its termination? Standing armies and navies, to an extent draining the revenues of each portion of the dissevered empire, would be created; extermination wars would follow—not a war of two or three years, but of interminable duration—an exterminating war would follow, until some Philip or Alexander, some Caesar or Napoleon, would rise to cut the Gordian knot, and
                solve the problem of the capacity of man for self-government, and crush the liberties of both the dissevered sections of this Union. Can you doubt it? Look at history—consult the pages of all history, ancient or modern; look at human nature—look at the character of the contest in which you would be engaged in the supposition of a war following the dissolution of the Union, such as I
                have suggested—and I ask you if it is possible <PageNumber num={425} /> for you to doubt that the final but perhaps distant termination of the whole will be some despot treading down the liberties of the people?— that the final result will be the extinction of this last and glorious light which is leading all mankind, who are gazing upon it, to cherish
                hope and anxious expectation that the liberty which prevails here will sooner or later be advanced throughout the civilized world.
              </p>
              <p className="p-in-sp">
                "Can you, Mr. President, lightly contemplate the consequences? Can you yield yourself to a torrent of passion, amidst dangers which I have depicted in colors far short of what would be the reality, if the event should ever happen? I conjure gentlemen—whether from the South or the North, by all they hold dear in this world—by all their love of liberty—by all their veneration for their
                ancestors—by all their regard for posterity—by all their gratitude to Him who has bestowed upon them such unnumbered blessings—by all the duties which they owe to mankind, and all the duties they owe to themselves—by all these considerations I implore them to pause—solemnly to pause—at the edge of the precipice, before the fearful and disastrous leap is taken in the yawning abyss
                below, which will inevitably lead to certain and irretrievable destruction.
              </p>
              <p className="p-in-sp">"And, finally, Mr. President, I implore, as the best blessing which Heaven can bestow upon me upon earth, that if the direful and sad event of the dissolution of the Union shall happen, I may not survive to behold the sad and heart rending spectacle."</p>
              <p className="p-in-sp">
                There are other extracts which I could read to the same effect, but I will not detain the Committee. Now, sir, this Convention has by a nearly unanimous vote—only twenty voices dissenting—agreed to use an expression of the gentleman from Princess Anne—to "slough off" or to consent to the sloughing off of the seceded States. My voice was among the twenty dissenting voices, and I am
                proud of it. I presume it is a foregone conclusion that an ultimatum is to be laid down by this body, and that the question is merely one of time, and so far as Virginia is concerned, it is to be a very short time. I understand it is intended to be effected by October; such is the proposition of the gentleman from Princess Anne in the amendment he proposes to this report. I fear many
                of our friends have unadvisedly committed themselves to the report of the committee of twenty-one, and in their effort to compromise with those who believe in the right of secession have allowed themselves to be recorded in favor of propositions which votes they will regret in the future.
              </p>
              <p>Here the hammer fell.</p>
              <div className="speaker" id="sp2823"><span className="persName">Mr. GOODE</span>, of Bedford—</div>
              <p className="p-in-sp">
                The gentleman from Harrison informs us <PageNumber num={426} /> that he is unalterably opposed to any ultimatum. Sir, why has he come to this Convention? For what purpose are we now here? Will the gentleman tell us, speaking here for his constituents, that if the amendments proposed by this Convention be deliberately rejected by the Northern States, he
                is then in favor of acquiescing? Is that the deliberate opinion of the gentleman and of those whom he represents?
              </p>
              <div className="speaker" id="sp2824"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">Is the gentleman putting an interrogatory to me?</p>
              <div className="speaker" id="sp2825"><span className="persName">Mr. JOHN GOODE, Jr.</span>—</div>
              <p className="p-in-sp">Yes, sir.</p>
              <div className="speaker" id="sp2826"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">What is it?</p>
              <div className="speaker" id="sp2827"><span className="persName">Mr. JOHN GOODE, Jr.</span>—</div>
              <p className="p-in-sp">I wish to know whether, if the amendment contemplated by this Convention shall be deliberately rejected by the Northern States, you and yours are in favor of acquiescing in that determination?</p>
              <div className="speaker" id="sp2828"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">
                I can answer the gentleman. Ours are a people who act on the maximum "sufficient unto the day is the evil thereof." As that question was not before them when I was last with them, I cannot say what their views would be. I can say this much, that it was contemplated by the people whom I represent that this Convention would do no act, and pass no resolution looking, on any condition to
                the present or future change of the relations that we sustain to the Federal Government, without first submitting their action to that people, as well as to the people of the other portions of the State, to be passed on at the ballot box.
              </p>
              <div className="speaker" id="sp2829"><span className="persName">Mr. JOHN GOODE, Jr.</span>—</div>
              <p className="p-in-sp">
                The gentleman has not met my inquiry; but so much for that. I undertake to say that, no matter what the deliberate judgment of the Northern mind may be, that gentleman will be opposed to any resistance, and will favor the continuance of the State of Virginia in the Northern Confederacy. I have a right to infer that much from the votes he has given during the pendency of these
                discussions. He is opposed to any ultimatum. Is that the sentiment of the people of Virginia? Sir, I had thought that if the sentiment of Virginia was made up on any subject on earth it was on this that the time had now come when this matter should be settled finally and forever.
              </p>
              <div className="speaker" id="sp2830"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">Will the gentleman permit me to ask him a question. Does he suppose that the adoption of an ultimatum which is, in fact, an impending threat to this people from whom these guarantees are demanded, is likely to obtain their consent, is it likely they will yield to the demand?</p>
              <div className="speaker" id="sp2831"><span className="persName">Mr. JOHN GOODE, JR.</span>—</div>
              <p className="p-in-sp">
                I cannot undertake to say what they would be likely to do; but I do undertake to say that we would be derelict <PageNumber num={427} /> in our duty, recreant to our trust, and would fail to meet the just expectation of our constituents, if we failed to say to the Northern people, not in the language of menace, but clearly, distinctly and unequivocally,
                "this is the sine qua non on which Virginia will remain longer in connection with you." It is on that that I propose to test the sense of the Convention. We have heard of ultimata from the time of our assembling, down to the present hour. I desire to know if we are to have an ultimatum, and if such is the understanding of the country?
              </p>
              <p className="p-in-sp">
                Now, Mr. Chairman, as to the residue of the reply made by the gentleman from Harrison, it is unnecessary for me to follow it. I am not here to reply to the speech of Henry Clay. I hope I have too much modesty to undertake the task. But I beg the gentleman from Harrison, if he has not yet waked up from the Rip Van Winkle sleep which seems to have overcome him, to bear in mind that the
                Union spoken of by Henry Clay in the paragraphs read by him, is no longer in existence. If Henry Clay were now alive, he would not undertake to speak of this Union as if it were unbroken. Does not the gentleman know that the Union is already dissolved? Does not he recognize the fact that the Federal Union of our fathers is no longer existing? The gentleman shakes his head. In his
                estimation, in the language of Abraham Lincoln's inaugural address, the Union is unbroken, altogether unbroken. Those seven seceded States that have retired from the Union and assumed a separate and independent position for themselves, are still integral members of the Union. Mr. Chairman, it is not necessary for me to reply to such an argument as that. I know it is not the sentiment
                of this Convention. He talks about the right of secession. I will not now discuss that question with him ; but I will remind the gentleman that five millions of freemen, in spite of his opinion to the contrary, have asserted the right of secession, and stand to-day, with arms in their hands, to maintain it. In offering my amendment I desire to test the sense of the body as to whether
                or not we are to have an ultimatum.
              </p>
              <p>The question being on <span className="persName">Mr. GOODE</span>'S amendment to the amendment, <span className="persName">Mr. JNO. GOODE, Jr.</span>, called for the yeas and nays.</p>
              <p>The yeas and nays were ordered.</p>
              <div className="speaker" id="sp2832"><span className="persName">Mr. THOS. F. GOODE</span>—</div>
              <p className="p-in-sp">I ask to be excused from voting on either the amendment to the amendment or the amendment itself. I did not come here to lay down an ultimatum.</p>
              <p>The question was taken and it was decided in the negative—yeas 54, nays 69; as follows :</p>
              <PageNumber num={428} />
              <p>
                YEAS-Messrs. Ambler, Armstrong, James Barbour, Blakey, Blow, Jr., Boisseau, Borst, Bouldin, Branch, Bruce, Cabell, Chambliss, Chapman, Coffman, Conn, R. H. Cox, Echols, Flournoy, Garland, Graham, Gregory, Jr., Goggin, J. Goode, Jr., Hale, C. Hall, L. S. Hall, Harvie, Holcombe, Hunton, Kent. Lawson, Leake, C. K. Mallory, J. B. Mallory, Marr, Marye, Sr., Montague, Morris, Morton,
                Neblett, Orrick, Parks, Richardson, Seawell, Sheffey, Speed, Strange, Sutherlin, Thornton, Tredway, Tyler, Williams, Wilson, Wise-54.
              </p>
              <p>NAYS-Messrs. Janney [President]
                , Aston, Baldwin, A. M. Barbour, Baylor, Berlin, Boggess, Brent, Brown, Burdett, Byrne, Campbell, Caperton, Carlile, Carter, C. B. Conrad, R. Y. Conrad, Couch, Critcher, Custis, Deskins, Dorman, Dulany, Early, French, Fugate, Gillespie, Gravely, Gray, A. Hall, E. B. Hall, Hammond, Haymond, Hoge, Hubbard, Hughes, Jackson, M. Johnson, P. C. Johnston, Lewis, McComas, McGrew, Macfarland,
                Marshall, Masters. Moffett, Moore, Osburn, Patrick, Pendleton, Porter, Price, Pugh, Rives, R. E. Scott, Sharp, Sitlington, Slaughter, Southall, Spurlock, Staples, C. J. Stuart. Summers, Tarr, Tayloe, Waller, White, Wickham, Willey-69.
              </p>
              <p>So the amendment to the amendment was rejected.</p>
              <div className="speaker" id="sp2833"><span className="persName">Mr. WICKHAM</span>—</div>
              <p className="p-in-sp">I desire to make a more verbal alteration in my amendment. It now reads, that the States shall respond at their earliest convenience "to the proposition in regard to those amendments." I proposed to alter that verbiage so as to make it read, "the said propositions of amendments."</p>
              <p>The amendment was so modified.</p>
              <div className="speaker" id="sp2834"><span className="persName">Mr. McGREW</span>—</div>
              <p className="p-in-sp">I propose to amend the amendment by striking out the words : "should and would result in the total and final disruption of the Union." I offer the amendment because this language does not, in this connection, meet with the sober convictions of my judgment, nor, as I believe, with the judgment of those whom I represent here.</p>
              <div className="speaker" id="sp2835"><span className="persName">Mr. WICKHAM</span>—</div>
              <p className="p-in-sp">
                I dislike exceedingly to differ with a gentleman whose views generally upon these questions concur with my own. I cannot, however, agree to this amendment. I think it is very well understood that the sense of this Convention is in conformity with the policy set down in the language proposed to be stricken out. I shall resist the adoption of that amendment, and I trust it will be the
                pleasure of the Committee to reject it.
              </p>
              <div className="speaker" id="sp2836"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I suppose I can call for a division of the question, and ask that a vote be first taken on striking out.</p>
              <div className="speaker" id="sp2837"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The question is not divisible. The proposition is to strike out. There is no motion to insert.</p>
              <div className="speaker" id="sp2838"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Oh! this is the amendment to the amendment?</p>
              <div className="speaker" id="sp2839"><span className="persName">Mr. CHAIRMAN</span>—</div>
              <p className="p-in-sp">Yes, sir.</p>
              <div className="speaker" id="sp2840"><span className="persName">Mr. BLAKEY</span>—</div>
              <p className="p-in-sp">I call for the yeas and nays.</p>
              <p>The call was not sustained. The question was then put on the <PageNumber num={429} />, and was decided in the negative.</p>
              <p>The question then recurred upon agreeing to the amendment.</p>
              <div className="speaker" id="sp2841"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I now call for a division of the question. My object is to get clearly to this doctrine of the resumption of power, and I want a test vote upon this question. I call for the yeas and nays.</p>
              <p>The call was sustained.</p>
              <p>The question was then taken on striking out so much of the resolution as has been referred to, and the roll being called, resulted—yeas 57, nays 68—as follows :</p>
              <p>YEAS-Messrs. John Janney [President]
                , Aston, Baldwin, A. M. Barbour, Baylor, Berlin, Boggess, Brown, Burdett, Byrne, Campbell, Carlile, Carter, C. B. Conrad, Robert Y. Conrad, Couch, Custis, Deskins, Dulany, Early, French, Fugate, Gillespie, Gravely, Gray, E. B. Hall, Haymond, Hoge, Hubbard, Hughes, Hull, Jackson, Peter C. Johnston, Lewis, McComas, McGrew, Marshall, Masters, Moffett, Moore, Osburn, Patrick, Pendleton,
                Porter, Price, Pugh, Rives, R. E. Scott, Sharp, Sitlington, Spurlock, C. J. Stuart, Summers, Tarr, White, Wickham, Willey-57.
              </p>
              <p>
                NAYS-Messrs. Ambler, Armstrong, James Barbour, Blakey, Blow, Boisseau, Borst, Bouldin, Branch, Brent, Bruce, Cabell, Caperton, Chambliss, Chapman, Coffman, Conn, Richard H. Cox, Critcher, Dorman, Echols, Flournoy, Garland, Graham, Gregory, Goggin, J. Goode, Jr., T. F. Goode, Hale, A. Hall, C. Hall, L. S. Hall, Hammond, Harvie, Holcombe, Hunton, Marmaduke Johnson, Kent, Lawson, Leake,
                Macfarland, Charles K. Mallory, J. B. Mallory, Marr, Marye, Montague, Morris, Morton, Neblett, Orrick, Parks, Richardson, Sea-well, Sheffey, Slaughter, Southall, Speed, Staples, Strange, Sutherlin, Tayloe, Thornton, Tredway, Tyler, Waller, Williams, Wilson, Wise-68,
              </p>
              <div className="speaker" id="sp2842"><span className="persName">Mr. WILSON</span>—</div>
              <p className="p-in-sp">I move to amend the 11 th resolution by striking out all after the words "United States" in the fifteenth line. The portion I wish to strike out is in these words "and throw herself upon her reserved rights." It is the natural and necessary consequence of what precedes it from the word "and" in the 11th line. I therefore think it is unnecessary.</p>
              <p className="p-in-sp">The section represents the desire of Virginia to meet, as directly as possible, the people of her sister States and to adjust our existing difficulties. If this cannot be done and Virginia cannot obtain her just rights, she will resume the powers granted by her under the Constitution of the United States.</p>
              <p className="p-in-sp">This, I believe, fully meets the views of my constituents.</p>
              <p className="p-in-sp">My colleague [<span className="persName">Mr. CARLILE</span>]
                , in giving his understanding of the views of our constituents, differs widely from me, if I understood him correctly in saying they did not wish either an ultimatum laid down, or guarantees demanded. I understand they are in favor of <PageNumber num={430} /> demanding the full measure of all their constitutional rights; and should the General
                Government, or the Northern States that now have the control of that Government, refuse to award them those rights, and refuse to recognize the equality of the States, they will, as I understand, at all hazards and at every risk, sever their connection with that Government.
              </p>
              <p className="p-in-sp">They are not willing, sir, to yield their reserved powers to any Government State or Federal, or to call any people masters, and if they continue to remain in the Union it must be on terms of exact equality with all sections.</p>
              <p className="p-in-sp">If the time ever does come, and I hope it never will, when it may become necessary for us to vindicate our rights of equality by a resort to arms, it will be found that the people who I, in part, represent on this floor, will be as prompt as any other part of the State to vindicate them and to sever their connection with this Union, and to sever it forever.</p>
              <p className="p-in-sp">But, sir, they hope to avoid this necessity. They wish to resort to all constitutional and proper means to avoid it. They wish to appeal earnestly and affectionately to their Northern sisters to do them justice. If this fails they will be ready for the last resort.</p>
              <p className="p-in-sp">These are the views of the constituency whom I, in part, represent as I understand them, and I am satisfied they will sustain me.</p>
              <div className="speaker" id="sp2843"><span className="persName">Mr. WILLEY</span>—</div>
              <p className="p-in-sp">My own individual opinion—which of course, is not worth much—is, that it would have been better if we could agree upon our proposed Constitutional amendment, without any resolutions, enunciating mere abstract principles. The object I have in proposing this amendment</p>
              <div className="note" id="Note44">
                <span className="noteLabel">1</span>Mr. Willey's resolution has apparently been omitted from the Enquirer report. The Journal entry is as follows:
                <p>On motion of <span className="persName">Mr. WILLEY</span>, the resolution was amended by striking from the 9th and 10th lines the words, "the positions assumed in the foregoing resolutions, and."</p>
              </div>
              <p className="p-in-sp">
                is to disembarrass our propositions of Constitutional amendment to be presented to the other States, of all considerations not exactly germane to the matter. What we desire for Virginia and for the protection and the guarantee of her rights, are amendments of the Federal Constitution, to which we can command the assent of all the States of the Union. But a submission of these
                abstract principles in the form of resolutions to the Northern people or to the other States, will, in my humble estimation, simply tend to embarrass the consideration of the amendments to the Constitution which we propose. We are anxious that these amendments should have a candid and fair consideration. It is these amendments, if adopted, that are to secure to us the benefits we
                desire and the guarantees of our rights which we desire, and not the adoption of the resolutions. I hope, therefore, it will be the pleasure of this Committee to allow these propositions to go to the Northern people, disembarrassed of all <PageNumber num={431} /> extraneous matter of consideration, so that we may get at the sentiments of the North upon
                the amendments to the Constitution alone. It is in that respect that we are really interested, and in no other, so far as the other States are concerned; and these are the reasons which have influenced me to move this amendment.
              </p>
              <p>The question was then taken on the amendment, and announced by the Chairman as carried in the affirmative.</p>
              <div className="speaker" id="sp2844"><span className="persName">Mr. SHEFFEY</span>—</div>
              <p className="p-in-sp">I call for a division.</p>
              <div className="speaker" id="sp2845"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The amendment has been agreed to, and it is now too late to call for a division.</p>
              <div className="speaker" id="sp2846"><span className="persName">Mr. SHEFFEY</span>—</div>
              <p className="p-in-sp">I called for a division immediately upon the Chair's announcing his opinion that the ayes had it.</p>
              <div className="speaker" id="sp2847"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Chair cannot now entertain a call for a division. If the gentleman called for a division, the Chair did not hear the call.</p>
              <div className="speaker" id="sp2848"><span className="persName">Mr. SHEFFEY</span>—</div>
              <p className="p-in-sp">
                I ask, sir, whether, when the Chair announces upon a viva voce vote, that a question is carried, or the contrary, he is to be regarded as infallible. I had some doubt as to the correctness of the decision of the Chair, as to the result of this vote, and, as is known to many gentlemen around me, I called for a division the moment the Chair announced the result of the vote, according
                to his estimate.
              </p>
              <div className="speaker" id="sp2849"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Chair assures the gentleman that he did not hear the call, and in view of this misunderstanding, the Chair will, by general consent, take the vote again.</p>
              <p>There being no objection, the question was again taken by a count, and decided in the affirmative—ayes 76, noes 37.</p>
              <p><span className="persName">Mr. TARR</span> moved to strike out of the 11th section that portion of the section which reads as follows :</p>
              <p>"And in the event that this Commonwealth fails to obtain satisfactory responses to her requests, from the non-slaveholding States, she will feel compelled to resume the powers granted by her under the Constitution of the United States, and to throw herself upon her reserved rights."</p>
              <p><span className="persName">Mr. WISE</span> demanded the yeas and nays, which were ordered.</p>
              <p>
                The question was then taken and the amendment was not agreed to —yeas 37, nays 81, as follows :<span className="note" id="Note45"><span className="noteLabel">2</span>The Journal omits Maslin from this list of negative votes.</span>
              </p>
              <p>YEAS-Messrs. Janney [President] , Aston, Baldwin, A. M. Barbour, Baylor, Berlin, Boggess, Brown, Burdett, Campbell, Carlile, Carter, C. B. Conrad, Couch, Early, Fugate, E. B. Hall, Haymond, Hubbard, Jackson, Lewis, McComas, McGrew, Marshall, Masters, Osburn, Patrick, Pendleton, Porter, Ro. E. Scott, Sharp, Sitlington, C. J. Stuart, Summers, Tarr, Wickham, Willey-37</p>
              <PageNumber num={432} />
              <p>
                NAYS-Messrs. Ambler, Armstrong, James Barbour, Blakey, Blow, Boisseau, Borst, Bouldin, Branch, Brent, Bruce, Byrne, Cabell, Chambliss, Chapman, Coffman, Conn, R. Y. Conrad, R. H. Cox, Critcher, Custis, Deskins, Dulany, Flournoy, French, Garland, Gillespie, Graham, Gravely, Gregory, Goggin, John Goode, Jr., Thomas F. Goode, Hale, Addison Hall, Cyrus Hall, L. S. Hall. Hammond, Harvie,
                Hoge, Holcombe, Hull, Hunton, Marmaduke Johnson, P. C. Johnston, Kent, Lawson, Leake, Macfarland, C. K. Mallory, J. B. Mallory, Marr, Marye, Maslin, Moffett, Montague, Morris, Morton, Moore, Neblett, Nelson, Orrick, Parks, Pugh, Richardson, Wm. C. Scott, Seawell, Sheffey, Slaughter, Southall, Speed, Spurlock, Staples, Strange, Sutherlin, Tayloe, Thornton, Tredway, Tyler, Waller,
                Wilson, and Wise-81.
              </p>
              <p>The Committee then took a recess until 4 o'clock.</p>
            </div>
            <div className="section" id="vsc1965.v3.2.7.10">
              <h3><span className="headingNumber">1.7.10. </span><span className="head">EVENING SESSION</span></h3>
              <p>The Committee of the Whole resumed its session at 4 o'clock, P.M. <span className="persName">Mr. MONTAGUE</span> in the Chair.</p>
              <p><span className="persName">The CHAIRMAN</span> stated that the eleventh section was still open for amendment.</p>
              <div className="speaker" id="sp2850"><span className="persName">Mr. BALDWIN</span>, of Augusta—</div>
              <p className="p-in-sp">I understand that the eleventh section is still open for amendment. I desire to suggest an amendment, which I thin:: obviates an objection well taken by yourself to the construction which may be put upon this eleventh section, but in regard to which I do not think the amendment suggested by you affords a remedy.</p>
              <p className="p-in-sp">
                It was apprehended, as I understood, by you—and the apprehension was concurred in by others—that the directness of the appeal proposed in the 11th section, "Virginia, therefore, requests the people of the several States" may be construed into an appeal to the people in the sense of antagonism and insubordination to regular constituted authority. I do not suppose that there is any one
                who desires that we shall attempt to institute in any part of the country any irregularity in the expression of the popular will. I would suggest this amendment, which I think obviates the difficulty and places us in the position of calling for nothing more than the expression of the popular will according to its legitimate mode of expression. Beginning at the sixth line, which now
                reads :
              </p>
              <p className="p-in-sp">"Virginia, therefore, requests the people of the several States, either by popular vote, or in Conventions similar to her own, to respond," &amp;c</p>
              <p className="p-in-sp">I propose to amend it, so that it would read :</p>
              <p className="p-in-sp">"Virginia, therefore, desires that the people of the several States be called upon to respond either by popular vote or in Convention similar to her own."</p>
              <PageNumber num={433} />
              <p>The question was then taken upon the amendment, and it was agreed to—ayes 45, noes 32.</p>
              <div className="speaker" id="sp2851"><span className="persName">Mr. GOODE</span>, of Bedford—</div>
              <p className="p-in-sp">I propose to strike out, in the 12th line, the word "satisfactory," and insert the word "affirmative;" so that the clause would read :</p>
              <p className="p-in-sp">"And in the event that this Commonwealth fails to obtain affirmative responses to her requests, from the non-slaveholding States, she will feel compelled to resume the powers granted by her under the Constitution of the United States, and to throw herself upon her reserved rights."</p>
              <p className="p-in-sp">
                I do not desire to trouble the Convention, but I offer this Amendment from a sense of duty. The constituency whom I have the honor, in part, to represent upon this floor, were divided at the time of the election and are divided now, upon the great question of immediate secession. But there was one question, and there is one question, now upon which there was no division, upon which
                all were agreed, and that was, that this Convention should adopt some definite, decided and final action ; and I undertake to say, without the fear of successful contradiction, that it was the almost universal sentiment of the Commonwealth that this Convention, when we assembled, would either adopt some final action, or adopt some measures which would lead to final action within some
                fixed and definite period. Now, sir, I cannot be mistaken upon that proposition. I desire particularly to direct the attention of members to this point. I put it to them to say if they are prepared to meet their constituents and say to them that they have reached no definite result and have adopted no measures which will bring about a definite and final result in the future. Why do
                gentlemen wish to keep up this uncertainty in the public mind? Our people are in a state of anxiety and suspense. All the great interests of the State are suffering; its business is paralysed, and the energies of our people are this day completely prostrated.
              </p>
              <p className="p-in-sp">
                Now, Mr. Chairman, I expect in the future, so far as I am concerned, to derive much satisfaction from a recollection of the fact, if my feeble voice could have been heard, and my poor counsels could have prevailed, that Virginia would have been this day cut loose from our membership in a Northern Black Republican Government, and that our people would not be in a condition which
                renders them liable to be called upon to-morrow to take up arms against their brethren of the South ; because, while I now speak, the storm cloud of war may be ready to burst in all its fury upon our heads, and in the <PageNumber num={434} /> language of the immortal HENRY, "the very next gale that sweeps from the South may bring to our ears the clash
                of resounding arms." Sir, I have been overruled in this by the majority. My counsels could not prevail. I desire now to ask that majority—I do it respectfully and in all kindness; I think I have the right to ask it in the name of my constituents—if they are not prepared to adopt such measures as will lead to some definite, decided, final action within a fixed and definite period?
              </p>
              <p className="p-in-sp">
                This resolution, sir, as it now reads, does not meet that requirement, and I wish to test it. Suppose the word "satisfactory" is retained in the place of the word "affirmative" which I desire to substitute for it, and suppose your propositions are rejected by the people of the North. Would that be satisfactory? If it would not be satisfactory, then why do gentlemen object to striking
                out the word "satisfactory," and insert the word "affirmative"?
              </p>
              <p className="p-in-sp">
                I had supposed, Mr. Chairman—I had indulged the hope, that the Convention would be prepared to take either final, decided action now, or such action as would point with certainty to some decided action in the future. I repeat, if these propositions are not responded to affirmatively, it may be contended, and I predict it will be contended in the future, notwithstanding they have been
                rejected in whole or in part, that the response is satisfactory. I trust that this uncertainty may terminate ; I trust that our people may be relieved from this anxiety and suspense, and if we are to be kept in this alliance with the Northern Black Republicans, I beg that I may be able to say to my constituents when I return home, if the ultimatum of Virginia is not adopted, that
                then Virginia, with one voice, will be prepared for action.
              </p>
              <div className="speaker" id="sp2852"><span className="persName">Mr. JOHNSON</span>, of Richmond—</div>
              <p className="p-in-sp">I trust it will not be the pleasure of this Committee to adopt the amendment offered by the gentleman from Bedford [<span className="persName">Mr. GoODE</span>]
                , and I had hoped that the gentleman would have been content with the test which he submitted to the Convention this morning. I do not know, Mr. Chairman, what tomorrow's news may bring. I do not know what the sensation spirit of the hour may occasion. I do not know whether the language of Patrick Henry, uttered as to the plains of Boston, is appropriate now to the harbor of
                Charleston, nor do I care, so far as this question is concerned. The question is, which is the preferable term? The question is, which would better represent the object of the resolutions "satisfactory" or "affirmative" ? Now, I submit to this Convention, that there is no more appropriate word than that which is <PageNumber num={435} /> embodied in the
                resolution. Who are to judge whether this action is satisfactory or not? The people of Virginia. My friend shakes his head. If this Convention adjourns, as I understand is proposed, this Convention is to decide upon it, and by the action of the people in electing this Convention, the action of this body is to be referred back to them. So that the people of this State must decide, and
                are bound to decide by their own voice, whether or not this action is satisfactory. Now, sir, why put in the word "affirmative?" Why, by any word—why, by any technical word—why, by any restrictive word, hamper the action of either the Northern or the Southern people? Sir, as I understand the present position of Virginia, it is this : She is here, a representative people, for the
                purpose of considering what terms they will submit to the Northern people as terms upon which they are willing to live together; and if to the proposition of Virginia they give a satisfactory answer, of which we ourselves are the judges, then we will say, "we will not part with you unless other circumstances necessitate us to do so." But if, on the other hand, they do not give a
                satisfactory answer, then we say, "This is an uncongenial association, and we will part from you."
              </p>
              <p className="p-in-sp">
                Now, why introduce the word "affirmative?" Is it that they shall say "yes" to everything? Is it that, when we ourselves are the judges of their own answer, there shall be a rigidly, strictly affirmative response to everything from everybody? Is it not enough for us to reserve to ourselves final adjudication upon these answers? Can we require more, as Southern people? Ought we to
                require more as Southern people? It does seem to me, sir—and I am sure my friend will take it in good part—that there is on his part, and on the part of those who co-operate with him, too much of a disposition to prevent co-operation on the part of Virginia and her sister border States. We are surrounded by them; they are our immediate neighbors; and to take any final, ultimate
                action, without consultation with them, is a proposition to which I do unequivocally say, I am unalterably opposed. We are bounded by those States in the North, the South, the East and the West; and I say, both as a matter of interest and as a matter of brotherly or of sisterly relation, it is our duty to enter into a hearty and cordial consultation with them. And it is not
                compatible with this policy or principle, that we ourselves should require a distinct affirmative answer to every proposition we may lay down, when, at the very time that that answer may come to us, we will probably be in consultation with our sister border States, whose voice is entitled to be heard. I say, not a voice to control the action of
                <PageNumber num={436} /> Virginia, but a voice that ought to be heard by Virginia before she acts. Therefore it does seem to me that it is a restrictive word in substitution of one which is the most comprehensive, and altogether the most appropriate and satisfactory. I trust, therefore, it will not be the pleasure of the Convention to impair, as it
                would be in my opinion, the character of the resolution, by substituting the word "affirmative" in place of the word "satisfactory."
              </p>
              <div className="speaker" id="sp2853"><span className="persName">Mr. GOODE</span>, of Bedford—</div>
              <p className="p-in-sp">
                I have but a word to say, Mr. Chairman, in reply to the gentleman from Richmond city. I think, sir, that his own argument has furnished to this house a most conclusive reason why the word "satisfactory" should be stricken out and the word "affirmative" substituted in its stead. What does the gentleman say, sir? He says that we are now engaged in deciding upon what terms we are
                willing to live with our confederates of the North. And, sir, if that is the work in which we are now engaged ; if we are now deciding the terms upon which we will be willing to continue our alliance with this Black Republic of the North, I ask if the door is to be left open to some distant day in the future to decide upon the terms which the gentleman says engage our attention at
                this hour?
              </p>
              <p className="p-in-sp">
                What are we here for? I suppose our constituents have commissioned us for some purpose, to decide upon something; and yet the gentleman tells you that after we are to decide we are to wait. Wait for what? Wait for a Border State Conference. Why, sir, did his constituents, or did mine, send him or me here to determine the ultimatum of Virginia, or to hand Virginia, over to the Border
                States of this Confederacy? Did they send me here to determine the terms upon which my constituents would be willing to keep this connection longer, or to refer it to Delaware, Maryland, Tennessee, North Carolina and other Border States to determine? We are now fixing the terms, and yet we are to wait for a Border State Conference. The gentleman has thought proper to refer to me and
                those with whom I am co-operating. I will reply in the same spirit, and tell him that upon the re-assembling of the last Congress, he and those who co-operate with him, asked us to wait for the Committee of Thirty-three. We did wait. They then asked us to wait for the Committee of Thirteen in the Senate. We did wait. They then asked us to wait for the Peace Conference at Washington.
                We did wait. And when that proved to be a notorious and signal failure, we were asked to wait for the Inaugural of our President, Mr. Abraham Lincoln. And now, sir—
              </p>
              <div className="speaker" id="sp2854"><span className="persName">Mr. JOHNSON</span>, of Richmond—</div>
              <p className="p-in-sp">If the gentleman will allow me, without taking up a moment of his time—</p>
              <PageNumber num={437} />
              <div className="speaker" id="sp2855"><span className="persName">Mr. GOODE</span>—</div>
              <p className="p-in-sp">My time is nearly out now.</p>
              <div className="speaker" id="sp2856"><span className="persName">Mr. JOHNSON</span>—</div>
              <p className="p-in-sp">I merely wish to say while it may represent the position of those with whom I co-operate, I beg leave to say that is not the position which I assume.</p>
              <div className="speaker" id="sp2857"><span className="persName">Mr. GOODE</span>—</div>
              <p className="p-in-sp">
                Well, let that matter go. But, now, the gentlemen, notwithstanding the universal sentiment in the Virginia heart, that something must be done, and done now, gentlemen ask us to wait and consult the border States of this Confederacy. Mr. Chairman, I do hope that such is not the deliberate purpose of this Convention. I hope, if we are not yet prepared to sever the connection of
                Virginia with this Northern Confederacy, that we are prepared to fix some day in the future when we will be prepared to do so, unless our terms, in the language of my friend, shall be acceded to. I think it must be obvious to every member of the committee that the amendment I propose is a suitable and proper amendment. If this Convention mean to do anything, if we are not going
                through the solemn forms of mockery, if we intend to fix the destiny of this people, then, sir, it ought to be understood by our constituencies, and by the Northern people, that we are in earnest.
              </p>
              <p className="p-in-sp">
                You talk about your ultimatum. It is proposed to send this to the Northern people; and yet, if this amendment is voted down, you send it to that people with the distinct declaration : "If you change it, if you adopt some of the amendments to the Constitution, and reject others, why we are not in earnest about this ultimatum, we will consider it next fall, and we will take terms
                different from those laid down in this ultimatum." The gentleman says, we can do nothing except what shall be passed upon by the people. As I understand the act calling this Convention, it provides that this Convention shall take no action severing the connection of Virginia with the Southern Confederacy, or changing her organic law, without submitting that action to the people. But
                it is contemplated that these amendments to the Constitution shall be voted upon by the people before they are sent to our Northern confederates? I apprehend no popular vote is needed before these amendments are to be responded to; and I insist upon it, if gentlemen are in earnest when they talk about an ultimatum, that it will be understood by the Northern people that we mean an
                ultimatum, and that it will be expressed in clear, distinct and unequivocal terms, so that he who runs may read.
              </p>
              <div className="speaker" id="sp2858"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The question is upon the adoption of the amendment.</p>
              <div className="speaker" id="sp2859"><span className="persName">Mr. GOODE</span>, of Bedford—</div>
              <p className="p-in-sp">I call for the yeas and nays.</p>
              <PageNumber num={438} />
              <p>The roll was then called and the amendment was rejected by a vote of yeas 44, nays 74—as follows :</p>
              <p>
                YEAS-Messrs. Ambler, James Barbour, Blakey, Boisseau, Borst, Bouldin, Bruce, Cabell, Cecil, Chambliss, Chapman, Conn, R. H. Cox, Echols, Flournoy, Garland, Graham, Goggin, J. Goode, Jr., T. F. Goode, Hale, L. S. Hall, Harvie, Holcombe, Hunton, Kent, Lawson, Leake, Marr, Marye, Sr., Montague, Morris, Morton, Neblett, Richardson, Seawell, Sheffey, Speed, Strange, Sutherlin, Thornton,
                Tredway, Williams, Wise-44.
              </p>
              <p>NAYS-Messrs. Janney [President]
                , Aston, Baldwin, A. M. Barbour, Baylor, Berlin, Blow, Boggess, Branch, Brent, Brown, Burdett, Byrne, Campbell, Caperton, Carlile, Carter, Coffman, C. B. Conrad, R. Y. Conrad, Couch, Critcher, Custis, Dent, Deskins, Dorman, Dulany, Early, French, Fugate, Gillespie, Gravely, Gray, Addison Hall, Ephraim B. Hall, Hammond, Haymond, Hoge, Hubbard, Hughes, Jackson, Marmaduke Johnson, P. C.
                Johnston, Lewis, McComas, McGrew, J. B. Mallory, Marshall, Masters, Moffett, Moore, Orrick, Osburn, Parks, Patrick, Pendleton, Porter, Price, Pugh, Rives, Wm. C. Scott, Sitlington, Slaughter, Spurlock, Staples, C. J. Stuart, Summers, Tarr, Tayloe, Waller, White, Wickham, Willey, Wilson-74.
              </p>
              <div className="speaker" id="sp2860"><span className="persName">Mr. RICHARDSON</span>, of Hanover—</div>
              <p className="p-in-sp">As I understand the question, sir, the 11th resolution has been amended upon the motion of the gentleman from Augusta. I presume, sir, that the whole resolution, as amended, can be amended again?</p>
              <div className="speaker" id="sp2861"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">Certainly, sir.</p>
              <div className="speaker" id="sp2862"><span className="persName">Mr. RICHARDSON</span>—</div>
              <p className="p-in-sp">
                I move to strike out all after the word "relations" in the third line, to the word "appended," in the 1 1 th line, inclusive, and to insert in place of that portion which I move to strike out, the following : "respectfully requests the people of the Northern States, as speedily as possible, by majority votes in all of their Congressional districts, to instruct their Representatives
                in Congress to propose as soon as it
                [Congress] can be assembled, the amendments to the Constitution, declared to be necessary by this Convention, to be ratified in one of the modes provided in the 5th article of the Constitution of the United States."</p>
              <p className="p-in-sp">So that the section as amended will read :</p>
              <p className="p-in-sp">
                "11. This Convention, composed of delegates elected by the people in districts, for the purpose of considering the existing difficulties in our Federal relations, respectfully requests the people of the Northern States, as speedily as possible, by majority votes in all of their Congressional districts, to instruct their representatives in Congress to propose as soon as it
                [Congress]
                can be assembled, the amendments to the constitution declared to be necessary by this Convention to be ratified in one of the modes provided in the 5th article of the <PageNumber num={439} /> constitution of the United States. And in the event that this Commonwealth fails to obtain satisfactory responses to her requests, from the non-slaveholding
                States, she will feel compelled to resume the powers granted by her under the constitution of the United States, and to throw herself upon her reserved rights."
              </p>
              <p className="p-in-sp">
                I am free to confess, Mr. Chairman, that the only part of the resolution under consideration which I approve, is the latter clause, which says, "that in the event that this Commonwealth fails to obtain satisfactory responses to her requests from the non-slaveholding States, she will feel compelled to resume the powers granted by her under the Constitution of the United States, and
                throw herself upon her reserved rights." The preceding part of the section carries on its face the objection of a carte blanche to the North, to delay indefinitely a response to the Constitutional amendments which will be proposed by this Convention. It says : "Virginia, therefore, requests the people of the several States, either by popular vote, or in Convention similar to her own,
                to respond at their earliest convenience to the positions assumed in the foregoing resolutions, and the proposed amendments to the Constitution of the United States hereunto appended." No time is fixed within, or mode indicated, by which the Northern States are to respond. It is left to them to answer in any election, or in none at all; when they vote for Magistrates and Constables,
                or for Judges, or for members of the Legislature, or for Congressmen, or for President, or never. They shall not, with my consent, have this opportunity for unlimited delay. So far as my voice goes, I demand that they come to the point, and decide at once. Now, sir, the amendment I have proposed is distinct and definite. It requires them to say, as speedily as possible, whether or
                not they will accede to the propositions of this Convention, and if they do not respond, as soon as the response can properly be made, it declares that the State will resume the powers delegated by her to the General Government. Let me not be misunderstood. I expect to vote for the resolution under consideration in some form, because the latter clause contains what, in my judgment,
                is an endorsement of the right of secession. Without that I would repudiate it utterly, as delaying the action of the State longer than I am willing to wait. Neither would I touch my own amendment as an original proposition. I know that the resolution under consideration will pass in some form, and I offer my amendment as an improvement to it, though the amendment, itself, is bad
                enough—it is a mere choice of evils—it is better than that part of the resolution it proposes to strike out, because it destroys as much of its vagueness as the <PageNumber num={440} /> nature of the case admits of ; as vi termini, it excludes the idea of a National Convention, because it aims to instruct Congress to propose the amendments at once ; and
                where Congress proposes amendments, they are referred by the very terms of the fifth article of the Constitution not to a National Convention, but to the Legislatures or a Convention of three-fourths of the States. The resolution of the Committee requests the Northern people to respond—this response, unless through their Representatives in Congress or through their Legislatures, will
                amount to nothing. No clause in the Constitution gives them the right to amend that instrument by popular vote. The 5th article thereof says, Congress shall propose amendments by a two-thirds vote, or shall call a Convention to amend the Constitution on the application of the Legislatures of two-thirds of the States. My resolution ignores the latter mode and adopts the former as the
                most direct and speeedy mode. It is a foregone conclusion that the appeal is to be made to the Northern people, and as this is going to be done by the Convention, my object is to obtain the speediest action in the Constitutional mode. Though if I had my way no more proposals should be made by Virginia alone to the North at all.
              </p>
              <div className="speaker" id="sp2863"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">If I understand the resolution of my friend from Hanover it proposes directly that this Convention shall address itself not to the State authority, not to the people in their organized capacity in their States, but to the people in primary assembly in their Congressional Districts.</p>
              <div className="speaker" id="sp2864"><span className="persName">Mr. RICHARDSON</span>—</div>
              <p className="p-in-sp">Yes, sir; but it requests them to act constitutionally through their representatives.</p>
              <div className="speaker" id="sp2865"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Well, sir, I cannot consent to that.</p>
              <div className="speaker" id="sp2866"><span className="persName">Mr. RICHARDSON</span>—</div>
              <p className="p-in-sp">
                I will say to my friend from Princess Anne, my proposition is that the people meet in their Congressional Districts and instruct their representatives. The resolution under consideration proposes an appeal to the Northern people—they must speak in response through their representatives in Congress or through their legislatures. I prefer the former as the speediest and most direct
                mode.
              </p>
              <div className="speaker" id="sp2867"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                I understand the gentleman's proposition. Can we, as a State, go outside of our own limits, to instruct other constituent authorities? Why, sir, it would be an example worse than anything I have seen yet proposed. It is what the Committee have expressly intended to avoid in their resolution. The idea of a sovereignty appealing directly to the people of other States is one not to be
                tolerated. We would certainly not tolerate it for another State to come here and appeal to the people in primary meetings to instruct us. My friend <PageNumber num={441} /> from Hanover, I am sure, would not tolerate for an instant that the sovereign authorities of Massachusetts or Pennsylvania should come down here and appeal to the people of Hanover
                to instruct him. If an agent were to come down to the county of Princess Anne, with a resolution from Pennsylvania appealing to the people to instruct me, I will not undertake to predict what his fate would be. He certainly would be required to remove out of the county. Sir, we have no right to appeal to the people in the other States to instruct their Congressmen to propose
                amendments to the Constitution of the United States. We would not tolerate such interference from the people of the other States and I take it for granted that the people of other States would not tolerate it from us.
              </p>
              <div className="speaker" id="sp2868"><span className="persName">Mr. RICHARDSON</span>—</div>
              <p className="p-in-sp">
                What I mean by my amendment is perfectly plain. This resolution under discussion, and which I presume will be voted for by the gentleman from Princess Anne, and adopted by the Committee, says that, "Virginia, therefore, requests the people of the several States, either by popular vote or in Conventions similar to her own, to respond at their earliest convenience to the positions
                assumed in the foregoing resolutions and the proposed amendments to the Constitution of the United States hereunto appended."
              </p>
              <p className="p-in-sp">
                Well, now, sir, supposing that resolution is adopted, when are the people to vote in response? When is the election to be called under which they are to vote? By what authority is it to be called? Cannot their vote be postponed for years, or forever? As I before stated, the vote of the people sanctioning the constitutional amendments proposed by this Convention will amount to
                nothing, unless that vote be sanctioned in the manner prescribed by the Constitution of the United States for its own amendment. That Constitution provides that Congress, whenever two-thirds of both Houses shall deem it necessary, shall propose amendments to the constitution; or on the application of two-thirds of the Legislatures of the several States shall call a Convention for
                proposing amendments, which in either case shall be valid to all intents and purposes, when ratified by three-fourths of the Legislatures or Conventions of the several States. Well, sir, what is the most direct mode by which the vote of the people is to sanction these amendments? Is it not to instruct their representatives in Congress to propose them? Unquestionably; because if they
                do not speak at once through their representatives in Congress, they must speak through their Legislatures, which Legislatures must then apply to Congress to call a Convention to propose amendments, which amendments are to be ratified in precisely the same way as if they were <PageNumber num={442} /> proposed by Congress. The proceeding beginning with
                the Legislatures, is three times as long as that brought about by a direct instruction to Congress, and it is in order that these amendments may be sanctioned by the people in such form as to be authoritative and binding, that I propose this resolution; they must be proposed by two-thirds of Congress, or by the application of two-thirds of the Legislatures of the several States. My
                distinguished friend from Princess Anne objects to an amendment requesting the Northern people to instruct their representatives. I admit that this is objectionable, but there is no other way of getting over the difficulty. If we have a right to request them to respond at all, surely we have the right to request them to make a response which will be of some value, and not a mere
                farce. It is no act or fault of mine that such an appeal is to be made to the Northern people. Could my counsels prevail it should not be done at all—but it will be done—and in God's name let it be so done, that it will be responded to, if at all, at once, and will amount to something. I desire that the most direct mode of obtaining that expression shall be adopted. I desire that
                they shall not have the privilege of delaying their action forever. The object, then, of my amendment is so to alter the resolution as to require at once, through a Constitutional mode, an authoritative response from the people of the Northern States. If a mode can be adopted by which they are required to decide the matter speedily and in a constitutional way, then the resolution
                will be improved, though even then it will be bad enough.
              </p>
              <p>The amendment was disagreed to.</p>
              <div className="speaker" id="sp2869"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">I move to strike out the whole of the 11th section and insert in lieu thereof, as follows :</p>
              <p className="p-in-sp">
                "This Convention is of the opinion that the extraordinary condition of the country resulting from our national difficulties renders it proper and necessary that amendments should be made to the Federal Constitution; and it is also of the opinion that the proposed amendments to said Federal Constitution hereunto appended, if adopted, will be satisfactory to the people of Virginia, and
                ought to be so to all the other slave States."
              </p>
              <p className="p-in-sp">
                Mr. Chairman, I am not to be affected in my course in regard to this report by any telegraphic dispatches. I have witnessed too many stampedes, caused by false rumors, to be now frightened from my propriety in that way. It seems to me that gentlemen on this floor who talk about laying down an ultimatum, forget utterly the powers with which this Convention is invested. The bill under
                which we are called provides that the question should be submitted to the people, <PageNumber num={443} /> whether in the event of our adopting an ordinance of secession, our action should be referred back to them, and by a majority of over 60,000 they decided that we should pass no ordinance or other act severing our connection with the Federal
                Government without submitting it to their ratification or rejection at the polls. Gentlemen whose constituents voted against referring, may feel themselves authorized to lay down an ultimatum, but my constituents voted, by a majority of over 1,200, that the action of this Convention should be referred back to them. How can this Convention lay down, on behalf of the people of
                Virginia, an ultimatum that they will, in certain contingencies, feel compelled to resume the powers delegated under the Federal Constitution? If we do so, may we not be placed in this position—that our ultimatum, not being accepted by the North, we will have to pass an ordinance of secession, and the people will refuse to ratify it?
              </p>
              <p className="p-in-sp">
                But, sir, we have not only not a right to pass an ordinance of secession without submitting it, but we have no power to lay down an ultimatum without also submitting it. But, besides, the 14th resolution contemplates a consultation with the border slave States. Where is the necessity of consulting with the border slave States if we are first to lay down our ultimatum and declare that
                if not acceded to Virginia will go out of the Union? Would it not be an insult to those border slave States to invite them to a conference after having laid down an ultimatum? I have, therefore, submitted my substitute for the 1 1 th resolution which deprives it of the character of an ultimatum. When we shall have made every effort to procure the desired amendments, it will be time
                enough for us to decide what Virginia ought to do, and to submit that action to the legal voters at the polls.
              </p>
              <p><span className="persName">Mr. E. B. HALL</span> called for a division of the question.</p>
              <p><span className="persName">Mr. SPEED</span> called for the yeas and nays on striking out the eleventh section.</p>
              <p>The yeas and nays were not ordered.</p>
              <p>The question was taken, and the Committee refused to strike out the eleventh section.</p>
              <p>The Chairman ' [<span className="persName">Mr. MONTAGUE</span> in the Chair] stated that that vote adopted the eleventh section.</p>
              <div className="speaker" id="sp2870"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">Do I understand the Chair to decide that on a motion to strike out and insert, the failure to strike out is the adoption of the resolution?</p>
              <div className="speaker" id="sp2871"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">That is the opinion of the Chair, and the Chair understands it has been so decided by the former occupant.</p>
              <PageNumber num={444} />
              <div className="speaker" id="sp2872"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">Is there not a special rule on the subject?</p>
              <div className="speaker" id="sp2873"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Chair will see whether there is a special rule. The Chair only decided on the general proposition.</p>
              <div className="speaker" id="sp2874"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">I think the decision of the Chair has been that a simple motion to strike out a section being rejected, adopts the section; but a motion to strike out and insert being rejected, is not an adoption of the section.</p>
              <div className="speaker" id="sp2875"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">On reference to the Rule, the Chair sees that the gentleman is right. The question is on the 11th resolution.</p>
              <div className="speaker" id="sp2876"><span className="persName">Mr. TARR</span>—</div>
              <p className="p-in-sp">
                I move to strike out the following words, after the word "will" in the 14th line of the 11th resolution : "feel compelled to resume the powers granted by her under the Constitution of the United States, and to throw herself upon her reserved rights," and insert in lieu thereof the words "take such action as she may deem necessary for the maintenance of her honor and the protection of
                her interests."
              </p>
              <p className="p-in-sp">
                I take it, Mr. Chairman, that a majority of this Convention are in favor of taking such a course as will be adequate to the protection of the interests of Virginia. I think that a majority are in favor of Virginia remaining in the Union, and I think there are no members of the Convention who regard dealing in threats as necessary to the honor of Virginia. I conceive that the
                amendment which I have offered should satisfy all views. If an ultimatum is made and rejected. then the question of secession will still have to be submitted to the people; and, as the gentleman from Franklin
                [<span className="persName">Mr. EARLY</span>] remarked. it would place us in a very awkward position for the people to refuse then to ratify our action.</p>
              <p className="p-in-sp">I call for the yeas and nays on my amendment.</p>
              <p>The yeas and nays were ordered.</p>
              <p>
                The question was taken and it was decided in the negative—yeas 39, nays 78, as follows : <span className="note" id="Note46"><span className="noteLabel">3</span>The Journal adds Ambler and Blakey to this list of negative votes.</span>
              </p>
              <p>YEAS-Messrs. John Janney [President] , Aston, Baldwin, Baylor, Berlin, Boggess, Brown, Burdett, Campbell, Carlile, Carter, C. B. Conrad, Couch, Custis, Dent, Early, French, Fugate, E. B. Hall, Haymond, Hubbard, Jackson, Lewis, McComas, McGrew, Masters, Osburn, Patrick, Pendleton, Porter, Rives, R. E. Scott, Sitlington, C. J. Stuart, Summers, Tarr, Wickham, Willey, Wilson-39.</p>
              <p>
                NAYS-Messrs. Armstrong, J. Barbour, Blow, Jr., Boisseau, Borst, Bouldin, Branch, Brent, Bruce, Cabell, Caperton, Cecil, Chambliss, Chapman, Coffman, Conn, R. Y. Conrad, Richard H. Cox, Critcher, Deskins, Dorman, Dulany, Echols, Flournoy, Garland, Gillespie, Graham, Gravely, Goggin, John Goode, Jr., T. F. Goode, Hale, A. Hall, Hammond, Harvie, Hoge, Holcombe, Hughes, Hunton, M.
                Johnson, P. C. Johnston, Kent, Lawson, Leake, C. K. <PageNumber num={445} /> Mallory, J. B. Mallory, Marshall, Marr, Marye, Sr., Moffett, Montague, Morris, Morton, Moore, Neblett, Nelson, Orrick, Parks, Price, Pugh, Richardson, W. C. Scott, Seawell, Sheffey, Slaughter, Speed, Spurlock, Staples, Strange, Sutherlin, Tayloe, Thornton, Tredway, Waller,
                Williams, Wise-78.
              </p>
              <p>So <span className="persName">Mr. TARR</span>'S amendment was rejected.</p>
              <div className="speaker" id="sp2877"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">I desire to move an amendment to strike out the words "at their earliest convenience," and insert in lieu thereof the words "by the first day of August next."</p>
              <div className="speaker" id="sp2878"><span className="persName">Several MEMBERS</span>—</div>
              <p className="p-in-sp">That is too soon.</p>
              <div className="speaker" id="sp2879"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">It is nearly four months.</p>
              <div className="speaker" id="sp2880"><span className="persName">Mr. BRUCE</span>—</div>
              <p className="p-in-sp">I suggest to the gentleman that it is better to withdraw his amendment and let the vote be taken on the resolution.</p>
              <div className="speaker" id="sp2881"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">Very well, I withdraw it.</p>
              <p>The yeas and nays were called for and ordered.</p>
              <p>The question was taken and it was decided in the affirmative yeas 79, nays 41, as follows :</p>
              <p>
                YEAS-Messrs. Ambler, Armstrong, Blakey, Blow, Borst, Bouldin, Branch, Brent, Bruce, Byrne, Cabell, Caperton, Cecil, Chambliss, Chapman, Coffman, Conn, R. Y. Conrad, Richard H. Cox, Critcher, Custis, Deskins, Dorman, Dulany, Echols, Flournoy, French, Garland, Gillespie, Graham, Gregory, Goggin, Jno. Goode, T. F. Goode, Hale, Addison Hall, Cyrus Hall, Hammond, Harvie, Hoge, Holcombe,
                Marmaduke Johnson, P. C. Johnston, Kent, Lawson, Leake, C. K. Mallory, J. B. Mallory, Marshall, Marr, Marye, Moffett, Montague, Morris, Morton, Moore, Neblett, Nelson, Orrick, Parks, Pugh, Richardson, R. E. Scott, Wm. C. Scott, Seawell, Sheffey, Slaughter, Speed, Spurlock, Staples, Sutherlin, Tayloe, Thornton, Tredway, Waller, White, Wickham, Wilson, Wise-79.
              </p>
              <p>NAYS-Messrs. Janney [President] , Aston, Baldwin, A. M. Barbour, James Barbour, Baylor, Berlin, Boggess, Boisseau, Brown, Burdett, Campbell, Carlile, Carter, C. B. Conrad, Couch, Dent, Early, Fugate, Gravely, Ephraim B. Hall, Haymond, Hubbard, Hughes, Hunton, Jackson, Lewis, McComas, McGrew, Masters, Osburn, Patrick, Pendleton, Porter, Price, Sitlington, C. J. Stuart, Strange, Summers, Tarr, Willey-41.</p>
              <p>So the resolution was adopted.</p>
              <p>During the vote,</p>
              <p><span className="persName">Mr. MORRIS</span> stated that in voting "aye" he did not intend to be committed to anything, except the latter clause of the resolution.</p>
              <p>Similar explanations were made by Messrs. <span className="persName">RICHARDSON</span>, <span className="persName">MONTAGUE</span>, and<span className="persName">R. H</span>. Cox.</p>
              <div className="speaker" id="sp2882"><span className="persName">Mr. WICKHAM</span> said—</div>
              <p className="p-in-sp">I desire to say that I do not regard this resolution as in any way recognizing the right of secession. If I did, I should not vote for it. I vote "aye."</p>
              <PageNumber num={446} />
              <p>The vote was announced as above recorded.</p>
              <p>The 12th resolution was then taken up for consideration. It reads as follows :</p>
              <p>
                "12. The people of Virginia will await any reasonable time to obtain answers from the other States, to these propositions, aware of the embarrassments that may produce delay, but they will expect, as an indispensable condition, that a pacific policy shall be adopted towards the seceded States, and that no attempt be made to subject them to the Federal authority, nor to reinforce the
                forts now in possession of the military forces of the United States, nor recapture the forts, arsenals or other property of the United States within their limits, nor to exact the payment of imposts upon their commerce ; nor any measure resorted to, justly calculated to provoke hostile collision."
              </p>
              <div className="speaker" id="sp2883"><span className="persName">Mr. CRITCHER</span>—</div>
              <p className="p-in-sp">I move to amend the second line of the 12th resolution, by striking out the words "these propositions" and inserting the words "the amendments hereunto appended." This is merely to make this resolution conform to the previous one as amended by the gentleman from Monongalia [<span className="persName">Mr. WILLEY</span>] .</p>
              <div className="speaker" id="sp2884"><span className="persName">Mr. CONRAD</span>, of Frederick—</div>
              <p className="p-in-sp">I desire to call the attention of the gentleman to the fact that I think, in looking at it again, he will discover, that the language, as it stands now in the section is entirely pertinent to the amendment previously made. That amendment only leads the proposition referred to in this section. The amendments are in fact now this proposition.</p>
              <div className="speaker" id="sp2885"><span className="persName">Mr. CRITCHER</span>—</div>
              <p className="p-in-sp">I do not so understand the effect of the amendment.</p>
              <div className="speaker" id="sp2886"><span className="persName">Mr. CONRAD</span>—</div>
              <p className="p-in-sp">It so strikes me; but I have no objection to the amendment.</p>
              <div className="speaker" id="sp2887"><span className="persName">Mr. CRITCHER</span>—</div>
              <p className="p-in-sp">The eleventh section, as it originally stood, reads, from the sixth to the eleventh lines :</p>
              <p className="p-in-sp">"Virginia, therefore, requests the people of the several States, either by popular vote or in Conventions similar to her own, to respond at their earliest convenience to the positions assumed in the foregoing resolutions and the proposed amendments to the Constitution hereunto appended."</p>
              <p className="p-in-sp">This reads now :</p>
              <p className="p-in-sp">"To respond at their earliest convenience, to the proposed amendments to the Constitution of the United States," &amp;c.</p>
              <p className="p-in-sp">Now, in the twelfth resolution, which reads at present :</p>
              <p className="p-in-sp">"The people of Virginia will await any reasonable time to obtain answers from the other States to these propositions," &amp;c.</p>
              <PageNumber num={447} />
              <p className="p-in-sp">Now, to carry out the idea set forth in the foregoing resolution this part of the resolution should read :</p>
              <p className="p-in-sp">"The people of Virginia will await any reasonable time to obtain answers from the other States to the amendments hereunto appended," &amp;c.</p>
              <p className="p-in-sp">Now, the effect of my amendment will be, in all deference to the gentleman's superior judgment, to make this part of the resolution conform to the foregoing resolution as amended by the gentleman from Monongalia [<span className="persName">Mr. WILLEY</span>] .</p>
              <div className="speaker" id="sp2888"><span className="persName">Mr. BALDWIN</span>, of Augusta—</div>
              <p className="p-in-sp">If the gentleman instead of striking out will insert the words "of amendments" after the word "propositions" he will accomplish his object.</p>
              <p><span className="persName">Mr. CRITCHER</span> was understood to accept this modification, and the amendment was agreed to.</p>
              <div className="speaker" id="sp2889"><span className="persName">Mr. CARTER</span>, of Loudoun—</div>
              <p className="p-in-sp">I propose to amend this 12th section by striking out after the word "authority" in the 6th line, to the word "commerce" in the 10th line.. If that amendment prevails, it will read :</p>
              <p className="p-in-sp">
                "The people of Virginia will await any reasonable time to obtain answers from the other States to these propositions of amendments, aware of the embarrassments that may produce delay, but they expect, as an indispensable condition, that a pacific policy shall be adopted towards the seceded States, and that no attempt be made to subject them to the Federal authority nor any measure
                resorted to justly calculated to provoke hostile collision."
              </p>
              <p className="p-in-sp">
                Mr. Chairman, I presume that I but speak the wish and the desire of every member of this Committee, when I say, that the great object and the great purpose to be now attained, is to preserve peace between the present government of the United States and the Confederated States? And I have no doubt that the object of this resolution, as it now stands, contemplates, and only
                contemplates, that this peace shall be preserved. But, sir, you will observe that after going on and stating in strong language as "an indispensable condition" "that a pacific policy shall be adopted towards the seceded States, and that no attempt shall be made to subject them to the Federal authority;" they go on then to enumerate certain acts which they would consider as
                interrupting this peace, and one of these recited causes in this indispensable condition is that the General Government of the United States should not "re-inforce the forts now in possession of the military forces of the United States."
              </p>
              <p className="p-in-sp">
                Now, sir, I suppose that there are certain forts in the possession <PageNumber num={448} /> of the military forces of the United States, which we would all be glad to see vacated and given up to the authorities of the Confederated States—Fort Sumter for instance, and probably some others. But there are certain other forts held by the military forces of
                the United States from which I, at least, am not prepared to say—however other gentlemen may feel—that it would be proper and right to withdraw the military forces of the United States. One of these is the Tortugas, which, I understand, was built not so much for the protection of the State of Florida as it was for the protection of the commerce of the Gulf of Mexico; and if we adopt
                this resolution as it now stands, any attempt by the authorities of the Federal Government to throw forces, provisions or munitions into that fort would be a violation of one of these "indispensable conditions" which we lay down in this resolution.
              </p>
              <p className="p-in-sp">
                I am not prepared to vote for that, but I am prepared to vote that they shall observe all possible forbearance towards the Confederate States; that they shall perform no act which shall look in any way to their subjugation, and I am willing to vote that their commerce shall not be interfered with, and that Fort Sumter shall be given up, and, in fact every other fort within the
                Confederate States; but I am not willing to vote that we should lay down here, as "an indispensable condition" that all the forts now held by the military forces of the United States shall be given up to the authorities of the Confederate States.
              </p>
              <div className="speaker" id="sp2890"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">This is a very curious doctrine of peace that is given to us by my friend from Loudoun [<span className="persName">Mr. CARTER</span>] .</p>
              <p className="p-in-sp">
                I ask my friend if he talked that way to his people when he told them that he was for peace ; that he was for a pacific policy, and opposed to the subjugation of these people to the Federal authority? I ask him whether he distinctly told them, at the same time, that he did not think it an act of hostility for the Federal Government to hold these forts; that he did not think it
                hostile to the seceded States for the Federal Government to wield their own guns in their own territory? Now he begins to elucidate what the idea of some of his friends is about peace.
              </p>
              <div className="speaker" id="sp2891"><span className="persName">Mr. CARTER</span> [in a low voice]—</div>
              <p className="p-in-sp">Happy to hear it.</p>
              <div className="speaker" id="sp2892"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                Happy to hear it! I am unhappy to hear it, because these doctrines of peace are the doctrines that really open the gates of the temple of Janus. It seems that our temple of Janus has one gate thrown open for peace, and one for war. I tell the gentleman, that his gate is the gate of war alone. The gate which the gentleman <PageNumber num={449} /> opens
                is the gate of hostility. Will he please tell me what difference he can make; what distinction he can make between the Tortugas in Florida which has seceded and the Forts of South Carolina within the bay of Charleston harbor? Where is the distinction in international law? where is the authority any more to hold the Tortugas than to hold the Forts at Charleston—Fort Sumter and Fort
                Moultrie, for instance? The gentleman tells us that he is not for interfering with the commerce of the seceded States, and yet he moves to strike out all frcm the word "authority" in the 6th line down to the word "commerce" in the 10th line, when the language of the last part of this section which he proposes to strike out proposes to prevent the Federal Government from exacting "the
                payment of imposts" upon the commerce of the Confederate States. Let me tell my friend that that very phrase gave us more difficulty before the Committee of Twenty-One than almost any other phrase in the Report—some gentlemen contending that we have a right, as some contend now, to defend our revenue by turning the ships while set upon the high seas, away from the port of Charleston
                to the port of New York. The question was asked, how are we to defend our revenue unless we can divert the ships bound to the Confederate ports from their port to our port on the ground of self-preservation and self defence? It was thought that they had a right to turn a ship before she reached that port. How can you take her upon the high seas if she sails under a foreign flag? If
                you take a ship sailing under the English or French flag, you will be guilty of piracy, and England and France will hold you responsible. Take her when she has passed the boundary line of the high seas and entered up the Eastern line of the marine league, and then you raise the question of jurisdiction. To whom does the marine league belong—to the Federal Government or the State? Is,
                there, a gentleman here who will say that the jurisdiction of Virginia does not cover the marine league? There is not a man in Northampton, in Accomac or in Princess Anne who will not pursue a fugitive out that distance and arrest him, with a full conviction that he is acting within the jurisdiction of Virginia. Let a man commit murder of his fellow within that marine league, and
                what court is it that will not decide that it is within the jurisdiction of Virginia? To whom does the marine league belong? Upon national grounds, and while we are a confederate people it belongs to the United States; but how came the United States in possession of the marine league? Will the gentleman say, as an international lawyer, that the Tortugas didn't belong to the United
                States, before the secession, in right of the State of Florida?
              </p>
              <PageNumber num={450} />
              <p className="p-in-sp">
                Florida was indeed created a State after the United States were established—she was purchased from Spain; but after the purchase, she was created a State upon an equal footing with the original States. Will the gentleman tell me that the original States had not the power of eminent domain three leagues out at sea? Where do these three leagues commence? They commence in limine<span
                  className="note"
                  id="Note47"
                ><span className="noteLabel">4</span>A correction (April 16) notes that the Latin phrase should read in littore.</span
                >
                upon the cut of the surf at low water mark; and it is well settled that they extend beyond the low water mark from peninsula, or head land, or island. All those difficult questions are to be swallowed up and submerged in pacification. The seceded States, forsooth, shall yield all, in order that no attempt shall be made at subjecting them to Federal authority. Is it not subjugating
                them, when the Federal Government continues to hold the forts within their territory? How was it with the United States themselves, when Great Britain took and meant to hold, under the stipulations of the treaty of Ghent, a fort upon our border? Negotiation and correspondence were resorted to in vain ; and at last the threat was made, if the British lion did not take his invading paw
                from off that fort, it would be taken, at the hazard of another war. What proud sovereignty, claiming to be sovereign, will allow the army of an enemy—for such is the relation in which the Confederate States view the Federal Government—to remain within her limits? Not one. If you mean peace, say so; and say, specifically, what you understand to be peace. Don't delude your people, I
                appeal to you, and say peace, peace to them, when there is no peace.
              </p>
              <p className="p-in-sp">
                Sir, I said this morning, if this administration would only pursue the line of a magnanimous policy, it would evacuate every one of these forts immediately. But if the amendment of the gentleman shall not prevail, I propose to add here, what the resolution does not embrace, the declaration that it is their duty, for the sake of peace, to evacuate at once all the forts, and relieve us
                from this apprehension of war. Let the question of adjustment go on. In the name of God, do not allow the attempts at adjustment to be conducted at the cannon's mouth.
              </p>
              <div className="speaker" id="sp2893"><span className="persName">Mr. CARTER</span>—</div>
              <p className="p-in-sp">I regret that I am not better able to meet in debate the gentleman from Princess Anne, but still I have very distinct ideas upon this subject, of what I think would be right, and therefore I still insist upon my proposed amendment to strike out the words I have indicated, and I will endeavor, so far as my time will allow me, to make myself understood.</p>
              <p className="p-in-sp">
                The Committee will observe that this resolution proposes to keep the peace, as between the Confederated States and the Government of <PageNumber num={451} /> the United States, and it will observe that in laying down the proposition, it lays down no indispensable condition as to what the Confederated States shall or shall not do. According to this
                resolution, they are left free to act at will; to accumulate forces, to make attacks, and the whole force of the resolution goes to prevent the United States from taking any steps to make war, or even to reinforce the forts they now hold. It does seem to me if perfect equality was contemplated by this resolution, that it should have also provided that the Confederated States should
                not perform any hostile acts towards the General Government. The whole idea, as I understand it, is that so far as the territory now occupied by the Confederate States, and by the Government of the United States is concerned, whilst these peace negotiations of Virginia are going on, it shall remain as it now is.
              </p>
              <p className="p-in-sp">
                A question much mooted here has been how the status quo should be preserved. And yet, the effect of this phrase is, if you make it "an indispensable condition" that the General Government of the United States should not throw reinforcements into their forts, and you yet have the Confederated States to gather around those forts whatever forces for attack they may choose and even make
                an attack, we in point of fact tell the General Government that it is an "indispensable condition" that they should give up those forts. Yes, sir, all of them; for it seems to me that, if they are not to be re-inforced, if the other party is left free to gather around them what forces they choose and even to make an attack, what is it but to say to the United States you must
                surrender them up?
              </p>
              <div className="speaker" id="sp2894"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I would ask the gentleman now, upon the supposition that this invading force that has gone down the coast—where we know not—does attempt to re-inforce Fort Sumter, and that fort is attacked by South Carolina, would he consider that the General Government, if it thus brings on a war, as establishing pacific policy?</p>
              <div className="speaker" id="sp2895"><span className="persName">Mr. CARTER</span>—</div>
              <p className="p-in-sp">
                I would consider the General Government as pursuing a very bad policy, if it was to make an attempt to reinforce Fort Sumter; I think it is pursuing a very bad policy not to evacuate it at once; but the difference between this Fort Tortugas, which I am not prepared to say ought to be given up, and the other State forts, is, that they are within the harbors of these different
                Confederated States. The peculiarity of this Fort Tortugas is that it was built for the protection of the commerce of the Gulf, and if the General Government were to give it up to Florida, she could not protect the commerce of the Gulf. Therefore, there is a very clear and wide <PageNumber num={452} /> marked distinction between Fort Sumter and Fort
                Tortugas. But the Committee will please observe that I do not propose to amend the resolution so as to require that the General Government shall not give up this fort, if it should be deemed the best policy. The resolution as amended does not prevent the government from giving it up, it only prevents it being considered an "indispensable condition," that it should not be re-inforced.
                That is all it does. The very fact that you say it is an "indispensable condition" that no re-inforcement should be sent to Tortugas, is tantamount to saying that you make it an "indispensable condition" that the government should give it up, because if it is held, it must be re-inforced. I want the Committee to bear in mind this marked and clear difference between the forts within
                the particular harbors of the Confederated States and this Fort Tortugas. It does not afford any protection to Florida; but it barely occupies a barren rock on a sand island sixty or seventy miles from the main land, and is only needed for the protection of the commerce of the Gulf ; and if we give it up to Florida to-morrow, Florida cannot protect the commerce of the Gulf—not even
                her own commerce. I think it better for Florida and the United States both that it should be in the hands of the government that can protect the commerce of the Gulf.
              </p>
              <p><span className="persName">Mr. MORTON</span> called for the yeas and nays, and they were ordered. The question was then taken upon <span className="persName">Mr. CARTER</span>'S amendment, and it was rejected yeas 36, nays 85, as follows :</p>
              <p>YEAS-Messrs. Janney [President] , Baldwin, Berlin, Boggess, Brown, Burdett, Byrne, Campbell, Carlile, Carter, C. B. Conrad, Couch, Dent, Early, Gravely, Ephraim B. Hall, Hoge, Hubbard, Hughes, Jackson, Lewis, McGrew, Masters, Moore, Osburn, Patrick, Pendleton, Porter, Rives, Sitlington, Spurlock, C. J. Stuart, Summers, Tarr, White, Willey-36.</p>
              <p>
                NAYS-Messrs. Ambler, Armstrong, Aston, A. M. Barbour, James Barbour, Baylor, Blakey, Blow, Boisseau, Borst, Bouldin, Branch, Brent, Bruce, Cabell, Caperton, Cecil, Chambliss, Chapman, Coffman, Conn, R. Y. Conrad, James H. Cox, Critcher, Custis, Deskins, Dorman, Dulany, Echols, Flournoy, French, Fugate, Garland, Gillespie, Graham, Gregory, Goggin, J. Goode, Jr., T. F. Goode, Hale,
                Addison Hall, Cyrus Hall, Hammond, Harvie, Haymond, Holcombe, Hunton, Marmaduke Johnson, Peter C. Johnston, Kent, Lawson. Leake, C. K. Mallory, J. B. Mallory, Marshall, Marr, Marye, Moffett, Montague, Morris, Morton, Neblett, Nelson, Orrick, Parks, Price, Pugh, Richardson, R. E. Scott, Wm. C. Scott, Seawell, Sheffey, Slaughter, Speed, Staples, Strange, Sutherlin, Tayloe, Thornton,
                Tredway, Waller, Wickham, Williams, Wilson, Wise-85.
              </p>
              <p>On motion of <span className="persName">Mr. STAPLES</span>, the Committee then rose and the <PageNumber num={453} /> President having resumed the Chair, the Chairman [<span className="persName">Mr. MONTAGUE</span>] reported progress.</p>
              <p>On motion, the Convention then adjourned, at 20 minutes past 6 o'clock, till 10, A. M., to-morrow.</p>
            </div>
          </div>
          <div className="day" id="vsc1965.v3.2.8">
            <PageNumber num={454} />
            <h2><span className="headingNumber">1.8. </span><span className="head">FORTY-EIGHTH DAY</span></h2>
            <div className="dateline">Wednesday, <span className="date">April 10</span></div>
            <p>Prayer by Rev. Mr. Bosserman, of the Universalist Church.</p>
            <div className="section" id="vsc1965.v3.2.8.1">
              <h3><span className="headingNumber">1.8.1. </span><span className="head">PROCEEDINGS OF A PUBLIC MEETING IN KING AND QUEEN</span></h3>
              <p><span className="persName">Mr. RICHARD H. COX</span>, presented the following proceedings of a public meeting in King and Queen, which were referred to the Committee on Federal Relations.</p>
              <div className="floatingText">
                <div className="floatingText_body">
                  <p>At a public meeting of the people of King and Queen, held at the Court-house, on the 4th of April, Archibald Pointer was called to the Chair, and E. B. Montague appointed Secretary.</p>
                  <p>On motion of Alexander Dudley, the following preamble and resolutions were unanimously adopted :</p>
                  <p>
                    Whereas, we feel assured that the preservation of our cherished institution of black labor demands the immediate secession of this Commonwealth from her present connection, and her union with the Confederated States of the South, and have seen, with deep mortification, the hesitating and dilatory course pursued by a majority of the State Convention, now in session in the city of
                    Richmond; therefore,
                  </p>
                  <p>
                    Resolved, 1st. That we are opposed to any Convention of the Border Slave States, because we regard it as a move looking to delay only. We are still more opposed to any further efforts being made to obtain concessions from, or compromises with, the white labor States of the late United States; and, since our Convention is unable to decide what shall be our future destiny, we
                    invite them to submit to the people the question, whether we shall remain in union with the cold, calculating, ism-worshipping, money-loving, unfriendly North, or shall we cast our lot with the noble sons of the South, numbers of whom sprung from Virginia sires, and whose interests are, in most material respects, identical with our own.
                  </p>
                  <p>Resolved, 2d. That we do not intend the foregoing resolutions as an instruction to our true and faithful representative, Dr. R. H. Cox ; on the contrary, we return him our earnest thanks for the straightforward, manly course which he has pursued.</p>
                  <p>Resolved, 3d. That a copy of these resolutions be forwarded to the Richmond papers for publication, and our delegate be requested to lay the same before the Convention.</p>
                  <p>On motion, the meeting adjourned.</p>
                  <p>ARCHIBALD POINTER, Ch'mn.</p>
                  <p>E. B. MONTAGUE, Sec'y.</p>
                </div>
              </div>
            </div>
            <div className="section" id="vsc1965.v3.2.8.2">
              <PageNumber num={455} />
              <h3><span className="headingNumber">1.8.2. </span><span className="head">TAXATION QUESTION</span></h3>
              <div className="speaker" id="sp2896"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The resolutions of the gentleman from Monongalia [<span className="persName">Mr. WILLEY</span>] , with the amendments attached thereto, are now in order.</p>
              <div className="speaker" id="sp2897"><span className="persName">Mr. WILLEY</span>—</div>
              <p className="p-in-sp">The amendment of the gentleman from Gloucester [<span className="persName">Mr. SEAWELL</span>] , it seems to me, is vague in its terms, and if adopted would amount to nothing. It is an amendment to an amendment offered by the gentleman from Jackson [<span className="persName">Mr. TURNER</span>] . The amendment offered by the gentleman from Jackson [<span className="persName">Mr. TURNER</span>]
                , is evidently incomplete on its face. By its own terms it has reference only to the 23d section of Article four of the Constitution, and if adopted would certainly fail of accomplishing the purpose which the mover of it had in view, no doubt. The 22d section provides "that taxation shall be equal and uniform throughout the Commonwealth, and all property other than slaves, shall be
                taxed in proportion to its value."
              </p>
              <p className="p-in-sp">Now, the amendment offered by the gentleman from Jackson [<span className="persName">Mr. TURNER</span>]
                , has reference only to the 23d section, and if adopted would leave the inhibition which I have referred to in the 22d wholly untouched. Therefore, as between the competing propositions, I, of course, would decidedly prefer the adoption of the amendment to the amendment; but that in its terms is, as I have already remarked, very vague, and if adopted would amount to nothing. It
                provides simply that a Committee shall be appointed to inquire into the expediency of certain objects not very well defined. Now, the original proposition asks the sense of the Convention upon the principle itself, and then if adopted, that a Committee shall be appointed at once to provide an amendment of these two sections in Article four of the Constitution, which shall conform to
                the organic law, and to the principle enunciated in the first resolution which I offered.
              </p>
              <p className="p-in-sp">
                Mr. President, this matter has been before the Convention for nearly a month, and has been discussed on both sides. Gentlemen have had ample time to reflect upon it, and I do trust it will be the pleasure of the Convention to vote down these amendments at once, and give us the sense of the Convention upon the principle itself, and then give us a Committee to conform the Constitution
                according to the principle. I shall not re-open the discussion at all, but I have deemed this explanation necessary for a fair understanding of the propositions as they now stand before the Convention.
              </p>
              <div className="speaker" id="sp2898"><span className="persName">Mr. SEAWELL</span>—</div>
              <p className="p-in-sp">I had the pleasure to move that amendment to the resolutions presented by the gentleman from Monongalia. I think it proper that I should state, by way of explanation, and in reply to the <PageNumber num={456} /> remarks which have fallen from the gentleman [<span className="persName">Mr. WILLEY</span>]
                , that I believe the proper course for this Convention to take is to make an inquiry as to the propriety of this movement. I am not very well versed in the proceedings of legislative and deliberative bodies; but I am under the impression that upon a question of primary importance, such as this is, it is eminently proper, and is the usual practice, to refer the subject to a Committee
                to enquire into it, in the first instance. The report which that Committee would make does not necessarily bind the action of this body; but it is necessary that the subject should be more fully investigated in all its bearings by a Committee than it can possibly be before the entire body itself. It is proper that an inquiry should first be made.
              </p>
              <p className="p-in-sp">
                The gentleman says that the subject has been fully discussed. Discussed by whom? It has been very fully and very ably discussed by the gentleman and his friends who are in favor of establishing the principle upon which he asks that the vote shall now be taken ; but within my hearing it has not been very fully discussed on the other side. Not one that I recollect of—and my
                recollection is confirmed by the gentleman near me—has discussed the subject to any extent on the other side. I suppose one reason why other gentlemen have not discussed the subject was that they were actuated by the same motive which has influenced me, namely, that I did not desire at this time to discuss the merits of the question, but wished first to have a report upon it by an
                investigating Committee, and when the subject should have been thus fully laid before this body, then to have an opportunity of discussing the merits of the question according to the report of the Committee.
              </p>
              <p className="p-in-sp">
                With regard to the objection to the first amendment, that it does not cover all the ground, I do not think, with all deference to the gentleman from Monongalia, that that objection applies to the second amendment, because that does expressly demand an enquiry into the twenty-second and twenty-third sections. I will not now go into a discussion of the subject; but I will simply
                reiterate that it seems eminently proper that the question should go through the ordeal of a Committee of enquiry, and in that manner come before this body for discussion and settlement. My amendment, I believe, embraces everything that is included in the original proposition.
              </p>
              <div className="speaker" id="sp2899"><span className="persName">Mr. BROWN</span>—</div>
              <p className="p-in-sp">
                I fear, sir, that the amendment to the amendment will only prevent a fair expression of the opinion of the Convention upon the subject before it. I am unwilling to dispose of it in that way. My people are very unwilling to have it postponed in that way, and <PageNumber num={457} /> I trust it will be the pleasure of the Convention to meet the question
                raised in the original resolution. If the sentiment of this Convention should be against the questions there raised, why vote them down and let us know that such is the case. I think that is the fair and manly course to pursue. It is the course I have always pursued myself ; and I trust the Convention will take some decisive course at once, so that we may understand what the
                sentiment is. Then we will cease our struggles and know our fate. But to get rid of the question by indirection is not the course, in my opinion, which this Convention should pursue.
              </p>
              <div className="speaker" id="sp2900"><span className="persName">Mr. CHAMBLISS</span>—</div>
              <p className="p-in-sp">
                -Mr. President, it is well known that the constituency whom I have the honor to represent upon this floor are as deeply interested in the question of the taxation of slaves as any other constituency represented here. At the same time that they are as deeply interested in this question, it certainly was not their expectation that at the meeting of this Convention the question would be
                discussed. I wish to say to my friends from the West, that when the proper time comes for the discussion of this question of taxation, the slaveholders of Eastern Virginia will be ready to deal fairly and equitably and justly with it. While they own a vast amount of this property, they are generous, and are always willing to contribute to the public treasury whatever is right and
                proper.
              </p>
              <p className="p-in-sp">
                Therefore, I would request the gentlemen to postpone, for the present, the discussion of this interesting question. I think I may answer for the slaveholders of Eastern Virginia, and say, that we will meet them at the proper time, in a proper spirit; and if the present taxation of slaves is wrong, if it is necessary not only to tax the productions of the slaves, but also to tax them
                as property and not as a laboring portion of Virginia, if you settle that that is right as a principle of taxation, we will meet you on that issue fairly and justly. But I beg gentlemen of the West to let us settle this all-absorbing question in which we have been engaged, first. Let us settle all these agitating questions which are now disturbing the country from one end of the
                Union to the other. Let Virginia first settle that, and when she has found what is to become of her, and where she is to go, all portions of the Commonwealth will be ready to contribute whatever may be necessary to the public treasury for her protection and defence. I think I have some right to address myself to Western gentlemen upon this subject. Never, anywhere, or upon any
                occasion, either publicly or privately, have I ever attributed to those people any other feeling than that of just, true, and proper loyalty to <PageNumber num={458} /> Virginia, and to all her institutions. I never intend to impugn the motives of any people. If a gentleman tells me that he is a Virginian, prima facie, his heart is in the right place.
                He must give me evidence of his want of loyalty strong and conclusive, before I will entertain or harbor an unkind thought towards him.
              </p>
              <p className="p-in-sp">
                Let me then, gentlemen from the Western part of Virginia, entreat you, to let this question sleep for the present. I do not think you are guilty of any violation of plighted faith, for introducing it. Whatever might have been the inference of Eastern gentlemen in the last Convention, having been a member of it myself, I can say that no man believed that he considered himself obliged
                not to disturb the question whenever he had an opportunity of doing so. If we shall re-assemble here—as it seems to be considered on all hands that we will—in October or some time during the Fall of the present year, if we have settled this subject, which is of so much greater interest than that of taxation, then we will meet you upon the question of taxation in all fairness.
              </p>
              <p className="p-in-sp">I am informed that in my absence a test vote was taken in this house upon laying these resolutions on the table. If it is the determination of Western gentlemen to have this investigation initiated, then the amendment of my friend from Gloucester [<span className="persName">Mr. SEAWELL</span>]
                , should prevail. We are not disposed now to take any final action. Let the committee enquire into the subject as suggested in the amendment of my friend from Gloucester. Let a committee be appointed to enquire whether any change be necessary at all on the subject of taxation of slaves; to investigate whether the principle is right or not; because if this principle is to be
                established that all slaves under twelve years of age are to be taxed ; if the principle which has been indicated by one of the gentlemen on this subject is the true principle ; that you will value the slaves; you will deduct the expense of their maintenance; you will collect the interest upon the value and deduct the expense from the amount of the interest upon the value and then
                tax the remainder. If you make the estimate in that way, I should not be surprised that you will collect less revenue upon slaves than you do now. I am not going to discuss this question at this time. It is one which, when it does come up for discussion, representing that large slave interest which I do represent, I shall feel myself compelled to discuss it in all its bearings. But
                for the present I again ask that it may be postponed until these mightier and overwhelming questions have been disposed of to the satisfaction of this Convention, and I hope to the satisfaction of the people of Virginia.
              </p>
              <PageNumber num={459} />
              <div className="speaker" id="sp2901"><span className="persName">Mr. HAYMOND</span>—</div>
              <p className="p-in-sp">
                Mr. President, I do not rise to discuss the merits of the question now before the Convention. I have already discussed the merits of the question to some extent, as far as I thought it necessary in the form in which it is at present before this body. I wish to say, Mr. President, on this occasion, that, so far as I am concerned as an individual, as an humble member of this
                Convention, and as a citizen of this Commonwealth, I shall make no further declaration of loyalty to Virginia. I have done so heretofore. I have done it in good faith; I have expressed the honest feelings of my heart. But I desire gentlemen to understand that Virginia is composed of more than one part or more than one section; and that when gentlemen from the Western portion of this
                State are expected to be loyal to the interest of Eastern Virginia, it is reasonable and just to expect that Eastern citizens will be just and true to them. I desire to have it understood by this Convention, and from this Convention, whether, or not we are to be denied this principle of justice which we are demanding? It is immaterial to me whether that principle of justice is to be
                acknowledged to day, this week or this month; I do not desire to encumber the important question of Federal Relations any more than I conceive justice demands; but I merely wish an expression upon the part of this Convention now, whether they intend to give us this relief. I tell gentlemen who entertain the opinion of my friend from Greenesville
                [<span className="persName">Mr. CHAMBLISS</span>] , upon the subject of our Federal relations, that Virginia ought to be prepared to settle that question upon the principle upon which he asks it to be settled, until this question is first settled. I rose, sir, for the purpose of demanding the previous question, and I now make that demand.</p>
              <div className="speaker" id="sp2902"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The pending question is on the amendment of the gentleman from Gloucester to the amendment offered by the gentleman from Jackson, and upon this the previous question is called.</p>
              <div className="speaker" id="sp2903"><span className="persName">Mr. BURDETT</span>—</div>
              <p className="p-in-sp">I demand the yeas and nays upon that question.</p>
              <div className="speaker" id="sp2904"><span className="persName">Mr. TREDWAY</span>—</div>
              <p className="p-in-sp">May I enquire whether, if the previous question is sustained and this amendment is voted down, that will preclude the offering of another amendment?</p>
              <div className="speaker" id="sp2905"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">No, sir.</p>
              <div className="speaker" id="sp2906"><span className="persName">Mr. BURDETT</span>—</div>
              <p className="p-in-sp">I withdraw the motion for the yeas and nays.</p>
              <p>At the request of several members the original resolution and the amendment were reported.</p>
            </div>
            <div className="section" id="vsc1965.v3.2.8.3">
              <h3><span className="headingNumber">1.8.3. </span><span className="head">FEDERAL RELATIONS</span></h3>
              <div className="speaker" id="sp2907"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The hour has arrived for going into Committee of the Whole.</p>
              <PageNumber num={460} />
              <p>The hour of half-past ten having arrived, the Convention, in pursuance of its standing order, resolved itself into Committee of the Whole [<span className="persName">Mr. SOUTHALL</span> in the Chair] , and resumed the consideration of the report of the Committee on Federal Relations, the 12th section as amended being under consideration, as follows :</p>
              <p>
                "The people of Virginia will wait any reasonable time to obtain answers from other States, to these propositions, of amendment, aware of the embarrassments that may produce delay, but they will expect as an indispensable condition, that a pacific policy shall be adopted towards the seceded States, and that no attempt be made to subject them to the Federal authority, nor to reinforce
                the forts now in the possession of the military forces of the United States, nor re-capture the forts, arsenals or other property of the United States, within their limits, nor to exact the payment of imposts upon their commerce, nor any measure resorted to justly calculated to provoke hostile collision."
              </p>
              <div className="speaker" id="sp2908"><span className="persName">Mr. ARMSTRONG</span>—</div>
              <p className="p-in-sp">
                I move to amend the 12th section in the 8th line, by inserting after the words "United States" the words "within the jurisdiction of the seceded States."<span className="note" id="Note48"><span className="noteLabel">1</span>This wording does not agree with that of the amended clause quoted below, nor with the Journal, which reads "within their jurisdiction."</span>
              </p>
              <p className="p-in-sp">The clause as amended would read :</p>
              <p className="p-in-sp">"Nor to reinforce the forts now in the possession of the military forces of the United States, within the limits of the seceded States."</p>
              <div className="speaker" id="sp2909"><span className="persName">Mr. SCOTT</span>, of Fauquier—</div>
              <p className="p-in-sp">I would suggest to the gentleman over the way, that in the 9th line of the section, he will find the words, "within their limits." It seems to me, therefore, that the amendment is unnecessary.</p>
              <div className="speaker" id="sp2910"><span className="persName">Mr. ARMSTRONG</span>—</div>
              <p className="p-in-sp">The gentleman will observe that I mean by my amendment, not to include the forts at Tortugas and Barrancas, which, according to my understanding, I do not regard as attaching to the seceded States.</p>
              <p>The amendment was not agreed to.</p>
              <div className="speaker" id="sp2911"><span className="persName">Mr. CAMPBELL</span>—</div>
              <p className="p-in-sp">
                I move to strike out the word "nor," in the 8th line of the section. The amendment, as I understand it, will leave the phraseology of the section perfectly intelligible. These words, "within their limits," I think, properly apply to all the clause preceding, which now reads : "nor to reinforce the forts now in possession of the military forces of the United States, nor to re-capture
                the forts, arsenals, or other property of the United States, within their limits." I think the amendment should be adopted in order to make the language express clearly and distinctly what the Committee intends to express.
              </p>
              <PageNumber num={461} />
              <p>The amendment was agreed to.</p>
              <div className="speaker" id="sp2912"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">I had determined that I would not trouble the Committee any further, with any efforts to amend this report. But, sir, I will make one more attempt, and then I think I shall subside. [Laughter.]
                I arise, Mr. Chairman, for the purpose of ascertaining, if I can, what is the intention of this body; whether it be to give aid and comfort to secession, and to commit Virginia, ultimately, to secession, or whether it be the intention of this Committee to use all the power Virginia may possess, in the Union, to preserve the Union, and to restore peace to every part of it. I move to
                strike out all that part of the section after the word "States," in the fifth line, as follows :
              </p>
              <p className="p-in-sp">
                "And that no attempt be made to subject them to the Federal authority nor to re-inforce the forts now in the possession of the military forces of the United States nor re-capture the forts, arsenals or other property of the United States within their limits, nor to exact the payment of imposts upon their commerce ; nor any measure resorted to justly calculated to provoke hostile
                collision."
              </p>
              <p className="p-in-sp">
                What will then remain of the section, will declare that Virginia's purpose is that a pacific policy shall be pursued towards the seceded States. Now, sir, I cannot believe until I see it, that this committee, after what has been said here, particularly in relation to those forts upon the Gulf coasts, which are known to every member here as being designed especially to protect the
                commerce and trade of the whole country, Virginia included—I cannot believe that it is the intention of this Committee to abandon those forts so necessary to that protection. It will not do for members of this Committee to say that they are not of the opinion that Tortugas, for instance, is situated within the jurisdiction of Florida. If it is not within the jurisdiction of Florida,
                tell me what State it is in? Is it beyond all State boundary, and beyond all territory boundary? It is, in my humble judgment, as much the territory of Florida as any inch of her soil. But, sir, you will, by retaining this provision as it is in this report, declare it to be your deliberate purpose to force the Federal Government, if it be within your power, to abandon all effort to
                protect your commerce, and all the commerce of the world, in that quarter. If we have deliberately come to that conclusion, sir, there is but one thing left for us to do. What is the Government worth, if you so weaken its arm that it is unable to afford to you the protection necessary to make it what a Government should be? If you have come to that conclusion, I say there is but one
                step further for you to take, and that is to unite <PageNumber num={462} /> our fortunes with the seceded States. If you are prepared to do that, then vote to retain the provision which I have moved to strike out. But if you are not prepared to give up this Union, as was beautifully expressed in the prayer to which we listened this morning, "around
                which cluster so many glorious memories," then strike out this clause from the section. I call for the yeas and nays upon the motion to strike out.
              </p>
              <p>
                The yeas and nays were ordered, the question was taken, and it was decided in the negative, yeas 17, nays 104, as follows :<span className="note" id="Note49"><span className="noteLabel">2</span>The Journal also indicates that there were 104 negative votes, although it lists the 106 names given here.</span>
              </p>
              <p>YEAS-Messrs. Brown, Burdett, Burley, Carlile, Carter, C. B. Conrad, Dent, Early, Hubbard, Hughes, Lewis, Moore, Patrick, Porter, Sharp, C. J. Stuart, Tarr-17.</p>
              <p>NAYS-Messrs. Janney [President]
                , Ambler, Armstrong, Aston, Baldwin, J. Barbour, Baylor, Berlin, Blakey, Blow, Boisseau, Borst, Bouldin, Branch, Brent, Bruce, Byrne, Cabell, Campbell, Caperton, Cecil, Chambliss, Chapman, Coffman, Conn, R. Y. Conrad, Couch, R. H. Cox, Critcher, Custis, Deskins, Dorman, Dulany, Echols, Flournoy, French, Fugate, Garland, Gillespie, Graham, Gravely, Gray, Gregory, Goggin, J. Goode,
                Jr., T. F. Goode, Hale, Addison Hall, C. Hall, L. S. Hall, Hammond, Haymond, Hoge, Holcombe, Hunton. Isbell, Jackson, Marmaduke Johnson, P. C. Johnston, Kent, Kilby, Lawson, Leake, McComas, McGrew, Macfarland, C. K. Mallory, J. B. Mallory, Marshall, Marr, Marye, Sr., Moffett, Morris, Morton, Neblett, Nelson, Orrick, Osburn, Parks, Pendleton, Price, Pugh, Richardson, Rives, R. E.
                Scott, Seawell, Sheffey. Sitlington, Slaughter, Southall, Speed, Spurlock, Staples, Strange, Summers, Sutherlin, Thornton, Tredway, Waller, White, Wickham, Willey, Williams, Wilson, Wise, Wysor-104.
              </p>
              <p>So the amendment was disagreed to.</p>
              <div className="speaker" id="sp2913">During the call of the roll, <span className="persName">Mr. NELSON</span>, when his name was called. said :</div>
              <p className="p-in-sp">
                Mr. Chairman, as you are aware I have paired off with the gentleman from Barbour, "on the report on Federal Relations," when I can do so conscientiously, though I have the privilege of voting with the gentleman from Middlesex, and I waited to hear him vote; as I did not hear him, I presume I will be following in the line of "safe precedents," if I vote with my friend from Louisa, I
                vote no.
              </p>
              <div className="speaker" id="sp2914"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I move further to amend the section, by adding the following words :</p>
              <p className="p-in-sp">"And the forts, arsenals, magazines and other places ceded to the United States within the limits of the seceded States ought to be evacuated by the authorities of the Federal Government."</p>
              <p className="p-in-sp">
                I merely wish to say that that involves the whole point under discussion. If we allow the Federal authorities to hold on or entertain <PageNumber num={463} /> the opinion that they have the right to hold on, or that it is our duty to say nothing about the right of the Federal Government to hold on to these forts, it leaves us at once exposed to all the
                hazards of war; I am afraid, I may say, to all the certainties of war; for I suppose there is no doubt in the mind of any member of this committee that the Confederate States, if these forts are not evacuated, will take them, whenever they have the means. That will bring on the war which is now the rumor of the hour.
              </p>
              <p className="p-in-sp">I submit to gentlemen whether it is not better, for the sake of peace, to evacuate these places at once and let Congress decide upon the question afterwards.</p>
              <div className="speaker" id="sp2915"><span className="persName">Mr. ROBERT E. SCOTT</span>—</div>
              <p className="p-in-sp">Will the gentleman permit me to read the fifth resolution which has been adopted?</p>
              <p className="p-in-sp">
                "The sites of the federal forts, arsenals, &amp;c., within the limits of the States of this Union, were acquired by the Federal Government, and jurisdiction over them ceded by the States, as trusts, for the common purposes of the Union, during its continuance; and upon the separation of the States, such jurisdiction reverts of right to the States, respectively, by which the
                jurisdiction was ceded. Whilst a State remains in the Union, the legitimate use of such forts, &amp;c., is to protect the country against foreign force, and to aid in suppressing domestic insurrection. To use, or prepare them to be used to intimidate a State, or constrain its free action, is a perversion of the purposes for which they were obtained; they were not intended to be used
                against the States, in whose limits they are found, in the event of civil war. In a time of profound peace with foreign nations, and when no symptoms of domestic insurrection appear—but whilst irritating questions of the deepest importance are pending between the States—to accumulate within the limits of a State, interested in such questions, an unusual amount of troops and munitions
                of war, not required for any legitimate purpose, is unwise, impolitic and offensive."
              </p>
              <p className="p-in-sp">It seems to me that that accomplishes the gentleman's object.</p>
              <div className="speaker" id="sp2916"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                Instead of accomplishing it, sir, it only renders my amendment necessary. That resolution declares that the forts in the seceded States, revert of right, to the States, respectively, by which the jurisdiction was ceded. After making that declaration, I call upon you to assert its sequence, that they ought to be evacuated. But, instead of that, we had the opinion uttered yesterday by
                the Chairman of the Committee on Federal Relations, that the General Government ought to hold on to some of those forts. Now, I submit, whether it is consistent to say, by the fifth resolution, that after the withdrawal <PageNumber num={464} /> of a State, its forts revert to the State, and then to say that you will not demand evacuation, but, that on
                the contrary, the General Government ought to hold on to them.
              </p>
              <p className="p-in-sp">
                I think, therefore, that the fifth resolution, instead of satisfying our minds, calls for my resolution. I was very well aware that this Committee, when it adopted the fifth resolution, did not exactly see to what they were tending. They were, however, tending to the end which I desired to accomplish. I hope the Committee will adhere to the fifth resolution, and carry it out by
                avowing in a manly spirit, the really pacific policy of evacuation—not yielding the right, but simply evacuating for the sake of peace.
              </p>
              <div className="speaker" id="sp2917"><span className="persName">Mr. SUMMERS</span>—</div>
              <p className="p-in-sp">
                I regard the amendment proposed by the gentleman from Princess Anne, as one of very great significance and importance—possibly of immediate, practical importance. It touches the evacuation of the Forts belonging to the United States within the limits of the seceded States. If the gentleman would so frame his amendment as to restrict it to the local Forts, I should be very much
                gratified, because, in my mind, it would receive a support characterized by almost entire unanimity. I, for one, am clear that, bound as we are to regard the seven States that have passed ordinances of secession, as having left the Union, we must treat them according to the exigencies which their action has brought about. I am free to say so far as I am concerned, that the policy
                which should be pursued by the Federal authorities in regard to these Forts, is that those which are only necessary for local defence should be evacuated. I am very sure there would be a general expression of opinion favorable to that proposition. The difficulty in my mind, is this: The amendment proposed by the gentleman from Princess Anne embraces all forts, magazines, arsenals and
                other public property, ceded by those States, wherever situated and whether necessary for the protection of those States or not. Fort Sumter, for instance, is necessary for the defence of Charleston, and is no longer necessary for purposes of national defence. Fort Pickens commands the approaches to Pensacola and the Navy Yard.
              </p>
              <p className="p-in-sp">I am ready to join with the gentleman from Princess Anne, most heartily, as I have been throughout, in recommending the evacuation of these forts.</p>
              <p className="p-in-sp">
                In relation, however, to the fortifications to which the gentleman from Princess Anne has addressed himself, and to which he has called the attention of the Committee so frequently—I mean the Tortugas and Key West—I am not so well satisfied. I am prone to coincide <PageNumber num={465} /> in opinion with the Chairman of the Committee on Federal
                Relations
                [<span className="persName">Mr. CONRAD</span>]
                , that these are points which need not be, and are not, necessarily embraced within the purview of policy which should be announced. I am not certain whether these fortifications, or either of them, had their commencement prior to the erection of Florida into a State. Perhaps the gentleman from Princess Anne can inform us. I presume that the Dry Tortugas, on which Fort Jefferson is
                situated, was acquired by the treaty of cession with Spain.
              </p>
              <div className="speaker" id="sp2918"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">They were all acquired under the treaty of St. Ildefonso.</p>
              <div className="speaker" id="sp2919"><span className="persName">Mr. SUMMERS</span>—</div>
              <p className="p-in-sp">
                Whether those fortifications were commenced by the United States Government previous to the erection of Florida into a State, or whether these points were reserved to the United States, or afterwards ceded by Florida to the General Government, I have not been able to ascertain. We know that the Dry Tortugas, on which Fort Jefferson is situated, is out at sea, some hundred miles
                distant from the mainland of Florida.
              </p>
              <p className="p-in-sp">
                Key West, on which Fort Taylor is situated where you have an Admiralty Court, a marine hospital and a complete wrecking organization, is some 30 or 40 miles distant from the mainland of Florida. These points have been always regarded not as belonging or pertaining to Florida particularly, but as points of national importance connected with our Gulf commerce, connected with the
                suppression of piracy, connected with the dangerous navigation on the Florida coast. In any state of things these points may remain necessary to national commerce. Fort Jefferson, on the Tortugas island, commands not only the trade and commerce floating between that island and Florida, but also all the commerce passing on the other side between that island and Cuba. If you look on
                the map you will see that Tortugas island has relations not confined to Florida, not to the States that have become confederate, but are of essential importance to our national commerce, essential to us, essential to Maryland, essential to all the States remaining within the Union.
              </p>
              <p className="p-in-sp">
                Now, Mr. Chairman, while I am not willing to express any opinion adverse to the withdrawal of troops from those points I am not, on the other hand, prepared to say, in the language of the amendment, that the withdrawal of troops from Key West and Tortugas should be made an indispensable condition of our waiting till those amendments proposed to the Constitution be acted upon. I am
                not prepared to go that length. I make a distinction between points of national defence and those of local character. I am for giving up at once to the Confederate States all those points necessary to their local interests.
              </p>
              <PageNumber num={466} />
              <p className="p-in-sp">I would yield them. They ought to be given up. But, in regard to those other points, I think we should not now be called upon to declare that the withdrawal of troops from them should be made an indispensable condition of our effort here for reconciliation and reconstruction.</p>
              <p className="p-in-sp">
                It will be. found, Mr. Chairman, that whatever appropriations have been asked for any of these points, either Key West or Tortugas, by the Senators or Representatives from Florida, the demand has always been made on the ground of their national importance. They have never been asked as Florida improvements; they have been uniformly asked and advocated on the ground that those works
                were of national importance, for the protection of the commerce of the whole country.
              </p>
              <p className="p-in-sp">That, Mr. Chairman, was the reason why I voted for the amendment submitted by the gentleman from Loudoun [<span className="persName">Mr. CARTER</span>]
                . It left in the resolution a full expression of denunciation on our part, of the retention of troops within those States, or of any purpose of subjugating them. Not a sentiment has been uttered here, in regard to a policy of this kind, in which I do not concur. But certainly there is a distinction between the two characters of forts adverted to. I understood the gentleman from
                Princess Anne as stating that the Tortugas and Key West were both within the limits of the State of Florida. If they are, then they fall within the purview of the fifth resolution; and the withdrawal of troops from Fort Jefferson and Fort Taylor, the abolition of the Court of Admiralty, and the naval depot and coaling station at Key West, which is a great naval rendezvous necessary
                for our Gulf commerce, would be made by the resolution an absolutely essential condition to our waiting at all, just as much as the withdrawal from Sumter and Pickens. I am not now prepared to say that.
              </p>
              <div className="speaker" id="sp2920"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                If the distinction which the gentleman from Kanawha takes were not a distinction without a difference, I might at once agree to his exception to the rule which I proposed. But the distinction which he makes is a factitious one. I never before heard of local forts as contra distinguished from other forts. In one sense, and the same sense, they are all local; and that is the only sense
                in which any can be called local.
              </p>
              <div className="speaker" id="sp2921"><span className="persName">Mr. SUMMERS</span>—</div>
              <p className="p-in-sp">I called them local forts in contradistinction to national ones.</p>
              <div className="speaker" id="sp2922"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                I know the gentleman has made that distinction. But it does not exist in the constitution of the United States. It does not exist in the laws of the United States. It does not exist in the laws of nations. The places ceded for forts and arsenals are all put, by the <PageNumber num={467} /> constitution of the United States, on precisely the same
                footing. "To exercise exclusive legislation in all cases whatsoever over such district not exceeding ten miles square as may, by cession of particular States and the acceptance of Congress, become the seat of Government of the United States, and to exercise like authority over all places purchased by the consent of the Legislature of the State in which the same shall be for the
                erection of forts, magazines, arsenals, dock-yards, and other needful buildings." The constitution of the United States makes all these forts, arsenals, magazines and dock yards national.
              </p>
              <p className="p-in-sp">
                There is no such thing in the contemplation of the Constitution of the United States, as a difference between one fort and another. They are all purchased and ceded for national purposes, for Federal uses. There is no such thing as a local fort as contra-distinguished from a national fort. Then the question is whether there is any distinction between Sumter and Pickens on the one
                hand, and Key West and the Tortugas on the other. Does any one here need to be sent to a primary school to learn from Mitchell's Geography, that Key West is in Florida and that the Tortugas is in Florida? I am just told by a friend that Senator Mallory, of Florida, lives at Key West; that it is in the county of Monroe in the State of Florida. Now, will the gentleman say that the fort
                there is more national than Fort Pickens?
              </p>
              <p className="p-in-sp">There is no such difference between the national and the local forts, and I address myself to him as a lawyer to ask what matters it whether the United States had occupied the sites for the forts before Florida was a State, or whether Florida became a State first and ceded it to the United States afterwards?</p>
              <p className="p-in-sp">
                I call the attention of the gentleman to the fact that they are all placed exactly upon the same footing; whether they be held by reservation or by cession, they are under Federal jurisdiction, simply for national purposes. Will he please tell me, how commerce, going into Charleston, is not as national as commerce entering the Gulf? Here comes a ship from abroad—from Liverpool,
                suppose, or from Havre, under the French or English flag she is bound to Charleston is it not just as much national commerce as if she sailed around the reefs of Florida, bound for New Orleans? Now, will the gentleman show me the difference, after this ship enters the Gulf and passes the Tortugas, and when she enters the bay where Fort Pickens is? I ask the gentleman to say whether
                this ship, after she enters the Tortugas, is to be regarded as engaged in national commerce, and the same ship entering at the bay where Fort Pickens is located, is to be regarded <PageNumber num={468} /> as engaged in local commerce? Does not the gentleman see the absurdity to which he reduces himself in the identical case? Why is it that he will not
                evacuate Tortugas? Because, he says, the commerce of the Gulf is national commerce? Is not the same commerce entering Mobile harbor or the mouth of the Mississippi as much national commerce as when passing the Tortugas?
              </p>
              <p className="p-in-sp">
                Does he call this commerce which passes within Mobile harbor, local; and that passing within the Tortugas, national? Certainly, this logic and these views will not endure examination. All your forts are national; and if, upon the ground of nationality, you hold one, you are bound to hold all, upon the same ground. If, upon the ground of its being local—that it is within the limits of
                the State—you evacuate Fort Sumter, for instance, then you are bound, by the same rule, to evacuate all. There is no difference; there is no distinction.
              </p>
              <p className="p-in-sp">
                I never heard of any Island in the Gulf of Mexico<span className="note" id="Note50"><span className="noteLabel">3</span>A correction (April 18) reads "I never heard of any island on the coast of the Gulf of Mexico, within the marine league," instead of "I never heard of any island in the Gulf of Mexico."</span> that did not belong either to Florida, Alabama, Mississippi, Louisiana, or Texas,
                as far as the coast of the United States is concerned. I have examined, with a great deal of particularity, these treaties, with a view to trace back. I have examined with great care the treaty of St. Ildefonso in regard to the boundary line of Florida; and I find that this boundary embraces all East Florida—every foot of ground in that part of the territory; that the United States
                acquired from Spain. Louisiana, Georgia, Mississippi, Alabama and Florida, together embrace all of the territory which we acquired from France and Spain under two separate treaties with these two powers. When a State is made a State, the reservations of the United States for Forts may exist; but when a State comes in, she comes in exactly as one of the original States, and she has
                not less power over her Forts than the old thirteen States have over theirs. That is embraced in the general recognition of the State and in the admission of the State into the Union. Her rights of jurisdiction are the same. They had forts in Key West before Florida became a State at all. The United States required no cession by the States of these forts. Why? Because they had the
                State already laid off, and when the State was admitted into the Union, it was necessary only in the act of admission, that they should resume the right over them. But the reservations in the case of the admission of new States, are placed, after their admission, precisely on the same footing as if the cession were made when the State is a State, and not a territory. There is no
                manner of distinction, then, in the sense in which he would make Fort Sumter and Fort Pickens local. In the <PageNumber num={469} /> same sense, the Tortugas and Key West are local; and as they are both alike within the jurisdiction of the State, they both alike revert to the State when she withdraws from the Union, precisely as the marine league
                itself, beyond the shores of all our islands, would revert to the State; so that I do not see that my friend is gaining anything by this distinction he makes without a difference ; and, let me tell him, that if he claims that the Tortugas and Key West forts are for national purposes, the Confederate States will claim them for their national purposes; and thus nationality and
                jurisdiction will be brought into conflict with nationality and jurisdiction, and at once you invite a war.
              </p>
              <p className="p-in-sp">One word more, sir. I would say to gentlemen, if you are going to deal amicably and peaceably, do it in a manly, open and sincere way at once; that will be the most graceful form in which you can do it.</p>
              <div className="speaker" id="sp2923"><span className="persName">Mr. BALDWIN</span>, of Augusta—</div>
              <p className="p-in-sp">I propose to amend that amendment by inserting after the word "ought" the words "in the interests of peace."</p>
              <p className="p-in-sp">I understand the gentleman from Princess Anne, in moving his amendment, not to desire to express any opinion in regard to the matter of right; but simply to express an earnest desire for the sake of peace, that this step should be taken, and I concur very heartily in that desire.</p>
              <p className="p-in-sp">
                There are differences of opinion, as we all know, in regard to the question which underlies all these resolutions—the right of secession and the question growing of the attempt to exercise that right. But I suppose, if the question is fairly and distinctly presented to this body, there will be no difference of opinion upon the question whether sound policy, and a just regard to the
                interests of humanity and peace do not require that the Federal Government shall withdraw its troops, which can serve no purpose, so far as I can see, but that of unnecessary irritation.
              </p>
              <p className="p-in-sp">Sir, while upon this subject I will state, that I think the distinction taken by the gentleman from Kanawha [<span className="persName">Mr. SUMMERS</span>]
                is sound and legitimate when regarded in the light of a peace measure. The Tortugas is one hundred miles, as I understand, from Florida. Key West is a considerable distance from Florida, and the holding of these two places, as I have been informed, is regarded as essential to the protection of the commerce of the Gulf. The holding of neither one of them can, so far as I understand,
                be considered a menace towards the seceded States; nor can it be regarded as a step in the direction of subjugating the seceded States to Federal authority. The same may <PageNumber num={470} /> be said, I think, of Fort Sumter in one sense. I suppose the seventy men that are in Fort Sumter are not regarded by the seceded States as subjecting them to
                any danger. I suppose the anxiety to get rid of them is upon the mere point of honor that they regard it as insulting to them to have such a force within the harbor of Charleston.
              </p>
              <p className="p-in-sp">
                Now, sir, I am willing, for the sake of peace, that the concession shall be made to the seceded States, even upon this point of honor; but sir, I think that our neighbors of the Confederate States ought to be careful how they undertake to make war upon the point of honor involved in the mere holding of an almost empty fort, when the fact stands out this day, that the Federal
                Government is exercising jurisdiction and authority in every county in the seceded States—is this day transporting its mails as an act of Federal jurisdiction—claiming the right to do it as part of the powers of the United States—appointing officers, removing officers, employing contractors, and having work done, not under a treaty with these States, but as the exercise of a
                legitimate Constitutional power, on the part of the Federal Government, within the limits of the seceded States, and within the limits of every county in these States. Sir, until the Confederate States feel the point of honor press upon them sufficiently to demand that this jurisdiction, that this authority shall be withdrawn from them as a matter of insult, I think they ought to be
                a little particular how they under take to make the point of order in regard to this miserable handful of soldiers that are occupying Fort Sumter, and who certainly do not threaten any more danger to the people of South Carolina, than the mail-carriers who carry their letters to them.
              </p>
              <div className="speaker" id="sp2924"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">If the gentleman will alter the phraseology of his amendment, and instead of saying, "in the interests of peace," put it "for the purpose of pacification," I will accept it.</p>
              <p><span className="persName">Mr. BALDWIN</span>, as the reporter understood, agreed so to change his amendment.</p>
              <div className="speaker" id="sp2925"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                Now, the gentleman says that there is a difference in regard to the character of these forts, and supports his friend from Kanawha, on the ground that there is a difference between the nationality of one fort and the nationality of the other. I ask both gentlemen, if these forts were not created for national uses, and if all are not alike? If one fort is constructed at Charleston
                harbor, and another at Hampton Roads, why, neither is local to South Carolina, or local to Virginia. Each is built and maintained for the protection of all the commerce of the United States, registered tonnage or licensed tonnage. Your licensed tonnage embraces your coastwise <PageNumber num={471} /> tonnage. Your registered tonnage embraces your
                foreign shipments. Both Fort Sumter and Fort Monroe, as well as those at the Tortugas, are national in their purposes and objects; and let me ask my friend from Augusta
                [<span className="persName">Mr. BALDWIN</span>]
                , to beware and think well upon the doctrine that he is proclaiming. Why would he protect and refuse to evacuate the forts on the Tortugas? He says, because its guns cover more commerce than enters at Charleston. But there happens to be a fort here at the mouth of the Chesapeake that covers all the commerce that enters from abroad into Baltimore—all the licensed tonnage of Virginia
                and of the surrounding States, and that hereafter may be involved precisely in the same point with the fort on the Tortugas. As I said the other day, all the commerce rolling down from the lips of the lakes may claim that this fort is common to them. Any State bordering the Susquehanna river may say that it is common to her. Pennsylvania may say it is common to her, and Maryland may
                claim that it is also common to her, precisely as all the Northwestern States say that the Mississippi is common to them. The same principle is involved. The gentleman from Augusta
                [<span className="persName">Mr. BALDWIN</span>] , should beware lest upon this principle Fort Monroe should be caught in this trap and claimed as a national fort, despite the right of Virginia to hold it, should she secede. And if she shall secede, is there a man in the State, I ask the gentleman from Kanawha [<span className="persName">Mr. SUMMERS</span>] , and the gentleman from Augusta [<span className="persName">Mr. BALDWIN</span>] , after all these attempts at negotiations fail, and the Federal Government shall attempt to hold Fort Monroe after we do secede ; I ask is there a man in Virginia who would not, at the cannon's mouth, at the point of the sabre, at the steel of the bayonet, force the evacuation of that fort?</p>
              <p className="p-in-sp">
                If we would do this in respect to Fortress Monroe, how can you expect Florida will not do it with respect to Tortugas? Gentlemen say that Tortugas is one hundred miles from Florida. I deny it. Florida extends beyond Tortugas. The jurisdiction of Florida extends three miles in the channel towards Cuba. Tortugas is in Florida as much as Fortress Monroe is in Virginia. Take care,
                gentlemen, that you do not lay traps for yourselves. While we are protecting the peace of Florida; while we are protecting the peace of all the United States in respect to the forts in the South, let us remember that we have the strongest fortifications, not only in the country, but anywhere upon the globe. Two of them with the cannon's mouth fronting each other. The Rip-Raps not yet
                finished, no casemates, not yet bomb proof and it cannot be held thirty minutes under the guns of Fortress Monroe. The United States would leave you to finish it, and how would you <PageNumber num={472} /> finish it? With Fortress Monroe in their hands, you could not do it. You cannot hold it twenty minutes by the watch under the columbiads of Fortress
                Monroe, and you could not put up a casemate that would be bomb-proof before they would blow every stone that caps the Rip-Raps, down rolling into the gulf of Hampton Roads. And yet gentlemen here are drawing these distinctions that would swallow up Fortress Monroe just as decisively as they could swallow up Tortugas. Gentlemen, you had better settle all these questions at once. I
                agree with my friend from Augusta
                [<span className="persName">Mr. BALDWIN</span>]
                , in decisively expressing the idea that if we obtain this evacuation, it will be no concession, it will be yielding nothing, but simply and generously evacuating the points in dispute, for the sake of peace pro terra pore, or until the war commences, and when that comes, whether we or they claim them or whether we or they occupy them, war will take them by the red right hand, if war
                can take them. Florida cannot now take Tortugas; but the timber grows upon the Tombigbee and grows upon the mighty Mississippi—that can be brought down, not in the form of rafts, but in the form of marine batteries that can take it, and if you hold on to it, they will take it just as a spirited people would take Fortress Monroe, if it costs you ten thousand lives. I know the power of
                the United States. I know the power of the North. I know that there is no contest to go on between the North and the South, like that which was carried on between Great Britain and the United States, when Great Britain had to transport armaments, ordnance and troops for three thousand miles across the Atlantic. I know that the North is now but one-tenth of that distance from the
                capes of Florida. I know that the North now has steam for transportation, but in spite of the diminution of distance, in spite of steam, those forts can be taken, and they will be taken unless they are evacuated.
              </p>
              <div className="speaker" id="sp2926"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">
                This Convention, some days ago, by a vote of two to one, declared the unwillingness of Virginia to withdraw from the Government of the United States—from the present Union. Recognizing myself as being yet a citizen of Virginia, and a citizen of the United States, I am addressing myself, as far as I can, earnestly to the policy which I believe to be the true policy of the Government
                under which we live the policy of maintaining peace with the States that have passed ordinances of secession. I wish to avoid committing this State, in any way whatever, to doubtful points of future controversy, which may be availed of as means of irritation by any party whatever. I wish to assert no doubtful points of jurisdictional right. I wish to assert no point of policy which
                may hereafter embarrass us in our intercourse <PageNumber num={473} /> with our own Government, or embarrass our Government in its intercourse with these seceded States. I have no hesitation at all, in perfect frankness and good faith, to declare that I am in favor of the Government of the United States at once adopting the policy of withdrawing from
                the limits of the seceded States every appearance of armed force which can in any sense be regarded by those people as a menace of subjection by force to the federal authority. As I regard Fort Sumter and Fort Pickens as both occupying such an internal position in respect to the States within whose limits they are found as naturally to be causes of constant irritation to the people
                of those States, in the policy of peace which I advocate, I desire that these forts shall be evacuated. But, sir, believing, as I do, that neither Key West nor the Tortugas occupy any such position or are in any wise calculated to produce upon the minds of any part of the Southern people the idea of intimidation or subjection by force, as a citizen of the United States, I am
                unwilling to recommend to my Government the giving up of property which is necessary for the protection of our commerce in the Gulf. Sir, why should we hand them over to those seceded States? If they were to get them, they would be pretty much in the condition of the man who drew the elephant in a raffle—they would not know what to do with them. They have neither the land nor the
                naval force necessary to maintain them against hostilities by other nations. They would be liable to be seized upon by hostile nations at any moment, if they were entrusted to the custody of these seceded States, without any naval force to sustain them. They not only are not prepared to keep them with any advantage, but they have no need for them, because they have no commerce
                whatever that it is necessary to protect, and no naval force that it is necessary to rendezvous at that place. If they attempt to take them from our government, they do it by going out of their way to pick a quarrel. They do it by going beyond the necessities of their position to force a quarrel upon our people. And, sir, with that movement, with that disposition, I have no sympathy
                whatever. I want our government, as long as it remains our government, to keep itself within the clear line of right. I want it to maintain a pacific policy towards this people. But, sir, I, for one, do not sympathise with any disposition on the part of those people who have set themselves up as a foreign government to force a quarrel on the government, which is, as yet, my
                government, and I do not look forward with any pleasurable anticipations to see the flag, which is our flag, trail before any assault by any hostile force whatever. I want our government to pursue <PageNumber num={474} /> a pacific policy, and I do not want to see it surrender valuable property and valuable interests to any demand which is made for the
                mere purpose of picking a quarrel.
              </p>
              <div className="speaker" id="sp2927"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I accept the amendment offered by the gentleman from Augusta [<span className="persName">Mr. BALDWIN</span>] .</p>
              <p>The yeas and nays were demanded and ordered.</p>
              <p>The question was then taken on the amendment of <span className="persName">Mr. WISE</span>, as amended, and it was rejected—yeas 60, nays 67—as follows :</p>
              <p>
                YEAS-Messrs. Ambler, James Barbour, Blakey, Boisseau, Borst, Bouldin, Branch, Bruce, Cabell, Cecil, Chambliss, Chapman, Coffman, Conn, R. H. Cox, Critcher, Echols, Flournoy, Garland, Graham, Gray, Gregory, Jr., Goggin, J. Goode, Jr., T. F. Goode, Hale, A. Hall, C. Hall, L. S. Hall, Harvie, Holcombe. Hunton, Isbell, Kent, Kilby, Lawson, Leake, C. K. Mallory, J. B. Mallory, Marr,
                Marye, Sr., Moffett, Morris, Morton, Neblett, Parks, Richardson, Seawell, Sheffey, Southall, Speed, Strange, Sutherlin, Thornton, Tredway, Tyler, Waller, Williams, Wise, Wysor-60.
              </p>
              <p>NAYS-Messrs. Janney [President]
                , Armstrong, Aston, Baldwin, A. M. Barbour, Baylor, Berlin, Blow, Jr., Boggess, Boyd, Brent, Brown, Burdett, Burley, Campbell, Caperton, Carlile, Carter, C. B. Conrad, R. Y. Conrad, Couch, Custis, Dent, Deskins, Dorman, Dulany, Early, French, Fugate, Gillespie, Gravely, E. B. Hall, Hammond, Haymond, Hoge, Hubbard, Hughes, Jackson, M. Johnson, P. C. Johnston, Lewis, McComas, McGrew,
                Macfarland, Marshall, Moore, Orrick, Osburn, Patrick, Pendleton, Porter, Price, Pugh, Rives, R. E. Scott, Sharp, Sitlington, Slaughter, Spurlock, Staples, C. J. Stuart, Summers, Tarr, White, Wickham, Willey, Wilson-67.
              </p>
              <div className="speaker" id="sp2928"><span className="persName">Mr. SUMMERS</span>—</div>
              <p className="p-in-sp">
                I propose to strike out after the word "reinforce," in the 7th line, the following words: "The forts now in possession of the military forces of the United States," and insert in lieu thereof these words : "Any forts situated upon the main land or within the borders of any of the seceded States," so that the clause would read : "And that no attempt be made to subject them to the
                Federal authority nor to reinforce any forts situated upon the main land or within the harbors of any of the seceded States," &amp;c.
              </p>
              <p className="p-in-sp">
                Mr. Chairman, I offer that amendment as in accordance with my own views of the declaration that ought to be made. I am entirely satisfied with the language of this resolution when it announces "that a pacific policy shall be adopted towards the seceded States, and that no attempt be made to subject them to the Federal authority, nor to re-capture the forts, arsenals or other property
                of the United States within their limits, nor to exact the payment of imposts upon their commerce; nor any measure resorted to, justly calculated to provoke hostile collision."
              </p>
              <p className="p-in-sp">
                All this will be left, if the Committee shall think fit to strike out the <PageNumber num={475} /> words which I have indicated, and insert in lieu thereof, those words I named in my amendment. My only object is to accomplish and carry out the idea which has already been indicated. If you adopt the resolution as it now is, it is virtually a quasi pledge
                of secession for this Commonwealth, in the event of a re-inforcement of any of the forts claimed already within the jurisdictional limits of the seceded States, or any one of them, by the Government of the United States.
              </p>
              <p className="p-in-sp">
                Now I wish to restrict them to the re-inforcement of forts not upon the main land or within the harbors of the seceded States. As to this latter class of fortifications, I am opposed to any re-inforcement of them, and had hoped, and desired, as we all desired, that they would have been evacuated before now. While I am willing and anxious that that policy should be pursued, I am not
                willing to commit myself to secession; I am not willing that we should break loose from our moorings and drift away, upon the happening of events alluded to in this resolution. If you pass this resolution, and they throw marines into the forts in Key West and the Tortugas, it breaks the condition. It is said that Fort Jefferson, at Tortugas, and Fort Taylor, at Key West, are both
                within the limits of Florida. Be it so. I am not advised—and I had expected that the gentleman from Princess Anne, who is familiar with the subject, would have stated—I am not advised whether the United States Government holds the Tortugas by reservation to itself, in the law creating Florida, or whether they hold it by deed of cession from Florida. That I am not prepared to say.
                But, be it the one way or the other, I am not prepared to avow that the throwing of a handful of troops or marines into these forts is to break the condition now existing between us and the Federal Government. By the amendment, we do not say that these forts should be reinforced; we only decline to say that a reinforcement of them would be a sufficient cause to disturb our effort at
                settlement. I am sure the gentleman from Princess Anne understood me, when I used the terms "local forts," that it was in contra-distinction to national. In one sense, all the forts are national. They were built at the expense of the general government; and so long as those States remain with us, they are forts for national defence. By the amendment which I have sent to the Chair, I
                have indicated what I mean by local in contra-distinction to national. I mean those forts upon the main land, and within the harbors of the seceded States. Now, suppose those troops were withdrawn from Key West and the Tortugas, do you not at once perceive that the whole commerce of the Gulf would be left at the mercy of pirates? We know that the Confederate States have no
                <PageNumber num={476} /> naval force, that they have no military marine, no commercial marine; they have no means to defend the commerce of the Gulf ; and I believe that it is for the interest of the Confederate States, as well as of the States now remaining in the Union, that those forts in the Gulf should, for the present, at all events, be left in
                the possession of the United States, for the reasons which I have assigned. What may be brought about in the future, is another thing: we are speaking and acting for the present.
              </p>
              <p className="p-in-sp">
                I will take occasion to remark, Mr. Chairman, that within the limits of the ten minutes rule, a man hardly knows what to leave unsaid or what to say. I will say, however, that this proposition is not a new one. A short time since, this matter was under discussion in the Senate of the United States. It was introduced by a resolution presented by Mr. Breckinridge, of Kentucky,
                indicating the policy which the Federal Government ought to pursue in relation to the withdrawal of forces from the forts. In the course of his remarks he was enquired of by one of the Senators whether he included within the scope and purpose of his resolution and within the policy he would wish to see pursued, the withdrawal of the troops from those very forts which have been
                referred to here—Fort Jefferson and Fort Taylor. In reply, Mr. Breckinridge reviewed to some extent, the arguments on both sides, and came to the conclusion, and so announced, as unmistakably his opinion, and what he believed to be the opinion of the nation, that the mere continued occupancy by troops of the United States of Fort Jefferson, at Tortugas, and Fort Taylor, at Key West,
                would not be just cause of complaint; and he took the ground that they were absolutely essential to protect the commerce of the Gulf and of the Atlantic.
              </p>
              <p className="p-in-sp">
                And I coincided in that opinion then, as I coincide in it now. While I would not advise that the troops should not be withdrawn from those forts, I am unwilling that the mere fact of their retention should be expressed as a ground of controversy and complaint. Now it seems to me there ought to be no difficulty apprehended by making the exception indicated in the amendment. It is
                clear to my mind that we can justly make the discrimination. I do not mean that the policy which the amendment embodies shall necessarily hold in the future, if those States shall manifest their settled purpose of continued separation ; I only mean to declare that at present, as we are now situated, we ought not to make the peremptory withdrawal of troops from those points the
                condition of our action here.
              </p>
              <p className="p-in-sp">
                I trust it will be the pleasure of the Committee to insert this <PageNumber num={477} /> amendment, and then the resolution will read as it has been reported from the Committee except the discriminating feature as to the fortifications. If there is any other form of words which will be more appropriate, and that will more effectually carry out the idea
                that I have in view, I will cheerfully accept it. I am sure that I will be quite as long as the gentleman from Princess Anne in consenting that Fortress Monroe, which is on the main land, shall be used for any purpose hostile to Virginia.
              </p>
              <div className="speaker" id="sp2929"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                I will give my friend the point at once, and an opportunity to answer the point. If this amendment be adopted, as I understand the proposition of the gentleman, he holds that after the withdrawal of these States, the Federal Government may hold the forts on the main land and in the harbors, which are now in the occupation of the troops of the United States; that they are only not to
                be reinforced. First, that you may hold those forts on the main land and in the harbors, both; and second, you may hold and reinforce the forts on the islands. That is the effect of the amendment.
              </p>
              <div className="speaker" id="sp2930"><span className="persName">Mr. SUMMERS</span>—</div>
              <p className="p-in-sp">I do not regard that as the effect of the amendment at all.</p>
              <div className="speaker" id="sp2931"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Read it, sir; I ask that it be reported.</p>
              <p>The amendment was reported.</p>
              <div className="speaker" id="sp2932"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                The amendment proposes to strike out the words "the forts now in the possession of the military forces of the United States," and when his amendment is adopted it will read "nor to re-inforce any of the forts situated upon the main land, or within the harbors of any of the seceded States." He gives them the power to hold the forts, and only deprives them of the power to re-inforce
                them. The effect is, I repeat, that we may hold the forts on the main land and in the harbors, and that we may hold and re-inforce the forts on the islands. Sir, what will he do in the case of the Rip Raps that is in no harbor, that is on no main land?
              </p>
              <div className="speaker" id="sp2933"><span className="persName">Mr. SCOTT</span>, of Fauquier—</div>
              <p className="p-in-sp">Will the gentleman from Princess Anne, allow me to make a single observation? The resolution embraces only the time we are engaged in attempting a settlement. It does not go beyond that.</p>
              <div className="speaker" id="sp2934"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">The resolution reads:</p>
              <p className="p-in-sp">
                "The people of Virginia will await any reasonable time to obtain answers from the other States, to these propositions, aware of the embarrassments that may produce delay, but they will expect, as an indispensable condition, that a pacific policy shall be adopted towards <PageNumber num={478} /> the seceded States, and that no attempt be made to subject
                them to the Federal authority."
              </p>
              <p className="p-in-sp">
                Now, I ask if it is not subjection to Federal authority to hold these forts and to retain the right to re-inforce them? Is that peace? Is not that subjection to Federal authority, to continue to hold forts now occupied by Federal troops, in the harbors of seceded States and on their main land? and not only to claim the right to hold those forts, but to re-inforce those forts which
                are on the islands? Does not the gentleman's amendment make the latter part of this resolution more inconsistent than it is already with the first part of the resolution? I say to the gentleman who sits before me ,
                [<span className="persName">Mr. SUMMERS</span>]
                , "I do not mean to hurt you; I do not mean to do you any harm; I do not mean to subject you at all to my authority; but I happen to have a rope round both your arms and both your legs ; I don't mean to tie you, but if you struggle, I will reserve the right to wind one or two more turns round both; but yet I do not mean you any harm, I do not mean to hurt you, I do not intend to
                subject you to my authority."
                [Laughter.]
                Sir, is not this a farcical idea of a peace policy? You mean peace, you mean a peace policy, you go further, and say you don't intend to subject them to Federal authority, and yet you take from them the right of eminent domain, of sovereignty, supremacy, the right to occupy their own territory, the right to occupy their own territory with arms, the right to occupy territory that they
                claim for their national uses, as well as you claim yours for your national uses.
              </p>
              <p className="p-in-sp">Now, who does the territory belong to? To which national use does this national territory belong? To the nation that owns the territory, or to the nation that does not own it? Let me state it in another form—to the nation within whose limits the site of the fort is, or to the nation that must at least concede the fact that it is within the limits of the other?</p>
              <p className="p-in-sp">
                The gentleman says he is not informed whether the United States hold the Tortugas by reservation or by cession. I am not able positively to inform him, but I am inclined to think that the forts on Key West and the Tortugas—especially that on Key West—though I am merely guessing now, I do not mean to assert it as a fact—I am inclined to think, as a reasonable conjecture, that Key West
                was one of the old Spanish fortifications, occupied by Spain when she ceded the jurisdiction to us. She ceded the entire jurisdiction to us. And by-the-by, sir, Congress, by a resolution passed in secret session in 1817, appropriated $150,000 for the occupation of that fort, and our government actually <PageNumber num={479} /> did occupy it before the
                treaty of cession was made. The secrecy imposed by Congress was not removed until eight years afterwards, when a subsequent edition of the laws of the United States was published. I could give you a history of that transaction. It was one of the most disgraceful transactions that ever characterized the government of the United States. You talk about filibustering. If you want to get
                an example above all other examples of filibustering, the United States of America, in the Capitol at Washington, by some of the gravest patriots of the land, were guilty of it in the passage of that resolution. It was one of the most arbitrary acts of despotism that was ever practiced by any government, not excepting Turkey. Congress put into the hands of a vice-gerent of the United
                States absolute legislative, executive and judicial power, all in one act. And as soon as the treaty was made with Spain, the treaty of St. Ildefonso, embracing the Tortugas, they had acquired from Spain all that part of the Floridas called East Florida; and, whether the forts are held by reservation or cession, I appeal to my friend to say whether the United States have any more
                authority over forts in new States, than over the forts in Virginia? No, sir.
              </p>
              <div className="speaker" id="sp2935"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The gentleman's time has expired.</p>
              <div className="speaker" id="sp2936"><span className="persName">Mr. SUMMERS</span>—</div>
              <p className="p-in-sp">
                I submit, Mr. Chairman, that the gentleman from Princess Anne has not answered the argument upon which the amendment was advocated by me. I know very well the power that that gentleman has, by an array of facts and his mode of statement, to make an imposing effect upon the judgment of the House. But, Mr. Chairman, I must claim that the ground upon which this amendment is proposed to
                be advocated, has in no manner been touched by anything that has fallen from the gentleman from Princess Anne. He commenced by saying that this amendment makes the resolution to assert that these fortifications within the harbors and on the main land, were to be continued; and further, that it makes the resolution declare that the forts situated beyond the main land, and the harbors
                are to be reinforced. Now, I ask this Committee to say whether any such result can legitimately follow the adoption of this amendment. It seems to me, with all due respect to the gentleman, that he misapprehends not my amendment only ; but the resolution itself. This is not a resolution declarative of what the Government ought to do, and what the Government must do under all
                circumstances. In the preceding section you have announced, among other things that unless the amendments proposed to the Constitution, shall be favorably responded to, Virginia is to resume her rights of sovereignty. Then <PageNumber num={480} /> comes the section which we are now considering, and which declares that Virginia will wait any reasonable
                time to obtain answers from the other States to these propositions of amendments, aware of the embarrassments that may produce delay in connection with their proceedings in relation to the proposed amendments. It then goes on to declare, however, what we shall expect in the meantime, while we are waiting for the non-slaveholding States to act upon these propositions. We are not
                declaring or assuming to declare opinions in the abstract upon these questions, but we are declaring here what we shall expect to be the policy of the General Government during the consideration of these amendments.
              </p>
              <div className="speaker" id="sp2937"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I concur with the gentleman in that, but the question is whether we shall leave the forts in this interval in the hands of the seceded States or not.</p>
              <div className="speaker" id="sp2938"><span className="persName">Mr. SUMMERS</span>—</div>
              <p className="p-in-sp">
                Yes sir, I am now speaking of the statement of the gentleman that my amendment is to affect them while they shall be left in the possession of the United States Government. I want to call his mind to the distinction between the matter attempted to be announced by this amendment, and the matter announced by this resolution. We are not, in either the resolution or the amendment,
                dictating what the policy of the Government ought to be in respect to the removal of the United States forces from the forts. I agree with him that it would be proper to remove the troops from all inland or harbor fortifications, but we are by the resolution under consideration, only announcing what shall be the course of the Federal Government as to re-inforcing these forts
                remaining in its possession during the interval of delay. We say we shall expect the Federal Government not to maintain an offensive policy towards the seceded States. I am as much opposed to any effort for the subjugation of these States as the gentleman is. In the next line we say that there should be no attempt to reinforce any of the forts now in possession of the Government,
                including all your forts in the Atlantic, in the Gulf, every fort that can, by any possibility be considered as attached to any of the seceded States. Now, sir, my amendment does not give any abstract opinion at all, but merely specifies the character of the forts to be affected by the resolution as it stands.
              </p>
              <div className="speaker" id="sp2939"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">And leaves them in the occupation of the Federal authorities on the mainland.</p>
              <div className="speaker" id="sp2940"><span className="persName">Mr. SUMMERS</span>—</div>
              <p className="p-in-sp">
                No more than your original resolution does. All your resolution declares is that none of them shall be reinforced. There is nothing said of evacuation. The difference between us is, that you <PageNumber num={481} /> declare if any attempt is made to reinforce any of the forts you will remain no longer in the Union. I say that any attempt to reinforce
                the forts within the harbors or on the mainland is not to be permitted, but omit to speak as to the forts out at sea, not necessary to the seceded States. I do not alter the language in any otherwise. I only group one class of fortifications; that is all. The whole matter beween the gentleman from Princess Anne and myself is this and I bring him to it; does he mean to say that during
                this period of waiting for responses from the Northern States, during this interval of examination and consideration of these amendments, that he would be willing to make the reinforcement at Tortugas or Key West the cause of breaking up this Government? Does he mean to say that the throwing into these forts, a hundred miles distant from the mainland, at sea, of a small additional
                force, would be sufficient cause for us to break off all negotiations, all efforts for peace, and to disrupt the Government?
              </p>
              <div className="speaker" id="sp2941"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Will the gentleman allow me to answer?</p>
              <div className="speaker" id="sp2942"><span className="persName">Mr. SUMMERS</span>—</div>
              <p className="p-in-sp">
                I have not time, and I was not permitted to answer the gentleman while he was speaking. I ask the gentleman, also, to answer this question : Has he reason to believe that the occupation by the United States troops of Key West and of Fort Jefferson upon the dry Tortugas, is in any manner offensive to the seceded States? Have they made any move, have they even asked the withdrawal of
                the United States troops from these points? The seceded States are in the condition of being without naval forces altogether, and the withdrawal of the United States troops from these points would throw our whole commerce in that quarter at the mercy of the piratical freebooters of the Gulf. It is for the interest of the seceded States, therefore, that no immediate withdrawal should
                be made. The resolution, no more than the amendment, covers more than the period during which our propositions are to be under consideration at the North. I am sure that when the Committee shall consider this amendment, in connexion with the resolution itself, they will find unquestionably that no reasonable objection can be made to it. I go heartily with the gentleman and all who
                are in favor of this resolution, in declaring that we shall expect that no effort will be made to re-capture any of the forts that have been taken by the seceded States. And the resolution declares further that we shall expect no imposts to be levied upon their com merce; and it winds up by declaring that, during this interval, we shall expect no measure will be resorted to, justly
                calculated to provoke hostile collision. That would perhaps be included by some in an attempt to reinforce Tortugas and Key West, but I do not think that we <PageNumber num={482} /> should state that it would be sufficient cause for us to break off our efforts to restore peace, and in my opinion it ought not to be considered as a cause of offence to
                anybody.
              </p>
              <p className="p-in-sp">Now, Mr. Chairman, I trust that we are all seriously and sincerely making our best effort to see that these difficulties between the South and the North can and may be adjusted.</p>
              <div className="speaker" id="sp2943"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I agree with the gentleman in motive but not in the course of action he proposes.</p>
              <div className="speaker" id="sp2944"><span className="persName">Mr. SUMMERS</span>—</div>
              <p className="p-in-sp">We disagree as to some things. My friend from Princess Anne, I believe, has presented a minority report.</p>
              <p>Here the hammer fell.</p>
              <div className="speaker" id="sp2945"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                Mr. Chairman, I suppose no further debate is in order upon this amendment, but I desire to ask a question as to its effect. I ask you, if this amendment is adopted, whether it would, or would not, leave the military forces of the United States in possession of the forts in the main land and in the harbors? I then ask, if the effect of this amendment would not be to enable the United
                States authorities to reinforce the forts out of the harbor and off the main land?
              </p>
              <div className="speaker" id="sp2946"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Chair would suggest to the gentleman that it is not in order to propound interrogatories, because time must be taken to answer them, and because it is in the nature of debate—</p>
              <div className="speaker" id="sp2947"><span className="persName">Mr. SUMMERS</span>—</div>
              <p className="p-in-sp">Is it in order for me to answer the gentleman?</p>
              <div className="speaker" id="sp2948"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">It is not.</p>
              <div className="speaker" id="sp2949"><span className="persName">Mr. SUMMERS</span>—</div>
              <p className="p-in-sp">The gentleman has asked a question, and I suppose it is equally in order for me to ask back the question; I simply propound back the question—for I cannot debate—whether the language of the resolution, as it now reads, does not leave the forts in the harbors and on the main land, in every particular, precisely as they will be left if the amendment is adopted.</p>
              <p>The amendment was agreed to—ayes 78, noes not counted.</p>
              <div className="speaker" id="sp2950"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">In order to make this resolution, as it has now been amended, consistent with itself ; I move to strike out, in the 7th line, the word "nor," and insert the word "except" so that the clause will read, "that no attempt be made to subject them to the Federal authority, except to re-inforce the forts" &amp;c. [Laughter.]
                That, sir, will express the true sense of the resolution as it is amended. That will show what the gentleman from Kanawha means. It will show exactly what this House means; that a pacific policy shall be pursued, except to re-inforce certain of the forts. Those forts are to continue to be held as now, in the possession of the forces of the United
                <PageNumber num={483} /> States. The gentleman says that I mis-stated it. I did not mis-state it, or, if I did, he has not made it clear to my mind, and I call his attention again to the effect of his amendment. It was to leave, during the interval of effort to secure an adjustment, the forts on the main land and in the harbors in the occupation of the
                United States authorities. That is the effect of the resolution itself, I admit, but then the effect of his amendment was further to enable them, not only to occupy the forts on the islands, but to re-inforce those forts. That is all I said in respect to the effect of the amendment, and in that he. himself, concurs. Is not the effect of the amendment that has just been adopted to
                allow the authorities of the United States to reinforce the forts on the islands? Not on the mainland, not in the harbor, I admit. Will the gentleman pretend to say that the effect of his amendment is not to enable them to re-inforce Tortugas? And yet he says that I have misunderstood the effect of his amendment. But what is his reason for it? Why that the resolution itself
                contemplates that the United States shall continue the occupation of these forts. That I admit, but his amendment has a second effect, to wit : that it allows the re-inforcement of Tortugas. Under the resolution of the Committee on Federal Relations, the authorities of the United States would not have the right, or it would not be policy for them to re-inforce Tortugas. The
                gentleman's amendment gives them that right. If it does not produce that effect, will he please tell me what effect it does produce? None other than to leave the forts on the mainland and in the harbors, where the resolution leaves them, and then, not only to hold Tortugas and the other forts not in the harbors and not on the mainland, but to re-inforce them, if the authorities of
                the United States shall so elect. What then? You have declared that these forts may be retained by the United States, and you have declared that such forts as Tortugas, off the mainland and out of the harbors, may be re-inforced.
              </p>
              <p className="p-in-sp">
                The gentleman wants to know whether I have any reason to believe that Florida or the Confederate States are excited upon the subject of Tortugas? Yes, sir, just as much as they are as to Fort Sumter, just as much as they are as to Fort Pickens. The moment they are able to take Tortugas they will take it. The only reason they do not take it now, is because it is not within their
                power, because they have not the marine force. But, sir, if the United States will evacuate that fort, the Confederate States will show them how they can occupy it by forces able to maintain it, with the science to maintain it, with the spirit to maintain it, and with the guns to maintain it. It is not a fact that they would not be able to occupy these island forts.
              </p>
              <PageNumber num={484} />
              <p className="p-in-sp">The gentleman has asked me a question whether I am ready to declare for secession the moment the United States shall attempt to re-inforce any of these forts? Was not that his question?</p>
              <div className="speaker" id="sp2951"><span className="persName">Mr. SUMMERS</span>—</div>
              <p className="p-in-sp">I said on the reinforcement of Tortugas by twenty marines.</p>
              <div className="speaker" id="sp2952"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                Twenty marines would test the principle of jurisdiction as well as 2,000. The Committee will not be blinded as to the principle itself, by the idea of a mere handful in point of numbers. I do answer him peremptorily that I would. Sir, I am ready now. A despatch has just come that the fight is going on at this moment in Charleston. I hear even now that the battle has commenced, the
                cannon booming. I am ready now to leave my seat upon this floor to march over and help them take these forts. I ask the gentleman to tell me in turn if the United States shall be guilty of the wickedness, the folly of attempting to force the occupation of these forts, pending our attempts at negotiation, by the fleet which has just sailed from New York, passing our very coast, our
                very door, to bring on war with these Confederate States, where he will stand? Gentlemen are very brave. They tell us they will never consent to the marching of troops through the State of Virginia to the South; no never. They tell me every day that they would stand by my side in that event. Sir, will you please to tell us the difference between this and sending them past your door
                on the water, within sight of your land, within sight of the county where I was born, where my eyes first opened upon the light. Will you tell me the difference between sending them from New York past the door of your Chesapeake Bay, down to subjugate these forts, and marching them directly across the terra firma? No, sir, they have not marched them, but they have sailed them ; and
                does the principle depend on whether they sailed by your door or marched through your house? The Lord deliver me from the excuses I hear every day about the contingencies that may happen, and the time that may arrive when gentlemen will be ready to take up their arms and defend Southern rights and Southern slavery! Whenever the disposition has been manifested that is now manifested
                to invade the territory of these States by occupying the forts, I tell the gentleman that I am not only ready to agree to defend them, but if I could I put ropes around you all and drag you to their defence. Sir, I am ready to take the responsibility, to leap into the abyss and to fight in defence of right, in defence of law, against wrong, against oppression, and, when your country
                lies bleeding before you, honor asks you if you doubt. Those worthy of respect crowd around you and tell you in public war, in <PageNumber num={485} /> private war, that if you doubt and go back you are damned, and ought to be damned to all eternity.
              </p>
              <p>Here the hammer fell.</p>
              <div className="speaker" id="sp2953"><span className="persName">Mr. SUMMERS</span>—</div>
              <p className="p-in-sp">
                Mr. Chairman, I am not a sensation man. The argument of the telegram I am not prepared, at this time, to respond to. I do not know its extent or reliability. I find myself called upon, as a member of this body, to deliberate calmly, as I think it is the duty of all of us to deliberate upon certain propositions now offered for our acceptance or rejection. I trust, Mr. Chairman, I need
                not go over the ground by which the amendment which has just met the approbation of a majority of this Committee commended itself to their approval. The gentlemen from Princess Anne seems to insist that the amendment which has been adopted and which he seeks to correct by the amendment he now proposes alters, in some manner, the meaning of this resolution. I understand him as
                insisting that this amendment, being adopted, give license not only to the troops within the fortifications upon the mainland of the seceded States, but to fortify and reinforce those already occupied. Now, Mr. Chair man, I differ toto caelo with the gentleman in this interpretation. He admits himself, that the original resolution, coming from the Committee of Twenty-one of which he
                was a member—held precisely the same language in that regard.
              </p>
              <div className="speaker" id="sp2954"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I did not concur in that report.</p>
              <div className="speaker" id="sp2955"><span className="persName">Mr. SUMMERS</span>—</div>
              <p className="p-in-sp">
                I did not say you did. I said the Committee of which you were a member. And I will undertake to say, from my knowledge of the gentleman from Princess Anne, that this resolution did not pass unobserved and uncriticised by him. I take it that there are very few things which passed that Committee that were not subjected to his full scrutiny. I remarked that the original resolution,
                coming from that Committee, contains precisely the same language ; that it is not altered or modified in any respect by the adoption of the amendment, so far as the occupation of these forts are concerned, and the gentleman admits it.
              </p>
              <div className="speaker" id="sp2956"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I do.</p>
              <div className="speaker" id="sp2957"><span className="persName">Mr. SUMMERS</span>—</div>
              <p className="p-in-sp">
                Then why did not the gentleman make his objection to the resolution itself, and without regard to my amendment? The language of the resolution is not altered in any respect, except as to the class of forts not to be reinforced, and yet the gentleman seems to imagine that the amendment has worked some mysterious influence upon the resolution, by which it has been made to speak another
                language. Now, what I maintain is this : If the original resolution, <PageNumber num={486} /> applies to all the forts, but does not authorize expressly the retention of the forces of the United States there, neither does
                it, now after the amendment has been inserted. The resolution does not import a declaration of the will of this Convention or an opinion upon the part of the Convention, as to whether the forces are or are not to be withdrawn from these forts. The original resolution did not express any opinion upon that subject, neither does it as amended. I have already intimated that I concur with
                him in opinion as to the policy of the withdrawal ; but nothing is contained in the resolution upon that subject. It is not in the original resolution, it is not in the resolution now. The declaration in respect to the position of the Federal Government in connection with these forts, pending our efforts to procure an adjustment of these questions as contained in the original
                resolution is : "Nor to reinforce the forts now in possession of the military forces of the United States." That is one of the conditions. It says nothing about the withdrawal of the troops already there. It makes it one of the conditions while we are waiting, not to reinforce the forts. That is all. Well, sir, the only difference between that condition and the condition of the
                resolution as it has been modified, is that the forts referred to are restricted to those upon the mainland and those in the harbors of the seceded States. It is equally silent with the original resolution as to the withdrawal. The original resolution expressed no such opinion, nor does it now.
              </p>
              <p className="p-in-sp">
                Then, sir, as to the effect of the amendment which has been adopted upon the forts located upon the islands and reefs out at sea. Sir, that amendment does not give license to reinforce those forts at all. It gives no such authority. It expresses no opinion at all as to what ought to be done or what ought not to be done, as to reinforcement, except that we make it a condition that
                those upon the mainland and in the harbors of the seceded States are not to be reinforced during this effort for adjustment. We say nothing about the reinforcement of the forts on Tortugas or Key West. I am not at this day prepared to express an opinion as to what should be the ultimate course of the Government in reference to these forts. We have not been called on for an opinion
                upon that subject, and I do not propose to go into a discussion of it on this occasion. I do not say that they should be reinforced—I only decline to say that if they are reinforced, it would be cause of just quarrel.
              </p>
              <p className="p-in-sp">
                Now, Mr. Chairman, if anything has occurred, or is now occurring, at any point in our country, at Charleston or elsewhere, no man would <PageNumber num={487} /> regret a hostile collision there more than I. None. But, sir, I say that we, the representatives of the people of Virginia, are not to be driven from our propriety by telegraphic despatches.
                When we shall have ascertained what is our duty, I trust there is no man here who is not ready to perform it. I make no professions, nor do I deem them necessary. The gentleman need not talk about driving us, nor about driving our constituents. We will not be driven one way nor the other, into precipitation. We will go with our eyes open, when we do go; we will act as our duty
                requires; we will act in accordance with the duty which we owe to our Commonwealth ; in accordance with the duty we owe to patriotism, in accordance with what duty and honor as Virginians require at our hands. And, sir, I entreat gentlemen—and I do not address myself particularly to the gentleman from Princess Anne—I mean all of us—not to act with precipitation. It is our duty,
                standing here as watchmen upon the walls of our political Zion if I may use the word—as sentinels of the great public interests committed to our hands, to be calm, to be considerate, wise, prudent ; and let us so act that whenever the path of duty shall have been opened up to us, whenever duty shall impel us forward, let us follow in the path, wherever it shall lead us.
              </p>
              <p className="p-in-sp">
                But, Mr. Chairman, let us act upon the subject now before us. The amendment is right, in my judgment, as it is, and I am sure that when the gentleman from Princess Anne shall have looked at it himself with a little more reflection, he will be satisfied that it makes no difference whatever in the original resolution, in the respect to which he takes exception ; that it does not
                recommend the occupancy of the forts, but merely leaves the question open without an expression of opinion, one way or the other.
              </p>
              <div className="speaker" id="sp2958"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                I propose, to say a word or two about the effect of the gentleman's amendment. He admits all I want him to admit. He admits that the resolution, as it now stands amended, permits the occupation, by the military forces of the United States, of the forts on the main land and in the harbor. That is a fact which cannot be denied. But his amendment goes further, and permits, in the
                election of the United States authorities, the re-inforcement of the forts outside the harbor and off the main land. That the gentleman in fact admitted, although, in resisting my objection, he seemed to contradict the fact.
              </p>
              <p className="p-in-sp">
                But, sir, the gentleman asked me a question. He asked me two questions, which, upon my part, I propose to answer. I asked him one which he did not answer. I asked him, in the event of an attempt <PageNumber num={488} /> upon the part of the United States to hold forcible occupation of Fort Sumter, if he would regard that as a breach of the pledges that
                we are making in these resolutions, so far as to make it necessary for the Confederated States and for us to take prompt and decided action? He did not answer that. He tells me he will look calmly on. Sir, I am calm upon this subject. I have spent, now, months here in reflecting; you know with what labor, you know with what patience, with what long suffering, you and I bore with each
                other, in comparison of our views and reflections upon all the points of these resolutions. And, now, sir, I will give the gentleman a telegraphic dispatch, received from a source, for which I will be personally responsible, as to its correctness. It is from a respectable, an honorable, and a responsible man. It reads:
              </p>
              <p className="p-in-sp">"NORFOLK, April 10—The Pawnee has just left the harbor, with sealed orders, for the South." [Laughter.]
                Gentlemen may laugh at dispatches that may be received ; but, sir, here a harbor within our own State is made the point from which to send out armed forces against the South. Gentlemen would resist the marching of armies through this Commonwealth, and yet they sit quietly and even laugh at the idea of the "Pawnee" being sent out with armed forces for the Southern States from the
                harbor of Norfolk.
              </p>
              <div className="speaker" id="sp2959"><span className="persName">Mr. SUMMERS</span>—</div>
              <p className="p-in-sp">Will the gentleman from Princess Anne allow me to interrupt him?</p>
              <div className="speaker" id="sp2960"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I will, although the gentleman refused to yield to me just now.</p>
              <div className="speaker" id="sp2961"><span className="persName">Mr. SUMMERS</span>—</div>
              <p className="p-in-sp">I did, because I had but a moment remaining. The gentleman states that I did not answer his question as to what I would do in a certain contingency. I reply to the gentleman that I will very cheerfully co-operate with him in what I understand to be his plan of fighting in the Union. [Laughter.] I do not want to go out of the Union.</p>
              <div className="speaker" id="sp2962"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                It is precisely such an answer as I expected from the gentleman when I heard him the other day talking so bravely of his Virginia blood and his Virginia feeling. I thought then the gentleman from Kanawha was talking cant.<span className="note" id="Note52"
                ><span className="noteLabel">5</span>An editorial correction of April 18 reads "I thought then that the gentleman from Kanawha was talking like Joe Tank," instead of "I thought then the gentleman from Kanawha was talking cant." On April 20, the Enquirer printed a letter from Mr. Wise, in which he corrected his remark to read "The gentleman was then making Kanawha seem to talk like Joe
                  Tank." Mr. Wise complained that the reporter's mistake made him twice insult Mr. Summers, whereas he "spoke to him in playfulness and with perfect respect." The reporter's other mistake was the phrase "base inertia" mentioned below.</span
                >
                When you come to bring him down to the point, he is always looking to some other contingency. The gentleman talks about my fighting in the Union. Sir, the gentleman, I am afraid when he comes to fight, will be found veering towards that North star which now controls the tide of his sympathies. He says he will not be dragged. It so happens in the heavens that there is an influence, a
                mysterious influence, more <PageNumber num={489} /> miraculous than anything I have ever known on earth an influence that when it touches the needle in whatever quarter of the earth, that needle still turns Northward. Sir, in the political heavens, too, at about an angle of 45 deg due North, that star makes the needle tremble towards the North pole.
                That Southern cross which is seen when you approach the equator, has no such influence—none. I recollect when I crossed the equator once upon the high seas and I saw the polar star of my own hemisphere descend below the horizon, it was then that I bade farewell to my country. As I saw that star dip beneath the earth, I saw ascending in the Southern hemisphere the Southern cross. But,
                notwithstanding, I passed the line that cuts the earth through its centre, the line of the equator, that needle which guided the ship over the vasty deep, that needle, still on and on, did not tremble towards the Southern cross. It still trembled towards that North solitary star that had dipped below the horizon. So with that gentleman. On the other side of the equator, carry him
                where you will, I am afraid there is an influence, like the mysterious influence of the needle, that will make him tremble still towards the Northern pole.
              </p>
              <p className="p-in-sp">
                Thank God for it, that the needle or the compass cannot have its point under the influence of the loadstone turned to the North star, without having its opposite point turned directly to the Southern cross. The gentleman takes one direction, and I take another. He is turned toward the North star, I toward the Southern cross. I do not mean to impugn his motives ; I have no doubt they
                are as patriotic as my own. I will not part without sadness from the North star. As to parting from the Union, in my affections, I shall never do that. As to leaving its flag, whenever I leave this Confederacy, this North star Confederacy, which makes the needle tremble northward, sir, I shall carry the flag of the old Union out with me; and if I ever have to fight—so help me God !—I
                will fight with the star-spangled banner still in one hand and my musket in the other. I will never take any Southern cross, or any Palmetto for my flag. I will never admit that a Yankee can drive me from the Union, and take from me our capital. I will take from him forts, I will take from him flags, I will take from him our capital, I will take from him, if I can, my whole country
                and save the whole. Will that satisfy the gentleman as to fighting in the Union? It will. I am glad of it. I implore him though, when he says he will not be dragged, not to drag me northward.
              </p>
              <div className="speaker" id="sp2963"><span className="persName">Mr. SUMMERS</span>—</div>
              <p className="p-in-sp">I made no reply.</p>
              <div className="speaker" id="sp2964"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Your silence gave consent.</p>
              <PageNumber num={490} />
              <div className="speaker" id="sp2965"><span className="persName">Mr. SUMMERS</span>—</div>
              <p className="p-in-sp">I was listening, but not assenting or dissenting.</p>
              <div className="speaker" id="sp2966"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                I hope, the gentleman when he says he will not be dragged himself, will not attempt to drag me. If he does not do it by positive force, I hope he will not do it by a base inertia,<span className="note" id="Note53"
                ><span className="noteLabel">6</span>Both the editorial correction of April 18 and Mr. Wise's correction printed on April 20 read "vis inertia" instead of "base inertia." Presumably the correction should read "vis inertice."</span
                >
                by making me look behind in the discharge of my duty, or making me dilatory on a point of honor.
              </p>
              <div className="speaker" id="sp2967"><span className="persName">Mr. RIVES</span>—</div>
              <p className="p-in-sp">
                I move to amend the amendment, by adding after the word "except," the words "with provisions"—so as to make it read not to re-inforce, "except with provisions." I know, Mr. Chairman, the gravity of the subject which we are now discussing, I know the excitement which is prevailing in the country, I know that public opinion may be misled as to the fundamental cause of the crisis which
                seems now to have reached a culminating point; I rise for the purpose of responding to the eloquent remarks made by my friend from Princess Anne. He has announced the fact that war has already commenced.
              </p>
              <div className="speaker" id="sp2968"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">No, not already commenced.</p>
              <div className="speaker" id="sp2969"><span className="persName">Mr. RIVES</span>—</div>
              <p className="p-in-sp">
                I rise to call attention to the fact, that if war has commenced, it has commenced because the General Government has attempted, if I understand correctly, to furnish Major Anderson at Fort Sumter with provisions. There is no other ground alleged but this—that the United States Government has dared to send meat and bread to a perishing garrison. Sir, I would suffer a thunderbolt from
                Heaven to strike me, before I would suffer him to perish for want of bread to eat.
              </p>
              <p className="p-in-sp">
                I call the attention of members to the issues involved. I want no man to run of and engage in a fight unless he knows what he is fighting about. If the fact turns out, as I have no doubt it will turn out, that the people of Charleston have cut off the supplies of Major Anderson, and that the government is performing the humane act of supplying him with provisions, the American people
                will support that act of the government. If you want a precedent for this, you will find it in smoking poor, miserable devils out of their holes by the British, with noxious gas, in which a dog could not live. The chivalry of South Carolina, if Major Anderson asked for bread, should give him bread, instead of pouring hot shot into him.
              </p>
              <p className="p-in-sp">
                I avow it here, before God and man, that if that is the cause of this war, you may set me down as ready for the rope; for I would hand him bread, if he was within my reach. Ask Major Anderson how long he has served his country; ask him what service he has done his country, that he is now to perish—that he is now to bare his bosom
                <PageNumber num={491} /> and have hot shot poured into him. He would say to you, "let me fall like a man, but in the name of God do not starve me to death!" Shame, shame, where is your blush? The finger of scorn rest on such a character! You have to nerve yourselves. Any man can kick up a row, but few men can stand in its midst and survey it. I know
                that when the war cry goes out, the young hot blood will rush into it, without considering the cause ; but I warn the young men of the country, I warn the discreet men of the country, to be sure that they are right before they engage in this war. Every man should deplore the spilling of blood, but if blood is to be spilled, let it be spilled in a cause that is just and proper.
              </p>
              <p className="p-in-sp">
                There is no act of oppression complained of, nothing in the world but that the General Government undertakes to supply to Major Anderson provisions which South Carolina refuses him. Now I put it to you—I put it to this Convention, whether the civilized world will not look with condemnation on the act of South Carolina bringing about this necessity on the part of the General
                Government. Is it right, or is it wrong, that he should have provisions? And yet not a minute ago, all the forts were to be given up for peaceable purposes.
              </p>
              <p className="p-in-sp">
                I have offered that amendment in good faith. I have no doubt in the world that my friend from Princess Anne offered his with a view of making some remarks and then withdrawing it. I believe that it is the duty of this Convention to express the opinion that if Major Anderson's supplies have been cut off by South Carolina, it is the duty of the General Government to supply them until
                this matter is settled. It is for that I offer my amendment. I put it here as a tangible proposition to be voted on, and hope the Committee will adopt it.
              </p>
              <p>The question was taken on <span className="persName">Mr. RIVES</span>' amendment, and it was rejected, there being on division—ayes 35, noes 60.</p>
              <p><span className="persName">Mr. WISE</span>, by unanimous consent, withdrew his amendment.</p>
              <div className="speaker" id="sp2970"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">
                I offer an amendment, by way of addition, to the 12th section. It is in the language of the amendment moved some time ago by the gentleman from Princess Anne, except as to the description of the forts. His amendment was, that the forts, arsenals, magazines, and other places ceded to the United States, within the limits of the seceded States, ought, for purposes of pacification, to be
                evacuated by the authorities of the Federal Government. That amendment was rejected—rejected because of its want of discrimination as to the character of the forts recommended to be evacuated. I, for one, do not wish to be placed before the country in the attitude of having voted <PageNumber num={492} /> against a recommendation to evacuate Forts Sumter
                and Pickens; and I do not suppose that that is the desire of any one here. I therefore offer this amendment to be added to the 12th section : "And the forts, arsenals, magazines and other places ceded to the United States, and situate on the mainland or for the protection of the harbors in the seceded States ought, for purposes of pacification, to be evacuated by the authorities of
                the General Government."
              </p>
              <div className="speaker" id="sp2971"><span className="persName">Mr. CONRAD</span>, of Frederick—</div>
              <p className="p-in-sp">I shall be compelled to vote against the amendment, and not because I have any objection to the proposition contained in it, but because I think it is not germane at all to the object of the section which it proposes to amend.</p>
              <p className="p-in-sp">
                The 5th section, I think, provides sufficiently for the expression of the opinion of this Convention in regard to these forts and arsenals. The 12th section is not directed to any such purpose; and I consider, therefore, that it will mar the section to introduce any declaration of this sort into it. There is no such in it now. The whole object of that section is, to express the
                opinion of this Convention that a pacific policy should be pursued pending the efforts of Virginia to adjust the national difficulties. I, therefore, sir, and for the reasons stated by the gentleman from Kanawha, will be compelled to go against the amendment proposed.
              </p>
              <div className="speaker" id="sp2972"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">I had no desire to offer any such amendment as this. I perceive the force of the objection, that it is not strictly germane to the resolution. But, sir, the vote which has been taken here upon the proposition of the gentleman from Princess Anne [<span className="persName">Mr. WISE</span>] , places me and the friends who act with me, in the attitude of having voted against a measure of pacification. I do not wish that I or those who act with me, should be placed in that position, because it is not a just position. I want, therefore, to present a distinct enunciation of what we designed in opposing this measure of pacification.</p>
              <div className="speaker" id="sp2973"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I call for the yeas and nays.</p>
              <p>
                The call was sustained, and the question being taken, resulted, yeas 101, nays 25; as follows:<span className="note" id="Note54"><span className="noteLabel">7</span>The Journal specifies 102 affirmative, 24 negative votes, and lists the names as given here.</span>
              </p>
              <p>YEAS-Messrs. John Janney [President]
                , Ambler, Armstrong, Aston, Baldwin, A. M. Barbour, James Barbour, Baylor, Blakey, Blow, Boisseau, Borst, Bouldin, Boyd, Branch, Bruce, Byrne, Cabell, Campbell, Cecil, Chambliss, Chapman, Coffman, Conn, Couch, Richard H. Cox, Critcher, Deskins, Dulany, Dorman, Echols, Flournoy, French, Fugate, Garland, Gillespie, Graham, Gregory, Goggin, J. Goode, T. F. Goode, Hale, A. Hall, C. Hall,
                L. S. Hall, Hammond, Harvie, Haymond, Hoge, Holcombe, Hunton, Isbell, Marmaduke Johnson, Peter C. Johnston, Kent, Kilby, Lawson, Leake, McComas, Charles K. Mallory, J. B. Mallory, Marshall, Marr, Marye, Moffett, Morris, Morton, <PageNumber num={493} /> Moore, Nelson, Orrick, Osburn, Parks, Patrick, Pendleton, Price, Pugh, Richardson, Rives, R. E. Scott,
                William C. Scott, Seawell, Sharp, Sheffey, Sitlington, Slaughter, Southall, Speed, Spurlock, Staples, C. J. Stuart, Strange, Summers, Sutherlin, Thornton, Tredway, Tyler, Waller, Wickham, Williams, Wilson, Wise, Wysor-101.
              </p>
              <p>NAYS-Messrs. Boggess, Brent, Brown, Burdett, Burley, Carlile, Carter, C. B. Conrad, Robert Y. Conrad, Custis, Dent, Early, Gravely, Hubbard, Hughes, Jackson, Lewis, McGrew, Macfarland, Masters, Porter, Tarr, White, Willey-25.</p>
              <div className="speaker" id="sp2974"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">I am tempted to offer one more amendment, by way of addition. I am not aware whether the Chair will construe it to be in order, or whether it is germane to the subject. The amendment I propose reads :</p>
              <p className="p-in-sp">"And that the Committee now rise and ask the Convention to be discharged from the consideration of the first report made by the Committee on Federal Relations."</p>
              <div className="speaker" id="sp2975"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Chair cannot entertain the motion.</p>
              <p>The question then recurred upon the adoption of the resolution as amended, and the question being put, it was adopted.</p>
              <p>The Committee then proceeded to the consideration of the thirteenth</p>
              <p>resolution in the report, which reads as follows:</p>
              <p>
                "13. In the opinion of this Convention, the people of Virginia would regard any action of the Federal Government, tending to produce a collision of forces, pending negotiations for the adjustment of existing difficulties as aggressive and injurious to the interests and offensive to the honor of this Commonwealth ; and they would regard any such action on the part of the seceded or
                Confederated States as hurtful and unfriendly, and as leaving them free to determine their future policy."
              </p>
              <div className="speaker" id="sp2976"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                I would rise simply to call the attention of my friend, the chairman of the Committee on Federal Relations, as a member of that committee, to the fact that the personal relative pronoun "them," in the 8th line, would refer to the Confederate States, whereas it is meant to refer to the people of this Commonwealth. The latter part of the section reads thus—"and they would regard any
                such action on the part of the seceded or Confederated States as hurtful and unfriendly, and as leaving them free to determine their future policy," the word "them" evidently referring to the Confederate States as being the last noun preceding that word. It is a mere verbal grammatical correction which I suggest.
              </p>
              <div className="speaker" id="sp2977"><span className="persName">Mr. CONRAD</span>—</div>
              <p className="p-in-sp">It is a very nice point of grammar—I do not think there is any danger of its being misunderstood.</p>
              <div className="speaker" id="sp2978"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                Well, as a member of the Committee, and intending to <PageNumber num={494} /> report language that will express the meaning of the Committee, I feel interest enough in the matter to move to amend this portion of the resolution. I move to amend by inserting in lieu of the word "them" the words "the people of Virginia." Should this amendment prevail, that
                part of the resolution will read, "and they would regard any: such action on the part of the seceded or Confederated States as hurtful and unfriendly to and as leaving the people of Virginia free to determine their future policy."
              </p>
              <div className="speaker" id="sp2979"><span className="persName">Mr. CONRAD</span>—</div>
              <p className="p-in-sp">
                I merely wish to suggest to the gentleman from Princess Anne that whilst I think it is correct as it stands—the true relative is the word "the"<span className="note" id="Note55"><span className="noteLabel">8</span>Mr. Conrad probably said that "them" was the true relative, but his meaning is not clear.</span>—the gentleman would make that part of the section still clearer by substituting the
                word "themselves" in place of "them." That would give it a correct reading.
              </p>
              <div className="speaker" id="sp2980"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I would rather substitute the words "the people of Virginia."</p>
              <div className="speaker" id="sp2981"><span className="persName">Mr. CONRAD</span>—</div>
              <p className="p-in-sp">It will read very awkwardly, then, sir.</p>
              <p>The question was then taken on the amendment by a count, and decided in the affirmative, ayes 73, noes 32.</p>
              <div className="speaker" id="sp2982"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I now call the attention of the Committee to the last clause of this resolution with a view to move to strike all after the word "Commonwealth." The portion which I propose to strike out is this :</p>
              <p className="p-in-sp">"And they would regard any such action on the part of the seceded or Confederated States as hurtful and unfriendly, and as leaving the people of Virginia free to determine their future policy."</p>
              <p className="p-in-sp">
                My first objection to that, is one that will seem hypercritical; still, with me, it is an objection. It implies that the people of the State of Virginia are not free now to determine their future policy. I think we are pretty free, sir. We are constrained by difficulties that embarrass all the affairs of human life, in peace as well as in war; but as far as election is concerned—as
                far as choice is concerned—as far as the will is concerned in determining our line of policy, we are free as a people in their Convention can be, already. That is the minor objection. The main objection to this is, that it implies something that I do not understand. Is the effect of this latter clause to be a threat to the Confederate States, that if they shall attempt to capture the
                forts within their limits, we would regard that act as "hurtful and unfriendly" to us? That we would regard it as hurtful and unfriendly for them to take their own forts? They have exercised their free will, their sovereign pleasure, and have chosen to go out of the <PageNumber num={495} /> Union, and we will regard it as "hurtful and unfriendly" to us
                for them to be free, too! Is that what we wish to say? Or, sir, do we mean to say more? Do we mean to express regret for their free and sovereign action whilst we claim to be free at the same time? This free action is hurtful and unfriendly to us. Does this mean to imply, by the very occult, by the very obfuscated expressions employed, "we are left free to determine our future
                policy"—do gentlemen mean thus to intimate the inclination, or the threat or idea that we will go North? Is that the meaning of this language? If that be the meaning, I cannot vote for this section with this latter clause in it. However wrong I might suppose the Confederate States to be—if that was my opinion, which it is not—in taking their own forts within their own territory, yet,
                sir, if they, in the exercise of this freedom; if they, in the exercise of their sovereignty—for I must yield to them as I would claim for myself freedom and sovereignty—if they choose, at their election to take the forts within their own limits, I mean to intimate to them the very reverse of what seems to be the inclination here. I shall be compelled to unite with them in defending
                their jurisdiction. Whatever other gentlemen may do, I, for my part, will with hand, heart and head, defend them in the exercise of their freedom and their sovereignty.
              </p>
              <div className="speaker" id="sp2983"><span className="persName">Mr. SPEED</span>—</div>
              <p className="p-in-sp">I propose to amend the amendment by striking out all after the word "unfriendly" in the 8th line.</p>
              <p className="p-in-sp">
                The object I have in view is identical with the purpose indicated by the gentleman from Princess Anne, which is not to commit the Commonwealth to an expression of opinion that she, in a certain contingency, would be free to act as she pleases. The resolution states that in a certain contingency, if a certain course of policy be pursued by the General Government, or by the
                Confederated States, Virginia would feel herself free to determine her future action. It seems to me, that by striking out these words, we will reach the object which the gentleman from Princess Anne is seeking to accomplish.
              </p>
              <div className="speaker" id="sp2984"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">I do not regard the amendment of the gentleman from Campbell [<span className="persName">Mr. SPEED</span>] , as making any material difference in the resolution ; but the amendment proposed by the gentleman from Princess Anne [<span className="persName">Mr. WISE</span>]
                , raises a very important question; and that is, whether this Convention, having a few days ago refused to take a position of hostility towards the Government of the United States, having distinctly, by a vote of two to one, refused to secede from the American Union, intend now to say, in effect, to the people of the Confederated States, who are regarded, by a member upon this floor,
                <PageNumber num={496} /> as constituting, to all intents and purposes, a community as foreign to us as England or France, "assail our flag, when you will, where you will, and you will have our sympathy."
              </p>
              <p className="p-in-sp">
                The question is whether we are to invite assault upon the flag of the United States from any quarter. Sir, whenever the time comes that I am willing to go against the flag of the United States, I will take the necessary preliminary at the start to declare a revolution against the Government. But so long as I recognise Virginia as a part of the United States; so long as I regard
                myself as a citizen of Virginia and the United States, I will never extend my sympathy to any assault upon the flag of the United States, come from what quarter it will. I will endeavor to see, as far as my influence can go, that that flag shall float only where it has a legitimate right to float—that it is not carried anywhere, where it has not a right to go; but, carry it where you
                will, let it float where it may, as long as it is my flag representing my country I will sympathise with it against all creation, and I will never be found in any way whatever, to express a wish, hope, desire or consent that it shall go down before mortal foe. Never, never! The motto upon which I act, is, "my country, may it always be right, but right or wrong, my country ;" and as
                long as I call the Stars and Stripes my flag, I will sympathise with those who bear it and against those who assail it.
              </p>
              <div className="speaker" id="sp2985"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                This is a good 4th of July oration. I have heard this story upon the symbol of that flag before. Let me tell the gentleman that he cannot love that Star Spangled Banner better than I do, but when he attempts to snatch it from a Southern Confederacy, and to carry it to a Northern Confederacy—we will have to fight over it. That flag had the motto of "United States of America," when we
                were 33 States E pluribus unum. And now, that the pluribus is reduced from 33, down to 26, the question is whether we will belong to the twenty-six that are North, or with the seven<span className="note" id="Note56"><span className="noteLabel">9</span>A correction (April 18) reads "to the seven" instead of "with the seven."</span> that are South. No hallelujah, no hifalutin, no Mesopotamia
                [laughter]
                phrase for me over the flag of the United States. I have heard enough of this before—it is ad captandum. I have had to deal with that flag 5,000 miles from home. I have seen a United States vessel prostituting that flag to the infamous usage<span className="note" id="Note57"><span className="noteLabel">10</span>A correction (April 18) reads "uses" instead of "usage."</span> of the African
                slave trade, and I have had to snatch it from the infamy of that trade, carried on by old England and New England, in vessels owned and registered in the name of Quakers of the North, nineteen out of twenty-two of them sailing from ports North of the Potomac—using that flag in this infamous traffic ; I, a Virginian and a slaveholder, had to snatch that flag from
                <PageNumber num={497} /> Englishmen and Yankees then, and I mean to snatch it from Englishmen and Yankees now.
              </p>
              <p className="p-in-sp">
                If there is any gentleman whose frame of mind or heart is so rank for the star-spangled banner—or so rank for the North, I meant to say—as to make the star-spangled banner, now waving over the whole land, the flag of the free and the brave, the flag of Yankee domination over me, and to emancipate my slaves per fas and nefas;<span className="note" id="Note58"
                ><span className="noteLabel">11</span>A correction (April 18) reads "per fas aut nefas."</span
                >
                I will snatch it from him, though I have to enter the very jaws of death. Let me tell my friend, that whenever the fight commences over that flag as to whom it belongs, let him be apprised of the fact, that it will be a fight between neighbor and neighbor.
              </p>
              <div className="speaker" id="sp2986"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">I will never take any part in any neighborhood war against the voice of Virginia.</p>
              <div className="speaker" id="sp2987"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                I will act as my fathers did. If there are any cow-boys in my neighborhood, I will drive them out. I will treat them, as my fathers treated those in their neighborhood who adhered to England—as Englishmen and as my enemies. I told my constituents, when the worst came to the worst, that some of them could not live in my neighborhood, or I in theirs. That the war, when it comes—we need
                not disguise it—will array neighbor against neighbor in this Commonwealth, is too plain; this is not to be misunderstood. Not that I would not unite with my friend from Augusta
                [<span className="persName">Mr. BALDWIN</span>] , in deploring, deprecating and preventing it. I merely call his attention to the fact that the whole question now is, who is entitled to the flag—those who have conscientiously kept the covenants of the Constitution, or those who ruthlessly have broken them?</p>
              <p>The hour of 2 o'clock having arrived, the Convention then took a recess till 4 o'clock, P. M.</p>
              <p>The Convention re-assembled at 4 o'clock, P. M., and in Committee of the Whole [<span className="persName">Mr. PRICE</span> in the Chair] , resumed the consideration of the amendments offered to the 13th resolution of the report of the Committee on Federal Relations.</p>
              <div className="speaker" id="sp2988"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">When the time for recess arrived, the Committee had under consideration the 13th resolution. The gentleman from Princess Anne [<span className="persName">Mr. WISE</span>] , moved to strike out all after the word "Commonwealth," in the 5th and 6th lines. The gentleman from Campbell [<span className="persName">Mr. SPEED</span>] , moved to strike out all after the word "unfriendly," in the 8th line. The question will be taken first upon the amendment of <span className="persName">Mr. SPEED</span>.</p>
              <p>The question was then taken upon <span className="persName">Mr. SPEED</span>'S amendment, and it was not agreed to.</p>
              <PageNumber num={498} />
              <p>The question then recurring on the amendment of <span className="persName">Mr. WISE</span>, <span className="persName">Mr. SHEFFEY</span> called for the yeas and nays, which were ordered.</p>
              <p>The question was then taken, and the amendment was rejected, yeas 32, nays 79, as follows :</p>
              <p>YEAS—Messrs. Ambler, J. Barbour, Blakey, Boisseau, Borst, Bouldin, Bruce, Cabell, Cecil, Chapman, Conn, Flournoy, Garland, Gregory, Jr., J. Goode, Jr.. T. F. Goode, Hale, C. Hall, Holcombe, Isbell, Leake, C. K. Mallory, Morris, Morton, Neblett, Speed, Strange, Sutherlin, Thornton, Williams, Wise, Wysor32</p>
              <p>NAYS-Messrs. Janney [President]
                , Armstrong, Aston, Baldwin, A. M. Barbour, Baylor, Berlin, Boggess, Branch, Brent, Brown, Burdett, Burley, Byrne, Campbell, Carlile, Carter, Coffman, C. B. Conrad, R. Y. Conrad, Couch, Custis, Dent, Deskins, Dorman, Dulany, Early, Echols, French, Fugate, Gillespie, Gravely, Gray, Goggin, A. Hall, E. B. Hall, Hammond, Haymond, Hoge, Hubbard, Jackson, M. Johnson, P. C. Johnston, Kent,
                Lawson, Lewis, McComas, McGrew, Macfarland, J. B. Mallory, Marshall, Marr, Marye, Sr., Masters, Moffett, Moore, Orrick, Osburn, Parks, Patrick, Pendleton, Porter, Price, Pugh, Rives, R. E. Scott, Sharp, Sheffey, Slaughter, Spurlock, Staples, C. J. Stuart, Summers, Tarr, Waller, White, Wickham, Willey, Wilson-79.
              </p>
              <p>After the call of the roll was completed,</p>
              <p><span className="persName">Mr. HUNTON</span> stated that he had paired off with <span className="persName">Mr. CRITCHER</span> for half an hour.</p>
              <div className="speaker" id="sp2989"><span className="persName">Mr. NELSON</span> said,</div>
              <p className="p-in-sp"><span className="persName">Mr. TAYLOE</span> desired me to say that he was detained by indisposition, and that he paired off with <span className="persName">Mr. MILLER</span>.</p>
              <p><span className="persName">Mr. WHITE</span> said that <span className="persName">Mr. HOLLADAY</span> had paired of with Mr. CHAMBLISS.</p>
              <div className="speaker" id="sp2990"><span className="persName">Mr. BRUCE</span>—</div>
              <p className="p-in-sp">
                I now move to strike out the whole of this section. It seems to me that the principle contained in this resolution has been expressed in former resolutions fully enough, and I think that, with so many resolutions, they might well spare this, if it be unnecessary. But I object to it, particularly from the fact that it speaks of a pending negotiation for the adjustment of existing
                difficulties. Where are negotiations pending? Who are the negotiators, and where is the scene of negotiation? My friend from Princess Anne has taken this question in hand, and I should be glad to have him tell me what negotiations are pending. I know of none. When the Peace Congress was in session—I think it very probable that this resolution was adopted by the Committee of
                Twenty-one, during the session of the Peace Congress—these negotiations were pending, but, since their consultations have come to nothing, it cannot be understood that any negotiations are now pending.
              </p>
              <PageNumber num={499} />
              <div className="speaker" id="sp2991"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I will remind the gentleman that this Convention has now sent a mission to Washington for the purpose of negotiation.</p>
              <div className="speaker" id="sp2992"><span className="persName">Mr. BRUCE</span>—</div>
              <p className="p-in-sp">
                I am not aware of the fact that this commission is clothed with the authority to enter upon any negotiations. I know the fact that now there are no negotiations pending, and that no negotiators have been appointed; therefore, the language of this resolution is not true. It may be said that we are to have negotiations hereafter; but we do not know it. If an ultimatum be presented by
                Virginia to the North, it will be no negotiation but a naked demand.
              </p>
              <p className="p-in-sp">I hope, therefore, it will be the pleasure of the Committee to strike out the whole of this resolution, as I consider it useless and I can see no good in it.</p>
              <div className="speaker" id="sp2993"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">
                I hope it will not be the pleasure of the Committee to strike out that resolution. It may be true that we have too many resolutions ; but I do not observe any sensitiveness on that ground, unless it happens that some resolution has something in it that happens to be particularly distasteful to gentlemen on this floor. It is not the number of resolutions that constitutes the most
                prominent objection. We have been engaged in a number of forms in declaring our objections to anything tending to break the public peace ; and while I cannot approve all the language used, I concur most heartily in the object in view. This resolution, however, has one merit that no other in the whole series has, and that is, that it speaks alike to both parties in favor of
                maintaining peace; and I would like to know whether this Convention has any serious objection to speak to the seceded States, as well as to our own government, in favor of peace. Therefore, sir, as this resolution has that peculiar recommendation, I hope it will be the pleasure of the Committee to retain it as it is.
              </p>
              <div className="speaker" id="sp2994"><span className="persName">Mr. BRUCE</span>—</div>
              <p className="p-in-sp">
                Mr. Chairman, I would simply remark that in the resolution we have just passed, the same doctrine is asserted in stronger terms than it is in this resolution; and to retain this would only be a mere repetition of what is asserted before. It says a specific policy shall be preserved ; and of course in order that the peace may be kept, there must be peace on either side. I think,
                therefore, that this resolution is unnecessary. But with regard to the other position which the gentleman takes. He says that the seceded States must be required to keep the peace. But has Virginia the power to say, on the part of the North, that it will abstain from any interference with the South? If she can answer for the North, and say that the North will keep the peace, it would
                be a different thing. But, Virginia has no authority to speak for the North. The sword of Damocles <PageNumber num={500} /> hangs over the Southern Confederacy; it is suspended by a hair; it may at any moment fall upon them. Under these circumstances, silence on the part of the North is aggression; for I cannot conceive of any greater punishment on a
                man or a nation than to have impending over them a threat which may be executed at any moment. Silence in this case, I repeat, is aggression; and if the seceded States were to determine at once to take Fort Sumter, I cannot but say that it would be done in self-defence. The proposition is perfectly untrue, for there is no negotiation pending. It takes away the foundation of the whole
                resolution; and therefore, I hope it will be the pleasure of the Committee that the whole resolution shall be stricken out.
              </p>
              <div className="speaker" id="sp2995"><span className="persName">Mr. JOHNSON</span>, of Richmond city—</div>
              <p className="p-in-sp">I move to amend the 13th resolution by striking out all after the word "government," in the second line, and inserting the following—</p>
              <div className="speaker" id="sp2996"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">I would enquire whether the gentleman is in order?</p>
              <div className="speaker" id="sp2997"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">Does the gentleman from Orange rise to a point of order?</p>
              <div className="speaker" id="sp2998"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">Yes, sir.</p>
              <div className="speaker" id="sp2999"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The gentleman will state his point of order.</p>
              <div className="speaker" id="sp3000"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">My point of order is this : The motion of the gentleman from Halifax is to strike out the whole section, and to that amendment the gentleman proposes an insertion. I ask if that is competent?</p>
              <div className="speaker" id="sp3001"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">It is perfectly competent for the section to be amended before the motion to strike out is put to the house. The point of order is overruled. The gentleman from Richmond will proceed.</p>
              <div className="speaker" id="sp3002"><span className="persName">Mr. JOHNSON</span>, of Richmond city—</div>
              <p className="p-in-sp">
                I move to strike out all of the 13th resolution after the word "government," in the second line, and insert in lieu of the same the following : "or of the Confederate States tending to produce a collision of forces, pending efforts for the adjustment of existing difficulties, as unwise and injurious to the interests of both, and they would regard any such action on the part of either
                as leaving them free to determine their own future policy."
              </p>
              <p className="p-in-sp">So as to make the entire section read :</p>
              <p className="p-in-sp">
                "In the opinion of this Convention, the people of Virginia would regard any action of the Federal Government or of the Confederate States, tending to produce a collision of forces, pending efforts for the adjustment of existing difficulties, as unwise and injurious to the interests of both; and they would regard any such action on the part of either as leaving them free to. determine
                their own future policy."
              </p>
              <PageNumber num={501} />
              <p>The question was stated to the house.</p>
              <div className="speaker" id="sp3003"><span className="persName">Mr. JOHNSON</span>, of Richmond City—</div>
              <p className="p-in-sp">
                Mr. Chairman, the reason why I offer this amendment, with great deference to the committee which framed the original resolution, is simply because the language employed in the resolution as it stands does not convey a clear idea of the attitude which Virginia really occupies. Seven of our sister States have withdrawn from us, for reasons, whether good or bad, I deem it improper now
                and here to discuss, and we are left belonging to the government of the United States; and the people of Virginia, through us, are here and now convened for the purpose of ascertaining what Virginia can do in order to preserve the peace and perpetuity and happiness of the country. I do not think that we ought to use language to either party, to the government of the United States or
                to our sister seceded States of the South, which would be construed by either as offensive unless it is necessary to do so. This resolution now reads :
              </p>
              <p className="p-in-sp">"In the opinion of this Convention, the people of Virginia would regard any action of the Federal Government, tending to produce a collision of forces, pending negotiations for the adjustment of existing difficulties as aggressive and injurious to the interests and offensive to the honor of this Commonwealth."</p>
              <p className="p-in-sp">That is the language in which it describes the opinion of Virginia in contemplation of any action of the Federal Government tending to produce a collision of forces; and then it concludes :</p>
              <p className="p-in-sp">"And they would regard any such action on the part of the seceded or Confederated States as hurtful and unfriendly, and as leaving them free to determine their future policy."</p>
              <p className="p-in-sp">
                Now occupying the position, which I consider a very proud position, which Virginia now assumes ; while belonging to one Government and separated from those who have recently detached themselves from the Government; occupying the position of a peace-maker between the Federal Government and these seceded States—the most honorable mediation in which she has ever been employed—it seems
                right and proper that we should not use any language of an offensive nature to either of these parties; but that we should say in plain, explicit, respectful language, that in our opinion, it is necessary for the peaceful negotiation of the pending difficulties, that both should abstain from any action tending to produce a collision. And it is for the purpose of strengthening
                ourselves as a mediator between these parties in these peculiarly imminent times, that I propose to introduce that substitute, which speaks not in the language of menace, but which speaks respectfully <PageNumber num={502} /> to both and says that the people of Virginia would regard any act on the part of either "tending to produce a collision of
                forces, pending efforts for the adjustment of existing difficulties, as unwise and injurious to the interests of both; and they would regard any such action on the part of either as leaving them free to determine their own future policy." I think that is a matter of obvious propriety, and I trust it will so seem to the Convention.
              </p>
              <div className="speaker" id="sp3004"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                I do not now rise to speak so much in my own behalf, as in behalf of my absent colleague, the Honorable WM. BALLARD PRESTON, who has been charged with an important mission to Washington, and with whom this resolution was an especial pet, as you, sir, know, and as we all know. And I say in his behalf, who regarded this as the very point of his sentiments and his attitude in regard to
                both of these parties—the Federal Government and the seceded States of the South—that his design was expressly to distinguish between the Federal Government and these seceded States. This sentiment was my own; that you could not apply the same language in respect to both, or, language alike to both, without being unjust to ourselves ; that it was not the design to place Virginia in
                the attitude of a mediator, merely, to keep the peace between the seceded States and the Federal Government; that we, ourselves, were bound to be a party, and not a mediator; that it was impossible, for the Federal Government to invade those Southern States, and to commence hostilities upon them, for their defence of the institution of slavery, and to compel them to an alliance with
                the Government at Washington city, when they, on account of the wrongs done to their institution of slavery, were disposed to defend it; that it was impossible that the Federal Government should commence an invasion of those States without, indirectly, at least, invading the State of Virginia. He, therefore, desired to say that we were more than mediator when the general government
                would compel the seceded States to submit to the positions that they themselves have taken in respect to the institution of slavery, that we would be a partaker with the seceded States upon interest and upon honor in any course involving defence against an invasion of the institution of slavery, and with other States that are defending that institution.
              </p>
              <p className="p-in-sp">
                I cannot consent, no more than the gentleman from Montgomery, who is absent, would consent, to have Virginia placed in the position of a mere mediator in this affair. I protest that the State of Virginia does not on this question stand in a mediatorial condition. Sir, a mediator must be one that is impartial between the parties ; a mediator must be one that is not interested wholly
                on one side ; and when we <PageNumber num={503} /> declare ourselves in a mediatorial condition, we declare in effect that we have not an interest in common with the Confederate States; that we have not a disposition to make the cause of five hundred thousand slaves a part of the cause and interest that is involved in any invasion of the South.
                Therefore, <span className="persName">Mr. PRESTON</span> very properly pressed that a distinction should be made; that the Federal Government should be placed in one category, and the seceded States in another. If the latter choose to make war they will do what will be unfriendly to us. If we think that they are pressing a war unnecessarily, and making a war that ought not to be made,
                why it is involving us in the war on one side or the other, and in that respect it would be unfriendly.
              </p>
              <p className="p-in-sp">
                But who says that resistance to an attempt, on the part of the Federal Government, to enforce jurisdiction over the seceded States, could, in that aspect, be regarded as offensive to the honor of this Commonwealth? While, upon the part of the Federal Government, their attempt to enforce subjugation to the Federal Constitution, might well be regarded, and jealously regarded, as an
                invasion of her honor, and not only offensive to her honor, but also injurious to her interests. The gentleman's amendment declares that if they go to war with one another, we would lay an equal, impartial and disinterested hand upon the right and upon the left party, and say to them, "Why, you will hurt one another; your war will be injurious to each other." I want to say to the
                gentleman that that war, commenced by the Federal Government, will not only be injurious to the seceded States, but will be an injury to our interests, and will be an attack upon our honor. That was what the gentleman from Montgomery would insist upon, I know, if he were here, for I appeal to my brethren upon the Committee that this resolution was the especial pet of that gentleman.
                In his absence I feel bound to oppose the amendment, and especially as the views I have presented are my own views.
              </p>
              <div className="speaker" id="sp3005"><span className="persName">Mr. JOHNSON</span>, of Richmond—</div>
              <p className="p-in-sp">
                Without having been a member of the Committee on Federal Relations, without knowing who was the author, or in whose affections this resolution was a favorite, I offered this amendment after some consultation with gentlemen to whose opinions I deferred because I thought it proper. Surely I can have no objection that the gentleman from Princess Anne should vindicate the petted favorite
                of the gentleman from Montgomery in his absence, but it does seem to me a little strange that the gentleman himself this morning should have allowed himself to take so material a liberty with this resolution, for if my recollection serves me right the gentleman himself moved to strike out the last four lines of this resolution. Then
                <PageNumber num={504} /> it seems to me that if he is the especial guardian of the resolution of the gentleman from Montgomery, he takes a good many friendly liberties when he moves himself materially to alter it, and yet denies me the right.
              </p>
              <div className="speaker" id="sp3006"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I was taking care of my own sentiments then.</p>
              <div className="speaker" id="sp3007"><span className="persName">Mr. JOHNSON</span>, of Richmond—</div>
              <p className="p-in-sp">
                I know; but nevertheless it was consideration for the gentleman from Montgomery which induced the gentleman to appeal to the Committee to reject any amendment, and to agree to the resolution as offered by the gentleman from Montgomery. But, sir, that has no logical connection with the question under consideration. The question under consideration is the amendment which I offered true
                and right; and has the gentleman denied the correctness of one word of it? Is it not true, that if the Government of the United States takes any action tending to produce hostile collision, it will be injurious to the interests of all parties in the premises? Does any gentleman deny that proposition? Is it not true, that if the Confederate States take any action tending to produce
                hostile collision, it will be injurious to the interests of all parties? Does any gentleman deny that? And that is all this resolution declares.
              </p>
              <div className="speaker" id="sp3008"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">You strike out "offensive to the honor of this Commonwealth."</p>
              <div className="speaker" id="sp3009"><span className="persName">Mr. JOHNSON</span>, of Richmond—</div>
              <p className="p-in-sp">
                Yes, sir, but if it is offensive upon the part of one, is it not offensive upon the part of the other? When we owe allegiance should we discriminate in favor of the Government to which we do not belong? Can we say that if the Government, now a foreign Government, takes action calculated to bring on a hostile collision with the Government to which we owe our allegiance, that it does
                not take a course that is offensive? Where is the logic of such a proposition as that? Sir, I especially avoided the employment of any phraseology as regarded either party, because I did not design to use language to which either could take offence. I desired to say, in the name, and on behalf of Virginia, occupying the proud position of attempting to reconcile the difficulties of
                the nations now attempting to perpetuate the blessings of republican liberty, now standing between these contending parties, separated between them, holding them on either side, and telling them in respectful terms, that "the cause of peace, happiness, truth, justice, religion and piety, demands that you shall not, if you can avoid it, come in hostile collision."
              </p>
              <p className="p-in-sp">
                This, sir, is the language of the resolution. The truth of it, the piety of it, the accuracy of it, no man can deny. I deem it of high, essential importance that we should say to the Government and say to the <PageNumber num={505} /> Confederated States, in the same terms, "we are now employing all our means to preserve peace between you." Yes, sir, we
                are mediators, and if ever the power of mediation crowns the name of mortal man, a lustre will be added to the name and glory of Virginia, if by this mediation she can preserve peace, and perpetuate the blessings which have been so long showered upon the country. I appeal to this Convention to adopt this amendment, which simply calls upon both these parties to do no act calculated to
                bring on civil war, pending our efforts to adjust our difficulties. I trust it will be the pleasure of the Committee to accept the amendment.
              </p>
              <div className="speaker" id="sp3010"><span className="persName">Mr. DORMAN</span>—</div>
              <p className="p-in-sp">
                I desire to understand the effect of the motion now before us. The gentleman from Halifax has moved to strike out the whole section. The gentleman from Richmond has moved to strike out and insert, which is, in fact, a motion to insert. I wish to know if we adopt the amendment of the gentleman from Richmond, if it will then be competent to amend the section further?
              </p>
              <div className="speaker" id="sp3011"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">It will not. It is competent to amend the amendment of the gentleman from Richmond now.</p>
              <div className="speaker" id="sp3012"><span className="persName">Mr. DORMAN</span>—</div>
              <p className="p-in-sp">I do not wish to interfere with the gentleman's amendment, but I would suggest that he change the phraseology, so as to make it read, "pending the efforts contemplated by these resolutions."</p>
              <p className="p-in-sp">I would inquire of the gentleman if that would be satisfactory to him?</p>
              <div className="speaker" id="sp3013"><span className="persName">Mr. JOHNSON</span>, of Richmond—</div>
              <p className="p-in-sp">There are a great many efforts to be made. I hope my friend will not be fastidious.</p>
              <div className="speaker" id="sp3014"><span className="persName">Mr. DORMAN</span>—</div>
              <p className="p-in-sp">There is another suggestion I wish to make—the same which was this morning suggested to the original resolution—which is, to substitute "people of Virginia" for the word "them."</p>
              <div className="speaker" id="sp3015"><span className="persName">Mr. JOHNSON</span>—</div>
              <p className="p-in-sp">I will suggest to the gentleman that the connection is now entirely different, and I think, upon reflection, he will see that the word "them" is not susceptible of the criticism made this morning.</p>
              <div className="speaker" id="sp3016"><span className="persName">Mr. DORMAN</span>—</div>
              <p className="p-in-sp">I move to amend the substitute of the gentleman from Richmond, by striking out the word "them" and inserting "themselves." I think gentlemen will see the same objection to the use of the word "them" which was urged this morning.</p>
              <div className="speaker" id="sp3017"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">do not see that "themselves" makes it any better. The amendment to the amendment was disagreed to.</p>
              <p>The amendment of <span className="persName">Mr. JOHNSON</span> was adopted.</p>
              <PageNumber num={506} />
              <p>The question then occurred on striking out the whole section as amended, on which the yeas and nays had been ordered.</p>
              <p>The question was taken, and it was decided in the negative, yeas 39, nays 86, as follows :</p>
              <p>YEAS—Messrs. Ambler, J. Barbour, Blakey, Boisseau, Borst, Bouldin, Branch, Bruce, Cabell, Cecil, Conn, Flournoy, Graham, Gregory, J. Goode, T. F. Goode, C. Hall, Harvie, Holcombe, Hunton, Isbell, Kent, Lawson, Leake, J. B. Mallory, Morris, Morton, Neblett, Richardson, Seawell, Slaughter, Speed, Strange, Sutherlin, Thornton, Tredway, Williams, Wise, Wysor-39.</p>
              <p>NAYS-Messrs. Janney [President]
                , Armstrong, Aston, Baldwin, A. M. Barbour, Baylor, Berlin, Blow, Boggess, Boyd, Brent, Brown, Burdett, Burley, Byrne, Campbell, Carlile, Carter, Chapman, Coffman, C. B. Conrad, R. Y. Conrad, Couch, Critcher, Custis, Dent, Deskins, Dorman, Dulany, Early, Echols, French, Fugate, Garland, Gillespie, Gravely, Gray, Goggin, Hale, A. Hall, E. B. Hall, L. S. Hall, Haymond, Hoge, Hubbard,
                Hughes, Jackson, M. Johnson, P. C. Johnston, Lewis, McComas, McGrew, Macfarland, C. K. Mallory, Marshall, Marr, Marye, Masters, Moffett, Moore, Orrick, Osburn, Parks, Patrick, Pendleton, Porter, Price, Pugh, Rives, R. E. Scott, Wm. C. Scott, Sharp, Sheffey, Sitlington, Spurlock, Staples, C. J. Stuart, Summers, Tarr, Tayloe, Waller, White, Whitfield, Wickham, Willey, Wilson-86.
              </p>
              <p>So the motion to strike out was disagreed to.</p>
              <p>During the call of the roll <span className="persName">Mr. MILLER</span> said he had paired off with <span className="persName">Mr. CLEMENS</span>.</p>
              <p>The resolution as amended was then adopted.</p>
              <p>The 14th resolution was then taken up and read as follows :</p>
              <p>
                "The peculiar relations of the States of Delaware, Maryland, Virginia, North Carolina, Tennessee, Kentucky, Missouri and Arkansas to the other States, make it proper, in the judgment of this Convention, that the former States should consult together and concert such measures for their final action as the honor, the interests and the safety of the people thereof may demand, and for
                that purpose the proper authorities of those States are requested to appoint commissioners to meet commissioners to be appointed by this Convention on behalf of the people of this State, at Frankfort, in the State of Kentucky, on the last Monday in May next."
              </p>
              <div className="speaker" id="sp3018"><span className="persName">Mr. FLOURNOY</span>—</div>
              <p className="p-in-sp">
                I think, Mr. Chairman, that it will be more appropriate to act on this resolution at the end of the series of amendments proposed to the constitution, and I suggest that, with that view, we pass it by for the present. There is some division of opinion in this body on the subject of the Border Conference. Passing this by for the present will have no effect on its ultimate fate, but
                will put it, I think, in the right position, whatever the action of the Convention on the <PageNumber num={507} /> constitutional amendments may be. I, .therefore, move to pass it by, for the present, till we shall have acted on the amendments proposed.
              </p>
              <div className="speaker" id="sp3019"><span className="persName">Mr. R. Y. CONRAD</span>—</div>
              <p className="p-in-sp">I do not see the necessity or propriety of this motion, and shall cast my vote against it unless some reason satisfactory to my mind be given for it.</p>
              <div className="speaker" id="sp3020"><span className="persName">Mr. FLOURNOY</span>—</div>
              <p className="p-in-sp">
                This 14th resolution alludes to amendments to the Constitution, which are not yet agreed upon; and it certainly appears to me that it would be more appropriate that the invitation to our sister border States—if that be the final determination of the Convention—should follow such propositions as we purpose to submit to their consideration. We are now taking action in regard to the
                border Conference, in advance of any amendments that we propose to submit, to be concurred in as propositions to be laid before the North. I speak more in regard to the order of the resolution than to anything else. The amendments to the Constitution, which this Convention may propose, may be such as that we should regard it as unnecessary to hold a Conference about them. They may,
                however, be such as, in the estimation of some, will make a border Conference the more necessary. This question can come up for our consideration after the amendments shall have been agreed upon, and we can then better discuss and decide upon its propriety. I, therefore, propose to pass by this resolution for the present. That does not hazard or defeat the proposition for a border
                Conference.
              </p>
              <div className="speaker" id="sp3021"><span className="persName">Mr. ROBERT Y. CONRAD</span>—</div>
              <p className="p-in-sp">I think if the gentleman will look at the 14th resolution he will find—</p>
              <div className="speaker" id="sp3022"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The gentleman from Frederick is not in order.</p>
              <p className="p-in-sp">He has had one opportunity of addressing the Chair on this subject.</p>
              <div className="speaker" id="sp3023"><span className="persName">Mr. ROBERT Y. CONRAD</span>—</div>
              <p className="p-in-sp">I did not hear any reason for it till now.</p>
              <div className="speaker" id="sp3024"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">If there be no objection, the gentleman will proceed.</p>
              <p>No objection was heard.</p>
              <div className="speaker" id="sp3025"><span className="persName">Mr. ROBERT Y. CONRAD</span>—</div>
              <p className="p-in-sp">My purpose was merely to point out to the gentleman from Halifax that he is mistaken in supposing that this resolution refers to any amendments proposed to the Constitution.</p>
              <div className="speaker" id="sp3026"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">I object to the gentleman proceeding out of order.</p>
              <div className="speaker" id="sp3027"><span className="persName">Mr. ROBERT Y. CONRAD</span>—</div>
              <p className="p-in-sp">I hope the Committee will vote down the motion.</p>
              <div className="speaker" id="sp3028"><span className="persName">Mr. FLOURNOY</span>—</div>
              <p className="p-in-sp">I hope the gentleman from Amelia will withdraw his objection.</p>
              <p>The objection was not withdrawn.</p>
              <PageNumber num={508} />
              <p>The question was taken on <span className="persName">Mr. FLOURNOY</span>'S motion and it was not agreed to.</p>
              <div className="speaker" id="sp3029"><span className="persName">Mr. ROBT. E. SCOTT</span>—</div>
              <p className="p-in-sp">
                Mr. Chairman, it will be recollected that some days since, I gave notice of my intention to move an amendment to this resolution. We have adopted a number of resolutions expressive of our opinions in regard to many of the important issues that are now before the country, and we propose to follow these resolutions by certain amendments to the Constitution of the United States to be
                submitted for the consideration and adoption of the other States. This resolution proposes to call, in advance, a consultation of the border slave States. But no where have we indicated that final action which we contemplate in the event of those propositions being rejected by the free States. In that respect, it seems to me, our work is deficient. I think we ought to indicate, to
                some extent, the nature of that action which we propose finally to take, if those propositions shall be rejected.
              </p>
              <p className="p-in-sp">
                I indicated, sir, according to my recollection, that I would propose to substitute the matter of the amendment, which I caused to be laid on your table, in place of the latter branch of this resolution. Finding that some gentlemen were indisposed to forego any part of this 14th resolution, I have concluded to modify my amendment, and to move it as an addendum to the section—the
                modification being nothing more than such as is necessary to conform with this arrangement. I am, myself, strongly in favor of this Border Conference. I think it important. Nay, I think it essential that these border slave States, which stand together on a common platform, ought, at as early a day as practicable, to be brought into consultation. That opinion, Mr. Chairman, I take
                this occasion to say, is strengthened by the circumstances which now surround us. I think the events of every day, nay, almost every hour, make it more and more important that this Conference should be had. I cannot conceive it to be right that one of these border slave States should precipitate itself into final action in regard to matters which interest all alike, without a
                consultation, without a concert of action—so that I am well disposed to leave the matter of this resolution entire. I therefore offer this amendment, as an addition to the 14th resolution :<span className="note" id="Note59"><span className="noteLabel">12</span>This version of the amendment varies slightly from that given on page 501, which agrees with the text in the Journal.</span>
              </p>
              <p className="p-in-sp">
                "And in the event that favorable responses on the part of the non slaveholding States, be not made to the proposed amendments of the Constitution by the time appointed for the re-assembling of this body, it is the opinion of this Convention that the said States of Delaware, Maryland, North Carolina, Tennessee, Kentucky, Missouri and Arkansas,
                <PageNumber num={509} /> ought to assemble in primary Conventions and in conjunction with this State, convene at , on the day of , a Congress of the said States, composed of delegates appointed by the respective Conventions thereof, for the purpose of recommending an amended Constitution of government, upon which the Union of all those States and the
                Confederate States, with such of the non-slaveholding States as may concur therein, can be safely effected, to which Congress, the Confederate States and the concurring non-slaveholding States ought to be invited to send Commissioners."
              </p>
              <p className="p-in-sp">
                The Committee will perceive, sir, that the contingency on which this conference is proposed to be held, is when our proposition to be addressed to the non slaveholding States, shall be rejected by them. I propose, in that event, to convene a Congress of the border slave States, to be composed of delegates to be appointed by primary Conventions of those States—a Congress which will
                necessarily be commissioned to deal authoritatively with the questions that will present themselves for consideration. I propose that these border States shall assemble themselves in Convention, so that they will be in a condition of readiness to act finally upon the propositions which may emanate from that Convention. It contemplates the possibility, that though these propositions,
                or some of them, may be rejected by some of the free States, and possibly by a majority of them, yet there may be a portion of those States that will be found willing to concur in the movement, and that such States shall be represented by Commissioners at the Congress. It contemplates, too, the like invitation to the Confederate States; so that it will be perceived that if this
                scheme is carried out, we will then be in a condition at once to associate ourselves with the Confederate States, if forms of association can be agreed upon, and at the same time, with such of the border States as may concur in the movement.
              </p>
              <div className="speaker" id="sp3030"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I would like to have the attention of the gentleman from Fauquier [<span className="persName">Mr. SCOTT</span>] , so that he may answer me.</p>
              <p className="p-in-sp">
                I would like to know how many assemblages of States we are to have under this resolution as it is proposed to be amended? Let us count, sir. We are first to call together all these eight border States to "consult together and concert such measures for their final action as the honor, the interests, and the safety of the people thereof may demand, and for that purpose the proper
                authorities of those States are requested to appoint Commissioners to meet Commissioners to be appointed by this Convention on behalf of the people of this State, <PageNumber num={510} /> at Frankfort, in the State of Kentucky, on the last Monday in May next."
              </p>
              <p className="p-in-sp">There are two months of time before the Commissioners are to meet. These Commissioners, I suppose, would be in consultation at least some two or three months more.</p>
              <p className="p-in-sp">What next? We have, then, to wait until the fall to hear from our propositions, here now separately made, to know whether they accept our ultimata or not. That brings us to next fall.</p>
              <p className="p-in-sp">
                Well, what next are we to do? To hold a Convention—a primary Convention. I don't understand what is meant by the words primary Convention? Is this a separate Convention proposed in each State? Is that what is meant by "primary Convention"? Then, I understand, it is not a primary assembly of the people ; but they are to meet, as we meet, in convention, regularly organized, I suppose.
                Thus we shall have the ordeal of the people of these States meeting in primary convention—calling all the States, as I understand, to meet in Conventions; then the border slave States consulting together, and holding a commission at Frankfort, the last Monday in May next, and then, waiting until fall comes, to hear from all the non-slaveholding States sitting here to judge and debate
                whether their answers are satisfactory or not—we are then, after all this delay, to begin again and call a primary Convention of the border States, who are to form a Constitution of Government to be submitted—am I right, sir?
              </p>
              <p><span className="persName">Mr. SCOTT</span> nodded—whether in assent or dissent the reporter could not say.</p>
              <div className="speaker" id="sp3031"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">To be submitted to all the States, to the non-slaveholding States as well as the border States. Will you tell me if this is not going through all the mazes and meandering of Commissions and of primary Conventions, to get at last—it might as well be avowed at first—if I understand my friend from Fauquier [<span className="persName">Mr. SCoTT</span>]
                ,—to get at last at a National Convention. Sir, it terminates in that and nothing short of it, but not in a short time. It is to take a very round about way, with every sort of machinery to be employed, at last to get us into a National Convention—to get border States and non-slaveholding States in a grand National Convention to decide our fate. Now, I ask my friend from Fauquier
                [<span className="persName">Mr. SCOTT</span>]
                , in what, if in any respect, this differs from the suggestion made in the celebrated speech of William H. Seward, of a delay "of one, two, or three years," and at last a National Convention? Why, sir, it is his proposition, not stated as clearly, with a great deal more machinery; and, in my opinion, it will require a great deal more time. It will at least require
                <PageNumber num={511} /> eighteen months to go through all this delay. Why employ all this machinery? Why not ask the border States at once to come together, and, if you mean to propose a National Convention, if you mean to call upon the non-slaveholding States, do it at once, and let them answer us face to face? And then we will know, about the time
                when this Convention meets again—eye to eye, and toe to toe, in the presence of each other—what is their intention. If you are determined upon calling a National Convention, do it at once. It can be done in a short time, and without all this machinery. If this is to be done, I would like to see it called, not under injunctions of secrecy, like the "Peace Conference" when we have had
                our own Commissioners on this floor ; but in open Convention. Let it be known what the Salmon P. Chase's will say; let it be clear and let it be open. I see no necessity for employing all this machinery to get up this Commission at Frankfort. I doubt whether we have got time before the last Monday in May to call the eight States together. How long are they to sit to form a
                Constitution to submit to the other States? How long will it take to assemble the other States in primary Conventions? Why, until next fall ; and it is next fall you are to call this Convention together to receive the answers of these slaveholding States.
              </p>
              <p className="p-in-sp">Will the Clerk read the latter part of that amendment?</p>
              <p>The Clerk read, as follows :</p>
              <p>
                "And in the event that favorable responses on the part of the nonslaveholding States be not made to the proposed amendments to the Constitution, by the time appointed for the re-assembling of this body, it is the opinion of this Convention that the said States of Delaware, Maryland, North Carolina, Tennessee, Kentucky, Missouri and Arkansas ought to assemble in primary conventions,
                and in conjunction with this State convene at on the day of , a Congress of the said States composed of delegates to be appointed by the respective conventions thereof for the purpose of recommending an amended Constitution of Government upon which the Union of the States and the Confederated States with such of the non-slaveholding States as may concur therein can be safely
                effected; to which Congress, the Confederated States and the concurring non-slaveholding States ought to be invited to send Commissioners."
              </p>
              <div className="speaker" id="sp3032"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                Now, sir, am I not right in saying that this is a call for a national Convention; to form a Constitution first by a convention of the border States, and then to submit that to a convention of the Confederate States, and then to submit it to a convention of <PageNumber num={512} /> the non-slaveholding States? Is this not a National Convention? If it is
                not, I am very much mistaken. Whether it be or not, it will undoubtedly take more time than this Commonwealth will wait. It is too conformable to the counsel given by that arch enemy of the South, Wm. H. Seward, for me to touch it, sir, with a pair of tongs.
              </p>
              <div className="stage it">[Laughter.]</div>
              <div className="speaker" id="sp3033"><span className="persName">Mr. SCOTT</span>, of Fauquier—</div>
              <p className="p-in-sp">I am sure if the gentleman from Princess Anne understood the proposition, he would have forborne many of the remarks that he has submitted to the Committee. I beg leave again to explain the amendment, with as much distinctness as I can.</p>
              <p className="p-in-sp">
                As the resolution stands, we have agreed to sundry propositions, announcing principles. This fourteenth resolution proposes to convene at Frankfort, in the month of May, a consultation of the slave border States, but there is nowhere indicated what action, in our judgment, that consultation ought to result in. The purpose of this amendment is, in part, to indicate that action. If we
                adopt the fourteenth resolution, as I trust we will, we send commissioners to Frankfort without any instructions, without any indication on our part as to that final action which they should recommend. We send them, in other words, without instructions, and without any indications as to the line of policy which they should pursue.
              </p>
              <p className="p-in-sp">Now, it is the single purpose of the amendment to supply that deficiency—to indicate upon our part the action which is proper to be recommended to the other border States.</p>
              <p className="p-in-sp">
                The gentleman says it is nothing more than a National Convention—it is too much like what Mr. Seward has recommended. This ghost of the National Convention seems to pursue the gentleman from Princess Anne, go where he may. In what sense a Congress, composed of the border slave States to consult upon the action which they shall take in the event of the rejection by the free States of
                our propositions, can be considered as a National Convention, remains for the astuteness and the ingenuity of the gentleman from Princess Anne to discover. The proposition is, sir, to convene a Congress simply of the border slave States in order that they may determine in the event contemplated the final action proper for them to take.
              </p>
              <p className="p-in-sp">
                Sir, when our propositions come back rejected, what are we to do? What is Virginia to do? To act without consultation with her sister border States and attempt to solve these great difficulties with her unaided strength? If not, sir, she must have consultation. If concert of action is proper and necessary for her safety, she must have previous consultation. The amendment in no way
                interferes with the <PageNumber num={513} /> scheme of this report, but it is to indicate on the part of this Convention, that when this consultation is held the scheme of the separate Border Slave State Convention shall be recommended, the purpose of which is to place these border States in a position where they can act definitely, finally upon the
                questions that shall be there presented
              </p>
              <p className="p-in-sp">the proposition is to convene a Congress when we have received unfavorable responses from the North—when that event happens, to decide what is to be done.</p>
              <p className="p-in-sp">
                I desire to know, when that event happens, what gentlemen propose to do. If they propose to precipitate this State alone into the vortex of secession, I am here to say that I shall oppose it. I am opposed now, unalterably opposed to any act of separate secession on the part of Virginia. Her interest, her safety, her security in my judgment demand concert of action with her sister
                border States. The amendment proposes to secure that concert of action, and to secure it at a time when final action will be necessary, and to secure it in a manner that will make that action effective.
              </p>
              <div className="speaker" id="sp3034"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Suppose the border States refuse?</p>
              <div className="speaker" id="sp3035"><span className="persName">Mr. SCOTT</span>—</div>
              <p className="p-in-sp">Then, if the border States refuse, we must submit to their refusal and govern ourselves according to the exigencies of the case.</p>
              <p>Here the hammer fell.</p>
              <div className="speaker" id="sp3036"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">I move to amend by filling up the blank with the word "Frankfort."</p>
              <div className="speaker" id="sp3037"><span className="persName">Mr. SCOTT</span>, of Fauquier—</div>
              <p className="p-in-sp">
                As the resolution is prepared, it contemplates fixing the time and place for the assemblage of this Congress. If the Convention prefer to indicate now the time and place for holding the Convention, they have only to fill those blanks. If they prefer to leave the time and place to be fixed hereafter, that portion of the resolution may be stricken out. The gentleman from Princess Anne
                seemed to apprehend that it is a part of the policy to protract, or if not the policy, that it would be the effect of these amendments to protract the settlement beyond a reasonable time. I do not know, sir, from what part of the amendment he derives that impression. By the resolutions, as they stand, I presume some time is to be given to the non-slaveholding States to consider the
                propositions which we submit to them. I have myself indicated no time, the report of the Committee indicates no time, but the time remains to be fixed by this Convention, and if it be protracted beyond a reasonable time, it will be so protracted by the action of this body. Let the time be fixed when it may, the amendment which I propose provides that
                <PageNumber num={514} /> when that time arrives, this Congress shall assemble. They may fix it in August, as has been proposed; they may fix it in September, or October, or whenever they choose. The amendment I propose contemplates the assembling of this Congress when that time shall arrive. And upon what authority the gentleman from Princess Anne
                assails it, because it contemplates unreasonable delay, I am at a loss to know. I am free to avow that my purpose is to put this State, and her sister border slave States in a position, when the time shall arrive, for cutting themselves loose from this Union, and, discarding the obligations of this government, it will be in their power to do so, if such shall be their judgment. There
                is nothing in the amendment that points to such action, but it leaves the border States free, when they come to consult together, to determine without embarrassment, upon the course proper for them to pursue. I do not see why any gentleman of any party, or of any opinion, in this body should dissent from the proposition. If his object be for re-constructing the Union, if his object
                be for adjusting these difficulties in the Union, and for re-constructing upon the present Federal Constitution—there will be opportunity to do it. If he is for secession, for withdrawing this State from her connection with the free States—if he is for association with the Southern States—then will be the time, and then the opportunity to accomplish that object; so that I cannot
                conceive upon what reason it is that gentlemen of any opinion or any shade of opinion, in this body, can dissent from it. Only those, perhaps, can find reason to object who still insist upon separate secession. If the secessionists will give up their hopeless scheme of separate State action and unite upon this amendment, they will, within a reasonable time, put this State in a
                position to enable her to secede from this Union, if upon any contingency she will secede. There is no delay contemplated. There is no time fixed, but the time remains to be fixed hereafter. I take it for granted that a reasonable time will be given to the other States for the consideration of the propositions that we mean to submit to them. What that reasonable time is, the judgment
                of this Committee must determine. Whenever that time is fixed, the amendment proposes, in the event of an unfavorable reception of the proposition submitted by us, that this machinery shall be set on foot which will be effectual, I trust, to unite all the border States in concerted action and put it in their power, finally and decisively, to determine the destiny of these States.
              </p>
              <p><span className="persName">Mr. BALDWIN</span>, with the leave of the Committee, withdrew the amendment.</p>
              <PageNumber num={515} />
              <div className="speaker" id="sp3038"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">I propose to amend by striking out of the proposition so much as fixes the time and place.</p>
              <p className="p-in-sp">
                The difficulty, Mr. Chairman, that has been encountered by every Union man in this body, so far as my observation has extended, in his intercourse with the secessionists, is chiefly this : These gentlemen insist upon knowing from us, "what do you intend to do now?" And when we tell them what our plan is—"what do you intend to do then?" And so on, to the end of the chapter.
              </p>
              <p className="p-in-sp">Now, sir, until this suggestion was made by the gentleman from Fauquier [<span className="persName">Mr. SCOTT</span>]
                , there was nothing in the programme of action proposed by the Union men of this body that enabled us to answer these questions to the end of the chapter. When we said to them, "We will have a Border Conference of the slave States yet remaining in the Union to take counsel together, and to go with united voice to the people of the South,<span className="note" id="Note60"
                ><span className="noteLabel">13</span>Mr. Baldwin must have meant to say "North."</span
                >
                demanding amendments to the Constitution, such as will guarantee to us all our rights"—the question was asked us, "suppose they refuse you your rights—suppose they deny you the amendments which you think essential for your protection, what then?" Until this suggestion was made by the gentleman from Fauquier
                [<span className="persName">Mr. SCOTT</span>]
                , we were bound to say—"sufficient unto the day is the evil thereof ;" when the refusal comes, the Convention will be in session and can meet the crisis as it arises and deal with it according to circumstances. For my part, until this suggestion was made by the gentleman from Fauquier, I preferred to reserve the determination of that question until the time for action should arrive;
                but this proposition made by the gentleman from Fauquier gives, to my mind, a satisfactory solution of the question, and I give it my most hearty and cordial assent. Sir, it answers the question as to what we will do in the event that the amendments which we demand of the Northern people are refused. The answer is, that we will not undertake, as is suggested by the separate State
                secessionists, to launch Virginia out by herself into this extreme of separate State action; but that we will seek co-operation with the border slave States yet remaining in the Union, who are identified with us in interest, in fortune, and in grievances. We will take counsel with them, and cooperate with them for the protection of those common interests and common rights, and for
                the redress of those common grievances. That, sir, I think, indicates the true policy to be pursued. If there is a State in this Union that is bound to pursue this policy of co-operation, it is Virginia. She is bound, not merely to co-operate with the slave States yet remaining in the Union, with North Carolina on the South,
                <PageNumber num={516} /> Tennessee and Kentucky on the West, and Maryland upon the North. She is bound, not only to seek co-operation with them ; but she is bound to seek co-operation, if possible, with Pennsylvania, Ohio, Indiana, and Illinois.
              </p>
              <p className="p-in-sp">
                I need not tell our Western friends upon this floor that the business relations of one half of this Commonwealth are with the free States upon our border. The people of Western Virginia along our Western and Northern border, would be in the most hopeless and helpless condition if it should happen to them that they are to be separated from the Border Free States. As was well remarked
                by a gentleman the other day upon this floor, they would be in as hopeless a condition as a rabbit shut up in his hole—there would be no escape for them.
              </p>
              <div className="stage it">[Here the Chairman's hammer fell.]</div>
              <div className="speaker" id="sp3039"><span className="persName">Mr. MOORE</span>—</div>
              <p className="p-in-sp">I shall move that the Committee do now rise. I feel very anxious to understand the proposition of the gentleman from Fauquier [<span className="persName">Mr. SCOTT</span>] . As far as I do understand it, I am strongly inclined to be in favor of it, but I do not like to be hurried into a vote without a full opportunity of understanding the proposition.</p>
              <div className="speaker" id="sp3040"><span className="persName">Mr. BRUCE</span>—</div>
              <p className="p-in-sp">I have an alternate proposition which I would like to be placed alongside that of the gentleman from Fauquier [Mr. SCOTT] .</p>
              <div className="speaker" id="sp3041"><span className="persName">A MEMBER</span>—</div>
              <p className="p-in-sp">Print them.</p>
              <div className="speaker" id="sp3042"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Committee has not the power to order printing to be done.</p>
              <p>The Committee then rose, and the Chairman, <span className="persName">Mr. SOUTHALL</span>, reported progress.</p>
              <div className="speaker" id="sp3043"><span className="persName">Mr. MARR</span>—</div>
              <p className="p-in-sp">
                Mr. President, I rise to a privileged question, merely to make an explanation. In the report of the debates of this Convention in the Richmond "Enquirer," which publishes officially the proceedings of this body, I am made on last Saturday to make a speech. I desire the reporter to make the correction. I made no speech. The remarks purporting to be made by me, were made by the
                delegate from the county of Franklin
                [<span className="persName">Mr. EARLY</span>] . And, in addition, I desire to state that, if I had made a speech on that occasion, I would have made one directly opposite to the one to which my name is prefixed.</p>
              <div className="speaker" id="sp3044"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">
                The remarks attributed to the gentleman from Fauquier, were made by myself. It so happens that the remarks were correctly reported, but they were attributed to the wrong person. I think the gentleman would be very glad if he had really delivered them. I would also state, however, that the remarks which I made in regard to the proposition to amend the 8th resolution, are entirely
                <PageNumber num={517} /> misreported. The point I intended to make is not at all made in the remarks. I have submitted to the editor a written statement of what I did say, and will not further allude to the matter now.
              </p>
              <div className="speaker" id="sp3045"><span className="persName">Mr. MARR</span>—</div>
              <p className="p-in-sp">I have only to state, in answer to the remark made by the gentleman from Franklin, that whilst he thinks I might have considered myself happy—I think I caught that word—to have made that speech, I have to say, that my constituents would have thought that I had misrepresented them in this Convention.</p>
              <div className="speaker" id="sp3046"><span className="persName">Mr. WILLEY</span>—</div>
              <p className="p-in-sp">Mr. President, I hope it will be the pleasure of the Convention—</p>
              <div className="speaker" id="sp3047"><span className="persName">Mr. SCOTT</span>, of Fauquier—</div>
              <p className="p-in-sp">Mr. President, some desire has been expressed that the amendment which I offered, and the amendment offered by the gentleman from Halifax will be ordered to be printed.</p>
              <div className="speaker" id="sp3048"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The order will be made without taking the question upon it.</p>
            </div>
            <div className="section" id="vsc1965.v3.2.8.4">
              <h3><span className="headingNumber">1.8.4. </span><span className="head">THE QUESTION OF TAXATION</span></h3>
              <div className="speaker" id="sp3049"><span className="persName">Mr. WILLEY</span>—</div>
              <p className="p-in-sp">I hope now, sir, it will be the pleasure of the Convention to dispose of the matter which was under consideration when we went into Committee of the Whole this morning.</p>
              <div className="speaker" id="sp3050"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The demand for the previous question was seconded this morning, and upon the question: Shall the main question be now put? the ayes and noes were ordered. Gentlemen, those who are of opinion the main question should be now put, will, as your names are called, answer aye—</p>
              <div className="speaker" id="sp3051"><span className="persName">Mr. PATRICK</span>—</div>
              <p className="p-in-sp">I understood that the gentleman who called for the ayes and noes on the main question afterwards withdrew the motion.</p>
              <div className="speaker" id="sp3052"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair is not aware of it. There is no record of the withdrawal. The previous question was demanded upon the amendment of the gentleman from Gloucester [<span className="persName">Mr. SEAWELL</span>] .</p>
              <div className="speaker" id="sp3053"><span className="persName">Mr. HALL</span>, of Wetzel—</div>
              <p className="p-in-sp">At the time the Convention went into Committee of the Whole this morning, I was on my feet, endeavoring to obtain the floor, to speak upon this question. I believe I have ten minutes?</p>
              <div className="speaker" id="sp3054"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">Does the gentleman desire to speak ten minutes upon the order for the previous question?</p>
              <div className="speaker" id="sp3055"><span className="persName">Mr. HALL</span>, of Wetzel—</div>
              <p className="p-in-sp">Yes, sir. Mr. President—</p>
              <div className="speaker" id="sp3056"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">I rise to a question of order.</p>
              <div className="speaker" id="sp3057"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The gentleman will state his point of order.</p>
              <div className="speaker" id="sp3058"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">The point I make is this : The 33rd rule provides for ten minutes discussion upon a call for the previous question.</p>
              <PageNumber num={518} />
              <p className="p-in-sp">
                According to my understanding of that rule the opportunity for that ten minutes' discussion must be availed of before the call is seconded ; for it is for the purpose of assigning reasons why the call should not be seconded and the previous question sustained that the discussion is allowed. My point of order is, that after the call has been seconded no discussion is in order.
              </p>
              <p>The CLERK was directed to read the 33rd rule as follows :</p>
              <p>
                "Upon a call for the previous question, no member shall speak more than ten minutes. Unless such call be seconded by twenty (20) members, indicated by rising, the President shall put no question ; but if so sustained, a question shall be immediately put by the Chair, to this effect : 'Shall the main question be now put?' If on this question a majority of the House vote in the
                affirmative, the Chair shall thereupon put the pending question, and all incidental questions of order arising after a motion is made for the question, and pending the motion, shall be decided, whether an appeal or otherwise, without debate."
              </p>
              <div className="speaker" id="sp3059"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">
                The Chair will state the reasons why he adheres to the opinion that debate is now in order. And first upon the reason of the rule. Where a demand is made for a previous question, if the debate upon it is then permitted to go on for the ten minutes, we have it upon us infallibly, and it may occupy the entire day, and it may turn out at the end of the debate, that when the question is
                propounded, whether the previous is seconded or not, twenty members may not rise in support of it. That is one ground why debate is allowed upon the reason of the case. But this rule emphatically declares, that until the previous question has been seconded, no debate shall take place. There is no question put at all until the previous question shall have been seconded; and if no
                question has been put, or can be put, until after the call for the previous question has been seconded, to what debate can this rule refer authorizing ten minutes debate? These are the reasons why the Chair comes to the conclusion that debate is now in order. It appears to me what the Vice-President would call inexorable logic. If there is an appeal for the decision of the Chair, the
                House will decide it. Is there an appeal?
              </p>
              <p>SEVERAL MEMBERS NO, no.</p>
              <div className="speaker" id="sp3060"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">Then debate is in order.</p>
              <div className="speaker" id="sp3061"><span className="persName">Mr. HALL</span>, of Wetzel—</div>
              <p className="p-in-sp">
                I do not know, Mr. President, that I shall occupy the ten minutes to which I am entitled. I have said nothing upon this question. Being a Western man, being identified with, and having at heart Western interests as well as the interests of every part <PageNumber num={519} /> of the great Commonwealth of Virginia, I hope that the Convention will indulge
                me whilst I give a few reasons which, in my judgment, should induce every man in the Commonwealth of Virginia to vote for the proposition to appoint a Committee to enquire into the propriety of amendments to the constitution of the State. I appeal to my Eastern friends and say to them that this is an important matter it is a matter that I have at heart; and I do hope that they will
                divest themselves of all selfishness, if they should happen to have any, on the subject, and at once look at the justice and equity of our demands, and yield a cheerful obedience to our requests. What, I would ask, Mr. President, has produced this collision in the country? For what purpose are we here? For what purpose—
              </p>
              <div className="speaker" id="sp3062"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair will remind the gentleman that while it is not out of order to allude to the main question, the discussion should be confined principally to the propriety of putting at this time the main question.</p>
              <div className="speaker" id="sp3063"><span className="persName">Mr. HALL</span>, of Wetzel—</div>
              <p className="p-in-sp">
                It is with that view I am now arguing—to show to Eastern gentlemen, and to all—because I don't admit that there are any sectional or conflicting interests in the Commonwealth of Virginia; and it is with a view of calling the attention of gentlemen, fr om every part of Virginia, in this Convention, to the subject in the light in which I view it, that I am endeavoring to induce them to
                vote for the proposition, upon the call of the previous question. Now, sir, I lay down this as a proposition : that Western Virginia is more interested, or will become more interested in the institution of slavery than Eastern Virginia; that Western Virginia has a greater interest, or will have when we get this change made which we contemplate, in the institution of slavery than
                Eastern Virginia. Why, sir? Why do I state that, sir? In Eastern Virginia the property consists principally of slaves; the aggregate value of that species of property is four hundred millions of dollars. Under the provisions of the present constitution the more slaves there are, the more one dollar and twenty cents we get; but if we should have them taxed ad valorem, as other
                property, then we are interested both in their number and in their value. We have forty millions of debt that we have to shoulder, and besides that the expenses of the State government to meet. The slave owners of the State can very easily transfer their slaves from Virginia to the Confederate States and employ them as profitably (or perhaps more so) than they do in Virginia and
                would lose nothing by the transfer, while we of the West, if the slave property of the State ceases to be a basis of taxation, then the whole burthen of the State debt falls upon <PageNumber num={520} /> the property of the West, which would more than double our burthens and we would have to pay for the improvements of the East; when very little of the
                appropriations that constitute the State debt has been expended in the West, the West would be paying for a dead horse.
              </p>
              <div className="speaker" id="sp3064"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">Shall the main question be now put?</p>
              <div className="speaker" id="sp3065"><span className="persName">Mr. BURDETT</span>—</div>
              <p className="p-in-sp">I withdraw the call for the ayes and noes; I thought I did so this morning.</p>
              <p>The reading of the original resolutions and the proposed amendments was called for, and the Clerk read them as follows : [The following are <span className="persName">Mr. WILLEY</span>'S original resolutions :] "Resolved, That taxation should be equal and uniform throughout the Commonwealth, and that all property should be taxed in proportion to its value.</p>
              <p>"Resolved, That a Committee of thirteen members be appointed to prepare and report to the Convention such alterations of sections 22 and 23 of Article IV, of the Constitution of the Commonwealth, as shall conform said sections to the principle of taxation enunciated in the foregoing resolution.</p>
              <p>"Resolved, That a Committee of thirteen members be appointed to take into consideration so much of Article VI, of the Constitution of this Commonwealth, as relates to the Supreme Court of Appeals, the District Courts, Circuit Courts, and County Courts; and that they report such amendments and modifications thereof as they may deem necessary and proper.</p>
              <p>"Resolved, That the basis of representation in the two Houses of the General Assembly should be the same; therefore, be it further</p>
              <p>"Resolved, That a Committee of twelve members, to be selected in equal numbers from the four great divisions of the State, be appointed to apportion representation in the Senate according to the number of the qualified voters in the Commonwealth, and that they report amendments of the IV. Article of the Constitution accordingly."</p>
              <p><span className="persName">Mr. TURNER</span>, of Jackson, moved to amend the resolutions by striking out the whole and inserting the following :</p>
              <p>
                "1. Resolved, That it is expedient and proper that the 23d section of the 4th Article of the Constitution of this State shall be so modified, that slaves, like other property, shall be taxed without exemption, and according to value, and that no exemption of any property from taxation shall be had without the vote of the majority of all the members elected to each House of the
                General Assembly.
              </p>
              <p>"2. Resolved, That a Committee, to consist of thirteen members, <PageNumber num={521} /> to be selected from the different sections of the State, be appointed, who shall report to this Convention such amendments to the Constitution of the State as will effect the object indicated in the foregoing resolutions."</p>
              <p><span className="persName">Mr. SEAWELL</span> moved to amend the amendment by striking out all after the word "resolved," in the first line, and inserting the following:</p>
              <p>"That a Committee of thirteen be appointed to enquire into and report to this Convention, whether any, and if any, what alterations of sections 22 and 23 of Article four of the Constitution of the Commonwealth are proper to be made at this time."</p>
              <div className="speaker" id="sp3066"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The question is upon the adoption of the amendment offered by the gentleman from Gloucester to the amendment offered by the gentleman from Jackson. Shall the main question be now put upon the adoption of the amendment of the gentleman from Gloucester?</p>
              <p>The question was put to the House and decided in the affirmative.</p>
              <div className="speaker" id="sp3067"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The main question is now ordered upon the adoption of the amendment offered by the gentleman from Gloucester.</p>
              <div className="speaker" id="sp3068"><span className="persName">Mr. SEAWELL</span>—</div>
              <p className="p-in-sp">I call for the ayes and noes.</p>
              <p>The roll was called, and <span className="persName">Mr. SEAWELL</span>'S amendment was rejected ayes 55, noes 65—as follows :</p>
              <p>YEAS-Messrs. Janney [President]
                , J. Barbour, Blakey, Boisseau, Borst, Bouldin, Branch, Brent, Bruce, Cabell, Caperton, Carter, Conn, R. Y. Conrad, Dulany, Flournoy, Garland, Gravely, Gregory, Jno. Goode, Jr., T. F. Goode, Hale, Addison Hall, Harvie, Holcombe, Isbell, Leake, Macfarland, C. K. Mallory, J. B. Mallory, Marshall, Marr, Marye, Moffett, Morris, Morton, Neblett, Nelson, Richardson, R. E. Scott, Wm. C.
                Scott, Seawell, Slaughter, Speed, Staples, Strange, Sutherlin, Thornton, Tredway, Tyler, Waller, Whitfield, Wickham, Williams, Wise-55.
              </p>
              <p>
                NAYS—Messrs. Ambler, Armstrong, Aston, Baldwin, A. M. Barbour, Baylor, Berlin, Boggess, Boyd, Brown, Burdett, Burley, Byrne, Campbell, Carlile, Cecil, Chapman, Coffman, C. B. Conrad, Couch, Dent, Deskins, Dorman, Early, Echols, French, Fugate, Gillespie, Gray, Goggin, Cyrus Hall, Ephraim B. Hall, L. S. Hall, Hammond, Haymond, Hoge, Hubbard, Hughes, Hull, Jackson, Marmaduke Johnson,
                P. C. Johnston, Kent, Lawson, Lewis, McComas, McGrew, Masters, Moore, Orrick, Osburn, Parks, Patrick, Porter, Price, Pugh, Sharp, Sheffey, Sitlington, C. J. Stuart, Summers, Tarr, White, Willey, Wilson-65.
              </p>
              <p>During the calling of the roll,</p>
              <div className="speaker" id="sp3069"><span className="persName">Mr. HUNTON</span> said :</div>
              <p className="p-in-sp">I have paired off upon this question with <span className="persName">Mr. WOODS</span>, of Barbour; otherwise I would vote aye.</p>
              <div className="speaker" id="sp3070"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">I voted for the purpose of killing the proposition, if <PageNumber num={522} /> I could. I will change my vote, as I am told by my friends that I voted wrong.</p>
              <div className="speaker" id="sp3071"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">How does the gentleman wish to vote?</p>
              <div className="speaker" id="sp3072"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">I wish to change it, and to put it on the other side.</p>
              <div className="stage it">[Laughter.]</div>
              <div className="speaker" id="sp3073"><span className="persName">Mr. WILLEY</span>—</div>
              <p className="p-in-sp">I move to amend the amendment of the gentleman from Jackson, by striking it out and inserting in lieu of it, the following :</p>
              <p className="p-in-sp">"Resolved, That a Committee of 13 be appointed to inquire into the expediency of so amending the 22nd and 23d sections of Article 4, of the Constitution of Virginia, as to provide that taxation shall be equal and uniform throughout the Commonwealth, and that all property shall be taxed in proportion to its value."</p>
              <p className="p-in-sp">It will be seen that that provides for a committee, merely to inquire into the expediency. I think it obviates several difficulties to which the amendment of the gentleman from Jackson was liable.</p>
              <div className="speaker" id="sp3074"><span className="persName">Mr. STAPLES</span>—</div>
              <p className="p-in-sp">I wish to inquire of the gentleman from Monongalia, if he offered that in lieu of his original proposition?</p>
              <div className="speaker" id="sp3075"><span className="persName">Mr. WILLEY</span>—</div>
              <p className="p-in-sp">As a substitute for the amendment.</p>
              <p>The amendment to the amendment was agreed to.</p>
              <p>The question then recurred upon the amendment as amended.</p>
              <div className="speaker" id="sp3076"><span className="persName">Mr. WILLEY</span>—</div>
              <p className="p-in-sp">I now desire that there shall be a test question between this amendment as amended, and my original proposition. I call for the yeas and nays.</p>
              <div className="speaker" id="sp3077"><span className="persName">Mr. TREDWAY</span>—</div>
              <p className="p-in-sp">Will it be in order to offer an amendment to the resolution of the gentleman from Monongalia as it now stands?</p>
              <div className="speaker" id="sp3078"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">It is not in order at this time.</p>
              <div className="speaker" id="sp3079"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I do not understand head or tail of what is before this Convention, and I ask the Chairman to state what is the original proposition, what amendment has been adopted, and where we stand —I have heard so much about the original proposition, and about questions of order, and amendments, that I do not know whether I am upon my head or my heels.</p>
              <div className="stage it">[Laughter.]</div>
              <div className="speaker" id="sp3080"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">
                The original resolution was offered by the gentleman from Monongalia. To this resolution an amendment was proposed by the gentleman from Jackson, and to that amendment, an amendment was offered by the gentleman from Gloucester. The amendment of the gentleman from Gloucester was rejected. The gentleman from Monongalia then offered a substitute for the amendment of the gentleman from
                Jackson, which was adopted by the Convention, and now stands as an amendment to the original <PageNumber num={523} /> resolution.
              </p>
              <div className="speaker" id="sp3081"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">And it now comes up as a proposition to amend the original resolution.</p>
              <div className="speaker" id="sp3082"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair so understands it.</p>
              <div className="speaker" id="sp3083"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">And the amendment is amendable.</p>
              <div className="speaker" id="sp3084"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">It is.</p>
              <div className="speaker" id="sp3085"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Well, sir, in order to give us an opportunity of examining it more thoroughly, I move that the Convention adjourn. The motion was disagreed to, ayes 48, noes 57.</p>
              <div className="speaker" id="sp3086"><span className="persName">Mr. WILLEY</span>—</div>
              <p className="p-in-sp">
                The question now comes up between my original resolution and the amendment just adopted. Although I offered that amendment, I did it to make it as acceptable to myself and those who think with me as I could, if the Convention should adopt it in place of the original proposition. I shall now vote against that amendment, and I hope those who think with me will also vote with me, and
                that the original resolution will be adopted.
              </p>
              <div className="speaker" id="sp3087"><span className="persName">Mr. SLAUGHTER</span>—</div>
              <p className="p-in-sp">
                I desire to say only one word upon this amendment. Gentlemen from the West will bear in mind that those from the East have taken no part in this discussion. They had their time taken up with the consideration of more important questions. I, myself, withdrew a motion to lay the subject upon the table, for the purpose of allowing gentlemen from the West to express their views. We have
                no time to consider this subject now, but if gentlemen want a committee to take the matter into consideration, I hope it will be given to them, and let the matter lie over until we meet in the Fall. This is no time for the discussion of such a matter. I repeat, sir, I hope they will be allowed to have their committee, let the committee report, and let us have no further excitement
                upon the subject now. No action can, of course, be had upon it now.
              </p>
              <div className="speaker" id="sp3088"><span className="persName">Mr. PRICE</span>—</div>
              <p className="p-in-sp">I merely desire to say, to explain my vote, I think, the substitute is a fair one, and I shall give it my support.</p>
              <p className="p-in-sp">
                In conversation at an early stage of the session, I stated that I did not desire to probe any of these old wounds. I referred to the question of taxation and to the question of representation. Although I thought the ad valorem principle of taxation was the true one, I did not desire to disturb it for the present, for the reason that I believed we had as much trouble upon our heads as
                we could very well get along with. The substitute, however, proposes to raise a Committee to report to the Convention at a subsequent time, when we may, perhaps, be able to give it a fair consideration. I shall vote for it, therefore, in preference to the original proposition which directs the <PageNumber num={524} /> Committee to report upon this and
                upon other questions to the Convention, and which contemplates action during its present session.
              </p>
              <div className="speaker" id="sp3089"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                I made one or two remarks upon this subject some days ago, and I am well satisfied that I did not go far enough then to explain my position upon this subject, for I tried to be as brief as possible. I stated then, and I repeat now, as to the principle of exemption, and as to this principle of equal, uniform, ad valorem taxation, that you could not wake me up at 6 o'clock in the
                morning, at any time when I would not answer you that I was in favor of that principle, without exception or qualification. But when a set of resolutions are presented at a time, when I believe not a man in this Commonwealth had any idea of entering into the question of amending the Constitution, when no question entered into the canvass in the election of members to this Convention
                other than that of dealing with our Federal and co-State relations, our external relations, I believe that we have no business with this question whatever and I shall vote in such a manner as to get clear of that subject entirely now. I do it upon another ground. Sir, if ever there was an understanding made, and of that understanding the Constitution itself is witness, it was that we
                were to let these questions alone until Anno Domini 1865. And I do say that it is unjust to the people of Virginia, either East or West, to seize upon a moment like this, when we are trying to unite ourselves in defence of our common enemies, to divide us upon our own internal questions. Sir, if we are divided we should be ready to waive any minor point of difference and to unite
                upon the great questions now before us.
              </p>
              <p className="p-in-sp">
                But if I were ready to enter upon these questions now, of Constitutional amendment, I would not be willing to do it, for I am very certain that in the county of Princess Anne, to whose people I am indebted for my seat upon this floor, no man dreamed that any such change would be proposed when they sent me here. But there is another reason why I will not act upon it. I say to Western
                gentlemen that I want to see whether they will act in defence of my rights as a slaveholder, before I ever come to any more compromises upon this negro question. I want to know whether they are going to stand by me upon the main question, before I answer how I will stand with them upon any incidental question. I was generous once, I was self devoted once upon this question; and I was
                assured then that I would find men always ready to stand by me and to protect me. But I must say, that there are some men here from the East and from the West both, that I cannot rely upon any more to defend my rights <PageNumber num={525} /> upon the negro question; and I especially intend, before giving to these gentlemen additional power to tax slave
                property, to be well satisfied that they are willing to unite with me to defend the rights of slave property.
              </p>
              <div className="speaker" id="sp3090"><span className="persName">Mr. KENT</span>—</div>
              <p className="p-in-sp">
                I desire, in a very few words, to explain my position upon this question. I can say, like the gentleman from Princess Anne, and other gentlemen who have made personal explanations upon this floor, that this question of taxation and representation is one that did not enter in the slightest degree into the canvass, among my constituents. Since the Convention has been in session,
                however, I have been instructed by my constituency, to vote against all propositions tending to disturb this question at this time, and I shall endeavor to carry out their wishes as far as I can. I may be reduced to the necessity of voting for alternate propositions. When it comes to that, I think I will have no difficulty in determining on which side my vote will be recorded.
              </p>
              <p className="p-in-sp">
                This is a question which requires, with me, some time for deliberation; for since 1850 this institution has undergone a very great change in my part of the State. It occupies a position there unlike its position in any other portion of the State. It is an institution to which my people are very much attached. We are attached to it, not simply because we derive any great profits from
                it, for we do not derive profits equal to those of other portions of the State, where slaves can be employed profitably at all seasons of the year, but we are sound upon it, are devotedly attached to it, and are anxious to foster it.
              </p>
              <p className="p-in-sp">
                In one respect, this question of ad valorem taxation presents to South-west Virginia, a difficult problem for solution. If the price of slaves continues to be high, a tax in the ratio of their market value will be so burthensome, with the small profits upon its labor, as very soon to extinguish it effectually. If, from want of proper protection, there is a stampede of slaves from
                Virginia, the burthen of paying the State debt will fall necessarily, in a great measure, on the lands and productions of the West. I have, therefore, desired time to go home, have a conference with my people, and know from them in a more solemn manner, than the mode in which I have had an expression of opinion from them, what course they desire me to adopt on this question. I shall,
                therefore, vote for the amendment as the readiest mode of disposing of the question, for the present.
              </p>
              <div className="speaker" id="sp3091"><span className="persName">Mr. ECHOLS</span>—</div>
              <p className="p-in-sp">
                Mr. President, when this question was first mooted in the Convention, I was one of those who thought no action should be taken by the Convention in regard to it at this session. The questions <PageNumber num={526} /> embraced in the resolution of the gentleman from Monongalia, had not entered into the canvass in the portion of the State from which I
                come, or if they entered into it at all, the general understanding was that at this time when the representatives of the people of Virginia met in solemn conclave, on such vital questions as are now being agitated among us, it was hardly proper that this subject of taxation of slave property should enter into our deliberations. When the resolution looking to the consideration of this
                question was first offered by the delegate from Preston
                [<span className="persName">Mr. BROWN</span>] , I, as a Western delegate, moved to lay it on the table. That motion prevailed, the resolution was laid on the table, and the subject remained at rest for a considerable time. Afterwards the resolution of the delegate from Monongalia [<span className="persName">Mr. WILLEY</span>]
                , was offered and discussed for sometime—the Western members generally taking part in the discussion to the exclusion of delegates from the East. Several of us from the West had thought it best, thought it would be carrying out our faith as we understood it to have been pledged to our Eastern brethren in the Convention of 1850, to let this question go by until we had come safely
                through the death struggle in which we were engaged. But while occupying that position, I was told, to my astonishment, by a delegate from one of the Eastern counties, representing a large slaveholding constituency, that he for one, did not consider there was any pledge in the Convention of 1850 on this subject, and that he thought it proper this question should be agitated, should
                be discussed, and should be determined by this Convention.
              </p>
              <p className="p-in-sp">
                Therefore, sir, representing, as I do, a Western constituency, I can no longer refrain from voting for an investigation of this question, when I am, as it were, upbraided by a delegate from the East, for the position which I previously occupied. I regard the principle of taxation laid down in the first resolution of the delegate from Monongalia, the proper principle. It is a
                principle on which we Western men stand almost unanimously together. It is a principle for which we have contended whenever a Convention of the people has been called together. If Eastern delegates were united in opposition to the discussion of the question at this time, I, for one, would say : "Gentlemen, we will not press it on you now. We do not think it right. Your constituents
                consider that their great interest is seriously involved in the great struggle now being made in regard to State sovereignty, State independence, and State equality; we will not, therefore, press other questions upon you at this time."
              </p>
              <p className="p-in-sp">There is another reason, Mr. President, why I oppose the amendment <PageNumber num={527} /> of the gentleman from Jackson [<span className="persName">Mr. F. P. TURNER</span>] , amended as it has been, by the substitute of the gentleman from Monongalia [<span className="persName">Mr. WILLEY</span>]
                , and why I prefer the original resolution. If we are to go into the discussion of questions affecting the organic law of the land, let us go fully into those reforms. Let us not stop at this single question of taxation. One of the chief recommendations of the resolution of the gentleman from Monongalia, is the fact that it looks also to the reform of our judiciary system, in the
                election of judges, magistrates, and other officers. If we are to have reform at all, let that reform be thorough and complete. I, therefore, as a Western delegate, say to the delegates from the East, "settle this matter for yourselves, gentlemen." Not one week ago, the delegate from the county of Franklin said, on this floor, that he thought the resolution was proper; that the
                reform was desirable, and that the people of the West had a right to demand it.
              </p>
              <div className="speaker" id="sp3092"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">
                I am informed that the gentleman referred to me as the delegate from one of the Eastern counties, who said that the faith of the State was not pledged to the arrangement made in 1850. If the gentleman will look at the report of my remarks he will find that he is greatly mistaken. I professed to know nothing about it, but I submitted this question to the gentleman from Northampton
                [<span className="persName">Mr. FISHER</span>] , whether if he considered the faith of the Northwest was pledged in this matter, it would not be equally violated by carrying out his proposition; and I asked what would become of my friends from Pittsylvania and Halifax, who came here Union men if such a compromise as that was made!</p>
              <p className="p-in-sp">
                Now, with regard to what I said about the proposition of the gentleman from Monongalia, I said this, that in my opinion, the principle of ad valorem taxation was a correct one, but that the question had not been agitated in my county at all. On the contrary, the question of union and disunion overrode all other questions. And I did not feel myself at liberty to interfere with the
                Constitution in any way, although I had opposed it from the beginning to end, and voted against it at the polls. But, I said that I would like to see changes in the Constitution, and would vote for a committee of inquiry; that in the meantime we could, all of us, go home and consult our constituents, and that if I found my constituents in favor of this change, I would go for it, as I
                believe the principle contended for was a correct one. That is the sum and substance of all I said.
              </p>
              <div className="speaker" id="sp3093"><span className="persName">Mr. ECHOLS</span>—</div>
              <p className="p-in-sp">
                I may have been somewhat mistaken as to the position which I attributed to the delegate from Franklin. I recollect <PageNumber num={528} /> that he expressed his willingness to go for that resolution, for the appointment of a committee to go into the investigation of the question proposed in the resolution, and that in the meantime he would go back to
                his people, consult with them, and return next fall prepared to act on the report of the Committee. If the gentleman from the county of Franklin, representing a large slaveholding constituency, is willing to do that, I submit to you whether I, representing a people who are so little interested in slavery, could refrain from going at least as far as the delegate from the county of
                Franklin.
              </p>
              <div className="speaker" id="sp3094"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">It is true that the county which I represent is a slave-holding county to a very considerable extent, but it so happens that so far as the question of basis and taxation is concerned, it is a perfect equilibrium.</p>
              <div className="speaker" id="sp3095"><span className="persName">Mr. ECHOLS</span>—</div>
              <p className="p-in-sp">
                The people whom I represent do not present the same appearance as the people of the county of Franklin present according to the statement of their delegate. There is no division of opinion among my people. We think that the principle laid down by the gentleman frorri Monongalia is the correct principle. We think that taxation ought to be founded on the value of the property, that the
                ad valorem is the only correct system.
              </p>
              <p className="p-in-sp">Sir, for the reasons which I have given I prefer the original resolution of the gentleman from Monongalia, and shall vote against the substitute of the delegate from Jackson, reserving to myself the right to vote as may seem best when the original resolution comes before us.</p>
              <div className="speaker" id="sp3096"><span className="persName">Mr. BROWN</span>—</div>
              <p className="p-in-sp">
                Mr. President, I presume the Convention desires to dispose of this question to-night, and I therefore want to say a few words before the vote is taken. You know that I am not very tedious, and I should not have said a word but for some remarks made by my friend from Princess Anne, whose services to the West I acknowledge now, and ever will. But when he told us there was not a man in
                the Commonwealth who .thought of amending the Constitution, he must have been alluding to his own constituents. He certainly could not speak for the county from which I came. There was not a man in that county who did not vote understandingly on that subject, and who has not read or heard read the provision of the law, that any amendment of the organic law that might be adopted by
                this Convention, should be submitted to the people for their ratification, as well as any ordinance of secession.
              </p>
              <p className="p-in-sp">
                My friend also made an allusion to what he called a compromise in the Convention of 1849-'50.<span className="note" id="Note61"><span className="noteLabel">14</span>Reference is to the Convention of 1850.</span> Sir, you know something about this matter. A change in regard to the Judiciary, or in regard to taxation, <PageNumber num={529} /> could only be made
                through a Convention. Was there any provision for a Convention in 1865? Not at all. The question of representation in the Legislature was deferred till 1865, and a mode was pointed out by which that would regulate itself. I am perfectly indifferent whether that subject shall be fixed by the Convention or left to regulate itself as prescribed in the Constitution of 1850.
              </p>
              <p className="p-in-sp">If my friend from Princess Anne will look to the Journal of the Convention of 1850, he will find that the provision fixing the valuation of adult slaves at $300, was forced upon the West after my friend from Kanawha [<span className="persName">Mr. SUMMERS</span>] moving to reject it. We could not have defeated you on that point if we had tried, and so we just let you take it. I voted with my friend from Kanawha to strike out; and my friend from Princess Anne voted against striking it out, and he being in a majority in the Convention, carried the measure.</p>
              <div className="speaker" id="sp3097"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I think the gentleman himself voted for it.</p>
              <div className="speaker" id="sp3098"><span className="persName">Mr. BROWN</span>—</div>
              <p className="p-in-sp">Sir, I have looked at the document.</p>
              <div className="speaker" id="sp3099"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I do not want to look at the document; but I ask the gentleman did he not himself vote with me for the taxation clause?</p>
              <div className="speaker" id="sp3100"><span className="persName">Mr. BROWN</span>—</div>
              <p className="p-in-sp">Not for the $300 valuation.</p>
              <div className="speaker" id="sp3101"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Did he not vote for that clause as it stands in the Constitution?</p>
              <div className="speaker" id="sp3102"><span className="persName">Mr. BROWN</span>—</div>
              <p className="p-in-sp">I did not. I voted against the $300 valuation, and it is so recorded in the yeas and nays.</p>
              <div className="speaker" id="sp3103"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">That may be on that particular question; but when it came to a vote on the entire proposition the blank being filled with $300, did he not vote for it?</p>
              <div className="speaker" id="sp3104"><span className="persName">Mr. BROWN</span>—</div>
              <p className="p-in-sp">I have not looked for that.</p>
              <div className="speaker" id="sp3105"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I have. The gentleman, and four others, were the men from the West, who helped us to force it on the West; and without their votes, we could not have done it.</p>
              <div className="speaker" id="sp3106"><span className="persName">Mr. BROWN</span>—</div>
              <p className="p-in-sp">I tell the gentleman from Princess Anne, that his memory fails him.</p>
              <div className="speaker" id="sp3107"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I ask the gentleman whether he was not obliged to retire from a contest eight years ago because of that vote?</p>
              <div className="speaker" id="sp3108"><span className="persName">Mr. BROWN</span>—</div>
              <p className="p-in-sp">I tell the gentleman he is mistaken. I have looked at the document.</p>
              <div className="speaker" id="sp3109"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I will not contradict the gentleman; but, the other night I named him as one of the five western men who backed me, after thirteen eastern men had seceded from me, and he did not then contradict me. He says he has not looked at the Journal on that point. I will look at it.</p>
              <PageNumber num={530} />
              <div className="speaker" id="sp3110"><span className="persName">Mr. BROWN</span>—</div>
              <p className="p-in-sp">When the gentleman from Princess Anne made those remarks the other night, I did not like to contradict him or make an issue till I should have examined the Journal.</p>
              <div className="speaker" id="sp3111"><span className="persName">Mr. CECIL</span>—</div>
              <p className="p-in-sp">I ask my friend to give way for a motion to adjourn, that he may have time to examine the record.</p>
              <div className="speaker" id="sp3112"><span className="persName">Mr. BROWN</span>—</div>
              <p className="p-in-sp">My friends desire to have a vote to night, and I shall be done in a few minutes. My friend from Princess Anne says he was true to the west; and, now, he expects the west to be true to the institution of slavery.</p>
              <p className="p-in-sp">I tell that gentleman that he and his property are perfectly safe in the keeping of the West. Sir, I have a constituency of 2500 voters, that would to day, if necessary, march to the county of Princess Anne to defend the smallest child he has.</p>
              <div className="speaker" id="sp3113"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I have no doubt of it—I have every confidence in Western men; but they must send better representatives here.</p>
              <div className="stage it">[Laughter.]</div>
              <div className="speaker" id="sp3114"><span className="persName">Mr. BROWN</span>—</div>
              <p className="p-in-sp">My constituents have every confidence in their representatives. Out of 2500 votes I received all but 27. Can the gentleman from Princess Anne say as much?</p>
              <div className="speaker" id="sp3115"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I can say this, in the language of the Holy Writ, "woe to him of whom all men speak well." [Loud laughter.] I have had some to doubt, and to try me before they sent me here. If the gentleman's constituents have this great confidence in the gentleman, we will see how it will be after he has got through the alembic of this Convention.</p>
              <div className="stage it">[Laughter.]</div>
              <div className="speaker" id="sp3116"><span className="persName">Mr. BROWN</span>—</div>
              <p className="p-in-sp">I want to assure my friend from Princess Anne—</p>
              <div className="speaker" id="sp3117"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I want to say a word more. Will the gentleman yield the floor?</p>
              <div className="speaker" id="sp3118"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">This is entirely out of order.</p>
              <div className="speaker" id="sp3119"><span className="persName">Mr. BROWN</span>—</div>
              <p className="p-in-sp">I cheerfully yield the floor to my friend.</p>
              <div className="speaker" id="sp3120"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I want to say, it is perfectly in order to ask another to yield the floor.</p>
              <div className="speaker" id="sp3121"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">It is not in order for two gentlemen to get on the floor together and carry on a colloquy.</p>
              <div className="speaker" id="sp3122"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">We will alternate then. Sir, I was about to say, and with the kindest regard to my friend from Preston [<span className="persName">Mr. BROWN</span>] , that I don't want to put him in any tight place, and I hope you will let him address you, whilst he is dressing me.</p>
              <div className="speaker" id="sp3123"><span className="persName">Mr. BROWN</span>—</div>
              <p className="p-in-sp">
                Well, we have had our playful remarks. I will now merely reiterate that if the non-slaveholders cannot get relief from this unjust discrimination now, and if this Convention should adjourn <PageNumber num={531} /> without giving that relief, can we hope to have another called within ten years? Do gentlemen desire that a Convention should be called to
                amend the Constitution when we have it in our power to do it now, and in a way that the gentleman from Princess Anne
                [<span className="persName">Mr. WISE</span>]
                , says is just and right. He seems to think, however, that the amendment ought not to be made until 1865. Now, when will taxes be collected upon slaves according to the ad valorem principle? Suppose steps are taken now to initiate the proposed change, no action can be had upon them until the Convention meets again, and then if they are agreed upon, they will have to be submitted to a
                vote of the people for ratification or rejection. Now, sir, these measures will not be submitted to the people before the next twelve months. The assessment of 1862 will have passed before that provision can possibly go into effect; so that it cannot affect the collection of the revenue before the year of 1863. That is the first year that the ad valorem principle can affect the tax
                upon slaves, assuming that the principle shall be established by this Convention. Would you desire, if this principle is right, to call another Convention to settle it in 1865, when its adjustment now will only make a difference of two years in the collection of the revenue upon this principle? If gentlemen think that they can finally defeat this measure, they are much mistaken. The
                non-slaveholders of the State, together with the slaveholders who favor the establishment of that principle, will form, combined, a sufficient power to compel justice to be done .in this matter. They know their rights, and, knowing them, they will assert them.
              </p>
              <p className="p-in-sp">
                I desire to say to the gentleman from Princess Anne, who has intimated that he will not give us this correct ad valorem principle, which he himself admits to be correct, unless or until we will act in relation to secession, whenever we can acknowledge that it is right to take the Old Dominion out of the Union, we will do it. We will never make the concession of this principle of ad
                valorem taxation a condition to unite in this movement. We will do what is right. And, sir, whenever Western men believe that it is the interest of Virginia to go out of this Union, they will aid you in taking Virginia out; but until they are convinced of the necessity of doing so, they will not do it. I do not say that events may not arise which would convince them of the necessity
                of taking Virginia out of the Union. I can suppose a state of events that would induce them in my opinion to go out readily, but that state of things is not upon us now.
              </p>
              <div className="speaker" id="sp3124"><span className="persName">Mr. BRANCH</span>—</div>
              <p className="p-in-sp">The Convention seems to be in an excellent mood to sit here for some hours. I have a speech to make and I would ask <PageNumber num={532} /> whether it would be now agreeable to the Convention to hear me, or to adjourn.</p>
              <div className="stage it">[Cries of "go on, go on," and "adjourn, adjourn."]</div>
              <div className="speaker" id="sp3125"><span className="persName">Mr. BRANCH</span>—</div>
              <p className="p-in-sp">I have a pretty long speech to make, and I think it would be advisable to defer its delivery until another time. I move that we adjourn.</p>
              <p>The motion was agreed to, and the Convention adjourned at twenty minutes to 8 o'clock, P.M.</p>
            </div>
          </div>
          <div className="day" id="vsc1965.v3.2.9">
            <PageNumber num={533} />
            <h2><span className="headingNumber">1.9. </span><span className="head">FORTY-NINTH DAY</span></h2>
            <div className="dateline">Thursday, <span className="date">April 11</span></div>
            <p>The Convention met at 10 o'clock, A.M.</p>
            <p>Prayer by the Rev. <span className="persName">Mr. BOSSERMAN</span>.</p>
            <div className="speaker" id="sp3126"><span className="persName">Mr. HARVIE</span>—</div>
            <p className="p-in-sp">Is it in order now to present the proceedings of a meeting held in a county that I represent?</p>
            <div className="speaker" id="sp3127"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">The gentleman can do so by general consent.</p>
            <div className="speaker" id="sp3128"><span className="persName">Mr. BRANCH</span>—</div>
            <p className="p-in-sp">Does the gentleman rise to a privileged question?</p>
            <div className="speaker" id="sp3129"><span className="persName">Mr. HARVIE</span>—</div>
            <p className="p-in-sp">I rise to ask to present the proceedings of a meeting which the gentleman himself asked leave to do a short time ago.</p>
            <p className="p-in-sp">I now beg leave to present the proceedings of a meeting held in the county of Nottoway. It was a very large meeting, and the resolutions, which I shall read, were adopted with but one dissenting voice.</p>
            <div className="section" id="vsc1965.v3.2.9.1">
              <h3><span className="headingNumber">1.9.1. </span><span className="head">PUBLIC MEETING IN NOTTOWAY</span></h3>
              <div className="floatingText">
                <div className="floatingText_body">
                  <p>
                    At a meeting held at Nottoway Court House on the 4th April, 1861, that being Court day, on motion of John E. Jones, Esq., Richard Irby was chosen Chairman, and W. R. Carter Secretary. The object of the meeting being to express their opinion of the action of the State Convention, on motion a committee of two was selected from each Magisterial District to draft resolutions;
                    whereupon the Chairman appointed John E. Jones, F. W. Epes, M. W. Robertson, W. G. Dickinson, R. Connalley, J. M. Hust,<span className="note" id="Note62"><span className="noteLabel">1</span>This name should perhaps be J. M. Hunt, like that of the delegate from the 3d District under the eighth resolution, below.</span> Col. J. H. Epes and L. A. Hawkes. During the absence of the committee,
                    J. H. Campbell, being called for, came forward and delivered an able and telling speech, advocating immediate secession. Capt. S. Weisiger, of Amelia, a Union candidate for the Convention, in the late election, also spoke in favor of secession, alleging that the failure of the Peace Conference had cured him of all his former Union proclivities. The committee then reported the
                    following resolutions, which were adopted with only one dissenting voice :
                  </p>
                  <p>Whereas, The dilatory proceedings of the Convention, now sitting in Richmond, demand from the people of Nottoway, as well as of the whole State, a firm and decided expression of opinion in the present political crisis; therefore be it</p>
                  <p>1. Resolved, That the course of our representative in said Convention, L. E. HARVIE, meets with our hearty approval.</p>
                  <p>2. That, in the opinion of this meeting, the Convention, should immediately pass an ordinance of secession.</p>
                  <p>3. That we are opposed to any proposition looking to delay, and <PageNumber num={534} /> especially any proposition looking to a Border Slave State Conference, before Virginia shall have passed her ordinance of secession, and clearly declared her position in favor of a union with the Southern Confederacy.</p>
                  <p>4. That we are opposed to any other proposition being made by Virginia to the Northern States for a settlement of the present difficulties.</p>
                  <p>5. That we do not believe that there is any real conflict of interest between Eastern and Western Virginia, and we earnestly entreat Western Virginia to unite and make common cause with us.</p>
                  <p>6. That we approve the course of our delegate, Colonel C. A. Crump, and Senator A. D. Dickinson.</p>
                  <p>7. That we cordially endorse the course of our able representative in Congress, the Hon. R. A. Pryor.</p>
                  <p>8. That the chairman appoint seven delegates from each Magisterial District to meet delegates from Amelia, at the junction of the Danville and South Side Railroads, on the 19th of April, to select a suitable candidate for the House of Delegates; whereupon, the chairman appointed the following:</p>
                  <p>1st District—Col. W. C. Jeifress, W. W. Gray, J. H. Campbell, John E. Jones, W. P. Dickinson, S. B. Fowlkes, Sharp Carter.</p>
                  <p>2d District—Colonel C. A. Crump, T. A. Fowlkes, Geo. Overton, P. 0. Lipscomb, W. G. Dickinson, W. A. Robertson, T. H. Vaughan.</p>
                  <p>3d District—J. G. Powell, E. G. Gunn, T. R. Blandy, R. Connalley, J. M. Hunt, F. C. Williams, W. D. Farley.</p>
                  <p>4th District—Theo. Foster, L. A. Hawkes, T. F. Epes, W. R. Bland, R. E. Borum, Capt. J. E. Shore, G. A. Cralle.</p>
                  <p>9. That these resolutions be published in the Richmond and Petersburg papers, and a copy be transmitted to our representative in the State Convention.</p>
                  <p>On motion, the meeting adjourned.</p>
                  <p>RICHARD IRBY, Chm'n</p>
                  <p>W. R. CARTER, Sec'y.</p>
                </div>
              </div>
              <div className="speaker" id="sp3130"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">I ask that these proceedings be laid upon the table and printed.</p>
              <p>The motion was agreed to.</p>
            </div>
            <div className="section" id="vsc1965.v3.2.9.2">
              <h3><span className="headingNumber">1.9.2. </span><span className="head">TAXATION QUESTION</span></h3>
              <div className="speaker" id="sp3131"><span className="persName">Mr. WILLEY</span>—</div>
              <p className="p-in-sp">I have leave of the gentleman from Petersburg [Mr. BRANCH, who was entitled to the floor] to take the floor to make a statement.</p>
              <PageNumber num={535} />
              <p className="p-in-sp">I indicated to the Convention last night, that although I offered the amendment to the amendment of the gentleman from Jackson [<span className="persName">Mr. TURNER</span>]
                that I nevertheless felt it my duty to vote against it, because I preferred the original proposition as offered by myself. But I have received since that time so many appeals from Eastern gentlemen and from so many quarters entitled to such high respect, that I feel myself disposed to yield to these appeals; and in a spirit of concession, against the dictates of my own judgment, and
                of the certain convictions of my own mind that the proposition, as I offered it, ought to be adopted, I am disposed nevertheless, if gentlemen will do me the favor, to adopt the amendment to the original proposition as it now stands, to ask my friends who think with me to unite with them in doing so.
              </p>
              <p className="p-in-sp">
                The reason why I desire that this proposition should be adopted in the form in which I presented it was, not as has been alleged or supposed by gentlemen upon this floor, to produce excitement ; but it was to allay; it was to satisfy that deep and burning spirit of indignation reigning in almost every Western mind and heart against the gross, unjust, political, personal and obnoxious
                distinction against their interests. But, sir, I am this morning willing to meet gentlemen from the East in a spirit of concession; and if they will give me this half-way proposition, to go home to my people and say to them, as I have had occasion to say before, be satisfied with this hoping for justice in the future.
              </p>
              <div className="speaker" id="sp3132"><span className="persName">Mr. BRANCH</span>—</div>
              <p className="p-in-sp">I understand the gentleman from Monongalia [<span className="persName">Mr. WILLEY</span>]
                , this morning, as being willing to accept the amendment in preference to the original resolution. I congratulate the gentleman upon his readiness to meet the views of the Convention. I would say it was as much as he ought to have expected, and I am perfectly willing to grant him that justice, and I hope, if he finds his people dissatisfied when he goes home, that he will tell them
                that they are receiving in license taxes, $460,000, in lieu of this exemption of negroes; and this amount is more than the whole amount paid by the Northwest. I hope he will tell his people that they are fully compensated in this license tax, for all they lose upon the darkies under 12 years of age. He can show them this by fair arithmetical calculation. They have the advantage, sir,
                in this question of taxation, if we only look to the extent to which our poor merchants in the East are taxed.
              </p>
              <div className="speaker" id="sp3133"><span className="persName">Mr. CYRUS HALL</span>—</div>
              <p className="p-in-sp">
                I desire to submit a few remarks upon the resolution offered by the gentleman from Monongalia, and in so doing I will not make any apology for the particular section of the State from which I come, as to the soundness of the people which I have the honor <PageNumber num={536} /> to represent in this Convention, on the slave interest in Virginia. I will
                say, sir, of the people that I represent here, that you may judge of their soundness by my action and by my votes given in this Convention. Sir, I represent a constituency of over 2,000 voters, that are as much devoted to the interests and institutions of Virginia as any people within her limits, and will be ever found ready and willing to defend the rights and honor of Virginia, at
                all times and at all hazards.
              </p>
              <p className="p-in-sp">But, sir, I can't pass the remarks made by the gentleman from Princess Anne [<span className="persName">Mr. WISE</span>]
                , without reply. He said that he had not come here to discuss the question of amending the State Constitution, but he had come here to save the life of Virginia. I must say to that gentleman that if he wants to save the life of Virginia, give to the West her just demands; don't tax everything in the shape of property, belonging to the poor man of the West, and at the same time exempt
                from taxation two hundred and eighty-six thousand dollars worth of property belonging to the wealthy men of the East. This system of unjust and unequal taxation in the Commonwealth is at this very hour doing more to crush out the very vitals of Virginia than anything else. If this cause of complaint was removed there would be harmony in this Convention instead of strife and division
                of sentiment. In my judgment these resolutions should be responded to by our Eastern friends without further delay. I think that Eastern gentlemen, by this time, can't misunderstand the temper of our people. It has been urged upon this Convention by every delegate from Northwestern Virginia, that they expect speedy justice to be done upon these questions of taxation and
                representation. Our people are becoming exceedingly restless and impatient under the long delay which attends the settlement of this question. If you refuse much longer to grant them their just demands, both in taxation and representation, they may come to the conclusion that this slave interest in Virginia, instead being a blessing to them, is turned into an engine of oppression.
                Sir, instead of keeping our people sound upon the slave interest of Virginia, you gentlemen of the East, will give the right of way for the extension of the underground railroad over Virginia soil, and when you do this, you do it with the protest of your friends in Western Virginia against such a policy. Sir, instead of keeping the terminus of this Abolition improvement on the
                Western bank of the Ohio River, in a short time you will find its terminus in the valley of Virginia, just ready to commence to tunnel the Blue Ridge. And when the work has progressed thus far, it will be unlike other internal improvement companies—find its capital exhausted—its capital will increase during its construction. I beg of you,
                <PageNumber num={537} /> for your own sake and for the sake of Virginia, don't by your own acts destroy your own cherished institutions.
              </p>
              <p className="p-in-sp">
                Sir, some of my friends from North-western Virginia are charged with being Abolitionists; but what grounds have you for making this charge of not being loyal and true to your interests? I suppose it is from the fact we have so little interest in the institution of slavery. Sir, we want an interest in the slave property of Eastern Virginia, and the only way you can make me and my
                people interested in the slave property of the East, is by taxing it according to its true value like other property. I am interested in dollars and cents, and when I get this moneyed interest in your slave property, you will not doubt my soundness, nor the soundness of my people on the slave question. Sir, if I were to return to the people that I represent, and tell them that I had
                not raised my voice in behalf of their rights on this question of ad valorem taxation, I would have a fearful account to settle with my constituents.
              </p>
              <p className="p-in-sp">
                Sir, it has been said by gentlemen from Eastern Virginia that it was never contemplated by their constituents that this question of change in the organic law of the Commonwealth would be raised in this Convention. This question was thoroughly discussed in the canvass in my section of the State, and it was understood by my people that this would be the most favorable time for them to
                demand their rights at the hands of their Eastern brothers. You are calling upon us to sever our connection with the Federal Government. For what cause. For the purpose of protecting this slave property of the East, which you refuse to let contribute like other property to the support of the Government. It is urged by gentlemen here that the people of the East pay more taxes than the
                West. Suppose this argument to be true, you pay more taxes because you have more property than the people of the West. Sir, is this the principle that is to govern Virginia, that because one section of the State is poor it is to be taxed with every cent's worth of property that it is possessed of : and because another section of the State is rich ; the most valuable portion of its
                property is to be exempt from taxation? It is true that our lands in the West are not so valuable as the lands of our Eastern friends. But this is easily accounted for when you look at the fact that North-western Virginia has never had but two million of dollars expended for internal improvements, in that section of the State. But we find a very different state of things in Eastern
                Virginia ; you have railroads running in every direction ; and these roads are all constructed on State account. But it is not so in the West. You have refused, time and again to give us the right of <PageNumber num={538} /> way over our own soil to build railroads with our own funds. These are some of the reasons why our lands are not so valuable as
                yours. In view of these facts, can you expect that we will longer submit to these wrongs and aggressions? Every gentleman from the Northwest that has addressed this Convention upon this subject, has, in most unmistakable terms, told you that if you fail to equalize the taxes in Virginia, you would do more to divide the people of Virginia by that act, than any other course you could
                pursue. If you want harmony in Virginia in this crisis, you must acknowledge the people of the West your equals in every respect. Do not discriminate against North Western Virginia. If she had her rights here to-day, she would have six additional delegates in this Convention. But, sir, I am thinking we are going to have a fight with the North about this slave property of yours, and
                if we do, I expect to take part in it. If I do, I want that property for which I fight to contribute something to support us while we fight.
              </p>
              <p className="p-in-sp">Sir, your interest will be safe if entrusted to our keeping. I speak especially for the people that I have the honor to represent in this Convention. They would come as quickly to the rescue of your property as the people of any counties in this Commonwealth.</p>
            </div>
            <div className="section" id="vsc1965.v3.2.9.3">
              <h3><span className="headingNumber">1.9.3. </span><span className="head">FEDERAL RELATIONS</span></h3>
              <p><span className="persName">The PRESIDENT</span> then announced that the hour of half-past ten had arrived—the time designated for going into the Committee of the Whole.</p>
              <p>The Convention then resolved itself into Committee of the Whole [<span className="persName">Mr. SOUTHALL</span> in the Chair] , and resumed the consideration of the report of the Committee on Federal Relations.</p>
              <div className="speaker" id="sp3135"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">When the Committee rose on yesterday, the 14th resolution was under consideration, to which the gentleman from Fauquier [<span className="persName">Mr. SCOTT</span>] , offered the following amendment to be appended to the resolution :</p>
              <p className="p-in-sp">
                "And in the event that favorable responses on the part of the nonslaveholding States be not made to the proposed amendments to the Constitution, by the time appointed for the reassembling of this body, it is the opinion of this Convention that the said States of Delaware, Maryland, North Carolina, Tennessee, Kentucky, Missouri and Arkansas, ought to assemble in primary Conventions,
                and in conjunction with this State, convene at on the day of a Congress of the said States composed of delegates to be appointed by the respective Conventions thereof for the purpose of recommending an amended Constitution of Government upon which the Union of the States and <PageNumber num={539} /> the Confederated States with such of the
                non-slaveholding States as may concur therein can be safely effected, to which Congress the Confederated States, and the concurring non-slaveholding States, ought to be invited to send Commissioners."
              </p>
              <p className="p-in-sp">The gentleman from Augusta [<span className="persName">Mr. BALDWIN</span>] , moved to amend by striking out all after the word "convene," down to the words "a Congress."</p>
              <p className="p-in-sp">The question was then taken on <span className="persName">Mr. BALDWIN</span>'S amendment, and it was not agreed to.</p>
              <div className="speaker" id="sp3136"><span className="persName">Mr. BRUCE</span>—</div>
              <p className="p-in-sp">I propose the following amendment to the amendment: Strike out all after the word "and," and insert :</p>
              <p className="p-in-sp">
                "The foregoing resolutions and amendments to the Constitution, thereto appended, are respectfully submitted to the other States of the Confederacy, as the only terms on which Virginia feels it safe or honorable to continue a member of the present Union, and she is bound to announce it as her fixed and unalterable determination, that if they are not acceded to on or before the day of
                , by such a number of her co-States as may be necessary to carry into effect the proposed amendments, in conformity with the provisions of the Constitution of the United States, that she will then withdraw herself from this Union, and proceed to form such new connections as she may deem necessary for her independence, her security and her happiness."
              </p>
              <p className="p-in-sp">
                I propose to offer to the Committee a very few remarks upon this question. I present this proposition as a peace offering to this Committee. It is known that there are two very distinct opinions prevailing amongst us, the one in favor of a Border Conference, the other in favor of Virginia acting for herself independently, and without consultation with the Border States. Why, Mr.
                Chairman, should there be such an antipathy to Southern States and such love for Border States? Where are our connexions? What connexion have we with many of the Border States? The only connexion that is worthy of consideration is a connexion of interest. What interest in the way of business have we with Missouri? Who sells to Missouri? Who buys from Missouri? With Kentucky we have
                had a trade, a most disastrous trade, to my region of the country, in the article of live hogs, for which we have had to pay in hard dollars, and for which we had nothing to exchange. That trade has been transferred to Cincinnati, and instead of buying live hogs, we buy salted pork and bacon. What connection have we with Tennessee? None. We have no trade, we have no intercourse with
                it. And the only States with which we have especial intercourse are the States of Maryland and North Carolina. North Carolina is indeed bone of our <PageNumber num={540} /> bone, and flesh of our flesh. She finds through Virginia ports an outlet for nearly all her products—for her lumber, her cotton, and her tobacco. In her we are deeply interested. In
                Maryland we are interested through the Northwest, that has a trade in live stock with the city of Baltimore. But with the South we are every way interested. We send to her ten millions worth of slaves. We send to her our manufactured tobacco of the best quality. We cannot live without the South, and the only intercourse that we have with the Border States, is through a common
                interest and a common connection with the Southern States.
              </p>
              <p className="p-in-sp">
                Now we are ignoring the Southern States. We are forgetting our close connection with them, our deep interest in them, and are looking only to the border States, because they are called border States. There is, Mr. Chairman, something in a name, and we feel that fact here; for if the common name of "Border States," were taken away, we would see that all our connection is with the
                South, and but little with these border States. Why, sir, what would you do if you were cut off from the South and united with the North? Where will your slaves go? Where will the increase of Virginia slaves go? In the course of twenty five years we will have a million, in the course of seventy-five years, four millions, of slaves "cribbed, cabined, confined," within our own borders.
                And yet gentlemen tell us that we are not interested in the South. Absolutely, in that connection, they are ignoring all intercourse with the South. We cannot live without the South ; we cannot exist without the South. Virginia, with her five hundred thousand slaves, is to go into a Conference with Delaware, abolitionized already, with Kentucky, half abolitionized, with Missouri,
                three-quarters abolitionized—for the other day in the capital of Missouri the unconditional Submissionists came within two votes of carrying that capital. And yet, is Virginia, with all her great interests, with her 500,000 slaves, to be put in a Conference with these border States with which she has so little interest? It is impossible that we can go into such a Conference with
                safety.
              </p>
              <p className="p-in-sp">
                It is said that Virginia will present her ultimatum. That, sir, is not worth a moment's consideration. It appears to me that an ultimatum to be carried into a Conference would be exceedingly insulting. Can anything be more insulting, can anything be more inconsistent with the very name of Conference? Why, the door would be slammed in our faces. We should be contumeliously rejected.
                Virginia, in taking the lead, does not take anything upon herself ; in this matter Virginia assumes nothing. She has a heavy slave interest; and it is the most natural thing in the world that she should lead in an effort to reconstruct <PageNumber num={541} /> this government. If Virginia shall go out of the Union, I am not disposed to say that she will
                stand alone. If she goes out she will form new connections, and I say—and I am not afraid to say it—that there is only one connection that Virginia can make, and that connection is with the Southern Confederacy. There is no other connection that she can make. That is the natural connection, and if Virginia drops off from this Union, she will as certainly go to the South as the apple
                falling from its parent limb will fall to the earth. She gravitates there. And gentlemen may talk about it as much as they please, but, if they will take a common sense view of this question, and the one that strikes the mind of every person, and if they will forget their antipathies to the South, they can come to but one conclusion. It is a principle that stares every man in the
                face, and is responded to by every man in Virginia. I have never seen a man in Virginia who thought that we ought to sever our connection with the United States, who did not believe that there was but one place for Virginia to go, and that place is the Southern Confederacy. The Southern Confederacy have a government. Have you a government to go to? What are you expecting? You ignore
                two governments, and are now trying to make a third, and a third of heterogeneous material. It will all end in debate, and consultation, and bickering, and strife; you will lose precious time, and the result will be at last that you will be compelled to go into the Confederacy of the South. There is no middle ground. There is no other place for you. Virginia will have to come to it.
                It is impossible that Virginia can long stand in her present condition of suspense. Every interest of the country is suffering on account of the unsettled state of affairs; and we to day are suffering almost as much as if we were in the very midst of civil war. I do hope that this Committee will look this question fairly and squarely in the face ; that they will consult, not with
                their prejudices, not with their passions, not with their foregone conclusions;* but that they will consult with their better judgment, will take a commonsense, practical home-spun view of the question, and that they will reject all these consultations, these conferences, these Congresses, which will keep us for years, perhaps, in debate, and at last end in nothing. I hope the
                Committee will take this view of the question.
              </p>
              <div className="speaker" id="sp3137"><span className="persName">Mr. MOORE</span>—</div>
              <p className="p-in-sp">I trust, sir, that the Committee will, without hesitation, reject the proposition submitted by the gentleman from Halifax [<span className="persName">Mr. BRUCE</span>]
                . The gentleman offers his amendment as a compromise. And what sort of a compromise is it? It is a compromise of direct and immediate secession. It is a proposition that we shall arrogate to the State of Virginia the right of dictating to every other State in the <PageNumber num={542} /> Union, including the Border States, and the Northern States, and
                all; that "by a given day and a given hour, you shall accede to the proposition which we make, or we go out of the Union on the spot." That is the substance of the compromise which is offered. Sir, I trust that this Committee can adopt no such thing as that.
              </p>
              <p className="p-in-sp">
                We have already held out to the world the idea—and the other States have acted upon it—that there was to be a Conference with the border States in reference to these matters, so important to them as well as to ourselves; and now we are to insult them by sending them dictatorial terms, and demanding instant submission to our dictation. Sir, I trust we will do no such thing. I trust,
                rather that the Committee will reject this proposition of the gentleman from Halifax, and adopt the proposition offered by the gentleman from Fauquier
                [<span className="persName">Mr. SCOTT</span>] .</p>
              <p className="p-in-sp">
                Mr. Chairman, we hear in the newspapers, and we hear in this assembly, insinuations about gentlemen who agree with me being disposed to submission, and to go to the North. Sir, these imputations and insinuations are unjust, illiberal and destitute of foundation in truth. I have no disposition to go to the North, any more than any where else. I mean to stand firm in Virginia, assert
                our rights and call upon all around us who are willing, to take part with us in asserting those rights. And if the Northern States reject, as I apprehend they will, the propositions of amendment to the constitution of the United States, which we submit, I say let them go. I want Virginia to stand in this position : that we will invite those States which are willing to accede to our
                propositions—and the seceded States too, if you please, sir—to unite with us in forming a New Confederacy, and to adopt a new Constitution. And in that new Constitution I should be glad to see adopted some of the amendments to the constitution of the United States, adopted by the Southern Confederacy, because I think some of those amendments are valuable. But, sir, the idea that
                because we don't choose to go at once into a connection with the seceded States of the South, we are to be called Submissionists, or are to be charged with a disposition to adhere to the North and to go against the South, is, I say, illiberal, unjust and untrue. We have no such disposition, sir.
              </p>
              <p className="p-in-sp">
                The gentleman from Halifax tells us of the trade which we have with the Southern States. I am inclined to believe that he forgets that Halifax county don't include the whole State of Virginia. Other people have trade with Kentucky, if the people of Halifax have not. Virginia is n)uch more deeply connected with the Border States than
                <PageNumber num={543} /> she is with those extreme States of the South. I am not, however, one of those who are unwilling to form a connection with these Southern States, provided we can do so with safety to ourselves. The proposition which I advocate may amount to secession. Sir, I admit the right of secession as a revolutionary right ; and when we
                cannot obtain what we justly demand, I am ready to take that step. I admit, then, the right of secession; but I contend, on the other hand, that every other State which conceives itself aggrieved by the act of the seceding States, has a right to demand that they shall perform their obligations, and to answer for the injury which is done to them. And I will go further than that, sir,
                and say, that the General Government, as the agent of the other States, has a right to compel them to do that duty to the other States. I am utterly opposed to coercion under existing circumstances; but, at the same time, I insist that when a State does secede, it does it with a due responsibility to the other States that may be injured by it. I hope the amendment will be rejected.
              </p>
              <div className="speaker" id="sp3138"><span className="persName">Mr. BRUCE</span>—</div>
              <p className="p-in-sp">I have but a word to say. You will find upon examination that this is a substitute—and I intend it, Mr. Chairman, as a substitute for the 14th resolution, and the amendment of the gentleman from Fauquier [<span className="persName">Mr. SCOTT</span>] . The gentleman who was last up has imagined that I used—</p>
              <div className="speaker" id="sp3139"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">Will the gentleman from Halifax allow me to ask a question?</p>
              <div className="speaker" id="sp3140"><span className="persName">Mr. BRUCE</span>—</div>
              <p className="p-in-sp">Certainly, sir.</p>
              <div className="speaker" id="sp3141"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">-I desire to ask the gentleman from Halifax whether he is now asking to change his motion? His motion as made, and laid upon our table in printed form, is to amend the amendment submitted by the gentleman from Fauquier [<span className="persName">Mr. SCOTT</span>] , in committee of the whole. Do I understand him as now desiring to change it?</p>
              <div className="speaker" id="sp3142"><span className="persName">Mr. BRUCE</span>—</div>
              <p className="p-in-sp">Yes, sir.</p>
              <div className="speaker" id="sp3143"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">I object.</p>
              <p><span className="persName">The CHAIRMAN</span> stated the question.</p>
              <div className="speaker" id="sp3144"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">Now, as the gentleman says he wants to strike out the whole of the 14th resolution, I object to that change.</p>
              <div className="speaker" id="sp3145"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">That cannot be entertained now because there is a proposition before the Committee.</p>
              <div className="speaker" id="sp3146"><span className="persName">Mr. BRUCE</span>—</div>
              <p className="p-in-sp">
                If this amendment is adopted, of course the other goes by the board. I will not insist upon that. But, sir, you will observe that this amendment does not pretend to pledge the State of Virginia to any future course, except the withdrawal from the Union, in case the resolutions of this Convention and the amendments to the
                <PageNumber num={544} /> Constitution which we propose, are not acceded to by the North. It does not intend to go one step further. But, in the abundance of my candor upon this subject—for I wished to be candid—I did state that the only possible place for Virginia to go, in case she separated from the Union, would be to the Southern Confederacy. I have
                no hesitation about that; and it would have been uncandid in me to have stated that, I expected Virginia to stand alone—though I believe she can stand alone, yet I do say that in all probability she would go to the Southern Confederacy; and I wish to say that she could not go anywhere else.
              </p>
              <p className="p-in-sp">
                How was the Southern Confederacy formed? They had grievances, as we have grievances; and we all acknowledge that these grievances are well-founded. First South Carolina went out of the Union, then went out Florida, then went out Mississippi, Alabama and Georgia. Does any man suppose that Georgia felt herself dragooned by South Carolina, when she went out? The policy has been adopted
                by the Southern Confederacy that those States which left the Union, went out of their own accord, independently, and afterwards formed a new Government.
              </p>
              <p className="p-in-sp">
                Well now, if Virginia goes out, will she not be simply following in the line of precedent set to her by Georgia? Is there any dragooning in the case? Is there any effort to operate upon the border States unjustly, improperly, or offensively? No, sir. We would only be following the line of precedent that has been set to us by the great State of Georgia—a State in every respect equal
                to the State of Virginia, a State which has been called the Empire State of the South; for when we look at her productions, her soil, her commercial advantages, her water power, she stands foremost among the foremost; and if she can follow South Carolina, may we not follow her? And are we treating the border States offensively when we say that on account of our great interest in
                slavery—greater than any two of these border States—greater than four or five of them together, she follows the lead which has been set to her by the great State of Georgia? There can be nothing offensive in this; nothing offensive at all. And if we cut loose from the North, I say we must go there. A system of crystallization is going on. South Carolina was the nucleus, just as a
                single thread may be the nucleus around which assemble the most beautiful crystals. I propose that we, too, if we cannot make our peace with the North, and secure a satisfactory adjustment, will go in the same direction. I do think that that is a fair compromise. Virginia puts forth her ultimatum. Let it be a reasonable ultimatum,
                <PageNumber num={545} /> an ultimatum that ought to be accepted, an ultimatum that the border States and the Confederate States may, without dishonor, accept; and then we shall re-construct this Union. There is but one way to re-construct it. Let those who sever their connection with this Union follow down into the Southern Confederacy, one by one,
                until all the slaveholding States are united, and then we will become such a body as will probably attract some of the free States to us, and thus the gentleman's dream of re-construction will be realized, and realized in the only manner in which it is possible for such a dream to be realized.
              </p>
              <p className="p-in-sp">
                Sir, go into Conferences, go into Congresses; and at every step you will be beset with difficulty. You are not steering between Scylla and Charybdis, but you are steering into a sea full of rocks and breakers. I say, without fear of contradiction, that it would be a miracle if a re-construction was ever formed upon any such terms as are proposed by the gentleman from Fauquier. Yes,
                sir, a miracle, such as has never been witnessed and never will be witnessed.
              </p>
              <p className="p-in-sp">
                The Southern States, in the course they have pursued, have taken the only course they could have pursued, and have been successful. The result has proved the wisdom of the course. When their course was first announced, I was under the impression that they had acted precipitately, that they ought to have consulted with the border States before resorting to the act of secession. But I
                am now satisfied that they have taken the only course by which they could have ever successfully resisted the tyranny of the North. They have pursued the only course that could have been practicable. What has been the success of that course? They are a united people—such a united people as never have been seen in the history of the world in case of revolution. I ask gentlemen to look
                at the history of the world; to look at the inauguration of other governments; look at civil war; look at rebellion; and I ask them if they can point me to one solitary case where there has been so much harmony, so much peace, so general a concurrence as there has been in the history of the Southern Confederacy? Sir, the world presents no such spectacle. You may look through the
                history of the world for it, and you will look in vain.
              </p>
              <p>Here the hammer fell.</p>
              <p><span className="persName">The CHAIRMAN</span> stated the question to be upon the amendment to the amendment.</p>
              <div className="speaker" id="sp3147"><span className="persName">Mr. STAPLES</span>—</div>
              <p className="p-in-sp">I wish to ask whether, if the amendment of the gentleman from Halifax be adopted, it will then be in order to move to strike out a portion of it?</p>
              <PageNumber num={546} />
              <div className="speaker" id="sp3148"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">It will not.</p>
              <div className="speaker" id="sp3149"><span className="persName">Mr. STAPLES</span>—</div>
              <p className="p-in-sp">Then I will remark that the amendment of the gentleman, in some respects, is in direct conflict with a provision the Committee has already adopted. I could state, if I were permitted to do so, the point of conflict, but I suppose, debate having been exhausted, I will not have the permission to do so.</p>
              <div className="speaker" id="sp3150"><span className="persName">Mr. BOULDIN</span>—</div>
              <p className="p-in-sp">
                I trust the gentleman from Halifax will be permitted, for the present, to withdraw his amendment. I understand that it was his intention to propose it as a substitute both for the original resolution and the amendment of the gentleman from Fauquier. As it now stands as a substitute for the amendment of the gentleman from Fauquier, it will leave still the proposition for a border
                State Conference standing. I again ask the permission of the Convention, for the gentleman, to withdraw his amendment.
              </p>
              <div className="speaker" id="sp3151"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">I am bound to interpose my objection, not of form but of substance.</p>
              <p>The question was put and permission was granted to <span className="persName">Mr. BRUCE</span> to withdraw his amendment.</p>
              <p><span className="persName">Mr. BRUCE</span> thereupon withdrew his amendment.</p>
              <p>The question then recurred upon the amendment of <span className="persName">Mr. SCOTT</span>, of Fauquier.</p>
              <div className="speaker" id="sp3152"><span className="persName">Mr. BRUCE</span>—</div>
              <p className="p-in-sp">Will it now be in order to offer a substitute to the original resolution?</p>
              <div className="speaker" id="sp3153"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The question must be first taken upon the amendment of the gentleman from Fauquier.</p>
              <div className="speaker" id="sp3154"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I move to amend the amendment of the gentleman from Fauquier, by striking out all after the word "body" in the 4th line as follows :</p>
              <p className="p-in-sp">
                "It is the opinion of this Convention that the said States of Delaware, Maryland, North Carolina, Tennessee, Kentucky, Missouri and Arkansas ought to assemble in primary Conventions, and in conjunction with this State convene at , on the day of , a Congress of the said States composed of delegates to be appointed by the respective Conventions thereof for the purpose of recommending
                an amended Constitution of Government upon which the Union of the States and the Confederated States with such of the non-slaveholding States as may concur therein can be safely effected; to which Congress, the Confederated States and the concurring non-slaveholding States ought to be invited to send Commissioners."
              </p>
              <p className="p-in-sp">The remaining clause of the amendment will then read :</p>
              <p className="p-in-sp">"And in the event that favorable responses on the part of the non-slaveholding <PageNumber num={547} /> States be not made to the proposed amendments to the Constitution, by the time appointed for the reassembling of this body," To that, I propose to add these words :</p>
              <p className="p-in-sp">
                "Said States of Delaware, Maryland, North Carolina, Tennessee, Kentucky, Missouri and Arkansas be notified that this Commonwealth will recommend to the people of Virginia to resume the powers granted by them under the Constitution of the United States, and to withdraw from the present Confederacy with the non-slaveholding States; and that they be requested to unite with the people of
                Virginia in such withdrawal ; and that it be recommended to them to act in such contingency, independently for themselves, and to withdraw either separately or in conjunction with such border slaveholding States as will unite in their action, whether any or all of said States will unite in the same or not."
              </p>
              <p className="p-in-sp">Mr. Chairman—I object utterly to this amendment offered by the gentleman from Fauquier. The first objection I have is that it expressly contradicts the 1 1 th resolution which you have adopted. I ask the gentleman what he does with that 1 1 th resolution if he proposes that we shall adopt his plan of ultimate adjustment. You have already declared that,</p>
              <p className="p-in-sp">
                "Virginia, therefore, requests the people of the several States, either by popular vote, or in Convention similar to her own, to respond at their earliest convenience, to the positions assumed in the foregoing resolutions, and the proposed amendments to the Constitution of the United States hereunto appended. And in the event that this Commonwealth fails to obtain satisfactory
                responses to her requests, from the non-slaveholding States, she will feel compelled to resume the powers granted by her under the Constitution of the United States, and to throw herself upon her reserved rights."
              </p>
              <p className="p-in-sp">
                Now, what does the gentleman's proposition propose? He proposes that if we shall not obtain satisfactory responses from the nonslaveholding States, we shall not then proceed to resume our granted powers and throw ourselves upon our reserved rights. But if we should fail to obtain favorable responses we should then take another step, and ask the border slave States for their
                concurrence in the action which we have already resolved upon ourselves. And, furthermore, he states, distinctly, that unless the border States will concur in our plan, unless they will agree to secede with us, he will not go for our withdrawal at all.
              </p>
              <p className="p-in-sp">
                Sir, I have great difficulty in understanding the gentleman. He said yesterday that he was unalterably opposed to any separate secession <PageNumber num={548} /> upon the part of Virginia. Well, then, if he opposes any separate secession, of course he means to say that he will wait for concurrent secession. There is no escape from that. He will wait.
                But, after waiting for the responses contemplated in the 1 1 th resolution, if they are not favorable, he now proposes that we take another step, to go with the border slave States for a conference to effect a concurrent withdrawal. In other words, he will make Virginia dependent upon the border States for the sanction of her own resolves. That is the amount of his
                proposition—nothing short of it.
              </p>
              <p className="p-in-sp">
                Then, sir, when the gentleman has obtained his proposition, he is to wait for favorable responses, six months at least. The shortest time that has been named is the first of October. In the next place, he wants the call of the border States after we shall have failed to receive favorable responses—I suppose at least two months afterwards. He is then to wait for the deliberations of
                that conference, how long? From three to five months. He is then to await the call of commissioners from the non-slaveholding States from two to four months, I suppose, which will make from fifteen to seventeen months before this plan could be carried out.
              </p>
              <p className="p-in-sp">
                Again, my third objection relates to those with whom we are to concur. Now, I do not speak upon the probabilities merely, but upon the almost fixed certainty of how that conference will stand. We were with the border States in conference, the other day. How did they agree with us there? Do we expect to agree with Delaware? Do we expect to agree with Maryland? Do we expect to agree
                with States where the indications are that they are at present in an almost abolitionized condition? No, sir, you will have on one side Virginia and North Carolina. Before the day your Congress will assemble Arkansas will be out of the Union, and will have taken her place with the Southern Confederacy. The probabilities, I say, are that you will have Virginia and North Carolina
                standing on one side, and Delaware, Maryland, Missouri, Kentucky and Tennessee on the other side, aided by all the influence of the Commissioners from the non-slaveholding States. What chance will Virginia and North Carolina have in such a Congress?
              </p>
              <p className="p-in-sp">
                My fourth objection is, that this proposition, by whatever name it is called, at last, when scrutinized and defined, is, in all its elements, in all its action, in all its proposed results a national Convention. But, the gentleman says, I have a great fear of a National Convention. I have told him from the beginning that I do fear that design which I see intimated in all his
                propositions. He cannot hide that "cat in the <PageNumber num={549} /> meal" from me. It is too plain. He may call it by another name than a National Convention. He may call it a Congress of border Slave States, to which the Confederate States and the concurring non-slaveholding States ought to be invited to send Commissioners. You may call it a
                Congress and you may invite border States and Confederate States, and all the concurring non-slaveholding States; still at best or at worst, it is but a National Convention. But let me tell the gentleman that the concurring non-slaveholding States will not be the only ones represented there. I know the Yankees too well not to know that they will fill that Convention with delegates
                from non-concurring States in order to overrule you.
              </p>
              <p className="p-in-sp">
                Sir, I am suffering this morning not only from hoarseness but from an affection of the left lobe of the lungs, which, I fear, is pneumonia. I, therefore, beg my friends to relieve me from the pain of straining my voice against their voices. I ought to be in bed and in the hands of a physician. It is impossible, with my ragged lungs and in my present nervous condition, to speak with
                ease, and I therefore ask that order may be preserved.
              </p>
              <div className="speaker" id="sp3155"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Chair will preserve order if practicable.</p>
              <div className="speaker" id="sp3156"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                I say, Mr. Chairman, that this is a National Convention. It calls too many States together. Too many States may come together; and what guarantee is there that that National Convention will bring its labors to a close in anything like the period of time in which to bring our rights and our remedies to a conclusion? Have you forgotten, Mr. Chairman, that it took from 1787 to 1789—I
                might say from 1785-6 to 1789—to form the present constitution of the United States? To form that instrument at a time when heroes and sages were consecrating their wisdom, their valor and their patriotism, to the permanent establishment of a constitution of civil liberty, with all minds concurrent, with every disposition of feeling coming together in one almost consolidated union,
                it took two years. How long, then, will it take in the present distracted state of the country, in the divided state of the public mind, in the present rampant state of excitement, not only to break up the present constitution, but to reform it?
              </p>
              <div className="speaker" id="sp3157"><span className="persName">Mr. R. E. SCOTT</span>—</div>
              <p className="p-in-sp">
                I will endeavor, again, to relieve the apprehensions of the gentleman from Princess Anne from the apprehension of a National Convention. If the gentleman will read my proposition, which has been printed, I think he will find that the Congress which is proposed to be assembled, is one to be composed entirely of delegates from the border slave States. If a Congress of the border
                <PageNumber num={550} /> slave States is a national Convention, then the amendment proposes a national Convention. Otherwise, it contains no such proposition. The invitation to the Confederate States, and to the concurring nonslaveholding States, is not to send delegates to the Congress, entitled to seats in the body, and to take part in the
                deliberations, but merely to send commissioners for consultation.
              </p>
              <p className="p-in-sp">
                The gentleman says that this amendment is inconsistent with the 1 1 th resolution. I see no such inconsistency. The 1 1 th resolution proposes that when unfavorable responses shall be made by the nonslaveholding States, to the propositions of this Convention, the State shall resume her granted powers, and throw herself on her reserved rights. What is there inconsistent with that in
                providing that this consultation shall proceed, or that the Congress shall be convened?
              </p>
              <p className="p-in-sp">
                The gentleman from Princess Anne may see it ; my perception is not sharp enough; I see no inconsistency whatever. When the State is to exercise this power, the 1 1 th resolution does not explain. It contains merely a declaration of intention—when that intention is to be carried out remains, I apprehend, for future determination. The State may execute that intention before she calls
                the Congress, or she may forbear it until the result of its deliberations shall be known.
              </p>
              <p className="p-in-sp">The gentleman says that I propose to bind Virginia to the action of the border States. I propose no such thing.</p>
              <p className="p-in-sp">The proposition is one of consultation. If agreement among the border States can be effected, we act in concert. We carry out the agreement. If agreement be not effected, it remains to the State to adopt, independently if she choose, just what action her judgment may dictate. The gentleman from Halifax [<span className="persName">Mr. BRUCE</span>]
                , put the question fairly when he stated that the proposition which he introduced was a competing proposition with that which I presented—the selection between independent State action and concerted action among the border States. And so the proposition of the gentleman from Princess Anne presents the same question, whether we shall consult with our sister border States and secure
                concert of action if it be possible, or proceed to independent action here. That is the question which this Convention will have to decide.
              </p>
              <p className="p-in-sp">
                Sir, already, as soon as the resolutions of the Committee of Twenty-one gained publicity, several of the border States acted on the suggestion—the mere suggestion—contained in that report. Missouri has appointed delegates ; Arkansas has appointed delegates, and Kentucky has passed a law providing for the election of delegates. Is it possible now, sir, when, pursuant to this
                prospective invitation, <PageNumber num={551} /> these States have acted and concurred in the proposition, for us to back out from it, and leave them exposed to the position in which such action will place them? I trust we will not do so. I trust it will be the pleasure of this Committee, the pleasure of this Convention, to go forward in the line of
                policy indicated, and secure concert of action among the border States, by previous consultation. If consultation secures concert, we shall obtain a strong position. If it fail, we will be but where we now are, driven, by no fault of ours, to the necessity of that separate State action to which, in my judgment, this State should never resort, except under the pressure of necessity.
              </p>
              <div className="speaker" id="sp3158"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                This is not a National Convention, sir! This is only a Congress of Border States, where commissioners from non-slaveholding States are to be kept waiting in the lobby, are they? Well, sir, it is a worse concern than I thought it was. Instead of being on the floor, speaking, showing their sentiments, showing their votes, opening the eyes of our constituents, the gentleman will keep
                the eyes of our constituents blinded to their sentiments and to their votes; and he tells me that that is not something worse than if they were on the floor as members. Do we not know the art, the contrivance, the cunning, the industry of the Yankees in wheedling us into compromises? Have we not heard gentlemen here condemning and almost damning us for acquiescing in the compromises
                that have been heretofore made? Are we to have the sixteen non-slaveholding States lobbying with all their influences over the delegates from Missouri, and Maryland, and Kentucky, which have Northern proclivities enough, God knows? Are we to have a sixteen-State power of lobby influence, which is to be intangible, invisible? Sir, that invisible power will be invincible. That
                invisible and invincible power will vanquish us and make us abolish slavery. No, sir; if you propose your National Convention, I will choose to take it actually, by strong preference over this contrivance, by which, unseen, unheard, we are to be honeyfugled out of our rights.
              </p>
              <p className="p-in-sp">
                The gentleman says they are there only for consultation, that they will have no right to seats on the floor, no vote, no right to speak. Will there be no right of caucusing, no right of conferring in private, no right of presenting ten thousand temptations for the union with the North? But the Confederated States are to be represented there also, to come in conflict with the North
                again in that Congress, as they have been in conflict in the Congress at Washington. Did any man ever know two enemies, private or public, to sit down to count over their contests and the causes of them, and be drawn any nearer together? <PageNumber num={552} /> No, sir. Bring these conflicting elements together, and there will be as much antagonism
                among the Border States themselves as there is between the Confederated States and the North.
              </p>
              <p className="p-in-sp">
                But the gentleman says if I read his printed document, I will see what it means. I ask him what it comes to if the Border Slave States, with all their consultation, will not be able to concur with one another? What will we do then? The gentleman tells us, plainly, that we are to stand where we are. And where stand we now? Not at the end of the beginning, but at the beginning itself
                of the beginning, of starting some form of action for the State of Virginia. That is precisely the motive which I impute to the gentleman, of running the chances, instead of taking the alternative. He takes the chances of going through all these meandering mazes, in order to do that. That we may get a Congress to concur all conflicts between non-slaveholding and Confederate
                Commissioners, before a border State Congress, which has conflicts enough in itself, and that failing, as it is morally certain it will fail, he is for rubbing out and beginning again. Now, I ask if the people of this State are to be kept in suspense by this kind of action? The gentleman says that the eleventh resolution does not declare how or when we are to resume our power. He
                gives an illustration of what we are to expect.
              </p>
              <p className="p-in-sp">
                When I read that 11th resolution I interpreted it, as I think every plain man will interpret it, to mean, that if a response is returned that will be unsatisfactory, that Virginia will withdraw, will resume the powers which she delegated under the constitution. The gentleman says no—he won't do it, because there is no time fixed; no mode prescribed; but he will resort to something
                else; and, if his second alternative fails, why, he will come back and begin again. After this he will not resume the power. The gentleman does not say, that under any contingency he is for resuming power. I ask him if hostilities shall arise while this Congress is sitting—if even then he would resume the powers? Sir, how easy will it be to have this border Convention to fail.
                Gentlemen who want to keep us in a Northern Confederacy will only have to prevail upon the five States to two, not to concur; and as the gentleman is pledged not to go out separately, why, sir, it must fail—it will inevitably fail if all these border, confederate and non slaveholding States will be allowed to disturb its deliberations.
              </p>
              <p className="p-in-sp">
                Sir, what harm is there in going out separately? What do we lose by going out separately? What do we lose by acting independently? If I had been arbiter of secession for secession's sake per se, I never would have had the border States to go out with the seven seceded <PageNumber num={553} /> or Confederate States. Why? Because we are the van-guard to
                protect the retreat of their rear guard; so now, I would never have the border States to join in and go out together. The moment that you do it, you will inevitably have civil war. But let Virginia step aside from the family jars herself, form independently her Constitution, and tender it to all the States North and South, East and West, she would then stand under the full panoply
                and protection of her own Constitution, and these non-slaveholding States would be deterred from making war upon us, because she would still have Maryland, Kentucky, Tennessee, and I would even keep North Carolina in between us and the South, while she would have us between her and the North, while she tendered her Constitution to the other States just as we do. Just as we protected
                the retreat of the seven seceded States, so would the remaining border States protect us if we should go out. Do we not place ourselves, by this course of separate and independent action under the aegis and protection of these States, when, if all seceded together, we would be in no attitude to present the Constitution which we might adopt, to the other States for their adoption?
              </p>
              <div className="speaker" id="sp3159"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">Is it in order to offer an amendment to the amendment?</p>
              <div className="speaker" id="sp3160"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">It is not in order, sir; the privilege of amendment is exhausted.</p>
              <div className="speaker" id="sp3161"><span className="persName">Mr. JACKSON</span>—</div>
              <p className="p-in-sp">I call the yeas and nays, upon the amendment. The call was sustained.</p>
              <div className="speaker" id="sp3162"><span className="persName">Mr. GILLESPIE</span>—</div>
              <p className="p-in-sp">Is it too late to move to make an alteration in the phraseology of the resolution?</p>
              <div className="speaker" id="sp3163"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">Too late, sir.</p>
              <p>
                The question was then taken by yeas and nays, on the amendment, and resulted, yeas 54, nays 79, as follows :<span className="note" id="Note63"><span className="noteLabel">2</span>The Journal adds Staples to the negative votes listed in the Proceedings.</span>
              </p>
              <p>
                YEAS-Messrs. Ambler, James Barbour, Blakey, Boisseau, Borst, Bouldin, Branch, Bruce, Cabell, Cecil, Chambliss, Chapman, Conn, Flournoy, Garland, Graham, Gregory, Jr., Goggin, J. Goode, Jr., T. F. Goode, Hale, C. Hall, L. S. Hall, Harvie, Holcombe, Hunton, Isbell, Kent, Lawson, Leake, Macfarland, C. K. Mallory, J. B. Mallory, Marr, Marye, Sr., Miller, Morris, Morton, Neblett,
                Richardson, Seawell, Sheffey, Slaughter, Speed, Strange, Sutherlin, Thornton, Tredway, R. Turner, Tyler, Whitfield, Williams, Wise, Wysor-54.
              </p>
              <p>NAYS-Messrs. Janney [President]
                , Armstrong, Aston, Baldwin, Baylor, Berlin, Blow, Jr., Boggess, Boyd, Brent, Brown, Burdett, Burley, Byrne, Campbell, Caperton, Carlile, Carter, Clemens, Coffman, C. B. Conrad, R. Y. Conrad, Couch, Critcher, Custis, Dent, Deskins, Dorman, Dulany, Early, Echols, French, Fugate, Gillespie, Gravely, Gray, A. Hall, E. B. Hall, Hammond, Haymond, Hoge, Holladay, Hubbard, Hughes, Hull,
                Jackson, M. Johnson, P. C. Johnston, <PageNumber num={554} /> Lewis, McComas, McGrew, Marshall, Maslin, Masters, Moffett, Moore, Orrick, Osburn, Patrick, Pendleton, Porter, Price, Pugh, Rives, R. E. Scott, Sharp, Sitlington, Southall, Spurlock, C. J. Stuart, Summers, Tarr, Tayloe, Waller, White, Wickham, Willey, Wilson-79.
              </p>
              <p>So the amendment was rejected.</p>
              <div className="speaker" id="sp3164"><span className="persName">Mr. NELSON</span>, when his name was called, said :</div>
              <p className="p-in-sp">Mr. Chairman, I paired off with the member from Barbour on the "Report on Federal Relations," to enable him to visit a sick child. I shall wait till next Saturday for that gentleman to return. On and after that day, God willing, I shall vote on all questions.</p>
              <p><span className="persName">Mr. A. M. BARBOUR</span> paired off with <span className="persName">Mr. WM. C. SCOTT</span>; he stated that otherwise he would have voted no.</p>
              <p><span className="persName">Mr. KILBY</span>, it was announced, was absent from sickness.</p>
              <p><span className="persName">Mr. HARVIE</span> stated that <span className="persName">Mr. COX</span>, of Chesterfield, was absent from illness.</p>
              <p><span className="persName">Mr. CRITCHER</span> stated that <span className="persName">Mr. MONTAGUE</span> and <span className="persName">Mr. COX</span>, of King and Queen, were unavoidably absent on account of illness in their families.</p>
              <div className="speaker" id="sp3165"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I now propose to add to the amendment of the gentleman from Fauquier [<span className="persName">Mr. SCOTT</span>] , the following:</p>
              <p className="p-in-sp">"And in the event that the said Congress shall not concur in measures of adjustment of pending difficulties, then it be recommended to the people of Virginia to resume the powers granted by them under the Constitution of the United States."</p>
              <div className="speaker" id="sp3166"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                The House has just voted down a proposition to resume powers, when the non-slaveholding States shall return even unsatisfactory answers to our demands. And they have now shown a disposition to adopt the proposition of the gentleman from Fauquier, to go into this Congress of the border States—calling Confederate States and non slaveholding together. But he does not indicate by this
                proposition any alternative, any ultimatum, any resort in the event that this Congress shall come to no satisfactory conclusion. Now, I propose to designate some time after some contingency, such contingency as yourselves will adopt when you will come to the point. Sir, I do not want to sit, like the gentleman from Fauquier, until gentlemen shall multiply contingency upon
                contingency; shall prolong from time to time, and never cease to endeavor as long as endeavor finds a resource. Why, sir, resorts are infinite in number and variety.
              </p>
              <p className="p-in-sp">
                If gentlemen say that they will try all effort, if they will wait until all resources are exhausted, what do they say more or what do they say less than that they never will exhaust their resources? I repeat, <PageNumber num={555} /> resources are infinite in number and in variety. Are we to be kept all the time hesitating in this namby-pamby way,
                dodging the issues to the right hand and then to the left, pursuing a torpid and indirect policy? Is Virginia to be placed in that attitude? I have asked you to let Virginia stand erect, alone, independent, self-reliant, upon the rock of right. You say no—we will call the border slave States together; they shall debate for us, consult with one another, and they shall have a lobby and
                advice from the Confederated States and the non-slaveholding States. We will be patient, we will submit as long as you will give us the hope that some time or other you will act; if you will only say, after you have resorted to every point of ultimata of demand and refusal, and have failed, that you will then act for yourselves, as Virginia declared for herself in the midst of the
                American Revolution, in the midst of a gloom more despairing than this gloom that now settles over us, when the strength of our whole population rested upon the Appalachian slope. Now, the mighty valley of TransAlleghany is no longer as young Hercules in his cradle, but it is Hercules in his manhood, armed with his club. Sir, she can meet the Gorgons dire of all this controversy.
                Eastern Virginia has multiplied in wealth and power. She can send from the homes of her twenty or thirty thousand slaveholders, twice that number of young and vigorous sons that have the money to buy the best of fire-arms, their ammunition, their provision, their horses, and who are ready to go, like Knights, to the field of this contest. Will you not say, relying upon the Hercules
                of the West and the chivalry of the East that some time or other Virginia shall leap into the lists and raise her own "Sic Semper Tyrannis," and settle this controversy by her authority. Sir, her physical arm, and her physical power are nothing compared with her great moral and political influence. Let her speak the word—let her not speak indefinitely—let her say that there is a time
                to act. You have been suffering these wrongs for nearly forty or fifty years. Congress has tried to settle them. State after State has resolved, and this State, years ago, resolved to the last extremity; and you
                [turning to <span className="persName">Mr. SCOTT</span>]
                , had the honor of repealing those last extremity resolutions. From that time to this I have been keeping you in my eye. You repealed the last extremity resolutions, and this proposition to-day is another repeal of the last extremity resolutions. I believe it is the boast of my friend to call them the last extremity resolutions. I ask him, if it is any extreme now to take him at his
                word, after adopting the 11th resolution that does not fix the time when and the manner how? Will it be precipitate? will it be asking too much to fix the time and <PageNumber num={556} /> the mode and manner in which he will some time or the other come with the ultima ratio? What is our ultima ratio? Arms are the ultima ratio of kings; not necessarily
                our ultima ratio. Our fathers set the example of breaking up the Confederation; States one by one, two by two and three by three, coming out of the old Confederation and framing the present glorious and wise Constitution, under which we have now lived for sixty years. They did it in a peaceful way. Why cannot we do it in the same way? The gentleman told us, yesterday, that we are not
                to be precipitated into separate State secession.
              </p>
              <p>Here the hammer fell.</p>
              <div className="speaker" id="sp3167"><span className="persName">Mr. SCOTT</span>, of Fauquier—</div>
              <p className="p-in-sp">
                The gentleman from Princess Anne desires us to adopt an amendment by which I understand we are to declare to our associate border States that in case we fail to agree, we shall take independent action, and resume the powers that we have granted to the Federal Government. Now, sir, for my life I cannot see the logical necessity for any such declaration. We have already declared in the
                11th resolution that upon receiving unfavorable responses from the non-slaveholding States we intend to resume the powers granted to the Federal Government. If that resumption shall be postponed till the result of the consultation is ascertained, does it not follow that upon that result action is to take place?
              </p>
              <div className="speaker" id="sp3168"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">No, sir.</p>
              <div className="speaker" id="sp3169"><span className="persName">Mr. SCOTT</span>—</div>
              <p className="p-in-sp">
                What then does the 11th section mean? The gentleman from Princess Anne is attempting to avoid delay. He wishes an instant resumption. The resolutions already adopted postpone the time of that resumption till the response is made by the non-slaveholding States, and as soon as this response comes, according to the terms of the resolution, this State will be at liberty then to act. The
                gentleman who desires hasty action now proposes, by his own amendment, to defer that until the farther time of the termination of this proposed Congress. In pursuit of speedy action, as he professes to be, his profession is to clog that action and to pledge the State to forbear to resume this power until the result of the Conference shall be known. The gentleman says that he is
                opposed to this namby-pamby meandering process. He is opposed to my proposition, because it fixes no definite time for final action. Well, sir, what is the nostrum prescribed by the gentleman from Princess Anne
                [Mr. WisE]
                ? His nostrum is to take the State at once out of the Union—to resume all the powers granted by her to the federal government—and then what? To join the Confederate States? No! What? To take one month, two months, three months, six months, it may be, <PageNumber num={557} /> for Virginia to determine by herself, not for herself, but for all the States—a
                constitution which she is to propose and to recommend for the adoption of all the States. What time would this nostrum for our evils require for its proper exhibition? One, two, six months debate to adopt a constitution for all the States—and that constitution, according to the gentleman's plan, must be submitted to all the States. How long a time does the gentleman from Princess
                Anne suppose would be consumed in that operation? Why, sir, he would have to count not by months, but by years. He is getting over to Mr. Seward's scheme of one, two and three years delay. How long would it take the thirty-three States of this Union to deliberate, consult, advise and debate a constitution to be proposed by the State of Virginia for their adoption?
              </p>
              <p className="p-in-sp">
                The gentleman says that we do not indicate what is to be done when the attempt shall fail—does his nostrum cure that evil? Is there anything in his nostrum to afford a remedy for that? What is his scheme? To join a Southern Confederacy? That is no part of it. This State is to resume her independent powers; but how long is she to maintain them? The gentleman in his desire to criticise
                the schemes of others, in his indulgence of what I think is hypercriticism of the schemes of others, is blind to the defects of his own scheme, and he is blind to the fact that his own scheme involves the same objection to a degree infinitely beyond what the scheme does that he criticises. I ask him what he proposes for Virginia to do while his scheme is progressing.
              </p>
              <p>Here the hammer fell.</p>
              <div className="speaker" id="sp3170"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                I submit it to this Committee, whether that gentleman has met the points I made, with any logical reply—whether his whole argument is not perfect sophism. I ask him when he is going out; and what is his reply to me? He says in your going out you will have to wait as long as I propose to wait before I go out. Why does not the gentleman see the absurdity in which he involves himself?
                The point between us is when we will go out of the Union. When? I show him that at the best it will take 18 months or 2 years for him to go out; and when he replies, the gentleman proposes to resume powers at once; and then he goes on to show that because it will take him two years, perhaps five, ten or twenty, to propose a Constitution that would be accepted, I would take as much
                time as he takes, when I have already gone out.
              </p>
              <p className="p-in-sp">
                Now, are we schoolboys—are we sucklings to be treated with primer replies like this? He says that I am for taking independent action alone. I beg his pardon. My proposition proposes conference, a joint action, <PageNumber num={558} /> or independent action. We will elect to take one or be compelled to take the other. He says that there is no logical
                necessity for this amendment. The Committee will judge whether there is a logical necessity for that gentleman to say when the time will come, and what the manner will be in which he will resume these powers granted under the Constitution. I have as yet seen no time fixed, but he says that the 11th section already declares that we will at some time or the other resume these powers;
                and the gentleman asks—do I not perceive if the Congress fails, that then the contingency will occur on which the 11th section depends?
              </p>
              <p className="p-in-sp">
                No, sir! No sir. When gentlemen have made a portion of the 11th resolution to depend upon its own contingency, to wit : that the nonslaveholding States return unsatisfactory answers, I say to the gentlemen, if that fails I am not to expect that they will regard the failure of another resort as a ground for applying the eleventh resolution? Why? Because they refused to apply the 1 1
                th resolution, even on its own contingency, of receiving answers from the non-slaveholding States.
              </p>
              <p className="p-in-sp">
                Again—the gentleman says I am for hasty action. I am not for hasty action. I am for acting when all the conditions of reasonable time and circumstances to perform a contingency shall have expired. But admit even that I am for hasty action. He says I am for deferring. I beg his pardon. He is for deferring, and I am obliged to await his time. He postpones the time himself. I say I take
                him at his own time, I don't go beyond his own time, I place him where he places himself, and ask him "will you act then, sir, at your own time?" And what is his complaint? That I am for hasty action. "Well," I reply, "you are not for hasty action."
              </p>
              <p className="p-in-sp">
                I want a piece of work done; I want it done immediately, but the man to whom I apply to do it for me says he cannot come before such a day—naming the time—to do my work. I ask him, "Will you come then?" "O," he says, "you want it done immediately; I cannot come then, because you are for my coming immediately." Would it not be nonsense for him to give me such an answer as that? "Tell
                me then, sir, when you will come?" It is in that spirit I ask the gentleman from Fauquier when he will act? It is in that spirit I tell him, "If I am for hasty action, you are not." I ask him : "Will you act at your own time, the time you propose to act?" And he will not say that he will act then.
              </p>
              <p className="p-in-sp">
                Again, he asks, what nostrum I propose to cure the evil. Sir, when you rise from Committee of the Whole and resolve yourselves back into Convention, and when I can offer a substitute for the whole <PageNumber num={559} /> report, I will show the gentleman that he may choose either one of two alternatives. One of them will be either to remain in the
                Union, and tender such an ultimatum as ought to be tendered—which I now fear is the worst mode of restoring the Union—or to step aside, as I said, from the family of States, and propose a Constitution to all the States, non-slaveholding and slaveholding.
              </p>
              <p className="p-in-sp">
                But the gentleman says I will take the State out at once; then to join whom? I say to stand independent and alone until we, ourselves, shall draw such a Constitution as shall declare equal justice to all, joining with none until we can tender it to all, and then to join with any who will accept it with us; to declare, like your fathers did, when they came out of the old
                Confederation. But he says, suppose none will accept it, what then? Then, sir, I would select which Confederacy I would join. Then, if none would adopt my Constitution, I would unite myself to whom I believed, in fortune, fate, faith, interest, honor, would impel me, I would go where the gentleman says his preference is to go—I would go to the Confederated States of the South. I ask
                him candidly, what would he do? where would he go in such an event? I say, then, that mode would be more direct; it will re-construct the Union in a shorter time than his mode will reconstruct it, and it will at once quiet the public mind, and, in my opinion, preserve the public peace.
              </p>
              <p className="p-in-sp">But he asks what time will I take to form this Constitution? I answer, my own time. My own time to perfect it, my own time to make it acceptable to all. After we have gone out, what matters it what time it takes? Time is now an all essential element. It might be important then, but it would not be as essential an element then as it is now.</p>
              <p className="p-in-sp">
                But he says, I would take such a time that I would fall in with Mr. Seward's scheme. I say that Mr. Seward's scheme is a dangerous scheme. My friend may confer with conservative Black Republicans, but sir, I never knew that rara avis in terra called a conservative Black Republican; I cannot conceive of such an animal being conservative at all. A Black Republican conservative? No,
                sir. What I have said to the gentleman repeatedly, I repeat now, that in 27 years experience I never knew one jot or tittle of the rights of the South to be lost by the extremest of Black Republicanism ; but every loss, all the cost that we have encountered in the South, is by these compromises with the conservative Black Republicans of the order of Wm. H. Seward.
              </p>
              <div className="stage it">[Here the hammer fell.]</div>
              <div className="speaker" id="sp3171"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The question will be on the amendment.</p>
              <PageNumber num={560} />
              <div className="speaker" id="sp3172"><span className="persName">Mr. WILSON</span>—</div>
              <p className="p-in-sp">I propose to suggest an amendment to that offered by the gentleman from Princess Anne. It is, after the words "pending difficulties" to insert the words "acceptable to Virginia," for the reason that the Congress may agree to some settlement that might not be acceptable to Virginia.</p>
              <div className="speaker" id="sp3173"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">If it would not be acceptable to Virginia, of course they would not concur in it. I do not think the change is necessary. I think it is all expressed in the amendment as it stands.</p>
              <div className="speaker" id="sp3174"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The question will be upon the amendment.</p>
              <div className="speaker" id="sp3175"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I will accept the suggestion of my friend [<span className="persName">Mr. WILSON</span>] .</p>
              <p>No objection being interposed, <span className="persName">Mr. WISE</span> accepted <span className="persName">Mr. WILSON</span>'S amendment.</p>
              <div className="speaker" id="sp3176"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I call for the yeas and nays.</p>
              <p>The call was sustained.</p>
              <p>The vote was then taken, and the amendment was rejected—yeas 48, nays 80, as follows :</p>
              <p>
                YEAS-Messrs. Ambler, Blow, Jr., Boisseau, Borst, Bouldin, Boyd, Cabell, Cecil, Chambliss, Chapman, Coffman, Conn, Garland, Graham, Gregory, Jr., Goggin, John Goode, Jr., T. F. Goode, Hale, L. S. Hall, Harvie, Holcombe, Hun-ton, Isbell, Kent, Lawson, Leake, C. K. Mallory, Marr, Marye, Sr., Miller, Morris, Morton, Neblett, Parks, Richardson, Seawell, Sheffey, Speed, Strange, Thornton,
                R. H. Turner, Tyler, Whitfield, Williams, Wilson, Wise, Wysor-48.
              </p>
              <p>NAYS-Messrs. John Janney [President]
                , Armstrong, Aston, Baldwin, Baylor, Berlin, Boggess, Branch, Brent, Brown, Bruce, Burdett, Burley, Byrne, Campbell, Caperton, Carlile, Carter, Clemens, C. B. Conrad, Robert Y. Conrad, Couch, Critcher, Dent, Deskins, Dorman, Dulany, Early, Echols, Flournoy, French, Fugate, Gillespie, Gravely, Gray, A. Hall, E. B. Hall, Hammond, Haymond, Hoge, Holladay, Hubbard, Hughes, Hull, Jackson,
                M. Johnson, P. C. Johnston, Lewis, McGrew, Macfarland, J. B. Mallory, Marshall, Maslin, Masters, Moffett, Moore, Orrick, Osburn, Patrick, Pendleton, Porter, Price, Pugh, Rives, R. E. Scott, Sharp, Sitlington, Slaughter, Southall, Spurlock, Staples, C. J. Stuart, Summers, Sutherlin, Tarr, Tayloe, Waller, White, Wickham, Willey-80.
              </p>
              <div className="speaker" id="sp3177"><span className="persName">Mr. THORNTON</span>—</div>
              <p className="p-in-sp">Mr. Chairman—I desire to offer an amendment to the amendments proposed by the gentleman from Fauquier. It is to strike out all after the word "body," in the fourth line of his amendment and to insert what I shall read. I will read the section as it will appear with my amendment inserted. It will read as follows:</p>
              <p className="p-in-sp">
                "And in the event that favorable responses on the part of the nonslaveholding States be not made to the proposed amendments to the Constitution, by the time appointed for the re-assembling of this body, the State of Virginia will immediately resume the powers which <PageNumber num={561} /> she has delegated to the Federal Government under the Federal
                Constitution; and this Convention would earnestly recommend to the slaveholding States yet remaining in the Union to assemble at once in Convention and provide for a similar resumption of their granted powers; and it is also the opinion of this Convention that, in the contingency which has been indicated, all the slave States, as well those in the Union as those forming the
                Confederate States, should assemble in Congress for the formation of a permanent Constitution of Government."
              </p>
              <p><span className="persName">The CHAIRMAN</span> stated the amendment, and announced that the question recurred upon it.</p>
              <div className="speaker" id="sp3178"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">I call for the ayes and noes.</p>
              <p>The call was sustained.</p>
              <div className="speaker" id="sp3179"><span className="persName">Mr. THORNTON</span>—</div>
              <p className="p-in-sp">
                Mr. Chairman, in my judgment, the true policy for Virginia to have pursued at this time, would have been an immediate resumption of the powers granted by her under the Federal Constitution. I did not think, after the efforts she had made to compose the differences between the sections, that it was becoming in her to present herself again before the Northern States ; I will not say
                with "bated breath and whispering humbleness," but to go, as the Committee here propose to go, with lamentations on their lips, and singing a jeremiad, and asking them to adopt some amendments to the organic law of the Confederacy. But the Convention in its wisdom, has determined otherwise. It has determined to present these amendments, and it has also placed upon its records the
                pledge, that if this Committee fails to obtain satisfactory responses from the non-slaveholding States to those amendments, she will be compelled to resume those granted powers, and to throw herself upon her reserved rights.
              </p>
              <p className="p-in-sp">
                Now, sir, the gentleman from Fauquier proposes, in the event of this unsatisfactory answer, to do what? To convene a Congress of the border slave States, for the purpose of forming a permanent constitution of government. Why, sir, should the slave States that now compose the Confederate States be excluded from that Congress? what position will Virginia be in, if she goes into the
                Congress with the border slaveholding States? Look, sir, at their population. Look at their opinions, as declared by their representatives in Congress.
              </p>
              <p className="p-in-sp">
                Why, sir, there are four of those States that by this proposition are to join with Virginia in the formation of that permanent constitution and government, that have not, all combined, as many slaves as Virginia by 200,000. Five of them, that have, combined, only a few <PageNumber num={562} /> thousand more slaves than Virginia herself owns, can adopt
                this permanent Constitution and Government. There is Maryland, that has maintained Winter Davis upon the floor of the House of Representatives; there is Missouri, that has maintained Blair upon the same floor; there is Delaware, that has a Black Republican to speak her voice in the Congress of the United States. And it is with these States that the gentleman from Fauquier proposes to
                consult, in order to form a permanent Constitution, and he proposes to exclude the seven States that have now entered into a Confederacy south of us—who have never done anything to injure our institutions—all of whose opinions and views have been in accordance with our own. That, sir, is the Congress the gentleman proposes, to form our Constitution. Why, sir, I ask, should these
                Confederate States be excluded from the initiative? Why not permit them to come in and unite with us, unite their counsels with ours in the formation of the organic law of the Confederacy.
              </p>
              <p className="p-in-sp">
                I confess, Mr. Chairman, I have never desired to see Virginia placed in the position of an applicant to the Confederate States to be admitted as a member of that Union. I have thought that her position, her power and her wealth, would entitle her to demand that the Confederated States should hold a new Congress, and in that Congress for the formation of a permanent Constitution, the
                voice of Virginia should be heard as well as the voice of Georgia, South Carolina, and the other Gulf States that now compose the Southern Confederacy. But while I claim that right for Virginia, I am willing to yield the same thing to the Gulf States. Do you wish to assemble in Convention with Delaware, Maryland, North Carolina, Tennessee, Kentucky, Missouri, and Arkansas, and form a
                Constitution which you will seek to impose upon the Gulf States? Do you not want their counsels in the formation of this permanent Constitution and Government? Is it proper, is it right upon our part, when we would claim from the Confederated States that we should be permitted to have a voice in the establishment of a Constitution, that we should deny to them—as the proposition of
                the gentleman from Fauquier does deny to them—the right of being heard in that Congress which is to form our Constitution and Government?
              </p>
              <p className="p-in-sp">
                Now, Mr. Chairman, it appears to me that the amendment which I offer is one that will secure the Conference which seems to be so much the favorite of the gentleman from Fauquier and those who are acting with him. It will secure a Conference, too, in a proper manner. His amendment, if adopted, will divide the slave interest; his amendment, if adopted, will raise up antagonism between
                the border slaveholding <PageNumber num={563} /> States and the cotton slaveholding States. They have a Constitution and a Government, and if we meet at Frankfort, Kentucky, and form another, what will be more natural, what more reasonable—I had almost said—what more inevitable, than that a conflict would grow up between the two Constitutions so that
                the slave interest of this country would never be brought into harmonious union?
              </p>
              <p className="p-in-sp">
                Now let me say to the members of this Committee, that if they desire a conference, let it be a conference that will not leave the slave-holding States divided in their counsels. Let it be a conference that will not say to some of the States, "You are favorites, you can come here upon the floor of the Congress of the States, and assist in the formation of this Constitution ;" but
                which shall say to others who are interested to precisely the same extent that we are, "You are to be excluded, you can hold your position in the lobby, you can meet us outside out of the Hall, and give us your suggestions, but you shall have no vote and no voice in the matter." I repeat, Mr. Chairman, if we are to have a conference let it be a conference of all the slaveholding
                States.
              </p>
              <p>Here the hammer fell.</p>
              <div className="speaker" id="sp3180"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">
                I am not at all surprised, Mr. Chairman, to see a gentleman who has already voted in this body to take Virginia directly out of the Union by herself, without conference with any other State North or South, consistently following out that cardinal idea of the secession policy and objecting to all conference, whenever the objection can be made. But, sir, I take it that this body has
                determined to make an appeal to the non-slaveholding States of this Union. I take it for granted that this body did not regard itself as indulging in "unmanly jeremiads" in making that appeal; and I do not propose to discuss now the manhood or the chivalry involved in the proposition. But, sir, this body has determined to make the appeal to all the non-slaveholding States of this
                Union and to wait a reasonable time for their responses. Now suppose that favorable and satisfactory responses come from Pennsylvania, New York, Ohio, Indiana and Illinois. Suppose those States, when thus appealed to, declare their willingness to give us every thing that we have asked and every thing that we could desire, but that all the non-slaveholding States do not send such
                responses. Does the gentleman from Prince Edward recommend, that in the face of this declaration of just and fair purposes upon the part of these non-slaveholding States, we shall turn our backs upon them and refuse any further association with them in the attempt that we shall make to carve out our future destiny? If he does, it seems to me
                <PageNumber num={564} /> he would treat their overtures of friendship and justice with singular discourtesy and unkindness.
              </p>
              <p className="p-in-sp">
                Now, sir, in this proposition of the gentleman from Fauquier, it is contemplated to resume the sovereignty of Virginia, and to call upon all the other States to resume their sovereignty to the extent of going into a Congress, to build up a new government, which, to my mind, is a sufficient declaration of resumption of sovereignty for any present purpose. And we ask all those of the
                free States who concur with us, to send commissioners; we ask the Confederate States also to send commissioners. It is objected that we do not place the Confederate States on any footing above that of the free States. It is said that we ought to invite them into the Congress, and that we ought not to invite any of the free States even to send commissioners. Why not? The Confederate
                States have left us and set up for themselves, without conference or consultation with us, and they declare that they never will, under any circumstances, unite with us again, except upon their own terms, and on their own Constitution. Now, sir, are we authorized to expect that those States will consent to come into a Congress with us and build up a new government? If we are to
                believe all we hear, I think they are already well satisfied with their own government and their own constitution. Has the gentleman from Prince Edward any reason to suppose that they will throw of that government and that constitution, and go into a fair and equal Congress with us, for the purpose of building up another government and framing another constitution? Unless he is, it
                seems to me the invitation ought not to be extended to them. And, sir, if the free States on the border have in the meantime indicated a purpose, a willingness to do us justice, to secure to us all we demand or desire, are they not to be asked to send commissioners to confer with us? Sir, I for one, as a Virginian, am not willing now to declare a purpose to cut loose from all of the
                free States. The interests of one half of Virginia—as I asserted the other day in another debate—commercially and industrially, are identified and bound up with the free States of the border in such a way that this body would show a most culpable disregard of the great interests of one half of the people of this Commonwealth, if they could consent to withdraw forever from all
                association with these border free States.
              </p>
              <p className="p-in-sp">
                We are bound, sir, to look at the material interests of our people. We are bound to look to what is essential for their prosperity and I for one am not prepared to declare here that in the event of a failure to get amendments to the present Constitution under the present Government, we are going to turn our backs upon friends who are
                <PageNumber num={565} /> willing to stand by us, upon associates whose interests are identified with ours, and whose feelings I verily believe are strongly enlisted in our behalf. I take it for granted that this body is not going to forget that Eastern Virginia is not this entire Commonwealth; aye, sir, that is not half of this Commonwealth; and when
                gentlemen talk about the Southern tendencies of the State, they forget entirely that the political power of this Commonwealth, the great controlling body of this Commonwealth lies in the West, and that that great West has interests demanding the attention of this Convention and that will demand to be considered in any future adjustment of the fate and fortunes of the State of
                Virginia.
              </p>
              <p>Here the hammer fell.</p>
              <div className="speaker" id="sp3181"><span className="persName">Mr. THORNTON</span>—</div>
              <p className="p-in-sp">
                I am sure, Mr. Chairman, that not one word has ever fallen from me upon this floor, or in any private conversation with the members of this Convention, that could induce any one to suppose that I imagined Eastern Virginia was the whole of this Commonwealth. Sir, there has been nothing that has given me more pain during the sessions of this body, than to hear this question of the
                sections of our State introduced here upon all occasions, and into all discussions. It appeared upon the first day of the Convention in the nominations for door keeper and Sergeant-at-Arms. Now, sir, I do not claim that Eastern Virginia is the whole of the State, and I would be the last man who would do anything that would injure what I believe to be the interests of Western
                Virginia. I feel that the whole Commonwealth is my mother Commonwealth. I feel the sentiment taught in that noble German hymn that I learned in childhood :
              </p>
              <div className="lg">
                <div className="l">"Great God, look down and bless this land,</div>
                <div className="l">And give her noble children souls</div>
                <div className="l">To cherish while existence rolls,</div>
                <div className="l">To love with heart and aid with hand</div>
                <div className="l">Our universal Father-land."</div>
              </div>
              <p className="p-in-sp">
                That is what I wish to do and what I intend to do. But the gentleman from Augusta says, suppose some of the non-slaveholding States give favorable responses, would you exclude them from this Congress? I propose no more to exclude them than does the amendment of the gentleman from Fauquier. I do not see what high and honorable position he grants to these non-slaveholding States by
                asking them to send commissioners to a Congress where they are neither to be allowed to vote nor speak. I do not see, if left to him, what great boon is given to the non-slaveholding States, whose interests the gentleman from Augusta desires to subserve.
              </p>
              <PageNumber num={566} />
              <p className="p-in-sp">
                Now, sir, the point of my objection to the amendment of the gentleman from Fauquier is one which has not been touched by the gentleman from Augusta. It is that, while the gentleman from Fauquier was proposing a Congress to form a permanent constitution and government for us, he chose to exscind these confederated States except by granting—what was intended as a boon, but what is
                really nothing. What he gives them is really as vapid as air, as worthless as dross. But, says the gentleman from Augusta, excusing himself for treating these Confederate States in that manner, they went off without consultation with us. Aye, sir, did not you reject the consultation yourselves? Was not that gentleman who is now at the head of the Treasury Department of the
                Confederate States, here in Richmond? Did you not feast him; did you not drench him with champagne, and then say to him, "go home and say to your people, in our opinion separate State action is the way to manage this thing?" How was it with the sister State of Mississippi? Did she not have her commissioner here? Did not she ask for this very conference; and did not you repel and
                reject her? And now, the gentleman gravely rises before this Committee, and proposes to hold a Congress from which one half of the slaveholding States are excluded, and bases his reason for doing so upon the fact when they did offer to consult you, you told them to go home and rely upon separate State action. And when they take your advice, you tell them, "why you did not consult
                us." Sir, these States did not object to a conference; they proposed a conference at a time and under circumstances when it might have been held. They did not tell you they could hold a conference when the General Government was, perhaps, sending forces to beleaguer them, to interrupt their commerce, to collect imposts from them and make them pay taxes whether they would or not ;
                they asked you to consult with them at a time and under circumstances when consultation might have been of avail. You rejected then, and now when you propose to go into this conference which you then rejected upon their invitation, when acting upon your advice they have adopted separate State action, you decline to invite them to the conference on the ground that they went out
                without consulting you.
              </p>
              <p>Here the hammer fell.</p>
              <p>The question being upon the amendment to the amendment, on which the yeas and nays had been ordered, it was taken and decided in the negative—yeas, 54, nays 77, as follows :</p>
              <p>
                YEAS-Messrs. Ambler, J. Barbour, Blakey, Boisseau, Borst, Bouldin, Branch. Bruce, Cabell, Cecil, Chambliss, Chapman, Conn, Flournoy, Garland, Graham, <PageNumber num={567} /> F. Gregory, Jr., Goggin, J. Goode, T. F. Goode, F. L. Hale, L. S. Hall, Hammond, Harvie, Holcombe, Hunton, Isbell, Kent, Leake, C. K. Mallory, J. B. Mallory, Marr, Marye, Miller,
                Morris, Morton, Orrick, Parks, Richardson, Seawell, Sheffey, Slaughter, Southall, Speed, Strange, Sutherlin, Thornton, Tredway, R. H. Turner, Tyler, Whitfield, Williams, Wise and Wysor-54.
              </p>
              <p>NAYS-Messrs. John Janney [President]
                , Armstrong, Aston, Baldwin, A. M. Barbour, Baylor, Berlin, Blow, Boggess, Boyd, Brent, Brown, Burdett, Burley, Byrne, Campbell, Caperton, Carlile, Carter, Clemens, Coffman, C. B. Conrad, R. Y. Conrad, Couch, Critcher, Custis, Dent, Deskins, Dorman, Dulany, Early, Echols, French, Fugate, Gillespie, Gravely, A. Hall, E. B. Hall, Hammond, Hoge, Holladay, Hubbard, Hughes, Hull, Jackson,
                M. Johnson, P. C. Johnston, Lewis, McComas, McGrew, Macfarland, Marshall, Maslin, Masters, Moffett, Moore, Osburn, Patrick, Pendleton, Porter, Price, Pugh, Rives, R. E. Scott, Sharp, Sitlington, Spurlock, Staples, C. J. Stuart, Summers, Tarr, Tayloe, Waller, White, Wickham, Willey, Wilson-77.
              </p>
              <p>So the amendment was disagreed to.</p>
              <div className="speaker" id="sp3182"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I propose to amend the original resolution in the 9th and tenth lines.</p>
              <div className="speaker" id="sp3183"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">It will not be in order until the vote has been taken upon the amendment of the gentleman from Fauquier.</p>
              <div className="speaker" id="sp3184"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I call for the yeas and nays on that amendment.</p>
              <p>The yeas and nays were ordered.</p>
              <div className="speaker" id="sp3185"><span className="persName">Mr. BOULDIN</span>—</div>
              <p className="p-in-sp">I desire to offer a further amendment to the amendment of the gentleman from Fauquier. I propose to strike out the word "concurring," immediately before the words "non-slaveholding States," and insert after the words "non-slaveholding States," as follows—</p>
              <div className="speaker" id="sp3186"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Chair will suggest to the gentleman that only one amendment can be offered at a time.</p>
              <div className="speaker" id="sp3187"><span className="persName">Mr. BOULDIN</span>—</div>
              <p className="p-in-sp">
                Then I will read what I propose to insert, in order to show my object in striking out the word "concurring." I propose to insert after the words I have indicated "concurring in the amendments to the Federal Constitution proposed by this Convention," so as to make the clause read, "to which Congress the Confederated States and the non-slaveholding States concurring in the amendments
                to the Federal Constitution proposed by this Constitution ought to be invited to send Commissioners."
              </p>
              <p className="p-in-sp">
                As I understand the proposition submitted by the gentleman from Fauquier, the words "concur" and "concurring," used by him, refer either to a call for the Congress or to the Constitution to be adopted by Congress. They do not refer to any principle established by this Convention, or to any principle or proposition to be made or submitted
                <PageNumber num={568} /> by even the Border State Conference. His proposition provides for
              </p>
              <p className="p-in-sp">
                "A Congress of the said States composed of delegates to be appointed by the respective conventions thereof for the purpose of recommending an amended constitution of government upon which the Union of the States and the Confederated States with such of the non-slaveholding States as may concur therein can be safely effected; to which Congress the Confederated States and the
                concurring non-slaveholding States ought to be invited to send Commissioners."
              </p>
              <p className="p-in-sp">
                "Concur therein." Concur in what? It seems to me to refer to the amended Constitution, but the next sentence would seem to deny that, because Commissioners from the States concurring are to be appointed to the Congress that is to adopt the amended Constitution, and which Constitution these States could not therefore have concurred in at the time. There seems to me to be very great
                ambiguity as to what these words "concur" and "concurring" refer to, and for the purpose of removing that ambiguity, I propose that we shall send no invitation to any non-slaveholding State what ever, that has rejected the propositions of the Commonwealth of Virginia. We have come here to settle this question, if possible. We have come here to make propositions to the nonslaveholding
                states, to ask them to concede to us nothing more than we are entitled to. But if these propositions shall be rejected by any of these States, I for one, will never consent to put Virginia in the position of again inviting them to come into a Congress to consider an amended Constitution with her. I wish the amendment of the gentleman from Fauquier then, to state in terms, that this
                invitation is extended only to those of the non-slaveholding States who shall concur in the proposed amendments to be offered by Virginia.
              </p>
              <div className="speaker" id="sp3188"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">I will suggest to the gentleman that the meaning of these words will be ascertained in this clause : "which the Union of the said States and the Confederated States, with such of the nonslaveholding States as may concur therein."</p>
              <div className="speaker" id="sp3189"><span className="persName">Mr. BOULDIN</span>—</div>
              <p className="p-in-sp">
                I understand the clause which the gentleman has read, to refer either to the previous word "Congress," or to the words "amended Constitution." There is certainly an ambiguity about it, and it is that ambiguity I wish to remove. If the gentleman had attended to my remarks, he would have seen how I understand it. In a previous line, the 9th line, it provides that "a Congress of the
                said States composed of delegates to be appointed by the respective Conventions thereof, for the purpose of recommending an amended Constitution of Government upon which the Union of the States and the <PageNumber num={569} /> Confederated States, with such of the non-slaveholding States as may concur therein, can be safely effected."
              </p>
              <p className="p-in-sp">
                Now, these words "as may concur therein," as far as any construction I am able to give, cannot refer to any propositions to be submitted by this Convention to the non-slaveholding States. It must refer to the amended Constitution; and then the expression just beyond "and the concurring non-slaveholding States," I presume, must either refer to the same thing or to the Congress. If it
                refers to the Congress, how do we know that the Confederated States or the non-slaveholding States will concur in the call of this Congress? I submit again that there is very great ambiguity attending this whole matter, and that the amendment I have suggested, ought to be adopted.
              </p>
              <div className="speaker" id="sp3190"><span className="persName">Mr. SCOTT</span>, of Fauquier—</div>
              <p className="p-in-sp">Mr. Chairman—I do not perceive the ambiguity which attracts the eye of the gentleman from Charlotte [<span className="persName">Mr. BOULDIN</span>]
                . The amendment proposes that a Congress shall be convened "for the purpose of recommending an amended Constitution of Government upon which the Union of the States"—that is the border States—"and the Confederated States with such of the nonslaveholding States as may concur therein, can be safely effected." Now, sir, the reason why I object to the amendment of the gentleman from
                Charlotte, is that it would seem to infer that this proposed Congress is to be confined to the amendments which this Convention shall propose. I think these amendments stop short of what the exigency requires.
              </p>
              <div className="speaker" id="sp3191"><span className="persName">Mr. BOULDIN</span>—</div>
              <p className="p-in-sp">If the gentleman will allow me, I think the amendment I have proposed is susceptible of no such construction. The object of it is simply to express our wish to have nothing to do, to have no conference with any non-slaveholding States which have rejected as a compromise, that which the gentleman himself says falls far short of our demands.</p>
              <div className="speaker" id="sp3192"><span className="persName">Mr. SCOTT</span>, of Fauquier—</div>
              <p className="p-in-sp">
                The gentleman's amendment, if I comprehend it, seeks to impose upon the non-slaveholding States the obligation of concurring in all the amendments which we shall propose to the constitution. I wish to impose no such condition upon them, for the reason as I shall proceed to state, that in my judgment the amendments as they now stand stop short of what the exigency of the case
                requires.
              </p>
              <p className="p-in-sp">
                I suppose that a Congress, charged with this grave duty, shall address itself to the work of amending the present constitution in all those particulars in which the past experience of its working has exhibited defects. I cannot, in the brief space allotted to me, indicate here <PageNumber num={570} /> what, in my judgment, these amendments ought to be,
                but it is sufficient to state that I contemplate further amendments, and, in my judgment, more valuable amendments being made to the Constitution before the Union between the slave States and the free States can be made perfect. So that it seems to me the gentleman's amendment, if it prevail, will be equivalent to the pledge on our part, that if these non-slaveholding States agree in
                those amendments the Congress proposed to be convened will be committed to them. Otherwise, it seems to me, it would be something like a breach of faith, a want of fair dealing. The implication seems to be inevitable that if the amendments to be proposed be concurred in, we shall be estopped from demanding more.
              </p>
              <div className="speaker" id="sp3193"><span className="persName">Mr. BOULDIN</span>—</div>
              <p className="p-in-sp">
                The argument of my friend from Fauquier satisfies me, at least, that I had put the right construction on his amendment, and that it was his object to place no limitation whatever on the Congress as to amendments to the Constitution. That was my objection to it. He concedes then that the meaning of his amendment is to extend a general catholic invitation to all the Northern States to
                meet us in conference, through their commissioners. I propose to limit the invitation. Permit me to say that the gentleman has misconceived the object of my amendment and the remarks which I have submitted, if he supposes that there has been any indication by me, as to what form of Constitution should be adopted by that Congress. My remarks were addressed to the character of the
                persons with whom we are to meet. We have not come, yet, to the amendments to the Constitution. They have not been adopted. We are speaking about these amendments in advance. There have been some reported by the Committee on Federal Relations, but they have passed the ordeal neither of this Committee nor of the Convention ; and we know not yet what amendments are to be adopted. But I
                do say, that if the amendments reported are to be adopted they will fall short of what the distinguished gentleman from Fauquier says are our just demands. I am for going into consultation with no Northern State that refuses to extend to us those amendments which the gentleman himself admits to fall short of our just demands. My proposition goes to define, in advance, with whom it is
                that we are to confer. Are we, after our propositions have been contemptuously rejected, to ask suppliantly those States to send Commissioners, to come into a Government with us, and to control us? for there are nineteen against us, and the majority is daily increasing. When they say deliberately that they will not give us what the gentleman from Fauquier says is short of our rights,
                then I proclaim in <PageNumber num={571} /> advance that I want no further communion with them, now or hereafter. Least of all would I be willing to trust myself in a Congress or consultation with a majority of those who would have just refused these moderate demands.
              </p>
              <p className="p-in-sp">
                It is but to meet this case, to show that when this Convention has adopted certain amendments to the Constitution and submitted them to the Northern States, no Northern State that deliberately rejects them shall be invited by Virginia to meet her to settle or agree on terms which they have rejected. That is the object of my amendment. With these remarks I submit it to the Committee.
              </p>
              <p>The yeas and nays were demanded and ordered.</p>
              <p>The question was taken, and it was decided in the affirmative—yeas 70, nays 60—as follows :</p>
              <p>
                YEAS-Messrs. Ambler, Armstrong, Blakey, Blow, Boisseau, Borst, Bouldin, Boyd, Branch, Bruce, Cabell, Caperton, Chambliss, Chapman, Conn, R. Y. Conrad, Dorman, Echols, Flournoy, Garland, Gillespie, Graham, Gregory, Goggin, J. Goode, Jr., T. F. Goode, Hale, A. Hall, L. S. Hall, Hammond, Harvie, Holcombe, Hunton, Isbell, Kent, Lawson, Leake, Macfarland, C. K. Mallory, J. B. Mallory,
                Marshall, Marr, Marye, Sr., Miller, Moffett, Morris, Morton, Nelson, Orrick, Parks, Pendleton, Pugh, Richardson, Seawell, Sheffey, Slaughter, Southall, Speed, Staples, Strange, Sutherlin, Tayloe, Thornton, Tredway, R. H. Turner, Tyler, Waller, Whitfield, Williams, Wise-70.
              </p>
              <p>NAYS-Messrs. Janney [President]
                , Aston, Baldwin, A. M. Barbour, Baylor, Berlin, Boggess, Brent, Brown, Burdett, Burley, Byrne, Campbell, Carlile, Carter, Clemens, C. B. Conrad, Couch, Critcher, Custis, Dent, Deskins, Dulany, Early, French, Fugate, Gravely, Gray, Addison Hall, E. B. Hall, Haymond, Hoge, Holladay, Hubbard, Hull, Jackson, Marmaduke Johnson, P. C. Johnston, Lewis, McComas, McGrew, Maslin, Masters,
                Moore, Osburn, Patrick, Porter, Price, Rives, R. E. Scott, Sharp, Sitlington, Spurlock, C. J. Stuart, Summers, Tarr, White, Wickham, Willey, Wilson-60.
              </p>
              <p>So the amendment to the amendment was agreed to.</p>
              <div className="speaker" id="sp3194"><span className="persName">Mr. BOULDIN</span>—</div>
              <p className="p-in-sp">I now move to amend the amendment by inserting after the word "States" the words "which concur in the amendments to the Federal Constitution, proposed by this Convention," so that it will read :</p>
              <p className="p-in-sp">
                "A Congress of the said States composed of delegates to be appointed by the respective conventions thereof for the purpose of recommending an amended constitution of government upon which the Union of the States and the Confederated States with such of the non-slaveholding States as may concur therein can be safely effected; to which Congress the Confederated States and the
                non-slaveholding States which concur in the amendments to the Federal Constitution proposed by this Convention ought to be invited to send commissioners."
              </p>
              <PageNumber num={572} />
              <p>The question was taken, and the amendment to the amendment was agreed to.</p>
              <div className="speaker" id="sp3195"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">I move to amend the amendment by striking out the words "time appointed for the re-assembling of this body," and inserting in lieu thereof the words "4th day of March, 1865," so that it will read :</p>
              <p className="p-in-sp">"And in the event that favorable responses on the part of the nonslaveholding States be not made to the proposed amendments to the constitution, by the 4th day of March, 1865," &amp;c.</p>
              <p className="p-in-sp">This amendment, the committee will bear in mind, is not an amendment to the report made by the committee of twenty-one, and therefore does not conflict with the promise I made yesterday.</p>
              <p className="p-in-sp">
                I offer it, Mr. Chairman, for the purpose of saying that if ever I was anxious to bring my mind to the conclusion to act with others, I am anxious on this proposed amendment. But, sir, the more reflection I give to it, the more satisfied am I that it is one of the most effectual and certain modes of withdrawing the State of Virginia from the present Union that could possibly be
                devised by man. I would fear infinitely less an ordinance of secession, to go before the people of this State, than I fear the adoption of this amendment.
              </p>
              <p className="p-in-sp">
                If an ordinance of secession were ratified by the people, there would be a possibility of future re-construction from the efforts to be made by the border States; but this not only carries Virginia out, but also carries out every slaveholding State in the Union. It is as sure to produce that result as the border slave State Conference meets; and I here make the prediction that it may
                go on the record. While I shall be left perhaps without the three associates who voted with me for the Peace Conference measures, I shall go solitary and alone to place my vote on the record in opposition to this certain, this inevitable, destruction of the Union, never to be re-constructed.
              </p>
              <p className="p-in-sp">
                Why, sir, look at its phraseology. The section of the report to which this is proposed to be added, provides for a Conference of these border Slave States, to consult together and concert measures. Those measures, I presume, are to be extreme demands, high above the guarantees which this Convention will require, because the gentleman from Fauquier has just told us, that the
                exigencies of the country demand much greater guarantees than this body will ask. Then, sir, such demands are to be made, as, in the opinion of the mover of this proposition the exigencies of the country require, and are to be proposed within what time? By the time this body are to re-assemble in October next. They are to be submitted to the non-slaveholding
                <PageNumber num={573} /> States. This Convention has been in session two months and has not even approached the consideration of these demands which we are to make. And yet, the nineteen non-slaveholding States are expected to concur in these demands by the time of the re-assembling of this body. Can you, do you, suppose any action can be had by the
                non-slaveholding States responsive to your ultimatum, which, in adopting the pending proposition, you lay down?
              </p>
              <p>The hour of two o'clock having arrived, the Convention took a recess until four.</p>
            </div>
            <div className="section" id="vsc1965.v3.2.9.4">
              <h3><span className="headingNumber">1.9.4. </span><span className="head">AFTERNOON SESSION</span></h3>
              <p>The Committee re-assembled at four o'clock, P. M.</p>
              <div className="speaker" id="sp3196"><span className="persName">Mr. CARLILE</span> resumed his remarks—</div>
              <p className="p-in-sp">
                It is expected, as I remarked before the recess, that this Convention will adjourn to meet again the first of October next. Within that time the non-slaveholding States are required, by this amendment, to concur in the amendments which may be proposed by this Border State Convention, which is to meet on the 4th Monday in May next, giving some four months for the action of the
                nineteen non-slaveholding States upon amendments which this Convention, after a session of two months, has not as yet entered upon.
              </p>
              <p className="p-in-sp">It has been urged by those with whom I usually act, that the Committee, having adopted the 11th section of this report of the Committee of Twenty-one, which declares that if unfavorable responses are received to what this Convention may hereafter propose as amendments to the Constitution, that then Virginia will resume her powers.</p>
              <p className="p-in-sp">
                Sir, I do not feel myself committed to that 11th resolution, for I voted against it; nor do I believe that it is the proper course for gentlemen to pursue who voted for it, to support the amendment of the gentleman from Fauquier, hoping thereby to relieve themselves from the embarrassment which they now seem to feel is resting upon them, in consequence of the adoption, by the
                Committee, of the 11th section. It has only been adopted by this Committee—it has yet to undergo the ordeal of the Convention ; and if you have a doubt as to the propriety of your vote in Committee, you can avail yourselves of the opportunity that will be afforded you in Convention, to strike from the report the 11th section.
              </p>
              <p className="p-in-sp">
                If the gentlemen are governed by the reason that the 11th section has been adopted in Committee of the Whole, and, because of this 11th section, feel constrained to vote for the pending proposition, <PageNumber num={574} /> would it not be safer and better to vote with me against the amendment of the gentleman from Fauquier, and defeat it here if we
                can? and then, when we get into Convention, let us labor to defeat the 11th section; and if we but exhibit half the will of the gentleman from Fauquier, we will defeat the 11th section; and then there will be no constraint upon gentlemen to vote for Frankfort conferences and unconstitutional Congresses, to organize another Government, for the purpose of overthrowing and destroying
                the Government formed for us by heroes and sages, patriots and statesmen.
              </p>
              <p className="p-in-sp">
                Sir, I regard this whole report, so far as adopted by this Committee, as committing the people of Virginia to nothing. It is an extra act on your part, and which you have not the authority to make, unless you first submit it to the people of Virginia, and they shall ratify it. All these propositions, upon which we have been engaged for two months are nothing more than the
                declarations of one hundred and fifty-two very respectable gentlemen, and have no binding force upon the people of Virginia or any other people now upon the earth and will not be respected by those who may hereafter be upon the earth.
              </p>
              <p className="p-in-sp">But sir, it has been stated by the gentleman from Augusta [<span className="persName">Mr. BALDWIN</span>] , this morning, that the largest portion of the State, both in point of territory and population, is so connected with the border non-slaveholding States, that it is absolutely essential to the preservation of the interests of the majority of our people, aye, sir, to their very existence, that they should not be separated from the border non-slaveholding States.</p>
              <p className="p-in-sp">
                See in what a dilemma we would be placed if Ohio, Indiana, Illinois, and the other States on your border, would non-concur in the action of your Conference. But, sir, there is an error, which, it seems to me, has pervaded all our conduct since we assembled here. The idea seems to rest upon the minds of gentlemen that something must be done. Sir, the Union sentiment of this country
                does not require this action on your part to satisfy it. I came to this Convention supposing that if there was a majority connected with the body in favor of secession, an ordinance of secession would be adopted at once and submitted to the people, and on the other hand if there was a majority of Union men that we would adjourn and go home. I have proposed sir, because there has been
                no limit of time fixed either by the report or the amendment before us for the re-assembling of this body, the amendment to the amendment of the gentleman from Fauquier. Gentlemen in discussion seem to have fixed the first day of October next as the day to which this Convention should adjourn. I propose <PageNumber num={575} /> the 4th day of March,
                1865, by which time a new President will have been elected and inaugurated and a new administration installed ; and should that President and that administration come from the great Union-loving, Union-saving, National, American, Democratic, Republican, State rights, Southern-rights, Secession, Disunion party of this country, our Cotton Confederates friends, I know, will prefer
                ruling thirty-four States and six or seven Territories, with thirty millions of people, to seven States without Territories and with but three millions of people, and would consider such a response far more satisfactory than any constitutional amendments we can suggest or the largest patriotism adopt.
              </p>
              <p className="p-in-sp">
                Having exhausted the ten minutes under the rule and knowing that my amendment will not be adopted, I shall, with the consent of the Committee, withdraw it, expressing at the same time my fears that the middle men, as the gentleman from Orange and Greene has designated them, will swallow up the Union men of this body, and that at this middle town of Frankfort, midway between
                Washington and Montgomery, the Union heretofore regarded by us as the choicest of blessings, will be swallowed up and destroyed.
              </p>
              <div className="speaker" id="sp3197"><span className="persName">Mr. GOODE</span>, of Bedford—</div>
              <p className="p-in-sp">I object.</p>
              <p>The question was then put on granting leave to withdraw the amendment, and was decided in the affirmative.</p>
              <div className="speaker" id="sp3198"><span className="persName">Mr. SUMMERS</span>, of Kanawha—</div>
              <p className="p-in-sp">Mr. Chairman, I desire to move an amendment of the proposition submitted by the gentleman from Fauquier [<span className="persName">Mr. SCOTT</span>] . I move to strike out in the first line the word "favorable," and insert in lieu thereof the word "satisfactory."</p>
              <p className="p-in-sp">
                I submit this amendment for the double purpose of making the language in that line by the substitution of the word "satisfactory," conform to other sections upon which this Committee has acted—the 11th section for example—and for the purpose of affording me an opportunity of stating, as far as I may be able to do so within the brief space of ten minutes, allowed under the rule, the
                reasons for the vote that I shall give upon the amendment offered by the gentleman from Fauquier
                [<span className="persName">Mr. SCOTT</span>] .</p>
              <p className="p-in-sp">I am not very particular, I confess, as to the word proposed, though I think it is better to conform it to the language used in the other sections.</p>
              <p className="p-in-sp">Mr. Chairman, I need not state that I look forward under any circumstances to an overthrow of the government and the dissolution of this Union, with feelings of pain and grief. It is not necessary for me to say that I should not sustain this amendment offered by the <PageNumber num={576} /> gentleman from Fauquier [<span className="persName">Mr. SCOTT</span>] , as a matter of choice were I left to independent action; but acting, as we are all compelled to act, in reference to the circumstances by which we are surrounded, and in reference to the action of this body already had, I have brought my mind to the conclusion to vote for this proposition as an addition to the report of the Committee.</p>
              <p className="p-in-sp">
                It will be borne in mind that we have already, by the adoption of the eleventh section of the report, declared that in the event we shall fail to obtain satisfactory responses from the non-slaveholding States, upon our proposed amendments to the Constitution, that Virginia will feel compelled to resume the powers delegated by her, under the Constitution, and to throw herself upon her
                reserved rights. That resolution has been adopted. I did not vote for it. It has been carried, however, by the votes of a majority of this Committee. So far, then, we stand pledged, to the extent of the vote upon this resolution, that in a certain contingency, Virginia is to resume her original rights. You have gone no further. You have not yet said what Virginia is to do after she
                shall have resumed her original rights, and thrown herself upon her reserved sovereignty. Some gentlemen of the Committee have given us to understand, that upon the happening of this contingency, they are favorable to Virginia taking her place at once in the Southern Confederacy. The gentleman from Halifax
                [<span className="persName">Mr. BRUCE</span>] , announced this morning that Virginia would as appropriately and necessarily take her destiny in that direction as the apple, when separated from the parent stem falls to the ground by the force of gravitation. The gentleman from Princess Anne [<span className="persName">Mr. WISE</span>]
                as I understand his position announces that when Virginia shall resume her original rights and separate from the Confederacy, that he is not in favor at once of uniting her destiny with the Southern Confederacy. He prefers that she shall act independently, and upon her own separate responsibility, retaining her position without connection with other States. That, as I understand, is
                the position of the gentleman from Princess Anne. It will be agreeable to him, also to have North Carolina yet maintain her position intervening between Virginia and the Confederated States, and still connected with the old Union.
              </p>
              <div className="speaker" id="sp3199"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Only while Virginia is taking the necessary action to place herself in an attitude of independence.</p>
              <div className="speaker" id="sp3200"><span className="persName">Mr. SUMMERS</span>—</div>
              <p className="p-in-sp">
                I understand the gentleman. He maintains that it would be better that North Carolina, during the further action of Virginia to secure her independence upon a firm footing, would <PageNumber num={577} /> continue in her present position intervening between us and the South. He would have her then, as I understand it, address herself to the work of
                forming a constitution of Government, and offering it for acceptance to all the States that may think proper to come in, and make them parties to it.
              </p>
              <p className="p-in-sp">The gentleman from Fauquier [<span className="persName">Mr. SCOTT</span>] , when inquired of what he would do beyond that point, replied, as I understand, that his feelings and his interests would lead him to unite with the Southern Confederacy, and in that event he would desire that North Carolina and all the sister States of the border would go with him.</p>
              <p className="p-in-sp">I have made this statement as showing what seems to be the divided sentiments of gentlemen, as to what Virginia is to do, and as to what would be her position after the resumption of her sovereignty. Regarding the gentleman from Halifax [<span className="persName">Mr. BRUCE</span>]
                , as representing one class of opinion, and the gentleman from Princess Anne, as representing another, I regard the proposition as submitted by the gentleman from Fauquier, as representing still another class, who desire that, when that event shall take place, that is a failure to receive satisfactory responses as you have announced under the 1 1 th resolution, and when Virginia
                shall throw herself on her reserved rights, she shall still remain in connection with the border slave States. I say, I regard that proposition as a more acceptable one than either of the others. It contemplates co-operative action with the border slave States; the others contemplate separate State action. This looks to co-operation by Virginia of the seven border slave States, in
                forming for themselves a Government which shall be acceptable, as we trust it will be, to the States of the Southern Confederacy and to a portion, at least, of the non-slaveholding States across the border, thus aggregating to the eight border slave States accession from both sides of the line. Would it not be better to adopt this policy than to act separately and apart from our
                sister States, Kentucky, Maryland, Tennessee, and those other States in our neighborhood to which we are so closely bound by every ligament of interest that can unite and bind a people together?
              </p>
              <p className="p-in-sp">
                What interest have we with the Confederated States to induce us to unite with them at once and without consultation with the border Slave States? Nothing that we have in the trans-Alleghany country finds its way into any of the markets of these States save such commerce as we have with the ports of the lower Mississippi. What interest have we with South Carolina beyond that which
                arises from a general feeling of patriotism and admiration of her gallantry? We <PageNumber num={578} /> will bear you up when the time for action comes; but we ask you to use all necessary efforts to unite with us those States in which we have a peculiar interest.
              </p>
              <p className="p-in-sp">I am irrevocably committed as a co-operation man. I have said here and elsewhere, and throughout these troubles, that the position of Virginia is necessarily in combination with her sister border slave States. Their interests and her interests are the same. They are identical and their fate must be identical. The gentleman from Halifax [<span className="persName">Mr. BRUCE</span>] , as I understood him this morning, said that we must be governed—</p>
              <p>Here the hammer fell.</p>
              <div className="speaker" id="sp3201"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">I propose to strike out the word "favorable," and insert the word "satisfactory."</p>
              <p className="p-in-sp">I wish to be distinctly understood by this Committee as being irrevocably opposed to any such change in the phraseology of this amendment.</p>
              <div className="stage it">[Laughter.]</div>
              <div className="speaker" id="sp3202"><span className="persName">Mr. SUMMERS</span>—</div>
              <p className="p-in-sp">It was announced by the gentleman from Halifax [<span className="persName">Mr. BRUCE</span>]
                , that we were necessarily governed, more or less, by our interests, and I understood him to say that we had little or no trade with Missouri, Kentucky, and Tennessee; that there was a time when the live hogs of Kentucky were purchased for Halifax, but now that trade had ceased, and they purchased their pork in Cincinnati. I desire, Mr. Chairman—and I say it in no spirit of
                sectionalism, for I think I can declare I am as free from it as any member of this body—I desire to know no portion of Virginia but only her interest throughout—that gentlemen should remember that there are other portions of Virginia which have direct, immediate and inseparable interests with the Border Slave States, however it may be with the portion of the Commonwealth from which
                the enlightened gentleman from Halifax
                [<span className="persName">Mr. BRUCE</span>]
                , comes. The whole trans-Alleghany portion of your State is more or less connected in commerce with the States of Maryland, Kentucky, Tennessee and Missouri. I come from a portion of the State directly interested to a very large extent in trade and commerce with Kentucky. Louisville is perhaps, as large a market as we have, both for salt and coal. At Maysville is situated the
                refinery of that very large and wealthy establishment which has its head spring in my county, for the manufacture of oil from cannel coal. At Maysville, Covington, Newport, Louisville, and other points we find constant markets for all our products, not only from the Kanawha and Mason's salt works, but from the country along the whole line of the Ohio River. Nashville, Tenn.,
                furnishes a market <PageNumber num={579} /> for our products; St. Louis, in Mo., is one of the best salt markets we have had at any time. I mention these places in the slave States upon this side of the Ohio ; while upon the other side we find a market in Cincinnati and numerous other towns in Ohio, Evansville, Madison and other points in the State of
                Indiana. The State of Iowa,<span className="note" id="Note64"><span className="noteLabel">3</span>Mr. Summers doubtless meant Indiana.</span> cut through the centre by the Wabash River, is perhaps the largest interior salt market we have, as we send our products to Vincennes, Terre-haute, and all the towns upon the Wabash.
              </p>
              <p className="p-in-sp">
                I mention these facts to show that, however it may be with other gentlemen, we are so situated in certain portions of this Commonwealth that, our material interests and our necessities, our bread and meat, depend upon connections which some gentlemen here seem to ignore altogether. It is not so by our making or our choice. It is made so by the irrevocable decree of that Providence to
                which we must all bow. It is not to be disguised that more than half of our Commonwealth belongs emphatically to the great valley of the Ohio and Mississippi. You may run the whole course of this line, take all this trade, and you will cripple it, and, perhaps, extinguish it in a very great degree, if not entirely, if you adopt a course of policy, such as is indicated here—I mean
                that policy which will take Virginia alone without Kentucky, Missouri, and Tennessee into the confederacy, having its capital at Montgomery. I say that you cannot make that portion of Virginia part and parcel of a confederacy or a Government of any kind, with any regard for its interests separate and apart, leaving behind you the great slaveholding States upon this side of the Ohio,
                and cutting loose from Kentucky, Tennessee, and Missouri. How is it upon the Potomac line? Western Virginia, in one of her most important articles of trade—I mean the cattle trade—looks to Baltimore for a market, and the Baltimore market is affected by the prices in Philadelphia, and Philadelphia is affected by New York. The price of almost every steer that feeds upon the blue grass
                pastures of the valley, and in the counties beyond the Alleghanies, depends upon the sensitive balance of trade in these cities beyond our border. I heard the gentleman from Berkeley
                [<span className="persName">Mr. HAMMOND</span>]
                , say the other day, that standing in his own yard he was within eight miles of Pennsylvania, and he could cast his eyes across into the narrow strip of Maryland; and yet the policy of gentlemen looks for the withdrawal of Virginia, separate action on the part of Virginia, which is to carry us off to this Southern confederacy, to make a part and parcel of that confederacy, without
                consultation with Maryland, Kentucky, Tennessee and Missouri, and without the chance to carry <PageNumber num={580} /> with us Pennsylvania, Ohio, Indiana, and Illinois. What region of your State on the Pennsylvania, Ohio, and Kentucky border regards as an indispensable condition for going with you, that you should carry along the States of Maryland,
                Kentucky, Tennessee, and Missouri, and the border States across the line? You can do it, there is no difficulty about it. We live in harmony now with our neighbors over the line. I tell gentlemen that the safety of our slave property this hour in the counties of Mason, Putnam, and Kanawha, the three great counties lying on the Kanawha, is in a great degree owing to the fact that we
                have, beyond the line of Ohio, in the county of Gallia, an old French settlement, whose population for the most part, is as faithful and true to their obligations as the population upon our own river. They arrest your fugitive slave and return him to you, as soon as a Virginian would do it. You take away this safeguard that we now have, and make that country a foreign government to
                us, and you put it in the power of that foreign government to block up our commerce and our business as effectually as you would block up a rabbit or a fox in his hole. I tell gentlemen now, that if secession is to come, if dissolution is to be brought upon us, if Virginia is to be carried into any other Government than that to which she is now a party, there is a very large portion
                of your State that will be placed in a condition in which I am sure no man here would desire to place any portion of his fellow citizens.
              </p>
              <p className="p-in-sp">My friend from Halifax [<span className="persName">Mr. BRUCE</span>]
                , said the other day in his address, to which I listened with great pleasure, that he did not know any part of this Commonwealth that he was willing to give up. He was not for secession now, but he was waiting until the public mind of the country was brought to the acceptance of that great final measure. He does not want to part from us. We do not want to part with him. I ask him
                when he speaks of the great slave interest, when he speaks of the tobacco interest of Virginia, I beg him to remember that 200,000 majority of the white population of this Commonwealth now lie beyond the Blue Ridge Mountains and that they have interests, if not in tobacco, yet in other products of the soil to the amount of millions upon millions, absorbing ten fold all the tobacco
                interests of the State.
              </p>
              <p>Here the hammer fell.</p>
              <div className="speaker" id="sp3203"><span className="persName">Mr. BOULDIN</span>—</div>
              <p className="p-in-sp">
                I desire merely to make a verbal correction in the amendment which was adopted this morning—to insert in the place of the words "which occur" the word "occurring." It does not change the sense at all.<span className="note" id="Note65"
                ><span className="noteLabel">4</span>Mr. Bouldin refers to the amendment quoted on page 561. The words in question are properly "which concur" and "concurring."</span
                >
              </p>
              <PageNumber num={581} />
              <p>There being no objection the amendment was agreed to.</p>
              <div className="speaker" id="sp3204"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I rise to call the attention of the Committee to a serious constitutional question. I propose to add this to the amendment of the gentleman :</p>
              <p className="p-in-sp">
                "But this Convention protest that this provision for a separate Congress to be held by States while remaining in the Union shall not be construed to erect a government superseding the present Congress and the government of the United States; and, whilst holding this exclusive and sectional Congress it shall not authorize any State to enter into any treaty, alliance or confederation
                and into no agreement<span className="note" id="Note66"><span className="noteLabel">5</span>Both the Enquirer and the Journal read "no agreement."</span> or compact with another State contrary to the first and third clause of section 10, article 1st of the Constitution of the United States."
              </p>
              <p className="p-in-sp">
                Sir, I put it to the consciences of gentlemen who have sworn to support the Constitution of the United States, who say that they cannot endorse the revolutionary right to secede from this Union, and go out of it and withdraw from it, how they, remaining in the Union—eight States only—can organize a Congress and form and present to each other an amended constitution, the purpose of
                which is to supersede the present Constitution of the United States, the present Government of the United States, the present Congress of the United States, that cannot be adopted without being adopted in the character of a compact or agreement with another State—that cannot be adopted without forming an alliance, treaty or confederation with any other State? How can they do it
                whilst remaining in the Union, without violating this expression—
              </p>
              <p className="p-in-sp">"No State shall enter into any treaty, alliance or confederation."</p>
              <p className="p-in-sp">
                It is positive. The consent of Congress, the consent of Government, cannot give you that power whilst you are remaining in the Union. You cannot even make a compact or agreement with any other State without the consent of Congress, except when you are in such imminent danger as will not admit of delay. Now, then, if you can solemnly, in your consciences, declare that you are in such
                imminent danger as will not admit of delay, you may make the compact or agreement without the consent of Congress. On the contrary, instead of declaring that you are in such imminent danger as will not admit of delay, you have declared the very reverse, that you will delay and delay from measure to measure, until you have done that very thing which the constitution forbids you to do.
              </p>
              <p className="p-in-sp">Now, I do not put this ad captandum. I ask gentlemen to get over this difficulty, if they can.</p>
              <PageNumber num={582} />
              <div className="speaker" id="sp3205"><span className="persName">Mr. SCOTT</span>—</div>
              <p className="p-in-sp">We will hop over it.</p>
              <div className="speaker" id="sp3206"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                The gentleman would hop over it! Yes. Federalism can raise a thousand constitutional obstacles to any States Rights course of action; but when the motive is federalist, federalism can always clear constitutional barriers with that hop, skip and jump, with which you are about to violate clause 3, of section 10, of article 1st of the constitution of the United States. You skip over
                clause two and violate clause three of section ten. Whilst abiding in the Union, whilst pretending exclusive veneration for this constitution and this Union—you propose to do what? To draw State after State, not one alone, but tiers of States, from the present Government and the present Union.
              </p>
              <p className="p-in-sp">
                I submit it to the manhood and the logic of gentlemen, if in the name of the Union, and with the instrumentality of the Union, they are going to destroy the Union? And I ask them what they mean to do with the non concurring slaveholding States? I asked them the other day, if they were going to stand by when Stephen was stoned to death, and to see the Union destroyed by sloughing off
                the Southern States. I now ask them, if they are going to stand on the other side of Stephen and see him stoned to death, while they are sloughing off some of the Northern States? and why they are going to Frankfort to hold this Congress in the Union, to destroy the Union, to destroy the Government under the protection of the Government, and to destroy the Constitution under the
                aegis of the Constitution, against its express provision?
              </p>
              <p className="p-in-sp">
                0, gentlemen, gentlemen; if you are going in the very teeth of the Constitution, and of the Union, in this way, better at once to take a manly stand of not being bound by the Constitution! As long as you are bound by the Constitution, as long as you glory in your fealty and loyalty to it, I implore you to look at its letter, look at its language before you create this Congress and go
                to Frankfort to upset that Constitution. If you don't intend to abide under the present Constitution, in the present Union, absolve yourselves by your sovereign act at once, and go out like men and take the consequences.
              </p>
              <p className="p-in-sp">
                Sir, I said to the gentleman from Harrison what I will now say openly to you, that—I will not say cheating, for that is an ugly word—but I will say, there is a delusion around the board some way or other. There is, I repeat, delusion around this board some way or other. You will either go to Frankfort, and carry Virginia and the Border States North—then you are deluding us—or you
                will go to Frankfort and carry the Border States South—and then you are deluding my friend from Kanawha. He has changed the word "favorable" into <PageNumber num={583} /> the word 'satisfactory," just as if a thing could be satisfactory without being favorable to the South ; and you have agreed to it. Given all the chances for elasticity of conscience,
                given all the means of squirming and twisting, and slipping and dodging, you are determined that Virginia, nolens volens, shall go out of the Union.
              </p>
              <p className="p-in-sp">
                Let us take one course or the other. There is the gentleman from the county of Wood, who says that he is not going to be dragged out of it to South Carolina anyhow—that he will burst the bands of the integrity of this State before he will be taken out of the Union. Piling secession upon secession. I ask him if his conscience can allow him to take this proposition, and to go directly
                in the teeth of the Constitution of the United States which prescribes that you shall form no treaty, no alliance, no confederation? It is a sin, it is a high national sin, it is a political sin, for the States to go down South and form a Confederacy there, after they have absolved themselves from all connection with the Federal Government; but it is no sin, it seems, to go to
                Frankfort and form a new Constitution, a new Government, a new Congress, in place of those which we have at present.
              </p>
              <p className="p-in-sp">
                Suppose George Washington were alive now, he who was President of the Convention that formed that Constitution, he who wrote the letter recommending it to the several States. Suppose George Washington were alive to-day, and on this floor among us, and was asked by us "can this government of yours further be dissolved, and superseded by a new Confederation of States still remaining in
                the Union? Can a new Congress be made, can a new amended Constitution be submitted in this form?" Sir, that august shade would do what he advised you in his Farewell Address to do—it would frown on such a proposal. He would tell you that, whilst we were in allegiance to the Union, under a government formed by that Constitution, the letter of the Constitution forbade you to do it. He
                would tell you about your right to defend your property, your life, and your sacred honor; and he has told you by his example to resist tyranny.
              </p>
              <div className="speaker" id="sp3207"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The gentleman's time is exhausted.</p>
              <p>The question then recurred upon the amendment offered by <span className="persName">Mr. WISE</span>, upon which he called for the ayes and noes. The roll was called and the amendment was rejected—ayes 27, noes 100, as follows :</p>
              <p>YEAS-Messrs. Ambler, Blakey, Borst, Cecil, Chambliss, Conn, Graham, Goggin, J. Goode, Jr., T. F. Goode, Hale, C. Hall, Harvie, Hunton, Isbell, Lawson, Leake, Morris, Morton, Neblett, Seawell, Speed, Strange, Thornton, R. H. Turner, Williams, Wise-27.</p>
              <p>NAYS-Messrs. Janney [President]
                , Armstrong, Aston, Baldwin, A. M. Barbour, James Barbour, Baylor, Berlin, Blow, Jr., Boggess, Bouldin, Boyd, Branch, <PageNumber num={584} /> Brent, Brown, Bruce, Burdett, Burley, Byrne, Cabell, Campbell, Caperton, Carlile, Carter, Chapman, Clemens, Coffman, C. B. Conrad, R. Y. Conrad, Couch, Critcher, Custis, Dent, Deskins, Dorman, Dulany, Early,
                Echols, Flournoy, French, Fugate, Garland, Gillespie, Gravely, Gray, A. Hall, E. B. Hall, Hammond, Haymond, Hoge, Holcombe, Holladay, Hubbard, Hull, Jackson, M. Johnson, P. C. Johnston, Kent, Kilby, Lewis, McComas, McGrew, Macfarland, C. K. Mallory, J. B. Mallory, Marshall, Marr, Marye, Sr., Maslin, Masters, Moffett, Moore, Orrick, Osburn, Parks, Patrick, Pendleton, Porter, Pugh,
                Richardson, Rives, R. E. Scott, Sharp, Sheffey, Sitlington, Slaughter, Southall, Spurlock, Staples, C. J. Stuart, Summers, Sutherlin, Tarr, Tayloe, Tredway, Waller, White, Wickham, Willey, Wilson-100.
              </p>
              <p>While the roll was being called,</p>
              <div className="speaker" id="sp3208"><span className="persName">Mr. GREGORY</span> said—</div>
              <p className="p-in-sp">The delegate from the county of Greenbrier [<span className="persName">Mr. PRICE</span>] , is temporarily absent from the hall. I promised to pair off with him, otherwise I would vote aye.</p>
              <div className="speaker" id="sp3209"><span className="persName">Mr. PRICE</span> [who had in the meantime returned], when his name was called, said—</div>
              <p className="p-in-sp">Inasmuch as on account of my absence my friend [<span className="persName">Mr. GREGORY</span>] did not vote, I therefore wish to decline voting on this question.</p>
              <div className="speaker" id="sp3210"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">
                Mr. Chairman, I have made several attempts to gain the floor, and now the proceedings have gone beyond the point at which I desired to submit an amendment. My object is to amend the pending amendment by making it include the free States, that accept our guarantees, along with the border states, and the Confederate States, in the invitation to co-operate; and further to gain time, by
                providing for separate action by the States in the first instance, and afterwards for their assembling in a Congress to settle the terms of their union.
              </p>
              <p className="p-in-sp">If the motion to strike out and insert prevail, the latter clause will read :</p>
              <p className="p-in-sp">
                "And such of the non-slaveholding States as may declare their assent to the said amendment, ought to resume the powers granted under the Constitution, and withdraw from the present Confederacy, and provide in their act of resumption and withdrawal for a Congress composed of delegates from the Confederate and other States aforesaid to recommend a Constitution under which their Union
                can be effected."
              </p>
              <p>The question was propounded to the House.</p>
              <div className="speaker" id="sp3211"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The question will be upon the amendment to the amendment.</p>
              <div className="speaker" id="sp3212"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">
                The considerations which recommend the amendment will occur on a moment's reflection. If anything is ascertained and settled, by the disclosures of public opinion for months <PageNumber num={585} /> past, it is that Virginia demands that the Constitution be amended, and that, if her propositions of amendment be refused, separation would become a duty
                and necessity. It is impossible to preserve the Union in the face of irritations and wrongs which would lead to war between independent powers. Except the amendments agreed upon by this Convention, shall be conceded by the North, disunion is inevitable. We will not continue in a Confederacy in which the section to which we belong is reduced below the position of a peer and an equal.
              </p>
              <p className="p-in-sp">
                Let me call the attention of the Committee to a politic view of the question before us. Rely upon it, that if the North be approached with the alternative of yielding our just demands, or with a new union, from which it would be excluded, a disposition to side with us will be kindled. The way to gain New England, is to let her see that a union will be formed, of large dimensions and
                great strength, without her co-operation. Submit your amendments to the free States, with the condition annexed, that their rejection is a divorce from the South, but that our bonds with such of them as accept our propositions will be stronger and more durable for the trial, and we may be sure of a dispassionate hearing, which is, of itself, a long step to a sound conclusion. We
                shall at least enlist on the Southern side whatever there be of brotherhood in the Northern mind—and there is in the North a large band of noble friends of the South—and rally to our cause all those who would be influenced by the fear of parting company with at least some of the free States. May we not rely on the adherence of Pennsylvania, of New Jersey, of the North-western States?
                With them the South is in too close proximity, and is connected otherwise by too many relations, to suppose they would willingly dissolve themselves from her. And if they, or any of them, should cast their fortunes with the South, States which might decline a Southern connection, if permitted to choose between the entire North and the South, would consider it a very different
                question when the separation would detach them from free as well as slave States. It is manifestly, therefore, of the highest importance, to treat the free States which accede to our amendment with the consideration and favor extended to the border slave States. There is no motive to treat them otherwise, but it is, on the contrary, our duty as well as our interest, to treat them as
                brothers in the fullest sense.
              </p>
              <p className="p-in-sp">
                The amendment, I submit, is a material improvement of the resolution in advancing us to the end at which we are aiming. The resolution contemplates separation and a new Union ; and the amendment, <PageNumber num={586} /> looking to the same issue, is calculated to shorten the period of interregnum, that is to say, its purpose is to abridge the interval
                of uncertainty, distrust and uneasiness, so injurious to the repose and business of the country, between the abandonment of the old, and the induction of the new government. In that end we should all concur, and, I presume, do.
              </p>
              <p className="p-in-sp">
                It becomes us to act for Virginia, and to act finally. In times like these decision is wisdom. Nothing less than constitutional amendments, which pledge anew the faith of the Confederacy to the South, for her full participation in all the rights and benefits of the Union, can or ought to satisfy Virginia. It is expected of us that we will speak out; amendments of the Constitution on
                which we agree, will, we may assume, be satisfactory to the border slave States. Their object and our own is the same—to restrain the majority from abusing their power to sectional inequality and oppression. This being effected, we may expect anti-slavery agitation to expire, since there will be then, neither power nor advantage to be gained by it. I agree with those who are for
                laying down our propositions strongly, and for standing upon them. Now is the time for presenting an ultimatum. The occasion demands it—the dignity of the Commonwealth, and her sympathy with her section, can be satisfied with nothing less. A halting, equivocal course is as short-sighted as it is unmanly. We shall accomplish nothing if our remedies do not work a moral reformation in
                the North, whereby, public opinion will be brought to own that any intermeddling with our system of labor is an offence and an injury.
              </p>
              <p className="p-in-sp">
                Sir, if I were reduced to the necessity of standing still, or of waiting on the border States, of losing their co-operation in the one case, and of gaining it in the other, I confess I should be perplexed and embarrassed. But that is not our condition. There is nothing unkind or disrespectful to those States in deciding in advance of this action upon the guaranties which would alone
                content and satisfy us.
              </p>
              <p>Here the hammer fell.</p>
              <div className="speaker" id="sp3213"><span className="persName">Mr. HOLCOMBE</span>—</div>
              <p className="p-in-sp">I concur entirely in the positions of the gentleman from Richmond and I only object to the amendment to enable him to continue his remarks.</p>
              <div className="speaker" id="sp3214"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">I am greatly indebted to my friend for the privilege of continuing my remarks, and am especially gratified with his concurrence in the views I have had the honor to submit.</p>
              <p className="p-in-sp">
                Mr. Chairman, it is time that speculation was at an end, respecting the determination of the Convention. There can be no repose or sense of security until Virginia declares her determination. It is <PageNumber num={587} /> looked for with solicitude by the entire country, and by the people of the border States, as anxiously as any other. There is no
                danger of alienating them by prompt and decisive action. The magnitude of her interest in the pending issues and the desire, which she has justly evinced, to preserve the Union, if it can be reconciled with Southern security, entitle our State to speak with plainness and decision. It is due to her position that she should announce her terms to the North, and ask of her sisters of the
                border to concur in them. None need apprehend that in so acting she will be considered as forward, presumptuous, or exacting. It is the course, of all others, calculated to impress the Federal Executive with the necessity of dealing peacefully with the Confederation of the South. It is, perhaps, the only course for avoiding civil war. Any delay in reaching our conclusions, and in
                announcing our ultimate purpose, serves only to disseminate delusion at the North, in respect to our determination to insist on our demands. After all, the question is, are we in earnest? Do we really believe that guarantees are demanded, if we would maintain Southern rights and honor? If so, why hesitate to assume the responsibility demanded by our convictions.
              </p>
              <div className="speaker" id="sp3215"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">I move to amend the amendment by striking out the word "and" between the words "Missouri and Arkansas," and add after the word "Arkansas," the words "and Virginia."</p>
              <div className="speaker" id="sp3216"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The question will first be taken on striking out the word "and."</p>
              <p>The amendment to the amendment was not agreed to.</p>
              <div className="speaker" id="sp3217"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">The Chair took the question while I was preparing to say something in its support.</p>
              <div className="speaker" id="sp3218"><span className="persName">Mr. SLAUGHTER</span>—</div>
              <p className="p-in-sp">I desire to amend the amendment, by striking out all after the word "and" in the first line and inserting as follows :</p>
              <p className="p-in-sp">"Also to cause Conventions similar to this to be held in each of said States to meet on the day which may be fixed for the re-assembling of this Convention."</p>
              <p className="p-in-sp">
                I will detain the Committee but a few moments in explanation of the amendment which I have offered. The original proposition proposes that there shall be a Conference of Commissioners to be appointed by the border slaveholding States, to be held at Frankfort, on the last Monday in May next. The amendment of the gentleman, from Fauquier, proposes Conventions to be held in the
                different border States and indicates the policy to be pursued therein. The objection which I have to that proposition is that I think it premature for us to indicate now what our policy will be at that time—and for various <PageNumber num={588} /> reasons. The most important is that we do not know under what peculiar circumstances we may be then
                placed, what situation we may be in, whether it will not be necessary for us, before then, to pass an Ordinance of Secession.
              </p>
              <p className="p-in-sp">
                I think it premature for us, in times of revolution like these to indicate six or nine months in advance, what policy we will pursue at that time. I ask those gentlemen who are so exceedingly anxious to confer with the border slave States, whether it would be treating them with proper respect for Virginia to indicate now, how that Congress is to be constituted, who shall compose it,
                and what questions will have to be decided in it, without saying a word to the border States in respect to those matters? Would it not be much better to ask them to assemble in Convention at the same time as ourselves, so that we may act in conjunction, and make arrangements that we shall all, if it becomes necessary, secede together, set up a new government, go to the Southern
                Confederacy, or reconstruct the old Confederacy? It seems to me that that would be much better than to indicate now what is to be done at that time. The proposed conference in May next would have no authority; it would be simply an advisory body.
              </p>
              <p className="p-in-sp">
                My proposition goes further. It asks the border slave States to call Conventions constituted like ours, and to be ready to act when we re-assemble. We can then determine what line of policy we should pursue, and if there shall appear to be a necessity for a border State Congress to form a new Government, it can then be called, the States can appoint delegates, and we may act in
                concert. Nothing can be accomplished by indicating our policy at this particular juncture, because we have to meet here again before we appoint delegates to the border slave State Congress. There is another reason which ought to operate strongly on the majority, and I am co-operating with them, and I desire that the report shall be made as acceptable as it can be made. I want to be
                able to advocate it before my people and to tell them that this is the ground on which they ought to stand. There are several sets of opinions held here, as to our course if driven to secession—one indicated by the gentleman from Halifax; one by the gentleman from Princess Anne, and one by the gentleman from Fauquier. If we do not insist on indicating now the line of policy to be
                pursued in such an event, we can call on all parties to unite upon a report which will be acceptable not only to the Convention, but probably to a large portion of the people. If we say we are going immediately to join the Southern Confederacy there will be a great many parties against us.
              </p>
              <PageNumber num={589} />
              <p className="p-in-sp">
                If we say that we are going to have a Border Confederacy there will be other parties against us. "Sufficient unto the day is the evil thereof." Events are thronging upon us so fast that six months hence we know not where we may be found. Gentlemen say that we ought not to secede without North Carolina, Tennessee, Maryland, Delaware, Missouri and Kentucky. If that be so, let us ask
                those States, as my amendment proposes, to meet in Convention the same day that we re-assemble, and then we may adopt such a line of policy as may be thought best.
              </p>
              <div className="speaker" id="sp3219"><span className="persName">Mr. SUMMERS</span>—</div>
              <p className="p-in-sp">Some of the objections assigned by the gentleman from Campbell [<span className="persName">Mr. SLAUGHTER</span>]
                , to the proposition of the gentleman from Fauquier operate on my mind in its support. It proposes the selection of an ultimate course of action. We have already announced by a majority vote of this committee that Virginia will resume her sovereign powers in the event of our receiving unfavorable responses from the other States. If we stopped there, we would leave ourselves open to
                the inquiry—"what then? Are you going North or are you going South? Where are you travelling? Are you under the influence of the North star, or of the Southern Cross?" as the gentleman from Princess Anne
                [<span className="persName">Mr. WISE</span>] , will have it? Now, Mr. Chairman, I stated before that this is not a position which I would select if I could act in the matter, as my judgment would dictate, but you have already announced what you would do in a certain contingency.</p>
              <p className="p-in-sp">
                Now I think it proper to indicate, in some form, the ultimate mode in which you are to carry out your action if the contingency should happen. The scheme of the gentleman from Fauquier is not, of itself, acceptable to me, but it is acceptable comparatively, in comparison with other modes of action. I prefer it to the proposition of the gentleman from Princess Anne—to let Virginia
                stand alone and act on her own responsibility. I prefer it to the course indicated by other gentlemen on the floor—to take Virginia immediately into the arms of the Confederate States, into a government which we have not aided in forming, to which we have not been partners, and to which we would merely go by accession. I prefer, Mr. Chairman, the more noble position of letting
                Virginia head this phalanx of border slave States, and to use these States as the nucleus of re-construction. I am for going neither North nor South. I am for Virginia speaking now, as she has spoken in times past, in a voice which shall be potential. I am not for her jumping into an apartment of a house which she did not help to build.
              </p>
              <div className="speaker" id="sp3220"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">Will the gentleman tell me when, in the ancient <PageNumber num={590} /> history of this Commonwealth, we have waited to ascertain the opinions of others instead of acting on our own?</p>
              <div className="speaker" id="sp3221"><span className="persName">Mr. SUMMERS</span>—</div>
              <p className="p-in-sp">
                We have always acted in concert. You went to Annapolis with General Washington at the head of your commissioners, for consultation and conference, and that consultation led to the Convention which sat in Philadelphia and which formed our Constitution. You have always acted in concert with other States, and you are so situated that you cannot, with a view to the interests of a large
                majority of your people, act except in concert.
              </p>
              <p className="p-in-sp">
                That is the position which I would have Virginia maintain. Let her, combined with her sister States with whom she has a common interest, make her own terms and present them to both sides, and reconstruct a Government for both sides. I care very little whether the more distant points come in or not; but it is absolutely necessary for the safety of the people and the promotion of their
                great material interests, that if we are to leave the present Union, we should act in harmony with the States immediately surrounding us, and carry them with us, so as not to loose or disrupt our present commercial and business relations.
              </p>
              <p className="p-in-sp">The gentleman from Richmond [<span className="persName">Mr. MACFARLAND</span>]
                , tells the Committee that the market of supply always controls the market of consumption. How is that, when you are just at the exact point of competition in the market of consumption, and that market of consumption is in a foreign country which itself supplies the same articles of trade? Take our own position in regard to our heaviest and most important products. Ohio and
                Virginia<span className="note" id="Note67"><span className="noteLabel">6</span>This should probably be Indiana.</span> produce exactly the same articles, and meet us in the same markets of consumption. Separate us from those States, make them foreign nations, and let them put the slightest tariff of duty on any of those articles, and they would effectually prohibit them.
              </p>
              <div className="speaker" id="sp3222"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">It is when the market is in the balance, that the market of consumption is most under the control of the market of supply.</p>
              <div className="speaker" id="sp3223"><span className="persName">Mr. SUMMERS</span>—</div>
              <p className="p-in-sp">
                That axiom will not do in the state of things which I have cited, and with which the gentleman from Richmond is but very little familiar. I made these remarks, however, in answer to the argument addressed to the Committee by the gentleman from Halifax, who was stating the interest of Eastern Virginia in the sale of tobacco, and in the immense sale of negro property. I wanted to show
                that there was an entire omission of a great and commanding interest of an immense majority of the people of the Commonwealth. I might apply <PageNumber num={591} /> the same argument to other portions of the State. The South-western portion of Virginia furnishes an immense quantity of salt to Tennessee, and Tennessee furnishes cattle and corn to
                Virginia. I venture to say that the fields of the Fauquier and Loudoun farmers are, at this hour, filled with cattle from Tennessee. And where are they vended? In 'Baltimore, Philadelphia, and New York. You are so inseparably connected in every form of trade and commerce, that you cannot cut the ligaments which bind us, without destruction.
              </p>
              <p className="p-in-sp">For this reason I have been for co-operation throughout, and shall be for co-operation. For this reason I am in favor of indicating this as a mode of ultimate action, in the event it shall ever become necessary to resort to it.</p>
              <p className="p-in-sp">I am not for bringing about that necessity. I voted against the 11th section. But in the event of that contingency coming upon us, then I have no objection to indicate this as the ultimate mode of action to be recommended by this Convention.</p>
              <p className="p-in-sp">
                That is the position I take. We ought to have harmony, or conjunction and concert of action with our sister border slave States, and be willing to consult with them, and form in conjunction with them, a government that should be acceptable to the Southern Confederacy, and acceptable also, at least, to a number of the non-slaveholding States. We must have some voice in selecting the
                position to which your necessities and not ours drive us.
              </p>
              <p className="p-in-sp">
                Is there anything unfair in that? You say that men must be governed by their interests. You must make it the rule of your own action. I must look to the interests of my constituents and of those having a common interest with me. You appeal to my patriotism. I trust I have a patriotism co-extensive with the limits of Virginia, and not restricted to Virginia, either, but co-extensive
                with all America. But where does patriotism begin? I would give but little for the patriotism of him who does not look first to the interests of his own home—to the interests of his own country. You do it—every man of you. And are we to be blamed, we who reside on the Western waters that carry us into the great Mississippi Valley, for trying to shape our course in such direction as
                ultimately to secure those interests with which we are inseparably connected and upon which our prosperity wholly depends? Is there any want of patriotism in that? I trust not. Can you turn the course of that beautiful stream on which I live and bid it pass through the gorges of the Alleghany and mingle with the James River, so as to bring our produce to your Richmond dock, and to
                Norfolk harbor? No. Providence sends me in another direction.
              </p>
              <PageNumber num={592} />
              <p className="p-in-sp">
                I place my foot on a steamer at my own door, and she carries me to Cincinnati, to Louisville, and down the Ohio and the Mississippi. Every article of commerce of that whole people necessarily floats outward by that channel. Should we not, therefore, look to our interests while you are looking to yours? We are willing to be with you, if you will but look to our interests in common
                with your own.
              </p>
              <p>Here the hammer fell.</p>
              <div className="speaker" id="sp3224"><span className="persName">Mr. SLAUGHTER</span>—</div>
              <p className="p-in-sp">I am very much obliged to the gentleman from Kanawha [<span className="persName">Mr. SUMMERS</span>]
                , for his argument, as it is entirely consistent with the proposition that I presented. What is the subject designed to be acted on by the Commissioners in the Conference, proposed to be held at Frankfort. My conclusion was, and I am still more convinced of the correctness of that conclusion on hearing the views of the gentleman from Kanawha, that Virginia ought not to determine
                beforehand what her ultimate policy would be, but leave to the Commissioners that meet at Frankfort, to determine what is best to be done in regard to re-construction. When you come to examine the amendment of the gentleman from Fauquier
                [<span className="persName">Mr. SCOTT</span>] , you will find that it expressly provides, that if the non slaveholding States fail to accept these terms, that then the border slave States shall appoint a Congress to form a Constitution for said States, the Confederate States, and such non slaveholding States as might unite with them.</p>
              <p className="p-in-sp">
                I differ entirely from this policy of presenting any mode of reconstruction after our terms are refused by the non-slaveholding States, inasmuch as its effect would be to forestall the action of the Conference. Why appoint Commissioners, if we do not choose to give them some direction. Are they to go there with the ultimatum of Virginia in their hands, which our resolutions propose,
                and which I favor, and also with definite instructions as to the course to be pursued in reconstructing the Union? Such a course would at once shut the door against all, negotiation and debate, on the part of those States of the border, which are invited into conference with Virginia. What is the use of their uniting in this Conference, if they are precluded from presenting any plan
                of adjustment or of reconstruction. It is asking too much, when we propose to these States to go into Conference with us, telling them, however, in advance, that we have laid down an ultimatum which we expect them to approve, and also, say to them that they not only must agree to our terms, but also, let Virginia dictate what States are to unite in forming the new Government—who are
                to <PageNumber num={593} /> be represented in the Congress, not only as delegates, but as Commissioners.
              </p>
              <p className="p-in-sp">
                This course of policy is by no means respectful to these States. This Conference will prove to be a mere farce, if we pursue such a plan of action. When our Commissioners go to Frankfort, I desire that they shall have some power, some discretion, and not to place Virginia in the attitude of dictating everything that is to be done. If she desires a Conference of the Border States, she
                should at least confer as to the action we should take when we determine to secede, when and on what terms we will make a new Union. Events will clearly indicate what course should be pursued, in less than six months. I, myself, am in favor of uniting with the Confederated States; but there is no occasion now to determine that question. Let us all unite in our determination to
                secede, if our terms are not accepted, leaving our future action to be directed \ by such lights as we may have when called on to act.
              </p>
              <p className="p-in-sp">
                What I propose is, that we should ask these border States to hold. Conventions similar to ours, and to meet on the day we are to reassemble, to act in concert with us. We may then be able to meet together to form a new Government, or to attach ourselves to the Southern Confederacy, as we may deem best. It should be the policy of all, as I am sure it is, to unite all parties, and make
                our report as satisfactory to all as possible. Let us, at this stage of our proceedings, do nothing to divide our counsels, that we are not necessarily required to act on at this time.
              </p>
              <p className="p-in-sp">
                If you indicate one course of policy here, you divide this Convention about half and half. How much better will it be to wait for future developments to indicate our future course. About one half of this Convention would be for uniting, if forced to secede, with the Southern States, and the other moiety for re-constructing in the manner indicated by the resolution of the gentleman
                from Fauquier
                [<span className="persName">Mr. SCOTT</span>] . Let us, then, leave to future events our course of policy in this respect.</p>
              <div className="speaker" id="sp3225"><span className="persName">Mr. FLOURNOY</span>—</div>
              <p className="p-in-sp">I call for the yeas and nays upon that amendment.</p>
              <p>The call was sustained, and the roll being called, resulted—yeas 49, nays 82, as follows :</p>
              <p>
                YEAS-Messrs. Ambler, J. Barbour, Blakey, Boisseau, Borst, Bouldin, Branch, Bruce, Cabell, Cecil, Chambliss, Chapman, Conn, Echols, Flournoy, Forbes, Garland, Graham, F. Gregory, Jr., Goggin, J. Goode, T. F. Goode, F. L. Hale, Cyrus Hall, Harvie, Holcombe, Hunton, Isbell, Kent, Lawson, Leake, C. K. Mallory, Marr, Miller, Morris, Morton, Neblett, Richardson, Seawell, Slaughter,
                <PageNumber num={594} /> Staples, Strange, Sutherlin, Thornton, Tredway, R. H. Turner, Williams, Wise and Wysor-49.
              </p>
              <p>NAYS-Messrs. John Janney [President]
                , Armstrong, Aston, Baldwin, A. M. Barbour, Baylor, Berlin, Blow, Boggess, Boyd, Brent, Brown, Burdett, Burley, Byrne, Campbell, Caperton, Carlile, Clemens, Coffman, C. B. Conrad, R. Y. Conrad, Couch, Critcher, Custis, Dent, Deskins, Dorman, Dulany, Early, French, Fugate, Gillespie, Gravely, Gray, A. Hall, E. B. Hall, Hammond, Hoge, Holladay, Hubbard, Hughes, Hull, Jackson, M.
                Johnson, P. C. Johnston, Kilby, Lewis, McComas, McGrew, Macfarland, J. B. Mallory, Marshall, Marye, Maslin, Masters, Moffett, Moore, Orrick, Osburn, Parks, Patrick, Pendleton, Porter, Price, Pugh, Rives, R. E. Scott, Sharp, Sitlington, Southall, Spurlock, C. J. Stuart, Summers, Tarr, Tayloe, Waller, White, Whitfield, Wickham, Willey, Wilson-82.
              </p>
              <p>So the amendment of <span className="persName">Mr. SLAUGHTER</span> was rejected.</p>
              <p><span className="persName">Mr. CARTER</span>, of Loudoun, paired off with <span className="persName">Mr. SHEFFEY</span>, of Smyth.</p>
              <div className="speaker" id="sp3226"><span className="persName">Mr. FLOURNOY</span>, of Halifax—</div>
              <p className="p-in-sp">I propose to amend the amendment of the gentleman from Fauquier [<span className="persName">Mr. SCOTT</span>] , by inserting in the third line, after the word "Constitution," the words, "presented by this Convention."</p>
              <p className="p-in-sp">This amendment, Mr. Chairman, if adopted, will make the resolution consistent throughout and consistent not only with the 11th section, but with the amendment proposed by the gentleman from Charlotte [<span className="persName">Mr. BOULDIN</span>] , at the end of the amendment of the gentleman from Fauquier, and which was adopted by this Committee. [<span className="persName">Mr. FLOURNOY</span> then read the amendment referred to. It will be found reported elsewhere.]
                The 11th section looks to the presentation, upon the adjournment of this Convention, of the proposed amendments to the constitution, that shall be adopted by this body, immediately to the Northern States, that they may at once inaugurate such measures as shall be necessary to enable them to respond to the call of Virginia by the time that this Convention shall re-assemble.
              </p>
              <p className="p-in-sp">The 14th section, however, looks to the assembling of a border Conference at Frankfort in the latter part of May, and connected with the amendment of the gentleman from Fauquier [<span className="persName">Mr. SCOTT</span>]
                , it leaves us at least in doubt which body is to present the amendments to the constitution proposed to the vote of the North—this Convention or that Convention at Frankfort; and we ought not, sir, I imagine, when we shall have gone through with the work in hand, to leave those who are to act upon our propositions, in any doubt as to the time at which that action is to be commenced;
                and unless these words are inserted the Northern States may very well doubt whether they <PageNumber num={595} /> are at once to inaugurate steps to take the vote of their people upon the amendments to the constitution proposed by this Convention, or whether they are to wait until the Convention at Frankfort shall assemble and debate, and amend or alter
                or adopt the propositions which this Convention shall adopt.
              </p>
              <p className="p-in-sp">
                Now, sir, if the North is to respond to us upon the re-assembling of this Convention—which it has been indicated will take place in October it is necessary that the North should have all the time that can be allowed them, by speedy action of this body, and by presenting these resolutions directly to the North upon the adjournment of this Convention, that they may accomplish the work
                that we desire them to accomplish by the time that we re-assemble. But if it is left open to doubt, or if the construction is placed upon it here that it is the Convention at Frankfort that is to present the final propositions, then the propositions cannot be presented until the latter part of July. Allowing that the Convention that shall assemble at Frankfort has only one-half the
                propensity for discussion that has been evinced in this body, it will be July before this Convention at Frankfort shall agree upon the propositions to be presented to the North ; and as the North will not commence its work until July, and as the people of the nineteen States must be called together to cast the vote of their States upon these propositions, the work will not be
                accomplished before the 1st of October.
              </p>
              <p className="p-in-sp">
                It, therefore, seems to me, that the amendment which I propose is an important one. It gives finality to our action. It enables this Convention to address itself directly to the North, upon its adjournment, and the North at once to commence its work of voting upon these amendments, and by the time we shall re-assemble, we shall know whether they will respond favorably or not. I hope
                it will be the pleasure of the Committee to adopt the amendment, and let it be consistent with the 1 1 th resolution, so that both the 1 1 th resolution and the amendment of the gentleman from Fauquier
                [<span className="persName">Mr. SCOTT</span>]
                , shall be so construed, by the plain terms used in each, that there can be no doubt upon the mind of any man, that Virginia is presenting her final determination, and does not wait the conference of the border States to present her final determination. If, sir, the amendment of the gentleman from Fauquier is intended to be made to correspond with the eleventh section, then it is an
                ultimatum made to the border States. If the gentleman from Fauquier does not regard his amendment as placing us in the same position in regard to the amendments of the constitution as the 11th section places us, then, sir, we are left <PageNumber num={596} /> not in the condition of presenting an ultimatum to the border States or presenting our
                proposition immediately to the North. But, sir, if it is designed by this amendment that an ultimatum shall not be presented to the border States, the 1 1 th section ought to be stricken from our resolution. If it is designed that an ultimatum shall be presented, then the words of the amendment which I propose should be inserted in the amendment of the gentleman from Fauquier
                [<span className="persName">Mr. SCOTT</span>]
                , and the whole, then, will be consistent throughout. All the resolutions will then stand together, and none will make a mistake as to the purpose of this Convention when they shall adopt their final action. I trust the words will be inserted, so that all uncertainty as to the purpose of this Committee may be removed. If a doubt is left by the adoption of the amendment of the
                gentleman from Fauquier as to the purpose of this Committee, when we get back to the Convention, that doubt may be solved by the majority which carries the amendment of the gentleman from Fauquier voting out the 1 1 th section, which was carried yesterday by a vote of 85 to 37.<span className="note" id="Note68"
                ><span className="noteLabel">7</span>The 11th resolution was passed on April 9, by a vote of 79 to 41, according to the Proceedings, page 435, or a vote of 81 to 41, according to the Journal.</span
                >
                I want certainty, if not finality; I desire finality, but we all ought to desire certainty, so that none can misapprehend the purpose of this Convention when it shall take its final action.
              </p>
              <div className="speaker" id="sp3227"><span className="persName">Mr. JOHNSON</span>, of Richmond—</div>
              <p className="p-in-sp">Mr. Chairman, I trust it will not be the pleasure of the Committee to adopt the amendment offered by the gentleman from Halifax [<span className="persName">Mr. FLOURNOY</span>]
                . I do not regard it as one which contributes, at all, to render certain what is now uncertain, but I regard it more as having in view the purposes of my friend and those with whom he agrees, to bring this Convention to action which will submit to the Northern people from this Convention, and this Convention alone, an ultimatum. That is the matter in which I construe the object of
                the amendment offered by my friend from Halifax. Now, sir, if I understand the question under consideration, it presents itself to the Convention in this wise. The 1 1 th section declares, that if the Northern States do not respond satisfactorily to the amendments which this Committee will adopt, then Virginia will resume her granted powers. The twelfth and thirteenth sections
                declare that as an indispensable condition the Federal Government shall abstain from any aggressive policy and that both sections shall do nothing tending to occasion hostility; and then we come to the fourteenth section, which proposes a Border Slave State Conference.
              </p>
              <p className="p-in-sp">
                Now, sir, I submit to this Convention that a border slave State conference must be intended for something. I submit that there must be some consequence attached to a conference which is designed to be <PageNumber num={597} /> held with the border slave States. I therefore, take it, that if any consequence is attachable to that conference we will not go
                there carrying in our pockets our own resolutions, telling them in a manner of dictation : you shall assent to this or nothing. Now, sir, that is the attitude which Virginia would occupy under such circumstances. The fact is, that the reason why we are not now in a border State conference, is because of the geographical impossibility, and it is the purpose to bring about a border
                State Conference as soon as it is practicable, but, in the meantime, it is our purpose to submit to the Northern people our policy, and the terms upon which we will live with them, and if they are not responded to, we will then resume our granted powers. In the meantime we will hold a border State conference with our immediate neighbors—and why? Because they are our immediate
                neighbors; because we are surrounded by them; because we are bounded on the South by North Carolina, on the South-west by Tennessee, on the West by Kentucky, and on the North and Northeast by Maryland and Delaware; because these States are slaveholding States; because we are in the midst of them; and because we cannot act independent of them finally. If we do not get satisfactory
                responses to our amendments and do then resume our powers—that is not final action. We then have taken one more and I fear sad step; but that is not final action. We then have something else to do. We must not stand alone, unassociated with anybody, as an independent and isolated power. We have something else to do; but we must ascertain what that is. How must we ascertain what that
                is? By a fair and manly conference with our border slave States—with those who are identified with us by associations, by institutions, and by God. And, therefore, I say, sir, that to present on our part irretractable, irrevocable and final propositions to the Northern States, without conferring with the border slave States, and conferring with them would be an act entirely
                unwarranted on our part—an act<span className="note" id="Note69"><span className="noteLabel">8</span>Apparently "would be" is omitted here before "an act."</span> which we would not be authorized to do, because of our associations with our immediate neighbors.
              </p>
              <p className="p-in-sp">
                There is no inconsistency between the amendment and the 1 1 th resolution, because it is for the people of Virginia to determine at last, and say whether the responses which come from the Northern people are satisfactory or not. I say that it well behooves the people of Virginia in determining that question, not to be controlled, not to be dictated to, not to be regulated by anybody
                else, but to determine the question upon consultation with those whom God Almighty had connected with them—her sisters of the border slave States. I trust, therefore, <PageNumber num={598} /> it will not be the pleasure of this Convention to adopt the amendment of the gentleman from Halifax, which, if adopted, will render a conference with the border
                slave States inconsistent with any practical purpose, because if you go into a Conference with them, with the unalterable temper that the proposition shall not be altered, modified or changed, even for our benefit; if you go there and say, we will not listen to your wisdom, we would put them in a position in which their own dignity would constrain them to say, we cannot hold
                conference with you, because it is not a conference conducted upon equal and honorable terms.
              </p>
              <p>Here the hammer fell.</p>
              <div className="speaker" id="sp3228"><span className="persName">Mr. FLOURNOY</span>—</div>
              <p className="p-in-sp">If the construction of the amendment of the gentleman from Fauquier made by my friend from the city of Richmond [<span className="persName">Mr. JOHNSON</span>] , is correct, then there is a direct conflict between the 11th section and the amendment of the gentleman from Fauquier [<span className="persName">Mr. SCOTT</span>]
                . My friend from Richmond argues against the amendment proposed by myself, that it would be presenting the final action of Virginia and would restrain the conference at Frankfort, from altering or amending the propositions submitted by this Convention. It was that very difference of construction placed upon this substitute and upon the 1 1 th section that induced me to offer this
                amendment, that certainty might be given to the action of this body ; that none might misunderstand it who had finally to act upon it.
              </p>
              <p className="p-in-sp">When my distinguished friend from Kanawha r [<span className="persName">Mr. SUMMERS</span>] , addressed the Convention but a short time since, he referred to this 1 1 th section and said that he had voted against it. He referred to it as indicating that by that section the action of this Convention upon these amendments would be final and cut off all conference with the Border States.</p>
              <p className="p-in-sp">As to the character of these amendments, I turn from the gentleman from Kanawha [<span className="persName">Mr. SUMMERS</span>] , to my friend from Richmond [<span className="persName">Mr. JOHNSON</span>] , and I find that he construes the amendment of the gentleman from Fauquier [<span className="persName">Mr. SCOTT</span>]
                , exactly the other way. Now, sir, leave the 1 1 th section as it is. Send out our proposition and then come back upon this Convention for the construction of their labors—and we are divided here as to the meaning of the language that we use, in regard to the very amendment upon which we require action. While I did not hear the construction which the distinguished gentleman from
                Fauquier put upon it, I think that it must be the same as that of the gentleman from Richmond.
              </p>
              <PageNumber num={599} />
              <p className="p-in-sp">The other day the gentleman from Fauquier, offered an amendment, which was printed by the order of the Convention.</p>
              <p className="p-in-sp">The amendment makes a request of the authorities of the several Border Slave States to "assemble the people thereof in primary Conventions, to take into consideration the propositions of this Convention," thus giving finality to it, and making it consistent with the 11th section.</p>
              <p className="p-in-sp">In the amendment he now proposes that this be stricken out, and in place of it he proposes to insert the proposed amendments to the Constitution. In reply to my friend from Charlotte [<span className="persName">Mr. BOULDIN</span>] , I inferred that he still regarded that when we went to the Border States, all these questions were open, for he said no restriction ought to be imposed, because in that border conference stronger demands might and ought to be made, than were contained in the amendment now proposed to be adopted by this Convention.</p>
              <p className="p-in-sp">
                Then, sir, are we to be placed in the condition that, when our labors are over, we ourselves don't know what we have done? Are the people of the Northern States to be left in a condition in which they can justly and properly excuse themselves from taking immediate action, and await the Border Conference in Kentucky, which may be in session six weeks, two months, or three months, and
                when we return for the apology to be made for the North that she has not acted because she has not had time to act? And when we return, having placed the North in this condition of uncertainty, having placed ourselves in this condition of uncertainty, I demand to know if an argument, strong and powerful, could not be made that Virginia shall not yet take her independent position,
                because of the uncertain condition in which her Convention placed these questions, the North have had no time to act? Sir, I will ask my friend from Fauquier, if he regards himself, by this amendment, as presenting an ultimatum to the Border Conference, or leaving these questions open, to be settled by that Border Conference, and for the Border Conference to present the ultimatum?
              </p>
              <div className="speaker" id="sp3229"><span className="persName">Mr. SCOTT</span>, of Fauquier—</div>
              <p className="p-in-sp">
                I will answer the gentleman from Halifax, that according to my grammatical construction of the sentence, the resolution means precisely the same with the words now employed as it will when he substitutes his amendment. Now, will the gentleman explain to this Committee what the definite article means—the proposed amendments? What proposed amendments? Of course these amendments
                appended to this report. It cannot mean anything else.
              </p>
              <div className="speaker" id="sp3230"><span className="persName">Mr. FLOURNOY</span>—</div>
              <p className="p-in-sp">
                Then, sir, if the amendment which I propose is <PageNumber num={600} /> inserted we will be relieved from all the difficulty of nice grammatical construction, and it will not change the sense intended by the gentleman from Fauquier in his resolution, but will make it so plain that he who runs may read, whether he is capable of making nice grammatical
                distinction or not. I hope the amendment will be adopted.
              </p>
              <p><span className="persName">Mr. AMBLER</span> called for the ayes and noes and the call was sustained.</p>
              <p>The vote was then taken on the motion to insert <span className="persName">Mr. FLOURNOY</span>'S amendment, which was decided in the negative—ayes 56; noes 72, as follows :</p>
              <p>
                YEAS-Messrs. Ambler, Blakey, Boisseau, Borst, Bouldin, Branch, Bruce, Cabell, Caperton, Cecil, Chambliss, Chapman, Coffman, Conn, Dorman, Echols, Flournoy, Forbes, Garland, Graham, Gravely, Gregory, Jr., Goggin, J. Goode, Jr., T. F. Goode, Hale, Cyrus Hall, L. S. Hall, Harvie, Holcombe, Hunton, Isbell, Kent, Kilby, Lawson, Leake, C. K. Mallory, Marr, Marye, Sr., Morris, Morton,
                Neblett, Orrick, Richardson, Seawell, Slaughter, Southall, Staples, Strange, Sutherlin, Thornton, Tredway, R. H. Turner, Williams, Wise, Wysor-56.
              </p>
              <p>NAYS-Messrs. John Janney [President]
                , Armstrong, Aston, Baldwin, A. M. Barbour, Baylor, Blow, Jr., Boggess, Boyd, Brent, Brown, Burdett, Burley, Byrne, Campbell, Carlile, Clemens, C. B. Conrad, Robert Y. Conrad, Couch, Critcher, Custis, Deskins, Dulany, Early, French, Fugate, Gillespie, Gray, A. Hall, E. B. Hall, Hammond, Haymond, Hoge, Holladay, Hubbard, Hughes, Hull, Jackson, M. Johnson, P. C. Johnston, Lewis,
                McComas, McGrew, Macfarland, J. B. Mallory, Marshall, Maslin, Masters, Moffett, Moore, Osburn, Patrick, Pendleton, Porter, Price, Pugh, Rives, R. E. Scott, Sharp, Sitlington, Spurlock, C. J. Stuart, Summers, Tarr, Tayloe, Waller, White, Whitfield, Wickham, Willey, Wilson-72.
              </p>
              <div className="speaker" id="sp3231"><span className="persName">Mr. MARR</span>—</div>
              <p className="p-in-sp">It is now nearly seven o'clock. I move that the Committee rise.</p>
              <p>A call for the ayes and noes on that motion was not sustained.</p>
              <p>A division was subsequently called for, and by a vote of 57 in the</p>
              <p>affirmative and 55 in the negative, the Committee concluded to rise. The Committee then rose, and the Chairman, <span className="persName">Mr. SOUTHALL</span>,</p>
              <p>reported progress.</p>
            </div>
            <div className="section" id="vsc1965.v3.2.9.5">
              <h3><span className="headingNumber">1.9.5. </span><span className="head">IN CONVENTION</span></h3>
              <div className="speaker" id="sp3232"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The pending question is on the adoption of the amendment to the resolutions offered by the gentleman from Monongalia [<span className="persName">Mr. WILLEY</span>] .</p>
              <p>Calls of "question, question."</p>
              <div className="speaker" id="sp3233"><span className="persName">Mr. WYSOR</span>—</div>
              <p className="p-in-sp">I wish simply to present a proposition.</p>
              <div className="speaker" id="sp3234"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">I move that we do now adjourn.</p>
              <div className="speaker" id="sp3235"><span className="persName">SEVERAL MEMBERS</span>—</div>
              <p className="p-in-sp">O no! Withdraw that motion.</p>
              <PageNumber num={601} />
              <p><span className="persName">Mr. MORTON</span> withdrew his motion to adjourn.</p>
              <div className="speaker" id="sp3236"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">Gentlemen, as many as are in favor of adopting—</p>
              <div className="speaker" id="sp3237"><span className="persName">Mr. WICKHAM</span>—</div>
              <p className="p-in-sp">Mr. President, I desire to say a few words upon this subject before the vote is taken.</p>
              <div className="speaker" id="sp3238"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">Then I move that the Convention do now adjourn.</p>
              <div className="speaker" id="sp3239"><span className="persName">Mr. WICKHAM</span>—</div>
              <p className="p-in-sp">I understand that the gentleman withdrew the motion to adjourn with the expectation that there was nothing more to be said upon the subject. If that is the case, I yield to him.</p>
              <div className="speaker" id="sp3240"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">I move, sir, that this Convention do now adjourn.</p>
              <div className="speaker" id="sp3241"><span className="persName">Mr. PUGH</span>—</div>
              <p className="p-in-sp">I call for the ayes and noes.</p>
              <p>The call was sustained.</p>
              <p>The question was then taken on the motion to adjourn, which was decided in the negative, ayes 43, noes 73, as follows:</p>
              <p>
                YEAS-Messrs. Ambler, Blakey, Blow, Borst, Bouldin, Bruce, Chambliss, Coffman, Custis, Forbes, Garland, Gillespie, Gregory, J. Goode, Jr., T. F. Goode, Harvie, Holcombe, Holladay, Hughes, Hunton, Isbell, Kilby, Lawson, Leake, Charles K. Mallory, Marr, Miller, Morris, Morton, Neblett, Orrick, Richardson, Seawell, Southall, Strange, Sutherlin, Tayloe, Thornton, Tredway, Robert H.
                Turner, Whitfield, Williams, Wise-43.
              </p>
              <p>NAYS-Messrs. John Janney [President]
                , Armstrong, Aston, Baldwin, A. M. Barbour, Baylor, Berlin, Boggess, Boyd, Brent, Brown, Burdett, Burley, Byrne, Campbell, Caperton, Carlile, Chapman, Clemens, Conn, C. B. Conrad, Couch, Critcher, Deskins, Dorman, Dulany, Early, Echols, French, Fugate, Gravely, Gray, Goggin, Hale, Addison Hall, L. S. Hall, Haymond, Hoge, Hubbard, Hull, Jackson, Marmaduke Johnson, Peter C. Johnston,
                Kent, Lewis, McComas, McGrew, J. B. Mallory, Marshall, Maslin, Masters, Moffett, Moore, Nelson, Osburn, Parks, Patrick, Porter, Price, Pugh, Sharp, Sitlington, Spur-lock, Staples, C. J. Stuart, Summers, Tarr, Waller, White, Wickham, Willey, Wilson, Wysor-73.
              </p>
              <p>Calls of "Question, question."</p>
              <div className="speaker" id="sp3242"><span className="persName">Mr. WYSOR</span>—</div>
              <p className="p-in-sp">I will ask the gentleman from Henrico to indulge me for one moment; I will not consume one moment of time. I simply ask that this amendment which I now wish to present, may be printed and referred to the Committee of the Whole.</p>
              <p>No opposition being interposed, the proposition which <span className="persName">Mr. WYSOR</span> presented, took that course :</p>
              <p>"1. It shall be the duty of the President of this Convention, immediately on its adjournment, to certify to the Governor of this Commonwealth a copy of the foregoing resolutions and proposed amendments to the Constitution of the United States thereunto annexed, together with this schedule.</p>
              <p>"2. Upon the receipt of such certified copy the Governor shall forthwith announce the fact by proclamation, to be published in such</p>
              <PageNumber num={602} />
              <p>
                newspapers of the State as may be deemed requisite for general information; and shall annex to his proclamation a copy of said resolutions, amendments and schedule ; which proclamation shall be published in the manner above mentioned for the space of one month; and the Secretary of the Commonwealth shall immediately transmit, by mail, ten printed copies thereof, together with the
                amendments to the Constitution of this State, hereinafter set forth, to the clerks of each county and corporation court in this Commonwealth, to be by such clerks submitted to the examination of any person desiring the same.
              </p>
              <p>
                "3. It shall also be the duty of the Governor immediately upon the receipt of the certified copy aforesaid, to transmit copies thereof to the President of the United States, and to each of the Governors of the several States yet remaining in the Union; making known to the former the wish of this Convention, that he shall, with as little delay as possible, convene the Congress of the
                United States, in extra session, and lay before that body the amendments aforesaid, in order that the same may be proposed to the Legislatures or Conventions of the several States, for ratification, as part of the Constitution of the United States; and expressing to the latter the desire of this Convention, that the Legislatures of, or Conventions, in their respective States, may be
                convened, to take into consideration the ratification of the amendments aforesaid, if the same shall be proposed to them by Congress, and requesting each of them, on or before the time specified in the next succeeding section, save one of this schedule, to communicate to the Executive of this State, the result of their deliberations.
              </p>
              <p>
                "4. In the event that Congress shall, on or before the day of , in the year one thousand eight hundred and sixty-one, propose the amendments aforesaid to the several States for ratification, the Governor shall forthwith, by proclamation, summon the members of this Convention to meet at the Capitol, in the City of Richmond, on a day to be named by him, not exceeding thirty from the
                date of his proclamation.
              </p>
              <p>"5. In the event that the amendments aforesaid shall not be proposed by Congress to the several States for ratification, or having been proposed, shall not, on or before the day of , in the year</p>
              <p>
                one thousand eight hundred and sixty-one, have been ratified by the Legislatures of three-fourths of the several States, Virginia inclusive, or by Conventions in three-fourths thereof, so that the same shall be valid to all intents and purposes, as part of the Constitution of the <PageNumber num={603} /> United States; it shall be the duty of the
                Governor to make that fact known by proclamation, to be published in the manner prescribed in the second section of this schedule; and in the same proclamation he shall appoint a day, not exceeding sixty nor less than thirty days, from the day of aforesaid, for holding an election to take the sense of the people of this Commonwealth upon the question of ratifying or rejecting the
                following ordinance, to wit:
              </p>
              <p>
                "We, the people of Virginia, do declare and ordain, and it is hereby declared and ordained, that the ordinance adopted by us in Convention, on the twenty-fifth day of June, in the year of our Lord one thousand seven hundred and eighty eight, whereby the Constitution of the United States of America was ratified; and also all acts and parts of acts of the General Assembly of this
                State, ratifying amendments of the said Constitution, are hereby repealed; and that the Union now subsisting between Virginia and other States, under the name of the United States of America, is hereby dissolved.
              </p>
              <p>
                "6. Upon the day appointed by the Governor as aforesaid, the officers authorized by existing laws to conduct general elections, shall, at the places appointed for holding the same, open a poll book to be headed with the ordinance aforesaid, and to contain two separate columns; the first column to be headed, "For ratifying," the other to be headed, "For rejecting." And such officers,
                keeping said polls open for the space of three days, shall then and there receive and record in said poll book, the votes for and against said ordinance, of all persons qualified under existing laws to exercise the right of suffrage.
              </p>
              <p>
                "7. The taking of the polls, the duties to be performed by the officers, the privilege of the voters, and the penalties attaching for misconduct on the part of any person shall be in all things as prescribed by existing laws regulating general elections so far as they may be applicable, adopting the forms of the returns, to the purpose of certifying the votes aforesaid to the
                Governor, and to the clerks of the several county and corporation Courts of the Commonwealth.
              </p>
              <p>
                "8. It shall be the duty of the Governor upon receiving the returns of said officers, to ascertain the result thereof, and forthwith to declare the same by his proclamation, stating the aggregate vote in the State for and against the ratification aforesaid, and if a majority of all the votes cast in said election shall be in favor of ratifying said ordinance, he shall in the same
                proclamation, summon the members of this Convention to meet at the Capitol, in the city of Richmond, on a day to be named by him, not exceeding twenty days from the date of his said proclamation, to take into consideration such measures and such new <PageNumber num={604} /> connection as the adoption of said ordinance may make necessary for the safety
                and happiness of this State."
              </p>
              <div className="speaker" id="sp3243"><span className="persName">Mr. WICKHAM</span>—</div>
              <p className="p-in-sp">
                Mr. President, it is with a great deal of reluctance that I undertake to address the Convention when I see gentlemen so impatient to get to their lodgings. I had intended to take no part whatever in the discussion of the question now before the Convention, and should not attempt to do so at this time, but for some remarks made this morning by the gentleman from the counties of
                Pleasants and Ritchie
                [<span className="persName">Mr. CYRUS HALL</span>] . Having heard gentlemen from the Western part of this State who have been acting in concert with myself upon this floor denounced as being untrue to Virginia, and having listened to the declarations of the gentleman from Pleasants and Ritchie, I cannot refrain from making a few remarks in relation to the subject.</p>
              <p className="p-in-sp">
                I desire to call attention to the fact that propositions to trade upon Virginia's rights and interests, have come entirely from the secession members of the body. First, we had a proposition made in Convention, by an Eastern member to Western men, that if they would go for an ordinance of secession and get their people to sustain it, that he would give them any taxation on slaves
                that they might ask for. And to-day we have from the delegate from the counties of Pleasants and Ritchie
                [<span className="persName">Mr. CYRUS HALL</span>]
                , a member who has voted with the secessionists of this body on every single proposition affecting Federal Relations, I believe, with only the exception of the direct vote on secession, and if I understand him aright, he is now ready to give that vote, a declaration that, unless Eastern men will grant the power to tax negroes of all ages ad valorem, his constituents will not give
                assistance for the protection of slave property in Virginia, but that on the contrary the underground railroad will be run through his country down into the Valley of Virginia. That to induce them to aid in the protection of slave property you must give them an interest in that property—and that interest must not be in the form of protection, which would enable them to hold slaves,
                but in the form of a cession to his people of the power to impose ruinous taxation upon the slaves held chiefly by us of the East. Sir, no such proposals for the barter of Virginia's rights have been heard on our side of the house, and after this, I hope we will hear no repetition of the unjust charge that Western Union men are not true to Virginia.
              </p>
              <p className="p-in-sp">
                I differ entirely from the delegates in this Convention from the West, on the question of taxation upon negroes. I conceive that the Constitution of 1850 contains a compromise on the question of <PageNumber num={605} /> representation, which, if not designed for the purpose, would, by giving the East the control of the Senate, have secured protection to
                us from this tax until after 1865, as no Convention could have been called prior to that date, but for some such cause as existed at the time that this one was ordered. Entertaining that opinion and desiring to give it effect, when the bill calling this Convention was under consideration, in the Senate, I offered, as an amendment, this provision :
              </p>
              <p className="p-in-sp">"But, in the opinion of the General Assembly it would be in violation of the spirit with which this Convention is called that it should, in any manner, alter the provisions contained in the existing Constitution of the Commonwealth in regard to taxation, or the apportionment of representation in either branch of the General Assembly."</p>
              <p className="p-in-sp">It met with opposition from both Western and Eastern members of that body, for reasons of a different character, and believing that it would be defeated on account of the anxiety to pass the bill through, and fearing that a negative vote on the proposition would be construed as a yielding of the question, I withdrew it without, allowing a vote on it.</p>
              <p className="p-in-sp">
                I have prepared some tables, showing the relative population and taxation of the counties of Henrico, Hanover, Pleasants and Ritchie, and of the counties of Amelia and Nottoway, represented by my friend <span className="persName">Mr. HARVIE</span>, who sits near me, which I will not trouble the Convention to hear read, but which I will hand to the reporters. They are as follows :
                <span className="note" id="Note70"><span className="noteLabel">9</span>There seem to be several errors in addition in the figures, and it looks as if some figures had been quoted incorrectly. However, the text of the Enquirer is reproduced here.</span>
              </p>
              <p className="p-in-sp">Table of Population and Taxes—Population taken from Census of</p>
              <p className="p-in-sp">1860—Taxes, etc., as reported by the Auditor for 1859.</p>
              <p className="p-in-sp">HENRICO</p>
              <p className="p-in-sp">Whites, 12,757 Taxable Slaves, 4,628—Slaves, 8,668.</p>
              <p className="p-in-sp">Tax on Real Estate $29,952.18</p>
              <p className="p-in-sp">Personal tax 17,586.87</p>
              <p className="p-in-sp">License tax 4,047.45</p>
              <p className="p-in-sp">Total $51,086.50</p>
              <p className="p-in-sp">Whites over 21 years 2,430</p>
              <p className="p-in-sp">Horses 3,171</p>
              <p className="p-in-sp">Cattle, etc. 11,555</p>
              <p className="p-in-sp">Tax on each adult white male $21.00</p>
              <p className="p-in-sp">Tax on slaves 5,547.60</p>
              <p className="p-in-sp">Tax on other property 12,039.27</p>
              <p className="p-in-sp">Tax on real and personal property, other than slaves 41,491.45</p>
              <PageNumber num={606} />
              <p className="p-in-sp">HANOVER</p>
              <p className="p-in-sp">Whites 7508; Taxable slaves 4850; slaves 9484.</p>
              <p className="p-in-sp">Tax on real estate $12,767.11</p>
              <p className="p-in-sp">Tax on personal property 12,999.82</p>
              <p className="p-in-sp">License tax 1,766.74</p>
              <p className="p-in-sp">Total $27,535.67</p>
              <p className="p-in-sp">Whites over 21 years 1,450</p>
              <p className="p-in-sp">Tax on each adult white male 19.00</p>
              <p className="p-in-sp">Tax on slaves 5,820.00</p>
              <p className="p-in-sp">Tax on other property 7,179.82</p>
              <p className="p-in-sp">Tax on real and personal property; other than slaves 19,946.93</p>
              <p className="p-in-sp">Horses 3,308</p>
              <p className="p-in-sp">Cattle, etc. 24,541</p>
              <p className="p-in-sp">PLEASANTS</p>
              <p className="p-in-sp">Whites 2,926—Taxable slaves, 5—Slaves 15.</p>
              <p className="p-in-sp">Tax on real estate $ 2,406.29</p>
              <p className="p-in-sp">Personal tax 981.23</p>
              <p className="p-in-sp">License tax 553.43</p>
              <p className="p-in-sp">Total $ 3,940.95</p>
              <p className="p-in-sp">Whites over 21 years 559</p>
              <p className="p-in-sp">Tax to each adult white male 7.00</p>
              <p className="p-in-sp">Tax on slaves 6.00</p>
              <p className="p-in-sp">Tax on other personal property 975.23</p>
              <p className="p-in-sp">Tax on real and personal property, other than slaves 3,381.52</p>
              <p className="p-in-sp">Horses 687</p>
              <p className="p-in-sp">Cattle, etc. 8,222</p>
              <p className="p-in-sp">RITCHIE</p>
              <p className="p-in-sp">Whites, 6,809—Taxable slaves 8—Slaves 38</p>
              <p className="p-in-sp">Tax on real estate $ 4,967.59</p>
              <p className="p-in-sp">Tax on personal property 1,892.82</p>
              <p className="p-in-sp">License tax 782.45</p>
              <p className="p-in-sp">Total $ 7,642.86</p>
              <p className="p-in-sp">Whites over 21 years 1,065</p>
              <p className="p-in-sp">Tax to each adult white male 7.50</p>
              <p className="p-in-sp">Tax on slaves 9.60</p>
              <p className="p-in-sp">Tax on other property 1,883.22</p>
              <PageNumber num={607} />
              <p className="p-in-sp">Tax on real and personal property, other than slaves 6,850.81</p>
              <p className="p-in-sp">Horses 1,513</p>
              <p className="p-in-sp">Cattle, etc. 19,228</p>
              <p className="p-in-sp">RITCHIE AND PLEASANTS-combined</p>
              <p className="p-in-sp">Whites, 9,735—Taxable slaves, 13—Slaves 53.</p>
              <p className="p-in-sp">Tax on real estate $ 7,373.88</p>
              <p className="p-in-sp">Tax on personal property 2,874.05</p>
              <p className="p-in-sp">License tax 1,335.88</p>
              <p className="p-in-sp">Total $11,583.81</p>
              <p className="p-in-sp">Whites over 21 years 1,624</p>
              <p className="p-in-sp">Tax to each white male 7.12 IA</p>
              <p className="p-in-sp">Tax on slaves 15.60</p>
              <p className="p-in-sp">Tax on other personal property 2,858.45</p>
              <p className="p-in-sp">Tax on real and personal property other than slaves 10,232.88</p>
              <p className="p-in-sp">Horses 2,200</p>
              <p className="p-in-sp">Cattle, &amp;c. 27,400</p>
              <p className="p-in-sp">Total value of slaves at $500 26,500.00</p>
              <p className="p-in-sp">Increase tax 90.90</p>
              <p className="p-in-sp">AMELIA</p>
              <p className="p-in-sp">Whites, 2,894—Taxable slaves, 4,152—Slaves, 7,667.</p>
              <p className="p-in-sp">Tax on real estate $ 8,824.92</p>
              <p className="p-in-sp">Personal property 9,946.21</p>
              <p className="p-in-sp">License tax 1,666.16</p>
              <p className="p-in-sp">Total 20,437.29</p>
              <p className="p-in-sp">White adult males 674</p>
              <p className="p-in-sp">Tax on each adult white males 30.331/3</p>
              <p className="p-in-sp">Tax on slaves 4,982.40</p>
              <p className="p-in-sp">Tax on other personal property 4,963.81</p>
              <p className="p-in-sp">Tax on real estate and personal property other than slaves 18,788.73</p>
              <p className="p-in-sp">Horses 2,042</p>
              <p className="p-in-sp">Cattle, &amp;c. 19,158</p>
              <p className="p-in-sp">NOTTOWAY</p>
              <p className="p-in-sp">Whites 2270; taxable slaves 3892; slaves 6468.</p>
              <p className="p-in-sp">Tax on real estate $ 7,779.43</p>
              <p className="p-in-sp">Tax on personal property 9,201.85</p>
              <PageNumber num={608} />
              <p className="p-in-sp">License tax 1,094.74</p>
              <p className="p-in-sp">Total $18,076.02</p>
              <p className="p-in-sp">White males over 21 years 531</p>
              <p className="p-in-sp">Tax to each adult white male $34</p>
              <p className="p-in-sp">Tax on slaves 4,670.40</p>
              <p className="p-in-sp">Tax on other personal property 4,531.48</p>
              <p className="p-in-sp">Tax on real and personal property, other than slaves 12,310.91</p>
              <p className="p-in-sp">Horses 1,610</p>
              <p className="p-in-sp">Cattle, &amp;c. 19,067</p>
              <p className="p-in-sp">AMELIA AND NOTTOWAY COMBINED</p>
              <p className="p-in-sp">Whites 5164; taxable slaves 8044; slaves 14135.</p>
              <p className="p-in-sp">Tax on real estate $16,604.35</p>
              <p className="p-in-sp">Tax on personal property 19,148.06</p>
              <p className="p-in-sp">License tax 2,760.90</p>
              <p className="p-in-sp">Total $38,513.31</p>
              <p className="p-in-sp">White males over 21</p>
              <p className="p-in-sp">Tax to each adult white male</p>
              <p className="p-in-sp">Total value of slaves at $500</p>
              <p className="p-in-sp">Tax on slaves</p>
              <p className="p-in-sp">Tax on other personal property</p>
              <p className="p-in-sp">Tax on real estate and personal property, other than</p>
              <p className="p-in-sp">slaves 26,099.64</p>
              <p className="p-in-sp">Horses 3,652</p>
              <p className="p-in-sp">Cattle, &amp;c. 28,225</p>
              <p className="p-in-sp">Increased tax 19,617.20</p>
              <p className="p-in-sp">HANOVER</p>
              <p className="p-in-sp">Total value of slaves at $500 $4,742,000.00</p>
              <p className="p-in-sp">Increase of tax on county 13,148.00</p>
              <p className="p-in-sp">HENRICO</p>
              <p className="p-in-sp">Total value of slaves at $500 $4,334,000.00</p>
              <p className="p-in-sp">Increase of tax on county 11,788.40</p>
              <p className="p-in-sp">THE STATE AT LARGE</p>
              <p className="p-in-sp">Total value of 500,000 at $500 $250,000,000.00</p>
              <p className="p-in-sp">Total tax at 400 on $100 of value 1,000,000.00</p>
              <p className="p-in-sp">Tax on slaves in 1859 326,487.60</p>
              <p className="p-in-sp">Increase of taxes on slave property, if proposed altera-</p>
              <p className="p-in-sp">tion in State constitution is made 673,512.40</p>
              <p className="p-in-sp">1,205</p>
              <p className="p-in-sp">$32</p>
              <p className="p-in-sp">7,067,500.00 9,652.80 9,495.21</p>
              <PageNumber num={609} />
              <p className="p-in-sp">
                It appears from these tables that the counties of Ritchie and Pleasants combined, with an aggregate population of 9,735 whites, pay a tax of $11,583.81, including the license tax; whilst the county of Henrico, which I represent on this floor, pays an aggregate State tax of $51,086.50; and the tax on personal property, exclusive of slaves, amounts to $12,039.27, being about one dollar
                per head to each white inhabitant; whilst in Pleasants and Henrico<span className="note" id="Note71"><span className="noteLabel">10</span>The speaker must have meant to say Ritchie.</span> it amounts to less than 30 cents per head. In the county of Hanover, which is chiefly a grain growing county, the proportion is about the same as in Henrico; but in the counties of Amelia and Nottoway (
                the district of my friend <span className="persName">Mr. HARVIE</span> which is more of a planting country, and where tobacco is the chief staple, the difference is still more marked. In those counties the tax on personal property other than slaves, amounts to the very large average of $1.83 to each white inhabitant. In the whole State the tax on slaves in 1859 was $326,487.60, and if
                the proposed increase were made, estimating the average value of slaves at $500 apiece, which is $112 a head less than they are assessed at in Georgia for taxation, that tax would amount to $1,000,000. In other words, the slaveholding interest of the State would have to pay an increased tax of $673,512.40 per annum.
              </p>
              <p className="p-in-sp">
                If I chose to occupy the time of the Convention, I could show that the tax paid on other species, of personal property in Eastern Virginia, is as great as that paid in Western Virginia. We own almost as many head of cattle. Certainly, in the counties where I have drawn comparisons, we own a larger number of horses than are owned in the Western counties of like population. Therefore,
                the present system of taxation is really not unjust to the West. I also call the attention of the Convention to the fact that this Convention has been called mainly for the purpose of securing protection to slave property, and I say to gentlemen that the imposition of this tax on young negroes would be a more dangerous blow to the institution of slavery in the State of Virginia than
                any single act that could be done by the government. The imposition of $672,000 a year as additional taxation on slave property, would be a far greater injury than the loss of $100,000 a year by fugitive slaves—which is, I believe, the estimated loss in the whole of the slaveholding States.
              </p>
              <p className="p-in-sp">It seems to me that it would be most unwise, now that we are attempting to secure slave property, to inflict a blow upon it which will inevitably drive it, to a certain extent, from the Commonwealth of Virginia. With these remarks, I submit the question to the Convention, not desiring to detain them longer at this late hour.</p>
              <PageNumber num={610} />
              <div className="speaker" id="sp3244"><span className="persName">Mr. HAYMOND</span>—</div>
              <p className="p-in-sp">I am exceedingly anxious to have a vote taken on this question. It merely proposes a committee of inquiry as to whether the constitution should be amended. The original resolution has been changed so as to allow an opportunity for gentlemen to consult with their constituents on the subject. I demand the previous question.</p>
              <p>The previous question was seconded.</p>
              <div className="speaker" id="sp3245"><span className="persName">Mr. NELSON</span>—</div>
              <p className="p-in-sp">I desire, if it be in order, to state my position.</p>
              <div className="speaker" id="sp3246"><span className="persName">Mr. STAPLES</span>—</div>
              <p className="p-in-sp">-Can the gentleman discuss the merits of the question on the call for the previous question?</p>
              <div className="speaker" id="sp3247"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">It is not in order to go into the general merits of the matter, but it is in order to allude to the original resolution to show why the debate should be continued.</p>
              <div className="speaker" id="sp3248"><span className="persName">Mr. NELSON</span>—</div>
              <p className="p-in-sp">
                I hope gentlemen will listen to my remarks before undertaking to object to them. I wish to represent my people correctly, and I know that they were so much occupied at the time of my election in the consideration of federal questions, that they never thought of this question at all. Still I am perfectly willing to vote for the amendment. I believe my people have already to pay as
                much tax as they can afford to pay, and I shall vote against anything that will tend to increase their burdens. With the exception of the county of Jefferson, my county pays a larger per capita tax in proportion to its white population, than any other county in the State. We pay $6.10 per capita while the county of Monongalia pays $1.60. It is because I know that my people are fully
                burdened in the way of taxes, that I shall oppose any thing having a tendency to increase their taxes.
              </p>
              <div className="speaker" id="sp3249"><span className="persName">Mr. PARKS</span>—</div>
              <p className="p-in-sp">
                Mr. President, some days since I presented the proceedings of a public meeting in my county, in which they particularly protested against this Convention taking any action on any subject aside from that of federal relations. It was not expected that this Convention would touch any other question. But inasmuch as this resolution is merely one of inquiry, without any design to take up
                the question at this session, I shall vote for it with the understanding that the Committee will not report at the present sitting of the Convention, but after the recess.
              </p>
              <p>The main question was ordered, which was on the adoption of the substitute, as follows :</p>
              <p>
                "Resolved, That a committee of thirteen be appointed to inquire into the expediency of so amending the twenty-second and twenty-third sections of article four of the Constitution of Virginia, as to provide that taxation shall be equal and uniform throughout the <PageNumber num={611} /> Commonwealth, and that all property shall be taxed in proportion to
                its value."
              </p>
              <p>The substitute was adopted.</p>
              <p><span className="persName">Mr. THOS. F. GOODE</span> called for the yeas and nays.</p>
              <p>The yeas and nays were ordered.</p>
              <div className="speaker" id="sp3250"><span className="persName">Mr. BOISSEAU</span>—</div>
              <p className="p-in-sp">I move to amend the resolution by adding to it these words : "which said committee shall report at an adjourned meeting of this Convention."</p>
              <div className="speaker" id="sp3251"><span className="persName">Several MEMBERS</span>—</div>
              <p className="p-in-sp">That is understood.</p>
              <div className="speaker" id="sp3252"><span className="persName">Mr. BOISSEAU</span>—</div>
              <p className="p-in-sp">But I want it expressed.</p>
              <p>The question was taken on <span className="persName">Mr. BOISSEAU</span>'S amendment, and it was not agreed to.</p>
              <p>The question was taken on the resolution as amended, and it was decided in the affirmative—yeas 63; nays 26—as follows:</p>
              <p>YEAS-Messrs. Janney [President]
                , Armstrong, Aston, Baldwin, A. M. Barbour, Baylor, Berlin, Boggess, Boyd, Brent, Brown, Burdett, Burley, Byrne, Campbell, Caperton, Carlile, Carter, Chapman, Clemens, Coffman, C. B. Conrad, Couch, Deskins, Dorman, Dulany, Early, Echols, French, Fugate, Gillespie, Goggin, C. Hall, E. B. Hall, L. S. Hall, Haymond, Hoge, Hubbard, Hull, Jackson, M. Johnson, P. C. Johnston, Lawson,
                Lewis, McComas, McGrew, Masters, Nelson, Osburn, Parks, Porter, Price, Pugh, Sharp, Sitlington, Spurlock, Staples, C. J. Stuart, Summers, Tarr, Willey, Wilson, Wysor-63.
              </p>
              <p>NAYS-Messrs. Ambler, Blakey, Blow, Jr., Boisseau, Bouldin, Conn, Forbes, Garland, Gravely, Gregory, Jr., T. F. Goode, A. Hall, Holladay, Isbell, Kilby, Leake, C. K. Mallory, J. B. Mallory, Moffett, Morris, Richardson, Southall, Strange, Thornton, Whitfield, Wickham-26.</p>
              <p>So the resolution as amended was adopted.</p>
              <p>During the vote,</p>
              <p><span className="persName">Mr. DENT</span> stated that he was paired with <span className="persName">Mr. JAS. BARBOUR</span>.</p>
              <p>It was stated that <span className="persName">Mr. JOHN GOODE</span>, JR., was paired with <span className="persName">Mr. HUGHES</span>, and <span className="persName">Mr. ORRICK</span> with <span className="persName">Mr. CRITCHER</span>, and <span className="persName">Mr. HOLCOMBE</span> with <span className="persName">Mr. MASLIN</span>.</p>
              <p><span className="persName">Mr. HALL</span> stated that <span className="persName">Mr. MCNEIL</span> was paired with a gentleman who would have voted in the negative.</p>
              <p>The vote was announced as above recorded.</p>
              <p>
                <span className="persName">Mr. WILSON</span> called for action tomorrow his resolution, offered on the 27th of March, and it was by general consent passed by.<span className="note" id="Note72"><span className="noteLabel">11</span>Presumably the text should read "on his resolution." Mr. Wilson's resolution was offered on February 27 (see Volume I, p. 248).</span>
              </p>
            </div>
            <div className="section" id="vsc1965.v3.2.9.6">
              <h3><span className="headingNumber">1.9.6. </span><span className="head">DEBATE IN CONVENTION</span></h3>
              <div className="speaker" id="sp3253"><span className="persName">Mr. PRICE</span>—</div>
              <p className="p-in-sp">I offer the following resolution :</p>
              <p className="p-in-sp">
                "Resolved, That the 33rd rule of the Convention shall be rescinded, and during the consideration of the report of the Committee on Federal <PageNumber num={612} /> Relations, and the amendments thereto, which may be made by the Committee of the Whole, when the same shall be reported back to the Convention, each member shall be limited to ten minutes."
              </p>
              <div className="speaker" id="sp3254"><span className="persName">Mr. AMBLER</span>—</div>
              <p className="p-in-sp">I rise to a question of order.</p>
              <div className="speaker" id="sp3255"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">No question will be taken till it is known that there is a quorum present.</p>
              <div className="speaker" id="sp3256"><span className="persName">Mr. PRICE</span>—</div>
              <p className="p-in-sp">
                I simply desire to say this : The 33d rule is that by which gentlemen may discuss the call for the previous question for ten minutes. That I desire to have repealed ; and I desire, when the report comes back from the Committee, that the discussion shall be limited as it is limited in Committee of the Whole. I do not desire to take advantage of a thin house, nor do I particularly
                desire that the resolution should be acted on now, if there be not a quorum present. I am therefore willing that the Convention shall now adjourn.
              </p>
              <div className="speaker" id="sp3257"><span className="persName">Mr. STAPLES</span>—</div>
              <p className="p-in-sp">I move to lay the resolution on the table.</p>
              <div className="speaker" id="sp3258"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">There can be no vote taken in the absence of a quorum, except to adjourn. The Chair is perfectly satisfied that there is nothing like a quorum present.</p>
              <div className="speaker" id="sp3259"><span className="persName">Mr. WILSON</span>—</div>
              <p className="p-in-sp">I move the Convention do now adjourn.</p>
              <p>The motion was agreed to, and the Convention, at half-past 7 P. M., adjourned.</p>
            </div>
          </div>
          <div className="day" id="vsc1965.v3.2.10">
            <PageNumber num={613} />
            <h2><span className="headingNumber">1.10. </span><span className="head">FIFTIETH DAY</span></h2>
            <div className="dateline">Friday, <span className="date">April 12</span></div>
            <p>The Convention met at 10 o'clock, A. M.</p>
            <p>Prayer by the Rev. Mr. Bosserman.</p>
            <div className="speaker" id="sp3260"><span className="persName">Mr. BLOW</span>, of Norfolk—</div>
            <p className="p-in-sp">Mr. President, I hold in my hand a document which I have lately received from my constituents of the city of Norfolk. It reads as follows:</p>
            <p className="p-in-sp">"To George Blow, Jr., Esq.:</p>
            <p className="p-in-sp">"SIR—We, the undersigned, citizens of Norfolk, desire to express the opinion that you would promote the interest and honor of Virginia by voting for immediate secession, and using all your efforts to that end."</p>
            <p className="p-in-sp">
              This petition contains 692 signatures. I have looked over the document and examined the names appended thereto, as far as possible, and from information in my possession, as from the paper itself, I feel warranted in concluding that it embraces the names of the entire secession party of the city of Norfolk. The total vote of that city, as expressed in the last Presidential election, is
              1655. So that it will be readily perceived that, whilst this petition is largely signed, yet it does not contain a majority of the qualified voters of the city which I represent. It is proper that I should say, sir, that in the election held for this Convention, there were 1362 votes cast, of which I received 922.
            </p>
            <p className="p-in-sp">
              Mr. President, I am a believer, and always have been a believer, in the doctrine of the right of instruction. I have ever operated with that party, that holds, in its largest sense, the right of the people to instruct their representatives on all questions touching their interests, and the duty of the representative, when that will is fairly and unmistakably expressed, to obey or
              resign his position—more especially, and for a stronger reason, on so important an occasion and emergency as that which now enlists the attention of the people of Virginia, do I recognize this principle? This paper, numerously signed as it is, and, so far as I can judge, by many of our most respected citizens, still leaves me in the unpleasant position of uncertainty as to the real and
              actual state of the popular will in the constituency which I represent. So far as I am concerned, with all the facts before me—with a knowledge that this paper has been most industriously circulated, and that every effort has been made for the purpose of swelling the signatures to the very largest amount, I am compelled to the conclusion that it represents the entire strength of that
              party in the city of Norfolk; and <PageNumber num={614} /> as I know that these instructions do not embrace the names of a majority of the qualified voters of the city, I must come to the conclusion that a majority of the people of Norfolk still desire me to pursue the course which I have felt it my duty to pursue since I have been a member of this
              Convention. From what has been already said, it may be readily inferred, and I do so announce, that whenever I shall be justified in coming to the conclusion that the people I represent, by a clearly ascertained majority, desire me, their representative, to vote for an ordinance of immediate secession, then will I feel it my duty to obey their behests or to resign the seat which I hold
              on this floor. On the other hand, sir, by way of showing that there are strong reasons for me to doubt the real state of the popular will, I shall present another document containing a vote of instruction coming from a large Union meeting held in that city on the 30th ult. It reads as follows :
            </p>
            <p className="p-in-sp">NORFOLK, April 2d, 1861.</p>
            <p className="p-in-sp">Gen. GEO. BLOW :</p>
            <p className="p-in-sp">Dear SIR-At a large meeting of the Union party of this city, on the 30th ultimo, we were appointed to a committee to address you a letter in reference to certain instructions or petitions that have been or will be sent to you from this place, requesting you to vote for immediate secession.</p>
            <p className="p-in-sp">You will see by the published proceedings of that meeting that you are requested by the Union men, to be governed by the will of your constituents as expressed at the polls when you were elected.</p>
            <p className="p-in-sp">We should see no reason why you should pay any attention to petitions that have been gotten up by your opponents to do what those who voted for you do not desire you to do.</p>
            <p className="p-in-sp">We assure you that there has been no change in the opinions of the people of this city ( so far as we can judge) , and we are, as we were when you were elected, Union men.</p>
            <p className="p-in-sp">Do not regard, we entreat you, any of these plots, but be governed by your own judgment and do what you may think best for the interest of us all.</p>
            <p className="p-in-sp">We are, very truly,</p>
            <p className="p-in-sp">Your friends,</p>
            <p className="p-in-sp">L. W. WEBB,</p>
            <p className="p-in-sp">F. F. FERGUSON,</p>
            <p className="p-in-sp">JAMES Y. LEIGH,</p>
            <p className="p-in-sp">Committee.</p>
            <p className="p-in-sp">General GEO. BLOW, Richmond.</p>
            <PageNumber num={615} />
            <p className="p-in-sp">
              It will be perceived, Mr. President, from the proceedings of the meeting just referred to, that an official assurance has been conveyed to me that there has been no material change in the opinions of the people. Such has been the tenor of my private correspondence. I may be mistaken in this conclusion. I know that the public pulse is in a feverish condition, but until better advised,
              and for the reasons to which I have already referred, I shall feel myself compelled to act in conformity with the principles which the majority held, at the time of my election. I will say, in conclusion, that whenever I am satisfied that a change has taken place in the opinions of the people I represent, and that a majority of the qualified voters of the city of Norfolk fairly
              expressed in favor of immediate secession, I shall feel it my duty to obey their behests or resign my seat in this body.
            </p>
            <p className="p-in-sp">I move that these documents be referred to the Committee on Federal Relations.</p>
            <p>The motion was agreed.</p>
            <div className="speaker" id="sp3261"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">The pending question is upon the resolution offered last evening by the gentleman from Greenbrier [<span className="persName">Mr. PRICE</span>] .</p>
            <p className="p-in-sp">The resolution reads as follows :</p>
            <p className="p-in-sp">Resolved, That the 33d rule of the Convention shall be rescinded, and, during the consideration of the report of the Committee on Federal Relations and the amendments thereto, which may be made by the Committee of the Whole, when the same shall be reported back to the Convention—each speaker be limited to ten minutes.</p>
            <div className="speaker" id="sp3262"><span className="persName">Mr. PRICE</span>—</div>
            <p className="p-in-sp">I will offer the following as a substitute for the resolution which I had offered last night :</p>
            <p className="p-in-sp">"Resolved, That the 33rd rule of the Convention be and the same is hereby rescinded ; and while considering in Convention the report of the Committee on Federal Relations, or any amendments thereto, no member shall be at liberty to speak more than ten minutes on any one subject."</p>
            <div className="speaker" id="sp3263"><span className="persName">Mr. MORRIS</span>—</div>
            <p className="p-in-sp">I rise to a question of order. I want to know whether we can consider two questions at one time. I understood the gentleman from Harrison [<span className="persName">Mr. WILSON</span>] , to have moved, last evening, to take up the resolution which he offered at an early part of the session, and that that motion was agreed to. While that gentleman had the floor upon his motion, he gave way to the gentleman from Greenbrier [<span className="persName">Mr. PRICE</span>] to make a motion ; but the Chair decided distinctly that the only business in order was the resolution offered by the gentleman from Harrison [<span className="persName">Mr. WILSON</span>] , and until that was disposed of no other business was in order.</p>
            <PageNumber num={616} />
            <div className="speaker" id="sp3264"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">The gentleman from Harrison moved to take up a resolution that has been lying upon the table for sometime. His motion prevailed. At that point the gentleman from Greenbrier [<span className="persName">Mr. PRICE</span>]
              , rose and proposed his resolution. The Chair decided that his motion was not in order, because there was a resolution pending; but stated that by universal consent the resolution of the gentleman from Harrison might be passed by, and the consideration of the resolution of the gentleman from Greenbrier proceeded with. That universal consent was given, and then the gentleman from
              Greenbrier offered his resolution, which, in the opinion of the Chair, is now the question before the Convention.
            </p>
            <div className="speaker" id="sp3265"><span className="persName">Mr. WISE</span>—</div>
            <p className="p-in-sp">I inquire whether there was a quorum in the House at that time.</p>
            <div className="speaker" id="sp3266"><span className="persName">Mr. MORRIS</span>—</div>
            <p className="p-in-sp">I was going to call the attention of the President to that fact. The Chair announced distinctly that he would put no question to the Convention, except a motion to adjourn, unless it was ascertained that there was a quorum present.</p>
            <div className="speaker" id="sp3267"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">The recollection of the Chair is very distinct, and he would appeal to the gentleman from Louisa [<span className="persName">Mr. AMBLER</span>] , as to whether he is not correct in his remembrance of the fact—that no question was raised whether a quorum was present or not, until the resolution of the gentleman from Greenbrier [<span className="persName">Mr. PRICE</span>] , was before the House.</p>
            <div className="speaker" id="sp3268"><span className="persName">Mr. AMBLER</span>—</div>
            <p className="p-in-sp">I certainly called the attention of the Chair to the fact that there was not a quorum present.</p>
            <div className="speaker" id="sp3269"><span className="persName">Mr. MORRIS</span>—</div>
            <p className="p-in-sp">I think the Chair stated that no business was in order.</p>
            <div className="speaker" id="sp3270"><span className="persName">Mr. AMBLER</span>—</div>
            <p className="p-in-sp">I called the attention of the Chair to the fact that no quorum was present; and that I regarded as an objection to the resolution. It was so construed by the body, and I had supposed it was so construed by the Chair, because the announcement of the Chair was that no motion was in order.</p>
            <div className="speaker" id="sp3271"><span className="persName">Mr. MORTON</span>—</div>
            <p className="p-in-sp">
              I appeal to the magnanimity of the gentleman from Greenbrier, to withdraw the amendment which he has offered. It is palpable from the testimony here, that there was not a quorum present last evening, when the resolution was offered, and the Chair, in all probability, acted under a misconception ; and in order to relieve the Chair, I would appeal to the magnanimity of the gentleman to
              withdraw his amendment.
            </p>
            <div className="speaker" id="sp3272"><span className="persName">Mr. PRICE</span>—</div>
            <p className="p-in-sp">I don't think it was very material whether there was a quorum present last evening or not. There is a quorum now, and</p>
            <PageNumber num={617} />
            <p className="p-in-sp">
              I submit to the gentleman whether there is occasion for an appeal to my magnanimity. You, sir, and the members who were present last night will bear me witness that I stated that I did not desire to press the question upon a thin house. I stated that I would take that opportunity to offer, but that in view of the thinness of the house, I would be willing to forego action upon it until
              this morning, so that my magnanimity is not involved in this matter at all. I think it is necessary that we should adopt some rule to expedite business, and this can best be done by the course that I propose.
            </p>
            <div className="speaker" id="sp3273"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">If the gentleman from Greenbrier declines to withdraw his resolution this debate is not in order.</p>
            <div className="speaker" id="sp3274"><span className="persName">Mr. MORTON</span>—</div>
            <p className="p-in-sp">I will make a suggestion—that is in order—which seems to have escaped the mind of the gentleman, and which would probably influence him in favor of the withdrawal of his amendment.</p>
            <p className="p-in-sp">
              It does not follow that because there is a full House now that the mistake of the Chair does not interpose the difficulty which is referred to here. My appeal to the magnanimity of the gentleman from Greenbrier was made upon the ground of the Chair's misconception of the matter in issue. The same ground of appeal still holds good notwithstanding that there is a full house now.
            </p>
            <div className="speaker" id="sp3275"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">The Chair appeals to the Convention to give him an opportunity to explain.</p>
            <p className="p-in-sp">There has been, no mistaken action on the part of the Chair—none at all. There was no question raised as to the presence of a quorum or no quorum, until the gentleman from Greenbrier [<span className="persName">Mr. PRICE</span>] , had submitted his resolution. The question was presented whether the resolution of the gentleman from Harrison [<span className="persName">Mr. WILSON</span>] , should be passed by. The Chair remarked that the resolution of the gentleman from Greenbrier could only be offered by universal consent. That consent was given, and it was not until after the resolution of the gentleman from Greenbrier was propounded, that the gentleman from Louisa [<span className="persName">Mr. AMBLER</span>] , rose and remarked that there was no quorum present; and the Chair stated that he concurred in that opinion, and refused to put the question upon the resolution.</p>
            <div className="speaker" id="sp3276"><span className="persName">Mr. WISE</span>—</div>
            <p className="p-in-sp">How could the resolution be received if there was no quorum?</p>
            <div className="speaker" id="sp3277"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">It was received before it was ascertained that no quorum was present.</p>
            <div className="speaker" id="sp3278"><span className="persName">Mr. MORRIS</span>—</div>
            <p className="p-in-sp">The gentleman from Harrison moved to take up the resolutions which he offered some time ago.</p>
            <PageNumber num={618} />
            <div className="speaker" id="sp3279"><span className="persName">Mr. PRICE</span>—</div>
            <p className="p-in-sp">I rise to a point of order. I maintain that it is not in order to debate this question.</p>
            <div className="speaker" id="sp3280"><span className="persName">Mr. MORRIS</span>—</div>
            <p className="p-in-sp">The gentleman from Harrison [<span className="persName">Mr. WILSON</span>] , made a motion to take up his resolution last evening. That motion was agreed to, and the gentleman gave way.</p>
            <div className="speaker" id="sp3281"><span className="persName">Mr. PRICE</span>—</div>
            <p className="p-in-sp">If the gentleman continues his remarks, I must insist upon my point of order.</p>
            <div className="speaker" id="sp3282"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">This difficulty has arisen entirely as to a question of fact, and the Chair is satisfied of the correctness of his view of the matter.</p>
            <div className="speaker" id="sp3283"><span className="persName">Mr. MORRIS</span>—</div>
            <p className="p-in-sp">
              The Chair himself announced, when the gentleman from Greenbrier made his motion, that it was not in order to take up any other business until a motion to pass by was made and carried. It was then ascertained that there was no quorum present. I understood the Chair to decide distinctly that a motion was not in order, and if his decision was otherwise, I should have objected to any
              motion being made.
            </p>
            <div className="speaker" id="sp3284"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">The Chair is of opinion that there was no question raised on the subject of a quorum not being present, until the resolution of the gentleman from Harrison [<span className="persName">Mr. WILSON</span>] was passed by universal consent.</p>
            <div className="speaker" id="sp3285"><span className="persName">Mr. CONRAD</span>—</div>
            <p className="p-in-sp">I was not in the house when the resolution was offered, but I understand that the last vote taken in the Convention, before the resolution was offered, showed that there was a quorum present; and I believe that is the only test that could be appealed to now.</p>
            <p className="p-in-sp">Assuming, however, that this resolution is now before the House, I rise for the purpose of suggesting that the repeal of the 33d rule, according to the terms of that resolution, would, as I understand, repeal the only rule upon the subject of the previous question. That being my impression, sir, I shall vote against it.</p>
            <div className="speaker" id="sp3286"><span className="persName">Mr. WISE</span>—</div>
            <p className="p-in-sp">Is this resolution before the House?</p>
            <div className="speaker" id="sp3287"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">Yes, sir, it is. The decision of the Chair is that it is the business in order.</p>
            <div className="speaker" id="sp3288"><span className="persName">Mr. WISE</span>—</div>
            <p className="p-in-sp">I appeal from the decision of the Chair, on the ground of the admission made by the Chair himself. If I understood him aright, he stated the fact that he did not believe, and so remarked at the time, that a quorum was not present.</p>
            <div className="speaker" id="sp3289"><span className="persName">Mr. CONRAD</span>—</div>
            <p className="p-in-sp">Can an appeal be taken while I am on the floor?</p>
            <div className="speaker" id="sp3290"><span className="persName">Mr. WISE</span>—</div>
            <p className="p-in-sp">This is a point of order. I desire to know from the <PageNumber num={619} /> Chair, whether I understand him aright or not. If I do, then I shall make the appeal.</p>
            <div className="speaker" id="sp3291"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">I will re-state the facts now according to my recollection of them. According to my recollection—and it is very distinct—the gentleman from Harrison [<span className="persName">Mr. WILSON</span>]
              , rose and moved to take up a resolution which had been laid on the table some time ago—and the motion prevailed. It was taken up for consideration, and at that point the gentleman from Greenbrier rose and stated that he desired to offer a resolution, but the Chair stated that it was not in order, as there was a subject then pending for consideration. It was then suggested that, by
              universal consent, the resolution of the gentleman from Harrison
              [<span className="persName">Mr. WILSON</span>] , could be passed by, for the purpose of receiving the resolution of the gentleman from Greenbrier [<span className="persName">Mr. PRICE</span>] . The gentleman from Greenbrier read his resolution, sent it to the table, and then the gentleman from Louisa [<span className="persName">Mr. AMBLER</span>] , and other gentlemen, suggested that there was no quorum present. The Chair stated that there would be no vote upon his proposition until there was a quorum present; and then the motion was made to adjourn, and the adjournment took place. The resolution of the gentleman from Greenbrier being in possession of the Convention, that adjournment took place.</p>
            <div className="speaker" id="sp3292"><span className="persName">Mr. WISE</span>—</div>
            <p className="p-in-sp">The Chair has not answered my inquiry.</p>
            <div className="speaker" id="sp3293"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">The Chair endeavored to do it.</p>
            <div className="speaker" id="sp3294"><span className="persName">Mr. WISE</span>—</div>
            <p className="p-in-sp">When the Chair first spoke he stated that his own impression was that there was no quorum present.</p>
            <div className="speaker" id="sp3295"><span className="persName">Mr. PRESIDENT</span>—</div>
            <p className="p-in-sp">Undoubtedly. When objection was made to the introduction of the resolution, I stated at once that there was no quorum present, and that no vote would be taken.</p>
            <div className="speaker" id="sp3296"><span className="persName">Mr. WISE</span>—</div>
            <p className="p-in-sp">
              The point I make is this : The Chair admits that he was apprised—and he admitted from the Chair—that no quorum was present. I ask the Chair by what authority it was that the order of proceeding was set aside. A House without a quorum can no more set aside the order of proceeding by consent, than it can by consent proceed even with the business in hand. It cannot even proceed with the
              business in hand when there is no quorum. Will the Chair tell me how it could be received. To receive it was to set aside the business in hand. That was action—a quorum can take no action—it was most important action. I was taken sick, and for one I had from illness to retire from this hall. I was not here to make the objection. Nobody else made it, and it needed no objection. You,
              yourself, admit that you <PageNumber num={620} /> were apprised that no quorum was present. Then, if there was no quorum present, no quorum could receive this resolution.
            </p>
            <div className="speaker" id="sp3297"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">Will the gentleman from Princess Anne permit me to correct him? The Chair has never made the admission that, at the time the gentleman from Greenbrier offered his resolution, it was known that there was no quorum.</p>
            <div className="speaker" id="sp3298"><span className="persName">Mr. WISE</span>—</div>
            <p className="p-in-sp">The Chair admitted that he thought so. When the question was raised, was it not the duty of the Chair to ascertain whether there was a quorum?</p>
            <div className="speaker" id="sp3299"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">Not unless his attention was called to it.</p>
            <div className="speaker" id="sp3300"><span className="persName">Mr. WISE</span>—</div>
            <p className="p-in-sp">
              I understand that the attention of the Chair was called to it. It was impossible to call his attention to it until after the resolution was offered. But I did understand the Chair to admit that he himself thought there was no quorum. Am I incorrect in that? Although the Chair does not admit there was no quorum, did he not twice admit that the Chair thought there was no quorum?
            </p>
            <div className="speaker" id="sp3301"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">When the objection was raised, and the Chair looked around the hall, the Chair expressed the opinion that there was no quorum.</p>
            <div className="speaker" id="sp3302"><span className="persName">Mr. WISE</span>—</div>
            <p className="p-in-sp">How long after the motion was it that the objection was made?</p>
            <div className="speaker" id="sp3303"><span className="persName">Mr. MORTON</span>—</div>
            <p className="p-in-sp">I wish to inquire if the hour has not passed for going into Committee of the Whole?</p>
            <div className="speaker" id="sp3304"><span className="persName">Mr. HOLLADAY</span>—</div>
            <p className="p-in-sp">I rise to a point of order. I desire to know what is before the Convention?</p>
            <div className="speaker" id="sp3305"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">An appeal from the decision of the Chair.</p>
            <div className="speaker" id="sp3306"><span className="persName">Mr. HOLLADAY</span>—</div>
            <p className="p-in-sp">I desire to know if that point must not be specifically made, and if it is in order to catechise the Chair in the way that the gentleman from Princess Anne [<span className="persName">Mr. WISE</span>] , has done?</p>
            <div className="speaker" id="sp3307"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">The Chair makes no objection to the catechism. It is a matter of fact.</p>
            <div className="speaker" id="sp3308"><span className="persName">Mr. HOLLADAY</span>—</div>
            <p className="p-in-sp">I raise the point of order upon the gentleman from Princess Anne.</p>
            <div className="speaker" id="sp3309"><span className="persName">Mr. WISE</span>—</div>
            <p className="p-in-sp">
              I am getting at the facts from the Chair. With all respect to the Chair, I am acting upon his own statement, and trying to get at that statement, in order to make my point of order. That, I think, is fair, and it is certainly respectful to the Chair to take his own statement. I was asking what point of time there was between the motion made by the gentleman from Greenbrier and the
              objection that there was no quorum? Was it but a moment's time, or had a considerable time elapsed? for much would depend upon that. If <PageNumber num={621} /> the attention of the Chair was called to the fact soon, very soon, after the motion was made, and if the Chair thought, in the very short time after the motion was made, whilst it was in fact
              pending, that there was no quorum, I insist, if that was the impression of the Chair, that this body, when there was no quorum, could not proceed to set aside the regular business, and take up a resolution which is highly objectionable to many members. When the Convention were fatigued ; when the Convention was without a quorum; when it was late, after a long day's session, the few
              gentlemen who were here could not be expected to be very attentive to the proceedings. I, myself, from sickness, was obliged to be absent, and I should have made the objection, with dead certainty.
            </p>
            <div className="speaker" id="sp3310"><span className="persName">Mr. BALDWIN</span>—</div>
            <p className="p-in-sp">Will the gentleman allow me to make a statement? I was an attentive observer of the proceedings.</p>
            <div className="speaker" id="sp3311"><span className="persName">Mr. WISE</span>—</div>
            <p className="p-in-sp">I am making an appeal to the Chair.</p>
            <div className="speaker" id="sp3312"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">The Chair will answer the gentleman's appeal.</p>
            <div className="speaker" id="sp3313"><span className="persName">Mr. WISE</span>—</div>
            <p className="p-in-sp">I do not pretend to say that there was or was not a quorum. I do not understand the Chair as admitting that there was not a quorum ; but I understand the Chair as admitting at the time the gentleman from Louisa [<span className="persName">Mr. AMBLER</span>] , called his attention to the fact that he thought there was no quorum; and if that be the fact, I submit that no quorum could set aside the business of the Convention. If the Chair thought there was no quorum, it was the duty of the Chair to have seen that there was a quorum before it could proceed to business.</p>
            <div className="speaker" id="sp3314"><span className="persName">Mr. MORTON</span>—</div>
            <p className="p-in-sp">I call the attention of the Chair to the fact that the hour for going into Committee of the whole has passed.</p>
            <div className="speaker" id="sp3315"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">The hour for going into the Committee of the Whole has arrived.</p>
            <div className="speaker" id="sp3316"><span className="persName">Mr. PATRICK</span>—</div>
            <p className="p-in-sp">I move to suspend the rules with the view of passing by the order.</p>
            <div className="speaker" id="sp3317"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">The Chair must adhere to the rules, but the Chair will ask the farther indulgence of the Convention upon this question.</p>
            <p>The Convention then resolved itself into Committee of the Whole [<span className="persName">Mr. SOUTHALL</span> in the Chair] , and resumed the consideration of the report of the Committee on Federal Relations.</p>
            <p><span className="persName">The CHAIRMAN</span> stated that the pending question was on <span className="persName">Mr. SCOTT</span>'S amendment [as amended] to the 14th resolution, viz:</p>
            <p>
              "And in the event that satisfactory responses on the part of the nonslaveholding States be not made to the proposed amendments to the Constitution by the time appointed for the re-assembling of this body, <PageNumber num={622} /> it is the opinion of this Convention that the said States of Delaware, Maryland, North Carolina, Tennessee, Kentucky, Missouri
              and Arkansas, ought to assemble in primary Conventions, and in conjunction with this State, convene a Congress of the said States, composed of delegates to be appointed by the respective Conventions thereof, for the purpose of recommending an amended Constitution of Government upon which the Union of the said States and the Confederated States, with such of the non-slaveholding States
              as may concur therein, can be safely effected; to which Congress, the Confederated States, and the non-slaveholding States concurring in the amendments to the Federal Constitution, proposed by this Convention, ought to be invited to send Commissioners."
            </p>
            <div className="speaker" id="sp3318"><span className="persName">Mr. WISE</span>—</div>
            <p className="p-in-sp">
              I move to amend the amendment by striking out the words "to be appointed by the respective Conventions thereof," and to insert—"to be elected for this State by the people thereof, voting by Congressional districts for thirteen delegates, one for each district, and by the State at large, for two delegates, one to be selected from the East and one from the West of the Blue Ridge."
            </p>
            <p className="p-in-sp">
              Yesterday I offered a grave constitutional question to this body, which was derided by some and I fear very little understood by many. Certain it was that the proposition only received 30 votes, and I do not know that they were given by those fully appreciating the value of that constitutional point, whether in the Union we could proceed to divide the Union—whether in the Union we
              could make treaties, alliances or confederations. Sir, I wish to submit that question to the people to decide, and all other questions that are involved in this proposition of a Border State Conference. I want the people to decide in answer to the question that was asked yesterday by the gentleman from Kanawha
              [<span className="persName">Mr. SUMMERS</span>]
              , where are you going? Are you going to follow the North Star or the Southern Cross? I said the other day that I wanted to see the effect of the load stone. The gentleman yesterday showed that the load stone had touched his point. My friend from Jefferson, who was superintendent at Harper's Ferry at the time of the John Brown raid, could tell you how gallantly a certain felon that was
              hung drove two officers, the venerable Kit Miller and our Armorer, Mr. Ball, at the point or at the muzzle of Sharp's rifle into the gates of his iron prison, telling them as he went, in the language of the New York b'hoys, hey g' lang, as if he had bit and check reins upon a pair of horses, and with whip in hand was afterwards telling them to "go lang!" Now, the question is, whether
              Stevens is to mount the box and tell us to g'lang—not whether we are to drag, but whether <PageNumber num={623} /> we are to be driven into this Border State Conference. After saying that he would not go either with the North Star or the Southern Cross, with a great deal of magnific heroism he told us he intended to stand exactly where we were. I ask
              where are we? Sir, we are already in the iron prison. We are already caught by the dead fall of this crisis in the Northern Confederacy and the gentleman tells us that so far as he is concerned, he means not to follow the North Star or the Southern Cross, but he means to stand where we are—stay where we are—act for ourselves. Magnific, grand, independent to declare that we will act for
              ourselves and yet he will vote deliberately to subject our action to a Border State Conference—subject our action to some seven States, besides Virginia—to declare with the gentleman from Fauquier, that unless they will concur in our action we will stay where we are. Will he stay where he is? Why, will he, after declaring that he will act independently and alone, subject us to this
              Border State Conference? He tells us that the Western market will be ruined. He tells us that as if the Western market were peculiar in Virginia—as if the East was not situated exactly like the West. What is there peculiar in the Western market that makes it differ from the Eastern market? What is there in the Kanawha market differing from that in Fauquier or that in Princess Anne? He
              tells us that the most of his trade is with the non-slaveholding States. Sir, most of our trade, except in negroes that are sold South, is with the North.
            </p>
            <p className="p-in-sp">
              The county of Princess Anne has to go to the North, to New York, to Philadelphia, to Baltimore, for a market. The amount of the products of that county—although it sounds like huckstering in peas, potatoes and tomatoes, the garden stuffs of that county will command almost as much value as any county in the land, will command as much as the county of Kanawha will in coal. We have to go
              to the North for a market, too. Let me tell the gentleman, that where he trades ten dollars with Louisiana, we trade one with the South in everything except negroes. What then does he mean by any peculiar Western interest? I asked a gentleman who was sitting by my side yesterday, from the Pan-handle, what it was that he sold in Pittsburg, for example? "You cannot carry coals to
              Newcastle, you cannot carry coals to Pittsburg; you cannot carry coals again to Newcastle, you cannot carry iron-ware there to be sold—what then has the Panhandle to bind it to Pittsburg, or to any other part of Pennsylvania?" In answer to my question, I was told that it was fire bricks—a peculiar kind of clay which is very good to be burnt, very good to stand the fire—that was sent to
              Pittsburg.
            </p>
            <PageNumber num={624} />
            <p className="p-in-sp">How much iron-ware, how many pounds of nails does Wheeling and all that region send down to the Southern cities? Unless I am misinformed, you have as much trade with the Southern States as with the Western; or North-western, or Northern States. The main body of your supplies sent to market, are required at the South.</p>
            <p className="p-in-sp">
              The West then is not different from the East, nor is it as peculiar, in trading with the North, as the East is. Why, then, should the gentleman from Kanawha, be so deeply interested about Western interests, as if they were peculiarly belonging to the North? Sir, Cincinnati has had a regie over the salt of Kanawha as France has over tobacco imported into her ports. I would rather strip
              Ohio of the power over the Western trade of Virginia which I have witnessed, especially when the Ohio Life and Trust Fund Company failed and ran away with a large portion of the banking capital of Western Virginia during the time that I happened to be the Chief Executive of this Commonwealth. At that time I had occasion to investigate into some facts, which it would be well for the
              Legislature of Virginia to look to, connected with the trade of the West. I have found that salt and bank capital have been controlled very much by those North-western nonslaveholding States.
            </p>
            <p className="p-in-sp">
              But the gentleman from Kanawha asks us where does patriotism begin? That was a queer patriotism, a very extraordinary patriotism, sir, which the gentleman defined to me yesterday. And what was it? Selfishness, sir, when you scrutinize it closely, and nothing else. He said yesterday—not in terms, but in every idea which terms are to express—that patriotism after all is nothing but
              selfishness. Not according to the authority of Paley, that "expediency is the test of virtue," for that resolves itself into the highest and most exalted love of virtue for virtue's sake; but the gentleman from Kanawha came down to the definition of patriotism which don't belong to any other philosophy than that of Machiavelli, "whatever is your peculiar interest to do, that is best to
              do, and that it is duty to do."
            </p>
            <p>Here the speaker's time had expired.</p>
            <div className="speaker" id="sp3319"><span className="persName">Mr. SUMMERS</span>—</div>
            <p className="p-in-sp">
              The gentleman from Princess Anne seems to have taken occasion, upon the opportunity afforded by the offering of the amendment which he has submitted, to reply to some remarks which fell from me yesterday. He has offered an amendment, and has not addressed one word to the Committee in support of that amendment, as far as I understand his remarks, but his attention has been drawn off
              entirely from his subject to reply to observations of mine on yesterday.
            </p>
            <PageNumber num={625} />
            <p className="p-in-sp">
              Mr. Chairman, as to the gentleman's magnifics and heroics, I have no remarks to make. I leave them to the gentleman himself, who has more to claim to them. He says I enquired where Virginia was to go—North or South. The gentleman is laboring under a misapprehension. When I used those terms I was replying to questions which I understand he himself had put the day before. He himself had
              enquired where you were to go, whether you would abide under the Northern Star or the Southern Cross. In reply to that question I stated that, as far as I was concerned, and as far as those acting with me were concerned, we were not disposed to be misled or shaken in our purpose, by appliances or taunts of that character; but if the contingency should come upon us in which it would
              become necessary to contract new alliances and new Confederations, we were in favor of the eight slave States still remaining in the Union, acting together; and I announced that my purpose, all along, from the beginning, had been co-operation by Virginia with those Border States with whom she held peculiar interests.
            </p>
            <p className="p-in-sp">
              I was not aware, sir, that in anything which fell from me I had subjected myself to the criticism which has just been indulged in, as to the definitions of patriotism which the gentleman has given us. I remarked in substance, Mr. Chairman, that I had but little confidence in a patriotism which did not find its head spring in love of one's immediate country. What is patriotism but love
              of country? When I hear gentlemen speak of that expansive love of country, in reference to which they delight to indulge, I put it to them to determine, as I did yesterday, whether it was incompatible with true patriotism, whether it was not necessarily a part and parcel of true patriotism that that sentiment should at least, include the country of one's own immediate interest, of
              one's own immediate residence? Is patriotism a love of one's own country, or of somebody else's country? I asked gentlemen if it was not a part of patriotism that we should, in the consideration of these great questions, look to the interests of those whom we represented, and I asked it in reply to what had fallen from gentlemen, with whom the gentleman from Princess Anne is acting
              here. I did not intend to discriminate between the two sections of the State in the particular to which the gentleman alluded. On every side, East, West and North, we are connected with the commerce of those States to which I have referred, and with whom I desire to concert final action.
            </p>
            <p className="p-in-sp">
              The gentleman misapprehends me in another particular. I was maintaining that it was not the interest of any part of Virginia, <PageNumber num={626} /> especially was it not the interest of Western Virginia, to proceed by separate State action, to go out of the present Union, without carrying with her the States of Maryland, Kentucky, Tennessee, and the
              other States around us and bordering upon us. I endeavored to show that the large commercial interest that we had with all those States, not confining it to the part of Virginia from which I am sent, particularly, but including every portion of the Commonwealth, would lead us to seek the opinions of those border States, and to act in concert with them. And in this connection, I said,
              also, that if this separation from the present Union was at any day to be made, it was the interest of Virginia to carry with her as many of the border nonslaveholding States as you could make proper alliances and constitutions with. And is this opinion to subject us to the imputation of a want of patriotism, of a want of love of country? Sir, I understand that we exhibit our love of
              country most, when we look to the best interests of that country; and I deny in toto that the scheme of the gentleman from Princess Anne does embrace, and look to, and comprehend the best interests of the Commonwealth of Virginia. He would have Virginia assume an independent and separate position and as I understand him, frame a Constitution by herself alone, and present it for
              acceptance by the other States of the Union; acting for herself in the first instance, and then presenting her own terms to the other States. The difference between us is this : I desire consultation with the slaveholding States still remaining in the Union, in advance of any definite action on the part of Virginia, and then, if it can be done properly and safely, upon terms that will
              suit both sides, I have maintained the expediency of carrying with us those slave States, and also, the border free States of Pennsylvania, Ohio, Indiana and Illinois. If that be at war with patriotism, make the most of it. If that be selfishness make the most of it.
            </p>
            <p className="p-in-sp">But, sir, I am aware that I have no time to speak to the gentleman's amendment. I rose principally to explain my position in reference to the matter to which I have alluded. The gentleman from Princess Anne, however, proposes an amendment by which the delegates to the Congress, contemplated by the resolution of the gentleman from Fauquier [Mr. SCOTT]
              , are to be elected in Congressional Districts. There are many objections to this amendment, but which I have no time to express. In the first place, the provision for the mode of the selection of these delegates is premature. The necessity for their appointment at all, whether they be appointed directly or indirectly by the people, is to depend upon the contingency pointed out in the
              <PageNumber num={627} /> amendment and which may never occur—that is, the non-procurement of satisfactory responses from those States to whom you are to propound these amendments to the Constitution.
            </p>
            <p className="p-in-sp">
              But, sir, the chief objection to this amendment is this : that gentleman, with whom it was my pleasure to act in the Convention of 1850 and '51, in vindication of the great principles of representation in a free government, who aided so materially and so gallantly in founding the State representation upon the white population of the State, that gentleman, who is here, a member of this
              Convention, which itself is called upon the very basis that he advocated in 1850-'51—the basis of suffrage—now seeks to carry Virginia, not into her own domestic halls of legislation, but into a consultation beyond her own borders, upon the issues, not of a tax bill, but upon the issues of union or disunion, upon the issues of war or peace, on a restricted basis of representation,
              utterly subversive of the rights of the Southern people. He seeks to carry us there upon the basis, not of suffrage or of white population, but upon the three-fifths principle of representation in Congress. This would give to the counties West of the Blue Ridge, and containing a majority of nearly 200,000 of the white population of the State, six delegates, while it would secure the
              Eastern counties seven delegates on the basis of three-fifths of the slaves.
            </p>
            <div className="speaker" id="sp3320"><span className="persName">Mr. WISE</span>—</div>
            <p className="p-in-sp">
              I will carry my State, sir, to the Congress at Frankfort, precisely with the same representation that my State is carried to the Congress at Washington. Federal relations are one thing; State relations are another; and we have this new proclamation of the gentleman from Kanawha, that the three-fifths of the slaves are not to be represented in any Congress. I thank him, sir, for
              disclosing that motive and that sentiment. It shows exactly the animus that we are to meet from that gentleman on these questions. The representation of the three-fifths of the slaves is to be abolished. I thought that I would extract from the gentleman, cautious as he is, this declaration. You
              [<span className="persName">Mr. SUMMERS</span>]
              , have proclaimed it. You said the ten minutes rule would not allow you to give further explanations. I wish the rule would give you the time. I wish that you might have proclaimed it with the intensity of emphasis that belongs to the earnestness with which you have said it—with sufficient impressiveness to apprize me of what you will do. You do not intend, then, that the State when
              she is carried out of her borders, shall protect slave property? You do not intend that the three-fifths principle shall be acknowledged?
            </p>
            <p className="p-in-sp">
              But, sir, as to the gentleman's patriotism. He speaks as if a section, as if a neighborhood, were the whole country. Sir, a section, a <PageNumber num={628} /> neighborhood, is not the whole country. The gentleman when he was defining patriotism yesterday, said that he must take care of his own Western Virginia before he looked to the whole State; that
              the border would be ruined and the influence of the Ohio river crushed if he was made to separate from those glorious, happy, harmonious brothers, of the non-slaveholding States. Sir, I have often heard it said, that the amor patrice is founded upon amor loci, that the love of home begets the highest love of country, and of the whole country, because it is the heart of home that binds
              us, and to home the heart clings; and no poet sang more sweetly than he who said—
            </p>
            <div className="lg">
              <div className="l">"O, thou shalt find, how e'er thy footsteps roam,</div>
              <div className="l">That land thy country, and that spot thy home."</div>
            </div>
            <p className="p-in-sp">
              Thus it is, sir, that the amor patrice is founded on the amor loci. But not in the selfish sense advocated by the gentleman from Kanawha. I will give the gentleman an illustrious instance of the illustration which I wish to make now, in the case of a patriot who lived during the time of the Revolutionary War, when the amor loci came in conflict with the amor patrice. No home in
              Yorktown was so much in danger as the house of an ancestor of one of the members here. I mean the house of General Nelson. There it stood the house of a rebel, and was made by Cornwallis his headquarters.
            </p>
            <p className="p-in-sp">
              Do you remember the siege? The story runs that the gunner was, by Nelson himself, directed to fire on his own house. "Let it go." He had given more than half a million of money; he had given horses; he had given provisions; he had given munitions of war; and at last his very hearth, his house, was at the cannon's mouth. He ordered the gunner to fire upon it. The gunner, unwilling to
              destroy General Nelson's house, every time purposely missed it, until, leaping from his horse, Nelson went to the gun, and himself put a shot directly through his own house. Sir, I say to my friend from Kanawha, that patriotism requires you to put a shot through your own castle, if the defence and honor of your country requires it. Sir, now, at this day, there at Yorktown, is the print
              of the ball shot by poor Nelson. He died here, I believe, prison-bound. Once, ascending the steps of this Capitol, to present his accounts to the Government, he burst into tears, and tore them into tatters. And those accounts have never been settled or liquidated, to this day.
            </p>
            <p className="p-in-sp">
              Sir, I claim it as one of the brightest feathers in my cap, when I was Governor of this Commonwealth, that I caused his image to be placed upon yonder monument. Sir, I say to the gentleman that this <PageNumber num={629} /> is the patriotism I recommend to him. Let him, too, shoot the ball, if the interests of his country require it, that shall speed its
              way through his own castle.
            </p>
            <p className="p-in-sp">
              But, sir, my castle is to be shot through as well as yours. I trade with the North as well as you. I am to be injured as well as you. I do not ask that Virginia shall stand out by herself. I only ask that temporarily she shall step aside and tender to all the States her independent terms. Let them accept them and then let us reconstruct this Union by the consent of as many States as
              will consent.
            </p>
            <p className="p-in-sp">
              But, sir, I have no time to deal with these questions. The gentleman supposes they will suffer injury by being left on the border. I ask him whether, on our separation from Great Britain, the inhabitants on the border were injured by the separation? Go along the line of the Canada border, go to New York, go to New England, go to the North, and ask them in what locality in their section
              of the country the largest fortunes were made after that separation. Sir, it was on the lip of the Lakes. It was on the very border where the people traded one with another—the North side with the South side, that private fortunes were built up. The fortunes of the Lawrences, I mention as one instance of the effect of that border trade, after our separation from Great Britain.
            </p>
            <p className="p-in-sp">
              But, sir, I hasten on to the real object of this border conference. The gentleman has disclosed his purpose. What is it? It is to secure the trade and co-operation of the West outside the borders of Virginia. What will be the effect of waiting for co-operation with that West outside the border? Is the gentleman not aware that Kentucky has, to a great extent, become abolitionized? Has
              not Maryland become, to a great extent, abolitionized? Has not a portion of Tennessee become abolitionized? Are we to go into this Border Conference with all this Western interest against Eastern Virginia and North Carolina?
            </p>
            <p className="p-in-sp">
              Well, sir, the gentleman has let that "cat out of the bag." It is to combine these States with you in your action. And if they will not act in concert with you in respect to separation, what will you do? Stand where we are? I repeat it that we are now in a Northern Confederacy; and the gentleman proposes to subject Virginia to Western influence outside the border that will leave us
              where we are.
              [Here the hammer fell.] <span className="persName">Mr. GOODE</span>, of Bedford, called for the yeas and nays upon the amendment to the amendment.</p>
            <p>The yeas and nays were ordered.</p>
            <PageNumber num={630} />
            <div className="speaker" id="sp3321"><span className="persName">Mr. SUMMERS</span>—</div>
            <p className="p-in-sp">I ask whether an amendment is, at this time, in order to the amendment of the gentleman from Princess Anne?</p>
            <div className="speaker" id="sp3322"><span className="persName">The CHAIR</span>—</div>
            <p className="p-in-sp">It is not. That is an amendment to the amendment of the gentleman from Fauquier, and therefore an amendment in the second degree.</p>
            <p>The question was taken, and it was decided in the negative yeas</p>
            <p>53, nays 74, as follows :</p>
            <p>
              YEAS-Messrs. Ambler, James Barbour, Blakey, Boisseau, Borst, Bouldin, Boyd, Branch, Bruce, Cabell, Cecil, Chambliss, Coffman, Conn, Flournoy, Forbes, Garland, Graham, Gregory, Goggin, John Goode, Jr., T. F. Goode, Hale, Cyrus Hall, Harvie, Holcombe, Hunton, Isbell, Kent, Lawson, Macfarland, C. K. Mallory, J. B. Mallory, Marr, Marye, Miller, Morris, Morton, Richardson, Rives, Sheffey,
              Slaughter, Speed, Strange, Sutherlin, Tredway, R. H. Turner, Tyler, Waller, Williams, Wilson, Wise, Wysor-53.
            </p>
            <p>NAYS-Messrs. Janney [President]
              , Armstrong, Aston, Baldwin, A. M. Barbour, Baylor, Berlin, Blow, Boggess, Brent, Brown, Burdett, Burley, Byrne, Campbell, Caperton, Carlile, Carter, Chapman, Clemens, C. B. Conrad, R. Y. Conrad, Couch, Critcher, Custis, Dent, Deskins, Dorman, Dulany, Early, Echols, French, Fugate, Gillespie, Gravely, Gray, E. B. Hall, Hammond, Haymond, Holladay, Hubbard, Hughes, Hull, Jackson, P. C.
              Johnston, Kilby, Lewis, McComas, McGrew, Marshall, Maslin, Masters, Moffett, Moore, Osburn, Parks, Patrick, Pendleton, Porter, Price, Pugh, Ro. E. Scott, Sharp, Sitlington, Southall, Spurlock, C. J. Stuart, Summers, Tarr, Tayloe, White, Whitfield, Wickham, Willey-74.
            </p>
            <p>So the amendment to the amendment was disagreed to.</p>
            <p>During the call of the roll,</p>
            <p><span className="persName">Mr. STAPLES</span> stated that <span className="persName">Mr. HOGE</span> was confined to his room by sickness</p>
            <p><span className="persName">Mr. LEAKE</span> stated that he had paired off with the gentleman from Lancaster [Mr.HALL] .</p>
            <p><span className="persName">Mr. WISE</span> said <span className="persName">Mr. KINDRED</span> was absent from the hall on account of sickness.</p>
            <p>A MEMBER stated that <span className="persName">Mr. MCNEIL</span> was absent on account of sickness.</p>
            <p><span className="persName">Mr. STAPLES</span> said he had paired off with <span className="persName">Mr. NEBLETT</span>.</p>
            <p><span className="persName">Mr. THORNTON</span> announced that he had paired off with <span className="persName">Mr. JOHNSON</span>, of Richmond.</p>
            <p>When the vote was announced,</p>
            <p><span className="persName">Mr. WILSON</span> said his name was improperly recorded in the affirmative. If he so voted it was through mistake.</p>
            <div className="speaker" id="sp3323"><span className="persName">Mr. MORTON</span>—</div>
            <p className="p-in-sp">
              I rise to propose an amendment, and I do it with the statement that I intend it as the foundation of some views I wish to present to the Convention, and with no intention of occasioning <PageNumber num={631} /> delay; for if I did not consider the views I intended to present, were founded on reason, I assure this Committee I would not trespass upon its
              patience. I propose to amend the amendment of the gentleman from Fauquier, by striking out the word "responses," and inserting the word "answers."
            </p>
            <p className="p-in-sp">Mr. Chairman, the great struggle, at this time, is as to the question of a Border State Conference—whether Virginia should act as an independent sovereignty, or whether Virginia shall manifest timidity in her action, and, regardless of what her interest and honor may be, she will not act until she has the co-operation of the Border States.</p>
            <p className="p-in-sp">Now, Mr. Chairman, permit me to say, upon this question, the Union party, I presume, is that party which goes for adherence to the Constitution of the United States; that they claim to be the law-abiding party ; that they wish no action, so long as they are members of this Union, in conflict with the Constitution of the United States.</p>
            <p className="p-in-sp">
              Well, what do they propose? They propose that the members to compose the Conference at Frankfort shall consist of the delegates from Virginia and the Seven Border States. What is the object of it? Is it not to make a contract, an agreement or a treaty among themselves? Are we to assemble in the city of Frankfort, and after consultation, after deliberation, to return, with no binding,
              obligatory action upon us; as free when we return as when we went? If that be the fact, sir, I call the attention of the House respectfully to the first clause, of the tenth section, of the first article of the Constitution, in these words :
            </p>
            <p className="p-in-sp">"No State shall enter into any treaty of confederation or alliance," &amp;c.</p>
            <p className="p-in-sp">The power of treaty or alliance is thus prohibited by the Constitution of the United States, to any State. And permit me to state, Mr. Chairman, that in the contest at the South between the immediate Secessionists and the Co-operationists, that was a question which was discussed, and it had its influence in the decision which was made.</p>
            <p className="p-in-sp">
              The secessionists contended that as long as they remained members of the Union they were inhibited by the Constitution from any violation of that instrument. They, therefore, insisted that secession should be immediate and direct, and let co-operation follow afterwards. That was the struggle, and yet the gentleman from Fauquier and the gentleman from Kanawha shrink with a holy horror
              from the separate, independent action of Virginia, until they first seek to obtain the co-operation of the border slave States.
            </p>
            <p className="p-in-sp">The gentleman from Fauquier [<span className="persName">Mr. R. E. SCOTT</span>]
              , declared in the discussion on the appointment of Commissioners to Washington, that <PageNumber num={632} /> if they did not receive satisfactory replies, he would be for instant, immediate secession; and yet he declared yesterday, that under no circumstances would he go for immediate secession, that he was in favor, irrevocably, of border State
              co-operation. I presented, in remarks on a previous occasion, the experience of the Cotton States against the theory of the gentlemen from Fauquier and Kanawha, who say "let us have co-operation before secession." What will be the effect and result of co-operation? On what terms will Virginia be brought into consultation with the border States? As has been stated, again and again on
              this floor, she will only stand as an equal with Delaware. She has more slaves than four of the border States, and only a few thousands less than five border States. And yet we are to come into consultation with the border States on an equality of votes.
            </p>
            <div className="speaker" id="sp3324"><span className="persName">The CHAIRMAN</span>—</div>
            <p className="p-in-sp">The gentleman's time is exhausted.</p>
            <div className="speaker" id="sp3325"><span className="persName">Mr. LEAKE</span>—</div>
            <p className="p-in-sp">
              I am opposed to the amendment. I have understood, Mr. Chairman, that in the resolutions already adopted by this Committee the ground has been taken that if any effort shall be made by the Federal authorities to subjugate the seceded States, Virginia could not look quietly but would resist any such effort on the part of Lincoln's Administration. I have understood that by the 8th
              resolution the Committee intended to commit this Convention to the great American principle that the States of this Union have a right, for good cause, to throw off the Federal authority, and to establish a new government for themselves. And yet, sir, we have heard, since the adoption of the resolution, the monstrous doctrine asserted that wherever the flag of this Union goes,
              gentlemen are determined, so long as Virginia is a member of this Confederacy, to fight under that flag. It has been announced that if Lincoln's Administration carries the flag of this Union into the South for the purpose of subjugating the South, and if Virginia still remains in the Union, they will follow that flag and fight under it in subjugating the South.
            </p>
            <p className="p-in-sp">
              Sir, I am committed to the doctrine of maintaining the flag of the country, so long as that flag waves over those who are fighting for their country's cause; but I never will consent to follow that flag when carried by minions of Abraham Lincoln in a contest against the Southern States who have seceded from this Union. I will not acknowledge that there is any obligation resting on me
              as a Virginian, to aid those who are opposed to the institutions of my country, and are engaged in destroying those institutions. If this 8th resolution enunciates the true feeling of this Committee, we are bound to resist all efforts on the part of the Federal authorities to subjugate the <PageNumber num={633} /> Southern States. If gentlemen mean to
              stand by this 8th resolution, and by the great doctrine which it enunciates, we should not allow that principle to be violated and outraged by the Administration at Washington, either by attempting to re-capture the forts or to collect the revenue, and thus coercing the Southern States back in the Union. If we mean to stand by that principle then we are committed to the doctrine of
              resisting all efforts that may be made by this Administration to coerce the seceded States.
            </p>
            <p className="p-in-sp">
              Mr. Chairman, I had supposed that the great object of this Convention was to vindicate this great American principle ; and yet gentlemen say that they are to be controlled in their action in regard to the position which Virginia is to occupy, by the interest which they may have in fat cattle or coal oil. They mean to go to the North or to the North-West, and do not mean to be governed
              at all by considerations of the great American principle involved in the controversy.
            </p>
            <p className="p-in-sp">It seems now that the gentlemen who aided in adopting these resolutions intend them to be mere idle declarations. They do not even require that the Northern States shall make any response to these demands, and will not even themselves be governed by the declarations which this Committee has made.</p>
            <div className="speaker" id="sp3326"><span className="persName">Mr. MORTON</span>—</div>
            <p className="p-in-sp">
              I adopt the views of the gentleman from Goochland in support of my amendment, and also on general principles. I only regret that, at the point at which my time expired I could not, under the steam which was then up, have proceeded to the end of my subject, for as you very well know, it requires, at least with diffident gentlemen, a few minutes to get in that strain which enables them
              to do justice to the subject. I would be glad to have the attention of the distinguished gentleman from Fauquier, and that he would, in progress of this discussion, meet the question which has not yet been met.
            </p>
            <p className="p-in-sp">
              His is, I say, a theory. The border conference is a theory. The course which I recommend is based on experience. This very question was the great absorbing question in the cotton States at the time they were discussing co-operation and immediate secession. And what have been the effects of immediate secession and co-operation afterwards? To the astonishment of all Union men, from
              Mexico to Canada, their predictions have been falsified by experience. History presents no parallel to the revolution begun under the principles of immediate secession and co-operation afterwards. The Confederate States have organized a government which excites the admiration of its friends and commands and extorts the respect of its enemies the world over. It is a
              <PageNumber num={634} /> confederacy and government so strong and powerful that Abraham Lincoln, at the head of this boastful but crumbling government, is not content to rest on his pillow without a Praetorian guard around the capital and the palace of the President. Why is this? It is because the Southern Confederacy is a strong, powerful, stable
              government. Distinguished men at the North, when questioned whether the Southern Confederacy is a permanent government, say it is. An ex-Governor of New Jersey has given it as his deliberate opinion, as you will see by the morning papers, that it is the interest of New Jersey to unite herself with the Southern Confederacy, that in the Northern Confederacy she would languish, but in the
              Southern Confederacy she would be more prosperous than ever. The cotton States acting on the principle of immediate secession, and co-operation afterwards, have a permanent, solid government, enjoying a credit in the markets of the world higher than that of the old Confederacy. If the course of the gentleman from Fauquier had been pursued there, if these Confederate States had
              assembled in Congress, does he believe—is there a gentleman here who believes—that they would now, this day, have such a firm and settled government as they have? No ; that Congress would have resulted in dissension and rivalry; and the probability is, it would have broken up without doing anything. I am satisfied from this, that if the border conference idea be carried out it will not
              lead to harmony, but to ill-feeling and dissension between the border States; and then Virginia will have to determine, after a rejection of her counsels by the border slave States, to take the responsibility of going alone and uniting with the Confederate States, or of remaining where she is, a dishonored member of a Northern Confederacy.
            </p>
            <p className="p-in-sp">The gentleman from Fauquier himself has said if the North were to give us a carte blanche to write such a Constitution as we choose, we still could not be safe in a Northern Confederacy.</p>
            <p className="p-in-sp">
              I say then, Mr. Chairman, we have experience against theory, and the course of wisdom, on the part of Virginia, is, that she should act as she did in '76, when the oppressions of England and the wrongs of the colonies were not a tithe of what Virginia and the South have suffered from this Government, to which hallelujahs are sung by the gentleman from Greenbrier, the gentleman from
              Kanawha, and other distinguished gentlemen on this floor. I can sing as many hallelujahs to the Union, as it was founded and administered by our fathers, as they can.
            </p>
            <p className="p-in-sp">But when the Union is a Union under Black Republican rule, that <PageNumber num={635} /> threatens the destruction of the liberties of my section, I would treat it as our fathers treated George the Third.</p>
            <p className="p-in-sp">Is my time out, Mr. Chairman?</p>
            <div className="speaker" id="sp3327"><span className="persName">The CHAIRMAN</span>—</div>
            <p className="p-in-sp">Within half a minute of it, sir.</p>
            <div className="speaker" id="sp3328"><span className="persName">Mr. MORTON</span>—</div>
            <p className="p-in-sp">I ask now the courtesy of the Committee, that they will give me three minutes.</p>
            <div className="speaker" id="sp3329"><span className="persName">The CHAIRMAN</span>—</div>
            <p className="p-in-sp">The Chair cannot entertain any motion of that kind.</p>
            <div className="speaker" id="sp3330"><span className="persName">Mr. MORTON</span>—</div>
            <p className="p-in-sp">I will withdraw my amendment, with the consent of the Committee.</p>
            <p>Objection was made, whereupon the question on granting leave to withdraw was put, and decided in the affirmative.</p>
            <div className="speaker" id="sp3331"><span className="persName">Mr. BOULDIN</span>, of Charlotte—</div>
            <p className="p-in-sp">
              I desire, Mr. Chairman, to offer an amendment to the amendment offered by the gentleman from Fauquier, by striking out the same words which the gentleman from Princess Anne moved to strike out in the 10th and 11th lines—"to be appointed by the respective Conventions thereof"—and inserting the following : "To be elected for this State by the people thereof, voting by districts arranged
              on the suffrage basis of representation, for fifteen delegates—one for each district."
            </p>
            <p className="p-in-sp">I have offered this amendment, sir, with no purpose of embarrassing the amendment of the gentleman from Fauquier; but because I consider it involves an important principle, and one which, at this time, the people should require to be observed.</p>
            <p className="p-in-sp">
              We have been told over and over again, that we are in the midst of revolution. Events have been hurrying on rapidly; scenes have been changing; opinions have been changing; the people have changed ; and it is eminently proper that when you are making a move so important, the people who have changed with events, shall again be heard, and that their voice shall tell in the great movement
              proposed by the gentleman from Fauquier
              [<span className="persName">Mr. SCOTT</span>] .</p>
            <p className="p-in-sp">
              I propose, then, this amendment, conceding everything here to the Western majority; conceding to them the basis on which this Convention is organized, simply to let the people speak ; and when this conference meets, if it ever does meet, that the people of Virginia, and not this Convention, shall be the constituent body of the proposed Congress. I think the amendment involves a
              principle of the highest importance to the people of Virginia, and it is eminently proper, at this time, that it should be asserted, because we have been told, day by day, that the Confederate States of the South have been taken out of the Union by the very move embodied in this amendment, by the <PageNumber num={636} /> action of a Convention elected
              before the grave events had occurred which have since arisen, and therefore not expressing the popular will. And we have been told, over and over again, that if the people could be heard in the Confederate States, the States rights party would be defeated, and the Union cause would be triumphant. I wish, sir, that hereafter, at least, we shall have no such argument to make on either
              side, in this State. That neither the States Rights party here, nor the Union party ( I use no opprobrious terms in designating parties, it is not my habit) , shall have an opportunity of saying, hereafter, that important action has been taken by a Convention elected before these grave events had been hurried upon us, and having opinions different from the opinions prevailing among the
              people. It is for this consideration that I desire that the voice of Virginia, in this important crisis of her history, shall be fairly represented in this Congress, and I trust it will be the pleasure of the Committee to adopt the amendment.
            </p>
            <div className="speaker" id="sp3332"><span className="persName">Mr. WISE</span>—</div>
            <p className="p-in-sp">I am sorry to have to say that I regard this amendment as coming exactly into the view of the principle that was announced a little while ago by the gentleman from Kanawha [<span className="persName">Mr. SUMMERS</span>] , it gives up the three-fifths principle of the slaves to be represented not in the State representation. We are not now going to the Legislature of the Commonwealth, we are not constituting a body here within the limits of the Commonwealth, but we are constituting a Congress out of the limits of the State upon a Federal basis.</p>
            <p className="p-in-sp">
              Sir, in 1829-30, Mr. Madison offered the Federal basis as the basis for Virginia, in her own internal government. It did not go down in that Convention. In the Convention of 1850-51, in my opinion, to which the gentleman from Kanawha, has referred here, it was urged over and over again upon him, as the basis of home representation. I rejected the Federal basis as the basis of home
              representation. I stood by him and maintained that every white man in the Commonwealth should be represented equally and alike, but I never denied then, nor do I mean to deny, that when we go into a Congress with other States, we should have our slaves represented according to the Constitution of the United States, which requires that taxation and representation shall be apportioned or
              determined among the several States, according to the number of population, counting three-fifths of the slaves in the basis of representation for the House of Representatives. For your own Convention or your House of Delegates, I am in favor of the white basis; but when you go outside of your limits, as the gentleman from Kanawha
              [<span className="persName">Mr. SUMMERS</span>]
              , used the expression this <PageNumber num={637} /> morning, when you go into a Congress with other States, your basis there is the Federal basis. Now, there is the example of the Constitution of the United States before you. I never did in 1850-51 give up the Federal basis for the Federal Government. I only claim the present basis of suffrage—the white
              basis, for the State of Virginia herself. As the gentleman's proposition does not yield the Federal basis for this outside Congress, I cannot vote for it—I regret that I can not.
            </p>
            <p className="p-in-sp">At a subsequent stage I will offer a proposition which I shall now read—</p>
            <p className="p-in-sp">
              In the 9th and 10th lines, strike out "appointed by this Convention, on behalf of the people of this State," and insert, "elected for this State by the people, in fifteen districts, one Commissioner from each district, to be laid off as nearly equal as possible in population, counting three-fifths of the slaves in the apportionment of representation, as under the Constitution of the
              United States," according to the last census of 1860.
            </p>
            <p className="p-in-sp">
              If we are to go into a Congress, I go for the Federal representation. Here at home, for the Legislature, or this Convention, I go for free, equal, universal white man suffrage—free, equal and universal white man representation—a Federal basis for Federal Government, universal basis for State Government. The gentleman from Kanawha knows that I did oppose this Federal basis for the
              State; but he never found me in his life opposing it for any confederation on earth. I would not confederate with any State, North or South, that would not let me count a due proportion of our black population. That is the question here involved.
            </p>
            <div className="speaker" id="sp3333"><span className="persName">Mr. BOULDIN</span>—</div>
            <p className="p-in-sp">The principle contended for by the distinguished gentleman from Princess Anne [<span className="persName">Mr. WISE</span>]
              , has already been presented to this Convention, a fair vote taken upon it, and we have been beaten. I am a practical man ; my mode of action is to deal with facts before me, and to come, if possible to some practical result and not to commit myself to a dead proposition; for I do nothing less, in still adhering to a proposition which has been already voted down by a large majority of
              this Convention against the votes of both the gentleman and myself. I voted with the gentleman from Princess Anne upon that proposition, but I do not see the propriety or practical utility of testing it again, when its defeat would be inevitable. Seeing that we are utterly powerless in this Convention, I desire then, sir, as a practical man, to do the next best thing that can be done.
              When we meet in the proposed Congress, and are arranging, not as now, a mere plan of State action— <PageNumber num={638} /> not acting for Virginia alone, but in concert with other States, forming a new constitution and adopting a basis of representation for this new government, it may be said, with reason, that the principle contended for by the
              gentleman, should be affirmed.
            </p>
            <p className="p-in-sp">
              But the question before us now is different. It is a question of disruption and of war. Immediate war may be the result of our action, and in such a case, it may, perhaps, be well contended that all the white men of Virginia having an equal interest in the question, should have an equal hearing. I am willing that we should adopt the basis under which we are assembled here. It is
              evidently the best we can do, and as a practical man, I am willing to go for it.
            </p>
            <div className="speaker" id="sp3334"><span className="persName">Mr. WISE</span>—</div>
            <p className="p-in-sp">Will the gentleman allow me to call his attention to another fact? Are we not acting for Virginia when we send representatives to the Congress of the United States exactly in the same way, and yet we elect our representatives to Congress upon the mixed basis.</p>
            <div className="speaker" id="sp3335"><span className="persName">Mr. BOULDIN</span>—</div>
            <p className="p-in-sp">
              We send representatives to Congress under the terms of the Constitution of the United States, and not under the Constitution of Virginia, and when the proposed Congress shall meet to recommend an amended Constitution, it is to be hoped that proper amendments will be adopted, and that all difficulties between the States as to terms of representation, will be properly adjusted, and slave
              interests protected. The basis of representation under the new Constitution must be adopted there and not here.
            </p>
            <p className="p-in-sp">
              The question now is, what Virginia is to do, and how she is to act, how her voice is to be heard upon these questions? I have already stated that the proposition to adopt the mixed basis has been defeated. We have been beaten on that question, and I take it for granted that it is now useless to make another trial. I desire, then, to fall back upon my next position. When the outer wall
              is carried, I will defend the next. That is my position. The first proposition has been killed, and I take up the next best and fight upon that.
            </p>
            <p className="p-in-sp">Now, sir, the next step is to secure, if you can, an expression of the popular opinion of Virginia on this question. That is the great object of both propositions—to get at the voice of Virginia; and if I cannot get it in the mode desired by the gentleman from Princess Anne, I am willing to adopt the next best mode by which the leading object can be effected.</p>
            <p className="p-in-sp">
              Now, sir, I beg to ask of the gentleman from Princess Anne, what his position will be if he defeats my proposition? Does he prefer that this Convention, already arranged upon a basis of representation known to be against us, and dead against the gentleman and myself <PageNumber num={639} /> on the main questions before us, shall elect these delegates, or
              will you take the same constituents that elected the gentleman from Princess Anne and myself, and let them elect the persons that shall sit in the Congress to settle these matters?
            </p>
            <div className="speaker" id="sp3336"><span className="persName">Mr. WISE</span>—</div>
            <p className="p-in-sp">I will answer the gentleman. I had rather anybody would elect them than to give up the principle.</p>
            <div className="speaker" id="sp3337"><span className="persName">Mr. BOULDIN</span>—</div>
            <p className="p-in-sp">
              The gentleman holds his commission here upon the very terms that I propose that these representatives shall hold their seats to act for Virginia in the proposed Congress. I hold my commission upon the same terms. We all stand upon those terms, and if it is proper to represent Virginia here and to-day on those terms, it will be safe to represent Virginia on the same terms in this
              Congress of States. I only desire the voice of Virginia to be heard.
            </p>
            <p><span className="persName">Mr. BALDWIN</span> made an interrogatory, which was inaudible to the reporter.</p>
            <div className="speaker" id="sp3338"><span className="persName">Mr. BOULDIN</span>—</div>
            <p className="p-in-sp">
              I would reply with another question, and ask the gentleman from Augusta if he has not been denouncing the action of the Southern States in keeping from the people the right to vote upon the ordinances of secession. I expected to see gentlemen wince under this proposition of submitting this question to the people. I am not surprised that the gentleman from Augusta should endeavor to
              turn aside from the merits of this proposition and to present another issue. I am unwilling to be drawn off. I desire to hear the voice of Virginia now. Important events have occurred since we were elected to seats upon this floor. Great changes have been going on, and new issues have arisen, on which it is eminently proper that the voice of Virginia should be heard. I did hope that my
              friend from Princess Anne, having failed to get the voice of Virginia in the mode he desired, would prefer the mode I have indicated to that of electing these delegates by this Convention, with a known unchangeable majority against us. I repeat that my object in this amendment is to get the voice of Virginia—to get it by districts—and if I cannot get a better—upon that basis of
              representation on which the members of this Convention were elected, I should not fear to-day to trust to the vote of Virginia, even upon that basis of representation.
            </p>
            <p><span className="persName">Mr. GOODE</span>, of Mecklenburg, demanded the yeas and nays, which were ordered.</p>
            <p>
              The question was then taken and the amendment was defeated, yeas 61, nays 66, as follows :<span className="note" id="Note73"><span className="noteLabel">1</span>The Journal lists 66 negative votes, adding C. B. Conrad to the names given in the Proceedings.</span>
            </p>
            <p>
              YEAS-Messrs. Ambler, James Barbour, Blakey, Boisseau, Bouldin, Boyd, <PageNumber num={640} /> Branch, Bruce, Cabell, Cecil, Chambliss, Chapman, Clemens, Coffman, Conn, Echols, Flournoy, Forbes, Garland, Gillespie, Graham, Gravely, Gregory, Goggin, John Goode, Thomas F. Goode, Hale, L. S. Hall, Harvie, Holcombe, Hun-ton, Isbell, Kent, Lawson, Macfarland,
              Charles K. Mallory, James B. Mallory, Marye, Miller, Morris, Morton, Parks, Richardson, Rives, Seawell, Sheffey, Slaughter, Southall, Speed, Staples, C. J. Stuart, Strange, Sutherlin, Tayloe, Tredway, R. H. Turner, Tyler, Waller, Whitfield, Williams, Wysor-61.
            </p>
            <p>NAYS-Messrs. Janney [President]
              , Armstrong, Aston, Baldwin, A. M. Barbour, Baylor, Berlin, Blow, Boggess, Brent, Brown, Burdett, Burley, Byrne, Campbell, Caperton, Carlile, Carter, R. Y. Conrad, Couch, Critcher, Custis, Dent, Deskins, Dorman, Dulany, Early, French, Fugate, Gray, E. B. Hall, Hammond, Haymond, Holladay, Hubbard, Hughes, Hull, Jackson, P. C. Johnston, Kilby, Lewis, McComas, McGrew, Marshall, Maslin,
              Masters, Moffett, Moore, Osburn, Patrick, Pendleton, Porter, Price, Pugh, R. E. Scott, Sharp, Sitlington, Spurlock, Summers, Tarr, White, Wickham, Willey, Wilson, Wise-66.
            </p>
            <div className="speaker" id="sp3339"><span className="persName">Mr. MALLORY</span>, of Brunswick—</div>
            <p className="p-in-sp">I move to strike out the word "Arkansas." I offer this amendment, not for the purpose of making a speech upon the questions involved in this report, but for the purpose of delivering a lecture to this Convention. I said on a former occasion that I was the oldest man in this body, except my friend from Charles City [ex-President TYLER]
              , who claims that he is older than I am. Are we statesmen? Is this the assembled wisdom of the great Commonwealth of Virginia? Are we "most potent, grave and reverend seigniors," or are we children? I can not call the discussion which has been going on for the last ten days anything but child's play. I want this Convention to vote, and not speak; and if I were a speaking man, as I am
              not, I would let those who are anxious to speak alone and not reply to them. Does it do any good? Is there a man in this Convention who will say that he has been convinced either one way or the other since the discussion commenced upon the majority report? As an old man I give you my advice, that you should cease speaking and go to voting. The people in my section of the State are
              complaining of the slow progress that you are making. Every letter I get asks me what we are doing. If I see a gentleman from the country and he asks me what we are doing, I have to acknowledge that I am completely cornered and that I cannot answer his question. "What are you going to do?" is a question that I cannot give a satisfactory answer. "How long are you going to remain in
              session?" My answer is, unless the debate is stopped in some way, it will never end. I understand that one of the propositions before us provides for the holding of a Conference of the Border Slave States sometime about the last of May. Now, sir, if we were to adjourn to day it would not allow sufficient time for such Convention; and, if this session is protracted,
              <PageNumber num={641} /> the time fixed for its assembling will have to be postponed.
            </p>
            <p className="p-in-sp">I have risen, not for the purpose of discussing any single provision in the report, but simply to ask gentlemen of this Convention to cease talking and go to voting, so that we may return to our homes and give an account of our stewardship.</p>
            <div className="speaker" id="sp3340"><span className="persName">Mr. RIVES</span>—</div>
            <p className="p-in-sp">I rise to return my thanks to the venerable gentleman from Brunswick [<span className="persName">Mr. MALLORY</span>]
              . I have been pained, not so much at the great amount of speaking, in which gentlemen have indulged here, as at the inconsistency which has been exhibited by gentlemen here in the action that they propose to take. We are now discussing the question whether we shall be represented in the Border State Conference or Congress, by delegates elected by the people or by delegates elected by
              the Convention. I understand gentlemen now to demand that these delegates shall be elected by the people, who are in favor of all the action that has been taken by the Confederated States of the South, that have adopted and ratified a constitution, when not one officer of that Southern Confederacy was ever elected by the people, nor have the people been allowed to ratify said
              constitution at the polls, but it has been erected by an aristocracy, in violation of every Republican principle. The question here is, whether we shall send delegates to a Congress of the Border Slave States to be appointed by this Convention, or whether they shall be appointed by each Congressional district in Virginia? Do gentlemen suppose that we who are for referring the action of
              this Convention back to the people, relying on the popular vote to sustain us, would sit here and allow the Secessionists to make up a record to bring against this Convention, that they were in favor of sending delegates to this Conference, as they were sent from South Carolina, Mississippi, Louisiana, Georgia and Texas, and without exposing the inconsistency that marks their conduct?
              Shall Virginia adopt that aristocratic feature of the Southern Confederate States? Is there a man in this Convention who voted for secession, who has not by his act endorsed the action of the Confederated States? Does not every man know that there is not a single officer of that government who has been elected by the people? The Constitution even, has not been submitted to a single
              voter of those States. My friend from Charlotte
              [<span className="persName">Mr. BOULDIN</span>]
              , introduced a proposition that the districts shall be represented in the Frankfort conference, or Congress upon the ratio of the suffrage basis, and I voted for it. Yes, sir, and in so doing I departed from the principle of being represented in the Congress of the United States upon the ratio of federal representation, where three-fifths of our slaves count; although
              <PageNumber num={642} /> every man knows it is a departure from the principle of representation in Congress. It is to make capital before the people, at the expense of consistency ; that is too glaring to conceal. Do you want to get to the people? If you do, I will vote to carry it there—carry it upon the separate basis. I tell you, the day will come, as
              sure as there is a God in Heaven, if the border slaveholding States withdraw, and join the Southern Confederacy, the negro representation will be stricken out, and the white man will demand a basis of representation upon which the Confederacy shall rest, and this action of Virginia will be cited as the precedent, and the secessionists of this Convention as the authors of the move.
              There is where the principle of my friend from Charlotte will lead to. Mark the consistency of these gentlemen, in getting up this record about members here voting against the adoption of the proposition of electing delegates to this Conference by districts, when they would shovel us off into the Southern Confederacy, where the voice of the people was silenced with as much nonchalance,
              as an Irishman would shovel off his last spade full of dirt before going to dinner
              [laughter]
              —into a Southern Confederacy, too, formed under a Constitution that was adopted by members, not one of whom was ever elected by the people—under a Constitution, not one word or feature of which had ever been submitted to the people for their ratification or rejection. I have always acted and voted on principle, and if I believe a principle to be right, I will plunge over the gulf of
              perdition, if necessary, in order to carry it out.
            </p>
            <p className="p-in-sp">But I will now make a record for them which shall be flaunted before their faces, on the public hustings, when they get home.</p>
            <p className="p-in-sp">
              Show me a representative who will duck, and coo, and bend, and bow, to catch the public influence, and I will show you a sycophant who is not worth trusting with a handful of chestnuts. I want it distinctly understood that I am voting for the proposition to gratify our secession friends, that it may go to the people, and there I will show their inconsistency. And if the object is, and
              if is it right for Virginia to send representatives to that Congress for the purpose of amending the constitution, why was it not right for the people of those Confederate States, that you talk so much about, to do so? Why, I ask, was it not right for them to do so? There is but one answer, sir, one which I think will be developed, and that is, that the people were not to be trusted
              down South. They had the fetters on; yes, sir, they had the fetters on. The sovereigns of Virginia have not them on yet; but the object in view is to precipitate us, and to put on the fetters, which are all fixed for us, as soon as we get into that Southern Confederacy.
            </p>
            <PageNumber num={643} />
            <p className="p-in-sp">That is the scheme, sir. The Legislature appointed my venerable, talented, patriotic friend [<span className="persName">Mr. TYLER</span>] , my eloquent friend from Kanawha [<span className="persName">Mr. SUMMERS</span>] , the gentleman from Goochland [<span className="persName">Mr. SEDDON</span>] , and Judge BROCKENBROUGH to go to Washington to attend a Peace Conference, to propose amendments to the constitution of the United States. Why did not the Legislature refer them to the people? 0, no, it was very convenient for them to usurp the power</p>
            <p>At this point the speaker's time had expired.</p>
            <div className="speaker" id="sp3341"><span className="persName">Mr. MALLORY</span>, of Brunswick—</div>
            <p className="p-in-sp">I withdraw the amendment.</p>
            <div className="speaker" id="sp3342"><span className="persName">Mr. BALDWIN</span>—</div>
            <p className="p-in-sp">I have an amendment which I wish to propose to the amendment offered by the gentleman from Fauquier. In the twelfth line, after the word "government," I desire to insert the words "to be submitted for ratification to the people of the several States," so that the latter part of the amendment will read :</p>
            <p className="p-in-sp">
              "A Congress of the said States, composed of delegates to be appointed by the respective Conventions thereof, for the purpose of recommending an amended constitution of government, to be submitted for ratification to the people of the several States, upon which the Union of the States and the Confederated States, with such of the non-slaveholding States as may concur therein, can be
              safely effected; to which Congress the Confederated States and the concurring nonslaveholding States ought to be invited to send Commissioners."
            </p>
            <p>The Chairman stated the question.</p>
            <div className="speaker" id="sp3343"><span className="persName">Mr. BALDWIN</span>—</div>
            <p className="p-in-sp">
              Mr. Chairman, I have always observed that the man who has a bad hand, is always disposed to have a new deal, and I can only account, upon this principle, for the earnestness of our secession friends for another vote of the people. I need not refer to the history of the Confederate States, in their secession, and in the formation and ratification of their Constitution, up to the present
              time, to show how utterly the principle of ratification by vote of the people, has been disregarded and despised in all their movements. Nor need I refer to the course of gentlemen upon this floor, to show that this disregard of the popular principle has been approved and applauded by them at every stage of its progress. Sir, we have an instance in our own State. When it was proposed
              that the action of this Convention should be submitted to popular approval, every secessionist on this floor, I believe, took ground against any such submission, and insisted that this Convention should be an irresponsible body without accounting at all to the people of Virginia for any of the important action to be taken here.
            </p>
            <p className="p-in-sp">
              Sir, since our assembling here we are told that great changes and <PageNumber num={644} /> re-actions have taken place among the people of Virginia; and now these gentlemen have suddenly become enamoured of popular votes, and are exceedingly desirous to have our action, and all we do at every stage, submitted to the people. So exceedingly anxious have
              they become upon that subject that white-basis men from the West have given up their favorite principle, and go for the odious principle of the Federal ratio with the East; and mixed basis and Federal basis men of the East have given up their favorite principle, and go for the odious principle of the suffrage basis with the West—showing that the desire on the part of these
              secessionists to get out of the Union, rises above all the principles of State Government for which they have contended in past times.
            </p>
            <p className="p-in-sp">
              And, sir, they have been seeking to place us, who have from the beginning appealed to the people, who have from the beginning declared our confidence that the Union sentiment of the people was a strong and abiding sentiment, they have been seeking to place us in the attitude of endeavoring to prevent the popular vote. Why, sir, by the very charter of our organization as a Convention,
              all our action is bound to go back to the people. Any step that we take to sever the relations between us and the General Government of the United States, must go back to the people, for ratification or rejection by popular vote; and I do not care how we conduct the intermediate steps in the transaction of our business here, that fact stares us all in the face, namely : that all our
              action to separate this State from the Union; all our action to change its relation towards the General Government, must go back to the people. Then, sir, it was not at all necessary for us to make any appeal to the people until we have matured a plan which we are prepared to recommend to them for their final adoption.
            </p>
            <p className="p-in-sp">
              But, sir, in order to meet a difficulty which might be suggested in regard to the organization and the action of this Congress, I propose an amendment; and, let us see, now, if these gentlemen will come up to the mark, and give their support to this great final appeal, which I propose to make to the popular vote of Virginia upon the question of a final separation of this Commonwealth
              from the Federal Government.
            </p>
            <div className="speaker" id="sp3344"><span className="persName">Mr. WISE</span> [in his seat]—</div>
            <p className="p-in-sp">On what basis?</p>
            <div className="speaker" id="sp3345"><span className="persName">Mr. BALDWIN</span>—</div>
            <p className="p-in-sp">
              I suppose it would be the white basis, as the voters of the State would come forward to the polls to register their opinions; and, as the gentleman from Charlotte says, "perhaps every white man in the State would have an equal interest in this question." I suppose <PageNumber num={645} /> that is the only basis that would be admissible in submitting the
              question to the popular vote. I want it to come back for ratification by the people at the polls ; and I assure gentlemen that whenever the people at the polls are called upon to give their vote in regard to any matter propounded to them affecting the American Union, or affecting the course of Virginia towards her sister States, I shall look forward with the most undoubting confidence,
              to their action.
            </p>
            <p className="p-in-sp">
              Sir, in regard to this matter of sending back to the people from time to time, our action for their ratification, I can see no reason for it, unless, perchance, it is anticipated by gentlemen that if we have a set of Commissioners elected by the people, we might be placed in this attitude : that we will have a set of Commissioners thinking one way, and the Convention thinking another
              way, and thus, in the collision of opinion, the whole action of the State will be brought to a stand. Sir, if this Convention is to be responsible for shaping and directing the policy of the State, it ought to direct it and shape it by Commissioners representing the opinions of this Convention, which are the opinions, and are bound to be taken as the opinions, of the people of
              Virginia, until the people indicate otherwise by their action upon our final recommendation. If we are to be responsible, I insist upon it that this Convention shall select the Commissioners, as much as it should appoint its Committee upon Federal Relations, and its officers; that it shall appoint all the agents by which its policy is to be shaped and carried into final consummation,
              until, in obedience to the mandate of the people, we shall submit our perfected work for the ratification of the people themselves at the polls.
            </p>
            <div className="speaker" id="sp3346"><span className="persName">Mr. TREDWAY</span>—</div>
            <p className="p-in-sp">Mr. Chairman, opposed as I am to the proposition of the gentleman from Fauquier [<span className="persName">Mr. ScOTT</span>] , I might perhaps be justifiable in advocating at the present time the proposition of amendment which has just been submitted by the gentleman from Augusta [<span className="persName">Mr. BALDWIN</span>] . The proposition of the gentleman from Augusta adds another link to that extended chain of time, during which we are to await the action prescribed in the plan of the gentleman from Fauquier, which constitutes with me, under the circumstances, the main objection to that proposition of the gentleman from Fauquier.</p>
            <p className="p-in-sp">
              Now, sir, I understand the proposition of the gentleman from Augusta to be, that the Constitution which may be adopted by the proposed Congress, shall be submitted to a vote of the people. I ask, sir, for what purpose is it to be submitted to a vote of the people? What can it effect by way of organizing a plan of government which may be adopted afterwards? Will a vote by the people of
              the States <PageNumber num={646} /> upon the proposition which may be agreed upon by the Congress, tend to make it a part of the organic law? Is that one of the modes prescribed by which the amendments to the Constitution of the United States can be effected? Sir, I am informed it is not. And I argue, therefore, that the only effect of the proposition of
              the gentleman from Augusta will be to delay the action proposed by the gentleman from Fauquier which we are to await, by its terms, for ultimate action on the part of the State of Virginia—merely to delay it while it will not effect anything which will operate as a part of the organic law. I don't approve of it, sir, because it is to be submitted to the people. I am not amongst those
              who believe that every thing ought to be submitted to the people. As I said on a former occasion, while I don't deny to the people the principle of self-government, I do deny that they do govern themselves; and if it is submitted to the people, the people will vote under the direction and dictation of politicians. Therefore, sir, unless it is really necessary, unless the action of the
              people- shall accomplish something which will be effectual, I don't care to harass the people with it, and to have another election upon it. I object to it then; and I shall object to the proposition of the gentleman from Fauquier whether it be amended as the gentleman proposes or not, because of the time which it will take, and the delay it will produce, when delay under the
              circumstances, may be dangerous to us.
            </p>
            <p className="p-in-sp">
              Mr. Chairman, I have been pained by much of the discussion which I have heard upon this floor in reference to the various propositions which have been submitted. It seems as if we were not now standing in the midst of a crisis in which was involved the great question between the North and the South; but for several days past we have had a grave question involving an irritating and
              important question between Eastern and Western Virginia. I have heard declarations made here by Western men upon a proposition which was most unfortunately lugged in and presented to this Convention, which actually startled me.
            </p>
            <p className="p-in-sp">
              Sir, we have enough in the present controversy between us and the General Government in looking to our federal relations, to engage us; enough to embarrass us; and when I have heard it proclaimed by gentlemen from the West that the West was unwilling to come forward : that the West was not willing to make sacrifices, to use that used up expression, "for the peculiar institution of the
              East," unless the East would give them an interest in her property; I have been startled at the injustice of the proposition ; startled at its enormity. I take it for granted that is not the language of the West. Give them an interest in the property of the East! How give them an interest?
            </p>
            <PageNumber num={647} />
            <p className="p-in-sp">
              We are told by giving the majority there the right to tax the property of the East. That is the interest they want; and they tell us that without that interest they are unwilling now to make sacrifices in behalf of the injured property of the East. Well, sir, I am not aware that we have asked them to make any such sacrifices. But I was surprised to hear from my friend from Kanawha, a
              gentleman who occupies a national reputation as a statesman, as a national and patriotic man I say I was surprised yesterday when I heard him speaking of the people of his section of the State being called upon to make enormous sacrifices in the separation from the Northern and Northwestern non-slaveholding States. Let me tell the gentleman that the people whom I represent do not now
              look forward and calculate the sacrifice they will be called on to make in this emergency. I tell him they are ready to make it. In the present condition of things, the great tobacco interest upon which more than any other, my section of the State depends, has been broken up. I can point him to millions that have been lost, sacrificed already, in consequence of our existing relations
              with the Northern States. I tell him that if his people trade with Illinois, Ohio and Indiana, and other non-slaveholding States, my people also trade with the North. Our tobacco has been put up very much by the aid of Northern capital. Much of our tobacco, which has gone to the South for consumption, has first gone to New York. Our commerce in that article has been carried on through
              the instrumentality of Northern capital. But, sir, at this time, our mercantile, our commercial interest, and every branch of industry has become depressed. The amount which has been sacrificed already, in consequence of our present Federal relations, will count as many millions as the gentleman's constituents will lose by their separation from the nonslaveholding States and the loss
              of their Northern customers.
            </p>
            <p className="p-in-sp">
              I mention this simply for the purpose of showing that we are suffering, bleeding in our every interest by the present condition of things, and I mention it to show that the disastrous result that must immediately follow the delay in a final settlement which this amendment looks to, which the amendment of the gentleman from Fauquier looks to, and in fact which this whole plan must
              inevitably involve. Every material interest in the Commonwealth will be ruined. I know not how long we can stand it. I think it is much better to act upon the maxim of the gentleman from Princess Anne, that we know we must make sacrifices whatever occurs, whether there is a re-construction of the Union or whether there be secession, and we should go forward in the
              <PageNumber num={648} /> path of duty ready to make still greater sacrifices if they are required of us.
            </p>
            <p>Here the hammer fell.</p>
            <div className="speaker" id="sp3347"><span className="persName">Mr. BALDWIN</span>—</div>
            <p className="p-in-sp">
              I understand that the only objection taken by the gentleman from Pittsylvania to my amendment, is on account of the delay that it will occasion. Why, sir, how much delay does it cause to take the popular vote in the State of Virginia? Our precipitate friends taught us last winter, within how short a period the people of the State of Virginia could be brought to a vote; I think in some
              eighteen days; I do not recollect precisely the number. The election was forced upon us in the dead of winter. In the midst of the snows in the Western mountains, the people of Virginia were called upon to elect members to this body. So much in haste were these gentlemen, so anxious to get out of the Union, that they were not willing to trust to the ordinary mail for carrying the
              intelligence to the remote counties, but the Legislature directed in the law calling this body into existence—so distrustful were they of the United States mail—so fearful that in some of the counties the election would be over before the news got there—that the order for holding the election should be sent by express riders.
            </p>
            <p className="p-in-sp">That, sir, has given us a lesson that it need not take any great length of time to hold a popular election in the State of Virginia. The amount of the delay required for an election ordered in this telegraphic style will be very small.</p>
            <p className="p-in-sp">
              But, the gentleman from Pittsylvania says this delay is ruinous to his people. Well, sir, that depends very much upon circumstances yet to be developed. The distress in the country, he says, is very great. The commercial and financial distress of his people are almost past endurance. Well, sir, this commercial and financial distress has all been brought about by disunion, and yet the
              gentleman seems to entertain the idea that all he has to do in order to remedy these troubles is to go a little deeper into disunion. He seems to imagine that if you will go ahead a little further, disunion will cure all the ills it has brought upon us. Mr. Chairman, it is a philosophy that I have never heard the parallel of, except in the case of the young lady who married a
              pertinacious beau, in order to get rid of him.
              [Laughter.] I cannot see how, upon any other principle, the gentleman can recommend going out.</p>
            <p className="p-in-sp">
              Mr. Chairman, I am not in favor of this system of hurrying in regard to matters of most vital consequence to this people. I do not admit this modern idea, that the more important a piece of business is, the <PageNumber num={649} /> less you ought to think about it. I do not admit the correctness of the principle that has been introduced, of hurrying
              deliberative bodies, or of hurrying the people themselves, without fair discussion, without due deliberation, into the determination of any question of importance. I think, therefore, sir, this objection on the ground of delay, amounts to very little.
            </p>
            <p className="p-in-sp">
              What excuse would you offer to the people when they ask, upon what ground you refused to allow them to determine upon this most important question that ever affected a free people? "Oh," says the gentleman from Pittsylvania, "we would like very well to have your opinion upon this matter, but, really, we have not time." What would the people think of such an excuse? It seems to me that
              this amendment is one which ought to commend itself to the support of every member of this body, of whatever party.
            </p>
            <p className="p-in-sp">I want to see whether those who have been so exceedingly anxious for an appeal to the people, will now come up and place this appeal upon this most important subject, where it can be fairly made and fairly met.</p>
            <div className="speaker" id="sp3348"><span className="persName">Mr. HUNTON</span>—</div>
            <p className="p-in-sp">If the gentleman will permit me, I desire to know to what States he refers in his amendment, when he says this Constitution is to be submitted to the people of the several States?</p>
            <div className="speaker" id="sp3349"><span className="persName">Mr. BALDWIN</span>—</div>
            <p className="p-in-sp">It is merely a suggestion, that where this Constitution shall be adopted, by any State, it shall be adopted by a vote of the people, and not by mere politicians.</p>
            <div className="speaker" id="sp3350"><span className="persName">Mr. HUNTON</span>—</div>
            <p className="p-in-sp">Then I say to the gentleman from Augusta, that I am in favor of his proposition.</p>
            <div className="speaker" id="sp3351"><span className="persName">Mr. BALDWIN</span>—</div>
            <p className="p-in-sp">It refers, of course, to those States which concur with us in making and adopting that Constitution, and suggests that the adoption or rejection shall be by the people at the polls. I desire to present in this respect as striking a contrast as possible to the action upon this subject by the Conventions of the Confederate States.</p>
            <div className="speaker" id="sp3352"><span className="persName">Mr. HUNTON</span>—</div>
            <p className="p-in-sp">The point I desire to present to the gentleman is, that we have no right to prescribe to the other States the manner in which they shall ratify or reject the Constitution that may be agreed upon. As far as Virginia is concerned, I am in favor of submitting any such a Constitution to the people.</p>
            <div className="speaker" id="sp3353"><span className="persName">Mr. BALDWIN</span>—</div>
            <p className="p-in-sp">
              Certainly this is not dictating to the other States at all. We merely suggest to them what in our opinion is the best plan in this respect, as we have suggested that the delegates to such a Congress should be selected by conventions similar to our own. All we do is merely to suggest that the action of that Congress shall be
              <PageNumber num={650} /> ratified or rejected by the people of each State. We have, of course, no right to dictate to the other States, what shall be their action, but we have the right respectfully to suggest to them the course we have adopted, so far as Virginia is concerned, in regard to the submission of the action of that Congress to the people for
              their ratification.
            </p>
            <p><span className="persName">Mr. WISE</span> called for the yeas and nays upon the adoption of the amendment.</p>
            <p>The yeas and nays were ordered.</p>
            <p>
              The question was then taken, and it was decided in the affirmative, yeas 124, noes 4, as follows :<span className="note" id="Note74"><span className="noteLabel">2</span>The Journal also refers to 124 affirmative votes, but actually lists 126, since it adds Fugate to the 125 given below.</span>
            </p>
            <p>YEAS-Messrs. John Janney [President]
              , Ambler, Armstrong, Aston, Baldwin, A. M. Barbour, James Barbour, Baylor, Berlin, Blakey, Blow, Jr., Boggess, Borst, Bouldin, Boyd, Branch, Brent, Brown, Bruce, Burdett, Burley, Byrne, Cabell, Campbell, Carlile, Carter, Cecil, Chapman, Clemens, Coffman, Conn, C. B. Conrad, Robert Y. Conrad, Couch, Critcher, Custis, Dent, Deskins, Dorman, Dulany, Early, Echols, Flournoy, Forbes,
              French, Garland, Gillespie, Graham, Gravely, Gray, Gregory, Jr., Goggin, J. Goode, Jr., T. F. Goode, Hale, E. B. Hall, L. S. Hall, Hammond, Harvie, Haymond, Holcombe, Holladay, Hubbard, Hughes, Hull, Hunton, Isbell, Jackson, P. C. Johnston, Kent, Lawson, Leake, Lewis, McComas, McGrew, Macfarland, C. K. Mallory, J. B. Mallory, Marshall, Marr, Marye, Sr., Maslin, Masters, Miller,
              Moffett, Morris, Morton, Moore, Nelson, Orrick, Osburn, Parks, Patrick, Pendleton, Porter, Price, Pugh, Richardson, Rives, R. E. Scott, Sharp, Sheffey, Sitlington, Slaughter, Southall, Speed, Staples, Chapman J. Stuart, Strange, Summers, Sutherlin, Tarr, Tayloe, Thornton, R. H. Turner, Tyler, Waller, White, Whitfield, Wickham, Willey, Williams, Wilson, Wise, Wysor-124.
            </p>
            <p>NAYS-Messrs. Boisseau, Chambliss, Kilby, Tredway-4.</p>
            <p>So the amendment was agreed to.</p>
            <div className="speaker" id="sp3354"><span className="persName">Mr. HARVIE</span>—</div>
            <p className="p-in-sp">I move to strike out in the third line from the word "Constitution" to, and including the word "body" in the 4th line, as follows : "by the time appointed for the re-assembling of this body," and to insert in lieu thereof the words "by the first of October next," so that it will read :</p>
            <p className="p-in-sp">"And in the event that favorable responses on the part of the nonslaveholding States be not made to the proposed amendments to the Constitution by the first of October next," &amp;c.</p>
            <p className="p-in-sp">
              Mr. Chairman, my object is to take the gentleman from Augusta at his word, and have a "new deal." When this deal was had, the gentleman from Augusta and those co-operating with him, appealed to the people to take this matter into their own hands. Whenever I heard them appeal to the people, it was to permit this Convention to do nothing without their own sanction. It may be true that
              the act <PageNumber num={651} /> of the Legislature calling the Committee did not go to that extent, but that was the issue everywhere made to the people.
            </p>
            <p className="p-in-sp">
              Well, sir, so far as the people can act, they have since acted. So far as I have heard from the people in every quarter of the State, they have affirmed and declared that it was the duty of this Convention to lay before the people the question whether they would change the relations which they now hold to the Federal Government. Sir, I defy the majority of this Convention to go before
              them on that question. I defy them in the name of my immediate constituents, and I defy them in the name of an overwhelming majority of the people of Virginia, to go before the people, not only upon the question of a severance of the State from the Federal Union, but upon the issue whether you or me represent them in this Convention.
            </p>
            <p className="p-in-sp">
              Now, sir, what does the gentleman from Augusta say? I call attention to his declaration, pregnant as it is with distrust of the people? What did he say when he was upon the floor a short time ago? He said that if the election of delegates to this proposed Congress was to take place by the people, it might perchance turn out that the delegates the people might send would be in
              antagonism with this Convention.
            </p>
            <div className="speaker" id="sp3355"><span className="persName">Mr. BALDWIN</span>—</div>
            <p className="p-in-sp">What I said was that I could see no other reason for their election being by the people, unless gentlemen hoped such would be the result.</p>
            <div className="speaker" id="sp3356"><span className="persName">Mr. HARVIE</span>—</div>
            <p className="p-in-sp">Yes, sir, and I am prepared to say that such will be the result, whenever this Convention shall submit the question to the test.</p>
            <p className="p-in-sp">
              Let him not come into this conclave with the cards packed. They were packed the first day of the session. They are packed now. Let him not force the people into this channel which they have dug here; but spread the canvass broad. Let the people take their own direction ; and I am satisfied and confident that they will rebuke the action of this Convention whenever the issue is
              presented. This is a pretty appeal which the gentleman has made by his last movement, and for which we have been compelled to vote, on principle and on principle alone.
            </p>
            <p className="p-in-sp">And what is the object? The object is to manacle, and tie the hands of the people by machinery ; and after the will of the people has been defeated, and after he has substituted the action of his machinery for the popular action, then it is that an appeal is to be made to them, when it is too late for the popular action to have any effect.</p>
            <p className="p-in-sp">
              Why not make the appeal now? The gentleman says it would take but a short time, and he instances the election of last winter, when the ground was frozen and snow was falling. Why not let the people be <PageNumber num={652} /> appealed to again before October, when the gentleman modestly proposes to come back here and perfect the work? Why not let the
              people decide whether they want the gentleman to perfect the work, or whether they want the work perfected at all? The gentleman first stated this call to the people last Fall, and, in my opinion, the people were deluded by the appeal made to them, and allowed this gentleman to have thus far his own way. Now I appeal to him to give the people an opportunity to determine whether the way
              which the gentleman desires to go, is the way that they wish to go.
            </p>
            <div className="speaker" id="sp3357"><span className="persName">Mr. R. E. SCOTT</span>—</div>
            <p className="p-in-sp">I do not propose, Mr. Chairman, to go into the question as to whether this Convention represents the popular wishes of the State of Virginia. I content myself to abide by the verdict of the election until another verdict is regularly rendered. But I hope it will not be the pleasure of the Committee to adopt the amendment of the gentleman from Amelia [<span className="persName">Mr. HARVIE</span>]
              . As the proposition stands, it defers the designation of the time by which we shall expect to receive the responses of the Northern States. That time is to be fixed by the day to be appointed for the re-assembling of this Convention. We cannot tell at this moment when we shall adjourn. How long we shall be kept here, to what further extent debate is to be protracted on these
              resolutions it is impossible to know, because it seems to me that there is a settled purpose on the part of some members of this body, who heretofore seemed solicitous for hasty action, to protract our sessions indefinitely. This is, I believe, the third day consumed in the consideration of this amendment. How many more days are to be consumed in it? If gentlemen insist,
              notwithstanding innumerable votes by yeas and nays, still to press amendments, it is obvious that they have it in their power, so long as we remain in Committee of the whole, to obstruct anything like final action. The question now before us touches the policy of a conference with the border States. That is a question of great importance, one, manifestly on which to some extent, the
              opinion of this body is divided. But it is one, in regard to which, so far as indications can be given by votes, there seems to be a settled purpose on the part of the majority.
            </p>
            <p className="p-in-sp">
              Now, the effect of the proposition of the gentleman from Amelia, can be nothing else, it seems to me, than to embarrass a fair decision on this question. The question as to the time to be given to the Northern States, is one of detail; one not involved in the principle of my amendment; and if we undertake now to agree on details, it is manifest that we will incur the hazard, to some
              extent, of having the judgment of the committee on the important question presented <PageNumber num={653} /> by my amendment disturbed. As that amendment stands, it proposes to defer the settlement of this matter of detail to the proper moment—to the moment when we come to adjourn this body. I insist that that is the proper time to settle it, because we
              can only judge then of the reasonable time to be given to the other States. I therefore trust that it will not be the pleasure of the Committee to concur in the amendment of the gentleman from Amelia, but, so far as that portion of the proposition is concerned, to allow it to stand in its present form.
            </p>
            <div className="speaker" id="sp3358"><span className="persName">Mr. HARVIE</span>—</div>
            <p className="p-in-sp">I called the attention of the Committee to the fact that the gentleman who made the remarks on which I commented failed to reply to me when the appeal was made to him.</p>
            <div className="speaker" id="sp3359"><span className="persName">Mr. BALDWIN</span>—</div>
            <p className="p-in-sp">I was in the act of getting up when the gentleman from Fauquier [<span className="persName">Mr. R. E. SCOTT</span>] , took the floor.</p>
            <div className="speaker" id="sp3360"><span className="persName">Mr. HARVIE</span>—</div>
            <p className="p-in-sp">The gentleman is usually very rapid when he wants to get the floor. I watched the gentleman and did not see that he made any spasmodic movement to get up.</p>
            <div className="speaker" id="sp3361"><span className="persName">Mr. BALDWIN</span>—</div>
            <p className="p-in-sp">I certainly did not make any "spasmodic" movement. The spasm which seems to affect the gentleman has not attacked me.</p>
            <div className="stage it">[Laughter.]</div>
            <div className="speaker" id="sp3362"><span className="persName">Mr. HARVIE</span>—</div>
            <p className="p-in-sp">
              I am not certain, Mr. Chairman, and will not say that any despatch, either telegraphic or otherwise, passed between the gentleman who did reply and the gentleman who did not reply, but I call attention to the fact that the gentleman who did take the floor did not reply to the appeal which I made; and at this time it has gone unreplied to. I call attention further, to the fact that any
              issue which I made was not made on the point to which the gentleman from Fauquier has addressed himself, but on the point of the reassembling of this Convention at any time after it adjourns.
            </p>
            <p className="p-in-sp">
              The gentleman from Fauquier says it will be time enough for us to settle this question when we come to adjourn. I want to know now, before voting on any proposition, who it is that are to perfect these arrangements. I want to know whether these gentlemen mean to rely on the snap judgment which they got on us last winter and keep the matter in their own hands, or whether they are now
              willing to carry out the spirit of that appeal and let the people themselves decide their future action. I bring gentlemen to that point. I am not going to make any side issues with them. But I call the attention of the Committee, and through it of the community, to the fact that, having by chance got a majority here on points which, in my opinion, the people misunderstood, these
              gentlemen take advantage of their <PageNumber num={654} /> position and withhold from the people an opportunity of expressing their sentiments as to what shall be the destiny of this State. They have perfected schemes and put their machinery into operation so as to trammel and bind the people. That is the point I make.
            </p>
            <p className="p-in-sp">I do not care whether you make these responses come in October or not. I am not afraid of the appeals of the gentleman from Augusta [<span className="persName">Mr. BALDWIN</span>] , who, in every speech he makes, appeals to popular prejudices, characterizing the men who make movements in opposition to his as precipitators. I have not heard a speech from him, in the last three days, which he has not prefaced by characterizing his opponents as precipitators, and saying that he could expect nothing better from that quarter.</p>
            <div className="speaker" id="sp3363"><span className="persName">Mr. BALDWIN</span>—</div>
            <p className="p-in-sp">The gentleman is mistaken, if he understood me as meaning any personal disrespect.</p>
            <div className="speaker" id="sp3364"><span className="persName">Mr. HARVIE</span>—</div>
            <p className="p-in-sp">
              None on the face of the earth, sir, but political disrespect. But he tries to get the judgment of the Court by exciting the prejudices of the Court. That is what I complain of. It is not fear. It is not the way in which the gentleman acts in private life, and I want him to use the same candor here as characterizes his dealings with private individuals. With them he deals on the square;
              but when he deals with politicians, I am afraid he makes some grains of allowance.
            </p>
            <p>The question being on <span className="persName">Mr. HARVIE</span>'S amendment to the amendment, <span className="persName">Mr. HARVIE</span> demanded the yeas and nays.</p>
            <p>The yeas and nays were ordered.</p>
            <p>The question was taken, and it was decided in the negative—yeas 44, nays 76—as follows:</p>
            <p>
              YEAS-Messrs. Ambler, Jas. Barbour, Blakey, Boisseau, Borst, Bouldin, Bruce, Cabe11, Cecil, Chambliss, Chapman, Conn, Echols, Forbes, Garland, Graham, Gregory, Goggin, J. Goode, Jr., T. F. Goode, Hale, L. S. Hall, Harvie, Holcombe, Hunton, Isbell, Kent, Lawson, C. K. Mallory, Marr, Miller, Morris, Morton, Richardson, Sheffey, Speed, Strange, Sutherlin, Tredway, R. H. Turner, Tyler,
              Williams, Wise, Wysor-44.
            </p>
            <p>NAYS-Messrs. Janney [President]
              , Armstrong, Aston, Baldwin, A. M. Barbour, Baylor, Berlin, Boggess, Branch, Brown, Burdett, Burley, Byrne, Campbell, Carlile, Carter, Clemens, C. B. Conrad, R. Y. Conrad, Couch, Critcher, Custis, Dent, Deskins, Dorman, Dulany, Early, French, Fugate, Gillespie, Gravely, Gray, E. B. Hall, Hammond, Haymond, Holladay, Hubbard, Hughes, Hull, Jackson, P. C. Johnston, Kilby, Lewis, McComas,
              McGrew, Macfarland, J. B. Mallory, Marshall, Marye, Maslin, Masters, Moffett, Moore, Orrick, Osburn, Patrick, Pendleton, Porter, Price, Pugh, Rives, Ro. E. Scott, Sharp, Sitlington, Southall, Spurlock, C. J. Stuart, Summers, Tarr, Tayloe, Waller, White, Whitfield, Wickham, Willey, Wilson-76.
            </p>
            <PageNumber num={655} />
            <p>So the amendment to the amendment was rejected.</p>
            <p>During the vote, <span className="persName">Mr. STAPLES</span> stated that he had paired off with <span className="persName">Mr. NEBLETT</span>, otherwise he should have voted no.</p>
            <div className="speaker" id="sp3365"><span className="persName">Mr. WISE</span>—</div>
            <p className="p-in-sp">I propose to offer another amendment to the amendment of the gentleman from Fauquier. I propose to insert between the words "time" and "appointed," the words "to be."</p>
            <p className="p-in-sp">
              There is a curious proposition here, sir, and it is to assume the fact without proposing it at all, that this body is to re-assemble. I have heard no proposition to re-assemble this body, and as far as my vote is concerned, it never will assemble again after it has once been in session. Whenever it does adjourn, it will not, with my consent, adjourn for a recess. It will adjourn
              according to the technical phrase, sine die, sir.
            </p>
            <p className="p-in-sp">
              But, sir, it is assumed here, that there is a time appointed; not only that, but it would seem that there is a time appointed for the re-assembling of this body without any resolution to that effect. I suppose, though, the mover of this proposition had all the triggers set, sir—every thing fixed before hand; and, though I did not know it, he knew the fact that it was to re-assemble. I
              have not been informed of the fact, if it is to re-assemble. But not content with asserting that it was to re-assemble or to be re-assembled, he has stated the fact in the past tense—he says, "by the time appointed"—that is the past participle. I now aver the fact that this resolution asserts what is not a fact, no time being appointed for the re-assembling of this Convention; and if
              you adopt this resolution before appointing a time for re-assembling the Convention, you will assert a fact which you all know does not exist. I would ask the gentleman from Fauquier
              [<span className="persName">Mr. SCOTT</span>] , to tell me, in all candor, when we are to re-assemble? Will the gentleman inform me? I take silence for my reply. I would like to be informed, and to be informed before I am called upon to vote upon this resolution, when it is that this Convention is to be re-assembled. That is in the womb of time—in the future as yet</p>
            <p className="p-in-sp">
              and when I am called to vote upon this question, the punctum temporis, the point of time is an essential element of my vote. When are we to re-assemble? Between now and October in the year '61, or October '62. This resolution says the time has been appointed. Has it been appointed? No. Has any proposition been made to appoint a time? No. But gentlemen have assumed that the time not
              only is to be appointed, but that it has been appointed. Sir, I might say, instead of adding the words, "to be appointed," "by the time which may be appointed." But, from this proposition, I take it for <PageNumber num={656} /> granted that it is positively fixed—that it is a foregone conclusion that the time is certainly fixed. Now, when? Can any
              gentleman who is better informed than I am tell me when?
            </p>
            <div className="speaker" id="sp3366"><span className="persName">Mr. SCOTT</span>, of Fauquier—</div>
            <p className="p-in-sp">
              I would suppose that the gentleman from Princess Anne, could have answered his inquiry without information from me. I have no doubt the gentleman has forgotten his minority report, because "a change seems to have come over the spirit of his dream." By the gentleman's own minority report, it is proposed that the propositions emanating from this Convention shall be submitted to the other
              States, and it gives till the first of October to receive responses. Did not the gentleman mean to indicate by that portion of his report the re-assembling of the Convention?
            </p>
            <p>The usual time for recess , [2 o'clock] having arrived, the Chairman vacated the chair.</p>
            <div className="section" id="vsc1965.v3.2.10.1">
              <h3><span className="headingNumber">1.10.1. </span><span className="head">AFTERNOON SESSION</span></h3>
              <p>The Convention re-assembled at 4 o'clock, P. M.</p>
              <div className="speaker" id="sp3367"><span className="persName">Mr. SCOTT</span>, of Fauquier, being entitled to the floor resumed his remarks :</div>
              <p className="p-in-sp">When the Committee took its recess I was about referring the gentleman from Princess Anne , [<span className="persName">Mr. WISE</span>] , to his own minority report for answers to the questions addressed to me.</p>
              <p className="p-in-sp">After suggesting the propriety of submitting various propositions to the Northern States, this provision will be found :</p>
              <p className="p-in-sp">"That responses to these demands from the respective States shall be requested within a fixed and limited period, to wit : on or before the 1st of October next, if possible."</p>
              <p className="p-in-sp">It would seem then, that the gentleman from Princess Anne contemplated that responses should be made to the propositions.</p>
              <p className="p-in-sp">Now, to whom are the responses to be made? By whom are they to be received? I take it, sir, that the gentleman contemplated responses to be made to this Convention; because in the 7th section of his report, he proceeds thus:</p>
              <p className="p-in-sp">"That this Convention will place itself in immediate communication with the border and other slaveholding States, still remaining in the Union, for conference and co-operation whilst awaiting the responses of the other States to these requests and demands."</p>
              <p className="p-in-sp">
                Now, it is perfectly clear that the gentleman must have contemplated one of two things—either that this Convention should remain in session—that we should be kept here through the dog days, until the 1st of October, or else he contemplated an adjournment in <PageNumber num={657} /> the meantime. He certainly did not contemplate the adjournment sine die
                because one of his propositions is, that we shall put ourselves in communication with the Conventions to be assembled by the border States.
              </p>
              <p className="p-in-sp">
                Now, sir, I take it that the gentleman from Princess Anne, like myself, supposed that this body would not adjourn finally until it executed the high trust committed to it. We have been elected to determine what is proper for the State of Virginia to do in respect to the grave events which have happened and are in progress of happening. That final action on the part of this body was
                contemplated, necessarily follows from the fact that by our commission we were forbid to do anything to determine the relations in which we stand to the Federal Government without submitting our work to the approbation of our people.
              </p>
              <p className="p-in-sp">Now, sir, for one, I take this occasion to say, that I am not to be driven by this eternal clamor of the minority upon this floor from a sense of the propriety and the dignity which becomes this body. I am not to be driven by assertions from any quarter, within this House or outside of it, that we misrepresent the wishes and the opinions of our constituents.</p>
              <p className="p-in-sp">I have said that we have been charged with a high trust, and, so far as my vote goes, I will never consent to abandon that trust until it shall be finally executed.</p>
              <p className="p-in-sp">Gentlemen say that we misrepresent popular opinion. I take issue with them. I aver, upon my part, according to my best belief we are the true exponents of the popular sentiment of the people of Virginia.</p>
              <p className="p-in-sp">
                When we shall have brought our present labors to a close, and adjourned to meet again at a future day, we will all have the opportunity of returning to our constituents, where, with free interchanges of opinion, we will inform ourselves of their wishes, and when we return, knowing those wishes, we will carry them out. I care not from what motive the assault is made, I repel it, and
                maintain that we represent truly the sentiments and the opinions of the people. Those gentlemen who are so much exercised upon this point, who desire another election to be made, can gratify that desire, so far as they are concerned by resigning their seats and submitting themselves to another popular contest.
              </p>
              <p>Here the ten minutes expired.</p>
              <div className="speaker" id="sp3368"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I ask you what reply the gentleman has given me to the question, when is your time? Here he has labored—</p>
              <div className="speaker" id="sp3369"><span className="persName">Mr. SCOTT</span>—</div>
              <p className="p-in-sp">I take it that the gentleman cannot dodge in this way.</p>
              <PageNumber num={658} />
              <div className="speaker" id="sp3370"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                I tell the gentleman he is dodging, and I mean to catch him. He cannot dodge me, and he cannot escape the point of dodging which I will make against him now. The gentleman asks me, what time? He labored for five minutes, before two o'clock, upon the idea of my report. He has now come back, after deliberation—and he is generally cool—and has hammered away again upon the report; and
                what has he made of it? That I, in my report, ask for time, and that I am asking for time now. At what time do you propose that this Convention shall assemble?
              </p>
              <div className="speaker" id="sp3371"><span className="persName">Mr. SCOTT</span>—</div>
              <p className="p-in-sp">That was not the question.</p>
              <div className="speaker" id="sp3372"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                It is the question. I asked none other. What was the question? If the gentleman understood any other he understood inaccurately. I asked him again and again, when he did propose to reassemble this Convention? To name to me the time. For the time was an element that I should consider in giving my vote upon this question. And what is the reply? Why, as usual, an evasion—that I proposed
                the 1st of October. The gentleman from Amelia
                [<span className="persName">Mr. HARVIE</span>] , tendered him the 1st of October as the time for us to re-assemble, and I believe he assisted in voting that proposition down?</p>
              <div className="speaker" id="sp3373"><span className="persName">Mr. SCOTT</span>—</div>
              <p className="p-in-sp">Yes, sir.</p>
              <div className="speaker" id="sp3374"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                Yes, he did. Now, I ask, when do you propose that this Convention shall re-assemble? I asked him before in the very words—is it in six months? is it by the 1st of October? is it by the 1st of January? is it Anna Domini, 1861 or Anno Domini, 1862? I said to him, "you know; I assume that you know, because you have assumed the fact that the Convention is to take a recess and to
                re-assemble." I said that he did not know that. I presume the gentlemen did not know it. Now, when is it that we are to re-assemble? When I made my report on the 9th of March, I thought, with some confidence, that the gentleman's scheme of a National Convention was killed. I thought that the snake had been scotched, if not killed. I did not dream that it was to approach me in the
                insidious form of a Border Conference. That report would not have waited a day for such a measure as this, and it would have been very different from what it is. What had my report to do with this question of time? My report has never been proposed here, except in the proposition to-day as to the point of time, made by the gentleman from Amelia
                [<span className="persName">Mr. HARVIE</span>] . The gentleman from Fauquier [<span className="persName">Mr. SCOTT</span>]
                , did not tell me that he would take the first of October. He told me that he would not take the first of October. Now what time will he take? Sir, he will take all the time he can take between now and doom's day? Time, time, delay, <PageNumber num={659} /> delay, whilst, in spite of all the sneers that have been indulged in at the false dispatches, I
                am now enabled to announce to you that I hold in my hand dispatches to the Ex President of the United States
                [<span className="persName">Mr. TYLER</span>] , from Montgomery, which establish the fact that the war has commenced. As the Convention may desire the information, I will, with your permission, read them if the reading does not come out of my time.</p>
              <p>Cries of "read, read."</p>
              <div className="speaker" id="sp3375"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The gentleman can read them as a part of his argument.</p>
              <div className="speaker" id="sp3376"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">If it will cut me short as to time, I will not read it now. Gentlemen talk about precipitation. The gentleman from Augusta [<span className="persName">Mr. BALDWIN</span>] , ought to be the last man ever to complain of precipitation—either the word or act of precipitation. It was by precipitation, and nothing else but precipitation, that enabled a Democratic or States Rights State to be represented by a majority of the enemies of the Democracy upon this floor. He and his friends owe everything to precipitation.</p>
              <p className="p-in-sp">We are now waiting in the midst of war to determine to a nicety what patriotism consists in. Sir, the gentleman from Fauquier proposes that those gentlemen who are so much exercised and who desire another election, shall resign their seats and go back to their constituents. I challenge both the gentleman from Fauquier and the gentleman from Augusta [<span className="persName">Mr. BALDWIN</span>]
                , to resign and go back to their constituents. The gentleman from Augusta has again and again boasted that Augusta is the heart of Virginia. I can only say, if it is the heart of Virginia, that it is more unlike the body than any heart that ever palpitated in any body that I ever knew. She has always voted against Virginia, against the doctrine of States rights. As for the gentleman
                from Fauquier, I do not believe that it would be safe for him to resign and go home. He was understood very differently, I am told.
              </p>
              <div className="speaker" id="sp3377"><span className="persName">Mr. SCOTT</span>—</div>
              <p className="p-in-sp">Upon what authority do you say that?</p>
              <div className="speaker" id="sp3378"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">By the authority of information and impression; by the authority of this proposition, which is itself a double delusion.</p>
              <div className="speaker" id="sp3379"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">I can hardly believe that the opinions of individuals should become subjects of discussion here.</p>
              <div className="speaker" id="sp3380"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">There is no reflection intended by me upon the motives of the gentleman.</p>
              <div className="speaker" id="sp3381"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Chair can hardly determine in a discussion of this kind, where the proper line is to be drawn.</p>
              <PageNumber num={660} />
              <div className="speaker" id="sp3382"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I do not mean to impugn the gentleman's motives. I only say that the effect of this proposition is to lead gentlemen to go for it, who mean, if they can, to side with the North.</p>
              <p>Here the hammer fell.</p>
              <div className="speaker" id="sp3383"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">I move to amend the amendment of the gentleman from Fauquier, by striking out the words, "by the time appointed for the re-assembling of this body," and insert the words, "in a reasonable time."</p>
              <p className="p-in-sp">I am sorry to trespass upon the patience of the Convention, which, I am sure, has been already exhausted; but, occupying the position that I do, I feel compelled to submit some remarks upon the proposition offered by the gentleman from Fauquier [<span className="persName">Mr. SCOTT</span>] . I do not desire to get into any controversy with my friend from Amelia [<span className="persName">Mr. HARVIE</span>] , who, if he does not regard himself as the only representative of the people, upon this floor, certainly regards himself as a peculiar representative of the popular sentiment here.</p>
              <p className="p-in-sp">
                Mr. Chairman, I have had occasion to admire the manly boldness which the gentleman from Amelia has displayed upon this floor, upon the various questions which have arisen. I have admired in him much more the manly opposition we have met with from him than the course of certain gentlemen, from whom we received opposition, and who we had a right to expect would be our allies.
              </p>
              <p className="p-in-sp">
                The gentleman from Amelia talks about making an appeal to the people. At the proper time I am prepared to make an appeal to the people, but I beg to assure the gentleman that we cannot permit him to devise the form in which that appeal shall be made. We, the representatives of the majority of the people, must be allowed to select the mode and manner in which we shall appeal to them.
                He talks about having a new deal, but I am afraid my friend wants, when he gets this new deal, to cut, shuffle and deal himself.
              </p>
              <p className="p-in-sp">
                But the object of my rising is to make some remarks with regard to an interest which is the peculiar interest of my section of the country. I mean the tobacco interest—in an agricultural and manufacturing point of view. If you were to take the remarks of the gentlemen from that section of the country, who have spoken, as indicating what is the real interest of that section upon this
                subject, you would suppose that all our interests lay to the South—that we had no interest upon this subject in any connection with the North or Northwest. Now, sir, I come from a tobacco raising country. I come from a county in which not only the slaveholders, but the non-slaveholders also are engaged in raising tobacco. Gentlemen will pardon me for using
                <PageNumber num={661} /> the term non-slaveholders here upon this floor; but I desire to protect every interest in this Commonwealth. Gentlemen will excuse me for expressing the opinion that a man who does not own a negro is as good as the man who does own one, other things being equal. I do think, notwithstanding I may, in the opinion of some, subject
                myself to the charge of being unsound upon this subject, that the interests, the rights and the feelings of the non-slaveholders of this Commonwealth ought to be regarded and respected, and not made entirely subordinate to the interests of slavery. But the rights and all the interests of all classes in the State, whether slaveholding or non-slaveholding, and of all portions of the
                State ought to be regarded and protected.
              </p>
              <p className="p-in-sp">Sir, the difficulties which now rest upon the country have fallen with a crushing weight upon my section of the country, and there is no portion of the population that have felt them so sensibly as those nonslaveholders engaged in the culture of tobacco.</p>
              <p className="p-in-sp">
                Sir, their principal means of raising money to supply their wants and to pay their dues to the State, depend upon the money that they shall receive for their tobacco. My friend from Pittsylvania, told us, before dinner, that we depend upon the capital of the North to carry on the manufacture of tobacco. Sir, his county and my county are not only engaged in the cultivation, but are
                largely engaged in the manufacture of this article ; and it is true that the manufacturer depends in a great degree upon the capital received from the North. He draws upon his commission merchant at the North, and in that way gets the money to pay the planter for his tobacco. When that source fails, then comes a pressure upon the community; and my county is now suffering on account
                of this pressure, because the manufacturer is unable to carry on his business, for want of means, and the consequence is that slaveholders and non-slaveholders are involved in the same distress.
              </p>
              <p className="p-in-sp">
                Mr. Chairman, where do we find our principal market for the manufactured article of tobacco? Many days ago a member of this Convention told this Committee that while the greater portion of it was carried to the North in the first instance, yet it found its principal market in the South. I think, sir, the gentleman was mistaken. Nearly the whole of the exports of manufactured tobacco
                from the city of Richmond and the country is to the markets of New York and Baltimore, and from those points it is scattered over the whole country. The consumption of the article of tobacco depends upon the number of mouths to be supplied, and as they have more mouths <PageNumber num={662} /> at the North, there is more tobacco consumed there. In fact,
                I had obtained a report, in a paper published in Baltimore—which I could not lay my hand on when I came here this afternoon—showing that there was a very large trade from Baltimore in this article with the North-western States. Now, sir, if we sever our connections entirely with the Northern and North-western States, what will be our condition? Gentlemen on this floor know that the
                States of Ohio, Indiana and Illinois are capable of producing any amount of tobacco; and if we do sever our connection with these States, we must know it will be the policy of the Government which they will have to encourage their productions, and they may lay such a tariff upon tobacco as will exclude us from their markets—markets in which are consumed, I venture to say, two thirds
                of our manufactured tobacco. These States, as I have said, can produce any amount of tobacco, and as it would be wise policy in a Northern Confederacy to encourage all their productions, agricultural as well as manufactured, we have reason to anticipate that it would adopt that policy which would prove utterly disastrous to the tobacco interest in my region of the country.
              </p>
              <p className="p-in-sp">Sir, the gentleman from Halifax [<span className="persName">Mr. BRUCE</span>]
                , asked what trade we had with the State of Tennessee? In the last three or four years, when our region of the country has been suffering from a great scarcity of grain, produced by droughts, we have received a considerable portion of our supplies of corn for bread from the State of Tennessee. Even in the rich, fertile county of Roanoke, represented by my friend in this body, they
                have had to bring corn to some extent from the State of Tennessee, in order to feed their negroes. And one of the principal line of improvements that we have in the State
                [the Virginia and Tennessee Railroad]
                , penetrates with its ramifications into Tennessee, connecting with Nashville and the Ohio river; and there is already projected a scheme to connect it with the Kentucky roads. When that is carried out, this line of improvement and its connections with the Kentucky and Tennessee roads, will bring us a trade as we have reason to anticipate that we have not received from any quarter
                yet.
              </p>
              <p>Here the hammer fell.</p>
              <p>The question was then taken upon the amendment to the amendment, which was rejected.</p>
              <div className="speaker" id="sp3384"><span className="persName">Mr. BLAKEY</span>—</div>
              <p className="p-in-sp">
                Mr. Chairman, I have an amendment which I desire to offer. I move to strike out in the tenth and eleventh lines the words "to be appointed by the respective Conventions thereof," and insert these words, "corresponding in number to the number of electors to <PageNumber num={663} /> which the said States are respectively entitled in a Presidential
                election; and Virginia requests that the said delegates shall be elected by the people of said States at elections to be held in their respective electoral districts; and in said Congress each State shall cast the same number of votes that she now casts in the Congress of the United States." If this amendment shall be adopted, it will be followed up by another, directing delegates to
                that Congress, to be elected by the people of Virginia upon the same principle.
              </p>
              <p><span className="persName">The CHAIRMAN</span> propounded the question to the House.</p>
              <div className="speaker" id="sp3385"><span className="persName">Mr. BLAKEY</span>—</div>
              <p className="p-in-sp">
                Mr. Chairman, the distinguished member from Augusta county undertook to show that those who occupy the position that I do in the Convention, are inconsistent, in maintaining the propositions which we have voted for to-day. I think, sir, I can show that we are perfectly consistent. It is true that my constituents voted against referring the action of this Convention back to themselves
                and to the other people of Virginia, but that vote was not based upon the idea that they had the least distrust in themselves, or in their fellow citizens of other parts of Virginia. They voted in that way because they believed that the people of Virginia were capable of selecting representatives in whose discretion and wisdom and patriotism they could confide. It was not because of
                a distrust of themselves, but it was because of the confidence that they had in them.
              </p>
              <p className="p-in-sp">
                The circumstances under which they were placed, indicated, furthermore, that in case this Convention should decide that Virginia ought to dissolve her connection with the Federal Government, circumstances might arise that would imperiously demand the promptest action and the promptest preparations for defence, and for the maintenance of their rights; and they therefore confided this
                discretion to their delegates, feeling perfectly confident that if this necessity for immediate action should not exist, the delegates would refer this action back to them, notwithstanding they had voted against reference.
              </p>
              <p className="p-in-sp">
                But, sir, what is the position of the gentleman, occupied upon that question? It was a perfectly safe game, if I might so characterize it. It was a perfectly safe line of policy for him, and those who entertained his views, to have pursued. If a line of policy different from their own should be carried out in the Convention, then they will have "another deal of the cards," and they
                could come again before the people. But if the line of policy which he indicated should be adopted, there was no "new shuffle" it was all that he and those who acted with him desired it to be. I submit that the amendments which I propose, and the amendments of a like character for which we have <PageNumber num={664} /> voted here to-day, are very
                different. The question is, have we confidence in the people to select fit and proper representatives; and if we have confidence in them, are we willing to confide that duty to them? Are we willing to put this power into the hands of the people, and to get representatives fresh from the people, who will represent the opinions and wishes of the people? If we are, then the way to do
                that is, when delegates are to be sent to a Congress, let those delegates be selected by the people themselves. If we appoint delegates, those delegates will represent OUR opinions. Now, sir, it may be true that the majority of this Convention represent the opinions of a majority of the people; but then, that may not be true; and the best way to ascertain the will of the people,
                fairly, clearly and distinctly, is to submit it to the people to elect their own representatives.
              </p>
              <p className="p-in-sp">
                The gentleman has also undertaken to make a fling at the Confederate States. I apprehend that, on the present occasion, we are not deciding upon the wisdom or folly of their action, in deciding this question. But, sir, I take up the gauntlet which the gentleman throws down. What was the condition of the Confederate States when they decided to dissolve their connection with the
                Federal Government? They did not know but that act would be immediately followed by war. They did not know what disturbing effects it would have upon trade. Every one felt and every one saw the necessity of having, and of immediately having, a thoroughly organized government, and under the pressing emergencies of the case they did as our fathers in the time of the revolution did.
                They proceeded promptly to form a government. Now they have organized a government, and they have built it upon principles which call forth the admiration of all of our people, or nearly all of them. They have acted with a degree of wisdom and discretion that has excited not only the admiration of our own people, but has excited the admiration of the world. They have excited the
                admiration of a people who are hostile to their institutions, and who have hitherto participated, to a large extent, in abusing and misrepresenting those institutions.
              </p>
              <p className="p-in-sp">
                But, sir, let us come to the proposition of the gentleman from Fauquier. What is proposed to be done? Why, sir, we submit certain propositions to the North, and if they are not accepted we are to have a National Congress, and that National Congress is to meet at Frankfort, Ky., or somewhere else, and proceed to form a Constitution, which Constitution, it is proposed, shall be adopted
                by the States North and South, including the Border slave States. This is to be done under the mighty influence of Virginia. Sir, it seems to me that gentlemen <PageNumber num={665} /> who advocate this plan, ought to cease to denounce secession; for, to carry out that plan, requires the most wholesale system of secession that I have ever heard
                suggested. First, the border slave States have to secede. Indeed every State that comes into this organization that is now in this Government, has to secede from this Government and come under the protection of this proposed Constitution. But that is not all. Every one of the Confederate States has again to secede from their Government and throw themselves under the shadow of this
                Constitution which is proposed to be made by the National Congress. Now, sir, think of it. Here are two Governments which claim to be stable. The people of the Confederate States claim to be perfectly satisfied with their Government. The people of this Confederacy North of Mason and Dixon's line are satisfied with this Government.
              </p>
              <p>The ten minutes to which the gentleman was entitled had here expired.</p>
              <p>The question was then taken upon the amendment to the amendment which was rejected.</p>
              <div className="speaker" id="sp3386"><span className="persName">Mr. GOODE</span>, of Mecklenburg—</div>
              <p className="p-in-sp">Mr. Chairman, I move to amend the amendment by striking out the word "Confederate" in the 13th line, and substituting therefor the word "Southern," so as to make that part read "Southern States" instead of "Confederate States."</p>
              <p>The Chairman propounded the amendment to the House.</p>
              <div className="speaker" id="sp3387"><span className="persName">Mr. GOODE</span>, of Mecklenburg—</div>
              <p className="p-in-sp">Mr. Chairman, I am frank to say, that I have proposed this amendment simply for the purpose of getting an opportunity of returning the fire made by the distinguished gentleman from Augusta [<span className="persName">Mr. BALDWIN</span>] , into the ranks of those with whom it is my pleasure to act upon this floor. That gentleman thinks that the jewel of consistency is not to be found among those of the secessionists upon this floor.</p>
              <div className="lg">
                <div className="l">"O wad some power the giftie gie us</div>
                <div className="l">To see ourselves as others see us?"</div>
              </div>
              <p className="p-in-sp">Had this rare gift been vouchsafed to that gentleman, very sure I am, that we should never have heard the word "consistency" from him upon this subject. That gentleman, and those with whom he acts upon this floor, seem to act upon the sentiment that</p>
              <div className="lg"><div className="l">"Beauty unadorned is adorned the most,"</div></div>
              <p className="p-in-sp">
                and they have thrown the jewel away. I propose to show that the secession party of Virginia in opposing the submission of the action of this Convention to a vote of the people, did so from no desire to disregard <PageNumber num={666} /> or to thwart the will of the people. I propose further to show that the gentleman from the county of Augusta, and
                those with whom he acts upon this floor, in refusing to submit to the people of Virginia, the election of the commissioners which they propose to send to a Border Conference, do desire to do it in wanton disregard of the wishes, and for the purpose of thwarting and overriding the will of the people.
              </p>
              <div className="speaker" id="sp3388"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">It is not in order to reflect on the motives of gentlemen.</p>
              <div className="speaker" id="sp3389"><span className="persName">Mr. GOODE</span>—</div>
              <p className="p-in-sp">I did not design to do so.</p>
              <div className="speaker" id="sp3390"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The chair thinks the gentleman's language approximates to it.</p>
              <div className="speaker" id="sp3391"><span className="persName">Mr. THOS. F. GOODE</span>—</div>
              <p className="p-in-sp">
                I have no design to reflect on that gentleman or any member on this floor. Under what circumstances, sir, did the Secession party of Virginia oppose the submission to the people of the action of this Convention? The naked issue to be submitted to the people, was Union or Disunion. We supposed it was as competent for the people to declare, and that they might as well declare in the
                election of delegates, which policy they would sanction. We saw that a revolution had been inaugurated, that there was every probability that it would culminate and burst upon us, that Virginia would be compelled to take sides in that great contest before there could be time to refer the action of the Convention to the people of Virginia. But, sir, under what circumstances is it that
                the majority on this floor ignore the will and the voice of the people of Virginia. It was proposed this morning by the gentleman from Princess Anne, that the people of Virginia should select from each of the thirteen Congressional districts one delegate, and that one should be selected from each section of the State, East and West of the Blue Ridge, to this border Conference. We
                were immediately told by the gentleman from Kanawha
                [<span className="persName">Mr. SUMMERS</span>] that he opposed that proposition for the reason that it was based on the basis of federal representation, on the three-fifths principle. To meet the views of Western gentlemen, we proposed to concede, and did, by the proposition of the gentleman from Charlotte [<span className="persName">Mr. BOULDIN</span>] , concede the election of representatives to the border Conference on the suffrage basis. And yet, sir, by Western gentlemen, by Union gentlemen on this floor, that proposition was voted down, and a purpose thereby manifested to ignore the will of the people in this matter.</p>
              <p className="p-in-sp">The gentleman from Augusta [<span className="persName">Mr. BALDWIN</span>]
                , told us that we had endorsed the action of the Confederate States, and, therefore, that it was with a bad grace that we demanded that the people should make this selection. In reply to the gentleman I tell him that he is among <PageNumber num={667} /> those who have denounced the action of the Confederate States, because they declined to do that which
                we now ask this Convention to do. I should think that the gentleman and those with whom he acts, were estopped from telling us that we had endorsed the action of those Confederate States. If the object of the gentlemen be not to ignore the will of the people I ask them to tell me for what reason they refuse to allow the people of Virginia to select their representatives to this
                border Conference instead of this Convention selecting them?
              </p>
              <p className="p-in-sp">
                While, sir, we were before the people in the canvass for the election of delegates to this Convention, a peace Congress was inaugurated at Washington, unfortunately, as I believe, for the interests and honor of Virginia. We, whose constitutional rights had been spurned and trampled in the dust in the halls of national legislation and by the action of Northern States, were required to
                go, as humble suppliants, to a Peace Congress for our constitutional rights. The people of Virginia, in a spirit of patriotic devotion to this Union for which they had sacrified so much, still clinging to the hope that concession might be made which would enable us to remain members of this Confederacy with some sense of self respect, sent gentlemen here pledged to the preservation
                of the Union, if possible, consistently with the rights and honor of Virginia.
              </p>
              <div className="speaker" id="sp3392"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The gentleman's time is exhausted.</p>
              <div className="speaker" id="sp3393"><span className="persName">Mr. BLAKEY</span>—</div>
              <p className="p-in-sp">
                Mr. Chairman, when I was on the floor just now, I undertook to show that by the amendment of the gentleman from Fauquier, it was expected that the influence of Virginia would be sufficient to induce all the States, belonging to this Black Republican Confederacy as well as all of those that belong to the Confederate States, to secede from their respective Governments and enter upon
                the field of uncertainty in the organization of a new Government. To accomplish all this, the influence of Virginia must be potent indeed. Now, Mr. Chairman I will not undertake to depreciate the influence of Virginia, but I respectfully submit to the consideration of this Committee, whether that expectation is not as baseless as a vision, which expects, not only the border slave
                States, but, also, all or nearly all of the hireling States to secede from, and thus dissolve this Federal Government, and likewise all of the States of the Confederacy at Montgomery to secede from and dissolve their Government, with which they are highly pleased, for the purpose of uniting with Virginia in forming a new Federal Government? It is manifest that this
                wholesalesecession—this dissolution of the two Federal Governments, must take place before the proposed new Federal Government can be formed.
              </p>
              <PageNumber num={668} />
              <p className="p-in-sp">
                But the most extraordinary thing about this matter is, that these gentlemen seem to estimate the influence of Virginia as unquestionably sufficient to cause each one of the Confederate States to secede from and dissolve their Federal Government, and all of the States which compose this Federal Government—including the border slave States to secede from and dissolve it, and then to
                reunite all these discordant elements once more in a great, glorious, harmonious and prosperous Confederacy, under a constitution formed by the border slave States in a convention to which all the other States are only permitted to send lobby members. Yet when we propose that Virginia shall separate herself from this Federal Government, whose officers are pledged in the most emphatic
                manner so to administer it as to destroy her institutions and annihilate her civilization—and to unite her with the Confederate States, whose institutions and civilization, whose sympathies and interests are in unison with her own ; these same gentlemen earnestly object because they want the border slave States to go with her, and they are afraid she has not influence enough to
                induce them to follow her out of this hostile Black Republican government into the friendly and genial government of the Confederate States? How gentlemen can accord to Virginia so little influence in the one case, and so much in the other, is to me unaccountable, unless it be that there is in their bosoms feelings of repulsion to these Confederate States of the South, towards whom,
                it would seem to me, the heart of every Virginian should yearn with warmest sympathy.
              </p>
              <p>The question was taken on <span className="persName">Mr. GOODE</span>'S amendment to the amendment, and it was not agreed to.</p>
              <p>The question recurred on <span className="persName">Mr. SCOTT</span>'S amendment.</p>
              <p><span className="persName">Mr. HARVIE</span> called for the yeas and nays.</p>
              <p>The yeas and nays were ordered.</p>
              <p>The question was taken, and it was decided in the affirmative yeas 76, nays 42—as follows :</p>
              <p>YEAS-Messrs. Janney [President]
                , Armstrong, Aston, Baldwin, Baylor, Berlin, Blow, Boggess, Boyd, Brent, Brown, Burdett, Byrne, Campbell, Caperton, Carter, Chapman, Coffman, C. B. Conrad, R. Y. Conrad, Couch, Custis, Dent, Deskins, Dorman, Dulany, Early, Echols, French, Fugate, Gillespie, Gravely, Gray, E. B. Hall, Hammond, Haymond, Hoge, Hubbard, Hughes, Hull, Jackson, P. C. Johnston, Kilby, Lewis, McComas, J. B.
                Mallory, Marshall, Marr, Marye, Sr., Maslin, Masters, Moffett, Moore, Orrick, Osburn, Parks, Patrick, Pendleton, Porter, Price, Pugh, Rives, R. E. Scott, Sharp, Sitlington, Southall, Spurlock, C. J. Stuart, Summers, Tarr, Tayloe, Waller, White, Whitfield, Wickham, Willey-76.
              </p>
              <p>
                NAYS-Messrs. Ambler, Blakey, Boisseau, Borst, Bouldin, Bruce, Cabell, <PageNumber num={669} /> Cecil, Conn, Flournoy, Forbes, Garland, Graham, Gregory, Goggin, J. Goode, Jr., T. F. Goode, Hale, L. S. Hall, Harvie, Holcombe, Hunton, Isbell, Kent, Lawson, C. K. Mallory, Morris, Morton, Richardson, Sheffey, Slaughter, Speed, Strange, Sutherlin, Thornton,
                Tredway, R. H. Turner, Tyler, Williams, Wilson, Wise, Wysor-42.
              </p>
              <p>So the amendment was agreed to.</p>
              <p>During the vote,</p>
              <p><span className="persName">Mr. LEAKE</span> stated that he was paired off with <span className="persName">Mr. ADDISON HALL</span>. <span className="persName">Mr. STAPLES</span> stated that he was paired off with <span className="persName">Mr. NEBLETT</span>.</p>
              <p><span className="persName">Mr. BROWN</span> stated that <span className="persName">Mr. McGR</span>Ew was absent, waiting on a sick friend whose death was hourly expected.</p>
              <p>The vote was announced as above recorded.</p>
              <p>The Chair then stated the question to be upon concurring in the 14th resolution as amended.</p>
              <p>The question was taken, and the resolution, as amended, was adopted.</p>
              <div className="speaker" id="sp3394"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">My attention was drawn off for the moment. I desired to amend the original resolution.</p>
              <div className="speaker" id="sp3395"><span className="persName">Mr. SCOTT</span>, of Fauquier—</div>
              <p className="p-in-sp">You can offer your amendment in the Convention.</p>
              <div className="speaker" id="sp3396"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">The gentleman says I can offer my amendments in the Convention. I can have no chance to offer amendments in the Convention.</p>
              <div className="speaker" id="sp3397"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Chair propounded the question distinctly, and the resolution, as amended, was adopted by the Committee.</p>
              <div className="speaker" id="sp3398"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I was talking at the moment and did not observe that the Chair was stating the question.</p>
              <div className="speaker" id="sp3399"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Chair regrets it, but it is now too late to offer any amendment.</p>
              <div className="speaker" id="sp3400"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Well, sir, I am cut off, that is all.</p>
            </div>
            <div className="section" id="vsc1965.v3.2.10.2">
              <h3><span className="headingNumber">1.10.2. </span><span className="head">AMENDMENTS TO THE CONSTITUTION</span></h3>
              <p>The first division of the report of the Committee on Federal Relations having been completed, the Committee then proceeded to consider the amendments to the Constitution of the United States, constituting the second branch of the report of the Committee on Federal Relations.</p>
              <p>The first section was reported as follows :</p>
              <p>"ART. XIII-SECTION 1. In all the present territory of the United States, north of the parallel of thirty-six degrees and thirty minutes of north latitude, involuntary servitude, except in punishment of crime, is</p>
              <PageNumber num={670} />
              <p>
                prohibited. In all the present territory south of that line, involuntary servitude, as it now exists, shall remain, and shall not be changed; nor shall any law be passed by Congress or the Territorial Legislature to hinder or prevent the taking of persons held to service or labor, from any of the States of this Union to said Territory; nor to impair the rights arising from said
                relation; nor shall said rights be in any manner affected by any pre-existing law of Mexico; but the same shall be subject to judicial cognizance in the Federal courts, according to the remedies and practice of the common law. When any Territory north or south of said line, within such boundary as Congress may prescribe, shall contain a population equal to that required for a member
                of Congress, it shall, if its form of government be republican, be admitted into the Union on an equal footing with the original States, with or without involuntary servitude, as such Constitution of the State may provide. In all territory which may hereafter be acquired by the United States, involuntary servitude is prohibited, except for crime, north of the latitude of thirty-six
                degrees and thirty minutes; but shall not be prohibited by Congress, or any Territorial Legislature, south of said line."
              </p>
              <div className="speaker" id="sp3401"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                I shall propose a substitute for that entire section, and in this connection I will remark that the amendment which I propose is substantially a substitute for the first two sections of the report of the committee. I understand it is not technically in order to move to strike out, except the first section at this time, but I desire to consider the two sections of the committee's
                report in connection with my amendment.
              </p>
              <div className="speaker" id="sp3402"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Chair will consider the two sections as really, though not technically, under consideration. If the amendment of the gentleman from Princess Anne be agreed to, it will then be for the committee to strike out entirely the second section.</p>
              <div className="speaker" id="sp3403"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I move then to strike out the first section of the report, and to insert as follows :</p>
              <p className="p-in-sp">
                "In all the present territory of the United States, involuntary servitude, as it now exists, shall remain and shall not be changed; nor shall any law be passed by Congress or the territorial legislatures to hinder or prevent the taking of persons held to service or labor, from any of the States of this Union to said territory; nor to impair the rights arising from said relation; nor
                shall said rights be in any manner affected by any pre-existing law of Mexico in the part acquired from her; but the same shall be protected by necessary remedial laws as other rights, and be subject to judicial cognizance in the Federal Courts, according to existing laws, and to the remedies and practice of the common law
                <PageNumber num={671} /> except so far as they may be modified by the existing or territorial laws. And, when any territory, within such boundary as Congress may prescribe, shall contain a population equal to that required for a member of Congress, it shall, if its form of government be republican, be admitted into the Union on an equal footing with the
                original States, with or without involuntary servitude, as such Constitution of the State may provide. In all territory which may hereafter be acquired by the United States, involuntary servitude is prohibited, except for crime, north of thirty-six degrees thirty minutes; but shall not be prohibited by Congress or any territorial legislature, and should be protected by law south of
                that line."
              </p>
              <p className="p-in-sp">
                Now, Mr. Chairman, I am trying to bring this Committee back to the principles of the Dred Scott decision. The Committee on Federal Relations have seen fit to divide the subject of my proposition into two sections ; the first relating to the territory now in the possession of the Government and the second to the territory hereafter to be acquired. What do they propose in relation to
                the present territory by these two sections? That in all the territory North of 36 deg. 30 m. slavery shall be absolutely prohibited. South of that line, slavery is by a great deal of periphrasis and circumlocution permitted. That periphrasis and circumlocution I have adopted because the Committee have endorsed it. I accept it. I allow slavery also in my amendment in all the
                territories by the same circumlocution.
              </p>
              <p className="p-in-sp">
                Then, sir, when we come to the admission of States, while prohibiting slavery in all the territory North of 36 deg. 30 m., the Committee propose that the States to be formed out of that territory, may be admitted with slavery or without it, as the people may determine. Sir, I ask the friends of that institution here, how they can vote for such a proposition? To say that during the
                whole territorial existence you shall not bring any slave into that territory, that slavery shall be prohibited, but that, when the same territory comes to be admitted into the Union as States, they may be admitted with or without slavery, as they may elect, is nothing more than a mockery.
              </p>
              <p className="p-in-sp">Suppose you should pass a law that no wheat, corn, oats or pork, shall be admitted into the city market, would it not be idle then to say that when the market comes to be opened you may sell wheat, corn, oats, pork, or anything else?</p>
              <p className="p-in-sp">
                That, sir, is the position in which the Committee places the State of Virginia in regard to the institution of slavery in all the territories North of 36:30. Their proposition, after providing for the prohibition of slavery North of 36:30, while the territory maintains its territorial <PageNumber num={672} /> condition, is in these words : "When any
                territory North or South of said line, within such boundary as Congress may prescribe, shall contain a population equal to that required for a member of Congress, it shall, if its form of Government be Republican, be admitted into the Union on an equal footing with the original States, with or without involuntary servitude, as such Constitution may provide."
              </p>
              <p className="p-in-sp">
                Now, sir, is not this keeping the promise to the ear, and breaking it to the hope? You shall not come with your slaves into the territory North of 36:30. You shall not go to the territory now unoccupied, with a single negro; but when the time comes for you to form a State Constitution, and be admitted into the Union, you may come into the Union, with or without slavery, as you may
                provide. Can this Committee, can this Convention, can the representatives of the State of Virginia, vote for such a proposition as that? Will that hold water in a slaveholding community or before any pro-slavery people in the State of Virginia who have minds of their own —minds to think and minds to argue? What do I do? I propose that all the territory, North and South, shall stand,
                in fact, where the Dred Scott decision places it; where we will all go alike, North and South, with or without slaves, as we may desire, into the common territory. I give you the privilege of going there before-hand—before their constitution comes to be formed. The people can then elect whether they will have slaves or not. I admit their right, when they come to form their State
                Constitution, to decide the question for themselves. I admit the right of my neighbor, when he comes to form his organic law, to say whether he will entertain that institution or not. I care not how strong I may be for it. But he shall not say to me that I shall not go with my negro north of 36 deg. 30 min., and settle there before the time comes to form the Constitution. Mr.
                Chairman, under this provision of the Committee, you could not by any possibility have a solitary slave in any territory north of 36 30, when the people of that territory come to vote on their constitution; and there would not be a slave-holder there to vote whether they should tolerate slavery in that constitution or not. My proposition is that slavery shall not be prohibited by
                Congress or by the Territorial Legislature in any territory north or south.
              </p>
              <p className="p-in-sp">
                When I first saw this proposition, I was disposed to take it as to any future territory to be acquired. Why? Because all the territory that is in the future to be acquired lies south of 36 deg. 30 min., and it is enough for me in the future territory, if I shall be allowed to have slavery there. But when I come to look into the second section, I am
                <PageNumber num={673} /> cut off from latitude by making it impossible for me to admit another new slave State. Thus the 1st clause of the 1st section cuts me off from ever having a slave State north of 36 deg. 30 min., and the second section cuts me off from ever having a slave State again south of 36 deg. 30 min. Sir, this is a part of the plan of the
                Peace Conference.
              </p>
              <div className="speaker" id="sp3404"><span className="persName">Mr. R. Y. CONRAD</span>—</div>
              <p className="p-in-sp">In the 4th section of the report, which has already been adopted by this Committee, the following language is contained:</p>
              <p className="p-in-sp">"If the equal admission of slave labor and free labor into any Territory, excites unfriendly conflict between the systems, a fair partition of the Territories ought to be made between them, and each system ought to be protected within the limits assigned to it, by the laws necessary for its proper development."</p>
              <p className="p-in-sp">
                Now, sir, that has already been adopted by the Committee. The section for which the substitute is proposed simply carries out that provision by fixing the line regarded as a line of fair division of the territory. It has been admitted on all hands, that there is no probability that any slaveholder would ever voluntarily remove his slaves North of this line. We have fixed the old line
                of 36 30, as a line of fair division in the existing territory of the United States. This line of division is formed upon a fair and reasonable ground, as already affirmed by the vote here upon this whole section, that it constituted a fair division of the territory; and upon the further ground, well known to all, that it is impossible to mix these two systems of labor in the same
                territory.
              </p>
              <p className="p-in-sp">
                Sir, I am not afraid to admit that the North had the same right of emigration into these territories which we claim for ourselves; and if they are to enjoy that right at all, they must enjoy it as well as us for emigration for free labor. I believe that the Missouri Compromise was, even as an original proposition, always just and fair, and conceded only to each party what was its
                just right. I believe that it is the experience of the whole country that you cannot mix these two species of labor.
              </p>
              <p className="p-in-sp">
                Well, now, what does the gentleman propose? He says that his proposition is intended to carry out the Dred Scott decision, and to every slaveholder he asserts shall be reserved the right at all times to take slaves into all territory however far North. Now, sir, if this Committee is prepared to claim that as a right, and to claim that the Northern people shall incorporate an
                acknowledgment of that right into the constitution of the United States, why, I have only to say this, we must stand prepared to make a vain effort at adjustment. If the <PageNumber num={674} /> proposition of the gentleman from Princess Anne succeeds before this Committee, the consequence unquestionably, inevitably will be that we shall make an
                unreasonable and extravagant demand, and one to which the Northern people never can accede—certainly never can accede; and I desire that we shall take this ex pede Herculem as a fair sample of the amendments that are proposed by the gentleman from Princess Anne to each section of these proposed amendments, for I desire to avoid troubling the Committee by comments upon the successive
                amendments which will be offered. This, sir, is an amendment, which, however it may be intended, is undoubtedly calculated utterly to destroy any prospect of adjustment.
              </p>
              <p className="p-in-sp">
                Well, sir, in regard to the admission of States North of this line, who are without slavery, I suppose the gentleman understands why it was inserted. It was not in the expectation of the Committee that we were securing any right to erect slave States North of 36, 30. It was simply a declaration according to the Constitution of the United States, that every State had a right to
                regulate this subject according to its pleasure; and that, although slavery is prohibited in the territorial condition North of that line, yet, when that territory is erected into a State, and is about to be incorporated in the Union, as I said, it is for the people of that State to determine for themselves whether they will have slavery or not. The gentleman seems to think that it
                was put there in order to delude some one with the idea that we were taking the chance for slave States North of that line.
              </p>
              <p className="p-in-sp">
                It is possible in the future that we might have slave States North. It is by no means impossible that States North of the Ohio—old States, may hereafter introduce this species of labor as a matter of political economy among them. But, whether they may or not, it was imagined by no member of the Committee, that when that language was incorporated, we were securing any right or
                privilege. We merely wish to exclude the idea that that prohibition was intended to be of the character of the prohibition which was inserted in the old ordinance in relation to the North-western territory, which unconstitutionally undertook to prohibit States from being introduced into the Union as slave States.
              </p>
              <p className="p-in-sp">
                I, sir, do not think it necessary to go further into an examination of the substitute offered by the gentleman from Princess Anne. I have given a sufficient sample of it to cause members to beware how they adopt it. I have pointed out one feature which, even if introduced anywhere in this report, breaks up utterly the whole scheme of adjustment, because it places an impossibility
                there for the acceptance of the <PageNumber num={675} /> Northern people, and it is but a stepping-stone to the great end of disrupting this Union.
              </p>
              <p className="p-in-sp">Now, sir, I suppose that the Committee fairly intend to carry out the attempt at adjustment, and that they will not permit these propositions to be perverted into mere instruments for sealing the disruption of this Confederacy.</p>
              <div className="speaker" id="sp3405"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I beg the attention of this Committee and of the people of the State, to what we have heard from the Chairman of the Committee on Federal Relations, the gentleman from Frederick [<span className="persName">Mr. CONRAD</span>] . I know that in the 4th resolution he did promise one thing, a fair apportionment of the territory; but he said one thing then, and I perceive he meant another thing by this first section.</p>
              <p className="p-in-sp">This section does not carry out the promise made in the 4th resolution. But I am not going to consume my time upon that point.</p>
              <p className="p-in-sp">
                The gentleman says that it is impossible to mix slave labor and free labor in the same territory. Does not the gentleman allow them to mix in the territory south of 36 deg. 30 min. Does he forbid the free laborers of the north from going down to New Mexico, south of 36 deg. 30 min., there to form a phalanx as they did in Kansas, to vote down slavery. Don't they mix them south of 36
                30? and why can't they consent to mix them north of 36 30? Has he not them mixed now in Virginia and in the States of Kentucky, Tennessee and the other border States? Aye, sir, but for the fact that my friend from Orange
                [<span className="persName">Mr. MORTON</span>] , cut me off from an amendment of this 4th section, I would have called the attention of the people of this State to the array that is made in that 4th section, of free labor against slave labor. What is its language?</p>
              <p className="p-in-sp">
                "If the equal admission of slave labor"—not of freesoil States—"and free labor"—not freesoil States, on the principles of freesoil—"into any territory excites unfriendly conflict between the systems"—what systems? systems of free labor and of slave labor—"a fair partition of the territories ought to be made between them, and each system ought to be protected within the limits
                assigned to it by the laws necessary for its proper development."
              </p>
              <p className="p-in-sp">
                I say that this doctrine in that 4th section is the agrarian principle of anti-slavery. "That does array free labor against slave labor." Dare one of you to go before your constituents and maintain that doctrine? Go to Frederick and tell them—the non-slaveholders—that they cannot live in the same Territory or State in which slave labor exists! What do you tell them? You tell them
                that there must be a conflict in the States, as in the Territories, between the systems of free and slave labor; <PageNumber num={676} /> and, unconsciously to the Committee—for I have no idea that they designed anything wrong—they are promulgating this doctrine : that free labor and slave labor cannot exist together. And the gentleman from Frederick
                [<span className="persName">Mr. CONRAD</span>] , has taken the bull by the horns, and openly avowed that sentiment.</p>
              <div className="speaker" id="sp3406"><span className="persName">Mr. CONRAD</span>—</div>
              <p className="p-in-sp">Will the gentleman allow me to state that it was the admission of the emigration into new territories of the two sorts of labor, that I regarded as calculated to excite unfriendly conflict between the systems.</p>
              <div className="speaker" id="sp3407"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                Why talk about an equal emigration? How is it that you allow an equal emigration? You don't allow an equal emigration of the slaves North of 36:30. You do allow the emigration of free labor South of 36:30. I take him upon his own word, and giving all to one of emigration South, and none to the other of emigration. He says there is an equal partition; but the doctrine of the 4th
                section is, that the two systems of labor excite unfriendly conflict between each other. "If the equal admission of slave labor and free labor into any territory, excites unfriendly conflict between the systems, a fair partition of the territories ought to be made between them."
              </p>
              <p className="p-in-sp">It is an avowal that there is an unfriendly conflict—not between free and slave States, but between the systems of free and slave labor. That is what is avowed by the gentleman. But he says that he only means to apply that sentiment to the territories. He says it is impossible to mix them together in the territories.</p>
              <p className="p-in-sp">
                How came they to be mixed in Virginia? How have we lived for over two centuries, with the slave labor and free labor mixed? And yet have we a slaveholder from a slave county, who tells us that it is impossible to mix them in a territory North, but not impossible to allow the free labor to march South and mix there. Let me say to the gentleman, that if an adjustment is to be made by
                yielding such principles as these, and acknowledging the agrarianism that is contained in that 4th section, I would die in my tracks before ever I would make an adjustment that required that sacrifice. If we cannot carry slaves North—if it is not probable that we can carry them North, why, leave it to King Jack Frost, leave it to the law of climate, to God Almighty—leave it to the
                cold and ice-dagger.
              </p>
              <p className="p-in-sp">
                Leave it to him to go there, if he dares to go against an iceberg; leave it to him to go there, if he chooses to go there against the dominion of frost; but do not say that he shall not emigrate there with his property to the common territory, if he shall elect to encounter the frost. Let me tell my friend that this position of the Committee, in my
                <PageNumber num={677} /> opinion, that will yield a right like this, that will concede an agrarian principle like that contained in the 4th section, will tend more to disrupt the Union than anything else. But are we to abstain even from going to the extreme of disrupting the Union, if we cannot get the principles that justly belong to us?
              </p>
              <p>Here the hammer fell.</p>
              <div className="speaker" id="sp3408"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">I would be glad to have that section of the amendment of the gentleman from Princess Anne [<span className="persName">Mr. WISE</span>] , read which refers to future territory. If I understood his remarks, and the amendment as it was read, I think his amendment contemplates that in all territory hereafter acquired North of 36 deg. 30 min., involuntary servitude was to be prohibited.</p>
              <div className="speaker" id="sp3409"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Does the gentleman know of any territory on the continent of North America, now that we have reached the Pacific, North of 36 deg. 30 min., to be hereafter acquired?</p>
              <div className="speaker" id="sp3410"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">But I go upon the principle of equality, and it is for that purpose I move to amend the amendment by striking out the following words :</p>
              <p className="p-in-sp">"In all territory which may hereafter be acquired by the United States, involuntary servitude is prohibited, except for crime, North of 36 deg. 30 min., but shall not be prohibited by Congress or by any Territorial Legislature, and shall be protected by law South of that line."</p>
              <p className="p-in-sp">
                Whilst I am speaking to that motion, Mr. Chairman, it will be unnecessary for you to look at the watch, for I do not intend to consume my ten minutes. It is only to make my action consistent with the declarations that I not only made to my constituents, but with those which I have avowed upon this floor, as regards the perfect equality or right of the South in all territory which we
                now have, and which we may hereafter acquire. If I knew there was not one inch of territory North of 36 deg. 30 min., now or hereafter to be acquired, I would not give up the principle of perfect equality of the North with the South, that is established by the Dred Scott decision. And when we are struggling for principle and for Southern rights, I am not willing to surrender that
                principle as a matter of compromise.
              </p>
              <p className="p-in-sp">The gentleman from Princess Anne [<span className="persName">Mr. WISE</span>]
                , has justly said and argued with unanswerable ability, that so far as the territory we now have is concerned there should be perfect equality, and that a man from the North should be permitted to emigrate South of 36 deg. 30 min. with all his property, and that a man from the South should be permitted to emigrate North of 36 deg. 30 min., because of his property.
              </p>
              <PageNumber num={678} />
              <p className="p-in-sp">
                That is equality. If you find these two systems are incompatible and cannot be reconciled, that there is an irrepressible conflict between the two systems, then, I say, if you go for compromise, the just compromise is this. If the Southern man cannot go with all his property North of 36 deg. 30 min. the Northern man should not go South of 36 deg. 30 min. with any of his property.
                There is equality. But the compromise which the gentleman from Frederick
                [<span className="persName">Mr. CONRAD</span>] , proposes, and which he says is perfectly fair, to exclude the South from territory North of 36 deg. 30 min. and not to exclude the North from territory South of 36 deg. 30 min. would be giving to the North a monopoly of all the territory North of 36 deg. 30 min., whilst they have an equal participation in territory South of 36 deg. 30 min.</p>
              <div className="speaker" id="sp3411"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I regret that objection is made to my proposition by my friend from Orange [<span className="persName">Mr. MORTON</span>]
                . Let me call his attention to the first part of my proposition, that there is nothing left of territory for his objection to operate upon, at all. I tried to call his attention to it, before he made his remarks. I provide, in the first part of this proposition, that territory North and South shall be put upon the same footing of precise equality. Where does the territory of the
                United States extend to now? Does not all the Northern territory stop at the Pacific? Have you not acquired every foot of it? What more is to be acquired there? None! Does my friend expect to get anything from the Russian Bear? Does he expect to get anything from the grasp of the paw of the British Lion, north of us? No, sir! If we were to get any territory either from the Lion or
                Bear of England or Russia, could a man's negro live there, if he were to carry him there, north of the present boundary of the United States? Where, then, does the territory lie, that we are likely to acquire? All south of us. Does my friend expect to run the line of 36 30 bounding over the billows of the Pacific, until he comes to the Sandwich Islands? I believe even the Sandwich
                Islands are south of 36 30. Does he want to acquire more beyond?
              </p>
              <p className="p-in-sp">
                The argument of my friend, if he will permit me, to say so with all respect for him, reminds me of what Judge Letcher—I mean Letcher of Kentucky, used to say of us in Virginia, that we would "die of abstractions;" that we were not practical, that we were too apt to deal in abstractions. In the name of God, what Territory can we get beyond that which we already have acquired north of
                36 deg. 30 min? We may get Territory south of 36 deg. 30 min., Territory after Territory, and I believe we will not stop in the course of time short of the isthmus between the continents of North and South America. There is not one foot of Territory to be acquired north of <PageNumber num={679} /> 36 deg. 30 min? No, says the gentleman, I will die on
                the principle. This is carrying the principle where there is nothing for the principle to operate upon.
              </p>
              <div className="speaker" id="sp3412"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">
                It always pains me to differ from my distinguished friend from Princess Anne. If I fight with tenacity for principle it shews that I have kindred feeling, if not kindred blood, with himself. Permit me to say, Mr. Chairman, that whilst I do not look to extending the line of 36-30 beyond the American continent across the waters of the Pacific to the islands in that placid ocean, yet I
                have such a tenacity for this principle, when we are struggling for our rights, that if the whole territory North of 36-30, which by possibility we might acquire, was but an iceberg or a barren rock, I would not surrender the principle, because by surrendering it, I should be acknowledging an inequality, which, before God and my country, I never will. I never will acknowledge myself
                as not the equal of the Northern man upon every foot of soil, rock or ice that belongs to the American nation. How would it stand, I ask my friend from Princess Anne, under the Dred Scott decision, if we did acquire territory North of 36-30, if we purchase an interest in Canada or acquire an interest in Russian Territory?
              </p>
              <div className="speaker" id="sp3413"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I will tell him that there is a mode by which some territory might be acquired and that is by a volcano.</p>
              <div className="stage it">[Laughter.]</div>
              <div className="speaker" id="sp3414"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">I hardly think I would have given way to the gentlemen from Princess Anne and I understood that he was to answer my question by placing me over a volcano. And not being so accustomed to debate as the gentleman, I very much regret that he unnecessarily breaks the thread of my argument.</p>
              <p className="p-in-sp">
                But, Mr. Chairman, there is territory north of 36 30 that we might acquire. How would they stand provided the Dred Scott decision will remain as the law of the land, as every Southern man says it ought? We would have a right to go there. Although it might not be profitable to the slaveholders, yet I am not disposed to relinquish the right; for what concession does the North make to
                us? The territory that we are likely to acquire is in the South. That territory, under the Dred Scott decision, would be open to the Southern man, and that territory too, would be equally open to the Northern man, so that we are making a surrender of principle without any equivalent; and for that reason, Mr. Chairman, I repeat, that if it was a rock or an iceberg that we acquired, I
                would fight for my right upon that rock or iceberg, because by surrendering it I should feel that I was acknowledging myself as not entitled to equal rights in the National domain <PageNumber num={680} /> with Northern men, when by nature we are their equals—I will not say their superiors.
              </p>
              <p>Here the speaker's time expired.</p>
              <div className="speaker" id="sp3415"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The question will be upon the amendment to the amendment to strike out what the Chair has already reported.</p>
              <p>A MEMBER called for the ayes and noes, but the call was not sustained.</p>
              <p>The question was then put to the House, and the amendment to the amendment was rejected.</p>
              <p>The question then recurred upon the amendment offered by <span className="persName">Mr. WISE</span>, upon which the ayes and noes had been ordered.</p>
              <p>
                The vote was taken and the Committee refused to strike out and insert—ayes 28; noes 84 as follows :<span className="note" id="Note75"><span className="noteLabel">3</span>The Journal adds Morton to negative votes listed below, making 84.</span>
              </p>
              <p>YEAS-Messrs. Ambler, Blakey, Boisseau, Borst, Cabell, Cecil, Coffman, Conn, Forbes, Garland, Graham, Gregory, John Goode, Jr., T. F. Goode, Hale, L. S. Hall, Harvie, Hunton, Isbell, Kent, Morris, Richardson, Strange, R. H. Turner, Tyler, Williams, Wise, Wysor-28.</p>
              <p>NAYS-Messrs. Janney [President]
                , Armstrong, Aston, Baldwin, A. M. Barbour, Baylor, Berlin, Blow, Boggess, Bouldin, Boyd, Branch, Brent, Brown, Burdett, Burley, Byrne, Campbell, Carter, Chapman, C. B. Conrad, R. Y. Conrad, Couch, Custis, Dent, Deskins, Dorman, Early, Echols, Flournoy, French, Fugate, Gillespie, Gravely, Gray, Goggin, E. B. Hall, Hammond, Haymond, Hoge, Hubbard, Hughes, Hull, Jackson, P. C.
                Johnston, Kilby, Lawson, Lewis, McComas, J. B. Mallory, Marshall, Marr, Marye, Masters, Moffett, Moore, Os-burn, Parks, Patrick, Pendleton, Porter, Price, Pugh, Rives, Ro. E. Scott, Sharp, Sheffey, Sitlington, Slaughter, Southall, Spurlock, C. J. Stuart, Summers, Tarr, Tayloe, Thornton, Tredway, Waller, White, Whitfield, Wickham, Willey, Wilson-84.
              </p>
              <p>During the call of the roll <span className="persName">Mr. LEAKE</span> said he had paired off with <span className="persName">Mr. HALL</span> of Lancaster, and consequently he did not vote.</p>
              <div className="speaker" id="sp3416"><span className="persName">Mr. WILSON</span>—</div>
              <p className="p-in-sp">I hope the Committee will now rise.</p>
              <div className="speaker" id="sp3417"><span className="persName">Mr. AMBLER</span>—</div>
              <p className="p-in-sp">Will the gentleman withdraw the motion for a moment to enable me to present an amendment?</p>
              <p><span className="persName">Mr. WILSON</span> withdrew his motion.</p>
              <div className="speaker" id="sp3418"><span className="persName">Mr. AMBLER</span>—</div>
              <p className="p-in-sp">Mr. Chairman, I hold in my hand an amendment which contains, as I think, the true principles involved here, and which I believe would be endorsed by a majority of the people of Virginia. I will read it by way of argument. I move to strike out the first section, and insert the following:</p>
              <p className="p-in-sp">
                "Congress shall have power to legislate and provide forms of Government for the inhabitants of all Territories belonging to the United States, and may permit them at such times and in such manner <PageNumber num={681} /> as it may by law provide, to form States and be admitted into the Union. Citizens and inhabitants of the several States and
                Territories shall have the right to take into any of the Territories persons held to service or labor, under the laws of the States or Territories whence they are removed ; and all rights of property in such persons, or in their service or labor as the same existed under the laws of the State or Territory prior to such removal, shall be recognized or protected by Congress and the
                Territorial Government. When any Territory within such boundary as Congress may prescribe, shall contain a population equal to that required for a member of Congress, it shall, if its form of government be republican, be admitted into the Union on an equal footing with the original States, with or without involuntary servitude as such constitution of the State may provide."
              </p>
              <div className="speaker" id="sp3419"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">I move that the Committee now rise, and that the amendment be printed.</p>
              <div className="speaker" id="sp3420"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Committee has no power to authorize anything to be printed.</p>
              <div className="speaker" id="sp3421"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">Well, sir, I move that the Committee now rise.</p>
              <div className="speaker" id="sp3422"><span className="persName">Mr. PUGH</span>—</div>
              <p className="p-in-sp">I call for the ayes and noes.</p>
              <p>
                The call was sustained. The vote stood ayes 47, noes 65, as follows :<span className="note" id="Note76"><span className="noteLabel">4</span>The Journal also lists 47 affirmative votes, but adds French to those noted below, while it omits Sitlington. In the Journal, Price and Sitlington are added to the negative votes as listed in the Proceedings.</span>
              </p>
              <p>
                YEAS-Messrs. Ambler, Blakey, Blow, Jr., Boisseau, Borst, Bouldin, Boyd, Cabell, Cecil, Coffman, Conn, Dorman, Garland, Gillespie, Graham, Gregory, Jr., Goggin, J. Goode, Jr., T. F. Goode, Hale, Harvie, Hughes, Hunton, Isbell, Kent, Lawson, Leake, Marr, Marye, Sr., Morris, Morton, Orrick, Richardson, Sheffey, Sitlington, Slaughter, Southall, Speed, Strange, Tayloe, Thornton, R. H.
                Turner, Tyler, Williams, Wilson, Wise, Wysor-47.
              </p>
              <p>NAYS-Messrs. Janney [President]
                , Armstrong, Aston, Baldwin, Baylor, Berlin, Boggess, Branch, Brent, Brown, Burdett, Burley, Byrne, Campbell, Carter, C. B. Conrad, R. Y. Conrad, Couch, Custis, Dent, Deskins, Early, Echols, Flournoy, Fugate, Gravely, Gray, E. B. Hall, Hammond, Haymond, Hoge, Hubbard, Hull, Jackson, P. C. Johnston, Kilby, Lewis, McComas, J. B. Mallory, Marshall, Masters, Moffett, Moore, Nelson,
                Osburn, Parks, Patrick, Pendleton, Porter, Pugh, Rives, R. E. Scott, Sharp, Spurlock, Staples, C. J. Stuart, Summers, Tarr, Waller, White, Whitfield, Wickham, Willey-65.
              </p>
              <p>So the Committee refused to rise.</p>
              <p>During the calling of the roll, <span className="persName">Mr. HALL</span>, of Wetzel, stated that he had paired off with <span className="persName">Mr. A. M. BARBOUR</span>.</p>
              <div className="speaker" id="sp3423"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The question recurs upon agreeing to the amendment of the gentleman from Louisa [<span className="persName">Mr. AMBLER</span>] .</p>
              <p><span className="persName">Mr. MORTON</span> called for the reporting of the amendment, and it was again reported.</p>
              <div className="speaker" id="sp3424"><span className="persName">Mr. AMBLER</span>—</div>
              <p className="p-in-sp">
                Mr. Chairman, as I perceive it is very evident that <PageNumber num={682} /> there is a disposition to deny to me a courtesy which I have never seen denied in any deliberative body in which I have ever been before, I will not press myself upon this body. On more than one occasion the same thing has occurred with me, and I shall certainly not press
                myself upon an unwilling audience. I ask the right, which I suppose will not be denied me, of having the ayes and noes recorded upon the proposition.
              </p>
              <div className="speaker" id="sp3425"><span className="persName">Mr. MOORE</span>—</div>
              <p className="p-in-sp">What is proposed to be stricken out?</p>
              <div className="speaker" id="sp3426"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The first amendment to the Constitution, proposed by the Committee of Twenty-one.</p>
              <div className="speaker" id="sp3427"><span className="persName">Mr. MOORE</span>—</div>
              <p className="p-in-sp">I call for a division of the question.</p>
              <div className="speaker" id="sp3428"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">
                I don't propose to say one word in regard to the amendment, but I desire to say on my own behalf, and on behalf of those around me, so far as I have any means of ascertaining their sentiments, that the gentleman from Louisa is laboring under a singular mistake, if he supposes that there is any disposition not to extend to him the courtesy of a hearing, or any other courtesy whatever.
              </p>
              <p className="p-in-sp">
                We are anxious to vote, and desire that there shall be as little delay as possible. But I say to the gentleman that I think there is certainly no disposition upon the part of any one to withhold from him any personal courtesy. I hope he will understand me distinctly, as speaking for myself and, I think, as speaking for all in this body with whom I act, when I say that there is no
                disposition to withhold from him that courtesy to which his own courteous demeanor to others entitles him.
              </p>
              <p>The question was taken on the motion to strike out, and it was decided in the negative—yeas 26; nays 80, as follows :</p>
              <p>AYES-Messrs. Ambler, Blakey, Boisseau, Borst, Cabell, Conn, Harvie, Hun-ton, Isbell, Morris, Morton, Richardson, Garland, Graham, Gregory, Jr., Goggin, John Goode, Jr., Thomas F. Goode, Hale, Speed, Strange, Thornton, Turner, Tyler, Wise, Wysor-26.</p>
              <p>NAYS-Messrs. Janney [President]
                , Armstrong, Aston, Baldwin, Baylor, Berlin, Blow, Jr., Boggess, Bouldin, Boyd, Branch, Brent, Brown, Burdett, Burley, Byrne, Campbell, Carter, Chapman, Coffman, C. B. Conrad, Robert Y. Conrad, Couch, Custis, Dent, Deskins, Dorman, Early, Echols, Flournoy, Forbes, French, Fugate, Gillespie, Gravely, Gray, Ephraim B. Hall, Hammond, Haymond, Hoge, Hubbard, Hughes, Hull, Jackson,
                Johnston, Lawson, Lewis, McComas, Mallory, Marshall, Marr, Marye, Sr., Masters, Moffett, McD. Moore, Osburn, Parks, Patrick, Pendleton, McC. Porter, Price, Pugh, Rives, Scott, Sharp, Sheffey, Sitlington, Slaughter, Southall, Spurlock, Stuart, Summers, Tarr, Tayloe, Waller, White, Whitfield, Wickham, Willey, Wilson-80.
              </p>
              <PageNumber num={683} />
              <p>So the Committee refused to strike out the first section.</p>
              <div className="speaker" id="sp3429"><span className="persName">Mr. DORMAN</span>—</div>
              <p className="p-in-sp">I know that several of my friends desire to offer amendments to this section, and unless it is the intention to remain here until 9 or 10 o'clock to night, I think we had better adjourn. I know that several gentlemen who are here, are remaining under much bodily suffering. I move that the Committee rise.</p>
              <div className="speaker" id="sp3430"><span className="persName">Mr. WHITE</span>—</div>
              <p className="p-in-sp">Upon that motion, I call the ayes and nays. The ayes and nays were ordered.</p>
              <p>The question was taken and it was decided in the negative—yeas 38, nays 55, as follows :</p>
              <p>YEAS-Messrs. Ambler, Blakey, Borst, Bouldin, Boyd, Cabell, Chapman, Coffman, Conn, Dent, Dorman, Flournoy, French, Garland, Gillespie, Graham, Goggin, J. Goode, Hammond, Harvie, Hunton, Isbell, Kent, Lawson, Leake, Marr, Marye, Sr., Morris, Morton, Richardson, Rives, Slaughter, Southall, Speed, Tayloe, Thornton, Tyler, Wise-38.</p>
              <p>NAYS-Messrs. Janney [President]
                , Armstrong, Aston, Baldwin, Baylor, Berlin, Boggess, Branch, Brent, Brown, Burley, Byrne, Campbell, Carter, C. B. Conrad, R. Y. Conrad, Couch, Custis, Deskins, Early, Fugate, Gravely, T. F. Goode, E. B. Hall, Haymond, Hoge, Hubbard, Hull, Jackson, P. C. Johnston, Lewis, McComas, J. B. Mallory, Marshall, Masters, Moffett, Osburn, Parks, Patrick, Porter, Price, Pugh, Sharp,
                Sitlington, Staples, Strange, Summers, Tarr, Waller, White, Whitfield, Wickham, Willey, Wilson, Wysor-55.
              </p>
              <p>So the Committee refused to rise.</p>
              <div className="speaker" id="sp3431"><span className="persName">Mr. STAPLES</span>—</div>
              <p className="p-in-sp">I move that the Committee rise.</p>
              <div className="speaker" id="sp3432"><span className="persName">Mr. BOYD</span>—</div>
              <p className="p-in-sp">I desire to offer an amendment, but if it is the wish of the Committee, I will not press it at this time.</p>
              <div className="speaker" id="sp3433"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Chair believes that under the practice of the House of Delegates in modern times, as well as under that of the House of Representatives, a motion to adjourn or for the Committee to rise, after having been once decided cannot be repeated until some business has intervened.</p>
              <div className="speaker" id="sp3434"><span className="persName">Mr. BOYD</span>—</div>
              <p className="p-in-sp">I move to amend by striking out the first section of the Committee's report, and substituting the following:</p>
              <p className="p-in-sp">
                "In all the present territory of the United States North of the parallel of 36:30 of North latitude, involuntary servitude, except in punishment of crime, is prohibited. In all the present territory of the United States South of said line of latitude, involuntary servitude or slavery of the African race is hereby recognized as existing, any law or usage to the contrary
                notwithstanding; and no law shall be framed by congress or by the territorial Legislature, to hinder or prevent the taking of persons held in slavery or involuntary servitude, from any of the States of this Union to said territory, nor to impair <PageNumber num={684} /> the rights arising from said relation; but the same shall be subject to judicial
                cognizance in the Federal courts, according to the remedies and practice of the common law ; and said relation shall be protected by all the departments of the territorial Government. When any territory North or south of said line, within such boundary as Congress may prescribe, shall contain a population equal to that required for a member of Congress, it shall, if its form of
                Government be republican, be admitted into the Union on an equal footing with the original States, with or without involuntary servitude, as such Constitution of the State may provide. In all territory which may hereafter be acquired by the United States, involuntary servitude is prohibited, except for crime, north of the latitude of thirty six degrees thirty minutes; but shall not
                be prohibited by Congress or any territorial Legislature South of said line."
              </p>
              <div className="speaker" id="sp3435"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">I now move that the Committee rise.</p>
              <div className="speaker" id="sp3436"><span className="persName">Mr. BORST</span>—</div>
              <p className="p-in-sp">Upon that motion I demand the ayes and noes.</p>
              <p>The ayes and noes were ordered.</p>
              <p>The question was taken and it was decided in the affirmative yeas 59, noes 37, as follows :</p>
              <p>YEAS-Messrs. Janney [President]
                , Ambler, Armstrong, Aston, Baldwin, Baylor, Blakey, Blow, Jr., Gregory, Jr., Goggin, John Goode, Jr., Hale, Hammond, Harvie, Hubbard, Hunton, Bouldin, Boyd, Branch, Brent, Burdett, Byrne, Cabell, Campbell, Chapman, Coffman, Conn, C. B. Conrad, Dent, Deskins, Echols, Flournoy, Forbes, French, Fugate, Garland, Graham, Gray, Isbell, P. C. Johnston, Kent, Leake, Marshall, Marye, Morris,
                Morton, Patrick, Pendleton, Richardson, Rives, Sharp, Sheffey, Speed, Tayloe, Thornton, Wickham, Willey, Wise and Wysor-59.
              </p>
              <p>NAYS-Messrs. Berlin, Boggess, Borst, Brown, Carter, Robert Y. Conrad, Couch, Custis, Dorman, Early, Gillespie, Gravely, Thomas F. Goode, Haymond, Hoge, Hughes, Hull, Jackson, Lewis, McComas, Mallory, Masters, Nelson, Osburn, Parks, Porter, Price, Pugh, Sitlington, Staples, Chapman J. Stuart, Strange, Summers, Tarr, Waller, White and Wilson-37.</p>
              <p>So the motion was agreed to. The Committee accordingly rose, and the President having resumed the chair, the Chairman [<span className="persName">Mr. PRICE</span>] , reported progress.</p>
            </div>
            <div className="section" id="vsc1965.v3.2.10.3">
              <h3><span className="headingNumber">1.10.3. </span><span className="head">PUBLIC MEETING AT CURDSVILLE</span></h3>
              <p><span className="persName">Mr. FORBES</span> presented the following proceedings of a meeting of citizens of Buckingham County, which were laid on the table and ordered to be printed :</p>
              <div className="floatingText">
                <div className="floatingText_body">
                  <p>
                    A large and enthusiastic meeting of the citizens of Curdsville and the vicinity, was convened at that place on Monday, the 1st of April, <PageNumber num={685} /> for the purpose of raising a secession pole, and expressing their detestation at the procrastination and submission to arrogance and tyranny which has been the policy of the majority of the
                    present State Convention.
                  </p>
                  <p>
                    Cap. James H. Grigg, Sr., was called to the Chair, and Wm. S. Wise appointed Secretary. Several speeches, burning with the true fire were delivered, everything tending to secession without delay. The orators declared their love for this, their native State, but clearly showed that longer alliance with Northern hypocrisy, deception and villainy, would only redound to our eternal
                    infamy and disgrace.
                  </p>
                  <p>The following gentleman were appointed a Committee to draft resolutions :</p>
                  <p>Dr. W. J. Eppes, Col. E. W. Hubard, Col. J. L. Hubard, T. G. Reynolds, and H. C. Thornton, who, after retiring, consulted and reported the following preamble and resolutions :</p>
                  <p>
                    Whereas, we regard the action of the Convention of Virginia as in derogation of the interest and honor of the State, and a wanton paltering with the just expectation of the people. And we being opposed to a border State Convention, and coinciding entirely in opinion with our representative, Col. W. W. Forbes, for immediate secession, do earnestly urge upon him to do all in his
                    power to have the question of secession submitted to the people, and that he oppose in every way possible the absurd and dangerous policy of a border State Convention, because we regard it but the last resort of those who would basely deliver Virginia bound hand and foot to the tender mercies of her enemies. Therefore,
                  </p>
                  <p>Resolved, That we are for the immediate secession of Virginia from union with the Northern Confederacy.</p>
                  <p>Resolved, That the proceedings of this meeting be forwarded to our representative, and by him be laid before the Convention.</p>
                  <p>Resolved, That we are opposed to the Convention taking a recess, but that they act finally and come home as private citizens.</p>
                  <p>Resolved, That a copy of these proceedings be sent to the Farmville "Journal" and Richmond papers for publication.</p>
                  <p>JAS. H. GRIGG, SR., Chairman</p>
                  <p>WM. S. WISE, Secretary</p>
                </div>
              </div>
              <p>The Convention then, at twenty-five minutes past 7 o'clock, adjourned.</p>
            </div>
          </div>
          <div className="day" id="vsc1965.v3.2.11">
            <PageNumber num={686} />
            <h2><span className="headingNumber">1.11. </span><span className="head">FIFTY-FIRST DAY</span></h2>
            <div className="dateline">Saturday, <span className="date">April 13</span></div>
            <p>The Convention met pursuant to adjournment at 10 o'clock, A. M. Prayer by the Rev. <span className="persName">Mr. BOSSERMAN</span>, of the Universalist Church.</p>
            <div className="section" id="vsc1965.v3.2.11.1">
              <h3><span className="headingNumber">1.11.1. </span><span className="head">AMENDMENTS TO THE REPORT</span></h3>
              <div className="speaker" id="sp3437"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I ask to have printed a substitute for the entire report of the Committee on Federal Relations, and to have it referred to the Committee of the Whole.</p>
              <p>It was so ordered.</p>
              <div className="speaker" id="sp3438"><span className="persName">Mr. SPEED</span>—</div>
              <p className="p-in-sp">I ask leave to present some amendments which I propose to offer in Committee of the Whole, to come in after the amendments to the Constitution of the United States which the Committee of the Whole have under consideration. I desire that they may be printed and referred to that Committee.</p>
              <p>It was so ordered.</p>
            </div>
            <div className="section" id="vsc1965.v3.2.11.2">
              <h3><span className="headingNumber">1.11.2. </span><span className="head">PETITIONS</span></h3>
              <p><span className="persName">Mr. PARKS</span> presented a petition of citizens of Grayson county for an ordinance of immediate secession, which was referred to the Committee on Federal Relations and ordered to be printed.</p>
              <p><span className="persName">Mr. CAMPBELL</span> presented petitions of citizens of Washington county, two in favor of, and one against an ordinance of immediate secession, which were referred to the Committee on Federal Relations and ordered to be printed.</p>
            </div>
            <div className="section" id="vsc1965.v3.2.11.3">
              <h3><span className="headingNumber">1.11.3. </span></h3>
              <p>ORDER OF BUSINESS</p>
              <p><span className="persName">The PRESIDENT</span> stated the first business in order to be the appeal from the decision of the Chair taken by <span className="persName">Mr. WISE</span> yesterday, ruling <span className="persName">Mr. PRICE</span>'S resolution to be properly before the Convention.</p>
              <div className="speaker" id="sp3439"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I withdraw the appeal.</p>
              <div className="speaker" id="sp3440"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The question first in order is the resolution of the gentleman from Greenbrier [<span className="persName">Mr. PRICE</span>] , as follows:</p>
              <p className="p-in-sp">Resolved, That the thirty-third rule of the Convention be and the same is hereby rescinded and while considering in Convention the report of the Committee on Federal Relations or any amendment thereto, no member shall be at liberty to speak more than ten minutes on any one subject.</p>
            </div>
            <div className="section" id="vsc1965.v3.2.11.4">
              <PageNumber num={687} />
              <h3><span className="headingNumber">1.11.4. </span><span className="head">CALL OF THE HOUSE</span></h3>
              <div className="speaker" id="sp3441"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I move that there be a call of the House. The House is too thin for the transaction of business.</p>
              <div className="speaker" id="sp3442"><span className="persName">Mr. PRICE</span>—</div>
              <p className="p-in-sp">I apprehend that if the yeas and nays are called on my resolution there will be enough of members present before the call is concluded.</p>
              <div className="speaker" id="sp3443"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I ask for a count.</p>
              <div className="speaker" id="sp3444"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">Does the gentleman withdraw his motion for a call of the House?</p>
              <div className="speaker" id="sp3445"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">No, sir.</p>
              <p>The question was taken on <span className="persName">Mr. WISE</span>'S motion, and it was agreed to. The roll was called, and the following members responded to their names.</p>
              <p>
                Messrs. Ambler, Armstrong, Aston, Baldwin, Alfred M. Barbour, James Barbour, Berlin, Blakey, Blow, Jr., Boggess, Borst, Boyd, Branch, Brent, Brown, Bruce, Burdett, Burley, Byrne, Cabell, Campbell, Carlile, Carter, Cecil, Chambliss, Chapman, Clemens, Coffman, Conn, C. B. Conrad, Robert Y. Conrad, Couch, Critcher, Custis, Dent, Deskins, Fugate, Garland, Gillespie, Graham, Gravely,
                Goggin, J. Goode, Jr., Thomas F. Goode, Hale, E. B. Hall, Hammond, Haymond, Holcombe, Holladay, Hubbard, Hughes, Hull, Hunton, Isbell, Jackson, P. C. Johnston, Kent, Lawson, Leake, Lewis, McComas, McGrew, Mallory, Marshall, Marr, Marye, Sr., Masters, Miller, Moffett, Morris, Morton, Moore, Nelson, Osburn, Parks, Patrick, Pendleton, Porter, Price, Pugh, Richardson, Rives, Sharp,
                Sheffey, Sitlington, Slaughter, Southall, Speed, Spurlock, Staples, C. J. Stuart, Strange, Tarr, Tayloe, R. H. Turner, Tyler, Waller, White, Whitfield, Wickham, Willey, Williams, Wilson, Wise, Wysor, John Janney
                [President] —Present 107.</p>
              <p><span className="persName">Mr. HALL</span> stated that <span className="persName">Mr. MCNEIL</span> was sick and had paired off. <span className="persName">Mr. GARLAND</span> stated that <span className="persName">Mr. CHAS. K. MALLORY</span> was sick and had paired off with <span className="persName">Mr. DULANY</span>.</p>
              <p>107 members having answered to their names,</p>
              <p><span className="persName">Mr. WISE</span> moved to dispense with all further proceedings under the call.</p>
              <p>The motion was agreed to.</p>
            </div>
            <div className="section" id="vsc1965.v3.2.11.5">
              <h3><span className="headingNumber">1.11.5. </span><span className="head">REGULATION OF DEBATE</span></h3>
              <div className="speaker" id="sp3446"><span className="persName">Mr. PRICE</span>—</div>
              <p className="p-in-sp">
                As there seems to be a misunderstanding about the import of my resolution, it is proper that I should state its character and effect. You will perceive, Mr. President, that the first clause of the resolution rescinds the 33rd rule. That rule admits a discussion of ten minutes by each member on a call for the previous question. My resolution leaves the call for the previous question
                as it is left by the <PageNumber num={688} /> manual, by the lex parlementaria, which requires the call to be seconded without debate.
              </p>
              <p className="p-in-sp">
                With regard to the latter clause of my resolution, it authorizes any member to speak for ten minutes. If, however, the Committee get impatient before the discussion terminates in that way, any member who gets the floor, may call the previous question, and if it be sustained, discussion is cut off. That is designed to be the effect of my resolution. But gentlemen say that that will
                allow speaking for ten times 152 minutes. That will not be its effect, because any member who may get the floor, can move the previous question, and if it be sustained, it will cut off all further debate. That is the effect of my resolution.
              </p>
              <div className="speaker" id="sp3447"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I believe it is near half past ten.</p>
              <div className="speaker" id="sp3448"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">It wants but two minutes of it.</p>
              <div className="speaker" id="sp3449"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Then we cannot debate this resolution.</p>
            </div>
            <div className="section" id="vsc1965.v3.2.11.6">
              <h3><span className="headingNumber">1.11.6. </span><span className="head">HOSTILITIES AT CHARLESTON</span></h3>
              <div className="speaker" id="sp3450"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair has received a communication from the Governor of the Commonwealth, which he desires to present to the Convention.</p>
              <p>The Clerk read as follows :</p>
              <div className="floatingText">
                <div className="floatingText_body">
                  <p>"CHARLESTON, S. C., April 12, 1861</p>
                  <p>"GOVERNOR LETCHER :</p>
                  <p>"Your telegram received. It is true, and still continues. No damage done to any one on our side, or to our works. Great damage to Fort Sumter. F. W. PICKENS."</p>
                </div>
              </div>
              <div className="speaker" id="sp3451"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                I should think now, that as war is flagrant, it should have some impression, and make a little change in our action. It seems that damage has been done to the property of the United States at Charleston, and I think that that is the best argument that can be offered against the adoption of the resolution. In amending this longa emendatio report, we are to be allowed, on substitutes
                that cannot be offered in Committee of the Whole, only ten minutes in the House. I have two alternative propositions which I asked leave, this morning, to have printed, and I want time to discuss them. The gentleman from Fauquier has had all the time he wanted. I have been fighting here in detail about two months, till I am nearly worn out. We are then to come into the House, and on
                a substitute to be offered for the whole report, I am to be cut down to ten minutes.
              </p>
              <PageNumber num={689} />
              <p className="p-in-sp">I hope, sir, that as war is now raging at Charleston, the Convention will extend the time for discussion on new propositions, which offer new phases and new aspects. I hope now that the fire is applied to the terrapin, he will crawl, at last, and that you will heat the poker till you fry the very fat off his vitals.</p>
              <div className="speaker" id="sp3452"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The hour having arrived for going into the Committee of the Whole, <span className="persName">Mr. SOUTHALL</span>, will please take the Chair.</p>
              <div className="speaker" id="sp3453"><span className="persName">Mr. AMBLER</span>—</div>
              <p className="p-in-sp">I beg leave to do now what I ought, in justice to myself, have done yesterday evening. When, after noticing what I considered a discourtesy shown to me, the gentleman from Augusta [<span className="persName">Mr. BALDWIN</span>]
                , made a most friendly and courteous reply, it was my duty to have met that gentleman in the spirit which he exhibited to me. I failed to do so because I was in that condition in which one is placed who believes himself discourteously treated by those with whom he acts, and by the Chair who represents those with whom he acts. To that gentleman I wish to extend my thanks for his
                kindness. I regret that I did not, at the time, meet it and him and those in whose name he spoke and who agree with him, in the spirit in which, in justice to him and to myself, I should have done so.
              </p>
              <div className="speaker" id="sp3454"><span className="persName">Mr. WHITFIELD</span>—</div>
              <p className="p-in-sp">
                Mr. Chairman, the Committee is well aware that I have not trespassed upon a moment of its time, but contented myself hitherto, with simply recording my vote. But, sir, I have ever occupied here the same position which I held before the people when they forced me to become a candidate for a seat in this Convention. I had been a Union man in one sense and a secessionist in one sense.
                No man loved this Union more than I did. No man appreciated more its blessings. I came here, sir, with a determination to use all honorable means and exhaust all constitutional remedies for the preservation of the interests of my section, and, failing to preserve these and obtain these remedies, to take this State out of the Union. I had supposed, sir, that the plan proposed by the
                majority here was in accordance with that idea. I had supposed, according to the effect of the eleventh resolution, that when the ultimatum of Virginia was presented and favorable responses not received within a reasonable time, that then the State would withdraw and resume the powers which she conferred upon the general Government. I did not apprehend, sir, that the amendment of the
                gentleman from Fauquier interfered at all with the line of policy indicated by the 11th resolution. I am now in some doubt in regard to it; and I wish to know from the gentleman from Fauquier if it is designed by the 11th resolution that we should withdraw from the Union in case of a refusal to accept our measures.
              </p>
              <PageNumber num={690} />
              <div className="speaker" id="sp3455"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Chair thinks that this is transcending the privilege of the gentleman. Debate may ensue upon this inquiry, and the gentleman's privilege only extends to the mere right of making a personal explanation.</p>
              <div className="speaker" id="sp3456"><span className="persName">Mr. WHITFIELD</span>—</div>
              <p className="p-in-sp">
                Then, sir, if that was not the purpose of that resolution, I have only to say, that I voted for that resolution under an entire misapprehension. I regarded that Congress as simply a meeting together of delegates of the different border States with a view of forming a Government for themselves—these States at the time, out of the Northern Confederacy. I supposed that our ultimatum
                would be submitted directly to the Northern people, without being altered or revised by any border State Conference, and when there was an unfavorable response returned, that this whole Convention was pledged to go out of this Confederacy, and that the next step was to convene this Congress for the purpose of forming a Government for themselves, or in conjunction with the Confederate
                States. I say if that has not been the purpose of that amendment, I voted for it under an entire misapprehension, and I feel it due to myself ; I feel it due to the position I now hold and ever expect to hold; I feel it due to those whom I represent here to make this correction. Having been confined to my bed from sickness, and being thus prevented from expressing my views, I feel it
                my duty to take the first occasion afforded me to make this explanation. Had I been here, sir, I would have opposed the appointment of this Commission to Washington. I have no faith in the responses that come from Lincoln. His policy is war, and we have no resort but to take the State out of the Union at once. We stand pledged to do so in the very contingency that has arisen. Every
                body here stands pledged by the action of this Convention, that when force was employed by the Federal Government, the time had arrived when Virginia should stand forth, resume her powers which she delegated to the Federal Government, and unite with the South in defence of Southern rights. I have no more to say at present. I will finish what I have to say at a future time.
              </p>
              <div className="speaker" id="sp3457"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">When the Committee rose on yesterday, it had under consideration the amendment submitted by the gentleman from Botetourt [<span className="persName">Mr. BOYD</span>] , as a substitute for the 1st article proposed as an amendment to the Constitution of the United States.</p>
              <p className="p-in-sp">The substitute reads :</p>
              <p className="p-in-sp">
                "In all the present territory of the United States, north of the parallel of thirty-six degrees and thirty minutes of north latitude, involuntary servitude, except in punishment of crime, is prohibited. In the present <PageNumber num={691} /> territory of the United States south of said line of latitude, involuntary servitude or slavery of the African
                race is hereby recognized as existing, any law or usage to the contrary notwithstanding; and no law shall be framed by Congress or by the Territorial Legislature, to hinder or prevent the taking of persons held in slavery or involuntary servitude, from any of the States of this Union to said territory, nor to impair the rights arising from said relation; but the same shall be subject
                to judicial cognizance in the Federal courts, according to the remedies and practice of the common law; and said relation shall be protected by all the departments of the territorial Government. When any territory north or south of said line, within such boundary as Congress may prescribe, shall contain a population equal to that required for a member of Congress, it shall, if its
                form of Government be republican, be admitted into the Union on an equal footing with the original States, with or without involuntary servitude, as such Constitution of the State may provide. In all territory which may hereafter be acquired by the United States, involuntary servitude is prohibited, except for crime, north of the latitude of thirty-six degrees thirty minutes; but
                shall not be prohibited by Congress or any territorial legislature south of said line."
              </p>
              <div className="speaker" id="sp3458"><span className="persName">Mr. BOYD</span>—</div>
              <p className="p-in-sp">I have no inclination to trespass on the time of the Committee. I will merely make a brief statement in order to call the attention of the Committee to the change which this substitute proposes to make in the 1st article as reported by the Committee.</p>
              <p className="p-in-sp">
                It will be perceived that the only material change made, is to substitute the words "African slavery," used by Mr. Crittenden in his proposition, for the terms used by the Committee. I have retained everything that was of value for the protection of slavery in the territory South of 36 deg. 30 min. that was in the section as reported by the Committee. My proposition simply presents
                the issue between the language employed by Mr. Crittenden in his proposition, and the language employed by the Committee in this section. It presents the issue between calling the right thing by the right name—"African slavery," and calling it by other terms not in common use, and which, however clear and satisfactory they may be to many of us here, are not well understood by the
                great man of the people. I think it important, sir, if we mean to make an adjustment that is to remove the distrust that prevails so generally in our slaveholding community, and restore that peace and tranquility which we are all so anxious to secure, that we should employ language in any amendment that this Committee may recommend and which may be adopted, that not
                <PageNumber num={692} /> only all men can understand, but that none can misunderstand. I therefore, prefer the language employed by Mr. Crittenden, who I have no doubt employed it after mature consideration, and in view of all the difficulties that might be presented either North or South in the way of its adoption.
              </p>
              <p className="p-in-sp">
                Again, sir, I cannot see any good objection to employing this language, which would be so much more satisfactory to the people throughout slaveholding communities, rather than the language employed in the report. Does any man undertake to sustain this first article upon any other ground than that it will establish African slavery South of 36 30? No. Will any man North, whatever his
                prejudices may be, will any man South, who is favorable to the adjustment of this question, undertake to say that he means by agreeing to this section to do anything else than establish slavery South of 36 30? If that be so, then why object to using that language which clearly and unequivocally expresses the intent of both parties?
              </p>
              <p className="p-in-sp">
                Again, we stand pledged by the 6th resolution, which this Convention almost unanimously adopted, to an earnest desire to re-establish this Union. How do we expect to re-establish it? Is it not important to this object that we should consult the prejudices and opinions of the seven States out of the Union, that we are seeking to bring back? Do we expect that they will concur in the
                use of these debatable terms, even should we obtain the proposed guarantees from the North, in a reasonable time?
              </p>
              <p className="p-in-sp">The Confederate States claim it as one of the improvements made by their Constitution upon the old one, that they have discarded, these terms of involuntary servitude and persons held to service, &amp;c., and substituted therefor slavery or African slavery.</p>
              <p className="p-in-sp">
                But there is another objection. I think it is the proper function of an article in a Constitution to effect by its own terms what it professes to accomplish. This section of the report does not do this. The substitute I offer does accomplish this object. What does this section state—it says "involuntary servitude as it now exists, shall remain and shall not be changed South of 36
                deg. 30 min." How does it now exist? Does this section tell you how it exists at this time? No. A slaveholder wishing to emigrate to any portion of the territory South of 36 deg. 30 min., and wishing to ascertain what his rights will be, cannot ascertain what they are by reference to this section. He must look to the territorial code of New Mexico—not the code as it now exists, but
                the code as it shall be, when this section may be incorporated in the <PageNumber num={693} /> Constitution—a code subject in the mean time to repeal or modification by the territorial Legislature or by Congress.
              </p>
              <div className="speaker" id="sp3459"><span className="persName">Mr. CONRAD</span>—</div>
              <p className="p-in-sp">
                If the only difference between the section and the proposed substitute was in the language and not in substance, I should not be disposed to trouble the Committee with any objections to the substitute. But it will be found, sir, I think—whilst the language in the amendment proposed by the Committee is clear and unequivocal and is not the language employed in the scheme of the Peace
                Conference at Washington, but, on the contrary, has been changed in regard to the description of slavery—materially changed so as to remove the only objections which have been made to Peace Conference propositions, that there is still another and a much more important difference between the proposition in the report of the Committee and the substitute, which I understand is nearly
                identical with the Crittenden resolutions—a much more important difference than any which has been discovered by the gentleman who offers the substitute; and to that alone I desire to call the attention of the Committee for a moment.
              </p>
              <p className="p-in-sp">The substitute provides that "involuntary servitude or slavery of the African race is hereby recognized as existing," &amp;c.—in other words, it proposes simply to recognize or establish by virtue of this provision, the institution of African slavery South of 36:30.</p>
              <p className="p-in-sp">
                It proposes to leave it to a mere general Constitutional recognition, without providing any law for its protection or the enforcement of the right. And it is to this that I desire to call the attention of the Committee particularly. It is a radical objection to the Crittenden proposition that it proposes simply to recognize by Constitutional provision, the existence of African
                slavery in a territory which may, and probably will be, governed by territorial authorities adverse to the institution of African slavery for years to come, and it provides no code of laws for the enforcement of these rights. Now, sir, we know not only that every State in this Union which has the institution of African slavery has, and must have, a code with minute and particular
                provisions for the enforcement and protection of the right, but without such code, the mere general recognition of the right would be of no avail, whatsoever; and any law must be a voluntary law, to protect it in this territory of New Mexico under the scheme of the substitute—a voluntary law to be enacted by these hostile territorial authorities. What does the report of the Committee
                do? The report of the Committee takes the institution as it now exists; says it shall remain and shall not be changed as it now exists; and the effect of that provision, <PageNumber num={694} /> sir, if it is adopted will be to incorporate into the Constitution of the United States a complete and perfect slave code. It will be found that the territorial
                government of New Mexico have already enacted a slave code. We know the fact that slavery now does exist in fact there; that slaves are held there by the adoption by the territorial legislature of the slave code of Mississippi, with all the privileges, remedies, rights and provisions of the slave code of the State of Mississippi, so that this provision that slavery as it now exists
                shall remain and not be changed in that territory of New Mexico, which is the whole territory of the United States South of the line of 36 deg. 30 min., takes the slave code of Mississippi and incorporates it into the Constitution of the United States for the protection of slavery South of that line.
              </p>
              <p className="p-in-sp">
                Then, sir, we have more than this general recognition, and that is the essential feature of difference between the substitute offered by the gentleman, to wit : the Crittenden proposition and the propositions of the Committee. But the gentleman speaks of the language being vague. He supposes that the language used here has been adopted to suit the tastes of the Northern people. Why,
                sir, if the gentleman will look for a moment at the language of the report of the Committee, he will find that the language has been entirely changed in that respect, and that precisely the same phraseology is used in regard to the prohibition North of the line of 36 deg. 30 min., as to the recognition South of that line—it is "involuntary servitude of persons held to labor." If
                gentlemen will only look to the force of that language, they will find that it is far more specific and far more clear than the more general use of the term "African slavery." Why, sir, what is African slavery? It differs in every one of the Southern States of this Union. African slavery is one thing in Missouri, it is another thing in Virginia, and another thing in Louisiana ; and
                you will find that every State has its peculiar kind of African slavery, so that the description by the terms "involuntary servitude" of persons held to labor, is the only clear generic description that can be used to ascertain with certainty the institution that is established. Some gentlemen have the idea that it might be proper to make provision that persons emigrating with their
                slaves from the slave States into this territory would carry with them for their protection the laws of the States from which they came. Why, sir, if that was the case, you would have a strange admixture of laws there. How could you ever enforce your rights? The relations between these two classes of persons are not the same, as I have already remarked, in any two of the slave States
                of this Union? Therefore, it is necessary, in order to give any effect or force to this constitutional <PageNumber num={695} /> amendment that we propose, that there should be, in some way indicated and adopted, some particular slave code of some slaveholding State in the Union; otherwise it would be an idle and perfectly vague amendment of the
                Constitution. As I said, it will be found upon examination that this provision, by its terms, actually adopts and incorporates into the Constitution of the United States the whole slave code of the State of Mississippi for the benefit of slave holders in that territory.
              </p>
              <p>Here the ten minutes expired.</p>
              <div className="speaker" id="sp3460"><span className="persName">Mr. BOYD</span>—</div>
              <p className="p-in-sp">
                I will say a word or two in reply. The particular point the gentleman from Frederick makes is, that the section as reported by the Committee of Twenty-one necessarily adopts the slave code that has been adopted by the Territorial Legislature of New Mexico. I do not see how this provision can necessarily have the effect of adopting and incorporating into the Constitution that code. It
                merely refers, in general terms to slavery as it now exists South of 36 deg. 30 min. Of course it would be necessary to refer to that law, to explain what sort of involuntary servitude this section of the Constitution had reference to. The substitute imposes upon the Territorial Legislature, upon all the departments of the Territorial Government, the obligations to protect slavery in
                that Territory. They cannot repeal any existing laws that may exist after this Constitution is adopted, nor can they, without violating the Constitution, fail to adopt suitable laws for protecting this property. It seems to me that one is as obligatory as the other. But suppose that construction to be true, that the laws now existing in New Mexico for the protection of slavery are
                incorporated into that section of the Constitution, and that it renders those laws irrepealable and unalterable. Would that be a recommendation? If it renders them irrepealable and unalterable, so as to prejudice slavery, would it not render them equally so as to any changes that might become necessary for the protection of slavery? I think it would. We have been legislating in
                Virginia, for the protection of slave property, for more than two centuries, and can any of us say that it would be safe to postpone, irrevocably, farther legislation upon that subject, even now, for a period of five years? Our laws now, in Virginia, are defective in many respects, and yet we are to make the first laws enacted in the territory, by territorial legislation,
                irrepealable—the only laws that the slaveholders going into that community have for the protection of their property. We attribute to this people a capacity and a perfection in law-making which we cannot bestow on ourselves.
              </p>
              <PageNumber num={696} />
              <p className="p-in-sp">I will not detain the Committee longer. I call for the yeas and nays.</p>
              <p>The yeas and nays were ordered.</p>
              <p>The question was then taken on <span className="persName">Mr. BOYD</span>'S amendment, and it was rejected—yeas 47, nays 69—as follows :</p>
              <p>
                YEAS-Messrs. Ambler, James Barbour, Blakey, Boisseau, Bouldin, Boyd, Bruce, Cabell, Cecil, Chambliss, Chapman, Coffman, Conn, Echols, Forbes, Garland, Graham, Goggin, John Goode, Jr., Thomas F. Goode, Hale, Cyrus Hall, L. S. Hall, Harvie, Holcombe, Hunton, Isbell, Kent, Lawson, Marr, Marye, Sr., Miller, Morris, Morton, Richardson, Wm. C. Scott, Sheffey, Slaughter, Speed, Staples,
                Strange, Tayloe, Tredway, R. H. Turner, Tyler, Williams and Wysor —47.
              </p>
              <p>NAYS-Messrs. John Janney [President]
                , Armstrong, Aston, Baldwin, Alfred M. Barbour, Baylor, Berlin, Blow, Jr., Boggess, Brent, Brown, Burley, Byrne, Campbell, Carlile, Carter, Clemens, C. B. Conrad, Robert Y. Conrad, Couch, Critcher, Custis, Dent, Deskins, Dorman, Early, French, Fugate, Gillespie, Gravely, E. B. Hall, Hammond, Haymond, Holladay, Hubbard, Hughes, Hull, J. J. Jackson, Peter C. Johnston, Lewis, McComas,
                James B. Mallory, Marshall, Maslin, Masters, Moffett, Moore, Nelson, Osburn, Parks, Patrick, Pendleton, Porter, Price, Pugh, Rives, Sharp, Sitlington, Southall, Spurlock, Chapman J. Stuart, Tarr, Waller, White, Whitfield, Wickham, Willey, Wilson and Wise-69.
              </p>
              <p><span className="persName">Mr. LEAKE</span> stated that he had paired off on all questions connected with the report under consideration, with <span className="persName">Mr. HALL</span>, of Lancaster.</p>
              <div className="speaker" id="sp3461"><span className="persName">Mr. HALL</span>, of Wetzel—</div>
              <p className="p-in-sp">I propose to offer an amendment to the first article of the Committee's report of the proposed amendments to the Constitution, and if my amendment shall be adopted, I presume it will supersede the necessity of doing anything more with the report. By way of argument I will read the amendment.</p>
              <div className="speaker" id="sp3462"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">Does the gentleman propose his amendment as a substitute for the article under consideration?</p>
              <div className="speaker" id="sp3463"><span className="persName">Mr. HALL</span>—</div>
              <p className="p-in-sp">Yes, sir. The amendment which I offer, reads as follows:</p>
              <p className="p-in-sp">
                "Resolved, That the Committee on Federal Relations be instructed to report an ordinance resuming the powers delegated by Virginia to the Federal Government, and to make provision for submitting the same to the qualified voters of the Commonwealth, for their adoption or rejection; and in the event of the adoption of the ordinance by the qualified voters aforesaid, then the 22nd and
                23d sections of the 4th article of the Constitution of this Commonwealth, shall be so changed as to equalize taxes throughout the State."
              </p>
              <div className="speaker" id="sp3464"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The question will be upon the amendment to the amendment to strike out and insert.</p>
              <div className="speaker" id="sp3465"><span className="persName">Mr. DORMAN</span>—</div>
              <p className="p-in-sp">I rise to a point of order; the proposed amendment is not germane to the subject under consideration.</p>
              <PageNumber num={697} />
              <div className="speaker" id="sp3466"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Chair cannot tell how far it is germane to the subject. The Chair has been, so far, indulging other propositions made in the committee of the whole.</p>
              <div className="speaker" id="sp3467"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">Will the gentleman from Wetzel permit me to make an appeal to him? I know how true he is to the honor and interest of this Commonwealth, and I respectfully request the gentleman, in perfect kindness, in order to promote that honor and interest to withdraw his resolution for the present.</p>
              <div className="speaker" id="sp3468"><span className="persName">Mr. WILSON</span>—</div>
              <p className="p-in-sp">I rise to a point of order which is a privileged question.</p>
              <div className="speaker" id="sp3469"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Chair wishes to make a point of order himself, and if the gentleman will resume his seat, the Chair will state his point of order.</p>
              <div className="speaker" id="sp3470"><span className="persName">Mr. WILSON</span>—</div>
              <p className="p-in-sp">Will the Chair permit me to state my point of order?</p>
              <div className="speaker" id="sp3471"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">Certainly, sir.</p>
              <div className="speaker" id="sp3472"><span className="persName">Mr. WILSON</span>—</div>
              <p className="p-in-sp">My point of order is, that it is out of order in committee of the whole, to instruct a Committee of the House.</p>
              <div className="speaker" id="sp3473"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">
                The Chair was about to raise that question, not observing, when the resolution was reported, the peculiar character of that amendment. The peculiar character of this amendment is that the Committee on Federal Relations be instructed to make a report of a certain character. This Committee has no right to refer anything to a select Committee; and, therefore, the Chair decides the
                amendment, coming in this shape, to be out of order.
              </p>
              <div className="speaker" id="sp3474"><span className="persName">Mr. BOULDIN</span>—</div>
              <p className="p-in-sp">
                Mr. Chairman, I desire to offer a very short amendment to the first article of the proposed amendments to the Constitution; not changing its character, if my friend the Chairman of the Committee on Federal Relations is correct in his construction of it. But not being satisfied myself that he is correct, I desire to make plain what seems to me to be ambiguous; and when thus amended,
                it is my purpose to sustain that section of the report of the Committee. The amendment which I propose is this : In the fifth line of the first section, article XIII of the amendments to the Constitution, to strike out the words "as it now exists," and insert in lieu thereof "of the African race is allowed and hereby declared to exist, and shall not be abolished by any law of
                Congress or a Territorial Legislature; but the same, as now protected by the laws of the Territory of New Mexico, shall remain," &amp;c. So that the whole section as I propose to amend it, shall read :
              </p>
              <p className="p-in-sp">
                "Section 1. In all the present territory of the United States, North of the parallel of thirty-six degrees and thirty minutes of North latitude, involuntary servitude, except in punishment of crime, is prohibited. In <PageNumber num={698} /> all the present territory, South of that line, involuntary servitude of the African race is allowed, and hereby
                declared to exist, and shall not be abolished by any law of Congress or a Territorial Legislature; but the same, as now protected by the laws of the Territory of New Mexico, shall remain and shall not be changed; nor shall any law be passed by Congress or the Territorial Legislature to hinder or prevent the taking of persons held to service or labor, from any of the States of this
                Union to said territory ; nor to impair the rights arising from said relation; nor shall said rights be in any manner affected by any pre-existing law of Mexico; but the same shall be subject to judicial cognizance in the Federal Courts, according to the remedies and practice of the common law. When any Territory, North or South of said line, within such boundary as Congress may
                prescribe, shall contain a population equal to that required for a member of Congress, it shall, if its form of government be Republican, be admitted into the Union on an equal footing with the original States, with or without involuntary servitude, as such Constitution of the State may provide. In all Territory which may hereafter be acquired by the United States, involuntary
                servitude is prohibited, except for crime, North of the latitude of thirty-six degrees and thirty minutes; but shall not be prohibited by Congress or any Territorial legislature South of said line."
              </p>
              <p><span className="persName">The CHAIRMAN</span> propounded the amendment to the House.</p>
              <div className="speaker" id="sp3475"><span className="persName">Mr. BOULDIN</span>—</div>
              <p className="p-in-sp">
                Mr. Chairman, I understand, and I have no doubt that such is the fact, that it was the object of the Committee, by this section, to recognize as now existing the system of African slavery; and to recognize it precisely as it is existing and is now protected by the stringent slave code of the territory of New Mexico. I understand from the argument of my friend, the Chairman of that
                Committee, just now delivered that such was the purpose of the Committee, and, I repeat, that I have no doubt that such was their purpose ; but to my mind, they have not by this section attained that end with absolute certainty. And why do I say so? It seems to me that no person can for a moment shut his eyes to the fact, on looking at this section of the report, that you must go
                outside of it to ascertain whether African slavery is protected or not. What are we doing, Mr. Chairman? We are endeavoring now to make plain what was before controverted—to make clear and certain what was before obscure and uncertain, and thus to protect the rights of the Southern people under the Constitution of the United States. And what, under such circumstances, is our obvious
                duty? Evidently, to make the Constitution so plain that he <PageNumber num={699} /> who runs may read—to leave nothing to construction. Now, sir, I ask again if any member on this floor can read the first section of this article, and say that upon its face African slavery is recognized and protected as an existing institution in any territory of the
                United States. It seems to me that it cannot be so affirmed. There is not, I apprehend, a single member of this body who knows that African slavery does now exist in the territory of New Mexico; and unless it does now rightfully exist in that territory, no security is afforded the South by the amendment of the Committee. The section in question merely provides for the protection of
                slavery South of the line of 36 : 30, if it already exists, and "as it now exists." Its true object is, or ought to be, not merely to protect slavery if existing, but if need be, to establish it South of that line, and thus to explain and make clear rights under the Constitution, now supposed to be doubtful; and yet it neither affirms that slavery does or may exist, nor is it
                possible to ascertain from the section itself, whether slavery does or does not exist in any territory south of the proposed line. To ascertain that fact you must look outside the Committee's report. The amendments proposed by Virginia should not themselves be ambiguous, but should so explain or amend the Constitution, as to settle forever this much controverted question. I desire
                that the amendment itself, shall, in terms, effect this desirable end. I wish the bond to show on its face that this fatal question is forever closed. I do not wish by our own action to create a necessity of going outside the bond to make collateral enquiries. But, as the section now stands, an outside enquiry is not only suggested, but is rendered absolutely indispensable. It
                provides that "involuntary servitude as it now exists, shall remain," &amp;c., &amp;c. This amendment, it will be seen, does not affirm as a conclusion by law or fact, that involuntary servitude does exist. It merely preserves and protects the existing status. No member here can, by possibility, say from this section, taken by itself, that slavery is acknowledged to be an existing
                institution in the territory south of the proposed line. We are compelled to go beyond the amendment, and enquire first, if slavery does in fact exist south of the line? and if we find it existing, we must then enquire, how does it exist? Does it exist de jure, or de facto merely? If it exists de jure, by what laws does it exist? You perceive, Mr. Chairman, that all these questions,
                and others, must necessarily arise under this section, and they are such, as I, for one, am utterly unwilling to submit to the decision of Black Republican Courts and Judges. I desire that the bond itself, which we wish to take from those who have been forcing this issue upon us, to our <PageNumber num={700} /> trouble, for years past, shall set forth
                on its face, in unambiguous terms, that African slavery does now exist in all the territories of the United States, south of the proposed line. To attain that end, my amendment affirms that "involuntary servitude of the African race is allowed and hereby declared to exist," &amp;c., &amp;c. Adopt the amendment, and you need not look beyond the Constitution itself to establish the
                legal validity and existence of slavery in the territories.
              </p>
              <p className="p-in-sp">But the amendment goes a step further, and meets the argument of my friend from Frederick—the Chairman of the Committee on Federal relations—just now addressed to the Committee, in reply to the argument of my friend from Botetourt [<span className="persName">Mr. BOYD</span>] .</p>
              <p className="p-in-sp">
                It was urged by the gentleman from Frederick, that the words "as it now exists," proposed by me to be stricken out, have the effect of engrafting upon the Constitution the existing slave code of the Territory of New Mexico; and that slavery would be unprotected, if those words should be stricken out. I am not satisfied, Mr. Chairman, that the end desired is secured by the Committee's
                amendment. The conclusion is reached by implication and argument and not by direct affirmation in the amendment. To avoid any doubt, my amendment provides that African slavery, "as now protected by the laws of the Territory of New Mexico, shall remain," &amp;c., &amp;c. This makes the laws of New Mexico, on that subject, clearly a part of the Constitution, thus meeting the argument
                of my friend from Frederick, and making that embodiment of the existing Mexican<span className="note" id="Note77"><span className="noteLabel">1</span>Presumably this should be New Mexican.</span> law clear and unquestionable, which, by the Committee's report, is left to inference and implication.
              </p>
              <p className="p-in-sp">
                Mr. Chairman, I am aware, from my position on this floor, that any amendment offered by me is likely to be looked on with suspicion by a majority of this body. Allow me to say, however, that I came here earnestly desiring and solemnly pledged to make a sincere and honest effort to re-construct the ancient Union of our fathers, now broken and gone ; and although I have cast here, in
                deference to the well known will of my constituents, a vote for secession, yet I explained to the Convention that I did it as a compliment to my constituents, but that I stood here then, and should continue to stand fully at liberty to compare opinions with members around, and if possible to effect such an adjustment of our difficulties as might re-establish in its integrity the
                Union as it was. It may be said, and I fear it is true, that, as hostile guns are at this moment firing in the Southern Confederacy—guns which in all human probability are the inauguration of a dreadful civil war amongst us—a result so much to be desired is no longer possible. This I fear and believe is true. But so long as we
                <PageNumber num={701} /> are at work on these amendments I shall endeavor to make them just and safe to the South. My only object in offering the amendment is to make that clear and unequivocal which I regard as vague and ambiguous. I have not the time, Mr. Chairman, in what remains of my ten minutes to go into a legal argument to show the construction
                which would unquestionably be given to this section, as it now stands, by Black Republican Judges of the Federal Court and by Territorial authorities appointed by a Black Republican Administration. It is enough to say, that both judges and politicians of that party, have already committed themselves in platforms and judicial decisions to the opinion that no law of the Territory of
                New Mexico can establish slavery in that Territory; and that freedom and not slavery is the normal condition of all Territory of the United States, any law of the Territory to the contrary notwithstanding. When, therefore, you merely undertake to protect slavery as it exists, without affirming its legal existence, or recognizing the constitutional validity of the law under which its
                existence is claimed, you do nothing to commend its legal validity to the judgment of a Black Republican Judge. Therefore it is, Mr. Chairman, that I earnestly desire an amendment to the constitution which shall declare that slavery does exist in these Territories.
              </p>
              <p className="p-in-sp">
                There will then be no room for doubt or cavil, and no duty will be left to the territorial authorities, but to protect slavery as an existing institution. Let the Constitution recognize it, in unequivocal terms, as actually existing, and then, and then only, can you defy the ingenuity and sophistry of the Black Republican Judges whose privilege it will be to construe the Constitution
                and the laws.
              </p>
              <p className="p-in-sp">I hope the amendment will be adopted.</p>
              <div className="speaker" id="sp3476"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The question will be upon the amendment to the first section.</p>
              <p><span className="persName">Mr. GOODE</span>, of Mecklenburg called for the yeas and nays. The result of the vote was—yeas 46, nays 67, as follows :</p>
              <p>
                YEAS-Messrs. Ambler, James Barbour, Blakey, Boisseau, Bouldin, Boyd, Bruce, Cabell, Chambliss, Chapman, Conn, Echols, Forbes, Garland, Graham, Goggin, J. Goode, Jr., T. F. Goode, Hale, L. S. Hall, Harvie, Holcombe, Hunton, Isbell, Kent, Lawson, J. B. Mallory, Marye, Sr., Miller, Morris, Morton, Parks, Richardson, W. C. Scott, Sheffey, Slaughter, Southall, Speed, Staples, Strange,
                Thornton, Robert H. Turner, Tyler, Williams, Wise, Wysor-46.
              </p>
              <p>NAYS-Messrs. John Janney [President]
                , Armstrong, Aston, Baldwin, A. M. Barbour, Baylor, Berlin, Blow, Boggess, Brent, Brown, Burley, Byrne, Campbell, Carlile, Carter, Clemens, Coffman, C. B. Conrad, R. Y. Conrad, Couch, Critcher, Custis, Deskins, Dorman, Early, French, Fugate, Gillespie, Gravely, <PageNumber num={702} /> E. B. Hall, Hammond, Haymond, Hoge, Holladay, Hubbard, Hughes, Hull,
                Jackson, Peter C. Johnston, Lewis, McComas, Marshall, Maslin, Masters, Moffett, Moore, Osburn, Patrick, Pendleton, Porter, Price, Pugh, Rives, Sharp, Sitlington, Spurlock, C. J. Stuart, Summers, Tarr, Tayloe, Waller, White, Whitfield, Wickham, Willey, Wilson-67.
              </p>
              <p>So the question upon the amendment was decided in the negative. During the calling of the roll, <span className="persName">Mr. BURDETT</span> stated that he had paired off for the day, with <span className="persName">Mr. FLOURNOY</span>.</p>
              <div className="speaker" id="sp3477"><span className="persName">Mr. MORRIS</span>, of Caroline—</div>
              <p className="p-in-sp">I move to amend the first section in the 5th line. The clause now reads :</p>
              <p className="p-in-sp">"In all the present territory of the United States, involuntary servitude as it now exists, shall remain and shall not be changed," &amp;c.</p>
              <p className="p-in-sp">I propose to strike out the words "now exists," and to insert "existed on the first day of March, 1861."</p>
              <div className="speaker" id="sp3478"><span className="persName">Mr. CONRAD</span>, of Frederick—</div>
              <p className="p-in-sp">I wish, before the gentleman submits his remarks upon the amendment, to say merely that I shall myself vote for the amendment, although I do not regard it as necessary or changing substantially the section as it stands.</p>
              <div className="speaker" id="sp3479"><span className="persName">Mr. MORRIS</span>—</div>
              <p className="p-in-sp">
                I will simply state that I offered the amendment to make the section conform to what I understand to be the views of a majority of the Committee on Federal Relations. The object of the amendment is to recognize slavery in the territories, as it exists now. and not as it may exist three years hence when this amendment to the Constitution of the United States may be adopted. It may be
                a mere verbal amendment, but it is necessary to carry out the view of the Committee on Federal Relations, in reporting this section.
              </p>
              <p>The amendment was adopted—ayes 63, noes 36.</p>
              <p>The section as amended was then adopted by the Committee. The second section was reported as follows :</p>
              <p>
                "Section 2. No territory shall be acquired by the United States, except by discovery and for naval and commercial stations, depots and transit routes, without the concurrence of a majority of all the Senators from States which allow involuntary servitude, and a majority of all the Senators from States which prohibit that relation; nor shall territory be acquired by treaty, unless the
                votes of a majority of the Senators from each class of States hereinbefore mentioned be cast as a part of the two-third majority necessary to the ratification of such treaty."
              </p>
              <div className="speaker" id="sp3480"><span className="persName">Mr. TYLER</span>—</div>
              <p className="p-in-sp">
                I rise, with a great deal of reluctance, to propose a substitute for the section which has just been read. The principle of concurrent majorities, as I have at a former period stated, is one to which I should not object, if it were made applicable to all matters <PageNumber num={703} /> of important legislation, affecting the interests of both sections
                of the country. Nay, sir, such a proposition would meet my most hearty concurrence. But when applied only to the mere acquisition of territory, I have on another occasion expressed the opinion which I entertained, and which reflection has only confirmed me in, that, limited and restrained to future territory, it denies to the South the acquisition of one foot of new territory,
                without conferring a particle of benefit.
              </p>
              <div className="speaker" id="sp3481"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Chair will suggest to the gentleman from Charles City, that he propose his amendment before he proceeds with his remarks.</p>
              <div className="speaker" id="sp3482"><span className="persName">Mr. TYLER</span>—</div>
              <p className="p-in-sp">I will. I respectfully remark, however, that it has been the custom, in the assemblies in which I have served, to permit a gentleman first to explain his proposition, and then to submit it. I will, however, follow the suggestion of the Chair, and send up my amendment.</p>
              <p>The Secretary read the amendment, as follows :</p>
              <p>Strike out all of section 2, and insert as follows :</p>
              <p>
                "That Senators of the United States shall be divided into two classes, whereof the Senators chosen by the States, whose institutions forbid slavery shall compose one, and the Senators chosen by the States whose institutions admit slavery, shall compose the other, and on the passage of any bill or resolution having the force of law, and on all appointments to office, whenever the
                advice and consent of the Senate is required, upon the demand of a majority of the Senators composing either class, the vote shall be taken by classes, and the concurrence of both classes shall be necessary to pass such bill or resolution into a law or to confirm such appointment ; provided that when the vote of both classes shall be equally divided, the Vice President may give the
                casting vote."
              </p>
              <div className="speaker" id="sp3483"><span className="persName">Mr. TYLER</span>—</div>
              <p className="p-in-sp">
                I desire now, Mr. Chairman, to say that that proposition, if adopted, will place you in the position of self-protection—will give an effectual guarantee and secure the seven slaveholding States remaining in the Union in the attitude of perfect security. You want a check. Your mere naked declarations on parchment, as I have said before, amount to nothing, literally nothing, when you
                come to stand in the presence of a mere sectional majority. When did power ever respect right? When did right, however plain or palpable it may be, restrain the lawless advance of the footsteps of power? You may go into this association with any sort of constitutional amendment in the shape of paper guarantees, as they are called. You may fancy yourself
                <PageNumber num={704} /> secure, but you are not. You must have a positive check—a check in your own hand to guard your rights and interests. You had that check throughout the greater period of the existence of this Government. You had it for fully sixty years of its existence, and you found it in your equality in the Senate. Your equality in the Senate
                prevented any in-road upon your rights; and during almost the whole of that period you stood, mark you, almost with your arms folded, regardless of the ravings of fanatics without or attempted treachery within. You had the power of protecting yourselves. You asked nobody for protection, resting as you did upon your equality in the Senate.
              </p>
              <p className="p-in-sp">
                You have now lost equality in the Senate. You are outnumbered in the proportion of 19 to 8. What will you do? Can you go into an association without a positive, absolute check upon the possible lawlessness of that 19? You may set up the Idol of Liberty, and worship it in sincerity and truth; but if you go into that association manacled, in the power of that majority without
                restraint, are you safe—to that idol or the sacred altar at which you would kneel, safe? Is that the way in which you would secure your rights? Is it in that form or in assuming that attitude that you will answer to this noble minded people of Virginia, when they require you to render an account of the manner in which you have acquitted yourselves in this body?
              </p>
              <p className="p-in-sp">
                I know that the argument will come up against this proposition of the smallness of the number of the slave States. Sir, it is the very fact of your weakness that requires the greater guarantee of security to be found in this guarantee of power. If you were strong, if you stood 15 to 19 even, you might present some check; but what possible chance have you, if you come fettered into an
                association, or more properly, if you continue in an association with this Government with the preponderating majority of 19 to 8?
              </p>
              <p className="p-in-sp">
                Sir, I submit the proposition; I do not mean to enter largely into the discussion of it. I do not wish to occupy the time of the Convention. I merely throw out these hints; they are enough, I trust, to direct the attention of gentlemen to the subject. It is not the territorial question which alone interests us. The slavery question is one of far greater importance—and so also is the
                taxing power. These two are fundamental questions. Why, look at the tariff law which has been lately passed. If you had had this check, you would have produced moderation. The effect of checks and balances is to produce moderation upon the part of Government, to restrain it in its disposition to go too far. Can any government on earth be co-existent with freedom, without checks and
                balances? You must have checks and balances in <PageNumber num={705} /> order to preserve your government. Would you deny to your section the power with which the Supreme Court of the United States to even a greater extent is invested at this moment. It has the veto power in all its plenitude. The two Houses of Congress may pass acts of Congress, yet
                the Supreme Court will annul those acts if they be unconstitutional. There is a positive check then in that direction. You have a check also in the Presidental veto. But you have no check at all in your course of legislation. Why not have it?
              </p>
              <p className="p-in-sp">
                Mr. Chairman, I fear I have exhausted my ten minutes. The subject is most important; I present it in that view and in no factious, or even party spirit. Nothing of the sort. I would scorn to appear before you in any such spirit. But, sir, why is it that the Senate was constituted upon the basis on which it stands? Why is it that the little State of Rhode Island, and the little State
                of Delaware, have such potency and exercise such a check upon the great States? But do you not perceive that while the check of the small over the large States might be valuable in the ordinary operations of the Government, it has ceased to be valuable in the case of a sectional majority? It is gone. All the States of a section go together. Rhode Island ceases to act as a check upon
                New York, Ohio, or other great States. They all go together. They all have become sectional. The lees are coming up in this great sectional controversy. The framers of the Constitution sought, through the small States, to restrain the power of the large, by making them all perfectly equal in the Senate. In the name of all that is gracious, tell me why the same check should not now be
                provided to protect us eight against this large majority which now operate against us, and which is now daily and hourly increasing? I have aimed to guard this concurrent majority power from abuse. It requires the support of a sectional majority before it can be interposed, and would never be exerted except on the most important questions. It would deserve to be regarded as the great
                conservative power of the Constitution, and would be worth, in that light alone, more than all the paper guarantees which could be crowded into the Constitution.
              </p>
              <div className="speaker" id="sp3484"><span className="persName">Mr. BLAKEY</span>—</div>
              <p className="p-in-sp">I call for the yeas and nays upon the adoption of the amendment.</p>
              <p>The yeas and nays were ordered.</p>
              <div className="speaker" id="sp3485"><span className="persName">Mr. SUMMERS</span>—</div>
              <p className="p-in-sp">I may be under a misapprehension, but if I heard the reading of the amendment correctly it is applicable to all bills and resolutions of every kind and character that may be before Congress. I would enquire, as it is a substitute for the second section, if it is applicable to treaties also?</p>
              <PageNumber num={706} />
              <div className="speaker" id="sp3486"><span className="persName">Mr. TYLER</span>—</div>
              <p className="p-in-sp">I prefer to offer it as an addition to the second section.</p>
              <div className="speaker" id="sp3487"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">They are utterly irreconcilable.</p>
              <div className="speaker" id="sp3488"><span className="persName">Mr. TYLER</span>—</div>
              <p className="p-in-sp">I prefer to have it stand as I first offered it, as a substitute for the second section.</p>
              <div className="speaker" id="sp3489"><span className="persName">Mr. SUMMERS</span>—</div>
              <p className="p-in-sp">I desire to know whether this amendment is moved as a substitute for the second section?</p>
              <div className="speaker" id="sp3490"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">It is.</p>
              <div className="speaker" id="sp3491"><span className="persName">Mr. SUMMERS</span>—</div>
              <p className="p-in-sp">And if adopted, it takes the place of the second section?</p>
              <div className="speaker" id="sp3492"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">Yes, Sir.</p>
              <div className="speaker" id="sp3493"><span className="persName">Mr. SUMMERS</span>—</div>
              <p className="p-in-sp">
                Then the result of it would be to leave us no new provision in regard to the acquisition of territory, leaving it to rest under the present Constitutional provision. The second section, now moved to be stricken out, confines itself to the acquisition of future territory. That is the only subject embraced in it, and, with all due deference to the better opinion of the distinguished
                gentleman from Charles City, it occurs to me that it is the very best provision which the South can have or devise in regard to that great and important subject.
              </p>
              <p className="p-in-sp">
                I took occasion, in addressing some remarks to the Committee some time ago, to explain this proposition, and I know very well that I have not time now, under the limitation of ten minutes, to go into an examination of it. I understood the gentleman from Charles City, not only to admit but to claim as the very best guarantee for the South, the principle of concurring majorities. Why,
                then, should any one, holding these opinions, object to the application of this principle of concurring majorities to the acquisition power. I do not understand, however, that the gentleman does object to that as a qualification of the treaty-making power, but he desires to extend it to all legislation. He would incorporate into the government that idea of duality which originated
                with, and was recommended by, a distinguished Senator from South Carolina some years since.
              </p>
              <p className="p-in-sp">
                He proposes that no law of any kind, whether relating to the internal or external interests of the country, no appointment to office should be passed or confirmed except by a majority of Senators from each class of States. It strikes me, Mr. Chairman, that that would be converting a majority government into a minority government. These are clear and distinctive reasons why this
                should be applied to the treaty-making power in the acquisition of territory, but they do not apply to ordinary legislation.
              </p>
              <PageNumber num={707} />
              <p className="p-in-sp">
                The section, as presented by the Committee, has no relation to ordinary treaties, treaties of commerce and of alliance, but is restricted to treaties for the acquisition of territory, where invariably, the great sectional question which divides the country comes up, as to what shall be the future condition of that territory quoad this institution of slavery. This section enables that
                question to be settled in advance. The gentleman says it will operate to exclude acquisition. It will do so no more than under the present plan of acquiring by treaty, which requires a two-thirds vote in the Senate to ratify it. The great recommendation of the plan of acquisition presented by the second section, is that it is a concession of perfect equality between North and South,
                as to the great question which divides them. It is not objectionable when restricted to a purely sectional question; and I therefore approve of this section in the report of the Committee of 21. It is a concession to the weaker section of the country.
              </p>
              <p className="p-in-sp">
                It is for the slavery question, above all other questions, that we need this equality. The free states having advanced now to an extent, which throws us in the minority, and that advance still going on, we desire in regard to the acquisition of future territory, to restore this equality, and it is restored by the provision now under consideration. If the section be stricken out,
                there will be no special provision in regard to the acquiring power; but you will substitute for the present Constitutional provision, this idea of concurring majorities into all the ordinary legislation of the country. It will apply to every species of legislation. The smallest private bill then can not be passed without a majority vote of both sections.
              </p>
              <p className="p-in-sp">
                Sir, need we introduce this sectional feature in regard to any further ground than that embraced in the section reported by the Committee? I think not. I think we ought not to lose the opportunity of presenting that principle and applying it to the acquisition of territory merely. I hope it will not be the pleasure of the Committee to strike out the second section, which is, in my
                opinion, the very best principle that can be devised in regard to the acquisition of territory, securing us perfect equality in that regard, and to substitute for it an amendment which has no relation whatever to the acquisition of territory, but applies to all legislation. It is a matter of regret to me to differ from my distinguished friend from Charles City on any occasion, but on
                this amendment I must differ entirely from him. He seeks to introduce here a principle which changes the whole nature and frame work of our Government. His amendment converts the principle of the majority power into that of the minority power, and applies that <PageNumber num={708} /> power, not alone to sectional questions, but to all legislative
                questions.
              </p>
              <div className="speaker" id="sp3494"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The gentleman's time is exhausted.</p>
              <div className="speaker" id="sp3495"><span className="persName">Mr. TYLER</span>—</div>
              <p className="p-in-sp">
                It is a circumstance of deep and painful regret to me, to find that whenever a proposition is presented, however important to the interests of Virginia it may be, if it is possible to lug in the name of Mr. Calhoun or Mr. somebody else, as the putative author of the principle of the proposition it is most generally done. Is this done to awaken prejudices, or for what? If a reference
                to Mr. Calhoun is to produce the slightest prejudice on the mind of any human being in this convention—
              </p>
              <div className="speaker" id="sp3496"><span className="persName">Mr. SUMMERS</span>—</div>
              <p className="p-in-sp">I did not refer to Mr. Calhoun for the purpose of bringing prejudices on the gentleman's proposition, but simply to designate it as one unknown and unconsidered before his time.</p>
              <div className="speaker" id="sp3497"><span className="persName">Mr. TYLER</span>—</div>
              <p className="p-in-sp">
                I mean to state, sir, that the principle of concurring majorities, which is the principle of my proposition, is just as applicable to the gentleman's own proposition as it is to mine. Pray, sir, from whom did the gentleman get his idea of a concurrent majority in the matter of acquiring territory, if not from the same common source as myself? Is the dual power a lovely creation to be
                most carefully and anxiously guarded when it denies to you the acquisition of another foot of territory so as to enlarge the area of your eight slave States, but when you come to matters of general legislation, is it to be regarded as a hideous monster to frighten you from your propriety?
              </p>
              <p className="p-in-sp">I do not understand the honorable gentleman. He is the advocate of a dual Government applied to the acquisition of lands and there he stops. I am for carrying it out to matters vastly more material. I am for restraining the extravagant exercise of power on the part of majorities, and they sectional majorities.</p>
              <p className="p-in-sp">
                But the gentleman tells us that I am about to upset, by this amendment, the whole operation of Government throughout all its affairs. I have said, and there is not a man on this floor who does not know, that for 60 years this very dual power which is now condemned was exerted in the Government. While you had an equality of States, you had the negative power on your side, and they had
                the negative power on theirs; and it was not possible for either to injure the other. But a new condition of things has come about, and you must apply sufficient remedies to this condition of things or you perish. Here comes up a large sectional party which threatens to deal with you after its own conceptions of right, duty or interest—probably the last in preference to the other
                two. We must restrain it or surrender all. Tell me what restraint we are to impose on it, short of a guarantee of <PageNumber num={709} /> power on our own part. Here you stand deliberating day by day, framing some sort of expedient to lay before the State for her adoption or rejection, and come to no positive, definite course after all, of effectuality
                and power. And when I ask you simply to throw around us the panoply of protection to which we are entitled, you talk about changing the whole form of the Government.
              </p>
              <p className="p-in-sp">
                If the gentleman desires to preserve his duality as to the acquisition of territory, why can he not amend this resolution by an addition to it? But I beg to know whether the two-thirds rule as now applicable to the acquisition of territory, inasmuch as every treaty requires a two-thirds vote of the Senate, is not going quite as far away upon that subject as we ought to go?
              </p>
              <p className="p-in-sp">
                But I shall not occupy any more of the time of the Committee. I know very well the influence of my friend in this body. I speak almost by his courtesy here. He has raised his voice against my proposition, and I look upon it as if it were already voted down. I know I will be left as far in a minority here as you will be in the Senate of the United States when you get there, now that
                one of the leaders of the majority in this body has prophesied its overthrow. So let it be, sir, I have done my duty, and shall be content with the result.
              </p>
              <p><span className="persName">Mr. MORTON</span> offered, but subsequently withdrew—in deference to the opinion of friends—the following amendment to the amendment :</p>
              <p>"And moreover in the election of President and Vice-President of the United States, no person shall be declared as President or Vice-President who shall not obtain a majority of electors from the slave States and also a majority of the electors from the free States in the college of electors."</p>
              <div className="speaker" id="sp3498"><span className="persName">Mr. THORNTON</span>—</div>
              <p className="p-in-sp">I offer the following as an amendment to the amendment :</p>
              <p className="p-in-sp">
                "Nor shall any treaty be made unless the votes of a majority of senators from each class of States herein before mentioned, be cast as a part of the two-thirds majority necessary for the ratification of such treaty; nor shall any bill, order, resolution, or vote, which has been passed by Congress and disapproved by the President of the United States, take effect unless the votes of a
                majority of Senators from each class of States hereinbefore mentioned, be cast as a part of the two-thirds majority necessary for the re-passage of such bill, order, resolution, or vote."
              </p>
              <div className="speaker" id="sp3499"><span className="persName">Mr. TYLER</span>—</div>
              <p className="p-in-sp">I accept the amendment. Will the gentleman permit me to make a suggestion. He should have included in his proposition <PageNumber num={710} /> a provision for the acquisition of territory by joint resolution as well as by treaty.</p>
              <div className="speaker" id="sp3500"><span className="persName">Mr. THORNTON</span>—</div>
              <p className="p-in-sp">I had supposed that the amendment of the gentleman from Charles City [<span className="persName">Mr. TYLER</span>] , provided as well for the acquisition of territory by joint resolution as by treaty.</p>
              <p className="p-in-sp">It is with reluctance that I trespass upon the attention of the committee for the ten minutes allowed me; but believing honestly that this principle of concurring majorities is the only one upon which this Union can ever be re-constructed, I desire to say a few words in favor of the adoption of the amendment proposed by the gentleman from Charles City [<span className="persName">Mr. TYLER</span>] , amended as I have proposed.</p>
              <p className="p-in-sp">The distinguished gentleman from Kanawha [<span className="persName">Mr. SUMMERS</span>]
                told us that if we adopted this amendment we would change the entire principle of our government. We would convert a majority into a minority government. Why, Mr. Chairman, it is news to me that this is a majority government. How does it happen that the six New England States, with a population no larger than the single State of New York, can now give twelve votes in the Senate of
                the United States, against two upon the part of the State of New York. But, sir, this committee of twenty-one proposes to incorporate into the Constitution of the United States a provision going much farther than either the proposition of the gentleman from Charles City or the proposition which I have the honor to submit. What is it that they propose to do? That no one of the
                amendments to the Constitution they have proposed, "nor the third paragraph of the second section of the fiY st Article of the Constitution, nor the third paragraph of the second section of the fourth article thereof, shall be amended or abolished without the consent of all the States."
              </p>
              <p className="p-in-sp">
                They do not propose to give the power to concurring majorities, but they actually wish to place it in the organic law of this Confederacy of Confederacies, that these provisions are neither to be amended or abolished without the consent of every State in the Union. Sir, that some provisions of this character are necessary for the maintenance of the Union has appeared even to the
                intellect of the most determined member of the Black Republican party. Mr. Adams, I do not mean the elder Adams, I do not mean the late Adams, I mean the last Adams, who has been sent to represent us at the Court of St. James, introduced a proposition into the Congress of the United States, by which he desired it to be provided that a certain amendment should be incorporated into the
                Constitution of the United States that could neither be amended or abolished without the consent <PageNumber num={711} /> of all the States. Well now, sir, what is the principle upon which this amendment is proposed? The gentleman from Kanawha
                [<span className="persName">Mr. SUMMERS</span>] , tells you that it is well to let it operate upon questions dividing the sections. Are not all the questions of legislation now, questions that will divide the sections? Is it not a question of power between the North and the South? and, as the gentleman from Charles City [<span className="persName">Mr. TYLER</span>]
                , well asked, where in the history of the world has it appeared that power has ever ceased in its encroachment upon right? It was a remark, I believe, of John Wesley, that in the affairs of this world, men were saved not by faith, but by the want of it. I commend that piece of secular theology—if I may use the expression—to the consideration of the gentlemen of this Committee.
              </p>
              <p className="p-in-sp">
                I have heard from many of those on the Union side of this House, that the late victory of the Republican party was not due to the strong anti-slavery feeling prevalent in the North, but was due to a determination on the part of the Northern majority to take the power of this government into their hands. Now, sir, when do you expect to see this desire upon the part of this majority
                cease? There are their commercial and their manufacturing interests to be protected. There is a vast domain of territory that we have, which is to be carved out to provide homes for the homeless, and land for the landless. Upon these questions may they not do to the South fully as much injury? May they not do to the Southern section, which I propose to protect here, fully as much
                injury, by casting their sectional power against us, as they can upon the slave question? It is, sir, to avoid these evils—it is to put the South in a posture of defence—it is, to use the language of Madison in the Convention that framed the Constitution, to give her the Constitutional power of defence at whatever point she may be assailed—that I support the amendment of the
                gentleman from Charles City
                [<span className="persName">Mr. TYLER</span>] , and that I have offered my own amendment.</p>
              <p>
                The question was then taken by yeas and nays on striking out the section proposed to be amended—a division of the question being called for, and resulted—yeas 44, nays 69, as follows:<span className="note" id="Note78"
                ><span className="noteLabel">2</span>The Journal adds Coffman to the affirmative votes listed here, making a total of 44. The 71 negative votes listed here are the same as given in the Journal.</span
                >
              </p>
              <p>
                YEAS-Messrs. Ambler, J. Barbour, Blakey, Boisseau, Borst, Bouldin, Cabell, Cecil, Chapman, Conn, Echols, Garland, Graham, Goggin, J. Goode, T. F. Goode, Hale, L. S. Hall, Harvie, Holcombe, Hunton, Isbell, Kent, Lawson, J. B. Mallory, Marye, Miller, Morris, Morton, Parks, Richardson, W. C. Scott, Sheffey, Slaughter, Speed, Strange, Thornton, Tredway, R. H. Turner, Tyler, Williams,
                Wise, and Wysor-44.
              </p>
              <p>NAYS-Messrs. John Janney [President]
                , Armstrong, Aston, Baldwin, Baylor, Berlin, Blow, Boggess, Boyd, Branch, Brent, Brown, Bruce, Burley, Byrne, Campbell, Carlile, Carter, Clemens, C. B. Conrad, R. Y. Conrad, Couch, Critcher, <PageNumber num={712} /> Custis, Dent, Deskins, Dorman, Early, French, Fugate, Gillespie, Gravely, E. B. Hall, Hammond, Haymond, Hoge, Hubbard, Hughes, Hull,
                Jackson, P. C. Johnston, Lewis, McComas, Macfarland, Marshall, Maslin, Masters, Moffett, Moore, Nelson, Osburn, Patrick, Pendleton, Porter, Price, Pugh, Rives, Sharp, Sitlington, Southall, Spurlock, Staples, C. J. Stuart, Summers, Tarr, Tayloe, Waller, White, Whitfield, Wickham and Willey-69.
              </p>
              <p>So the motion to strike out was rejected.</p>
              <p><span className="persName">Mr. BURDETT</span>, stated, when his name was called, that he paired off with the gentleman from Halifax [<span className="persName">Mr. FLOURNOY</span>] .</p>
              <div className="speaker" id="sp3501"><span className="persName">Mr. KENT</span>—</div>
              <p className="p-in-sp">I beg leave to offer the following amendment. I propose to add the following at the end of the second section : "Nor shall foreign territory be admitted into the Union as a State unless the votes of a majority of the Senators from each class of States herein before mentioned, be cast in favor of such admission."</p>
              <p className="p-in-sp">
                Mr. Chairman, I arise not to detain the Committee with a speech, but merely to explain the relation which the amendment is intended to bear to the report of the Committee on Federal Relations. The word territory as used in the section seems to be restricted to territory already possessed by the United States or which may be hereafter acquired, either unsettled or with an insufficient
                population to entitle it to knock at the door of the Union for admission as a State. The amendment is intended to extend the meaning of the term to foreign territory or people, who with a population already sufficient to entitle them to a representative in Congress, would upon acquisition be entitled to come in as a State. The object of the section is to impose checks against a
                dominant sectional majority. Suppose, sir, Canada should apply for admission as a State into the Union—a supposition in my judgment, not improbable—at a future day, if the integrity of the Union is preserved—you would find the Northern majority contending that the term territory as used in the amended Constitution did not prevent its admission by a bare majority of both Houses of
                Congress, and they would refer to the admission of Texas by joint resolution as a precedent; and I think the argument would be unanswerable. If it is contended that we may wish to acquire territory further South, and that the operation of this amendment will tend to prevent it, I reply that the obstacles are no greater than would exist under the construction which the North will give
                to the word Territory. By their majority they may admit territory notwithstanding your objection; whilst you will be powerless even to acquire another foot of land. And thus the sectional power of the North will be increased. I hope it will be the pleasure of this committee <PageNumber num={713} /> to remove this ambiguity from the report of the
                Committee of twenty-one.
              </p>
              <p>The question was taken upon <span className="persName">Mr. KENT</span>'S amendment, and it was rejected.</p>
              <div className="speaker" id="sp3502"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I propose to strike out the whole of the 2nd section, and insert in lieu thereof the following</p>
              <p className="p-in-sp">"No territory shall be acquired by treaty, without the concurrence of a majority of all the Senators from the States which allow involuntary servitude, and a majority of all the Senators from States which prohibit that relation, and such concurrent majorities may advise and consent to such treaty."</p>
              <p className="p-in-sp">I move to strike out this part of the section, because in fact it amounts to nothing. In other words, the exception there is greater than the rule. The report says :</p>
              <p className="p-in-sp">"No territory shall be acquired by the United States, except by discovery, and for naval and commercial stations, depots and transit routes, without the concurrence of a majority of all the Senators from States which allow involuntary servitude, and a majority of all the Senators from States which prohibit that relation."</p>
              <p className="p-in-sp">
                Will you please tell me—gentlemen will think it over in their minds —what will be left? The exception is that you may acquire all territory by discovery. That embraces all the unknown lands. Then of all the known lands, you may take all you choose for naval and commercial stations. What limitation is that? How much do you require for naval stations? Is it one spot of space only that
                is required? Now, sir, under that construction I could require an immense range of forests for ship timber. Again—how much do you require for commercial stations? Will gentlemen tell me what limit there is to commercial stations? When you have got a point of space for commercial stations, how much back country will you require to conserve a commercial station? Again, you are allowed
                to acquire territory for depots. Depots for what? How many and where? There is no limit made here. Again, you are allowed to acquire, independent of this provision, transit routes. Now take the transit route which has been in dispute between Great Britain and the United States. One transit route, at least, we have been aiming at. How much are you to acquire —how broad, how narrow?
                You may, in the name of a transit route for a canal, acquire territory enough to make two, three, four or five States. Do you mean to say that you are only to take the ribbon of territory that is embraced along the line of transit? This section, in this respect, is perfectly indefinite. And for all these purposes you are <PageNumber num={714} /> to
                require a mere majority of Senators. Is there a man upon this floor who would throw any obstruction in the way of the United States in acquiring the transit route across the Isthmus? Why not strike out that part of the section I have indicated? It only embarrasses you.
              </p>
              <p className="p-in-sp">But in respect to the treaty-making power, there is a reason for concurring in the principle alluded to by the gentleman from Kanawha [<span className="persName">Mr. SUMMERS</span>] , and the gentleman from Charles City [Ex-President TYLER]
                . Why, I can never forget a scene which occurred upon the floor of the House of Representatives when carrying on the internecine war, that it was my fate to carry on with the old man eloquent, as he was called—John Quincy Adams. Some gentleman wanted to offer a resolution, that Congress had not the power to abolish slavery in the States. He denied the assertion that Congress had not
                the power to abolish slavery in the States. I asked him where was the power. He replied, it was the "Cudjo" power. It was most significant language. Who was Cudjo? A poor abused slave of Jamaica, oppressed by an inhuman English overseer, who fled to the corral of the mountains of Jamaica, there barricaded himself and defied all the power of England, until he brought the English power
                itself to terms; and what were the terms of the treaty? That he and his companions and their race, should forever be free. One of the most infamous acts that any civilized power on earth could commit, which Adams once denounced, and which I denounced, was the act of this super serviceable philanthropic power of England, when she broke that treaty ignominiously, and capitally executed
                some whom the treaty protected.
              </p>
              <p className="p-in-sp">
                You are required to look to the treaty making power. You are required to look to the war power. There is no guard in this report against the treaty making power. I cannot consent that you shall not only require the majority of both the slave States and the non-slaveholding States, but that that majority shall be superadded to the present requirement of the Constitution, a vote of
                two-thirds. It makes the acquisition by treaty of Territory too difficult—puts the majority too much in the power of the minority, and whenever both sections concur in acquiring territory, why not let them? I am sure that both my friend from Charles City
                [<span className="persName">Mr. TYLER</span>] , and my friend from Kanawha [<span className="persName">Mr. SUMMERS</span>]
                , cannot but see the fairness of that. When they can concur, why not let both minority and majority section do it? Why superadd the two-third power also? I see no necessity for superadding the two-third power. I would look to the treaty-making power after such a doctrine as this, that came from authority so high as an Ex-President, Mr. Adams, who was one of the most
                <PageNumber num={715} /> learned international lawyers that this country ever produced, and I would never leave it unguarded. It, more than any other, comes within the rule of the gentleman from Kanawha
                [<span className="persName">Mr. SUMMERS</span>]
                , that we ought to limit the power on purely sectional questions. But, sir, I have objection to the whole section. Sir, you must take one horn or the other of the dilemma. When you bring a people that are in confederacy to the attitude that either the majority power must be left dominant to oppress, or, as was said by the gentleman from Kanawha, you must have a minority actually
                wielding the government
              </p>
              <p className="p-in-sp">
                when these questions, on one side or the other, have to be proposed to us—what does it demonstrate? Sir, above anything else upon the face of the earth, it demonstrates the actual, the logical and the political necessity of separation. I concur with the gentleman from Kanawha, that the minority ought not to be allowed to govern the majority. I concur, certainly, with my own section,
                in swearing upon the altar that that majority shall never oppress me ; but I cannot relieve myself from the oppression by such checks and balances as will place the minority in the place of the majority.
              </p>
              <p>Here the ten minutes expired.</p>
              <div className="speaker" id="sp3503"><span className="persName">Mr. NELSON</span>—</div>
              <p className="p-in-sp">I am not altogether satisfied with the argument of the gentleman from Princess Anne. I rise, therefore, to object and in order that the gentleman from Princess Anne may finish his argument.</p>
              <div className="speaker" id="sp3504"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                I am much obliged to my friend for giving me the opportunity to continue my argument. I say that when you come to make provisions like this, you come to nothing else, nothing short of the knowledge that you come to the point of separation. You have got to take one of two alternatives and by proposing amendments like this, you admit the principle that you have got to take one of two
                alternatives, either to leave the majority with power to oppress the minority, or to clothe the minority with power to govern the majority. I will adopt neither alternative. When it comes to that, that the people cannot live together, without these two dire alternatives, I say it is demonstrated that they have come to the point of natural separation.
              </p>
              <p className="p-in-sp">
                Again, how unjust is this provision? Compare it with the map of our history. But yesterday, when it required but two-thirds to acquire territory, you acquired it by concurrence—you acquired it by treaty you reached from the Belize to New Orleans and up to the den of the white or grizzly bear youreached up to English and Russian territory in the North, and from the great valley of the
                Mississippi you <PageNumber num={716} /> acquired at once by treaty with France the territory of Louisiana. Since then, by treaty with Mexico, or rather by conquest—for the treaty only followed the conquest—you acquired another empire of territory, a large portion of which was immediately dedicated to free-soil by a flagrant usurpation of power and
                total disregard of national law, as well as constitutional obligation. Now, when the continent is filled up entirely in the North, unobstructed by any provisions like this; now that the only field for acquisition is South of 36 deg. 30 m., you propose to hinder, to obstruct and prevent us, by these double dualities, or rather by a duality with the two-third power added. As long as
                the North had territory to acquire, no such obstruction was put in the way. They were allowed to go as far as they could go, until they were stopped by the sea. Now that there is no more for them to acquire; now that all the field lies before the South to the Isthmus and beyond ( and, sir, I am one of those who believe that the principle of American republican liberty is strong
                enough not only to bear two American continents, but, in the course of time, a world on its shoulders) ; now that the North has no field of territory to be acquired, and the South has all the field for acquisition, we are to interpose, by these ingenious devices, against our rights. Will it strengthen you to acquire another piece of the territory of Mexico? Will it strengthen you to
                acquire the best transit route to the Pacific by annihilating that feeble power which we call Mexico, composed of a people which decency forbids me to characterize upon this floor? I have heard them well described, but in such language as not to be used here.
              </p>
              <p className="p-in-sp">
                Will it strengthen you to extend the power and dominion of the South, by going to Mexico where a yankee cannot work and live? I can tell you that from four years' experience, if you don't know it. Will it strengthen you, I ask, to go where none can live by manual labor but the negro? If it will, you will never get a two-thirds majority. You had better strike out the whole of the
                section; for this provision tends to the effect that we are making an acknowledgment in the most formal manner against our own interests at this moment whilst Fort Sumter is burning.
              </p>
              <div className="speaker" id="sp3505"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The question is upon the amendment to strike out and insert.</p>
              <p><span className="persName">Mr. CAMPBELL</span> called first for a division of the question, and then for the yeas and nays.</p>
              <p>The result of the vote stood, yeas 31, nays 77, as follows :</p>
              <PageNumber num={717} />
              <p>YEAS-Messrs. Ambler, Jas. Barbour, Blakey, Boisseau, Borst, Chapman, Conn, Echols, Forbes, Graham, Goggin, J. Goode, Jr., T. F. Goode, Hale, L. S. Hall, Holcombe, Hunton, Isbell, Kent, Lawson, Miller, Morris, Morton, Richardson, Sheffey, Speed, Strange, Thornton, Williams, Wise, Wysor-31.</p>
              <p>NAYS-Messrs. Janney [President]
                , Armstrong, Aston, Baldwin, A. M. Barbour, Baylor, Berlin, Blow, Boggess, Bouldin, Boyd, Branch, Brent, Brown, Bruce, Burley, Byrne, Cabell, Campbell, Carlile, Carter, Clemens, C. B. Conrad, R. Y. Conrad, Couch, Critcher, Custis, Dent, Deskins, Dorman, Early, French, Fugate, Garland, Gillespie, Gravely, Gray, E. B. Hall, Hammond, Haymond, Hoge, Hubbard, Hughes, Hull, Jackson, P. C.
                Johnston, Lewis, McComas, J. B. Mallory, Marshall, Marye, Maslin, Masters, Moffett, Nelson, Osburn, Patrick, Pendleton, Porter, Price, Pugh, Rives, Sharp, Sitlington, Slaughter, Southall, Spurlock, Staples, C. J. Stuart, Summers, Tarr, Tayloe, Waller, White, Wickham, Willey, Wilson-77.
              </p>
              <p>So the motion to strike out was decided in the negative.</p>
              <div className="speaker" id="sp3506"><span className="persName">Mr. BLOW</span>—</div>
              <p className="p-in-sp">Mr. Chairman, I hold in my hand a very small amendment, which I wish to offer. I move to insert after the word "routes," in the third line of the second section, the following words : "Nor shall any foreign State be annexed," so that the section will read as follows:</p>
              <p className="p-in-sp">
                "Section 2. No territory shall be acquired by the United States, except by discovery and for naval and commercial stations, depots and transit routes, nor shall any foreign State be annexed; without the concurrence of a majority of all the Senators from States which allow involuntary servitude, and a majority of all the Senators from States which prohibit that relation; nor shall
                territory be acquired by treaty, unless the votes of a majority of the Senators from each class of States hereinbefore mentioned be cast as a part of the two-third majority necessary to the ratification of such treaty."
              </p>
              <p>After the Chairman had reported the amendment to the House,</p>
              <div className="speaker" id="sp3507"><span className="persName">Mr. BLOW</span> said :</div>
              <p className="p-in-sp">Mr. Chairman, my purpose in offering this amendment, is to relieve the section of an ambiguity, which has occurred, not only to my mind, but to the minds of various other members of this Committee, and more especially as an amendment has already been offered by the gentleman from Wythe [<span className="persName">Mr. KENT</span>]
                , substantially the same. But an expression of opinion has been made that the word "territory," used in this section, does not cover the case of a foreign State, but simply refers to a territory in the form of land to be acquired either in the original way or by treaty. I propose, by offering this amendment, that the ambiguity shall be removed. It involves a principle of so much
                importance, I think, Mr. Chairman, that it should not be overlooked by this Committee. We are here engaged in the duties connected with the framing of a Constitution, under which, by possibility, we may live hereafter, and in framing that <PageNumber num={718} /> Constitution, whilst we are protecting ourselves against danger by the disturbance of the
                equilibrium of this government—the danger so far as the acquisition of other territory is concerned—we should be sufficiently anxious as to avoid the interpretation which, in a certain contingency, would justify the acquisition of Canada to the Confederacy.
              </p>
              <p className="p-in-sp">
                I know, sir, that there may be, from the phraseology of that section, a difference of opinion as to the interpretation to be put on it; but it does seem to me, Mr. Chairman, that in such an important question as this the matter should be left beyond dispute, and that no doubt as to the interpretation of that word "Territory" should be left; so as that at some future period it might
                be acquired, whilst we should be deprived, under the section as it now stands, of those advantages, or of any advantages that might accrue to us, that it should be left in the power of that fixed majority which we never can hope to overcome, to add another great State to the Confederacy, for the purpose of placing beyond a doubt the preponderance of the North.
              </p>
              <p className="p-in-sp">
                I hope, sir, that this amendment will prevail. I do not think that it is inconsistent with the wishes or the views of the Committee of twenty-one; and I would show that whilst our Northern friends are sufficiently acute in the interpretation of language, already their attention has been attracted by the peculiar phraseology of this section, and the amendment of the word "territory,"
                and that it has already been asserted in the Northern prints that this language contained in our resolution will not prevent the acquisition of Canada. Now, sir, they may be wrong in their technical construction of this sentence ; but I am not disposed to place in their hands this advantage, or that at some future time when it might be desirable to enable them to put their own
                interpretation upon this section to claim that the question was raised in the Virginia Convention, and was not determined by us. If we are disposed to place a check upon them in reference to the acquisition of a foreign State or Territory, I think this is the proper time and the proper occasion for so doing. It will not mar in any respect the tone or the intention of the section, and
                it will relieve us of that sophistical construction. I hope the amendment will prevail.
              </p>
              <div className="speaker" id="sp3508"><span className="persName">Mr. DORMAN</span>—</div>
              <p className="p-in-sp">
                Mr. Chairman, I believe the amendment reads, "nor shall any foreign State be annexed." I propose to offer a further amendment, by adding the words "or country" after "State," so as to make that part read, "nor shall any foreign State or country be annexed," &amp;c. I offer the amendment principally for the purpose of calling the attention of gentlemen to what I consider a vital,
                important <PageNumber num={719} /> principle of this amendment. I wish to do so because I am perfectly well aware that there is a large number of delegates here who are now so accustomed to amendments being offered which they expect to vote down, as a matter of course, and so many who, I am free to say, are so worried with amendments, that they pay no
                attention when an amendment containing important suggestions is made. Now, the suggestion contained in this amendment, I believe to be a valuable one. It occurred to my mind before it was presented in this hall. I had no intention of offering an amendment myself at all, because there have always been a sufficient number of members ready to enter into this fruitful field of
                operations, and who are amply qualified to meet the necessities of the occasion; but when the gentleman from Wythe presented an amendment this morning touching this point, I was glad to concur in it. I am pleased that my friend from Norfolk has now come forward with his suggestion; and I beg the Committee to consider well before they object to this amendment of his, merely because it
                is an amendment. I say it is plain, that as the section now stands, it may not only afford an opportunity for quibbling fanatics, who desire to strike a blow at the South, to misconstrue its purport, but it might mislead candid minds into a misinterpretation of its true intention, so as not to exclude the acquisition of Canada. You recollect that it was expressly provided in the old
                Articles of Confederation, that Canada might be admitted into the Union thereby formed. When we formed our Constitution of government, that provision was not specially retained; but I imagine, sir, that the Black Republican lawyer would argue that whilst it was not retained in words, it was retained in effect, and that under the provision of the Constitution we could receive Canada
                at any time into this Union. It was said by the gentleman from Wythe, that the acquisition of Texas was a case precisely in point. We are not protected, in the least degree, against the acquisition of Canada, as the section now stands. I trust it will be the pleasure of the Committee to adopt the suggestion of the gentleman from Norfolk; and that he will accept the additional
                amendment which I propose.
              </p>
              <p><span className="persName">Mr. DORMAN</span>'S amendment was accepted by <span className="persName">Mr. BOULDIN</span>.</p>
              <p>The question then recurred upon the adoption of <span className="persName">Mr. BOULDIN</span>'S amendment, upon which the yeas and nays were called. The amendment was rejected—yeas 45, nays 64, as follows :</p>
              <p>
                YEAS-Messrs. Aston, Blow, Jr., Borst, Bouldin, Boyd, Branch, Bruce, Cabell, Carter, Coffman, Conn, Custis, Dorman, Dulany, Echols, French, Fugate, Garland, Graham, F. L. Hale, L. S. Hall, Hunton, Kent, Lawson, J. B. Mallory, <PageNumber num={720} /> Marr, Marye, Sr., Miller, Moffett, Morris, Moore, Parks, Richardson, Slaughter, Southall, Speed, Staples,
                Strange, Tayloe, Thornton, Waller, White, Whitfield, Wickham, Williams-45.
              </p>
              <p>NAYS-Messrs. Janney [President]
                , Ambler, Armstrong, Baldwin, A. M. Barbour, Baylor, Berlin, Blakey, Brent, Brown, Burley, Byrne, Campbell, Carlile, Chapman, Clemens, C. B. Conrad, R. Y. Conrad, Couch, Critcher, Dent, Deskins, Early, Gillespie, Gravely, Gray, Goggin, John Goode, Jr., E. B. Hall, Hammond, Harvie, Hoge, Hubbard, Hughes, Hull, Isbell, Jackson, Peter C. Johnston, Lewis, McComas, McGrew, Marshall,
                Maslin, Masters, Morton, Nelson, Osburn, Patrick, Pendleton, Porter, Price, Pugh, Rives, Sharp, Sheffey, Sitlington, Spur-lock, Stuart, Summers, Tarr, Tyler, Willey, Wilson, Wise-64.
              </p>
              <p>During the calling of the roll, <span className="persName">Mr. BURDETT</span> stated that he had paired off with <span className="persName">Mr. FLOURNOY</span>, and <span className="persName">Mr. CHAMBLISS</span> said that he had paired off with <span className="persName">Mr. HOLLADAY</span>.</p>
              <p><span className="persName">Mr. PRICE</span> explained that he did not regard the proposed amendment as adding anything to the section, and therefore he voted no. The second section as amended, was then agreed to.</p>
              <p>The third section was reported as follows :</p>
              <p>
                "SEC. 3. Neither the Constitution, nor any amendment thereof, shall be construed to give Congress power to legislate concerning involuntary servitude in any State or Territory wherein the same is acknowledged or may exist by the laws thereof, nor to interfere with or abolish the same in the District of Columbia, without the consent of Maryland and Virginia, and without the consent of
                the owners, or making the owners, who do not consent, just compensation; nor the power to interfere with or prohibit representatives and others from bringing with them to the District of Columbia, retaining and taking away, persons so held to labor or service, nor the power to interfere with or abolish involuntary service in places under the exclusive jurisdiction of the United
                States within those States and Territories where the same is established or recognized; nor the power to prohibit the removal or transportation, by land or water, of persons held to labor, or involuntary service in any State or Territory of the United States to any other State or Territory thereof, where it is established or recognized by law or usage; and the right during
                transportation, by sea or river, of touching at ports, shores and landings, and landing in case of need shall exist, but not the right of sojourn or sale in any State or territory against the laws thereof. Nor shall Congress have power to authorize any higher rate of taxation on persons held to labor or service than on land.
              </p>
              <p>"The bringing into the District of Columbia persons held to labor or service for sale, or placing them in depots to be afterwards transferred <PageNumber num={721} /> to other places for sale as merchandise, is prohibited."</p>
              <p>The section was agreed to. The 4th section was reported as follows :</p>
              <p>"SEC. 4. The third paragraph of the second section of the fourth article of the Constitution shall not be construed to prevent any of the States, by appropriate legislation, and through the action of their judicial and ministerial officers, from enforcing the delivery of fugitives from labor to the person to whom such service or labor is due."</p>
              <div className="speaker" id="sp3509"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I propose to insert between the third and fourth sections, as an independent section, to be number "four," the following :</p>
              <p className="p-in-sp">
                "In all cases where property in persons held to service or labor in any State or Territory of the States or in the District of Columbia has been or hereafter may be taken for public use as in the case of impressment in war or otherwise, the owner thereof shall be justly compensated as in the case of other property taken; and in all cases involving the question of property in said
                persons, the rights of property in them shall be recognized and protected by the United States and other authorities, as rights to any other property are recognized and protected."
              </p>
              <p className="p-in-sp">This, sir, is a matter which I believe was never brought to the consideration of the Committee of Twenty-one at all, and was, therefore, omitted in their report.</p>
              <p className="p-in-sp">
                The present Constitution of the United States provides that private property shall not be taken for public use without just compensation. Yet, notwithstanding that, the celebrated class of cases called the D'Hauteville cases of Louisiana,<span className="note" id="Note79"
                ><span className="noteLabel">3</span>No record has been found of the D'Hauteville (or de Hauteville) cases. The speaker may have meant to refer to such cases as the claim of Marigny D'Auterive, of Louisiana, for remuneration for a slave, horse and cart pressed into United States service in the War of 1812.</span
                >
                have been from the time of the war of 1812, or rather from the 8th of January, 1815, rejected by the Congress of the United States. It will be remembered that I took up the challenge of the gentleman from Augusta
                [<span className="persName">Mr. BALDWIN</span>]
                , the other day, to produce a single instance in which the United States Government had ever wronged the Southern States. I now produce one class of wrongs, and I tell the gentleman I can produce not less than fifty equally flagrant. Notwithstanding this opinion of the yankees that the negroes are our weakness, Gen. Jackson proved, in 1815, to Englishmen, as we can now show to New
                Englishmen, that they are to a great extent an important element for our defence. Sir, in 1815, destitute of troops, except half-trained militia, with nothing but the gallant hearts and brave arms of the Tennessee and Mississippi volunteers to defend that city, which was to be sacked by Englishmen under the cry of "Beauty and Booty," the old hero proclaimed martial law, and seized on
                the property of individuals, embracing many negroes, and put them to work, as well as horses and carts. In front of the <PageNumber num={722} /> battery, in the face of veterans brought from Waterloo, Cuffy, the negro was made to stand and pile cotton bale upon cotton bale until ultimately Jackson completed his rampart, behind which he defended his
                country against an odds of almost a hundred to one, employing, too, mere raw militia against trained troops. A number of these negroes were killed; others were crippled and made useless for life. And, sir, from that day to this the Congress of the United States have refused to recognize the claim for payment for those negroes, impressed into the service of the Government in defence
                of the city of New Orleans. For eleven years, when I was in Congress, I can testify that these claimants were spurned and scorned; that all the influence of General Jackson himself could not prevail with the Committee of Claims in the House of Representatives, to pay for that property. Elisha Whittlesey, one of the most eminent, excellent and worthy conservatives of the State of
                Ohio, was Chairman of the Committee of Claims all the time I was in Congress. Williams, of North Carolina, was second on that Committee. They tried their best to get a report from the Committee. But, from 1815 down to this hour, they have rejected the claim.
              </p>
              <p className="p-in-sp">
                Now, sir, there is no member upon this floor who has not heard of the celebrated D'Hauteville cases, and there is one instance where the Federal Government have expressly denied to you your rights. Upon what ground? Invariably the Committee have asked to be discharged from the further consideration of these claims, on the ground that they would not recognize property in slaves.
              </p>
              <p className="p-in-sp">
                But I will give you another instance : You remember very well that when the British fleet lay off our coast in the war of 1812, a number of slaves ran away to them. I was an infant at the time. One of my hereditary slaves and a number of my brother's, ran away on that occasion to the British fleet. It so happened that I had then no legal guardian. My guardian died just about the time
                the claim for the payment of this slave ought to have been handed in to the Commission. The guardian who succeeded, when I was off at College, outside the State of Virginia, did not know of the circumstances until the commission had expired. There were other cases like mine that were not considered or satisfied by the commission. A balance of some $6,000 or $8,000 now remains of the
                fund provided under the treaty of Ghent, in the Treasury of the United States. I am now a petitioner for the payment of that claim, a petitioner upon a ground which any court of equity on earth would sustain. I have demanded that the claim should be satisfied as a right. The money is remaining in the Treasury of the United States, provided by a foreign power, and belongs
                <PageNumber num={723} /> to nobody else but to me and to those like me, for whom it was intended. Yes, sir, that claim is refused upon the ground that the Congress of the United States will not recognize the right of property in slaves.
              </p>
              <p className="p-in-sp">
                I mention these two instances, Mr. Chairman, to show the necessity of making some provision for enforcing, in respect to property in slaves, one of the plainest provisions in the Constitution of the United States—that private property shall not be taken for public use without just compensation. Plain as that principle is, you require of your Northern Confederates a provision to make
                it plainer, or rather, more imperative, if possible, by the mere force of repetition.
              </p>
              <p className="p-in-sp">I call for the yeas and nays upon the adoption of the amendment.</p>
              <p>The yeas and nays were ordered.</p>
              <p>The question was taken, and it was decided in the affirmative yeas 60, nays 46, as follows :</p>
              <p>
                AYES-Messrs. Ambler, Aston, A. M. Barbour, Blakey, Borst, Bouldin, Boyd, Branch, Bruce, Cabell, Campbell, Cecil, Chapman, Clemens, Coffman, Conn, Critcher, Custis, Dorman, Echols, French, Fugate, Garland, Graham, Gravely, Goggin, John Goode, Jr., Hale, Harvie, Holcombe, Hunton, Isbell, Kent, Lawson, Leake, James B. Mallory, Marr, Marye, Sr., Moffett, Morris, Morton, Nelson, Parks,
                Richardson, Sheffey, Sitlington, Slaughter, Southall, Speed, Staples, Strange, Thornton, Tredway, R. H. Turner, Tyler, Waller, Whitfield, Wickham, Wilson, Wise-60..
              </p>
              <p>NAYS-Messrs. Janney [President]
                , Armstrong, Baldwin, Baylor, Berlin, Blow, Jr., Boggess, Brent, Brown, Burley, Byrne, Carlile, Carter, C. B. Conrad, Robert Y. Conrad, Couch, Dent, Deskins, Early, Gillespie, Ephraim B. Hall, Hammond, Hubbard, Hughes, Hull, Jackson, P. C. Johnston, Lewis, McComas, Marshall, Maslin, Masters, Moore, Osburn, Patrick, Pendleton, Porter, Price, Pugh, Sharp, Spurlock, Chapman J. Stuart,
                Summers, Tarr, White, Willey-46.
              </p>
              <p>So the amendment was adopted.</p>
              <p>The hour of 2 having arrived, the Committee took a recess until 4 o'clock.</p>
            </div>
            <div className="section" id="vsc1965.v3.2.11.7">
              <h3><span className="headingNumber">1.11.7. </span><span className="head">EVENING SESSION</span></h3>
              <p>The Convention re-assembled at 4 P. M.</p>
              <p>The question was taken on the 4th section of the report of the Committee on Federal Relations, as follows :</p>
              <p>SECTION 4. The third paragraph of the second section of the fourth article of the Constitution, shall not be construed to prevent any of the States, by appropriate legislation, and through the action of their judicial and ministerial officers, from enforcing the delivery of fugitives from labor to the persons to whom such service or labor is due.</p>
              <PageNumber num={724} />
              <p>The section was agreed to.</p>
              <p>SECTION 5. The importation of slaves, coolies or persons held to service or labor, into the United States, and the territories, from places beyond the limits thereof, is hereby forever prohibited.</p>
              <div className="speaker" id="sp3510"><span className="persName">Mr. WILSON</span>—</div>
              <p className="p-in-sp">I offer the following amendment to the section :</p>
              <p className="p-in-sp">"But this section shall not be so construed as to prohibit the introduction of persons so held to service or labor into the United States, from the Confederate States, or any territory they may hereafter acquire."</p>
              <p className="p-in-sp">
                Taking into consideration the development of the country, it is not at all improbable that the time may come when slaves will be returned from the South to the North. When the supply of labor in the South shall overrun the demand, they may be sent back to Virginia or other States further North. When Virginia comes to work her rich mines and follow different pursuits from those she
                now follows, it may be her policy to purchase slaves in the South and bring them back. That is my reason for offering the amendment. I do not propose to occupy the time of the Committee, but I hope my amendment will be adopted.
              </p>
              <p><span className="persName">Mr. SHEFFEY</span> called for the ayes and noes.</p>
              <p>The question was ordered.</p>
              <p>The question was taken, and it was decided in the affirmative—yeas 49, nays 43, as follows :</p>
              <p>
                YEAS-Messrs. Blakey, Boisseau, Borst, Bouldin, Bruce, Cabell, Chapman, Coffman, Conn, R. Y. Conrad, R. H. Cox, Deskins, Dorman, Echols, Garland, Goggin, J. Goode, Jr., T. F. Goode, Hale, Hammond, Hunton, Isbell, Lawson, J. B. Mallory, Marr, Marye, Sr., Miller, Moffett, Morris, Morton, Moore, Parks, Richardson, W. C. Scott, Sheffey, Slaughter, Southall, Speed, Staples, Strange,
                Tayloe, Tredway, R. H. Turner, Waller, Whitfield, Williams, Wilson, Wise, Wysor-49.
              </p>
              <p>NAYS-Messrs. Janney [President] , Aston, Baylor, Berlin, Boggess, Burley, Byrne, Campbell, Carlile, Carter, C. B. Conrad, Couch, Dent, Early, French, Fugate, Graham, Gravely, E. B. Hall, Hubbard, Hoge, Hughes, Hull, Jackson, P. C. Johnston, McComas, Maslin, Masters, Osburn, Patrick, Pendleton, Porter, Price, Pugh, Sharp, Sitlington, Spurlock, C. J. Stuart, Summers, Tarr, White, Wickham, Willey-43.</p>
              <p>During the vote, <span className="persName">Mr. ARMSTRONG</span> stated that he had paired off with <span className="persName">Mr. AMBLER</span>.</p>
              <p><span className="persName">Mr. ALFRED M. BARBOUR</span> stated that he had paired off with Mr. CECIL.</p>
              <p><span className="persName">Mr. BURDETT</span> stated that he had paired off with <span className="persName">Mr. FLOURNOY</span>.</p>
              <PageNumber num={725} />
              <p><span className="persName">Mr. CHAMBLISS</span> stated that if he had not paired off he would have voted "aye."</p>
              <p><span className="persName">Mr. KENT</span> stated that he had paired off with <span className="persName">Mr. BALDWIN</span>. <span className="persName">Mr. LEWIS</span> stated that he had paired off with <span className="persName">Mr. HARVIE</span>. The vote was announced as above recorded.</p>
              <div className="speaker" id="sp3511"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">I move to amend by striking out the words "forever."</p>
              <p className="p-in-sp">
                I offer this amendment in order to explain the reason why I shall vote against the section as amended. By a vote of the Committee, a condition has been attached to the section, by which it is proposed to recognize the Confederate States as a separate and independent government, in an amendment to the Constitution. Now I cannot vote to propose an amendment to the Constitution which
                shall recognize their separate and independent existence, and preclude them from any part in this government.
              </p>
              <p className="p-in-sp">For this reason, I shall vote against the section as amended, hoping that when we get into Convention we shall be able to strike out the clause that has been added to the section.</p>
              <div className="speaker" id="sp3512"><span className="persName">Mr. CHAPMAN J. STUART</span>—</div>
              <p className="p-in-sp">I desire to speak to the amendment, for the purpose of explaining why I cannot vote for the section as amended on the motion of the gentleman from Harrison [Mr. Wm SON]
                . I look upon it as indirectly opening the African slave trade. It does not preclude the importation of Africans into the Southern Confederacy. Now, if that Confederacy is to be acknowledged as being a separate government, and if it shall, in its discretion, open the African slave trade, then this clause would permit the introduction of Africans into the United States. Thus, by our
                own act, we would be indirectly opening the African slave trade. For that reason, I will vote against the section as amended.
              </p>
              <p><span className="persName">Mr. EARLY</span>, by unanimous consent withdrew his amendment.</p>
              <div className="speaker" id="sp3513"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">
                I move to strike out the whole section. I approve, Mr. Chairman, of the fifth section as it was reported by the Committee of Twenty-one; but the amendment which has been adopted by very little more than a quorum voting on it, has, in my opinion, made it necessary that the Committee shall get rid of the whole section; and then so much of it as was reported by the Committee of
                Twenty-one can be restored elsewhere, or can be inserted by the Convention.
              </p>
              <p className="p-in-sp">
                I imagine it is not expected that we will require the non-slaveholding States to concur, as a condition of our remaining in the Union with them, in an amendment which will demand of them a recognition of the Southern Confederacy. I am not prepared to recognize their <PageNumber num={726} /> existence as a separate and independent power; and I imagine
                that there are still fewer in the non-slaveholding States who are prepared to recognize it. I believe that the amendment just added to this section would, of itself, if there were no other objection, defeat all hope that we might otherwise entertain of a concurrence in our action by the non-slaveholding States, and will tend as certainly to the ultimate destruction of this Government
                as any act which the Convention could commit. I therefore move to strike out the whole section as amended.
              </p>
              <div className="speaker" id="sp3514"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">
                I have listened to the remarks of the gentleman from Franklin and of the gentleman from Harrison. I hardly think that either of those gentlemen have comprehended the force and the necessity of this amendment to which they have taken exception. We know the Confederate States constitute a Government de facto, and I believe, de jure. If that Government is to continue, and if it shall be
                the will of Providence—as I trust in God it will not be—that there is to be a permanent separation of the slave States of the old Union, there will be gentlemen in the State of Virginia who have slave interests in the Confederate States; and those interests will be promoted by being able to bring their slaves from the Confederate States to their farms in this State.
              </p>
              <p className="p-in-sp">
                But the gentlemen from Franklin and Harrison are objecting to this amendment, because it acknowledges the independence of the Confederate States. Sir, the thunders from Fort Sumter have answered those gentlemen, deaf as they may be—that these States are independent. That flag of the Confederate States, which is to-day floating over Fort Sumter, is a response to them. They seem to
                have a tenderness and sympathy for the free States, but no sympathy for the slave States. They have spoken on this floor as if Virginia was the first power on earth; as if her voice was peace or war; as if it is only necessary for her to speak and the Confederate States must bow and the North must yield to her. Mr. Chairman, I have had as much respect for, and as much pride in, my
                native State as either the gentleman from Harrison or the gentleman from Franklin. But I receive, almost daily, letters from native born citizens of Virginia, who mourn over her fall from her high estate; that this Convention should remain so long in session, with the voice of war heard from Washington, and the thunders of Federal guns directed against our blood and our kindred, and
                yet that she should falter in the path of bold defiance and elevated patriotism. The gentleman from Augusta
                [<span className="persName">Mr. BALDWIN</span>] , declared, a few days since, that wherever the flag <PageNumber num={727} /> of the United States floated, without reference to the cause, he would rejoice in the success of that flag.</p>
              <p className="p-in-sp">
                It is equivalent to a declaration that in this struggle to crush the slave,<span className="note" id="Note80"><span className="noteLabel">4</span>Probably the word "States" has been omitted here.</span> gentlemen would rejoice in the success of the Republican party of the North.
              </p>
              <div className="speaker" id="sp3515"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">It is not in order for the gentleman to impugn the motives of members.</p>
              <div className="speaker" id="sp3516"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">I am not impugning the motives of any gentleman here.</p>
              <div className="speaker" id="sp3517"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">While the language of the gentleman is capable of such a construction, the Chair is satisfied the gentleman does not intend to impugn the motives of any gentleman on this floor.</p>
              <div className="speaker" id="sp3518"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">I am well satisfied, sir, that my language did not reflect upon any gentleman. It was not so intended.</p>
              <p className="p-in-sp">But, sir, I would ask the attention of the Committee to the following which I read from the journal :</p>
              <p className="p-in-sp">"<span className="persName">Mr. WISE</span> moved to amend by striking out the whole resolution, and insert in lieu thereof the following:</p>
              <p className="p-in-sp">" 'The people of Virginia hereby declare their consent to the recognition of the separate independence of the seceded States; that they shall be treated with as independent powers; and that the proper laws shall be passed to effectuate their separation.' "</p>
              <p className="p-in-sp">And that was passed by a vote of 107 to 20.</p>
              <p className="p-in-sp">
                And after that declaration, after the conduct of the administration, and after it is indicated that all the powers of the government are to be applied to the enforcement of a line of policy to subjugate and grind to the dust the Cotton States—and every man here knows that if the Cotton States are crushed, Virginia and every slave State will be crushed, I say, sir, after all these
                manifestations that those who would rejoice in the success of such a policy, would be those who would hail the triumph of Abolitionism and Republicanism over the slave States of this Union.
              </p>
              <p className="p-in-sp">Mr. Chairman, although it might have been the opinion of the Chair that I was transgressing the license of debate, it was far, far from my feelings; and if under the excitement of the moment I responded as I should not have responded to the Chair, I assure it equally that I intended no disrespect.</p>
              <div className="speaker" id="sp3519"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Chair did not understand the gentleman as meaning the least disrespect to it.</p>
              <div className="speaker" id="sp3520"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">
                But, sir, I say it is a source of mortification that in <PageNumber num={728} /> this House and on this day, and this hour, when we ought not to be in session, unless we should be signing an ordinance of secession, that we should find Virginians, representing slaveholding interests, denying the power that we acknowledge to exist in the Government of the
                gallant Confederate States, that have defeated the other in arms, triumphed over the flag of the Union and planted the flag of the Confederate States in its place. I say, sir, it is a source of mortification that gentlemen here will refuse to acknowledge the independence of these States, and the gentleman from Harrison
                [<span className="persName">Mr. CARLILE</span>] , goes so far as to say that there are in the Northern States gentlemen who will not acknowledge it.</p>
              <div className="speaker" id="sp3521"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">I confess my surprise at the remarks which have fallen from the respectable gentleman from Orange and Green [<span className="persName">Mr. MORTON</span>]
                . I had expected if there was a member in this body who would have united with me in the proposition I submitted, it would have been the gentleman from Orange and Green. I know that he yields to no man in his strong desire to have himself again re-united to those cotton States whose gallantry he so handsomely applauded in the remarks he has made, and with whom he seems to sympathize
                in the triumph that they have won over the flag of his country and my country. Sir, I have no sympathy with any effort that is made to trample upon that flag. Like the gentleman before me—the gentleman from Princess Anne
                [<span className="persName">Mr. WISE</span>] if the time shall ever come when I shall be found warring against any portion of this Union, I trust that, with him, I will be found with the flag of my country in one of my hands at least.</p>
              <div className="speaker" id="sp3522"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Oh ! I should take it away from you if I saw it there.</p>
              <div className="stage it">[Laughter.]</div>
              <div className="speaker" id="sp3523"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">
                When we struggle for the possession of the flag of our country, it will only be a struggle which of us shall uphold it, we will not be struggling at least for its dishonor. Now, sir, my object, if I have one that is dearer to me than another, it is to see harmony restored to this Union, and all the States re-instated to their respective positions. I am unwilling by any act of mine,
                until Virginia shall have determined that she no longer will remain in this Union, to recognize the independence of these States, or aid, as has been remarked, any effort that may be made to slough off these States, for which the gentleman from Orange has manifested so much affection in all that he has said and done here, but which he would now seem willing to discard from this
                Union. If I understood the object avowed by this Committee in its action so far, it has been endeavoring to agree <PageNumber num={729} /> upon propositions of amendments to be submitted to all the States, in the hope if acceded to, our Union would remain as it has been—would remain as it was formed, and, as I believe now, according to all
                constitutional law, and in the eyes of the constitution of the country, it exists, namely, a Union of thirty-four stars without one star erased. Is this government to continue, says the gentleman from Orange and Green? In response, I would inquire what are the efforts of the Convention, what has the Convention been doing, has it not been engaged in efforts to continue the government
                and perpetuate it? What is the object we have in view in considering these propositions which we trust will be acceded to by the other States, and made part and parcel of the Constitution of the United States, if not to preserve the government and restore harmony? And, sir, if these propositions are acceded to, we declare by our action here that they are sufficient to protect us and
                the slaveholding States South, and in fact all the States of this Union against any encroachments that may be hereafter attempted upon our rights. I confess I do not sympathize with the gentleman from Orange, if I understood him right, in the gratification he seemed to express at the spilling of the blood of our own countrymen. Sir, whose blood flows through the veins of Maj.
                Anderson? Kindred blood with our own, cooped up in Fort Sumter as he has been, and denied by the authorities surrounding him, the means to sustain life, where has there been any effort on the part of the administration to reinforce him, or furnish him with supplies? When has there been any effort to crush these Confederate States, or even a single member of that assumed government?
                Was not messenger after messenger discharged to Charleston to implore and beg of the authorities there, the poor privilege of sending supplies to Major Anderson and his 59 men, but the request was refused and supplies denied. And when that gallant commander was called upon to surrender, did he not say that he could not maintain his honor and comply with the request, and that he would
                not be willing to disgrace his government, but that in two or three days he would be compelled to surrender for want of supplies? Upon the return of that answer to the so-called War Department of the assumed Confederate Government, orders were issued to reduce the fort, and at once a fire was opened upon it by the troops in the employ of the seceded States. These are the despatches
                that are to be used as arguments by gentlemen who endeavor to throw discredit upon those whose sympathies are just as strong for the return of the Southern States, as are the sympathies of the gentleman from Orange and Green. There is no <PageNumber num={730} /> sympathy here with the free States as against them; but there is a love for the union of our
                country; and, sir,
              </p>
              <div className="lg">
                <div className="l">"You may break, you may ruin the vase if you will,</div>
                <div className="l">But the scent of the roses will hang 'round it still."</div>
              </div>
              <p className="p-in-sp">
                The seceded States have shown that they have thrown off, as far as they can, all allegiance to the United States Government ; they have trampled upon its flag, and erected the standard of rebellion in their midst; and because we desire to provide for them such guarantees as they must know will save them from harm in the future and secure for them all their just rights, we are to be
                told that all this amounts to nothing, and that we are to place upon the record our recognition of the independence of these seceded States; which, in effect, would be saying, we have no interest in you; but we, like Garrison and Phillips, desire to slough you off, and we are determined to separate ourselves from you. It will be time enough to recognize their independence, when you
                are willing to give up the Union of your country, and when you feel that a Union with them is your only destiny outside of the Union that has heretofore bound you to them.
              </p>
              <p className="p-in-sp">
                I would appeal to the Committee, and I trust it will be their pleasure to strike out this amendment, since it has been amended by this proposition, which, in itself, is an acknowledgment of the destruction of the government, and a desire on the part of this Committee to perpetuate the separation, and to paralyze all efforts for the maintenance of the Union and the preservation of our
                institutions.
              </p>
              <div className="speaker" id="sp3524"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I move to amend the amendment of the gentleman from Harrison [<span className="persName">Mr. CARLILE</span>]
                , by inserting at the end of the fifth section the following: "Provided that nothing herein contained shall be deemed to apply to the Southern States which have declared, or may hereafter declare their separation from this Confederacy, in case their separate independence shall be acknowledged and continued." That differs from the other proposition, which I voted for inadvertently. I
                find that the proposition added to the 5th resolution, which requires that it shall be inserted in the Constitution, and of course would thus become the supreme law of the land, provides that slaves shall be admitted into Massachusetts, the Southern States, or anywhere else. Now, the proposition which I make secures the object of the amendment to the first amendment upon which we
                voted, and does it, too, in the most deferential manner, and without introducing the words "slave States," or "non-slaveholding States."
              </p>
              <div className="speaker" id="sp3525"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">The gentleman from Harrison [<span className="persName">Mr. CARLILE</span>]
                , seems <PageNumber num={731} /> to think that I have an undue sympathy for the Confederated States, whilst I think that he has rather too much regard for the free States; and when I express the sentiment that the independence of the Confederate States was established; when this Convention had, by a vote of 107 to 20, acknowledged, in fact, that
                independence, he tells us of his efforts, and the efforts of this Committee, to devise means by which to gather in one harmonious Union the Cotton States of the South, and the States of the North. I put it to the candor of that gentleman to say whether he has the shadow of a shade of a hope of any such result. Is there a man in this Convention who honestly believes at this hour that
                there is the slightest hope of a re-construction of the Union as it was. If there is such a man, hope has absorbed judgment, and he has neither read history, nor has he read the heart of man. There was a time when there might have been a re-construction, and if Virginia had acted as Virginia should have acted, and if Virginia with one voice had headed the column of the Southern
                States, this day we would have had a Confederate Republic of fifteen States; we would have been respected by the North, and then, if these fifteen States had organized a constitution with guarantees, we would have secured all our rights; and if we had made provision in that constitution for the admission of free States, we would have had as many as we desired, and as much
                re-construction as would be healthy.
              </p>
              <p className="p-in-sp">
                But, Mr. Chairman, the gentleman has sought to awaken the sympathy of this Convention, and, I suppose, of the country, in favor of Major Anderson. Far be it from me to cast the slightest reflection upon that gallant officer and that gallant Southern man. My sympathies have always been in favor of Major Anderson, as a gallant man, whose wife is the daughter of the lamented General
                Clinch, of Georgia, known in our historic annals. Whilst I have sympathy for him, I scorn, as I trust every honest man upon this floor scorns the Republican administration that has not the nerve and boldness to reinforce him in time, or withdraw him. No, Abraham Lincoln, who stands at the head, as he claims, of thirty-one millions of freemen, cannot sleep at night in his palace
                unless he is guarded by the Lieutenant-General of the Army, with his Pretorian band andhere is his gallant officer abandoned at Fort Sumter, without provisions and without reinforcement. As to the attitude of the Confederated States, they have manifested a degree of forbearance unprecedented, and beyond what I trust Virginia would have done under like circumstances. The gentleman
                from Harrison
                [<span className="persName">Mr. CARLILE</span>] , like the head of the Republican party, denies that South Carolina is independent, notwithstanding <PageNumber num={732} /> the United States flag does not float upon an inch of her soil.</p>
              <div className="speaker" id="sp3526"><span className="persName">Mr. CARLILE</span>—</div>
              <p className="p-in-sp">Will the gentleman give way for a moment?</p>
              <div className="speaker" id="sp3527"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">
                I cannot, as my time is limited. I do not wish to reflect upon the gentleman. I see the point at which the shoe pinches. I do not wish to do him any injustice. Whilst he does not sympathize with Abraham Lincoln, the Republican, he knows that Abraham Lincoln, the Republican, is at the head of the Government, and he sympathizes in every triumph that the flag once borne by the
                Republicans, can have. The historian who will faithfully transmit to posterity the history of this day, will write it in words of burning indignation and curses, upon this Republican Administration, and in praise of the Confederated States.
              </p>
              <p className="p-in-sp">The gentleman from Harrison speaks as if the Confederated States had acted wantonly, in not permitting the Government to furnish supplies to Major Anderson, and he speaks, although he did not say so in terms, as if the policy of the Administration at Washington was a pacific policy.</p>
              <p>Here the ten minutes expired.</p>
              <p>The question being divided, the question was then taken on striking out, which was not agreed to.</p>
              <p>The question then recurred on inserting the words as proposed by <span className="persName">Mr. WISE</span>.</p>
              <div className="speaker" id="sp3528"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">I move to strike out of the amendment the proviso.</p>
              <p className="p-in-sp">
                I must confess that upon this day my heart is bowed down with sorrow, not so much that the flag of my country has been compelled to give way to another; not so much that a gallant friend and comrade of mine in former times has been compelled to yield to the force of over-ruling numbers, as that I find Virginians ready to rejoice in this event. I had fondly hoped, however we might
                disagree as to the course to be pursued towards these Confederated States, that the heart of every Virginian would appreciate the gallant devotion to duty which had characterized that man, who, if reports be true, was lately the commander, and is now the hero of Fort Sumter. A few years ago Martin Koszta, a refugee from the despotism of the old world, came to our shores, and declared
                his intention to become a citizen of the United States. Upon his return to his native country, he was arrested and placed on board of an Austrian ship, when a vessel bearing the flag of the United States, and commanded by a citizen of South Carolina, too, with guns loaded, torches lighted, in the Mediterranean, in the face of the despotism of Europe, demanded his surrender; and
                <PageNumber num={733} /> when the news came to the United States that that demand was acceded to, a thrill of joy pervaded the whole country, and every man felt proud of the protection which the flag of his country had afforded in this case.
              </p>
              <p className="p-in-sp">
                Now, sir, when a man in whose veins every drop of blood that flows is that of a Virginian; when the son of an officer of the Revolution, who fought for our liberties—yes, sir, fought for South Carolina—when, I say, the son of that man, with a handful of starving men, has been fired into, and has been compelled, at the cannon's mouth, to lower the flag of his country, I confess that
                my heart is bowed down with sorrow to find Virginians ready to rejoice at such an event.
              </p>
              <p className="p-in-sp">
                Mr. Chairman, I do not despair of the cause of liberty ; I do not despair of the Union. I have an abiding confidence in the devotion of the people of Virginia to the Union of the country. I will not despair of their verdict ; but I must confess it is a matter of regret to me that any Virginian should be found ready to rejoice to see the dishonor to our flag in the hands of a son of
                one of Virginia's revolutionary soldiers.
              </p>
              <p className="p-in-sp">
                Mr. Chairman, this act has done nothing to advance the cause of the Confederate States. In my humble opinion, it has placed a gulf between them and the people of Virginia. The mass of the people will never be found sanctioning their cause. We are threatened with having an army marched through the State of Virginia to Washington; and I see by the papers this morning that a son of
                Virginia has stated in Charleston, that the very moment a blow was struck, Virginia would go out of the Union. If there be any Virginians who advise or encourage the idea of marching an army from the Confederated States through our borders to Washington, they mistake the tone and temper of our people. I trust that the issue may never be forced upon us; but when it does come, mark it,
                that the invasion of our soil will be promptly resisted. The spirit of manhood has not deserted the sons of Virginia.
              </p>
              <p className="p-in-sp">I proposed the amendment I have offered, not merely for the purpose of making these remarks, but for the purpose of asking what will be the effect of the amendment of the gentleman from Accomac [<span className="persName">Mr. WISE</span>] , if it be adopted? If these Confederate States shall hereafter adopt the slave trade, it will authorize the introduction of slaves brought from Africa into our country, and provided we shall remain in this Union, it will, in fact re-open the slave trade. For that reason, I hope the Committee will strike out the words I have indicated.</p>
              <div className="speaker" id="sp3529"><span className="persName">Mr. GOODE</span> of M.—</div>
              <p className="p-in-sp">I desire to say to the gentleman from Franklin <PageNumber num={734} /> [<span className="persName">Mr. EARLY</span>]
                , that he very much misconceives the sentiment of the people of Virginia, if he supposes that the act of the Confederate States of this day, at Fort Sumter, has placed an impassable gulf between them and us. What it may place between the majority of this Convention and the Confederate States, I will not undertake to say, but I fear that there was already between them a gulf, wide,
                deep, and impassable. But be this as it may, I tell the gentleman from Franklin that the great popular heart of Virginia is now throbbing in sympathy and unison with those gallant men who, upon Carolina's soil, are battling unto death for the common rights of the South. Sir, I tell gentlemen here, that, be the action of this Convention what it may, when the dread struggle comes, the
                freemen of Virginia, from the Ohio to the Chesapeake, will gather around the flag of the Confederate States, as the Scottish Highlanders around the cross of fire—that side by side and shoulder to shoulder, they will go forth beneath its folds to victory or death. Sir, the gentleman from Franklin intimates that the Confederate States would not be allowed to march an army over the soil
                of Virginia. I tell the gentleman that they will, if need be, march an army over Virginia's soil, I tell him that the battles of the Republican Administration at Washington, are not to be fought alone on Southern soil—our Southern brethren will not wait until fire and sword is carried into the heart of the Southern country. They will not wait to be cut down around their own altars
                and firesides, and amid their wives and children. The tramp of Southern soldiers will soon be heard on the Northward march. And I tell gentlemen, that with thousands of Virginia freemen at their side, they will carve their way, if need be, through Virginia soil, against all corners. Sir, gentlemen need not trouble themselves about the independence of the Confederate States. That is
                now a fixed fact. The flag of the Confederate States now floats in lustrous beauty from the Capitol at Montgomery. It now waves proudly and defiantly from the battlements at Fort Sumter. And, sir, if gentlemen wish to know where the great heart of Virginia is in this contest, I point them to the guns now thundering in our ears in commemoration of the work of this day at Fort Sumter.
                In conclusion, sir, I desire to say that we of the East do not mean to be held in this Union by any power, within or without the State—out of it we intend to go.
              </p>
              <div className="speaker" id="sp3530"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The question is upon the amendment to strike out the proviso in the amendment of the gentleman from Princess Anne.</p>
              <p><span className="persName">Mr. BOISSEAU</span> called for the yeas and nays.</p>
              <PageNumber num={735} />
              <div className="speaker" id="sp3531"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">Mr. Chairman, I thought we had on this floor gentlemen who called themselves State Rights men. When I hear a gentleman belonging to that party get up on this floor and invite an army to be marched through Virginia to influence the action of Virginians--</p>
              <div className="speaker" id="sp3532"><span className="persName">Mr. GOODE</span>, of Mecklenburg—</div>
              <p className="p-in-sp">Will the gentleman allow me to interrupt him? I did not say that with any view to influence the action of Virginia. I did not mean it so to be understood.</p>
              <div className="speaker" id="sp3533"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">
                -When, sir, I hear gentlemen invite, whether to influence or not, an army to march through Virginia to sustain the rights of Virginia, I ask what becomes of the doctrine of State Rights? Sir, if Virginia is to resume her powers into her own hands, I ask how is this proposition to the President of the Confederate States to march an army through our borders to redress our wrongs and
                assert our rights, to coincide with any idea of State Rights?
              </p>
              <p className="p-in-sp">
                Mr. Chairman, the gentlemen who talk about an appeal to the people, gentlemen whose constituents voted against a reference, who, themselves I imagine, voted against a reference to the people, come now and tell us what wonderful revolutions have taken place in the public mind. Why, sir, the gentleman from Mecklenburg, I believe, did present resolutions from his county. Was there any
                change or revolution going on there? I have listened to a number of the resolutions which have been read, and they have all, or nearly all—except the resolutions from the town of my worthy friend from Petersburg
                [<span className="persName">Mr. BRANCH</span>] they are all from counties which have sent secession delegates here; counties which voted against a reference to the people; and these are given as evidences of a great revolution in popular sentiment.</p>
              <p className="p-in-sp">
                Sir, I imagine that gentlemen don't know how large the State of Virginia is. There was an old man, a citizen of my county, who has been reared in the hollows of the mountains, where the peaks run up very high, and you see only a little of the sky above. He had never been further than ten miles away from home. He had read his Bible and knew it by heart, but knew very little of worldly
                matters; and he took it into his head to become a candidate for the Legislature. Well, he started, and when he got a little below our Court-house, the country began to open up before him, and he was led to exclaim, "Great God, if I had known the world had been half so large, I never would have started out from home."
                [Laughter.] If these gentlemen who talk about revolution were to go up into our mountain peaks, and look out upon the broad expanse that would be spread before them, they would be astonished and amazed to see how large the State of Virginia is.</p>
              <PageNumber num={736} />
              <p className="p-in-sp">
                Why, sir, do these men who follow after bands of music in the streets, represent the masses of the people of Virginia? Sir, the masses of the people of Virginia are at home. In these towns, where men congregate together in masses, and where the element of excitement and disorder prevails, you may find demonstrations which gentlemen may mistake for evidences of popular opinion. If
                gentlemen will look abroad over this Commonwealth, to see how large it is, and how many people we have, I think they would see the fallacy of this idea of a change in popular sentiment.
              </p>
              <p className="p-in-sp">
                Sir, the gentleman who claims to be of the States Rights school has threatened, whatever may be the voice of this Convention ( do I understand him to say of the people?) that he still will join the banner of this Southern army. What, sir, becomes of all these pretensions of devotion to Virginia? I acknowledge my fealty to Virginia. I intend to stand by her, as I said before, whatever
                may be her destiny for weal or woe. But, sir, will the gentleman who claims to be the especial champion of Virginia's rights, place his voice, his wishes, in opposition to the wishes of the people of Virginia?
              </p>
              <div className="speaker" id="sp3534"><span className="persName">Mr. GOODE</span>, of Mecklenburg—</div>
              <p className="p-in-sp">Does the gentleman desire an answer?</p>
              <p><span className="persName">Mr. EARLY</span> indicated his assent.</p>
              <div className="speaker" id="sp3535"><span className="persName">Mr. GOODE</span>—</div>
              <p className="p-in-sp">I said to the gentleman then, that be the action of the Convention what it may, we of the East will neither be held in this government under a Republican administration by the powers at Washington, nor by the powers that, perchance, may lie West of the Alleghany mountains.</p>
              <div className="speaker" id="sp3536"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">
                Then, sir, the gentleman is not only going to secede from the Union, but to secede from the State of Virginia; and I say, what becomes of his claim of devotion to Virginia, her altars, her honor and her interest? Sir, I have belonged to a party that has always been in a minority in this State, ever since I knew anything about public affairs. I never have set up my voice against the
                will of the people; when that has been expressed, I have submitted. But when the gentleman, under this new doctrine of State Rights, sets up his opinion and the opinion of his constituents, against the will of the majority, Great God, sir, if the honor of Virginia is to be confided to such hands, I want to know what will become of it.
              </p>
              <div className="speaker" id="sp3537"><span className="persName">Mr. GOODE</span>—</div>
              <p className="p-in-sp">Will the gentleman from Franklin allow me to ask him a question?</p>
              <div className="speaker" id="sp3538"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">I have done.</p>
              <div className="speaker" id="sp3539"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">Has the gentleman from Franklin concluded his remarks?</p>
              <PageNumber num={737} />
              <div className="speaker" id="sp3540"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">Yes, sir.</p>
              <div className="speaker" id="sp3541"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">Then debate ceases.</p>
              <div className="speaker" id="sp3542"><span className="persName">Mr. GOODE</span>—</div>
              <p className="p-in-sp">I beg to ask the gentleman one single question.</p>
              <div className="speaker" id="sp3543"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The gentleman from Mecklenburg will please to take his seat. The question is upon the amendment to the amendment, and upon this question the yeas and nays are ordered.</p>
              <div className="speaker" id="sp3544"><span className="persName">Mr. WM. C. SCOTT</span>—</div>
              <p className="p-in-sp">I call for the reporting of the amendment.</p>
              <div className="speaker" id="sp3545"><span className="persName">Mr. GOODE</span>—</div>
              <p className="p-in-sp">I submit that I have a right to rise to a privileged question.</p>
              <div className="speaker" id="sp3546"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The gentleman from Powhatan called for the reporting of the amendment.</p>
              <div className="speaker" id="sp3547"><span className="persName">Mr. SHEFFEY</span>—</div>
              <p className="p-in-sp">Mr. Chairman, the gentleman from Mecklenburg rose to a privileged question.</p>
              <div className="speaker" id="sp3548"><span className="persName">Mr. GOODE</span>, of Mecklenburg—</div>
              <p className="p-in-sp">I rise to a privileged question. I desire to ask the gentleman from Franklin, and I ask him in all respect, whether in his closing remarks, it was his intention to cast any imputation or reflection upon me?</p>
              <div className="speaker" id="sp3549"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Chair would explain that he did not understand the character of the question which the gentleman desired to propose. I suppose he desired to continue the debate.</p>
              <div className="speaker" id="sp3550"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">
                Mr. Chairman, I believe that the language that I used was pretty much this : that the gentleman proposes to oppose his will to the will of the majority of the people of the State; that he professes to be an advocate of the States Rights doctrine; and I asked him if he is to oppose the wishes of the majority of the people of the State, in the name of God what sort of support is State
                Rights to receive from him? That is about the substance of it. Now, I .ask, what right the gentleman has to ask me the question?
              </p>
              <div className="speaker" id="sp3551"><span className="persName">The CHAIRMAN</span>—</div>
              <p className="p-in-sp">The Chair did not recognize the remark as intended in an offensive sense; if he had, the Chair would have instantly interposed.</p>
              <div className="speaker" id="sp3552"><span className="persName">Mr. GOODE</span>—</div>
              <p className="p-in-sp">I stated distinctly that I asked the gentleman most respectfully to say whether he designed to make any reflection or imputation upon me. If he did, I can see no objection to his saying so.</p>
              <div className="speaker" id="sp3553"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">
                Mr. Chairman, on the other evening I stated that I should consider myself unworthy of the position that I occupied here if I could be capable of imputing to any gentleman on this floor any but patriotic motives. Sir, I was commenting upon the position that the gentleman had taken in his remarks. The remarks that I made, <PageNumber num={738} /> I think,
                are capable of no misconstruction. I do insist that the gentleman has no right to put the question to me in that way.
              </p>
              <div className="speaker" id="sp3554"><span className="persName">Mr. WISE</span> [in his seat]—</div>
              <p className="p-in-sp">Ask him what the words were.</p>
              <div className="speaker" id="sp3555"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">Will the gentleman state what the words are, to which he objects?</p>
              <div className="speaker" id="sp3556"><span className="persName">Mr. GOODE</span>—</div>
              <p className="p-in-sp">
                I do not recollect, sir. I cannot state now the identical words used by the gentleman. I understood him to say substantially this, though, sir: "God help the people, if their interests and honor were to be confined to the keeping of gentlemen such as I." I shall press the question, and, as I stated before, I ask him most respectfully whether he designed to cast any imputation or
                reflection upon me?
              </p>
              <div className="speaker" id="sp3557"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">I have stated just now the substance of what I said. I think he is entirely mistaken in saying I used the expression, "of gentlemen such as he is."</p>
              <div className="speaker" id="sp3558"><span className="persName">Mr. GOODE</span>—</div>
              <p className="p-in-sp">Mr. Chairman, I say again, I ask him most respectfully. I have assailed the rights of no gentleman upon this floor, and I trust mine shall not be assailed. I ask the gentleman whether he disclaims any intention to cast any reflection upon me?</p>
              <div className="speaker" id="sp3559"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Mr. Chairman, I must interpose here. I think, decidedly, the gentleman from Franklin disclaimed it. He disclaims the words he is said to have used to my friend from Mecklenburg, and, of course, he disclaims the sense of the words. So I understand the gentleman from Franklin.</p>
              <div className="speaker" id="sp3560"><span className="persName">Mr. GOODE</span>—</div>
              <p className="p-in-sp">I will ask him another question : If he disclaims the language, whether he also disclaims the application of that language to me?</p>
              <div className="speaker" id="sp3561"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">I really think the gentleman has no right to ask me such a question. I trust I have a sufficient appreciation for every gentleman upon this floor, but I think the gentleman is carrying this thing rather too far. I do not think the gentleman has the right to ask me if I disclaim the application of language to him, which I have not used.</p>
              <p>
                The question being upon striking out the proviso to the amendment offered by <span className="persName">Mr. WISE</span>, upon which the yeas and nays had been ordered, it was taken, and decided in the negative—yeas 45, nays 61—as follows : <span className="note" id="Note81"><span className="noteLabel">5</span>The Journal adds McComas to the affirmative votes listed here.</span>
              </p>
              <p>YEAS-Messrs. John Janney [President]
                , Aston, Baldwin, Baylor, Brent, Brown, Burley, Byrne, Campbell, Carlile, Carter, Clemens, C. B. Conrad, Couch, Custis, Dent, Early, French, Fugate, Gillespie, Gravely, E. B. Hall, Hoge, Hubbard, <PageNumber num={739} /> Hughes, Hull, Jackson, Peter C. Johnston, Marshall, Maslin, Masters, Moore, Osburn, Patrick, Pendleton, Porter, Price, Sharp,
                Sitlington, Chapman J. Stuart, Summers, Tarr, White, Willey-45.
              </p>
              <p>
                NAYS-Messrs. James Barbour, Blakey, Blow, Jr., Boisseau, Borst, Bouldin, Boyd, Cabeil, Chapman, Coffman, Conn, R. Y. Conrad, R. H. Cox, Critcher, Deskins, Dorman, Echols, Forbes, Garland, Graham, Gray, Goggin, J. Goode, Jr., Hale, Hammond, Haymond, Holcombe, Hunton, Isbell, Kent, Lawson, Leake, J. B. Mallory, Marr, Marye, Sr., Miller, Moffett, Morris, Nelson, Parks, Pugh, Richardson,
                Wm. C. Scott, Sheffey, Slaughter, Southall, Speed, Spurlock, Staples, Strange, Tayloe, Tredway, R. H. Turner, Tyler, Waller, Whitfield, Wickham, Williams, Wilson, Wise, Wysor-61.
              </p>
              <p>So the proviso was not stricken out.</p>
              <div className="speaker" id="sp3562"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">
                Some gentlemen who are friends of mine, in whom I have great confidence, have represented to me that perhaps I was not fully aware of the purport of the language I have used. I am not accustomed to speak in a deliberative body, and, in fact, I am not much accustomed to speak in any way, and I know that very frequently my ideas get ahead of my language. If I used language which would
                bear the construction which my friends tell me they understood, it was language I did not intend to use. The idea I intended to convey was, that if the position taken by the gentleman from Mecklenburg was an evidence of States rights, God defend us from any such States rights as that. I intended the language to be applied in a political sense. If I used any expression that would have
                any other construction, it was because I have not a command of language to express my ideas.
              </p>
              <p className="p-in-sp">I hope the Convention will understand the motive which impels me to make this statement. And I hope every gentleman will understand that what I said was intended to reach a political aspect of the case, and not to apply to the gentleman personally.</p>
              <div className="speaker" id="sp3563"><span className="persName">Mr. GOODE</span>, of Mecklenburg—</div>
              <p className="p-in-sp">I am sure that I, for one, knowing that gentleman by character, as I well do, fully appreciate the motives prompting him to make the explanation he has just made. I need not say that I accept it with pleasure.</p>
              <p>The question was then taken on inserting the amendment proposed by <span className="persName">Mr. WISE</span>, in place of the matter stricken out by the Committee, and the amendment was inserted.</p>
              <div className="speaker" id="sp3564"><span className="persName">Mr. FORBES</span>—</div>
              <p className="p-in-sp">I move that the Committee rise.</p>
              <p>The motion was agreed to—ayes 63, noes 58.</p>
              <p>The Committee accordingly rose, and the President having resumed the Chair, the Chairman [<span className="persName">Mr. SOUTHALL</span>] reported progress.</p>
            </div>
            <div className="section" id="vsc1965.v3.2.11.8">
              <PageNumber num={740} />
              <h3><span className="headingNumber">1.11.8. </span><span className="head">COMMUNICATION FROM THE GOVERNOR</span></h3>
              <p><span className="persName">The PRESIDENT</span> laid before the Convention the following communication:</p>
              <div className="floatingText">
                <div className="floatingText_body">
                  <p>EXECUTIVE DEPARTMENT,</p>
                  <p>April 13th, 1861.</p>
                  <p>Gentlemen of the Convention:</p>
                  <p>
                    Since your adjournment this morning, I have received another despatch from his Excellency, Gov. Pickens, which is herewith communicated. I consider it a duty to communicate any despatch that may be sent to me by the Governor of a State. In reply to the enquiry with which the despatch closes, I have replied, "The Convention now in session will determine what Virginia will do."
                  </p>
                  <p>Respectfully, JOHN LETCHER.</p>
                  <p>CHARLESTON, S. C., April 13th, 1861.</p>
                  <p>To Governor Letcher:</p>
                  <p>Received your despatch. It is true that Fort Sumter was bombarded all day yesterday, after refusing to evacuate, and four vessels were off the bar with troops and supplies waiting for the tide to come in, and the Fort was in signal with them.</p>
                  <p>
                    President Lincoln sent a special messenger, and informed me in writing that supplies would be put in, but asked no reply. Not a man at our batteries was hurt even. The Fort was furious in its fire on us. Our iron battery did great damage to the Fort in the south wall. Our shells fall freely in the Fort; it is not known exactly with what effect, but supposed to be serious, as they
                    are not firing this morning. Our Enfield battery dismounted three of the large Columbiads. We will take the Fort and can keep sixteen ten-inch mortars all the time on it, besides heavy guns which will give no peace, night or day. We can sink the fleet if they attempt to enter the channel. If they land elsewhere we can whip them. I have here, now, nearly seven thousand of the best
                    troops in the world, and a reserve of ten thousand on our railroads. The war is commenced, and we will triumph or perish. This is my answer to you. Please let me know what Virginia will do, as I telegraph to you candidly. F. W. PICKENS.
                  </p>
                </div>
              </div>
            </div>
            <div className="section" id="vsc1965.v3.2.11.9">
              <h3><span className="headingNumber">1.11.9. </span><span className="head">PUBLIC MEETING IN CAMPBELL</span></h3>
              <div className="speaker" id="sp3565"><span className="persName">Mr. SPEED</span>—</div>
              <p className="p-in-sp">I have received the proceedings of a large meeting held in the county of Campbell, at which certain resolutions were adopted, one of which requests me to lay them before this body, which I do, and ask that they be referred to the Committee on Federal Relations.</p>
              <PageNumber num={741} />
              <p className="p-in-sp">The following are the proceedings :</p>
              <div className="floatingText">
                <div className="floatingText_body">
                  <p>At a large and respectable meeting of the citizens of Campbell county, at the Court House, on Monday the 8th of April, 1861, on the motion of Wm. T. Yancey, R. G. H. Kean, Esq., was called to the Chair, and E. R. Page requested to act as Secretary.</p>
                  <p>After other proceedings,</p>
                  <p>The following resolutions were offered by Edwin R. Page :</p>
                  <p>
                    1st. Resolved, That this Commonwealth, in ratifying and accepting the Federal compact, parted with no essential attribute of her soverereignty, that she relinquished such rights, and delegated such powers, only to the General Government as are explicitly defined in the Constitution, that no reserved right can be cancelled, abridged, restrained or modified, and that we, her
                    citizens, owe our allegiance, only to the Sovereign State of Virginia.
                  </p>
                  <p>2d. Resolved, That it does not require a confederate council of States to ascertain and determine the rights of Virginia; these rights are known to her people, who are prepared to uphold and maintain them under all circumstances and in every emergency.</p>
                  <p>
                    3d. Resolved, That the preservation of her honor, and justice to her ancient renown alike require that Virginia should, without further delay, resume all the powers granted by her to the Federal Government, and assume a separate and independent station, for the purpose of uniting with the Confederate States of the South, either in a separate Southern Union, or in a common
                    Confederacy of all the States, constructed upon such terms of justice and equality, and with such guarantees, as will secure the rights, and satisfy the demands of each.
                  </p>
                  <p>
                    4th. Resolved, That if the State Convention now in session should consider it expedient for Virginia to make another "final" effort to restore or re-construct the Federal Union, the terms propounded in her "ultimatum," should be such as to secure the sanction and approval of the Confederate States, and we hereby declare our deliberate conviction that she can never, consistently
                    with the rights and vital interests of her people, be permanently a member of any confederacy which does not embrace the slaveholding States of the South.
                  </p>
                  <p>Which, after being freely discussed by Messrs. Goggin, Lynch, Garland, Yancey, Clay and Page, were unanimously adopted, the 3rd resolution having been modified and amended, so as to read as follows :</p>
                  <p>3. Resolved, That the preservation of her honor and interests, and justice to her ancient renown, require that Virginia should, without further delay, resume all the powers granted by her to the Federal <PageNumber num={742} /> Government for the purpose of uniting her destinies with the Confederated States.</p>
                  <p>The following resolution was offered by Mr. George W. Clement, and adopted without a dissenting voice :</p>
                  <p>Resolved, That this meeting cordially approves of the vote of John M. Speed, Esq., one of our delegates in the Convention, upon the resolution presented to that body by Mr. Harvie, of Amelia, in favor of immediate secession.</p>
                  <p>On motion it was ordered that a copy of the foregoing resolutions be forwarded to our delegates, with a request that they be laid before the Convention.</p>
                  <p>R. G. H. KEAN, Chairman.</p>
                  <p>EDWIN R. PAGE, Secretary.</p>
                </div>
              </div>
            </div>
            <div className="section" id="vsc1965.v3.2.11.10">
              <h3><span className="headingNumber">1.11.10. </span><span className="head">FORT SUMTER</span></h3>
              <div className="speaker" id="sp3566"><span className="persName">Mr. WYSOR</span>—</div>
              <p className="p-in-sp">I submit the following preamble and resolution for the adoption of the Convention :</p>
              <p className="p-in-sp">
                Whereas, official information has reached this Convention that hostilities have actually commenced in the harbor of Charleston, South Carolina, brought on by attempts on the part of the Federal Executive at Washington, to supply provisions and reinforcements to Fort Sumter, a fortification within the jurisdiction of the State of South Carolina, by force of arms; therefore,
              </p>
              <p className="p-in-sp">Resolved, That it be recommended to the people of Virginia to unite at once in defence of their institutions, their rights and their liberties, and to make common cause with their brethren of the Confederate States, thus most unjustly assailed in a war of self-defence.</p>
              <div className="speaker" id="sp3567"><span className="persName">Mr. PRICE</span>—</div>
              <p className="p-in-sp">I rise to a question of order. I apprehend there is a pending question before this body which must be disposed of, before any other business can be brought before it.</p>
              <div className="speaker" id="sp3568"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The resolution cannot be considered at this time except by general consent.</p>
              <div className="speaker" id="sp3569"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">I move that the Convention adjourn.</p>
              <p>The motion was agreed to, and thereupon the Convention at half-past six o'clock, adjourned until Monday next, at ten o'clock A. M.</p>
            </div>
          </div>
          <div className="day" id="vsc1965.v3.2.12">
            <PageNumber num={743} />
            <h2><span className="headingNumber">1.12. </span><span className="head">FIFTY-SECOND DAY</span></h2>
            <div className="dateline">Monday, <span className="date">April 15</span></div>
            <p>The Convention met pursuant to adjournment at 10 o'clock, A. M.</p>
            <p>Prayer by Rev. Mr. Boggs, of the Seamen's Bethel Church.</p>
            <div className="speaker" id="sp3570"><span className="persName">Mr. WYSOR</span>—</div>
            <p className="p-in-sp">When the Convention adjourned on Saturday evening last, I was entitled to the floor on my motion to pass by the order of business in order to consider a certain resolution which I proposed to the Convention that evening.</p>
            <p className="p-in-sp">Since then we have official information from Washington pointing out the policy proposed to be adopted by the Federal Executive.</p>
            <p className="p-in-sp">That information leaves no sort of doubt as to what the policy of the Federal Executive is to be. The gentleman from Montgomery [<span className="persName">Mr. PRESTON</span>]
              , declared to us before he went to Washington, that he held himself and those with whom he acted responsible for the future, of the controversy that is going on between the Northern and Southern States, so far as Virginia was concerned. For my part I accept the propositions. I look to them as responsible. I therefore propose to move nothing, to do nothing, which those gentlemen may not
              approve. I desire now to repeat the question to the gentleman from Augusta
              [<span className="persName">Mr. BALDWIN</span>] , which he said the other day so much hankered him—and to ask him what is Virginia now to do? When he answers that, I intend to repeat the following question which he mentioned —"what will you do then?" I beg leave, therefore, to withdraw my motion to pass by the ordinary business, and to yield the floor to any other gentleman who may have a proposition to submit.</p>
            <div className="speaker" id="sp3571"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">The motion to pass by the resolution being withdrawn, the resolution of the gentleman from Greenbrier is now before the Convention.</p>
            <div className="speaker" id="sp3572"><span className="persName">Mr. PRICE</span>—</div>
            <p className="p-in-sp">I suppose the Convention is about to receive a report from the Commissioners to Washington, and certainly I will acquiesce in the proposition to pass by the ordinary business with a view of receiving that report.</p>
            <p>The regular order of business was by general consent passed by.</p>
            <div className="section" id="vsc1965.v3.2.12.1">
              <h3><span className="headingNumber">1.12.1. </span><span className="head">COMMISSION TO WASHINGTON</span></h3>
              <p><span className="persName">Mr. PRESTON</span> submitted the following report :</p>
              <div className="floatingText">
                <div className="floatingText_body">
                  <p>
                    The Committee appointed on the 8th inst. by this Convention, with direction to wait upon the President of the United States, and present to him the preamble and resolutions adopted on that day, beg <PageNumber num={744} /> leave to report that in the fulfilment of their duty, they left the city of Richmond on the morning of the 9th instant for
                    Washington city; they were, however, prevented, by injuries sustained by the railroad from a violent and protracted storm, from reaching Washington until eleven o'clock on Friday, the 12th inst. At one o'clock on that day they called on the President and informed him that they had been appointed a Committee by the Convention of Virginia, then in session, to make a communication
                    to him from that body, and requested him to designate an hour at which it would be agreeable to him to receive us. He replied that he would be happy to receive us at 9 o'clock the next morning. We accordingly attended him at that hour, when we presented him with the resolution of the Convention, and explained to him our mission. He then read to us a paper which he stated he had
                    just prepared, as the answer to the communication from the Convention, declaring that he had seen in the newspapers the proceedings of the Convention, and the character of our mission. We herewith present the President's reply to the preamble and resolution of the Convention.
                  </p>
                  <p>WM. BALLARD PRESTON, ALEX. H. H. STUART, GEO. W. RANDOLPH</p>
                  <p>Hon. Messrs. <span className="persName">PRESTON</span>, <span className="persName">STUART</span> and <span className="persName">RANDOLPH</span> :</p>
                  <p>Gentlemen—As a committee of the Virginia Convention, now in session, you present me a preamble and resolution in these words :</p>
                  <p>
                    "Whereas, in the opinion of this Convention, the uncertainty which prevails in the public mind as to the policy which the Federal Executive intends to pursue towards the seceded States is extremely injurious to the industrial and commercial interests of the country, tends to keep up an excitement which is unfavorable to the adjustment of pending difficulties, and threatens a
                    disturbance of the public peace. Therefore
                  </p>
                  <p>"Resolved, That a committee of three delegates be appointed to wait on the President of the United States, present to him this preamble and resolution, and respectfully ask him to communicate to this Convention the policy which the Federal Executive intends to pursue in regard to the Confederate States."</p>
                  <p>In answer I have to say that having at the beginning of my official term expressed my intended policy as plainly as I was able, it is with deep regret and some mortification I now learn that there is great and injurious uncertainty in the public mind as to what that policy is, and what course I intend to pursue.</p>
                  <PageNumber num={745} />
                  <p>Not having as yet seen occasion to change, it is now my purpose to pursue the course marked out in the Inaugural Address. I commend a careful consideration of the whole document, as the best expression that I can give of my purposes. As I then and therein said, I now repeat :</p>
                  <p>"The power confided to me will be used to hold, occupy and possess the property and places belonging to the Government, and to collect the duties and imposts; but beyond what is necessary for these objects there will be no invasion, no using of force against or among the people anywhere."</p>
                  <p>By the words "property and places belonging to the Government" I chiefly allude to the military posts and property which were in the possession of the Government when it came to my hands.</p>
                  <p>But if, as now appears to be true, in pursuit of a purpose to drive the United States authority from these places, an unprovoked assault has been made upon Fort Sumter, I shall hold myself at liberty to repossess, if I can, like places which had been seized before the Government was devolved upon me.</p>
                  <p>And, in any event, I shall, to the best of my ability, repel force by force.</p>
                  <p>In case it proves true that Fort Sumter has been assaulted, as is reported, I shall, perhaps, cause the United States mails to be withdrawn from all the States which claim to have seceded, believing that the commencement of actual war against the Government justifies and possibly demands it.</p>
                  <p>I scarcely need to say that I consider the military posts and property situated within the States which claim to have seceded, as yet belonging to the Government of the United States, as much as they did before the supposed secession.</p>
                  <p>Whatever else I may do for the purpose, I shall not attempt to collect the duties and imposts by any armed invasion of any part of the country—not meaning by this, however, that I may not land a force deemed necessary to relieve a fort upon the border of the country.</p>
                  <p>From the fact that I have quoted a part of the Inaugural Address, it must not be inferred that I repudiate any other part, the whole of which I re-affirm, except so far as what I now say of the mails may be regarded as a modification.</p>
                </div>
              </div>
            </div>
            <div className="section" id="vsc1965.v3.2.12.2">
              <h3><span className="headingNumber">1.12.2. </span><span className="head">PROPOSITION FOR SECRET SESSION, &amp;C.</span></h3>
              <div className="speaker" id="sp3573"><span className="persName">Mr. HOLCOMBE</span>—</div>
              <p className="p-in-sp">I offer the following resolution :</p>
              <p className="p-in-sp">"Resolved, That the standing order for resolving the Convention <PageNumber num={746} /> into Committee of the Whole, be suspended, and that the Convention will immediately go into secret session, in order to consider the report of the Committee appointed to visit Washington."</p>
              <p className="p-in-sp">I shall not, Mr. President, trouble the Convention with many observations on the propriety of adopting that resolution. It is submitted by me, at the suggestion of, and after conference with, various gentlemen of all shades of opinion in this body.</p>
              <p className="p-in-sp">
                I believe that the grave and critical circumstances by which we are now surrounded, imperatively demand the fullest, the frankest, the freest interchange of sentiment and opinion, between members of this Convention. I do not believe that it is possible to attain that object so perfectly in any manner as in secret session. I am sure there is no gentleman in this body who is not
                willing at this time, to forget all past differences, to bury every remembrance of private or party feud, and to permit no thought or sentiment to dwell in his bosom which is not borrowed from his country. I believe that with an opportunity for this frank conference with each other, it may be possible to attain—and it is my earnest hope and conviction that we shall attain—something
                of that harmony and unanimity which ought to characterize the action of a great Commonwealth like that of Virginia in such a crisis.
              </p>
              <div className="speaker" id="sp3574"><span className="persName">Mr. ROBERT E. SCOTT</span>—</div>
              <p className="p-in-sp">
                I very heartily concur in the desire of the gentleman from Albemarle, that in the presence of the grave events which now surround us, we should communicate frankly and fairly with each other, and should discard all considerations except those which belong to the high trust which we are here to discharge. For one, sir, I take this occasion to say, that I have no opinions touching the
                discharge of our duty here which I am not ready to declare to the public. I am ready to pursue no course, to advocate no policy, which I am not ready to explain to the public. I see no reason why we should change, at this time, that course of public deliberation which has thus far characterized our action. It seems to me, Mr. President, that if the evidence of the decision of the
                Administration at Washington which is spread before us, be true, there is but one course left us to pursue. In the exercise of powers, which I regard as usurped powers—if this evidence be true—this country is about to be plunged into one universal war. So long as hostilities should be confined between the Administration at Washington and the Confederated States, touching the position
                of forts in Southern harbors, I could see no imminent danger of a general war; but now it seems that the President of the United States has issued his proclamation making a requisition for 75,000 soldiers—a requisition for a military force which <PageNumber num={747} /> can be designed for no other purpose than active general war. I take it, sir, that
                this Convention has already vindicated the policy which it means to pursue in such an emergency.
              </p>
              <p className="p-in-sp">We have already declared—deliberately declared—that under no circumstances, in our opinion, ought the people of this State to consent to be made parties to an unnatural war against our Southern brethren. I say, sir, that this Convention has already declared that much in terms explicit and not to be misunderstood.</p>
              <p className="p-in-sp">
                The question now is presented to us whether we will make that declaration good, whether, so far as lies in our power, we will adopt the measures necessary to avoid a contingency which shall involve us in this war as parties against the Southern States. For one, sir, I have no hesitation in saying that, so far as my action is concerned, I mean to make good the declaration of that
                resolution.
              </p>
              <div className="speaker" id="sp3575"><span className="persName">Mr. CONRAD</span>, of Frederick—</div>
              <p className="p-in-sp">I must ask leave, for a moment, to interrupt my friend from Fauquier [Mr. Scorn] , to ask him to give way for a moment in order that a vote may be taken upon the first branch of the resolution before the House to suspend the standing order for going into Committee of the Whole, and I move, if it be in order, that the question be taken upon the motion merely to suspend the standing order for the present.</p>
              <div className="speaker" id="sp3576"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">I have a word to say, sir, in regard to this, and I desire to say that word not merely upon the motion to go into secret session, but upon the motion to suspend the order of business.</p>
              <p className="p-in-sp">
                It does not seem to me, sir, that we are in any condition this morning, in point of information, to justify us in taking any extraordinary step for the purpose of coming to the consideration of the circumstances by which we are surrounded. So far as I am concerned, I must say, that I am unwilling to go into a consideration now of this most grave crisis at which we have arrived. I
                regard the questions now pressing upon us as of too momentous consequence to be considered by the light of the information we have received in regard to them so far. As yet, sir, we have only telegraphic communication, unconfirmed, unaccompanied by any sort of comment or explanation to enable us to get at the whole bearing of these circumstances which are upon us. I, for one, sir, am
                not willing to be pressed thus, half informed, into a consideration of the matter which we are called upon to consider. I have thought, so far from departing from the ordinary round of our business in order to hasten into a consideration of the subject, that a just regard for the gravity of the subject, a just regard to that prudent statesmanship which ought to characterize our
                <PageNumber num={748} /> deliberations, would require of us rather to adjourn our action until we can get information in regard to the matter than to press forward these extraordinary resorts of parliamentary rule.
              </p>
              <p className="p-in-sp">
                Sir, as to the course to be pursued; as to the end to be aimed at ; I presume there will be no diversion in this body. I presume, sir, that if war is upon us, and is now inevitable, there will not be two opinions in regard to the part that Virginia shall play in any such war. I take that, sir, for granted. But, sir, while I said that Virginia will take, and will maintain, to the
                utmost of her ability, a position that will not be doubtful, there are many grave circumstances to be taken into consideration as to the best means of making her espousal of either side, or any side, most efficient for advancing the interests which she has at heart, and which she will espouse at the proper time.
              </p>
              <p className="p-in-sp">
                Sir, I think that there never has been a day, since the organization of this Commonwealth, on which a deliberative assembly of Virginians occupied a position so justly devolving upon every individual member of it a serious responsibility for every word that he addresses, for every step that he takes. I acknowledge, sir, that if there is any member in this assembly who, upon the
                information we have, and upon the time for deliberation that has been afforded us, has made up his own mind as to the true policy which the interests and the honor of Virginia require her to pursue, he is greatly in advance of me. For my part, sir, while I have a general idea that in any exigency of this sort, the position of Virginia and of all the South, is one; while I have a
                general opinion and a general determination that, so far as my voice goes, the action of Virginia shall be in defence of Southern rights and institutions, yet sir, I must acknowledge that when I have come to that conclusion, I have exhausted all expedients that have occurred to me.
              </p>
              <p className="p-in-sp">
                As to the second branch of the question in regard to a secret session, I have a word to say. I hope we will not inaugurate in Virginia any secret session or deliberation. In regard to that policy when it becomes a matter of Executive action, I can understand the propriety of secret session. I can understand and perceive the policy which controls the Executive in observing secrecy;
                but in regard to the policy to be pursued here, I do not see any necessity for the proposed secrecy.
              </p>
              <div className="speaker" id="sp3577"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The hour has arrived for going into Committee of the Whole.</p>
              <div className="speaker" id="sp3578"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I rise to a point of order, and it is, that whilst the question to suspend the order is pending a vote should be taken upon that motion; and that the execution of the order cannot go on until <PageNumber num={749} /> a test is made whether it shall be suspended or not. I have never known any other practice under the rule.</p>
              <div className="speaker" id="sp3579"><span className="persName">Mr. AMBLER</span>, of Louisa—</div>
              <p className="p-in-sp">The point of order is well taken; and you will see that any other rule would give the Chair the power of deciding the motion before the House.</p>
              <div className="speaker" id="sp3580"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair is of opinion, that to permit the debate to go on, will be to postpone the order of the Convention to go into Committee of the Whole, without even a decision by the House upon it.</p>
              <div className="speaker" id="sp3581"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I insist, sir, that even in the act of executing an order, or whilst an order is actually operating, it is in order to move to suspend that order.</p>
              <div className="speaker" id="sp3582"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">
                The Chair considers it to be its duty, under the order of the House, to announce to the Convention, at half-past 10 o'clock, that the hour has arrived for the Convention to go into Committee of the Whole. The Chair considers itself as having no discretion to permit the debate upon this motion to go on, as by such means the order of business might be indefinitely postponed.
              </p>
              <div className="speaker" id="sp3583"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">I move to suspend the execution of the order.</p>
              <div className="speaker" id="sp3584"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">That motion is already before the Convention. The question is whether debate can be entertained upon such a motion. If so, the order will be in effect, suspending without any direct action of the Convention upon the motion.</p>
              <div className="speaker" id="sp3585"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">
                I submit, sir, that the proper mode will be an appeal from the decision of the Chair in regard to the point of order. I submit that if it be true that a mere motion to suspend the rule, followed up by unlimited debate, is to have the effect of keeping us out of committee, that a motion any morning would supersede altogether the standing order of the Convention in regard to going into
                Committee of the Whole at all, and that we never could get into Committee of the Whole as long as there were members enough disposed to debate the motion to suspend. It seems to me, sir, that the course which I indicate would be the proper course to pursue. I think it is a matter of importance that we shall not establish a precedent that this Convention is to depart from its order of
                business.
              </p>
              <div className="speaker" id="sp3586"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">
                If the gentleman will permit the Chair to interrupt him, it will say, that it will be very glad to have any of its errors corrected by a vote of the body. The Chair decides that debate at this hour upon the motion to suspend is not in order, because the effect would be to suspend it indefinitely if debate is permitted to go on. But the Chair will, if it be the pleasure of the House,
                although <PageNumber num={750} /> it cannot entertain debate, put the question directly to the House, shall the execution of the order be suspended?
              </p>
              <div className="speaker" id="sp3587"><span className="persName">Mr. HALL</span>, of Marion—</div>
              <p className="p-in-sp">And upon that question I call the yeas and nays.</p>
              <p>
                The call was sustained, and the question being put, resulted, yeas 80, nays 43, as follows :<span className="note" id="Note82"><span className="noteLabel">1</span>The Journal adds McComas to the negative votes listed here.</span>
              </p>
              <p>YEAS-Messrs. Janney [President]
                , Ambler, Armstrong, Aston, A. M. Barbour, James Barbour, Blakey, Blow, Boggess, Boisseau, Borst, Bouldin, Boyd, Bruce, Cabell, Cecil, Caperton, Chapman, Coffman, Conn, R. Y. Conrad, R. H. Cox,. Critcher, Custis, Deskins, Dorman, Echols, Forbes, French, Fugate, Garland, Gregory, Goggin, John Goode, Jr., Hale, Cyrus Hall, L. S. Hall, Hammond, Holcombe, Hunton, Isbell, Marmaduke
                Johnson, Kent, Lawson, Leake, Macfarland, J. B. Mallory, Marshall, Marye, Miller, Moffett, Montague, Morris, Morton, Nelson, Parks, Preston, Pugh, Randolph, Richardson, Ro. E. Scott, Wm. C. Scott, Sheffey, Slaughter, Southall, Speed, Staples, Strange, Tayloe, Thornton, Tredway, R. H. Turner, Tyler, Waller, White, Whitfield, Wickham, Williams, Wise, Wysor-80.
              </p>
              <p>
                NAYS-Messrs. Baldwin, Baylor, Berlin, Brown, Burley, Byrne, Carlile, Carter, Clemens, C. B. Conrad, Couch, Dent, Early, Gillespie, Gravely, Gray, E. B. Hall, Haymond, Hoge, Hubbard, Hughes, Hull, Jackson, P. C. Johnston, Lewis, McGrew, Masters, Moore, Osburn, Patrick, Pendleton, Porter, Price, Sharp, Sitlington, Spurlock, A. H. H. Stuart, C. J. Stuart, Summers, Tarr, Willey,
                Wilson-43.
              </p>
              <p>So the motion to suspend was carried in the affirmative.</p>
              <p><span className="persName">Mr. BURDETT</span> stated, when his name was called, that he had paired off with the gentleman from Halifax [<span className="persName">Mr. FLOURNOY</span>] .</p>
              <p><span className="persName">Mr. CHAMBLISS</span>, when his name was called, said that he paired off with <span className="persName">Mr. HOLLADAY</span>. He was not aware how that gentleman would vote, if present, but, having no assurance upon the subject he should decline to vote. He stated that under other circumstances he would vote aye.</p>
              <p><span className="persName">Mr. T. F. GOODE</span>, of Mecklenburg, stated that he had paired off with <span className="persName">Mr. BRENT</span>, of Alexandria, otherwise he would vote aye.</p>
              <p><span className="persName">Mr. MARR</span> was unavoidably absent, as stated by <span className="persName">Mr. HUNTON</span>, in his behalf.</p>
              <p><span className="persName">Mr. MASLIN</span> was absent by illness in his family.</p>
              <p><span className="persName">Mr. TURNER</span>, of Jackson, paired off with <span className="persName">Mr. MCNEIL</span> ; he would otherwise have voted aye.</p>
              <p><span className="persName">Mr. BRANCH</span> said he had paired off with <span className="persName">Mr. RIVES</span>, of Prince George, but in a moment of forgetfulness, forgot that arrangement, and voted in the affirmative. He [<span className="persName">Mr. BRANCH</span>] , expected that <span className="persName">Mr. RIVES</span> would be present this morning, but <span className="persName">Mr. R</span>., sent him word this <PageNumber num={751} /> morning that he could not be present until this afternoon. He would, therefore, ask the Secretary to strike his name from the roll.</p>
              <p><span className="persName">Mr. CECIL</span> said that his colleague <span className="persName">Mr. GRAHAM</span>, was unavoidably absent.</p>
              <div className="speaker" id="sp3588"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">Will the gentleman from Albemarle [<span className="persName">Mr. HOLCOMBE</span>] , modify his resolution so as to suit the present state of facts.</p>
              <div className="speaker" id="sp3589"><span className="persName">Mr. HOLCOMBE</span>—</div>
              <p className="p-in-sp">The first branch has been adopted, and the motion now is, that we go immediately into secret session, and it requires no modification.</p>
              <div className="speaker" id="sp3590"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">It is now moved that this Convention immediately go into secret session.</p>
              <div className="speaker" id="sp3591"><span className="persName">Mr. SCOTT</span>, of Fauquier—</div>
              <p className="p-in-sp">I was proceeding, when interrupted a few minutes since, to address some remarks to the proposition of the gentleman from Albemarle [<span className="persName">Mr. HOLCOMBE</span>]
                , which proposes that this Convention shall now resolve itself into secret session. I call the attention of the Convention to one of the series of resolutions that with great unanimity this body adopted—that one which declares that under no circumstances ought the people of Virginia to consent that they should be made parties to this impending war. I know nothing, Mr. President, as
                to the authenticity of the proclamation which the papers of this morning give to us, but the remarks I was proceeding to make were based upon the hypothesis that that proclamation is true. If it be true, I think there can be little doubt that if it is responded to by the people of the non-slaveholding States, the administration is about to inaugurate a general war. In that event, I
                repeat, it seems to me, that we have no alternative left us to make good the declaration of the resolution to which I have referred, and, necessarily, we must disconnect ourselves from this Federal Government.
              </p>
              <div className="stage it">[Slight manifestations of applause in the galleries.]</div>
              <div className="speaker" id="sp3592"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The galleries will be cleared upon the appearance of any disorder. It is too solemn an occasion to tolerate it.</p>
              <div className="speaker" id="sp3593"><span className="persName">Mr. SCOTT</span> [resuming]—</div>
              <p className="p-in-sp">In what manner, and at what time it shall be done, are questions of grave import, demanding the most serious consideration of this body.</p>
              <p className="p-in-sp">
                Mr. President, by the law which convened this Convention, we are required to submit to the ratification, or the vote of the people at the polls, any measure adopted by this body which is to change the relations of this State with the Federal Union. That, I say, is a mandate of the law under which this Convention assembled. It is a part of the trust that has been confided to our
                hands, and I take it that <PageNumber num={752} /> whatever diversity of opinion prevails amongst us in respect to other matters, there can be none in respect to this. It is our duty, our imperative duty to submit to the ratification of the people of Virginia, any act on the part of this Convention, such as is contemplated by the provision of law, so
                that as secession, at last, will have to be determined by a vote of the people at the polls, I cannot see for what reason in the discussion of this question, we should resolve ourselves into secret session.
              </p>
              <p className="p-in-sp">
                The question, Mr. President, that has divided this body, the material question which has divided us, relates to the policy to be pursued in respect to this very action. The opinion is now entertained, and up to this time has been entertained by a decided majority, that we ought to adopt no final and decisive measure without a previous consultation with the remaining border slave
                States. On the part of other gentlemen, the opinion has been entertained that our true policy requires us to take what they call a separate State action. I have been, Mr. President, from the beginning impressed with the opinion that our true policy dictated consultation, I remain of that opinion. Heretofore, it has been to me more a question of propriety and a question of expediency,
                now it presses upon me as a question of necessity. In every point of view in which I consider the circumstances that surround us, I am the more and more impressed with the opinion that the true policy of Virginia is to take counsel with our sister border States.
              </p>
              <p className="p-in-sp">Now, sir, we have, as I have already said, to submit to a vote of the people whatever action we may determine upon. It seems to me, therefore, that that policy is plain before us. The division which characterizes this body, is found to exist to the same or a greater extent among the people of the States. The question of separate State or co-operative action is—</p>
              <div className="speaker" id="sp3594"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I rise to a question of order. My point of order is, whether upon the motion to go into secret session, the gentleman can open up the whole question of policy and indulge in this wide latitude of debate?</p>
              <div className="speaker" id="sp3595"><span className="persName">Mr. SCOTT</span>—</div>
              <p className="p-in-sp">I had supposed that the point to which I was directing my remarks, bore directly upon the proposition of the gentleman from Albemarle [<span className="persName">Mr. HOLCOMBE</span>]
                . According to my understanding of it, I was about addressing an argument, why the resolution proposed by the gentleman from Albemarle, should not be adopted, because I could see no necessity for secrecy, nothing good to arise from a secret deliberation; and I was proceeding to explain that by the terms of the <PageNumber num={753} /> law which convened
                us, we were obliged to submit to the final determination of our constituents at the polls, any measure intended or calculated to change the relations which this State now occupies, towards the Federal Union. I was proceeding to argue that it seemed to me our policy was a plain one. As we were divided here between this question of separate State action and of co-operative action—
              </p>
              <div className="speaker" id="sp3596"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">It appears to the Chair that the gentleman is going now into the line of argument which he was pursuing at the time the gentleman from Princess Anne [<span className="persName">Mr. WISE</span>]
                , rose to a point of order. The Chair will proceed to express his opinion upon the point of order, and will be happy to be corrected, if he should be wrong. The question is, shall the Convention now go into secret session? And the Chair is of the opinion that that question may well be decided either one way or the other, according to the character of the subject to be discussed. In
                these times, the Chair is not disposed to draw the line very rigidly and the Chair, therefore, decides that it is competent for the gentleman from Fauquier
                [<span className="persName">Mr. SCOTT</span>] , to show that the subject now before us is one that ought to be discussed in public instead of secret session.</p>
              <div className="speaker" id="sp3597"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                Will the Chair allow me to say, that if this course be allowed to the one side that knows no reason for secret session, will full latitude for discussion be allowed when those who do know the reasons for secret sessions, or are firmly convinced that they have such reasons, would be cut off from assigning their reasons; for they cannot assign them without exposing the secrets that
                they would wish not to disclose, so that the decision of the Chair gives ex parte effect to the discussion.
              </p>
              <div className="speaker" id="sp3598"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">That result may happen.</p>
              <div className="speaker" id="sp3599"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">It is obliged to happen.</p>
              <div className="speaker" id="sp3600"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">But the question undoubtedly is a debatable one, whether the Convention shall go in to secret session or no. All that the Chair can say upon that subject is that it will redeem the pledge given, when he was first called to this seat, and that is, to treat gentlemen upon all sides with perfect impartiality.</p>
              <div className="speaker" id="sp3601"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I do not impugn the motives of the Chair. I only speak of the effect of the decision to show that a motion to go into secret session implies that there was something secret. Why, sir, it would be a solecism—it would be worse than an Irish blunder for us to disclose the secrets, when giving reasons why we should go into secret session.</p>
              <div className="speaker" id="sp3602"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">It is the decision of the Chair that the gentleman <PageNumber num={754} /> from Fauquier [<span className="persName">Mr. SCOTT</span>] , shall be allowed to proceed with his remarks.</p>
              <div className="speaker" id="sp3603"><span className="persName">Mr. SCOTT</span>—</div>
              <p className="p-in-sp">
                I have but few remarks more to make, and I regret that I have encountered these frequent interruptions. I was proceeding to say that it seemed to me, that our policy was a plain one, that is, to submit these questions, that divided us in this body, and that to some extent, divided the people of this State, to the determination of the people of the State ; and that as it was incumbent
                upon us to submit our final action to the ratification of a popular vote, that we might now submit these questions to the people, at the polls, to be voted on and determined by them; that we might submit the alternative propositions of separate State action, or of co-operative action, which, up to this time, has found favor with the majority of this body.
              </p>
              <p className="p-in-sp">
                I do not feel at liberty, in discussing the question propounded by the resolution of the gentleman from Albemarle, to go at large into the considerations which bring me to this conclusion. Hereafter, I trust, an opportunity will be afforded me for a full and free comparison of opinion upon the question. For the present, I meant to content myself with an explanation of the policy,
                which it seemed to me, proper now to be pursued—that is, that we shall submit the alternative proposition to our constituents and put it in their power to determine whether they prefer that course of policy indicated by the gentlemen upon this floor who advocate separate State action, or that indicated by other gentlemen upon this floor, who desire the co-operation of the border
                States.
              </p>
              <p className="p-in-sp">
                These are questions, sir, of grave import, involving in their consideration many suggestions proper to be made, proper to be explained, proper to be discussed; but, upon the resolution of the gentleman from Albemarle, that debate probably would be out of order; so that I conclude, that as I know of no good to be attained by secret session, as I see, that to whatever conclusion this
                body may arrive, the verdict of the people is to be taken upon it, we cannot act in secret. We are forbid by the very law which summoned us; because, by that law, as I have said, the people at large have taken the determination of this question into their own hands; and if we submit fairly to them the question of separate State action, or action through the co-operation of the border
                States, I cannot see how the policy of either party here is to suffer injury. If the people shall determine that the State shall act separately for herself, they will so direct by their votes. If they prefer co-operation with their sister border States, in like manner they will determine. It seems to me that the policy is a plain one;
                <PageNumber num={755} /> that the policy is one which will meet the exigencies of this case. Viewed in any light, it is one which will give a fair opportunity for the expression of public sentiment; one which will put it in the power of those who are in favor of separate State action, to carry that policy out to its legitimate result, if the people
                sanction it.
              </p>
              <div className="speaker" id="sp3604"><span className="persName">Mr. WM. BALLARD PRESTON</span>—</div>
              <p className="p-in-sp">
                Mr. President, I beg to make a very few remarks to the Convention. When I presented the report of the Committee this morning, and as the house certainly observed at the moment, I studiously abstained from the expression of any general opinions, or from the expression of my own individual opinions, with regard to the important questions embraced in that report. I did so, sir, in
                deference to the views of friends on both sides of this question, who supposed that it would be better to go into secret session, in order that there, all that was publicly known, and all that was known through any other channels, might be freely presented to the Convention, and might not in any wise mislead the public on this subject. Like the gentleman who has just sat down, I
                would say, that for myself, my mind is made up irrevocably. The career of duty lies plain before me; I trust that all my sinews will bear me up through this conflict. It was from no desire at all, that anything I said, or thought, or felt, should be held from any human being. I was indifferent as to the fact whether we went into a secret session or not; but yet discreet gentlemen
                supposed it were proper, and I yielded with the greatest alacrity and with the greatest pleasure. I will not now announce what my purpose is; I am not now indicating the line of policy, because I desire this house first to determine whether all shall be in secret, or all in public; the moment that is done, I shall contribute my little mite on this occasion.
              </p>
              <p className="p-in-sp">
                I wish to address one word to my friend from Fauquier. The whole line of duty is not whether the State, the people of Virginia, will accept our action. There is upon us now, if the newspapers be true, a flagrant and open war. There is upon us here, as this report which we brought here unquestionably shows, an official purpose on the part of the President of the United States to
                re-conquer forts, and to repossess himself of possessions held by our people at the South. It may very well be that there is an exigency upon us that does not require us to submit to the people the question as to certain modes of action. I do not now speak of the question of secession, for that must go before the people but prudential questions concerning the best way for us to act
                in view of the present emergency, before the people will act upon the great emergency. That was my idea. I shall say nothing more, <PageNumber num={756} /> with the hope that the gentleman will consent to go into secret session, because after we shall have considered the matters suggested by the exigencies of the hour, we can come out again and debate
                the question of secession before the public.
              </p>
              <div className="speaker" id="sp3605"><span className="persName">Mr. CONRAD</span>, of Frederick—</div>
              <p className="p-in-sp">
                Mr. President, when the motion of the gentleman from Albemarle was announced, I trusted that it would commend itself to the judgment of every member, almost, of this Convention. And therefore, sir, I have been surprised, and my surprise has not been unmingled with regret, to find that motion opposed by gentlemen with whom I have had the honor hitherto to act upon this floor, and with
                whom I trust to act cordially to the end.
              </p>
              <p className="p-in-sp">
                But, sir, after all that has been urged against this motion, I find myself only more strongly confirmed in my first impression of the propriety, and almost indispensable necessity of the procedure pointed out in the motion of the gentleman from Albemarle. It may be true, sir, that recent events may have left but one course, in the general sense, for the Commonwealth of Virginia to
                take; that is, in opposition to the coercive policy indicated by the reply made by the President of the United States to our Committee. But, sir, gentlemen cannot fail to see that that general course involves many important considerations of policy, of expediency, some, at least, of which it may be essentially necessary should not have publicity given to them.
              </p>
              <p className="p-in-sp">
                Now, sir, I do not understand that there is indicated by this motion any suppression, finally, of what may be said or of what may be done in secret session upon this floor. I suppose it was no part of the purpose of the gentleman from Albemarle by his motion to suppress debate, or even to withhold from the people of Virginia all or anything of what may be said or done in secret
                session. The general course in such cases, I believe, is to retain the reporters here, to enjoin secrecy upon them, and, at a proper time, to remove the injunction of secrecy from the whole proceedings. That I suppose is the purpose of the mover of the resolution. But, sir, as I said, it may be all-important, for the present at least, not to give publicity to the particular
                considerations of policy, of expediency, which may be presented before this body in reference to the general course which we may have to take. Some of them, one at least, has been indicated by one of the gentlemen, to which I may advert merely for illustration. This very question of separate State action on the one hand, or of co-operation with the border States upon the other, may
                be an important question of expediency, and gentlemen who have hitherto differed upon this question <PageNumber num={757} /> may, upon a fair comparison of views in secret session, perhaps come to some unanimity upon that point.
              </p>
              <p className="p-in-sp">
                Now, sir, I will not say how important, how absolutely necessary, that in any action that we may ultimately take upon this great and momentous subject, we should be unanimous in Virginia. The truth is, unless we do have unanimity our actions will be in vain. We cannot take any action, until we come together on some common platform. Well, sir, this very comparison of opinion, this
                free discussion which is necessary to produce this unanimity, ought to be in secret session.
              </p>
              <p className="p-in-sp">
                And other considerations might be presented, all of which I think strongly tend to show the propriety of the motion which has been made. I do not wish to trouble the Convention with going over this, because I might, as has been said, entrench upon the very object with which the motion is made. I cannot go into anything like a general consideration of this proclamation of the
                President, without perhaps more or less doing the very mischief which the motion is intended to avoid. I only repeat, therefore, that no possible harm can be done, no suppression of the debate will be effected, by going into secret session. The only effect is that members will have an opportunity to express their views freely, they can express the views of the different
                constituencies in the State, so as to come upon one common platform based upon the action which the State ought to take.
              </p>
              <p className="p-in-sp">
                Again, sir, the gentleman urges in opposition to this motion the provision in the law constituting this Convention, which requires a reference of its action, provided that action results in disturbing at all the relation which the State now bears to the Constitution of the United States, or in changing the organic law of the State, back to the people. Why, sir, whilst it is perfectly
                true that we must refer these general questions back to the people, yet it is not less true that particular subjects of policy, of expediency will have to be adopted in reference to these particular modes of action which it may be important should be done in secret session. I would, therefore, make an appeal to my friend from Fauquier
                [<span className="persName">Mr. SCOTT</span>] and to my friend from Augusta [<span className="persName">Mr. BALDWIN</span>]
                too, to reconsider their opposition to this motion. It is one which, as announced by the mover, has been made, after consultation, by him, with members of every various shade of opinion upon this floor; and therefore it is one step towards that great desideratum—some unanimity of action upon the part of the people of Virginia, and the representatives of the people of Virginia in this
                great crisis of our country's fate. I trust, therefore, that the opposition will be withdrawn, or that we will at least be allowed to try <PageNumber num={758} /> the experiment of a secret session when we all can see its propriety and necessity.
              </p>
              <div className="speaker" id="sp3606"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">
                I trust, Mr. President, that it will not be the pleasure of the Convention to go into secret session. It will be an innovation upon the long-established usages of the State, and one which should not be consented to unless strong and stringent reasons be assigned for it. I stand, sir, upon the usage of the State, and I insist upon that usage being respected under existing
                circumstances, unless gentlemen are able to show some strong and sufficient reasons for a departure from it. I confess that I have heard no such reasons. My conviction is that the surest mode of securing calm deliberation, and of arriving at the best results, is to require the members of this Convention to act under all the responsibilities which attach to their relation to the
                public. And is not that to be found in public and open session? Sir, it is a matter of some importance that the public shall be informed of the individual opinions of gentlemen who are called upon to dispose of this question; and there is no mode by which the public can be accurately and sufficiently informed, except by our holding our sessions in public. No one contemplates, I
                apprehend, that the reporters are to be admitted upon the floor of the secret session. It is suggested to me, Mr. Chairman, that it is understood they will be. They will, however, be under restraint from giving immediate publicity to what is done and said.
              </p>
              <p className="p-in-sp">
                Now, it seems to me, that the scheme is one which will increase the public anxiety, and inflame the public agitation. Let us, then, contribute nothing to increase this anxiety. There is no incentive to excitement stronger than the imperfect and exaggerated intimations which leak out from secret sessions. The avowed object of going into secret session is to allay, and not increase,
                the public anxiety. But, Mr. President, when there is nothing to do, of which advantage can be taken by announcing it in advance, since all that is done, or is proposed to be done, must come at last before the people for their ratification, what possible advantage can result from concealing our proceedings? Why, sir, I have never known a secret session to be justified or sanctioned,
                except upon the ground that advantage may be taken by the enemy of the plan or pro jet which may be adopted. Here there is nothing to be apprehended, certainly, in respect to our sister States becoming informed of our proceedings. No one apprehends that advantage can be taken or gained by the Federal Government in making our proceedings public.
              </p>
              <p className="p-in-sp">
                As, therefore, nothing is to be gained by either side to which I have <PageNumber num={759} /> referred, surely I cannot be mistaken in saying that there is no object to be accomplished by going into secret session. Mr. President, my humble conviction is, that the question now to be determined by this Convention, and to be determined by the public, is
                brought down to exceedingly narrow limits. It is but one; and, if I may be permitted to name it, I would say that by the events of the day we are reduced to the necessity of standing upon our rights, or of jeopardising and sacrificing our rights. I desire, therefore, that what may be thought by this Convention, or what this Convention may do, should go before the public for its
                sanction or its disapproval. The publicity given to the proceedings of legislative bodies, is founded upon sound and rational principles. It has received the sanction of all the free governments of the world, and I doubt, with the exception, perhaps, of examples in the Southern Confederacy, that an instance can be referred to where secrecy has been imposed upon the proceedings of a
                deliberative body, unless some schemes were contemplated of which advantage might be taken to the prejudices and injury of the State. Here there is nothing of the sort of which advantage can be taken. Sir, there is scarcely anything in which we have been engaged for weeks passed, in which it may be said that there was not as much reason to withhold from the public as what we propose
                to do now.
              </p>
              <p className="p-in-sp">
                My humble conviction is, that the moral position which will be occupied by our State will be higher and more imposing, if we continue to observe our ancient usage, than if we close our doors upon the public. Sir, for one, I desire that the moral influence of this Convention, and, I trust, it has a large moral influence, may be exerted upon the powers at Washington, in constraining
                them if it be possible, to an acknowledgement of their responsibility to the public in maintaining the peace of the country.
              </p>
              <p className="p-in-sp">
                The moment that you go into secret session the impression will go out to the world that there are divisions and differences of opinion in this body which I am satisfied do not exist. I apprehend that, whatever may have been our differences heretofore, we have now reached a stage in this controversy in which there will be found to be an approximation of opinions and judgments. I
                trust, therefore, that it will not be the pleasure of this Convention to authorize a secret session.
              </p>
              <div className="speaker" id="sp3607"><span className="persName">Mr. CAPERTON</span>—</div>
              <p className="p-in-sp">
                I do not propose to discuss the propriety of going into secret session or of remaining in open session. I desire to call the attention of this Convention to the fact that doubts have been expressed as to the authenticity of the proclamation that has given rise <PageNumber num={760} /> to the motion now under consideration. I think it would be proper to
                afford time to solve those doubts, if there be any such doubts. I have for myself no such doubt.
              </p>
              <div className="speaker" id="sp3608"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">If the gentleman will permit me, I will say that I think I read the proclamation in last night's Intelligencer, which is a very strong circumstance of authentication.</p>
              <div className="speaker" id="sp3609"><span className="persName">Mr. CAPERTON</span>—</div>
              <p className="p-in-sp">
                I do not mean by the motion I intend to make, to cast any doubt myself, upon the authenticity of the proclamation. I entertain no doubt; and I will say further, as to my own action, that it is fixed. It is not with a view of relieving myself, but it is with a view of relieving the doubts of others in regard to the authenticity of the proclamation, and also with a view of affording an
                opportunity to members to confer with one another, so that we may possibly and probably approach something like unanimity, that I now move that the Convention adjourn.
              </p>
              <div className="speaker" id="sp3610"><span className="persName">Mr. HOLCOMBE</span>—</div>
              <p className="p-in-sp">I trust the gentleman will withdraw that motion for a single moment. I will renew it if he desires.</p>
              <div className="speaker" id="sp3611"><span className="persName">Mr. CAPERTON</span>—</div>
              <p className="p-in-sp">I will withdraw it with that understanding.</p>
              <div className="speaker" id="sp3612"><span className="persName">Mr. HOLCOMBE</span>—</div>
              <p className="p-in-sp">I desire that the motion should be withdrawn for a moment, that in the event of its being carried by this body no gentleman present should carry from the Convention any misapprehension as to the position in which I and those with whom I have been in the habit of acting upon these subjects, stand in reference to this matter.</p>
              <p className="p-in-sp">
                I do not suppose there is any man in the State who can doubt that the gentlemen to whom I refer are now, as they have been for a long time past, in favor of prompt public action. For myself, more than twelve months ago, I declared my conviction and my earnest hope that the election even of a Black Republican President, by a sectional majority, should be followed by immediate
                secession. On the 4th of November, if it had been in my power to have shaped the action and policy of this Commonwealth, such an ordinance would have gone forth. There has not been, from that day to this, an instant in which I would have hesitated to pass such an ordinance, or one moment in which there was a shadow of a doubt, in my mind, as to its being the true course of policy of
                the Commonwealth. And when the proclamation of the President of the United States, with its 4,000 words was traveling over the wires, if we could have carried our wishes into effect, those of us who have been acting together in this Convention, would have pressed the lightning into service, and, if <PageNumber num={761} /> possible, have overtaken it
                with a defiance upon the part of this Commonwealth that would have roused every Southern patriot from the Susquehanna to the Rio Grande. We are now for going into this battle with the shout of a king. It is from no desire, on our part, of secrecy in any part we may take in this discussion, that I have proposed this motion.
              </p>
              <p className="p-in-sp">
                We recognize the fact, well known to this body, well known to the State that we have not now and have not had the power of controlling the action of this Convention. We have indicated weeks ago what we believe its true policy was. That action the Convention declined to take. Gentlemen having different views, with manliness, frankness and decision, declared that the responsibility of
                the welfare of the Commonwealth rested upon them. When I came into this house this morning, it was with no view or expectation of submitting this proposition. I understood, however, that there were gentlemen who differed with us in opinion, who believed that various matters of information important to be obtained from gentlemen in different parts of the Commonwealth might better and
                more safely be obtained in Executive session than in any other mode; that possibly it might be the policy and duty of the Convention to take some immediate action in reference to the defence or the resources of the Commonwealth; that from these and a thousand other contingencies, which it is not necessary for me to indicate, it would be desirable to have a secret session. I made this
                .motion, therefore, as an earnest—and I shall vote for it with the same feeling, as, I am sure, the gentlemen with whom I usually act here, will vote for it—of my willingness to sustain them in any patriotic effort they may make in defence of the Commonwealth, and not for any choice or any purpose on our part of assuming the responsibility of their position.
              </p>
              <p className="p-in-sp">I will only say in reference to the suggestion of the gentleman from Richmond [<span className="persName">Mr. MACFARLAND</span>]
                , that it has been the universal practice of our country in revolutionary times for all bodies like our own to go into secret session. The Revolutionary Congress went into secret session. The House of Burgesses before 1776 repeatedly went into secret session, as is not unfamiliar to gentlemen. But without attempting to vindicate the motion or its merits, because I do not hold that to
                be incumbent upon me, I merely advert to these facts, approving as I do heartily and cordially the purpose of the motion, and willing as I am for myself and my friends to give it a frank support.
              </p>
              <p className="p-in-sp">I now, in accordance with the wish of the gentleman, renew the motion to adjourn, promising, however, myself to vote against it.</p>
              <PageNumber num={762} />
              <div className="speaker" id="sp3613"><span className="persName">Mr. BRANCH</span>—</div>
              <p className="p-in-sp">
                This is a very plain question Mr. President, it seems to me. Gentlemen with very fertile minds and expansive views, however, have seen that it is one so important as to be worthy of discussion. Gentlemen tell us that they have something to communicate which it would not be prudent to announce in open Convention. Well, sir, I am in favor of going into secret session and hearing what
                they have to say. If it turns out that they have nothing to tell us, we can open the doors. It is a simple proposition. It does not follow that because we go into secret session we are to remain there always. Sir, it is the plainest proposition that I ever knew to admit of debate.
              </p>
              <p className="p-in-sp">
                I do hope, therefore, that gentlemen will withdraw all opposition, and allow us to go into secret session with unanimity, and hear what is to be told. I might mention, if it were necessary, some instances in which other deliberative bodies have acted in secret session. If I am not mistaken, the Convention which was in session in Virginia in 1787 and 1788, to ratify the Constitution,
                deliberated with closed doors. The Senate of the United States every day goes into secret session in time of peace, for the purpose of deliberating upon matters of importance to the Government. I frankly confess that something has occurred to my mind since I have come into this body to-day that I would like to tell my brethren of the Convention, that I would not like to say publicly.
                [Laughter.]
                I am not going to spread it upon the wings of the wind. I do think we ought to go into secret session, and then come out again immediately if gentlemen have nothing to tell us. I imagine there are things which the Convention will understand, that can be better acted upon in secret. I represent the noble and patriotic people of a town in which there are volunteers, many of them ready
                to take up the line of march whenever they are summoned by the Governor of the Commonwealth or by this Convention. Sir, there are some questions upon which I am ready to take the responsibility now. I am ready to go, and I believe the people I represent will follow.
                [Laughter.] I am not acting under instructions now. I repudiate instructions. [Laughter.]
                I will have nothing to do with them. My heart is fired with love of my country, with love of my native soil, and whenever it shall become necessary for me to buckle on my armor, I hope I shall be found in the front ranks, as old a man as I am, and I imagine there are several old men here who are ready to go with me. It is my pleasure to say that my heart is beating in unison with
                that of the gentleman from Fauquier
                [<span className="persName">Mr. SCOTT</span>] , upon <PageNumber num={763} /> all the questions he has so manfully advocated since he came into this Convention.</p>
              <p className="p-in-sp">Our hearts beat in unison this morning on the all-important subject of going out of the Union. I am going out, sir, and as soon as I can get out. But I believe we ought to go into secret session now and deliberate as a household, in which there is a division of opinion. The gentleman from Richmond [<span className="persName">Mr. MACFARLAND</span>]
                , says that the idea will go out to the world that we are divided. Have we not been divided? Have we not fought here with all the arguments that could be used on this very important subject? Certainly we have. I trust there will be no more fighting. I want us to come heart to heart; and though there may be disagreement of opinion, let us compromise. Let us encourage each other. Let
                us not speak unkindly of each other. Let us believe that the men, from East or West, who are opposed to immediate secession, are as patriotic as we are, have the same object in sustaining the honor of Virginia, and will, at the right time, come up with us. Let us open our hearts. Let us tell one another that we want to heal the wounds of our household. In that way we will accomplish
                much in secret session which we might not otherwise accomplish. With these few remarks, and resting my argument on the fact that we can go out of secret session as easily as we can go into it, I hope gentlemen will withdraw their opposition, and let us go into secret session and see and hear what is to be seen or heard.
              </p>
              <div className="speaker" id="sp3614"><span className="persName">Mr. WM. C. SCOTT</span>—</div>
              <p className="p-in-sp">If I am in favor of going into secret session, it is not because I have any secrets to communicate. But I can readily conceive that there are other gentlemen who have views to present to the Convention which they may not wish to present in public. I am not like the gentleman from Albemarle [<span className="persName">Mr. HOLCOMBE</span>] who believed it to be the duty of Virginia to secede immediately after the election of Lincoln was announced. I saw no necessity for secession at that time.</p>
              <p className="p-in-sp">
                Mr. Lincoln was powerless if the seceded States had performed their duty to the country. But they withdrew from the Union—withdrew, in my humble opinion, without cause, and I, in my portion of the country, took a strong position against them. I love the old Union ; I deeply love it. And so long as there was a chance of preserving it, I continused to use all the efforts in my power to
                preserve it or to re-construct it.
              </p>
              <div className="speaker" id="sp3615"><span className="persName">Mr. HOLCOMBE</span>—</div>
              <p className="p-in-sp">Will the gentleman pardon me a word of explanation?</p>
              <div className="speaker" id="sp3616"><span className="persName">Mr. WM. C. SCOTT</span>—</div>
              <p className="p-in-sp">Certainly.</p>
              <PageNumber num={764} />
              <div className="speaker" id="sp3617"><span className="persName">Mr. HOLCOMBE</span>—</div>
              <p className="p-in-sp">I wish merely to disclaim any purpose of imputing to gentlemen who differ from me, any impropriety, or casting any reflection on their action. It was with no idea of that sort that I made reference to my own position as having been in favor of an ordinance of secession, from the beginning; I merely intended to show that I was for prompt and public action.</p>
              <div className="speaker" id="sp3618"><span className="persName">Mr. WM. C. SCOTT</span>—</div>
              <p className="p-in-sp">
                I did not understand the gentleman as casting any imputation on those who are in favor of continuing this Union or of re-constructing it. I was regarded as one of the strongest Union men in all our county. I was told that it was impossible to preserve the Union or to re-construct it. I recollect, and have always endeavored to practice, the expression of Napoleon to one of his
                marshals. Napoleon ordered one of his marshals, on one occasion, to go and do a particular act. "Sire," said the Marshal, "it is impossible." "Impossible," replied Napoleon, "is not good French," and I have always thought it was not good English. I have always believed that whatever ought to be done, ought to be attempted and that the attempt should not be abandoned as long as there
                was a chance of accomplishing it. I have had, almost up to this moment, hopes for a re-construction. I had clung to that hope. But now I have abandoned it and am for war.
              </p>
              <p className="p-in-sp">
                Sir, the course of the President of the United States cannot be mistaken. The positions which he took in his inaugural message were plain and unmistakable. But this Convention threw out its arms and told the President at Washington : "If you do attempt coercion on the Southern seceded States, Virginia will not stand by an idle spectator, but will take part on behalf of her Southern
                sisters." For a long time his policy was vacillating. He professed a peace policy. But lately his policy has been perfectly clear to my mind. Why has he called the Governors of various Northern States to meet him in council? What did he mean by arming ships and sending them down South? What did he mean by throwing reinforcements into Old Point Comfort, while this Convention was in
                session debating on the proper policy which Virginia ought to pursue, and using every effort for the purpose of the pacification of the country? Was that not a menace and an insult to the people of Virginia? It is true, sir, that he sent word to the Governor of South Carolina that if provisions were allowed to be carried to Fort Sumter, he would make no attack on the people of
                Charleston; but that was only a ruse de guerre.
              </p>
              <p className="p-in-sp">He wanted to throw on them the onus of commencing war; for, if the provisioning of Fort Sumter had been his only object, he would <PageNumber num={765} /> have sent provisions alone. But while he was professing to be attempting to provision Fort Sumter, he sent armed men to reinforce Fort Pickens.</p>
              <p className="p-in-sp">
                And then, sir, in his answer to the Commissioners from Virginia you see declared his policy, not merely to hold and possess the forts of the South, but that he means war. He has made a requisition for 75,000 men, with whom he means not only to capture and hold the forts, but to carry on war. And I say, for one, that I am ready to meet him any where when he chooses to invade the soil
                of Virginia. He will never get one soldier from Virginia.
              </p>
              <p className="p-in-sp">
                But, Mr. President, I admit that the question to-day is, what must Virginia do? I am ready at this moment to declare that our people shall secede—not because it is the will of my constituents, but because it is the impulse of my own mind and heart. Still the question is, what is best for Virginia to do? Is it politic for us to secede at this moment? We are in a bad position. We have
                only about 10,000 men armed in the State, and only about 5,000 or 6,000 armed well. Virginia is separated from the seceded States. But these are matters for secret session, and I will not discuss them. If gentlemen desire to communicate anything in secret session, I am willing to give them the opportunity. I have no secret to communicate. But there is one great thing to be
                accomplished by secret session, that is, that it will have the effect of producing unanimity among the members of this body. With these views I shall vote for the motion.
              </p>
              <div className="speaker" id="sp3619"><span className="persName">Mr. DORMAN</span>—</div>
              <p className="p-in-sp">
                Mr. President, in supporting the resolution of the gentleman from Albemarle, as I shall do most heartily, I feel it my duty to express very briefly the reasons which impel me to that course. If I considered it as properly a resolution of secrecy, I should oppose it. But it is no such thing; and I wish the Convention to remember and to understand precisely what the effect of its
                adoption would be. The gentleman from Richmond
                [<span className="persName">Mr. MACFARLAND</span>]
                , told us, in his argument against this resolution, that he desired members of this Convention to act under all the responsibilities that attach to their position, and that that could not be so, unless what they said and did were in public. I beg leave to say that, even in private session, even in withholding temporarily from the public what the course of debate and of action may be,
                I consider we would be still acting under every responsibility.
              </p>
              <p className="p-in-sp">The suggestion which occurred to my mind was that expressed by the gentleman from Frederick [<span className="persName">Mr. CONRAD</span>]
                -that there is, in reality, no secrecy about the proceeding. We retain the reporters in the hall, <PageNumber num={766} /> they record our proceedings, and whenever we deem it proper to take off the seal of secrecy, the proceedings are to be published as freely, as completely, as unreservedly, as everything has been published since the commencement of
                our session.
              </p>
              <p className="p-in-sp">
                For my own part, I am free to confess that in secret session, if you so call it, or in private session, as I prefer to call it, I shall say or do nothing that I will not be willing to have published before my constituents, and before the world; and I will not consent to go into any session the proceedings of which are to be kept secret after the adjournment of this body. Thus we
                remove the objection that has occurred to the minds of gentlemen with regard to the bugbear of secrecy, that whatever is done in secret is necessarily wrong, or has some wrong intendment. But there are grave reasons, it seems to me, that we should, for the present, withdraw from the face of the public, and especially, from the face of the powers that be, in the city of Washington,
                what we say and do here. I might advert to a single fact in illustration of this—a fact that was adverted to by the gentleman from Richmond
                [<span className="persName">Mr. MACFARLAND</span>]
                , when he said that there was as good reason for having secrecy enjoined on some of the proceedings of last week, as there can be for enjoining secrecy now. I agree with him that some of the sayings in this body had better not be published, and if we are to have a repetition of them, in the face of this threatening cloud of war which hangs over the Northern horizon, if we are to have
                any of those out-goings of temper on the one side or the other, then I say, in the name of God, let us have the family quarrel to ourselves, and not before the face of the world. I believe and hope, we are not going to have any repetition of them. My object is harmony. First of all, I want to harmonize and unite Virginia. If the union of the States be important to us, how vastly more
                important is the union of the State? Let us try to come to that. I believe we will make a great advance towards it when we come to a private consultation previous to going before the public.
              </p>
              <p className="p-in-sp">
                But it is said again that it is desirable the moral power of the action of this Convention should be brought to bear, from day to day, on the powers at Washington, and should be communicated to the other States. I say, for one, that I am tired of trying moral power on Abraham Lincoln—tired of it. We sent our Commissioners to Washington the other day. They had an interview with him
                and he returned an answer which, I think, discloses enough. But he did not say one word to them about the proclamation which is now authenticated before this body. He returned an answer to them one day, and the <PageNumber num={767} /> next day, by the time our Commissioners arrived in the city of Richmond, we received the proclamation of war. As long
                as there was doubt about the authenticity of this document, as long as there could be the least room for suspicion that it might not be authentic, we might have hesitated, and the balance might have hung in doubt. But the gentleman from Richmond has informed us that he has seen that document printed in the columns of the "National Intelligencer."
              </p>
              <div className="speaker" id="sp3620"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">I desire to be understood as having some doubt about it; but my impression is that I read it there.</p>
              <div className="speaker" id="sp3621"><span className="persName">Mr. DORMAN</span>—</div>
              <p className="p-in-sp">
                Well, that is the gentleman's impression. If the proclamation be a fact I ask any gentleman in this assemblage what Abraham Lincoln means by summoning 75,000 troops into the field? Is there a man here who will insult the intelligence of Virginia by saying that he does not mean war and subjugation? Is there a man in this Commonwealth who will hold himself up before the community as
                such an arrant fool as to say that he does not understand and know that it means war and subjugation? If it means war then I want to know if it is desirable that our action here should be immediately known at Washington? When the Commissioners went there and presented the resolutions of this body, they were informed by the President of the United States that he was aware of their
                coming, that he had read the proceedings of the Convention, that he knew what was done and was prepared to receive them. No doubt he was ready to give them their answer even before they set foot in the city of Washington. We may draw a valuable lesson from that. No one doubts that, in the face of threatened war, it would be the extreme of stultification and folly on our part, to
                proclaim to our enemies what we think and what we are doing. For that reason I shall have no hesitation in voting to go into private session, and to remain there as long as may be necessary, during the session of the Convention. One sufficient reason, if there were no other, is that my friend from Montgomery
                [<span className="persName">Mr. PRESTON</span>] , declared that things were to be communicated there which could not so well be communicated in public.</p>
              <div className="speaker" id="sp3622"><span className="persName">Mr. PRESTON</span>—</div>
              <p className="p-in-sp">No, sir; I did not say that.</p>
              <div className="speaker" id="sp3623"><span className="persName">Mr. DORMAN</span>—</div>
              <p className="p-in-sp">I mean to say that there would be greater frankness in speaking. I understand that perfectly well. Having now stated the reasons which will influence my vote, I yield the floor.</p>
              <div className="speaker" id="sp3624"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                I rise, sir, not to make a speech, but to make a motion, which for 30 years of service, State and Federal, I have scarcely made three times in my life. Gentlemen say we want to harmonize—some say that they fear that harmony and unity of purpose will be disturbed <PageNumber num={768} /> by going into secret session. I would say that they are in much
                more danger of having their harmony disturbed by this discussion, which is, sir, playing with peace and war. And let me say, sir, what my experience in public life entitles me to say; that gentlemen do not seem to apprehend the correct idea of secret session.
              </p>
              <p className="p-in-sp">
                What is the true idea of a secret session, with which all men are so familiar, especially in our knowledge of the constant daily practice of the Senate of the United States? Sir, do they go into secret session because some member of the Senate has some secret to disclose? No, sir. In ordinary executive appointments—subjects even as unimportant as that—they go into secret session.
                Why? Because, when nominations are made to the Senate, there are testimonials of qualification, and some times complaints against character, or the unfitness of the nominee to be offered. They go into secret session to discuss all the testimonials, and all the charges made against the characters of individuals nominated by the executive, because to do so in public would be to set all
                society at war. The Senate, therefore, goes into secret session, not because any Senator has anything to disclose, but because, Mr. President, there is a secret in the subject itself. I ask the gentlemen if any subjects are fuller of secrets of mysteries upon mysteries—than the subject of peace and war? Have you not incidental questions upon which the people are not to vote at
                all—incidental, altogether accidental to the main question—to consider? Have you not to consider steps and preparations pro salute populi? Are you, the guardians of the people, called to look over their safety, seeing them about to be destroyed, to wait to ask the people whether the preparation shall be made to save them? Are you going to tell the enemy that has proclaimed war upon
                you, the means by which you are going to defend the people, and the preparations you are going to make? The circumstances have not been considered by those who are not favorable to secrecy in those matters. Oh! the sweet mysteries of peace—Oh! the dreadful mysteries of war! Sir, for God's sake quit talking. I have a right to say so if any member on this floor has it, for I have a
                worn out lacerated lung. As long as you were debating ultimata here—as long as you were in a state of hopefulness, I endeavored, day in and day out, to help you—to try to help myself—to hope. The time for action has now come. Action, sir, with lips not only unopened but compressed to immovable firmness. If there be any doubt about that proclamation that has come to you on the wires
                of electricity being authentic, you have an official report from your Commissioners that more than confirms that proclamation. Admit that a proclamation has <PageNumber num={769} /> not been made to the United States of America, commanding whole sovereignties to obey this idol at Washington; a communication has been made to you and to me containing all
                that and more than is contained in the proclamation which is intended for you and for me as well as the Confederate States. Not only that, but you have an official call from the Governor of South Carolina, upon our Executive, addressing to Virginia the question, aye, before the proclamation, before the message to you—What will Virginia do? The Executive has told you in an official
                communication that he cannot answer that question. He has referred it to this body which represents the sovereignty of Virginia. What Virginia will do? Sir, with a view to answer that question quietly, deliberately, we are asked to go into secret session.
              </p>
              <div className="stage it">[At this stage <span className="persName">Mr. BARBOUR</span>, of Culpeper, handed <span className="persName">Mr. WISE</span> a despatch.]</div>
              <div className="speaker" id="sp3625"><span className="persName">Mr. WISE</span> proceeding—</div>
              <p className="p-in-sp">
                Here is a telegraphic despatch just received by my friend from Culpeper, which he vouches for as being from a gentleman of the highest respectability. It says that "the proclamation of the President is in the 'Sun' of to-day. He calls upon the several States of the Union for seventy-five thousand troops to suppress combinations and to cause the laws to be executed—details to be
                communicated to the State authorities—Congress called by the 4th of July—declares the first step to be to re-possess the forts, &amp;c. I write now from the proclamation dated to-day."
              </p>
              <p className="p-in-sp">The gentleman who handed me this paper vouches for the respectability and responsibility of the gentleman who sent it.</p>
              <div className="speaker" id="sp3626"><span className="persName">Mr. JAS. BARBOUR</span>—</div>
              <p className="p-in-sp">Mr. Read, of Alexandria.</p>
              <div className="speaker" id="sp3627"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">There is no doubt about the fact of the proclamation being issued. But, independent of that, as I have already said, we have an official record to go upon ; and with a view to come to action, if it be in order, I move the previous question.</p>
              <p>The call for the previous question was sustained, ten minutes being allowed to each to speak upon the question whether the main question should be ordered.</p>
              <div className="speaker" id="sp3628"><span className="persName">Mr. EARLY</span> said—</div>
              <p className="p-in-sp">I desire to say a word upon this question.</p>
              <p className="p-in-sp">
                Mr. President, I trust I have a full sense of the high responsibilities resting upon me as a member of this Convention. When I consented to accept a seat on this floor, I was perfectly aware and had come to the conclusion in my own mind that the time would arrive when I would have need for all the physical and all the moral courage with which my Creator had endowed me. I trust that
                in the emergency <PageNumber num={770} /> which now presses upon me, I shall be able to discharge my duty as it becomes a representative of the people.
              </p>
              <p className="p-in-sp">Mr. President, I regretted much to see the line of remark indulged in this morning. I do not see anything in the information now before us requiring this time that we shall go into secret session. Like the gentleman from Albemarle [<span className="persName">Mr. HOLCOMBE</span>]
                , and his friends, I have nothing to conceal from the public. I have not heard it intimated by any member that he had any secret to communicate to this body. The gentleman from Albemarle certainly did not intimate any such thing. I desire that all my opinions shall be expressed in the face of the public, and I trust I shall have the nerve to express them freely, boldly and
                independently, without regard to any influence which may be brought to bear upon this Convention from any quarter.
              </p>
              <p className="p-in-sp">
                Sir, I do not think that the despatch that the gentleman from Princess Anne has read, places the authenticity of this proclamation in any better shape. If the proclamation is published in the Baltimore "Sun" of this morning, it might as well be published in the Richmond papers; but I would ask how is it, if it has been issued in any of the Washington papers—in the official organs of
                the Government, that the gentleman in Alexandria is not informed of that fact. I think the gentleman from Richmond city
                [<span className="persName">Mr. MACFARLAND</span>] , must be entirely mistaken in the impression that he has, of having seen the proclamation last evening in the Washington "Intelligencer."</p>
              <div className="speaker" id="sp3629"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">Will the gentleman allow me to say that I have no certainty as to whether I did see the proclamation in the "Intelligencer."</p>
              <div className="speaker" id="sp3630"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">
                The Washington "Intelligencer," which would contain the proclamation, if it was issued, has not arrived here as yet. It was published this morning and could not, therefore, reach here as yet. I doubt exceedingly the authenticity of this proclamation for another reason. The present Secretary of State, as we all know, is a statesman —able, prudent and discreet, and there are internal
                evidences in that proclamation which afford the strongest evidences of its not emanating from a source characterized by these qualities and of its entire want of authenticity. I cannot conceive under what law or authority any such proclamation as that could be issued. I am not aware of any law, now existing, under which the President has the power to call out the Militia without
                first issuing a proclamation by way of warning; and it is only in case that shall fail that he has a right to call on the military authority in aid of the civil authority. It seems to me, moreover, very strange that in his proclamation calling out troops, he would also <PageNumber num={771} /> embody a call for the assembling of Congress at a distant
                day. I cannot think that a statesman as able, discreet and prudent as the Secretary of State is known to be, could have been guilty of the blunders which appear in that proclamation. It may be true; but if it is true, it is certainly an evidence that the administration and its officers have lost all prudence, discretion and good sense.
              </p>
              <p className="p-in-sp">
                Now, sir, what would be achieved by going into secret session on this day? Will we place the Commonwealth in any better condition to resist aggression than we can to-morrow? Have we the means to do it? Can we accomplish any good by acting precipitately upon information which is not authenticated? We may do a vast deal of harm. I think it would be wise and prudent for us to take some
                time to deliberate upon this matter, and with that view, I shall move that the Convention do now adjourn.
              </p>
              <p>The motion was lost.</p>
              <div className="speaker" id="sp3631"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">
                I was about to state, Mr. President, when the point of order was raised awhile ago, the objection I had to go into secret session. I have not heard yet any sufficient reason assigned for so striking a departure from what I regard as one of the fundamental principles of free government—that the discussion of the representatives shall be held in open session subject to observation—of
                the constituent body.
              </p>
              <p className="p-in-sp">
                Sir, it seems to be thought here that every motion to go into secret session must prevail as a matter of course. The argument is this : "I move to go into secret session. You can give your reasons against it, but I cannot give my reasons for it; and therefore you must of necessity, go into secret session every time I ask it." I deny the right of any such proposition. There may be
                reasons given for going into secret session without any improper disclosure of what is to be done or what is to be considered in secret session. And, sir, I take it to be a sound principle, that all questions determining the Legislative policy to be adopted by this Commonwealth, or the policy to be adopted by this Convention, after all, must go before the people for their
                ratification or rejection; that upon these questions it concerns the rights and interests of our constituents, that they shall know at every step and every stage of our progress, the reasons which have been assigned by those advocating certain lines of policy, and the reasons which have led us to our conclusions.
              </p>
              <p className="p-in-sp">
                Sir, I look upon it as a grave responsibility for any representative body to take, to withhold from their constituency a full observation of all that they do. If we shall determine to enter upon a policy of war, <PageNumber num={772} /> if we shall determine to do anything the execution of which requires secrecy, then, sir, we might have executive
                sessions, for the purpose of forwarding these objects. But, sir, when it is deliberation as to the line of policy to be adopted, it seems to me that our constituents have a right to know all about it as it progresses ; because, sir, one of the great checks upon a popular representative body is the return of the sentiment of the community upon them while they are in consultation; and
                I suppose that the gentlemen who differ with me on this floor, would hardly deny the efficacy of that sort of re-acting influence upon a public body during its deliberations. We have had an evidence of it here—indications constantly coming up from the people in the way of commentaries upon the pending discussions of this body, brought here for the purpose of influencing the
                determination of this body itself ; and I conceive, sir, that it is a grave responsibility for us to undertake to cut ourselves off from the current contemporary commentary of our constituents; and, for one, I am unwilling to adopt any such policy. I think we are not at this time in a position, in point of information, to justify us in departing from the ordinary course of the
                proceedings of this body. I do not recognize the propriety of turning to the right or to the left upon unauthenticated telegraphic despatches. I think that all of us have a right to ask of our associates in this body, that we shall not be pressed into any extraordinary course of proceeding upon half gathered information, and that we shall have such indulgence in point of time as is
                necessary to enable us to get information upon which, as discreet men, as prudent representatives of the people, we can feel satisfied to base the most important action.
              </p>
              <p className="p-in-sp">
                Sir, I dislike the idea of being forced into a secret session, or into any action on the subjects that are now alluded to until I know more about them. I am not willing to take for granted every dispatch and every rumor. I have seen enough, since I have been a member of this body, I see enough now to make me believe that much of the crisis that is forced upon us now has been forced
                upon us at this time with a view to influence our action, with a view to determine our course. I have strong belief that that is the fact; and, sir, while it may result that we shall be compelled to yield to the influence thus brought to bear upon us, I 'think we should have an opportunity of sifting to the bottom the genuineness of the information upon which we are called to act.
              </p>
              <p className="p-in-sp">
                Sir, as I said this morning, I can have no hesitation, when it comes to war or subjugation against the South, as to what position I shall <PageNumber num={773} /> occupy; but I claim that before I take position in regard to any war, before I take position as a representative of Virginia which is to place her in the front rank of the battle, before I
                take any position that is to transfer the seat of this war from the South to Virginia, I claim, as a representative of a portion of the people of Virginia, that I shall see before me as clear as the sunlight the events and facts upon which I am called to act. It is a momentous responsibility that we are called on to take ; and I want to consider the question in open session, and with
                the deliberation necessary to gather all the facts upon which we have to act.
              </p>
              <div className="speaker" id="sp3632"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">
                I wish to make a remark to correct a statement of mine which may make an erroneous impression. I said that I believed I read the information to which I referred in the "National Intelligencer" of Saturday. I find it is not in that paper of Saturday, and I desire to correct any erroneous impression that might arise from the statement. I read my city paper so early this morning that I
                had gotten my memory confused about it.
              </p>
              <div className="speaker" id="sp3633"><span className="persName">Mr. GILLESPIE</span>—</div>
              <p className="p-in-sp">
                Mr. President, I voted for the resolution which has been alluded to here, that if the contingency should ever arise which is now supposed to exist, that the State of Virginia would make common cause with the Southern States. I voted for that resolution on a full conviction of all the responsibility attached to it. I voted for it with a calm conviction, and with a fixed and determined
                purpose that if the occasion should arise, I would stand up to it. I feel no inclination or disposition at this moment to shrink from that responsibility. But, sir, I confess that I have been so often deceived by despatches which have arrived here, by despatches which have been sent here for the very purpose of influencing our section in this assembly—although the dispatch now before
                us does come with imposing demonstrations—yet, sir, I confess, that even with all the appearance of authenticity attached to it, I have some misgivings. It does seem to me, if I understand the purport of it, that at any rate within the next six hours, a requisition might be made upon the Governor of this Commonwealth for men. If I understand it, it contemplates a requisition upon the
                Governors of all the States for certain numbers of men. So soon as that request shall be made, doubtless we shall be authoritatively informed of it by our Governor. When we shall receive such information as that, I, for one, will be better prepared to act. I shall then conceive that I am prepared to act advisedly. At present, I confess, I do not feel prepared to act advisedly. I
                think it more than probable, sir, that other men in this <PageNumber num={774} /> Convention occupy the same position in that respect which I do. And those of my friends on this floor who have arrived at a fixed purpose, who have dissipated all doubt and are now prepared to act, I hope, as a matter of favor, as a matter of courtesy to us who are not yet
                fully prepared to act upon these questions, will indulge us until to-morrow; and I therefore move an adjournment.
              </p>
              <p>The motion was put to the House, and upon a division, 63 voting in the affirmative and 44 in the negative, the Convention, at a quarter to one o'clock, P.M., adjourned until Tuesday at 10 A. M.</p>
              <div className="note" id="Note83">
                <span className="noteLabel">2</span>Mr. Turner, of Jackson, referred in the Proceedings of April 16, Vol. IV, p. 55, to proceedings of meetings of his constituents which he had presented the day before. He probably was speaking of reports of meetings at Sandyville on April 6 and Jackson Court House on April 8, printed in the Enquirer on April 30. There is no record in the Proceedings for
                April 15 of the presentation of these reports, which were printed in the Enquirer as follows:
                <p>Mr. Turner, of Jackson, presented the following proceedings in the Convention, a few days since :</p>
                <p>At a large and respectable meeting of the Southern States' rights men of Jackson county, held at Jackson C. H. (it being court-day) on Monday evening, April 8th, 1861, Miles Jacoby, Esq., was called to the Chair, and E. B. Wright, Dr. B. H. Hoyt and 0. L. C. Hinesman were appointed Secretaries.</p>
                <p>George N. Fitzhugh, Esq., was then called upon to explain the object of the meeting which he did in a few brief and pointed remarks.</p>
                <p>O. L. C. Hinesman then made a short speech with regard to the objects of the meeting, as well as the Union or submission meeting held on this day.</p>
                <p>F. P. Turner, Esq., was then called upon, and delivered some spirited remarks with regard to the present crisis, and also the action of the present State Convention now in session at Richmond, which were received with great applause.</p>
                <p>Mr. Hinesman then offered the following preamble and resolutions, which were adopted unanimously: Whereas, a meeting was held on this day calling itself a "Union Meeting ;" and, whereas, as said meeting, composed of a few submissionists, passed certain resolutions favoring submission; therefore,</p>
                <p>Resolved, That this meeting, composed of Southern States' rights men declare that the action of the so called Union meeting is a political swindle, and that those few individuals composing the said so called Union meeting had no right to dictate to the people of Jackson county; and</p>
                <p>Whereas, ample time has been given to the Northern States to restore to the South her rights, and that all honorable means having been exhausted by the Southern States in arduously attempting to secure themselves from the wrongs, injuries and oppressions which have been imposed upon the South by the Northern tyrants and fanatics, therefore,</p>
                <p>Resolved, That our Delegate in the Convention be requested to vote for any ordinance dissolving the ties now existing between the Commonwealth of Virginia and the late United States.</p>
                <p>Resolved, That we approve of the course F. P. Turner, Esq., our delegate in the Convention, has pursued, and that we offer him our sincere thanks therefor.</p>
                <p>Nine cheers were then given for Jeff Davis, President of the Confederate States of America, and nine groans for Abe Lincoln.</p>
                <p>On motion of M. J. Kester,</p>
                <p>Resolved, That the proceedings of this meeting be published in the "Virginia Chronicle," Kanawha "Valley Star,- Richmond "Enquirer" and Richmond "Examiner;" and, also, that a copy of these proceedings be presented to F. P. Turner, Esq., our delegate in Convention.</p>
                <p>On motion, the meeting adjourned sine die.</p>
                <p>MILES JACOBY, Chairman</p>
                <p>B. H. HOYT,</p>
                <p>E. B. WRIGHT, Secretaries</p>
                <p>O. L. C. HINESMAN,</p>
                <p>PUBLIC MEETING IN JACKSON COUNTY</p>
                <p>At a meeting of a portion of the people of Jackson county, irrespective of party, held at Sandyville, on the 6th day of April, 1861, on motion of G. N. Fitzhugh, Michael Crow was called to the Chair, and Daniel W. Sayre appointed Secretary.</p>
                <p>The object of the meeting having been explained by Robert Park, George N. Fitzhugh, Esq., addressed a few pertinent remarks upon the true policy of Virginia in relation to the late Federal Union.</p>
                <p>On motion of Dr. W. B. McMahon, the Chair appointed a committee of five to draft resolutions expressive of the sense of this meeting. Whereupon the Chair appointed the following gentlemen : Dr. W. B. McMahon, George N. Fitzhugh, Robert Park, Daniel W. Sayre and David Curry, who, after a short absence, reported the following resolutions, which were unanimously adopted :</p>
                <p>
                  1. Resolved, That this meeting endorse and heartily approve the resolutions offered by F. P. Turner, Esq., delegate from Jackson and Roane counties in the Convention, as maintaining the true doctrines of State rights and State sovereignty; that we believe in the right of a State to withdraw her allegiance from the Federal Government whenever she deems herself wronged or oppressed.
                </p>
                <p>2. Resolved, That we recognize no right in any Government to coerce a sister State into submission, who may, for good cause, have seceded from the late Federal Union ; and that we will regard any such attempt as a declaration of war and resist it to the last extremity.</p>
                <p>3. Resolved, That the delegate from Jackson and Roane counties be requested to vote against any proposition that may be submitted to the present Convention of Virginia for holding a Border State Convention at any future period, for we are sick of delay and humbugs.</p>
                <p>4. Resolved, That if an ordinance for the immediate secession of Virginia shall be proposed, that we request F. P. Turner, Esq., our Delegate, to vote for the same as the only means, in our opinion, left to save our good old Commonwealth from Black Republican domination and the grinding oppression of a Black Republican tariff.</p>
                <p>5. Resolved, That the proceedings of this meeting be published in the Parkersburg News, Richmond Examiner, Enquirer and Dispatch.</p>
                <p>MICHAEL CROW, Chairman</p>
                <p>D. W. SAYRE, Secretary</p>
              </div>
            </div>
          </div>
          <div className="day" id="vsc1965.v3.2.13">
            <PageNumber num={775} />
            <h2><span className="headingNumber">1.13. </span><span className="head">FIFTY-THIRD DAY</span></h2>
            <div className="dateline">Tuesday, <span className="date">April 16</span></div>
            <p>Prayer by REV. DR. DOGGETT, of the Methodist Episcopal Church.</p>
            <div className="section" id="vsc1965.v3.2.13.1">
              <h3><span className="headingNumber">1.13.1. </span><span className="head">COMMITTEE ON TAXATION QUESTION</span></h3>
              <div className="speaker" id="sp3634"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair will announce the Committee upon the resolution of the gentleman from Monongalia [<span className="persName">Mr. WILLEY</span>] .</p>
              <p><span className="persName">The PRESIDENT</span> announced the names which are as follows :</p>
              <p>Messrs. Waitman T. Willey, Franklin P. Turner, Napoleon B. French, John A. Campbell, Alfred M. Barbour, George Baylor, Geo. P. Tayloe, Wm. M. Tredway, Wm. M. Ambler, John Q. Marr, John R. Chambliss, Fendall Gregory and Geo. W. Brent.</p>
              <div className="speaker" id="sp3635"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The pending question this morning is shall the main question be now put on the resolutions of the gentleman from Albemarle?</p>
              <p>Calls of "Question, question."</p>
              <p>The question was put to the House, and the main question was ordered to be put.</p>
              <p>The resolutions of the gentleman from Albemarle [<span className="persName">Mr. HOLCOMBE</span>] , were then adopted as follows :</p>
              <p>Resolved, That the standing order resolving the Convention into Committee of the Whole be suspended.</p>
              <p>Resolved, That the Convention will immediately go into secret session, to consider the Report of the Committee appointed to visit Washington.</p>
              <div className="speaker" id="sp3636"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The galleries and lobbies will be cleared. The galleries and lobbies were accordingly cleared.</p>
              <p>The Sergeant-at-arms was sent for.</p>
              <div className="speaker" id="sp3637"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Sergeant-at-arms is required to report to the Chair that the order has been executed, and that there are no persons in the room but those who are authorized to remain.</p>
              <div className="speaker" id="sp3638"><span className="persName">Mr. TYLER</span>—</div>
              <p className="p-in-sp">
                Mr. President, I know very well, sir, that it is an invidious task to move the exclusion of any one from this secret session ; but it does appear to me to be altogether a farce, if you are to retain all the reporters here, and all the pages, and all the door-keepers, why you might just as well not have gone into secret session at all; you might just as well despatch from the lips of
                every man who speaks upon this floor upon this occasion, over the telegraph wires, what is said, to the whole world. Sir, you must limit <PageNumber num={776} /> this thing. If you make an exception in the case of any, it should be in favor of the single reporter who commenced reporting at the first stage our proceedings. Otherwise all this pretence of
                secrecy is a mere fancy. It has no actual existence; no reality. I move you, therefore, sir, in the first place, that the door-keeper whom you retain, be sworn to secrecy, that you shall dispense with your pages, and that all the reporters shall be asked to retire from their seats, with the exception of the regular reporter who commenced reporting these proceedings, and who also
                shall be subjected to an oath of secrecy.
              </p>
              <div className="speaker" id="sp3639"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">
                The Chair has announced to many gentlemen here, in private, its purpose, so soon as the galleries and lobbies were cleared, to bring to the attention of the Convention the very questior raised by the gentleman from Charles City. We are without any rule upon that subject; but the rule in the Senate of the United States, as I am informed, is that the reporters are always excluded, and
                that the officers are all sworn to keep profoundly secret all the proceedings and all the debates of the body in secret session. Yesterday it was announced by many gentlemen on the floor, that there was no idea of excluding the reporters. Therefore the Chair gave the Sergeantat-Arms and door-keeper no order upon that subject, leaving it to the Convention to decide after the galleries
                and lobbies should have been cleared.
              </p>
              <div className="speaker" id="sp3640"><span className="persName">Mr. WICKHAM</span>—</div>
              <p className="p-in-sp">Mr. President, I would suggest to the gentleman from Charles City, that the manner in which the debates are taken down would render it impossible for one reporter to do the whole reporting during the entire session. Therefore, it would be better to include not merely one reporter, but as many as may be necessary to relieve him.</p>
              <div className="speaker" id="sp3641"><span className="persName">Mr. TYLER</span>—</div>
              <p className="p-in-sp">Everybody is excluded from the Senate of the United States and in the House of Representatives.</p>
              <div className="speaker" id="sp3642"><span className="persName">Mr. CONRAD</span>, of Frederick—</div>
              <p className="p-in-sp">
                Mr. President, I think that before taking a vote upon that subject it would be proper to ascertain some facts, of which I, for one, am ignorant in regard to this reporting. We have contracted, as I understand, with the editor of the Richmond "Enquirer" to report the debates and proceedings of this Convention. I heard in the early part of its session from one of the editors of the
                "Enquirer" that he had employed, I think he said, two stenographic reporters, to take down these debates, and I had supposed that the debates and proceedings would be continued to be reported in secret session, and that all necessary precaution against their being divulged would be taken not only by subjecting the <PageNumber num={777} /> reporters to
                an oath of secrecy, but by actually requiring the delivering up of their notes and memoranda at the close of each day to the clerk for safe keeping.
              </p>
              <p className="p-in-sp">
                I should like to know, first how many reporters it would be necessary to retain. I suppose the party with whom the Convention contracted should designate the reporters to be retained. I certainly had no idea that our proceedings in secret session were not only to be excluded from the public for the present, but were to be absolutely suppressed. I think it would be important to have a
                record of the proceedings in secret kept. I understand now, that one reporter will be sufficient, and with that understanding, I shall vote for the resolution of the gentleman from Charles City.
              </p>
              <p>The resolution offered by <span className="persName">Mr. TYLER</span>, was agreed to.</p>
              <p>The Hall was then cleared of the reporters, with the exception of the one designated in the resolution.</p>
              <div className="note" id="Note84">
                <span className="noteLabel">1</span>A report of a public meeting held in Culpeper County on April 15, printed below, was published in the Enquirer of April 30. There is no indication either in the Proceedings or in the Journal of when the report was presented to the Convention, but it seems likely that it would have been presented on April 16, and not improbably before the Convention
                went into secret session.
                <p>PUBLIC MEETING IN THE COUNTY OF CULPEPER</p>
                <p>Mr. Barbour, of Culpeper, presented the following proceedings in the Convention:</p>
                <p>At an immense meeting of the citizens and voters of the county of Culpeper, held to-day, the fifteenth of April, 1861, the following preamble and resolutions were unanimously adopted :</p>
                <p>
                  Whereas, the proclamation of the President of the United States, dated this day, has reached us, by the mail of this morning, and informs us, of the purpose and policy of the Cabinet in Washington in respect to our national troubles, as finally agreed upon, and ordered to be officially proclaimed ; and whereas, the said proclamation announced to the world the intention of the
                  President to maintain the Union by force of arms and invokes the cooperation of the military and civil powers of the country, to that end and by that means ; and whereas, in the opinion of this meeting the maintenance of free institutions by military force, and the preservation of the public harmony by actual war, is equally impossible in fact as absurd in theory; and whereas, the
                  people of this ancient county have on all occasions, up to this present moment, evinced by the most unequivocal testimonials their deep devotion and sincere loyalty to the most magnificent experiment in behalf of human liberty which the annals of human society have recorded, and have hoped and struggled whilst doubts existed as to the designs of power, without ever for a moment
                  intending to foment or to countenance sectional discords or divisions on a question, in regard to which there is in fact a perfect community of interest and sentiment. Therefore, this meeting feels it to be its duty and its privilege to announce promptly and briefly, but distinctly its feelings and opinions—and accordingly it is resolved, unanimously,
                </p>
                <p>That the means and expedients for a pacific and patriotic solution of our national troubles are finally and entirely exhausted.</p>
                <p>That it is now the sacred duty of all our people, without reference to any conditions of personal inconvenience, to unite together and present an undivided and undivisible front in support of the interest, the honor, and the order of our beloved and venerable sovereign and mother, the ancient Commonwealth of Virginia.</p>
                <p>That with us political parties exist no more—the memory of their passions and conflicts is delivered to oblivion.</p>
                <p>Resolved, further, That we will not countenance or permit contests for public stations, and we do hereby re-nominate our present representatives, State and Federal.</p>
                <p>
                  And, finally, be it resolved as the opinion of this meeting, respectfully expressed to our State Convention, now in session, That Virginia has no alternative but to reclaim all the attributes of her original sovereignty, and to announce immediately, by solemn ordinance, her fixed purpose to seek such future affiliations as are most favorable and convenient to the interests and
                  security of her own people.
                </p>
                <p>
                  The proclamation of the President was, by general request, read by John S. Pendleton, after which he submitted the foregoing preamble and resolutions, which, he stated, had been very hastily prepared at the instance of a number of the oldest and most influential citizens ; and he accompanied them with a few remarks, which were received with much applause. He stated that he was just
                  from Washington, where he had been for as much as a couple of days, for the first time in two years, on the private business of a personal friend ; that he had sought to get from the highest sources the best information he could ; but that he had nothing to report, and no information, but what was common to the whole public by means of the public press.
                </p>
                <p>The proceedings were adopted unanimously, and with remarkable enthusiasm, and ordered to be published, and communicated to the Convention and to James Barbour and Hon. Wm. Smith.</p>
                <p>
                  The crowd then called upon a number of gentlemen—Mr. Daniel F. Slaughter, Richard H. Cunningham; Col. Freeman, Jas. L. Stringfellow, Messrs. Patton, Gray, Field and Green, of the Culpeper bar; Gen. Kemper, of Madison county; Mr. Gabriel Jones, lately of Iowa, though born and raised in Culpeper, and others, all of whom responded in the same spirit, and in terms which frequently
                  elicited very great approbation.
                </p>
                <p>The meeting was held in the public streets in front of the Piedmont Hotel, because there was no room in the place large enough to hold half the crowd.</p>
              </div>
            </div>
            <PageNumber num={778} />
          </div>
        </section>
      </div>

      <div className="tei_back" id="v3back">
        <section className="back" id="vsc1965.v3.3">
          <header>
            <h1><span className="headingNumber">Appendix A </span><span className="head">NOTES</span></h1>
          </header>
          <PageNumber num={780} />
          <div className="notes" id="vsc1965.v3.3.1">
            <h2><span className="headingNumber">Appendix A.1 </span><span className="head">NOTES</span></h2>
            <p>The notes below are numbered 1 and upwards for each day of Convention Proceedings.</p>
            <p>Dates in parentheses are dates of issues of the Enquirer which contain editorial corrections for its reports of Proceedings.</p>
            <p>Page numbers in citations of Proceedings are numbers of pages in this edition.</p>
            <p>References to Documents, and to Journal or Convention Journal, are references to the printed official Documents of the Convention, and to the printed Journal of the Acts and Proceedings of a General Convention of the State of Virginia ... (Richmond : Wyatt M. Elliott, Printer, 1861 ) .</p>
            <p>FORTY-FIRST DAY</p>
            <p>Tuesday, April 2</p>
            <p>1. This speech by Mr. Macfarland has not been found in the Enquirer. On April 3 the Daily Dispatch published this abstract of the proceedings including the speech :</p>
            <p><span className="persName">Mr. MACFARLAND</span>, of Richmond, took the floor, but the Chairman requested him to suspend his remarks for a moment, while the Sergeantat-arms again cleared the gallery and the lobby.</p>
            <p><span className="persName">Mr. MACFARLAND</span> and <span className="persName">Mr. WISE</span> appealed to the Chair to withdraw the order, which the Chair consented to do, alluding to the pain which it gave him to enforce such an order, and admonishing the spectators that if the thing were again repeated, the order would be peremptorily executed.</p>
            <p>
              <span className="persName">Mr. MACFARLAND</span> then commenced his speech with the remark that he entered upon the discussion with a painful sense of the responsibility which the position implied. When the secession movement began, the country was in the plenitude of its strength ; yet the evils resulted from causes which had long been operating in the public mind. No Union could long
              exist, where the people on one side constantly exercised a spirit of hostility against the people of the other side, and to this constant agitation he attributed the present evils overshadowing the country. He proceeded to consider the question whether the sentiment of hostility at the North might be overcome and supplanted by a sentiment of kindness and friendliness to the South. It
              was, he conceived, the part of wise statesmanship not to dismiss and abandon institutions like ours, without at least making one intrepid effort to retain them. There was neither courage nor intrepidity in despair. The peace of the world was once placed in jeopardy far more imminent, under the inflaming influence of a religious controversy, than it was now under the agitation
              <PageNumber num={782} /> of the African slavery question; and yet it was found that by toleration the Protestant and the Roman Catholic might dwell together in peace. He apprehended that in years hence it would be found that the people of this country might dwell together as peacefully as the people of any one county in either section. No suggestion of
              the futility of making an effort to this end should discourage us from making it. If success should fortunately crown the effort, there was not an individual here engaged in the work of endeavoring to restore the Union, but would establish a claim upon posterity for reverence and gratitude. If it were possible, by amendments to the Constitution, to close the door of agitation so that
              no human power could open it, was there not at least a prospect of annihilating the evils which have so wrought upon the feeling of the South? He would not say that the report of the committee was sufficient, but he took the ground that amendments might be introduced which would satisfy, not only Virginia, but the whole South. The question is not whether Virginia shall go with either
              the Northern or the
            </p>
            <p>Southern Confederacy, but whether she shall continue in the Union ti</p>
            <p>restored and reconstructed as a whole. He could not consent to abandon</p>
            <p>
              the Southern States, nor the Border States ; but believed that the whole Union should be preserved, and through judicious efforts the North might be brought to proper concessions, such as would satisfy the extreme South. Pennsylvania, according to reliable information, would consent to such a scheme, and with Pennsylvania would come the whole Northwest. With Pennsylvania and the
              Northwest united, it would be impossible to keep New England out of the Union.
            </p>
            <p>On this point <span className="persName">Mr. MACFARLAND</span> argued until half-past 1 o'clock, when he yielded the floor at the request of <span className="persName">Mr. PRESTON</span>, who moved that the Committee rise.</p>
            <p>The Chair could not entertain the motion, since the Committee had agreed to sit until 2 o'clock.</p>
            <p><span className="persName">Mr. SPEED</span> said that the object could be attained by the general consent of the Committee. Mr. Macfarland was laboring under physical disability, and under such circumstances he supposed no objection would be made.</p>
            <p>The Committee then took a recess until 4 o'clock P. M.</p>
            <p>The Committee re-assembled at 4 o'clock, and <span className="persName">Mr. MACFARLAND</span> resumed his remarks.</p>
            <p>
              He said he had, in the morning, invited the attention of the Committee to a practical view of the method by which the great question now agitating the country might be definitely settled. His object was to show that if the subject of slavery was to be taken out of the halls of legislation, and withdrawn from the fields of agitation, we might yet live on terms of friendship and respect
              with the North. Unless this could be done, he would not consent to remain in a Union with the people of that section, for it would be by no means desirable. He took the position that the Northern people were as much interested in perpetuating the institution of slavery as we were, and if the question of its extinction were presented to them, it would be decided in the negative. They
              were dependent upon it for subsistence, and you might as well expect that the collieries of <PageNumber num={783} /> England would be abandoned, as that the Yankees would consent to their own impoverishment by the abolition of slavery at the South. There was, he assumed, a. change going on in the sentiment of the world on the subject, as shown by the
              employment of coolies by France and England. We had a right to insist that the North should let us alone, and he believed that when the naked issue was presented, the North would recede from its aggressive position rather than encounter the evils which must result from a continued agitation of the slavery question. Alluding to the grandeur of the Union in the eyes of the world, he
              thought it would be time enough to pass sentence upon it after our best efforts for its preservation shall have failed. He looked upon it as altogether improbable, that war could be avoided in the event of separation ; and one single war between the North and the South would entail upon each section an amount of suffering and waste which years of industry and successful commerce would
              in vain endeavor to repair. These considerations, he conceived, would have their due weight upon the Northern mind. Bring them to the question, slavery or war, and no sensible man would decide in favor of the latter. He argued that the proper way to remedy existing evils was to close the door forever against agitation. If we could bring the North to agree that there should be no
              introduction of the slavery question in the halls of Congress, no effort to impair the relation between master and slave, the public mind would become quieted, and peace would return to the country. But suppose we were mistaken in this? Would anything be lost by making the experiment ? Is it not as easy to separate twenty years hence as now "? The relative positions of the two sections
              would not be changed. Everything encouraged us to make the effort. He conceived there would be no dishonor in saying to the North we must have these guarantees, or disunion is the inevitable result. It may be said that this has already been done ; but he apprehended it had not been done in the form and with the authority here proposed.
            </p>
            <p>
              He argued that the Federal Government ought, and might now with propriety, acknowledge the independence of the Confederated States, and that it was an indispensable step towards a settlement of the difficulty. He agreed with the gentleman from Middlesex that the condition of the Confederated States was not to be disturbed by force. He held that the Committee ought to agree upon
              something that would speedily settle the pending difficulty, taking care to demand enough to meet the exigencies of the case. He spoke highly of the resources of the Southern Confederacy, and the gallantry of her people, but must be permitted to say that he thought she acted with rather too much haste in going off without consulting with her Southern sisters. He was not, however,
              disposed to quarrel with her on that account. He apprehended that, as strong as the Southern Confederacy was, she would not object to an addition to her strength, if it could be done with perfect security and without sacrifice of principle.
            </p>
            <p>
              <span className="persName">Mr. MACFARLAND</span> went on with an elaborate argument on the question of the Tariff to show that the Southern Confederacy was not entitled to any particular favor from Virginia upon the ground of her avowed policy of free trade; yet he was so firmly attached by every <PageNumber num={784} /> consideration to the Gulf States, that
              he would be unwilling, under any fair circumstances, to turn his back upon them. He was in favor of a further and final effort to reconstruct the Union; and whoever should have the good fortune to so shape the course of events as to bring about a peaceful solution of the great questions at issue, would be entitled to the lasting gratitude of posterity.
            </p>
            <p>2. A correction (April 6) gives this sentence as "I mean no proposed amendment."</p>
            <p>3. The correction (April 6) gives this clause as "I asked of you no earnest."</p>
            <p>4. According to the Enquirer text, Mr. Wise named only four men, including himself.</p>
            <p>FORTY-SECOND DAY</p>
            <p>Wednesday, April 3</p>
            <p>1. The speaker must have said, or meant to say, Indiana.</p>
            <p>2. Mr. Richardson's speech was delivered on April 3 and 4, but the point of interruption is not indicated. All of the speech has been printed as part of the Proceedings of April 3.</p>
            <p>FORTY-THIRD DAY</p>
            <p>Thursday, April 4</p>
            <p>1. Mr. Richardson's entire speech is given in the Proceedings of April 3. See note 2 on those Proceedings.</p>
            <p>2. In the Proceedings of April 6, page 226, Mr. Hull noted that it was he, and not Mr. Hall, who had paired off with Colonel Forbes. It is not clear whether Mr. Hull referred to only one of the two votes in which Mr. Hall was reported to have paired with Colonel Forbes, or to both of them.</p>
            <p>3. See note 2, above.</p>
            <p>4. Some words, perhaps "was ceded," are omitted here.</p>
            <p>5. The Journal refers to and lists only 61 negative votes.</p>
            <p>6. The Journal lists 53 affirmative votes, which is the actual count of affirmative votes in the Proceedings. The Journal has Carter among the 80 negative votes it lists.</p>
            <p>7. The Journal lists 114 negative votes, adding the names of Custis and Fugate to those given in the Proceedings.</p>
            <p>
              8. In the Proceedings of April 9, page 370, Mr. Mallory noted that he had voted in the affirmative on Mr. Harvie's substitute to the 6th resolution of the Committee of Twenty-One. The Journal lists J. B. Mallory, W. C. Scott, R. H. Turner, F. P. Turner and Tyler among the affirmative votes, giving a total of 45. In the negative votes, the Journal omits J. B. Mallory, but includes
              Coffman, Gray, Marshall and Marr, making a total of 90.
            </p>
            <p>9. A correction (April 16) of Mr. Brent's remark is as follows:</p>
            <p><span className="persName">Mr. BRENT</span>—The official report in the "Enquirer" represents me as saying, whilst the motion of the gentleman from Amelia [<span className="persName">Mr. HARVIE</span>] <PageNumber num={785} /> was pending to strike out the sixth section of the report of the Committee of Twenty-One, and to insert the substitute offered by him, that the gentleman from Mecklenburg [<span className="persName">Mr. GOODE</span>] and the gentleman from Fairfax [<span className="persName">Mr. DULANY</span>] had paired off, and that I felt warranted in saying that both gentlemen would have voted no. I stated on that occasion that the gentleman from Mecklenburg had he been present would have voted aye, and the gentleman from Fairfax, no.</p>
            <p>Although the form of the correction looks like that of the Proceedings, the</p>
            <p>correction follows the close of the report of Proceedings of April 9, and has</p>
            <p>no apparent relation to it.</p>
            <p>FORTY-FOURTH DAY</p>
            <p>Friday, April 5</p>
            <p>
              1. These concluding remarks by Mr. Stuart were printed on April 6, divorced from the rest of the Proceedings of April 5. They were printed again on May 17 along with Mr. Stuart's remarks of April 3 and 4 on the same topic. The version used here in the Proceedings of April 5 is the one printed on May 17. The version of April 6 does not have the short second paragraph of the later
              version, and what corresponds to the third and sixth paragraphs of the later version was printed thus on April 6:
            </p>
            <p>
              It will be recollected, Mr. President, that I said yesterday that my constituents were ready and willing to stand up in defence of the rights and institutions of the people of the Eastern portion of this State. In saying so I did not wish to be misunderstood as meaning that the people of the West are willing to take the course pressed by those whom we call the ultra men of the Eastern
              portion of the State; but simply that the people of the West are sound on this question of slavery and are willing, at all hazards, to stand up for the rights and interests of the people of the Eastern portion of this State.
            </p>
            <p>
              I desire to be understood that we are not in favor of the doctrine of the Constitutional right of secession. We believe in the right of revolution. We believe that when our Constitution has been perverted to our injury and oppression, we have the right to throw off the shackles, and appeal to our natural rights. But revolution pre-supposes, in my opinion, a remedy; and, if the fact of
              dissevering ourselves from the Federal Government would be a remedy for the evils of which we complain, then we would be in favor of it. But I cannot see, for my life, how, under present circumstances, secession or revolution would be a remedy for the evils complained of. Will it relieve us from any of our grievances? If we had to complain of a perversion of the Constitution by the
              Federal Government, then revolution, if successful, would be a complete remedy; but if the complaint is in regard to the action of separate States, then secession from the General Government would be no remedy. If it were even successful, we would still find ourselves in identically the same position in which we now are. The institutions and laws of those States, derogatory of the
              rights of the South, would still remain on their statute books.
            </p>
            <p>* * *</p>
            <PageNumber num={786} />
            <p>
              They appeal to us to come to their rescue and help them to save the Union. They have fought for us in days gone by, and now they appeal to our brotherly feeling to come to their aid and to save the Union. If we pursue the course indicated here by the secessionist party, we will cut ourselves loose from our friends in the free States, and array them against us. The whole civilized world
              will then be arrayed, by our action, against the institution of slavery. If we say to those in the North, who have heretofore stood up for our rights, "We will have nothing more to do with you," can you expect or hope to retain the good will and kind feeling of that people ? No sir. By pursuing this course, you will, in my humble opinion, unite the people of the North in one solid mass
              against our institutions. Then we will have the whole world arrayed against us. Can we expect our friends in the North to stand by us, after we have destroyed the Government and brought ruin upon them? It is hopeless to expect so. Then I hold that secession or ,revolution is no remedy for the evils complained of, but is an aggravation of them, and will, if persisted in, lead to the
              extermination of slavery.
            </p>
            <p>I find, Mr. President, that I have but four minutes left before the Convention goes into Committee of the Whole, and, as I could not discuss any other proposition within that time, I will now yield the floor.</p>
            <p>2. The Journal refers to 54 affirmative votes, and lists the affirmative votes given here.</p>
            <p>3. There seems to be some omission in this sentence, as the speaker must have meant to say something like "but which, on the contrary, were carried there for the purpose."</p>
            <p>4. The Journal lists 57 affirmative votes, among them L. S. Hall and Lewis E. Harvie ; the Enquirer seems to have telescoped these two names into "L. S. Harvie." The Journal lists 83 negative votes.</p>
            <p>5. The Journal lists 67 negative votes, adding Haymond and Summers to those given in the Proceedings.</p>
            <p>6. The Journal lists 93 negative votes, adding Maslin and Wilson to those in the Proceedings.</p>
            <p>7. The correct number of affirmative votes is probably 77, as it is in the Journal.</p>
            <p>8. The Journal includes Kilby in the affirmative votes, and Janney and McComas in the negative votes, in addition to those listed here.</p>
            <p>9. According to the Journal, it was Mr. Southall who reported the progress of the Committee. The Proceedings of April 5, page 171, note that Mr. Southall took the chair in Committ -!e of the Whole.</p>
            <p>FORTY-FIFTH DAY</p>
            <p>Saturday, April 6</p>
            <p>1. In the Proceedings of April 9, page 381, Mr. Bouldin made some corrections of the second and fourth paragraphs of his remarks.</p>
            <p>2. The Journal includes Boggess among the 72 negative votes it lists.</p>
            <p>3. The Journal lists the same 69 affirmative votes given in the Proceedings. In the Journal, the name of Jackson makes up the 71 negative votes.</p>
            <PageNumber num={787} />
            <p>4. The Journal includes Marye, but not Williams in the affirmative votes; in the Journal, McComas and Preston are among the negative votes, but Marye is not.</p>
            <p>5. The Journal lists 106 negative votes, adding Woods to those listed in the Proceedings. In the Journal, Willey's name is only among the affirmative votes, and presumably it should be deleted from negative votes in the Proceedings.</p>
            <p>6. A correction (April 11) gives this somewhat different version of Mr. Early's comments on his amendment:</p>
            <p>
              <span className="persName">Mr. EARLY</span> said—The amendment which I have proposed is in the language of the ordinance or form of ratification adopted by the Virginia Convention which ratified the Constitution of the United States. That ordinance or form of ratification was prepared by a Committee of which Governor Edmund Randolph was Chairman and Wilson Cary Nicholas, James Madison,
              John Marshall and Francis Corbin were members, and is in the following words:
            </p>
            <p>* * *</p>
            <p>
              Now, sir, I shall not go into the general argument upon the right of secession—that was fully exhausted thirty years ago—but there is an argument made in Virginia, based upon the phraseology of the form of ratification adopted by her Convention, which is the most embarrassing one the opponents of the right of secession have to encounter. On day before yesterday, the word "several" was
              inserted, by a vote of this body, in the place of the word "united," in the third line of the fourth resolution; upon the express idea that the words, "the people of the United States," were words of consolidation, and sustained the doctrine that the Federal Government is a consolidated government. Now, sir, the language of the form of ratification adopted by the Virginia Convention is
              the language of her wisest and best statesman, and if the gentlemen of the States' rights school of politics, on this floor, think that this language contains the doctrine of the right of secession, and are willing to risk that doctrine upon that language, I call upon them to vote for my amendment; but if they do not think that it contains that doctrine, but contains the doctrine of
              consolidation, then I want them to cast their votes against the amendment, and forever put an end to the argument in favor of the right of secession, based upon the form of ratification adopted by the Virginia Convention.
            </p>
            <p>7. Mr. Marr noted in the Proceedings of April 10, p. 506, that this should be "Mr. Early," and not "Mr. Marr." The Enquirer (April 11) made the same correction.</p>
            <p>FORTY-SIXTH DAY</p>
            <p>Monday, April 8</p>
            <p>
              1. The Arkansas resolutions and ordinance transmitted by Governor Letcher were printed on April 13 in the Enquirer. The Convention Journal for April 8 notes that the communications and accompanying documents "were laid upon the table, and ordered to be printed." The three letters printed in the Proceedings of April 8, and the resolutions and ordinance, were
              <PageNumber num={788} /> printed as Doc. No. XVI, Communication from the Executive of the Commonwealth Transmitting Communications from the President of the State Convention of Arkansas. It appears that the resolutions and ordinance were not included in the Proceedings.
            </p>
            <p>2. The Journal adds the name of Brent to the affirmative votes listed here. The Journal is presumably correct in listing P. C. Johnston in the affirmative votes, rather than M. Johnson, since the latter was paired with Mr. Thornton.</p>
            <p>3. The Journal adds Baldwin to the negative votes listed here.</p>
            <p>4. The Enquirer text, presumably garbled, says "ray met."</p>
            <p>5. The vote occurred on April 4.</p>
            <p>6. Mr. Wise must have meant to say "General Government."</p>
            <p>7. The Journal lists 63 affirmative votes, omitting Borst from the list as given in the Proceedings.</p>
            <p>FORTY-SEVENTH DAY</p>
            <p>Tuesday, April 9</p>
            <p>1. Mr. Willey's resolution has apparently been omitted from the Enquirer report. The Journal entry is as follows:</p>
            <p>On motion of <span className="persName">Mr. WILLEY</span>, the resolution was amended by striking from the 9th and 10th lines the words, "the positions assumed in the foregoing resolutions, and."</p>
            <p>2. The Journal omits Maslin from this list of negative votes.</p>
            <p>3. The Journal adds Ambler and Blakey to this list of negative votes.</p>
            <p>4. A correction (April 16) notes that the Latin phrase should read in littore.</p>
            <p>FORTY-EIGHTH DAY</p>
            <p>Wednesday, April 10</p>
            <p>1. This wording does not agree with that of the amended clause quoted below, nor with the Journal, which reads "within their jurisdiction."</p>
            <p>2. The Journal also indicates that there were 104 negative votes, although it lists the 106 names given here.</p>
            <p>3. A correction (April 18) reads "I never heard of any island on the coast of the Gulf of Mexico, within the marine league," instead of "I never heard of any island in the Gulf of Mexico."</p>
            <p>4. The "which" seems superfluous.</p>
            <p>
              5. An editorial correction of April 18 reads "I thought then that the gentleman from Kanawha was talking like Joe Tank," instead of "I thought then the gentleman from Kanawha was talking cant." On April 20, the Enquirer printed a letter from Mr. Wise, in which he corrected his remark to read "The gentleman was then making Kanawha seem to talk like Joe Tank." Mr. Wise complained that
              the reporter's mistake made him twice insult Mr. Summers, whereas he "spoke to him in playfulness and with perfect respect." The reporter's other mistake was the phrase "base inertia" mentioned below.
            </p>
            <PageNumber num={789} />
            <p>6. Both the editorial correction of April 18 and Mr. Wise's correction printed on April 20 read "vis inertia" instead of "base inertia." Presumably the correction should read "vis inertice."</p>
            <p>7. The Journal specifies 102 affirmative, 24 negative votes, and lists the names as given here.</p>
            <p>8. Mr. Conrad probably said that "them" was the true relative, but his meaning is not clear.</p>
            <p>9. A correction (April 18) reads "to the seven" instead of "with the seven."</p>
            <p>10. A correction (April 18) reads "uses" instead of "usage."</p>
            <p>11. A correction (April 18) reads "per fas aut nefas."</p>
            <p>12. This version of the amendment varies slightly from that given on page 501, which agrees with the text in the Journal.</p>
            <p>13. Mr. Baldwin must have meant to say "North."</p>
            <p>14. Reference is to the Convention of 1850.</p>
            <p>FORTY-NINTH DAY</p>
            <p>Thursday, April 11</p>
            <p>1. This name should perhaps be J. M. Hunt, like that of the delegate from the 3d District under the eighth resolution, below.</p>
            <p>2. The Journal adds Staples to the negative votes listed in the Proceedings.</p>
            <p>3. Mr. Summers doubtless meant Indiana.</p>
            <p>4. Mr. Bouldin refers to the amendment quoted on page 561. The words in question are properly "which concur" and "concurring."</p>
            <p>5. Both the Enquirer and the Journal read "no agreement.'</p>
            <p>6. This should probably be Indiana.</p>
            <p>7. The 11th resolution was passed on April 9, by a vote of 79 to 41, according to the Proceedings, page 435, or a vote of 81 to 41, according to the Journal.</p>
            <p>8. Apparently "would be" is omitted here before "an act."</p>
            <p>9. There seem to be several errors in addition in the figures, and it looks as if some figures had been quoted incorrectly. However, the text of the Enquirer is reproduced here.</p>
            <p>10. The speaker must have meant to say Ritchie.</p>
            <p>11. Presumably the text should read "on his resolution." Mr. Wilson's resolution was offered on February 27 (see Volume I, p. 248).</p>
            <p>FIFTIETH DAY</p>
            <p>Friday, April 12</p>
            <p>1. The Journal lists 66 negative votes, adding C. B. Conrad to the names given in the Proceedings.</p>
            <p>2. The Journal also refers to 124 affirmative votes, but actually lists 126, since it adds Fugate to the 125 given below.</p>
            <PageNumber num={790} />
            <p>3. The Journal adds Morton to negative votes listed below, making 84.</p>
            <p>4. The Journal also lists 47 affirmative votes, but adds French to those noted below, while it omits Sitlington. In the Journal, Price and Sitlington are added to the negative votes as listed in the Proceedings.</p>
            <p>FIFTY-FIRST DAY</p>
            <p>Saturday, April 13</p>
            <p>1. Presumably this should be New Mexican.</p>
            <p>2. The Journal adds Coffman to the affirmative votes listed here, making a total of 44. The 71 negative votes listed here are the same as given in the Journal.</p>
            <p>3. No record has been found of the D'Hauteville (or de Hauteville) cases. The speaker may have meant to refer to such cases as the claim of Marigny D'Auterive, of Louisiana, for remuneration for a slave, horse and cart pressed into United States service in the War of 1812.</p>
            <p>4. Probably the word "States" has been omitted here.</p>
            <p>5. The Journal adds McComas to the affirmative votes listed here.</p>
            <p>FIFTY-SECOND DAY</p>
            <p>Monday, April 15</p>
            <p>1. The Journal adds McComas to the negative votes listed here.</p>
            <p>
              2. Mr. Turner, of Jackson, referred in the Proceedings of April 16, Vol. IV, p. 55, to proceedings of meetings of his constituents which he had presented the day before. He probably was speaking of reports of meetings at Sandyville on April 6 and Jackson Court House on April 8, printed in the Enquirer on April 30. There is no record in the Proceedings for April 15 of the presentation
              of these reports, which were printed in the Enquirer as follows:
            </p>
            <p>Mr. Turner, of Jackson, presented the following proceedings in the Convention, a few days since :</p>
            <p>At a large and respectable meeting of the Southern States' rights men of Jackson county, held at Jackson C. H. (it being court-day) on Monday evening, April 8th, 1861, Miles Jacoby, Esq., was called to the Chair, and E. B. Wright, Dr. B. H. Hoyt and 0. L. C. Hinesman were appointed Secretaries.</p>
            <p>George N. Fitzhugh, Esq., was then called upon to explain the object of the meeting which he did in a few brief and pointed remarks.</p>
            <p>0. L. C. Hinesman then made a short speech with regard to the objects of the meeting, as well as the Union or submission meeting held on this day.</p>
            <p>F. P. Turner, Esq., was then called upon, and delivered some spirited remarks with regard to the present crisis, and also the action of the present State Convention now in session at Richmond, which were received with great applause.</p>
            <p>Mr. Hinesman then offered the following preamble and resolutions, which were adopted unanimously: <PageNumber num={791} /> Whereas, a meeting was held on this day calling itself a "Union Meeting ;" and, whereas, as said meeting, composed of a few submissionists, passed certain resolutions favoring submission; therefore,</p>
            <p>Resolved, That this meeting, composed of Southern States' rights men declare that the action of the so called Union meeting is a political swindle, and that those few individuals composing the said so called Union meeting had no right to dictate to the people of Jackson county; and</p>
            <p>Whereas, ample time has been given to the Northern States to restore to the South her rights, and that all honorable means having been exhausted by the Southern States in arduously attempting to secure themselves from the wrongs, injuries and oppressions which have been imposed upon the South by the Northern tyrants and fanatics, therefore,</p>
            <p>Resolved, That our Delegate in the Convention be requested to vote for any ordinance dissolving the ties now existing between the Commonwealth of Virginia and the late United States.</p>
            <p>Resolved, That we approve of the course F. P. Turner, Esq., our delegate in the Convention, has pursued, and that we offer him our sincere thanks therefor.</p>
            <p>Nine cheers were then given for Jeff Davis, President of the Confederate States of America, and nine groans for Abe Lincoln.</p>
            <p>On motion of M. J. Kester,</p>
            <p>Resolved, That the proceedings of this meeting be published in the "Virginia Chronicle," Kanawha "Valley Star,- Richmond "Enquirer" and Richmond "Examiner;" and, also, that a copy of these proceedings be presented to F. P. Turner, Esq., our delegate in Convention.</p>
            <p>On motion, the meeting adjourned sine die.</p>
            <p>MILES JACOBY, Chairman</p>
            <p>B. H. HOYT,</p>
            <p>E. B. WRIGHT, Secretaries</p>
            <p>0. L. C. HINESMAN,</p>
            <p>PUBLIC MEETING IN JACKSON COUNTY</p>
            <p>At a meeting of a portion of the people of Jackson county, irrespective of party, held at Sandyville, on the 6th day of April, 1861, on motion of G. N. Fitzhugh, Michael Crow was called to the Chair, and Daniel W. Sayre appointed Secretary.</p>
            <p>The object of the meeting having been explained by Robert Park, George N. Fitzhugh, Esq., addressed a few pertinent remarks upon the true policy of Virginia in relation to the late Federal Union.</p>
            <p>
              On motion of Dr. W. B. McMahon, the Chair appointed a committee of five to draft resolutions expressive of the sense of this meeting. Whereupon the Chair appointed the following gentlemen : Dr. W. B. McMahon, George N. Fitzhugh, Robert Park, Daniel W. Sayre and <PageNumber num={792} /> David Curry, who, after a short absence, reported the following
              resolutions, which were unanimously adopted :
            </p>
            <p>1. Resolved, That this meeting endorse and heartily approve the resolutions offered by F. P. Turner, Esq., delegate from Jackson and Roane counties in the Convention, as maintaining the true doctrines of State rights and State sovereignty; that we believe in the right of a State to withdraw her allegiance from the Federal Government whenever she deems herself wronged or oppressed.</p>
            <p>2. Resolved, That we recognize no right in any Government to coerce a sister State into submission, who may, for good cause, have seceded from the late Federal Union ; and that we will regard any such attempt as a declaration of war and resist it to the last extremity.</p>
            <p>3. Resolved, That the delegate from Jackson and Roane counties be requested to vote against any proposition that may be submitted to the present Convention of Virginia for holding a Border State Convention at any future period, for we are sick of delay and humbugs.</p>
            <p>4. Resolved, That if an ordinance for the immediate secession of Virginia shall be proposed, that we request F. P. Turner, Esq., our Delegate, to vote for the same as the only means, in our opinion, left to save our good old Commonwealth from Black Republican domination and the grinding oppression of a Black Republican tariff.</p>
            <p>5. Resolved, That the proceedings of this meeting be published in the Parkersburg News, Richmond Examiner, Enquirer and Dispatch.</p>
            <p>MICHAEL CROW, Chairman</p>
            <p>D. W. SAYRE, Secretary</p>
            <p>FIFTY-THIRD DAY</p>
            <p>Tuesday, April 16</p>
            <p>1. A report of a public meeting held in Culpeper County on April 15, printed below, was published in the Enquirer of April 30. There is no indication either in the Proceedings or in the Journal of when the report was presented to the Convention, but it seems likely that it would have been presented on April 16, and not improbably before the Convention went into secret session.</p>
            <p>PUBLIC MEETING IN THE COUNTY OF CULPEPER</p>
            <p>Mr. Barbour, of Culpeper, presented the following proceedings in the Convention:</p>
            <p>At an immense meeting of the citizens and voters of the county of Culpeper, held to-day, the fifteenth of April, 1861, the following preamble and resolutions were unanimously adopted :</p>
            <p>
              Whereas, the proclamation of the President of the United States, dated this day, has reached us, by the mail of this morning, and informs us, of the purpose and policy of the Cabinet in Washington in respect to our national troubles, as finally agreed upon, and ordered to be officially proclaimed ; and whereas, the said proclamation announced to the world the intention of the President
              to maintain the Union by force of arms <PageNumber num={793} /> and invokes the cooperation of the military and civil powers of the country, to that end and by that means ; and whereas, in the opinion of this meeting the maintenance of free institutions by military force, and the preservation of the public harmony by actual war, is equally impossible in
              fact as absurd in theory; and whereas, the people of this ancient county have on all occasions, up to this present moment, evinced by the most unequivocal testimonials their deep devotion and sincere loyalty to the most magnificent experiment in behalf of human liberty which the annals of human society have recorded, and have hoped and struggled whilst doubts existed as to the designs
              of power, without ever for a moment intending to foment or to countenance sectional discords or divisions on a question, in regard to which there is in fact a perfect community of interest and sentiment. Therefore, this meeting feels it to be its duty and its privilege to announce promptly and briefly, but distinctly its feelings and opinions—and accordingly it is resolved,
              unanimously,
            </p>
            <p>That the means and expedients for a pacific and patriotic solution of our national troubles are finally and entirely exhausted.</p>
            <p>That it is now the sacred duty of all our people, without reference to any conditions of personal inconvenience, to unite together and present an undivided and undivisible front in support of the interest, the honor, and the order of our beloved and venerable sovereign and mother, the ancient Commonwealth of Virginia.</p>
            <p>That with us political parties exist no more—the memory of their passions and conflicts is delivered to oblivion.</p>
            <p>Resolved, further, That we will not countenance or permit contests for public stations, and we do hereby re-nominate our present representatives, State and Federal.</p>
            <p>
              And, finally, be it resolved as the opinion of this meeting, respectfully expressed to our State Convention, now in session, That Virginia has no alternative but to reclaim all the attributes of her original sovereignty, and to announce immediately, by solemn ordinance, her fixed purpose to seek such future affiliations as are most favorable and convenient to the interests and security
              of her own people.
            </p>
            <p>
              The proclamation of the President was, by general request, read by John S. Pendleton, after which he submitted the foregoing preamble and resolutions, which, he stated, had been very hastily prepared at the instance of a number of the oldest and most influential citizens ; and he accompanied them with a few remarks, which were received with much applause. He stated that he was just
              from Washington, where he had been for as much as a couple of days, for the first time in two years, on the private business of a personal friend ; that he had sought to get from the highest sources the best information he could ; but that he had nothing to report, and no information, but what was common to the whole public by means of the public press.
            </p>
            <p>The proceedings were adopted unanimously, and with remarkable enthusiasm, and ordered to be published, and communicated to the Convention and to James Barbour and Hon. Wm. Smith.</p>
            <PageNumber num={794} />
            <p>
              The crowd then called upon a number of gentlemen—Mr. Daniel F. Slaughter, Richard H. Cunningham; Col. Freeman, Jas. L. Stringfellow, Messrs. Patton, Gray, Field and Green, of the Culpeper bar; Gen. Kemper, of Madison county; Mr. Gabriel Jones, lately of Iowa, though born and raised in Culpeper, and others, all of whom responded in the same spirit, and in terms which frequently elicited
              very great approbation.
            </p>
            <p>The meeting was held in the public streets in front of the Piedmont Hotel, because there was no room in the place large enough to hold half the crowd.</p>
          </div>
          <span className="pagebreak" id="index.xml-pb-w5960495aab1b2ab4">[Page]</span>
        </section>
      </div>

    </Styled.Volume>
  );
};

export default Transcription;
