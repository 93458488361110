import { timeFormat } from "d3";
import * as TimelineConstants from "../constants";
import * as Styled from "./styled";

import { useTimelineContext } from "../../../hooks";

const Track = () => {
  const { dimensions, yScale, events, dates } = useTimelineContext();
  const { trackWidth, dayHeight } = dimensions;
  const { lincolnsElection, lastDate, all: allDates } = dates;
  return (
    <>
      {/* the base of the track */}
      <Styled.TrackBase
        y1={TimelineConstants.trackWidth / 2}
        y2={yScale(lastDate)}
      />
      <Styled.Track
        y1={TimelineConstants.trackWidth / 2}
        y2={yScale(lastDate)}
      />
      <Styled.TrackBreak
        y1={yScale(new Date(lincolnsElection.getTime() + 24 * 60 * 60 * 1000 * 21)) - 15}
        y2={yScale(new Date(lincolnsElection.getTime() + 24 * 60 * 60 * 1000 * 21)) + 15}
      />
      <Styled.TrackBreakExplanation
        x={30}
        y={yScale(new Date(lincolnsElection.getTime() + 24 * 60 * 60 * 1000 * 21)) + 7}>
        ~ SIX WEEKS ~
      </Styled.TrackBreakExplanation>
      {/* the ticks */} /
      <Styled.LincolnsElectionMarker
        cy={yScale(lincolnsElection)}
        r={trackWidth}
      />
      <text
        y={yScale(lincolnsElection) + 9}
        fontSize="24"
        fill={"white"}
        textAnchor="middle">
        6
      </text>
      {allDates.map(date => (
        <g key={date.toISOString()}>
          {events.find(event => event.start === timeFormat("%Y-%m-%d")(date)) && (
            <Styled.EventTick
              y1={yScale(date) - dayHeight / 2 + 0.5}
              y2={yScale(date) + dayHeight / 2 - 0.5}
            />
          )}
          <Styled.Date
            y={yScale(date) + 3}>
            {timeFormat("%e")(date)}
          </Styled.Date>
        </g>
      ))}
    </>
  );
};

export default Track;
