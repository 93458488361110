import { useTimelineContext } from "../../hooks";
import Events from "./Events/Index";
import MonthLabels from './MonthLabels/Index';
import Track from './Track/Index';
import * as Styled from "./styled";
import ThreeStars from "../Icons/ThreeStars";
import * as StyledRoot from '../styled';

const Timeline = () => {
  const { dimensions } = useTimelineContext();
  const { width, height, dayHeight } = dimensions;

  return (
    <Styled.TimelinePageContainer>
      <StyledRoot.ThreeStars>
        <ThreeStars />
      </StyledRoot.ThreeStars>
      <h1>Timeline</h1>

      <Styled.TimelineContainer>
        <MonthLabels />

        <Styled.Timeline width={width} height={height}>
          <g transform={`translate(100 ${Math.round(dayHeight / 2)})`}>
            <Track />
            <Events />
          </g>
        </Styled.Timeline>
      </Styled.TimelineContainer>
    </Styled.TimelinePageContainer>
  );
};

export default Timeline;
