import { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { PageNumberOffsetsContexts } from "../../Contexts";
import Volume1 from './transcriptions/Volume1';
import Volume2 from './transcriptions/Volume2';
import Volume3 from './transcriptions/Volume3';
import Volume4 from './transcriptions/Volume4';
import * as Styled from './transcriptions/styled';

const Transcription = () => {
  const { volume } = useParams();
  const page = useParams().page || "1";
  const { hash } = useLocation();
  const scrollToAnchor = hash ? hash.replace("#", "") : null;

  // pageNum is stored in state and updated when any necessary scrolling has finished
  const [pageNum, setPageNum] = useState(0);
  // this is set to true if the element if scrollIntoView is necessary in the effect below. It's passed to PageNumber components to prevent them from navigating to a new page until the scroll here is complete.
  const [isAutoScrolling, setIsAutoScrolling] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  //when the page number changes, scroll to the page on the transcription
  useEffect(() => {
    if (parseInt(page) !== pageNum && !scrollToAnchor) {
      const element = document.getElementById(`page-${page}`);
      const transcriptionContainer = ref.current;
      if (element && transcriptionContainer) {
        element.scrollIntoView();
        let scrollTimeout: any;
        setIsAutoScrolling(true);
        const onScroll = (e: any) => {
          clearTimeout(scrollTimeout);
          scrollTimeout = setTimeout(function () {
            setPageNum(parseInt(page));
            setIsAutoScrolling(false);
          }, 100);
        };
        transcriptionContainer.addEventListener("scroll", onScroll);
        return () => {
          transcriptionContainer.removeEventListener("scroll", onScroll);
        };
      }
    }
  }, [page, pageNum, scrollToAnchor]);

  // scroll to a meeting anchor
  useEffect(() => {
    if (scrollToAnchor) {
      const element = document.getElementById(scrollToAnchor);
      const transcriptionContainer = ref.current;
      if (element && transcriptionContainer) {
        element.scrollIntoView();
        let scrollTimeout: any;
        setIsAutoScrolling(true);
        const onScroll = (e: any) => {
          clearTimeout(scrollTimeout);
          scrollTimeout = setTimeout(function () {
            setPageNum(parseInt(page));
            setIsAutoScrolling(false);
          }, 100);
        };
        transcriptionContainer.addEventListener("scroll", onScroll);
        return () => {
          transcriptionContainer.removeEventListener("scroll", onScroll);
        };
      }
    }
  }, [page, pageNum, scrollToAnchor]);

  return (
    <PageNumberOffsetsContexts.Provider
      value={{ isAutoScrolling, activePage: pageNum }}
    >
      <Styled.Transcription className="transcription" id="transcription" ref={ref} tabIndex={0}>
        {volume === 'v1' && <Volume1 />}
        {volume === 'v2' && <Volume2 />}
        {volume === 'v3' && <Volume3 />}
        {volume === 'v4' && <Volume4 />}
      </Styled.Transcription>
    </PageNumberOffsetsContexts.Provider >
  );
};

export default Transcription;
