import styled from "styled-components";
import * as Constants from '../../../constants';

export const Months = styled.div<{ $paddingTop: number }>`
  padding-top: ${(p) => p.$paddingTop}px;
  position: relative;
  width: 70px;
  height: 12000px;
`;

export const Month = styled.div<{ height: number }>`
  height: ${(p) => p.height}px;
  position: sticky;
  top: 5px;
  text-align: right;
`;

export const MonthLabel = styled.h2`
  margin: 0;
  display: inline-block;
  background-color: ${Constants.colors.grayLight};
  color: ${Constants.colors.grayMedium};
  text-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
  text-transform: uppercase;
  z-index: -3;
`;

