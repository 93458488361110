import { useEffect, useRef, useState } from "react";
import { useLocation, useParams, Link } from "react-router-dom";
// import "../../../styles/PageViewer/Transcription.scss";
import PageNumber from "./PageNumber";
import * as Styled from './styled';

const Transcription = () => {
  const page = useParams().page || "1";
  const { hash } = useLocation();
  const scrollToAnchor = hash ? hash.replace("#", "") : null;

  // pageNum is stored in state and updated when any necessary scrolling has finished
  const [pageNum, setPageNum] = useState(0);
  // this is set to true if the element if scrollIntoView is necessary in the effect below. It's passed to PageNumber components to prevent them from navigating to a new page until the scroll here is complete.
  const [isAutoScrolling, setIsAutoScrolling] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  //when the page number changes, scroll to the page on the transcription
  useEffect(() => {
    if (parseInt(page) !== pageNum && !scrollToAnchor) {
      const element = document.getElementById(`page-${page}`);
      const transcriptionContainer = ref.current;
      if (element && transcriptionContainer) {
        element.scrollIntoView();
        let scrollTimeout: any;
        setIsAutoScrolling(true);
        const onScroll = (e: any) => {
          clearTimeout(scrollTimeout);
          scrollTimeout = setTimeout(function () {
            setPageNum(parseInt(page));
            setIsAutoScrolling(false);
          }, 100);
        };
        transcriptionContainer.addEventListener("scroll", onScroll);
        return () => {
          transcriptionContainer.removeEventListener("scroll", onScroll);
        };
      }
    }
  }, [page, pageNum, scrollToAnchor]);

  // scroll to a meeting anchor
  useEffect(() => {
    if (scrollToAnchor) {
      const element = document.getElementById(scrollToAnchor);
      const transcriptionContainer = ref.current;
      if (element && transcriptionContainer) {
        element.scrollIntoView();
        let scrollTimeout: any;
        setIsAutoScrolling(true);
        const onScroll = (e: any) => {
          clearTimeout(scrollTimeout);
          scrollTimeout = setTimeout(function () {
            setPageNum(parseInt(page));
            setIsAutoScrolling(false);
          }, 100);
        };
        transcriptionContainer.addEventListener("scroll", onScroll);
        return () => {
          transcriptionContainer.removeEventListener("scroll", onScroll);
        };
      }
    }
  }, [page, pageNum, scrollToAnchor]);

  return (
    <Styled.Volume>
      <div className="tei_front" id="v4front">
        <PageNumber num={1} />
        <div className="titlePage">
          <div className="docTitle">
            <div className="titlePart">
              Proceedings<br />
              OF THE<br />
              Virginia State Convention<br />
              of 1861
            </div>
            <div className="titlePart">FEBRUARY 13 — MAY 1<br />In Four Volumes</div>
          </div>
          <div className="byline">GEORGE H. REESE<br />Editor</div>
          <div className="docImprint"><span className="date">1965</span><span className="publisher">VIRGINIA STATE LIBRARY</span><span className="pubPlace">RICHMOND, VIRGINIA</span>:</div>
        </div>
        <section className="front" id="vsc1965.v4.1">
          <h1>Library of Congress …</h1>
          <PageNumber num={2} />
          <p>Library of Congress Catalog Card No. A65-7459</p>
          <p>PRINTED IN U.S.A.</p>
          <div className="contents" id="vsc1965.v4.1.1">
            <PageNumber num={3} />
            <h2><span className="head">CONTENTS</span></h2>
            <p>Synopsis v</p>
            <p>Proceedings 3</p>
            <p>Notes 799</p>
            <PageNumber num={4} />
          </div>
          <div className="synopsis" id="vsc1965.v4.1.2">
            <PageNumber num={5} />
            <h2><span className="head">SYNOPSIS</span></h2>
            <p>SECRET SESSION</p>
            <h3>FIRST DAY</h3>
            <div className="synopsisDateLine"><div>Tuesday, April 16</div><Link to={`../${3 + 12}`} relative="path">3</Link></div>            
            <p>
              The Convention goes into secret session. The commissioners report on their conversation with President Lincoln: Mr. Randolph urges immediate defense measures, and secession, while Mr. Stuart advises against secession before Virginia has consulted the border states. The Governor communicates the call of the U. S. Secretary of War for Virginia militia. Mr. Preston submits an ordinance of
              secession. Mr. Scott speaks in favor of resistance, but proposes a referendum on secession. Members from western Virginia discuss the effect of secession on their section of the state. Other members continue the discussion of secession, with most of those speaking opposed to precipitate action.
            </p>
            <h3>SECOND DAY</h3>
            <div className="synopsisDateLine"><div>Wednesday, April 17</div><Link to={`../${75 + 12}`} relative="path">75</Link></div>            
            <p>
              The Governor transmits his refusal to supply troops to the U. S. Secretary of War, and also communicates the Adjutant General's report on Virginia's volunteer force and armaments. A large number of delegates comment on Mr. Preston's proposal of secession and Mr. Scott's substitute, and vote down the latter. Delegates debate the ordinance of secession, and adopt it by a vote of 88 to
              55. Mr. Randolph proposes that the Governor be authorized to call up troops and borrow money for defense. Mr. Scott proposes a Committee on Military Affairs. The Convention adopts Mr. Randolph's resolutions on defense, and Mr. Wise's proposal to inform the President of the Confederate States that Virginia has seceded.
            </p>
            <h3>THIRD DAY</h3>
            <div className="synopsisDateLine"><div>Thursday, April 18</div><Link to={`../${183 + 12}`} relative="path">183</Link></div>            
            <p>The creation of a Committee on Military Affairs is approved. The Convention votes to publish the ordinance of secession. An ordinance authorizing a defense loan is approved. Consultation with the Governor on military matters is accepted. An ordinance to appoint a corn-</p>
            <PageNumber num={6} />
            <p>
              mander-in-chief is referred to the Committee on Military Affairs. The Convention receives the resolutions of the "Convention of the People," applauding the ordinance of secession. Resolutions to prevent unauthorized military movements and to solicit an alliance with the Confederate States are approved. The questions of a provisional government and of Virginia's financial condition are
              explored. Resolutions on appointment of military aides, the state of Virginia's defenses, financing, and relations with the Confederacy and the border states are moved.
            </p>
            <h3>FOURTH DAY</h3>
            <div className="synopsisDateLine"><div>Friday, April 19</div><Link to={`../${245 + 12}`} relative="path">245</Link></div>            
            <p>
              The President announces assignments to the committee on finance. Mr. Preston offers a resolution for submitting the ordinance of secession to the people. Mr. Willey presents the report of the committee on taxation, Mr. Summers comments on the report, and Mr. Wise moves to amend it. The Convention adopts ordinances creating the office of major general commanding Virginia forces, and
              offering the command to Colonel Robert E. Lee. The Secretary reads President Davis's acceptance of the proposal of alliance. Mr. Wise proposes an ordinance to set up military departments and bureaus.
            </p>
            <h3>FIFTH DAY</h3>
            <div className="synopsisDateLine"><div>Saturday, April 20</div><Link to={`../${263 + 12}`} relative="path">263</Link></div>            
            <p>
              The Convention again considers the report of the committee on taxation. Mr. Woods urges action on taxation, Mr. Willey replies, and the report is put aside. Arrangements for the vote on the secession ordinance are presented. A select committee reports an ordinance to suspend the Federal authority in Virginia; the Convention discusses practical effects. Resolutions and ordinances on
              communications, defense and finance are offered. The question of taxation comes up again, is fully debated, and is put aside once more, while the Convention approves appointment of a council of three to advise and assist the Governor. Debate on taxation is resumed.
            </p>
            <h3>SIXTH DAY</h3>
            <div className="synopsisDateLine"><div>Sunday, April 21</div><Link to={`../${316 + 12}`} relative="path">316</Link></div>            
            <p>The Convention meets at the Governor's call, to consider military</p>
            <p>matters. The Committee on Military Affairs submits an ordinance to</p>
            <p>authorize a Provisional Army of Virginia. Mr. Johnston, Mr. Early</p>
            <PageNumber num={7} />
            <p>and Mr. Baldwin suggest amendments to the ordinance. Mr. Baldwin's amendment, authorizing the formation of staff departments, is accepted as a substitute for the report of the Committee on Military Affairs.</p>
            <h3>SEVENTH DAY</h3>
            <div className="synopsisDateLine"><div>Monday, April 22</div><Link to={`../${332 + 12}`} relative="path">332</Link></div>            
            <p>
              The President submits the credentials of Alexander H. Stephens as Confederate commissioner to Virginia, and the Convention invites him to address them. The Convention returns to consideration of the report on taxation and, after discussion, lays it on the table. The Committee on Military Affairs proposes a new ordinance for organizing staff departments in the military forces. After a
              long discussion, the Convention agrees to put the subject aside, and votes to suspend the ordinance already passed for organizing a staff corps. The role of the county courts in raising and arming volunteers is discussed. The President submits the Governor's nomination of Colonel Lee as commander of Virginia's military and naval forces. The Convention unanimously confirms the
              nomination, and proceeds with arrangements for receiving Major General Lee and making formal announcement of his appointment.
            </p>
            <h3>EIGHTH DAY</h3>
            <div className="synopsisDateLine"><div>Tuesday, April 23</div><Link to={`../${368 + 12}`} relative="path">368</Link></div>            
            <p>
              The Convention receives Vice President Stephens and Major General Lee, and General Lee replies briefly to an address of welcome by the President. Mr. Stephens addresses the Convention, urging an immediate alliance between the Confederate States and Virginia. Mr. Holcombe submits two ordinances on the duties of the Governor's Advisory Council, which are adopted. Mr. Fisher offers an
              ordinance directing the Governor to take possession of all customhouses and post offices in the state, and the matter is referred to a special committee. Mr. Morton proposes that a committee be appointed to confer with Mr. Stephens on the terms of an alliance; the resolution is debated, amended and adopted, and a committee of five is appointed.
            </p>
            <h3>NINTH DAY</h3>
            <div className="synopsisDateLine"><div>Wednesday, April 24</div><Link to={`../${416 + 12}`} relative="path">416</Link></div>            
            <p>Mr. Blakey offers amendments of the schedule for voting on the</p>
            <p>secession ordinance, and the amended schedule is adopted. Mr.</p>
            <PageNumber num={8} />
            <p>
              Caperton offers an ordinance to suspend sales for debt. Mr. Macfarland offers an ordinance authorizing the issue of small bank notes. Mr. Fisher offers a resolution to subject all military appointments to the approval of the Convehtion; the question is referred to the Committee on Military Affairs. Mr. Scott submits ordinances for the organization of military staff departments, the
              construction of telegraph lines, and the exemption of railroad employees from military service. The committee to confer with Mr. Stephens reports a covenant between Virginia and the Confederate States, and an ordinance providing for Virginia's adoption of the Confederate Constitution. An ordinance to ratify the covenant between Virginia and the Confederate States is proposed and
              argued.
            </p>
            <h3>TENTH DAY</h3>
            <div className="synopsisDateLine"><div>Thursday, April 25</div><Link to={`../${473 + 12}`} relative="path">473</Link></div>            
            <p>
              The disposal of machinery from Harper's Ferry is discussed. Delegates resume debate on ratification of the proposed covenant, and its proposal to put Virginia forces under Confederate authority is attacked The ordinance to ratify the covenant is approved, and the ordinance for adoption of the Confederate Constitution is accepted without argument. Mr. Morton proposes that the
              Confederacy shall be invited to make the City of Richmond its capital.
            </p>
            <h3>ELEVENTH DAY</h3>
            <div className="synopsisDateLine"><div>Friday, April 26</div><Link to={`../${496 + 12}`} relative="path">496</Link></div>            
            <p>
              Mr. Macfarland submits an ordinance to provide for deficiencies in contingent funds. Mr. Hull submits an ordinance suspending sales for debt. Mr. Coffman presents a petition for small bank notes, from business men of Rockingham County. The ordinance authorizing issue of small bank notes is discussed, amended and approved. Mr. Scott reports ordinances for organization of a Virginia
              Provisional Army, for the establishment of military regulations, and for enlistments in the Provisional Army; the first of these provokes much discussion. The President communicates resolutions from the Missouri State Convention. Mr. Morton presents an ordinance to amend the Virginia Constitution so as to establish uniform taxation. The ordinance is debated at length and finally
              adopted. The President announces members of committees on representation in the Confederate Congress, and on enrolled ordinances.
            </p>
            <PageNumber num={9} />
            <h3>TWELFTH DAY</h3>
            <div className="synopsisDateLine"><div>Saturday, April 27</div><Link to={`../${549 + 12}`} relative="path">549</Link></div>            
            <p>
              Mr. Macfarland reports an ordinance, establishing a board of commissioners to pass on defense expenditures and public claims, which is adopted. Mr. Scott submits ordinances to establish a flag for the Commonwealth; to regulate the commissary, paymaster general, quartermaster, and medical departments; to provide chaplains; to establish a Virginia navy; and to regulate appointment of
              officers. The last of these is adopted, while the others are tabled. The committee on postmasters and revenue officers and the committee on representation in the Confederate Congress submit reports. Mr. Turner presents the ordinance on taxation with a schedule for its ratification; the Convention approves. The resolution inviting the Confederate government to set up its capital in
              Richmond is accepted. Mr. Caperton moves adoption of the ordinance to suspend sales for debt, Mr. Goggin offers a substitute, and both are tabled. The Convention turns again to the ordinance for organizing the Provisional Army, amends it, and adopts it. A number of other ordinances on military matters are discussed. The appointment of Colonel Thomas J. Jackson is confirmed.
            </p>
            <h3>THIRTEENTH DAY</h3>
            <div className="synopsisDateLine"><div>Monday, April 29</div><Link to={`../${581 + 12}`} relative="path">581</Link></div>            
            <p>
              Reports are made by the committee on enrolled ordinances and the committee dealing with the Virginia delegation to the Confederate Congress. The Convention accepts a resolution for arming counties on Virginia's western border. Resolutions are offered on military organization, and an ordinance is submitted to combine and reconcile two earlier ordinances on the quartermaster's department
              and on public claims. The Convention elects five delegates to the Confederate Congress: R. M. T. Hunter, Wm. C. Rives, John W. Brockenbrough, Waller R. Staples, and Gideon D. Camden. Delegates approve an ordinance which enables judges and other state officials to accept appointments by the Convention. A further amendment to the ordinance on suspension of sales for debt is offered, and
              an ordinance to increase the number of the Advisory Council is approved. The day closes with discussion of the rule of secrecy, and the issuing of small bank notes by towns.
            </p>
            <PageNumber num={10} />
            <h3>FOURTEENTH DAY</h3>
            <div className="synopsisDateLine"><div>Tuesday, April 30</div><Link to={`../${658 + 12}`} relative="path">658</Link></div>            
            <p>
              The Convention debates an ordinance to authorize the issue of treasury notes, then tables it. An ordinance prescribing military pay is argued and tabled. Another ordinance, to authorize arming of Virginia border counties, is discussed at length, but replaced by a substitute authorizing General Lee to issue arms at his discretion. The nomination of a new member of the Advisory Council
              is confirmed. The Convention asks the Governor to report on military recruitment and appointments. The ordinance establishing a flag for the Commonwealth is approved. The new ordinance to regulate military purchases and requisitions is presented and passed. An ordinance inviting officers from the U. S. revenue and coast survey services to enter the service of Virginia is passed. The
              ordinance to suspend sales for debt is argued in detail, and amended, before it is passed. The ordinance to authorize the issue of treasury notes is debated and passed. An ordinance regulating terms of service for volunteers is adopted without discussion, as is one providing chaplains for the volunteers. An ordinance setting the pay of the volunteer force and the Provisional Army is
              debated, but action is deferred.
            </p>
            <h3>FIFTEENTH DAY</h3>
            <div className="synopsisDateLine"><div>Wednesday, May 1</div><Link to={`../${726 + 12}`} relative="path">726</Link></div>            
            <p>
              The Convention amends and approves an ordinance for compensation of the Advisory Council. An ordinance to continue the operation of Federal courts in Virginia is tabled. An ordinance regulating the pay of the volunteers and Provisional Army is taken up again; Mr. Tyler offers a substitute, which is approved. The Convention accepts ordinances to establish regulations for Virginia's
              armed forces; to prohibit the export of provisions; to authorize seizure of telegraph lines; to set up regulations for the staff departments; and to exempt certain workers from military duty. An ordinance to release citizens of Virginia from the obligation to support the U. S. Constitution is submitted. The Governor transmits a report of volunteers in service and officers appointed.
              Mr. Randolph offers an amendment to the ordinance defining the duties of the Advisory Council. Mr. Wilson proposes that the Convention send commissioners to the border slave states. The Convention debates Mr. Moffett's proposal to allow publication of proceedings of the Convention. Resolutions and ordinances concerning Virginia's relation to the Confederacy, endorsement of
            </p>
            <PageNumber num={11} />
            <p>
              treasury notes, and appointments of officers of volunteers are considered. The committee on duties of collectors and postmasters makes its report, which is accepted. Mr. Tyler presents an ordinance for establishing county police forces, and it is adopted. An ordinance for the arming of militia forces is adopted, but an ordinance authorizing militia musters is tabled, as is an ordinance
              to invalidate any act of the U. S. Supreme Court, or district courts, since the secession of Virginia. The Convention adjourns until June 12, 1861.
            </p>
            <PageNumber num={12} />
          </div>
        </section>
      </div>


      <div className="tei_body" id="v4body">
        <section className="body" id="vsc1965.v4.2">
          <PageNumber num={13} />
          <header>
            <h1><span className="headingNumber">1. </span><span className="head">VOLUME FOUR</span></h1>
          </header>
          <p>Proceedings : April 16 - May 1</p>
          <PageNumber num={14} />
          <div className="day" id="vsc1965.v4.2.1">
            <PageNumber num={15} />
            <h2>
              <span className="headingNumber">1.1. </span><span className="head">SECRET SESSION<br />FIRST DAY</span>
            </h2>
            <div className="dateline">Tuesday, <span className="date">April 16</span></div>
            <p>The Convention went into secret session at a few minutes past 10 o'clock, A. M.; and, after a few unimportant preliminaries, the following resolution, offered by <span className="persName">Mr. R. Y. CONRAD</span>, of Frederick, and amended by <span className="persName">Mr. WYSOR</span>, of Pulaski, was adopted:</p>
            <p>Resolved, That a committee of three be appointed to inform the Governor of the Commonwealth that this Convention is now in secret session, and to request him to communicate to it, from time to time, such information as may be, in his opinion, important for it to have.</p>
            <p>The following committee was appointed under that resolution:</p>
            <p>Messrs. <span className="persName">R. Y. CONRAD</span>, <span className="persName">BENJ. F. WYSOR</span> and <span className="persName">WM. H. MACFARLAND</span>.</p>
            <div className="speaker" id="sp3643"><span className="persName">Mr. FORBES</span>, of Buckingham—</div>
            <p className="p-in-sp">I beg leave to offer the following resolution, hoping that it will lead to no discussion:</p>
            <p className="p-in-sp">
              Resolved, That the President of this Convention be directed to invite and earnestly request the attendance of the Governors of the border slave States, to wit: North Carolina, Maryland, Delaware, Tennessee, Kentucky, Missouri and Arkansas, at this place, at their earliest convenience, for the purpose of conference and consultation in regard to the present condition of the country.
            </p>
            <div className="speaker" id="sp3644"><span className="persName">Mr. FORBES</span>—</div>
            <p className="p-in-sp">I desire to say that I offer that resolution with a belief that this Convention will, to-day or to-morrow, or in some short time, pass an ordinance of secession. I think it is highly important that we should have this consultation with these gentlemen.</p>
            <div className="speaker" id="sp3645"><span className="persName">Mr. PRICE</span>, of Greenbrier—</div>
            <p className="p-in-sp">It will take some time to get these gentlemen here. I do not want to wait until they get here, and I therefore move to lay the resolution on the table.</p>
            <p>The motion was agreed to.</p>
            <p><span className="persName">Mr. TYLER</span>, of Charles City-I beg leave to offer the following resolution:</p>
            <p>Resolved, That the Governor of the Commonwealth be requested to communicate to this body any official despatches which he may have received from Washington, calling upon Virginia for any quota of troops, under the President's recent proclamation.</p>
            <p>The resolution was adopted.</p>
            <div className="speaker" id="sp3646"><span className="persName">Mr. CHAMBLISS</span>, of Greensville—</div>
            <p className="p-in-sp">I will ask what disposition has been made of the reports of the Commissioners to Washington?</p>
            <PageNumber num={16} />
            <div className="speaker" id="sp3647"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">No disposition has been made of them.</p>
            <div className="speaker" id="sp3648"><span className="persName">Mr. CHAMBLISS</span>—</div>
            <p className="p-in-sp">I move that they be now taken up and considered.</p>
            <p>Agreed to. [<span className="persName">Mr. WM. B. PRESTON</span>, one of the Commissioners who went to Washington, then addressed the Convention for some hours. His speech is yet in his hands for revision, and will be published as soon as sent to the "Enquirer" office.]
              <span className="note" id="Note5"><span className="noteLabel">1</span>This speech by Mr. Preston has not been found in the Enquirer.</span>
            </p>
            <div className="speaker" id="sp3649"><span className="persName">Mr. RANDOLPH</span>, of Richmond city—</div>
            <p className="p-in-sp">I desire, as far as possible, like the gentleman from Montgomery [<span className="persName">Mr. PRESTON</span>] to confine myself to clearly ascertained facts; but it will be impossible for me to avoid giving, to some extent, the impressions that I derive from the conversation with the President of the United States, and from facts that came to our knowledge within the City of Washington.</p>
            <p className="p-in-sp">
              The President requested that his conversation would not be reported, and, as a matter of course, I shall not undertake to say what he said, inasmuch as I might mistake his language. But, I presume, it will be perfectly legitimate to state the impression which that conversation made upon me. The reason that he assigned for desiring that it should not be reported, as I understand, was
              that it might be misreported; and I shall not undertake, therefore, to state what he said. But I deem it my duty to-this-Convention, to state the impressions that conversation made upon me.
            </p>
            <p className="p-in-sp">
              Mr. President, I believe, from all that I have heard, and can understand, that we are in the beginning of the greatest war that has ever been waged upon this continent. That war will be conducted with the entire force of the Federal Government, and will, unquestionably, in the start, command the entire support of the Northern people. I believe, sir, that a warlike policy has been in
              part impressed upon the popular sentiment of the North; and, from what I now observe in the public press, I see no ground to hope that, at least, in the beginning of that war, there will be any serious division among them. I conferred yesterday with a gentleman who had just come from the North-west, and with other gentlemen just from the city of New York, and they all told me that
              where they travelled there, there was but one opinion, and that in favor of war.
            </p>
            <p className="p-in-sp">
              Now, sir, what is the object of that war? It is ostensibly defensive-merely to re-possess certain forts and arsenals which have been seized by the Confederate States, and to collect the revenue. But can it be a defensive war? Can it be to re-possess Fort Sumter and the other forts seized by the Confederate States? Can you for a moment believe that they will be allowed to recover these
              back? There is no chance of it; <PageNumber num={17} /> and then will not a discretion be given to the commanders of the United States forces which will necessarily induce them to follow up the contest and to disperse the defending army? If so, there is an aggressive war at once. You may as well attempt to circumscribe a fire in a prairie as to attempt to
              confine a war to the neighborhood of the forts intended to be re-possessed. We see by the President's proclamation that 75,000 men are to be called into action, and as we have every reason to believe that it is the wish of the administration that at least one-third of that number should be concentrated upon the frontier of Virginia and at the City of Washington, my own opinion is, that
              unless this State views this just now as a military question, and unless she considers military preparations as of the first and primary importance, we will be a subjugated people. As for a position of neutrality in this shock of arms, if any were base enough to desire it, he could not attain it. Why, you are called upon already to furnish your quota of men! You are now furnishing your
              contingent of money to pay that army. You do not occupy a neutral position now; and you have got sons who are required by their military oath and allegiance to serve in that army, and who, unless you call them back to you, will serve in that army and commit themselves to the policy of the administration. There is only one course which would ensure neutrality. If they would permit you
              to separate yourselves from the United States without connecting yourselves with the other States, you might stand idle, and dishonorably witness this war upon your own frontiers. But as long as you are connected with one side or the other, you must be a party to the war. There is no alternative; you have got to fight-and the question is, which side will you fight with? If, in place of
              girding your loins for battle, you go into council with irresolute, divided, unprepared States, you will put a clog upon your military preparations which will render them wholly inefficient. In my humble judgment the very first step which the safety of this State imperiously requires is, to release herself from all constitutional obligations to the people and to the government who are
              preparing to coerce her sister States of the South. As a military question, waiving all political considerations, looking solely to that which will enable us to maintain our liberties, I believe that the very first step in the preparation must be to relieve ourselves from all further constitutional obligations to that government, and to call home from its service all of our sons who
              are willing to abide by you.
            </p>
            <p className="p-in-sp">
              There are two things, Mr. President, which are to be done quickly, and to some extent secretly. I shall not attempt here to suggest the <PageNumber num={18} /> plan of a campaign. I am a civilian; but we may all form opinions as to the general preparations necessary. In the first place, here is a dock-yard which it would be important for us immediately to
              possess. I took occasion to walk through it on Thursday, while in Norfolk, and to converse with the officer in charge of it, and to find out what they were doing. I ascertained that there are stored there 30,000 barrels of gunpowder, besides several hundred guns of heavy calibre. If you seize all that immense dock-yard, with all its ship timber, its vessels in the streams, your
              armament is complete. You have got what you most need; an ample supply of powder, vessels in stocks and materials for a navy force. If you stand idly by-even now they are stripping it of everything and moving it off-all that material will be employed to subjugate instead of defend you. Last winter, while acting on the Military Commission of the State, of which I am a member, I called
              at the War Department and got them to show me a list of the arms within the State belonging to the United States. I found that there were at Harper's Ferry 48,000 Minie muskets and a much larger number of smooth bore muskets. It would be an easy matter to prevent the removal of those by breaking up the railroad track of the Baltimore and Ohio Rail Road and the question now is whether
              you will give up these arms to the enemy or keep them yourselves.
            </p>
            <p className="p-in-sp">
              There is another great object to be looked to. The plan of operation on the other side has to be formed upon your frontier; and it is absolutely necessary, without delay, to form your plan of action and to see that you do not bring into the field undisciplined, unorganized battalions against disciplined and organized battalions. Now, sir, neither of these objects can be properly
              attained without the agency and control of experienced and regular officers, and these you cannot get so long as you are connected with that government, and they are in its service. We saw many of them at Washington. Our room was a perfect anteroom for sailors and soldiers for an entire day. We were called upon by many connected with the military and naval service. We had many
              confidential conversations with gentlemen in both these departments of the public service; and the impression was that so long as the State remained in the Union they would remain in the United States service; but the moment she went out, their services would be at her control. I understood that some committed themselves on the other side, and were now prepared to make war upon the
              State of their birth.
            </p>
            <p className="p-in-sp">
              These, sir, were men of rank, and chiefly connected with the navy. Let this war go on while you are in the Union, and you will lose <PageNumber num={19} /> the services of many brave and experienced officers; you will lose all opportunity of organizing and disciplining your own troops while organization and instruction is going on on the other side; so that
              those 75,000 militia men will be made efficient soldiers by the time you have done your counsels, and then you will have to commence your preparations when, perhaps, it may be too late.
            </p>
            <p className="p-in-sp">
              Mr. President, I don't see how it is possible for us to maintain our independence in the face of that power, unless we at once and without a delay of twenty-four hours, commence immediate preparation. Shall we do this by going to Kentucky, which has not appropriated a dollar to raise an armed soldier? By going to Tennessee, which does not consider the cause of sufficient importance to
              assemble a convention in reference to it? By going to Missouri, which is almost on the side of the enemy? By going to Maryland, where a Henry Winter Davis is elected while the South is battling for equality? Shall we best vindicate our liberty and independence by counselling with irresolute, divided and distracted States? or by throwing ourselves at once into the arms of the only
              organized government which is ready to do battle with us and for us? I don't object to conference with the border States. I think it respectful; I think it proper; but I do most earnestly protest-and I desire to put that protest on record, and to stand acquitted of all responsibility in the matter-against delaying the action of Virginia until these States can be brought to concur with
              us. I believe that you will get their concurrence sooner by going out, and by sending commissioners to them to explain to them that the emergency was so great that you could not wait to consult with them. I believe you will get their co-operation sooner by that course than you will by assembling in Convention and discussing the question, whether you will fight on one side or the other,
              or fight at all? That question is settled. You have got to fight; and the further question is settled as to which side you have got to fight on; and the next question is, whether you will act promptly and efficiently?
            </p>
            <p className="p-in-sp">
              There is another question? While you are restraining, counseling and admonishing your own people, and distracting them, you are preparing to pay the costs of both sides of this war. Why, sir, loans will be immediately engaged by the United States Government, and they will have no difficulty in obtaining what they need. I believe they can now command, in the fervor of public feeling at
              the North, all the money necessary, and it will require some forty or fifty millions to maintain that force for a year in the field; and your share of that will be over half or perhaps three quarters of a million a year. By <PageNumber num={20} /> this delay you are placing yourselves in a position where you will be called upon to pay on both sides. Even in
              a pecuniary point of view, it is the interest of the State at once to place herself by the side of her Southern sisters, and make common cause with them. As to the proposed conference with the border States, I take it, that we do not need advice now. We need help, we need bayonets, not counsel, in the extremity in which we now find ourselves. I have great respect for the opinions of
              the statesmen from Kentucky, Tennessee and Missouri; but I don't want to stop to hear them talk when a campaign is formed and arranged for the invasion of the Commonwealth, on one side of our border.
            </p>
            <p className="p-in-sp">
              Let us get ready, not as a member of the Northern Confederacy; but let us detach ourselves from that Union, and throw the responsibility of the military operations upon the government of the Northern Confederacy, which will also have to bear the expense of the war. Let us do that speedily, and at the same time counsel with our friends; but do not let us defer these most indispensable
              preparations to hear the opinions of any man.
            </p>
            <p className="p-in-sp">
              Sir, the destiny of Virginia is committed now to our hands. We are sent here to advise the people. Don't let us distract the people by submitting to them alternate propositions. We are sent here to tell them what we think they ought to do, not to throw upon them the decision of a military or other question which ought to be considered here with closed doors. Whether the State would be
              benefited by cooperation or separate action, we are the people to decide. We know the grounds upon which to decide. We have the information, and they look to us for advice; and, in my judgment, we should be wanting in our duty to them, if we failed to indicate to them the line of policy which, in our judgment, they ought to pursue.
            </p>
            <p className="p-in-sp">
              Having said this much, it only remains for me to say, so far as my future action is concerned, I mean to forget, as far as I can, all differences upon this floor. I do not mean to go back and fix the blame upon anybody. I wish to consider that there is no blame to attach to any one; that we will be responsible only for the future. Let the responsibility of the past go, and let us take
              a fair start. Let us have no criminations or recriminations. I do entreat gentlemen, in this most perilous hour, to take no step which they may hereafter repent of-to take no step which will distract and divide the people, or which will weaken the moral or material power of the Commonwealth. Let us, if we can, unite our counsels, and, without personal asperity, without crimination seek
              to work out the salvation of this common <PageNumber num={21} /> mother of us all. Let us forget all sectional animosities, all party differences, all past criminations, and come to the consideration of these questions with an ardent desire to conciliate, to harmonize, and to achieve for this Commonwealth that safety which she leaves to us to secure for
              her. I shall, therefore, whilst I have most decided views upon this question of secession, listen with respect to all opposing counsels; and if my judgment can be brought to concur in any other line of policy, it will give me the greatest pleasure to acknowledge that my reason has been convinced, and to join my humble efforts to those of gentlemen who differ from me.
            </p>
            <div className="speaker" id="sp3650"><span className="persName">Mr. A. H. H. STUART</span>, of Augusta—</div>
            <p className="p-in-sp">
              I had not expected to open my lips during the remainder of this session; but, in consequence of what has been said by the gentlemen who were associated with me in my mission to Washington, and in consequence of what seems to me to be an understanding that each member of that Commission shall give the benefit of his observations on that mission, I feel bound to say a few words.
            </p>
            <p className="p-in-sp">And first, sir, I take occasion to express the pleasure which I feel at the harmony which existed among the members of that Commission, during the mission. When we were appointed, we were represented as belonging to different parties upon this floor. I was represented as belonging to the extreme Union party. My friend who sits before me [<span className="persName">Mr. PRESTON</span>] was represented as belonging to the middle party; and my other friend, who sits across the way [<span className="persName">Mr. RANDOLPH</span>] was represented as belonging to the ultra Southern party. But I am happy to say that there was no division of sentiment between us, in reference to anything that occurred during our mission.</p>
            <p className="p-in-sp">I do not think it necessary to repeat what has been said by those gentlemen in regard to what occurred in our interview with the President. We had not only an official interview, in which we received an official answer, but we had also a conversation of considerable length, which was intended to be somewhat explanatory of the written communication.</p>
            <p className="p-in-sp">
              The President said to us that he desired that the conversation should not be treated as a part of the communication, and as such reported to the Convention. He stated, at the same time, that it was from no indisposition that it should be known, but from an apprehension he entertained that it would not be correctly reported, and that any irregular communication was liable to
              misconception. There was an implied understanding between us that we would not detail that <PageNumber num={22} /> conversation, and, of course, my colleagues have refrained from so doing.
            </p>
            <div className="speaker" id="sp3651"><span className="persName">Mr. PRESTON</span>—</div>
            <p className="p-in-sp">I think the President observed that what transpired there was under confidence between gentlemen.</p>
            <div className="speaker" id="sp3652"><span className="persName">Mr. STUART</span>—</div>
            <p className="p-in-sp">The gentleman reminds me that he expressly said, that what was said in the interview referred to was under confidence between gentlemen. I know that the obligation existed. I speak of it as an obligation which I do not mean to violate.</p>
            <p className="p-in-sp">
              But I may be permitted to say that I did not conceive that a good portion of that conversation should go without an answer. In the interview, if my colleagues will remember, upon my own responsibility, disclaiming any authority from them, and professing to speak for myself alone, I gave to him very plainly my views of his conduct and of his administration. I feel, sir, that there is no
              obligation of secrecy upon me to withhold from the Convention what I said. That is my secret; that is subject to my control; and, while it may not be of much interest, yet, as it may shed some little light upon the character of the interview, I will state, as fully as I can, what my remarks to him were.
            </p>
            <p className="p-in-sp">
              I stated to him, that while I could readily perceive his sense of obligation to enforce the law, and could give him credit for sincerity in his attempt to enforce it, yet that I must be permitted to say to him, that I thought he had fallen into a great error. I instanced, in the first place, the revenue law, and stated that he ought to remember that while he was under an obligation to
              enforce the law, the law itself provided agencies and instrumentalities by which they were to be enforced; and that it was as much his obligation to pursue the mode pointed out by the law, as it was to execute the law itself. These agencies, I said, were part and parcel of the law, and he was bound to respect them; and I remarked that whenever he undertook to depart from the mode which
              the law pointed out for its own enforcement, he was no longer enforcing the law, but usurping power not conferred upon him by law, and that did not exist. I stated much on the subject of the collection of duties, and at length came to where, as regards the lighter duties, the Collector is authorized to make all the machinery necessary for the collection of the revenue. I then stated to
              him that, as this machinery did not exist, his authority ceased with it. He could only execute the laws through this agency, and when this agency no longer existed he had no right to substitute others than those which the law itself provided. I then referred to the case of the Courts. I remarked to him that the power to use the military authority of the
              <PageNumber num={23} /> country was in all cases subordinate to the civil power; that it could only be used as ancillary or subsidiary to the civil power; and that as soon as the Courts ceased to exist, and the civil authority was withdrawn from the seceded States, he had no authority to employ the military power for the enforcement of the laws, and that
              any attempt to do so by him would be a violation of the law, instead of an enforcement of it.
            </p>
            <p className="p-in-sp">
              I then referred to the forts. I stated to him that I cherished the hope that he would find it consistent with his sense of duty to withdraw his troops, at least from those forts which were, strictly speaking, local; that he might find it consistent with his sense of official obligation to withdraw the troops from Fort Sumter, Fort Pickens, and any other local forts which had no great
              national functions to perform; but which were intended for the defence of the particular locality in which they were situated. It seems, sir, that I was making the same discrimination, by accident, in regard to the forts, which was made in this Convention. I drew a distinction between the forts which I understand to be national and those that were local. I referred to Fort Sumter and
              the Tortugas, and stated that whilst the latter might be regarded as national in their purposes, as fulfilling national functions which interested New York and Massachusetts even more than Florida, yet, that when it came to these local forts, I could conceive that there was no public necessity for his retaining them. I said to him that upon this subject his power was plenary; that, as
              Commander-in-chief of the Army and Navy of the United States, he had power to dispose of these forces as to him seemed most expedient. I referred to the fact that it was an every-day occurrence to evacuate forts which it was no longer expedient to hold, and I had cherished the hope that he would find that it was no longer expedient to hold Fort Sumter or Fort Pickens, because they were
              no longer necessary for purposes of local defence; at least for the present. I stated that he might have withdrawn the troops from these forts without any renunciation of the jurisdiction of the United States over them; but that he might have made a public proclamation of his purpose in withdrawing the troops; that it was in the interests of peace, and that he would make an appeal to
              the people of the country to adjust these distracting issues which now are upon us. I stated to him that his predecessors had admitted that these questions were too grave and important to be dealt with by the Executive or Legislative authority of the country; that the only power competent to deal with them was either a Convention or Congress of the United
              <PageNumber num={24} /> States, through the Legislatures. At this point he interrupted me by saying that he made that appeal. I thanked him for referring to the fact, and assented to the truth of it, that he had, in his Inaugural Address, proceeded to make that appeal to the people of the United States; but I reminded him that he made that appeal after the
              Congress of the United States had adjourned. I reminded him of the fact that the Congress of the United States was the only agency through which a response could be obtained to that appeal. I here stated that it had been my expectation and hope, that in fulfilling the policy enunciated in his Inaugural, he would have felt called upon to convoke Congress to carry out the policy which he
              indicated in his Inaugural; but I called his attention to the fact, that instead of enforcing the policy indicated by that Inaugural, in carrying out this appeal after having addressed the appeal to an arbiter of his own selection, before that arbiter had gone upon the judgment seat, he prejudged the result of the appeal by his course. I felt it due to myself to make these remarks to
              the President, which were, as near as I can recollect, without any premeditation or previous preparation.
            </p>
            <p className="p-in-sp">
              Now, I desire to say a few words more. I confess, sir, that, like my friends who were associated with me, I regard the President's answer as in the highest degree unsatisfactory. I regard it, while courteous in form, as almost hostile in intent. But I must confess further, that I was utterly unprepared for the developments which have taken place since. All the inferences which my mind
              could draw from the interview were directly at variance with these subsequent developments. While his policy was decidedly expressed, yet I understood him, from our official communications-and the general impressions left on my mind, favored that idea-as intending nothing like a general war, nothing in the shape of a general system of hostilities. I understood his whole efforts to be
              limited to the special purpose of re-occupying the forts. While he said in his communication to us that he would, after the attack upon Fort Sumter, feel himself at liberty to recapture, if he could, that and other public property seized by the seceded States, he did not indicate any determination of purpose to do so. While he indicated that he might be at liberty to discontinue the
              mails, yet he did not indicate any purpose to do so. The inference that I drew was, that there was no such purpose as seemed to have existed; and while I cannot refer to anything which would justify me in imputing a want of candor, either to the Executive or to any of his advisers, yet I must confess that I was so entirely taken by surprise by the appearance of the
              <PageNumber num={25} /> proclamation, that I did not for a moment believe that it was authentic. I believed that it was a sensation document, gotten up by some mischievous persons; and such was my confidence of that fact, that as soon as I read the document yesterday, I repaired to my room, prepared a despatch to the Secretary of State, to ask him whether
              it was genuine or fabricated. I received a response from him late last night that it was genuine-a response which I was prepared to receive, of course, from the news which we learned through the mail. Like my colleagues, I therefore think, in view of this communication of the President to the Convention, through this Committee, and in view of the proclamation of the President of the
              United States to the whole people, that there is no hope of an amicable arrangement with the Administration. I have cherished, sir, a most ardent hope that matters would be solved peaceably. If there be any man upon this floor who has cherished a more ardent, a more decided, a more, I must say, religious and sincere love for this Union than any other, I claim to be that man.
              Seventy-nine years ago, the people of Augusta deputed my father to this city as a member of the Convention to ratify and adopt the Constitution which formed this Union. After a lapse of three-quarters of a century, the same county, the descendants of the same constituency, have deputed me here to uphold and sustain it. I have, therefore, sir, a traditionary attachment to the Union. I
              will stand by it, sir, as long as I can stand by it consistently with the honor and the interests of Virginia. I must confess that my hopes in the perpetuation of the Union, as it now stands, have been greatly weakened, if they have not been entirely destroyed.
            </p>
            <p className="p-in-sp">
              It seems proper, then, for us to decide in this emergency what we, as Virginians, are to do. There are three lines of policy that lay before us: One is to remain in the Union as we are, and to lend our forces and our arms to the subjugation of our Southern sisters. I believe, sir, that there is no man upon this floor who is prepared to stand here in that attitude. There is no man who
              desires to retain his connection with the present Union, without these guarantees of protection, and without the adoption of that satisfactory line of policy which would be consistent with the interest and the honor of the Commonwealth. Sir, if we remain as we are we abandon, in my judgment, all hope of obtaining any such guarantees; we abandon all hope of security, and we lend
              ourselves to the purposes of a dominant, sectional majority. I am not, then, sir, for adopting that line of policy.
            </p>
            <p className="p-in-sp">
              But there are two others. One is to secede immediately, and the <PageNumber num={26} /> other is to ask the co-operation of our sister States which have not yet seceded. I propose to make a few remarks upon this subject, all unprepared as I am, and all unexpected as the discussion is to me. And, sir, I wish to present them in a plain, practical point of
              view. I am a plain, practical man, who endeavors to look at things in a plain, practical, common sense point of view. Now let us see what would be the effect if an Ordinance of Secession is adopted at this time.
            </p>
            <p className="p-in-sp">
              Here is a war waging. Here is an immense preparation made on the part of the United States Government for carrying on that war. The present seat of that war is at a remote part of the Union. It is now confined to the region about the city of Charleston and the city of Pensacola. These are the points where the war is now seated. What would be the effect of the immediate secession of
              Virginia? It would be to transfer the seat of war from the Gulf of Mexico, and from the extreme Southern part of our Atlantic coast, to the bosom of Virginia. Yes, sir, your county
              [Loudoun]
              that county situated upon the old highway, North and South; the county of Jefferson and the county of Berkeley, would be the Flanders in this war. I could not conceive of any greater favor that you could confer upon this Black Republican administration than to secede now. It is attended with great cost and with great danger, to place every gun and every man at that remote point of our
              territory. The climate is insalubrious; the warm season is advancing, and if the war is prosecuted there, you may expect pestilence to be a more valuable ally than your armies. You may well expect that the miasma arising from the swamps of Florida, and the swamps that surround Charleston, would sweep out of existence more of the invaders than the arms of the Commonwealth and the
              Confederate States. All the provisions for the support of the Northern armies would have to be transported thousands of miles, at great expense; and it is under great difficulties that they could be supplied at all. But, sir, by Virginia's seceding you transfer the seat of war to this fertile and salubrious country. You transfer it to a country that furnishes every supply that is
              necessary for the support of the troops; to a climate that is entirely salubrious to the Northern troops who would be engaged in prosecuting the war. Yes, sir, you bring it home to your own fair cities and families. You go into this war without any aid from any quarter. We have no alliance with the Confederate States, nor our sister border States not yet seceded, and Virginia would
              stand alone between the Federal Government and the Confederate States of the South. She would be the battle ground. Her fields <PageNumber num={27} /> would be laid waste, and her citizens would become the victims of the conflict.
            </p>
            <p className="p-in-sp">
              And, sir, what is our state of preparation? Where is our ordnance? Where is our musketry? Where are our rifles? Where, in fact, are any of the munitions of war, which are indispensable for our security? Sir, you may talk about courage, and you may talk about chivalry; but I say it is not true courage and true chivalry to rush into such an unequal contest as that. I say, in my judgment
              it would be folly and fool-hardiness in the extreme. What is the condition of our Treasury? Let me tell you, sir; because it has been my melancholy duty to make myself familiar with it, being a member of the Senate of Virginia, and having occasion to examine the financial condition of the State. I tell you, you are bankrupt. In order to raise the money to buy a few arms, you were
              obliged to resort to the miserable expedient of attempting to issue one million of treasury notes. There is no money in your treasury; your credit is gone; your bonds are selling now, as some gentleman remarked, at some sixty dollars. Even the munitions which are to be bought under the operation of this treasury note bill, have not yet been supplied. Intimations have been given that
              the Governor has made a contract, and that there is some misunderstanding in regard to a portion of it. But, sir, all must concur that we are not in a condition to go to war, and I say that it is the part of common prudence, common sense and humanity, that we should not engage in war until we shall have prepared ourselves for the conflict.
            </p>
            <p className="p-in-sp">My friend over the way, from the city of Richmond [<span className="persName">Mr. RANDOLPH</span>] has suggested the idea of the capture of the Navy Yard at Gosport, and of the Armory at Harper's Ferry. Let me call his attention, and that of the Convention, to the relations which we now bear to the Federal Government.</p>
            <p className="p-in-sp">
              An ordinance of secession does not terminate our relations with the Federal Government. An ordinance passed by this body is not a dissolution of the ties which bind us to the Union. It goes forth simply as matter of advice to the people; and, without the ratification of the people, it is not worth the paper on which it is written. What, then, are we to do? Are we acting under the
              obligation of an oath to support the Constitution of the United States, as I venture to say almost every man in this body is? I ask gentlemen, under these circumstances, if they are prepared, in view of the obligation of that oath, to make, as it were, a flagrant and unprovoked war upon the Government of the United States by seizing upon these public arsenals? Sir, this property is
              left there in our charge, I may say, and <PageNumber num={28} /> I do not see how we can, consistent with our confederate obligations, seize upon the property which lies within our borders, under the implied confidence, at least, that it will be secure in our midst.
            </p>
            <p className="p-in-sp">
              It is not my purpose to detain the Convention with any elaborate views of all the considerations which would forbid the idea of immediate secession. Sir, let us look to other consequences which must result. In my opinion, secession is not only war, but it is emancipation; it is bankruptcy; it is repudiation; it is wide-spread ruin to our people; nay, sir, it must be more. It may result
              not only in a dissolution of the Union, but it may result in another dissolution, which, to me, would be more painful, even, than the overthrow of the Union itself. It may result in a dissolution of the bonds which bind together the different great slopes of this State.
            </p>
            <p className="p-in-sp">
              We all know that communities are governed by their interests. We all know that there are certain laws of attraction which are laws of nature, which are not controllable, and which lead to the formation of commercial relations that are more binding than political relations. Here we have our State divided into two great mountain shoots-one sloping to the Atlantic, and the other to the
              Ohio. We find the trade and the social relations of that mountain slope intertwined and associated with the great West. We find almost all their relations connected with the non-slaveholding States of the great West. These people, then, will be called upon to sever connections of the most intimate character-connections which affect vitally every interest which they have-connections
              which are indispensable to their enjoyment, their social happiness and prosperity. We may count upon the patriotism of that region. I do not want to question it, sir. But when this thing has gone on; when this merciless and desolating war shall have progressed for some length of time; when taxes shall have become intolerable and burthensome, you may rely upon it that the people will
              begin to look into the cause of the war; and, looking to the relations which they bear to this cause, they will be led, I fear, ultimately, to conclude that their interest in the war is secondary to other interests which they have connected with their material prosperity.
            </p>
            <p className="p-in-sp">But, sir, as wise, sagacious statesmen, we should not lose sight of these facts, and that before we take this fearful step-for fearful it is, sir, in every aspect in which we can view it-we should pause and ponder well.</p>
            <p className="p-in-sp">
              But, sir, there is another aspect of the case. We have already held out to our sister States that have not yet seceded, the idea that we <PageNumber num={29} /> intended to co-operate with them. We have passed, in Committee of the Whole, which embodies this whole Convention, by a large majority, an invitation to these States to co-operate with us in this
              trying emergency. Three of these States have already responded to that call. They have either appointed or taken steps for the appointment of delegates to meet delegates from this Convention in consultation, at Frankfort, on the 27th of next month. Now, sir, in what attitude would we stand-how would we be regarded, if, in violation of our previous action and of our invitation to them,
              we should now precipitately rush ourselves out of the Union without consulting them? Did we not all complain of the action of South Carolina, in going out of the Union without consultation with any of her sister States? Did we not complain that she should go out of the Union without consultation with the other Southern States and change their relations both with her and the General
              Government? I think there were few, if any who did not believe that South Carolina acted rashly, unwisely and improperly. Now, sir, I for one am not for following the example of South Carolina; but even if South Carolina did not stand in the same relation to the other States in which we stand to the sister States that have not seceded, here is our invitation gone forth, the invitation
              accepted by three or four of those States, and when the day arrives, they will find that they have been treated with the most marked disrespect, and that without that conference we have undertaken to assume a new line of policy which changes their relations as well as ours to the General Government.
            </p>
            <div className="speaker" id="sp3653"><span className="persName">Mr. MORTON</span>, of Orange and Greene—</div>
            <p className="p-in-sp">Will the gentleman permit me to interrupt him?</p>
            <p className="p-in-sp">The gentleman is speaking of an invitation from Virginia to the Border States. I should be glad to hear from the gentleman, what source was it that that invitation came from.</p>
            <div className="speaker" id="sp3654"><span className="persName">Mr. STUART</span>—</div>
            <p className="p-in-sp">I have stated already that it was an invitation extended through the Committee of the Whole.</p>
            <div className="speaker" id="sp3655"><span className="persName">Mr. MORTON</span>—</div>
            <p className="p-in-sp">I ask the gentleman if the action of the Committee of the Whole is the action of the Convention?</p>
            <div className="speaker" id="sp3656"><span className="persName">Mr. STUART</span>—</div>
            <p className="p-in-sp">
              Certainly not. I don't profess that it was a perfect invitation, but spoke of it as a qualified invitation-an invitation which was so well understood and so well recognized that it met with a response from three or four of our sister States. I discriminate between a quasi invitation; but still an invitation conveying fully the sense of the Convention, and an invitation having the
              formal approval of the Convention. The invitation having passed in Committee of the <PageNumber num={30} /> whole by a large majority, it might readily be supposed that it would meet with the sanction of the body which was composed of the same material that composed the committee.
            </p>
            <p className="p-in-sp">
              Let us look at the attitude in which we would stand. Here is Virginia surrounded on three sides by States that have not seceded, and on the other side bounded by the Atlantic ocean. How would we stand if North Carolina, Maryland, Kentucky and Tennessee refused to secede? I think sir, that it is due to these States that we should consult them; that it is the part of prudence that we
              should consult them before we take any final action. And upon the ordinary dictates of prudence, if a nation is going to war, does it not always seek such alliances as it may obtain-alliances offensive and defensive? When we were struggling for our independence did we not seek an alliance with France to aid us in our struggle for liberty? And I want to know if Virginia now, with a
              million of inhabitants, is to go into this unequal contest rashly and precipitately, when here are our allies around us who, by paying proper respect to them, would co-operate with us, as we have every reason to believe, in this great future struggle, if it shall come? But, if we have this conference, if these other States should come into line with us, if we show an unbroken front,
              then I cherish the hope that the North, according to the argument which has been urged by our friends, the secessionists, will see that it is a hopeless task to attempt to subjugate these eight States, in addition to the seven seceded States; and that, instead of the bloody war which we now expect, we might have a peaceable adjustment of our difficulties.
            </p>
            <p className="p-in-sp">
              Sir, I am in favor of making this appeal. I am opposed to passing any ordinance of immediate secession. I am in favor of addressing this invitation to our sister States to meet us in Conference at Frankfort on the 27th of next month. I want to have a full and fair interchange of opinion in secret session with them there, and I want to ascertain what they intend to do. I want to
              ascertain when they are ready to act. I want to tender the olive branch, not to the administration-because, as I said, I am hopeless of any good from that quarter; but I believe that the relations of business interests and those social ties which connect the Border States, on either side, will exercise the most potent influence on either side of the dividing line; and I would be
              willing to tender to Illinois, Indiana, Ohio, Pennsylvania, New York and New Jersey, such amendments to the Constitution, or such a new Constitution as we are willing to live under. I would invite their aid and concurrence. I would invite them to join us under a new Constitution, framed with such guarantees as would give to us
              <PageNumber num={31} /> effectual security for all our rights. I would invite them to disconnect themselves from the extreme North and North-west, and, unlike some of my friends, my information leads me to believe that such an appeal would be responded to by these States.
            </p>
            <p className="p-in-sp">
              Sir, fanaticism is a great evil, and I would avoid contact with it as I would a plague; but business relations, private interests, social ties, the ties of brotherhood, the ties of intermarriage and of communication, in every form and shape in which they can take place, must, to a great extent, counterbalance this odious fanaticism; and in severing those political ties, I would seek to
              withdraw these States from their allegiance to the Federal government-I would seek to induce them to become part and parcel of our new government. I would seek to have a tier of friendly States between the slaveholding States and the States of the extreme North and North-west. By pursuing this policy we would, I believe, ultimately effect a reconstruction of the Union upon such terms
              as we would dictate. We could compel the young States to come into our terms or to remain outside of this great Central Confederacy. We would establish such a government here in the great heart of this Continent as that our Southern sisters would quickly unite with us ; because under its flag they would feel there would be security from every foe, external and internal.
            </p>
            <p className="p-in-sp">
              Sir, let us look at some of the primary effects of immediate secession: Why, sir, under the intimations thrown out by the President, this country might in one week after the passage of the Ordinance of Secession, be in the condition of a man whose arms are paralyzed. Your mails might be broken up, and all communication stopped, thus completely clogging all commercial operations with
              the great seat of commerce with which we hold the most important commercial relations. Sir, pass this Ordinance of Secession now, and you incur another hazard. You incur the hazard that the people themselves, not quite as sensitive to the highest notions of chivalry as the members of this Convention, but looking with a more unimpassioned view at the practical results, the interruptions
              to business, the burthensome taxation, the onerous military service, all the privations of every description which they are to suffer, might be induced to vote down the Ordinance of Secession. And where would you then stand? The gentleman before me
              [<span className="persName">Mr. MORTON</span>]
              says forcible revolution would follow. Revolution against whom? Against any body and every body. Well, sir, that is very vague and indefinite. I must confess I cannot comprehend it. I know the gentleman would not be willing to turn his sword against his brother, who, cherishing a feeling of attachment <PageNumber num={32} /> to his country, might entertain
              a different notion of what is best for its interest. The gentleman says let them try. I know he is speaking under excitement. I know there is no feeling in his heart that would induce him to turn his sword against a brother. I do not question the gentleman's patriotism; but Providence has created man with different qualities. Providence has ordained these diversities of intellectual
              structure for many purposes. It enables man to look from different stand points. It enables man to see subjects in different aspects; and it is by comparison of different views that we are enabled to get at a full knowledge of the entire subject. I occupy that position here. The subject presents itself in one light to my eye on one side, while on the opposite side it presents itself in
              an entirely different light; yet we are equally honest in our views, and it is only by comparison of each that we finally learn to comprehend the whole subject.
            </p>
            <p className="p-in-sp">
              I claim to be actuated by my own views of what is right, of what is just, patriotic and expedient, under all the circumstances of the case, looking at it in every aspect in which I can view it, but holding myself open to conviction-for I am not one of those who are in the habit of adopting opinions from which they cannot be moved. It is said that men of sense change their opinions, but
              fools never do-I claim at least that amount of intellect which does make me, when the light of reason is shed upon a subject and new information' is thrown upon me, cheerfully yield to its influence and change my opinion but, as I was saying, under all the circumstances of the case, and looking at it in every aspect in which I can view it, it seems to me that the proper course for this
              Convention to pursue would be to meet our sister border States in conference and consult with them on that great question which we are here considering, in order thereby to secure concert of action. This I believe to be the true policy.
            </p>
            <p className="p-in-sp">
              I have thus given my views on this great measure. I choose to dictate to nobody, and will submit to no dictation from anybody. I will listen with candor and fairness to the views of every gentleman, that may be presented, and will endeavor, when we come to the closing scene of this great drama, to do what my judgment shall dictate as the best for the interest of our State and country.
            </p>
            <div className="speaker" id="sp3657"><span className="persName">Mr. MORTON</span>—</div>
            <p className="p-in-sp">Will the gentleman permit me to ask him a question?</p>
            <div className="speaker" id="sp3658"><span className="persName">Mr. STUART</span>—</div>
            <p className="p-in-sp">Certainly.</p>
            <div className="speaker" id="sp3659"><span className="persName">Mr. MORTON</span>—</div>
            <p className="p-in-sp">The gentleman, I understood, in referring to the first proposition which he laid down, repudiated the idea of continuing in this Union.</p>
            <div className="speaker" id="sp3660"><span className="persName">Mr. STUART</span>—</div>
            <p className="p-in-sp">Yes, sir.</p>
            <PageNumber num={33} />
            <div className="speaker" id="sp3661"><span className="persName">Mr. MORTON</span>—</div>
            <p className="p-in-sp">The gentleman has not told us what would be his course in the event of the rejection or the abortion of the Border Conference. Suppose the Conference should fail, what position would he then have Virginia occupy?</p>
            <div className="speaker" id="sp3662"><span className="persName">Mr. STUART</span>—</div>
            <p className="p-in-sp">
              I might answer the gentleman in the language of the Scripture : "Sufficient unto the day is the evil thereof ;" but I will say that his conclusions are so remote, so nearly relate to an impossibility, as scarcely to need any discussion. If we treat our sister States with ordinary respect, I can hardly look to such a contingency, or deem any measure necessary to provide for that
              contingency.
            </p>
            <div className="speaker" id="sp3663"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">I beg leave to lay before the Convention the following communication from the Governor of the Commonwealth.</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>EXECUTIVE DEPARTMENT,</p>
                <p>April 16th, 1861.</p>
                <p>Gentlemen of the Convention: In response to your resolution, this day adopted, I communicate herewith a dispatch received last evening. This is the only information I have received on this subject. I expect to hear further by the mails of to-day.</p>
                <p>Respectfully,</p>
                <p>JOHN LETCHER</p>
                <p>The following is the telegraphic dispatch referred to in the communication :</p>
                <p>WAR DEPARTMENT,</p>
                <p>Washington, April 15th, 1861.</p>
                <p>Call made on you by to-night's mail for three regiments of militia for immediate service.</p>
                <p>SIMON CAMERON</p>
                <p>Secretary of War</p>
              </div>
            </div>
            <div className="speaker" id="sp3664"><span className="persName">Mr. SUTHERLIN</span>, of Pittsylvania—</div>
            <p className="p-in-sp">I did not intend to have said a word, but to listen to older and wiser heads than my own. But I cannot stand here and listen to the speech of the gentleman from Augusta [<span className="persName">Mr. STUART</span>], without making some remarks.</p>
            <p className="p-in-sp">
              What do we find him saying to us? We find him counseling us now not only to take our own position at our leisure, but to inaugurate a <PageNumber num={34} /> system of conference with the border States, whilst this Government, to which he says he will not submit, is now surrounding us with forces which are to subdue us in this Union. Is that what the
              gentleman desires? He says no. And now while they are chaining, haltering and pressing him down to that submission which he predicts, he wants to inaugurate a system of border State conference, and then to go with a proposition to these people at the North, asking them to permit us to live with them in peace. I tell you that such a policy is enough to make a patriot's heart sicken
              within him. I feel it; my people feel it, and the people of the whole State feel it, and they will hold responsible every man here to-day for any longer delay in taking the steps necessary to place Virginia in a position of independence. I am myself under serious obligation to obey the laws of the United States. Am I to be kept in a position in which the obligations under that oath
              shall continue to rest? And this is the position of every man in office in Virginia. The State should at once be placed in an attitude to relieve its citizens from this predicament. These are practical questions; you have got to meet them. Will you submit or will you resist this oppression? If you resist it, how long will it be before you undertake the task? I tell you, as certain as
              there is a God in Heaven, that, unless we act now and act promptly, we are a degraded and disgraced people; and I would rather die a soldier's death than stand here and see this great old Commonwealth disgraced by my action. I have been a Union man, but, my God, I never have been a submissionist. Here are these 120 gentlemen who voted a few days ago against coercion. Has not coercion
              commenced already, and are they not now bound in honor to make good the pledge which they have recorded? Let them go home to their constituents and ask them whether they will sustain them or not. They are upon the record and we mean to hold them to it.
            </p>
            <p className="p-in-sp">
              I felt that it was due to myself that I should say this much. I don't mean to detain this Convention. I believe we ought to act, and to keep our actions secret. We ought to prepare now, and get possession of every available means within this State that will be worth anything to us in this contest. If we fail to do so, we will find that they will be used against us. Are you Virginians?
              You Western Virginians, prepared to submit to the system of oppression which is about being inaugurated by your enemies at Washington? I feel an abiding hope that you will not submit; but that you will take your stand by the side of your fellow citizens in Eastern Virginia. There is no reason why we should divide. We feel a deep interest in you, and can you
              <PageNumber num={35} /> take any course, or aid in the inauguration of any policy which would dishonor and disgrace us? I trust not. For one, I shall raise my voice here against any such policy, and, so help me God, I hope that the people of Virginia will hold us all responsible for our action here. Gentlemen talk about their constituents; I have a Union
              constituency who elected me by a majority of one thousand, and I believe now that there are not ten Union men in that county to-day. I tell you there is no time to parley now. The time for action has come, and I trust the Convention will act fully up to the crisis.
            </p>
            <div className="speaker" id="sp3665"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">I beg leave to submit a communication from the Governor of the Commonwealth for the consideration of the Convention.</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>EXECUTIVE DEPARTMENT, April 16, 1861. Gentlemen of the Convention:</p>
                <p>I have received the telegraphic dispatch herewith transmitted, addressed to Mr. Charles Harris, and by him placed in my hands. I understand the person transmitting it is reliable. The delegates from that section, however, know Mr. Hathaway, and can furnish the body with information on this point.</p>
                <p>Respectfully, JOHN LETCHER</p>
                <p>NORFOLK, April 16, 1861</p>
                <p>To Charles Harrris, at one of the Hotels:</p>
                <p>
                  Information has been received here that five hundred Federal troops will be here to-morrow morning to defend the Navy Yard. The Merrimac and Cumberland are taking on board everything they can. The Cumberland took all the money out of the Custom House to-day. See Governor Letcher and get him to send an order here by telegraph to obstruct the channel, and plant batteries on the
                  Elizabeth river for our protection. Do it immediately or much will be lost. Wm. H. Parker is now on his way to Richmond to see Gov. Letcher on this subject. Arrangements are nearly all ready, and we only await the authority to act. In haste.
                </p>
                <p>JOHN R. HATHAWAY</p>
              </div>
            </div>
            <PageNumber num={36} />
            <div className="speaker" id="sp3666"><span className="persName">Mr. GEORGE BLOW</span>, JR., of Norfolk City—</div>
            <p className="p-in-sp">I will state, sir, for the information of the Convention, that the gentleman whose name is appended to that despatch, is one of the most respectable and respected citizens of Norfolk.</p>
            <p>The communication and the accompanying despatch were laid upon the table.</p>
            <div className="topic" id="vsc1965.v4.2.1.1">
              <h3><span className="headingNumber">1.1.1. </span><span className="head">THE ORDINANCE OF SECESSION</span></h3>
              <div className="speaker" id="sp3667"><span className="persName">Mr. WM. B. PRESTON</span>, of Montgomery—</div>
              <p className="p-in-sp">
                I arise, with feelings of the deepest pain, to, offer something to the House that is tangible, and to express my opinion in this exigency. I hold in my hand what I am compelled to offer and what, in a measure, circumstances have accidentally made me the origin of. It is an ORDINANCE OF SECESSION. I offer it to the House, and I trust that God shall extend his mercy to me- on this
                occasion. He is my witness that I am devoting every service of my heart to the Commonwealth of Virginia. That Ordinance has not been offered under the influence of circumstances or telegraphic information. It is offered on the basis of the report we brought here from Washington, and the proclamation of the President. I cannot, I will not recede now from the grounds I have taken. I
                feel that I would be unworthy of the position I occupy here, were I to take one step backwards. Those who choose this lead may follow. Those who don't choose have a right to take whatever course their judgments may dictate. I will not upbraid them if they choose to take a position different from that which I have marked out for myself. I shall go through all these struggles with a
                consciousness that I have done my duty to my country, and I believe I have done it to God, and I feel that in this contest God himself will be with us. I now submit the Ordinance:
              </p>
              <p className="p-in-sp">AN ORDINANCE to repeal the ratification of the Constitution of the United States of America, by the State of Virginia, and to resume all the rights and powers granted under said Constitution.</p>
              <p className="p-in-sp">
                The people of Virginia, in their ratification of the Constitution of the United States of America, adopted by them in Convention, on the twenty-fifth day of June, in the year of our Lord one thousand seven hundred and eighty-eight, having declared that the powers granted under the said Constitution were derived from the people of the United States, and might be resumed whensoever the
                same should be perverted to their injury and oppression, and the Federal Government having perverted said powers, not only to the injury of the people <PageNumber num={37} /> of Virginia, but to the oppression of the Southern slaveholding States,
              </p>
              <p className="p-in-sp">
                Now, therefore, we, the people of Virginia, do declare and ordain, That the ordinance adopted by the people of this State in Convention, on the twenty-fifth day of June, in the year of our Lord one thousand seven hundred and eighty-eight, whereby the Constitution of the United States of America was ratified; and all acts of the General Assembly of this State ratifying or adopting
                amendments to said Constitution, are hereby repealed and abrogated; that the union between the State of Virginia and the other States under the Constitution aforesaid, is hereby dissolved, and that the State of Virginia is in the full possession and exercise of all the rights of sovereignty, which belong and appertain to a free and independent State.
              </p>
              <p className="p-in-sp">And they do further declare, That said Constitution of the United States of America, is no longer binding on any of the citizens of this State.</p>
              <p className="p-in-sp">This ordinance shall take effect and be an act of this day, when ratified by a majority of the votes of the people of this State, cast at a poll to be taken thereon, on the fourth Thursday in May next, in pursuance of a schedule hereafter to be enacted.</p>
              <p className="p-in-sp">Done in Convention in the city of Richmond, on the seventeenth day of April, in the year of our Lord one thousand eight hundred and sixty-one, and in the eighty-fifth year of the Commonwealth of Virginia.</p>
              <div className="speaker" id="sp3668"><span className="persName">Mr. JOHN J. JACKSON</span>, of Wood—</div>
              <p className="p-in-sp">
                I did not suppose that it was the intention of the Convention to bring that matter before the body at this period. I had a desire to say something, and it is the last opportunity I expect to have ever to lift my voice in this or any other deliberative body. I expect, as an old man, to give utterance to the views I have upon this question, with perfect frankness and candor, and in
                order to give me a fair opportunity to do so, I hope it will be the pleasure of the Convention to adjourn till morning.
              </p>
              <div className="speaker" id="sp3669"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">I am very much exhausted, and I would move to adjourn until 4 o'clock, P. M.</p>
              <div className="speaker" id="sp3670"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I hope the gentleman will add to his motion, "and that the Sergeant-at-Arms be directed to inform all the sick members to be present if possible."</p>
              <div className="speaker" id="sp3671"><span className="persName">Mr. R. Y. CONRAD</span>, of Frederick—</div>
              <p className="p-in-sp">I would ask the gentleman to withdraw his motion for a moment, to enable me to submit a report from a Committee.</p>
              <PageNumber num={38} />
              <div className="speaker" id="sp3672"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">I withdraw it for that purpose.</p>
              <div className="speaker" id="sp3673"><span className="persName">Mr. CONRAD</span>—</div>
              <p className="p-in-sp">The Committee appointed by the Convention this morning to wait upon the Governor, have directed me to report that they waited upon the Governor and presented to him a copy of the resolution. He desired me to say that he would promptly communicate to the Convention any information he may receive from time to time.</p>
              <div className="speaker" id="sp3674"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">I move we adjourn.</p>
              <div className="speaker" id="sp3675"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">What will be the effect of that motion if carried?</p>
              <div className="speaker" id="sp3676"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">To adjourn till to-morrow.</p>
              <div className="speaker" id="sp3677"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">There are other propositions to be submitted to this House which I thought it would be proper that the Convention should have time to consider.</p>
              <p>The question was then taken by a count on the motion to adjourn, and decided in the negative-ayes 51, noes 62.</p>
              <div className="speaker" id="sp3678"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">I renew the motion to take a recess until 4 o'clock.</p>
              <p>The motion was agreed to.</p>
              <p>The Convention re-assembled at the appointed hour.</p>
              <div className="speaker" id="sp3679"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">I beg leave to present a communication from the Governor of the Commonwealth.</p>
              <div className="floatingText">
                <div className="floatingText_body">
                  <p>EXECUTIVE DEPARTMENT,</p>
                  <p>April 16, 1861.</p>
                  <p>Gentlemen of the Convention:</p>
                  <p>By the mail of this evening, I received from Hon. Simon Cameron, Secretary of War, the communication and pamphlet herewith transmitted for your consideration.</p>
                  <p>I communicate also a dispatch from W. B. Cooke, of Norfolk city. To this dispatch I have replied, instructing the pilots not to take out of that port any war vessels.</p>
                  <p>Respectfully, JOHN LETCHER</p>
                  <p>The following is the communication received from Simon Cameron, referred to above:</p>
                  <p>WAR DEPARTMENT,</p>
                  <p>WASHINGTON, April 15, 1861.</p>
                  <p>
                    SIR: Under the act of Congress "for calling forth the militia to execute the laws of the Union, suppress insurrections, repel invasions," <PageNumber num={39} /> etc., approved Feb. 28, 1795, I have the honor to request your Excellency to cause to be immediately detached from the militia of your State the quota designated in the table below, to serve
                    as infantry or riflemen for the period of three months, unless sooner discharged.
                  </p>
                  <p>Your Excellency will please communicate to me the time at or about which your quota will be expected at its rendezvous, as it will be met as soon as practicable by an officer or officers to muster it into the service and pay of the United States. At the same the oath of fidelity to the United States will be administered to every officer and man.</p>
                  <p>The mustering officer will be instructed to receive no man under the rank of commissioned officer who is in years apparently over forty-five, or under eighteen, or who is not in physical strength and vigor.</p>
                  <p>Quota from Virginia: 3 Regiments, to be composed of 3 Colonels, 3 Lieut. Colonels, 3 Majors, 3 Adjutants [Lieutenants] , 3 Surgeons, 3 Surgeons' Mates, 3 Sergeant Majors, 3 Drum Majors, 3 Fife Majors, 30 Captains, 30 Lieutenants, 30 Ensigns, 120 Sergeants, 120 Corporals, 30 Drummers, 30 Fifers, 1,920 privates. Total of officers, 111; do. of men, 2,229. Aggregate 2,340.</p>
                  <p>The rendezvous for your State will be at Staunton, Wheeling and Gordonsville.</p>
                  <p>I have the honor to be,</p>
                  <p>Very respectfully, your ob't serv't,</p>
                  <p>SIMON CAMERON, Secretary of War</p>
                  <p>To His Excellency JOHN LETCHER, Governor of Virginia. The following is the despatch received from W. B. Cooke :</p>
                  <p>NORFOLK, April 16,1861</p>
                  <p>Would it not be well for you to order the Virginia pilots not to take out of our port any United States war vessels? They await your orders. There are two here now ready for sea, and may sail at any moment.</p>
                  <p>W. B. COOKE</p>
                </div>
              </div>
              <p>The communication and accompanying documents were laid upon the table.</p>
              <PageNumber num={40} />
              <div className="speaker" id="sp3680"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                I would call the attention of the Convention to the fact that the Governor does not inform us what his reply is to the requisition made upon him by the President for Virginia's quota of troops. I think, sir, that before that requisition could be possibly executed, it becomes our duty to order him not to obey it. The matter requires immediate action. I make this suggestion for the
                discretion of the House; and, by way of testing the sense of the Convention as to the propriety of acting upon it, I move to lay aside the order of business for the present, in order to pass an ordinance directing the Governor to refuse to comply with the requisition of the President.
              </p>
              <div className="speaker" id="sp3681"><span className="persName">Mr. STUART</span>—</div>
              <p className="p-in-sp">
                I would hope that the Convention would not lay aside the order of business. The course suggested would imply a distrust of the Governor in reference to the discharge of his duty. I think I can say, with confidence, that there is no danger of the Governor's responding affirmatively to that communication. It is hardly to be supposed that a communication received this evening could have
                been responded to by him at this early day. I think I can say that when the Governor does answer, it will be such an answer as will become his dignity, and the position of the Commonwealth.
              </p>
              <div className="speaker" id="sp3682"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I do not mean to express any distrust or confidence; but I do mean to say, so far as I am concerned, that I mean to do my duty.</p>
              <p className="p-in-sp">We are here representing the sovereignty of the State, and we have a part to act, as well as the Governor.</p>
              <p className="p-in-sp">
                I hope the Governor will refuse to obey this requisition. I find that he has sent us two communications informing us that the requisition has been made upon him, but has not intimated what his answer might be. Although it may require time to prepare the answer to the requisition, it would not require more than a sentence for him to have said that he did not intend to comply with it.
              </p>
              <p className="p-in-sp">Now, when the gentleman says that he will do what will comport with duty-that he will refuse to obey the requisition-does he state what he knows to be the intention of the Governor?</p>
              <div className="speaker" id="sp3683"><span className="persName">Mr. STUART</span>—</div>
              <p className="p-in-sp">Certainly, sir.</p>
              <div className="speaker" id="sp3684"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Then I withdraw the motion for the present.</p>
              <div className="speaker" id="sp3685"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">I hope the gentleman will not withdraw the motion. I think the Governor is entitled to act under the sanction of this body. He has communicated intelligence which, from its importance, requires that we should express an opinion upon the response to be given to it. It will not be any less an Executive act if endorsed by this Convention.</p>
              <PageNumber num={41} />
              <div className="speaker" id="sp3686"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Will the gentleman allow me to say that this is a question, now, which may properly be raised after we know what the answer is; but, with the assurance of the gentleman from Augusta [<span className="persName">Mr. STUART</span>] that the answer will be a refusal to comply with the requisition, I cannot but await information as to what that answer will be. Let him take his responsibility, and we will take ours.</p>
              <div className="speaker" id="sp3687"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">Let me ask if it will not go forth with additional sanction if it has the endorsement of this body?</p>
              <div className="speaker" id="sp3688"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I thought it would. I am assured by another friend that there is other information to act upon, and we can act upon all at the same time.</p>
              <p className="p-in-sp">I will withdraw my motion for the present.</p>
              <div className="speaker" id="sp3689"><span className="persName">Mr. SPEED</span>—</div>
              <p className="p-in-sp">At the suggestion of a friend, I will communicate to the Convention a communication that I received by telegraph before dinner, from a gentleman now in the city of Washington, whose responsibility I fully endorse. The despatch reads:</p>
              <p className="p-in-sp">"The question of seizing upon Anderson's establishment, now manufacturing arms for the Southern Confederacy, is now being discussed here. See Anderson on the subject."</p>
              <div className="speaker" id="sp3690"><span className="persName">Mr. SPEED</span>—</div>
              <p className="p-in-sp">I went as soon as possible and saw Mr. Anderson, who immediately communicated this information to the Governor. Mr. Anderson soon after informed me that the Governor's reply was, that this Convention was in secret session, and that he presumed it would do something in the premises.</p>
              <div className="speaker" id="sp3691"><span className="persName">Mr. JOHN J. JACKSON</span>, of Wood—</div>
              <p className="p-in-sp">It was not my purpose in rising before the recess to make any extended remarks, nor should I do so now but for some remarks which have fallen from gentlemen on the the other side.</p>
              <p className="p-in-sp">
                No gentleman upon this floor appreciates this occasion with more solemn feelings than I do. Sir, I feel choaked-I scarcely have utterance to convey to you the estimate I form of the magnitude of the subject which we are now deliberating upon; and, sir, above all things, I am utterly incompetent to portray, even in the feeblest manner, the momentous consequences, which, in my humble
                judgment, hang upon the present movement.
              </p>
              <p className="p-in-sp">I will now proceed to the consideration of the subject; and the brief remarks which I shall make, I shall be solely governed by a sense of duty, and in the discharge of that duty I will claim your indulgence.</p>
              <p className="p-in-sp">
                In times of revolution to be patriotic is to shout for war. In times of revolution to be a traitor is to counsel peace. I stand, I fear, in <PageNumber num={42} /> that relation upon the present occasion. An old man, sir, "to the manor born," never out of Virginia, except in the public service, educated in the Military Academy, I served five years in the
                public service, and after leaving that service, which I have to recollect with bitterness, because of its consequences to me, I was pursued with a degree of violent hostility by the abolitionists along the Ohio borders, which few men could have withstood. Sir, it was in that very service that I had caused the arrest of several individuals along the Ohio river, who were subsequently
                brought here and punished in your critninal courts. It was in consequence of that, sir, that I became the object of unceasing attacks through the whole extent of my abolition country. In consequence of my services to the Commonwealth on that occasion, my servants were taken from me. I suppose there is no gentleman within the sound of my voice that has suffered as much as I have in
                the cause of the Commonwealth. I lost from $15,000 to $20,000 worth of servants, and yet it seems that gentlemen would insinuate doubts as to my integrity on the question of the Commonwealth's interests. But I can bear this-I can bear anything when I believe it comports with my country's good. I can appeal to gentlemen who served with me on the Committee on Federal Relations, whether
                throughout all the efforts there, I did not exhibit a conciliatory spirit; whether I did not give up my own predilections and views, in order that I might harmonize all with whom I associated, and present to the country that which seemed necessary to heal the country's troubles. I have done, in short, all that lies in my power in order to effect reconciliation, and restore peace and
                unity. I have now been conducted by gentlemen upon this floor to the brink of a yawning gulf, and I feel to-day as if I was at a funeral-not the funeral of my friends or relatives, as I have often been-but the funeral of my country; aye, sir, a funeral which must be but the forerunner of many a disaster, and much suffering.
              </p>
              <p className="p-in-sp">
                I have no heart; no, no, sir-none whatever. I stand here an old man; I have loved my country; I have served my country; I have served this Commonwealth long, faithfully, earnestly-doubtless, not so well as I might have done-not so well as many others, doubtless, have done; but I served her with my whole heart. I served her thirty-five years ago on this floor; and though not a very
                old man in the public service of the Commonwealth, I stand here to-day having taken the oath to support the Constitution of the United States twenty-seven times. Was that an unmeaning ceremony? Was it of no consequence that I called the eternal God to witness that I would be true to the <PageNumber num={43} /> Constitution of Virginia as well as the
                Constitution of the United States? Is it there registered for nothing?
                [Pointing his finger towards Heaven.]
                In a few years more I expect to be confronted with Him. My time is nearly ended. I am in the "sear and yellow leaf," and the question now propounded to me is-twenty times I have taken the oath to support the Constitution of the United States, and am even holding an office now which requires obedience to that Constitution-and the solemn question is propounded to me, is it compatible
                with my obligations, not only to my country, but with my obligations of duty to God, that I shall obliterate this magnificent fabric of self-government, this Constitution of which we are so often reminded when we go to the door and look upon yonder Capitol
                [referring to the Capitol at Washington]
                that cost the Treasury millions upon millions-a Capitol founded on the counsel and the wisdom, not only of the most enlightened and distinguished men that were ever permitted to adorn the earth, but on a Constitution that had for its guiding star and direction the ever-blessed Spirit himself. I am called upon to obliterate that; to efface it from the memory of man. Will we not have
                to pause? Will not ordinary spirits fail as they enter upon the unholy task. The man who was cradled under the stars and stripes; who was nurtured and cherished under them; who grew to manhood under their benignity and protection, and who now stands in the enjoyment of every civil right which he needs-I say that a man so blessed should pause and reflect upon these benefits before
                taking this fatal step. It was a great work to create man, but it was a greater work to keep him from pursuing the path of ruin and degradation. It was in fact the work of a demon to drag him from his high eminence, and pervert him from his noble destiny. It was a great work to create this Government; but it is the work of a Lilliput alone to destroy it. Who is it that purposes to be
                the architect to reconstruct this Government? Who is it? I suppose we are to have a renewal of that exhibition a few evenings ago, in having the flag of treason hoisted over the public buildings of the nation, as was done at Fort Sumter. I am not responsible for my conduct; the truth operates upon my mind, and I cannot control its influence. I do not wish to feel unkind towards
                anybody; and on the present occasion I certainly do not; but I must say, that I do not concur with gentlemen in the views they have taken upon this subject. I desire to call to the recollection of gentlemen the prediction I made last Saturday week ago, to the effect that the answer which the Commissioners to Washington would receive, would be unsatisfactory, and that that
                <PageNumber num={44} /> being so, it would cause Virginia to march right out of the Union. That prediction has been fulfilled.
              </p>
              <p className="p-in-sp">Mr. President, look at it, sir. Take the history of this transaction from its beginning. Simultaneously with the going of these Commissioners to Washington, when it was as palpable to my mind as that the sun is in the Heavens-the sun does not shine to day-the sun has draped himself in sombre to day- [Laughter]
                -I say it was as palpable to my mind as any event could be, that the answer of the President would be unsatisfactory-but as I was about to say, simultaneously with the departure of the Commissioners, Fort Sumter was attacked, and the country being thus in a state of revolution, the presumption was that no favorable answer would be given by the President, even though he should
                otherwise be disposed to show a conciliatory spirit.
              </p>
              <p className="p-in-sp">
                Just as the Commissioners were appointed, Mr. Pryor started for South Carolina, and there informed the authorities of the appointment of this commission, and of the probability of their receiving a favorable reply unless some step was taken, such as the attack upon Fort Sumter, to convulse the country, and thus put a stop to negotiation. The attack upon the fort soon followed, and we
                have now the result, at least, so far as relates to the action which Virginia evidently contemplates taking.
              </p>
              <p className="p-in-sp">The gentleman from Richmond [<span className="persName">Mr. RANDOLPH</span>]
                , astounded me beyond measure this morning, when he intimated that it is war to garrison the whole State, but not war to seize upon the public property within the limits of Virginia; and if that be the case, is it in the power of this Convention to make an act of war which will change the relations of the people of this Commonwealth to the government of the United States? Have they
                not declared that you can do no act changing the relations of the people of Virginia to the General Government, without submitting it to them? If you seize upon ships-if you seize upon the Armory at Harper's Ferry-is not that an act of war, changing the relations of the people of Virginia to the General Government? I put it to the gentleman from Richmond, and to this Convention, if
                any of these acts is not a flagrant act of war against the General Government, which necessarily changes the relations of the people of Virginia to that Government? What signifies your act of secession, if you are to be guilty of a flagrant act of war? Why go through the sham of sending to the people an ordinance of secession, when you yourselves have struck the blow at once by
                seizing upon ships, arsenals, &amp;c.? Why, sir, it is a solemn farce. The people of Virginia, if they had been permitted to have done so, would have <PageNumber num={45} /> voted down the Convention altogether. They never dreamed that they were clothing you with the power of war, and yet the very ordinance which the gentleman from Montgomery
                [<span className="persName">Mr. PRESTON</span>]
                , has proposed, involves an act of war. True, the ordinance will have to be submitted to the people; but a determination will already have been formed by this act of war, to go out. If the people vote down this ordinance, what then? Why, you are already at war with the General Government, and the people will find themselves in the peculiar embarrassment of sanctioning this act, or
                leaving its authors to reap the penalties which they must suffer at the hands of an outraged and injured people-nay, even at the hands of the government of the country.
              </p>
              <p className="p-in-sp">
                The people of Virginia have sent us as their deputies, clothed with power to do a specific act or acts, and they have inhibited us from doing any act that shall change the relations which we sustain to the Government of the United States; and yet your property, your everything, is to be depressed by a descent upon Harper's Ferry, which is a flagrant act of war done under sanction of
                this Convention, if the indications which I see around me be correct. And in the teeth of this, with facts like these apparent to the world, you are going before the people with this sham ordinance, asking them whether they will secede or not!
              </p>
              <p className="p-in-sp">I do not want to trifle with the people. I do not want to do that which will stultify myself before them. If you mean war, say so truly; throw your scabbard away, and tell the people that you mean to do just what you do.</p>
              <p className="p-in-sp">That was the matter that I rose to speak about. I do not intend to go into the subject of this message, as the gentleman from Montgomery [<span className="persName">Mr. PRESTON</span>] , has done. I give that gentleman great credit for his candor and patent sincerity. I trust that he, and you, and all, will indulge me in the same way as you have that gentleman, while I take a brief view of the communication from the President.</p>
              <p className="p-in-sp">I understood that communication-and, in my opinion, the people of the country will understand it-to mean nothing but what is defensive. I have it before me.</p>
              <p className="p-in-sp">It says : "The powers confided to me will be used to hold, occupy and possess the property and places belonging to the Government, and to collect the duties and imposts; but beyond what may be necessary for these objects, there will be no invasion, no using of force against or among the people anywhere."</p>
              <p className="p-in-sp">
                The term "possess" must necessarily be understood to mean "to be <PageNumber num={46} /> in possession of" at first. If he had meant to "re-capture," he would use language to convey that idea. If he contemplated "re-possession," he would use language in conformity with that intention. And to show that he does not mean either, he expresses himself, in the
                latter part of this reply to the Commissioners :
              </p>
              <p className="p-in-sp">"Whatever else I may do for the purpose, I shall not attempt to collect the duties and imposts by any armed invasion of any part of the country; not meaning by this, however, that I may not land a force deemed necessary to relieve a fort upon a border of the country."</p>
              <p className="p-in-sp">That is, in my humble judgment, a full and complete refutation of the argument that has been used on the other side. The President does not desire to harm Virginia in any particular whatever.</p>
              <p className="p-in-sp">But the gentleman from Richmond [<span className="persName">Mr. RANDOLPH</span>] , indulged in expressions which I was very much surprised to hear. I trust it was a slip of the tongue, and that he really did not mean to say what he did say.</p>
              <p className="p-in-sp">He asks us, what will you do? And he says it would be base to talk about neutrality.</p>
              <p className="p-in-sp">
                Mr. President, I never believed it was base at any time for a man to look before he leaped; to consider before he undertook to act; to survey the whole subject, and to see whether he was forcing the representatives of the people here, who have rights upon this floor as well as others, into measures which have not the approval of their judgments; to examine what is all this war about,
                and to reflect upon every other difficulty which may grow out of this transaction.
              </p>
              <p className="p-in-sp">
                We are told that, as for the protection of slave property, my people have none. They have rights and interests, and if these people could believe in their consciences that your rights and interests in this particular were depreciated, they would readily grant you all the support that may be in their power to afford. But they cannot believe it; and, if anybody could be alive to the
                interests of that institution, I would have been that man. I have had a clear, painful light showered upon me, in consequence of the transactions I have already adverted to. I have not yet been informed that the Government is in fault for this lack of protection complained of. Did the Government of Virginia come to my relief to protect me, and to restore to me my property? They did
                not. The Government of the United States and that of Virginia did all they could, and, having done so, I was bound to be content. A bad man may steal your horse or your negro. Do you hold the Government accountable for that? You have no more right to hold the Government of the United States responsible for <PageNumber num={47} /> the theft of your negro,
                than you have to hold it responsible for every man who chooses to steal your horses.
              </p>
              <p className="p-in-sp">
                Then we are going to destroy this Government, under which we have prospered as no people ever prospered before, and which we are bound to hand down to posterity as it was given to us-and for what? Because South Carolina chooses to throw off her connection with the General Government. Virginia does not suffer more now than she did twenty years ago, and I can see no other reason for
                this effort on her part to destroy this Government, than this action on the part of South Carolina. It strikes me that the Government of the United States is acting on the defensive. Such seems to me to be the case. I may not be able to comprehend the entire bearing of the proposition, but, according to the best lights of my understanding, it does seem to me that the Government of
                the United States is acting on the defensive; and, viewing the matter in this light, can you expect me, representing a people who have their family connections on the other side of the Ohio, in sight of them-who are allied with the people of that State by inter-marriages-do you expect me to abandon them all, and to throw them away by giving sanction to your proceeding?
              </p>
              <p className="p-in-sp">Why, sir, the gentleman from Pittsylvania [<span className="persName">Mr. SUTHERLIN</span>] , spoke to-day with patriotic warmth. If he was as old as I am, he would not be as warm. There are two sides to this question; and it is the duty of an old man to counsel circumspection, for he brought us to the verge of an opening chasm which I did dread to tumble into.</p>
              <p className="p-in-sp">
                I am prepared to do at all times what a reasonable man ought to do. I went in good faith for the programme of the Committee that was before you. I announced that I would stand by you in getting the amendments to the Constitution which you laid down in your programme. I am still prepared to go for them, and now the question I will refer to is-though I know that what I shall say to you
                will be but "sounding brass or tinkling cymbal"-if you want your action to have the moral weight it ought to have, accept of counsel which can harmonize us all; accept of the proposition of the gentleman from Fauquier
                [<span className="persName">Mr. SCOTT</span>]
                . Do it, and you can have Virginia a unit. You can command our hearts, and our hands, and our all. But, sir, if you are determined to precipitate this matter, you must not expect us to be led like beeves to the slaughter. I tell you that when "grim-visaged war" is staring us in the face, the old men of the country will wake up and begin to revolve this thing in their own minds, and
                they will hold to a just accountability those who shall bring upon us this terrible disaster. We are ground to death with taxes; and it has been <PageNumber num={48} /> suggested in various portions of the Commonwealth to divide the State, for the reason that our taxes are disproportionate, and we cannot concur with you in many matters of policy. I
                lament this difference of opinion; I grieve over it. I feel as much solicitude for Virginia interests and for all past glories as any man. I am sorry to say that I fear she is fallen from her once very "high estate." Sir, whenever I think of Virginia, I make a great leap and get back half a century again. I love to dwell upon the past. They say an old man does not reflect much upon
                the events of past days; but when I think of Virginia I love to leap over the present, and get back to the ages of the great and glorious patriots of the past, who pledged all for her. I do feel, however, the deepest concern for the present occasion. This was the great palladium of civil liberty. Here was the last hope of the human family; and just as we are flushed with hope; just
                as the political horologe has ascended up and is casting his eye over that bright land of promise, like its prototype of old, it is fading gradually from his vision, until it is nearly obliterated.
              </p>
              <p className="p-in-sp">This is the way I feel. I have not strength enough to pursue this subject. I would to God that I could see this as other gentlemen see it. Would to God that I could see it as the gentleman from Richmond city [<span className="persName">Mr. RANDOLPH</span>]
                sees it. Would to God that I could bring myself to similar conclusions with those who differ from me. But I, and my people like me, cannot help believing that it is a great sacrifice of the interests of the State to take such a step as seems now contemplated by this Convention. Why follow thus in the footsteps of South Carolina? Have you not yourselves recorded your testimony that
                there was not sufficient cause for secession? Have you not stated that the mere election of a President is not enough to cause you to take this step? You cannot get anybody to believe it. They cannot believe it. The agitation and excitement, which so generally prevails, may get gentlemen to disregard their conscientious convictions, and yield to the popular current; but the day is
                soon coming when they will have to confront the great tribunal on this subject. I have sought to do my duty to man and to my God. I have not hesitated in appealing humbly to God on bended knees for his direction in this matter. I have done this often and again. I love my country; I love my fellow man. I forgive all. It is because I do believe this to be a most momentous occasion,
                that I felt called upon to bear testimony against this precipitate action.
              </p>
              <p className="p-in-sp">I invoke gentlemen to let us have the proposition of the gentleman from Fauquier [<span className="persName">Mr. SCOTT</span>]
                . Let us stand arm to arm, shoulder to <PageNumber num={49} /> shoulder, and keep step to the music of a glorious and patriotic determination that we shall banish all minor differences and rally to our country whenever necessary. I think I yield much in going thus far; let other gentlemen yield as much; but when you ask me to give up all that is dear to
                me in life-to subjugate my wife, my children, my relatives, my friends-every hope of man; when I believe that the final ruin of my country is about to be consummated, I never can consent to yield one jot or tittle more; for I am convinced that in so doing I would be giving aid and sanction to the terrible consequences which I regard as inevitable results of the policy which it is
                intended here to inaugurate.
              </p>
              <p className="p-in-sp">That God may grant you sufficient light and wisdom to do what is best, is my earnest prayer.</p>
              <div className="speaker" id="sp3692"><span className="persName">Mr. HALL</span>, of Wetzel—</div>
              <div className="stage it">[<span className="persName">Mr. SCOTT</span>, of Fauquier, having the floor]</div>
              <p className="p-in-sp">I will merely state, with the permission of the gentleman from Fauquier, that I have in my possession the proceedings of a meeting held in my county, instructing me to vote for an act of immediate and unconditional secession. The meeting was held right in the neighborhood of the gentleman from Wood [<span className="persName">Mr. JACKSON</span>] . I will not detain the House now by reading them, but will hand them in for publication. [These proceedings have already been published.]
                <span className="note" id="Note6"><span className="noteLabel">2</span>These proceedings of a meeting in Wetzel have not been found in the Enquirer.</span>
              </p>
              <div className="speaker" id="sp3693"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">I have received a communication from the Governor of the Commonwealth, which I beg leave to submit for the consideration of the Convention:</p>
              <div className="floatingText">
                <div className="floatingText_body">
                  <p>EXECUTIVE DEPARTMENT,</p>
                  <p>April 16, 1861.</p>
                  <p>Gentlemen of the Convention:</p>
                  <p>I communicate herewith a despatch received from <span className="persName">Mr. J. R</span>. Hathaway, of Norfolk, confirmatory of the information communicated by him to Mr. Harris this morning.</p>
                  <p>Respectfully, JOHN LETCHER</p>
                  <p>The following is the despatch referred to:</p>
                  <p>"NORFOLK, April 16, 1861</p>
                  <p>"To Governor Letcher:</p>
                  <p>"The information in regard to troops for the Navy Yard is reliable. It comes from Captain Sinclair, U. S. Navy, in command of the Pennsylvania. J. R. HATHAWAY."</p>
                </div>
              </div>
              <PageNumber num={50} />
              <div className="speaker" id="sp3694"><span className="persName">Mr. SCOTT</span>, of Fauquier—</div>
              <p className="p-in-sp">Mr. President : We have met in secret session for the purpose of a free and full comparison of opinions, and I trust the comparison will be made deliberately and calmly.</p>
              <p className="p-in-sp">I propose to avail myself of the occasion to explain some of the views which present themselves to my mind as worthy to be considered by this body.</p>
              <p className="p-in-sp">
                Mr. President : From my earliest manhood I have cherished strong feelings of nationality, and have habituated myself to regard our Federal Union as a national necessity-a necessity arising from both social and geographical causes, affecting, in equal degree, all parts of the country-and, until a recent time, I have never entertained a doubt of its continuance. But when the people of
                the country separated into sectional parties, and upon purely sectional issues waged party conflicts for the possession of political power, my confidence in the strength of the Union abated, and my feelings of attachment to it diminished. Nevertheless, for a time I clung to these feelings, and still cherished the hope that our nationality would survive these conflicts, and our Union
                and Constitution endure.
              </p>
              <p className="p-in-sp">
                But, Mr. President, these feelings have gone-this hope has fled. I no longer believe that the Union of our fathers and the Government founded upon it can be preserved. I have brought myself to look upon a dissolution as inevitable ; nay, more, in the presence of the events that surround us, and spread their baneful influences over the land, I look upon it as a necessity, and as
                desirable. I can never bring myself to consent that the slaveholding States shall become the subject provinces of the non-slaveholding States, to which condition their continuance in this Union, in my judgment, will reduce them.
              </p>
              <p className="p-in-sp">I am, therefore, in favor of a dissolution, and make here this deliberate declaration of that opinion. The resolutions adopted by this body in open session commit us all to the same, unless this Republican invasion can be driven back upon its authors.</p>
              <p className="p-in-sp">
                For one, I shall stand by the resolutions, and will tread no step backwards. In disregard of our formal resolutions, in the face of our solemn, deliberate and recorded opinions, at a time when we were struggling to keep the peace unbroken and save the Union, the party in power has proclaimed a war, and entered upon measures of coercion. If I could regard this policy as the policy of
                the Administration only, or as that of the political leaders who now govern the action of the party, I might still hope to save the Union and preserve the peace. The Northern masses might not be infected by the insanity <PageNumber num={51} /> which rages in their leaders, and a returning sense of patriotism, and of what is due to associates under a
                common government, might induce them to bring back their rulers to the walks of peace and the beaten tracks of the Constitution. But when I see the President and his Cabinet exercising usurped power, and, in defiance of constitutional restraints, declaring war upon seven States of the Confederacy; and when I see the people of the non-slaveholding States, of all classes and
                conditions, of all professions and pursuits, of all political parties, unite in support of them, and, making themselves parties to the war, proclaim that the price of our safety is submission to their rule, I am ready to say that we will not pay the price; and I am, therefore, ready to cut loose from them, and dissolve altogether the connection which subsists between us.
              </p>
              <p className="p-in-sp">
                Mr. President, these are grave matters for discussion; they are graver matters for action; and I do not regret that, under the commission by which we exercise the powers of this body, the decision is to rest ultimately with those who are most concerned in the determination which they are to receive. Whatever conclusion we come to, must remain unexecuted until it receives the
                approbation of the people. Our power extends no farther than to recommend what in our judgment the people ought to do; we cannot command, we cannot bind them.
              </p>
              <p className="p-in-sp">
                When our difficulties begun, I was among those who thought a settlement practicable, and when I took my seat in this body no one was more intent than myself upon propositions of adjustment. But when the "Peace Conference" measures were rejected by Congress, and when no non-slaveholding State took action towards a settlement, I began to despair of reaching any settlement by obtaining
                amendments to the Constitution through Northern agency. But other gentlemen were more hopeful and I was content to work on with them and try the experiment. I saw nothing to be lost by it; on the contrary, it seemed to me much was to be gained. As matters stood, we were divided upon the policy proper to be pursued, and the people were divided; but when the experiment failed, when the
                refusal came, every cause of division would disappear and we and our people would stand, as in the presence of these great dangers we ought to stand, one united brotherhood. But no one can think that, under the circumstances that now surround us, and in the present temper of the Northern mind, there is the remotest possibility that any favorable response will be made to any
                proposition of constitutional amendments that might emanate from this body. I am, therefore, for discarding this idea.
              </p>
              <PageNumber num={52} />
              <p className="p-in-sp">
                The policy of the Administration is aggressive, and, though it aims now at our Southern neighbors, it threatens all. The danger is common to all of the slaveholding States, and in the presence of this danger we are called upon to recommend to the people of this Commonwealth that line of policy which in our judgment is proper for them to adopt, and to suggest the measures suited to
                the exigencies in which events have placed them. It is a responsible duty, and we should approach it cautiously.
              </p>
              <p className="p-in-sp">
                I am for resisting this aggression, and will vote to advise resistance by all. But in what form shall resistance be made and when shall it begin? A proposition is made that we shall recommend to the people the adoption of an ordinance by which, immediately, this State is to be separated from all political connection with the Federal Government, and made to resume the powers of an
                independent sovereignty. With some this has for a long time been a favorite scheme, and most zealously insisted upon; but before the President's proclamation the judgment of this body and the voice of the people of the State were decidedly opposed to it. The proclamation, however, has effected a change; nevertheless diversities still exist among the members of this body, and to an
                equal extent among the people.
              </p>
              <p className="p-in-sp">
                The diversity in this body, to a great extent, is sectional, and the same will be found to be true outside of it. The State comprises a large area, and, both socially and geographically, is divided into sections, and this fact presents a consideration that ought not to be overlooked in the determination of this question. It would be deplorable indeed if the sectional controversy
                which is sundering the bonds that have connected the South with the North, should infuse its venom into the domestic relations of our own State, and poison the peace of our own firesides.
              </p>
              <p className="p-in-sp">
                The question arises whether, in the presence of these differences, it is prudent and wise to recommend a measure on which our people must of necessity manifest their strongest antagonisms. If Maryland, Kentucky, North Carolina and Tennessee would unite in a common action with us, every section of our State would agree in opinion and every cause of sectional antagonism would
                disappear. Because it is mainly owing to the apprehension that Virginia is to be torn from her natural connections with these States, or some of them, that, in some parts of the State, opposition to secession has taken such firm hold of the public mind.
              </p>
              <p className="p-in-sp">
                What, sir, is to be the effect of secession? Heretofore it has been urged by its advocates as a peaceful measure, and they have insisted <PageNumber num={53} /> most strenuously on this assumption. When we return to our constituents and tell them that we have adopted an ordinance of secession, they will naturally desire to know what will be the
                consequences of its ratification. We can tell them it will sever their connection with the Federal Government; but they will need no information on that point. No one will be so ignorant as not to know that such will be its first consequence. They will ask what will be its next. While it changes their political relations with the people of the other States, will it also change their
                peaceful relations? Will it bring on war? How are we to answer these questions? I appeal to my friend from Montgomery
                [<span className="persName">Mr. PRESTON</span>] , to my friend from Richmond city [<span className="persName">Mr. RANDOLPH</span>] , to tell me what answers are to be given.</p>
              <div className="speaker" id="sp3695"><span className="persName">Mr. RANDOLPH</span>—</div>
              <p className="p-in-sp">I would say to them, no; the war is already brought on, and it is necessary for you to defend yourselves against that war.</p>
              <div className="speaker" id="sp3696"></div>
              <p className="p-in-sp">
                <span className="persName">Mr. SCOTT</span>-They would tell me that they saw no war and felt none, and they would still desire to know whether war will come of it. It will not do for the gentleman from Richmond city to tell me that war already exists. Whatever of war there is consists merely of hostility about some of the Southern ports, to which, under present circumstances, it must
                necessarily be confined. Against this war the Southern States are in a condition to make defence; the border States shield them against internal assault. For the present, it seems to me, that this is the best attitude for all parties. The Southern States have preparation to make, and they will thus be best enabled to make it. As we now stand there is no reason to apprehend collision
                on our part with the Federal Government, or with any neighboring State. The border States have unanimously refused compliance with the President's proclamation. He has no authority of law for the measure; it proceeds upon a flagrant usurpation, and is justly repudiated. Between the President and the Southern States, the border States will stand in the attitude of armed neutrals,
                forbidding assaults through their territories; and, with full representation in Congress, they will exert a strong influence in restraining intemperate action. I repeat, it seems to me that such is the best policy for both parties-for the border and the Southern States. For the present, we will do them the most effectual service by protecting their borders, and confining hostilities
                within narrow limits where they can oppose effectual resistance. We shall have no war in the border States; standing together they will cause their neutrality to be respected. We shall have no war upon the soil of Virginia.
              </p>
              <PageNumber num={54} />
              <p className="p-in-sp">
                If the President persists in hostility against the Southern States it will remove all diversities of opinions among the people of the border States, and unite them in a common sentiment. Maryland, North Carolina and Tennessee have refused to call Conventions; the Union sentiment is prevalent among the people of those States, but it is not strong enough to stand the existence of
                hostilities in opposition to their protests. The sympathies of all are with the Southern States, and in the end we must make common cause with them. It is but a question of time and manner. While we adhere to the Union our ports will be open; we can sell wherever we can find purchasers, and buy wherever commodities are to be found. We can purchase arms and munitions of war; all of
                the border States can put themselves in military array, and when fully armed, and acting together, their determination to unite their destinies with the Southern States will be respected by the Northern States and submitted to in peace. Precipitation will defeat these ends, and separate action, without preparation will incite to aggression and lead to war. My opinion is, that
                secession now on the part of Virginia is instant war. There is no escape from it. Are we prepared for it? We have not more than thirty thousand stand of efficient arms, with a very small quantity of powder and balls; we have but few percussion caps and scarcely any military organization. We have no military head. All of these are to be provided, and may be speedily obtained if we
                remain a member of the present Union. But when we secede what will be our condition? The enemy will be in possession of Harper's Ferry, of Fortress Monroe, of Fort Calhoun and of the Gosport Navy Yard; and our harbors will be blockaded. In what manner will we obtain supplies? How will it be with the other border States? Tennessee and North Carolina may join us, but they, too, are
                without arms. Maryland cannot join us; the government will occupy her territory and take military possession of her strongholds. It must do this, or abandon the city of Washington. Kentucky and Missouri may not choose to join us. What will be our condition? The Southern States will doubtless come promptly to our assistance, I have no fear of their hesitation; they will come quickly,
                they will come in their strength and in the gallant spirit that animates their sons, and bravely mingle their blood with ours on the battle-field. They will not hesitate; the war will be their war; the cause their cause, and they will gain the advantage of keeping its ravages from their own fair fields and make it spread its havoc around the homes of their distant neighbors.
              </p>
              <PageNumber num={55} />
              <p className="p-in-sp">
                I have said that as matters now stand, I apprehend no greater extent of hostile collision than such as might be necessary for the re-capture and defence of the forts in some of the Southern ports; but let Virginia take this step of secession now, in the present inflamed state of the Northern mind, when every Southern State is so unprepared, and every Northern State is fully arming-I
                say, let Virginia take this step now, and, in place of the confined hostilities which threaten the South, we shall light up one universal flame of civil war throughout our entire borders, and our own fields will become the battle fields of opposing hosts. Sir, I am possessed by no craven fear; I mistrust not for a moment the final issue. Let war come when it may; let it carry its
                ravages where it may; let its desolations endure however long, in the end we will drive the invaders back, and deliver our homes from the intrusive presence of the insolent foe. But to wage war we must have means to equip armies; and if we would wage successful war, the means at command must be commensurate with the exigency that demands them.
              </p>
              <p className="p-in-sp">
                Our men are brave and hardy; but neither bravery nor hardihood will suffice to meet the shock of war without arms and equipments. These have to be obtained. If we secede now they must be obtained after secession, and what I desire to ask is whether it will not be better to make the purchases in advance? Whether preparation for the war had not better precede than follow its
                declaration? Whether we may not more easily purchase what we want and supply our necessities whilst our commerce is free than after our ports are closed?
              </p>
              <p className="p-in-sp">
                Sir, I agree with our friends on the other side in many particulars. I view the cause of quarrel as they view it. I am for dissolving our connexion with the Federal Union; I am for resisting the aggression of the Republican party; I am willing for the combat, and ready to meet the danger and take its consequences. We differ in this-that I am in favor of preparation, and taking time
                beforehand necessary for the preparation, believing that while Virginia and the other border States remain in the Union, the opportunity for preparation will be better.
              </p>
              <p className="p-in-sp">
                With suitable preparation for the conflict, I cannot doubt that all the Border States will act together, and that much of that division which now distracts their people will disappear; but if the conflict be precipitated in advance of preparation, no one can foresee the disaster in which it may result. We want friends and we want allies. We want to make friends and allies of the
                people of these Border <PageNumber num={56} /> States. Let us, then, consult with them, and concert the plan of action. It may prove a great error to suppose that, by our separate action, we can constrain them to follow our example. We have already adopted resolutions proposing a conference of the Border States, and these have been responded to by three
                of the States. The proposition of the conference concedes the common interests of all of these States in the subject under consideration ; and I submit it to the judgment of this body whether it is becoming in us to abandon suddenly our own deliberate proposals, and to assume to ourselves the sole responsibility touching a measure so vital to others whose co-operation we desire?
              </p>
              <p className="p-in-sp">
                My position on the border, possibly, makes me more sensitive on this subject than those whose interior position renders them safe from hostile incursions. My own constituents and their neighbors have a deep and immediate interest in the question. If we begin the war before we are prepared for operation, the enemy will be the assailants, and we will feel at our door-sills the shock of
                battle and the ruin of war; but if we prepare beforehand, this shock and ruin may be driven to the homes of those who provoke them.
              </p>
              <p className="p-in-sp">
                Mr. President, it is manifest that this body is greatly divided in opinion on this question, insomuch that at the present moment it is uncertain on which side the majority will be found. I have no expectation that the preponderance will be great on either side, and to press this proposition through by a naked majority, and to go before the country with that evidence of our own
                division will destroy the moral effect of the act, and, what is worse, incite to divisions among our constituents when they come to vote upon it. I fear it will be impossible to unite them upon it. If the views of some of our friends on the other side had obtained, and this Convention had, at an earlier day, before the President's proclamation, adopted an ordinance of secession, in
                my judgment it would have led to a division of the State, whereby the Blue Ridge of mountains might have become the line of division; and, when this ordinance is adopted, in the present condition of our affairs, the danger of discord is most imminent. For myself, I would avoid discord. The policy that leads to it will prove disastrous, and fatal consequences will ensue to the
                commonwealth. If unanimity, or something approaching to it, could be obtained for immediate secession in this Convention, I would readily vote for the ordinance notwithstanding the objections that have been stated, or if I thought the people of the State were united as to it so as to make the determination at the polls decisive, I would feel far less repugnance to the expressions.
                But I fear divisions and their fatal consequences.
              </p>
              <PageNumber num={57} />
              <p className="p-in-sp">
                Those most opposed to the measure of secession-the members from the eastern<span className="note" id="Note7"><span className="noteLabel">3</span>The speaker must have meant to say "western."</span> limits of the State-are content to abide by the determination of all the border States in council. If these States together declare for secession, they and their constituents will acquiesce, and
                they will vote now for such consultation. Why may not all agree to convoke these States in council? The time required cannot be long, and it need not be lost. We can improve the time by purchasing supplies of those arms and munitions that we and they stand so much in need. In this way we may obtain union at home, we may satisfy those who otherwise would be dissatisfied, and move and
                more surely and safely to that end which the most ardent secessionist has nearest at heart. I think it a straight road to secession, and I am frank to avow it. I know not what others expect from a border State Conference, but for myself I cannot believe that intelligent and patriotic representatives of the border slave States would be forced to consent to subordinate the great
                interests of their section to the overbearing dominant majority of the North. I should expect from such a conference calmness and deliberation, forecast and preparation, for in my judgment the occasion requires these.
              </p>
              <p className="p-in-sp">
                Between the two policies, this Convention is divided-some are for instant secession and the adoption of an ordinance, others are opposed to secession, but will acquiesce in the determination of a border State Conference. Now our action in the premises is not to be final. If the ordinance of secession be adopted it must be submitted to the ratification of the popular vote, and then
                this body must reassemble to carry the mandate of the people into effect.
              </p>
              <p className="p-in-sp">
                It would be just as well, therefore, to submit the question of secession to be voted upon as the ordinance itself. Now, I am most anxious to avoid diversions among ourselves. I am most desirous of seeing this Convention agree in a body upon some plan of action; I have the strongest repugnance to the exhibition of ourselves in divided parties; and to harmonize this conflict and
                promote a spirit of union and mutual concession, let us agree to submit both policies to the people in a way that they may select without embarrassment between them. Those who prefer the policy of immediate secession can vote their instruction to the Convention, while those who prefer the policy of consultation and concert of action with the other border States can vote their
                instruction in like manner. I can see no just objection to this plan; it deals fairly by the people, and presents the question for determination in a manner to be understood by all. If a majority vote for the ordinance of secession, the Convention will reassemble <PageNumber num={58} /> immediately and carry it into effect-or if the majority be for
                consultation the steps necessary to attain that end can be taken. In no way can anything be lost by it to the cause of secession.
              </p>
              <p className="p-in-sp">I have prepared a paper containing the scheme to which I have adverted, and propose it as a substitute for the proposition of the gentleman from Montgomery [<span className="persName">Mr. PRESTON</span>] .</p>
              <p className="p-in-sp">Regretting that I have consumed so much of the time of the Convention, I submit the substitute, and ask that it be read by the Clerk. The Clerk read the substitute as follows:</p>
              <p className="p-in-sp">
                Whereas, at the election held Pursuant to the act passed the 14th January, 1861, providing for the choice of members of this Convention, it was determined by the people of this Commonwealth that any action of this Convention dissolving the connection of this State with the Federal Union should be submitted to them for ratification or rejection; and, whereas, differences exist as to
                the action proper to be recommended for the adoption of the people of this State in the present posture of our Federal relations-it being the opinion of some that this State ought, at once, by separate and immediate secession, to revoke the powers granted by her to the Federal Government, and the opinion of others that before any final measure shall be taken in the premises, a
                consultation for the purpose of concerted action should be had among the eight slaveholding States yet remaining in the Union : and, whereas, certain resolutions adopted by this Convention, among other things, recommend a consultation for that purpose, to be held at Frankfort, in the State of Kentucky, on the last Monday in May next, to which the said State of Kentucky and the said
                States of Arkansas and Missouri have signified their intention to send delegates: and whereas, it is desirable to ascertain the preferences of the people of this State between the two modes of proceeding: It is, therefore, ordered by this Convention, that at the general election to be held for this Commonwealth, on the 4th Thursday in May next, the commissioners of election, at the
                several places of voting in their several counties and cities, shall open a separate poll to take the sense of the qualified voters on the question aforesaid.
              </p>
              <p className="p-in-sp">
                2. The poll book shall be headed, "Upon the question of co-operation or separate secession," and shall contain two columns, one headed "For co-operation among the slaveholding States yet remaining in the Union," and the other "For separate and immediate secession," and the names of those who vote for co-operation shall be written under the former heading, and the names of those who
                vote for separate and immediate secession shall be written under the latter heading.
              </p>
              <PageNumber num={59} />
              <p className="p-in-sp">
                3. The said commissioners shall make return of the number of persons voting for each proposition at the time and in the manner provided by law in the case of other elections, and shall forthwith deliver the returns to the clerks of their respective counties and corporations, and it shall be the duty of such clerks respectively to transmit immediately to the Governor of the
                Commonwealth copies of the said returns so delivered to them respectively.
              </p>
              <p className="p-in-sp">
                4. The Governor shall make proclamation of the result, and if it appear that a majority of all the legal votes cast upon the said question be in favor of separate and immediate secession, it shall be his duty to notify the members of this Convention, within thirty days thereafter, to re-assemble at the Capitol, in the city of Richmond, on a day specified, and such vote shall be taken
                to be instructions to this Convention to pass an ordinance of immediate secession dissolving the connection existing between the State of Virginia and the Federal Union, known as "the United States of America."
              </p>
              <p className="p-in-sp">
                5. The Secretary of the Commonwealth shall cause to be sent to the clerks of each county and corporation as many copies of this order as there are precincts therein, using special messengers for that purpose, when necessary; and it shall be the duty of the said clerks to deliver the same to the sheriffs for distribution, whose duty it shall be forthwith to post the said copies at
                some public places in each election district.
              </p>
              <p className="p-in-sp">6. The expenses incurred in providing poll books and in procuring writers to enter the names of the voters therein, shall be defrayed as in the case of election of members of the General Assembly.</p>
              <div className="speaker" id="sp3697"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I do not rise to debate this question, sir. I am too sick to do so. I merely rise to move the previous question.</p>
              <p>The motion was subsequently withdrawn.</p>
              <div className="speaker" id="sp3698"><span className="persName">Mr. GEO. W. RANDOLPH</span>—</div>
              <p className="p-in-sp">I desire to say a few words in regard to the military preparations of the State. I feel it a duty to say something on this subject, inasmuch as I was a member of the commission appointed by the State to purchase arms for her defence.</p>
              <p className="p-in-sp">I am much surprised to hear the gentleman from Fauquier [<span className="persName">Mr. SCOTT</span>] say that this State had made no preparations for war and was in no condition to enter upon a campaign.</p>
              <p className="p-in-sp">
                My own belief is that this State is far better prepared than any Northern State in the Union. She can arm, at least, 25,000 men with arms now in her arsenals and in the hands of her troops, and she has sufficient arms-the worst of them equal to the arms used by the <PageNumber num={60} /> United States army in Mexico-for upwards of 2,000 infantry and not
                less than 3,000 cavalry.
              </p>
              <p className="p-in-sp">
                She has powder for at least two campaigns for a train of artillery. Her supply of musket powder is by no means sufficient; but there will be a magazine in this city conducted on private account, which will manufacture a large supply of this kind of powder, and the State can have what she wants. I have been informed by the agent for the Dupont Mills, that at any time powder is needed,
                they can supply us to an unlimited extent. We would have had a much larger supply on hand were it not for a failure of the appropriation necessary for that and kindred purposes.
              </p>
              <p className="p-in-sp">
                The arms that our troops have, are precisely the arms that will be brought against us. They were purchased out of the United States Armory, and are not at all inferior to those which the 75,000 men called for by the President will have, if they come here; and, so far as drill is concerned, the State has several thousand men in drill since the Harper's Ferry affair, who can challenge
                comparison with any similar number, North or South. I believe there is no State in the country that can bring such an efficiently drilled force in the field to-day as Virginia can. I know not whether the Pennsylvanians have any cavalry. Certainly their preparations for it were most meagre. We have about 90 troops of cavalry of the finest material on the continent of America. At a
                late encampment of cavalry in this city, I heard Col. Hardee say that he never had seen a finer body of men. Unquestionably we need more preparation; but we have not been idle. We have been pushing our preparations as far as the appropriations would permit. The recent appropriations made by the last General Assembly will now be expended, and by the time the conflict comes, I have no
                fear that our State will be found unprepared. I do most earnestly hope and trust that, under the flimsy idea of making preparations, this Convention will not determine to keep the State under an obligation not to make these preparations; that it will not keep her in a position when to make these preparations is treason, which would bring upon our heads the arms of the Federal
                authorities.
              </p>
              <p className="p-in-sp">
                Do not delude yourselves. Do you believe that this disguise cannot be seen through by the Administration at Washington? Sir, it is treason, in its most odious form, without the bold, manly design which would hurl defiance at the head of that Government. It has, to my eyes, the appearance of hyprocrisy-pretending that you are not going out, in order to avoid the shock of arms, and all
                the time pushing your preparations to go out. Let the world believe that you <PageNumber num={61} /> are going to engage in this warfare. This will be more manly, while it will involve no worse consequences than would result from the policy indicated by the gentleman from Fauquier. That guise will be easily seen through by the authorities at Washington,
                and their preparations will be just as extensive as though we took a bold and open stand. Let us, then, come boldly out and proclaim our purpose to unite with our Southern friends, instead of going into consultation with States, which the gentleman tells us are not prepared to aid us in doing what is necessary for our safety.
              </p>
              <p className="p-in-sp">Why did not prudence govern you when you were voting these resolutions through? [Referring, the reporter believes, to the anti-coercion resolutions.]
                How came it that you did not use prudence in making the declarations, which you seem now to have reversed-"be bold in your declarations, but prudent in action"? How came it that this is only a local war? If between the Southern border States and the State of Virginia there is a final consultation, how can you determine-how can you say that if Virginia goes South, the fight will be
                confined to the South? I should like to hear the grounds for these assumptions, and I trust that the gentleman will furnish them. The people will naturally ask us, what do you want to do? We sent you there to test the question of peace or war, and not to refer back to us the question of peace or war. We want the Convention to say what we ought to do. We have authorized that
                Convention to say that we will not permit coercion, and the Convention has full power to use the necessary means to that end.
              </p>
              <p className="p-in-sp">
                The gentleman declares that he is in favor of secession; and how does he propose to get out? By seceding? No; but by getting the States, that everybody knows will not secede, to join with Virginia in a consultation which is destined to end in nothing but mischief, by delaying action and allowing the enemy an opportunity of fastening his fangs tighter upon us. The gentleman tells us
                to defer secession until Maryland, North Carolina, Tennessee and Kentucky tell us they are ready to go out, and all this time we are getting weaker-in my judgment weaker, morally and physically, in the eyes of all honorable men-weaker in the eyes of our own citizens. Unless you go out forthwith, you will have to contribute your blood and treasure to subjugate the Confederate States.
                If we have to fight, let us fight in behalf of Southern rights, and not to sustain the enemies of our section. In my humble judgment, the Ordinance of Secession, so far from being a war measure, is the most pacific course that could be adopted. The enemy is coming down upon us with his entire force, <PageNumber num={62} /> and, in my opinion, no man ever
                yet escaped danger by turning his back upon it. Face it like a man. Call your sons to the field, give them the best arms you have got, and put your trust in your cause and in the God of battles.
              </p>
              <p className="p-in-sp">
                I do not pretend to say that secession will save your liberties and existence; but if you do fail, you leave a page in history that your descendants need not be ashamed of. My humble belief is, that a bold, manly, decided course will operate a moral influence upon the North that will bring them to a stand until mediation comes between. But if we give way, the storm will burst upon us
                and destroy us.
              </p>
              <p className="p-in-sp">
                Sir, when General Scott arrives here to morrow to offer you his counsel and his sword, are you to tell him to go back and wait until we consult the border States, and say whether we will fight or not? Is that the reception that you are going to give to the first General of the age? Are you going to tell him that his services are not needed; that you are not ready to fight; to go back
                and wait till you send for him? I, for one, never will vote for any such proposition. If I stand alone, I mean to record my vote this night, I hope, for immediate secession; and I mean to follow it up with a resolution, calling upon the Governor of this Commonwealth to organize the whole military force of the State, in order to repel invasion, defend our soil, and maintain our honor,
                until assistance can come from some other quarter.
              </p>
              <div className="speaker" id="sp3699"><span className="persName">Mr. JOHN GOODE, JR.</span>, of Bedford—</div>
              <p className="p-in-sp">I do not rise to make a speech. I rise to do what I have never done in a deliberative body before. It seems to me that whatever action is to be taken by this body, ought to be taken now. Argument has been exhausted, and the time has come for action, if action is to be had. I now move the previous question, from a high solemn sense of duty.</p>
              <p>The call was sustained.</p>
              <p>Ten minutes being allowed each speaker under the rule, to show cause why the main question should not be put-</p>
              <div className="speaker" id="sp3700"><span className="persName">Mr. WAITMAN T. WILLEY</span>, of Monongalia, said—</div>
              <p className="p-in-sp">
                Mr. President: I had no expectation, and of course have no right, now to enter upon the discussion of the subject; but I wish to record my protest against the application of this rule at this time. I have seen intimations that were not to be mistaken, as to what was to be the result of our deliberations to-day. I have contemplated, with a melancholy and with a regret, which never
                pressed my heart before, the attitude in which the proud old Commonwealth of Virginia now stands; and, though these feelings have recurred frequently during the deliberations <PageNumber num={63} /> of this Convention to my mind, they come up with peculiar force this day.
              </p>
              <p className="p-in-sp">
                I had, sometimes, in the indulgence of a patriotic hope, wished that I could have lived a century hence, to see the advancement of our civilization, the power and greatness of our country, and the value of a great name. Instead of that, I live to see the hour of the commencement of our disintegration and down fall. I have lived to see the hour when the proud flag, under which we have
                lived in safety and honor for nearly a century, and which waved victoriously over many a battle field, is to be trailed in the dust, and I have lived to see the hour when Virginia, who gave the first impulse to the ball of the revolution, is about to put her foot on that flag, on the very soil that gave it glorious birth.
              </p>
              <p className="p-in-sp">
                Sir, I will not be led away by my feelings. I rose, as I have mentioned just now, to protest against this measure, in the name of my constituents; in the name of the people of Virginia; in the name of liberty, and in the name of God. If you were to ordain this day that my constituents and the people of North-western Virginia should be delivered over to death and destruction, in so
                many words, you could not express the meaning of what will result from this Ordinance of Secession, if it passes.
              </p>
              <p className="p-in-sp">
                Sir, I could give what I have-I would willingly give all my little estate-as God is my judge, standing in the capitol of Virginia, I would leave my farm, as a small sacrifice, to save my country, if it was acceptable. But I have a wife and children; I represent a constituency that have wives and children; I represent an old, patriotic father, born amid the thunders of the Revolution,
                whom I went out of my way to see as I came down here, with the frosts of ninety-five winters gathered upon his revered head; and the last words he told me when he gave me his blessings were: "My son, save this Union, or never let my eyes rest upon you again."
              </p>
              <p className="p-in-sp">
                You cannot make any line through any of your counties throughout Western Virginia, that will more effectually dissever the people of Virginia lying along our North western border from those on the other side of the line, than will this ordinance of secession. These 450 miles of border State line will interpose a barrier between fathers and sons, fathers-in-law and sons-in-law, that
                no effort of ours can overcome.
              </p>
              <div className="speaker" id="sp3701"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">You will all associate as before; there will be nothing to prevent it.</p>
              <div className="speaker" id="sp3702"><span className="persName">Mr. WILLEY</span>—</div>
              <p className="p-in-sp">
                No, sir. Secession is war, and the man on this side <PageNumber num={64} /> of the line that does not rally to the call of his State, is a traitor; and if I meet a man from the other side of the line with weapon in hand, and fail to shoot him, in disregard of the order of a superior officer, I will be hung as a traitor. So help me God, I will be hung as
                a traitor, if this omission will involve such a penalty.
              </p>
              <p className="p-in-sp">I protest against this measure. I do it in the name and for the lives and property of North-western Virginia.</p>
              <div className="speaker" id="sp3703"><span className="persName">Mr. HALL</span>—</div>
              <p className="p-in-sp">Except Wetzel.</p>
              <div className="speaker" id="sp3704"><span className="persName">Mr. WILLEY</span>—</div>
              <p className="p-in-sp">Oh, except Wetzel! I don't mean to doubt the courage of Wetzel.</p>
              <p className="p-in-sp">
                Why don't you gentlemen wait until we get the aid that we may get by co-operation? We are certainly not yet out of the Union. Why can't we wait until we consult with our border sister States? We will not have to wait much longer than we will if we wait for the ratification of your ordinance. And here allow me to say, that I trust my fellow members upon this floor will not suppose me
                capable of urging this policy from any other than the purest and best motives, dictated solely by a zeal for the interests of my State. I say, in all sincerity, from the depths of the belief of my heart, that if this ordinance of secession goes out naked and alone, it will either be voted down by the people, or it will dissolve this State. It will divide this State; it will destroy
                the loyalty of the best friends that ever your slaveholders had; it will destroy the sons of sires who shed their blood in your defence, and whose bones now lie along your sea-shore.
              </p>
              <p className="p-in-sp">I protest again against this hasty measure. I ask you, in the name of this Convention, and of the large portion of this great Commonwealth which I in part represent, that you will give us the privilege which would be given if the proposition of the gentleman shall pass.</p>
              <p className="p-in-sp">I thank the Convention, and you, Mr. President, for allowing me that latitude which I have taken under the peculiar restriction imposed by the previous question.</p>
              <div className="speaker" id="sp3705"><span className="persName">Mr. GEORGE W. BERLIN</span>, of Upshur—</div>
              <p className="p-in-sp">
                Mr. President, I would be remiss to my duty if I were to remain silent at this time. I am very reluctant, nevertheless, to waste the time of this Convention with any remarks on this subject, although I deem it necessary to discuss it very briefly. I cannot submit, in view of the ruin that is to be drawn down upon us, and the policy that is to be inaugurated, to be forced into
                silence. Occupying the position that I do, representing a constituency peculiarly situated as they are, I cannot remain silent, and shall therefore avail myself of the ten minutes allowed me under the rule, to express what I feel on this subject, and define my position.
              </p>
              <PageNumber num={65} />
              <p className="p-in-sp">
                What is our situation? We are situated beyond the mountains, isolated from the rest of the State by these almost impassable mountain barriers-cut off from all connection with our Eastern brethren or the South. The policy of this State always has been to withhold from us all railroad facilities with the Eastern portion of the State, and we are now left with out the means of
                transporting troops or munitions of war to aid us in the coming struggle. While thus isolated from the rest of the State, we have an exposed border of 450 miles, stretching along the lines of two of the most powerful free States in the Union. In addition to this disadvantage, we are left without the means of defence. We are destitute of arms and munitions of war of every description.
                There are scarcely half a dozen guns west of the Alleghanies, while we are equally destitute of magazines. We are as perfectly in the power of our enemies, in the event of war, as any people under God's Heaven ever were; and, in view of this condition, you seem determined to precipitate us into war, which must inevitably result in our ruin, if some timely aid, not now visible, is not
                afforded us. But we may be attacked within the next twenty-four hours; and, situated as we are, on the borders of the powerful States of Ohio and Pennsylvania, we would be literally annihilated before you could provide us the necessary aid through the imperfect and difficult channels of communication by which relief could be sent.
              </p>
              <p className="p-in-sp">
                Would it not be wise, under these circumstances, to hesitate, and give sufficient time to supply us at least with the arms and munitions that are necessary to protect us from invasion? Situated as we are, if you take the action now which you evidently contemplate, you will render us powerless; you will force us to occupy a position of neutrality; you will place us in an attitude of
                revolution and resistance to the Commonwealth of Virginia herself.
              </p>
              <p className="p-in-sp">
                Now, this is the position that you will force us to occupy; and while we are forced to take this stand, from want of the necessary means of defence, you are fully prepared with all the means to carry on a war. You have railroad communications by which you can concentrate all your arms and munitions of war, and troops, at any given point, in a brief space of time. You possess these
                advantages over us, while you have the mountain barriers to protect you on the West, from any attack from that quarter. So that you may feel comparatively safe and contented.
              </p>
              <p className="p-in-sp">
                As it was remarked, the people of Western Virginia are the descendants of those who fought with your ancestors in times past. They are to-day a warlike people, and it is only necessary to say that they <PageNumber num={66} /> are mountaineers, to establish that fact, because no race of mountaineers have ever been conquered or enslaved when they have
                resisted.
              </p>
              <p className="p-in-sp">
                What is your condition now? You aim to precipitate the State at once into a war with nineteen free States, which can bring into the field legions thoroughly drilled and thoroughly armed-not by a State Government, but by the General Government. Under these circumstances, if you force this war it must be at a great disadvantage and considerable odds. And then, when you reflect that you
                have a debt of $40,000,000 upon your shoulders, you must necessarily see that if you precipitate this war, bankruptcy and repudiation must be the immediate consequence. You will destroy your property and your credit; you will block up every avenue of trade and commerce, and you will drive your very slaves from the Commonwealth. I would ask my Eastern friends whether we are now in a
                condition to resist an invading army of 75,000 men? I would ask them whether, in the face of an invading army of this magnitude, they would permit their slaves to remain here? I hardly think they would; and they must, then, necessarily see that the result of this conflict will be to drive this property from the Commonwealth.
              </p>
              <p className="p-in-sp">But I cannot discuss this question in the brief space of ten minutes. I can only say, in addition to what I have already stated, that I must, in behalf of my constituents, protest against this ordinance of secession.</p>
              <div className="speaker" id="sp3706"><span className="persName">Mr. FRANKLIN P. TURNER</span>, of Jackson and Roane—</div>
              <p className="p-in-sp">
                I merely rise, Mr. President, for the purpose of saying that there is at least one portion of North-Western Virginia which do not desire that the protest of the gentleman from Monongalia [Mr. WILLEY] shall apply to.<span className="note" id="Note8"><span className="noteLabel">4</span>There seems to be an omission, or a change of construction here.</span> I refer to that portion of North
                Western Virginia which I have the honor to represent upon this floor. I have had recent opportunities of personal communication with them, and found that, instead of their being behind me upon the question now agitating the country, if anything they were in advance of me, and I was rather following their lead than otherwise.
              </p>
              <p className="p-in-sp">
                Before I returned there, and without one particle of communication, between me and them, I found that they had called meetings throughout the county, not for the purpose of instructing me, but to express to me their entire approval of the course I pursued, and to ask me to do anything in this Convention which would avoid delay, and, in furtherance of that policy, to vote at the
                earliest moment for an ordinance dissolving our connection with the Federal Government. Under these circumstances, I regret that it may not be in my power <PageNumber num={67} /> to record my vote here to night. It is known that I paired off with a gentleman who has left here, and I therefore may not be able to record my vote. But, I desire to say that
                if I had the opportunity, I would find a substitute and record my vote for the proposition of the gentleman from Montgomery
                [<span className="persName">Mr. PRESTON</span>]
                . I desire to make this statement now. I would have made it yesterday on presenting the proceedings of the meeting of my constituents, in order that my position on this question should be known, but it was not the appropriate time.<span className="note" id="Note9"
                ><span className="noteLabel">5</span>Presumably Mr. Turner is referring to reports of meetings printed in the Enquirer on April 30. See note 2 on Proceedings of April 15.</span
                >
              </p>
              <p className="p-in-sp">
                I felt, sir, that on leaving here a short time ago, it was necessary for me, in order that my vote should be known, to leave the slight influence of my presence behind me, and in order to do so, I requested of the gentleman with whom I paired off to make known my position upon any important vote that may be taken during my absence. That gentleman did so, and now I feel compelled to
                reciprocate that favor.
              </p>
              <p className="p-in-sp">I repeat again, that the protest of the gentleman from Monongalia [<span className="persName">Mr. WILLEY</span>] , does not apply to the whole population of the North-West; and that among my constituency there are to be found as true and loyal heroes as any upon the soil of Virginia.</p>
              <p className="p-in-sp">Sir, no portion of this Commonwealth will enter its protest more solemnly against any division of this Commonwealth than that which I have the honor to represent. Not only that but the people of the whole of North-Western Virginia will, I have great confidence, be opposed to any policy looking to a division of this State.</p>
              <div className="speaker" id="sp3707"><span className="persName">Mr. BALDWIN</span>, of Augusta—</div>
              <p className="p-in-sp">I move that this House do adjourn, and upon that I call the yeas and nays.</p>
              <p>The motion was subsequently withdrawn.</p>
              <div className="speaker" id="sp3708"><span className="persName">Mr. ALGERNON S. GRAY</span>, of Rockingham—</div>
              <p className="p-in-sp">In common with my colleagues from Rockingham [Messrs. <span className="persName">COFFMAN</span> and <span className="persName">LEWIS</span>] , I have cast a silent vote on all occasions, since we came to this Convention. I prefer now to speak in this solemn hour, before this Convention, of my people.</p>
              <p className="p-in-sp">On the 25th day of January, 1788, our ancestors met here and ratified the Constitution of the United States; and for three-quarters of a century a history has been written of prosperity and glory unparalleled, in grandeur and magnificence, in human history.</p>
              <p className="p-in-sp">
                This 16th day of April, we, the representatives of the people, meet here again. What shall be the record of this hour, which we shall present to those who are to come after us? It is well for gentlemen all around me that, in their conscience, they can feel that they come to the<PageNumber num={68} /> work of this solemn hour with the same love of
                country, and the same love of liberty as our fathers. Woe to the man who does not feel it.
              </p>
              <p className="p-in-sp">
                Sir, I had desired not to speak now of the subject before us; but of my people and my own duty. Oh! with what earnestness I have wished this day that I was with them face to face, to hear from their own voices what they would have me do in this hour of peril and destiny. I would to God I could draw around me here, in your presence, that loyal, patriotic, just and upright people who
                sent me here with my colleagues, upon the swelling waves of Union feeling. But the times have changed. We came here under circumstances of excitement; but we have added to it here now, that which convulses our hearts. I am free to confess that my own Virginia heart rises to repel- the invasion that is spoken of in the distance.
              </p>
              <p className="p-in-sp">
                Sir, in this hour I think not of my own personal feelings, but look with an earnestness that I never before felt towards the people among whom I was born and where I dwell. I have, in the isolation of my chamber, in the dread hours of the long night, ardently yearned to know what they would have me to do. I have in this isolation reviewed silently their past lives, my conversations
                with them, their meetings with me at the cross-roads, in the school and on the hustings, and now I feel that their voices come over the mountains to me to-night, to direct, encourage and animate me; as did the voices of their fathers direct Washington at Yorktown. It is thus that I feel. That was the condition in which I came here, and my heart now is swelling for Virginia, the more
                so as she seems harassed and buffeted by adversities. When I see gentlemen around me who, in the recent ardor of their patriotic feelings, believe that this is the acceptable hour for Virginia to leap out of the Union of our fathers, I would ask them to believe that I, too, sympathize with that feeling, and that I believe that a day will yet come when Virginia shall hear the voice of
                her people deciding what her destiny shall be. There will be no division after that decision shall be made; but, until then, I am slow to believe that anything like unanimity will prevail.
              </p>
              <p className="p-in-sp">
                Under all these circumstances I believe that this night, approaching the dreadful hour, it is my duty to cast the vote of my people for the plan by which the greatest hope is left of re-building the Republic that they have loved so well. It may be that it is gone forever. It may be that a new season and a new creation may be opened to them. Whatever may be the future which the action
                of Virginia to day may bring about, that people will be found, when <PageNumber num={69} /> necessary, standing shoulder to shoulder with the people of the tidewater supporting the integrity of all her soil.
              </p>
              <p className="p-in-sp">
                What then must I do? I believe in my heart, that, standing here to-night and knowing the will of my people, which I trust I do, and feeling all the responsibilities of a representative heavily bearing on my heart, I believe I would not betray them, if I knew their will, to save my life. It is therefore in that spirit and under these circumstances, and looking hopefully to the future,
                fearing only my God, and deeply sympathizing with the proud and loyal people who I represent, that I shall cast my vote here to-night for the proposition of the gentleman from Fauquier
                [<span className="persName">Mr. SCOTT</span>] .</p>
              <p className="p-in-sp">I thank you for the kindness with which I have been listened to in an hour that my heart has been oppressed with sadness.</p>
              <div className="speaker" id="sp3709"><span className="persName">Mr. SAMUEL A. COFFMAN</span>, of Rockingham—</div>
              <p className="p-in-sp">Mr. President, I had supposed that I could have said nothing on this subject. I had trusted that I might remain a mute spectator of the acts that transpired around us; but, sir, circumstances that point directly to myself; the position that my colleague has just taken with regard to the vote that he shall cast for the substitute of the gentleman from Fauquier [<span className="persName">Mr. SCOTT</span>] , is one that I feel impelled now openly and avowedly, to cast my vote against.</p>
              <p className="p-in-sp">
                When I announced myself a candidate for this Convention, before my people, I declared then that I believed in the right of secession, I believed it that day, and I have no cause to change my opinion. Sir, I further stated to the people whom I have the honor, in part, to represent, that I would make no avowal of my policy further than that I would exhaust all honorable means to
                perpetuate this Union in its integrity. I stated to them, also, that the only pledge I would then make was, that in no contingency, under no circumstances, if I could prevent it, should Virginia be degraded.
              </p>
              <p className="p-in-sp">Mr. President, I am not in the habit of addressing public assemblies. I am a plain man; but I think that when a plain proposition is presented to me, I can see it in its proper bearing, and comprehend the nature and character of acts whose responsibility is forced upon us.</p>
              <p className="p-in-sp">
                I have made up my mind, days ago, that the only salvation for Virginia was secession. I believe, sir, that she can no longer remain in this Union with honor to herself, and I will therefore cast my vote for an ordinance of secession. I have the gratifying intelligence this evening from my own county that a meeting was held in the town of Harrisonburg last Monday at which the
                expression of the people of the county was overwhelming in favor of immediate secession. If I had any <PageNumber num={70} /> misgivings previous to that time, they have all been removed; and I stand here believing that I am representing my constituents when I cast that vote for an ordinance of secession; and believing that, I shall cast that vote
                cheerfully, freely and cordially, because the wrongs heaped upon Virginia demand, in my opinion, some such remedy as secession alone can now afford. My very heart burns with indignation at the recollection of the injuries heaped upon us. I am unwilling to bear them any longer. I would be willing to cast my vote here tonight for an ordinance of secession if every man, woman and child
                in the county of Rockingham were opposed to it, and if they did not deem that course wise, I would resign my position.
              </p>
              <p className="p-in-sp">I now renew the motion to adjourn.</p>
              <div className="speaker" id="sp3710"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">We have three gentlemen here who have been brought out of their sick beds to vote, and I trust the Convention will not adjourn until a vote is taken. I call for the yeas and nays on the motion to adjourn.</p>
              <p>The call was sustained, and the question being taken, resulted-yeas 65, nays 78, as follows:</p>
              <p>
                YEAS-Messrs. Armstrong, Aston, Baldwin, Baylor, Berlin, Blow, Jr., Boggess, Brent, Brown, Burdett, Burley, Byrne, Campbell, Carter, C. B. Conrad, R. Y. Conrad, Couch, Critcher, Custis, Dent, Deskins, Dulany, Early, French, Fugate, Gillespie, Gray, Ephraim B. Hall, Hammond, Haymond, Hoge, Holladay, Hubbard, Hughes, Hull, Jackson, Peter C. Johnston, Lewis, McComas, McGrew, J. Marshall,
                Masters, Moore, Nelson, Osburn, Patrick, Pendleton, Porter, Price, Pugh, Rives, Robert E. Scott, William C. Scott, Sharp, Sitlington, Spurlock, Alexander H. H. Stuart, Chapman J. Stuart, Summers, Tarr, White, Wickham, Willey, Wilson, <span className="persName">Mr. PRESIDENT</span>-65.
              </p>
              <p>
                NAYS-Messrs. Ambler, Alfred M. Barbour, James Barbour, Blakey, Boisseau, Borst, Bouldin, Boyd, Branch, Bruce, Cabell, Caperton, Cecil, Chambliss, Chapman, Clemens, Coffman, Conn, James H. Cox, Richard H. Cox, Dorman, Echols, Fisher, Flournoy, Forbes, Garland, Graham, Gravely, Gregory, Jr., Goggin, John Goode, Jr., Thomas F. Goode, Hale, Cyrus Hall, L. S. Hall, Harvie, Holcombe,
                Hunton, Isbell, Marmaduke Johnson, Kent, Kindred, Lawson, Leake, Macfarland, Marye, Sr., Chas. K. Mallory, James B. Mallory, Miller, Moffett, Montague, Morris, Morton, Orrick, Parks, Preston, Randolph, Richardson, Seawell, Sheffey, Slaughter, Southall, Speed, Staples, Strange, Sutherlin, Tayloe, Thornton, Tredway, Robert H. Turner, Franklin P. Turner, Tyler, Waller, Whitfield,
                Williams, Wise, Woods, Wysor-78.
              </p>
              <p>So the Convention refused to adjourn.</p>
              <div className="speaker" id="sp3711"><span className="persName">Mr. JUBAL A. EARLY</span>, of Franklin—</div>
              <p className="p-in-sp">I hope I may be permitted to say a few words.</p>
              <p className="p-in-sp">I have sat in my seat all day, and imagined that I could see a ball of flame hanging over this body. Without meaning to reflect upon <PageNumber num={71} /> the motives and the conduct of gentlemen, I have felt as if a great crime was about being perpetrated against the cause of liberty and civilization.</p>
              <p className="p-in-sp">
                During the progress of this Convention, we have frequently referred to the example of our fathers in the Revolution. They took no precipitate course of action. They protested and remonstrated for years. They had their armies in the field twelve months before they decided upon the final act of separation from the British Government. The State of Virginia herself never adopted the
                Declaration of Independence until after our armies had been in the field for twelve months. She instructed her representatives to propose in the Congress of the United States a Declaration of Independence.
              </p>
              <p className="p-in-sp">Sir, I see no reason why we should act more precipitately than those men whose true devotion to country, and whose patriotism is a common theme among all of us.</p>
              <p className="p-in-sp">I cannot permit the occasion to pass, when an ordinance of secession has passed this body, without expressing my feelings upon it in addition to casting my vote against it.</p>
              <p className="p-in-sp">It may be that such a vote, which I feel bound to give, shall draw upon me some censure. I trust I shall have manhood and firmness enough to endure, because the course I propose to pursue I feel to be in the interest of my country, in the interest of my State, and in the interest of the cause of liberty itself.</p>
              <p className="p-in-sp">
                What must be the result, the inevitable result of this proceeding? War, sir-such a war as this country has never seen, or, until recently, has never dreamed of. I should like to know, if we are engaged in war, what are the means of transportation of arms from this portion of the State to North-western Virginia? How will men and arms come from North-Western Virginia to this portion of
                the Commonwealth? As I understand, there are but two ways of transportation from Eastern to North-western Virginia. One way is through the Federal Capital, and thence by way of the Relay House near Baltimore, over the Baltimore and Ohio Railroad. A gentleman on this floor has told you that North-western Virginia is almost entirely destitute of arms. It may be necessary to send them;
                it may be necessary for us to call upon them for men to defend us in the West, and how can we do so with propriety without furnishing them the means of defence?
              </p>
              <p className="p-in-sp">
                Am I going by my vote to place the country in such a position as this? I certainly am not. I have opinions of my own upon this subject, and I mean to act upon them. As regards my own county, it is so situated, as that it must experience very few of the shocks <PageNumber num={72} /> of war. It is perhaps as far removed from what will be the scene of
                operations as any portion of the Commonwealth. But I cannot forget that the whole State of Virginia is my country. I am here to look after the interests of all portions of the State.
              </p>
              <p className="p-in-sp">
                I don't come here to look merely to my own section. There is not a portion of the State, from the Eastern Shore to the Pan Handle, that is not dear to me. I cannot, by my vote, adopt a course of action which will bring upon a part of the State the evils which I believe secession and war in its footsteps will bring upon the Western and North-Western parts of this State.
              </p>
              <p className="p-in-sp">I trust, therefore, it may not be the decision of this Convention to hurry us into a vote upon this question this evening.</p>
              <div className="speaker" id="sp3712"><span className="persName">Mr. SAMUEL G. STAPLES</span>, of Patrick—</div>
              <p className="p-in-sp">Mr. President, I claim the indulgence of the Convention while I submit a few remarks to its consideration upon the propositions now pending before it.</p>
              <p className="p-in-sp">The gentleman from Montgomery [<span className="persName">Mr. PRESTON</span>] has proposed that a simple ordinance of secession be adopted by the Convention and submitted to the people for ratification or rejection. To this proposition the gentleman from Fauquier [<span className="persName">Mr. SCOTT</span>]
                offers an amendment, by which it is proposed to submit to the decision of the people the question of secession or a conference of the border slave States. I shall vote against the proposition of the gentleman from Fauquier, because I can see no good result to be obtained by a border slave State conference. I am unwilling to submit the destiny of Virginia, in this great contest, to
                the hands of any body of men but those selected by her to represent her sovereignty in Convention. She alone must be the arbiter of her own destiny.
              </p>
              <p className="p-in-sp">
                Besides, Mr. President, the rapidly shifting scenes of the day admonish us of the imperious necessity for instant action; and, in my judgment, the time has now arrived when Virginia should resume the sovereign powers she conferred upon the General Government and form such alliances, offensive and defensive, as her honor and her interest may demand. I shall, therefore, vote for the
                ordinance of secession submitted by the gentleman from Montgomery
                [<span className="persName">Mr. PRESTON</span>] .</p>
              <p className="p-in-sp">
                It is quite obvious to all of us that the necessity for debate has passed, and that the time for action has arrived. We are now in the midst of revolution, and the appalling circumstances under which we are surrounded admonish us of the necessity of firmness, promptness and decision. Ten days ago, I was known as a Union man-attached to the Union of these States by the hallowed
                memories of the past, and by the glorious hopes of a brilliant future, in which its <PageNumber num={73} /> unexampled career warranted us in indulging. I was elected under a pledge to resort to all honorable, constitutional means to preserve it in its integrity and purity just as our fathers formed it. It was formed by them "to establish justice, insure
                domestic tranquility, provide for the common defence, promote the general welfare, and secure the blessings of liberty to ourselves and our posterity." The Revolutionary war was waged in the defence of the great principle of self-government, and in the maintenance of the doctrines, that among the rights with which the Greater has endowed us "are life, liberty and the pursuit of
                happiness, and that to secure these rights governments are instituted among men, deriving their just powers from the consent of the governed; that whenever any form of government becomes destructive of these ends, it is the right of the people to alter or abolish it and to institute new government, laying its foundations on such principles, and organizing its power in such form as to
                them shall seem most likely to effect their safety and happiness." In support of these time-honored principles, in defence of which the revolutionary patriots poured out their blood like water, I announced to my constituents, when I was a candidate for a seat in this body, that while I cordially endorsed the reasons that impelled the seceded States to dissolve their connection with
                the Federal Government; yet I doubted the propriety of their course, and I hesitated not to declare that sound policy dictated a united movement on the part of all the Southern States, in order to procure from the Northern people an acknowledgment of our absolute equality in this government and of all the rights guaranteed to us by the Constitution. At the same time I declared my
                unalterable opposition to any attempt on the part of the Federal Government to coerce any of the seceded States back into the Union as in direct violation of the fundamental principles of the government and as in conflict with the acknowledged right of every people to regulate their own institutions in their own way. In the government and as in conflict with the acknowledged right of
                every measure I thought calculated to preserve the peace of the country and the salvation of the Union. In common with a large majority of the Union men in the Convention, I voted for and sustained the report of the Committee on Federal Relations as a basis of negotiation on which the unhappy dissensions between the North and the South might be amicably and honorably settled and the
                peace of the two sections placed upon a stable foundation. We know, as every reflecting man must know, that if hostilities once commenced between the Federal Government and Confederate States, Virginia and the other <PageNumber num={74} /> border slaveholding States could not and would not remain neutral; and that, as these were Southern States,
                identified in interest, in ties of affection and party association with the Confederate States, they would unite their fortunes with them. Hence, it was of the deepest and most vital importance that no step should be taken either by one or the other which would be likely to initiate hostilities and thus inaugurate a long and bloody war. Under these circumstances and actuated by these
                patriotic motives, the Union party voted for and adopted the following resolution:
              </p>
              <p className="p-in-sp">
                The people of Virginia will await any reasonable time to obtain answers from the other States to these propositions, aware of the embarrassments that may produce delay; but they will expect, as an indispensable condition, that a pacific policy should be adopted towards the seceded States, and that no attempt be made to subject them to the Federal authority, nor to reinforce the forts
                now in possession of the United States, nor recapture the arsenals or other property of the United States within their limits, nor to exact the payment of imposts upon their commerce, nor any measure resorted to, justly calculated to provoke hostile collision. All we asked of the Federal authorities at Washington was, a pacific, a conciliatory policy. We did not demand of them
                anything derogatory from the character of the government, nor did we ask them to surrender any right to which they were justly entitled, but we simply declared that we could not consent that the Federal power, which was in part our power, should be exerted for the purpose of subjugating the people of any of the States to the Federal authority. It was announced in the public press
                that the administration at Washington, desirous that an adjustment should be speedily effected, would inaugurate no policy calculated to irritate the public mind in the South and thus precipitate hostilities; that the forts in possession of the seceded States should not be recaptured, and that those in the possession of the Federal Government should not be reinforced; but that the
                status of things should remain until Virginia, acting in the capacity of mediator, but at the same time asserting and maintaining her own rights, should command the peace and present to the consideration of the people North and South such propositions and amendments to the Constitution, for their adoption, as would forever settle this vexed question of slavery, and place the Union of
                the States upon an enduring basis. Virginia had a right to demand this, and nothing less than this. She was the first State to take the initiatory steps that led to the formation of the Federal Constitution, and she will be the last to surrender one solitary right <PageNumber num={75} /> guaranteed to her by that Constitution. Her people never did and
                never will consent to the acknowledgment of the power of the General Government to coerce any seceded State back into the Union. They declared in the Declaration of Independence that, "when a long train of abuses and usurpations, pursuing invariably the same object, evinces a design to reduce them under absolute despotism, it is their right, it is their duty to throw off such
                government and provide new guards for their future security." They did not wait until the King of Great Britain had riveted the chains around them and bound them to slavery; but they took up arms at the first dawning of any attempt at subjugation. They accomplished the revolution on a strict question of principle.
              </p>
              <p className="p-in-sp">
                The Parliament of Great Britain asserted the right to tax the Colonies in all cases whatsoever, and it was precisely on this question they made the revolution turn. The amount of taxation was trifling, but the claim itself was inconsistent with liberty, and that was in their eyes enough. It was against the recital of an act of Parliament, rather than against any suffering under its
                enactment, that they took up arms. They went to war against a preamble-they fought seven years against a declaration. They poured out their treasures and their blood like water against an assertion, which those, less sagacious and not so well schooled in the principles of civil liberty, would have regarded as barren phraseology or mere parade of words. They saw in the claim of the
                British Parliament, a seminal principle of mischief-the germ of unjust power. They detected it; dragged it forth from underneath its plausible disguises; struck at it; nor did it elude their steady eye or well directed blow till they had extirpated and destroyed it to the smallest fibre. On this question of principle, while actual suffering was yet afar off, they raised their flag
                against a power, to which, for purposes of foreign conquest, Rome, in the height of her glory, is not to be compared-a power which was dotted over the earth with her possessions and military posts, whose morning drum beat, following the sun, and keeping company with the hours, circles the earth with one continuous and unbroken chain of the national airs of England.
              </p>
              <p className="p-in-sp">
                It is, Mr. President, this principle, held sacred in the bosoms of all true Virginians, that has aroused the people of Virginia to arms, to resist the usurpation of Abraham Lincoln, and the dangerous doctrines contained in his proclamation of war against the seceded States. They see in it "a seminal principle of mischief-the germ of unjust power" which, if assented to by them, will
                result in the overthrow <PageNumber num={76} /> of the Constitution, the destruction of the liberties they now enjoy, and will bind them hand and foot to the car of Federal power, and they are rising in their majesty and strength to vindicate, by an appeal to arms, their rights and their honor. The framers of the Federal Constitution never dreamed of
                incorporating into that instrument a provision authorizing the Federal Government to exert force against a seceded State; for in a discussion of a resolution offered by Mr, Randolph in the Convention of 1787, "authorizing an exertion of the force of the whole against a delinquent State," Mr. Madison observed "that the more he reflected on the use of force, the more he doubted the
                practicability, the justice and the efficacy of it, when applied to a people collectively, and not individually. A Union of the States containing such an ingredient seemed to provide for its own destruction. The use of force against a State would look more like a declaration of war than an infliction of punishment, and would probably be considered by the party attacked as a
                dissolution of all previous compacts by which it might be bound. He hoped that such a system would be framed as might render this resource unnecessary, and he moved that the clause be postponed." This was carried unanimously, and the vote upon it conclusively proves that, by the unanimous sentiment of the Convention, the proposition to give the General Government authority to coerce
                a seceded State was regarded as impolitic and dangerous.
              </p>
              <p className="p-in-sp">
                In the 43d number of "The Federalist," Mr. Madison, in discussing "what relation is to subsist between the nine or more States ratifying the Constitution, and the remaining four who do not become parties to it," observed: "It is one of those cases that must be left to provide for itself. In general, although no political relation can subsist between the assenting and dissenting
                States, yet the moral relations will remain uncancelled. The claims of justice, both on one side and on the other, will be in force and must be fulfilled; the rights of humanity must in all cases be duly and mutually respected; whilst considerations of a common interest, and, above all, the remembrance of endearing scenes which are past, and the anticipations of a speedy triumph over
                the obstacles to remain, will, it is hoped, not urge in vain moderation on one side and prudence on the other."
              </p>
              <p className="p-in-sp">What a commentary is this upon the aggressive and bloody spirit of the present day, which underlies the pretended "maintenance of the Constitution and the enforcement of the laws"?</p>
              <p className="p-in-sp">
                If States are out of the Union, the United States have no Constitution and laws to enforce in them. The laws of the United States <PageNumber num={77} /> are to be maintained and enforced only in the States which are in the Union. The true principle is: if States cannot live together in peace, for them to separate in peace, as Mr. Madison proposed in
                passing from the Confederacy to the Constitution.
              </p>
              <p className="p-in-sp">Nor is the clamor about public property anything more than a display of political demagogism. The United States want no property in a foreign nation, and the mutual interests of the States remaining with these departed is now but a question which could easily be adjusted by a friendly and fair negotiation.</p>
              <p className="p-in-sp">
                Actuated by these principles, and moved by a determination to do all in its power to adjust the difficulties that so seriously threaten the country, the Convention was proceeding to perfect the report of the Committee on Federal Relations, when the country began to be agitated by rumors of war-like preparations at Philadelphia and New York. These rumors soon assumed an authorised
                form, and, in order that the policy of the President might be definitely ascertained, and the awful suspense under which the country was suffering might be removed, the following preamble and resolution, offered by <span className="persName">Mr. PRESTON</span>, of Montgomery, was adopted :
              </p>
              <p className="p-in-sp">
                "Whereas, in the opinion of this Convention, the uncertainty which prevails in the public mind as to the policy which the Federal Executive intends to pursue towards the seceded States is extremely injurious to the industrial and commercial interests of the country, tends to keep up an excitement which is unfavorable to the adjustment of pending difficulties, and threatens a
                disturbance of the public peace-
              </p>
              <p className="p-in-sp">"Resolved, That a committee of three delegates be appointed to wait on the President of the United States, present to him this preamble and resolution, and respectfully ask him to communicate to this Convention the policy which the Federal Executive intends to pursue in regard to the Confederate States."</p>
              <p className="p-in-sp">
                The Convention, under this resolution, elected Messrs. Preston, Randolph and Stuart, who immediately proceeded to Washington on their mission of peace. Before they reached that city, the country was startled by the intelligence that Mr. Lincoln had, in violation of his well understood pledge, sent a messenger to the city of Charleston and informed Governor Pickens that he intended to
                reinforce Fort Sumter, peaceably, if he could, forcibly, if he must. No one could for a moment suppose that the Confederate States would passively permit troops and provisions to be thrown in Fort Sumter, to overawe and control the people of the South; and such an act <PageNumber num={78} /> being regarded by them as a virtual declaration of war, an
                attack on the Fort was immediately commenced, but not, however, until an effort had been made by the authorities of the Confederate States to prevent a hostile collision. Before the bombardment of the Fort commenced, and after a demand for its surrender had been made, the Secretary of War informed General Beauregard that they did not desire to bombard Fort Sumter if Major Anderson
                would state the time at which, as intimated by him, he would evacuate. This Major Anderson declined doing, and, as I have remarked, the bombardment began. This was followed by a proclamation of war by the President of the United States, and a requisition upon Virginia for 3,500 troops to aid in the subjugation of her sister Southern States. Now let it be borne in mind that this
                Convention had, by a very large majority resolved that they never would consent that the Federal power, which is in part their power, should be exerted for the purpose of subjugating the people of such States
                [the Confederate States]
                , to the Federal authority; and this resolution was advocated and adopted upon the ground that inasmuch as our institutions were based upon the principle that government was founded upon the consent of the governed, and that all power vests in and emanates from the people, the people of Virginia could never consent to take part in a contest against their Southern brethren, who had
                done nothing more than change their form of government to suit themselves. The question is now narrowed down to this : Shall Virginia unite her fortunes with the Northern people, and take part in this war of subjugation and conquest, or shall she resume the powers she conferred upon the Federal Government, and form such alliances with the Confederate States as her honor and interest
                demand?
              </p>
              <p className="p-in-sp">
                This Convention having exhausted all peaceable measures to save the Union and avoid a resort to arms, has now no alternative left but to adopt an ordinance of secession, and submit it to the people for ratification or rejection. It cannot be urged, Mr. President, that the Confederate States are responsible for this sad and lamentable condition of things, for they had sent
                commissioners to the city of Washington to negotiate with the President of the United States for a peaceful solution of existing difficulties. These commissioners repaired to the Federal Capitol, and, on the 15th of March last, sent a communication to the President, in the following language:
              </p>
              <p className="p-in-sp">
                "With a view to a speedy adjustment of all questions growing out of this political separation, upon such terms of amity and good will as the respective interests, geographical contiguity and future welfare <PageNumber num={79} /> of the two nations may render necessary, the undersigned are instructed to make to the Government of the United States
                overtures for the opening of negotiations, assuring the Government of the United States that the President, Congress and people of the Confederate States earnestly desire a peaceful solution of these great questions; that it is neither their interest nor their wish to make any demand which is not founded in the strictest justice, nor to do any act to injure their late confederates."
              </p>
              <p className="p-in-sp">They waited for a reply to their courteous and friendly communication until the 8th of April, when they received a response from Mr. Seward, the Secretary of State, in which the following language is used:</p>
              <p className="p-in-sp">
                "Under these circumstances the Secretary of State, whose official duties are confined, subject to the direction of the President, to the conducting of the foreign relations of the country, and do not at all embrace domestic questions arising between the several States and the Federal Government, is unable to comply with the request of Messrs. Forsyth and Crawford, to appoint a day on
                which they may present the evidences of their authority and the objects of their visit to the President of the United States. On the contrary, he is obliged to state to Messrs. Forsyth and Crawford that he has not authority, nor is he at liberty to recognize them as diplomatic agents, or hold correspondence or any communication with them."
              </p>
              <p className="p-in-sp">This insulting reply of course closed the door to all further negotiations between the parties, and the commissioners departed for the South.</p>
              <p className="p-in-sp">
                An impartial public will throw the blame of this unnatural contest upon the Administration at Washington. That Administration has "let slip the dogs of war," and is now engaged in the most extensive preparations for a long and bloody contest. In such a contest Virginia cannot be neutral. The principles which the Revolutionary War was waged to defend-the States' Rights doctrine which
                her statesmen have always held dear-her honor, her interest, her all, are intimately bound up in this contest. If she yields now, and silently permits the subjugation of her Southern sisters, and tacitly assents to the usurpations of power by the Executive at Washington, the time will soon come when, driven on by fanaticism, backed by the force of mere numbers, and influenced by
                thirst for power, that Executive may obliterate every vestige of constitutional liberty, and make us, while living under the mere forms of free government, the veriest slaves on earth. The Virginia people will never consent that this Black Republican <PageNumber num={80} /> party, through its puppet at the federal capital, should ruthlessly attempt to
                conquer a brave and loyal race, who never did harm to them, who never attempted malignant and unjustifiable interference with their domestic institutions, who have remonstrated against the wrongs they have patiently endured until a longer forbearance has ceased to be a virtue, and who, being driven to take up arms in their own defence, will light up the camp fires of resistance on
                every hill top and every valley, and never cease until the last drop of the blood of the invaders shall wash out their foul footsteps' pollution.
              </p>
              <p className="p-in-sp">
                Mr. President, this Union, as our fathers formed it, has been dissolved; the fairest political fabric the world ever saw has been undermined by corruption, and has toppled to its foundation. It is now a question of the restoration of the Union. It cannot be done. That has passed away. The bloody proclamation of the petty tyrant who now disgraces the chair which Washington once
                filled, has dissipated the last ray of hope that lingered in the bosom of the most hopeful. If there were Union men in Virginia once, there are no Union men here now. We are for resistance to the death. We are now called upon to make a choice of our destiny-to bow our proud necks to the yoke of the tyrant, or rise up as one man, and burst the manacles that bind us. It is now a
                question of freedom or slavery, of honor or dishonor-yea, of our very existence. Our whole civilization is at stake, and the liberty or bondage of millions yet unborn depend upon the result of this contest. In the folly and madness that rules the hour, an attempt has been made to coerce the Southern States; but it will not succeed.
              </p>
              <p className="p-in-sp">"Freedom's battle once begun, Bequeathed to bleeding sire or son, Though baffled oft, is ever won."</p>
              <p className="p-in-sp">
                The South contains millions of men, and their stout hearts and strong arms will be as one in the defence of their rights, the sanctity of their homes and firesides. What people on earth, who have once tasted the sweets of national constitutional freedom, will ever again passively submit to drink the bitter dregs of slavery? To them anything is better than the living death of
                conscious degradation, and the withering contempt of those who would impose the yoke upon their necks.
              </p>
              <p className="p-in-sp">
                I shall cheerfully vote for and subscribe my name to this ordinance of secession, regarding it as the proudest act of my life; one from <PageNumber num={81} /> which this proud Commonwealth will receive no detriment; by which the liberties under which we were born will be kept entire, and transmitted unimpaired to our posterity; and the heritage of our
                children will be one of honor, and not of shame-of freedom, and not of slavery.
              </p>
              <div className="speaker" id="sp3713"><span className="persName">Mr. JOHN B. BALDWIN</span>, of Augusta—</div>
              <p className="p-in-sp">
                I desire to say a few words. I must acknowledge that I am surprised and disturbed to see the course pursued here upon this floor by men who, like myself, were elected as Union men, representing Union constituencies, and men who have, from the commencement of the session of this body, concurred with me in opinion and co-operated with me in action in shaping the policy that was to be
                pursued by this Convention. They have seen occasion to change their views in regard to a matter of policy. I do not object to this. They have a perfect right to change their views; and I would be the last man to question the sincerity of the change, or the patriotism of the motives by which they are actuated. But what I do complain of is this, that these gentlemen, who have thus
                changed their views and policy, without conference at all with those who have concurred with them during the session; without opportunities of exchanging views, come here into this body, and, uniting with those whom we have been opposed to during the entire session, determine to prevent me and all the rest of their associates in opinion and action from having any opportunity whatever
                to debate this now most important feature of the business of this body.
              </p>
              <p className="p-in-sp">
                It seems to me that it is as little as we could expect from these gentlemen, who have seen reason to change their views, that they should come and in open debate give us the information upon which they have acted, and the reasons upon which they propose to change their opinions, and hear from us, as friends and associates, the reasons we have to assign for our course under these
                circumstances.
              </p>
              <p className="p-in-sp">
                These gentlemen have thought with me all the time that the true plan was, that of a Border Conference and co-operation with the slave States yet remaining in the Union. They and I together have co-operated earnestly and cordially, as I supposed, up to within a few days past, and pursued a system of that sort. They have not only abandoned that, but when I ask them to submit to us one
                of the alternatives proposed to the people of Virginia, and leave the people to decide which is right, they refuse even to allow their constituents, who have directed them in their course of co-operation, up to within two or three days past-their associates upon this floor who have <PageNumber num={82} /> laboriously united with them in seeking to carry
                out this policy-they utterly refuse us any part in submitting this policy for the approval of the people, and refuse to let the people themselves have an opportunity of determining their policy.
              </p>
              <p className="p-in-sp">
                Sir, I think this is as little as could be conceded by these gentlemen, to those who were united with them in the past, that they should either allow us an opportunity of fair and open debate in regard to this matter, or allow us the opportunity of appealing to the people, who sent them here as Union men and co-operationists, to determine which is the true policy, the one that they
                have matured up to within a day or two past, or the one with which they have recently become enamored.
              </p>
              <p className="p-in-sp">
                I regard this as an exceedingly dark hour in the history of this State; and one of the worst symptoms I see in regard to it is, that so soon as gentlemen who have been cautious, deliberate, prudent in counsel and action, consent to go for secession, they seem to change the whole character of their motives and disposition; and from having before insisted constantly upon deliberation
                and investigation at every point, they stand firm around their guns with the most rabid of all the precipitators. These gentlemen have suddenly become the most rabid of all, sir. There seems to be something in the hour; there seems to be something in the fever with which our people have been seized; there seems to be something in the excitement which pervades the community, which
                renders me hopeless of being able to see anything that can put a stop or check to the war, which I think promises nothing but calamity and mischief. But it seems to me a sort of fanatical excitement which I think bodes nothing but ill to the country. Sir, if I had the time, I think I could show that the scheme of cooperation presented is the only chance, the only hope that could be
                offered to our people of avoiding instant and overwhelming destructive civil war, the only hope whatever that is offered by possibility of escaping from utter and irretrievable destruction; the only hope whatever of securing peace; the only hope whatever of reconstructing the government in connection with States whose interests we are bound up in a way that nothing but destruction
                and calamity that can follow from severance can impair.<span className="note" id="Note10"><span className="noteLabel">6</span>There is apparently an omission in this sentence.</span> I could show this on the one hand, and on the other I could show that separate State secession is not only war, but calamitous war, civil war, servile war, aye, sir, a war fraught with all conceivable and
                imaginable horrors for which we have no preparation, and which, if undertaken now, we shall have no opportunity hereafter to prepare for.
              </p>
              <PageNumber num={83} />
              <p className="p-in-sp">
                But, sir, it seems that we are to be pressed to a vote upon this proposition to-night. It seems to be determined that this act is to be performed to-night sir. All I can say is, that while I shall earnestly battle against it, while I shall earnestly deprecate it, I shall offer no factious opposition to it. I can only say that, so far as I am concerned, in view of the responsibility
                that I owe to God and man, I cannot concur in the act which is about to be done.
              </p>
              <div className="speaker" id="sp3714"><span className="persName">Mr. BENJAMIN WILSON</span>, of Harrison—</div>
              <p className="p-in-sp">I beg pardon of this Convention while I assign the reason that induced me to vote for the proposition of the gentleman from Fauquier [<span className="persName">Mr. SCOTT</span>] .</p>
              <p className="p-in-sp">
                We all admit that war is now imminent, nay, inevitable. It cannot be denied but that it is necessary for Virginia to take some action. That action must be final. It should be taken understandingly, and with due deliberation. We must take such a course as will enable us to defend ourselves, to protect our lives, our honor, and our property. Let the gentleman who offered the
                proposition, take no steps backward. For my part, I shall adhere to all the votes I have heretofore cast. I shall advocate the same States-Rights doctrine which I have heretofore advocated. I shall concede to Virginia the full measure of all her Constitutional rights, and I presume there is no gentleman here who is willing to place Virginia in any false position. The only question
                about which we differ, as I understand, is as to the manner in which she should protect herself.
              </p>
              <p className="p-in-sp">It is very important, and very desirable in this crisis that we should have the unanimous concurrence and co-operation of our people.</p>
              <p className="p-in-sp">
                To have a divided and distracted people, will bring upon us at once the most awful calamities that any one can imagine. There is no use in endeavoring to disguise the fact that the institution of slavery is one of the acting causes that brought about this calamity. Look at the state of feelings in respect to this institution in the South, and you will find that most of your
                slaveholding States are divided upon this question. Your own State is divided upon it in a very considerable degree. Look down from the valley, down to Augusta, to the extreme Eastern counties-to the city of Norfolk. Look to the county of Frederick, and other adjacent counties, and there you will find thousands of slaves, while the gentlemen representing these counties on this floor,
                have all through identified themselves with the party whose zeal in behalf of the institution of slavery, if measured by their efforts in behalf of the Southern or Secession cause, as some would seem disposed to measure, would indeed be at a very low standard. We find some members who represent a much larger amount of <PageNumber num={84} /> property
                than those just referred to seeking to defer action, while others, representing large slaveholding interests, and some represent ing that interest but to a limited extent, are for immediate secession.
              </p>
              <p className="p-in-sp">
                By the act of secession, we place ourselves in this predicament. We call attention to the fact that we have severed our connexion with the Federal Government. We therefore invite war. I understand that this Ordinance cannot be operative until the people pass upon it; and whilst we are in the embarrassing predicament of having passed the Ordinance of Secession, our enemies will go on
                preparing, and we will be liable to attack upon any of our borders without the means of defence. We must, in fact, be in a state of doubt as to what course to pursue until the vote of the people is taken upon the Ordinance. It is only then that we can see clearly before us; meanwhile the enemy may have us completely at their mercy.
              </p>
              <p className="p-in-sp">
                I think it is better for us to call to our aid the border slave States, whose co-operation in the coming struggle is essential to our success. I think it is an act of unnecessary precipitancy for us to pass this Ordinance of Secession to-night. I regard it as precipitate to pass it at all now, without first taking counsel with our sister border States of Maryland, North Carolina,
                Tennessee, and Kentucky. After that, we will be better prepared to take action; and we go into the conflict with the additional prestige, which the approval and co-operation of these States would confer. I take it that a conference would result in a perfect union of all the slaveholding States now in the Union, for one end-probably for Secession. This, in my judgment, would do more
                to restore peace than any means that could be desired.
              </p>
              <p className="p-in-sp">
                Run your eyes around your borders, and you will find that there are three sides of Virginia exposed to the enemy. Let us preserve our present position, at least for a while, and we can repel the assaults of the General Government as well in the Union as out of it. We can call to our aid men and munitions of war in the Union as well as out; and, in view of these circumstances, I think
                it better we should wait for a conference of these States.
              </p>
              <p className="p-in-sp">
                You find there is a large number in Virginia who are of opinion that you ought to confer with the border States. If such a conference is had, my word for it its influence in Virginia will be salutary. We will have Virginia as one man co-operating together, and proclaiming one universal sentiment in favor of the assertion of Southern rights. This would be a result which might well
                urge the policy and expediency of deliberation, and an avoidance of such precipitate action, as seems now to be contemplated-action which, if taken, must result <PageNumber num={85} /> only in diversion and ruin. It is the part of wisdom; it is the part of prudence; it is a source of safety to wait for assistance, and to take the necessary time for
                preparation. To pursue any other course is to incur disgrace, and to invite an attack within our limits which may end in our utter ruin and destruction. One great object should be to secure unity within the State in any event, so that come weal or woe, we might stand shoulder to shoulder, and die beside each other if necessary, in the defence of our noble old Commonwealth. We should
                make a great effort to secure so glorious a result, and I trust that the House will consider the propriety of that course, and finally pursue it.
              </p>
              <p className="p-in-sp">
                If the proposition of the gentleman from Fauquier is adopted, the several delegates, on their return home, will have an opportunity of ascertaining the sentiment of their people, and they will return here fully prepared to take any steps that may be necessary in connection with the ordinance, should the people direct such to be passed. I take it that the proposition of the gentleman
                from Fauquier
                [<span className="persName">Mr. SCOTT</span>] , will rather facilitate than delay the consummation of the purpose sought for by gentlemen on the other side.</p>
              <div className="speaker" id="sp3715"><span className="persName">Mr. CARTER</span>, of Loudoun—</div>
              <p className="p-in-sp">I have not troubled this Convention for a moment since we have been in session. I don't want to trouble them now; but I want to make an appeal to those gentlemen with whom I have been co-operating up to the present, or, at any rate, until within a few days, and to ask them to aid me in carrying the question that this Convention now adjourn.</p>
              <p className="p-in-sp">I make that motion.</p>
              <div className="speaker" id="sp3716"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I ask the yeas and nays upon the motion.</p>
              <p>
                The call was sustained, and the question being taken, resulted-yeas 76, nays 66-as follows:<span className="note" id="Note11"><span className="noteLabel">7</span>The Journal adds Lawson to the negative votes listed here, to make a total of 65.</span>
              </p>
              <p>
                YEAS-Messrs. Armstrong, Aston, Baldwin, Baylor, Berlin, Blow, Boggess, Boyd, Branch, Brent, Brown, Burdett, Burley, Byrne, Campbell, Caperton, Carter, Coffman, C. B. Conrad, R. Y. Conrad, Couch, Critcher, Custis, Dent, Deskins, Dorman, Dulany, Early, Echols, French, Fugate, Gillespie, Gravely, Gray, Ephraim B. Hall, Hammond, Haymond, Hoge, Holladay, Hubbard, Hughes, Hull, Jackson,
                Marmaduke Johnson, Peter C. Johnston, Lewis, McComas, McGrew, Macfarland, Marshall, Marye, Masters, Moore, Nelson, Osburn, Patrick, Pendleton, Porter, Price, Pugh, Rives, R. E. Scott, Wm. C. Scott, Sharp, Sitlington, Spurlock, A. H. H. Stuart, Chapman J. Stuart, Summers, Tarr, White, Whitfield, Wickham, Willey, Wilson, <span className="persName">Mr. PRESIDENT</span>-76.
              </p>
              <p>
                NAYS-Messrs. Ambler, A. M. Barbour, James Barbour, Blakey, Boisseau, Borst, Bouldin, Bruce, Cabell, Cecil, Chambliss, Chapman, Clemens, Conn, J. H. Cox, R. H. Cox, Fisher, Flournoy, Forbes, Garland, Graham, Gregory, Goggin, <PageNumber num={86} /> John Goode, Jr., Thos. F. Goode, Hale, L. S. Hall, Harvie, Holcombe, Hunton, Isbell, Kent, Kindred, Leake,
                C. K. Mallory, J. B. Mallory, Miller, Moffett, Montague, Morris, Morton, Orrick, Parks, Preston, Randolph, Richardson, Seawell, Sheffey, Slaughter, Southall, Speed, Staples, Strange, Sutherlin, Tayloe, Thornton, Tredway, R. H. Turner, Tyler, Waller, Williams, Wise, Woods, Wysor-64.
              </p>
              <p><span className="persName">Mr. TURNER</span>, of Jackson, stated that if he had not paired off with an absent member, he would have voted "no."</p>
              <p>The Convention then adjourned until 10 o'clock, A. M., to-morrow.</p>
            </div>
          </div>
          <div className="day" id="vsc1965.v4.2.2">
            <PageNumber num={87} />
            <h2><span className="headingNumber">1.2. </span><span className="head">SECOND DAY</span></h2>
            <div className="dateline">Wednesday, <span className="date">April 17</span></div>
            <p>The Convention met at 10 o'clock, pursuant to adjournment.</p>
            <p>After some discussion in reference to the mode by which part of yesterday's proceedings got into the columns of one of the Richmond papers-</p>
            <p><span className="persName">Mr. CONRAD</span>, of Frederick, offered the following resolution :</p>
            <p>Resolved, That each member of this Convention should feel himself in honor bound not to disclose any portion of what may occur in secret session, until the injunction of secrecy shall be removed.</p>
            <p><span className="persName">Mr. WISE</span> offered the following by way of substitute, which was accepted by <span className="persName">Mr. CONRAD</span>, and unanimously adopted :</p>
            <p>Resolved, That the order to go into secret session, bound, and still binds, the honor of each member carefully to keep secret what is said or done in such session.</p>
            <p><span className="persName">Mr. COX</span>, of Chesterfield, then offered the following resolution, which was adopted :</p>
            <p>Resolved, That two door-keepers be sworn, whose duty it shall be to keep the rotunda and stairways clear.</p>
            <p>The oath of secrecy was then administered to W. J. Leake, second door-keeper, and J. G. Moss, third door-keeper.</p>
            <p>The Sergeant-at-Arms and first door-keeper were previously sworn.</p>
            <div className="speaker" id="sp3717"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">The Chair has received a communication from the Governor, to be laid before the Convention, which the Secretary will read.</p>
            <p>The Secretary then read the following reply of the Governor to the requisition for Virginia's quota of troops, made by the President of the United States through the Secretary of War:</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>EXECUTIVE DEPARTMENT, RICHMOND, VA., April 16, 1861.</p>
                <p>Hon. SIMON CAMERON, Secretary of War:</p>
                <p>SIR: I received your telegram of the 15th, the genuineness of which I doubted. Since that time I have received your communication, mailed the same day, in which I am requested to detach from the militia of the State of Virginia "the quota designated in a table," which you append, "to serve as infantry or riflemen for a period of three months, unless sooner discharged."</p>
                <PageNumber num={88} />
                <p>
                  In reply to this communication, I have only to say that the militia of Virginia will not be furnished to the powers at Washington, for any such use or purpose as they have in view. Your object is to subjugate the Southern States, and the requisition made upon me for such an object, in my judgment, not within the province of the Constitution or the act of 1795, will not be complied
                  with. You have chosen to inaugurate civil war, and, having done so, we will meet it in a spirit as determined as the administration has exhibited towards the South.
                </p>
                <p>Respectfully,</p>
                <p>JOHN LETCHER</p>
              </div>
            </div>
            <div className="speaker" id="sp3718"><span className="persName">Mr. RANDOLPH</span>—</div>
            <p className="p-in-sp">
              In order to avoid misconception in regard to the supply of gun powder, I beg leave to add to the statement which I made yesterday, that the army commissioners purchased 30,000 pounds of powder, which was deemed a sufficient supply for a train of artillery of 12 or 15,000; that they had purchased 12,000 pounds of musket powder, which was deemed altogether insufficient. They would have
              purchased more had the funds been available at the time. Indeed, there was a difficulty in obtaining the powder even if all the means necessary to purchase it were at hand.
            </p>
            <p className="p-in-sp">
              It is known that there are two great powder establishments in the country-those of Dupont and Hazard. Both of them have agents in this city, who usually keep a supply on hand sufficient at least for ordinary purposes; but I suppose their present supply will be altogether inadequate to the demand which the coming emergency will create. I learned this morning from a conversation with the
              agent for Dupont that the supply had been suffered to diminish very materially, and that there was not now under his control more than 300 kegs of powder, and he offered to place that under the control of the Governor. He stated that the present state of things in the South has caused the usual supply of powder to be cut off, so that we cannot expect to receive a great deal from that
              source. I have not been able to see Hazard's agent, but usually the supply of that agent is far less than that furnished to Dupont's agent, the manufactory being in Connecticut. This being the case, the State will have to take under her control all the powder now available, and supply herself from other sources.
            </p>
            <div className="speaker" id="sp3719"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">The pending question now is, shall the main question be put, being the substitute offered by the gentleman from Fauquier [<span className="persName">Mr. SCOTT</span>] .</p>
            <div className="speaker" id="sp3720"><span className="persName">Mr. ALPHEUS F. HAYMOND</span>, of Marion—</div>
            <p className="p-in-sp">
              I had thrown myself <PageNumber num={89} /> some time ago upon the indulgance of this body, while I offered some remarks on the subject of uniform taxation, which was a subject of a somewhat local character. I had hoped that at some stage of our deliberations, I should have had an opportunity to have addressed my brother members upon the state and
              condition of the country; but, sir, under the operation of the previous question, I am deprived of that opportunity. I desire now, in the few minutes left me, to state to this Convention and the country my position now, and the motives and impulses which have animated me during the session of this body.
            </p>
            <p className="p-in-sp">Mr. President, I have been a Democrat, and still claim to be one. In the last Presidential election I acted in connection with that portion of the Democracy which had voted for John C. Breckinridge for President, fully endorsing, as I now do, the platform upon which he ran.</p>
            <p className="p-in-sp">
              In order to reply to some sentiments which have been thrown out in this body by some members who have differed in political opinions with me, I desire, in support of my position, motives and sentiments, to refer to some sentiments uttered by Mr. Breckinridge in a speech delivered by him in the Hall of the House of Representatives in Kentucky on the 21st of December, 1859. I shall read
              but two passages.
            </p>
            <p className="p-in-sp">In speaking of the Black Republican party, or rather their political platform as he then understood it to be, he used this language:</p>
            <div className="stage it">
              <p>[<span className="persName">Mr. HAYMOND</span> here read an extract from the speech referred to, in which Mr. Breckinridge expressed a hope that some other remedy than a resort to a dissolution of the Union would be found for the evils of which the South complained.</p>
              <p><span className="persName">Mr. HAYMOND</span> also read an extract from the same speech, in which it was stated that the people of the Southern States were in advance of their representatives in the cry for dissolution; but that he hoped, nevertheless, such a disaster would be averted by measures of conciliation and compromise.]</p>
            </div>
            <p className="p-in-sp">
              This, sir, was the language held by Mr. Breckinridge in the last published speech which he delivered prior to his nomination; and when I supported him, I did not think that I was making myself a party to the dissolution of this Union. I am satisfied, sir, that all of that party in my section of the State who supported Mr. Breckinridge were governed by higher and more patriotic motives
              in giving him their support, than that of dissolving this Union.
            </p>
            <p className="p-in-sp">
              I have never been a disunionist. I have been in favor of perpetuating forever, if possible, the Constitution and our Union as it was transmitted to us by our fathers. I believe the Constitution has <PageNumber num={90} /> been violated. I believe that the compromises of the Constitution have, in many instances, been trampled under foot; yet I had hoped
              that notwithstanding all this, a better state of feeling might be brought about, and the Union and the Constitution preserved and transmitted to the remotest posterity.
            </p>
            <p className="p-in-sp">
              It was for these reasons that I had engaged in this body, with my whole heart and my whole soul, to endeavor, with my colleagues here, to bring about a restoration of the country to a condition of peace, harmony and mutual good will among both sections. But, sir, at this moment I see-I say it with the deepest sorrow-that it is a foregone conclusion on the part of a majority of the
              members of this body, that this Union is to be dissolved, and dissolved forever. Virginia has thus far stood firm, under the hope that reconciliation might be effected, notwithstanding that seven Southern States had already separated themselves from the Union. This hope, I regret to say, was not fulfilled, and the terrible consequence, it appears, is now upon us.
            </p>
            <p className="p-in-sp">What am I to do? I cannot now argue this question. I desire, however, to state my position, and shall do so as briefly and explicitly as I can.</p>
            <p className="p-in-sp">
              I know-I feel this day that the same patriotic spirit burns in my bosom that animates the heart of every member on this floor; yet, in the North-western portion of Virginia, from which I come, dissatisfaction will be produced by the passage of this ordinance, because of their isolated and defenceless condition-the result of the policy pursued towards that section by past legislation.
              While the bounty of the Commonwealth has been poured out upon every other section of the State, that section has been neglected ; and, sir, so much so that we have not the means of communication with your city, or the commercial ports of Virginia. We cannot get to this capitol without coming through the State of Maryland, having no other means of travel to this point than the Baltimore
              and Ohio rail road. My people have no trade whatever with any other point except the cities of Pittsburgh and of Baltimore, and in that state of things, I apprehend that, with the disruption of interests which will follow the act of secession, deep and bitter dissatisfaction will prevail among them upon the consummation of this act of dissolution.
            </p>
            <p className="p-in-sp">
              Now, sir, I have been in favor of a Border State Conference. Why, sir? Because if Virginia moved and Maryland would not, the mountains and hills which, until within the last few years, have shut us out from the world, would again encircle us, and the only outlet which we have had would be effectually blocked up. We would be shut out from
              <PageNumber num={91} /> every market, and our condition would be no better than was that of the primitive settlers of that vast North-western region.
            </p>
            <p className="p-in-sp">
              In looking at the newspapers this morning, desirous to see the answers of the various Governors throughout the country to the requisition of the Secretary of War for troops, I find that the Governor of the State of Maryland has responded favorably to the demand made upon him by that official, and has further stated officially that Maryland determines to continue in the Union.
            </p>
            <p className="p-in-sp">
              Now, what is the position in which we will be placed if Maryland is to adhere to the Union? We will be locked up in the mountains until you choose to let us out. Universal bankruptcy will spread all over my country. I sympathize with you, but the injury which will result from this mode of vindication to which you propose resorting will be ruin to my people. No man upon this floor has a
              more Southern constituency, in all their feelings, than I have; but they must consult their interests, and I, as their representative, must see that their interests suffer no detriment here, so far as lies in my power to prevent it.
            </p>
            <p className="p-in-sp">
              What are we to do if Maryland takes this position and refuses to go out? Why, Mr. President, as has been remarked here before, we have Pennsylvania immediately upon our Northern border, and Ohio upon the West. In my county we have but one armed company, and a gallant company it is. We have men enough-men, too, of noble and patriotic hearts; but we need arms-and the question is, how are
              arms and munitions of war to be transported from the City of Richmond, or any other point in the east to that county? They cannot be sent there by any ordinary means of transportation; and the only other means of transportation is too slow and too expensive to offer any hope of efficient relief.
            </p>
            <p className="p-in-sp">You will thus perceive that our predicament, in the event of the passage of the ordinance of secession, will be a peculiarly embarrassing one; and not the least of the evils which will be entailed upon us by this course of action, will be, as I very much apprehend, the manumission of every slave in our midst by the enemy.</p>
            <p className="p-in-sp">
              I am a Virginian in every aspiration, and feeling, and sentiment. I stood by you at all times in defending your rights. I would like to do so now. I would like to see a united Virginia, and to that end I should have been pleased to see you pay some attention to our interests; for it is only in that way that you can reconcile our people to the sufferings and disadvantages to be entailed
              by dissolution. Why not now adopt some measure upon which we can all unite? Will you save us <PageNumber num={92} /> by adopting the border State Conference proposition, or hand us over to a heartless enemy by passing the ordinance of secession? So far as I am concerned, I am in favor of protecting every right. My people are not cowardly. Those who think
              so, may be disappointed if they only go among them, or attempt to invade a single right of Virginia.
            </p>
            <p className="p-in-sp">I believe there is not one of them that would not stand by her, and fully verify by their acts the noble motto of the Old Dominion-"Sic Semper Tyrannis." Let those people have a fair chance, and you will find them true to your interest and your honor.</p>
            <p className="p-in-sp">
              While I make this appeal, I cannot help believing that it is a foregone conclusion that the ordinance of secession will be passed this day. Be it so. I have stated to you my position and the position of my people. I believe that co-operation could be had with the State of Maryland, that Virginia could carry her with her if she only took the proper steps, and united herself and the
              other border States into conference. If Maryland does not come, nothing but ruin awaits us. But, sir, if a sacrifice is to be made let it be made, and let us be the victims.
            </p>
            <p className="p-in-sp">I would only say to my brother members here that when the question shall have been decided I will go to my people and communicate with and console them as best I can. I trust in that God who has ever been so kind, and whose guardianship our country has ever enjoyed, to stand by them to the last.</p>
            <div className="speaker" id="sp3721"><span className="persName">Mr. WILLIAM G. BROWN</span>, of Preston—</div>
            <p className="p-in-sp">Mr. President—</p>
            <div className="speaker" id="sp3722"><span className="persName">Mr. WISE</span>—</div>
            <p className="p-in-sp">I wish to give notice that I will object to any further violation of the rule as to time.</p>
            <div className="speaker" id="sp3723"><span className="persName">Mr. WM. G. BROWN</span>—</div>
            <p className="p-in-sp">My friend from Princess Anne need not give that notice so far as I am concerned.</p>
            <div className="speaker" id="sp3724"><span className="persName">Mr. WISE</span>—</div>
            <p className="p-in-sp">I do not refer to the gentleman in particular.</p>
            <div className="speaker" id="sp3725"><span className="persName">Mr. BROWN</span>—</div>
            <p className="p-in-sp">Representing one of the large counties of this Commonwealth, lying immediately upon the Pennsylvania line, with nothing but a wooden fence between my people and the Western portion of Pennsylvania, I desire simply to present to my fellow members here the condition of my people.</p>
            <p className="p-in-sp">
              On the North side of Mason and Dixon's line, in that section lies an area about the same size of my county, called Fayette. That county has a population of about 40,000, while my county has a population of but 14,000. Back of western Pennsylvania lies western New York and northern Ohio. If you will turn to your table you will see that these sections include the largest population,
              perhaps, to the square mile of any other part of the free States. In addition to this, they have <PageNumber num={93} /> the advantage of an armory at Pittsburg, which is one of the largest and best belonging to the Federal Government. They are well supplied with all the munitions of war, and, with such advantages and a population so vastly exceeding that
              on our side of the line, you may well imagine what our condition will be in the event of a war.
            </p>
            <p className="p-in-sp">
              My county contains some of the descendants of the men of the Revolution, and of the descendants of the men who fought in the last war; probably more than any other two counties in the State. Following upon the last war, and immediately at the close of the Revolution, when that county was opened to settlement and the poor soldiers discharged from service, they sought a home there,
              carrying with them their trusty rifles. These rifles are still in the hands of their descendants, as well as some specimens of the Continental money.
            </p>
            <p className="p-in-sp">
              During the last war-I mean the war of 1812-the men of Monongalia and of Preston not only came to the Eastern shore for the defence of Virginia, but went to the lakes and fought there in defence of your rights. They were prominent in all the battles along these frontiers; and I think it is but just to their descendants to day that some regard should be had to their interests in the
              action which this Convention may take at this time. They did not expect me to say anything about their bravery. Sir, they disregard any conflict that may come upon them; but they are aware of the vast difference in numbers and equipments which exists between them and the enemy. They know that the very moment the dogs of war are let loose that we are to be assailed from North of Mason
              and Dixon's line-that we are in fact to be overrun.
            </p>
            <p className="p-in-sp">
              Sir, we will defend our homes, and our wives and children to the very last. Our old rifles will be called into requisition and all the use we can make of them will be made. If we are driven back, we will fall into the gorges of the mountains and battle to protect ourselves. I trust in God for our success. While thus engaged in our own defences you cannot expect aid of men from us. We
              have more to encounter than you have. You have Maryland between you and the free States, which contains a population very differently disposed towards you from what the population on our border is towards us. We have North of us a people who have very little scruples in assailing us and running off our slaves. But let them go; we can spare them. I care nothing about the sacrifice of
              property; if we can only save our families, and occupy our homes, when war ceases, it is as much as we can expect. If we have a soldier to spare, you can have him. If it is necessary to carry the old revolutionary rifle, we will come here <PageNumber num={94} /> with it; but I am satisfied we will have as much as we can do to defend ourselves.
            </p>
            <p className="p-in-sp">
              If it is the pleasure of the Convention to pass the Ordinance of secession and let loose upon us the Northern horde, do it quickly, that we may make haste to our homes. I am told the railroad is to be broken up between this and Harper's Ferry; in which event, we will have to take the road by Philadelphia. I am even told that we will be captured on our way home; and you will doubtless,
              hear of many captures before long.
            </p>
            <p className="p-in-sp">
              The Pan Handle is in a worse condition than even my own county. It is bounded on the north by Pennsylvania and on the west by Ohio. It is a country over which a cannon ball can be thrown. Can they maintain their existence there? Can you send a soldier there to defend them? Do you really think that we will not need any? We will need them, but we do not expect aid from you, and I am sure
              you will not be able to get aid from us to any extent. The Pan Handle will be overrun, and will be in the possession of the enemy, as well as all the border counties of the North-west. That will be our condition.
            </p>
            <p className="p-in-sp">
              Sir, I will simply conclude by saying that I have been more than thirty years in the service of the State, either here or at Washington. It is not necessary for me to say that I am attached to Virginia and to the Federal Government. This State and the Federal Government have protected me, and my fathers before me; and I only hope that they will furnish the same protection to those that
              I shall leave behind me.
            </p>
            <div className="speaker" id="sp3726">
              <span className="persName">Mr. JOHN L. MARYE, SR.</span>, of Spotsylvania
              <div className="note" id="Note12">
                <span className="noteLabel">1</span>This speech was badly reported; the "Latin" phrase pungite passita on p. 85, for example, must be a misunderstanding of "perfect opacity." Mr. Marye sent a letter to the editors of the Enquirer, with a corrected version of these remarks. The letter and the corrected version were printed in the Enquirer on October 29 as follows:
                <p>Fredericksburg, Oct. 28, 1861.</p>
                <p>
                  Gentlemen: I did not see until Saturday last, your paper containing the report of the proceedings of the Convention in secret session on the 17th of April, in which appears a report of some remarks made by me in that body. The latter is full of gross errors and either perverts or renders unintelligible what I said. I do not impute the errors to the want of skill or fidelity in the
                  reporter, for the excitement which prevailed and my indistinct utterance might readily have occasioned them. As your reporter gave assurances to the members that all such reports should receive the supervision of the speaker, I ask your publication of the correct statement of what I did say, which will accompany this.
                </p>
                <p>Very respectfully yours,</p>
                <p>JOHN L. MARYE.</p>
                <p>REMARKS OF JOHN L. MARYE, ESQ.</p>
                <p>
                  As I have had, Mr. President, no ambitious views to serve, I have been content during the session of this body to remain a silent listener. It is true, that, from time to time, I thought that I might throw out a suggestion that would avail something and sought opportunity to do so, but being unversed in the tactics of deliberative bodies, I always failed to seize the proper
                  occasion, and found myself forestalled. It is well that it has so occurred, for in the sequel I found, when opportunity offered, that what I proposed to say, had already been better said by others.
                </p>
                <p>I should have remained a silent listener to the end of the session, but events have crowded upon us and culminated in a crisis, in which it does not comport with my sense of what I owe to myself, to record my vote in silence. I am at least unwilling to do so.</p>
                <p>
                  I came here, sir, representing a constituency of an entirely different type of politics, from those which I entertain; I felt, therefore, in all its force, the obligation which devolved upon me to sink my individual predilections when they tended to oppose the will of those who sent me; and so far as party politics are concerned, I can say with truth that I am ignorant of the
                  political affinities of most of the members of this body. I am equally ignorant of the sectional classification of its members. There are, it is true, some whose prominence of character has forced a knowledge of their locality upon me, however studiously I sought to remain ignorant, and that knowledge, added to what I have heard from them in debate, has inducted me to the
                  conclusion, that every consideration founded upon locality tending to influence our deliberations, should be blotted out and forever. I have, so far as knowledge of the fact can be gathered from what has transpired here, as much faith in the patriotism of members from the West, as in those from the East. I go further and say that the wisdom and statesmanship developed by the
                  debates to which I have listened, are not circumscribed by locality, and I trust that no sentiment or feeling, springing from sectional position and tending to produce discord, will find lodgment with any member to the extent of influencing his vote upon the question about to be taken.
                </p>
                <p>
                  I came here, sir, as the representative of no section; and whilst I admit that those who sent me have the right to make their mouth piece, it is as their mouth piece that I now here announce that I know no section-that I know no political party, and when, on a late occasion, at a full meeting of my countymen, I deemed it proper, because of instructions received from a portion of
                  them, to express my views upon the question of secession, viewed in its then existing surroundings, I urged them to relieve me, by instructions, from the responsibility, which I felt to be a burden, of deciding it for them. I stated, at the same time, that candor required me to add that, whilst for my own relief I sought their instructions, I should deem it unwise in them to grant
                  my request. With my views fully promulged they determined to take no action, but to submit to me the decision of the question as it might arise and with all the lights then before me.
                </p>
                <p>
                  Sir, with what views did I enter this body? What interest had I to serve? What was the Virginia sentiment that directed my action? The preservation of the Union-yes, the preservation of the Union in all its integrity. What were the views I entertained in regard to the possibility, if not probability, of achieving that result? I am of sanguine temperament. I did not realize it as an
                  insurmountable difficulty for this body to carve out such measures of compromise as would result in furnishing efficient guarantees upon all those questions, and the settlement of which caused its inauguration. I anticipated that the trouble with regard to the acceptance of the guarantees we might propose would be with the South, not the North; and what was the basis of my
                  calculation? In the absence of any personal knowledge of the extent of that fanaticism and folly at the North which have wrought results so disastrous to the Country, I concluded that the magnitude of her interest in preserving her connection with the South, was too controlling to yield to the dominion of prejudice.
                </p>
                <p>
                  With the South, the trouble sprang from the independent position then occupied by some two or three of the States, and the difficulty in our way consisted in so shaping the measures proposed by us as to satisfy those States that if accepted they would be adequate to vindicate their rights and protect their interests. But now the number of the seceded States is not three but
                  seven-with a government fully inaugurated, and with ability to sustain themselves. All my fond and sanguine calculations of reconstruction have failed, and the conclusion has been forced upon me that a difficulty, great in itself as it existed at the time I came here, has become insuperable. After the secession of the seven States, what remained to be accomplished by this body?
                  Why, so to shape our measures as would retain the balance of the confederacy in its integrity, and adequately to defend all the interests of our own state. This was rendered a matter of necessity by the course pursued at Washington; but now it is not a matter of option with Virginia whether she will remain in the Union. To my mind, the necessity of severing her connection is
                  manifest She has no middle ground to occupy. It is now a mere matter of time, and the question presents itself to us after this mode: Shall we, before we go out, secure cooperation on the part of those of our border States whose cooperation is essential to the maintenance of our position; or shall we take the chance of their cooperating with us after we shall have gone out, and,
                  probably, in the midst of a war waged against us by the North?
                </p>
                <p>
                  Sir, unprepared as we are, I have no hesitation on that question. Purpose is power, when coupled with high moral influence such as we possess. It is more potent than anything that could now be accomplished by negotiation, under the circumstances in which that negotiation would be conducted. I therefore have no hesitation as to the course to be pursued. But my main object in rising
                  here, after declaring my own position, was to say, that when I entered this body, I took it for granted that the results of our deliberations (however much we might differ in the progress of the investigation which led to them) would receive the undivided support of all the members. I thought that however much we may have been divided in the past into sectional parties; however
                  much we may have been divided in political views; however much we may have been subdivided into local associations, growing out of diversities of interest-all would sink into utter insignificance in view of the great issue now upon us, whose decision involves so much that is dear to us all. Discord in this body will prove a prolific source of discord out of it; and it behoves us to
                  guard against it by all the means in our power. Sir, can an occasion arise, is it possible for one to be conceived, in which the practical effect of that great truth "in concord there is strength," could be more fully illustrated or beneficially applied than the present? I will not at this time of trial, when the whole Confederacy is convulsed to its centre, refer to the causes
                  which have produced the sad results now upon us. They are wide-spread and numerous, and if we carry the elements of discord which have wrought these results into our own body, what can follow but evils infinitely more deleterious to the public weal than the sum of evils which existed at the time we entered this hall?
                </p>
                <p>
                  Let us then cheerfully yield our support to whatever line of policy the majority shall adopt, and let us as one man give force and efficacy to that action as the action of our State. Let nothing of prejudice or passion obtain. God in his providence sometimes so orders it in our voyage through life that the only light vouchsafed us should be coruscated in the wake of the boat that
                  bears us. It would seem that such are our present surroundings; but it were worse than impious to doubt the adequacy of the light to conduct us safely into port. Yet we are so constituted that often by the working of our passions a cloud is generated which obscures the true sources of the little light about us. I pray that you will not by such a display of criminal folly intensify
                  the darkening heavens into perfect opacity; for prejudice or passion indulged to excess by those to whom is committed a nation's destiny, mounts into crime. In coming then to a decision upon this momentous question, pregnant with the future weal or woe of our country, let all of feeling calculated to disturb the judgement be stilled, and let the decision, whatever it may be,
                  receive our undivided support.
                </p>
              </div>
              —
            </div>
            <p className="p-in-sp">
              As I have had no ambitious views to serve, I have been content, during the sessions of this body, to remain a listener. It is true that from time to time, had I thought I might throw out an idea which might avail something, I would have sought an opportunity to do so; but, being unaccustomed to such proceedings, I had always found myself forestalled. It is, no doubt, better that it
              should have been so, for I have frequently found that what I intended to say had been better said by others.
            </p>
            <p className="p-in-sp">I should have remained a silent listener to the end of this session, but events have crowded upon us, and have brought us to a crisis in which it does not become any man to record his vote in silence. I am unwilling, at least, myself, to do so.</p>
            <p className="p-in-sp">
              I came here, sir, representing a constituency of an entirely different type of politics from those which I entertain. I came here, therefore, at least under an obligation which of itself would be sufficient to cause me to sink everything in the way of individual predilections to carry <PageNumber num={95} /> out the will of that people; and, so far as
              party politics are concerned, I can say of truth, that I cannot speak of the political sentiments of twenty men within the hearing of my voice. I have sought to remain ignorant in that regard. I am about equally ignorant in regard to the sectional positions of the members who constitute this body. There are some members of a prominent character from different sections, in regard to
              whose residence, however much I may have sought to remain uninformed upon the subject, a knowledge has been forced upon me; and that knowledge, with what I have heard incidentally escape from themselves, has brought me to the conclusion that every consideration looking to locality or section should be blotted out, and for ever. I have, so far as a knowledge of the fact can be gathered
              from what transpired here, as much patriotism from the West as from the East; and I trust that no sentiment calculated to produce sectional division or sectional discord will escape the lips of any member here.
            </p>
            <p className="p-in-sp">Sir, permit me to say that I have not only seen wise developments, but have heard as much patriotism from the West as from the East in the course of the debates upon this question.</p>
            <p className="p-in-sp">
              I came here, sir, as the representative of no section, and, whilst I feel that those who sent me to make me their mouth-piece, it is as their mouth-piece that I announce here that I know no section; that I know no political party; and when, on a late occasion, I spoke to that constituency, because of instructions coming from different parts, I abjured them to absolve me from the
              responsibility of casting a vote when the time should come when I should cast it. A due regard to candor required me to tell them that, whilst I sought that direction, I condemned the instructions, and, upon that expression, I could not get one man to give me instructions. Knowing my views, they concede to me the responsibility of speaking in their behalf.
            </p>
            <p className="p-in-sp">
              Sir, with what views did I enter this body? What interests have I to serve? What was the Virginia principle that directed my action? The preservation of the Union-the preservation of this Union in all its integrity! What were the views which I entertained in regard to the possibility of accomplishing that object? It never once entered my head that there would be much facility in
              carving out such a measure by this body as would result in the fullest guarantees upon all these questions in reference to which the majority for a Convention had its origin. The only trouble that I anticipated was not in regard to the North, but in regard to the South. And what were the elements of my calculation? Not any local knowledge in regard to the extent of that prejudice which
              has wrought these results so disastrous to the <PageNumber num={96} /> country, but a knowledge that there was a controlling interest which the South would not be willing to sacrifice. There were only some two or three Southern States which had seceded at the out set, and the trouble was in reconstructing the Government upon the basis of those interests
              which it was our duty as a people to procure guarantees for from the North. Now the number of seceded States is not three, but seven, which have inaugurated a Government capable of sustaining itself. All my calculations of the feasibility of reconstruction have failed, and the conclusion has been forced upon me that a difficulty, great in itself as it existed at the time that I came
              into this body, has become insuperable.
            </p>
            <p className="p-in-sp">
              What was the next thing that could be accomplished? To shape our measures in such a manner as would retain the balance of this Confederacy in its integrity. This is rendered a matter of necessity by the course of those men at the head of the Administration at Washington. It is not a matter of choice now with Virginia what position she will take. Seeing that there is no middle ground
              now for Virginia to occupy, but that she must take one extreme or the other, the question addresses itself to us after this fashion-shall we, before we go out, secure a co-operation by which we may successfully maintain ourselves, or shall we take the chances of co-operation in the midst of war, with those people whom we have to rely upon in an eminent degree after we shall go out?
            </p>
            <p className="p-in-sp">Sir, I have no hesitation in that respect, unprepared as we are. Purpose is power when coupled with a high moral influence, such as we possess. It is more than anything that could now be accomplished by negotiation, under the circumstances in which that negotiation could be conducted. I, therefore, can have no hesitation as to the course to be pursued.</p>
            <p className="p-in-sp">
              But my main object in rising here, after declaring my own position, was to say, that when I entered this body, I took it for granted that the results of our deliberations, however much we might differ in our progress with the business, would meet the unanimous approval of all who engaged in the proceedings. I was satisfied, that however much we may have been divided in the past into
              sectional parties; however much we may have been divided in political views; however much we may have been divided into local cliques growing out of the large diversities of interest here represented, all would sink into insignificance in view of the great issue upon us, and which involves so much that is dear to us all. Discord in this body will prove a
              <PageNumber num={97} /> prolific source of discord abroad, and it behooves us to guard against it by all the means in our power.
            </p>
            <p className="p-in-sp">
              Sir, can an occasion arise-is it possible for one to be conceived-in which the practical effect of that great truth, "in concord will be found strength," can be more fully illustrated and more beneficially applied, than at the present? I will not, at this time of trial, when the whole Confederacy is convulsed to its centre, refer to the causes which have produced the sad results now
              upon us. They are widespread and numerous; and if you carry the elements of discord, which have wrought these results abroad, into your own body, what can follow? Evils ten times more deleterious to the public weal than that sum of evil that existed at the time that we entered this Hall. Let us cheerfully yield to whatever line of policy the majority shall mark out, and come as one man
              to give force and efficiency to their action as the action of our State. Let no prejudice or hostility exist. God, in his Providence, has so shaped our course that, in our voyage, we have nothing to guide us but that light which is coruscated from the light of the bark in which we sail. It were worse than impious to assume that that light was not adequate to conduct us safely into
              port, undisturbed by prejudice or passion. But, in the ebullition of the passions, there is a cloud of prejudice affiliated through which this light thunders imperfectly; and I beg you not to infuse into the mind that which will make it pungite passita, for I feel that when prejudice and passion are aroused by those who have the destiny of nations in our hands, it follows that danger,
              nay, ruin to the cause in which they are engaged, is inevitable. Let us avoid this, and unite in favor of the ordinance of secession.
            </p>
            <div className="speaker" id="sp3727"><span className="persName">Mr. GEORGE BLOW, JR.</span>, of Norfolk City—</div>
            <p className="p-in-sp">I feel perhaps, as fully and as deeply as any gentleman in this body, a sense of the responsibility which rests upon me in the vote which I shall soon be called upon to cast.</p>
            <p className="p-in-sp">
              I came to this Convention, sir, representing a Union constituency. It has been my purpose, so far as my humble abilities would go, to contribute to carry out as faithfully as I could the purposes and the object which I know that constituency desired when they elected me. I believed, sir, through the long period in which we have been engaged in our deliberations, that not only was it
              our duty, if possible, to conserve that Union under which we have lived so prosperously, but sir, I believe that it was practicable and that, by pursuing the course which had been chalked out and marked by this Convention, that our efforts would have been ultimately crowned with success. I believe <PageNumber num={98} /> now, that but for the interposition
              of the power which stands at the head of the Executive government of this nation, these efforts would have been triumphantly successful. Event has been treading so rapidly upon the heel of event within the last 30 days, as to render it utterly impossible for any man's mind to be fixed positively upon any particular line of action.
            </p>
            <p className="p-in-sp">The last two days, sir-the last forty-eight hours-have been filling with events which, if they have not produced impressions upon the minds of others, have produced a profound impression upon my mind.</p>
            <p className="p-in-sp">
              Sir, the hope of reconstructing this Government has passed away. The chance of reconstructing the Union is but a myth. It no longer exists but in the mind of the visionary. Sir, the last act in this drama has come upon us. The curtain has been, at last, lifted, and we now see in all its hideous deformity, the long concealed policy of that party, which, I fear, we all misunderstood. Be
              that as it may, we are now face to face with revolution, and with war, and I, for one, have ceased to look upon the question as a political question, or a question of reconstruction. I am now looking upon it as a military question. It is useless to disguise it. We cannot put the image away from us. We must regard it now as a military question, and a military question alone.
            </p>
            <p className="p-in-sp">I was profoundly influenced yesterday by the able and ingenious argument of the gentleman from Fauquier [<span className="persName">Mr. SCOTT</span>] .</p>
            <p className="p-in-sp">
              I left this House with my mind in a state of doubt upon the question. I could see the force of many of the positions which he assumed as to why we should look to co-operation rather to immediate secession. If the vote had been taken last night, impressed with the force of his argument and those of other gentlemen which have been offered upon the subject, I should have felt it my duty
              to have voted for his scheme of co-operation. I went home, sir, and took these arguments and examined them to the best of my abilities and judgment; aye, sir, with an invocation to Providence to aid me in the investigation; and the result was, a conclusion that this was not the policy proper to be pursued at this time and under existing circumstances.
            </p>
            <p className="p-in-sp">
              I feel impressed with the solemnity of the occasion. I feel impressed with the importance of the decision which this Convention is about to make. And, sir, I have brought my mind to the conclusion that at this hour, as a matter of prudence, as a military question, as a question involving the possible existence of our Commonwealth, and of all our interests, the argument on the other
              side is the best, and most entitled to consideration. Under these circumstances, sir, although I am <PageNumber num={99} /> representing a Union constituency, and don't know what change has been produced upon their minds by recent events-nor can I now wait for instruction from home-yet I will do what I believe my gallant constituency would have me to
              do-record my vote according to my judgment, with reference to the interests of this State; and that, sir, will be that Virginia shall assume the power which she has granted to the General Government.
            </p>
            <p className="p-in-sp">
              Mr. President, what is the argument in favor of co-operation? It is that we may invite all the slave States in one consolidated mass for the purpose of resisting this horde of Hessians which is soon to be thrown upon our coasts. This will take time; aye, and our action may lead to misconstruction, so far as those States are concerned. I hold that it is important that they should know
              at once what Virginia's position is, and that our friends in those States should have the advantage of the moral argument of Virginia's position. Let them feel and know that we are in earnest. Let those who are determined upon resistance know that Virginia has led the van, and then, sir, we can co-operate just as well out of the Union as in the Union. We can co-operate with greater
              force and effect when we have taken our position. We can strengthen all those who are weak. We can confirm all those who are doubtful; and, sir, bring about more rapidly that co-operation which we all look to.
            </p>
            <p className="p-in-sp">
              As I understand the arguments which have been urged, they both lead to the same end. The object is, to bring all the slave States in solid column, shoulder to shoulder in this contest. How is it best to accomplish that? I am compelled to come to the conclusion, that it will be more efficiently accomplished by Virginia's taking a decided stand now, than by waiting for conference with
              the border States. If, in accordance with the argument presented by the gentleman from Fauquier
              [<span className="persName">Mr. SCOTT</span>]
              , you throw this contest among your own people by offering alternate propositions to be submitted to the people to make a choice between them, what is the impression to be produced upon the Border States? Will not an impression of doubt be produced with reference to Virginia's position? And what will be the effect produced upon the Northern mind? Why, sir, if Virginia presents herself
              in a condition of doubt in this emergency, shall we not stimulate them to increased exertion by throwing out a false hope which our ultimate purpose does not justify? What is to be the effect produced upon your own people? It will paralyze their efforts and dispirit them at the outset of this contest. Now is the time for our column to be closed up in solid mass. Now is the time for our
              people to stand shoulder to shoulder and fight for the purpose of resisting this oppression. <PageNumber num={100} /> We cannot retreat. The bridge is cut away. We are compelled to resist. We stand pledged to resist; honor requires it; self-interest and self-preservation require it. Then, sir, under these circumstances, shall we throw in a question of
              doubt and difficulty, to be contested and canvassed throughout the State of Virginia for the next two months? I trust not, and hope it will be the pleasure of the Convention to pass the Ordinance of Secession proposed by the gentleman from Montgomery
              [<span className="persName">Mr. PRESTON</span>] .</p>
            <div className="speaker" id="sp3728"><span className="persName">Mr. SAMUEL MCDOWELL MOORE</span>, of Rockbridge—</div>
            <p className="p-in-sp">Mr. President, I did not intend to say a word upon the question now before the Convention; but on reflection, I feel that it is due to myself that I should take this occasion to say a few words in explanation of the position which I now occupy and design to occupy.</p>
            <p className="p-in-sp">
              I will advert for a few minutes to the cause which has brought us to our present condition. I presume it will be admitted on all hands, that the immediate cause is the attack which was made upon Fort Sumter. I presume it will not be denied that when a proposition was made to Major Anderson to surrender that fort, he informed the authorities of South Carolina, that he had no more
              provisions than would last him for two or three days. It is equally well ascertained that no provisions could be sent to him-the fortifications which were erected in the harbor being such as to preclude the possibility of sending him reinforcements or supplies.
            </p>
            <p className="p-in-sp">
              Why, then, was that attack made? I regret that the attack had been made. If the fact exists that South Carolina was guilty of the inhuman act of attacking, with her best men, a fort in which there was but a force of sixty or seventy men, the public will be at no loss to decide where the responsibility of the existing state of things lies. And still so it is. This attack was made upon a
              handful of half-starved soldiers, and a war thus inaugurated. I repeat, that the responsibility for what is now likely to occur, deservedly rests upon those who made the attack and those who advised the attack to be made. I understand that an advice was publicly given to make the attack, and with a view of drawing Virginia out of the Union.
            </p>
            <p className="p-in-sp">
              On the other hand, no man can view the conduct of the present Administration with more contempt and indignation than I do. I regard the whole course of Lincoln as marked by duplicity and an utter want of candor and honesty. He has pretended that he was for peace when nothing was farther, in my opinion, from his purpose than that peace should be preserved. He pretended that honor
              required him to hold these forts, and now pretends that it requires that he should <PageNumber num={101} /> repossess himself of them. For what purpose does he want to hold these forts? I apprehend sir, that he has no purpose of maintaining the peace, but that he means to hold on until he makes preparations for conquering the Southern States. I have reason
              to suspect that the intentions of this Black Republican party look to the conquest of the whole South; and it is this fact alone, which, in my opinion, justifies the steps likely to be taken by Virginia now.
            </p>
            <p className="p-in-sp">I prefer the proposition submitted by the gentleman from Fauquier [<span className="persName">Mr. SCOTT</span>]
              , because I regard this thing of immediate secession as an open declaration of war. The other proposition will give some time to prepare for a war which I conceive is inevitable. I look upon secession too as unquestionable, and the only difference therefore is, that our declaration of immediate secession is a declaration of war at once, which will bring upon us the forces of the
              Northern States. On the other hand, if we adopt the proposition of the gentleman from Fauquier
              [<span className="persName">Mr. SCOTT</span>]
              , it will give us time to send to Europe for that powder which it seems we are entirely destitute of. It will give us time to unite with the border States and the seceded States; and I am ready now at this moment to send agents to the Government at Montgomery, and to each of the border States, with a view to concert measures to carry on the war which I now regard as inevitable.
            </p>
            <p className="p-in-sp">I fully concur with the views expressed by the worthy gentleman to my left [<span className="persName">Mr. B</span>Low]
              , as to what course we should pursue. After our measures are determined upon, I think we should disregard all previous differences and prepare to go into the war with one heart and one opinion, and I trust it will be done throughout Virginia. I trust, sir, that there will be no effort anywhere to hold back, or to throw any obstacle in the way of perfect unanimity in all that concerns
              our future welfare and safety. I wish that provision may be made for calling out the volunteer companies in every county in the State, in order that we may be prepared for the war that is now evidently upon us.
            </p>
            <div className="speaker" id="sp3729"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">I beg leave to present, for the consideration of the Convention a communication :</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>EXECUTIVE DEPARTMENT, April 17th, 1861.</p>
                <p>Gentlemen of the Convention:</p>
                <p>I feel it to be a duty to furnish to you the present volunteer force, as nearly as it can be arrived at from the returns made to the Adjutant <PageNumber num={102} /> General. This information is embodied in the Report of the Adjutant General of this date, herewith presented.</p>
                <p>
                  I communicate also, from the Ordnance Department, a report showing what steps have been taken to meet pressing wants. From this report it will be seen that we have ordered 282 additional barrels of powder, and have sent the Master Armorer to the North to procure arms for infantry, cavalry and artillery, all of which we expect to be able to procure in the course of a week or ten
                  days.
                </p>
                <p>Last summer I directed 10,000 flints to be purchased. These were obtained, and since that time I have directed the purchase of ten thousand additional flints, which have been also procured.</p>
                <p>Six of the new rifled cannon are mounted, and in eight or ten days we expect to have enough mounted for the use of the artillery of the State.</p>
                <p>Respectfully,</p>
                <p>JOHN LETCHER</p>
                <p>ADJUTANT GENERAL'S OFFICE,</p>
                <p>April 17, 1861.</p>
                <p>His Excellency JOHN LETCHER, Governor of Virginia:</p>
                <p>
                  SIR-The following is a statement of the present Volunteer force of the State, as nearly as can be arrived at from this office :<span className="note" id="Note13"><span className="noteLabel">2</span>The Total Cavalry figure below, and on p. 92, ought to be 4,800.</span>
                </p>
                <p>CAVALRY</p>
                <p>Seven troops, armed with sabres and cavalry percussion</p>
                <p>pistols, U. S., rank and file, 350</p>
                <p>Two troops, armed with sabres and cavalry musketoons, 100</p>
                <p>Thirty-six troops, armed with sabres and revolvers, 1,800</p>
                <p>Twenty-two troops, armed with sabres only, 1,100</p>
                <p>Armed, 3,350</p>
                <p>Twenty-nine troops unarmed, 1,450</p>
                <p>Total Cavalry, 4,900</p>
                <p>ARTILLERY</p>
                <p>Twelve companies armed with 6 pound field guns, with carriages and implements complete, and 11 of them with Artillery swords, 600</p>
                <PageNumber num={103} />
                <p>One company armed with 6-pound field-pieces, swords and</p>
                <p>Sappers and Miners' musketoons, 50</p>
                <p>One company armed with same, and Artillery musketoons 50</p>
                <p>One company armed with six 12 pound howitzers and Light Artillery sabres, 80</p>
                <p>Armed,</p>
                <p>780</p>
                <p>Thirteen companies unarmed, 650</p>
                <p>Total Artillery,</p>
                <p>1,430</p>
                <p>LIGHT INFANTRY</p>
                <p>Seven companies armed with rifled muskets, 440</p>
                <p>Eighty-one companies armed with percussion muskets, 4,050</p>
                <p>Twenty-six companies armed with flintlock muskets, 1,300</p>
                <p>Armed,</p>
                <p>5,790</p>
                <p>Five companies unarmed, 250</p>
                <p>Total Light Infantry,</p>
                <p>6,040</p>
                <p>RIFLEMEN</p>
                <p>Four companies armed with long-range rifles, 330</p>
                <p>Twenty-eight companies armed with percussion rifles, 1,400</p>
                <p>Ten companies armed with flint-lock rifles, 500</p>
                <p>Armed,</p>
                <p>2,230</p>
                <p>Seventy-six companies unarmed, 3,800</p>
                <p>Total riflemen,</p>
                <p>6,030</p>
                <p>
                  This varies in some degree from my report of 27th February last, by some additional troops and companies in each arm having received arms, and some new companies having been commissioned since then. Except in a few cases, where the actual strength of particular troops or companies is known at this office, I have estimated all other troops and companies at the minimum number
                  required by law, discarding the return, which would increase the force. The Cavalry force is unnecessarily large; the Artillery much too small, though additional companies are organizing. It is far too weak in the tide-water region.
                </p>
                <p>There are in depot at the Armory 200 long-range rifles, and at the Lexington Arsenal 1,007 excellent flint-lock rifles.</p>
                <PageNumber num={104} />
                <p>I respectfully suggest the expediency of organizing in the West, as soon as possible, a Rifle force of picked men.</p>
                <p>Very respectfully,</p>
                <p>Your obd't servant,</p>
                <p>WM. H. RICHARDSON, A. G.</p>
                <p>RECAPITULATION</p>
                <p>Cavalry armed, 3,350 Total.</p>
                <p>" unarmed, 1,450</p>
                <p>4,900</p>
                <p>Artillery armed 780</p>
                <p>" unarmed, 650</p>
                <p>1,430</p>
                <p>Light Infantry armed, 5,790</p>
                <p>gc unarmed, 250</p>
                <p>6,040</p>
                <p>Riflemen armed, 2,230</p>
                <p>t4 unarmed, 3,800</p>
                <p>6,030</p>
                <p>Total Volunteer force, rank and file, 18,400</p>
                <p>ORDNANCE DEPARTMENT,</p>
                <p>RICHMOND, VA., April 16, 1861. His Excellency Governor LETCHER:</p>
                <p>SIR-The following advertisement and orders for munitions of war were issued from this department, from the 12th instant to this date :</p>
                <p>ORDNANCE DEPARTMENT,</p>
                <p>RICHMOND, VA., April 12, 1861.</p>
                <p>Sealed proposals will be received at this Office, up to 12 o'clock M., on the 10th of May next, for furnishing the following articles:</p>
                <p>For altering 5,000 Flint-Lock Muskets into Percussion.</p>
                <p>5,000 best Rifle Muskets-Calibre, English or American, 57 or 58. 2,000 best Revolver Pistols, Navy or Army size.</p>
                <p>1,000 Cavalry Carbines, breech-loading, Army size.</p>
                <p>1,000 Light Cavalry Sabres.</p>
                <p>1,000 United States Artillery Sabres.</p>
                <PageNumber num={105} />
                <p>8 12-pounder Howitzers.</p>
                <p>3 24-pounder Howitzers.</p>
                <p>10 6-pounder Gun Carriages, with Caissons.</p>
                <p>8 12-pounder Gun Carriages, with Caissons.</p>
                <p>8 12-pounder Howitzer Carriages, with Caissons.</p>
                <p>3 24-pounder Howitzer Carriages, with Caissons.</p>
                <p>4 Battery Wagons.</p>
                <p>4 Travelling Forges.</p>
                <p>60 Sets of Artillery Harness [4 horses to a set] .</p>
                <p>80 Haversacks, for Artillery.</p>
                <p>5,700 6-pound Shot-per pound.</p>
                <p>2,400 12-pound Shot-per pound.</p>
                <p>3,000 12-pound Howitzer Shells, ready to be charged and fused-per piece.</p>
                <p>900 24-pound Howitzer Shells, ready to be charged and fused-per piece.</p>
                <p>1 Bullet Machine, for Conical Balls.</p>
                <p>1 Percussion Cap Machine.</p>
                <p>500 Tents [Sibley's.] 1,000 Spades. 1,000 Axes.</p>
                <p>1,000 Pick-axes. 1,500 Hatchets. 1,500 Camp Kettles.</p>
                <p>4,000 Mess Pans. 10,000 Canteens. 8,000 Knapsacks. 8,000 Haversacks [Infantry] .</p>
                <p>8,000 Cartridge Boxes.</p>
                <p>8,000 Bayonet Scabbards.</p>
                <p>10,000 Percussion Cap Pouches.</p>
                <p>20,000 Brass Plates, for Infantry Belts.</p>
                <p>6 Tons of Lead, ready for a Bullet Machine.</p>
                <p>4 Tons Pig Lead.</p>
                <p>These articles must be of the best materials and make, and according to samples, patterns and drawings to be seen at the Armory, in this city.</p>
                <p>The shortest time possible will be required, within which the articles in whole, or in part, must be delivered at the Armory, where they will be inspected by an officer of the Department, or an agent thereof.</p>
                <PageNumber num={106} />
                <p>The Department reserves the right to reject all bids.</p>
                <p>CHAS. DIMMOCK,</p>
                <p>Colonel of Ordnance</p>
                <p>NOTE-For the Muskets, Pistols, Carbines, Sabres, Bullet Machine and Cap Machine, proposals must be handed in by 12 o'clock M., on the 25th of April next.</p>
                <p>APRIL 12, 1861-Powder ordered this day from E. Wortham &amp; Co. 150 barrels musket powder, of 100 pounds each; 50 do. common powder, of 100 lbs. each.</p>
                <p>An enquiry was addressed this day to the agent for the sale of the Portable Field Cooking Stove, Broadway, New York, as to the price of 240 Stoves, and the time in which they could be furnished. [No answer received.] An order was issued to-day to S. Cottrell &amp; Co., for the manufacture of 2,000 cap boxes, 2,000 bayonet scabbards, and 2,000 cartridge boxes.</p>
                <p>APRIL 13, 1861-Powder ordered from Wortham &amp; Co., 82 barrels of musket powder.</p>
                <p>APRIL 15, 1861-The Master Armorer was to-day ordered to proceed to the North and purchase :</p>
                <p>5,000 Rifle Muskets.</p>
                <p>1 Bullet Machine.</p>
                <p>1 Percussion Cap Machine.</p>
                <p>1,000 Cavalry Sabres.</p>
                <p>1,000 Infantry Sabres.</p>
                <p>Also, to purchase one horse and one wagon for the Armory.</p>
                <p>APRIL 16, 1861-The following advertisement was this day published in the Richmond "Whig," "Enquirer" and "Dispatch."</p>
                <p>ORDNANCE DEPARTMENT,</p>
                <p>RICHMOND, VA., April 16, 1861.</p>
                <p>Bids for making ten Gun Carriages, with Caissons, will be received up to 12 o'clock M., Saturday, the 20th inst., at this office.</p>
                <p>C. DIMMOCK,</p>
                <p>Colonel of Ordnance. Very Respectfully,</p>
                <p>CHARLES DIMMOCK,</p>
                <p>Col. of Ordnance.</p>
              </div>
            </div>
            <PageNumber num={107} />
            <div className="speaker" id="sp3730"><span className="persName">Mr. JOHN S. BURDETT</span>, of Taylor County—</div>
            <p className="p-in-sp">
              It will be borne in mind that I have not occupied fifteen minutes of the time of this Convention since its commencement. I do not rise now to speak with a view to give myself notoriety, nor merely for the sake of speaking. I feel perfectly the solemnity of the occasion, and feel also that I would be recreant to myself, to my duty, to my constituents, were I not to say a word or two
              before the vote is taken on the Ordinance of Secession.
            </p>
            <p className="p-in-sp">
              I feel to day more like being in the midst of a funeral cortege, than being in a Convention, when I witness the scene which is here presented, and contemplate the fearful step which is about to be taken. I feel that we are now about to destroy this great nation, which was the hope of the oppressed, and as I believe would be the rock of our safety for all future time. In contemplating
              those evils, I have asked myself what great sin we have committed, that God should so afflict us. I came here representing a loyal constituency of free men-a constituency full of Union sentiments; aye, sir, and half of those who voted against me, I know to be Union-loving men, as true as any in the world. I have had no communication with them for the last week, that would inform me
              whether there was any change of sentiment among them. I concur fully with the views of my friend from Marion
              [<span className="persName">Mr. HAYMOND</span>]
              , as to the deplorable state of things in that country-our secluded condition, without arms or munitions of war. The county I represent has a population of 7000, and if we have a rifle in it, I am not aware of the fact. We have no volunteer company in it, and but little use in organizing them, since we are entirely destitute of arms and the other necessary equipments.
            </p>
            <p className="p-in-sp">
              I rise now to protest, in God's name, against the passage of this Ordinance of Secession. Give us the proposition of the gentleman from Fauquier, in preference to this Ordinance, which is tantamount to a declaration of war. It is not a mere matter of choice with us whether we take side with you or not. We will be overwhelmed I fear in any event, and will doubtless be taken prisoners
              before two weeks. We have no means to protect ourselves, and we will necessarily have to submit. Yet gentlemen do not seem to apprehend our condition, and will not listen to our accounts of it. Indeed, a gentleman on this floor
              [<span className="persName">Mr. GOODE</span>, of Mecklenburg] , said a few days ago that he and those associated with him would pass this Ordinance of Secession regardless of Western Virginia and her 20,000 population. I will say nothing in reply. Be the circumstances what they may, we are Virginians, and we feel disposed to unite with her.</p>
            <PageNumber num={108} />
            <p className="p-in-sp">But I would beg of you to respect your pledge and your position. Give us the proposition of the gentleman from Fauquier, and let the people say whether they will secede or not.</p>
            <div className="speaker" id="sp3731"><span className="persName">Mr. HENRY L. GILLESPIE</span>, of Fayette and Raleigh counties—</div>
            <p className="p-in-sp">I certainly do not desire to thrust myself upon this body, and do not intend to trouble them but for a brief space of time.</p>
            <p className="p-in-sp">
              It seems to me now, sir, an established fact, about which there is no doubt now in any quarter, that we are in the midst of war. For one, I confess to utter surprise at the response given by the President of the nation to our Commissioners, and especially was I surprised, and I might say indignant, on reading his proclamation. Both reveal, to my entire satisfaction, his purpose to be
              war.
            </p>
            <p className="p-in-sp">
              I came here with strong hopes that our difficulties would be adjusted. I cherished these hopes up to a very recent period. The reading of this document, I confess, fell upon my ear like an avalanche. The reading of this document crushed out the last ray of hope which I entertained of averting a dissolution of this Union. The reading of that document filled my mind with a violent
              commotion and agitation, which has not ceased from that moment to the present. I saw at once that war was upon us, and the only thing remaining for us to do was to determine how or in what mode we should conduct that war so as best to protect the interests of Virginia-of the whole of Virginia-Western Virginia, Northern Virginia, Central Virginia, and Eastern Virginia. It seems to me
              that that is the only question now before this Convention-how shall we best protect the interests of all Virginia? And it would seem to me, that in order to protect the interests of the whole of Virginia, we should adopt such measures, if they are within the reach of this Convention-as I presume they are-as will harmonize the whole of Virginia; for whilst I know that no portion of this
              Convention would be so unkind, so unnatural, so ungrateful to any portion of the State, as to disregard their interests wilfully, to pass them by without according to them such measure of relief as they would deem practicable, yet I think there is a probability that they may unintentionally sacrifice the best interests of those upon whom, in a great measure, they have to rely in the
              future.
            </p>
            <p className="p-in-sp">
              It seems to me, that of all things under Heaven that is most to be lamented under existing circumstances, it is the idea on the part of any portion of the people of the Commonwealth, that their interests have been neglected or discarded. However erroneous that opinion may be; however unfounded it may be; if you permit it to find a lodgment in the minds of the people; if any individual
              in any portion <PageNumber num={109} /> of the Commonwealth finds ground for any such opinion, it will produce dissatisfaction, and alienate the support and sympathy of a large number of our population.
            </p>
            <p className="p-in-sp">
              And, I confess, that the appeals made to us by members from portions of this State are no military fictions. I believe they feel that, in the next recess, the complaints they are uttering here will be fully realized. I believe they feel that these are not mere apprehensions, but stern realities. They believe that if an ordinance of secession be now adopted, they are at once to be
              overthrown. They believe, for I so understand from remarks made here, that the substitute of the gentleman from Fauquier
              [<span className="persName">Mr. SCOTT</span>] will relieve them if it is adopted.</p>
            <p className="p-in-sp">The only question before the Convention then, is a choice between these two propositions. The ordinance of secession is offered to us by the gentleman from Montgomery [<span className="persName">Mr. PRESTON</span>] , and the substitute is offered to us by the gentleman from Fauquier [<span className="persName">Mr. SCOTT</span>] . I take it, then, that unless there be something in the substitute which is exceedingly unpalatable to gentlemen upon the floor of this Convention, they will vote for it.</p>
            <p className="p-in-sp">
              I may be told that the substitute will afford no protection to gentlemen in the North-west; that it is a mere chimera that the Northern States will not harmonize with them; that you cannot get Ohio and the adjoining States to go with the people of the North-west. Well, be it so. These men think they will. They think they can procure the co-operation of these States. They think they
              will be sufficiently protected, if you accord to them this alternate proposition offered by the gentleman from Fauquier
              [<span className="persName">Mr. SCOTT</span>] . If you do so, you will bring about harmony and conciliation. If you offer them this alternate, they become satisfied. They will say that Eastern Virginia has done for us all that she could do. She has taken into consideration our imperfect means of protection, and we feel grateful for the favor.</p>
            <p className="p-in-sp">I beg gentlemen, who entertain different views, not to take this matter lightly and hurriedly. I heard a gentleman on this floor, whose patriotism I don't doubt for a moment, say, on yesterday, in response to the gentleman from Augusta [<span className="persName">Mr. STUART</span>] - [<span className="persName">Mr. SUTHERLIN</span> is the gentleman alluded to, according to the reporter's idea] -that he would fight every man who opposed him; I understood him to go to the extent of saying, that he would fight his own brother if he would offer any interposition to the mode of adjustment which he thought the most advisable.</p>
            <PageNumber num={110} />
            <p className="p-in-sp">
              Sir, if you are willing to lay violent hands even upon a beloved brother for offering resistance to those measures which you think will redound to your safety and protection, are you not ready to accord to other members of this Convention such measures as they may think will result in their protection? I trust that such a consideration will actuate every member here, and that they will
              adopt the substitute, and thus secure harmony among all.
            </p>
            <div className="speaker" id="sp3732"><span className="persName">Mr. MARMADUKE JOHNSON</span>, of Richmond city—</div>
            <p className="p-in-sp">Mr. President, I desire to say a few words to the Convention under the present melancholy circumstances which attend our deliberations.</p>
            <p className="p-in-sp">
              I was elected a member of this Convention, sir, as a Union man. I have earnestly and anxiously contended, on this floor, since the commencement of our session, for the passage of every law which I thought would contribute to perpetuate the freedom which we heretofore enjoyed under this Union. I know not now, sir, how to express myself, except that I deem it necessary, in contemplation
              of the action which I expect to take, to say something in its vindication.
            </p>
            <p className="p-in-sp">
              Sir, I have resisted with some tenacity, and, as some said, with some contumacy, the outside pressure which has thrown its influence upon me with peculiar force during the session of this Convention. As I understand the action of this Convention, it has declared, by a very large majority, in favor of the 12th section of the report of the Committee on Federal Relations, which proposes
              to submit to the Northern people certain amendments to be incorporated in the Constitution of the United States, and if they were not accepted by satisfactory responses, that we should feel constrained to resume our delegated powers; and in the very next section this Convention has declared that, as an indispensable condition, they would wait a reasonable time for response, the Federal
              Government, meanwhile, to abstain from aggression.
            </p>
            <p className="p-in-sp">
              Now, what have we done? For four months we have intervened between the Seceded States and the Northern States, and we have succeeded in preserving the peace until lately. We have sent Commissioners to Washington; we have communicated with them by telegraph; we have communicated with them by correspondence; and we have but a few days since sent Commissioners to Washington, and these
              Commissioners have returned to us with war sounding in their ears from the very lips of the President himself.
            </p>
            <p className="p-in-sp">
              Thus, sir, this Convention, by its own action, stands committed to the doctrine of the immediate resumption of power; nay, more, sir; the President of the United States followed the message which he sent <PageNumber num={111} /> to this body by your Commissioners with a proclamation of war; nay, more than that still; he followed that proclamation of war
              with a requisition upon Virginia for her quota of troops to aid in subjugating her Southern sisters. I say, then, that this Convention stands committed to an immediate resumption of the delegated powers of the Commonwealth.
            </p>
            <p className="p-in-sp">
              No man mourns the necessity for such a resort more than I do. It is with a sad and sorrowing heart; it is with a feeling of grief and melancholy, that I would ever sign an ordinance of secession. And, if I regarded the act of secession as a declaration of war, I would rather that we should suffer eternal perils than do so. But a war is now upon us; and we are necessitated to determine
              which side of that war we will take. It is a melancholy state of affairs; because there is no privilege of choice between peace or war. It is a sad necessity, and one which we must obey. I know it is fraught with dangerous consequences, and attended with melancholy disasters; but it must be undertaken. We are reduced to the necessity of accepting the war issue. I, for one, say that the
              Virginia banner shall float over Southern soil, and that Virginia's sons shall fight under that banner and no other. I would to God that I could come to some other conclusion. I would to God that my Heavenly Father would grant me grace to execute the justice and to maintain the truth, for, in the infirmity of my poor judgment, I believe that the Northern people have forced this issue
              upon us, and, that they deserve a just punishment, and a heavy retribution for their course in the past. For my part, I see no other alternative, than that Virginia shall immediately dissolve the connection which exists between her and the Federal Government.
            </p>
            <p className="p-in-sp">
              Now, sir, I appeal to my Western brethren to remember that they alone are not exposed to the attacks of the enemy. We are all exposed, from the waters of the Chesapeake, which wash the Eastern shores, to those of the Ohio, which ripple by the banks and green fields of Western Virginia. We all are exposed alike. Let us all be equally willing to bare our breast to the storm. I pray
              gentlemen to remember that when we can we will come to their rescue; and I know that when you can you will come to our rescue, if need be. As long as the sun of Heaven shall play over our mountains, let us go together. With one mind, one heart, one soul, and a willing co-operation on the part of all-with but a single thought and "two hearts which beat as one"-we must inevitably
              triumph.
            </p>
            <p className="p-in-sp">I have given these sentiments because I thought it proper to make some explanation of the vote I shall give. I trust that, whatever we <PageNumber num={112} /> may do, shall be done in a harmonious disposition, to forget all past differences, and act cordially together in the future.</p>
            <div className="speaker" id="sp3733"><span className="persName">Mr. ALLEN T. CAPERTON</span>, of Monroe—</div>
            <p className="p-in-sp">Mr. President, I desire to address myself to the only point of difficulty which seems to me to be now arresting the action of this body. I believe there is a general concurrence as to one opinion, and that is, that there shall be secession. Whether immediate or at a more distant day is the only question in dispute.</p>
            <p className="p-in-sp">
              It is proposed, sir, that we shall postpone this movement with a view to have a conference with our sister border slave States. With what view, sir? What is the object of the Conference? Co-operation? If that is the design, sir, and the only design, then the end is already achieved. We have that co-operation this day. The newspapers have this morning announced that this co-operation
              was forthcoming. The Governors of several of the States, in their responses to the requisition made upon them by the President for their respective quotas of volunteers, have declared their unwillingness to unite with the President of the United States, in this war upon Southern rights, and that throws them at once into line with Virginia, so that, it seems to me, a work of
              supererogation to ask for this conference.
            </p>
            <p className="p-in-sp">Sir, if you cannot have this co-operation by the clash of arms, I ask, gentlemen, when are they to get it by conference? Sir, the only result of the adoption of the measures proposed by the gentleman from Fauquier [<span className="persName">Mr. SCOTT</span>]
              , will be to produce delay and disaster. Let us repudiate that proposition and come at once to the only alternative which is presented to us. Let us act unitedly and harmoniously, in order to give the utmost moral power and influence to our action in other parts of the country. I believe if Virginia were to secede to day, and the ordinance of secession were supported by anything
              approximating a unanimous vote of the Convention, that if anything tended to stay the aim of the Federal Government it would be this act of secession.
            </p>
            <p className="p-in-sp">What good is to be effected by delay? Are we to be in a better condition by and by than we are now? The gentleman who offered the substitute [<span className="persName">Mr. SCOTT</span>] referred to the state of our finance. Are our bonds to be in more credit in six, eight or twelve months from this time than they are now? Is not our pecuniary condition becoming every day and hour worse and worse? Then, sir, nothing is to be gained by that policy.</p>
            <p className="p-in-sp">
              Several of my North-western friends refer to their defenceless condition. How is that to be helped? They, I understand, are of opinion <PageNumber num={113} /> that we must either now or at some distant period separate. Where are these defences to be. Are we to submit to oppression until that remedy is afforded? I say we will not, we cannot, we dare not
              wait upon a suggestion thrown up along that line of border? How is this defect complained of to be remedied ?-of that sort.<span className="note" id="Note14"><span className="noteLabel">3</span>The meaning is unclear.</span> I myself deplore the condition of all who live on the frontier of our State.
            </p>
            <div className="speaker" id="sp3734"><span className="persName">Mr. WM. G. BROWN</span>—</div>
            <p className="p-in-sp">I did not suggest a moment's delay from the cause referred to by the gentleman. I said it was fixed that the State would secede.</p>
            <div className="speaker" id="sp3735"><span className="persName">Mr. CAPERTON</span>—</div>
            <p className="p-in-sp">
              I am happy to hear it. Let me now say to the gentleman, if the passage of the ordinance is fixed, is it not his duty, as a good citizen, a Virginia-loving citizen, to give all the effect to that ordinance which it is possible for it to have? Should he not unite with us by giving his vote in order to create that powerful impression which it will have if it goes forth as the unit work of
              the Virginia Convention? I would ask all my friends from the North-west if they see that secession is determined upon; if they see that Virginia must inevitably be involved in a war-is it not their duty, as good citizens, to unite now with us in that movement, so as to make it as effectual as possible? It seems to me they ought to unite with us. And why should they not do so? Is it a
              criminal proceeding upon the part of the people of Eastern Virginia to stand up in support of their rights? They are all united to a man in the Eastern portion of the State. They are inevitably committed to secession-will you desert them?
            </p>
            <div className="speaker" id="sp3736"><span className="persName">Mr. BROWN</span>—</div>
            <p className="p-in-sp">I expect myself to fight, and I will endeavor to influence North-western Virginia to do the same thing.</p>
            <div className="speaker" id="sp3737"><span className="persName">Mr. CAPERTON</span>—</div>
            <p className="p-in-sp">
              I have no doubt the gentleman will fight; but I invoke him now to come up by his vote by uniting in the action now, and give effect to this first war movement which is endangering the lives and fortunes of all the citizens of Virginia, whether East or West. I believe, sir, that if there is anything under the sun which by possibility may arrest this terrible catastrophe of war, it will
              be a union among ourselves and a unanimous vote in this Convention in favor of the ordinance of secession. I see nothing else that may save us from that disaster; and I hope and trust it will be the sense and the pleasure of the gentlemen from the Western portion of the State to unite in this measure, and not let our enemies in the North be backed by assurance given by a divided vote
              here that Virginia is not united-that Virginia cannot maintain this war because there are domestic difficulties-because there is a prospect of civil war within her own limits, which will need all her power and energies to put down. That <PageNumber num={114} /> is to be the effect which is to be produced by any appearance of division here.
            </p>
            <p className="p-in-sp">
              I appeal to gentlemen-I implore them to come now and put their hands to the work, and aid if possible, if not in averting this war, in maintaining it. This is all I have to say. I have troubled this Convention but little since its commencement. I have not engaged in those discussions which have occupied our time for months. But, sir, now is the time for action. I could not suppress the
              inclination I felt to make one appeal, at least to my Western friends, to come up and aid us in accomplishing what we are now about to engage in. War is upon us, and we are compelled to make the best of it. Let our Western friends, by all means, assist us in so doing.
            </p>
            <div className="speaker" id="sp3738"><span className="persName">Mr. SAMUEL WOODS</span>, of Barbour County—</div>
            <p className="p-in-sp">No member of this Convention approaches the consideration of this question more deeply embarrassed by a sense of its overwhelming importance, than I do.</p>
            <p className="p-in-sp">Coming from the exposed portion of Virginia, known as Northwestern Virginia, I cordially tender to my friend who has just taken his seat, my hearty co-operation and the hearty co-operation of a people unsurpassed by any within the broad limits of Virginia, in their zeal and fidelity to her interest.</p>
            <p className="p-in-sp">
              Sir, unpleasant as is this conviction that is now forced upon every mind, I acknowledge that it has been preying on my conscience ever since this Convention assembled, that the greatest error that we could commit, and that we have committed was, in failing to take that position in the beginning which circumstances have compelled us to take to-day. Every time I heard it announced upon
              this floor that the people of Virginia had no right of secession; every time I heard it announced that the people of the Southern States were traitors, and rebels, I felt as if the death knell of the liberties of the people of Virginia was sounded in my ears! for I felt, and every man feels it now-that secession is necessary, that it is the very life, the very soul, and the protection
              of the liberties of the people of Virginia.
            </p>
            <p className="p-in-sp">
              Mr. President, since I last opened my mouth in this Convention, events have hurried rapidly forward, and we are now standing upon the verge of civil war-civil war that we deplored-civil war that we have not the moral courage to say we would resist; for I cannot divest my mind of the conviction, sad and melancholy though it be, that it is forced upon us by our own wavering course of
              conduct here. Why, sir, I believe if we had committed ourselves, as we ought to have done on the 4th day of March, when Lincoln chose to disregard the position <PageNumber num={115} /> assumed by members on this floor, that the right of secession existed-that we would have been spared all this trouble. But "insult has been added to 'injury' "-we have been
              spurned and turned aside; and a regular requisition has been made upon the Governor to furnish our quota of troops to cut the throats of our fathers and brothers. But I feel to-day like rendering honor to whom honor is due. I rejoice that the Executive of Virginia has at last said that Virginia will have nothing to do with this matter; and that the people of Virginia will never lend
              themselves to any such conduct.
            </p>
            <p className="p-in-sp">
              While these things depress my mind, I cannot but rejoice that a sense of impending danger has been drawing us closer together; and when I hear voices uttered here to day, which only eight or ten days ago were raised against us, I feel that our cause must triumph, and that a full and adequate sense of our wrongs is entertained which cannot fail to command the sympathy and support of
              every Virginian. I would to God that when this Ordinance shall pass, that it shall go out to the world without a dissenting voice. I would be glad to have it known over the length and breadth of the land that Virginia has reached a position where she is fully united; and, as a Western man, let me enter my solemn protest; let me set the brand of my reprobation upon the sentiments that
              are uttered here threatening revolution from Western Virginia. I tell you that men may say that here in the heat of debate; but go home to the good and loyal people of that section and let them know that you expect them to become traitors to their Commonwealth, and there will not be a law-abiding power in the Commonwealth sufficient to save them from that destruction that they will
              draw down upon their own heads. There is no such spirit among the people of the West. Such remarks are thrown out for the purpose of intimidation, and they ought to be denounced wherever or whenever they are heard.
            </p>
            <p className="p-in-sp">
              We are defenceless, it is true. We have no arms or munitions of war, but let us not forget that the people on the Virginia side of the Ohio river have just as strong hearts, strong arms, and brave souls, and that they can hold a rifle as true to its aim, as any people in the world. The invasion is not to be all on one side. I would to God that some means could be determined, by which
              it could be avoided; but if it comes, the future historian of Virginia will write down deeds of glory performed from one extreme of our Western border to the other, and will record the fact that no traitor, that no treason shall ever be found existing within these borders.
            </p>
            <p className="p-in-sp">But I am opposed to cutting off discussion. These are important <PageNumber num={116} /> questions-questions of peace or war-and full liberty of discussion should be given.</p>
            <p className="p-in-sp">
              I shall, Mr. President, when the Ordinance of Secession comes up, vote for it. My people require me to vote for it; but I shall not give my vote to cut off discussion. I understand this debate is now as to why the main question shall not be put. I trust every man will have an opportunity to present whatever reasons he may think proper in explanation of his vote. I shall therefore vote
              against this question to cut off debate, but will cheerfully vote for the Ordinance of Secession.
            </p>
            <div className="speaker" id="sp3739"><span className="persName">Mr. WM. C. SCOTT</span>, of Powhatan—</div>
            <p className="p-in-sp">
              I cannot record my vote without giving the reasons which shall influence me in giving that vote. I am perfectly well aware, sir, that I shall give a vote which will not be agreeable to some; but since the meeting of this body I have given no vote that was not in accordance with the conviction of my own mind, with the exception of one which I gave in accordance with the instruction of
              my constituents.
            </p>
            <p className="p-in-sp">
              When I entered this body, I entered it not as a party man. I should have deemed myself unworthy of a seat in this Convention if I permitted myself to be controlled or influenced by any party considerations. I came here not as Whig! I came here not as Democrat! I came here not as a secessionist; I came here not as a Union man. I came into this body prepared to pursue that course which
              patriotism might suggest as the best and wisest.
            </p>
            <p className="p-in-sp">
              Sir, I have been deeply grieved at the unfortunate condition of things, and I envy not that man his callousness of mind, who cannot be grieved at the state of affairs which is now presented before us; and I envy not the impressions and feelings of those men who undertake to destroy this great temple of liberty. For my part, I have made every legitimate effort to ward off this terrible
              disaster; but notwithstanding all my efforts, I find it is perfectly impossible to avert the evil. I have surrendered my opinions and ceased my efforts to accomplish the purpose for which I had set out. The war is now upon us, and we must fight.
            </p>
            <p className="p-in-sp">I was proceeding with some remarks the other day, when I was reminded by my friend from Monroe [<span className="persName">Mr. CAPERTON</span>] , that the remarks which I was about to make, might better be made in secret session. I will now say that I believe we must fight and we must secede. These questions are settled beyond the possibility of defeating them.</p>
            <p className="p-in-sp">I differ, however, from my friend from Monroe [<span className="persName">Mr. CAPERTON</span>] , about the way of avoiding a war. We cannot avoid it; we must fight.</p>
            <PageNumber num={117} />
            <p className="p-in-sp">The war is upon us already, and it is impossible to do otherwise than to fight, and prepare for war. In my estimation, it is now a mere military question, and the next inquiry is, whether, under the circumstances by which we are surrounded, we shall pass an ordinance of secession at once.</p>
            <p className="p-in-sp">What virtue is there in an Ordinance of Secession? My friend from the city of Richmond [<span className="persName">Mr. RANDOLPH</span>]
              , said yesterday that we wanted not advice but bayonets. Will an Ordinance of Secession, which we can pass in five minutes, furnish bayonets to the State of Virginia. Look at the condition in which we are-see the circumstances by which we are surrounded. We are differently situated from the seceded States. They are far off from the seat of the Federal Government, and from the Northern
              States. We are at their doors. We are isolated, cut off from the Seceded States by North Carolina and Tennessee. We have about two hundred miles of Northern border, and about the same extent upon our Western border, separated, I may say, only by an imaginary line from the free States. On the Atlantic side we are threatened by four routes. The James River, that commands the heart of
              Virginia, is commanded by the Federal Government, and in a few minutes an army of any magnitude could be thrown here into the very heart of Virginia, and the mouths of our rivers blockaded. Look at the course that the Federal Government has pursued. We were told by my friend from Richmond
              [<span className="persName">Mr. RANDOLPH</span>]
              , that Virginia was in a better state of military organization than any Northern State. Mark you that we have not only to fight the Northern States, but the Federal Government, with 18,000 regular troops at their command, and nineteen Northern States in addition, besides the whole Navy of the United States. With such advantages, it strikes me, they would have little difficulty in
              throwing an army into the city of Richmond in twenty-four hours; and what have we to defend ourselves. It will not do for gentlemen to talk about bravery alone. It is very well to talk to soldiers about; but it will not do to talk to statesmen and Generals in reference to it. We have six thousand two hundred and twenty Infantry armed, according to the report made to-day. We have no
              powder; what, then, are we to do? Sir, if you pass an Ordinance of Secession, you declare war-you declare yourselves in a state of war. You will be in a state of revolution, and you invite the Federal Government to make an attack upon you. They will undoubtedly do so; and they can throw an armed force here, and take possession of the Armory, which is now useless, because there is no
              machinery in it. They will in all probability take possession of Anderson's Foundry, <PageNumber num={118} /> where the guns that took Fort Sumter were cast, and we can offer comparatively no resistance for want of the necessary means of defence.
            </p>
            <p className="p-in-sp">
              I am not talking in this way because I wish to avoid any responsibility as a military man; so far from it, I have offered the services of my brigade and my own services to the Governor; for feeble as this arm is, if the Governor will permit, it will be raised in defence of my State, no matter what may be the force brought against me; and no matter though I may be committing treason
              according to the laws of the United States. Though that may be my opinion, if the Governor of this Commonwealth will permit, no man can be found who will more cheerfully take his position in defence of Virginia than I will. But we are not in a condition to carry on war now. What, then, is best to be done? I shall vote for the proposition of the gentleman from Fauquier
              [<span className="persName">Mr. SCOTT</span>]
              , because it will give us a little time to buy arms. Why, sir, I was told by the Adjutant General this morning that if we passed an ordinance of secession, we ought not to let it be known for a few days, because he sent, for arms to the North, and he is apprehensive that they may be intercepted if it was known that the ordinance passed. Would it not be well, if we are determined to
              secede, to wait a little while in order that we may receive those arms from the North? We could then secede, and we would be in a much better condition to meet the enemy than we are now. This seems to be the proper course, and I trust the Convention will pursue it.
            </p>
            <div className="speaker" id="sp3740"><span className="persName">Mr. JOHN N. HUGHES</span>, of Randolph and Tucker—</div>
            <p className="p-in-sp">
              Mr. President, when I survey this Convention; when I see men who are ripe with years of experience, I feel that I had much better be silent than open my lips. Heretofore, sir, I have certainly been silent, except to cast my vote; but I feel now, representing as I do, two counties and part of a third, lying immediately west of the Alleghanies, that I can no longer remain a silent
              spectator of the proceedings in this Hall and not say something.
            </p>
            <p className="p-in-sp">Permit me to tell you that I have the honor of representing upon this floor a constituency than whom there are none in the Commonwealth more noble, none more loyal, none more brave, none who would leap to their muskets more readily to defend their rights.</p>
            <p className="p-in-sp">
              Heretofore, I have co-operated with the Union party on this floor. I have had an earnest, abiding faith that all our difficulties might be amicably settled, and that peace and quiet might once more reign throughout the borders of this noble old Commonwealth. I have clung to the last broken plank of our once noble ship of State, until it has sunk beneath me; and I have now but one
              remedy left, and I confess <PageNumber num={119} /> to you frankly that I feel that to be separating from this Union. Stern necessity forces me to say to my people, that an emergency has arisen which compels me, in justice to them and myself, to take ground in favor of action-prompt, immediate, decisive action. I am opposed to temporizing any longer; I am
              opposed to making any more propositions to a tyrannical and overbearing foe that desires to make slaves of me and you. For my part, I can submit no longer. I had no opportunity to confer with my people since the last news received here of Abraham Lincoln's tyrannical over-bearing declaration of war against us. I have had no opportunity to hear from my people in respect to the course
              which they would have me pursue in this emergency. I think I know my people and know their feelings, and I believe I know their hearts, and am satisfied that every impulse of theirs would be in favor of Virginia all over-not North-western Virginia alone-not Western Virginia alone, but Eastern Virginia, Middle Virginia-the whole of Virginia. That, I confidently believe, is the feeling
              of my people; and, if I failed so to represent them upon this floor, I believe to-day, I would be recreant to my duty to myself, to my people, to my country, to my native State, and to the God that presides over the destinies of Virginia.
            </p>
            <p className="p-in-sp">
              Permit me, meanwhile, to inform you of the platform upon which I was sent here. I told my people when I was a candidate, that, if elected, I would be in favor of doing everything that we could do honorably to preserve the Union; but, at the same time, I told them I would never agree to see Virginia disgraced or dishonored; and believing now, sir, that there is an effort being made by
              Abraham Lincoln and his co-adjutors to subjugate not only our sister States, but Virginia, I am in favor of immediate secession to-day without any ceremony.
            </p>
            <p className="p-in-sp">
              We have temporized too long already, and I confess I have been one of those who favored that policy. I hated to give up this noble Union that formed the source of our great prosperity in the past. This was my feeling. Look, if you please, at the action of the last Black Republican Congress, sixty-seven members of which signed a recommendation in favor of the notorious Helper book,
              which advised our slaves to rise up and cut our throats, and then play havoc with our people at their discretion. You see a proposition introduced into the Congress of the United States that the Black Republican party must not interfere with slavery in the States; and what do you find in the teeth of this movement? I blush to state the fact-that almost 100 Black Republicans in that
              Congress voted against that proposition; <PageNumber num={120} /> the effect of which action is that they were in favor of declaring war upon slavery in Virginia and the South. Yes, Mr. President, almost one hundred members of that Black Republican Congress voted against a proposition of that kind. But notwithstanding all this, I was willing still to
              compromise, still to adjust our difficulties, still to meet them in a spirit of brotherly love, and settle this matter, if possible; and while I co-operated with the majority upon this floor; while we were engaged in check-mating the minority at every turn; while we met them on every proposition, and voted them down; while we showed to Abraham Lincoln that there was a majority of this
              Convention who were determined to preserve this Union; while we were engaged in this good work of seeking to effect an adjustment, Lincoln declared war upon Virginia; yes, sir, upon Virginia! And what can we now do? What remedy have we to adopt? What means can we resort to to maintain our rights and resist this war? But, sir, not content with thus declaring war against us, he adds
              insult to injury; he makes a requisition upon Gov. Letcher for Virginia's quota of troops to make war upon the Southern States. When that is the case, after having done all that, as an honorable man, I think I can do-after the majority upon this floor have resorted to all the means they could resort to in order to adjust these difficulties-I feel compelled to give my vote in favor of
              action-decisive and immediate action. We even sent commissioners recently to Washington, in a spirit of peace and harmony, to appeal to Lincoln to preserve the status quo until we could make further efforts at adjustment. The only answer he makes to these commissioners is a reference to his inaugural and a declaration of war upon our people immediately upon the return of our
              commissioners to this city.
            </p>
            <p className="p-in-sp">Under these circumstances, I shall feel compelled to vote in favor of the Ordinance of Secession.</p>
            <div className="speaker" id="sp3741"><span className="persName">Mr. SHERRARD CLEMENS</span>, of Wheeling—</div>
            <p className="p-in-sp">
              The darkest, the most cheerless and the most desolate hours of my life have been passed within sight of this spot where I now stand. I had hoped, sir, that the cup that is now presented to my lip might, in the mercy of God, pass away. No human being will ever know the conflict to which my heart has been subjected for the last six long weary days and weary nights at the thought that
              when I returned to my people upon the banks of the Ohio it would be to bear to them the sad tiding of the final dissolution of this great Republic, and to inform them that I must now abide the issue whether for evil or for good which, the South has now made. I have not forgotten that one in whose veins flows <PageNumber num={121} /> the same blood which
              now courses in mine is second in command at Pensacola. Blood, kindred, affection, love, every impulse that can control an honorable man have been bearing down day after day upon my judgment until I felt that all the fortitude, that all the heroism, that all the hardihood that could belong to a wise man was necessary to meet and suppress this storm of emotion and of fear.
            </p>
            <p className="p-in-sp">Sir, I have tried to purge myself, as far as my feeble faculties could, from that natural and painful feeling which I dare any Virginian to expel from his bosom when he feels himself standing here in front of a destiny that, deprecate as he may, he sees is inevitable.</p>
            <p className="p-in-sp">
              Sir, I have waited to this hour-I have calmly and deliberately weighed every argument as fully and zealously and conscientiously as I could do, to see in what direction the path of duty lay. On the one side was my family, their interests, their fortunes, their all. On the other were a people who have known me only to love me, and who in every hour of trial have showered down their
              manifold blessings upon me, until I feel that I belong more to them than to myself.
            </p>
            <p className="p-in-sp">
              Sir, I desire, at this day and at this hour, to present a united front, if a united front we can present. I adjure you in the name of the people whose protection and whose interests you cannot overlook, to unite upon some measure that will make a unit of this Convention, and will finally make a unit of the people of the State. Sir, this is not now altogether a military question. If it
              were, I should feel myself at liberty to act upon other principles and with other considerations than those which bear upon me now. We have no right to pass an ordinance of immediate and unconditional secession. You cannot do it legally. The proposition of the gentleman from Fauquier
              [<span className="persName">Mr. SCOTT</span>]
              , seems to me to be fair and just, for it presents a safe alternative without giving rise to delay. If you pass an ordinance of secession, you must await the decision of the people upon it, and no more delay will result from a vote upon the proposition of the gentleman from Fauquier, than will from taking a vote upon the ordinance. You gain nothing by passing an ordinance of immediate
              secession. That is, in fact, a power you cannot exercise. You can present to the people the alternative of deciding whether they will, by their own sovereign act, dissolve the bonds which bind this people together; or whether they will more firmly unite those who are bound together by geographical ties and the ties of a common interest and a common destiny.
            </p>
            <p className="p-in-sp">Sir, in my conscience, I believe that if this Convention will present the proposition of the gentleman from Fauquier [Mr. SCOTT]
              , that we can and will unite all in this house, and that will present a united <PageNumber num={122} /> front, which, at a time like this, is of great importance. Under these considerations, and without going into any further argument upon the subject, I am compelled to vote for the proposition of the gentleman from Fauquier. I trust that those who desire
              that we may all unite upon the broad principles necessary to our security as a people, and who believe that in peace is the time to prepare for war, will cooperate with us in adopting that proposition. I would remark, that ominous and threatening as may be the cloud that now seems to be gathering over us, it is not an act of patriotism to deliver over the people to wholesale slaughter
              without affording them the means of defence. However indisputable the right of revolution may be; however justifiable it may be for the people of Virginia to take arms in their hands to vindicate their liberties; if Virginia desires to secure her best interests, and to keep her people in their proper line of duty, she will take the proposition of the gentleman from Fauquier, and reject
              the ordinance of secession.
            </p>
            <div className="speaker" id="sp3742"><span className="persName">Mr. HUGH M. NELSON</span>, of Clarke—</div>
            <p className="p-in-sp">
              Mr. President, I know not where I shall find words to express the deep feelings of my heart, and if I had them, shattered as my nerves are by sleepless nights, I should not have the physical strength to speak them. I did not dare to trust myself to speak on yesterday, for my feelings would have overcome me. I never realized till then that Virginia must go out of this Union.
            </p>
            <p className="p-in-sp">Do not blame me, sir, if I have peculiar feelings of veneration and affection for the American flag and the name of American citizen.</p>
            <p className="p-in-sp">
              Sir, it was my fortune to be in Paris during the deadly carnage of the insurrection of June, 1848. When fear and consternation reigned throughout the city, I stood under that flag, safe as within the bastions of Gilbraltar. I felt and experienced during that trying crisis that the name of American citizen was an xgis of protection in every danger. Sir, I have always associated the
              American flag with the name of Washington. My honored grandfather, Gen. Thomas Nelson, stood under that flag, when he aimed the first gun shot at Yorktown, against his own house, then the headquarters of Cornwallis. Do not wonder, then, if I am grieved at heart to give up that flag.
            </p>
            <p className="p-in-sp">
              Mr. President, if I have come to the conclusion that we cannot secede from this Union without consultation with the border slave States, I can at least plead that my convictions on that point have been strengthened by the eloquent remarks of the gentleman from Montgomery, on the 26th of March. Its fervid strains are still echoing in my ears. I thought that day that if the Hybla bees
              had been <PageNumber num={123} /> there they would have swarmed upon his lips. Let him not blame me, then, if I am still under the influence of that spell.
            </p>
            <p className="p-in-sp">Sir, I said I never realized till yesterday that this Union must be broken up; for I never would believe that it could be broken up whilst Virginia remained a member of it. But, sir, I begin to realize that it must be so; and if the time has come, I shall have no conscientious scruples in breaking every tie that binds us to it.</p>
            <p className="p-in-sp">When I remember, though, that that beautiful valley where my people live, as has been said, is to be the Flanders of the battle, and that those green fields will probably be fields of blood; when I look back to the past and forward to the future, I sorrow from my heart, and I am speaking with trembling lips, with tearful eyes, and from a bursting heart.</p>
            <p className="p-in-sp">
              Sir, I have reluctantly come to the conclusion that we must secede; that we must resist the Federal usurpation at every cost and at every hazard; and, sir, I only differ with some of my friends as to the best mode of doing it. I believe that the alternative proposition of cooperation with the border slave States should be submitted to the people, together with the question of separate
              secession.
            </p>
            <p className="p-in-sp">
              Sir, the only consolation which I shall have in parting with the flag of the United States, is that I shall stand under the flag of this beloved Commonwealth when she unfurls it to the breeze, and calls upon her sons to defend it. I pledge you here, that as long as there is strength within my arm, or in those of my people, we will defend it to the death. If it ever falls to my lot to
              hold that flag, like heroic Ja'far, on the field of Muta, when I lose my right hand may I shift the standard to my left, and should the left be severed from my body, may I embrace it with the bleeding stumps, and if I am killed, may there be some Khalid there to bear it on to victory and to triumph.
            </p>
            <div className="speaker" id="sp3743"><span className="persName">Mr. TIMOTHY RIVES</span>, of Prince George and Surry—</div>
            <p className="p-in-sp">I rise, Mr. President, for the purpose of making a few remarks, not merely to excuse myself to the present generation, but that I may stand right upon the record hereafter. I have no fear of being misrepresented whilst I am alive, and my record I shall leave as my vindication when I shall have passed from this world.</p>
            <p className="p-in-sp">
              When I came here, it was with a disposition to have this matter amicably adjusted. Before we entered fairly upon this task of adjustment, there were a set of resolutions adopted, in which it was expressly stated that the General Government was to forbear from the exercise of any power that might bring about a collision between that Government and the seceded States. We then had
              Virginia in a <PageNumber num={124} /> safe position, so that we could with propriety under take to settle this matter amicably, without the citizens of Virginia apprehending danger from any source. I felt myself at liberty, with the assurance that everybody in Virginia was safe, to make every honorable effort to settle this matter. Recently, however, my
              constituents at home attempted to instruct me, and when I went home on Saturday last, I demanded to know what line of policy they instructed me to pursue. I was told by a member what arguments were used to get the paper signed. I made no reply, however, until I met those who drew up the resolutions, and had them adopted. I took occasion, on meeting them, to say that when their
              instructions came to me, I would receive them respectfully, and then tender my resignation, go home, and run again, when I am confident of beating their strongest man by a majority of at least two hundred. I told them I would refer the whole matter to the people who gave me their confidence. Every man whom I met, of the signers of the paper, told me he signed it under a
              misapprehension. I stated that I would resign if the document should be presented to me, and would insist not only on his withdrawing his name from it, but also in denouncing the man who got him to sign it.
            </p>
            <p className="p-in-sp">
              When I got to Petersburg, I found that a telegraphic despatch had reported a state of things from Washington that was, to me, alarming enough. I was met by two gentlemen of high distinction, who had done their best to defeat me. They told me they would not sign the instructions. That I beat them in a stand up fight, and that they would now leave me to pursue the course I thought best.
              I told them, if that was their plan, I would meet them as men. I said my efforts were directed to an amicable adjustment of the whole affair; and that, if we went out before making due preparation, there would be a divided Virginia. I said I did not believe, and Virginia did not believe, at that time, that there was any existing cause to justify disunion, and I am still of opinion that
              no cause did exist, then, to justify secession. I stated that I thought some overt act should be committed to justify secession. In my estimation, that without that there would be no unity of purpose or sentiment in the State. I stated that I regarded the policy of Lincoln in calling forth 75,000 militia, and calling upon Virginia for her quota of that number, as sufficient to unite
              not only Virginia, but Kentucky, Tennessee, Maryland and Missouri. I said to them, "Gentlemen, go on; issue your instructions; I know when the point of honor is past, as well as any man; I know when to strike the blow." I remarked to them, also, that it was due to the commissioners who went to Washington that we should wait and hear what they
              <PageNumber num={125} /> had to say. I said that when I heard their report I would be ready to do my duty. I came here, and finding the report of the commissioners to be of a character by no means satisfactory, I determined, if an ordinance of secession was offered, to vote for it. In doing so, however, I am not condemning my Western friends for the
              position they take. I am not condemning my friend from Fauquier
              [<span className="persName">Mr. SCOTT</span>] , for I know he has done everything he could to secure peace.</p>
            <p className="p-in-sp">
              I will remark, however, that when I sign that ordinance of secession, I shall sign it as an act of revolution. I tell you that the passage of this ordinance is the inauguration of revolution; and I tell you that in less than thirty hours afterwards, there will be acts done in the Convention that no man would now regard as possible. I tell you I go into this fight, and look every man in
              the face, and say, I did what I deemed best for my country, meanwhile believing that in signing the ordinance, I was helping to inaugurate revolution.
            </p>
            <div className="speaker" id="sp3744"><span className="persName">Mr. J. G. HOLLADAY</span>, of Norfolk county—</div>
            <p className="p-in-sp">I beg pardon of this Convention at this late day to say a few words. We are about to write our names upon the record of history, and I will not consent that my name shall be signed to that record without a declaration of my opinions as to the crisis which is upon us.</p>
            <p className="p-in-sp">Sir, I cannot disguise from myself the fact that the most glorious government that ever protected a people has been overturned, and has, without sufficient cause been overturned. I admit that we are in revolution. I admit that the resumption of the sovereignty of Virginia is necessary, and proclaim here, sir, that that necessity exists without sufficient cause.</p>
            <p className="p-in-sp">
              Sir, I cannot shut my eyes to the fact, that certain States of this Union, for reasons peculiar to themselves, took certain steps which have precipitated this issue upon Virginia. I cannot shut my eyes to the fact that they have had co-adjutors among ourselves; and, holding that this Union is the last sheet anchor of liberty, I came here prepared to resist the effects of what has been
              done, and to counteract, as far as my efforts would avail, the evil influence of the course which these States have thought proper to pursue. Sir, those who have co-operated together upon this floor for the purpose of preserving the Constitution and the Union as our fathers made it, have met with reproach for their efforts. It is true, sir, it was of a general and not a specific and
              personal character; but, nevertheless, I have felt in my inmost soul, the impropriety of the course which gentlemen have thought proper to pursue in this crisis. For my part, I have in silence met these reproaches with a contempt more profound than the <PageNumber num={126} /> insults which they conveyed. I cannot shut my eyes to the fact that this matter
              has been precipitated upon this Convention, and upon this people. I cannot shut my eyes to the fact that against my wish, and against the thousand interests of the State of Virginia, have we all been drifted into this eventful crisis. And, sir, I should be wanting in the frankness of a man if I did not proclaim here, whilst I may co-operate with gentlemen in passing an ordinance of
              secession, that I do it without the feeling of kindness and the sympathy which ought to have existed, and which would exist if they had pursued the right course towards those who have thus far differed from them. Humble as I am, I think I can see the machinery which has been, and which is to be put in motion in connection with this movement.
            </p>
            <p className="p-in-sp">Now, sir, in reference to the plan proposed by the gentleman from Fauquier [<span className="persName">Mr. SCOTT</span>]
              , I shall vote for it and give it my most cordial support. It commends itself to my judgment as far preferable to the immediate ordinance of secession in many respects. I believe that the effect of this proposition will be to unite the citizens of Virginia in one solid body; to unite the people of Virginia and cause them to act in co-operation with the citizens of the other border
              slave States. It would enable us to do what the gentleman from Fauquier
              [<span className="persName">Mr. SCOTT</span>]
              so ably argued for in a speech which he delivered in Committee of the whole, namely: to make terms in a consolidated body with the Southern Confederacy, instead of being precipitated into that Confederacy under a Constitution which we will not have the ability to alter. I regard those who press upon the Convention now this ordinance of immediate secession, to speak plainly, as guilty
              of eminent folly. Sir, it is said in the public press this morning that an intimation has been received from the President of the Southern Confederacy, that, if acceptable to Virginia, his headquarters will be removed to this city. Whether the paper is correct or not, is there a gentleman on this floor who doubts that the effect of the immediate secession of Virginia will be to defeat
              that result? I would call the attention of gentlemen here to a fact that is demonstrative of the evil of this immediate ordinance of secession, so far as the State of Virginia is concerned. We all admit that if war does not now actually exist, that in a short time it will exist. The secession of Virginia will necessarily bring about a conflict which, without her secession, would not
              ensue. And what is to be the result? That we who are precipitated into this issue; that we who have no instrumentality in bringing it about; we who were opposed to the action of these Southern States-we step forward to take the part they ought to bear-we step forward and shift the scene <PageNumber num={127} /> of conflict from those States to the soil of
              Virginia. Now, there is no part of Virginia more exposed than the part I come from, and I am unwilling by my vote to give additional impulse to a movement which must involve my people in danger, and, it may be, entail sad disasters upon them. I am not prepared to precipitate a war upon my constituents. If the people of Virginia choose to engage in this conflict, why not furnish them
              men and money to meet the force which the General Government may send against them? It is the excess of folly in us so to act in this great emergency as to shift the scene of conflict from the seceded States to ourselves.
            </p>
            <p className="p-in-sp">I simply rise now to proclaim, that I have been zealously attached to the Union of these States, and willing, so long as hope existed, to use every honorable effort to maintain and preserve that Union; and that I shall at last, if at last I must, vote for an ordinance of secession, under a protest that I did so from the force of circumstances.</p>
            <div className="speaker" id="sp3745"><span className="persName">Mr. PETER B. BORST</span>, of Page—</div>
            <p className="p-in-sp">I presume the members of this Convention will indulge me for a few moments while I make a remark or two. War is now actually declared, and I feel that the time for talking has passed.</p>
            <p className="p-in-sp">
              I will state that the people whom I have the honor to represent are fully alive to the crisis, and prepared to meet it, come what may. They possess as noble and patriotic hearts as beat in the breasts of any people on earth. They are as industrious and sober, and as zealous for the success of the cause of Virginia and the South as any people can be. I know that they desire with all
              their hearts that Virginia shall secede immediately, and, in addition to that, they are prepared to defend the honor of Virginia and the rights of Virginia. So much for my constituents; now for myself.
            </p>
            <p className="p-in-sp">
              I have only to say to this Convention and before high Heaven, that I am determined that my action here shall be such as all will approve of. And when I am gone, my highest aspiration is, that I shall be buried in old Virginia in some spot where my wife may weep over me; and I would fain trust that when I am gone, I may be remembered in the patriotic heart of every patriotic defender of
              Southern rights and Southern liberty, if any merit there is in my course here to entitle me to such remembrance.
            </p>
            <p className="p-in-sp">This is all that I would look for as a reward for any little service which it may be my good fortune to confer upon my State and the cause of the whole South.</p>
            <div className="speaker" id="sp3746"><span className="persName">Mr. ALGERNON S. GRAY</span>, of Rockingham—</div>
            <p className="p-in-sp">I would ask leave of the Convention to add a few words to what I said last evening.</p>
            <PageNumber num={128} />
            <p className="p-in-sp">
              It will give my constituents the chance of voting to call to this council chamber of death the glorious State of Kentucky. She ought to be there. She is the daughter of Virginia-"bone of our bone and flesh of our flesh." The fairest daughter of all the earth. I know her well. I have heard the voice of her love for her honored mother. Hers is the most enchanting land on the continent.
              Her population are a race of heroes-wise and faithful in council; valiant, gallant, aye defiant, in the field. Call them to your council in this dreadful hour of dissolution, and if the red banner of war should invade our borders, her heroes and ours will triumph together, or embrace in death. Yes, sir, Kentucky should be present in the high councils of Virginia. It is an action that
              may affect our destiny for ages. I know my constituents will want her here. I beseech you to let it be so.
            </p>
            <p className="p-in-sp">
              I shall vote against secession because I was elected to do so, and I desire, above every other consideration, to carry out faithfully the will of my constituents in this dreadful hour. They know the difficulty of my position, and if they have changed their opinions, they could have sent us instructions from the court house, where court was held on Monday, by the mail day before
              yesterday and again on yesterday. But they did not do it. I, therefore, vote their will in casting it against this ordinance of secession. If I believed there were instructions from the body of the people on the way now to that effect. I would vote for secession. I thank God they will have the power to vote upon it themselves. And when Virginia has decided, they and I will stand by her
              flag for weal or woe.
            </p>
            <div className="speaker" id="sp3747"><span className="persName">Mr. CHAPMAN J. STUART</span>, of Doddridge and Tyler—</div>
            <p className="p-in-sp">
              Mr. President, I have no apology to offer for consuming the few minutes' time allotted to me under the call of the previous question, on the subject now under discussion. As to who represents and who do not represent the views and sentiments of their constituents on this floor, time will soon disclose. Of one thing I am certain, and that is, that I have an approving conscience that I
              have, in my course in this Convention, been actuated by pure and patriotic motives. I believe that I have represented the wishes of my constituents, and in the vote that I am now about to cast I am still of the opinion that I represent the interest and views of my people. If I were not convinced that I represented the feelings of my people, I would this moment resign my seat in this
              body. No earthly power could induce me to cast a vote of this magnitude against my sentiments and convictions of right, nor would I cast it against the views and will of my constituents.
            </p>
            <p className="p-in-sp">
              We were appealed to by the gentleman from Pittsylvania to know <PageNumber num={129} /> who we were-were we Western Virginians and not Virginians! Let me say to that gentleman that I am a Virginian. My great ancestor sought refuge in Virginia from foreign bondage and oppression. My grand ancestor fought through the Revolutionary war as a Virginian. My
              father fought through the war of 1812, and I, Mr. President, stand here to day a Virginian-a direct lineal descendant from revolutionary stock-ready and willing to live or die for my country, as her necessities may require. Through these veins courses blood that never shrank from danger when country was imperiled. Under the flag of my country, I am ready to march to victory or to
              death. But, Mr. President, I must know that it is for my country that I fight. I am not willing to bring on my little family and defenceless countrymen the battles of a people whose conduct and course of action towards us I have always condemned. I am not willing to perjure myself and commit treason against my Government until that Government has been perverted to my oppression and
              injury. Not until I and my people are enabled to justify ourselves in the sight of God and man for such a fearful act, are we prepared to do this thing. I am now called upon to pass upon a question of more vital importance to the people of Virginia than has ever been before any of her deliberative bodies during her existence as a sovereign State; and that, too, with closed doors and
              with indecent haste. Why this unusual haste to press this question on the Convention? The ordinance of secession offered for adoption proposes to submit the action of this body to the people for ratification some forty days hence. An ordinance of secession cannot be effective until ratified by the people. Then, what possible harm could result to this Convention or the people, if one or
              two days should be consumed in discussing the two propositions now before this body? We have consumed months in debating abstractions which were of no earthly importance; and now, when a question that in my opinion involves life and death and all the material interest of this Commonwealth,<span className="note" id="Note15"
              ><span className="noteLabel">4</span>Some word such as "arises" seems to be missing here.</span
              >
              it is to be pressed through under an unusual excitement. Little does it become the dignity of Virginia statesmen. The people will demand, and have a right to know, the reasons that influenced the actions of their representatives. We hear some of our brother members say they desire to be heard before this House. Let me say to them, if their voice is to be silenced here, that this is not
              the only tribunal. There is another last and final appeal to be made before those of whom we are the servants. There, if not here, we will be heard. I see one object the friends of the ordinance of secession have in view, in pressing it to so hasty an issue. This ordinance of secession is to be <PageNumber num={130} /> hurried through this body; a
              revolutionary spirit is to be aroused, and the action of the people of Virginia is to be anticipated. The property within the limits of Virginia, belonging to the Federal Government, is to be seized. Hostile collision is to be induced by that act between the people of Virginia and the General Government. War is to be inaugurated here, without consulting the people. The purposes and
              objects for which we were delegated here will have been subverted by our own acts. Our mission here, as I understood it, was to deliberate and consult together for the interests of the Commonwealth, and to submit our action to the people for their adoption or rejection. What matters an Ordinance of Secession after we have brought upon our people open war with the General Government-the
              very thing we were sent here to prevent? Peace was our mission. The people reserved to themselves the right to say whether we should have war or peace. They reserved to themselves the right to pass upon every act of ours looking to a change of our federal relations. I would ask, is not the seizing and wresting from the possession of the federal government the property in our limits,
              changing our relations with that government; and that, too, in the face of the avowed declaration of the President that he would hold the public property, peaceably, if he could, forcibly, if he must? Will not the members of this Convention hesitate before they recommend such a course? If you will not, let me warn you, that your unwarranted usurpation of power will arouse a spirit of
              resistance in Virginia, that you are not now prepared to realize.
            </p>
            <p className="p-in-sp">
              Mr. President, time fails me. I represent a people lying on the extreme border of the State. We can look across the beautiful Ohio, and converse with our sisters and brothers. In the name of every thing that is sacred, are we to be put in mortal array against each other without one last and final appeal to our brothers for reconciliation? Secession is war-war in its most fearful and
              desolating aspect. I make this my last and final appeal to this Convention to give us the substitute of the gentleman from Fauquier. I want my people to have the privilege of passing on it. With it the people of Virginia can be united. I came here with the determination of demanding the rights of Virginia and taking nothing less. Every vote that I have cast in this Convention has
              looked to that object. But I for one, am not willing that Lincoln or any one man shall destroy this Government. I want to make an appeal to the people. Is it possible that a few ambitious reckless men will be permitted to destroy the fairest Government that was ever constructed by mortal man, and the people in their majesty <PageNumber num={131} /> and
              power not come to the rescue? God in his mercy forbid! Time is up, I am done.
            </p>
            <div className="speaker" id="sp3748"><span className="persName">Mr. WM. J. NEBLETT</span>, of Lunenburg—</div>
            <p className="p-in-sp">I simply desire to say that I tied off with <span className="persName">Mr. KILBY</span>, of Nansemond, from Saturday morning until last night, and that I am now free to vote as I think proper.</p>
            <div className="speaker" id="sp3749"><span className="persName">Mr. JAMES B. DORMAN</span>, of Rockbridge—</div>
            <p className="p-in-sp">Mr. President, the issue is now upon us; we must fight; and the question is, which side will we take. I have not heard from my people and have therefore no positive information as to how they would vote. I will, however, undertake to decide for them on which side they will vote, and that side is the South.</p>
            <div className="speaker" id="sp3750"><span className="persName">Mr. JOHN A. CARTER</span>, of Loudoun—</div>
            <p className="p-in-sp">
              I have not had any instructions from my constituents, nor have I had any special communication from them as to what I ought to do here. I do not wish it to be understood that the vote I mean to give here to day is given as by them. I wish it to be understood, that I take the whole responsibility myself. Whether they would concur in it if they were here or not, I do not know; but the
              vote that I shall give I fully approve with my head and heart.
            </p>
            <div className="speaker" id="sp3751"><span className="persName">Mr. JOHN A. CAMPBELL</span>, of Washington county—</div>
            <p className="p-in-sp">I have not detained this Convention heretofore by any remarks of mine, to the extent of twenty minutes. I feel compelled upon this occasion, however, to say something before this vote is taken.</p>
            <p className="p-in-sp">I believe that I appreciate fully the solemn responsibility which attaches to me, and every member of this Convention, on this occasion. But, sir, I do not intend to indulge in any vague and idle repinings. There is a great crisis upon us, and as men we must meet it.</p>
            <p className="p-in-sp">I endorse the sentiments of my friend from Loudoun [<span className="persName">Mr. CARTER</span>] , and intend, furthermore, to meet the crisis with all the responsibility that attaches to it.</p>
            <p className="p-in-sp">Now, sir, there are two propositions before us; and, because the vote upon the proposition of the gentleman from Fauquier [<span className="persName">Mr. SCOTT</span>] is intended to be a test vote, I shall take occasion now to give my sentiments upon the main question.</p>
            <p className="p-in-sp">I intend to vote what I believe is for the very best interests of the whole State of Virginia, because I claim to be a Virginian, true to every interest of my State-a Virginian without any prefix.</p>
            <p className="p-in-sp">What are these propositions? One proposition-that submitted by the gentleman from Montgomery [<span className="persName">Mr. PRESTON</span>] -is an ordinance of immediate secession, in support of which I have heard no argument calculated to bring conviction to my mind. The other is the proposition <PageNumber num={132} /> of the gentleman from Fauquier [<span className="persName">Mr. SCOTT</span>] , in aid of which I have heard an argument clear, powerful, convincing, patriotic; and in answer to that argument I have heard no other response than a call for the previous question.</p>
            <p className="p-in-sp">
              Now, sir, I intend to vote for the proposition of the gentleman from Fauquier, and why? Because it presents some hope that if we must fight-as I believe now we must and will do cheerfully-we will go into it well prepared. In such an event, we might with some confidence look forward to a successful issue; and when the conflict conies, I hope to meet there some gentlemen that I have the
              honor to meet here. In the mean time, as a Christian man, I do not intend by My vote to bring on this conflict; but rather to avert it as long as there is a possibility of escaping it. I do not intend to plunge our people into a war when arms or money are not available, and when we even have not powder enough to keep an army in the field for a campaign of two, nay, one month.
            </p>
            <p className="p-in-sp">I intend, therefore, to vote for the proposition of the gentleman from Fauquier [<span className="persName">Mr. SCOTT</span>]
              , believing that, under the operation of that ordinance, we will be enabled to supply the deficiencies which are known to exist at present. I do not intend, so far as I am concerned, to plunge this State, bound hand and feet, into a civil war; and, as that would be the effect of an ordinance of immediate secession, I cannot vote for it. By the passage of this ordinance, you, in effect,
              declare war, and nothing else than war. Not a formal declaration, it is true, but such a declaration as Lincoln will perfectly understand and meet in due time.
            </p>
            <p className="p-in-sp">
              How has this condition of things been brought upon us? One week ago, we were all calm, and firm. Since that time, by sensation despatches; by all sorts of communications; by proclamations issued by the President-gentlemen have been driven from their propriety, and now we all are upon the very brink of revolution, the end of which, I firmly believe, no man here will live to see.
            </p>
            <p className="p-in-sp">In view of these facts, I intend to record my vote for the proposition of the gentleman from Fauquier [<span className="persName">Mr. SCOTT</span>]
              ; and whilst I intend to hold myself responsible for that act to my people, I feel that I express, in recording my vote for that proposition, the sentiments of one of the most populous counties in this State. What I shall do after that may be what may be necessary to co-operate with those who now take the responsibility of plunging Virginia into a civil war. I shall do what, in my
              humble judgment, is best for the interests of all Virginia. I shall consult my best judgment in the matter, with no <PageNumber num={133} /> other desire than to arrive at the truth. Thus far I have seen my way clearly. I don't know but that from this hour forward my way may be lighted by the blaze of civil war.
            </p>
            <div className="speaker" id="sp3752"><span className="persName">Mr. GEORGE W. HULL</span>, of Highland—</div>
            <p className="p-in-sp">
              Mr. President, in violation of the positive injunction of my physician, I am now in my seat upon this floor, at a moment the most momentous, and fraught with more of interest than any since my existence, or the existence of the free people of America. Sir, I am about to be called upon to cast my vote upon a subject, the decision of which I feel involves the best interests, not only of
              the noble and generous people who honored me with their suffrages, but the people of the whole State. In my opinion, upon the decision of the proposition of the gentleman from Fauquier depends the fact whether Virginia is at once-yes, at once-to be plunged into a war that we are wholly unprepared to engage in, or whether we are to have some little time in which to make preparation. War
              we are to have, and the only question now is time and preparation.
            </p>
            <p className="p-in-sp">
              We are informed that there is a great insufficiency of arms, and a still greater insufficiency of powder; not near enough of the latter article, in my opinion, to justify us in taking the field without hope of soon obtaining more. There is not a powder mill south of Mason and Dixon's line ; where is our supply to come from? pass an ordinance of immediate secession, and you cannot get
              one pound from the North; but by accepting the proposition of the gentleman from Fauquier, I feel sure that we could procure a supply, if not large. at least an amount that we would be safe in taking the field with until we could procure a supply from England. Sir, I was elected to my seat here by a constituency whose devotion to the Union was a unit; a constituency noble, patriotic
              and generous, and to a majority of whom I have been opposed in the political questions formerly dividing parties all my life, but who, upon this occasion, laid past differences aside, as I have done here, and elected me by a triumphant majority over a Democrat. But they elected me, not on account of my former political affinities, but because I was a Union loving man, in whose
              integrity they thought they could trust, and who they felt assured would stand firm to the last. I have not heard of any change in their opinion, and even this morning heard they were as I left them. It is apparent to me that the proposition of the gentleman from Fauquier will be rejected, but for which I will vote, and that an ordinance of immediate secession will be passed by this
              Convention, but for which I shall not vote, because if I did I feel sure I would thereby misrepresent a majority of those who sent me <PageNumber num={134} /> here. But, sir, when that ordinance shall have been passed and ratified by the people, and when Virginia shall have been declared to be out of this Union, I shall then be prepared to stand by her to
              the last.
            </p>
            <p className="p-in-sp">
              But, sir, I must here in the most solemn manner, protest in the name of my people against the passage of this immediate ordinance, but will give my support to the alternative one; because it will give time to make the preparation spoken of. Sir, we are about to be precipitated into a war brought upon us by fanatics and precipitationists; upon the heads of such be the responsibility;
              they have raised the whirlwind, can they direct the storm? To a just God, to desolated families, to fatherless children, let them account.
            </p>
            <p className="p-in-sp">When Virginia takes her stand I will be with her; yea, be with her to the last; and whether my head be laid upon the pillow of death in my own happy home, or whether I be hurled into eternity upon the battle field, amid the clash of arms and groaning of my dying comrades, my prayer shall be for my own my beloved native State.</p>
            <div className="speaker" id="sp3753">THE PRESIDENT [<span className="persName">Mr. JOHN JANNEY</span>]—</div>
            <p className="p-in-sp">Shall the main question be now put-which is on the substitute offered by the gentleman from Fauquier [<span className="persName">Mr. SCOTT</span>] .</p>
            <p>The vote having been put on ordering the main question to be put, was decided in the affirmative.</p>
            <div className="speaker" id="sp3754"><span className="persName">Mr. PRICE</span>, of Greenbrier—</div>
            <p className="p-in-sp">I call for the yeas and nays on the substitute.</p>
            <p>
              The call was sustained, and the roll having been called, resulted-yeas 64, nays 77, as follows:<span className="note" id="Note16"
              ><span className="noteLabel">5</span>The Journal adds Armstrong and White to the affirmative votes listed here. The Journal adds Ambler, Addison Hall, and Leake to negative votes listed here, to arrive at a total of 79; however, apparently only Ambler should be added, since Leake and Hall were paired.</span
              >
            </p>
            <p>YEAS-Messrs. Janney [President]
              , Aston, Baldwin, Baylor, Berlin, Boggess, Brent, Brown, Burdett, Burley, Byrne, Campbell, Carter, Clemens, C. B. Conrad, R. Y. Conrad, Couch, Critcher, Custis, Dent, Dulany, Early, French, Fugate, Gillespie, Gravely, Gray, Ephraim B. Hall, Hammond, Haymond, Hoge, Holladay, Hubbard, Hull, Jackson, Peter C. Johnston, Lewis, McComas, McGrew, J. Marshall, Masters, Moore, Nelson, Osburn,
              Patrick, Pendleton, Porter, Price, Pugh, Robert E. Scott, William C. Scott, Sharp, Sitlington, Spurlock, Alexander H. H. Stuart, Chapman J. Stuart, Summers, Tarr, Whitfield, Wickham, Willey, Wilson-64.
            </p>
            <p>
              NAYS-Messrs. James Barbour, Blakey, Blow, Jr., Boisseau, Borst, Bouldin, Boyd, Branch, Bruce, Cabell, Caperton, Cecil, Chambliss, Chapman, Coffman, Conn, James H. Cox, Richard H. Cox, Deskins, Dorman, Echols, Fisher, Flournoy, Forbes, Garland, Graham, Gregory, Jr., Goggin, John Goode, Jr., Thomas F. Goode, Hale, Cyrus Hall, L. S. Hall, Harvie, Holcombe, Hughes, Hunton, Isbell,
              Marmaduke Johnson, Kent, Kindred, Lawson, Macfarland, Chas. K. Mallory, James B. Mallory, Marye, Sr., Miller, Moffett, Montague, Morris, Morton, Neblett, Orrick, Parks, Preston, Randolph, Richardson, Rives, Seawell, <PageNumber num={135} /> Sheffey, Slaughter, Southall, Speed, Staples, Strange, Sutherlin, Tayloe, Thornton, Tredway, Robert H. Turner,
              Tyler, Waller, Williams, Wise, Woods, Wysor -77.
            </p>
            <div className="speaker" id="sp3755"><span className="persName">Mr. WALTER D. LEAKE</span>, of Goochland, when his name was called, said,</div>
            <p className="p-in-sp">I agreed to pair off with the gentleman from Lancaster [<span className="persName">Mr. ADDISON HALL</span>] , upon certain questions, one of which was the substitute of the gentleman from Fauquier. Were it not for that, I should have voted in the negative.</p>
            <p><span className="persName">Mr. FRANKLIN P. TURNER</span> , of Jackson, said, that he had paired off with <span className="persName">Mr. PAUL McNEIL</span>, of Pocahontas, otherwise he would vote no.</p>
            <p><span className="persName">Mr. WM. P. CECIL</span>, of Tazewell, McDowell and Buchanan, stated that his colleague, <span className="persName">Mr. SAMUEL L. GRAHAM</span>, was absent on account of illness.</p>
            <p><span className="persName">Mr. JUBAL A. EARLY</span>, of Franklin, stated that his colleague, <span className="persName">Mr. PETER SAUNDERS, SR.</span>, was detained from his seat by sickness. He added that if he were present he would vote in the affirmative.</p>
            <div className="speaker" id="sp3756"><span className="persName">Mr. JOHN A. CAMPBELL</span>, of Washington, when the name of ROBT. E. GRANT, of the same county was called, said—</div>
            <p className="p-in-sp">my colleague, Dr. GRANT, is now absent from sickness. I don't know how he would vote if present; but believe he would vote in the affirmative.</p>
            <div className="speaker" id="sp3757"><span className="persName">Mr. ROBT. E. SCOTT</span>, of Fauquier—</div>
            <p className="p-in-sp">
              I have pursued, Mr. President, with undeviating constancy, the policy of co-operation. I have believed and still believe that we would best have subserved the interests of our constituents by producing concert of action between ourselves and the people of the border slave States. That policy has not met the approbation of a majority of this body; and it seems now that an ordinance is
              to be recommended to the people of this Commonwealth, by which we should immediately sever our connections with the Federal Government. I merely rise to say, sir, that I mean to take my share of the responsibility of voting for that ordinance, and now make an appeal to those gentlemen with whom I have been acting, to come up and give to this action all the strength that united counsel
              can impart to it.
            </p>
            <p className="p-in-sp">I do not propose to detain the Convention a moment in explaining the considerations that must at once address themselves to the mind of every member; but merely to announce my own intention, and to beg at least as many of those as find it compatible with their sense of duty to unite with me and give to this vote as large a majority as it is possible.</p>
            <div className="speaker" id="sp3758"><span className="persName">Mr. WM. C. SCOTT</span>, of Powhatan—</div>
            <p className="p-in-sp">I am one of those who voted for the proposition of the gentleman from Fauquier [Mr. SCOTT]
              . I voted <PageNumber num={136} /> for it with the knowledge that it would be defeated. The gentleman has appealed to all those who co-operated with him in support of his substitute, to come now and vote for this ordinance of secession that is now before the House. I need no such appeal from him, and will remark, that I should have done so without that
              appeal. I unite with him in asking every member of this House with whom I have heretofore acted to come forward and make this action of the State as nearly unanimous as possible. Many of the votes that I have given, were given for the purpose of uniting the State, and in deference to the wishes of our friends from the West, who, I know, have as patriotic hearts as any of those in the
              East. I appeal to them now in this hour of trial and trepidation to come up and aid us in our efforts to place Virginia in a position of independence. Let not Virginia be divided. Let us all come up to the mark. Let us, like one man, shoulder to shoulder, take the responsibility of carrying the Old Dominion out of the Union. I trust it will be the pleasure of all to unite in that good
              work.
            </p>
            <div className="speaker" id="sp3759"><span className="persName">Mr. WISE</span>—</div>
            <p className="p-in-sp">
              I know the fact, as well as I can know it without being present at either the time or place, that there is a probability that blood will be flowing at Harper's Ferry before night. I know the fact that the harbor of Norfolk has been obstructed last night by the sinking of vessels. I know the fact that at this moment a force is on its way to Harper's Ferry to prevent the reinforcement of
              the Federal troops at that point. I am told it is already being reinforced by 1,000 men from the Black Republican ranks. I know the fact that your Governor has ordered reinforcements there to back our own citizens and to protect our lives and our arms. In the midst of a scene like this, when an attempt is made by our troops to capture the navy yard, and seize the Armory at Harper's
              Ferry, we are here indulging in foolish debates, the only result of which must be delay, and, perhaps, ruin.
            </p>
            <p className="p-in-sp">I move the previous question.</p>
            <p>The call was sustained. Ten minutes was allowed under the rule for each man to show why the main question should not be put.</p>
            <div className="speaker" id="sp3760"><span className="persName">Mr. ROBT. H. WHITFIELD</span>, of Isle of Wight—</div>
            <p className="p-in-sp">I will say but a few words.</p>
            <p className="p-in-sp">
              I am distressed, sir, beyond measure. I have not language to describe the feelings which fill my heart and this bosom. I never supposed that the first time that I should be in public life, I would be called upon to sever my connection with the United States of America. I have done all that I could do, consistently with the honor of Virginia,
              <PageNumber num={137} /> to preserve this Union. I opposed the policy before my people of separate State action. I believed that hasty and independent action would result in the destruction of our fair country, and the utter extinction of the liberties of the State itself. But, sir, events over which we have no control have forced this evil crisis upon
              us. We have stood here manfully battling for the rights of Virginia; we have stood here manfully battling for the rights of the South, and, as I believe, for the freedom of the world. All these efforts, however, have proved futile. We have been forced into civil war by no act of ours. It has been forced upon us by the usurpations of a Black Republican President. I have never been
              deceived from the time of his inaugural to the present moment in regard to his policy. It was in keeping with the policy, the shrewdness and cunning of his Secretary of State. I have always kept my eye upon him, and have never forgotten the declaration he made six years ago, that the time would come, when, to aid his party in their hellish purposes, he would vote in favor of raising a
              force of 50,000 men. I have had no faith in that man since, and have considered every act of kindness on his part towards the South as merely apparent, and rendered for ulterior purposes involving ruin to the South. I wish that gentlemen here, who are able and eloquent, would proclaim to the world that Virginia now is one, and that this cunning arch agitator has led us into this evil
              crisis; I entertained a doubt this morning whether or not I should vote for the proposition which has just been voted down; but, sir, from the time I went before the people, up to the moment that I heard the enunciations just made by the gentleman from Princess Anne, in regard to what has been done at Norfolk, and is being done at Harper's Ferry, I believed that co-operation was most
              necessary for Virginia. Separate State action in the South has brought upon us much more calamities than will fall upon them. I do not, however, blame them. They acted right, as they thought; they certainly acted courageously, and the idea never entered my head to utter a word of condemnation upon them. They are bone of our bone, and flesh of our flesh, and we are bound to defend them
              at every hazard. Had I been here I would not have voted to send Commissioners to Washington. I was prepared, then, from what I had seen of the policy of that man Seward, to vote for secession; and now I do not require the appeal made by the gentleman from Fauquier
              [Mr. ScoTT]
              to induce me to vote for this Ordinance. I came cheerfully to vote for it; and I think that every man who is a Virginian in heart, is bound to give her action as much unanimity as possible. This day's proceedings <PageNumber num={138} /> will be read by the world. The eyes of the world are upon us; let us, therefore, act as one man. Let us offer up on the
              altar of our country, our prejudices in the past. Let us determine that no man will need any other appeal than that which comes up from his own heart, and when we give our votes, let us give them because that course has been forced not by any act of ours, but by the infamous policy of a Black Republican President whose authority we defy.
            </p>
            <div className="speaker" id="sp3761"><span className="persName">Mr. JOHN B. BALDWIN</span>, of Augusta—</div>
            <p className="p-in-sp">
              We have been appealed to, Mr. President, to take upon ourselves a portion of the responsibility of the passage of the ordinance of secession. If an ordinance of secession becomes the act of Virginia, we will have to share in the responsibility that is to attach to it-that is, all of us that intend to share the fortunes of the State. I am one of those who intend to share the fortunes of
              the State, come weal, come woe; and I shall take and bear my full share of the responsibility of the act of the State. But, sir, we are now in council as to what should be the act of the State; and I cannot see how any gentleman who does not think the act a wise act for the State, can share the responsibility of making it the act of the State.
            </p>
            <p className="p-in-sp">
              I am one of those, sir, who believe that this act is an exceedingly unwise one, to be done now and under the circumstances by which we are surrounded. I have indicated that opinion by the vote which I gave for the proposition of the gentleman from Fauquier. I shall indicate that opinion again by the vote I shall give against the ordinance of secession. I claim the right as a
              representative upon this floor; as the representative of a free people living under a constitutional form of government; I claim the right, while a matter is in council, to give my vote according to my opinion. When I am overruled, and the act of the State is determined, then comes the time for united action upon the results of the council of the States, and then I shall be found
              sharing the fortunes of Virginia.
            </p>
            <p className="p-in-sp">Sir, the gentleman from Princess Anne [<span className="persName">Mr. WISE</span>] says that we are already in the midst of war. The Governor of this State at his instance and the instance of others, has already directed assaults to be made upon Harper's Ferry and Gosport Navy Yard.</p>
            <div className="speaker" id="sp3762"><span className="persName">Mr. WISE</span>—</div>
            <p className="p-in-sp">I did not say that.</p>
            <div className="speaker" id="sp3763"><span className="persName">Mr. BALDWIN</span>—</div>
            <p className="p-in-sp">I understood him to say that the Governor, at his instance, had directed steps to be taken with a view of taking Harper's Ferry and Gosport Navy Yard. If that is the case, I feel it to be my duty to tell my people not to march under an order that the Governor had no right to give.</p>
            <PageNumber num={139} />
            <div className="speaker" id="sp3764"><span className="persName">Mr. WISE</span>—</div>
            <p className="p-in-sp">
              I will state to the gentleman that it is to protect his people from being cut to pieces by the Wide Awakes-his people, who have marched to Harper's Ferry, I hope, in time to prevent the Wide-Awakes from getting the 20,000 arms at that place-that he has ordered out reinforcements. This has been done at the instance of a gentleman from this city. It is now too late to recall these
              people, if the gentleman could, and I hope he never would recall them, if he could. The Augusta troop are acting nobly in this matter, and I only wish my people had the honor of taking that stronghold.
            </p>
            <div className="speaker" id="sp3765"><span className="persName">Mr. BALDWIN</span>—</div>
            <p className="p-in-sp">I have no doubt that my people will be found ready, at all times and under all circumstances, to uphold the honor of their country. They have never been found wanting on that point.</p>
            <p className="p-in-sp">
              But, sir, I am speaking here as the representative of the people in a constitutional government, in regard to an act which the people themselves, by a majority of 60,000, directed should not be consummated without their voice at the polls; and I say that to consummate this act in defiance of the solemn action of the sovereign people of Virginia, I care not how patriotic the impulse
              which actuated such a course, is in derogation of the sovereign rights of the people of Virginia, who have appointed to settle it at the polls. I do not undertake to discuss, because I do not know the fact, that any portion of the people of Virginia have undertaken to assume the responsibility of making war, or opening hostilities; but, sir, I do undertake to say that it is a fearful
              responsibility that any one undertakes who plunges this State into civil war, unprepared as she is.
            </p>
            <p className="p-in-sp">
              Sir, the Governor is said to have sent men for the protection of my fellow citizens who are gone on this mission. I have no doubt that the Governor, in whatever he has done and will do, will be actuated by a high patriotism. I have high confidence in his patriotic purposes and his pure and sincere devotion to the interests of the State, as well as his capacity to preside over her
              destinies; but I feel it to be due to myself to say, that in recording my vote here, I cannot record my vote as one of the counsellors of the State of Virginia for a measure which my judgment does not approve. I repeat, sir, that when it shall become the act of the State, when my opinions shall be overruled it is a matter to be taken home to the hearts of the whole State, and to be
              sustained by the hands of the whole State. God forbid that I should be the one to make the slightest objection to carrying into action, aye, into determined action whatever the people of Virginia, in their sovereign capacity, solemnly determine shall be done.
            </p>
            <p className="p-in-sp">
              Sir, I regard this as the most solemn hour of my life, and I would be <PageNumber num={140} /> wanting in that candor which belongs to the position I occupy, and to the friends by whom I am surrounded here, if I had withheld this declaration of my position. I wish I could look upon this matter as other gentlemen here seem to look upon it. I wish I could
              participate in the feelings which seem to light up the countenances of friends whose opinions and patriotism I respect. But, sir, to me the future looks dark-da'rk and dreary; and while I trust I shall have a stout heart and strong arm to carry me through this as through any other duty to which my country may call me, while it is yet to be determined upon, my voice is against it.
            </p>
            <div className="speaker" id="sp3766"><span className="persName">Mr. NAPOLEON B. FRENCH</span>, of Mercer—</div>
            <p className="p-in-sp">Mr. President, I think it necessary for me to make a slight explanation of the position I occupy here.</p>
            <p className="p-in-sp">
              I was elected to this body whilst a member of another body in this city. I was not at home during the election; but the party that elected me, over a darling brother of my own, occupying the same position with myself, was the party with which I have acted here from the beginning. I have done all in my power-humble though I am sure my efforts were-to maintain the integrity of the Union,
              and to preserve the peace. I have looked forward hopefully to a settlement of this question without a resort to arms; but that hope has unfortunately been disappointed. I now give, and shall very cheerfully vote with the majority on this occasion, and for the reason that I regard it as important that we shall show a united front to the enemy. I am willing to take my two pounds of
              powder and make the most of it that I can; and shall, moreover, do my utmost for the future to harmonize the people and to bring this war to a termination as early as possible.
            </p>
            <p className="p-in-sp">I shall vote for the ordinance of secession, when the question comes up; and I would in this connection, while up, advise as a matter of some importance to Virginia in my opinion, that the flags that are now spread all through this Commonwealth shall at once be taken down, and the flag of Virginia run up in their stead, so that all can vote under that flag.</p>
            <div className="speaker" id="sp3767"><span className="persName">Mr. WAITMAN T. WILLEY</span>, of Monongalia—</div>
            <p className="p-in-sp">I rise simply to respond to my respected friend from Fauquier [<span className="persName">Mr. SCOTT</span>] .</p>
            <p className="p-in-sp">
              In respect to his appeal, I will say, sir, that when the position of Virginia, shall have been taken, whatever that position shall be, I expect to sustain it-to sustain it in any quarter or in any capacity in which it may be required of me. I will be willing to sustain it in the field if necessary, or by the investment of what little fortune I have,
              <PageNumber num={141} /> if necessary. I shall stand by Virginia to the last. Wherever her fortunes lead her, I will follow-I expect to be with those who follow them.
            </p>
            <p className="p-in-sp">
              But in this crisis I am required, as the representative of a portion of the people of Virginia, to record their convictions upon the question before us, and my own convictions. I believe this proposition wrong. It has not the assent of my judgment. I believe it is the act that is to destroy this State; to destroy liberty itself. I believe, however honest may be-as I know honest they
              are-the sentiments and opinions of gentlemen on this floor, that without the madness, we have yet the folly of Vombier of pulling down the pillars of the glorious temple of liberty, and destroying ourselves.
            </p>
            <p className="p-in-sp">
              I cannot, therefore, record my vote for this measure. I expect to sustain it when it is passed, and I feel that the necessity which compels me to make this declaration is a sure guarantee that the pledge which I make will be redeemed. But when I go home to my constituents, and when I see their trade all destroyed; when I see them cut off from every market they had in the South and
              West; when I see bankruptcy and distress stalking abroad in the midst of the honest constituency whom I represent; when, perhaps, I shall be called to look upon bloody fields and desolate homes-when, in the midst of all this ruin and desolation, I don't want my constituency or my conscience to reproach me with having any agency in producing it; and when I stand before the bar of my
              God, I wish to stand with my skirts all clear of the blood and the carnage which this fatal act will bring upon the State, if she shall adopt such a measure.
            </p>
            <div className="speaker" id="sp3768"><span className="persName">Mr. GEORGE BAYLOR</span>, of Augusta—</div>
            <p className="p-in-sp">I have lived to see fifty seven winters and summers; I have had many trials and difficulties to endure during that time; but I have to confess that this hour is the most trying that I have ever experienced in my life.</p>
            <p className="p-in-sp">We are told that some of my own countrymen have gone on to Harper's Ferry, where, perhaps, blood will be shed. Who are gone, I know not; but I do not deem it improbable that my own son is among the number. If he is not, certainly, some of the best friends I have are there.</p>
            <p className="p-in-sp">
              I came here, as you know, and pronounced myself a Union man, determined at the same time to hold to that position until the very last hope had failed; and, sir, the last hope has failed. I believe that the Union is eternally gone, and the question is now, what is to be done? I had very little difficulty in making up my mind to vote for the proposition of the gentleman from Fauquier
              [<span className="persName">Mr. SCOTT</span>]
              , because I <PageNumber num={142} /> thought it was right, and because I thought it was better that the people should have an opportunity of choosing between two propositions-of deciding whether they would consolidate the border States first, or whether they would adopt an Ordinance of Secession without that procedure. That proposition has been voted
              down, and now the single question comes up of secession or no secession. And what am I to do? I am sorry to say, sir, that I cannot approve of it. Yet, this Convention is going to pass it, whether I vote for it or not; but, in any event, I am determined to go wherever Virginia goes, and I would have gentlemen not suppose for a moment that because I cannot vote for this measure, and
              would prefer that proposed by the gentleman from Fauquier, I would throw any obstacle in the way of the action of the Convention. I may be doing wrong, but if I do, it is an error of the head, and not of the heart. I can say, in the language of one who figured more than three hundred years ago in the Old World, whose name and fame will stand forth in the pages of history identified
              with all that is noble and independent down to the end of time itself-I mean the illustrious Luther. When he was summoned before the dignitaries of the Old World, he observed :
            </p>
            <p className="p-in-sp">"I cannot and will not recant, because it is neither safe nor advisable to do anything against conscience. Here I stand; I cannot act otherwise."</p>
            <p className="p-in-sp">I don't know whether the debates of this day will ever see the light, but if they do, I want it to be seen why I cannot vote for this measure; and I want it to appear that I declared, whatever may be my vote, that I would go wherever the majority of the people of Virginia would go.</p>
            <p className="p-in-sp">I will conclude by tendering to the Convention thanks for the attentive manner in which these few remarks have been listened to.</p>
            <div className="speaker" id="sp3769"><span className="persName">Mr. BENJAMIN WILSON</span>, of Harrison—</div>
            <p className="p-in-sp">Mr. President, no member on this floor feels more deeply than I do the responsibility that devolves upon him. I am here, not to act for myself, but am charged with a representative duty-not of an ordinary character, but of the utmost importance to my people. It is for the people of my county I must speak and act.</p>
            <p className="p-in-sp">The vote just taken upon the proposition of the gentleman from Fauquier, is known to be a test vote. It is known that an ordinance of secession will pass this Convention this day-my vote could not prevent it. I have no desire to hinder what is to be the action of this body. If I was left to act for myself, I could do so without the embarrassment I now feel.</p>
            <PageNumber num={143} />
            <p className="p-in-sp">Sir, I shall shrink from no responsibility, and avoid no issue. But I am at a loss to know what would be the wish of my constituents in this hour of my country's trouble. And, as my vote could not change the result, I ask that I may be excused from voting on the proposed ordinance of secession.</p>
            <p>The excuse was granted.</p>
            <div className="speaker" id="sp3770"><span className="persName">Mr. W. G. BROWN</span>, of Preston—</div>
            <p className="p-in-sp">I do not desire to detain the Convention further than to ask permission for myself and my colleague [<span className="persName">Mr. MCGREW</span>] to file a written statement of the reasons why we shall vote against the ordinance of secession.</p>
            <p>Leave having been granted, the following was handed to the reporter for publication :</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>HALL OF THE CONVENTION,</p>
                <p>Wednesday, April 17th, 1861.</p>
                <p>
                  When the people of Preston county, in February last, first heard of the passage, by the General Assembly, of the act calling a Sovereign Convention of the State, for the purpose of taking into consideration the subject of Federal relations, meetings were held in the several districts of the county, by the people, in which they resolved that they would hold a county convention, on a
                  certain day, in which they would select such persons as would be acceptable to them, and who would faithfully represent their opinions and wishes, to be voted for as members of the said sovereign Convention. That county Convention was accordingly held, and the undersigned were unanimously selected, on account, as they believe, of their known attachment to the Union, and were, on
                  the 4th of February, voted for and elected, without any agency on their part, to produce the result. On the day of election, near two thousand votes were cast, and all but eleven of them were given for a reference back to the people, of what ever the sovereign Convention might do on the subject of an ordinance of secession, or in anywise affecting their relations with the Federal
                  Government. It was understood, on the day of the election, and until we left the county for Richmond, that all the votes cast, except the eleven, were votes for the preservation of the Union.
                </p>
                <p>All the votes given by us in the Convention, have been in opposition to every thing favoring immediate or separate secession of the State from the Union.</p>
                <p>
                  Our constituents have been kept fully informed of our course up to this time, and we have heard of no complaints; but on the contrary, <PageNumber num={144} /> we have been almost daily in receipt of letters from our county fully endorsing our course. Under this state of facts, and from the solemn conviction of our deliberate judgment, we feel it to
                  be our duty to record our votes against an ordinance of secession.
                </p>
                <p>WM. G. BROWN JAMES C. MCGREW</p>
              </div>
            </div>
            <div className="speaker" id="sp3771"><span className="persName">Mr. A. F. HAYMOND</span>, of Marion—</div>
            <p className="p-in-sp">Mr. President, I wish very briefly to give the reasons for the vote which I mean to cast.</p>
            <p className="p-in-sp">
              When I was elected to this Convention, I am satisfied that a large majority of the people were opposed to separate State secession. This Convention has been in session for a considerable period of time, within which several Court days have passed in my county. My votes have gone there, and in no instance have the people intimated, either by resolution or petition, that they have
              undergone any change since I left home.
            </p>
            <p className="p-in-sp">In casting the vote which I have cast for the proposition of the gentleman from Fauquier [<span className="persName">Mr. SCOTT</span>] , I acted, as I believe, strictly in accordance with the wishes of those who sent me here. A majority was opposed to separate State secession when I last saw them, and when I communicated with them in a public manner, I received no instructions conveying any wish to the contrary.</p>
            <p className="p-in-sp">I feel, therefore, that I can but give the negative vote upon the passage of the ordinance of secession.</p>
            <div className="speaker" id="sp3772"><span className="persName">Mr. WM. B. ASTON</span>, of Russell and Wise—</div>
            <p className="p-in-sp">I desire, Mr. President, to say a word or two.</p>
            <p className="p-in-sp">This is with me a very solemn occasion. Up to this time, I have been content to keep my seat, to listen to others and say not a word. I was still disposed to pursue that course, but I felt that it was proper that I, like others, should offer a remark or two in explanation of my vote.</p>
            <p className="p-in-sp">
              I came here as a Union man to represent on this floor a strong Union constituency. But I would say in their regard, that while they are a Union loving people, they are loyal. to Virginia, and zealous for the promotion of every interest of hers. They are ready to do all in their power to maintain her honor and her rights; and, for my part, I would appeal to my brethren here to say how,
              persistently, in every vote I have given, I have endeavored to do that which I thought was calculated to maintain the Union of these States consistently with the honor of Virginia. But, sir, within the last day or two, I have, like every one else on this floor, lost all hope of preserving <PageNumber num={145} /> the Union. I voted for the proposition of
              the gentleman from Fauquier
              [<span className="persName">Mr. SCOTT</span>]
              , because it presented to the people the alternative of a choice between co-operation with the border slave States, or immediate secession ; and because, moreover, it afforded time for preparation, which was needed. That proposition, however, has been lost; and now the only question left for me to decide is, whether I shall vote against the proposition of the gentleman from Montgomery
              [<span className="persName">Mr. PRESTON</span>] , and thereby appear to make a factious opposition to it, or whether I shall respond to the appeals of my friend from Fauquier [<span className="persName">Mr. SCOTT</span>]
              . I have to say that I have determined to go with him. I have had, I confess, much difficulty in examining this question. When I look forward and see nothing before us but war, devastation and utter ruin, I must say that my hopes of the future are dark and ominous. I have already learned by the result of the last vote that a majority of this house have condemned the proposition of the
              gentleman from Fauquier. I see a determination on the part of a majority here to pass this ordinance of secession now, and, in order to give as much unanimity as I can by my vote to the action of this body, I mean to vote in the affirmative.
            </p>
            <div className="speaker" id="sp3773"><span className="persName">Mr. PETER C. JOHNSTON</span>, of Lee and Scott—</div>
            <p className="p-in-sp">
              Mr. President, I would not desire to say a word were it not that there is upon us now a moment of more importance, carrying with it issues of more momentous interest than any upon which the State of Virginia has, perhaps, ever been called upon to vote. In this condition of things, I think it proper for me to state what is the course I shall pursue on the question before the house, and
              to assign, in a few words, the reasons that induce me to take the course that I shall pursue.
            </p>
            <p className="p-in-sp">
              Recent events within the knowledge of all of us, have absolutely forced the State of Virginia, whatever may have been the disposition of a large number in this Convention previous to these events, to say now that she cannot remain where she is; that she cannot remain without doing that which I don't suppose any gentleman would counsel to be incurred without dishonor and disgrace. She
              must move in that condition of things.
            </p>
            <p className="p-in-sp">Two propositions have been presented to the house; one that of the gentleman from Fauquier, as a substitute for that offered by my friend from Montgomery [<span className="persName">Mr. PRESTON</span>] , which is an ordinance of immediate secession. The only question then presented in that attitude of the matter is, all agreeing that the State must move, in what manner shall that movement be made, whether according to the one or the other of these two propositions.</p>
            <PageNumber num={146} />
            <p className="p-in-sp">I am not disposed now to occupy the time of the Convention, to take even a glance at the arguments which have been offered by the gentleman from Fauquier [<span className="persName">Mr. SCOTT</span>]
              in presenting his proposition. They were clear, forcible-to my mind not only unanswerable, but unanswered. The Convention, however, chose to reject it, and I bow to its decision, and shall willingly submit to it. I preferred that proposition to the one now before the House, and from the situation in which I now find myself-with a conscience deeply impressed as to the inevitable
              necessity of the State's moving-there is nothing for me to do but to take the other proposition which the House has not rejected.
            </p>
            <p className="p-in-sp">I concur entirely in the idea of the absolute and inevitable necessity of Virginia moving, and the only difficulty presented to my mind was, which of these two propositions was preferable. One being rejected, I adopt the other with all my heart.</p>
            <p className="p-in-sp">I shall make no professions here of my devotion to the State. I shall make no professions at this stage, for I feel that in doing so I would be trespassing unnecessarily on the time of the Convention. At best, I am satisfied, it would be superfluous; for those who know me need no assurance of my fidelity to the cause of Virginia.</p>
            <p className="p-in-sp">I will conclude by saying that I shall cast my vote for the ordinance of secession, the Convention having failed to adopt the competing proposition.</p>
            <div className="speaker" id="sp3774"><span className="persName">Mr. LOGAN OSBURN</span>, of Jefferson—</div>
            <p className="p-in-sp">
              Mr. President, I have no disposition to detain the Convention further than to remark that I voted for the proposition of the gentleman from Fauquier with great cordiality and much pleasure, because its plan of co-operation with the border slave States seemed to me to embrace the great want which, it is to be feared, will prove detrimental to us when it will be too late to remedy it. We
              need, moreover, considerable preparation, and this we would have an opportunity of making if the proposition of the gentleman from Fauquier was carried.
            </p>
            <p className="p-in-sp">
              I have received letters from my constituents since I came here, and they all sustained me in the position which I have taken here. I received a letter last night which takes this position. All in fact are utterly opposed in sentiment to the adoption of an ordinance of secession, and, convinced, that this is the general sentiment of my people, I feel bound to vote against it. While
              doing so, I am not disposed by any vote I shall cast here to prevent entire unanimity in the action of this body. I hope that no emergency will prevent <PageNumber num={147} /> gentlemen here from submitting their action to the people. If they adopt an ordinance of secession, I shall most cheerfully acquiesce.
            </p>
            <div className="speaker" id="sp3775"><span className="persName">Mr. JOHN D. SHARP</span>, of Lee—</div>
            <p className="p-in-sp">
              Mr. President, I hope this Convention will indulge me for a few moments, whilst I explain the vote I shall give on this momentous question on which we are now called to vote. The action of this Convention on this question cannot be final. Whatever it may do, before that action can be final, must be ratified by the people at the polls. Whenever, and however the people may decide, I, as
              a Virginian, will bow to that decision.
            </p>
            <p className="p-in-sp">But we are asked to give a vote on this question, that is to go forth to the people as a recommendation of it to their favorable consideration and vote.</p>
            <p className="p-in-sp">
              Sir, I cannot give that recommendation to the measure. I believe the separate and immediate secession of Virginia is equivalent to immediate war. And in the unprepared and defenceless condition of the State, war is destruction and direful ruin to her. She is without soldiers, arms, money and credit; and with a debt of forty millions hanging over her without the means of payment.
            </p>
            <p className="p-in-sp">I shall therefore vote no. I cannot recommend it.</p>
            <div className="speaker" id="sp3776"><span className="persName">Mr. WM. C. SCOTT</span>, of Powhatan—</div>
            <p className="p-in-sp">
              I will ask for a division of the question-the division to consist in taking a separate vote upon the ordinance and preamble. In intimating this desire to divide the question, I am influenced by a wish to have some change made in the preamble, for it seems to me to be somewhat defective. I do not mean to be understood as seeking to avoid casting my vote upon it. That I shall do in any
              event.
            </p>
            <p>At this stage of the proceeding, Lieutenant Governor ROBT. L. MONTAGUE took the chair and occupied it till after the passage of the ordinance of secession.</p>
            <div className="speaker" id="sp3777"><span className="persName">Mr. WILLIAM H. B. CUSTIS</span>, of Accomac—</div>
            <p className="p-in-sp">Mr. President, I was in hopes when the Committee of the Whole adjourned and went into Convention, that then I would have an opportunity to make my views known upon this all absorbing and important question. That opportunity has not been afforded me, and I rise now simply to enter my most solemn protest against the passage of this ordinance.</p>
            <p className="p-in-sp">
              Sir, what is it? A declaration of war against our Government. Believing it to be such, never, so help me God, shall it receive my vote. I know not what may be in the future. I have a wife and six little children standing around her knee and sitting on her lap, and I see nothing but ruin to that family and hundreds of others besides them, if this ordinance shall pass.
            </p>
            <PageNumber num={148} />
            <p className="p-in-sp">
              What is our condition on the sea coast. We cannot be protected. We cannot have communication with our old mother Commonwealth. Separated by a bay thirty five miles wide, with inlets to admit the enemy and render us liable in a single night to have our property consumed, our negroes run off, our all taken off by a reckless rabble from the Northern States, without a gun or a rifle in my
              county; with only a few blank cartridges, a few balls and a few little pieces of ordnance which were sent there to keep down servile insurrection, in case it should break out. I say, sir, with such disadvantages, who can doubt that our condition, in the event of war, must be desperate. I see nothing, in fact, but ruin for my people in the policy which it is intended to inaugurate here
              to-day.
            </p>
            <p className="p-in-sp">A few years ago, sir, when a boy so high [describing the height]
              I stood by the bed side of the only parent I ever knew. Besides many other wise counsels he gave me, he told me to try always and do right; to walk steadily and constantly in the path of duty, and that God would bless my efforts. I have sought zealously to observe these counsels; I have tried with all my might to discharge my duty to all, and I congratulate myself that so far the
              results have been very much as predicted by my good parent. Upon that arm depends the support of my family; but, so help me God, I would have it cut from its place before I would use my hand to sign an ordinance of secession. I voted for the proposition of the gentleman from Fauquier
              [<span className="persName">Mr. SCOTT</span>] , but I cannot vote for the proposition of the gentleman from Montgomery [<span className="persName">Mr. PRESTON</span>]
              , because I do not believe it to be for the good of the Commonwealth; and if no other man in this assembly will cast his vote against it, I will stand here solitary and alone and do it. I will not only do that, but when I go back to my people, I will vote against it and advise the people to vote against it. But if a majority of these people vote for it, then it becomes the act of the
              people and I will submit; and whenever the standard of the Old Dominion floats, I will rush to it; and I venture to say I will be found as true to it as the most ultra in this Convention or out of it. In the language of my friend from Princess Anne
              [<span className="persName">Mr. WISE</span>]
              , employed in the first speech which he delivered in Congress, I am intus et in cute a Virginian. If honor shall flow from this day's proceedings you of the secession party are entitled to it all; but if ruin, bloodshed, desolation and disgrace shall be the consequence, as I very much apprehend will be the case, you must step forward and say, "we did it and we are ready to abide the
              consequences." Great, indeed, will be the responsibility that must attach to so awful a proceeding if such results as I have referred to <PageNumber num={149} /> should follow. For my part, I wash my hands of the whole matter. Never, never will I sanction this movement until it is sanctioned and ratified by the sovereign people of Virginia. There all
              sovereignty resides. Whatever may be the consequence of this proceeding, I hope that the great principle of self-government will be protected and upheld for all time; that it may be transmitted in all its purity and perfection to our latest posterity, and may flourish in all its original splendor when that city yonder
              [referring no doubt to Washington] will crumble to dust.</p>
            <div className="speaker" id="sp3778"><span className="persName">Mr. JOHN JANNEY</span>, of Loudoun [Lieutenant Governor ROBERT L. MONTAGUE, of Mathews and Middlesex, in the Chair]—</div>
            <p className="p-in-sp">I beg the indulgence of the Convention while I depart from a resolution I formed the moment you elected me to that chair-to take no part in your deliberations. But the moment has come when I feel myself impelled, by a sense of duty, to state, with all the brevity I can, the reason why I cannot vote for this ordinance of secession.</p>
            <p className="p-in-sp">I always feel the force of every appeal that is made to me by my friend from Fauquier [<span className="persName">Mr. SCOTT</span>]
              , and when he invited us all to come up and present a united front in favor of this ordinance, I felt as if I would have done it, if I could, without violating my own sense of duty. I know not what course I may pursue when I go home, although I am determined now to vote against this ordinance of secession. But if I had resolved, from the moment my foot touched the soil of the county in
              which I live, to urge the people there to vote for the ordinance of secession, I would, as a matter of policy, vote against it; and I appeal to all gentlemen, who have been representing Union constituencies like myself, to say, if, by carrying out the will of their constituencies here in the vote they are about to give, they could not return home and operate with more power, and more
              influence upon them, than if they vote here to violate the will of those constituents who sent them.
            </p>
            <p className="p-in-sp">Sir, I know what is before me; I know that an Ordinance of Secession is war, a cruel, bloody, civil war. The gentleman from Augusta [<span className="persName">Mr. STUART</span>]
              , who spoke yesterday, alluded to my county and its position. Sir, it is true as he has intimated, there is a large revolutionary highway over which the armies of the country marched North and South, during the whole of that war; which I cross every day that I put my foot outside of my office. There is the old highway over which Braddock's army was marched to Saratoga; and there is
              that old highway now leading into the heart of the most populous and powerful portion of the great State of Pennsylvania, with its three <PageNumber num={150} /> million of inhabitants, and we within three miles of that State, separated from it only by a river that is fordable for six months in the year. I desire to know of gentlemen here to day if any
              power that ever governed any civilized nation upon the face of the earth, ever plunged their country into a war with such fearful odds against them, without having a single battalion of regular soldiers, with a beggarly account of empty armories and magazines, with a frontier of more than 1,200 miles without a fort or fortification upon any one of its headlands. My friend from Augusta
              [<span className="persName">Mr. STUART</span>]
              , yesterday, told you that I lived in the Flanders of Virginia, or rather of this war. When you shall have passed this ordinance of secession the Potomac will be the Rhine, the borders of which for miles on either side, from where it takes its rise in the Alps to where it empties into the German ocean, has been saturated with human blood for the last three centuries. Gentlemen must
              have read history in vain if they can point out to me any boundary between two nations in any civilized continent on the globe where the same result has not been produced. Aye, gentlemen, your wives and children in the county of Montgomery, up in the mountains, the wives and children of the gentleman from Montgomery
              [<span className="persName">Mr. PRESTON</span>] and the gentleman from Bedford [<span className="persName">Mr. GOGGIN</span>] will all be asleep peacefully at midnight, when others will be fleeing with their children in their arms, by the light of their burning dwellings.</p>
            <p className="p-in-sp">What I demand of you is this : will you make a declaration of war-for that is what this ordinance of secession will amount to-without preparing to defend our people who live upon the frontier, who are exposed to the assaults of the enemy, and upon whom they will come thick and heavy? I see before me now my venerable friend from Charles City [Ex-President TYLER]
              . I demand to know of him, if he were President of the United States, whether he would ever have recommended to Congress to declare a war under such circumstances as now press upon us, with a force against him of eighteen to one, with a powerful navy upon the other side, and he without a gun boat; with armies drilled and disciplined, and with a command of 800,000 militia, would he have
              recommended to Congress to pass a declaration of war without waiting sixty days to make some preparation for it? I imagine not, sir.
            </p>
            <p className="p-in-sp">
              We have been told here that the power of these Confederate States once organized into a regular government, with a regular army, would be sufficient to meet the emergency of a war such as is now threatened. Gentlemen, that is a tried failure. I tell you that the Confederate army never will and never can march to our assistance. Not that
              <PageNumber num={151} /> they are not as brave and gallant men as any in the world; but they will use all their means and power to protect themselves at home.
            </p>
            <p className="p-in-sp">
              I looked lately into the history of the control by the government of Spain of the mouth of the Mississippi river. I learned there that all Western Virginia, all Tennessee, all Kentucky were aroused almost to open rebellion against their own government, because they would not get the command of the mouth of the Mississippi by treaty, and if not by treaty, by force of arms; and when Mr.
              Jefferson became President, he wrote to France and stated that that country
              [referring to the country along the mouth of the Mississippi]
              must come under the control of the United States at the hazard of our existence; and he sent Mr. Monroe there to aid Mr. Livingston in negotiating for its acquisition. Mr. Livingston wrote to Mr. Monroe at Havre, saying, "you came here too soon-you ought to have gone down there and taken the right, and then come here to negotiate it." And now what is the state of the case? North of the
              Ohio, which was then a wilderness, is now filled with its teeming millions of brave, hardy people, and their outlet is down the Mississippi river; and, sir, as sure as God is in Heaven, lament as we may, and lament as I should, and always would any conflict, they would have the mouth of the Mississippi river, whether the Government at Washington says aye or no. In the Mechanics' Hall,
              when the manly form of the gentleman from Mississippi
              [<span className="persName">Mr. STARKE</span>]
              <span className="note" id="Note17"><span className="noteLabel">6</span>This name should be that of Mr. Anderson, the Commissioner from Mississippi.</span> was standing by my side addressing this Convention, I looked upon him with sadness and with sorrow. Facts which I had forgotten were all fresh in my memory, and I could not but reflect upon the dreadful spectacle of thousands of armed men
              floating down that river, not that river alone, but all its tributaries, to clean out the fertile regions which line them on every side with habitations.
            </p>
            <p className="p-in-sp">
              Mr. Clay stated, in 1840, that while some had talked of a South-Western Confederacy, it was certain that those who lived upon the Mississippi would never permit a barrier to lodge at the Belize or over the falls of the Crescent city. Mr. Vallandigham, in the Congress of the United States, told them that they of the North-West would hew their way to the mouth of that river, sword in
              hand. So that you will get no aid from that quarter.
            </p>
            <p className="p-in-sp">
              Permit me to say, that what I have said has been said in no spirit of reproach upon any of these Southern States that have thought proper to change their relation to the General Government. I thought the hour had come to look facts in the face as they were, and to see <PageNumber num={152} /> whether we could hope for any aid from the North-West in this
              new Confederacy which you are about to create.
            </p>
            <p className="p-in-sp">I propound this question now to you, and ask you to respond to it, not governed by passion or prejudice, or by the excitement of the hour, but clearly and calmly, if it be possible.</p>
            <p className="p-in-sp">
              If Lord Palmerston, the British Premier, were to involve his country in a war with France, or with any nation upon the face of the globe, without preparation, with a force of eighteen to one against him, ought not he to be impeached, and lose his head? What has Louis Napoleon been about ever since he ascended the throne? Making preparations for the great Italian campaign. He has been
              ever since the close of that campaign making preparations for any war that may turn up, or that he may choose to originate, whenever it suits his will and pleasure.
            </p>
            <p className="p-in-sp">The proposition of my friend from Fauquier [<span className="persName">Mr. SCOTT</span>]
              , was a favorite proposition of mine, and I think ought to have been adopted, because it would have given us time, at least, to have expended this million appropriated by the Legislature before the shock of arms should come upon us. My belief is, that if this ordinance passes here to day, and more, if it passes by the unanimous vote of this body, that it will be regarded as an act of
              war, and so treated by the General Government.
            </p>
            <p className="p-in-sp">I shall vote against this ordinance of secession, for the reason, among others, that if there be not a unanimous vote upon it, there may be a pause on the part of the General Government until our people vote upon it. In the mean time we will have an opportunity to make some necessary preparation to meet an emergency of war, if such should result.</p>
            <p className="p-in-sp">
              This ordinance of secession was brought before this body yesterday. The previous question was moved upon it and the ten minutes rule adopted, and now we are called upon to give a vote that, in my judgment, is pregnant with the issue of human freedom all over the globe. I believe that if this experiment fails there is no hope left for representative government and liberty regulated by
              law. I believe that the dark night of universal despotism will settle down over the whole globe, upon the failure of this great experiment of ours. This is not the only proposition before us. Not at all. We have other propositions before us. We have a proposition in the resolutions and in the amendments to the Constitution which are laying over. These contain a plan of conference and
              co-operation with those border sister States of ours. I thank the Convention, not only for the kindness and <PageNumber num={153} /> indulgence that they have extended to me now, but for that which I have received in my entire sphere of action during the whole session of this body. I have felt it due to my constituents to enter this, their protest, in
              view of the peculiar circumstances in which they are placed, against this act dissolving the Union.
            </p>
            <div className="speaker" id="sp3779"><span className="persName">Mr. MIERS W. FISHER</span>, of Northampton—</div>
            <p className="p-in-sp">I do not desire to occupy the ten minutes allotted to each member, but before I commence the remarks I intend to make, I ought to say to the gentleman from Loudoun [<span className="persName">Mr. JANNEY</span>]
              , that he ought to recollect that for the last two months we have been discussing this question, whether the State should secede or not. This question was not mooted for the first time yesterday. It is one with which every member here, I imagine, is perfectly familiar. It does not become gentlemen to seek to cast odium upon what was once the minority in this Convention, but what is now
              the majority, for calling the previous question when they themselves voted to restrict debate to ten minutes at an early stage of the session; and when they had lying upon the table a resolution declaring that as soon as the question under debate at the time would have been disposed of, in Committee of the Whole, that the same gag was again to be applied. It is not, therefore, becoming
              in gentlemen, as I humbly conceive, that they should be making complaints of this sort at this time. Why, if the arguments of gentlemen are to avail here, no person would vindicate his violated honor. They are making this a question of dollars and cents, and not one of honor. My friend from Accomac
              [Mr. CusTrs]
              , who really has elicited this reference, has told you about the exposed state of his county. Sir, my county is as much exposed as his, with not half the population that his possesses, with not half the arms within its borders that are within the borders of his county. There is not a tree, as you know, Mr. President, between my residence and Europe or Africa, and on the other side the
              Chesapeake washes some of my possessions that are not distant above five miles from my residence; and, sir, one-third of my whole estate consists of slaves, and these, if necessary, I am willing at any time to dedicate to the honor and independence of Virginia. The invaders cannot take my lands; but, as I have said, if it be necessary to the maintenance of Virginia's honor and the
              establishment of her independence of Yankeedom, that all my slaves should be lost to me, I will cheerfully abide the loss. They are no consideration with me in an issue of this sort, and especially so, when I feel confident that the coming conflict will close with Virginia's wrongs fully avenged. I expect, after all, I will be able to live.
            </p>
            <PageNumber num={154} />
            <p className="p-in-sp">I have more confidence in Divine Providence than the gentleman from Loudoun [<span className="persName">Mr. JANNEY</span>] , who seems to have a belief that everything that is within the pale of Virginia is sacred. The gentleman ought to recollect that if we have no forts on our borders, neither has Pennsylvania or Ohio. But, sir, if we have no forts we have a gallant people. We have a people who "know their rights and dare maintain them" at all and every hazard.</p>
            <p className="p-in-sp">
              The gentleman says that there will be a declaration of war as soon as the ordinance of secession is passed. Has he forgotten that a declaration of war is already made by Abraham Lincoln? Has he forgotten that Abraham Lincoln, as President of the United States, has called upon Virginia to furnish her quota of troops, not only to suppress the insurrection or the rebellion, as he chooses
              to call it, in the Confederate States, but even in our own Commonwealth? If this ordinance be adopted, and the gentleman from Loudoun
              [<span className="persName">Mr. JANNEY</span>]
              believes afterwards that Abraham Lincoln can exercise any restraint upon him, I hope it will be a constitutional restraint; and that if, as the gentleman apprehends, war should be declared, which I maintain is already declared, it will be a declaration in keeping with the forms presented by the Constitution, and not an act of usurpation, such as he seems thus disposed to perpetrate. If
              Congress shall declare war, I accept the issue. I never mean to be deferred from declaring my rights because I apprehend that some stronger men than myself will try to wrest it from me.
            </p>
            <div className="speaker" id="sp3780"><span className="persName">Mr. CYRUS HALL</span>, of Pleasants and Ritchie—</div>
            <p className="p-in-sp">Mr. President, I will only make a few remarks before I give my vote.</p>
            <p className="p-in-sp">I must confess that I find myself today in a very peculiar position. I am here among the members of my section, with only three others and myself prepared to vote for an ordinance of secession.</p>
            <p className="p-in-sp">
              There are in fact, but four who are prepared to vote for this ordinance, out of thirty counties in the North West. I have been importuned by those from that section who are opposed to this policy, to vote against the ordinance; and I must confess that their appeals have had some influence upon me. I had determined in my own mind to vote for an ordinance of secession; but events have
              transpired and indications have been furnished which brought dismay to some of the strongest hearts from my section, arid I almost determined to abandon, for the present, my original purpose. Upon testing the feelings of the members from my Congressional District, I ascertained that out of 18 counties, which it comprises, there were but two-another and myself-who were willing to vote
              for an ordinance of secession as a last resort.
            </p>
            <PageNumber num={155} />
            <p className="p-in-sp">
              I found that my friends all around, North and South of my counties, intended to vote against this ordinance, while others asked to be excused from voting, and the House granted the leave. I should like very much to have that favor extended to me, while, at the same time, I will return to my people if this ordinance is passed, and will urge upon them to ratify it by their votes at the
              polls.
            </p>
            <p className="p-in-sp">You can very easily understand the position I occupy. I have had no instructions from my people. I am now four hundred miles from them; they have entrusted their destiny to my keeping, and, in the absence of instructions, I feel that I should act with extreme caution.</p>
            <p className="p-in-sp">The gentleman from Harrison [<span className="persName">Mr. WILLEY</span>] whom I know to have considerable influence in the counties I represent, is aware that the people of these counties hold intimate relation with the people of Ohio; and, having no instructions from them, I don't know but that the vote I may give may plunge them into civil war.</p>
            <p className="p-in-sp">
              I have concluded, this morning, that there is but one course that I could see marked out for me, and that was to cast my vote for this ordinance of secession and leave the result to God. It is true, as has been said here by gentlemen from the North-West, that we have not a market in that whole country, nor have we any means of defence whatever. We are cut off from you, and we will soon
              be cut off, I have no doubt, from the city of Baltimore, with which we carried on a large trade. I was going on to remark that if it would be the pleasure of the Convention to excuse me from voting, I would thank them for it, but if not excused, I trust I shall have the nerve to meet the emergency and vote for this ordinance. I am importuned by many men from my section, whose judgment
              is more ripe than my own, and who probably have looked carefully into this matter, not to vote for the ordinance. I determined, before I would yield altogether my judgment to theirs, to ask this Convention to excuse me from voting, which, if they do, I shall thank them for; but if they refuse to do so, I shall not shrink from the responsibility, let the result be what it may to me and
              my people. Though we stand at the point of danger, live or die, sink or swim, I will record my vote in favor of the ordinance, and give my heart and hand to the cause of my mother State.
              [Applause.] The main question, which was the ordinance of secession, was then ordered to be put.</p>
            <div className="speaker" id="sp3781"><span className="persName">Mr. WISE</span>—</div>
            <p className="p-in-sp">I ask for the yeas and nays upon the adoption of the ordinance.</p>
            <p>The call was sustained.</p>
            <PageNumber num={156} />
            <div className="speaker" id="sp3782"><span className="persName">Mr. WM. B. PRESTON</span>—</div>
            <p className="p-in-sp">I would ask that the vote be first taken on the ordinance itself, and that the preamble be acted upon afterwards.</p>
            <p>It was so ordered.</p>
            <p>The question was then taken on the adoption of the ordinance.</p>
            <div className="speaker" id="sp3783">When the name of <span className="persName">Mr. TIMOTHY RIVES</span>, of Prince George and Surry, was called, he said:</div>
            <p className="p-in-sp">The Government being already overthrown by revolution, I vote "aye."</p>
            <div className="speaker" id="sp3784"><span className="persName">Mr. A. T. CAPERTON</span>, of Monroe, before the vote was announced, said:</div>
            <p className="p-in-sp">I have no doubt there are many gentlemen who, upon a night's reflection, would be disposed to record their votes in favor of this ordinance of secession; and, with a view to afford them an opportunity of a night's reflection, and in order to have the vote as nearly unanimous as possible, I move we adjourn.</p>
            <div className="speaker" id="sp3785"><span className="persName">Mr. A. H. H. STUART</span>, of Augusta—</div>
            <p className="p-in-sp">I have heard the question asked whether gentlemen were at liberty to speak of the result of this vote. I think it is a matter of the very highest importance that the matter should be kept secret for the present.</p>
            <p>
              The question was then taken on the motion to adjourn, and lost. The vote on the adoption of the ordinance of secession was then announced. It stood-yeas 88, nays 55, as follows: <span className="note" id="Note18"><span className="noteLabel">7</span>The Journal adds Rives to the affirmative votes listed here.</span>
            </p>
            <p>
              YEAS-Messrs. Ambler, Aston, Jas. Barbour, Blakey, Blow, Boisseau, Borst, Bouldin, Boyd, Branch, Bruce, Cabell, Campbell, Caperton, Cecil, Chambliss, Chapman, Coffman, Conn, James H. Cox, Richard H. Cox, Critcher, Deskins, Dorman, Echols, Fisher, Flournoy, Forbes, French, Garland, Gillespie, Graham, Gregory, Goggin, John Goode, Thomas F. Goode, Hale, Cyrus Hall, L. S. Hall, Harvie,
              Holcombe, Hughes, Hunton, Isbell, Marmaduke Johnson, P. C. Johnston, Kent, Kindred, Lawson, Leake, Macfarland, Chas. K. Mallory, James B. Mallory, Marye, Sr., Miller, Moffett, Montague, Morris, Morton, Neblett, Orrick, Parks, Preston, Randolph, Richardson, Robert E. Scott, William C. Scott, Seawell, Sheffey, Slaughter, Southall, Speed, Staples, Strange, Sutherlin, Tayloe, Thornton,
              Tredway, Robt. H. Turner, F. P. Turner, Tyler, Waller, Whitfield, Williams, Wise, Woods, Wysor-88.
            </p>
            <p>NAYS-Messrs. Jno. Janney [President]
              , Armstrong, Baldwin, Baylor, Berlin, Boggess, Brent, Brown, Burdett, Burley, Byrne, Carlile, Carter, Clemens, C. B. Conrad, R. Y. Conrad, Couch, Custis, Dent, Dulany, Early, Fugate, Gravely, Gray, Ephraim B. Hall, Hammond, Haymond, Hoge, Holladay, Hubbard, Hull, Jackson, Lewis, McComas, McGrew, Marshall, Masters, Moore, Nelson, Osburn, Patrick, Pendleton, Porter, Price, Pugh, Sharp,
              Sitlington, Spurlock, Alexander H. H. Stuart, Chapman J. Stuart, Summers, Tarr, White, Wickham, Willey-55.
            </p>
            <div className="speaker" id="sp3786"><span className="persName">Mr. JOHN M. SPEED</span>, of Lynchburg—</div>
            <p className="p-in-sp">At the suggestion of members around me, I rise to suggest that the injunction of secrecy be removed, so far as it relates to the passage of the ordinance of secession.</p>
            <PageNumber num={157} />
            <p>No action was had upon the suggestion. The question then recurred upon the adoption of the preamble to the ordinance.</p>
            <div className="speaker" id="sp3787"><span className="persName">Mr. JOHN J. JACKSON</span>, of Wood—</div>
            <p className="p-in-sp">I call for the yeas and nays upon the adoption of the preamble.</p>
            <p>The call was sustained.</p>
            <div className="speaker" id="sp3788"><span className="persName">Mr. JOHN ECHOLS</span>, of Monroe—</div>
            <p className="p-in-sp">I understand the injunction of secrecy applies to writing or speaking. I desire to call the attention of gentlemen to the necessity of their withholding all information upon the result of this vote from even their friends at home, as it is very important that the whole matter should be unknown for several days.</p>
            <p>
              The question on the adoption of the preamble to the ordinance was then put, and decided in the affirmative; yeas 74, nays 64, as follows:<span className="note" id="Note19"><span className="noteLabel">8</span>The Journal adds L. S. Hall to the affirmative votes listed here; the Journal lists 62 negative votes, adding Carter and Clemens to those given here.</span>
            </p>
            <p>
              YEAS-Messrs. Ambler, Jas. Barbour, Blakey, Blow, Boisseau, Borst, Bouldin, Bruce, Cabell, Caperton, Cecil, Chambliss, Chapman, Coffman, Conn, R. H. Cox, Deskins, Dorman, Echols, Fisher, Flournoy, Forbes, Garland, Graham, Gregory, Goggin, John Goode, Thos. F. Goode, Hale, Cyrus Hall, Harvie, Holcombe, Hughes, Hunton, Isbell, Marmaduke Johnson, Kent, Kindred, Leake, C. K. Mallory, J. B.
              Mallory, Marye, Miller, Moffett, Montague, Morris, Morton, Neblett, Orrick, Parks, Preston, Randolph, Richardson, R. E. Scott, Seawell, Sheffey, Slaughter, Southall, Speed, Staples, Strange, Sutherlin, Tayloe, Thornton, Tredway, R. H. Turner, F. P. Turner, Tyler, Whitfield, Williams, Wise, Woods, Wysor-74.
            </p>
            <p>NAYS-Messrs. John Janney [President]
              , Armstrong, Aston, Baldwin, Baylor, Berlin, Boggess, Branch, Brent, Brown, Burdett, Burley, Byrne, Campbell, Carlile, C. B. Conrad, Couch, Critcher, Custis, Dent, Dulany, Early, French, Fugate, Gravely, Gray, Ephraim B. Hall, Hammond, Haymond, Hoge, Holladay, Hubbard, Hull, Jackson, Peter C. Johnston, Lewis, McComas, McGrew, Macfarland, Masters, Moore, Nelson, Osburn, Patrick,
              Pendleton, Porter, Pugh, Rives, Wm. C. Scott, Sharp, Sitlington, Spurlock, A. H. H. Stuart, Chapman J. Stuart, Summers, Tarr, Waller, White, Wickham, Willey-64.
            </p>
            <p>The following is the preamble and ordinance as they were adopted :</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>AN ORDINANCE</p>
                <p>To repeal the Ratification of the Constitution of the United States of America, by the State of Virginia, and to resume all the rights and powers granted under said Constitution.</p>
                <p>
                  The people of Virginia, in their ratification of the Constitution of the United States of America, adopted by them in Convention, on the 25th day of June, in the year of our Lord one thousand seven hundred and eighty-eight, having declared that the powers granted under the said Constitution were derived from the people of the United
                  <PageNumber num={158} /> States, and might be resumed whensoever the same should be perverted to their injury and oppression, and the Federal Government having perverted said powers, not only to the injury of the people of Virginia, but to the oppression of the Southern slaveholding States :
                </p>
                <p>
                  Now, therefore, we, the people of Virginia, do declare and ordain that the ordinance adopted by the people of this State, in Convention, on the 25th day of June, in the year of our Lord one thousand seven hundred and eighty-eight, whereby the Constitution of the United States of America, was ratified-and all acts of the General Assembly of this State ratifying or adopting
                  amendments to said Constitution-are hereby repealed and abrogated; that the Union between the State of Virginia and the other States under the Constitution aforesaid is hereby dissolved, and that the State of Virginia is in the full possession and exercise of all the rights of sovereignty which belong and appertain to a free and independent State. And they do further declare that
                  the said Constitution of the United States of America is no longer binding on any of the citizens of this State.
                </p>
                <p>This ordinance shall take effect and be an act of this day when ratified by a majority of the votes of the people of this State, cast at a poll to be taken thereon on the fourth Thursday in May next, in pursuance of a schedule hereafter to be enacted.</p>
                <p>Done in Convention, in the city of Richmond, on the seventeenth day of April, in the year of our Lord one thousand eight hundred and sixty one, and in the eighty-fifth year of the Commonwealth of Virginia.</p>
              </div>
            </div>
            <div className="speaker" id="sp3789"><span className="persName">Mr. WISE</span>—</div>
            <div className="stage it">[<span className="persName">Mr. WM. C. WICKHAM</span> who had the floor, yielding it]</div>
            <p className="p-in-sp">
              -I merely rise to say to the Convention, that your work now is just begun, and that the Governor of the Commonwealth will need immediate co-operation upon those measures which I know he has instituted. He will want the sanction of this Convention to take steps for the defence of the Commonwealth, which he has every reason to believe is now invaded. I hope we will not adjourn over until
              tomorrow. If we do, your secret is out at once. As certain as you adjourn over till to-morrow, they will consider your work as done. We had better take a recess until about 7 or 8 o'clock this evening. I will split the difference, and say half past 7. It will keep up appearances, and look as if we were struggling on this question; and really there will be some work which we want to do.
            </p>
            <p className="p-in-sp">Before the question is taken on the motion for a recess, I would offer the following resolution.</p>
            <p>
              Resolved, That the President of this Convention communicate in <PageNumber num={159} /> confidence, by special messenger, the ordinance of the Convention resuming the powers granted under the Constitution of the United States to the Federal government, to the President of the Confederate States, and that the obligation of secrecy be removed so far as it
              applies to the Governor of this Commonwealth, with a request to observe it as strictly confidential, except so far as he may find it necessary to issue secret orders.
            </p>
            <div className="speaker" id="sp3790"><span className="persName">Mr. E. B. HALL</span>, of Marion—</div>
            <p className="p-in-sp">I move to lay that resolution on the table.</p>
            <p>The motion was lost.</p>
            <div className="speaker" id="sp3791"><span className="persName">Mr. J. S. HOLLADAY</span>, of Norfolk county—</div>
            <p className="p-in-sp">I call for a division of the question.</p>
            <div className="speaker" id="sp3792"><span className="persName">Mr. Wm. C. SCOTT</span>, of Powhatan—</div>
            <p className="p-in-sp">I shall vote against the resolution.</p>
            <p className="p-in-sp">
              I had a consultation this morning with the Adjutant General, and he said that he thought it highly improper that any of the proceedings of this Convention should be made known outside of this Hall, until we should get the arms from the North. The Governor tells us this morning that if the action of this Convention is permitted to be known outside of this body, these arms will not be
              allowed to come here.
            </p>
            <p className="p-in-sp">If you send a communication of this sort to the President of the Confederate States, there will be great danger that the whole secret will leak out.</p>
            <div className="speaker" id="sp3793"><span className="persName">Mr. JOHN B. BALDWIN</span>, of Augusta—</div>
            <p className="p-in-sp">
              It seems to me that this question brings up directly for consideration, not as fully as I should like to see it presented, but yet it brings up the whole subject of the secrets and plans of this Convention in regard to that subject. As I said to a friend who consulted me this morning in regard to it, while I have formed an opinion, my mind is open to conviction in regard to the extent
              of the power of this Convention. But it seems to me as being highly important that we should, before we take another step, have the whole subject brought up and deliberately discussed. I hold that all who acquiesce in the action of the Convention feel desirous to co-operate in every legitimate function necessary to place the State in a proper position for the people to act upon the
              ordinance of secession. I feel it important that we shall not forget, although we have passed an ordinance of secession, that that ordinance is of no validity until it is passed upon by the people. We yet live under a Constitutional Government, which limits all the power by strict States Rights rules; and, sir, the charter of our organization limits us in every change we may make in
              the organic law of the State, by organization, <PageNumber num={160} /> that such must be subject to the ratification of the people at the polls.
            </p>
            <p className="p-in-sp">Now, if we wish to observe, as I think it important that we should observe, the limitations of Constitutional right which belong to American Republicanism, if we want to observe the safe guards of public liberty, it seems to me of the utmost consequence that we shall keep within the scope of our power, before we take any action.</p>
            <p className="p-in-sp">With that view, I move, without taking any vote upon this subject at all, we adjourn.</p>
            <div className="speaker" id="sp3794"><span className="persName">Mr. DORMAN</span>, of Rockbridge—</div>
            <p className="p-in-sp">Will the gentleman allow me to make a simple suggestion?</p>
            <p className="p-in-sp">There is no objection to the second part of that resolution removing the secrecy so far as the Governor is concerned.</p>
            <p className="p-in-sp">I would ask the gentleman from Princess Anne [<span className="persName">Mr. WISE</span>] , to withdraw the first part of his resolution until the adjourned session, and allow the latter part to be adopted.</p>
            <p>No discussion being in order, the question on the adjournment was taken and decided in the negative.</p>
            <div className="speaker" id="sp3795"><span className="persName">Mr. WISE</span>—</div>
            <p className="p-in-sp">I move, now, that the Convention take a recess until half past 7 o'clock this evening.</p>
            <p>The motion was agreed to, and the Convention accordingly took a recess until the hour mentioned.</p>
            <div className="session" id="vsc1965.v4.2.2.1">
              <h3><span className="headingNumber">1.2.1. </span><span className="head">EVENING SESSION</span></h3>
              <p>The Convention re-assembled at half past 7 o'clock, P. M., pursuant to adjournment.</p>
              <div className="speaker" id="sp3796"><span className="persName">Mr. GEORGE W. RANDOLPH</span>, of Richmond—</div>
              <p className="p-in-sp">Mr. President, I hold in my hand three resolutions which I propose to offer.</p>
              <div className="speaker" id="sp3797"><span className="persName">THE PRESIDENT</span>—</div>
              <p className="p-in-sp">There is a resolution now pending.</p>
              <div className="speaker" id="sp3798"><span className="persName">Mr. RANDOLPH</span>—</div>
              <p className="p-in-sp">The gentleman from Princess Anne [<span className="persName">Mr. WISE</span>] , told me he would give me precedence, in consideration of the importance of the resolutions which I propose to offer.</p>
              <div className="speaker" id="sp3799"><span className="persName">Mr. WICKHAM</span>, of Hanover [a delegate from Henrico]—</div>
              <p className="p-in-sp">I yielded the floor to the gentleman from Princess Anne, with the understanding that when the resolution which he was about to offer should be disposed of, I would be entitled to the floor.</p>
              <div className="speaker" id="sp3800"><span className="persName">Mr. RANDOLPH</span>—</div>
              <p className="p-in-sp">If the gentleman from Henrico will allow me to read the resolutions, he will find that two of them will elicit no debate. It is extremely important that the Governor should be armed with sufficient authority to use sufficient force to defend the Commonwealth.</p>
              <div className="speaker" id="sp3801"><span className="persName">Mr. WICKHAM</span>—</div>
              <p className="p-in-sp">That is the very purpose I had in view.</p>
              <PageNumber num={161} />
              <div className="speaker" id="sp3802"><span className="persName">Mr. RANDOLPH</span>—</div>
              <p className="p-in-sp">I will read the resolutions:</p>
              <p>
                1st. Resolved, That the Governor of the Commonwealth be, and he is hereby authorized and required to call into the service of the State, such portions of the military force of the Commonwealth as may be necessary to repel invasion and to protect the citizens of the State in the present emergency; which force he will receive in companies and organize into regiments, brigades and
                divisions, according to the force required, and the Governor shall appoint and commission the general and staff officers of said forces, and proceed to have them organized and instructed; and he shall immediately invite all Virginians and residents of Virginia in the army and navy of the United States to retire therefrom and to enter the service of Virginia, assigning to them such
                rank as will not reverse the relative rank held by them in the United States service, and will, at least, be equivalent thereto.
              </p>
              <p>2d. Resolved, That the Governor shall repel invasion, and see that in all things the Commonwealth take no detriment, and shall exercise for this purpose the power conferred upon him by the Constitution and laws of the State.</p>
              <div className="speaker" id="sp3803"><span className="persName">Mr. RANDOLPH</span>—</div>
              <p className="p-in-sp">To these resolutions there can be no objection. There may be some objection to the third resolution.</p>
              <p className="p-in-sp">I have just parted with a captain in the army of the United States, who is waiting now to send his commission on, and is anxious to take service in any position Virginia may have, provided he would be accepted.</p>
              <p className="p-in-sp">In order to cover the expenses which may arise before the Legislature meets, if there be expenses over and above the appropriations made by the last Legislature, I have prepared a third resolution, which reads as follows:</p>
              <p>Resolved, That to enable him to carry out the resolutions aforesaid, the sum of dollars be, and the same is hereby, appropriated out of any money in the treasury not otherwise appropriated.</p>
              <div className="speaker" id="sp3804"><span className="persName">Mr. WILLIAMS C. WICKHAM</span>, of Henrico—</div>
              <p className="p-in-sp">I would prefer, if it is agreeable to the gentleman from Richmond [<span className="persName">Mr. RANDOLPH</span>] , that I would have the privilege accorded to me of having my resolution offered, and the gentleman may, if he chooses, allow his to stand before the House.</p>
              <p className="p-in-sp">My object in getting the floor is a desire to state my position upon the proposition submitted by myself, rather than upon that submitted by the gentleman from Richmond.</p>
              <div className="speaker" id="sp3805"><span className="persName">Mr. RANDOLPH</span>—</div>
              <p className="p-in-sp">If the gentleman will read his proposition, I may be able then to say whether or not I will accept it in lieu of my own.</p>
              <PageNumber num={162} />
              <div className="speaker" id="sp3806"><span className="persName">Mr. WICKHAM</span>—</div>
              <p className="p-in-sp">It reads as follows:</p>
              <p>Be it ordained, that the Governor of this Commonwealth be hereby directed at once to cause active steps to be taken to prepare all necessary equipments, including tents and camp equipage for an army of thousand men.</p>
              <p>Be it ordained, that the Governor of this Commonwealth be hereby directed to call into immediate service a force such as he may deem adequate, to be brought together in such numbers and at such point as he may deem best, for the defence of this Commonwealth from armed invasion.</p>
              <p>Be it further ordained, that the Governor of this Commonwealth is hereby empowered and directed to borrow on the faith of this Commonwealth in addition to the sums heretofore appropriated by the Legislature, in such manner as may seem best to him, the further sum of one million of dollars to be expended for the defence of this Commonwealth under the discretion of the Governor.</p>
              <div className="speaker" id="sp3807"><span className="persName">Mr. WICKHAM</span>, of Henrico—</div>
              <p className="p-in-sp">In offering that resolution, sir, I do but what I conceive to be a duty to myself and to the constituency which I represent.</p>
              <p className="p-in-sp">I have been accustomed, for some months back, to hear, with perfect indifference, I must confess, intimations and declarations that my course was brought about by a spirit of cowardly submission.</p>
              <p className="p-in-sp">
                Sir, I paid no attention to these declarations, feeling satisfied that they were utterly untrue. But, sir, I came to this Convention upon a declaration of devotion to this Union; upon a declaration of an intention to do all that man could do to preserve that Union. I have felt that I was justified in carrying out these efforts to the fullest extent. I came openly forward with the
                declaration that I did not believe in the right of secession; but that, when the proper time came, and sufficient provocation was offered to justify it, I was ready to dissolve the connection between us and the Federal Government by revolution, assuming all responsibility for that act.
              </p>
              <p className="p-in-sp">
                For the past three days I have witnessed the scenes which have been transpiring here with feelings of inexpressible anguish. To say that I would lay down my life to preserve this Union, is but to say what I know and feel truly. I could not and will not attempt to express the depth of the love that I entertain for that Union. I have a wife and four children at home, of whom any man
                might be proud; and I confess, strange as it may be, that I loved that Union more than I love that wife and those children.
              </p>
              <PageNumber num={163} />
              <p className="p-in-sp">
                Entertaining these opinions; believing, as I did, that there was no constitutional right of resuming our sovereignty, I recorded my vote against the ordinance of secession. But I avail myself of this first opportunity to acknowledge the fact that revolution does now exist within the limits of the State of Virginia, brought about by no agency of my own, but actually existing; and I
                recognize it to be the bounden duty of every man of this Convention to use all his efforts to preserve the people of this Commonwealth against the dangers which surround them. It is almost certain that within the next ten days this State will be flooded with hostile forces; and it is proper we should take every precaution with which to protect the lives of those persons who have
                become involved in danger by the ordinance of this Convention passed to-day.
              </p>
              <p className="p-in-sp">
                It may be said that this ordinance is of no avail, and can have no influence until the people of Virginia have recorded their votes in favor of it-that, in fact, it is no ordinance as yet. No one can conceal from himself the fact that under existing circumstances, and such a state of circumstances as events will cause to exist during the interval to the day of election, the decision
                of the people will be overwhelmingly in favor of the ordinance, and that its influence meanwhile will be just what it would be were the action of the Convention upon it final. It is clear to my mind that the ordinance which has been passed here to-day is but the recorded edict of the people of Virginia, and that the sooner we make preparations the better, and the more ample we make
                them the better will it be for the people.
              </p>
              <p className="p-in-sp">
                I have said thus much to show that I, for one, am ready to assume all the responsibility which attaches to the action of this Convention to-day; and whilst I will not in any way give my voice in favor of this ordinance of secession, yet, recognizing the existence of secession, may, more, revolution, I am prepared to carry out the pledge which I made to my constituency, that when
                Virginia raised her flag, I would be found fighting to the death under that flag.
              </p>
              <div className="speaker" id="sp3808"><span className="persName">Mr. RANDOLPH</span>—</div>
              <p className="p-in-sp">
                I must say that I prefer my own resolutions to that offered by the gentleman from Henrico, and I beg leave to offer them as a substitute for his. As I understand, he was entitled to the floor, and his ordinance has, therefore, precedence over mine. That being so, I beg leave to offer them as a substitute, and before doing so, would ask leave to amend them to the extent of
                substituting the words "Be it Ordained" for the word "resolved" at the head of each resolution.
              </p>
              <p className="p-in-sp">My resolutions say nothing about camp equipage, because, not knowing what the State has on hand, and it being also a question of <PageNumber num={164} /> detail, I preferred to leave that entirely to the judgment of the Governor.</p>
              <p className="p-in-sp">
                The gentleman's resolution, I think, is defective in another most important particular which I endeavored to supply. He makes no provision for calling to our aid the officers of the army and navy who are now waiting for the invitation to come to us. I have heard this evening that two of the officers of the navy, both personal friends of mine, and most distinguished and efficient men,
                are now waiting to hear that their State wants them, to enter her service forthwith. I know the same is the case with officers of the army; and I have reason to know that of all the wants in this Commonwealth that of Commander-in-Chief who may be here competent to advise the Governor and direct military movements, is the greatest.
              </p>
              <p className="p-in-sp">I think we can find no such officer out of the ranks of the regular service. I think there are at least two who will respond to our invitation and be here immediately, either one of whom is able to command an army.</p>
              <p className="p-in-sp">
                I propose, therefore, to authorize the Governor to invite those gentlemen, and to assure them that they shall hold here such rank as they held in the United States service. I think that the State being pledged to these gentlemen to make provision for them, in case they quit the service upon which they are now dependent for support, there will be little doubt of their availing
                themselves of the first invitation offered.
              </p>
              <p className="p-in-sp">I think, therefore, my resolutions are preferable to that of my friend from Henrico [<span className="persName">Mr. WICKHAM</span>]
                , inasmuch as they avoid details and provide for calling into our service immediately the best military talent of the country. I take great pleasure in assuring the gentleman that I never doubted for one moment his patriotism or that of any other gentleman. I believe that on the other side of the House there are those who are as true to the interests of the State, as any in the ranks
                of those who voted to-day to put Virginia out of the Union; I do not believe that anybody will interpose factious opposition to any measures that may be introduced here to place the State in a proper condition of defence. As regards the resolutions which I have offered, if they can be amended in any particular to make them more efficient, it will give me pleasure to accept such
                amendments.
              </p>
              <div className="speaker" id="sp3809"><span className="persName">Mr. W. H. MACFARLAND</span>, of Richmond—</div>
              <p className="p-in-sp">
                I beg leave to say, that I think the best course for this Convention to pursue, would be to refer to gentlemen of military experience the scheme of business for us to adopt. We should have the benefit of concert of action, and, with that view, I would suggest that a committee be appointed, to whom the <PageNumber num={165} /> whole matter should be
                referred, and that committee may report by the morning session, if not to-night. I suppose it will be in full time to submit a scheme to-morrow.
              </p>
              <p className="p-in-sp">
                But, Mr. President, I have another motive in seeking the floor. You know, sir, no one better knows, that the opponents of the revolutionary movements are sought for always in cotemporaneous times. A proposition, in the morning session, was argued upon the assumption, allow me to say, with all deference, the gross assumption that a majority in this body was forcing the State into
                revolution and rebellion.
              </p>
              <div className="speaker" id="sp3810"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair begs leave to remind the gentleman that his language is not strictly in order.</p>
              <div className="speaker" id="sp3811"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">I shall insist, before I am done, upon the necessity of putting Virginia in a state of defence, and, as leading to that, I was proceeding to remark that if the argument in the morning was hereafter to be regarded as the true exponent of the principle upon which the Convention acted, that it will be misunderstood, misconceived and misapprehended.</p>
              <p className="p-in-sp">Sir, an idea seemed to prevail that we were forcing a controversy, an armed controversy with the Federal Government. Now, I submit, sir, that no idea was ever more mistaken, in my apprehension. None. Until within the last two days, the prevailing sentiment of this body was one of amity, of deference, of loyalty, to the Government of the United States.</p>
              <p className="p-in-sp">Nor was it changed until we were reduced to the condition of seeking peace, provided we would submit to wage war on the part of the Government of the United States. That was the precise condition upon which our loyalty to the General Government was to be tested and tried.</p>
              <p className="p-in-sp">Now, sir, I desire to know-I trust that I shall be answered by the gentlemen upon the other side-whether there is one of the minority who would be willing to preserve the Union upon condition of an acknowledgment that Virginia was about to furnish her contingent to carry on the war against the Southern States?</p>
              <p className="p-in-sp">
                Sir, the success of our appeal to the patriots of the country and the patriotism of our fellow citizens, will depend upon the justice and the equity of that appeal. Is there a gentleman in this Convention who is willing to say that he would seek the preservation of the Union upon the condition of furnishing our full contingent to sustain the Government in their flagrant purpose upon
                the South? Are there gentlemen here of that opinion? Sir, if I could have been left free to decide between a conference with the border States and immediate secession, <PageNumber num={166} /> I would have felt myself at liberty to act, and should have unhesitatingly chosen the alternative of secession sooner than submit to the degrading condition of
                employing the means at our command to subjugate our brethren of the South.
              </p>
              <div className="speaker" id="sp3812"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair regrets exceedingly to interrupt the gentleman, but he feels bound to say that he is indulging in a latitude of debate that is entirely out of order. The question before the Convention is the substitute offered by the gentleman from the city of Richmond [<span className="persName">Mr. RANDOLPH</span>] to the ordinance offered by the gentleman from Henrico [<span className="persName">Mr. WICKHAM</span>] .</p>
              <div className="speaker" id="sp3813"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">
                The Chair will observe that I am pressing upon the consideration of the Convention the proposition made to arm the State, upon the ground that our quarrel is just, and not that it is germane to the suhject of inquiry? What I mean to insist upon is, that the course of this Convention in the determination which it came to in the morning session, was not factious nor disloyal, but
                forced upon it, and that it was imperiously required by a just regard to whatever is characteristic of firm, stable and constitutional-loving men.
              </p>
              <p className="p-in-sp">I place myself under the correction of the Chair, and desire to know if the remarks, with the proposition in view, as I have now defined it, are not strictly in order?</p>
              <div className="speaker" id="sp3814"><span className="persName">THE PRESIDENT</span>—</div>
              <p className="p-in-sp">Undoubtedly so. I considered, however, that the previous remarks were not in order so far, else the Chair would not have interrupted the gentleman.</p>
              <div className="speaker" id="sp3815"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">
                With a view to determine upon the probable success of any appeal on the part of Virginia to the patriotism of our fellow citizens, it is conceded to be right, that it should be ascertained that the position of Virginia to-day was demanded by all just and proper considerations; and I was proceeding to show, that there ought not to be two opinions in respect to the propriety and the
                urgency of the occasion we are called upon to respond to. And here permit me to inquire if there be a single member of the minority upon this floor who wants time for consultation with the border States, who would be willing to encourage the propriety of continuing in the Union, if the condition was attached thereto of furnishing the contingent of the State, necessary to satisfy the
                President of the United States.
              </p>
              <p className="p-in-sp">I desire to bring distinctly to the minds of gentlemen a consideration of that question. We must have many differences, Mr. President.</p>
              <p className="p-in-sp">
                I am sorry to find that I differ with you, sir, in respect to the policy and the cause which the Convention has decided upon. There's no citizen in the State whose judgment and patriotism I more appreciate <PageNumber num={167} /> than yours. Yet, I must insist that if it was left to you to determine the condition upon which we would seek the
                co-operation of the border States, that you would regret that co-operation, if it required the submission of Virginia to the requisition which has been made by the Secretary of War. I apprehend, sir, that we may expect in the border States the unanimity and agreement which characterize our own State in respect to that important and exciting question.
              </p>
              <p className="p-in-sp">
                Well now, sir, it will be agreed upon all hands that there is an actual, inevitable necessity for equipping the State. We must have money-I mean there shall be no deficiency of it; but I desire it to be known that if the State is subject to the necessity of furnishing men or money for her defence, it is by the exercise of no capricious power, of no independent and unrestricted will;
                but that it is a necessity forced upon us by the actual condition and necessities of the times.
              </p>
              <p className="p-in-sp">
                Why, sir, we apprehended a week ago, that Virginia would be called upon to furnish her contingent to subjugate the citizens of the South. If the suggestion was made a week since, it would have been regarded on all hands as one too wild and chimerical to be entertained for a moment. And now that it is come; now that it is upon us, I apprehend that every sentiment of manhood and of
                loyalty ought to be enlisted upon the side of a stern and uncompromising defence.
              </p>
              <p className="p-in-sp">
                Mr. President, allow me to say, that there is not a gentleman upon this floor to whom sectional appeals could be addressed with more oppressive force and influence than myself; and there is nothing short of principle which I would not concede for the sake of harmony and consent. There is nothing which I would not rather yield than that gentlemen, upon this floor, from any section of
                the State, should retire dissatisfied or disaffected from the proceedings of this body.
              </p>
              <p className="p-in-sp">But I beg gentlemen to observe that sectional questions of necessity are sunk. They must be put aside when the question respects the honor, the integrity and the safety of the State.</p>
              <p className="p-in-sp">I am informed that military gentlemen are waiting to receive the orders of this body for the purpose of retiring from the United States service, and engaging in the service of Virginia. I trust this order will be given, and in order to avoid embarrassing the proposition now before the Convention, I will suspend my remarks.</p>
              <div className="speaker" id="sp3816"><span className="persName">Mr. JOHN R. KILBY</span>, of Nansemond—</div>
              <p className="p-in-sp">
                Mr. President, I would ask the consent of the Convention to record my vote for the ordinance of secession which passed this body in the morning session. Circumstances prevented my being present, else I should not trouble the Convention with an application of this sort. I came to the conclusion some days ago to <PageNumber num={168} /> vote for this
                ordinance, and I desire, with the consent of the Convention, to do so now.
              </p>
              <p>The question on granting the leave asked for was put and agreed to unanimously.</p>
              <div className="speaker" id="sp3817"><span className="persName">Mr. WM. H. DULANY</span>, of Fairfax—</div>
              <p className="p-in-sp">I desire to speak upon the resolution before the House.</p>
              <div className="speaker" id="sp3818"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">I beg leave to say that I thought I was understood as merely yielding the floor to enable the gentleman who offered these propositions to make some further explanations in regard to them. Inasmuch as these gentlemen have not thought proper to do so, I think I am now entitled to the floor.</p>
              <div className="speaker" id="sp3819"><span className="persName">THE PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair understood the gentleman as having concluded his remarks.</p>
              <div className="speaker" id="sp3820"><span className="persName">Mr. DULANY</span>—</div>
              <p className="p-in-sp">
                I do not desire to be guilty of any act that would seem discourteous to any member of this Convention, and especially towards the gentleman from Richmond city. If I could be sure of obtaining the floor after these military gentlemen should have concluded their remarks, I would cheerfully allow the gentleman to proceed. But as the gentleman was so courteous as to yield the floor to
                enable these military gentlemen to make some important proposition, as he supposed, to be acted upon immediately, or to make some further explanations of the resolutions before the House, I suppose he would not refuse a similar courtesy to one who has never troubled the House with a single remark, but simply recorded his vote without even a word of explanation.
              </p>
              <p className="p-in-sp">
                I arose, sir, for the purpose of saying that the substance of the resolutions now before the Convention, meet in the main, my cordial approbation. I am one of those, Mr. President, one of the few from Eastern Virginia, who to-day recorded their votes against the ordinance of secession which has been passed here to-day; and I trust, if I am out of order in alluding but for a few
                moments to the reasons which operated upon me in giving that vote, that the Convention will bear with me. I know I am subject to the ten minutes rule; I know I am transgressing the rule in referring to the merits of any question, or stating any reasons other than those which apply to the propositions before the House, but I throw myself upon the indulgence of the Convention, and ask
                at their hands to be permitted to state the reasons which influenced me in voting against the ordinance of secession.
              </p>
              <p className="p-in-sp">
                In voting against the ordinance of secession, I voted in, what I believed to be, strict accordance with the sentiments of the people whom I have the honor to represent here. I voted in strict accordance with <PageNumber num={169} /> my own judgment also upon that subject; because if I had believed that the people of my county were unanimous in favor of
                such an ordinance, and my judgment did not approve it, I could never have consented to give my vote for it, and would feel bound to resign my seat here and allow my constituents to substitute another who would carry out their will. I thought, sir, according to my poor judgment, that by the passage of this ordinance we were transferring the war, as already commenced, from its present
                seat to our own State, and especially to the borders of my own native county; and although, like the gentleman from Northampton
                [<span className="persName">Mr. FISHER</span>]
                , I would willingly offer up the little I have as a sacrifice to preserve the honor of my native State, yet there were ties that bound me to her and to the Union more holy than the mere rights of property, than mere dollars and cents. I do not wish to see an aged mother and sisters, who are dependent upon me, who will be-I say it without egotism-in the first of this conflict-I say I
                do not wish to see them flying from the county, as I apprehend they will have to do, inasmuch as it is likely to become the seat of the first conflict, being in fact in sight of the emissaries of the Federal Government, and likely to be before long in their exclusive possession. I voted against this ordinance of secession to avoid transferring the seat of war to that section; and I
                did so, moreover, because, as I have said, I believed that in so doing, I reflected the will and sentiments of the people I represent.
              </p>
              <p className="p-in-sp">In regard to the resolutions now before the Convention, I would say that those which have been offered by my friend from Henrico [<span className="persName">Mr. WICKHAM</span>] , meet my cordial approbation. If I understand the resolutions of the gentleman from the city of Richmond [<span className="persName">Mr. RANDOLPH</span>]
                , there is a portion of them that I never can give my assent to-I mean that portion which extends an invitation to the officers of the army and navy of the United States who may be native Virginians or residents of Virginia, to resign their positions in that service, and enter the service of Virginia. If the resolution stopped with a mere invitation, I would make no objections to it;
                but it places us, Virginians, in the humiliating attitude of offering a bribe to Virginia officers in the United States service. The Administration at Washington has already avowed its policy to be the subjugation of Virginia; and I take it that every officer from Virginia in the United States service will find in that sufficient cause to quit that service and engage in the defence
                of his native State. I should certainly suppose that no stronger stimulant could be afforded to a patriotic heart, and cause it to repudiate all connection with that Government than <PageNumber num={170} /> the proclamation of a purpose to invade and subjugate the soil wherein it first throbbed. If I understand these resolutions, which I think I do, the
                gentleman from Richmond
                [<span className="persName">Mr. RANDOLPH</span>]
                , could not have given to the subject that deliberation which characterizes his management and preparation of all subjects which he undertakes to act upon. I certainly cannot look upon it in any other light than as a humiliating offer to those Virginians who now hold commissions under the Federal Government to resign these commissions upon the considerations that are contained in the
                resolutions. All considerations of patriotism seem to be ignored, when it should be the first presented. I believe that there is not an officer now holding a commission under the Federal Government in the present condition of things-that is if all that we heard to day be true, namely, that we are now engaged in a conflict at two points of the Commonwealth—
              </p>
              <div className="speaker" id="sp3821"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I ask if this course of remark is in order?</p>
              <div className="speaker" id="sp3822"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair considers the gentleman in order.</p>
              <div className="speaker" id="sp3823"><span className="persName">Mr. DULANY</span>—</div>
              <p className="p-in-sp">I would say to the gentleman that I will soon conclude.</p>
              <p className="p-in-sp">I was going on to remark when I was interrupted by the gentleman from Princess Anne [<span className="persName">Mr. WISE</span>]
                , that I believe if the state of things represented to us here to day, be true, and I have no reason to doubt it, that there is not a single officer, a native of Virginia, in the service of the Federal Government, whose services we desire, that would not voluntarily throw up his commission and seek employment in our ranks. I say, therefore, that I never will consent to hold out any
                offer of rank or emolument, to gentlemen whose patriotism, I am sure, would, and will impel them to engage in the service of their own native State without any such overtures as these resolutions propose to make.
              </p>
              <p className="p-in-sp">For these reasons, I am opposed to the substitute and in favor of the original proposition offered by the gentleman from Henrico.</p>
              <div className="speaker" id="sp3824"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I rise to move the previous question. I want to cut off debate at these night sessions. There are officers waiting here now upon public business of the utmost importance. I hate to be specially, even seemingly discourteous to the gentleman from Richmond [<span className="persName">Mr. MACFARLAND</span>] ; but the public interest requires that we should act promptly upon the matters now before the Convention.</p>
              <p>The call for the previous question was sustained. Ten minutes, however, were allowed under the rule to each member who chose to address the Convention as to why the main question should not be ordered.</p>
              <div className="speaker" id="sp3825"><span className="persName">Mr. JOHN B. BALDWIN</span>, of Augusta—</div>
              <p className="p-in-sp">
                I do not wish to be placed before this body, or in the estimation of any gentleman belonging <PageNumber num={171} /> to it, in the attitude of desiring for one moment to obstruct its business by any factious opposition to any measure proposed before it. As I hope to be considered a fair and candid man, I am prepared to consider every proposition before
                this body upon its merits and with a sincere desire to do everything in my power to forward the object of placing this Commonwealth upon a footing of complete and perfect armed preparation for the conflict which is upon us. That is my disposition, sir, and I assure gentlemen that if any man upon this floor doubts it, he does me a grievous wrong.
              </p>
              <p className="p-in-sp">
                But I do desire at the outset, now, upon this first proposition that is made of a practical character, to draw the attention of this Convention to great and high considerations affecting the scope of our power. I must say that, to cut off time, at the very beginning upon a proposition of this magnitude, by the previous question, is a hardship that I did not expect from this body in
                the great circumstances by which we are surrounded. I do think, if there ever was a time when it behooves men to hear one another calmly, patiently and within reasonable limits, now is the time.
              </p>
              <p className="p-in-sp">
                The proposition now is, that this Convention shall undertake several things-in the first place, that it shall undertake legislative functions. Now, the Constitution of the State of Virginia declares that the Legislature shall be formed of two distinct branches, which together shall be a complete Legislature, and shall be called the General Assembly of Virginia. I understand that,
                under the provisions of our fundamental law, all power of legislative action is invested in the General Assembly; and that it is not competent for any other assembly here, or any other body of men in this Commonwealth, to exercise any legislative functions, while that Constitution remains the fundamental law of this State. This Convention has unquestionably power to deal with the
                fundamental law of the State. It has power to recommend amendments to the Constitution, which, according to the law which organized this Convention, are to have no force until they are sanctioned by a vote of the people at the polls. If these two propositions be true-and I maintain that they are incontrovertible-where has this Convention got the authority to constitute officers in
                the army of the State, and then to exercise the executive functions of indicating who shall fill them?
              </p>
              <p className="p-in-sp">
                Sir, again the Constitution of the State provides : that no money shall be taken from the treasury of the State, except in accordance with the appropriations made by law, and that every law making an appropriation of money, or incurring a debt, or laying a tax, shall <PageNumber num={172} /> receive the concurrent assent of a majority of all the members
                elected to both Houses of the General Assembly.
              </p>
              <p className="p-in-sp">
                Now, here it is proposed by this body to confer upon the Governor authority to borrow money, and to place that money under the Executive's discretion, and in the trite language of that resolution, the Governor shall see that the Commonwealth take no detriment, which was the language under which the dictators of old issued their edicts. It is under that language, the Governor of the
                State is authorized to borrow money.
              </p>
              <div className="speaker" id="sp3826"><span className="persName">Mr. RANDOLPH</span>—</div>
              <p className="p-in-sp">I struck out the provision authorizing the Governor to borrow money. That phraseology, to which my friend refers, is expressly qualified by the next phrase, which says that he shall exercise the powers conferred by the Constitution now.</p>
              <div className="speaker" id="sp3827"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">
                I do not propose to debate a question of this magnitude in ten minutes. I could not think of doing it; but I propose to make an appeal to the House at the threshold of undertaking this business, to allow, at least, fair debate for the purpose of eliciting the judgment of this body as to what is the scope of our power; what rights we have in dealing with the affairs of the
                Commonwealth; and as to whether we are a body limited by the Constitution of the State and the act of Assembly under which we organized; or whether we are under the impression that these, being revolutionary times, we are invested with all manner of power. I want to know it, because, as I said, in all earnestness and sincerity, I desire to co-operate in placing this Commonwealth upon
                the best possible footing. I will co-operate in every measure where I find we have the power, and if gentlemen want unanimity, I ask them to come forward and relieve my difficulty in fair debate. I am open to conviction, and am anxious that gentlemen will not cut off debate upon a question of this sort.
              </p>
              <div className="speaker" id="sp3828"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                As I moved the previous question, I must reply to an appeal made with all sincerity, I have no doubt, for fairness. If I thought there was the least unfairness in that motion, I would not make it; and if the gentleman could have convinced me that there was any necessity whatever to question the power of this body to do what is proposed, even if it had been to authorize the Governor,
                or a committee of safety, to borrow money by an ordinance of the highest corporate power that could be organized-the very primary power of the people itself-if the reason given for the appeal had any foundation for it, I would, as far as I could, suspend the operation of the previous question.
              </p>
              <PageNumber num={173} />
              <div className="speaker" id="sp3829"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">I have not given the reason-I merely suggested the propriety of allowing time to debate the question.</p>
              <div className="speaker" id="sp3830"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">No reason can be given why this Convention is authorized to change the whole Constitution of the State; when it can repeal and reorganize the whole legislative power itself.</p>
              <div className="speaker" id="sp3831"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">Not without the sanction of the people.</p>
              <div className="speaker" id="sp3832"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">If it were necessary to make that issue, I would make the issue which was made ten years ago, and might be made to-day, that a new municipal legislative power cannot limit the supreme conventional power. When they once call a convention to act upon organic law, they can give it no limitation whatever.</p>
              <div className="speaker" id="sp3833"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">The people themselves in calling the Convention together, stated the limitations in the very call which they had issued.</p>
              <div className="speaker" id="sp3834"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                I was coming to that, sir. I conceive that the legislative power had not a right to limit the Conventional power. It was referred to the people themselves to say whether we, their representative body, shall return the question to themselves in their primary capacity at the polls. Well, they did require a reference to them of certain action on the part of this Convention, but what
                action did they require to be referred to them? I have looked at the act of assembly which required to be referred to them the question of the final separation of this State from the Federal Government-I may be mistaken, sir; but mistaken or not on that point, suppose that they have required us to submit all questions back to them, is there any man here, when the car of war is
                rushing over the people themselves, crushing them like the car of Juggernaut under its wheels; when the whole frontier West, North and East; when the edge of every bay and estuary in the bay is endangered by invasion; whilst your steamers are seized in New York; whilst your people have risen up and sunk hulks in the channel at Norfolk-will you tell me, sir, whether the salus populi
                does not require us to take the responsibility of doing ad interim whatever we can do for their defence; to take the responsibility of defending the people between now and the election in May? And if they renounce an action in this respect; if they throw aside the sword which we offer to put in their hands; if they renounce what will prevent their subjugation, I, sir, will say, we
                are not to blame for it. But if we do neglect this defence; if we do neglect these means now, and they should confirm the act of secession, is there a constituency of any representative on this floor who would forgive him for remaining here idle and unwilling to take the responsibility to act in the people's defence before they shall direct him through the polls?
              </p>
              <PageNumber num={174} />
              <p className="p-in-sp">Sir, the safety of the people by every law, moral, divine, political or popular, justifies the overriding for a time at least of acts and statutes and even the Constitution itself.</p>
              <div className="speaker" id="sp3835"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">Not under our system.</p>
              <div className="speaker" id="sp3836"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                Under our system the written Constitution of the United States requires that the President shall execute the laws of the United States; shall collect the revenue; shall hold the ports; shall do every thing that would now oblige civil war to be brought on, and yet, the gentleman says that the President is bound to forbear and not bring on civil war. Why? Because civil war attacks the
                safety of all; attacks the very vitals of the State, the happiness of the people, and destroys the very ends which the Constitution itself was established for. Now, to tell me that this Convention is bound, before it can do anything to provide means to defend the people at this moment; before it can prevent this navy yard from being taken with all its immense stores, shipping and
                ordnance; before it can take steps to capture the forts which are held by the Federal Government, and all the arms which are lying on our territory, among a people destitute of arms; to tell me that before all this is done we must await the sanction of the people; to tell me that the power of the Convention, in view of these necessities, is limited, and that it is but a mere
                municipal power, regulated by the Constitution, is to present to me an argument too conservative for any man to recognize in a crisis such as I have pictured, and which is now upon us.
              </p>
              <p className="p-in-sp">My friend from Augusta [<span className="persName">Mr. BALDWIN</span>]
                I have no doubt is conservative. As a general thing, in peaceful times, in ordinary exigencies -even extraordinary exigencies-his principle is undoubtedly a safe conservative principle. The question now is, when invasion is upon us whether we dare stop to await the vote of the people upon an action which the salus populi imperatively demands at our hands with the least possible
                delay? Such a policy would be disastrous, in my opinion, and it cannot by any means have my sanction.
              </p>
              <div className="speaker" id="sp3837"><span className="persName">Mr. W. H. MACFARLAND</span>, of Richmond—</div>
              <p className="p-in-sp">The face of questions before this body changes as rapidly as things out of doors. The remarks that would have been a few minutes ago appropriate to the subject under consideration, are now out of place. I shall, therefore, decline pursuing the investigation in which I was engaged, when at a friendly suggestion of a gentleman on this floor, I gave way.</p>
              <p className="p-in-sp">
                I beg leave to say that the constituency which I have the honor in part to represent, constitute chiefly the commercial portion of our State, to which reference may be had in any debate on the subject <PageNumber num={175} /> of borrowing money for the use of the Commonwealth. I think I can say for that community that they will be as prompt and generous
                as any people that can be found in any other part of the entire Union. It is a community which will respond promptly to any call of the State, needing only to be satisfied that the call is suggested by prudence and wisdom; and hence it was that a while ago I was endeavoring to show that the condition in which the State is now placed by the passage of the ordinance of secession this
                morning was called for by the most imperious considerations of safety. But I shall not pursue that inquiry. I beg leave to say, in reply to the gentleman from Augusta
                [<span className="persName">Mr. BALDWIN</span>]
                , that there is no prohibition whatever upon the powers of this body, except that it is not at liberty to change the relations of the State towards the General Government without the sanction of the people-perhaps not that prohibition. But, with the exception of that, there is certainly no negation upon the power of this body; and I apprehend that there is no authority in the State
                more competent to commit the credit of the Commonwealth than this body, for any purpose of State necessity or defence, and I apprehend that the least of all doubts which would occur to business men would be in respect to the authority of this body to authorize loans for bare State purposes.
              </p>
              <p className="p-in-sp">
                Sir, it will occur to gentlemen, I am sure, upon a moment's reflection, that this disposition of power which exists in the civil government, in the ordinary political system, is simply a matter of ordinance or appointment proceeding from the primary or original power. It is instituted by the Convention as proper and necessary in the ordinary experience of the administration of the
                affairs of the State, but there is no sense nor propriety whatever in denying to the Convention the power to institute and establish any and all measures which, in the apprehension of the Convention, may be necessary for the security and safety of the State.
              </p>
              <p className="p-in-sp">
                Sir, it is obvious, upon a moment's reflection, that the worst of all possible consequences would attend upon the opposite theory. The Convention for the time being is regarded as the General Assembly of the people, acting in the name of the people, and charged by the people to do whatever concerns the safety and the honor of the Commonwealth within the limits of the representative
                power derived from the people; and I understand from my friend who sits upon my right
                [<span className="persName">Mr. THORNTON</span>] that the Convention is expressly charged by the Legislature, under which the election, was held, to do and ordain whatever may be necessary and deemed expedient for the welfare of the State.</p>
              <PageNumber num={176} />
              <p className="p-in-sp">
                Now, it is obvious that there can be no delegation of power in terms more comprehensive and unrestricted, and allow me to say, that there can be nothing more unfortunate than the popular impression, derived from diversities of opinion, in this body in relation to the power of the Convention to commit the credit of the State. I do hope, therefore, that gentlemen will be careful in
                intimating doubts in respect to the power and authority of this body in reference to measures which must depend for their efficacy upon popular consent and popular response.
              </p>
              <p className="p-in-sp">
                There is, in my humble judgment, no doubt whatever, in respect to the authority of this body to commit the credit of the State to whatever expenditure it may deem necessary for the security of the State. Allow me to say, in conclusion, that whilst I would have been gratified to extend the remarks which I was making a while ago, the purpose of which was to show, if I could succeed in
                doing so, that a very exaggerated and unauthorized statement was indulged, in respect to the consequences which would result from a contempt of co-operation with the border slave States, and from immediate secession. I am grateful to the Convention for its kind attention to my remarks.
              </p>
              <div className="speaker" id="sp3838"><span className="persName">Mr. THOMAS BRANCH</span>, of Petersburg—</div>
              <p className="p-in-sp">I think the hour has come when we should retire from labor. We have had a long day's session, and I am satisfied that every gentleman is willing now to take the vote. I am willing to forego the pleasure of speaking to-night provided the vote is taken.</p>
              <div className="speaker" id="sp3839"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The gentleman must yield unconditionally, if he yields at all.</p>
              <div className="speaker" id="sp3840"><span className="persName">Mr. BRANCH</span>—</div>
              <p className="p-in-sp">I will proceed, then [laughter]
                , though I am satisfied every member here can vote as intelligibly now as he can after the House has exhausted its powers of speech. The presumption seems to be here that nothing can be done, no vote recorded upon any proposition without a speech. Gentlemen must make speeches at any risk or sacrifice of time, for the purpose of having them recorded, to be read at a future time. Those
                who pursue this policy will of course assume the responsibility attaching to their course.
              </p>
              <p className="p-in-sp">
                I would remark that, in my opinion, we are now ostensibly in the union, but actually out of it. Virginia stands now where she stood when she declared her independence in May, 1776-an independent sovereignty-such as she was before she assembled with the other twelve States in Philadelphia to form a Constitution. She stands, I say, in that position to-night; and I stand here as the
                representative <PageNumber num={177} /> of the City of Petersburg, ready to maintain her by every means which may be necessary for her defence. I am here to-night as the representative of that patriotic city, which is known to be such throughout the country, to take the responsibility of sustaining the honor of the State that is now no longer part of
                the Union. And here, I will say, that I wept this day as I witnessed the severance of that tie which bound Virginia to this once glorious Confederacy. I have wept tears of bitterness at the disintegration of that loved Union which was the terror of tyrants throughout the globe. I should say that I was not a man if I did not weep over so great a catastrophe. Yes, sir, I could not
                resist the influence of the sad reality, of having to desert the flag which sustained and protected me and my forefathers, and which commanded respect in every clime and on every sea.
              </p>
              <p className="p-in-sp">
                Mr. President, I said I was here to take any responsibility that attached to my vote in favor of any measure designed to maintain the honor of Virginia. I repeat, sir, I am willing to take that responsibility, and I only hope that gentlemen will at once proceed to take the vote and come to some practical conclusion. As I have before said, it needs no further light to enable every
                member to vote intelligibly upon the proposition before the Convention ; and, I trust, therefore, that we shall have an end to this system of interminable speaking which has governed us heretofore to the serious delay of the public business. Physical man is unable to bear up under the harassing labors which we have had to undergo recently; and, inasmuch as nothing can now be gained
                by further debate upon this question, I hope the vote will at once be taken.
              </p>
              <div className="speaker" id="sp3841"><span className="persName">Mr. JAMES P. HOLCOMBE</span>, of Albemarle—</div>
              <p className="p-in-sp">I will not say one word more than is necessary to make the point I desire to present to the Convention intelligible. It relates to our power.</p>
              <p className="p-in-sp">
                I presume that every member of the Convention will assent to the general proposition that the Convention, without any limitation upon its power, embodies the whole sovereignty of the Commonwealth, and could exercise any authority which the people are capable of exercising; that all will assent to the precedents and practices of our State. The practices of all American States, for
                Conventions, are known only in this country; and establish the general principle that a Convention, without limitation, could do everything which the community it represents could do.
              </p>
              <p className="p-in-sp">
                Now, I am very doubtful whether any limitation whatever is imposed under this law, on the authority of this Convention. I do not raise the question whether any limitation would be binding on the <PageNumber num={178} /> Convention. I am willing to acknowledge that a limitation expressed and positive in its terms ought to bind the Convention. Looking
                carefully to the phraseology of the law, you may find that although a reference is made to the people to ascertain whether or no our action in any contingency shall be referred to them, yet it is not expressly made obligatory upon us in either of the two contingencies specified, to refer our action to the people, and I believe that the very construction of the first paragraph is,
                that the Convention is here, with the expression authenticated in proper form of law, of a desire on the part of the people of the State, that the action of the Convention should in two cases be referred to them, but not a command on the part of the people. I merely throw that out as a suggestion as to the immediate point which it presents to the Convention. I don't think it admits
                of a doubt as to our ability to exercise any of the ordinary legislative or executive powers of the government of this State. These are the limitations:
              </p>
              <p className="p-in-sp">"A poll is to be opened to take the sense of the voters as to whether any action of said Convention, dissolving our connection with the Federal Union or changing the organic law of the State, shall be submitted to the people for ratification or rejection."</p>
              <p className="p-in-sp">
                Provision is made for getting their opinions upon two questions, changing the organic law of the State. Now, the fair import of this provision is, that any change in the Constitution of the State-that is, the organic law of the State-any change in the permanent law of the State-the organic law, not a Legislative act-any change in the permanent law, the Constitution of the State,
                shall be submitted to the people for ratification or rejection.
              </p>
              <p className="p-in-sp">Now, what is the meaning of another clause in this act?</p>
              <p className="p-in-sp">"The members so chosen shall meet on Wednesday, the 18th day of February next, in the Capitol, at Richmond, and proceed to adopt such measures as they may deem expedient for the welfare of the Commonwealth."</p>
              <p className="p-in-sp">That is an express grant, in the clearest terms of Legislative authority.</p>
              <p className="p-in-sp">
                Can it be contended for a moment that the whole scope and meaning of that clause would be exhausted in the concerting and adoption by this body of some provision to be suggested to the people, and to become obligatory, operative and binding only upon its ratification by the people? The clause means something. We are either authorized to adopt measures, or we have only authority to
                recommend or suggest measures. According to this clause, we have authority to <PageNumber num={179} /> adopt any measures we "may deem expedient for the welfare of the Commonwealth"-a perfect form of expression-subject only to the limitation, if it be a limitation, of referring our action-if we change the permanent law, the Constitution-to the voters of
                the State; for we are only required to take the sense of the people in either of these two great contingencies-that of changing the organic law, or changing our relations with the Federal Government. We would acknowledge our obligations under any ordinary circumstances, and, for one, I would be disposed to assume the authority to act even under ordinary circumstances; but it is not
                necessary that I should place myself upon such grounds while a stronger foothold exists.
              </p>
              <div className="speaker" id="sp3842"><span className="persName">Mr. JUBAL A. EARLY</span>, of Franklin—</div>
              <p className="p-in-sp">The Convention knows very well that I am not responsible for the present state of things. I have nothing to regret in the votes I have cast except that they have been of such little avail. I have the same doubts about our power that the gentleman from Augusta [<span className="persName">Mr. BALDWIN</span>]
                has; but I find myself in the face of an imperious necessity, and must yield to it. He and I both think, and have thought, that the adoption of the ordinance to-day amounts to war. Now I am perfectly satisfied, from what I witness around me, that a conflict will take place between our citizens and the authorities of the General Government, and it is absolutely necessary to have a
                force in the field under the control of the State authorities. I think that to authorize the Governor to call the military forces into the field is a conservative measure. I believe we are still under the Constitution of the United States; but that does not prevent our State authorities from repelling invasion. I shall therefore vote for the measure to authorize the Governor to call
                into service the military forces in order to repel invasion and to protect the citizens of the State. I trust that this power may not be used for any unlawful or unconstitutional purpose, but that it will be exercised in the spirit and with due regard to the limitations to which it is properly subject.
              </p>
              <p className="p-in-sp">With regard to the question, how that force shall be organized and called into service now, the Convention will pardon me for making some suggestions.</p>
              <p className="p-in-sp">
                From what little observation I have had, I will say, that the military force itself is entirely insufficient; and I do not believe that there is a Major General or Brigadier-General in command in the State, who has the qualification to command a division or a brigade. This statement from me will be, perhaps, more excusable, inasmuch as I have lately received the commission of
                Brigadier General myself. War is a science; and the commander has to be trained to it to be efficient.
              </p>
              <PageNumber num={180} />
              <p className="p-in-sp">
                It is necessary for us to call into service able and experienced officers. If we resort to the system of drafting the militia, we stand a great chance of getting inefficient officers. The proposition to authorize the Governor to call the volunteer forces into service is a wise one, in my opinion. The people who volunteer in times of danger, are more particular in the selection of
                their officers than the old militia, under ordinary circumstances. It is necessary, also, that the Governor should have the appointment of the various generals and field officers for that peculiar service. I believe in the one man power when you come to military matters. I propose, therefore, that the Governor shall be authorized to call the volunteer forces into service, receive
                them by companies, organize them into regiments, brigades and divisions, and that he have power to commission the officers. There are a number of officers, natives of Virginia, who are capable of commanding an army of any size. Among them I may mention Col. Robert E. Lee and Gen. Johnston. Gen. Scott said in regard to Col. Lee that he had proved himself competent for any position. It
                is very necessary for us to have a man who is thoroughly competent to command. For my part, I should be unwilling to entrust to any Major General in the Commonwealth the command of our forces. It will not do to carry the system of elections too far; for it tends, in my opinion, rather to impair the selection of men in many instances who are by no means competent to fill the positions
                to which they are assigned. Influence operates to a considerable extent in this mode of selection to the exclusion of all claims of merit. I am, therefore, opposed to carrying this system of electing officers too far.
              </p>
              <p className="p-in-sp">
                A very experienced officer, who was a German by birth, a very old man, died a year or two ago who held a commission under George the Third. He came to this country and received a commission from General Washington, and he remained in our service until two or three years ago. He said an army would do very well in a republic; but the republican system would not do in an army. The
                details of such a plan as is proposed would have to be drawn up with some care. I have drawn up an ordinance, which I may offer at the proper time, as an amendment to a substitute offered by the gentleman from Richmond
                [<span className="persName">Mr. RANDOLPH</span>] . I think that the whole arrangement of rank ought to be left to the Governor. He, doubtless, will exercise this power according to proper discretion. This will prevent any conflict between the officers of the army and militia. Under the plan proposed by the gentleman from Richmond [<span className="persName">Mr. RANDOLPH</span>]
                , the Governor is authorized to assign such officers now in the United States service <PageNumber num={181} /> as may resign their commissions and enter the service of Virginia such rank as will not reverse the relative rank held by them in the United States service, and will, at least, be equivalent thereto. In times of danger it will not do to stand
                too much upon points of rank. There are many officers in the army who may do to command a regiment, while there are others of the same rank, or probably of inferior rank, who may be competent to command a brigade. I think the practice pursued in the regular army is when new regiments are organized, the officers are promoted without reference to rank, and this practice I approve of
                highly. It is this system of precedence that destroyed, in a great measure, the efficiency of the English army in the Crimea, and it has been a source of great difficulty in our army. It is only when new regiments are organized that the practice of selecting officers upon the principle of merit, and not of rank, prevails, and I am in hopes that this rule of selection will be observed
                in our service. If a force is called into service, and this principle shall be established, the Governor will have entire discretion in the matter of choosing officers, and will, I am satisfied, exercise it wisely. We will get rid of all difficulties arising from the old system of election-while there will be no interference with the present arrangement where companies are already
                organized. They can be mustered into service and the officers can be promptly selected for service during the existence of our difficulties.
              </p>
              <div className="speaker" id="sp3843"><span className="persName">Mr. JAMES BOISSEAU</span>, of Dinwiddie—</div>
              <p className="p-in-sp">
                As I understand, Mr. President, the gentleman from Richmond proposes to authorize the Governor of the State to accept the services of officers now in commission in the army and navy of the United States, who are native citizens of Virginia. When I was a member of the Legislature, three years ago, I voted on this floor for a sword to be presented to Lieut. Robert B. Pegram, a native
                of Dinwiddie county, for gallant services in the Chinese sea. Within the last hour or two, sir, I have shaken by the hand that gallant officer. He is now here, very likely in conference with the Governor, ready to offer his services to the State of Virginia in this trying emergency.
              </p>
              <p className="p-in-sp">Understanding that the substitute of the gentleman from Richmond [<span className="persName">Mr. RANDOLPH</span>] proposes to authorize the Governor to accept a service of that sort, I shall cheerfully vote for it</p>
              <div className="speaker" id="sp3844"><span className="persName">Mr. WM. C. SCOTT</span>, of Powhatan—</div>
              <p className="p-in-sp">I have an amendment to offer to the substitute of the gentleman from Richmond city [<span className="persName">Mr. RANDOLPH</span>] . I do not subscribe to all that has been said by my friend from Franklin [<span className="persName">Mr. EARLY</span>] . I agree with him that most of the field officers <PageNumber num={182} /> in Virginia have but little experience in military affairs; but I do not agree with him that all of them are entirely incompetent to perform the duties that may be required of them.</p>
              <div className="speaker" id="sp3845"><span className="persName">Mr. JUBAL A. EARLY</span>—</div>
              <p className="p-in-sp">I do not mean to say anything in regard to the general officers.</p>
              <div className="speaker" id="sp3846"><span className="persName">Mr. SCOTT</span>—</div>
              <p className="p-in-sp">
                A great deal of what the gentleman said, I have no doubt, is true; but I believe we have as good material in this State for making efficient officers, as they have in any State in the Union. It does not follow that because a man is in the regular army he is therefore capable of commanding an army. Many officers have fought in battle that are not capable of commanding an army, while
                others who never fought, are fully competent for that duty.
              </p>
              <p className="p-in-sp">The following is the amendment which I propose to offer:</p>
              <p className="p-in-sp">"That a Committee on Military Affairs, be appointed by the President, and that all subjects relating to Military Affairs be referred to that committee."</p>
              <p className="p-in-sp">It seems to me that all matters relating to military affairs ought to be referred to a committee of this character.</p>
              <div className="speaker" id="sp3847"><span className="persName">Mr. BENJAMIN F. WYSOR</span>, of Pulaski—</div>
              <p className="p-in-sp">I submit, whether it is in order to offer an amendment after the previous question has been called and the call sustained.</p>
              <div className="speaker" id="sp3848"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair thinks it is in order.</p>
              <div className="speaker" id="sp3849"><span className="persName">Mr. WILLIAMS C. WICKHAM</span>, of Henrico—</div>
              <p className="p-in-sp">As a member of the Legislature that passed the bill calling this Convention together, I desire to enter my protest against the construction placed upon it by the gentleman from Albemarle [<span className="persName">Mr. HOLCOMBE</span>]
                . The sole and distinctly expressed understanding of the Legislature when that bill was passed was, that if a majority of the people of this State voted in favor of reference, no ordinance of this Convention dissolving the connection of the State with the Federal Union, or in any manner altering the organic law of the State, should have force unless it was referred to the people and
                ratified by them.
              </p>
              <p className="p-in-sp">I desire simply to enter my protest against the construction given to this law; and I do this lest it may be supposed by my silence that I acquiesce in the construction placed upon it by the gentleman from Albemarle [<span className="persName">Mr. HOLCOMBE</span>]
                . I do not refer at all to his arguments in regard to the powers of the Convention in other matters; but as regards his assumption in behalf of the Convention of a right to make certain changes in the organic law, without taking the vote of the people upon such changes, I look upon it as the greatest usurpation of power that could possibly be perpetrated. It would, in fact, be
                ignoring <PageNumber num={183} /> the will of the people of Virginia, expressed in the most emphatic manner, with a majority at the polls of not less than 60,000 giving force and emphasis to it.
              </p>
              <div className="speaker" id="sp3850"><span className="persName">Mr. JOHN B. BALDWIN</span>, of Augusta—</div>
              <p className="p-in-sp">Sir, I regard it as an exceedingly important matter that there should be a reference of all this matter to a committee.</p>
              <p className="p-in-sp">The gentleman from Princess Anne [<span className="persName">Mr. WIsE</span>]
                informs us that salus populi is a sort of higher law known in free government. I deny it. I deny that we have any higher law under our system of government than the Constitution, or that we can have any higher law than the Constitution. I deny that there can be any salus populi in any just sense-any violation of fundamental constitutional principles. The gentleman seems to think that
                we are in revolutionary times now, and that, therefore, the great principles of free government are all to be forgotten. It seems to me if ever there was a time when it was necessary for us to appeal to the great cardinal principles of constitutional power, it is in a time like this. The fact that this is war time, is offered as an apology for a violation of the Constitution; but if
                we once adopt the principle of the salus populi supremo lex, there is no knowing what we may practice in the name of this salus populi. In the various revolutions recorded in history, we know what was practiced under the euphonious name of liberty. It is the beauty of our government that all the functions of government are limited by constitutional restrictions. If we have separated
                ourselves from the Government of the United States, we are yet a constitutional Republic. If we are separated we are yet a Republic, where every functionary must bow before the supremacy of the popular will. If we have revolutionized against the General Government, we have not yet revolutionized the State Government. It is a mere act of separation, and not an act of revolution.
              </p>
              <p className="p-in-sp">As to the idea of the gentleman from Albemarle [<span className="persName">Mr. HOLCOMBE</span>]
                that this act of Assembly does not limit our power, I am satisfied that an intimation from this body, that it felt itself free from the claims and obligations of that act of Assembly to refer back its action to the people, would raise a revolution among our own people, and they never would submit to it under Heaven. They would instantly check so gross an assumption of unyielded
                power, and rise in their majesty and turn this body out of doors, if necessary, at the point of the bayonet.
              </p>
              <p className="p-in-sp">Sir, the gentleman from Albemarle [<span className="persName">Mr. HOLCOMBE</span>] seems to think that a change in the fundamental law does not prohibit us from <PageNumber num={184} /> exercising power in co-ordinate functions, where, in fact, the organizing this body expressly prohibits them from changing the Constitution without the sanction of the people, expressed at the polls.</p>
              <p className="p-in-sp">
                The main features of this question, and those which are to be considered as peculiarly relating to the powers assumed for this Convention are these : In the first place, this Convention, like the people themselves, are under obligations to observe the Constitution of the State until it is lawfully changed. In the second place, it cannot be lawfully changed until it is submitted to
                the people and ratified by them. It is under these guarantees that the people consented to organize this Convention.
              </p>
              <p className="p-in-sp">
                I imagine, if the people felt that they were calling a Convention to change the Constitution, without reference to their subsequent sanction and approval, they would never have constituted such a body under Heaven. They constituted this body, believing that it was a body which was to be under the restraint of the Constitution of the State, while that Constitution remained in force;
                and believing that in the act convening this body, they had prohibited it from changing the fundamental law of the State, except by a vote of the people at the polls approving the change. I stated distinctly that I desired to forward the object in view; but my notion of the way to do so is to pass such changes in the Constitution of the State as will make it conform to the ordinance
                of secession. I desire that the Legislature should be called together and that the Governor and Legislature shall have full power to deal with the subject of appropriations in behalf of the people of Virginia.
              </p>
              <div className="speaker" id="sp3851"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">This is an important matter to be decided now. Its decision involves all that is dear to the people of Virginia; it involves their safety and their honor.</p>
              <p className="p-in-sp">
                If the argument of the gentleman and his principle be correct, you can do nothing whatever, until after the election in May. What are we to do in the meantime? Are we to stand still-we, the conservators of the people-and do nothing between now and May? I put the question, and will answer. I take it on either horn of this dilemma. If the invaders come down upon us, you are certain to
                have no election. If the invading army is to cross the line, West, North, and East, by sea and by land, where and how is the election to be held? Invasion can and will suspend the constitutional power, in the elective power of the State from doing anything.
              </p>
              <p className="p-in-sp">
                Take the other horn of the dilemma. Let the people say, we have seceded; our Convention has advised us to secede. In order to defend <PageNumber num={185} /> ourselves, in case we elect to secede, we will take the arms necessary to defend ourselves-we will take the forts that now threaten our lives and liberties; we will take the Navy-yard, that holds
                all the ship timber, the best in the United States. If we will take that Navy-yard now, we will have plenty of arms and ammunition. If we do not take it, when we vote to secede, we will have no ships, no muskets, no ordnance, no powder. Yes, sir, there lies 3,000 barrels of powder, and the gentleman's argument is that you can do nothing in self-defence; but must permit the enemy to
                invade us without raising a hand in resistance. The enemy has told you that he intended to invade if you dare to secede; and has told you, in reply to the peace proposition, that he intended to re-possess all the forts and arsenals, as if in utter contempt of the declared purpose of Virginia to resist any such attempt. The amount of the gentleman's argument is, that we are to do
                nothing; that we are to let the powder go, the navy-yard go, all the arms at Harper's Ferry go, the Thermopylx of the State go; and all this because of a mere stickling upon a point between tweedledum and tweedledee. He denies the doctrine of salus populi, when he himself acknowledged, in respect to Lincoln, that he was bound not to execute the law-to forbear from the execution of a
                law which he was sworn to execute.
              </p>
              <div className="speaker" id="sp3852"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">I gave no such vote.</p>
              <div className="speaker" id="sp3853"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">The gentleman himself offered a resolution to the effect that we will not suffer the Federal authorities to reinforce in the mainland or in the harbors.</p>
              <div className="speaker" id="sp3854"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">I offered a resolution that the President of the United States ought to favor peace, and forbear as long as possible from the execution of the laws, so far as related to the recapture of the forts and other public property.</p>
              <div className="speaker" id="sp3855"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                Did not the gentleman in offering such a resolution, ask the President not to do what the U. S. Constitution required him to do? In other words, the gentleman offered a resolution to the effect that to prevent a war he was bound to violate the spirit and defeat the end of the Constitution; and ought we not, for the sake of self-defence now, to take the same attitude that the
                gentleman said the President ought to take for the sake of peace? I certainly think we ought; for the necessity in the one case is as great as in the other. But I believe that you cannot have an election next month. My opinion is, that so many of the counties will be engaged in preparations for war, and so many absent in the camp, that an election cannot be held in them. I mean that
                no election will be held which <PageNumber num={186} /> will exhibit the true state of feelings in many of the counties. I say, then, our policy is to seize at once upon the arms and ammunitions which are now within our reach. This doctrine of adherence to technical constitutional requirements suited to ordinary circumstances, will not do; for the
                people must look to their own defence. I have no fear but that the people will settle this thing for themselves. They will take this salus populi into their own hands. Election or no election they will take Harper's Ferry; they will take the Gosport Navy Yard; and if you do not give them an opportunity to do so now, they will take them when the enterprize will involve imminent
                dangers, and it may be, a sacrifice which we cannot now contemplate. Let the people have an opportunity to protect their homes. Give them the arms by which to protect them. They are now within their reach, and they ought to have them, so that when an election is fairly held they may be able to act efficiently in defence of their homes and their liberties. I regret having to trouble
                the Convention again. I would not have said a word now, were it not for the strange course of remark in which the gentleman indulged.
              </p>
              <div className="speaker" id="sp3856"><span className="persName">Mr. J. G. HOLLADAY</span>, of Norfolk county—</div>
              <p className="p-in-sp">I dislike trespassing upon the time of the House at this late hour; but, in my judgment-and by that I must stand-it is necessary for me to make a few remarks in reference to the course which I shall hereafter pursue.</p>
              <p className="p-in-sp">And permit me to say that I agree with much that has fallen from the gentleman from Augusta [<span className="persName">Mr. BALDwIN</span>] , and some that has fallen from the gentleman from Princess Anne [<span className="persName">Mr. WISE</span>] . And the gentleman will pardon me for observing that it would be a little remarkable if I did not agree with him to some extent; for he has a facility of adapting himself to circumstances, which enables him to put himself on both sides of the same question. [Laughter.]
                I voted to-day against the Ordinance of Secession with all my heart and soul. I have no part of that act to retract or regret. I have done all that I could do to stave off a condition of things which I deeply deplored; but, in spite of my vote and opposition, I was unable to effect that which I most earnestly desired. I was borne down by that spirit of revolution which seems to have
                seized upon many here as well as outside.
              </p>
              <p className="p-in-sp">Sir, whilst I concur with the gentleman from Augusta [<span className="persName">Mr. BALDWIN</span>] , that this Convention is about to transcend its power, yet I must look at the condition of things as they now exist, and govern my action accordingly.</p>
              <p className="p-in-sp">Sir, I find my people are in a peculiar attitude. I find that steps <PageNumber num={187} /> may be taken, that may, perhaps, bring on a collision which may induce the sending on of Northern troops. I find that troops have already been sent from the city of Richmond, as has been stated here by the gentleman from Princess Anne [<span className="persName">Mr. WISE</span>] . Obstructions have been thrown in the channel with a view, as I understand, of preventing the egress of vessels of war now at the Navy-Yard. He says it is the act of the people. It might have been the act of the people; but, in my opinion, the edict went forth from the city of Richmond before the people of the city of Portsmouth or of Norfolk began to act.</p>
              <div className="speaker" id="sp3857"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Do you allude to me?</p>
              <div className="speaker" id="sp3858"><span className="persName">Mr. HOLLADAY</span>—</div>
              <p className="p-in-sp">I say it went forth from the city of Richmond.</p>
              <div className="speaker" id="sp3859"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I can say that I know of the fact that it originated in the city of Norfolk. I can substantiate it if necessary, and give to him all the information on that subject. A telegraphic dispatch was sent from Norfolk to Richmond, asking for a thousand men to prevent the Navy-Yard from being reinforced and to take it if possible.</p>
              <div className="speaker" id="sp3860"><span className="persName">Mr. HOLLADAY</span>—</div>
              <p className="p-in-sp">
                I say we must take things as they exist; and believing that there is a condition of things now existing which calls for prompt action, I am ready to transcend the power of this Convention, and to give such a vote as will be necessary to meet the exigency; but in giving it I feel that I am acting by an assumption of power that does not rest in this Convention. I do not desire,
                however, that this action shall be looked to as a precedent hereafter, to justify similar usurpations of power. If this action should be so held in the future, I would deplore very much that it had my sanction. I know that the assumption of a responsibility to exercise revolutionary functions not delegated to the Convention is a very serious one; but, as I have said, a great crisis
                is upon us, and we have to meet it even at the risk of transcending our legitimate powers.
              </p>
              <p className="p-in-sp">
                It has been said here that this act of Assembly confers upon this body the authority to act, and to act definitely in many respects; and the argument has been held here substantially, that when the people convened this Convention, the sovereignty passed from the people to the Convention, a proposition which I wholly deny. I maintain this: that when the people assembled a Convention
                to represent their sovereignty, as a matter of right their action should be referred back to the people unless they themselves otherwise expressly instruct. But in this case there have been instructions, and authority has been given to act only upon two questions and that was limited to advisory action.
              </p>
              <p className="p-in-sp">
                It is said, that more than this was contemplated in the reference <PageNumber num={188} /> to a change in our relations with the Federal Government. I hold that it is an entire misconstruction of the scope and meaning of the act to assume that it confers power to the extent sought to be exercised here by some members of this Convention. If the war power
                is given, then this implies an explanation which shall govern me in the votes which I shall give. I submit the whole question to the House.
              </p>
              <p>The question was upon the amendment to the amendment offered by <span className="persName">Mr. SCOTT</span>, of Powhatan, that a committee on military affairs be appointed, to which all subjects of a military character should be referred.</p>
              <div className="speaker" id="sp3861"><span className="persName">Mr. RANDOLPH</span>, of Richmond city—</div>
              <p className="p-in-sp">Since I have addressed the House in explanation of my resolutions, I have been called out, and found two navy officers with their commissions in their pockets, ready to go back and engage in our conflicts. They want to know whether they shall send in their commission or not.</p>
              <div className="speaker" id="sp3862"><span className="persName">Mr. WM. C. SCOTT</span>, of Powhatan—</div>
              <p className="p-in-sp">If the proposition of this gentleman has special view to any single officer, I will withdraw my amendment so as to enable the House to act upon his substitute.</p>
              <p>Leave was given to withdraw the amendment.</p>
              <div className="speaker" id="sp3863"><span className="persName">Mr. GEO. BLOW, JR.</span>, of Norfolk city—</div>
              <p className="p-in-sp">The resolution, as I understand it, authorizes and requires the Governor of the Commonwealth to employ in the service of Virginia all natives and residents of Virginia who are now officers in the army and navy of the United States. Now, there are two classes of men in that service—</p>
              <div className="speaker" id="sp3864"><span className="persName">Mr. RANDOLPH</span>—</div>
              <p className="p-in-sp">Permit me to make a small amendment to the resolution, by adding the words "all efficient and worthy members," natives or residents of Virginia, &amp;c.</p>
              <p>The amendment was agreed to.</p>
              <div className="speaker" id="sp3865"><span className="persName">Mr. JOHN B. BALDWIN</span>, of Augusta—</div>
              <p className="p-in-sp">I made a very earnest appeal to this body to induce them to discuss this question, which I regard as one of the most important that has ever occupied the attention of a deliberative assembly.</p>
              <div className="speaker" id="sp3866"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair will inform the gentleman that he has exhausted his privilege, having spoken twice upon the same subject.</p>
              <div className="speaker" id="sp3867"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">Then, if time is denied me by calling down the parliamentary rule, I must avail myself of parliamentary rules so far as may be necessary to enable me to state my proposition.</p>
              <p className="p-in-sp">I will offer the following amendment to the substitute of the gentleman from Richmond [<span className="persName">Mr. RANDOLPH</span>] :</p>
              <p className="p-in-sp">"That the Governor be authorized to call the General Assembly into <PageNumber num={189} /> extra session, to make such appropriations as may be necessary for the present emergency."</p>
              <p className="p-in-sp">I have twice already declared the sincerity of my vote upon this question, and would again renew that declaration, if I thought it was deemed necessary. But I do not, for I am satisfied that all appreciate the truth and sincerity of the declaration.</p>
              <p className="p-in-sp">We are aware that war is upon us, and that however correct the principles I have announced may be as general rules, they do not hold good now.</p>
              <p className="p-in-sp">
                Sir, if war is upon us, by what authority is war upon us? Who has declared war? Who has authorized it? The President of the United States, it is true, has threatened a war against the Confederate States, and a war in which I am perfectly disposed to make common cause with the Confederate States. But it is not yet our war until we adopt it. It is not a war threatened or declared
                against us; but against those States which had seceded before the President's proclamation was issued. Then, sir, I will take the ground that there was and is no military necessity in the way of an intended attack upon the State of Virginia, unless that military necessity has been created without authority or law.
              </p>
              <p className="p-in-sp">I understood from the gentleman from Princess Anne [<span className="persName">Mr. WISE</span>]
                , to-day, that an unauthorized expedition had been instructed to seize upon the armory at Harper's Ferry. If that be true, and that act is ratified and adopted by the Governor of the State, those persons engaged in it are acting in violation of law and in violation of the rights of the people of this State; and the Governor, in ratifying and adopting this act, is acting in violation
                of the Constitution of the State, is transcending his right and power as Governor, and in derogation of the rights of the people of this Commonwealth. And if this Convention adopts that unauthorized act of the people or Governor, it is undertaking, in advance, to forestall the people of this State in the decision of a question which they have reserved for their own decision.
              </p>
              <p className="p-in-sp">The gentleman cannot avoid that conclusion. The gentleman from Princess Anne [<span className="persName">Mr. WISE</span>]
                , has stated that in all probability there will be no election. Sir, what right have we, when the people have said, that our action shall go back before them, to bring about a state of things that would prevent them from having the right to pass upon it? If it be true that there can be no election, what object is there in the reservation of that right to the people? Sir, as the
                representative of a portion of the people of Virginia, in the name of constitutional obligation; in the name of constitutional liberty; in the name of the <PageNumber num={190} /> rights of all the people of the Commonwealth, I protest against this course of proceeding as in derogation of constitutional, representative liberty. This is a small matter,
                so far as we have gone. This particular .act is one of little or no consequence; but I wish to raise the question, and enter my protest against any, even the least, departure by this Convention from any constitutional power or lawful functions. We are yet but in the beginning of a revolution; but we are not yet overwhelmed by the din of arms and the rush of battle. When the time
                comes that war is raging through this Commonwealth, we may have most melancholy occasion to look back to this time when we could have acted calmly and wisely, and perfected such plans as the importance of the occasion demanded. We have asserted the proposition that we possess all power, and we are acting upon the dangerous power suggested by the salus populi.
              </p>
              <p className="p-in-sp">
                They say revolutions never go backwards, and if we start at the outset without regard to the proper limitations of power, cautious as we may hereafter be, I tell you we are in danger of emerging from this revolution anything but a free people. It may be that we will make this war at a disadvantage, as a people governed by constitutional law; it may be that constitutional law may be
                unfavorable to success in the State; but I would rather go into a war with all the disadvantages resulting from constitutional power than to throw off the reserves which we find necessary for the safety of the people.
              </p>
              <p className="p-in-sp">
                For one, I never can consent to leave the principle of constitutional law, of limited government and of representative responsibility and restraint, to launch out upon any principle so vague, so ominous of evil as the principle announced in the maxim of salus populi. In the name of my constituency; in the name of constitutional law; in the name of constitutional liberty; in the name
                of representative responsibility, I protest against this act.
              </p>
              <div className="speaker" id="sp3868"><span className="persName">Mr. BRANCH</span>, of Petersburg—</div>
              <p className="p-in-sp">I trust the Convention will vote down the amendment.</p>
              <p>The question was then put on the amendment offered by <span className="persName">Mr. BALDWIN</span>, authorizing the Governor to call the Legislature into extra session, and decided in the negative.</p>
              <p><span className="persName">Mr. EARLY</span> was about to offer an amendment, when <span className="persName">Mr. MONTAGUE</span> made a point of order, to the effect that no amendment was in order; the previous question having been called and sustained upon the substitute offered by <span className="persName">Mr. RANDOLPH</span>.</p>
              <p>The President sustained the point of order.</p>
              <PageNumber num={191} />
              <p>The question then recurred upon the substitute offered by <span className="persName">Mr. RANDOLPH</span>.</p>
              <div className="speaker" id="sp3869"><span className="persName">Mr. PETER C. JOHNSTON</span>, of Lee and Scott—</div>
              <p className="p-in-sp">I call for a division of the question.</p>
              <p>The vote was then taken on each of the two first resolutions separately, and they were adopted.</p>
              <p>The question having recurred upon the third resolution, which proposed to make an appropriation sufficient to enable the Governor to carry out the objects of the two first resolutions,</p>
              <div className="speaker" id="sp3870"><span className="persName">Mr. GEO. W. RANDOLPH</span> said—</div>
              <p className="p-in-sp">I would suggest that the blank in that resolution be filled with $100,000.</p>
              <div className="speaker" id="sp3871"><span className="persName">Mr. JAS. B. DORMAN</span>, of Rockbridge—</div>
              <p className="p-in-sp">I move to amend by substituting the sum of $500,000.</p>
              <div className="speaker" id="sp3872"><span className="persName">Mr. RANDOLPH</span>—</div>
              <p className="p-in-sp">My proposition is confined to the amount of available funds now in the treasury. There is another resolution prepared by the gentleman before me, authorizing a loan of one million dollars which will come in as a separate proposition.</p>
              <p><span className="persName">Mr. DORMAN</span> then withdrew his amendment, and the question having been put on the motion of <span className="persName">Mr. RANDOLPH</span> to fill the blank with $100,000, it was carried.</p>
              <div className="speaker" id="sp3873"><span className="persName">Mr. SCOTT</span>, of Powhatan—</div>
              <p className="p-in-sp">I beg leave to offer the following resolution :</p>
              <p className="p-in-sp">"Resolved, That a committee of thirteen be appointed by the President, and that all resolutions in relation to military matters be referred to said committee."</p>
              <div className="speaker" id="sp3874"><span className="persName">Mr. PRICE</span>, of Greenbrier—</div>
              <p className="p-in-sp">Thirteen is a very inconvenient number. I should suppose seven would answer the purpose better.</p>
              <p>The resolution was rejected.</p>
              <div className="speaker" id="sp3875"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I think it is absolutely necessary that we should communicate the fact of the passage of the ordinance to our sister border slave States, in confidence, and that we should also communicate it in confidence to the President of the Confederate States. We should communicate it immediately to the Governor, for without doing so we cannot talk with him on the subject.</p>
              <p className="p-in-sp">I now move to take up the resolution which I offered on the subject in the early part of the day.</p>
              <p>The resolution was taken up. It reads as follows:</p>
              <p>
                Resolved, That the President of this Convention communicate in confidence by special messenger the ordinance of the Convention resuming the powers granted under the Constitution of the United States to the Federal Government, to the President of the Confederate <PageNumber num={192} /> States, and that the obligation of secrecy be removed, so far as it
                applies to the Governor of this Commonwealth, with a request to observe it as strictly confidential, except so far as he may find it necessary to issue secret orders.
              </p>
              <div className="speaker" id="sp3876"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I would move to add after the words "to the President of the Confederate States," the words "and to the Governors of the non-seceded slaveholding States."</p>
              <div className="speaker" id="sp3877"><span className="persName">Mr. THOMAS S. FLOURNOY</span>, of Halifax—</div>
              <p className="p-in-sp">I would suggest to the gentleman that it would be better to designate the States. I don't think it would be well to use the comprehensive term "Governors," inasmuch as Governor Hicks, of Maryland, would be embraced in that designation.</p>
              <div className="speaker" id="sp3878"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                I am informed that the Governor of Maryland has not obeyed the requisition for troops made upon him by the Secretary of War. I would certainly be unwilling, out of respect to Maryland, to make any distinction in regard to her or her Governor. Governor Hicks, I believe, in his personal relations, is a gentleman who has an appropriate sense of his obligations as such ; and I am
                satisfied, if this matter is communicated to him in confidence, he will adhere to the obligation and keep it secret.
              </p>
              <div className="speaker" id="sp3879"><span className="persName">Mr. JOHN ECHOLS</span>, of Monroe—</div>
              <p className="p-in-sp">
                I would ask what particular object would be attained by maintaining secrecy any longer as to the passage of the ordinance of secession? Whether this particular object can be attained more readily if the communication is made in confidence to the Governors of the six border slave States and the President of the Confederate States, than if made under no such injunction; and what good
                is to be attained if they are to regard that communication as confidential? I ask for information on this subject.
              </p>
              <div className="speaker" id="sp3880"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">
                I would say that for the present and while our messengers would be on this mission, there are orders to be issued here which ought not to be known at Washington. Our messengers may not get to their destinations before our orders are issued, but in case a necessity should not arise for the issue of these orders immediately, it is very necessary that steps should be taken to
                communicate this information to the parties designated. This precaution is only intended for the present. We can at any moment hereafter remove it. This does not compel us to maintain the injunction of secrecy for any particular time. The probability is that we will remove it before even our messengers can get to their destination.
              </p>
              <div className="speaker" id="sp3881"><span className="persName">Mr. JAMES C. BRUCE</span>, of Halifax—</div>
              <p className="p-in-sp">I would suggest to my friend from Princess Anne [<span className="persName">Mr. WISE</span>] , that it will require a great deal of <PageNumber num={193} /> time, and involve a great deal of expense to send messengers to these different States. I think we could as well send these communications by mail as by messengers.</p>
              <div className="speaker" id="sp3882"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Well, then, send it through by mail or messenger. I would not be willing to trust the mail. I would rather incur the expense of messengers than send it by mail at present.</p>
              <div className="speaker" id="sp3883"><span className="persName">Mr. JAMES B. DORMAN</span>—</div>
              <p className="p-in-sp">I beg leave to say that, so far as I am concerned, I prefer the resolution in its original form, and without the amendment. I think the proper course would be to communicate the matter to the President of the Confederate States, and say nothing to the Governors of the non-seceded slaveholding States.</p>
              <div className="speaker" id="sp3884"><span className="persName">Mr. PETER C. JOHNSTON</span>, of Lee and Scott—</div>
              <p className="p-in-sp">
                If this communication is to be made to the Governors confidentially, of course it must be kept secret by them until the injunction of secrecy is removed by this Convention. Why not delay the communication until that injunction is removed, and then make the communication formally? No good can result from communicating the matter subject to the injunction of secrecy. The Governors
                cannot make this information available for any practical purpose while subject to this restriction.
              </p>
              <div className="speaker" id="sp3885"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">If my friend understood the reply I made to the gentleman from Monroe [<span className="persName">Mr. ECHOLS</span>] , he would see what object I meant to accomplish by the course I propose to pursue.</p>
              <p className="p-in-sp">I said that we will probably remove the injunction of secrecy by the time that the messengers can get to their destination, or perhaps before; and even if the messengers got there before the injunction is removed, the executives of the respective States can take various actions without the necessity of disclosing the secret.</p>
              <div className="speaker" id="sp3886"><span className="persName">Mr. EDWARD M. ARMSTRONG</span>, of Hampshire—</div>
              <p className="p-in-sp">It seems then that the injunction is not intended to rest longer than two or three days. In that case, the news will reach these Governors by telegraph in advance of the messengers.</p>
              <div className="speaker" id="sp3887"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">I will say that I doubt exceedingly the propriety of communicating to the Executives of these States so important a matter under the obligation of secrecy.</p>
              <div className="speaker" id="sp3888"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">No, the most respectful term is confidence, and that is the word used in the resolution.</p>
              <div className="speaker" id="sp3889"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">
                Confidence and secrecy mean the same thing in that connection ; so that if the communication is made under such an obligation, they will be compelled to keep it a strict secret, no matter what the importance of its revelation may be. It seems to me, if the <PageNumber num={194} /> communication is made, that it ought to be made at the discretion of the
                Governor; and I trust that the Convention will so decide.
              </p>
              <div className="speaker" id="sp3890"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I move the previous question.</p>
              <p>The call was sustained and the main question ordered to be put, being the resolution offered by <span className="persName">Mr. WISE</span>, which was adopted.</p>
              <p>The Convention then, on motion of <span className="persName">Mr. DULANY</span> of Fairfax, adjourned at 10 minutes past 11 o'clock, P. M.</p>
            </div>
          </div>
          <div className="day" id="vsc1965.v4.2.3">
            <PageNumber num={195} />
            <h2><span className="headingNumber">1.3. </span><span className="head">THIRD DAY</span></h2>
            <div className="dateline">Thursday, <span className="date">April 18</span></div>
            <p>The Convention met at 10 o'clock, A. M.</p>
            <div className="speaker" id="sp3891"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">I am very much indisposed this morning and will request <span className="persName">Mr. SOUTHALL</span> to act for me.</p>
            <p><span className="persName">Mr. VALENTINE W. SOUTHALL</span>, of Albemarle, accordingly took the chair.</p>
            <div className="speaker" id="sp3892"><span className="persName">Mr. WM. C. SCOTT</span>, of Powhatan—</div>
            <p className="p-in-sp">Mr. President, I beg leave to offer the following Ordinance which I am satisfied will meet the approval of the Convention:</p>
            <p>Be it Ordained by this Convention, That all the commissions of all the military officers of this State be vacated, and that the Governor of the Commonwealth renew the said commissions, and that, hereafter, no officer of this Commonwealth, civil or military, shall be required to take an oath to support the Constitution of the United States.</p>
            <div className="speaker" id="sp3893"><span className="persName">Mr. Wms. C. WICKHAM</span>, of Henrico—</div>
            <p className="p-in-sp">I would suggest a slight amendment, providing that the several officers, when re-commissioned, shall rank as they now rank.</p>
            <div className="speaker" id="sp3894"><span className="persName">Mr. WM. B. PRESTON</span>—</div>
            <p className="p-in-sp">May I ask the gentleman to give way for a moment to enable me to make an important suggestion?</p>
            <div className="speaker" id="sp3895"><span className="persName">Mr. SCOTT</span>—</div>
            <p className="p-in-sp">I give way cheerfully.</p>
            <div className="speaker" id="sp3896"><span className="persName">Mr. PRESTON</span>—</div>
            <p className="p-in-sp">The Ordinance of Secession says it will take effect this day. There is no date in the Ordinance, and I propose to add "this 17th day of April."</p>
            <p>The amendment was agreed to unanimously.</p>
            <p>The consideration of <span className="persName">Mr. SCOTT</span>'S ordinance was then resumed.</p>
            <div className="speaker" id="sp3897"><span className="persName">Mr. GEORGE W. RICHARDSON</span>, of Hanover—</div>
            <p className="p-in-sp">I suggest to my friend from Powhatan [<span className="persName">Mr. SCOTT</span>] , to include in that ordinance a provision for the recommission of all officers in the Commonwealth, civil as well as military.</p>
            <div className="speaker" id="sp3898"><span className="persName">Mr. SCOTT</span>, of Powhatan—</div>
            <p className="p-in-sp">
              I have no sort of objection to the gentleman moving any amendment he chooses. My whole purpose is to get rid of the obligation that is now resting upon the officers of the Commonwealth of Virginia, in respect to the form of oath which they have taken. There is not an officer in the Commonwealth in commission, who has not taken an oath to support the Constitution of the United States. I
              know it is not the place of this Convention to relieve an individual from an oath; but every oath of this character that is taken, is taken impliedly to rest so long as he holds the commission.
            </p>
            <PageNumber num={196} />
            <p className="p-in-sp">When the commission is vacated he is absolved from the oath. When the commission is renewed then he takes a new oath, and thus frees himself from all responsibility under the former oath.</p>
            <div className="speaker" id="sp3899"><span className="persName">Mr. SAMUEL G. STAPLES</span>, of Patrick—</div>
            <p className="p-in-sp">I simply want to make an inquiry touching the subject of this ordinance.</p>
            <p className="p-in-sp">
              If I understand the nature of the ordinance of secession which we passed here yesterday, it is not binding upon the people until it shall be ratified by the people themselves at the polls. I simply rise to inquire how it is that while we are part and parcel of the General Government, an officer can be expected to repudiate his obligation to support the Constitution of the United
              States?
            </p>
            <div className="speaker" id="sp3900"><span className="persName">Mr. SCOTT</span>, of Powhatan—</div>
            <p className="p-in-sp">According to my view, the oath of every officer lasts during the time he holds his office and no longer. The object of the ordinance is to absolve him from that obligation by declaring the office vacant.</p>
            <div className="speaker" id="sp3901"><span className="persName">Mr. WM. G. BROWN</span>, of Preston—</div>
            <p className="p-in-sp">I desire to inquire of my friend if that oath is not taken by the Governor himself, and if he can forego the obligation under the ordinance?</p>
            <div className="speaker" id="sp3902"><span className="persName">Mr. J. B. BALDWIN</span>, of Augusta—</div>
            <p className="p-in-sp">
              It appears to me if it is the purpose of this Convention to enter upon the discharge of these sort of functions, that we had better go a little deeper into this matter. I took occasion to state on yester, distinctly to the Convention, my views as to the scope of the powers of this body, and the earnest objections which I entertain to our departing from well defined limitations, as it
              seemed to me.
            </p>
            <p className="p-in-sp">
              Having defined my position in regard to that matter, I expressed the earnest conviction which I still entertain, that it would be wiser and better for us to confine ourselves strictly within the limits of our power under the Constitution of Virginia. I think it proper to state now that, understanding the judgment of the Convention to be different, and that it is its purpose to exercise
              a power that, I have thought, belonged elsewhere, I have discharged what I have regarded as my own duty to my constituents, and will offer no further opposition to any exercise of power on the part of this Convention on the mere score of power. I have placed myself right in regard to this matter. I do not intend to be placed in the position of a factious opponent in any sense of the
              term. From this moment further I wish to be understood as taking up and considering in good faith every measure that comes before this body, upon the single ground of expediency, to forward the great object in view. I believe we are all, by the judgment of this Convention, required to engage in that work forthwith. <PageNumber num={197} /> I believe, sir,
              that in taking this position, I will serve the Commonwealth in the circumstances by which I am surrounded, although I still believe the Convention would better serve the Commonwealth by another course.
            </p>
            <p className="p-in-sp">
              Now, sir, in the spirit and with a determination to go right to the mark of placing this Commonwealth upon the highest possible ground of military efficiency, I ask what is the practical benefit of the measure here propounded? Sir, if our ordinance of secession amounts to anything, it has performed the function which is now proposed by the ordinance of the gentleman from Powhatan
              [Mr. ScoTT]
              . The oath to support the Constitution of the United States is an oath which applies during continuance in office. If a man is to have merely his appointment changed, while his office remains the same, it does not at all affect the question of conscience involved. The question lies deeper than that. We all recognize the right of revolution, and we all recognize that a revolution by a
              State is not at all like the right at a regiment muster; that the State commanding the soldier has a right to carry along with it the unit action of all her best citizens, with a view to the maintenance of social order; and as a part of this power to maintain social order, she has a right to command her citizens to cease to serve anybody else but herself as the holder of social order.
              It is a revolutionary right, and I think that this is the position that is now taken by the State of Virginia; and that every citizen is free to act and vote for the State of Virginia, without regard to past associations, or past oaths, or anything of the sort. But this proposition to change the commissions of the military officers amounts to nothing. If we want an efficient military
              organization, as a matter of course it should be found in the volunteers whose officers are selected with more reference to their qualifications than officers of the regiments of the line. Why is it that more of the latter are not selected with reference to their qualifications? It is because the prospect of employment has been always so remote, and when they come into service, they
              come there without a just sense of the responsibility resting upon them. But declare all the offices in the line vacant to-day and direct them to be filled now with reference to the emergency that is upon us, and I venture to say the field officers in the militia will be filled by as efficient a body of men as can be started anywhere on the face of the earth. If there is a man within
              the limits of any county in which a regiment is to be mustered, who has a military education, he will, in view of the emergency upon us, be required to fill the office thus declared vacant.
            </p>
            <PageNumber num={198} />
            <p className="p-in-sp">
              As to the general officers, I am satisfied that it would be true policy to take their commissions and to have them filled by the Governor of the Commonwealth, with reference to eminent service. Sir, we have a good many of the general officers of the militia of this Commonwealth "lagging superfluous on the stage;" and if we are to call upon the entire military force of the State, as I
              believe we shall have to do before we come out of this great enterprise, it behooves us, sir, to have every military office of importance filled, and filled promptly, with the highest military talent of the State. It seems to me that this would look more like military efficiency than a mere change of oath, continuing the same inefficient men.
            </p>
            <p className="p-in-sp">
              I throw out these suggestions for the Convention to act upon as they may think,proper. It is my purpose to support whatever in the judgment of this Convention shall be most efficient for the work of organizing a proper military force, and otherwise promoting the success of our cause. If these suggestions should meet the favor of the Convention, I trust that they may be considered by
              some military man who knows more about military matters than I do. I was a military man myself once. I never set a squadron in the field; but suppose I was as competent as militia colonels generally are. I have no reason, personally, to be concerned about the proposed change of the commissions. I only have to say that, under any circumstance, I am ready to resist the invaders.
            </p>
            <div className="speaker" id="sp3903"><span className="persName">Mr. GEORGE W. RICHARDSON</span>, of Hanover—</div>
            <p className="p-in-sp">I desire to bring to the notice of the Convention a matter personal to myself.</p>
            <p className="p-in-sp">
              Mr. President, just a few minutes before 11 o'clock last night this Convention adopted an ordinance giving to the Governor of the State the power to call into service the whole volunteer force of the Commonwealth, and to organize that force into regiments, brigades and divisions. I command at this time the 74th, in the county of Hanover. In that regiment there are five volunteer
              companies-four thoroughly organized and armed, one in process of organization, which will, I believe, be armed and organized in the course of a few days. The Governor is authorized under that ordinance to detach from every regiment in the State every volunteer company which has been organized within the bounds of that regiment, and in detaching them to depose the field officers now in
              command, and to substitute in their stead other officers. Of that matter I do not complain. I desire to say that the companies in my regiment have been organized with great trouble on my own part and on the part of the company officers, as, I think, will be testified by my friend, the representative from <PageNumber num={199} /> Henrico
              [<span className="persName">Mr. WICKHAM</span>]
              . Now this Convention has adopted an order of secrecy. That obligation has been removed to a certain extent so far as the Governor is concerned, because the Convention has communicated to him confidentially the fact of the passage of the ordinance of secession; and it will no doubt also communicate to him the power conferred upon him by the ordinance of the gentleman from Richmond
              [G. W. RANDOLPH] , adopted last night. I do not want any gentleman to get up here and tell me that it having been communicated to the Governor that this organization is to take place, there is no use in making the appeal which I now make to the Convention.</p>
            <p className="p-in-sp">
              I desire that the Convention shall extend to me the privilege of allowing me to tender to the Governor without violating this obligation of secrecy my humble services to be associated with the volunteers of my own regiment. As soon as this ordinance passed, I addressed a communication to the Governor, tendering to him the services of this regiment; but I was advised by my friends, if
              it was not trespassing on the courtesy of the Convention, to get their permission. I would therefore ask the Convention to relieve me from the obligation of secrecy, so far as to allow me to tender my services to the Governor of the Commonwealth.
            </p>
            <div className="speaker" id="sp3904"><span className="persName">Mr. GEO. W. RANDOLPH</span>, of Richmond—</div>
            <p className="p-in-sp">There is no injunction of secrecy as between the members of this Convention and the Governor.</p>
            <div className="speaker" id="sp3905"><span className="persName">Mr. BOULDIN</span>, of Charlotte—</div>
            <p className="p-in-sp">It seems to me that we are at perfect liberty to communicate with the Governor without any special permission of the character now applied for. If this order is passed it will impliedly deny to us the power to communicate with the Governor.</p>
            <p className="p-in-sp">The reading of the resolution offered by <span className="persName">Mr. WISE</span> on yesterday, directing, among other things, the obligation of secrecy to be removed, so far as to communicate the fact of the passage of the Ordinance to the Governor, was called for. It was read by the Secretary.</p>
            <div className="stage it">[See second day's proceedings.]</div>
            <div className="speaker" id="sp3906"><span className="persName">Mr. WM. B. PRESTON</span>—</div>
            <p className="p-in-sp">I came to the House this morning under the impression that it would be good policy to remove the injunction of secrecy, so far as to announce that an Ordinance of Secession had passed. My impression now is that it would be wise to remove this obligation; and with that view I will move to amend the motion of the gentleman from Hanover [<span className="persName">Mr. RICHARDSON</span>] , that this House remove the obligation of secrecy, so far as to announce that an Ordinance of Secession has passed.</p>
            <div className="speaker" id="sp3907"><span className="persName">Mr. RANDOLPH</span>—</div>
            <p className="p-in-sp">I shall then ask the Convention to remove the <PageNumber num={200} /> reflection upon me which this course would imply, for I have had conversations with the Governor upon subjects which transpired here.</p>
            <div className="speaker" id="sp3908"><span className="persName">Mr. WISE</span>—</div>
            <p className="p-in-sp">I am in the same situation.</p>
            <div className="speaker" id="sp3909"><span className="persName">Mr. HUGH M. NELSON</span>, of Clarke—</div>
            <p className="p-in-sp">I tendered my services to the Governor as Major of a regiment, so that I am guilty also of a violation of the injunction of secrecy, if such it be.</p>
            <div className="speaker" id="sp3910"><span className="persName">Mr. WISE</span>—</div>
            <p className="p-in-sp">
              In objecting to the motion of the gentleman from Hanover, I do it merely to prevent an entry upon the record which would imply, at least, some reflection upon members-myself among the number-who have already done what the gentleman asks permission now to do. I am confident that this motion is made with no intention to reflect upon those who have already conversed with the Governor on
              matters of a secret character appertaining to this Convention.
            </p>
            <div className="speaker" id="sp3911"><span className="persName">Mr. SAMUEL G. STAPLES</span>, of Patrick—</div>
            <p className="p-in-sp">I move that the proposition of the gentleman from Hanover [<span className="persName">Mr. RICHARDSON</span>] be laid upon the table, and I beg to say that I make that motion out of no disrespect to the gentleman.</p>
            <p>The motion was agreed to-ayes 62, noes 26.</p>
            <div className="speaker" id="sp3912"><span className="persName">Mr. RICHARDSON</span>—</div>
            <p className="p-in-sp">I desire to say that I am indebted to this Convention for nothing. This small request not having been accorded to me, thank God, I owe them nothing.</p>
            <div className="speaker" id="sp3913"><span className="persName">Mr. TIMOTHY RIVES</span>, of Prince George and Surry—</div>
            <p className="p-in-sp">Before voting on the resolution offered by the gentleman from Powhatan [<span className="persName">Mr. SCOTT</span>] , I desire to define the position that I occupy here.</p>
            <p className="p-in-sp">
              I say that no man in this Convention will go farther than I will to carry the war to a successful termination. I tell gentlemen here, to whom I have been opposed from the beginning, that I will vote for any measure that military men say is necessary. I will give this vote, believing that we are not acting according to the Constitution of the State of Virginia or of the United States. I
              will do it with the same spirit in which I voted for that ordinance of secession. I will vote for it, because I believe we are in a state of revolution. I believe there is no law to justify this. How can I go and take an oath that Virginia is now subject to the United States Constitution that is not now operating in this State, when we are not actually out of the Union until the people
              ratify the ordinance of secession by their votes at the polls on the fourth Thursday in May? When that is done, the exercise of the power of peaceable secession will have been accomplished. I cannot believe that any man can get up here, after <PageNumber num={201} /> having read the act of the Legislature convening this body, and say that this ordinance
              of secession has any validity until it is ratified by the people. The requirement to send it back for ratification stands as the recorded will of the State, by a majority of 60,000. Meanwhile, I say, it is better to assume the powers necessary to enable us to meet the pressing emergency and protect the people from invasion. For my part, I am satisfied to do that, but I cannot agree to
              construe the act of Assembly as giving authority to exercise these powers. I repeat again, that any vote I may give here to the end of carrying out this object, will not be given under the sanction of law as we now stand; on the contrary, if we are to be regulated by law, the law must change from what it has been at its passage. Virginia is not out of this Union until this ordinance is
              ratified by the people; and every act that we do from this time, until the ratification of the ordinance by the people in pursuance of the objects designated here, will be a departure from the Constitution of the State, and of the United States, will be to carry out the revolution that is now existing. It was from this conviction that I voted against the preamble to that ordinance.
              Fifty years from now, when my children rise up and look at that preamble, they will say to your children, what did your father see to justify this course on their part? If your preamble set forth the true cause of this movement and presented a full justification for it, such as really existed, they would have placed the General Government in the wrong and us in the right. I shall vote
              for the resolution cheerfully.
            </p>
            <div className="speaker" id="sp3914"><span className="persName">Mr. R. Y. CONRAD</span>, of Frederick—</div>
            <p className="p-in-sp">
              It appears to me, in matters of such importance, that it would be better not to forego the usual course of proceeding in deliberative bodies, to refer all propositions, especially those of an important character, like the one under consideration, to committees. Various propositions have been made here, and passed, in all probability, without being thoroughly understood by the House, as
              must always be the case in a body like this.
            </p>
            <p className="p-in-sp">
              There was one other matter in reference to other resolutions, which I desire to mention, although the suggestion was made, I believe, by some other gentleman; and it is this: that if any actual service is expected from the present militia officers attached to the ordinary military organizations, I should deem it absolutely necessary that there should be a change in these officers at
              least, and that the power should be somewhere vested of appointing efficient officers of every grade; because it is well known to every gentleman upon this floor that the militia officers of the Commonwealth, generally appointed merely for <PageNumber num={202} /> holiday practice, are not of the efficient character which would be required in actual
              service.
            </p>
            <p className="p-in-sp">
              But, Mr. President, my principal object in rising was to trouble the Convention but for a moment with a subject with which I have never, I believe, troubled them before, and that is my individual views, and position, and action upon this floor. I think the time has come when it is my duty to say one word only upon a subject not irrelevant by any means to the subject before the House
              already. It is applicable as well to various other propositions that have been made as to the one which is now immediately under consideration.
            </p>
            <p className="p-in-sp">
              I deemed it my duty to my constituents to vote against the ordinance of secession, which passed this body yesterday; and it is to avoid any misapprehension of my own individual views and position here, that I desire to say, that I entertain, in regard to the action now passing before this House, and in regard to that ordinance of secession, none of the scruples which were indicated by
              some other gentlemen upon this floor. I have no difficulty whatsoever in using every effort, and promoting every effort that may be thought proper and necessary to put this Commonwealth in a complete and thorough condition of military preparation for war. I have no scruples whatever in respect to any measures of opposition that may be inaugurated here, to the threats and the actions
              tending to enforce these threats, that have emanated from the Federal authorities at Washington. I voted against this ordinance of secession mainly because I regard the whole course of action taken by the Federal authorities and indicated by the proclamation of the President, as an unconstitutional usurpation of power; and I have therefore no hesitation, and should have none as a
              member of the ordinary State Legislature of Virginia, and think that the Executive authority of the Commonwealth should have none, in resisting, to the uttermost extremity, all efforts of these Federal authorities, because, as I have heretofore indicated to the Convention, my own opinion is fixed and positive, that in the present state of things the Federal authority, under the
              Constitution of the United States, is utterly paralyzed.
            </p>
            <div className="speaker" id="sp3915"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">The Chair is constrained to remark that in his opinion the gentleman is taking too much latitude.</p>
            <div className="speaker" id="sp3916"><span className="persName">Mr. CONRAD</span>—</div>
            <p className="p-in-sp">
              It is not my purpose to make a long speech. I wish to indicate that in regard to this action I have no difficulty whatever. I am willing to go now to the extremest point to prepare for war. My opinion, however, is, Mr. President, and I throw this out for the consideration of the Convention, that we should have some <PageNumber num={203} /> declaration, as
              it does not seem to be indicated in the preamble-discriminating between the action of the Federal authority under the constitution and the unconstitutional and usurped power which that Government has assumed. This has reference to the proposition now before you. I should prefer that the ground for this action on the part of the Convention should be in some way indicated; but as I said,
              I am prepared, whether this is given or not, to concur heartily with gentlemen upon the floor, in making full preparations for the coming conflict. I am unwilling, however, and it may be necessary to make these limitations as to some of these proposed measures-I am unwilling, before the people of Virginia shall have voted to approve the ordinance of secession, to vote for any
              proposition which plainly implies that the State of Virginia has already withdrawn her people from their connection with the other States under this Union; for I don't believe that this is a fact. By the vote of the people of Virginia in the election of the members of this Convention, it was declared by the people that any action on the part of this Convention severing any connection
              of this State with the Union of States, should have no effect until it should be subsequently approved by the popular vote. That approval not having been given, our action is merely inchoate and cannot be regarded as having effected a dissolution of the connection between this State and the Federal Government. I can therefore vote conscientiously for no measure which declares or
              assumes that the State of Virginia has already severed her connection with the other States, and I deem this the best and the safest position which Virginia can assume at this time-to resist the President of the United States and the Federal authorities upon the ground that they are exercising or attempting to exercise usurped power over the people of this Commonwealth and the people
              of other States in this Union; and to hold them responsible as individuals, as lawless usurpers of power, and not acting under the Constitution of the Federal Government or any other Constitution; and I think, sir, that it is due to the people of Virginia; it is due to the safety of the people of Virginia; it is due to ourselves in some mode or other, to declare to the world that we
              have not chosen, by a mere voluntary act, to overturn this Federal Government, which I admit that the people of Virginia had a right to do under the circumstances. That we did not, upon a mere act of volition, destroy the integrity of this Union; but that we did it upon the ground that this Federal authority, elected temporarily under the Constitution, holding office for a short term,
              had undertaken to disregard all constitutional limitation, and, in violation of the <PageNumber num={204} /> Constitution and of the natural rights of the people of Virginia and of the United States, are attempting to exercise lawless, usurped authority. It is upon that ground we should place all the action of this Convention-at least in part-if gentlemen
              deem it necessary to take any ground for their justification other than those which past events have developed.
            </p>
            <p className="p-in-sp">
              I beg gentlemen who have the control of these proceedings-for I don't mean to seek any particular participation in them, but simply as a silent member to vote for them-I ask gentlemen to consider this question, and say whether some ordinance ought not to be passed declaring the usurpation of authority by this Federal Government, and declaring our action to be based in part upon that
              ground. The idea I throw out was mentioned by my friend from Prince George
              [Mr. IllyEs]
              , as the basis of his action, and of his concurrence with the action here. I wish to place myself right; and I merely desire to say, in addition to what I have already said, that I am not in the habit of making professions. I don't expect to go into this war myself, but I have a deep interest in it. I have four sons now that are able to bear arms, and if there is to be a war I know
              they are all to be in it. I have, therefore, a deep stake in it. I may add, simply, that I will not attempt to withhold them if it becomes necessary to protect the land of my birth and of the birth of my forefathers, which took place long before Virginia was a State at all.
            </p>
            <div className="speaker" id="sp3917"><span className="persName">Mr. SCOTT</span>, of Powhatan—</div>
            <p className="p-in-sp">At the suggestion of several friends, I withdraw the resolution I offered, and submit the following in its stead :</p>
            <p className="p-in-sp">"Resolved, That a Committee on Military Affairs, consisting of seven, be appointed by the President."</p>
            <div className="speaker" id="sp3918"><span className="persName">Mr. EPPA HUNTON</span>, of Prince William—</div>
            <p className="p-in-sp">Do I understand that as being offered by way of a substitute?</p>
            <div className="speaker" id="sp3919"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">No. It is offered as an original proposition. The other resolution has been withdrawn.</p>
            <p>The resolution was adopted.</p>
            <div className="speaker" id="sp3920"><span className="persName">Mr. JOHN GOODE, JR.</span>, of Bedford—</div>
            <p className="p-in-sp">I have a resolution which I desired to get the floor yesterday to offer. I think it is eminently proper, under the circumstances which surround us now, that it should be adopted. It reads as follows:</p>
            <p className="p-in-sp">Resolved, That this Convention approves and endorses the response of the Governor of the Commonwealth to the call made upon him by the Secretary of War at Washington for a quota of the militia of Virginia; a copy of which response has been communicated to this Convention.</p>
            <PageNumber num={205} />
            <p className="p-in-sp">Resolved, That a copy of the foregoing resolution be immediately communicated to the Governor.</p>
            <p>The resolutions were adopted.</p>
            <div className="speaker" id="sp3921"><span className="persName">Mr. WM. BALLARD PRESTON</span>—</div>
            <p className="p-in-sp">I beg leave to offer the following resolution :</p>
            <p className="p-in-sp">Resolved, That the injunction of secrecy be and the same is hereby removed, so far as to permit the disclosure and the publication of the ordinance of secession, passed by this body on yesterday, to take effect as an act of that day, when the same is ratified by the people at a poll to be taken thereon on the 4th Thursday in May next.</p>
            <div className="speaker" id="sp3922"><span className="persName">Mr. PRESTON</span>—</div>
            <p className="p-in-sp">I have a telegraphic dispatch from distinguished friends in Kentucky, who are all on tip-toe to know what our action has been. We will be seriously embarrassed if the secrecy is maintained any longer. It was intimated, yesterday, that the fact had gone forth by accident, and the people will be firing salvos to-day without any security that the statement is correct.</p>
            <div className="speaker" id="sp3923"><span className="persName">Mr. THOMAS S. FLOURNOY</span>, of Halifax—</div>
            <p className="p-in-sp">I would suggest that the disclosure be confined to the fact of the ordinance being passed, without giving the vote upon it.</p>
            <div className="speaker" id="sp3924"><span className="persName">Mr. JEREMIAH MORTON</span>, of Orange and Greene—</div>
            <p className="p-in-sp">I think it is important that only the mere fact of an ordinance of secession being passed should be proclaimed. The ordinance itself, in my opinion, should not be published now, inasmuch as we have not adopted a preamble to it.</p>
            <div className="speaker" id="sp3925"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">The preamble has been also adopted.</p>
            <div className="speaker" id="sp3926"><span className="persName">Mr. MORTON</span>—</div>
            <p className="p-in-sp">I was not aware of that; I was, doubtless, absent when the vote was taken upon it. I would, however, have it merely stated that an ordinance of secession has passed without publishing the ordinance.</p>
            <div className="speaker" id="sp3927"><span className="persName">Mr. MACFARLAND</span>—</div>
            <p className="p-in-sp">I would suggest to the gentleman from Orange [<span className="persName">Mr. MORTON</span>] , that the ordinance without the preamble be published.</p>
            <div className="speaker" id="sp3928"><span className="persName">Mr. MORTON</span>—</div>
            <p className="p-in-sp">I understand that is the proposition of the gentleman from Montgomery [<span className="persName">Mr. PRESTON</span>] .</p>
            <div className="speaker" id="sp3929"><span className="persName">Mr. MACFARLAND</span>—</div>
            <p className="p-in-sp">I am in favor of the publication of the ordinance. To announce, simply, that we assented to an Ordinance withdrawing Virginia from the Union, would, perhaps, lead to uncertainty and groundless speculation. It may be surmised that we had withdrawn in some qualified form. All that will be removed by the publication simply of the act of withdrawal.</p>
            <div className="speaker" id="sp3930"><span className="persName">Mr. MORTON</span>—</div>
            <p className="p-in-sp">
              I am more and more impressed with every moment's reflection, as to the propriety of publishing the fact merely that an <PageNumber num={206} /> ordinance of secession has been adopted without publishing the ordinance itself. This is not the time for us to consider the propriety of modifying that ordinance, the necessity for which seems to be conceded by
              many gentlemen here. The publication to the world of the fact that an ordinance has been adopted, will be all the satisfaction that our friends desire. This course will not tie the hands of the Convention at all in making such modifications as may be deemed expedient. In my view, the proper course would be to appoint a committee, and in making out that Committee I wish it understood
              that I do not desire to be on it, to draw up a proper preamble to the Ordinance, and to suggest any modification that it may deem necessary in the words of that Ordinance. I think that this will fully meet the views of the gentleman from Montgomery
              [<span className="persName">Mr. PRESTON</span>] , and the gentleman from Richmond [<span className="persName">Mr. RANDOLPH</span>] .</p>
            <div className="speaker" id="sp3931"><span className="persName">Mr. WAITMAN T. WILLEY</span>, of Monongalia—</div>
            <p className="p-in-sp">I concur entirely in the propriety of removing the injunction of secrecy, as contemplated by the resolution of the gentleman from Montgomery [<span className="persName">Mr. PRESTON</span>] . I do not concur in the suggestions of the gentleman from Orange [<span className="persName">Mr. MORTON</span>]
              . If the people are interested in any action of this body at this time, it is in what action they have taken upon the question of secession. They are to pass upon it finally, and it ought to be placed before them as speedily as possible; and while it is improper to withhold from them the fact that an Ordinance of Secession has been passed, upon which they will be required to pass their
              opinion at the polls, it would be equally improper that they should be deprived of the privilege of knowing the reason assigned in the preamble as the grounds upon which that Ordinance of Secession is to be justified. We cannot too rapidly send this back to the people. They are the arbiters who are to pass upon it. We cannot too rapidly place before the people the grounds upon which
              gentlemen, who voted for that proposition, justify their action. I am opposed, therefore, to the publication of the simple fact of the passage of an Ordinance of Secession, without the publication of the Ordinance itself, and without the preamble assigning the reasons upon which that Ordinance is predicated.
            </p>
            <p className="p-in-sp">
              Will we withhold from the people this matter? They are the parties interested in it. They constitute the tribunal who are to pass upon it. It is a matter of life and death with the people. They ought to have the whole matter spread before them for consideration and deliberation. I have had letters and telegraphic dispatches in regard to this matter from the North-West. My mouth has
              been closed. I <PageNumber num={207} /> have never answered the letters or dispatches, because of the obligation of secrecy resting upon me. Sir, the people ought to know the actual truth in regard to this ordinance; and the more rapidly they know it, the better it will be. I am not for concealing anything from the people. They have a right to know
              whether or not we passed an ordinance, and the grounds upon which that ordinance had been passed. I am not for this Convention sitting here and allowing the fact to go out to the people, without a knowledge of the reasons which were held to justify those who voted for the ordinance. I hope, therefore, that both the preamble and the ordinance will be permitted to be spread before the
              people. The fact itself and the reasons for it, ought to go out together, and it is high time to have them known.
            </p>
            <div className="speaker" id="sp3932"><span className="persName">Mr. WM. WHITE</span>, of Norfolk county—</div>
            <p className="p-in-sp">
              The people that I represent on this floor have already placed themselves in a revolutionary attitude. They have taken this matter into their own hands, they are now directly in opposition to the Federal Government, and I am satisfied that the publication of this ordinance will cause the Federal Government to concentrate troops in Norfolk and its vicinity, before it is possible for the
              Governor to place at that point the troops which are necessary. I understand that in all probability the Commonwealth will have three or four thousand troops there before three days from this time. I think it would be better to have the troops there to repel any invasion which the Government will bring to bear upon that quarter before any disclosure of our action here in regard to the
              ordinance is made. Thus believing, I am prepared to vote against the publicity.
            </p>
            <div className="speaker" id="sp3933"><span className="persName">Mr. WAITMAN T. WILLEY</span>—</div>
            <p className="p-in-sp">Will the gentleman allow me to interrupt him for a moment?</p>
            <div className="speaker" id="sp3934"><span className="persName">Mr. WHITE</span>—</div>
            <p className="p-in-sp">Certainly.</p>
            <div className="speaker" id="sp3935"><span className="persName">Mr. WILLEY</span>—</div>
            <p className="p-in-sp">The main reason of my rising was to mention a fact which escaped my mind when last up, and that was, that we ought to let it go out to the people, that the Federal Government and all may see that this matter is still to be ratified by the people before it takes effect. Very few, if any, apprehend the difficulties to which the gentleman from Norfolk county [<span className="persName">Mr. WHITE</span>] , alludes. The effect of the publication of the ordinance and preamble, in my opinion, would be to quiet the public mind.</p>
            <div className="speaker" id="sp3936"><span className="persName">Mr. WHITE</span>—</div>
            <p className="p-in-sp">
              All I have to say is, that we are now in revolution. We are actually at war with the General Government, and it is wise policy on our part to keep our proceedings secret as long as possible, in order to enable this Commonwealth to place herself in a state of <PageNumber num={208} /> proper defence. It is possible that three thousand troops will be in the
              neighborhood of Norfolk in two or three days. There are now in the Gosport Navy Yard two thousand barrels of powder, one thousand cannon, six or eight ships of war, all amounting in value to about ten millions of dollars. There are arms enough there to arm 15,000 infantry, and who will question, in view of these facts, the importance of occupying the Navy Yard immediately. Fort Monroe
              is in the possession of the Federal Government, and they can march any number of troops into Norfolk and Portsmouth and land them under the guns of their war vessels.
            </p>
            <p className="p-in-sp">I think, therefore, that the publication of the passage of this Ordinance would be premature, and would risk the safety of the constituents that I represent.</p>
            <div className="speaker" id="sp3937"><span className="persName">Mr. LEWIS E. HARVIE</span>, of Amelia—</div>
            <p className="p-in-sp">
              I was amongst those who were most anxious that the action of this Convention, so far as it related especially to the passage of the Ordinance of Secession, should be kept secret for a time at least. I saw that great good would result from it. I am perfectly certain now, however, that those who are enemies of the State of Virginia and of this movement, are acting upon the assumption
              that action has been taken by this Convention upon the question of secession, or will undoubtedly be taken. My pocket is full of telegraphic despatches from various points outside and inside of the State, asking for information as to whether any action has been or will be taken, and stating that their action will be predicated upon our course here. Our enemies are in possession of the
              fact, so far as certain presumption or inference goes, and they are acting upon this, while our friends are ignorant of the fact, and are therefore inactive, inasmuch as they presume that information would be conveyed to them of the fact of the passage of the Ordinance, to enable them to take prompt steps to meet the enemy. We should give them information upon this subject at the
              earliest possible moment, so that they may go on with their preparations, and not suffer the enemy to be in advance of them.
            </p>
            <p className="p-in-sp">
              I trust it will be the pleasure of the Convention to remove this injunction of secrecy, in order that we may have the full benefit of the action of Virginia. I trust, sir, that there will be no hesitation in removing this injunction, for I am satisfied that there is a feeling of disquietude growing up in the public mind in consequence of our tardiness in this matter, and the sooner our
              action is disclosed the better. I understand perfectly what I am about, when I say to this Convention that I think it is a matter of importance that you should <PageNumber num={209} /> publish this action, unless there is some serious objection to its being made public; and I see none at present. So far as the purpose of the gentleman from Norfolk county
              [<span className="persName">Mr. WHITE</span>] , is concerned, I am aware that every effort has been made to accomplish it, and I am satisfied that these efforts are likely to be attended with success.</p>
            <div className="speaker" id="sp3938"><span className="persName">Mr. WHITE</span>—</div>
            <p className="p-in-sp">I have no doubt that forces enough to capture the Navy yard will be sent down to-day or to-morrow; but the question is, will they be able to hold it?</p>
            <div className="speaker" id="sp3939"><span className="persName">Mr. HARVIE</span>—</div>
            <p className="p-in-sp">I am satisfied they will with the aid of reinforcements which will be promptly sent.</p>
            <div className="speaker" id="sp3940"><span className="persName">Mr. WM. G. BROWN</span>, of Preston—</div>
            <p className="p-in-sp">Mr. President, it is well known that I have been in a minority upon this floor; but I feel that it is a matter of importance now that the friends of secession everywhere, North and South, should know the position that we occupy.</p>
            <p className="p-in-sp">
              It is said, sir, that troops have been ordered to the vicinity of Norfolk. Our enemies do not know that they have the sanction of this Convention. It is not known abroad, but that they are acting without our sanction, and I think it is important that our friends everywhere shall know that these movements are not unauthorized, but that they have the full sanction and approval of this
              body.
            </p>
            <p className="p-in-sp">
              I am decidedly in favor of removing the obligation of secrecy from all our proceedings, that they may be known to our own people and throughout the length and breadth of the land. If we are to intimidate the North, of which I have no hope, let it be known that our whole available strength shall be employed against them. It is thus only that we can hope to effect that object, if,
              indeed, there is any ground of hope in that respect.
            </p>
            <div className="speaker" id="sp3941"><span className="persName">Mr. WM. BALLARD PRESTON</span>—</div>
            <p className="p-in-sp">I would ask leave of the Convention to offer the following resolution, in lieu of that which I have already submitted.</p>
            <p className="p-in-sp">Resolved, That the injunction of secrecy be removed so far alone as to permit a disclosure of the fact that an ordinance of Secession was passed by this body yesterday, to take effect as an act of that day, when the same is ratified by the people at a poll to be taken thereon on the 4th Thursday in May next.</p>
            <div className="speaker" id="sp3942"><span className="persName">Mr. SCOTT</span>, of Powhatan—</div>
            <p className="p-in-sp">
              I am opposed to that resolution. I see no good to result from it; on the contrary, I can see much harm that is likely to follow the removal of the injunction of secrecy. What good, I will ask, will result from communicating to the country that this body has passed an Ordinance of Secession? The argument which I used in favor of the Ordinance, submitted by the gentleman from
              <PageNumber num={210} /> Fauquier
              [<span className="persName">R. E. SCOTT</span>]
              as a substitute for the Ordinance of Secession, would apply to the resolution now under consideration. I stated in the course of my remarks upon the Ordinance, that one object I had in supporting it was delay, with a view to obtain arms from the North, in order to put the State in a proper condition of military organization. But, sir, what do we accomplish by publishing to the world
              that this Ordinance of Secession has passed? It will be, in effect, proclaiming to the Federal Government that we are now in a state of revolution, and in a condition to resist any attack that may be made upon us. They are stronger than we are, and better prepared than we are. If we make the fact known that we are in a state of revolution, they will be perfectly justified in throwing
              an army into the city of Richmond in forty-eight hours if they can; and, in my opinion, they will have an army in the city of Richmond before we can be in a condition to prevent it.
            </p>
            <div className="speaker" id="sp3943"><span className="persName">Mr. ROBERT L. MONTAGUE</span>, of Mathews and Middlesex—</div>
            <p className="p-in-sp">A gentleman of this city told me last night that he was so fully convinced of the fact that we passed an ordinance of secession, that he telegraphed to North Carolina to that effect.</p>
            <div className="speaker" id="sp3944"><span className="persName">Mr. SCOTT</span>, of Powhatan—</div>
            <p className="p-in-sp">
              That may be true. The President of the United States may believe such to be the case; but he would have no right to throw an army here or anywhere else without positive evidence of the fact of our having passed an ordinance of secession. But, publish to the world that we have seceded, and the President would have a right to throw an army here or anywhere else in the State he thought
              proper.
            </p>
            <p className="p-in-sp">
              But, sir, there is another reason why this publication should not be made now. The Governor has informed us that he has ordered a large quantity of arms from the North, which he expected to receive in a few days. There are many people at the North who believe that Virginia has not and will not secede, and they would be willing to send these arms; but, let them once become informed of
              the fact of our secession, and there is an end to our getting them. We are not now in a condition to go into a war. Let us, then, according to the advice of the Adjutant General, keep this movement as secret as possible, so that we may get all the arms we can and place ourselves in the best possible condition to meet the enemy.
            </p>
            <div className="speaker" id="sp3945"><span className="persName">Mr. KILBY</span>, of Nansemond—</div>
            <p className="p-in-sp">I call the previous question.</p>
            <p>The call was sustained. Ten minutes, however, was still allowed to each member under the rule, to show why the main question should not be put.</p>
            <PageNumber num={211} />
            <div className="speaker" id="sp3946"><span className="persName">Mr. JOHN J. JACKSON</span>, of Wood—</div>
            <p className="p-in-sp">I desire to say two or three words only. I made my will here a few days ago, and I desire to add a few words by way of a codicil. [Laughter.] This body, as I understand, has passed an ordinance placing us outside of the Union, and yet you deny me the poor privilege to tell my wife of the fact.</p>
            <p className="p-in-sp">
              In the name of common humanity, I protest against this action. It is known to every body in this city; and yet I am not permitted to say one word about it to my people who have telegraphed to me to ascertain whether or not an ordinance of secession was passed. I am not at liberty to tell them this ordinance has passed, while it appears from what has transpired here, that it is
              generally known throughout Richmond. I have been told of it by twenty persons to-day; and when I go home, as I shall this evening-and meet my people, what shall I tell them? Why my mouth will be hermetically sealed so far as relates to the passage of this ordinance; and I cannot gratify the anxious desire of my constituents to be informed upon what has transpired here.
            </p>
            <p className="p-in-sp">
              I trust it will be the pleasure of this body to put us all upon an equality. I leave upon the train this evening, and when I get home I want to unburthen myself to my people; to discharge my whole duty by telling them what you have done. I received a telegraphic despatch this morning making anxious enquiries relative to our action, and whether this ordinance had passed. In view of the
              peculiar circumstances in which I, in common with many other members on this floor am placed, I trust it will be the pleasure of the Convention to discharge members from this obligation. If these measures do not suit-although you passed them; although you compelled us to vote upon them-if they don't suit, keep them under advisement until you make them suit. Only let the record show
              what you have done; let it go forth to the country; let it go upon the tide of time; and when the historian comes to write the events of this day, let him have a fair opportunity to write what is done. And if the genius of liberty does not shed tears enough to blot it all out, I am a mistaken man.
            </p>
            <div className="speaker" id="sp3947"><span className="persName">Mr. SCOTT</span>, of Fauquier—</div>
            <p className="p-in-sp">
              I was about to say that for myself, I was aware of no reason why the fact of the adoption of the ordinance should not be made known. There may, however, be reasons why it should not-reasons of a nature connecting themselves with the action of the Governor of the Commonwealth; and it seems to me that we ought to be satisfied upon that subject by ascertaining from him whether any such
              reason exists. Some gentleman says that there is <PageNumber num={212} /> a contract for the purchase of arms in course of fulfillment in the North. It may be that removal of the obligation of secrecy would throw some obstacle in the way of the fulfillment of that contract. The Governor may give us some information upon the question, which would settle
              all doubts in regard to it.
            </p>
            <p className="p-in-sp">It seems to me, before we make the ordinance public, that we should communicate with the Governor.</p>
            <div className="speaker" id="sp3948"><span className="persName">Mr. WISE</span>—</div>
            <p className="p-in-sp">I spoke to the Governor a few minutes ago on the subject, and he knew of nothing which required the secrecy to be continued any longer.</p>
            <p className="p-in-sp">I informed him that a motion would be made to remove the secrecy, and I understood him distinctly to concur in the opinion that it ought to be removed. There was at first strong reason why it should be kept secret. That, however, no longer exists.</p>
            <div className="speaker" id="sp3949"><span className="persName">Mr. CHAPMAN J. STUART</span>, of Doddridge and Tyler—</div>
            <p className="p-in-sp">I rise to make an inquiry.</p>
            <p className="p-in-sp">
              I received a letter from my family this morning imperatively demanding my presence at home, and I desire to know whether on my return home, I shall be permitted to communicate to my constituents, who will flock around me on my return to ascertain the news, what has transpired here? I shall leave here to-night to visit my family, and while I am not intimately known to many members of
              this Convention, I will say to them, that I would lose my right arm before I would dishonor myself or the generous constituency who sent me here, by any unauthorized disclosure of what has been done here.
            </p>
            <p className="p-in-sp">
              But, I appeal to this Convention to relieve me from the embarrassment in which a continuance of the injunction of secrecy would involve me on my return home. When I reach there, men, women and children will rush to me, and eagerly inquire what has been done. But they must be disappointed in their efforts to obtain the information they will seek after unless the obligation of secrecy is
              removed; for in no event would I reveal a single incident of what has transpired here while the obligation of secrecy rests. I am willing to keep my tongue silent, but I must know from this Convention, before I leave here this evening, when you propose to relieve me from this obligation. My district extends over a vast extent of country, most of it rough and almost impassable. So
              difficult of access is it, that a large number of my people were not aware that the election for this Convention had taken place at all. They will, however, have a full opportunity of voting upon your ordinance of secession, and they are necessarily anxious to know the reasons by which you felt justified <PageNumber num={213} /> in passing this ordinance.
              It is on this account that I am anxious to be relieved from the injunction of secrecy; and I trust it will be the pleasure of the Convention to disembarrass me to the extent of allowing me to communicate to my people the fact of the passage of the ordinance and the reasons for its passage.
            </p>
            <div className="speaker" id="sp3950"><span className="persName">Mr. J. G. HOLLADAY</span>, of Norfolk county—</div>
            <p className="p-in-sp">I am constrained to differ with my colleague [<span className="persName">Mr. WHITE</span>] , in reference to the propriety of keeping secret the Ordinance of Secession. And, sir, I fully concur with the gentleman from Amelia [<span className="persName">Mr. HARVIE</span>]
              , with whom I find myself now co-operating, that there is an absolute necessity for the publication of the passage of this ordinance, because it is manifest that so long as the people of this Commonwealth remain in ignorance of the fact that we have passed an ordinance of secession, the military preparations will be but spasmodic; and my colleague may expect but limited aid to his
              section of Virginia, so long as it is unknown by individuals that their action receives the sanction of the authorities.
            </p>
            <p className="p-in-sp">
              I understand now, from gentlemen on the other side, who were formerly designated the Union party, but who are now co-operating with the other side, that, if we are to co-operate effectually together, there ought to be a full and fair understanding between us. For one, I believe I am groping my way in the dark. There are intimations on this floor of proposed actions of which I know
              nothing. We are told, on the one hand, that orders are to be issued in reference to the movement of troops; and, on the other hand, that amendments are to be proposed to the preamble and ordinance of secession. I think it is time that gentlemen should show their hand. We must co-operate together now, sir, if we desire success. When the preamble was before the Convention, it was
              intimated that it was not acceptable to a great many gentlemen, and that a good many alterations would have to be made. We are told to-day, for the first time, that the ordinance of secession is not acceptable to a great many of the gentlemen who opposed the ordinance, and that it is proposed to make amendments to it.
            </p>
            <div className="speaker" id="sp3951"><span className="persName">Mr. HARVIE</span>—</div>
            <p className="p-in-sp">Who made that statement.</p>
            <div className="speaker" id="sp3952"><span className="persName">Mr. HOLLADAY</span>—</div>
            <p className="p-in-sp">I understood Mr. Morton to say so.</p>
            <div className="speaker" id="sp3953"><span className="persName">Mr. MORTON</span>—</div>
            <p className="p-in-sp">I said that some modifications may be made. I said that I thought it might be judicious to make a modification in the ordinance as well as in the preamble; but I said, in addition, that the sentiment seemed to be so general against a disturbance of the ordinance, that I cordially accepted the amended proposition of the gentleman from Montgomery [<span className="persName">Mr. PRESTON</span>] .</p>
            <PageNumber num={214} />
            <div className="speaker" id="sp3954"><span className="persName">Mr. HOLLADAY</span>—</div>
            <p className="p-in-sp">I understand the gentleman then as saying that it is not contemplated to make any further amendment to the ordinance.</p>
            <div className="speaker" id="sp3955"><span className="persName">Mr. MORTON</span>—</div>
            <p className="p-in-sp">None, sir.</p>
            <div className="speaker" id="sp3956"><span className="persName">Mr. HOLLADAY</span>—</div>
            <p className="p-in-sp">I have no more remarks to make then.</p>
            <p>The main question was then ordered, and the question being put on the resolution offered by <span className="persName">Mr. PRESTON</span>, it was decided in the affirmative.</p>
            <p>The President announced the following committee under the resolution offered by <span className="persName">Mr. SCOTT</span>, of Powhatan: Messrs. William C. Scott, of Powhatan; Jubal A. Early, of Franklin; Peter C. Johnston, of Lee and Scott; George Blow, Jr., of Norfolk city; George W. Randolph, of Richmond city; John Echols, of Monroe, and Eppa Hunton, of Prince William.</p>
            <p><span className="persName">Mr. WM. M. AMBLER</span>, of Louisa, submitted to the Convention, a communication from the Governor, transmitting three acts passed by the last General Assembly, which have not yet been published.</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>AN ACT to Authorize the Issue of Treasury Notes, Passed March 14th, 1861.</p>
                <p>I. Be it enacted by the General Assembly, that the Governor, for the purpose of raising means for the defence of the State, is hereby authorized to direct the Auditor of Public Accounts to borrow for the</p>
                <p>They are as follows:</p>
                <p>Commonwealth of Virginia, from time to time, an amount not exceeding in the aggregate one million of dollars. And for that purpose the said Auditor shall issue to the lender, in Treasury Notes, the amount which he proposes to loan, in sums not less than twenty dollars.</p>
                <p>
                  2. The said Treasury Notes shall be prepared under the direction of the Governor and shall be signed by the Treasurer and countersigned by the Auditor of Public Accounts, but shall not be delivered by the said Auditor until the receipt of the Treasurer stating that the par value thereof has been paid into the Treasury. The said notes shall be made payable to order of the lender,
                  and be redeemable at the Treasury of the State one year after their respective dates. They shall bear interest at a rate not exceeding six per centum per annum from the date of their issue until redeemed or received by the authorized officers of the State in payment of dues to the Commonwealth. For the payment of the interest and redemption of the principal at the place and time
                  specified on the face of the note, the faith of the Commonwealth of Virginia is hereby pledged.
                </p>
                <PageNumber num={215} />
                <p>3. The said notes shall be transferable by the endorsement of the lender to bearer and thereafter by delivery.</p>
                <p>4. The said Treasury Notes shall be received in payment of taxes and debts actually due to the Commonwealth after the 30th day of September next. And in the settlement of said taxes or dues, the person making such payment shall be allowed the amount of the principal and the interest which may be due at the time of settlement, on the said Treasury Notes.</p>
                <p>
                  5. The officer receiving the Treasury notes in payment, shall endorse thereon that the same is paid, and the date of the payment; and the person tendering the same in payment of taxes or dues shall subscribe his name as a receipt in full therefor. And in the settlement of said officer with the Auditor of Public Accounts, the principle of said note and the interest thereon,
                  calculated to such date, shall be in full discharge of so much of the taxes or dues charged against said officer for collection. No Treasury note so endorsed and subscribed shall be afterwards transferable.
                </p>
                <p>
                  6. The Auditor of Public Accounts is hereby directed to cause to be redeemed all Treasury notes not so received by the collecting officers, principal and interest, at the time when the same is redeemable, and presented for payment : to be paid out of any money in the Treasury not otherwise appropriated. And if the said notes be not presented within twelve months after the same are
                  redeemable, the said Auditor shall advertise for the same to be brought in on a given day, and after such day the interest thereon shall cease.
                </p>
                <p>
                  7. Whenever from time to time one hundred thousand dollars of said notes shall be returned to the Auditor by said collecting officers, or shall be redeemed by him as herein provided, the same shall be cancelled by him and be delivered to the Treasurer, to be preserved in his office : and from time to time within two years from the passage of this act an amount equal to the sum so
                  cancelled may, by directions of the Governor, be again issued, subject to all the provisions hereinbefore prescribed.
                </p>
                <p>8. The Auditor of Public Accounts and the Treasurer shall each keep a full and accurate account of the number, date, denomination and amount of all the notes signed by them respectively, and of the names of the persons to whose order the said notes are severally made payable, and in like manner of all the said notes redeemed and cancelled.</p>
                <p>
                  9. For defraying the expenses of preparing, printing and engraving the said Treasury notes, a sum not exceeding two thousand dollars is <PageNumber num={216} /> hereby appropriated, to be paid by order of the Governor, out of any money in the Treasury not otherwise appropriated; and the plate or plates for such engraving shall be preserved by the
                  Treasurer, and shall be destroyed when the issue of said notes shall cease.
                </p>
                <p>10. All the provisions of the third, fourth and sixth sections of Chapter 193, of the Code, applicable to bank notes, shall be held to apply with the same effect to the Treasury notes directed to be issued by this act.</p>
                <p>11. It shall be lawful for the banks of this Commonwealth to discount or purchase any note or notes issued under this act.</p>
                <p>12. This act shall be in force from its passage.</p>
                <p>A true copy from the Rolls.</p>
                <p>Teste, P. F. HOWARD,</p>
                <p>Acting for Wm. F. Gordon, Jr., C. H. D., and Keeper of the Rolls of Va.</p>
                <p>AN ACT Appropriating one million of dollars for the defence of the Commonwealth-passed January 29, 1861.</p>
                <p>
                  Be it enacted by the General Assembly, That the Colonel of Ordnance be, and he is hereby, authorized and required, under the direction of the Governor, to procure such arms and equipments, and munitions of war, as may be necessary for the defence of the State. The said officer is also authorized to contract, under the direction of the Governor, for the manufacture, in this State,
                  of equipments and munitions of war, and may buy materials to be used in the manufacture of the same, and may contract with parties for altering and improving, in this State, cannon and small arms, or may, in his discretion, purchase machinery and materials for such purpose, if the same cannot be done by contract upon as reasonable terms: Provided, that not more than eight hundred
                  thousand dollars shall be expended for such purposes, which sum is hereby appropriated out of any money in the treasury not otherwise appropriated. The arms so to be purchased shall be so distributed that the militia of the State along and near the border on the non-slaveholding States, and along the border of the Chesapeake bay and the navigable streams tributary thereto, shall be
                  put on equal footing in the supply of arms with the militia of any other part of the Commonwealth.
                </p>
                <p>
                  Be it further enacted, That the Governor is hereby authorized and required to employ forthwith a competent engineer for the purpose of planning and constructing such coast, harbor and river defences <PageNumber num={217} /> as are immediately needed for the protection of the Commonwealth; provided, however, that said engineer shall first submit his
                  plans, with the cost of executing the same, to the Governor for his approval, and, if such plans be approved by him, the same shall be executed ; and provided, that for the defence of the Western and North-Western frontier and the Valley, the sum of fifty thousand dollars is hereby directed to be applied to the construction of three arsenals, one at or near the Baltimore and Ohio
                  Railroad, or the North-Western Virginia Railroad, one at some point upon the Great Kanawha river, and one in the Valley of Virginia at or near the town of Winchester, the precise locations of which shall be determined by the Governor, upon the report of the engineer hereby authorized to be employed; and he is further authorized and required to acquire for the State, by purchase or
                  condemnation, such sites as may be required for the defensive works aforesaid. If condemnation be necessary, it shall be conducted as in the proceedings by a company, county or town to take land without the owner's consent.
                </p>
                <p>The sum of two hundred thousand dollars is hereby appropriated for the purposes mentioned in the preceding section out of any money in the treasury not otherwise appropriated.</p>
                <p>This act shall be in force from its passage.</p>
                <p>A true copy from the rolls.</p>
                <p>Teste, P. F. HOWARD,</p>
                <p>Acting for Wm. F. Gordon, Jr., C. H. D., And Keeper of the Rolls.</p>
                <p>AN ACT to Create an Ordnance Department, passed January 25, 1861.</p>
                <p>
                  1. Be it enacted by the General Assembly, That an Ordnance Department be, and is hereby created, to consist of one Colonel of Ordnance, to be appointed by the Governor, by and with the advice and consent of the Senate, and subordinate officers, not exceeding six in number, to be appointed in like manner; the said subordinates to hold such rank as may be prescribed by the Governor,
                  with the consent of the Senate. The pay and allowances of all commissioned officers of the Ordnance Department shall be the same allowed to officers of the same rank and service in the United States Army, on the first day of January, eighteen hundred and fifty.
                </p>
                <p>2. The duties of the said Department shall be the duties performed by the Ordnance Quartermaster and Subsistence Department <PageNumber num={218} /> of the United States Army, and such other duties as may be prescribed by the Colonel of Ordnance, with the consent of the Governor.</p>
                <p>3. The officers of said Department shall be governed by the articles of war and regulations which are in force at this time for the government of the troops of the United States, so far as the same may be applicable.</p>
                <p>4. The duties heretofore assigned to the Commissioners of the Armory, shall be performed by the officers whose appointment is authorized by this act.</p>
                <p>5. This act shall be in force from its passage.</p>
                <p>A true copy from the Rolls.</p>
                <p>[Teste,] P. F. HOWARD,</p>
                <p>Acting for WM. F. GORDON, JR., C. H. D., And Keeper of the Rolls of Virginia.</p>
              </div>
            </div>
            <p>These acts were, on motion of <span className="persName">Mr. WICKHAM</span>, laid upon the table.</p>
            <div className="speaker" id="sp3957"><span className="persName">Mr. ADDISON HALL</span>, of Lancaster and Northumberland—</div>
            <p className="p-in-sp">I rise to a personal explanation.</p>
            <p className="p-in-sp">I was not here on yesterday when the ordinance of secession was passed, and I regret I was not, because I paired off with my friend from Goochland [<span className="persName">Mr. LEAKE</span>] , and thus prevented him from voting upon the question. Had I been here, I should have recorded my vote with him in favor of the ordinance, and not against it. I now desire to have the privilege of recording that vote, and I am determined that all my actions hereafter shall be directed to the maintenance of the honor of Virginia and of the whole South.</p>
            <p>Leave was unanimously given to <span className="persName">Mr. HALL</span> to record his vote in favor of the ordinance.</p>
            <div className="speaker" id="sp3958"><span className="persName">Mr. WALTER D. LEAKE</span>, of Goochland—</div>
            <p className="p-in-sp">I voted on yesterday for the ordinance of secession, being convinced that the gentleman from Lancaster would so vote if he was here. I did not vote for the substitute proposed by the gentleman from Fauquier [<span className="persName">Mr. SCOTT</span>] . I understood that he would not have prevented me from voting against it, and I now ask the privilege of having my vote recorded against that substitute.</p>
            <p>Leave was given.</p>
            <div className="speaker" id="sp3959"><span className="persName">Mr. ADDISON HALL</span>—</div>
            <p className="p-in-sp">I would ask permission to have my vote recorded on both propositions, against the substitute of the gentleman from Fauquier, and for the ordinance of secession.</p>
            <p>Leave was also granted to <span className="persName">Mr. HALL</span>.</p>
            <PageNumber num={219} />
            <div className="speaker" id="sp3960"><span className="persName">Mr. THOMAS S. FLOURNOY</span>, of Halifax—</div>
            <p className="p-in-sp">I beg leave to offer the following ordinance:</p>
            <p className="p-in-sp">
              "Be it ordained by this Convention, that the Governor of this State be, and he is hereby authorized and requested, should it become necessary for the defence of this State, to borrow, on the faith and in the name of this State, either by the issue of bonds or Treasury notes, a sum of money not exceeding one million of dollars f$1,000,0001 to be paid into the Treasury of the State and
              disbursed by the Governor under the ordinance of this Convention."
            </p>
            <div className="speaker" id="sp3961"><span className="persName">Mr. FLOURNOY</span>—</div>
            <p className="p-in-sp">
              I don't mean to detain the Convention by any extended remarks upon the proposition which I have submitted for their consideration. I trust it will be the pleasure of the Convention to adopt it at once, for the present state of things requires that our action shall be prompt and decisive. The temper of this administration in Washington, by every intelligence that we receive, is such
              that we have no time for delay. I don't know whether the information that has just reached us upon this floor is correct or not, but I understand that that distinguished officer, Winfield Scott, the Lieutenant General of the Army of the United States, resigned his commission last evening, and was immediately placed under arrest by the Government at Washington. This is indicative, if it
              be true, of a very vindictive feeling on the part of the administration at Washington, and indicates to this body that minutes are becoming hours, and that we must act, and act at once, and place every means within reach of the proper authorities of this Commonwealth, so that we may be enabled to put ourselves in a proper condition of defence. I hope that the ordinance will be adopted
              at once, and unanimously.
            </p>
            <p>The question was put upon its adoption, and carried unanimously.</p>
            <div className="speaker" id="sp3962"><span className="persName">Mr. R. Y. CONRAD</span>, of Frederick—</div>
            <p className="p-in-sp">I offer the ordinance which I hold in my hand as indicating the purpose which I mentioned in the few remarks that I made this morning:</p>
            <p className="p-in-sp">
              "Be it ordained by the people of Virginia, in Convention assembled, that, whereas, the President of the United States hath, by his public proclamation as well as by a communication to this body, assumed the authority and announced his purpose by military force to subject to his power and authority the States of the Southern Confederacy; and whereas, he hath, to this end, commenced
              raising an army of volunteers and militia; and whereas, he hath made a requisition for a part of the militia force of Virginia, and hath also, with the intent to intimidate and constrain the action of the people of Virginia, made preparation to seize, hold and strengthen the forts, arsenals and navy- <PageNumber num={220} /> yards within the limits of our
              State; and whereas, this Convention hath, in view of this action and avowed purpose of the President, declared the withdrawal of the people of Virginia from the Federal Government, to take effect, if ratified by the people at the polls on the day of May next; and whereas, this Convention doth believe and declare that said purpose and action of the President of the United States is
              wholly unconstitutional and is a palpable usurpation of power; and whereas, the necessity is great and imminent for the security of the State and people, the preservation of the peace and our rights, that immediate steps should be taken to put this Commonwealth in a condition of military defence, and that our people should be prepared to resist, if needful, in arms, all aggressions by,
              or under the pursuance of said intents of Federal authorities:
            </p>
            <p className="p-in-sp">"Therefore, we authorize and enjoin upon all the citizens of Virginia to organize, arm and prepare themselves for military service, and to obey all orders which they may receive from the regular authorities of this Commonwealth for such military service, from and after the day of the passage of this ordinance."</p>
            <div className="speaker" id="sp3963"><span className="persName">Mr. CONRAD</span>—</div>
            <p className="p-in-sp">I move that that ordinance be laid upon the table for the present.</p>
            <p className="p-in-sp">
              I beg leave to say what I have said already, and would appeal to gentlemen of more experience than myself to sustain me in the statement, whether it is not necessary and proper that we should cause all propositions made to this Convention to go before a committee, even if it were but for five or ten minutes. I don't think we should go any longer passing resolutions which one-half of
              the Convention do not hear, and of course cannot understand. It is impossible that a proposition can be fully understood by a single reading of it at the Clerk's desk.
            </p>
            <div className="speaker" id="sp3964"><span className="persName">Mr. MORTON</span>—</div>
            <p className="p-in-sp">I would call the attention of the gentleman to an error of fact in his ordinance.</p>
            <div className="speaker" id="sp3965"><span className="persName">Mr. CONRAD</span>—</div>
            <p className="p-in-sp">If the gentleman will allow me, I will state that it was drawn up very hastily at the desk just now. It will be competent for the gentleman to offer any amendment he may see fit when the ordinance comes up for consideration.</p>
            <p>The motion to lay on the table was agreed to.</p>
            <div className="speaker" id="sp3966"><span className="persName">Mr. JOHN B. BALDWIN</span>—</div>
            <p className="p-in-sp">I beg to offer the following resolution:</p>
            <p className="p-in-sp">
              Resolved, That the Military Committee inquire into the expediency of taking immediate steps to prevent unauthorized military movements within the State as tending to forestall and embarrass the determination <PageNumber num={221} /> of a general plan of military operations by precipitating inefficient collisions in improper localities.
            </p>
            <div className="speaker" id="sp3967"><span className="persName">Mr. BALDWIN</span>—</div>
            <p className="p-in-sp">
              I think it a very important thing that we should not be dragged into grave military errors by the unauthorized action of excited people in different parts of the State. I don't profess to have any experience or any great amount of information with regard to military affairs; but it has been stated upon this floor, that it has been contemplated by citizens of the cities of Norfolk and
              Portsmouth to lay hold of the Navy-yard and drive out the military authorities of the United States.
            </p>
            <p className="p-in-sp">Now, sir, I understand, also, that there is a movement made upon Harper's Ferry for the purpose of getting possession of the armory at that place. These are mere indications. I don't think they will progress in different localities, unless it is understood that our military preparations are to be but mere spasmodic affairs.</p>
            <p className="p-in-sp">
              Now, as I say, I am no soldier; but it seems to me that an attempt by the cities of Norfolk and Portsmouth to hold the Gosport Navy-yard would be a capital military mistake. I think that there is not a more accessible point on earth to military assault by a government possessed of a navy, than Norfolk and Portsmouth. That is my impression. And all the military resources that may be
              expended upon the defence of Norfolk and Portsmouth will only have the effect of bringing down upon the citizens of those two towns the horrors of a most disastrous military siege.
            </p>
            <div className="speaker" id="sp3968"><span className="persName">Mr. BLOW</span>, of Norfolk city—</div>
            <p className="p-in-sp">Do I understand the gentleman as conveying the idea that he would consider it good policy if the Navy-yard was to be occupied by a body of "Wide-Awake's," to allow them to hold it without making any attempt to drive them out?</p>
            <div className="speaker" id="sp3969"><span className="persName">Mr. BALDWIN</span>—</div>
            <p className="p-in-sp">Not at all. I mean that it would be well not to give the Federal Government the pretext to come there and make the attack by any premature or unauthorized move on the part of our citizens.</p>
            <p className="p-in-sp">
              Sir, I merely throw out these suggestions, not as matured opinions upon military questions of this sort, but to show that it certainly cannot be good policy to allow that neighborhood the selection of a plan of a military campaign. If it shall be determined by military men that Norfolk and Portsmouth are military points capable of being held, why, as a matter of course, it will be
              done. On the other hand, it may be best not to take any action that may precipitate us into a conflict-I mean unauthorized action. All I want to assert in regard to the matter is, that I do not see any propriety in allowing our <PageNumber num={222} /> military plans to be determined by a squad of men in the neighborhood of these towns.
            </p>
            <p className="p-in-sp">
              It may be, sir, that all my impressions in regard to Norfolk and Portsmouth may be mistaken; but still I think have demonstrated the impropriety of individuals in precipitating collisions in their neighborhood, to the prejudice and forestalling of the regular plan of campaign. This is but a mere resolution of enquiry before the Military Committee as to the propriety of taking this plan
              out of the hands of an unauthorized person.
            </p>
            <div className="speaker" id="sp3970"><span className="persName">Mr. J. G. HOLLADAY</span>, of Norfolk county—</div>
            <p className="p-in-sp">
              I do not exactly understand the gentleman. I understand he asks the reference of his resolution to the Military Committee; and I presume that the design is, that that committee shall determine upon this matter. Now, so far as the movements referred to are concerned, the gentleman falls into an error, because whilst we all believe that the general campaign ought to be under the control
              of competent men, yet in reference to this particular object, I think there can be but little difference of opinion between us.
            </p>
            <p className="p-in-sp">
              In the navy yard there are immense stores of munitions of war, provisions, ships of war already armed, and some to be armed. In reference to Harper's Ferry, it was understood that a large supply of arms was contained there; and it seems to me that a movement to seize them does not conflict at all with the idea of a general campaign referred to in the gentleman's resolution. It would be
              the most remarkable thing on earth, situated as we are, with arms located at different points within our borders, for us, under existing circumstances, to refuse and decline the opportunity of seizing arms presented to our hands, and to permit these arms to be removed beyond the limits of the Commonwealth of Virginia. While I concur with the gentleman that a general campaign ought to
              be under the direction of competent military officers, yet the propriety of the course intimated in reference to these things exists, and I cannot for one moment doubt the necessity for immediate action. We are unquestionably in revolution. We have made substantially a declaration of war, and we all know that within a few days the horrors of war will be upon us; and the question is,
              shall we refuse to avail ourselves of the opportunity now offered us to arm ourselves? I submit that, in reference to the movements in my own locality, I regard them as eminently proper, and fully justified by the circumstances which surround us.
            </p>
            <p className="p-in-sp">I will simply remark, in conclusion, that this being a resolution of <PageNumber num={223} /> inquiry, I see no impropriety in adopting it. I do not understand that the gentleman refers to these movements specially, further than they might conflict with his idea of a general campaign.</p>
            <div className="speaker" id="sp3971"><span className="persName">Mr. BALDWIN</span>—</div>
            <p className="p-in-sp">I have no idea that the movements are made from any other than patriotic motives. My object merely is to obviate, if possible, premature conflicts, which may embarrass a general plan of campaign.</p>
            <div className="speaker" id="sp3972"><span className="persName">Mr. KILBY</span>, of Nansemond—</div>
            <p className="p-in-sp">I think it is apparent we can do no business now.</p>
            <p className="p-in-sp">I move that we take a recess until half past 7 o'clock, this evening.</p>
            <p>The motion was subsequently withdrawn at the request of <span className="persName">Mr. RANDOLPH</span>, who promised to renew it.</p>
            <div className="speaker" id="sp3973"><span className="persName">Mr. GEO. W. RANDOLPH</span>, of Richmond—</div>
            <p className="p-in-sp">I had a conversation with the Governor, and he reminded me that the ordinance passed, authorizing him to call out the military force of the State, cannot be carried out, unless the injunction of secrecy is removed so far as it may be necessary to enable him to discharge his duties under that ordinance.</p>
            <p className="p-in-sp">I move that the pending resolution be laid upon the table for a moment, to enable me to offer a resolution to that effect.</p>
            <p>The motion was agreed to.</p>
            <div className="speaker" id="sp3974"><span className="persName">Mr. RANDOLPH</span>—</div>
            <p className="p-in-sp">I now offer the following resolution:</p>
            <p className="p-in-sp">Resolved, That the injunction of secrecy in reference to military matters is hereby, removed, so far as in the judgment of the Governor it may be necessary for the efficient discharge of his duties.</p>
            <p>The resolution was adopted.</p>
            <div className="speaker" id="sp3975"><span className="persName">Mr. RANDOLPH</span>—</div>
            <p className="p-in-sp">According to promise, I renew the motion for a recess.</p>
            <p><span className="persName">Mr. KILBY</span> again withdrew it at the request of <span className="persName">Mr. SCOTT</span>, of Fauquier.</p>
            <div className="speaker" id="sp3976"><span className="persName">Mr. SCOTT</span>, of Fauquier—</div>
            <p className="p-in-sp">I am very strongly impressed with the impropriety of the course of proceeding we are falling into here, of adopting resolutions and ordinances of grave import, hastily prepared by gentlemen at their desks, the nature of which can be but imperfectly known and understood by the Convention.</p>
            <p className="p-in-sp">I think, sir, that we ought to deliberate, and be careful to put forth nothing but perfected work. The gentleman from Frederick [<span className="persName">Mr. CONRAD</span>] , a few minutes ago, propounded something in the form of an ordinance, on a matter to which my own attention has been for sometime drawn. As I thought from the reading of it, it did not seem to me to come fully up to the necessity of the times.</p>
            <p className="p-in-sp">
              We have been said, and I think truly said, to be in a state of revolution. <PageNumber num={224} /> It has been said, and I think truly said, that the present Federal authorities are now in the exercise of usurped power. We have adopted an ordinance which cannot take legal effect until it receives its ratification by the people at the polls. In the
              meantime the people of this State are subjected to hazardous and unlawful proceedings. It is impossible to stand still now in this condition of public affairs; to wait as we are till the fourth Thursday in May next, or until the result of the vote upon this Ordinance shall be known. It seems to me that we have reached a condition of things which demands the passage of a Provisional
              Ordinance, suspending the authority of the Federal Government within the limits of this Commonwealth, until we shall be advised of the ratification of the Ordinance of Secession that we have passed; and with that view, and without detaining the Convention by a further discussion of it, I offer the following resolution:
            </p>
            <p className="p-in-sp">"Resolved, That a committee of five be appointed to enquire into the expediency of suspending the authority of the Federal Government within the limits of this Commonwealth, until the sense of the people of this State shall be taken upon the ratification of the Ordinance already adopted."</p>
            <div className="speaker" id="sp3977"><span className="persName">Mr. SCOTT</span>—</div>
            <p className="p-in-sp">The Convention will see that the resolution proposes nothing more than the appointment of a committee to enquire into the expediency of doing what is therein referred to.</p>
            <p>The resolution was adopted.</p>
            <div className="speaker" id="sp3978"><span className="persName">Mr. RANDOLPH</span>—</div>
            <p className="p-in-sp">I move to take up the resolution of the gentleman from Augusta [<span className="persName">Mr. BALDWIN</span>] , which was laid on the table a few minutes ago, upon my motion.</p>
            <div className="speaker" id="sp3979"><span className="persName">Mr. JUBAL A. EARLY</span>, of Franklin—</div>
            <p className="p-in-sp">I trust the gentleman will withdraw that motion to enable me to offer a short ordinance.</p>
            <p className="p-in-sp">Under the Ordinance passed last night the Governor is authorized to call into service a Major General, to take command of the forces of the State. We want now some general officer, and it is with a view to secure the services of one, that I offer this Ordinance:</p>
            <p className="p-in-sp">
              "Be it ordained, That the Governor of this Commonwealth be authorized and required to appoint a commission to call into service a Major General of the military forces of the State, to command in chief, in subordination to him, the troops which may be called into service, which Major General shall rank all other officers, whether of the militia or volunteers, without regard to date of
              commission."
            </p>
            <div className="speaker" id="sp3980"><span className="persName">Mr. WISE</span>—</div>
            <p className="p-in-sp">
              I will call the attention of the gentleman to the fact that the terms he employs there are capable of misconstruction, and <PageNumber num={225} /> do not convey what I am sure he meant to express. He uses the words "Major General of the military forces of the State." That would seem to confine the appointment to the Major Generals of militia. My object
              is to get some such man as Colonel Lee, of the United States Army.
            </p>
            <div className="speaker" id="sp3981"><span className="persName">Mr. EARLY</span>—</div>
            <p className="p-in-sp">I will modify the Ordinance in accordance with that idea, and strike out the words "of the military forces of the State."</p>
            <div className="speaker" id="sp3982"><span className="persName">Mr. SCOTT</span>, of Powhatan—</div>
            <p className="p-in-sp">
              I hope the gentleman will consent to have that Ordinance referred to the Military Committee. I have no reason at all to mistrust the intelligence and fidelity of the Governor of the Commonwealth, but I would like to know who is to fill this great office, before sanctioning the creation of it by my vote here. I trust the gentleman will consent to allow his Ordinance to go before the
              Committee on Military Affairs.
            </p>
            <div className="speaker" id="sp3983"><span className="persName">Mr. EARLY</span>—</div>
            <p className="p-in-sp">The object I had in view was to authorize the creation of this office, so that we may have the services of Col. Lee.</p>
            <p className="p-in-sp">I have no objection to its reference to the committee.</p>
            <p>The ordinance was accordingly referred.</p>
            <p>The President announced the following committee, authorized to be raised under the resolution of <span className="persName">Mr. ROBT. E. SCOTT</span>, which has just been adopted:</p>
            <p>Messrs. Robert E. Scott, of Fauquier; Jno. B. Baldwin, of Staunton; Wm. Ballard Preston, of Montgomery; Wm. H. Macfarland, of Richmond city; and James P. Holcombe, of Albemarle.</p>
            <p>On motion of Mr. Morton, the Convention took a recess until 4 o'clock P. M.</p>
            <div className="session" id="vsc1965.v4.2.3.1">
              <h3><span className="headingNumber">1.3.1. </span><span className="head">EVENING SESSION</span></h3>
              <p>The Convention re-assembled at 4 o'clock P. M., pursuant to adjournment, <span className="persName">Mr. SOUTHALL</span> in the Chair.</p>
              <div className="speaker" id="sp3984"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">I have received a communication from "the Convention of the People," now in session in Metropolitan Hall in this city, through its Secretary, S. Bassett French, transmitting resolutions of that body, which I beg leave to submit:</p>
              <div className="floatingText">
                <div className="floatingText_body">
                  <p>RICHMOND, April 18, 1861.</p>
                  <p>Hon. JOHN JANNEY, President of the State Convention:</p>
                  <p>Sir-With a heart too full for utterance, I content myself with enclosing to you authenticated resolves of the Convention of the People now assembled in this city, adopted unanimously, under the</p>
                  <PageNumber num={226} />
                  <p>deepest sense of their responsibility to the Almighty God and our beloved State.</p>
                  <p>Putting our whole trust in the God of our fathers,</p>
                  <p>I am, with high respect,</p>
                  <p>Your obedient servant,</p>
                  <p>S. BASSETT FRENCH.</p>
                  <p>In Convention of the people, held in the Metropolitan Hall, in the city of Richmond, on Thursday, 18th April, 1861,</p>
                  <p>On the motion of the Hon. Willoughby Newton, it was</p>
                  <p>Resolved, unanimously, That the thanks of this Convention are cordially tendered to the State Convention for the noble act of patriotic duty which they have just performed; and, forgetting all past dissensions, we will rally with united hearts and hands in defence of the honor, safety and independence of Virginia, and the Confederate States.</p>
                  <p>On the motion of Col. S. Bassett French,</p>
                  <p>Resolved, unanimously, That the members of this Convention do here, in the presence of Almighty God and of each other, pledge themselves and each other, their fortunes and sacred honors, in defence of their native soil.</p>
                  <p>Ordered, That the Secretary of this Convention be directed to deliver a copy of the foregoing resolutions to the President of the State Convention and to the Governor of this Commonwealth.</p>
                  <p>A copy-teste,</p>
                  <p>S. BASSETT FRENCH, Sec'y.</p>
                </div>
              </div>
              <div className="speaker" id="sp3985"><span className="persName">Mr. JUBAL A. EARLY</span>—</div>
              <p className="p-in-sp">Do I understand that to be a communication from a Convention of the People of Virginia?</p>
              <div className="speaker" id="sp3986"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">It is styled a "Convention of the People of Virginia" in the document which I have just submitted. The Chair knows nothing more about it than it can gather from these documents.</p>
              <div className="speaker" id="sp3987"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">I move that the communication be laid upon the table and printed.</p>
              <div className="speaker" id="sp3988"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">I hold in my hand the Baltimore "Sun" of this morning, in which I find the following reference to this Convention :</p>
              <div className="stage it">[<span className="persName">Mr. E</span>. here read an extract from a letter to the "Sun," from its Richmond correspondent, referring to the "People's Convention," and intimating its object to be the coercion or intimidation of the State Convention into the passage of an Ordinance of Secession.]</div>
              <PageNumber num={227} />
              <p className="p-in-sp">
                Mr. President, I have heard it intimated that another Convention was being held in the city of Richmond in secret during the last few days. I wish to know what is my position here-whether I stand here a free representative of the people, at liberty to represent the will of my constituents-whether the voice of the people is to be echoed through this Convention, or whether we shall act
                here subject to the will of another body, now in session in this city?
              </p>
              <p className="p-in-sp">
                Sir, when the Ordinance of Secession was passed against my most earnest wish and vote, I was disposed to throw no factious opposition in the way of the necessary steps to place this Commonwealth in a proper condition of defence. On the contrary, believing that revolution was inaugurated, I was willing to lend my assistance in organizing an efficient military force for the protection
                of the Commonwealth, and to defend our citizens against invasion, so as to enable them to pass in a peaceable way upon the Ordinance of Secession that has been adopted. But if there is another body here which is to take upon itself the supervision of our action, and assume the power, in case they should not approve of that action, to turn us out and substitute a Provisional
                Government in place of that now in existence, I do not feel at liberty to occupy my seat in silence. I have been pained to see another flag floating over this Capitol. By what authority it has been placed there, I do not know; but I cannot now express the painful emotions which I felt on seeing another flag floating over the Capitol of this proud State of Virginia. I am anxious to
                know by what authority this flag is there. Is it by authority of the Convention? Is it by authority of any of the officials of this Commonwealth? Are we already a conquered people? Will not the people of Virginia be allowed to pass upon the ordinance of secession that has been adopted? It does seem that it would be acting the part of wisdom and manifesting a proper appreciation of
                the people's rights, for us to suppress any such demonstration until this ordinance should have received the sanction of the people.
              </p>
              <p className="p-in-sp">
                I would call upon gentlemen to recollect, that it often happens that those who begin a revolution do not end it. What shall be the result of this, I will not of course undertake to predict; but I call upon gentlemen who now have charge of this movement here, to stop, if possible, any revolutionary measures that may be going on. I am willing to do all in my power to place this
                Commonwealth in a condition of defence by organizing an efficient military force, but I have no idea of submitting to intimidations from outside sources or to yield <PageNumber num={228} /> to any pressure such as would seem to be resorted to by other self constituted and irresponsible bodies.
              </p>
              <div className="speaker" id="sp3989"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair must remind the gentleman that this course of remark is strictly out of order.</p>
              <div className="speaker" id="sp3990"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">
                I will make this remark with a view to inquiry: what is the character of this people's Convention? I have seen no publication made in connection with it, and no call issued for its assembling. I have seen no account of the holding of meetings in any of the counties to send delegates to this Convention. If they have been held, they have escaped my notice. I will say, in conclusion, if
                I remain here longer I do not desire to receive messages, it may be from Jacobin clubs.
              </p>
              <div className="speaker" id="sp3991"><span className="persName">Mr. MIERS W. FISHER</span>, of Northampton—</div>
              <p className="p-in-sp">
                I desire to say only a few words in reply to the gentleman from Franklin. It seems to me that he is misjudging the character of the communication made to this body, and that there is nothing to indicate that the seed of Jacob met together in the city of Richmond for the purpose of overthrowing this body. On the contrary, the resolution which that Convention has adopted is one
                complimentary to the action of this body; and I suppose if a dozen gentlemen meet together in the city of Richmond, that they are at perfect liberty to make respectful communication to this body, saying they approve of its action, without subjecting themselves to any such charge as the gentleman insinuates.
              </p>
              <div className="speaker" id="sp3992"><span className="persName">Mr. JOHNSON</span>, of Richmond city—</div>
              <p className="p-in-sp">Will my friend from Northampton suspend for a moment to allow the resolution of this people's Convention to be read?</p>
              <div className="speaker" id="sp3993"><span className="persName">Mr. FISHER</span>—</div>
              <p className="p-in-sp">Certainly, sir.</p>
              <p>The Secretary then read the resolution.</p>
              <div className="speaker" id="sp3994"><span className="persName">Mr. FISHER</span>—</div>
              <p className="p-in-sp">I think, Mr. President, that there is nothing in that resolution that is at all disrespectful to this body. In my opinion, they but express sentiments which I hope are held by all the people of this Commonwealth, and that is, to sustain this body in the action which it has taken.</p>
              <p className="p-in-sp">
                If the gentleman from Franklin had reflected but for a few minutes, it seems to me that he would not have referred in the terms which he did to a respectful communication made, as we may reasonably suppose, by a responsible body; for such it is, judging by the names mentioned in the communication. We know the gentlemen whose names are associated with this Convention personally, and
                no one will doubt that they are gentlemen of the first responsibility in the Commonwealth, incapable, notwithstanding the statement of an irresponsible <PageNumber num={229} /> correspondent in the Baltimore "Sun" or "American" representing that body as being disposed to inaugurate a revolution if they failed to obtain what they desire-I say incapable
                of identifying themselves with any movement that may be incompatible with honor, and a strict regard for the interests of the State.
              </p>
              <p className="p-in-sp">
                Is this body to be moved from its sense of propriety by any communication that may be made by some anonymous scribbler from Richmond to the Baltimore "Sun," or any other paper? Do such communications justify the ascription to that body of purposes and motives such as the gentleman from Franklin referred to? Can they be said to give to it a character which is unbecoming an assembly of
                Virginia gentlemen? I should suppose not; and it does not become this Convention to be moved from its sense of propriety by any representations, respecting the character of public assemblies emanating from irresponsible newspaper correspondents.
              </p>
              <p className="p-in-sp">
                But, why not take the evidence before us, and judge from the nature and character of the resolutions on our table, what the character of that Convention is. Far from offering insult, or seeking to exert any improper influence over this body, they embody expressions of congratulations and sympathy which are evidently sincere, and pledge the Convention to maintain the action of this
                body to the utmost of their power. Can we, with any sort of propriety, treat such a communication with contempt? I care not what may have been said in the newspapers. I know nothing of the motives which may have dictated the reference in the paper cited by the gentleman from Franklin
                [<span className="persName">Mr. EARLY</span>] ; but so far as my information extends, independent of this communication which has been sent to the Baltimore "Sun," I never ascertained that the purpose of that Convention, which I was informed convened in this city on Tuesday last, was to inaugurate revolution in the State, or in any portion of the country.</p>
              <p className="p-in-sp">
                I understood, if I may speak of it, it was in a thousand mouths, that the object of that Convention was to concentrate, if possible, public opinion in the Commonwealth of Virginia in favor of the vindication of the rights of the State, and not with a view to intimidate a body which, I hope, was not capable of intimidation. Whilst I was in a minority, I never dreamed of bringing about
                a state of things which was to control the independent action of the representatives of the people for I hold that every member should have a full opportunity, apart from all outside influence, to exercise his representative privileges, he alone being responsible to his constituents for any action he may take here. I have ever been a law-loving and a
                <PageNumber num={230} /> law-abiding man; and I never will encourage the inauguration of a revolution which may result in a forfeiture of every right I hold dear. Since I left this Convention before the recess to day I learned more in reference to the materials which compose this people's Convention than I had previously known. I am informed that it is
                constituted of men of the highest respectability from different portions of the Commonwealth. I am satisfied, upon information which I received relative to its object, that it aims to accomplish what is dear to the heart of every member of this Convention, and is determined to sustain the action of this body and in no wise to trammel it.
              </p>
              <p className="p-in-sp">Under the circumstances, I would suggest that the communication be printed, and will venture to hope that the gentleman from Franklin [<span className="persName">Mr. EARLY</span>]
                , will make no objection to so reasonable, and I would say, so just and proper a motion. The gentlemen composing that Convention, like himself, however much they may have differed in times past, are now prepared to vindicate and sustain the action of this body; and therefore hope that it will be the pleasure of the gentleman not to cast reproach upon the members of that Convention by
                refusing the courtesy of printing their communication. They are, as I have said gentlemen of the highest respectability and are, like ourselves, members of this great Commonwealth animated by the same feelings as ourselves; pledged to stand by us, and determined that our destiny shall be theirs. They propose to stand by our common mother in the vindication of her rights and her
                honor; and it seems to me that a people animated by such feelings and entertaining purposes of this character, are worthy to be treated with the limited respect which the printing of this communication would imply.
              </p>
              <div className="speaker" id="sp3995"><span className="persName">Mr. LEWIS E. HARVIE</span>, of Amelia—</div>
              <p className="p-in-sp">I had hoped, after this Convention had taken the stand which it has taken, that even the gentleman from Franklin [<span className="persName">Mr. EARLY</span>]
                would have endeavored to unite the people of Virginia, and that he would not by any action of his attempt to divide them. I had hoped that the time had come when, with one common heart and hand, we would be prepared to strike a blow for this Commonwealth against all the power that could be brought against her, and that no gentleman would distrust or doubt those engaged in a common
                cause.
              </p>
              <p className="p-in-sp">
                Mr. President, I know the people that constitute that Convention. Many of the nearest and dearest friends I have got; many of the highest, most distinguished and intelligent citizens of Virginia are in that Convention. One of the prominent members of that Convention was selected by the people of Virginia, through her Legislature, a
                <PageNumber num={231} /> member of the "Peace Conference," held in Washington in the course of last winter for the purpose of settling the difficulties which led to our separation from the General Government. The presence of a gentleman of that standing in this Convention, and the fact of his taking in it the position of Vice President, should, in my
                opinion, shield it from the calumnies of the Baltimore "Sun's" correspondent and the suspicions of the gentleman from Franklin
                [<span className="persName">Mr. EARLY</span>] .</p>
              <p className="p-in-sp">That Convention came here, I presume, for the same purpose that I came here, to do what they could to maintain the rights and the interests of Virginia; and, so far as they were individually concerned, they determined, as I am informed, that the honor of Virginia should be maintained.</p>
              <p className="p-in-sp">
                I denounce here, sir, the charge of the correspondent of the "Baltimore Sun" as a wanton calumny. I denounce it as calumny with no more knowledge of the objects and purposes of that Convention than is implied in the characters of the men who compose it, and a general intimation received through channels which I regard as reliable. There is no foundation for the charge that that
                Convention assembled here for the purpose of controlling the action of this body. I believe it is false-utterly false. The members of that Convention assembled here as freemen having a common interest with us, in this hour of common peril, and to make common cause with us. They came to see what was best to be done in this controversy, and to be in place, if need be, to take part with
                the Commonwealth of Virginia. And, sir, they give positive evidence, by the character of the resolutions they have adopted that their purpose is what I represent it to be; because the very first opportunity they had to applaud your action, they have done so. They have come here, as I have reason to know, to sustain the rights and the interests of Virginia, and my friend from Franklin
                [<span className="persName">Mr. EARLY</span>]
                ought to give them the right hand of fellowship instead of assailing them. They came, sir, for a noble and a patriotic purpose; and the very moment the seal of secrecy was removed from my lips, I went into that Convention, having first applied for and obtained the privilege of entering, and announced what was done here. The announcement was received with the most enthusiastic
                applause, which conclusively betokened the high estimate that they placed upon the patriotism and spirit of this Convention ; and, now, when they come to express these feelings, through the medium of a formal communication, suspicion, doubt and distrust is attempted to be cast upon them, upon the unfounded charge of a correspondent of the Baltimore "Sun."
              </p>
              <PageNumber num={232} />
              <p className="p-in-sp">
                The gentleman from Franklin ought to have known that there were members in this body as true to the interests of Virginia as himself, that never would have allowed any body of men, however influential, to come and interfere with the duties they owe to Virginia. I will say to the gentleman that I would die at that door before I would permit any body of men in this Commonwealth to
                trench upon the rights of this body or in any manner interfere with its action.
              </p>
              <p className="p-in-sp">My blood would be the first spilled to maintain the honor and the representative sovereignty of this assembly.</p>
              <p className="p-in-sp">I have said, perhaps, too much upon this question; but I could say no less in view of the relations which I hold to many of the members composing that Convention.</p>
              <p className="p-in-sp">
                As to that flag that floats from the Capitol, I thank God that it does float there. I hope I will be pardoned for saying, that when I saw that flag floating from the Capitol as I was returning from this Convention, I went immediately to the highest functionary in this Government, and told him if it was disagreeable to him, I would go before the people and get them, if possible, to
                take it down; and he promptly replied that he thought it was no desecration of the sanctity of the State that that flag should be hoisted along side the flag representing the Sovereignty of Virginia, that is now floating over our heads. I tell the gentleman that if he thinks we are again going under the flag of the stars and stripes, he is very much mistaken. We are never going back
                into that political charnel house; but are, for the future, to live under the glorious emblem of a new and glorious Southern Confederacy.
              </p>
              <div className="speaker" id="sp3996"><span className="persName">Mr. JAMES W. SHEFFEY</span>, of Smyth—</div>
              <p className="p-in-sp">
                I regret exceedingly, at a time like this, when I had hoped that past differences and dissensions were buried and forgotten, upon entering this Hall, to find tumult and excitement about a resolution, presented from a Convention of citizens of this Commonwealth, of as responsible gentlemen as belong to it, complimenting us for our action yesterday, and pledging, in a patriotic spirit,
                their lives, their fortunes, and their sacred honor, to stand by Virginia and the South in this hour of their peril and their trial. I would have remained silent on this occasion, but for the connection of my name with the origin of this Convention that has thus complimented the action of this body, and thus pledges all that is dear to it for the maintenance of that action of ours.
              </p>
              <p className="p-in-sp">
                I was applied to by a distinguished friend in the city of Richmond, and informed that it was desirable that a Convention of respectable gentlemen, from all parts of the Commonwealth, should be convened <PageNumber num={233} /> here, without distinction of party, so far as parties in the past were concerned, for the purpose, and for the sole purpose of
                organizing a States' Right, Southern Rights party, with reference, and with reference alone, to the spring elections. I was assured, sir, that the object of this Convention was to promote Southern rights, and to protect, to preserve and to promote State rights alone. I was told, sir, that gentlemen from different parts of the Commonwealth united in that call for that purpose, and I
                assented to the use of my name for the call of a Convention for that purpose, and for that purpose alone.
              </p>
              <p className="p-in-sp">
                Sir, if there was the spirit or purpose of revolution in those who called it, or in those who were called, that spirit and that purpose was, and is wholly unknown to me. I for one am incapable of lending myself or my humble name for the promotion of such an object at such a time, when Virginia and Virginia's destinies were in the hands of this legally constituted body.
              </p>
              <p className="p-in-sp">
                By some accident, certainly not foreseen, it has happened that whilst this Convention of citizens assembled here for that purpose were in session, this body, under the pressure of events around us, over which we had no control, has thought proper to declare that our connection with the government of the United States no longer exists, and to place Virginia under the xgis of her own
                sovereignty, and under her own appropriate and suggestive flag; and, now when this act has gone forth to the country and to the world as the second declaration of independence, can it be that there is any one here who will not receive with gladness a response from the assembled citizens of the Commonwealth, applauding the patriotic purpose and the patriotic act of this Convention,
                and pledging themselves to stand by us and to stand by the State in this emergency whatever may be the peril, and whatever may be the sacrifice?
              </p>
              <p className="p-in-sp">I regret to hear, from the remarks of the gentleman who has just taken his seat [<span className="persName">Mr. EARLY</span>]
                , that one whose courage and patriotism I have been compelled to admire in the progress of the proceedings of this Convention, should have smelled treason in the tainted gale, when no treason existed; should have snuffed revolution in the breeze when no revolution was ever contemplated or designed. It may be true, according to that gentleman's views, that we are now in the midst of
                revolution; and it is true that that gentleman, in the true spirit, as I honestly believe, of a Virginia heart and a Virginia patriot, has sought to arrest the events which must, as he thought, precipitate us into revolution; of that I cannot and will not complain, for, I <PageNumber num={234} /> believe, sir, I know that his purpose and his spirit has
                been as patriotic as the purpose and spirit of any other man in this floor. I do not and will not impugn it; but I do expect, now that Virginia, through the action of this Convention, has taken her stand-I do expect and have always expected, that no man in this Commonwealth would stand by Virginia's honor, and Virginia's interest, now that she has taken that position, with more zeal
                and more firmness than the gentleman who has detected revolution in the action or the purposes of the assembling of that Convention.
              </p>
              <p className="p-in-sp">
                I do hope that the gentleman will be satisfied with the explanation and assurance I have given him, that that Convention assembled with no revolutionary purpose whatever, but for a single and proper purpose, and that he will not think for one moment of refusing to accept the compliment tendered by that Convention, but that he will respond to them as becomes us to respond to such
                sentiments as these resolutions embody. I expect him, with this explanation, to join me in assuring that Convention of our co-operation in the fixed purpose and the firm resolve which they have expressed and to which we should respond, that we will stand by Virginia in weal or in woe, in peace or in war, until she shall be released from oppression and tyranny, and until she shall be
                made free and independent.
              </p>
              <p className="p-in-sp">I have deemed it proper to make these remarks, certainly in no spirit of unkindness, but with a feeling of profound regret that the purpose of that Convention should be misapprehended or misunderstood, and a regret that any obstacle has been thrown in the way of the acceptance of these resolutions, or the ordering of them to be printed.</p>
              <div className="speaker" id="sp3997"><span className="persName">Mr. JAMES H. COX</span>, of Chesterfield—</div>
              <p className="p-in-sp">I call the previous question.</p>
              <p>The call was sustained.</p>
              <div className="speaker" id="sp3998"><span className="persName">Mr. JOHNSON</span>, of Richmond city—</div>
              <p className="p-in-sp">Will the gentleman allow me to ask a question? I understand the motion of my friend from Orange and Green is to lay on the table and print.</p>
              <div className="speaker" id="sp3999"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">That is the motion.</p>
              <div className="speaker" id="sp4000"><span className="persName">Mr. JOHNSON</span>—</div>
              <p className="p-in-sp">I would ask if the injunction is removed in reference to this matter? If not, how can the resolutions be printed?</p>
              <div className="speaker" id="sp4001"><span className="persName">Mr. JUBAL A. EARLY</span>, of Franklin—</div>
              <p className="p-in-sp">I have no disposition to impute to any gentleman anywhere any improper motives. I have not gone out of my way to make any charge or assertion; but the paper from which I read is a paper of strong secession proclivities, and the correspondent shows that he entertains the same sentiments that are displayed in the journal for which he writes.</p>
              <PageNumber num={235} />
              <p className="p-in-sp">
                I saw this morning in a paper of this city, the "Examiner"-a paper I have not read for sometime before, I merely got it by chance in this instance-I saw in that a suggestion some what to the same effect-which was, that this Convention-I mean the so-called People's Convention-should appoint a provisional Government. Here is a Convention assembled in the city of Richmond, while there
                is no account of any proceedings of meetings held in any of the counties for the purpose of appointing delegates. The presence of these delegates here must be due to some extraordinary mode not within the usual routine of primary meetings. In view of this fact, I must say, that there was some grounds for the suspicions intimated by the "Sun's" correspondent. I accept willingly the
                assurances of the gentleman from Amelia
                [<span className="persName">Mr. HARVIE</span>] and the gentleman from Smyth [<span className="persName">Mr. SHEFFEY</span>] and hoping that the Convention will proceed to adopt all measures necessary for the protection of the Commonwealth, I withdraw any further objection to the resolutions.</p>
              <p>The main question was then ordered to be put, and the vote being taken on the motion to lay on the table and print, was decided in the affirmative.</p>
              <div className="speaker" id="sp4002"><span className="persName">Mr. JOHN B. BALDWIN</span>—</div>
              <p className="p-in-sp">I offered a resolution this morning which at the instance of the gentleman from Richmond [<span className="persName">Mr. RANDOLPH</span>] , was laid on the table to enable him to make a motion to remove the obligation of secrecy from a certain ordinance passed on yesterday; I ask that this may now be taken up.</p>
              <div className="speaker" id="sp4003"><span className="persName">Mr. R. Y. CONRAD</span> of Frederick—</div>
              <p className="p-in-sp">May I ask the gentleman to withdraw his motion for a moment to enable me to take up an ordinance which I wish to have referred.</p>
              <div className="speaker" id="sp4004"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">I withdraw it.</p>
              <div className="speaker" id="sp4005"><span className="persName">Mr. CONRAD</span>—</div>
              <p className="p-in-sp">I move to take up an ordinance submitted by me this morning, with a view to refer it to the special committee appointed at the instance of the gentleman from Fauquier [<span className="persName">Mr. SCOTT</span>] .</p>
              <p>The motion was agreed to, and the ordinance being taken up, it was, on motion of <span className="persName">Mr. CONRAD</span>, referred to the special committee which he had indicated.</p>
              <div className="stage it">[The ordinance appears in the proceedings of the morning session.]</div>
              <div className="speaker" id="sp4006"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">I now move to take up my resolution.</p>
              <p>The motion was agreed to.</p>
              <p>The resolution reads :</p>
              <p>"Resolved, That the Military Committee enquire into the expediency of taking immediate steps to prevent unauthorized military movements within the State, as tending to forestall and embarrass the determination <PageNumber num={236} /> of a general plan of military operations by precipitating inefficient collisions in improper localities."</p>
              <div className="speaker" id="sp4007"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">This is a mere resolution of inquiry addressed to the Military Committee.</p>
              <p className="p-in-sp">I may be authorized to say, that I have been exceedingly grieved at hearing the remarks made by the gentleman from Amelia [<span className="persName">Mr. HARVIE</span>] and the gentleman from Smyth [<span className="persName">Mr. SHEFFEY</span>]
                in regard to the Convention which has been in session in this city for several days. I. know nothing about it, but I know that there have been many conjectures in regard to it, and the spirit which brought that body together. I know nothing about it further than that such prevailed among many people in this city, and even among members of this Convention. I am happy to see that there
                is no danger of any collision between any portion of the citizens of the State and the authorities of the State. I do hope that we will conduct the affairs upon the cardinal idea that although we have thrown off our connection with the General Government, we are not engaged in any revolution against our State Government, but that Virginia shall occupy her position of independence as
                a law abiding, Constitution-keeping people. I hope that will be the spirit that will actuate all our people everywhere in the State, and that it will be kept constantly in view in our own deliberations in this body. I hope that it will be the policy adopted by this Convention so to conduct all their affairs as to avoid, as far as possible, all imputations upon the conservatism and
                law and order disposition of the people of Virginia. It has been a matter of pride with the people of the South, even those who do not concur in the movement taken by the Confederate States in throwing off their connection with the General Government, that in all their movements they have maintained their usual order, and have kept intact their own Constitutional Governments. I hope
                we will be able to sustain that character, and to show to the world that revolution in governments does not in any sense disturb social order.
              </p>
              <div className="speaker" id="sp4008"><span className="persName">Mr. LEWIS E. HARVIE</span>, of Amelia—</div>
              <p className="p-in-sp">
                As I stated before, I repeat again, that, so far as any revolutionary purpose was designed by that Convention, if they had any such purpose, it has been certainly kept from my knowledge; and it is very extraordinary, if it had such a purpose, that it should be composed of the materials that I know it was composed of. I know many of the gentlemen who composed that Convention
                intimately, and more conservative, cool and guarded men in their actions do not exist in this or any other country. The wealth, intelligence, patriotism, wisdom and conservatism of the State <PageNumber num={237} /> were, in no small degree, represented in that Convention. The members composing that body have a greater interest at stake than any other
                body of men that I have ever seen convened in this Commonwealth, and it is, therefore, idle to come to the conclusion that they came here for the purpose of overthrowing this Government and establishing in its stead a Provisional Government. I do not well comprehend the gentleman's allusion to the independent position which Virginia is to occupy. I give him warning that, so far as it
                is in my power, there shall be as close an interest as I can establish by my action between Virginia and the other slaveholding States-between the Border Slave States and the Cotton States.
              </p>
              <div className="speaker" id="sp4009"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">I assure the gentleman that he misunderstood me, if he supposed that I intended to vindicate any policy in regard to Virginia's status, when I said that she would, in her independent position, maintain social order.</p>
              <div className="speaker" id="sp4010"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">I am glad to hear that.</p>
              <div className="speaker" id="sp4011"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">I do not know how else I would designate Virginia. I take occasion to say to the gentleman that, believing as I do that an independent position on the part of Virginia is not a desirable position, I will join him or anybody else that will take us into the closest possible embrace consistent with our rights, with the best Government that offers.</p>
              <p><span className="persName">Mr. BALDWIN</span>'S resolution was then adopted.</p>
              <div className="speaker" id="sp4012"><span className="persName">Mr. JOHN T. THORNTON</span>, of Prince Edward—</div>
              <p className="p-in-sp">I beg leave to offer the following resolution:</p>
              <p className="p-in-sp">
                "Resolved, That the Governor of this Commonwealth be requested to communicate immediately to the President of the Confederate States the fact that this Convention, on yesterday, adopted an Ordinance resuming the powers delegated by Virginia to the Federal Government, and to express to the said President the earnest desire of Virginia to enter into an alliance offensive and defensive
                with the said Confederate States."
              </p>
              <div className="speaker" id="sp4013"><span className="persName">Mr. WM. G. BROWN</span>—</div>
              <p className="p-in-sp">I would enquire of my friend if he desires to enter into that alliance before the vote is taken on that ordinance?</p>
              <div className="speaker" id="sp4014"><span className="persName">Mr. THORNTON</span>—</div>
              <p className="p-in-sp">Yes, sir, I desire it to be formed at once. I think if ever there was a time for an alliance with any powers that can assist us, now is the time.</p>
              <div className="speaker" id="sp4015"><span className="persName">Mr. BROWN</span>—</div>
              <p className="p-in-sp">I am not prepared to vote for that resolution proposing to make a treaty with any foreign power. I suppose, however, as a majority of this Convention thinks it necessary to do so, it is vain for me to make any opposition.</p>
              <PageNumber num={238} />
              <div className="speaker" id="sp4016"><span className="persName">Mr. SAMUEL MCDOWELL MOORE</span>, of Rockbridge—</div>
              <p className="p-in-sp">I would suggest to the gentleman who offered that resolution to include the border slave States also. I would be glad to form such a connection with all of them.</p>
              <div className="speaker" id="sp4017"><span className="persName">Mr. THORNTON</span>—</div>
              <p className="p-in-sp">
                I would willingly support a resolution to send commissioners to the proper authorities of the border States to ask their co-operation. My object in providing for the Governor's transmitting the intelligence immediately is, that it may be sent by telegraph, as our messenger may be some time in getting to Montgomery, and then he was instructed to communicate it in confidence.
              </p>
              <p>The Resolution was adopted.</p>
              <div className="speaker" id="sp4018"><span className="persName">Mr. JAMES H. COX</span>, of Chesterfield—</div>
              <p className="p-in-sp">I beg leave to offer the following resolution:</p>
              <p className="p-in-sp">Resolved, That the Committee on Military Affairs be instructed to enquire into the expediency of increasing the number of the "Public Guard."</p>
              <p>The resolution was adopted.</p>
              <div className="speaker" id="sp4019"><span className="persName">Mr. PRICE</span>, of Greenbrier—</div>
              <p className="p-in-sp">From the indications all around me, I feel pretty certain, unless some extraordinary emergency arises which will demand the presence of members here, that very few days will find this Convention without a quorum. I desire very much myself to return home in a very few days, and it would be very agreeable to me to be afforded an opportunity of doing so.</p>
              <p className="p-in-sp">
                Now, if the leading members of the Convention, who know what business is to be done, will indicate in what time it could be done, it will be a great convenience to me and others. I do not desire to go and leave the Convention without a quorum. Cannot the Convention fix upon some time to adjourn over until some particular day? I think it is intended that we shall have a subsequent
                meeting. Unless this course is pursued, I think you will find yourselves, in a very few days, without a quorum. It is true you may drag along from day to day, but you will not have enough to decide any important question, and thus the Convention will be left in a very embarrassing position. Several gentlemen are going away to-night, and I am informed that many others will follow in a
                day or two.
              </p>
              <p className="p-in-sp">I desire that those who have charge of the business should fix the day of adjournment.</p>
              <div className="speaker" id="sp4020"><span className="persName">Mr. FRANKLIN P. TURNER</span>, of Jackson—</div>
              <p className="p-in-sp">
                I believe I am as far from the Capital as any member in this Convention. I cannot conceive that any member of this body can be more interested in being at home than myself. If there is any necessity for gentlemen's returning home <PageNumber num={239} /> on account of their private business, or on account of the peculiar emergency which is now upon us,
                I think it is much more important that I and others from my section should be at our homes, inasmuch as we are located in that portion of the State which is said to be the most exposed portion of Virginia-right upon the Ohio. But, while I entertain a full conviction of the necessity of my presence at home, both for my own private interests, and the interests, in some degree, of those
                whom I have the honor to represent, I cannot conceive that, in the present position of Virginia, any gentleman on this floor would be justified in withdrawing from this body as long as there is a necessity for his being here. Like the gentleman from Greenbrier
                [<span className="persName">Mr. PRICE</span>] , I desire to bring the session to a close as early as possible; but I do not think I would be justified in absenting myself until the State is put in a proper condition of defence. If a day is to be fixed, I will agree to the earliest day possible.</p>
              <div className="speaker" id="sp4021"><span className="persName">Mr. HALL</span>, of Wetzel—</div>
              <p className="p-in-sp">
                I suppose there is not a man in this body who feels a deeper interest in the present emergency than I do, or who stands in more imminent danger, or is more immediately amenable to the disastrous consequence of a war than I am. I live immediately on the banks of the Ohio river, where what little property I have is located. I have a wife and five children there, who must necessarily
                remain at the mercy of the invader, if he should come. My county is bounded on one side by the Ohio river and on the other by Pennsylvania, so that I am situated between the lines of the enemy. I have not been at home since the assembling of this Convention, but yet, sir, I am willing, so far as I am concerned, in this hour of trial and peril, to stand at my post here and to do
                everything or anything that may be necessary to vindicate the decision we have arrived at, and to maintain the position which we have assigned to Virginia.
              </p>
              <p className="p-in-sp">
                It is important now, if it ever was important, that there should be an efficient military organization; and it becomes us, as the agents or representatives of the people of the Commonwealth, to watch the progress of events as they follow one upon the other, and prepare for any emergency. So rapid are events transpiring, that no man can tell what an hour may bring forth-what the
                exigencies of every passing hour may demand.
              </p>
              <p className="p-in-sp">In view of this condition of uncertainty, I am opposed to indicating any particular day for adjournment, or even of determining what shall be done within the next two days.</p>
              <PageNumber num={240} />
              <p className="p-in-sp">
                We have assumed, voluntarily, a position of independence. Our action in that respect has been published to the world to-day; and we now occupy a position in which it makes it incumbent upon us to provide all the necessary means to give effect to that action. The people will expect us to consummate the good work which we have begun by furnishing means to meet every emergency
                consequent upon the passage of the Ordinance of Secession. Every man who now abandons his post will be held justly responsible by the people. Their lives, their property and their all are now at stake, and they look to us for the means of defence. If we fail to meet the emergency, our responsibility, indeed, will be great, and the disasters consequent upon our derelictions must be
                appalling.
              </p>
              <div className="speaker" id="sp4022"><span className="persName">Mr. JAMES B. MALLORY</span>, of Brunswick—</div>
              <p className="p-in-sp">I rise to a question of order. There is no proposition before the house, and yet debate is permitted to go on.</p>
              <div className="speaker" id="sp4023"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The gentleman is perfectly right in his point of order. There is nothing before the house, and the gentleman is, therefore, out of order.</p>
              <div className="speaker" id="sp4024"><span className="persName">Mr. PRICE</span>, of Greenbrier—</div>
              <p className="p-in-sp">
                If there is nothing before the Convention, I will submit a resolution. Before sending it to the Chair, I desire to make this remark. The gentleman who responded to my inquiry did not seem to comprehend the nature of that inquiry. I merely desired to warn the Convention of the danger they were in of being left without a quorum and to guard against it. I now desire gentlemen who have
                the lead here to indicate at what time they would probably get through here. I will ask my friend from Montgomery
                [<span className="persName">Mr. PRESTON</span>] to give some information as to the probable amount of work to be transacted and the period necessary for its accomplishment.</p>
              <p className="p-in-sp">I beg leave to offer the following resolution :</p>
              <p className="p-in-sp">"Resolved, That when this Convention adjourns on Saturday next, it will adjourn to meet on the day of next, subject to be convened in the meantime by the proclamation of the Governor."</p>
              <div className="speaker" id="sp4025"><span className="persName">Mr. GEORGE W. RANDOLPH</span>—</div>
              <p className="p-in-sp">The gentleman seems to expect a response to his inquiry.</p>
              <p className="p-in-sp">I beg leave to say that the first thing to be done now, as I understand, is to settle this tax question. For one, I am prepared to say that, after what has been done in this house by Western members, I am prepared to face the consequences and give them a satisfactory adjustment of that question.</p>
              <PageNumber num={241} />
              <p className="p-in-sp">
                In the next place, I would inquire of the Governor whether he desires an Executive Council; and if he was willing that the responsibility of his position should be divided, I would appoint an Advisory Council. I would ask him whether he desired any further action on the part of the Convention to assist him in maintaining the integrity of the State. If he does, that action should, of
                course, be taken.
              </p>
              <p className="p-in-sp">I would then appoint a committee to prepare an address to the people, setting forth the causes of our action, and let the Committee prepare the address in the interim between the time of our adjournment now and our next meeting.</p>
              <div className="speaker" id="sp4026"><span className="persName">Mr. FRANKLIN P. TURNER</span>, of Jackson—</div>
              <p className="p-in-sp">I desire the attention of the Convention to a suggestion I propose to make. I do not desire to press the question of taxation unnecessarily. I am obliged to the gentleman from the city of Richmond [<span className="persName">Mr. RANDOLPH</span>]
                , for his kind and generous suggestion on this matter; and for my part, in view of the liberal spirit which that gentleman has manifested, and which, I am satisfied, is participated in by other gentlemen from the East, I have no wish to say a word upon that subject, or to embarrass the house in the consideration of their important State question, by introducing it at this time.
              </p>
              <p className="p-in-sp">In order to afford an opportunity for a response from the commissioners to be appointed to confer with the border slave States, and also to give fair time for discussion, I move to amend the resolution of the gentleman from Greenbrier [<span className="persName">Mr. PRICE</span>]
                , by inserting "that upon this Convention" the words, "on Saturday next it will adjourn to meet again on the day of," &amp;c.<span className="note" id="Note20"
                ><span className="noteLabel">1</span>Mr. Turner's amendment is hopelessly garbled. According to the Journal, <span className="persName">Mr. TURNER</span>, of Jackson, moved to amend the resolution, by striking out the word "next" after "Saturday," and inserting, in lieu thereof, "the 27th instant."</span
                >
              </p>
              <div className="speaker" id="sp4027"><span className="persName">Mr. MARMADUKE JOHNSON</span>, of Richmond—</div>
              <p className="p-in-sp">
                I respectfully submit to the Convention how we can tell now when it may be proper for us to adjourn. I can very well understand how gentlemen who have been long separated from their homes and families, should be anxious to return to them; but it seems to me to be entirely precipitate and needlessly impatient for us to undertake now in this important crisis to say when we will
                adjourn.
              </p>
              <p className="p-in-sp">
                Now, sir, we have just passed an ordinance which separated us from the government to which we belonged; we are just in the midst of preparations; we don't know what conference the Governor may desire from us-what assistance or co-operation he may need from us; and I therefore respectfully submit to the Convention that we may better determine a day or two hence, when we can consummate
                our deliberations than we can now.
              </p>
              <PageNumber num={242} />
              <p className="p-in-sp">Looking upon the resolution in that view, I move to lay it upon the table.</p>
              <div className="speaker" id="sp4028"><span className="persName">Mr. PRICE</span>—</div>
              <p className="p-in-sp">If my friend will withdraw that motion, I will amend the resolution so as, if possible, to make it satisfactory to the House.</p>
              <div className="speaker" id="sp4029"><span className="persName">Mr. JOHNSON</span>—</div>
              <p className="p-in-sp">We can have the sense of the Convention now upon the resolution. If the motion to lay on the table is rejected, the gentleman will have full opportunity to amend his resolution.</p>
              <p>The question was then put on the motion to lay on the table, and was decided in the affirmative-yeas 78, noes 45.</p>
              <div className="speaker" id="sp4030"><span className="persName">Mr. WM. BALLARD PRESTON</span>—</div>
              <p className="p-in-sp">I have but a word or two to say.</p>
              <p className="p-in-sp">This morning, with a view of covering this whole question, the gentleman from Fauquier [<span className="persName">Mr. SCOTT</span>] , made a suggestion, and had a committee raised for the purpose of inquiring what would be necessary to be done during the interim between this period, and that upon which the people shall vote on the ordinance of secession.</p>
              <p className="p-in-sp">
                I presume the whole question of a provisional government will come under the supervision of that committee. A large majority of us, I believe, have agreed that it would be very indiscreet for us to leave our posts at this important crisis. Now, if the committee will report so as to inspire confidence to trade and business matters, I shall have no objection to adjourn as soon after
                that report is made as the Convention may choose. The resolution of the gentleman from Greenbrier
                [<span className="persName">Mr. PRICE</span>]
                was offered, as I understand, with a view to fix a day. I saw the Governor since dinner, and had a conversation with him. I feel, from what I have ascertained, that it would be improper to leave the Governor, under the circumstances in which he is now placed, without additional aid to lighten the burdens which this emergency will throw upon him. In view of the heavy duties which must
                devolve upon him, it is essential that he should have a council around him who would share the duties and responsibilities of his position. We expect to receive aid in a short time from a quarter foreign to us now but soon I hope to be in union with us. When that aid arrives, which it will in a day or two, we will be able to determine with more exactness than we now can, what plan of
                military organization it will be best for us to adopt in the outset.
              </p>
              <p className="p-in-sp">
                I would suppose that a council of safety-a council to aid the Governor, clothed with like power as the Governor, for the exigency, between this and the day on which the vote is taken, would be a very proper associate for the Governor in this emergency. But we have not had time so far to provide the committee. It is better, perhaps, we would have a day or two more for reflection upon
                this subject. And <PageNumber num={243} /> I will observe in this connection, that this is not a time for precipitate action. Let us take time to know the ground on which we stand, and on which our constituents will stand between this period and the period at which they will cast their votes upon the Ordinance of Secession. The committee should have due
                time for deliberation, and a full opportunity to determine and recommend to us whatever they think is necessary. I agree with the suggestion of the gentleman from Richmond
                [<span className="persName">Mr. RANDOLPH</span>]
                , to call upon the Governor and ascertain from him what may be necessary to enable him to meet the present emergency. I trust that gentlemen will not abandon us in this time of peril, so that it may be said, we had left the people at the mercy of the enemy, after we had taken the war tender made to us by Lincoln. I have no apprehension that gentlemen will abandon their posts in view
                of the serious consequences which such an act would involve to our people. We are compelled to stand up and maintain ourselves with all the means that God and nature has put in our power. What means may be necessary to do that, time and events can alone determine. At this time, it is impossible to designate a day when we might adjourn; and, for my part, I am utterly unwilling to
                leave here until all that is necessary to be done to meet pressing emergencies shall be done.
              </p>
              <div className="speaker" id="sp4031"><span className="persName">Mr. BEN J. WILSON</span>, of Harrison—</div>
              <p className="p-in-sp">
                There is a subject, Mr. President, to which I desire very much to call the attention of the Convention. I do not propose for a moment to interfere with the consideration of our national difficulties. The matter to which I refer is of considerable importance, relating as it does to the condition of our State bonds and to the status of Virginia in a financial point of view.
              </p>
              <p className="p-in-sp">Virginia has now assumed an independent position, which will devolve upon her the necessity of providing means to meet the large expenditures incident to this charge in her relation to the other States of the Union. It is very important for her to look to her credit and see by what means she can best obtain the money necessary to meet the emergency upon her.</p>
              <p className="p-in-sp">
                On the 27th day of February last, I had the honor to offer a resolution looking to that subject, and that resolution now lies upon your table. It does not propose to raise a committee; it merely requests the Auditor to give the information called for in that resolution. I think the information referred to, is such as we ought to have, especially, in this hour of difficulty. I ask
                that that resolution be taken up.
              </p>
              <PageNumber num={244} />
              <p>The motion was agreed to.</p>
              <p>The resolution was then taken up and read as follows:</p>
              <p>
                "Resolved, That the Auditor of Public Accounts be requested to report to this Convention whether any loss has been sustained to this State, from the manner in which the Commissioners of the Board of Public Works have disposed of the bonds of the State; if so, what the loss is, of what it consisted, and when it occurred. And that he be also requested to report to this Convention the
                amount levied by each county of the State for the year 1860 for the compensation of Justices of the Peace. Also the amount paid to jurors for services rendered in the county courts from the State and county treasuries during the same period."
              </p>
              <div className="speaker" id="sp4032"><span className="persName">Mr. WILSON</span>—</div>
              <p className="p-in-sp">I now move to amend that resolution by adding thereto the following:</p>
              <p className="p-in-sp">"Resolved, That in order to .facilitate the report of the Auditor as to the condition of the State bonds, he be instructed to call to his aid the commissioners of the sinking fund, and the Secretary of the Board of Public Works, if in his opinion it shall be necessary for him to do so."</p>
              <div className="speaker" id="sp4033"><span className="persName">Mr. WILSON</span>—</div>
              <p className="p-in-sp">
                It will be remembered that when the Board of Public Works went into operation under the last Constitution, our State bonds were above par. The last sale of our bonds in this city show them to be 30 1/2 per cent below par. Indeed I have seen it stated in the papers that they have been kept in the market at 50 cents in the dollar. If Virginia has to raise money by means of State bonds,
                which are to be sold at 50 cents in the dollar, we are truly in a deplorable condition.
              </p>
              <div className="speaker" id="sp4034"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">I don't see what is the object of this inquiry.</p>
              <div className="speaker" id="sp4035"><span className="persName">Mr. WILSON</span>—</div>
              <p className="p-in-sp">
                I understand that under the present system of the Board of Public Works, these bonds are so disposed of that the credit of the State is very much impaired, if not entirely ruined. Ten years ago, our State bonds were the most desirable in the world. They were sought with avidity everywhere abroad, and because of some mismanagement they are now selling perhaps at 40 cents in the
                dollar. The last quotation I saw was 60 cents in the dollar. It is very well known that we have a provision in our statutes requiring that our bonds shall not be sold for less than par, and yet, notwithstanding that provision our bonds are sold in this town fox 40 per cent below par. Now, we have recently incurred an additional debt of one million dollars, and are about to incur a
                million more; and I desire to know by what means can this amount be obtained, in view of the statutory <PageNumber num={245} /> restriction placed upon us, but to sell our bonds under par? Truly, it may be said that Virginia is on the eve of bankruptcy.
              </p>
              <div className="speaker" id="sp4036"><span className="persName">Mr. HARVIE</span>, of Amelia—</div>
              <p className="p-in-sp">
                I think the depreciation to which the gentleman refers, can be very easily accounted for. Ten years ago, when the gentleman says our bonds were above par, we owed only about twenty millions of dollars; now we owe about forty millions. That is sufficient to account for the depreciation within the period referred to. If the gentleman will look at the recent quotation of Virginia State
                stock, he will find that there has been an extraordinary fall in these stocks within the last two days, brought about by a combination of gentlemen in Wall street, New York, to affect the action of this Convention, and impair the credit of the State in this emergency. This depreciation, therefore, is merely temporary and artificial, and furnishes no criterion of the intrinsic value
                of the bonds. It is caused for a purpose and is not at all the result of any lack of confidence in the ability of the State, or the integrity of her people.
              </p>
              <p className="p-in-sp">
                I am somewhat familiar with the operations of the Board of Public Works, and I see no reason now, nor have I at any time seen any reason to throw any imputation upon the system of administration pursued by that Board. They are in no way responsible for the state of things complained of by the gentleman, and should, therefore, be exempt from any censure in that connection.
              </p>
              <p className="p-in-sp">This whole inquiry I regard in no wise pertinent to the subjects which this body is now considering, and I therefore move to lay the resolution on the table.</p>
              <div className="speaker" id="sp4037"><span className="persName">Mr. WILSON</span>—</div>
              <p className="p-in-sp">
                The gentleman seems to misunderstand my position. I do not desire to impute any improper motive to the members of the Board of Public Works. I attribute the evil complained of to a radical defect in our financial system, and not to the Board. It cannot be denied that this defect exists; it cannot be denied that our bonds were sold here even before the Convention went in to session,
                at 35 per cent. below par. This is the result of a system of traffic, which has been going on here for years past. A state contractor calls upon the Board of Public works, presents his certificate for work done, and demands payment. He is informed that there is no money in the Treasury, and he will be compelled to take the bonds. He finds it convenient to borrow money from a broker,
                say a thousand dollars, and invests that in State bonds. So soon as the thousand dollars, which we will say is the amount of his contract, is deposited to the credit, and so soon as he draws it of the State,<span className="note" id="Note21"><span className="noteLabel">2</span>This line seems confused. See page 242 for another account of this procedure.</span> he applies for and obtains a
                warrant upon it. He then returns the thousand dollars <PageNumber num={246} /> to his friend and disposes of the bonds at the current price. The contractor, however, is not at a loss on account of the discount upon the bonds; but the State loses, inasmuch as he takes care to indemnify himself in advance by taking the contract at a rate which will more
                than cover the discount.
              </p>
              <p className="p-in-sp">
                Now, I take it, if that is a fact-and I am told by credible gentlemen that it is a fact-Virginia loses 30/2 per cent. upon every $100 she passes out of her treasury. That is not much upon $100, but it will be a vast sum upon four millions. All that I now desire is to get your authority to call for the information referred to in my resolution. If it be true that these operations are
                going on, we ought to know it; and if a defect does exist, we ought to correct it. I do not ask to raise a committee. I merely propose to call upon the Auditor to give us the state of affairs that exist now with reference to our bonds.
              </p>
              <div className="speaker" id="sp4038"><span className="persName">Mr. Wm. G. BROWN</span>—</div>
              <p className="p-in-sp">My friend from Amelia [<span className="persName">Mr. HARVIE</span>]
                was pleased to say that the reduction in the State bonds was owing to the policy recently pursued by the brokers in New York. Sir, they have gone down very rapidly within the last two days, as he is aware of; but this sudden depreciation is not the result of any operations by the Wall street brokers. If the gentleman will look at a resolution or ordinance passed here this morning
                without being read a first, second or third time, he will probably find the true source of this mischief. It is this kind of reckless legislation that has led to the depreciation more than anything else. The reckless, careless manner in which appropriations are made here, could have no other result than that which is exhibited in the depressed standard of the bonds of the State. It
                is a natural consequence of such a system, and I think it would be well for the Legislature, or for this body, to look into this system, and correct it, if they desire to keep Virginia from repudiation and her people from dishonor. I do not know the best mode in which that could be accomplished. My friend from Amelia
                [<span className="persName">Mr. HARVIE</span>] is decidedly more experienced in matters of that kind than I am. I am willing to give that title to my friend, and I trust that he will use that experience which I know he possesses in devising the necessary means to restore the credit of the Commonwealth.</p>
              <div className="speaker" id="sp4039"><span className="persName">Mr. BRANCH</span>, of Petersburg—</div>
              <p className="p-in-sp">I did not give very particular attention to the remarks offered by the gentleman from Harrison [<span className="persName">Mr. WILSON</span>]
                , but I caught enough of them to satisfy me that the expedient referred to by him was a very adroit one to get money out of the Treasury. I have no doubt he believes everything he says to be true; and whilst I admit that the action he proposes is more of a <PageNumber num={247} /> legislative character than a subject of inquiry by this body, yet the
                information asked for is so simple that I should feel very willing to vote for the resolution.
              </p>
              <div className="speaker" id="sp4040"><span className="persName">Mr. HARVIE</span>, of Amelia—</div>
              <p className="p-in-sp">I certainly would not have asked the gentleman from Preston [<span className="persName">Mr. BROWN</span>]
                , to give me any "title" if I could see any motive for the remarks which he made, except to charge this Convention with reckless squandering of the public money. This morning the gentleman did all he could to prevent the State of Virginia from guarding against consequences, the ruinous character of which no body could even contemplate, much less foresee. I regard the position which
                the gentleman has assumed as peculiarly reckless, inasmuch as it is calculated to embarrass the State and paralyze her energies; for how can she enter upon her defence unless the necessary means are provided by this body? As regards the proposition of the gentleman from Hanover,<span className="note" id="Note22"><span className="noteLabel">3</span>This should be Harrison.</span> which the
                gentleman from Preston
                [<span className="persName">Mr. BROWN</span>] , sustained, I look upon it as injudicious and inexpedient in the last degree.</p>
              <div className="speaker" id="sp4041"><span className="persName">Mr. BROWN</span>—</div>
              <p className="p-in-sp">
                I would say to the gentleman that I did not care to see this Convention appropriate ten millions of dollars, if necessary. I merely stated that the ordinance appropriating a million of dollars was passed this morning in two minutes without any deliberation. I refer to it as a matter calculated to impair the credit of the State by showing the careless manner in which appropriations
                are made; and in that view of its effect, I took the liberty of admonishing the Convention of the necessity of more deliberation in appropriating large sums of money.
              </p>
              <div className="speaker" id="sp4042"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">The gentleman referred to the course of this body and attributed to it a recklessness in appropriating the public money.</p>
              <p className="p-in-sp">
                I don't know what the gentleman has been thinking about; but I have been and am fully sensible of the consequences that would result to the State of Virginia in her present position if the policy of the gentleman were pursued. For my part, in view of these consequences, I would cheerfully vote for an appropriation of ten millions, much less one million, if required. The gentleman has
                evidently not made up his mind to maintain the action of this Convention, and, doubtless, will not until he goes home to his constituents and ascertains their sentiments upon the subject.
              </p>
              <p className="p-in-sp">
                I will remark that I and those acting with me are very willing to take the full responsibility of what we have done. The gentleman has no responsibility attaching to him in this matter. He is going home for the purpose of beating us if he can. I am going home to maintain <PageNumber num={248} /> our action here before the people. The difference between
                the gentleman's position and mine, is manifest and plain. I tell him that I represent twenty dollars where he represents one, and that I represent more negroes than he has got in the whole trans-Alleghany country. Yes, sir, we are rich in negroes, and in their valuable resources, and we are willing to spend everything we have got to sustain the action of this Convention.
              </p>
              <div className="speaker" id="sp4043"><span className="persName">Mr. JAMES P. HOLCOMBE</span>, of Albemarle—</div>
              <p className="p-in-sp">I have not heard the whole of these remarks, and such of them as I did hear, and as were excepted to by my friend from Amelia [<span className="persName">Mr. HARVIE</span>] , I was disposed to take to myself in part, though not in the spirit in which my friend seemed to receive them. I took to myself a portion of what my friend considered rebuke given by the gentleman from Preston [<span className="persName">Mr. B</span>RowN]
                . I consider, for one, that I deserve it. Before the gentleman from Preston made his remarks, I was preparing a resolution to redeem what I believed was my error. I must have been in the hall when this Ordinance authorizing the Governor to borrow one million of dollars upon the credit of the State was passed. If I was, and voted for that Ordinance, as I presume I did, I committed an
                error which it is my purpose now to redeem. The Governor cannot borrow the money tonight, nor could he, I am satisfied, borrow it to-morrow; and, in view of that fact, it seems to me to have passed this Ordinance without debate transcends everything I have known in the customs of deliberative bodies; and I will now propose that a committee shall enquire deliberately as to the best
                means of raising this money, and the best mode of appropriating it.
              </p>
              <p className="p-in-sp">I did not understand that the remarks of the gentleman from Preston [<span className="persName">Mr. BROWN</span>] went anything too far; and it is with a view to dissent from that portion of the speech of the gentleman from Amelia [<span className="persName">Mr. HARVIE</span>]
                , in which he maintains the precipitate action of the Convention on the Ordinance referred to by the gentleman from Preston, that I desired to offer two resolutions-one to appoint a committee of five to consider and report to the Convention the best mode of raising the sum of money which may be necessary to meet the extraordinary expenses which may devolve upon this Commonwealth.
                That was not the only instance of what I call hasty action on the part of this body. A resolution was offered by the gentleman from Prince Edward
                [<span className="persName">Mr. THORNTON</span>]
                , which I thought was too precipitate. I desire that we should put ourselves in connection with the Confederate States at the earliest moment consistent with due caution and deliberation; but I am opposed to ill matured and precipitate action. I <PageNumber num={249} /> desire that this resolution, as well as the Ordinance submitted this morning by the
                gentleman from Halifax
                [<span className="persName">Mr. FLOURNOY</span>]
                , shall be reconsidered, and referred to a committee; and whilst I am on this subject, I say, for one, that I do trust that members of this Convention on all sides will endeavor to bring to our deliberations here the utmost charity, and to avoid the use of any remarks which may be deemed exceptionable. I certainly say that I see nothing in the remarks of the gentleman from Preston
                [<span className="persName">Mr. BROWN</span>] , to which any exception could be taken.</p>
              <div className="speaker" id="sp4044"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">I did not yield the floor for the purpose of allowing the gentleman to rebuke me.</p>
              <div className="speaker" id="sp4045"><span className="persName">Mr. HOLCOMBE</span>—</div>
              <p className="p-in-sp">
                I did not intend to rebuke the gentleman. I was going on to say that it was hardly to be expected that gentlemen opposed to each other as we have been, in the discussion of the most momentous questions that ever devolved upon any deliberative body, should immediately forget and banish from our bosoms all the feelings of excitement which that discussion had produced on both sides. I
                acknowledge, so far as I am concerned, that I have had as much of it as any gentleman on this floor. I desire now to bury it all, and I trust that every one here will be animated by a like feeling. I will state in conclusion, that my remarks were not addressed more to the gentleman from Amelia
                [<span className="persName">Mr. HARVIE</span>] , than to any other gentleman on this floor.</p>
              <div className="speaker" id="sp4046"><span className="persName">Mr. BROWN</span>, of Preston—</div>
              <p className="p-in-sp">I should certainly not have said anything if it had not been for the erroneous application which the gentleman from Amelia [<span className="persName">Mr. HARVIE</span>] seemed to give to the few remarks which I made in relation to the manner in which an appropriation was made this morning. I did not think that any gentleman would have misunderstood me as objecting to the amount appropriated. I merely objected to the carelessness and want of deliberation displayed in making the appropriation.</p>
              <p className="p-in-sp">
                I think my friend from Amelia must have some feelings in reference to me, which no one else entertains, for why, if such was not the case, did not others misunderstand the application of my language? I cannot see what was there to justify this violent onslaught on the part of the gentleman. Certainly my manner was very different from his, and I did not know that my friend cherished
                some unkind recollections of our meeting in the National Convention.
              </p>
              <div className="speaker" id="sp4047"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">I assure the gentleman, I entertained nothing for him but the kindest feelings.</p>
              <div className="speaker" id="sp4048"><span className="persName">Mr. WICKHAM</span>, of Henrico—</div>
              <p className="p-in-sp">I would suggest to the gentleman from <PageNumber num={250} /> Harrison [<span className="persName">Mr. WILSON</span>] , that there is one portion of his resolution that seems to me to be inappropriate.</p>
              <div className="speaker" id="sp4049"><span className="persName">Mr. PRICE</span>, of Greenbrier—</div>
              <p className="p-in-sp">I don't think that any good will come of this inquiry, particularly at this time, and before this body. I therefore desire to get clear of it as soon as possible.</p>
              <p className="p-in-sp">I move to lay the motion upon the table.</p>
              <div className="speaker" id="sp4050"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">That motion has already been made by the gentleman from Amelia.</p>
              <p>The motion was agreed to.</p>
              <div className="speaker" id="sp4051"><span className="persName">Mr. FENDALL GREGORY, JR.</span>, of King William—</div>
              <p className="p-in-sp">I beg leave to offer the following resolution:</p>
              <p className="p-in-sp">"Resolved, That this Convention hereafter meet at 12 o'clock M., until otherwise ordered."</p>
              <div className="speaker" id="sp4052"><span className="persName">Mr. STAPLES</span>, of Patrick—</div>
              <p className="p-in-sp">I move to lay that resolution on the table.</p>
              <p>This motion was subsequently withdrawn.</p>
              <div className="speaker" id="sp4053"><span className="persName">Mr. GREGORY</span>—</div>
              <p className="p-in-sp">My purpose in offering the resolution was to afford time to the committees to do their work. It was offered with special reference to them.</p>
              <p>The resolution was adopted.</p>
              <div className="speaker" id="sp4054"><span className="persName">Mr. EPPA HUNTON</span>, of Prince William—</div>
              <p className="p-in-sp">I beg leave to offer the following resolution :</p>
              <p className="p-in-sp">Resolved, That the Governor of this Commonwealth be and he is hereby authorized to appoint an additional number of aids, who shall have the same rank as those now authorized by law.</p>
              <p>This resolution was referred to the Committee on Military Affairs. <span className="persName">Mr. CALEB BOGGESS</span>, of Lewis, offered the following resolution, which was adopted:</p>
              <p>Resolved, That the Committee on Military Affairs be required to report as early as possible the military condition of the borders of each part of the State-the liability of the same to invasion-where and what kinds of defence would be best adapted to the protection thereof, designating particularly the points and kinds of fortifications, if any, to be erected.</p>
              <div className="speaker" id="sp4055"><span className="persName">Mr. HOLCOMBE</span>, of Albemarle—</div>
              <p className="p-in-sp">I now move to reconsider the Ordinance offered by the gentleman from Halifax [<span className="persName">Mr. FLOURNOY</span>] , and adopted this morning, authorizing the Governor to borrow one million of dollars.</p>
              <p>The motion was agreed to.</p>
              <div className="speaker" id="sp4056"><span className="persName">Mr. HOLCOMBE</span>—</div>
              <p className="p-in-sp">I now offer the following resolution:</p>
              <p className="p-in-sp">
                Resolved, That a committee of five members be appointed to consider <PageNumber num={251} /> and report to the Convention the best mode of raising the sum of money which may be necessary to defray the extraordinary expenses which may devolve upon this Commonwealth in this emergency, and that the ordinance passed this morning, authorizing the Governor to
                borrow one million of dollars, be referred to that committee.
              </p>
              <p>The resolution was adopted.</p>
              <div className="speaker" id="sp4057"><span className="persName">Mr. HOLCOMBE</span>—</div>
              <p className="p-in-sp">I will now offer the following resolution:</p>
              <p className="p-in-sp">"Resolved, That a committee of five be appointed to consider and report to the Convention what action is necessary for Virginia to take in relation to the Confederate States and the border slave States."</p>
              <div className="speaker" id="sp4058"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">Is it the intention of the gentleman to offer that as a substitute for the resolution offered by the gentleman from Prince Edward [<span className="persName">Mr. THORNTON</span>] .</p>
              <div className="speaker" id="sp4059"><span className="persName">Mr. HOLCOMBE</span>—</div>
              <p className="p-in-sp">Yes, sir.</p>
              <div className="speaker" id="sp4060"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">Then it is necessary to move a reconsideration of the vote by which that resolution was adopted.</p>
              <div className="speaker" id="sp4061"><span className="persName">Mr. HOLCOMBE</span>—</div>
              <p className="p-in-sp">I submit that motion.</p>
              <div className="speaker" id="sp4062"><span className="persName">Mr. SHEFFEY</span>, of Smyth—</div>
              <p className="p-in-sp">I understand that the resolution of <span className="persName">Mr. THORNTON</span> has already been communicated to the Governor.</p>
              <p className="p-in-sp">I would request the gentleman not to submit his motion to reconsider until it is ascertained whether the Governor has communicated the contents of the resolution or not.</p>
              <div className="speaker" id="sp4063"><span className="persName">Mr. HOLCOMBE</span>—</div>
              <p className="p-in-sp">I move that the Governor be requested to return the resolution and to suspend action upon it.</p>
              <p>The motion was agreed to.</p>
              <div className="speaker" id="sp4064"><span className="persName">Mr. SHEFFEY</span>—</div>
              <p className="p-in-sp">I move that the gentleman from Albemarle [<span className="persName">Mr. HOLCOMBE</span>] be appointed by the President of the Convention a committee to wait upon the Governor and communicate to him the action of this body.</p>
              <p>The motion was agreed to, and <span className="persName">Mr. HOLCOMBE</span> accordingly appointed for the purpose indicated.</p>
              <div className="speaker" id="sp4065"><span className="persName">Mr. BENJAMIN WILSON</span>, of Harrison—</div>
              <p className="p-in-sp">I beg leave to offer the following preamble and resolutions:</p>
              <p className="p-in-sp">
                "Whereas, in consequence of the constantly increasing burthens imposed upon the people of Virginia to support and administer her government, it becomes important to enquire whether any, and if any, what reforms are needed in the organic law of the State, to secure to the people 'the greatest degree of happiness and safety,' and most effectually to guard 'against the danger of
                mal-administration;' be it, therefore—
              </p>
              <PageNumber num={252} />
              <p className="p-in-sp">"Resolved, That the Auditor of Public Accounts be requested to collect and furnish to this Convention, as soon as practicable, the following information :</p>
              <p className="p-in-sp">"1. The total amount of money which has been appropriated to works of internal improvement in each one of the grand divisions of the State, sub-dividing the Trans-Alleghany district into the North-West and South-west.</p>
              <p className="p-in-sp">"2. The amount of taxes assessed on lands in each district, the amount of taxes on all other subjects therein for the year 1860, and the amount of assessments returned delinquent.</p>
              <p className="p-in-sp">"3. The number of registered voters in each district.</p>
              <p className="p-in-sp">"4. The names of all officers and employees residing in said districts, whether they be State, county, or corporation officers; their residence, and the salary, income and fees received by each for the year 1860, or for a full year commencing or terminating in the year 1860.</p>
              <p className="p-in-sp">"5. The number and names of all sheriffs and clerks of courts who have been delinquent in the payment of revenue since the first day of July, 1852, and the number and names of those delinquent for nine years next preceding the first day of July, 1852.</p>
              <p className="p-in-sp">"6. A comparative statement of expenses incurred by each one of the public institutions of the State for ten years, commencing with the year 1840, and for ten years, commencing with the year 1850."</p>
              <div className="speaker" id="sp4066"><span className="persName">Mr. WILSON</span>—</div>
              <p className="p-in-sp">I did not design to offer that resolution now. I prepared it at a time when I entertained a hope that we might be able to adjust our difficulties. I desire that, in this hour of trial, when the State is almost bankrupt, that the gentlemen who voted to secede from the Federal Union will unite with me in an effort to place our State in a safe position.</p>
              <p className="p-in-sp">
                It occurs to me, sir, and I am partly sustained in that idea by the action of the Convention on my resolutions to-day, that there is a persistent determination to do that which is to cry down the credit of the State. The million of dollars that has been appropriated to-day will cost the State, under the operations which I detailed here to-day, about $350,000. The State will have to
                pay a bonus of about $600,000 to raise the two millions of dollars appropriated by the Legislature and this Convention, and yet there seems to be no effort made to remedy this evil. And it would seem in fact as if no hope is entertained of keeping up the credit of the State, but that rather a disposition existed to allow ruin and disaster to come upon every interest.
              </p>
              <p className="p-in-sp">
                I have heretofore stated that there is a radical defect in our <PageNumber num={253} /> financial system, and that we can correct that defect here without costing Virginia one dollar. If the shaving practice, to which I referred to-day, is pursued, I want to know who is responsible for it. If we are to be oppressed and burdened with unnecessary
                taxation, I desire that the people shall know from what source it comes. We are not disposed to seek an unnecessary and exorbitant bonus to provide means to put our State in a proper condition of defence. I desire, as all my resolutions have been laid upon the table, that this inquiry shall be made, and the information sought for furnished. And it is a little strange that the very
                section which voted Virginia out of the Union, is the section which is now opposed to taking any step tending to the maintenance of her credit. I will not consent to that policy of indifference in a matter so important as the preservation of the credit of the State. We, of Western Virginia, will co-operate with you so far as the maintenance of the rights of Virginia is concerned.
                That far we will go, and no farther. We will do nothing which we believe calculated to impair and destroy the credit of the State; but will rather seek to build it up and maintain it at a standard befitting the resources of a great Commonwealth, such as Virginia possesses.
              </p>
              <p className="p-in-sp">
                I presume that these resolutions will meet the same fate with the one I have already offered. I must say in all frankness, that I would not have offered these resolutions but for the fate that my first resolution met. I deemed it proper to make another effort, so as, if possible, to remedy an evil which sooner or later will result in ruin to the interests of the State if permitted to
                continue. There is, as I have said, a radical defect in our financial system, and we can easily remedy this defect. Our bonds are at a ruinous discount by reason of the loose manner in which they are hawked about by speculators and brokers. They are sent in millions to New York and Philadelphia, and there sold at a depreciation which must operate ruinously upon the credit of the
                State.
              </p>
              <p className="p-in-sp">I am anxious that the honeyfugling operations which are known to be practiced in this town, and by which our bonds are depreciated to the extent of 301/2 per cent below par should be checked.</p>
              <p className="p-in-sp">I trust it will be the pleasure of the Convention to adopt these resolutions, which, as I have already stated, would not have been offered if my first resolution was adopted. I see to-day that our bonds have been sold at 50 cents on the dollar, and this of itself should stimulate the Convention to take some action which would prevent this ruinous depreciation.</p>
              <div className="speaker" id="sp4067"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">I should like to know whether these bonds are sold by <PageNumber num={254} /> the State officers.</p>
              <div className="speaker" id="sp4068"><span className="persName">Mr. WILSON</span>—</div>
              <p className="p-in-sp">
                I have heard it said in this town frequently within the last two months, that a member of the General Assembly charged upon this floor, that in awarding contracts, the price allowed under these contracts was with reference to the depreciated value of the bonds of the State; and that when the contractor has completed the work in whole or in part, he presents his drafts at the Treasury
                Department for payment. The Treasurer, however, does not find it convenient to pay the money. The contractor manages, through the accommodation of a friend, to buy a thousand dollars worth of State bonds, and then receives his warrant and draws that $1,000 out of the Treasury. As soon as this is drawn, he returns the money to the friend and immediately disposes of the bonds to the
                brokers at the week's price. The State loses the discount, the contractor having secured himself in his original estimate by putting an advance upon the legitimate price of the work to the extent of the drawback upon the bonds.
              </p>
              <div className="speaker" id="sp4069"><span className="persName">Mr. WISE</span>—</div>
              <p className="p-in-sp">Will the gentleman allow me to make a remark or two?</p>
              <p className="p-in-sp">
                When Governor of the Commonwealth I was very well aware of the operations to which the gentleman refers, and I called the attention of the Legislature to the fact; but without effect, as no remedy was provided, and none could be in my opinion. If the gentleman can solve this problem, he will do what I could not do, and what I could find no person else who could do. How can the
                gentleman prevent the evil of which he complains? I would ask him if he was a contractor whether he would contract upon the same terms when paid in bad funds as he would when paid in good funds? I imagine not; and that being the case, the question is, does the State comply with the letter of the law? It is known to everybody that the contractor could not afford to take at par the
                State bonds given him in payment, and he is therefore compelled to guard against loss from the depreciation in these bonds by enhancing his estimate. How will the gentleman so invent a process of finance as to bring the contract down to par as well as the money up to par? If he can do it, he will do more than any one I have yet heard of.
              </p>
              <div className="speaker" id="sp4070"><span className="persName">Mr. WILSON</span>—</div>
              <p className="p-in-sp">
                I understand that under the former system of the Board of Public Works in the management and sale of our bonds, much carelessness was exhibited, especially in their operations through Selden, Withers &amp; Co., and this fact materially shook the confidence of brokers in our State bonds, and they were not disposed to deal in them, but were hawking and spreading them about without any
                <PageNumber num={255} /> security. It could be easily seen that if that course had been kept up the credit of our bonds could not be sustained.
              </p>
              <p className="p-in-sp">What do we see now? Why, that our bonds are from 40/2 to 50 per cent. below par, and I do therefore contend that if this money is to be raised, we ought to so regulate our bonds as to prevent their becoming a subject of loss.</p>
              <p className="p-in-sp">I trust the house will adopt these resolutions. The Auditor can furnish the required information in a very few days. There is a radical defect somewhere, and it is our duty to remedy it.</p>
              <div className="speaker" id="sp4071"><span className="persName">Mr. WICKHAM</span>, of Henrico—</div>
              <p className="p-in-sp">It seems to me that some of the enquiries proposed by the gentleman are of some importance; while there are others that are altogether unnecessary. I trust that the House will sustain the enquiry.</p>
              <div className="speaker" id="sp4072"><span className="persName">Mr. SLAUGHTER</span>, of Campbell—</div>
              <p className="p-in-sp">I move to lay the whole subject on the table.</p>
              <p>The motion was agreed to.</p>
              <div className="speaker" id="sp4073"><span className="persName">Mr. WILSON</span>—</div>
              <p className="p-in-sp">I move to take up the resolution which was laid on the table to-day, at the instance of the gentleman from Amelia [<span className="persName">Mr. HARVIE</span>] , and the gentleman from Greenbrier [<span className="persName">Mr. PRICE</span>] . Both made motions to that effect.</p>
              <div className="speaker" id="sp4074"><span className="persName">Mr. PRICE</span>, of Greenbrier—</div>
              <p className="p-in-sp">I made that motion, because it seemed to me that there was no remedy indicated for the losses which the State was supposed to sustain through the operations referred to by the gentleman from Harrison [<span className="persName">Mr. WILSON</span>] . I see no good to come from this enquiry, and I hope the resolution will not be taken up.</p>
              <p>The question on the motion to take up, was put and decided in the negative.</p>
              <p><span className="persName">Mr. NAPOLEON B. FRENCH</span> of Mercer, offered the following resolution, which was adopted.</p>
              <p>Resolved, That a committee of five be appointed to originate and report to this Convention the best mode of promoting the manufacture of gunpowder, and of securing supplies of saltpetre and sulphur.</p>
              <div className="speaker" id="sp4075"><span className="persName">Mr. HOLCOMBE</span>—</div>
              <p className="p-in-sp">I called upon the Governor and communicated to him the request of the Convention to retain the resolution offered by <span className="persName">Mr. THORNTON</span> and adopted by the Convention. He informed me that he had taken prompt action upon it, and that the message was now in Montgomery.</p>
              <p className="p-in-sp">Looking again at the resolution of my friend from Prince Edward, I find that it merely expressed a desire to form an alliance with the Confederate States, without committing us to any policy whatever. It is probably as well that it has been acted upon.</p>
              <PageNumber num={256} />
              <div className="speaker" id="sp4076"><span className="persName">Mr. MOORE</span>, of Rockbridge—</div>
              <p className="p-in-sp">I move that the Convention adjourn.</p>
              <p>The motion was agreed to, and the Convention adjourned at eight minutes past 7 o'clock, P. M.</p>
            </div>
          </div>
          <div className="day" id="vsc1965.v4.2.4">
            <PageNumber num={257} />
            <h2><span className="headingNumber">1.4. </span><span className="head">FOURTH DAY</span></h2>
            <div className="dateline">Friday, <span className="date">April 19</span></div>
            <p>The Convention met at 12 o'clock, M.</p>
            <div className="speaker" id="sp4077"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">The Chair has received a telegraphic dispatch from the President of the State Convention of Arkansas, which he begs leave to submit for the consideration of the Convention.</p>
            <p>The following is the dispatch referred to:</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>"FAYETTEVILLE, Ark., April 19, 1861.</p>
                <p>"To the President of the Virginia Convention:</p>
                <p>"Under existing circumstances, I am urged to re-convene the Arkansas Convention. What will Virginia do?"</p>
              </div>
            </div>
            <div className="speaker" id="sp4078"><span className="persName">Mr. MACFARLAND</span>—</div>
            <p className="p-in-sp">The communication, as I understand, is from the President of the Arkansas Convention. I propose that it be replied to through the President of this Convention, by announcing to the President of the Arkansas Convention, by telegraph, the action which this Convention has taken.</p>
            <div className="speaker" id="sp4079"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">I beg leave, also, to submit a telegraphic communication from the Governor of Georgia.</p>
            <p>The communication was read, and is as follows:</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>"MILLEDGEVILLE, April 18, 1861.</p>
                <p>"President Virginia Convention:</p>
                <p>"Received telegraphic dispatch announcing passage of Secession Ordinance, and, as representative of Georgia, I send greeting and one hundred hearty cheers to the Old Dominion. May God, who defends the right, enable her to protect the cradle of liberty and the grave of Washington from Abolition desecration.</p>
                <p>"JOSEPH C. BROWN."</p>
              </div>
            </div>
            <p>This communication was, on motion, laid upon the table.</p>
            <div className="speaker" id="sp4080"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">I have also received a communication from the Cashier of the Traders' Bank of the city of Richmond, which I submit. The communication was read as follows:</p>
            <PageNumber num={258} />
            <div className="floatingText">
              <div className="floatingText_body">
                <p>"RICHMOND, April 18, 1861.</p>
                <p>"At an annual meeting of the stockholders of the Traders' Bank of the city of Richmond, held this day, the following resolution was unanimously adopted :</p>
                <p>"Resolved, That the Board of Directors be and they are authorized and directed to invest a sum not exceeding fifty thousand dollars ($50,000) in Virginia State bonds, Treasury notes or other securities which may be hereafter issued, for the purpose of arming or defending the State, and all such securities to be taken at their par value.</p>
                <p>"A copy from the minutes.</p>
                <p>"C. SINTON, Cashier."</p>
              </div>
            </div>
            <p>Laid on the table.</p>
            <div className="speaker" id="sp4081"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">A resolution was adopted on yesterday ordering a committee to be raised on the subject of finances. It was offered by the gentleman from Albemarle [<span className="persName">Mr. HOLCOMBE</span>] ; but, at his request, his name is not placed on the committee.</p>
            <p className="p-in-sp">The committee is as follows:</p>
            <p className="p-in-sp">Messrs. <span className="persName">W. H</span>. Macfarland, of Richmond; Charles R. Slaughter, of Campbell; John Echols, of Monroe; Jeremiah Morton, of Orange and Greene; and Alpheus F. Haymond, of Marion.</p>
            <p><span className="persName">Mr. PRESTON</span>, of Montgomery, offered the following resolution:</p>
            <p>"Resolved, That the Ordinance separating Virginia from a union with the other States under the Constitution of the United States, be referred to a committee of seven, to report the same with such changes in the preamble thereto as the committee shall recommend, with a plan to submit the preamble and ordinance to the good people of the Commonwealth."</p>
            <div className="speaker" id="sp4082"><span className="persName">Mr. FISHER</span>, of Northampton—</div>
            <p className="p-in-sp">I want to offer an amendment to that, because I have no idea that the preamble ought to be referred at all:</p>
            <p className="p-in-sp">"Resolved, That a committee of five be appointed by the President, whose duty it shall be to have that preamble and ordinance, resuming the sovereignty of Virginia, engrossed on parchment, so that the members may sign the same."</p>
            <div className="speaker" id="sp4083"><span className="persName">Mr. PRESTON</span>—</div>
            <p className="p-in-sp">I will point out the difference between my proposition and that of the gentleman from Northampton. My resolution proposes to refer the ordinance, with a view to the perfection of a proper <PageNumber num={259} /> schedule for its submission to the people. There need be no apprehension of any material change in the preamble.</p>
            <p className="p-in-sp">The gentleman's proposition contemplates no such preparation, but proposes that it shall be signed forthwith, as though our action was final with reference to it.</p>
            <p className="p-in-sp">The Convention will see that there is a very material difference in the propositions.</p>
            <div className="speaker" id="sp4084"><span className="persName">Mr. FISHER</span>—</div>
            <p className="p-in-sp">I am opposed to the preamble being referred at all. I will, however, withdraw my resolution.</p>
            <div className="speaker" id="sp4085"><span className="persName">Mr. HARVIE</span>—</div>
            <p className="p-in-sp">
              I regret that I am compelled to oppose the reference of the preamble. Objection was made to it at the outset; but when the question on its adoption was taken, the Convention by a large majority sustained it. I don't think it is fair for gentlemen who absented themselves from this Convention when the vote was taken, to seek now to effect a change in its action. I think we ought to keep
              the preamble and ordinance exactly as they are, and submit them without modification to the voters of the State.
            </p>
            <p className="p-in-sp">I should like to know from the gentleman in what particular he desires an alteration made?</p>
            <div className="speaker" id="sp4086"><span className="persName">Mr. PRESTON</span>—</div>
            <p className="p-in-sp">As to myself, in no particular. But an alteration was suggested by the gentleman from Orange [<span className="persName">Mr. MORTON</span>] . He wanted some alterations made, and suggested, in a spirit of perfect amity and deference, that it be referred to a committee, who would supervise it and report.</p>
            <div className="speaker" id="sp4087"><span className="persName">Mr. HARVIE</span>—</div>
            <p className="p-in-sp">I will forego any further opposition, in view of the facts stated by the gentleman.</p>
            <div className="speaker" id="sp4088"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">It would certainly not be competent for the Convention to make any change now in the preamble without first reconsidering the vote adopting the preamble.</p>
            <div className="speaker" id="sp4089"><span className="persName">Mr. PRESTON</span>—</div>
            <p className="p-in-sp">I move to reconsider the vote by which it was adopted.</p>
            <p>The motion was agreed to, and the resolution offered by <span className="persName">Mr. PRESTON</span> was adopted.</p>
            <div className="speaker" id="sp4090"><span className="persName">Mr. WICKHAM</span>, of Henrico—</div>
            <p className="p-in-sp">
              Mr. President : I rise to a question of privilege. When the vote was taken on the adoption of the Ordinance of Secession, I voted in the negative; I believed that the proposition of the gentleman from Fauquier presented a far better mode of bringing the question, and, although it was defeated in the Convention, yet I thought it might be presented to the people as the course that would
              be pursued, if the Ordinance was not ratified by them; and as the action of this Convention was intended to be recommendatory— <PageNumber num={260} /> not final—I was unwilling, by my vote, to recommend a policy which I deemed unwise. But, sir, I am satisfied that to-day a very large proportion of the people of Henrico, with a full knowledge of the
              probable consequences of the act, are in favor of this ordinance; and, as I think it right that their wishes should be accurately expressed and carried out, and that the weight of their vote shoula go along with the Ordinance, I have no hesitation in asking the Convention to grant me the privilege of transferring my vote from the negative to the affirmative.
            </p>
            <p className="p-in-sp">
              Again, Mr. President, whilst I have differed from the majority of this Convention as to the mode in which a State should assert its independence, yet, with all my devotion to the Union, I have ever acknowledged my allegiance as due, first of all, to the State of Virginia. Events that have transpired and are transpiring, render it clear that the Convention, in adopting that ordinance,
              have expressed the will of a large majority of the people of the State, and the State having assumed a position that will require the earnest and hearty co-operation of all her sons to successfully maintain, I regard it as a solemn duty, on my part, to give my humble aid in procuring unanimity of action and of thought among our people. I have the honor to command a troop of horse in
              the county of Hanover. Acting in conformity with the proclamation of the Governor, and with orders subsequently received from my superior officers, I have ordered that troop to prepare to take the field, and am on the eve of my departure from the city to take my place at its head, determined, on my part, to do all in my power to advocate the cause in which Virginia is engaged, and
              meaning to defend her flag wherever it may wave.
            </p>
            <p className="p-in-sp">Mr. President, I desire to say that the Union men of Henrico, who sent me here, will prove themselves as true to the cause of Virginia as any other portion of the people of the State, and, like myself, they will share her fate, be it for weal or woe.</p>
            <div className="speaker" id="sp4091"><span className="persName">Mr. JOHN B. BALDWIN</span>—</div>
            <p className="p-in-sp">What will be the effect of granting the leave which the gentleman asked? There are others of us who have an interest to know the process by which that change is to be effected. I would like to know what will be the mode of transferring the vote of a gentleman who may wish to make the transfer?</p>
            <div className="speaker" id="sp4092"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">The Chair thinks that the original record cannot be altered; but an entry of the transfer of the vote may be made in the journal.</p>
            <div className="speaker" id="sp4093"><span className="persName">Mr. BALDWIN</span>—</div>
            <p className="p-in-sp">If that is the understanding, I shall concur very heartily in giving the leave the gentleman asks.</p>
            <PageNumber num={261} />
            <p>The leave was granted.</p>
            <div className="speaker" id="sp4094"><span className="persName">Mr. WAITMAN T. WILLEY</span>, of Monongalia—</div>
            <p className="p-in-sp">I am requested by the committee on the subject of taxation to make a report. In doing so, it is proper I should accompany it with some personal explanation.</p>
            <p className="p-in-sp">
              When the resolution was adopted appointing a committee to take this matter into consideration, it was impliedly understood that no action on the subject should be taken until an adjourned session of the Convention, which was then contemplated. It is, therefore, proper for me to say that this action and report on the part of that committee has not been at the instance of myself or of
              the members composing it who are from the West. The action of the committee has been, at the instance of Eastern gentlemen, largely interested in slave property.
            </p>
            <p className="p-in-sp">
              I am authorized this morning to report a proposition of amendments to the Constitution, entirely acceptable, as I understand, to the Western portion of the State, and that proposition was not adopted, I will further say, at the instance of the Western members. It is proper that I should make a further explanation in this connection, in which, I think, I will be borne out by Eastern
              gentlemen composing that committee; and it is this, that Western gentlemen distinctly placed this proposition before the consideration of the committee upon its abstract propriety and justice in their estimation, desiring it to be distinctly understood that they did not bring it forward at their own instance, and especially that they did not bring it forward now in the present exigency
              and crisis of the Commonwealth. I wish still further to observe that, acting upon the professions, upon the original, implied understanding that the consideration of this question was to be postponed until an adjourned session of the Convention, many of the Western members have gone home, perhaps not to return until the adjourned session, not anticipating, I suppose, that any action
              would be taken now upon it.
            </p>
            <p className="p-in-sp">
              With this explanation, Mr. President, I propose to submit this report to the Convention, and ask that it be laid on the table and printed. I submit to Eastern gentlemen that they can dispose of it as they see proper. Our friends are not here; those who would support this proposition from the West are not here; but if Eastern gentlemen, from a sense of justice to the West, or of
              generosity, see proper to take this question up and give us the proposition as reported, we shall be exceedingly grateful to them for it. I don't feel myself, individually, authorized to ask any action on the part of the Convention on the report. I submit it to be laid upon the table and printed. If <PageNumber num={262} /> Eastern gentlemen see proper to
              take it up and pass it we would be exceedingly grateful to them for it.
            </p>
            <p>The following is the report.</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>
                  The committee to whom was referred the duty of inquiring into the expediency of amending sections 22 and 23 of article 4 of the Constitution, so as to provide that taxation shall be equal and uniform throughout the Commonwealth, &amp;c. have had the matter referred to them under consideration, and beg leave to submit the following, as proper to be adopted in the place and stead of
                  said sections 22 and 23, to wit:
                </p>
                <p>Taxation shall be equal and uniform throughout the Commonwealth, and all property shall be taxed in proportion to its value, which shall be ascertained in such manner as may be prescribed by law; but any property may be exempted from taxation by the vote of a majority of the whole number of members elected to each house of the General Assembly.</p>
                <p>W. T. WILLEY, Chairman.</p>
              </div>
            </div>
            <div className="speaker" id="sp4095"><span className="persName">Mr. Wm. M. TREDWAY</span>, of Pittsylvania—</div>
            <p className="p-in-sp">
              The reasons suggested by the gentleman from Monongalia, who has just made the report, together with other considerations operating upon my mind, induce me to urge that that report remain upon the table, and that there shall be no action upon it until an adjourned session, or, at any rate, until we shall have passed through the consideration of the pressing subjects which come upon us
              daily, and which will, probably, come upon us as long as we remain here.
            </p>
            <p className="p-in-sp">
              I understand the motion of the gentleman is, to lay the report on the table and have it printed. My purpose now is merely to say, that whatever may be the result when we shall take it up, there are strong reasons which induce me to vote against its consideration at this time. I will say, that in my view, it would be impolitic; it would be wrong to act upon a new subject of taxation,
              until we can consider with it the subject of representation. The subject of representation was not referred to that Committee, and therefore it could not report upon it. It is, however, a subject which is immediately connected with it, and I think it ought to be considered by the Convention before we act upon the subject of taxation.
            </p>
            <p className="p-in-sp">I shall for these reasons favor a proposition that the report be allowed to lie upon the table until some future period.</p>
            <div className="speaker" id="sp4096"><span className="persName">Mr. EPPA HUNTON</span>, of Prince William—</div>
            <p className="p-in-sp">
              I merely desire to say in regard to the report of the Committee, that I am prepared to respond <PageNumber num={263} /> to its recommendations in the main, most heartily and cordially. I am not only willing myself, but, sir, there are those in this Convention who recollect the proceedings of a meeting in my county, held some two or three weeks ago, in
              which my constituents authorized me, in view of the circumstances that now surround us, to vote for the ad valorem system of taxation which that report, as I understand recommends.
            </p>
            <p className="p-in-sp">There is one suggestion in regard to it which I desire to throw out.</p>
            <p className="p-in-sp">
              If I understand it correctly, it proposes to establish the system of taxation,<span className="note" id="Note23"><span className="noteLabel">1</span>Something like "equal" or "ad valorem" must have been intended to qualify "taxation."</span> and if I understand the meaning of that language, it proposes to put the tax upon negro property according to its block value.
            </p>
            <div className="speaker" id="sp4097"><span className="persName">Mr. FORBES</span>, of Buckingham—</div>
            <p className="p-in-sp">I understand that a motion to lay on the table is not debatable.</p>
            <div className="speaker" id="sp4098"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">That motion is also accompanied by a motion to print, which is debatable.</p>
            <div className="speaker" id="sp4099"><span className="persName">Mr. HUNTON</span>—</div>
            <p className="p-in-sp">I rose merely for the purpose of expressing my approval of the ad valorem principle of taxation, and no other. Having done so, I shall proceed no further with the debate.</p>
            <div className="speaker" id="sp4100"><span className="persName">Mr. GEORGE W. SUMMERS</span>, of Kanawha—</div>
            <p className="p-in-sp">I was not aware that there was to be any report made from the committee appointed to take charge of this subject, this morning; but I regard it somewhat as a duty to make a remark or two on this report, while the opportunity is now afforded me.</p>
            <p className="p-in-sp">
              I have taken no part in any of the discussions which occurred in the Convention since our first assembling, in relation to this question of taxation. My friends from various portions of the West have given their views upon this question. I voted for the raising of this committee, and voted against laying the propositions offered by gentlemen from the West on the table. I was very
              willing that a committee should be appointed to consider the subject, and I acquiesced in the disposition which was finally made of it. I thought that we were not to report until a subsequent meeting of the Convention. That seemed to be the condition on which the committee was raised.
            </p>
            <p className="p-in-sp">Now, for one, I have not been disposed, from the beginning of the Convention, to have the great primary object of this Convention embarrassed in any way by questions touching the State constitution.</p>
            <p className="p-in-sp">
              At a meeting, a very large meeting of the people of my county, which took place a short time before I left home, at which I was present, and in the proceedings of which I participated, it was among other things resolved, that in regard to outside difficulties with which we were surrounded, the people of my county knew no sort of national dissensions. I stand in truth instructed, so far
              as the meeting I refer to <PageNumber num={264} /> announced the sentiments of the county. The sentiment of that meeting was, that we were not to interfere with the settlement, in any form, of the pending national difficulties by the introduction of any subject touching the State Constitution calculated to embarrass or excite differences of opinion. In
              that I acquiesced at the time, and have acquiesced since.
            </p>
            <p className="p-in-sp">
              Now, upon this question of the assimilation of the tax upon the ad valorem principle, no man who knows anything of my former course can deny that it has always been in accordance with this report. I was in favor of that principle in 1851. I am so now; and, under other circumstances, there would be no hesitation at all in a universal assent to the principle enunciated in that report and
              to the adoption of that principle.
            </p>
            <p className="p-in-sp">A committee was raised, however, as I remarked before, with the entire understanding that they were not to report upon this subject until a future session of this Convention. As was remarked by the gentleman from Monongalia [<span className="persName">Mr. WILLEY</span>]
              , some of the Western members have left, and gone home, the understanding being that this subject would not be taken up at this session. Coming up now, however, as it does, I should be willing to act upon it. I have not examined the report of the committee, and the amendments proposed. I do not intend to commit myself to the question whether that report does perfectly embrace what
              ought to be inserted in the Constitution. I am speaking only on general principles. I would be willing to take the opportunity of adopting that, but there are other matters to be considered in connection with this proposition.
            </p>
            <p className="p-in-sp">
              The law under which this Convention was assembled, contains, as was understood throughout this Commonwealth, from one end to the other, a provision declaring that no change in our relations to the Government of the U. States or in the Constitution of Virginia, was to be made unless submitted to the qualified voters of the Commonwealth, and ratified by them. Surely this proposition now
              made will have to be submitted to the vote of the people. The Convention adopted an ordinance, by which our relations to the General Government are changed. That will have to Je submitted to the people. If that action here shall be ratified by a majority of the qualified voters of this Commonwealth, and our connection with the Government of the United States sundered, then, as every
              gentleman must perceive, it will become necessary to change the organic law of the Commonwealth, not merely in this feature, but in regard to many other features of that instrument. All portions of that Constitution which look to a connection <PageNumber num={265} /> with the Government of the United States, all provisions which look to the election of
              members of Congress in Virginia, will have to be changed; and not only in regard to our relations heretofore held with the Government of the United States, but in regard to our own national system, there will necessarily be material changes proposed, which will have to be acted upon by the voters of the State.
            </p>
            <p className="p-in-sp">
              Now, is it necessary, in that state of things, having passed that ordinance of secession, that we should act upon this single isolated amendment of the Constitution, and submit it to the action of the people at the May election? This ordinance will have to be ratified. This Convention will have to submit changes in various portions of the Constitution; and I know no reason why we
              should ad interim and from time to time be submitting propositions to change the Constitution. After the action of the people in May, the plan would be to make all the changes necessary, and submit all to their action.
            </p>
            <p className="p-in-sp">
              Now, in regard to this taxation question, it can avail nothing for the present, under any circumstances. The assessments of the Commonwealth have already been made. Previous to the 1st of February, a year's taxes can in no manner be placed upon negro property in pursuance of this amendment, even if prepared now, and the people adopted it at the May election; so that there is no
              necessity to act upon it in regard to any change of the taxation for the present year.
            </p>
            <p className="p-in-sp">
              I prefer, then, to keep this matter separate until the action of the people in May. Then act upon your State Constitution. We must be governed by the circumstances that then surround us. What new relations are to spring up, none of us can tell. What changes may be necessary after awhile no one can tell. In taking this position, I am not conscious that I represent entirely the interests
              of that portion of the State from which I come. I am, sir, representing the wishes of those that I immediately represent here; for, from the beginning, we intended that this great question we met to consider should not be embarrassed by questions of mere State concern. This I say in explanation of my own views in this matter.
            </p>
            <p className="p-in-sp">The gentleman from Monongalia [<span className="persName">Mr. WILLEY</span>] , has made a motion to lay on the table and print.</p>
            <p className="p-in-sp">I did not understand my friend to say that he was anxious to take it up. I understood him to say that he would leave to Eastern gentlemen to take it up or lay it on the table, as they thought best. If they took it up and adopted it, he would be grateful to them for it.</p>
            <p className="p-in-sp">
              Concurring with my friend from Monongalia, as I do, in no sectional spirit; regarding him as one of the purest and best of men, I <PageNumber num={266} /> would be very unwilling to differ from him in anything. I do say, however, that if this be a matter of right; if it be a true system of taxation, as I believe it to be; if it be due to the people of the
              West to adopt that system of ad valorem taxation, I don't choose to take it as a matter of thankfulness and gratitude at all. I claim to be simply a member of this great Commonwealth of ours, and an humble member of this body, standing here to defend the rights of my constituents; and, so far as I can, looking to the interests of the entire State, I am here to compare opinions with my
              fellow members, and to lend my assistance, heart and hand, to whatever is necessary for this Commonwealth. I have not voted for this ordinance of secession. Action has been had, however, by a majority of this body; and, as a citizen of this Commonwealth, I am for the submission of that action to the people of this State. In order to carry out fully the scheme of this Convention, when
              the people shall have adopted that ordinance, and during the interval of time between the passage of the ordinance to their action upon it, I am for putting this Commonwealth, so far as my feeble efforts may go, in a position of perfect independence.
            </p>
            <p className="p-in-sp">
              I do not intend to go back into an argument for or against the action which has been had. I said not one word during these nearly two days of solemnity that have transpired here. While other gentlemen East and West were rising to explain their positions, I sat silent. In the first place, I felt that there was the same species of solemnity that I would have felt in attending the funeral
              of the mother of my family. I was silent again, for the reason that I knew no voice would change the result. The ordinance of secession received the votes of the majority of this body; and from that moment, so far as I am concerned, I am ready, by my vote, to forward any measure that may be necessary to get us through our difficulties successfully. I trust that those who have supported
              this ordinance will not regard those who voted against it as having no lot in the conflict which is to go on in this Commonwealth. It is true the responsibilities are upon those who passed the ordinance. For all the action that is taken here, there is a responsibility attaching to us all. I only hope that whatever we find it necessary to do; whatever the exigencies of our position may
              require at our hands, we shall do it in harmony; we shall do it in a mode that will not be inconsistent with the past of this old Commonwealth. Let us do it in such a manner as that it shall at least look well in future history.
            </p>
            <p className="p-in-sp">I have been borne along to say what I had no expectation to say when I rose. While up I will say, that thrown as we are into this <PageNumber num={267} /> most trying position, called upon to perform duties to this old Commonwealth yet dear to us all, let us not cut off the glories of the past or the hopes of the future.</p>
            <p className="p-in-sp">
              Mr. President, is it possible that this glorious country of ours is to be overrun by blood and carnage. This Union is dissolved; but may we not yet hope to part in peace? Is it consistent with the civilization of the age in which we live, to indulge a thought that such a separation is hopeless? We are involved in a civil war, more bloody than the pages of history have yet disclosed, if
              it is to be carried on, to end, we know not when, with the subversion and destruction of every interest of every portion of your people; and, it may be, under some new despotic form of government.
            </p>
            <p className="p-in-sp">
              May we not indulge a hope that Providence yet has in reserve for us some means by which this calamity may be avoided? I have no suggestions to make. We are all, it would seem, at sea without a helm. We are upon the waves, floating on-I know not where; but as a citizen of this Commonwealth-as a Christian man-I yet indulge a trust in that Providence which never yet has deserted our
              fathers or their posterity. Although in hostilities, are we to give up all hope that nothing but a long train of bloody war is to bring us through this contest? We are not without foundation for hope in the examples of history. Very recently, in the history of Europe, an example is presented, from which we may take some gleam of hope. In 1847 the ancient Confederation of the Hartz
              Swiss Cantons was disrupted. Seven of these Cantons withdrew from all connection with the remaining seventeen. A bloody war ensued, many lives were lost, and an amount of treasure expended infinitely beyond anything that occurred with us. Actually, raging war ensued, and was continued a considerable time. But even under circumstances so inauspicious as these, moments of calm reflection
              ensued. A re-union was formed; the seven Cantons seceding and the seventeen remaining came again into harmony, and that union and that harmony has been progressing favorably from that day to this. They have a more firm Union now than they had then. It is true, we have interests more diverse in their character than the Swiss Cantons; but there are at least fifteen States of this Union
              that have common interests, and when they shall be brought to act together, a peaceful solution of these difficulties may be accomplished. A re-union of all the States of this Confederacy is possible. It is yet possible that the interests of every portion of this Confederacy may be preserved. I shall not dismiss from my bosom a lingering ray of hope that still abides there.
            </p>
            <PageNumber num={268} />
            <p className="p-in-sp">
              I have an abiding hope that we yet shall emerge from these difficulties. But, in the meantime, let us act as our fathers acted-let us be true to ourselves, true to our country, true to the present and the future. Whatever we do, let it be done in harmony with our past history and our past glory. For one, I am ready to unite in every legitimate mode of action to place Virginia where she
              ought to be.
            </p>
            <div className="speaker" id="sp4101"><span className="persName">Mr. JAMES W. SHEFFEY</span>, of Smyth—</div>
            <p className="p-in-sp">I have heretofore sat silent and calm, and have listened again and again to gentlemen who sought to throw the responsibility upon those who passed this ordinance of secession, for the consequences that now exist and that are about to befall the country.</p>
            <div className="speaker" id="sp4102"><span className="persName">Mr. RANDOLPH</span>, of Richmond city—</div>
            <p className="p-in-sp">Will the gentleman give way for a moment to enable the Committee on Military Affairs to make a report.</p>
            <div className="speaker" id="sp4103"><span className="persName">Mr. SHEFFEY</span>—</div>
            <p className="p-in-sp">If it is important I will.</p>
            <div className="speaker" id="sp4104"><span className="persName">Mr. RANDOLPH</span>—</div>
            <p className="p-in-sp">There are two ordinances about to be reported by the Military Committee, which it is very important, should be passed, so that they may be acted upon by the Governor to-night.</p>
            <div className="speaker" id="sp4105"><span className="persName">Mr. WISE</span>—</div>
            <p className="p-in-sp">While this question of taxation is under consideration, I will offer this amendment to the report of the Committee submitted by the gentleman from Monongalia [<span className="persName">Mr. WILLEY</span>] , and ask that it be laid upon the table and printed with the report.</p>
            <p>The amendment is as follows:</p>
            <p>"Provided, however, that the same rate of ad valorem tax imposed upon land shall be imposed upon slaves, and the same that is imposed upon slaves shall be imposed upon land, and that neither land nor slaves shall be exempted without exempting the other."</p>
            <div className="speaker" id="sp4106"><span className="persName">Mr. SCOTT</span>, of Powhatan—</div>
            <p className="p-in-sp">I move to postpone the further consideration of the report of the Committee on Taxation.</p>
            <p>The motion was agreed to, whereupon</p>
            <p><span className="persName">Mr. SCOTT</span> submitted the following reports from the Committee on Military Affairs:</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>
                  "Be it ordained, That the office of commander of the military and naval forces of the State with the rank of Major-General, be and the same is hereby created. The said commander shall be appointed by the Governor of the Commonwealth, by and with the advice and consent of this Convention, and, in subordination to the Governor, shall take charge of the organization and operations of
                  all the military and naval forces which may be called into the service of the State.
                </p>
                <p>Be it further ordained, That the said commander shall take rank <PageNumber num={269} /> and precedence over all other military and naval officers of the State, without regard to date of commission."</p>
              </div>
            </div>
            <div className="speaker" id="sp4107"><span className="persName">Mr. SCOTT</span>, of Powhatan—</div>
            <p className="p-in-sp">I have been instructed by the committee to report the following resolution, in connection with the ordinances just submitted :</p>
            <p className="p-in-sp">Resolved, That the ordinance providing for the appointment of a Commander-in-chief of military and naval forces of the Commonwealth, with the ordinance providing for calling out the volunteer forces of the State, be communicated in confidence to Col. Robert E. Lee.</p>
            <div className="speaker" id="sp4108"><span className="persName">Mr. JAMES B. MALLORY</span>, of Brunswick—</div>
            <p className="p-in-sp">I call the previous question.</p>
            <p>The call was sustained, and the main question ordered to be put, which was on the ordinances reported by the Military Committee.</p>
            <p>The question was then taken, and the ordinances were adopted.</p>
            <p><span className="persName">The PRESIDENT</span> announced the following committee under the resolution of Mr. Preston ordering a reference of the ordinance of secession and preamble to a committee, who shall also prepare a schedule for the submission of said ordinance and preamble to the people:</p>
            <p>Messrs. Wm. Ballard Preston, of Montgomery; R. E. Scott, of Fauquier; H. A. Wise, of Princess Anne; Wm. H. Macfarland, of Richmond ; James P. Holcombe, of Albemarle; James Barbour, of Culpeper; and Charles R. Slaughter, of Campbell.</p>
            <div className="speaker" id="sp4109"><span className="persName">Mr. WISE</span>—</div>
            <p className="p-in-sp">I beg you to excuse me from serving on that committee. I am compelled, because of my own illness and the situation of my family, to leave for home to-morrow.</p>
            <p><span className="persName">Mr. WISE</span> was excused and <span className="persName">Mr. PRICE</span>, of Greenbrier, appointed in his stead.</p>
            <div className="speaker" id="sp4110"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">I beg leave to submit to the Convention a telegraphic despatch received from the President of the Confederate States.</p>
            <p>The Secretary read the despatch.</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>MONTGOMERY, April 19,1861.</p>
                <p>Hon. JOHN LETCHER, Governor of Virginia:</p>
                <p>Resolution for alliance received and proposition cordially accepted. A Commissioner will be sent by next train.</p>
                <p>JEFFERSON DAVIS.</p>
              </div>
            </div>
            <div className="speaker" id="sp4111"><span className="persName">Mr. WISE</span>—</div>
            <p className="p-in-sp">I am authorized to say that I have information directly <PageNumber num={270} /> from Montgomery also, that there are upwards of 2,000 troops on their march to Norfolk to assist us.</p>
            <div className="stage it">[Applause.]</div>
            <div className="speaker" id="sp4112"><span className="persName">Mr. R. Y. CONRAD</span>—</div>
            <p className="p-in-sp">My attention has been called to the fact to-day, that the Committee on Federal Relations have a clerk who is still entitled to the per diem. The business of the committee has been for some time discharged substantially, and I therefore offer the following resolution:</p>
            <p className="p-in-sp">'Resolved, That the Committee on Federal Relations be discharged from the further consideration of all resolutions heretofore referred to them and not acted upon, and it is further ordered that the services of the clerk of that Committee be dispensed with.</p>
            <div className="speaker" id="sp4113"><span className="persName">Mr. WISE</span>—</div>
            <p className="p-in-sp">I hope the latter part of that resolution will not be adopted.</p>
            <div className="speaker" id="sp4114"><span className="persName">Mr. CONRAD</span>—</div>
            <p className="p-in-sp">There will be no difficulty in appointing a Clerk, whenever we need one. We have had a Clerk for now nearly a fortnight receiving pay without doing anything.</p>
            <p>The resolution was adopted.</p>
            <p>The question was then put on the resolution reported by the Committee on Military Affairs, directing the Governor to communicate, in confidence, to Col. Lee the ordinances referred to in that resolution, and it was agreed to.</p>
            <p>It was ordered that copies should be sent to Gen. Johnston.</p>
            <div className="speaker" id="sp4115"><span className="persName">Mr. SCOTT</span>, of Powhatan—</div>
            <p className="p-in-sp">I beg leave to report the following ordinance from the Committee on Military Affairs, in conformity with the resolution offered to-day by the gentleman from Prince William [<span className="persName">Mr. HUNTON</span>] :</p>
            <p className="p-in-sp">"Be it ordained, That the Governor of the Commonwealth be authorized to appoint as many aids, with the rank of Lieutenant of Cavalry, as the necessity of the service may require."</p>
            <p>The ordinance was adopted.</p>
            <p><span className="persName">Mr. SCOTT</span>, from the same Committee, submitted the following resolution, which was adopted :</p>
            <p>"Resolved, That the Military Committee be authorized to depart so far from the injunction of secrecy as to consult in committee, but in confidence, with such military men as they may deem advisable."</p>
            <div className="speaker" id="sp4116"><span className="persName">Mr. SCOTT</span>—</div>
            <p className="p-in-sp">At the instance of the committee, I move that it be enlarged by the addition of two members.</p>
            <p>The motion was agreed to.</p>
            <p>Messrs. <span className="persName">C. B. CONRAD</span>, of Gilmer, and CALEB BOGGESS, of Lewis, were added to the committee.</p>
            <PageNumber num={271} />
            <div className="speaker" id="sp4117"><span className="persName">Mr. WISE</span>—</div>
            <p className="p-in-sp">I offer this resolution:</p>
            <p className="p-in-sp">
              "Be it ordained, That an Executive Committee of Safety, consisting of five, to be appointed by the votes of this Convention, be, and they are hereby constituted, to aid and assist the Governor of the Commonwealth in organizing and executing all the ways and means of public defence. They shall organize and construct, particularly an Adjutant General's Department, a Commissary General's
              Department, an Inspector General's Department and a Bureau of Ordnance ; and, in addition to this, they shall organize a department to be called the Department of Private Arms; to cause a return to be made, as near as practicable, of the number of arms and of the amount of munitions of war in the hands of private individuals.
            </p>
            <p className="p-in-sp">
              SEC 2D. In appointing the heads of said Departments and Bureau, persons experienced in military affairs or who have served in the army or navy of the United States and graduates of the Military Academy of the United States, or of the Military Institute of Virginia, shall be preferred. And in organizing said Departments and Bureau, the plans of similar departments and bureaus of the
              United States Government shall, in general, be observed, and a regular system of reports and records shall be devised.
            </p>
            <p className="p-in-sp">
              SEC 3D. And the said committee shall have power to appoint a Secretary, and shall keep a record of its proceedings; and it shall have power to sit during the sessions and in the recess of this Convention; and should, in conjunction with the Governor, or in case of his absence, or death or resignation, have power to make all necessary appointments and to apply all appropriations; and,
              in all other respects, shall have power to act for the defence of the State, and, if necessary, to decide when this Convention shall be called to re-assemble in case of recess, and they shall, in the recess of this Convention, have power to fill vacancies in their own body, and any three of them shall be a quorum, with power to act in the absence of the other two of their number."
            </p>
            <div className="speaker" id="sp4118"><span className="persName">Mr. WISE</span>—</div>
            <p className="p-in-sp">I ask that that be laid upon the table and printed.</p>
            <div className="speaker" id="sp4119"><span className="persName">Mr. BALDWIN</span>—</div>
            <p className="p-in-sp">I would suggest that it be referred to the Committee on Military Affairs.</p>
            <div className="speaker" id="sp4120"><span className="persName">Mr. WISE</span>—</div>
            <p className="p-in-sp">
              The Committee on Military Affairs, I think, should have nothing to do with our civil organization. This is a matter that relates to the Governor. A committee of safety appointed by this Convention ought not only embrace military affairs but departmental organizations also. Let me tell you, what I experienced during the John Brown raid. What was the state of your organization at that
              time?
            </p>
            <PageNumber num={272} />
            <p className="p-in-sp">
              You had an Adjutant General-aged, infirm and alone to do all the duty of Adjutant General, Commissary General, of the Ordnance Department and Inspector's Department. As my friend Gen. SCOTT of Powhatan knows, when I went to Harper's Ferry, I had to perform every one of those myself. It was utterly impossible for the Adjutant General, with the force you had to assist him, to keep pace
              with the telegraphs, let alone the correspondences. He did his duty faithfully and well; and as well as one lone, old man could do it. It is very difficult for the State to conduct her affairs with the organization which she now has; and I have therefore endeavored to furnish such a plan of organization as I thought would suit the emergency-I have taken the very organization of the
              United States Departments-their bureaus, &amp;c, for our army. It will be no more costly than is necessary-I don't think it will be as costly as the present system; because the extra aid that you have to hire, would more than pay the regular salaries of a sufficient number to do the work. I have experience in this matter. I can speak from a knowledge of the extraordinary labors that I
              myself had to perform during the time of that little campaign at Harper's Ferry. I had to find an Assistant Adjutant General, and with the aid of a Major General, the only General in the State who did tender his services to me. I am I think in error. Let me say for one who sits before me, that he did tender his services
              [Gen. EPPA HUNTON, of Prince William, the reporter believes] . No, sir; there are two in this body who tendered their services-I mean the gentlemen on my left and right [Gen. SCOTT of Powhatan, and Gen. HUNTON as the reporter thinks] . Withal, I found that the work could not be done in such a manner as to save the Commonwealth from extraordinary expense. The cost of that little raid, was at least from 20 to 30 or 50 per cent more than it ought to be owing to the want of anything like an efficient organization, before the emergency arose.</p>
            <p className="p-in-sp">
              Now, these Departments are just what you do want. It may be that one of the departments, that of private arms, that I have named there, may be found to be unnecessary; but I believe it will be found upon reflection to be one of the most vital of all. I think it will be well to have a department here to look around and see where the private arms now wanted could be collected; where
              their calibre could be compared, and where they could be selected with reference to their uniformity. There are thousands of revolvers of every kind which really are not wanted by many families in the State, unless where there are some of their members who intend to go into service. An immense number of arms, which would add materially to our armory, can be
              <PageNumber num={273} /> obtained, if you will only appoint somebody to look after them. It is due to the people in the West of this State to say, that if they defend themselves, they will have to do it with their own private arms for they have not been furnished with any arms, except a few sabres, that I was enabled to supply them during the time that I
              filled the Executive chair, and with the exception of one or two or three batteries of ordnance, which with difficulty I was enabled to send to that section. I am sorry to say, that I heard to-day, that one of your Brigadier Generals has gone home with the avowed declaration, that he shall cross the Ohio river, and go into Ohio,, and appeal to people there to be neutral in this war,
              and promise them that the people of North western Virginia will also remain neutral.
            </p>
            <p className="p-in-sp">
              I refer to Gen. JACKSON. I am assured by gentlemen who heard him express himself upon the subject, that that was the policy he determined upon. I shall not be surprised to hear, after such a declaration as this, that Parkersburg will soon be in the hands of the enemy. I was shocked, and very much distressed when I was informed of it this morning. I am gratified, however, that I did
              send some guns to the West to defend their own people.
            </p>
            <p className="p-in-sp">
              I say you have need to look after your organization now more than any people have on the face of the earth. This morning I prepared that ordinance. Although it is roughly drawn yet I believe, sir, that it sufficiently covers the object in view, and I merely ask that it be laid upon the table and printed, so that gentlemen may see if something better can be done. I would say that the
              most essential thing you can do now is to adopt that ordinance. I would not commit this duty into the hands of any man, I don't care who it is.
            </p>
            <p className="p-in-sp">
              The Governor of Virginia cannot carry on the war unless this organization is complete. He cannot buy cannon and small arms, clothing, provisions, and the various other requirements necessary for an army, without an efficient organization. All these matters will involve double the ordinary expense unless they are properly attended to. You want a commissary general, an adjutant general,
              an inspector general. You want an ordnance department. You want every department that experience has shown to be needed, every department, in fact, that they have had at Washington, and I would adopt at once the organization they had there; and if you obtain the valuable services of Colonel Lee or General Johnston, they will find the system arranged which they perfectly understand, and
              will know how to conform to it all the details of the army. I do think that you will neglect a beneficial service if you fail to adopt an organization of this kind, <PageNumber num={274} /> and as I will leave in the morning, I could not do so, even hurriedly as it is, without bringing the attention of this Convention to this all-essential and important
              matter.
            </p>
            <div className="speaker" id="sp4121"><span className="persName">Mr. ECHOLS</span>, of Monroe—</div>
            <p className="p-in-sp">
              There are one or two objections which seem to me to exist to the motion of the gentleman. We are in secret session, and it is understood that all that transpires here is to be kept secret. It would be rather difficult, when that paper is sent to the printer, to have the injunction of secrecy maintained. This is a very important matter, and it seems to me that it ought to be referred to
              some committee.
            </p>
            <div className="speaker" id="sp4122"><span className="persName">Mr. BALDWIN</span>, of Augusta—</div>
            <p className="p-in-sp">I move that it be referred to the Committee on Military Affairs.</p>
            <p>Pending that motion-</p>
            <div className="speaker" id="sp4123"><span className="persName">The PRESIDENT</span> said—</div>
            <p className="p-in-sp">There was a committee ordered to be raised on yesterday, on the manufacture of gunpowder; but it has been suggested that the subject be referred to the Committee on Military Affairs.</p>
            <div className="speaker" id="sp4124"><span className="persName">Mr. FRENCH</span>, of Mercer—</div>
            <p className="p-in-sp">I move that it be referred to that committee.</p>
            <p>The motion was agreed to.</p>
            <div className="speaker" id="sp4125"><span className="persName">Mr. FORBES</span>, of Buckingham—</div>
            <p className="p-in-sp">I was unexpectedly called from the hall when the vote was being taken on the ordinance reported by the Committee on Military Affairs, in relation to the transmission of the ordinance creating a Major General, &amp;c., to Col. Lee. I desire to say that, had I been present, I would have voted for that ordinance.</p>
            <p className="p-in-sp">I now move that the Convention take a recess until 5 o'clock.</p>
            <div className="speaker" id="sp4126"><span className="persName">Mr. Wm. P. CECIL</span>, of Tazewell—</div>
            <p className="p-in-sp">I move to amend that motion by moving to adjourn.</p>
            <p>The motion was agreed to-ayes 49, noes 31, and the Convention accordingly adjourned at quarter to four o'clock, P. M.</p>
            <div className="speaker" id="sp4127"><span className="persName">Mr. SUTHERLIN</span>, of Pittsylvania, before the members had left their seats, said—</div>
            <p className="p-in-sp">I want to state to the Convention that I was in the Ordnance Department to-day, when several gentlemen came in to enquire for arms. It was ascertained that there were none to be had.</p>
            <p className="p-in-sp">I wish to say that there is a gentleman now here from Louisiana, who is come prepared, if he can find any one to co-operate with him, to establish a manufactory of arms in Richmond. If he can procure this co-operation, he will be prepared to furnish arms in a few months.</p>
          </div>
          <div className="day" id="vsc1965.v4.2.5">
            <PageNumber num={275} />
            <h2><span className="headingNumber">1.5. </span><span className="head">FIFTH DAY</span></h2>
            <div className="dateline">Saturday, <span className="date">April 20</span></div>
            <p>The Convention met at 12 o'clock.</p>
            <div className="speaker" id="sp4128"><span className="persName">Mr. NEBLETT</span>, of Lunenburg—</div>
            <p className="p-in-sp">I will state to the Convention that Mr. Thos. F. GOODE, of Mecklenburg, is absent from his seat, having returned home to take command of a volunteer company, of which he is captain.</p>
            <div className="speaker" id="sp4129"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">The first business now in order is the report of the Committee on Taxation, presented on yesterday, by the gentleman from Monongalia [<span className="persName">Mr. WILLEY</span>] .</p>
            <div className="speaker" id="sp4130"><span className="persName">Mr. WILLEY</span>—</div>
            <p className="p-in-sp">At the request of some of the friends of this proposition, with whom I have consulted, I beg leave to withdraw the motion to lay on the table and print. I submit the matter, at once, to such action as this Convention may deem proper to take.</p>
            <div className="speaker" id="sp4131"><span className="persName">Mr. SAMUEL WOODS</span>, of Barbour—</div>
            <p className="p-in-sp">
              On yesterday morning, sir, when the report of the Committee was presented to the consideration of this Convention, I was not prepared to hear the motion which was offered and discussed by the Chairman of the Committee on Taxation, and by the distinguished gentleman from the county of Kanawha. I felt very much disappointed, and I confess, exceedingly chagrined, that, notwithstanding we
              had reached that period when the necessity for a change in our organic law, contemplated by the report of the Committee, was made apparent to every man East and West, North and South, in Virginia, and when almost every possible objection to the adoption of this proposition was removed by the altered circumstances of the people of this Commonwealth, in relation to the Federal
              Government-I say I was disappointed and chagrined at finding the Chairman of the Committee and the distinguished gentleman from Kanawha
              [<span className="persName">Mr. SUMMERS</span>] , presenting the subject in such a manner as to ensure its defeat.</p>
            <div className="speaker" id="sp4132"><span className="persName">Mr. JOHN R. CHAMBLISS</span>, of Greenesville—</div>
            <p className="p-in-sp">I desire to know whether the subject now under consideration is the report of the Committee or the amendment of the gentleman from Princess Anne [<span className="persName">Mr. WISE</span>] . He offered that amendment with the understanding that the report and amendment were to be printed, and expected that no action would be had upon the report until he returned. I suppose the amendment is the subject now under consideration.</p>
            <div className="speaker" id="sp4133"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">The amendment of the gentleman from Princess Anne is not before the Convention. When he offered it, it was not in <PageNumber num={276} /> order. He merely meant to lay it on the table and have it printed for information.</p>
            <div className="speaker" id="sp4134"><span className="persName">Mr. WOODS</span>—</div>
            <p className="p-in-sp">I understand, then, that the report of the Committee is the only question before the Convention.</p>
            <p className="p-in-sp">
              I was remarking, sir, that I felt chagrined when I found the report presented under circumstances, as I understood, indicating its defeat. I have been equally chagrined at seeing members of this Convention from the region of country which I have the honor in part to represent, endeavoring to shirk, if possible, a vote upon this question by absenting themselves at this time from the
              Convention.
            </p>
            <p className="p-in-sp">
              My position on the great question which engrossed our thoughts and attention heretofore is well understood by this Convention. I have taken occasion upon every proper opportunity to indicate to the members of this Convention, privately and publicly, that the rights of the country from which I come require and demand that this question shall be adjusted; and when I found objections
              coming from a portion of the State where we had least expected them to come from, I must acknowledge I never had been more mortified than to find my own friends leaving their seats and going home, thus hazarding the success of this proposition, which, whatever might have been the opinion of gentlemen heretofore, must have impressed itself upon the minds of this Convention as necessary
              for the unity, peace and harmony of the State of Virginia. I felt equally chagrined when I learned that members of this Convention from Eastern Virginia were willing to favor the very principle which the originators of that movement vindicated; but which they now seem indifferent to. I hail it as a good omen that this question was brought forward by Eastern gentlemen. For myself, I
              never have been much impressed by the advantages that some thought we would derive from this thing. Believing it, however, to be right in principle, I felt that it ought to be allowed, and that the change ought to be made and promptly made by those who were interested in the kind of property under consideration. But I expected that Western gentlemen would, in this Convention, stand up
              to what they must know and feel to be the sense of their people. I admit that in some sections of the State, they allow this great question of Federal relations for the present to cover it up; but I have never found anybody, who, when the question was presented, would be excused, much less authorized or justified in avoiding a fair and manly consideration of the question.
            </p>
            <p className="p-in-sp">
              I am willing, so far as I am individually concerned, to take the report of the Committee; because, as I understand, it justly presents it <PageNumber num={277} /> as a necessity required by my people. I acknowledge that there are other crying defects in the Constitution that ought to be remedied, and that I believe this Convention will remedy before it
              adjourns. But I am not prepared to adjourn without doing justice in this one particular, because there are other questions which it is not now expedient for this Convention to determine. I am not at all favorably impressed with the arguments of the gentleman from Kanawha upon this subject. I know that the weight of his name carries with it considerable influence, and is calculated to
              give force and effect to any argument he may use; but I hardly think it will avail in this instance against the manifest justice and conceded propriety of the claim set up here on the part of the people of his section and of the whole West. Standing here upon a platform that a due sense of my obligations to my constituents requires me to stand upon, I am not to turn aside from the
              discharge of my duty, because forsooth it may not be possible to discharge now all the duties which devolve upon us. It may be possible that we may have other business that may prevent us from meeting here again, and adjusting the other questions which are deemed necessary to be acted upon. But that is no reason why we would not now settle this question.
            </p>
            <p className="p-in-sp">
              The difficulty under which these gentlemen seem to labor, that we will be compelled, at different times, to submit different portions of this Constitution to the people, is not solid. In my opinion, this question can be submitted to the people in the coming election, and although we may not derive any benefit from it now or at any time, yet I desire to put all the property of the
              Commonwealth in a position to pay its due proportion of taxes. This is a principle which ought to commend itself not only to the patriotism, but justice of every member of this Convention East and West. I know very well that some of our members have gone home, and it may be under the impression that nothing was to be done now on this question; but that at some future time, when this
              Convention was to have an adjourned session, it would be considered. It is, perhaps, unfortunate that these gentlemen have absented themselves from their duties at this particular time; but we have only to trust to the sense of justice of our Eastern friends to grant us what we believe to be our just rights.
            </p>
            <p className="p-in-sp">
              Sir, this measure ought to be adopted. No good reason has yet been shown why it should not be adopted. I do not believe that any good reason can be shown. If there be anything desirable in having the sentiment of the State united, it seems to me that, at this particular juncture, it behooves every man to come up to this question and to do justice to all. I trust that, notwithstanding
              the fact that many members <PageNumber num={278} /> from Western Virginia have departed to their homes, the action of this Convention will forever forestall the possibility of creating disturbances in any part of the State, by calling to the attention of the people directly interested in the settlement of this question, the fact that it was brought up
              here for consideration and rejected. Every member here owes it to his State and people that this question should be settled and peace and harmony secured in the Commonwealth. But, while I differ from the distinguished gentleman from Kanawha
              [<span className="persName">Mr. SUMMERS</span>]
              , in his sentiments on this question, I concur with him in other sentiments in regard to the question of peace, I think the only question between the majority and minority here is, that we have reached the same end at different periods. I trust that, whatever of discord or distrust any of us may have felt or imagined in anything we may have done, now, as a common peril is upon us, we
              will ignore all past dissensions and differences, and unite for the common good. Every effort has been made to preserve the peace of the country. The whole country knew and felt that there was a majority in the Virginia Convention of Union men so strongly entrenched in their positions that nothing but the force of arms would drive them from it. I trust that we will let no question
              distract us at home, and notwithstanding our political horizon may be dark to-day, yet, I humbly trust that that Providence that has hitherto guarded us, may still extend over us the shadow of his wings until this great difficulty is at an end.
            </p>
            <p className="p-in-sp">I think I ought to appeal again to every member of this Convention to settle this question now and forever. I did not expect to trouble this Convention now or at a future time; but finding myself deserted by friends in Western Virginia, I felt myself justified in appealing to this Convention to come up and dispose finally of this question.</p>
            <div className="speaker" id="sp4135"><span className="persName">Mr. TREDWAY</span>, of Pittsylvania—</div>
            <p className="p-in-sp">Is an amendment in order?</p>
            <div className="speaker" id="sp4136"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">Certainly, sir.</p>
            <div className="speaker" id="sp4137"><span className="persName">Mr. R. E. SCOTT</span>, of Fauquier—</div>
            <p className="p-in-sp">There was a committee appointed yesterday, charged with the duty of preparing a schedule for the submission of the Ordinance to the popular vote. The day of election is not far off, and unless the schedule is immediately acted upon it cannot be distributed through the Commonwealth so as to give the people a fair opportunity to vote upon the question at the polls.</p>
            <p className="p-in-sp">I would respectfully suggest that this matter about the tax, which is now occupying the time of the body, shall be laid aside for the present, until this necessary part of our business shall be disposed of. I would suggest to the gentleman having it in charge to pass it by for the present.</p>
            <PageNumber num={279} />
            <div className="speaker" id="sp4138"><span className="persName">Mr. WILLEY</span>, of Monongalia—</div>
            <p className="p-in-sp">The members of the Convention will judge whether the reference made to me by the gentleman from Barbour [<span className="persName">Mr. WOODS</span>] has been justified by any remarks made by me in the course of my observations yesterday. If the gentleman from Barbour had given me his attention then, he never would have made the allusion to me he has made.</p>
            <p className="p-in-sp">
              I have always been anxious for this proposition, and I stood yesterday morning, where I ever stood, on a platform of good faith to all the members of this Convention. I introduced that report with the understanding, which was distinct when the resolution which I introduced was passed, that this matter was not to be taken up until the adjourned session, and therefore I could not,
              without violating good faith on my part, urge the adoption of that measure now.
            </p>
            <p className="p-in-sp">
              But I also stated the fact that, notwithstanding this previous arrangement to which I have alluded, Eastern gentlemen have suggested to me, as the chairman of that committee, the propriety of getting the committee together and presenting the report to the Convention. Acting upon this suggestion, I did so, and laid before that committee the identical proposition reported. It was brought
              forward on the motion of a member of that committee from the East, and I was ordered to report it to the Convention. Under these circumstances, I made the report, accompanied with the statement which I submitted on yesterday.
            </p>
            <p className="p-in-sp">The Convention will remember that I made this statement, and will also remember that I expressed myself as being ready to render thanks, and to express my obligations to Eastern gentlemen if they would take up that report and pass it, and allow it to be submitted to the people.</p>
            <p className="p-in-sp">But the Western friends of the measure have gone home; many of them, no doubt, with the understanding that this matter was not to be taken up now. Certainly the gentleman from Barbour [<span className="persName">Mr. W</span>000s]
              does me great injustice when he intimates, directly or indirectly, that I do not desire a consideration of that proposition. But, ardently as I desire it, I will not violate my pledged faith to the members of the Convention. I say now, to the Eastern members, that I would be under great obligations to them if they would pass it. I have received information from a neighbor, this
              morning, that makes it necessary for me to go home to protect my children, who are left without the support of either father or mother. I will go, unless you hang me; and I suppose that the same motives operated upon other gentlemen living on the border. I will not attribute to these gentlemen such motives as have been attributed to them by the gentleman from Barbour
              [Mr. <PageNumber num={280} /> WOODS]
              . I stated yesterday that I would not presume to present this measure to be adopted, from motives arising out of the present exigency. I appeal to no such consideration or motives, but directly to the sense of justice of this Convention. I place the proposition on that ground, and no other. I so stated to the committee. I stated, since then, that unless they passed this measure, I
              would beg them not to take it up until all our friends are here. We come with a proposition that is just to the West, and also to the East. You can pass it, or you can reject it. If the whole delegation from the West were here now, it is improbable that we could pass it without the aid of Eastern members. I repeat now, what I stated to many gentlemen from the East, not to take up this
              proposition unless you intend to pass it. If you pass it, you will place me and my constituents under a lasting obligation; not because we are suppliants; not because we are begging you to do it; but because we are demanding at your hands a principle which we believe to be just, right and proper.
            </p>
            <p className="p-in-sp">Having made this statement, I will commit the further management of this proposition to my friends from the West and East.</p>
            <div className="speaker" id="sp4139"><span className="persName">Mr. J. B. MALLORY</span>, of Brunswick—</div>
            <p className="p-in-sp">I am of those who are not disposed to express any opinion upon this subject. I don't think this question ought to be acted upon now with so thin a House.</p>
            <p className="p-in-sp">I move to lay the whole subject on the table.</p>
            <div className="speaker" id="sp4140"><span className="persName">Mr. WILLEY</span>—</div>
            <p className="p-in-sp">I ask for the yeas and nays on that motion.</p>
            <p>The call was sustained.</p>
            <p><span className="persName">Mr. MALLORY</span> withdrew his motion.</p>
            <div className="speaker" id="sp4141"><span className="persName">Mr. HUNTON</span>, of Prince William—</div>
            <p className="p-in-sp">I move that the consideration of the report be passed by for the present.</p>
            <div className="speaker" id="sp4142"><span className="persName">Mr. BROWN</span>—</div>
            <p className="p-in-sp">Is that in order?</p>
            <div className="speaker" id="sp4143"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">Yes, sir.</p>
            <p>The question was then taken on the motion to pass by, and it was agreed to.</p>
            <div className="speaker" id="sp4144"><span className="persName">Mr. HARVIE</span>, of Amelia—</div>
            <p className="p-in-sp">I beg leave to offer the following resolution:</p>
            <p className="p-in-sp">"Resolved, That the Governor of the State be requested to inform this body, whether the invitation to the 'efficient and worthy Virginians, and residents of Virginia, in the army and navy of the United States to return therefrom, and to enter the service of Virginia,' has been communicated to said officers or any of them, and what response, if any, has been made."</p>
            <div className="speaker" id="sp4145"><span className="persName">Mr. HARVIE</span>—</div>
            <p className="p-in-sp">
              I consider the State of Virginia at this time in a condition of utter and absolute want of defence. I have no more interest in this matter than any other citizen of Virginia; but I have <PageNumber num={281} /> more feeling upon this subject, perhaps, than many others. I feel that there are members of my family in imminent peril in this State, without
              being under the control of proper military officers. We passed a resolution here the other day requesting the Governor to invite efficient and worthy Virginians in the army and navy of the United States, to retire therefrom and to enter the service of Virginia, and yet, up to this time, I have heard of no response given to this extraordinary invitation.
            </p>
            <p className="p-in-sp">
              For my part, I am unwilling to be subjected to this delay because of mere points of etiquette, or doubts as to whether it would be better for these officers to come here or to remain where they are. There is no time now for reflection. Decisions must be prompt or we must suffer in the important matter of our military organization. I am speaking for the protection of my State; I am
              speaking for the protection of the lives of the citizens of the State, when I demand that this Convention shall take every means in its power to protect its interests, its honor, and the lives of its people; I desire that this Convention shall call upon the Governor and ask him whether he sent this invitation, and if so, what response has been given. If the Governor shall say that
              there has been no response, I will then propose that invitation be extended to the President of the Confederate States, to come at once to the State of Virginia, and take charge of the military preparation of the State. We know that that gentleman is competent-that he is faithful. We know that he will come, at least, we may safely presume he will. I am willing to trust the destiny of
              the State of Virginia to him. I am willing to trust the lives of my children into his hands.
            </p>
            <p className="p-in-sp">I am anxious that the Convention shall take action upon this subject before it adjourns to-day. I must beg pardon of my friend from Montgomery [<span className="persName">Mr. PRESTON</span>] , for having interposed with this proposition and prevented him from submitting his report. I am sure he will excuse me in view of the importance of this matter.</p>
            <div className="speaker" id="sp4146"><span className="persName">Mr. JAMES B. DORMAN</span>, of Rockbridge—</div>
            <p className="p-in-sp">I merely beg leave to say that I endorse every word that has fallen from my friend from Amelia [<span className="persName">Mr. HARVIE</span>] , and I am satisfied that my people will sustain me in carrying out the line of policy he has indicated.</p>
            <div className="speaker" id="sp4147"><span className="persName">Mr. EARLY</span>, of Franklin—</div>
            <p className="p-in-sp">
              I agree with the gentleman, that we are in a most deplorable condition, not only in regard to military matters but in regard to many other important things. The Convention has not yet removed the injunction of secrecy in respect to the ordinance <PageNumber num={282} /> authorizing the Governor to invite these gentlemen into the service of Virginia.
            </p>
            <div className="speaker" id="sp4148"><span className="persName">Mr. HARVIE</span>—</div>
            <p className="p-in-sp">The Convention authorized the Governor to extend the invitation, and that, it would seem, is sufficient.</p>
            <div className="speaker" id="sp4149"><span className="persName">Mr. EARLY</span>—</div>
            <p className="p-in-sp">
              The Governor is at a loss to know where these officers are to be found. I know of no means of conveying this invitation to the officers, in the present uncertain state of the mails, except by special messengers. I think the military committee should be asked to report that the injunction of secrecy be removed, so far as it relates to this ordinance authorizing the Governor to extend
              those invitations. I understand that some gentlemen from the Navy, have reported themselves to the Governor. I understand that no direct approaches have been made to any of them. The object of the ordinance passed yesterday, was to send direct to some military officers, who would aid in forming some plan of campaign. I trust that the Convention will permit the chairman of the Committee
              on Military Affairs to submit a report removing the injunction of secrecy from the ordinance to which I refer.
            </p>
            <p>The resolution offered by <span className="persName">Mr. HARVIE</span> was then adopted.</p>
            <div className="speaker" id="sp4150"><span className="persName">Mr. JOHN B. BALDWIN</span>—</div>
            <p className="p-in-sp">I rise to a question of order.</p>
            <p className="p-in-sp">On yesterday, the gentleman from Princess Anne [<span className="persName">Mr. WIsE</span>] , offered an ordinance, and moved that it be laid upon the table and printed. Pending that motion, the House adjourned. I submit that that is the proposition in order now. The motion of the gentleman from Princess Anne was not put, the House having adjourned soon after it was submitted.</p>
            <div className="speaker" id="sp4151"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">The Chair did not understand the gentleman from Princess Anne as presenting the ordinance with a view to its being printed.</p>
            <div className="speaker" id="sp4152"><span className="persName">Mr. BALDWIN</span>—</div>
            <p className="p-in-sp">Yes, sir. The gentleman submitted it to be printed, and accompanied it with some remarks.</p>
            <div className="speaker" id="sp4153"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">The recollection of the Chair is, that a motion was made, yesterday morning, to pass by the report of the committee on Taxation, for the purpose of considering a resolution offered by the gentleman from Richmond city [<span className="persName">Mr. RANDOLPH</span>] . That being the case, so soon as the resolution of the gentleman was disposed of, the report of the committee on Taxation was next in order; and at that time the gentleman from Princess Anne [<span className="persName">Mr. WISE</span>] arose, and announced the proposition that he intended to submit, without making a motion in connection with it.</p>
            <p className="p-in-sp">That is the recollection of the Chair.</p>
            <PageNumber num={283} />
            <p>Reference having been made to the journal, it was found that a motion was made to lay on the table and print.</p>
            <div className="speaker" id="sp4154"><span className="persName">Mr. WM. B. PRESTON</span>—</div>
            <p className="p-in-sp">I move to pass by the consideration of this ordinance for the present.</p>
            <p>The motion was agreed to.</p>
            <div className="speaker" id="sp4155"><span className="persName">Mr. PRESTON</span>—</div>
            <p className="p-in-sp">The committee to whom was referred the ordinance of secession for the purpose of revising the preamble thereto, and preparing a schedule, have directed me to make the following report:</p>
            <p className="p-in-sp">In the ordinance of secession, the committee have agreed to strike out the words "17th day of April, 1861," and insert in lieu thereof the words, "done in Convention, on the seventeenth day of April, in the year of our Lord one thousand eight hundred and sixty-one, and in the eighty-fifth year of the Commonwealth of Virginia."</p>
            <div className="stage it">[The words stricken out above were those contained in the ordinance when it was originally adopted.]</div>
            <div className="speaker" id="sp4156"><span className="persName">Mr. PRESTON</span>—</div>
            <p className="p-in-sp">I have a single word to say. The period at which the ordinance was put upon its passage did not appear on its face. Some gentlemen suggested "the 17th of April, 1861," while others suggested that these words be stricken out, and the form of attestation adopted which was contained in the United States Constitution.</p>
            <div className="speaker" id="sp4157"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">
              The Chair stated, the other day, that it would require a reconsideration of the vote adopting the Ordinance before any amendment could be made. A reconsideration of the vote adopting the preamble was had, but no such action was taken with reference to the Ordinance. The Chair will, however, by universal consent, entertain the motion to amend without resorting to this formula.
            </p>
            <p>There was no objection, and the question being put on the adoption of the amendment, it was agreed to unanimously.</p>
            <div className="speaker" id="sp4158"><span className="persName">Mr. PRESTON</span>—</div>
            <p className="p-in-sp">I am further directed by the committee to submit the following schedule :</p>
            <p className="p-in-sp">SCHEDULE</p>
            <p className="p-in-sp">
              It shall be the duty of the officers conducting the elections directed by law to be held on the fourth Thursday in May next, at the places appointed for holding the same, to open a poll to take the sense of the qualified voters of this Commonwealth upon the ratification or rejection of "An Ordinance to Repeal the Ratification of the Constitution of the United States of America by the
              State of Virginia, and to resume all the Rights and Powers granted under the said Constitution," adopted in Convention, at the city of Richmond, on the seventeenth day of April, one thousand eight hundred and sixty-one.
            </p>
            <PageNumber num={284} />
            <p className="p-in-sp">2. The poll book shall be headed "The Ordinance of Secession," and shall contain two columns, one headed "For Ratification," and the other "For Rejection;" and the names of those who vote for the ratification of the said Ordinance shall be written under the former heading, and the names of those who vote for its rejection shall be written under the latter heading.</p>
            <p className="p-in-sp">
              3. The said officers shall make return of the number of persons voting for each proposition at the time and in the manner provided by law in the case of other elections, and shall forthwith deliver the returns, together with the poll books, to the clerks of their respective counties and corporations; and it shall be the duty of such clerks respectively to transmit immediately to the
              Governor of the Commonwealth copies of the said returns so delivered to them.
            </p>
            <p className="p-in-sp">
              4. The Governor shall, without delay, make proclamation of the result, stating therein the aggregate vote for and against the ratification, to be published in such newspapers in the State as may be deemed requisite for general information; and if a majority of said votes be cast for the ratification of the said Ordinance, he shall annex to his proclamation a copy thereof, together with
              the schedule.
            </p>
            <p className="p-in-sp">
              5. The Secretary of the Commonwealth shall cause to be sent to the clerks of each county and corporation as many copies of this schedule and Ordinance aforesaid as there are places of voting therein, using special messengers for that purpose when necessary. And it shall be the duty of said clerks to deliver the same to the sheriffs for distribution, whose duty it shall be forthwith to
              post the said copies at some public place in each election district.
            </p>
            <p className="p-in-sp">6. The expenses incurred in providing poll books, and in procuring writers to enter the names of the voters therein, shall be defrayed as in the case of the election of members to the General Assembly.</p>
            <p className="p-in-sp">
              Done in Convention, in the city of Richmond, on the 24th day of April, eighteen hundred and sixty-one, and in the eighty-sixth year of the Commonwealth of Virginia.<span className="note" id="Note24"
              ><span className="noteLabel">1</span>It was the eighty-fifth year of the Commonwealth. The date of April 24 was that on which the schedule was finally approved (see page 418). Since this Schedule, and a number of Ordinances passed later in April, include the dates on which they were passed, the Enquirer evidently printed them from versions already published when the Proceedings were
                being readied for the press. Sometimes, as here, a document bears a date of passage several days later than the day on which it was reported as passed.</span
              >
            </p>
            <div className="speaker" id="sp4159"><span className="persName">Mr. HARVIE</span>—</div>
            <p className="p-in-sp">I want to know whether the Governor is to call us together after the election.</p>
            <div className="speaker" id="sp4160"><span className="persName">Mr. SCOTT</span>, of Fauquier—</div>
            <p className="p-in-sp">No, sir.</p>
            <div className="speaker" id="sp4161"><span className="persName">Mr. HARVIE</span>—</div>
            <p className="p-in-sp">I think we ought to be called together to enable the Convention to take all necessary steps to meet any exigency that may arise. I am of opinion that there will be strong need for their reassembling. I would move that this Convention be called together after the polls are received.</p>
            <div className="speaker" id="sp4162"><span className="persName">Mr. BRUCE</span>, of Halifax—</div>
            <p className="p-in-sp">I think it is competent for us to fix a day <PageNumber num={285} /> for meeting again, when in the course of this session it will appear to us proper to do so. If the ordinance is ratified we will have to meet under any circumstances.</p>
            <div className="speaker" id="sp4163"><span className="persName">Mr. HARVIE</span>—</div>
            <p className="p-in-sp">Why call upon the Governor to assemble us in one case and not in the other? Why not call upon him to assemble us in case the ordinance is rejected as well as in case of its adoption?</p>
            <div className="speaker" id="sp4164"><span className="persName">Mr. PRICE</span>—</div>
            <p className="p-in-sp">I think the gentleman can be accommodated. Can we not now provide for that contingency? I apprehend that we will not be in session until the day of election. We must adjourn before then and we can easily fix a day for reassembling if necessary.</p>
            <div className="speaker" id="sp4165"><span className="persName">Mr. HARVIE</span>—</div>
            <p className="p-in-sp">I move to strike out of the schedule all that relates to adjourning and reassembling the Convention.</p>
            <div className="speaker" id="sp4166"><span className="persName">Mr. PRICE</span>—</div>
            <p className="p-in-sp">I think the gentleman can hardly dispense with the necessity of calling us together if the ordinance is adopted.</p>
            <div className="speaker" id="sp4167"><span className="persName">Mr. HARVIE</span>—</div>
            <p className="p-in-sp">I see a great necessity for calling this Convention together if the ordinance be not adopted.</p>
            <div className="speaker" id="sp4168"><span className="persName">Mr. SCOTT</span>, of Fauquier—</div>
            <p className="p-in-sp">I can suggest how an amendment may be made, if a little opportunity is afforded for so doing. With a view of having that opportunity, I move that the schedule be laid upon the table for the present.</p>
            <div className="speaker" id="sp4169"><span className="persName">Mr. COX</span>, of Chesterfield—</div>
            <p className="p-in-sp">I want to throw out this suggestion for the consideration of the gentleman.</p>
            <p className="p-in-sp">It is very likely that when this election takes place, there will be twenty or thirty thousand of our voters absent in camps. I would suggest that this schedule be so amended as to enable those who may be in the military service to vote wherever they may happen to be on the day of election.</p>
            <div className="speaker" id="sp4170"><span className="persName">Mr. WOOD BOULDIN</span>, of Charlotte—</div>
            <p className="p-in-sp">I would ask the gentleman who made the motion to lay the schedule on the table to withdraw it to enable me to make a motion to refer it back to the Committee.</p>
            <div className="speaker" id="sp4171"><span className="persName">Mr. BALDWIN</span>, of Augusta—</div>
            <p className="p-in-sp">
              It seems to me that we ought at once adopt such amendments to the Constitution as the adoption of the ordinance of secession would make necessary, in order to adapt them to the new order of things, so that they may be submitted to the people at the same time, or that we should ask the people to give us plenary power, and thus dispense with the necessity of submitting any changes in the
              Constitution we may deem necessary.
            </p>
            <p><span className="persName">Mr. SCOTT</span>, of Fauquier, withdrew his motion to lay on the table. Whereupon, <span className="persName">Mr. BOULDIN</span> moved to re-commit the schedule, which was agreed to.</p>
            <div className="speaker" id="sp4172"><span className="persName">Mr. SCOTT</span>, of Fauquier—</div>
            <p className="p-in-sp">I beg leave to make a report pursuant to <PageNumber num={286} /> a resolution offered by me, in relation to suspending the authority of the Federal Government in the State until the ordinance of secession is adopted by the people.</p>
            <p className="p-in-sp">The select committee appointed to inquire into the expediency of suspending the authority of the Federal Government within the limits of this Commonwealth, until the sense of the people of this State shall be taken upon the ratification of the ordinance already adopted, have had the subject under consideration, and directed me to report the accompanying ordinance:</p>
            <p className="p-in-sp">
              Whereas, the President of the United States, in his response to the resolution of this Convention requesting information touching the policy intended to be pursued by him towards the Confederate States of America, and by his proclamation calling forth the militia of the several States of the Union, to the aggregate number of seventy-five thousand, has indicated an intention, under the
              pretext of suppressing insurrections, and repelling invasions, of waging war upon the Confederate States; and, whereas, the Constitution of the United States gives to Congress alone, the power to declare war, and such power assumed by the President will, in the opinion of this Convention, be plainly a usurped power; and, whereas, by an ordinance adopted by this Convention, the union
              existing between the State of Virginia and the other States under the Constitution of the United States is declared to be dissolved, as an act of the seventeenth day of April, eighteen hundred and sixty-one, when the same shall be ratified by the qualified voters of the Commonwealth;
            </p>
            <p className="p-in-sp">Now, therefore, to relieve the people of the Commonwealth from any supposed obligation, in the meantime, to the military orders of the Executive authorities of the United States, be it</p>
            <p className="p-in-sp">
              Ordained by this Convention, That until the ratification or rejection of the aforesaid ordinance in the manner provided therein, the people of this Commonwealth are discharged and absolved from the obligation of obedience to the military orders of the said Executive authorities of the United States; and they are hereby enjoined and required to submit themselves in all things to the
              direction of the proper authorities of this Commonwealth.
            </p>
            <div className="speaker" id="sp4173"><span className="persName">Mr. HARVIE</span>—</div>
            <p className="p-in-sp">I want to know whether, in the meantime, we are to be considered as under obligation to the Federal Government in civil matters.</p>
            <div className="speaker" id="sp4174"><span className="persName">Mr. SCOTT</span>, of Fauquier—</div>
            <p className="p-in-sp">
              The reading of the ordinance discloses to the Convention that its operation is confined exclusively to the relief of the people of this Commonwealth from any supposed obligation that <PageNumber num={287} /> they may be under to obey the military orders of the Federal Executive. I suppose that it may be possible that whilst we are in this state of
              transition-while we still remain one of the States of the United States, under the obligations of the Constitution of the United States, military gentlemen now in the service of the United States might possibly have some scruples in taking service with us; and therefore, it is, that I propose to confine this ordinance to this single question of military power. I did not suppose that it
              would be proper before we knew the sense of the people in respect to the ratification of the ordinance which has been adopted, to take any steps which will sever our connection altogether from the Federal Union. It is the duty of the Federal authorities to transport the mail amongst us, and I did not desire to give an excuse to those authorities to withdraw these mail facilities or to
              give to them any excuse during this period which is to elapse between now and the election in May, to treat us as having withdrawn from the Union.
            </p>
            <p className="p-in-sp">
              It has seemed to be the opinion of the Convention that the ratification or rejection of the ordinance separating the State from its Federal connection, was a question to be passed on finally by the people at the polls, and accordingly the ordinance itself ordains that it is not to have effect until it receives that ratification; and I could not therefore, see the propriety of adopting
              another ordinance to go into effect in advance of that ratification, whereby this State would be severed altogether from its present connection. But regarding, as I most unquestionably do, the power claimed by the President to levy 75,000 militia for the purpose indicated in his response to this Convention, of waging war against the Confederate States, as plainly a usurped power, I
              have no difficulty at all in providing, as that ordinance proposes to provide, relief to the people of this Commonwealth from any supposed obligation to obey the military orders of the Federal authorities. I don't think that it would be proper, at this time, to go further than that ordinance proposes to go. There are a great many reasons why it seems to me we should confine our action
              within the limits to which that ordinance confines it. There are a great many reasons that I would not undertake now to explain to the Convention, why, it seems to me, we should forbear until the ratification or rejection of the ordinance by the people shall be known, to adopt any measure which is to withdraw the State from her present connection; and, in reply to the gentleman from
              Amelia, I have, therefore, to say that the operation of the ordinance is confined strictly to the military orders of the Federal Executive.
            </p>
            <PageNumber num={288} />
            <div className="speaker" id="sp4175"><span className="persName">Mr. HARVIE</span>—</div>
            <p className="p-in-sp">I shall move to amend that ordinance by adding the word "civil" so as to make it include civil as well as military matters. Before, however, offering that amendment, I will make a few remarks.</p>
            <p className="p-in-sp">
              Mr. President, I know that in making this motion to amend, I make it under considerable disadvantages, because of the impression that my temperament is impetuous. I can only say to the members of this body, that it is utterly impossible for me to speak upon any question, especially of the character now before the House, without displaying a violent manlier, perhaps not generally
              consistent with the success of my efforts. I hope that, while my manner is excited, I will intelligibly convey, and dispassionately state my opinions and sentiments upon the subject now under consideration.
            </p>
            <p className="p-in-sp">
              I feel, Mr. President, that I labor under disadvantage in being opposed to a gentleman of high intellect and superior capacity, having the confidence of the community. I know, sir, the serious disadvantages under which I labor, but in the face of all, I consider it an imperative duty upon me to stand by the interests of the State, and to protect it in time of need, so far as I am able
              to do so.
            </p>
            <p className="p-in-sp">
              It is idle to suppose that there is now, or that there ever can be again any connection between the State of Virginia and the Federal Government. It is preposterous and idle to suppose, that we will ever again form a connection with a people who have unnecessarily imbrued their hands in our blood. I repeat, that it is impossible that we should unite again with such a people; and, that
              being so, where, I ask, is the necessity of keeping up an apparent connection with those people between this time and the period when the people are to decide upon the ordinance of secession? I appeal to the Convention to give me their attention for a moment.
            </p>
            <p className="p-in-sp">
              It is as easy to dissolve our connection with the Federal Government in one particular as in all, or to bind all together or not at all. We are bound to submit to military if we are bound to submit to the civil authority. If we are bound to the civil authority up to the day of election, I should like to know why we should not be bound to the military authority? I see nothing that can
              result from our remaining subject to any obligation to the General Government except embarrassment, and I therefore think that we ought to free ourselves from it as soon as possible. Why can we not pass an ordinance to relieve the State up to the time of the election from every obligation to that Government? If it is legitimate to grant this relief in one particular it is in all. Why
              cannot this committee bring in an ordinance to postpone, if you choose, any obligation until the people decide whether there shall <PageNumber num={289} /> be any obligation resting upon us or not? I see no difficulty in the way of such a course. Are we to go on and cause the people to stultify themselves by maintaining civil relations with a people with
              whom we are at war? It would be folly, criminal folly, to permit it.
            </p>
            <p className="p-in-sp">
              The people of Virginia never can be carried back into connection with that Government except by revolution; and how far that can be successful remains to be seen. I have no misgivings upon the subject; my confidence in the power and ability of Virginia in union with the South to maintain herself is unbounded. This spirit of opposition to a re-union with the North I know pervades my
              section intensely. I hate to speak of any particular section of the State, because God knows I love every portion of it; and I pledge myself here now and all that I have, whether it is property or life, to go to the rescue of any portion of this State. I would readily sacrifice all that I have for any portion of the State. Why not place us in a situation where there can be no doubt in
              the mind of any individual as to where his allegiance is due? I appeal to the gentleman to come to the rescue with all the elements of power and wealth that are available. I am not speaking now impetuously, but I am speaking the sober sentiments which I have long felt, and which are well matured in my mind. I am giving utterance now to these sentiments to induce you to announce the
              fact that no man in Virginia owes allegiance to any other power than his own State Government. These are my opinions now, and I consider that this Convention would be acting in conformity with the sentiment of the people of the State if it would at once suspend all authority of the Federal Government over them, at least during the interval to the day of election.
            </p>
            <p className="p-in-sp">Let us recognize the fact that we are separated from this Federal authority, and let us address ourselves to a consideration of the means by which we can form a connection, politically, commercially and financially, with those who have the means to sustain us-I mean the Southern Confederacy.</p>
            <div className="speaker" id="sp4176"><span className="persName">Mr. FISHER</span>, of Northampton—</div>
            <p className="p-in-sp">I will, with the permission of the gentleman, offer the following amendment:</p>
            <p className="p-in-sp">
              "And the execution of all laws of the United States, except the laws respecting the transportation and the distribution of the mails in the Commonwealth, until the people shall have passed upon the Ordinance of Secession."<span className="note" id="Note25"><span className="noteLabel">2</span>Apparently some such words as "shall be suspended" were omitted after "Commonwealth."</span>
            </p>
            <div className="speaker" id="sp4177"><span className="persName">Mr. HARVIE</span>—</div>
            <p className="p-in-sp">I submit the motion which I indicated at the opening of my remarks a while ago-that the word "civil" be included in the <PageNumber num={290} /> Ordinance, so as to make the suspension of Federal authority apply as well to civil as military matters.</p>
            <div className="speaker" id="sp4178"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">I have received a communication from the Executive which I propose to submit. It is as follows :</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>"EXECUTIVE DEPARTMENT,</p>
                <p>Richmond, April 20, 1861.</p>
                <p>"To the Honorable the President of the Convention:</p>
                <p>"SIR: I have just received a telegram from a reliable source in Fredericksburg, informing me that a line of express may be established to Baltimore from opposite Acquia Creek, which may be of much service to the State in case the mails should be permanently interrupted.</p>
                <p>"I recommend that authority be given to establish such a line, and that provision be made to compensate the agents who may be employed.</p>
                <p>Very respectfully,</p>
                <p>"JOHN LETCHER."</p>
                <p>The following telegraphic despatch was also transmitted by the Governor to the Convention:</p>
                <p>"To Governor Letcher:</p>
                <p>
                  "In regard to laying down telegraph to Winchester, the Manassas Gap Railroad Co. propose to incur half of cost to Strasburg-say of digging holes, getting posts, and setting them-the State to furnish wire and instruments. The cost, under this arrangement, to Strasburg, per mile, will be about fifty dollars. From Strasburg to Winchester the cost will be about one hundred dollars per
                  mile. It will take a week to put up the line. It is very important, as it will ensure a connection with the Valley and North.
                </p>
                <p>"JOHN S. BARBOUR"</p>
              </div>
            </div>
            <div className="speaker" id="sp4179"><span className="persName">Mr. RANDOLPH</span>, of Richmond—</div>
            <p className="p-in-sp">Would it not be well to refer these communications to some committee, so that they may be acted upon? No motion having been made to that effect, they were suffered to go on the table.</p>
            <div className="speaker" id="sp4180"><span className="persName">Mr. HARVIE</span>—</div>
            <p className="p-in-sp">I will indicate more clearly the form of the amendment <PageNumber num={291} /> which I submitted to the Ordinance, reported by the gentleman from Fauquier [<span className="persName">Mr. SCOTT</span>] .</p>
            <p className="p-in-sp">I propose to strike out the words "military orders of the Executive authorities of the United States," and insert "absolved from the Constitution, laws, and authorities of the United States of America."</p>
            <div className="speaker" id="sp4181"><span className="persName">Mr. SCOTT</span>, of Fauquier—</div>
            <p className="p-in-sp">
              The effect of that amendment, if it shall be adopted, it seems to me, will be to give immediate effect to the Ordinance of Secession. Now, sir, I do not propose to enter at all upon a discussion of the question of power; but when the Convention was full, when nearly all of its members were present, the Ordinance adopted on the 17th day of this month was propounded and adopted, with the
              express stipulation set forth in distinct terms, that its effect was to be suspended until its ratification by the people at the polls. Since that day a large proportion of the members of this Convention have left their seats and returned to their homes, and we stand now with a large number of vacated seats. In this condition of this body, it is proposed to retrace the action of the
              17th day of April, in so far as to give that ordinance effect.
            </p>
            <p className="p-in-sp">
              Now, sir, for one, I should require to have the excuse at least of a very urgent necessity to induce me to adopt the amendment. I have not heard it explained by the advocate of the amendment, that we are now under any such urgent, immediate necessity. The fourth Thursday in May is about a month off, and until the fourth Thursday shall come around, it seems to me we shall suffer no
              great detriment, be subject to no great evil by allowing this State to remain, in respect to all civil matters, still as a member of the Union. I do not understand, I repeat, what necessity exists for this hasty action. Conceding, as a question of power, that we have the power, I suggest to the Convention whether, under the circumstances, it is proper to exercise the power. According
              to the amendment proposed, we are to suspend all federal laws; to withdraw, so far as our action can withdraw, all Federal officers exercising functions within the limits of this Commonwealth, from their duty of obedience to the Federal laws. It is, in fact, a mandate addressed to the Judges of the Courts-to the Judges of your Federal Courts, and the officers attached to these Courts.
              It is a mandate addressed to the revenue officers. It is a mandate addressed to all postmasters and all mail carriers, by which they are to be commanded no longer to yield obedience to Federal laws, no longer to discharge the duties in these offices, which the laws impose upon them. I desire to know, sir, what necessity exists for this course; I desire to know whether we shall not be
              prejudicing our own interest greatly <PageNumber num={292} /> more by the adoption of this amendment, than we can possibly prejudice the interests of the Federal government. Certainly it is a matter of some convenience to the people of Virginia, that the mails shall be carried within the limits of Virginia.
            </p>
            <div className="speaker" id="sp4182"><span className="persName">Mr. HARVIE</span>—</div>
            <p className="p-in-sp">The States of South Carolina and Georgia have utterly repudiated all Federal authority, and yet the mails continue to be delivered in the States as usual.</p>
            <div className="speaker" id="sp4183"><span className="persName">Mr. SCOTT</span>, of Fauquier—</div>
            <p className="p-in-sp">
              That is an anomaly, a very strange anomaly-that the Confederate States, who have separated themselves from the Federal Union and assumed separate and distinct nationality, should yet remain indebted to the Federal Government for all their mail facilities. It is an anomaly occasioned by that construction of the Constitution by the Federal authorities which denies to any State, or any
              number of States composing this Union the right, by their own act, to withdraw from the association. But, sir, if the Convention will remember, we have received a very significant hint upon this very question of mail facilities from the President in his communication addressed to this Convention through the Commissioners sent to wait on him.
            </p>
            <div className="speaker" id="sp4184"><span className="persName">Mr. MACFARLAND</span>—</div>
            <p className="p-in-sp">I understand that the Governor of the Commonwealth has issued some order to the postmaster in reference to arrangements contemplated to be made in the event of a stoppage of the mails by the Federal authorities. It may be well that the Convention should know what that order is.</p>
            <div className="speaker" id="sp4185"><span className="persName">Mr. SCOTT</span>—</div>
            <p className="p-in-sp">If the gentleman has anything to communicate, I shall not occupy the time of the Convention very long. We will be very glad to hear him upon the question as soon as an opportunity is afforded him to give his views.</p>
            <p className="p-in-sp">I was proceeding to say, that I thought we had received a very significant hint upon this question directly from the President himself because in the communication addressed by him to this Convention, he announces his purpose to withdraw from the Confederate States their mail facilities.</p>
            <p className="p-in-sp">
              Now, sir, I am unwilling to put this State at this time in a position to justify or excuse the President of the United States, or the Executive authorities of the United States, in any act calculated so much to prejudice the people of this State as the withdrawing of the mail facilities would. So that I am of a decided opinion that during this short interval of one month we ought to
              confine the operations of that ordinance to those military orders against which the ordinance directs its mandate. I see a good reason why we should go thus far, because, <PageNumber num={293} /> as I have already said, it may remove an impediment out of the way of these army officers who are yet attached to the army of the United States, yet holding
              commissions under the Federal authority who may be disposed to surrender those commissions and take service with us. If the ordinance remains inoperative until the ratification of it by the people, we remain, to all legal purposes, still a member of the Union; and if they give up their commissions in the Federal army, and take service here, they will not have that protection which the
              authorities of this Commonwealth ought to give them.
            </p>
            <p className="p-in-sp">
              It seems to me that they will feel the absence of this protection and regard it as an impediment in their way, even if they desire to give up service in the one government and take service in the other. But I cannot see why we should extend the operation of this ordinance to the civil authorities. What inconvenience is to be created, what evil is to result from obedience to the civil
              laws for the period of one month?
            </p>
            <div className="speaker" id="sp4186"><span className="persName">Mr. HARvIE</span>—</div>
            <p className="p-in-sp">
              I understand that a telegraphic dispatch has been received here this morning, stating that the President has directed that no vessel shall be cleared to any of the Seceded States, and that one of our own vessels has been seized at New York. Are we to allow these acts to proceed without making preparations to counteract them? Now, in regard to the mails, there is an arrangement made
              which will meet the requirements of the State in that respect, in case the Federal authorities cut off the mail facilities.
            </p>
            <div className="speaker" id="sp4187"><span className="persName">Mr. WOOD BOULDIN</span>, of Charlotte—</div>
            <p className="p-in-sp">The statement just given, and which was derived from me, is not correctly given.</p>
            <p className="p-in-sp">
              As I stepped out a few minutes ago, I met a gentleman who was taking a telegraphic dispatch up to the Governor. I ascertained from the Governor that it was to inform him that the President of the United States had issued orders to the Collectors at New York to clear no vessels coming to the Seceded States. It stated also that a vessel belonging to Messrs. Crenshaw &amp; Haxall was
              captured on her way to New York.
            </p>
            <div className="speaker" id="sp4188"><span className="persName">Mr. SCOTT</span>, of Fauquier—</div>
            <p className="p-in-sp">
              I know nothing about these telegrams; but if any suggestion comes to me to furnish a strong reason against the proposed amendment, it is found in the very statement just submitted. It is said that the President has issued orders of a hostile character directed towards the seceded States. These orders are necessarily designed to prejudice the interests of these States; and the
              proposition here is, before we shall be in the condition of these States properly, to take action which would subject us to all of these inconveniences; to take a <PageNumber num={294} /> position in which the President may be well excused in the exercise of all power belonging to the Executive authority, to embarrass us in every way-to embarrass our
              commerce, to cut up our trade, to destroy our mail facilities. We are inviting this hostile action, and yet I have not heard from my friend from Amelia
              [<span className="persName">Mr. HARVIE</span>]
              , or any advocate of this amendment a suggestion of any evil likely to follow to the good people of this Commonwealth for the space of one month frOm leaving the civil laws in operation. Why, sir, you have the Federal Courts; and for whose benefit have these courts been created? I apprehend for the benefit of the people of Virginia. Who are interested in the business of these Courts? I
              apprehend the people of Virginia; and yet you propose at this time to deny to our own people all the benefits which they are receiving from the execution of the civil laws of the United States. I cannot see the propriety of this act. If gentlemen will point out to me any evil likely to follow from a continued application of these laws; any danger to be apprehended to the public weal;
              any danger to the public safety, I will listen to the objections. I can see none-I can apprehend none. On the contrary, as I have said, I can see, and do apprehend that great inconvenience, great evil, great injury is likely to result from the operation of the proposed amendment.
            </p>
            <p className="p-in-sp">
              So far as the military power goes, I am content to take the responsibility now of suspending that power-now, because I can see danger that is likely to result even for this short space of one month, if we suffer our people to remain subject to the military orders of the President. Therefore it is, that the ordinance proposes to confine its operation to the source of danger; and if we
              protect ourselves from danger likely to occur, it seems to me we go far enough. I cannot see the propriety of going further, and, for the mere sake of a temporary ordinance, to subject ourselves to the inconveniences and the evils that, it seems to me, must necessarily follow from it.
            </p>
            <p className="p-in-sp">I therefore trust it will not be the pleasure of the Convention to adopt the amendment; but if we pass the ordinance, that it shall remain confined, as it is according to its terms, to the military power, because that is the only service from which I apprehend danger.</p>
            <p>
              <span className="persName">The PRESIDENT</span> laid before the Convention the following communication from the Governor, in reply to the resolution offered by <span className="persName">Mr. HARVIE</span>, inquiring whether the Governor had acted upon the ordinance directing him to extend an invitation to worthy natives and residents of Virginia, in the service of the United States, to enter
              the service of Virginia:
            </p>
            <PageNumber num={295} />
            <div className="floatingText">
              <div className="floatingText_body">
                <p>EXECUTIVE DEPARTMENT,</p>
                <p>April 20th, 1861.</p>
                <p>Gentlemen of the Convention:</p>
                <p>
                  In response to your resolution, adopted this day, requesting the Executive to inform your body "whether he has invited the efficient and worthy Virginians and residents of Virginia in the army and navy of the United States to retire therefrom and to enter the service of Virginia, has been communicated to the said officers or any of them, and what response has been made," I have the
                  honor to state that I have made known to the friends of officers in the service of the United States the action of the Convention on this subject, with a request to communicate with them, and advise me of their determination. Two days since I deputed Mr. Funsten, of Alexandria, to visit Col. Lee, and extend to him, in my name, and that of the Convention, such an invitation as your
                  ordinance directed. Mr. Funsten was to have replied by telegraph from Alexandria, but up to this date no reply has been received. I dispatched Judge John Robertson, of this city, last evening; but, in consequence of the seizure of the Potomac mail boats by the authorities at Washington, he was compelled to return this morning. He is now on his way, via Gordonsville.
                </p>
                <p>Quite a number of the officers of the navy at Norfolk have resigned, and commissions have been sent to several of them. Commissions will be sent to the others so soon as their rank can be ascertained. Commissions have been issued to two who have retired from the army. Col. Ruggles, now in the city, will be commissioned this evening.</p>
                <p>I did not suppose the Convention intended that I should issue a proclamation, through the press, and I have not done so. If, however, such was the wish and intention, and I am so advised, your wishes will be executed promptly and with pleasure.</p>
                <p>Respectfully,</p>
                <p>JOHN LETCHER.</p>
              </div>
            </div>
            <div className="speaker" id="sp4189"><span className="persName">Mr. HARVIE</span>—</div>
            <p className="p-in-sp">I think there is an answer to the whole argument of the gentleman from Fauquier [<span className="persName">Mr. SCOTT</span>]
              , in that paper. I understand that the information we have asked for cannot be communicated to this HOUSE, because the Federal authorities have discontinued the mails, so that we are unable, under the laws of the United States, to have our mail matter transmitted through our own territory without <PageNumber num={296} /> being intercepted by the Federal
              authorities. That is not a military but a civil order from the Federal functionaries. The Federal Government is disregarding its civil obligations, and we are asked here to observe our civil obligations to the Federal Government. This is the situation in which we are placed. I am no lawyer, but it strikes me that it is a principle of law which justifies the nullification of contract
              the moment any of the parties to it violate its stipulations. Upon this principle, are we not fully justified in repudiating our civil obligations to the Federal Government, in view of their disregard of their proper obligations to us?
            </p>
            <p className="p-in-sp">
              And the telegraphic despatch, which I have read since I took my seat, shows that we will be subjected, if possible, to all the pains and penalties attaching to the "robbery" of the Federal property. I imagine that an indictment would lie against us, under the United States law, for the acts we have already perpetrated; and are we to run the risk of such an ordeal, by yielding obedience
              to the civil authority of that Government? I think it would be foolish policy to leave ourselves in such a condition.
            </p>
            <p className="p-in-sp">As regards the mail facilities, they are now virtually withdrawn.</p>
            <p className="p-in-sp">
              In the manner in which they are now regarded, with the espionage practiced under the sanction of the Federal authorities, they are rather a disadvantage than otherwise. No letter is now safe that passes through the hands of Post-Office officials, and it were better, in my opinion, for the interests of the South that the entire mail system within its limits, as it exists under Federal
              auspices, should be abolished, than continued as it has been recently. I am informed this morning that the mail facilities have been stopped; and the effect of continuing the civil obligations upon our people will be to render them amenable to criminal prosecutions without yielding anything like a collateral benefit.
            </p>
            <div className="speaker" id="sp4190"><span className="persName">Mr. SCOTT</span>, of Fauquier—</div>
            <p className="p-in-sp">
              Will the gentleman let me make a suggestion? In all the Southern States, where ordinances of secession were adopted and the laws of the Federal Union were revoked, it was necessary at once to provide other laws to take their place; and we must be entering upon a pretty wide field of duty when we revoke all the present laws of the United States, within the limits of Virginia, and supply
              such as will be necessary to take their place. If the ordinance, amended as the gentleman proposes, was to be adopted, it would operate as a jail delivery, if there are any persons charged with crime in the Federal Courts, and you would expose your mail to be opened and robbed.
            </p>
            <PageNumber num={297} />
            <div className="speaker" id="sp4191"><span className="persName">Mr. HARVIE</span>—</div>
            <p className="p-in-sp">I consider it robbed this morning by the Federal authorities.</p>
            <div className="speaker" id="sp4192"><span className="persName">Mr. SCOTT</span>—</div>
            <p className="p-in-sp">It is impossible for us to revoke all the Federal laws, without considerable inconvenience, unless we furnish other laws in their stead.</p>
            <p className="p-in-sp">The gentleman is furnishing what is only necessary to be done after the ordinance of secession is ratified.</p>
            <div className="speaker" id="sp4193"><span className="persName">Mr. HARVIE</span>—</div>
            <p className="p-in-sp">I know very well that the gentleman seeks to entangle me in a web of legal technicalities; but he has failed, it seems to me, to have either met the question of power or of expediency.</p>
            <p className="p-in-sp">
              Now, I move, to lay the whole subject upon the table; and I do so with the understanding that there is no obligation resting upon me in respect to the Federal Government in any connection; for I admit none, and shall recognize none. My obedience is due and shall be rendered exclusively to the State authorities and laws; and I shall use every means in my power to give effect to those
              laws and to maintain their authority.
            </p>
            <p className="p-in-sp">I move to lay the ordinance on the table.</p>
            <p>The motion was agreed to.</p>
            <div className="speaker" id="sp4194"><span className="persName">Mr. RANDOLPH</span>—</div>
            <p className="p-in-sp">I have an ordinance here, to cover the message from the Governor in regard to the telegraphs. It reads:</p>
            <p className="p-in-sp">"Be it ordained, that the Governor be authorized to contract for such lines of telegraph and agencies as he may think necessary."</p>
            <div className="speaker" id="sp4195"><span className="persName">Mr. PRICE</span>, of Greenbrier—</div>
            <p className="p-in-sp">I think it is better to refer that to a committee. This is great power to confer upon any official.</p>
            <div className="speaker" id="sp4196"><span className="persName">Mr. MORTON</span>—</div>
            <p className="p-in-sp">I hope that it will not be sent to a Committee. We are in revolution, and war is upon us; and if this ordinance is sent to a Committee it cannot be acted upon until Monday. I hope, therefore, that we will adopt it at once.</p>
            <div className="speaker" id="sp4197"><span className="persName">Mr. TURNER</span>, of Jackson—</div>
            <p className="p-in-sp">That question is divisible. I move that it be divided, the question to be first on the part relating to the telegraphs.</p>
            <div className="speaker" id="sp4198"><span className="persName">Mr. RANDOLPH</span>—</div>
            <p className="p-in-sp">I will amend it as follows:</p>
            <p className="p-in-sp">
              "Be it ordained, That the Governor is hereby authorized to contract for a line of telegraph from the city of Alexandria, by way of Strasburg, to the town of Winchester, and for an Express line from the city of Richmond to Baltimore, by way of Aquia Creek, and to defray the expense thereof from the appropriation heretofore made, or from such loan as may be authorized by this
              Convention."
            </p>
            <div className="speaker" id="sp4199"><span className="persName">Mr. HARVIE</span>—</div>
            <p className="p-in-sp">
              I merely want to state, in regard to the invitations extended to Col. Lee and Gen. Johnson, and the other officers invited, that if they are not accepted, I will on Monday morning move that <PageNumber num={298} /> an invitation be forwarded to the President of the Confederate States, to come and take charge of the military operations of the State of
              Virginia.
            </p>
            <p>The ordinance offered by <span className="persName">Mr. RANDOLPH</span> was then adopted.</p>
            <p><span className="persName">Mr. SCOTT</span>, of Powhatan, from the committee on Military Affairs, submitted the following resolution, which was adopted :</p>
            <p>"Resolved, That the injunction of secrecy as to the ordinance directing the volunteers to be called into service, be so far removed as that the first section of said ordinance be published."</p>
            <p><span className="persName">Mr. MACFARLAND</span> offered the following resolution, which was adopted :</p>
            <p>"Resolved, That the Committee of Finance inquire into and report upon the expediency of authorizing the banks of the Commonwealth to issue one and, two dollar notes."</p>
            <p><span className="persName">Mr. RANDOLPH</span> offered the following resolution :</p>
            <p>"Resolved, That the Committee on Military Affairs is hereby instructed to inquire whether it is probable that Messrs. Joseph R. Anderson &amp; Co. will be able to execute their contract for furnishing the machinery required by the State, for the manufacture of small arms, at the armory in this city, and if not, what steps should be taken in reference thereto."</p>
            <p>The resolution was adopted.</p>
            <div className="speaker" id="sp4200"><span className="persName">Mr. SAMUEL G. STAPLES</span>, of Patrick—</div>
            <p className="p-in-sp">I beg leave to offer the following resolution :</p>
            <p className="p-in-sp">"Resolved, That the President appoint a committee of five members, to prepare and report to this Convention, an address to the people of Virginia, setting forth the reasons that impelled this Convention to pass an ordinance of secession, and why the people should ratify the same."</p>
            <div className="speaker" id="sp4201"><span className="persName">Mr. EARLY</span>—</div>
            <p className="p-in-sp">I move to lay that resolution on the table.</p>
            <div className="stage it">[The motion was lost, and the resolution thereupon adopted.]</div>
            <div className="speaker" id="sp4202"><span className="persName">Mr. PRESTON</span>, of Montgomery—</div>
            <p className="p-in-sp">I beg leave to report back the schedule to the ordinance of secession with some modification, and move that it be printed with the ordinance.</p>
            <div className="speaker" id="sp4203"><span className="persName">Mr. SCOTT</span>, of Fauquier—</div>
            <p className="p-in-sp">I think if this provision is adopted affording facilities for those in the military service of the State to vote, it ought to be made part of the schedule.</p>
            <p className="p-in-sp">
              I move therefore to amend it by providing, that such "as may be absent from the counties or corporations of their residence on the day of election in the military service of the State, may vote for the ratification or rejection of the ordinance at such place or places within their encampments, or as the commander at such encampment shall
              <PageNumber num={299} /> designate, whether the said encampment shall be within the limits of the State or not."
            </p>
            <div className="speaker" id="sp4204"><span className="persName">Mr. PRESTON</span>—</div>
            <p className="p-in-sp">I withdraw the motion I have just made with a view to enable the gentleman to offer his amendment.</p>
            <div className="speaker" id="sp4205"><span className="persName">Mr. SCOTT</span>, of Fauquier—</div>
            <p className="p-in-sp">I now offer that amendment.</p>
            <p>It was agreed to.</p>
            <div className="speaker" id="sp4206"><span className="persName">Mr. PRESTON</span>—</div>
            <p className="p-in-sp">I now move that the ordinance and schedule be printed.</p>
            <p>The motion was agreed to.</p>
            <div className="note" id="Note26">
              <span className="noteLabel">3</span>The Enquirer omitted to report the submission, at about this point in the Proceedings, of two resolutions by Mr. Seawell, and an amendment to the first resolution, moved by Mr. Blakey. The Journal reported this business as follows:
              <p><span className="persName">Mr. SEAWELL</span> submitted the following resolutions:</p>
              <p>1. Resolved, That a committee of five be appointed by the Chair, to inquire into the expediency of postponing the election of members of Congress to the United States, at the ensuing general elections for the State, on the fourth Thursday in May next.</p>
              <p>2. Resolved, That the same Committee inquire into the propriety of providing that persons entitled to vote at the ensuing elections, who may be in the actual service of the State, at the time, may cast their votes at the nearest place of election to them, on the question of the ratification or rejection of the Ordinance of Secession.</p>
              <p><span className="persName">Mr. BLAKEY</span> moved to amend the first resolution by striking out all after the word "resolved," and inserting the following:</p>
              <p>"That a committee of five be appointed to report what action, if any, this Convention should take in reference to the approaching Congressional election in this State."</p>
              <p>The question was put, and decided in the affirmative, and the resolution, as amended, was adopted.</p>
              <p><span className="persName">Mr. EARLY</span> moved that the second resolution submitted by <span className="persName">Mr. SEAWELL</span>, be referred to the Committee of Elections. Negatived.</p>
              <p>The resolution was then adopted.</p>
              <p>The exact point at which Mr. Seawell offered his resolutions is not quite certain, for the order of events on April 20, according to the Journal, was not precisely what the Enquirer reported.</p>
            </div>
            <div className="stage it">
              <p>[The schedule and ordinance, as amended, have already been published—the schedule in the early part of to-day's proceedings—the ordinance in the proceedings of the 17th, with the exception of the modification in the form of attestation.</p>
              <p>
                The only change made by the Committee in the schedule, upon its recommitment, was simply the striking out of a provision authorizing the Governor to call the Convention together on ascertaining from the returns of the votes, that a majority were in favor of the ratification of the Ordinance. This, with the addition just made in Convention, on the motion of
                <span className="persName">Mr. SCOTT</span>, of Fauquier, includes all the modifications made in the schedule as it was originally reported.]
              </p>
            </div>
            <div className="speaker" id="sp4207"><span className="persName">Mr. R. Y. CONRAD</span>—</div>
            <p className="p-in-sp">I hold in my hand some resolutions which I desire to offer, and ask that they may be laid upon the table and printed. I shall, at a subsequent time, move to give them proper reference.</p>
            <p className="p-in-sp">
              The main object of these resolutions is to call the attention of the Convention to the subject of some conference with the slave States remaining in the Union, with a view to co-operation. It seems to be a matter entirely lost sight of, while it appears that several of the States have already appointed delegates to attend the Convention, to be held next month, at Frankfort, Kentucky.
            </p>
            <p className="p-in-sp">I beg leave now to offer the resolutions:</p>
            <p className="p-in-sp">
              "Whereas, the assumption by the President of the United States, of powers not granted by the Constitution, and the lawless attempts to enforce the same by levies of armed forces threatening the overthrow of the Federal Constitution and the safety of the Commonwealth of Virginia, hath constrained this Convention to adopt a conditional ordinance of secession, and, until the same can be
              acted upon by the people at the polls, to withdraw all military obedience of the citizens of Virginia from the Federal Government, and place all the military power and means for defence in the hands of our State authorities : and, whereas, it is expedient and necessary, so long <PageNumber num={300} /> as this usurpation by the Federal Executive authority
              may be continued, for this Commonwealth to secure the safety of the people by thorough armament, by maintaining her authority in all places within her borders, and conferring with the people of sister States in order to form alliances and restore peaceable and efficient civil government: to the end, therefore, that the first purposes of this Convention may be accomplished and peace
              maintained, as much as possible; in the meantime, it is hereby
            </p>
            <p className="p-in-sp">
              "1. Resolved, That all seizures of forts, arsenals and dock-yards of the United States and public property therein, within the limits of Virginia, which have been or may be made by the authorities of this State, it is not with intention to begin, declare, or provoke hostilities with the Federal authorities, or permanently appropriate the value of any property thus seized; but that such
              seizures will be made and regarded solely as temporary and defensive.
            </p>
            <p className="p-in-sp">
              "2. Resolved, That all military preparations and movements now made by the authorities of the State, shall be construed and regarded as intended only to meet the sudden and extraordinary emergency which has arisen, and not in any way to invite or approve a civil war with the people or authorities of other States, a result which we should deprecate as a calamity and reproach to the
              whole people of the States.
            </p>
            <p className="p-in-sp">"3. Resolved, That from the necessity above indicated, and without intending to determine the future position, relations, or policy of the Commonwealth of Virginia, we will send to a Congress to be held at Frankfort, in the State of Kentucky, on the last Monday in May next, delegates, to meet like delegates from the States of North</p>
            <p className="p-in-sp">
              Carolina, Delaware, Maryland, Kentucky, Tennessee, Missouri, and Arkansas, with power and duty to devise and recommend to the several people of said States, and of any other States, and the Southern Confederacy of States, any and all measures proper to be adopted for present safety and future government, to which Congress, the said Confederate States of the South, and the other States
              of our former Union that may so elect, are invited to send Commissioners.
            </p>
            <p className="p-in-sp">"4. Resolved, That the Governor of this Commonwealth is hereby authorized and instructed to repel, by all means in his power, any and all attempts to invade the rights or soil of this Commonwealth, by the Federal or any other authority; and such steps as he hath already taken to this end, are hereby sanctioned and approved."</p>
            <div className="speaker" id="sp4208"><span className="persName">Mr. HARVIE</span>—</div>
            <p className="p-in-sp">My friend, doubtless, knows that I would not withhold from him any courtesy that I would extend to any gentleman <PageNumber num={301} /> here or elsewhere, but, sir, the resolutions that he moves to have printed, are entirely foreign to the sentiments of this body; and I therefore move that they be laid upon the table.</p>
            <div className="speaker" id="sp4209"><span className="persName">Mr. CONRAD</span>—</div>
            <p className="p-in-sp">I certainly did not ask that the resolutions be printed, as any expression of the opinions of the Convention at this time.</p>
            <div className="speaker" id="sp4210"><span className="persName">Mr. SHEFFEY</span>, of Smyth—</div>
            <p className="p-in-sp">I rise to a point of order. A motion to lay on the table is not debatable.</p>
            <div className="speaker" id="sp4211"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">It is not debatable.</p>
            <p>The motion to lay on the table was agreed to.</p>
            <div className="speaker" id="sp4212"><span className="persName">Mr. THORNTON</span>, of Prince Edward—</div>
            <p className="p-in-sp">I beg leave to offer the following resolution—"Resolved, that a committee of five be appointed to meet the Commissioner from the Confederate States expected in this city, and to confer with him in relation to such temporary alliance as the present condition of Virginia and the said Confederate States shall require."</p>
            <p>The resolution was agreed to.</p>
            <div className="speaker" id="sp4213"><span className="persName">Mr. BLAKEY</span>, of Madison—</div>
            <p className="p-in-sp">I move the following as a substitute for that resolution :</p>
            <p className="p-in-sp">1. Resolved, That Virginia should, as soon as practicable, unite with and become one of the Confederate States.</p>
            <p className="p-in-sp">2. Resolved, That this Convention will proceed to appoint Commissioners to negotiate with the Commissioners of the Confederate States, for such a union and for a temporary alliance until said union can be formally ratified, and that the Commissioners on the part of Virginia, report to this Convention for ratification, the result of said negotiations.</p>
            <p>Pending the consideration of these resolutions, the Convention, on motion of <span className="persName">Mr. BLAKEY</span>, took a recess until 5 o'clock, P. M.</p>
            <div className="session" id="vsc1965.v4.2.5.1">
              <h3><span className="headingNumber">1.5.1. </span><span className="head">EVENING SESSION</span></h3>
              <p>The Convention re-assembled at the appointed hour.</p>
              <div className="speaker" id="sp4214"><span className="persName">Mr. RANDOLPH</span>, of Richmond—</div>
              <p className="p-in-sp">
                Under the appropriation for the purchase of arms made in 1860, there is a balance of some $10,000 or $12,000 still due by the State to the commissioners for the purchase of arms. If that be paid by the sale of State bonds, it will cost the State a very large amount, perhaps, twenty odd thousand dollars. If it be paid out of the loan authorized by the Convention, we may get off with
                $10,000.
              </p>
              <p className="p-in-sp">I will offer the following resolution on the subject:</p>
              <p className="p-in-sp">Resolved, That the Committee on Finance be instructed to inquire into the expediency of liquidating, from the fund authorized by this <PageNumber num={302} /> Convention, such balance as may be due from the State under the appropriation made on the day of January, 1860, for the purchase of arms.</p>
              <p>The resolution was adopted.</p>
              <div className="speaker" id="sp4215"><span className="persName">Mr. WILLEY</span>, of Monongalia—</div>
              <p className="p-in-sp">I would inquire, sir, whether the question of taxation is not in order.</p>
              <div className="speaker" id="sp4216"><span className="persName">THE PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair will suggest to the Convention whether it would not be better to move to lay this report on the table when the Convention is not ready to vote upon it. This plan of passing it by from time to time is embarrassing to the Chair, and must be annoying to the Convention.</p>
              <div className="speaker" id="sp4217"><span className="persName">Mr. HOLCOMBE</span>, of Albemarle—</div>
              <p className="p-in-sp">Will the gentleman allow me to submit a brief proposition?</p>
              <div className="speaker" id="sp4218"><span className="persName">THE PRESIDENT</span>—</div>
              <p className="p-in-sp">Does the gentleman insist on taking up the report?</p>
              <div className="speaker" id="sp4219"><span className="persName">Mr. WILLEY</span>—</div>
              <p className="p-in-sp">It is impossible for me to forego this opportunity of calling it up. It was laid aside to-day at the instance of the gentleman from Prince William [<span className="persName">Mr. HUNTON</span>] , to enable the gentleman from Amelia [<span className="persName">Mr. HARVIE</span>] , to offer a resolution inquiring what steps the Governor had taken under the ordinance authorizing and directing him to invite him into the service of Virginia native Virginians now in the service of the United States.</p>
              <p>The report was taken up.</p>
              <div className="speaker" id="sp4220"><span className="persName">Mr. TREDWAY</span>—</div>
              <p className="p-in-sp">I offer the following amendment to the report, and will then move to lay both on the table :</p>
              <p className="p-in-sp">"Provided that the 5th section of the 4th article of the Constitution be so amended that representation in the Senate shall hereafter be apportioned on the mixed basis, that is, according to the number of white inhabitants and the amount of all State taxes paid, in the several counties, cities and towns of the Commonwealth."</p>
              <p className="p-in-sp">I now move that the report and amendment be laid upon the table.</p>
              <div className="speaker" id="sp4221"><span className="persName">Mr. WILSON</span>, of Harrison—</div>
              <p className="p-in-sp">I will offer the following as an amendment to the amendment:</p>
              <p className="p-in-sp">
                Resolved, that every slave who has attained the age of 12 years shall be assessed with a tax equal and not exceeding that assessed on land of the value of 500 dollars. And that each slave under 12 years of age shall be assessed with a tax equal and not exceeding that assessed on land of the value of 250 dollars. But, the circuit and county Courts of each county shall have authority
                to exempt from such taxation all slaves who from age, disease, or bodily infirmity are incapable of rendering service to their owner.
              </p>
              <div className="speaker" id="sp4222"><span className="persName">Mr. WHITE</span>, of Norfolk county—</div>
              <p className="p-in-sp">I would ask the gentleman if he had not better take the balance of the slaves.</p>
              <PageNumber num={303} />
              <div className="speaker" id="sp4223"><span className="persName">Mr. BROWN</span>, of Preston—</div>
              <p className="p-in-sp">I desire to have some information on this subject. This morning I called for the yeas and nays upon a motion to lay on the table. This motion was made before the gentleman from Pittsylvania [<span className="persName">Mr. TREDWAY</span>]
                , submitted his amendment, and it had, therefore, to be disposed of before the amendment could be in order. I don't understand why it is, after the yeas and nays were called and sustained, that the motion to lay on the table, upon which the call was made, could be withdrawn. I was under the impression that I should have to withdraw the call for the yeas and nays before it was
                competent for the mover to withdraw his motion to lay on the table.
              </p>
              <div className="speaker" id="sp4224"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The motion to lay on the table was withdrawn after the call for the yeas and nays, and it was perfectly proper to do so.</p>
              <div className="speaker" id="sp4225"><span className="persName">Mr. HAYMOND</span>, of Marion—</div>
              <p className="p-in-sp">I now ask for the yeas and nays upon the motion to lay on the table.</p>
              <p>
                The call was sustained, and the question being put, was decided in the negative, yeas 29, nays 50, as follows :<span className="note" id="Note27"><span className="noteLabel">4</span>The Journal lists 30 affirmative votes, as given here: the Journal omits Forbes from the negative votes, but adds Preston and Price to those in this list.</span>
              </p>
              <p>YEAS-Messrs. Janney [President] , James Barbour, Blakey, Bouldin, Bruce, Cabell, Critcher, Fisher, Flournoy, Garland, Gravely, Addison Hall, Holcombe, Kilby, Leake, Macfarland, James B. Mallory, Marye, Sr., Morris, Morton, Neblett, Richardson, Robert E. Scott, Seawell, Slaughter, Strange, Tayloe, Thornton, Tredway, White-29.</p>
              <p>
                NAYS-Messrs. Ambler, Aston, Baldwin, Boggess, Boyd, Brent, Brown, Burley, Campbell, Caperton, Coffman, Conn, Deskins, Dorman, Early, Echols, Forbes, French, Gillespie, Gray, Goggin, Cyrus Hall, E. B. Hall, L. S. Hall, Haymond, Hull, Hunton, Marmaduke Johnson, Peter C. Johnston, Kent, Lawson, McComas, McGrew, Marshall, Moffett, Moore, Parks, Porter, Pugh, Rives, Sharp, Sheffey,
                Spurlock, Robt. H. Turner, F. P. Turner, Williams, Wilson, Woods, Wysor-50.
              </p>
              <p>So the motion was rejected.</p>
              <p><span className="persName">Mr. WILLEY</span> paired off with some absent member whose name the reporter did not hear.</p>
              <div className="speaker" id="sp4226"><span className="persName">Mr. HAYMOND</span>—</div>
              <p className="p-in-sp">I think the amendment offered by the gentleman from Pittsylvania [<span className="persName">Mr. TREDWAY</span>]
                , is not germane to the subject under consideration. The report of the committee only refers to the changes in the constitution of the State indicated by the resolution appointing the committee; and the gentleman from Pittsylvania, submits a proposition to amend the 4th and 5th sections of the Constitution which relate to the subject of representation in the Senate. I maintain that
                it is not germane to the subject reported upon by the committee, and that it is therefore out of order.
              </p>
              <PageNumber num={304} />
              <div className="speaker" id="sp4227"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The rule of the House of Representatives and Senate of the United States and I believe of the House of Delegates of Virginia, is as stated by the gentleman from Marion [<span className="persName">Mr. RAYMOND</span>]
                . The parliamentary law is directly the reverse, for it recognizes the right to submit an amendment which may establish a proposition entirely different from the original proposition itself. It is for the Convention to decide, in voting upon it, whether the issue embraced in the amendment, be connected with the other or not. The Chair is, therefore, of opinion that the amendment is
                in order.
              </p>
              <div className="speaker" id="sp4228"><span className="persName">Mr. HAYMOND</span>—</div>
              <p className="p-in-sp">If that be the case, I appeal to the gentleman from the county of Harrison [<span className="persName">Mr. WILSON</span>] , to withdraw the resolution. His resolution does not aim at any constitutional amendment. It would be well timed and appropriate if referred to the committee having the subject of taxation in charge, when the whole matter was under consideration before it.</p>
              <p className="p-in-sp">If the gentleman will withdraw his proposition, we can have a direct vote upon the proposition of the gentleman from Pittsylvania, as in opposition to the report of the committee.</p>
              <div className="speaker" id="sp4229"><span className="persName">Mr. WILSON</span>—</div>
              <p className="p-in-sp">I withdraw my proposition.</p>
              <div className="speaker" id="sp4230"><span className="persName">Mr. FISHER</span>, of Northampton—</div>
              <p className="p-in-sp">I offer the amendment which was offered by the gentleman from Princess Anne [<span className="persName">Mr. WISE</span>] on yesterday as an amendment to the original report.</p>
              <p className="p-in-sp">It reads as follows :</p>
              <p className="p-in-sp">"Provided, however, that the same rate of ad valorem tax imposed upon the land shall be imposed upon slaves, and the same that is imposed upon the slaves shall be imposed upon land; and that neither land nor slaves shall be exempted without exempting the other!"</p>
              <div className="speaker" id="sp4231"><span className="persName">Mr. PRICE</span>, of Greenbrier—</div>
              <p className="p-in-sp">I understand the amendment of the gentleman from Pittsylvania was an amendment to the amendment of the gentleman from Harrison.</p>
              <div className="speaker" id="sp4232"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">No, sir, it was the reverse.</p>
              <div className="speaker" id="sp4233"><span className="persName">Mr. FISHER</span>—</div>
              <p className="p-in-sp">I will withdraw the amendment of the gentleman from Princess Anne, in order to allow the vote to be taken on the amendment of the gentleman from Pittsylvania. I will then offer the amendment.</p>
              <div className="speaker" id="sp4234"><span className="persName">Mr. TREDWAY</span>—</div>
              <p className="p-in-sp">
                The proposition which I have submitted is intended to put the Senate representation upon the mixed basis principle. Looking to the appointment<span className="note" id="Note28"><span className="noteLabel">5</span>This word should be "apportionment."</span> in the House of Delegates, we find that it is established according to the white basis, under the present Constitution,
                <PageNumber num={305} /> and will remain so unless altered by a majority of the voters of the State in 1865. I would be glad if the Convention would allow me to add the following to my amendment.
              </p>
              <p className="p-in-sp">"And representation in the House of Delegates shall be, and remain apportioned according to the number of white male inhabitants."</p>
              <p>The Convention agreed to the addition being made.</p>
              <div className="speaker" id="sp4235"><span className="persName">Mr. FLOURNOY</span>—</div>
              <p className="p-in-sp">I would inquire of the gentleman, whether he offers his amendment as an addition or substitute?</p>
              <div className="speaker" id="sp4236"><span className="persName">Mr. TREDWAY</span>—</div>
              <p className="p-in-sp">As an addition.</p>
              <div className="speaker" id="sp4237"><span className="persName">Mr. HAYMOND</span>—</div>
              <p className="p-in-sp">
                I wish to say, as a friend of this taxation measure, that I am opposed to the amendment of the gentleman from Pittsylvania; and I trust the friends of the proposition reported by the committee will vote against it, and at once dispose of this subject. I have already discussed it myself as far as I deemed it necessary to discuss it; and I shall make no further remarks upon its merits.
                Members are going away, and the Convention is getting thinner and thinner, every day. If it is not disposed of now, it is probable it will not be disposed of during the present session.
              </p>
              <div className="speaker" id="sp4238"><span className="persName">Mr. TURNER</span>, of Jackson—</div>
              <p className="p-in-sp">I do not desire to claim the attention of the Convention, but for a moment or two. I have heard to-day, so many appeals made to defer the consideration of this subject in order to afford an opportunity to consider other matters of "great importance," as we were told, to the exclusion of this subject, that I am determined not to yield to any appeal of that sort.</p>
              <p className="p-in-sp">
                I have not desired to press this question at any time on the attention of the Convention. I had thought that a claim so manifestly just to my section, would be readily conceded to us by Eastern gentlemen. I hold that my friends on this floor from the Eastern portion of Virginia, who are co-operating with me in relation to our Federal matters, ought themselves to present this matter,
                and aid in carrying it out, and I believe now, and always did believe, that they will do before this Convention adjourns.
              </p>
              <p className="p-in-sp">Sir, I desire to offer the few remarks which I shall offer, for the purpose of putting myself right upon the record.</p>
              <p className="p-in-sp">
                Some gentlemen from the West and East have said that there was a distinct understanding at the time of the adoption of the resolution raising this committee, that that committee should not report until after the recess. I was not here when the resolution was adopted, and if I was, I should have protested against any such understanding. I have said at all times that I would not press
                this matter until the important question of Federal relations should be disposed of, either <PageNumber num={306} /> by the adoption of the Border Conference plan, or of an Ordinance of Secession. My people favored this policy; but they had no idea that this question of taxation would be permitted to hold over until an adjourned session of this body. I
                am opposed to any understanding or bargain of the character indicated. I have yet to see a single gentleman on this floor who maintained that the proposed plan of equalization of taxes was wrong in principle, or who deemed that it was just<span className="note" id="Note29"><span className="noteLabel">6</span>This should logically be "not just."</span> and proper. I am not now seeking to
                press this matter; because if we should attempt to press it, it is impossible for us to succeed, for I find that the portion of the State that I represent is now, or at least will be, on Monday, in such a hopeless minority, as to render it impossible for them to carry any proposition, except through the magnanimity of Eastern gentlemen. Some seven or eight members from the North-west
                are already gone, and some six or eight more will leave before Monday, so that if there is any material opposition to the report, it will be impossible for us to carry it.
              </p>
              <p className="p-in-sp">I am anxious that a distinct vote should be had upon the report itself; and I trust that gentlemen from the East who are interested so much in the question of slavery, will not embarrass this question by putting another proposition which is not germane to the subject.</p>
              <p className="p-in-sp">
                Sir, it is well known that this question of representation is a matter of very great interest to the people of the west. It is known that we feel very much the state of inequality to which we are subjected on the score of representation as well as taxation, but we do not desire to trespass upon this Convention by making any effort to effect a change upon the subject of
                representation. I say now, that a compromise was made upon that subject which was to continue until 1865, and we are perfectly willing to conform to that understanding. If gentlemen choose to press the consideration of that subject in connection with the proposition now before the Convention, it cannot receive the support or sanction of Western members. To permit this connection of
                the two propositions, would be to derogate from that character for fairness and magnanimity which distinguishes Eastern gentlemen. I appeal to the gentleman from Pittsylvania
                [<span className="persName">Mr. TREDWAY</span>] , to let his proposition go before the Committee, and come before this Convention separately in order that we may have separate action upon this report.</p>
              <p className="p-in-sp">
                Eastern gentlemen cannot, of course, appreciate as we do, for they do not feel it, the sense of inequality which Western men entertain. They feel it very bitterly, and they will feel it more if this act of justice is now withheld from them. They are not upon terms of <PageNumber num={307} /> equality with their Eastern brethren upon this question of
                taxation; and the interests of peace and unity require that this cause of discontent should be removed. If you keep up this inequality, it will only lead to more bitter feelings than have ever before existed between the East and West.
              </p>
              <p className="p-in-sp">As I have said already, I am not desirous to press this matter; but I do trust that a majority on this floor will come forward and magnanimously tender to us that which is our right.</p>
              <div className="speaker" id="sp4239"><span className="persName">Mr. JOHN T. SEAWELL</span>, of Gloucester—</div>
              <p className="p-in-sp">I have remained silent during the whole time this subject has been before this body, not because I felt but little interest in it, but because there were so many more gentlemen competent to discuss it than I am, and I willingly gave place to them.</p>
              <p className="p-in-sp">The gentleman from Jackson [<span className="persName">Mr. TURNER</span>]
                has repeated what I heard repeated very often before, that no man from the East had come forward and denied the justice of the principle for which he now contends. I desire now, to say, that whilst I stand prepared to go as far as any Eastern man in conceding any principle of justice, yet I do not conceive that this is a question of absolute right on the part of the West.
              </p>
              <p className="p-in-sp">
                Sir, there are various questions which are connected with this question of equal taxation. I think that there never has been any government yet, in which certain branches of industry have not received some peculiar advantage in the shape of exemption from imposts, or in some other form, for the benefit of the whole mass, and for the peculiar advantage of those who created the class
                of industry which is thus made the subject of the fostering care of the Government. I might cite you the department of ship building, which, in all mercantile countries, is encouraged by some special exemption, or some peculiar favor. We have paid large bounties for the fishing interests of the North, for the reason that we derived most of the efficient seamen in our naval service
                from that source. I say that whilst I don't mean, I wish it distinctly to be understood, to urge this argument against the adoption of this principle of equal taxation, I state it for the purpose of showing that what I said has some force in it, and to show that it ought to be carried out in your theory. A man who accumulates $500,000 does not add one dollar of material strength to
                the Commonwealth; but a man who undertakes to raise a family of young negroes, although I confess it is a very unprofitable and laborious business to himself and his family, is adding, by every slave that he raises to the age of twelve years, one laborer to the labor <PageNumber num={308} /> resources of Virginia, and to the material strength of the
                Commonwealth. And now I say, that whilst I do not urge this argument against the adoption of the principle for which the gentleman is contending, I say that when you adjust the principle, and come to the settlement of the question, it will be found that there is not a slaveholder in Virginia that can afford to pay an ad valorem tax on his slaves, old and young, if they are assessed
                according to the Louisiana prices.
              </p>
              <div className="speaker" id="sp4240"><span className="persName">Mr. TURNER</span>, of Jackson—</div>
              <p className="p-in-sp">It was always distinctly understood that the slave owner was to assess the value of his slaves. The commissioner of the revenue was to fix it where the owner failed to do so.</p>
              <div className="speaker" id="sp4241"><span className="persName">Mr. SEAWELL</span>—</div>
              <p className="p-in-sp">
                When you call in a commissioner to assess the cash value of a slave, he will do so at what it would bring in the public market. A family of slaves that will burden you annually to the extent of hundreds of dollars, will bring a large sum when put on the block. If they were valued at that rate, no man would be able to bear up against such an incubus. If some mode of valuation be fixed
                in which all the charges and disadvantages of the owner should be duly considered, there would be no difficulty on the subject; and for this reason I hope that this question may not be pressed now; in order that gentlemen may have some plan matured by which the mode of valuation could be fixed.
              </p>
              <div className="speaker" id="sp4242"><span className="persName">Mr. WILLEY</span>—</div>
              <p className="p-in-sp">A provision is embodied in the report which gives authority to the Legislature to regulate the value. The provision reads, "and all property shall be taxed in proportion to its value, which shall be ascertained in such manner as may be prescribed by law." So that the valuation shall be regulated by law.</p>
              <div className="speaker" id="sp4243"><span className="persName">Mr. SEAWELL</span>—</div>
              <p className="p-in-sp">
                I know I would prefer that the valuation of a subject of such value as this, should be fixed in the Constitution and not left to the fleeting legislation of the county. It should be fixed in such a manner as that there would be no doubt about it. I hold that this great interest, upon which stands this day the main prosperity of the State of Virginia, ought not to be left at the mercy
                of ordinary legislation, but be placed beyond the possibility, of detriment from incompetent or partial legislation.
              </p>
              <p><span className="persName">Mr. WILSON</span>, of Harrison, offered the following amendment to the amendment offered by <span className="persName">Mr. TREDWAY</span> :</p>
              <p>Resolved, That every slave who has attained the age of 12 years shall be assessed with a tax equal to and not exceeding that on land, of the value of $500, and that each slave under twelve years of age not better take the balance of the slaves?</p>
              <PageNumber num={309} />
              <p>shall be assessed with a tax equal to and not exceeding that on land of the value of $250.</p>
              <div className="speaker" id="sp4244"><span className="persName">Mr. SEAWELL</span>—</div>
              <p className="p-in-sp">
                When I said some such provision as that would be acceptable to certain gentlemen, I did not mean to say that I would adopt that or that any body else would adopt it. But I repeat now that some mode of valuation should be fixed. I say that if this proposition could be allowed to lie on the table, and gentlemen afforded an opportunity of turning their attention to the question of
                fixing a mode of taxation, it would be much better. I do not object to the principle, but I insist that we ought to have some regulation of that principle in the Constitution itself by which to secure justice and equality.
              </p>
              <p className="p-in-sp">I shall therefore, oppose, by my vote, the adoption of that report at this time.</p>
              <div className="speaker" id="sp4245"><span className="persName">Mr. PRICE</span>, of Greenbrier—</div>
              <p className="p-in-sp">I regret that the subject of taxation was brought up when it was, because I knew it would be an exciting subject, and, as I stated before, I thought we had as much trouble as we could get along with in our federal relations. Since it has been taken up, I trust it will be acted on promptly.</p>
              <p className="p-in-sp">I regret that my friend from Pittsylvania [<span className="persName">Mr. TREDWAY</span>] has seen proper to offer his amendment by which this subject is very much complicated.</p>
              <p className="p-in-sp">Now, is the principle in the report a correct principle?</p>
              <p className="p-in-sp">The gentleman from Gloucester [<span className="persName">Mr. SEAWELL</span>]
                seems to doubt its correctness. Let us see. You have $10,000; I have $10,000. You invest your $10,000 in negroes; I invest mine in land. It is a matter entirely of choice with you, whether you invest in slaves or in land. It is a matter of choice with me whether I will invest in land or anything else. If you invest yours in slaves, you must take the consequences of the investment if
                it turns out to be unprofitable. You are not as successful as I am; but if my lands had been unprofitable and your slaves profitable, I, of course, must take the consequences. A third person has $10,000, and he loans it out at six per cent. interest. He may not make more than either of us. He has his $10,000 of capital. You have yours, and you invest it in slaves. I have my $10,000,
                and it is a matter of choice with us, if we invest it in any particular way, and if one is unsuccessful, there is no person to blame but the individual himself.
              </p>
              <p className="p-in-sp">
                Now, it does not seem to me that because "A" chooses to invest his capital in slaves, he should be taxed less than "B," who invests his money in lands. The principle in the report is, that all property should be taxed alike. It does not undertake to say that slaves should be taxed <PageNumber num={310} /> differently from any other description of
                property. The mode of taxation is a question to be settled by the Legislature, who will no doubt see that the slave interest suffers no detriment.
              </p>
              <p className="p-in-sp">
                A gentleman here says that according to the report, it will be ascertained by reference to the Louisiana prices. I do not understand it so. The Commissioner of the Revenue comes to your house. He asks you the value of your property. You give it. I do not think there is a Commissioner in Virginia, who would fix the price of slaves at the standard they would reach if they were put on
                the block and sold to a Louisiana planter. I do not think the Commissioner of the Revenue would value your horse at the price it would bring after being fed and pampered for months. The standard in regard to slaves is what they would reasonably sell for as a family of slaves. This would seem to me to be a very safe principle.
              </p>
              <p className="p-in-sp">Now, in reference to the proposition of the gentleman from Pittsylvania [<span className="persName">Mr. TREDWAY</span>]
                , I do not think, from the manifestations made, that gentlemen desire to modify the principle of taxation, as contained in the Constitution. Now, if they are not willing to do so, they ought frankly to say so, and not endeavor to complicate the question with one that is disagreeable to a large portion of this Convention. I think they ought to endeavor to get clear of the principle
                enunciated in the report by way of a side wind. How does the gentleman from Pittsylvania seek to get rid of this question? By way of a provision which enunciates nothing like a correct principle. Now, I don't understand representation in the House of Delegates as based upon the white basis. It is an arbitrary apportionment. It is true that in making the apportionment reference was
                made to the white voters of the Commonwealth, so that the principle contained in this recent modification of his provision is not a correct recital of the fact as it exists. Of course those who are in favor of modifying the principle of taxation, are not willing to receive it at a cost of a fair representation in the House of the General Assembly. If gentlemen intend to give us the
                ad valorem principle of taxation, they ought to say so. Why complicate these two vexed questions by joining them together, and making one dependent on the other? The report of the Committee enunciates the ad valorem principle of taxation, and the provision submitted by the gentleman from Pittsylvania says that representation in one House of the General Assembly shall be based upon
                taxation and population, and the other upon white population. The two propositions are so united and intended so to operate, as that if you take one you are bound to take the other.
              </p>
              <PageNumber num={311} />
              <p className="p-in-sp">
                Now, I am not willing to take the report if the House shall place the provision of the gentleman from Pittsylvania upon it. I believe that the report enunciates the right principle. But if gentlemen think differently and decide not to accord it, let them say so. I believe that the voting population of the State is the proper principle to constitute the basis of representation.
              </p>
              <p className="p-in-sp">
                Now, sir, these two propositions are totally distinct in the Constitution. One is in one article; the other in a different article. One fixes representation in the two houses of Assembly. That I believe is the very first article; and the one in reference to taxation comes after. I shall not think bad of my friend, if he votes against the ad valorem principle; but I do not think that
                he ought to join this odious principle with the other by way of defeating the report by side winds.
              </p>
              <div className="speaker" id="sp4246"><span className="persName">Mr. TREDWAY</span>—</div>
              <p className="p-in-sp">
                I am very reluctant to take up the time of the Convention at such a period as this, in discussion for I really am becoming impatient of having so much discussion, when we have pressing business. But, sir, I cannot, consistently with my duty, remain silent when I hear at such a time as this, and in such a manner as it has been done, a demand made upon us to submit' to the taxation of
                our most valuable property, without reserving to ourselves the slightest protection in the Constitution. And when I hear a gentleman, like my friend from Greenbrier
                [<span className="persName">Mr. PRICE</span>]
                , one of his prudence and his ability, when I hear him say, that a proposition which only asks that you shall give in one branch of the Legislature, some power to be put into the hands of those who bear the burden of the government-when I hear him denounce that as an odious proposition, I am astounded. He lauds the proposition embodied in the report, which puts taxation upon all
                property according to its value, as a just principle, a principle which was never in your Constitution. It never has been until to-day a principle in the Constitution of Virginia. Until within the last few years, the ad valorem principle was not the principle on which taxation was based in Virginia.
              </p>
              <p className="p-in-sp">It is a new principle in this Government, and now they say it is the only just principle, and they come here to demand it in a crisis like this, and hold over our heads, if not a menace, at least a declaration of dissatisfaction, which is very significant.</p>
              <p className="p-in-sp">
                It has been stated and reiterated that unless the East concedes this measure to the West-in the language of one gentleman from the West, unless the East gives them an interest in our negroes, which is the power of taxation, a power which the Eastern minority could not <PageNumber num={312} /> resist—we could not expect the West to stand by us in this
                crisis. Why, at a period like this, when no man knows how long we will have this property; when the people of the North propose to wage a war of extermination against it; when the West comes to the East, and say we must tax this species of property heavily; when they come and assail it by assuming the power of taxing it at will, what do you expect, gentlemen, we consider is our
                situation? Are you to come and drive this property from the State by taxation? We all know that oppressive taxation is one of the most effectual means by which you can attack an institution. It is the means by which it can be exterminated. We in Eastern Virginia stand in this condition, and at this time immediate action is demanded by Western gentlemen. In regard to my proposition,
                it simply proposes to give us a power in one branch of the General Assembly, which is necessary to our protection. You propose the ad valorem principle of taxation. If we vote for that proposition and my amendment, we concede all you ask. The gentleman from Jackson
                [<span className="persName">Mr. TURNER</span>] , who came here at the beginning of this session, and said that his people would then go in for immediate secession, and help us to fight the battle, now insinuates that discontent and disaffection will undoubtedly arise in his section, if this report is not adopted.</p>
              <div className="speaker" id="sp4247"><span className="persName">Mr. TURNER</span>, of Jackson—</div>
              <p className="p-in-sp">The gentleman misunderstands my position. I did not come here in favor of immediate secession. On the contrary, I was in favor of exhausting every means to adjust the difficulties which led to secession.</p>
              <div className="speaker" id="sp4248"><span className="persName">Mr. TREDWAY</span>—</div>
              <p className="p-in-sp">
                I know that some gentlemen from the West, were for precipitating us into this state of things which we are now beginning to experience, when a large portion of the Eastern men-those who represented the largest portion of slave property-were for a peaceable adjustment, if possible, of our difficulties by a resort to a Border State Conference. While the gentleman from Wetzel
                [<span className="persName">Mr. HALL</span>] , and the gentleman from Barbour [Mr. WooDs] , and others from that section stood here and told us they were ready to pledge themselves that they would stand by the East in the issue, gentlemen from the East were quietly pursuing the even tenor of their way, seeking if possible to avert the great crisis which is now upon us.</p>
              <p className="p-in-sp">Understanding that there is some important business to be acted upon, I will yield the floor, and move to pass by the subject under discussion for the present.</p>
              <div className="speaker" id="sp4249"><span className="persName">Mr. HAYMOND</span>, of Marion—</div>
              <p className="p-in-sp">I wish to know whether this Convention means to dispose of this question or not, and by way of ascertaining <PageNumber num={313} /> the purpose of the House in regard to it, I will ask the yeas and nays upon that motion.</p>
              <div className="speaker" id="sp4250"><span className="persName">Mr. BOULDIN</span>, of Charlotte—</div>
              <p className="p-in-sp">
                I will state that at the moment the recess was taken, a resolution was before the committee<span className="note" id="Note30"><span className="noteLabel">7</span>Presumably this should be "convention."</span> in relation to the appointment of a committee to meet the Commissioner now on his way from Montgomery.
              </p>
              <div className="speaker" id="sp4251"><span className="persName">Mr. HOLCOMBE</span>, of Albemarle—</div>
              <p className="p-in-sp">Is it in order to say a word why this business should be passed by?</p>
              <div className="speaker" id="sp4252"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">It is in order.</p>
              <div className="speaker" id="sp4253"><span className="persName">Mr. HOLCOMBE</span>—</div>
              <p className="p-in-sp">
                I trust it will be the pleasure of the Convention to pass by this subject for the present, that I may offer a proposition which is one requiring, as I conceive, immediate action. I have not taken up much of the time of the Convention; I certainly have not occupied its time in any debate upon abstract propositions; and I may truly say, that I never trespass upon the time of this body
                except to discharge what I believe to be an imperative duty involving the interests of the State at large. It is in the discharge of such a duty that I now rise.
              </p>
              <p className="p-in-sp">
                The Governor of the Commonwealth at this time has every moment presented to him greater and more important questions than ever came before a Governor of this Commonwealth, requiring from him immediate decision. I hear, from sources which make me rely upon the information, that in all probability a fleet containing a number of soldiers has been sent to Norfolk from Washington. That
                information is direct. The Governor is every moment receiving telegraphic despatches which are greatly accumulating the business of his department, inasmuch as it is necessary that they should be promptly responded to. It would be certainly agreeable to him, as I understand, if this Convention should appoint an Advisory Council-say of three gentlemen-no committee of safety. I don't
                propose at all to enter into a discussion as to whether it may be necessary to have a Committee of Safety, in accordance with the suggestion contained in the resolution of the gentleman from Princess Anne
                [<span className="persName">Mr. WISE</span>]
                . I think a council of three gentlemen, outside of this body, who would assist the Governor, give him their advice as to what course he should take, and what response to make to the several questions put to him, would answer all purposes in that connection. It does seem to me that the propriety of such a step would commend itself to every gentleman, and that it would be passed
                without debate.
              </p>
              <p className="p-in-sp">
                In reference to the subject of taxation, I assure the gentlemen interested, that I am anxious to do what is right. I have not had an <PageNumber num={314} /> opportunity to think upon this subject; but as soon as this ordinance was passed, I desired Eastern gentlemen to meet together and to offer to the Convention the most liberal proposition you
                desire.
              </p>
              <div className="speaker" id="sp4254"><span className="persName">Mr. HALL</span>, of Wetzel—</div>
              <p className="p-in-sp">
                I hope it will be the pleasure of the Convention<span className="note" id="Note31"><span className="noteLabel">8</span>The word "not" must have been dropped out here.</span> to pass this subject by. I am unwilling that this question shall be eternally passed by upon the stereotyped plea of "some important business to be transacted." I want to know now whether it is the purpose of this
                Convention to act upon this question now, in order that this farce of taking up and passing by, be put an end to, and the business of the Convention permitted to go smoothly on. We do not come here to beg for anything. We want nothing but what we believe to be our right, and that we will insist upon by every legitimate means, despite the parliamentary resorts which seem to be so
                frequently brought into requisition to stave off action in this manner. If our claim is right let it be granted. If not, the Convention should so decide at once and not seek to procrastinate debate by motions to lay on the table and motions to pass by.
              </p>
              <p className="p-in-sp">
                We are now asked to pass this question by in order to enable a proposition to be made to elect three more Governors. The people have elected one Governor, and he should be allowed to act upon his own responsibility. For my part, I have no idea of yielding to a postponement of this question to appoint auxiliaries to the Executive. To us, this question of taxation is of vital
                importance, and we cannot, in justice to our people, forego this opportunity of urging action upon it.
              </p>
              <p className="p-in-sp">
                I have heard some remarks made impugning indirectly the motives of the gentlemen from the North-West who have left for their homes, and it has also been stated that others would soon follow. One gentleman said this morning he would leave, unless you hung him. I trust the gentleman will not be detained by any cause of that sort; and I will state, in relation to those who have thought
                proper to leave, that in my opinion they better serve the cause of their constituents by remaining at their posts, than going home.
              </p>
              <p className="p-in-sp">We need here all the help we can get; but it is to be hoped that we shall find it in the magnanimous action of the Eastern gentlemen.</p>
              <p className="p-in-sp">
                I hope it will not be understood that I have any unkind feeling to those who have spoken against this proposition. I have some feeling in regard to the efforts being made to stave off action upon it. If it is not disposed of now, I know that some of its friends will leave here before the subject can be brought up again for consideration. It is now before us with a proposition added
                on to stifle it. I am anxious <PageNumber num={315} /> to see what its fate is to be, and with that view shall oppose the introduction of any other measure until action is had upon it. If you intend to kill it, do so by a direct vote, and not by side wind efforts.
              </p>
              <p>The question was then taken on the motion to pass by, made by <span className="persName">Mr. TREDWAY</span>, and it was decided in the affirmative-ayes 45, noes 33.</p>
              <div className="speaker" id="sp4255"><span className="persName">Mr. RANDOLPH</span>—</div>
              <p className="p-in-sp">I beg leave to present the following communication from the Governor of the Commonwealth:</p>
              <div className="floatingText">
                <div className="floatingText_body">
                  <p>EXECUTIVE DEPARTMENT</p>
                  <p>April 20th, 1861.</p>
                  <p>Gentlemen of the Convention:</p>
                  <p>
                    The condition of the Commonwealth in the present crisis absolutely requires a considerable increase in the appropriations to the military and civil contingent funds. The annual appropriation to the former has only been two thousand eight hundred dollars, a mere nominal amount, which was ordinarily sufficient, there being in fact, heretofore, no military establishment. That amount
                    has already been exceeded three hundred and sixty three dollars. At a time like this, there should be not less than one hundred thousand dollars appropriated to that fund. To the civil contingent fund there was appropriated for this year thirty thousand dollars-the usual annual amount. The necessary drafts upon that fund will require that it should be increased at least to thirty
                    thousand dollars additional. There remains now in the Treasury to the credit of that fund the small balance of about six thousand dollars. I feel it due to the Commonwealth to exert all proper economy; but efficiency in the defence of the State requires that no embarrassment should arise from inefficient appropriations.
                  </p>
                  <p>Respectfully,</p>
                  <p>JOHN FETCHER.</p>
                </div>
              </div>
              <div className="speaker" id="sp4256"><span className="persName">Mr. HOLCOMBE</span>—</div>
              <p className="p-in-sp">I submit the following ordinance :</p>
              <p className="p-in-sp">"Be it ordained, That a council of three be appointed by the Convention, to aid, counsel and advise the Governor in the exercise of his Executive authority in the present emergency: the said council to continue in office at the discretion of this body."</p>
              <div className="speaker" id="sp4257"><span className="persName">Mr. BALDWIN</span>, of Augusta—</div>
              <p className="p-in-sp">
                I understand that that ordinance is presented with the consent of the Governor, and that he has been consulted as to the proper persons who are to be appointed for council. I think a council of that sort would be exceedingly disastrous at this <PageNumber num={316} /> time, if it were not constituted in entire conformity with the Governor's wishes. I
                think it is due not only to the interests of the State, but out of respect to the Governor himself, that his wish in this matter should be consulted, and that he should have the right to suggest who were to compose that council.
              </p>
              <p className="p-in-sp">I therefore move, by way of amendment to the ordinance, after the words, "that a council of three be appointed by the Convention," the words, "upon the nomination of the Governor."</p>
              <div className="speaker" id="sp4258"><span className="persName">Mr. HOLCOMBE</span>—</div>
              <p className="p-in-sp">I will say, that in reference to the permanent council of the State, so long as it existed under the old Constitution, the Governor had no voice in their appointment.</p>
              <p className="p-in-sp">As to the gentlemen thought of as proper to constitute this council, I believe that two gentlemen have already been mentioned to the Governor, whose names will be brought to the attention of the Convention, and, who, I am satisfied, will be entirely acceptable to the Governor himself.</p>
              <div className="speaker" id="sp4259"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">If these gentlemen will be agreeable to the Governor, they can be nominated by himself.</p>
              <div className="speaker" id="sp4260"><span className="persName">Mr. HOLCOMBE</span>—</div>
              <p className="p-in-sp">
                I will say, that I do not regard it as in the slightest derogating from the respect due to the Governor, that the appointment of this council should originate and be made by this Convention, especially when the gentlemen to be put in nomination have already been suggested to him and are agreeable to him. Judge John J. Allen and Col. F. H. Smith have been mentioned to him, and they
                are entirely acceptable to him.
              </p>
              <div className="speaker" id="sp4261"><span className="persName">Mr. ROB'T E. SCOTT</span>, of Fauquier—</div>
              <p className="p-in-sp">It is very important that there should be harmony of action between the Governor and this Council. I am perfectly content to vote for the ordinance of the gentleman from Albemarle [<span className="persName">Mr. HOLCOMBE</span>] , if I am perfectly assured that it will be agreeable to the Governor. I am not disposed to force an Advisory Council upon him against his will.</p>
              <p className="p-in-sp">
                In respect to the nominations, the Governor is here and can easily be conferred with. I am not disposed to vote for any person who will not be entirely acceptable to him. I cannot conceive of a more unfortunate state of things than would occur, if you force this Council upon the Governor without his consent, or appoint to it persons not agreeable to him. We want concert of action,
                unity and harmony in the Executive Department.
              </p>
              <div className="speaker" id="sp4262"><span className="persName">Mr. PRESTON</span>, of Montgomery—</div>
              <p className="p-in-sp">I felt the necessity of some such movement as that which has been presented by the gentleman from Albemarle [<span className="persName">Mr. HOLCOMBE</span>]
                . I have been compelled recently, in the <PageNumber num={317} /> discharge of the duties of my constituents and friends, to be at the Governor's frequently for the last three or four days. I have had a little Executive experience, and can judge of the difficulties of the Governor's position in this crisis. I found that he was literally overrun; that he
                had not a chamber or a parlor, or an office, in his house in which groups of citizens were not collected, so that it was actually impossible for the Governor to decide upon anything. I felt from that experience that he should have his Executive power distributed.
              </p>
              <p className="p-in-sp">In conversation with my friend from Albemarle [<span className="persName">Mr. HOLCOMBE</span>] , and others, we concluded that, instead of waiting for this Committee of safety, we would aid the Governor now in distributing the ten thousand men that are now available. I concur with the gentleman from Fauquier [<span className="persName">Mr. SCOTT</span>]
                , that it would be a mischievous thing if the appointments were not in conformity with the wishes of the Governor. I went to the Governor this morning, and stated to him my feelings and views in this matter, and that we had in view the appointment of this advisory council. He said that it would be the greatest relief possible. I said to him that we proposed to appoint two gentlemen.
                He remarked that they were highly acceptable, and Col. Smith, who was present, at once concurred in the view, and I know that they both appreciated it in the highest degree. I left them under the impression that we desired to have such a movement, and I trust the House will pass this ordinance. I am very sure the Governor cannot be controlled by this council. It is meant for no
                purpose except the emergency now upon us. The ordinance can be revoked at any moment that a necessity for this council ceases to exist. No human being can transact the business devolving upon the Governor, unless he has an efficient council to sit in his room and keep him from the crowd. While he is writing the most important financial document, a fellow comes in and says, "a
                suspicious vessel is in view," or mentions something else of little or no importance, which must necessarily distract the Governor, and prevent him from proceeding with the important matter he has on hand.
              </p>
              <p className="p-in-sp">
                I mentioned to the Governor the name of Judge Allen, of the Court of Appeals, as a gentleman who would make an efficient member of this Advisory Council, having all those qualities of knowledge, calmness and deliberation which are essential in a crisis of this character. I also mentioned the name of Col. Smith, and the Governor agreed that these gentlemen were peculiarly adapted to
                that duty, and that, with the addition of a Navy officer, who would advise in regard to matters along the coast, the Council would be certain to prove a great and <PageNumber num={318} /> beneficial auxiliary to him in the discharge of his duties. He telegraphed for Capt. Pegram, who no doubt will be here in the course of to-morrow.
              </p>
              <p className="p-in-sp">I hope it will be the pleasure of the House to adopt this ordinance.</p>
              <div className="speaker" id="sp4263"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">
                The Governor is responsible for all Executive acts, and I think he ought to have the privilege of nominating his advisers. This process will cause no delay; and if we proceed to make the appointments without the nomination of the Governor, it will not be generally understood, and an impression will go abroad that for some reason or other the Governor is superseded.
              </p>
              <div className="speaker" id="sp4264"><span className="persName">Mr. HOLCOMBE</span>—</div>
              <p className="p-in-sp">I am willing to accept the suggestion of the gentleman.</p>
              <div className="speaker" id="sp4265"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">I would suggest to the gentleman to draw up his ordinance in such a way as to include this suggestion.</p>
              <div className="speaker" id="sp4266"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">I have already offered an amendment, which embraces all that the gentleman desires.</p>
              <div className="speaker" id="sp4267"><span className="persName">Mr. HOLCOMBE</span>—</div>
              <p className="p-in-sp">Yes, the gentleman has offered such an amendment, and I cheerfully accept it.</p>
              <p>The question was then put on the adoption of the ordinance as amended, and it was decided in the affirmative.</p>
              <p>The ordinance was at once communicated to the Governor. The report of the committee on taxation was then taken up.</p>
              <div className="speaker" id="sp4268"><span className="persName">Mr. THORNTON</span>, of Prince Edward—</div>
              <p className="p-in-sp">I move to pass it by.</p>
              <div className="speaker" id="sp4269"><span className="persName">Mr. WILLEY</span>, of Monongalia—</div>
              <p className="p-in-sp">I call for the yeas and nays on that motion, in order that we may know whether it is to be acted upon or not.</p>
              <p>
                The call was sustained, and the roll being called, resulted-yeas 27, nays 56, as follows:<span className="note" id="Note32"><span className="noteLabel">9</span>The Journal adds John Goode and F. P. Turner to the affirmative votes given here. In the Journal, F. P. Turner and Woods are not among the 55 negative votes.</span>
              </p>
              <p>YEAS-Messrs. James Barbour, Bouldin, Bruce, Cabell, Conn, Critcher, Fisher, Garland, Gravely, Gregory, Jr., Addison Hall, Isbell, Leake, James B. Mallory, Marye, Sr., Morris, Morton, Neblett, Richardson, Seawell, Strange, Thornton, Tredway, Willey, Williams-27.</p>
              <p>NAYS-Messrs. John Janney [President]
                , Ambler, Aston, Baldwin, Blakey, Boggess, Boyd, Brent, Brown, Burley, Campbell, Caperton, Chapman, Coffman, R. Y. Conrad, Deskins, Dorman, Dulany, Early, Flournoy, French, Gillespie, Gray, Goggin, Cyrus Hall, Ephraim B. Hall, L. S. Hall, Haymond, Hull, Hunton, Peter C. Johnston, Kent, Lawson, McComas, McGrew, Macfarland, Marshall, Miller, Moffett, McD. Moore, Parks, Porter, Preston,
                Price, Pugh, Randolph, Robert E. Scott, Sharp, Sheffey, Sitlington, Slaughter, Tayloe, Robt. H. Turner, Franklin P. Turner, White, Woods, Wysor-56.
              </p>
              <p>So the House refused to pass by the report.</p>
              <p><span className="persName">Mr. WILLEY</span>, when his name was called, said he had paired off with <PageNumber num={319} /> a friend; but in order to keep a quorum he would vote against his own sentiments. He voted aye.</p>
              <div className="speaker" id="sp4270"><span className="persName">Mr. TREDWAY</span>—</div>
              <p className="p-in-sp">I don't like to detain the Convention by any further remarks; but occupying the position that I do, entertaining the opinions that I do entertain in regard to this subject, I must take occasion to make a brief statement upon this subject.</p>
              <p className="p-in-sp">
                In 1851 the subject then agitated in the Convention was the subject of representation. The West was clamoring not upon the subject of taxation, but upon the subject of representation. There was a long and most exciting debate between the East and West, and when we came to ascertain the result the West was in a minority, and they could not carry their White Basis proposition. They
                had, however, some hopes of carrying it after awhile. Threats were made that they would divide the State. Four or five Eastern men came to the rescue and agreed to vote for the White Basis; but I always understood from the debate which took place in the Convention, that these gentlemen voted for the White Basis with the understanding that there were to be some guarantees in regard to
                the power of taxation given to the East. I recollect that Western gentlemen, in advocating the White Basis, took the position that they were willing to give to the East guarantees on the subject of taxation.
              </p>
              <p className="p-in-sp">
                There was another subject came up in regard to the tax upon slaves. One guarantee was to put into your Constitution a provision that slaves should be taxed according to land, not exceeding $300 in value; and the other guarantee which was carried by a large majority of that Convention, was, that no tax should be imposed upon slaves under 12 years of age. The present House of
                Delegates, I admit, is not constituted upon the White Basis principle. It was taken as an arbitrary settlement; but that was sufficient. It was regarded as the White Basis apportionment in the house, and the Senate was put upon the mixed basis; and a compromise was made empowering the Legislature to submit to the free white voters of the State in 1865 the question whether the whole
                system of representation shall be placed upon the white population or not. It is absolutely certain that in 1865, under this Constitution, you will have both houses put upon the White Basis. What is the condition, then, of the East? They will be in a helpless minority without any positive guarantee to secure them against oppressive and unjust taxation.
              </p>
              <div className="speaker" id="sp4271"><span className="persName">Mr. PRICE</span>—</div>
              <p className="p-in-sp">Does not the ad valorem principle afford all the guarantees you ought to ask for?</p>
              <div className="speaker" id="sp4272"><span className="persName">Mr. TREDWAY</span>—</div>
              <p className="p-in-sp">
                It does not, sir. The ad valorem principle, I <PageNumber num={320} /> always believed, was a just one; but I have never known a principle in legislation in constitutional governments which could not be perverted in its adaptation to the peculiar exigencies of the country. If you get the ad valorem principle, you have it in your power to exempt any
                portion of property that you may think proper to exempt. You have got the power. I am not saying you will exercise it in this manner; but I say this principle will give us no protection.
              </p>
              <p className="p-in-sp">The gentleman from Greenbrier [<span className="persName">Mr. PRICE</span>] says that the principle of putting one branch of your Legislature upon the mixed basis was an odious one; and he and other gentlemen say that the question of representation, which is embraced in my amendment, is a distinct subject from that of taxation.</p>
              <p className="p-in-sp">
                Sir, they never were separated in the history of this Government, either State or Federal; and I have the Constitution of the United States before me in which it will be found that the intimate union of these two principles is carefully set forth and observed. I hold that the adoption of this principle, unaccompanied by the amendment which I offer, will deprive the East of every
                necessary guarantee to protect itself against oppressive taxation.
              </p>
              <p className="p-in-sp">
                The Constitution of the United States says, "Representatives and direct taxes shall be apportioned among the several States which may be included within this Union, according to their respective numbers, which shall be determined by adding to the whole number of free persons, including those bound to service for a term of years, and excluding Indians not taxed, three-fifths of all
                other persons."
              </p>
              <div className="speaker" id="sp4273"><span className="persName">Mr. PRICE</span>—</div>
              <p className="p-in-sp">That has been suspended.</p>
              <div className="speaker" id="sp4274"><span className="persName">Mr. TREDWAY</span>—</div>
              <p className="p-in-sp">
                How? By a disruption of the Government? But we all regard that as a vital principle of this Government. It was not suspended because that provision was there; but because of other causes of dissatisfaction having no relation to the question of taxation or of representation. I mention this to show to gentlemen that the framers of the Constitution of the United States of America and of
                the State of Virginia, have never maintained the position that taxation and representation could be separated. The fact is they are twin principles, and must go together. You cannot protect yourselves against taxation in any other form than by representation. It is the highest attribute of sovereignty. This power of taxation is the most dangerous power that is exercised by a
                sovereign State; and this power the gentlemen of the West are asking us to give them unqualifiedly.
              </p>
              <p className="p-in-sp">
                I do submit, sir, looking to the State of things now existing in Virginia, it is an ungenerous and unmanly act on the part of the West <PageNumber num={321} /> to come to us now in this hour of adversity, and demand of us that we shall surrender to them all power to protect an institution which is so essential to us, and so necessary to them as a
                prolific source of revenue; and if I have not mistaken the character of the Western people, if these gentlemen will go home and say that they mean to support us in obtaining these representative guarantees; they will come up and assert that it is right that these guarantees should be afforded us, especially when we agree to give the West the ad valorem principle of taxation. I have
                no doubt that every man in the West would concede the justice of this arrangement, and I trust that the representatives of that section here will see the justice of it and agree to its adoption.
              </p>
              <div className="speaker" id="sp4275"><span className="persName">Mr. SAMUEL MCDOWELL MOORE</span>, of Rockbridge—</div>
              <p className="p-in-sp">I rise with great reluctance at this hour; to say a word upon this question. I want to put my friend from Pittsylvania right in one or two particulars.</p>
              <p className="p-in-sp">I did hope that gentlemen would have permitted this matter to remain until an adjourned session. As it is brought up, however, I must vote for the proposition.</p>
              <p className="p-in-sp">My friend from Pittsylvania [<span className="persName">Mr. TREDWAY</span>]
                , says he wants protection against unequal taxation. I imagine there is no need for such protection. Is it in the power of the people of the Trans-Alleghany country to put an onerous tax upon slave property? I imagine not, sir. We are interested in this property as well as the East, though not to the same extent. We have a large number of slaves, and it is not our interest to impose
                upon them an unjust or oppressive tax. In doing so, we would be discouraging an institution which furnishes a large amount of revenue in various forms, and which is a source of immense wealth to the State.
              </p>
              <p className="p-in-sp">
                But, I maintain that this principle of ad valorem tax, is right and ought to be granted. The gentleman from Pittsylvania need have no apprehension about our imposing a heavy tax upon the property. So far as my section is concerned, it has a much greater interest in the prosperity of the State at large than it can have in the trans-Alleghany country, and it will, therefore, sanction
                no act that would tend so much to impair the general prosperity as would the imposition of a heavy tax upon a species of property which is the prime source of that prosperity.
              </p>
              <p className="p-in-sp">A great deal has been said about an understanding which existed in the Convention of 1850-'51 on the subject of taxation. I know nothing about it, and none existed so far as I can ascertain. This <PageNumber num={322} /> principle is right, and I am satisfied that no possible detriment to Eastern interests can grow out of it.</p>
              <p className="p-in-sp">I trust, then, that we will vote down the proposition of the gentleman from Pittsylvania [<span className="persName">Mr. TREDWAY</span>] , and take a vote immediately upon the main question.</p>
              <p className="p-in-sp">As to the suggestion of the gentleman from Gloucester [<span className="persName">Mr. SEAWELL</span>] , I think there is something worthy of consideration in that; but it seems to me there will be great difficulty in getting anything like a uniform valuation in the various counties of the Commonwealth. I am inclined to think that the best course would be to fix some price for slaves over 12 years of age.</p>
              <div className="speaker" id="sp4276"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair feels bound, under existing circumstances, not to withhold for a moment, any communication sent in by the Governor.</p>
              <p className="p-in-sp">I beg leave, to submit the following communication for the consideration of the Convention:</p>
              <div className="floatingText">
                <div className="floatingText_body">
                  <p>Gentlemen of the Convention:</p>
                  <p>I nominate, under the ordinance just communicated, Judge John J. Allen, and Col. F. H. Smith, and must ask your indulgence, until Monday next, to designate the other member of the council.</p>
                  <p>Respectfully,</p>
                  <p>JOHN LETCHER.</p>
                </div>
              </div>
              <div className="speaker" id="sp4277"><span className="persName">Mr. SCOTT</span>, of Fauquier—</div>
              <p className="p-in-sp">I think there is a difficulty in the way of Judge Allen's appointment.</p>
              <p className="p-in-sp">If gentlemen will turn to the 15th section of the 6th article of the Constitution, they will find, that "no Judge, during his term of service, shall hold any other office, appointment or public trust, and the acceptance thereof shall vacate his Judicial office; nor shall he, during such term or within one year thereafter, be eligible to any political office."</p>
              <p className="p-in-sp">Now, we have just created a new office-the office of advisory council to the Governor; and I understand Judge Allen is nominated. If we appoint him to it, the appointment will, in my opinion, be unlawful; for he is ineligible under the clause in the Constitution which I have just read.</p>
              <p className="p-in-sp">It seems to me that we can get over the difficulty by reconsidering the ordinance offered by the gentleman from Albemarle</p>
              <div className="stage it">[<span className="persName">Mr. HOLCOMBE</span>.]</div>
              <PageNumber num={323} />
              <p className="p-in-sp">I move that the vote adopting that ordinance be re-considered with a view to offer an amendment to it which will obviate any difficulty in reference to the appointment of Judge Allen.</p>
              <p>The motion to re-consider was agreed to.</p>
              <div className="speaker" id="sp4278"><span className="persName">Mr. SCOTT</span>—</div>
              <p className="p-in-sp">I now move to amend the ordinance, by adding the following provision:</p>
              <p className="p-in-sp">"Provided, That the 15th section of the sixth article of the Constitution of this State shall not apply to the office hereby created."</p>
              <div className="speaker" id="sp4279"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">In accordance with the purpose which I indicated a few days ago, I mean to throw no obstacle in the way of any measure introduced for the purpose of forwarding the great object of our State's defence. I do not, however, desire it to be understood that I consider this as at all legal.</p>
              <p>The question was then put on the adoption of the amendment offered by <span className="persName">Mr. SCOTT</span>, and it was agreed to.</p>
              <p>The ordinance, as amended, was then adopted.</p>
              <p>The nominations of Judge Allen and Colonel Smith, as members of the Advisory Council were taken up and confirmed.</p>
              <p>The taxation question then came up.</p>
              <div className="speaker" id="sp4280"><span className="persName">Mr. WILSON</span>—</div>
              <p className="p-in-sp">I withdraw my amendment.</p>
              <div className="speaker" id="sp4281"><span className="persName">Mr. HAYMOND</span>—</div>
              <p className="p-in-sp">I demand the previous question on the amendment of the gentleman from Pittsylvania [<span className="persName">Mr. TREDWAY</span>] .</p>
              <p>The call was sustained, and the main question ordered to be put. The amendment is as follows:</p>
              <p>
                "Provided, that the fifth section of the fourth article of the Constitution be so amended that representation in the Senate shall hereafter be apportioned on the mixed basis-that is, according to the number of white inhabitants and the amount of all State taxes paid, in the several counties, cities and towns of the Commonwealth-and representation in the House of Delegates shall be
                and remain apportioned according to the number of white male inhabitants."
              </p>
              <div className="speaker" id="sp4282"><span className="persName">Mr. HAYMOND</span>—</div>
              <p className="p-in-sp">I now call for the yeas and nays.</p>
              <p>The call was sustained.</p>
              <p>Another roll being called resulted-yeas 26, nays 55, as follows:</p>
              <p>YEAS-Messrs. John Janney [President] , Bouldin, Bruce, Cabell, Fisher, Flournoy, Garland, Gravely, Gregory, Jr., John Goode, Jr., Addison Hall, Holcombe, Isbell, Kilby, Leake, James B. Mallory, Marye, Sr., Morris, Morton, Neblett, Richardson, Seawell, Slaughter, Strange, Thornton, Tredway-26.</p>
              <p>
                NAYS-Messrs. Ambler, Aston, Baldwin, Blakey, Boggess, Boyd, Brent, Brown, Burley, Campbell, Caperton, Chapman, Coffman, R. Y. Conrad, Deskins, Dorman, Dulany, Early, Echols, French, Gillespie, Goggin, Cyrus Hall, Ephraim B. Hall, L. S. Hall, Haymond, Hull, Hunton, Peter C. Johnston, Kent, Lawson, <PageNumber num={324} /> McComas, McGrew, Macfarland,
                Marshall, Miller, Moffett, McD. Moore, Parks, Porter, Preston, Price, Pugh, Randolph, Robert E. Scott, Sharp, Sheffey, Sitlington, Tayloe, Robert H. Turner, Franklin P. Turner, White, Williams, Woods, Wysor-55.
              </p>
              <div className="speaker" id="sp4283"><span className="persName">Mr. FISHER</span>, of Northampton—</div>
              <p className="p-in-sp">I offer the following proviso:</p>
              <p className="p-in-sp">"Provided, however, that the same rate of ad valorem tax imposed upon land shall be imposed upon slaves, and the same rate which shall be imposed upon slaves shall be imposed upon lands, and that neither land nor slaves shall be exempt without exempting the other."</p>
              <div className="speaker" id="sp4284"><span className="persName">Mr. THOS. S. FLOURNOY</span>—</div>
              <p className="p-in-sp">
                Suppose, under the ad valorem principle, a majority of both Houses should determine, as that resolution contemplates, in fairness, to exempt a portion of the young negroes that are of no value while they are yet chargeable, this amendment would absolutely prevent a Western majority to do an act of justice to the East, by exempting a portion of the young slaves, without requiring a
                corresponding exemption of land. An exemption in young slaves is no benefit to us, which is to be counterbalanced by an exemption of their equivalent in land in the West.
              </p>
              <div className="speaker" id="sp4285"><span className="persName">Mr. FISHER</span>—</div>
              <p className="p-in-sp">I would state, what is probably already understood by the House that that was the amendment proposed by the gentleman from Princess Anne [<span className="persName">Mr. WISE</span>] the day before he left here. It was left in charge of <span className="persName">Mr. CHAMBLISS</span>, who has gone home. That gentleman left it with me to offer; but if it shall have the effect the gentleman from Halifax thinks it will have, I am opposed to it, and will therefore withdraw it.</p>
              <div className="speaker" id="sp4286"><span className="persName">Mr. JAMES C. BRUCE</span>, of Halifax—</div>
              <p className="p-in-sp">Representing as I do the largest slaveholding county in the State, I would beg the indulgence of the House but for a few minutes.</p>
              <p className="p-in-sp">
                I think that this principle of ad valorem tax upon slaves is not a tenable one. I am disposed to do full justice to the West. If I could be satisfied that it was just that we should make no exception to the ad valorem principle in favor of slaves, I would vote for it; and I find that I can approach this subject with more impartiality, from the fact that my own personal interest is in
                favor of the tax on slave property. But I am looking to my constituents.
              </p>
              <p className="p-in-sp">
                It is entirely wrong to suppose and to avow that a negro is property alone. He is necessarily a person also. You have to take greater care of him than you do of the animals on your farm. You have to attend to his comforts. You have to get him medical aid. You are obliged, according to some conscientious men, to give him religious instruction, and that to put him on the same footing
                with your horse or your cow is <PageNumber num={325} /> not just. I know from a large experience that a negro will not bear an ad valorem tax. I say that an ad valorem tax serves the purpose of abolitionists; more, that it is an abolition measure in disguise, though I know it is not so intended. You cannot look upon slaves as upon any other property.
                You have to give them comfortable houses, and otherwise provide for them as "persons" require to be provided for. I think this is a great moral question. I would exempt them from this tax, because it would have a great moral effect, by securing the slave against the imposition of extra labor, such as some masters may require them to perform, in order to meet this extra charge. I
                protest against this ad valorem tax, because I honestly look upon it as an abolition measure in disguise; not so intended by the West, I know. If Western gentlemen would be contented with a fair compromise upon this question, I will go as far as any man to do what is right in respect to the interests of the West. If they will be satisfied to agree to some fixed and uniform rate of
                taxation, I would vote for it cheerfully, but justice to my constituents will not permit me to vote for this principle which is now proposed. The naked principle of an ad valorem tax upon negroes, of all ages and all conditions, is what I don't think that property can bear in Virginia.
              </p>
              <div className="speaker" id="sp4287"><span className="persName">Mr. JOHN R. KILBY</span>, of Nansemond—</div>
              <p className="p-in-sp">I have no idea of discussing this question now. I have not troubled the Convention heretofore upon any subject, being merely a silent listener to all that has been said, and content with recording my votes, which, I trust, will speak for me in the future.</p>
              <p className="p-in-sp">
                I merely rise now to enter my protest against this snap judgment, which is taken in the absence of many members who are deeply interested in this question. I say here that it has been announced upon this floor, over and over again, that this question was not to be pressed ; and, with that understanding, several of the members from the West and East have gone home. I submit to this
                Convention, if it is treating us with that justice which we have a right to expect, thus to press this matter to a vote in the absence of so many members who are interested in it, and whose absence is owing to the assurances here given that this question would remain over until an adjourned session. I submit whether it is fair, at this late hour, in the absence of so many members, to
                press this matter upon us.
              </p>
              <p className="p-in-sp">If Western gentlemen suppose that any Eastern man will regard this measure as just, they are very much mistaken. It is not just to tax negroes under 12 years of age. It is in fact taxing our debts-it is taxing our expenses.</p>
              <PageNumber num={326} />
              <p className="p-in-sp">
                I have not time to discuss this question now as fully as I would desire. I simply rose to enter my protest in order that the records of this Convention may show that this vote was pressed upon us, and under what circumstances it is that this report shall become the law of the land. I want the record to show that we are pressed into a vote upon this question, with only 79 members
                present, and at a late hour of the night.
              </p>
              <div className="speaker" id="sp4288"><span className="persName">Mr. BROWN</span>, of Preston—</div>
              <p className="p-in-sp">I merely want to say that if this question has to be passed upon now under the circumstances referred to by the gentleman from Nansemond [<span className="persName">Mr. KILBY</span>] , it is not our fault; because it has been passed by again and again for the purpose of taking up other business, represented, by its peculiar patron, as being of great importance. I will say to the gentleman that if it passes at this hour, it will be because of its peculiar friends from the West having given way.</p>
              <div className="speaker" id="sp4289"><span className="persName">Mr. KILBY</span>—</div>
              <p className="p-in-sp">Certainly. It cannot be passed by its enemies.</p>
              <div className="speaker" id="sp4290"><span className="persName">Mr. THORNTON</span>—</div>
              <p className="p-in-sp">I move that this Convention now adjourn.</p>
              <div className="speaker" id="sp4291"><span className="persName">Mr. HAYMOND</span>—</div>
              <p className="p-in-sp">I ask for the yeas and nays upon that motion.</p>
              <p>
                The call was sustained, and, the roll being called, resulted-yeas 38, nays 46, as follows:<span className="note" id="Note33"><span className="noteLabel">10</span>The Journal also refers to 46 negative votes, but lists only the 45 given here.</span>
              </p>
              <p>YEAS-Messrs. John Janney [President] , Ambler, Blakey, Borst, Bouldin, Bruce, Cabell, Cecil, Dulany, Fisher, Flournoy, Garland, Gravely, Gregory, Jr., Goggin, John Goode, Jr., Addison Hall, Hunton, Isbell, Kent, Kilby, Leake, Macfarland, James B. Mallory, Marye, Sr., Morris, Morton, Neblett, Randolph, Richardson, Robert E. Scott, John T. Seawell, Sitlington, Thornton, Tredway, White, Willey, Williams-38.</p>
              <p>
                NAYS-Messrs. Aston, Baldwin, Boggess, Boyd, Brent, Brown, Burley, Campbell, Caperton, Chapman, Coffman, R. Y. Conrad, Deskins, Dorman, Early, Echols, French, Gillespie, Gray, Cyrus Hall, Ephraim B. Hall, L. S. Hall, Haymond, Hull, Peter C. Johnston, Lawson, McComas, McGrew, Marshall, Miller, Moffett, McD. Moore, Parks, Porter, Preston, Price, Pugh, Sharp, Sheffey, Strange, Tayioe,
                Robert H. Turner, Franklin P. Turner, Woods, Wysor-46.
              </p>
              <p>So the Convention refused to adjourn.</p>
              <div className="speaker" id="sp4292"><span className="persName">Mr. HAYMOND</span>—</div>
              <p className="p-in-sp">I now move the previous question.</p>
              <p>The call was sustained, and the vote having been taken on ordering the main question to be put resulted ayes 36, noes 37. No quorum.</p>
              <div className="speaker" id="sp4293"><span className="persName">Mr. DORMAN</span>, of Rockbridge—</div>
              <p className="p-in-sp">I move that this Convention do now adjourn.</p>
              <div className="speaker" id="sp4294"><span className="persName">Mr. WOODS</span>, of Barbour—</div>
              <p className="p-in-sp">I call for the yeas and nays upon that motion.</p>
              <p>
                The call was sustained, and the question being put resulted-yeas 37, nays 44, as follows:<span className="note" id="Note34"><span className="noteLabel">11</span>The Journal adds Preston to the negative votes listed in the Proceedings.</span>
              </p>
              <PageNumber num={327} />
              <p>YEAS-Messrs. John Janney [President] , Ambler, Bouldin, Bruce, Cabell, Critcher, Dorman, Fisher, Flournoy, Garland, Gravely, Gregory, Jr., Goggin, John Goode, Jr., A. Hall, Holcombe, Hunton, Isbell, Kent, Kilby, Leake, Macfarland, J. B. Mallory, Marye, Sr., Moffett, Morris, Morton, Neblett, Randolph, Richardson, R. E. Scott, Seawell, Strange, Thornton, Tredway, White, Williams -37.</p>
              <p>
                NAYS-Messrs. Aston, Baldwin, Boggess, Boyd, Brent, Brown, Burley, Campbell, Caperton, Cecil, Coffman, R. Y. Conrad, Deskins, Dulany, Early, Echols, French, Gillespie, Gray, C. Hall, E. B. Hall, L. S. Hall, Haymond, Holladay, Hull, Peter C. Johnston, Lawson, McComas, McGrew, Marshall, Miller, Moore, Parks, Porter, Price, Pugh, Sharp, Sheffey, Tayloe, R. H. Turner, F. P. Turner, Woods,
                Wysor-44.
              </p>
              <p>So the Convention refused to adjourn.</p>
              <div className="speaker" id="sp4295"><span className="persName">Mr. BRUCE</span>, of Halifax—</div>
              <p className="p-in-sp">I offer the following amendment:</p>
              <p className="p-in-sp">"Provided, That slaves over 12 years of age shall not be valued at more than $500, and that slaves under 12 years of age shall not be valued for more than $250."</p>
              <div className="speaker" id="sp4296"><span className="persName">Mr. BRUCE</span>—</div>
              <p className="p-in-sp">Gentlemen will bear in mind that the Convention which framed our Constitution determined, as a question between the North and South at that time, that the labor of three white men was equal to the labor of five slaves.</p>
              <p className="p-in-sp">It does seem to me that when gentlemen come to reflect upon it, they will find it to be hardly just to tax negroes upon the ad valorem principle.</p>
              <p className="p-in-sp">I will not trouble the house with any further remarks at this late hour.</p>
              <p>The question was then taken by a count on the amendment offered by <span className="persName">Mr. BRUCE</span>, and resulted ayes 31, noes 38-no quorum voting.</p>
              <div className="speaker" id="sp4297"><span className="persName">Mr. FISHER</span>—</div>
              <p className="p-in-sp">I move that we now adjourn.</p>
              <p>The question was taken on this motion by a count, and resulted ayes</p>
              <p>30, noes 35.</p>
              <p>The Convention then adjourned at half past 8 o'clock, P. M.</p>
            </div>
          </div>
          <div className="day" id="vsc1965.v4.2.6">
            <PageNumber num={328} />
            <h2><span className="headingNumber">1.6. </span><span className="head">SIXTH DAY</span></h2>
            <div className="dateline">Sunday, <span className="date">April 21</span></div>
            <p>The Convention assembled this day at 10 o'clock, A. M., in obedience to a call from the Governor of the Commonwealth.</p>
            <p>The proceedings were opened with prayer by the Rev. <span className="persName">Mr. Mc</span>Comas, the delegate from Cabell.</p>
            <div className="speaker" id="sp4298"><span className="persName">THE PRESIDENT</span>—</div>
            <p className="p-in-sp">I beg leave to lay before the Convention the following communication from the Governor of the Commonwealth:</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>EXECUTIVE DEPARTMENT, April 21st, 1861.</p>
                <p>Gentlemen of the Convention:</p>
                <p>I desired that you should be called together to consider questions of deep interest to the Commonwealth. The reports of the Military Committee will sufficiently explain the objects of the call.</p>
                <p>Respectfully,</p>
                <p>JOHN LETCHER.</p>
              </div>
            </div>
            <div className="speaker" id="sp4299"><span className="persName">Mr. SCOTT</span>, of Powhatan—</div>
            <p className="p-in-sp">I beg leave to submit the following report from the Committee on Military Affairs:</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>
                  Be it ordained, That there shall be organized a Provisional Army for the State of Virginia, as the exigencies of the service may require, which Army shall consist, in addition to the major general commanding the military and naval forces of the State, of two major generals, four brigadier-generals, two regiments of artillery, eight regiments of infantry, one regiment of riflemen,
                  and one regiment of cavalry. Each regiment of artillery shall consist of one colonel, one lieutenant colonel, one major, one sergeant major, one quartermaster sergeant, and ten companies, and each company shall consist of one captain, one first lieutenant, one second lieutenant, four sergeants, four corporals, two artificers, two musicians, and seventy-two privates. Each regiment
                  of infantry shall consist of one colonel, one lieutenant colonel, one major, one sergeant major, one quartermaster sergeant, two principal musicians, and ten companies, and each company shall consist of one captain, one first lieutenant, one second lieutenant, four sergeants, four corporals, two musicians; and seventy-two privates.
                </p>
                <p>
                  The regiment of cavalry shall consist of one colonel, one lieutenant <PageNumber num={329} /> colonel, one major, one adjutant, who shall be a lieutenant, one sergeant major, one quartermaster sergeant, one chief musician, two chief buglers, and ten companies, and each company shall consist of one captain, one first lieutenant, one second lieutenant,
                  exclusive of the lieutenant who is to be the adjutant of the regiment; four sergeants, one of whom shall act as quartermaster sergeant to the company, four corporals, two buglers, one farrier and blacksmith, and seventy-two privates.
                </p>
                <p>The regiment of Riflemen shall consist of one colonel, one lieutenant colonel, one major, one sergeant major, one quartermaster sergeant, two principal musicians, and ten companies, and each company shall consist of one captain, one first lieutenant, one second lieutenant, four sergeants, four corporals, two musicians, and seventy-two privates.</p>
                <p>The brigadier generals of the said army shall be appointed by the Governor, and the field and other commissioned officers of the several regiments shall be appointed by the Governor, and the Governor shall commission all of the said officers.</p>
                <p>The major general commanding the military and naval forces of the State shall be entitled to two aids de-camp, who may be taken from the line without regard to rank; and he may appoint a military secretary either from private citizens or from subalterns of the army, who, for the time being, shall have the pay and emoluments of a captain of cavalry.</p>
                <p>Each brigadier general shall be entitled to one aid, to be taken from the subalterns of the line; and to each regiment there shall be an adjutant, to be appointed from among the subalterns by the commanding officer of the regiment.</p>
                <p>The non-commissioned officers, musicians, artificers, farriers and blacksmiths shall be enlisted for twelve months, unless sooner discharged, and the regulations for their enlistment shall be prescribed by the major general commanding the military and naval forces, subject, however, to the approval of the Governor and his advisory council.</p>
                <p>
                  The officers of the provisional army shall take rank and precedence over all officers of the same grade of the volunteers and militia, without regard to date of commission; and the Governor may proceed at once to appoint, in the mode prescribed, the general and field officers of the provisional army, and they may be assigned to duty with their respective rank with the volunteers
                  which have been or may be called into service.
                </p>
                <p>
                  General regulations for the provisional army and all the departments <PageNumber num={330} /> of the military and naval forces may be prescribed by the commander of the military and naval forces, subject to the ratification of the Governor and his advisory council. This ordinance shall be in force from the time of its adoption, and be subject to
                  amendment, modification, or repeal by this Convention or by the General Assembly.
                </p>
                <p>There shall be also organized a staff department to consist of</p>
                <p>1. A subsistence department, to be composed of one colonel, one lieutenant colonel, two majors and four captains.</p>
                <p>2. A quartermaster's department, of one colonel, one lieutenant colonel, two majors and four captains.</p>
                <p>3. A pay department, to be composed of four paymasters with the rank of major of cavalry.</p>
                <p>4. A medical department to be composed of one surgeon general with the rank of colonel of cavalry, ten surgeons, and ten assistant surgeons.</p>
                <p>5. An adjutant general's department, to be composed of one adjutant general of the rank of colonel of cavalry, with authority to the commander-in-chief to detail as many officers as assistant adjutant generals as the service may require.</p>
                <p>This ordinance shall be in force from its passage, and be subject to amendment, modification, or repeal by this Convention or by the General Assembly.</p>
              </div>
            </div>
            <div className="speaker" id="sp4300"><span className="persName">Mr. JOHNSTON</span>, of Lee and Scott—</div>
            <p className="p-in-sp">I would move to amend the sixth section by striking out the words, "for twelve months," and insert "during the war," so that that part of the section will read : "The non-commissioned officers, musicians, artificers, farriers and blacksmiths shall be enlisted during the war unless sooner discharged," &amp;c.</p>
            <p className="p-in-sp">
              I think that the adoption of this amendment would very much tend to promote this organization. To prescribe so short a period of service as that mentioned in the report will but serve to render the organization inefficient and give it the character of a mere temporary movement, liable to be broken up at the very moment that it is entering upon the period 'of 'its greatest efficiency.
              It may be that the war may terminate before a year is out. If so, the obligation to serve longer will cease; but in any event you will have a guarantee of their services during its existence.
            </p>
            <p className="p-in-sp">
              With the modification I have suggested, sir, I think the plan should be adopted in full. I think that the period mentioned in the report is too short, and not calculated to promote the success of the organization. We cannot rely upon the militia, nor upon the militia officers of the State, in a crisis like this; and I therefore think that we
              <PageNumber num={331} /> should adopt this plan of organization. I trust it will be the pleasure of the Convention to adopt the amendment which I have offered, for with it I believe the plan would be more perfect.
            </p>
            <div className="speaker" id="sp4301"><span className="persName">Mr. JOHN ECHOLS</span>, of Monroe—</div>
            <p className="p-in-sp">
              I know very little in regard to this report, except what I gather from gentlemen of experience; but, as a member of this Committee, I think that the first great object which should be attained now is, to establish some kind of efficient organization at once. All of us have seen within the last two three days the great evil of the want of some fixed purpose in a certain direction.
              Everything around us has seemed to have been in confusion. All our forces seem to be scattered and tending to different directions, instead of all being concentrated into one body, which can only be done by some fixed organization. It is possible that the plan proposed by the committee may not be the very best plan that could be proposed. A better plan may be worked out after weeks of
              consultation, but such a process is unsuited to the present emergency. We must take now the best scheme that the limited time allowed will enable us to perfect; and I regard the plan submitted by the committee as sufficient to meet the pressing emergency now upon us. Time and experience will suggest whatever modifications it may be necessary to make. Permit me to say that the objection
              which has been made by the gentleman from Lee and Scott
              [<span className="persName">Mr. JoHNsToN</span>]
              to this scheme is not a valid one, and rather serves as an argument in its favor. The principal thing we need now is an efficient organization of the different departments in our military service. We need a prompt and efficient organization of the Quartermaster's Department, a prompt and efficient organization of the Commissary Department, and an enlargement of the Adjutant General's
              Department in particular. All these things are provided for by this ordinance; and in addition to that, we provide that certain officers shall be appointed, whose services shall be devoted also to the volunteer service. This ordinance also provides for the appointment, in addition to the Major General commanding the military and naval forces of the State, of four Brigadier Generals, a
              number of Colonels, Majors, Captains, &amp;c. These are to be furnished to us principally from the regular army. A plan of enlistment of our soldiers is going on gradually. It is not to be supposed that we can secure a force of ten thousand men at once. It is possible that in a few weeks we may find ourselves in the Southern Confederacy, and then our forces will probably be
              concentrated into the army of the Southern Confederacy. When the true sons of Virginia now in the Federal army come here, we can tell them that they are to have <PageNumber num={332} /> permanent appointments in the Provisional army, instead of the volunteer service. Our volunteers need to be drilled, and this very deficiency shows the necessity of
              organizing this Provisional Army, which will always be available, whenever an emergency arises. We have in the volunteers of the State young men of qualifications; young men of education and intelligence, who come forward with all the ardor of patriots upon the call of their country, and with a zeal and a courage which carries them forward to engage in any enterprise, however
              hazardous; and when you look upon these young men-these gallant volunteers who have just come from the colleges and counting houses of the merchants-a feeling of sadness must arise, and a natural desire to obviate the necessity of calling such youths into the field to be pitted against the offscouring of Northern cities. The organization which the committee proposes, will, in a great
              measure, supply the need which forces a resort to the volunteer system, and spare to the country the class of enlightened youths to whom I have just referred. I have no doubt that many of these gallant youths will fall in this conflict, and, of course, their places must be taken by others. This organization will provide for that necessity, and, as I said, obviate to some extent the
              necessity for a resort to this call upon our gallant youths. There is another thing to be suggested. Under the ordinance providing to call into the field the volunteer forces of the State, the volunteer companies from all parts of the State will be called into service immediately. You must recollect that under the laws of the Commonwealth, now regulating the volunteer system, persons
              enlisting in the volunteer service only enlist for two years.
            </p>
            <div className="speaker" id="sp4302"><span className="persName">Mr. MORTON</span>—</div>
            <p className="p-in-sp">Four years. I have just seen the law upon that subject.</p>
            <div className="speaker" id="sp4303"><span className="persName">Mr. ECHOLS</span>—</div>
            <p className="p-in-sp">
              My impression is that the code has been amended, and that the period of enlistment is two years. Be that as it may, the argument applies in other respects. Many of these volunteer companies have been in existence now for a period of three and four years. The term of service of many of them will soon expire, and thus they will be freed from the obligation to serve any longer. Their
              places must be filled by men drawn together under the provisions of this ordinance. I think the question ought to be considered maturely, yet rapidly. The exigency calls upon us imperatively to come forward and waive all merely legal technicalities in view of the great crisis which is now upon us. This I hold to be pre-eminently the duty of the volunteer companies whose term of
              service, according to law, has expired. They should waive this privilege of exemption, at least until <PageNumber num={333} /> this new organization is in force. An unrelenting enemy is about to press upon us, who will be satisfied with nothing but to overrun our people and destroy and desecrate our homes. This all here must admit to be the purpose of the
              North in waging this unjust, unholy war. The idea of the people of that section is the subjugation of the South; but, so far as our people are concerned, they never, I imagine, entertained even for one moment so ridiculous and groundless an apprehension. Such an idea never has entered, and never will enter, the minds of the true citizens of the old Commonwealth.
            </p>
            <p className="p-in-sp">
              But, sir, we should prepare to meet the crisis like men. This organization has been planned for us by gentlemen of experience and of skill. Let us adopt this ordinance; and if there are defects in it, they will become readily apparent when the plan is put in operation, and can then be easily remedied. Let these gentlemen who are rushing to us from the army of the United States be
              provided for. Let the enlistments go on gradually. Let three or four recruiting stations be established in the State, and when the voice of Virginia shall be held on the 4th Thursday in May ratifying the separation of Virginia from the Federal Government, we can form such combination and alliances as will prove most beneficial to the State.
            </p>
            <p className="p-in-sp">Let it be seen when that period arrives, that Virginia comes with an efficient force armed cap a-pie, and ready to be transferred to the Confederate Government, of which she is to become a member.</p>
            <p className="p-in-sp">In regard to the amendment offered by the gentleman from Scott and Lee [<span className="persName">Mr. JOHNSTON</span>]
              that the enlistment shall not be for one year, but during the war, unless sooner discharged, the difficulty I have is that the war can hardly as yet be considered as existing. I have no doubt that a war is about to be waged against us, and whilst I am perfectly willing to adopt the amendment of the gentleman, I am satisfied that some other expression would be more appropriate at this
              particular juncture.
            </p>
            <p className="p-in-sp">I trust that no needless objections will be urged to the scheme. I know that my experience in military matters does not entitle me to speak upon the advantages or disadvantages of that scheme; and that what I say here is but a reiteration of what has been said here for the past two or three days.</p>
            <p className="p-in-sp">But let us have some organization, some system that will enable us to accept the aid of those who are tendering their services to us.</p>
            <div className="speaker" id="sp4304"><span className="persName">Mr. SCOTT</span>, of Powhatan—</div>
            <p className="p-in-sp">All will, doubtless, agree that it will not do to depend in this emergency upon the militia of the State, or the militia officers of the State.</p>
            <PageNumber num={334} />
            <p className="p-in-sp">
              We know that most of these are incompetent to perform the duties which they would have to perform in this crisis, especially, the inferior officers-Captains and Lieutenants; and, I have no doubt, it will be the pleasure of the committee to propose an ordinance to this Convention before it shall adjourn, that all positions as officers in the militia be vacated and re-filled. But this
              rule should not, in my opinion, apply altogether to the field and general officers of the State. There are many of these in various sections of the State who are perfectly competent to perform the duties assigned to them. I should be wanting in self-respect, if I were to admit the full scope and latitude of the charges made by some gentlemen, that all militia generals of the State are
              incompetent to perform the duties assigned to them. I believe that most of them are incompetent, and that is the only length I can go in the admission of incompetency, with reference to that class of officers.
            </p>
            <p className="p-in-sp">
              But, sir, we cannot trust the militia of the State. We must depend upon the volunteer and regular army. We can get as many of these, perhaps, as may be necessary; but we should have a regular army as a nucleus around which volunteers are to collect from which to form another army. We propose, therefore, to form a regular army of ten thousand men to be increased or diminished, as
              circumstances may demand. But the gentleman from Scott and Lee
              [<span className="persName">Mr. JOHNSTON</span>]
              , says, we ought to enlist into the regular army during the war, instead of for one year. That was a matter of investigation before the Committee; but it was believed that before the end of the year, this army would be incorporated with the Southern army, and thus, necessarily subjected to its regulations; and it was, therefore, supposed that enlistment for this term would suit such an
              arrangement. I am ready to admit that if this army was necessary to perform all the duties during the war, it would be better to extend the period of service, or adopt the plan proposed by the gentleman from Scott and Lee.
            </p>
            <div className="speaker" id="sp4305"><span className="persName">Mr. PRICE</span>, of Greenbrier—</div>
            <p className="p-in-sp">
              I supposed that when we were called here to-day, there was some very pressing exigency that required us to be here. I find, however, that we are called to consider a report from the Military Committee. The report is here, but there seems to be no concurrence between the members of the committee themselves in respect to it. This makes it difficult for us to decide how we are to vote.
              The chairman of the committee does not concur in the report. The gentleman from Scott and Lee
              [<span className="persName">Mr. JOHNSTON</span>] , a member of that committee, does not concur in it, and neither, I understand, does the gentleman from the city of Richmond [<span className="persName">Mr. RANDOLPH</span>] . In this <PageNumber num={335} /> state of things, it is extremely difficult for the members of this Convention to decide how they shall vote, and what plan of organization to adopt.</p>
            <p className="p-in-sp">There is one thing perfectly certain, that, for the present exigency, you are bound to depend upon volunteers. You cannot depend upon enlisted soldiers or the militia for the present exigency.</p>
            <p className="p-in-sp">
              But, sir, I ask is it proper for us to be here to-day to consider a scheme for a regular army which cannot render any actual immediate service, inasmuch as it cannot be organized within many weeks, nay, perhaps months to come. I therefore move that all these propositions shall go back to the committee, that they may consider them a little more maturely, and that they may concur with
              more unanimity than they exhibit here to-day, in whatever proposition they should present. To-morrow will be Monday, and by the time the House meets I hope the committee will have decided upon some definite plan upon which the Convention can act at once. My motion now is to recommit the report.
            </p>
            <div className="speaker" id="sp4306"><span className="persName">Mr. EARLY</span>, of Franklin—</div>
            <p className="p-in-sp">
              I wish to inquire whether it is necessary to have our debates here to-day reported? I think we had better dispense with this reporting service, for if our proceedings here are ever brought to light, they will show in what state of confusion we are, and how little we seem to know as to how this emergency should be met. I propose, therefore, that we dispense with the services of the
              reporter.
            </p>
            <div className="speaker" id="sp4307"><span className="persName">Mr. SHEFFEY</span>—</div>
            <p className="p-in-sp">I shall oppose any such motion as that.</p>
            <div className="speaker" id="sp4308"><span className="persName">Mr. EARLY</span>—</div>
            <p className="p-in-sp">I wish the gentleman from Greenbrier [<span className="persName">Mr. PRICE</span>]
              , would withdraw his motion in order that I might offer this amendment by way of a substitute for the first portion of the report. I want to say to the gentleman that the position I take here is not at all inconsistent with the position I took on the committee. We assembled here in a very sudden manner this morning, and it is not to be wondered that some conflict of opinion between the
              different members of the committee should exist.
            </p>
            <div className="speaker" id="sp4309"><span className="persName">Mr. R. Y. CONRAD</span>, of Frederick—</div>
            <p className="p-in-sp">
              I should like to know from the chairman of the Military Committee, before I vote upon this question, whether there exists any necessity for immediate action upon this subject. I have gathered from the debate to day, that it is desirable now that the Governor should have it in his power to appoint officers of this proposed provisional army, who would take command of the volunteer forces
              of this Commonwealth. If this is so, I do not see any harm in adopting this ordinance. It has been explained to us, <PageNumber num={336} /> that this subject of enlisting soldiers will be entirely under the control, hereafter, of the Governor and military officers; and that all that can be done under this ordinance will be to appoint the officers. It has
              been proposed to have two sets of officers, and that the officers of the regular army shall be charged with the duty of seeing to the volunteers. This I regard as a good plan, and one that should be speedily adopted.
            </p>
            <p className="p-in-sp">
              I cannot vote for the recommitment of the report except with the assent of the committee itself. I think myself, that there is too little reliance disposed to be placed by the Convention upon the responsibility of the members of this Committee. I think at this time, at all events, we might take a great deal upon trust. I do not see that in this matter any harm can be done by acting
              upon it now; and unless the committee desire to have the subject recommitted I will vote against the motion.
            </p>
            <div className="speaker" id="sp4310"><span className="persName">Mr. JOHNSTON</span>, of Lee and Scott—</div>
            <p className="p-in-sp">I have a single suggestion to make. I suppose it would be out of order to make a motion to amend. When this proposition shall be disposed of, I shall offer a further amendment; the object of which is, to authorize the Governor to appoint the officers authorized to be appointed by this ordinance as the exigencies of the service may require.</p>
            <div className="speaker" id="sp4311"><span className="persName">Mr. EPPA HUNTON</span>, of Prince William—</div>
            <p className="p-in-sp">I desire to make a statement to the Convention in behalf of the committee of which I am a member, which I think will show the necessity for prompt action, and furnish a justification for the assembling of the body this day.</p>
            <p className="p-in-sp">
              We are all aware that the condition of things is perilous, that our forces are without a competent military head. This morning Governor Letcher was tendered the services of a fine military commander, and at once accepted them, and appointed him to Norfolk. He would not take the service until the Governor assured him that an ordinance somewhat of the nature of the one now before the
              Convention would be passed to day. For instance, it was necessary, in order that he might carry on operations at Norfolk, that there should be a Quartermaster's Department; and this deficit involves the necessity for the prompt action which is now sought. Although this ordinance may not in all respects meet the views of members, although it may be by no means perfect, yet it is tht
              best that could be done under the peculiar circumstances in which the committee was placed.
            </p>
            <p className="p-in-sp">
              If defects shall be made apparent in the future operation of the system, it is the easiest thing in the world to remedy them. We are aware that we shall soon have here the services of a military Commander- <PageNumber num={337} /> in-Chief, who, as members here well know, will have the whole organization of the army in charge; and he will, of course,
              immediately remedy any defect that may exist. It is apparent, upon further reflection, that we are in no condition to enter upon a campaign; that we should have an efficient military commander at once, who would enter upon the duty of organizing our forces and putting the different military departments, which we are now seeking to create, on an efficient war footing. We could not
              obtain the services of a military commander, if he was not assured that a Quartermaster's Department would be organized to-day; and in view of that fact, I am satisfied the Convention will overlook any little defects that may exist in the ordinance, and adopt it promptly, leaving to competent military gentlemen to remedy these defects hereafter.
            </p>
            <p className="p-in-sp">
              I know that there is a pressing necessity for this body to act promptly growing out of the perilous condition of affairs in our State. It is necessary that they should act now; and, if in the future we can determine by the advice of such military men as will come to our aid, that something better than this ordinance embodies, is needed; there is no man here who will not go for such a
              measure.
            </p>
            <p className="p-in-sp">But let us have some system, now, though that system may not be perfect, and though it may be liable to the objections which some gentlemen here have referred to. It is the best we can do on the present occasion; and our policy, as it seems to me, is to adopt it, and trust to future developments to remedy these objections.</p>
            <p className="p-in-sp">I trust, therefore, that the Convention will forego all minor objections and adopt this ordinance. Col. GWYNN is now in Norfolk in charge of our forces at that point, and, as I said, only assumed command upon the assurance of the Governor, that an ordinance of this character would be adopted to-day.</p>
            <div className="speaker" id="sp4312"><span className="persName">Mr. BALDWIN</span>, of Augusta—</div>
            <p className="p-in-sp">It seems to me that the view presented by the gentleman from Prince William [<span className="persName">Mr. HUNTON</span>]
              , is a very striking one, and the first that has been presented to justify the haste in which we are called together. I will suggest that the differences of opinion which have sprung up here, have all been such as do not come within the Governor's province. Nobody doubts the necessity for the organization of these different departments. No one has made objection to them; and if the
              gentlemen of the Military Committee will withdraw anything beyond these, the whole matter will be disposed of in five minutes.
            </p>
            <p className="p-in-sp">
              But, sir, it is in the difference of opinion that has arisen here with regard to the organization of a regular force, to be hereafter organized <PageNumber num={338} /> more at leisure, as the foundation of a regular army, that the chief difficulty exists; and I suggest to the gentlemen of the committee that they separate that which is pressing and
              necessary, and about which there is no manner of dispute, from that in regard to which differences of opinion have arisen. There is no press, as I understand, for this matter of enlisting troops, and it might therefore be passed over for the present. Let us all unite in carrying out other branches of the ordinance which are of immediate necessity. It seems to me that the suggestion of
              the gentleman from Prince William
              [<span className="persName">Mr. HUNTON</span>] brings us directly to the particular point involved. I would suggest that we adopt the part suggested by the gentleman, namely : that relating to the organization of a Quartermaster's Department, and lay the balance of the ordinance over for future consideration.</p>
            <div className="speaker" id="sp4313"><span className="persName">Mr. PRICE</span>, of Greenbrier—</div>
            <p className="p-in-sp">I do not desire to embarrass the action of the Convention by any means; and if my motion is calculated to do so, I will withdraw it. I want to concur with the military men; but I want them to concur with themselves when they present an important scheme here.</p>
            <div className="speaker" id="sp4314"><span className="persName">Mr. HUNTON</span>—</div>
            <p className="p-in-sp">In regard to the concurrence of the Committee, I believe that report received the vote of all except one.</p>
            <div className="speaker" id="sp4315"><span className="persName">Mr. PRICE</span>—</div>
            <p className="p-in-sp">The gentleman from Scott and Lee [<span className="persName">Mr. JOHNSTON</span>] , has offered one amendment and proposed at the proper time to offer another. The gentleman from Franklin [<span className="persName">Mr. EARLY</span>] has also indicated his purpose to offer an amendment, so that this does not look much like unanimity.</p>
            <p className="p-in-sp">In order that the Convention may act upon the proposition in reference to which the Governor is pledged, I will withdraw my motion to recommit.</p>
            <div className="speaker" id="sp4316"><span className="persName">Mr. RICHARDSON</span>, of Hanover—</div>
            <p className="p-in-sp">I am ready to vote upon the whole proposition of the Committee; and to give it my support. If, as the gentleman from Augusta [<span className="persName">Mr. BALDWIN</span>] , very properly remarked, we are here for the purpose of deliberating, and if it is the duty of members to make any suggestion they deem calculated to promote the efficiency of this body, and to give a salutary influence to its action, I hope it is no violation of modesty upon my part to make a suggestion by way of inquiry.</p>
            <p className="p-in-sp">
              I desire most respectfully to inquire of my friend, the Chairman of the Committee on Military Affairs, why is it that some volunteer troops have not been concentrated at some point within the State. It seems to me that it would be exceedingly proper to concentrate directly <PageNumber num={339} /> the volunteers which are around Richmond, in the city. I
              understand that the Armory at Harper's Ferry has been burned and that damage has been done to the Navy Yard at Portsmouth. I think, sir, that orders might be given to the volunteer troops to concentrate in or around Richmond ready to move to any point to which they may be ordered. Otherwise, when such an order is given, they will be found scattered in different parts of the country,
              and of course unable to meet as promptly as if concentrated at one point.
            </p>
            <p className="p-in-sp">Would it not be well to have some 12 or 1500 troops concentrated at once in the city of Richmond so that they may be instantly ordered to any point necessary?</p>
            <div className="speaker" id="sp4317"><span className="persName">Mr. EARLY</span>—</div>
            <p className="p-in-sp">The Military Committee have nothing to do with the planning of the campaign or marching of troops. All that the committee have to do is, to put in the power of the Governor the means of calling out troops.</p>
            <div className="speaker" id="sp4318"><span className="persName">Mr. RICHARDSON</span>—</div>
            <p className="p-in-sp">I had supposed the committee would not be very formal, and that they probably had taken occasion to consult with the Governor in reference to matters appertaining to their peculiar duty. I had supposed that they might have conferred with him on this subject, and have the inquiry which I made.</p>
            <div className="speaker" id="sp4319"><span className="persName">Mr. EARLY</span>—</div>
            <p className="p-in-sp">
              I recognize the necessity of having some efficient staff corps organized. I think it is necessary to provide for some efficient general officers to take charge of our operations and of all the forces that may be in the field. We have now five or six Generals of militia in the field, and the Governor has, I understand, appointed two Major Generals of volunteers to take command at once.
            </p>
            <div className="speaker" id="sp4320"><span className="persName">Mr. RICHARDSON</span>—</div>
            <p className="p-in-sp">I did not yield the floor to afford the gentleman an opportunity of making a speech.</p>
            <p className="p-in-sp">I desire to know whether there is any purpose to order volunteers to the city of Richmond?</p>
            <div className="speaker" id="sp4321"><span className="persName">Mr. SCOTT</span>, of Powhatan—</div>
            <p className="p-in-sp">I cannot answer the question. I believe there is, however. The Governor said there was a purpose of that sort entertained, but I do not know when they are to be concentrated here, nor how.</p>
            <div className="speaker" id="sp4322"><span className="persName">Mr. RICHARDSON</span>—</div>
            <p className="p-in-sp">I can only say, that I made this inquiry for the best purpose in the world, and not for the purpose of taking up the time of the Convention. I will state, that if such a force is ordered to Richmond, quarters could be obtained at the Fair Grounds.</p>
            <div className="speaker" id="sp4323"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">I beg leave to submit the following communication from the Governor of the Commonwealth:</p>
            <PageNumber num={340} />
            <div className="floatingText">
              <div className="floatingText_body">
                <p>EXECUTIVE DEPARTMENT,</p>
                <p>April 21st, 1861.</p>
                <p>Gentlemen of the Convention:</p>
                <p>I hereby nominate Capt. M. F. Maury, as the third member of the Advisory Council, provided for in your ordinance.</p>
                <p>Respectfully,</p>
                <p>JOHN LETCHER.</p>
              </div>
            </div>
            <div className="speaker" id="sp4324"><span className="persName">Mr. MORTON</span>—</div>
            <p className="p-in-sp">I move that that nomination be unanimously confirmed.</p>
            <p>The question on this nomination was put and decided in the affirmative.</p>
            <div className="speaker" id="sp4325"><span className="persName">Mr. MORTON</span>—</div>
            <p className="p-in-sp">I move now that that action be immediately communicated to the Governor.</p>
            <div className="speaker" id="sp4326"><span className="persName">Mr. SHEFFEY</span>—</div>
            <p className="p-in-sp">I move the following as a substitute for that motion :</p>
            <p className="p-in-sp">"Resolved, That the Secretary of this Convention furnish the Hon. John J. Allen, Col. F. H. Smith and Capt. M. F. Maury with a copy of the ordinance appointing them members of the Advisory Council of the Governor of the Commonwealth."</p>
            <p>The resolution was adopted.</p>
            <div className="speaker" id="sp4327"><span className="persName">Mr. MORTON</span>—</div>
            <p className="p-in-sp">It is important that the Governor should at once know of the confirmation of Capt. Maury, and, as the communication referred to would require some time on the part of the Secretary, I trust it will be the pleasure of the President to instruct the Secretary to communicate immediately to the Governor the action of the Convention on Capt. Maury's nomination.</p>
            <p>The report of the Committee was again taken up.</p>
            <div className="speaker" id="sp4328"><span className="persName">Mr. EARLY</span>—</div>
            <p className="p-in-sp">I wish to make a statement to the Convention as to the reason, in my opinion, why this ordinance is reported this morning.</p>
            <p className="p-in-sp">
              It is the scheme of a gentleman of considerable distinction in the State as a military man, and it is a pet scheme of his, which he has been maturing for a year or two. The Committee, after considering it for a short time this morning, agreed to report it, because they thought the necessity of our position required imperatively that something should be done. I acknowledge the
              necessity. We stand in need of some competent general officer, with an efficient staff. There are some five or six major generals of militia in the field, and the Governor has also called into service two major generals of volunteers; but, according to my view of the ordinance adopted the other day in <PageNumber num={341} /> great haste, he has not now
              any authority to appoint a major general of volunteers. That ordinance authorized him to call into service as many of the volunteers of the Commonwealth as may be required to repel invasion, &amp;c., and to receive them in companies and organize them into regiments, brigades and divisions. I wish to state that this creates a new volunteer service, in which they may be called into
              service for any length of time. The organization of the companies is to take place now, and new companies already organized may tender their services if they choose.
            </p>
            <p className="p-in-sp">I propose to offer an amendment to this ordinance by way of a substitute for the first portion of it.</p>
            <p className="p-in-sp">It reads as follows :</p>
            <p className="p-in-sp">
              "Be it ordained, That the Governor of the Committee be authorized and required to appoint, by and with the advice and consent of this Convention, two major generals and four brigadier generals for the military forces of the State, who shall be assigned to duty with the military forces which shall be called into the actual service of the State, in preference to all major and brigadier
              generals of the volunteers and militia, and shall take rank and precedence over them, but shall be subordinate to the commander of the military and naval forces, provided for by the ordinance on the 19th inst."
            </p>
            <div className="speaker" id="sp4329"><span className="persName">Mr. EARLY</span>—</div>
            <p className="p-in-sp">
              I do think it is time to speak out boldly and plainly. I do think that the necessities of the country require that we should have efficient men and we ought not to be governed by any sentiment of delicacy with regard to persons already in the service. My opinion is that the necessities of the State require that all the Generals of Militia should be decapitated. I offer my own head on
              the block as a willing victim for the good of the Commonwealth.
            </p>
            <p className="p-in-sp">
              I offer this proposition as a substitute for the first part of the report, and I trust it will be the pleasure of the Convention to adopt it. If we do publish an ordinance to raise an army, we cannot tell to what extent officers may be commissioned. It will impede the organization of volunteers, and that is the force that we must now rely upon; and if it is necessary hereafter to
              organize a regular army, the volunteer system will prove the most efficient aid to effect that organization. This amendment provides for the appointment of Generals to command all these forces; and, in my opinion, there will be Generals enough to command all the forces that may be called into the field.
            </p>
            <div className="speaker" id="sp4330"><span className="persName">Mr. BENJ. F. WYSOR</span>, of Pulaski—</div>
            <p className="p-in-sp">It seems to be the general opinion that the time for action has arrived, and I therefore rise to call the previous question.</p>
            <PageNumber num={342} />
            <p>The call was sustained, and the main question ordered to be put, whereupon the vote was taken on the amendment of Mr. Early, and was decided in the negative.</p>
            <div className="speaker" id="sp4331"><span className="persName">Mr. JOHNSTON</span>, of Lee and Scott—</div>
            <p className="p-in-sp">I withdraw my amendment.</p>
            <div className="speaker" id="sp4332"><span className="persName">Mr. KILBY</span>—</div>
            <p className="p-in-sp">I call the previous question.</p>
            <p>The call was sustained.</p>
            <div className="speaker" id="sp4333"><span className="persName">Mr. BALDWIN</span>—</div>
            <p className="p-in-sp">
              I would ask the gentleman to withdraw that motion. I will state, by way of argument, that I have prepared an amendment to the ordinance reported by the committee, which provides for the immediate consideration of everything that is necessary to meet the present exigency, leaving out that question as to which there is difference of opinion, and about which there is abundance of time for
              us to deliberate, under circumstances, when we can come to a wiser conclusion. And, it does seem to me, that it is unjust to this body to press upon it a proposition of doubt and difficulty, simply because there are features in it requiring prompt action. I think it is a fair and just ground to take, and one that we might all concur in; that we should act upon what is now of pressing
              necessity. I do not wish that we should here concur in a question of permanent policy; I do not wish that we should do anything to cause unnecessary delay; I do not wish that we should throw any obstacle in the way of the most efficient organization; but I appeal to the House to say, whether we are to have ourselves dragged into a system of the most serious consequences without the
              slightest opportunity for a consideration of it, when it is admitted that it is of no pressing haste.
            </p>
            <p className="p-in-sp">Let us do to-day—Sunday as it is—all that is required to be done ; but do not press us into doing anything more. It strikes me that this system of hot pressure is entirely unnecessary, and that the amendment which I have prepared will furnish all that the exigency requires.</p>
            <p className="p-in-sp">It reads as follows:</p>
            <p className="p-in-sp">Be it ordained, That the Governor of Virginia is empowered and directed to organize as the exigency of the service may require :</p>
            <p className="p-in-sp">1. A subsistence department, to be composed of one colonel, one lieutenant colonel, two majors, and four captains.</p>
            <p className="p-in-sp">2. A quartermaster's department, of one colonel, one lieutenant colonel, two majors and four captains.</p>
            <p className="p-in-sp">3. A pay department, to be composed of four paymasters, with the rank of major of cavalry.</p>
            <p className="p-in-sp">4. A medical department, to be composed of one surgeon general with the rank of colonel of cavalry, ten surgeons, and ten assistant surgeons.</p>
            <p className="p-in-sp">5. An adjutant general's department, to be composed of one adjutant <PageNumber num={343} /> general of the rank of colonel of cavalry, with authority to the commander-in-chief to detail as many officers as assistant adjutant generals as the service may require.</p>
            <p className="p-in-sp">This ordinance shall be in force from its passage.</p>
            <div className="speaker" id="sp4334"><span className="persName">Mr. BALDWIN</span>—</div>
            <p className="p-in-sp">The House will perceive that it embraces everything in the report except the disputed point about the standing army. There is not a member in the House, I believe, who will say that that point requires to be settled to-day. If so, why not separate these propositions and adopt those which are of pressing necessity.</p>
            <div className="speaker" id="sp4335"><span className="persName">Mr. ECHOLS</span>—</div>
            <p className="p-in-sp">Is your amendment the same as that part of the report of the committee which refers to the subjects embraced in your amendment?</p>
            <div className="speaker" id="sp4336"><span className="persName">Mr. BALDWIN</span>—</div>
            <p className="p-in-sp">Precisely, sir. I have said nothing about Major Generals, or Brigadier Generals. My amendment only embraces that which is required to be acted upon to-day.</p>
            <div className="speaker" id="sp4337"><span className="persName">Mr. ECHOLS</span>—</div>
            <p className="p-in-sp">So far as I am concerned, I think the proposition of the gentleman from Augusta [<span className="persName">Mr. BALDWIN</span>] is a reasonable one. We don't care to-day about the appointment of Major Generals or Brigadier Generals, and our policy should therefore be to discharge that duty which is of imperative necessity, leaving to a future day the consideration of that which is not of pressing necessity now.</p>
            <div className="speaker" id="sp4338"><span className="persName">Mr. JOHNSTON</span>, of Lee and Scott—</div>
            <p className="p-in-sp">I desire to append to the ordinance, as an independent section, which will remove all difficulties suggested, the following:</p>
            <p className="p-in-sp">"The force hereby directed to be raised shall be appointed and commissioned by the Governor at such times as the exigency of the service shall require."</p>
            <div className="speaker" id="sp4339"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">I understand that the question now before the Convention is, shall the main question be put upon the adoption of the ordinance. An appeal has been made to the gentleman who called the previous question to withdraw it. Has any response been made to the appeal?</p>
            <div className="speaker" id="sp4340"><span className="persName">Mr. KILBY</span>—</div>
            <p className="p-in-sp">I made the call for the previous question, but I now withdraw it.</p>
            <div className="speaker" id="sp4341"><span className="persName">Mr. BALDWIN</span>—</div>
            <p className="p-in-sp">I now offer my amendment.</p>
            <div className="speaker" id="sp4342"><span className="persName">Mr. TURNER</span>, of Jackson—</div>
            <p className="p-in-sp">I call the previous question.</p>
            <p>The call was sustained and the main question ordered to be put, when the vote upon <span className="persName">Mr. BALDWIN</span>'S substitute was put, and decided in the affirmative.</p>
            <p>On motion of <span className="persName">Mr. PRICE</span>, of Greenbrier, the Convention adjourned at five minutes to one o'clock, P.M.</p>
          </div>
          <div className="day" id="vsc1965.v4.2.7">
            <PageNumber num={344} />
            <h2><span className="headingNumber">1.7. </span><span className="head">SEVENTH DAY</span></h2>
            <div className="dateline">Monday, <span className="date">April 22</span></div>
            <p>The Convention met at 12 o'clock. Prayer by <span className="persName">Mr. ADDISON HALL</span>, a member of the Convention from Lancaster and Northumberland.</p>
            <div className="speaker" id="sp4343"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">The following is the Committee ordered to be appointed under the resolution offered on Saturday last by <span className="persName">Mr. STAPLES</span>, to prepare an address to the people of Virginia setting forth the causes which impelled the Convention to pass the ordinance of Secession, &amp;c:</p>
            <p className="p-in-sp">Messrs. <span className="persName">S. G. STAPLES</span> of Patrick, <span className="persName">JAMES P. HOLCOMBE</span> of Albemarle, <span className="persName">WOOD BOULDIN</span> of Charlotte, <span className="persName">WM. M. AMBLER</span> of Louisa, and <span className="persName">JAMES W. SHEFFEY</span> of Smyth.</p>
            <div className="speaker" id="sp4344"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">I beg leave to announce the following Committee ordered to be appointed under the amendment offered by the gentleman from Madison [<span className="persName">Mr. BLAKEY</span>] , to a resolution offered on Saturday last by the gentleman from Gloucester [<span className="persName">Mr. SEAWELL</span>]
              <span className="note" id="Note35"><span className="noteLabel">1</span>See note 3 on the Proceedings of April 20 for the resolution and amendment.</span> in relation to the action which the Convention should take as to the approaching Congressional election in this State-Messrs. <span className="persName">A. R. BLAKEY</span> of Madison, <span className="persName">J. T. SEAWELL</span> of Gloucester,
              <span className="persName">CYRUS HALL</span> of Pleasants and Ritchie, <span className="persName">C. C. FUGATE</span> of Scott, and <span className="persName">F. B. MILLER</span> of Botetourt and Craig.
            </p>
            <div className="speaker" id="sp4345"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">I beg leave to submit a communication from the Governor of the Commonwealth for the consideration of the Convention.</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>EXECUTIVE DEPARTMENT,</p>
                <p>April 22nd, 1861.</p>
                <p>Gentlemen of the Convention:</p>
                <p>I have received from His Excellency, Jefferson Davis, President of the Confederate States of America, a communication, informing me that he had appointed Vice-President Stephens special Commissioner to the Commonwealth of Virginia. A commission duly executed accompanies the communication, and both are herewith transmitted for your consideration.</p>
                <p>Vice-President Stephens has arrived, and is stopping at the Exchange Hotel.</p>
                <p>Respectfully,</p>
                <p>JOHN LETCHER.</p>
                <PageNumber num={345} />
                <p>To His Excellency, John Letcher, Governor of the State of Virginia, &amp;e:</p>
                <p>
                  SIR-In response to your communication conveying to me, in behalf of the State of Virginia, the expression of the earnest desire of that Commonwealth to enter into an alliance offensive and defensive with the Confederate States, and being animated by a sincere wish to unite and bind together our respective countries by friendly ties, I have appointed Alexander H. Stephens,
                  Vice-President of the Confederate States, as special Commissioner of the Confederate States to the Government of Virginia, and I have now the honor to introduce him to you, and to ask for him a reception and treatment corresponding to his station, and to the purposes for which he is sent. Those purposes he will more particularly explain to you.
                </p>
                <p>Hoping that, through his agency, these may be accomplished, I avail myself of this occasion to offer to you the assurances of my distinguished consideration.</p>
                <p>JEFFERSON DAVIS.</p>
                <p>MONTGOMERY, April 19, 1861.</p>
                <p>The following is a copy of <span className="persName">Mr. STEPHENS</span> credentials: JEFFERSON DAVIS,</p>
                <p>President of the Confederate States.</p>
                <p>
                  To all whom these presents shall concern, greeting : Know ye, that for the purpose of establishing friendly relations between the Confederate States of America and the Commonwealth of Virginia, and reposing special trust and confidence in the integrity, prudence and ability of Alexander H. Stephens, Vice-President of the Confederate States of America, appointed special Commissioner
                  of the Confederate States of the Commonwealth of Virginia, I have invested him with full and all manner of power and authority for, and in the name of the Confederate States, to meet and confer with any person or persons authorized by the Governor of Virginia, being furnished with like power and authority, and with him or them to agree, treat, consult and negotiate of, and
                  concerning all matters and subjects interesting to both republics; and to conclude any treaty or treaties, Convention or Conventions, touching the premises, transmitting the same to the President of the Confederate States, for his final ratification, by and with the advice and consent of the Congress of the Confederate States.
                </p>
                <PageNumber num={346} />
                <p>In testimony whereof I have caused the seal of the Confederate States to be hereunto affixed.</p>
                <p>Given under my hand, at the City of Montgomery, this nineteenth day of April, A.D., 1861.</p>
                <p>JEFFERSON DAVIS.</p>
                <p>By the President :</p>
                <p>R. TOOMBS,</p>
                <p>Secretary of State.</p>
              </div>
            </div>
            <div className="speaker" id="sp4346"><span className="persName">Mr. KILBY</span>—</div>
            <p className="p-in-sp">I move that the communication of the Governor, with the accompanying papers, be laid upon the table.</p>
            <p>The motion was agreed to.</p>
            <div className="speaker" id="sp4347"><span className="persName">Mr. JOHN GOODE, Jr.</span>, of Bedford—</div>
            <p className="p-in-sp">I beg leave to offer the following resolution:</p>
            <p className="p-in-sp">Resolved, That a Committee of three be appointed to wait upon the Hon. A. H. Stephens, Commissioner from the Government of the Confederate States, and invite him to communicate, in person, with this body, at such time as may best suit his convenience.</p>
            <div className="speaker" id="sp4348"><span className="persName">Mr. WILSON</span>, of Harrison—</div>
            <p className="p-in-sp">I will ask the gentleman, from the county of Bedford, to add thereto an invitation to the Hon. R. M. T. Hunter and the Hon. James M. Mason, Senators from Virginia in the late United States Senate.</p>
            <div className="speaker" id="sp4349"><span className="persName">Mr. GOODE</span>—</div>
            <p className="p-in-sp">I don't want to connect an invitation to these gentlemen with the resolution I have offered.</p>
            <p>The resolution was adopted.</p>
            <div className="speaker" id="sp4350"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">The business in order now is the report of the committee on taxation.</p>
            <div className="speaker" id="sp4351"><span className="persName">Mr. KILBY</span>—</div>
            <p className="p-in-sp">I move that that report be laid upon the table.</p>
            <div className="speaker" id="sp4352"><span className="persName">Mr. WOODS</span>, of Barbour—</div>
            <p className="p-in-sp">I ask for the yeas and nays upon the motion.</p>
            <p>The call was sustained.</p>
            <div className="speaker" id="sp4353"><span className="persName">Mr. JAMES B. MALLORY</span>, of Brunswick—</div>
            <p className="p-in-sp">I don't think there is a quorum present.</p>
            <div className="speaker" id="sp4354"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">The chair will count to ascertain whether or not there is a quorum in the House.</p>
            <p>The President counted and discovered that there were but 71 members present. Other members, however, soon entered the Hall, and after a few minutes suspension of proceedings, a quorum was found to be present.</p>
            <div className="speaker" id="sp4355"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">The following is the Committee ordered to be appointed under the resolution offered by <span className="persName">Mr. GOODE</span>, to wait on the <PageNumber num={347} /> Vice President of the Confederate States: John Goode, Jr., of Bedford, Jeremiah Morton, of Orange and Greene, and John T. Thornton, of Prince Edward.</p>
            <div className="speaker" id="sp4356"><span className="persName">Mr. WOODS</span>—</div>
            <p className="p-in-sp">Perhaps I owe it to the Convention to make an explanation for my call of the yeas and nays upon the motion to lay this report upon the table. I desire my people to know where the members are when the vote is taken.</p>
            <div className="speaker" id="sp4357"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">The Chair has received a communication from the Governor of the Commonwealth which he begs leave to lay before the Convention. The Chair thinks it his duty to present immediately upon their receipt all communications received from the Governor.</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>EXECUTIVE DEPARTMENT,</p>
                <p>April 22, 1861.</p>
                <p>Gentlemen of the Convention:</p>
                <p>I consider it my duty to communicate to you a telegraphic despatch received this morning from John S. Barbour, Esq.</p>
                <p>
                  After conference with the Council, I determined to send the arms requested, and accordingly ordered Major General Harper, in command at Harper's Ferry, to send them at once to Gen. Stewart, at Baltimore. If the Convention had been in session, I would have communicated the despatch to you, and would have been controlled by your wishes. The case was urgent and delay might have
                  subjected our brethren of Maryland to serious danger.
                </p>
                <p>Respectfully,</p>
                <p>JOHN LETCHER.</p>
                <p>The following is the telegraphic despatch referred to:</p>
                <p>ALEXANDRIA, April 22, 1861.</p>
                <p>To Governor John Letcher:</p>
                <p>
                  A messenger has arrived here this morning from General Stewart in command of Baltimore troops, addressed to commander-in-chief of Virginia at this point, asking assistance to resist the passage of federal forces through that State. There are some three thousand soldiers now in camp about ten miles from Baltimore, awaiting reinforcements before proceeding to Washington. Baltimore is
                  almost bare of arms, and the request is for a temporary loan from Virginia. I am requested to <PageNumber num={348} /> ask you for an order to send General Stewart one thousand of the guns now at Harper's Ferry. Answer by telegraph as party is waiting for reply.
                </p>
                <p>J. S. BARBOUR.</p>
              </div>
            </div>
            <p>The communication and dispatch were laid upon the table.</p>
            <p>
              The report from the Committee on Taxation then came up, and the question being taken on the motion of Mr. Kilby to lay it on the table, was decided in the negative-yeas 39, nays 45, as follows:<span className="note" id="Note36"><span className="noteLabel">2</span>The Journal adds Johnston, Sitlington, Spurlock, and Tayloe to the negative votes given here.</span>
            </p>
            <p>YEAS-Messrs. Janney [President] , James Barbour, Blakey, Borst, Bouldin, Bruce, Cabell, Chambliss, Conn, R. Y. Conrad, J. H. Cox, Critcher, Fisher, Flournoy, Garland, Gravely, Gregory, John Goode, Jr., A. Hall, Kilby, Leake, Macfarland, J. B. Mallory, Marshall, Marye, Morris, Morton, Neblett, Rives, Slaughter, Southall, Speed, Strange, Thornton, Tredway, R. H. Turner, Waller, White, Williams-39.</p>
            <p>
              NAYS-Messrs. Aston, Baldwin, Berlin, Boyd, Campbell, Caperton, Cecil, Chapman, Coffman, Deskins, Dorman, Early, French, Gillespie, Gray, Goggin, Cyrus Hall, Ephraim B. Hall, L. S. Hall, Harvie, Haymond, Holcombe, Hull, Marmaduke Johnson, Kent, Lawson, McComas, McNeil, Miller, Moffett, Moore, Parks, Preston, Pugh, Sharp, Sheffey, Staples, Franklin P. Turner, Wilson, Woods, Wysor-45.
            </p>
            <p>So the motion to lay on the table was lost.</p>
            <p>The question then recurred on the amendment offered by Mr. Bruce of Halifax, which was pending when the Convention adjourned on Saturday. It reads—</p>
            <p>"Provided, that slaves over 12 years of age, shall not be valued for more than $500, and that slaves under 12 years shall not be valued for more than $250."</p>
            <div className="speaker" id="sp4358"><span className="persName">Mr. HALL</span>, of Wetzel—</div>
            <p className="p-in-sp">I have not hitherto occupied much time upon this question; but I now desire to say a few words with reference to it.</p>
            <p className="p-in-sp">
              Many of the Western members have left for their homes under the impression that the question would not be acted upon until the adjourned session. It was, however, brought up at the instance of some Eastern gentlemen, and it is for them now to say whether the action initiated by them shall be carried to a successful termination, or whether it shall fall still born, destined perhaps
              never again to be considered by this body. The West had no agency in bringing the matter forward now. They were willing to abide the understanding with which the committee on this subject was raised. They will not now press it, if it is not the pleasure of Eastern gentlemen to act upon it. If they do not pass it, they certainly cannot justify their action upon
              <PageNumber num={349} /> the score of justice or fair play to the section which I in part represent. Indeed, the justice of this demand has been admitted by gentlemen from all sections.
            </p>
            <p className="p-in-sp">
              The principle of ad valorem taxation is universally recognized as the only just and proper basis of taxation that can be determined. How, then, can any man object to it? How, moreover, can he object to the valuation by himself of his own property, for that is to be the rule save where a palpable diminution of the value of the property is discovered; and, then, the Commissioner of the
              Revenue steps in and makes his valuation. And even when it comes to this, the property holder must needs be fairly dealt with, for he elects his own Commissioner, who will be rather disposed to diminish than increase the value of the property.
            </p>
            <p className="p-in-sp">For my part, I have no idea that we will get any more revenue through the ad valorem principle than we would by adopting a plan of specific taxes; but it is a just principle and should, therefore, be adopted without regard to the results which are to follow.</p>
            <p className="p-in-sp">
              I will say, that I would be very much gratified if Eastern gentlemen would take up this matter and pass it. I have risked my all upon the question, which concerns the East. My property is on the Ohio river, and I am in danger of having all taken from me. I would be extremely gratified if our Eastern brethren would come forward and remove this only source of difficulty among our people.
            </p>
            <p className="p-in-sp">
              I am afraid, at this moment, that men are working upon the minds of our people, upon this question, so as to alienate the people of the West from the people of the East. They are using this question as a lever to produce this estrangement; and I trust, therefore, that it will be the pleasure of the Convention to reject this amendment, and adopt the report. I am satisfied that the
              principle embraced in the gentleman's amendment would yield more revenue than that proposed by the Committee; but I hold to the ad valorem principle, and I trust that the Convention will adopt it. If they do they will remove the chief, if not the only argument upon which our enemies in the West have to rely. Do us justice in this matter, and we will meet them with effect.
            </p>
            <div className="speaker" id="sp4359"><span className="persName">Mr. JOHN A. CAMPBELL</span>, of Washington Co.—</div>
            <p className="p-in-sp">
              I hope the Convention will not adopt this amendment. As I understand it, it fixes a valuation beyond which you shall not go, in assessing taxes upon negro property. It proposes to fix the value of negroes over twelve years of age at $500, and negroes under twelve at $250. I am opposed to that amendment, because it restricts the Commissioner of the Revenue from
              <PageNumber num={350} /> transcending these limits, no matter how valuable the slave may be; whilst if the negro is worth less than the amounts set down, a reduction must be made in the assessment.
            </p>
            <p className="p-in-sp">
              There is another reason why I shall oppose any amendment to the report of the Committee. That report proposes that negro property shall be placed upon the same footing with every other description of property. That is the true principle upon which taxation should be based, according to all admissions; and I trust, therefore, that the amendment may be voted down, and the report adopted.
            </p>
            <div className="speaker" id="sp4360"><span className="persName">Mr. FISHER</span>, of Northampton—</div>
            <p className="p-in-sp">I desire to make only a few remarks upon this question.</p>
            <p className="p-in-sp">
              These gentlemen who have expressed opinions in regard to this amendment seem to me not to understand or appreciate the expenses incident to the raising of young negroes. I know from my own experience and observation, that a young slave is entirely helpless and a dead expense until he attains the age of eight years. I know, moreover-and the lists of mortality show it-that a large
              proportion of young negroes as well as young white people, die between the birth and the age of five years; and up to that period, the dictates of humanity absolutely require that they should not be separated from their mother.
            </p>
            <p className="p-in-sp">I know, no man, at least in my section of the State, who is so unfeeling as to separate a child under five years old from its mother. Certainly no man in that section would voluntarily do it.</p>
            <p className="p-in-sp">
              Now, sir, according to the notions of gentlemen here, these young negroes that are, as I have said, an expense to their owners, are to be valued as though they were separated from their mothers and rendering some service to their masters. They are valued, at the age of one or two, at perhaps $100, when at the very next moment after the valuation and assessment is made, they may die,
              leaving the owner liable for the tax upon them besides the actual loss of slaves. Slaves are not like cattle. They are not like lands,<span className="note" id="Note37"><span className="noteLabel">3</span>This should perhaps be "lambs," which would fit the context better than "lands."</span> nor are they like kids and young calves, that may be put into market and sold five or ten days after
              birth; that can be converted into money at once without doing any violence to the feelings of humanity, or without violating any Christian principle whatever. And gentlemen are insisting that these young negroes, before they are twelve years of age should be valued at their full market value, that is what they would bring from a negro trader upon the block. Negro traders are to be
              found in almost every county; and an individual who may be a resident of the county, and who would desire to purchase some favorite servant, when put upon <PageNumber num={351} /> the block, must pay the same price at which he or she would be sold to the negro trader; and the consequence would be that the Commissioner of the Revenue, acting under his oath
              of office, would value the slave at the price it brought on the block.
            </p>
            <p className="p-in-sp">But, say our friends on the other side, you elect the commissioner of the revenue, who is to assess the value of your property. I know that my friend from Wetzel [<span className="persName">Mr. HALL</span>]
              , who has used this argument, did not mean to cast reproach upon the slaveholding community by insinuating that they would not elect a man who would discharge his duty faithfully and strictly observe his official oath. Sir, I would not vote for any man as commissioner of the revenue who would be so false to his obligations as to assess my property at a lower rate than its value would
              justify. My experience, as well as the experience of every slaveholder, is that slaves are fluctuating in value. I have not paid more than $300 a piece for the best slaves I have when they were 21 years of age. Since that time they have gone up to $1,000, and even in some instances to $2,000. Now, however, they are very much depressed in value; and, if this war is to go on, I dare say
              they will come down below $500. I have known a most valuable man to sell for $250; and it may be that the depression consequent .upon the war would reduce them to nearly the same standard again.
            </p>
            <p className="p-in-sp">
              Now, the proposition of the gentleman from Halifax is, that there shall be a maximum value for slaves above 12 years of age, and a maximum value for slaves under 12 years. I assure you, sir, that in my county slaves at the age of 13 or 14 will not bring more than $500 to-day. Species of property will not at all bear the ad valorem principle of taxation, according to the ideas of
              gentlemen as to the mode of valuation. If you tax that species of property according to the ad valorem principle, I have no doubt that what the gentleman from Halifax
              [<span className="persName">Mr. BRUCE</span>]
              predicted, will be realized. A slaveholder, who has a surplus of slave property, will sell the slaves, because he cannot afford to keep them in the State of Virginia, and the consequence will be to force this subject of taxation from the State. Instead of the people of the West and of all sections of the State being served by this principle, they will be injured by it. Why should this
              question be pressed now by gentlemen from the East or West, with the house now constituted, according to the last vote of but 84 members out of 152, and when, as the gentleman who brought in the report told you the other day, the understanding of the Convention at the time the committee was raised, was that it would not be acted upon until the adjourned session of the Convention? Why,
              then, should it be pressed at <PageNumber num={352} /> this time? Why should a constitutional amendment be forced upon the State of Virginia by a body composed now of but 84 members out of 152? I am one of those who believe in the ad valorem principle. I have ever believed in it, and I do not mean now to make war upon that principle, but I ask gentlemen
              to forego action upon it now, under the disadvantages and embarrassments which beset us; and await a deliberate consideration of the whole subject by a committee composed of gentlemen from all sections of the State. They can mature such a principle of taxation as, I have no doubt, will be satisfactory to all our people; and the Convention can at its adjourned session act intelligently
              upon it. In the mean time, we shall have an opportunity of canvassing the wishes of our constituents in respect to this matter, and return here prepared to act promptly upon it. If this policy is adopted, I shall return to my people and advise them to agree to the ad valorem principle, if the committee should determine to report in favor of its adoption. These Western gentlemen can
              suffer no inconvenience from this delay, because, as was remarked by the gentleman from Kanawha
              [<span className="persName">Mr. SUMMERS</span>]
              , the principle recommended by the committee, cannot be enforced during the current year, even if adopted now. Now, sir, I take it for granted that if this Convention shall take a recess, it will re-assemble before two-thirds of the year has passed, and by that time our relations with the Federal Government, and the Confederate Government may have attained a stable and permanent
              character, thus enabling us to act in full view of the important events which may have transpired during the interval. When we shall have gotten clear of all the complications resulting from our recent actions, and shall have fully established our independence in union with the Confederate States, then will be the time to consider this question of taxation and to dispose of it
              effectually to the entire satisfaction of all sections. We can then meet together, free from the distractions and embarrassments which now surround us, with no hostile interests to mar our action; but like brothers engaged in a common cause, anxious to mete out its full measure of justice to every portion of the Commonwealth.
            </p>
            <p className="p-in-sp">I beg leave to say that I am individually anxious to remove any cause of grievance that may exist in any part of the Commonwealth, and to promote union and harmony among all our people.</p>
            <p className="p-in-sp">I trust that gentlemen from the West, nor East, who are interested in this matter, will not press it now; but that they will allow it to hold over until the adjourned session, when we can all return with a <PageNumber num={353} /> full knowledge of our people's wishes on the subject, prepared to act as they may direct.</p>
            <div className="speaker" id="sp4361"><span className="persName">Mr. BRANCH</span>, of Petersburg—</div>
            <p className="p-in-sp">I am quite surprised to find the subject of taxation up again this morning in so thin a House as we now have. I am the more surprised after hearing the remarks of the gentleman from Kanawha [<span className="persName">Mr. SUMMERS</span>] , on last Friday; and after hearing, also, the remarks of the gentleman from Monongalia [<span className="persName">Mr. WILLEY</span>]
              , on Saturday last. The gentlemen who press the consideration of this subject must be convinced that this is not the, proper time to act upon it. I will say, that I am astonished that any Eastern man would say that negroes are not sufficiently taxed. I speak now the sentiments that I feel, and I tell these gentlemen-Eastern and Western-that when we convene here again, I will go heart
              and hand for an equitable tax on all descriptions of property. But this is not the time to consider this question. War is now upon us; and this is by no means an appropriate subject to be considered, when matters affecting the safety of our lives and property press upon us for consideration.
            </p>
            <p className="p-in-sp">
              But there is no need for this haste which gentlemen evince in regard to this tax question. If the principle is changed, now, you cannot levy any tax under it this year. If we meet here in the Fall, or in June, we will be prepared to consider this question more maturely; but, being elected without any reference to this tax question, we are necessarily unprepared to vote upon it,
              inasmuch as we are ignorant of the feelings of our constituents upon the subject. I regard it as unjust to take it up now and act upon it with so thin a house. The fact is, we are acting here too precipitately, and without anything like due reflection. It would seem, sir, as if this Convention had lost its balance, for its course for the past few days has been by no means such as
              should characterize a body of grave, prudent and wise men, such as I have always believed this Convention to be composed of. I feel actually ashamed of a vote I gave on Friday last; but I have learned from my imprudence then to be more cautious in the future. I am not going into the subject of taxation, or any other subject, rashly again, and I trust that other gentlemen will observe
              like caution.
            </p>
            <p className="p-in-sp">The amendment of the gentleman from Halifax [<span className="persName">Mr. BRUCE</span>]
              proposes to fix the maximum value of slaves over twelve years of age at $500, and those under twelve at $250. I do not consider that estimate exorbitant, but I complain of the inopportune time when this subject is introduced. I warn gentlemen from the West not to precipitate this matter at this time, because they will, I venture to say, have to be content with less now than they would
              be required to accept it at a <PageNumber num={354} /> future time, and under more favorable circumstances than now surround us. I should certainly suppose that, in any event, some consideration should be given to the expense of raising negroes, in fixing the value of that description of property. When raised, they have to receive peculiar attention in
              the shape of comfortable dwellings, medical attendance and a series of other requirements not necessary for any other species of property. I am for an equitable tax, a just tax; but, as I have already said, this is no time to adjust it, and there is no pressing need for its adjustment, inasmuch as no levy can be made under the ad valorem principle this year, even if it was adopted now.
              I am really surprised that the Convention should entertain a proposition of this kind, at a time when we have need to employ all our available time in devising means by which to meet the expenses of this war which is now at our doors. I am surprised at Eastern gentlemen voting to take up this report, when the gentleman from Kanawha
              [<span className="persName">Mr. SUMMERS</span>]
              says that the time has not arrived to consider and act upon it. I trust that the Convention will be governed by the opinion of that gentleman in this matter. It is rendered against the interest of his own section, and should, therefore, carry weight and influence with Eastern gentlemen who are, unaccountably to me, interesting themselves so zealously in behalf of this project.
            </p>
            <p className="p-in-sp">
              I trust we will not act upon this question now. I said to the gentlemen from the Northwest the other day that they lost nothing by the exemption granted to negro property, for the East is assessed to the extent of about $460,000 a year tax upon merchants' licenses. So that, gentlemen of the West, while negroes have not paid their proper proportion of tax, you have a full equivalent for
              that draw-back in the tax upon merchants' licenses.
            </p>
            <p className="p-in-sp">I now move to lay the whole subject on the table.</p>
            <div className="speaker" id="sp4362"><span className="persName">Mr. WILSON</span>—</div>
            <p className="p-in-sp">I call for the yeas and nays upon that motion.</p>
            <p>
              The call was sustained, and the vote being put, resulted-yeas 48, nays 38-as follows:<span className="note" id="Note38"><span className="noteLabel">4</span>The Journal adds Spurlock to the negative votes listed here.</span>
            </p>
            <p>YEAS-Messrs. Janney [President]
              , Ambler, James Barbour, Blakey, Borst, Bouldin, Branch, Bruce, Cabe11, Caperton, Chambliss, Conrad, James H. Cox, Critcher, Fisher, Flournoy, Garland, Gravely, Gregory, Jr., Goggin, John Goode, Jr., Addison Hall, Harvie, Holcombe, Marmaduke Johnson, Kilby, Leake, Macfarland, James B. Mallory, Marshall, Marye, Sr., Morris, Morton, Neblett, Price, Richardson, Rives, Slaughter, Southall,
              Speed, Strange, Thornton, Tredway, Robert H. Turner, Waller, White, Williams, Woods-48.
            </p>
            <p>
              NAYS-Aston, Baldwin, Berlin, Boyd, Campbell, Cecil, Chapman, Coffman, Deskins, Dorman, Early, Echols, French, Gillespie, Cyrus Hall, Ephraim B. Hall, L. S. Hall, Haymond, Hull, Peter C. Johnston, Lawson, McComas, McNeil, <PageNumber num={355} /> Miller, Moffett, Moore, Parks, Preston, Pugh, Sharp, Sheffey, Sitlington, Staples, Franklin P. Turner, Tayloe,
              Wilson, Wysor-38.
            </p>
            <p>So the Convention agreed to lay the report and amendment on the table.</p>
            <div className="speaker" id="sp4363"><span className="persName">Mr. JOHN GOODE, JR.</span>, of Bedford—</div>
            <p className="p-in-sp">The Committee to wait on the Hon. Alex. H. Stephens, have performed that duty, and they have been informed by him that he will address the Convention to-morrow at 1 o'clock, P. M. I deem it proper to state that his address had better be delivered in secret session.</p>
            <div className="speaker" id="sp4364"><span className="persName">Mr. MOORE</span>, of Rockbridge—</div>
            <p className="p-in-sp">I beg leave to offer the following resolution:</p>
            <p className="p-in-sp">Resolved, That the 33d rule be and the same is hereby so amended, as to prohibit all debate upon a call of the previous question.</p>
            <p>The resolution was adopted.</p>
            <div className="speaker" id="sp4365"><span className="persName">Mr. SHEFFEY</span>, of Smyth—</div>
            <p className="p-in-sp">I offer the following resolution:</p>
            <p className="p-in-sp">
              "Resolved, That the Governor of Virginia be requested to inform the Convention what number of volunteers has been tendered to him, for the service of the State; what companies have been accepted, from what cities and counties, what companies have been ordered into service, and what volunteer or other force has been ordered to the city of Richmond or other points, so far as he and his
              council may deem such information compatible with the public interest."
            </p>
            <div className="speaker" id="sp4366"><span className="persName">Mr. SHEFFEY</span>—</div>
            <p className="p-in-sp">I propose very briefly to explain my object in proposing the adoption of that resolution.</p>
            <p className="p-in-sp">A very important subject was brought before the Convention by the Military Committee on yesterday, which was the organization of a Provisional Army for Virginia. I think before we take up that question and act upon it, it is proper we should have information on the subject to which this resolution relates.</p>
            <p className="p-in-sp">I am aware that in all parts of the State, volunteers are being raised; and it is important that members of the Convention shall know what has been the action of the Governor in relation to these volunteers, as far as he and his council may think such information proper.</p>
            <div className="speaker" id="sp4367"><span className="persName">Mr. SLAUGHTER</span>—</div>
            <p className="p-in-sp">It seems to me that it is better not to call upon the Governor for any such information at this time. The Governor, no doubt, is occupied by matters of much more importance than that. I move to lay the resolution on the table.</p>
            <p>The motion was agreed to.</p>
            <div className="speaker" id="sp4368"><span className="persName">Mr. SCOTT</span>, of Powhatan—</div>
            <p className="p-in-sp">I beg leave, in behalf of the Military Committee, to report the following ordinance:</p>
            <p className="p-in-sp">I will state that we had not a sufficient number of the committee <PageNumber num={356} /> present this morning to act upon the question of organizing a provisional army, which was under consideration here yesterday; and we adopted the ordinance which was recommended as a substitute by the gentleman from Augusta [<span className="persName">Mr. BALDWIN</span>] , with some slight modification relating to the organization of a corps of Engineers.</p>
            <div className="speaker" id="sp4369"><span className="persName">Mr. BALDWIN</span>, of Augusta—</div>
            <p className="p-in-sp">I thought that belonged necessarily to the part of the ordinance referring to the organization of the 10,000 men; and not knowing how far it may be necessary to refer to that matter, I consider it proper to forego any action upon it, believing that it fell within the principles of the part relating to the organiza- tion of the ten thousand.</p>
            <div className="speaker" id="sp4370"><span className="persName">Mr. SCOTT</span>, of Powhatan—</div>
            <p className="p-in-sp">A corps of Engineers is necessary, whether you organize an army of 10,000 men or not. The Governor and Council are anxious that the Convention should act upon this matter at once, and I trust that their wishes in this respect will be complied with.</p>
            <p className="p-in-sp">The ordinance reads as follows :</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>AN ORDINANCE</p>
                <p>Amending and re-enacting the ordinance adopted on the twenty-first instant, for the Organization of Staff Departments for the Military Forces of the State,</p>
                <p>"Be it ordained, That the Governor of the Commonwealth be and he is hereby authorized and required to organize, as the exigencies of the service may require:</p>
                <p>An adjutant general's department with one adjutant general of the rank of colonel, two assistant adjutant generals of the rank of major. and four assistant adjutant generals of the rank of captain.</p>
                <p>A quartermaster's department, with one quartermaster general of the rank of colonel, one assistant quartermaster general with the rank of lieutenant colonel, two quartermasters with the rank of major, and four assistant quartermasters with the rank of captain.</p>
                <p>A subsistence department, with a commissary general of the rank of colonel, an assistant commissary general of the rank of lieutenant colonel, two commissaries of the rank of major, and four assistant commissaries of the rank of captain.</p>
                <p>A medical department, with a surgeon general of the rank of colonel, ten surgeons of the rank of majors, and ten assistant surgeons of the rank of captain.</p>
                <p>A pay department, with a paymaster general of the rank of colonel, and three paymasters with the rank of major.</p>
                <PageNumber num={357} />
                <p>An engineer corps with one colonel, one lieutenant colonel, two majors, six captains, two first lieutenants, and two second lieutenants.</p>
                <p>To this corps shall be attached two companies of sappers and miners, to each of which shall be assigned one captain, one first lieutenant, and one second lieutenant of engineers.</p>
                <p>
                  The adjutant general's department above provided for, shall be the adjutant general's department for all the military forces which shall be called into service in the field, camp or garrison, and shall be entirely separate and independent of the adjutant general's department of the militia, and the quartermaster's department, the subsistence department, the medical department, and
                  the pay department shall take charge of all the operations of their respective departments for all branches of the military service.
                </p>
                <p>The Governor shall appoint the officers of the several departments above named, including the engineer corps, by and with the advice and consent of the Advisory Council, established under an ordinance of this Convention, and he shall commission the same.</p>
                <p>This ordinance shall be in force from its passage, and shall be in the lieu and stead of the ordinance adopted on the twenty-first instant."</p>
              </div>
            </div>
            <div className="speaker" id="sp4371"><span className="persName">Mr. HARVIE</span>—</div>
            <p className="p-in-sp">I am no military man, sir. I know as little upon that subject as any other man upon the face of the earth; and my impression is, that a large majority of this Convention are just as ignorant upon the same subject as I am. [Laughter.]
              Now, sir, here is a plan involving the organization of an army for the State of Virginia before we have a head for that army; before we know whether this is to be part of the army acting in subordination to the chief officer of the Confederate States army. I don't think the government of the State of Virginia should appoint the officers of these departments. I think these appointments
              should be made by the Convention, or the Commander-in-chief, if we had one. It will be remembered that I gave notice on Saturday last, that unless we had ascertained that we could get within the limits of the State of Virginia a head for the army of the State, I should move to invite the President of the Confederate States to come here and take charge of the army. I am told that Col.
              Lee will be here to-day, but I hold that it is not certain whether he will offer his services or not to the State of Virginia.
            </p>
            <div className="speaker" id="sp4372"><span className="persName">Mr. WILLIAM B. PRESTON</span>—</div>
            <p className="p-in-sp">I understand he will.</p>
            <div className="speaker" id="sp4373"><span className="persName">Mr. HARVIE</span>—</div>
            <p className="p-in-sp">If that be so, I am satisfied. I heard that he was coming. I have not heard whether he will accept the commission to be tendered to him or not.</p>
            <PageNumber num={358} />
            <div className="speaker" id="sp4374"><span className="persName">Mr. WM. H. MACFARLAND</span>—</div>
            <p className="p-in-sp">Will the gentleman allow me to say that Colonel Lee has resigned his commission in the United States army.</p>
            <p className="p-in-sp">I beg leave to make this suggestion, that there is an obvious propriety in Col. Lee's not showing his willingness to accept high rank until he comes here, and it is properly tendered.</p>
            <div className="speaker" id="sp4375"><span className="persName">Mr. HARVIE</span>—</div>
            <p className="p-in-sp">
              I hope the gentleman does not understand me as casting any imputation upon Col. Lee. If he is coming here it is the more necessary that we should consult him in regard to the organization of the army, for he will necessarily desire to have it established upon a footing in conformity with his own predilections and ideas. I think Col. Lee is more competent to suggest appointments to the
              Governor than the Governor is to make them. I do not want to restrict these appointments to the State of Virginia, or to those United States officers who have tendered their services to Virginia. I am, therefore, opposed to any action upon this question now. Without meaning to cast the least imputation upon Col. Lee or any gentleman connected with the service of the United States, I
              believe now, as I believed from the first, that the enunciation of Virginia's policy in the passage of her ordinance of secession on Tuesday was invitation enough to all her sons in the service of the United States to rally to her standard; and I now say that unless Col. Lee does not accept<span className="note" id="Note39"
              ><span className="noteLabel">5</span>Probably the speaker meant to say either "unless Col. Lee accepts," or "if Col. Lee does not accept... ."</span
              >
              this position of Commander-in-Chief, it is the duty of this Convention to act without any further delay, and to call upon those who are already in the field to come here and organize this army. I shall, tomorrow, after Mr. Stephens concludes his speech, bring this matter to a point.
            </p>
            <p className="p-in-sp">I came here yesterday [Sunday]
              , hearing you were in session, and when I got into the city, I witnessed a scene such as I never witnessed before, and such I trust as I shall never witness again. I saw the citizens of Richmond, without any cause, put in a state of excitement. I saw old men with their shot guns, hurrying down in the direction of Rocketts, to meet the United States steamer Pawnee, which was supposed to
              be on her way up the river, with a military force to attack Richmond. Had the r-port proved true, and the forces been landed, our citizens would have been cut to pieces like sheep in the shambles; and this, because we have no military leader, who would establish order and enforce discipline. Our people were, yesterday, in a state of perfect disorder, with no head to lead them, and the
              consequence would have been, had the enemy landed, as was apprehended, <PageNumber num={359} /> that our people would have been decimated and our property ruined.
            </p>
            <p className="p-in-sp">
              I am tired of this state of inefficiency in which we now are. I witnessed that spectacle on yesterday, with shame and grief. Such a condition of things ought not to be permitted to exist for one day. If there had been any assault on yesterday, it would have been better that our whole population here were beyond the Alleghany mountains, than that an attempt at resistance should have
              been made. In the latter instance they would have been spared for a future emergency. In the former, their annihilation would be almost certain.
            </p>
            <div className="speaker" id="sp4376"><span className="persName">Mr. MACFARLAND</span>—</div>
            <p className="p-in-sp">
              I confess, sir, that I have entire confidence in those who compose the Military Committee, but I will beg leave to make this suggestion, hoping it may command the concurrence of that committee. Colonel Lee will be here at 2 o'clock, and I have witnessed indications which cannot be mistaken that there is a disposition on the part of this Convention to confer upon him the highest rank
              which it is in their power to confer in connection with the military and naval organization of the State. I desire to call the attention of the Convention distinctly to the consideration of a fact which, in my view, is a very important one. Colonel Lee, as I have said, will be here at 2 o'clock. We will have an evening session. Between the time of his arrival and our meeting here, the
              Military Committee will have an opportunity of conferring with him, and submitting their protege of<span className="note" id="Note40"
              ><span className="noteLabel">6</span>The text sounds confused here. The word protege may be a rendering of protege, but Mr. Macfarland seems to have been referring to the ordinance for the organization of staff departments (pp. 344-345) rather than to a person. If he was speaking of that ordinance, he may have described it as a pro jet, or draft. In any event, the text should certainly
                read "for his opinion . ." or "to his opinion.. .."</span
              >
              his opinion and approbation. I submit if something will not be gained by adopting that course. It would seem to be due to the high position intended for Colonel Lee, when his arrival is so near at hand, that he should be consulted upon the details of any military scheme that we should adopt. I would say to all sides of the House, that, as Colonel Lee will be here at 2 o'clock, nothing
              will be lost really in point of time. By awaiting his arrival we shall gain the counsels of a mature soldier, while, at the same time, we will be showing to him a very becoming respect. I may add here, that gentlemen ought not to be surprised at the failure of any intimation on the part of Colonel Lee, that he will accept any rank that may be tendered to him. Propriety required that he
              should seal his lips upon the subject until a tender came through some authorized channel. It is my privilege to know him, and what his merits are in other particulars. He is distinguished by modesty and reserve, and could not, in my opinion, bring himself to accept a position tendered to him otherwise than in strict conformity with official usage.
            </p>
            <p className="p-in-sp">I think, that in view of these simple considerations, by common <PageNumber num={360} /> consent, the matter should be passed over for the present with a view to its being taken up in the afternoon session.</p>
            <p className="p-in-sp">I submit a motion to pass it by.</p>
            <div className="speaker" id="sp4377"><span className="persName">Mr. SCOTT</span>, of Powhatan—</div>
            <p className="p-in-sp">
              I fully concur with the gentleman in the views he has taken. The committee was not aware that Col. Lee would be here to-day; but whether he comes or not, it is all important, in my estimation, that these departments should be organized if we are to have an army in the field. Whether it be connected with the Southern army or not, I repeat that we should have these. departments. An
              Engineer corps is all important; and you will find, when we have the advice of Col. Lee, that he will agree to it, because it is absolutely necessary. We would not have acted upon this matter, I believe, if we knew that Col. Lee would be here to-day, and I shall therefore concur in the motion just made.
            </p>
            <div className="speaker" id="sp4378"><span className="persName">Mr. EARLY</span>, of Franklin—</div>
            <p className="p-in-sp">
              We passed, on yesterday, amidst the stampede, "an ordinance to provide for the organization of Staff Departments for the military forces of the State." It will be recollected that I was opposed to this action in regard to a standing army. My desire has been all the time to wait until we had an efficient military chief who could prescribe a proper system of military organization. I am
              very much afraid that this action is to be forestalled, because, as I stated yesterday, we have a large number of major generals and brigadier generals in the field.
            </p>
            <p className="p-in-sp">We now have four Major Generals and three Brigadier-Generals. while there is a command for a Brig. General in the field; and on yesterday the Convention came very near establishing a regular army, which could not be got into the field until these questions are settled.</p>
            <p className="p-in-sp">
              It was stated by a member of the committee, that General Gwynn was sent to Norfolk with an assurance that an ordinance would be passed yesterday or to-day, providing for a staff department. We passed such an ordinance on yesterday, but it appears we left out what was the most important of all-an engineer's department. Yesterday evening I met
              <span className="persName">Mr. RANDOLPH</span> on his way down the river. He informed me that he did not expect a war vessel as high up as Richmond; but that he intended, however, to sink hulks and thus intercept her passage up, if any such movement was contemplated. He deemed this course the only safe one in case of any such attempt, inasmuch as he could not fire upon a vessel with any
              good effect. He asked me to speak to the Governor about the organization of a corps of engineers, who would superintend the construction of a line of <PageNumber num={361} /> breast-works at suitable points along the river, and thus afford a good opportunity for effectual resistance.
            </p>
            <p className="p-in-sp">
              The little experience of yesterday shows that you have omitted from the ordinance the most important feature of all. That ordinance is already in force, and the Governor is going on organizing the staff under it, so far as relates to the particular departments detailed therein. The ordinance reported by the committee to-day merely reenacts the ordinance of yesterday, with the simple
              addition of an engineers department.
            </p>
            <p className="p-in-sp">
              We have now, according to the ordinance passed on yesterday, an Adjutant General's Department, which may be merged into the Adjutant General's Department of the State; but it seems to me that we should have an Adjutant General's Department different from that. I think that we should at once organize this Staff department, and when Col. Lee arrives he will find provision made to effect
              this organization. I trust that some friend of the Governor will, if we pass this ordinance, request him not to make any appointment until he consults with some military head.
            </p>
            <p className="p-in-sp">
              It is indispensable that we should immediately organize an Ordnance Department and an Adjutant General's Department. As to the Pay Department, I think it is more important to get the money than to organize a force to pay what is not now available. I was opposed to having more than four paymasters with the title of majors; but that is already ordained by the ordinance passed on
              yesterday. The ordinance now reported but re-enacts and makes more perfect the ordinance of yesterday, and I trust it will be the pleasure of the Convention to adopt it immediately.
            </p>
            <div className="speaker" id="sp4379"><span className="persName">Mr. WYSOR</span>, of Pulaski—</div>
            <p className="p-in-sp">I beg leave to inquire what effect would a re-consideration of the vote adopting the ordinance on yesterday have upon the action of the Governor?</p>
            <div className="speaker" id="sp4380"><span className="persName">Mr. EARLY</span>—</div>
            <p className="p-in-sp">
              I cannot tell exactly. I am afraid it will have the effect of nullifying that action. But, as I understand, the gentleman in charge of this report does not propose to interfere with any action already taken, but merely to make the addition of an Engineer Corps to the staff already provided for, so that whoever shall come to take charge of our forces, may have an efficient organization
              of all the Engineers and Staff Departments, which he can at once proceed to have placed on an efficient footing.
            </p>
            <div className="speaker" id="sp4381"><span className="persName">Mr. HARVIE</span>—</div>
            <p className="p-in-sp">
              I never felt, sir, more embarrassment and difficulty as to the action which I should take than I do now. I don't want to show distrust of any human being on earth; and I have none. I <PageNumber num={362} /> don't want to show distrust of any officer in Virginia from the Governor down. But I don't want things to be done without reflection and
              deliberation. I assure my friends here, whose views I have listened to upon this subject, that I have more confidence in their opinion than my own. I have entire confidence that the Governor of the State will do all that he can to promote the interests of the State; but I do not believe that there is any officer in this State who is competent to do what we require to be done, and to do
              it in the way that it ought to be done at this time. I think that before we make any progress, we ought to be satisfied that we do not make it in the wrong direction.
            </p>
            <p className="p-in-sp">
              I will not appeal to the gentlemen of the Military Committee to move to suspend action under the ordinance passed on yesterday; but will suggest to them to call upon the Governor and request him not to take any action upon it that is not necessary for the immediate defence of the State, until we know whether Col. Lee will accept a commission in our army. I hope that gentlemen will not
              only not press any further action upon this Convention now, but that they will consent to suspend action under what has been done yesterday, in order that we may fully understand what we do. Perhaps the better course would be now that the Governor be called upon and requested respectfully not to act upon the. ordinance adopted yesterday. I shall make a motion to that effect, but I
              would prefer that the committee should take that position, and thus relieve me and every member in this House from the embarrassments upon us.
            </p>
            <div className="speaker" id="sp4382"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">The Chair would suggest the propriety, if it is the pleasure of the Convention to pass by the consideration of the subject, to move to lay it upon the table.</p>
            <div className="speaker" id="sp4383"><span className="persName">Mr. EARLY</span>—</div>
            <p className="p-in-sp">I wish to make a few observations, which I hope will be of some service to the Convention.</p>
            <p className="p-in-sp">It will be recollected that on the night after the passage of the ordinance, when the gentleman from Richmond [<span className="persName">Mr. RANDOLPH</span>] , offered his ordinance authorizing the Governor to call out the military forces of the State, I took occasion to make some remarks, and offered a substitute for the first part of his ordinance, which he accepted. I suggested to him, and also to my friend from Augusta [<span className="persName">Mr. BALD</span>-WIN]
              , the propriety of having the whole matter referred to a committee; but those gentlemen were in such haste that they could not wait to subject the proposition to such an ordeal at that time. A great deal of the difficulty by which we now find ourselves surrounded results from having passed that ordinance. We have invited the
              <PageNumber num={363} /> officers of the army and navy under that ordinance, with the assurance that their relative rank in the army and navy should not be reversed, while we all agreed that there were some men in a superior grade who were not competent to command a brigade, when there were others inferior to them who were fully competent to do so.
            </p>
            <div className="speaker" id="sp4384"><span className="persName">Mr. HARVIE</span>—</div>
            <p className="p-in-sp">I observe in the papers of this morning that Philip St. George Cocke has been appointed Major General, and that all the officers, without regard to rank, are to be under his control, although he has been taken from the rank of Captain.</p>
            <p className="p-in-sp">I would like to know the effect of that action.</p>
            <div className="speaker" id="sp4385"><span className="persName">Mr. JOHN ECHOLS</span>, of Monroe—</div>
            <p className="p-in-sp">Gen. Cocke is not appointed Major General. He is merely appointed Brigadier General.</p>
            <div className="speaker" id="sp4386"><span className="persName">Mr. EARLY</span>—</div>
            <p className="p-in-sp">
              I said yesterday I understood that the Governor made two appointments of Major Generals, and I believe I remarked that he had no power to make any such appointments at this time, because we sent forth the ordinance passed here on the night of the 17th inst., inviting all officers of the army and navy of the United States who were natives or residents of Virginia, to retire from that
              service and take service with us. My opinion is that the Governor has no power to make any appointment until the army is organized into brigades and divisions. I am sorry to see any of these officers forestalled. I offered on yesterday an amendment to the ordinance reported by the committee, which, in my view, would have set us right in this difficulty; but that amendment was rejected.
            </p>
            <p className="p-in-sp">
              Now, what I desire particularly to impress upon the Convention is, that we shall not act precipitately. It is better for us to do these things deliberately. Even if we hear the firing of the cannon, let us try and preserve our composure and go through this ordinance deliberately. If we pass this ordinance, in my opinion we will have then furnished to the authorities the means of
              organizing an efficient army, and providing for its equipment, and if the authorities do not exercise the power thus conferred upon them with discretion, it will not be our fault. All we can do is to furnish them the means of calling an army into the field. I am sorry the Governor has undertaken to forestall the officers of the army by making the appointments of the Major Generals. I
              have no doubt he has done what he believes to be for the good of the Commonwealth. Some officers of the United States army have arrived to-day; and I trust that no further appointments will be made until others have an opportunity of coming here. In the meantime, all we can do is to pass an ordinance to enable the Governor to put them in service when they do come.
            </p>
            <PageNumber num={364} />
            <div className="speaker" id="sp4387"><span className="persName">Mr. AMBLER</span>, of Louisa—</div>
            <p className="p-in-sp">I think that, after the lights that have been given to the Convention by several gentlemen here, the attention of the Military Committee should be called to the facts as they occurred on yesterday.</p>
            <p className="p-in-sp">
              On Sunday morning a message was conveyed to each member of this Convention, that, under extraordinary circumstances, we were to come here to receive some unknown communication. Not the slightest intimation was given what it was that we were so suddenly called together to act upon. We came together, sir, and a report was made by the Military Committee; and, after that report was made,
              as was well remarked by a gentleman who commented upon it
              [<span className="persName">Mr. PRICE</span>] , no two members of that committee agreed upon that report. Attention was called at the time to the fact that no two members of that committee seemed to agree to that report.</p>
            <div className="speaker" id="sp4388"><span className="persName">Mr. SCOTT</span>, of Powhatan—</div>
            <p className="p-in-sp">The gentleman is very much mistaken in that matter. I read the report, section by section in committee, and I don't know that there was any objection at all to it. I moved an amendment, it is true, which was not suggested in the committee, and this probably is what the gentleman alludes to.</p>
            <div className="speaker" id="sp4389"><span className="persName">Mr. AMBLER</span>—</div>
            <p className="p-in-sp">I do not allude to what took place in committee. I am speaking of what took place on this floor.</p>
            <p className="p-in-sp">
              Now we must of necessity rely upon the gentlemen of that committee to present to us, properly matured, whatever scheme they submit to us for action. They called us here hurriedly yesterday, and presented a report, whereupon members of that committee itself offered amendments to that report. Then it will be borne in mind, that when an amendment was offered by the gentleman from Augusta
              [<span className="persName">Mr. BALDWIN</span>] , I addressed myself to the members of that committee, and asked if all was included in that amendment which was absolutely necessary for us to act upon at so extraordinary a session. The reply was that it did contain all that was necessary for us to action.</p>
            <p className="p-in-sp">Now, sir, acting in the good faith which, for one, I place in the members of that committee, I voted for it. They said it was absolutely necessary that we should adopt it, and we did adopt it.</p>
            <p className="p-in-sp">Now, what is the condition of things this morning? We are told that a most important, a most pressing, a most vital measure, which ought to have been contained in that report, was not touched by the ordinance which we did pass.</p>
            <div className="speaker" id="sp4390"><span className="persName">Mr. BALDWIN</span>—</div>
            <p className="p-in-sp">
              I think it is just to the committee that I should say that, in all probability, I am to blame for the misapprehension on <PageNumber num={365} /> that subject; for the ordinance which I submitted, I assured them, was a transcript of their ordinance, with the exception of the provision organizing the army. I did not advert at all to the provision relating
              to the Engineer Corps. I omitted that, not with any intention to deceive any one at all; but I thought every one understood that that belonged more to the organization of the army than to the organization of the staff. I think it most probable that I misled the Military Committee in giving the answer which I did give to the inquiry, whether my substitute embraced all of the original
              report, save that which related to the organization of the army.
            </p>
            <div className="speaker" id="sp4391"><span className="persName">Mr. AMBLER</span>—</div>
            <p className="p-in-sp">
              I want it distinctly understood, that I am making no sort of attack upon the Military Committee; I am merely repelling a charge upon the Convention, and calling attention to the fact, which is in the knowledge of all here, that if the blame is to be placed anywhere, it ought to be placed upon members here who must rely upon these gentlemen in whose hands so important a charge is
              committed. And it is unjust to the members of this Convention, that we should be held responsible for errors committed, when these errors were committed by those in whom we have placed confidence. For one, I wish to clear my shoulders of acting in the way in which the gentleman from Franklin
              [<span className="persName">Mr. EARLY</span>]
              , characterized our action. If I complained at the course pursued on yesterday, I was as calm as I ever was in my life, and was as anxious to do what those who conducted our movements said was necessary to do as any man in this Convention. If an error is committed let it be placed where it properly belonged. Do not, when you bring in immature projects here-when I confide in you, and
              when you have committed an error-do not come back upon me and say that it is my error. I am not willing to be placed in that position. For one, I am willing to place all confidence in you. If you have committed the error, take it yourselves. Do not place it on me.
            </p>
            <div className="speaker" id="sp4392"><span className="persName">Mr. SCOTT</span>, of Powhatan—</div>
            <p className="p-in-sp">I feel it due to the committee that I should make a brief explanation of its action.</p>
            <p className="p-in-sp">
              The gentleman is entirely mistaken in throwing the blame of this matter upon the Committee on Military Affairs. It is well known that the Committee that acted upon this ordinance yesterday was a pretty full Committee, and at the time the ordinance was submitted it was submitted, as I thought, with the unanimous consent of the Committee. One of the members of the Committee, the
              gentleman from Franklin
              [<span className="persName">Mr. EARLY</span>] , proposed an amendment to it in the Convention. I proposed a small amendment, which I did not press, <PageNumber num={366} /> so that, in fact, the only amendment proposed by any member of the</p>
            <p className="p-in-sp">Committee was that offered by the gentleman from Franklin [Mr. EARLY] .</p>
            <div className="speaker" id="sp4393"><span className="persName">Mr. AMBLER</span>—</div>
            <p className="p-in-sp">I beg to know if Gen. JOHNSTON is not a member of the Committee; and did he not propose an amendment?</p>
            <div className="speaker" id="sp4394"><span className="persName">Mr. SCOTT</span>—</div>
            <p className="p-in-sp">He withdrew it.</p>
            <div className="speaker" id="sp4395"><span className="persName">Mr. AMBLER</span>—</div>
            <p className="p-in-sp">I beg to ask if <span className="persName">Mr. RANDOLPH</span> did not express his disapprobation of the report?</p>
            <div className="speaker" id="sp4396"><span className="persName">Mr. SCOTT</span>—</div>
            <p className="p-in-sp">If any dissatisfaction was expressed here by him, I forget it. It was not expressed in committee.</p>
            <p className="p-in-sp">In regard to the ordinance that was passed on yesterday at the instance of the gentleman from Augusta [<span className="persName">Mr. BALDWIN</span>] , I believed then, and did believe until this morning, that it included all of the reported ordinance except the part relating to the organization of 10,000 men. That was my impression until informed to the contrary to day by Col. SMITH.</p>
            <p className="p-in-sp">I move to lay the whole subject on the table.</p>
            <div className="speaker" id="sp4397"><span className="persName">Mr. HARVIE</span>—</div>
            <p className="p-in-sp">
              I desire to correct an error into which I had fallen in the course of my remarks a few minutes ago. I stated that the Governor had appointed Philip St. George Cocke to the office of Major General. It turns out, however, that the appointment is to a Brigadier Generalship. I deem it due to the Governor and the gentleman upon whom this appointment was conferred to make this correction.
            </p>
            <p className="p-in-sp">
              I was called out of this House a short time ago by a gentleman of as much chivalry as any in the service of the State of Virginia, and asked by him whether it was not his duty to resign on the spot? He stated that he was an officer older than Gen. Cocke, who was taken and put in the most important position in Virginia, over the heads of those who should properly be over him. He said,
              "I will fight with brick-bats if necessary, but I don't want to be degraded by having one inferior in rank put over me." That is the condition of things which has been brought about by premature action; and the experience thus furnished affords, in my opinion, sufficient reason why this practice should be discouraged.
            </p>
            <p>The motion to lay on the table was agreed to.</p>
            <div className="speaker" id="sp4398"><span className="persName">Mr. MORRIS</span>, of Caroline—</div>
            <p className="p-in-sp">I offer the following resolution: "Resolved, That the operation of the ordinance organizing a Staff department for the military forces of the State, passed the 21st of April, be suspended until the further order of the Convention."</p>
            <p>The resolution was agreed to.</p>
            <PageNumber num={367} />
            <div className="speaker" id="sp4399"><span className="persName">Mr. MORRIS</span>—</div>
            <p className="p-in-sp">I now move that the resolution just adopted be forthwith communicated to the Governor.</p>
            <p>The motion was agreed to.</p>
            <div className="speaker" id="sp4400"><span className="persName">Mr. GEORGE W. HULL</span>, of Highland—</div>
            <p className="p-in-sp">I beg leave to offer the following resolution :</p>
            <p className="p-in-sp">"Resolved, That a committee of five be appointed to enquire into the expediency of suspending the collection of debts under process of law for a given period of time, and report to this body."</p>
            <div className="speaker" id="sp4401"><span className="persName">Mr. KILBY</span>, of Nansemond—</div>
            <p className="p-in-sp">I think it would be proper to remove the obligation of secrecy, so far as relates to the appointment of a Council of State and the reception of the Commissioner from the Southern States, are concerned.</p>
            <p className="p-in-sp">I offer the following resolution:</p>
            <p className="p-in-sp">"Resolved, That the injunction of secrecy be removed, so far as relates to the appointment of a Council of State and the reception of a Commissioner from the Confederate States."</p>
            <p>The resolution was adopted.</p>
            <div className="speaker" id="sp4402"><span className="persName">Mr. MORRIS</span>—</div>
            <p className="p-in-sp">I beg to substitute this resolution for the one passed a while ago, to suspend the operation of the ordinance authorizing the Governor to organize a Staff Department.</p>
            <p className="p-in-sp">"Resolved, That the Governor be requested to suspend any further appointments under the ordinance adopted on yesterday, until the adoption of further steps by this Convention."</p>
            <div className="speaker" id="sp4403"><span className="persName">Mr. MORRIS</span>—</div>
            <p className="p-in-sp">I move to reconsider the vote by which the resolution offered by me a few minutes ago, was adopted.</p>
            <p>The motion was agreed to, whereupon the above resolution was offered and adopted.</p>
            <div className="speaker" id="sp4404"><span className="persName">Mr. WILSON</span>, of Harrison—</div>
            <p className="p-in-sp">I offer the following resolution :</p>
            <p className="p-in-sp">"Resolved, That a committee of five be appointed to inquire into the expediency of providing for the transportation of the mail in Virginia, in the event that the present arrangement for the transportation of the same shall be suspended by the Government at Washington."</p>
            <div className="speaker" id="sp4405"><span className="persName">Mr. WILSON</span>—</div>
            <p className="p-in-sp">I think it is very important that we should look into this matter in due time, so as to avoid the inconveniences which would inevitably result to our people by a sudden stoppage of the ordinary mail facilities. If it is the pleasure of the Convention, I desire not to be put on that committee.</p>
            <p>The resolution was rejected.</p>
            <div className="speaker" id="sp4406"><span className="persName">Mr. COX</span>, of Chesterfield—</div>
            <p className="p-in-sp">I beg leave to offer the following resolution.</p>
            <p className="p-in-sp">"Resolved, That the ordinance of secession be ordered to be enrolled <PageNumber num={368} /> forthwith, and to be open to the signatures of members from the time of enrollment till the final adjournment of the Convention."</p>
            <div className="speaker" id="sp4407"><span className="persName">Mr. COX</span>—</div>
            <p className="p-in-sp">I offer that at this time from an apprehension that members may leave here without opportunity of signing the ordinance. Here are members who voted against it, but who, perhaps, before their departure would desire to change their votes and sign the ordinance. This proposition, if carried out, will afford, all who may desire, a full opportunity to do so.</p>
            <div className="speaker" id="sp4408"><span className="persName">Mr. ROBERT C. KENT</span>, of Wythe—</div>
            <p className="p-in-sp">I would ask if it is proper to sign that until the people shall have voted upon it. I think not, and I therefore move to lay the resolution on the table.</p>
            <p>The motion was agreed to.</p>
            <div className="speaker" id="sp4409"><span className="persName">Mr. MORTON</span>, of Orange and Greene—</div>
            <p className="p-in-sp">When that ordinance is enrolled upon parchment, and is to be transmitted to posterity, I wish it to appear that the signatures have been put to it in open session.</p>
            <p className="p-in-sp">I think there is a rough draft of it, and such of the members as voted against it, and would now be willing to sign it, could put their signatures to that rough draft.</p>
            <p>The motion to lay on the table was rejected and the resolution adopted.</p>
            <div className="speaker" id="sp4410"><span className="persName">Mr. ECHOLS</span>—</div>
            <p className="p-in-sp">There was a communication sent from the Governor to this body on Saturday, of a very important character; and no disposition has as yet been made of it.</p>
            <p className="p-in-sp">It relates to the military and civil contingent fund. The Governor says that this heretofore has only amounted to $2,800, and he suggests the propriety of increasing it to $100,000.</p>
            <p className="p-in-sp">I ask that the communication be at once referred to the Finance Committee.</p>
            <p>The motion was agreed to.</p>
            <div className="speaker" id="sp4411"><span className="persName">Mr. PARKS</span>, of Grayson—</div>
            <p className="p-in-sp">I move that the Convention take a recess until half past seven o'clock.</p>
            <div className="speaker" id="sp4412"><span className="persName">Mr. MORTON</span>—</div>
            <p className="p-in-sp">I move to adjourn till five o'clock.</p>
            <p>This motion was lost and <span className="persName">Mr. PARKS</span>' motion adopted.</p>
            <p>And then the Convention took a recess until half past 7 o'clock, P. M.</p>
            <div className="session" id="vsc1965.v4.2.7.1">
              <h3><span className="headingNumber">1.7.1. </span><span className="head">NIGHT SESSION</span></h3>
              <p>The Convention re-assembled at the appointed hour.</p>
              <div className="speaker" id="sp4413"><span className="persName">Mr. ALGERNON S. GRAY</span>, of Rockingham—</div>
              <p className="p-in-sp">
                In the short debate on the Ordinance of Secession as it passed the Convention, I expressed the earnest desire I felt to carry out in that most solemn hour the <PageNumber num={369} /> will of my constituents. I gave the reasons then which made me cast my vote against the ordinance. In all America there was not a people more loyal to the two flags of the
                Union and of the State. But the infamous proclamation of the President of the United States, reckless of the terrible consequences of the civil war which it inaugurates, has roused their indignation, and united them all to the point of immediate separate secession. I therefore ask leave of the Convention to change my vote from no to aye. I believe it is now the wish of the great body
                of the people of the county of Rockingham that I cast my vote for the ordinance. As an American citizen, I could weep over the dissolution. As a Virginian, I pray that the God of our fathers may continue his Providence over us in the career before us.
              </p>
              <p>Leave was granted to <span className="persName">Mr. GRAY</span> to change his vote.</p>
              <div className="speaker" id="sp4414"><span className="persName">THE PRESIDENT</span>—</div>
              <p className="p-in-sp">I beg leave to announce the following committee under the resolution offered this morning by <span className="persName">Mr. HULL</span>, in reference to a suspension of the collection of debts-Messrs. Geo. W. Hull of Highland, James Marshall of Frederick, Allen T. Caperton of Monroe, Robert E. Scott of Fauquier, and John R. Chambliss of Greenesville.</p>
              <div className="speaker" id="sp4415"><span className="persName">Mr. MOORE</span>, of Rockbridge—</div>
              <p className="p-in-sp">I have a resolution to offer, which I think will elicit no debate, if indeed anything can be acted on here without debate.</p>
              <p className="p-in-sp">I understand that a great many counties have raised a considerable number of volunteers, and that the county courts have made provision for arming and equipping them. I think it is desirable that this should take place in all counties; and in order to stimulate our friends to the good work, I offer the following resolution:</p>
              <p className="p-in-sp">Resolved, That it be earnestly recommended, to the citizens of the several counties of this Commonwealth, to promptly form and discipline companies of volunteers, of their able-bodied men.</p>
              <div className="speaker" id="sp4416"><span className="persName">Mr. MOORE</span>—</div>
              <p className="p-in-sp">I think, sir, if this recommendation goes forth from the Convention, that almost every county in the State will be ready to respond to it.</p>
              <p className="p-in-sp">I will say that, although I voted against the ordinance of secession, yet when the act is done, I consider it binding upon me, inasmuch as it is the act of the majority, and I am ready to go as far as any other man in support of anything that is calculated to preserve the honor and promote the interest of this Commonwealth.</p>
              <p className="p-in-sp">As to my constituents, I believe they will be as prompt and as ready as any other people in the Commonwealth to maintain its rights.</p>
              <p className="p-in-sp">I understand that three companies from Rockbridge have gone to <PageNumber num={370} /> Harper's Ferry, and that another company has since been raised there.</p>
              <p className="p-in-sp">I understand from letters received from there that they are perfectly willing that my colleague and myself should do what we thought best calculated to maintain the honor of the State, and preserve the rights of its citizens.</p>
              <div className="speaker" id="sp4417"><span className="persName">Mr. JOHN R. CHAMBLISS</span>, of Greenesville—</div>
              <p className="p-in-sp">There is a general law upon this subject, which provides that the county courts may raise money and arms to equip volunteers.</p>
              <p className="p-in-sp">But as the resolution of the gentleman is merely recommendatory, I hope it will be adopted.</p>
              <div className="speaker" id="sp4418"><span className="persName">Mr. JAMES B. MALLORY</span>, of Brunswick—</div>
              <p className="p-in-sp">I have no doubt that the counties will respond to this call. I can answer for the counties south of Petersburg. Greenesville, Lunenburg, Mecklenburg and Brunswick have already responded, and many more, I am satisfied, will promptly respond to the recommendation made in that resolution.</p>
              <div className="speaker" id="sp4419"><span className="persName">Mr. J. G. HOLLADAY</span>, of Norfolk county—</div>
              <p className="p-in-sp">It strikes me, if the county courts in the various counties could be induced to make appropriations to be used under proper directions, that it will be better than to leave it to individual action.</p>
              <p className="p-in-sp">
                In the first place, it seems to me that they cannot act as efficiently as the central directing authority; and acting independently, that they will, if they are able to purchase arms at all, purchase them without any regard to uniformity. It may be that counties may appropriate money for arming and equipping their own volunteers, when the equipments may not suit for general purposes,
                and conflict with some general arrangement established by the heads of the army. I should, therefore, as an individual member of the Convention, prefer that the resolution should be so altered as to direct that the funds raised through the action of county courts, should be used by some central authority, so as to ensure uniformity. I merely throw this out as a suggestion.
              </p>
              <div className="speaker" id="sp4420"><span className="persName">Mr. MOORE</span>—</div>
              <p className="p-in-sp">It has been suggested to me by several gentlemen to add to the resolution the words-"and to the county courts to levy or raise, by issuing bonds, a sufficient amount of money to equip and arm such volunteers when raised."</p>
              <div className="speaker" id="sp4421"><span className="persName">Mr. E. B. HALL</span>, of Marion—</div>
              <p className="p-in-sp">I hope it will not be so amended as to propose to the county courts to issue bonds. I think we have enough in that line without extending it.</p>
              <div className="speaker" id="sp4422"><span className="persName">Mr. PRICE</span>, of Greenbrier—</div>
              <p className="p-in-sp">It seems to me that if the gentleman <PageNumber num={371} /> would use the word "raise" without saying in what manner, his resolution would be less objectionable. Some counties may be able to raise money by issuing bonds and others by levying.</p>
              <p className="p-in-sp">I proposed to strike out the words "levy or raise by issuing bonds" and insert the word "raise."</p>
              <div className="speaker" id="sp4423"><span className="persName">Mr. MORTON</span>, of Orange and Greene—</div>
              <p className="p-in-sp">If my good friend from Rockbridge [<span className="persName">Mr. MOORE</span>]
                will only have a little patience with me, and will be satisfied that any objection I make to his resolution is not in a captious spirit, I will venture to call to the attention of the Convention reasons which satisfy me that we should lay this resolution upon the table. I presume, at the close of the war, or if that term be too strong, when our difficulties are settled, all of these
                claims will be presented by the counties, and, no doubt, will be saddled upon the Treasury of the State. I do not care what may be the opinion of any gentleman in relation to his own people, but I am confident that is to be the end of this matter. Now, at this moment, when the feelings of the country are in favor of raising volunteers to report to the Governor for service, I don't
                think that any thing should be brought before the people to distract them.
              </p>
              <p className="p-in-sp">
                One objection I have to the adoption of the resolution is that it will, in the first place, involve much expense to the State to carry it out; and, in the second place, we will have inferior arms. The course of each county, that will make appropriations, will be to appoint an agent to obtain the arms, who will be utterly inexperienced and no judge of arms, and who, in fact, will be
                at a loss to know where to get them; whereas under the military organization that we are now getting up, there will be competent men, properly directed to points from which these arms can be had; and instead of having an agent for each county, there will be a single agent representing the State at large. You will thus have but the expense of a single man instead of 140 or 150 men to
                carry out this object; while we can secure better judges of the kind and quality of the arms, than we could under the plan proposed by the gentleman from Rockbridge. If this step had been taken by every county in the Commonwealth at the time that the Legislature passed the law, it would have been a wise provision; but now, when all intercourse between the North and South is entirely
                cut off, and you cannot get the arms there, we will have to look to our own manufacturers in the South for them, or beyond the ocean, and for that purpose the Government can much more easily procure an agent than the counties individually can.
              </p>
              <p className="p-in-sp">I believe, therefore, it would be better to lay this resolution on <PageNumber num={372} /> the table, and rely upon the Commonwealth to raise whatever amount is necessary for the purposes indicated.</p>
              <div className="speaker" id="sp4424"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">
                I would like to know from the gentleman whether the course of remark in which he is now indulging, is intended to have special application to the county which I have the honor to represent on this floor. It appears that this resolution contemplates the raising of volunteers and money by the counties, and, from the strain of the gentleman's remarks, I understand that he is intending
                some aspersion of the county I represent. The tenor of his remarks would indicate that he aims at the county court of Augusta, which to-day appropriated $30,000 for arms and equipments.
              </p>
              <p className="p-in-sp">I rise to know whether he refers to that subject.</p>
              <div className="speaker" id="sp4425"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">
                It is not the first time that we have heard from that gentleman that the State of Virginia is the centre of creation, and that the county of Augusta is the centre of Virginia. Permit me to say, that whilst I commend the patriotism of the county of Augusta, I am glad to find that the feelings of that people are just as I predicted. I was always confident that when the crisis would
                arise, old Augusta would be where old Augusta was in '76—ahead of her representatives on this floor.
              </p>
              <p className="p-in-sp">
                But, Mr. President, however honorable the course of Augusta may be, it does not fill my heart and head so entirely as it does the heart and head of the distinguished representative from that county. Meanwhile, I will state that the county of Augusta is one of the last counties upon which I would cast any reflection; for I know her better than the gentleman himself knows her. And
                whilst I am upon this subject, I will correct a historic fact, referred to, I think, by the gentleman from Monongalia
                [<span className="persName">Mr. WILLEY</span>]
                , that it was to that region that the declaration of Gen. Washington was intended to apply when he said, "Give me a standard; let me plant it upon the mountains of West Augusta, and I will lift my bleeding country from the dust." It was not in relation to the county which that gentleman represents that this remark was used; but it was in reference to the gallant county of Augusta. It
                was when Tarleton was pressing his army to cross the Blue Ridge, and the fact was announced in Staunton on Sabbath evening that Mrs. Lewis, the grandmother of the gentleman from the county of Rockbridge, who is not, I regret to say, at present upon this floor, when her husband was sick in bed, called her boys around her who were over thirteen years of age, and told them to enlist
                under the banner of their country, and never to see her face again if the <PageNumber num={373} /> British soldiers were permitted to touch the soil of Augusta; and when that fact was communicated to General Washington, he said, "give me but a standard; let me plant it upon the mountains of West Augusta, and I will raise my bleeding country from the
                dust." And when I saw that the voice of Augusta in this body was not up to the occasion, I thought of the spirit of that departed matron. But, I trusted when the hour arrived, that Augusta would be what Augusta was when Washington uttered that memorable declaration.
              </p>
              <div className="speaker" id="sp4426"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">
                When the gentleman undertakes to correct a matter of history as to the venerable matron who undertook to send her sons to resist the march of the British soldiers into the county of Augusta, he should endeavor to be correct as to facts. He gives to the gentleman from Rockbridge a position, which, I am sure, he would not consent to occupy if he were here. It is not true, though a
                relative, that he is the grandson of the venerable lady referred to.
              </p>
              <div className="speaker" id="sp4427"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">I understood from him that he was a grandson of Mrs. Lewis.</p>
              <div className="speaker" id="sp4428"><span className="persName">Mr. BALDWIN</span>—</div>
              <p className="p-in-sp">It is a mistake. I have the good fortune to be married to a great granddaughter of that lady herself; and have thus a better opportunity than the gentleman, perhaps, in ascertaining all about her lineage.</p>
              <p className="p-in-sp">I don't know whether Augusta county is ahead of her representatives. I don't suppose that her people or her representatives feel at all dissatisfied, in looking forward to the hour of conflict, at the reflection, that before drawing the sword they exhausted all honorable means of pacification.</p>
              <p className="p-in-sp">I trust, when the time of trial comes, her people and her representatives will demonstrate that their failure to draw the sword sooner was owing to a conscientious conviction, that efforts for peace were not only more Christian-like, but in their judgment more manly.</p>
              <p className="p-in-sp">The question on the adoption of the resolution offered by <span className="persName">Mr. MOORE</span>, was then put and decided in the affirmative.</p>
              <div className="speaker" id="sp4429"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">I believe I made a motion to lay the resolution on the table.</p>
              <div className="speaker" id="sp4430"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">No, sir.</p>
              <div className="speaker" id="sp4431"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">I certainly intended to do so.</p>
              <div className="stage it">[Laughter.]</div>
              <div className="speaker" id="sp4432"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">I beg leave to submit the following communication from the Governor of the Commonwealth for the consideration of the Convention.</p>
              <PageNumber num={374} />
              <div className="floatingText">
                <div className="floatingText_body">
                  <p>EXECUTIVE DEPARTMENT,</p>
                  <p>April 22, 1861.</p>
                  <p>Gentlemen of the Convention:</p>
                  <p>I hereby nominate, and with your advice and consent, appoint Colonel Robert E. Lee, to the office of Commander of the Military and Naval forces of the State of Virginia, with the rank of Major General. Talent, experience and devotion to the interests of Virginia, fit him in an eminent degree for the exalted position he is nominated to fill.</p>
                  <p>It affords me pleasure to assure you upon undoubted testimony, that his resignation as an officer of the army of the United States, was determined upon, before the passage of your ordinance creating the office, which it is now proposed to fill. I trust the nomination will meet your approbation, and that it will be your pleasure, to receive him in open Convention to-morrow.</p>
                  <p>Respectfully,</p>
                  <p>JOHN LETCHER.</p>
                </div>
              </div>
              <div className="speaker" id="sp4433"><span className="persName">Mr. JOHN CRITCHER</span>, of Richmond County and Westmoreland—</div>
              <p className="p-in-sp">I move that the nomination be unanimously confirmed.</p>
              <div className="speaker" id="sp4434"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">I thank God that we have found a Virginian to command our army and navy, and that we have not been subjected to the burning shame of calling upon any other State to furnish us with a commander.</p>
              <p className="p-in-sp">
                I take this occasion to say, that while I have opposed the act of secession at every step, I have nothing to regret with regard to the course I pursued; yet as a Convention of my State has decided in favor of that act of secession, and as we are now engaged in this contest, all my wishes, all my desires and all the energies of my hand and heart will be given to the cause of my State.
                Whether we have the right of secession or revolution, I want to see my State triumphant. I do believe that it will be triumphant under the lead of Major-General Lee.
              </p>
              <div className="speaker" id="sp4435"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">
                There is no person in this assembly more rejoiced than I am, that there is a Virginian who, without being tempted by any offer from this assembly, came into the service of his native State voluntarily to take charge of her army and navy. No one who has heard anything that I said, believes that I had any distrust of General Lee; but I would say that if General Lee had failed to come
                forward, I would <PageNumber num={375} /> have felt that Virginia would have no cause of shame in fighting under the banner of Jeff. Davis. I would have felt no shame, as I am sure, none here would even if compelled to resort to the alternative of inviting that distinguished gentleman to take the lead in this conflict.
              </p>
              <div className="speaker" id="sp4436"><span className="persName">Mr. CRITCHER</span>—</div>
              <p className="p-in-sp">
                I desire only to say, that in the days of the Revolution, when this country called on one of her sons to command the army that conducted her to victory and success, my county had the honor of furnishing that illustrious man whom we all venerate and whose virtue the world admires; and it is with pleasure and with pride, that I find the same country, when she is looking for another
                hero to conduct her armies to victory, turning her eyes upon another son of the same county, and committing to his charge the high trust of which this Convention has just made General Lee the recipient.
              </p>
              <p className="p-in-sp">
                Sir, when I stand in my own yard, I can turn to one side and look upon the farm on which Washington was born; and turning to the other, I can point to the farm where General Lee was born. On the other farm beyond Light Horse Henry Lee, who moved the Declaration of Independence, was born; and when my friends visit me, I can drive them to the birth spot of Monroe, and in two hours more
                we come to the spot where Madison was born. This, it must be admitted, is a splendid galaxy of great names for one county to claim the parentage of.
              </p>
              <p className="p-in-sp">I hope it will be the pleasure of the Convention to confirm this nomination unanimously, and additional renown will thus be given to the old county for having furnished another distinguished commander to conduct us to victory.</p>
              <div className="speaker" id="sp4437"><span className="persName">Mr. SHEFFEY</span>—</div>
              <p className="p-in-sp">In order to put the motion of the gentleman in proper form, I beg leave to offer the following resolution:</p>
              <p className="p-in-sp">"Resolved, That this Convention ratify and approve the nomination made by the Governor, of Col. Robert E. Lee, to the office of Commander of the military and naval forces of the State of Virginia with the rank of Major General."</p>
              <div className="speaker" id="sp4438"><span className="persName">Mr. HALL</span>, of Wetzel—</div>
              <p className="p-in-sp">I rise to make an inquiry as to whether this confirmation will trammel us in any action we may take towards a union with the Confederate States.</p>
              <div className="speaker" id="sp4439"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">Not at all.</p>
              <div className="speaker" id="sp4440"><span className="persName">Mr. PRESTON</span>, of Montgomery—</div>
              <p className="p-in-sp">I suppose that this resolution is unnecessary. The question now is upon the nomination of the Governor. We have nothing to do but to confirm that nomination; and I would ask the gentleman from Smyth [<span className="persName">Mr. SHEFFEY</span>] , to withdraw <PageNumber num={376} /> his resolution, and allow action to be taken upon the simple motion to confirm.</p>
              <div className="speaker" id="sp4441"><span className="persName">Mr. CRITCHER</span>—</div>
              <p className="p-in-sp">I concur with the gentleman from Montgomery that the motion which I made is the regular form to be observed in such matters.</p>
              <div className="speaker" id="sp4442"><span className="persName">Mr. SHEFFEY</span>—</div>
              <p className="p-in-sp">I withdraw the resolution.</p>
              <p>The question was then put on the confirmation of the nomination of Col. Lee to the office of Major General and it was unanimously confirmed.</p>
              <div className="speaker" id="sp4443"><span className="persName">Mr. PRESTON</span>—</div>
              <p className="p-in-sp">I move that it be entered upon the journal, that the nomination was unanimously confirmed.</p>
              <div className="speaker" id="sp4444"><span className="persName">Mr. MOORE</span>, of Rockbridge—</div>
              <p className="p-in-sp">I wish to propose that the resolution which was adopted awhile ago, recommending to the citizens of each county to raise volunteers, and to the county courts to raise funds for arming and equipping them, be published in the newspapers.</p>
              <p>The motion was agreed to.</p>
              <div className="speaker" id="sp4445"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">I move that the ordinance which was reported this morning from the military committee be taken up, with a view to move its reference back to the same committee, in order that we may have the advice and counsel of Major General Lee in regard to it.</p>
              <p>The motion was agreed to, whereupon, on motion of <span className="persName">Mr. EARLY</span>, the report was re-committed.</p>
              <div className="speaker" id="sp4446"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">
                I would call the attention of the Convention to the propriety and fitness of receiving Major General Lee in this Hall to-morrow at 12 o'clock, in order that the President of the Convention may announce to him, formally, the distinguished appointment which he has received by the unanimous consent of this body. I beg leave, in this connection, to call the recollection of gentlemen to
                the historical fact that a similar ceremony was observed upon the appointment of the immortal Washington.
              </p>
              <p className="p-in-sp">
                He was received by the body which conferred the office of Major General upon him, and the enunciation of his appointment was made by the presiding officer of that body. It was upon that interesting occasion that he made the memorable declaration of his own apprehensions in respect to his qualifications to fill that high office. I think it is becoming, it is fit, it is appropriate
                that the same ceremony should be observed on this occasion. For one, I am satisfied that I speak but the common convictions of the whole country, when I say that the appointment could not have been conferred upon one of higher merit; and it will be gratifying to the entire country if Major General Lee shall be received with the ceremony to which I have
                <PageNumber num={377} /> referred. It will be interesting to us and highly complimentary to him, while its moral influence will remain in the hearts and the memory of the entire country.
              </p>
              <p className="p-in-sp">I therefore submit a motion that Major General Lee be received here to-morrow at 12 o'clock, and his appointment announced to him by the President of this Convention.</p>
              <p className="p-in-sp">I suggest 12 o'clock, for it is then midday, and the sun is in her altitude.</p>
              <div className="speaker" id="sp4447"><span className="persName">Mr. MORRIS</span>—</div>
              <p className="p-in-sp">Does the gentleman intend that this ceremony shall take place in open session?</p>
              <div className="speaker" id="sp4448"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">No, unless gentlemen so prefer. I am entirely satisfied that the present habit of the House should be continued. I would say, with the permission of the Convention, that I would amend the resolution so far as to authorize the President of the Convention to extend an invitation to as many as it may be in his power to accommodate.</p>
              <p>Cries of no, no, no.</p>
              <div className="speaker" id="sp4449"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">Then, as it seems not to be the pleasure of the Convention to confer this authority, I am in favor of strictly observing the habit of the House, and continuing the secrecy.</p>
              <div className="speaker" id="sp4450"><span className="persName">Mr. STAPLES</span>, of Patrick—</div>
              <p className="p-in-sp">If the gentleman has no particular objection, I will move to amend his motion by inserting the word "open," so that it will read that "Major General Lee be received in open session."</p>
              <p>Cries of no, no, no.</p>
              <div className="speaker" id="sp4451"><span className="persName">Mr. STAPLES</span>—</div>
              <p className="p-in-sp">I withdraw my amendment.</p>
              <div className="speaker" id="sp4452"><span className="persName">Mr. PRESTON</span>, of Montgomery—</div>
              <p className="p-in-sp">I understand that it is proposed that he be received in this Hall not in open session.</p>
              <p className="p-in-sp">I would then move that the Governor and council be invited to attend the ceremony, and also the Vice President of the Confederate States.</p>
              <div className="speaker" id="sp4453"><span className="persName">Mr. PRESIDENT</span>—</div>
              <p className="p-in-sp">That motion is not in order except by way of amendment.</p>
              <div className="speaker" id="sp4454"><span className="persName">Mr. PRESTON</span>—</div>
              <p className="p-in-sp">I will then offer it as an independent proposition after action is had upon the motion of the gentleman from Richmond city.</p>
              <p>The question on the adoption of <span className="persName">Mr. MACFARLAND</span>'S motion was then put, and it was decided in the affirmative.</p>
              <div className="speaker" id="sp4455"><span className="persName">Mr. PRESTON</span>—</div>
              <p className="p-in-sp">I now renew my motion that the Governor and council be invited to attend the ceremony, and also the Vice President of the Confederate States.</p>
              <PageNumber num={378} />
              <div className="speaker" id="sp4456"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">Will my friend accept an amendment to his proposition?</p>
              <p className="p-in-sp">I understand that the Vice President of the Confederate States will address this Convention to-morrow at 1 o'clock, in secret session. I shall vote for the motion of the gentleman with great pleasure; and will move an addition, that the Governor and council be also invited to remain and hear the address of the Vice President of the Confederate States.</p>
              <div className="speaker" id="sp4457"><span className="persName">Mr. PRESTON</span>—</div>
              <p className="p-in-sp">I will vote for that motion; but I think it is better to offer it as an independent proposition.</p>
              <div className="speaker" id="sp4458"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">I shall do so.</p>
              <div className="speaker" id="sp4459"><span className="persName">Mr. BORST</span>, of Page—</div>
              <p className="p-in-sp">I would add that the Attorney General be also invited.</p>
              <p>Cries of no, no, no.</p>
              <div className="speaker" id="sp4460"><span className="persName">Mr. BORST</span>—</div>
              <p className="p-in-sp">I withdraw the motion.</p>
              <p>The question was then put, on the motion of <span className="persName">Mr. PRESTON</span>, and it was decided in the affirmative.</p>
              <p><span className="persName">Mr. MONTAGUE</span> then renewed his motion, and it was also agreed to.</p>
              <div className="speaker" id="sp4461"><span className="persName">Mr. BLAKEY</span>, of Madison—</div>
              <p className="p-in-sp">I understand the object of receiving Major General Lee in secret session, is to keep the crowd out.</p>
              <div className="speaker" id="sp4462"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">We can remove the injunction of secrecy from the proceedings afterwards.</p>
              <div className="speaker" id="sp4463"><span className="persName">Mr. KILBY</span>—</div>
              <p className="p-in-sp">I would like to know if the confirmation of Major General Lee is a secret matter? If so, I would move to remove the obligation from members in reference to it.</p>
              <p>The motion was agreed to.</p>
              <div className="speaker" id="sp4464"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">I would suggest that this resolution be communicated to Major General Lee to-night.</p>
              <div className="speaker" id="sp4465"><span className="persName">Mr. JOHNSON</span>, of Richmond—</div>
              <p className="p-in-sp">I move that a committee of three be appointed to inform Major General Lee of the invitation extended to him to attend at 12 o'clock, to-morrow, to be received by the Convention.</p>
              <div className="speaker" id="sp4466"><span className="persName">Mr. TURNER</span>, of Jackson—</div>
              <p className="p-in-sp">I move to amend that motion by moving to inform the Governor of the fact of General Lee's confirmation, and request him to communicate that information to Major General Lee.</p>
              <div className="speaker" id="sp4467"><span className="persName">Mr. JOHNSON</span>—</div>
              <p className="p-in-sp">I accept the amendment, but I would add that a committee be appointed to wait upon General Lee, and inform him that he is invited to this hall, to-morrow at 12 o'clock.</p>
              <p>The motion was agreed to. The President appointed the following committee under that motion: Messrs. <span className="persName">MARMADUKE JOHNSON</span>, of <PageNumber num={379} /> Richmond City; PETER C. JOHNSTON, of Lee and Scott, and WM. T. SUTHERLIN, of Pittsylvania.</p>
              <div className="speaker" id="sp4468"><span className="persName">Mr. SHEFFEY</span>—</div>
              <p className="p-in-sp">I move that <span className="persName">Mr. CRITCHER</span>, by general consent, be added to that committee.</p>
              <p>The motion was agreed to.</p>
              <div className="speaker" id="sp4469"><span className="persName">Mr. CAMPBELL</span>, of Washington county—</div>
              <p className="p-in-sp">I rise to suggest that the committee appointed to wait on Major General Lee be charged with the further duty of communicating the action of this Convention to Mr. Stephens, Vice-President of the Confederate States.</p>
              <div className="speaker" id="sp4470"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair will consider that as part of the duty assigned to them.</p>
              <div className="speaker" id="sp4471"><span className="persName">Mr. TURNER</span>, of Jackson—</div>
              <p className="p-in-sp">I move this Convention do now adjourn. The motion was agreed to, and the Convention adjourned at a few minutes to 11 o'clock, P. M.</p>
            </div>
          </div>
          <div className="day" id="vsc1965.v4.2.8">
            <PageNumber num={380} />
            <h2><span className="headingNumber">1.8. </span><span className="head">EIGHTH DAY</span></h2>
            <div className="dateline">Tuesday, <span className="date">April 23</span></div>
            <p>The Convention assembled at 12 o'clock. Prayer by the Rev. Dr. Jeter, of the Baptist Church.</p>
            <div className="speaker" id="sp4472"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">I beg leave to submit a communication from the Governor of the Commonwealth:</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>EXECUTIVE DEPARTMENT, April 23rd, 1861.</p>
                <p>Gentlemen of the Convention:</p>
                <p>I communicate herewith a despatch from the President of the Confederate States in regard to a further requisition for troops made by him. Respectfully,</p>
                <p>JOHN LETCHER.</p>
                <p>MONTGOMERY, April 22d, 1861.</p>
                <p>To Governor JOHN LETCHER:</p>
                <p>In addition to the force heretofore ordered, requisitions have been made for thirteen regiments-eight to rendezvous at Lynchburg, four at Richmond, one at Harper's Ferry. Sustain Baltimore if possible. We reinforce you.</p>
                <p>JEFFERSON DAVIS.</p>
              </div>
            </div>
            <p>This dispatch was, on motion, laid upon the table.</p>
            <div className="speaker" id="sp4473"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">I received a communication from the Council appointed to advise the Governor, which I beg leave to submit for the consideration of the Convention :</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>COUNCIL OFFICE, April 23, 1861.</p>
                <p>Gentlemen of the Convention:</p>
                <p>
                  The Council of three, appointed by a resolution of the Convention, adopted on the 20th April, 1861, to aid, counsel and advise the Governor in the exercise of his executive authority in the present emergency, beg leave to represent to the Convention, that great uncertainty arises out of the terms of the resolution as to the relation which it was contemplated to establish between
                  the Governor and the Council. The expression "to aid the Governor" is ambiguous. It could
                </p>
                <PageNumber num={381} />
                <p>not have been contemplated that the Council should enter upon the performance of executive functions without the concurrence of the Governor, for that would have been to create a dual executive, each acting independently.</p>
                <p>
                  The resolution does not make it necessary that the Governor shall act with the advice of the Council, or direct him, before he exercises any discretionary power to require the advice of the Council, leaving it discretionary with the Governor to conform his action thereto, or not, as to him may seem expedient. If it was contemplated that the Council should counsel and advise the
                  Governor upon such matters as the Council might deem important; such advice, if offered without the request of the Governor, might appear obtrusive, and, if given without adequate knowledge of the facts, might lead to disastrous consequences.
                </p>
                <p>
                  The members of the Council beg leave, in conclusion, to state that, acting under the emergency which prompted the passage of the resolution, they entered upon the performance of the duties imposed on them, and that, so far as they have been consulted, or ventured to make independent suggestions, the utmost harmony has existed between the Governor and Council; but they feel
                  convinced that, under the resolution as it now stands, they cannot render that effective service which the public exigency demands.
                </p>
                <p>JOHN J. ALLEN,</p>
                <p>President of the Council.</p>
              </div>
            </div>
            <div className="speaker" id="sp4474"><span className="persName">Mr. HOLCOMBE</span>—</div>
            <p className="p-in-sp">I move that that communication be referred to a special Committee of five with a request to report as soon as possible.</p>
            <p>The motion was agreed to.</p>
            <p><span className="persName">The PRESIDENT</span> announced the following Committee under that resolution: Messrs. <span className="persName">J. P. HOLCOMBE</span> of Albemarle, <span className="persName">J. M. SPEED</span> of Campbell, <span className="persName">WOOD BOULDIN</span> of Charlotte, <span className="persName">J. G. HOLLADAY</span> of Norfolk county and R. Y. CONRAD of Frederick.</p>
            <div className="speaker" id="sp4475"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">
              I am informed that Major General Lee is now in the Capitol, and will present himself whenever it shall be the pleasure of the Convention to receive him.<span className="note" id="Note41"
              ><span className="noteLabel">1</span>The section of the Proceedings for April 23 concerning the reception of General Lee (the paragraph with the President's announcement that Lee was in the Capitol, and the following text down through the paragraph beginning "The chair was then vacated ..." ) was printed in the Enquirer on April 25, in the Journal of the Convention for April 23, and then
                in the full report of the Proceedings of April 23 which was published in the Enquirer on November 5. There are a few inconsequential differences among the three versions; the versions in the Journal and the Enquirer of November 5 are nearly identical. The version of the Proceedings printed in the Enquirer on November 5 is given here, unless another version is specified.</span
              >
            </p>
            <div className="speaker" id="sp4476"><span className="persName">Mr. MORTON</span>—</div>
            <p className="p-in-sp">I would inquire whether appropriate seats have been assigned for the Vice President of the Confederate States and the other invited guests?</p>
            <div className="speaker" id="sp4477"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">Yes, sir. The Chair would suggest that the invited guests be first introduced into the Hall and assigned their seats before Major General Lee is invited into the Hall.</p>
            <PageNumber num={382} />
            <div className="speaker" id="sp4478"><span className="persName">Mr. SHEFFEY</span>—</div>
            <p className="p-in-sp">I would suggest, in conformity with the suggestions of gentlemen around me, that the Convention ought to receive Major General Lee standing.</p>
            <div className="speaker" id="sp4479"><span className="persName">The PRESIDENT</span></div>
            <p className="p-in-sp">The Chair would merely ask the attention of the Convention to the suggestion.</p>
            <p>
              <span className="persName">Mr. JOHNSTON</span> then introduced Judge Allen, a member of the Advisory Council.<span className="note" id="Note42"><span className="noteLabel">2</span>This paragraph and the one just below it are placed immediately after the paragraph describing the entrance of Mr. Stephens, in the Enquirer of April 25. Their position here is the one they have in the Journal.</span>
            </p>
            <p><span className="persName">Mr. CRITCHER</span> next introduced Colonel Smith, of the Virginia Military Institute.</p>
            <div className="speaker" id="sp4480"><span className="persName">Mr. MORTON</span>—</div>
            <p className="p-in-sp">I would suggest whether it would not be proper to receive the Vice President of the Confederate States standing.</p>
            <div className="speaker" id="sp4481"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">It will be so understood by the Convention.</p>
            <p>At this stage of the proceedings, the Hon. A. H. Stephens, Vice President of the Confederate States, entered the Hall, accompanied by the Governor, and was introduced to the PRESIDENT by <span className="persName">Mr. JOHNSTON</span>, a member of the committee appointed to invite and conduct that gentleman to the Hall.</p>
            <p><span className="persName">Mr. MORTON</span> introduced Capt. M. F. Maury, late of the United States navy, who, with Col. Smith, constitute the other members of the Advisory Council.</p>
            <p>Every delegate was on his feet during this ceremony.</p>
            <p>The Governor and Mr. Stephens were assigned seats on the right of the PRESIDENT, and the three members of the Advisory Council on the left.</p>
            <p>At this time, Major General Lee entered, leaning on the arm of <span className="persName">Mr. JOHNSON</span>, of Richmond, chairman of the committee appointed to conduct the distinguished military chief to the Hall. As they reached the main aisle,</p>
            <div className="speaker" id="sp4482"><span className="persName">Mr. JOHNSON</span> said:</div>
            <p className="p-in-sp">Mr. President, I have the honor to present to you and to the Convention, Major General Lee.</p>
            <div className="speaker" id="sp4483"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">Major General Lee : In the name of the people of your native State, here represented, I bid you a cordial and heartfelt welcome to this Hall, in which we may almost yet hear the echo of the voices of the statesmen, the soldiers and sages of by-gone days, who have borne your name, and whose blood now flows in your veins.</p>
            <p className="p-in-sp">
              We met in the month of February last, charged with the solemn duty of protecting the rights, the honor and the interests of the people of this Commonwealth. We differed for a time as to the best means of accomplishing that object; but there never was, at any moment, a shade of difference amongst us as to the great object itself ; and now, Virginia having taken her position, as far as
              the power of this Convention extends, we stand animated by one impulse, governed <PageNumber num={383} /> by one desire and one determination, and that is that she shall be defended; and that no spot of her soil shall be polluted by the foot of an invader.
            </p>
            <p className="p-in-sp">
              When the necessity became apparent of having a leader for our forces, all hearts and all eyes, by the impulse of an instinct which is a surer guide than reason itself, turned to the old county of Westmoreland. We knew how prolific she had been, in other days, of heroes and statesmen. We knew she had given birth to the Father of his Country; to Richard Henry Lee, to Monroe, and last,
              though not least, to your own gallant father, and we knew well, by your own deeds, that her productive power was not yet exhausted.
            </p>
            <p className="p-in-sp">
              Sir, we watched with the most profound and intense interest the triumphant march of the army led by General Scott, to which you were attached, from Vera Cruz to the Capital of Mexico; we read of the sanguinary conflicts and the blood-stained fields, in all of which victory perched upon our own banners; we knew of the unfading lustre that was shed upon the American arms by that
              campaign; and we know, also, what your modesty has always disclaimed, that no small share of the glory of those achievements was due to your valor and your military genius.<span className="note" id="Note43"
              ><span className="noteLabel">3</span>This paragraph and the one just below it are placed immediately after the paragraph beginning "When the Father of his Country ..." in the Enquirer of November 5. Their position here is the one they have in the Enquirer of April 25 and in the Journal, and seems to be the logical position.</span
              >
            </p>
            <p className="p-in-sp">
              Sir, one of the proudest recollections of my life will be the honor that I yesterday had of submitting to this body the confirmation of the nomination made by the Governor of this State, of you as Commander-in-Chief of the military and naval forces of this Commonwealth. I rose to put the question, and when I asked if this body would advise and consent to that appointment, there rushed
              from the hearts to the tongues of all the members, an affirmative response that told, with an emphasis that could leave no doubt, of the feeling whence it emanated. I put the negative of the question for form's sake, but there was an unbroken silence.
            </p>
            <p className="p-in-sp">
              Sir, we have, by this unanimous vote, expressed our convictions that you are, at this day, among the living citizens of Virginia, "first in war." We pray to God most fervently that you may so conduct the operations committed to your charge, that it will soon be said of you, that you are "first in peace," and when that time comes you will have earned the still prouder distinction of
              being "first in the hearts of your countrymen."
            </p>
            <p className="p-in-sp">I will close with one more remark.</p>
            <p className="p-in-sp">
              When the Father of his Country made his last will and testament, he gave his swords to his favorite nephews, with an injunction that they should never be drawn from their scabbards except in self defence <PageNumber num={384} /> or in defence of the rights and liberties of their country, and, that if drawn for the latter purpose, they should fall with
              them in their hands, rather than relinquish them.
            </p>
            <p className="p-in-sp">Yesterday, your mother, Virginia, placed her sword in your hand, upon the implied condition that we know you will keep to the letter and in spirit, that you will draw it only in her defence, and that you will fall with it in your hand rather than the object for which it was placed there shall fail.</p>
            <div className="stage it">[Applause.]</div>
            <div className="speaker" id="sp4484"><span className="persName">Major General Lee</span> responded as follows :</div>
            <p className="p-in-sp">
              Mr. President and Gentlemen of the Convention: Profoundly impressed with the solemnity of the occasion, for which I must say I was not prepared, I accept the position assigned me by your partiality. I would have much preferred had your choice fallen on an abler man. Trusting in Almighty God, an approving conscience, and the aid of my fellow-citizens, I devote myself to the service of
              my native State, in whose behalf alone will I ever again draw my sword.
              [Applause.] The chair was then vacated, and some time was spent in the introduction of delegates to Major General Lee, and the tender to him of congratulations by the members.</p>
            <div className="speaker" id="sp4485"><span className="persName">The PRESIDENT</span> having again resumed the Chair said :</div>
            <p className="p-in-sp">Gentlemen of the Convention—I have the honor to introduce the Hon. ALEXANDER H. STEPHENS, Vice President of the Confederate States, who comes charged with a special mission from the Confederate States to the Government of Virginia.</p>
            <div className="speech" id="vsc1965.v4.2.8.1">
              <h3><span className="headingNumber">1.8.1. </span><span className="head">SPEECH OF THE HONORABLE ALEX. H. STEPHENS</span></h3>
              <div className="speaker" id="sp4486">[<span className="persName">Alexander Stephens</span>: ]</div>
              <p className="p-in-sp">
                Mr. President and gentlemen of the Convention: I appear before you on this occasion upon your own invitation, representing the Government of the Confederate States. My mission was at your instance, in compliance with a resolution inviting that Government to send a Commissioner here. The powers by which I am accredited were, I presume, communicated to you by your Executive yesterday;
                and I have simply in this interview, in accordance with your request, to state to you very freely, candidly and frankly what are the wishes and objects of our Government in sending me here. I will premise by stating with equal candor and frankness that the communication from this Convention to our Government inviting this conference, was received with a great deal of gratification. I
                presume that no event since the separation of the more Southern States from the late Union, has occurred to give such unbounded pleasure to the whole <PageNumber num={385} /> Southern people, as the news that the Old Dominion had thrown her fortunes with ours.
              </p>
              <p className="p-in-sp">
                We had thought, from the beginning, that this result would ultimately be inevitable. Individually, you will allow me to say I had not the slightest doubt upon the subject, and I feel extremely gratified that my anticipations have been so early realized. When the communication was received that Virginia had seceded, and wished a conference with our Government, there was not the
                slightest hesitation. The telegraph announced it at 2 o'clock, P. M., and by 8 in the evening I was on my way here.
              </p>
              <p className="p-in-sp">
                It is true your resolution simply indicated a wish to form an alliance with the present Confederate States, in the present emergency, in the midst of the present perils which surround you and us alike. The condition of this body is not unknown to our government. The circumstances under which you are assembled, and the limitations of the powers under which you act, are very well known
                at Montgomery. We know the condition on which your Ordinance of Secession was necessarily passed-that it was, under the circumstances, properly subjected to the popular ratification of your people. Embarrassments, it was known, therefore, might attend any alliance that may be made; but the great question, looking to existing, present perils, and the dangers which instantly press upon
                you and us alike, was how best to meet these; how best to provide for to-day, leaving the troubles and embarrassments of future contingencies to be provided for as they may arise. An immediate alliance to the extent of your powers was by your government thought best. It was taken for granted that such, also, was your opinion. This seems to be too apparent to admit of doubt. The only
                question is as to details. Common dangers require common and united action. A war is upon us-upon you and the Confederate States alike. The extent of this war no human being at this moment can foresee. Whether it be short or prolonged; whether it will be bloody and waged on the part of our enemies, with a view to subjugation and extermination, are matters of uncertainty. In this free
                conference I may be permitted to give you my individual opinion on these points, for what it is worth. We can lose nothing by looking dangers full in the face, however great; we may thereby be the better enabled to meet them. My own opinion, then, is, that it is to be a war for our subjugation and the extermination, if possible, of the whole fabric of our civil and social
                institutions. This is my view of its probable, ultimate range; and that it will require all the resources of money and men of the Southern people to maintain their cause successfully, unless, <PageNumber num={386} /> fortunately, by immediate and prompt action, such a decisive blow shall be given, on our part, as will turn the tide of victory in our
                favor at the outset, and show our full power to sustain independence. In this way it may be a war of short duration; but this is rather a hope than an expectation.
              </p>
              <p className="p-in-sp">
                As to the ultimate result-whether long or short, whether waged on a small or extensive scale-I do not permit myself to entertain a doubt. We have the means-the men and those resources which will command the money. All will be put forth, if necessary. Still the issue of this war, as of all wars, as well as the destinies of the nation, we should not forget, are in the hands of the
                Great Sovereign of the universe. In Him and the justice of our cause, and our own exertions, our trust and confidence of success should be placed. Our enemies may rely upon their superiority of numbers, but the race is not always to the swift nor the battle to the strong; but it is with God who gives the victory to the right. The war has not been of our seeking. We have done all that
                we could to avoid it. We feel assured of the righteousness of our cause, and that "thrice armed is he who hath his quarrel just." We have committed no wrong on those who force the war on us; we have made no aggression on them or theirs; we have merely claimed and exercised the right of all free and independent States to govern ourselves as we please, and according to our own wishes,
                without interfering with or in any way molesting the other sovereign and independent States that formed the old Union. With those States we were united under a compact known as the Constitution, that imposed obligations upon all the States. These obligations, on the part of the Southern States, have been faithfully performed, while, on the part of a large number of the Northern
                States, they were openly and avowedly disregarded. The breach of faith was on their part. In the judgment of our people the only hope for safety was in a resumption of their delegated powers. Having resumed the powers delegated to the General Government-a right which Virginia distinctly reserved to herself in the adoption of the Federal Constitution-there is no power on earth that
                can rightfully call in question our acts as free, sovereign and independent States, so far as the old Union is concerned. Even in the opinion of Mr. Webster, the great Northern expounder of the Constitution, when the Northern States refused to fulfill their obligations under the Constitution, it was no longer binding upon the Southern States.
              </p>
              <p className="p-in-sp">But this is a digression. It was only intended to impress the rightfulness of our cause. The matter now before us is the formation of a <PageNumber num={387} /> new alliance that will better secure our rights and our safety-the first object of every State and community.</p>
              <p className="p-in-sp">
                The importance of a union or an alliance of some sort on the part of your Commonwealth with the present Confederated States South, in this conflict for our common rights, I need not discuss before this intelligent body. Any one State, acting in its own capacity, without concert with other States, would be powerless, or at least could not exert its power efficiently. The cause of
                Virginia, and I will go further, the cause of Maryland, and even the cause of Delaware, and of all the States with institutions similar to ours, is the cause of the Confederate States-the cause of each, the interests of each, the safety of each is the same; and the destiny of each, if they could all but be brought to realize the dangers, would be the same. Therefore, where there is a
                common danger, where there is a common interest, where there is a common safety, where there is a common destiny, there ought to be a common and united effort.
              </p>
              <p className="p-in-sp">This is the view entertained by our Government, and hence the invitation of the Commonwealth of Virginia was responded to so promptly.</p>
              <p className="p-in-sp">
                There are various reasons that I might present to enforce the importance of such a policy, if I were aware of there being the slightest necessity for it; but I am not. Indeed, I am speaking without knowing anything of the individual sentiments of the members of the Convention; and it may be that what I am now stating to the Convention as very important to them and to us, is a subject
                upon which there is no difference of opinion. The truth of the general propositions thus cursorily stated, seems to me to be so self evident that I feel it hardly necessary to argue them before you. I will, however, add a few things briefly.
              </p>
              <p className="p-in-sp">
                First, as to the ends or objects of the alliance. To me it seems very important that your military should at least be in co-operation with, if not under the direction of the Confederate States Government. We will necessarily have a large amount of forces in the field. When I left Montgomery there were 59,000 troops ordered out; 15,000 of them were then under arms, and most of them
                are perhaps under arms by this time. From information received from the Executive to-day, it appears that the President of the Confederacy has ordered out thirteen more regiments since I left. That will be about 12,000 more troops. North Carolina may be considered as co-operating with us now, though this large force
                [72,000]
                does not embrace any from that State. Tennessee also has tendered 5,000, with an assurance from <PageNumber num={388} /> distinguished gentlemen from that State to our Government, on Tuesday of last week, that soon after the news of the bombardment of Fort Sumter, 15,000 had tendered their services, and that, if necessary, 50,000 would be forthcoming.
                So large a number, however, would not be called for from there.
              </p>
              <p className="p-in-sp">
                Kentucky, also, has a large body of men who will be mustered into our service should the exigency arise. It may be that some of those troops may be discharged, and their places supplied by others; but mop() men will perhaps be in the field in less than three months. That is not counting Virginia. You, of course, will have a large force. All these forces should co-operate to be
                efficient; and while I don't claim to be a military man, it seems to me to be clear, on general rational principles, that all the forces-those of the Confederate States, those of Virginia, as well as those of the Border States that are not yet out of the Union-should be under one head, as also all the military operations of the country directed to the same ends. It is generally
                admitted that, in the execution of laws, it is essential that there should be one head; but more important than in the usual execution of laws is it that military operations should be under one head. In physical economy all the parts and functions in each organism, to be efficient, are under the control of one head, one animating, moving spirit, with one sensorium, one mind, one
                directing will. In military matters, looking to the same ends and objects, there should be one head. It is probable Virginia will be the main theatre, to a great extent, of the pending conflict. Maryland may be, perhaps-we don't know; but the line of Virginia, your great waters on the North, necessarily make you, in this conflict, the theatre of large and extensive military
                operations, if not the scene of the bloodiest conflicts that this continent has ever yet witnessed. You will, necessarily, therefore, look to the Southern Confederacy immediately for aid, even whether you become a member of it or not. I will state here, however, before passing any further, that we are looking to this, your ultimate union with us, as a fixed fact; and the unanimous
                desire of every branch of our Government is, that, just as speedily as possible, you will thus link your fortunes with ours. Your cause is ours, your future will be ours; and your destiny must be ours.
              </p>
              <p className="p-in-sp">
                But my mission relates to the intermediate time; to such alliance as may be necessary for the next twenty or forty days-before action can be taken by the people in their sovereign capacity at the ballot box. In the meantime, between now and then, the salus populi must be the rule of your action as the custodiers of popular rights. Your
                <PageNumber num={389} /> duty to yourselves and your homes, is to look immediately to the pressing wants of your people, and, in the meantime, make such preparations as are necessary to meet this extraordinary exigency. Is it not essential that there should be concert and united action under one head? Now, what can Virginia do under a military
                organization distinct from that of the Confederate States? How can she act in concert with her allies, or those willing to help her without some compact or agreement? Troops from the South are already on the way here. Two regiments from South Carolina will, perhaps, be here within the next 24 hours. Forces have been ordered from Louisiana, and are coming immediately to your
                assistance. Ought there not to be some understanding as to how they shall be received and how directed? Would it not be better that these troops, as well as your whole military operations, should be under the control and supervision of our Government? To me it seems essential for efficient action. These suggestions are thrown out for the consideration of the Convention.
              </p>
              <p className="p-in-sp">
                There are other considerations which I might also present. I know the condition of your State in financial matters only to a limited extent. I know the vast resources of Virginia, and I know that her people, with the patriotism that has ever distinguished them, would never permit her cause to suffer for lack of means at any cost or sacrifice. But have you the means now at command?
                Arms must be had, munitions of war must be procured, men must be sent immediately to the field-these must be clothed and fed as well as armed. All this will require money. "Money is the sinew of war." Where money cannot be had, credit may answer. But money or credit, which will command it, is essential. On the financial point, so far as it relates to the Confederate States, I may
                state here, that our Congress authorized a loan of fifteen millions at its late session.
              </p>
              <p className="p-in-sp">
                The Secretary of the Treasury advertised for five millions. The loan was taken the day I left Montgomery. There were two days for its subscription. When I left, news had already reached by telegraph from the cities that seven millions of the loan of five that had been offered had been taken. The subscriptions in the interior towns had not been heard from, but it was believed that the
                whole amount would not fall far short of nine or ten millions-double the amount offered. This shows how our credit stands-the money thus raised is now at the disposal of our Government; and it was believed that if an offer for the other five millions should be made, making the whole fifteen millions, it would be subscribed in ten days. Our people,
                <PageNumber num={390} /> from South Carolina to the Rio Grande, are in this movement heart and soul; and every dollar that can be raised will be used for the defence of the country in this emergency. No serious difficulty is apprehended as to our ability to raise the necessary means. In the State of Georgia, before we entered into an alliance with the
                other States, apprehensions were felt as to our available means. Georgia ordered a loan on her own account, of one million of dollars. This was promptly raised or provided for in our own State. What amount it will require to put your State in proper defence and to meet the invasion that may be looked for is a matter for your own considerate attention-and also whether the State at
                this time could, without a sacrifice of her credit, raise the requisite amount.
              </p>
              <p className="p-in-sp">
                An army of not less than 50,000 men will doubtless be required in your State. On this point your distinguished commander in chief, just duly installed into office, can of course give better information than any conjecture of mine. But whether a small or large force shall be required, it may be considered as certain that many millions will be required to cover the expense. Whether you
                have the means to do this, is a matter for you to consider.
              </p>
              <p className="p-in-sp">
                Again : if you had the means, another question is, would it be right for Virginia, on her own account, to make this heavy expenditure in this enterprise? Because you stand on the border, it is not our desire that you should fight our battles. We don't wish you alone to fight these battles, or to bear yourself the expense of defending Virginia. I know that the intimation has been held
                out in other parts that we were not considering the peculiar circumstances of our brethren on the border States. I give you every assurance that our government feels thoroughly identified with you in interest, and we do not wish your great Commonwealth to do more than bear her part in this contest. We know she is willing to do that. So far as the pecuniary matters are concerned then,
                I simply suggest whether it would not be wise and just and proper that all should share the burden equally-and whether we should not, as our fathers did, in the first struggle for independence, look to each other, and bear equally the costs of a common cause? This I present, whether Virginia joins us ultimately or not. But to be entirely frank, I must say that we are looking to a
                speedy and early union of your State with our Confederacy. Hence the greater importance for this immediate and temporary alliance. We. want Virginia, the mother of States, as well as of statesmen, to be one of the States of our Confederation. We want it because your people are our people-your interests are our <PageNumber num={391} /> interests; nay,
                more : because of the very prestige of the name of the Old Commonwealth. We want it, because of the memory of Jefferson, of Madison, and Washington, the father of his country-we want it for all the associations of the past-we want it because the principles in our Constitutions, both provisional and permanent, sprung from Virginia. They emanated from your statesmen-they are Virginian
                throughout -taught by your illustrious sages and, by their instrumentality mainly, were incorporated in the old Constitution. That ancient and sacred instrument has no less of our regard and admiration now than it ever had. We quit the Union, but not the Constitution-this we have preserved. Secession from the old Union on the part of the Confederate States was founded upon the
                conviction that the time honored Constitution of our fathers was about to be utterly undermined and destroyed, and that if the present administration at Washington had been permitted to rule over us, in less than four years, perhaps, this inestimable inheritance of liberty, regulated and protected by fundamental law, would have been forever lost. We believe that the movement with us
                has been the only course to save that great work of Virginia statesmen.
              </p>
              <p className="p-in-sp">
                On this point indulge me a moment. Under the latitudinarian construction of the Constitution which prevails at the North, the general idea is maintained that the will of the majority is supreme; and as to constitutional checks or restraints, they have no just conception of them. The Constitution was, at first, mainly the work of Southern men, and Virginia men at that. The Government
                under it lasted only so long as it was kept in its proper sphere, with due regard to its limitations, checks and balances. This, from the origin of the Government, was effected mainly by Southern statesmen. It was only when all further effort seemed to be hopeless to keep the Federal Government within its proper sphere of delegated powers, that the Confederate States, each for
                itself, resumed those powers and looked out for new safe-guards for their rights and domestic tranquility. These are found not in abandoning the Constitution, but in adhering only to those who will faithfully sustain it.
              </p>
              <p className="p-in-sp">
                We have rescued the Constitution from utter annihilation. This is our conviction, and we believe history will so record the fact. You have seen what we have done. Our Constitution has been published. Perhaps most of you have read it. If not I have a copy here, which is at the service of any who may wish to examine it. It is the old Constitution, with all its essentials and some
                changes, of which I may speak presently.
              </p>
              <PageNumber num={392} />
              <p className="p-in-sp">
                It is upon this basis we are looking to your union with us; first, by the adoption of the Provisional Constitution, and then of the permanent one, in such way as you may consider best, under the limitations of your powers. This I may be pardoned for pressing upon the Convention, and expressing the hope that they may do it, utterly ignoring all past differences of opinion.
              </p>
              <p className="p-in-sp">
                In all bodies of men differences of opinion may be expected; but the disagreements and differences with you, as was the case with us, will perhaps be found to relate more as to the mode of action, than to the propriety and necessity of action of some sort. As to differences in the past, on the subject of Union and Secession, let them be buried and forgotten forever.
              </p>
              <p className="p-in-sp">
                My position and views upon these questions in the past may be known to you. If not, it may be proper to state, and I feel no reluctance in declaring, in your presence here in the capitol of the old Commonwealth of Virginia, that there never breathed a human spirit on the soil of America more strongly and devoutly attached to the Union of our fathers than I. I was, however, in favor
                of no Union that did not secure perfect equality and protection of all rights guaranteed under the Constitution. I was not insensible of the fact that several of the Northern States had openly repudiated their constitutional obligations, and that if the principles of the present dominant party should be carried out, ultimate separation was inevitable. But still, I did trust that
                there was wisdom and patriotism enough at the North when aroused to correct the evils, to right the wrongs and to do us justice. I trusted even to the last for some hopeful reaction in the popular sentiment at the North.
              </p>
              <p className="p-in-sp">
                I was attached to the Union, however, not on account of the Union per se, but I was attached to it for what was its soul, its vitality and spirit; these were the living embodiment of the great principles of self government, springing from the great truth, that the just powers of all governments are derived from the consent of the governed, as it was transmitted to us by our fathers.
                This is the foundation on which alone all constitutional liberty is and must be based-and to these principles I am to-day attached just as ardently as I ever was before, and I now announce to you my solemn conviction that the only hope you have for the preservation of these p-inciples, is by your alliance with those who have rescued, restored and re-established them in the
                Constitution of the Confederate States-there is no hope in the States North.
              </p>
              <p className="p-in-sp">
                The disagreements that existed in our State as to the course that we <PageNumber num={393} /> should pursue, before the last resort of secession was adopted were more as to the mode and manner of redress, than as to the cause of the grievance or the existence of the grievance requiring redress. I take this occasion, in passing, to state to you, that in
                our Convention there was considerable difference of opinion on this view of the subject. It may not be known to you that on that occasion, I disagreed with the majority on the course adopted. My vote was recorded against the secession ordinance in our State. I was for making one more effort and for getting the whole South united if possible in that effort for redress.
              </p>
              <p className="p-in-sp">
                But when the State in her sovereign capacity determined otherwise, my judgment was yielded to hers. My allegiance was due to her. My fortunes were linked with hers; her cause was my cause; and her destiny was my destiny. A large minority in that Convention voted as I did. But after secession was determined on by the majority, a resolution was drawn up to the effect, that whereas the
                lack of unanimity on the passage of the ordinance was owing more to a disagreement as to the proper mode at the time for a redress of existing wrongs and threatened wrongs than as to the fact of the existence of such wrongs as required redress; therefore, after the mode and manner was adopted by a majority of the Convention, that all of us, as an evidence of our determination to
                maintain the State in her chosen remedy, should sign the ordinance; and with that determination under that resolution, every member of the Convention, except six, signed it. Those six also declared upon record a like determination on their part. So our State became a unit upon the measure, when it was resolved upon. All anterior differences amongst us were dropped. The cause of
                Georgia was the cause of us all; and so I trust it will be in Virginia. Let all past differences be forgotten. Whether, if some other course had been adopted, our rights could have ultimately been secured in the old Union, is a problem now that can never be solved. I am free to confess, as I frankly do, that the late indications afford strong evidence that the majority at the North
                were bent upon our destruction at every cost and every hazard. At all events, we now know that our only hope is on our own strong arms and stout hearts, with unity among ourselves. Our course is adopted. We can take no steps backwards. The time for compromise, if it ever existed, is past. Many entertained hopes from the "Peace Congress"-that failed. Even an extension of the Missouri
                line, which was offered by prominent Southern men, was sullenly rejected. Every indication of Northern sentiment on the part of the dominant party there, since the election <PageNumber num={394} /> last fall, shows that they were and are bent upon carrying out their aggressive and destructive policy against us. This they insidiously expected to succeed
                in by relying upon the known strong Union sentiment in the border States. They evidently relied strongly on this in Virginia, their policy being to divide and conquer. In this, I think, however, they counted without their host.
              </p>
              <p className="p-in-sp">The people of Virginia may have been attached to the Union; but they are much more attached to their homes, their firesides and all that is dear to freemen-constitutional liberty.</p>
              <p className="p-in-sp">All hopes of preserving this in the old Union are gone forever. We must for the future look to ourselves. It is cheering to feel conscious that we are not without hope in that quarter. At first, I must confess, that I was not without serious apprehensions on that point. These apprehensions were allayed at Montgomery.</p>
              <p className="p-in-sp">
                The men who were sent there were not such materials as revolutions usually throw up. They seemed to understand thoroughly the position of affairs-the past, the present and the future. They duly appreciated the magnitude of the responsibilities resting upon them, and proved themselves, I trust, not only determined to overthrow our Government, but capable of building up another. Their
                work, as I have said, is before you. One leading idea runs through the whole-the preservation of that time-honored constitutional liberty which they inherited from their fathers.
              </p>
              <p className="p-in-sp">
                The first thing was to organize a Provisional Government. This was done by the adoption of the Provisional Constitution. It is to last but one year, and conforms to our ancient usages as nearly as practicable. No changes in essential or fundamental principles. We have but one legislative body. This possesses the powers of the old Senate and House combined; but the rights of the
                States and the sovereign equality of each is fully recognized-more fully than under the old Constitution, which was the basis of the action of the Convention; for, during the Provisional Government, on all questions in Congress, each State has an equal vote. This Provisional Government was only a temporary arrangement to meet the exigencies until a permanent constitution could be
                formed and put into operation. This was really the great work before them.
              </p>
              <p className="p-in-sp">
                In this, as in the Provisional Government, the old Constitution of our fathers-the Constitution of Madison and Washington, was their model. I said I might say something touching its provisions. Time will not allow me to go much into details. You will please read and examine it minutely for yourselves. While the old Constitution
                <PageNumber num={395} /> was the basis and model of its construction, you will find in it several changes and modifications, some of them important. But of them all I make in passing this general remark-they are all of a conservative character. This is the most striking characteristic of our revolution or change of government thus far, that none of the
                changes introduced are of a radical or downward tendency.
              </p>
              <p className="p-in-sp">
                But all the changes-every one of them-are upon what is called the conservative side. Now, this I ask your special attention to. It is an important fact. I wish you specially to mark it, for I know that efforts have been made to create prejudice against our movement by telling the conservative men of the country that it sprung from some of the hot-heads down South, and should not be
                relied on or trusted. But take this Constitution and read it, and you will find that every change in it from the old Constitution is conservative. In many respects it is an improvement upon the Constitution of our fathers. It has such improvements as the experience of seventy years showed were required. In this particular our revolution thus far is distinguished from popular
                revolutions in the history of the world. In it are settled many of the vexed questions which disturbed us in the old Confederacy. A few of these may be mentioned-such as that no money shall be appropriated from the common Treasury for internal improvements; leaving all such matters for the local and State authorities. The tariff question is also settled. The Presidential term is
                extended, and no re-election allowed. This will relieve the country of those periodical agitations from which sprang so much mischief in the old government. If history shall record the truth in reference to our past system of government, it will be written of us that one of the greatest evils in the old government was the scramble for public offices. Connected with the Presidential
                election, this evil is entirely obviated under the Constitution which we have adoped.
              </p>
              <p className="p-in-sp">
                Many other improvements, as I think, could be mentioned, but it is unnecessary. I have barely alluded to the subject to show you that we do not invite you to any wild scheme of revolution. We invite Virginia to join us in perpetuating the principles upon which she has ever stood-the only hope of constitutional liberty in the world, as I now seriously apprehend. If it fails with us,
                where else can we see hope? But for the South, what would have become of the principles of Jefferson, Madison and Washington as embodied in the old Constitution long ago? Whatever the United States Government has done in advancement of civilization, by solving the great principles of self-government by the people through representatives clothed with delegated
                <PageNumber num={396} /> powers, is due mainly to the South. The achievement has been by Southern statesmen. The honor and glory of the Western Republic, to which the eyes of the world has been directed for years, was the work mainly of Southern men, and my judgment is, if you will pardon its expression, that just so soon as the South is entirely
                separated from the North, and the Government at Washington has no longer the advice and council of your statesmen and the men of the South, they will go into confusion and anarchy speedily. It gives me no pleasure to think so. It would be to our advantage as well as theirs, for them, as we can no longer live in safety and peace under the same Constitution, to go on and be prosperous,
                and leave us to do the same. But my conviction is that they will not. They do not understand constitutional liberty. It is an exotic in their clime. It is a plant of Southern growth. I have, however, no war to make on their institutions. They seem to think them better than ours, and, not satisfied with this, they war upon ours. Now, the true policy of both sides should be to let each
                other alone. Let both try their systems not in war, but in friendly rivalship. Hence it is from no unkind feelings towards them or their institutions that I express the opinion I do. I believe that our institutions are by far the best. My judgment is that theirs will be a failure. I would give them every opportunity to :try them thoroughly by themselves and for themselves. I simply
                give my view of what I believe to be the prospect on both sides, as well as the true policy of both; but I seriously doubt whether the rivalry which I would fain indulge the hope of seeing carried out, will be engaged in. War is what they are bent on in the start. When this will end time alone can determine. What 1 have ventured to say of the probable future of the North is founded
                upon the experience and association of many years with their public men in Washington. They do not seem to understand the nature or workings of a federative system. They have but slender conceptions of limited powers. Their ideas run into consolidation.
              </p>
              <p className="p-in-sp">
                Whilst I was in Congress I knew of but few men there from the North who ever made a constitutional argument on any question. They seemed to consider themselves as clothed with unlimited power. Mr. Webster was one of these distinguished few. Though he generally differed with Southern men on points of constitutional power, yet he argued his side with great ability. Mr. Douglas is also
                another distinguished exception to the general remark. One or two others might be named as exceptions to the rule, but the great majority, the almost entire Representation from the North in Congress, both in the House and Senate, seemed really to have no correct idea of the <PageNumber num={397} /> nature of the Government they were engaged in carrying
                on. They looked upon it simply as a Government of majorities.
              </p>
              <p className="p-in-sp">
                They did not seem to understand that it was a government that bound majorities by constitutional restraints. Now, nothing is more fixed or certain than that constitutional liberty can be maintained only by a rigid adherence to fundamental principles. Government is a science-the Northern mind seems disinclined to that sort of study. Excuse this digression. It may not, however, be
                altogether inappropriate to the occasion-all things being duly considered. It springs from no disposition on my part wantonly to disparage Northern character. It is intended rather to show where our future safety and security lies. We have our destiny under Providence in our own hands, and we must work it out the best we can. All we ask of our late confederates is to let us alone.
                But, be this as it may, we shall, I trust, be equal to the future and our mission, whether they choose to pursue toward us a peace or a war policy.
              </p>
              <p className="p-in-sp">With union, harmony, concert of action and patriotism, our ultimate success in establishing or rather perpetuating a stable and good government on our ancient republican model, need not be feared.</p>
              <p className="p-in-sp">
                One good and wise feature in our new or revised Constitution is, that we have put to rest the vexed question of slavery forever, so far as the Confederate halls are concerned. On this subject, from which sprung the immediate cause of our late troubles and threatened dangers, you will indulge me in a few remarks as not irrelevant to the occasion. The condition of the negro race
                amongst us, presents a peculiar phase of republican civilization and constitutional liberty. To some, the problem seems hard to understand. The difficulty is in theory, not in practical demonstration; that works well enough-theories in government, as in all things else, must yield to facts. No truth is clearer than that the best form or system of government for any people or society
                is that which secures the greatest amount of happiness, not to the greatest number, but to all the constituent elements of that society, community or State. If our system does not accomplish this; if it is not the best for the negro as well as for the white man; for the inferior as well as the superior race, it is wrong in principle. But if it does, or is capable of doing this, then
                it is right, and can never be successfully assailed by reason or logic. That the negroes with us, under masters who care for, provide for and protect them, are better off and enjoy more of the blessings of good government than their race does in any other part of the world, statistics abundantly prove. As a race, the African is inferior to the white
                <PageNumber num={398} /> man. Subordination to the white man, is his normal condition. He is not his equal by nature, and cannot be made so by human laws or human institutions. Our system, therefore, so far as regards this inferior race, rests upon this great immutable law of nature. It is founded not upon wrong or injustice, but upon the eternal
                fitness of things. Hence, its harmonious working for the benefit and advantage of both. Why one race was made inferior to another, is not for us to inquire. The statesman and the Christian, as well as the philosopher, must take things as they find them, and do the best he can with them as he finds them.
              </p>
              <p className="p-in-sp">
                The great truth, I repeat, upon which our system rests, is the inferiority of the African. The enemies of our institutions ignore this truth. They set out with the assumption that the races are equal; that the negro is equal to the white man. If their premises were correct, their conclusions would be legitimate. But their premises being false, their conclusions are false also. Most
                of that fanatical spirit at the North on this subject, which in its zeal without knowledge, would upturn our society and lay waste our fair country, springs from this false reasoning. Hence so much misapplied sympathy for fancied wrongs and sufferings. These wrongs and sufferings exist only in their heated imaginations. There tan be no wrong where there is no violation of nature's
                laws. We have heard much of the higher law. I believe myself in the higher law. We stand upon that higher law. I would defend and support no Constitution that is against the higher law. I mean by that the law of nature and of God. Human Constitutions and human laws that are made against the law of nature or of God, ought to be overturned; and if Seward was right the Constitution
                which he was sworn to support, and is now requiring others to swear to support, ought to have been overthrown long ago. It ought never to have been made. But in point of fact it is he and his associates in this crusade against us, who are warring against the higher law-we stand upon the laws of the Creator, upon the highest of all laws. It is the fanatics of the North, who are
                warring against the decrees of God Almighty, in, their attempts to make things equal which he made unequal. My assurance of ultimate success in this controversy is strong from the conviction, that we stand upon the right. Some years ago in the hall of the House of Representatives, a very prominent gentleman from Ohio, announced with a great deal of effect, that we at the South would
                be obliged to yield upon this question of slavery, because we warred against a principle; and that it was as impossible to war successfully against principle in politics as <PageNumber num={399} /> it was in mechanics. The principle said he, would ultimately prevail. He announced this with imposing effect, and endeavored to maintain that we were
                contending against the great principle of equality in holding our fellow men in the unnatural condition of bondage. In reply, I stated to him, that I admitted his proposition as he announced it, that it was impossible to war successfully against a principle in mechanics and the same was true in politics-the principle would certainly prevail-and from that stand point I had come to the
                conclusion that we of the South would ultimately succeed and the North would be compelled to yield their ideas upon this subject. For it was they who were contending against a principle and not we. It was they who were trying to make the black man a white man, or his equal, which was nearly the same thing. The controlling laws of nature regulate the difference between them as
                absolutely as the laws of gravitation control whatever comes within their action-and until he could change the laws of gravitation, or any other law of nature, he could never make the negro a white man or his equal. No human efforts or human laws can change the leopard's spots or the Ethiopian's skin. These are the works of Providence-in whose hands are the fortunes of men as well as
                the destiny of nations and the distinctions of races.
              </p>
              <p className="p-in-sp">
                On this subject a change is evidently going on in the intellectual world-in the republic of thinkers. The British West India experiment has done much to produce this change. All theories on the problem of human society must in the end yield to facts-just as all theories and speculations in other departments of science must yield to the same sure and unerring test. The changes of
                sentiment upon the subject of negro subordination have been great already, for this is the proper term to designate his condition with us. That they will continue as truth progresses, there can be no doubt. All new truths progress slowly. With us this change of view and sentiment has been wonderful. There has been almost a complete revolu' tion within the last half century. It was a
                question little understood by the eminent statesmen of the South seventy years ago. This is no disparagement to their wisdom or ability. They were occupied in the solution of other great new truths upon which rested the first great principles of self-government by the governing race. These principles they solved and established. They met and proved themselves equal to the exigencies
                of their day and generation-that was enough to fill the measure of their fame. Each generation in the eternal progress of all things connected with existence, must meet new questions, new problems, <PageNumber num={400} /> new phases of even old subjects, and it will be enough for the men of each generation, if they prove themselves equal to the
                requirement of the times in which they live. As our fathers were equal to all the questions of their day, so may their sons be at this and all succeeding times. This is the point to which our attention should be chiefly directed.
              </p>
              <p className="p-in-sp">
                In our Constitution, provision is made for the admission of other States into the Confederacy; but none can be admitted without first adopting our Constitution, and, consequently, none can be admitted which does not first adopt the fundamental principles on which our social and domestic institutions rest-thereby removing forever from our public or confederate councils that question
                which gave rise to so much disturbance in the old government.
              </p>
              <p className="p-in-sp">
                I have, perhaps, detained you much longer than I ought to have done, and upon matters, perhaps, which you may consider not very pertinent to the object of my mission. This you will please excuse. As I said in the outset, I appeared before you upon your invitation and was rather at a loss what to say, until I knew more of your own objects and wishes-and without, therefore, trespassing
                further upon your time and patience, in conclusion, I will barely add, by way of recapitulation, the main object, then, I had in view in coming before you to day, was simply to announce that our Government hailed with joy the news of your secession from the old Government, and a desire on your part to form an alliance with us. Our Government is very desirous that your ancient
                Commonwealth shall become a member of our Confederacy. Your interests and ours are the same; your safety the same, and your ultimate destiny must be the same. We are looking to your union with us as a certainty. But, in the meantime-before that union can be perfected by the action of your people, we think a temporary alliance or Convention of the highest importance to meet the
                exigencies of the day and the hour. The enemy is now on your border-almost at your door-he must be met. This can best be done by having your military operations under the common head at Montgomery-or it may be at Richmond. For, while I have no authority to speak on that subject, I feel at perfect liberty to say, that it is quite within the range of probability that, if such an
                alliance is made as seems to me ought to be made, the seat of our Government will, within a few weeks, be moved to this place. There is no permanent location at Montgomery-and should Virginia become, as it probably will, the theatre of the war, the whole may be transferred here-then all your military operations with ours will be under a common head.
              </p>
              <PageNumber num={401} />
              <p className="p-in-sp">Your distinguished Commander in Chief [General Lee]
                , will, doubtless, have such position as his great military talents and merits deserve. Whether in the Confederate Army proper, or in the State service, will, I doubt not, depend upon his own choice. The great object is to have perfect union, harmony and co-operation under one head. We think also that it is better for you, in a financial point of view, to unite with us immediately.
                Besides this, we want your members at Montgomery. We want the voice of Virginia in our Confederate Councils.
              </p>
              <p className="p-in-sp">
                On this point, I would suggest to you that this Convention immediately, if you think you have got the power, appoint delegates to our Provisional Congress. My opinion is you have got the power. You may have to refer back to your constituents whatever change you make in your Federal relations and in your State Constitution; but in all other matters you have plenary power. You
                certainly have full power to send delegates to the Provisional Congress.
              </p>
              <p className="p-in-sp">
                Is it not expedient that you should send members immediately to the Congress that is to assemble at Montgomery next week? If you think it is necessary that this matter should be decided by the people, I would wait, even though perils threatened, before I would infringe upon the rights of the people. But at all events, I wish you to understand that we expect you to join as soon as you
                can. If you see fit to make an alliance offensive and defensive, we will have our military here just as soon after the alliance is concluded as possible. We want you to join us permanently by the adoption of the permanent Constitution, which will go into operation next winter, and of course it will be important to you in regard to the elections, that you change your fundamental law
                so far as relates to the election of members to the Southern Congress under that Constitution.
              </p>
              <p className="p-in-sp">
                I must apologize to you for trespassing so long upon your patience. I have said so much in a desultory way that I have, I fear, overlooked or omitted some things that would have been more appropriate if I had known more of the temper and views of your body. But this is a time for free conference and consultation upon the general state of public affairs. It is from this conviction I
                have addressed you as I have. You are now in possession of my views very fully and frankly. It may be that something may occur that may render it proper for me to appear before you again. In any discussions that may grow out of what I have submitted, I hold myself in readiness to confer with you; and if this body should decide to form any alliance or treaty that may be thought
                proper, such as I have intimated, I will be found <PageNumber num={402} /> ready to meet them or any number that may be appointed to negotiate with me on the subject. I am alone, and have no associate; but any number that may be thought best on your part to meet me can be appointed.
              </p>
              <p className="p-in-sp">If you desire to hear from me on any other point, most cheerfully I will be at your command.</p>
              <div className="speaker" id="sp4487"><span className="persName">Mr. CRITCHER</span>—</div>
              <p className="p-in-sp">I move we take a recess until 4 o'clock, P. M.</p>
              <p>The motion was agreed to, and the Convention accordingly adjourned till 4 o'clock.</p>
            </div>
            <div className="session" id="vsc1965.v4.2.8.2">
              <h3><span className="headingNumber">1.8.2. </span></h3>
              <p>EVENING SESSION</p>
              <p>The Convention re-assembled, at the appointed hour.</p>
              <div className="speaker" id="sp4488"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">I have a motion to submit.</p>
              <div className="speaker" id="sp4489"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">There is no quorum present.</p>
              <div className="speaker" id="sp4490"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">The motion which I mean to submit will hardly require a quorum.</p>
              <p className="p-in-sp">
                There was a proceeding in the morning session which, I am sure, would afford much gratification to the public, if it were made known. It was honorable to the Convention; it was honorable to the presiding officer of this body, and will be in the highest degree satisfactory to the public. I desire that the public should know what was transacted at the morning session, and I, therefore,
                move, that there be furnished, for immediate publication, the address of the President of this body to Major General Lee, and General Lee's reply-feeling assured that the Convention could do nothing that would be more pleasing to our constituents.
              </p>
              <div className="speaker" id="sp4491"><span className="persName">Mr. SHEFFEY</span>—</div>
              <p className="p-in-sp">I would move to amend that motion by accompanying the addresses of the President and Major General Lee with a description of the ceremonies of the reception.</p>
              <div className="speaker" id="sp4492"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair will state that the reporter has prepared both a report of the addresses and ceremonies of the reception.</p>
              <div className="speaker" id="sp4493"><span className="persName">Mr. SHEFFEY</span>—</div>
              <p className="p-in-sp">I take occasion to say, that the scene was most gratifying to me, and that I shall carry it with me in my memory as long as I live.</p>
              <div className="speaker" id="sp4494"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair is of opinion that there is not a quorum present; but inasmuch as the subject is not one of much importance, and would be likely to meet the concurrence of the whole body, he will put the question upon motion of the gentleman from Richmond.</p>
              <p>The question was then put on the motion of <span className="persName">Mr. MACFARLAND</span>, and it was decided in the affirmative, there being still no quorum.</p>
              <div className="speaker" id="sp4495"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">
                I would suggest, that in respect to matters not <PageNumber num={403} /> likely to lead to a division, we need not wait for a quorum. The fact is not known, by authority, whether we have a quorum or not; and we can, therefore, proceed with such business as referring matters, to committees and other unimportant matters, not requiring a division of the
                House.
              </p>
              <div className="speaker" id="sp4496"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair is not now aware that there is no quorum.</p>
              <div className="speaker" id="sp4497"><span className="persName">Mr. HOLCOMBE</span>—</div>
              <p className="p-in-sp">I beg leave to submit the following report from the special committee on the duties of the Advisory Council:</p>
              <div className="floatingText">
                <div className="floatingText_body">
                  <p>"The committee to whom was referred the communication of the President of the Council of three appointed on the 20th inst., beg leave to report, for the adoption of the Convention, the following ordinance:</p>
                  <p>
                    "1st. Be it ordained, that in the appointment of a Council of three by the Convention, upon the nomination of the Governor, to aid, counsel and advise him in the exercise of his Executive authority in the present emergency, it was not the purpose or intention of the Convention to confer on the Council the power to control the Governor in the exercise of his Executive functions,
                    but it was their purpose to impose upon the Governor the obligation to consult the Council in regard to all Executive acts.
                  </p>
                  <p>"2d That a regular journal of the Executive proceedings and acts should be kept, in which should be recorded all appointments and proceedings of the Executive, and the votes of the several councillors thereupon."</p>
                </div>
              </div>
              <div className="speaker" id="sp4498"><span className="persName">Mr. HOLCOMBE</span>—</div>
              <p className="p-in-sp">
                I may say that, before this application was made in behalf of the Council, by their President, for a construction of their power from the Convention, on my own responsibility, and understanding that some ambiguity was supposed to exist in the law, I went to the Governor and had a conversation with him on the subject. I thought it proper to do so, inasmuch as I offered the resolution
                for the appointment of the Council.
              </p>
              <p className="p-in-sp">
                I stated to him, that in offering that resolution, I said to the Convention that my understanding of the meaning of the resolution was, that the Council should be an advisory one, not having power to control the Executive, but that it was merely to be a council with which the Executive was to consult. The Governor expressed to me his concurrence in that view of the resolution; so
                that I presume this construction of the Convention is in accordance with his view, as well as with that of those who moved the proposition.
              </p>
              <p className="p-in-sp">I beg leave to offer these two ordinances for the adoption of the Convention.</p>
              <PageNumber num={404} />
              <p>They were agreed to.</p>
              <div className="speaker" id="sp4499"><span className="persName">Mr. CRITCHER</span>—</div>
              <p className="p-in-sp">I would ask if that construction is not too stringent on the Governor? That ordinance requires him to consult this Advisory Council on all matters; and surely an obligation seems to me to be entirely too stringent. I would suggest that he be left a discretion to consult this Advisory Council whenever he deems it expedient.</p>
              <div className="speaker" id="sp4500"><span className="persName">Mr. HOLCOMBE</span>—</div>
              <p className="p-in-sp">I will state that that difficulty was suggested by a member of the council; but my answer to it is that it is impossible to draw the line of discrimination, and that there will be no practical difficulty at all in the council's conferring upon the Governor all authority to act in reference to matters in no wise involved in the critical condition of the country.</p>
              <div className="speaker" id="sp4501"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">I would suggest that it would be well to define more particularly what is meant by the council. The council consists of three. Does the ordinance require that they should all be present? I would provide for the case of the absence of two; and would wish to see the ordinance in other respects made a little more definite.</p>
              <div className="speaker" id="sp4502"><span className="persName">Mr. HOLCOMBE</span>—</div>
              <p className="p-in-sp">
                In reference to the first resolution, it pursues the very form of words in the resolution of the Convention appointing the council. The application to us was, as to the construction of that resolution. We may offer a resolution to change the composition, and enlarge the functions of the council; but so far as the application of the council is concerned, what they want of us, and what
                this first resolution fully meets, is an interpretation of their authority by the council itself.
              </p>
              <p className="p-in-sp">The second resolution provides for keeping a record of the proceedings of the council. That is new matter, and I am willing to modify it in any way to meet the views of the Convention.</p>
              <div className="speaker" id="sp4503"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">I would suggest, that in the absence of the other members of the council, the Governor should consult one.</p>
              <div className="speaker" id="sp4504"><span className="persName">Mr. HOLCOMBE</span>—</div>
              <p className="p-in-sp">I am opposed to that on principle. I would be unwilling that the action of the council would be other than the action of the three members.</p>
              <p>The report of the Committee was adopted.</p>
              <div className="speaker" id="sp4505"><span className="persName">Mr. CHARLES R. SLAUGHTER</span>, of Campbell—</div>
              <p className="p-in-sp">
                I have a resolution of inquiry which I propose to offer. I understand the machinery seized at Harper's Ferry has been removed merely to Staunton. No actual preparation has been made to carry it to any particular place, and my object in offering the resolution is to make some provision for its destination. Upon inquiry of one of the Executive Committee this morning, I ascertained that
                perhaps the best course would be to bring <PageNumber num={405} /> it to Richmond. I offer the following resolution :
              </p>
              <p className="p-in-sp">"Resolved, That a committee of five be appointed to inquire what disposition should be made of the machinery taken from Harper's Ferry, so as to make the same serviceable as soon as possible."</p>
              <div className="speaker" id="sp4506"><span className="persName">Mr. HOLLADAY</span>, of Norfolk county—</div>
              <p className="p-in-sp">I would suggest that this inquiry in regard to the machinery at Harper's Ferry, be enlarged so as to embrace the machinery, &amp;c., in the Gosport Navy yard.</p>
              <div className="speaker" id="sp4507"><span className="persName">Mr. SLAUGHTER</span>—</div>
              <p className="p-in-sp">I have no objection to that.</p>
              <p>The resolution was amended in conformity with this suggestion, and then adopted.</p>
              <p>The President appointed the following committee under that resolution—Messrs. <span className="persName">C. R. SLAUGHTER</span> of Campbell, <span className="persName">JOHNSON</span> of Richmond, <span className="persName">CAPERTON </span>Of Monroe, BARBOUR of Culpeper, and SHEFFEY of Smyth.</p>
              <div className="speaker" id="sp4508"><span className="persName">Mr. FISHER</span>, of Northampton—</div>
              <p className="p-in-sp">I desire now to offer a short ordinance such as I referred to some time since; and I will briefly assign to the Convention the reason why they should adopt such an ordinance at this time.</p>
              <p className="p-in-sp">"Be it ordained, that the Governor of this Commonwealth take possession of the various Custom Houses in this State, and that he continue the collectors in the discharge of their duties, under such regulations as he may prescribe.</p>
              <p className="p-in-sp">"Be it further ordained, that the Governor assume control of the Postoffice Department and all other officers under the jurisdiction of the Federal Government heretofore, and that he retain the offices now engaged in these Departments subject to such regulations as he may for the present deem necessary "</p>
              <div className="speaker" id="sp4509"><span className="persName">Mr. FISHER</span>—</div>
              <p className="p-in-sp">I will state that I received this morning a letter from the collector of customs at Norfolk. It is a private communication; but inasmuch as it bears chiefly upon the subject now before the Convention, I will read some extracts from it.</p>
              <p className="p-in-sp">
                It is dated yesterday. The writer says: "I am not obeying the orders of the Federal Government, nor do I mean to do so. It is absolutely necessary that a collector should be here to act for the interests of Virginia. Would not the Convention direct me to act in the interregnum? I have refused to pay out money on the draft of the Secretary of the Treasury, &amp;c."
              </p>
              <div className="speaker" id="sp4510"><span className="persName">Mr. FISHER</span>—</div>
              <p className="p-in-sp">
                I mentioned the subject to Governor Letcher this morning, and he said, whilst he was willing to take any responsibility that was right and proper, yet he would greatly prefer that the Convention would pass an ordinance directing him to take possession of the Custom House, &amp;c. Unless the Convention will do so, it will be
                <PageNumber num={406} /> placing these public officers who are holding commissions under the Federal Government in an exceedingly unpleasant predicament. The Government sent money orders to the Custom House officer at Norfolk for payment; but they are protested because he is carrying out the feelings of the State; and yet up to this hour, his action is
                in no wise sanctioned by this body except inferentially.
              </p>
              <p className="p-in-sp">
                I don't think we ought to please gentlemen who have executed bonds to the Federal Government and who are bound to respond to every draft made upon them so long as they have funds in their possession. In this predicament we ought not to cast the responsibility which the Commonwealth should assume, upon individual citizens. I understand here are other officers in like predicaments with
                the Collector at Norfolk; and at the suggestion of gentlemen here, I drafted the 2d ordinance in order to meet the cases of other officers. I don't know the number of these officers who may be in the same predicament. There are Postmasters, and many others who, no doubt, will feel themselves placed in a very unpleasant dilemma when drafts are made upon them, unless the Convention
                will take the responsibility of freeing them from their obligations to the Federal Government, and conferring upon the Executive of the Commonwealth the control of these officers until the Convention shall otherwise order.
              </p>
              <p className="p-in-sp">I hope it will be the pleasure of the House to adopt these ordinances.</p>
              <div className="speaker" id="sp4511"><span className="persName">Mr. WM. B. PRESTON</span>—</div>
              <p className="p-in-sp">
                However proper the policy indicated by the gentleman may be, the most legitimate course is to raise a committee for the purpose of considering this question and reporting an ordinance in reference to it. I offered a resolution of that character three or four days ago. Every gentleman I spoke to upon the subject seemed to approve of the propriety of it then, and no doubt does now. I
                asked for a committee; but in consequence of other pressing matters, the resolution was never acted upon.
              </p>
              <div className="speaker" id="sp4512"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">With the permission of my friend, I would suggest that that, probably, had better be suspended, as, in all probability, it will be suspended by what may be the action of a committee that we contemplate appointing to confer with the President of the Confederate States.</p>
              <p className="p-in-sp">I therefore move, with his permission, that the ordinance be laid upon the table for the present.</p>
              <div className="speaker" id="sp4513"><span className="persName">Mr. FISHER</span>—</div>
              <p className="p-in-sp">
                I have been anxious to bring this subject before the Convention for a number of days. Indeed, I thought it was the duty of the Convention to have passed such an ordinance the moment they adopted the Ordinance of Secession, and now I will say to the <PageNumber num={407} /> Convention that they can have an opportunity of voting it down, if they think
                proper.
              </p>
              <p className="p-in-sp">
                I have no idea, sir, of consenting to the postponement of this subject for ten days or a fortnight, and it may be for twenty days, when the negotiations between this Commonwealth and the Confederate States may be completed. I have no idea, in the world, that we shall call upon individuals to act upon their own responsibility in matters involving, it may be, serious consequences to
                them, when this Convention is not prepared to shelter them under the panoply of the power of this Commonwealth. If the Convention chooses to cast upon gentlemen the responsibility of acts performed from motives of patriotism, and for the sole benefit of the Commonwealth, let them do so, and vote down the proposition. I will not cast upon my friends-and I have many of them holding
                Federal offices in this Commonwealth-a responsibility which the Commonwealth of Virginia ought to take. I will not ask of my friends to hold on to their offices in the character merely of locum tenens, for the benefit of the Commonwealth of Virginia. I will tell them that Virginia will not protect them, and that they had better resign their offices.
              </p>
              <p className="p-in-sp">
                Whenever any question is mooted here, I hear it stated by gentlemen who are unwilling to vote for it, but do not choose to make any direct opposition to it, that some important matter is to be considered and that it should have precedence over the subject introduced. Gentlemen who pursue this course seem entirely to forget the condition of these office-holders, who, when they take a
                position which is to benefit the Commonwealth, are abandoned by the State of Virginia to the tender mercies of the Federal Government.
              </p>
              <div className="speaker" id="sp4514"><span className="persName">Mr. GOGGIN</span>, of Bedford—</div>
              <p className="p-in-sp">I am very much disposed to co-operate with my friend from Northampton [<span className="persName">Mr. FISHER</span>] in carrying out the suggestions which are contained in the ordinance which he has proposed.</p>
              <div className="stage it">[The reading of the Ordinances was called for, and they were again read by the Secretary.]</div>
              <div className="speaker" id="sp4515"><span className="persName">Mr. GOGGIN</span>—</div>
              <p className="p-in-sp">
                I was going on to remark that I am very much disposed to concur in the views presented by the gentleman from Northampton, so far, at least, as relates to the Custom House Department. I will, however, say to the gentleman, that while I may not dissent from his first ordinance, it seems to me that the second ordinance goes much farther than any gentleman in this Convention would feel
                justified in going in the present state of affairs. The gentleman proposes not only that the Governor of the Commonwealth <PageNumber num={408} /> should take possession of all the Custom Houses and of all the other public property, but that he shall also assume control of a department of the General Government, which is indispensable to trade and the
                comfort and convenience of every citizen of the Commonwealth. The second ordinance empowers the Governor to take possession of all the post offices in the State of Virginia. Such an act would necessarily involve our exclusion from all the mail facilities which we now enjoy, without providing any adequate substitute therefor.
              </p>
              <p className="p-in-sp">I am, therefore, utterly opposed to any such policy.</p>
              <div className="speaker" id="sp4516"><span className="persName">Mr. FISHER</span>—</div>
              <p className="p-in-sp">The first ordinance is my own. The other has been added at the suggestion of some gentlemen, but if it is deemed objectionable, I have no particular desire for its retention.</p>
              <div className="speaker" id="sp4517"><span className="persName">Mr. GOGGIN</span>—</div>
              <p className="p-in-sp">I don't pretend to say who is the author of the one or the other. I merely bring the attention of gentlemen to the fact that what I represent would be the operation of the ordinance. I am opposed to the second ordinance, and if it be in order, I would move that it be referred to a committee of five to report thereon.</p>
              <div className="speaker" id="sp4518"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">
                The matter before us is one of very deep importance to the commercial portion of the community. It is especially important in this city. The fact is known to me that there are now cargoes in our river which are detained because of the uncertainty which prevails in respect to clearances. It is very important that a committee should be raised, and that that committee should pursue its
                inquiries diligently, with a view of ascertaining under what sanction the foreign commerce may be safely prosecuted. I would suggest to gentlemen, who may be on that committee, that they inquire of the foreign consuls in our city, under what clearances vessels may be seized at sea.
              </p>
              <p className="p-in-sp">
                I am in favor of directing that all the Custom House officers be duly qualified, and that they give security to account for the public money; but I trust it will not be the pleasure of the Convention to depart from these regulations which are found so important in the commercial relations of the country. If there must be a change, let it be only in respect to authority and not in
                respect to long established commercial regulations with which every commercial interest is perfectly familiar. It is a matter of infinite importance that nothing should be done to embarrass or hinder the foreign commerce of our State. We have far more to lose than to gain by its suspension; and I trust the Convention will not be called upon to pass upon this subject without full and
                minute information in respect to the operation of any change that may be instituted. We had much better lose <PageNumber num={409} /> the whole amount of imports that may be collected for the Government than to suspend the operations of our commerce for twenty-four hours. I know the commercial men now in this city are embarrassed by the uncertainty
                which prevails in regard to this subject. The committee which will be charged with it, will find itself engaged in a laborious and embarrassing inquiry; and it is therefore to be hoped that the Convention will refer the matter to a committee which will undertake the duty in the spirit which I have indicated.
              </p>
              <div className="speaker" id="sp4519"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair has received a communication from the Governor of the Commonwealth, which he begs leave to lay before the Convention.</p>
              <p>The following is the communication:</p>
              <div className="floatingText">
                <div className="floatingText_body">
                  <p>EXECUTIVE DEPARTMENT,</p>
                  <p>April 23rd, 1861.</p>
                  <p>Gentlemen of the Convention:</p>
                  <p>
                    A foreign vessel laden with molasses has arrived at this port, and I am at a loss to know what instructions to give to the collector in regard to the duties to be paid. I desire, therefore, to know the will of the Convention, and respectfully request that you will inform me whether duties are to be collected under the act of 1857 or the tariff of 1861, commonly called the Morrill
                    tariff.
                  </p>
                  <p>Respectfully,</p>
                  <p>JOHN LETCHER.</p>
                </div>
              </div>
              <div className="speaker" id="sp4520"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">I move that that communication be referred to the committee to which the ordinances of the gentleman from Northampton may be referred.</p>
              <div className="speaker" id="sp4521"><span className="persName">Mr. TIMOTHY RIVES</span>, of Prince George—</div>
              <p className="p-in-sp">It is essentially necessary that some regulation should be made upon this subject. We are bound to make our returns to the Secretary of the Treasury every month, and before the period for making the next month's return arrives, the Convention should take some action in this matter.</p>
              <p className="p-in-sp">
                I went over to Petersburg on Saturday last and brought with me all the money in the Custom House, which, on arriving here, I delivered over to Mr. Harrison, the collector for this port. If some action is not taken upon this subject at once, we will be compelled to hand the revenue over to the Secretary of the Treasury or subject our sureties to a heavy forfeiture. An ordinance ought
                to be passed authorizing <PageNumber num={410} /> the Governor to take possession of the Custom Houses and Post Offices, the duties to be collected, and the mails to go as usual. That was the course pursued in South Carolina. The same officers were continued that held the positions before the passage of the ordinance of secession; and an ordinance was
                passed to save the officers from any injury or inconvenience from a forfeiture of their bonds.
              </p>
              <p className="p-in-sp">I think if the question was referred to a committee an opportunity would be afforded to prepare such an ordinance as would cover the whole ground. I am, therefore, in favor of referring the whole subject to a committee.</p>
              <div className="speaker" id="sp4522"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">
                I think, upon a little consideration, that the motion made in respect to the last communication was, perhaps, a hasty one. As I understand from the Governor's communication a vessel is now in our port laden with molasses. If we take control of it, and the cargo should be lost by fire or other accident we should become answerable for its value. There is no doubt that the vessel is
                under an insurance which entitles her to discharge her cargo immediately upon her arrival. If we delay the discharge of the cargo we undertake the responsibility of the underwriters.
              </p>
              <p className="p-in-sp">
                I think, therefore, that in the actual case now before us the Governor should have no difficulty in determining that the duty is to be collected under the tariff which was in force on the day when our separation from the Union was declared. What is to become of the money hereafter will depend upon the future action of the Convention; but I pray that we shall not be hurried into the
                confusion and embarrassment of undertaking to give orders to take a responsibility as to the propriety of which we may have serious doubts when these orders are given. It is very plain that the vessel should be permitted to land her cargo.
              </p>
              <p className="p-in-sp">
                The payment of duty in cash is not demanded. The bonded system is still in force, and I apprehend that under that system the amount of duty may be ascertained hereafter. Let us not incur the reproach of giving orders in commercial matters with which we are not familiar, and to avoid any improper action in this matter, I would direct in respect to the communication of the Governor
                that he would permit the cargo to be landed, and the duty to be regulated by the rules I referred to.
              </p>
              <div className="speaker" id="sp4523"><span className="persName">Mr. PRESTON</span>—</div>
              <p className="p-in-sp">Send it to a committee.</p>
              <div className="speaker" id="sp4524"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">That process would be too slow, for in the meantime we should have to take control of the cargo.</p>
              <p className="p-in-sp">So far as the proposition of the gentleman from Northampton [Mr. <PageNumber num={411} /> FISHER] , is concerned, I concur in it, provided he is satisfied that it be referred to a committee. But while that is referred, I do not think it should carry with it the case brought to our attention by the last communication from the Governor.</p>
              <div className="speaker" id="sp4525"><span className="persName">Mr. FISHER</span>—</div>
              <p className="p-in-sp">I think the gentleman from Richmond [<span className="persName">Mr. MACFARLAND</span>]
                , is too learned a lawyer to suppose that the question of insurance can possibly arise under the first section of that ordinance. It is wholly impossible that it can. This first section or ordinance, which is my own, only authorizes the Governor to do what he has, in fact, already done in regard to the Richmond Custom House. Governor Letcher has taken possession of that
                establishment, presuming, no doubt, that this Convention would sustain him in his action, and, I understand, that an appointee of Abraham Lincoln had to be removed at the point of bayonet from that custom house this morning or yesterday, so that he might justify himself to his master. On receiving this morning, from the collector of customs at Norfolk, the communication which I have
                read, I called upon Gov. Letcher and asked him to take the same responsibility in regard to the custom house at Norfolk that he did in regard to the custom house at Richmond; but he was unwilling to do it without authority from this body.
              </p>
              <p className="p-in-sp">Sir, that ordinance does not disturb the relations between Virginia and any foreign power. It does not look to the establishment of any tariff. It does not look to the question whether goods are to be warehoused under the tariff of 1846 or '57 or the Morrill tariff. I admit that the argument of the gentleman from Richmond [<span className="persName">Mr. MACFARLAND</span>] , is perfectly conclusive as to the propriety of referring that question to a committee. It ought not to be passed upon without mature deliberation.</p>
              <p className="p-in-sp">
                I have not enjoyed the honor of being upon any committee since the assembling of this Convention, and my modesty should, in this instance, have suggested to me the propriety of declining to act upon the committee which it is now proposed to raise. But whether I am on that committee or not, I have, I trust, sufficient courage to present every ordinance which I should think necessary,
                regardless what may be thought of my action elsewhere. If it be the pleasure of the Convention to vote down this proposition, why, sir, they have shoulders broad enough to bear the consequences that must necessarily result from this action. If the Convention refuses to adopt their proposition, I shall deem it my duty to write to a dozen or so of near and dear friends of mine, who
                hold Federal offices in different parts of the <PageNumber num={412} /> Commonwealth, as I shall to the gentleman whose letter I read in part to the Convention this morning, to take no responsibility on themselves. I cannot advise friends to take a responsibility in the interests of the State from which a Convention of the sovereignty of Virginia will
                shrink. I cannot ask a man who has given a bond for $90,000, and who is liable to a forfeiture of that amount for failing to make his report, to a responsibility which the Convention will not take themselves, and from the consequences of which they refuse to protect him. Governor Letcher told me this morning you could pass an ordinance in five minutes that would settle the whole
                matter. I told him I would bring it before this body, and he requested me to ask the collector to hold on for awhile-until the Convention would take some action.
              </p>
              <p className="p-in-sp">This ordinance simply authorizes the Governor to take possession of the Custom House until the Convention shall otherwise order.</p>
              <p className="p-in-sp">The second ordinance, I admit, goes farther. As I have said, that was added at the suggestion of some friends; but, inasmuch as some objection is made to it, I will ask for a division of the question. Upon further reflection, I confess that there ought to be a committee appointed to take into consideration the suggestions of the gentleman from Richmond [<span className="persName">Mr. MACFARLAND</span>] as to the course which ought to be pursued in the collection of the public revenue.</p>
              <div className="speaker" id="sp4526"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">I will appeal to the gentleman to consent to have both his resolutions sent to a committee. There will be no loss of time by adopting that course, and the Convention will act with far better information than it has now, and we shall incur less hazard of a blunder.</p>
              <p className="p-in-sp">I offer the following resolution:</p>
              <p className="p-in-sp">"Resolved, That a committee of five be appointed to bring in an ordinance regulating the duties of post masters and officers in the revenue service of the United States."</p>
              <div className="speaker" id="sp4527"><span className="persName">Mr. TURNER</span>, of Jackson—</div>
              <p className="p-in-sp">I am opposed to any interference with the question of post-offices in this State.</p>
              <div className="speaker" id="sp4528"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">Let the subject go before a committee, and they can do what they deem best for the interest of the State in that particular.</p>
              <div className="speaker" id="sp4529"><span className="persName">Mr. WILSON</span>—</div>
              <p className="p-in-sp">I offered a resolution, a few days ago, on the subject of post-offices which I would ask to have referred to the same committee.</p>
              <div className="speaker" id="sp4530"><span className="persName">Mr. AMBLER</span>, of Louisa—</div>
              <p className="p-in-sp">There was a resolution offered a few days ago by the gentleman from Fauquier [<span className="persName">Mr. R. E. SCOTT</span>]
                , under which a <PageNumber num={413} /> committee was raised to inquire into the expediency of suspending the authority of the General Government in this State, until the people should vote upon the Ordinance of Secession. It was contemplated, as I understand, that all subjects of this character should be referred to that committee. If we go on
                multiplying committees we shall get ourselves into a condition of embarrassment from which we shall find it difficult to extricate ourselves.
              </p>
              <p className="p-in-sp">The following is the resolution referred to:</p>
              <p className="p-in-sp">"Resolved, That a committee of five be appointed to inquire into the expediency of suspending the authority of the Federal Government, within the limits of this Commonwealth, until the sense of the people of this State shall be taken upon the ratification of the ordinance already adopted."</p>
              <p className="p-in-sp">It strikes me that you have a committee already appointed whose duty it is to take under consideration this very question, and I trust the Convention will refuse to raise any other committee for the proposed object, than that now existing under the resolution which has just been read.</p>
              <div className="speaker" id="sp4531"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">I understand that that committee have already made a report.</p>
              <div className="speaker" id="sp4532"><span className="persName">Mr. AMBLER</span>—</div>
              <p className="p-in-sp">True, they have made a report; but they are in a condition to consider this subject. If you go on appointing special committees, embarrassments must inevitably follow.</p>
              <p className="p-in-sp">I move to refer the subject to the committee appointed under <span className="persName">Mr. SCOTT</span>'S resolution.</p>
              <div className="speaker" id="sp4533"><span className="persName">Mr. HAYMOND</span>, of Marion—</div>
              <p className="p-in-sp">I rise to what has been considered a question of privilege in this Convention; and I ask the indulgence of the Convention for a few moments. The reason why I now undertake to interrupt the proceedings and ask this indulgence is, that the military committee is in session, and being a member of it, it is important that I should be present at its deliberation.</p>
              <p className="p-in-sp">In accordance with a practice observed here recently, I rise to ask leave of the Convention to allow me to change my vote upon the ordinance of secession, and to state my reason for so doing.</p>
              <p className="p-in-sp">
                It will be remembered that throughout the session of this Convention, as I apprehend all the members can testify who are aware of my action and the motives which dictated that action, I was in favor of bringing about a restoration of our union, and peace and harmony to the country; that I was willing to co-operate in any and all measures that would in all reasonable probability,
                bring about that result. I was opposed to the secession of the State without the <PageNumber num={414} /> co-operation of the State of Maryland, because of the peculiar position of my section, which has no outlet to any part of the country except through the State of Maryland. As the representative of that section in part, I felt that to be my true
                position.
              </p>
              <p className="p-in-sp">
                But, sir, a large majority of this Convention have differed in opinion from me. They voted down a proposition for co-operation, and adopted an ordinance of secession. Since that time I have obtained some information which I was not before in possession of, and that is, that since the issue of his proclamation by Lincoln a large portion of the people of my county, if not a majority, a
                large majority of those at least who supported me at the polls, are in favor of secession. I, therefore, feel it to be my duty as a Virginian, for my lot is cast with Virginia-I was born among the proud hills of North-western Virginia, and educated in your own Eastern Virginia, and I have no other feelings and am animated by no other impulses than those of a true Virginian; and so
                help me God, my destiny shall be that of Virginia, whether it be one of prosperity or poverty. I say, in view of the position recently assumed by my constituents, upon which I sincerely congratulate them and myself, I feel it my duty to cast my vote for the ordinance of secession. I feel in my heart and before my God, that I have done my duty to my State and to my constituents. I
                have exercised all the influence and whatever of ability I possessed, to avert the dread calamity which now hangs over us. I have endeavored as best I could to avert a dissolution of the Union and to bring about peace and harmony. But in all this I have failed; as well as those who co-operated with me. We have failed not because of any action of ours; but because of the corruption
                and usurpation of the Administration now in power at Washington City. The action of the President of the United States has been such as to excite universal indignation among the people of Virginia. There have been no people upon Virginia's broad domain who have been more ardently attached to the Union than those in the Northwestern counties of Virginia. They have prospered under it,
                and they have been deeply attached to it. But the action of the present Administration has produced an entire change, and many of them who were before strong Union men are now in favor of secession. In view of these facts, sir, I am compelled, in pursuance of my duty to my constituents, and as the friend of national and constitutional liberty, with the consent of the Convention to
                cast my vote for the ordinance of secession.
              </p>
              <p>Leave was unanimously given to change the vote as desired.</p>
              <PageNumber num={415} />
              <p>The consideration of the ordinances offered by FISHER, having been resumed,</p>
              <div className="speaker" id="sp4534"><span className="persName">Mr. TURNER</span>, of Jackson, said—</div>
              <p className="p-in-sp">I desire to submit a substitute for the ordinances offered by the gentleman from Richmond [<span className="persName">Mr. MACFARLAND</span>] , which I ask to go to the committee.</p>
              <div className="speaker" id="sp4535"><span className="persName">Mr. AMBLER</span>—</div>
              <p className="p-in-sp">I understand the substitute of the gentleman designates as the committee to which it is to be referred, the committee already appointed under the resolution of the gentleman from Fauquier [<span className="persName">Mr. SCOTT</span>] . If such is the case, I shall be perfectly willing to have it referred.</p>
              <div className="speaker" id="sp4536"><span className="persName">Mr. TURNER</span>—</div>
              <p className="p-in-sp">The following is the substitute which I propose to offer:</p>
              <p className="p-in-sp">"Resolved, that the committee of five heretofore appointed on the subject of suspending the Federal authority in the State, be directed to inquire into and report what steps, if any, are necessary to be taken by this Convention to protect the commerce of the State."</p>
              <div className="speaker" id="sp4537"><span className="persName">Mr. BRANCH</span>—</div>
              <p className="p-in-sp">I call the previous question.</p>
              <p>The call was sustained and the main question being ordered to be put, the vote was accordingly taken on the substitute just offered by <span className="persName">Mr. TURNER</span>, and was decided in the negative.</p>
              <p>The question then recurred on the resolution offered by <span className="persName">Mr. MACFARLAND</span> appointing a committee of five to whom the ordinances offered by <span className="persName">Mr. FISHER</span> should be referred, and it was adopted.</p>
              <p>The President appointed the following committee under that resolution-Messrs. Miers W. Fisher of Northampton, Wm. H. Macfarland of Richmond, Thomas Branch of Petersburg, Wm. M. Ambler of Louisa and Wood Bouldin of Charlotte.</p>
              <div className="speaker" id="sp4538"><span className="persName">Mr. MORTON</span>, of Orange and Greene—</div>
              <p className="p-in-sp">I wish to call to the attention of the Convention a subject which I supposed would have been the first acted upon in the evening session.</p>
              <p className="p-in-sp">I propose the following resolution :</p>
              <p className="p-in-sp">"Resolved, That a committee of five be appointed, by this Convention, to confer with the Commissioner of the Confederate States, and report their action, subject to the approval of this Convention."</p>
              <div className="speaker" id="sp4539"><span className="persName">Mr. HALL</span>, of Wetzel—</div>
              <p className="p-in-sp">I rise to inquire whether a committee on the election of members of Congress, ordered to be raised under the resolution of <span className="persName">Mr. BLAKEY</span>, has been appointed.</p>
              <p>The Secretary replied that the committee was appointed, and that <span className="persName">Mr. BLAKEY</span> was Chairman of it.</p>
              <div className="speaker" id="sp4540"><span className="persName">Mr. GEO. W. BERLIN</span>, of Upshur—</div>
              <p className="p-in-sp">I rise for the purpose of making a personal explanation.</p>
              <PageNumber num={416} />
              <p className="p-in-sp">
                It is known to all here that I voted against the Ordinance of Secession, and I did so at the time for reasons which were entirely satisfactory to myself, and prove a strong sense of duty to those whom I represent. My constituents, sir, were strong for Union when I last heard from them. I was elected as a Union delegate. My people were unanimous upon that subject, and I came here and
                thus far faithfully represented their views and wishes. They were devotedly attached to the Union for many reasons which it is not now necessary for me to state. I can say that I most heartily endorse and adopt what was so well said by the gentleman from Marion
                [<span className="persName">Mr. HAYMOND</span>]
                . My people, like his, were truly attached to the Union : not only because of the benefits which they derived from it; not only in consequence of the perilous situation which they occupy, and the relationship that exists between that region of country and the border free States; but from higher and nobler considerations than any of these. They were attached to it because of the power
                and prestige which that Union has given us among the nations of the earth.
              </p>
              <p className="p-in-sp">
                I will here state a fact which I know exercised considerable influence among my people. As early as 1856, there were 183,000 native Virginians in the border free State counties, and the ties of consanguinity which this fact shows to exist operated necessarily to produce a strong Union feeling among the people along the border counties in Virginia. But, sir, I have done my duty; and
                whilst I have been silent, my votes have shown, and now show, how I have stood. All our efforts for an adjustment of the difficulties that beset us, have proved fruitless, because of the course of the corrupt powers at Washington. All hopes of re-constructing the Union are now at an end. It is no longer reasonable even to conceive such an idea. Every hope of such a result is gone.
                This is not a time for Virginians to be divided; and lest my position and the position of the people of my section may be misunderstood; lest our position might exert a deleterious influence upon the people of the East, by being supposed to be that of hostility to this movement; and anxious to promote that unanimity so essential to the success of the cause in which Virginia is now
                engaged, while I have not heard a word from my constituents for a month, yet, assured that they will sustain me in view of the events that have transpired recently, I shall now ask leave of the Convention to change my vote on the ordinance of secession, from the negative to the affirmative.
              </p>
              <p className="p-in-sp">
                I have contemplated this change for days. I have been waiting only for one thing to be done, and that would have been done if gentlemen <PageNumber num={417} /> only understood the salutary influence it would exercise among the people of my section. I refer to the tax question. You all know that this has been a thorn in the side of our people. I will
                hazard the opinion that some gentlemen in that far distant border would be very well pleased if this Convention would take no action in this matter, but allow it to remain as it is, and thus afford these gentlemen an opportunity to create mischief.
              </p>
              <p className="p-in-sp">
                But in order to obviate such difficulties and defeat the infamous plans of that class of mischief makers, I had hoped that the Convention would see the importance of removing this source of difficulty, and doing that which would lead to conciliation and harmony among our people at this time. If they would pass this tax ordinance we could go home to our constituents and say we had
                done our duty, and having been defeated in our efforts at peace by the machinations of the powers at Washington, we changed our policy and went in for secession. Our Eastern brethren found it their duty to pursue this line of policy, and we aided them in it. But they have done you justice by adopting the ad valorem principle of taxation in reference to the negro property and we have
                no cause now for any misunderstanding. They would hail joyfully this change, and unite heartily with the East.
              </p>
              <p>The question on granting <span className="persName">Mr. BERLIN</span> leave to change his vote, was put, and decided unanimously in the affirmative.</p>
              <div className="speaker" id="sp4541"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">I would be very glad to be interrupted again and again under circumstances like this, and to be as much gratified in every instance as I have been by the course of the two gentlemen who have last addressed us.</p>
              <p className="p-in-sp">I now offer my resolution, which I will again read.</p>
              <p className="p-in-sp">"Resolved, That a committee of five be appointed by this Convention to confer with the commissioner of the Confederate States, and report their action, subject to the approval of this Convention."</p>
              <div className="speaker" id="sp4542"><span className="persName">Mr. CRITCHER</span>—</div>
              <p className="p-in-sp">On what subject does the gentleman propose to confer?</p>
              <div className="speaker" id="sp4543"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">In regard to the object of his mission.</p>
              <div className="speaker" id="sp4544"><span className="persName">Mr. CRITCHER</span>—</div>
              <p className="p-in-sp">There are two classes of subjects upon which we are to treat-one of a military and one of a civic character. It seems to me that there should be a committee to confer with him on the subject of military matters, and a committee to confer with him on the subject of an alliance.</p>
              <p className="p-in-sp">I would offer the following as an amendment:</p>
              <p className="p-in-sp">"Resolved, That the committee on military affairs be instructed to <PageNumber num={418} /> confer with the Hon. Alexander H. Stephens, Commissioner from the Confederate States, and report to us such measures of military cooperation as they may deem most expedient for the present."</p>
              <div className="speaker" id="sp4545"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">
                I trust it will not be the pleasure of the Convention to adopt the amendment. The military committee has enough to do already. It is a committee not constituted as we wish this committee to be constituted. The committee which I propose to raise is a committee to consider all the subjects which were embraced in the address of the commissioner from the Confederate States. We do not
                propose to limit them; but to give them a carte blanche, subject, however, to the approval of this Convention. If we were to give in detail in this Convention the subjects to be treated upon, there might be division; but when, after a full conference on the part of a committee of select men representing this body and chosen from different sections of the State, with the Confederate
                States commissioner, a report is sent in, we shall have the whole subject laid before us, and then we can either adopt or reject it in whole or in part as we may think proper.
              </p>
              <p className="p-in-sp">I will state that in submitting this resolution I have no personal motive whatever. I am animated in taking this course, alone by a strict regard to the honor and the interests of this Commonwealth. I will indicate the gentlemen whom I trust it will be the pleasure of the Convention to appoint as a committee in behalf of this to-day.</p>
              <div className="speaker" id="sp4546"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">I trust that the gentleman will make no such indication until I have an opportunity of offering an amendment, which is as follows:</p>
              <p className="p-in-sp">"Resolved, That a committee of five be appointed by this Convention to inquire into and report what connection, if any, should be formed by the State of Virginia with the government of the Confederate States prior to the time at which the ordinance of secession shall be submitted to a vote of the people for ratification or rejection."</p>
              <div className="speaker" id="sp4547"><span className="persName">Mr. GOODE</span>, of Bedford—</div>
              <p className="p-in-sp">It seems to me that the resolution ought to provide that the committee should confer with the commissioner.</p>
              <div className="speaker" id="sp4548"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">
                This Convention cannot yet decide as to what extent they will go in that direction or whether they will move in that direction at all. It is necessary in my view-it is respectful in my view to this commissioner, that this Convention should have some opinion upon the subject before a committee is appointed to confer with him. I therefore offer this resolution in order to ascertain to
                what extent this Convention intends to go and having determined that, we should <PageNumber num={419} /> follow up this action by the appointment of a committee to confer with the commissioner from the Confederate States.
              </p>
              <p className="p-in-sp">We have done nothing for the past three days of any importance, and it seems still as if we were disposed to do nothing except divide upon trifling questions that do not appertain to the general subject which should command our attention. We must have subjects introduced here of the character of that presented by my friend from Northampton [<span className="persName">Mr. FISHER</span>] because we have no fixed line of policy marked out for us.</p>
              <p className="p-in-sp">I would, therefore, like to have a committee appointed to ascertain the sense of this body as to the proper question to be treated upon, and having done so, then to act upon them and go home.</p>
              <div className="speaker" id="sp4549"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">I am perfectly satisfied that my friend from Amelia and myself fully concur in the object in view. Between the wisdom of the course which he indicated, and the wisdom of the course which I indicated, it is for this Convention to decide. Now, I ask my friend if he was here to-day when-</p>
              <div className="speaker" id="sp4550"><span className="persName">Mr. JOHNSON</span>, of Richmond city—</div>
              <p className="p-in-sp">Will the gentleman allow me to interrupt him?</p>
              <div className="speaker" id="sp4551"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">Certainly, sir.</p>
              <div className="speaker" id="sp4552"><span className="persName">Mr. JOHNSON</span>—</div>
              <p className="p-in-sp">The gentleman says he is perfectly aware that his friend from Amelia and himself know the object they have each in view. I suppose the gentleman can have no objection to stating, for the information of the Convention, what that object is.</p>
              <div className="speaker" id="sp4553"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">I am sorry the gentleman found it necessary to propound such an inquiry as that before I finished my remarks. I will respond, however, to that gentleman before I conclude.</p>
              <p className="p-in-sp">I would ask my friend from Amelia [<span className="persName">Mr. HARVIE</span>]
                what was the impression that was made to-day upon his mind by the learned and eloquent address of the commissioner from the Confederate States? Did it not leave a profound impression upon every member of this body? And, notwithstanding all this, he failed, owing to the limited time which he allotted to himself, and the feeble state of his health, to give in detail the various
                subjects in respect to which the conference has been proposed.
              </p>
              <p className="p-in-sp">It is unquestionable that if the policy of the gentleman from Amelia [<span className="persName">Mr. HARVIE</span>] be carried out, we shall have premature discussions upon questions which the report of the committee will, doubtless, embrace.</p>
              <p className="p-in-sp">I stated that my friend from Amelia and myself looked to the same common object, and the gentleman from Richmond [<span className="persName">Mr. JOHNSON</span>]
                inquires what that object is. It is to pursue such a policy as will, in the <PageNumber num={420} /> shortest possible time, place Virginia in such a position, financially, and in a military point of view, as that she stand up boldly and meet the hosts which are polluting her soil. How is that to be done? As the humblest member in this body, I will say
                that I have no disguises. I say our policy, nay, our duty to our constituents and the Commonwealth is at once to become a member of the Confederate States, subject to the approval of our constituents-subject to the ratification of this body. That is the object that I have in view, and I trust the gentleman from Richmond
                [<span className="persName">Mr. JOHNSON</span>]
                has reached that point under the pressure of the difficulties that now surround us -when our Commonwealth is a camp; when probably in three days there will be fifty thousand Northern troops upon our border, and when the Confederate States are offering us all their military and financial resources, and making the battle a common one against a common enemy. Is the gentleman answered?
                If he is not, I will be still more explicit. I trust, however, he is answered. I trust in this hour and this crisis, that the indications we have had from the NorthWest-that portion of the Commonwealth which was more doubted than any other, but which in the hour of trial will be found as true to the honor and interests of this Commonwealth as any other portion. I say, I trust that
                the indications we have had recently from that section are but the forerunners of a thorough and complete revolution in the public sentiment of the people in that region. As an Eastern man, upon whom probably a modification of the taxes, in the manner proposed here, would operate more heavily than any member of the Convention, in proportion to the number of slaves that he holds; as
                an Eastern man, I will respond to the demand of the gentlemen from the North-West. I am willing to give them what they ask, in order that the weapon may be knocked from the hands of any enemies we may have in the West, and in order that we may be fully united against a common enemy.
              </p>
              <div className="speaker" id="sp4554"><span className="persName">Mr. JOHN GOODE, JR.</span>, of Bedford—</div>
              <p className="p-in-sp">
                We are all agreed that it is all important that some union and alliance should be formed between this State and the Confederate States. I apprehend there is no division of sentiment upon that subject. Gentlemen may differ as to the best mode of forming that alliance. I have not yet heard any resolution offered upon this subject, which, it seems to me, meets the question precisely as
                it ought to be presented by the committee appointed by this Convention to wait on the commissioner from the Confederate States.
              </p>
              <p className="p-in-sp">I have drawn up a resolution which I mean to offer in case the <PageNumber num={421} /> amendment to the amendment of the gentleman from Amelia shall be voted down. I will read it by way of information:</p>
              <p className="p-in-sp">"Resolved, That a committee of five be appointed to confer with the Hon. Alexander H. Stephens, Commissioner from the Confederate States, and to arrange with him the terms of a union or alliance between this State and the said Confederate States, subject to the ratification or rejection of this Convention."</p>
              <div className="speaker" id="sp4555"><span className="persName">Mr. BOULDIN</span>—</div>
              <p className="p-in-sp">
                I merely desire to say that I am in favor of the views presented by the gentleman who has just taken his seat. I think it is essential that a committee should at once be appointed to confer with the commissioner from the Confederate States. He has been here since yesterday morning with no person authorized to treat with him. He has asked us to-day to give him somebody, as soon as
                possible, with whom he would treat; and I trust that a committee will be appointed at once with a view to treat upon the subject of an alliance with the Confederate States.
              </p>
              <div className="speaker" id="sp4556"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">I accept the proposition of the gentleman from Bedford [Mr. GoonE] in lieu of my amendment.</p>
              <div className="speaker" id="sp4557"><span className="persName">Mr. HALL</span>, of Marion—</div>
              <p className="p-in-sp">It is known that I am one of those who did not co-operate with the majority in bringing our State to her present condition. A question has arisen, however, in the discussion of this matter which compels me to make a remark or two in reference to it.</p>
              <p className="p-in-sp">
                The proposition, if I understand it aright, contemplates the raising of a committee with a view to confer with the Commissioner from the Confederate States, first with reference to a military connection in response to our invitation to the authorities at Montgomery; and, secondly, with reference to an alliance of a civil character-an alliance offensive and defensive, as proposed by
                this Convention in its resolution inviting the commissioner on. I hope, sir, that the suggestion made that we ought to be a unit, in order to carry out, successfully, the contest in which we are engaged, will not be confounded with the question of secession; but it may be that those who voted against the ordinance would be willing to aid in this contest. I have heard here remarks
                made with reference to that fearful nook of creation from which I hail. I am not here to defend that section. I am told that all points will now be armed. I hope my people are not to be intimidated. Whenever the proposition as to the ratification or rejection of the ordinance comes before them, they will vote upon it independent of any influence from any quarter. We are here
                representing a people numbering fifty thousand, and it must be borne in mind that anything you do in respect to a change in our relations <PageNumber num={422} /> with the Federal Government or any change you make in our organic law will have to be submitted to them as an element in the body politic of our State before it becomes final. And now what do
                you propose to do? You propose to enter into an alliance, taking it for granted as though it was passed upon by the people, that the ordinance of secession is finally adopted. I care not how foregone may be the conclusion in this respect, I would regard the very appearance of the thing as an insult to the people who have seen fit to restrict you in what you should do. I ask now what
                is the necessity of rushing head-long into a Southern Confederacy or any other confederacy? You don't know to-day what to-morrow may make necessary. Why this haste, therefore?
              </p>
              <p className="p-in-sp">
                And now I will refer to another matter. It is said, and I hope that that is the honest sentiment of those who gave utterance to the expression, that it is desirable we should be united. I hope we will. I hope there will be but one sentiment with reference to that declaration. But if you want to produce discord, you have only to say to my people, you have only to do what is now
                proposed to be done, before they are afforded an opportunity of pronouncing upon the ordinance of secession at the polls as the law organizing this Convention said should be done.
              </p>
              <p className="p-in-sp">
                If I had never seen a man from that region, I would judge that this policy, this kind of helter skelter, would produce division. I wish to say this: I have as yet been unable to see what benefit this alliance can yield to us. I concede that the ratification of the ordinance of secession is a fixed fact; but until that is accomplished, we have no authority to conclude any alliance. To
                take the course proposed by gentlemen here is not in my opinion treating with proper respect those whom we represent.
              </p>
              <p className="p-in-sp">I hope therefore it will be the pleasure of this Convention, if an alliance is formed, to confine its purpose to that of common defence, and not go beyond that.</p>
              <div className="speaker" id="sp4558"><span className="persName">Mr. MOORE</span>, of Rockbridge—</div>
              <p className="p-in-sp">
                It is known, sir, that no member of this Convention has been more adverse and unwilling to form any sort of connection with these Confederate States than myself. But, I was deeply impressed by the speech which I heard this morning, with the absolute necessity of prompt and decided action. We are now in a situation which makes it necessary for us to form an alliance with the
                Confederate States. This is our only hope; it is indispensable, in fact, to our political existence and independence.
              </p>
              <p className="p-in-sp">I am not now disposed to enter into a permanent alliance with <PageNumber num={423} /> these States; but, it seems to me, to be indispensably necessary that we should form some sort of alliance with them, not in reference alone to military affairs, but in reference to what, it seems to me, overrides military affairs.</p>
              <p className="p-in-sp">
                Our financial condition is such that we cannot raise the means of supporting our own soldiers. By forming a temporary alliance with these States we would have it in our power to raise the funds necessary to carry on the war in which we are now certain to be engaged. I am for entering into this alliance because, I believe it to be necessary. The people may condemn the act, but, I
                believe in voting for this alliance, I vote for that which is right, and the people may say what they please about it.
              </p>
              <div className="speaker" id="sp4559"><span className="persName">Mr. PRESTON</span>, of Montgomery—</div>
              <p className="p-in-sp">No question that has been presented here, so far, not even the ordinance of secession itself, is more momentous or more important than the resolution now before us. Few men can form an idea of the delicacy and difficulty of the duty which the committee, to be raised, will have imposed upon them.</p>
              <p className="p-in-sp">When the resolution of the gentleman from Orange [<span className="persName">Mr. MORTON</span>] , was offered, I did not concur in it. When the proposition was offered by the gentleman from Amelia [<span className="persName">Mr. HARVIE</span>]
                , I thought it a proper proposition, and upon this view, we published on the 18th of April, and had telegraphed to the authorities of the Confederate States, the fact that we had seceded, and had also communicated to them our desire to form an alliance, offensive and defensive with these States. Under that invitation, they have sent a Commissioner here for the purpose of entering
                into negotiations to that end. And Mr. Stephens is here to-day prepared to confer with any committee we may appoint to meet him.
              </p>
              <div className="speaker" id="sp4560"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">Mr. Stephens said in his speech to day that he hoped the alliance would go beyond the points indicated by the gentleman.</p>
              <div className="speaker" id="sp4561"><span className="persName">Mr. PRESTON</span>—</div>
              <p className="p-in-sp">I know that. That is the extent to which we have gone in extending the invitation. Mr. Stephens indicated a desire to go further. The gentleman from Orange [<span className="persName">Mr. MORTON</span>] catches at this, and he too expresses a desire to go further. The Convention, however, has not indicated its purpose to go further, and it was, therefore, that I regarded the suggestion of the gentleman from Amelia [<span className="persName">Mr. HARVIE</span>] as eminently wise and proper.</p>
              <p className="p-in-sp">I go for this last resolution, however. We are in an exigency. We are in revolution; and I am prepared to follow it up by every means in our power. But I desire to do so with deliberation and calmness.</p>
              <p className="p-in-sp">
                A question arises now which is one of great delicacy. All who <PageNumber num={424} /> listened to Mr. Stephens say that he approved of our course in appointing a commander-in-chief of the military and naval forces of Virginia. That officer is now in the discharge of that function. We also know that there will be regiments of South Carolina troops here
                under another commander. There will arise at once the question of rank, and this will have to be decided in the proposed conference. I think the proper course would be to appoint a committee of your discreet gentlemen who shall go out and look at this question in its length and breadth, and then go to Mr. Stephens with a proposition which this House shall sanction. This is a course
                which I don't think is at all embarrassing. I don't consider that the question of finance is paramount to all others at this time. It is on the military question that action should first be had, for it is essential that we should have at command for immediate service a strong military force.
              </p>
              <p className="p-in-sp">I will vote for either proposition. I simply want to bring this question of an alliance into such a position and place it upon such a basis as would enable the commissioner to act without consulting with his government.</p>
              <p className="p-in-sp">Let us defer this question till to-morrow morning, and take it up, say at 11 o'clock, and then appoint this commission with instructions to treat with Mr. Stephens in reference to whatever he has come empowered or instructed to treat upon.</p>
              <p className="p-in-sp">If these views meet the views of gentlemen, I will move to lay the whole subject on the table until to-morrow morning so as to ascertain the basis of this movement, and secure entire concert of action.</p>
              <div className="speaker" id="sp4562"><span className="persName">Mr. T. S. FLOURNOY</span>, of Halifax—</div>
              <p className="p-in-sp">I am sorry to find myself differing with my friend from Montgomery.</p>
              <p className="p-in-sp">
                We heard in the impressive speech made by the Commissioner this morning, that our telegraphic dispatch announcing to the President of the Confederate States the desire of Virginia that an alliance offensive and defensive should be formed, arrived there at 12 o'clock, and that he himself was in the cars at 6 o'clock on his way here to Virginia. He said, that he traveled night and day
                and made the trip from Montgomery to this city in 62 hours; and though coming in this precipitate haste, he is now two days in this city, and we have not as yet appointed a commissioner to confer with hm.
              </p>
              <div className="speaker" id="sp4563"><span className="persName">Mr. PRESTON</span>—</div>
              <p className="p-in-sp">I will state, that I am for appointing a committee</p>
              <p className="p-in-sp">now, but I would wait until to-morrow before giving them instructions.</p>
              <div className="speaker" id="sp4564"><span className="persName">Mr. FLOURNOY</span>—</div>
              <p className="p-in-sp">
                I do not see the necessity of delaying this committee. Instead of having to debate this question here, and decide also, take five of your most discreet men, and let them confer with <PageNumber num={425} /> Mr. Stephens on the subjects involved in the proposed negotiation. We can act upon the question when they report. I am satisfied that this is the
                wisest course to pursue, and that when the committee give in their report, it will be entirely satisfactory to this body, and will be promptly acted upon by them. If perchance, however, the Convention would not concur in that report, it could be amended, and no doubt we could be induced to remain here until the whole matter was perfected.
              </p>
              <p className="p-in-sp">War, sir, is now at your door. Our borders are assailed, and despatch is necessary. Minutes are now hours. Let us act at once and appoint this committee, and let them confer with Mr. Stephens as soon as possible, and if any debate is to ensue, let it come when the report is made.</p>
              <p className="p-in-sp">Sir, he set us an example of despatch which is worthy of imitation. In six hours after your despatch was received at Montgomery, the Commissioner was on his way to this city. That gentleman has said that he was ready to treat with us immediately, and I therefore think the proper course is to appoint a committee, and defer all debate until they report.</p>
              <div className="speaker" id="sp4565"><span className="persName">Mr. HOLCOMBE</span>—</div>
              <p className="p-in-sp">
                I understand there is only one difficulty between gentlemen who have expressed their opinions upon this question. It seems to be considered, and I fully concur in that view of the subject, that considerations of courtesy and propriety require that the Vice President of the Confederate States, who has been brought here upon our own summons, should be put in contact promptly with
                representations from this body. I am perfectly satisfied as to the propriety of doing that at once. To that extent the proposition of my friend from Orange
                [<span className="persName">Mr. MORTON</span>] goes.</p>
              <p className="p-in-sp">But I am also in favor of doing more. My friend from Montgomery 1 [<span className="persName">Mr. PRESTON</span>]
                has said, and I agree in the views which he takes, that the commission appointed by this body should not undertake to arrange with <span className="persName">Mr. STEPHENS</span> to-night, and that before they confer with him they should know from the Convention the terms upon which this Commonwealth should be associated with the Confederate States. It seems to me that the views of
                gentlemen on both sides could be substituted by the appointment of a committee which should confer with the Vice President of the Confederate States upon these important subjects, and which should also report to this Convention what action, in their opinion, should be taken by this Convention. The Convention would then have an opportunity to determine
                <PageNumber num={426} /> its action. The confirmation of that report by the Convention would immediately conclude the negotiations between the two parties.
              </p>
              <p className="p-in-sp">
                If, on the contrary, that committee should report to the Convention that, in their judgment, something else than this alliance offensive and defensive was necessary, it could be rejected by the Convention, and the terms upon which the association could be formed at once determined by this body. We will not have an opportunity of indicating before hand what these terms are; but a
                committee of gentlemen can confer with him and report to the Convention, and the Convention will thus have the means of forming an intelligent opinion. They will be able to arrive at a prompt decision, and the courtesy due to the Vice President will be accorded to him by the immediate appointment of the committee to confer with him.
              </p>
              <p className="p-in-sp">Should this proposition be voted down, I would offer the following resolution:</p>
              <p className="p-in-sp">"Resolved, That a committee of five be appointed to confer with the Hon. ALEXANDER H. STEPHENS, the Commissioner from the Confederate States, as to the terms of a union between this State and the Confederate States, and report the same promptly to the Convention."</p>
              <div className="speaker" id="sp4566"><span className="persName">Mr. BRANCH</span>—</div>
              <p className="p-in-sp">I think this subject has been fully discussed, and I therefore call the previous question.</p>
              <p>The call was sustained and the main question ordered to be put.</p>
              <div className="speaker" id="sp4567"><span className="persName">Mr. JOHNSON</span>, of Richmond—</div>
              <p className="p-in-sp">I simply desire to know what is the authority of the Commissioner from the Confederate States to negotiate the terms of the proposed alliance.</p>
              <div className="speaker" id="sp4568"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Secretary will read the credentials of the Commissioner.</p>
              <p>The credentials were accordingly read.</p>
              <div className="stage it">[They are published in the proceedings of Monday.]</div>
              <div className="speaker" id="sp4569"><span className="persName">Mr. JOHNSON</span>—</div>
              <p className="p-in-sp">I simply desire to say that it was in no captious spirit that I made the inquiry. Being absent when the credentials of the Commissioner were presented on yesterday, I was anxious to hear them read. I am very glad that the Commissioner has the power to negotiate the proposed alliance.</p>
              <div className="speaker" id="sp4570"><span className="persName">Mr. PRESTON</span>—</div>
              <p className="p-in-sp">There seems to have arisen a great misapprehension in regard to my remarks, and I would, with the universal consent of the Convention, offer a single word of explanation.</p>
              <p className="p-in-sp">
                I said, when I got up, that I intended to vote for the resolution. I expressed a desire that a committee be appointed, but was opposed to its taking any ulterior action until there was some consultation among gentlemen here, and an indication given which would guide <PageNumber num={427} /> the committee and enlighten it as to the terms upon which the
                Convention desired the association to be formed.
              </p>
              <p className="p-in-sp">The resolution of the gentleman from Amelia [<span className="persName">Mr. HARVIE</span>] , meets my views precisely.</p>
              <p>The question was then put on the adoption of the substitute offered by <span className="persName">Mr. GOODE</span> and accepted by <span className="persName">Mr. HARVIE</span>, and it was decided in the affirmative.</p>
              <p>The resolution as amended was then adopted.</p>
              <p><span className="persName">The PRESIDENT</span> appointed the following committee under that resolution-Messrs. John Tyler of Charles City, Wm. Ballard Preston of Montgomery, Samuel McDowell Moore of Rockbridge, James P. Holcombe of Albemarle, and James C. Bruce of Halifax.</p>
              <div className="speaker" id="sp4571"><span className="persName">Mr. BOULDIN</span>, of Charlotte—</div>
              <p className="p-in-sp">There was a document laid upon the table to-day by the distinguished Commissioner from the Confederate States. I mean the Constitution of the Confederate States, which I move to have printed for the use of the Convention.</p>
              <div className="speaker" id="sp4572"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">What number?</p>
              <div className="speaker" id="sp4573"><span className="persName">Mr. BOULDIN</span>—</div>
              <p className="p-in-sp">The usual number—200 copies.</p>
              <p>The motion was agreed to.</p>
              <div className="speaker" id="sp4574"><span className="persName">Mr. MORRIS</span>, of Caroline—</div>
              <p className="p-in-sp">I move that <span className="persName">Mr. HARVIE</span> be added to the committee appointed to confer with the Vice President of the Confederate States.</p>
              <p>The motion was agreed to.</p>
              <div className="speaker" id="sp4575"><span className="persName">Mr. BLAKEY</span>, of Madison—</div>
              <p className="p-in-sp">I beg leave to offer the following resolution:</p>
              <p className="p-in-sp">"Resolved, That this Convention heartily approves of the act of the Governor of this State in lending one thousand stand of arms to our brethren in Maryland; and he is hereby authorized to extend to them hereafter any facility in his power, to enable them to resist the aggressions of the Northern hordes who are seeking to pollute the soil of that State."</p>
              <p>The resolution was adopted.</p>
              <p>On motion of <span className="persName">Mr. STAPLES</span>, of Patrick, the Convention adjourned until 12 o'clock to-morrow.</p>
            </div>
          </div>
          <div className="day" id="vsc1965.v4.2.9">
            <PageNumber num={428} />
            <h2><span className="headingNumber">1.9. </span><span className="head">NINTH DAY</span></h2>
            <div className="dateline">Wednesday, <span className="date">April 24</span></div>
            <p>The Convention met at 12 o'clock. Prayer by the Rev. Dr. Jeter, of the Baptist Church.</p>
            <div className="speaker" id="sp4576"><span className="persName">Mr. BLAKEY</span>, of Madison—</div>
            <p className="p-in-sp">I desire to offer a report on the subject of elections as an addenda to the election schedule already adopted by the Convention:</p>
            <p className="p-in-sp">
              7. The qualified voters of the Commonwealth who may be absent from the counties or corporations of their residence on the day of election in the military service of the State may vote for the ratification or rejection of the said ordinance at such place or places within their encampments or as the commander at such encampment shall designate, whether the said encampment shall be within
              the limits of the State or not. For each place of voting he shall appoint a superintendent, three commissioners, and as many clerks as shall be necessary, who, after having been first duly sworn by him, shall perform the duties required of, and be liable to the penalties imposed upon, such officers under the election laws of this State.
            </p>
            <p className="p-in-sp">8. The officers conducting the said election shall, on the day after the election, or as soon thereafter as may be, deliver the poll-books to their said commander, who shall forthwith forward the same to the Governor of this Commonwealth, who shall count the said votes in ascertaining the result of the said election in the State.</p>
            <p className="p-in-sp">9. That the election for members of Congress for this State to the House of Representatives of the Congress of the United States, required by law to be held on the fourth Thursday in May next, is hereby suspended and prohibited until otherwise ordained by this Convention.</p>
            <div className="speaker" id="sp4577"><span className="persName">Mr. BLAKEY</span>—</div>
            <p className="p-in-sp">In order to make the schedule already adopted and the addenda correspond, it may be necessary to amend the schedule in one respect. I suppose it will be to re-consider the vote by which it was adopted. I make a motion to that effect.</p>
            <p>The motion was agreed to.</p>
            <div className="speaker" id="sp4578"><span className="persName">Mr. BLAKEY</span>—</div>
            <p className="p-in-sp">I will read the schedule as it was originally adopted :</p>
            <p className="p-in-sp">
              "1. It shall be the duty of the officers conducting the elections directed by law to be held on the fourth Thursday in May next, at the places appointed for holding the same, to open a poll to take the sense of the qualified voters of this Commonwealth upon the ratification or rejection of "An ordinance to repeal the ratification of the
              <PageNumber num={429} /> Constitution of the United States of America by the State of Virginia, and to resume all the rights and powers granted under said Constitution," adopted in Convention at the city of Richmond on the seventeenth day of April, one thousand eight hundred and sixty-one.
            </p>
            <p className="p-in-sp">"2. The poll book shall be headed "The Ordinance of Secession," and shall contain two columns, one headed "For Ratification," and the other "For Rejection," and the names of those who vote for the ratification of the said ordinance shall be written under the former heading, and the names of those who vote for its rejection shall be written under the latter heading.</p>
            <p className="p-in-sp">
              "3. The said officers shall make return of the number of persons voting for each proposition, at the time and in the manner provided by law in the case of other elections; and shall forthwith deliver the returns, together with the poll books, to the clerks of their respective counties and corporations; and it shall be the duty of such clerks, respectively, to transmit immediately to
              the Governor of the Commonwealth copies of the said returns so delivered to them.
            </p>
            <p className="p-in-sp">
              "4. The Governor shall, without delay, make proclamation of the result, stating therein the aggregate vote for and against the ratification, to be published in such newspapers in the State as may be deemed requisite for general information; and if a majority of said votes be cast for the ratification of the said ordinance he shall annex to his proclamation a copy thereof, together with
              this schedule.
            </p>
            <p className="p-in-sp">
              "5. The Secretary of the Commonwealth shall cause to be sent to the clerks of each county and corporation as many copies of this schedule and ordinance aforesaid as there are places of voting therein, using special messengers for that purpose when necessary. And it shall be the duty of said clerks to deliver the same to the sheriffs for distribution, whose duty it shall be forthwith to
              post the said copies at some public place in each election district.
            </p>
            <p className="p-in-sp">"6. The expenses incurred in providing poll-books and in procuring writers to enter the names of the voters therein shall be defrayed as in the case of the election of members to the General Assembly.</p>
            <p className="p-in-sp">
              Done in Convention, in the city of Richmond, on the 24th day of April, eighteen hundred and sixty-one, and in the eighty-fifth year of the Commonwealth of Virginia."<span className="note" id="Note44"><span className="noteLabel">1</span>See note 1 on the Proceedings of April 20.</span>
            </p>
            <div className="speaker" id="sp4579"><span className="persName">Mr. BLAKEY</span>—</div>
            <p className="p-in-sp">I now propose to insert at the conclusion of the 6th clause, and before the concluding clause, the following:</p>
            <p className="p-in-sp">
              7. The qualified voters of the Commonwealth who may be absent from the counties or corporations of their residence on the day of election in the military service of the State may vote for the ratification <PageNumber num={430} /> or rejection of the said ordinance at such place or places within their encampments or as the commander at such encampment
              shall designate, whether the said encampment shall be within the limits of the State or not. For each place of voting he shall appoint a superintendent, three commissioners, and as many clerks as shall be necessary, who, after having been first duly sworn by him, shall perform the duties required of, and shall be liable to the penalties imposed upon, such officers under the election
              law of this State.
            </p>
            <p className="p-in-sp">8. The officers conducting the said election shall on the day after the election, or as soon thereafter as may be, deliver the poll-books to their said commander, who shall forthwith forward the same to the Governor of this Commonwealth, who shall count the said votes in ascertaining the result of the said election in the State.</p>
            <p className="p-in-sp">9. That the election for members of Congress for this State to the House of Representatives of the Congress of the United States, required by law to be held on the fourth Thursday in May next, is hereby suspended and prohibited until otherwise ordained by this Convention.</p>
            <div className="speaker" id="sp4580"><span className="persName">Mr. HALL</span>, of Marion—</div>
            <p className="p-in-sp">I ask for a division of the question.</p>
            <p>The vote was accordingly taken upon each section separately, and they were severally adopted.</p>
            <div className="speaker" id="sp4581"><span className="persName">Mr. BLAKEY</span>—</div>
            <p className="p-in-sp">I move further to amend by adding after the words "as there are places of voting therein" the words "and three copies to each of the military commanders hereinafter referred to."</p>
            <p>The amendment was adopted.</p>
            <p>The schedule, as amended, was then adopted.</p>
            <p><span className="persName">Mr. HAYMOND</span>, from the Committee of Elections, reported as follows:</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>The Committee of Elections, to whom was referred the petition of M. B. D. Lane, contesting the seat of John D. Sharp in the Convention, as a delegate from the county of Lee, have had under further and final consideration said petition, and other papers connected therewith, and have come to the following conclusion, to wit:</p>
                <p>Resolved, That John D. Sharp, the sitting member, is entitled to hold his seat in the Convention, he being duly elected, and that the committee be discharged from the further consideration of said petition.</p>
                <p>The committee also ask to be discharged from the further consideration of all other subjects heretofore referred to them, and ask the adoption of the following resolution :</p>
                <PageNumber num={431} />
                <p>Resolved, That John Grime, Jr., be allowed twenty-eight dollars for services rendered as Clerk of the Committee of Elections.</p>
                <p>All of which is respectfully submitted.</p>
              </div>
            </div>
            <div className="speaker" id="sp4582"><span className="persName">Mr. HAYMOND</span>—</div>
            <p className="p-in-sp">I will state, in relation to this compensation, that we had a great deal of trouble in obtaining the returns, and, by a vote of the committee, we authorized the employment of Mr. Grime to assist us. He did so, and was engaged in that service for a week. The committee concluded that $4 a day being the compensation usually allowed, was reasonable.</p>
            <p>The report was agreed to.</p>
            <div className="speaker" id="sp4583"><span className="persName">Mr. ROBERT L. MONTAGUE</span>—</div>
            <p className="p-in-sp">
              I have been requested by one of the assistants of the Clerk of the House of Delegates to ask you to authorize the door-keeper to let the first door of the Capitol, which communicates with the Clerk's office, to remain open. There is a great deal of business necessary to be done in connection with that office, and he has a great difficulty in getting into it. I merely make the
              suggestion. You can issue the order or not, as you choose.
            </p>
            <p className="p-in-sp">While up, I will offer the following resolution:</p>
            <p className="p-in-sp">
              "Resolved, That the several courts of this Commonwealth be, and are hereby authorized to suspend the sessions thereof, and all proceedings therein, insofar as to them may seem proper, in view of the condition of public affairs, until otherwise ordered by this Convention, or by the General Assembly: Provided, however, that the county and corporation courts shall continue to exercise
              their ordinary jurisdiction in cases of probate and administration, and for like purposes-and their proper police authority for the maintenance of the peace and for arming and equipping troops as may be authorized by law or by this Convention. And provided further, that all the courts shall be open for the trial of criminal causes, involving the life or liberty of the accused, for
              applications for bail, and for writs of habeas corpus; and for all proceedings in behalf of the Commonwealth for the collection of the public revenue."
            </p>
            <p className="p-in-sp">I ask that this resolution may be adopted and referred to the appropriate committee. I don't know the style of the committee, but there is a committee in existence having kindred subjects in charge. I hope it will be referred, with instructions to report forthwith.</p>
            <div className="speaker" id="sp4584"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">There is a committee appointed in regard to the suspension of debts, which was raised under a resolution offered by <span className="persName">Mr. HULL</span>, I believe.</p>
            <div className="speaker" id="sp4585"><span className="persName">Mr. MONTAGUE</span>—</div>
            <p className="p-in-sp">Let it be referred to that committee.</p>
            <PageNumber num={432} />
            <p className="p-in-sp">I now move that the injunction of secrecy be removed, so far as relates to the schedule just adopted.</p>
            <div className="speaker" id="sp4586"><span className="persName">Mr. KILBY</span>—</div>
            <p className="p-in-sp">I will suggest that the ordinance of secession be published with it.</p>
            <div className="speaker" id="sp4587"><span className="persName">Mr. MONTAGUE</span>—</div>
            <p className="p-in-sp">I will so amend th; motion as to embrace the ordinance.</p>
            <p>The question was then put on <span className="persName">Mr. MONTAGUE</span>'S motion, as amended, and it was agreed to.</p>
            <div className="speaker" id="sp4588"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">I beg leave to submit the following communication from the Governor of the Commonwealth:</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>EXECUTIVE DEPARTMENT,</p>
                <p>April 24th, 1861.</p>
                <p>Gentlemen of the Convention:</p>
                <p>
                  Troops are arriving in large numbers hourly from our own State, and from the Confederate States, and it now becomes indispensable, that you shall declare the policy which is to be adopted. If we are to act on the defensive, we have a much larger number of troops in the field than is necessary for that purpose if an aggressive movement is determined upon, it is of the utmost
                  importance that it shall be announced at the earliest moment practicable. It is due to the Executive, and also to the General in Command of our State forces, that we should be left in no doubt as to your purposes and objects, in order to secure harmonious action.
                </p>
                <p>Respectfully,</p>
                <p>JOHN LETCHER.</p>
              </div>
            </div>
            <div className="speaker" id="sp4589"><span className="persName">Mr. JOHN M. SPEED</span>, of Campbell—</div>
            <p className="p-in-sp">I propose to offer the following resolution-</p>
            <p className="p-in-sp">I deem it due to myself, to the Convention and to the subjects embraced by it, to state that I do so at the instance of a distinguished member of the Convention. I do not ask that the usual courtesy extended to the mover of a resolution, be extended to me by placing me first upon the committee. I prefer that the honor should be conferred upon some other.</p>
            <p className="p-in-sp">The resolution has reference to the subject embraced by the communication just received from the Executive.</p>
            <p>The following is the resolution:</p>
            <p>"Resolved, That a committee of seven be appointed by the President, to consider and report upon the general objects and policy most <PageNumber num={433} /> proper to be followed in our present circumstances, with reference to the hostilities in which the people of this Commonwealth are likely to be engaged."</p>
            <div className="speaker" id="sp4590"><span className="persName">Mr. EARLY</span>—</div>
            <p className="p-in-sp">I want to state one thing. We have a commander-in-chief now who must plan our operations, and before we can determine what course of policy we should adopt we ought to have the advice and counsel of that officer. A committee might be raised to consider this subject; but a great deal of mischief may result from the development of any policy at this time.</p>
            <p className="p-in-sp">
              On last Saturday night there was a company organized here for the purpose of going to the banks of the Potomac and putting up a battery there. That fact was published in the newspapers on the following Monday. I saw on yesterday a gentleman who went on that expedition, and he said when they got there, they found the steamship Anacostia with troops, ready to prevent any undertaking for
              the defence of that section. All our military operations ought to be secret.
            </p>
            <p className="p-in-sp">
              Sir, we are now in a very deplorable condition in many particulars. We ought to ascertain what are our military resources. The commander-in-chief ought to have an opportunity to ascertain what means we have before we develop any policy. I ascertained from the gentleman having charge of the ordnance department that there are about 40,000 percussion caps in that department. He has
              telegraphed North and South and has ascertained that none can be found anywhere. 40,000 caps would not last an army of 1,000 men an hour; and after that supply is gone we would be left without any means of using the arms that have percussion locks. I understood from the same gentleman that our flint muskets are in a very bad condition, most of them being manufactured thirty or forty
              years ago.
            </p>
            <p className="p-in-sp">I throw out these considerations for the benefit of the gentlemen who may be appointed on that committee. I am myself opposed to any committee; but it seems to me, that the best thing we can do is to provide for the organization of an effective military force.</p>
            <div className="speaker" id="sp4591"><span className="persName">Mr. GOGGIN</span>, of Bedford—</div>
            <p className="p-in-sp">
              I do not intend to throw any obstacle in the way of the purpose of the gentleman who offered the resolution; but it seems to me that before we adopt a resolution embracing so many important subjects as this does-a resolution which it seems to me is directly connected with all the interests of the State at large, and the relations of the State not only with the United States, but the
              Confederate States also, we ought to await the report of the committee appointed to confer with the Vice President, of which the gentleman from Charles City
              [<span className="persName">Mr. TYLER</span>]
              , is the chairman. That <PageNumber num={434} /> committee will, in all probability, be prepared to make a report during the day, and when that is done, we can judge better of the propriety of the course recommended by that resolution. The effect of the adoption of the resolution now, if I understand the purport of it, would be the appointment of another
              committee, which would have before it for consideration substantially what has already been committed to the committee appointed in connection with the mission of the Commissioner from the Confederate States. The duties of that committee, should it be appointed, cannot be performed, at least to my satisfaction, until I am put in possession of the report of the committee, to wait on the
              Vice President.
            </p>
            <p className="p-in-sp">I make this suggestion to my friend from Lynchburg [<span className="persName">Mr. SPEED</span>] , without intimating a purpose to throw any obstacle in the way of the accomplishment of this object. I would much prefer that this subject would not be presented for the present.</p>
            <div className="speaker" id="sp4592"><span className="persName">Mr. AMBLER</span>—</div>
            <p className="p-in-sp">
              I am very much surprised that a resolution of this character has been proposed to the Convention. You have just appointed a Commander-in-Chief. He has not had an opportunity of knowing anything about your resources, or of examining the country which he is to protect. He has had no opportunity of knowing the number of forces that you can place at his disposal; and now you propose here
              under this resolution to plan the campaign for that Commander-in-Chief. You propose to decide upon the policy whether this war is to be a defensive or an aggressive one. Is not that planning the campaign? We, in fact, propose to appoint a committee who are to govern the Commander-in-Chief, whom you have appointed to take charge of the military and naval forces of the State.
            </p>
            <p className="p-in-sp">Now, sir, do you intend that you shall tie his hands? And yet, if your committee undertakes to decide the questions referred to in that resolution, you do tie his hands. You force that Commander-in-Chief, no matter what his views may be, to form all his plans in conformity with your ideas and not his own.</p>
            <p className="p-in-sp">
              Why, sir, is he not the person to decide whether your course shall be simply defensive or aggressive? Does not that decision depend upon the means at his command? Are you going to decide this question before you give him an opportunity of knowing what he will have in his power to do? Why, sir, you cannot tell this day or a week hence, whether you will be able to move one thousand men
              or five thousand men to any one given point.
            </p>
            <p className="p-in-sp">The gentleman says you have not more than forty thousand percussion caps.</p>
            <PageNumber num={435} />
            <div className="speaker" id="sp4593"><span className="persName">Mr. RANDOLPH</span>, of Richmond—</div>
            <p className="p-in-sp">I bought myself for the State four hundred thousand percussion caps and I imagine that is a mere remnant of that number.</p>
            <div className="speaker" id="sp4594"><span className="persName">Mr. AMBLER</span>—</div>
            <p className="p-in-sp">
              I don't know anything about this matter of my own personal knowledge; but a gentleman here says that the manager of the Ordnance Department stated to him that there are but forty thousand caps now in that Department. Now, this very fact that you have two gentlemen differing as to this matter, shows you the folly of your undertaking to decide upon the question upon which it is proposed
              you shall decide through this committee. Here you yourselves do not seem to be informed with respect to this important matter of percussion caps. Sir, viewing this policy in the light which I view it, I regard it in effect, as taking the power out of the hands of the Commander-in-Chief and placing it in the hands of a committee of the Convention. You supersede the Commander-in-Chief by
              your action, and he is merely to execute your orders.
            </p>
            <p className="p-in-sp">Now, sir, I give the utmost deference to gentlemen in this Convention, but I must say, that I do not think that any committee of seven men ever existed, or will exist, who, under these circumstances, ought to be trusted with such a power. It is of necessity a power which ought to be intrusted to one man, and one man alone.</p>
            <p className="p-in-sp">
              Now, sir, I do implore gentlemen to pause in time. Do not let us commence our career by binding the very hands in which we placed the sword for the purpose of defending our lives. The result of this policy of granting to a committee a power which properly belongs to the Commander-in-Chief, will be inevitable collision, which must end in confusion and perhaps defeat and ruin. I hope the
              resolution will be withdrawn, and that we will stop the inauguration of a system which, if persisted in, must end in destruction to our cause.
            </p>
            <div className="speaker" id="sp4595"><span className="persName">Mr. R. Y. CONRAD</span>, of Frederick—</div>
            <p className="p-in-sp">If gentlemen had not misconceived the purport and language of the resolution of the gentleman from Lynchburg [<span className="persName">Mr. SPEED</span>] I am sure it would not have met with the opposition which has been made to it.</p>
            <p className="p-in-sp">The resolution, as I understand it, proposes to raise a committee to declare what the general aim or purpose, and what shall be the general policy in the hostilities in which we are about to be engaged.</p>
            <p className="p-in-sp">
              Well now, sir, this is not the wild inquiry which gentlemen had supposed. We are now preparing for hostilities upon a large scale. It must be with some purpose; with some aim or object-and what is it, sir? I presume the response from every member here will be, that it is for the purpose of peace; of being allowed to separate from the
              <PageNumber num={436} /> government with which we have hitherto been connected peaceably-that this is the general purpose and aim of these hostilities in which we are about to be engaged.
            </p>
            <p className="p-in-sp">The other inquiry is, what shall be the general policy; and I apprehend, sir, there will probably be as little difference of opinion upon that subject as upon the other. It has been indicated by the gentleman over the way [<span className="persName">Mr. AMBLER</span>] somewhat to my surprise, I must be permitted to say, that that policy was assumed by him to be an aggressive policy.</p>
            <div className="speaker" id="sp4596"><span className="persName">Mr. AMBLER</span>—</div>
            <p className="p-in-sp">I utterly disclaim that any language which I used, could be distorted into any such meaning. The whole tenor of what I said justified rather an opposite conclusion.</p>
            <div className="speaker" id="sp4597"><span className="persName">Mr. CONRAD</span>—</div>
            <p className="p-in-sp">
              I am delighted to hear that disclaimer, because I was compelled, as I thought, to draw the inference I did from the remarks of the gentleman. I take it back most cheerfully. I will state, by way of justification for the inference which I made, that the gentleman said, in referring to our means to carry on a war, that we were not able now to determine whether we should pursue an
              aggressive policy or not.
            </p>
            <div className="speaker" id="sp4598"><span className="persName">Mr. AMBLER</span>—</div>
            <p className="p-in-sp">What I said was in reply to what had been said by the gentleman from Lynchburg [<span className="persName">Mr. SPEED</span>] in regard to that very fact.</p>
            <div className="speaker" id="sp4599"><span className="persName">Mr. CONRAD</span>—</div>
            <p className="p-in-sp">
              I do not mean to pursue that matter farther; but I will say that I am very glad to hear the disclaimer of the gentleman. I repeat that I suppose there will be as little difference of opinion on this floor as to the general policy to be pursued in this manner as there was upon the other question, as to what is to be the ultimate aim and end of our hostilities, and that is, that our
              general policy shall be a defensive policy; that we shall stand upon the defensive from first to last, except so far as, in the course of events, aggression may really become defensive.
            </p>
            <p className="p-in-sp">
              Well then, sir, I suppose that upon another question of general policy there will be as little difference of opinion-that is, that these hostilities, if unhappily we are engaged in them-shall be conducted in the mode and manner, at least, of civilized warfare, and that we are not to go in, as I am sorry to see, seems to have been begun by some of the citizens of this Commonwealth, for
              seizing upon private property and private persons, wherever found in our Commonwealth, from the Northern States. This, I take for granted, the committee will declare to be the general aim of this war, and as the general policy upon which the hostilities shall be pursued.
            </p>
            <PageNumber num={437} />
            <p className="p-in-sp">Now, my friend over the way [<span className="persName">Mr. AMBLER</span>] , uses one argument which apparently has some force in it, against anything like interference with the Commander-in-chief who was appointed yesterday to conduct these hostilities. He supposes that any programme which may be laid down by this committee might be an improper interference with his functions.</p>
            <p className="p-in-sp">
              Now, sir, this is not a time for any disguises; and although, perhaps, under ordinary circumstances I would not be permitted to state it, I will mention here that this very inquiry is instituted at the request of Major General Lee himself. He stated, and properly stated, in a private conversation yesterday, that whilst he took the responsibility of conducting all the military
              operations, there were political and moral considerations which he had a right to be advised upon by the representatives of the people of Virginia. He, I am sure, must be utterly averse to this irregular action-this uncivilized action of seizing upon private property-the property of peaceable individuals, who have come here before any declaration of secession was made on our part. He
              must be averse to pouncing upon an officer of the army of the United States who happened to be on our soil without a suspicion that we have been engaged in war.
            </p>
            <p className="p-in-sp">
              All these irregularities must be abhorrent to the mind of any regular soldier; and yet, if the people of Virginia are permitted to pursue this practice without any control or declaration from this Convention, the general in command may find some difficulty in suppressing this spirit among the people. It is the proper duty of the representatives of the people to declare what ought to be
              done in these cases.
            </p>
            <p className="p-in-sp">
              With respect to the general aim of the war, if our general object is merely to have a peaceable separation from these other States; if our general policy, which is almost a natural sequence to that, shall be a defensive one, it is important that the general in command should know that that is the purpose and position of the Commonwealth of Virginia, in order that he may conduct his
              operations in accordance with the general sense of the people and the general policy which they desire to be pursued. If he could get some expression of opinion from this Convention in reference to this general object, I am satisfied the clear mind of my friend, if he will only reflect for a moment, will see that this general declaration cannot by possibility in any way control or
              interfere with the operations of Gen. Lee. We should proclaim, as soon as possible, that we are not for invading any other State; that we are claiming nothing of any other State; that we are asking only our own, and to be permitted to remain in possession <PageNumber num={438} /> of our own in peace, and, therefore, we do not desire any aggressive
              hostilities which are unnecessary, but desire only to defend our own soil, our own people and all that is within the limits of Virginia to the utmost of our power. By making this declaration, he will have distinctly before him all that will be important to him in conducting his operations. He cannot be cramped in any way by any declaration of this sort.
            </p>
            <p className="p-in-sp">I am in favor of the proposed inquiry, for the reason I have stated, that this is his own deliberate opinion and desire upon the subject. I am satisfied that that statement alone will induce the gentleman to withdraw the opposition which he has at first view, and, with all respect, I think somewhat hastily, raised to this resolution.</p>
            <div className="speaker" id="sp4600"><span className="persName">Mr. SAMUEL M. GARLAND</span>, of Amherst—</div>
            <p className="p-in-sp">I regard this matter as of very great importance, and I therefore, with some distrust, presume to present to this Convention one or two remarks.</p>
            <p className="p-in-sp">My own idea is, that this movement on the part of my friend from Campbell [<span className="persName">Mr. SPEED</span>] is, to say the least of it, exceedingly premature. I concur fully in the view of the gentleman from Louisa [<span className="persName">Mr. AMBLER</span>] , that its effect must be, if it have any at all, to embarrass the operations of him upon whom we have conferred the important trust of conducting our military operations.</p>
            <p className="p-in-sp">
              But, independent of this view, there is to my mind another very strong reason why we should not interfere with this matter, and that is, that we are not now in a condition to act definitely upon it. I take it for granted, that Virginia intends to form an alliance of some sort with the Southern Confederacy. This is inevitable. We cannot avoid it, if we would. Well, sir, if that be so;
              if we are obliged to form this alliance either from feelings of regard to them, or from interest, is it not exceedingly improper for us to take any step here in advance of what may be the general policy in conducting this war? Virginia does not intend to carry on this great conflict by herself. She must look for aid from the Southern Confederacy, and in the event of a union with the
              States of that Confederacy, which I regard as inevitable, it may happen that the very plan upon which we might determine, would be entirely overruled by the military authorities of these States. This consideration, presents to my mind an insuperable difficulty; and I would therefore take no step until we hear from the committee appointed to confer with the Vice President in regard to
              the alliance, which we expect to form with the Southern Confederacy. We ought not to move until we know to whom we are to be allianced.
            </p>
            <p className="p-in-sp">
              Gentlemen seem to be startled at the idea of the word "war." It has <PageNumber num={439} /> no fears for me. We intend to procure our rights. We have asserted them by the passage of the ordinance of secession, and we intend to resist aggression from any quarter; and if it be necessary in order to procure our rights and defend them, and to purchase a
              peace, that we should pursue an aggressive war, I am for aggression to any extremity; and I believe now, although no military man, that the most effectual way of procuring a peace is to carry on an aggressive war, and if need be, to assail the Capital itself.
            </p>
            <p className="p-in-sp">The idea of the gentleman from Frederick [<span className="persName">Mr. CONRAD</span>]
              that this would be an improper and lawless proceeding, I think, has no force. Our military operations are now under the control of the military men. You cannot prevent the outburst of the feelings of the people by any declaration you may make here. Let those who have them in charge control them. Let us see who are to be our allies; what connections we are to form. Let our leaders
              determine upon some great plan of operations; let the war be an aggressive one and we shall soon have peace.
            </p>
            <div className="speaker" id="sp4601"><span className="persName">Mr. SHEFFEY</span>, of Smyth—</div>
            <p className="p-in-sp">
              We have appointed a commander-in-chief of the military and naval forces of Virginia. We have communicated to the Confederate States that we have seceded from the United States, and we have proposed to them to enter into an alliance offensive and defensive. That proposition has been promptly responded to. As fast as steam could bear the distinguished commissioner from the Confederate
              States hither, he has come, and frankly proposes to enter into any alliance offensive and defensive or into any union that may be acceptable to Virginia.
            </p>
            <p className="p-in-sp">
              We present the highest spectacle of moral sublimity of having, under a threat of coercion from the Federal Government to involve the country in a war, with 75,000 men, called for to carry out that threat, and Virginia called upon for her quota, seceded from the Union, and defied the menace. In the face of these threats, Virginia, as an independent State, solitary and alone, without
              promise to be sustained in a war, has taken this position; and in that condition, we are assured of a readiness upon the part of the Confederate States to stand by us, to unite with us in the defence of a common right, in the defence of a common liberty: for I believe, sir, to-day the purpose of the powers at Washington is, to wage not only an aggressive war upon the South, but an
              exterminating war-not only a war to subvert and overthrow the State rights, but to subvert and overthrow liberty itself. In this state of things, we have appointed, for the purpose of strengthening us in our position a commission, at the head <PageNumber num={440} /> of which is the distinguished ex-President of the United States
              [<span className="persName">Mr. TYLER</span>]
              , and others whose names I cannot now call to mind, but in whose discretion and wisdom we have entire confidence. The commission is to decide whether our destiny shall be to carry on this conflict alone, or unite in an alliance offensive and defensive, or a union with the Confederate States. They will determine what our future policy is to be, whether we shall stand alone in this
              conflict or be assisted by those having a common interest and a common destiny with us. We are called upon by the Executive of Virginia, who has taken it into his own hands to convene troops here from different portions of the State without authority from us, to declare what shall be the course or policy that Virginia shall pursue-whether it shall be aggressive, or whether it shall be
              defensive.
            </p>
            <p className="p-in-sp">
              I maintain that it is not now for us to decide whether our course shall be aggressive or defensive. I say that depends first upon the question whether we shall form an alliance with the Southern Confederacy, what the character of that alliance is to be, and what are the terms upon which we enter it. That alliance forbids that we shall determine the policy of Virginia. If we enter into
              such an alliance, we are to consult the authorities of the Confederate States as to the policy which is to be pursued.
            </p>
            <p className="p-in-sp">I think, therefore, it is premature for us to be called upon by the Governor to take the responsibility of deciding in this attitude of affairs whether our course shall be an aggressive or defensive one.</p>
            <p className="p-in-sp">
              There is another reason why we should not declare what our policy is to be. We have appointed a Commander-in-chief to whom we have entrusted the military and naval power of the Commonwealth, and now if we enter into an alliance offensive and defensive with the Confederate States, I take it that it will be a matter of consultation between the Confederate authorities and Virginia what
              course she shall pursue. I am no military man; but I maintain that in this emergency the true defense of Virginia, the true defense of the South, the true mode of conquering a peace is, with all the power of the Confederate States and with all the power of Virginia at once, without delay, as soon as we can accumulate forces sufficient, to expel from an usurped throne the detested
              tyrant who sits on it at Washington.
            </p>
            <p className="p-in-sp">
              That, sir, is my view of the true policy of Virginia. But, sir, we are not here to determine what that policy shall be until we first know what are our relations to the other States of the Southern Confederacy. If we are to fight alone we should be forced to adopt the policy of a mere defensive war, but if there is to come to our aid
              <PageNumber num={441} /> the power of the Confederate States and all the slave States of the South, then we may find that the best mode of defense, the best mode of securing a peace, the best mode of preventing the subjugation of the South is to carry the war into Washington, and to defend our rights upon the borders of Maryland. I believe to-day that
              with the power at our command we have the means of driving from the borders of the slave States those who are engaged not only in a war of aggression against us, but who are animated by a purpose of pursuing that aggression to the end of extermination.
            </p>
            <p className="p-in-sp">Gentlemen talk about civilized warfare. Why, sir, what course of policy has been exhibited by the powers at Washington? They are disposed to destroy everything in the shape of property that falls within their control rather than that it shall fall into our hands, before even we have declared any war against them, and whilst we are acting on the defensive.</p>
            <p className="p-in-sp">
              It seems to me, sir, that it is premature in us to adopt a resolution appointing a committee to determine whether our course shall be aggressive or defensive, until the committee appointed to confer with the Vice-President shall determine what our relations with the seceded States are to be, and until they shall have determined by a conference with the Commander-in-chief of our forces
              what our policy shall be.
            </p>
            <p className="p-in-sp">
              There is another policy to which I desire to call the attention of the Convention. The gallant State of Maryland has stood in the breach between us and the power of the North; between us and the hordes of the Goths and Vandals who were pouring down to the capital of the country to concentrate their forces there for the purpose of subjugating the South; and is it to be tolerated by bold
              and proud Virginia; by the men of the South who are animated by the true spirit of patriotism and of freedom; that that gallant State shall stand alone, and be overrun by these Northern hordes?
            </p>
            <div className="speaker" id="sp4602"><span className="persName">Mr. CONRAD</span>, of Frederick—</div>
            <p className="p-in-sp">Did the gentleman suppose that I uttered any sentiment against the defence of Maryland?</p>
            <div className="speaker" id="sp4603"><span className="persName">Mr. SHEFFEY</span>—</div>
            <p className="p-in-sp">
              I hope the gentleman does not entertain any such sentiment as that; but when I hear a defensive war talked of; and when I see our brethren of Maryland standing like a bulwark between us and these hordes of the North; I say that every impulse of a manly heart demands that we in the South shall stand between her and these hordes, cost what it may; and I maintain that for efficient
              defence it is necessary we shall meet the enemy at the threshold; and that we shall roll back the tide from the outer border, ere it rushes <PageNumber num={442} /> down with irresistible power upon us, if in connection with the Confederate States and in conference with the Commander-in-chief, that policy be deemed proper, as in my heart I believe it is.
            </p>
            <p className="p-in-sp">
              I am opposed, therefore, to the raising of a committee intended to commit this State to a defensive war, when aggression may be the true mode of defence-when aggression, as the gentleman might call it, or as some might suppose it, may stay the march of troops into the Capital where they are being concentrated for the purpose of making a descent upon our own State at a favorable time,
              and, if possible, of subjugating us. For one, I am in favor of driving them from that position and intercepting the advance of any more troops within the borders of Maryland.
            </p>
            <p className="p-in-sp">
              I have but one in whose veins my own blood flows, and I am ready to yield him up, if need be, as a sacrifice to my country, and if it becomes necessary, in addition to the personal peril, that we leave this body and engage in the conflict, I am willing to pour out the last drop of blood that flows in my own veins in defence of Southern rights and Southern liberty, which are now
              assailed, and which, if not promptly defended, must be extinguished in eternal ruin.
            </p>
            <p className="p-in-sp">Sir, I say it is impolitic and unwise. It would, in fact, be criminal in this Convention to say what the policy of Virginia should be until we have determined what our relations with the Confederate States shall be, and until we shall have consulted those to whom our military defence shall be committed, under the union or alliance which it is intended to form.</p>
            <p className="p-in-sp">I would move, therefore, that the resolution of the gentleman be laid upon the table, if it does not interfere with further discussion. If it does, I will not make that motion.</p>
            <div className="speaker" id="sp4604"><span className="persName">Mr. WICKHAM</span>—</div>
            <p className="p-in-sp">I would suggest that a guard be provided by the Governor to keep persons outside the building at a distance.</p>
            <div className="speaker" id="sp4605"><span className="persName">Mr. GOGGIN</span>—</div>
            <p className="p-in-sp">I hope not, lest it may be supposed that we are apprehensive of our personal safety.</p>
            <div className="speaker" id="sp4606"><span className="persName">Mr. TURNER</span>, of Jackson—</div>
            <p className="p-in-sp">I will state that yesterday evening I went round the Capitol and listened for a time in front of the windows of the Hall, but could not hear a word.</p>
            <div className="speaker" id="sp4607"><span className="persName">Mr. BORST</span>—</div>
            <p className="p-in-sp">I am satisfied they cannot hear members speaking on this side of the building. They may hear members speaking on that side. If the windows are let down, there need be no apprehension of being heard.</p>
            <div className="speaker" id="sp4608"><span className="persName">Mr. JAS. B. DORMAN</span>, of Rockbridge—</div>
            <p className="p-in-sp">There will be no necessity of letting the windows down, as far as I am concerned.</p>
            <PageNumber num={443} />
            <p className="p-in-sp">I would ask the withdrawal of the motion of the gentleman from Smyth [<span className="persName">Mr. SHEFFEY</span>] for the purpose of correcting a statement which he made in regard to the Governor of the Commonwealth.</p>
            <div className="speaker" id="sp4609"><span className="persName">Mr. SHEFFEY</span>—</div>
            <p className="p-in-sp">I withdraw my motion.</p>
            <div className="speaker" id="sp4610"><span className="persName">Mr. DORMAN</span>—</div>
            <p className="p-in-sp">I think a great deal has been said on both sides in which there is a great deal of truth. This whole debate seems to have been brought on on account of the Executive recommendation. It is, in my judgment, entirely premature. The Governor sends us a request that we shall give him information upon this subject. My friend from Lynchburg [<span className="persName">Mr. SPEED</span>]
              moves a resolution accordingly; but I suppose it is not known to the Governor that we have now regularly authorized commissioners who are conferring with the commissioner from the Confederate States, and who will determine the policy which we shall pursue in the future. I would say for one, that I recognize it as a supreme political and military necessity that we should have possession
              of the city of Washington as soon as possible. But we cannot determine upon that until we know the relations in which we stand to the Confederate States. I hope, therefore, the gentleman from Lynchburg
              [<span className="persName">Mr. SPEED</span>] will withdraw his resolution and await a development of the plans which these commissioners shall determine upon.</p>
            <p className="p-in-sp">But the gentleman from Smyth [<span className="persName">Mr. SHEFFEY</span>]
              made a remark that the Governor called a volunteer force here without our authority, and now asks us to determine what our course of policy will be. Sir, we passed an ordinance of secession, from which we removed the obligation of secrecy and which was published in the newspapers. This, no doubt, has led to active movements on the part of the Federal authorities, which required a
              corresponding effort on our part. It occurred to me, that there was a special reason on the part of the Governor for this application to us. I am reliably informed that a force of 13,000 men is on the march to Harper's Ferry; and it is perfectly certain that there will be no means of camping that number, or of otherwise providing for them. But we cannot undertake to give a definite
              response to the communication of the Governor until we ascertain what the committee will do.
            </p>
            <div className="speaker" id="sp4611"><span className="persName">Mr. CAPERTON</span>, of Monroe—</div>
            <p className="p-in-sp">May I ask that the subject under consideration be passed by for a moment.</p>
            <div className="speaker" id="sp4612"><span className="persName">Mr. SPEED</span>—</div>
            <p className="p-in-sp">I am perfectly willing that it shall be passed by.</p>
            <div className="speaker" id="sp4613"><span className="persName">Mr. CAPERTON</span>—</div>
            <p className="p-in-sp">I am instructed by the committee on the suspension of debts, to report the following ordinance.</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>AN ORDINANCE to provide against the sacrifice of property and to suspend proceedings in certain cases.</p>
                <PageNumber num={444} />
                <p>Be it ordained, That no execution shall be issued from the date hereof, by any court of record or magistrate, for the sale of property; nor shall there be any sales under deeds of trust or decrees, for the payment of money, until otherwise provided by law.</p>
                <p>Where executions have issued and are now in the hands of officers, whether levied or not, if the debtor offer bond and security for the payment of the debt, interest and costs, when the operation of this ordinance ceases, the property shall be restored and the bond so taken shall be returned as in case of a forthcoming bond.</p>
                <p>
                  If the debtor offers no such bond, it shall be the duty of the officer to convene three freeholders from the vicinage, who, after being sworn, shall proceed to value the property according to what would have been its value on the 6th day of November 1860, and unless the said property shall sell for the full amount of such valuation, it shall be restored to the debtor without lien.
                </p>
                <p>Except in criminal cases or Commonwealth's prosecutions there shall be no trial of any cause requiring the intervention of a jury, but either party to such cause shall have the right, upon reasonable notice, to take the depositions of any witness, to be read de bene esse.</p>
                <p>In cases of misdemeanor, juries shall be summoned from the bystanders, and not under the law, as it now stands; and with the consent of the party prosecuted, the cause may be tried by the court.</p>
                <p>This ordinance shall not apply to liabilities upon the part of public officers either to the State, counties, municipal corporations or individuals.</p>
                <p>The time during which this ordinance is in force shall not be computed in any case where the statute of limitations comes in question.</p>
              </div>
            </div>
            <div className="speaker" id="sp4614"><span className="persName">Mr. MORTON</span>—</div>
            <p className="p-in-sp">I move that the report be laid upon the table and printed, and that the injunction of secrecy be removed so far as regards it.</p>
            <p>The motion was agreed to.</p>
            <div className="speaker" id="sp4615"><span className="persName">Mr. MACFARLAND</span>—</div>
            <p className="p-in-sp">I beg leave to submit a report from the finance committee on the subject of small notes. I have no objection to its being laid upon the table if discussion will arise from it.</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>No. 37.-AN ORDINANCE authorizing the Banks of the Commonwealth to issue notes of the denomination of one and two dollars. Passed April 26, 1861.</p>
                <p>
                  Be it ordained, That the several Banks of this Commonwealth be authorized to issue notes of the denomination of one and two dollars to an amount not exceeding five per cent. of their respective capitals, <PageNumber num={445} /> which notes may be signed by such officer or officers of said banks, respectively, as may be designated for that purpose by
                  the Board of Directors, and any of said Banks which may have preserved the notes of the denomination of one and two dollars, heretofore issued under authority of law be at liberty to circulate the same, so that their issue shall not exceed the amount authorized by this ordinance.
                </p>
                <p>This ordinance shall expire at the end of thirty days from the next meeting of the General Assembly.</p>
              </div>
            </div>
            <div className="speaker" id="sp4616"><span className="persName">Mr. FISHER</span>, of Northampton—</div>
            <p className="p-in-sp">I move to lay that ordinance on the table for the present.</p>
            <p>The motion was lost-ayes 24, noes 47.</p>
            <div className="speaker" id="sp4617"><span className="persName">Mr. BLAKEY</span>—</div>
            <p className="p-in-sp">I understood this report was introduced with the distinct understanding that if there was any discussion upon it it would be laid upon the table.</p>
            <div className="speaker" id="sp4618"><span className="persName">Mr. MACFARLAND</span>—</div>
            <p className="p-in-sp">
              The committee have no objection to laying the report upon the table. It is a matter of some moment, and, perhaps, it is better that a little time should be given to consider and examine it. But I will beg leave to say that if the Convention intends to take action on the question, it should be done as speedily as possible, for many public bodies are now engaged in issuing small notes,
              and if it is ascertained that such an ordinance as this is to be passed, this system will be discontinued.
            </p>
            <p>The question was on laying the ordinance on the table, and the vote stood-ayes 21 noes 54-no quorum voting.</p>
            <div className="speaker" id="sp4619"><span className="persName">Mr. MACFARLAND</span>—</div>
            <p className="p-in-sp">As gentlemen seem to be so much surprised at the course the matter has taken, I will, with the consent of the Convention, withdraw the report for the present.</p>
            <div className="speaker" id="sp4620"><span className="persName">Mr. AMBLER</span>—</div>
            <p className="p-in-sp">I thought it was an express understanding between him and myself that he would merely submit the report; not to act now upon it.</p>
            <div className="speaker" id="sp4621"><span className="persName">Mr. MACFARLAND</span>—</div>
            <p className="p-in-sp">I beg leave to say that there is no departure from the understanding by me. I understood him to say that the report was to consume no time; and that which it has consumed was perfectly accidental.</p>
            <div className="speaker" id="sp4622"><span className="persName">Mr. HARVIE</span>—</div>
            <p className="p-in-sp">May I make a statement to the Convention?</p>
            <p className="p-in-sp">
              The committee that has been in session all day in regard to our relations with the Confederate States, will be enabled to report to-day; and they have sent me here to suggest that when the Convention adjourns, it will not adjourn finally for the day, but that it will come back this evening to consider the subject, as it is important that the result should be communicated to Montgomery
              at once.
            </p>
            <PageNumber num={446} />
            <div className="speaker" id="sp4623"><span className="persName">Mr. JOHNSTON</span>, of Lee and Scott—</div>
            <p className="p-in-sp">I will make a point of order. A count was just now made by which it was ascertained that there is less than a quorum present. Is it competent for the house to do anything in the absence of a quorum?</p>
            <div className="speaker" id="sp4624"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">The chair would put no question until it was ascertained that there was a quorum.</p>
            <div className="speaker" id="sp4625"><span className="persName">Mr. HALL</span>, of Wetzel—</div>
            <p className="p-in-sp">I will remark that there are a number of the members engaged on committees, and it is probably in consequence of that that a quorum is not present.</p>
            <p className="p-in-sp">I would move that the Convention adjourn until 4 o'clock.</p>
            <div className="speaker" id="sp4626"><span className="persName">Mr. AMBLER</span></div>
            <p className="p-in-sp">I want to put myself right with regard to the remarks that I made, which seem to be misunderstood by the gentleman from Frederick [<span className="persName">Mr. CONRAD</span>] .</p>
            <p className="p-in-sp">I wished distinctly to place myself upon the ground that it was utterly impossible for us now to answer the questions suggested by the gentleman from Lynchburg [<span className="persName">Mr. SPEED</span>]
              , and the whole intent of what I said was to show the impropriety of making a response now. I did not mean to commit myself to any given policy. I argued against the policy of the Convention's committing itself to any policy in the state of profound ignorance in which the Convention of necessity is, and maintained that the result of the adoption of the resolution, as proposed, would be
              to commit the Convention to a policy which would of necessity bind the hands of the Commander-in-Chief. I propose to get rid of this whole difficulty by a response to the communication of the Governor. I will now submit the following resolution:
            </p>
            <p className="p-in-sp">
              "Resolved, in response to the communication of the Governor, inquiring what is to be the policy of Virginia in her military operation, That this Convention is not prepared to answer further than to say, that it is the true policy of Virginia to defend her own soil and institutions, and to co-operate with all the slaveholding States, and the Confederate States in defending and
              protecting their soil and institutions; and that she desires to form a union with the said States; but in the meantime Virginia should devote all her power to carry out the general objects and policy herein indicated."
            </p>
            <div className="speaker" id="sp4627"><span className="persName">Mr. AMBLER</span>—</div>
            <p className="p-in-sp">This response, I think, is fully as far as we are prepared to go now in indicating what our future course should be. It avoids any interferences with military questions, avoids hampering any of our military officers, gets rid of all the objections to the resolution as it stands, and leaves us free to answer all questions as they may arise.</p>
            <p className="p-in-sp">I move that as a substitute for the original resolution.</p>
            <PageNumber num={447} />
            <div className="speaker" id="sp4628"><span className="persName">Mr. CAPERTON</span>—</div>
            <p className="p-in-sp">I am satisfied that all that subject will be dealt with by the committee that is now holding its session with reference to an alliance with the Confederate States.</p>
            <p className="p-in-sp">I move, therefore, to lay the whole subject on the table.</p>
            <div className="speaker" id="sp4629"><span className="persName">Mr. FLOURNOY</span>, of Halifax—</div>
            <p className="p-in-sp">Will the gentleman withdraw his motion, for a moment, until I offer an amendment to the amendment offered by the gentleman from Louisa [<span className="persName">Mr. AMBLER</span>] ?</p>
            <div className="speaker" id="sp4630"><span className="persName">Mr. CAPERTON</span>—</div>
            <p className="p-in-sp">I will withdraw it if the gentleman renews it.</p>
            <div className="speaker" id="sp4631"><span className="persName">Mr. FLOURNOY</span>—</div>
            <p className="p-in-sp">I will renew it.</p>
            <p className="p-in-sp">I now offer the following resolution as an amendment to the amendment:</p>
            <p className="p-in-sp">
              "Resolved by this Convention, That it is the object of Virginia, under existing circumstances, to effect and secure a separation from the non-slaveholding States of the recent Union, and to defend from aggression and subjugation, herself and her sister States of the South; and her policy is to effect that object by such military movements, offensive or defensive, as may, in the
              estimation of those to whom she has entrusted the command of her forces, be deemed judicious and expedient."
            </p>
            <div className="speaker" id="sp4632"><span className="persName">Mr. AMBLER</span>—</div>
            <p className="p-in-sp">I will accept that in place of the substitute that I offered.</p>
            <div className="speaker" id="sp4633"><span className="persName">Mr. FLOURNOY</span>—</div>
            <p className="p-in-sp">In conformity with a promise which I made to the gentleman from Monroe [<span className="persName">Mr. CAPERTON</span>] , I move that the whole subject be laid upon the table.</p>
            <div className="speaker" id="sp4634"><span className="persName">Mr. SPEED</span>—</div>
            <p className="p-in-sp">I feel, Mr. President, that, after the indications that have been given of a desire to have this matter disposed of, I am acting, perhaps, in opposition to every member present, in making a claim to be heard even for a moment.</p>
            <p className="p-in-sp">I wish to disabuse the Convention of any impression which may have entered the mind of any member of it, that I had any other motive in offering this resolution than that of accomplishing what I believed to be for the public good, by fair means.</p>
            <div className="speaker" id="sp4635"><span className="persName">Mr. AMBLER</span>—</div>
            <p className="p-in-sp">I hope that the gentleman will not consider anything that I have said, as by any means intending to impugn his motives. Nothing was farther from my mind.</p>
            <div className="speaker" id="sp4636"><span className="persName">Mr. SPEED</span>—</div>
            <p className="p-in-sp">
              The various objections made justified the inference that I was about to repel. I observed members rising in haste to say that this motion was premature, urging that it was premature, and, that it was an improper interference with the authority of the Major General, and that, in fact, the resolution was offered with a purpose <PageNumber num={448} /> to
              direct matters in regard to which he alone is responsible, and in respect to which he is the most competent judge.
            </p>
            <p className="p-in-sp">
              In the face of the declaration, that this precise application was contemplated on yesterday in pursuance of a conference that led to the knowledge that some such proceeding as this would be most agreeable to the Commander-in-Chief, we are told that it is premature -premature after a declaration to the Convention that this precise line of proceeding was not only agreeable to, but
              invoked even by the Executive.
            </p>
            <p className="p-in-sp">
              You are told by one gentleman that this proceeding was instituted in pursuance of a plan of the Governor himself, who, as was stated, brought troops here from every portion of the State, and now appealed to the Convention as to what line of policy he would pursue. I presume the object was-I don't know the fact and would not charge or intimate that it is so-but the objections made in
              that connection were calculated to furnish grounds for such an inference, to create the impression that this movement was made in the interest of some persons or party, that persons and parties here might feel it to their interest to oppose it; and in that connection the idea was thrown out, that the object was to commit this Convention to a line of defensive policy.
            </p>
            <p className="p-in-sp">
              Sir, I repudiate, so far as I am concerned, any such purpose. The only view I had was to carry out a suggestion which I thought was conceived in wisdom. Why, then, is this movement premature? One objection is that it interferes with another committee; that we are not prepared now to indicate what line of policy we shall pursue, as if this was an application to indicate in detail what
              is to be our line of policy; and this objection comes from one who himself offers a resolution by way of a substitute which does indicate what is to be our line of policy.
            </p>
            <p className="p-in-sp">
              Sir, the objections made to that resolution of inquiry, which is to be subjected to the deliberations of seven gentlemen, to be selected by yourselves, whose action is afterwards to be reviewed by this Convention when it comes before us in the form of a report, do not commend themselves to my favor; and it strikes me that these objections arise from some other motives than those which
              were presented here.
            </p>
            <p className="p-in-sp">Now, sir, why should this substitute prevail? I am surrounded here by kind friends who volunteered to give me a kind advice to accept the substitute of my friend from Halifax [<span className="persName">Mr. FLOURNOY</span>]
              . Why accept it? Does it answer the very point of inquiry which has been suggested <PageNumber num={449} /> to you by the Governor of the Commonwealth? He is in daily conference and consultation with three wise and prudent gentlemen, in regard to whom we have expressed our opinions favorably; and he, no doubt, has a good opportunity of knowing what is
              best to be done for the interests of the State. What possible harm can the appointment of this committee do? What injury can the public suffer by this inquiry? It has not occurred to me how any possible detriment could enure to the public interest by any such proceeding, and those who have so strenuously objected to the resolution, have failed to show the grounds of their preferences
              for the form of proceeding which they advocate over that proposed in my resolution. Why not let the committee be appointed?
            </p>
            <p className="p-in-sp">
              It is said that we are in conference with the Commissioner from the Confederate States, and that we would be interfering with that proceeding. I cannot believe that any such result would follow from the adoption of this resolution. If this committee is appointed the two reports can be brought, and we might then consider them in connection. But, surely, when such action as this is
              refused at the hands of this Convention-action merely involving inquiry, which inquiry may be pregnant with the most vital consequences to the military operations of the country, we may well suspect the motives which actuate gentlemen in their opposition to so simple a proposition. I trust that the resolution will not be set aside by an affirmative vote upon a proposition declaring
              that we are not now prepared to indicate our policy. I do not ask that our policy shall be now indicated; but surely it cannot do any harm, if a committee of gentlemen shall confer upon this subject. I find that in place of advocating the raising of a committee, one gentleman asks that you shall indicate a policy, which is, of course, vastly more objectionable than that which he
              opposes. Another gentleman-near the gentleman from Halifax-tells you to take the form of proceedings, which he lays down; but it can readily be seen that that is no response to the call made by the Governor, nor does it meet the emergency of the occasion.
            </p>
            <p className="p-in-sp">I will not trespass further upon the Convention. I am not here to make declaration of attachment to the country. I would cheerfully contribute whatever I could, to the success of our cause; and all that I do, sir, I trust is prompted by the high considerations of public good and none other.</p>
            <div className="speaker" id="sp4637"><span className="persName">Mr. J. G. HOLLADAY</span>, of Norfolk county—</div>
            <p className="p-in-sp">This is a subject as to which I know very little, and in respect to which I desire to say very little. But I have my own opinion in reference to this matter.</p>
            <PageNumber num={450} />
            <p className="p-in-sp">
              If I understand this affair aright, sir, the information desired by the Commander-in-Chief, is information as to the policy which this Convention would think proper to be pursued. I take it for granted, therefore, that it follows as a necessary consequence, that an expression of opinion will not be a dictation such as would hamper, but rather enlighten that officer, and the better
              enable him to carry on his operations.
            </p>
            <p className="p-in-sp">
              Perhaps, sir, there ought to be some response to that communication; and how can that be made, except by the action of the Convention, in the form of some declaration, to be communicated to the commanding officer by the Convention, or by a committee appointed to confer with him. It seems to me that these are the only modes of response that could be resorted to, either by a declaration
              of the Convention itself or through a committee.
            </p>
            <p className="p-in-sp">
              Now, sir, I profess to be no military man, and I believe that I never could bq; but there is a certain common sense stand point which, I take it, every man ought to be capable of taking. Sir, your officer is presumed to be in entire ignorance as to the policy which this Convention is likely to pursue. It seems to me, from the remarks of gentlemen who have addressed this Convention,
              that everything is to be at a stand still, in a military point of view, until the negotiations now pending are completed. When are they to be completed? Is there any assurance upon this point? We are told that a committee is appointed to confer with the Vice President of the Confederate States. These negotiations may occupy some days-how many we know not; and, in the meantime, all
              military operations are to be suspended; nothing is to be done because the commanding officer is ignorant as to what the Convention requires to be done. It strikes me that there is to be co-operation between Virginia and the Confederate States and the other slave States in some manner or form. It seems to be manifest, also, from the declarations of the Vice President of the Southern
              Confederacy, that Virginia is to be the main theatre of military operations, and that we are not only to prepare for defensive but offensive warfare. Can there be any impropriety in informing that officer that we are to prepare immediately for defence, and prospectively for offence? We have appointed a Major General, and if Virginia is to be the theatre of operations, and he be the
              competent officer that he is said to be, and which I hope and believe he is, it seems to me that he may, during the pendency of these negotiations, be preparing to take his position so as to further the policy we may adopt. If I understand the object of appointing a committee, <PageNumber num={451} /> it is to confer with the commander-in-chief so as to
              make out a policy. I shall be in favor of the appointment of that committee with a view to hold this conference, and in order that steps might be taken in advance of the consummation of the negotiations now pending. It seems to me that this is eminently proper. I can conceive of no objection to such preliminary steps being taken. If this officer be a competent officer; if the
              intimations thrown out by the Vice President be fulfilled, it seems to me that we should communicate the fact to him, that we are determined to act with the other Southern States, and to enter with him into an alliance offensive and defensive.
            </p>
            <p className="p-in-sp">In view of the frequent expressions of opinion all around favoring the laying of this subject on the table, I will vote for that proposition.</p>
            <div className="speaker" id="sp4638"><span className="persName">Mr. EARLY</span>—</div>
            <p className="p-in-sp">I judge from the remarks of the gentleman from Frederick [<span className="persName">Mr. CONRAD</span>]
              , that there is some misapprehension as to the views of Gen. Lee. He said something to the committee on Military Affairs, and in conformity with his views, a resolution was directed to be reported. His idea is this: that aggression upon the private rights and private property of individuals, whether citizens of one side or the other, greatly aggravates the horrors of war and
              exasperates the feelings on both sides. The resolution was directed to be reported to the Convention, and it was placed in charge of the gentleman from Richmond City
              [<span className="persName">Mr. RANDOLPH</span>] , who was called into service yesterday with his company. That resolution reads as follows:</p>
            <p className="p-in-sp">"Resolved, That a committee of three be appointed by the President, with directions to report upon the propriety of adopting measures to prevent the illegal and improper seizure of private property, and to restore any which may have been already seized, and which may not be needed for public purposes."</p>
            <p className="p-in-sp">While I am up, I will simply state, lest the statement of the gentleman from Richmond city [<span className="persName">Mr. RANDOLPH</span>] , in reference to percussion caps, may be misunderstood, I have no doubt that the amount mentioned by the gentleman has been purchased; but I fear there has been a very wasteful disposition made of them. I have been to the Ordnance Department; and while I did not see the officer in charge, I ascertained that there is a great deficiency in this article.</p>
            <p className="p-in-sp">In regard to the subject under consideration, I apprehend that the sole object of Gen. Lee in making this suggestion was to prevent any mischief being done by aggressions upon private rights and private property. This is the resolution, the substance of which was directed to be reported on yesterday.</p>
            <PageNumber num={452} />
            <p className="p-in-sp">It will, I think, meet the views of the gentleman from Frederick [<span className="persName">Mr. CONRAD</span>] .</p>
            <p>On motion of <span className="persName">Mr. TURNER</span> of Jackson, the Convention took a recess until 4 o'clock, P. M.</p>
            <div className="session" id="vsc1965.v4.2.9.1">
              <h3><span className="headingNumber">1.9.1. </span><span className="head">EVENING SESSION</span></h3>
              <p>The Convention re-assembled at the appointed hour.</p>
              <div className="speaker" id="sp4639"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The pending question was upon the amendment offered by the gentleman from Halifax [<span className="persName">Mr. FLOURNOY</span>] , to the substitute offered by the gentleman from Louisa [<span className="persName">Mr. AMBLER</span>] , for <span className="persName">Mr. SPEED</span>'S resolution. The amendment has been accepted by the gentleman from Louisa, and that now stands as the competing proposition to that offered by the gentleman from Lynchburg [<span className="persName">Mr. SPEED</span>] . A motion has been made by the gentleman from Monroe [<span className="persName">Mr. CAPERTON</span>] , to lay dip whole subject on the table, and that is the motion directly before the House.</p>
              <p>The question having then been taken on the motion to lay on the table, it was decided in the affirmative.</p>
              <div className="speaker" id="sp4640"><span className="persName">Mr. FISHER</span>—</div>
              <p className="p-in-sp">I desire to offer the following resolution:</p>
              <p className="p-in-sp">"Resolved, That in all future appointments to the offices of major, colonel, brigadier general, or any other officers of equivalent or higher grade, by the Executive, such appointments shall be confirmed by this Convention, or shall only be valid when made during the recess of tile Convention, during such recess."</p>
              <p className="p-in-sp">I desire to say, that I had no idea, until I was informed of the fact on yesterday, that this Convention conferred upon any human being the power without control of making appointments of Brigadier Generals, Inspector Generals, Colonels and Majors. This is a power even greater than is possessed by the President of the United States.</p>
              <div className="speaker" id="sp4641"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">We will have that subject up immediately, in the form of a report from the Committee on Military Affairs.</p>
              <div className="speaker" id="sp4642"><span className="persName">Mr. FISHER</span>—</div>
              <p className="p-in-sp">
                I am very glad to hear from the gentleman that the subject is taken in charge by the Military Committee. I don't mean to cast any reflection upon the Executive in offering this resolution; for if the Governor was my bosom friend I would not consent that he should exercise such power without being subject to the supervision of this Convention. I had supposed that the same principle
                applied to these offices referred to in the resolution as to the office of Major General. I don't know now what committee brought in the ordinance upon the subject of the Governor's power in this respect-whether it <PageNumber num={453} /> was the Committee on Military Affairs or not. I take it for granted, however, that the omission of a clause
                reserving the supervisory power which it is the object of my resolution to secure, was the result of an oversight on the part of those who prepared the ordinance.
              </p>
              <p className="p-in-sp">
                The ordinance authorizing the appointment of these officers was, I believe, passed on last Sabbath; and, if I mistake not, its further execution was ordered to be suspended by the Convention. Whether this is so or no, I am not now fully aware; but I want this body to declare that no such dictatorial power shall be exercised by the Executive of this Commonwealth. I have no idea that
                it shall be in the power of the Governor of this Commonwealth, whoever he may be, to appoint individuals to these high offices in which the whole Commonwealth are deeply interested.
              </p>
              <p className="p-in-sp">
                I hold that these offices ought to be filled with individuals whose appointment would prove acceptable to the people of the whole State, and this object can best be attained by a supervisory action on the part of this Convention, whose members are best competent to judge of the character of the appointments, and their conformity to the sentiment of those whom they represent.
              </p>
              <p className="p-in-sp">
                Those offices are too important to the great interests of the Commonwealth; they are too important to the success of our military operations to be filled by one individual, who may be governed in his selections by mere personal considerations. I do not charge any such motive upon the Executive; but the presumption is not a violent one; for he is, after all, but human nature, like men
                who have been known to yield to such considerations in the exercise of important trusts. When these appointments shall receive the confirmation of this Convention, representing every portion of the Commonwealth, it must be presumed, that they will give satisfaction, and prove efficient. But I tell you, Mr. President, if you authorize an individual, however elevated he may be, to
                appoint persons to important offices of the character of these referred to, without any control, the appointments will not give satisfaction. The President of the United States has never exercised such power, and the Constitution never conferred it upon him. We are not in such a dilemma, as yet, as to make it necessary for us to appoint a dictator; and, in using this term, I beg
                again to say, that I mean to cast no reflection upon the Executive. But I say that the concession of this uncontrollable power of appointment, is conferring upon him a sort of dictatorial power which I am by no means willing to yield to any human being.
              </p>
              <p className="p-in-sp">I shall ask for the adoption of the resolution unless the Committee <PageNumber num={454} /> on Military Affairs, submit a report on the subject. If that report is not such as I like, I shall offer this as a substitute.</p>
              <div className="speaker" id="sp4643"><span className="persName">Mr. SCOTT</span>, of Powhatan—</div>
              <p className="p-in-sp">The gentleman from Northampton [<span className="persName">Mr. FISHER</span>] is mistaken in supposing that this has been the work of the committee in any manner or form whatever.</p>
              <p className="p-in-sp">
                .The ordinance under which Generals are appointed by the Executive, was passed before the organization of the military committee, and they therefore can have no responsibility in the matter. An ordinance was adopted by this Convention declaring that the Executive of this commonwealth should receive volunteer companies by companies, and should organize them into regiments, brigades
                and divisions and appoint the necessary officers to the positions thus created.
              </p>
              <p className="p-in-sp">
                Under that ordinance which was passed before the committee was appointed at all, the Executive appointed one Major General, and has appointed, to my knowledge, two Brigadier Generals of Volunteers. I say nothing in regard to the fitness of the gentlemen appointed to these offices. Gen. Gwynn was appointed Major General, and Gens. Cocke and Ruggles Brigadier Generals.
              </p>
              <p className="p-in-sp">Whether any more have been appointed I cannot say. In regard to these appointment, I have nothing to say.</p>
              <p className="p-in-sp">As to the Inspector General, I know nothing about it, nor am I aware of the authority under which the appointment was made.</p>
              <div className="speaker" id="sp4644"><span className="persName">Mr. MORTON</span>, of Orange and Greene—</div>
              <p className="p-in-sp">My idea in relation to that matter is, that the responsibility of the Military Department is upon Major General Lee, who is Commander-in-Chief, and that these appointments, from Colonel up, ought to receive his sanction and approval.</p>
              <div className="speaker" id="sp4645"><span className="persName">Mr. DORMAN</span>, of Rockbridge—</div>
              <p className="p-in-sp">It seems to me that this Convention has been going on in a very slip-shod way. Ordinances are frequently passed here which we do not hear read, and the meaning of which we frequently do not know.</p>
              <p className="p-in-sp">We are ignorant of the order of business, so rapid are subjects accumulating, and so little attention does there seem to be paid to system or order in our proceedings.</p>
              <p className="p-in-sp">I propose to offer a resolution to settle the order of business.</p>
              <p className="p-in-sp">It is the practice, I believe, in legislative bodies, I know such is the practice in the House of Delegates-to have upon the table a list of all propositions pending; a regular list of the business in order, and to call over each morning the list of committees, when such as are prepared to report can do so without any difficulty.</p>
              <PageNumber num={455} />
              <p className="p-in-sp">With a view to establish some such order of business, I will offer a resolution.</p>
              <div className="speaker" id="sp4646"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">It is not in order at this time.</p>
              <div className="speaker" id="sp4647"><span className="persName">Mr. DORMAN</span>—</div>
              <p className="p-in-sp">I move to lay the pending resolution on the table for the present.</p>
              <div className="speaker" id="sp4648"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">
                I want to call the attention of the Convention to this fact, that the Military Committee have reported but one ordinance, which has been adopted by this body: that is, the ordinance for the appointment of a commander of the military and naval forces, which resulted auspiciously to the interests of the Commonwealth. They reported another ordinance, for which a substitute was adopted
                under circumstances of haste and excitment. That substitute has got us into a state of difficulty now. The ordinance under which these appointments have been made, was adopted on the night after the ordinance of secession was passed, upon the motion of the gentleman from the City of Richmond
                [<span className="persName">Mr. RANDOLPH</span>] . I beg leave to call the attention of the Convention to the fact, that that ordinance was pressed through by gentlemen agreeing in sentiment with the gentleman from Northampton [<span className="persName">Mr. FISHER</span>]
                , without their being willing for a military committee to take this matter into consideration. I had myself prepared a resolution as a substitute for it authorizing the appointment of a committee, and inviting officers of the army, who were natives of Virginia, to return from the service of the United States, with the assurance that they would be provided for but gentlemen agreeing
                with him would not agree to that proposition. We were told that there were officers of the navy now ready to tender their services to the State, and it was asked with the air of half astonishment and indignation, "were they to be answered by the appointment of a committee?"
              </p>
              <p className="p-in-sp">Now, the military committee is prepared to report another ordinance, which was referred back, and that, too, after consultation with the Commander-in-Chief.</p>
              <p className="p-in-sp">
                It is an ordinance for the purpose of organizing a staff department for our forces, which is the most pressing question that we have now to consider; and gentlemen have not been willing to let the committee make that report. The question in regard to the mode of appointments in reference to which the gentleman from Northampton has just offered a resolution, will come up upon the
                consideration of that ordinance.
              </p>
              <p className="p-in-sp">
                We have now under consideration another question, with regard to the organization of an army and the appointment of general officers <PageNumber num={456} /> to command that army, together with a staff department. I mean by a staff department an Adjutant General's Department, a Quartermaster's Department, a Subsistence Department, a Medical Department,
                a Pay Department, &amp;c. On last Sunday, when the ordinance that was adopted was pending, I offered a substitute, providing for the appointment of two Major Generals and four Brigadier Generals by the Governor, by and with the advice and consent of this Convention; but it was not adopted.
              </p>
              <p className="p-in-sp">
                Now, sir, if gentlemen will allow the military committee to have an opportunity to report, they can make any propositions they choose in regard to the appointing power, by way of amendments to the report. We are ready to report an ordinance for the purpose of organizing a staff department. We have agreed upon a mode of appointments which seemed to us the best under the circumstances.
                When that report is made, the question of appointments will come up, so far as these departments are concerned.
              </p>
              <p className="p-in-sp">I beg the gentleman from Northampton [<span className="persName">Mr. FISHER</span>] , to withdraw his resolution and allow the military committee to report.</p>
              <div className="speaker" id="sp4649"><span className="persName">Mr. TURNER</span>, of Jackson—</div>
              <p className="p-in-sp">I desire to know what is before the House.</p>
              <div className="speaker" id="sp4650"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Secretary will report the resolution.</p>
              <p>The resolution offered by <span className="persName">Mr. FISHER</span> was again read by the Secretary.</p>
              <div className="speaker" id="sp4651"><span className="persName">Mr. TURNER</span>—</div>
              <p className="p-in-sp">
                Some days ago, an ordinance was, I believe, adopted calling upon the officers of the army and navy, residents and natives of Virginia, to tender their services to this State, assuring them that their relative rank in the United States service would not be reversed. I understood, however, that all appointments to be made were subject to the confirmation of the Convention. I may be
                mistaken, but that was my impression.
              </p>
              <div className="speaker" id="sp4652"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">The Convention will recollect that at the time that that very subject was under consideration, I stated that the rule of appointment adopted in that ordinance must lead to great mischief ; that there were many officers of inferior rank who were more competent than many of those who ranked above them.</p>
              <div className="speaker" id="sp4653"><span className="persName">Mr. TURNER</span>—</div>
              <p className="p-in-sp">I trust that something will be done immediately to alter the mode of appointment, for a great deal of dissatisfaction seems to exist because of it. I am willing to vote for any proposition that can effect this change and remove the dissatisfaction which now prevails in regard to this loose and unguarded mode of appointment.</p>
              <p className="p-in-sp">I understand that the resolution of the gentleman from Northampton [<span className="persName">Mr. FISHER</span>]
                , if adopted, will remedy this difficulty by making <PageNumber num={457} /> all appointments by the Governor subject to the confirmation of this Convention. I was under the impression, when I voted for the ordinance creating this staff department and conferring the appointing power upon the Governor, that it embodied a reservation of this character.
                But it seems not, and I am, therefore, anxious that the defect should at once be supplied. With that view, I shall vote for the resolution of the gentleman from Northampton
                [<span className="persName">Mr. FISHER</span>] .</p>
              <div className="speaker" id="sp4654"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">I move that the resolution offered by the gentleman from Northampton be referred to the Military Committee.</p>
              <div className="speaker" id="sp4655"><span className="persName">Mr. FISHER</span>—</div>
              <p className="p-in-sp">I desire to say a word on that motion.</p>
              <p className="p-in-sp">
                That resolution is worth nothing if referred to the Military Committee. I mean to say that it will not suspend the action of the Executive; and that is what I want to accomplish. I have no doubt that the Military Committee will discharge its duty faithfully; but it cannot do everything of an important and pressing character within the limited period which the necessity of particular
                cases require. They are bound to take up and consider<span className="note" id="Note45"><span className="noteLabel">2</span>Something like "matters" or "subjects" has probably been omitted here.</span> before them in regular order; and in view of the amount of business, which I know has precedence of this, several days would elapse before they could take up this subject for consideration;
                and it may be a week or even fortnight before they could bring in an ordinance on the subject.
              </p>
              <div className="speaker" id="sp4656"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">A resolution has passed this body suspending the exercise of the appointing power by the Executive.</p>
              <div className="speaker" id="sp4657"><span className="persName">Mr. MORRIS</span>, of Caroline—</div>
              <p className="p-in-sp">It is only in regard to the ordinance passed last Sunday that that resolution was adopted. It does not relate to appointments.</p>
              <div className="speaker" id="sp4658"><span className="persName">Mr. FISHER</span>—</div>
              <p className="p-in-sp">
                I don't know anything about military tactics any more than I do about parliamentary tactics; but I have some conception I believe upon the great principles of a free and enlightened government, and how it ought to administered. I have no idea, when I offer a proposition which I regard to be fair and just, nay, indispensable, in view of our present anomalous condition in the matter of
                appointments, to yield to the influences which my friends would bring to bear upon me. Yesterday, sir, I was induced to yield to the solicitation of my friends in consenting to the reference of a proposition of mine to a committee; but I cannot now consent to a similar disposition of this resolution.
              </p>
              <p className="p-in-sp">
                Why refer this to a committee? Do you require to be informed that dictatorial power should not be exercised by the chief Executive? Will you be better informed after that committee reports as to the <PageNumber num={458} /> great principles upon which human liberty rests? No, sir. I take it for granted that every gentleman in this body is prepared to
                say, whether he is willing for the chief Executive officer of the State to make appointments to these officers named in the resolution without confirmation by this body, or no. If they are prepared to reject that resolution, I shall, I hope, like a good Christian, submit to the power of the majority. Meanwhile, I must say that such action would be far from convincing me that I was in
                error in this matter; but being a law-loving, a law-abiding citizen, I would yield to the influence of members. Sir, if there was no other man in this Commonwealth who would raise his voice against this arbitrary and dictatorial power, I would, solitary and alone, be found denouncing it. If on the flat of my back, I would raise my finger in condemnation of so dangerous an exercise of
                power; and I do not mean that this resolution, which proposes to remedy an evil of this character, shall be over-slaughed by its reference to a committee already too much burdened with business.
              </p>
              <p className="p-in-sp">
                This proposition, addressing itself to the common sense of every gentleman, it addresses itself to the consideration of this enlightened body; and, sir, if it be true that the appointments already made, but about which I mean not now to speak, have created dissatisfaction, there is still greater reason for prompt action on the part of this body. I am satisfied the Governor of the
                Commonwealth will yield a willing deference to the action of this Convention, and not desire to exert a power which would be subversive in any degree of the great principles upon which our institutions rest.
              </p>
              <p className="p-in-sp">
                I, therefore, cannot consent to a reference of this resolution to the Committee on Military Affairs, or any other committee. The Committee on Military Affairs, I doubt not, will bring in an ordinance that will be satisfactory to this body; but, in the meantime, the action of this body, if the resolution shall be adopted-if the principles embodied in it be carried out, will remove any
                unpleasant feelings on the part of members of this Convention towards the action of the Executive, if any such now exists. I am exceedingly anxious that every Department in this Government-the Legislative, the Executive, and, as far as may be, the judicial, and the people in their respective counties-may act in harmony and with one voice, with one heart and one sentiment.
              </p>
              <p className="p-in-sp">If that resolution is adopted it can do no harm, and it may prevent the unpleasant feelings to which I have referred. I have no unpleasant feelings myself; but I am unwilling that the power delegated <PageNumber num={459} /> to the Executive shall be exercised without some controlling voice by this Convention.</p>
              <div className="speaker" id="sp4659"><span className="persName">Mr. TREDWAY</span>, of Pittsylvania—</div>
              <p className="p-in-sp">I am exceedingly anxious to hear from this Military Committee; and, not being informed on military matters, and believing that there is a necessity for action in matters appertaining to the Department, in reference to which that committee was specially intended to act, I call the previous question.</p>
              <p>The call was sustained and the main question ordered to be put, being the motion made by <span className="persName">Mr. MORTON</span> to lay the resolution on the table.</p>
              <div className="speaker" id="sp4660"><span className="persName">Mr. FISHER</span>—</div>
              <p className="p-in-sp">I call for the yeas and nays upon that motion.</p>
              <p>The call was not sustained, and the question being taken by a count, resulted-ayes 49, noes 21. No quorum voting.</p>
              <p>After a brief interval the question was again taken and resulted-ayes 57, noes 22.</p>
              <p>So the motion to refer was carried in the affirmative.</p>
              <div className="speaker" id="sp4661"><span className="persName">Mr. SCOTT</span>, of Powhatan—</div>
              <p className="p-in-sp">I beg leave to make a report in behalf of the Committee on Military Affairs.</p>
              <p className="p-in-sp">
                I will state that the committee have had under consideration the ordinance for the organization of an army of 10,000 men. I believe it has been agreed to unanimously by the committee, and with the approbation of the Commander-in-Chief, who was before the committee, that such an army as was referred to in the ordinance, which we have already presented, was necessary. The details of
                that ordinance, however, are not yet finished; and we desire to perfect it before we shall submit it. We are now only prepared to report an ordinance for organization of the staff.
              </p>
              <p>The ordinance reads as follows:</p>
              <div className="floatingText">
                <div className="floatingText_body">
                  <p>AN ORDINANCE Amending and Re-enacting the Ordinance Adopted</p>
                  <p>on the twenty-first instant, for the Organization of Staff Depart-</p>
                  <p>ments for the Military Forces of the State, Passed April 24, 1861.</p>
                  <p>Be it ordained, That the Governor of the Commonwealth be and he is hereby authorized and required to organize, as the exigencies of the service may require :</p>
                  <p>An adjutant general's department, with one adjutant general of the rank of colonel, two assistant adjutant generals with the rank of major, and four assistant adjutant generals of the rank of captain.</p>
                  <p>A quartermaster's department, with one quartermaster general of the rank of colonel, one assistant quartermaster general of the rank of lieutenant colonel, two quartermasters with the rank of major, and four assistant quartermasters, with the rank of captain.</p>
                  <PageNumber num={460} />
                  <p>A subsistence department, with a commissary general of the rank of colonel, an assistant commissary general of the rank of lieutenant colonel, two commissaries of the rank of major, and four assistant commissaries of the rank of captain.</p>
                  <p>A medical department, with a surgeon general of the rank of colonel, ten surgeons of the rank of major, and ten assistant surgeons of the rank of captain.</p>
                  <p>A pay department, with a paymaster general of the rank of colonel, and three paymasters, with the rank of major.</p>
                  <p>An engineer corps, with one colonel, one lieutenant colonel, two majors, six captains, two first lieutenants and two second lieutenants.</p>
                  <p>To this corps shall be attached two companies of sappers and miners, to each of which shall be assigned one captain, one first lieutenant and one second lieutenant of engineers.</p>
                  <p>
                    The adjutant general's department above provided for, shall be the adjutant general's department for all the military forces which shall be called into service in the field, camp or garrison, and shall be entirely separate and independent of the adjutant general's department of the militia, and the quartermaster's department, the subsistence department, the medical department,
                    and the pay department shall take charge of all the operations of their respective departments for all branches of the military service.
                  </p>
                  <p>The governor shall appoint the officers of the several departments above named, including the engineer corps, by and with the advice and consent of the Advisory Council, established under an ordinance of this Convention, and he shall commission the same.</p>
                  <p>This ordinance shall be in force from its passage, and shall be in the lieu and stead of the ordinance adopted on the twenty-first inst.</p>
                </div>
              </div>
              <p><span className="persName">Mr. SCOTT</span>, from the same committee, also reported the following ordinance :</p>
              <div className="floatingText">
                <div className="floatingText_body">
                  <p>AN ORDINANCE to construct a line of Telegraph.</p>
                  <p>Be it ordained, That the Governor be authorized to have constructed a line of telegraph from the city of Richmond to such points on the James river as he may select, and to defray the cost of the same from such monies as may be raised for military purposes.</p>
                </div>
              </div>
              <div className="speaker" id="sp4662"><span className="persName">Mr. SCOTT</span>—</div>
              <p className="p-in-sp">The committee have directed me to report also "An ordinance to authorize the Governor to exempt certain persons from military duty."</p>
              <p className="p-in-sp">"Be it ordained that the Governor be, and he is hereby authorized to excuse from military service such number of the employees of each railroad company as may be necessary to operate the road, and to <PageNumber num={461} /> organize them for the defence of the line of such road and of its termini."</p>
              <div className="speaker" id="sp4663"><span className="persName">Mr. SCOTT</span>—</div>
              <p className="p-in-sp">I desire, at a future time, to offer the following resolution, which I will now read for the information of the Convention. It is not from the Committee on Military Affairs:</p>
              <p className="p-in-sp">"Resolved, That hereafter the first business with the Convention each day shall be a call by the President upon the chairman of each committee for such reports as he may have to submit."</p>
              <p>The question being on the adoption of the first ordinance reported from the Committee on Military Affairs-</p>
              <div className="speaker" id="sp4664"><span className="persName">Mr. TURNER</span>, of Jackson—</div>
              <p className="p-in-sp">I desire to offer this amendment, to come in after the word "Convention," in the last paragraph but one : "subject, however, to the approval of this Convention."</p>
              <div className="speaker" id="sp4665"><span className="persName">Mr. DORMAN</span>, of Rockbridge—</div>
              <p className="p-in-sp">
                This satisfies me of the importance of altering the system of business heretofore pursued. It is impossible for the Convention to pass intelligibly upon business here. I desire to determine this question, by a motion which I shall make, at this point. The question of a military organization is of the very highest importance to us, and we should have an opportunity to examine it
                fully, and consider it with care and caution. I move that this ordinance be laid upon the table and printed.
              </p>
              <div className="speaker" id="sp4666"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">I would ask the gentleman to withdraw his motion and I will renew it.</p>
              <div className="speaker" id="sp4667"><span className="persName">Mr. DORMAN</span>—</div>
              <p className="p-in-sp">I withdraw it.</p>
              <div className="speaker" id="sp4668"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">It is absolutely necessary that we shall have at least some of this staff department organized. There have been a large number of volunteers ordered to this city. My friend from Bedford [Mr. GoonE] , and myself were engaged yesterday evening in endeavoring to find quarters for some companies that were ordered here from the city of Lynchburg, and were unable to procure any until a late hour of the night, and then we were only enabled to provide them with empty rooms.</p>
              <p className="p-in-sp">It is absolutely necessary to have a Quartermaster's Department, and we should organize it at once. This whole scheme was first proposed by the Superintendent of the Military Institute [Col. Smith]
                and was since submitted to the Commander-in-Chief. The organization of a Staff Department has met with his approbation. The only question now is, as to the mode of appointment. It will be perceived that the ordinance provides for the organization of the Departments through the Executive, by and with the advice and consent of the Advisory Council; but this mode of appointment does not
                seem <PageNumber num={462} /> satisfactory to some gentlemen, and the question now is, whether we will make these appointments subject to the ratification of this Convention, or to that of the Advisory Council. The Convention will not be long in session, and some inconvenience may result from requiring the appointments to be confirmed by it.
              </p>
              <div className="speaker" id="sp4669"><span className="persName">Mr. TURNER</span>—</div>
              <p className="p-in-sp">It has been suggested that a Committee of Safety be appointed, and that, I presume, will obviate the difficulty which the gentleman indicates.</p>
              <div className="speaker" id="sp4670"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">I have nothing to do with a Committee of Safety.</p>
              <p className="p-in-sp">
                Now, sir, some of these departments may not be required at once. An Engineer department may be organized at leisure. A Pay Department may not be organized for some time; but it is necessary to have a Quartermaster's Department forthwith. If we were to submit the nominations to this Convention, we should have interminable debates. We have now an Advisory Council, composed of the
                President of the Court of Appeals, the Superintendent of the Military Institute, and a member of the U. S. Navy, of world-wide reputation; and this Convention may be satisfied that no appointment will go through that council that will not be satisfactory to the Commonwealth.
              </p>
              <p className="p-in-sp">I shall, according to promise, renew the motion to lay the ordinance upon the table and print it; but shall vote against it.</p>
              <div className="speaker" id="sp4671"><span className="persName">Mr. DORMAN</span>—</div>
              <p className="p-in-sp">There is no necessity of renewing it. I will withdraw it.</p>
              <div className="speaker" id="sp4672"><span className="persName">Mr. TURNER</span>, of Jackson—</div>
              <p className="p-in-sp">
                I don't intend to detain the Convention by any extended remarks upon this question. My idea was, that in case of the adjournment of this Convention, the appointments made by the Advisory Council during the recess of this Convention would occupy the same position as appointments made by the President of the United States during the recess of Congress-that they would hold good until
                confirmed or rejected by the Convention.
              </p>
              <p className="p-in-sp">
                This is the state of things with reference to all appointments made by the President of the United States; and why should we not have a supervisory power over our Executive, just as the United States Senate has over the President. We are now acting as an independent government, and acting thus, I desire that we shall have all control over the Executive Department of the Government.
              </p>
              <p className="p-in-sp">The gentleman from Franklin [<span className="persName">Mr. EARLY</span>]
                has spoken of the experience and capacity of the members composing the advisory council. About that I have not a word to say; but I would say that the members of this Convention, coming as they have come from all <PageNumber num={463} /> parts of the State, are more likely to know how far the appointments to be made may comport with the interests and
                sentiments of the State at large. One of the gentlemen composing that council, I mean Capt. Maury, has no knowledge of the State, nor of the men who ought to be appointed to the offices which this ordinance proposes to create. Another member of that council, Judge Allen, has been presiding in the Court of Appeals, and has had his attention exclusively confined to the duties of his
                office. He is, therefore, not familiar with the material suited to these offices.
              </p>
              <div className="speaker" id="sp4673"><span className="persName">Mr. WYSOR</span>, of Pulaski—</div>
              <p className="p-in-sp">I call the previous question upon the pending amendment.</p>
              <p>The call was sustained and the main question ordered to be put, whereupon the vote was taken on the amendment offered by <span className="persName">Mr. TURNER</span>, and it was decided in the negative.</p>
              <p>The question then recurred upon the ordinance as reported by the Committee on Military Affairs, and it was adopted.</p>
              <p>
                The question then recurred upon the second ordinance reported by the committee in relation to the construction of a telegraph line from Richmond to some point on York river.<span className="note" id="Note46"><span className="noteLabel">3</span>This should presumably read "James river."</span>
              </p>
              <div className="speaker" id="sp4674"><span className="persName">Mr. SEAWELL</span>, of Gloucester—</div>
              <p className="p-in-sp">
                I would suggest to the Military Committee which reported this ordinance, that it would be better to direct that telegraph line to be erected at some point on York river, for this reason; if it is erected on James river you will receive no information by it, that you cannot receive from Norfolk. A telegraph line erected on James river will give you no information from the North or
                from sea while Fort Monroe remains in the hands of the enemy. If you erect it at Yorktown, which is only twenty-five miles from the Capes and seven or eight miles from Chesapeake bay, you are open to all the information you can get from the States North and from the ocean.
              </p>
              <div className="speaker" id="sp4675"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">I would suggest to the gentleman, if it would not be better to say "to such point or points on York river and James river, as the Governor may deem best."</p>
              <div className="speaker" id="sp4676"><span className="persName">Mr. SEAWELL</span>—</div>
              <p className="p-in-sp">I prefer the proposition which I have suggested. I propose to strike out "James river" and insert "York river."</p>
              <div className="speaker" id="sp4677"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">I suppose the gentleman inserts the words "York river or James river."</p>
              <div className="speaker" id="sp4678"><span className="persName">Mr. SEAWELL</span>—</div>
              <p className="p-in-sp">I have no objection to that.</p>
              <div className="speaker" id="sp4679"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">The idea of the committee is to keep open the communication with the bay.</p>
              <div className="speaker" id="sp4680"><span className="persName">Mr. RANDOLPH</span>, of Richmond—</div>
              <p className="p-in-sp">I think the gentleman from Gloucester <PageNumber num={464} /> [<span className="persName">Mr. SEAWELL</span>] , is right about the terminus; but the reason that we left it unsettled was, that the report would meet with opposition; but now it seems that opposition comes because we failed to fix the terminus.</p>
              <p className="p-in-sp">I hope the amendment of the gentleman from Gloucester [Mr. SEAWELL] , will prevail.</p>
              <div className="speaker" id="sp4681"><span className="persName">Mr. SEAWELL</span>—</div>
              <p className="p-in-sp">I will leave the amendment as I offered it originally, namely to substitute "York river" for "James river."</p>
              <p>The amendment was agreed to, and the ordinance as amended was then adopted.</p>
              <p>The question then recurred upon the third ordinance, which relates to the exemption of a certain number of railroad employees from military service; and it was adopted.</p>
              <div className="speaker" id="sp4682"><span className="persName">Mr. SCOTT</span>, of Powhatan—</div>
              <p className="p-in-sp">I offer the following resolution :</p>
              <p className="p-in-sp">"Resolved, That hereafter the first business of the Convention each day shall be a call by the President of the Convention upon the chairman of each committee for such reports as he may have to submit."</p>
              <div className="speaker" id="sp4683"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">I would suggest to the gentleman so to amend his resolution as to direct the Secretary to make out a list of each committee, and have it called over the first thing each morning.</p>
              <div className="speaker" id="sp4684"><span className="persName">Mr. SCOTT</span>—</div>
              <p className="p-in-sp">I accept the suggestion.</p>
              <div className="speaker" id="sp4685"><span className="persName">Mr. DORMAN</span>—</div>
              <p className="p-in-sp">I offer the following as a substitute for the resolution of the gentleman from Powhatan :</p>
              <p className="p-in-sp">
                "Resolved, That the Clerk prepare a list of the committees heretofore appointed and not discharged by the Convention, and add thereto from time to time as other committees may be appointed, and that he shall also prepare a list of the ordinances which have been or may hereafter be adopted for the use of members, and that he shall each day call over the list of committees before the
                Convention proceeds to the discharge of any other business."
              </p>
              <div className="speaker" id="sp4686"><span className="persName">Mr. SCOTT</span>—</div>
              <p className="p-in-sp">I accept that as a substitute for my resolution.</p>
              <div className="speaker" id="sp4687"><span className="persName">Mr. AMBLER</span>—</div>
              <p className="p-in-sp">I would suggest that they can get the rules of the House of Delegates and prepare a resolution in conformity with a rule to be found there that works well, which is to call over the list of committees first every morning. Instead of getting a new rule, get a rule which is already decided upon, and has been known to work efficiently.</p>
              <div className="speaker" id="sp4688"><span className="persName">Mr. KILBY</span>—</div>
              <p className="p-in-sp">It is not the custom of the House of Delegates that the Clerk shall call over the list of committees.</p>
              <div className="speaker" id="sp4689"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">I know that is the custom in the Senate.</p>
              <PageNumber num={465} />
              <div className="speaker" id="sp4690"><span className="persName">Mr. CONRAD</span>, of Frederick—</div>
              <p className="p-in-sp">I beg leave to offer the following resolution :</p>
              <p className="p-in-sp">"Resolved, That the committee on Military Affairs be instructed to inquire into the expediency of authorizing the Winchester and Potomac Railroad Company to extend their road from Winchester to Strasburg, provided the same can be done without expense to the State."</p>
              <div className="speaker" id="sp4691"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">I would suggest to the gentleman that he had better refer it to a special committee. The Committee on Military Affairs have as much as they can do at present.</p>
              <div className="speaker" id="sp4692"><span className="persName">Mr. CONRAD</span>—</div>
              <p className="p-in-sp">
                I would be very glad to do so. It would answer my purpose individually, perhaps, better; but I think it will be found that this is a subject of military importance, and it is upon that ground that I present it to the Convention. I think the gentleman will find that it is not by any means an idle or trivial subject. I understand that a great deal of expense and delay has been incurred
                because of the want of railroad connection between Strasburg and Winchester for the transportation of troops and muntitions of war.
              </p>
              <p className="p-in-sp">This connection, I may say, has been a subject of dispute in the Legislature for some years, owing to some rival interest which opposed it. It ought to have been made long ago, and the Company, as I understand, are willing to make it now, if the privilege is given.</p>
              <div className="speaker" id="sp4693"><span className="persName">Mr. JAMES BARBOUR</span>, of Culpeper—</div>
              <p className="p-in-sp">
                That proposition has been before the Legislature at every session in which I have served, and has been invariably defeated, because it came in collision with other interests. It was presented at the last session as a war measure, and was defeated. It seems to me that this Convention, in acting upon this subject, is undertaking legislative duties which do not properly come within its
                sphere of action.
              </p>
              <div className="speaker" id="sp4694"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">I know nothing of the subject of railroads generally, but I would say that new interests and pressing emergencies have arisen which would make this necessary, according to my judgment. I protest against this being referred to the Military Committee.</p>
              <div className="speaker" id="sp4695"><span className="persName">Mr. CONRAD</span>, of Frederick—</div>
              <p className="p-in-sp">I accept the suggestion of the gentleman, and move, if it be in order, that the subject be referred to a committee of five.</p>
              <div className="speaker" id="sp4696"><span className="persName">Mr. TURNER</span>, of Jackson—</div>
              <p className="p-in-sp">I trust that this will not be referred to a committee, but that it will be voted down at once.</p>
              <div className="speaker" id="sp4697"><span className="persName">Mr. MONTAGUE</span></div>
              <p className="p-in-sp">I have just one word to say on this subject.</p>
              <p className="p-in-sp">
                There are some railroads and telegraph lines in this State of which this Convention will have to take control, if you intend to carry on <PageNumber num={466} /> the war. I know that this railroad extension now asked for has been a subject of controversy in our Legislature for ten years, and I always thought that these people ought to have the right to
                build this road with their own money. It is now a great military necessity, and I hope it will be the pleasure of the Convention to grant the right of way for this road. If this connection is not formed, how will you have communication with the North-west?
              </p>
              <div className="speaker" id="sp4698"><span className="persName">Mr. JAMES BARBOUR</span>—</div>
              <p className="p-in-sp">
                I don't desire to enter into any controversy before this body on such a proposition as this; but I would say, that this is one of the most extraordinary means I ever heard of. You will have a new Legislature elected before this road could be finished; even if the right of way should be granted, and they will meet here in a few months, when this question can again be introduced for
                further consideration. I think we should leave them at least some little power.
              </p>
              <div className="speaker" id="sp4699"><span className="persName">Mr. SPEED</span>, of Campbell—</div>
              <p className="p-in-sp">I hear that the committee appointed to confer with the commissioner from the Confederate States, are here and prepared to report. Whether they are or not, I move to lay that resolution upon the table.</p>
              <p>The motion was agreed to, ayes 44, nays 37.</p>
              <div className="speaker" id="sp4700"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">I move to take up the ordinance reported this morning from the Committee of Finance, in relation to the issue of small notes of the denomination of one and two dollars.</p>
              <p>The motion was agreed to.</p>
              <div className="speaker" id="sp4701"><span className="persName">Mr. CONRAD</span>—</div>
              <p className="p-in-sp">
                I move to amend that ordinance by providing for the issue of these notes by a portion of the banks which I don't think are provided for in the ordinance as reported. There are a portion of the banks called State stock banks, that have these notes countersigned by the Treasurer, which are not contemplated, I imagine, by that committee. I move to amend the ordinance by adding the
                following:
              </p>
              <p className="p-in-sp">"The banks whose issues are based upon a pledge of State stock, may dispense with the signature of the Treasurer and issue such small notes in the same way as the other banks."</p>
              <div className="speaker" id="sp4702"><span className="persName">Mr. FISHER</span>—</div>
              <p className="p-in-sp">I trust it will not be the pleasure of the Convention to adopt either the ordinance or amendment.</p>
              <p className="p-in-sp">
                I have some experience, sir, as to the effects of this shinplaster currency. On the Eastern Shore of Virginia we never have been flooded with them; and wherever they circulated they invariably drove from circulation gold and silver, which are the ordinary subjects of exchangeable value in our country. In 1837, when we had a perfect paralysis in the monetary affairs of the
                country-when all the banks, <PageNumber num={467} /> with the exception, I believe, of a few in some of the States, suspended specie payment, and the country was flooded with shinplasters, these small notes were not received on the Eastern Shore; and what was the consequence? During the whole period of suspension, sir, we had as much gold and silver as
                we wanted for all the purposes of ordinary traffic. If you went during the same period to the city of Norfolk, a distance of over forty-five miles from my county, you would find it almost impossible for you to get a note of any description changed into gold or silver, except by paying some five or ten per cent. You could get in that city the lowest, the most ragged and the most
                miserable looking paper you ever saw, from North Carolina, and some portions of this State.
              </p>
              <p className="p-in-sp">In 1847 there was another suspension, and we refused to receive these small notes on the Eastern Shore. The result was, as in 1837, a superabundance of specie.</p>
              <p className="p-in-sp">There has been another suspension since then; and the same consequences resulted. We had a superabundance of specie, while in other places, where small notes were issued, there was a perfect dearth of specie.</p>
              <p className="p-in-sp">Such have been the results of this small note circulation, at the different periods to which I refer, and if you adopt that policy now, the same consequences will follow, and gold and silver will at once disappear like water cast upon the sands of the sea shore.</p>
              <p className="p-in-sp">
                That ordinance is objectionable in another respect. It will not only banish all the precious metals from circulation; but it fails to provide that the banks shall redeem even these small notes in gold and silver at their counters. Is this Convention going to authorize the banks of this Commonwealth to issue that large amount of small notes, and not require them to redeem these notes?
                If they do, I can only say that the Convention will be acting without due regard to the interests and safety of the people. The banks of the Commonwealth have been chartered not for the especial benefit of the corporators, but to sub-serve the convenience of the public; and I regard the adoption of this ordinance the best bargain that the banks of the Commonwealth could make with
                this Convention in order to amass large profits by the use of small means.
              </p>
              <p className="p-in-sp">
                Why, sir, the banks of the Commonwealth have sought to influence the Legislature from time to time to grant this provision, but failed. This Government, it was hoped, was rapidly becoming a hard money Government, but it seems the banks will never allow it to become such so long as the public authorities allow them to exercise their
                <PageNumber num={468} /> corporate powers in this way. The people will have less specie. They will be far more inconvenienced than they are now, for want of small change to carry on the transactions of every day life, while a currency will be put in circulation which has actually no representative value. But, sir, our Legislature has required that every
                merchant who obtains a license shall take an oath that he will neither receive nor pay out any note of a less denomination than $5. Do you mean by this ordinance to absolve these merchants from the perjury which they commit if they receive these notes? The gentleman from Richmond
                [<span className="persName">Mr. MACFARLAND</span>] this morning remarked that one object of this ordinance was to suppress the issue of small notes by corporations and irresponsible parties.</p>
              <p className="p-in-sp">
                Sir, if such corporations or individuals issue these notes they must know that they are doing so in violation of law, and they of course will take the responsibility of their acts. It is an indictable offense; and if they are guilty of it, they must of course abide the result. But, sir, if these irresponsible individuals and corporations possess this power now, will it not be the
                multiplying of a depreciated currency by the sanction of this Convention, which will place our issues so far in excess of our specie basis as to derange all our transactions with other States, and place us at a disadvantage in the matter of exchanges?
              </p>
              <p className="p-in-sp">
                To pass such an ordinance as this would be to do what was not contemplated to be done in introducing that measure. Who ever heard of a convention legalizing these issues? Why, sir, we are dwindling into an insignificance that would require a microscope to discern. When we can descend so far as to mingle in the little speculations of the banking institutions of the State, the
                commentaries of the public in regard to our course here may well be deemed justifiable. Our posterity, when reading the history of this Convention, would be startled when they come to read in the proceedings of the 24th of April, in the year of our Lord, 1861, that a committee of this body reported an ordinance to a Convention of the sovereign people of Virginia, authorizing the
                issue of one and two dollar notes by the Banks of this Commonwealth. Why, sir, the blush of shame would redden the cheeks of our children as they would read this in the history of the ancient Dominion of Virginia.. They would be struck with consternation at finding that a subject of this character was before an assembly of the representatives of the sovereign will of Virginia for
                consideration-an assembly which met here to settle the question of liberty on the one hand or slavery on the other, and on the very day <PageNumber num={469} /> when a committee of this body had been in conclave with the representative of the Confederate States, and was ready to make a report of the result of their conference with that functionary.
              </p>
              <div className="speaker" id="sp4703"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">Will the gentleman allow the committee, to which he is just referring, to make a report?</p>
              <div className="speaker" id="sp4704"><span className="persName">Mr. FISHER</span>—</div>
              <p className="p-in-sp">I will yield the floor to the committee to enable them to make their report, and will now move to lay the ordinance under consideration upon the table.</p>
              <p>The motion was agreed to.</p>
              <div className="speaker" id="sp4705"><span className="persName">Mr. JOHN TYLER</span>, of Charles City—</div>
              <p className="p-in-sp">I am instructed by the committee appointed by this body to confer with the Commissioner from the Confederate States, to enter into terms as to the form of alliance or union that may be entered into between Virginia and these Confederate States, to report to you an agreement which has been signed and sealed by the parties, subject to your approval or disapproval.</p>
              <p className="p-in-sp">There follows after that an ordinance, which will be submitted immediately upon the adoption of that document.</p>
              <p>The following is the agreement:</p>
              <div className="floatingText">
                <div className="floatingText_body">
                  <p>Convention between the Commonwealth of Virginia and the Confederate States of America:</p>
                  <p>
                    The Commonwealth of Virginia, looking to a speedy union of said Commonwealth and the other slave States with the Confederate States of America, according to the provisions of the constitution for the provisional government of said States, enters into the following temporary convention and agreement with said States for the purpose of meeting pressing exigencies affecting the
                    common rights, interests and safety of said Commonwealth and said Confederacy:
                  </p>
                  <p>
                    1st. Until the union of said Commonwealth with said Confederacy shall be perfected, and said Commonwealth shall become a member of said Confederacy according to the constitutions of both powers, the whole military force and military operations, offensive and defensive, of said Commonwealth, in the impending conflict with the United States, shall be under the chief control and
                    direction of the President of said Confederate States, upon the same principles, basis and footing as if said Commonwealth were now, and during the interval, a member of said Confederacy.
                  </p>
                  <p>
                    2d. The Commonwealth of Virginia will, after the consummation of the union contemplated in this Convention, and her adoption of the constitution for a permanent government of said Confederate States, and she shall become a member of said Confederacy, under said permanent constitution, if the same occur, turn over to said
                    <PageNumber num={470} /> Confederate States all the public property, naval stores and munitions of war, etc., she may then be in possession of, acquired from the United States, on the same terms and in like manner as the other States of said Confederacy have done in like cases.
                  </p>
                  <p>3d. Whatever expenditures of money, if any, said Commonwealth of Virginia shall make before the union under the provisional government, as above contemplated, shall be consummated, shall be met and provided for by said Confederate States.</p>
                  <p>
                    This Convention, entered into and agreed to, in the city of Richmond, Virginia, on the twenty-fourth day of April, eighteen hundred and sixty-one, by Alexander H. Stephens, the duly authorized commissioner to act in the matter for the said Confederate States, and John Tyler, William Ballard Preston, Samuel McD. Moore, James P. Holcombe, James C. Bruce and Lewis E. Harvie, parties
                    duly authorized to act in like manner for said Commonwealth of Virginia-the whole subject to the approval and ratification of the proper authorities of both Governments respectively.
                  </p>
                  <p>In testimony whereof, the parties aforesaid have hereto set their hands and seals, the day and year aforesaid, and at the place aforesaid, in duplicate originals.</p>
                  <p>JOHN TYLER, [Seal.]</p>
                  <p>WM. BALLARD PRESTON, [Seal.]</p>
                  <p>S. McD. MOORE, [Seal.]</p>
                  <p>JAMES P. HOLCOMBE, [Seal.]</p>
                  <p>JAMES C. BRUCE, [Seal.]</p>
                  <p>LEWIS E. HARVIE [Seal.]</p>
                  <p>Committee of the Convention.</p>
                  <p>ALEXANDER H. STEPHENS, [Seal.]</p>
                  <p>Commissioner of Confederate States.</p>
                </div>
              </div>
              <div className="speaker" id="sp4706"><span className="persName">Mr. RANDOLPH</span>—</div>
              <p className="p-in-sp">I should like to know if there is any other report from the same Committee.</p>
              <div className="speaker" id="sp4707"><span className="persName">Mr. TYLER</span>—</div>
              <p className="p-in-sp">I have another ordinance which must be consequent upon the adoption of that report. I will send it to the Secretary to be read for the information of the body.</p>
              <p>The Secretary read the ordinance, as follows:</p>
              <div className="floatingText">
                <div className="floatingText_body">
                  <p>AN ORDINANCE for the adoption of the Constitution of the Provisional Government of the Confederate States of America. Passed April 25th, 1861.</p>
                  <p>
                    We, the delegates of the people of Virginia, in Convention, assembled, solemnly impressed with the perils which surround the <PageNumber num={471} /> Commonwealth, and appealing to the Searcher of Hearts for the rectitude of our intentions in assuming the grave responsibility of this act, do, by this ordinance, adopt and ratify the Constitution of
                    the Provisional Government of the Confederate States of America, ordained and established at Montgomery, Alabama, on the eighth day of February, eighteen hundred and sixty-one : Provided, That this ordinance shall cease to have any legal operation or effect if the people of this Commonwealth, upon the vote directed to be taken on the ordinance of secession passed by this
                    Convention on the seventeenth day of April, eighteen hundred and sixty-one, shall reject the same.
                  </p>
                </div>
              </div>
              <div className="speaker" id="sp4708"><span className="persName">Mr. TYLER</span>—</div>
              <p className="p-in-sp">
                I desire merely to say, by way of explanation of the agreement which has been entered into between the Committee appointed by this body and the Commissioner from the Confederate States, that after considering the propositions which presented themselves to our minds, in every imaginable form, we were driven to the conclusion that the only possible mode in which we could avail
                ourselves of the organized government of the Confederate States was to become a member of it forthwith. But mark you, not for ever; but merely a member of its provisional government, which expires in twelve months from the time of its adoption by the Confederate States. I think it expires, probably, in February next. It was adopted on the 8th day of February last, and it expires in
                twelve months from that day, unless, indeed, it should be the pleasure of Congress to change that limitation by a prompt and early organization of a new government under the new Constitution which has been adopted by all the States of that Confederacy. Still it would run up to February before it could expire. The election under the Constitution of the permanent government could not
                occur sooner than November; and during the whole intervening time, and before that Congress could be installed in office, the provisional government has existence, and no other. It is into that provisional government that you go. It is not, I repeat, a permanent association with these Confederate States, under a permanent system of government, but altogether temporary. But should
                that ordinance which has been read to you in connection with the treaty which has been made between the Confederate States and your committee, go into effect, it will only provide for the period of time that the treaty may exist, namely, for the date of its ratification here by the assembling of Congress. You perceive that it may be some two days before the Congress which is to
                assemble on the 29th of April will be installed into office. You want some provision to exist between you and the Confederate States between this and the 29th <PageNumber num={472} /> day of the month. These stipulations-call them treaty or whatever else you please-are designed simply to fill up the period that may elapse between the adoption of that
                treaty and the assembling of the Congress of the Confederate States, a period of about nine days; that is to say, that you should adopt the ordinance of union to the provisional government, which, of course, you will be obliged to do, for otherwise the treaty would be of no avail whatever.
              </p>
              <p className="p-in-sp">
                In turning the matter over in the minds of the committee, we found it impossible to place the State of Virginia in a proper and becoming attitude without adopting that ordinance of union and of confraternity. If you stood out upon a separate treaty of alliance, offensive and defensive, you would have no power conferred upon you that would be sufficient to carry you through this war.
                For instance, not being a member of a duly organized government, you would have a difficulty in negotiating treaties; you could negotiate with difficulty any treaty whatever with any European power. These powers require some evidence of the entire capacity of yourselves to maintain your existence before they will enter into stipulations with you. Might it not be a matter of doubt
                whether Virginia, standing isolated from all other connections, could by possibility maintain her existence and separate sovereignty? We might hope so in the pride of our feelings. We might even think so; but yet it would be a desperate and hazardous conflict which you would enter into. It is only by forming a union with a powerful State or a combination of States, that you can with
                perfect certainty and confidence assure yourselves of your capacity and your ability to vindicate your existence and your sovereignty.
              </p>
              <p className="p-in-sp">
                But, sir, I was remarking that you could not negotiate foreign treaties, standing isolated and alone upon an alliance merely offensive and defensive. In this case your character would not be changed into a separate and independent State. Separate and distinct from the Confederate States, you would be forced to stand se soutenir, as the French call it; and, standing in that position,
                it becomes more than questionable whether you could enter into any treaty whatever with a foreign power. Entering, however, into a union with these States, the difficulty vanishes. You are connected with a powerful Confederacy, a regularly organized government-a government de facto, which has maintained its independence for the last three, or four, or five, or six months. It can
                scarcely be assailed from any quarter. It is now understood by all European governments to be safely established under the panoply of its own arms. There will be <PageNumber num={473} /> no difficulty, therefore, especially when you have entered into this union; because your sovereignty will be maintained, to say nothing of the rapid accession of
                Southern power from other States which you will be likely to receive. You will have a power and an interest in the affairs of the world the very instant you enter into that Confederacy. But, mark you, it is altogether a temporary union, of comparatively short duration.
              </p>
              <p className="p-in-sp">
                But again, you want money. How are you to get it? Can Virginia, standing alone, obtain it? Would it be possible for you to go into the money markets of the world and obtain, out of your own limits, one dollar upon loan from any bank? I think it would not; your situation would be altogether too perilous in this conflict, with nineteen States arrayed against you, you having to fight
                alone and maintain your independence alone.
              </p>
              <p className="p-in-sp">
                Nor is that all. It was enough to bring the mind of every member of the committee, I believe, to the conclusion, that there was no alternative left us but to enter into the stipulations of that temporary treaty. I speak of it as temporary. It may last for six months, possibly for nine months. It cannot go very well beyond nine months. You are in a state of great emergency at this
                time; you have large armies assembling in Washington. Old Point Comfort was reinforced the other day by four thousand troops. You are threatened with these hostile demonstrations in every direction. Large armies are destined to move from the North-west. Your territory is liable to invasion in that direction. Sir, we want aid. Where else can you obtain it from? What other quarter can
                you look to for aid except the Confederate States? I do not mean to dwell longer upon this subject, I know that I have made but a very poor exposition of the views I intended to present, arising probably from the difficulty of writing more than from anything else. I am, moreover, unwilling to delay your action upon either the treaty, the terms of agreement, or the ordinance of
                secession. Nay, sir, I would most ardently entreat the Convention to delay not a moment in the ratification of the terms which your committee have agreed on with the commissioner from the Confederate States. Not only does the commissioner desire us to act with promptitude, but it is our interest to do so. I am speaking exclusively with reference to the interests of Virginia, you
                should act forthwith, for you cannot say what a day will bring forth. You want speedy and decisive action, and I trust the Convention will recognize the necessity of such action by ratifying this treaty.
              </p>
              <p className="p-in-sp">With these remarks, sir, the question is before you.</p>
              <PageNumber num={474} />
              <div className="speaker" id="sp4709"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">Since the adoption of the ordinance of secession, I have felt my perfect incapacity and inability to deal with the questions that have arisen. I may say, without meaning disrespect to any other gentleman, that I have very much feared the want of capacity of other gentlemen to deal with the questions that press upon us.</p>
              <p className="p-in-sp">
                Sir, we have by this ordinance undertaken to resume for the time being the power that was delegated to the General Government by Virginia. As I now understand it, it is proposed to turn over the Commonwealth of Virginia, bound hand and feet, to the Confederate States. Sir, while we remain a member of the old Union, no man doubted or denied the power of Virginia to defend herself
                against invasion, or to protect herself when her rights were invaded. But, as I understand this Convention, it proposes to give to the President of the Confederate States the absolute control of all our military operations, whether offensive or defensive. It places the whole military power of the Commonwealth under his control; a power never possessed by the Federal Government. Sir,
                under this Convention, the President of the Confederate States may call for any number of troops for any purpose he may think proper. This is not at all consistent with my idea of the position which Virginia now occupies. Until the people pass upon this ordinance of secession, my opinion is, that our position is one of defence.
              </p>
              <div className="speaker" id="sp4710"><span className="persName">Mr. TYLER</span>—</div>
              <p className="p-in-sp">The President of the old Union has just called for 75,000, without any limitation.</p>
              <div className="speaker" id="sp4711"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">
                He has called for them for no other purpose than to maintain the Constitution and laws. If he has done so for any other purpose, he is acting against the Constitution and laws; and I find myself compelled to stand here in defence of the rights of Virginia as a sovereign and independent State, which she became the moment that she resumed to herself the powers which she delegated to
                the general government, and which she is now exercising. According to my understanding of that Convention, it invests the President of the Confederate States with a power which the President of the United States nor Congress never did possess. The President, under a law of Congress, had a right to call out the military powers for certain purposes. He had no right to control the
                military power of Virginia, or of any other State.
              </p>
              <div className="speaker" id="sp4712"><span className="persName">Mr. DORMAN</span>—</div>
              <p className="p-in-sp">Does this Convention give the President any other power than that which he has under the Constitution of the Confederate States?</p>
              <div className="speaker" id="sp4713"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">
                I don't know anything about that. I am speaking of <PageNumber num={475} /> the condition of Virginia. We are now in a great emergency. We do not know the moment we might have to defend some particular point in the State. But this proposition that gives the President every power, nullifies all of our ordinances on the subject of the military
                preparations of the State. He may order our forces to the Capital at Washington, or anywhere else on our borders needing protection. My opinion is that we now stand in a position of defence, and we have a right to defend ourselves; but, sir, if you adopt this Convention, then the whole military power of this State is turned over to another Government. I fear that in adopting this,
                you may create a division in the State that may be productive of disastrous consequences. We have already appointed a Commander-in-Chief of the military and naval forces of Virginia. This Convention gives to the President of the Confederate States the power to order him to any place he may think proper. Sir, I would be willing to consent to any alliance with the Confederate States
                which, recognizing the sovereignty and independence of Virginia, would bind them to assist us; but I never will consent to place Virginia in the position in which, in my opinion, this Convention places her.
              </p>
              <p className="p-in-sp">
                I feel it due to myself and to the position I occupy, to express my opinions frankly. I acknowledge my incapacity to deal with the questions that arise here. I have felt an earnest and anxious desire to defend the State against invasion; but I do not feel authorized to enter into any alliance which shall place this State, with its whole military power, under the control of the
                President.
              </p>
              <p className="p-in-sp">Sir, this Convention, making this State a member of the provisional government, transcends the power with which we are invested. It is entirely inconsistent with the act requiring that the ordinance of secession should be referred to the people for their action before it should be final.</p>
              <div className="speaker" id="sp4714"><span className="persName">Mr. RANDOLPH</span>—</div>
              <p className="p-in-sp">
                As I understand this Convention, it confers no greater power upon the President of the Confederate States than are conferred upon the President of the United States. He is the commander-in-chief of the army and navy; and the only difference will be, that instead of having Governor Letcher to command them, we will have President Davis to command them. Our condition is a most anomalous
                one. If we remain a member of the United States, every act we have been passing here is treasonable; and, I therefore, believe we ought to put ourselves beyond reach of the responsibilities attending these acts by becoming a member of the Confederate States. I don't believe that the connection will at all hamper the Convention. We
                <PageNumber num={476} /> do not intend to disregard the act calling us into existence. We are only adopting a measure necessary for the safety of the State. Until the people of Virginia can say what the final destiny of the State shall be, my humble opinion is, that it is entirely within our power to enter into such an alliance, and conclude such a
                treaty as we may see necessary for the defence of the State. It is an exercise of extraordinary power which ought to be sanctioned by our constituents. I shall feel relieved from any sort of responsibility in favoring the assumption of such power. I mean to do for them, as far as I am concerned, what I think they ought to sanction; and if they chance to err, they shall not consider
                me responsible.
              </p>
              <div className="speaker" id="sp4715"><span className="persName">Mr. TYLER</span>—</div>
              <p className="p-in-sp">
                The very moment, sir, that your members present themselves at Montgomery for seats in the Congress, which will be then in session, I am authorized by Mr. Stephens to say, that your members will be admitted into full communion with all the other members that might there be assembled. He intimated, moreover, that for the purpose of conferring upon the State of Virginia that power and
                influence which she was known to possess, and in order to avail themselves fully of her sagacity and her statesmanship, that a vacancy would be made in the Cabinet at Montgomery so as to admit one of your prominent citizens. So that, after the adoption of this Convention, you will have full participation in all that relates to the operations of the Government; full and complete
                participation in the management of its Executive Department.
              </p>
              <p className="p-in-sp">One remark more and then I am done. You perceive that the ordinance is made to depend upon the ratification of your act of secession by the people. Well, now, that takes place the latter part of May; so that one short month sees the termination of all these proceedings, in the event of the people's not ratifying what you have done.</p>
              <div className="speaker" id="sp4716"><span className="persName">Mr. RANDOLPH</span>—</div>
              <div className="stage it">[<span className="persName">Mr. GOGGIN</span> temporarily occupying the Chair]</div>
              <p className="p-in-sp">
                We have adopted measures here which tended to break up the Government of the United States. We have called all Virginians out of the service of that Government; and it does seem to me strange, if we have the power to do that, that we have not the power to ally ourselves with another Government. Our powers, it would seem from the argument of gentlemen, are only for destruction and not
                for construction; and while we may shatter and pull down, we cannot build up even if the defence of the State and the salvation of the people depend upon it. I think it is a happy solution of our difficulties, if we are relieved from the expense and responsibility of <PageNumber num={477} /> keeping up a separate military organization. This month alone
                will cost the State of Virginia upward of one million of dollars. I think moreover, this alliance will have a happy political effect. I think the publication of our ordinance of secession unaccompanied with this Convention will have a very disheartening effect.
              </p>
              <div className="speaker" id="sp4717"><span className="persName">Mr. ECHOLS</span>—</div>
              <p className="p-in-sp">
                I think the report of the committee is a very important matter, and must be so regarded by every member here; and while I have the greatest confidence in the capacity of the members of the committee, I would like to have a little time to consider it. I desire this, too, for the reason that it was read very indistinctly, and is therefore not perfectly intelligible to me. I do not
                desire to throw any obstacle in the way of the report; but in a matter of so much importance, I would not feel that I was discharging my duty properly if I was to act upon it now.
              </p>
              <p className="p-in-sp">I move that the Convention now adjourn.</p>
              <div className="speaker" id="sp4718"><span className="persName">Mr. DULANY</span>—</div>
              <p className="p-in-sp">I would ask the gentleman to withdraw that motion. I want to make a request of the Convention. I will renew the motion.</p>
              <div className="speaker" id="sp4719"><span className="persName">Mr. ECHOLS</span>—</div>
              <p className="p-in-sp">I withdraw it.</p>
              <div className="speaker" id="sp4720"><span className="persName">Mr. DULANY</span>—</div>
              <p className="p-in-sp">I rise to ask the privilege of this Convention, as I am compelled to leave here to-morrow, to sign the ordinance of secession before I do leave. I understand it is ready for the signatures.</p>
              <p>Leave was granted.</p>
              <div className="speaker" id="sp4721"><span className="persName">Mr. DULANY</span>—</div>
              <p className="p-in-sp">I now renew the motion to adjourn, according to promise.</p>
              <p>The motion was rejected.</p>
              <div className="speaker" id="sp4722"><span className="persName">Mr. WM. BALLARD PRESTON</span>—</div>
              <p className="p-in-sp">
                I did not at all oppose the motion which was made by my friend from MONROE (<span className="persName">Mr. ECHOLS</span>) Probably, this is a subject that gentlemen may not feel themselves prepared to vote upon to-night. But I shall subject myself to the judgment of the Convention. Still I feel that it is due to the report that has been made, that I should make some expositions in
                addition to that which has been made by our chairman, of the condition in which we stand, and of the grounds upon which we felt ourselves constrained to take the action which we have taken.
              </p>
              <p className="p-in-sp">We are deeply sensible of the magnitude of the action which we commend to the vote of this House to-night. We are deeply sensible of the responsibility on us as members of this committee. It is a duty that I would not have performed except under a conviction that our action was subject to the ratification of the House.</p>
              <p className="p-in-sp">
                Now, look at our position. In the circumstances in which we are <PageNumber num={478} /> placed by the act of secession, we are threatened immediately with an open, flagrant and powerful invasion. Troops are concentrating everywhere around us, and our own Commonwealth seems to be the battle ground upon which this conflict is mainly to be waged. I was
                not insensible to these facts on the 17th, when I proposed the ordinance of secession. The question now is as to our present condition. We are threatened with imminent and powerful invasion from nineteen States of the late Confederacy. What can we do? Are we prepared to day to defend the line of the Potomac and the line of the Ohio? I will say we are not. We neither have the arms,
                the organization or the money. What, then, shall we do? Shall we stand here upon our sovereignty, which has not been resumed, until the ordinance is ratified by the people; and before our people may have a chance to say whether they will or not continue members of this Union, and our power may be overthrown and our homes and families destroyed. We cannot stand in that position. What,
                then, will we do? I will not discuss this now. My own feelings direct me to the conclusion that the only hope on earth is a union with the Confederate States, such as the committee has proposed. These States are ready to come to our aid, and give us all the assistance that is necessary to carry us through this terrible conflict. Here are regiments now from the South which have come
                in our defence. Our own troops are swelling in upon us from every direction; and we have neither the means nor the organization to take care of them for one night-for one hour. They are all dependent on their own private or individual resources? What shall we do? This is no assumption of arbitrary power. It is merely exercising a necessary power in deference to the voice of the
                people. You will perceive that the ordinance declares that it shall be operative only until the people have time to pass on it, and then it is to be revoked if they think proper not to exercise this power of withdrawing from the Union. But, in the mean time, what shall we do? How can there be any organization? how can there be any concerted action by which our power and our strength
                can be used to repel invasion? There can be none, as it appears to me, but by finding allies somewhere. We have found allies in our sisters of the South. They propose to give us an organized government, with money in abundance. They have agreed to pay the expenses of this war. We have agreed to put our military organization under their charge; but it is only a change from the
                government at Washington, with Lincoln at the head, to the government at Montgomery, with Jeff. Davis at its head. The Provisional Constitution is the old Constitution of the United States of America, with a few <PageNumber num={479} /> amendments which it is not necessary to consider now, because they are amendments in reference to peace, and not in
                reference to war.
              </p>
              <p className="p-in-sp">The power to call out the militia, under the Provisional Government, is exactly the stipulation which is found in our old Constitution. It is this:</p>
              <p className="p-in-sp">"The President shall be Commander-in-Chief of the Confederate States, and of the militia of the several States, when called into the actual service of the Confederate States."</p>
              <p className="p-in-sp">That is the provision contained in the Provisional Constitution, which is precisely a transcript of the provision on the same subject found in the old Constitution. So that we take the same privilege and ask the Provisional President to exercise the same powers for our defence which were exercised under the old Constitution.</p>
              <p className="p-in-sp">Again : we find this provision in the Provisional Constitution, which is precisely the same as that contained in the old Constitution on the same subject.</p>
              <p className="p-in-sp">"Congress shall have power to provide for organizing, arming and disciplining the militia, and for governing such part of them as may be employed in the service of the Confederate State; reserving to the States respectively the appointment of the officers, and the authority of training the militia according to the discipline prescribed by law."</p>
              <p className="p-in-sp">There lies the whole question as far as the military organization is concerned, and President Davis only undertakes to put our army in the field, just as Lincoln would do if we were in the Union. The only difference is, that instead of being called out by Abraham Lincoln for offensive purposes, we are put under Jeff. Davis for defensive purposes.</p>
              <div className="speaker" id="sp4723"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">Will my friend allow me to put a question to him?</p>
              <p className="p-in-sp">If, when Governor Wise ordered out the militia to Harper's Ferry to repel the John Brown invasion, the President of the United States had a right to control these troops? And now I would ask if this ordinance does not give to the President of the Confederate States our military forces, either for offensive or defensive purposes?</p>
              <div className="speaker" id="sp4724"><span className="persName">Mr. PRESTON</span>—</div>
              <p className="p-in-sp">
                Yes, sir; the ordinance is that we are made a party to the Constitution which I hold in my hand, and the power which we confer is properly limited, as may be seen by the language which is given. We are not uniting ourselves with a revolutionary government. We are merely transferring our power to an organized government from the government at Washington. I do not see any difference in
                that, sir.
              </p>
              <p className="p-in-sp">
                There is another exigency greater than all these. You are now in war. If you do not put yourselves under an organized government, <PageNumber num={480} /> what becomes of all your postoffice affairs, of your commercial affairs and of every other important function which vitally concerns the Commonwealth? We have to fight a great and strong government,
                and for all these purposes in this exigency, the moment the Federal Government blockades our ports, we shall be deprived of all means to maintain ourselves and resist Northern aggression. We have no port of entry; we cannot receive a vessel. We can purchase no guns except guns belonging to the State of Virginia, and we shall be compelled at last to resort to the Southern States to
                supply us in all that is necessary to prosecute the war. We may invest the Executive of Virginia with the power of discharging all these functions of the Federal Government. Which is the best course to pursue?-to confer on our Executive all the powers belonging to the President of the United States to regulate the mails, command our military forces, &amp;c., or shall we, for the time
                being, place them in the hands of a government now organized, and ready to discharge them? These are the considerations that controlled my feelings to-day, and I was compelled to decide between them.
              </p>
              <p className="p-in-sp">One remark or two more. The subject is infinite in its importance; and, I am entirely unprepared for such a presentation of it as its importance demands.</p>
              <p className="p-in-sp">The ordinance is based upon one principle, and, if that is wrong, I am wrong.</p>
              <p className="p-in-sp">
                We are compelled, in this exigency, to assume power somewhere that does not exist in organism anywhere. I thought it was better to take the organism to the South than give it all to the Governor of Virginia, who would then be an absolute military dictator without control. We can easily pass through this transition by adopting this ordinance, and run no risk of being subjected to an
                absolute military despotism. We have to choose between this and Constitutional Government; between a government which is defensive and an absolute government. These are terrible conditions. You can well imagine how a man, born in a country of law, finds himself oppressed with these difficulties. Virginia, sir, cannot defend herself alone. I know the heroic heart of my friend, and his
                confidence in her strength and courage; but she is not able to defend herself in this conflict. Such is the stern fact. How shall she organize herself? By giving all this power to the Governor? I imagine not. Had we not better strengthen the Confederate powers, not in the direction of an absolute power, but in the direction of Constitutional Government?
              </p>
              <p className="p-in-sp">
                One point further. I am a Western man in one sense. God knows <PageNumber num={481} /> I have endeavored, to the best of my ability, to do all that I have done here in the spirit of a Virginian, uncontrolled by any sectional feeling. I bow to the popular voice. I would not pass an ordinance of secession, unless it was to be ratified by the people. We
                have provided in this law that this whole transfer of power shall be nullified if the people reject the ordinance of secession.
              </p>
              <p className="p-in-sp">We have guarded it as far as man could. I know there is some feeling in this matter. I know that my friend from Franklin [<span className="persName">Mr. EARLY</span>]
                felt that there was a great desire that our sister border slave States would go with us. Such were the feelings of a majority of the Convention for some time. We have expressed not only our desire to go, but have expressed a purpose that Virginia should go into this Confederacy with the border States. We have taken now this provisional form of government, which ends at the expiration
                of a year. Therefore, we have restricted it to the shortest period consistent with the requirements of the present exigency. We have left our people perfectly free to unite with the South, or to unite with the border slave States, as their judgment may hereafter determine. As for myself, I am free to say I see no alternative. But we have left the alternative to the people, and not
                committed the State of Virginia to any ultimate policy. I think that she is inseparably united with the Southern Confederacy. I believe that all the border slave States are destined to assume the position which we now occupy. Here is Maryland, with a Governor resisting secession, rising up and heroically resisting the passage of Federal troops through her territory, and, at the
                proper time, we may well calculate upon her aid and assistance in this struggle. They will be with us, sir, without doubt. Even if it were not the result of choice, that stern necessity which fixes the destinies of nations would force Maryland into that attitude.
              </p>
              <p className="p-in-sp">
                We are informed to-day that Kentucky is all alive and that North Carolina will soon follow our example. And how will these various forces be brought together in any form of organism? Only by pursuing the policy which your committee recommends-a union with the Confederate Government. Let us stand on that peculiar principle. It is an extreme measure; but in my opinion there is no
                alternative. As to what the people of Virginia will do, I shall express a very decided opinion. I know they will do whatever is necessary for their safety, and judgment points out no mode of safety on God's earth, but availing ourselves of the organism of the Confederate States first, of the money next, of the military next.
              </p>
              <p className="p-in-sp">Gentlemen say there is to be an absolute invasion of the South by <PageNumber num={482} /> all the forces of the North. I do not concur in that. I may be wrong. It is only an opinion operating upon me. I will show why it does operate upon me.</p>
              <p className="p-in-sp">Whatever we may think of Lincoln, we know that he has advisers able and skilful, in war and in peace. I don't think that our policy ought to be aggressive. Our policy ought to be defensive; and now I want to show how that policy operates upon the Convention which we have made.</p>
              <p className="p-in-sp">
                It ought to be known that the South has united all her energies, with her money value, and when that union occurs, this one question is presented to the North. When we throw in our 450,000, who voted against Lincoln at the North, my opinion is that in a very short time the propositions for this Northern power will be simply this: no incursion in Virginia; no attempt to march from
                Aquia Creek and form a union with the basis of operations at the mouth of the Chesapeake. But when all have come up and all stand in one solid column, I believe in time that the North, from motives of humanity as well as interest, will come to a sense of the impropriety of pursuing this war, because the South has never yet beheld an invasion that could involve the total annihilation
                of all classes and of all races in the South. I think we shall see some effort made by that 450,000 who voted against Lincoln. They will do so, if from no other motive, upon the ground of interest; and yet all feel that there is no mode of proceeding but in an effort to subjugate the South. That will require an army that must always be victorious. It cannot be done by any predatory
                warfare. Thus they must go forward in organizing an army of hundreds of thousands if they really entertain any hope of subjugating the South. There is the subject of interest which will force itself upon the consideration of those people. This, in my opinion, will produce peace, and make them say peace instead of war. If we unite and stand on the defensive, I still believe that there
                is a hope that this matter may pass off without this war of extermination. Therefore, I thought that the more rapidly we could concentrate troops within our borders, the sooner will come the result which we desire to be produced.
              </p>
              <p className="p-in-sp">I know the view that I take is an extreme one. It must stand only upon the principle of the salus populi.</p>
              <p className="p-in-sp">I thank the House for its kind and patient attention this evening.</p>
              <div className="speaker" id="sp4725"><span className="persName">Mr. BRANCH</span>, of Petersburg—</div>
              <p className="p-in-sp">I presume that every gentleman's mind is made up, and that there is but one side to the question. Hence, without meaning to be offensive to any gentleman, I call the previous question.</p>
              <PageNumber num={483} />
              <p><span className="persName">Mr. BRANCH</span> subsequently withdrew the call.</p>
              <div className="speaker" id="sp4726"><span className="persName">Mr. BLOW</span>—</div>
              <p className="p-in-sp">I move we now adjourn.</p>
              <div className="stage it">[Negatived.]</div>
              <div className="speaker" id="sp4727"><span className="persName">Mr. SHEFFEY</span>—</div>
              <p className="p-in-sp">I desire to offer the following ordinance :</p>
              <div className="floatingText">
                <div className="floatingText_body">
                  <p>AN ORDINANCE ratifying and confirming the convention entered into between the commissioner of the Confederate States and the commissioners of the State of Virginia.</p>
                  <p>
                    Be it ordained by this Convention, That the convention entered into on the twenty-fourth April, eighteen hundred and sixty-one, between Alexander H. Stephens, commissioner of the Confederate States, and John Tyler, Wm. Ballard Preston, S. McD. Moore, James P. Holcombe, James C. Bruce and Lewis E. Harvie, commissioners of Virginia, for a temporary union of Virginia with said
                    Confederate States, under the Provisional Government adopted by said Confederate States, be, and the same is hereby, ratified and confirmed on the terms agreed upon by said commissioners.
                  </p>
                </div>
              </div>
              <div className="speaker" id="sp4728"><span className="persName">Mr. PETER C. JOHNSTON</span>, of Lee and Scott—</div>
              <p className="p-in-sp">My purpose in rising is to suggest, what seems to me, to be a difficulty, and to bring attention to a point which, in my judgment, requires modification.</p>
              <p className="p-in-sp">
                In reference to this contract, the ordinance, as I understand it, proposes that the ratification of that measure shall be dependent upon the ratification by the people of the ordinance of secession. In other words, I understand that no separate vote is to be taken upon this measure. They are yoked together. The people are called upon to vote upon one, and one only; and the vote upon
                that will decide the fate of the other.
              </p>
              <p className="p-in-sp">
                Now, let us look for a moment and see in what position that places the subject. What will be the effect of their being unwilling to ratify this agreement? I believe that the people are determined to ratify the ordinance of secession; and, that being the case, when you bring them to the polls, you allow them no discussions; you make them take the two together or neither. I think the
                questions ought to be presented separately and disconnected. I have no doubt the people will ratify the ordinance of secession; but I am not equally sure that the people would be disposed to ratify this contract with the Confederate States.
              </p>
              <p className="p-in-sp">It is said that this Convention has the power to adopt this ordinance. I don't believe it has the power to adopt it finally. I don't believe it has the power to adopt without submitting it to the people for ratification.</p>
              <div className="speaker" id="sp4729"><span className="persName">Mr. BLAKEY</span>—</div>
              <p className="p-in-sp">Why is it we cannot ratify it now? How can you submit it to the people?</p>
              <div className="speaker" id="sp4730"><span className="persName">Mr. JOHNSTON</span>—</div>
              <p className="p-in-sp">
                We came here instructed by the people to submit to their approval any measure, adopted by this Convention, calculated <PageNumber num={484} /> to alter our relations with the Federal Government. In other words, we came here instructed to submit that, or any agreement changing our relations to the late U. S. Government to the approval of the people,
                before we enter into it. We dare not attempt to carry out an ordinance of secession without submitting it to the people; and how does it follow that we exercise the power of carrying the people into another government without submitting the question to them? I don't see that we have more power to do one thing than the other. It strikes me that it is necessary to submit to the people
                the question whether they will approve of this agreement. It strikes me that it is necessary to submit to them any measure intended to unite us to another, and put us under another government. It seems to me, and it presents itself with a force which my mind cannot well resist-that it is indispensably necessary that this measure, likewise, should be put to a vote of the people in the
                same spirit and in an equal manner with the ordinance of secession.
              </p>
              <p className="p-in-sp">
                It is not necessary to go here now into a consideration of the fact, whether the State is able to sustain her position. That is a point which was considered with great earnestness and great anxiety, and decided by this Convention when the ordinance of secession was passed. It was discussed fully on that occasion, and this Convention pronounced that Virginia ought to go out
                separately, and depend upon herself to maintain her rights. I don't see any change of circumstances since that day, calculated to produce a change of opinion. On the contrary, if there is any change, it is probably the other way.
              </p>
              <p className="p-in-sp">I will close with a reiteration of my opinion, that this agreement ought to be submitted to the people for their approval, with the ordinance of secession.</p>
              <div className="speaker" id="sp4731"><span className="persName">Mr. HAYMOND</span>—</div>
              <p className="p-in-sp">I confess that I have very great confidence in the judgement and prudence of the gentlemen of this committee; and I have no doubt they have made this treaty with a very patriotic spirit, looking to the very best interests of the State of Virginia.</p>
              <p className="p-in-sp">
                But, sir, we are either to approve or disapprove of the action of that committee; and it does seem to me, as we have to account to our constituents for all that we do, and as we ought to understand the bearing of every vote we cast in this Convention, that it is prudent to allow this Convention to adjourn over until to-morrow. I therefore move that this Convention do now adjourn.
              </p>
              <p>The motion was agreed to, and the Convention adjourned at quarter past 8 o'clock, P. M.</p>
            </div>
          </div>
          <div className="day" id="vsc1965.v4.2.10">
            <PageNumber num={485} />
            <h2><span className="headingNumber">1.10. </span><span className="head">TENTH DAY</span></h2>
            <div className="dateline">Thursday, <span className="date">April 25</span></div>
            <p>The Convention met at 12 o'clock.</p>
            <p>Prayer by the Rev. Mr. Spurlock, delegate from the county of Wayne.</p>
            <div className="speaker" id="sp4732"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">Under the rule adopted on yesterday, reports from committees are in order.</p>
            <p>The Secretary called over the list of committees, as directed by the resolution adopted on yesterday.</p>
            <p>When the Finance Committee was called,</p>
            <div className="speaker" id="sp4733"><span className="persName">Mr. MACFARLAND</span>—</div>
            <p className="p-in-sp">The Committee of Finance reported yesterday morning an ordinance authorizing the issue of one and two dollar notes, and I ask that it now be taken up.</p>
            <div className="speaker" id="sp4734"><span className="persName">Mr. GOGGIN</span>—</div>
            <p className="p-in-sp">I did understand that to be the rule.</p>
            <div className="speaker" id="sp4735"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">The rule did not contemplate the taking up of reports previously made.</p>
            <p>The call of the list of committees was then resumed, but no reports were made.</p>
            <div className="speaker" id="sp4736"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">The Chair will now present a communication received from the President of the Confederate States.</p>
            <p>The communication was read by the Secretary, as follows :</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>MONTGOMERY, ALABAMA,</p>
                <p>April 22d, 1861.</p>
                <p>Hon. JOHN JANNEY,</p>
                <p>President of the Convention of Va.</p>
                <p>
                  Sir : I have the honor to acknowledge the receipt of the ordinance of secession adopted by the Convention of the people of Virginia on the 17th inst., and transmitted with your letter by a special messenger, <span className="persName">Mr. WM. F. GORDON</span>, JR. The fact was most gratifying and came to us as the fulfillment of expectations securely resting on the long and illustrious
                  career of Virginia, as the indication<span className="note" id="Note47"><span className="noteLabel">1</span>The Enquirer printed "indication," but the context seems to call for "vindicator."</span> of State rights and the fearless opposer of usurpation and tyranny.
                </p>
                <p>Accept my best wishes, and believe me, very respectfully, and truly yours,</p>
                <p>JEFFERSON DAVIS.</p>
              </div>
            </div>
            <div className="speaker" id="sp4737"><span className="persName">Mr. MONTAGUE</span>—</div>
            <p className="p-in-sp">I move that the communication be laid upon the table and printed, so as to become a part of the journal.</p>
            <PageNumber num={486} />
            <p>The motion was agreed to.</p>
            <div className="speaker" id="sp4738"><span className="persName">Mr. MORTON</span>—</div>
            <p className="p-in-sp">In connection with that, I move that $120 be allowed to Mr. Gordon, the special messenger on that occasion. He desires no pay, but merely his expenses. I will put my motion in the form of a resolution.</p>
            <p className="p-in-sp">"Resolved, That one hundred and twenty dollars be allowed to Mr. Wm. F. Gordon, Jr., for his expenses as special messenger to the Confederate States."</p>
            <p>The resolution was adopted.</p>
            <p><span className="persName">Mr. SLAUGHTER</span>, from a special committee appointed to consider the subject of the disposition of the machinery seized at Harper's Ferry, submitted the following report:</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>"AN ORDINANCE providing for the removal and disposition of the machinery taken at Harper's Ferry."</p>
                <p>
                  "Be it ordained, that the Governor of the Commonwealth cause so much of the machinery taken at Harper's Ferry, as may be useful, on repairing of muskets, to be removed to the city of Richmond and placed in the Armory, and, that he cause the residue of said machinery to be removed to the city of Lynchburg and that so much thereof as may be necessary, be put in a condition to be used
                  as speedily as possible-all the expenses incurred under this ordinance to be paid out of the money appropriated for the defence of the State."
                </p>
              </div>
            </div>
            <div className="speaker" id="sp4739"><span className="persName">Mr. SLAUGHTER</span>—</div>
            <p className="p-in-sp">
              I will state, in regard to this matter, that the Committee called upon General Lee and Colonel Dimmock, and enquired of them whether they would consider the machinery safe at Harper's Ferry or not. They both concurred in the opinion that it would not be safe there now. The Committee then concurred as to the propriety of removing it to Richmond; but, upon ascertaining from Col. Dimmock
              that the Armory here was nearly in a state to commence the manufacture of arms, we agreed to remove such portion of the machinery here at once as could be used for the completion of the Armory, directing another portion to be removed to Lynchburg, which, from the facilities of travel to it, and its being already the seat of extensive manufacturing establishments, was deemed a safe and
              proper place of deposit for such of this machinery as could not be used in connection with the new Armory here. I enquired what accommodations could be procured for the machinery at that point, and was informed that Mr. Deane, who is the owner of a large foundry there, tendered his establishment for that purpose. There were mills and factories which could readily be procured for that
              purpose. Understanding <PageNumber num={487} /> that these accommodations could be had, we agreed to report this ordinance.
            </p>
            <p className="p-in-sp">I will state that this does not contemplate the permanent removal of this machinery. It only provides for its temporary protection until there is an opportunity of making some permanent disposition of it.</p>
            <p className="p-in-sp">It is important that this report should be acted upon at once, for it appears that the people near Harper's Ferry object to the removal of this machinery without the consent of the Convention.</p>
            <div className="speaker" id="sp4740"><span className="persName">Mr. BARBOUR</span>, of Culpeper—</div>
            <p className="p-in-sp">I desire to say, that I was not upon the committee when that ordinance was adopted. I do not see the necessity for passing it this morning; and I would suggest that it be laid upon the table and printed.</p>
            <p className="p-in-sp">I may state that the President of the Orange and Alexandria railroad is in this city, and from him I learn that part of that machinery has been removed, and he goes for taking it down as rapidly as possible and depositing it in some safe place in the mountains.</p>
            <p className="p-in-sp">The Superintendent of the Armory will be here to-day or to-morrow, and he will be able to furnish us some important information regarding this machinery and the disposition to be made of it.</p>
            <p className="p-in-sp">I move that the ordinance be laid upon the table and printed.</p>
            <div className="speaker" id="sp4741"><span className="persName">Mr. JOHNSON</span>—</div>
            <p className="p-in-sp">Will the gentleman withdraw that for a moment?</p>
            <div className="speaker" id="sp4742"><span className="persName">Mr. BARBOUR</span>—</div>
            <p className="p-in-sp">Yes, sir.</p>
            <div className="speaker" id="sp4743"><span className="persName">Mr. JOHNSON</span>—</div>
            <p className="p-in-sp">
              It seems to be very desirable that the machinery at Harper's Ferry, which is very valuable, should be at once removed, inasmuch as Harper's Ferry is not considered at present a safe place for it. I will say to the Convention as a member of the committee, that, learning from Gen. Lee and Col. Dimmock, that this was not a safe place of deposit for the machinery, the committee were
              unanimously in favor of its removal as indicated in the ordinance. Though the gentleman from Culpeper
              [<span className="persName">Mr. BARBOUR</span>] , was not present at its adoption, he gave his assent to it.</p>
            <div className="speaker" id="sp4744"><span className="persName">Mr. BARBOUR</span>—</div>
            <p className="p-in-sp">The report was shown to me, and I assented to it so far as my information at that time justified me. But this project of removing the machinery to Lynchburg seems to me to be a very expensive one, and I cannot at present vote for it. I don't see why this matter should be acted upon to-day more than to-morrow.</p>
            <div className="speaker" id="sp4745"><span className="persName">Mr. JOHNSON</span>—</div>
            <p className="p-in-sp">
              It seems to me peculiarly proper that it should be acted upon to-day, in order that the machinery may be removed with as little delay as possible. This machinery is now at Harper's Ferry, a point which is easily accessible, and, therefore, a point from which <PageNumber num={488} /> all valuables should be removed as speedily as possible. One single day's
              delay may occasion the loss of all by allowing a favorable opportunity for its removal to pass. The mere fact that it was being removed, as intimated by my friend, to some point in this neighborhood, renders it the more necessary, in my opinion, to see at once to its transfer to some place of safe deposit; and the committee, taking that view of the matter, suggested that that portion
              of the machinery which was suited to the manufacture of muskets should be removed to the city of Richmond, and put into the Armory, for the manufacture of that description of arms, which we now very much need.
            </p>
            <p className="p-in-sp">
              So far as the balance of the machinery is concerned-that suited to the manufacture of rifles and pistols-there are manufacturing establishments in the city of Lynchburg which could be had for the use of the Commonwealth immediately; so that this portion of the machinery could be brought into requisition without much delay. While the committee did not undertake to make any contract
              touching these establishments, they thought that this, being a safe place of deposit, and there being already in it manufacturing conveniences, with the aid of this machinery, could be made available for early use, was the best point to which it could be removed.
            </p>
            <p className="p-in-sp">I hope that it will be the pleasure of the Convention to adopt the report.</p>
            <div className="speaker" id="sp4746"><span className="persName">Mr. BARBOUR</span>—</div>
            <p className="p-in-sp">I renew the motion to lay it on the table and order it to be printed.</p>
            <div className="speaker" id="sp4747"><span className="persName">Mr. SUTHERLIN</span>—</div>
            <p className="p-in-sp">I dislike to say anything on this subject that might place me in conflict with the gentleman from Lynchburg [<span className="persName">Mr. SLAUGHTER</span>] .</p>
            <p className="p-in-sp">There are other places in the State where I think this machinery may be as secure, and as profitably used as Lynchburg.</p>
            <div className="speaker" id="sp4748"><span className="persName">Mr. JOHNSON</span>—</div>
            <p className="p-in-sp">Will my friend allow me to say, in justice to my friend from Lynchburg [<span className="persName">Mr. SLAUGHTER</span>] , that it was not at his instance that Lynchburg was indicated as a safe place of deposit for the portion of the machinery to which I have referred.</p>
            <div className="speaker" id="sp4749"><span className="persName">Mr. SUTHERLIN</span>—</div>
            <p className="p-in-sp">I will explain a point to which I desire the attention of the Convention.</p>
            <div className="speaker" id="sp4750"><span className="persName">Mr. JOHNSON</span>—</div>
            <p className="p-in-sp">I will state that it was at the instance of Major General Lee and Col. Dimmock that this location was selected.</p>
            <div className="speaker" id="sp4751"><span className="persName">Mr. SUTHERLIN</span>—</div>
            <p className="p-in-sp">I wish to make a simple statement in justice to myself.</p>
            <p className="p-in-sp">
              When this resolution was offered by my friend from Lynchburg, I told him that there could be found in the Southern portion of the <PageNumber num={489} /> State a position which would be suitable as a place of deposit for this machinery. I told him, in order that that section of the State might be represented, that I would like him to place me or my
              colleague on that committee, and that if he did not think proper to do so, I would like to have a hearing before the committee previous to its making a report.
            </p>
            <div className="speaker" id="sp4752"><span className="persName">Mr. SLAUGHTER</span>—</div>
            <p className="p-in-sp">I have no doubt the gentleman made the request, but I have no recollection of it at all. I had no sectional view in the matter, and made it no consideration in acting upon this measure; my object being to secure the safest place, and the place where the machinery could be made forthwith available for manufacturing purposes.</p>
            <div className="speaker" id="sp4753"><span className="persName">Mr. SUTHERLIN</span>—</div>
            <p className="p-in-sp">
              I would say that I would scorn myself if I could consent to represent my constituents here by securing to them advantages at a cost of any important interests of the State. I felt, however, that it was due to these people that their claim should be presented, and that they should have a fair hearing before that Committee. I would now ask that this report be printed and laid over for
              action. If that is done I shall go more into detail regarding this subject.
            </p>
            <p className="p-in-sp">
              As to the portion of the machinery that is designed to be removed to Richmond, I will state, that I regard that as an immediate necessity. But when you come to make arrangements for a permanent arrangement, the question becomes very different. As to the patriotism of the people of Lynchburg in offering their establishments I have nothing to say; but I feel assured that there are
              advantages possessed by other portions of the State, for the accommodation of this machinery, besides Lynchburg.
            </p>
            <div className="speaker" id="sp4754"><span className="persName">Mr. BARBOUR</span>, of Culpeper—</div>
            <p className="p-in-sp">I desire to make a statement that will show the Convention the importance of carefully deliberating about their action in this matter.</p>
            <p className="p-in-sp">
              I saw a day or so ago a man by the name of Moss, an armorer in one of the Northern cities, who told me that he had recently seen that machinery at Harper's Ferry, and he said, that if you had five millions of dollars in your hands, you could not get up such machinery as that at Harper's Ferry this moment; and the Vice President told me that from what he could hear, he regarded it as of
              incalculable value to the Confederate States.
            </p>
            <p className="p-in-sp">I think we ought to defer action on this subject, and see what the superintendent would have to say in reference to it.</p>
            <div className="speaker" id="sp4755"><span className="persName">Mr. R. Y. CONRAD</span>, of Frederick—</div>
            <p className="p-in-sp">I have some information upon <PageNumber num={490} /> the subject of this machinery, which seems to differ from what has been stated by gentlemen on this floor, and which it may be important to consider in acting upon this ordinance.</p>
            <p className="p-in-sp">
              I am informed by a gentleman, a resident of Winchester, who arrived in the city to-day from Harper's Ferry, that a considerable portion of that valuable machinery-for I have always understood it was valuable-had already been rudely torn down by inexperienced persons there and removed on the railroad to Winchester, a distance of twenty odd miles; and when my informant left Winchester
              they were packing up a portion of the machinery for the purpose of returning it to Harper's Ferry. I think it is incumbent upon the Convention to make some provision for the careful taking down of the machinery in these shops, for irreparable injury might be done to it by its being taken down by unskilled persons. The portion of it referred to was taken down by the soldiers at Harper's
              Ferry, without any instructions from any one competent to manage machinery. The workmen now say they could finish the unfinished work on hand, and supply arms enough to meet the requirements of the Commonwealth to-day. Finding that portion of the machinery which was indispensable to finish the unfinished work, was taken down and sent to Winchester, it was ordered back, and will soon be
              employed in finishing the work which is almost finished.
            </p>
            <p className="p-in-sp">
              Now, inasmuch as the question as to where the machinery is to be removed is one that requires some consideration, I would suggest that the Governor or some one else be charged with the protection of the machinery, and the taking of it down in a proper manner. I regret to hear that Harper's Ferry is not supposed to be safe, because a great deal of loss will result even from the removal
              of that machinery.
            </p>
            <p className="p-in-sp">
              I would mention one other fact. There is no railroad communication from Harper's Ferry now farther than Winchester, except the Baltimore and Ohio Railroad, running east or west. It will cost a large amount to remove the machinery from Winchester to the Manassas Gap Railroad, and, therefore, it is proper to consider whether it could be got to some place of deposit in the neighborhood,
              where it would be safe until the permanent location of it is determined upon, in order to avoid unnecessary transportation and consequent risk of damage.
            </p>
            <div className="speaker" id="sp4756"><span className="persName">Mr. MORTON</span>—</div>
            <p className="p-in-sp">I move that the question be referred to Major General Lee to take such action as he may deem best; and I now call the previous question.</p>
            <PageNumber num={491} />
            <div className="speaker" id="sp4757"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">The gentleman will commit his resolution to writing.</p>
            <p>It was accordingly done, and the resolution was offered as follows:</p>
            <p>Resolved, That Major General Lee be requested at once to provide for the security of the machinery for manufacturing arms, now or recently at Harper's Ferry, and that the report now under consideration be referred to him.</p>
            <div className="speaker" id="sp4758"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">The Chair is reminded that a motion to lay on the table and print, offered by the gentleman from Culpeper [<span className="persName">Mr. BARBOUR</span>] is pending. The gentleman can only offer his resolution as a substitute.</p>
            <div className="speaker" id="sp4759"><span className="persName">Mr. MORTON</span>—</div>
            <p className="p-in-sp">Then I offer it as a substitute.</p>
            <div className="speaker" id="sp4760"><span className="persName">Mr. AMBLER</span>—</div>
            <p className="p-in-sp">I desire to propound an interrogatory. The construction of the resolution is one of doubt. It may be construed to mean to defend it there by military force. I would suggest an amendment by inserting after the words "at Harper's Ferry," the words "by removal or otherwise."</p>
            <div className="speaker" id="sp4761"><span className="persName">Mr. MORTON</span>—</div>
            <p className="p-in-sp">I accept that amendment.</p>
            <p className="p-in-sp">I now renew the call for the previous question.</p>
            <p>The call was sustained, and the main question ordered to be put, whereupon the vote was taken on the resolution, and it was decided in the affirmative-ayes 55, noes 30.</p>
            <div className="speaker" id="sp4762"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">The first business now in order, is the report of the committee appointed to wait on the Commissioner from the Confederate States.</p>
            <div className="speaker" id="sp4763"><span className="persName">Mr. SHEFFEY</span>—</div>
            <p className="p-in-sp">The pending question was upon the ordinance which I offered last evening, and which I shall now read again, for the information of the House.</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>"AN ORDINANCE ratifying and confirming the Convention entered into between the Commissioner of the'Confederate States and the Commissioners of the State of Virginia."</p>
                <p>
                  "Be it ordained, That the Convention entered into on the twenty-fourth of April, eighteen hundred and sixty-one, between Alexander H. Stephens, Commissioner of the Confederate States, and John Tyler, Wm. Ballard Preston, S. McD. Moore, James P. Holcombe, James C. Bruce, and Lewis E. Harvie, Commissioners of Virginia, for a temporary union of Virginia with said Confederate States,
                  under the provisional government, adopted by the said Confederate States, be, and the same is hereby ratified and confirmed on the terms agreed upon by said Commissioners."
                </p>
              </div>
            </div>
            <PageNumber num={492} />
            <div className="speaker" id="sp4764"><span className="persName">Mr. BAYLOR</span>, of Augusta—</div>
            <p className="p-in-sp">I desire again to have the convention reported.</p>
            <p>The Secretary again read the report of the committee, which is as follows :</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>
                  The Commonwealth of Virginia, looking to a speedy union of said Commonwealth and the other slave States with the Confederate States of America, according to the provisions of the Constitution for the Provisional Government of said States, enters into the following temporary convention and agreement with said States for the purpose of meeting pressing exigencies affecting the common
                  rights, interests and safety of said Commonwealth and said Confederacy.
                </p>
                <p>
                  1st. Until the union of said Commonwealth with said Confederacy shall be perfected, and said Commonwealth shall become a member of said Confederacy according to the Constitutions of both powers, the whole military force and military operations, offensive and defensive, of said Commonwealth, in the impending conflict with the United States, shall be under the chief control and
                  direction of the President of said Confederate States, upon the same principles, basis and footing as if said Commonwealth were now, and during the interval, a member of said Confederacy.
                </p>
                <p>
                  2d. The Commonwealth of Virginia will, after the consummation of the union contemplated in this convention, and her adoption of the Constitution for a permanent government of said Confederate States, and she shall become a member of said Confederacy, under said permanent Constitution, if the same occur, turn over to said Confederate States all the public property, naval stores and
                  munitions of war, etc., she may then be in possession of, acquired from the United States, on the same terms and in like manner as the other States of said Confederacy have done in like cases.
                </p>
                <p>3d. Whatever expenditures of money, if any, said Commonwealth of Virginia shall make before the union under the Provisional Government, as above contemplated, shall be consummated, shall be met and provided for by said Confederate States.</p>
                <p>
                  This convention, entered into and agreed to in the city of Richmond, Virginia, on the twenty-fourth day of April, eighteen hundred and sixty-one, by Alexander H. Stephens, the duly authorized Commissioner to act in the matter for the said Confederate States, and John Tyler, William Ballard Preston, Samuel McD. Moore, James P. Holcombe, James C. Bruce and Lewis E. Harvie, parties
                  duly authorized to act in like manner for said Commonwealth of Virginia <PageNumber num={493} /> —the whole subject to the approval and ratification of the proper authorities of both Governments respectively.
                </p>
                <p>In testimony whereof, the parties aforesaid have hereto set their hands and seals, the day and year aforesaid, and at the place aforesaid, in duplicate originals.</p>
                <p>JOHN TYLER, [Seal.]</p>
                <p>WM. BALLARD PRESTON, [Seal.]</p>
                <p>S. McD. MOORE, [Seal.]</p>
                <p>JAMES P. HOLCOMBE, [Seal.]</p>
                <p>JAMES C. BRUCE, [Seal.]</p>
                <p>LEWIS E. HARVIE, [Seal.]</p>
                <p>Committee of the Convention.</p>
                <p>ALEXANDER H. STEPHENS, [Seal.]</p>
                <p>Commissioner of the Confederate States.</p>
              </div>
            </div>
            <div className="speaker" id="sp4765"><span className="persName">Mr. SCOTT</span>, of Powhatan—</div>
            <p className="p-in-sp">I move to pass by the consideration of this subject for a few minutes.</p>
            <p>The motion was agreed to.</p>
            <div className="speaker" id="sp4766"><span className="persName">Mr. SCOTT</span>—</div>
            <p className="p-in-sp">I offer the following resolution:</p>
            <p className="p-in-sp">"Resolved, That the Committee on Military Affairs be authorized to employ a clerk."</p>
            <p>The resolution was adopted.</p>
            <div className="speaker" id="sp4767"><span className="persName">Mr. SUTHERLIN</span>—</div>
            <p className="p-in-sp">I offer the following resolution:</p>
            <p className="p-in-sp">"Resolved, That the Committee on Military Affairs inquire into the expediency of authorizing the Governor of this Commonwealth to receive into the service of the State, organized battalions, regiments and brigades of volunteers, as well as companies."</p>
            <div className="speaker" id="sp4768"><span className="persName">Mr. BALDWIN</span>, of Augusta—</div>
            <p className="p-in-sp">I suppose, sir, that the Governor, as commander-in-chief, has the organization of all the forces as they come in; and he has been instructed already to have all battalions and regiments, heretofore organized, mustered into the service, and he has the appointment, as a matter of course, of field officers.</p>
            <div className="speaker" id="sp4769"><span className="persName">Mr. SUTHERLIN</span>—</div>
            <p className="p-in-sp">I withdraw the resolution.</p>
            <div className="speaker" id="sp4770"><span className="persName">Mr. SCOTT</span>, of Powhatan—</div>
            <p className="p-in-sp">I object to its withdrawal, because I think the gentleman misunderstands the ordinance as it exists now.</p>
            <p className="p-in-sp">That ordinance does not authorize the Governor to receive into the service any other than volunteers organized into companies. He has no authority to receive volunteers organized into regiments. There are volunteers in the State already organized into regiments; but the governor has no power to receive them except as companies.</p>
            <p className="p-in-sp">
              Again, sir, it may be necessary-I think it will be expedient, at <PageNumber num={494} /> least, that the Governor should have to receive volunteers that hereafter may be organized into regiments. I will state a fact that has already occurred in this connection. Four companies from the county of Chesterfield propose to raise a regiment of volunteers in
              addition to others now organized, provided they have an assurance from the Governor that they will be received into the organization of the State as a regiment. This was regarded doubtful, and the enterprise was accordingly abandoned.
            </p>
            <div className="speaker" id="sp4771"><span className="persName">Mr. HOLLADAY</span>, of Norfolk county—</div>
            <p className="p-in-sp">If I understand aright, the very object of giving to the Governor the appointment of field officers conflicts with that resolution.</p>
            <p className="p-in-sp">
              Now, it seems to me, that the resolution under consideration comes directly in conflict with the sense of propriety and the cause and motives which have heretofore governed this Convention; because it proposes now that the Governor shall receive regiments already organized, which, as a matter of course, carries with it the officers selected by them; and it takes from him the discretion
              of selecting fit and suitable persons.
            </p>
            <p className="p-in-sp">I hope, therefore, that the resolution will not prevail.</p>
            <p>The question was then taken on the resolution, and it was decided in the negative.</p>
            <div className="speaker" id="sp4772"><span className="persName">Mr. NELSON</span>, of Clarke—</div>
            <p className="p-in-sp">I ask leave of the house to change my vote on the ordinance of secession from the negative to the affirmative.</p>
            <p>Leave was granted.</p>
            <p>The consideration of the report of the committee appointed to wait upon the Commissioner from the Confederate States was resumed.</p>
            <div className="speaker" id="sp4773"><span className="persName">Mr. CONRAD</span>, of Frederick—</div>
            <p className="p-in-sp">Is it in order to offer a resolution in reference to the report before the house?</p>
            <div className="speaker" id="sp4774"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">The question now before the Convention is the ordinance offered by the gentleman from Smyth [<span className="persName">Mr. SHEFFEY</span>] . It is competent for the gentleman to move an amendment to it.</p>
            <div className="speaker" id="sp4775"><span className="persName">Mr. CONRAD</span>—</div>
            <p className="p-in-sp">I offer the following as an amendment:</p>
            <p className="p-in-sp">"Resolved, That the report of the committee to confer with the Commissioner from the Confederate States, be recommitted to said committee with direction to confer with the Governor of the Commonwealth and General Lee."</p>
            <p className="p-in-sp">
              The first clause in this compact provides, that "the whole military force, and military operations, offensive and defensive, of said Commonwealth, in the impending conflict with the United States, shall be under the chief control and direction of the President of said <PageNumber num={495} /> Confederate States," and this, as I understand it, is to take
              effect immediately. Now, sir, I am unable to vote for this, especially without a previous conference which is suggested in the amendment that I have offered, with the Governor and General Lee, for this reason, that the Constitution of Virginia makes the Governor of the Commonwealth the Commander-in-Chief of all the forces of the Commonwealth. We are, therefore, by this Convention,
              superseding the Governor in violation of the Constitution of the State. We have also elected here Gen'l. Lee, as the Commander-in-Chief, under the Governor, of the military and naval forces of the Commonwealth, and so far as appears, without any conference or notice to him, he also is to be superseded in his command, and the office of Commanderin-Chief conferred upon the President of
              the Confederate States.
            </p>
            <p className="p-in-sp">
              I am, therefore, entirely unwilling, while I desire to throw no impediment /in the way of the action of this Convention, and while I recognize the great importance of making some arrangement for our defence at once, I say I am unwilling, at least without the assent of these two functionaries, to vote for any provision of this sort. If the Governor of the Commonwealth, yielding to the
              supposed necessity for immediate action, will concur in this action of ours, and recommend it, so far as he is concerned, as at present advised, I may be willing to consent to vote for it. And if General Lee will previously consent to this arrangement, I should waive any objections certainly as far as it would supersede him
            </p>
            <p className="p-in-sp">
              But I beg the Convention to look at the consequences. We are not only violating the Constitution of the State, but we are interfering in this question of military rank-one of the most delicate that can be touched; and there is a possibility that the adoption of this compact may deprive us of the services of a valuable officer, who has just been elected to the chief command of the
              military forces of the Commonwealth.
            </p>
            <p className="p-in-sp">I think, therefore, that at least this ought to be recommitted to the committee, in order that they might confer with the Governor and General Lee, and obtain their consent and approval of this compact before it is offered to the vote of this Convention.</p>
            <div className="speaker" id="sp4776"><span className="persName">Mr. JAMES P. HOLCOMBE</span>—</div>
            <p className="p-in-sp">I can relieve, certainly in part, the objections to this report of the gentleman from Frederick [<span className="persName">Mr. CONRAD</span>] .</p>
            <p className="p-in-sp">
              As a member of the committee, I conferred with General Lee upon this subject. His answer and the position which he assumed, it is due to him that this whole State should know. I said to him that it may be <PageNumber num={496} /> proper in the judgment of this Convention, to connect the State of Virginia with the Confederate States; and in that
              contingency, that the President of the Confederate States would, as a matter of course, be the commander-in-chief of the army and navy of the Confederate States; that further, the arrangements of the Confederate States in reference to its army might, in some way, possibly result in placing him in a position which his feelings as a soldier might render it disagreeable for him to accept.
              I said that as, being a civilian, I knew nothing of these punctilios of military rank; but I desired to know whether there was any contingency tnat might arise in which that sentiment could in any way be offended.
            </p>
            <p className="p-in-sp">He said at once, I trust you will not think of me one moment in any arrangement which you may desire to make for the public welfare. Do not, I beg you, permit yourselves for an instant to be embarrassed by any consideration personal to me.</p>
            <p className="p-in-sp">I replied, in substance, that is an answer, sir, such as I anticipated from you; but, in my judgment, it makes it more incumbent upon us to take care that your feelings as a soldier should in every way be respected.</p>
            <p className="p-in-sp">
              I asked him whether there was any contingency in which he could be under any other command than under the regulations of the army of the Confederate States; and I was informed by him that the only possible contingency that could arise would be the appointment of a Major General of their regular army by the Confederate States. They have now only Brigadier Generals, and of course Gen.
              Lee would not be under their command. If they appoint a Major General of their regular army, I have an idea that the choice would fall upon him. I went to the Commissioner of the Confederate States, and he said that it was not a matter which, constitutionally, or in accordance with their laws, could be regulated by treaty; but that I may be perfectly satisfied that every arrangement
              would be made in conformity with the wishes and feelings of this gentleman, and every regard paid to them; that they appreciated, in the highest degree, his services, and he had no doubt that command would be tendered to him, if he chose to accept it from these States. He said that there was no man in their service, unless Gen. Twiggs desired command, who was before him in command,
              that would outrank him.
            </p>
            <p className="p-in-sp">
              In reference to the Governor of the Commonwealth, we had no conference with him. We had supposed that there would be no question of military feeling on his part; and whether we shall become members of this provisional government or enter into a treaty for the <PageNumber num={497} /> time being, in either event, it seems to me that the President of the
              Confederate States should have the direction of the military affairs of the State and of the Confederacy.
            </p>
            <div className="speaker" id="sp4777"><span className="persName">Mr. CONRAD</span>, of Frederick—</div>
            <p className="p-in-sp">The difficulty, in my mind, is only removed in part by the reply of the gentleman.</p>
            <p className="p-in-sp">
              I see in this compact no provision whatever for the army of Virginia when we come under the control of the Confederate States. General Lee, if he has any command at all, as it seems to me, it must be under a commission from the Confederate States. The forces of Virginia are not provided for at all. The rank of the officers is not determined, and they may be lowered below their
              appropriate rank at the pleasure of the Confederate States authorities.
            </p>
            <p className="p-in-sp">
              In regard to the Governor of the Commonwealth, the difficulty I mentioned was not upon the supposition that the Governor would undertake the actual command of the forces, and that in that way the question of military etiquette might arise; but he is, by the Constitution, Commander-in-Chief of all the forces of Virginia, and Gen. Lee is next under him in command. According to this
              compact, the orders to Gen. Lee would be issued not by the Governor of Virginia, but by the President of the Confederate States, and that only after a recognition of Gen. Lee as holding rank under the Confederate States.
            </p>
            <p className="p-in-sp">
              It does seem to me, that in this particular, the compact requires some revision; and it is with a view of removing this difficulty, that I propose that the same committee shall take it; consult the governor and Major General Lee; procure their approval of this arrangement, and insert such provision in regard to the present forces and commanding officers in Virginia as shall be provided
              for in any compact that may be made. Otherwise, I would persist that there should be no commanding officer at all of the forces of Virginia.
            </p>
            <p className="p-in-sp">At all events, it would be well to consult both these officers on the subject.</p>
            <div className="speaker" id="sp4778"><span className="persName">Mr. TYLER</span>—</div>
            <p className="p-in-sp">I desire to say that the gentleman will not accomplish his object by an ordinance. You could only do it by a stipulation emanating from the President of the Confederate States. It could not come from the Vice President, nor could any Minister from the Confederate States enter into a treaty stipulation without express command from the President of the Confederacy.</p>
            <p className="p-in-sp">
              In relation to this matter of rank, for instance, in order to present the command of General Lee, he must act under a regular commission from the President of the Confederate States. He can <PageNumber num={498} /> only act by virtue of a commission when he comes to command Confederate forces in the field. When Virginia is acting alone General Lee is
              unquestionably the Commander-in-Chief, but when he engages in the Confederate States service, he takes rank with General Twiggs, who is his senior in command.
            </p>
            <p className="p-in-sp">
              There is no command in the Confederate army beyond a Brigadier. General Lee is our Major General; and if you throw the commander of a regular corps in the army of the United States into service with the commander of your militia, the latter being an older commission, possesses a higher grade than the officer of the regular army, and the commander of the militia commands him. So it is
              with General Lee.
            </p>
            <p className="p-in-sp">But, sir, I did not rise to make a speech. I will merely say that all the ordinances in the world can accomplish nothing, for there is no party to settle this question of rank but the President of the Confederate States.</p>
            <p className="p-in-sp">My own humble impression is, that if ever it becomes necessary, he will be tendered a commission in behalf of the Confederate States. I have a sort of conviction that the result will follow from the great confidence which, I understand, the Confederate States authorities repose in the military skill and ability of Gen. Lee.</p>
            <div className="speaker" id="sp4779"><span className="persName">Mr. CONRAD</span>, of Frederick—</div>
            <p className="p-in-sp">As this is to go for ratification to the Congress of the Confederate States, it seems to me that it would in no way embarrass this negotiation if a provision was inserted that Gen. Lee should be ranked as he is now, and that the officers now ranked in the Virginia forces should be held in the same rank.</p>
            <div className="speaker" id="sp4780"><span className="persName">Mr. WICKHAM</span>, of Henrico—</div>
            <p className="p-in-sp">
              I desire to say that any stipulation in this treaty made with a view to the personal advancement of General Lee, would be unacceptable to him. I am authorized to say that he has tendered his services to the State of Virginia without any regard to the position he was to occupy, and that it would be by no means agreeable to him that his position should be made any consideration in the
              agreements to be entered into between Virginia and the Confederate States.
            </p>
            <div className="speaker" id="sp4781"><span className="persName">Mr. TYLER</span>—</div>
            <p className="p-in-sp">I should most cordially unite with the gentleman from Frederick [<span className="persName">Mr. CONRAD</span>]
              and I am sure this Convention would unanimously unite with him in the strongest declaration of our desire to have things remain under the command of General Lee, to secure to him the command which we have attempted to confer upon him here-that of commander-in-chief of the Confederate forces, should such an office be created. Any sort of resolution declaratory of our
              <PageNumber num={499} /> confidence in General Lee, and expressing our desire that he might be retained in the highest possible military command, consistent with the good of the Confederate States, I would, most assuredly, with all my heart, unite with the gentleman in adopting. But there is no necessity for this. Vice President Stephens has no authority
              to enter into any negotiations upon this subject. It can only proceed from the President of the Confederate States.
            </p>
            <div className="speaker" id="sp4782"><span className="persName">Mr. HARVIE</span>—</div>
            <p className="p-in-sp">I am very willing to give gentlemen every opportunity to discuss this question fully; but it is important that the report should be acted upon to-day. I don't think that the resolution of the gentleman from Frederick [<span className="persName">Mr. CONRAD</span>] , relates properly to the merits of the question; and, in order to decide the question whether the house so regards it or not, I will call the previous question.</p>
            <p>The call was sustained, and the main question ordered to be put. <span className="persName">Mr. MOORE</span>, of Rockbridge-I have seen the Governor, and he does not consider himself affected by this agreement at all.</p>
            <div className="speaker" id="sp4783"><span className="persName">Mr. CONRAD</span>—</div>
            <p className="p-in-sp">I call for the yeas and nays upon the main question.</p>
            <p>The call was sustained, and the vote being taken on the amendment offered by <span className="persName">Mr. CONRAD</span>, resulted-yeas 18, nays 76-as follows:</p>
            <p>YEAS-The President, Messrs. Aston, Baldwin, Baylor, Campbell, Carter, R. Y. Conrad, Early, Gillespie, E. B. Hall, P. C. Johnston, McComas, Marshall, Pendleton, Pugh, Rives, Sharp and Southall.</p>
            <p>
              NAYS-Messrs. Ambler, James Barbour, Berlin, Blakey, Boisseau, Borst, Bouldin, Boyd, Branch, Bruce, Cabell, Caperton, Cecil, Chambliss, Chapman, Coffman, Conn, J. H. Cox, Critcher, Deskins, Dorman, Echols, Fisher, Flournoy, Forbes, French, Garland, Gravely, Gregory, Goggin, John Goode, Jr., Addison Hall, Cyrus Hall, L. S. Hall, Harvie, Haymond, Holcombe, Holladay, Isbell, Marmaduke
              Johnson, Kilby, Lawson, Leake, Macfarland, Charles K. Mallory, J. B. Mallory, Marye, Miller, Moffett, Montague, Morris, Morton, Moore, Neblett, Nelson, Preston, Randolph, Richardson, Wm. C. Scott, Seawell, Sheffey, Sitlington, Slaughter, Speed, Staples, Sutherlin, Tayloe, R. H. Turner, F. P. Turner, Tyler, Waller, Wickham, Williams, Wilson, Woods and Wysor.
            </p>
            <p>So the amendment was rejected.</p>
            <p>The question then recurred upon the adoption of the ordinance offered by <span className="persName">Mr. SHEFFEY</span>.</p>
            <div className="speaker" id="sp4784"><span className="persName">Mr. BALDWIN</span>, of Augusta—</div>
            <p className="p-in-sp">
              It seems to me, sir, that the subjects before this Convention are of too vast importance to be dealt with in the very brief and hasty way that they are dealt with here. I don't propose to go into any discussion on the question of the power of this Convention to make an arrangement of this sort. I think I entered into a stipulation some days back in this Convention not to debate in this
              body any more questions as to its powers. I deem it to be but just <PageNumber num={500} /> to myself to say, that I have no idea at all that this Convention has any power to make such an agreement as proposed by this paper. I understand that although we have revolutionized the government of the United States, and separated ourselves from that government
              and the other States composing it, that we have not as yet undertaken to revolutionize the State government. But I take it that every member of this body will recognize the fact that we are assembled here under the Constitution of the State, subject to the Constitution of the State in all our actions-having the power to change the Constitution, it is true, when the changes we make are
              ratified by the people of the State-but having no other power to change the Constitution of Virginia. I have never understood that this Convention had, as yet, come to revolutionize the State Government. It is true that we have, in a number of instances, assumed, under the pressure of the circumstances by which we have been surrounded, to exercise power which could not be justified by
              a fair construction of the Constitution of the State, and which could only be excused by the press of the emergency; but we never have undertaken yet to go as far as is undertaken by this agreement. I take it, sir, that the authority of this Convention is plainly limited, so that we shall not change the relations between this State and the General Government of the United States
              without the assent of the people of the State; and I take it that we have no right any more to enter into new governmental connections with another Government without a like consent of the people; and if we had the power, so far as I am concerned, I would not be willing to exercise it, because it so plainly affects the rights of the people without consulting them at the polls.
            </p>
            <p className="p-in-sp">But, sir, I don't propose to discuss that question at all. I merely wish to state my own opinion and belief that this Convention has no power whatever to do any one of a number of things that are proposed in this paper.</p>
            <p className="p-in-sp">
              In the first place, we have no authority, as was stated by the gentleman from Frederick, to supersede the Executive functions of the Governor of this State. In the next place, we have no authority whatever to undertake to place our people under the military control of any foreign potentate whatever, even with the Governor's consent. The Governor himself and all the authorities of the
              State have no right to demand of our people that they shall give supreme obedience to another government. It is true that under the treaty making power as an independent sovereignty, we have a right to contribute our forces to a common, offensive and defensive alliance; but our government <PageNumber num={501} /> has not a right to depart with the power
              of governing our own people. It is not merely a power and a right, but government is a duty as well, and our government has no right to depart from the duty of governing every citizen of the Commonwealth, according to the Constitution and laws of Virginia; and we assume a fearful responsibility when we undertake to say that all the military strength of this Commonwealth, by which we
              mean that the life, the blood, the muscle, the spirit, the energy of every man in the Commonwealth is to be transferred and made subject to the supreme control of a foreign power. We undertake a fearful responsibility; and, as it seems to me, we undertake to let go a high duty when we abandon the government of the people of this State and the protection of their rights.
            </p>
            <p className="p-in-sp">
              So far as I am concerned, I am not willing to do that. I am perfectly willing, and think it desirable on every ground whatever, that as a sovereign, independent State, in peril of a great conflict just at hand, we should have the alliance of other sovereign States in a like situation with ourselves, of like interests and like sympathies. But, sir, the world's history is full to
              overflowing of instances of alliances between sovereign States for offensive and defensive warfare, without either one undertaking to place its entire military force and all its citizens under the supreme control of the government of the other. I can see no reason, sir, assigned why, in the exercise of the treaty-making power, the State of Virginia on the one part and the Confederate
              States upon the other, may not agree to the terms upon which, as equals, as co-equal sovereignties, they shall wage a common war for a common cause. I have never heard of an instance-I venture to say there is no instance in the history of the world-where, between equal States, the power of governing the people of the one is to be surrendered over to the control of the other. How was it
              with England and France in the Crimean war? They co-operated together, and I suppose that the terms of that co-operation were agreed upon between them, and carried out according to the terms of the convention. Who ever heard that the people of England agreed that the Empire of France should have the supreme control and direction, according to the laws of France, of the whole military
              of England? Or vice versa, that the people of France agreed that England should have the supreme control and direction, according to the laws of England, of the whole military power of France?
            </p>
            <p className="p-in-sp">
              Sir, it seems to me that this is a matter unnecessary, wholly unnecessary. It may be said-and I have no doubt that that is the chief consideration in this matter-that the Confederate States undertake <PageNumber num={502} /> to bear the whole burden of this conflict. That certainly is a very important affair. But if they bear the expenses of the war, and
              we become a part of the Confederate States, we will have to bear, after all, a very large portion of the expenses. But it seems to me that there is no necessity at all for this course. A mere pecuniary and financial necessity cannot justify this Convention, cannot justify this government in parting with a responsibility which God Almighty has placed, under these circumstances, in their
              hands-the responsibility of guiding and governing the destinies of the people of this Commonwealth. You may talk about resisting civil power, but, in flagrant war, he who has the unrestrained military control of the land and naval forces-not only of those called into the field, but the power to call as many more as they want-wields a power which it is vain to attempt resisting.
            </p>
            <p className="p-in-sp">I want to know from the committee whether Virginia has any more discretion in this matter.</p>
            <div className="speaker" id="sp4785"><span className="persName">Mr. HOLCOMBE</span>—</div>
            <p className="p-in-sp">
              That provision to which the gentleman refers, making the President of the Confederate States the Commander-inChief of the military and the naval forces, is a provision that will last only for four or five days. It will last only until we go into the Provisional Government, or until our own application to go into that Government is granted, and it confers upon the President of the
              Confederate States only the direction of the military power of the Commonwealth, and no authority at all except through our own Governor and officers, and in accordance with our own laws.
            </p>
            <div className="speaker" id="sp4786"><span className="persName">Mr. BALDWIN</span>—</div>
            <p className="p-in-sp">I understand the matter. I do not understand it to be in contemplation that we shall go immediately into the Provisional Government.</p>
            <div className="speaker" id="sp4787"><span className="persName">Mr. HOLCOMBE</span>—</div>
            <p className="p-in-sp">
              The ordinance before the Convention is merely upon this stipulation. The treaty stipulation and the ordinance adopting the Constitution of the Provisional Government have been brought in together, and, as it seems to me, with due deference to the Chair, should have been voted upon together in the Convention. All the stipulations are merely intended to cover the brief interval of time
              that will elapse between the ratification of the treaty, and the action of the Provisional Government of the Confederate States. The Congress of that Confederacy meets on Monday, and we propose to follow this proposition with a proposition to send Commissioners to that Congress.
            </p>
            <div className="speaker" id="sp4788"><span className="persName">Mr. BALDWIN</span>—</div>
            <p className="p-in-sp">
              I don't so read it. It reads, "Until the union of said Commonwealth with said Confederacy shall be perfected, and said <PageNumber num={503} /> Commonwealth shall become a member of said Confederacy according to the Constitution of both powers, the whole military force and military operations, offensive and defensive of said Commonwealth in the impending
              conflict with the United States, shall be under the chief control and direction of the President of said Confederate States, upon the same principles, basis and footing as if said Commonwealth were now, and during the interval, a member of said Confederacy."
            </p>
            <p className="p-in-sp">Do I understand that it is proposed to take this Government into the control of the Confederate States in anticipation of the vote at the polls in May?</p>
            <div className="speaker" id="sp4789"><span className="persName">Mr. HOLCOMBE</span>—</div>
            <p className="p-in-sp">It says that if the ordinance is rejected, this whole subject goes to the ground.</p>
            <div className="speaker" id="sp4790"><span className="persName">Mr. BALDWIN</span>—</div>
            <p className="p-in-sp">I understand it is proposed to take us into the Confederate States and place us under the power of the Confederate States in advance of the decision of the question to be submitted to the people at the polls. So far as I am concerned, I do not believe that we have any such power under the Heavens-none whatever.</p>
            <p className="p-in-sp">But, sir, there are some other matters that I do not understand in regard to this Convention. I have not been able to see at all where is the necessity which impels us to become members of the Confederacy prior to the action of our people.</p>
            <p className="p-in-sp">
              I have been under the impression, and am now, that the result has been brought about which cannot be avoided; that sooner or later the entire South must be united under a common government. All that I desire now in regard to this matter is that Virginia shall go into that government under circumstances that give her the best possible advantage in the terms on which she goes in. And,
              sir, as a part of the advantages which she is to derive by going into that government, I take it that the matter which we have been contemplating ever since the passage of the ordinance of secession, was to have a conference with our sister border slave States in regard to our common interests; and, so far as I am concerned, whilst I recognize the fact that we are in a position that
              compels us to make an alliance offensive and defensive in behalf of a common cause, yet I am unwilling to let go the power of negotiation; the right of acting as equal in any assemblage of the States South, and of taking a position of just equality in all forms of negotiation. I object to that, mainly as a matter of policy. I say nothing of the matter of power that I have suggested. It
              seems to me that this is an unnecessary exercise of power which does not belong to us, and an inexpedient exercise of it besides. This is one thing we ought to retain, subject to our own action.
            </p>
            <PageNumber num={504} />
            <p className="p-in-sp">
              The future, as yet, is not sufficiently developed to enable us to see what is the true policy of the war in which we are engaged. I have a very recent conviction in regard to the policy of that war; but I am not military man enough to say how far it may be right or wrong. I do not wish to be venturing opinions upon subjects with which I am not acquainted. But Virginia, it seems to me,
              ought to have the determination of this question. I think that Virginia ought not now nor at any time to give up, for even a single day, any attribute of a sovereign State, dealing with a sovereignty, that she ought not go into the Confederate States and give up her voice as a sovereign State dealing with a sovereign nation; that she ought not to give up to four or five other States a
              question which involves the integrity of her soil and the safety of all her people. It seems to me that we are surrendering too much in this alliance. As to the assistance that we are to expect from these Confederate States, I look to their assistance with pleasure and satisfaction always.
            </p>
            <p className="p-in-sp">
              I am perfectly willing to reciprocate that assistance to the full extent of our power. I have no wish to do anything-God forbid I would do anything-to impair the efficiency of the military associations of these different governments. But I don't wish Virginia to be so far overcome by the anticipations of the conflict as to lose all prudential considerations in regard to her future
              destiny. I want her to look this question in the face calmly. She has, I am satisfied, the strength of will to defend her soil and to repel any invasion that may be attempted upon her. She is not in so helpless a situation as to give up everything like a prudential regard for her own safety. I prefer that a matter of this importance should be gone on with more cautiously, leaving
              Virginia, meanwhile, to defend the rights of her citizens.
            </p>
            <p className="p-in-sp">
              I have not seen this convention, except by a mere casual reading of it. I am not prepared to say that there may not be other objections which have not come under my notice; but because we have no power to overthrow the Constitution of the State, because our rights, as a class, are not recognized in this Convention, and, because we refuse to Virginia her equal and full voice in the
              future management of the war, I shall be compelled to vote against the ratification of this Convention; and, while I do so, I am very anxious to unite in every measure and sustain every policy which shall, in my judgment, best subserve the interests of the State in this emergency.
            </p>
            <p className="p-in-sp">So far as the capacity of the President of the Confederate States is concerned, I know he has a very high reputation; but as regards <PageNumber num={505} /> his capacity for a war of this character, that is to be tried. I am satisfied to see him tried, if compatible with the sovereignty, the independence and the safety of Virginia.</p>
            <div className="speaker" id="sp4791"><span className="persName">Mr. FLOURNOY</span>—</div>
            <p className="p-in-sp">I call the previous question.</p>
            <p>The call was sustained, and the main question ordered to be put, which was the ordinance offered by <span className="persName">Mr. SHEFFEY</span>.</p>
            <div className="speaker" id="sp4792"><span className="persName">Mr. BORST</span>—</div>
            <p className="p-in-sp">I demand the yeas and nays.</p>
            <p>The demand was sustained.</p>
            <p>The vote was then taken, and resulted-yeas 80, nays 16, as follows:</p>
            <p>
              YEAS-Messrs. Ambler, James Barbour, Berlin, Blakey, Blow, Jr., Boisseau, Borst, Bouldin, Boyd, Branch, Bruce, Cabell, Caperton, Cecil, Chambliss, Chapman, Coffman, Conn, James H. Cox, Critcher, Deskins, Dorman, Echols, Fisher, Flournoy, Forbes, French, Garland, Gravely, Gregory, Jr., Goggin, John Goode, Jr., Addison Hall, Cyrus Hall, L. S. Hall, Harvie, Haymond, Holcombe, Holladay,
              Isbell, Marmaduke Johnson, Kilby, Lawson, Leake, Macfarland, Charles K. Mallory, James B. Mallory, Marshall, Marye, Sr., Miller, Moffett, Montague, Morris, Morton, Moore, Neblett, Nelson, Preston, Pugh, Randolph, Richardson, Rives, Wm. C. Scott, Seawell, Sheffey, Sitlington, Slaughter, Speed, Staples, Sutherlin, Tayloe, Robert H. Turner, Franklin P. Turner, Tyler, Waller, Wickham,
              Williams, Wilson, Woods, Wysor-80.
            </p>
            <p>NAYS-Messrs. John Janney [President] , Aston, Baldwin, Brent, Campbell, Carter, R. Y. Conrad, Early, Gillespie, Ephraim B. Hall, Peter C. Johnston, McComas, Pendleton, Sharp, Southall, Spurlock-16.</p>
            <p><span className="persName">Mr. BAYLOR</span>, of Augusta, when his name was called, asked to be excused from voting, and the Convention agreed to excuse him.</p>
            <div className="speaker" id="sp4793"><span className="persName">Mr. RIVES</span>, of Prince George—</div>
            <p className="p-in-sp">Before I vote I desire to know whether the ordinance is intended only to ratify the Convention which has been reported, or whether it is intended also to adopt the provisional government.</p>
            <div className="speaker" id="sp4794"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">It is intended only to ratify the Convention, and not the ordinance adopting the provisional Constitution.</p>
            <div className="speaker" id="sp4795"><span className="persName">Mr. RIVES</span>—</div>
            <p className="p-in-sp">Under these circumstances I vote aye.</p>
            <div className="speaker" id="sp4796"><span className="persName">Mr. FLOURNOY</span>—</div>
            <p className="p-in-sp">I understand from the explanation of the Chair, that the ordinance just adopted did not embrace the ordinance reported by the committee, which proposes to adopt the provisional Constitution.</p>
            <p className="p-in-sp">I ask the previous question upon that ordinance.</p>
            <p>The call was sustained, and the main question ordered to be put, which was as follows:</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>AN ORDINANCE for the adoption of the Constitution of the Provisional Government of the Confederate States of America.</p>
                <PageNumber num={506} />
                <p>
                  We, the delegates of the people of Virginia, in Convention assembled, solemnly impressed with the perils which surround the Commonwealth, and appealing to the Searcher of Hearts for the rectitude of our intentions in assuming the grave responsibility of this act, do, by this ordinance, adopt and ratify the Constitution of the Provisional Government of the Confederate States of
                  America, ordained and established at Montgomery, Alabama, on the eighth day of February, eighteen hundred and sixty-one: Provided, That this ordinance shall cease to have any legal operation or effect if the people of this Commonwealth, upon the vote directed to be taken on the ordinance of secession passed by this Convention on the seventeenth day of April, eighteen hundred and
                  sixty-one, shall reject the same.
                </p>
              </div>
            </div>
            <div className="speaker" id="sp4797"><span className="persName">Mr. CONRAD</span>—</div>
            <p className="p-in-sp">I call for the yeas and nays: upon that question.</p>
            <p>
              The call was sustained, and the vote being taken, resulted yeas 76, nays 19, as follows: <span className="note" id="Note48"><span className="noteLabel">2</span>According to the Journal, the vote was 75 to 19; the Journal's list has Robert Y. Conrad among the negative votes, and not among the affirmative votes. The Journal states that Mr. Morton moved to record the vote.</span>
            </p>
            <p>
              YEAS-Messrs. Ambler, James Barbour, Berlin, Blakey, Boisseau, Borst, Bouldin, Boyd, Branch, Bruce, Cabe11, Caperton, Cecil, Chambliss, Chapman, Coffman, Conn, R. Y. Conrad, J. H. Cox, Critcher, Deskins, Dorman, Echols, Fisher, Flournoy, Forbes, French, Garland, Gravely, Gregory, Goggin, John Goode, Jr., Addison Hall, Cyrus Hall, L. S. Hall, Harvie, Haymond, Holcombe, Isbell, Marmaduke
              Johnson, Kilby, Lawson, Leake, Macfarland, C. K. Mallory, J. B. Mallory, Marye, Miller, Moffett, Montague, Morris, Morton, Moore, Neblett, Nelson, Preston, Randolph, Richardson, Wm. C. Scott, Seawell, Sheffey, Sitlington, Slaughter, Speed, Staples, Sutherlin, Tayloe, R. H. Turner, Franklin P. Turner, Tyler, Waller, Wickham, Williams, Wilson, Woods, Wysor-76.
            </p>
            <p>NAYS-Messrs. John Janney [President] , Aston, Brent, Campbell, Carter, Early, Gillespie, Ephraim B. Hall, Holladay, Peter C. Johnston, McComas, Marshall, Pendleton, Pugh, Rives, Sharp, Southall, Spurlock-19.</p>
            <p>So the ordinance was adopted.</p>
            <div className="speaker" id="sp4798"><span className="persName">Mr. MORTON</span>, of Orange and Green—</div>
            <p className="p-in-sp">I submit the following resolution:</p>
            <p className="p-in-sp">"Resolved by this Convention, That the President of the Confederate States, and the constituted authorities of the Confederacy, be, and they are hereby cordially and respectfully invited, whenever, in their opinion, the public interest or convenience may require it, to make the city of Richmond, or some other city in this State, the temporary seat of Government."</p>
            <div className="speaker" id="sp4799"><span className="persName">Mr. MORTON</span>—</div>
            <p className="p-in-sp">Upon that resolution I call the previous question.</p>
            <p>The call was sustained.</p>
            <div className="speaker" id="sp4800"><span className="persName">Mr. CONRAD</span>, of Frederick—</div>
            <p className="p-in-sp">I call the yeas and nays upon ordering the main question to be put.</p>
            <PageNumber num={507} />
            <div className="speaker" id="sp4801"><span className="persName">Mr. MORTON</span>—</div>
            <p className="p-in-sp">I withdraw the call for the previous question, and shall also withdraw the resolution for the present.</p>
            <div className="speaker" id="sp4802"><span className="persName">Mr. MORRIS</span>, of Caroline—</div>
            <p className="p-in-sp">I beg leave to offer the following resolution:</p>
            <p className="p-in-sp">"Resolved, That the injunction of secrecy be so far removed as to allow the publication of the convention entered into between the Commissioners of the State of Virginia and the Commissioner of the Confederate States, and the ordinance of Union with the Confederate States under the provisional government thereof."</p>
            <p>The resolution was adopted.</p>
            <div className="speaker" id="sp4803"><span className="persName">Mr. WILSON</span>—</div>
            <p className="p-in-sp">I move that the Convention take a recess till 7 o'clock, P. M.</p>
            <div className="speaker" id="sp4804"><span className="persName">Mr. KILBY</span>—</div>
            <p className="p-in-sp">I move we adjourn.</p>
            <p>The question was taken on the motion to adjourn, and lost.</p>
            <p>The question then recurred on the motion to take a recess until 7 o'clock, and it was decided in the affirmative.</p>
            <p>The Convention accordingly adjourned till that hour.</p>
            <div className="session" id="vsc1965.v4.2.10.1">
              <h3><span className="headingNumber">1.10.1. </span><span className="head">NIGHT SESSION</span></h3>
              <p>A few members assembled at the appointed hour of meeting, but a quorum was not in attendance, and, on motion of <span className="persName">Mr. MALLORY</span>, of Brunswick, the Convention adjourned.</p>
            </div>
          </div>
          <div className="day" id="vsc1965.v4.2.11">
            <PageNumber num={508} />
            <h2><span className="headingNumber">1.11. </span><span className="head">ELEVENTH DAY</span></h2>
            <div className="dateline">Friday, <span className="date">April 26</span></div>
            <p>The Convention met at 12 o'clock.</p>
            <p>Prayer by the Rev. Mr. Hatcher, of the Baptist Church.</p>
            <p>The Secretary called over the list of committees in conformity with the rule adopted on Wednesday, when</p>
            <p><span className="persName">Mr. MACFARLAND</span>, from the committee of finance, submitted</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>AN ORDINANCE providing for deficiencies in the Civil and Military Contingent Funds.</p>
                <p>Be it ordained, That the Auditor of Public Accounts place to the credit of the Civil and Military Contingent Funds, on the order of the Governor of the Commonwealth, out of any money in the Treasury, not otherwise appropriated, such sum or sums of money as will provide for deficiencies now existing or hereafter to arise in said funds or either of them.</p>
                <p>This ordinance shall be in force until ten days after the commencement of the next session of the General Assembly.</p>
              </div>
            </div>
            <p>The question was put on the adoption of this ordinance, and it was agreed to.</p>
            <div className="speaker" id="sp4805"><span className="persName">Mr. AMBLER</span>—</div>
            <p className="p-in-sp">I had supposed that the Convention had changed its practice of adopting ordinances without affording some opportunity to examine them. But it would seem not. I was not aware that the vote was taken on this ordinance until the Chair announced the result, so rapidly was it hurried through.</p>
            <p className="p-in-sp">I now move a reconsideration of the vote by which it was adopted.</p>
            <p className="p-in-sp">The motion was agreed to.</p>
            <div className="speaker" id="sp4806"><span className="persName">Mr. AMBLER</span>—</div>
            <p className="p-in-sp">I now move that the report of the committee be laid upon the table in order that we should have an opportunity of examining it.</p>
            <p>The motion was agreed to.</p>
            <p><span className="persName">Mr. HULL</span>, from a special committee, submitted the following:</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>AN ORDINANCE to provide against the sacrifice of property and to suspend proceedings in certain cases.</p>
                <p>Be it ordained, That no execution shall be issued from the date hereof, by any court of record or magistrate, for the sale of property; nor shall there be any sales under deeds of trust or decrees, for the payment of money, until otherwise provided by law.</p>
                <PageNumber num={509} />
                <p>Where executions have issued and are now in the hands of officers, whether levied or not, if the debtor offer bond and security for the payment of the debt, interest and costs, when the operation of this ordinance ceases, the property shall be restored and the bond so taken shall be returned as in case of a forthcoming bond.</p>
                <p>
                  If the debtor offers no such bond it shall be the duty of the officer to convene three free-holders from the vicinage, who, after being sworn, shall proceed to value the property according to what would have been its value on the 6th day of November, 1860, and unless the said property shall sell for the full amount of such valuation, it shall be restored to the debtor without lien.
                </p>
                <p>Except in criminal cases or Commonwealth's prosecutions there shall be no trial of any cause requiring the intervention of a jury, but either party to such cause shall have the right, upon reasonable notice, to take the depositions of any witness, to be read de bene esse.</p>
                <p>In cases of misdemeanor, juries shall be summoned from the bystanders, and not under the law, as it now stands; and with the consent of the party prosecuted, the cause may be tried by the court.</p>
                <p>This ordinance shall not apply to liabilities upon the part of public officers either to the State, counties, municipal corporations or individuals.</p>
                <p>The time during which this ordinance is in force shall not be computed in any case where the statute of limitations comes in question.</p>
              </div>
            </div>
            <div className="speaker" id="sp4807"><span className="persName">Mr. COFFMAN</span>, of Rockingham—</div>
            <p className="p-in-sp">I beg leave to present a petition from business men of my county, praying that the banks be authorized to issue notes of a smaller denomination than five dollars.</p>
            <p className="p-in-sp">I will state that unless some action of this sort is taken by this Convention, our State will be flooded with small notes issued by corporations and individuals.</p>
            <p className="p-in-sp">This petition is signed by a large number of business men of my county, and I desire that it be reported by the Secretary. I will then move that the report of the Finance Committee authorizing the issue of small notes be taken up.</p>
            <p>The Secretary read the petition as follows:</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>To the President and members of the Convention of Virginia; now sitting in the city of Richmond:</p>
                <p>Your petitioners respectfully represent that such is the scarcity of gold and silver change in the State that the ordinary business of the people can not be operated unless some remedy shall be immediately</p>
                <PageNumber num={510} />
                <p>
                  supplied by your honorable body. Your petitioners, therefore, pray that an ordinance be passed, giving the banks of the Commonwealth authority to issue notes of a less denomination than five dollars. Unless this shall be done the State will soon be flooded with an illegal and irredeemable currency; in the shape of promissory notes or shinplasters, issued by private individuals, in
                  every portion of the Commonwealth.
                </p>
                <p>In the absence of the Legislature the people look alone to the Convention for relief. Your petitioners, therefore, earnestly hope that their petition may be immediately granted.</p>
                <p>Louvenbach, Stebber &amp; Bros.,</p>
                <p>G. M. &amp; A. M. Effinger,</p>
                <p>J. H. Shue,</p>
                <p>D. M. Switzer,</p>
                <p>G. W. Waesche,</p>
                <p>G. S. Christee,</p>
                <p>Wm. N. Gay,</p>
                <p>T. Kaufman,</p>
                <p>S. H. Ott,</p>
                <p>Thomas Ott,</p>
                <p>Samuel R. Stirling,</p>
                <p>J. Hardesty &amp; Son,</p>
                <p>Ott &amp; Cootes,</p>
                <p>B. F. Rickala,</p>
                <p>George Bruffy,</p>
                <p>C. A. Sprinkel &amp; Co.,</p>
                <p>Henry Shacklett, Merchants of Harrisonburg.</p>
                <p>C. Clinton Clapp, Mect.,</p>
                <p>E. Coffman,</p>
                <p>John T. Harris,</p>
                <p>Henry Ott,</p>
                <p>S. M. Ott,</p>
                <p>R. H. Smith,</p>
                <p>W. H. Ritenour,</p>
                <p>Samuel Shacklett,</p>
                <p>C. C. Strayer, Cashier,</p>
                <p>A. B. Irick, President,</p>
                <p>A. E. Heneberger, Teller,</p>
                <p>Peter Heneberger,</p>
                <PageNumber num={511} />
                <p>P. H. Woodward,</p>
                <p>J. T. Effinger,</p>
                <p>M. Harvey Effinger,</p>
                <p>G. W. Effinger.</p>
              </div>
            </div>
            <div className="speaker" id="sp4808"><span className="persName">Mr. COFFMAN</span>—</div>
            <p className="p-in-sp">I now move that the report of the Finance Committee, in relation to the issue of small notes by the banks, be taken up.</p>
            <div className="speaker" id="sp4809"><span className="persName">Mr. COX</span>, of Chesterfield—</div>
            <p className="p-in-sp">Will the gentleman withdraw that motion for a moment to enable me to offer a resolution?</p>
            <div className="speaker" id="sp4810"><span className="persName">Mr. COFFMAN</span>—</div>
            <p className="p-in-sp">I will do so, if the gentleman renews it.</p>
            <div className="speaker" id="sp4811"><span className="persName">Mr. COX</span>—</div>
            <p className="p-in-sp">I shall do so.</p>
            <p className="p-in-sp">We have passed various ordinances here that have the force of law, and these ordinances ought to be enrolled and signed by the President.</p>
            <p className="p-in-sp">I offer the following resolution:</p>
            <p className="p-in-sp">"Resolved, That a committee of five be appointed to examine enrolled ordinances, and that all ordinances that have been, or may be passed by the Convention, be enrolled and when examined by said committee, be signed by the President."</p>
            <p>The resolution was adopted.</p>
            <div className="speaker" id="sp4812"><span className="persName">Mr. COX</span>—</div>
            <p className="p-in-sp">I renew the motion to take up the report of the Finance Committee in relation to small notes.</p>
            <div className="speaker" id="sp4813"><span className="persName">Mr. MORTON</span>—</div>
            <p className="p-in-sp">I would appeal to the gentleman from Richmond [<span className="persName">Mr. MACFARLAND</span>] , to withdraw his motion for a moment.</p>
            <div className="speaker" id="sp4814"><span className="persName">Mr. MACFARLAND</span>—</div>
            <p className="p-in-sp">The motion was not made by me, it was made by the gentleman from Rockingham , [<span className="persName">Mr. COFFMAN</span>] .</p>
            <div className="speaker" id="sp4815"><span className="persName">Mr. COFFMAN</span>—</div>
            <p className="p-in-sp">I withdraw it for the present.</p>
            <div className="speaker" id="sp4816"><span className="persName">Mr. AMBLER</span>—</div>
            <p className="p-in-sp">I ask leave to offer the following resolution:</p>
            <p className="p-in-sp">"Resolved, That a committee of five be appointed to inquire into the number of delegates to be sent by this State to the Congress of the Confederate States, and further to inquire whether the selection of persons to be appointed as such delegates be confined to members of this body."</p>
            <div className="speaker" id="sp4817"><span className="persName">Mr. ECHOLS</span>—</div>
            <p className="p-in-sp">I desire to amend the resolution so as to make it one of instruction that no member of this body shall be eligible.</p>
            <div className="speaker" id="sp4818"><span className="persName">Mr. AMBLER</span>—</div>
            <p className="p-in-sp">That amendment can be offered when the report comes in.</p>
            <div className="speaker" id="sp4819"><span className="persName">Mr. ECHOLS</span>—</div>
            <p className="p-in-sp">I withdraw my amendment then.</p>
            <div className="speaker" id="sp4820"><span className="persName">Mr. MONTAGUE</span>—</div>
            <p className="p-in-sp">I offer the following resolution. I will state to the Convention in a moment the reason why I offer it. I saw Judge Allen this morning, and he assured me that unless something was done in <PageNumber num={512} /> the matter that is referred to here, that our financial accounts would be in a bad way.</p>
            <p className="p-in-sp">The following is the resolution :</p>
            <p className="p-in-sp">"Resolved, That the Committee of Finance inquire into the expediency of reporting an ordinance creating the office of Secretary of the Treasury of the Commonwealth; or to create an additional number of officers to audit, settle and pay the claims against the State."</p>
            <p>The resolution was adopted.</p>
            <div className="speaker" id="sp4821"><span className="persName">Mr. COX</span>, of Chesterfield—</div>
            <p className="p-in-sp">I now move to take up the ordinance reported by the Finance Committee, authorizing the banks to issue one and two dollar notes.</p>
            <div className="speaker" id="sp4822"><span className="persName">Mr. FISHER</span>—</div>
            <p className="p-in-sp">I rise to a point of order. I desire the resolution to be read requiring the committees to be called over every morning.</p>
            <div className="speaker" id="sp4823"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">The committees have been called over.</p>
            <div className="speaker" id="sp4824"><span className="persName">Mr. FISHER</span>—</div>
            <p className="p-in-sp">There are other committees which have not been called.</p>
            <div className="speaker" id="sp4825"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">All have been called.</p>
            <div className="speaker" id="sp4826"><span className="persName">Mr. FISHER</span>—</div>
            <p className="p-in-sp">I am a member of a committee, charged with the consideration of the subject of the mail transportation and other kindred matters, and I know that that committee has not been called.</p>
            <div className="speaker" id="sp4827"><span className="persName">Mr. ROB'T. C. KENT</span>, of Wythe—</div>
            <p className="p-in-sp">I rise to a question of privilege. On yesterday I was detained from the Convention, being confined to my bed from indisposition. The Convention, I understand, adopted an important ordinance in relation to a temporary union of the State of Virginia with the Confederate States, and I would ask the permission to record my vote in favor of that ordinance.</p>
            <div className="speaker" id="sp4828"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">There were two questions acted upon. There was a convention and an ordinance.</p>
            <div className="speaker" id="sp4829"><span className="persName">Mr. KENT</span>—</div>
            <p className="p-in-sp">I ask leave to record my vote in favor of both.</p>
            <p>Leave was granted.</p>
            <div className="speaker" id="sp4830"><span className="persName">Mr. JAMES M. STRANGE</span>, of Fluvanna—</div>
            <p className="p-in-sp">I was also absent when the vote was taken upon these questions, and I ask the same privilege as that accorded to the gentleman from Wythe.</p>
            <p>The Convention agreed to grant the leave.</p>
            <div className="speaker" id="sp4831"><span className="persName">Mr. BAYLOR</span>, of Augusta—</div>
            <p className="p-in-sp">On the 17th day of this month I voted for the proposition of the gentleman from Fauquier [<span className="persName">Mr. SCOTT</span>] . After that proposition failed I felt very much inclined to vote for the ordinance of secession, which was passed on that day. But not being fully satisfied at the time that such a course would meet with the approbation of my people, I felt it my duty to vote against it.</p>
            <PageNumber num={513} />
            <p className="p-in-sp">
              Since that time, however, I have become perfectly satisfied that if I had voted for it my action would have fully met the approbation of my constituents. Therefore, I feel it my duty to indicate, as far as I can by any act of mine here, my entire acquiescence in that ordinance, and, so far as it can be done, to change my vote upon that ordinance from no to aye, with the permission of
              the Convention.
            </p>
            <p className="p-in-sp">
              I will state further, that I have been among my people, and found that so strong was their determination to uphold the State in the position she has assumed, that they have raised in the county of Augusta alone eight companies of volunteers, who are now in service. There are four other companies ready to march as soon as they can be armed; and I am of opinion that three or four more
              companies can be raised if it is thought necessary.
            </p>
            <p className="p-in-sp">On Monday last the County Court of Augusta appropriated the sum of $50,000, $30,000 of which, or as much thereof as may be necessary, to be expended for the wives and children of those who have gone into the service of the State, the remaining $20,000 to be applied to the purchase of arms for the volunteers.</p>
            <div className="speaker" id="sp4832"><span className="persName">Mr. TREDWAY</span>—</div>
            <p className="p-in-sp">I rise to a privileged question.</p>
            <p className="p-in-sp">I merely ask, inasmuch as I was necessarily absent yesterday when the Convention formed an alliance between ourselves and the Confederate States, and the ordinance adopting the Provisional Constitution of those States were adopted, I would ask permission of the Convention to record my vote in favor of both.</p>
            <p>Permission was granted.</p>
            <div className="speaker" id="sp4833"><span className="persName">Mr. SAMUEL L. GRAHAM</span>, of Tazewell—</div>
            <p className="p-in-sp">I ask the same privilege.</p>
            <p>It was accorded.</p>
            <p>The pending question was upon the motion made by <span className="persName">Mr. COX</span>, of Chesterfield, to take up the ordinance in relation to the issue of small notes by the banks, and the vote being taken upon it, it was agreed to.</p>
            <p>The ordinance reads as follows:</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>AN ORDINANCE authorizing the Banks of this Commonwealth to issue notes of the denomination of one and two dollars. Passed April 26, 1861.</p>
                <p>
                  Be it ordained, That the several banks of this Commonwealth be authorized to issue notes of the denomination of one and two dollars to an amount not exceeding five per cent. of their respective capitals, which notes may be signed by such officer or officers of said banks, respectively, as may be designated for that purpose by the Board of Directors, and any of said banks which may
                  have preserved <PageNumber num={514} /> the notes of the denomination of one and two dollars, heretofore issued under authority of law, be at liberty to circulate the same, so that their issue shall not exceed the amount authorized by this ordinance.
                </p>
                <p>This ordinance shall expire at the end of thirty days from the next meeting of the General Assembly.</p>
              </div>
            </div>
            <p>The pending question was on the amendment offered by <span className="persName">Mr. CONRAD</span>, of Frederick, when the ordinance was last under consideration, which reads:</p>
            <p>"The banks whose issues are based upon a pledge of State stock, may dispense with the signature of the Treasurer, and issue such small notes in the same way as other banks."</p>
            <div className="speaker" id="sp4834"><span className="persName">Mr. COFFMAN</span>—</div>
            <p className="p-in-sp">I call the previous question.</p>
            <p>The call was sustained, and the main question was ordered to be put by the following vote, ayes 58, noes 25; whereupon the vote was taken on the amendment, and resulted ayes 53-no count on the negative side, a being apparent that a large majority was in favor of the amendment.</p>
            <div className="speaker" id="sp4835"><span className="persName">Mr. WICKHAM</span>—</div>
            <p className="p-in-sp">I move to amend the ordinance as amended by adding thereto the following:</p>
            <p className="p-in-sp">"But the President and Directors of any such bank shall before the issue of any such small notes, withdraw from circulation and deposit with the Treasurer of the State an amount of their present notes equal to the amount of small notes hereby authorized to be issued."</p>
            <div className="speaker" id="sp4836"><span className="persName">Mr. CONRAD</span>, of Frederick—</div>
            <p className="p-in-sp">I will state that he will find from the reports of the State Stock banks, that they have not issued anything like the amount of State Stocks which they have pledged.</p>
            <p className="p-in-sp">Now if he will require them not to issue in the whole, more than an equivalent for the stock pledged, it will be well enough.</p>
            <p className="p-in-sp">As to the security they are better secured than any other banks in the State. They have the security of the stocks pledged, and of the individual stockholders.</p>
            <p className="p-in-sp">There is no difficulty in regard to the security, and as the gentleman will see, their whole issue will not exceed the whole amount which is now authorized to be issued by law.</p>
            <div className="speaker" id="sp4837"><span className="persName">Mr. WICKHAM</span>—</div>
            <p className="p-in-sp">I have no objection to a modification of the amendment in conformity with the gentleman's suggestion.</p>
            <div className="speaker" id="sp4838"><span className="persName">Mr. AMBLER</span>—</div>
            <p className="p-in-sp">
              I would ask the gentleman if it is not one of the guards against an over issue, that their notes shall be countersigned by the Treasurer, if that provision is not the provision relied upon in their charters to prevent that over-issue, and if his amendment <PageNumber num={515} /> does not sweep away that control and leave to each bank to issue notes
              without any such guard?
            </p>
            <div className="speaker" id="sp4839"><span className="persName">Mr. CONRAD</span>, of Frederick—</div>
            <p className="p-in-sp">
              This amendment does not propose to dispense with the provisions in their charters which require them to have their notes countersigned by the Treasurer, except as to these small notes of one and two dollars. By this limitation the issue of these notes cannot exceed five per cent of their capital. Under the present law they cannot issue a note beyond the exact amount of their capital.
              The other banks have a right to issue two to one. The State stock banks have their stock pledged to the full amount of their issues, and they are compelled to keep twenty per cent of their capital in their vaults, so that the issue of five per cent of their capital in small notes, when their regular issue is not equal to 70 per cent of the amount pledged, will not infringe at all upon
              their privilege.
            </p>
            <div className="speaker" id="sp4840"><span className="persName">Mr. AMBLER</span>—</div>
            <p className="p-in-sp">
              The point I made is not met. It is this: By this amendment you remove the check against an improper issue, which is placed by the charters upon these banks. You have required these banks to have their notes countersigned by the Treasurer. Now, sir, so far as these one and two dollar notes are concerned, you destroy their check utterly; and while your amendment restricts the issue to
              five per cent, what is to restrain the banks from going over that five per cent? Where is your check? What provision do you make to make them accountable in case they go beyond the proper limitations? These banks are upon a peculiar footing, and you are destroying the provision in their charters by which the country is saved from an abuse of their powers.
            </p>
            <div className="speaker" id="sp4841"><span className="persName">Mr. WICKHAM</span>—</div>
            <p className="p-in-sp">I will modify my amendment, and present it in this form :</p>
            <p className="p-in-sp">"But no such bank shall be hereby authorized to issue an amount of circulation larger than is now allowed by law."</p>
            <div className="speaker" id="sp4842"><span className="persName">Mr. FISHER</span>—</div>
            <p className="p-in-sp">I move to amend that amendment by adding-"but any of the banks availing themselves of the provision of this ordinance, shall be required to redeem such small notes in specie at their counters."</p>
            <p className="p-in-sp">I would offer that as an amendment to the ordinance, if the amendment of the gentleman from Henrico [<span className="persName">Mr. WICKHAM</span>]
              , should be rejected. But I am sure the previous question will be called the very moment the amendment of the gentleman is disposed of by being adopted or rejected. I am, therefore, compelled to offer it as an amendment, feeling that I would have but a poor chance of getting the floor after the vote is taken upon the amendment. Other gentlemen
              <PageNumber num={516} /> who are interested in the defeat of my amendment, and who are much more active than I am, would, in all probability, anticipate me in getting the floor, and I therefore deemed this the better course. I have been suffering from rheumatism for some time, and being consequently too stiff in my limbs, I cannot rise from my seat as
              readily as other gentlemen.
            </p>
            <p className="p-in-sp">
              The amendment which I offer explains itself. I am anxious that, should these small notes be issued, all the specie of the Commonwealth shall not be sunk and hidden from circulation. The banks ought to be required to redeem these small notes in specie at their counters. If you pass this ordinance without requiring these small notes to be redeemed in specie, you will not keep your
              $500,000 in specie one day after the adoption of that ordinance. The brokers and some merchants who are brokers in that respect, will take their specie, and send it to that market where it will bring most.
            </p>
            <p className="p-in-sp">
              Now, sir, the banks would not desire to issue these small notes of one and two dollars if they were not to make a profit by the operation, and I humbly submit whether the great interests of the people of the Commonwealth are not to be as much looked to as the interests of the corporations known as the banks of the Commonwealth. I know, sir, that one motive operating with some gentlemen
              in favor of the ordinance is that some irresponsible corporations and individuals will be issuing notes of the description referred to in this ordinance in case it should not pass. But, sir, is not such a declaration on the part of this Convention an admission that it is not in the power of the Commonwealth of Virginia-that it is not in the power of the Judiciary of the State to
              enforce the present law against the issue of small notes. Is any gentleman in this body prepared to admit that we have not the power to enforce this law. I imagine no gentleman would make such an admission directly; but they must admit that they are doing so in effect when they present as an argument in favor of this ordinance the fact that it will check the issue of small notes by
              irresponsible corporations and individuals.
            </p>
            <p className="p-in-sp">It may be true that these notes may be of some value to some individuals-it may be that they may afford facilities to some persons in business-but I know, as to my own people, the issue of these notes will be a positive injury, even if my amendment should be adopted.</p>
            <p className="p-in-sp">I said, the other day, we never experienced any inconvenience in my section of the Commonwealth for the want of a sufficient amount of specie, for we have always refused to receive notes of the denomination herein referred to.</p>
            <PageNumber num={517} />
            <p className="p-in-sp">I hope it will be the pleasure of the Convention to adopt this amendment, because, in my opinion, it is all important to the safety of the community.</p>
            <div className="speaker" id="sp4843"><span className="persName">Mr. MACFARLAND</span>—</div>
            <p className="p-in-sp">I desire especially to disabuse the minds of members of the idea that seems to exist in the mind of the gentleman from Northampton [<span className="persName">Mr. FISHER</span>]
              , that the proposition to issue one and two dollar notes originated with the banks. It does not, and I am sure, it will be seen at once, that upon the privilege of issuing one and two dollar notes to the extent of five per cent of their capital, their profits will scarcely pay the expenses of buying the plates and the additional labor required. The bank with which I am connected would
              be entitled to issue $160,000 in these notes, and if there is any profit in these issues, experience shows that it is only in the destruction which attends them.
            </p>
            <p className="p-in-sp">The banks will have to employ additional clerks to discharge the extra labor which these issues will occasion, so that actually no profit will arise to the bank from this source other than may result from the destruction of the notes.</p>
            <p className="p-in-sp">
              As to the propriety of making these notes redeemable at once in coin, the gentleman will see that that will inevitably defeat the leading policy upon which the bill is founded. There is great complaint in this city-and it exists everywhere in the Commonwealth now, of the great difficulty of obtaining small change. The brokers are dealing in it, and it is rapidly disappearing. It is
              concealed and hoarded from apprehensions and as expedient to relieve the necessities of the times. We find that upon all hands, propositions are made in defiance of law to issue small notes in some cases, by irresponsible parties; and now, the whole case to be determined is, whether the public shall be secured against the loss and uncertainty of a circulation of this description
              supplied by individuals who have no authority to do it, or whether the public shall have the security of the banks of the Commonwealth? I think that there is not room for two opinions upon the subject. If gentlemen will regard this as a proposition proceeding from the public and not from the banks, as it really is, I apprehend it will recommend itself to the favor and the adoption of
              one and all. I don't think it will tend to expel coin more rapidly from the State than it goes now. Any idea to the contrary is a mere speculation. Until something like specie, confidence and security is established, be assured we shall suffer from the evil of a lack of change, and in a great degree unless this remedy be supplied.
            </p>
            <p className="p-in-sp">If I may be pardoned for alluding for a minute to one observation <PageNumber num={518} /> or two, of the gentleman from Northampton [<span className="persName">Mr. FISHER</span>]
              , I will say that he seemed to amuse himself a little yesterday at the idea of a Convention, assembled under the solemn circumstances which define our position, should find itself engaged in supplying a small currency for the people. Let me tell him that old England found it necessary, in her war with Napoleon, to suspend specie payment, and resort to the issue of small notes. If the
              gentleman has any respect for the intelligence, the statesmanship and great financial knowledge of the English House of Commons, I think. he must be satisfied to dismiss his apprehensions that we would be lowering the dignity of our body by providing for the issue of small notes.
            </p>
            <div className="speaker" id="sp4844"><span className="persName">Mr. FISHER</span>—</div>
            <p className="p-in-sp">This action was not had by England in solemn convention.</p>
            <div className="speaker" id="sp4845"><span className="persName">Mr. MACFARLAND</span>—</div>
            <p className="p-in-sp">It was the act of a solemn Parliament, sitting in solemn assembly, to avert the dangers that surrounded the kingdom.</p>
            <p>The question was taken on the amendment to the amendment offered by <span className="persName">Mr. FISHER</span>, and it was decided in the negative.</p>
            <p>The question then recurred upon the amendment offered by <span className="persName">Mr. WICKHAM</span>, and it was decided in the affirmative.</p>
            <p>The question recurring on the adoption of the ordinance, as amended-</p>
            <p><span className="persName">Mr. KILBY</span> offered the following amendment: "That all laws now in force prohibiting the receiving or passing of bank notes under the denomination of five dollars, are hereby suspended, during the time this ordinance shall remain in force."</p>
            <div className="speaker" id="sp4846"><span className="persName">Mr. MACFARLAND</span>—</div>
            <p className="p-in-sp">
              There is no necessity for this amendment. I presume, when authority is given to issue these notes, it places it beyond the operation of any general law to the contrary which may have been previously in force. I would say that if this amendment is adopted it would authorize the issue of these notes by any individual or corporation. I feel confident that there can be no dispensation more
              indisputable than the special license given by this Convention to the banks to issue small notes. Indubitably that license withdraws the banks from the operation of the general law; and, if I am not mistaken, the gentleman will find by reference to the act of Assembly which last authorized the issue of small notes, that it conforms to the ordinance now before the Convention.
            </p>
            <div className="speaker" id="sp4847"><span className="persName">Mr. KILBY</span>—</div>
            <p className="p-in-sp">This ordinance authorizes the banks to issue notes of a less denomination than five dollars; but I don't understand that the mere authority to issue these notes removes the penalties attaching, <PageNumber num={519} /> under the act of Assembly, to the receiving or paying out of notes of a less denomination than five dollars.</p>
            <div className="speaker" id="sp4848"><span className="persName">Mr. SHEFFEY</span>—</div>
            <p className="p-in-sp">I move to amend the amendment by inserting after the word "suspended" the words "as to notes issued by the banks of Virginia under this ordinance," so that the amendment would read-</p>
            <p className="p-in-sp">"That all laws now in force prohibiting the receiving or passing of bank notes under the denomination of five dollars, are hereby repealed as to notes issued by the banks of Virginia, during the time this ordinance shall remain in force."</p>
            <div className="speaker" id="sp4849"><span className="persName">Mr. SHEFFEY</span>—</div>
            <p className="p-in-sp">If this is not adopted, we will be flooded with small notes from North Carolina and other neighboring States.</p>
            <div className="speaker" id="sp4850"><span className="persName">Mr. KILBY</span>—</div>
            <p className="p-in-sp">I accept that amendment.</p>
            <div className="speaker" id="sp4851"><span className="persName">Mr. SUTHERLIN</span>—</div>
            <p className="p-in-sp">
              I hope it will not be the pleasure of the Convention to adopt that amendment. How can we on the borders of North Carolina get along if that amendment is adopted? As I understand, every licensed merchant now in Virginia is sworn not to receive these notes, and it seems to me that this restriction should be removed from us who are located on the borders of North Carolina.
            </p>
            <div className="speaker" id="sp4852"><span className="persName">Mr. SHEFFEY</span>—</div>
            <p className="p-in-sp">You will have plenty of Virginia bank notes.</p>
            <div className="speaker" id="sp4853"><span className="persName">Mr. SUTHERLIN</span>—</div>
            <p className="p-in-sp">We would if they were in North Carolina. Our trade is with that State chiefly, and I do not think we ought to be kept subject to that restriction. I move to strike out the amendment just offered and accepted by the gentleman from Nansemond.</p>
            <div className="speaker" id="sp4854"><span className="persName">Mr. HOLLADAY</span>, of Norfolk County—</div>
            <p className="p-in-sp">If I understand the original ordinance, it proposes to authorize banks to issue small notes to the extent of five per cent, upon their capital stock. It seems to me, if the amendment of the gentleman from Nansemond [<span className="persName">Mr. KILBY</span>] is adopted, it will effectually nullify the restriction as to the proportion to be issued.</p>
            <div className="speaker" id="sp4855"><span className="persName">Mr. KILBY</span>—</div>
            <p className="p-in-sp">It will have no such effect.</p>
            <div className="speaker" id="sp4856"><span className="persName">Mr. HOLLADAY</span>—</div>
            <p className="p-in-sp">
              The Convention will remember that a few minutes ago a difference of construction arose, which made this amendment necessary. Some gentleman held that the power to issue gave a right to receive. If that be true, will not the converse of the proposition produce precisely the same effect? Perhaps I am wrong, sir. If so, I should like to be corrected. I was going to say, if the power to
              receive was given, the correlative power to issue would go with it.
            </p>
            <div className="speaker" id="sp4857"><span className="persName">Mr. GOGGIN</span>—</div>
            <p className="p-in-sp">
              I would say that inasmuch as a difference of opinion exists upon this question, and in order that we might vote understandingly upon it, I would ask that the whole subject be recommitted to the committee, with instructions to consider the matter in all the <PageNumber num={520} /> aspects in which it is here presented, and report again to the House as
              early as possible. I understand that a motion to recommit is always in order.
            </p>
            <p>The motion was lost.</p>
            <div className="speaker" id="sp4858"><span className="persName">Mr. WICKHAM</span>—</div>
            <p className="p-in-sp">I trust it will not be the pleasure of the Convention to strike out the amendment of the gentleman from Smyth [<span className="persName">Mr. SHEFFEY</span>] , which was accepted by the gentleman from Nansemond [<span className="persName">Mr. KILBY</span>]
              . If the ordinance is adopted without the restriction which that imposes, it will do a vast deal of injury, because it will bring into this section of the State foreign small notes, of which our people will know nothing. I have always been opposed to small notes. Now, however, they are a necessity, and I have, therefore, no objection to their being issued; but I desire that the
              currency of this class must be such as our citizens will know something of, and may rely upon. I think the two banks in the county of Pittsylvania can issue a sufficient amount of these notes to satisfy the requirements of that county.
            </p>
            <div className="speaker" id="sp4859"><span className="persName">Mr. CAMPBELL</span>, of Washington county—</div>
            <p className="p-in-sp">My county lies upon the borders of Tennessee, and if that amendment prevails, my section will be flooded with small notes from that State, issued by utterly irresponsible parties.</p>
            <div className="speaker" id="sp4860"><span className="persName">Mr. SUTHERLIN</span>—</div>
            <p className="p-in-sp">
              If the gentleman will allow me, I will make another suggestion which, if accepted, will render the amendment satisfactory to me, and cause me to withdraw my motion to strike out. I am perfectly satisfied that, with a permission to the banks only to issue five per cent. of their capital in small notes, some of them will not issue any at all. They will not be able to go to the expense of
              getting up plates and procuring the additional clerical force which would be necessary.
            </p>
            <p className="p-in-sp">I move to amend the ordinance by increasing the proportion of small notes to be issued to ten per cent of the capital stock of the banks.</p>
            <div className="speaker" id="sp4861"><span className="persName">Mr. CAMPBELL</span>—</div>
            <p className="p-in-sp">I have no objection to that amendment. I hope the gentleman will withdraw his motion to strike out the amendment of the gentleman from Smyth [<span className="persName">Mr. SHEFFEY</span>] . I know that very heavy losses have been sustained by the people of my section from the circulation of these worthless Tennessee notes.</p>
            <p className="p-in-sp">I have no objection to the amendment proposing to raise the per centage of small notes to be issued from five to ten per cent.</p>
            <div className="speaker" id="sp4862"><span className="persName">Mr. SUTHERLIN</span>—</div>
            <p className="p-in-sp">I want the Convention to understand one thing in reference to these issues.</p>
            <PageNumber num={521} />
            <p className="p-in-sp">
              I assure the House that the small banks cannot issue these notes at all, and the different communities in which these banks are situated will thus be deprived of the benefit of them. It can be no benefit to the banks at all. We have hundreds of thousands of dollars of these notes now .lying in our vaults, and we could issue them forthwith. The bank of which I am president could issue
              $100,000 of these notes today; and, that being the case, why go to the expense of getting new plates? If you increase the proportion to ten per cent, we might be justified in some degree in going to the expense of getting new plates.
            </p>
            <div className="speaker" id="sp4863"><span className="persName">Mr. MORTON</span>—</div>
            <p className="p-in-sp">
              I was one of the committee that reported this ordinance. An issue of five per cent upon the banking capital of the State would amount to $800,000. Ten per cent would amount to $1,600,000. My opinion was, that probably five per centage upon the banking capital, which would give us about $500,000, allowing for the small banks that may not issue, would have given us a sufficiency to meet
              the public wants. If these small banks do not choose to go to the expense of issuing these notes, still the large banks will probably furnish us with a circulation of half a million.
            </p>
            <p>The question was put, on the motion of <span className="persName">Mr. SUTHERLIN</span>, to strike out the words "as to notes issued by the banks of Virginia under this ordinance;" and it was decided in the affirmative.</p>
            <p>The question then recurred upon the amendment offered by <span className="persName">Mr. KILBY</span>, and it was agreed to.</p>
            <div className="speaker" id="sp4864"><span className="persName">Mr. SUTHERLIN</span>—</div>
            <p className="p-in-sp">I now move to amend the ordinance by allowing the banks to issue ten per cent upon their capital.</p>
            <div className="speaker" id="sp4865"><span className="persName">Mr. MACFARLAND</span>—</div>
            <p className="p-in-sp">I feel bound to say that it was the opinion of the committee that five per cent would meet the necessities of the State; but I have no objection to this amendment.</p>
            <p>The question was taken on the amendment, and it was decided in the negative.</p>
            <p>The question then recurred on the ordinance as amended.</p>
            <div className="speaker" id="sp4866"><span className="persName">Mr. HALL</span>, of Marion—</div>
            <p className="p-in-sp">I call the yeas and nays upon the adoption of that ordinance.</p>
            <p>
              The call was sustained, and, the vote being taken, resulted-yeas 59, nays 28-as follows:<span className="note" id="Note49"><span className="noteLabel">1</span>The Journal lists 28 negative votes; it omits A. M. Barbour from the list as given below.</span>
            </p>
            <p>
              YEAS-Messrs. Aston, Baylor, Boisseau, Borst, Bouldin, Boyd, Brent, Cabell, Campbell, Caperton, Carter, Cecil, Chambliss, Chapman, Coffman, R. Y. Conrad, James H. Cox, Critcher, Deskins, Early, Echols, Flournoy, French, Garland, Gillespie, Gravely, Goggin, Harvie, Holcombe, Holladay, Marmaduke Johnson, Peter C. Johnston, Kilby, McComas, Macfarland, Charles K. Mallory, Marshall,
              <PageNumber num={522} /> Miller, Moffett, Morton, McD. Moore, Neblett, Pendleton, Preston, Pugh, Richardson, Rives, Robert E. Scott, Seawell, Sharp, Sheffey, Speed, Spurlock, Staples, Strange, Tayloe, Wickham, Wysor, and John Janney
              [President] -59.</p>
            <p>NAYS-Messrs. Ambler, Alfred M. Barbour, James Barbour, Bruce, Conn, Fisher, Graham, Gregory, John Goode, Addison Hall, Ephraim B. Hall, Haymond, Isbell, Kent, Lawson, Leake, Marye, Sr., Montague, Nelson, Randolph, William C. Scott, Sitlington, Sutherlin, Robt. H. Turner, Franklin P. Turner, Tyler, Williams, Wilson, and Woods-28.</p>
            <div className="speaker" id="sp4867"><span className="persName">Mr. CHAMBLISS</span>—</div>
            <p className="p-in-sp">I now move to take up the ordinance in regard to the suspension of the collection of debts.</p>
            <p>The motion was agreed to.</p>
            <div className="speaker" id="sp4868"><span className="persName">Mr. SCOTT</span>, of Powhatan—</div>
            <p className="p-in-sp">I move to pass by the pending business for a moment to enable me to make a report from the Military Committee.</p>
            <p>The motion was agreed to.</p>
            <div className="speaker" id="sp4869"><span className="persName">Mr. SCOTT</span>—</div>
            <p className="p-in-sp">I beg leave to report an ordinance in regard to the organization of.a provisional army for the State of Virginia. It reads as follows:</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>AN ORDINANCE to provide for the organization of a Provisional Army for the State of Virginia, April 26, 1861.</p>
                <p>
                  Be it ordained, That there shall be organized a Provisional Army for the State of Virginia, as the exigencies of the service may require, which army shall consist, in addition to the major general commanding the military and naval forces of the State, of four brigadier generals, two regiments of artillery, eight regiments of infantry, one regiment of riflemen, and one regiment of
                  cavalry. Each regiment of artillery shall consist of one colonel, one lieutenant colonel, one major, one sergeant major, one quartermaster sergeant, and ten companies; and each company shall consist of one captain, one first lieutenant, one second lieutenant, four sergeants, four corporals, two artificers, two musicians, and seventy-two privates. Each regiment of infantry shall
                  consist of one colonel, one lieutenant colonel, one major, one sergeant major, one quartermaster sergeant, two principal musicians, and ten companies, and each company shall consist of one captain, one first lieutenant, one second lieutenant, four sergeants, four corporals, two musicians, and seventy-two privates.
                </p>
                <p>
                  The regiment of cavalry shall consist of one colonel, one lieutenant colonel, one major, one adjutant, who shall be a lieutenant, one sergeant major, one quartermaster sergeant, one chief musician, two chief buglers, and ten companies, and each company shall consist of one captain, one first lieutenant, one second lieutenant,
                  <PageNumber num={523} /> exclusive of the lieutenant who is to be the adjutant of the regiment; four sergeants, one of whom shall act as quartermaster sergeant to the company, four corporals, two buglers, one farrier and blacksmith, and seventy-two privates.
                </p>
                <p>The regiment of riflemen shall consist of one colonel, one lieutenant colonel, one major, one sergeant major, one quartermaster sergeant, two principal musicians, and ten companies, and each company shall consist of one captain, one first lieutenant, one second lieutenant, four sergeants, four corporals, two musicians, and seventy-two privates.</p>
                <p>
                  The brigadier generals of the said army shall be appointed by the Governor, by and with the advice and consent of this Convention, and the field and other commissioned officers of the several regiments, shall be appointed by the Governor, by and with the advice and consent of his advisory Council, until it is otherwise provided, and the Governor shall commission all of the said
                  officers.
                </p>
                <p>
                  The Major General commanding the military and naval forces of the State shall be entitled to two aids de-camp who may be taken from the line without regard to rank, and he may appoint a military secretary either from private citizens or from the subalterns of the army, who, for the time being, shall have the pay and emoluments of a captain of cavalry. Each brigadier general shall
                  be entitled to one aid to be taken from the subalterns of the line; and to each regiment there shall be an adjutant, to be appointed from among the subalterns by the commanding officer of the regiment.
                </p>
                <p>The non-commissioned officers, musicians, artificers, farriers, and blacksmiths shall be enlisted for three years unless sooner discharged, and the regulations for their enlistment shall be prescribed by the major general commanding the military and naval forces, subject, however, to the approval of the Governor and his advisory Council.</p>
                <p>
                  The officers of the provisional army shall take rank and precedence over all officers of the same grade of the volunteers and militia without regard to date of commission; and the Governor may proceed at once to appoint, in the mode prescribed, the general and field officers of the provisional army, and they may be assigned to duty with their respective rank with the volunteers
                  which have been or may be called into service.
                </p>
                <p>General regulations for the provisional army and all the departments of the military and naval forces may be prescribed by the commander of the military and naval forces, subject to the ratification of the Governor and his advisory council.</p>
                <p>This ordinance shall be in force from the time of its adoption, <PageNumber num={524} /> and be subject to amendment, modification, or repeal by this Convention or by the General Assembly.</p>
              </div>
            </div>
            <div className="speaker" id="sp4870"><span className="persName">Mr. AMBLER</span>—</div>
            <p className="p-in-sp">I would like to ask a question of the Chairman of the Military Committee.</p>
            <p className="p-in-sp">I have heard ordinance after ordinance passed here, creating, it seems to me, an indefinite number of generals.</p>
            <div className="speaker" id="sp4871"><span className="persName">Mr. SCOTT</span>—</div>
            <p className="p-in-sp">This is the first one, except one creating the office of major general.</p>
            <div className="speaker" id="sp4872"><span className="persName">Mr. AMBLER</span>—</div>
            <p className="p-in-sp">I understand there are two other major generals now existing, and two brigadier generals.</p>
            <div className="speaker" id="sp4873"><span className="persName">Mr. SCOTT</span>—</div>
            <p className="p-in-sp">
              We have not passed any ordinance in regard to the appointment of any particular number of generals. We did pass an ordinance authorizing the Governor to receive the volunteers of the State in companies, and after being received, to organize them into regiments, brigades and divisions; and authorising the Governor to appoint all the field officers and all the general officers.
            </p>
            <p className="p-in-sp">Under that ordinance the Governor did appoint one major general -General Gwynn-and two brigadier generals-Cols. Cocke and Ruggles. These are all the general officers appointed.</p>
            <div className="speaker" id="sp4874"><span className="persName">Mr. SHEFFEY</span>—</div>
            <p className="p-in-sp">Under what authority was Col. Baldwin appointed Inspector General?</p>
            <div className="speaker" id="sp4875"><span className="persName">Mr. SCOTT</span>—</div>
            <p className="p-in-sp">I don't know, sir. These are the only generals that the Convention has so far authorized to be appointed.</p>
            <p className="p-in-sp">
              We wish to establish by this ordinance a regular army, to be composed of about 10,000 men. We provided in the first report made to this Convention for the appointment of two major generals, four brigadier generals; but in a conference with the Commander-in-chief, we thought proper to strike out the two major generals and leave the four brigadier generals in. This has the approbation of
              the Commander-in-chief.
            </p>
            <p className="p-in-sp">I am instructed by the Committee on Military Affairs to submit "an ordinance establishing rules and articles for the government of the armies of the State of Virginia."</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>AN ORDINANCE establishing Rules and Articles for the government of the Armies of the State of Virginia.</p>
                <p>Be it ordained by the Convention of Virginia, That from and after the passing of this ordinance the Volunteers in actual service and the Provisional Army shall be governed by the Rules and Articles of War now in force in the Army of the United States, amended as follows :</p>
                <p>
                  Insert "the State of Virginia," instead of "the United States of <PageNumber num={525} /> America or the United States;" "the Governor of Virginia," instead of "the President of the United States;" "the Commander-in-chief," instead of "the Secretary of War ;" "the office of the Adjutant General," instead of "Department of War," or "Office of the
                  Department of War," wherever the latter phrases occur.
                </p>
                <p>In Article V. strike out "the President of the United States, the Vice President thereof, against the Congress of the United States, or against the Chief Magistrate, or Legislature of any of the United States in which he may be quartered," and insert "the Governor, Lieutenant Governor, Convention and Legislature of Virginia."</p>
                <p>In Article X. strike out "them," in the phrase "I will serve them," and insert "the State of Virginia." Strike out "their," in the phrase "their enemies," and insert "her."</p>
                <p>In Article XXXIV. strike out "in the State or Territory where such regiment shall be stationed."</p>
                <p>In Article LI. strike out "employed in any ports out of the United States."</p>
                <p>In Article LXXXVII. strike out "nor shall more than fifty lashes be inflicted on any offender at the discretion of a court martial," and insert "no corporeal punishment by stripes or lashes shall be inflicted."</p>
                <p>In Article XCVII. strike out the phrase beginning "save only" and all that follows.</p>
                <p>Article XCVIII. to be omitted and the numbers of the articles following to be reduced accordingly.</p>
                <p>In Article CI. omit 3d, 4th, 6th, 8th and 9th paragraphs, and add to the article the following paragraph: "The Governor, by and with the advice of his Council, may appoint from the army a suitable person as Judge Advocate of the Army, who shall have the brevet rank, pay and emoluments of a Major of Cavalry."</p>
                <p>The following are the rules and articles of war in the United States army to which the above amendments are offered:</p>
              </div>
            </div>
            <div className="stage it">[This document will be published hereafter.]</div>
            <p>
              <span className="note" id="Note50"><span className="noteLabel">2</span>These "rules and articles of war in the United States army" have not been found in the Enquirer.</span>
            </p>
            <div className="speaker" id="sp4876"><span className="persName">Mr. SCOTT</span>—</div>
            <p className="p-in-sp">I beg leave to submit the following report from the Committee on Military Affairs:</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>AN ORDINANCE providing for Enlistments in the Provisional Army, Passed April 27, 1861.</p>
                <p>1. All free, able-bodied, effective men, between the ages of eighteen and forty-five years, may be enlisted, and the enlistment shall be binding on minors, providing they are allowed four days, including</p>
                <PageNumber num={526} />
                <p>the day of enlistment, to reconsider and retract their enlistment. During these four days, they shall receive no pay, bounty, or clothing, and shall not be restrained of their liberty. After the expiration of that period, without retraction, the enlistment shall be binding.</p>
                <p>2. Minors enlisting without the knowledge of their parent or guardian, shall be discharged on the application of such parent or guardian.</p>
                <p>3. No person convicted of an infamous offence shall be enlisted.</p>
              </div>
            </div>
            <div className="speaker" id="sp4877"><span className="persName">Mr. WICKHAM</span>—</div>
            <p className="p-in-sp">I desire to get the ear of the chairman of the Military Committee while I state my understanding of the present state of military affairs in the State.</p>
            <p className="p-in-sp">
              I understand that, by an ordinance passed before this Military Committee was appointed, the Governor was directed to organize the volunteer forces of the State into regiments and to appoint the general and field officers necessary for that organization. I understand now that the purpose and the recommendation of the Military Committee is, to call into service, in addition to such
              volunteers as have been organized, a regular standing army, and that the ordinance which has been submitted this day is for the organization of that standing army distinct from the volunteers, the volunteers, in the meantime, to do such duty as may be assigned to them, and ultimately to be supplanted by this standing army. So that this organization is meant to be entirely distinct from
              the volunteer forces. I think that is what is meant by this ordinance.
            </p>
            <div className="speaker" id="sp4878"><span className="persName">Mr. SCOTT</span>—</div>
            <p className="p-in-sp">Yes, sir.</p>
            <div className="speaker" id="sp4879"><span className="persName">Mr. MORTON</span>—</div>
            <p className="p-in-sp">I move that this ordinance be laid upon the table and printed.</p>
            <div className="speaker" id="sp4880"><span className="persName">Mr. ECHOLS</span>—</div>
            <p className="p-in-sp">I hope the gentleman will withdraw that motion.</p>
            <div className="speaker" id="sp4881"><span className="persName">Mr. MORTON</span>—</div>
            <p className="p-in-sp">I am so amiable and courteous I cannot refuse the request. [Laughter.] I withdraw it.</p>
            <div className="speaker" id="sp4882"><span className="persName">Mr. AMBLER</span>—</div>
            <p className="p-in-sp">
              I understood distinctly that it was the intention, and aim and object of our movements here to call into the service of this State the military officers, natives and residents of Virginia, who have been in the service of the late United States; that they were to have a preference of the officers in our service; that the ends we aimed at were to put these officers in command, and, that
              the ordinance which we adopted looked directly to their employment. I know the fact, from the public papers, that these officers have not been employed, but that other officers have been appointed who were not in the service of the United States.
            </p>
            <p className="p-in-sp">
              I have voted here for ordinances creating these offices, under <PageNumber num={527} /> the belief, drawn from statements made upon this floor by those who have this subject under their control, that they would be filled by officers in the late United States service. I find, however, that these offices are not so filled. I have asked for an explanation
              why they were filled otherwise than, as we understood, they were to be filled; but none has been given.
            </p>
            <p className="p-in-sp">
              Then, sir, here is a provision making a distinction between the provisional army and the volunteer army; and we have had an ordinance reported-one acted upon and one not yet acted upon-which also recognize this distinction. When that ordinance was passed I understood it to discriminate in favor of officers, who served in the late United States Army, and the declaration of the Chairman
              of the Committee on Military Affairs was, that these army officers were to be employed in the command of these volunteers. I, for one, voted for the creation of these offices, under the idea that they were to be so filled.
            </p>
            <p className="p-in-sp">Now, sir, here is an ordinance creating new officers-new general officers. I want to know what provision is made to redeem the pledge which we gave in the name of the people of Virginia, that the officers of the United States Army who resigned and came here into our service should get a preference of offices in our gift?</p>
            <div className="speaker" id="sp4883"><span className="persName">Mr. EARLY</span>—</div>
            <p className="p-in-sp">Will the gentleman allow me to interrupt him?</p>
            <div className="speaker" id="sp4884"><span className="persName">Mr. AMBLER</span>—</div>
            <p className="p-in-sp">Certainly.</p>
            <div className="speaker" id="sp4885"><span className="persName">Mr. EARLY</span>—</div>
            <p className="p-in-sp">That certainly was the intention; and if the Convention will look at the ordinance that was adopted on the 17th inst., they will find that it provides that volunteers may be received by the Governor in companies and organized into regiments, brigades and divisions, and that the general and field officers should be appointed by the Governor.</p>
            <div className="speaker" id="sp4886"><span className="persName">Mr. AMBLER</span>—</div>
            <p className="p-in-sp">When that ordinance was passed, I understood distinctly that these offices were to be filled by officers who had left the United States service, and tendered their services to Virginia.</p>
            <div className="speaker" id="sp4887"><span className="persName">Mr. EARLY</span>—</div>
            <p className="p-in-sp">
              Now, according to the construction of that ordinance, before the Governor was authorized to appoint a Colonel, there ought to be a regiment organized; before a Brigadier General was appointed, a brigade ought to be organized; and so with a Major General-before he was appointed, a division ought to be organized. The appointment of all these officers in advance of the completion of their
              respective organizations is not warranted by this ordinance. If this plan of appointing, in advance of the organization of our forces, had been carried out, we would have more Brigadier Generals <PageNumber num={528} /> than we would have brigades for them to command. We want to provide against any undue exercise of this power. This ordinance will remedy,
              in a great degree, some of the mischief that has already been unintentionally done. We provide for the organization of a Provisional army, with four Brigadier Generals, who are to be appointed by and with the advice and consent of this Convention. We can, therefore, take care to see that provision is made for these officers of the army, and that competent men, so far as we are able to
              judge, shall be appointed. A provision is also there, that field officers and commanding officers shall be appointed by the Governor by and with the advice and consent of the Advisory Council. We should have to stay too long if we were required to confirm these appointments.
            </p>
            <p className="p-in-sp">There is a further provision, that these officers shall be appointed forthwith, and assigned to duty with the volunteers, which will give them command of the volunteer forces in the field, and that they shall take rank over all officers of the volunteers and militia of the same grade, without regard to date of commission.</p>
            <p className="p-in-sp">
              I want to get rid, if possible, of all the Major Generals and Brigadier Generals that are not necessary. When we have appointed four competent gentlemen to the Brigadier Generalship, I hope that all the others will be withdrawn from the services. All that we can do is to report the measures authorizing the proper officers of the government to organize an effective force. We cannot be
              responsible for the power that is of necessity conferred upon these officers.
            </p>
            <div className="speaker" id="sp4888"><span className="persName">Mr. HARVIE</span>—</div>
            <p className="p-in-sp">I move that the ordinance just reported be laid upon the table and printed.</p>
            <div className="speaker" id="sp4889"><span className="persName">Mr. WICKHAM</span>—</div>
            <p className="p-in-sp">I desire to say, in response to the gentleman from Franklin [<span className="persName">Mr. EARLY</span>] , that the Governor has no interest whatever in any of these appointments. He is merely doing what is provided for by the ordinance of the 17th. It was absolutely necessary that these officers should be appointed to enable him to carry out the organization directed by the Convention.</p>
            <div className="speaker" id="sp4890"><span className="persName">Mr. SCOTT</span>, of Powhatan—</div>
            <p className="p-in-sp">I wish to say a word by way of correcting a statement which I have made.</p>
            <p className="p-in-sp">
              I was in error just now when I said that only one Major General had been appointed under the ordinance of the 17th. I saw in this morning's paper that another Major General has been appointed-General Joseph E. Johnston, late of the United States army. So that under that ordinance two Major Generals have been appointed <PageNumber num={529} /> instead of
              one, as I erroneously stated. Two Brigadier Generals also have been appointed under that ordinance.
            </p>
            <div className="speaker" id="sp4891"><span className="persName">Mr. GEO. W. RANDOLPH</span>—</div>
            <p className="p-in-sp">
              There is a volunteer organization that is entirely separate and distinct from this regular army. Now, unless you intend to rely upon the volunteers for the war, which may last for years; unless you intend to keep men of business-farmers, merchants and mechanics in the field for years, you must have regular troops to take their places. Unless you intend, when the Confederate Government
              calls on you for your quota, to send your very best men to stand and fight along side of regular soldiers, you must organize a regular army.
            </p>
            <p className="p-in-sp">We have checked any mischief that may occur in the appointments by this ordinance, and we have the power to vacate any appointment that may be made. We have not more generals appointed than are necessary; but the generals appointed are not such as should be appointed.</p>
            <p className="p-in-sp">
              Now, sir, we are not going to rely upon this volunteer system, and therefore we are providing for the organization of a regular army not exceeding 10,000 men. With these we can replace the volunteer forces, and with these we can respond to any call which the Confederate government may make upon us. Every body agrees that it is a cheaper and more efficient corps than the volunteer
              system; and we know now that there are a large number of men in a starving condition who are anxious to enter this army. Judge Allen informed me last night that there were hundreds on the Covington and Ohio railroad who are in a starving condition, and must turn to marauding unless there is a position opened for them. Now, we have not a general more than is necessary. We have only
              provided for the appointment of such a number of generals as General Lee thought necessary; and while he does not say that there ought to be a regular force, he leaves that to the Convention, but he does say, that if such a force is raised, this is the proper mode to raise it.
            </p>
            <p className="p-in-sp">
              In the first place, look at the safe guards. All major generals and brigadier generals are to be confirmed by this Convention. All below brigadier generals are to be appointed by the Governor, by and with the advice and consent of the Advisory Council. If we can get four brigadier generals for the regular army, we can rely upon their being efficient. We have another ordinance which can
              be offered if this is adopted, regulating the pay of the volunteers, and requiring that it shall be the same in the provisional army, so that they can be incorporated in a day, if necessary, with the regular army.
            </p>
            <PageNumber num={530} />
            <p className="p-in-sp">There is no increase in the number of generals. If you choose you might pass an ordinance requiring that these generals, until the men are enlisted for them to command, shall take command of the volunteers, and you might vacate all commissions above captain, and leave them to be provided for hereafter.</p>
            <p className="p-in-sp">There are other ordinances which shall be submitted if this is adopted, such as an ordinance organizing a pay department, a quartermasters department, &amp;c., all taken from the United States service, and adapted to our condition.</p>
            <div className="speaker" id="sp4892"><span className="persName">Mr. TYLER</span>—</div>
            <p className="p-in-sp">
              The ordinance creating this provisional army seems to me to be defective in this, that there is not an express stipulation contained in that ordinance that the officers heretofore in the United States service who have resigned their commissions, should have precedence in these appointments over any other. I want the appointments made up of officers belonging to Virginia in the late
              United States army.
            </p>
            <div className="speaker" id="sp4893"><span className="persName">Mr. RANDOLPH</span>—</div>
            <p className="p-in-sp">That might be put in now as an amendment, or it may be submitted in the shape of a separate ordinance.</p>
            <div className="speaker" id="sp4894"><span className="persName">Mr. TYLER</span>—</div>
            <p className="p-in-sp">You perceive, you have invited these officers and it is proper you should give them a preference of these offices.</p>
            <div className="speaker" id="sp4895"><span className="persName">Mr. RANDOLPH</span>—</div>
            <p className="p-in-sp">Yes, sir, and we are, of course, very willing to make provision for them. This whole movement has been originated in part for that purpose.</p>
            <div className="speaker" id="sp4896"><span className="persName">Mr. AMBLER</span>—</div>
            <p className="p-in-sp">
              I am perfectly satisfied that this provisional army ought to be organized. What I have in view is, that the faith pledged by this Convention that these officers who have resigned should be provided for, shall be redeemed. The Committee on Military Affairs have the power to report an ordinance requiring that that faith shall be redeemed, and they should do so. I desire to call the
              attention of the committee to that fact. For one, I voted for the ordinance extending the invitation to the army officers with a belief that it would be carried out, and, I think, it is now our duty to see that it shall be carried out.
            </p>
            <div className="speaker" id="sp4897"><span className="persName">Mr. RANDOLPH</span>—</div>
            <p className="p-in-sp">I should be very glad to have these suggestions embodied in the ordinance under consideration, or in a special ordinance providing for these officers.</p>
            <p className="p-in-sp">
              What I wish the Convention to do is, not to make war upon this regular army for the sake of rectifying mistakes or abuses in the volunteer forces. Let us perfect this, and then provide that the officers of the late United States army, who have resigned, shall receive a preference of these appointments. But do not let us bring
              <PageNumber num={531} /> odium upon this project by referring to errors which it is rather designed to correct than otherwise.
            </p>
            <div className="speaker" id="sp4898"><span className="persName">Mr. SHEFFEY</span>—</div>
            <p className="p-in-sp">Before the Convention acts upon the motion to lay on the table and print, I desire to call the attention of the gentleman from Richmond [<span className="persName">Mr. RANDOLPH</span>] , to a single fact.</p>
            <p className="p-in-sp">I desire to know whether the expense of establishing this provisional army will fall under the 3rd provision of the convention, entered into between Virginia and the Confederate States, which makes the Confederate States subject to the expenses?</p>
            <div className="speaker" id="sp4899"><span className="persName">Mr. RANDOLPH</span>—</div>
            <p className="p-in-sp">I presume it will.</p>
            <div className="speaker" id="sp4900"><span className="persName">Mr. SHEFFEY</span>—</div>
            <p className="p-in-sp">
              You are going on now to create a provisional standing army before the consummation of this union with the Confederate States. This army is to be a provisional army for Virginia; and you propose to turn over a portion of that provisional army to the Confederate States after the consummation of that union. I desire to know whether this standing army will not be a burden to the State of
              Virginia, in addition to the other expenses arising from the volunteers; and whether we could not require the Confederate States to foot the bill?
            </p>
            <div className="speaker" id="sp4901"><span className="persName">Mr. RANDOLPH</span>—</div>
            <p className="p-in-sp">
              I can say that this union will be consummated in a few days, and this provisional army will not be consummated for some months, perhaps, for a year. It is provided that this army will be raised as circumstances may require. We might have some provisional army, say of one or two thousand men, and that portion of the expense will fall upon Virginia. But, so far from being any extra
              expense, it will be economy to raise these troops. The ordinance does not compel the whole number to be raised. If they don't want more than one or two thousand men, they won't raise more. If the provisional government desire a part of that army, they must foot the bill for that part. If they don't wish any part of it, they don't foot the bill. It enables us to raise such an army as we
              need, and to raise troops for them and turn them over ready organized. If they choose to raise them for themselves under their own organization, we will be relieved of the duty of doing so for them.
            </p>
            <div className="speaker" id="sp4902"><span className="persName">Mr. BAYLOR</span>—</div>
            <p className="p-in-sp">
              I am very much pleased at this idea of raising a regular army; but I don't know that I understand the gentleman correctly. I understand thus far, that of course, this army is not to interfere with the regular volunteers; but I think I understand the gentleman to say that the Brigadier Generals to be appointed would interfere with and supersede the volunteer Brigadier Generals.
            </p>
            <div className="speaker" id="sp4903"><span className="persName">Mr. RANDOLPH</span>—</div>
            <p className="p-in-sp">Not at all. I said if the Convention choose, they <PageNumber num={532} /> might, by another ordinance require that the Brigadier General should command the volunteers.</p>
            <div className="speaker" id="sp4904"><span className="persName">Mr. AMBLER</span>—</div>
            <p className="p-in-sp">I call the attention of the gentleman to the fact, that a declaration was made, I think by the Chairman of the Military Committee-certainly by some member of the committee-that it was the intention, in the appointment of these regular officers, to assign them to the command of the volunteers until the regular army was organized.</p>
            <div className="speaker" id="sp4905"><span className="persName">Mr. WILSON</span>—</div>
            <p className="p-in-sp">I rise to a question of order. I understood the Chair to say, that on a motion to lay on the table, it was only competent for a member to show why it should not be laid upon the table, and not debate the question on its merits.</p>
            <div className="speaker" id="sp4906"><span className="persName">Mr. HOLCOMBE</span>—</div>
            <p className="p-in-sp">If the gentlemen of the Military Committee are unanimous in suggesting any proposition, I shall vote for it; that is, unless any conclusive reason, adverse to its adoption, present itself to my mind. If the scheme is at all doubtful, the fact that it comes to us recommended by all of the gentlemen of the Military Committee, will be sufficient to turn my vote.</p>
            <p className="p-in-sp">I desire to know, if it is not improper, whether this ordinance comes before us with the approbation of the whole Committee?</p>
            <div className="speaker" id="sp4907"><span className="persName">Mr. SCOTT</span>—</div>
            <p className="p-in-sp">The Committee were unanimous for its adoption.</p>
            <div className="speaker" id="sp4908"><span className="persName">Mr. HOLCOMBE</span>—</div>
            <p className="p-in-sp">
              And, as I understand, it comes before us with the approbation of Major General Lee. My own mind is made up on the question. Without going into the details of this matter, inasmuch as these gentlemen think that it is a matter of importance to have this organization at once, I am willing to take the details in the ordinance as it is communicated to us, with the endorsement of the
              Commander-in-Chief.
            </p>
            <div className="speaker" id="sp4909"><span className="persName">Mr. HARVIE</span>—</div>
            <p className="p-in-sp">
              I have no desire to throw any obstacle in the way of progress here; but I do not understand the question, and I want to see whether it does not defeat the very recommendations made by the Military Committee to this Convention. I understand that we invited the officers of the army of the United States, natives of Virginia, to resign and come here, that they might take charge of our
              army. Now, I understand that this army is to be raised as we may deem necessary from time to time, and these officers of the United States army are not to get command of the volunteers of the State, so that if this provisional army should hereafter be raised, these officers are to be supernumeraries.
            </p>
            <p className="p-in-sp">
              That, sir, was not what this Convention meant when they invited them here. They wanted them here in order to supply this deficiency <PageNumber num={533} /> which now exists and which hereafter may not exist, because the gentlemen who are now militia generals and colonels will be educated and instructed by this war, and may answer as well as those who are
              now educated. We need the services of these regular army officers then and now; but by this arrangement we get them in the future, and don't get them now.
            </p>
            <div className="speaker" id="sp4910"><span className="persName">Mr. SCOTT</span>, of Powhatan—</div>
            <p className="p-in-sp">You are mistaken about that.</p>
            <div className="speaker" id="sp4911"><span className="persName">Mr. HARVIE</span>—</div>
            <p className="p-in-sp">So far from my being mistaken, I find there is no provision in the ordinance to get these officers into the volunteer system, and that the powers that be have disregarded the obvious intents and purposes of the Convention by appointing men over others who are better entitled to the positions than those appointed.</p>
            <p className="p-in-sp">
              For that reason, sir, I wanted this matter laid upon the table, in order that we might fully consider it. I think, moreover, that it should be referred back to the committee before we take action upon it. My attention was called to the subject by a gentleman in whom I have very great confidence, and for whom I entertain much respect. I allude to a member of the Advisory Council. He
              suggested something like this provisional army; but if this be what he suggested I do not understand it.
            </p>
            <p className="p-in-sp">
              Now, as regards these railroaders becoming a marauding force if they are not employed-I have seen two letters from the Confederate States Government to-day, inviting two gentlemen connected with that army to open recruiting stations here and in Norfolk for the purpose of getting recruits of this character for the Confederate States army. Why not put this class of men, represented as
              starving, into this army? It could be done just as well as if this provisional army was organized. For my part, I am opposed to the Governor's getting any authority to appoint a single individual to any military office in the State, either in the volunteer or standing army, until those officers of the late United States army should receive what we pledged ourselves they should have.
            </p>
            <div className="speaker" id="sp4912"><span className="persName">Mr. SCOTT</span>, of Powhatan—</div>
            <p className="p-in-sp">
              The gentleman is entirely mistaken in supposing that any error has been committed in consequence of the action of this committee. If anything wrong has been done, it was done by the action of this Convention. Before this committee was appointed, the Convention passed an ordinance authorizing the Governor to appoint field and general officers for the volunteers. I will further remark,
              that the Governor has not made a single appointment of any kind under authority of any ordinance reported to the Convention by the Military Committee.
            </p>
            <PageNumber num={534} />
            <div className="speaker" id="sp4913"><span className="persName">Mr. HARVIE</span>—</div>
            <p className="p-in-sp">I will take that back, and say that these appointments were made in spite of them.</p>
            <div className="speaker" id="sp4914"><span className="persName">Mr. SCOTT</span>—</div>
            <p className="p-in-sp">I repeat that the Governor has not made any appointment under an ordinance reported by this Convention.</p>
            <p className="p-in-sp">
              In regard to these officers who have been invited to resign their commission in the army of the United States, it was expected that the appointing power would give precedence over all others of the same grade; and, so far as I know, they have been given precedence. I do not know a single officer, who left the United States service and tendered his service to Virginia, who has been
              rejected by the Governor. I know that all of these officers who have made application, have been appointed to an office, at least equal in grade to the office they held in the United States service. I know, further, that it is the purpose of the Governor to give them precedence over all officers of the same grade in their own State.
            </p>
            <div className="speaker" id="sp4915"><span className="persName">Mr. AMBLER</span>—</div>
            <p className="p-in-sp">Just here I would ask the gentleman if he does not know the fact that a most important line of defence, in this State, is now under the command of a gentleman who did not belong to the army of the United States?</p>
            <div className="speaker" id="sp4916"><span className="persName">Mr. SCOTT</span>—</div>
            <p className="p-in-sp">That may be so. Col. Ruggles has now command of some port, I believe.</p>
            <div className="speaker" id="sp4917"><span className="persName">Mr. AMBLER</span>—</div>
            <p className="p-in-sp">I would ask if there is not an officer in command of an important line of defence who was not in the United States service.</p>
            <div className="speaker" id="sp4918"><span className="persName">Mr. SCOTT</span>—</div>
            <p className="p-in-sp">
              I don't know; but I say that no appointment has been made under any ordinance reported by the Committee on Military Affairs. We provide in this ordinance for the appointment of four Brigadier Generals who are to be appointed by the Governor, by and with the advice and consent of the Convention. It gives the power of appointing these Brigadier Generals at once, and places them in
              command of the volunteers of the State. They are to have precedence in rank over volunteer Generals-even those that have already been appointed.
            </p>
            <p className="p-in-sp">In regard to enlistments, the committee deem it necessary that Virginia shall have a standing army in view of her exposed position on the border. While we wish to act in concert with the Confederate States army, we deem it proper that Virginia shall be in a condition to defend herself against any hostile demonstration from her enemies on the borders.</p>
            <div className="speaker" id="sp4919"><span className="persName">Mr. EARLY</span>—</div>
            <p className="p-in-sp">The organization of this army is in accordance with <PageNumber num={535} /> the organization of the U. S. army, with some little changes suggested by Gen. Lee himself.</p>
            <p className="p-in-sp">With regard to the employment of officers of the U. S. army, we have provided that that should be done by giving to the Convention the power to ratify or reject the appointment of the general officers, and to the Advisory Council the power to ratify or reject the subordinate appointments.</p>
            <p className="p-in-sp">This is the provision.</p>
            <p className="p-in-sp">
              "The officers of the provisional army shall take rank and precedence over all officers of the same grade of the volunteers and militia, without regard to date of commission; and the Governor may proceed at once to appoint, in the mode prescribed, the general and field officers of the provisional army, and they may be assigned to duty within their respective rank with the volunteers
              which have been or may be called into service."
            </p>
            <p className="p-in-sp">
              It authorizes the appointment of them all at once, and their assignment to the command of the volunteer forces of the State. If men are hereafter raised by enlistment sufficient to constitute a provisional army, they can be transferred to that command. We think we have fully provided for carrying out the first invitation given to the officers of the U. S. army by putting this check
              upon the appointments.
            </p>
            <p className="p-in-sp">
              In regard to one appointment, I mean that of Gen. Joseph E. Johnston, the Governor has consulted the interests of the public service in making it. My opinion is, that we don't want so many major generals. In the U. States army there is but one major general-there is in fact none now. There is a lieutenant general. I think that the officers who have the rank of brigadier general will be
              sufficient to carry out all the details of the organization here, which are in accordance with the details of the organization of the army of the U. States. We have not as many commanding officers as there are in the U. States, and this I regard as rather an improvement upon the U. States system.
            </p>
            <p className="p-in-sp">
              The rules and articles are very much the same as in the United States service. General Lee was of opinion that there ought to be a provisional army; but he is left in some doubt as to whether the State is authorized to organize a provisional army under the ordinance adopted yesterday. Certainly his opinion was that we ought to have a provisional army, and the details of the ordinance
              met with his concurrence. The mode of appointment, of course, he had nothing to do with. It would be rather an anomaly to incorporate into this ordinance a provision that the officers shall be taken from amongst <PageNumber num={536} /> a certain class of persons. There are some men who were heretofore in the United States army that may be competent to
              fill some of these positions; but we can, I think, insure the appointment at once of some of these officers, and give assurances to carry out the pledge made by us already.
            </p>
            <p className="p-in-sp">
              I think it is important that we should pass this ordinance, with a view to have the appointment confirmed. Certainly the Governor has not carried out-I mean no disrespect to him-the provisions of the ordinance under which he has been acting, in the matter of appointments. All of these officers, I am in hopes, will be put into this regular service, and then assigned to duty with the
              volunteers. Under this provision, giving the Convention a supervisory power over the appointments made by the Governor, this body will see that competent persons are made Brigadier Generals.
            </p>
            <div className="speaker" id="sp4920"><span className="persName">Mr. AMBLER</span>—</div>
            <p className="p-in-sp">Before the question is taken, allow me to say, that I don't want to interfere with the Military Committee. My object is to redeem the pledge made to the officers of the United States army, who were invited by this Convention to resign their commissions and take service in Virginia.</p>
            <p>The question was then taken on the motion to lay the ordinance on the table, and, the vote being taken by a count, resulted-ayes 53, noes 17-no quorum.</p>
            <div className="speaker" id="sp4921"><span className="persName">Mr. HARVIE</span>—</div>
            <p className="p-in-sp">I withdraw the motion.</p>
            <div className="speaker" id="sp4922"><span className="persName">Mr. BORST</span>—</div>
            <p className="p-in-sp">I move we take a recess till 4 o'clock.</p>
            <div className="speaker" id="sp4923"><span className="persName">Mr. MORTON</span>—</div>
            <p className="p-in-sp">I move to amend by substituting 5 o'clock.</p>
            <p>The amendment was agreed to, and the Convention accordingly adjourned till 5 o'clock P. M.</p>
            <div className="session" id="vsc1965.v4.2.11.1">
              <h3><span className="headingNumber">1.11.1. </span><span className="head">EVENING SESSION</span></h3>
              <p>The Convention re-assembled at the appointed hour.</p>
              <div className="speaker" id="sp4924"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair has received a communication from the Governor of the Commonwealth, which he begs leave to present for the consideration of the Convention ;</p>
              <div className="floatingText">
                <div className="floatingText_body">
                  <p>EXECUTIVE DEPARTMENT,</p>
                  <p>April 26th, 1861.</p>
                  <p>Gentlemen of the Convention:</p>
                  <p>
                    I transmit communication received this morning from Tazewell Taylor, Esq., of Norfolk city, in regard to the Navy Agency at that place; from Samuel V. Watkins, Esq., of Petersburg, in regard to the <PageNumber num={537} /> Surveyorship of the ports of Richmond and Petersburg; and from John S. Parker, Esq., Collector of the District of Cherrystone;
                    all of which are herewith transmitted for your consideration. I must be pardoned for urging immediate action upon the questions presented in these communications.
                  </p>
                  <p>Respectfully,</p>
                  <p>JOHN LETCHER.</p>
                  <p>The following communications, referred to in the Governor's letter, were then read :</p>
                  <p>NORFOLK, April 25, 1861.</p>
                  <p>GOVERNOR LETCHER, Richmond :</p>
                  <p>
                    Dear Sir-George Loyall, Esq., having resigned, about ten days ago, his position as Navy Agent of the United States, and the authorities here having demanded a surrender of the United States property in his possession-a demand which he, of course, will readily comply with-I take the liberty of suggesting for your consideration the propriety of appointing him Navy Agent of the
                    State for this port. This would, I think, save all further embarrassment, and greatly facilitate our operations here in that office. I need not add one word in relation to one so well known as Mr. Loyall.
                  </p>
                  <p>Yours very respectfully,</p>
                  <p>TAZEWELL TAYLOR.</p>
                  <p>PETERSBURG, April 25, 1861.</p>
                  <p>HON. JOHN LETCHER:</p>
                  <p>
                    Dear Sir: Being unwilling to hold office under the Washington Administration, I intended some time since to resign my office as Surveyor of the Ports of Richmond and Petersburg; but, by the advice of influential Southern friends, declined doing so. I am now unwilling to perform one official act under the Constitution of the old United States, and beg leave most respectfully to
                    ask your instructions as to my duty in the event of arrival of vessels at City Point. I know you have much business of far more importance pressing upon you, and await your convenience for a reply.
                  </p>
                  <p>Yours, very respectfully, SAMUEL V. WATKINS.</p>
                  <PageNumber num={538} />
                  <p>EASTVILLE, April 23, 1861.</p>
                  <p>
                    Sir: Please instruct me in regard to my duties as Collector of the District of Cherrystone, now that the State of Virginia has, through her Convention, passed an ordinance of secession. There is a small balance of public money in my hands, and I have ceased to make returns to Washington until I am instructed by you. What shall I do in regard to the enrollment and licensing of
                    vessels? I will be very thankful for instructions in full from you, and will endeavor, in all respects, faithfully to discharge my duty to the State of Virginia.
                  </p>
                  <p>Very respectfully,</p>
                  <p>JOHN S. PARKER,</p>
                  <p>Collector in the District of Cherrystone.</p>
                  <p>HON. JOHN LETCHER, Governor of the State of Virginia, Richmond, Va.</p>
                </div>
              </div>
              <div className="speaker" id="sp4925"><span className="persName">Mr. SHEFFEY</span>—</div>
              <p className="p-in-sp">I move that those communications be referred to the proper Committee. I don't know to what Committee they might be appropriately referred. Perhaps the Finance Committee would be the proper Committee to which to refer them.</p>
              <div className="speaker" id="sp4926"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">I would move to refer them to the Executive Council. They are charged with similar subjects.</p>
              <div className="speaker" id="sp4927"><span className="persName">Mr. FISHER</span>—</div>
              <p className="p-in-sp">I would state that the subjects of these communications constitute the main features of the matter now under consideration of the Committee of which I am Chairman, and if I could have gotten a meeting of that Committee yesterday morning, I would have made a report before this time.</p>
              <p className="p-in-sp">I now move that these communications be referred to the Special Committee on the duties of Postmasters and Revenue officers.</p>
              <p>The motion was agreed to.</p>
              <div className="speaker" id="sp4928"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair begs leave to submit another communication from the Governor of the Commonwealth, which the Secretary will read.</p>
              <p>The Secretary read the communication, as follows:</p>
              <div className="floatingText">
                <div className="floatingText_body">
                  <p>EXECUTIVE DEPARTMENT,</p>
                  <p>April 26th, 1861.</p>
                  <p>Gentlemen of the Convention:</p>
                  <p>I have received from Hon. Sterling Price, President of the Missouri <PageNumber num={539} /> State Convention, a communication, accompanied by resolutions adopted by that body. I communicate them herewith, for your consideration.</p>
                  <p>Respectfully,</p>
                  <p>JOHN LETCHER.</p>
                  <p>The communication and resolutions referred to are as follows:</p>
                  <p>MISSOURI STATE CONVENTION,</p>
                  <p>CONVENTION HALL, ST. LOUIS, MO.,</p>
                  <p>March 22, 1861.</p>
                  <p>To His Excellency the Governor of Virginia:</p>
                  <p>SIR: Under a resolution adopted by the Convention of this State, I am instructed to transmit to your Excellency the enclosed resolutions, which were adopted by said Convention.</p>
                  <p>I am, respectfully,</p>
                  <p>Your obedient servant,</p>
                  <p>STERLING PRICE,</p>
                  <p>President.</p>
                  <p>Attest:</p>
                  <p>S. A. LOWE, Sec'y.</p>
                  <p>Resolutions Adopted by the Missouri State Convention.</p>
                  <p>1. Resolved, That at present there is no adequate cause to impel Missouri to dissolve her connection with the Federal Union, but on the contrary she will labor for such an adjustment of existing troubles as will secure the peace, as well as the rights and equality of all the States.</p>
                  <p>
                    2. Resolved, That the people of this State are devotedly attached to the institutions of our country, and earnestly desire that, by a fair and amicable adjustment, all the causes of disagreement that at present unfortunately distract us as a people may be removed, to the end that our Union may be preserved and perpetuated, and peace and harmony be restored between the North and
                    South.
                  </p>
                  <p>
                    3. Resolved, That the people of this State deem the amendments to the Constitution of the United States, proposed by the Hon. John J. Crittenden of Kentucky, with the extension of the same to the Territory hereafter to be acquired by treaty or otherwise, a basis <PageNumber num={540} /> of adjustment which will successfully remove the causes of
                    difference forever from the arena of national politics.
                  </p>
                  <p>
                    4. Resolved, That the people of Missouri believe the peace and quiet of the country will be promoted by a Convention to propose amendments to the Constitution of the United States, and this Convention therefore urges the Legislature of this State, and of the other States, to take the proper steps for calling such a Convention in pursuance of the fifth article of the Constitution,
                    and for providing by law for an election by the people of such number of delegates as are to be sent to such Convention.
                  </p>
                  <p>
                    5. Resolved, That, in the opinion of this Convention, the employment of military force by the Federal Government to coerce the submission of the seceding States, or the employment of military force by the seceding States to assail the Government of the United States, will inevitably plunge this country into civil war, and thereby entirely extinguish all hope of an amicable
                    settlement of the fearful issues now pending before the country; we, therefore, earnestly entreat as well the Federal Government as the seceding States to withhold and stay the arm of military power, and on no pretense whatever bring upon the nation the horrors of civil war. This Convention is not sufficiently acquainted with all the facts concerning the forts of the United
                    States, within the limits of the seceding States, as to be able to give an opinion with reference to the course to be pursued by the Federal Government, but this Convention earnestly hopes that such action will be taken by the United States and the seceding States as will avoid all hostile collision between the United States and the said seceding States; and, in order to the
                    restoration of harmony and fraternal feeling between the different sections, we would recommend the policy of withdrawing the Federal troops from the forts, within the borders of the seceding States, where there is danger of collision between the State and Federal troops.
                  </p>
                  <p>6. Resolved, That when this Convention adjourns its session in the city of St. Louis, it adjourn to meet in the Hall of the House of Representatives at Jefferson City, on the third Monday of December, 1861.</p>
                  <p>
                    7. Resolved, That there shall be a committee, consisting of the President of this Convention (who shall be ex officio chairman) , and seven members, one from each congressional district of the State, to be elected by this Convention, a majority of which shall have power to call this Convention together, at such time prior to the third Monday in December next, and at such place as
                    they may think the public <PageNumber num={541} /> exigencies require; and in case any vacancy shall happen in said committee, by death, resignation, or otherwise, during the recess of this Convention, the remaining members or member of said committee shall have power to fill such vacancy.
                  </p>
                  <p>
                    Whereas, it is probable that the Convention of the State of Virginia, now in session, will request a meeting of Delegates from the Border States, or Border Slave States, for the purpose of devising some plan for the adjustment of our national difficulties; and, whereas, the State of Missouri participates strongly in the desire for such adjustment, and desires to show respect for
                    the wishes of Virginia : Therefore,
                  </p>
                  <p>
                    8. Be it Resolved, That the Convention will elect seven delegates, one from each congressional district, whose duty it shall be to attend at such time and place as may be designated by the Convention of the State of Virginia for the meeting of delegates from the Border States, or Border Slave States; and if there should assemble, then and there, delegates duly accredited from a
                    majority of the States invited to such conference, then the delegates from this Convention shall enter into conference with them, and shall endeavor to devise a plan for the amicable and equitable adjustment of all matters in difference between the States of this Union. And the delegates appointed under this resolution shall report their proceedings in such conference, and any
                    plan that may be there agreed upon, to this Convention for its approval or rejection.
                  </p>
                </div>
              </div>
              <div className="speaker" id="sp4929"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">I move to lay the communication of the Governor and accompanying documents on the table.</p>
              <p>The motion was agreed to.</p>
              <div className="speaker" id="sp4930"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">I beg leave also to submit a communication from JOHN Q. MARR, one of the delegates from the county of Fauquier, which the Secretary will read.</p>
              <p>The Secretary read the communication as follows :</p>
              <div className="floatingText">
                <div className="floatingText_body">
                  <p>WARRENTON, FAUQUIER, VA.,</p>
                  <p>April 23, 1861.</p>
                  <p>To the President of the State Convention, Richmond, Va.:</p>
                  <p>
                    On the 14th inst., I was called home by circumstances of an imperative and controlling character, and which have since detained me from the Convention. I go to-morrow to the discharge of military duties to which I have been ordered in the service of the State, and <PageNumber num={542} /> I ask that leave of absence from the Convention be granted me
                    whilst in the discharge of those duties, and that this letter be incorporated in the proceedings of your body as explanatory of my absence from the Convention. Had I been present last week, I should have voted for the ordinance of secession.
                  </p>
                  <p>Respectfully,</p>
                  <p>JOHN Q. MARR.</p>
                </div>
              </div>
              <div className="speaker" id="sp4931"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">I move that the leave of absence asked for be granted, and that the communication be entered upon the journal. The motion was agreed to.</p>
              <div className="speaker" id="sp4932"><span className="persName">Mr. THORNTON</span>—</div>
              <p className="p-in-sp">I rise to a privileged question.</p>
              <p className="p-in-sp">
                During my absence from the Convention, I learn that a vote had been taken upon two propositions-one a convention between Virginia and the Confederate States, and the other an ordinance adopting the provisional government of the Confederate States as the government of Virginia, subject to the vote to be taken on the fourth Thursday in May adopting or rejecting the ordinance of
                secession heretofore passed by this Convention.
              </p>
              <p className="p-in-sp">I ask leave to record my vote in favor of these two propositions.</p>
              <p>Leave was unanimously granted.</p>
              <div className="speaker" id="sp4933"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">I rise with a view of asking that the report of a committee shall be taken up.</p>
              <div className="speaker" id="sp4934"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">There is a report of a committee now before the Convention. The report of the committee on military affairs is still pending.</p>
              <div className="speaker" id="sp4935"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">I ask that it may be passed by for the present, and my reason is this, that the subject which I desire the Convention to consider is of more importance than any other one subject that shall engage the deliberations of this body. My object in moving to pass by the pending subject is, to take up the ordinance reported by the committee on taxation.</p>
              <p className="p-in-sp">It is important that this subject shall be acted upon; and I don't expect to see during the remainder of the session so many present as there are present now.</p>
              <p className="p-in-sp">It is due to the East; it is due to the West; it is due to the Commonwealth that this matter should be acted upon; and I do trust that by unanimous consent it will be the pleasure of the Convention to pass by this ordinance and take up the report of the committee on taxation which can be disposed of very soon.</p>
              <PageNumber num={543} />
              <div className="speaker" id="sp4936"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair thinks the proper course would be to move to lay it upon the table.</p>
              <div className="speaker" id="sp4937"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">That proposition is already pending.</p>
              <p className="p-in-sp">The motion pending is to lay the report of the military committee on the table and print it.</p>
              <div className="speaker" id="sp4938"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The motion was withdrawn by the gentleman from Amelia [<span className="persName">Mr. HARVIE</span>] just before the recess.</p>
              <div className="speaker" id="sp4939"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">I renew it, then. This is a very important matter, and ought to be acted upon with due deliberation.</p>
              <div className="speaker" id="sp4940"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">I call the previous question upon that motion.</p>
              <p>The call was sustained, and the main question being ordered to be put, it was decided in the affirmative.</p>
              <div className="speaker" id="sp4941"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">I am fairly up at last-I have the floor. [Laughter.] I now move that the ordinance upon the subject of taxation be taken up.</p>
              <div className="speaker" id="sp4942"><span className="persName">Mr. RANDOLPH</span>—</div>
              <p className="p-in-sp">Will the gentleman give way for a moment to enable me to offer an important resolution. It will give rise to no debate.</p>
              <div className="speaker" id="sp4943"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">I shall do so.</p>
              <div className="speaker" id="sp4944"><span className="persName">Mr. RANDOLPH</span>—</div>
              <p className="p-in-sp">I offer the following:</p>
              <p className="p-in-sp">"Resolved, That a committee of five be appointed to inquire into the expediency of providing by ordinance for auditing and settling all claims which may arise against the Commonwealth for expenditures arising from the present emergency."</p>
              <p>The resolution was adopted.</p>
              <div className="speaker" id="sp4945"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">I now renew my motion to take up the ordinance on the subject of taxation.</p>
              <p>The question was taken on the motion by a count and resulted-ayes 47, noes 20, no quorum. A second count was had, it being evident that a quorum was present, and resulted-ayes 55, noes 27. So the ordinance was taken up.</p>
              <p>It reads as follows:</p>
              <div className="floatingText">
                <div className="floatingText_body">
                  <p>AN ORDINANCE to amend the Constitution of this Commonwealth, so as to strike out the twenty-second and twenty-third sections of the fourth article of the present Constitution, and insert the following in lieu thereof:</p>
                  <p>
                    Taxation shall be equal and uniform throughout the Commonwealth, and all property shall be taxed in proportion to its value, which shall be ascertained in such manner as may be prescribed by law; but any property may be exempted from taxation by the vote of a majority <PageNumber num={544} /> of the whole number of members elected to each House of
                    the General Assembly.
                  </p>
                  <p>This ordinance shall take effect on the first day of July next, when ratified by a majority of the votes of the people of this Commonwealth, cast at a poll to be taken thereon, on the fourth Thursday in May next, in pursuance of a schedule hereafter to be enacted.</p>
                  <p>Done in Convention in the City of Richmond, on the 26th day of April, in the year of our Lord one thousand eight hundred and sixty-one, and in the eighty-fifth year of the Commonwealth of Virginia.</p>
                </div>
              </div>
              <p>The pending question was upon the following amendment, offered by <span className="persName">Mr. BRUCE</span>, of Halifax:</p>
              <p>"Provided, That slaves over 12 years of age shall not be valued at more than $500, and slaves under 12 years at more than $250."</p>
              <div className="speaker" id="sp4946"><span className="persName">Mr. SUTHERLIN</span>—</div>
              <p className="p-in-sp">I will offer this as a substitute for the amendment of the gentleman from Halifax :</p>
              <p className="p-in-sp">"Be it ordained, That the 27th section of the 4th article of the Constitution of this State be amended so as to read as follows:</p>
              <p className="p-in-sp">
                "On the passage of every act which imposes, continues or revives a tax, or creates a debt or charge, or makes, continues or revives any appropriation of public or trust money or property, or releases, discharges or commutes any claim or demand of the State, the vote shall be determined by yeas and nays, and the names of the persons voting for and against the same shall be entered on
                the journals of the respective Houses and a majority of all the members elected to the House of Delegates and two thirds of all the members elected to the Senate, shall be necessary to give it the force of a law."
              </p>
              <div className="speaker" id="sp4947"><span className="persName">Mr. SUTHERLIN</span>—</div>
              <p className="p-in-sp">The only alteration in that section is that it requires a two-thirds vote in the Senate to make any appropriation. As the Constitution is now, it requires a majority of the members of both Houses. This amendment has it that it will require a majority of the House and two-thirds of the Senate.</p>
              <div className="speaker" id="sp4948"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">Mr. President, I will promise this House, if it will be kind enough to give me its attention, that I will not trespass more than a few minutes upon its patience.</p>
              <p className="p-in-sp">
                The 17th of April and the 25th of April, 1861, will be days memorable in the annals of our country. In a crisis like this, when we are threatened with invasion, when we ought to have every heart in the Commonwealth of Virginia beating in unison, we ought to pursue that course which will give satisfaction, and ought to give satisfaction to every portion of the Commonwealth.
              </p>
              <p className="p-in-sp">
                The question of taxation which is proposed in that ordinance to be <PageNumber num={545} /> made a portion of the Constitution, is a question that was not mooted during the last canvass, at least among my people. I don't know what their opinions are, but I take the responsibility, as their representative, of giving my vote in favor of that ordinance. I
                am confident in the belief, that I can meet them as their representative, and that my action in this matter will not only meet with their approval, but their gratification.
              </p>
              <p className="p-in-sp">It is unquestionably a fact, that we are in a state of war, and that our revenue must be increased. We shall have to tax all the property that has not been taxed, and bring the whole of it under its due share of taxation.</p>
              <p className="p-in-sp">
                I confess, sir, that this is a question to which I have not devoted many hours of reflection. I have listened to the debates incidentally as they progressed, but it seems to me, from the best reflection that I have given to it, that it is a principle that will commend itself to the approval of all portions of the Commonwealth. I can say to my constituents, that if I had been too
                generous in voting for this ordinance, I have been just also. The operation of that ordinance will, probably, be heavier upon me than upon any of my constituents; for, in the disposition of my slave property-I state this as a mere explanation-my most efficient force is out of the Commonwealth. I have an undue portion under 12 years of age now free from taxation, and I will cheerfully
                submit to their being subject to tax like any other property. The principle, sir, is a just principle in itself, and should not be objected to.
              </p>
              <p className="p-in-sp">
                It is said that a slave under 12 years of age is a tax upon his owner. But is it not increasing in value every year, and that, too, probably beyond any other description of property in the Commonwealth. A colt that is but one year old is not fit for service, and yet it is a taxable piece of property; and upon the same principle should slaves under twelve years be made subject to
                taxation.
              </p>
              <p className="p-in-sp">But, Mr. President, I will not proceed further upon the principle involved in this question. I did not intend to go into it to this extent. I press this question now upon this ground, which I think ought to be satisfactory to the whole State.</p>
              <p className="p-in-sp">
                It is unquestionably our interest that we should be united-that every portion of the Commonwealth should be united. We have passed an ordinance of secession. We have gone, I may say, out of one Confederacy and into another. Our friends from the West who are with us, have stood up and acted manfully with us. From the accounts that we have from the West, there exist there the same
                feelings <PageNumber num={546} /> of patriotism, the same sentiments and sympathies that exist in the East. I ask, then-I appeal to the Eastern men, after what has occurred-I appeal to them as one man to come up and graciously grant that which this ordinance proposes. There is no one who does not concede the fact that in a very few years that principle
                will be established. Whatever may be the opinion of Eastern men as to the justice of it, as a matter of expediency, I think that they ought, as I trust they will, concede it.
              </p>
              <p className="p-in-sp">
                The amendment that has been proposed is an exact transcript from the present constitution with this single exception, that instead of appropriation bills being passed by a majority of each House, an appropriation bill must have a majority in the House of Delegates and a two-thirds vote in the Senate. That is a protection in the hands of the minority, and I trust with that amendment,
                which is an act of justice to our Western borders, that we will come forward, and not only give a majority vote upon this ordinance, but a unanimous vote, if possible. I should be delighted to see that result and then I should say, that the 17th, 25th and 26th of April will be days that will be recorded in our annals as auspicious days, for our State and, I doubt not our country. How
                triumphantly will our friends from the West who have voted with us for the ordinance of secession, and the other important ordinances return to their constituents, if by their fidelity in remaining at their posts this ordinance should pass. Instead of being censured by their constituents, they will stand higher than ever, while those who have deserted their posts, will be remembered
                only with scorn and infamy.
              </p>
              <div className="speaker" id="sp4949"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair must suggest to, the gentleman, that it is not in order to reflect upon absent members of this body.</p>
              <div className="speaker" id="sp4950"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">Did I reflect upon absent members. If I did, I did not so intend; I don't mean to reflect upon any member of this body.</p>
              <div className="speaker" id="sp4951"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair so thought.</p>
              <div className="speaker" id="sp4952"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">Would it be reflecting upon an absent member to say, that he was raising the flag of rebellion when the fact was so?</p>
              <div className="speaker" id="sp4953"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The gentleman has disclaimed any reflection upon any member of this body, and that is enough.</p>
              <div className="speaker" id="sp4954"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">I withdraw whatever I may have said disrespectful to any member.</p>
              <p className="p-in-sp">
                Sir, I only express the feeling of every patriot in the Commonwealth when I made the allusion which I did. I use no names; but I ask my brethren of the East, since they must know that the principle which is here sought for is one which will, undoubtedly, be conceded in two <PageNumber num={547} /> or three years time, in any event, to come forward now,
                and in a spirit of magnaminity vote for this ordinance. In view also of the fact that we shall be compelled to raise our taxes, I ask if it does not become them, as generous, noble, gallant men, to stand by our gallant friends from the West? I trust they will do so, and, in order that an early opportunity may be afforded them to attest their appreciation of the fidelity of our
                Western friends, I call the previous question.
              </p>
              <div className="speaker" id="sp4955"><span className="persName">Mr. HALL</span>, of Marion—</div>
              <p className="p-in-sp">I hope the gentleman will withdraw that motion.</p>
              <div className="speaker" id="sp4956"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">I withdraw it for the present.</p>
              <div className="speaker" id="sp4957"><span className="persName">Mr. HALL</span>, of Marion—</div>
              <p className="p-in-sp">
                It is not my purpose to enter into a general discussion of this question. So far as the discussion proceeded to-day, it has been assumed that the amendments proposed here are satisfactory to the Western members. How far it may be so, I do not know. For one, I am opposed to any amendment. I shall vote against any amendment. I came here asking this question as a right, and I do not
                desire it to be given merely as a plank to bear up those who vote for secession. I have heard intimations time and again thrown out: nay, direct propositions made, that if Western men would vote for secession they would get this measure.
              </p>
              <div className="speaker" id="sp4958"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">I say that if the gentleman refers to me he is entirely mistaken. I will ask the gentleman to let me explain.</p>
              <div className="speaker" id="sp4959"><span className="persName">Mr. HALL</span>, of Marion—</div>
              <p className="p-in-sp">
                I make no reference to the gentleman, and there is, therefore no need for his explanation on that score. The remarks to which I refer have been made here in public some time ago. So far as the contract referred to is concerned, I will leave the Convention to draw its own inference. I don't desire that this matter should be prejudiced by any of these matters, or that they should be
                pressed as considerations why this ordinance should be now voted for.
              </p>
              <p className="p-in-sp">
                It has been said that several members are gone home who have been friendly to this measure. I think it is necessary to explain to this House why the peculiar friends of this ordinance have left. It may be thought a great crime that gentlemen from the North-west should be away from here. Opprobrious epithets have been applied to them. They can't have any reference to me, for I have
                been at my post. But it has reference to friends of mine who are absent. I deem it just to them to say, that they have had to go home to attend to their families. I am sorry that gentlemen would not be more gallant, and wait to confront those against whom these charges of absenting themselves, and <PageNumber num={548} /> deserting their posts are made.
                I heard the gentleman from Barbour make an allusion which was out of place.
              </p>
              <div className="speaker" id="sp4960"><span className="persName">Mr. WOODS</span> of Barbour—</div>
              <p className="p-in-sp">Does the gentleman refer to me.</p>
              <div className="speaker" id="sp4961"><span className="persName">Mr. HALL</span>, of Marion—</div>
              <p className="p-in-sp">I have heard the gentleman from Barbour make some reference to the absence of those who are now away. I have heard some explanations of the cause of the absence of those who were called away.</p>
              <div className="speaker" id="sp4962"><span className="persName">Mr. WOODS</span>—</div>
              <p className="p-in-sp">I feel constrained to require of the gentleman to say, whether he intended that these remarks should apply to me personally?</p>
              <div className="speaker" id="sp4963"><span className="persName">Mr. HALL</span>, of Marion—</div>
              <p className="p-in-sp">Certainly not. My remarks are general. It was in my mind that the gentleman from Barbour made some remarks of this sort.</p>
              <div className="speaker" id="sp4964"><span className="persName">Mr. WOODS</span>—</div>
              <p className="p-in-sp">In private conversation with yourself?</p>
              <div className="speaker" id="sp4965"><span className="persName">Mr. HALL</span>—</div>
              <p className="p-in-sp">I think not, sir.</p>
              <div className="speaker" id="sp4966"><span className="persName">Mr. WOODS</span>—</div>
              <p className="p-in-sp">Yes, sir.</p>
              <div className="speaker" id="sp4967"><span className="persName">Mr. HALL</span> ;</div>
              <p className="p-in-sp">In the remarks I have made, I meant nothing offensive to any one; but I do mean to defend those who are absent.</p>
              <p className="p-in-sp">I think the report of the committee is very just, and I trust, when we act upon it, that we shall do so upon the principle upon which it now stands.</p>
              <p className="p-in-sp">I shall oppose any amendment. The principle is' right, and, therefore, as was remarked by the gentleman from Orange [<span className="persName">Mr. MORTON</span>] , it is due not only to the West, but to the whole State, that it should be granted. That is the basis upon which I rest this claim on the part of the West, and I trust it will pass without being influenced by any extraneous considerations.</p>
              <div className="speaker" id="sp4968"><span className="persName">Mr. BORST</span>—</div>
              <p className="p-in-sp">I call the previous question.</p>
              <div className="speaker" id="sp4969"><span className="persName">Mr. WOODS</span>, of Barbour—</div>
              <p className="p-in-sp">I trust the gentleman will withdraw it, to enable me to make a personal explanation. I will renew it.</p>
              <div className="speaker" id="sp4970"><span className="persName">Mr. BORST</span>—</div>
              <p className="p-in-sp">I withdraw it.</p>
              <div className="speaker" id="sp4971"><span className="persName">Mr. WOODS</span>—</div>
              <p className="p-in-sp">I regret, exceedingly, that my friend from Marion [<span className="persName">Mr. HALL</span>] , should have deemed it necessary or excusable to have pursued the line of remark in which he has indulged towards those members from the North-west, who have been absent and returned. I believe I am one of them-I am perhaps the only person; but those members from the North-west are not the only persons who occupy that position on this floor.</p>
              <div className="speaker" id="sp4972"><span className="persName">Mr. HALL</span>, of Marion—</div>
              <p className="p-in-sp">I will state that my remarks had no reference to the members from the North-west.</p>
              <div className="speaker" id="sp4973"><span className="persName">Mr. WOODS</span>—</div>
              <p className="p-in-sp">
                I can only judge of what the gentleman means by what he says. I have good ears to listen attentively to what is said <PageNumber num={549} /> here. He disclaims any purpose to offend. I accept his disclaimer. I was called home-as I trust no other gentleman in this Convention will be called home during the session-by a telegraphic despatch announcing to
                me the fact that my child was on the point of death; and when I went home I found my child dead. Before departing from here, I left provision to have me telegraphed for, in order that I may return in time to put the seal of my approbation upon that ordinance of secession.
              </p>
              <p className="p-in-sp">
                Sir,, I would despise myself, if for a single moment I could permit any consideration to deter me from pursuing the line of my duty. I came here to do my duty to my country, and I did it. I will now leave the issue to God and to my country. The gentleman need have no apprehension about my being bolstered up by my constituents in regard to the course which I have pursued here. I have
                the great satisfaction to know that I have the entire confidence of my people, and I am determined never to betray that confidence. It does not make a particle of difference to me whether you pass this tax question or not, so far as a manly defence of my State and of every square inch of her soil is concerned. My friend from Northampton
                [<span className="persName">Mr. FISHER</span>] made a remark at an early period of the secession to his friend from Preston [<span className="persName">Mr. BROWN</span>] , to the effect that this tax question would be conceded by the East as an equivalent, or quid pro quo, for the votes of Western gentlemen in favor of Secession.</p>
              <p className="p-in-sp">
                I am sorry that no better argument could be found on this floor, in the mouths of gentlemen who complain of the Secession party, than that remark. I would have preferred that the remark had not been made. The gentleman who made it disclaimed it for every body else, except himself personally; and there is no point of parliamentary law better settled than that a man has a right to make
                his own disclaimer.
              </p>
              <p className="p-in-sp">
                Now, as to the course the peculiar friends of this measure, as he thinks proper to designate them, have pursued-I protest against any such designation-I recognize no man as the peculiar friend of this measure in Virginia. It is the interest of every man here to favor this measure; and I should think that the circumstances by which we are surrounded, and which are pressing upon us
                from every side, are calculated to force this conviction upon every heart. But, I will admit, if they desire it, that these gentlemen who are now absent were the peculiar friends of this measure; and I have only to say in that regard, "God save me from my friends," if the course of its advocates here is to be the measure of their zeal for this measure.
              </p>
              <PageNumber num={550} />
              <p className="p-in-sp">
                I regret exceedingly that the gentleman should have aroused such an unpleasant feeling in my heart; but justice to myself required that I should say this much. I freely accord to him that he has been both persistent and consistent, and I have no doubt will remain so, in the choice and in the pursuit of that line of conduct that he has marked out for himself. I admire all these
                qualities wherever found, though I may not be able to coincide with that line of conduct. I regret very much that he should have suffered his zeal to have aroused any feeling of this kind. I have not been absent a day nor an hour, nor a half an hour from my seat until the occasion to which I referred. That, sir, is my duty, and I think that we ought to be able to bear that testimony
                to each other, for, according to my observation, in only one or two instances during the Convention have any of us been found absent.
              </p>
              <p className="p-in-sp">Mr. President, I have presented my views already on this subject. I can most cheerfully endorse all that my friend from Orange [<span className="persName">Mr. MORTON</span>]
                has said in regard to the propriety and justice of settling this question now and forever, because, however unpleasant it may be to refer to this fact, yet the fact is patent all over the United States, that members of this Convention are attempting to disorganize the State of Virginia at Wheeling, and it is well enough that it should be known, so that the seal of eternal infamy
                should be stamped upon the brow of every one of them. The only possible hope; the only remaining possible contingency upon which they can expect to disturb the loyalty of the citizens of Virginia is upon this vexed question.
              </p>
              <p className="p-in-sp">
                But I shall not attempt to charge this upon any body further than I have done heretofore; and nothing was farther from my intention to-day than to be tempted into saying one word on this question. I deemed it due to myself, however, to make this explanation of the cause of my absence during a period of five or six days. I promised the gentleman to renew the call for the previous
                question.
              </p>
              <div className="speaker" id="sp4974"><span className="persName">Mr. WILSON</span>—</div>
              <p className="p-in-sp">I would ask the gentleman to withdraw the call for a moment.</p>
              <div className="speaker" id="sp4975"><span className="persName">Mr. BORST</span>—</div>
              <p className="p-in-sp">I withdraw it.</p>
              <div className="speaker" id="sp4976"><span className="persName">Mr. WILSON</span>—</div>
              <p className="p-in-sp">It is very desirable in this crisis that we should have unity of action. It is to produce that unity that I most anxiously hope that this Convention will now pass this ordinance. Every mail from the North-west brings me accounts of more and more dissatisfaction in that section, because of the proceedings of this body.</p>
              <p className="p-in-sp">
                I will call attention to a paper I hold in my hand, which shows that a meeting was held the other day in the North-west, at which <PageNumber num={551} /> an effort was made to throw off allegiance to Virginia. That proceeding was based upon the passage of the ordinance of secession and a refusal on the part of the Convention to pass the ordinance in
                relation to the tax question. I have heard that the good feeling heretofore existing there was very much impaired because of this fact. There is a gentleman now in this city, who came here with a view of urging, if possible, the passage of this ordinance. There will be no dissatisfaction in that section if this ordinance is passed. I heard to day that personal conflicts are expected
                in my county, and that an influential gentleman there was threatened with personal violence because of his advocacy of secession. I have heard it stated that threats of personal violence were made towards me; and if such an issue shall come, I am determined that I shall not occupy a doubtful position. I heard it said that the ladies of our town
                [Clarksburg]
                have to go armed, and when that is the case, we may easily imagine what the condition of things in that section is. Every letter which I receive from home satisfies me of the great necessity of passing this ordinance, for it is only through that that any hope of harmony and unity can exist. I hope, therefore, that the East will concede this act of justice, and defeat the purposes of
                those who are seeking to make this question the basis of discord and division in the North-west.
              </p>
              <div className="speaker" id="sp4977"><span className="persName">Mr. BORST</span>—</div>
              <p className="p-in-sp">I now renew the call for the previous question upon the amendment to the amendment offered by the gentleman from Pittsylvania [<span className="persName">Mr. SUTHERLIN</span>] .</p>
              <p>The call was sustained and the question being put, it was decided in the negative.</p>
              <div className="speaker" id="sp4978"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">
                I have heard it intimated at various times during the session of this Convention, that a portion of this State would not submit to the action of this Convention, or to the action of the State of Virginia, if it should be in approval of our action. Sir, I have regretted this exceedingly, coming from whatever quarter it may. I ask now why is it that this occasion is taken to utter
                denunciation against gentlemen who have gone from this Convention to their homes, upon rumors, when we have heard so many rumors that have proved to be false?
              </p>
              <p className="p-in-sp">
                Sir, I know that there are gentlemen who have left this Convention for their homes in Western and North-western Virginia, who have no idea of attempting to thwart the course of this Convention, or the voice of Virginia; and I think that gentlemen, after they have permitted these declarations to go so often unrebuked, ought, at least, to have a little forbearance now and wait till we
                ascertain certainly what is to be the course of those members who are absent.
              </p>
              <PageNumber num={552} />
              <p className="p-in-sp">
                Gentlemen living in the Northwestern portion of this Commonwealth are in a peculiar position. Those living on the borders of the Ohio river, and on the Pennsylvania frontier, have their wives and children who may be in hourly peril, and no one will deny that a man's duty is first to his family. The man who disregards the safety of his wife and children is not to be relied upon to
                defend his country.
              </p>
              <div className="speaker" id="sp4979"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">Will the gentleman permit the Chair to suggest to the gentleman the propriety of his confining himself to the amendment.</p>
              <div className="speaker" id="sp4980"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">Well, sir, I trust in God that we may all be united in our action-that there will be no division-that there may be perfect unanimity; but I do think that gentlemen must have taken too early an opportunity to rebuke these rumored threats of opposition to the course that we should pursue.</p>
              <p className="p-in-sp">
                Sir, I have felt some temptation myself to leave this Convention. I have an aged father living within thirteen miles of the Ohio, with some thirty or forty negroes, and in the most exposed portion of that region, and the question presented itself to me whether it was my duty to go to his assistance rather than stay here to perform my duty. I can understand the feelings which may
                actuate gentlemen of this Convention who have their wives and children living unprotected in these exposed positions, and I do think they are entitled to some forbearance. I know that a number of these gentlemen went away for no other purpose than to see that their wives and children were in a proper condition.
              </p>
              <p className="p-in-sp">
                Sir, we have not now a soldier in all the valley of the Kanawha. In the North-west, they have not arms to arm a company, and there is no means of communicating with the people there, or sending them arms. In view of these facts, I think that gentlemen should have some consideration for those who have left. Why is it that now, after we have had so much opposition to this question,
                that gentlemen now come to find it so important that we shall adopt this provision? When, a single week ago, I announced that, in my opinion, this principle of taxation was a just, correct principle, but that I did not feel authorized on account of the position I held to my constituents, to vote for it; when I announced the fact that I believed it was a correct principle, several of
                my friends came to me and stated that I had given an excuse to Western gentlemen to claim this tax; and some Western gentlemen have attributed their change of position to that remark.
              </p>
              <p className="p-in-sp">
                This principle is either just or unjust, and this Convention ought <PageNumber num={553} /> to decide the question upon the principle of justice. I ask why it is that it is proposed now to do it because gentlemen from particular quarters of the country have voted for the ordinance of secession? Why is it placed upon the ground that it is reported that
                gentlemen of this Convention have raised the standard of rebellion? Sir, if the principle is unjust in itself, we ought not to yield to any improper demand from the North-west. If it is just, we ought to yield it; but not in order to enable certain gentlemen to sustain themselves at home.
              </p>
              <p className="p-in-sp">
                I make these remarks in order to deprecate the course of debate which has been pursued here upon this question; and I would ask if it is calculated to increase harmony and united action in Virginia, to indulge in the course of remark thrown out here in regard to gentlemen who have absented themselves recently from this Convention? I trust in God that no Virginian will be found in any
                quarter of this State, taking ground against his State. But I submit, if it is calculated to bring about unanimity and harmony for gentlemen here to make charges of infidelity against absent members, even if they had intimations that some have done wrong? If it is true, we ought to pass it over in silence. If it is a mere matter of rumor, we ought to disregard it, and not make it the
                ground of charges of treason and rebellion against absent members.
              </p>
              <p className="p-in-sp">I trust that every member has come here to sustain his State; and when action is necessary I trust that every man will be found sustaining the interests of his State, and protecting and defending her honor.</p>
              <div className="speaker" id="sp4981"><span className="persName">Mr. STAPLES</span>, of Patrick—</div>
              <p className="p-in-sp">I now call the previous question on the amendment of the gentleman from Halifax [<span className="persName">Mr. BRUCE</span>] .</p>
              <p>The call was sustained and the main question ordered to be put. <span className="persName">Mr. FISHER</span>-I ask for the yeas and nays.</p>
              <p>
                The yeas and nays were ordered, and the votes being taken resulted -yeas 36, nays 56-as follows:<span className="note" id="Note51"><span className="noteLabel">3</span>The Journal adds Deskins to negative votes listed here.</span>
              </p>
              <p>YEAS-Messrs. John Janney [President] , Blow, Boisseau, Bouldin, Bruce, Chambliss, R. Y. Conrad, J. H. Cox, Critcher, Fisher, Flournoy, Forbes, Garland, Gravely, Gregory, John Goode, Jr., Addison Hall, Holladay, Isbell, Kilby, Leake, Macfarland, C. K. Mallory, Marye, Montague, Neblett, Nelson, Richardson, Seawell, Speed, Strange, Sutherlin, Thornton, Tredway, Tyler, Wickham -36.</p>
              <p>
                NAYS-Messrs. Ambler, Aston, A. M. Barbour, James Barbour, Baylor, Borst, Boyd, Brent, Campbell, Caperton, Carter, Cecil, Chapman, Coffman, Conn, Dorman, Early, Echols, French, Gillespie, Graham, Goggin, Cyrus Hall, Ephraim B. Hall, L. S. Hall, Harvie, Haymond, Holcombe, Marmaduke Johnson, Peter C. Johnston, Kent, Lawson, Lewis, McComas, Marshall, Miller,
                <PageNumber num={554} /> Moffett, Morton, Moore, Preston, Pugh, Randolph, R. E. Scott, Sharp, Sheffey, Sitlington, Spurlock, Staples, Tayloe, R. H. Turner, .Franklin P. Turner, Williams, Wilson, Woods, Wysor-56.
              </p>
              <p><span className="persName">Mr. STUART</span>, of Augusta, paired off with <span className="persName">Mr. GOODE</span>, of Mecklenburg.</p>
              <div className="speaker" id="sp4982"><span className="persName">Mr. CHAMBLISS</span>—</div>
              <p className="p-in-sp">I move to amend the ordinance by striking out the second paragraph thereof, and inserting the following:</p>
              <p className="p-in-sp">"Every slave shall be assessed with a tax equal to, and not exceeding that assessed on land of the value of three hundred dollars."</p>
              <div className="speaker" id="sp4983"><span className="persName">Mr. CHAMBLISS</span>—</div>
              <p className="p-in-sp">
                I have offered that amendment as some compromise of this question of taxation upon slave property. It may be that the tax which may be imposed upon slave property, under that amendment, will exceed an ad valorem rate of taxation. But that is not the question which I wish to carry out. We are perfectly willing, and expect to pay more taxes on slaves than we have heretofore. It is
                nothing but right that we should do so. It is proper that we should contribute more than we have heretofore done in taxation upon this species of property. Now, all I desire is, that there shall be a limitation upon the valuation of slave property. If you strike out the limitation in value, then our slave property is to be valued, not according to its local value, but its value in
                Southern States. You cannot value land in that way. Land is valued according to its local valuation. The land in Western Virginia, is not valued as it is in other localities. Land there worth $10 an acre would be worth $40 an acre on the seaboard. So with negroes. They would be worth much more in the. South than here. If you tax according to the amendment of my friend from Halifax
                all over 12 years at the rate of $500 and under 12 at the rate of $250-it makes the average value $325. There are at present in the State 200,000 slaves under 12 years of age, and estimated upon the basis of my proposition, this number would yield at the sum of $320,000 annually, at the present rate of taxation. I don't know what the people in Eastern Virginia think; I don't know
                what my constituents think about this question. I heard from some of my constituents to-day, and they I understand are favorable to an increase of taxation upon slave property. So far as I am concerned, I am willing to pay the additional tax; and I take it for granted, the feeling throughout the slaveholding portion of Virginia is favorable to an increase of the tax upon slave
                property.
              </p>
              <p className="p-in-sp">
                You have in the amendment which I offer, the very probation in the matter of valuation that we need. You remember at the last Convention, when that amendment was introduced by one of my colleagues, <PageNumber num={555} /> that the question was, that we desired to fix the valuation of slave property so that it may not be changed by the fluctuating
                prices.
              </p>
              <div className="speaker" id="sp4984"><span className="persName">Mr. GOGGIN</span>—</div>
              <p className="p-in-sp">I don't intend to detain the Convention with any lengthy remarks upon this subject. It is one of those questions in regard to which, in every vote that has been taken upon it, I have taken the position which I intend to occupy now in the final vote upon it.</p>
              <p className="p-in-sp">As was very well said by my friend from Franklin [<span className="persName">Mr. EARLY</span>]
                , if this principle is right in itself, and an appeal is made now to do justice in reference to it and no more, I think it becomes this Convention to act upon it now. If it be right that this concession should be made by the East to the West, it should be made not in view of any particular action on the part of some members from the Northwest on the question of secession or any other
                question; not in view of the crisis which gentlemen say is upon us; but because it is a right which has been withheld from the West, to which the West is entitled to.
              </p>
              <p className="p-in-sp">I am reminded by the gentleman from Greenesville [<span className="persName">Mr. CHAMBLISS</span>] , in his remarks with reference to the relative value of slaves here and in the South, that one thing of which the least complaint in connection with this subject is, that a large portion of the capital of Western men is invested in unprofitable land which cannot be removed to the market of the South, as the slave is.</p>
              <p className="p-in-sp">
                Many men in the West are possessed of immense tracts of land of thirty or forty thousand acres, upon which a fertile or cultivated spot is to be found at very remote intervals, and their lands are subject to taxation year after year. If that be so, I should think the slaveholder, who has a profitable market at any time to which to send his slave, has no right to seek exemption from a
                charge to which the less fortunate man in the West who happens to invest his money in unprofitable land is made subject. I say it is but right, if that Western man's land, which he cannot make valuable for any purpose whatever, is to be taxed, that this species of property in which Eastern men have invested their capital and to more advantage than the Western man has invested his,
                should be subject to its due proportion of revenue for the support of the Government.
              </p>
              <p className="p-in-sp">
                But, sir, this argument applies more forcibly still, in view of the present state of the country, and the need which exists for the means to meet this terrible emergency which is upon us. We are now, sir, without the necessary means to carry on a war. If it should be waged for a period of two or five or ten years, as it may be, I ask if it is not right now that we should lay the
                foundation of a system of revenue <PageNumber num={556} /> from which we may derive the means to defend ourselves and to put the State in a proper condition of defence for all future time. We cannot possibly define the issue of this conflict which is now upon the country, and it therefore becomes us as a body assembled here to see after the security of
                our people, and provide for their defence in any emergency that may arise; and with that view, we should provide some basis of taxation commensurate with the needs which the impending conflict must create.
              </p>
              <p className="p-in-sp">I should be unwilling as a slaveholder to sit down and say that I have withheld from any portion of the people of this Commonwealth one of the means which was necessary to place us in a situation in which we could defend ourselves not only against our neighbors, but the whole world.</p>
              <p className="p-in-sp">I do not stand here to cast any imputation upon any man; but I stand here to say in behalf of one friend whom I had in this Convention-I refer to the gentleman from Kanawha [<span className="persName">Mr. SUMMERS</span>]
                -that he has not left this Convention for the purpose of avoiding any responsibility that might attach to him as a member of it. I know, from sources which it is not necessary to tell here, that circumstances beyond his control made it necessary for him to return to his home. Every member here knows the fact that he was a member of the Peace Conference at Washington long before this
                Convention met, and was, therefore, engaged in the discharge of duties connected with his State, some weeks in advance of any member of this body, save my friend from Charles City
                [ex-President TYLER] , who sits before me. I know that the gentleman from Kanawha [<span className="persName">Mr. SUMMERS</span>]
                , shrinks from no responsibility that belongs to him. I believe that every man within the sound of my voice, every man whose acquaintance at least I have made, and that comprises nearly the whole body, has endeavored to discharge his duty to his God and his country. If gentlemen have not seen important subjects here as I have seen them, it is not for me to reproach them. I do not
                intend to do so, and, I trust that that perfect freedom of opinion in which we delight to pride ourselves will be granted to all who may choose to differ from us.
              </p>
              <p className="p-in-sp">I shall vote against the amendment of the gentleman from Greenesville [<span className="persName">Mr. CHAmE</span>Etss] , because, while I consider that the gentleman has yielded something, and that the amount of taxation from this source may be equal to what the ad valorem principle may yield, yet I think that inasmuch as the argument of right applies to that principle, it is proper we should concede it.</p>
              <PageNumber num={557} />
              <p className="p-in-sp">
                The gentleman tells us that he has presented his amendment by way of a compromise. I thought if there was a member in this house who had a dislike for compromises, it was the gentleman from Greenesville. We have all heard a great deal about compromise; and have been told that every compromise that has ever been made has resulted in the oppression of one side-that is, the South. In
                the teeth of this, the gentleman tells us now that he is willing to enter into another compromise. I say now, that I came to the same conclusion to which I know the gentleman from Greenesville has long since come, that the time for compromises is past. Everything we do here for the interests of the State and of the South should be upon the principle of right and justice.
              </p>
              <p className="p-in-sp">Upon this ground, and for other reasons which I might, if I had time, elaborate, I shall vote against the amendment and for the ordinance as reported by the committee.</p>
              <div className="speaker" id="sp4985"><span className="persName">Mr. WILSON</span>—</div>
              <p className="p-in-sp">I demand the previous question.</p>
              <p>The demand was sustained, and the main question being put, was decided in the negative.</p>
              <p>The question then recurred on the adoption of the original ordinance.</p>
              <div className="speaker" id="sp4986"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">I call the previous question upon that.</p>
              <p>The call was sustained.</p>
              <div className="speaker" id="sp4987"><span className="persName">Mr. CARTER</span>, of Loudoun—</div>
              <p className="p-in-sp">I call for the yeas and nays.</p>
              <p>
                The call was sustained, and the question being taken resulted-yeas 66, nays 26, as follows :<span className="note" id="Note52"><span className="noteLabel">4</span>The Journal adds Sharp to affirmative votes listed here.</span>
              </p>
              <p>
                YEAS-Messrs. Ambler, Aston, A. M. Barbour, Jas. Barbour, Baylor, Borst, Boyd, Brent, Campbell, Caperton, Carter, Cecil, Chapman, Coffman, Conn, Deskins, Dorman, Early, Echols, Flournoy, French, Gillespie, Graham, Goggin, John Goode, Cyrus Hall, E. B. Hall, L. S. Hall, Harvie, Haymond, Holcombe, Holladay, Marmaduke Johnson, P. C. Johnston, Kent, Lawson, Lewis, McComas, C. K. Mallory,
                Marshall, Marye, Miller, Moffett, Montague, Morton, Moore, Nelson, Preston, Pugh, Randolph, Rives, Robert E. Scott, Sheffey, Sitlington, Spurlock, Staples, Strange, Sutherlin, Tayloe, R. H. Turner, F. P. Turner, Williams, Wilson, Woods, Wysor-66.
              </p>
              <p>NAYS-Messrs. John Janney [President] , Blow, Boisseau, Bouldin, Bruce, Chambliss, R. Y. Conrad, James H. Cox, Critcher, Fisher, Garland, Gravely, Gregory, Addison Hall, Isbell, Kilby, Leake, Macfarland, Neblett, Richardson, Seawell, Speed, Thornton, Tredway, Tyler, Wickham-26.</p>
              <p>So the ordinance was adopted.</p>
              <div className="speaker" id="sp4988"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair begs leave to announce the following Committee, under the resolution offered this morning by the gentleman from Louisa [<span className="persName">Mr. AMBLER</span>] on the subject of representation in the <PageNumber num={558} /> Congress of the Confederate States: Messrs. Wm. M. Ambler, of Louisa; Benjamin F. Wysor, of Pulaski; Angus R. Blakey, of Madison; Alpheus F. Haymond, of Marion; and Miers W. Fisher, of Northampton.</p>
              <div className="speaker" id="sp4989"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair will also announce the following Committee on enrolled ordinances, ordered to be appointed under a resolution offered this morning by the gentleman from Chesterfield [<span className="persName">Mr. COX</span>] : Messrs. James H. Cox, of Chesterfield; John A. Carter, of Loudoun; Manilius Chapman, of Giles; Harvey Deskins, of Floyd; and Peter B. Borst, of Page.</p>
              <div className="speaker" id="sp4990"><span className="persName">Mr. HAYMOND</span>—</div>
              <p className="p-in-sp">I beg leave to offer the following resolution:</p>
              <p className="p-in-sp">"Resolved, That the Committee on Taxation be, and they are hereby instructed to report a schedule and ordinance submitting to the voters of this Commonwealth, for their adoption or rejection, the amendments of the 22d and 23d sections of Article IV of the Constitution of this State, this day adopted by the Convention."</p>
              <p>The resolution was adopted.</p>
              <div className="speaker" id="sp4991"><span className="persName">Mr. GOODE</span>, of Bedford—</div>
              <p className="p-in-sp">I beg leave to offer the following resolution:</p>
              <p className="p-in-sp">"Resolved, That the President of the Confederate States and the constituted authorities of the Confederacy be, and they are hereby cordially and respectfully invited, whenever, in their opinion, the public interest or convenience may require it, to make the city of Richmond, or some other place in this State, the seat of the Government of the Confederacy."</p>
              <div className="speaker" id="sp4992"><span className="persName">Mr. HALL</span>, of Marion—</div>
              <p className="p-in-sp">I move to lay that resolution on the table.</p>
              <div className="speaker" id="sp4993"><span className="persName">Mr. HOLLADAY</span>, of Norfolk County—</div>
              <p className="p-in-sp">I desire to say a few words upon that resolution.</p>
              <p className="p-in-sp">
                We have just adopted or ratified the Convention formed between the Commissioners and the Vice President of the Confederate States, and passed an ordinance making Virginia a member of that Confederacy. These acts are followed up immediately by the presentation of the resolution just reported. Now, sir, I do hope, and I mean to make the remark in all due respect, for the honor of
                Virginia, this Convention will vote down that resolution.
              </p>
              <p className="p-in-sp">
                It seems to me that we no sooner enter into that Southern Confederacy, than we seek to reap pecuniary advantages by the location of the capital within the State of Virginia. To do this, as the first act of this Convention after it has placed the State within the Southern Confederacy, would seem to indicate a purpose to clutch the spoils,
                <PageNumber num={559} /> and thus inaugurate the system which rendered Washington politics so infamous in the eyes of the world.
              </p>
              <p className="p-in-sp">I do say that we are placed in a humiliating attitude here, by initiating this struggle for the spoils.</p>
              <div className="speaker" id="sp4994"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">
                This resolution is similar to a resolution that was passed by the Convention of Georgia. I am perfectly satisfied that this invitation is expected and desired by the Commissioner from the Confederate States, and if the gentleman will dismiss for a moment from his mind the idea of "spoils," and look to what is the condition of the country, he will see the propriety of adopting this
                resolution.
              </p>
              <p className="p-in-sp">
                We are threatened with a formidable invasion, and it is appropriate, nay, necessary that we should have the Executive head of the Confederacy as near the scene of action as possible. Our treasury is empty. We need about ten millions of dollars, and they have that, and if that be spoils, trusting it will be, I take it in no wise discreditable to us to engage in it.
              </p>
              <div className="speaker" id="sp4995"><span className="persName">Mr. BOULDIN</span>, of Charlotte—</div>
              <p className="p-in-sp">I understand the Commissioner from the Confederate States has intimated not only a desire to have the Capital removed here for the present, but that it may become necessary to have it removed here.</p>
              <p className="p-in-sp">I will offer this as a preamble to the resolution which will answer as a full justification for this course.</p>
              <p className="p-in-sp">Whereas, it has been intimated to this Convention by the Commissioner of the Confederate States, that it may become necessary to remove the seat of government to the State of Virginia, therefore-</p>
              <p className="p-in-sp">Resolved, &amp;c.</p>
              <p className="p-in-sp">This is designed to show that we are not asking for any advantage, but meeting an overture that was made by the Commissioner.</p>
              <div className="speaker" id="sp4996"><span className="persName">Mr. GOODE</span>, of Bedford—</div>
              <p className="p-in-sp">If there has been any such intimation, I will accept the amendment.</p>
              <div className="speaker" id="sp4997"><span className="persName">Mr. BOULDIN</span>—</div>
              <p className="p-in-sp">I withdraw the amendment.</p>
              <div className="speaker" id="sp4998"><span className="persName">Mr. WICKHAM</span>, of Henrico—</div>
              <p className="p-in-sp">I desire to know whether it is proper to invite the President to make this the Seat of Government before it is ascertained that the people will ratify the ordinance of secession?</p>
              <div className="speaker" id="sp4999"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">This is merely for a temporary purpose.</p>
              <p className="p-in-sp">I call the previous question.</p>
              <p>The call was sustained, and the main question ordered to be put.</p>
              <div className="speaker" id="sp5000"><span className="persName">Mr. CONRAD</span>, of Frederick—</div>
              <p className="p-in-sp">I call the yeas and nays upon that resolution.</p>
              <p>The call was sustained, and the vote being taken, resulted-yeas 63, nays 13, as follows:</p>
              <PageNumber num={560} />
              <p>
                YEAS-Messrs. Ambler, Aston, Baylor, Blow, Boisseau, Borst, Bouldin, Boyd, Campbell, Caperton, Chambliss, Chapman, Coffman, Conn, Jas. H. Cox, Deskins, Dorman, Echols, Fisher, Flournoy, French, Garland, Graham, Gregory, Goggin, John Goode, Jr., Addison Hall, L. S. Hall, Harvie, Holcombe, Isbell, Marmaduke Johnson, Peter C. Johnston, Kilby, Leake, Macfarland, Charles K. Mallory, Marye,
                Miller, Moffett, Montague, Morton, Moore, Neblett, Preston, Randolph, Richardson, Seawell, Sheffey, Sitlington, Speed, Staples, Strange, Sutherlin, Tayloe, Thornton, R. H. Turner, Franklin P. Turner, Tyler, Williams, Wilson, Woods, Wysor-63.
              </p>
              <p>NAYS-Messrs. John Janney [President] , Bruce, Carter, R. Y. Conrad, Early, Gillespie, Gravely, Ephraim B. Hall, Lewis, Pugh, Sharp, A. H. H. Stuart, Wickham-13.</p>
              <p>No quorum voting.</p>
              <p><span className="persName">Mr. STUART</span>, of Augusta, paired off with <span className="persName">Mr. GOODE</span>, of Mecklenburg; but in order to assist in making a quorum, he voted in the negative.</p>
              <div className="speaker" id="sp5001"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">I move a call of the House.</p>
              <div className="speaker" id="sp5002"><span className="persName">Mr. ECHOLS</span>—</div>
              <p className="p-in-sp">I move we adjourn.</p>
              <div className="speaker" id="sp5003"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">I call the yeas and nays upon that motion.</p>
              <p>The call was sustained, and the vote being taken on the motion to adjourn, was decided in the affirmative.</p>
              <p>The Convention accordingly adjourned.</p>
            </div>
          </div>
          <div className="day" id="vsc1965.v4.2.12">
            <PageNumber num={561} />
            <h2><span className="headingNumber">1.12. </span><span className="head">TWELFTH DAY</span></h2>
            <div className="dateline">Saturday, <span className="date">April 27</span></div>
            <p>The Convention met at 12 o'clock.</p>
            <p>Prayer by the Rev. Dr. Burrows, of the Baptist Church.</p>
            <p>The Secretary called over the list of committees, when</p>
            <p><span className="persName">Mr. MACFARLAND</span>, from the Finance Committee, reported the following ordinance :</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>"AN ORDINANCE to provide a Board to whom shall be referred all claims for expenditures arising from the organization, equipment, and support of the land and naval forces called or to be called out for the defence of the Commonwealth under the present emergency.</p>
                <p>
                  "Be it ordained by the Convention of the Commonwealth of Virginia, That George W. Munford, John R. Tucker, and Jonathan M. Bennett, any two of whom may act, shall be a Board of Commissioners with authority to audit and settle, upon principles of law and justice, all accounts for expenses incurred in the assembling, arming, equipping, and maintaining troops, and for transporting
                  munitions of war and everything connected therewith; for officering, equipping, and maintaining the navy of the State, and for the pay of officers, seamen, and marines, and employees at navy or dock yards, including transportation of ordnance, ammunition and stores of every description, the purchase of vessels, and for damages arising for seizure and detention of vessels, or from
                  injury or use of cargo or property of any description either by the land or naval forces, and for all other incidental expenses incurred in the defence of the State, including expenses incident to the guarding, supporting, and transportation of prisoners or others. And for this purpose the said commissioners are authorized to administer oaths or to require affidavits to be made
                  where necessary. The said commissioners shall be authorized to place in the hands of proper disbursing officers, under regulations to be prescribed by them for the security of the money to be disbursed, such sums of money as may be required to give efficiency to the raising and subsistence of the army and navy ordered into service from time to time under competent authority. All
                  accounts shall be sustained by proper vouchers, and when allowed shall be kept in a general account under proper heads for future settlement. And when said accounts are so sustained and allowed, or when advances are made as aforesaid, the said commissioners are hereby authorized to direct the <PageNumber num={562} /> Auditor of Public Accounts to
                  issue his warrants for the several amounts thereof to be paid out of any money in the treasury not otherwise appropriated.
                </p>
                <p>"The said accounts so allowed and paid shall be certified by said commissioners quarterly, for payment, to the government of the Confederate States, to be paid according to the convention between this State and the said Confederate States, adopted on the 25th April, 1861; and upon payment thereof the sum so received shall be paid into the public treasury of this Commonwealth.</p>
                <p>"The said commissioners shall have power to appoint a clerk or clerks.</p>
                <p>"The commissioners hereby appointed for their services shall be allowed each the sum of three dollars per day of actual service, and they may allow to their clerk or clerks such sum as may be reasonable."</p>
              </div>
            </div>
            <div className="speaker" id="sp5004"><span className="persName">Mr. MACFARLAND</span>—</div>
            <p className="p-in-sp">I hope in a very short time to report other ordinances, which are now being copied. I will add further that it is important that the report made by the Finance Committee on yesterday should be taken up. I refer to the ordinance providing for deficiencies in the civil and military contingent fund.</p>
            <div className="speaker" id="sp5005"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">It is not in order to call up ordinances now on the table until the committees have reported.</p>
            <div className="speaker" id="sp5006"><span className="persName">Mr. MACFARLAND</span>—</div>
            <p className="p-in-sp">
              I trust the Convention will act upon the ordinance just reported. It is a very important ordinance, and it should be adopted without delay. If gentlemen want information in regard to it, I am prepared to furnish it. It will become necessary to advance money with a view to purchase military supplies. The ordinance provides as well for the payment of actual accounts as for the advance of
              money to be appropriated for the purchase of military stores, &amp;c. It will be necessary to send commercial agents out of the State with a view to the purchase of these stores, and they must have money, or be foiled in their mission. They must be responsible parties, so that no loss will result to the State.
            </p>
            <p className="p-in-sp">I move that the ordinance be adopted.</p>
            <p className="p-in-sp">
              I discover that there is some impression that it may not be in the power of the Commissioners to give the requisite time to the discharge of these duties. I have had a conversation with Colonel Munford and Mr. Bennett; Mr. Tucker I have not seen; and I will say, for the information of gentlemen, that Colonel Munford and Mr. Bennett think they will have the power to attend to these
              duties.
            </p>
            <p>The question was then put on the adoption of the ordinance just reported, and it was decided in the affirmative.</p>
            <PageNumber num={563} />
            <div className="speaker" id="sp5007"><span className="persName">Mr. MACFARLAND</span>—</div>
            <p className="p-in-sp">I suppose it falls within the spirit, if not the letter of the ordinance under which the Secretary is calling over the committees, to call up the report of the Finance Committee made yesterday.</p>
            <div className="speaker" id="sp5008"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">It is not in order to call it up now.</p>
            <div className="speaker" id="sp5009"><span className="persName">Mr. SCOTT</span>, of Powhatan—</div>
            <p className="p-in-sp">I beg leave to submit the following report from the Committee on Military Affairs:</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>AN ORDINANCE to establish a Flag for this Commonwealth.</p>
                <p>
                  Be it ordained by the Convention of the Commonwealth of Virginia, That the flag of this Commonwealth shall hereafter be made of bunting, which shall be a deep blue field, with a circle of white in the centre, upon which shall be painted, or embroidered, to show on both sides alike, the coat of arms of the State, as described by the Convention of 1776, for one side of the Seal of
                  the State, to wit: Virtus, the genius of the Commonwealth, dressed like an Amazon, resting on a spear with one hand, and holding a sword in the other, and treading on Tyranny, represented by a man prostrate, a crown fallen from his head, a broken chain in his left hand, and a scourge in his right. In the Exergon, the word "Virginia" over the head of Virtus, and underneath, the
                  words "Sic Semper Tyrannis."
                </p>
                <p>This flag shall be known and respected as the flag of Virginia.</p>
                <p>The Governor shall regulate the size and dimensions of the flag proper for forts, arsenals and public buildings, for ships of war and merchant marine, for troops in the field, respectively, and for any other purpose, according to his discretion; which regulations shall be published and proclaimed by him as occasion may require.</p>
                <p>This ordinance shall take effect from its passage.</p>
              </div>
            </div>
            <div className="speaker" id="sp5010"><span className="persName">Mr. RANDOLPH</span>—</div>
            <p className="p-in-sp">This ordinance merely legalizes the present flag. I move it be adopted.</p>
            <div className="speaker" id="sp5011"><span className="persName">Mr. TYLER</span>—</div>
            <p className="p-in-sp">
              I have only to inform the committee that it falls within my information that our respected and honored colleague, Governor Wise, who is detained at home by the illness of his wife, has long had the question of a flag for Virginia under consideration. He has had a model which he submitted to myself and the Secretary of State for Virginia. If there was no immediate necessity to adopt
              that ordinance, I would prefer that the subject should lay on the table until Gov. Wise would be able to attend. I make a motion to that effect.
            </p>
            <p>The motion was agreed to.</p>
            <div className="speaker" id="sp5012"><span className="persName">Mr. SCOTT</span>, of Powhatan—</div>
            <p className="p-in-sp">I am instructed by the Committee on Military Affairs to submit the following ordinance :</p>
            <PageNumber num={564} />
            <div className="floatingText">
              <div className="floatingText_body">
                <p>AN ORDINANCE concerning the Commissary Department:</p>
                <p>
                  Be it ordained by the Convention as follows, to wit: The Commissary general, assistant commissary general, commissaries and assistant commissaries, before entering on the duties of their offices, shall give bonds, with approved security, in such penalties as the Governor may direct, and as many acting assistant commissaries may be detailed from the subalterns of the line as may be
                  necessary, who shall likewise give bond and security as above directed. The bonds hereby required to be given shall be made payable to the State of Virginia, and shall be filed with the First Auditor. The commissary general and his assistants shall perform such duties in purchasing and issuing of rations to the army of the State of Virginia as the Governor may direct.
                </p>
                <p>Supplies for the army, unless in particular and urgent cases the Governor should otherwise direct, shall be purchased by contract, to be made by the commissary general, on public notice, to be delivered on inspection in the bulk, and at such places as shall be stipulated; which contract shall be made under such regulations as the Governor may direct.</p>
                <p>This ordinance shall be in force from the time of its adoption, subject to amendment, modification or repeal by this Convention, or by the General Assembly of Virginia.</p>
              </div>
            </div>
            <div className="speaker" id="sp5013"><span className="persName">Mr. SHEFFEY</span>—</div>
            <p className="p-in-sp">I suggest to the Chairman that he had better lay that ordinance on the table.</p>
            <div className="speaker" id="sp5014"><span className="persName">Mr. WICKHAM</span>—</div>
            <p className="p-in-sp">I ask the Chairman whether it is his purpose to establish an additional Commissary Department to the one now existing?</p>
            <div className="speaker" id="sp5015"><span className="persName">Mr. SCOTT</span>—</div>
            <p className="p-in-sp">No, sir.</p>
            <div className="speaker" id="sp5016"><span className="persName">Mr. BRANCH</span>—</div>
            <p className="p-in-sp">I beg leave to suggest to the Chairman that I think it would be better to strike out the words, "may be detailed from the subalterns of the line."</p>
            <p className="p-in-sp">I say that gentlemen in the military line are not the most competent to trade for the Commissary.</p>
            <div className="speaker" id="sp5017"><span className="persName">Mr. SCOTT</span>, of Powhatan—</div>
            <p className="p-in-sp">I will say, with regard to that, almost all the ordinances have been taken from the laws of the United States, and made to conform as nearly as possible to our condition.</p>
            <div className="speaker" id="sp5018"><span className="persName">Mr. EARLY</span>—</div>
            <p className="p-in-sp">
              I was not present when that ordinance was agreed upon. I think it requires a little modification. The different branches were given to different members of the committee, and I was absent consulting with the Commander-in-Chief. I have no doubt but that <PageNumber num={565} /> this ordinance, with a very slight modification, will accomplish the object of
              the committee.
            </p>
            <p className="p-in-sp">
              An ordinance has been passed providing for the organization of a Commissary Department, to consist of a commissary general, two commissaries and four assistant commissaries. These will constitute the Department for the purpose of providing for the purchase of provisions. But it is necessary to have for the troops in the field an assistant commissary, whose duty it should be to issue
              the rations, and he ought to be an officer. That is the object of the ordinance, and it only requires a slight modification to effect the object contemplated by the committee. These assistants named in the ordinance are intended to issue the rations to the men in the field, and they ought to be military men.
            </p>
            <div className="speaker" id="sp5019"><span className="persName">Mr. BRANCH</span>—</div>
            <p className="p-in-sp">
              I beg to say that striking out the words from the ordinance which I have indicated does not prevent the appointment of officers from the Federal ranks. I am sure I am right in that. Give the Governor the appointing power, and he will procure competent men to discharge the duties. There are plenty of men in civil life who prove more efficient in that department than any military
              officer.
            </p>
            <p>The question was then taken on <span className="persName">Mr. BRANCH</span>'S amendment, and it was decided in the affirmative.</p>
            <div className="speaker" id="sp5020"><span className="persName">Mr. EARLY</span>—</div>
            <p className="p-in-sp">I will ask to suggest an amendment.</p>
            <div className="speaker" id="sp5021"><span className="persName">Mr. HAYMOND</span>—</div>
            <p className="p-in-sp">The ordinance organizing a provisional army has not been adopted by this Convention. Is it proper, then, to adopt this ordinance until the ordinance organizing the army in view of which this is reported, shall be acted upon?</p>
            <div className="speaker" id="sp5022"><span className="persName">Mr. EARLY</span>—</div>
            <p className="p-in-sp">I propose to modify the ordinance.</p>
            <div className="speaker" id="sp5023"><span className="persName">Mr. HAYMOND</span>—</div>
            <p className="p-in-sp">
              I will just remark here, that I have no objection to any amendment that may be adopted. The gentleman did not understand the point I made. On yesterday morning an ordinance providing for the organization of a provisional army, reported from the Committee on Military Affairs, was laid upon the table. It seems to me that the proper course would be to lay this subject on the table until
              the ordinance reported yesterday shall be adopted.
            </p>
            <p className="p-in-sp">I move to lay that ordinance on the table and that it be printed.</p>
            <div className="speaker" id="sp5024"><span className="persName">Mr. AMBLER</span>—</div>
            <p className="p-in-sp">
              There was an express understanding on yesterday that such arrangements were to be made in regard to these various offices as would guard against such errors as were heretofore committed. I understand that the ordinance for the organization of a <PageNumber num={566} /> provisional army should first be adopted, before any action should be had in this
              matter now under consideration.
            </p>
            <p className="p-in-sp">Let us have a fair understanding, and avoid the errors which we have been committing. I trust that the motion to lay on the table and print will prevail.</p>
            <p>The motion was agreed to.</p>
            <div className="speaker" id="sp5025"><span className="persName">Mr. RANDOLPH</span>—</div>
            <p className="p-in-sp">If all these ordinances are to be laid on the table and printed, you can not get them here for two weeks. The ordinance laid on the table yesterday and ordered to be printed, will make forty or fifty pages, and will necessarily require considerable time to be printed. If this practice is to be continued you might as well give up the provisional army at once.</p>
            <div className="speaker" id="sp5026"><span className="persName">Mr. AMBLER</span>—</div>
            <p className="p-in-sp">
              A remark was made to the members of the Military Committee, and not one seemed to heed it. If we could understand the order of business which the committee seem to have marked out for themselves, we could get along without difficulty. The object of those on this side of the House who have gone for laying this matter on the table and printing it, is that the understanding which we
              entered into on yesterday should be carried out. Now, if the suggestion of the gentleman from Marion
              [<span className="persName">Mr. HAYMOND</span>]
              , had been attended to by the committee, we would not have this laid on the table. But you did not heed the remarks, and we, therefore, had to take this action. The understanding was that this ordinance providing for the organization of a provisional army should be printed, and time afforded for its proper consideration, in order that the errors heretofore committed should not be
              committed again.
            </p>
            <div className="speaker" id="sp5027"><span className="persName">Mr. RANDOLPH</span>—</div>
            <p className="p-in-sp">I do not know of any errors that have been committed.</p>
            <p className="p-in-sp">I would say that these ordinances be laid upon the table until the ordinance organizing the army is first adopted. That is the regular order.</p>
            <div className="speaker" id="sp5028"><span className="persName">Mr. AMBLER</span>—</div>
            <p className="p-in-sp">Let me repeat that we voted to lay on the table and print, because the suggestion on this side was not heeded.</p>
            <div className="speaker" id="sp5029"><span className="persName">Mr. RANDOLPH</span>—</div>
            <p className="p-in-sp">I do not wish to interfere with anything the gentleman intends to do. I say the ordinance reported yesterday should be first adopted before any action is had upon this; but I do think it is waste of time and money to print these ordinances.</p>
            <p className="p-in-sp">If the gentleman would call up the ordinances reported yesterday and have them acted upon, he would be better promoting the dispatch of business than by referring to what he believes to be errors in our past proceedings.</p>
            <PageNumber num={567} />
            <div className="speaker" id="sp5030"><span className="persName">Mr. AMBLER</span>—</div>
            <p className="p-in-sp">I move to reconsider the vote by which this ordinance was laid upon the table and ordered to be printed.</p>
            <p>The motion was agreed to.</p>
            <div className="speaker" id="sp5031"><span className="persName">Mr. WICKHAM</span>—</div>
            <p className="p-in-sp">As I understand, this ordinance has no connection with the ordinance reported on yesterday.</p>
            <div className="speaker" id="sp5032"><span className="persName">Mr. RANDOLPH</span>—</div>
            <p className="p-in-sp">Not at all. This ordinance merely proposes to carry out the details of the ordinance reported on yesterday.</p>
            <div className="speaker" id="sp5033"><span className="persName">Mr. AMBLER</span>—</div>
            <p className="p-in-sp">I now move to lay the ordinance on the table.</p>
            <p>The motion was agreed to.</p>
            <div className="speaker" id="sp5034"><span className="persName">Mr. SCOTT</span>, of Powhatan—</div>
            <p className="p-in-sp">I am instructed by the Military Committee to report the following ordinance :</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>AN ORDINANCE Concerning a Quartermaster's Department.</p>
                <p>
                  Be it ordained by the Convention as follows, to wit: That in addition to their duties in the field, it shall be the duty of the quartermaster general, his deputies and assistant deputies, when thereto directed by the Governor, to purchase military stores, camp equipage, and other articles requisite for the troops and generally to procure and provide means of transport for the army,
                  its stores, artillery, and camp equipage; that the quartermaster general shall account as often as may be required, and at least once in three months, with the First Auditor, in such manner as shall be prescribed, for all property which may pass through his hands or the hands of the subordinate officers of his department, or that may be in his or their care or possession, and for
                  all moneys which he or they expend in discharging their respective duties; that he shall be responsible for the regularity and correctness of all returns in his department, and that he, his deputies, and assistant deputies, before they enter on the execution of their respective offices, shall severally take an oath faithfully to perform the duties thereof, the penalty of said bonds
                  to be prescribed by the Governor, and said bonds to be filed with the First Auditor; that the quartermaster general shall be responsible for the regularity and correctness of all returns in his department.
                </p>
                <p>
                  Neither the quartermaster general, the commissary general, nor any or either of their deputies or assistant deputies shall be concerned, directly or indirectly, in the purchase or sale, for commercial purposes, of any article intended for, making a part of, or appertaining to their respective departments except for and on account of the State of Virginia; nor shall they or either
                  of them take or supply to his or their use any gain or emolument for negotiating or transacting any <PageNumber num={568} /> business in their respective departments other than what is or may be allowed by law.
                </p>
                <p>That the quartermaster general be, and he is hereby, empowered to appoint one principal barrack master, and as many deputy barrack masters as may, from time to time, be necessary, not exceeding one to each separate barrack or cantonment.</p>
                <p>
                  That the Governor of the State of Virginia be, and he is hereby, empowered, as he may deem it expedient, either to appoint, for the time being, a special commissary or commissaries for the purpose of supply, by purchase or contract, and of issuing, or to authorize any officer or officers in the quartermaster general's department to supply and issue as aforesaid, the whole or any
                  part of the subsistence of the army in all cases where, either from the want of contractors or from any deficiency on their part, or from any other contingency, such measure may be proper and necessary in order to insure the subsistence of the army or of any part thereof; and such special commissaries shall each, whilst employed, be entitled to the pay and emoluments of a deputy
                  quartermaster general.
                </p>
                <p>The quartermaster general shall be authorized, with the approbation and under the direction of the Governor, to appoint as many forage, wagon, and barrack masters, and to employ as many artificers, mechanics and laborers as the public may require.</p>
                <p>
                  All officers of the navy, commissary and quartermaster's department shall, previous to their entering on the duties of their respective offices, give bonds with sufficient security to be approved of by the First Auditor conditioned for the faithful expenditure of all public moneys, and accounting for all public property which may come to their hands, respectively, and the
                  quartermaster general and commissary general shall neither be liable for any money or property, that may come into the hands of the subordinate officers of his departments who are required by this ordinance to give bond. And all pay masters, commissaries and store-keepers shall be subject to the rules and articles of war in the same manner as commissioned officers.
                </p>
                <p>
                  It shall be the duty of the quartermaster's department, in addition to its duties above prescribed, to receive from the purchasing department, and distribute to the army, all clothing and camp and garrison equipage required for the use of the troops, and that it shall be the duty of the quartermaster general under the direction of the Governor to prescribe and enforce under the
                  provisions of this ordinance, a system of accountability for all clothing and equipage issued to the army.
                </p>
                <PageNumber num={569} />
                <p>
                  Every captain or commander of a company detachment or recruiting station, or other officer who shall have received clothing or camp equipage for the use of his command or for issue to the troops, shall render to the quartermaster general at the expiration of each regular quarter of the year, quarterly returns of such supplies according to the forms which may be prescribed,
                  accompanied by the requisite vouchers for any issue that shall have been made, which returns and vouchers after due examination by the quartermaster general, shall be transmitted for settlement to the First Auditor. It shall be the duty of all officers charged with the issue of clothing or other supplies, carefully to preserve the same from waste or damage; and in case of
                  deficiency in final settlement of any article of supplies, the value thereof shall be charged against the delinquent and deducted from his monthly pay, unless he shall show to the satisfaction of the First Auditor, by one or more depositions setting forth the circumstances of the case, that the said deficiency was occasioned by unavoidable accident, or was lost in actual service
                  without any fault on his part; and in case of damage he shall also be subject to charge for the damage actually sustained, unless he shall show, in like manner, to the satisfaction of the First Auditor, that due care and attention were given to the preservation of said supplies, and that the damage did not result from neglect.
                </p>
                <p>
                  That during the absence of the quartermaster general, or the chief of any other bureau or department of the military department, the Governor be authorized to empower some officer of the department or corps whose chief is absent, to take charge thereof, and to perform the duties of quartermaster general or chief of the department or corps, as the case may be, during such absence:
                  Provided, That no additional compensation be allowed therefor.
                </p>
                <p>
                  There shall be added to the quartermaster's department not exceeding three military storekeepers, and such storekeepers shall be appointed in the same manner as the quartermaster general, and shall, before entering upon the performance of their duties, give bond with sufficient security, to be approved by the Governor, in such penalty as the Governor may direct, with condition for
                  the faithful performance of their duties, said bonds to be filed with the First Auditor.
                </p>
                <p>All bonds required to be given by this ordinance, shall be made payable to the State of Virginia.</p>
                <p>This ordinance shall be in force from its adoption, subject to amendment, modification, or repeal by this Convention or by the General Assembly of Virginia.</p>
              </div>
            </div>
            <div className="speaker" id="sp5035"><span className="persName">Mr. SCOTT</span>—</div>
            <p className="p-in-sp">I move to lay that ordinance on the table.</p>
            <PageNumber num={570} />
            <p>The motion was agreed to.</p>
            <div className="speaker" id="sp5036"><span className="persName">Mr. SCOTT</span>—</div>
            <p className="p-in-sp">I beg leave to submit from the Committee on Military Affairs "an ordinance prescribing the duties of the Paymaster General."</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>
                  "The Paymaster General shall receive from the Treasury of the State all moneys entrusted to him to pay the troops or purchase subsistence or forage. He shall receive the pay abstracts of the paymasters of the several regiments or corps, and shall compare them with the rations or muster rolls accompanying the said abstracts. He shall certify to the commanding officer the sums due to
                  the respective corps, who shall thereupon issue his warrant on the said Paymaster General for payment accordingly. Copies of all reports to the commanding officer, and the warrants thereon shall be duly transmitted to the office of the First Auditor, to be examined and finally adjusted at the Treasury.
                </p>
                <p>"This ordinance shall be in force from its adoption, and be subject to amendment, modification or repeal by this Convention or by the General Assembly."</p>
              </div>
            </div>
            <div className="speaker" id="sp5037"><span className="persName">Mr. SCOTT</span>—</div>
            <p className="p-in-sp">I move to lay that ordinance upon the table.</p>
            <p>The motion was agreed to.</p>
            <div className="speaker" id="sp5038"><span className="persName">Mr. SCOTT</span>—</div>
            <p className="p-in-sp">I beg leave to report from the same committee "an ordinance concerning promotions and brevet rank."</p>
            <div className="speaker" id="sp5039"><span className="persName">Mr. SCOTT</span>—</div>
            <p className="p-in-sp">I move to lay that ordinance on the table.</p>
            <p>The motion was agreed to.</p>
            <div className="speaker" id="sp5040"><span className="persName">Mr. SCOTT</span>—</div>
            <p className="p-in-sp">I beg leave to report the following ordinance from the same committee:</p>
            <p>"An ordinance concerning a reserved list."</p>
            <div className="speaker" id="sp5041"><span className="persName">Mr. SCOTT</span>—</div>
            <p className="p-in-sp">I move to lay that ordinance on the table.</p>
            <p>The motion was agreed to.</p>
            <div className="speaker" id="sp5042"><span className="persName">Mr. SCOTT</span>—</div>
            <p className="p-in-sp">I now present from the same committee-</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>AN ORDINANCE for the regulation of the Medical Department of the Provisional Army.</p>
                <p>
                  "Be it ordained, That regulations prescribing the duties of the Surgeon General, the Surgeons, Assistant Surgeons, and all employees in the Medical Department, and for the efficient organization and government of said department, including the mode of making purchases and providing stores, shall be adopted by the Governor and his Advisory Council, and the Governor, by and with the
                  advice and consent of the said Council, may appoint as many additional assistant surgeons in the said department as may be necessary; and whenever <PageNumber num={571} /> it is proper physicians may be temporarily employed under such rules as may be prescribed by the Governor and his Advisory Council."
                </p>
              </div>
            </div>
            <div className="speaker" id="sp5043"><span className="persName">Mr. SCOTT</span>—</div>
            <p className="p-in-sp">I move to lay that ordinance on the table.</p>
            <p>The motion was agreed to.</p>
            <div className="speaker" id="sp5044"><span className="persName">Mr. SCOTT</span>—</div>
            <p className="p-in-sp">I beg leave to submit from the same committee-</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>AN ORDINANCE providing Chaplains for the Provisional Army.</p>
                <p>"Be it ordained, That the Governor shall appoint one Chaplain for each brigade, who shall be entitled to the same pay and emoluments as a Major of Infantry.</p>
                <p>"This ordinance shall be in force from the time of its adoption, and be subject to amendment, modification or repeal by this Convention or by the General Assembly."</p>
              </div>
            </div>
            <div className="speaker" id="sp5045"><span className="persName">Mr. SCOTT</span>—</div>
            <p className="p-in-sp">I move to lay that ordinance on the table.</p>
            <p>The motion was agreed to.</p>
            <div className="speaker" id="sp5046"><span className="persName">Mr. SCOTT</span>—</div>
            <p className="p-in-sp">The same committee has instructed me to report the following:</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>AN ORDINANCE establishing the Navy of Virginia.</p>
                <p>
                  Be it ordained, That the navy of Virginia shall consist of two thousand seamen and marines, in such proportions as the Governor shall deem expedient, and of such officers as have availed themselves, or may hereafter avail themselves, of the invitation of this Commonwealth to worthy and efficient Virginians and residents of Virginia in the navy of the United States to retire
                  therefrom and to enter the service of the State. The rank of such officers shall not be reversed, and they shall have at least a rank equal to that held in the United States navy.
                </p>
                <p>2. The organization of the navy shall be prescribed by the Governor, and the pay, rations, and allowances shall be the same with those of the United States navy at this present time.</p>
                <p>3. Virginians and residents of Virginia on the reserved list of the United States navy, who resigned and desire to enter the service of the State, may be provided for by the Governor by allowing them the same pay they received in the United States navy, and requiring from them such duties as they are competent to discharge.</p>
                <p>4. The rules for the government of the United States navy at this present time shall be revised by a board of officers ordered by the Governor, and made applicable to the navy of Virginia, and shall then be promulgated as the rules governing the said navy.</p>
                <p>5. The terms of enlistment in the navy shall be three years for</p>
                <PageNumber num={572} />
                <p>seamen and five for the marines, and boys not under thirteen may be enlisted with the consent of their parents or guardians. Proper regulations for enlistment shall be prescribed by the Governor.</p>
                <p>6. A medical department shall be organized by the Governor, but no person shall be appointed thereto who has not been a Surgeon or Assistant Surgeon in the navy of the United States, or been examined and approved by a Board of Surgeons designed for the purpose.</p>
              </div>
            </div>
            <div className="speaker" id="sp5047"><span className="persName">Mr. SCOTT</span>—</div>
            <p className="p-in-sp">I move that this ordinance be laid upon the table.</p>
            <p>The motion was agreed to.</p>
            <div className="speaker" id="sp5048"><span className="persName">Mr. SCOTT</span>—</div>
            <p className="p-in-sp">I beg leave to report the following ordinance from the Military Committee, which I would ask the Convention to adopt. It is-</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>AN ORDINANCE in respect to Confirmation by the Convention.</p>
                <p>
                  Be it ordained, That no appointment of officers of volunteers above the rank of Lieut. Colonel shall be valid until confirmed by the Convention, unless made during the recess of the Convention, and in such case, the appointment, unless subsequently confirmed, shall be deemed vacated on the adjournment or recess of the Convention. And all such appointments heretofore made shall be
                  sent into the Convention for confirmation or rejection.
                </p>
              </div>
            </div>
            <div className="speaker" id="sp5049"><span className="persName">Mr. SCOTT</span>—</div>
            <p className="p-in-sp">I move that this ordinance be adopted.</p>
            <p>The motion was agreed to.</p>
            <div className="speaker" id="sp5050"><span className="persName">Mr. MORTON</span>—</div>
            <p className="p-in-sp">I move that that ordinance be sent immediately to the Governor.</p>
            <p>The motion was agreed to.</p>
            <div className="speaker" id="sp5051"><span className="persName">Mr. TYLER</span>—</div>
            <p className="p-in-sp">I move that the Committee appointed to confer with the Commissioner from the Confederate States, be discharged.</p>
            <p>The motion was agreed to.</p>
            <div className="speaker" id="sp5052"><span className="persName">Mr. JOHNSON</span>—</div>
            <p className="p-in-sp">I move that the Committee on the removal of the machinery from Harper's Ferry be also discharged.</p>
            <p>Agreed to.</p>
            <div className="speaker" id="sp5053"><span className="persName">Mr. FISHER</span>, of Northampton—</div>
            <p className="p-in-sp">I beg leave to submit the following report from the special Committee on the duties of Postmasters and officers of the Revenue service:</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>
                  "The Committee who were charged with the duty of inquiring into the expediency of reporting an ordinance regulating the duties of Postmasters and officers in the service of the United States, and to whom was also referred the message of the Governor, with the accompanying letters of Tazewell Taylor, John S. Parker, collector
                  <PageNumber num={573} /> of the customs of the district of Cherrystone, and of Samuel V. Watkins, surveyor of the ports of Richmond and Petersburg, beg leave to report:
                </p>
                <p>
                  That they would recommend that the Governor of the Commonwealth be requested to instruct the several collectors of the customs, surveyors of the different ports of entry, and the Postmasters in this Commonwealth, to retain and exercise the functions of their respective offices; that the collectors of the customs of the several ports collect the duties on foreign importations under
                  the existing laws of the United States; that they enrol and license vessels under the said laws as heretofore, and that they account for and pay into the public treasury of this State all public monies collected by them, less their usual salaries and commissions to which they may be entitled under the laws of the United States. And they recommend that similar instructions, so far
                  as they may be applicable, be given to the Postmasters of this State, except that they shall be instructed to pay the different contractors for carrying the public mails the same sums as they have been heretofore in the habit of paying under orders of the Postmaster General of the United States, and shall be required to pay into the public treasury of this State only the surplus
                  monies which may remain in their hands after making such payments. And they recommend that the said collectors of customs and Postmasters shall be required to make reports to the Governor of this State, as they have been in the habit heretofore of making to the different departments of the Federal Government to which they were respectively attached.
                </p>
                <p>And the committee further recommend, that the Governor instruct the paymaster at Norfolk to discharge the duties of navy agent at that post, if the discharge of such office be not incompatible with the other duties which said paymaster has to perform, in which event he is authorized to appoint George Loyall, of Norfolk, navy agent of the said port.</p>
                <p>And the committee further recommend, that these regulations shall be in force until otherwise ordered by this Convention, or until the Government of the Confederate States of America shall take control of the several offices hereinbefore mentioned, and prescribe regulations for the proper discharge of the duties and appointment of the officers to the same</p>
                <p>And the committee recommend further, that the Governor of the Commonwealth be furnished with a copy of this report as soon as the <PageNumber num={574} /> same shall be approved and adopted by the Convention, if the same shall be approved.</p>
                <p>All of which is respectfully reported.</p>
                <p>MIERS W. FISHER,</p>
                <p>Chairman of the Committee."</p>
              </div>
            </div>
            <div className="speaker" id="sp5054"><span className="persName">Mr. FISHER</span>—</div>
            <p className="p-in-sp">I ask that that report be adopted.</p>
            <p className="p-in-sp">I will state to the Convention that I conversed with General Lee, in regard to some of these matters, this morning, and he suggested to me, that the paymaster at Norfolk was the proper person to discharge the duties of navy agent there, and, it is in consequence of that remark of General Lee, that the committee made that recommendation.</p>
            <p className="p-in-sp">I hope it will be the pleasure of the Convention to adopt the report.</p>
            <div className="speaker" id="sp5055"><span className="persName">Mr. MONTAGUE</span>—</div>
            <p className="p-in-sp">
              That report embraces matters of, perhaps, as much importance as any that has been presented to this Convention. For one, I don't understand one word in it. There has been so much confusion around me here, while the report was being read, as to render it impossible for me to hear it. I trust that gentlemen here will demean themselves in a manner befitting a crisis like this. I must say
              that, during an experience of ten years in public life, I have seen nothing to equal the disorder which has prevailed in this body during the last four or five days.
            </p>
            <p className="p-in-sp">I regard this report as important, and having had no opportunity to hear it read, I move that it be laid upon the table.</p>
            <p>The motion was agreed to.</p>
            <div className="speaker" id="sp5056"><span className="persName">Mr. FISHER</span>—</div>
            <p className="p-in-sp">I now move that the committee which submitted that report be discharged.</p>
            <div className="speaker" id="sp5057"><span className="persName">Mr. BOULDIN</span>, of Charlotte—</div>
            <p className="p-in-sp">I don't think that a motion of that kind ought to be made in the present state of that report. I am a member of that committee, and I think it has not finished its duties. This report may be recommitted, and I therefore trust the House will not discharge the committee.</p>
            <div className="speaker" id="sp5058"><span className="persName">Mr. WICKHAM</span>—</div>
            <p className="p-in-sp">No chairman of a committee has a right to make a motion of that kind without the consent of the other members of the committee.</p>
            <div className="speaker" id="sp5059"><span className="persName">Mr. FISHER</span>—</div>
            <p className="p-in-sp">I withdraw the motion.</p>
            <div className="speaker" id="sp5060"><span className="persName">Mr. AMBLER</span>—</div>
            <p className="p-in-sp">I beg leave to submit the following report from the committee appointed to enquire into the number of delegates proper to be sent to the Congress of the Confederate States:</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>"The committee to whom was referred a resolution with instructions</p>
                <PageNumber num={575} />
                <p>to inquire what number of delegates should be sent by this State to the Congress of the Confederate States, and whether the selection of persons to be appointed as such delegates should be confined to members of this body, beg leave to report the following resolutions:</p>
                <p>"1. Resolved, That eighteen delegates should be sent by this State to the Congress of the Confederate States.</p>
                <p>"2. Resolved, That the said delegates should be selected, one from each of the present Congressional Districts, and five from the State at large.</p>
                <p>"3. Resolved, That in the selection of these delegates the choice should not be confined to members of this Convention."</p>
              </div>
            </div>
            <div className="speaker" id="sp5061"><span className="persName">Mr. ECHOLS</span>—</div>
            <p className="p-in-sp">I move to lay that report upon the table.</p>
            <p>The motion was agreed to.</p>
            <div className="speaker" id="sp5062"><span className="persName">Mr. TURNER</span>, of Jackson—</div>
            <p className="p-in-sp">I am instructed by the Special Committee appointed to prepare an ordinance and schedule on the subject of taxation, with a view to its being voted upon by the people of the Commonwealth in May next, to present the following.</p>
            <p className="p-in-sp">I will state that the greater portion of the schedule is precisely that which has been adopted by the Convention for the submission of the ordinance of secession. It is important that it should pass at once, so that it may go to the people forthwith.</p>
            <p>The ordinance and schedule were then read as follows :</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>AN ORDINANCE to amend the Constitution of this Commonwealth, so as to strike out the twenty-second and twenty-third sections of the fourth article of the present Constitution, and insert the following in lieu thereof.</p>
                <p>Taxation shall be equal and uniform throughout the Commonwealth, and all property shall be taxed in proportion to its value, which shall be ascertained in such manner as may be prescribed by law; but any property may be exempted from taxation by the vote of a majority of the whole number of members elected to each House of the General Assembly.</p>
                <p>This ordinance shall take effect on the first day of July next, when ratified by a majority of the votes of the people of this Commonwealth, cast at a poll to be taken thereon, on the fourth Thursday in May next, in pursuance of a schedule hereafter to be enacted.</p>
                <p>Done in Convention in the City of Richmond, on the 26th day of April, in the year of our Lord one thousand eight hundred and sixty one, in the eighty-fifth year of the Commonwealth of Virginia.</p>
                <PageNumber num={576} />
                <p>SCHEDULE</p>
                <p>
                  1. It shall be the duty of the officers conducting the elections directed by law to be held on the fourth Thursday in May next, at the places appointed for holding the same, to open a poll to take the sense of the qualified voters of this Commonwealth upon the ratification or rejection of "An ordinance to amend the Constitution of this Commonwealth, so as to strike out the
                  twenty-second and twenty-third sections of the fourth article of the present Constitution," adopted in Convention, in the City of Richmond, on the twenty-sixth day of April, one thousand eight hundred and sixty one.
                </p>
                <p>2. The poll book shall be headed, "Amendment to the Constitution of Virginia," and shall contain two columns-one headed, "For the Amendment," and the other, "Against the Amendment,"-and the names of those who vote for the amendment shall be written under the former heading, and the names of those who vote against the amendment, shall be written under the latter heading.</p>
                <p>
                  3. The said officers shall make return of the number of persons voting for each proposition at the time and in the manner provided by law, as in the case of other elections; and shall forthwith deliver the returns, together with the poll books, to the clerks of their respective counties and corporations, and it shall be the duty of such clerks respectively to transmit immediately
                  to the Governor of the Commonwealth, copies of the said returns so delivered to them.
                </p>
                <p>
                  4. The Governor shall, without delay, make proclamation of the result, stating therein the aggregate vote for and against the amendment, to be published in such newspapers in the State as may be deemed requisite for general information; and if a majority of said votes be cast for the ratification of the said ordinance, he shall annex to his proclamation a copy thereof, together
                  with the schedule.
                </p>
                <p>
                  5. The Secretary of the Commonwealth shall cause to be sent to the clerks of each county and corporation, as many copies of this schedule and ordinance aforesaid as there are places of voting therein, and three copies to each of the military commanders hereinafter referred to, using special messengers for that purpose when necessary. And it shall be the duty of the said clerks, to
                  deliver the same to the sheriffs for distribution, whose duty it shall be forthwith to post the said copies at some public place in each election district.
                </p>
                <p>6. The expenses incurred in providing poll books and in procuring writers to enter the names of the voters therein, shall be defrayed as in the case of the election of members to the General Assembly.</p>
                <PageNumber num={577} />
                <p>
                  7. The qualified voters of the Commonwealth who may be absent from the counties or corporations of their residence, on the day of election in the military service of the State may vote for the ratification or rejection of the said ordinance at such place or places within their encampment, or, as the commander at such encampment shall designate, whether the said encampment shall be
                  within the limits of this State or not. For each place of voting he shall appoint a superintendent, three commissioners, and as many clerks as shall be necessary, who, after having been first duly sworn by him shall perform the duties required of and be liable to the penalties imposed upon such officers under the election laws of the State.
                </p>
                <p>8. The officers conducting the said election shall, on the day after the election, or as soon thereafter as may be, deliver the poll books to their said commander, who shall forthwith forward the same to the Governor of this Commonwealth, who shall count the said votes in ascertaining the result of the said election in the State.</p>
              </div>
            </div>
            <div className="speaker" id="sp5063"><span className="persName">Mr. TURNER</span>—</div>
            <p className="p-in-sp">I move the adoption of the ordinance and schedule.</p>
            <div className="speaker" id="sp5064"><span className="persName">Mr. HOLCOMBE</span>—</div>
            <p className="p-in-sp">
              I would be glad to know whether this is the only change in the organic law of the State contemplated. We are required to refer to the people all changes which we may make in the organic law. Now, if we intend to go any farther into a revision of the Constitution, it seems to me very questionable whether we should refer this change now to the people. We had better wait and refer all the
              changes which we may make together, so that we may have a single vote upon all. If the Constitution is to be revised, it would be better, that all the questions should be submitted at once to the people than that they should act upon them in detail in this way. I don't know whether it is contemplated to go further into the subject; but for one I would be very glad to submit this tax
              question and the ordinance of secession to the vote of the people at the same time. I will, however, press no election to its submission.
            </p>
            <div className="speaker" id="sp5065"><span className="persName">Mr. TURNER</span>, of Jackson—</div>
            <p className="p-in-sp">I will say, that it is deemed very desirable that this question should be submitted at the next election; and I trust the Convention will adopt this report.</p>
            <p>The report was adopted.</p>
            <div className="speaker" id="sp5066"><span className="persName">Mr. TURNER</span>—</div>
            <p className="p-in-sp">I now offer the following resolution:</p>
            <p className="p-in-sp">"Resolved, That the injunction of secrecy so far as it relates to the subject of taxation be removed."</p>
            <div className="speaker" id="sp5067"><span className="persName">Mr. KILBY</span>—</div>
            <p className="p-in-sp">I would move to amend by adding "and, to the subject of small notes."</p>
            <div className="speaker" id="sp5068"><span className="persName">Mr. TURNER</span>—</div>
            <p className="p-in-sp">I accept the amendment.</p>
            <PageNumber num={578} />
            <div className="speaker" id="sp5069"><span className="persName">Mr. FORBES</span>—</div>
            <p className="p-in-sp">I think it would be proper to remove the obligation of secrecy from every subject not relating to Military Affairs.</p>
            <p>The question was then put on the adoption of the resolution and it was adopted.</p>
            <div className="speaker" id="sp5070"><span className="persName">Mr. MONTAGUE</span>—</div>
            <p className="p-in-sp">I beg leave to offer the following resolution.</p>
            <p className="p-in-sp">"Resolved, That the Committee of Finance report an ordinance for the payment of the expenses of the three commissioners sent by this Convention to Washington."</p>
            <div className="speaker" id="sp5071"><span className="persName">Mr. MACFARLAND</span>—</div>
            <p className="p-in-sp">I would suggest an amendment "that the Secretary of the Convention be authorized to draw his warrant on the Auditor of Public Accounts for the payment of the expenses of the three commissioners lately sent by this Convention to the city of Washington."</p>
            <p>The resolution was adopted.</p>
            <p>The unfinished business was then taken up, being the resolution submitted by <span className="persName">Mr. MORTON</span> inviting the President of the Confederate States and the constituted authorities of the Confederacy to make the city of Richmond or some other place in Virginia, the seat of government of the Confederacy.</p>
            <p>The vote was being taken on yesterday upon the adoption of the resolution, but a quorum not being present the Convention adjourned.</p>
            <div className="speaker" id="sp5072"><span className="persName">Mr. MORTON</span>—</div>
            <p className="p-in-sp">I move that the names of those who were absent last night when the vote was being taken on this question be now called.</p>
            <p><span className="persName">The PRESIDENT</span> so ordered, and the vote resulted yeas 76, nays 16-as follows:</p>
            <p>The names of those who voted in the affirmative are :</p>
            <p>
              Messrs. William M. Ambler, Wm. B. Aston, James Barbour, George Baylor, Geo. Blow, Jr., James Boisseau, Peter B. Borst, Wood Bouldin, Wm. W. Boyd, Thos. Branch, Fred. M. Cabell, Jno. A. Campbell, Allen T. Caperton, Wm. P. Cecil, John R. Chambliss, Manilius Chapman, Sam'l A. Coffman, Raphael M. Conn, James H. Cox, John Critcher, Harvey Deskins, Jas. B. Dorman, John Echols, Miers W.
              Fisher, Thos. S. Flournoy, Wm. W. Forbes, Napoleon B. French, Sam'l M. Garland, Sam'l L. Graham, Fendall Gregory, Jr., Wm. L. Goggin, John Goode, Jr., Addison Hall, Cyrus Hall, L. S. Hall, Lewis E. Harvie, Jas. P. Holcombe, Lewis D. Isbell, Marmaduke Johnson, Peter C. Johnston, Robert C. Kent, John R. Kilby, Walter D. Leake, Wm. McComas, Wm. H. Macfarland, Chas. K. Mallory, Jno. L.
              Marye, Sr., Fleming B. Miller, Horatio G. Moffett, Robert L. Montague, Edmund T. Morris, Jeremiah Morton, Sam'l McD. Moore, Wm. J. Neblett, Wm. Ballard Preston, George W. Randolph, Geo. W. Richardson, Timothy Rives, John T. Seawell, James W. Sheffey, Thomas Sitlington, John M. Speed, Burwell Spurlock, Samuel G. Staples, James M. Strange, Wm. T. Sutherlin, George P. Tayloe, John T.
              Thornton, <PageNumber num={579} /> Wm. M. Tredway, Robert H. Turner, Franklin P. Turner, John Tyler, Samuel C. Williams, Benjamin Wilson, Samuel Woods, Benj. F. Wysor-76.
            </p>
            <p>The names of those who voted in the negative are:</p>
            <p>Messrs. John Janney [President] , James C. Bruce, John A. Carter, Robert Y. Conrad, Jubal A. Early, H. L. Gillespie, Peyton Gravely, Ephraim B. Hall, J. G. Holladay, John F. Lewis, James Marshall, Edmund Pendleton, David Pugh, John D. Sharp, Alex. H. H. Stuart, W. C. Wickham-16.</p>
            <p>So the resolution was adopted.</p>
            <div className="speaker" id="sp5073"><span className="persName">Mr. GOODE</span>, of Bedford—</div>
            <p className="p-in-sp">I move that the President of the Convention communicate, by telegraph, to the Government of the Confederate States the subject of this resolution.</p>
            <p>The motion was agreed to.</p>
            <div className="speaker" id="sp5074"><span className="persName">Mr. MORTON</span>—</div>
            <p className="p-in-sp">I move that the injunction of secrecy be removed from this proceeding.</p>
            <p>Agreed to.</p>
            <div className="speaker" id="sp5075"><span className="persName">Mr. CAPERTON</span>, of Monroe—</div>
            <p className="p-in-sp">I move to take up the report in regard to the suspension of debts.</p>
            <div className="speaker" id="sp5076"><span className="persName">Mr. CAMPBELL</span>, of Washington county—</div>
            <p className="p-in-sp">I rise to a privileged question.</p>
            <p className="p-in-sp">
              On the day before yesterday, when the Convention negotiated by the Commissioners of Virginia and the Commissioner of the Confederate States, with the ordinance adopting the provisional Constitution of the Confederate States, were reported to this Convention, I had no time to examine them; and I voted against them. Having given them, since that time, more reflection than I had an
              opportunity of giving them upon their adoption, I am satisfied that the objections which induced me to vote against them do not exist. Having no hope of a reconstruction of the late Confederacy, and being unwilling to live under the powers that now exist at Washington and having been foiled in all my efforts to restore harmony and preserve the Union, I see no alternative for me but to
              turn my attention altogether to the South, and unite with those who now seek to accomplish her independence. My strongest hope now is that there may be a perfect Union of all the Southern States. I should regret that any vote of mine should commit me to any policy adverse to a speedy union with the Confederate States. I am, therefore, compelled to ask the Convention to grant me the
              privilege of changing my vote on these questions.
            </p>
            <div className="speaker" id="sp5077"><span className="persName">Mr. JOHNSTON</span>, of Lee and Scott—</div>
            <p className="p-in-sp">I also ask leave to change my vote on the Convention and ordinance.</p>
            <p>Leave was granted in both instances.</p>
            <div className="speaker" id="sp5078"><span className="persName">Mr. CAPERTON</span>—</div>
            <p className="p-in-sp">I now move to take up the ordinance in relation to the suspension of debts.</p>
            <PageNumber num={580} />
            <p>The motion was agreed to, and the ordinance was read as follows:</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>AN ORDINANCE to provide against the sacrifice of property and to suspend proceedings in certain cases.</p>
                <p>Be it ordained, That no execution shall be issued from the date hereof, by any court of record or magistrate, for the sale of property; nor shall there be any sales under deeds of trust or decrees, for the payment of money, until otherwise provided by law.</p>
                <p>Where executions have issued and are now in the hands of officers, whether levied or not, if the debtor offer bond and security for the payment of the debt, interests and costs, when the operation of this ordinance ceases, the property shall be restored and the bond so taken shall be returned as in case of a forthcoming bond.</p>
                <p>
                  If the debtor offers no such bond, it shall be the duty of the officer to convene three freeholders from the vicinage, who, after being sworn, shall proceed to value the property according to what would have been its value on the 6th day of November, 1860, and unless the said property shall sell for the full amount of such valuation, it shall be restored to the debtor without lien.
                </p>
                <p>Except in criminal cases or Commonwealth's prosecutions there shall be no trial of any cause requiring the intervention of a jury, but either party to such cause shall have the right, upon reasonable notice, to take the depositions of any witness, to be read de bene esse.</p>
                <p>In case of misdemeanor, juries shall be summoned from the bystanders, and not under the law, as it now stands; and with the consent of the party prosecuted, the cause may be tried by the court.</p>
                <p>This ordinance shall not apply to liabilities upon the part of public officers either to the State, counties, municipal corporations or individuals.</p>
                <p>The time during which this ordinance is in force shall not be computed in any case where the statute of limitations comes in question.</p>
              </div>
            </div>
            <div className="speaker" id="sp5079"><span className="persName">Mr. HENRY L. GILLESPIE</span>, of Fayette and Raleigh—</div>
            <p className="p-in-sp">I offer the following as a substitute for that ordinance:</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>SEC. 1. No civil suit for the trial whereof a jury may be demanded of right, and to which any officer or soldier in the service of the State of Virginia is a party, shall hereafter be tried by any court or justice of said State.</p>
                <p>
                  SEC. 2. Every unsatisfied judgment heretofore rendered, or hereafter to be rendered in said Commonwealth by any justice or court having competent jurisdiction to render the same, shall, in addition to the effect it now has under the Code of Virginia, operate a lien upon <PageNumber num={581} /> all the goods chattels and choses in action of the
                  judgment debtor against subsequent debtors and creditors with notice.
                </p>
                <p>SEC. 3. No execution shall issue for the collection of money upon any judgment of any tribunal of said Commonwealth, nor shall any sale be made under any deed of trust or decree of a Court of Chancery except in the cases hereinafter mentioned.</p>
                <p>
                  SEC. 4. The Clerk of every Court in the Commonwealth, having authority under laws now existing to issue executions, shall, at the request either of a judgment creditor or his agent; coupled with an affidavit, made either by such creditor or any surety, for the payment of the judgment, or by the agent of either that such judgment or a part thereof is still due and unsatisfied, and
                  that the judgment debtor is wasting, selling or disposing of his goods, chattels or choses in action, to the prejudice of such creditor or surety, or is about to dispose of such goods, chattels or choses in action, forthwith issue execution upon any such unsatisfied judgment of such Court; and every justice of the Commonwealth shall, upon such request and affidavit as aforesaid,
                  forthwith issue execution upon any unsatisfied judgment rendered by him, and after the termination of the term of office of such justice, either by efflux of time, removal, resignation or otherwise, any justice of the county or corporation wherein it was rendered shall, upon such request and affidavit as aforesaid, accompanied with the judgment, or an authenticated copy thereof,
                  issue such execution.
                </p>
                <p>
                  SEC. 5. Every Trustee in a deed of trust, and every Commissioner in chancery, authorized to sell property under the general laws of the Commonwealth, shall, at the request either of the beneficiary in such deed or of the decretal creditor or his agent, coupled with an affidavit made either by such creditor or surety for the payment of the debt, or by the agent of either, that such
                  decree or deed of trust is unsatisfied, and that the debtor is wasting, selling or disposing of his goods, chattels or choses in action to the prejudice of such creditor or surety, or is about so to dispose of them, forthwith proceed to sell according to the requisitions of the decree or deed of trust.
                </p>
                <p>
                  SEC. 6. The grantor or debtor may tender to the trustee or commissioner authorized to sell, or to any officer in possession of an execution, a bond with good security, payable to the creditor and conditioned for the payment of the debt, interest and costs sought to be recovered at the expiration of this ordinance; thereupon any property of the debtor, possessed by such trustee,
                  commissioner or officer, in virtue of such execution, commission or trusteeship shall be <PageNumber num={582} /> restored to the debtor, and the bond so taken shall be returned to the proper office as forthcoming bond, and shall have the same force and effect, and be subject to the same award of execution upon the same notice.
                </p>
                <p>
                  SEC. 7. If neither the debtor nor any one for him offer such bond, it shall be the duty of the officer to summon, or of the commissioner or trustee to procure to be summoned, three free-holders of the vicinage, not of kin to either party, who shall, after being sworn, value the property according to current prices, and make out and deliver under their signatures a duplicate
                  statement of such valuation, consisting of a list of articles, value with the price attached to each, the one whereof they shall deliver to the officer, trustee or commissioner, and the other they shall deliver to the debtor, if at any time within twelve months thereafter demanded by him, and such trustee, commissioner or officer shall not sell said property unless it bring
                  two-thirds of its valuation.
                </p>
                <p>
                  SEC. 8. The defendant in any execution, may move the justice who issued it, or the court whose clerk issued it, to quash it upon the ground that it issued upon a false suggestion, and thereupon the plaintiff therein shall be required to prove that the defendant therein, was at the date thereof, either disposing or was about to dispose of his property, or choses in action, to the
                  prejudice of such creditor or surety, as herein before mentioned, and upon failure of such proof, the execution shall be quashed : Provided, always, that the plaintiff in the execution have ten days previous notice of the time and place of the motion to quash.
                </p>
                <p>
                  SEC. 9. Any grantor in a deed of trust or debtor in a decree of a Court of Chancery may apply to any justice of the county or corporation wherein the trustee or commissioner is proceeding to sell, or to any Judge of the Circuit Court thereof, either in vacation or in term, for relief against such apprehended sale alleging that such trustee or commissioner is proceeding upon a false
                  suggestion, and thereupon such judge or justice, shall, within 15 days thereafter, cause the creditor or beneficiary to appear before him to contest such allegation, in the meantime issuing a restraining order to the trustee or commissioner, and if such beneficiary or creditor prove that the grantor or debtor was, before the commissioner or trustee took action, disposing of, or was
                  about to dispose of goods, chattels or choses in action, to the prejudice of the beneficiary or creditor, or surety of the debtor, said restraining order shall be dissolved; otherwise it shall remain in force.
                </p>
                <PageNumber num={583} />
                <p>
                  SEC. 10. This ordinance shall take effect upon the day of , 1861, and continue in force until repealed, either by a Con vention of this State or the Legislature thereof, which latter body is hereby authorized to make such repeal whenever it may deem it proper so to do; but the said ordinance shall not apply to liabilities of public officers, either to the State, counties,
                  corporations or individuals.
                </p>
                <p>SEC. 11. The time during which this ordinance be in force shall not be computed in any case wherein the statute of limitation shall come in question.</p>
              </div>
            </div>
            <div className="speaker" id="sp5080"><span className="persName">Mr. CAPERTON</span>—</div>
            <p className="p-in-sp">I call the previous question upon this substitute.</p>
            <p>The call was sustained, and the main question ordered to be put.</p>
            <div className="speaker" id="sp5081"><span className="persName">Mr. STAPLES</span>—</div>
            <p className="p-in-sp">Is it in order to move to lay the substitute on the table?</p>
            <div className="speaker" id="sp5082"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">No, sir.</p>
            <div className="speaker" id="sp5083"><span className="persName">Mr. STAPLES</span>—</div>
            <p className="p-in-sp">Well, then, I won't vote for or against it. I did not hear all its provisions distinctly, and, therefore, cannot vote intelligently upon it.</p>
            <p>The question was then put on the adoption of the substitute, and it was decided in the negative.</p>
            <div className="speaker" id="sp5084"><span className="persName">Mr. GOGGIN</span>—</div>
            <p className="p-in-sp">I approve, in the main, of the objects intended to be accomplished by this ordinance. But, it seems to me, it is very imperfect in many of its provisions, and for one, I cannot vote for it unless it is properly amended.</p>
            <p className="p-in-sp">I have prepared an amendment which I think will meet many of the objections which I have myself to it. I don't pretend to say that even this amendment is free from objections.</p>
            <p className="p-in-sp">I will now read my amendment.</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>
                  1. Be it ordained that no execution shall be issued after five days from the passage of this ordinance, by any Court of Record or Magistrate, for the sale of property; nor shall there be any sales under deeds of trust, except for the payment of interest and costs due according to the stipulations of such deeds; nor under decrees for the payment of money, until otherwise provided by
                  law.
                </p>
                <p>
                  2. Where executions have issued and are now in force in the hands of officers, whether levied or not, if the debtor shall tender bond with sufficient security, conditioned for the payment of the debt, interest and cost, and the expiration of one year, at any time before or on the day of sale-in all such cases, where property has been levied on, upon the giving of such bond, such
                  property shall be restored to the owner, and such bond shall be returned, and like proceedings shall then be had as in the case of forthcoming bonds.
                </p>
                <PageNumber num={584} />
                <p>
                  3. If the debtor offers no such bond, it shall be the duty of the officer to summon three freeholders of the vicinage, who, after being first sworn for the purpose, shall proceed to value the property according to what would have been its value on the 6th day of November, 1860, and unless the said property shall sell for the full amount of such valuation, it shall be restored to
                  the debtor. Provided, however, that the lien of the creditor upon such property shall thereafter remain as if his fi. fa. were in force in the hands of the officer, and not levied.
                </p>
                <p>4. Except in criminal cases or Commonwealth's prosecutions there shall be no trial of any cause requiring the intervention of a jury, but either party to such cause shall have the right, upon reasonable notice, to take the depositions of any witness to be read de bene esse.</p>
                <p>5. In cases of misdemeanor, juries shall be summoned from the bystanders, and not under the law as it now is-and with the consent of the party prosecuted, the cause may be tried by the Court.</p>
                <p>This ordinance shall not apply to liabilities upon the part of public officers, either to the State, to counties, to municipal corporations, or to individuals-nor to the liabilities of fiduciaries in their character as such.</p>
                <p>The time during which this ordinance is in force, shall not be computed in any case where the statute of limitations comes in question.</p>
                <p>This ordinance shall expire on the 30th day after the assembling of the next General Assembly.</p>
              </div>
            </div>
            <div className="speaker" id="sp5085"><span className="persName">Mr. GOGGIN</span>—</div>
            <p className="p-in-sp">I offer that as a substitute for the whole ordinance of the committee.</p>
            <div className="speaker" id="sp5086"><span className="persName">Mr. RANDOLPH</span>—</div>
            <p className="p-in-sp">I move to lay the ordinance and substitute upon the table.</p>
            <p>Agreed to.</p>
            <div className="speaker" id="sp5087"><span className="persName">Mr. MACFARLAND</span>—</div>
            <p className="p-in-sp">I move that the substitute be printed. The motion was agreed to.</p>
            <div className="speaker" id="sp5088"><span className="persName">Mr. RANDOLPH</span>—</div>
            <p className="p-in-sp">I now move that the Convention take up the ordinance providing for the organization of a provisional army.</p>
            <p>The motion was agreed to.</p>
            <p>The ordinance is as follows:</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>
                  Be it ordained, That there shall be organized a Provisional Army for the State of Virginia, as the exigencies of the service may require, which Army shall consist, in addition to the Major General commanding the military and naval forces of the State, of four Brigadier-Generals, two regiments of artillery, eight regiments of infantry, one
                  <PageNumber num={585} /> regiment of riflemen, and one regiment of cavalry. Each regiment of artillery shall consist of one colonel, one lieutenant colonel, one major, one sergeant major, one quarter master sergeant, and ten companies, and each company shall consist of one captain, one first lieutenant, one second lieutenant, four sergeants, four
                  corporals, two artificers, two musicians, and seventy-two privates. Each regiment of infantry shall consist of one colonel, one lieutenant colonel, one major, one sergeant major, one quartermaster sergeant, two principal musicians, and ten companies, and each company shall consist of one captain, one first lieutenant, one second lieutenant, four sergeants, four corporals, two
                  musicians, and seventy-two privates.
                </p>
                <p>
                  The regiment of cavalry shall consist of one colonel, one lieutenant colonel, one major, one adjutant, who shall be a lieutenant, one sergeant major, one quartermaster sergeant, one chief musician, two chief buglers, and ten companies, and each company shall consist of one captain, one first lieutenant, one second lieutenant, exclusive of the lieutenant who is to be the adjutant of
                  the regiment; four sergeants, one of whom shall act as quartermaster sergeant to the company, four corporals, two buglers, one farrier and blacksmith, and seventy-two privates.
                </p>
                <p>The regiment of riflemen shall consist of one colonel, one lieutenant colonel, one major, one sergeant major, one quartermaster sergeant, two principal musicians, and ten companies, and each company shall consist of one captain, one first lieutenant, one second lieutenant, four sergeants, four corporals, two musicians, and seventy-two privates.</p>
                <p>The Brigadier Generals of the said Army, shall be appointed by the Governor, by and with the advice and consent of this Convention, and the field and other officers of the several regiments shall be appointed by the Governor by and with the advice and consent of his Advisory Council until otherwise provided, and the Governor shall commission all of the said officers.</p>
                <p>
                  The Major General commanding the military and naval forces of the State shall be entitled to two aids-de camp who may be taken from the line without regard to rank, and he may appoint a military secretary either from private citizens or from the subalterns of the army, who, for the time being, shall have the pay and emoluments of a captain of cavalry. Each brigadier general shall
                  be entitled to one aid to be taken from the subalterns of the line; and to each regiment there shall be an adjutant, to be appointed from among the subalterns by the commanding officer of the regiment.
                </p>
                <p>
                  The non-commissioned officers, musicians, artificers, farriers and <PageNumber num={586} /> blacksmiths, and privates of the Provisional Army shall be enlisted for three years, unless sooner discharged, and the regulations for their enlistment shall be prescribed by the major general commanding the military and naval forces, subject, however, to the
                  approval of the Governor and his Advisory Council.
                </p>
                <p>
                  The officers of the Provisional Army shall take rank and precedence over all officers of the same grade of the volunteers and militia without regard to date of commission; and the Governor may proceed at once to appoint, in the mode prescribed, the general and field officers of the Provisional Army, and they may be assigned to duty with their respective rank with the volunteers
                  which have been or may be called into service.
                </p>
                <p>General regulations for the Provisional Army and all the departments of the military and naval forces may be prescribed by the commander of the military and naval forces, subject to the ratification of the Governor and his Advisory Council.</p>
                <p>This ordinance shall be in force from the time of its adoption, and be subject to amendment, modification, or repeal by this Convention or by the General Assembly.</p>
              </div>
            </div>
            <div className="speaker" id="sp5089"><span className="persName">Mr. EARLY</span>—</div>
            <p className="p-in-sp">I propose to offer an amendment to this ordinance, in conformity with the suggestion of the gentleman from Louisa [<span className="persName">Mr. AMBLER</span>] .</p>
            <p className="p-in-sp">The fourth section of the ordinance reads:</p>
            <p className="p-in-sp">
              "The Brigadier Generals of the said army shall be appointed by the Governor, by and with the advice and consent of this Convention, and the field and other officers of the several regiments shall be appointed by the Governor, by and with the advice and consent of his Advisory Council, until it is otherwise provided, and the Governor shall commission all of the said officers."
            </p>
            <p className="p-in-sp">I propose to substitute the following for that section:</p>
            <p className="p-in-sp">
              "The Brigadier Generals and field officers of the said Provisional Army, shall be appointed by the Governor, by and with the advice and consent of this Convention, and the captains and lieutenants shall be appointed by the Governor by and with the advice and consent of his Advisory Council, and the Governor shall commission the same, but in the recess of the Convention, the Governor
              may appoint the general and field officers by and with the advice and consent of his Advisory Council, if the said officers cannot be sooner appointed, but the appointment of such officers shall be subject to ratification or rejection by the Convention at its adjourned session, and their commissions shall expire upon the adjournment of the Convention, unless
              <PageNumber num={587} /> confirmed. In making all the appointments for the Provisional Army, preference shall be given to all worthy and efficient citizens and residents of Virginia, who have held or now hold commissions in the Army of the United States, and have resigned or shall resign the same with a view to offer their services to this State."
            </p>
            <div className="speaker" id="sp5090"><span className="persName">Mr. HOLLADAY</span>—</div>
            <p className="p-in-sp">Will the gentleman permit me to make a suggestion?</p>
            <p className="p-in-sp">
              According to this amendment, preference is given to officers who may resign in the United States Army, in all these appointments. It may be that a very subordinate officer might be raised, under this ordinance, to a very exalted position in preference to a much more competent man outside of the United States service. If I understand the object of the former ordinance, pledging the
              faith of the State to give a preference to those gentlemen of the rank they held in the service of the United States, I think this section goes too far, and is very likely to lead to the result which I have just indicated.
            </p>
            <p>The question was taken on the amendment, and it was agreed to.</p>
            <p>The question then recurred upon the adoption of the ordinance as amended, and it was decided in the affirmative.</p>
            <div className="speaker" id="sp5091"><span className="persName">Mr. MACFARLAND</span>—</div>
            <p className="p-in-sp">There is lying on the table a report submitted by the committee on yesterday, providing for deficiencies in the civil and military contingent funds, and I move to take it up.</p>
            <p>The motion was agreed to.</p>
            <p>The report was taken up and reads :</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>"AN ORDINANCE providing for deficiencies in the Civil and Military Contingent Funds.</p>
                <p>"Be it ordained, That the Auditor of Public Accounts place to the credit of the civil and military contingent funds, on the order of the Governor of the Commonwealth, out of any money in the treasury not otherwise appropriated, such sum or sums of money as will provide for deficiencies now existing or hereafter to arise in said funds or either of them.</p>
                <p>"This ordinance shall be in force until ten days after the commencement of the next session of the General Assembly."</p>
              </div>
            </div>
            <div className="speaker" id="sp5092"><span className="persName">Mr. MACFARLAND</span>—</div>
            <p className="p-in-sp">I would say that the contingent expenses of the Commonwealth are very much increased, and it is impossible in advance to say to what they may amount. The ordinance provides simply that any actual deficiency in the funds shall be made good by a transfer from the Treasury. That is the whole purport of it.</p>
            <p>The ordinance was adopted.</p>
            <PageNumber num={588} />
            <div className="speaker" id="sp5093"><span className="persName">Mr. RANDOLPH</span>—</div>
            <p className="p-in-sp">I move to take up the ordinance providing for enlistments in the provisional army.</p>
            <p>The motion was agreed to, and the ordinance being read, was adopted.</p>
            <div className="stage it">[This ordinance was published in a part of this day's proceedings.]</div>
            <div className="speaker" id="sp5094"><span className="persName">Mr. RANDOLPH</span>—</div>
            <p className="p-in-sp">I now move that all the ordinances reported today from the Committee on Military Affairs be taken up and acted upon.</p>
            <p>The motion was agreed to.</p>
            <p>The first in order was-</p>
            <p>"An ordinance providing for enlistments in the provisional army." The question having been put on the adoption of this ordinance, it was agreed to.</p>
            <p>The next was-</p>
            <p>"An ordinance concerning the Commissary Department." It reads:</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>
                  Be it ordained by the Convention, as follows, to wit : The commissary general, assistant commissary general, commissaries and assistant commissaries, before entering on the duties of their offices, shall give bonds, with approved security, in such penalties as the Governor may direct, and as many acting assistant commissaries may be detailed from the subalterns of the line as may
                  be necessary, who shall likewise give bond and security as above directed. The bonds hereby required to be given shall be made payable to the State of Virginia, and shall be filed with the First Auditor. The commissary general and his assistants shall perform such duties in purchasing and issuing of rations to the army of the State of Virginia as the Governor may direct.
                </p>
                <p>Supplies for the army, unless in particular and urgent cases the Governor should otherwise direct, shall be purchased by contract, to be made by the commissary general, on public notice, to be delivered on inspection in the bulk, and at such places as shall be stipulated; which contract shall be made under such regulations as the Governor may direct.</p>
                <p>This ordinance shall be in force from the time of its adoption, subject to amendment, modification or repeal by this Convention, or by the General Assembly of Virginia.</p>
              </div>
            </div>
            <div className="speaker" id="sp5095"><span className="persName">Mr. SHEFFEY</span>—</div>
            <p className="p-in-sp">I would suggest to the Chairman of the Committee, that in all cases where the words, "as the Governor may direct" occur, the words "by and with the advice and consent of the Advisory Council" be added.</p>
            <PageNumber num={589} />
            <div className="speaker" id="sp5096"><span className="persName">Mr. RANDOLPH</span>—</div>
            <p className="p-in-sp">That is covered by a general ordinance.</p>
            <p><span className="persName">Mr. EARLY</span>, being engaged preparing an amendment to this ordinance,</p>
            <p><span className="persName">Mr. MACFARLAND</span> moved to take up the ordinance, providing for the payment of the machinery authorized for the manufacture of arms in the public armory.</p>
            <p>The motion was agreed to.</p>
            <p>The ordinance reads:</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>"Be it ordained, That the Commissioners appointed by an ordinance of Convention, adopted this day for auditing military and naval claims, shall be authorized in the manner prescribed by said ordinance, to audit, settle and certify for payment any claims for arms and machinery, authorized by existing contracts for the manufacture of arms in the public armory."</p>
              </div>
            </div>
            <div className="speaker" id="sp5097"><span className="persName">Mr. CARTER</span>, of Loudoun—</div>
            <p className="p-in-sp">I would ask the gentleman if there is not a contract already existing for the completion of the work in that armory?</p>
            <div className="speaker" id="sp5098"><span className="persName">Mr. MACFARLAND</span>—</div>
            <p className="p-in-sp">There is a law which provides for the payment of this claim. But that can be done only by the sale of State bonds, which, if resorted to now, would result in a great loss to the State.</p>
            <div className="speaker" id="sp5099"><span className="persName">Mr. CARTER</span>—</div>
            <p className="p-in-sp">I desire to know whether this creates any change in the original contract.</p>
            <div className="speaker" id="sp5100"><span className="persName">Mr. MACFARLAND</span>—</div>
            <p className="p-in-sp">It does not.</p>
            <div className="speaker" id="sp5101"><span className="persName">Mr. CARTER</span>—</div>
            <p className="p-in-sp">If there is a contract already, how can you abandon it without the consent of the contractors on the other side?</p>
            <div className="speaker" id="sp5102"><span className="persName">Mr. RANDOLPH</span>—</div>
            <p className="p-in-sp">It does not propose to alter the contract. There is a sum of $12,000 due for the purchase of arms. If this money is to be raised by the sale of State bonds, it will involve a great loss to the State.</p>
            <p>The ordinance was then adopted.</p>
            <p>The ordinance concerning the Commissary Department was then taken up, and being amended slightly, on the motion of <span className="persName">Mr. EARLY</span>, it was adopted.</p>
            <p>The ordinance is perfect as it appears above.</p>
            <p>The ordinance concerning a Quartermaster's Department was next taken up and adopted. [This ordinance was published in the first part of to-day's proceedings.] The ordinance prescribing the duties of the Paymaster General was taken up.</p>
            <p>It reads:</p>
            <PageNumber num={590} />
            <div className="floatingText">
              <div className="floatingText_body">
                <p>
                  The Paymaster General shall receive from the Treasury of the State all monies entrusted to him to pay the troops or purchase subsistence or forage. He shall receive the pay abstracts of the paymasters of the several regiments or corps, and shall compare them with the rations or muster rolls accompanying the said abstracts. He shall certify to the commanding officer the sums due to
                  the respective corps, who shall thereupon issue his warrant on the said paymaster general for payment accordingly. Copies of all reports to the commanding officer and the warrants thereon shall be duly transmitted to the office of the First Auditor, to be examined and finally adjusted at the Treasury.
                </p>
                <p>This ordinance shall be in force from its adoption, and the subject to amendment, modification, or repeal by this Convention or by the General Assembly.</p>
              </div>
            </div>
            <p>This ordinance was adopted.</p>
            <p>The next ordinance was that concerning promotion and brevet rank.</p>
            <div className="speaker" id="sp5103"><span className="persName">Mr. EARLY</span>—</div>
            <p className="p-in-sp">That ordinance was drawn up before the ordinance I had charge of, with regard to the provisional army; and the corps there are named differently from that reported this morning.</p>
            <p className="p-in-sp">I move that it be referred back to the Military Committee.</p>
            <div className="speaker" id="sp5104"><span className="persName">Mr. TURNER</span>—</div>
            <p className="p-in-sp">I move that we adjourn.</p>
            <p>The motion was lost.</p>
            <p>The motion to recommit was then agreed to.</p>
            <p>"An Ordinance concerning a reserved list" was next taken up.</p>
            <div className="speaker" id="sp5105"><span className="persName">Mr. EARLY</span>—</div>
            <p className="p-in-sp">I move that that be laid upon the table.</p>
            <p className="p-in-sp">I think that may be hereafter a proper subject for consideration. It is taken from a provision in the army laws of the United States which provides for a reserved list. I do not think we ought to provide for a reserved list now, but for men in active service.</p>
            <p>The motion to lay on the table was agreed to.</p>
            <p>"An Ordinance for the regulation of the Provisional Army of Virginia," next came up.</p>
            <div className="speaker" id="sp5106"><span className="persName">Mr. EARLY</span>—</div>
            <p className="p-in-sp">That had better go back to the committee. I have not heard that read before. I see it provides for a surgeon general and assistant surgeons. We have already provided for these. It may be necessary to provide for more.</p>
            <p>I move that it be referred back to the Committee on Military Affairs.</p>
            <p>The motion was agreed to.</p>
            <p>The ordinance differs from that published in the early part of these <PageNumber num={591} /> proceedings. The latter, however, is the ordinance which was sub sequently reported by the committee and adopted by the Convention.</p>
            <p>"An Ordinance establishing the Navy of Virginia," next came up.</p>
            <div className="speaker" id="sp5107"><span className="persName">Mr. SHEFFEY</span>—</div>
            <p className="p-in-sp">
              Before the question is put upon that ordinance, I desire to know if it is intended to provide for a body of reserved naval officers upon the reserved list of the United States who are to come as pensioners upon the bounty of Virginia? If so, this is in conflict with an ordinance heretofore adopted, inviting only into our service "worthy and efficient officers of the army and navy." I
              don't think we are in a condition financially to encumber our Treasury with a reserved list-with pensioners now living upon the bounty of the Government of the United States. If that be the effect of that ordinance, I desire to know it.
            </p>
            <div className="speaker" id="sp5108"><span className="persName">Mr. RANDOLPH</span>—</div>
            <p className="p-in-sp">
              There are only seven retired officers for the State of Virginia. Some of them have already entered the service of the State and are hard at work. Now, all we propose to give them is the same pay that the United States gave them; and the whole number is so small that their pay will be but little felt. Inasmuch as your action would cut them off from the only livelihood they had, we
              thought it right to provide for them. Of course we expect this whole Navy Department to be adopted by the Confederate States, and they will probably continue this provision for these retired officers.
            </p>
            <p>The ordinance was adopted.</p>
            <div className="speaker" id="sp5109"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">I beg leave to submit a communication from the Governor of the Commonwealth.</p>
            <p>The Secretary read the communication as follows:</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>EXECUTIVE CHAMBER,</p>
                <p>April 27th, 1861.</p>
                <p>To the Council of Three:</p>
                <p>I hereby nominate Major Thomas J. Jackson Colonel of Volunteers, called into service under the ordinance of April 17th, 1861, and respectfully request the advice of Council on the appointment.</p>
                <p>JOHN LETCHER.</p>
                <p>The Council unanimously approve of the nomination of Major Jackson as Colonel of Volunteers.</p>
                <p>By order of the Council,</p>
                <p>P. F. HOWARD, Secretary.</p>
                <PageNumber num={592} />
                <p>Gentlemen of the Convention:</p>
                <p>I, this morning, nominated Major Thomas J. Jackson Colonel of Volunteers, called into service under the act of April 17th, 1861, and that nomination has been unanimously approved by the Council. I now present the nomination for confirmation by your honorable body, suggesting that early action is desirable.</p>
                <p>Respectfully,</p>
                <p>JOHN LETCHER.</p>
              </div>
            </div>
            <div className="speaker" id="sp5110"><span className="persName">Mr. DORMAN</span>, of Rockbridge—</div>
            <p className="p-in-sp">There is not a more gallant man on the face of the earth than Major Thomas J. Jackson. He is a graduate of the Virginia Military Institute.</p>
            <div className="note" id="Note53">
              <span className="noteLabel">1</span>A communication from Mr. Dorman (November 19) corrected his remarks of April 27 in support of the nomination of Major Jackson, as follows:
              <p>
                Gentlemen: In the report of our proceedings of April I am represented as saying that Major (now Major General) Jackson is a graduate of the Virginia Military Institute. On my own account, I should not care to correct the mistake, but anything relating to an officer who has so nobly distinguished himself in the present war, should be stated with accuracy. Upon the nomination of Major
                Jackson as Colonel of Volunteers, the question was asked, "Who is he?" I responded to the inquiry, and after a brief tribute to the gallantry and high personal qualities of the nominee, said, "I have known him for several years as a Professor at the Virginia Military Institute. He is a graduate of West Point, and was brevetted three times for gallant service in a very short space of
                time (I believe about three weeks) as Lieutenant of Artillery in the Mexican War."
              </p>
            </div>
            <div className="speaker" id="sp5111"><span className="persName">Mr. GOODE</span>, of Bedford—</div>
            <p className="p-in-sp">I move that the nomination be confirmed. The motion was agreed to, and an order was given to inform the Governor of the confirmation of Colonel Jackson.</p>
            <div className="speaker" id="sp5112"><span className="persName">Mr. SUTHERLIN</span>—</div>
            <p className="p-in-sp">I offer the following resolution:</p>
            <p className="p-in-sp">"Resolved, That when the Convention adjourns to-day it will adjourn to meet on Monday next, at 10 o'clock, A. M., and at the same hour at each successive day until otherwise ordered."</p>
            <p>The resolution was adopted.</p>
            <div className="speaker" id="sp5113"><span className="persName">Mr. KILBY</span>—</div>
            <p className="p-in-sp">I offer the following resolution:</p>
            <p className="p-in-sp">"Resolved, That when this Convention shall adjourn on Tuesday next the 30th inst., it adjourn to meet in the city of Richmond, on the second Wednesday in June next, or at such other place as the Governor of this Commonwealth shall, in his judgment, determine."</p>
            <div className="speaker" id="sp5114"><span className="persName">Mr. STAPLES</span>—</div>
            <p className="p-in-sp">I move to lay that resolution on the table.</p>
            <p>The motion was agreed to.</p>
            <div className="speaker" id="sp5115"><span className="persName">Mr. TURNER</span>, of Jackson—</div>
            <p className="p-in-sp">I offer the following resolution:</p>
            <p className="p-in-sp">"Resolved, That the Secretary of the Convention be authorized to appoint, until further ordered, an engrossing clerk with the same compensation as clerks of committee."</p>
            <p>The resolution was adopted.</p>
            <div className="speaker" id="sp5116"><span className="persName">Mr. RANDOLPH</span>—</div>
            <p className="p-in-sp">I move that the injunction of secrecy be removed, so far as it relates to the ordinances organizing the army and navy, just adopted.</p>
            <div className="speaker" id="sp5117"><span className="persName">Mr. MACFARLAND</span>—</div>
            <p className="p-in-sp">I move to add, "And the two ordinances from the Finance Committee adopted to-day."</p>
            <div className="speaker" id="sp5118"><span className="persName">Mr. RANDOLPH</span>—</div>
            <p className="p-in-sp">I accept the amendment.</p>
            <p>The motion was then agreed to.</p>
            <p>On motion of <span className="persName">Mr. RANDOLPH</span>, the Convention adjourned until 10 o'clock A. M., on Monday.</p>
          </div>
          <div className="day" id="vsc1965.v4.2.13">
            <PageNumber num={593} />
            <h2><span className="headingNumber">1.13. </span><span className="head">THIRTEENTH DAY</span></h2>
            <div className="dateline">Monday, <span className="date">April 29</span></div>
            <p>The Convention met at 10 o'clock, pursuant to adjournment on Saturday.</p>
            <p>Prayer by the Rev. <span className="persName">Mr. SPURLOCK</span>, the delegate from the county of Wayne.</p>
            <div className="speaker" id="sp5119"><span className="persName">Mr. MORTON</span>—</div>
            <p className="p-in-sp">I move that the pages of the Convention be admitted to the Hall.</p>
            <p>The motion was agreed to.</p>
            <p>The Secretary called over the list of committees, whereupon,</p>
            <div className="speaker" id="sp5120"><span className="persName">Mr. COX</span>, of Chesterfield, said:</div>
            <p className="p-in-sp">I am instructed by the Committee on Enrolled Ordinances to state that some seven or eight ordinances have been enrolled. The committee asks leave to sit during the session of the Convention.</p>
            <div className="speaker" id="sp5121"><span className="persName">Mr. CARTER</span>, of Loudoun—</div>
            <p className="p-in-sp">I move that the Committee on Enrolled Ordinances be enlarged by the addition of one member.</p>
            <p>The motion was agreed to.</p>
            <p>The motion for leave to sit during the session of the Convention was then agreed to.</p>
            <p><span className="persName">The PRESIDENT</span> added to the Committee on Enrolled Ordinances the name of ROBT. H. TURNER, of Warren.</p>
            <div className="speaker" id="sp5122"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">The business in order this morning is the report of the committee charged with the duty of inquiring what number of delegates should be sent to the Confederate Congress from Virginia.</p>
            <div className="speaker" id="sp5123"><span className="persName">Mr. WM. MCCOMAS</span>, of Cabell—</div>
            <p className="p-in-sp">I beg leave to offer the following resolution—</p>
            <div className="speaker" id="sp5124"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">It is not in order.</p>
            <div className="speaker" id="sp5125"><span className="persName">Mr. MCCOMAS</span>—</div>
            <p className="p-in-sp">I move to pass by the unfinished business for a moment.</p>
            <p>The motion was agreed to.</p>
            <div className="speaker" id="sp5126"><span className="persName">Mr. MCCOMAS</span>—</div>
            <p className="p-in-sp">I offer the following resolution:</p>
            <p className="p-in-sp">"Resolved, That the Military Committee be instructed to inquire into the most expeditious mode for arming the counties on the Western border line of this State."</p>
            <p>The resolution was agreed to.</p>
            <div className="speaker" id="sp5127"><span className="persName">Mr. CONRAD</span>, of Frederick—</div>
            <p className="p-in-sp">I would ask the general consent of the Convention to take up the resolution, offered the other day, in regard to the completion of the railroad communication between Winchester and Strasburg.</p>
            <PageNumber num={594} />
            <div className="speaker" id="sp5128"><span className="persName">Mr. MORTON</span>—</div>
            <p className="p-in-sp">I object.</p>
            <p>The report of the committee appointed to inquire what number of delegates should be sent from Virginia to the Congress of the Confederate States was then taken up.</p>
            <p>It is as follows:</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>1. Resolved, That eighteen delegates should be sent by this State to the Congress of the Confederate States.</p>
                <p>2. Resolved, That the said delegates should be selected, one from each of the present Congressional Districts, and five from the State at large.</p>
                <p>3. Resolved, That in the selection of these delegates the choice should not be confined to members of this Convention.</p>
              </div>
            </div>
            <div className="speaker" id="sp5129"><span className="persName">Mr. GOODE</span>, of Bedford—</div>
            <p className="p-in-sp">I offer the following as a substitute :</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>"1. Resolved, That this Convention will now proceed to elect fifteen representatives to represent the State of Virginia in the Congress about to assemble at Montgomery.</p>
                <p>"2. Resolved, That no member of this body shall be elected as a representative in the said Congress."</p>
              </div>
            </div>
            <div className="speaker" id="sp5130"><span className="persName">Mr. GOODE</span>, of Bedford—</div>
            <p className="p-in-sp">
              As to the number suggested by the committee, it seems to me to be entirely too large, and I propose to reduce that number to fifteen. I understand that all the States now represented in the Congress at Montgomery have the same number of delegates which they had in the old Congress, including members and Senators. That would entitle us to fifteen, and I see no propriety in sending a
              greater number.
            </p>
            <p className="p-in-sp">
              As to the second resolution, it seems to me that the propriety of adopting it must be apparent. I propose it as a self-denying ordinance, in view of the delicate trust which we have now to discharge here. It was never contemplated by the people that we would send delegates to Congress. The power is a usurped power in our hands, and we should so exercise it as to create no
              dissatisfaction among the people. If we undertake to elect members of our own body, we will subject ourselves to the imputation of selfishness and of arrogating to this body all the talent and wisdom of the State. We have good and able men here who would make excellent representatives; but we have men outside of this body who would be equally creditable to the State as representatives
              in the Confederate Congress; and in making the selections, we should ascertain as nearly as possible the preferences of the people in the districts, and act as the people would act if they had the choices to make.
            </p>
            <PageNumber num={595} />
            <div className="speaker" id="sp5131"><span className="persName">Mr. ECHOLS</span>, of Monroe—</div>
            <p className="p-in-sp">I understand that the resolutions just offered are intended as a substitute for the report of the committee. The substitute proposes that we shall elect fifteen delegates to the Congress of the Confederate States. The substitute also proposes that no member of the Convention shall be eligible to the position of delegate to the Confederate Congress.</p>
            <p className="p-in-sp">I heartily concur in the second resolution but dissent from the first. I think myself, and I shall offer such an amendment to the first resolution, that five is a sufficient number to be sent to that Congress for the present.</p>
            <div className="speaker" id="sp5132"><span className="persName">Mr. GOODE</span>—</div>
            <p className="p-in-sp">I accept the amendment.</p>
            <div className="speaker" id="sp5133"><span className="persName">Mr. ECHOLS</span>—</div>
            <p className="p-in-sp">I put the amendment upon the ground that we send men of undoubted talents, who will represent us-not so much as delegates, as commissioners-until the people of the State shall have an opportunity of duly electing their representatives. I move to insert "five" instead "fifteen."</p>
            <div className="speaker" id="sp5134"><span className="persName">Mr. AMBLER</span>—</div>
            <p className="p-in-sp">It is due to the committee that a statement should be made with regard to their course upon this subject. These are times when it is necessary that men should speak out and speak plainly.</p>
            <p className="p-in-sp">
              Now, the condition of things is this, that it was known, and known generally, that there was a strong desire felt on the part of a very large proportion of the gentlemen who were in the Congress of the late United States that they should go into the Confederate States Congress; that that influence had been brought to bear upon members of this Convention; that if it succeeded the effect
              would be to dispose, at once, of fifteen places in this Congress, and that if it did succeed, it would of necessity exclude all other persons as representatives from this State in the Congress. In order to avoid that difficulty, it was proposed by the committee to put an addition of three to the number, to be chosen from the State at large. Supposing that the same rule would apply to
              the two Senators which was proposed to apply to the representatives from the different districts; and eighteen being the number to which, if you adopt the ratio of 70,000, the State would be entitled, that number was recommended by the committee. In order, also, to reconcile all these differences of opinion among gentlemen from the various sections of the State, and to enable the
              Convention to avail itself of the talent and experience of the delegates in the last Congress of the United States, the committee recommended the adoption of that number.
            </p>
            <p className="p-in-sp">If you adopt the number proposed by the gentleman from Monroe [five] , and accepted by the gentleman from Bedford [<span className="persName">Mr. GOODE</span>]
              , you <PageNumber num={596} /> are introducing elements of discord and of difficulty. Already there are serious murmurs, and murmurs which must be heeded, with regard to the course pursued in the matter of appointments here and elsewhere. Here complaints are, as I think, well founded, and if not heeded here, they will be openly and decidedly expressed;
              and it is time that we should, as far as we can, apply a remedy to the evils which I believe to exist. If you reduce the number to five, you will necessarily create much difficulty and dissatisfaction. I have heard that one of these five is to be chosen from my section of the country, a gentleman whose course has rendered him obnoxious to men in all my section of the country; and if he
              is brought forward here, it will be against the protest of every man in all my section of the country, for it will be an outrage upon my section if you force such a man upon my people to represent them anywhere. Sir, there is a feeling which you must not raise; and if the attempt I refer to is made here, that feeling must be displayed, and it will be displayed to the injury of the
              cause which we all advocate.
            </p>
            <p className="p-in-sp">
              I appeal to gentlemen before they raise that feeling to reflect well upon their action, and avow a course which must, if carried out, result in division and embarrassment. Sir, there is a respect due to the wishes and the feelings of the people which cannot be disregarded, and if it is disregarded, proper respect will be enforced by the people themselves. I do beg gentlemen to pause
              before they commit themselves to a course which must raise that feeling and produce serious injury.
            </p>
            <div className="speaker" id="sp5135"><span className="persName">Mr. MORTON</span>—</div>
            <p className="p-in-sp">I confess that I have listened with some degree of surprise and much regret to the remarks of my friend from the county of Louisa.</p>
            <p className="p-in-sp">I am decidedly in favor of the amendment. This morning, in the public library, I accidentally laid my hands upon a speech of the great Irish Patriot, Henry Grattan, and the very first sentence that I read, I commend to the consideration of this body.</p>
            <p className="p-in-sp">It is this: "We would do well to keep in mind that there is but one enemy, the French, and that our best defence against that enemy is our unanimity."</p>
            <p className="p-in-sp">I am for unanimity. I wish the best men, the truest men, the most talented men that can be found in the State, having due reference to the great sectional divisions of the Commonwealth, to be elected by this Convention.</p>
            <p className="p-in-sp">
              I fully concur in the propriety of electing men from outside and not inside this body. The gentleman from Louisa has referred to an individual <PageNumber num={597} /> without naming him; but I presume I know the individual to whom he refers. I do not concur in that nomination if it should be made. If the gentleman had waited for the nominations, I
              presume that he would have found that nominations would have been made which would receive the general assent of this Convention, and meet with a patriotic endorsement throughout the Commonwealth. I say the time is past when we shall be looking to old party divisions. We should elect men who are true. At the same time we should have a man from the Tide-water section, from the Piedmont
              range, from the Valley, from the North-West and from the South-West. Give us a man from each of these sections, and the gentleman will find unanimity beyond what he will find if we send fifteen or eighteen delegates to the Confederate States Congress. If we determine to send eighteen, each member of the last Congress will consider himself slighted if he is not the honored choice of
              this body; but if you reduce the number to five, there is no man whose feelings can be touched. If he is overlooked, he must be overlooked in common with many as patriotic and talented as himself So if you wish to promote unanimity, and prevent division, my honest opinion is, that you should first go outside of this body to choose delegates, and that the number should not exceed five.
            </p>
            <div className="speaker" id="sp5136"><span className="persName">Mr. THORNTON</span>, of Prince Edward—</div>
            <p className="p-in-sp">I am constrained to say something in opposition to the resolution offered by the gentleman from Bedford [<span className="persName">Mr. GOODE</span>] .</p>
            <p className="p-in-sp">
              So far as his "self denying ordinance," as he has termed it, is concerned, while it would be agreeable to me personally to vote for it, yet I do not think that we ought at this time to exclude from our consideration, in the selection of these delegates, the 152 gentlemen who are members of this Convention. I suppose, sir, our object ought to be to get the wisest and best men we can to
              fill this position, and I am unwilling, either, as indicated by the gentleman from Louisa
              [<span className="persName">Mr. AMBLER</span>]
              , to elect every representative that we had in the late Congress of the United States, or as desired by the gentleman from Bedford, to elect entirely outside of this Convention. But I want all the material that is in the State brought within the range of selection, so that we may be able to choose that man or these men from the whole State, who, I think, would make the ablest and the
              wisest Counsellors in the Congress of the Provisional Government.
            </p>
            <p className="p-in-sp">Again, sir, there seems to me to be much force in the suggestion of the Vice-President of the Confederate States, that Virginia should have a larger delegation than five in that Congress. I propose the <PageNumber num={598} /> scheme originally suggested by the gentleman from Bedford [Mr. GooDE]
              , of sending fifteen; and when I vote for that I would not consider myself pledged to support all the gentlemen who represented us in the late Congress of the United States; but I conceive that I am at liberty to look over the whole State, and wherever I can find a man possessing eminent ability for the office, whether he was in the late Congress, whether he is in this Convention or
              whether he fills any other office, I desire to give my vote for him.
            </p>
            <div className="speaker" id="sp5137"><span className="persName">Mr. WM. J. NEBLETT</span>, of Lunenburg—</div>
            <p className="p-in-sp">
              I do not intend to make a speech. I know too well the temper of this House to impose such an affliction upon them at this time; but I must take occasion to say that I am utterly opposed to selecting any gentleman from this body to represent us in the Confederate States Congress, for the reason that there is no necessity, nor is there any propriety in such a course. We shall have
              business enough of importance here to demand the undivided labor and attention of every member of this body.
            </p>
            <p className="p-in-sp">I am also utterly opposed to the sending of eighteen members to this Congress, as recommended by the committee; because I consider that number too large; and I am opposed to the number [five] proposed by the gentleman from Monroe [<span className="persName">Mr. ECHOLS</span>] , and accepted by the gentleman from Bedford [<span className="persName">Mr. GOODE</span>]
              , because I regard that number too small. The best course for us to pursue, in my judgment, is to send fifteen delegates to Montgomery. We will thus have an equal representation with other States, according to our representative ratio, and this number, moreover, is likely to give general satisfaction, inasmuch, as every district will be represented, allowing, in addition, two, by way
              of an equivalent, for the two Senators that represented us in the late Congress. If we come down to the number five, we will inevitably produce dissatisfaction everywhere throughout the Commonwealth.
            </p>
            <p className="p-in-sp">Now, so far as my district is concerned, my people are ninety nine in one hundred in favor of the Hon. Roger A. Pryor, because he is a gentleman not only of State reputation, but of national reputation.</p>
            <p className="p-in-sp">I do hope that it will be the pleasure of the Convention to adopt neither the number eighteen nor five, but to adopt the number fifteen; and that they will agree to go outside of this Convention for the selection of that number. We will thus give general satisfaction.</p>
            <div className="speaker" id="sp5138"><span className="persName">Mr. BLAKEY</span>, of Madison—</div>
            <p className="p-in-sp">
              It seems to me, if the object of this Convention is to save money to the Confederate States, or if its object is to reduce the number below the representation which we now have, I conceive that the same reason which would reduce it to five would <PageNumber num={599} /> bring it down to one. Let us, then, take the most acceptable men within the broad
              limits of the Commonwealth, and if the selection of fifteen will create jealousy, and the selection of five will diminish this, the selection of one will reduce it still lower. If the object is to get rid of jealousy-if you carry out this process of reasoning-you will send no delegates to Montgomery.
            </p>
            <p className="p-in-sp">
              It seems to me, sir, that the object we should have in view should be this: to send a delegation which will most fairly represent the various shades of opinion and interest throughout the Commonwealth; and I see no reason why we should not send precisely the same number that we expect to send in the Fall, if we should become permanently connected with the Government of the Confederate
              States. Why is it that the same number should not be sent now as then? Are there not the same interests to take care of now as will be then? Are there not the same constituencies to represent? And are they not to come in contact with the delegates of the same people from all parts of the Confederate States? It does seem to me, in view of these considerations, that we ought to select a
              delegation which will come, not only from every section, but every district of the State; and the committee in fixing upon eighteen, took that number which they supposed Virginia would be entitled to when she sends her representatives to the permanent Congress; and the same reason which would justify our sending that number under the permanent Government of the Confederate States,
              induced the committee to believe it would justify them in recommending the same number now. For this reason I shall vote for the number eighteen, and if that is voted down, I will go for fifteen, and if that is voted down, I will vote for the next highest number.
            </p>
            <div className="speaker" id="sp5139"><span className="persName">Mr. RIVES</span>, of Prince George—</div>
            <p className="p-in-sp">I beg leave to offer the following amendment to the amendment offered by the gentleman from Bedford [<span className="persName">Mr. GOODE</span>] .</p>
            <p className="p-in-sp">
              "Resolved, That this Convention now proceed to elect fifteen delegates to the Congress of the Southern Confederacy, twelve of whom shall be the late members from the respective districts in the Congress of the United States, to wit: From the 1st district, M. R. H. Garnett; 2d district, J. S. Millson; 3d district, D. C. Dejarnette; 4th district, Roger A. Pryor; 5th district, Thomas S.
              Bocock; 6th district, Shelton F. Leake; 7th district, William Smith; 8th district, Alexander R. Boteler; 9th district, John T. Harris; 1 1 th district, A. G. Jenkins, 12th district, H. A. Edmundson; 13th district, E. S. Martin; and that be elected from the 10th district, the <PageNumber num={600} /> Hon. Sherrard Clemens having declined a re-election, and
              that the Hon. R. M. T. Hunter and James M. Mason be elected for the State at large."
            </p>
            <div className="speaker" id="sp5140"><span className="persName">Mr. RIVES</span>—</div>
            <p className="p-in-sp">
              I offer that amendment upon this ground. It is perfectly clear that the election which we are now about to proceed to, properly belongs to the people of the State. If members of Congress were to be elected and sufficient time was left for their election by the people, nobody would deny but that the people of each district would have to elect these members. The people, however, have not
              time to elect, and that duty will devolve upon this Convention. In the exercise of that duty, this Convention should approach as nearly as possible to what they believe the people would do. We only express the voice of the people; and in electing any other than those whom we know to be the choice of the people, I suggest that we would be departing from the established principle which
              regulates popular elections themselves-that the people's favorite should represent them, and no other-and that the voice of the people in each district should govern in the selection of their representatives. This principle can best be carried out by leaving to the delegates from the respective districts the selection of the representatives for these districts. According to the popular
              election system, the people of district No. 1 would have nothing to do with the election of the delegate from district No. 2, and so with all the other districts. If the people, say of the first district, shall be deprived of the privilege of voting for their representatives, if the voice of the people is to be stopped, and the voice of the representative is to speak, it is because the
              people cannot speak, and this being the case the delegation from the first Congressional district would be the proper body to elect a representative for that district. They are the immediate representatives of the sovereign people, and necessarily best informed as to their will in this and all other matters appertaining to their public relations. The people themselves would have to
              elect their delegates in the event of a failure of the Convention to do so, and their choice should be strictly regarded. If this whole Convention, suppose, were to elect a member for the 1st district, I ask whether it is probable that the result could be as satisfactory as if the choice was made by the delegation from that district. That was a great fallacy which was embodied in the
              proclamation of General Jackson, that a representative was not the representative of the people of his immediate district, but of the people at large. I hold that he is the peculiar representative of the people who elected him, and to some extent, I admit, the representative <PageNumber num={601} /> of the people at large. But his first obligation, is to
              his immediate constituents. I am satisfied, sir, that the best and safest policy is to give to the delegation from the respective districts, the power to elect the representative for each district. They can be governed by the last expression of the popular choice, particularly of this regard, that expression is an endorsement of the representative in the late Congress. This is the best
              data upon which to base a selection. There is in fact no better way, unless the representative has done something since his last election, so glaring as to leave no room to doubt that a radical change of sentiment has taken place in reference to him.
            </p>
            <p className="p-in-sp">
              In exercising this power for the people, we should indeed be cautious to act as nearly in conformity with the expressed wish of the people as possible; and in order to do so, it seems to me that the adoption of the plan which I propose, is the best. In the absence of any positive assurance by a late election, as to what the popular sentiment is, the action of the delegates for their
              respective districts, will best secure the end which we all have in view-as close an accord of our choice here, with the popular preferences as possible. I know that there are delegates from a number of districts that do not represent the feelings of their constituents; but that is not our matter. They were chosen by a majority of their people, and we must pay due regard to that
              consideration. We cannot reverse the decrees of the people; and if a choice is made that does not strictly accord with their preferences they must blame themselves.
            </p>
            <div className="speaker" id="sp5141"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">The Chair will suggest to the gentleman that there is a rule that stands in the way of his resolution. In elections, but one vacancy can be filled at a time.</p>
            <div className="speaker" id="sp5142"><span className="persName">Mr. RIVES</span>—</div>
            <p className="p-in-sp">That rule will not come in conflict with the resolution I offer. We can take them up one by one, and take twelve or fifteen different ballots.</p>
            <p className="p-in-sp">But to carry out the line of argument which I started upon; is it not true that the principle upon which the resolution is based, is that the voice of the people shall be represented as far as possible? Let me call your attention to the fact, that when it was proposed to have a Congress of the border slave States at Frankfort, the gentleman from Princess Anne [<span className="persName">Mr. WISE</span>] , moved an amendment to the report of the Committee on Federal Relations to the effect that the delegates to this Congress should be elected by the members of the respective districts.</p>
            <p className="p-in-sp">
              I maintain, sir, in every view of this matter, that the proper course <PageNumber num={602} /> to take in the absence of a direct expression of opinion by the people at the polls, is to leave to each delegation the selection of the representative for their respective districts; and in submitting that resolution, I am governed by the belief that it will be
              carrying out a principle that, so far as I am concerned, I never will disregard. I am unwilling to vote for a man, sir, to represent your district, because I am ignorant of the preferences of the people of that district. You, sir, and your colleagues from that district are more competent to decide than I am, or any others representing other districts here, what would be the choice
              which would most harmonize with the sentiments and wishes of the people there.
            </p>
            <p className="p-in-sp">I trust it will be the pleasure of the Convention to adopt this amendment, for I am satisfied that there is no better means of avoiding dissatisfaction among the people than that suggested in the amendment.</p>
            <div className="speaker" id="sp5143"><span className="persName">Mr. MORTON</span>—</div>
            <p className="p-in-sp">The gentleman from Prince George [<span className="persName">Mr. RIVES</span>] seems to misunderstand the character of the representation that we are to send to Montgomery. He seems to speak as though every congressional district would have a separate and independent vote, when it is known that in the present Congress at Montgomery each State has but a single vote.</p>
            <p className="p-in-sp">I say, then, as we are in revolution, it is important that we should look to the financial question; that we should not saddle upon the Confederate Treasury heavier expenses than are necessary. Five chosen from the different sections of the State will undoubtedly cast as intelligent a vote as the fifteen which the gentleman recommends.</p>
            <p className="p-in-sp">
              I am, therefore, of the opinion that the number should not exceed five. If, however, I am overruled in that, and the motion of the gentleman shall be adopted, I will go with him to the extent of voting for every one of the old delegates that I think this day represent the feelings of their constituents. But I will neither vote for the gentleman who represented the Norfolk district
              [Mr. MILLsoN] , nor the gentleman who represented the Rockingham district [<span className="persName">Mr. HARRIS</span>] , because from the lights now before me, I take it that they do not represent their districts.</p>
            <div className="speaker" id="sp5144"><span className="persName">Mr. COFFMAN</span>, of Rockingham—</div>
            <p className="p-in-sp">I understand from the resolution of the gentleman from Prince George [<span className="persName">Mr. RIVES</span>] , that it leaves no discretion to the Convention but to elect the late delegates in the United States Congress.</p>
            <div className="speaker" id="sp5145"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">The Chair will state that, in its opinion, this proposition cannot be voted upon by this Convention at all; and the <PageNumber num={603} /> Chair will suggest further that the object of the resolution might be attained in a very simple form.</p>
            <p className="p-in-sp">The amendment of the gentleman from Bedford [<span className="persName">Mr. GOODE</span>] is now open to amendment, and the question can be raised between fifteen and five. In that form, if the number of "five" shall be adopted, then the whole question as to number will be determined and nominations can be made.</p>
            <div className="speaker" id="sp5146"><span className="persName">Mr. COFFMAN</span>—</div>
            <p className="p-in-sp">
              Desiring to represent as nearly as I can the wishes of the people who sent me here, I shall be compelled to vote against our present representative, should his name be brought forward, because he does not represent the sentiments of the people of his district. He is my personal friend, but I cannot suffer that consideration to weigh where representative trust is concerned.
            </p>
            <div className="speaker" id="sp5147"><span className="persName">Mr. CAMPBELL</span>, of Washington county—</div>
            <p className="p-in-sp">There is so much of wisdom and practical good sense in the substitute, that I am induced to call the previous question upon it.</p>
            <p>The call was sustained, and the main question ordered to be put.</p>
            <div className="speaker" id="sp5148"><span className="persName">Mr. KILBY</span>—</div>
            <p className="p-in-sp">I ask for a division of the question.</p>
            <p>The vote was then taken on the first resolution of the substitute, which reads :</p>
            <p>"Resolved, That this Convention will now proceed to elect five representatives to represent the State of Virginia in the Congress about to assemble at Montgomery."</p>
            <div className="speaker" id="sp5149"><span className="persName">Mr. HALL</span>, of Marion—</div>
            <p className="p-in-sp">I call the yeas and nays upon that resolution.</p>
            <p>
              The call was sustained, and, the vote being taken, resulted-yeas 67, nays 24-as follows:<span className="note" id="Note54"><span className="noteLabel">1</span>The Journal adds Baldwin and Preston to the affirmative votes listed here.</span>
            </p>
            <p>YEAS-Messrs. John Janney [President]
              , Aston, Blow, Borst, Bouldin, Boyd, Branch, Brent, Bruce, Cabell, Campbell, Carter, Chapman, Conn, R. Y. Conrad, Critcher, Deskins, Dorman, Dulany, Early, Echols, Flournoy, French, Garland, Gillespie, Gravely, Goggin, John Goode, Jr., Holcombe, Marmaduke Johnson, Peter C. Johnston, Kent, Kilby, Lawson, Leake, McComas, Marshall, Miller, Montague, Morris, Morton, Moore, Nelson,
              Pendleton, Pugh, Randolph, Richardson, Robert E. Scott, William C. Scott, Seawell, Sharp, Sheffey, Sitlington, Slaughter, Speed, Spurlock, Alex. H. H. Stuart, Strange, Sutherlin, Tayloe, Tredway, Robert H. Turner, Tyler, Wickham, Williams-67.
            </p>
            <p>NAYS-Messrs. Ambler, Alfred M. Barbour, Baylor, Blakey, Boisseau, Cecil, Chambliss, Coffman, James H. Cox, Forbes, Ephraim B. Hall, Harvie, Haymond, Holladay, Isbell, Lewis, Moffett, Neblett, Rives, Staples, Thornton, Franklin P. Turner, Woods, Wysor-24.</p>
            <p>So the resolution was adopted.</p>
            <p>The question then recurred upon the adoption of the second resolution of the substitute which reads:</p>
            <PageNumber num={604} />
            <p>"Resolved, That no member of this body shall be elected as a representative in the said Congress."</p>
            <div className="speaker" id="sp5150"><span className="persName">Mr. MORRIS</span>, of Caroline—</div>
            <p className="p-in-sp">I call the yeas and nays upon that resolution.</p>
            <p>The call was sustained, and the vote being taken resulted-yeas 73, nays 19, as follows:</p>
            <p>YEAS-Messrs. John Janney [President]
              Ambler, Aston, A. M. Barbour, Baylor, Blakey, Boisseau, Borst, Bouldin, Boyd, Branch, Brent, Bruce, Cabell, Campbell, Carter, Cecil, Chambliss, Chapman, Coffman, Conn, Deskins, Dorman, Echols, Flournoy, Forbes, French, Garland, Gillespie, Goggin, John Goode, Cyrus Hall, Harvie, Haymond, Holladay, Isbell, P. C. Johnston, Kent, Kilby, Lawson, Leake, Marshall, Miller, Moffett, Montague,
              Morris, Morton, Moore, Neblett, Pendleton, Preston, Pugh, Randolph, Richardson, Seawell, Sharp, Sheffey, Sitlington, Slaughter, Southall, Spurlock, Staples, Alexander H. Stuart, Strange, Sutherlin, Tayloe, R. H. Turner, F. P. Turner, Wickham, Williams, Wilson, Woods, Wysor-73.
            </p>
            <p>NAYS-Messrs. Baldwin, Blow, Jr., R. Y. Conrad, James H. Cox, Critcher, Dulany, Early, Gravely, Holcombe, Marmaduke Johnson, Lewis, Nelson, Rives, Robert E. Scott, William C. Scott, Speed, Thornton, Tredway, Tyler-19.</p>
            <p>So the second resolution was adopted.</p>
            <p>The question next recurred upon the report of the Committee as amended.</p>
            <div className="speaker" id="sp5151"><span className="persName">Mr. MORTON</span>—</div>
            <p className="p-in-sp">I call the previous question upon that.</p>
            <p>The call was sustained, and the main question being put, it was decided in the affirmative.</p>
            <div className="speaker" id="sp5152"><span className="persName">Mr. BALDWIN</span>—</div>
            <p className="p-in-sp">I offer the following resolution:</p>
            <p className="p-in-sp">Resolved, That the Military Committee inquire into the expediency of providing-</p>
            <p className="p-in-sp">1. For the term of service of the volunteer forces.</p>
            <p className="p-in-sp">2. For the mode of compensation for horses and equipments of volunteer cavalry, and of fixing maximum and minimum limits of values.</p>
            <p className="p-in-sp">3. For uniformity in the number of company officers.</p>
            <p className="p-in-sp">4. For the adoption of some general system of army regulation.</p>
            <p>The resolution was adopted.</p>
            <div className="speaker" id="sp5153"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">I beg leave to present for the consideration of the Convention, the following communication from the Advisory Council.</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>COUNCIL CHAMBER,</p>
                <p>April 29th 1861.</p>
                <p>To the Honorable the President of the Convention of Virginia:</p>
                <p>
                  The Council of Three respectfully request the consideration of the <PageNumber num={605} /> Convention of the accompanying sketch of the plan of an ordinance, prepared in their supervision, in the place of and to supersede two ordinances adopted on the 27th inst., one entitled "An Ordinance concerning the Quartermaster's Department," the other
                  entitled "An Ordinance to provide a Board to whom shall be referred all claims for expenditures arising from the organization, equipment and support of the land and naval forces called or to be called out for the defence of the Commonwealth under the present emergency."
                </p>
                <p>
                  Some of the provisions of these ordinances indicate that they were drawn up without sufficient consultation between those engaged in their preparation; producing such a discrepancy and conflict between them as to render it difficult if not impossible to carry them into effect. It has, therefore, been thought advisable to combine both into one plan, such as is herewith presented, so
                  as to produce a consistent whole, capable of being carried into systematic and prompt execution.
                </p>
                <p>By order of the Council,</p>
                <p>P. F. HOWARD, Secretary.</p>
              </div>
            </div>
            <div className="speaker" id="sp5154"><span className="persName">Mr. MONTAGUE</span>—</div>
            <p className="p-in-sp">I move to lay that communication on the table for the present.</p>
            <p className="p-in-sp">
              I will state to the Convention that I have had a conversation with a member of the Advisory Council, upon the subjects referred to in that communication. It appears that two ordinances that you passed here on Saturday conflict one with the other, and at the suggestion of the Council, the Secretary of the Commonwealth and the Attorney General of the Commonwealth have prepared an
              ordinance which is now before you.
            </p>
            <p className="p-in-sp">I move to lay it on the table for the present.</p>
            <p>The motion was agreed to.</p>
            <p>The following is the ordinance referred to, as being drawn up by the Secretary of the Commonwealth and the Attorney General.</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>AN ORDINANCE for the better regulation of the Departments of the Army and Navy of Virginia and for the audit and settlement of accounts and claims arising in the present emergency for the defence of the Commonwealth. Passed April 30, 1861.</p>
                <p>
                  1. Be it ordained by the Convention of the Commonwealth of Virginia, That George W. Munford, J. R. Tucker and J. M. Bennett (any two of whom may act), shall constitute a Board of Commissioners, with authority to audit and settle, upon principles of law and justice, <PageNumber num={606} /> all accounts for expenses heretofore or hereafter to be
                  incurred in the assembling, arming, equipping, and maintaining troops, in the transportation of the same, of munitions of war, provisions and supplies of every kind, and for any other purpose connected therewith. Also, all expenses for officering, equipping and maintaining the Navy of the State, and for the pay of officers, seamen and marines therein, and all employees at Navy and
                  Dock Yards, including herein the expense of transportation of ordnance, ammunition and stores of every description, the purchase of vessels, and damages arising from seizure and detention of vessels, or from injury to, or use of cargo or property of any kind by land or naval forces, or under the order of the authorities of the State. Also, for all other incidental expenses incurred
                  or to be incurred in the defence of the Commonwealth, including herein the guarding, support, and transportation of prisoners, and all claims for arms, and for machinery for the manufacture of arms in the Public Armory, or others authorized by existing or future contracts. The said Board are further authorized to administer oaths or to require affidavits or other evidence,
                  necessary to the proof of all such claims. The Governor shall have power to supply any vacancy occurring in the said Board. In all cases arising under this section, the claimant shall have a right of appeal from the decision of the Board to the Governor of the Commonwealth.
                </p>
                <p>
                  2. The said Board shall be authorized to require of the several heads of the departments established by this Convention, an estimate of the sums necessary to be disbursed by them for the public service; and upon the same being furnished, to place from time to time, under the control of the proper disbursing officers in said departments, such sums of money as may be required, and as
                  may be properly applicable thereto, for the raising and support of the Army and Navy ordered into service, from time to time, under legal authority.
                </p>
                <p>
                  3. All accounts presented to the said Board under the first section shall be sustained by proper vouchers, and when allowed, shall be kept in a general account under proper heads for future settlement. When said accounts are so allowed, and when moneys are ordered to be disbursed by the Board under the second section, the said Board is hereby authorized to direct the Auditor of
                  Public Accounts to issue his warrants for the several amounts so allowed, to be paid out of any money in the treasury not otherwise appropriated.
                </p>
                <p>4. The Board shall adopt such regulations as it may approve for the security of the money to be disbursed under its direction under <PageNumber num={607} /> the second section, and for this end may require the heads of the several departments to submit such a system of regulations for that purpose as may be right and proper.</p>
                <p>
                  5. The accounts allowed under the first section, except claims for arms and machinery for the manufacture of arms authorized by existing laws, and the disbursements ordered under the second section, shall be certified in proper form, at the end of every quarter of the year, for payment, to the Government of the Confederate States of America, according to the Convention between this
                  Commonwealth and the said Confederate States, adopted on the 25th day of April, 1861, and when the same shall be received, it shall be paid into the treasury of this Commonwealth.
                </p>
                <p>6. The said Board shall, for their services, receive the sum of three dollars per day, of actual service, to be paid to each of them; and shall have power to appoint a clerk or clerks, and to allow to them such compensation as may be reasonable.</p>
                <p>
                  7. And for the better regulation of the quarter master's department, it is ordained, that in addition to field duties, it shall be the duty of the quartermaster general, and the subordinates in his department, when thereto directed by the Governor, to purchase military stores, artillery and camp equipage, and other articles required for the troops, and to procure and provide means
                  of transport for the army, its stores, artillery and camp equipage, and in order thereto, to report such an estimate, as may be proper to the said Board, constituted by the first section, of the cost thereof; and to disburse the sums under their control, by the said Board, to the best advantage. It shall be the duty of the quartermaster general to report to and account with such
                  Board, and in three months, or oftener, if required, in such manner as it shall prescribe, for all money and property which may pass through his hands or those of his subordinates, or may be disbursed by them as aforesaid, or may be in his or their care or possession. The quartermaster general shall be responsible for the regularity and correctness of all the returns of his
                  subordinates, in his department.
                </p>
                <p>8. The quartermaster general is authorized to appoint, with the approbation and under the direction of the Governor, as many forage wagon masters, and employ as many artificers, mechanics, and laborers as the public service may require.</p>
                <p>
                  9. It shall be the duty of the quartermaster's department to distribute to the army all clothing, camp and garrison equipage and other articles required for the army; and to prescribe and enforce, according <PageNumber num={608} /> to such regulations as shall be approved by the said Board, a proper accountability for such clothing, equipage and other
                  articles, so distributed to the army.
                </p>
                <p>
                  Every captain or commander of a company, detachment or recruiting station, or other officer, so receiving clothing, equipage, or other articles for the use of his command, or for issue to the troops, shall render to the quartermaster general quarterly returns thereof, according to the requisite forms, and accompanied by the proper vouchers, which returns and vouchers, shall be
                  included by the said quartermaster general, in his report and account with the Board hereby constituted.
                </p>
                <p>
                  It shall be the duty of all officers charged with the duty of issuing clothing, equipage, or other articles, to secure the same from waste and damage. In case of the loss of any such articles, or damage thereto, the value thereof, or final settlement with the officer, shall be charged against him and be deducted from his pay, unless he shall show, to the satisfaction of the
                  quartermaster general, and to the said Board, in passing on his accounts, by proper evidence, that such loss or damage occurred from unavoidable accident, or in actual service, without fault on his part, and was not the result of neglect on his part, and that he used due care and attention for the preservation of such articles.
                </p>
                <p>10. There shall be added to the quartermaster's department, military store-keepers, not exceeding three in number, who shall be appointed in the same manner as the quartermaster general.</p>
                <p>11. The other departments of the army and navy of this Commonwealth shall be subject in like manner, as the quartermaster's department, to the provisions of the second, third, fourth, fifth and seventh sections of this ordinance, insofar as their respective duties under existing or future laws make them applicable.</p>
                <p>
                  12. Whenever the quartermaster general, or the chief of any department of the army or navy of the Commonwealth shall be absent or unable to attend to his duty, the Governor is hereby authorized to empower some officer of such department to take charge thereof, and to perform the duty of said quartermaster general or chief, during said absence or inability: Provided, That no
                  additional compensation be allowed therefor.
                </p>
                <p>
                  13. The Governor is hereby authorized to appoint for the time being one or more special commissaries for the purpose of supplying by purchase or contract, and of issuing, or to authorize one or more officers in the quartermaster's department to supply and issue the <PageNumber num={609} /> whole or any part of the subsistence of the army, in all
                  cases, when, from the want of contractors, or a deficiency on their part, or any other cause, such measure may be necessary and proper to secure the subsistence of the army; and such special commissaries shall, whilst so employed, be entitled to the pay and emoluments of a deputy quartermaster general. The Governor shall have authority to appoint any additional officers, subject to
                  the control of the Legislature, whenever the chief of any department of the army and navy shall report that they are required for the public service, and the Board hereby constituted shall concur in such report and request the Governor to make such appointments.
                </p>
                <p>
                  14. No officer of the army or navy of Virginia, nor quartermaster, paymaster or commissary, nor any member of the Board hereby constituted shall be concerned, directly or indirectly, for himself, or as agent, or as partner, in any contract, sale, purchase, manufacturing or supply of any article, intended for or appertaining to any of said departments, except for and on behalf of
                  Virginia; nor shall any of said officers take or receive to his use and benefit any gain, commission or emolument for any negotiation or transaction of any business in any of said departments, other than may be allowed by law. If any disbursing officer mentioned in this ordinance shall be found delinquent in his accounts, the Board are hereby required to report the fact to the
                  Governor, who shall thereupon strike the name of said officer from the roll of the army and navy.
                </p>
                <p>15. Each and all of the officers named in this ordinance and connected with the departments of the army and navy, shall take the oaths prescribed by the Code of Virginia, chapter thirteen, sections one and three.</p>
                <p>
                  16. The Board hereby constituted shall require every storekeeper, paymaster and purser under this ordinance to execute bond, with sufficient surety, in a penalty to be fixed by the Board, conditioned according to law. The said Board, in their discretion, may require similar bonds of any officers of the army and navy referred to in this ordinance. Neither the quartermaster general,
                  nor the commissary general, nor any other head of a department shall be responsible for any loss which may accrue by the act or neglect of any subordinate officer in such department, who has been lawfully put in possession of money or other property under this ordinance, or any law of this Commonwealth.
                </p>
                <p>All bonds hereby required shall be payable to the Commonwealth of Virginia.</p>
                <PageNumber num={610} />
                <p>All paymasters, commissaries and storekeepers shall be subject to the rules and articles of war in the same manner as commissioned officers.</p>
                <p>17. The duties of the officers of departments in the army and navy shall, insofar as they may not be inconsistent with this or other ordinances of this Convention, be determined by the laws of this State and of the United States, which were in force within this Commonwealth on the seventeenth day of April, eighteen hundred and sixty-one.</p>
                <p>18. The ordinance passed by this Convention on the twenty-seventh day of April, eighteen hundred and sixty-one, entitled "A general ordinance to provide a Board," &amp;c., and an ordinance passed the same day, entitled "An ordinance concerning the quartermaster's department," are hereby repealed.</p>
                <p>19. This ordinance shall be in force from its adoption, subject to the right of this Convention or of the General Assembly to amend, alter or repeal the same.</p>
              </div>
            </div>
            <div className="speaker" id="sp5155"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">Nominations for delegates to Congress are now in order.</p>
            <div className="speaker" id="sp5156"><span className="persName">Mr. MONTAGUE</span>—</div>
            <p className="p-in-sp">I rise for the purpose of putting in nomination as the representative from my section, for I presume sections will be taken into consideration, the Hon. R. M. T. Hunter, of the county of Essex.</p>
            <p className="p-in-sp">The delegate from that county is detained from his seat and the duty, therefore, devolves upon me of presenting the name of that distinguished gentleman for this office.</p>
            <p className="p-in-sp">
              It is not necessary for me to say anything about the qualifications of Mr. Hunter to discharge this or any other office to which he may be chosen. He is known to the State and to the country; and I trust it will be the pleasure of the Convention to elect him. I have no doubt his sound, conservative principles, and mature wisdom will be of advantage in the new government as it was in
              the old.
            </p>
            <div className="speaker" id="sp5157"><span className="persName">Mr. BRANCH</span>—</div>
            <p className="p-in-sp">I move that the votes upon the election of these five commissioners be taken by yeas and nays.</p>
            <div className="speaker" id="sp5158"><span className="persName">Mr. WICKHAM</span>—</div>
            <p className="p-in-sp">I move to amend by inserting, "when there are more nominees than one."</p>
            <div className="speaker" id="sp5159"><span className="persName">Mr. CONRAD</span>, of Frederick—</div>
            <p className="p-in-sp">I approve the proposition of the gentleman from Petersburg [<span className="persName">Mr. BRANCH</span>]
              , I wish to record my vote, and I will state that I shall not consider myself bound by a vote of the Convention excluding members of the Convention from the right to be chosen as representatives to this Congress. I don't consider that the <PageNumber num={611} /> Convention had any right to decide that question. I have a right to vote for whom I please,
              and shall do so notwithstanding the order of the Convention.
            </p>
            <p>The question was taken on the amendment offered by <span className="persName">Mr. WICKHAM</span>, and it was rejected.</p>
            <p>The motion offered by <span className="persName">Mr. BRANCH</span> was then agreed to.</p>
            <div className="speaker" id="sp5160"><span className="persName">Mr. SPEED</span>—</div>
            <p className="p-in-sp">I suppose that there are a good many members of the Convention who were in ignorance of the fact that the Convention would determine upon the selection of but five delegates to the Southern Congress. I think that some little time at least should be given to those who desire to make the most judicious selection.</p>
            <p className="p-in-sp">I move that we postpone the elections until the evening session.</p>
            <p>The motion was lost.</p>
            <div className="speaker" id="sp5161"><span className="persName">Mr. KILBY</span>—</div>
            <p className="p-in-sp">I would suggest that the committees now in session be informed by the pages that the election of representatives to Congress is about to be entered upon, so that they may participate in the election if they deem it proper.</p>
            <div className="speaker" id="sp5162"><span className="persName">Mr. HALL</span>, of Marion—</div>
            <p className="p-in-sp">I offer the following resolution:</p>
            <p className="p-in-sp">"Resolved, That all further proceedings in reference to the election of representatives to represent Virginia in the Congress of the Confederate States, at Montgomery, be postponed until after the election in May next."</p>
            <div className="speaker" id="sp5163"><span className="persName">Mr. AMBLER</span>—</div>
            <p className="p-in-sp">I ask for the yeas and nays upon that resolution.</p>
            <div className="speaker" id="sp5164"><span className="persName">Mr. MONTAGUE</span>—</div>
            <p className="p-in-sp">Is not that resolution out of order? Have we not already resolved to go into the election? and if that resolution is adopted do you not reverse your action?</p>
            <div className="speaker" id="sp5165"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">The Chair rules the resolution out of order.</p>
            <p>The roll was then called on the election of Mr. Hunter, who was the only one in nomination, and resulted-</p>
            <p>R. M. T. Hunter 78</p>
            <p>John S. Millson 8</p>
            <p>F. F. Gholson 1</p>
            <p>W. C. Rives 1</p>
            <p>as follows:</p>
            <p>FOR HUNTER-Messrs. Janney [President]
              , Ambler, Aston, Alfred M. Barbour, James Barbour, Blakey, Boisseau, Borst, Bouldin, Boyd, Bruce, Cabell, Campbell, Carter, Cecil, Chambliss, Chapman, Coffman, Conn, James H. Cox, Critcher, Deskins, Dorman, Dulany, Echols, Flournoy, Forbes, French, Garland, Gillespie, Goggin, John Goode, Jr., Cyrus Hall, Harvie, Haymond, Holcombe, Holladay, Isbell, Marmaduke Johnson, Peter C. Johnston,
              Kent, Kilby, Lawson, Leake, Macfarland, Miller, Moffett, Montague., Morris, Morton, Neblett, <PageNumber num={612} /> Nelson, Preston, Randolph, Richardson, Rives, Robert E. Scott, William C. Scott, Seawell, Sheffey, Sitlington, Slaughter, Southall, Speed, Staples, Strange, Sutherlin, Tayloe, Thornton, Tredway, Robert H. Turner, Franklin P. Turner, Tyler,
              Wickham, Williams, Wilson, Woods, Wysor-78.
            </p>
            <p>FOR MILLSON-Messrs. Baylor, R. Y. Conrad, Early, Gravely, Ephraim B. Hall, Moore, Pugh, Alex. H. H. Stuart-8.</p>
            <p>FOR GHOLSON-Mr. Branch.</p>
            <p>FOR RIVES-Mr. Spurlock.</p>
            <p><span className="persName">Mr. HUNTER</span> having received a majority was declared duly elected.</p>
            <div className="speaker" id="sp5166"><span className="persName">Mr. THOS. S. FLOURNOY</span>, of Halifax—</div>
            <p className="p-in-sp">I rise to put in nomination the Hon. James A. Seddon, of Goochland.</p>
            <p className="p-in-sp">
              I feel that it is unnecessary for me to pronounce any eulogy upon that gentleman. He is a man who is true to the State of Virginia. He is a man whose reputation is co-extensive not merely with the State but the whole country; and is known everywhere as a man of the purest character and of the finest intellect. In this trying crisis upon our State, Mr. Seddon would be a great
              acquisition in our public councils, having not only the capacity but the integrity to guard, protect and advance every interest of Virginia in this new government; and I hope it will be the pleasure of this Convention to confer this appointment upon Mr. Seddon with unanimity.
            </p>
            <div className="speaker" id="sp5167"><span className="persName">Mr. MACFARLAND</span>—</div>
            <p className="p-in-sp">
              I beg leave to add to the nomination for this district the name of another distinguished gentleman. I am sure I need but name James Lyons, Esq., to ensure an admission on every side, of his ability and exalted worth. Mr. Lyons is well known personally, I presume, to all the members of this Convention. He is unquestionably a gentleman of high endowments, and of exceedingly noble spirit,
              and, indeed, I am not aware that there is a gentleman in the Commonwealth who would represent us upon this occasion, with more dignity, efficiency and grace than would that gentleman.
            </p>
            <p className="p-in-sp">I hope, therefore, that the high claims of Mr. Lyons will receive the endorsement of this Convention.</p>
            <div className="speaker" id="sp5168"><span className="persName">Mr. EARLY</span>—</div>
            <p className="p-in-sp">I rise for the purpose of putting in nomination the Hon. Wm. C. Rives of the county of Albemarle.</p>
            <p className="p-in-sp">It is not necessary for me to say one word in behalf of that gentleman, as he is well known to every gentleman here and throughout the State and the country.</p>
            <p className="p-in-sp">I make that nomination for the Piedmont district.</p>
            <div className="speaker" id="sp5169"><span className="persName">Mr. DORMAN</span>—</div>
            <p className="p-in-sp">I wish to know when gentlemen make nominations of this kind, whether they have any authority from those whom they nominate, to present their names here for these positions, with a certainty <PageNumber num={613} /> almost of being defeated. I would cheerfully vote for Mr. Rives if I supposed he stood a good chance of being elected.</p>
            <div className="speaker" id="sp5170"><span className="persName">Mr. MOORE</span>, of Rockbridge—</div>
            <p className="p-in-sp">
              I shall support the nomination of Mr. Rives whether he shall be elected or no. I want to know whether we are to have a strict party representation in that body. I hoped that there was an end put to these party divisions in the State. If there is not an end put to them, there will be a degree of excitement such as has never been known before. I am unwilling to confer these offices upon
              gentlemen upon any particular stripe of party.
            </p>
            <div className="speaker" id="sp5171"><span className="persName">Mr. MORTON</span>—</div>
            <p className="p-in-sp">
              I come from the Piedmont region, and I will speak what I believe to be the unanimous voice and sentiment of my people. Whilst I have the profoundest respect for the intellect and private characters of the three distinguished gentlemen who have been put in nomination, I believe that the Hon. James A. Seddon would receive ninety-nine in one hundred of the votes of my people in opposition
              to the Hon. Mr. Rives or Mr. Lyons, both of whom I am proud to call my personal friends.
            </p>
            <p className="p-in-sp">My friend from Rockbridge [Mr. MOORE] is greatly mistaken, in supposing that we are regarding party lines here. I voted with my friend from Rockbridge for Bell and Everett for the Presidency and Vice-Presidency, and I proclaimed to my constituents that the time had arrived when we should disregard all party considerations and look alone to country.</p>
            <p className="p-in-sp">I regret that my friend from Rockbridge [Mr. MOORE] , did not</p>
            <p className="p-in-sp">
              listen to the admonition of that Irish patriot from whose language I quoted this morning. He said that the only divisions the people of England ought to have were those existing between Great Britain and France, and I take the liberty of applying that language here, and saying that the only parties that we ought to have were the parties of the North and South. There should be no
              divisions here, save those which are designated by Mason and Dixon's line. I ask, if you take this strict sectional consideration as between the North and South as the test of election, who, in relation to those distinguished gentlemen, is the most prominent, not only in the State of Virginia, but in the whole Union, as his efforts to accomplish, what we now enjoy, freedom from the
              thraldom of Northern tyranny? I regard the Hon. James A. Seddon, as at least, among the most active and prominent in this glorious work. Who doubts the unquestioned ability of that gentleman? No one, I imagine, can refer to a gentleman of finer ability or more unblemished character in the State of Virginia or anywhere else. Whilst I fully endorse the high character and abilities
              <PageNumber num={614} /> of the other gentlemen in nomination, I am compelled to vote for Mr. Seddon, khowing him to be the choice of a vast majority of my people.
            </p>
            <p className="p-in-sp">I hope it will be the pleasure of the Convention to elect him to the position for which he is nominated.</p>
            <div className="speaker" id="sp5172"><span className="persName">Mr. COFFMAN</span>, of Rockingham—</div>
            <p className="p-in-sp">I desire to put in nomination the Hon. Shelton F. Leake for that district.</p>
            <p className="p-in-sp">I will attempt no eulogy of Mr. Leake. His ability as an orator and his devotion to the South are known to all parties. I will, therefore, content myself with a mere announcement of his name for that district.</p>
            <div className="speaker" id="sp5173"><span className="persName">Mr. FLOURNOY</span>—</div>
            <p className="p-in-sp">
              In making the nomination which I did make, I had no reference on earth to party. I made it with no concerted arrangement with anybody. I have never, since I first entered into politics, acted with Mr. Seddon. I have never agreed with him, that I am aware of, in party politics, at any time, or in reference either to measures or men; and in making the nomination, I intended, so far as I
              was concerned, to show, that in this crisis, that demanded the highest and noblest patriotic efforts on the part of the State in reference to our safety, I was prepared here to rise above party, and to nominate a gentleman whom I thought really qualified to represent the State.
            </p>
            <p className="p-in-sp">
              We have gone, with my entire approbation and concurrence, outside of this Convention to select candidates for those offices; and while I believe there are men in this body who, whether the number chosen was eighteen, fifteen or five, would represent this State as ably as any others in it, yet I concurred with the suggestion made, that, placing ourselves within the Southern Confederacy,
              a proper regard for delicacy required that we should send members chosen outside of this body. I was looking to the fitness of men only in making the nomination which I did, and not to anything like party politics. I will do anything; I will yield to any demand that is made from any section of Virginia in favor of any proper and competent man that may be presented to form one of the
              five to be sent to Montgomery. I intended and hoped to be able to sustain the nomination of William C. Rives as one of those gentlemen whose reputation, character and public services do not confine him to any section of our State; but whose character, reputation and services cover the whole State and render him peculiarly fitted for the position for which he is nominated. But if
              gentlemen will insist upon his election, I shall be forced to the disagreeable alternative of voting against him. But I do <PageNumber num={615} /> insist that we shall discard all party feeling and seek to represent that state of feeling which now exists in the Piedmont country, by combining, if possible, that whole region in one single advocacy of
              secession, and one desire to unite with the Southern Confederacy; and, I believe, there is no man in that section so peculiarly qualified to represent that feeling and to establish that unity of sentiment as James A. Seddon.
            </p>
            <p className="p-in-sp">In regard to the distinguished gentleman nominated by my friend from the city of Richmond [<span className="persName">Mr. MACFARLAND</span>] , I understand-if section is to be considered-that Mr. Lyons belongs to the tide-water section. He resides in Henrico, and that county, I believe, is a part of the tide-water section. If, then, considerations of section are to enter into these elections, Mr. Lyons is not eligible upon that basis to represent the Piedmont district.</p>
            <p className="p-in-sp">I hope it will be the pleasure of the Convention to elect Mr. Seddon; and I trust that, while we are ignoring party politics, my friend from Rockbridge [<span className="persName">Mr. MOORE</span>] will not suppose that we are doing the West any injustice.</p>
            <div className="speaker" id="sp5174"><span className="persName">Mr. MACFARLAND</span>—</div>
            <p className="p-in-sp">I should hardly have troubled the Convention again, but for the allusion made to party politics.</p>
            <p className="p-in-sp">
              I need not say to this Convention, to which I am personally known, that in venturing to present the distinguished claims of Mr. Lyons, I was actuated by no party motives. It is very well known that I have differed on all national political questions for years with my friend Mr. Lyons. He was not, therefore, recommended to me by party considerations. He was recommended to me by his high
              intellectual and moral qualifications. There is not in the whole of the Commonwealth a more intelligent, able and skilful advocate of his own opinions than Mr. Lyons. But, I am unwilling to press the nomination of that gentleman upon the consideration of the Convention if he resides within the Tide-water District. It occurs to me that he is above that District. He lives about a mile
              above the city; and according to my idea of the boundary of the Tide-water District that is beyond it. If, however, gentlemen refuse to vote for him on that ground, I would hardly consider it just to him to press his claims upon the consideration of this body. My friend from Charlotte
              [<span className="persName">Mr. BOULDIN</span>] , informs me that under the arrangement of the districts, the county of Henrico is assigned to the Tide-water District. I therefore withdraw the nomination of Mr. Lyons.</p>
            <div className="speaker" id="sp5175"><span className="persName">Mr. COFFMAN</span>—</div>
            <p className="p-in-sp">Upon consultation with some friends, I am induced to withdraw the nomination of Mr. Leake.</p>
            <PageNumber num={616} />
            <div className="speaker" id="sp5176"><span className="persName">Mr. HOLLADAY</span>, of Norfolk county—</div>
            <p className="p-in-sp">There is one observation that has fallen from the gentleman from Halifax [<span className="persName">Mr. FLOURNOY</span>] , which in my judgment needs some reply.</p>
            <p className="p-in-sp">
              If I understand the gentleman from Halifax, his object was to ignore party altogether in the nominations which are to be made before this body, and hence his nomination of the distinguished gentleman from Goochland. I understood that gentleman as referring to the Hon. Wm. C. Rives of Albemarle in terms of the highest commendation, but at the same time expressing a preference for Mr.
              Seddon because of the change of public sentiment in that region of the State under the exigencies and circumstances of the times. Am I right or not?
            </p>
            <div className="speaker" id="sp5177"><span className="persName">Mr. FLOURNOY</span>—</div>
            <p className="p-in-sp">
              Yes, sir, I do believe that Mr. Seddon represents now more perfectly the opinions of the people of the Piedmont country than Mr. Rives as far as I know Mr. Rives' opinions. But I speak from my knowledge of the opinions of both at a comparatively recent period. I would say further that, not expecting that the selections would be made upon the basis of geographical positions, I expected
              to be able to vote both for Mr. Seddon and Mr. Rives.
            </p>
            <div className="speaker" id="sp5178"><span className="persName">Mr. HOLLADAY</span>—</div>
            <p className="p-in-sp">
              Still I beg to call the gentleman's attention to his own argument and the inevitable inference to be drawn from it. He supposes that because Mr. Seddon is the representative of a certain prevailing sentiment, and because Mr. Rives was the representative of a past sentiment, that, therefore, in view of the change which the public sentiment has undergone, Mr. Seddon is a fairer exponent
              of that sentiment than Mr. Rives.
            </p>
            <p className="p-in-sp">
              Sir, make the application of this argument, and what is to be the result? That no man within the broad limits of this Commonwealth whether in this Convention or out of it who has held conservative sentiments is to receive the support of this Convention for any position. If I understand right, the gentleman who makes this nomination and this argument would himself come under this
              principle of exclusion which he has laid down, for if I understand right, that gentleman was elected to his seat in this Convention as a conservative, Union man. I say that whatever may have been the gentleman's intention, and I give him credit for acting from the best and purest of motives, the inevitable effect of his argument is to subvert the very principle upon which he is acting;
              for if this is to apply, it creates at once a party spirit to the exclusion of every gentleman who held conservative sentiments in the past. In as pure a spirit as animated any gentleman here, I voted for Mr. Hunter when his name was in nomination, <PageNumber num={617} /> and I had hoped that the same course of conduct would be pursued by gentlemen on
              the other side of the House.
            </p>
            <div className="speaker" id="sp5179"><span className="persName">Mr. MORTON</span>—</div>
            <p className="p-in-sp">It is all one sided now.</p>
            <div className="speaker" id="sp5180"><span className="persName">Mr. HOLLADAY</span>—</div>
            <p className="p-in-sp">It may be all one sided, and it occurs to me that it is all one sided.</p>
            <p className="p-in-sp">
              It seems to me that matters are taking a turn here which are not produced by mere accident. I hold that there are conservative gentlemen within this Commonwealth of talents, as exalted, of virtues as high, and patriotism as pure as can be found upon the other side, and if in the progress of this movement, it shall turn out that there is a disposition here accidentally to exclude all of
              one side, I must be pardoned if I look upon the movement with suspicion. I will not lend myself to it. I do hope that gentlemen will rise above a mere scramble for office and party considerations here, and that they will have an eye single to the harmonizing of the people of the Commonwealth.
            </p>
            <p className="p-in-sp">
              I hope that patriotic views and efforts would prevail here, and that in this emergency when it is so necessary that all party lines should be cancelled, gentlemen would so regulate their actions as to preclude even a suspicion that party motives actuated them. I do hope that it will be the pleasure of the Convention in making a choice of representatives to cast aside all party
              considerations.
            </p>
            <div className="speaker" id="sp5181"><span className="persName">Mr. MOORE</span>, of Rockbridge—</div>
            <p className="p-in-sp">As to the suggestion of my friend to the left [<span className="persName">Mr. FLOURNOY</span>] that Mr. Seddon represents what are now the sentiments of the people of the Piedmont district, I would remark, that in my opinion this is a mistake. It will be found, upon investigation, that it is not these alone who concur with the extreme views of the gentleman from Goochland [<span className="persName">Mr. SEDDON</span>] that are now marching to the defence of the State. There are thousands who condemn what has been done, but they find themselves in a position in which they must support the State in this contest; and they can do that without going into those extreme notions which <span className="persName">Mr. SEDDON</span> is known to represent.</p>
            <div className="speaker" id="sp5182"><span className="persName">Mr. EARLY</span>—</div>
            <p className="p-in-sp">Having nominated <span className="persName">Mr. RIVES</span>, I deem it proper to make a remark or two.</p>
            <p className="p-in-sp">My friend from Halifax [<span className="persName">Mr. FLOURNOY</span>] , said he had no idea that these appointments were to be made from sections. We have agreed to elect five representatives corresponding with the five sections of the State.</p>
            <p className="p-in-sp">
              In order to produce harmony, it is necessary, in my opinion, that the sections should be represented. It seems to me to be the proper mode of representations. We have representatives in the Legislature <PageNumber num={618} /> by counties, and in Congress by districts; and when we send five representatives to the Southern Congress, all parts of the State
              ought to be represented. When the gentleman from Middlesex
              [<span className="persName">Mr. MONTAGUE</span>] nominated Mr. Hunter, he said he put him in nomination as the representative from his section.</p>
            <p className="p-in-sp">
              Now, if we intend to elect Mr. Rives at all, it will be only proper to elect him from the Piedmont section. Suppose you elect him from the Valley section, will the people and delegates from that section be satisfied? Suppose you nominate him from the Eastern section, will the people and delegates from that section be satisfied? If you elect him from the North-western portion of the
              State, will the people there be satisfied. I imagine not. We have already a great deal of dissatisfaction existing in that section; and would not the election of any man from the East to represent that section increase that dissatisfaction. We have elected one delegate from Eastern Virginia corresponding in his views with <span className="persName">Mr. SEDDON</span>. If that party in the
              State, which was originally the Union party, is to be ignored, I ask how can the feelings and wishes of the people of Virginia be represented? If this State has to depend in the councils of this Confederacy and for its defence in the field, upon these gentlemen who represent the feelings of <span className="persName">Mr. SEDDON</span>, the State will be in a deplorable condition.
            </p>
            <p className="p-in-sp">I trust that we shall have harmony everywhere; but I would remark that if gentlemen who were Union men, who thought our country would be best protected and most benefited by remaining in the Union, are to be excluded, then, sir, you will have the whole control of the State in the hands of what was originally a very small minority.</p>
            <div className="speaker" id="sp5183"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">There are now in nomination the Hon. James A. Seddon and the Hon. Wm. C. Rives. If no other nominations are made, the vote will be taken upon these two nominees.</p>
            <div className="speaker" id="sp5184"><span className="persName">Mr. SUTHERLIN</span>—</div>
            <p className="p-in-sp">
              Entertaining, as I do, the opinion that neither James A. Seddon nor Wm. C. Rives represent, properly, the sentiments of the section from which I come, I will nominate a gentleman who, I think, will represent the feelings and sentiments of these people-I mean the Hon. Thomas S. Bocock. I believe, sir, that the views of that gentleman have been much more popular than either those of Mr.
              Seddon or Mr. Rives.
            </p>
            <p className="p-in-sp">I believe his election to this position would give general satisfaction, and I hope it will be the pleasure of the Convention to confer this office upon him.</p>
            <div className="speaker" id="sp5185"><span className="persName">Mr. SPEED</span>—</div>
            <p className="p-in-sp">The nomination just made makes it not improper in my opinion that I should say a word upon this subject.</p>
            <p className="p-in-sp">
              If it had been determined to send fifteen representatives to the <PageNumber num={619} /> Southern Congress, I should myself, with pleasure, in deference to the wishes, as I understand them, of the people of that district, have cast my vote for Mr. Bocock. I shall not vote for him now, however, because I don't think all things considered that he has the
              same claim for this position as the gentleman for whom I think proper to cast my vote.
            </p>
            <p className="p-in-sp">
              I am one of those who think that party ought to be ignored; but the question is, how shall we ignore it? In what form is it best that we should ignore it? It seems to me, not by alluding to politics, not by referring to these gentlemen who but recently were regarded as extremists, and regarding those who may now vote for them as extremists; but by coming up like patriots and standing
              upon one common platform, having in view only the defence of the Commonwealth and the proper vindication of her rights. This is the means by which we can most effectually ignore all party considerations, and it is thus we can but meet the terrible emergency which is upon us.
            </p>
            <p className="p-in-sp">
              For myself, in the vote that I have cast, I have not looked to section or party; nor do I mean to do it in the votes I mean to cast. If all our representatives on this occasion were selected from the city of Richmond or any other peculiar locality, and they possessed the qualifications which, in my opinion, rendered them peculiarly fitted for the positions to which they were to be
              elevated, I would feel it my privilege and duty to cast my vote for them.
            </p>
            <p className="p-in-sp">
              I voted for Mr. Hunter not because he came from any particular locality or district; but because I believed him to possess the qualifications necessary for a representative in the new Congress. I shall vote for Mr. Seddon because I regard him as having claims very little beneath Mr. Hunter, and qualifications perhaps fully equal to him. I will vote for him, not because he comes from
              the Piedmont district, but because he is entitled to the position on the score of precedence in the advocacy and support of the principles and opinions which we all now recognize and maintain. He is ahead of those who advocated Union principles. We have come up to his position, and it is but just that he should now have the reward of his early advocacy of these principles at least to
              the extent of a recognition of his claims of precedence over those who held entirely opposite positions in the past.
            </p>
            <p className="p-in-sp">We occupy now the position that he occupied some time since-years ago, in fact, and we should, therefore, yield to him precedence in this matter. These considerations I advance independent of his other peculiar qualifications, which all must admit to be very superior.</p>
            <PageNumber num={620} />
            <p className="p-in-sp">I think further that, other things being equal, we should elect two Breckinridge men, two Bell men and one Douglas man, always regarding it as a pre-requisite that they shall be fully qualified. For my part I was determined to vote for Mr. Hunter, Mr. Seddon and then for Mr. Rives.</p>
            <p className="p-in-sp">The gentleman from Franklin [<span className="persName">Mr. EARLY</span>]
              , said that dissatisfaction would be caused if Mr. Rives were elected from the Valley District or the North-western District. I don't believe in that principle. We are here to get the best men to represent the whole State. If we ignore the democratic party, dissatisfaction will be given. If we ignore the old whig party, certainly dissatisfaction will be created among them. But, sir,
              however that may be, we should not ignore the party which was ahead of all others in the advocacy of the principles which are now generally recognized.
            </p>
            <div className="speaker" id="sp5186"><span className="persName">Mr. COFFMAN</span>—</div>
            <p className="p-in-sp">I desire to know whether it is the wish of this Convention to select all from the East.</p>
            <p>Cries of no, no, no.</p>
            <div className="speaker" id="sp5187"><span className="persName">Mr. SPEED</span>—</div>
            <p className="p-in-sp">I merely stated that I would vote for all from the city of Richmond if they possessed the qualifications which I considered necessary, just to illustrate the principle that I was in favor of; namely, taking the best men without regard to section.</p>
            <div className="speaker" id="sp5188"><span className="persName">Mr. HOLCOMBE</span>, of Albemarle—</div>
            <p className="p-in-sp">I desire to say a word.</p>
            <p className="p-in-sp">
              There is no gentleman in the Convention who has a higher appreciation of the abilities of Mr. Rives or who has more confidence in his patriotism than I have. He is from my county, and it would give me great pleasure to cast my vote for him for any public office. I don't know, however, what is Mr. Rives' position on this great issue to-day. I know there are many gentlemen who are
              willing to sustain the ordinance of secession who are not willing to sustain our action adopting the Constitution of the Confederate States Government. I am very loth to believe that any vote here shall be regarded as being of a party character. The Convention has embarrassed me seriously by its action. There are men upon this floor who have been identified with the Whig party in all
              its history, men who voted for Bell and Everett, any one of whom, and all of whom I would most cheerfully confide in as my representative at Montgomery; but I am compelled to exclude them from my choice. I know where they are. I know their opinions. I would most willingly vote for any of them; but I cannot do it now. I am compelled to go outside of the Convention for representatives. I
              don't know how gentlemen outside of the Convention stand with reference to the action recently taken by <PageNumber num={621} /> the Convention in reference to our union with the Confederate States. I make these remarks as a reason why I cannot vote for Mr. Rives.
            </p>
            <div className="speaker" id="sp5189"><span className="persName">Mr. BRANCH</span>, of Petersburg—</div>
            <p className="p-in-sp">I said I would vote for Mr. Seddon, but I am a little shaken in that determination, not that I don't believe Mr. Seddon to be as true as any man; but I am unwilling to cast any vote savoring of a party character.</p>
            <p className="p-in-sp">
              We elected Mr. Hunter, who was a straight-out secessionist. I did not vote for him, however. We are now called upon to vote for another straight-out secessionist, and Judge Brockenbrough is spoken of as another representative, who is also a straight-out secessionist. Russell, of Wheeling, is also spoken of, and he, too, is a straight-out secessionist. I was a conservative Union man-a
              Douglasite, and I am opposed to electing all Breckinridge men. There are Bell men and Douglas men who are as true to the Southern Confederacy as any that ever lived. Go to my town, sir, and you will find that those who cried out for secession are not those who now constitute the military contributions from there to your army. Those who were designated during the last campaign Black
              Republicans-those I mean who supported Douglas-are now in the army by the thousand with sword and musket in hand, and I cannot submit to see that class ignored here in the selection of representatives to Montgomery. Some regard should be paid to their wishes, and so far as my vote goes, it shall be done.
            </p>
            <p className="p-in-sp">If this jealous and unjust discrimination is carried out here, a great deal of mischief must result from it. I shall go for Mr. Seddon with the greatest pleasure imaginable; but if I do not hear a disclaimer of a purpose to elect a majority of straight-out secessionists, I will vote against him.</p>
            <div className="speaker" id="sp5190"><span className="persName">Mr. HALL</span>, of Wetzel—</div>
            <p className="p-in-sp">
              I was very much opposed to the introduction of anything like party discussions here, or to any reference to party politics. I was a Douglas Democrat, but I would scorn to rise here and claim any special favor in that account. I cast all these considerations behind me. They belong to the past, and should be permitted to remain with the past. I am now acting for Virginia and the South,
              and not for party. It is to maintain the interests of my State and section that all my energies are now devoted, and not to promote party ascendancy. I neither identify myself with the Breckinridge, Douglas or Bell factions, but with the South and her interests. We should all look to the general concerns of our section and not to party. We are embarked in a cause of momentous
              importance to us all, and we should discard all other considerations save <PageNumber num={622} /> that which can best tend to secure the success of that cause, and accomplish the great end which we have in view-the establishment of our independence and our emancipation from the galling tyranny of the Goths and Vandals of the North. Let us, in short,
              select the men who are most competent to represent us, without regard to their past political associations.
            </p>
            <p className="p-in-sp">This war in which we are engaged in is far from being a trifling one. No, sir. We are going to have a terrible conflict, the end of which no man can now venture to predict; and it is for us to see that we do our duty as becomes a people engaged in a struggle of this character.</p>
            <p className="p-in-sp">
              In choosing men for Congress, let us secure that kind of material that will best subserve the great purpose we have in view. Let us select men of high character, judgment and discretion. As regards the gentleman nominated, I must say, that I cannot give my vote for Mr. Rives, for the reason that I do not know how he stands, and for an additional reason that I don't believe his heart is
              with us in this movement, and that, moreover, he is one of those who would be willing that Virginia should furnish her quota of troops to the Lincoln Government to subjugate and destroy our brethren in the South.
            </p>
            <p className="p-in-sp">As to a choice between Bocock and Seddon, I am at a loss to make any. Under all the circumstances, however, I regard Mr. Seddon as perhaps the most eligible, and I shall therefore vote for him.</p>
            <div className="speaker" id="sp5191"><span className="persName">Mr. WICKHAM</span>—</div>
            <p className="p-in-sp">
              I desire to say that I came into the Convention this morning somewhat under the impression that party lines would be obliterated henceforth and forever. With this impression I came to a conclusion as to whom I should vote for for representatives to the Confederate Congress. I determined first to vote for Mr. Hunter and Mr. Seddon, and then for Mr. Rives. And here I will remark in
              respect to the doubt expressed just now in reference to Mr. Rives' position, by the gentleman from Albemarle
              [<span className="persName">Mr. HOLCOMBE</span>]
              , that I recently met Mr. Rives and took occasion to converse with him on the subject of the great issues now before the country; and I am enabled to say that there is no more thorough Virginian within the limits of the State than Wm. C. Rives, and there is no man within the same limits more determined to advance the interests of the State, and promote the permanent organization and
              establishment of the Southern Confederacy than he is.
            </p>
            <p className="p-in-sp">
              It seems to me that those who were in the majority at one time are entitled to have their feelings represented in this Congress. There is not one here, so far as I know, who has not pledged himself to defend <PageNumber num={623} /> the interests of the State; and, if our friends are denied a position in the Congress of the Confederate States, we will
              content ourselves with becoming food for powder.
            </p>
            <p className="p-in-sp">I trust, however, that it will be the pleasure of the Convention to give us a due representation in that Congress, and not to discard those who may, at one time, have differed from the majority here.</p>
            <div className="speaker" id="sp5192"><span className="persName">Mr. MACFARLAND</span>—</div>
            <p className="p-in-sp">Great injustice has been done to <span className="persName">Mr. RIVES</span>, by the suggestion of a doubt, as to his fidelity to the State in her new attitude.</p>
            <div className="speaker" id="sp5193"><span className="persName">Mr. NEBLETT</span>—</div>
            <p className="p-in-sp">Is he in favor of the Southern Confederacy?</p>
            <div className="speaker" id="sp5194"><span className="persName">Mr. MACFARLAND</span>—</div>
            <p className="p-in-sp">
              I had the pleasure of an interview recently with Mr. Rives in my own house, and from what I could learn of his sentiments in the course of that interview, I believe that there is not a man within the limits of the Commonwealth more loyal, more devoted, or who will prove more efficient, if he has the pleasure of representing his State, than will Mr. Rives. He was a Union man; but he
              bows with all grace to the decision of the State. His heart is now in the issue. Give him a chance and he will furnish ample proof of his fidelity and loyalty to the State, as well as of his efficiency in promoting her interests and those of the Confederacy at large.
            </p>
            <p className="p-in-sp">There seems to be a manifest propriety, under existing circumstances, in so disposing of the offices in question as that speculation will be put to rest, and that the entire public will be satisfied that we can be just, in according the honors of the Commonwealth to those who have heretofore differed from us in sentiment.</p>
            <div className="speaker" id="sp5195"><span className="persName">Mr. TREDWAY</span>—</div>
            <p className="p-in-sp">I wish to inquire whether the previous question will apply to this debate?</p>
            <div className="speaker" id="sp5196"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">Yes sir.</p>
            <div className="speaker" id="sp5197"><span className="persName">Mr. TREDWAY</span>—</div>
            <p className="p-in-sp">I have risen for the purpose of calling it.</p>
            <p className="p-in-sp">Of all things, those most difficult of accomplishment by this Convention, are to elect persons to office. I might refer to the difficulty and delay which was encountered in the organization of this body, in proof of this statement.</p>
            <p className="p-in-sp">I will now call the previous question.</p>
            <p>The call was sustained.</p>
            <div className="speaker" id="sp5198"><span className="persName">Mr. HOLCOMBE</span>—</div>
            <p className="p-in-sp">After the statement which has been made with reference to Mr. Rives, if the gentleman [<span className="persName">Mr. EARLY</span>] will withdraw his nomination from the Piedmont section, I will pledge myself to vote for him on another ballot.</p>
            <div className="speaker" id="sp5199"><span className="persName">Mr. MACFARLAND</span>—</div>
            <p className="p-in-sp">That seems to be in opposition to the idea of the Convention. I have myself withdrawn the nomination of Mr. <PageNumber num={624} /> Lyons, because the sentiment of the Convention seemed to be favorable to sectional divisions in choosing representatives.</p>
            <p className="p-in-sp">This nomination was made by the gentleman from Franklin and not by me.</p>
            <div className="speaker" id="sp5200"><span className="persName">Mr. SUTHERLIN</span>—</div>
            <p className="p-in-sp">At the suggestion of some friends from Mr. Bocock's neighborhood, I withdraw the name of that gentleman, with the understanding that no member of the late Congress is to be chosen.</p>
            <p>
              The vote was then taken between the Hon. James A. Seddon and the Hon. Wm. C. Rives, and resulted for Wm. C. Rives 61, for James A. Seddon 34-as follows:
              <span className="note" id="Note55"><span className="noteLabel">2</span>The Journal states there were 62 votes for Rives, and adds Richardson to those listed here. Among the votes for Seddon, the Journal has neither Forbes nor Richardson, but it adds Cyrus Hall to those listed in the Proceedings.</span>
            </p>
            <p>RIVES-The President [John Janney]
              , Messrs. Aston, A. M. Barbour, James Barbour, Baylor, Blow, Borst, Boyd, Branch, Brent, Campbell, Caperton, Carter, Cecil, Robert Y. Conrad, James H. Cox, Critcher, Deskins, Dorman, Dulany, Early, Forbes, French, Garland, Gillespie, Gravely, Goggin, E. B. Hall, Holcombe, Holladay, Marmaduke Johnson, Peter C. Johnston, Kilby, Lawson, McComas, Macfarland, Marshall, Moffett, Moore,
              Neblett, Nelson, Pendleton, Preston, Pugh, Randolph, Rives, Robert E. Scott, William C. Scott, Sharp, Sheffey, Sitlington, Slaughter, Southall, Spurlock, Staples, Alex. H. H. Stuart, Strange, Sutherlin, Tayloe, Tredway, Wickham-61.
            </p>
            <p>SEDDON-Messrs. Ambler, Blakey, Boisseau, Bouldin, Bruce, Cabell, Chambliss, Chapman, Coffman, Conn, Echols, Flournoy, Forbes, John Goode, Jr., L. S. Hall, Harvie, Haymond, Isbell, Kent, Leake, Miller, Montague, Morris, Morton, Richardson, Seawell, Speed, Thornton, R. H. Turner, F. P. Turner, Tyler, Williams, Wilson, Woods, Wysor-34.</p>
            <p>Mr. Rives having received a majority of all the votes cast, was declared duly elected.</p>
            <p>It is due to Mr. Seddon to state that the original state of the vote was-for Mr. Rives, 48; for Mr. Seddon, 47. Several members afterwards changed their votes, in order to make the election of Mr. Rives as nearly unanimous as possible.</p>
            <div className="speaker" id="sp5201"><span className="persName">Mr. BORST</span>, of Page—</div>
            <p className="p-in-sp">I beg leave to put in nomination from the Valley district, the Hon. James M. Mason, of the county of Frederick.</p>
            <p className="p-in-sp">It would be a work of supererogation for me to say anything of the qualifications of Mr. Mason. His long experience in public life, and his known zeal for Southern Independence, furnish more ample assurances of his fitness to discharge the duties of the office of the representative in this Congress than any I can give.</p>
            <p className="p-in-sp">I will, therefore, content myself with the mere presentation of his name for that position.</p>
            <div className="speaker" id="sp5202"><span className="persName">Mr. MOORE</span>, of Rockbridge—</div>
            <p className="p-in-sp">I nominate the Hon. John W. Brockenbrough, of the county of Rockbridge. Mr. Brockenbrough never <PageNumber num={625} /> agreed with me in politics; but I am happy, nevertheless, to bear testimony to his eminent qualifications for this or any other office to which he may be elevated.</p>
            <div className="speaker" id="sp5203"><span className="persName">Mr. DORMAN</span>—</div>
            <p className="p-in-sp">I second the nomination of Judge Brockenbrough, and I undertake to say that he will prove to be as faithful and efficient a representative as can be chosen.</p>
            <div className="speaker" id="sp5204"><span className="persName">Mr. GOODE</span>, of Bedford—</div>
            <p className="p-in-sp">I cannot allow this opportunity to pass without bearing my humble testimony to the fitness of the nomination just made by my friend from the county of Rockbridge and seconded by my other friend from the same county.</p>
            <p className="p-in-sp">
              It has been my good fortune in the past to know Judge Brockenbrough well, and I say here in my representative capacity that there is no man within the limits of this broad Commonwealth whom I would more delight to honor. It is enough for me to say that he is a first rate specimen of the old Virginia gentleman whose ancestry illustrated the annals of Virginia in the pure and palmy days
              of the old Commonwealth.
            </p>
            <p className="p-in-sp">
              As Judge of the United States Court in the Western District of Virginia, he has rendered decisions which entitle him to the respect of the country. In proof of the wisdom which has characterized his judgment, and the high estimate in which they are held, I would state that they have been reported in all the law journals of the country, and are regarded generally as conclusive authority
              upon the subjects to which they related.
            </p>
            <p className="p-in-sp">He is, sir, a high-toned gentleman, highly conservative in his sentiments, and will, I hesitate not to say, if elected, do honor to the State and credit to himself.</p>
            <div className="speaker" id="sp5205"><span className="persName">Mr. WILSON</span>, of Harrison—</div>
            <p className="p-in-sp">I fully concur in all that has been said of Judge Brockenbrough. I believe there is no man who would give more general satisfaction to the Western portion of the State than Judge Brockenbrough.</p>
            <p className="p-in-sp">I trust it will be the pleasure of the Convention to elect him to this position.</p>
            <div className="speaker" id="sp5206"><span className="persName">Mr. TURNER</span>, of Jackson—</div>
            <p className="p-in-sp">
              While I concur in all that has been said in behalf of Judge Brockenbrough, I desire also to render my testimony to the high qualifications and eminent worth of the gentleman who has been put in nomination by the gentleman from Page. No one can deny that, in all the varying conditions of the country-in all the emergencies which have of late years arisen to test the efficiency and
              fidelity of its representatives-the Hon. James M. Mason proved himself equal to every exigency. His fidelity to Southern interests is <PageNumber num={626} /> proverbial, and by electing him to this position this Convention will be but giving a fitting and just endorsement to the representative acts of this distinguished gentleman.
            </p>
            <div className="speaker" id="sp5207"><span className="persName">Mr. MORTON</span>—</div>
            <p className="p-in-sp">My friend from Petersburg [<span className="persName">Mr. BRANCH</span>]
              has spoken of the secessionists as Breckinridge men. I would say that we ought to ignore all these considerations; and as respects the nominations which have been made, I should be untrue to the instincts of my heart, I should be untrue to that gallant constituency which I represent, if I did not endorse the nomination of James M. Mason. He has served this State in the Senate of the
              United States with distinction, as is evident from the fact that he was Chairman of one of the most important committees of that House-I mean the Committee on Foreign Relations.
            </p>
            <p className="p-in-sp">
              The gentleman has referred to the ancestry of Judge Brockenbrough. I concede all that he has said on that score. It is, in my judgment, a question not properly appertaining to the matter under consideration, and not such a one as ought to be introduced here. But if you base claims of candidates upon the character and services of ancestry, who, I ask will have a better claim to your
              preference, than the Hon. James M. Mason? Who can claim a nobler ancestry than he who is the grandson of George Mason, the immortal author of the Bill of Rights of Virginia? Who has stood up more boldly for Southern rights upon the floor of the Senate of the United States than James M. Mason? No one, sir; and none would prove more efficient and faithful in the discharge of the duties
              of this new office than he would. He has filled the station of United States Senator with distinction to himself and honor to his State, and he would, I am satisfied, maintain that reputation in this new sphere of action, if it will be the pleasure of this Convention to avail themselves of his services in that sphere. When we are selecting five men to be sent to the Confederate
              Congress, I think it is due to him and it is due to Virginia herself that he should stand by the side of his colleague in the Senate, the Hon. R. M. T. Hunter.
            </p>
            <p className="p-in-sp">I trust it will be the pleasure of this Convention to elect Mr. Mason to this position.</p>
            <p>No other nominations being made, the vote was taken as between the Hon. John W. Brockenbrough and the Hon. James M. Mason, and resulted-for John W. Brockenbrough 60, for James M. Mason 33, as follows:</p>
            <p>FOR BROCKENBROUGH-Messrs. John Janney [President]
              , Aston, Baylor, Blow, Bouldin, Boyd, Branch, Brent, Cabe11, Campbell, Caperton, Carter, Cecil, Coffman, <PageNumber num={627} /> R. Y. Conrad, James H. Cox, Critcher, Deskins, Dorman, Dulany, Early, Echols, Flournoy, Forbes, French, Garland, Gravely, Goggin, John Goode, Marmaduke Johnson, P. C. Johnston, Kilby, Lawson, McComas, Macfarland, Miller, Moore,
              Nelson, Preston, Pugh, Richardson, Rives, Robert E. Scott, W. C. Scott, Sharp, Sheffey, Sitlington, Slaughter, Southall, Speed, Spurlock, Staples, Alex. H. H. Stuart, Strange, Sutherlin, Tayloe, Tredway, Wickham, Wilson, Wysor-60.
            </p>
            <p>FOR MASON-Messrs. Ambler, A. M. Barbour, Jas. Barbour, Blakey, Boisseau, Borst, Chambliss, Chapman, Conn, Gillespie, Cyrus B. Hall, L. S. Hall, Harvie, Haymond, Holcombe, Holladay, Isbell, Kent, LeAe, James Marshall, Moffett, Montague, Morris, Morton, Neblett, Geo. W. Randolph, Seawell, Thornton, R. H. Turner, F. P. Turner, Tyler, Williams, Woods-33.</p>
            <p>Judge Brockenbrough having received a majority of all the votes cast, was declared duly elected.</p>
            <div className="speaker" id="sp5208"><span className="persName">Mr. JAMES BARBOUR</span>—</div>
            <p className="p-in-sp">
              I desire to place in nomination for the next position a gentleman so well known to this Assembly that it is unnecessary for me to say a word in his behalf. I refer to the Hon. John B. Floyd. The vindictiveness with which the enemies of the country have pursued him is the very best proof I can give of the fidelity and efficiency of that gentleman in the fulfillment of the public trusts
              with which he has been charged. No man has proved himself more true and faithful to Southern interests, and I have no doubt he would attest fidelity and efficiency in a similar degree in this new position, if it would be the pleasure of the Convention to elect him to it.
            </p>
            <div className="speaker" id="sp5209"><span className="persName">Mr. WM. BALLARD PRESTON</span>—</div>
            <p className="p-in-sp">That nomination, I perceive, is for the South-west. The relationship that exists between the gentleman just nominated and myself is known to many, probably all in this Convention. He is my cousin german. I shall say, therefore, nothing in relation to him.</p>
            <p className="p-in-sp">But, I rise, sir, to perform a public duty which is incumbent upon me, and which I discharge with great reluctance, and, I trust, with proper delicacy.</p>
            <p className="p-in-sp">
              I nominate for the South western District Waller R. Staples, Esq. It will probably be necessary for me to say a word or two in behalf of that gentleman. He is a resident of my own county. He is a man of highly cultivated mind; educated at William and Mary; of ripe learning; of sound scholarship, of great professional attainments. He is thirty-five years old-in the prime of life and
              manhood-a fervent, dexterous and skilful debater; and although he has not been much in a public theatre, yet his reputation is well known throughout the Commonwealth, and his peculiar qualifications eminently esteemed and regarded in the immediate district to which he belongs. I speak in <PageNumber num={628} /> the presence of the delegates from that
              district, all of whom, whether they vote for him or not, will bear me out in that declaration. He belonged to the Union party, and voted for me for the seat I now occupy; but, sir, it is enough to say, in proof of the position which he now occupies, that he came down here bringing with him three companies to carry out the principles which we have proclaimed by our action here.
            </p>
            <p className="p-in-sp">
              I know no man more eminently fit for this important trust than <span className="persName">Mr. STAPLES</span>. His reputation, his accomplishments, his education, his high professional attainments and ripe scholarship, entitle him to the first rank of ambition and honor. He is a young man possessing every quality to render him an efficient representative in a crisis like this; and if
              chosen, will be found a true representative of Virginia's honor, and I have no doubt will prove himself by little experience a worthy representative of Virginia statesmanship.
            </p>
            <p className="p-in-sp">I trust it will be the pleasure of the Convention to elect him to this position.</p>
            <div className="speaker" id="sp5210"><span className="persName">Mr. HARVIE</span>—</div>
            <p className="p-in-sp">I did not intend to say a word on the subject of these elections, and will say but very little now.</p>
            <p className="p-in-sp">
              I will state at the outset that there is no man in the Commonwealth of Virginia to whom, in my opinion, the State owes more than she does to John B. Floyd. I was in the city of Washington when that man was struck down, because of his fidelity to the South and to his State. Hearing the situation in which he was, I went to see him; and I can bear testimony to the fact that I never saw a
              man stand up more gallantly under the persecution inflicted upon him than did that gentleman. I never saw a man that discharged his duty under the circumstances better than did John B. Floyd. From the time that it was known that he was true to his State and his section, he had been hunted down and calumniated by the enemies of the South; and I avail myself of this opportunity to
              congratulate him on the triumphs which he achieved over his enemies and the enemies of his State and section, and to express my high appreciation of his whole course.
            </p>
            <p className="p-in-sp">
              It would seem to me, indeed, very remarkable if, after having struck down one of the most honored citizens of Virginia, Senator Mason, who, above all others, did his duty to his State and his section boldly and independently, we should now strike down John B. Floyd, who is equally obnoxious to our Northern enemies for his fidelity to his section. And how will this course be received by
              the State and country? Why, the inference will be, as it is natural it should, that <PageNumber num={629} /> they were struck down because of their peculiar zeal in behalf of Southern rights and Southern interests. This conclusion would be perfectly legitimate in view of the peculiar prominence of these gentlemen as the advocates and friends of Southern
              rights, and the rank and bitter hostility manifested by Yankeedom towards them.
            </p>
            <p className="p-in-sp">I, for one, will never put myself in the attitude of crying down those men so as to gratify Yankee spleen. I will record my vote most cordially for John B. Floyd, and I trust it will be the pleasure of the Convention to elect him.</p>
            <div className="speaker" id="sp5211"><span className="persName">Mr. WM. P. CECIL</span>, of Tazewell—</div>
            <p className="p-in-sp">I am gratified to find that my friend from Culpeper [<span className="persName">Mr. BARBOUR</span>] , and my friend from Amelia [<span className="persName">Mr. HARVIE</span>] , have so fully sustained the claims of that distinguished gentleman John B. Floyd. I feel it my duty, however, coming as I do from the region of country to which he belongs, to bear my humble testimony to the eminent qualifications of that distinguished gentleman.</p>
            <p className="p-in-sp">
              It is true that the bitterness and malignity with which he has been persecuted by the enemies of the South, is the strongest testimony that could be rendered to his fidelity to that section; and, under these circumstances, it seems to me that it is a duty which Virginia owes to her distinguished son to give him her endorsement by electing him to this position. I am satisfied that it is
              a position which Governor Floyd has not sought nor does not seek; but it is no less the duty of Virginia to confer it upon him, and thus repudiate the calumnies which Yankee hostility and sectional rancor had conceived and propagated.
            </p>
            <p className="p-in-sp">
              I know that such action on the part of this Convention would be gratifying to him, while, as I have said, he has no desire for the position further than its signification in the connection which I have named. It would be gratifying to him to know, moreover, that the people of Virginia appreciate his fidelity, and the eminent services which he has rendered to the South.
            </p>
            <p className="p-in-sp">
              If there is a man in the Commonwealth of Virginia who deserves well of his State and section, that man is John B. Floyd. No man has done as much as he has to place Virginia in a position in which she could best sustain herself. No man who had ever occupied a seat in the Cabinet of the United States, except John B. Floyd, had taken the pains of making a just and fair distribution of the
              arms which belonged to the Federal Government amongst the Southern States.
            </p>
            <p className="p-in-sp">
              This testimony did, and by that act displayed a foresight, statesmanship and sagacity which are characteristic of great minds and profound thinkers. He saw what was to come, and he took occasion, <PageNumber num={630} /> in due time, to place arms in the hands of the South to enable her to defend herself against the aggressions which he saw in prospect.
              It was truly a wise move, and one which deserves the commendation and thanks of every Southern man.
            </p>
            <p className="p-in-sp">
              I ask, therefore, not only as a matter of right to South-western Virginia, which has always been true to the State and to the whole South, but as an act of simple justice to John B. Floyd, that he be elected to this position. He has been always the first and ablest advocate of Southern rights in my section, and it is but a just reward even for that service that he should be chosen to
              this position.
            </p>
            <p className="p-in-sp">It would be peculiarly gratifying to that section to see this distinguished gentleman elected to this post of importance, and it is needless to say that if elected, he will be found efficient and faithful.</p>
            <p className="p-in-sp">I trust it will be the pleasure of the Convention to confer that position upon him.</p>
            <div className="speaker" id="sp5212"><span className="persName">Mr. MARMADUKE JOHNSON</span>, of Richmond—</div>
            <p className="p-in-sp">I desire to add my humble testimony to the claims of the gentleman who was put in nomination by my friend from Montgomery [<span className="persName">Mr. PRESTON</span>] .</p>
            <p className="p-in-sp">I have long had the pleasure of a cordial personal acquaintance with that gentleman, and to those members of the Convention who may not have enjoyed that pleasure, it affords me great satisfaction to recommend him.</p>
            <p className="p-in-sp">As a gentleman in private life he is possessed of all the social qualities which endear him to those with whom he is brought into association.</p>
            <p className="p-in-sp">
              As one of a calm, reflecting mind, I know of but few of his age who are his equals; of none who is his superior as a vigorous and energetic debater-capable of meeting any person in the theatre of discussion. Within the range of his occupation, I know of no gentleman whose claims are entitled to higher consideration. I say this much with cordiality, and as a matter of justice to Mr.
              Staples. He may not be as extensively known throughout the State as the gentleman whose name is in opposition to him, of whom I do not mean to say anything in derogation-whose claims to public merit I have never investigated. His faults are known; his virtues and his faults, no doubt, are equally well known to every member here. As to the claims of Mr. Staples privately and publicly,
              no one can say anything against them. I trust that I may be permitted to say to those here who may not have the pleasure of his acquaintance, that he will discharge the duties of the office to which his friends propose to elect him, with honor to himself and credit to the State.
            </p>
            <PageNumber num={631} />
            <p className="p-in-sp">I trust, therefore, it will be the pleasure of the Convention to confer this office upon him.</p>
            <div className="speaker" id="sp5213"><span className="persName">Mr. WM. B. ASTON</span>, of Russell and Wise—</div>
            <p className="p-in-sp">As a member from the South-west, I rise to endorse the nomination of Mr. Staples.</p>
            <p className="p-in-sp">He is a young gentleman of fine talents, and I fully endorse all that has been said concerning him.</p>
            <div className="speaker" id="sp5214"><span className="persName">Mr. CECIL</span>—</div>
            <p className="p-in-sp">With the consent of the gentleman from Culpeper [<span className="persName">Mr. BARBOUR</span>] , I withdraw the name of Governor Floyd, and put in nomination Walter Preston, of Abingdon.</p>
            <div className="speaker" id="sp5215"><span className="persName">Mr. DORMAN</span>—</div>
            <p className="p-in-sp">
              I trust my friend will withdraw that nomination. I had the pleasure of an acquaintance with Mr. Preston some years ago, and know Mr. Staples personally. I will support the latter cheerfully; but I must confess that the nomination of Mr. Preston places me in a position of great embarrassment. I should, under other circumstances, take pleasure in voting for him; and I trust that the
              gentleman will not press his nomination, especially as there seems but little prospect of his election.
            </p>
            <div className="speaker" id="sp5216"><span className="persName">Mr. STUART</span>, of Augusta—</div>
            <p className="p-in-sp">I would inquire whether Mr. Preston's health would enable him to serve? I know he is in feeble health.</p>
            <div className="speaker" id="sp5217"><span className="persName">Mr. CECIL</span>—</div>
            <p className="p-in-sp">I withdraw the nomination of Mr. Preston.</p>
            <div className="speaker" id="sp5218"><span className="persName">Mr. BORST</span>—</div>
            <p className="p-in-sp">I re-nominate him.</p>
            <div className="speaker" id="sp5219"><span className="persName">Mr. CAMPBELL</span>, of Washington county—</div>
            <p className="p-in-sp">Both of these gentlemen are known to me. Mr. Preston I have known all my life, and should vote for him cheerfully if I supposed there was any chance of his election. As there seems no chance of that result, I shall ask leave to be excused from voting at all. [Granted.]
              The roll was then called, and resulted-for Waller R. Staples, 78; for Walter Preston, 8; for John B. Floyd, 8-as follows:<span className="note" id="Note56"
              ><span className="noteLabel">3</span>The Journal refers to 72 votes for Staples, but lists only 71; it adds Janney, Baylor, Sharp and Tyler to the names given here, but omits Lawson. According to the Journal, there were 8 votes for Floyd: those listed in the Proceedings, and Blakey and L. S. Hall in addition.</span
              >
            </p>
            <p>
              FOR <span className="persName">Mr. STAPLES</span>-Messrs. Aston, Blow, Boisseau, Bouldin, Boyd, Branch, Brent, Cabell, Caperton, Carter, Chapman, Coffman, Robert Y. Conrad, James H. Cox, Critcher, Deskins, Dorman, Dulany, Early, Flournoy, Forbes, French, Garland, Gillespie, Gravely, Goggin, John Goode, Jr., Haymond, Holcombe, Holladay, Isbell, M. Johnson, P. C. Johnston, Lawson, Kilby,
              Leake, McComas, Macfarland, Marshall, Miller, Moffett, Montague, Morton, Moore, Nelson, Preston, Pugh, G. W. Randolph, Richardson, Rives, R. E. Scott, W. C. Scott, Seawell, Sheffey, Sitlington, Slaughter, Southall, Speed, Staples, A. H. H. Stuart, Strange, Sutherlin, Tayloe, Thornton, Wickham, Wilson, Woods and Wysor-69.
            </p>
            <p>FOR <span className="persName">Mr. PRESTON</span>-Messrs. Ambler, Borst, Cecil, Chambliss, Morris, Robert H. Turner, Franklin P. Turner and Williams-8.</p>
            <p>FOR <span className="persName">Mr. FLOYD</span>-Alfred M. Barbour, James Barbour, Conn, Harvie, Kent and Neblett-6.</p>
            <PageNumber num={632} />
            <div className="speaker" id="sp5220"><span className="persName">Mr. ALPHEUS F. HAYMOND</span>, of Marion—</div>
            <p className="p-in-sp">I beg leave to put in nomination for the North-western District, the Hon. Gideon D. Camden, of the county of Harrison.</p>
            <p className="p-in-sp">I know Judge Camden well, and can recommend him to be as true a Virginian, in every sense of the word, as any man within the limits of the Commonwealth. There is, I am confident, no man whose election to this position would give as general satisfaction to the people of that section as would that of Judge Camden.</p>
            <p className="p-in-sp">I trust, therefore, it will be the pleasure of the Convention to confer that position upon him.</p>
            <div className="speaker" id="sp5221"><span className="persName">Mr. A. M. BARBOUR</span>, of Jefferson—</div>
            <p className="p-in-sp">I rise to put in nomination for this position Charles W. Russell, Esq., of Wheeling.</p>
            <p className="p-in-sp">I will not trespass upon the Convention by any attempt at eulogy upon the character and qualifications of Mr. Russell, further than to say, that he is a man of the highest intellect, and the purest private character; one of the ablest lawyers in the State, and a political debater of great power and acuteness.</p>
            <p className="p-in-sp">I deem it unnecessary to say any more in reference to this gentleman, as I am satisfied he is very well known to every member here.</p>
            <p className="p-in-sp">I trust it will be the pleasure of the Convention to elect him to this position.</p>
            <div className="speaker" id="sp5222"><span className="persName">Mr. TURNER</span>, of Jackson—</div>
            <p className="p-in-sp">
              Whilst I would be the last man to say aught in disparagement of the two gentlemen who have been placed in nomination, for I have known them both long and well, still I desire to place in nomination for this position a gentleman entitled to as much credit for ability, for integrity, for public and private worth, as either of these gentlemen; or, sir, in proportion to his years, as any
              gentleman of the delegation of the Southern Confederacy.
            </p>
            <p className="p-in-sp">
              He has had the honor of representing the Congressional district in which I reside for the last four years upon the floor of the late United States House of Representatives. I believe there are gentlemen here who will bear me out in the declaration that he acquitted himself in that position in a manner befitting a representative from the State of Virginia, and with an efficiency and
              ability rarely displayed by a gentleman of his age. In that position, too, he gave proof of his fidelity to the Southern cause in opposition to what was the sentiment of a large portion of his district, and what, I fear, now is. It would be but an act of sheer justice to him that his fidelity should be acknowledged by the display of this mark of appreciation of his course. It would, I
              have no doubt, be a source of extreme pleasure <PageNumber num={633} /> to him, apart from the honor which would attach to this position itself, to have his course endorsed by this Convention.
            </p>
            <p className="p-in-sp">
              At the commencement of the discussion of the question of the division of this government, it was urged as an argument, showing the improbability of Virginia's seceding, that the North-western portion of the State was disloyal to the institutions of the South; and that it would divide the State in preference, to withdrawing from the Union; and of all the other gentlemen representing
              that section of the State, he alone was heard to raise his voice against it in the Congress of the United States, and to assure the South that no portion of Virginia would be more faithful to Virginia and the South than the portion of the State which he represented.
            </p>
            <p className="p-in-sp">I put in nomination the Hon. Albert G. Jenkins, of the county of Cabell.</p>
            <div className="speaker" id="sp5223"><span className="persName">Mr. WOODS</span>, of Barbour—</div>
            <p className="p-in-sp">
              I have hitherto forborne to occupy the time or attention of the Convention with any remarks in relation to the qualifications of any of the distinguished gentlemen who have been presented here as candidates for the honorable position of representatives of the State of Virginia in the Congress of the Southern Confederacy, and I would not now trouble the Convention with a word, if I did
              not deem it my duty to do so.
            </p>
            <p className="p-in-sp">No man in the State of Virginia has a more exalted opinion of the distinguished gentleman from the city of Wheeling [<span className="persName">Mr. RUSSELL</span>]
              , whose name is presented to this Convention, than I have. I respect him for the manly and independent course that he has pursued amidst the struggles in which we have been engaged. No man is more able to defend any position that he may assume than he is. No man has superior ability to him; yet I desire to say to the gentleman who has placed his name in nomination here, that I would be
              glad, if for the sake of harmony in our district, he would withdraw his nomination. I am well convinced, from all I can hear, that he cannot be elected, and I would very much regret to see his name presented if he should not receive the election.
            </p>
            <p className="p-in-sp">For the distinguished gentleman [<span className="persName">Mr. JENKINS</span>]
              , placed in nomination by the gentleman from Jackson, I have an equally high regard. I would cheerfully vote for either under other circumstances. But among these gentlemen, I am constrained to select the gentleman nominated by the gentleman from Marion, the Hon. Gideon D. Camden. He has been for the last twenty years, and is now, a Judge. He possesses the entire confidence of every
              one to whom he is known. No man stands higher in the confidence of his friends and
            </p>
            <PageNumber num={634} />
            <p className="p-in-sp">
              neighbors, and I will say, without intending to be invidious, that no Judge in the State of Virginia has acquitted himself more ably than Judge Camden. He has never been a politician, but he is a true and loyal son of Virginia, and is ready to-day to divest himself of his judicial ermine and serve the State in the capacity of a member of the Confederate States Congress, if it shall be
              the pleasure of the Convention to elect him to that position.
            </p>
            <p className="p-in-sp">I trust that his claims will receive that consideration at the hands of this body which they so richly deserve.</p>
            <div className="speaker" id="sp5224"><span className="persName">Mr. KILBY</span>—</div>
            <p className="p-in-sp">I would enquire whether Judge Camden is eligible to this position? Does not the Constitution prohibit the holding of any other office by a Judge, during his incumbency of the Judgeship?</p>
            <div className="speaker" id="sp5225"><span className="persName">Mr. HAYMOND</span>—</div>
            <p className="p-in-sp">
              I will remark that this Convention had recently appointed Judge Allen to the office of member of the Advisory Council, and we have adopted a provision suspending the operation of the constitutional prohibition, in reference to him. I apprehend that a suspension of this sort is but an alteration of the Constitution, and would, therefore, not be operative until the question went before
              the people-the law convening this body requiring that any change in the organic law of the State should be submitted to the people before it can have force or effect. But I can say that this question has been under consideration, and that distinguished Judges have determined that it was competent for a Judge in this State to be elected as a representative to the Southern Congress,
              under existing circumstances. At all events, if this provision of the Constitution has been suspended so as to admit of the election of a Judge to the position of member of our State Council, I cannot see why a similar provision cannot be had in the case of Judge Camden, should it be the pleasure of the Convention to elect him to the position for which his name is presented.
            </p>
            <div className="speaker" id="sp5226"><span className="persName">Mr. HALL</span>, of Wetzel—</div>
            <p className="p-in-sp">
              I rise not for the purpose of saying anything against the distinguished gentlemen who have been put in nomination here, but for the purpose of putting in nomination another gentleman from the Northwest. The disposition of this body seems to be to partition among the old party factions the distinguished positions which they are now filling, without reference to the public weal or the
              public interest. They have already chosen two Breckinridge men and two Bell men, and it is now proper, in view of this plan of division, that a Douglas man should be chosen as a fifth representative. I have been a Douglas man, and must claim my rights in this matter.
            </p>
            <p className="p-in-sp">
              The nomination of the gentleman whose name I shall present is not <PageNumber num={635} /> made by way of opposition to the other gentlemen in nomination. There is no man in the State for whom I have a higher respect than I have for C. W. Russell. He was a strong Breckinridge man, as I have reason to know, for he and I met frequently on the stump during
              the last Presidential canvass-he advocating the claims of Breckinridge and I those of Douglas.
            </p>
            <p className="p-in-sp">
              I have nothing to say against Judge Camden; on the contrary, I regard him as eminently qualified to fill this office. Mr. Jenkins, who is the third nominee, is a gentleman, perhaps, equally worthy of the position with any of the others, and a gentleman for whose talents and fidelity to Southern interests, I have a very high appreciation. I make no opposition to any of these gentlemen
              on the score of qualification; but I do believe, since the claims of particular factions have been recognized, that the Douglas faction have a right to be represented.
            </p>
            <p className="p-in-sp">With a view to test the sense of justice of this Convention in this matter of the distribution of office, I shall put in nomination Wiley H. Oldham, Esq., and I do so with the understanding that my friend from Jefferson [<span className="persName">Mr. A. M. BARBOUR</span>] , will withdraw the nomination of Mr. Russell.</p>
            <p className="p-in-sp">Every gentleman here who knows Mr. Oldham knows that there is no abler man in the North-west, and none more worthy in every respect.</p>
            <div className="speaker" id="sp5227"><span className="persName">Mr. ECHOLS</span>—</div>
            <p className="p-in-sp">I have been detained from my seat in the Convention for a few minutes, and am, therefore, ignorant of the state of things here.</p>
            <p className="p-in-sp">I understand, however, that Mr. C. W. Russell is in nomination, and I desire to bear my testimony to his eminent worth, ability and peculiar fitness for the position of member to the Confederate States Congress.</p>
            <p className="p-in-sp">
              I have had considerable intercourse with Mr. Russell, and good opportunities of knowing his peculiar worth, and I am justified in saying that no gentleman combines more fully the elements of ability, prudence and foresight so essential to constitute an efficient public servant, than does Mr. Russell. He is distinguished both as a lawyer and a statesman, and no man will discharge more
              faithfully and efficiently the duties of this position than he will, if it shall be the pleasure of the Convention to confer it upon him. I have always regarded him as one of the most reliable men in the State, and in that opinion I am fully borne out by his course in every position in which he has served.
            </p>
            <PageNumber num={636} />
            <p className="p-in-sp">I was with him in the Legislature of 1852-'3, and there was no man in the branch in which we served who had exercised so important an influence in that body as he did. He enjoyed the confidence of every member, and was indefatigable and faithful in the discharge of every duty that devolved upon him.</p>
            <p className="p-in-sp">He is a debater of rare capacity; and to his powers in this respect was owing the success of a very important measure at that time, which, were it not for the power of his arguments, would have been decided upon adversely.</p>
            <p className="p-in-sp">I trust it will be the pleasure of this Convention to elect him to this position.</p>
            <div className="speaker" id="sp5228"><span className="persName">Mr. KILBY</span>—</div>
            <p className="p-in-sp">It may not be amiss for me to add a single word to what has been said by my friend from the county of Monroe [<span className="persName">Mr. ECHOLS</span>] .</p>
            <p className="p-in-sp">
              I fully endorse every word he has said in regard to Mr. Russell. I had the pleasure of serving with him for many years in the State Legislature, and I will recollect the circumstance to which the gentleman has referred. No man is better fitted for this position than Mr. Russell on the score of ability and fidelity to Southern interests, and I trust the Convention will elect him to this
              position.
            </p>
            <div className="speaker" id="sp5229"><span className="persName">Mr. CYRUS HALL</span>, of Pleasants and Ritchie—</div>
            <p className="p-in-sp">
              My opinion is, that the election of Judge Camden would give greater satisfaction to the people of North-western Virginia than that of any other man that could be named. I know Judge Camden well. I know him to be a Virginian of the right stamp, and a man of eminent acquirements, ripe scholarship and proverbial prudence and discretion. I think he would unite the people of North-western
              Virginia, and harmonize them to a greater degree than any other gentleman that has been nominated for this position.
            </p>
            <p className="p-in-sp">I hope that the nomination of Mr. Russell will be withdrawn. While I can bear testimony to the qualifications of that gentleman, and his entire fitness for this office, yet I am satisfied that the will of our people would be best consulted by the election of Judge Camden.</p>
            <div className="speaker" id="sp5230"><span className="persName">Mr. CAPERTON</span>, of Monroe—</div>
            <p className="p-in-sp">
              I beg leave to say as one who served with Judge Camden in the Convention of 1850-'51, that he maintained a position there of as much fidelity and credit as any man coming from that portion of the State as those who were there with him, and who are now here will testify. Further than that, Judge Camden has shown that he will be, in all probability, more acceptable to the people of that
              part of the Commonwealth than any that could be chosen.
            </p>
            <p className="p-in-sp">
              He has been twice elected to the bench. In his first contest he was elected by a majority of 5,000, and in his second contest he was elected <PageNumber num={637} /> over a popular Democrat by a majority of over 4,000. I have not been much acquainted with Judge Camden, but those who have been acquainted with him say that there is no more popular man in
              that region of the country. I believe his competitor in the last election for the Judgeship was the delegate from the county of Preston
              [<span className="persName">Mr. BROWN</span>] who was a hard man to beat, and Judge Camden was elected over him by over 4,000 majority. This, I presume, is conclusive of the popularity of that gentleman in his region of country, and shows that his election to this position would give general satisfaction to the people of that region.</p>
            <div className="speaker" id="sp5231"><span className="persName">Mr. TURNER</span>—</div>
            <p className="p-in-sp">I withdraw the nomination of Mr. Jenkins, whilst I do not believe that the election of Judge Camden would give more satisfaction to the people of the North-west than would that of Mr. Jenkins.</p>
            <div className="speaker" id="sp5232"><span className="persName">Mr. WILSON</span>—</div>
            <p className="p-in-sp">I cannot let this opportunity pass without adding my testimony to the worth and ability of my county man, Judge Camden. I am grateful to those gentlemen who have so ably, and I must say, appropriately vindicated his claims, and borne testimony to his character and qualifications.</p>
            <p className="p-in-sp">
              It is very desirable that, in the election now to be made, an effort should be made to reconcile all sections. Judge Camden is a native of the State, and has called around him more warm personal friends than any gentleman, perhaps, in the region of country in which he lives. He enjoys the highest degree of confidence among all. Early in life he was a whig, and made his first departure,
              I believe, from that party during the Buchanan and Fremont campaign when he supported the democratic candidate. Whatever party affiliations he showed since then, were with the democracy, I think; but he has observed a judicious neutrality since his elevation to the judgeship, and there is scarcely a possibility of forming an opinion as to his political predilections.
            </p>
            <p className="p-in-sp">
              I hesitate not to say that his election to this office would do as much to harmonize the people and render perfect and entire satisfaction<span className="note" id="Note57"><span className="noteLabel">4</span>Perhaps "as that" has been omitted here.</span> of any other gentleman in that section.
            </p>
            <p className="p-in-sp">
              I fully appreciate the worth and ability of that eloquent gentleman, Charles W. Russell. I have nothing to say of him, sir, but what is good. He has heretofore been regarded as among the extremists in that part of the country; and the fact that there was a large majority of Union men there who occupied an entirely opposite position from that held by Mr. Russell, indicates that his
              election to this position would not be apt to produce that harmony which would result <PageNumber num={638} /> from the election of a gentleman of more moderate opinions in the past. He could not command that degree of confidence nor exercise the influence over the popular mind of that section that Judge Camden could.
            </p>
            <p className="p-in-sp">As a proof of the fidelity of Judge Camden, I would state that a few days ago in our town he observed to his friends that his property there would be given at any moment to Virginia for the purpose of vindicating her rights and her honor, and that, in short, he was prepared to make any sacrifice in that direction.</p>
            <p className="p-in-sp">I hope it will be the pleasure of the Convention to confer this position upon him.</p>
            <div className="speaker" id="sp5233"><span className="persName">Mr. CHAMBLISS</span>—</div>
            <p className="p-in-sp">
              I concur most heartily with all the commendations that have been given to Judge Camden; but I beg leave to say that there is a more important position to which I think Judge Camden ought to be appointed. It is certain that the number now composing the Advisory Council of the Governor is too small. They want an additional number in order to perform efficiently the duties which devolve
              upon them; and if Judge Camden should not be elected to the position for which he is nominated, and Mr. Russell should a move will be made for the purpose of increasing the Advisory Council, and thus creating a position for which Judge Camden is peculiarly fitted. He is, I understand, the choice of the Advisory Council itself, as well as of this body, so that he will be sure of an
              appointment in any event.
            </p>
            <p className="p-in-sp">It will be proposed to increase that Council by the addition of two members, one of whom will come from the extreme East, and Judge Camden from the North-west.</p>
            <p className="p-in-sp">I therefore submit to the friends of Judge Camden whether it would not be better and more advisable to place him in the Advisory Council rather than send him as a representative to the Congress of the Confederate States? I think it would; and, so believing, and with a view to cast my vote for the Judge for that position, I shall now go for Mr. Russell.</p>
            <p className="p-in-sp">I throw out these suggestions to the friends of Judge Camden, trusting that they will see the wisdom of carrying them out.</p>
            <div className="speaker" id="sp5234"><span className="persName">Mr. DORMAN</span>—</div>
            <p className="p-in-sp">
              I have made up my mind to vote for Judge Camden for this office. I have known him, and known his popularity, and I have determined my vote upon the general consideration that it would have a most important effect upon the people of North-western Virginia. I think that this is what we ought now to consider. We should certainly vote for the man whose election would carry most influence.
              In that view of the case, I thought Judge Camden was the proper <PageNumber num={639} /> man. But it may be well to consider the suggestion of the gentleman from Greenesville
              [<span className="persName">Mr. CHAMBLISS</span>] ; and with a view to afford an opportunity to do so, I move we take a recess.</p>
            <div className="speaker" id="sp5235"><span className="persName">Mr. RANDOLPH</span>—</div>
            <p className="p-in-sp">I have an ordinance which I intend to offer with a view to increase the Advisory Council to five, and creating the Lieutenant Governor ex-officio one of that number</p>
            <p className="p-in-sp">If the ordinance shall pass, the two additional members can be chosen from the extreme ends of the State-say Judge Camden from the North-west and the Lieut. Governor from the extreme East.</p>
            <div className="speaker" id="sp5236"><span className="persName">Mr. WILSON</span>—</div>
            <p className="p-in-sp">I do not consent to take the liberty of withdrawing Judge Camden's name. I heard that the purpose of increasing the Advisory Council was to create a position for Mr. Russell.</p>
            <div className="speaker" id="sp5237"><span className="persName">Mr. HAYMOND</span>—</div>
            <p className="p-in-sp">I nominated Judge Camden, and do not feel willing to withdraw his name unless his friends desire it to be done.</p>
            <p className="p-in-sp">
              I admit that Judge Camden is peculiarly fitted to serve as a member of the Advisory Council; but there are others in the North-west who can act in that capacity besides Judge Camden. I regard him as being peculiarly fitted for the position to which he has been nominated, and I presented his name in connection with it after consulting gentlemen from the North-west who are perfectly
              familiar with the Judge's qualifications.
            </p>
            <p className="p-in-sp">
              Judge Camden is a true Southern man, and so esteemed by all who know him, and I hold that no man is more competent to do what is necessary for the maintenance of Southern interests than he is, and none more willing to act with that view. I do not feel at liberty to withdraw his name, and I hope it will be the pleasure of the Convention to elect him to this position.
            </p>
            <div className="speaker" id="sp5238"><span className="persName">Mr. HALL</span>, of Wetzel—</div>
            <p className="p-in-sp">I withdraw the name of Mr. Oldham.</p>
            <p>
              The roll was then called, as between Judge Camden and Mr. Russell, who were the only two in nomination, and resulted-far Gideon D. Camden 48, for Hon. C. W. Russell 47-as follows:<span className="note" id="Note58"
              ><span className="noteLabel">5</span>The Journal lists the same 48 votes for each nominee as listed here, although it says of this vote "Whole number of votes 95; necessary to a choice 48. Mr. Camden having received the requisite number, was declared duly elected."</span
              >
            </p>
            <p>
              For <span className="persName">Mr. C. W. RUSSELL</span>-Messrs. Ambler, A. M. Barbour, James Barbour, Blakey, Boisseau, Borst, Bouldin, Boyd, Bruce, Cabell, Cecil, Chambliss, Chapman, Coffman, Conn, Echols, Flournoy, Garland, Gregory, John Goode, L. S. Hall, Harvie, Holcombe, Isbell, Kilby, Marye, Miller, Montague, Morris, Morton, Moore, Neblett, Preston, Geo. W. Randolph, Richardson,
              Seawell, Sheffey, Sitlington, Slaughter, Speed, Staples, Strange, Sutherlin, Thornton, R. H. Turner, Tyler, Williams and Wysor-47.
            </p>
            <p>
              For <span className="persName">Mr. GIDEON D. CAMDEN</span>-Messrs. Aston, Baylor, Blow, Branch, Brent, Campbell, Caperton, Carter, R. Y. Conrad, James H. Cox, Critcher, Deskins, Dorman, Dulany, Early, Forbes, French, Gillespie, Gravely, Goggin, Cyrus Hall, Ephraim B. Hall, Haymond, Holladay, Marmaduke Johnson, P. C. <PageNumber num={640} /> Johnston, Lawson,
              Leake, Lewis, McComas, Macfarland, James Marshall, Moffett, Nelson, Pugh, Rives, Robert E. Scott, W. C. Scott, Sharp, Southall, Spurlock, Alex. H. H. Stuart, Tayloe, F. P. Turner, Wickham, Wilson, Woods and John Janney
              [President] -48.</p>
            <p>Judge Camden having received a majority of all the votes cast, was declared duly elected.</p>
            <div className="speaker" id="sp5239"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">I beg leave to submit the following communication from the Governor of the Commonwealth:</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>EXECUTIVE DEPARTMENT,</p>
                <p>April 29th, 1861.</p>
                <p>Gentlemen of the Convention:</p>
                <p>In obedience to an ordinance passed April 27th, 1861, requiring all appointments of officers heretofore made by the Governor and Council, or which shall hereafter be made, above the rank of Lieut. Colonel, to be sent in to the Convention for confirmation, I submit the following appointments:</p>
                <p>Walter Gwynn, Maj. Gen. of Volunteers, appointed April 26th, 1861. Joseph E. Johnston, Maj. Gen. of Provisional Army, April 25th, 1861.</p>
                <p>Philip St. George Cocke, Brig. Gen. Provisional Army, April 21st, 1861.</p>
                <p>Daniel Ruggles, Brig. Gen. Provisional Army, April 22d, 1861. John B. Magruder, Colonel Provisional Army, April 25th, 1861. James F. Preston, Colonel Provisional Army, April 25th, 1861. Robt. S. Garnett, Col. Adj't. Gen. Provisional Army, April 25th,</p>
                <p>1861.</p>
                <p>John B. Baldwin, Col. Insp. Gen. Provisional Army, April 21st, 1861.</p>
                <p>Wm. Gilham, Col. Insp. Gen. Provisional Army, April 23d, 1861.</p>
                <p>Chas. Bell Gibson, Col. Surgeon General Provisional Army, April</p>
                <p>26th, 1861.</p>
                <p>Respectfully,</p>
                <p>JOHN LETCHER.</p>
              </div>
            </div>
            <div className="speaker" id="sp5240"><span className="persName">Mr. FLOURNOY</span>—</div>
            <p className="p-in-sp">I move to lay that communication on the table.</p>
            <p>The motion was agreed to.</p>
            <div className="speaker" id="sp5241"><span className="persName">Mr. SCOTT</span>, of Fauquier—</div>
            <p className="p-in-sp">The appointment of Judge Camden to the position of representative to the Congress of the Confederate States, makes it necessary to relieve him from the provision of the Constitution which would otherwise vacate his judgeship.</p>
            <PageNumber num={641} />
            <p className="p-in-sp">The other day when Judge Allen was appointed to the Advisory Council, the proviso then adopted only related to that particular office; but here is an ordinance now in general form which will apply to any case, and supersede the necessity of any other ordinance touching the question.</p>
            <p className="p-in-sp">"Be it ordained by the people of Virginia in Convention assembled, That the acceptance of any appointment of public trust created by this Convention shall not vacate any office under the Constitution and laws of the State now held by any person on whom such appointment or public trust may be conferred."</p>
            <p>The ordinance was adopted.</p>
            <div className="speaker" id="sp5242"><span className="persName">Mr. RANDOLPH</span>—</div>
            <p className="p-in-sp">I now offer the following ordinance:</p>
            <p className="p-in-sp">"Be it ordained, That the Advisory Council of the Governor of this Commonwealth be, and the same is hereby increased to five, and that the Lieut. Governor shall be ex-officio one of the said five members."</p>
            <div className="speaker" id="sp5243"><span className="persName">Mr. BRANCH</span>—</div>
            <p className="p-in-sp">I would be glad to hear some reason for this increase.</p>
            <div className="speaker" id="sp5244"><span className="persName">Mr. RANDOLPH</span>—</div>
            <p className="p-in-sp">I was informed by Judge Allen that an increase of the number now composing that Council was necessary in order to discharge the increasing business devolving upon it; and he suggested, moreover, that it was desirable to have a member from the Northwest and one from the extreme East.</p>
            <div className="speaker" id="sp5245"><span className="persName">Mr. CAMPBELL</span>, of Washington county—</div>
            <p className="p-in-sp">
              It seems to me that, as we are now or soon will be a member of the Southern Confederacy, we are unnecessarily increasing the expenses of the Commonwealth. I must vote against this ordinance for the reason that I believe the proposed increase to be unnecessary, and not because of the gentlemen whose names, I understand, it is contemplated to present for the additional appointments.
            </p>
            <p className="p-in-sp">I think that, for the short time we have to remain in this condition, we have force enough in that Council.</p>
            <div className="speaker" id="sp5246"><span className="persName">Mr. HALL</span>, of Wetzel—</div>
            <p className="p-in-sp">I can very well judge of the necessity of having some representative from the North-West in this Council.</p>
            <p className="p-in-sp">
              The people of that region are now making preparations to protect themselves from local traitors, and they should assuredly be provided with an armed force and other necessary means to defend themselves from outside enemies; and with a view to afford all the necessary information touching the North-Western country-its wants, needs and proper points of defence-a representative from that
              section ought to be placed in the Council. We are in a bad way out there. We have nothing now but our mountains and trusty rifles to defend <PageNumber num={642} /> us, and we should at least have some-one near the State authorities who would present our needs, and procure, if possible, the necessary means to relieve us from our dangerous condition.
            </p>
            <p className="p-in-sp">I shall cheerfully vote for that ordinance.</p>
            <div className="speaker" id="sp5247"><span className="persName">Mr. DORMAN</span>—</div>
            <p className="p-in-sp">I understand this ordinance proposes to increase the number composing the Advisory Council to five.</p>
            <p className="p-in-sp">I am not prepared to vote for that proposition. I have had great doubt in my mind as to the propriety of the organization of that body at all.</p>
            <p className="p-in-sp">I move that the ordinance be, for the present, laid upon the table.</p>
            <p>The motion was rejected-ayes 32, noes 46.</p>
            <div className="speaker" id="sp5248"><span className="persName">Mr. EARLY</span>—</div>
            <p className="p-in-sp">I certainly am in favor of making some different arrangements about the management of our affairs.</p>
            <p className="p-in-sp">I am sorry that we have retained in our secret sessions a reporter, to report our debates to be hereafter published, because I think the public interests would have been better subserved if we had no reporter at all. I am afraid, sir, that we have made a mistake in the organization of the Advisory Council that we already have.</p>
            <p className="p-in-sp">
              We have three gentlemen of eminent abilities in their particular sphere of duty; but I doubt very much whether they have sufficient administrative ability to render their services of much value in the capacity in which they are now serving. One of the members of that Council is a Judge of the Court of Appeals, whose whole life has been devoted to the study of law. We have another
              gentleman who is President of a Military Academy in the State, no doubt, skilled in his profession, but with little experience in administrative duties; and another gentleman of the Naval service of the United States, who is solely and entirely a scientific man; who understands all about currents, the stars, and who can foretell the coming of comets; but I very much doubt whether there
              is much administrative talent in the whole Council.
            </p>
            <p className="p-in-sp">I think there ought to be a change in this organization. What we stand in need of now is administrative talent. A Judge of the Court of Appeals, a President of a Military Academy and a Superintendent of an Observatory are not very well qualified to discharge administrative duties of the character devolving on that Council.</p>
            <p className="p-in-sp">
              I would suggest to the gentleman who has this matter in charge, if he cannot have a committee appointed with a view to effect some reform in that Council, and create different departments with an appropriate organization for each. The question is, whether we can improve the present defects in the Council, and increase its efficiency
              <PageNumber num={643} /> by adding to it? I fear not. While I have the highest respect for the gentlemen now composing the Advisory Council, I fear, as I have already said, that they do not combine the administrative talents necessary for the positions they occupy.
            </p>
            <p className="p-in-sp">
              I have had some conversation with a gentleman who is now in the naval service of Virginia, and he assured me that all the naval operations are in a perfect state of confusion. They do not know, in fact, who has charge of them. We have, I understand, three commanders-in-chief of our navy already appointed; we have a number of major generals, brigadier generals, colonels of volunteers
              already appointed, and we have found it necessary to take charge of these appointments ourselves.
            </p>
            <p className="p-in-sp">I will submit to the gentleman from Richmond [<span className="persName">Mr. RANDOLPH</span>]
              , if a better arrangement cannot be made by having this whole subject referred to a committee, to see if we cannot get a more efficient administrative organization than those we now have; if we cannot organize boards-a naval board, and a board of army officers-suited to these respective departments of the Government. That will answer better than our present arrangement.
            </p>
            <div className="speaker" id="sp5249"><span className="persName">Mr. RANDOLPH</span>—</div>
            <p className="p-in-sp">
              I have no objection to refer the ordinance to a committee; but I am satisfied if an addition is made to the Council of two competent men-of another with the Lieutenant Governor-the business of that Council will be efficiently discharged. I do not think it is necessary for us to organize departments. The members of the Council can divide the business, and thus secure as much efficiency
              as if there were separate boards.
            </p>
            <p className="p-in-sp">
              With regard to the navy, permit me to say, that there is less confusion in that than any other Department of the Government of Virginia. They have already organized Bureaus-Bureaus of Construction, Bureaus of Ordnance, &amp;c. I think there is more system displayed there than in any other Department of the newly created Government. We cannot expect in a day to bring order out of chaos.
              We have just pulled down one government, and we have now to organize a new one. It is not reasonable for gentlemen to complain, because, by a single wave of the magician's wand, you cannot have order out of ruin. I think everything is going on as well as we could expect. We have a Commander-in-Chief who is competent to do his duty. We have the best talent in the United States Navy, and
              I think we have no cause whatever to complain.
            </p>
            <div className="speaker" id="sp5250"><span className="persName">Mr. MACFARLAND</span>—</div>
            <p className="p-in-sp">I would suggest that it is all-important that <PageNumber num={644} /> there should be an ordinance passed in respect to the Circuit and District Courts of the United States.</p>
            <p className="p-in-sp">I offer a proposition that a Committee be raised to report upon it.</p>
            <div className="speaker" id="sp5251"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">The ordinance of the gentleman from Richmond [<span className="persName">Mr. RANDOLPH</span>] is not yet disposed of.</p>
            <div className="speaker" id="sp5252"><span className="persName">Mr. KILBY</span>—</div>
            <p className="p-in-sp">I move that the Convention take a recess until 5 o'clock P. M.</p>
            <p>The motion was agreed to, and the Convention accordingly adjourned till the hour indicated.</p>
            <div className="session" id="vsc1965.v4.2.13.1">
              <h3><span className="headingNumber">1.13.1. </span><span className="head">EVENING SESSION</span></h3>
              <p>The Convention re-assembled at the hour appointed.</p>
              <div className="speaker" id="sp5253"><span className="persName">Mr. SEAWELL</span>, of Gloucester—</div>
              <p className="p-in-sp">By general consent, I would ask to submit an amendment to the amendment offered by the gentleman from Bedford [<span className="persName">Mr. GOGGIN</span>] to the ordinance for the suspension of debts, and ask that it be laid upon the table and printed.</p>
              <div className="speaker" id="sp5254"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The gentleman can only offer this amendment by general consent, the ordinance submitted by <span className="persName">Mr. RANDOLPH</span> to increase the number of the Advisory Council being the first business in order.</p>
              <p>There being no objection made, <span className="persName">Mr. SEAWELL</span> submitted the following, by way of amendment to the amendment offered by <span className="persName">Mr. GOGGIN</span> to the ordinance for the suspension of debts:</p>
              <div className="floatingText">
                <div className="floatingText_body">
                  <p>1. Be it ordained, That no execution shall be issued from and after the day of 1861, on any judgment or decree of any court</p>
                  <p>of record; nor by any justice of the peace; nor shall there be any sales under any deed of trust or mortgage, or decrees of any court of the State until otherwise provided by law, except for the collection of interest and costs due thereon.</p>
                  <p>
                    2. Where executions have issued from any court of record or single justice, and are now in the hands of the proper officers, whether levied or not, if the debtor offer to the officer in whose hands such execution may be, bond with good security, conditioned for the payment of the debt for which such execution issued, with all interest, costs and damages thereon, when the
                    operation of this ordinance ceases, said execution shall be returned by said officer to the office from which it issued, or to the clerk's office of the county court, if issued by a justice, and the bond so taken shall be returned with it, by said officer. In lieu of such bond and security, the said debt, interest, damages and costs for which said execution issued may be secured
                    by
                  </p>
                  <PageNumber num={645} />
                  <p>deed of trust or mortgage, on real or personal estate, if the parties interested shall prefer so to secure the same.</p>
                  <p>
                    3. If the debtor shall offer no such bond or other security, as aforesaid, it shall be the duty of the officer in whose hands such execution may be to convene three freeholders of the vicinage, who, after being duly sworn by such officer, shall proceed to value any property on which said execution may have been levied, according to what would have been its value on the 6th day of
                    November, 1860; said officer shall then proceed to offer said property for sale, according to law. Unless the same shall sell for the amount of such valuation, it shall be returned to the debtor. But the said execution shall retain its priority of lien on said property until otherwise provided by law. Provided that all the provisions of this and the preceding section may be
                    disregarded by consent of all parties interested in such execution.
                  </p>
                  <p>4. Except in criminal causes, on behalf of the Commonwealth, there shall be no judgment or decree for the payment of money rendered by any court of record or single justice, in this State, from and after the day of 1861.</p>
                  <p>5. In cases of misdemeanor, juries shall be summoned from the vicinage, and not under the law, as it now stands, and by consent of the party accused and the Attorney for the Commonwealth, any such cause may be submitted to, and decided by the court in which the same is pending.</p>
                  <p>
                    6. This ordinance shall not apply to liabilities on the part of public officers. In all cases of money due and payable by fiduciaries, petition may be filed in chancery and payment enforced by decree of the court in such mode as it may deem proper. Provided that nothing in this ordinance shall be held to extend to attachments authorized by sections 1, 2, 3, 4 and 5 of chapter 151
                    of the Code of Virginia.
                  </p>
                  <p>7. The time during which this ordinance may be in force shall not be computed in any case where the statute of limitations shall come in question.</p>
                  <p>8. This ordinance shall remain in force until repealed or changed by this Convention or the General Assembly of the State; and if not so repealed or changed, shall expire at the end of thirty days after the first day of the next General Assembly.</p>
                </div>
              </div>
              <p>The consideration of the ordinance offered by <span className="persName">Mr. RANDOLPH</span>, proposing an increase in the Advisory Council, was then resumed.</p>
              <div className="speaker" id="sp5255"><span className="persName">Mr. SHEFFEY</span>—</div>
              <p className="p-in-sp">Allow me to inquire whether that ordinance defines <PageNumber num={646} /> that the Lieutenant Governor shall be one of the five, or that he is to be an ex-officio member, in addition to two others?</p>
              <div className="speaker" id="sp5256"><span className="persName">Mr. RANDOLPH</span>—</div>
              <p className="p-in-sp">It says expressly that the Lieutenant Governor shall be one of the five members.</p>
              <p className="p-in-sp">The question was then taken on the adoption of the ordinance, and, the vote being taken, by a count, resulted-ayes 44, noes 22; no quorum voting.</p>
              <div className="speaker" id="sp5257"><span className="persName">Mr. GOODE</span>, of Bedford—</div>
              <p className="p-in-sp">
                As I understand, an ordinance has already been passed, in addition to that organizing this Council, for the purpose of defining the powers and duties of this Advisory Council, so that they and the Executive might act harmoniously. Inasmuch as some dissatisfaction exists as to the character of this organization and the increase of its members, I will offer the following resolution as
                a means to settle the whole matter:
              </p>
              <p className="p-in-sp">"Resolved, That the ordinances heretofore adopted in regard to the Advisory Council, and the ordinance now under consideration, be referred to a committee of five, with instructions to report an ordinance fixing the number of said Council, and also defining their duties."</p>
              <div className="speaker" id="sp5258"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">I rise to a question of order. This House is now voting upon that proposition, and it is not in order to make any motion in reference to it.</p>
              <p className="p-in-sp">I remained here three days in the Legislature upon a proposition of that kind. A vote had been taken upon a question, and there being no quorum, we were compelled to remain inactive until a quorum was raised, no other proposition being, meanwhile, admissible.</p>
              <div className="speaker" id="sp5259"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair decides that a motion is in order. A vote was taken, but there was no quorum, and the question stands precisely as if there was no vote taken at all.</p>
              <div className="speaker" id="sp5260"><span className="persName">Mr. JOHNSON</span>, of Richmond—</div>
              <p className="p-in-sp">It seems to me that the resolution of my friend from Bedford [<span className="persName">Mr. GOODE</span>] , does not at all tend to simplify the subject under consideration.</p>
              <p className="p-in-sp">
                We have an Advisory Council already, composed of Judge Allen, Col. Smith, and Capt. Maury, all of whom, I believe, occupy high positions; and it seems to me, if they, who are constantly in consultation with the Governor, cannot determine their duty, we certainly cannot determine it for them. I do not see how we, who are less competent, and without the means of information relative to
                these duties, which they must possess, can safely undertake to define for them duties of which we are necessarily ignorant.
              </p>
              <p className="p-in-sp">
                The Council, as I understand, have been acting harmoniously thus far, and it is now proposed, because of an increase of duty, to add <PageNumber num={647} /> two to the number of that Council; not that they do not do their duty, or that we distrust their qualifications at all. On the contrary, we have entire confidence in all the members of that
                Council. The proposition simply is, to relieve them in part of a duty which is now too onerous for them. But for a committee of five to undertake to suggest to them what is their duty, would be to increase their embarrassment; and with great respect to the gentleman, I move to lay the resolution on the table.
              </p>
              <div className="speaker" id="sp5261"><span className="persName">Mr. DORMAN</span>—</div>
              <p className="p-in-sp">I would ask the gentleman to withdraw that motion for a moment.</p>
              <div className="speaker" id="sp5262"><span className="persName">Mr. JOHNSON</span>—</div>
              <p className="p-in-sp">I regret to have to refuse the gentleman.</p>
              <div className="speaker" id="sp5263"><span className="persName">Mr. TURNER</span>, of Jackson—</div>
              <p className="p-in-sp">I rise to a question of order.</p>
              <p className="p-in-sp">A committee has heretofore been appointed to define what are the duties of the Advisory Council and their relations to the Governor. That committee has reported and its report acted upon, and I therefore consider it out of order to appoint another committee with reference to that subject.</p>
              <div className="speaker" id="sp5264"><span className="persName">Mr. GOODE</span>, of Bedford—</div>
              <p className="p-in-sp">Will my friend from Richmond [<span className="persName">Mr. JOHNSON</span>] , withdraw his motion for a moment?</p>
              <div className="speaker" id="sp5265"><span className="persName">Mr. JOHNSON</span>—</div>
              <p className="p-in-sp">The gentleman from Rockbridge [<span className="persName">Mr. DORMAN</span>] , has already asked me to withdraw it, and I declined to do so. I therefore cannot withdraw it for the gentleman who now makes the request. But I shall do so at the instance of the gentleman from Rockbridge, in order that the gentleman may have an opportunity to present his views upon the subject.</p>
              <div className="speaker" id="sp5266"><span className="persName">Mr. DORMAN</span>—</div>
              <p className="p-in-sp">
                According to the construction of some people, at least, this Council is an Executive Council. The objection in my hearing, when we were about to constitute this Advisory Council at first, was that it was a dual Executive. It is now understood by gentlemen of capacity, that we have a dual Executive. I want the matter to be finally settled. If we have appointed a Council possessing
                Executive functions with regard to all appointments, I desire the people to know it. Much as I admire the gentlemen composing that Council, they are the last men in Virginia to whom I would trust these appointments.
              </p>
              <p className="p-in-sp">I trust that it will be the pleasure of the Convention to put this matter in the hands of a competent committee, so that we will know whether we have altered our system of government to constitute a new and unknown power. I want to have it fixed, what power they are to have, and what responsibility they are to have.</p>
              <div className="speaker" id="sp5267"><span className="persName">Mr. GOODE</span>, of Bedford—</div>
              <p className="p-in-sp">I desire to state to the Convention that <PageNumber num={648} /> this resolution has been submitted this evening in accordance with the express desire of a member of the Council.</p>
              <div className="speaker" id="sp5268"><span className="persName">Mr. BOULDIN</span>, of Charlotte—</div>
              <p className="p-in-sp">
                We had this very question, as to whether this is wholly an Executive or Advisory Council already called to the attention of this Convention, and distinctly and clearly reported upon by a Committee. The House, by its vote, has decided it to be a mere Advisory Council-a Council not intended to control the Governor, but a Council which he is bound to consult upon all Executive acts. We
                have said distinctly that this Council cannot control the Executive; so that the difficulty suggested by the gentleman from Bedford
                [<span className="persName">Mr. GOODE</span>]
                , if it exists any where, cannot exist in the minds of the Council. The President of that Council addressed to this House a communication, saying, that to make them an Advisory Council in the sense indicated would be to make them an Executive Council. A committee was appointed to consider and report what, in their opinion, were the duties and the power of the Council; and they
                reported that the object in appointing the Advisory Council was not to control the Executive, but merely to consult with him.
              </p>
              <div className="speaker" id="sp5269"><span className="persName">Mr. SPEED</span>—</div>
              <p className="p-in-sp">I was a member of the committee with the gentleman from Charlotte [<span className="persName">Mr. BOULDIN</span>] , to whom was referred the resolution in reference to a definition of the powers and duties of the Advisory Council.</p>
              <p className="p-in-sp">
                I understood the object of the application of the President of the Council was to get an explanation as to what the object of the Convention was in appointing that Council. The sole duty of the committee was to explain the ordinance creating the Council, and such was the understanding of the committee, as I ascertained from its members, as well as of the Convention, so far as I could
                learn from conversation with several members. So that the general view as to the duty of the committee was to explain the ordinance creating the council, and not to inquire what should be the duty of the council in certain contingencies.
              </p>
              <p className="p-in-sp">But since that, I understand it is the desire of the members themselves that there should be some changes made in the powers conferred upon the council.</p>
              <p className="p-in-sp">
                It is now ascertained that the duties devolved upon the Governor and Council are so onerous that these four gentlemen are not able to discharge them. Hence a necessity has arisen for two additional members. Suppose a question of great importance were to arise, and the three members of the Council were on one side and the Governor
                <PageNumber num={649} /> on the other, who was to govern? If the question was put to me, I would say the majority of the Council should control the Governor. I think we ought to extend the powers of the Council, so far as in matters of difference arising between the Governor and Council, to give a majority of the latter more than an advisory power. I am
                satisfied that a committee ought to be appointed, and that the Governor and Council should be consulted upon the subject.
              </p>
              <div className="speaker" id="sp5270"><span className="persName">Mr. F. GREGORY, JR.</span>, of King William—</div>
              <p className="p-in-sp">I understand this to be a question of mere physical capacity. We have learned that the present Council is incapable of discharging the duties now devolving upon them, from physical inability, and not from any distrust of their own mental powers. It is therefore asked that the Council be increased.</p>
              <p className="p-in-sp">
                Now, sir, I am one of those who participated in the legislation of this body with reference to this Council, and I understood at the time the general opinion on this floor to be, that this was to be an Advisory Council-one which by no possibility could control the action of the Executive, but one which could merely advise the Executive upon all matters relating to his duty.
              </p>
              <p className="p-in-sp">I presume that the Executive and the Council will keep a record of their proceedings, and probably of the votes which they may cast upon any questions in respect to which there may be any conflict of opinion between them, so that the country may understand how they voted.</p>
              <p className="p-in-sp">We have learned that it is necessary for the purpose of discharging efficiently the duties of the Council to add to its number. I am disposed to consent to that increase, especially as I understand it is demanded by the able gentlemen who now compose that council.</p>
              <p className="p-in-sp">For this reason, sir, I am opposed to the resolution of the gentleman from Bedford [<span className="persName">Mr. GOODE</span>] , and move to lay it upon the table.</p>
              <div className="speaker" id="sp5271"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair is of opinion that that would carry the ordinance of the gentleman from Richmond with it.</p>
              <div className="speaker" id="sp5272"><span className="persName">Mr. GREGORY</span>—</div>
              <p className="p-in-sp">I withdraw the motion to lay on the table, and I call the previous question upon the resolution.</p>
              <p>The call was sustained, and the main question ordered to be put.</p>
              <div className="speaker" id="sp5273"><span className="persName">Mr. JOHNSTON</span>, of Lee and Scott—</div>
              <p className="p-in-sp">I desire to make an inquiry of the gentleman from Bedford, whether his resolution is an independent proposition or a substitute for the ordinance?</p>
              <div className="speaker" id="sp5274"><span className="persName">Mr. GOODE</span>—</div>
              <p className="p-in-sp">I offered it as an independent proposition.</p>
              <p>The question was then taken by a count on the resolution, and resulted, ayes 34, noes 35-no quorum.</p>
              <PageNumber num={650} />
              <p><span className="persName">The PRESIDENT</span> made a count of the House, and ascertained that there were 83 members present.</p>
              <div className="speaker" id="sp5275"><span className="persName">Mr. GOODE</span>—</div>
              <p className="p-in-sp">I call for the yeas and nays.</p>
              <p>
                The call was sustained, and the vote being taken by yeas and nays, resulted-yeas 39, nays 44-as follows :<span className="note" id="Note59"><span className="noteLabel">6</span>The Journal adds Miller to the negative votes listed here.</span>
              </p>
              <p>YEAS-Ambler, Aston, J. Barbour, Blow, Boyd, Branch, Brent, Cabell, Campbell, Caperton, Coffman, Dorman, Dulany, Early, Echols, French, Garland, Gillespie, Goggin, John Goode, Isbell, Peter C. Johnston, Leake, McComas, Macfarland, Marye, Morton, Nelson, Richardson, Rives, William C. Scott, Sea-well, Sharp, Speed, Spurlock, Sutherlin, Tredway, Wickham, Wilson-39.</p>
              <p>
                NAYS-Alfred M. Barbour, Blakey, Boisseau, Borst, Bouldin, Chambliss, Chapman, Conn, Robert Y. Conrad, Deskins, Flournoy, Gray, Gregory, Addison Hall, Cyrus Hall, Ephraim B. Hall, Harvie, Haymond, Holcombe, Holladay, Hunton, Kilby, Lawson, Marshall, Moffett, Morris, Moore, Neblett, Preston, Pugh, Randolph, Robert E. Scott, Sheffey, Slaughter, A. H. H. Stuart, Strange, Tayloe,
                Thornton, Robert H. Turner, Franklin P. Turner, Williams, Woods, John Janney-44.
              </p>
              <div className="speaker" id="sp5276"><span className="persName">Mr. MONTAGUE</span>, when his name was called, said:</div>
              <p className="p-in-sp">As my name is somewhat connected with the matter before the House, I would ask to be excused from voting either upon this or the ordinance.</p>
              <p>The House agreed to excuse him.</p>
              <div className="speaker" id="sp5277"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The question now recurs upon the adoption of the ordinance submitted by the gentleman from the city of Richmond [<span className="persName">Mr. RANDOLPH</span>] .</p>
              <div className="speaker" id="sp5278"><span className="persName">Mr. TURNER</span>, of Jackson—</div>
              <p className="p-in-sp">Upon that I call the previous question.</p>
              <p>The call was sustained, and the main question being put, resulted-ayes 44, noes 35.</p>
              <p>So the ordinance was adopted.</p>
              <div className="speaker" id="sp5279"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">I beg leave to offer the following resolution:</p>
              <p className="p-in-sp">Resolved, That the term of service of the members of this Convention shall terminate on the 4th Thursday in May next, and that on that day an election shall be held for delegates to a Convention to be called together upon the proclamation of the Governor-the members to be elected according to the provisions of the act of Assembly convening the present Convention.</p>
              <div className="speaker" id="sp5280"><span className="persName">Mr. DORMAN</span>—</div>
              <p className="p-in-sp">I make a point of order upon that resolution.</p>
              <p className="p-in-sp">We have a rule which prohibits any member from voting upon a question in which he is personally interested.</p>
              <div className="speaker" id="sp5281"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">I over rule the point of order.</p>
              <div className="speaker" id="sp5282"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">
                I was of opinion, sir, and so stated before this House went into secret session, that it ought to adjourn sine die whenever it would adjourn. Nothing has occurred since that time to change <PageNumber num={651} /> that opinion. I am now as I was then, of opinion that this Convention should go back to the people and return to them the power with which we
                have been entrusted.
              </p>
              <p className="p-in-sp">
                I think it is manifest that this body does not now fully represent the sentiments of the people. It is obvious, moreover, from the number of seats which are now vacant, and have been vacant for sometime, that the gentlemen who have occupied them, have ceased to feel any interest in the proceedings of this Convention; and I maintain that they ought not to be allowed to come back
                without the people having an opportunity to pass upon their conduct.
              </p>
              <p className="p-in-sp">There are many other reasons why this Convention should go before the people, and in order that gentlemen may have an opportunity of recording their names upon the resolution, I ask the yeas and nays upon it.</p>
              <p>The call was sustained.</p>
              <div className="speaker" id="sp5283"><span className="persName">Mr. SHEFFEY</span>—</div>
              <p className="p-in-sp">
                I regret exceedingly to differ with a gentleman with whom, in the main, I agreed. I regret that that gentleman has thought proper to offer such a proposition. Why is it that we who have been entrusted by the people of Virginia in this crisis with the control of the most important affairs concerning the State, shall voluntarily throw up that authority and go back to the people,
                acknowledging our incompetence, and asking them to appoint new delegates to this Convention? Why is it that we shall again go before the people and ask them to do what they have already done? Are we to stultify ourselves by throwing up a commission committed to us, and going back to the people and asking them to appoint others who are capable of performing the duty which we are not
                able to perform? It seems to me it would be a desertion of our posts, which I will not be guilty of. I, for one, will stand by it to the last, whatever the result may be.
              </p>
              <p className="p-in-sp">
                I have heard various propositions of this sort suggested; but I never for one moment will entertain such a proposition. The duty that the people have assigned to me and to you and this Convention, we have a right to perform, and will perform as long as that duty remains unfinished. If we can say here now that we have finished the duties with which we are charged, I am ready to
                adjourn sine die. But I should be recreant to my duty, I should be recreant to the trust committed to me, if I did not stand to my post and perform that duty as long as it remained to be performed.
              </p>
              <p className="p-in-sp">
                I wish that the gentleman who offered that resolution, with whom I have heretofore co-operated, and with whom I would be glad yet further to co-operate, would think proper to withdraw it. It places <PageNumber num={652} /> us in a very delicate position; in a position, as it were, of holding on by our votes to our present stations. If, however, the
                gentleman refuses to withdraw the resolution, I am ready to vote against it, and meet any responsibility which attaches to that course. If a proposition is made to take a recess, and meet again here after the election, I for one am ready to assume such a responsibility. I shall shrink from no responsibility where duty is concerned. We have a duty to perform here after the people
                shall have passed upon the ordinance of secession. If they shall ratify that ordinance, it will be our duty to make such changes in the organic law as the circumstances of the country may demand. If they shall reject it, then we have other duties to perform. I for one, without having any apprehensions of not being sustained by my people, will vote for performing my duty to the
                last-vote for resigning who may. I will say that we would not be true to ourselves; we would not be true to the people who sent us here; we would not be true to the State we represent; we would be deserters of our post and recreant to our duty, if we were to abandon our position now in this perilous condition of the country.
              </p>
              <p className="p-in-sp">It may be that the people might select wiser and better men than those who now compose this body; but I do not think we will be apt to think so or vote so. I, for one, without fear of consequences, am prepared to say that I will not surrender my position until my people invite me to do so.</p>
              <div className="speaker" id="sp5284"><span className="persName">Mr. CAPERTON</span>—</div>
              <p className="p-in-sp">I move the previous question.</p>
              <p>
                The call was sustained, and the main question being ordered to be put, the vote was taken by yeas and nays, and resulted-yeas 26, nays 61-as follows:<span className="note" id="Note60"
                ><span className="noteLabel">7</span>The Journal adds Morris to affirmative votes listed here; in the Journal the negative votes have Janney and R. E. Scott in addition to those listed here, but do not include Spurlock.</span
                >
              </p>
              <p>YEAS-Messrs. Ambler, A. M. Barbour, James Barbour, Blakey, Borst, Bruce, Chambliss, Chapman, Conn, Flournoy, Gregory, John Goode, Jr., Harvie, Hunton, Isbell, Leake, Marye, Montague, Morton, Randolph, Seawell, Strange, Sutherlin, Tredway, Williams-26.</p>
              <p>
                NAYS-Messrs. Aston, Baldwin, Baylor, Blow, Boisseau, Bouldin, Boyd, Branch, Brent, Cabell, Campbell, Caperton, Coffman, R. Y. Conrad, Deskins, Dorman, Dulany, Early, Echols, French, Garland, Gillespie, Gray, Goggin, Addison Hall, E. B. Hall, Haymond, Holcombe, Holladay, P. C. Johnston, Kent, Kilby, Lewis, McComas, Macfarland, Marshall, Miller, Moffett, Moore, Neblett, Nelson,
                Preston, Pugh, Richardson, Rives, Wm. C. Scott, Sharp, Sheffey, Sitlington, Slaughter, Speed, Spurlock, Alex. H. H. Stuart, Tayloe, Thornton, Robert H. Turner, Franklin P. Turner, Wickham, Wilson, Woods-61.
              </p>
              <p>So the resolution was rejected.</p>
              <div className="speaker" id="sp5285"><span className="persName">Mr. FLOURNOY</span>, of Halifax—</div>
              <p className="p-in-sp">I have a resolution to offer; but, in order to enable me to offer it, it will be necessary for me to move a <PageNumber num={653} /> reconsideration of the vote by which the number of five delegates to the Congress of the Southern Confederacy was agreed upon. I make that motion.</p>
              <p className="p-in-sp">My object is to increase the number to seven, with a view to add to the number now composing the delegation the names of James A. Seddon and Lucas P. Thompson.</p>
              <div className="speaker" id="sp5286"><span className="persName">Mr. BRANCH</span>—</div>
              <p className="p-in-sp">
                I trust it will be the pleasure of the Convention to reconsider that resolution. It is well known to the house that Mr. Seddon would have been elected but for my vote, which was cast for Mr. Rives, under peculiar circumstances. I voted for that gentleman because I understood there was to be no compromise on the part of the Breckinridge men. I determined that I would not vote to place
                on the delegation four of that party, the number which I understood it was contemplated to elect. It would seem that this was the purpose of the Breckinridge party, for we find them urging the claims of Mr. Seddon, Mr. Bocock and Mr. Russell, in addition to those whom they had elected. This satisfied me that they had no disposition to compromise, and being determined, if possible, to
                defeat that purpose, I voted for Mr. Rives against Mr. Seddon.
              </p>
              <p className="p-in-sp">I trust it will be the pleasure of the Convention to reconsider this vote.</p>
              <div className="speaker" id="sp5287"><span className="persName">Mr. WILSON</span>—</div>
              <p className="p-in-sp">I would suggest to the gentleman from Halifax to add another to the number, so as to afford a place for Mr. Charles W. Russell.</p>
              <div className="speaker" id="sp5288"><span className="persName">Mr. FLOURNOY</span>—</div>
              <p className="p-in-sp">
                I should suppose that would be out of proportion. I thought it would be a fair distribution to give four to the West and three to the East. There should moreover be an odd number in order to secure the casting of the vote of Virginia upon every question. The number eight might cause the vote of the State to be neutralised, and I therefore suggested the number seven.
              </p>
              <div className="speaker" id="sp5289"><span className="persName">Mr. BORST</span>—</div>
              <p className="p-in-sp">
                I would suggest to the gentleman, that Mr. Thompson and Judge Brockenbrough are within a stone throw of each other, and that Judge Brockenbrough would sufficiently represent that district without taking another from it. I maintain that the Valley of Virginia is entitled to the choice of one of the two whom the gentleman proposes to add to the number now chosen. I think the proper
                course would be to take Mr. James M. Mason. Indeed this arrangement is nothing more than is due to our section of the State. There is no justice, no propriety in taking two representatives from one section of the State, and excluding other sections from all share <PageNumber num={654} /> in this representation, and I trust the Convention will not agree
                to any such arrangement.
              </p>
              <div className="speaker" id="sp5290"><span className="persName">Mr. MOORE</span>, of Rockbridge—</div>
              <p className="p-in-sp">
                I hope that this House will not engage in this sort of child's play. We have decided this day that five should be sent to represent us in the Confederate States Congress; and I cannot now for my part consent to this plan of making places for particular parties contrary to the policy determined upon by the Convention after full debate. This seems to be the special purpose of the
                motion which has been made; and I trust the House will sanction no such proceeding. If five men were enough to-day, it is enough now. If we agree to elect seven, then we will have demands to increase the number to nine, and perhaps to fifteen.
              </p>
              <p className="p-in-sp">I trust the motion will not prevail.</p>
              <div className="speaker" id="sp5291"><span className="persName">Mr. SHEFFEY</span>—</div>
              <p className="p-in-sp">I call the previous question.</p>
              <p>The call was sustained, and the main question ordered to be put.</p>
              <div className="speaker" id="sp5292"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">I rise to a question of order.</p>
              <p className="p-in-sp">The motion of the gentleman from Halifax [<span className="persName">Mr. FLOURNOY</span>] , is not, I maintain, in order. If that resolution is reconsidered, it sets aside the elections which we have already made. The order of the House has been executed, and it cannot now be reconsidered.</p>
              <div className="speaker" id="sp5293"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair thinks the point is not well taken. The addition of two members will not affect the elections already made.</p>
              <div className="speaker" id="sp5294"><span className="persName">Mr. BOYD</span>, of Botetourt and Craig—</div>
              <p className="p-in-sp">I call the yeas and nays upon the resolution.</p>
              <p>The call was sustained, and the vote being taken, resulted-yeas 27, nays 58, as follows:</p>
              <p>YEAS-Messrs. James Barbour, Blakey, Borst, Bouldin, Branch, Chambliss, Dorman, Echols, Flournoy, Garland, Gregory, John Goode, Holcombe, Hunton, Leake, Montague, Morton, Neblett, Preston, Randolph, Richardson, Seawell, Speed, Sutherlin, Thornton, R. H. Turner, Wilson-27.</p>
              <p>NAYS-The President [John Janney]
                , Messrs. Ambler, Aston, Baldwin, A. M. Barbour, Baylor, Blow, Boisseau, Boyd, Brent, Bruce, Cabell, Campbell, Caperton, Chapman, Coffman, Conn, Robert Y. Conrad, Deskins, Dulany, Early, French, Gillespie, Gravely, Gray, Goggin, Addison Hall, E. B. Hall, Harvie, Haymond, Holladay, Isbell, Peter C. Johnston, Kilby, Lewis, McComas, Macfarland, Marshall, Marye, Moffett, Moore, Nelson,
                Pugh, Rives, Robert E. Scott, William C. Scott, Sharp, Sheffey, Sitlington, Slaughter, Spurlock, Alex. H. H. Stuart, Strange, Tayloe, Tredway, F. P. Turner, Wickham, Williams-58.
              </p>
              <div className="speaker" id="sp5295"><span className="persName">Mr. BLAKEY</span>—</div>
              <p className="p-in-sp">I move that the obligation of secrecy be removed in reference to the election of members to the Southern Congress, and also in reference to the addition to our Advisory Council.</p>
              <PageNumber num={655} />
              <div className="speaker" id="sp5296"><span className="persName">Mr. CONRAD</span>, of Frederick—</div>
              <p className="p-in-sp">I move to amend by including all the proceedings of the secret session.</p>
              <div className="speaker" id="sp5297"><span className="persName">Mr. CAPERTON</span>—</div>
              <p className="p-in-sp">I think it would be imprudent to do that. Some matters have transpired here which ought never to be published.</p>
              <div className="speaker" id="sp5298"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">I know of nothing that has been done here for which the obligation of secrecy ought not to be removed.</p>
              <p className="p-in-sp">
                As far as I know, everything that has transpired here ought to be published. I know of no harm that can result from it. I think it would be an act of injustice to those who left here with a belief that everything done in this body would be revealed, to withhold anything from publication. If any member can point to anything that ought not to be published, I will agree to its
                suppression.
              </p>
              <div className="speaker" id="sp5299"><span className="persName">Mr. CAPERTON</span>—</div>
              <p className="p-in-sp">I want to know if there have not been one or two reports here in reference to the consideration of our supplies, arms, &amp;c., which it would not be proper to publish.</p>
              <div className="speaker" id="sp5300"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">I think if these reports should appear, they would show that our condition is better than that of any State in the Union.</p>
              <div className="speaker" id="sp5301"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">I do not understand that this motion has reference to the debates.</p>
              <div className="speaker" id="sp5302"><span className="persName">Mr. CONRAD</span>—</div>
              <p className="p-in-sp">I do not mean my motion to refer to the publication immediately, but merely to remove the injunction of secrecy.</p>
              <div className="speaker" id="sp5303"><span className="persName">Mr. BLAKEY</span>—</div>
              <p className="p-in-sp">I would suggest to the gentleman that he withdraw his proposition, and allow my motion to prevail, so that we may take time to consider the other question of removing the secrecy generally.</p>
              <div className="speaker" id="sp5304"><span className="persName">Mr. CONRAD</span>—</div>
              <p className="p-in-sp">
                I will repeat what I understand to be the purport of the amendment as offered by me. It is not to require the publication of anything. The votes<span className="note" id="Note61"><span className="noteLabel">8</span>This word probably should be "notes."</span> of the debates as well as the journal are still under the direction of the Convention. My motion simply proposes to remove the
                injunction of secrecy, so far as relates to the members of this Convention. I desire, when I go home, to have the privilege of making any statement in reference to the proceedings of the Convention that I desire to make.
              </p>
              <div className="speaker" id="sp5305"><span className="persName">Mr. BLAKEY</span>—</div>
              <p className="p-in-sp">
                As soon as the seal of secrecy is removed, all these questions will become matters of conversation, and will ultimately get into the newspapers. The objection does not exist simply in reference to the delegates, stating whatever they may desire relative to the proceedings of the Convention, but to the danger of facts appearing in the newspapers in an imperfect and distorted form, as
                they probably would.
              </p>
              <div className="speaker" id="sp5306"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">
                What was the reporter retained for, if not to publish our debates and proceedings. I should suppose that it would be far <PageNumber num={656} /> better to permit the publication of the debates as they are accurately taken down by an accomplished reporter, than allow what transpires here to be distorted by Tom, Dick and Harry, after they shall have
                received some little intimation of what has transpired here. There may be, and no doubt are, many things which ought not to be published; but so far as the general action of the Convention is concerned, I demand that the people shall know it. I have a right as representative here, to let my people know what has been done by this body. I do not consider that I have given any
                obligation to withhold from whomever I please the action of this Convention. If I am under such a restraint, I trust that I may be convinced of that; so that I may do nothing unbecoming a gentleman, or in derogation of my position here as a member of this Convention. If I am not satisfied upon this point, I shall feel authorized to state what has taken place here.
              </p>
              <div className="speaker" id="sp5307"><span className="persName">Mr. BAYLOR</span>, of Augusta—</div>
              <p className="p-in-sp">I am entirely opposed to the amendment offered by the gentleman from Frederick [<span className="persName">Mr. CONRAD</span>]
                , if it be understood as proposing merely to remove the injunction of secrecy so far as to allow members to talk to their constituents about the proceedings here. But if it be understood as proposing to permit the publication of the whole proceedings in an authentic form, and thus preventing the publication in the newspapers of garbled and imperfect accounts, I am willing to vote for
                it. I have no objection to my votes here being published to the world; but I desire to have accompanied with them all the explanations that have been made in connection with them. I want all to go before the public, or none at all.
              </p>
              <div className="speaker" id="sp5308"><span className="persName">Mr. SHEFFEY</span>—</div>
              <p className="p-in-sp">
                It does seem to me that in reference to all matters concerning our financial and military condition, it would be unwise and injudicious in this Convention to publish its proceedings. We have been engaged here for two weeks or more in secret session about matters of defence; we have, in fact, been acting as a council of war, and we are now called upon to show to our enemy our weak
                points as well as our strong points. The very object of going into secret session, and the very object of imposing the injunction of secrecy upon the members of this body was that those matters which belong to ourselves, which are for our own guidance and defence, should be kept to ourselves and from our enemies; and it seems to me that it would be the height of folly, if not
                madness, to remove that injunction of secrecy, and to expose our weakness in a financial and military point of view.
              </p>
              <p className="p-in-sp">Let me call attention to the remarks of the gentleman from Franklin [<span className="persName">Mr. EARLY</span>] , uttered some days ago. He told us that we had but a <PageNumber num={657} /> very limited supply of percussion caps-40,000, I believe-and that we were told by the gentleman from Richmond [<span className="persName">Mr. RANDOLPH</span>]
                , that 240,000 have been purchased, and all except 40,000 had been distributed. Are we to disclose such facts as these to the world? We are trying to draw to us the aid of the other border slave States; and will we not defeat that object by exposing our weakness to them? We are not as weak as gentlemen suppose here; but if these proceedings go before the world-the proceedings, I mean
                in reference to our military condition and financial operations-it is but telling to the enemy that which, in all councils of war, are required to be kept secret.
              </p>
              <p className="p-in-sp">
                I do think that it would be improper in us now to publish any of these proceedings, except those specially required to be published. It is suggested to me by one who has seen service, by one who knows the necessity of prudence and caution, and secrecy in council, and in camp, that success always depends upon the secrecy of army operations; and we are now called upon, unwisely as I
                humbly suggest, to disclose to our adversaries all our points of defence, both weak and strong. I am willing to remove the injunction of secrecy as to any matter which ought to be made public for the interest of the State; but I am utterly opposed to publishing that which relates to our condition of defence.
              </p>
              <div className="speaker" id="sp5309"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">I rise to endorse most cordially the remarks of the gentleman from Smyth [<span className="persName">Mr. SHEFFEY</span>] . I think nothing in relation to our financial or military operations ought to be published. Neither do I think that the vote upon the ordinance of secession ought to be published; because when that is published, I trust it will be with an almost unanimous vote in favor of it.</p>
              <div className="speaker" id="sp5310"><span className="persName">Mr. BORST</span>—</div>
              <p className="p-in-sp">I move to lay the amendment of the gentleman from Frederick [<span className="persName">Mr. CONRAD</span>] , on the table.</p>
              <div className="speaker" id="sp5311"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">I call the yeas and nays upon that motion.</p>
              <div className="speaker" id="sp5312"><span className="persName">Mr. BORST</span>—</div>
              <p className="p-in-sp">I withdraw the motion.</p>
              <div className="speaker" id="sp5313"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">I call the yeas and nays upon the motion of the gentleman from Frederick.</p>
              <p>
                The call was sustained; and the vote being taken, resulted-yeas 27, nays 60-as follows:<span className="note" id="Note62"><span className="noteLabel">9</span>The Journal adds F. P. Turner to the negative votes listed here.</span>
              </p>
              <p>YEAS-Messrs. Ambler, Alfred M. Barbour, Boisseau, Bouldin, Brent, Cabell, Carter, Chambliss, Conn, Robt. Y. Conrad, Critcher, Early, Gray, Gregory, John Goode, E. B. Hall, L. S. Hall, Harvie, Hunton, Kent, Kilby, Lewis, Morris, Moore, Rives, Wilson and John Janney [President] -27.</p>
              <p>
                NAYS-Messrs. Aston, Baldwin, Baylor, Blakey, Blow, Borst, Boyd, Branch, Bruce, Campbell, Caperton, Coffman, Deskins, Dulany, Echols, Flournoy, French, <PageNumber num={658} /> Garland, Gillespie, Gravely, Goggin, Addison Hall, Haymond, Holcombe, Holladay, Isbell, P. C. Johnston, Lawson, Leake, McComas, Macfarland, Jas. Marshall, Marye, Miller, Moffett,
                Montague, Morton, Neblett, Nelson, Preston, Pugh, Geo. W. Randolph, Richardson, Robert E. Scott, Wm. C. Scott, Sharp, Sheffey, Slaughter, Speed, Spurlock, A. H. H. Stuart, Strange, Sutherlin, Tayloe, Thornton, R. H. Turner, Wickham, Williams and Woods-60.
              </p>
              <p>So the motion was rejected.</p>
              <p>The question then recurred upon the motion made by <span className="persName">Mr. BLAKEY</span>, and it was agreed to.</p>
              <div className="speaker" id="sp5314"><span className="persName">Mr. KILBY</span>—</div>
              <p className="p-in-sp">
                I beg leave to state that the corporations of the cities of Richmond, Norfolk and Petersburg, and others, have authorized the issue of small notes; and it is well known that the act of Assembly requires commissioners of the revenue to administer to merchants an oath compelling them neither to receive nor pay out any notes of a less denomination than five dollars, while it imposes a
                penalty on all for receiving or paying them out. I think it is due to the people that we should relieve them from the penalty, and, with that view, I will offer the following ordinance :
              </p>
              <p className="p-in-sp">"1. Be it ordained by the Convention, That all laws now in force prohibiting the receiving, passing, paying out, or circulating small notes, be and the same are hereby suspended.</p>
              <p className="p-in-sp">"2. That all merchants and others who have taken the oath required by law not to pass or circulate small notes be released and exonerated from its obligation.</p>
              <p className="p-in-sp">"3. That this ordinance shall be in force until the first day of March next, and may be altered, amended or repealed by this Convention, or the General Assembly of Virginia."</p>
              <div className="speaker" id="sp5315"><span className="persName">Mr. BRANCH</span>—</div>
              <p className="p-in-sp">I rise for the purpose of calling attention to the ordinance on the subject of small notes passed by the City Council of Petersburg.</p>
              <p className="p-in-sp">I will read a portion of it :</p>
              <p className="p-in-sp">
                "That there shall be issued, in the name of the city of Petersburg, $50,000 in notes of $1, 50 cents, and 25 cents, for the redemption of which the Common Council pledges the faith of the city, and which shall be redeemable by the Chamberlain thereof in Virginia bank notes, when presented to him in sums of five dollars, or the multiple of that sum, and which shall be receivable for
                licenses and other taxes, rents of houses, water rents, or any indebtedness whatever due or payable to the city of Petersburg."
              </p>
              <p className="p-in-sp">
                By that it will be seen that the city of Petersburg does not propose to issue money to pay debts at all; but it proposes to issue five, ten or <PageNumber num={659} /> twenty of the small notes of 25, 50, or 75 cents to any gentleman who will bring a $5, a $10 or a $20 note. It is simply designed for the convenience of the trade of the city of
                Petersburg and the payment of laborers by the day. It is impossible to get silver to do it unless you go and pay ten or fifteen per cent. to the brokers. If a dollar is turned over six days in the week, the broker charges 60 cents upon it. If you do not permit these notes to be issued, what is to be the consequence?
              </p>
              <p className="p-in-sp">
                I will say that notes for $100,000 are drawn on the Farmers' Bank of this city, and setting down the fractional part of a dollar to be fifty cents, one hundred thousand will draw from that bank $50,000, and if the Farmers' Bank has only $10,000, she can only issue $300,000, and $50,000 upon the fractional part of $100,000. The consequence will be that $50,000 of paper money will be
                drawn from circulation. The question then arises, whether you can afford to withdraw that amount from circulation. I do not recollect the statement of the Farmers' Bank, nor in fact any of them; but I question whether that bank has one hundred thousand of specie in her vaults now.
              </p>
              <div className="speaker" id="sp5316"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">Yes, a great deal more. In proportion as you draw specie from the bank, just so far you reduce the circulation of paper. Is the country at this time prepared to lose its circulation of paper money?</p>
              <div className="speaker" id="sp5317"><span className="persName">Mr. KILBY</span>—</div>
              <p className="p-in-sp">
                An objection has been suggested to me, that this ordinance applies to notes other than bank notes. I drew it in that form, so that it may only apply to bank notes. I have said nothing about any class of notes, for the simple reason that I did not desire to have anything in the ordinance legalizing any other issues than those already legalized. But if corporations seem willing to
                issue small notes, it is proper we should relieve merchants from committing perjury every hour of the day.
              </p>
              <div className="speaker" id="sp5318"><span className="persName">Mr. MORRIS</span>, of Caroline—</div>
              <p className="p-in-sp">I would suggest to the gentleman to change the time when this ordinance is to expire, so as to make it conform to the period fixed for the expiration of the ordinance authorizing the issue of small notes.</p>
              <div className="speaker" id="sp5319"><span className="persName">Mr. KILBY</span>—</div>
              <p className="p-in-sp">I strike out the words "until the first day of March next," and substitute the words "for thirty days after the meeting of the General Assembly," so as to make it read, "this ordinance shall be in force for thirty days after the meeting of the General Assembly, &amp;c." It will thus correspond with the ordinance authorizing the issue of small notes.</p>
              <div className="speaker" id="sp5320"><span className="persName">Mr. WICKHAM</span>—</div>
              <p className="p-in-sp">I desire to amend the ordinance of the gentleman <PageNumber num={660} /> from Nansemond [<span className="persName">Mr. KILBY</span>] , by adding to the first branch of it, the following:</p>
              <p className="p-in-sp">"So far as the issue of small notes by the banks of this Commonwealth, and by the Councils of incorporated towns and cities of this State are concerned."</p>
              <p className="p-in-sp">This, as will be seen, is intended to make the suspension of the anti small note law applicable to the banks which are already authorized to issue these small notes, and to the incorporated towns and cities of the State.</p>
              <p className="p-in-sp">I was one of those who advocated the issuing of notes by the banks under the denomination of five dollars, and endeavored at the same time to have the ordinance so amended as to confine the issue to these institutions. The effect of that ordinance without this amendment will be to flood this State, at once, with the issues of every broker in the Commonwealth.</p>
              <p className="p-in-sp">We shall have issues put in circulation immediately upon the passage of this ordinance of every denomination, down to twelve and a half cents.</p>
              <p className="p-in-sp">It seems to me that the banks of the Commonwealth will afford a sufficient amount of small notes for all purposes.</p>
              <p className="p-in-sp">If this ordinance is adopted as it is presented we will have thousands of these shin-plasters, of all denominations, issued by every irresponsible broker in the State.</p>
              <div className="speaker" id="sp5321"><span className="persName">Mr. HALL</span>, of Marion—</div>
              <p className="p-in-sp">I move to amend the amendment by striking out the words, "and by the Councils of incorporated towns and cities of this State."</p>
              <div className="speaker" id="sp5322"><span className="persName">Mr. HAYMOND</span>—</div>
              <p className="p-in-sp">It is but a short time since the Convention passed an ordinance authorizing the banks to issue small notes to the extent of five per cent. upon their capital. Gentlemen will find that if the banks avail themselves of that ordinance we will have added to our circulation $800,000.</p>
              <p className="p-in-sp">
                It is now proposed, in addition to authorizing the banks to issue these notes, to extend the same privilege to corporate towns, for the purpose of carrying on a war against the Abolitionists. If we have no other means of carrying on a war than that, we had better seek a peace at once. Whenever it becomes necessary for the State to resort to shinplaster currency of a different
                character from that already authorized, its condition, indeed, must be deplorable. It is bad enough to have to authorize the issue of these notes by any institution, however stable and solvent; but to permit an enlargement of these issues by corporations and irresponsible individuals, just at the heel <PageNumber num={661} /> of the passage of the
                ordinance of secession, and before the people have ratified our action, to open the flood-gate for every rotten currency that it is possible for man to conceive, is a policy which seems to me to be extremely unwise-nay, suicidal to the best interests of the State. Are we prepared to do this thing? Ought we not wait until we see whether it will be necessary to resort to it?
              </p>
              <p className="p-in-sp">
                I object to this ordinance upon principle, and not because I am disposed to be captious. The measure is pregnant with mischief, and ought to be passed.<span className="note" id="Note63"><span className="noteLabel">10</span>The speaker must have said "ought not to be passed."</span>
              </p>
              <p className="p-in-sp">I therefore move to lay the ordinance and amendment on the table.</p>
              <p>The motion was agreed to.</p>
              <div className="speaker" id="sp5323"><span className="persName">Mr. BOISSEAU</span>, of Dinwiddie—</div>
              <p className="p-in-sp">I move to take up the ordinance concerning the stay of executions. I make that motion for a reason personal to myself.</p>
              <div className="speaker" id="sp5324"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">I will state that there is an amendment now pending to that ordinance, which is in the hands of the printer.</p>
              <div className="speaker" id="sp5325"><span className="persName">Mr. BOISSEAU</span>—</div>
              <p className="p-in-sp">
                I desire to state that there is a sale advertised for Monday, in which I am interested, and I am obliged to leave here to-morrow to attend to it, unless the Convention acts upon this stay law. If it passes in the shape in which it is reported, no sale will take place; but in order to enable me to determine how I must act, it is absolutely necessary to take up the ordinance and act
                upon it.
              </p>
              <p className="p-in-sp">I hope it will be the pleasure of the Convention to agree to this motion to take up.</p>
              <p>The question was then on the motion, and resulted-ayes 35, noes 34; no quorum voting.</p>
              <div className="speaker" id="sp5326"><span className="persName">Mr. BORST</span>—</div>
              <p className="p-in-sp">I move we adjourn.</p>
              <p>The question was put on this motion, and resulted-ayes 49, noes 30. So the Convention agreed to adjourn.</p>
            </div>
          </div>
          <div className="day" id="vsc1965.v4.2.14">
            <PageNumber num={662} />
            <h2><span className="headingNumber">1.14. </span><span className="head">FOURTEENTH DAY</span></h2>
            <div className="dateline">Tuesday, <span className="date">April 30</span></div>
            <p>The Convention assembled at 10 o'clock.</p>
            <p>Prayer by the Rev. <span className="persName">Mr. SPURLOCK</span>, the delegate from Wayne county. The Secretary called over the list of committees, when</p>
            <p><span className="persName">Mr. MACFARLAND</span>, from the Finance Committee, reported an ordinance to authorize the issue of treasury notes, which reads as follows:</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>
                  1. Be it ordained, That the Governor, for the purpose of raising money for the defence of the State, is hereby authorized to direct the Auditor of Public Accounts to borrow for the Commonwealth of Virginia, from time to time, an amount not exceeding in the aggregate two millions of dollars; and for that purpose the said Auditor, on the order of the Governor, shall issue treasury
                  notes for the amount so directed to be borrowed, in sums not less than twenty dollars.
                </p>
                <p>
                  2. That said treasury notes shall be prepared under the direction of the Governor, and shall be signed by the Treasurer and countersigned by the Auditor of Public Accounts, but shall not be delivered by the said Auditor except upon the receipt of the Treasurer that the par value thereof has been paid into the Treasury. The said notes shall be made payable to bearer, and be made
                  payable at the Treasury of the State one year after their respective dates.
                </p>
                <p>They shall bear interest at a rate not exceeding six per centum per annum from the date of their issue until redeemable. For the payment of the interest and redemption of the principal, as set out in said notes, the faith of the Commonwealth of Virginia is hereby pledged.</p>
                <p>
                  3. The Auditor of Public Accounts is hereby directed to cause to be redeemed all treasury notes, principal and interest, at the time when the same are redeemable and presented for payment, to be paid out of any money in treasury not otherwise appropriated; and if the said notes be not presented within twelve months after the same are redeemable, the said Auditor, if there be funds
                  in the treasury sufficient to pay the said notes, shall advertise for the same to be brought in on a given day, and after such day the interest thereon shall cease.
                </p>
                <p>
                  4. Whenever any of said notes shall be redeemed by the Auditor as herein provided, the same shall be cancelled by him and be delivered to the Treasurer, to be preserved in his office; and from time to time an amount equal to the sum so cancelled may, by order of the <PageNumber num={663} /> Governor, be again issued, subject to all the provisions
                  herein prescribed.
                </p>
                <p>5. The Auditor of Public Accounts and the Treasurer shall each keep a full and accurate account of the number, date, denomination and amount of all the notes signed by them, respectively, and in like manner of all the said notes redeemed and cancelled.</p>
                <p>6. For defraying the expenses of preparing, engraving and printing said treasury notes, a sum not exceeding two thousand dollars is hereby appropriated, to be paid, by order of the Governor, out of any money in the treasury not otherwise appropriated; and the plate or plates shall be deposited for safe keeping in one of the banks of this city, until otherwise provided by law.</p>
                <p>7. All the provisions of the third, fourth and sixth sections of chapter 193 of the Code, applicable to bank notes, shall be held to apply and relate in their effect to the treasury notes directed to be issued by this ordinance.</p>
                <p>8. It shall be lawful for the banks of this Commonwealth to discount or purchase any note or notes issued under this ordinance, to receive the same on deposit and pay them out at their counters.</p>
                <p>9. The treasury notes to be issued under the provisions of this ordinance shall be received in payment of all taxes or other dues to the Commonwealth. Authority is hereby reserved to the General Assembly to arrest and suspend the issue of treasury notes hereinbefore provided for.</p>
              </div>
            </div>
            <div className="speaker" id="sp5327"><span className="persName">Mr. BLAKEY</span>—</div>
            <p className="p-in-sp">It seems to me that this is a matter of too great importance to act upon immediately.</p>
            <p className="p-in-sp">I move it be laid upon the table and printed.</p>
            <div className="speaker" id="sp5328"><span className="persName">Mr. MACFARLAND</span>—</div>
            <p className="p-in-sp">
              I beg leave to say that this ordinance authorizes the borrowing of two millions of dollars. The Auditor recommended that we should place the amount at five millions; but the committee were afraid that so large a sum would surprise the public, and, in truth, suppose that there was no need for so large a sum at this time, as this body will be in session again in all probability in two
              months or more. The ordinance provides for the issuing of two millions of treasury notes, which are to issue from time to time upon the order of the Governor, and are to be signed by the Treasurer and countersigned by the Auditor of Public Accounts.
            </p>
            <p className="p-in-sp">
              These safe guards, in the opinion of the committee, are sufficient to prevent fraud. These notes will circulate as a medium of circulation to a large extent. The committee decided not to order them below twenty dollars; and I beg leave to add that that was against the <PageNumber num={664} /> recommendation of the Auditor, who suggested that they should
              be as small as five. But the committee was of opinion that they should not be brought below twenty dollars, and the principal reason of the committee for coming to that decision was that the Legislature, in their recent law authorizing the issue of treasury notes, fixed the lowest denomination at twenty dollars.
            </p>
            <p className="p-in-sp">I beg leave to say that if the Convention has given me its attention, it is now in possession of all the important provisions of this ordinance, and as well prepared to act upon it now as at any other time.</p>
            <div className="speaker" id="sp5329"><span className="persName">Mr. BLAKEY</span>—</div>
            <p className="p-in-sp">
              I beg the Convention to bear in mind the effects of this precipitate legislation in other matters of great importance. The Convention will bear in mind the effect of it in our military organization. To-day we have passed an ordinance which yesterday was deemed altogether unfit, and instead of saving time, we have lost time by this system of hasty and precipitate action.
            </p>
            <p className="p-in-sp">
              Here is a bill of the greatest magnitude, that has just this moment been submitted to the consideration of the Convention, and we are called upon now to act upon it. I do not know how it is with others, but for myself I am not quick enough to comprehend at a glance the bearing of a proposition like this. I do not say that I am prepared to oppose it. I have no doubt the main provisions
              of the bill are correct. But where is the necessity for hurry? Cannot the members of this Convention wait here to consider this calmly? If they are not willing to wait until to-morrow, let this ordinance lie on the table until this evening. I am willing to wait until twelve to-night, if necessary, in order to act upon it.
            </p>
            <p className="p-in-sp">
              There is one feature in that bill, if I understand it correctly, that I am not inclined to approve at this time. If I understand it, it requires before any of these treasury notes shall be issued, that the money shall be first paid in for them. Now, if these bills are to circulate as a medium, where is the objection to paying them out in satisfaction for what the State owes, to every
              one who was willing to receive them. I don't see the necessity of waiting to issue them until some body comes in and pays money for them. As this seems to be the day for small notes, if banks are to be permitted to issue one dollar notes, and corporations notes of the denomination of 75 cents, 50 cents, and 25 cents, I don't see why the Commonwealth of Virginia may not come down to
              five dollar notes. If this Commonwealth is to be flooded with shin-plasters, I don't see how the credit of the Commonwealth is to be injured by issuing five and ten dollar notes. These are features in the bill which probably could be improved by consideration, <PageNumber num={665} /> and a little more than seems to be the purpose of the chairman of the
              committee to afford.
            </p>
            <p className="p-in-sp">I will modify my proposition by moving that it be laid upon the table for the present.</p>
            <div className="speaker" id="sp5330"><span className="persName">Mr. MACFARLAND</span>—</div>
            <p className="p-in-sp">Will the gentleman withdraw the motion for a moment, and I shall renew it.</p>
            <div className="speaker" id="sp5331"><span className="persName">Mr. BLAKEY</span>—</div>
            <p className="p-in-sp">I withdraw it.</p>
            <div className="speaker" id="sp5332"><span className="persName">Mr. MACFARLAND</span>—</div>
            <p className="p-in-sp">
              The gentleman is mistaken in supposing that there is anything novel in the provisions of the ordinance in respect to the issue of these Treasury notes. As I understand, it is the established usage of the office of the Auditor never to pay out a bond for any other obligation of the State, unless all these forms be observed. I presume, in ordinary cases of disbursements, the creditor of
              the Commonwealth, who is willing to receive in payment these Treasury notes, can very well receive them under this ordinance, the provisions of which are in strict accordance with the usage of the office. I now renew the motion to lay on the table as I promised.
            </p>
            <div className="speaker" id="sp5333"><span className="persName">Mr. AMBLER</span>—</div>
            <p className="p-in-sp">I would ask the gentleman to withdraw it for a moment.</p>
            <div className="speaker" id="sp5334"><span className="persName">Mr. BLAKEY</span>—</div>
            <p className="p-in-sp">I withdraw it.</p>
            <div className="speaker" id="sp5335"></div>
            <p className="p-in-sp"><span className="persName">Mr. AMBLER</span>-</p>
            <p className="p-in-sp">I think the objection of the gentleman from Madison [<span className="persName">Mr. BLAKEY</span>] , is well taken.</p>
            <p className="p-in-sp">I will read how it is provided that these Treasury notes shall get into circulation:</p>
            <p className="p-in-sp">"The said Treasury notes shall be prepared under the direction of the Governor, and shall be signed by the Treasurer and countersigned by the Auditor of Public Accounts, but shall not be delivered by the said Auditor, except upon the receipt of the Treasurer that the par value thereof has been paid into the Treasury."</p>
            <div className="speaker" id="sp5336"><span className="persName">Mr. MACFARLAND</span>—</div>
            <p className="p-in-sp">The gentleman will find that there is a similar provision by which bonds are authorized to be issued from time to time to contractors by their depositing the amount in bank to the credit of the Treasurer.</p>
            <div className="speaker" id="sp5337"><span className="persName">Mr. AMBLER</span>—</div>
            <p className="p-in-sp">The Chairman of the Committee of Finance is entirely mistaken. The only mode in which these notes are paid out to contractors is to buy them. By this ordinance you would exclude from circulation these notes until the notes of banks, or gold or silver is paid into the Treasury.</p>
            <div className="speaker" id="sp5338"><span className="persName">Mr. MACFARLAND</span>—</div>
            <p className="p-in-sp">The gentleman will please give me his attention for a moment.</p>
            <p className="p-in-sp">Suppose there be a creditor for the sum of $100 who is willing to <PageNumber num={666} /> receive these Treasury notes in liquidation. He must go to the bank and deposit $100 to the credit of the Treasury; then he becomes entitled to $100 of Treasury notes.</p>
            <p className="p-in-sp">But yet he is unpaid, and that very fund which he has deposited is checked upon in satisfaction of his account.</p>
            <div className="speaker" id="sp5339"><span className="persName">Mr. AMBLER</span>—</div>
            <p className="p-in-sp">Why go through this form? According to the gentleman's own statement, if, for any purpose, any citizen of the State had furnished $100 worth of any value to the State, and he goes to get this money, he must go and raise $100 and deposit it to the credit of the Treasury before he gets his pay.</p>
            <p className="p-in-sp">Now, the object of this ordinance, as I understand it, is to enable the Commonwealth to avail herself of her credit to pay the claims upon her.</p>
            <div className="speaker" id="sp5340"><span className="persName">Mr. MACFARLAND</span>—</div>
            <p className="p-in-sp">That ordinance was drawn up at the suggestion of the Auditor of the Commonwealth.</p>
            <div className="speaker" id="sp5341"><span className="persName">Mr. AMBLER</span>—</div>
            <p className="p-in-sp">
              The Treasury note, after all, will be a mere certificate of debt, which can only be used after a man shall raise the amount in which the Commonwealth is indebted to him. I think this error should be corrected so far as to provide that these Treasury notes should be paid out directly, in discharge of any claims due of the Commonwealth, without compelling claimants to go through this
              ordeal.
            </p>
            <p className="p-in-sp">There is another serious error. It will be found that these notes are limited to $20. They ought to be as low as five, for the purpose of circulation. I hope that the ordinance will be laid upon the table, and time afforded for properly considering it.</p>
            <div className="speaker" id="sp5342"><span className="persName">Mr. SLAUGHTER</span>—</div>
            <p className="p-in-sp">That ordinance is drawn exactly in accordance with the law passed by the Legislature, and was regarded by the Committee as entirely suited to the purpose for which it is intended. Yet, for the sake of unanimity, to permit the ordinance to lie on the table, I renew the motion to lay on the table.</p>
            <div className="speaker" id="sp5343"><span className="persName">Mr. TYLER</span>—</div>
            <p className="p-in-sp">I desire to make a very plain inquiry of the Chairman of the Committee.</p>
            <p className="p-in-sp">
              If a creditor should present a claim, say for $1,000, it passed through the ordinary offices in the Departments below, and is presented at your banks, where, doubtless, you have on deposit a good many of these notes, will it be optional with the creditor to take the Treasury or your own notes? If you give him that option, and the treasury notes are wanted by him, there is no impediment
              to the circulation of these notes.
            </p>
            <div className="speaker" id="sp5344"><span className="persName">Mr. MACFARLAND</span>—</div>
            <p className="p-in-sp">
              I beg leave to say, that the habit now established <PageNumber num={667} /> in the Treasury office is an exact one, and is founded upon notions of safety and security, and the gentleman will at once see that if a change could be made to-day in the Treasury Department, you would lose a very important and essential check. There is no money received into the
              Treasury, nor any money paid out but upon security; and it must go to the credit of the Treasury account in bank. Now, if the Convention was to decide to except the issues of Treasury notes from the general habit in the Treasury Department, you would deprive the Treasury of the security which is found to exist in the habit established of carrying everything through the banks.
            </p>
            <div className="speaker" id="sp5345"><span className="persName">Mr. TYLER</span>—</div>
            <p className="p-in-sp">A man may desire a Treasury note in preference to the bank notes, and the question is, can he get it? He comes, and his claim is passed through all the forms of the offices below. It is presented at bank for payment, and the inquiry is, upon his expressing a preference for Treasury notes over your bank notes, will you indulge him in that preference?</p>
            <div className="speaker" id="sp5346"><span className="persName">Mr. MACFARLAND</span>—</div>
            <p className="p-in-sp">
              I beg leave to say, it would not be in the power of the bank to give the party applying the Treasury notes. He should go into bank in the first instance for the purpose of depositing an amount of money equivalent to the amount which he desires to draw in Treasury notes. He gets the bank certificate that he has deposited a given amount. He goes back to the Treasury with that, and
              receives from the Treasurer a check, and gets the amount in Treasury notes.
            </p>
            <div className="speaker" id="sp5347"><span className="persName">Mr. TYLER</span>—</div>
            <p className="p-in-sp">
              I want to secure some basis on which these Treasury notes should rest. I would prefer, decidedly, in the present view of the subject, that there should be an actual deposit of these Treasury notes made in the bank, to be issued out to the creditor of the Commonwealth, upon his presenting the Auditor's warrant to you at his own option. Circulating currently with your own paper, I should
              look to an extensive circulation of our notes; otherwise, not.
            </p>
            <div className="speaker" id="sp5348"><span className="persName">Mr. MACFARLAND</span>—</div>
            <p className="p-in-sp">
              I do not expect that these Treasury notes will circulate as extensively as bank notes, and for this reason: that they will be regarded as the more valuable of the two. It is an inflexible law of currency, that the better remains still while the inferior circulates freely. Every man wishes to get rid of that which is regarded the least reliable, while that which is most valuable is held
              up.
            </p>
            <div className="speaker" id="sp5349"><span className="persName">Mr. BLAKEY</span>—</div>
            <p className="p-in-sp">
              If a gentleman expends, for the use of the Commonwealth, $5,000 before he can get theSe Treasury notes, he has got to go and raise $5,000 more and deposit it in bank. I am satisfied that I am right in this, and that is the understanding of the Chairman of the Committee himself. A man who has used all his money in <PageNumber num={668} /> supplying the
              necessities of the Commonwealth has to get and deposit an amount equal to the amount of his claim, before he gets these Treasury notes.
            </p>
            <p className="p-in-sp">I should like to know how it is, that a man who has spent his money and given his labor to the Commonwealth, and thus made the Commonwealth his debtor, shall be compelled to go and borrow money that is less valuable than the treasury note, in order to get these Treasury notes.</p>
            <p className="p-in-sp">
              Why may he not be permitted to get these Treasury notes at the outset. I do not see why this Convention should adopt any such ordinance as that. I can well see how capitalists, having money less valuable, might invest it in Treasury notes; but there is no reason why the man who invests his all in procuring supplies for the Commonwealth should have to raise an equal amount to what he
              has expended, to deposit in bank before he can receive these Treasury notes.
            </p>
            <p className="p-in-sp">I trust it will be the pleasure of the Convention to lay the ordinance on the table, to enable the Convention to examine it.</p>
            <p>The question was then put on the motion of <span className="persName">Mr. SLAUGHTER</span>, to lay the ordinance on the table, and it was decided in the affirmative.</p>
            <div className="speaker" id="sp5350"><span className="persName">Mr. SCOTT</span>, of Powhatan—</div>
            <p className="p-in-sp">I am instructed by the Committee on Military Affairs, to report the following ordinance :</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>AN ORDINANCE</p>
                <p>Prescribing the pay of the Provisional Army, and of the Volunteer forces of the State of Virginia.</p>
                <p>
                  1. Be it ordained, That the Major General and Commander of the Military and Naval forces of the State of Virginia, shall be entitled to receive four thousand dollars per annum pay, which shall be his full and entire compensation, without any right to demand any other compensation or perquisite whatever. His aids shall each be entitled to twenty-four dollars monthly, in addition to
                  their pay in the line, ten dollars monthly for forage, and four rations per day. The said Commander in chief and his aids shall receive their pay and allowances monthly, or at such other times as they may choose.
                </p>
                <p>2. The pay, emoluments, and subsistence of the Provisional Army, and of the Volunteers in actual service, shall be the same with those of the Army of the Confederate States.</p>
                <p>3. This ordinance shall be in force from its adoption, and be subject to amendment, modification or repeal by this Convention, or by the General Assembly.</p>
              </div>
            </div>
            <PageNumber num={669} />
            <div className="speaker" id="sp5351"><span className="persName">Mr. EARLY</span>—</div>
            <p className="p-in-sp">I think that had better be laid on the table until we see whether we have a Major General in service.</p>
            <p className="p-in-sp">I make that motion.</p>
            <p>The motion was rejected.</p>
            <div className="speaker" id="sp5352"><span className="persName">Mr. JOHNSTON</span>, of Lee and Scott—</div>
            <p className="p-in-sp">I will state that the provision, so far as relates to the Commander-in-chief and his aids, is the same as in the United States service, with this exception: that the pay of the Commander-in-chief is fixed monthly, and forage, &amp;c., allowed.</p>
            <div className="speaker" id="sp5353"><span className="persName">Mr. MACFARLAND</span>—</div>
            <p className="p-in-sp">What is the pay?</p>
            <div className="speaker" id="sp5354"><span className="persName">Mr. JOHNSTON</span>—</div>
            <p className="p-in-sp">The only elements which we wanted, in order to make a precisely correct calculation was, to know what the allowances would amount to annually. Estimating the ration at 20 cents-I presume it is higher now-the whole allowances, including pay, rations and forage, would amount to nearly $3,800.</p>
            <p className="p-in-sp">We supposed that there would be some difference between the prices of rations now and then, and that $4,000 would perhaps be a fair allowance, in consideration of the advance in prices.</p>
            <div className="speaker" id="sp5355"><span className="persName">Mr. MACFARLAND</span>—</div>
            <p className="p-in-sp">I would beg leave to suggest to the gentleman, that as the Major General of Virginia takes a rank in which his expenses will be very large, we should increase his pay somewhat. I wish, therefore, that the gentleman would suggest a larger sum.</p>
            <p className="p-in-sp">I would suggest, for the approbation of the Committee, that his pay be put at $6,000 a year.</p>
            <div className="speaker" id="sp5356"><span className="persName">Mr. JOHNSTON</span>—</div>
            <p className="p-in-sp">The Committee decided that the best course was to put him upon the same footing upon which a Major General stands. If the Convention chooses to increase the pay, of course they can.</p>
            <div className="speaker" id="sp5357"><span className="persName">Mr. NELSON</span>, of Clarke—</div>
            <p className="p-in-sp">Is that pay confined to one Major General?</p>
            <div className="speaker" id="sp5358"><span className="persName">Mr. AMBLER</span>—</div>
            <p className="p-in-sp">I should like to call the attention of the members of the Committee to the fact that some gentlemen are under the impression that the pay of a Major General in the United States service amounts to about $7,000.</p>
            <div className="speaker" id="sp5359"><span className="persName">Mr. EARLY</span>—</div>
            <p className="p-in-sp">The pay and other allowances amounts to about that; but we are not in a condition to pay that sum. I propose to substitute $5,000 instead of $4,000.</p>
            <div className="speaker" id="sp5360"><span className="persName">Mr. SCOTT</span>, of Powhatan—</div>
            <p className="p-in-sp">In fixing the pay for a Major General, we looked into the laws of the United States, and ascertained that the money pay of a Major General was between $3,700 and $3,800 per annum. There were certain emoluments added, in the shape of rations, forage, &amp;c.</p>
            <div className="speaker" id="sp5361"><span className="persName">Mr. JOHNSTON</span>—</div>
            <p className="p-in-sp">Allow me to correct the gentleman. The pay, <PageNumber num={670} /> rations and forage amounted, according to the calculation, to nearly $3,800.</p>
            <div className="speaker" id="sp5362"><span className="persName">Mr. SCOTT</span>—</div>
            <p className="p-in-sp">I know that the gentleman from Lee made the calculation, and is no doubt correct.</p>
            <div className="speaker" id="sp5363"><span className="persName">Mr. MACFARLAND</span>—</div>
            <p className="p-in-sp">What was General Lee's pay before he got into our service?</p>
            <div className="speaker" id="sp5364"><span className="persName">Mr. SCOTT</span>—</div>
            <p className="p-in-sp">His pay was that of Colonel. The Committee allowed him what they considered sufficient.</p>
            <div className="speaker" id="sp5365"><span className="persName">Mr. MACFARLAND</span>—</div>
            <p className="p-in-sp">
              I hope the proposition to increase the pay to $5,000, at least, will commend itself to the approbation of the Convention. Gentlemen must recollect that something is due to the rank assigned to Maj. Gen. Lee. It is something more than a mere Major General. He is Major General, with the supplementary addition of Commander-in-Chief. If a Major General, holding simply that rank, received
              about four thousand dollars, is not something due to the higher rank which we have assigned to the distinguished gentleman in question? His headquarters will be here, where he will be exposed to large expenditures; and I presume that the pay of the Major General, fix it as we may, will be assumed by the Southern Confederacy, as soon as we become fairly consolidated with it. I think it
              will occasion no surprise to the authorities there, that we have put this estimate upon the services of that gentleman; but, on the contrary, will give rise to satisfaction, and elicit general approval.
            </p>
            <div className="speaker" id="sp5366"><span className="persName">Mr. MORTON</span>—</div>
            <p className="p-in-sp">-I hope the motion will not prevail. We are not in a situation now to be extra liberal. As to entertainments by the Commander-in-Chief, I think there will be no time for entertainments on his part.</p>
            <div className="speaker" id="sp5367"><span className="persName">Mr. STUART</span>, of Augusta—</div>
            <p className="p-in-sp">I desire to make an enquiry, for my own information.</p>
            <p className="p-in-sp">
              I was absent when the invitation was given to these officers, and as there has been no publication of the proceedings, I was not informed about the terms of that invitation. I had a general impression in my mind that when we invited these gentlemen we gave them the assurance that they would receive the same pay and rank that they received in the United States service. If that be the
              fact, I think the faith of the State is pledged, and it is our duty to carry out faithfully the terms of the invitation.
            </p>
            <div className="speaker" id="sp5368"><span className="persName">Mr. JOHNSTON</span>—</div>
            <p className="p-in-sp">
              I will say to the gentleman that the invitation to these officers simply provided that they should receive from the State an appointment not below that which they held in the service of the United States. In the case before us, the rank is much higher than <PageNumber num={671} /> that held in the United States service. We are not bound by the invitation
              given to give the pay and emoluments attached in the United States service, to the rank which we gave them here.
            </p>
            <div className="speaker" id="sp5369"><span className="persName">Mr. STUART</span>—</div>
            <p className="p-in-sp">
              If we had a general who ranked above Col. Lee in the United States army, he would receive the same rank and emoluments which he received from the United States. Now, Col. Lee, being a Colonel, must be receiving less pay than a brigadier general, to whom he was subordinate in the service of the United States. I do not know the rank of another officer late in the service of the United
              States-I mean Gen. Johnston. I believe he was a Brigadier General in the United States service. He would be entitled to the same rank here as in the United States service, and incidentally to it, the same pay.
            </p>
            <p className="p-in-sp">It appears to me that that ordinance ought to be subjected to revision by military men, so that there might be no cause of dissatisfaction.</p>
            <div className="speaker" id="sp5370"><span className="persName">Mr. BORST</span>—</div>
            <p className="p-in-sp">
              I beg leave to remark that the gentlemen composing the committee which reported this ordinance are military men, who are fully competent to judge of the relative ranks which these officers should hold in our army, and the pay to which they are entitled. We are told by one of the gentlemen composing that committee, in whose military skill and experience I have much confidence, that the
              compensation now allowed is larger, by two hundred dollars a year, than that allowed to similar officers in the United States service. The ordinance inviting these officers gave an assurance that they should receive the same rank and pay as they received in the United States service; but this goes beyond the assurance therein given; for, in respect to General Lee, he has been elevated
              to higher rank, and is now allowed more pay than is even allowed to Major Generals in the United States service.
            </p>
            <p className="p-in-sp">I am sure that that gentleman will be satisfied with the allowance here made; for, in resigning his commission and taking service in Virginia, he was actuated by motives vastly higher than the mere question of pay. We have not the means to pay extravagant salaries, and I am sure there is no need for such a policy in this instance, at least.</p>
            <p className="p-in-sp">I am surprised to see the gentleman from Franklin [<span className="persName">Mr. EARLY</span>]
              , who generally complains so much about the poverty of the State, favoring an increase of the pay of officers. This principle of giving large salaries is an unwise one, and I hope the Convention will see <PageNumber num={672} /> that such principles is not established here, especially at this time, when we are about forming a new government and in need of
              means.
            </p>
            <div className="speaker" id="sp5371"><span className="persName">Mr. AMBLER</span>—</div>
            <p className="p-in-sp">After the remarks of my friend over the way, it is proper I should say that I had a conversation with one of the military gentlemen who are here, and who resigned their commission in the United States service.</p>
            <p className="p-in-sp">
              I approached him for the purpose of getting information by which I might be governed in voting upon this question. He declined saying one word in connection with the subject. He merely remarked, "we came here to offer our services in any department you place us in-from carrying a musket up." That was the answer, and it should be met in the same spirit; and, so far as these officers are
              concerned, I hope it will be borne in mind that that is the spirit which actuates them all, and that they will be dealt with in a spirit of liberality in keeping with the exalted motives which actuated them in entering our service. I will say that this allowance of 4,000 is a niggardly allowance, and by no means adequate to the responsibilities of the position.
            </p>
            <div className="speaker" id="sp5372"><span className="persName">Mr. EARLY</span>—</div>
            <p className="p-in-sp">With regard to the pay of a Major General, especially a Major General commanding the army, I think my friend from Lee [<span className="persName">Mr. JoHNsToN</span>] , is mistaken in his calculation.</p>
            <p className="p-in-sp">
              I know that the Colonel of a regiment of infantry is entitled to over $2,200 a year. That was the pay during the war with Mexico, but it has, I think, been since increased. A Colonel of cavalry is entitled to over $3,000. A Major General is entitled to some eight or ten horses with a large number of servants and rations-all of which, when calculated, make a much larger amount than his
              monthly pay. I had the pay of a Major in the service, and my pay monthly was $50, but perquisites amounted to nearly double that. The Major General commanding the army has larger pay that any other Major General. This is necessary, for he has to have a number of horses besides a large number of servants, &amp;c.
            </p>
            <p className="p-in-sp">
              General Lee is put in a very inconvenient position. He has lately been in New Mexico, and his whole military equipment is in New York, so that he is completely cut off from it. I have been informed that he is a man in very moderate circumstances, and to get all his equipments, his horses, &amp;c., will require a large sum. Five thousand is a very moderate compensation, and, in my
              opinion, would not more than bear his expenses.
            </p>
            <div className="speaker" id="sp5373"><span className="persName">Mr. JOHNSON</span>—</div>
            <p className="p-in-sp">I have the material before me which shows that the gentleman is mistaken in the estimates which he has just suggested.</p>
            <p className="p-in-sp">This [referring to a book in his hand] is a revised edition of the <PageNumber num={673} /> United States laws regulating the army, and are the laws which are now in force in that service. It comes down to 1857, since which time no revision has been made that I am aware of.</p>
            <p className="p-in-sp">A Major General, according to these laws, is entitled to $200 a month pay, $20 for forage, and fifteen rations per day. If you make the calculation, you will find my estimate to be correct.</p>
            <div className="speaker" id="sp5374"><span className="persName">Mr. MORTON</span>—</div>
            <p className="p-in-sp">In regard to the remark of the gentleman from Louisa [<span className="persName">Mr. AMBLER</span>]
              , that the allowance of $4,000 was a niggardly allowance, I think it is due not only to the Military Committee, but to myself and others, that he should make some explanation of that term. I trust the gentleman used it without properly reflecting as to the bearing of the language. I think the gentlemen composing the Military Committee, or the gentleman who addressed you, are no more
              disposed to limit Gen. Lee to a miserable allowance than the gentleman from Louisa.
            </p>
            <div className="speaker" id="sp5375"><span className="persName">Mr. AMBLER</span>—</div>
            <p className="p-in-sp">I had no idea of casting a reflection upon any gentleman.</p>
            <div className="speaker" id="sp5376"><span className="persName">Mr. MORTON</span>—</div>
            <p className="p-in-sp">I am perfectly satisfied of that, and hence it was that I called the attention of the gentleman to the remark.</p>
            <p>The explanation that has been given by the gentleman from Franklin [<span className="persName">Mr. EARLY</span>] , as to the circumstances under which General Lee reached Virginia, will induce me to waive the objection which I made, and I will now go for the amendment, fixing his salary at $5,000.</p>
            <div className="speaker" id="sp5377"><span className="persName">Mr. SCOTT</span>, of Powhatan—</div>
            <p className="p-in-sp">We have just made a little calculation of the pay, allowances, &amp;c., of a Major General, as follows :</p>
            <p className="p-in-sp">Salary per annum $2,400</p>
            <p className="p-in-sp">For forage $20 a month, making for the whole year 240</p>
            <p className="p-in-sp">Fifteen rations a day, at 20 cents per ration, amounts in the year to 1,095</p>
            <p className="p-in-sp">Making in all, per annum $3,735</p>
            <p><span className="persName">Mr. KILBY</span> called the previous question; the call was sustained, and, the main question being put, which was upon the amendment offered by <span className="persName">Mr. EARLY</span> fixing $5,000 a year as the salary of the Major General, it was agreed to.</p>
            <div className="speaker" id="sp5378"><span className="persName">Mr. WICKHAM</span>—</div>
            <p className="p-in-sp">I am not aware whether the pay in the United States army is $4,000 or 5,000. I think it is necessary to look into this, and with that view, I move to lay the ordinance on the table.</p>
            <p>The motion was lost.</p>
            <PageNumber num={674} />
            <div className="speaker" id="sp5379"><span className="persName">Mr. EARLY</span>—</div>
            <p className="p-in-sp">I move this amendment.</p>
            <p className="p-in-sp">"Any other Major General in the service shall receive $300 per month, and shall receive no other perquisite or emolument."</p>
            <div className="speaker" id="sp5380"><span className="persName">Mr. TYLER</span>—</div>
            <p className="p-in-sp">
              It seems that we have fallen into confusion on this subject of a Major General. In the United States service there is but one Major General in full and complete command. There never was but one. Major General Scott commands the army of the United States. General Gaines commanded the Southern division of that army. You have Brigadier Generals under them; but as I said there is but one
              Major General. That, I know, was the case during my Administration, and is still. Now, you have three or four Major Generals-I do not know, in fact, how many. Your Major General, mark you, is not confined alone to Virginia. He is the oldest officer in the service of the States. When he is thrown in connection with the Southern army or the army of Virginia, he commands that army. You
              may have Major Generals by brevet under him; but he is to have full command. You have no Major General in the Confederate army, while we have three or four. This is entirely too many. You should have but one, and he should be in command of your regular army, and you should pay him liberally.
            </p>
            <div className="speaker" id="sp5381"><span className="persName">Mr. SCOTT</span>, of Powhatan—</div>
            <p className="p-in-sp">If any error has been committed in the appointment of Major Generals, it certainly has not been the result of any action of the committee. There have been appointed one of them as Commander in chief, and if there is any error in that, it has been the fault of the Convention.</p>
            <p className="p-in-sp">The other two Major Generals that have been appointed, are Gen. Gwynn and Gen. Johnston. They have been appointed Major Generals of Volunteers.</p>
            <p className="p-in-sp">
              The ordinance in regard to the volunteer forces of the State, was passed by the Convention before any Military Committee was appointed. That ordinance directed that the Governor should receive into the service of the State, volunteer companies by companies, and in no larger organizations; that he should organize these companies into regiments, brigades and divisions, and should appoint
              the necessary officers for these regiments, brigades and divisions. If he organized these volunteer forces into divisions, as the ordinance directed him to do, ex necessitate rei, he was compelled to appoint Major Generals for them; because a Major General does command a division. He may, perhaps, have gone too rapidly to work, and appointed these generals before there were any
              divisions formed; but whenever a division is <PageNumber num={675} /> organized, he is compelled to appoint a Major General to command them.
            </p>
            <p className="p-in-sp">
              Under the ordinance to which I refer, the Governor has appointed two Major Generals-Gwynn and Johnston. There is one thing that the committee have under consideration, and that is the pay of these Major Generals, for which we have, as yet, made no provision. We have provided for the pay of the Commander-in-Chief, and of all the Brigadier Generals, because we put them, when in actual
              service, on the same footing with Brigadier Generals of the same rank in the U. S. service.
            </p>
            <div className="speaker" id="sp5382"><span className="persName">Mr. WICKHAM</span>—</div>
            <p className="p-in-sp">A gentleman of this city has just received the following from the Secretary of State, Col. Munford:</p>
            <p className="p-in-sp">"The pay of a Major General, with rations, &amp;c., amounts to $4,282."</p>
            <p className="p-in-sp">There is, in addition to this, an allowance for horses, servants and a house, the amount of which the Secretary has not time to ascertain.</p>
            <p>The question was taken on the amendment proposed by <span className="persName">Mr. EARLY</span>, and it was rejected.</p>
            <p>The question then recurred on the adoption of the ordinance as amended.</p>
            <div className="speaker" id="sp5383"><span className="persName">Mr. MACFARLAND</span>—</div>
            <p className="p-in-sp">Before the Convention proceeds further, I will offer one remark more.</p>
            <p className="p-in-sp">I understand that the pay of the Major General in command is increased to $5,000. It now turns out that the pay of a Major General of the army of the United States amounts to about $7,000.</p>
            <p className="p-in-sp">Is there a gentleman in the Convention who is willing to put the pay of the Major General of our army at a lower figure than that; I should hope not, and I now move that the pay of Major General Lee be the same as that received in the United States service.</p>
            <p className="p-in-sp">If it be necessary to reconsider first the vote by which the amendment, fixing $5,000 as the pay, was adopted, I make that motion.</p>
            <p>The motion was lost-ayes 52, noes 30. Two-thirds not voting in the affirmative.</p>
            <div className="speaker" id="sp5384"><span className="persName">Mr. TYLER</span>—</div>
            <p className="p-in-sp">I move to lay the whole matter on the table to afford an opportunity for examining it.</p>
            <p>The motion was agreed to.</p>
            <div className="speaker" id="sp5385"><span className="persName">Mr. SCOTT</span>, of Powhatan—</div>
            <p className="p-in-sp">I beg leave to report from the Military Committee,</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>AN ORDINANCE concerning the Distribution of Arms.</p>
                <p>Be it ordained, That the Major General commanding the military</p>
                <PageNumber num={676} />
                <p>
                  and naval forces be, and he is hereby, authorized to cause arms, accoutrements and munitions of war to be issued to volunteer companies in the valley of the Kanawha river, and in the counties adjacent to or bordering on the Ohio river, and the Northern frontier, without regard to the regulations prescribed by the militia laws for the issue of such arms, accoutrements and munitions,
                  and he is empowered to prescribe regulations for the preservation and return of said arms, accoutrements and munitions, subject, however, to the constitutional power of the Governor as commander-in-chief.
                </p>
              </div>
            </div>
            <div className="speaker" id="sp5386"><span className="persName">Mr. EARLY</span>—</div>
            <p className="p-in-sp">
              I will state that it is necessary to have some provision of that sort. There are already here three very respectable gentlemen from the valley of the Kanawha, who have come for the purpose of getting arms. The country out there is in a state of excitement, and these gentlemen desire to organize forces there for defence. There are difficulties in the way of their procuring arms.
              According to the law as it now exists, volunteer companies must be organized and uniformed before they are entitled to arms, and it is to obviate that difficulty, and enable these people to procure arms without complying with these requirements, that this ordinance is reported.
            </p>
            <p className="p-in-sp">The gentleman from Jackson [<span className="persName">Mr. TURNER</span>]
              , knows the condition of that country and the disposition of the people out there to defend themselves. I understand now that there is a great deal of excitement in that region, owing to the fact that at some towns on the Ohio river they are stopping the steamboats as they go up and down, and compelling them to hoist the United States flag and to repudiate all sympathy with the
              Southern Confederacy. The people along the Kanawha valley are apprehending danger constantly, and they have, in fact, very few arms in that country. They ought to be authorized to have a battery of artillery; but if they are subjected to the ordinary provision of having their companies organized and uniformed before receiving the arms, they will not be able to get them in time to do
              any service.
            </p>
            <div className="speaker" id="sp5387"><span className="persName">Mr. WILSON</span>—</div>
            <p className="p-in-sp">
              I do not wish to be understood as throwing any obstacle in the way of sending arms to the Valley of the Kanawha; but I claim that the section of country I come from is more exposed than the Kanawha Valley. It is true that steamboats navigate up from the Ohio river to Charleston; but that navigation can be easily cut off. In my country steamboats come up to Fairmont. Our portion of the
              State is entirely cut off from every source whence we could procure arms or assistance. We are within 30 miles of the Pennsylvania line on one side, and sixty miles of the Ohio on the other.
            </p>
            <PageNumber num={677} />
            <p className="p-in-sp">
              I object to this ordinance because it provides for arming the people along the borders of the Kanawha Valley, while it makes no provision for my country. We are willing to take the arms upon the same terms as the county of Jackson and the other border counties. If a distribution is at all made, I maintain that there is no portion of the State more in need of them than that from which I
              come.
            </p>
            <p className="p-in-sp">I trust that some action will be had to secure a share of the arms for that section, so that our people may have the proper means of defence.</p>
            <div className="speaker" id="sp5388"><span className="persName">Mr. GEORGE BLOW, JR.</span>, of Norfolk city—</div>
            <p className="p-in-sp">It seems to me, sir, that we are discussing questions and progressing to a system of action that is calculated to produce serious injury to the State of Virginia.</p>
            <p className="p-in-sp">Sir, we are discussing a question here in regard to the distribution of arms to different portions of the State, just as if we had arsenals scattered all over the Commonwealth, and as if arms to any number could be commanded simply by the bidding.</p>
            <p className="p-in-sp">
              Now, the fact is, as your Governor knows, we are deficient in arms; that they cannot be procured in any way or by any means, and that we have but a small number of old flintlock muskets in our arsenals, and that the time is fast approaching when it will be necessary that we shall have the full strength of the State in the field. Now, if every exposed portion of Virginia is to be
              provided with,<span className="note" id="Note64"><span className="noteLabel">1</span>Evidently "arms" is omitted here.</span> out of the few we have, when your troops are called together for the purpose of defending the State, where are your arms to come from? The true policy, in my humble estimation is, that the arms now in possession of the State should be husbanded with the utmost care and
              caution. I have a right to put in a claim for arms, if the circumstance of exposure is to constitute a right to them; for the very town that I represent is now an armed camp. But, sir, I say let us hold on to our arms, and only distribute in conformity with the law in relation to the organization and equipment of the volunteers. Let them be distributed to companies as fast as they are
              organized and uniformed ; but if you depart from that rule, and distribute them without any restraint, such as that law imposes, you will not have a gun when you shall most need it. I trust, therefore, that we will hold on to what we have, and if there be exposed positions, let us send the men there fully armed, if arms cannot be procured in any other way, rather than scatter the arms
              we have over the State where they cannot be available when needed.
            </p>
            <p className="p-in-sp">Under the present regulation, it is only necessary for a company to be organized and uniformed in order to get arms. This is a wise precau-</p>
            <PageNumber num={678} />
            <p className="p-in-sp">tion, inasmuch as it secures the possession of the arms to regularly organized companies in whose hands they must necessarily prove of more advantage to the State than if scattered among individuals, uncontrolled by military rule or discipline.</p>
            <p className="p-in-sp">I trust that we will husband these arms, and distribute them only in accordance with the wise provisions now in existence.</p>
            <div className="speaker" id="sp5389"><span className="persName">Mr. CAPERTON</span>—</div>
            <p className="p-in-sp">
              I understand there is a law which requires that there shall be some certificate as to the company's being organized and uniformed, before the Governor is authorized to give them orders. This ordinance proposes to supersede the necessity for this formula. I have seen a letter this morning from that particular section of country, stating that there is a very great state of excitement
              existing there.
            </p>
            <p className="p-in-sp">It is a portion of the country where there is greater reason for apprehending something in the shape of a raid than any portion of the Western border of the State. There is a large amount of negro property in the valley of the Kanawha, and that will present temptations to our enemies to make incursions into that region.</p>
            <p className="p-in-sp">
              Now, these gentlemen who are here upon this mission have prepared, as I learn, means for transporting these arms. They want them as rapidly as it is possible to obtain them. They are in apprehension every moment of something in the shape of a raid. There is a military gentleman there now, formerly connected with the army, who I understand, in connection with the representative from
              that county in this body, is making a reconnoissance upon the Kanawha river, for the purpose of ascertaining what defences they can provide. He reports that the country is in a most defenceless condition; and that gentleman writes in terms strong and imploring to the authorities here to send arms to that region.
            </p>
            <p className="p-in-sp">Now, the question is, shall we wait until you can communicate with Kanawha for the purpose of ascertaining whether those companies are uniformed or whether you will do away with that arrangement by passing the simple ordinance which has been reported.</p>
            <p className="p-in-sp">I think the Convention should not hesitate to pass this ordinance and allow this portion of the country to be provided for as speedily as possible. If you wait until you write to the Kanawha Valley, in all human probability the country may be overrun before you get a reply.</p>
            <p className="p-in-sp">In view of this state of things, the committee has very properly reported this ordinance providing for these arms, and I do hope that it will be the pleasure of the Convention at once to pass it. In regard to these general provisions indicated by the gentleman from Amelia <PageNumber num={679} /> [<span className="persName">Mr. HARVIE</span>]
              , I think the proper course would be to bring them forward in a separate ordinance.<span className="note" id="Note65"><span className="noteLabel">2</span>No comment on this subject by Mr. Harvie has been found up to this point; see, however, pages 668 and 670-671 for Mr. Harvie's substitute and comment.</span>
            </p>
            <p className="p-in-sp">One word in regard to the position of the gentleman from Harrison [<span className="persName">Mr. WILSON</span>] . There is a railroad running through the county of Harrison, and connecting with Harper's Ferry, over which, I presume, the people of that section can manage to transport arms from the latter point. It is not, moreover, much exposed, but the Kanawha Valley is peculiarly so.</p>
            <p className="p-in-sp">I trust it will be the pleasure of the Convention to pass the ordinance at once.</p>
            <div className="speaker" id="sp5390"><span className="persName">Mr. JAMES BARBOUR</span>, of Culpeper—</div>
            <p className="p-in-sp">It seems to me that this is a very incompetent body to determine what point the enemy is going to attack. The gentlemen from the Kanawha Valley are saying that that is the point where the attack is to be made, while the gentleman from Harrison thinks his section is the point most likely to be invaded.</p>
            <p className="p-in-sp">This body cannot judge of these things, and we should transfer the responsibility to the hands of the Commander-in-Chief, whose duty it is to determine where it is proper to send the arms.</p>
            <p className="p-in-sp">
              My county is not a border county, and yet it is the point where one of your Major Generals has his headquarters; and every armed man that that county can muster is in the service of the State at Harper's Ferry. That county has tendered her five hundred men, and there are not arms enough to put into their hands and render them available for service. With no less than 20,000 slaves in
              that county, which is within two hours run of the enemy's stronghold, I am to stand here and hear gentlemen say that it is necessary to pass ordinances to defend the frontier. I cannot consent to this. I desire to centre the responsibility where the power is.
            </p>
            <div className="speaker" id="sp5391"><span className="persName">Mr. HARVIE</span>—</div>
            <p className="p-in-sp">
              We have got a Major General, who, I have no doubt, is fully competent to discharge his duty. I am opposed to this Convention's indicating to him what his duty is. I take it for granted that he has given his attention to the whole condition of the State, and will make such a distribution of men and arms as he thinks necessary. I prefer, therefore, that the matter should be left
              altogether to his discretion.
            </p>
            <div className="speaker" id="sp5392"><span className="persName">Mr. MONTAGUE</span>—</div>
            <p className="p-in-sp">I have seen too clearly the evils resulting here from too much speaking to engage in the practice myself, and I merely rise now to move that that ordinance be recommitted to the Military Committee, with instructions to make such a report as will enable an equal distribution of these arms to all the exposed portions of the State.</p>
            <PageNumber num={680} />
            <p className="p-in-sp">I come from a portion of the State that is very much exposed. A steamship can come and land forces on my farm; and there are indentations everywhere that would admit of the passage of vessels to our serious detriment.</p>
            <p className="p-in-sp">I submit the motion to recommit</p>
            <div className="speaker" id="sp5393"><span className="persName">Mr. WILSON</span>—</div>
            <p className="p-in-sp">Inasmuch as this ordinance makes provision for the Valley of the Kanawha, and does not for the region along the Valley of the Monongahela, I move to include that Valley.</p>
            <div className="speaker" id="sp5394"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">There is a motion to recommit, and the gentleman's motion is therefore out of order.</p>
            <div className="speaker" id="sp5395"><span className="persName">Mr. TURNER</span>, of Jackson—</div>
            <p className="p-in-sp">Being referred to by the gentleman from Franklin [<span className="persName">Mr. EARLY</span>]
              , in connection with the needs of the Kanawha Valley in the means of defence, I will state that there are now cooped within one and half hour's run of Ohio by steamboat, in the town of Ravenswood and Jackson Court House, over four hundred slaves, which are liable at any moment to be carried off. The county is entirely defenseless, being without any arms, except the common rifles in the
              hands of farmers. We have an organized volunteer company in the county, for which I have been in vain applying for arms for the last three or four weeks. We cannot get a single gun, great as is the need for them in our country. I am satisfied the Commander-inChief is ready to furnish these arms, if proper steps are taken to enable him to do so. We do not demand that any force shall be
              sent out to that region; we only desire to obtain arms, to enable our people to defend themselves. We are now organizing forces, and only need the arms to put into their hands. I hope the ordinance will not be recommitted.
            </p>
            <div className="speaker" id="sp5396"><span className="persName">Mr. HARVIE</span>—</div>
            <p className="p-in-sp">I move this as a substitute for the motion to recommit</p>
            <p className="p-in-sp">"Resolved, That the ordinance under consideration be recommitted, with instructions to report an ordinance to empower the Commanderin-Chief to issue arms to such portions of the Commonwealth as, in his judgment, the public defence requires."</p>
            <div className="speaker" id="sp5397"><span className="persName">Mr. EARLY</span>—</div>
            <p className="p-in-sp">I do not think it is necessary to recommit this ordinance in order to accomplish the object of the gentleman from Middlesex [<span className="persName">Mr. MONTAGUE</span>] .</p>
            <p className="p-in-sp">
              We have some fifteen or twenty thousand troops called into service in Eastern Virginia and in the Valley, and the Commander-in-chief will, upon a proper representation, send not only arms, but troops, to the quarter indicated by the gentleman from Middlesex. There is no difficulty in providing for the safety of Eastern Virginia and the
              <PageNumber num={681} /> Valley, so far as the means on hand enable the Commander-in-chief to do so. But here is the whole frontier of the Ohio, which is almost entirely inaccessible from this point, and here are three gentlemen from the valley of the Kanawha who are willing to have the arms transported at a cost to themselves and their counties. They
              cannot go down the Ohio river without being subjected to insult, and can get arms nowhere for their defence.
            </p>
            <p className="p-in-sp">I went with these gentlemen to the Commander-in-Chief, and he had no power to issue arms until the companies for whom they were intended were duly organized and uniformed. This ordinance, if adopted, will give him the power to issue arms without any compliance with these formulae.</p>
            <p className="p-in-sp">It is necessary to make some provision immediately to enable these people to defend themselves. The Kanawha valley is open to invasion. There is steamboat navigation to the town of Charleston, and if the people are unarmed, troops can be poured in there. The people are willing to buy them, if they cannot get them otherwise.</p>
            <p className="p-in-sp">
              Now, sir, as to all these frontiers in the East and in the Valley, we can readily send troops to these points; but it is a work of time, to go back into that country and have the volunteers organized according to law, in order to secure them arms. This provision embraces all portions of the State that are exposed to Northern invasion. It gives the Commander-in-Chief power to send arms
              to these portions of the State without going through the formalities required by the law relating to the organization and equipment of volunteers.
            </p>
            <p className="p-in-sp">I trust, therefore, that it will be the pleasure of the Convention not to recommit this ordinance, but to pass it at once.</p>
            <div className="speaker" id="sp5398"><span className="persName">Mr. CAPERTON</span>—</div>
            <p className="p-in-sp">Let me suggest a difficulty.</p>
            <p className="p-in-sp">Suppose you pass an ordinance giving to the Major General the power which you propose, as the law now stands, he could not send arms to any portion of the State that may need them.</p>
            <div className="speaker" id="sp5399"><span className="persName">Mr. JAMES BARBOUR</span>—</div>
            <p className="p-in-sp">I would give him all power over the military operations of the State. The gentleman from Amelia [<span className="persName">Mr. HARVIE</span>] , proposes to give power to the Commander-in-chief to send arms where, in his opinion, the arms are necessary. That should be sufficient.</p>
            <div className="speaker" id="sp5400"><span className="persName">Mr. TURNER</span>, of Jackson—</div>
            <p className="p-in-sp">
              The gentleman from Norfolk says, if necessary, that armed men would be sent to these exposed portions of the State, while we hear gentlemen from all quarters of the State saying that their particular sections are the points most exposed. Of all the arms received by this State from the General Government, the regions west of the Alleghanies have never received five hundred stand. And
              <PageNumber num={682} /> yet we are told that you cannot furnish us with arms, because you distrust these people, as we are led to infer from your opposition here. We know these people, and we can trust them. All that we want is, that these men shall not be required to uniform themselves so as to secure these arms. I understand that we could now, if this
              ordinance was passed, receive about twenty thousand stand of arms, which, in my judgment, would be sufficient to enable our people to defend themselves.
            </p>
            <p className="p-in-sp">
              Why, it is said that it is contemplated to organise a provisional government in Western Virginia, and it is even rumored that the men who are engaged in organising the movement have been assured by the Northern government, that as soon as they complete the organization, they will give them arms enough to sustain themselves, and even send men to aid them in that effort. And you talk of
              sending men to Western Virginia, while you say they are needed here on your own borders. All you want is to be furnished with arms. The men you can reserve for the defence of those points in the East, which you say are exposed.
            </p>
            <p className="p-in-sp">I trust it will be the pleasure of the Convention to pass this ordinance, and allow the arms to be sent out at once.</p>
            <div className="speaker" id="sp5401"><span className="persName">Mr. HARVIE</span>—</div>
            <p className="p-in-sp">
              I do not say that arms ought not to be sent there. If the ordinance shall pass with the amendment I have offered, Gen. Lee will have full power to send arms wherever he may think they are needed; and I have no doubt, if gentlemen will go to the General and communicate to him what they have stated here, he will, upon the passage of the ordinance, as I propose to amend, furnish the
              necessary supply of arms.
            </p>
            <p className="p-in-sp">
              Why should we come here and indicate the particular points where arms are to be sent to? Why should we come here and suspend the operation of this law in relation to the arming of volunteers in reference to one locality and not to another? It would be unjust to do so, and I trust the Convention will be guilty of no such act of partiality. My amendment proposes to confer upon the
              Commander-in-chief full discretion in the matter of the distribution of arms, and to whom could it more properly be committed? We are incapable of judging what points most need the means of defence. Indeed, so little do we know concerning military matters, that we have frequently to repeal one day an ordinance which we passed the day before. I have great respect for the judgment of the
              members of the Military Committee, but I have much more respect for the judgment of Gen. Lee in military affairs. My opinion is, that it would be better for us not to <PageNumber num={683} /> make any disposition of these arms until the President of the Confederate States and General Lee shall direct what is best to be done with them.
            </p>
            <div className="speaker" id="sp5402"><span className="persName">Mr. HALL</span>, of Wetzel—</div>
            <p className="p-in-sp">I live on the borders of North-western Virginia, with the State of Pennsylvania on one side and Ohio on the other, and I have, therefore, I think, a right to say something.</p>
            <p className="p-in-sp">
              I think that gentlemen here entirely misapprehend the character of the struggle in which we are engaged. It seems to me that it would be extremely impolitic for this Convention to direct where the blow shall be struck, or to undertake, in any manner, so far as relates to military operations, to govern the authorities to whom we have entrusted the conduct of our military movements.
            </p>
            <p className="p-in-sp">
              It has been proclaimed that this war is to be conducted upon the principle of civilized warfare. I trust that may be so. It will be, I am satisfied, so far as our side is concerned; for already it is indicated that all the rights of private property are to be strictly regarded. But there may spring up guerilla parties on the borders, who may disregard these principles and ransack and
              plunder everything that comes in their way. I am satisfied, sir, if the enemy resorts to this, he will be met in a similar spirit, and that retaliation will not be attempted in vain. I have entire confidence in the judgment of General Lee, and trust it will be the pleasure of the Convention to leave to him the power to manage affairs in the military line as seems best to him. It seems
              to me to be extremely unwise to interfere with his movements in any way, and I hope the Convention will not do so.
            </p>
            <div className="speaker" id="sp5403"><span className="persName">Mr. EARLY</span>—</div>
            <p className="p-in-sp">I am actually astonished at the opposition which this ordinance is receiving. I hope that the Convention will give me their ear for a moment while I make an explanation. I do not desire to take up too much time.</p>
            <p className="p-in-sp">
              This ordinance does not propose to lay down for the commander-in-chief any plan of operations whatsoever. It authorizes him to cause arms to be issued for the use of particular localities. It merely gives him the power to issue them at his discretion, and in view of the public necessity, but does not direct him to do so. Is not this a safe policy to pursue? There are here three
              gentlemen from the Kanawha valley who are ready to transport the arms without cost to the State. I understand, if you give them the guns, they will have carriages manufactured for them, so that the State will not be at the expense of a dollar beyond the furnishing of the arms. I understand an officer is already appointed to take charge of the operations in the region to which it is
              proposed to furnish these arms.
            </p>
            <PageNumber num={684} />
            <p className="p-in-sp">
              Now, we are asked to delay until provision is made for the distribution of arms in other quarters. What other quarters are there except the borders on the Ohio and the Northern borders in the Valley? There are a great many in the latter point with arms in their hands, while a large number of arms were taken at Harper's Ferry, which, if necessary, can be employed for the defence of the
              borders along the line of the Potomac. Besides, there are several thousand soldiers in Eastern Virginia ready to be transported to any point in that section where their presence may be needed. In view of all this, gentlemen tell us to wait until the arms are fairly distributed. Sir, all the arms that we have are concentrated in Eastern Virginia and in the Valley. The volunteer
              companies that have been organized and armed, are limited entirely to these portions of the State; and yet gentlemen in these quarters tell us to wait for an equal distribution of the arms everywhere before you give the commander-in-chief the power to send arms to those exposed points for which they are now asked.
            </p>
            <p className="p-in-sp">In regard to the Eastern Section and the Valley, there is no need to dispense with the volunteer regulations, for they have all the necessary facilities to obtain arms and men at any moment, while in regard to the Kanawha valley region, there is every reason to dispense with it, inasmuch as the same facilities for organization and obtaining arms do not exist.</p>
            <p className="p-in-sp">I hope it will be the pleasure of the Convention to pass this ordinance, which, in fact, does nothing more than commit the whole matter to the discretion of the Commander-in-chief.</p>
            <div className="speaker" id="sp5404"><span className="persName">Mr. THORNTON</span>, of Prince Edward—</div>
            <p className="p-in-sp">I would ask would it not be better to frame this ordinance in the general terms proposed by the gentleman from Middlesex [<span className="persName">Mr. MONTAGUE</span>]
              , than to take it in the restricted form proposed by the Military Committee. One can be passed in as short a time as the other; and as we have a Commander-in-chief in whom all of us have confidence, why not repeal the provision of the law that now regulates the disposition of arms, and leave it to his discretion to distribute arms to military companies in any portion of the State in
              such manner as in his wisdom may seem just and proper.
            </p>
            <div className="speaker" id="sp5405"><span className="persName">Mr. EARLY</span>—</div>
            <p className="p-in-sp">There are regulations for the issuing of arms requiring the officers to give bond and security that the arms will be forthcoming when demanded by the State, or their cash equivalent.</p>
            <p className="p-in-sp">
              This provision is necessary in order to compel the parties receiving the arms to take proper care of them. If there is any justification to suspend the operation of this provision, the circumstances of the <PageNumber num={685} /> application now made furnish it. This ordinance does not propose to repeal that provision. It merely proposes to confer upon
              Gen. Lee the power to furnish the arms to the points where they are needed.
            </p>
            <div className="speaker" id="sp5406"><span className="persName">Mr. THORNTON</span>—</div>
            <p className="p-in-sp">
              I take it for granted that if you confer this authority upon the Commander-in-chief, that he will prescribe regulations for the care and due return of the arms to the State before he issues them to the troops in the Valley, the Pan Handle, or in Middlesex, or any other portion of the State. I really think that all these guards thrown out, the distribution of arms in times of peace
              should be done away with now, and that some simple provision suggested by the Commander-in-chief should be introduced instead. I would leave to his experience to place the armed companies in such positions as would enable them to act best for the defence of the State.
            </p>
            <div className="speaker" id="sp5407"><span className="persName">Mr. WOODS</span>, of Barbour—</div>
            <p className="p-in-sp">
              Being entirely ignorant of military affairs myself, I have, in every instance, forborne to say anything upon any military subject before the Convention. But understanding the object of this ordinance to be, to give authority to the Commander-in-chief to dispense with the provision of the law in regard to the distribution of arms in the particular section indicated, and not for the
              purpose of dispensing with that provision of law in every case, where, in the opinion of the Commander-in-chief, it may be necessary and proper to distribute arms, it does not and cannot command my support. I prefer that this ordinance shall go back to the committee to report such an ordinance as will give him authority, in every proper case, to dispense with that provision of the law,
              and, give him authority to distribute arms wherever he thought proper. This ordinance excludes my section. I will say that we have not a single weapon of any kind; and every mail brings me letters carrying requests to bring the subject before the Governor.
            </p>
            <p className="p-in-sp">
              I am not much in the habit of waiting upon governors; I know very little about the rules of etiquette that ought to be observed in seeking interviews with the executive head of the Commonwealth. I ventured, however, to bring this subject to the attention of the Governor yesterday and received no answer to my communication. To-day again I received an important communication from an
              adjoining county appealing to me and to the Governor, upon every consideration of patriotism, that some arms may be placed within their reach to enable them not only to defend them against outside enemies, but against bands of lawless persons on the day of election. I understand that parties are to be sent from Pennsylvania to intimidate the voters, and if possible, secure a majority
              against secession. I received today the Wheeling "Intelligencer" <PageNumber num={686} /> from which I learn that organizations are on foot for the purpose of forming a provisional government for North-western Virginia in order that they may unite by force of arms, every disaffected person in that region. I saw in this paper the name of Campbell Tarr
              designated as being actively engaged in that organization. I have seen also the names of John S. Carlile and of Chester D. Hubbard among the active participants in that movement. I am informed by letters received from home that the people in the North-west are apprehensive that large bodies of armed men from Ohio and Pennsylvania will be thrown into that section on or before the day of
              election, for the purpose of preventing the expression of the people at the polls. Without some such effort, I have no fear of the people of that section. If you will give them the arms they are able and willing to defend themselves. We do not ask any assistance from anybody; but we do think that some measures ought to be adopted authorizing those who have control of the public arms to
              place them at such points as, in case of necessity, the people might have access to them. We have a railroad passing from the Ohio river to Baltimore-the Baltimore and Ohio Railroad ; and while that affords us access, to the country along the line, it affords a much more dangerous means of ingress from the great North-west. An army of ten or twenty thousand men may be thrown along that
              line, and our great highway made the point of attack. We have no arms at all. In the two Congressional districts of the North-west, there are upwards of two hundred and fifty thousand inhabitants, and I suppose they have not two hundred stand of arms outside of the city of Wheeling. I learned from the prosecuting attorney of Taylor county that they are in fear there that the polls will
              be so guarded by armed bands, imported for that purpose, that a fair expression of the opinions of the people of that county cannot be had. All I ask for, then, is arms. Give us the arms and ammunition, and we will furnish you the men.
            </p>
            <p className="p-in-sp">
              I am opposed to restricting this ordinance to the Valley of the Kanawha, and to the counties on the Ohio river. Gentlemen seem to have a very imperfect idea of the position of the people in my section. While we are removed by three counties from the Ohio, we are within four hours travel of it-near enough, it must be said, to admit of the rapid transportation of troops to our midst.
            </p>
            <p className="p-in-sp">Give us arms and an army of 1,000 men, and we can beat 10,000 in the gorges of the mountains; but keep them unarmed, and they will be a prey to the invaders. Under the circumstances, I will vote for recommitting this ordinance.</p>
            <PageNumber num={687} />
            <div className="speaker" id="sp5408"><span className="persName">Mr. SHEFFEY</span>—</div>
            <p className="p-in-sp">I offer this amendment to the amendment which has been offered by the gentleman from Amelia [<span className="persName">Mr. HARVIE</span>] :</p>
            <p className="p-in-sp">"Be it ordained, That Major General Lee be, and he is hereby empowered to issue arms to such parts as, in his discretion, the public defence requires, and as the condition of the State will, in his judgment, allow, under such terms and restrictions as he may deem proper, for the preservation and return of the arms when required."</p>
            <p className="p-in-sp">I call the previous question.</p>
            <p>The call was sustained, and the main question being put, which was upon the amendment just reported, it was agreed to.</p>
            <div className="speaker" id="sp5409"><span className="persName">Mr. RANDOLPH</span>—</div>
            <p className="p-in-sp">There is a very important ordinance on the table, which it is very necessary should be acted upon. It is the ordinance for the better regulation of the army and navy of Virginia.</p>
            <div className="speaker" id="sp5410"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">I have received a communication from the Governor of the Commonwealth, which I beg leave to submit for the consideration of the Convention:</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>Be it ordained, That the Advisory Council of the Governor of this Commonwealth be, and the same are hereby increased to five, and that the Lieutenant Governor shall be ex-officio one of the said five members.</p>
                <p>
                  Adopted by the Convention of Virginia April 23d, 1861.<span className="note" id="Note66"><span className="noteLabel">3</span>The ordinance was passed on April 29.</span>
                </p>
                <p>JOHN L. EUBANK, Sec'y. EXECUTIVE DEPARTMENT,</p>
                <p>April 30, 1861.</p>
                <p>Gentlemen of the Convention :</p>
                <p>Under the ordinance above certified, I nominate General Thomas S. Haymond as a member of the Council.</p>
                <p>Respectfully,</p>
                <p>JOHN LETCHER.</p>
              </div>
            </div>
            <div className="speaker" id="sp5411"><span className="persName">Mr. WOODS</span>, of Barbour—</div>
            <p className="p-in-sp">
              I do not know that I can vote for that. That, I understand, is an addition to the Advisory Council. He is a very old man, and altogether inadequate to the physical labors that are necessary to discharge the duties connected with this office. I understand the complaint of the gentlemen composing the Council as it now stands, was, that they were unable to discharge the physical labors
              which exist in connection with that office.
            </p>
            <div className="speaker" id="sp5412"><span className="persName">Mr. WILSON</span>—</div>
            <p className="p-in-sp">I could not sit by and hear the name of Gen. <PageNumber num={688} /> Haymond, nominated, presented for this office without giving my testimony of his worth and fitness for that position.</p>
            <p className="p-in-sp">
              Gen. Haymond, is a gentleman of fine intelligence; and I take it that he has sufficient physical vigor to perform the duties of that office. He is a gentleman in whom the East and West place implicit confidence; and I think he has sufficient military experience to enable him to act efficiently in that office. He was mustered in the war of 1812, and served at Norfolk during the period
              of the war. For forty years he has been familiar with every section of the State, and no man is more competent to form an opinion of the needs of every portion of it than he is.
            </p>
            <p className="p-in-sp">I trust it will be the pleasure of the Convention to confirm the nomination of General Haymond.</p>
            <div className="speaker" id="sp5413"><span className="persName">Mr. CAPERTON</span>—</div>
            <p className="p-in-sp">In relation to the capacity of Gen. Haymond to labor, I served with him on the Committee of Finance of the House of Delegates, and I will say, that a more laborious man I have never known in the General Assembly of Virginia.</p>
            <p className="p-in-sp">I undertake to say, that he performed more labor during the last session than any ten members of the body.</p>
            <div className="speaker" id="sp5414"><span className="persName">Mr. CAMPBELL</span>, of Washington county—</div>
            <p className="p-in-sp">I desire to bear my testimony to the fitness of the nomination made, and I will cheerfully vote for its confirmation.</p>
            <p>The nomination was confirmed.</p>
            <div className="speaker" id="sp5415"><span className="persName">Mr. CAPERTON</span>—</div>
            <p className="p-in-sp">I now call for the unfinished business.</p>
            <div className="speaker" id="sp5416"><span className="persName">Mr. COX</span>—</div>
            <p className="p-in-sp">The call of committees has not yet been completed.</p>
            <div className="speaker" id="sp5417"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">The order of the House was that the committees should be called over before any other business could be entered upon.</p>
            <p>The Secretary resumed the call of committees, when <span className="persName">Mr. EARLY</span>, from the committee on Military Affairs, submitted the following resolution :</p>
            <p>
              Resolved, That the Governor be requested to report to the Convention, as soon as practicable, how many volunteers have been called into the service under the ordinance adopted on the 17th of this month, and what general and field officers have been appointed for the same. He is also requested to report whether any portion of the militia of the line has been called into the service, and
              what general officers of the militia have been placed in command of any portion of the troops in actual service. He is also requested to report whether he has called upon the Governors of any of the slave States for troops to come to the assistance of this State.
            </p>
            <p>The resolution was adopted.</p>
            <PageNumber num={689} />
            <p><span className="persName">Mr. COX</span>, of Chesterfield, from the Committee on Enrolled Ordinances, reported that they had examined several ordinances and found them correctly enrolled.</p>
            <p>"An ordinance to establish a flag for the Commonwealth," was then taken up, on motion of <span className="persName">Mr. SCOTT</span>, of Powhatan.</p>
            <div className="speaker" id="sp5418"><span className="persName">Mr. MORTON</span>—</div>
            <p className="p-in-sp">I move to lay that ordinance upon the table.</p>
            <p>The motion was rejected.</p>
            <div className="speaker" id="sp5419"><span className="persName">Mr. TYLER</span>—</div>
            <p className="p-in-sp">I said to the Convention the other day, on a motion to lay that ordinance on the table, that my friend Gov. Wise had labored with some care and interest, along with the Secretary of the Commonwealth, to form a new flag, and they have fixed upon one which I consider very beautiful and very appropriate. You will perceive [a miniature flag of the style referred to, was exhibited by the speaker]
              , that your "nil desperando,"<span className="note" id="Note67"><span className="noteLabel">4</span>The motto is given thus in the Enquirer.</span> and your "sic semper tyrannis" are not in the least interfered with. Instead of the stripes running up and down, as in the case of the U. S. flag, they are simply reversed, so as to distinguish it from any other flag now afloat.
            </p>
            <p className="p-in-sp">I submit this flag, because I know that Gov. Wise had his heart very much set upon it. I know that it has been elaborated with very great care by himself and the Secretary of State.</p>
            <p className="p-in-sp">
              It is a remarkable fact that there never was a Virginia flag. You adopted your seal of State, but you never adopted a flag. A very close search has been made throughout the entire records of the Commonwealth-from the foundation of the State of Virginia as an independent State-in other words, from 1775 to this day, and there is not one mention made of any particular flag. The flag which
              floats over you was planned by one of the most estimable men that ever lived, and one of the best Governors you ever had-the first Governor Floyd. I think it was erected over the Arsenal by him as the Virginia flag; but it was, in fact, a flag very much of his own design. The public records give no evidence of the adoption of any flag at any period by the State. You have therefore to
              adopt a flag, and I know of no design more perfect and appropriate than that which I present.
            </p>
            <p className="p-in-sp">I move that it be adopted in lieu of the present flag.</p>
            <div className="speaker" id="sp5420"><span className="persName">Mr. MORTON</span>—</div>
            <p className="p-in-sp">I move the previous question.</p>
            <p>The motion was sustained, and the main question ordered to be put.</p>
            <div className="speaker" id="sp5421"><span className="persName">Mr. RANDOLPH</span>—</div>
            <p className="p-in-sp">There is no description of this flag, and I cannot see how it is to be adopted.</p>
            <div className="speaker" id="sp5422"><span className="persName">Mr. TYLER</span>—</div>
            <p className="p-in-sp">How was this old flag, which floats over us now, adopted, and what made it the flag of Virginia?</p>
            <div className="speaker" id="sp5423"><span className="persName">Mr. RANDOLPH</span>—</div>
            <p className="p-in-sp">Usage, sir.</p>
            <PageNumber num={690} />
            <div className="speaker" id="sp5424"><span className="persName">Mr. TYLER</span>—</div>
            <p className="p-in-sp">When did it commence to be recognized?</p>
            <div className="speaker" id="sp5425"><span className="persName">Mr. RANDOLPH</span>—</div>
            <p className="p-in-sp">I would suggest that there is no resolution or ordinance to be acted upon in connection with this.</p>
            <div className="speaker" id="sp5426"><span className="persName">Mr. WICKHAM</span>—</div>
            <p className="p-in-sp">I rise to a point of order.</p>
            <p className="p-in-sp">Is it competent to move the previous question upon a resolution not in writing?</p>
            <div className="speaker" id="sp5427"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">It is competent to move it upon a motion, and the previous question now is upon the motion to adopt this flag as a substitute for that now in use.</p>
            <p>The question was put on the motion of <span className="persName">Mr. TYLER</span>, and it was rejected.</p>
            <div className="speaker" id="sp5428"><span className="persName">Mr. TURNER</span>, of Jackson—</div>
            <p className="p-in-sp">I move that the report be recommitted.</p>
            <p>The motion was lost.</p>
            <p>The question then recurred upon the adoption of the ordinance reported by the Committee, and it was decided in the affirmative. The ordinance reads:</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>
                  Be it ordained by the Convention of the Commonwealth of Virginia, That the Flag of this Commonwealth shall hereafter be made of bunting, which shall be a deep blue field with a circle of white in the centre, upon which shall be painted, or embroidered, to show on both sides alike, the Coat of Arms of the State, as described by the Convention of 1776, for one side of the Seal of
                  State, to wit:
                </p>
                <p>
                  Virtus, the genius of the Commonwealth, dressed like an Amazon, resting on a spear with one hand, and holding a sword in the other, and treading on Tyranny, represented by a man prostrate, a crown fallen from his head, a broken chain in his left hand, and a scourge in his right. In the Exergon the word Virginia over the head of Virtus, and underneath the words "Sic Seraper
                  Tyrannis."
                </p>
                <p>This flag shall be known and respected as the flag of Virginia.</p>
                <p>The Governor shall regulate the size and dimensions of the flag proper for forts, arsenals and public buildings, for ships of war and merchant marine, for troops in the field, respectively, and for any other purpose, according to his discretion; which regulations shall be published and proclaimed by him as occasion may require.</p>
                <p>This ordinance shall take effect from its passage.</p>
              </div>
            </div>
            <div className="speaker" id="sp5429"><span className="persName">Mr. RANDOLPH</span>—</div>
            <p className="p-in-sp">I move to take up the ordinance for the better regulation of the Departments of the Army and Navy of Virginia, &amp;c., communicated by the Governor and Council.</p>
            <div className="speaker" id="sp5430"><span className="persName">Mr. CAPERTON</span>—</div>
            <p className="p-in-sp">I rise to a point of order.</p>
            <p className="p-in-sp">The gentleman cannot make that motion without a motion to pass by the unfinished business.</p>
            <PageNumber num={691} />
            <div className="speaker" id="sp5431"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">The chair is not aware that there is any unfinished business [<span className="persName">Mr. SOUTHALL</span> was in the chair] .</p>
            <div className="speaker" id="sp5432"><span className="persName">Mr. CAPERTON</span>—</div>
            <p className="p-in-sp">Yes, sir, the stay law.</p>
            <div className="speaker" id="sp5433"><span className="persName">Mr. RANDOLPH</span>—</div>
            <p className="p-in-sp">To avoid any difficulty, I move to pass by the unfinished business for the purpose of taking up the ordinance which I have just indicated.</p>
            <p>This was agreed to.</p>
            <p>The ordinance was then taken up and read by the Secretary as follows:</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>AN ORDINANCE for the better regulation of the Departments of the Army and Navy of Virginia, and for the audit and settlement of accounts and claims arising in the present emergency for the defence of the Commonwealth. Passed April 30, 1861.</p>
                <p>
                  1. Be it ordained by the Convention of the Commonwealth of Virginia, That George W. Munford, J. R. Tucker, and J. M. Bennett, any two of whom may act, shall constitute a Board of Commissioners, with the authority to audit and settle, upon principles of law and justice, all accounts for expenses heretofore or hereafter to be incurred in the assembling, arming, equipping, and
                  maintaining troops, in the transportation of the same, of munitions of war, provisions, and supplies of every kind, and for any other purpose connected therewith. Also, all expenses for officering, equipping and maintaining the navy of the State, and for the pay of officers, seamen and marines therein, and all employees at navy and dock yards, including herein the expense of
                  transportation of ordnance, ammunition and stores of every description, the purchase of vessels, and damages, arising from seizure and detention of vessels, or from injury to, or use of cargo, or property of any kind by land or naval forces, or under the order of the authorities of the State. Also, for all other incidental expenses incurred or to be incurred in the defence of the
                  Commonwealth, including herein the guarding, support, and transportation of prisoners, and all claims for arms, and for machinery for the manufacture of arms in the Public Armory, or others authorized by existing or future contracts. The said Board are further authorized to administer oaths or to require affidavits or other evidence necessary to the proof of all such claims. The
                  Governor shall have power to supply any vacancy occurring in the said Board. In all cases arising under this section, the claimant shall have a right of appeal from the decision of the Board to the Governor of the Commonwealth.
                </p>
                <PageNumber num={692} />
                <p>
                  2. The said Board shall be authorized to require of the several heads of the departments established by this Convention, an estimate of the sums necessary to be disbursed by them for the public service; and upon the same being furnished, to place from time to time, under the control of the proper disbursing officers in said departments, such sums of money as may be required, and as
                  may be properly applicable thereto, for the raising and support of the Army and Navy ordered into service, from time to time, under legal authority.
                </p>
                <p>
                  3. All accounts presented to the said Board under the first section shall be sustained by proper vouchers, and when allowed shall be kept in a general account under proper heads for future settlement. When said accounts are so allowed, and when monies are ordered to be disbursed by the Board under the second section, the said Board is hereby authorized to direct the Auditor of
                  Public Accounts to issue his warrants for the several amounts so allowed, to be paid out of any money in the Treasury not otherwise appropriated.
                </p>
                <p>4. The Board shall adopt such regulations as it may approve, for the security of the money to be disbursed under its direction under the second section; and, for this end, may require the heads of the several departments to submit such a system of regulations for that purpose as may be right and proper.</p>
                <p>
                  5. The accounts allowed under the first section, except claims for arms and machinery for the manufacture of arms authorized by existing laws, and the disbursements ordered under the second section, shall be certified in proper form at the end of every quarter of a year, for payment, to the Government of the Confederate States of America, according to the Convention between this
                  Commonwealth and the said Confederate States, adopted on the 25th day of April, 1861; and when the same shall be received, it shall be paid into the treasury of this Commonwealth.
                </p>
                <p>6. The said Board shall, for their services, receive the sum of three dollars per day of actual service, to be paid to each of them; and shall have power to appoint a clerk or clerks, and to allow to them such compensation as may be reasonable.</p>
                <p>
                  7. And for the better regulation of the quartermaster's department, it is ordained, that in addition to field duties, it shall be the duty of the Quartermaster General, and the subordinates in his department, when thereto directed by the Governor to purchase military stores, camp equipage, and other articles required for the troops, and to procure and provide means of transport for
                  the army, its stores, artillery and camp equipage; and in order thereto, to report such an estimate <PageNumber num={693} /> as may be proper to the said Board, constituted by the first section, of the cost thereof; and to disburse the sums under their control, by the said Board, to the best advantage. It shall be the duty of the Quartermaster General
                  to report to and account with said Board, once in three months, or oftener if required, in such manner as it shall prescribe, for all money and property which may pass through his hands or those of his subordinates, or may be disbursed by them as aforesaid, or may be in his or their care or possession. The Quartermaster General shall be responsible for the regularity and
                  correctness of all the returns of his subordinates, in his department.
                </p>
                <p>8. The Quartermaster General is authorized to appoint, with the approbation and under the direction of the Governor, as many forage wagon-masters, and employ as many artificers, mechanics and laborers as the public service may require.</p>
                <p>9. It shall be the duty of the Quartermaster's department to distribute to the army all clothing, camp and garrison equipage and other articles required for the army; and to prescribe and enforce according to such regulations as shall be approved by the said Board, a proper accountability for such clothing, equipage and other articles, so distributed to the army.</p>
                <p>
                  Every captain or commander of a company, detachment or recruiting station, or other officer, so receiving clothing, equipage, or other articles for the use of his command, or for issue to the troops, shall render to the quartermaster general quarterly returns thereof, according to the requisite forms, and accompanied by the proper vouchers; which returns and vouchers shall be
                  included by the said quartermaster general in his report and account with the Board hereby constituted.
                </p>
                <p>
                  It shall be the duty of all officers charged with the duty of issuing clothing, equipage, or other articles, to secure the same from waste and damage. In case of the loss of any such articles, or damage thereto, the value thereof, on final settlement with the officer, shall be charged against him and be deducted from his pay, unless he shall show to the satisfaction of the
                  quartermaster general, and to the said Board, in passing on his accounts, by proper evidence, that such loss or damage occurred from unavoidable accident, or in actual service, without fault on his part, and was not the result of neglect on his part, and that he used due care and attention for the preservation of such articles.
                </p>
                <p>10. There shall be added to the quartermaster's department military <PageNumber num={694} /> storekeepers, not exceeding three in number, who shall be appointed in the same manner as the quartermaster general.</p>
                <p>11. The other departments of the army and navy of this Commonwealth shall be subject, in like manner as the quartermaster's department, to the provisions of the second, third, fourth, fifth and seventh sections of this ordinance, in so far as their respective duties under existing or future laws may make them applicable.</p>
                <p>
                  12. Whenever the quartermaster general, or the chief of any department of the army or navy of the Commonwealth shall be absent or unable to attend to his duty, the Governor is hereby authorized to empower some officer of such department to take charge thereof, and to perform the duty of said quartermaster general or chief, during said absence or inability: Provided, That no
                  additional compensation be allowed therefor.
                </p>
                <p>
                  13. The Governor is hereby authorized to appoint, for the time being, one or more special commissaries for the purpose of supplying by purchase or contract, and of issuing, or to authorize one or more officers in the quartermaster's department to supply and issue the whole or any part of the subsistence of the army, in all cases, when from the want of contractors or a deficiency on
                  their part, or any other cause, such measure may be necessary and proper to secure the subsistence of the army; and such special commissaries shall, whilst so employed, be entitled to the pay and emoluments of a deputy quartermaster general.
                </p>
                <p>The Governor shall have authority to appoint any additional officers, subject to the control of the Legislature, whenever the chief of any department of the army and navy shall report that they are required for the public service; and the Board, hereby constituted, shall concur in such report, and request the Governor to make such appointments.</p>
                <p>
                  14. No officer of the army or navy of Virginia, nor quartermaster, paymaster, or commissary, nor any member of the Board hereby created, shall be concerned, directly or indirectly, for himself, or as agent, or as partner, in any contract, sale, purchase, manufacturing or supply of any article, intended for, or appertaining to any of said departments, except for and on behalf of
                  Virginia; nor shall any of said officers take or receive to his use and benefit any gain, commission or emolument for any negotiation or transaction of any business in any of said departments, other than may be allowed by law. If any disbursing officer mentioned in this ordinance shall be found delinquent in his accounts, the Board are hereby required to report the fact to the
                  <PageNumber num={695} /> Governor, who shall thereupon strike the name of said officer from the roll of the army and navy.
                </p>
                <p>15. Each and all of the officers, named in this ordinance and connected with the department of the army and navy, shall take the oaths prescribed by the Code of Virginia, chapter thirteen, sections one and three.</p>
                <p>
                  16. The Board hereby constituted shall require every storekeeper, paymaster and purser under this ordinance, to execute bond, with sufficient surety, in a penalty to be fixed by the board, conditioned according to law. The said Board, in their discretion, may require similar bonds of any officers of the army and navy referred to in this ordinance. Neither the quartermaster general,
                  nor the commissary general, nor any other head of a department shall be responsible for any loss which may accrue by the act or neglect of any subordinate officer in such department, who has been lawfully put in possession of money or other property under this ordinance, or any law of this Commonwealth.
                </p>
                <p>All bonds hereby required shall be payable to the Commonwealth of Virginia.</p>
                <p>All paymasters, commissaries and storekeepers shall be subject to the rules and articles of war in the same manner as commissioned officers.</p>
                <p>17. The duties of the officers of departments in the army and navy shall, in so far as they may not be inconsistent with this or other ordinances of this Convention, be determined by the laws of this State and of the United States, which were in force within this Commonwealth on the seventeenth day of April, eighteen hundred and sixty-one.</p>
                <p>18. The ordinance passed by this Convention on the twenty-seventh day of April, eighteen hundred and sixty-one, entitled "A general ordinance to provide a Board," &amp;c., and an ordinance passed the same day, entitled "An Ordinance concerning the quartermaster's department," are hereby repealed.</p>
                <p>19. This ordinance shall be in force from its adoption, subject to the right of this Convention or of the General Assembly to amend, alter or repeal the same.</p>
              </div>
            </div>
            <div className="speaker" id="sp5434"><span className="persName">Mr. HAYMOND</span>—</div>
            <p className="p-in-sp">I suppose it is necessary to make some explanation in reference to this ordinance.</p>
            <p className="p-in-sp">
              The Military Committee reported to the Convention some days ago an ordinance of some length in relation to the Quartermaster's Department in the Provisional Army. That ordinance was adopted by the Convention. The Committee of Finance reported another ordinance <PageNumber num={696} /> appointing a board to audit claims against the Commonwealth, but it was
              ascertained by the council that both these ordinances conflicted, and they combined the two so as to make them harmonize, and combine every provision necessary in connection with the particular Departments to which they relate.
            </p>
            <div className="speaker" id="sp5435"><span className="persName">Mr. TURNER</span>, of Jackson—</div>
            <p className="p-in-sp">I would like to know whether one of the requirements in that ordinance, is to take an oath to support the Constitution of the United States? If so, it ought to be stricken out.</p>
            <div className="speaker" id="sp5436"><span className="persName">Mr. HAYMOND</span>—</div>
            <p className="p-in-sp">According to the information I have upon the subject, no such provision is contained in it. It was before the Military Committee, and one of that Committee stated that no such oath was required of the officers.</p>
            <div className="speaker" id="sp5437"><span className="persName">Mr. TURNER</span>—</div>
            <p className="p-in-sp">I was induced to make that inquiry, because of the fact, that the Convention refused to entertain an ordinance dispensing with that obligation on the part of officers.</p>
            <div className="speaker" id="sp5438"><span className="persName">Mr. WICKHAM</span>—</div>
            <p className="p-in-sp">I will say that there is an act of Assembly which requires every officer of the Commonwealth to take that oath.</p>
            <div className="speaker" id="sp5439"><span className="persName">Mr. JOHNSTON</span>—</div>
            <p className="p-in-sp">I suppose the difficulty will be obviated by the introduction of a few words. Already the ordinance provides for the oath of fidelity to the State of Virginia.</p>
            <div className="speaker" id="sp5440"><span className="persName">Mr. EARLY</span>—</div>
            <p className="p-in-sp">The Constitution of the State prescribes, that all Executive, Judicial and Legislative officers shall take an oath to support the Constitution of the State and of the United States.</p>
            <div className="speaker" id="sp5441"><span className="persName">Mr. KILBY</span>—</div>
            <p className="p-in-sp">The gentleman may be right in that respect; but this ordinance says, in the 15th section: "Each and all of the officers named in this ordinance, and connected with the departments of the army and navy, shall take the oaths prescribed by the Code of Virginia, ch. 13, sections 1 and 3." It is necessary to know what those sections contain.</p>
            <div className="speaker" id="sp5442"><span className="persName">Mr. JOHNSTON</span>—</div>
            <p className="p-in-sp">I suppose, by that, they intended simply to refer to the oath to support the Constitution of the State.</p>
            <p>The question was put on the adoption of the ordinance, and it was decided in the affirmative.</p>
            <div className="speaker" id="sp5443"><span className="persName">Mr. MACFARLAND</span>—</div>
            <p className="p-in-sp">I beg leave to submit the following resolutions of inquiry:</p>
            <p className="p-in-sp">"Resolved, That the Committee of Finance report an ordinance fixing the compensation of the members of the Executive Council.</p>
            <p className="p-in-sp">"Resolved, That a committee of five enquire whether any, and what provision, should be made for the circuit and district courts of the United States within this State, and that they report by ordinance or otherwise."</p>
            <PageNumber num={697} />
            <p>The resolutions were adopted.</p>
            <div className="speaker" id="sp5444"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">The following is the Committee to consider the subject of that resolution: Messrs. <span className="persName">WM. H. MACFARLAND</span>, of Richmond; ROBERT E. SCOTT, of Fauquier; WOOD BOULDIN, of Charlotte; JAMES P. HOLCOMBE, of Albemarle; and JOHN M. SPEED, of Lynchburg.</p>
            <div className="speaker" id="sp5445"><span className="persName">Mr. CHAMBLISS</span>—</div>
            <p className="p-in-sp">I beg leave to offer the following ordinance:</p>
            <p className="p-in-sp">"AN ORDINANCE concerning officers in the, revenue and coast survey service of the United States."</p>
            <p className="p-in-sp">
              Be it ordained, That the ordinance passed on the seventeenth day of April, eighteen hundred and sixty-one, by the Convention, which directs the Governor of the Commonwealth to invite all efficient and worthy Virginians and residents of Virginia in the army and navy of the United States, to retire therefrom, and to enter the service of Virginia, be and the same is hereby extended to all
              such officers in the revenue service and coast survey service of the United States. And the Governor is authorized to make such provisions for them as may be proper and will not interfere with the rank conferred upon officers of the navy."
            </p>
            <p className="p-in-sp">I will state that these gentlemen, in the opinion of the Advisory Council, are not included in the invitations already extended.</p>
            <p>The ordinance was adopted.</p>
            <div className="speaker" id="sp5446"><span className="persName">Mr. CAPERTON</span>—</div>
            <p className="p-in-sp">I now call for the unfinished business, which is the ordinance to provide against the sacrifice of property.</p>
            <p>The ordinance was taken up, and reads as follows:</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>AN ORDINANCE to provide against the sacrifice of property and to suspend proceedings in certain cases.</p>
                <p>Be it ordained, That no execution shall be issued from the date hereof, by any court of record or magistrate, for the sale of property; nor shall there be any sales under deeds of trust or decrees, for the payment of money, until otherwise provided by law.</p>
                <p>Where the executions have issued and are now in the hands of officers, whether levied or not, if the debtor offer bond and security for the payment of the debt, interest and costs, when the operation of this ordinance ceases, the property shall be restored and the bond so taken shall be returned as in case of a forthcoming bond.</p>
                <p>
                  If the debtor offers no such bond, it shall be the duty of the officer to convene three freeholders from the vicinage, who, after being sworn, shall proceed to value the property according to what would have been <PageNumber num={698} /> its value on the 6th day of November, 1860, and unless the said property shall sell for the full amount of such
                  valuation, it shall be restored to the debtor without lien.
                </p>
                <p>Except in criminal cases or Commonwealth's prosecutions there shall be no trial of any cause requiring the intervention of a jury, but either party to such cause shall have the right, upon reasonable notice, to take the depositions of any witness, to be read de bene esse.</p>
                <p>In cases of misdemeanor, juries shall be summoned from the bystanders, and not under the law, as it now stands; and with the consent of the party prosecuted, the cause may be tried by the court.</p>
                <p>This ordinance shall not apply to liabilities upon the part of public officers either to the State, counties, municipal corporations or individuals.</p>
                <p>The time during which this ordinance is in force shall not be computed in any case where the statute of limitations comes in question.</p>
              </div>
            </div>
            <p>The pending question was upon the amendment or substitute offered by <span className="persName">Mr. JOHN T. SEAWELL</span>, of Gloucester, to the substitute offered by <span className="persName">Mr. GOGGIN</span>, which is as follows:</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>MR. GOGGIN'S SUBSTITUTE</p>
                <p>
                  1. Be it ordained, That no execution shall be issued after five days from the passage of this ordinance, by any court of record or magistrate for the sale of property; nor shall there be any sales under deeds of trust, except for the payment of interest and costs due according to the stipulations of such deeds; nor under decrees for the payment of money, until otherwise provided by
                  law.
                </p>
                <p>
                  2. Where executions have issued and are now in force in the hands of officers, whether levied or not, if the debtor shall tender bond with sufficient security, conditioned for the payment of the debt, interest and cost at the expiration of one year, at any time before or on the day of sale-in all such cases, where property has been levied on, upon the giving of such bond such
                  property shall be restored to the owner, and such bond shall be returned, and like proceedings shall then be had as in the case of forthcoming bonds.
                </p>
                <p>
                  3. If the debtor offers no such bond, it shall be the duty of the officer to summon three freeholders of the vicinage, who, after being first sworn for the purpose, shall proceed to value the property according to what would have been its value on the 6th day of November, 1860, and unless the said property shall sell for the full amount of such valuation, it shall be restored to
                  the debtor. Provided, <PageNumber num={699} /> however, that the lien of the creditor upon such property shall thereafter remain as if his fi. fa. were in force in the hands of the officer, and not levied.
                </p>
                <p>4. Except in criminal cases or Commonwealth's prosecutions there shall be no trial of any cause requiring the intervention of a jury, but either party to such cause shall have the right, upon reasonable notice, to take the depositions of any witness to be read de bene esse.</p>
                <p>5. In cases of misdemeanor, juries shall be summoned from the bystanders, and not under the law as it now is; and with the consent of the party prosecuted, the cause may be tried by the court.</p>
                <p>6. This ordinance shall not apply to liabilities upon the part of public officers, either to the State, to counties, to municipal corporations, or to individuals-nor to the liabilities of fiduciaries in their character as such.</p>
                <p>7. The time during which this ordinance is in force, shall not be computed in any case where the statute of limitations comes in question.</p>
                <p>8. This ordinance shall expire on the 30th day after the assembling of the next General Assembly.</p>
              </div>
            </div>
            <p>The following is the substitute offered by <span className="persName">Mr. SEAWELL</span> for <span className="persName">Mr. GOGGIN</span>'S substitute, as given above:</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>1. Be it ordained, That no execution shall be issued from and after the day of , 1861, on any judgment or decree of any court</p>
                <p>of record; nor by any justice of the peace; nor shall there be any sales under any deed of trust or mortgage, or decrees of any court of the State, until otherwise provided by law, except for the collection of interest and costs due thereon.</p>
                <p>
                  2. Where executions have issued from any court of record or single justice, and are now in the hands of the proper officers, whether levied or not, if the debtor offer to the officer in whose hands such execution may be, bond with good security, conditioned for the payment of the debt for which such execution issued, with all interest, costs and damages thereon, when the operation
                  of this ordinance ceases, said execution shall be returned by said officer to the office from which it issued, or to the clerk's office of the county court, if issued by a justice, and the bond so taken shall be returned with it, by said officer. In lieu of such bond and security, the said debt, interest, damages and costs for which said execution issued may be secured by deed of
                  trust or mortgage, on real or personal estate, if the parties interested shall prefer so to secure the same
                </p>
                <p>
                  3. If the debtor shall offer no such bond or other security, as aforesaid, <PageNumber num={700} /> it shall be the duty of the officer in whose hands such execution may be, to convene three freeholders of the vicinage, who, after being duly sworn by such officer, shall proceed to value any property on which said execution may have been levied,
                  according to what would have been its value on the 6th day of November, 1860; said officer shall then proceed to offer said property for sale according to law. Unless the same shall sell for the amount of such valuation, it shall be returned to the debtor. But the said execution shall retain its priority of lien on said property until otherwise provided by law. Provided that all
                  the provisions of this and the preceding section may be disregarded by consent of all parties interested in such execution.
                </p>
                <p>4. Except in criminal causes, on behalf of the Commonwealth, there shall be no judgment or decree for the payment of money rendered by any court of record or single justice, in this State, from and after the day of 1861.</p>
                <p>5. In cases of misdemeanor, juries shall be summoned from the vicinage, and not under the law, as it now stands, and by consent of the party accused, and the Attorney for the Commonwealth, any such cause may be submitted to, and decided by the court in which the same is pending.</p>
                <p>
                  6. This ordinance shall not apply to liabilities on the part of public officers. In all cases of money due and payable by fiduciaries, petition may be filed in chancery and payment enforced by decree of the court in such mode as it may deem proper. Provided that nothing in this ordinance shall be held to extend to attachments authorized by sections 1, 2, 3, 4 and 5 of chapter 151
                  of the Code of Virginia.
                </p>
                <p>7. The time during which this ordinance may be in force shall not be computed in any case where the statute of limitations shall come in question.</p>
                <p>8. This ordinance shall remain in force until repealed or changed by this Convention, or the General Assembly of the State; and if not so repealed or changed, shall expire at the end of thirty days after the first day of the next General Assembly.</p>
              </div>
            </div>
            <p>The question was upon this substitute offered by <span className="persName">Mr. SEAWELL</span>.</p>
            <div className="speaker" id="sp5447"><span className="persName">Mr. CAPERTON</span>—</div>
            <p className="p-in-sp">Before the vote is taken, it is competent for us to perfect the original ordinance.</p>
            <div className="speaker" id="sp5448"><span className="persName">Mr. CHAMBLISS</span>—</div>
            <p className="p-in-sp">I propose, on behalf of the committee, to amend the 4th section by striking out all after the word "jury" in the third line, and inserting "nor upon warrants for small claims before a justice."</p>
            <p>The amendment was agreed to.</p>
            <PageNumber num={701} />
            <div className="speaker" id="sp5449"><span className="persName">Mr. MACFARLAND</span>—</div>
            <p className="p-in-sp">I move to strike out the word "municipal" in the sixth line.</p>
            <p>The motion was rejected.</p>
            <div className="speaker" id="sp5450"><span className="persName">Mr. CHAMBLISS</span>—</div>
            <p className="p-in-sp">I propose to add the following, to come in at the end of the ordinance :</p>
            <p className="p-in-sp">"This ordinance shall remain in force until repealed or changed by this Convention or the General Assembly of the State; and if not so repealed or changed, shall expire at the end of thirty days after the first day of the next General Assembly."</p>
            <p>The amendment was agreed to.</p>
            <div className="speaker" id="sp5451"><span className="persName">Mr. CAPERTON</span>—</div>
            <p className="p-in-sp">I propose to amend the 1st section by inserting in the third line, after the word "property," the words "until otherwise provided by law."</p>
            <p>The amendment was agreed to.</p>
            <div className="speaker" id="sp5452"><span className="persName">Mr. CAPERTON</span>—</div>
            <p className="p-in-sp">I propose further to amend the 1st section by inserting after the word "money," in the fourth line, the words, "or for the sale of real or personal property."</p>
            <div className="speaker" id="sp5453"><span className="persName">Mr. MACFARLAND</span>—</div>
            <p className="p-in-sp">I hope the gentleman will consent to a modification of his amendment, the propriety of which, I am sure, he will see.</p>
            <p className="p-in-sp">Deeds of trust very frequently cover property of a very perishable nature. It is a very common thing in the State, to convey by trust deeds steamboats, for example, furniture and things of that sort. A steamboat will not last six months if laid up and kept idle; and so with flour and corn, and many other articles, upon which deeds of trust are given.</p>
            <p className="p-in-sp">
              There is no reason, I apprehend, for withholding from sale property which is perishable in its nature, and, therefore, liable to great depreciation. I would extend the exemption to the sale of land and slaves. If land and slaves be protected, enough will have been accomplished to secure all that, is just and fair in this connection. All other species of property are essentially
              perishable in their nature, and the exemption ought to apply to them.
            </p>
            <div className="speaker" id="sp5454"><span className="persName">Mr. AMBLER</span>—</div>
            <p className="p-in-sp">I think the gentleman will get rid of the difficulty by inserting after the amendment which the gentleman from Monroe [<span className="persName">Mr. CAPERTON</span>] , has just offered, the words "unless by the consent of the parties interested."</p>
            <div className="speaker" id="sp5455"><span className="persName">Mr. MACFARLAND</span>—</div>
            <p className="p-in-sp">
              I am prepared to go as far as any gentleman in protecting the debtor class. It seems to me, however, that there may be many cases in which the grantor would not consent to the sale of property. He may desire to retain the property in his possession, and its depreciation within that period might amount to as much as the whole <PageNumber num={702} /> debt.
              I happen to know that there are deeds of trust upon steamboats. Now, what would they be worth if they were tied up for six months? I think there ought to be some provision for perishable goods.
            </p>
            <p className="p-in-sp">Suppose a debtor was to convey his crop of corn for a debt, and you interpose to prevent the sale of it; if he does not use it, what would it be worth at the end of 12 months?</p>
            <div className="speaker" id="sp5456"><span className="persName">Mr. CHAMBLISS</span>—</div>
            <p className="p-in-sp">The Committee, in drafting this ordinance, intended only a temporary relief, presuming that the Legislature would be in session in a very few weeks, and would go into the details of the stay law.</p>
            <p className="p-in-sp">
              This law is intended for the protection of the debtor who is called into the service of the State. I assure you we had very little idea of taking much care of the creditor. We could not do it. All stay laws, no doubt, open the door to fraud; and while we do not mean to do any injustice to the creditor, we did endeavor, in framing this ordinance, to afford due protection to the debtor.
            </p>
            <p className="p-in-sp">The main portion of the community are debtors; and inasmuch as it is probable that the larger portion of those who will constitute our army, will come from that class; we deemed it but an act of justice to guard them against the losses which would result from a sale of their property under the depressing circumstances attendant upon war.</p>
            <p className="p-in-sp">In regard to the case cited by my friend from Richmond [<span className="persName">Mr. MACFARLAND</span>]
              , if a grantor conveys his corn for the payment of his debts, and he can consume it before the Legislature can pass a law with all the details which can render it effective and just to all, I am perfectly willing he should do it to the detriment of the creditor. I will not consent, so far as I am concerned, to allow the debtor of the trustee to consent to the sale of his property at a
              sacrifice, and, perhaps, under some form of duress.
            </p>
            <p className="p-in-sp">
              While I am up, I will take occasion to allude to some other provisions in that ordinance which I know will be subjects of attack by the opponents of this law. I allude especially to the provision which requires the property now under execution to be restored without a lien. If you permit people to sue and get judgments, and these judgments are to have the effect of a lien, you will
              invite thousands upon thousands to bring suits throughout the State.
            </p>
            <p className="p-in-sp">Our purpose is to prevent that very thing of lien, so that the personal property of the whole people may not be tied up by a lien, which would hold until after the war is over, and result in immense loss.</p>
            <PageNumber num={703} />
            <p className="p-in-sp">
              Suppose you get a judgment and issue an execution. Some poor man, who is off in the service of the country, has a good horse, for instance, and his family, who may need the price of it to aid in their support, cannot dispose of it, forsooth, because it is under a lien. So that this whole subject of lien must be destroyed if you intend to pass any stay law which will operate to the
              advantage of those who go into the service of the State. A man sues me; gets an execution, and unless I give security, he will sell my property at a sacrifice, as all property must be sold which goes to the hammer at such times.
            </p>
            <div className="speaker" id="sp5457"><span className="persName">Mr. MORRIS</span>—</div>
            <p className="p-in-sp">That provision, as I understand it, places the execution now in the hands of the officers, whether levied on or not, on the same footing as a forthcoming bond, if the debtor offer bond and security for the payment of the debt, interests and costs when the ordinance ceases to operate.</p>
            <div className="speaker" id="sp5458"><span className="persName">Mr. CHAMBLISS</span>—</div>
            <p className="p-in-sp">
              In relation to that lien, he can save himself from any loss. We do not intend to destroy the effect of any lien already created, except by the consent of the creditor himself. Why, unless the debtor offers bond and security for the payment of the debt, interests and costs when this stay law ceases, then three neighbors are called in, under oath, to value the property, and unless it
              sells for its value on the 6th day of November last, it is to be restored to the debtor without lien. If a man is so anxious to secure his debt out of this poor debtor, let him take the property at what it was estimated to be worth at the date I have just mentioned.
            </p>
            <p className="p-in-sp">
              I am merely making these general remarks in order that the Convention may see the views that prevailed in the committee in reference to this ordinance. I wish the Convention to understand that the object of the committee was to protect the poor debtors who are called into the service of the State, and they discarded all system of lien and security to make the stay law effectual for
              that class.
            </p>
            <div className="speaker" id="sp5459"><span className="persName">Mr. MACFARLAND</span>—</div>
            <p className="p-in-sp">I propose to amend the first section by inserting at the end of the word "money," the following provision: "except of property perishable in its nature."</p>
            <div className="speaker" id="sp5460"><span className="persName">Mr. CAPERTON</span>—</div>
            <p className="p-in-sp">That precisely embraces the very description of property held by the poor.</p>
            <div className="speaker" id="sp5461"><span className="persName">Mr. MACFARLAND</span>—</div>
            <p className="p-in-sp">I would substitute for the word "perishable in its nature" the words "consumed in its."</p>
            <div className="speaker" id="sp5462"><span className="persName">Mr. MOFFETT</span>, of Rappahannock—</div>
            <p className="p-in-sp">The effect of the amendment proposed by the gentleman from Richmond [<span className="persName">Mr. MACFARLAND</span>]
              , in the county I represent, would be to subject to sale the very property which of all others should be exempt. Those who are now rushing to the <PageNumber num={704} /> defence of the State are the people who have neither land nor negroes; so that the effect of the amendment would be to do away with all the benefit to that class of debtors who were
              designed to be benefited by it.
            </p>
            <div className="speaker" id="sp5463"><span className="persName">Mr. MACFARLAND</span>—</div>
            <p className="p-in-sp">
              I hope it will not be considered that I am at all unfavorable to the class to whom the gentleman referred. I think it is our duty to pay some respect to the contracts of parties. It cannot be expected by one who conveys his property to secure a debt, that it is likely to be consumed by its use. I apprehend that the Convention in exempting that property, is offering a premium to bad
              faith. I imagine that deeds of trust will grow into disfavor, under this legislation. If parties entered into contracts heretofore by which their property is made liable, is it right for this Convention to step in and take away the security which the contract gives? Is that just? It certainly cannot be. All I propose to do is to bring before the minds of the Convention that, while the
              poorer class of the community is entitled to privileges under existing circumstances, something is due to contracts, that something is due to the rights of creditors. There is no hardship in my opinion in subjecting to sale, according to the agreement of parties, property of the description to which my amendment refers.
            </p>
            <div className="speaker" id="sp5464"><span className="persName">Mr. BORST</span>—</div>
            <p className="p-in-sp">
              The gentleman from the city of Richmond seems to think that steamboats, among other things, are fit subjects for exclusion from the operation of this ordinance. If I understand aright, it is very little difference what becomes of the steamboats, so far as the effects of the ordinance, in a general sense, are concerned. Steamboats, the few of them that are in the State, are owned by
              persons along the line of our rivers, who constitute but an insignificant portion of our community. Such an exclusion as the gentleman proposes would only benefit a few wealthy men, who may have deeds of trust upon that species of property; but no other.
            </p>
            <p className="p-in-sp">I trust that no such exclusion will be granted, and that the proposed amendment will be rejected.</p>
            <p>The question was then put on the amendment, and it was rejected.</p>
            <p>The question then recurred on the amendment offered by <span className="persName">Mr. CAPERTON</span>.</p>
            <p><span className="persName">Mr. C</span>. offered the following in lieu of that which he had already submitted: strike out the words "for the payment of money," in the 4th line of the first section, and insert "unless by the consent of the parties interested."</p>
            <p>The amendment was agreed to.</p>
            <div className="speaker" id="sp5465"><span className="persName">Mr. CAPERTON</span>—</div>
            <p className="p-in-sp">
              I propose to add to the second section the following: <PageNumber num={705} /> "And shall constitute a lien on the realty of the obligors to the same extent, and in the same manner as forthcoming bonds now do, and judgment may be had on said bonds in the manner and by the same proceedings as judgments may be obtained on forthcoming bonds under existing
              laws."
            </p>
            <p>The amendment was adopted.</p>
            <div className="speaker" id="sp5466"><span className="persName">Mr. JOHNSTON</span>, of Lee and Scott—</div>
            <p className="p-in-sp">I move to amend by adding to the second section, as amended, the following: "And the same shall not be levied until otherwise provided by law; but the creditor's lien shall be preserved."</p>
            <div className="speaker" id="sp5467"><span className="persName">Mr. CHAMBLISS</span>—</div>
            <p className="p-in-sp">That defeats the whole object we had in view. It creates an eternal lien. Until this ordinance is dispensed with, the execution is to lay in the hands of the officers, and it will operate as a lien.</p>
            <p className="p-in-sp">I hope it will not be the pleasure of the Convention to adopt that amendment. It will be found that all the amendments that will be offered here, or at least a great majority of them, will be designed to enact a lien upon the very class whom it is intended to protect.</p>
            <p>The amendment was rejected.</p>
            <div className="speaker" id="sp5468"><span className="persName">Mr. THORNTON</span>—</div>
            <p className="p-in-sp">I would remark, that I think the gentleman from Monroe [<span className="persName">Mr. CAPERTON</span>] , was mistaken as to the effect of a forthcoming bond. If the conditions of this bond be not complied with, it can be returned to the office of the clerk of the court, and shall have the force of a judgment.</p>
            <div className="speaker" id="sp5469"><span className="persName">Mr. MORRIS</span>—</div>
            <p className="p-in-sp">
              I propose to amend the second section as amended by <span className="persName">Mr. CAPERTON</span>, by adding after the words "in the same manner as," in his amendment, the words "forfeited forthcoming bonds returned to the clerk's office," so that that part of the amended section will read: "And the bond so taken shall be returned as in case of a forthcoming bond; and shall constitute a
              lien on the realty of the obligors to the same extent and in the same manner as forfeited forthcoming bonds returned to the clerk's office now do, and judgment may be had on said bond," &amp;c.
            </p>
            <p>The amendment was adopted.</p>
            <div className="speaker" id="sp5470"><span className="persName">Mr. CHAMBLISS</span>—</div>
            <p className="p-in-sp">I move to amend the 6th section by adding thereto the following: "Nor to debts hereafter contracted."</p>
            <div className="speaker" id="sp5471"><span className="persName">Mr. SHEFFEY</span>—</div>
            <p className="p-in-sp">This would be to give precedence to subsequent debts over the debts first contracted.</p>
            <div className="speaker" id="sp5472"><span className="persName">Mr. STUART</span>, of Augusta—</div>
            <p className="p-in-sp">I would say to the gentleman from Smyth [<span className="persName">Mr. SHEFFEY</span>] , that if there is no limitation, you afford the poor man no credit.</p>
            <PageNumber num={706} />
            <p>The amendment was agreed to.</p>
            <div className="speaker" id="sp5473"><span className="persName">Mr. BAYLOR</span>, of Augusta—</div>
            <p className="p-in-sp">I rise to offer an amendment to the first section.</p>
            <p className="p-in-sp">
              I understand that this ordinance is intended to protect persons who are in the service of the State. I regard this as altogether proper; but I do not see that there is any distinction made at all between those who are in the service and those who are not. It will benefit all alike; and while it is intended particularly and solely for the benefit of those who are in service, the
              ordinance does not say so. I, therefore, propose to add to the first section the following:
            </p>
            <p className="p-in-sp">"Provided, however, That this ordinance shall only apply in cases where persons are engaged in the service of the State."</p>
            <div className="speaker" id="sp5474"><span className="persName">Mr. CHAMBLISS</span>—</div>
            <p className="p-in-sp">We do not know but that all may soon be in the service of the State.</p>
            <p>The amendment was rejected.</p>
            <div className="speaker" id="sp5475"><span className="persName">Mr. WICKHAM</span>—</div>
            <p className="p-in-sp">I desire to amend the third section by striking out the words "without lien," at the end of the section, and inserting in lieu thereof the following:</p>
            <p className="p-in-sp">"But the said execution shall retain its authority of lien until otherwise provided by law."</p>
            <p className="p-in-sp">I am satisfied to do what I can to protect the interest of those who may be engaged in the laudable work of State defence; but I am not for legislating more particularly for the benefit of the debtor than of the creditor, further than securing to the debtor a sufficient time to enable him to pay his debts.</p>
            <p className="p-in-sp">But I deem it just to place it in the power of the creditor to secure himself against loss, when the ordinance ceases to operate, by giving to his execution the authority of a lien, as this amendment proposes.</p>
            <p className="p-in-sp">I will vote cheerfully for the ordinance with this provision in it; but without such a provision, I would regard it as involving serious injury to the State, and could not, therefore, support it. I think we can do ample justice to the debtor without inflicting serious injury upon the creditor.</p>
            <div className="speaker" id="sp5476"><span className="persName">Mr. WILSON</span>—</div>
            <p className="p-in-sp">I will observe that, if you permit a lien to hang over property, you destroy its value. As security, it may be desirable and necessary; but its effect upon property will be to depreciate it very materially.</p>
            <div className="speaker" id="sp5477"><span className="persName">Mr. BAYLOR</span>—</div>
            <p className="p-in-sp">I move that we take a recess until 5 o'clock.</p>
            <p>The motion was rejected.</p>
            <div className="speaker" id="sp5478"><span className="persName">Mr. KILBY</span>—</div>
            <p className="p-in-sp">I move we pass by the consideration of this question, for the purpose of taking up the bill authorizing the issue of Treasury <PageNumber num={707} />, from the Finance Committee.</p>
            <p>The motion was lost-ayes 32; noes 39.</p>
            <div className="speaker" id="sp5479"><span className="persName">Mr. ADDISON HALL</span>, of Lancaster and Northumberland—</div>
            <p className="p-in-sp">I move we take a recess until 5 o'clock.</p>
            <p>The motion was agreed to, and the Convention accordingly adjourned till that hour.</p>
            <div className="session" id="vsc1965.v4.2.14.1">
              <h3><span className="headingNumber">1.14.1. </span><span className="head">EVENING SESSION</span></h3>
              <p>The Convention re-assembled at the appointed hour.</p>
              <p><span className="persName">Mr. SPEED</span>, by general consent, offered the following resolution:</p>
              <p>Resolved, That when the Convention adjourns on to-morrow, it will adjourn to meet on Wednesday, the 12th day of June next, unless sooner convened by the Governor of the Commonwealth. And the Governor of the Commonwealth is hereby authorized to make such call at any time before that day, when, in his opinion the public interests may require it.</p>
              <div className="speaker" id="sp5480"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">For one, I wish we could have adjourned a month ago; but I am unwilling that we shall adjourn so long as any important duty remains to be discharged.</p>
              <div className="speaker" id="sp5481"><span className="persName">Mr. SPEED</span>—</div>
              <p className="p-in-sp">I offered the resolution so as to meet the contingency of our not having a quorum. I fixed the adjournment for to-morrow, supposing that we can get through with the business. If not, we can alter the resolution.</p>
              <div className="speaker" id="sp5482"><span className="persName">Mr. MORRIS</span>—</div>
              <p className="p-in-sp">I suggest that we can hardly have the business complete at that time, and would indicate a later day.</p>
              <div className="speaker" id="sp5483"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">
                I hope that the resolution will not be adopted. I am perfectly satisfied that there are a great many things remaining to be done which are absolutely necessary in order to leave the Commonwealth in a condition to be defended in our absence. I have heard a great many insinuations by gentlemen who have absented themselves from this Convention a great deal of the time. There are
                gentlemen here who are absenting themselves from this hall, leaving no quorum; and I doubt very much if these gentlemen are not the most clamorous, because of the want of a quorum.
              </p>
              <p className="p-in-sp">
                There is a great deal yet to be done in regard to the organization of our forces, and other important matters which cannot be disposed of by to-morrow evening. I hope that gentlemen, during the remainder of the session, will be more regular and prompt in their attention to the business here, so that we may act with more dispatch, and bring the Convention quickly to a close. We have a
                number of <PageNumber num={708} /> nominations now upon our table which ought to have been acted upon heretofore. But they have not been, because of the time being occupied in useless discussions upon questions of much less importance.
              </p>
              <p className="p-in-sp">I move to lay the resolution on the table.</p>
              <div className="speaker" id="sp5484"><span className="persName">Mr. SPEED</span>—</div>
              <p className="p-in-sp">I hope the gentleman will withdraw that motion for a moment.</p>
              <div className="speaker" id="sp5485"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">I withdraw it for the present.</p>
              <div className="speaker" id="sp5486"><span className="persName">Mr. SPEED</span>—</div>
              <p className="p-in-sp">
                If the gentleman had been as attentive to the proceedings of the Convention as he says other members ought to have been, he would have ascertained from the statement which I made when I offered my proposition, that it provided for the contingency of the absence of a quorum. If there is a quorum here, we will have the whole subject under our control, and can make such provisions as
                may be necessary. If the resolution is not adopted now, its adoption to-morrow may be imperiled by the want of a quorum.
              </p>
              <p className="p-in-sp">With this explanation, I hope the Convention will adopt the resolution.</p>
              <p>It was adopted.</p>
              <div className="speaker" id="sp5487"><span className="persName">Mr. LEAKE</span>, of Goochland—</div>
              <p className="p-in-sp">I ask the Convention, on behalf of my friend, the representative from King and Queen [<span className="persName">R. H. COX</span>] , that he may be permitted to record his vote for the Convention entered into between Virginia and the Confederate States, as also the ordinance adopting the provisional constitution of these States.</p>
              <p className="p-in-sp">The gentleman was unavoidably absent when the vote was taken on these subjects.</p>
              <p>Leave was granted.</p>
              <div className="speaker" id="sp5488"><span className="persName">Mr. HUNTON</span>, of Prince William—</div>
              <p className="p-in-sp">I desire the privilege of having my vote recorded in favor of the ordinance on the subject of equal taxation, and the Convention and ordinance just referred to by the gentleman from Goochland [<span className="persName">Mr. LEAKE</span>] .</p>
              <p>Leave was granted.</p>
              <div className="speaker" id="sp5489"><span className="persName">Mr. BRANCH</span>—</div>
              <p className="p-in-sp">I desire to record my vote in favor of the tax ordinance.</p>
              <p>The House granted the permission.</p>
              <div className="speaker" id="sp5490"><span className="persName">Mr. JOHN Q. MARR</span>, of Fauquier—</div>
              <p className="p-in-sp">I ask the privilege of recording my vote for the ordinance of secession, the Convention entered into with the Confederate States, and the ordinance adopting the provisional constitution of the Southern Confederacy.</p>
              <p>The privilege was granted.</p>
              <div className="speaker" id="sp5491"><span className="persName">Mr. R. E. GRANT</span>, of Washington county—</div>
              <p className="p-in-sp">
                It has been my misfortune to be detained from the deliberations of this body for some <PageNumber num={709} /> time in attendance on a sick family. I have myself been very unwell for the past two weeks, and therefore unable to be present. I now ask the privilege of recording my vote for the following ordinances : the ordinance of secession, the tax
                ordinance, the ordinance adopting the Constitution of the Provisional Government of the Southern Confederacy, and the convention entered into between Virginia and the Confederate States.
              </p>
              <p>Leave was granted in each instance.</p>
              <div className="speaker" id="sp5492"><span className="persName">Mr. A. M. BARBOUR</span>, of Jefferson—</div>
              <p className="p-in-sp">I would ask the privilege of recording my vote for the ordinance of secession, the ordinance adopting the Provisional Government of the Confederate States, and the convention between the Confederate States and Virginia.</p>
              <div className="stage it">[Granted.]</div>
              <div className="speaker" id="sp5493"><span className="persName">Mr. GOODE</span>, of Bedford—</div>
              <p className="p-in-sp">I ask a similar courtesy for my friend from Mecklenburg [Thos. F. GOODE] , in regard to the ordinance adopting the Provisional Government of the Confederate States, and the convention between these States and Virginia.</p>
              <div className="stage it">[Granted.]</div>
              <div className="speaker" id="sp5494"><span className="persName">Mr. CAMPBELL</span>, of Washington county—</div>
              <p className="p-in-sp">I received a letter last evening from <span className="persName">Mr. FUGATE</span>, of Scott county, and I feel warranted in stating in his behalf that, if he were here, he would ask the Convention for permission to change his vote upon the ordinance of secession.</p>
              <p className="p-in-sp">I do not feel fully authorized, however, to ask that privilege for him.</p>
              <div className="speaker" id="sp5495"><span className="persName">Mr. FLOURNOY</span>, of Halifax—</div>
              <p className="p-in-sp">I received a letter on yesterday from a member of this Convention-<span className="persName">Mr. ALLEN C. HAMMOND</span>, of Berkeley—in which he expresses a desire that the Convention would allow him to change his vote on the ordinance of secession from the negative to the affirmative.</p>
              <div className="speaker" id="sp5496"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">His letter can be placed upon the journal.</p>
              <div className="speaker" id="sp5497"><span className="persName">Mr. FLOURNOY</span>—</div>
              <p className="p-in-sp">I would do so were it not that it refers to other matters besides that.</p>
              <div className="speaker" id="sp5498"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The leave may be entered without the vote being changed, and when he returns he can sign the ordinance.</p>
              <p>The Convention so ordered.</p>
              <p>The consideration of the ordinance to prevent the sacrifice of property was then resumed.</p>
              <p>The pending question was upon the amendment offered by <span className="persName">Mr. WICKHAM</span>, which was to strike out the two last words, "without lien" in the third section and insert the following in lieu thereof :</p>
              <p>"But the said execution shall retain its authority of lien until otherwise provided by law."</p>
              <div className="speaker" id="sp5499"><span className="persName">Mr. HALL</span>, of Marion—</div>
              <p className="p-in-sp">
                I move to amend the amendment by inserting <PageNumber num={710} /> the following after the word "shall" in the 6th line: "upon the debtor's executing a bond in due form according to law, with good security, condition for the forthcoming of the property when this ordinance shall cease to be in force, be restored to the debtor," &amp;c.
              </p>
              <div className="speaker" id="sp5500"><span className="persName">Mr. CAPERTON</span>—</div>
              <p className="p-in-sp">I imagine we had better dispose of this question as rapidly as possible, and in order to do that, I move the previous question.</p>
              <p>The motion was sustained and the main question being put, which was upon the amendment to the amendment offered by <span className="persName">Mr. HALL</span>, it was decided in the negative.</p>
              <p>The question then recurred upon the adoption of the amendment offered by <span className="persName">Mr. WICKHAM</span>, and it was rejected.</p>
              <div className="speaker" id="sp5501"><span className="persName">Mr. WICKHAM</span>—</div>
              <p className="p-in-sp">I move to amend the third section by striking out the words "would have been its value," in the fourth line, and inserting the words "it would have brought at a Sheriff's sale."</p>
              <p className="p-in-sp">The object of that simply is, to arrive at what would have been the amount received by the individual whose property may be under execution, if it had been sold on the 6th day of November last.</p>
              <div className="speaker" id="sp5502"><span className="persName">Mr. CHAMBLISS</span>—</div>
              <p className="p-in-sp">I ask for a division of the question.</p>
              <p>The question was first upon striking out.</p>
              <p>The vote having been taken on this branch of the subject, it was decided in the negative.</p>
              <p>The amendment fell to the ground, the Convention having refused to strike out that for which it was offered as a substitute.</p>
              <div className="speaker" id="sp5503"><span className="persName">Mr. SPEED</span>—</div>
              <p className="p-in-sp">I propose to amend the 3rd section by striking out the last two words, "without lien."</p>
              <p className="p-in-sp">
                I will state that it seems to me that when the creditor makes a legal effort to procure the payment of his debt that he ought not to be placed in a worse position at the termination of the effort than he was during its progress. It seems to me, if he should fail to get the payment of his debt, after he has exhausted the remedy which the law provides for him, that his chances of
                payment ought not, on that account, be diminished. This section leaves him without lien by his execution in any form, and, in fact, inflicts a penalty upon him if he fails in the effort that he has in view.
              </p>
              <p>The question having been taken by a count, resulted-ayes 38, noes 39.</p>
              <p>So the amendment was rejected.</p>
              <div className="speaker" id="sp5504"><span className="persName">Mr. SCOTT</span>, of Fauquier—</div>
              <p className="p-in-sp">
                I, looked, not with a great deal of care, into both of the proteges,<span className="note" id="Note68"
                ><span className="noteLabel">5</span>The word proteges used here and on the following page is thought to be meant for the French word protégés, and that word was perhaps mistakenly used instead of projets. Evidently the speaker refers to draft versions (projets) of the ordinance to provide against the sacrifice of property. Compare note 6 on Proceedings of April 22.</span
                >
                and I regret to say that neither of them are, at present, in a condition in which they ought to receive the <PageNumber num={711} /> sanction of this Convention.
              </p>
              <p className="p-in-sp">It is a very difficult thing to prepare an ordinance of this character. They require a great deal of care, much more than I think has been expended upon these.</p>
              <p className="p-in-sp">As I understand them, both contain provisions prohibiting the issuing of executions generally.</p>
              <p className="p-in-sp">
                Now, if gentlemen will recur to chapter 42 of the Code of Virginia they will find that that chapter contains provisions for the enforcement of debts due to the Commonwealth. I do not think the Commonwealth is in a condition now to stand a stay law, which would prevent her from recovering debts due to her. Her treasury is rather too empty for such a process as that; and, I think, in
                the preparation of any stay law, care should be taken to exempt its operation in respect to debts due to the Commonwealth. Chapter 187 of the Code provides, among other things, for executions against corporations. I do not well see why they should be suspended. That chapter also provides for executions for the recovery of specific property. A takes the horse of B; B sues him, gets
                judgment, and surely he ought not to be stayed in the process to recover back property that had been wrongfully taken from him. And yet no provision here takes out of the operation of the ordinance executions for the Commonwealth or executions for specific property.
              </p>
              <p className="p-in-sp">
                So in regard to rights of possession. One man takes another's land; the owner sues him, and gets judgment for the land. Why prohibit him from gaining possession of the land which was unjustly taken by another? And there are cases where process of execution goes against non-residents, or those who are not citizens of the Commonwealth. Why protect their property? Why stay the hands of
                your own citizen in his recovery of a debt due by one who is not a citizen; and yet neither of these proteges makes any provision in that case? When you propose to continue the liabilities of sureties-in fact, to make new contracts for them-I think some provision ought to be made, so as to enable sureties, who desire relief, to seek their relief; otherwise, it seems to me that you
                are making a new contract for them.
              </p>
              <p className="p-in-sp">
                Now, sir, I call the attention of gentlemen who have this matter in charge-though I believe I was appointed on the committee; but the condition of my health for some days has been such as to prevent my attending its meetings-to the fact that neither of these proteges are sufficiently guarded in the particulars to which I have adverted. I repeat, I see no reason why the Commonwealth
                should be impeded <PageNumber num={712} /> in the recovery of a debt of hers. I see no reason, therefore, why this ordinance should be allowed to apply to chapter 42 at all. I see no reason why a corporation should be exempt from process of execution, no reason why process for specific property should be exempted. I see no reason why the right of
                possession of land which has been taken from you should be interfered with. I see no reason why this ordinance should apply to those who are not citizens of the Commonwealth.
              </p>
              <p className="p-in-sp">
                It seems to me that amendments ought to be made in these particulars. If any gentleman will sit down to the task of removing these objections and making the necessary provisions, I think he will find that he has a very arduous task on hand; one that, probably, he will not discharge himself of within the brief time that now seems to be allotted to this session. I was going to say,
                that as it seems we will come back on some early day in the month of June, I see no great detriment that will accrue to the citizens of the Commonwealth, if we should lay the whole ordinance by until our return.
              </p>
              <p className="p-in-sp">
                As an additional reason to that, the Code now provides proper exemption in all cases where persons are in the military service of the State. You cannot sue out executions against them. So that, so far as they are concerned, a provision now exists in the Code. So far as others are concerned, I think they may remain subject to the liabilities of the law until this Convention
                re-assembles in the month of June.
              </p>
              <p className="p-in-sp">I throw out that suggestion for the consideration of the gentlemen who have this ordinance in charge.</p>
              <div className="speaker" id="sp5505"><span className="persName">Mr. CAPERTON</span>—</div>
              <p className="p-in-sp">I regret very much that the committee could not have had the benefit of all these suggestions with which the gentleman has just favored the Convention. It occurs to me that every defect might have been supplied if he merely suggested amendments upon all these subjects.</p>
              <p className="p-in-sp">
                Now, in relation to executions, the first objection was that this ordinance embraced executions for specific property. That could be removed in an instant by issuing no execution for payment of money except in favor of the Commonwealth and against non-residents; and so in relation to every defect to which the gentleman referred. They can be corrected by the insertion of a word or two
                here and there in the ordinance if the gentleman will make his objections and will indicate where the amendment is to be made. We did not intend that this was to be permanent. It was designed to be merely temporary. It was intended to relieve those who, being compelled to leave <PageNumber num={713} /> their homes, might be subject to all the
                inconveniences resulting from the enforcement of executions against them. Surely, sir, notwithstanding all the defects to which the gentleman refers, no great injury can result from the passage of this ordinance, since we will again assemble here early in June. If we find that this works badly, as the Convention will be in session before the Legislature meets, we can make such
                amendments as the experience of the intervening period, and a better consideration of the provisions of this ordinance may suggest. It occurred to me that there was not one of them that could not be remedied by amendments within the time occupied by the gentleman in enumerating them.
              </p>
              <p className="p-in-sp">If any gentleman will suggest any particular defect, I for one will readily agree to amend it.</p>
              <div className="speaker" id="sp5506"><span className="persName">Mr. MORRIS</span>—</div>
              <p className="p-in-sp">I move to recommit the report with the substitutes to the committee.</p>
              <div className="speaker" id="sp5507"><span className="persName">Mr. CAPERTON</span>—</div>
              <p className="p-in-sp">I trust the ordinance will not be recommitted, because the effect of its recommitment will be to defeat it. If we devote a short time to it here, we can make it as perfect as can be.</p>
              <div className="speaker" id="sp5508"><span className="persName">Mr. TURNER</span>, of Jackson—</div>
              <p className="p-in-sp">I shall regard this as a test question for the purpose of defeating the proposition.</p>
              <div className="speaker" id="sp5509"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">If the members of the Convention will look closely into this ordinance, they will find that it is not subject to any of the criticisms made upon it by the gentleman from Fauquier [<span className="persName">Mr. SCOTT</span>] .</p>
              <p className="p-in-sp">The first provision is: "That no execution shall be issued from the date hereof by any court of record or magistrate for the sale of property." Now, sir, what sort of an execution is that? Is it a distringas or a writ of possession? It is a writ of fieri facias. The first section does not apply to writs of possession or writs of distringas.</p>
              <p className="p-in-sp">The second section is:</p>
              <p className="p-in-sp">"When executions have issued and are now in the hands of officers, whether levied or not, if the debtor offer bond and security for the payment of the debt, interest and costs, when the operation of this ordinance ceases, the property shall be restored and the bond so taken shall be returned as in the case of a forthcoming bond."</p>
              <p className="p-in-sp">This does not allow him to discharge the property that he sued under a writ of distringas; and then in the 3d section it is provided that "If the debtor offers no such bond, it shall be the duty of the officers to convene three freeholders from the vicinage, who, after being sworn, shall proceed to value the property-"</p>
              <p className="p-in-sp">What property? The very property which has been levied upon, and which has not been restored by reason of the bond not being given.</p>
              <PageNumber num={714} />
              <p className="p-in-sp">"-shall proceed to value the property according to what would have been its value on the 6th day of November, 1860, and unless the said property shall sell for the full amount of such valuation, it shall be restored to the debtor without lien."</p>
              <p className="p-in-sp">Now, sir, the next section provides, that "except in criminal cases, or Commonwealth's prosecutions, there shall be no trial of any cause requiring the intervention of a jury, but either party to such cause shall have the right, upon reasonable notice, to take the depositions of any witness to be read de bene esse."</p>
              <p className="p-in-sp">
                That might delay the trying of actions for the recovery of real property, or the recovery of specific property. But that can do no very great damage. Already the Judges of the courts have decided not to hold any courts, and this can produce but very little evil. The Convention will re-assemble in June, and the Legislature, perhaps, sooner than that; and any amendments, which in the
                meantime, may be found necessary, can be made, and any defects which may be developed easily remedied.
              </p>
              <p className="p-in-sp">Now, in regard to the class of debts due to the Commonwealth, I would ask, what class of debts is the Commonwealth in need of? It is the tax, the revenue in the hands of public officers, and it is only necessary to provide against any delay in the collection of these liabilities, in order to save the Commonwealth from any inconvenience.</p>
              <p className="p-in-sp">The section next to the last, provides, that "this ordinance shall not apply to liabilities upon the part of public officers either to the State, counties, municipal corporations or individuals."</p>
              <p className="p-in-sp">
                Now, sir, what class of debts are these due to the Commonwealth which are not embraced in this section so that it is necessary for her to get?<span className="note" id="Note69"><span className="noteLabel">6</span>The closing section of this question seems to be missing.</span> None, I imagine. And in regard to debts against corporations, I submit, that no injury can result, so far as this
                ordinance applies to them.
              </p>
              <p className="p-in-sp">My opinion is, that the ordinance is not subject to any one of the criticisms made upon it by the gentleman from Fauquier [Mr. SCOTT] .</p>
              <div className="speaker" id="sp5510"><span className="persName">Mr. BAYLOR</span>, of Augusta—</div>
              <p className="p-in-sp">
                I acknowledge that some portions of this ordinance seem to be plain enough. While that is so, there is great difficulty in my mind in regard to other portions of it. For instance, take the fourth section. "Except in criminal cases, or Commonwealth's prosecutions, there shall be no trial of any cause requiring the intervention of a jury; but either party to such cause shall have a
                right, upon reasonable notice, to take the depositions of any witness, to be read de bene esse."
              </p>
              <p className="p-in-sp">
                Now, I take it that this mode of proceeding is intended to benefit <PageNumber num={715} /> these people who are in the service of the Commonwealth. That I understand to be the peculiar object of this whole ordinance. Now, if this section passes as it stands, it will work both ways, in all probability; for it happens that I know of my own knowledge
                persons in the service of the Commonwealth who are not poor, but who have an abundance, and who even have debtors at home. I know that if no suit be instituted to give the plaintiff a lien, by the time these p6ople return home they will have nothing to get a lien upon.
              </p>
              <p className="p-in-sp">
                I say that it is a difficult matter to pass any ordinance of this character that will not operate injuriously to the creditor. According to the Code of Virginia now, you cannot institute a suit against a soldier in the service of the State. I do not see what injury can result from a postponement of this matter until the Convention reassembles in June, when it can be more maturely
                considered and rendered less liable to objections.
              </p>
              <div className="speaker" id="sp5511"><span className="persName">Mr. BRANCH</span>—</div>
              <p className="p-in-sp">I call the previous question upon the motion to recommit.</p>
              <p>The call was sustained, and the main question ordered to be put.</p>
              <div className="speaker" id="sp5512"><span className="persName">Mr. TURNER</span>, of Jackson—</div>
              <p className="p-in-sp">I call the yeas and nays upon that motion.</p>
              <p>The call was sustained, and the vote being taken, resulted-yeas 27, nays 65-as follows:</p>
              <p>YEAS-Messrs. Janney [President] , Baylor, Blakey, Boyd, Branch, Bruce, James H. Cox, Dorman, Gillespie, Gregory, Goggin, Addison Hall, E. B. Hall, Harvie, Isbell, Kilby, Lawson, Macfarland, Marye, Morris, Moore, Randolph, Richardson, Robert E. Scott, A. H. H. Stuart, Tyler, Wickham-27.</p>
              <p>
                NAYS-Messrs. Ambler, Aston, A. M. Barbour, James Barbour, Blow, Borst, Bouldin, Brent, Cabell, Campbell, Caperton, Carter, Chambliss, Chapman, Coffman, Conn, R. H. Cox, Custis, Deskins, Dulany, Early, Echols, Flournoy, Forbes, French, Garland, Grant, Gray, John Goode, T. F. Goode, L. S. Hall, Haymond, Holcombe, Holladay, Hunton, P. C. Johnston, Leake, McComas, Marr, Miller, Moffett,
                Montague, Morton, Nelson, Preston, Rives, Wm. C. Scott, Seawell, Sharp, Sheffey, Sitlington, Slaughter, Speed, Spurlock, Staples, Strange, Sutherlin, Tayloe, Thornton, R. H. Turner, Franklin P. Turner, White, Williams, Wilson, Woods-65.
              </p>
              <p>So the Convention refused to recommit</p>
              <div className="speaker" id="sp5513"><span className="persName">Mr. CAPERTON</span>—</div>
              <p className="p-in-sp">I move to amend the first section by inserting after the word "execution," in the first line, the words "of fieri facias other than in favor of the Commonwealth, and against non-residents, shall be issued from the date hereof," &amp;c.</p>
              <p>The amendment was agreed to.</p>
              <div className="speaker" id="sp5514"><span className="persName">Mr. CAPERTON</span>—</div>
              <p className="p-in-sp">I move to amend the 2nd section by prefixing before the word "executions" in the first line, the word "such."</p>
              <PageNumber num={716} />
              <p>Agreed to.</p>
              <div className="speaker" id="sp5515"><span className="persName">Mr. WICKHAM</span>—</div>
              <p className="p-in-sp">I move to amend the 6th section by adding to it, as amended, the words "nor to debts due the Commonwealth."</p>
              <p>The amendment was agreed to.</p>
              <div className="speaker" id="sp5516"><span className="persName">Mr. CAPERTON</span>—</div>
              <p className="p-in-sp">I desire further to amend the first section, in first line, by inserting after the words "fieri facias," in the amendment which I have just offered, the words "or venditioni exponas," so that that part of the section will read:</p>
              <p className="p-in-sp">"Be it ordained, That no execution of fieri facias or venditioni exponas, other than in favor of the Commonwealth and against nonresidents, shall be issued from the date hereof," &amp;c.</p>
              <p>The amendment was adopted.</p>
              <div className="speaker" id="sp5517"><span className="persName">Mr. MORRIS</span>—</div>
              <p className="p-in-sp">I propose to amend the fifth section by inserting after the word "by-standers," the words, "or vicinage," so that that part of the section will read:</p>
              <p className="p-in-sp">"In cases of misdemeanor, juries shall be summoned from the bystanders or vicinage," &amp;c.</p>
              <p>The amendment was adopted.</p>
              <div className="speaker" id="sp5518"><span className="persName">Mr. WOODS</span>—</div>
              <p className="p-in-sp">I move to amend the sixth section by adding, after the words "nor to debts due to the Commonwealth," which was the amendment just offered by the gentleman from Henrico [<span className="persName">Mr. WICKHAM</span>] , the following:</p>
              <p className="p-in-sp">"Provided, That no note, bill, acceptance or other obligation, the consideration of which is any debt or obligation, at present existing, shall be held or considered as a debt hereafter contracted."</p>
              <p>This amendment was agreed to.</p>
              <div className="speaker" id="sp5519"><span className="persName">Mr. JOHNSTON</span>, of Lee and Scott—</div>
              <p className="p-in-sp">I move to amend by offering the following as an independent section:</p>
              <p className="p-in-sp">"Nothing in this ordinance shall be held to change the attachments authorized by law or in equity, except for rent."</p>
              <p>The amendment was rejected.</p>
              <div className="speaker" id="sp5520"><span className="persName">Mr. MORRIS</span>—</div>
              <p className="p-in-sp">I propose to amend the fourth section by adding, after the word "jury," the words, "except where the parties litigant shall consent to the trial of any cause by the modes now provided by law."</p>
              <div className="speaker" id="sp5521"><span className="persName">Mr. CAPERTON</span>—</div>
              <p className="p-in-sp">The object of this provision was to dispense, as far as could be, with the attendance of juries and witnesses.</p>
              <p className="p-in-sp">
                Now, if this amendment prevails, it will conflict with that design; for the parties to suits may actually summon witnesses to have them in attendance upon the court; and although the case may not be tried by a jury, it will make it necessary that the witnesses should still be summoned, because while ever there was an uncertainty as to the
                <PageNumber num={717} /> parties consenting to a trial by jury, the attendance of the witnesses would be necessary.
              </p>
              <p>The amendment was rejected.</p>
              <p>This ordinance being perfected, the question recurred upon the substitute offered by <span className="persName">Mr. SEAWELL</span>, as given in the morning proceedings.</p>
              <div className="speaker" id="sp5522"><span className="persName">Mr. SEAWELL</span>—</div>
              <p className="p-in-sp">I will ask leave to amend my substitute by inserting after the word "three," in the 3rd line of the 3rd section, the word "disinterested," so as to make that part of the section read-</p>
              <p className="p-in-sp">"If the debtor shall offer no such bond or other security as aforesaid, it shall be the duty of the officer in whose hands such execution may be, to convene three disinterested freeholders," &amp;c.</p>
              <p>The amendment was agreed to.</p>
              <div className="speaker" id="sp5523"><span className="persName">Mr. SEAWELL</span>—</div>
              <p className="p-in-sp">I propose further to amend the sixth section by inserting after the words "officers," in the second line, the words "or debts due the Commonwealth," so that that part of the section will read-</p>
              <p className="p-in-sp">"This ordinance shall not apply to liabilities on the part of public officers, or debts due the Commonwealth."</p>
              <p>The amendment was adopted.</p>
              <p>The question now was upon <span className="persName">Mr. SEAWELL</span>'S substitute to that offered by <span className="persName">Mr. GOGGIN</span>.</p>
              <div className="speaker" id="sp5524"><span className="persName">Mr. GOGGIN</span>—</div>
              <p className="p-in-sp">I accept the substitute of the gentleman from Gloucester [<span className="persName">Mr. SEAWELL</span>] .</p>
              <div className="speaker" id="sp5525"><span className="persName">Mr. SEAWELL</span>—</div>
              <p className="p-in-sp">Then, sir, the vote comes up directly upon my substitute.</p>
              <p className="p-in-sp">I do not mean to detain this Convention for the purpose of urging any scheme of mine. I saw that the scheme of the committee was defective, and I think that the amendments they have added to it to-day shows that there was something in the idea I entertained of its defects.</p>
              <p className="p-in-sp">
                With regard to this matter of lien, it seems to me that all we have to do in passing an act of this sort is, to leave the parties exactly as we find them. If you deprive a man of any thing which the law allows him, we unavoidably inflict injustice. If a judgment has been rendered and an execution issued, the creditor has, in my opinion, acquired that right and that degree of security
                which we ought not to deprive him of. Hence, I provided in the substitute which I had endeavored to frame, that the execution being returned to the clerk's office, after the bond which is required shall be refused to the officer, that that priority of lien which it had shall be retained, and nothing <PageNumber num={718} /> more. I think it is very
                clear that you ought not to deprive the creditor of this lien which he had before. Although the debtor may be a poor man, and may be inconvenienced by having his property tied up, yet the creditor may be a poor man, too. It very often happens that the creditor is a poorer man than the debtor, and therefore he ought to lose nothing that the law gives him.
              </p>
              <p className="p-in-sp">There is another reason why this lien ought to be retained. In the absence of such a restraint, the debtor may take his property and sell it before the eyes of his creditor, and he has no remedy but to submit.</p>
              <p className="p-in-sp">This substitute provides against such an injustice as that, and secures to the creditor the lien which he now has. There certainly can be no objection to this upon any ground of law or equity.</p>
              <p className="p-in-sp">The fourth section provides that, "except in criminal causes on behalf of the Commonwealth, there shall be no judgment or decree for the payment of money rendered by any court of record or single justice," &amp;c.</p>
              <p className="p-in-sp">Now, that leaves the courts free to render judgment for specific property. It leaves them free to render judgment in any cause except for the payment of money. So that that meets one of the objections that the gentleman from Fauquier [<span className="persName">Mr. SCOTT</span>] , suggested.</p>
              <p className="p-in-sp">It is not worth while to say anything in regard to the fifth section. It says "in cases of misdemeanor, juries shall be summoned from the vicinage," &amp;c. That includes bystanders, and it gives authority to the party accused and the Commonwealth's attorney to have the cause submitted to the court without a jury.</p>
              <p className="p-in-sp">The sixth section provides that this ordinance shall not apply to liabilities on the part of public officers, or debts due to the Commonwealth, thus meeting another objection suggested by the gentleman from Fauquier [<span className="persName">Mr. SCOTT</span>]
                . It contains another important provision. There are a great many deeds of trust and large sums of money that are now in the hands of public officers and fiduciaries upon which families are dependent for their support; and I have provided that in all cases of that sort, the party may file his petition in a court of equity, and the court apply a remedy to the state of facts existing
                at the time. You cannot apply that remedy properly in a court of law, but in circumstances such as these, the Chancellor may or may not direct certain portions of the money and interest to be collected and applied. I think, moreover, it will be best for debtors to force them to pay the interest on their debts generally. The same section exempts from the operations of this ordinance
                all attachments allowed in the Code of Virginia, sections 1, 2, 3, 4, and 5, chapter 151, proceedings <PageNumber num={719} /> against absconding debtors, and all other attachments are excepted which I think very necessary. I deem it proper to say that this exception was embodied in the ordinance at the suggestion of the gentleman from Fauquier.
              </p>
              <p className="p-in-sp">
                The seventh section provides that "the time during which this ordinance may be in force, shall not be computed in any case where the statute of limitation shall come in question," and it is also provided that "this ordinance shall remain in force until repealed or changed by this Convention or the General Assembly of the State; and if not so repealed or changed, shall expire at the
                end of thirty days after the first day of the next General Assembly."
              </p>
              <p className="p-in-sp">The object of this is to force the next General Assembly to take action on this subject, and supply all defects which may be found to exist in this ordinance.</p>
              <div className="speaker" id="sp5526"><span className="persName">Mr. CHAMBLISS</span>—</div>
              <p className="p-in-sp">
                The vote is now to be taken upon the adoption of the substitute. As I stated in the beginning, the very foundation of all these amendments is to retain liens upon property, and also to require security. All this strikes at the root of what I understood this Convention intended to effect-a temporary relief until the Legislature, which will soon assemble, should change the law, and, if
                necessary, render it more perfect.
              </p>
              <p className="p-in-sp">
                There is another matter to which I would refer, and that is, whether you will allow debts to be collected from fiduciaries. You prevent fiduciaries from collecting money and yet you compel them to pay. That part of the section reads: "In all cases of money due and payable by fiduciaries, petition may be filed in chancery and payment enforced by decree of the court, in such mode as it
                may deem proper."
              </p>
              <p className="p-in-sp">That is precisely as the law is now.</p>
              <div className="speaker" id="sp5527"><span className="persName">Mr. SEAWELL</span>—</div>
              <p className="p-in-sp">That puts it in the power of a court of equity to refuse relief when it was proper not to grant it, and vice versa.</p>
              <div className="speaker" id="sp5528"><span className="persName">Mr. CHAMBLISS</span>—</div>
              <p className="p-in-sp">
                There are cases of hardship, no doubt, in this whole measure, and nothing but the necessities of war would induce me to vote for one section it contains. This is not intended as legislation for ordinary times. It is merely intended to protect those who are called on to fight our battles; and, for one, I am willing to adopt the most rigid rules for the purpose of sustaining those men.
                All men have to suffer hardships in times of war. Wives are made widows and children orphans at such times, and we should not complain about whatever pecuniary inconvenience may result from this law. If you want a stay law, the stay law of 1814 goes into these various details of legislation referred to. That stay law even was exceedingly defective,
                <PageNumber num={720} /> as all laws of that character must be. But I insist that this ordinance shall be adopted and the substitute voted down, if you intend to relieve these people who have offered their services to the State. I admit that the best men in the State, and the most affluent, are now called into action; but you may very soon be calling
                upon others who are less able to bear the sacrifice of property than the volunteers now in service.
              </p>
              <p className="p-in-sp">I hope the Convention will vote down this substitute.</p>
              <div className="speaker" id="sp5529"><span className="persName">Mr. WILSON</span>—</div>
              <p className="p-in-sp">I concur in the remarks of the gentleman from Greenesville [<span className="persName">Mr. CHAMBLISS</span>] .</p>
              <p className="p-in-sp">I have read the substitute with a great deal of care, and it seems to me to be very defective. The only effect of it will be to enable the sheriffs to shave the poor of the State. If you adopt that substitute, you may as well transfer the property of the poor laborers to the sheriffs at once. It affords no protection to the poor.</p>
              <p className="p-in-sp">I call the previous question.</p>
              <p>The call was sustained, and, the main question being put, which was the substitute offered by <span className="persName">Mr. SEAWELL</span>, it was decided in the negative-ayes 33, noes 52.</p>
              <p>The question then recurred upon the adoption of the original ordinance as amended.</p>
              <div className="speaker" id="sp5530"><span className="persName">Mr. MOFFETT</span>—</div>
              <p className="p-in-sp">I call the previous question.</p>
              <p>The call was sustained and the main question ordered to be put.</p>
              <p><span className="persName">Mr. WOODS</span>, by general consent, was permitted to offer the following amendment to the 4th section on behalf of the Committee: after the word "jury," insert the words "nor upon warrants for small claims before a justice."</p>
              <p>The amendment was agreed to. The ordinance as amended was then adopted.</p>
              <p>The ordinance as perfected reads as follows:</p>
              <div className="floatingText">
                <div className="floatingText_body">
                  <p>AN ORDINANCE to provide against the Sacrifice of Property and to Suspend Proceedings in Certain Cases.</p>
                  <p>
                    1. Be it ordained, That no execution of fieri facias or venditioni exponas, other than in favor of the Commonwealth and against nonresidents, shall be issued from the date hereof by any court of record or magistrate for the sale of property until otherwise provided by law. Nor shall there be any sales under deeds of trust or decrees, unless by the consent of parties interested,
                    until otherwise provided by law.
                  </p>
                  <p>
                    2. Where such executionsnhave issued and are now in the hands of officers, whether levied or not, if the debtor offer bond and security <PageNumber num={721} /> for the payment of the debt, interest, and costs, when the operation of this ordinance ceases, the property shall be restored and the bond so taken shall be returned as in case of a
                    forthcoming bond, and shall constitute a lien on the realty of the obligors to the same extent and in the same manner as forfeited forthcoming bonds returned to the clerk's office now do, and judgment may be had on said bond in the same manner and by the same proceedings as judgments may be obtained on forthcoming bonds under existing laws.
                  </p>
                  <p>
                    3. If the debtor offers no such bond, it shall be the duty of the officer to convene three freeholders from the vicinage, who, after being sworn shall proceed to value the property according to what would have been its value on the sixth day of November, eighteen hundred and sixty, and unless the said property shall sell for the full amount of such valuation, it shall be restored
                    to the debtor without lien.
                  </p>
                  <p>4. Except in criminal cases or Commonwealth's prosecutions there shall be no trial of any cause requiring the intervention of a jury, nor upon warrants for small claims before a justice.</p>
                  <p>5. In cases of misdemeanor, juries shall be summoned from the by-standers or vicinage, and not under the law, as it now stands; and with the consent of the party prosecuted the cause may be tried by the court.</p>
                  <p>
                    6. This ordinance shall not apply to liabilities upon the part of public officers, either to the State, counties, corporations, or individuals, nor to debts hereafter contracted, nor to debts due the Commonwealth: Provided, That no note, bill, acceptance, or other obligation, the consideration of which is any debt or obligation at present existing, shall be held or considered as
                    a debt hereafter contracted.
                  </p>
                  <p>7. The time during which this ordinance is in force shall not be computed in any case where the statute of limitation comes in question.</p>
                  <p>8. This ordinance shall remain in force until repealed or changed by this Convention or the General Assembly of the State; and if not so repealed or changed, shall expire at the end of thirty days after the first day of the next General Assembly.</p>
                </div>
              </div>
              <div className="speaker" id="sp5531"><span className="persName">Mr. JOHNSTON</span>, of Lee and Scott—</div>
              <p className="p-in-sp">I offer the following resolutions:</p>
              <p className="p-in-sp">
                1. Resolved, That a Commissioner to each of the States of Maryland, North Carolina, Tennessee, Kentucky, Delaware, Missouri and Arkansas be appointed by this Convention, to appear forthwith before their respective Conventions or Legislatures, as the one or the other may be in session, and invite the said States to withdraw from their
                <PageNumber num={722} /> union under the Constitution of the United States, and co-operate with the State of Virginia, in determined opposition to the flagrant usurpations of the Federal authorities; and in the event that neither the Convention nor Legislature of any of the said States shall be in session, the Commissioner to such State, shall
                communicate to the Governor thereof his mission, and its objects, and request him to take the necessary steps to lay the same before the proper representatives of his State.
              </p>
              <p className="p-in-sp">
                2. Resolved, That the said Commissioners also request the said Conventions, Legislatures, or Governors, as the case may be, to put themselves in communication with this Convention, or, in its recess, with the Governor of this State, as soon as may be practicable, in relation to the subject of the foregoing resolution, and that they also communicate to the said authorities of the said
                States, the ordinance of this State, adopted on the 17th day of April, 1861, the Convention with the Commissioner of the Confederate States, ratified on the 25th day of April, 1861, and the ordinance adopted on the last mentioned day, for the adoption of the Provisional Government of the Confederate States of America.
              </p>
              <div className="speaker" id="sp5532"><span className="persName">Mr. JOHNSTON</span>—</div>
              <p className="p-in-sp">
                I do not deem it necessary to inflict a speech upon this Convention, in advocacy of resolutions, having in view such an object as they propose to accomplish. It seems to me that every consideration of wisdom and sound policy is in favor of their adoption. I think the simple reading of the resolutions is enough to commend them to the favor of this body. Suffer me, however, to avert to
                a fact or two, which seems to me to make it necessary to adopt such a measure.
              </p>
              <p className="p-in-sp">
                Virginia has withdrawn from the Northern States, and has temporarily, at least-with the expectation, on all hands, that the step will be a permanent one-united herself with the Confederate States. But the position in which we stand is a peculiar one. We are surrounded by States on every side still belonging to the old Union. On the North and North-west we have Maryland and Ohio; on
                the South, Tennessee and North Carolina. On the West, Kentucky; cutting us off from all communication except by the comity of these States, with the Confederation with which we have associated ourselves. That fact presents a reason perfectly conclusive why we should appeal to the States lying around us to unite with us in a similar step. If there was no other circumstance to induce
                us to the movement which these resolutions propose, it seems to me that that would be perfectly conclusive.
              </p>
              <PageNumber num={723} />
              <p className="p-in-sp">
                The time at which it is proposed to make this move is peculiarly propitious. The Legislatures of Maryland, North Carolina, Tennessee and Kentucky are now in session, as we see from the public papers. The Convention of the State of Arkansas is ordered to be immediately convened. There remains, in fact, no Legislature of the neighboring States which is not now in session, or upon the
                point of being so, but those of Delaware and Missouri. I have no doubt that these Legislatures can very easily be brought together, and the necessary steps taken to put them in the way of following in our lead.
              </p>
              <p className="p-in-sp">I need not present in the shape of an argument the expediency of getting these States-if we can induce them to do it by proper means-to unite with us in whatever course seems to us proper to be taken in the present extraordinary condition of things. I look, moreover, upon the co-operation of these States as indispensably necessary to our future success.</p>
              <p className="p-in-sp">There is a great deal due to these States on the score of comity. They are lying around us, similarly situated with respect to ourselves, and animated by the same interests and feelings that we are. Surely, in view of these considerations, it would be a total departure from all comity and propriety to ignore them altogether.</p>
              <p className="p-in-sp">I did not intend to inflict upon the Convention a labored argument upon this question. I have no doubt that it is wholly unnecessary, inasmuch as they at once see the propriety of this course.</p>
              <div className="speaker" id="sp5533"><span className="persName">Mr. TURNER</span>, of Jackson—</div>
              <p className="p-in-sp">I desire to offer an amendment to the second resolution.</p>
              <p className="p-in-sp">I propose to insert after the words "with the Governor of this State," the words, "and with the authorities of the Confederate States."</p>
              <p className="p-in-sp">
                We have invited the Confederate States authorities to make this city or some other city or town in the State, the seat of Government, if they deem it proper and necessary for the public safety. If the result should take place, and the States referred to should deem it expedient to take such action as the first resolution refers to, it may be necessary for them forthwith to enter into
                communication with the Confederate States Government.
              </p>
              <p className="p-in-sp">Like the gentleman from Lee and Scott [<span className="persName">Mr. JOHNSTON</span>] , I am satisfied that this action ought to be taken; and should have been taken some days ago. I see by the action of the Legislature of Maryland that they are taking a course which I am satisfied they would not take if Commissioners from Virginia were present.</p>
              <p className="p-in-sp">I trust the Convention will adopt this amendment, and with it the resolutions.</p>
              <PageNumber num={724} />
              <div className="speaker" id="sp5534"><span className="persName">Mr. BORST</span>—</div>
              <p className="p-in-sp">I am of opinion that we could accomplish it equally as well by instructing the President of this Convention to communicate with the Governors or Legislatures of these States.</p>
              <div className="speaker" id="sp5535"><span className="persName">Mr. JOHNSTON</span>—</div>
              <p className="p-in-sp">I have no objection to the amendment suggested by the gentleman from Jackson [<span className="persName">Mr. TURNER</span>]
                . I propose to add to that amendment the words, "if they think proper," so that that part of the resolution "to put themselves in communication with this Convention, or in its recess, with the Governor and with the authorities of the Confederate States, if they think proper."<span className="note" id="Note70"
                ><span className="noteLabel">7</span>Some words like "will read" should follow "resolution," and "of this State" should follow "Governor."</span
                >
              </p>
              <div className="speaker" id="sp5536"><span className="persName">Mr. TURNER</span>—</div>
              <p className="p-in-sp">The only object I had in view in offering that amendment was, to show that this State desired them to take the same course that Virginia took.</p>
              <div className="speaker" id="sp5537"><span className="persName">Mr. CAMPBELL</span>, of Washington county—</div>
              <p className="p-in-sp">I hold in my hand a letter from Knoxville, Tennessee, written by a cousin of mine, which shows the spirit that animates the people of that section of the country. I am sure it is such a document as the Convention would take pleasure in having read, and I will therefore let them hear its contents.</p>
              <div className="floatingText">
                <div className="floatingText_body">
                  <p>KNOXVILLE, April 26, 1861.</p>
                  <p>Colonel A. C. Cummings:</p>
                  <p>I am now engaged in forming a regiment to aid in driving the abolition hordes from the borders of our old Mother State, and, if needs be, planting the standard of the Southern Confederacy on Tammany Hall.</p>
                  <p>
                    The war spirit is aroused in East Tennessee, and I do not think there will be much trouble in forming a regiment. Some eight or ten companies have already been offered. I see the fires are burning brightly in South-western Virginia. I hope soon to be on the soil of the Old Dominion. We have organized a staff, and are procuring all camp equipments and stores, so when we get upon
                    the field we will be ready for active duty. I have bought all the powder and lead in Knoxville-400 kegs of powder and 400 pounds of lead-and about 1,000 sacks of flour. We have bakers baking bread, and can get several thousand pounds of bacon. We will be prepared for twelve months service without any expense to Virginia. We will have our own paymaster, with Tennessee money to pay
                    all legal demands.
                  </p>
                  <p>D. H. CUMMINGS.</p>
                </div>
              </div>
              <div className="speaker" id="sp5538"><span className="persName">Mr. TURNER</span>—</div>
              <p className="p-in-sp">I withdraw my amendment.</p>
              <div className="speaker" id="sp5539"><span className="persName">Mr. HOLLADAY</span>—</div>
              <p className="p-in-sp">I call the previous question.</p>
              <PageNumber num={725} />
              <p>The call was sustained, and, the main question being put, resulted-ayes 39, noes 40.</p>
              <p>So the resolutions were rejected.</p>
              <div className="speaker" id="sp5540"><span className="persName">Mr. ECHOLS</span>—</div>
              <p className="p-in-sp">I beg leave to offer the following resolution:</p>
              <p className="p-in-sp">"Resolved, That the Auditor of Public Accounts is hereby instructed to issue a warrant to Messrs. Starke &amp; Cardozo, for the sum of twelve hundred and fifty dollars and eighty cents, in payment of their account for stationery, supplied by them for the use of the Convention."</p>
              <div className="speaker" id="sp5541"><span className="persName">Mr. TURNER</span>—</div>
              <p className="p-in-sp">I move to refer the subject to the Committee of Finance.</p>
              <div className="speaker" id="sp5542"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">I hope it will not be referred to the Committee of Finance. The bill is certified by an official of this body, and that would be sufficient evidence to justify any jury in rendering a verdict upon it. I trust the Convention will order payment of the amount at once.</p>
              <p>The motion to refer was agreed to.</p>
              <div className="speaker" id="sp5543"><span className="persName">Mr. CAPERTON</span>—</div>
              <p className="p-in-sp">I beg leave to offer the following resolution:</p>
              <p className="p-in-sp">
                "Resolved, That the Secretary of the Convention have the ordinance passed this day, providing against the sacrifice of property, published in the papers of this city, and that the Secretary of the Commonwealth send five copies to the clerk of each county and corporation in the State-one copy to be furnished by him to the clerk of the Circuit Court, and one to the Sheriff of his
                county or corporation."
              </p>
              <p>The resolution was adopted.</p>
              <div className="speaker" id="sp5544"><span className="persName">Mr. KILBY</span>—</div>
              <p className="p-in-sp">I now move to take up the ordinance authorizing the issue of treasury notes, reported this morning from the Finance Committee.</p>
              <p>The motion was agreed to.</p>
              <p>The ordinance reads as follows:</p>
              <div className="floatingText">
                <div className="floatingText_body">
                  <p>AN ORDINANCE to authorize the issue of Treasury Notes.</p>
                  <p>
                    1. Be it ordained, That the Governor, for the purpose of raising money for the defence of the State, is hereby authorized to direct the Auditor of Public Accounts to borrow for the Commonwealth of Virginia, from time to time, an amount not exceeding in the aggregate two millions of dollars; and for that purpose the said Auditor, on the order of the Governor, shall issue treasury
                    notes for the amount so directed to be borrowed, in sums not less than twenty dollars.
                  </p>
                  <p>
                    2. The said treasury notes shall be prepared under the direction of the Governor, and shall be signed by the Treasurer and countersigned by the Auditor of Public Accounts, but shall not be delivered by the <PageNumber num={726} /> said Auditor, except upon the receipt of the Treasurer that the par value thereof has been paid in to the Treasury. The
                    said notes shall be made payable to bearer, and be made payable at the Treasury of the State one year after their respective dates. They shall bear interest at a rate not exceeding six per centum per annum, from the date of their issue until redeemable. For the payment of the interest and redemption of the principal, as set out in said notes, the faith of the Commonwealth of
                    Virginia is hereby pledged.
                  </p>
                  <p>
                    3. The Auditor of Public Accounts is hereby directed to cause to be redeemed all treasury notes, principal and interest, at the time when the same are redeemable and presented for payment, to be paid out of any money in the treasury not otherwise appropriated. And if the said notes be not presented within twelve months after the same are redeemable, the said Auditor, if there be
                    funds in the treasury sufficient to pay the said notes, shall advertise for the same to be brought in on a given day, and after such day the interest thereon shall cease.
                  </p>
                  <p>4. Whenever any of said notes shall be redeemed by the Auditor, as herein provided, the same shall be cancelled by him and be delivered to the Treasurer, to be preserved in his office; and from time to time an amount equal to the sum so cancelled may, by order of the Governor, be again issued, subject to all the provisions herein prescribed.</p>
                  <p>5. The Auditor of Public Accounts and the Treasurer shall each keep a full and accurate account of the number, date, denomination, and amount of all the notes signed by them, respectively, and in like manner of all the said notes redeemed and cancelled.</p>
                  <p>
                    6. For defraying the expenses of preparing, engraving and printing the said treasury notes, a sum not exceeding two thousand dollars is hereby appropriated, to be paid by order of the Governor, out of any money in the treasury not otherwise appropriated; and the plate or plates shall be deposited for safe keeping in one of the banks of this city, until otherwise provided by law.
                  </p>
                  <p>7. All the provisions of the third, fourth and sixth sections of chapter 193 of the Code, applicable to bank notes shall be held to apply and relate, in their effect, to the treasury notes directed to be issued by this ordinance.</p>
                  <p>8. It shall be lawful for the banks of this Commonwealth to discount or purchase any note or notes issued under this ordinance, to receive the same on deposit and pay them out at their counter.</p>
                  <p>9. The treasury notes to be issued under the provisions of this</p>
                  <PageNumber num={727} />
                  <p>ordinance shall be received in payment of all taxes or other dues to the Commonwealth. Authority is hereby reserved to the General Assembly to arrest and suspend the issue of treasury notes herein provided for.</p>
                </div>
              </div>
              <div className="speaker" id="sp5545"><span className="persName">Mr. BLAKEY</span>—</div>
              <p className="p-in-sp">I beg leave to offer the following as a substitute for that ordinance. It embodies the provisions of that ordinance making alterations only upon these points in reference to which I submitted some remarks this morning.</p>
              <div className="stage it">[<span className="persName">Mr. BLAKEY</span> here offered a substitute which the reporter was unable to procure.]</div>
              <div className="speaker" id="sp5546"><span className="persName">Mr. BLAKEY</span>—</div>
              <p className="p-in-sp">I call the previous question.</p>
              <div className="speaker" id="sp5547"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">It is not in order to submit two motions at once.</p>
              <div className="speaker" id="sp5548"><span className="persName">Mr. MACFARLAND</span></div>
              <p className="p-in-sp">-I have but little to say, sir, but I desire to call the attention of the Convention to the ordinance as reported by the committee.</p>
              <p className="p-in-sp">This morning there was some conversation upon the mode of transacting business at the Treasury Department. I beg to call the attention of gentlemen especially to that. I shall be a little more minute and specific than I was this morning.</p>
              <p className="p-in-sp">
                I beg that every gentleman will take notice that any creditor of the Commonwealth, who shall hereafter call at the Treasury, will be entitled to a check for the amount of his claim upon one of the banks in which the account of the Commonwealth is kept. That will always be done if there be money to the credit of the Commonwealth. And it could scarcely happen that any creditor to the
                Commonwealth desiring to receive the amount of his claim in treasury notes, would not find the Commonwealth entitled to check for the amount of his claim. If he desired to obtain Treasury Notes rather than bank notes, it would not be in the power of the Treasury Department to supply him. There would be no occasion to dispose of the Treasury notes; and I hope it is no part of the
                policy of any gentleman here to require the Treasury notes to be disposed of where there is no occasion to obtain money upon them. If the creditor of the Commonwealth should be anxious to procure Treasury notes, all that it would be necessary for him to do would be to deposit in the bank, to the credit of the Treasury, an amount equal to the amount of his claim, and then get the
                Treasurer's check upon the bank, and come back and demand Treasury notes. Well, now, the simple question with the Convention is whether it will reverse a system established more than twenty years ago, the whole purpose and object of which was to protect the <PageNumber num={728} /> Treasury against spoliation. The change began some years ago-I do not
                remember the exact time-but it was found by experience that the system of requiring payment to be made to the credit of the Treasury in bank, and confining the disbursements of the Treasury to checks upon the bank, was a great security against peculation and fraud.
              </p>
              <p className="p-in-sp">
                I presume there is no gentleman upon this floor who is now prepared to reverse that system. If it be reversed, it must be remembered, sir, that you give up the security which the Commonwealth now holds under a bond which the treasurer executes upon coming into office. For I presume, sir, that there is not one gentleman upon this floor who is not prepared to assent to the proposition
                that if you extend the control of a public functionary over the public money beyond what he engaged at the time he gave his bond and security, that you ought at least, in equity, discharge his securities. This ordinance, sir, as reported by the committee, is in exact conformity to the last act of the General Assembly. Indeed, I think I might go further, and say that it conforms, in
                all particulars, with all the laws passed by the General Assembly heretofore with a view to providing money for general or specific objects.
              </p>
              <p className="p-in-sp">Why, sir, when a provision is made for the completion or the undertaking of public works, as in this instance, a limit is placed upon the price at which the bonds of the State may be disposed of.</p>
              <p className="p-in-sp">It has been habitual to require that, before the security or bond is parted with, a warrant shall be obtained at the Treasury Department in the ordinary and established course-and that is, by the payment of the amount to the credit of the Treasury, in one of the deposit banks of this city.</p>
              <p className="p-in-sp">I here repeat, sir, that it is impossible to depart from the usage in this particular, except at a risk to the security of the public Treasury.</p>
              <p className="p-in-sp">
                I have said upon this subject all that I deem it necessary to say, except this: that when the Committee was employed in drawing up the ordinance in question, they had before them the last act of the General Assembly upon this specific subject of raising money by Treasury notes. I will say that if the ordinance is passed in the form reported by the committee, I can answer for it, that
                there will be no difficulty in obtaining the amount called for.
              </p>
              <p className="p-in-sp">I have the satisfaction to say that the banks of this city, representing a capital equal to sixteen millions of dollars, have agreed to advance that amount.</p>
              <p className="p-in-sp">
                The banks of this city have agreed cheerfully to contribute in any <PageNumber num={729} /> ratio to which the banks of the State at large will submit, and I am satisfied that the banks have such confidence in the security of the ordinance reported by the committee, that they would not hesitate to advance for banks at a distance, which might decline to
                advance upon this security.
              </p>
              <p className="p-in-sp">
                Now, sir, in all other particulars in which the amendment departs from the ordinance, I would submit that the advantage is decidedly in favor of the report of the committee. Indeed, it does not occur to me now that it needs any alterations in respect to any single point suggested in the amendment. I do not see that, in the substitute, any additional security for the State is
                suggested or intimated. I am not aware that the committee, in reporting the ordinance which I am now advocating, has omitted, in any single particular, to take all the precautions, all the means necessary to guard against loss either by negligence or from impropriety of purpose on the part of the public officers. I am confident of this thing, that if it be the purpose of this
                Convention to get the treasury notes into general circulation, there is no mode better calculated to accomplish that result than the ordinance which has been reported by the committee. Their purpose was to give confidence in this circulation, to attract the public attention to it; and the best mode of accomplishing that end is, not to display any particular solicitude with reference
                to it, as, indeed, there is no occasion for it, since, in the issues contemplated by the ordinance of the committee, payment for them is at once called for.
              </p>
              <p className="p-in-sp">
                I know, sir, that the committee which reported this ordinance gave it their particular attention, and I can add further that the committee had before them the Auditor of Public Accounts, with a view to obtain from him whatever information they might deem necessary; and whilst they did not submit implicitly to the judgment of the Auditor, they examined him carefully with respect to
                all the particulars which bear in the slightest degree upon the public questions involved.
              </p>
              <p className="p-in-sp">I recommend humbly, and without any confidence in my own opinion, to the adoption of the convention the ordinance reported by the committee.</p>
              <div className="speaker" id="sp5549"><span className="persName">Mr. BLAKEY</span>—</div>
              <p className="p-in-sp">
                I cannot for the life of me see how it is that the substitute will in any degree increase the liabilities of the Treasurer, so as to render it just to him to release his securities. According to the report of the committee, the Treasury notes are to be issued to people who lend the money to the Commonwealth; and I cannot see how the plan proposed by the substitute will increase the
                liabilities of the Treasurer more than does the ordinance as reported.
              </p>
              <PageNumber num={730} />
              <p className="p-in-sp">
                The difference between the report and the substitute offered by me is this: that the Treasury note in the case of the report is to be issued to the party who lends the Commonwealth money and brings that much more money under the control of the Treasurer to be accounted for. According to the substitute, the Treasury note shall be issued to the party who lends to the Commonwealth or to
                a creditor of the Commonwealth. If it is issued to the creditor of the Commonwealth, there is just that amount of money less handled in the transactions, and, as a consequence, the liability of the officer is diminished in that degree. In fact, it diminishes both labor and responsibility, and simplifies the transactions in the Treasury department.
              </p>
              <p className="p-in-sp">
                There is a greater difference still between the two schemes. According to the scheme of the Committee, these notes cannot be issued until somebody will lend the money to the Commonwealth. Now, sir, as I stated this morning, a creditor of the Commonwealth who has used his means in supplying the Commonwealth with these things which were indispensably necessary for her in carrying on
                this war, comes here to be paid, and he finds no money in the Treasury, how is he to get it? Is there anybody that will lend the money? There may be, or there may not be. The like of that has occurred before, in times of peace. It is known to every member of this Convention that the creditors of the Commonwealth could not get the money that was due to them, because the State could
                not sell her bonds; and a member of the Convention stated upon this floor a few days since that the practice of railroad contractors and other contractors had been, to go to a broker and borrow, say $1,000, at a very deep shave, and then with that to buy State bonds, which he would go and sell at a heavy sacrifice to get money to buy more bonds with, and finally he manages to put the
                money in the Treasury with which to pay himself. If that has been done in times of peace, will not the same thing be done in times of war?
              </p>
              <p className="p-in-sp">
                And why this roundabout process? If the creditor is willing to receive treasury notes in payment of his debt, why shall he not be permitted to receive them without first borrowing the money and having it deposited in bank in order to get hold of these treasury notes? Why may he not as well receive them, and go home at once perfectly satisfied with his treasury notes, as be subjected
                to this superfluous ordeal?
              </p>
              <p className="p-in-sp">The Chairman of the Finance Committee [<span className="persName">Mr. MACFARLAND</span>]
                this morning admitted that a Treasury note would be better currency than <PageNumber num={731} /> a bank note. Now, will you deprive the creditors of the Commonwealth of this better currency and put it in the hands of money-lenders, that they may be paid with an inferior currency? It seems to me it would be unworthy of the character of Virginia. As to
                the guards that are thrown around these Treasury notes, I do not see that there are any more guards thrown around them when advanced to the money-lender than when they are distributed to the creditors of the Commonwealth. It seems to me that there are as many guards around them in one case as in the other; and the only difference between the two schemes is, that the scheme, according
                to the reports, puts the credit of the Commonwealth under the control of money-lenders, and must frequently place the creditor in a condition in which he cannot get his money without submitting to a heavy sacrifice, while the substitute provides for the payment of the creditor at once, and without subjecting him to this process.
              </p>
              <p className="p-in-sp">
                There is another provision in the substitute which commends itself to my mind, and that is, that when an officer of the Commonwealth shall receive these Treasury notes in payment of dues, he shall endorse on it the date when he receives it, and from that time the payment of interest shall cease; and it makes it the duty of the Treasurer, when it is brought in in the payment of dues
                to the Commonwealth to administer an oath to that officer to the effect that the date has been correctly entered.
              </p>
              <p className="p-in-sp">
                There is another provision that recommends it. The report of the committee limits the issues to $20 notes. Now, this Convention has said that it is for the good of the country that banks shall issue one and two dollar notes. I cannot see that the country will be damaged by the issue of $5 Treasury notes and $10 Treasury notes in behalf of the Commonwealth. If this scheme has been
                resorted to for the purpose of throwing these Treasury notes into circulation as money, to pass from hand to hand as bank notes, I cannot see any reason why Treasury notes shall not go out of the denomination of $5; none whatever.
              </p>
              <p className="p-in-sp">With these views, sir, I submit the decision of the question to the Convention.</p>
              <p className="p-in-sp">I now call the previous question.</p>
              <p>The call was sustained, and the main question being put, which was upon the adoption of the substitute, it was rejected.</p>
              <p>The question then recurred upon the adoption of the ordinance as reported by the committee, and it was adopted.</p>
              <PageNumber num={732} />
              <div className="speaker" id="sp5550"><span className="persName">Mr. SCOTT</span>, of Powhatan—</div>
              <p className="p-in-sp">I am instructed by the Military Committee to report the following ordinance:</p>
              <div className="floatingText">
                <div className="floatingText_body">
                  <p>AN ORDINANCE regulating the term of Service, Pay, and Manner of Enlistment of Volunteers.</p>
                  <p>
                    Be it ordained, That the term of service of volunteers, called into service under the ordinance passed on the 17th of April, 1861, shall be twelve months from the time they were mustered into service, unless they be sooner discharged, except where their term of enlistment, under existing laws, expires before the end of the said period, in which case they shall be discharged on
                    the expiration of such enlistment.
                  </p>
                  <p>2. Enlistment for the volunteer corps after they are mustered into service shall be made in conformity with such regulations as the Commander-in-Chief shall prescribe.</p>
                  <p>3. The pay of the volunteers shall commence from the time they were organized at any rendezvous under proper authority.</p>
                </div>
              </div>
              <p>This ordinance was adopted.</p>
              <div className="speaker" id="sp5551"><span className="persName">Mr. SCOTT</span>—</div>
              <p className="p-in-sp">I beg leave to submit from the same committee "An ordinance providing chaplains for the volunteers."</p>
              <div className="floatingText">
                <div className="floatingText_body">
                  <p>Be it ordained, That the Governor shall appoint one chaplain for each regiment of volunteers, who shall be entitled to the same pay and emoluments as captains of cavalry.</p>
                  <p>This ordinance shall be in force from the time of its adoption, and be subject to amendment, modification, or repeal by this Convention or by the General Assembly.</p>
                </div>
              </div>
              <p>This ordinance was also adopted.</p>
              <div className="speaker" id="sp5552"><span className="persName">Mr. TYLER</span>—</div>
              <p className="p-in-sp">I move to take up the "ordinance prescribing the pay of the Provisional army and of the volunteer force of the State of Virginia."</p>
              <p>The motion was agreed to.</p>
              <p>The ordinance reads</p>
              <div className="floatingText">
                <div className="floatingText_body">
                  <p>
                    Be it ordained, That the Commander-in-Chief of the army and navy of the State of Virginia shall be entitled to receive four thousand dollars per annum, which shall be his full and entire compensation, without any right to demand any other compensation or perquisite whatever. His aids shall each be entitled to twenty-four dollars monthly in addition to their pay in the line, ten
                    dollars monthly for forage, and four rations per day. The said Commander-in-Chief and his aids shall receive their pay and allowances monthly, or at such other times as they may choose.
                  </p>
                  <p>2. The pay, emoluments and subsistence of the Provisional Army <PageNumber num={733} /> and the volunteers in actual service shall be the same with those of the army of the Confederate States.</p>
                  <p>3. This ordinance shall be in force from its adoption, and be subject to amendment, modification or repeal by this Convention or by the General Assembly.</p>
                </div>
              </div>
              <div className="speaker" id="sp5553"><span className="persName">Mr. TYLER</span>—</div>
              <p className="p-in-sp">I offer the following as a substitute :</p>
              <div className="floatingText">
                <div className="floatingText_body">
                  <p>Be it ordained, That the Major General and commander of the military and naval forces of the State of Virginia shall be entitled to receive the same pay, allowances and emoluments which are given to a major general in the army of the United States.</p>
                  <p>2. That the major generals holding rank under the Commander-inChief shall be major generals by brevet, with the pay and emoluments which appertain to major generals when in actual command.</p>
                  <p>3. The pay, emoluments and subsistence of the Provisional Army and of the volunteers and militia in actual service, shall be the same with those of the army of the Confederate States.</p>
                  <p>4. This ordinance shall be in force from its adoption, and be subject to amendment, modification or repeal by this Convention or by the General Assembly.</p>
                </div>
              </div>
              <div className="speaker" id="sp5554"><span className="persName">Mr. TYLER</span>—</div>
              <p className="p-in-sp">I desire to say that I could, in no other mode than that suggested in the substitute, reduce the army of Virginia to the condition of the army of the United States, namely by making the Major Generals under the Commander-in-chief, Major Generals by brevet, with all the emoluments which appertain to Major Generals when they are in actual command.</p>
              <p className="p-in-sp">This morning the subject turned upon the pay of the Commander-in-chief of our army. I have been at some pains to ascertain the extent of that pay. It is much larger than was contemplated even by myself.</p>
              <p className="p-in-sp">
                He has $220 a month. He has fifteen rations per day; he has seven horses in time of war; he has three horses in time of peace, and he is allowed four servants in time of war. His pay at $220 a month would be $2,640 a year. His rations at $270 a month would be $3,240 a year. His horses at $56 a month would be $672. His servants at $90 a month would be $1,080; thus making an aggregate
                of $7,632 a year. In addition to this, a Major General is entitled to commutation pay for quarters, fuel, stationery, &amp;c., the amount of which will, of necessity, vary according to circumstances. So that, including his commutation pay for quarters, fuel, stationery, &amp;c., you will have more in the aggregate than his pay would amount to.
              </p>
              <p className="p-in-sp">
                In regard to the pay of Brigadier Generals, the pay of a Brigadier <PageNumber num={734} /> General is $124 a month $1,488 a year. His rations amount to $108 a month, making $1,296 a year. His horses $40, making $480. His servants $67.50 a month, making $810-the whole per annum amounting to $4,074. If he was commanding an army his rations would be
                doubled, and $1,296 would necessarily have to be added. The whole aggregate to which a Brigadier General in command of army would be entitled, would be $5,370 per annum. A Brigadier General is also entitled to commutation for quarters, fuel, stationery, &amp;c., which will bring his pay up to a considerable amount.
              </p>
              <p className="p-in-sp">
                This is information which I bring with me in connection with this substitute. I think you may rely upon it with the most perfect confidence, that the pay of a Major General with his commutation pay falls but little short of $8,000 a year, and the pay of a Brigadier General with his commutation pay falls little short of $6,000 a year. I think we ought to give to this officer precisely
                what he would get had he occupied the same position in the United States service.
              </p>
              <p className="p-in-sp">
                We cannot expect that these Generals are to be confined to the operations of the State of Virginia. My hope and trust is that the Major General of the army of Virginia will very soon be appointed to the command of the army of the Confederate States under the President. He would thus be carried to remote parts, and not confined to the soil of Virginia, operating as I trust, sooner or
                later at Baltimore, possibly at Cairo, and at every strategic point. He will have as his opponent the Commander-in-Chief of the army of the United States, and in my deliberate opinion, he is fully competent to enter the contest against him, and bear himself successfully through it. If there was nothing else in his favor to control the destiny of this contest, it would be found of
                necessity in the very laws of nature-in his superiority in point of age. He is possessed of all the activity, all the vigor, and all the intellect and information which we would desire to see concentrated in the Commander-in-chief of our army.
              </p>
              <p className="p-in-sp">There is, as you know, no Major General in the service of the Confederate States. He is the highest officer in commission, and I cannot but think that the Confederate Congress, not only upon the score of merit, but also upon the score of seniority, will see proper to confer the whole command upon Major General Lee.</p>
              <p className="p-in-sp">I trust, therefore, there will be no abatement of his pay.</p>
              <p className="p-in-sp">
                There is another item which I know will swell it, and that is the item of transportation of baggage, &amp;c. The charges in the United States service for these purposes were very high. But that is alien to this question. There is no man who entertains a higher opinion of <PageNumber num={735} /> Gen. Johnston and Gen. Gwynn than myself. It is because I
                desire to have our military placed on a right footing, that I nominated Brigadier Generals in command, to Brevet Major Generals when in separate command. These two officers, gallant and distinguished as they have been in all their past lives, eminent for their military knowledge, scarcely inferior to the Commander-in-Chief himself, will often be found to be in the position to receive
                the pay of a full Major General.
              </p>
              <p className="p-in-sp">I trust that the ordinance as amended will commend itself to the Convention.</p>
              <div className="speaker" id="sp5555"><span className="persName">Mr. JOHNSTON</span>, of Lee and Scott—</div>
              <p className="p-in-sp">I desire to make an inquiry.</p>
              <p className="p-in-sp">I desire to know whether I understand this whole substitute correctly. It proposes, as I understand, to regard the Major Generals who have been appointed, as holding that rank by brevet. It goes on to provide, that when in separate command, they shall receive the pay of that rank. What pay ought they receive when not in that command?</p>
              <div className="speaker" id="sp5556"><span className="persName">Mr. TYLER</span>—</div>
              <p className="p-in-sp">The pay of a Brigadier General.</p>
              <div className="speaker" id="sp5557"><span className="persName">Mr. JOHNSTON</span>—</div>
              <p className="p-in-sp">
                I do not perceive that the difficulty is removed yet. It provides for their receiving the pay of Major General when in separate command. I do not understand it as conferring upon them the pay of a Brigadier General when not in command. The only effect of that provision is simply to declare that they shall be regarded as Major Generals by brevet, and when in separate command that they
                shall receive the pay of that rank.
              </p>
              <div className="speaker" id="sp5558"><span className="persName">Mr. TYLER</span>—</div>
              <p className="p-in-sp">You can introduce the words, "shall be regarded as Brigadier Generals of the line." This will place them on the same footing with officers of that grade in the United States service.</p>
              <div className="speaker" id="sp5559"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">
                I have not risen to express any opinion as to the amount of pay suggested by the venerable gentleman who has just taken his seat. I am willing to pay General Lee any amount that this Convention believes to be necessary. I understand that the substitute offered by the gentleman from Charles City, not only fixes the amount to be paid to Major General Lee, but to the Major and Brigadier
                Generals that have been nominated by this Convention. There have been no other appointments confirmed and none made in the sense in which I view these appointments.
              </p>
              <p className="p-in-sp">
                Now, sir, I am going to undertake what could be no more disagreeable to any man in this Convention than it would be to me. I believe that there have been more field officers nominated to this Convention than are necessary for the conduct of the army of the State; and if we are to pay at the rate of $7,000 a year to Major Generals,
                <PageNumber num={736} /> of whom we have three, it must be a useless and unnecessary expenditure of money, particularly when we understand that there are no forces for these Generals to command. For my part, I will vote cheerfully for a liberal pay to such as may be appointed; but I hope that this House will closely scrutinize the appointments that have
                been made not only in reference to their number, but their qualifications, when they come up for confirmation. My opinion is, that the number now appointed is vastly disproportioned to our present needs. While these gentlemen appointed are as noble and patriotic as any others, I believe, they are not sufficiently skilled in military matters to act with efficiency in the important
                posts to which they have been assigned. I therefore hope that whenever these nominations come up for confirmation, we shall vote upon each one of them separately, and that no false delicacy, no questions in regard to the relations that members occupy to the nominees, that no false delicacy in regard to the Governor of the State of Virginia, will prevent us from cutting off the
                excrescence wherever it may be found.
              </p>
              <p className="p-in-sp">I make these remarks, merely with a view to call the attention of the Convention to what I regard as an imperative duty on their part, and I will now await with anxiety the result of their action in the matters referred to.</p>
              <div className="speaker" id="sp5560"><span className="persName">Mr. RANDOLPH</span>—</div>
              <p className="p-in-sp">
                I think we are getting ourselves into a very embarrassing situation with regard to the number and grade of our generals. If these nominees are confirmed, we are to have three Major Generals. There are no Major Generals in the army of the Confederate States; and when we come to turn our army over to them, our general officers must be reduced, or there will be a very disagreeable
                controversy between the Confederate States and ourselves with regard to the grade of these officers. My own opinion is, that there ought to be no Major Generals, except the Commander-inChief. I see no reason why the number of general officers and field officers for the volunteers should exceed in grade or number the officers of the provisional army. I have drawn up an ordinance,
                which, if adopted, will settle that question, by limiting the appointments to the volunteer army to the same grade and number as those that may be made to the provisional army.
              </p>
              <p className="p-in-sp">It reads as follows:</p>
              <p className="p-in-sp">"Be it ordained, That the general and field officers of volunteers shall not exceed in grade the number of general and field officers of the provisional army."</p>
              <PageNumber num={737} />
              <p className="p-in-sp">This will save the disagreeable necessity of voting against the confirmation of these officers, and I hope it will be adopted.</p>
              <div className="speaker" id="sp5561"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">May I ask the gentleman a question? I wish to know if there is any Major General in the provisional army at all?</p>
              <div className="speaker" id="sp5562"><span className="persName">Mr. RANDOLPH</span>—</div>
              <p className="p-in-sp">None except the Commander-in-Chief.</p>
              <div className="speaker" id="sp5563"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">I understand the gentleman, then, as not proposing to appoint any Major General.</p>
              <div className="speaker" id="sp5564"><span className="persName">Mr. RANDOLPH</span>—</div>
              <p className="p-in-sp">None, except General Lee, who is now in command.</p>
              <div className="speaker" id="sp5565"><span className="persName">Mr. TYLER</span>—</div>
              <p className="p-in-sp">
                I acted under the impression that you had already confirmed the nominations of these two other Major Generals-Generals Gwynn and Johnston. I thought I was taking a very bold step in reducing them to the rank of Brigadier Generals. I think it would be better to await the action of the Convention on the nominations that are made, before any determination is arrived at in regard to the
                pay of these officers.
              </p>
              <p className="p-in-sp">With this view, I shall move to strike out the second section of the ordinance reported by the Committee.</p>
              <div className="speaker" id="sp5566"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair will suggest to the gentleman that the proper course would be for him to offer his ordinance as a substitute for the 2d section.</p>
              <div className="speaker" id="sp5567"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">I would suggest to the gentleman from Charles City [<span className="persName">Mr. TYLER</span>] , and also to the gentleman from the City of Richmond [<span className="persName">Mr. RANDOLPH</span>] , that we pass by this subject, and take up the nominations that have already been made.</p>
              <div className="speaker" id="sp5568"><span className="persName">Mr. RANDOLPH</span>—</div>
              <p className="p-in-sp">I hope my friend will not force us to vote on these nominations to-night.</p>
              <div className="speaker" id="sp5569"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">I have been here to-day very nearly ten hours, and am very nearly exhausted. My health is very feeble; and, for my part, I do not feel able to continue here any longer. If a quorum of the Convention cannot stay here to-morrow or next day, the fault, or the shame, or the dishonor will be theirs.</p>
              <p className="p-in-sp">I move we adjourn.</p>
              <p>The motion was agreed to, and the Convention adjourned accordingly.</p>
            </div>
          </div>
          <div className="day" id="vsc1965.v4.2.15">
            <PageNumber num={738} />
            <h2><span className="headingNumber">1.15. </span><span className="head">FIFTEENTH DAY</span></h2>
            <div className="dateline">Wednesday, <span className="date">May 1</span></div>
            <p>The Convention assembled at 10 o'clock. Prayer by the Rev. Mr. Jeter, of the Baptist Church.</p>
            <p>The Secretary proceeded to call over the list of committees, when</p>
            <div className="speaker" id="sp5570"><span className="persName">Mr. MACFARLAND</span>, from the Finance Committee, submitted a report upon the account of Messrs. Starke &amp; Cardozo for stationery, referred to them on yesterday. He said—</div>
            <p className="p-in-sp">I will remark that the account seems to be large; but so far as the committee could ascertain, it corresponds with the expenditures usually incurred by the Legislature for similar supplies. It is about $8.30 a head for the time the Convention is in session.</p>
            <p className="p-in-sp">The committee have directed me to report the following resolution:</p>
            <p className="p-in-sp">Resolved, That the Auditor of Public Accounts is hereby directed to issue his warrant to Messrs. Starke &amp; Cardozo in payment of their account for stationery furnished to the Convention.</p>
            <p>The resolution was adopted.</p>
            <div className="speaker" id="sp5571"><span className="persName">Mr. MACFARLAND</span>—</div>
            <p className="p-in-sp">There was referred to the committee a resolution directing it to fix the compensation of the members of the Advisory Council. The committee have directed me to report the following ordinance.</p>
            <p className="p-in-sp">I would remark for the information of gentlemen, that the compensation allowed to the members of the Advisory Council corresponds with the allowance made to the judges of the Commonwealth when they are serving in special courts.</p>
            <p className="p-in-sp">The following is the ordinance which the committee has instructed me to report:</p>
            <p className="p-in-sp">Be it ordained, That the members of the Advisory Council shall each receive $10 per day during their continuance in office.</p>
            <div className="speaker" id="sp5572"><span className="persName">Mr. STAPLES</span>, of Patrick—</div>
            <p className="p-in-sp">I desire to know whether that is for the whole year or during actual service?</p>
            <div className="speaker" id="sp5573"><span className="persName">Mr. MACFARLAND</span>—</div>
            <p className="p-in-sp">It is impossible to say how long the Advisory Council may be continued. The Committee, in the report which I have had the honor to submit, fixed their compensation during their continuance in office at $10 per day each.</p>
            <div className="speaker" id="sp5574"><span className="persName">Mr. AMBLER</span>—</div>
            <p className="p-in-sp">For one, I must enter my protest against this principle inaugurated here, of giving extravagant salaries to officers.</p>
            <p className="p-in-sp">
              Now, sir, for these officers who have abandoned lucrative positions in the Government of the old United States, I am willing to do everything <PageNumber num={739} /> in our power which circumstances may demand; but for those resident in the State, the old Revolutionary rule ought to be adopted, of paying what will merely meet their expenses. This is all
              that ought to be expended, and all that ought to be granted.
            </p>
            <p className="p-in-sp">I move that the ordinance be amended so as to reduce the allowance to $4 a day. We know from our own experience here that we can maintain ourselves upon that allowance. I move that $4 a day be allowed for the actual time of service.</p>
            <p className="p-in-sp">
              I will state, that all these members of the Council, are actually now drawing pay for other offices which they hold. Captain Maury will draw his pay as Captain. We have guaranteed to him that he will lose nothing; and in allowing him $4 a day for this service in addition to his pay, he will receive more than he expected. Every other member of the Council is receiving pay for other
              offices-one, that of Superintendent of the Military Institute; the other, that of Judge of the Court of Appeals. Now, I think it is out of the question that we should be introducing this system here, and apply this rule to those receiving pay from the State for other services and those who receive nothing.
            </p>
            <div className="speaker" id="sp5575"><span className="persName">Mr. MORTON</span>—</div>
            <p className="p-in-sp">
              As a member of the Finance Committee, I will state that I believe it was on my motion that the compensation was fixed at $10 a day. Capt. Maury left a position that was worth to him at least $4,000 a year. His salary was $3,000. He had, besides, a house furnished, servants and other emoluments, which made his salary about, if not above, $4,000. He has a large family, and $10 a day
              would probably not more than support himself and family, boarded at a hotel as they have to be now. I do not know whether he is at all commissioned as yet, or whether he receives anything in virtue of any naval commission. The attention of the Committee was not drawn to that fact. We supposed that, filling for the present the position of a member of the Advisory Council, he looked
              alone to his salary as a member of that Council. The Committee could not well discriminate between the members, having no information as to their relative grades of salary, and they concluded it was best to place them all upon the same footing. We do not look upon this Council as a permanent body, by any means. The probability is that it will not continue for sixty days. So soon as we
              adopt the permanent Constitution of the Confederate States, and become a member of that government, all of the duties that are devolving upon the Council will fall under the control of the Executive of the Confederate States. So that I trust it will be the pleasure of the Convention to permit this allowance to stand at $10 per day. Of the pecuniary circumstances of the several
              <PageNumber num={740} /> members of the Council I know nothing. But I am satisfied that $10 a day is little enough to enable a man to support his family in this city.
            </p>
            <div className="speaker" id="sp5576"><span className="persName">Mr. BORST</span>—</div>
            <p className="p-in-sp">
              If I am correctly informed, Captain Maury will receive, under the ordinance which we have passed inviting officers of the army and navy of the United States to enter our service, the same pay, at least, that he received in the United States service. All the members of the Council, in fact, except the last two who were appointed, are already in receipt of large salaries from the State
              for other offices which they hold; and I can see no justice in putting them on the same level with those gentlemen who are now receiving no pay from that source.
            </p>
            <p className="p-in-sp">I would suggest that the ordinance be so modified as to discriminate in favor of those who hold no other office under the State Government.</p>
            <div className="speaker" id="sp5577"><span className="persName">Mr. MACFARLAND</span>—</div>
            <p className="p-in-sp">
              I have but little to say upon the subject of compensation. Every gentleman is as well qualified to form an opinion upon that subject as I am or as any other member of the committee. It seems to the committee, in view of the important character of the service, and of the necessity that these gentlemen would be under of coming to the city, that $10 a day was little enough. They will be
              withdrawn from their other avocations, and $10 a day is not a large sum when reference is had to the expense of living in this city. While it is the policy of the State to be sparing and economical, it becomes the Convention to be just.
            </p>
            <p className="p-in-sp">
              In respect to Captain Maury, the effect of the ordinance which we have heretofore passed in respect to salaries, would not exclude the debit against the allowance under the ordinance now reported. If his pay in the United States service was $3,000, and that only, he would get that and that only under the ordinance now reported. He would get according, as the pay of a member of the
              Council was equal to or less than the enactments of the Convention in his behalf. As the one or the other may be greater or less, it would be stopped as authorized.
            </p>
            <div className="speaker" id="sp5578"><span className="persName">Mr. GOODE</span>, of Mecklenburg—</div>
            <p className="p-in-sp">I desire to enter my protest against the extravagant allowance proposed to be given to this Council.</p>
            <div className="speaker" id="sp5579"><span className="persName">Mr. RICHARDSON</span>, of Hanover—</div>
            <p className="p-in-sp">
              I think the report of the Committee makes a meagre compensation to all the members of that Council. I do not know what is the pay of Capt. Maury, or how much Judge Allen receives for the office of Judge, or what Col. Smith receives; but there are two members of that Council who receive not a cent in virtue of any State office. I refer to the last gentleman appointed, Gen. Haymond, and
              the Lieut. Governor, Mr. Montague, <PageNumber num={741} /> who has been made ex-officio a member of that Council. Now, sir, this Convention will probably adjourn to-night; the Senate has already adjourned, and the allowance which that gentleman received will of course cease. I think it would be a great hardship to ask him to give up his practice, to come
              here and serve the State for the meagre compensation of $4 a day.
            </p>
            <p className="p-in-sp">I think the compensation fixed by the Committee is very moderate, and the Convention should not hesitate to allow it.</p>
            <div className="speaker" id="sp5580"><span className="persName">Mr. HOLLADAY</span>—</div>
            <p className="p-in-sp">I desire to join with the gentleman from Mecklenburg [Mr. GoonE] , in this protest against the exorbitant allowance proposed to be made.</p>
            <p className="p-in-sp">
              It is said here that some members of the Council have no salaries from the State, and that $10 a day is necessary for their support and the support of their families. I want to know how it happens that gentlemen are willing to discriminate and enforce a rule of necessity in the case of these councillors that does not apply to other officers, including the members of the Convention.
            </p>
            <p className="p-in-sp">
              We have here in this body one hundred and fifty-two gentlemen, embracing, I presume, all degrees of talent and pecuniary resources; some of them gentlemen of high ability-and our people have deemed $4 a day ample. I want to know why it is that these gentlemen, with certainly not superior qualifications to some members of this Convention, are to be compensated at the rate of $10 a day
              for a less amount of service than members of this Convention have to render for $4 a day. This, in my opinion, is an unjust discrimination which ought not to be sustained here.
            </p>
            <p className="p-in-sp">
              Reference has been made to the pay received in the service of the U. States government. It seems to me we ought to discriminate between the resources of that government and those of Virginia. When we look at the condition of this Commonwealth, we find that we are in the midst of circumstances of the most embarrassing nature-with an empty treasury and a heavy impending debt, and yet, it
              is proposed that an extravagance in the per diem of officers shall be pursued here which exceeds even that in the United States government.
            </p>
            <p className="p-in-sp">
              Now, here are several of these gentlemen who are already drawing salaries from this Commonwealth, and I can see no propriety in giving them the large pay proposed by the committee. With regard to one of these gentlemen, I do not know but that we have a right to demand his services upon the pay he receives. He is one of the military officers of the government, and, as such, it seems to
              me the State is entitled to his services without extra pay. I know it is hard to discriminate, <PageNumber num={742} /> but I think every gentleman will say that $4 a day is enough to support any man.
            </p>
            <div className="speaker" id="sp5581"><span className="persName">Mr. MORTON</span>—</div>
            <p className="p-in-sp">Suppose they have families?</p>
            <div className="speaker" id="sp5582"><span className="persName">Mr. HOLLADAY</span>—</div>
            <p className="p-in-sp">I take it for granted that members of the Council are not more embarrassed in their pecuniary circumstances than many members of the Convention.</p>
            <p className="p-in-sp">I certainly think that $10 a day is too extravagant.</p>
            <div className="speaker" id="sp5583"><span className="persName">Mr. HARVIE</span>—</div>
            <p className="p-in-sp">
              I am sure that those who know me will not suppose that I am disposed to stint officers in the public service; and it is with great reluctance that I say a word at all upon this subject. But I think that in times like these, when it is the duty of every man, no matter at what cost to himself, to come forward and stand by his country, that the strictest economy should be observed in the
              expenditure of the public money, and that no salaries should be given which would render an office desirable. Something should be left to patriotism.
            </p>
            <p className="p-in-sp">
              Here are three members who have been put upon this Council because of their qualifications, whom it is now proposed to compensate at the rate of $10 per day, notwithstanding that they are now receiving salaries from the State for other services. We did not seek for men who had no salaries upon whom to confer these appointments, but because we believed these gentlemen upon whom the
              appointments were conferred to be peculiarly qualified for the position; and it seems to me that such a mark of appreciation would in itself be a sufficient counterbalance for any drawback in the way of compensation that they may suffer. I give these gentlemen full credit for a high and exalted patriotism; but if I were in their position I certainly would desire no compensation but
              what I had already received from the Commonwealth, in virtue of any office I might hold in her service.
            </p>
            <p className="p-in-sp">
              There are other gentlemen who have been added to the council, who receive no pay from the State, and they ought to be compensated at least to an extent that would justify them in coming here. I shall move an amendment to the report of the committee giving no salaries to the three gentlemen first appointed, and allowing $7 a day each to the two who receive no other pay from the
              Commonwealth.
            </p>
            <p className="p-in-sp">I offer the following:</p>
            <p className="p-in-sp">"Be it ordained, That those members of the Advisory Council who are not in the pay of the State, be allowed the sum of $7 per day while they remain in office."</p>
            <div className="speaker" id="sp5584"><span className="persName">Mr. HOLLADAY</span>—</div>
            <p className="p-in-sp">I understand the highest pay received by any member of the Council is $3,500 a year. I would suggest that the <PageNumber num={743} /> members of the Council be each allowed a sum sufficient to make his pay equal to that of the member receiving the highest salary, so that all may be on the same footing.</p>
            <div className="speaker" id="sp5585"><span className="persName">Mr. HARVIE</span>—</div>
            <p className="p-in-sp">I cannot agree to that suggestion. I do not believe that these three gentlemen first appointed are entitled to any pay. My purpose is to compensate these other gentlemen who are taken away from their other avocations.</p>
            <div className="speaker" id="sp5586"><span className="persName">Mr. MACFARLAND</span>—</div>
            <p className="p-in-sp">If that amendment is adopted, will it be liable to amendment?</p>
            <div className="speaker" id="sp5587"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">No, sir. The chair is of opinion that this ought not to be received as a substitute for the whole ordinance.</p>
            <div className="speaker" id="sp5588"><span className="persName">Mr. HARVIE</span>—</div>
            <p className="p-in-sp">Then I will move it as an amendment to the amendment offered by the gentleman from Louisa [<span className="persName">Mr. AMBLER</span>] .</p>
            <div className="speaker" id="sp5589"><span className="persName">Mr. STAPLES</span>, of Patrick—</div>
            <p className="p-in-sp">If the amendment is voted down, I will move to fix the compensation at the rate of $2,000 a year.</p>
            <div className="speaker" id="sp5590"><span className="persName">Mr. HARVIE</span>—</div>
            <p className="p-in-sp">Remember that the old executive council of Virginia which checked and overlooked everything that came before the Governor, received only at the rate of $1,000 a year. If that amount was sufficient then, I cannot see why $7 a day is not, for probably less duty.</p>
            <div className="speaker" id="sp5591"><span className="persName">Mr. BLAKEY</span>—</div>
            <p className="p-in-sp">I rise to make an inquiry.</p>
            <p className="p-in-sp">I do not understand whether the proposition of the gentleman from Amelia [<span className="persName">Mr. HARVIE</span>]
              proposes to give Judge Allen and those others on the Council who are already receiving pay from the Commonwealth anything at all. It seems to me that that is not right if such be the object of the gentleman's amendment. Judge Allen, it is true, as Judge of the Court of Appeals, is receiving a salary; but he is receiving that salary as Judge, and not as Councillor. I do not think it is
              fair to throw additional labor upon Judge Allen without compensation.
            </p>
            <div className="speaker" id="sp5592"><span className="persName">Mr. SUTHERLIN</span>—</div>
            <p className="p-in-sp">I call the previous question and the main question ordered, which was upon the amendment offered by <span className="persName">Mr. HARVIE</span>.</p>
            <div className="speaker" id="sp5593"><span className="persName">Mr. WICKHAM</span>—</div>
            <p className="p-in-sp">It seems to me it is perfectly competent to move an amendment for the entire ordinance.</p>
            <div className="speaker" id="sp5594"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">The Chair is of a different opinion, because it cuts off all opportunity for amending afterwards.</p>
            <p className="p-in-sp">The Chair will explain how the question now stands.</p>
            <p className="p-in-sp">The original proposition gives to these gentlemen $10 each per day. The amendment of the gentleman from Louisa is to strike out $10 and insert $4. The proposition of the gentleman from Amelia <PageNumber num={744} /> proposes to insert the ordinance which he has just submitted as an amendment to the amendment.</p>
            <div className="speaker" id="sp5595"><span className="persName">Mr. GOODE</span>, of Mecklenburg—</div>
            <p className="p-in-sp">I call for the yeas and nays.</p>
            <p>The call was sustained, and the vote being taken, resulted-yeas 39, nays 45-as follows:</p>
            <p>
              YEAS-Messrs. Aston, Blow, Branch, Brent, Bruce, Cabell, Chambliss, Conn, Deskins, Early, Echols, Flournoy, Garland, Gillespie, Addison Hall, L. S. Hall, Harvie, Holcombe, Hunton, Marmaduke Johnson, Peter C. Johnston, Leake, Macfarland, Marr, Morris, Morton, Moore, Nelson, Preston, Richardson, Sea-well, Southall, Sutherlin, Tayloe, Thornton, Robert H. Turner, White, Williams, Wilson-39.
            </p>
            <p>NAYS-The President [John Janney]
              , Messrs. Ambler, Alfred M. Barbour, Baylor, Blakey, Borst, Bouldin, Boyd, Campbell, Caperton, Carter, Cecil, Chapman, Coffman, J. H. Cox, R. H. Cox, Custis, Dulany, French, Grant, Gray, Goggin, John Goode, T. F. Goode, Ephraim B. Hall, Holladay, Isbell, Lawson, Lewis, Marye, Miller, Moffett, Randolph, Rives, William C. Scott, Sharp, Sheffey, Sitlington, Speed, Staples, A. H. H.
              Stuart, Strange, Tyler, Wickham, Woods-45.
            </p>
            <p>So the amendment was rejected.</p>
            <p><span className="persName">Mr. MONTAGUE</span> was excused from voting, at his own request.</p>
            <div className="speaker" id="sp5596"><span className="persName">Mr. HARVIE</span>—</div>
            <p className="p-in-sp">I move that the sum of $6 be allowed instead of the amount proposed by the committee.</p>
            <div className="speaker" id="sp5597"><span className="persName">Mr. SHEFFEY</span>—</div>
            <p className="p-in-sp">I desire to inform the Convention that if the proposition of the gentleman from Amelia, just submitted, is rejected, I will offer the following, by way of an amendment to the amendment of the gentleman from Louisa [<span className="persName">Mr. AMBLER</span>] .</p>
            <p className="p-in-sp">"Resolved, That there be allowed and paid to each member of the Advisory Council of the Governor the sum of $4 per day, and that each member not already in the pay of the State shall receive, in addition, the mileage allowed to members of the General Assembly."</p>
            <div className="speaker" id="sp5598"><span className="persName">Mr. SUTHERLIN</span>—</div>
            <p className="p-in-sp">I call the previous question.</p>
            <div className="speaker" id="sp5599"><span className="persName">Mr. WICKHAM</span>—</div>
            <p className="p-in-sp">I trust the call for the previous question will not be sustained.</p>
            <p className="p-in-sp">I desire to offer an amendment, which I will read for the information of the Convention :</p>
            <p className="p-in-sp">"Be it ordained, That the members of the Advisory Council who receive a salary from the State shall receive $4 per day for their services, and that those members who do not receive a salary from the State shall receive $8 per day for their services."</p>
            <p>The previous question was sustained, and the main question being put upon the adoption of the amendment offered by Mr. Harvie, fixing the salary of the two newly appointed members at $6 per day, <PageNumber num={745} /> it was decided in the negative.</p>
            <div className="speaker" id="sp5600"><span className="persName">Mr. WICKHAM</span>—</div>
            <p className="p-in-sp">I now offer the ordinance which I just read as an amendment to the amendment offered by the gentleman from Louisa.</p>
            <p className="p-in-sp">It seems to me fair that these gentlemen who received a salary from the State should receive something in consideration of the fact that they are brought here from their places of residence; and that those who receive nothing from the State, should be paid a larger salary.</p>
            <p className="p-in-sp">I call the previous question.</p>
            <p>The call was sustained, and the main question being put, it was decided in the affirmative.</p>
            <div className="speaker" id="sp5601"><span className="persName">Mr. MORTON</span>—</div>
            <p className="p-in-sp">I move to strike out $4, so as to leave the salaried officers as they now stand, and in order that those who have no salaries shall have the $6 a day. I know that the Lieutenant Governor cannot live here with his family on less than that amount.</p>
            <div className="speaker" id="sp5602"><span className="persName">Mr. WICKHAM</span>—</div>
            <p className="p-in-sp">The Convention has, but a single moment ago, decided that point in the rejection of the amendment offered by the gentleman from Amelia.</p>
            <div className="speaker" id="sp5603"><span className="persName">Mr. HARVIE</span>—</div>
            <p className="p-in-sp">It is competent for us to strike out any part of the ordinance we think proper, and leave any part of it which we desire to retain.</p>
            <div className="speaker" id="sp5604"><span className="persName">Mr. MACFARLAND</span>—</div>
            <p className="p-in-sp">I would suggest to the gentleman, as I understand it, that the compensation allowed to two members of the Council by the amendment of the gentleman from Henrico [<span className="persName">Mr. WICKHAM</span>] , just adopted, is $8 per day, and $4 to the other three who already receive salaries from the State.</p>
            <p className="p-in-sp">I wish to have the amendment again reported.</p>
            <p>The Secretary read the amendment, as follows :</p>
            <p>Be it ordained, That the members of the Advisory Council who receive a salary from the State, shall receive $4 per day for their services, and that those members who do not receive a salary from the State, shall receive $8 per day for their services.</p>
            <div className="speaker" id="sp5605"><span className="persName">Mr. MACFARLAND</span>—</div>
            <p className="p-in-sp">That seems to me to be perfectly conclusive upon the question of the salary of these gentlemen.</p>
            <div className="speaker" id="sp5606"><span className="persName">Mr. MORTON</span>—</div>
            <p className="p-in-sp">I offer the following by way of amendment to the ordinance as amended :</p>
            <p className="p-in-sp">"Be it ordained, That the members of the Advisory Council, now receiving a salary from the State, shall receive no additional compensation; and that those who receive no salary from the State, shall receive $8 per day for their services."</p>
            <div className="speaker" id="sp5607"><span className="persName">Mr. WICKHAM</span>—</div>
            <p className="p-in-sp">That is identically the proposition which was <PageNumber num={746} /> already rejected. The gentleman from Amelia [<span className="persName">Mr. HARVIE</span>] , offered a proposition of that character, but the House refused to adopt it.</p>
            <div className="speaker" id="sp5608"><span className="persName">Mr. MORTON</span>—</div>
            <p className="p-in-sp">The proposition of the gentleman from Amelia was $7 a day.</p>
            <div className="speaker" id="sp5609"><span className="persName">Mr. WICKHAM</span>—</div>
            <p className="p-in-sp">Yes, but the principle is identical.</p>
            <p>The amendment was rejected.</p>
            <div className="speaker" id="sp5610"><span className="persName">Mr. WICKHAM</span>—</div>
            <p className="p-in-sp">I now move to amend the ordinance as amended, so as to make it conform to the amendment, by striking out that portion of the ordinance reported, which conflicts with the amendment offered by me, and which was adopted.</p>
            <div className="speaker" id="sp5611"><span className="persName">Mr. HARVIE</span>—</div>
            <p className="p-in-sp">The gentleman from Henrico [<span className="persName">Mr. WICKHAM</span>] , leaves the Lieut. Governor's pay at $4 a day. He is now receiving a salary of $8 per day as Lieut. Governor of the State.</p>
            <div className="speaker" id="sp5612"><span className="persName">Mr. WICKHAM</span>—</div>
            <p className="p-in-sp">The gentleman is entirely mistaken both as to the intention and letter of the ordinance. The Lieut. Governor is receiving no salary from the State except as a member of this Convention.</p>
            <div className="speaker" id="sp5613"><span className="persName">Mr. HARVIE</span>—</div>
            <p className="p-in-sp">The gentleman from Middlesex receives a salary of $8 per day, while the Senate is in session, as Lieut. Governor of the Commonwealth. He presides over the Senate, but does not receive that salary as a member of that body.</p>
            <div className="speaker" id="sp5614"><span className="persName">Mr. MACFARLAND</span>—</div>
            <p className="p-in-sp">That is no salary.</p>
            <div className="speaker" id="sp5615"><span className="persName">Mr. HARVIE</span>—</div>
            <p className="p-in-sp">That is a salary; and he receives it while he is acting as presiding officer of the Senate.</p>
            <p className="p-in-sp">I regard this Council as important, but I am unwilling to vote for extraordinary and disproportionate salaries in connection with that body. I cannot vote to give those gentlemen now receiving salaries from the Commonwealth an additional boon to the extent of $4 a day.</p>
            <p className="p-in-sp">I trust it will not be the pleasure of the Convention to make this $4 per diem allowance to those gentlemen now receiving salaries from the Commonwealth.</p>
            <div className="speaker" id="sp5616"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">I beg leave to submit a communication from the Governor of the Commonwealth, for the consideration of the Convention:</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>EXECUTIVE DEPARTMENT,</p>
                <p>May 1st, 1861.</p>
                <p>Gentlemen of the Convention:</p>
                <p>I desire to withdraw the message sent in to you presenting nominations for military officers, that I may make some changes in the rank of some of the officers.</p>
                <p>JOHN LETCHER.</p>
              </div>
            </div>
            <PageNumber num={747} />
            <div className="speaker" id="sp5617"><span className="persName">Mr. MORTON</span>—</div>
            <p className="p-in-sp">I move that leave be granted to withdraw the message referred to.</p>
            <p>The Convention granted the leave.</p>
            <div className="speaker" id="sp5618"><span className="persName">Mr. SCOTT</span>, of Fauquier—</div>
            <p className="p-in-sp">I desire to call the attention of the Convention, especially that of the gentleman from Amelia [Mr. Harvie] , to the provision concerning the Lieut. Governor. I do not regard him as a salaried officer.</p>
            <p className="p-in-sp">The provision is, that "the Lieut. Governor shall be President of the Senate, but shall have no vote, and while acting as such, shall receive a compensation equal to that received by the Speaker of the House of Delegates."</p>
            <p className="p-in-sp">Now, it seems to me perfectly clear that, instead of his being a salaried officer—</p>
            <div className="speaker" id="sp5619"><span className="persName">Mr. SPEED</span>—</div>
            <p className="p-in-sp">I ask the gentleman to give way for a moment. The communication from the Governor, requesting the message which he sent in the other day to be returned, makes it important that action should be taken now upon an ordinance that I hold in my hand, and which I offer with the approbation of the gentleman from Richmond [<span className="persName">Mr. RANDOLPH</span>] . I ask leave to offer it now, because it relates particularly to some action which is being taken in the Governor's room on the subject to which it relates. If the ordinance is adopted, it will be necessary for the Governor to act upon it at once.</p>
            <p className="p-in-sp">I move to pass by the business now under consideration for a moment.</p>
            <p>The motion was agreed to.</p>
            <div className="speaker" id="sp5620"><span className="persName">Mr. SPEED</span>—</div>
            <p className="p-in-sp">I now offer the following ordinance:</p>
            <p className="p-in-sp">"Be it ordained, That the ordinance passed on the 17th day of April authorizing the volunteers to be called into service, shall not be considered as embracing the First Regiment Virginia Volunteers."</p>
            <p className="p-in-sp">The special reason for this, sir, will be better explained by my friend from Richmond [<span className="persName">Mr. RANDOLPH</span>] , who is acquainted with the peculiar position in which this regiment stands.</p>
            <div className="speaker" id="sp5621"><span className="persName">Mr. RANDOLPH</span>—</div>
            <p className="p-in-sp">As I am no longer a member of that regiment; I have no personal interest in this matter.</p>
            <p className="p-in-sp">
              It appears that the first regiment has been called into service as a regiment. By a construction or misconstruction of that ordinance the Governor supposed that he was authorized to call that regiment into service as a regiment, and the consequence now is that the regiment is mustered in, and that the field officers do not know whether they are in commission or not. If the Governor be
              wrong in his construction of the ordinance, they are not in commission. If he be right, they are.
            </p>
            <PageNumber num={748} />
            <p className="p-in-sp">The object of that ordinance is to legalize the call which has been made of that regiment, into service as a regiment.</p>
            <p className="p-in-sp">I know that I shall vote for that ordinance. I merely rose to state what its object was, and, as I saw it, it is merely to legalize what the Governor has already done under, as it seems, a misrepresentation of the ordinance.</p>
            <div className="speaker" id="sp5622"><span className="persName">Mr. HOLLADAY</span>—</div>
            <p className="p-in-sp">I move to amend the ordinance by exempting also the third regiment of Virginia volunteers.</p>
            <p className="p-in-sp">I will state that I believe precisely the same course has been pursued in reference to the third regiment that has been pursued with reference to the first. This, sir, is a regiment in my own town [Portsmouth]
              , which has already rendered most efficient service. I would state here, which is creditable to them, that on the occasion of firing the navy yard, within a very short period of time, at a risk of their lives by explosion-for it was known that trains had been laid under many of the buildings-that regiment was at the scene of the conflagration rendering efficient service. I understand
              they have been mustered as a regiment with its regularly organized regimeptal officers.
            </p>
            <p className="p-in-sp">Now, sir, I take it that if the original ordinance be acceptable to this Convention, the third regiment commends itself equally with the first to the favorable consideration of the Convention, and I therefore move to amend the ordinance by exempting the third regiment from the operation of the ordinance of the 17th of April.</p>
            <div className="speaker" id="sp5623"><span className="persName">Mr. RANDOLPH</span>—</div>
            <p className="p-in-sp">I will say that I shall vote against this amendment. I think it is an injudicious departure from the plan of proceeding originally adopted by the Convention.</p>
            <p className="p-in-sp">If this exemption is to be extended to regiments not yet called into service, I shall vote against any exemption in favor of the first regiment or any other.</p>
            <div className="speaker" id="sp5624"><span className="persName">Mr. WHITE</span>, of Norfolk County—</div>
            <p className="p-in-sp">The third regiment is already in service, and has been camping out for ten days. I have a petition now to the Governor praying that their field officers shall be retained.</p>
            <div className="speaker" id="sp5625"><span className="persName">Mr. HUNTON</span>, of Prince William—</div>
            <p className="p-in-sp">I beg leave to say that this subject was before the military committee this morning, and it was determined by that committee that it would be wrong for this Convention to depart from the rule of organization for the volunteer forces of the State which had already been inaugurated.</p>
            <p className="p-in-sp">The object which that ordinance has in view, can be effected in another way. If there are particular reasons why the officers in the regiment now should be retained, all the Governor has to do is to recommission those officers.</p>
            <PageNumber num={749} />
            <p className="p-in-sp">I therefore move to lay the whole subject on the table.</p>
            <div className="speaker" id="sp5626"><span className="persName">Mr. BAYLOR</span>, of Augusta—</div>
            <p className="p-in-sp">
              I understand there are only three volunteer regiments in the State of Virginia. I am not sufficiently familiar with these regiments to know how they are numbered; but from what has passed here, I judge that the volunteer regiment of the county of Augusta must be No. 2. I do not see why it is that any distinction should be made in favor of numbers 1 and 3 over number 2. I am in favor of
              all the volunteer regiments being called into service as regiments, if possible. The volunteer companies of the county of Augusta have been called out as a regiment, and are now in service. They are acting as a volunteer regiment with their regular officers, and if any of the volunteer regiments are to be exempt from the ordinance of the 17th of April, I do not think there ought to be
              any difference made between them. All should be put on the same footing.
            </p>
            <div className="speaker" id="sp5627"><span className="persName">Mr. SPEED</span>—</div>
            <p className="p-in-sp">I do not think I can add a word to what I have said before, and I do not know that I can give any strength to the proposition by anything I can say.</p>
            <p className="p-in-sp">I stated, when up before, that this ordinance was proposed with the approbation of the gentleman from Richmond [<span className="persName">Mr. RANDOLPH</span>]
              . He assisted me in the preparation of it, and I consulted with him in regard to the propriety of offering it. I therefore thought at the time I submitted it that I should continue to have the benefit of his full and cordial endorsement; but I am sorry to say that I have not only lost the active aid of what I had hoped to be a coadjutor, but have found in him an efficient opponent. I
              thought there was, in his estimation, some special reason why this regiment should be exempted from the operation of the ordinance adopted on the 17th of last month, requiring the military forces of the State to be called into service in companies. I was aware of the fact that the subject was before the Military Committee this morning, but not in the form I presented. The application
              before the Committee was to exempt this and several other regiments from the operation of this ordinance. It failed, however, in that form, but the fact was never brought before the Convention in any formal manner. This is a separate proposition. I submitted it to the Chairman of the Committee on Military Affairs, and he cordially approved it; and yet, sir, when I stated this
              proposition a moment ago I found it vigorously opposed even by the gentleman from Richmond
              [<span className="persName">Mr. RANDOLPH</span>] , whose endorsement it received before it was brought to the attention of the Convention.</p>
            <p className="p-in-sp">
              I trust the House will not favor the amendment to include the third <PageNumber num={750} /> regiment in this exemption. The same reason cannot be urged in favor of that amendment that can be in favor of the proposition which I have offered. This first, as I understand it, has very peculiar claims on the favor and patronage of the State. It has been
              acting as a full regiment for the space of thirteen years, and its members have equipped themselves without calling on the State for one dollar in any form. They have been in every service which the State has called upon them to perform, including the Harper's Ferry raid.
            </p>
            <p className="p-in-sp">I trust it will not be the pleasure of the Convention to adopt the amendment, but that they will adopt the proposition as presented by me.</p>
            <div className="speaker" id="sp5628"><span className="persName">Mr. MORTON</span>—</div>
            <p className="p-in-sp">I move to lay the whole subject on the table.</p>
            <div className="speaker" id="sp5629"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">That motion is already pending.</p>
            <div className="speaker" id="sp5630"><span className="persName">Mr. BLOW</span>—</div>
            <p className="p-in-sp">I think when this matter comes to be better understood by the Convention, that there will be no objection, whatever, raised to the proposition, if it comes in proper form.</p>
            <p className="p-in-sp">
              Gentlemen seem to be under the impression that if this proposition prevails, so far as relates to the First Regiment, and in fact, the other regiments in the State, it will interfere with the organization of the volunteer forces of the State under the ordinance adopted on the 17th of last month. Upon an examination of this question I think that it will be found to be an error. By
              examining the law on the subject of volunteers, passed several years ago, it will be found that any city, town or county district in the Commonwealth of Virginia having a sufficient number of volunteer companies by companies, which would agree to unite for the purpose of forming battalions or regiments, should have the privilege of electing their own field officers. There are at this
              time but three regiments in the State-the 1st in Richmond, the 3d in the town of Portsmouth, and a volunteer battalion in the city of Norfolk, and perhaps a volunteer battalion in Petersburg, Augusta and Danville; and if there be any class of men in the State, who are entitled to the consideration of the Convention, I say it is that body of men; for as has been properly remarked by the
              gentleman from Richmond, in regard to the 1st regiment, these regiments have not only uniformed themselves, but were always ready, as they are now, to perform any service which the State assigned them. Let me tell my friend from Lynchburg
              [<span className="persName">Mr. SPEED</span>] , that whilst I know the high character of the 1st regiment, and that probably no finer body of men can be found, yet there are other volunteer associations in regiments and battalions in the State, not at all inferior to them.</p>
            <p className="p-in-sp">
              Ten days since, in the city of Norfolk, when our town was to be <PageNumber num={751} /> attacked, as was then supposed, and when the sound of the enemy's cannon echoed daily in our ears, reminding us that an attack was imminent, this volunteer regiment and battalion, sprung forward without any order, and rendered whatever service was necessary. I know
              that these men had been on duty for thirty hours without receiving one morsel of food. I say that these officers, in whom the men have confidence, should be received into the organization of the State. Let us grant the exemption sought for to these regiments and battalions; and with a view to put the question in a clear and distinct form before the Convention, I will offer the
              following ordinance by way of amendment.
            </p>
            <div className="speaker" id="sp5631"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">Any proposition is now out of order pending a motion to lay on the table.</p>
            <div className="speaker" id="sp5632"><span className="persName">Mr. HUNTON</span>—</div>
            <p className="p-in-sp">I withdraw the motion to lay on the table, for the present.</p>
            <div className="speaker" id="sp5633"><span className="persName">Mr. BLOW</span>—</div>
            <p className="p-in-sp">I now offer the following substitute for the ordinance offered by the gentleman from Lynchburg [<span className="persName">Mr. SPEED</span>] .</p>
            <p className="p-in-sp">
              "Be it ordained, that the Governor of the Commonwealth, by and with the advice of the Advisory Council, be and he is hereby authorized to receive into the service of the State, volunteer regiments and battalions which have been organized under the provisions of the 19th and 20th sections, chapter 26 of the code, when the field officers of the regiment, may be commissioned accordingly."
            </p>
            <p className="p-in-sp">This will apply only to the volunteer regiments organized before the secession of this State. I see no harm that can be done by the adoption of this ordinance. On the contrary, I believe that it will be productive of harmony among the gallant men composing these regiments and battalions.</p>
            <p className="p-in-sp">I call the previous question.</p>
            <div className="speaker" id="sp5634"><span className="persName">Mr. SUTHERLIN</span>—</div>
            <p className="p-in-sp">I hope the gentleman will withdraw the call for a moment.</p>
            <div className="speaker" id="sp5635"><span className="persName">Mr. BLOW</span>—</div>
            <p className="p-in-sp">I withdraw it.</p>
            <div className="speaker" id="sp5636"><span className="persName">Mr. SUTHERLIN</span>—</div>
            <p className="p-in-sp">I wish to state in support of that proposition that we have a battalion in my town that has been organized for several months, and thoroughly and completely officered. They have now standing to their credit some $9000 appropriated by the corporation of Danville, in addition to an amount contributed by the citizens.</p>
            <p className="p-in-sp">
              Now, the only thing they ask is that the State shall allow them to be received as a battalion, and I can see no reasonable objection to such a privilege. The battalion is officered by men with whom they are willing to go into battle. It is here now, and has been received by the <PageNumber num={752} /> Commander-in-Chief as a battalion, subject to the
              decision of the Governor hereafter.
            </p>
            <p className="p-in-sp">I hope it will be the pleasure of the Convention to order the reception of this battalion as a battalion.</p>
            <div className="speaker" id="sp5637"><span className="persName">Mr. NELSON</span>, of Clarke—</div>
            <p className="p-in-sp">I rise to speak of a regiment which I think has claims equal to any regiment spoken of here.</p>
            <div className="speaker" id="sp5638"><span className="persName">Mr. SPEED</span>—</div>
            <p className="p-in-sp">What is the condition of the question? I offered an ordinance; the gentleman from Norfolk county [<span className="persName">Mr. HOLLADAY</span>] , offered an amendment, and now the gentleman from Norfolk city [<span className="persName">Mr. BLOW</span>] , offers an ordinance by way of a sustitute for the whole.</p>
            <div className="speaker" id="sp5639"><span className="persName">Mr. HOLLADAY</span>—</div>
            <p className="p-in-sp">I withdraw my amendment.</p>
            <div className="speaker" id="sp5640"><span className="persName">Mr. NELSON</span>—</div>
            <p className="p-in-sp">The regiment I speak of has been organized into a regiment from companies' formed in different counties. I refer to the second regiment of Virginia volunteers, composed of companies organized in the counties of Jefferson, Berkeley and Clarke.</p>
            <p className="p-in-sp">I move that this regiment be included in the ordinance just submitted.</p>
            <div className="speaker" id="sp5641"><span className="persName">Mr. HOLLADAY</span>—</div>
            <p className="p-in-sp">The proposition just submitted by the gentleman from Norfolk city [Mr. BLOW] covers the whole matter.</p>
            <div className="speaker" id="sp5642"><span className="persName">Mr. NELSON</span>—</div>
            <p className="p-in-sp">No, sir, the Second Regiment is not included in that.</p>
            <div className="speaker" id="sp5643"><span className="persName">Mr. MOFFETT</span>—</div>
            <p className="p-in-sp">I move to lay the whole subject upon the table.</p>
            <p>The motion was agreed to.</p>
            <div className="speaker" id="sp5644"><span className="persName">Mr. GOGGIN</span>—</div>
            <p className="p-in-sp">I suppose the ordinance in regard to the pay of the members of the Advisory Council will now come up.</p>
            <p className="p-in-sp">I move as an amendment to that ordinance the following:</p>
            <p className="p-in-sp">"Provided, however, that this ordinance shall not be so construed as to preclude the Lieutenant Governor from receiving eight dollars per day for his services, when acting as member of the Advisory Council."</p>
            <p className="p-in-sp">Upon that amendment I call the previous question.</p>
            <p>The call was sustained, and the main question being put, it was decided in the affirmative.</p>
            <p>The question then recurred upon the adoption of the ordinance as amended, and it was decided in the affirmative.</p>
            <div className="speaker" id="sp5645"><span className="persName">Mr. MACFARLAND</span>—</div>
            <p className="p-in-sp">I am instructed by the Special Committee on the subject of the Circuit and District Courts of the United States, in this Commonwealth, to submit the following ordinance:</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>
                  Be it ordained, That the Circuit and District Courts of the late United States of America, which existed in this State on the day when this Convention first assembled, shall continue to exist, and have and exercise all the power and jurisdiction, civil and criminal, which <PageNumber num={753} /> they had and exercised at that time; and shall be
                  governed in their practice and decisions by the same rules and laws and usages, by which they were governed at that time; so far as the said rules, laws and usages are not incompatible with any ordinance of this Convention, or act of the Congress of the Southern Confederacy: and until they may be repealed or modified by the Legislature of this State or the Congress of the Southern
                  Confederacy.
                </p>
                <p>
                  And be it further ordained, That no suit, writ, mandate from the Supreme Court of the United States, or other proceeding whatever, which was returnable to, or pending in, said courts first above mentioned, on the day aforesaid, or on the seventeenth day of April last, past, shall abate or be continued, in consequence of the action or any ordinance of this Convention; but shall
                  remain in the same plight, and be proceeded in as they would or might have been if this Convention had never assembled.
                </p>
                <p>
                  And be it further ordained, That John W. Brockenbrough and James D. Halyburton, late Judges of said Circuit and District Courts, may continue to hold and act as Judges of said courts in the same manner as heretofore, during their behavior, and shall receive as compensation therefor, each of them, a sum equal in amount to the compensation heretofore received by him as Judge from the
                  United States aforesaid, payable quarterly out of the treasury of this State, to commence from the day on which the last quarterly payment of their salaries were due to said Judges from the said late United States of America.
                </p>
                <p>
                  And be it further ordained, That the late Marshals and Attorneys for the United States, who were in office in this State, on the first day of March last, shall continue to act as such, and to hold their offices until the new appointments may be made under the Constitution of the Southern Confederacy, and shall receive the same compensation as heretofore, to be paid out of the
                  Treasury of this State quarterly, and the same fees as have heretofore been allowed; but the Judge of either of said Districts may remove, at pleasure, the Marshal or Attorney of the District of which he is Judge, to make new appointments; and the bonds given by the Marshal shall be taken by the Judge of the District to which said Marshal belongs, and shall be filed and preserved
                  in the office of the Clerk of such District.
                </p>
                <p>
                  And be it further ordained, That all the statutes and laws of the late United States of America, which were of force in this State on the seventeenth day of this month, and which are not incompatible with any ordinance of this Convention, or any law of the Southern Confederacy, <PageNumber num={754} /> be and are hereby continued in force in this
                  State, until repealed by the legislature of this State, or the Congress of the Southern Confederacy.
                </p>
              </div>
            </div>
            <div className="speaker" id="sp5646"><span className="persName">Mr. MACFARLAND</span>—</div>
            <p className="p-in-sp">I am instructed by the same committee to offer the following resolution:</p>
            <p className="p-in-sp">Resolved, That the Hon. George P. Scarborough, late Judge of the Court of Claims of the United States of America, having resigned his office, is entitled to and enjoys the confidence of this convention, and is respectfully recommended to the favorable consideration of the authorities of the Southern Confederacy.</p>
            <div className="speaker" id="sp5647"><span className="persName">Mr. MORTON</span>—</div>
            <p className="p-in-sp">I move to lay the ordinance upon the table.</p>
            <div className="speaker" id="sp5648"><span className="persName">Mr. MACFARLAND</span>—</div>
            <p className="p-in-sp">To overlook this subject will amount to a confiscation of property involved in suits pending in these courts. I trust the gentleman will withdraw the motion, and I promise to renew it.</p>
            <div className="speaker" id="sp5649"><span className="persName">Mr. MORTON</span>—</div>
            <p className="p-in-sp">I withdraw the motion.</p>
            <div className="speaker" id="sp5650"><span className="persName">Mr. MACFARLAND</span>—</div>
            <p className="p-in-sp">
              I shall go into no argument to establish the propriety of this ordinance; but I beg gentlemen to consider, that it is important that this ordinance should be passed, in order to prevent the confiscation of a large amount of property involved in causes now depending in these courts. There are many causes which have been depending there for years, and if the court goes down, as it must
              go, unless some action as the committee recommends is had hereafter, these causes will be at an end; and the result will be, that private rights to the extent involved in these courts shall be sacrificed.
            </p>
            <p className="p-in-sp">If there be anything in that ordinance which makes it objectionable to gentlemen, let us amend it; but do not, I pray, jeopardize the interests which are now involved in these Courts. Why, sir, appeals have been taken to the Supreme Court of the United States, the result of which must lead to some embarrassment, if we do not take some action in this matter.</p>
            <p className="p-in-sp">I would, therefore, Mr. President, be willing, if gentlemen are not satisfied now to vote in favor of the ordinance, that it should for the present be laid upon the table, to enable it to be examined.</p>
            <p className="p-in-sp">
              The ordinance came under the investigation and the examination of the committees, and one and all concurred in its propriety. And let me say that I conferred with Judge Halyburton on the subject; and this ordinance is offered with his full approbation. It does nothing more than commit the treasury of the State to the payment of the salaries of these Judges, I apprehend, but for a very
              short period. This matter of Federal jurisdiction is provided for by the Southern Constitution; and just as soon as matters can be arranged in the South, <PageNumber num={755} /> just so soon, I apprehend, will these courts be established under the Southern Confederacy, and the State relieved from all expenses which may be incurred under this ordinance.
            </p>
            <p className="p-in-sp">
              The effect of the passage of this ordinance will be to protect private rights against the waste and destruction which will attend them if this provision be not made. I commend it, therefore, to the favor and acceptance of gentlemen, and I would do it for the additional reason that, in my opinion, there are not two men in the Commonwealth entitled to more respect from this Convention
              than Judge Halyburton and Judge Brockenbrough.
            </p>
            <p className="p-in-sp">We have taken care of all military men who have tendered their services to us; and can it be that this Convention will be indifferent to the high claims of the gentlemen to whom I have referred.</p>
            <p className="p-in-sp">I trust that the ordinance will commend itself to the consideration of the Convention.</p>
            <p className="p-in-sp">I will now, according to agreement, move to lay the ordinance on the table, with the hope that this Convention will have it called up.</p>
            <div className="speaker" id="sp5651"><span className="persName">Mr. AMBLER</span>—</div>
            <p className="p-in-sp">I would ask the gentleman from Orange [<span className="persName">Mr. MORTON</span>] , to withdraw the motion. It is his motion, and it is for him alone to withdraw it.</p>
            <div className="speaker" id="sp5652"><span className="persName">Mr. MORTON</span>—</div>
            <p className="p-in-sp">I withdraw it for the present.</p>
            <div className="speaker" id="sp5653"><span className="persName">Mr. AMBLER</span>—</div>
            <p className="p-in-sp">I should like that some explanation could be given to the Convention, why it is necessary that this policy should be pursued of continuing and creating so many offices in the present condition of the Commonwealth.</p>
            <p className="p-in-sp">
              Now, by our action, these offices which are proposed to be continued under this ordinance, would be abolished. It is known that any case arising now, of which, under other circumstances, the Federal Courts should take cognizance, can be acted upon by our State Courts; because, sir, in the case of the retrocession of Alexandria, the same causes then pending in the Courts of that part of
              the then District of Columbia were brought up for consideration in the State Courts, and were maturely considered and decided upon. The whole difficulty was gotten rid of by transferring all causes pending in these Courts to the State Courts, which were in existence, thus getting rid of all the machinery which existed in the Courts in Alexandria under the Federal jurisdiction. Matters
              went on just as smoothly, just as well, and without any more difficulty than if the reversion had not taken place.
            </p>
            <p className="p-in-sp">
              Now, the advantage that would result from pursuing a similar course in this instance would be, that we would get rid of the expensive <PageNumber num={756} /> machinery which this ordinance proposes to keep up. We would simplify all proceedings, and the matter would be distinctly understood, because it would be acted upon just as a matter of the same kind
              had been acted upon before; the laws which were then passed, having been acted on since; so that no new question might possibly arise, and we should get rid of all the difficulties which this ordinance actually creates.
            </p>
            <p className="p-in-sp">
              I hope, sir, that in view of that precedent where precisely the same questions were considered, and when there was an abundance of time to consider them, the Convention will refuse to adopt this ordinance, and that the same course will be pursued with regard to all causes pending now before the late Federal Courts in Virginia, as was in the causes pending before the United States
              courts existing in Alexandria.
            </p>
            <p className="p-in-sp">If the Committee would frame their ordinance in conformity with that precedent, it would save us a great deal of time and trouble, and accomplish a great deal of good.</p>
            <p className="p-in-sp">
              It has just been suggested to put this query: What is the use of having united with the Confederate States, if we are still to attend to late Federal matters? The Congress of the Confederate States is now in session. Why not wait for the proper action on the part of that Congress, in order that they may provide, as they will ultimately have to do, for the decision of the very questions
              which may arise under this ordinance? They may provide for these in such a way as that we may transfer, by our action, all these causes now pending in the late Federal courts to the courts of the Confederate States; and they could then provide, without difficulty, for the officers in whose behalf an appeal is made here.
            </p>
            <p className="p-in-sp">Now, sir, we are in a condition where every man must be compelled to make sacrifices. Inconveniences must occur to all, and it is out of the question that our time should be taken up here in considering provision for sacrifices on the part of the citizens of the same State with us, and who are in the same boat with ourselves.</p>
            <div className="speaker" id="sp5654"><span className="persName">Mr. MORTON</span>—</div>
            <p className="p-in-sp">If the gentleman had such regard to economy as he professes to have, he would not have made so long a speech.</p>
            <p className="p-in-sp">I now renew my motion to lay the whole subject on the table.</p>
            <p>The motion was agreed to.</p>
            <div className="speaker" id="sp5655"><span className="persName">Mr. MORTON</span>—</div>
            <p className="p-in-sp">I now move to lay the resolution, reported by the special committee on the subject of Federal Courts, on the table.</p>
            <p>This motion was also agreed to.</p>
            <div className="speaker" id="sp5656"><span className="persName">Mr. RANDOLPH</span>—</div>
            <p className="p-in-sp">I move to take up the unfinished business of last <PageNumber num={757} /> night, which was "an ordinance prescribing the pay of the Provisional army and of the volunteer force of the State of Virginia."</p>
            <p>The motion was agreed to.</p>
            <p>Whereupon the substitute offered by <span className="persName">Mr. TYLER</span> was taken up and read as follows:</p>
            <p>1. Be it ordained, That the Major General and Commander of the military and naval force of the State of Virginia shall be entitled to receive the same pay, allowances and emoluments which are given to a Major General in the army of the United States.</p>
            <p>2. That the Major Generals holding rank under the Commanderin-Chief, shall be Major Generals by brevet, with the pay and emoluments which appertain to Major Generals when in actual command.</p>
            <p>3. The pay, emoluments and subsistence of the Provisional army and of the volunteer and militia in actual service, shall be the same with those of the army of the Confederate States.</p>
            <p>4. This ordinance shall be in force from its adoption, and be subject to amendment, modification or repeal by this Convention or by the General Assembly.</p>
            <div className="speaker" id="sp5657"><span className="persName">Mr. RANDOLPH</span>—</div>
            <p className="p-in-sp">I propose to strike out the second section and insert :</p>
            <p className="p-in-sp">"That the General and field officers of the volunteers shall not exceed in grade and numbers the General and field officers of the Provisional army."</p>
            <div className="speaker" id="sp5658"><span className="persName">Mr. EARLY</span>—</div>
            <p className="p-in-sp">That amendment, if adopted, must produce a good deal of inconvenience.</p>
            <p className="p-in-sp">
              I am, as I have before indicated, opposed to the appointment of too many general officers. That amendment provides that there shall be no more field officers of the volunteers than of the regular army. Now we have a large number of volunteers called out, which are variously estimated at from fifteen to twenty thousand. The ordinance for the Provisional army provides for twelve
              regiments, which will number about 10,000. This amendment provides that there shall be no more field officers of the volunteers than of the Provisional army. In that case we will have to discharge a good many of the volunteers now mustered into service.
            </p>
            <p className="p-in-sp">
              I do not think the ordinance provides for the pay of all the men that may be called into service. I have prepared an ordinance which I will read by way of argument, that I shall offer as a substitute for the whole. According to the provision for paying the officers of the United States army, they are allowed certain pay and emoluments in the way of rations, horses, servants and
              commutation pay for quarters, <PageNumber num={758} /> stationery, &amp;c. This is a very inconvenient mode of paying, and I know that the officers of the army have been anxious that some law might be passed giving them a certain sum and dispensing with these allowances. It is true that a Major General commanding the army of the United States was entitled
              to upwards of $7000; but a great deal of that, according to my view of it, was made up of the additional rations that were given for every five years service. I have an Almanac here containing the pay, rations and allowances to Major Generals in the United States army. It amounts to $469 a month, exclusive of the allowance for horses. The rule in the army is, when an officer is in the
              field to give him no commutation for quarters, for he is furnished with all the camp equipage necessary for him.
            </p>
            <p className="p-in-sp">The ordinance that I have drawn up, proposes to give to the Major General commanding the gross sum of $500 a month, which will be $6000 a year. That will be sufficient, I think, to meet all his wants; it will be certainly as much as we will be able to pay.</p>
            <p className="p-in-sp">The second section provides that the pay of any other Major General called into service, shall be $300 per month, which will amount to $3,600 a year. We have already three Major Generals in service, and it is for them that this is intended to provide.</p>
            <p className="p-in-sp">This ordinance makes no provision for the officers of the Adjutant General's Department, the Quartermaster's Department, the Commissariat Department and the Medical Department. The rule in regard to these officers is, that they shall have the same pay as officers of the same grade in the Cavalry.</p>
            <p className="p-in-sp">There is another thing. The regular troops are furnished with clothing. The volunteers and militia are paid commutation for clothing; and I have drawn up a section providing that instead of clothing they should be given commutation for clothing, according to the rule governing the Confederate States army.</p>
            <p className="p-in-sp">This comprises the important features of the ordinance which I have in my hand; and I propose to offer it as a substitute for the whole when it is in order to do so.</p>
            <div className="speaker" id="sp5659"><span className="persName">Mr. WILSON</span>—</div>
            <p className="p-in-sp">I move the previous question.</p>
            <p>The call was sustained and the main question ordered to be put, which was upon the amendment offered by <span className="persName">Mr. RANDOLPH</span> to the second section of <span className="persName">Mr. TYLER</span>'S substitute.</p>
            <p><span className="persName">Mr. TYLER</span> accepted the amendment, and it was therefore agreed to.</p>
            <div className="speaker" id="sp5660"><span className="persName">Mr. RANDOLPH</span>—</div>
            <p className="p-in-sp">In order to complete the amendment just agreed to, there must be grades in the Confederate States service which are not in ours.</p>
            <PageNumber num={759} />
            <p className="p-in-sp">I propose to add this to the second section: "If there be any grade in the Provisional army or the volunteers and militia herein provided for, the Governor, by and with the advice of his council, may prescribe the pay for such grade until further provision be made therefor."</p>
            <div className="speaker" id="sp5661"><span className="persName">Mr. HAYMOND</span>—</div>
            <p className="p-in-sp">I demand the previous question.</p>
            <p>The demand was sustained, and the main question being put, it was decided in the affirmative.</p>
            <p><span className="persName">Mr. EARLY</span> here offered the substitute, the provisions of which are given above.</p>
            <div className="speaker" id="sp5662"><span className="persName">Mr. SCOTT</span>, of Powhatan—</div>
            <p className="p-in-sp">I shall support that proposition in preference to the substitute of the gentleman from Charles City [<span className="persName">Mr. TYLER</span>]
              , as it has been amended. That substitute seems to me to be almost absurd. The ordinance organizing the Provisional Army proposes that it shall number about 10,000 men. The substitute of the gentleman from Charles City, as amended by the gentleman from Richmond, declares that there shall be no more field and general officers for volunteers than for the regular army. According to that
              substitute, no more officers can be furnished for a force of 20,000 men, if so many were called into the field, than will be allowed for the regular army, which is to consist of 10,000 men.
            </p>
            <p className="p-in-sp">I shall vote for the substitute of the gentleman from Franklin [Mr. EARLY] .</p>
            <div className="speaker" id="sp5663"><span className="persName">Mr. DULANY</span>, of Fairfax—</div>
            <p className="p-in-sp">I call the previous question.</p>
            <div className="speaker" id="sp5664"><span className="persName">Mr. RANDOLPH</span>—</div>
            <p className="p-in-sp">If the gentleman withdraws the call for a moment I shall renew it.</p>
            <div className="speaker" id="sp5665"><span className="persName">Mr. DULANY</span>—</div>
            <p className="p-in-sp">I withdraw it.</p>
            <div className="speaker" id="sp5666"><span className="persName">Mr. RANDOLPH</span></div>
            <p className="p-in-sp">-We have provided for eight Generals, twenty-eight Colonels and twenty-eight Majors, which is but a mere temporary provision until this Convention meets in June. And yet, the gentleman from Powhatan [<span className="persName">Mr. SCOTT</span>] , does not think that number enough until the month of June.</p>
            <div className="speaker" id="sp5667"><span className="persName">Mr. SCOTT</span>—</div>
            <p className="p-in-sp">That ordinance does not provide this number of officers until June.</p>
            <div className="speaker" id="sp5668"><span className="persName">Mr. RANDOLPH</span>—</div>
            <p className="p-in-sp">
              Cannot the Convention make any change they deem necessary, when they meet here in June? All I want is to guard against multiplication of officers until we can meet and determine what our course shall be in future. We have provided officers for twenty-four regiments, and that, I think, will be a larger army than Napoleon has in proportion to his population. I think that will do at least
              as a temporary provision until this Convention can meet again.
            </p>
            <p className="p-in-sp">Gentlemen may talk about putting 50,000 or 60,000 in the field.</p>
            <PageNumber num={760} />
            <p className="p-in-sp">If you put them in the field, they will be cumbersome and inefficient at least for the present. We do not want a larger army than we have officers for.</p>
            <p className="p-in-sp">I call the previous question, as I promised the gentleman from Fairfax [<span className="persName">Mr. DULANY</span>] , I would do.</p>
            <p>The call was sustained, and the main question being put, which was upon the substitute offered by the gentleman from Franklin [<span className="persName">Mr. EARLY</span>] , it was decided in the negative.</p>
            <p>The question then recurred upon the adoption of the substitute offered by <span className="persName">Mr. TYLER</span>, as amended.</p>
            <div className="speaker" id="sp5669"><span className="persName">Mr. EARLY</span>—</div>
            <p className="p-in-sp">I feel compelled to move that this subject be recommitted. I want to state briefly the reason for that motion.</p>
            <p className="p-in-sp">The substitute of the gentleman from Charles City [<span className="persName">Mr. TYLER</span>]
              , provides for the pay of the Provisional army, the militia and volunteers. We have adopted an ordinance that provides for an Adjutant General's Department, a Quartermaster's Department, a Commissary Department and a Medical Department for the whole troops. We have fixed the grades of the officers. If the pay of these men shall be according to the army of the Confederate States, we
              have no data here to go by. The Adjutant General of the Confederate army, I believe, is a Brigadier General. Our Adjutant General is a Colonel; our Commissary General and our Quartermaster General is a Colonel. How are they to be paid? We have the rank, but how is the pay to be determined? I provided, in my substitute, that they should be paid in the same way as the cavalry officers of
              the same grade. Now, I have as much desire to cut off the General officers of all services as any body else. I think that four Brigadier Generals for the Provisional army is enough; but when we have a large volunteer force in the field, consisting of over 20,000 men, to restrict them to the same number of the Provisional with only 10,000 men, is not at all reasonable.<span
                className="note"
                id="Note71"
              ><span className="noteLabel">1</span>Mr. Early may have said "as the Provisional Army."</span
              >
            </p>
            <div className="speaker" id="sp5670"><span className="persName">Mr. RANDOLPH</span>—</div>
            <p className="p-in-sp">Do I understand the gentleman as stating that we have made no provision for the staff corps?</p>
            <div className="speaker" id="sp5671"><span className="persName">Mr. EARLY</span>—</div>
            <p className="p-in-sp">I do not think there is any provision made for that corps.</p>
            <div className="speaker" id="sp5672"><span className="persName">Mr. RANDOLPH</span>—</div>
            <p className="p-in-sp">It provides that the staff corps shall receive the same pay provided for in the Confederate States army.</p>
            <div className="speaker" id="sp5673"><span className="persName">Mr. EARLY</span>—</div>
            <p className="p-in-sp">But ours are a staff only for the State.</p>
            <div className="speaker" id="sp5674"><span className="persName">Mr. RANDOLPH</span>—</div>
            <p className="p-in-sp">Be that as it may, if the staff are attached to no army, they receive no pay. If they are attached to an army, that army must be either regulars, militia or volunteers, and we provide for all of them.</p>
            <PageNumber num={761} />
            <div className="speaker" id="sp5675"><span className="persName">Mr. FRENCH</span>, of Mercer—</div>
            <p className="p-in-sp">It seems these military gentlemen cannot agree among themselves, and I think the best course to pursue is to recommit the whole subject. I make that motion.</p>
            <p>The motion was rejected.</p>
            <p>The question then recurred upon the adoption of the substitute of <span className="persName">Mr. TYLER</span>, as amended.</p>
            <div className="speaker" id="sp5676"><span className="persName">Mr. STAPLES</span>, of Patrick—</div>
            <p className="p-in-sp">I demand the previous question.</p>
            <p>The demand was sustained, and the main question was ordered to be put.</p>
            <div className="speaker" id="sp5677"><span className="persName">Mr. SHEFFEY</span>—</div>
            <p className="p-in-sp">I rise for the purpose of inquiring what will be the effect of voting down the substitute?</p>
            <div className="speaker" id="sp5678"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">It will bring the original ordinance before the Convention.</p>
            <p>The question was then put upon the adoption of the substitute, and it was decided in the affirmative.</p>
            <p>The following is the ordinance perfected :</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>Be it ordained, That the Major General and Commander of the military and naval forces of the State of Virginia shall be entitled to receive the same pay, allowances and emoluments which are given to a Major General in the army of the United States.</p>
                <p>2. That the general and field officers of the volunteers shall not exceed in grade the general and field officers of the Provisional Army.</p>
                <p>
                  3. The pay, emoluments and subsistence of the Provisional Army, and of the volunteers and militia in actual service, shall be the same with those of the army of the Confederate States. If there be any grade in the Provisional Army, or the volunteers and militia herein provided for, the Governor, by and with the advice of his Council, may prescribe the pay for such grade until
                  further provision be made therefor.
                </p>
                <p>4. This ordinance shall be in force from its adoption, and subject to amendment, modification or repeal by this Convention or by the General Assembly.</p>
              </div>
            </div>
            <div className="speaker" id="sp5679"><span className="persName">Mr. HAYMOND</span>—</div>
            <p className="p-in-sp">The Committee on Military Affairs reported an ordinance a few days ago in relation to the regulations and articles of war for our army. I move that that ordinance be taken up.</p>
            <p>The motion was agreed to.</p>
            <p>The ordinance reads :</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>AN ORDINANCE establishing Rules and Articles for the Government of the Armies of the State of Virginia.</p>
                <p>Be it ordained by the Convention of Virginia, That from and after <PageNumber num={762} /> the passing of this ordinance the Volunteers in actual service and the Provisional Army shall be governed by the Rules and Articles of War now in force in the Army of the United States, amended as follows:</p>
                <p>Insert "the State of Virginia," instead of "the United States of America or the United States;" "the Governor of Virginia," instead of "the President of the United States;" "the Commander-in-Chief," instead of "the Secretary of War;" "the office of the Adjutant General," instead of "Department of war," or "Office of the Department of War," wherever the latter phases occur.</p>
                <p>In Article V. strike out "the President of the United States, the Vice President thereof, against the Congress of the United States, or against the Chief Magistrate, or Legislature of any of the United States in which he may be quartered," and insert "the Governor, Lieutenant Governor, Convention and Legislature of Virginia."</p>
                <p>In Article X. strike out "them," in the phrase "I will serve them," and insert "the State of Virginia." Strike out "their," in the phrase "their enemies," and insert "her."</p>
                <p>In Article XXXIV. strike out "in the State or Territory where such regiment shall be stationed."</p>
                <p>In article LI. strike out "employed in any ports out of the United States."</p>
                <p>In Article LXXXVII. strike out "nor shall more than fifty lashes be inflicted on any offender at the discretion of a court martial," and insert "no corporeal punishment by stripes or lashes shall be inflicted."</p>
                <p>In Article XCVII. strike out the phrase beginning "save only" and all that follows.</p>
                <p>Article XCVIII to be omitted and the numbers of the articles following to be reduced accordingly.</p>
                <p>In Article CI. omit 3d, 4th, 6th, 8th and 9th paragraphs, and add to the article the following paragraph: "The Governor, by and with the advice of his Council, may appoint from the army a suitable person as Judge Advocate of the Army, who shall have the brevet rank, pay and emoluments of a Major of Cavalry."</p>
              </div>
            </div>
            <div className="speaker" id="sp5680"><span className="persName">Mr. HAYMOND</span>—</div>
            <p className="p-in-sp">I will remark that the articles of war reported by the Committee are exactly those adopted by the Congress of the United States for the government of the army of the United States, with only such alterations as are necessary to adapt them to our new government.</p>
            <p>This ordinance was adopted.</p>
            <div className="speaker" id="sp5681"><span className="persName">Mr. SCOTT</span>, of Powhatan—</div>
            <p className="p-in-sp">I beg leave to report the following ordinance from the Committee on Military Affairs:</p>
            <PageNumber num={763} />
            <div className="floatingText">
              <div className="floatingText_body">
                <p>AN ORDINANCE in regard to the exportation of Breadstuffs.</p>
                <p>"Be it ordained by the Convention, That the Governor, by and with the advice of the Council, may, whenever, in his opinion, the exigencies of the public service may require, prohibit the exportation of bread-stuffs and provisions of every character from all or any of the ports and places in this State."</p>
              </div>
            </div>
            <p>This ordinance was adopted.</p>
            <div className="speaker" id="sp5682"><span className="persName">Mr. SCOTT</span>—</div>
            <p className="p-in-sp">I also report the following ordinance from the same committee:</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>AN ORDINANCE concerning telegraphic lines.</p>
                <p>"Be it ordained, That the Governor be and he is hereby authorized and empowered to take possession and control of all or any telegraphic lines operating in this Commonwealth whenever, in his opinion, the public interest may require it."</p>
              </div>
            </div>
            <p>This ordinance was adopted.</p>
            <div className="speaker" id="sp5683"><span className="persName">Mr. SCOTT</span>—</div>
            <p className="p-in-sp">I beg leave also to report from the same committee the following ordinance:</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>AN ORDINANCE relating to the Medical Department, the Adjutant General's Department and the Ordnance Department.</p>
                <p>
                  "Be it ordained, That regulations prescribing the duties of the Surgeon General, the Surgeons, Assistant Surgeons and all employees in the Medical Department, and for the efficient organization of government under said department, including the mode of making purchases and providing stores, shall be adopted by the Governor and his Advisory Council, and the Governor, by and with the
                  advice and consent of the said Council, may appoint as many additional assistant surgeons in the said department as may be necessary; and, whenever it is proper, physicians may be temporarily employed under such rules as may be prescribed by the Governor and his Advisory Council.
                </p>
                <p>Be it further ordained, That all orders for calling out volunteers and the militia for active service and for arming the same shall be issued through the office of the Adjutant General for the troops in active service.</p>
                <p>Be it further ordained, That the Ordnance Department, organized under the provision of an act of the General Assembly, heretofore passed, be and the same is hereby placed under the control of the Major General commanding the military and naval forces, subject, however, to the constitutional powers of the Governor.</p>
                <p>This ordinance shall be in force from its passage."</p>
              </div>
            </div>
            <PageNumber num={764} />
            <p>This ordinance was adopted.</p>
            <div className="speaker" id="sp5684"><span className="persName">Mr. SCOTT</span>, of Fauquier—</div>
            <p className="p-in-sp">I beg leave to call to the attention of the Convention an ordinance which I prepared this morning after consultation with Col. Smith.</p>
            <p className="p-in-sp">
              We have now in the State a small number of infant manufacturers engaged in the manufacture of cotton and woolen goods necessary for furnishing not only that portion of our people who are engaged in the civil walks of life, but those who will be called to the duties of the camp, that have in their employment various employees who are subject to military duty; and I received a letter
              this morning on behalf of two manufacturers in the neighborhood of my residence, stating that some of the master mechanics in whose absence the business of their factories could not proceed, were members of volunteer companies, and liable to be called into military service.
            </p>
            <p className="p-in-sp">I propose to the Convention the adoption of the following ordinance:</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>AN ORDINANCE concerning persons exempt from Military Duty.</p>
                <p>
                  Be it ordained, That all persons necessarily and personally employed in any factory for the manufacture of arms, munitions of war, shoes, cotton or woolen goods, or any other thing deemed necessary for the defence of the State, shall be exempt from the performance of military duty, unless drafted and detailed for actual service by order of the Governor, until otherwise provided by
                  law.
                </p>
              </div>
            </div>
            <div className="speaker" id="sp5685"><span className="persName">Mr. MONTAGUE</span>—</div>
            <p className="p-in-sp">I would suggest to the gentleman to include persons engaged in the manufacture of leather. There are several engaged in that business in the State, and leather is an article that will be much needed.</p>
            <p className="p-in-sp">I received a letter yesterday from a gentleman from Gloucester, who has several engaged in that business, and if they shall be compelled to go into service, the manufacture must cease.</p>
            <p>Manufacturers of leather were included, at the suggestion of Mr. Montague; whereupon, the ordinance was adopted.</p>
            <div className="speaker" id="sp5686"><span className="persName">Mr. MONTAGUE</span>—</div>
            <p className="p-in-sp">I beg leave to offer the following ordinance:</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>"An ordinance, to release the officers, civil and military, and the citizens generally of the State of Virginia, from all obligation to support the Constitution of the late Confederacy, known as the United States of America."</p>
                <p>
                  Be it ordained, That all officers, civil and military, and the people generally of this State, be and they are hereby released from any and all oaths which they may have taken to support the Constitution of the late Confederacy, known as the United States of America, and <PageNumber num={765} /> that the said oaths and the said Constitution are
                  inoperative and void, and of no effect, and that the eleventh section of chapter thirteen of the Code of Virginia, 1860, be and is hereby repealed.
                </p>
              </div>
            </div>
            <div className="speaker" id="sp5687"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">The Chair begs leave to lay before the Convention a communication from the Governor, giving information as to how many men he has called into service, &amp;c.</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>
                  Resolved, That the Governor be requested to report to the Convention, as soon as practicable, how many volunteers have been called into the service under the ordinance adopted on the 17th of this month; and what general and field officers have been appointed for the same. He is also requested to report whether any portion of the militia of the line has been ordered into the
                  service, and what general officers of the militia have been placed in command of any portion of the troops in actual service. He is also requested to report whether he has called upon the Governor of any of the slave States for troops to come to the assistance of this State.
                </p>
                <p>Adopted, by the Convention of Virginia, April 30, 1861.</p>
                <p>JOHN L. EUBANK,</p>
                <p>Secretary of Convention.</p>
                <p>EXECUTIVE DEPARTMENT, May 1, 1861. Gentlemen of the Convention:</p>
                <p>In obedience to the above resolution, I herewith transmit documents, embodying all the information which I have on the subjects referred to.</p>
                <p>Respectfully,</p>
                <p>JOHN LETCHER.</p>
                <p>List of all Commissions issued above rank of Captain.</p>
                <p>Robert E. Lee, Major General Commanding.</p>
                <p>Joseph E. Johnston, Major General.</p>
                <p>Walter Gwynn, do.</p>
                <p>Philip St. George Cocke, Brigadier General.</p>
                <p>Daniel Ruggles, do.</p>
                <p>Robert S. Garnett, Adjutant General.</p>
                <p>James F. Preston, Colonel Volunteers.</p>
                <p>John B. Magruder, Colonel Volunteers.</p>
                <p>Chas. Bell Gibson, Colonel, Surgeon General.</p>
                <p>John B. Baldwin, do. Inspector General.</p>
                <PageNumber num={766} />
                <p>Daniel A. Langhorne, Colonel Volunteers.</p>
                <p>Thos. J. Jackson, do.</p>
                <p>William Gilham, do.</p>
                <p>Henry Heth, Lieutenant Colonel Volunteers.</p>
                <p>Richard S. Ewell, do.</p>
                <p>Joseph Selden, do.</p>
                <p>Samuel Jones, do.</p>
                <p>J. C. Pemberton, do.</p>
                <p>William Mahone, do.</p>
                <p>John McCausland, do.</p>
                <p>Robert H. Chilton, do.</p>
                <p>A. S. Taylor do.</p>
                <p>James R. Crenshaw, Maj. Vols. Com. Dep't.</p>
                <p>George C. Hutter, do. Paymaster.</p>
                <p>Chas. H. Smith, do. Surgeon.</p>
                <p>Eugene McLean, do. Quartermaster.</p>
                <p>Robert Johnson, do.</p>
                <p>Charles B. Ball, do. Paymaster.</p>
                <p>B. F. Ficklin, do. Quartermaster.</p>
                <p>M. G. Harman, do.</p>
                <p>P. R. Page, do.</p>
                <p>J. P. Wilson, do.</p>
                <p>Alonzo Loring, do.</p>
                <p>F. M. Boykin, Jr.. do.</p>
                <p>Cornelius Boyle, do.</p>
                <p>HEADQUARTERS VIRGINIA FORCES, Richmond, Virginia, April 30th, 1861.</p>
                <p>
                  SIR-I have the honor to acknowledge the receipt of your note of this date, and to state, in reply, that so far I have only received returns from Harper's Ferry, which I enclose.<span className="note" id="Note72"
                  ><span className="noteLabel">2</span>The Morning Report which General Lee enclosed is reproduced on p. 755, with the tabular arrangement altered to make the Report fit the page.</span
                  >
                  I enclose also an order on the subject, under which I expect, in a few days, to have returns from all points. When received, I shall take pleasure in submitting a consolidated return of them. I am, sir, very respectfully, your ob't servant,
                </p>
                <p>R. E. LEE,</p>
                <p>Major General Commanding.</p>
                <p>His Excellency JOHN LETCHER, Gov. of Va.</p>
                <PageNumber num={767} />
                <p>MORNING REPORT of the 3d DIVISION ARMY OF VIRGINIA,</p>
                <p>Commanded by Maj. General Harper</p>
                <p>Exclusive of the General Staff, which numbers 13 in all, with attaches</p>
                <p>GENERAL STAFF</p>
                <p>13TH BRIGADE-Brig. Gen. Wm. H. Harman, commanding</p>
                <p>7TH BRIGADE-Brig. Gen. G. G. Meem, commanding</p>
                <p>16TH BRIGADE-Brig. Gen. J. H. Carson, commanding</p>
                <p>STATION-Harper's Ferry, Jefferson county,</p>
                <p>Va. DATE-April 25th, 1861.</p>
                <p>BOLIVAR CHRISTIAN, Adjutant General, Division.</p>
                <p>KENTON HARPER, Major General, Commanding Division.</p>
                <PageNumber num={768} />
                <p>The following is the order referred to in General Lee's communication given above:</p>
                <p>HEADQUARTERS VIRGINIA FORCES,</p>
                <p>Richmond, Virginia, April 29, 1861.</p>
                <p>General Orders, No. 4:</p>
                <p>1. The General or other officers, commanding Virginia Forces, at Richmond, Norfolk, Fredericksburg, Alexandria and Harper's Ferry, and such other points as they may hereafter be sent in separate commands, are required to submit to this office returns of their respective commands, once in ten days, commencing on the first day of each month.</p>
                <p>2. The attention of all officers of the Virginia Volunteers is called to the regulation concerning military correspondence, as laid down in the army regulations of the late United States. Edition of 1857.</p>
                <p>By command of Maj. Gen. Lee,</p>
                <p>R. S. GARNETT, Adjutant General.</p>
                <p>CHARLESTON, S. C., April 16th, 1861.</p>
                <p>To GOVERNOR LETCHER:</p>
                <p>Gov. Pickens authorizes me to say to you, that in forty-eight [48] hours he can put two thousand [2,000] troops in Norfolk at your disposal. Answer at once, if you desire them.</p>
                <p>ROGER A. PRYOR.</p>
                <p>ANSWER</p>
                <p>The State is in a condition that justifies me in accepting your tender of troops. We shall have need for all we can command, if the rumors are to be relied upon.</p>
                <p>JOHN LETCHER.</p>
                <p>To Gov. PICKENS, Charleston, S. C. April 18th, 1861.</p>
              </div>
            </div>
            <div className="speaker" id="sp5688"><span className="persName">Mr. RANDOLPH</span>—</div>
            <p className="p-in-sp">I move that these communications be laid upon the table.</p>
            <p>The motion was agreed to.</p>
            <div className="speaker" id="sp5689"><span className="persName">Mr. RANDOLPH</span>—</div>
            <p className="p-in-sp">The Governor and Council desire a very slight amendment to be made in the ordinance defining their duties.</p>
            <p className="p-in-sp">There are a great number of acts, such as issuing proclamations for escaped convicts, in reference to which the Governor cannot be expected to consult the Council.</p>
            <PageNumber num={769} />
            <p className="p-in-sp">I propose to add to the first section of the ordinance, by adding thereto the words-"growing out of the said emergency," so that that section will read :</p>
            <p className="p-in-sp">
              1. Be it ordained, That in the appointment of a Council of Five by the Convention, upon the nomination of the Governor, to aid, counsel, and advise him in the exercise of his executive authority in the present emergency, it was not the purpose or the intention of the Convention to confer on the Council the power to control the Governor in the exercise of his executive functions; but it
              was their purpose to impose upon the Governor the obligation to consult the Council in regard to all executive acts growing out of said emergency.
            </p>
            <p className="p-in-sp">In the second section the Governor was required to keep a regular journal of all his proceedings.</p>
            <p className="p-in-sp">He already does that under an act of the Legislature. That is kept by the Secretary of the Commonwealth. I propose, therefore, that the Advisory Council only be required to keep a journal of their proceedings.</p>
            <div className="speaker" id="sp5690"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">It will require a motion to reconsider the former ordinance before an amendment can be made.</p>
            <div className="speaker" id="sp5691"><span className="persName">Mr. RANDOLPH</span>—</div>
            <p className="p-in-sp">I move to reconsider the vote adopting on the 23d of last month, the ordinance defining the duties of the Advisory Council.</p>
            <p>The motion was agreed to, whereupon <span className="persName">Mr. RANDOLPH</span> offered the amendment to the first section, as given above, and it was adopted.</p>
            <div className="speaker" id="sp5692"><span className="persName">Mr. RANDOLPH</span>—</div>
            <p className="p-in-sp">I now propose to amend the second section, which reads, at present: "That a regular journal of the Executive proceedings and acts should be kept, in which should be recorded all appointments and proceedings of the Executive, and the votes of the several councillors thereupon."</p>
            <p className="p-in-sp">I propose to amend that section by inserting the following in lieu of it:</p>
            <p className="p-in-sp">"That a regular journal of the acts of the Council shall be kept, in which shall be recorded all appointments and the votes of the several councillors thereupon."</p>
            <div className="speaker" id="sp5693"><span className="persName">Mr. ECHOLS</span>—</div>
            <p className="p-in-sp">It will be seen that this ordinance, as proposed by the gentleman from Richmond [<span className="persName">Mr. RANDOLPH</span>] , will facilitate business very much for the Governor.</p>
            <p className="p-in-sp">
              Under the ordinance already adopted, the Governor is compelled to communicate to the Council every act, however trivial, and consult them upon it. It is in order to dispense with that necessity that the <PageNumber num={770} /> amendment of the gentleman from Richmond has been proposed. The latter amendment explains itself sufficiently, and needs no
              remark from me.
            </p>
            <p>The amendment was agreed to.</p>
            <div className="speaker" id="sp5694"><span className="persName">Mr. WILSON</span>—</div>
            <p className="p-in-sp">In the present troubled condition of the country, I deem it important for Virginia to confer with the border slave States that have not severed their connection with the Federal Union.</p>
            <p className="p-in-sp">
              I propose, with a view to bring about a conference between Virginia and these States, to offer an ordinance somewhat different from that offered on yesterday. The object of my proposition is to send one Commissioner to each of the border slave States. It is very well known that there is now a very high state of excitement in the State of Kentucky. We believe that it is the duty of that
              State to follow the example of Virginia, and sever her connection with the Federal Union. We believe that any communication addressed by you to these States, cannot have the same influence upon these States as would the sending of a Commissioner to each, who will appear in person and communicate with the Legislatures of these States in the form of an address.
            </p>
            <p className="p-in-sp">
              I therefore propose to offer an ordinance appointing a Commissioner to each State; but I must request that I shall not be among these Commissioners. I very well know that my youth and inexperience disqualify me from performing that high trust. I feel, however, that it is very important that Virginia should now communicate with those States, and try to influence them to unite with us.
            </p>
            <p>The following is the ordinance:</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>
                  "Be it ordained, That this Convention appoint one Commissioner to each of the States of Maryland, Delaware, Kentucky, Tennessee, North Carolina, Arkansas and Missouri, to communicate to the authorities of these States respectively, the Ordinance of Secession adopted by this Convention, as also the ordinance adopting the Provisional Government of the Confederate States, and to
                  invite each of those States to unite with us."
                </p>
              </div>
            </div>
            <div className="speaker" id="sp5695"><span className="persName">Mr. WILSON</span>—</div>
            <p className="p-in-sp">
              We see that there has been studied efforts on the part of the General Government to overawe the State of Maryland. Their General Assembly has been driven from Annapolis, the capital of the State, and compelled to meet at Frederick. I believe that a majority of the free white citizens of Maryland prefer separation from the Federal Government and a union with us. And the same may safely
              be said of Missouri, Kentucky, and the other border slave States.
            </p>
            <PageNumber num={771} />
            <p className="p-in-sp">
              We know that if prominent influential men of Virginia are selected to go on this mission, they will influence these to unite with Virginia, and make common cause with her. I know that there are gentlemen here who would exercise a powerful influence on the State of Maryland, while there are others who exercise great influence upon Kentucky. I trust it will be the pleasure of the
              Convention to adopt this ordinance.
            </p>
            <div className="speaker" id="sp5696"><span className="persName">Mr. HARVIE</span>—</div>
            <p className="p-in-sp">We passed upon a proposition similar to this on yesterday, and from the result it was evident that the Convention was not favorable to the scheme. I take it for granted this ordinance will be voted down as was that on yesterday.</p>
            <p className="p-in-sp">
              We have had Commissioners here from some of the Southern States, all of whom comprised men of the first standing in their respective States; and what did they accomplish? Nothing, literally nothing. Their communications fell still-born upon the members of this Convention. They led to no action on the part of this body; and I presume the effect of a communication from us to the border
              slave States-I care not how influential the medium may be through which it might be made-would be no better. We have taken our position despite all the difficulties that surrounded us, and I see no stronger stimulant to induce similar action on their part than is furnished in that circumstance. This, it seems to me, is calculated to have more influence upon these States than any mere
              formal invitation that we could now extend to them. They know full well that we are anxious for their Union with us in this movement-they know that before the passage of the ordinance of secession-a result brought about by stern necessity -we were considering the best means to induce them to co-operate with us; they know that while we were endeavoring to get them to confer with us, we
              were forced to choose between an attitude of base submission or independence.
            </p>
            <p className="p-in-sp">
              I have suggested the adoption of a resolution which, in my judgment, will effect more than speeches, to communicate formally, by letter, through the President of the Convention, the action of this State, with a request that these States would co-operate with us, and give their aid in the future struggle. This will only cost the State the postage upon the letters. It does seem to me
              that, in negotiations of this or any other character, we should pay due regard to the outlay which the sending of so many commissioners must involve, especially in this instance, where no hope of a successful issue is, or, at least, can be entertained. Unless I can be convinced that this movement holds out any prospect of a good result, I must vote against it.
            </p>
            <PageNumber num={772} />
            <p className="p-in-sp">So far, I see no earthly good that can come out of it, and I hope this ordinance will be rejected.</p>
            <div className="speaker" id="sp5697"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">The Chair will remark, that by a rule of the House, a question once determined, shall remain as the judgment of the Convention, and as the Chair understands, whilst there may be some trivial difference between this and the proposition rejected on yesterday, it is in effect the same question.</p>
            <p className="p-in-sp">The decision of the Chair is, that it is out of order.</p>
            <div className="speaker" id="sp5698"><span className="persName">Mr. AMBLER</span>—</div>
            <p className="p-in-sp">I offer the following resolution:</p>
            <p className="p-in-sp">Resolved, That Messrs. Randolph, Macfarland and Montague be appointed a committee to examine the ordinances, proceedings and debates of this Convention from which the seal of secrecy has not been removed, and allow such of said ordinances, proceedings and debates, as in their opinion may be compatible with the public interests, to be published.</p>
            <div className="speaker" id="sp5699"><span className="persName">Mr. MONTAGUE</span>—</div>
            <p className="p-in-sp">When does the gentleman expect this service to be performed?</p>
            <div className="speaker" id="sp5700"><span className="persName">Mr. MOFFETT</span>—</div>
            <p className="p-in-sp">I offer the following as an amendment to that resolution:</p>
            <p className="p-in-sp">Resolved, That the injunction of secrecy be removed so far as to authorize the publication of the ordinances, proceedings and debates of this Convention.</p>
            <div className="speaker" id="sp5701"><span className="persName">Mr. AMBLER</span>—</div>
            <p className="p-in-sp">
              One difficulty occurs to me with regard to that resolution. We have received a report this morning from the Major-General Commanding, as to the actual force at Harper's Ferry. That is a point that ought to be kept secret. Under the resolution of the gentleman, that would be made public. It is absolutely necessary that we should have a committee to examine everything that has transpired
              here, and strike out what would be injurious to the Commonwealth, and give to the public what would not be injurious to the public interest.
            </p>
            <p className="p-in-sp">It has been suggested to me that the gentleman from Middlesex [<span className="persName">Mr. MONTAGUE</span>] , is engaged in the Advisory Council, and cannot, therefore, attend to the duty which this resolution imposes. I would ask leave to substitute <span className="persName">Mr. JOHNSON</span>, of Richmond for <span className="persName">Mr. MONTAGUE</span>.</p>
            <div className="speaker" id="sp5702"><span className="persName">Mr. EARLY</span>—</div>
            <p className="p-in-sp">I hope the resolution offered by the gentleman from Rappahannock [<span className="persName">Mr. MOFFETT</span>] , will be adopted. When we all go home now we will not have the privilege to explain to our constituents <PageNumber num={773} /> what has transpired here, and we will thus be placed in a peculiar predicament.</p>
            <p className="p-in-sp">Now, I see no objection to publishing what has transpired here this morning in regard to Harper's Ferry. The government at Washington will very soon ascertain all about the state of affairs at that point.</p>
            <div className="speaker" id="sp5703"><span className="persName">Mr. AMBLER</span>—</div>
            <p className="p-in-sp">It strikes me that that would be a very unwise course to pursue when we know that an effort is being made by our enemies to ascertain the exact strength of our forces there.</p>
            <div className="speaker" id="sp5704"><span className="persName">Mr. EARLY</span>—</div>
            <p className="p-in-sp">We are only furnished with the number there when the report was made out, and this number will of course increase with the necessity to have additional troops at that point. There are no doubt plenty in the employment of the United States Government, who would give full information on the subject to that government.</p>
            <div className="speaker" id="sp5705"><span className="persName">Mr. AMBLER</span>—</div>
            <p className="p-in-sp">In answer to that, I would state that there is a gentleman just behind me who has lately arrived from Harper's Ferry, and he believes that the actual number there is three times what it really is, and that impression, he says, very generally prevails.</p>
            <div className="speaker" id="sp5706"><span className="persName">Mr. EARLY</span>—</div>
            <p className="p-in-sp">
              Still I do not see any objection to publishing that return, inasmuch as the public will be in possession of the true state of things, while, by suppressing it, we cannot withhold from the enemy the information they seek to obtain on that head. If we give to a committee the privilege of publishing whatever they may think proper, why, according to their views, they may make public what
              is most necessary to be suppressed. When we return home to our constituents what condition will we be in when our lips are sealed in regard to all that has transpired here?
            </p>
            <p className="p-in-sp">I trust the amendment will be adopted.</p>
            <div className="speaker" id="sp5707"><span className="persName">Mr. SHEFFEY</span>—</div>
            <p className="p-in-sp">I move to amend the amendment by striking out the word "debates," so as to limit the publication to the ordinances and proceedings.</p>
            <p className="p-in-sp">I think it would be committing to this committee an unlimited power to say that my remarks shall be excluded and the remarks of other gentlemen published, or that my remarks shall be published and the remarks of other gentlemen excluded.</p>
            <p className="p-in-sp">I move, therefore, to strike out the word "debates," and leave the contract with the Editors of the Richmond "Enquirer" for the publication of the debates as it stands. We have no right to interfere with that contract, and commit to a committee the power to absolve us from that contract.</p>
            <div className="speaker" id="sp5708"><span className="persName">Mr. BRANCH</span>—</div>
            <p className="p-in-sp">I approve the resolution of the gentleman from <PageNumber num={774} /> Louisa [<span className="persName">Mr. AMBLER</span>] , and of the amendment of the gentleman from Smythe; and I now call the previous question.</p>
            <p>The call was sustained and the main question ordered, which was upon the amendment offered by <span className="persName">Mr. SHEFFEY</span> ; and the vote being taken upon it, was decided in the affirmative.</p>
            <p>The question then recurred upon the adoption of the amendment as amended.</p>
            <div className="speaker" id="sp5709"><span className="persName">Mr. AMBLER</span>—</div>
            <p className="p-in-sp">
              I will call the attention of the Convention to one fact. It is but an act of justice to those who are in a minority here, that the fact should be called to the attention of the Convention, that the minority have been refused the privilege of having announced to the public the cause of their opposition to the course which the majority have taken. Now, this majority denies to the
              minority the poor privilege of letting the public know upon what ground and under what circumstances they opposed the action of this majority. You have kept this iron rule upon us from the time we came into this Convention to this very hour; and now, when we ask you to let the people of Virginia know what we have done here, you say that all that is to be given to the public is the bare
              proceedings of the Convention, and we must go before our people without having the power of vindicating our course here, by the publication of the debates, which would show the reasons for our action.
            </p>
            <div className="speaker" id="sp5710"><span className="persName">Mr. JOHNSON</span>—</div>
            <p className="p-in-sp">Does the gentleman know who voted with the majority and who voted with the minority in the vote to which he alludes?</p>
            <div className="speaker" id="sp5711"><span className="persName">Mr. AMBLER</span>—</div>
            <p className="p-in-sp">I don't know. Whoever voted against this privilege, did us a great wrong, in my opinion.</p>
            <div className="speaker" id="sp5712"><span className="persName">Mr. SHEFFEY</span>—</div>
            <p className="p-in-sp">I desire to know whether he considers me in the majority or minority? The debates and votes of each member will be published in due time, I imagine.</p>
            <div className="speaker" id="sp5713"><span className="persName">Mr. JOHNSON</span>—</div>
            <p className="p-in-sp">I beg leave to say that the amendment that was offered, was offered by one of the strongest secessionists in the Convention.</p>
            <div className="speaker" id="sp5714"><span className="persName">Mr. HARVIE</span>—</div>
            <p className="p-in-sp">I wish to know if it is in order now to move a substitute for the amendment just adopted?</p>
            <div className="speaker" id="sp5715"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">Yes, sir.</p>
            <div className="speaker" id="sp5716"><span className="persName">Mr. HARVIE</span>—</div>
            <p className="p-in-sp">I move that the seal of secrecy be removed from the proceedings, ordinances and debates of this Convention.</p>
            <div className="speaker" id="sp5717"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">The Chair does not regard that as in order. That is already before the Convention.</p>
            <div className="speaker" id="sp5718"><span className="persName">Mr. HARVIE</span>—</div>
            <p className="p-in-sp">
              I do trust, sir, that I feel as much at heart the interests of this Convention as any member in it. If it can be shown <PageNumber num={775} /> to me that that interest would be promoted by keeping secret our acts here and the reasons for them, I would be the last man to tear the veil from these acts. But I verily believe that the interests of this
              Commonwealth, and of the Confederate States, which we have met here to subserve, make it important and necessary that the seal of secrecy should be removed from all that has transpired here.
            </p>
            <p className="p-in-sp">
              I think it is our duty to let the people know what we have done here, and the reasons which influenced us in every action we have taken. This can only be done by publishing the debates, for otherwise unworthy or improper motives may be attributed to gentlemen for their opposition to some measures which have been adopted here. It seems to me that before the people vote upon this
              ordinance of secession they should know why it was and how it was that it was adopted. They should hear the arguments for and against its adoption, so that they may vote understandingly upon the question, and approve or condemn our course here. I want them to know how it was, that after this ordinance was adopted, some individuals left for their homes, leaving the Convention with a
              bare quorum, and thus jeopardizing the public interest at a time the most important in the history of Virginia. I want, when I go home to my people, to have the means of defending myself from any charge that may be made against me for my course here, and that means consists in the publication of all that has transpired, including the debates, which contain the reason that influenced my
              votes. I will be unable to make any just vindication of my course there, unless the injunction of secrecy is removed from all that I did here and all that I said. There was general dissatisfaction in the Southern States that held secret conventions because of the suppression of the proceedings. And, for my part, I am unwilling to go back to my constituents perfectly gagged; for such,
              in fact, would be my condition, if the proceedings should be published unaccompanied with the debates upon these proceedings. I will not go back, if possible, under these circumstances-certainly not without having the privilege of communicating freely and without restraint with my people, unless you put me in a position to involve my personal honor. You have no right to place me in
              that position; and you shall not do it if I can help it.
            </p>
            <p className="p-in-sp">Now, I give you notice that nothing that you can do can close my lips, if I find, upon a review of my position, that it is in my power to open them in reference to what has transpired here. I come into this conclave with the understanding that before you adjourned you would remove this seal of secrecy.</p>
            <PageNumber num={776} />
            <p className="p-in-sp">You told these gentlemen who have left that the injunction would be removed in time for them to meet their constituents.</p>
            <p className="p-in-sp">I give notice here that there is not a power in this Convention or Commonwealth that can cause my lips to be closed, if I find that you violate what I understood to be the engagement when I agreed to put the seal of secrecy on my lips.</p>
            <div className="speaker" id="sp5719"><span className="persName">Mr. MORTON</span>—</div>
            <p className="p-in-sp">I have listened to the remarks of my friend from Louisa [<span className="persName">Mr. AMBLER</span>] , and my friend from Amelia [<span className="persName">Mr. HARVIE</span>] , with unfeigned regret. I presume that they will allow me the credit of being a secessionist as soon, as warm and as ardent as either of them.</p>
            <div className="speaker" id="sp5720"><span className="persName">Mr. HARVIE</span>—</div>
            <p className="p-in-sp">It is not as a secessionist that I make this appeal. It is as a representative of the people of Virginia that I make it.</p>
            <div className="speaker" id="sp5721"><span className="persName">Mr. MORTON</span>—</div>
            <p className="p-in-sp">I understood the gentleman as saying that, during the session of this Convention, they were in a minority. We were originally in a minority; but the progress of events have placed us in a triumphant majority; and those who have come in even at the last hour, I consider as true patriots and as gallant men as any in the Commonwealth.</p>
            <p className="p-in-sp">
              As regards the effect of tearing away the veil of secrecy from every imprudent expression and declaration which has been made here, I am satisfied it would operate as an apple of discord thrown among our people. When we have an enemy to contend against, we should stand up as one man, with one heart and arm to resist that enemy. This is no time for dissension and disagreement. It is a
              time for united action, and if the gentleman will exercise the candor of his nature, he will acknowledge that there have been many speeches made here that would be productive of injury if published, and that would lead to dissension in the Commonwealth.
            </p>
            <p className="p-in-sp">
              I have made use of expressions here which, although so far as I am concerned individually, I would be perfectly willing that every man in the Commonwealth should know, for I have independence enough to utter and avow them, and have confidence enough in my constituents to say, that in the main my conduct will be approved by them, however much they might censure an individual act-yet
              while I would be thus willing to have all I did so known, so far as it could affect myself, I think from public motives, it would be prudent to suppress all that I did say, for the present at least. The publication of many things that transpired here, would in my opinion be very injurious to the public interest, and be productive of much evil. To reveal the condition of this
              Commonwealth, not merely by documents submitted here from time to time, but by the elaborate discussions <PageNumber num={777} /> which have arisen upon them, would be in the last degree unwise and impolitic, and I trust it will not be done.
            </p>
            <p className="p-in-sp">The gentleman from Amelia [<span className="persName">Mr. HARVIE</span>]
              tells us of the complaints and dissatisfaction that exists in the Southern States because of the proceedings of their Conventions being kept secret. Has the gentleman been there to know what are the sentiments of these people? The wisdom of that act, sir, is shown in the result. The seceded States are unanimous as one man in sustaining the government which they have established. If the
              seal of secrecy had been removed from all the diversity of opinion, and all the dissensions that may have taken place in these bodies, and these diversities of opinion and dissensions published to the world, instead of there being unity of action among the people, there would have been division and discord.
            </p>
            <p className="p-in-sp">
              We should follow that good example, and avoid a publication, at this time, which would produce inevitable injury. So far as the question of secrecy is concerned, it has operated well for us, and I would trust in God that when we leave this Hall we will forget all party divisions and all party dissensions which have sprung up in this body; that we will go forth as a band of brothers to
              unite and not to divide our people, and that we shall endeavor to array every man, woman and child in sustaining the action of this Convention.
            </p>
            <div className="speaker" id="sp5722"><span className="persName">Mr. BLAKEY</span>—</div>
            <p className="p-in-sp">I beg leave to offer an amendment to the amendment :</p>
            <p className="p-in-sp">
              "Resolved, That the seal of secrecy be removed from all the debates and proceedings of this Convention, except such as appertain to military matters; and that it be referred to Messrs. <span className="persName">MACFARLAND</span>, <span className="persName">JOHNSON</span> and <span className="persName">RANDOLPH</span> to determine what part of the proceedings in relation to military matters should
              be suppressed and what part published."
            </p>
            <div className="speaker" id="sp5723"><span className="persName">Mr. MONTAGUE</span>—</div>
            <p className="p-in-sp">I would suggest, by way of amendment, the addition of the words "and financial matters."</p>
            <div className="speaker" id="sp5724"><span className="persName">Mr. BLAKEY</span>—</div>
            <p className="p-in-sp">I accept the amendment.</p>
            <div className="speaker" id="sp5725"><span className="persName">Mr. PRESTON</span>—</div>
            <p className="p-in-sp">I make a suggestion, that the debates be not published without being subjected to the revision of the members.</p>
            <div className="speaker" id="sp5726"><span className="persName">Mr. HALL</span>, of Wetzel—</div>
            <p className="p-in-sp">I wish, at the proper time, to offer the following amendment:</p>
            <p className="p-in-sp">"Resolved, That the injunction of secrecy be so far removed as to allow each member to state how he voted upon any important question, and the reasons therefor."</p>
            <div className="speaker" id="sp5727"><span className="persName">Mr. BLAKEY</span>—</div>
            <p className="p-in-sp">
              I do not participate in any of the excitement which <PageNumber num={778} /> seems to have characterized some of the gentlemen who have discussed this question. I know that there is no unkindness in the heart of either one of the gentlemen; but I know there was some excitement. I do not participate in that excitement, but, sir, when this door was closed
              it shut us up from the public, and the last view they had of us, we were wrangling, debating and divided.
            </p>
            <p className="p-in-sp">Sir, I want the seal of secrecy removed, as far as can be done consistently with the public interest, to show how we have become united, and are now acting as one man, not as party men. It is just to every member of this Convention who has undertaken to explain the reason of his act, that the reason shall go along with the publication of the act.</p>
            <p className="p-in-sp">
              Now, sir, except as regards financial and military matters, I know of nothing that ought to be concealed from the public. I can see that much good may be accomplished by the publication of these debates and proceedings. When the representatives of the people in this Convention shall go home, the people will naturally have a desire, in fact they have a legitimate right to know from them
              what has been done here; and unless the secrecy is removed, the lips of the representative will be sealed against any revelation of what had been done. I believe that much good can be accomplished if the representative is permitted to make known all that has transpired, for he can rid the minds of his people of many prejudices which have been engendered by false and exaggerated rumors,
              and justify his course here to their entire satisfaction. I do not believe that the members of this Convention are going home with apples of discord from the hall of this Convention, to throw them among the people. No, sir, their object well be to unite the people; to fire their patriotic hearts; to nerve their patriotic arms for this dread conflict that we are now facing. That is the
              spirit that will characterize the conduct of the representatives here when they go from this hall. I spurn the idea that the members of this Convention are to go home to wrangle and contend with each other. They have a foe to contend with that will call forth all their power, and every feeling of patriotism requires that they shall use all the power to meet the foe.
            </p>
            <div className="speaker" id="sp5728"><span className="persName">Mr. TURNER</span>, of Marion—</div>
            <p className="p-in-sp">I call the previous question.</p>
            <div className="speaker" id="sp5729"><span className="persName">Mr. MOORE</span>—</div>
            <p className="p-in-sp">I would ask the gentleman to withdraw the call for a moment.</p>
            <div className="speaker" id="sp5730"><span className="persName">Mr. TURNER</span>—</div>
            <p className="p-in-sp">I withdraw it.</p>
            <div className="speaker" id="sp5731"><span className="persName">Mr. MOORE</span>—</div>
            <p className="p-in-sp">Mr. President, the previous question being withdrawn at my request, I will say but a few words.</p>
            <PageNumber num={779} />
            <p className="p-in-sp">I hope the proposition offered by the gentleman from Rappahannock [<span className="persName">Mr. MOFFETT</span>] , with the amendment just submitted by the gentleman from Madison [<span className="persName">Mr. BLAKEY</span>] , will be adopted. I concur entirely in the amendment, and in the views of the gentleman who offered it. I am not aware of anything that has been said that is not proper to be published. If anything has been said which should not go before the public, I dare say I must have said as imprudent things as any other man. [Laughter.]
              But I have no objection to the public seeing what I did say. I have battled with gentlemen who were in favor of secession from the start, to the last extremity, until they carried me out of the Union. I think there has been no more imprudence committed by those who acted with me, than there has been by those who acted on the other side; and they necessarily feel as little objection to
              the publication of what transpired here as the latter do.
            </p>
            <p className="p-in-sp">
              In any event, and now that the work of secession is accomplished, we should unite together and imitate the example of the Highland clans, who, when the King sent up an army to interpose in their conflicts, clubbed together and whipped the forces of his Majesty. Let us now club together and forget all past differences, in view of the terrible conflict before us, in which we have all a
              common interest.
            </p>
            <div className="speaker" id="sp5732"><span className="persName">Mr. TYLER</span>—</div>
            <p className="p-in-sp">I concur in the adoption of the resolution which has been offered by my friend from Louisa [<span className="persName">Mr. AMBLER</span>]
              . I think it goes far enough. Remember that we are acting not for ourselves personally. The question invariably presents itself, what is best for the common cause, and the great cause in which we are engaged? We have enough to do without engendering disputes at home; without causing any crimination or recrimination, either here or elsewhere. We have enough to do to protect ourselves
              against our foreign enemy.
            </p>
            <p className="p-in-sp">
              You may, in my opinion, publish everything without injury to any public interest. There may be some indiscretions touching certain matters, in reference to our military preparations that were under consideration here, which it may not be prudent to publish; but they sink into utter insignificance in comparison to the vote upon the question of secession. Publish that vote, and you do
              incalculable mischief. What would that exhibit? It would show fifty-five votes, I believe, against it. Here we are standing in need of the co-operation of the border States every where. Here we are with the Legislature of Maryland in session surrounded by difficulties and embarrassments-one party urging them to take a negative position; the other stimulating her to come up to the stand
              and take her seat by our side. So it is in <PageNumber num={780} /> Kentucky; so it is in Tennessee, to some extent, but in Missouri and Kentucky particularly.
            </p>
            <p className="p-in-sp">
              If you determine to publish this vote, you have to give the vote at it was first taken. Then you have to come to your conclusions about the actual feelings that exist now by consulting your records of the votes added since then, and the changes made from the negative to the affirmative. The result in neither instance, would I imagine, furnish a true index of the feeling now prevalent.
              We ought not to exhibit this proceeding to the public. With what eagerness would they be seized, and the power of that minority exercised on the part of these States around us, to urge the people of these States to entire inaction? I pray you to weigh that consideration. To my mind it has a controlling influence.
            </p>
            <p className="p-in-sp">
              Sir, if you publish anything in connexion with that act of secession, publish only the ordinance, and nothing else. If my voice could have more influence over this body than it has, if it could have the influence which I would fain impart to it, if I had the moral suasion presented in my own character, if I could urge any consideration of patriotism which does not occur to every member
              present, I should urge every man who voted against that ordinance to come up now and sign it. When you have signed it, when we shall present a unanimous vote before the public, Virginia will stand redeemed from all internal bitterness and hatred; she will stand redeemed from all party shackles, and stand forth in all the plenitude of her power and majesty.
            </p>
            <p className="p-in-sp">
              Why shall it not .be so? Why ought such a state of things exist as to have upon the record of the vote upon the ordinance of secession fifty-five votes in the negative? How is it now? Why we find the newspapers teeming with reports that should stir up every feeling of the human heart. Sir, my own son, peaceful in all the relations of life; possessing every attribute that could render
              him popular and beloved by his fellow men, noble and generous, almost to a fault, is driven from his post of public duty in Philadelphia, hunted like a felon, chased everywhere through the State, and where he is now is wholly unknown to me-under the protection of a kind and overruling Providence, I am sure.
            </p>
            <p className="p-in-sp">
              Is this the time for us, in this state of things, with a prospect of having hundreds and thousands of these semi-barbarians invading you to trample upon you, partition your lands and drive away your families into banishment-is this the time, I say, for us to be deliberating-is this the time for any man to pause as to the course he ought
              <PageNumber num={781} /> to pursue, when by a single act he presents Virginia to the whole Confederacy as united upon an ordinance of secession?
            </p>
            <p className="p-in-sp">I am opposed to the opening of this door so as to have us disclose our proceedings, especially upon the question of secession, while I am at the same time perfectly willing to make public the ordinance of secession. I know if we commit the whole matter, as proposed by the gentleman from Louisa [<span className="persName">Mr. AMBLER</span>] , to the delegation from the city of Richmond, they will make public nothing that ought to be concealed, and will publish everything that ought to be made public.</p>
            <p className="p-in-sp">I beg pardon for extending these remarks.</p>
            <div className="speaker" id="sp5733"><span className="persName">Mr. HALL</span>, of Marion—</div>
            <p className="p-in-sp">I beg to offer the following by way of amendment—</p>
            <div className="speaker" id="sp5734"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">It is not in order.</p>
            <div className="speaker" id="sp5735"><span className="persName">Mr. HALL</span>—</div>
            <p className="p-in-sp">I will read it then, by way of argument.</p>
            <p className="p-in-sp">Resolved, That the injunction of secrecy be removed, and the proceedings of this Convention had in secret session, be as early as practicable published, except so far as the same tends to disclose the financial and military condition of the Commonwealth.</p>
            <p className="p-in-sp">I hope it will be the pleasure of the Convention to remove the injunction of secrecy in every particular, except those indicated in the substitute.</p>
            <p className="p-in-sp">
              It is said that indiscreet remarks have been made in this Convention. If that be true, is it not even more proper that the proceedings and the debates, including these indiscreet remarks, should be made public so that they might not again be uttered? I should think that no gentleman in this body is afraid to meet the responsibility of what he said and did here. I maintain that it is
              the right of the constituents of every gentleman here to demand of their representative to inform them as to what has transpired here, and what his action was.
            </p>
            <p className="p-in-sp">
              I know that my constituents will demand of me when I go home, to tell them what we have done, not only in open but secret session. It is known that the very idea of sitting in secret is revolting to the whole sentiment of the people of Virginia. I voted against going into secret session at all. I was in favor of Virginia's standing upon the house tops, and daring to do in broad
              daylight everything she would do. I have, however, when the majority thought best to adopt the secret system, kept the pledge; but I understood at the time, as remarked by the gentleman from Amelia
              [<span className="persName">Mr. HARVIE</span>]
              , that it was with the distinct understanding that this injunction would be removed <PageNumber num={782} /> before the Convention adjourned, and we could return to our people and disclose to them all that transpired here. Now, I cannot go home to my people without opening my mouth and letting them know what I did here and what others did. I do not see
              why the representatives of a free people should endeavor to cover up anything that they dared to do in their representative capacity.
            </p>
            <p className="p-in-sp">It is suggested by the venerable gentleman who last spoke [<span className="persName">Mr. TYLER</span>] , that at all events we ought not to disclose the vote upon the ordinance of secession. I maintain that we ought to disclose that as well as every other vote that was cast here. I did not vote for it, and as a man who opposed it on the 17th of April, I will go before the people and tell them that I did oppose it.</p>
            <p className="p-in-sp">
              Let me beg gentlemen to remember that the saving of the country does not depend merely upon those who voted for that ordinance. As strong arms for the defence of our State and the vindication of her honor, are found among those who voted against it as among those who voted for it. My arm is not less strong to defend Virginia because I voted against secession than if I voted for it. I
              am not going to stultify myself by supporting now what I believed on the 17th of April to be injudicious. I believed then it was injudicious to pass that ordinance, and I believe so now for a stronger reason. I maintain, sir, that it is the right of the people to know the vote upon that ordinance and all about it. If gentlemen choose to change their votes, that of course will appear. I
              tell you there is no force in the argument against the publication of the vote upon that ordinance. All must know that the defence of Virginia does not rest upon the vote that may be given for or against an ordinance of secession. The people will look elsewhere for indications as to whether or not Virginia is united. If the vote upon the ordinance of secession was the indication of
              Virginia's unity and her power to defend herself, the estimate of our capacity in this respect would necessarily be unfavorable. I believe that, acting as the representative of a free people, we should return to them, as a witness comes into court, prepared to tell the truth, the whole truth and nothing but the truth. For my part, I defy the scorn and the contempt of those who decry my
              position and impute improper motives to me.
            </p>
            <div className="speaker" id="sp5736"><span className="persName">Mr. MORTON</span>—</div>
            <p className="p-in-sp">I call the gentleman to order.</p>
            <div className="speaker" id="sp5737"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">The Chair thinks the gentleman was merely vindicating himself from any suspicion of acting improperly.</p>
            <div className="speaker" id="sp5738"><span className="persName">Mr. HALL</span>—</div>
            <p className="p-in-sp">I was referring to myself, sir. I scorn the idea of <PageNumber num={783} /> perpetrating a mean act, and did not intend to cast an imputation upon any gentleman here.</p>
            <p className="p-in-sp">I am of opinion that, with the exception of some military and financial matters, everything else ought to be published. I feel very much like the gentleman from Amelia [<span className="persName">Mr. HARVIE</span>] , that at the end of the secret session the injunction of secrecy would be removed. It was that understanding that caused me and many others, as I believe, to consent to our going into secret session.</p>
            <p className="p-in-sp">I hope it will be the pleasure of the Convention to remove the injunction of secrecy, except in the particulars indicated.</p>
            <div className="speaker" id="sp5739"><span className="persName">Mr. HARVIE</span>—</div>
            <p className="p-in-sp">I rise to do what seems strange and unusual, to reply, in a great measure, to my own speech. I must say that, after the remarks of my friend from Charles City [<span className="persName">Mr. TYLER</span>]
              , I cannot insist that the injunction shall be removed from our proceedings and debates. I must endure the painful condition in which I shall inevitably find myself when I go home, because he has satisfied me that mischief to the Commonwealth and injury to the cause would necessarily follow the disclosure of all our proceedings here. I believe that we ought not to proclaim to the world
              what the vote upon the ordinance of secession was. I do not care whether this man or the other may indicate what it was, but we ought not to give the benefit of the information to those who are opposed to us.
            </p>
            <p className="p-in-sp">It must require a great deal to change a gentleman's position, which is founded upon strong convictions, but such has been the case in my instance. I am now convinced that my position awhile ago was wrong, and I would be unworthy of the seat I occupy here if I was not willing to change my opinions when satisfied of their error.</p>
            <p className="p-in-sp">I am in favor of the proposition submitted by the gentleman from Louisa [<span className="persName">Mr. AMBLER</span>] referring the whole matter to the discretion of a select committee.</p>
            <div className="speaker" id="sp5740"><span className="persName">Mr. RANDOLPH</span>—</div>
            <p className="p-in-sp">By general consent, I beg leave to make a small amendment in the second section of the ordinance prescribing the pay of the provisional army and of the volunteer forces of the State.</p>
            <p className="p-in-sp">The section now reads:</p>
            <p className="p-in-sp">"That the general and field officers of the volunteers shall not exceed in grade and numbers the general and field officers of the Provisional army."</p>
            <p className="p-in-sp">I propose to amend that section by striking out the word "numbers," so that that part of the section will read, "shall not exceed in grade the general and field officers of the Provisional army."</p>
            <PageNumber num={784} />
            <p>The amendment was agreed to.</p>
            <div className="speaker" id="sp5741"><span className="persName">Mr. BLAKEY</span>—</div>
            <p className="p-in-sp">
              I rise to say that the same publication which will show that the vote against the ordinance of secession was 55, will show that the ordinance for an alliance with the Confederate States had only 16 votes against it, and the further proceedings will show that a large number of that sixteen are cordially co-operating with us in the defence which we are making against Northern aggression.
            </p>
            <p className="p-in-sp">I call the previous question.</p>
            <div className="speaker" id="sp5742"><span className="persName">Mr. HALL</span>, of Marion—</div>
            <p className="p-in-sp">Is my amendment before the Convention?</p>
            <div className="speaker" id="sp5743"><span className="persName">The PRESIDENT</span>—</div>
            <p className="p-in-sp">No, sir.</p>
            <p>The call for the previous question was sustained, and the question being put upon the amendment to the amendment offered by <span className="persName">Mr. BLAKEY</span>, it was decided in the negative.</p>
            <p>The question then recurred on the amendment offered by <span className="persName">Mr. MOFFETT</span>, and it was rejected.</p>
            <p>The question next came up on the original resolution.</p>
            <div className="speaker" id="sp5744"><span className="persName">Mr. AMBLER</span>—</div>
            <p className="p-in-sp">I will offer an amendment to my resolution in conformity with what seems to be the general sentiment of the Convention, inasmuch as it was agreed to as an amendment to the amendment of the gentleman from Rappahannock [<span className="persName">Mr. MOFFETT</span>] which was rejected. I move to strike out the words "and debates," so as to make the duty of the committee only embrace the ordinances and proceedings.</p>
            <div className="speaker" id="sp5745"><span className="persName">Mr. RANDOLPH</span>—</div>
            <p className="p-in-sp">I shall not be able to act upon that committee.</p>
            <div className="speaker" id="sp5746"><span className="persName">Mr. BLAKEY</span>—</div>
            <p className="p-in-sp">I call the previous question.</p>
            <p>The call was sustained, and the main question being put, which was upon the adoption of the original resolution offered by <span className="persName">Mr. AMBLER</span>, it was decided in the affirmative.</p>
            <div className="speaker" id="sp5747"><span className="persName">Mr. HOLCOMBE</span>—</div>
            <p className="p-in-sp">I beg leave to offer the following resolution:</p>
            <p className="p-in-sp">
              "Resolved, That the delegates to the Provisional Congress, at Montgomery, be furnished with a properly certified copy of the ordinance of this Convention, adopting the Provisional Constitution of the Confederate States; and also the ordinance appointing delegates to the Congress of the Provisional Government of said Confederate States; and that the said delegates be instructed to
              proceed, as soon as possible, to Montgomery, and ask admission for this State into said Confederacy; and that the Secretary of the Commonwealth is hereby instructed to certify the preceding ordinances under the seal of the Commonwealth."
            </p>
            <p className="p-in-sp">The Convention are aware that, as the State now stands, she is not <PageNumber num={785} /> a member of the Confederate States; she can only become one by the action of the Congress of the Confederate States. Under the new Constitution, that Congress has the power to admit new States.</p>
            <p className="p-in-sp">I saw one of the delegates this morning, and he wishes to know how he should act. Presuming the proper course to be to furnish them with a properly certified copy of the ordinance, adopting the Provisional Constitution, and also the ordinance appointing delegates to the Provisional Congress I prepared the resolution which I now present.</p>
            <div className="speaker" id="sp5748"><span className="persName">Mr. HOLLADAY</span>—</div>
            <p className="p-in-sp">I call the previous question.</p>
            <p>The call was sustained, and the main question being put it was decided in the affirmative.</p>
            <div className="speaker" id="sp5749"><span className="persName">Mr. MACFARLAND</span>—</div>
            <p className="p-in-sp">I beg to say that I had an interview with the Auditor this morning, and was informed by him that it was important to change the ordinance authorizing the issue of treasury notes in some select matters of detail.</p>
            <p className="p-in-sp">I move the following as a supplement :</p>
            <div className="floatingText">
              <div className="floatingText_body">
                <p>AN ORDINANCE, respecting Treasury Notes.</p>
                <p>
                  "Be it ordained, That any officer receiving treasury notes in payment of taxes or other public dues, shall endorse thereon that the same is paid, and the date of such payment, and thereafter the interest shall thereon cease; and that the ordinance, authorizing the issue of treasury notes, passed the 30th of April, 1861, shall be held and construed to authorize interest to be paid
                  thereon until the said notes are actually redeemed, or to such time after the same be redeemable on their face, as may be fixed by the advertisement authorized by said ordinance."
                </p>
              </div>
            </div>
            <div className="speaker" id="sp5750"><span className="persName">Mr. EARLY</span>—</div>
            <p className="p-in-sp">I wish to ask the gentleman if there has been an ordinance reported and adopted, making appropriations for the different branches of the military service?</p>
            <div className="speaker" id="sp5751"><span className="persName">Mr. MACFARLAND</span>—</div>
            <p className="p-in-sp">Yes, sir.</p>
            <p className="p-in-sp">The supplement given above was adopted.</p>
            <div className="speaker" id="sp5752"><span className="persName">Mr. BLAKEY</span>—</div>
            <p className="p-in-sp">I move that <span className="persName">Mr. HARVIE</span> be added to the committee of three, to supervise the ordinances and proceedings of this Convention.</p>
            <p>The motion was agreed to.</p>
            <div className="speaker" id="sp5753"><span className="persName">Mr. STUART</span>, of Augusta—</div>
            <p className="p-in-sp">I beg leave to offer the following resolution :</p>
            <p className="p-in-sp">"Resolved, That a committee of seven members be appointed by the President of this Convention, whose duty it shall be to consider and report to the Convention, at its adjourned session, such amendments <PageNumber num={786} /> of the Constitution of Virginia, as are necessary and expedient under existing circumstances."</p>
            <p>The resolution was adopted, whereupon, on motion of <span className="persName">Mr. STUART</span>, the Convention took a recess until 5 o'clock, P. M.</p>
            <div className="session" id="vsc1965.v4.2.15.1">
              <h3><span className="headingNumber">1.15.1. </span><span className="head">EVENING SESSION</span></h3>
              <p>The Convention re-assembled at 5 o'clock, pursuant to adjournment.</p>
              <div className="speaker" id="sp5754"><span className="persName">Mr. SPEED</span>—</div>
              <p className="p-in-sp">I offer the following resolution:</p>
              <p className="p-in-sp">Resolved, That this Convention, by the passage of the ordinance organizing the volunteer force of the Commonwealth, did not design to restrict the Governor in the appointment of officers, to those who were recently officers in the United States army.</p>
              <p className="p-in-sp">I offer this resolution because I learn the Governor is under what I am sure is a very serious misapprehension in regard to the proper interpretation to be placed upon that ordinance.</p>
              <p className="p-in-sp">Upon conference with many members of the Convention on this subject, I find that they concur with me in saying, that this ordinance was not intended to be what the Governor interprets it.</p>
              <div className="speaker" id="sp5755"><span className="persName">Mr. AMBLER</span>—</div>
              <p className="p-in-sp">I call the attention of the gentleman to the fact, that the mere addition of a few words would give the proper interpretation.</p>
              <div className="speaker" id="sp5756"><span className="persName">Mr. SPEED</span>—</div>
              <p className="p-in-sp">The language "did not design to restrict" seems to me to be sufficiently expressive.</p>
              <div className="speaker" id="sp5757"><span className="persName">Mr. AMBLER</span>—</div>
              <p className="p-in-sp">I offer the following as an amendment by way of addition:</p>
              <p className="p-in-sp">"But simply to express the wish of this Convention that preference, other things being equal, should be given to these officers."</p>
              <div className="speaker" id="sp5758"><span className="persName">Mr. RANDOLPH</span>—</div>
              <p className="p-in-sp">
                The ordinance organizing the volunteer forces said nothing about a preference. The ordinance organizing the Provisional army said that the officers lately in the service of the United States should have a preference. The ordinance calling the volunteers into service, is simply an ordinance authorizing the Governor to issue that call without instructing him as to any question of
                preference.
              </p>
              <div className="speaker" id="sp5759"><span className="persName">Mr. SPEED</span>—</div>
              <p className="p-in-sp">I will not accept the amendment of the gentleman from Louisa [<span className="persName">Mr. AMBLER</span>] , and I hope he will withdraw it.</p>
              <div className="speaker" id="sp5760"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">I hope the gentleman will not withdraw his amendment; but that the gentleman from Lynchburg [<span className="persName">Mr. SPEED</span>]
                will put in his resolution the words "Provisional Army." The mistake that the gentleman supposes the Governor to be under, does not, I am sure, apply to the volunteer forces, as will be seen by looking to the list of <PageNumber num={787} /> appointments already made. I think it is necessary to pass some such explanatory resolution as this, giving a
                preference to officers of the army, where all other things are equal. I certainly am in favor of giving a preference to these officers, while it does not follow that because a man is in the army he has superior qualifications over every body else.
              </p>
              <div className="speaker" id="sp5761"><span className="persName">Mr. RANDOLPH</span>—</div>
              <p className="p-in-sp">Here is the ordinance of the gentleman himself-the ordinance organizing a Provisional Army, in the fourth section of which the following provision is found :</p>
              <p className="p-in-sp">"In making all the appointments for the Provisional Army preference shall be given to all worthy and efficient citizens and residents of Virginia, who have held, or now hold, commissions in the army of the United States, and have resigned, or shall resign, the same with a view to offer their services to this State."</p>
              <div className="speaker" id="sp5762"><span className="persName">Mr. EARLY</span>—</div>
              <p className="p-in-sp">I will say that I embodied that provision with great reluctance. I did so in deference to the wishes of gentlemen who desired it in order to carry out effectually the invitation extended to these officers.</p>
              <div className="speaker" id="sp5763"><span className="persName">Mr. RANDOLPH</span>—</div>
              <p className="p-in-sp">
                That may all be so, yet the ordinance is passed, and the preference is given in the Provisional Army to United States officers. In the Provisional Army, which is to consist of 10,000 men-twelve regiments-we shall have twelve Colonels, twelve Lieutenant Colonels, twelve Majors and twelve Sergeant Majors-enough, I think, to absorb all the worthy and efficient officers from Virginia in
                the late United States Army. It is not necessary, after having redeemed our pledge to these gentlemen, by giving them commissions in the permanent army of the Commonwealth, that we should go under a pledge to give them a preference in the temporary department of the army.
              </p>
              <div className="speaker" id="sp5764"><span className="persName">Mr. AMBLER</span>—</div>
              <p className="p-in-sp">I ask leave of the Convention to withdraw my amendment, as it is likely to lead to some embarrassment.</p>
              <p>Leave was granted.</p>
              <div className="speaker" id="sp5765"><span className="persName">Mr. JOHNSON</span>—</div>
              <p className="p-in-sp">I trust that the resolution of the gentleman from Lynchburg [<span className="persName">Mr. SPEED</span>]
                will be adopted, because it will at least accomplish this much: it will be an instruction to the Governor as to what course he shall pursue in this matter, and will prevent misconstruction on his part. I know, from recent information which I received, that the construction placed by the Governor upon the ordinance which is now the subject of discussion, is, that he should give
                preference to the army officers who have resigned, not only as regarded the provisional army, but even the volunteer army. I know <PageNumber num={788} /> that, from information received within the last ten minutes, and as I do not think it just that it should be so, and in order to settle the question whether my colleague
                [<span className="persName">Mr. RANDOLPH</span>] is right in his interpretation, and whether the Governor is right, and in order to put at rest any doubt as regards the construction of the ordinance, I hope the resolution will be at once acted upon. I know that the Governor has a difficulty about it, and I am satisfied that the passage of that resolution will put at rest that difficulty.</p>
              <div className="speaker" id="sp5766"><span className="persName">Mr. WM. B. PRESTON</span>—</div>
              <p className="p-in-sp">I will make a single remark, sir, more for the purpose of elicting information than otherwise.</p>
              <p className="p-in-sp">I voted for the ordinance organizing the Provisional army, understanding it as I shall now state, and if I was wrong, I trust I shall be corrected by some of the gentlemen who compose the Military Committee.</p>
              <p className="p-in-sp">We adopted an ordinance for the volunteer service, that being supposed to be the most efficient, and as being best calculated to rid us of a great deal of embarrassment, which was likely to result from the manner in which our militia were generally organized. And then we went on and proposed the ordinance to organize this Provisional army.</p>
              <p className="p-in-sp">
                I understood that that was intended exclusively to absorb the military gentlemen who resigned in the United States army and took or shall take service with us. We did not intend to take them into the volunteer service, which, I trust, will be a very short service, but to provide for them commissions of a permanent character, and then transfer them to the Confederate States
                Government. I understood that the volunteer service was to be a distinct and separate service, to embrace only those who were ready to join during the war.
              </p>
              <p className="p-in-sp">
                That was my understanding of it. I voted for this with great reluctance for this reason. I know there are in many portions of the Commonwealth a vast number of true, capable militia officers, who could render efficient service in the volunteer army, but for whom no provision was made in the ordinance of the 17th of April, authorizing the volunteers to be called out and officered. I
                thought since, however, that it may be well to have pursued that course, so that when the Provisional army would be turned over to the Confederate States Government our volunteer forces would be ready to take the field; and in case of a protracted war, we could call out the militia service. We have a great many militia generals, and I trust that they will step forward and take
                command of volunteers as fast as they can get them, and not wait until the militia are called out. I think it is but just that such of them as are capable and efficient should be given a <PageNumber num={789} /> preference in the volunteer service, now that the army officers are provided for by the establishment of a Provisional army.
              </p>
              <p className="p-in-sp">I hope the resolution will be adopted, and that the Governor will see that the Virginia officers have a preference in the volunteer service.</p>
              <div className="speaker" id="sp5767"><span className="persName">Mr. HAYMOND</span>—</div>
              <p className="p-in-sp">I call the previous question upon the resolution of the gentleman from Lynchburg [<span className="persName">Mr. SPEED</span>] .</p>
              <p>The call was sustained, and the main question being put, it was decided in the affirmative.</p>
              <div className="speaker" id="sp5768"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">I beg leave to lay before the Convention the following communication from the Governor of the Commonwealth:</p>
              <div className="floatingText">
                <div className="floatingText_body">
                  <p>EXECUTIVE DEPARTMENT,</p>
                  <p>May 1st, 1861.</p>
                  <p>Gentlemen of the Convention:</p>
                  <p>In obedience to an ordinance passed April 27th, 1861, requiring all appointments of officers heretofore made by the Governor and council, or which shall hereafter be made above the rank of Lieut. Colonel, to be sent in to the Convention for confirmation, I submit the following appointments:</p>
                  <p>Walter Gwynn, Brigadier General of volunteers,</p>
                  <p>appointed April 26, 1861.</p>
                  <p>Joseph E. Johnston, Brigadier General, Provisional Army, April 21. Philip St. George Cocke, Colonel of volunteers, April 21.</p>
                  <p>Daniel Ruggles, Colonel of volunteers, April 22.</p>
                  <p>John B. Magruder, Colonel of volunteers, April 25.</p>
                  <p>James F. Preston, Colonel of volunteers, April 25.</p>
                  <p>Robert S. Garnett, Colonel of volunteers,</p>
                  <p>Adjutant General, April 25.</p>
                  <p>John B. Baldwin, Colonel of volunteers, Inspector General April 21. Wm. Gilham, Colonel of volunteers, April 23.</p>
                  <p>Charles Bell Gibson, Colonel of volunteers,</p>
                  <p>Surgeon General, April 26.</p>
                  <p>Respectfully,</p>
                  <p>JOHN LETCHER.</p>
                </div>
              </div>
              <p>These nominations were severally confirmed.</p>
              <div className="speaker" id="sp5769"><span className="persName">Mr. BOULDIN</span>, of Charlotte—</div>
              <p className="p-in-sp">I desire to call up the report of the committee on the duties of Collectors and Postmasters.</p>
              <p>The report was taken up, and reads as follows:</p>
              <div className="floatingText">
                <div className="floatingText_body">
                  <p>
                    The Committee, who were charged with the duty of enquiring into the expediency of reporting an ordinance regulating the duties <PageNumber num={790} /> of postmasters and officers in the revenue service of the United States, and to whom was also referred the message of the Governor, with the accompanying letters of Tazewell Taylor, John S. Parker,
                    collector of the customs of the district of Cherrystone, and of Samuel V. Watkins, surveyor of the ports of Richmond and Petersburg, beg leave to report:
                  </p>
                  <p>
                    That they would recommend that the Governor of the Commonwealth be requested to instruct the several collectors of the customs, surveyors of the different ports of entry and the postmasters in this Commonwealth, to retain and exercise the functions of their respective offices; that the collectors of the customs of the several ports collect the duties on foreign importations under
                    the existing laws of the United States; that they enroll and license vessels under the said laws as heretofore, and that they account for and pay into the public treasury of this State all public moneys collected by them, less their usual salaries and commissions to which they may be entitled under the laws of the United States. And they recommend that similiar instructions, so
                    far as they may be applicable, be given to the postmasters of this State, except that they shall be instructed to pay the different contractors for carrying the public mails, the same sums as they have been heretofore in the habit of paying under the orders of the Postmaster General of the United States, and shall be required to pay into the public treasury of this State only the
                    surplus moneys which may remain in their hands after making such payment. And they recommend that the said collectors of customs and postmasters shall be required to make reports to the Governor of this State, as they have been in the habit heretofore of making to the different departments of the Federal Government to which they were respectively attached.
                  </p>
                  <p>And the Committee further recommend, that the Governor instruct the Paymaster at Norfolk to discharge the duties of Navy Agent at that port, if the discharge of such office be not incompatible with the other duties which said Paymaster has to perform, in which event he is authorized to appoint George Loyall, of Norfolk, Navy Agent of the said port.</p>
                  <p>And the Committee further recommend, that these regulations shall be in force until otherwise ordered by this Convention, or until the government of the Confederate States of America shall take control of the several offices hereinbefore mentioned, and prescribe regulations for the proper discharge of the duties and the appointment of the officers to the same.</p>
                  <p>And the Committee recommend further, that the Governor of this <PageNumber num={791} /> Commonwealth be furnished with a copy of this report as soon as the same shall be approved and adopted by the Convention, if the same shall be approved.</p>
                  <p>All of which is respectfully reported.</p>
                  <p>MIERS W. FISHER,</p>
                  <p>Chairman of the Committee.</p>
                </div>
              </div>
              <div className="speaker" id="sp5770"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">I see that in that report it is proposed to collect the duties under the existing laws of the United States, which is the Morrill tariff. I think we ought to amend that, so as to make it conform with the tariff now in force in the Confederate States, which is the tariff of 1857.</p>
              <p className="p-in-sp">I therefore move to strike out the words "under the existing laws of the United States," and insert the words "under the tariff of 1857."</p>
              <div className="speaker" id="sp5771"><span className="persName">Mr. BOULDIN</span>—</div>
              <p className="p-in-sp">I accept the amendment.</p>
              <div className="speaker" id="sp5772"><span className="persName">Mr. BLOW</span>, of Norfolk—</div>
              <p className="p-in-sp">
                I move that that report be laid on the table. I see no necessity for making any such arrangements as this, in view of the fact that our members of Congress will soon be at their posts, and have full opportunity to make whatever change they shall deem necessary. It may be that some inconvenience may result in the Post Office Department, but as in the other instance, our members will
                probably have it in their power to remedy that.
              </p>
              <div className="speaker" id="sp5773"><span className="persName">Mr. BOULDIN</span>—</div>
              <p className="p-in-sp">I hope the gentleman will withdraw that motion.</p>
              <div className="speaker" id="sp5774"><span className="persName">Mr. BLOW</span>—</div>
              <p className="p-in-sp">I withdraw it.</p>
              <div className="speaker" id="sp5775"><span className="persName">Mr. BOULDIN</span>—</div>
              <p className="p-in-sp">
                We received information not only at the time that the committee was appointed, but even to day, that there are now cargoes in the port of Richmond which, in the peculiar condition in which the people of Virginia now are, must be deemed of considerable importance. These cargoes consist of sugar, molasses and stores of all kinds, and instructions should at once be given as to what
                should be done with them. This provision is merely temporary, and can of course be altered or repealed just as soon as our relations with the Federal Government shall justify.<span className="note" id="Note73"><span className="noteLabel">3</span>The context seems to call for "Confederate Government."</span>
              </p>
              <div className="speaker" id="sp5776"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">I want to know if this Convention should not now reinstate the Federal officers in this Commonwealth, who were in office before Lincoln's election, in place of those who have been appointed by the present administration?</p>
              <div className="speaker" id="sp5777"><span className="persName">Mr. PRESTON</span>—</div>
              <p className="p-in-sp">I move that the ordinance be amended by striking out the following words in the report:</p>
              <p className="p-in-sp">
                "And the committee further recommend that the Governor instruct the paymaster at Norfolk to discharge the duties of navy agent at that <PageNumber num={792} /> port, if the discharge of such office be not incompatible with the other duties which said paymaster has to perform, in which event he is authorized to appoint George Loyall, of Norfolk, navy
                agent of the said port."
              </p>
              <p className="p-in-sp">I move to strike out all these words and insert in lieu thereof the words:</p>
              <p className="p-in-sp">"And the Governor is requested to appoint George Loyall navy agent at Norfolk."</p>
              <div className="speaker" id="sp5778"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">I do not see why we should not appoint all the officers who have resigned since Lincoln's election, or been removed by him. There are many in the same position as George Loyall.</p>
              <p className="p-in-sp">
                We have in the city of Richmond Mr. Harrison, the Collector of Customs, and Mr. Bigger, the Postmaster, both of whom, I understand, it is contemplated to remove, if they are not already removed. There is also Judge Halyburton, of the District Federal Court, and Col. Wiley, marshal for the Eastern District of Virginia, both of whom, I understand, are out of office. They should be
                forthwith reinstated.
              </p>
              <div className="speaker" id="sp5779"><span className="persName">Mr. BOULDIN</span>—</div>
              <p className="p-in-sp">These matters are not germane at all to this subject. The committee have merely reported as to the manner of discharging the duties of Collectors and Postmasters for the present. They have nothing to do with the removal or reinstatement of officers.</p>
              <div className="speaker" id="sp5780"><span className="persName">Mr. GRAY</span>, of Rockingham—</div>
              <p className="p-in-sp">I want to know if these appointments do not involve the payment of salaries.</p>
              <div className="speaker" id="sp5781"><span className="persName">Mr. BOULDIN</span>—</div>
              <p className="p-in-sp">
                I made the very suggestion that my friend has just made, when it was suggested that we should recommend at once the appointment of George Loyall as Navy agent; and the Chairman of the committee stated that he had had a conference with Mr. Loyall, and he thought perhaps the paymaster would act ex-officio Navy agent without any additional compensation, and that thus the difficulty
                suggested by the gentleman in regard to salary would be avoided. We therefore suggested that the paymaster for the present, discharge the duties of Navy agent.
              </p>
              <p className="p-in-sp">That was the reason that induced the Chairman, <span className="persName">Mr. FISHER</span>, to put it in that form, and the committee adopted it.</p>
              <p className="p-in-sp">This is merely a temporary arrangement. The Confederate States authorities will soon manage the whole affair in their own way.</p>
              <div className="speaker" id="sp5782"><span className="persName">Mr. BLOW</span>—</div>
              <p className="p-in-sp">I think it proper that I should make an explanation about this matter, inasmuch as I know something about the officer in question. I should not feel that I was discharging my duty to the <PageNumber num={793} /> State if I did not oppose the amendment offered by the gentleman from Montgomery [<span className="persName">Mr. PRESTON</span>] .</p>
              <p className="p-in-sp">
                Nothing can be said, so far as regards the gentleman who has been put in nomination for that office. I know Mr. Loyall, and can say, there is no man of more estimable qualities in the State of Virginia. But I must object to the amendment, for the reason that, in the present condition of our civil affairs, every duty that is necessary to be performed in the capacity of agent, can be
                performed by the Paymaster of that station; and if you appoint an officer to this post, he will of course have emoluments and a salary, when there will be no duty to perform. It will cost the State of Virginia not less than $5,000, and for that reason I must object to the amendment.
              </p>
              <p className="p-in-sp">I am in favor of leaving the report as it came from the committee.</p>
              <div className="speaker" id="sp5783"><span className="persName">Mr. PRESTON</span>—</div>
              <p className="p-in-sp">Upon that explanation, I withdraw my amendment.</p>
              <div className="speaker" id="sp5784"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">I beg leave to inquire whether the report recommends that the present incumbents ought to be retained. If not, I think it ought to be amended. I understand that the Postmaster and clerks in this city were changed when the present administration came into office.</p>
              <p className="p-in-sp">I would recommend that the following words be inserted after the words "and the Postmasters in this Commonwealth"-the words "who were in office on the 4th day of March last," so as to make it more explicit.</p>
              <div className="speaker" id="sp5785"><span className="persName">Mr. BRANCH</span>—</div>
              <p className="p-in-sp">I will say that I am quite sure many Postmasters in unimportant country places have voluntarily resigned since the 4th of March last, and if you adopt that amendment, you reinstate those who have thus resigned, and necessarily produce a serious difficulty.</p>
              <div className="speaker" id="sp5786"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">That difficulty can easily be obviated. The amendment was adopted.</p>
              <div className="speaker" id="sp5787"><span className="persName">Mr. WOODS</span>, of Barbour—</div>
              <p className="p-in-sp">I would inquire whether the report requires the existing laws in relation to postage, to be applied to the postage system of Virginia.</p>
              <div className="speaker" id="sp5788"><span className="persName">Mr. BOULDIN</span>—</div>
              <p className="p-in-sp">It is not intended to do that.</p>
              <div className="speaker" id="sp5789"><span className="persName">Mr. JOHNSON</span>—</div>
              <p className="p-in-sp">I desire to call the attention of the Convention to one feature of the report, and that is, that the officers, who were in office on the 4th of March, shall retain the positions which they then held. I desire to know how is it as to those offices that have been vacated since, and are now filled by other officers? Do their predecessors take their places?</p>
              <div className="speaker" id="sp5790"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">I so understand it.</p>
              <PageNumber num={794} />
              <div className="speaker" id="sp5791"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">I will read the ordinance which I intended to offer:</p>
              <p className="p-in-sp">"Be it ordained, That all persons removed by Abraham Lincoln, President of the United States, from offices held in this State, and all persons who resigned their offices in consequence of his election, be reinstated in the offices held by them on the 3d day of March, 1861."</p>
              <p className="p-in-sp">I shall offer this as an independent proposition.</p>
              <p>The question was then taken on the report of the committee, and it was decided in the affirmative.</p>
              <div className="speaker" id="sp5792"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">I now offer the ordinance which I have just read.</p>
              <div className="speaker" id="sp5793"><span className="persName">Mr. WOODS</span>—</div>
              <p className="p-in-sp">I desire to make another inquiry. I desire to know whether that is sufficiently specific to place these people, who thus obtain office, under the same responsibility to the State that they were under to the Federal Government? If they are reinstated, they ought to be required to give the same sureties they had given before.</p>
              <div className="speaker" id="sp5794"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">I take it that these officers will be retained by the Government of the Confederate States, and they will see that they give the proper safeguards.</p>
              <div className="speaker" id="sp5795"><span className="persName">Mr. WOODS</span>—</div>
              <p className="p-in-sp">It may happen that they may not be.</p>
              <div className="speaker" id="sp5796"><span className="persName">Mr. HOLLADAY</span>—</div>
              <p className="p-in-sp">
                This Convention has just adopted a report by which substantially it refused to reinstate Mr. George Loyall, as navy agent at the port of Norfolk, except in the contingency that the paymaster there should be incompetent to discharge the duties of navy agent; and yet in the very face of that action against the renewal, or I may say the creation of new offices, an ordinance is offered
                here which substantially creates the office of navy agent at Norfolk, and fills it with Mr. Loyall.
              </p>
              <p className="p-in-sp">
                My objection to that ordinance does not stop here. I must confess that it raises a question which is not according to my taste. It raises one which the whole feelings of my nature revolt at. Sir, I thank God that I have never been one of those who wanted, either for myself or my friends, the spoils of office; and if I had been, I hope that my appetite would not have been so
                insatiable as not to be appeased by the condition of things existing in this State.
              </p>
              <p className="p-in-sp">
                Now, sir, I take it that nineteen twentieths, or perhaps the larger proportion of the offices in this Commonwealth, are in the hands of gentlemen who were the incumbents of these offices on the fourth day of March last. I suppose that but a very small proportion of the office holders, on the 4th day of March, have been removed since that period; and yet, we are called upon here to
                reinstate to office the small fraction of those who have been removed.
              </p>
              <p className="p-in-sp">
                Sir, I had hoped that, under the peculiar circumstances in which <PageNumber num={795} /> we are now placed, old party lines and distinctions would have been obliterated, and that all men would have been united together in cordial co-operation to produce that unity which was necessary in this emergency. It so happened that I came from a community which
                held strong Union sentiments. That people never contemplated the condition of things which now exists. Whilst Virginia was a member of the United States, they regarded the government at Washington as their government. In this state of things, it so happened that a few, very few, removals were made; but I am satisfied that nine-tenths of the offices in that community are now held by
                the old incumbents. I assert here upon my personal responsibility and knowledge that those who have been appointed to office within my community, with two exceptions, I think, are as worthy gentlemen and as true, patriotic citizens as any who occupy seats on this floor.
              </p>
              <p className="p-in-sp">
                Now, why should they be removed? Why should the old incumbents be reinstated? What do principles of fairness, and a just and liberal policy dictate? That as we found things on the day the ordinance of secession was adopted, so we should leave them! that we should not seek, under the pretence that they were the appointees of Abraham Lincoln, to remove men who may have been appointed
                as Union men, and re-instate the old incumbents.
              </p>
              <p className="p-in-sp">
                I don't choose, sir, to occupy the attention of this Convention, by elaborating this view of the matter. It is too plain and manifest to every mind that the removal of those who were appointed as Union men, will have a deleterious influence in that community, where the Union sentiment was so strong. I have every reason to think, and I say here to the honor of that community, that as
                zealous as they were for the preservation of the Union, upon Virginia's taking her position without the Union, they forthwith rushed to arms; and in a community with a population not exceeding 9,000, there are now nine companies in the service of the State. I venture to say that in proportion to population, no town or county has manifested its loyalty to the same extent, and in so
                substantial a manner as has the town of Portsmouth; and I do trust that the overwhelming majority of the citizens there who concurred in the appointments made by Abraham Lincoln, will not be outraged by this Convention's ousting those who have been obnoxious to them. I trust it will not be the pleasure of this Convention to remove men who were Union men. If they were worthy men, as
                we find them in office, when the State leaves the Union, let us continue them in office. If they are worthy men, the mere fact of their being appointees of Abraham Lincoln is no excuse to <PageNumber num={796} /> have them removed. Every body will say that men as pure and as lofty in their patriotism as any to be found in the Commonwealth, might have
                been appointees of Abraham Lincoln. I can state that with two exceptions, the men appointed in our community are men of unblemished character and pure patriotism.
              </p>
              <p className="p-in-sp">I move to lay the resolution on the table.</p>
              <div className="speaker" id="sp5797"><span className="persName">Mr. FLOURNOY</span>—</div>
              <p className="p-in-sp">
                I concur fully with the gentleman who last addressed the Convention, as to the impropriety of re-instating to office those who have been removed since the 4th of March last. I see no necessity for doing so, I see no valuable end to be gained by putting the mark of this Convention upon those citizens of Virginia, who have thought proper to receive appointments at the hands of this
                administration, to fill vacancies which that administration thought proper to create. Some office holders resigned, and thus made it absolutely necessary to fill the vacancies.
              </p>
              <p className="p-in-sp">
                Many in Virginia thought that their honor would be impeached, and that it was absolutely a reflection upon themselves to remain in office after this administration was inaugurated. But while we were in the Union, and formed part of the government, our own convenience required that the government machinery should be carried on. The transportation of the mails by the Post Office
                Department, which was a great convenience to every community in Virginia, necessarily needed officers to conduct them; and I heard of no man in the State-while I heard of officers who resigned-I heard of no man who said it was improper that the Post Office Department in Virginia should continue in operation; who thought that the office in the city of Richmond, or the offices in
                Lynchburg, Fredericksburg and Norfolk, ought not to be held by some men in Virginia. I imagine that there was no man in those towns, or elsewhere in Virginia, who thought that upon the accession of Abraham Lincoln to power all these offices were to be abandoned. Was it the fault of Virginia that these men were turned out? If Lincoln chooses to put some out, and put others in, was it
                the fault of those who were in? Shall we put a mark upon our own citizens, and create dissension and disaffection at a time when the union of every heart in Virginia is essential, and when every act we perform should have in view the union of all men to meet that infernal horde of barbarians that threatens to sweep us, as they say, from the Potomac to the Rio Grande? Is this the time
                for us to create heart-burnings, when every effort is being made by our enemy to crush, and if possible, annihilate us? I should think not— <PageNumber num={797} /> and I trust that no action will be had here which can have so bad a result.
              </p>
              <p className="p-in-sp">
                When your own citizens have received offices necessary for the convenience of the people of Virginia to be filled; when these offices were confined to us as Virginians, and were vacated either by resignation or removal, I cannot by any means consent, so far as I am concerned, that a mark shall be put upon the men who choose to accept appointments to these offices; and division and
                discord thus stirred up at every hour when it is most important that we should be united to the last man.
              </p>
              <p className="p-in-sp">
                Sir, the time is short between this and the fourth Thursday of the present month, and if on that day our action here, separating Virginia from the General Government, shall be sustained by our people-and there is hardly a man in the Commonwealth who doubts that it will be sustained-then we shall be in a condition in which this matter can be attended to, and in which men can be
                removed for any cause that may be just, without Virginia's putting a mark upon her own people.
              </p>
              <p className="p-in-sp">I will make no motion that may cut off debate, but if no man wishes to address the Chair, I will move to lay this ordinance upon the table.</p>
              <div className="speaker" id="sp5798"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">That motion is already pending.</p>
              <div className="speaker" id="sp5799"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">The gentleman talks about creating heart burnings and casting stigmas upon our citizens. Who is it that has cast stigmas upon those faithful men who have been ostracised and proscribed for their sentiments?</p>
              <div className="speaker" id="sp5800"><span className="persName">Mr. FLOURNOY</span>—</div>
              <p className="p-in-sp">Not Virginia, I suppose, but Abraham Lincoln.</p>
              <div className="speaker" id="sp5801"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">
                Virginia is to some extent responsible. Does not every one here know that an edict went forth from Washington to the effect that men in office who were not for Secession were safe. If a man was for Secession, let him belong to what party he may, his head was to be cut off. If he was a Union man, it did not matter what party he belonged to, he was safe. Who are those in this State who
                were to be decapitated? Col. Bigger, the Postmaster of this city, a man who was not only true to his State and section, but who bears the marks of his patriotism upon his person. There, too, was Col. Wiley, who also bears upon his person wounds received when fighting in defence of his country. I say, sir, that if the State of Virginia be true to herself, she will not let one day pass
                over before she reinstates every man who has been ostracised by this Abolition President to the position from which he was driven because of his fidelity to Virginia and the South.
              </p>
              <div className="speaker" id="sp5802"><span className="persName">Mr. FLOURNOY</span>—</div>
              <p className="p-in-sp">I hope when the time comes that full justice will <PageNumber num={798} /> be done to every man to whom injustice has been done in these matters.</p>
              <div className="speaker" id="sp5803"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">It is the duty of the Convention to do justice now.</p>
              <p className="p-in-sp">The great mass of the people of the State objected to these parties receiving the appointments at the hands of Lincoln which he conferred upon them. If they received them in defiance of the public sentiment, they should not murmur if that sentiment should demand retribution for the outrage perpetuated upon it, and the disregard paid to it.</p>
              <div className="speaker" id="sp5804"><span className="persName">Mr. TURNER</span>, of Jackson—</div>
              <p className="p-in-sp">I have an objection to this ordinance other than that presented by the gentleman from Halifax [<span className="persName">Mr. FLOURNOY</span>] , and one which I regard as a serious objection. Whilst I have no sympathy with those who accepted office from Lincoln, at the same time I am not willing to reinstate those who have resigned their offices. My objection is to the language of the ordinance, which is as follows:</p>
              <p className="p-in-sp">"Be it ordained that all persons removed by Abraham Lincoln, President of the United States, from offices held in this State, and all persons who resigned their offices in consequence of his election, be reinstated in the offices held by them on the 3rd day of March, 1861."</p>
              <p className="p-in-sp">What offices did they hold on the 3rd of March, 1861? They held offices under the United States Government.</p>
              <p className="p-in-sp">To what offices do you propose to reinstate them? To offices created by the Government of the United States. If any ordinance has been passed here to fill offices in this State, they were offices created by the Federal Government, and now under the control of that government, for we are still, to some extent, a part of that government.</p>
              <div className="speaker" id="sp5805"><span className="persName">Mr. HARVIE</span>—</div>
              <p className="p-in-sp">I withdraw the ordinance.</p>
              <div className="speaker" id="sp5806"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">I beg leave to submit a communication from the Governor of the Commonwealth :</p>
              <div className="floatingText">
                <div className="floatingText_body">
                  <p>EXECUTIVE DEPARTMENT,</p>
                  <p>May 1st, 1861.</p>
                  <p>Gentlemen of the Convention:</p>
                  <p>In obedience to the ordinance of the 27th of April, I send herewith, for confirmation as Colonels of volunteers, the names of James W. Allen, Kenton Harper and C. Q. Tompkins.</p>
                  <p>Respectfully,</p>
                  <p>JOHN LETCHER.</p>
                </div>
              </div>
              <p>These nominations were severally confirmed.</p>
              <div className="speaker" id="sp5807"><span className="persName">Mr. TYLER</span>—</div>
              <p className="p-in-sp">I beg leave to present the following ordinance:</p>
              <PageNumber num={799} />
              <div className="floatingText">
                <div className="floatingText_body">
                  <p>"AN ORDINANCE, for the prevention and punishment of offences against the Commonwealth.</p>
                  <p>
                    "Be it ordained by the Convention of Virginia, 1. That in addition to the powers now vested in the county courts, they shall have power to establish a regular police force, who, being first qualified by taking the oaths of fidelity to this Commonwealth, and to discharge the duties devolved upon them under this ordinance, shall be authorized to apprehend and carry forthwith,
                    before a Justice of the Peace for the county, any person whom they shall have just cause to suspect has violated any law of the State in regard to tampering with slaves, inciting them to rebel or make insurrection, or to escape from their owners, or has attempted to commit any such offence, or any person, whom they shall have just cause to suspect, is concerned in any manner in
                    counselling, aiding or abetting the Government of the United States or any officer or agent thereof in any invasion or hostile action against this Commonwealth or any of its citizens, acting under the proper authorities thereof, or against the Confederate States of America or any of them, or of any slaveholding State in amity with them; or any person who shall by letter or
                    otherwise communicate to the said Government of the United States or any officer or agent thereof, directly or indirectly, any information touching the action of this Commonwealth or its authorities, other than as the same may be authorized by them or any of them, and to bring all such persons before a Justice of the Peace of the county, to be dealt with according to law and the
                    provisions of this ordinance.
                  </p>
                  <p>2. Any person who shall commit any offence for which a person may be apprehended under the first section of this ordinance, shall be deemed guilty of felony, and shall be punished in such manner as the laws now in force prescribe; provided, that any imprisonment prescribed by existing laws in such cases shall be in the Public Jail and Penitentiary house.</p>
                  <p>In these cases, mentioned in the first section, for which no punishment is fixed by existing laws, the person convicted thereof shall be punished by fine not less than one hundred nor more than ten thousand dollars, and by imprisonment in the Public Jail and Penitentiary house for not less than five nor more than twenty years.</p>
                  <p>3. The powers vested by this ordinance in the county courts, and all the provisions of the first and second sections thereof, shall be vested in like manner, and be applicable to the corporation courts, and to the police force legally appointed by such corporations under their respective charters.</p>
                  <PageNumber num={800} />
                  <p>4. No person so apprehended shall be admitted to bail, except by a Judge of a Circuit Court in this Commonwealth or of the Supreme Court of Appeals; but the party, if so released on bail, shall not be permitted to leave the Commonwealth before trial, and the order of release shall require the officer to take care that the party does not leave the Commonwealth.</p>
                  <p>5. This ordinance shall be in force from its passage, and be subject to amendment and repeal by the General Assembly of Virginia."</p>
                </div>
              </div>
              <div className="speaker" id="sp5808"><span className="persName">Mr. TYLER</span>—</div>
              <p className="p-in-sp">
                I have only to say that that ordinance was presented at the request of the Mayor of the City of Richmond; that it has been under the revision of the Attorney General, and received his approval, and it has met, in my own presence, with the approval of Judge Meredith. It is indispensably necessary that it should be passed in order to enable the authorities of this city to deal
                summarily with suspected parties. The Mayor states this fact, that there are two persons under arrest at this moment, that he has them in prison, but has no power to punish them, while he considers it dangerous to discharge them. He is anxious, therefore, that some action should be taken at once to enable him to deal with suspicious persons.
              </p>
              <div className="speaker" id="sp5809"><span className="persName">Mr. HAYMOND</span>—</div>
              <p className="p-in-sp">I will suggest to the gentleman from Charles City, the propriety of striking out of the ordinance the words, "public jail and," before the word Penitentiary in the second section. Our Code employs the word Penitentiary.</p>
              <div className="speaker" id="sp5810"><span className="persName">Mr. TYLER</span>—</div>
              <p className="p-in-sp">I accept the suggestion, and shall so amend the ordinance.</p>
              <div className="speaker" id="sp5811"><span className="persName">Mr. WOODS</span>, of Barbour—</div>
              <p className="p-in-sp">That is a very important ordinance, and it occurs to me, that it is more important to the citizens of Virginia than any ordinance we have passed since the ordinance of secession. If I can have the attention of the Convention for a moment, I will state very briefly my views regarding that ordinance.</p>
              <p className="p-in-sp">Ever since the ordinance of secession was passed, the subject of this ordinance has been pressing upon my mind, and I was exceedingly anxious that such an ordinance should be presented. But it occurred to me, upon listening attentively to the reading of this ordinance, that it grants exclusive jurisdiction over these offences to the county and corporation courts.</p>
              <p className="p-in-sp">
                It seems to me that an ordinance making so radical a change in die legal system of Virginia, ought not to be suffered to pass so summarily I cannot consent to vote for that ordinance in its present form, because it authorizes county and corporation courts, in which <PageNumber num={801} /> I think no lawyer has very much confidence, to try a man for
                felony. I never can consent to that. Unless it be changed in that particular, I shall be obliged to vote against it.
              </p>
              <p className="p-in-sp">I will ask that it may be read again.</p>
              <p>The ordinance was again read as desired.</p>
              <div className="speaker" id="sp5812"><span className="persName">Mr. WOODS</span>—</div>
              <p className="p-in-sp">My objection is removed on hearing the ordinance read.</p>
              <p>The ordinance was then adopted.</p>
              <div className="speaker" id="sp5813"><span className="persName">Mr. SPEED</span>—</div>
              <p className="p-in-sp">I move to reconsider the vote adopting the report of the committee on the duties of Collectors and Postmasters.</p>
              <p className="p-in-sp">It is important to amend an addition made to that report upon the motion of the gentleman from Richmond [<span className="persName">Mr. MACFARLAND</span>] .</p>
              <p>The motion to reconsider was agreed to.</p>
              <div className="speaker" id="sp5814"><span className="persName">Mr. MACFARLAND</span>—</div>
              <p className="p-in-sp">I have ascertained that the amendment made at my instance to the effect that all persons holding office on the 4th of March last, should be again reinstated, was a little injudicious.</p>
              <p className="p-in-sp">I ask that by general consent, the words "in office on the 4th of March last," which was my amendment, be stricken out.</p>
              <p>The motion to strike out was agreed to.</p>
              <p>The report was then adopted.</p>
              <div className="speaker" id="sp5815"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">I beg leave to submit "an ordinance concerning elections to the General Assembly."</p>
              <p className="p-in-sp">"Be it ordained, That soldiers entitled to vote at their encampments upon the Ordinance of Secession, shall have a right to vote for members of the General Assembly in the same manner and at the same time that they shall vote for the Ordinance of Secession."</p>
              <p className="p-in-sp">This I regard as necessary, in view of the fact that there was no provision made for soldiers to vote for members of the General Assembly.</p>
              <div className="speaker" id="sp5816"><span className="persName">Mr. WICKHAM</span>—</div>
              <p className="p-in-sp">I would ask the gentleman if he has calculated the expense of removing the polls? It seems to me that there would be scarcely any opposition to members now representing the different counties. There is no issue now to divide the people, and I cannot conceive the necessity of putting the State to unnecessary expense.</p>
              <div className="speaker" id="sp5817"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">I presume it will cost no more than the transmitting of the vote upon the Ordinance of Secession. I withdraw the ordinance.</p>
              <div className="speaker" id="sp5818"><span className="persName">Mr. CHAMBLISS</span>—</div>
              <p className="p-in-sp">I offer the following resolution:</p>
              <p className="p-in-sp">"Resolved, That the Secretary of this Convention be allowed the <PageNumber num={802} /> sum of $30 a week for the compensation of his assistant during the session of the Convention."</p>
              <div className="speaker" id="sp5819"><span className="persName">Mr. CHAMBLISS</span>—</div>
              <p className="p-in-sp">I will state that the labors of the Secretary are very great, and that the pay allowed him is little enough without any diminution of it for the pay of an assistant. The Secretary receives much less, indeed scarcely half what is paid to the Clerk of the House of Delegates.</p>
              <p>The resolution was adopted.</p>
              <div className="speaker" id="sp5820"><span className="persName">Mr. HAYMOND</span>—</div>
              <p className="p-in-sp">I beg leave to offer the following resolution:</p>
              <p className="p-in-sp">"Resolved, That the Secretary of the Convention deliver to the Governor copies of all ordinances adopted by the Convention, and a list of the confirmations of persons recommended to the Convention by him, with which he has not already been furnished."</p>
              <p className="p-in-sp">
                I will remark that the reason why I offer this resolution is, that there are a great number of ordinances which have not yet been furnished to the Governor, and of which of course he must be ignorant to some extent. Only a portion of our ordinances have been communicated to him, those, for instance, adopted at an early stage of the secret session; and it is necessary that he should
                have copies of all as soon as possible, so that he may act in conformity with such of them as embody instructions to him.
              </p>
              <p className="p-in-sp">I hope the resolution will be adopted.</p>
              <p>It was adopted.</p>
              <div className="speaker" id="sp5821"><span className="persName">Mr. SUTHERLIN</span>—</div>
              <p className="p-in-sp">I offer the following ordinance:</p>
              <div className="floatingText">
                <div className="floatingText_body"><p>Be it ordained, That the ministers of the Gospel who are citizens of this Commonwealth, be allowed to vote upon the ordinance of secession in any county in which they may be on the day of election.</p></div>
              </div>
              <p>The ordinance was rejected.</p>
              <div className="speaker" id="sp5822"><span className="persName">Mr. BOULDIN</span>, of Charlotte—</div>
              <p className="p-in-sp">I offer the following ordinance in order to facilitate the carrying out of the act of Assembly for arming the militia:</p>
              <div className="floatingText">
                <div className="floatingText_body">
                  <p>
                    Be it ordained, That the presiding justice of any county in this State, be, and he is hereby, authorized and required, on the application of three or more justices of such county, to cause all the acting justices thereof, to be summoned to meet at the court house of such county on the next succeeding court day, or on some intermediate day, not less than five days from the date of
                    the summons, to take into consideration and carry into effect the provisions of the act of the General Assembly, entitled "an act to authorize the county courts and
                  </p>
                  <PageNumber num={803} />
                  <p>any incorporated city or town to arm the militia of their respective counties, cities and towns, and to provide means therefor," passed January nineteenth, eighteen hundred and sixty-one.</p>
                  <p>And whereas there may be a doubt whether the term "militia," in said act of Assembly, includes the volunteer force as a part of the militia, be it further ordained that the said statute be so construed as to embrace the volunteer force as a part of the militia of the several counties, cities and towns of the Commonwealth.</p>
                </div>
              </div>
              <p>This ordinance was adopted.</p>
              <div className="speaker" id="sp5823"><span className="persName">Mr. BRANCH</span>—</div>
              <p className="p-in-sp">I offer the following resolution:</p>
              <p className="p-in-sp">Resolved, that the Governor be informed that this Convention is ready to adjourn, unless he has some further communication for them.</p>
              <p>The resolution was adopted, and <span className="persName">Mr. BRANCH</span> requested to communicate it to the Governor.</p>
              <div className="speaker" id="sp5824"><span className="persName">Mr. SCOTT</span>, of Powhatan—</div>
              <p className="p-in-sp">I am instructed by the Committee on Military Affairs to report the following ordinance:</p>
              <div className="floatingText">
                <div className="floatingText_body">
                  <p>AN ORDINANCE concerning Militia Musters.</p>
                  <p>Be it ordained, That the commandants of regiments of the militia of the Commonwealth be authorized to order as many company, battalion and regimental musters, within the limits of their respective commands, as in their discretion may be deemed necessary.</p>
                  <p>This ordinance shall be in force from its passage, and be subject to amendment or repeal by this Convention or by the Legislature.</p>
                </div>
              </div>
              <div className="speaker" id="sp5825"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">I move to lay that ordinance on the table. The motion was agreed to.</p>
              <div className="speaker" id="sp5826"><span className="persName">Mr. RANDOLPH</span>—</div>
              <p className="p-in-sp">I will have to move to reconsider the vote by which the report of the Committee on the duties of Collectors and Postmasters was adopted, with a view to change the tariff under which the collection of customs was ordered to be made. I do not think we are justified at present to change the tariff.</p>
              <div className="speaker" id="sp5827"><span className="persName">Mr. BOULDIN</span>—</div>
              <p className="p-in-sp">
                The existing tariff laws were preserved in the report as it was originally presented. By the action of the House it was changed to the tariff of 1857. It seems to me that we ought to have one thing or the other, and in my opinion, the plan first recommended in the report was the proper one. By allowing the collections to be made under the existing tariff laws, the desired end would,
                I imagine, be fully attained. I think it would be better to restore the report to its original form.
              </p>
              <p>The motion to reconsider was agreed to.</p>
              <PageNumber num={804} />
              <div className="speaker" id="sp5828"><span className="persName">Mr. RANDOLPH</span>—</div>
              <p className="p-in-sp">I now move to amend the report by restoring it to its original form, so that the collectors will collect the duties under the existing laws of the United States. I do not think it is competent for us, in our present attitude, to make any change in these laws. This we would be doing substantially by adopting the tariff of 1857, in lieu of that now in force.</p>
              <p>The motion to amend was agreed to.</p>
              <div className="speaker" id="sp5829"><span className="persName">Mr. SHEFFEY</span>—</div>
              <p className="p-in-sp">I offer the following resolution:</p>
              <p className="p-in-sp">Resolved, That the ordinance passed in relation to criminal offences against the State be forthwith published.</p>
              <div className="speaker" id="sp5830"><span className="persName">Mr. MORRIS</span>—</div>
              <p className="p-in-sp">I move to amend the resolution by adding thereto the following-"and that the Secretary of the Commonwealth cause copies of the said ordinance to be printed and sent forthwith to the clerk of each county and corporation court in the Commonwealth."</p>
              <div className="speaker" id="sp5831"><span className="persName">Mr. SHEFFEY</span>—</div>
              <p className="p-in-sp">I accept the amendment.</p>
              <p>The resolution, as amended, was adopted.</p>
              <div className="speaker" id="sp5832"><span className="persName">Mr. LEWIS D. ISBELL</span>, of Appomattox—</div>
              <p className="p-in-sp">I beg leave to offer the following resolution:</p>
              <p className="p-in-sp">Resolved, That the delegates from Virginia to the Congress of the Confederate States be, and they are hereby instructed to urge the speedy adoption of measures for the procurement of arms from Europe for the Confederate States.</p>
              <p>The resolution was adopted.</p>
              <div className="speaker" id="sp5833"><span className="persName">Mr. HOLLADAY</span>—</div>
              <p className="p-in-sp">I beg to offer the following ordinance:</p>
              <div className="floatingText">
                <div className="floatingText_body">
                  <p>AN ORDINANCE in relation to the District and Supreme Courts of the United States.</p>
                  <p>"Be it ordained, That no suit, writ or mandate, or other proceeding from the Supreme or District Courts of the United States, since the day of the passage of the ordinance of secession by this Convention, shall have force or effect within this Commonwealth."</p>
                </div>
              </div>
              <div className="speaker" id="sp5834"><span className="persName">Mr. TURNER</span>, of Jackson—</div>
              <p className="p-in-sp">I move to lay that ordinance on the table.</p>
              <p>The motion was agreed to.</p>
              <div className="speaker" id="sp5835"><span className="persName">Mr. MONTAGUE</span>—</div>
              <p className="p-in-sp">I move to reconsider the vote by which the ordinance, authorizing ministers of the Gospel who are citizens of Virginia, to vote upon the ordinance of secession in any county in which they may be on the day of election, was rejected awhile ago. I understand that ordinance was offered at the instance of a Methodist clergyman of one of our circuits.</p>
              <PageNumber num={805} />
              <div className="speaker" id="sp5836"><span className="persName">Mr. WICKHAM</span>—</div>
              <p className="p-in-sp">
                That ordinance would deprive this class of clergyman of the right to vote.<span className="note" id="Note74"><span className="noteLabel">4</span>Mr. Wickham possibly said "The rejection of that ordinance...."</span>
              </p>
              <p>The question on the motion to reconsider was put, and decided in the negative.</p>
              <div className="speaker" id="sp5837"><span className="persName">Mr. HAYMOND</span>—</div>
              <p className="p-in-sp">I offer the following resolution:</p>
              <p className="p-in-sp">"Resolved, That the enrolled ordinances of the Convention be filed by the Secretary of the Convention with the Clerk of the House of Delegates, to be by him enrolled with the bills of the House of Delegates."</p>
              <div className="speaker" id="sp5838"><span className="persName">Mr. JOHNSON</span>—</div>
              <p className="p-in-sp">I would suggest that this resolution be so amended as to conform to the ordinance appointing a committee to determine what ordinances should be published, and what suppressed. I would suggest to the gentleman to amend his resolution by providing for their enrollment in the form indicated, of such ordinances as the committee shall order to be published.</p>
              <div className="speaker" id="sp5839"><span className="persName">Mr. WICKHAM</span>—</div>
              <p className="p-in-sp">I would say that it is better not to adopt this resolution for this reason, that it is proper we should have all our ordinances published in one volume. If the resolution is adopted, we shall have part of them published by themselves, and a portion with the acts of the House of Delegates.</p>
              <div className="speaker" id="sp5840"><span className="persName">Mr. DULANY</span>—</div>
              <p className="p-in-sp">I move to lay the resolution on the table.</p>
              <p>The motion was agreed to.</p>
              <div className="speaker" id="sp5841"><span className="persName">Mr. MORRIS</span>—</div>
              <p className="p-in-sp">I offer the following resolution :</p>
              <p className="p-in-sp">Resolved, That the Secretary of the Convention be instructed to have the Ordinance of Secession, adopted by the Convention, engrossed on parchment by a professional penman, during the recess of the Convention so that the same may be signed by members on their return, if it be adopted by the people at the polls.</p>
              <p>The resolution was adopted.</p>
              <div className="speaker" id="sp5842"><span className="persName">Mr. JOHN GOODE, JR.</span>—</div>
              <p className="p-in-sp">I beg leave to offer the following resolution:</p>
              <p className="p-in-sp">"Resolved, That the Secretary be instructed to hand over to the reporter his short-hand notes of the debates in secret session, to enable him to proceed with the transcript thereof; but the obligation of secrecy shall continue to rest upon the reporter until the Convention shall remove the same."</p>
              <p>The resolution was adopted.</p>
              <div className="speaker" id="sp5843"><span className="persName">Mr. BOULDIN</span>—</div>
              <p className="p-in-sp">I would ask the Convention to authorize the publication of the ordinance just adopted, to facilitate the carrying out by <PageNumber num={806} /> the county courts, of the act of Assembly in relation to the arming of the militia.</p>
              <div className="speaker" id="sp5844"><span className="persName">Mr. MORTON</span>—</div>
              <p className="p-in-sp">I move that authority be given for its publication.</p>
              <p>The motion was agreed to.</p>
              <div className="speaker" id="sp5845"><span className="persName">Mr. BRANCH</span>—</div>
              <p className="p-in-sp">I beg leave to say that I performed the duty assigned me by the Convention, of waiting on the Governor, and presenting to him the resolution adopted by the Convention a few minutes ago, and he has requested me to say that he has nothing to present to the Convention until its meeting here in June next.</p>
              <div className="speaker" id="sp5846"><span className="persName">Mr. HAYMOND</span>—</div>
              <p className="p-in-sp">I rise to inquire whether the ordinances have been enrolled so that they may be signed by the President of the Convention, that we may adjourn to-night.</p>
              <div className="speaker" id="sp5847"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">All the ordinances reported by the committee on enrollment have been signed.</p>
              <p className="p-in-sp">The Chair will state, however, that there is one ordinance of twenty-six pages which could not be enrolled in time.</p>
              <div className="speaker" id="sp5848"><span className="persName">Mr. COX</span>, of Chesterfield—</div>
              <p className="p-in-sp">I offer the following resolution:</p>
              <p className="p-in-sp">Resolved, That the President of the Convention have authority to sign during the recess, such ordinances as are not now enrolled and ready for his signature.</p>
              <p>The resolution was adopted.</p>
              <div className="speaker" id="sp5849"><span className="persName">The PRESIDENT</span>—</div>
              <p className="p-in-sp">The Chair will announce the following committee under the resolution offered by the gentleman from Augusta [<span className="persName">Mr. STUART</span>] on the subject of the amendments necessary to be made in the State Constitution: Messrs. <span className="persName">A. H. H</span>. Stuart, of Augusta, G. W. Summers, of Kanawha, Robt. E. Scott, of Fauquier, George Blow, Jr., of Norfolk city, Alpheus F. Haymond, of Marion, Wm. M. Tredway, of Pittsylvania, and Wm. W. Boyd, of Botetourt and Craig.</p>
              <div className="speaker" id="sp5850"><span className="persName">Mr. BLOW</span>—</div>
              <p className="p-in-sp">A resolution, I believe, was offered a short time since, authorizing the publication of all the ordinances and resolutions passed to-day. If I am right in that belief, I would suggest that a resolution has been adopted in relation to the purchase of arms in Europe, which it would not be prudent to publish.</p>
              <p>That resolution was exempt by general consent from the order to publish.</p>
              <div className="speaker" id="sp5851"><span className="persName">Mr. WILSON</span>—</div>
              <p className="p-in-sp">I desire to call up the resolution laid upon the table some days ago. It is a resolution calling for certain information from the Auditor of Public Accounts.</p>
              <p className="p-in-sp">I am informed by the Auditor that if this resolution is adopted, he will have all the information required when we return here in June.</p>
              <PageNumber num={807} />
              <div className="speaker" id="sp5852"><span className="persName">Mr. WOODS</span>—</div>
              <p className="p-in-sp">I trust it will not be the pleasure of the Convention to take up that resolution. It will inevitably lead to debate.</p>
              <p>The Convention refused to take it up.</p>
              <div className="speaker" id="sp5853"><span className="persName">Mr. BORST</span>—</div>
              <p className="p-in-sp">I move we now adjourn.</p>
              <p>The motion was agreed to, and the Convention adjourned until the 12th day of June, 1861.</p>
            </div>
            <PageNumber num={808} />
          </div>
        </section>
      </div>

      <div className="tei_back" id="v4back">
        <section className="back" id="vsc1965.v4.3">
          <header>
            <h1><span className="headingNumber">Appendix A </span><span className="head">NOTES</span></h1>
          </header>
          <PageNumber num={810} />
          <div className="notes" id="vsc1965.v4.3.1">
            <h2><span className="headingNumber">Appendix A.1 </span><span className="head">NOTES</span></h2>
            <p>The notes below are numbered 1 and upwards for each day of Convention Proceedings.</p>
            <p>Dates in parentheses are dates of issues of the Enquirer which contain editorial corrections for its reports of Proceedings.</p>
            <p>Page numbers in citations of Proceedings are numbers of pages in this edition.</p>
            <p>References to Documents, and to Journal or Convention Journal, are references to the printed official Documents of the Convention, and to the printed Journal of the Acts and Proceedings of a General Convention of the State of Virginia ... (Richmond: Wyatt M. Elliott, Printer, 1861) .</p>
            <p>FIRST DAY</p>
            <p>Tuesday, April 16</p>
            <p>1. This speech by Mr. Preston has not been found in the Enquirer.</p>
            <p>2. These proceedings of a meeting in Wetzel have not been found in the Enquirer.</p>
            <p>3. The speaker must have meant to say "western."</p>
            <p>4. There seems to be an omission, or a change of construction here.</p>
            <p>5. Presumably Mr. Turner is referring to reports of meetings printed in the Enquirer on April 30. See note 2 on Proceedings of April 15.</p>
            <p>6. There is apparently an omission in this sentence.</p>
            <p>7. The Journal adds Lawson to the negative votes listed here, to make a total of 65.</p>
            <p>SECOND DAY</p>
            <p>Wednesday, April 17</p>
            <p>1. This speech was badly reported; the "Latin" phrase pungite passita on p. 85, for example, must be a misunderstanding of "perfect opacity." Mr. Marye sent a letter to the editors of the Enquirer, with a corrected version of these remarks. The letter and the corrected version were printed in the Enquirer on October 29 as follows:</p>
            <p>Fredericksburg, Oct. 28, 1861.</p>
            <p>Gentlemen: I did not see until Saturday last, your paper containing the report of the proceedings of the Convention in secret session on the 17th of April, in which appears a report of some remarks made by me in that body. The latter is full of gross errors and either perverts or renders unintelligible what I said. I do not impute the errors to the want of</p>
            <PageNumber num={812} />
            <p>skill or fidelity in the reporter, for the excitement which prevailed and my indistinct utterance might readily have occasioned them. As your reporter gave assurances to the members that all such reports should receive the supervision of the speaker, I ask your publication of the correct statement of what I did say, which will accompany this.</p>
            <p>Very respectfully yours,</p>
            <p>JOHN L. MARYE.</p>
            <p>REMARKS OF JOHN L. MARYE, ESQ.</p>
            <p>
              As I have had, Mr. President, no ambitious views to serve, I have been content during the session of this body to remain a silent listener. It is true, that, from time to time, I thought that I might throw out a suggestion that would avail something and sought opportunity to do so, but being unversed in the tactics of deliberative bodies, I always failed to seize the proper occasion,
              and found myself forestalled. It is well that it has so occurred, for in the sequel I found, when opportunity offered, that what I proposed to say, had already been better said by others.
            </p>
            <p>I should have remained a silent listener to the end of the session, but events have crowded upon us and culminated in a crisis, in which it does not comport with my sense of what I owe to myself, to record my vote in silence. I am at least unwilling to do so.</p>
            <p>
              I came here, sir, representing a constituency of an entirely different type of politics, from those which I entertain; I felt, therefore, in all its force, the obligation which devolved upon me to sink my individual predilections when they tended to oppose the will of those who sent me; and so far as party politics are concerned, I can say with truth that I am ignorant of the political
              affinities of most of the members of this body. I am equally ignorant of the sectional classification of its members. There are, it is true, some whose prominence of character has forced a knowledge of their locality upon me, however studiously I sought to remain ignorant, and that knowledge, added to what I have heard from them in debate, has inducted me to the conclusion, that every
              consideration founded upon locality tending to influence our deliberations, should be blotted out and forever. I have, so far as knowledge of the fact can be gathered from what has transpired here, as much faith in the patriotism of members from the West, as in those from the East. I go further and say that the wisdom and statesmanship developed by the debates to which I have listened,
              are not circumscribed by locality, and I trust that no sentiment or feeling, springing from sectional position and tending to produce discord, will find lodgment with any member to the extent of influencing his vote upon the question about to be taken.
            </p>
            <p>I came here, sir, as the representative of no section; and whilst I admit that those who sent me have the right to make their mouth piece, it is as their mouth piece that I now here announce that I know no section-that I know no political party, and when, on a late occasion, at a full meeting of my countymen, I deemed it proper, because of</p>
            <PageNumber num={813} />
            <p>
              instructions received from a portion of them, to express my views upon the question of secession, viewed in its then existing surroundings, I urged them to relieve me, by instructions, from the responsibility, which I felt to be a burden, of deciding it for them. I stated, at the same time, that candor required me to add that, whilst for my own relief I sought their instructions, I
              should deem it unwise in them to grant my request. With my views fully promulged they determined to take no action, but to submit to me the decision of the question as it might arise and with all the lights then before me.
            </p>
            <p>
              Sir, with what views did I enter this body? What interest had I to serve? What was the Virginia sentiment that directed my action? The preservation of the Union-yes, the preservation of the Union in all its integrity. What were the views I entertained in regard to the possibility, if not probability, of achieving that result? I am of sanguine temperament. I did not realize it as an
              insurmountable difficulty for this body to carve out such measures of compromise as would result in furnishing efficient guarantees upon all those questions, and the settlement of which caused its inauguration. I anticipated that the trouble with regard to the acceptance of the guarantees we might propose would be with the South, not the North; and what was the basis of my calculation?
              In the absence of any personal knowledge of the extent of that fanaticism and folly at the North which have wrought results so disastrous to the Country, I concluded that the magnitude of her interest in preserving her connection with the South, was too controlling to yield to the dominion of prejudice.
            </p>
            <p>
              With the South, the trouble sprang from the independent position then occupied by some two or three of the States, and the difficulty in our way consisted in so shaping the measures proposed by us as to satisfy those States that if accepted they would be adequate to vindicate their rights and protect their interests. But now the number of the seceded States is not three but seven-with
              a government fully inaugurated, and with ability to sustain themselves. All my fond and sanguine calculations of reconstruction have failed, and the conclusion has been forced upon me that a difficulty, great in itself as it existed at the time I came here, has become insuperable. After the secession of the seven States, what remained to be accomplished by this body? Why, so to shape
              our measures as would retain the balance of the confederacy in its integrity, and adequately to defend all the interests of our own state. This was rendered a matter of necessity by the course pursued at Washington; but now it is not a matter of option with Virginia whether she will remain in the Union. To my mind, the necessity of severing her connection is manifest She has no middle
              ground to occupy. It is now a mere matter of time, and the question presents itself to us after this mode: Shall we, before we go out, secure cooperation on the part of those of our border States whose cooperation is essential to the maintenance of our position; or shall we take the chance of their cooperating with us after we shall have gone out, and, probably, in the midst of a war
              waged against us by the North?
            </p>
            <p>Sir, unprepared as we are, I have no hesitation on that question. Purpose is power, when coupled with high moral influence such as we</p>
            <PageNumber num={814} />
            <p>
              possess. It is more potent than anything that could now be accomplished by negotiation, under the circumstances in which that negotiation would be conducted. I therefore have no hesitation as to the course to be pursued. But my main object in rising here, after declaring my own position, was to say, that when I entered this body, I took it for granted that the results of our
              deliberations (however much we might differ in the progress of the investigation which led to them) would receive the undivided support of all the members. I thought that however much we may have been divided in the past into sectional parties; however much we may have been divided in political views; however much we may have been subdivided into local associations, growing out of
              diversities of interest-all would sink into utter insignificance in view of the great issue now upon us, whose decision involves so much that is dear to us all. Discord in this body will prove a prolific source of discord out of it; and it behoves us to guard against it by all the means in our power. Sir, can an occasion arise, is it possible for one to be conceived, in which the
              practical effect of that great truth "in concord there is strength," could be more fully illustrated or beneficially applied than the present? I will not at this time of trial, when the whole Confederacy is convulsed to its centre, refer to the causes which have produced the sad results now upon us. They are wide-spread and numerous, and if we carry the elements of discord which have
              wrought these results into our own body, what can follow but evils infinitely more deleterious to the public weal than the sum of evils which existed at the time we entered this hall?
            </p>
            <p>
              Let us then cheerfully yield our support to whatever line of policy the majority shall adopt, and let us as one man give force and efficacy to that action as the action of our State. Let nothing of prejudice or passion obtain. God in his providence sometimes so orders it in our voyage through life that the only light vouchsafed us should be coruscated in the wake of the boat that bears
              us. It would seem that such are our present surroundings; but it were worse than impious to doubt the adequacy of the light to conduct us safely into port. Yet we are so constituted that often by the working of our passions a cloud is generated which obscures the true sources of the little light about us. I pray that you will not by such a display of criminal folly intensify the
              darkening heavens into perfect opacity; for prejudice or passion indulged to excess by those to whom is committed a nation's destiny, mounts into crime. In coming then to a decision upon this momentous question, pregnant with the future weal or woe of our country, let all of feeling calculated to disturb the judgement be stilled, and let the decision, whatever it may be, receive our
              undivided support.
            </p>
            <p>2. The Total Cavalry figure below, and on p. 92, ought to be 4,800.</p>
            <p>3. The meaning is unclear.</p>
            <p>4. Some word such as "arises" seems to be missing here.</p>
            <p>5. The Journal adds Armstrong and White to the affirmative votes listed here. The Journal adds Ambler, Addison Hall, and Leake to negative votes listed here, to arrive at a total of 79; however, apparently only Ambler should be added, since Leake and Hall were paired.</p>
            <PageNumber num={815} />
            <p>6. This name should be that of Mr. Anderson, the Commissioner from Mississippi.</p>
            <p>7. The Journal adds Rives to the affirmative votes listed here.</p>
            <p>8..The Journal adds L. S. Hall to the affirmative votes listed here; the Journal lists 62 negative votes, adding Carter and Clemens to those given here.</p>
            <p>THIRD DAY</p>
            <p>Thursday, April 18</p>
            <p>1. Mr. Turner's amendment is hopelessly garbled. According to the Journal, <span className="persName">Mr. TURNER</span>, of Jackson. moved to amend the resolution, by striking out the word "next" after "Saturday," and inserting, in lieu thereof, "the 27th instant."</p>
            <p>2. This line seems confused. See page 242 for another account of this procedure.</p>
            <p>3. This should be Harrison.</p>
            <p>FOURTH DAY</p>
            <p>Friday, April 19</p>
            <p>1. Something like "equal" or "ad valorem" must have been intended to qualify "taxation."</p>
            <p>FIFTH DAY</p>
            <p>Saturday, April 20</p>
            <p>
              1. It was the eighty-fifth year of the Commonwealth. The date of April 24 was that on which the schedule was finally approved (see page 418). Since this Schedule, and a number of Ordinances passed later in April, include the dates on which they were passed, the Enquirer evidently printed them from versions already published when the Proceedings were being readied for the press.
              Sometimes, as here, a document bears a date of passage several days later than the day on which it was reported as passed.
            </p>
            <p>2. Apparently some such words as "shall be suspended" were omitted after "Commonwealth."</p>
            <p>3. The Enquirer omitted to report the submission, at about this point in the Proceedings, of two resolutions by Mr. Seawell, and an amendment to the first resolution, moved by Mr. Blakey. The Journal reported this business as follows:</p>
            <p><span className="persName">Mr. SEAWELL</span> submitted the following resolutions:</p>
            <p>1. Resolved, That a committee of five be appointed by the Chair, to inquire into the expediency of postponing the election of members of Congress to the United States, at the ensuing general elections for the State, on the fourth Thursday in May next.</p>
            <p>2. Resolved, That the same Committee inquire into the propriety of providing that persons entitled to vote at the ensuing elections, who may</p>
            <PageNumber num={816} />
            <p>be in the actual service of the State, at the time, may cast their votes at the nearest place of election to them, on the question of the ratification or rejection of the Ordinance of Secession.</p>
            <p><span className="persName">Mr. BLAKEY</span> moved to amend the first resolution by striking out all after the word "resolved," and inserting the following:</p>
            <p>"That a committee of five be appointed to report what action, if any, this Convention should take in reference to the approaching Congressional election in this State."</p>
            <p>The question was put, and decided in the affirmative, and the resolution, as amended, was adopted.</p>
            <p><span className="persName">Mr. EARLY</span> moved that the second resolution submitted by <span className="persName">Mr. SEAWELL</span>, be referred to the Committee of Elections. Negatived.</p>
            <p>The resolution was then adopted.</p>
            <p>The exact point at which Mr. Seawell offered his resolutions is not quite certain, for the order of events on April 20, according to the Journal, was not precisely what the Enquirer reported.</p>
            <p>4. The Journal lists 30 affirmative votes, as given here: the Journal omits Forbes from the negative votes, but adds Preston and Price to those in this list.</p>
            <p>5. This word should be "apportionment."</p>
            <p>6. This should logically be "not just."</p>
            <p>7. Presumably this should be "convention."</p>
            <p>8. The word "not" must have been dropped out here.</p>
            <p>9. The Journal adds John Goode and F. P. Turner to the affirmative votes given here. In the Journal, F. P. Turner and Woods are not among the 55 negative votes.</p>
            <p>10. The Journal also refers to 46 negative votes, but lists only the 45 given here.</p>
            <p>11. The Journal adds Preston to the negative votes listed in the Proceedings.</p>
            <p>SEVENTH DAY</p>
            <p>Monday, April 22</p>
            <p>1. See note 3 on the Proceedings of April 20 for the resolution and amendment.</p>
            <p>2. The Journal adds Johnston, Sitlington, Spurlock, and Tayloe to the negative votes given here.</p>
            <p>3. This should perhaps be "lambs," which would fit the context better than "lands."</p>
            <p>4. The Journal adds Spurlock to the negative votes listed here.</p>
            <p>5. Probably the speaker meant to say either "unless Col. Lee accepts," or "if Col. Lee does not accept... ."</p>
            <p>6. The text sounds confused here. The word protege may be a rendering of protege, but Mr. Macfarland seems to have been referring to the ordinance for the organization of staff departments (pp. 344-345) rather than to a person. If he was speaking of that ordinance, he may have described</p>
            <PageNumber num={817} />
            <p>it as a pro jet, or draft. In any event, the text should certainly read "for his opinion . ." or "to his opinion.. .."</p>
            <p>EIGHTH DAY</p>
            <p>Tuesday, April 23</p>
            <p>
              1. The section of the Proceedings for April 23 concerning the reception of General Lee (the paragraph with the President's announcement that Lee was in the Capitol, and the following text down through the paragraph beginning "The chair was then vacated ..." ) was printed in the Enquirer on April 25, in the Journal of the Convention for April 23, and then in the full report of the
              Proceedings of April 23 which was published in the Enquirer on November 5. There are a few inconsequential differences among the three versions; the versions in the Journal and the Enquirer of November 5 are nearly identical. The version of the Proceedings printed in the Enquirer on November 5 is given here, unless another version is specified.
            </p>
            <p>2. This paragraph and the one just below it are placed immediately after the paragraph describing the entrance of Mr. Stephens, in the Enquirer of April 25. Their position here is the one they have in the Journal.</p>
            <p>3. This paragraph and the one just below it are placed immediately after the paragraph beginning "When the Father of his Country ..." in the Enquirer of November 5. Their position here is the one they have in the Enquirer of April 25 and in the Journal, and seems to be the logical position.</p>
            <p>NINTH DAY</p>
            <p>Wednesday, April 24</p>
            <p>1. See note 1 on the Proceedings of April 20.</p>
            <p>2. Something like "matters" or "subjects" has probably been omitted here.</p>
            <p>3. This should presumably read "James river."</p>
            <p>TENTH DAY</p>
            <p>Thursday, April 25</p>
            <p>1. The Enquirer printed "indication," but the context seems to call for "vindicator."</p>
            <p>2. According to the Journal, the vote was 75 to 19; the Journal's list has Robert Y. Conrad among the negative votes, and not among the affirmative votes. The Journal states that Mr. Morton moved to record the vote.</p>
            <p>ELEVENTH DAY</p>
            <p>Friday, April 26</p>
            <p>1. The Journal lists 28 negative votes; it omits A. M. Barbour from the list as given below.</p>
            <p>2. These "rules and articles of war in the United States army" have not been found in the Enquirer.</p>
            <PageNumber num={818} />
            <p>3. The Journal adds Deskins to negative votes listed here.</p>
            <p>4. The Journal adds Sharp to affirmative votes listed here.</p>
            <p>TWELFTH DAY</p>
            <p>Saturday, April 27</p>
            <p>1. A communication from Mr. Dorman (November 19) corrected his remarks</p>
            <p>of April 27 in support of the nomination of Major Jackson, as follows:</p>
            <p>
              Gentlemen: In the report of our proceedings of April I am represented as saying that Major (now Major General) Jackson is a graduate of the Virginia Military Institute. On my own account, I should not care to correct the mistake, but anything relating to an officer who has so nobly distinguished himself in the present war, should be stated with accuracy. Upon the nomination of Major
              Jackson as Colonel of Volunteers, the question was asked, "Who is he?" I responded to the inquiry, and after a brief tribute to the gallantry and high personal qualities of the nominee, said, "I have known him for several years as a Professor at the Virginia Military Institute. He is a graduate of West Point, and was brevetted three times for gallant service in a very short space of
              time (I believe about three weeks) as Lieutenant of Artillery in the Mexican War."
            </p>
            <p>THIRTEENTH DAY</p>
            <p>Monday, April 29</p>
            <p>1. The Journal adds Baldwin and Preston to the affirmative votes listed here.</p>
            <p>2. The Journal states there were 62 votes for Rives, and adds Richardson to those listed here. Among the votes for Seddon, the Journal has neither Forbes nor Richardson, but it adds Cyrus Hall to those listed in the Proceedings.</p>
            <p>3. The Journal refers to 72 votes for Staples, but lists only 71; it adds Janney, Baylor, Sharp and Tyler to the names given here, but omits Lawson. According to the Journal, there were 8 votes for Floyd: those listed in the Proceedings, and Blakey and L. S. Hall in addition.</p>
            <p>4. Perhaps "as that" has been omitted here.</p>
            <p>5. The Journal lists the same 48 votes for each nominee as listed here, although it says of this vote "Whole number of votes 95; necessary to a choice 48. Mr. Camden having received the requisite number, was declared duly elected."</p>
            <p>6. The Journal adds Miller to the negative votes listed here.</p>
            <p>7. The Journal adds Morris to affirmative votes listed here; in the Journal the negative votes have Janney and R. E. Scott in addition to those listed here, but do not include Spurlock.</p>
            <p>8. This word probably should be "notes."</p>
            <p>9. The Journal adds F. P. Turner to the negative votes listed here.</p>
            <p>10. The speaker must have said "ought not to be passed."</p>
            <PageNumber num={819} />
            <p>FOURTEENTH DAY</p>
            <p>Tuesday, April 30</p>
            <p>1. Evidently "arms" is omitted here.</p>
            <p>2. No comment on this subject by Mr. Harvie has been found up to this point; see, however, pages 668 and 670-671 for Mr. Harvie's substitute and comment.</p>
            <p>3. The ordinance was passed on April 29.</p>
            <p>4. The motto is given thus in the Enquirer.</p>
            <p>5. The word proteges used here and on the following page is thought to be meant for the French word protOggs, and that word was perhaps mistakenly used instead of projets. Evidently the speaker refers to draft versions (projets) of the ordinance to provide against the sacrifice of property. Compare note 6 on Proceedings of April 22.</p>
            <p>6. The closing section of this question seems to be missing.</p>
            <p>7. Some words like "will read" should follow "resolution," and "of this State" should follow "Governor."</p>
            <p>FIFTEENTH DAY</p>
            <p>Wednesday, May 1</p>
            <p>1. Mr. Early may have said "as the Provisional Army."</p>
            <p>2. The Morning Report which General Lee enclosed is reproduced on p. 755, with the tabular arrangement altered to make the Report fit the page.</p>
            <p>3. The context seems to call for "Confederate Government."</p>
            <p>4. Mr. Wickham possibly said "The rejection of that ordinance...."</p>
            <PageNumber num={820} />
          </div>
          <span className="pagebreak" id="index.xml-pb-w5969083aab1b2ab4">[Page]</span>
        </section>
      </div>
    </Styled.Volume>
  );
};

export default Transcription;
