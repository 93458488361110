import styled from "styled-components";
import * as Constants from '../../constants';

export const TimelinePageContainer = styled.div`
  background-color: ${Constants.colors.grayLight};
  width: min(1200px, 100%);
  margin: 10px auto;
  height: 12100px;
  padding: 3rem 0 5rem 0;
`;

export const Timeline = styled.svg`
  position: absolute;
  top: 0px;
  margin: 0 auto;
  display: block;
  z-index: 1;
`;

export const TimelineContainer = styled.div`
  position: relative;
`;
