import { timeFormat } from "d3";
import { useTimelineContext } from "../../../hooks";
import * as Styled from "./styled";

const MonthLabels = () => {
  const { months, yScale, dates } = useTimelineContext();
  const { lincolnsElection, firstDate } = dates;
  return (
    <Styled.Months $paddingTop={yScale(lincolnsElection) - yScale(firstDate) + 9}>
      {months.map(date => (
        <Styled.Month
          height={date.height}
          key={date.date.toISOString()}
        >
          <Styled.MonthLabel>
            {timeFormat("%b")(date.date)}
            <br />
            {timeFormat("%Y")(date.date)}
          </Styled.MonthLabel>
        </Styled.Month>
      ))}
    </Styled.Months>
  );
};

export default MonthLabels;
