import { useEffect, useRef, useState } from "react";
import * as Styled from "./styled";

const Event = ({ event, space }: { event: string[] | string; space?: number }) => {
  const ref = useRef(null);
  const [ellipse, setEllipse] = useState(false);

  useEffect(() => {
    if (ref.current) {
      const { clientWidth, scrollWidth } = ref.current;
      if (scrollWidth > clientWidth) {
        setEllipse(true);
      }
    }
  }, []);

  useEffect(() => {
    if (ref.current && space) {
      const { scrollHeight } = ref.current;
      if (scrollHeight > space) {
        setEllipse(true);
      }
    }
  }, [space, event]);

  return (
    <Styled.Event
      $ellipse={ellipse}
      ref={ref}
    >
      {typeof event === "object" ? (
        <ul>
          {event.map(_event => (
            <li key={_event}>{_event}</li>
          ))}
        </ul>
      ) : (
        <>{event}</>
      )}
    </Styled.Event>
  );
};

export default Event;
