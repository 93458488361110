import React, { useRef, useLayoutEffect } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { usePageNumberOffsetsContexts, useOnScreen, useDimensions } from '../../../hooks';
import * as Styled from "./styled";

const PageNumber = ({ num }: { num: number }) => {
  const { volume } = useParams();
  const navigate = useNavigate();
  const dimensions = useDimensions();
  const ref: any = useRef<HTMLDivElement>();
  const { activePage, isAutoScrolling } = usePageNumberOffsetsContexts();


  const int2roman = (original: number): string => {
    if (original < 1 || original > 3999) {
      throw new Error('Error: Input integer limited to 1 through 3,999');
    }

    const numerals = [
      ['I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX'], // 1-9
      ['X', 'XX', 'XXX', 'XL', 'L', 'LX', 'LXX', 'LXXX', 'XC'], // 10-90
      ['C', 'CC', 'CCC', 'CD', 'D', 'DC', 'DCC', 'DCCC', 'CM'], // 100-900
      ['M', 'MM', 'MMM']
    ]; // 1000 - 3000


    // TODO: Could expand to support fractions, simply rounding for now
    const digits = Math.round(original).toString().split('');
    let position = (digits.length - 1);

    return digits.reduce((roman, digit) => {
      if (digit !== '0') {
        roman += numerals[position][parseInt(digit) - 1];
      }

      position -= 1

      return roman
    }, '');
  }


  let pageLabel = num.toString();
  if (volume === 'v1') {
    pageLabel = (num < 25) ? int2roman(num).toLowerCase() : (num - 24).toString();
  }
  if (volume === 'v2' || volume === 'v3') {
    pageLabel = (num < 11) ? int2roman(num).toLowerCase() : (num - 10).toString();
  }
  if (volume === 'v4') {
    pageLabel = (num < 13) ? int2roman(num).toLowerCase() : (num - 12).toString();
  }
  
  const y = useOnScreen(ref) as number;
  
  // I *think* useLayoutEffect is preferable to useEffect here as you want to get the y location asap and prevent browser painting from preventing a scroll from being missed.
  useLayoutEffect(() => {
    const headersHeight = dimensions.header.height + dimensions.headerTitle.height;
    const transcriptionHeight = dimensions.height - headersHeight - dimensions.footer.height;
    // set the y range for detecting page anchors for autonavigations at 10% of the height of the transcription area
    const detectionY = transcriptionHeight * 0.1;
    if (y && y >= 0 && y <= detectionY && activePage !== 0 && activePage !== num && !isAutoScrolling) {
      // if the pagenumber is at the top of the screen, navigate to it
      // set a slight delay to account for scrolling events like find that you can't keep track of
      setTimeout(() => {
        const anotherY = ref.current.getBoundingClientRect().y - headersHeight;
        if (anotherY >= 0 && anotherY <= detectionY) {
          navigate(`../${num}`, { relative: "path", replace: true });
        }
      }, 50)
    }
  }, [y, activePage, num, isAutoScrolling, dimensions]);

  return (
    <Styled.PageNumber>
      <span id={`page-${num}`} ref={ref} className='page-point'>.</span>
      <Link to={`../${volume}/${num}`} className='page-link'>
        <span className="page-number">
          <span className='label'>Page</span>
          {pageLabel}
        </span>
      </Link>
    </Styled.PageNumber>
  );
}

export default React.memo(PageNumber);