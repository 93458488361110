import styled from "styled-components";
import * as Constants from "../../../constants";

export const Event = styled.div<{ $ellipse: boolean }>`
  font-size: 1rem;
  // month labels are 70px wide, half the width of the track is 10px, the offset is 20px
  width: calc(100% - 100px - 1em);
  pointer-events: auto;
  border: 1px solid transparent;
  border-left-color: ${Constants.colors.grayDark};
  border-radius: 5px;
  padding: 5px 5px 5px 5px;
  overflow: hidden;

  ul {
    margin: 0;
    padding-left: 0;
    list-style: none;
  }

  li {
    &:before {
      content: "• ";
      color: purple;
    }
  }

  ${p => p.$ellipse && (
    `
    height: 12px;  
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    border-bottom: 1px dashed ${Constants.colors.interactive};

    li {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow-x: hidden;
    }

    &:hover {
      white-space: normal;
      background-color: white;
      height: auto;
      border-color: ${Constants.colors.grayDark};
      border-bottom-style: solid;

      li {
        white-space: normal;
        text-decoration: none;
      }
    }
  `)}
`;

export const Edge = styled.line`
  stroke: ${Constants.colors.grayDark};
`;
