import styled from "styled-components";
import { Link } from "react-router-dom";
import * as Constants from "../../constants";
import { hexToRgb } from "../../utilities";

export const Results = styled.div`
  max-width: min(900px, 95vw);
  margin: 5px auto;
  padding: 0.5em 0;
  background-color: ${Constants.colors.white};
  box-shadow: 0px 10px 10px -12px rgba(${hexToRgb(Constants.colors.black)}, 0.25);

  > * {
    padding: 0 1em;
  }

  a {
    color: ${Constants.colors.interactive};
  }
`;

export const Title = styled.h4`
  margin-bottom: 0;
  text-align: left;
`;

export const Excerpt = styled.li`
  margin-left: 1em;
  line-height: 1.2;

  em {
    padding: 0 0.25em;
    font-weight: bold;
    font-style: normal;
    background-color: ${Constants.colors.yellow};
  }
`;

export const Nav = styled.div`
  max-width: min(900px, 95vw);
  gap: 0.5em;
  margin: 20px auto;
  font-size: 0.8em;
  text-align: center;

  @media ${Constants.devices.tablet} {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    text-align: unset;
  }

  > div {
    min-width: 10em;
  }

  &:last-child {
    margin-bottom: 5em;
  }
`;

export const PageNum = styled.div`
  flex-shrink: 10;
  display: inline-block;
  margin-bottom: 0.5em;
  margin-right: 0.5em;
  font-size: 1.3em;
  font-weight: 700 !important;
  text-align: right;

  @media ${Constants.devices.tablet} {
    margin-bottom: 0;
  }
`;

export const PrevPageLink = styled(Link)`
  display: inline-block;
  margin-right: 0.5em;

  &:nth-of-type(2n) {
    margin-right: 0 ;
  }

  svg {
    height: 1.25em;
    width: 1.25em;
    fill: none;
    stroke: ${Constants.colors.interactive};
    stroke-width: 60;
    stroke-linejoin: miter;
    transform: translateY(20%);
  }
`;

export const NextPageLink = styled(PrevPageLink)`
  text-align: right;
`;

export const LinkPlaceholder = styled.div`
  width: 100px;
`;

export const NoResults = styled.div`
  max-width: min(900px, 95vw);
  text-align: center;
  margin: 40px auto;
  font-size: 1.5em;
  font-weight: bold;
`