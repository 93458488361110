import styled from "styled-components";
import { Link } from "react-router-dom";
import * as Constants from '../../constants';
import { hexToRgb } from "../../utilities";

export const Landing = styled.div`

  h2 {
    margin-top: 3rem;

    @media ${Constants.devices.tablet} {
      margin-top: 4rem;
    }
  }
`;

export const Volumes = styled.div`

  @media ${Constants.devices.tablet} {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

export const VolumeLink = styled(Link)`
  display: block;
  margin: 0 0 10px 0;
  padding: 1rem;
  color: ${Constants.colors.text};
  text-align: center;
  text-decoration: none;
  background-color: rgba(${hexToRgb(Constants.colors.white)}, 0.4);
  box-shadow: 0px 5px 5px -2px rgba(${hexToRgb(Constants.colors.black)}, 0.1);
  transition: background-color 300ms, box-shadow 1000ms;

  @media ${Constants.devices.tablet} {
    display: flex;
    flex-direction: column;
    flex-basis: calc(50% - 2rem - 5px);
    justify-content: center;
  }

  &:hover,
  &:active {
    color: ${Constants.colors.interactive};
    background-color: rgba(${hexToRgb(Constants.colors.white)}, 1);
    box-shadow: 0px 5px 5px -2px rgba(${hexToRgb(Constants.colors.black)}, 0.025);
  }
`;


export const TextsMenu = styled.div`
  box-shadow: 0px 10px 10px -12px rgba(${hexToRgb(Constants.colors.black)}, 0.25);

  button {
    height: 18px;
    width: 18px;
    color: white;
    font-weight: 700;
    line-height: 18px;
    background-color: ${Constants.colors.interactive};
    border: 0;
    border-radius: 50%;

    &:hover {
      background-color: ${Constants.colors.interactiveDark};
    }
  }

  h2 {
    position: relative;
    margin: 0 0 1rem 0;
    padding: 1rem 0 1rem 30px;
    text-align: left;
    cursor: pointer;

    &:hover,
    &:active {
      color: ${Constants.colors.interactive};
    }

    > svg {
      position: absolute;
      top: 50%;
      left: 0;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }

  ul {
    margin: -1rem 0 1rem 0;
    padding: 0 0 1.5rem 0;

    li {
      list-style: none;
      break-inside: avoid-column;
      display: block;
      margin: 0;
    }

    a {
      padding: 0.75rem;
      color: ${Constants.colors.text};
      display: grid;
      grid-template-columns: 40% calc(60% - 0.6em);
      gap: 0.6em;
      text-decoration: none;
      background-color: transparent;
      cursor: pointer;

      &:hover,
      &:active {
        background-color: ${Constants.colors.interactiveLight};

        span {
          color: ${Constants.colors.interactive};
        }
      }

      span:nth-child(1) {
        text-align: right;
        font-weight: bold;
      }

      &.noquorum span {
        opacity: 0.5;
      }
    }
  }

  @media ${Constants.devices.tablet} {
    ul {
      columns: 2;
  
      a {
        padding: 0.5rem;
      }
    }
  }

  @media ${Constants.devices.desktop} {
    ul {
      columns: 3;
    }
  }
`;
