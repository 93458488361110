import React from "react";
import Counties from '../../data/counties.json';

type Vote = 'secession' | 'union' | undefined;
type Delegate = {
  name: string;
  vote404?: Vote;
  vote417?: Vote;
};
type County = {
  nhgis_join: string;
  delegates: Delegate[];
  path: string;
}


const Map = ({ date, emphasizeChanged }: { date: '404' | '417'; emphasizeChanged?: boolean }) => {
  // const grey = '#917B78';
  // const blue = '#2222aa';
  // const split = '#555500';
  const grey = '#229922';
  const blue = '#772277';
  const split = '#555500';

  const votesChanged = (delegates: Delegate[]) => {
    const votes404 = delegates
      .filter(d => d[`vote404`])
      .map(d => d[`vote404`]);
    const votes417 = delegates
      .filter(d => d[`vote417`])
      .map(d => d[`vote417`]);
    const percent = (votes: Vote[], type: 'secession' | 'union') => votes.filter(d => d === type).length / votes.length;
    if ((votes404.length === 0 && votes417.length > 0) || (votes404.length > 0 && votes417.length === 0)) {
      return true;
    }
    if (votes404.length > 0 || votes417.length > 0) {
      if (percent(votes404, 'union') !== percent(votes417, 'union') || percent(votes404, 'secession') !== percent(votes417, 'secession')) {
        return true;
      }
    }
    return false;
  };

  const counties = (Counties as County[]).sort((a, b) => {
    if (votesChanged(a.delegates) && !votesChanged(b.delegates)) {
      return 1;
    }
    if (!votesChanged(a.delegates) && votesChanged(b.delegates)) {
      return -1;
    }
    return 0;
  })

  const getColor = (delegates: Delegate[]) => {
    const votes = delegates.filter(d => d[`vote${date}`]).map(d => d[`vote${date}`]);
    if (votes.every(d => !d)) {
      return 'transparent';
    }
    if (votes.every(d => d === 'secession')) {
      return grey;
    }
    if (votes.every(d => d === 'union')) {
      return blue;
    }
    return 'url(#pattern)';
  }

  const getStroke = (delegates: Delegate[]) => {
    const votes = delegates.filter(d => d[`vote417`]).map(d => d[`vote417`]);
    if (votes.every(d => !d)) {
      return 'transparent';
    }
    if (votes.every(d => d === 'secession')) {
      return grey;
    }
    if (votes.every(d => d === 'union')) {
      return blue;
    }
    return 'url(#pattern)';
  };

  const getStrokeWidth = (delegates: Delegate[]) => {
    const votes404 = delegates
      .filter(d => d[`vote404`])
      .map(d => d[`vote404`]);
    const votes417 = delegates
      .filter(d => d[`vote417`])
      .map(d => d[`vote417`]);
    const percent = (votes: Vote[], type: 'secession' | 'union') => votes.filter(d => d === type).length / votes.length;
    if (votes404.length > 0 || votes417.length > 0) {
      if (percent(votes404, 'union') !== percent(votes417, 'union') || percent(votes404, 'secession') !== percent(votes417, 'secession')) {
        return 3;
      }
    }
    return 1;
  };

  return (
    <svg
      viewBox="30 0 830 560"
    >
      <defs>
        <pattern id="pattern" patternUnits="userSpaceOnUse" width="8" height="8" patternTransform="rotate(60)">
          <line x1="2" y="0" x2="2" y2="8" stroke={blue} stroke-width="4" />
          <line x1="6" y="0" x2="6" y2="8" stroke={grey} stroke-width="4" />
        </pattern>
      </defs>
      {Counties.map((county) => (
        <path
          key={county.nhgis_join}
          d={county.path}
          stroke={votesChanged(county.delegates as Delegate[]) ? '#bbb' : '#bbb'}
          //stroke={getStroke(county.delegates as Delegate[])}
          strokeWidth={votesChanged(county.delegates as Delegate[]) ? 1 : 1}
          fill={getColor(county.delegates as Delegate[])}
          fillOpacity={(!emphasizeChanged || votesChanged(county.delegates as Delegate[])) ? 1 : 0.1}
        />


      ))}
      <text transform='translate(430 550)' textAnchor="middle">
        <tspan fill={grey} fontSize='3.5em'>■</tspan> 
        <tspan dx='7' dy='-3' fill='black' fontSize='22px'>FOR SECESSION</tspan>
        <tspan dx='30' dy='3' fill={blue} fontSize='3.5em'>■</tspan>
        <tspan dx='7' dy='-3' fill='black' fontSize='22px'>AGAINST SECESSION</tspan>
        <tspan dx='30' dy='3' fill='url(#pattern)' fontSize='3.5em'>■</tspan>
        <tspan dx='7' dy='-3' fill='black' fontSize='22px'>SPLIT</tspan>
      </text>
    </svg>
  );
};

export default Map;