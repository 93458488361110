import { useTimelineContext } from "../../../hooks";
import Event from "./Event";
import * as Styled from './styled';

const Events = () => {
  const { dimensions, yScale, events } = useTimelineContext();
  const { width, trackWidth, eventOffset } = dimensions;
  return (
    <>
      <foreignObject
        y={yScale(new Date(1860, 10, 6)) - 12}
        width={width / 2 - eventOffset}
        height="200"
        pointerEvents="none"
        transform={`translate(${eventOffset + 5})`}
      >
        <div>Abraham Lincoln elected President</div>
      </foreignObject>

      {events.map((event, i) => (
        <g
          key={`${event.start}-${event.title}`}
          transform={`translate(0, ${yScale(event.start)})`}
        >
          <Styled.Edge
            x1={trackWidth / 2}
            x2={20}
          />
          <foreignObject
            y="-12"
            width={width - eventOffset}
            height="800"
            pointerEvents="none"
            transform={`translate(${eventOffset})`}
          >
            <Event
              event={event.title}
              space={events[i - 1] ? yScale(events[i - 1].start) - yScale(event.start) : undefined}
            />
          </foreignObject>
        </g>
      ))}
    </>
  );
};

export default Events;
